import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ConfiguracaoGuiaTissAPI from 'src/APIs/ConfigAPI/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldEspecialidadeProps = DropdownProps & {
  name?: string;
  label: string;
  params: {
    idConvenio: string | number;
  };
};

export const FieldGuiaTiss = ({
  name = 'guiaTiss',
  label,
  params,
  ...rest
}: FieldEspecialidadeProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [guiaTiss, setGuiaTiss] = useState<any[]>([]);

  const loadAPI = useCallback(async () => {
    if (params?.idConvenio)
      ConfiguracaoGuiaTissAPI.loadConfiguracaoGuiaTiss(params).then((d: any) =>
        setGuiaTiss(d),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.idConvenio]);

  useEffect(() => {
    loadAPI();
  }, [loadAPI]);

  const options = useMemo(() => {
    const options = guiaTiss.map((item: any) => ({
      label: item.descricao,
      value: item,
    }));

    return options;
  }, [guiaTiss]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={options || []}
      errorMsg={errors?.[name]?.message}
      hideTextErrorSpace={true}
      disabled={!params?.idConvenio}
      {...rest}
    />
  );
};
