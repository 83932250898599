import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';

import DropdownControlled, {
  DropdownControlledProps,
} from 'src/components/Basics/DropdownControlled/DropdownControlled';

type IProps = Omit<DropdownControlledProps, 'control' | 'name'> & {
  name?: string;
  label: string;
};

export const FieldTiposPedidoCuidado = ({
  name = 'pedidoCuidado',
  label,
  ...rest
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    setLoading(true);

    const { data } = await PedidosCuidadoAPI.getTiposPedidoCuidado({
      nome: '',
      ativo: true,
    });

    if (data) setItems(data);

    setLoading(false);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const options = items.map(item => ({
      label: item.descricao,
      value: item,
    }));

    return options;
  }, [items]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={options}
      errorMsg={errors?.[name]?.message}
      hideTextErrorSpace={false}
      loading={loading}
      {...rest}
    />
  );
};
