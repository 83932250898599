import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button, RadioButton } from 'src/components/_UI';
import Checkbox from 'src/components/Basics/Checkbox/Checkbox';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const tipoResposta: { [key: string]: string } = {
  TEXTO: 'Texto',
  NUMERO: 'Número',
  ESCOLHA: 'Escolha',
  MULTIPLA_ESCOLHA: 'Múltipla escolha',
  DATA: 'Data',
};

export const FormularioDinamicoAcervoPerguntasRow = ({
  onDelete,
  item,
}: {
  onDelete(): void;
  item: IPerguntaFormularioItem;
}) => {
  const { isMobile } = useSize();
  const disclosure = useDisclosure({ opened: false });

  if (isMobile) {
    return <MobileRow item={item} onDelete={onDelete} {...disclosure} />;
  }

  const isSelectType =
    ['ESCOLHA', 'MULTIPLA_ESCOLHA'].includes(item.tipoResposta) &&
    Boolean(item.opcoes?.length);

  return (
    <div className="p-d-flex p-flex-wrap row content-row p-mb-2 p-ai-center">
      <div className="p-col-4 p-md-5  p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Enunciado
        </SimpleText>
        <SimpleText>{item.enunciado}</SimpleText>
      </div>
      <div className="p-col-3 p-md-2 p-xl-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Tipo
        </SimpleText>
        <SimpleText>{tipoResposta[item.tipoResposta]}</SimpleText>
      </div>
      <div className="p-col-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Data da inclusão
        </SimpleText>
        {/* // TODO */}
        <SimpleText>
          {item.dataInclusao
            ? dayjs(item.dataInclusao).format('DD/MM/YYYY HH:mm')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-2 p-xl-1 p-d-flex p-gap-2 p-jc-end p-ai-center">
        <Divider layout="vertical" className="p-px-0 p-mx-0" />

        <Button
          btnType="gray"
          icon="fas fa-trash"
          onClick={() => onDelete()}
          checkPermission={'ADM_MANTER_PERGUNTA_FORMULARIO_DINAMICO_EXCLUIR'}
        />
        <Button
          btnType="gray"
          icon={disclosure.isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          onClick={() => disclosure.toggle()}
          disabled={!isSelectType}
        />
      </div>

      {disclosure.isOpen && (
        <div className="p-col-12 p-py-0 p-px-1">
          {item.tipoResposta === 'ESCOLHA' && (
            <div className="p-d-flex p-flex-column p-gap-2 p-col-12 p-mb-2">
              {item.opcoes?.map((opcao, index) => (
                <RadioButton
                  panel={false}
                  disabled
                  key={index}
                  label={opcao.descricao}
                />
              ))}
            </div>
          )}

          {item.tipoResposta === 'MULTIPLA_ESCOLHA' && (
            <div className="p-d-flex p-flex-column p-mb-2">
              <Checkbox
                disabled
                options={
                  item.opcoes?.map(opcao => ({
                    label: opcao.descricao,
                    value: opcao.descricao,
                  })) || []
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type MobileProps = DisclosureType & {
  onDelete: () => void;
  item: IPerguntaFormularioItem;
};

const MobileRow = ({ item, ...disclosure }: MobileProps) => {
  const isSelectType =
    ['ESCOLHA', 'MULTIPLA_ESCOLHA'].includes(item.tipoResposta) &&
    Boolean(item.opcoes?.length);

  return (
    <div className="p-d-flex p-flex-wrap row content-row p-mb-2">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Enunciado
        </SimpleText>
        <SimpleText>
          Por causa de sua saúde ou condição física, você deixou de fazer
          compras?
        </SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Tipo
        </SimpleText>
        <SimpleText>Múltipla escolha</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Data da inclusão
        </SimpleText>
        <SimpleText>{dayjs().format('DD/MM/YYYY HH:mm')}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-gap-2 p-jc-end p-ai-center">
        <Divider layout="vertical" className="p-px-0 p-mx-0" />

        <Button
          btnType="gray"
          icon="fas fa-trash"
          onClick={() => disclosure.onDelete()}
          checkPermission={'ADM_MANTER_PERGUNTA_FORMULARIO_DINAMICO_EXCLUIR'}
        />
        <Button
          btnType="gray"
          icon={disclosure.isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          onClick={() => disclosure.toggle()}
          disabled={!isSelectType}
        />
      </div>

      {disclosure.isOpen && (
        <div className="p-col-12 p-py-0 p-px-1">
          {item.tipoResposta === 'ESCOLHA' && (
            <div className="p-d-flex p-flex-column p-gap-2 p-col-12 p-mb-2">
              {item.opcoes?.map((opcao, index) => (
                <RadioButton
                  panel={false}
                  disabled
                  key={index}
                  label={opcao.descricao}
                />
              ))}
            </div>
          )}

          {item.tipoResposta === 'MULTIPLA_ESCOLHA' && (
            <div className="p-d-flex p-flex-column p-mb-2">
              <Checkbox
                disabled
                options={
                  item.opcoes?.map(opcao => ({
                    label: opcao.descricao,
                    value: opcao.descricao,
                  })) || []
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
