import { Controller, useForm } from 'react-hook-form';

import MotivoAtendimentoAPI from 'src/APIs/ProntuarioAPI/FichaPaciente/MotivoAtendimento/MotivoAtendimentoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';

interface InativarProps {
  visible: boolean;
  onHide: () => void;
  reloadList: () => void;
  idAtendimento: string | undefined;
  motivoAtendimento: MotivoAtendimento | undefined;
}

const InativarMotivo = ({
  visible,
  onHide,
  idAtendimento,
  motivoAtendimento,
  reloadList,
}: InativarProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({});

  const onSubmit = async (data: any) => {
    const payload = {
      idAtendimento: idAtendimento,
      idTipoMotivoAtendimento: motivoAtendimento?.id,
      idProfissionalSaude: user.idUsuario,
      dataOcorrencia: motivoAtendimento?.dataOcorrencia,
      justificativaInativacao: data.justificativaInativar,
    };

    const response = await MotivoAtendimentoAPI.putMotivoAtendimentoInativar(
      payload,
    );

    if (response?.status === 204) {
      reloadList();
      onHide();
    }
  };

  return (
    <Dialog
      header="Você tem certeza que deseja inativar?"
      type="modal"
      visible={visible}
      onHide={onHide}
      modal={false}
    >
      <div>
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
          Você está inativando a inclusão deste item no prontuário do paciente.
          Ele continuará ser exibido como uma informação inativa. Para
          continuar, justifique.
        </SimpleText>

        <form
          className="p-mt-3"
          onSubmit={handleSubmit(data => onSubmit(data))}
        >
          <Controller
            name="justificativaInativar"
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <TextareaInput
                label="Justificativa"
                className="grid p-col-12 p-md-12  p-mb-2"
                value={value}
                onChange={onChange}
                autoResize
              />
            )}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={onHide}
              stretch
              loading={isSubmitting}
            />
            <Button
              label="Salvar"
              stretch
              loading={isSubmitting}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default InativarMotivo;
