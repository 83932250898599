import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldListarPerguntasVinculoProps = DropdownProps & {
  name?: string;
  label: string;
  perguntaId: number;
  idFormularioDinamico: number;
};

export const FieldListarPerguntasVinculo = ({
  name = 'listaPerguntas',
  label,
  perguntaId,
  idFormularioDinamico,
  ...rest
}: FieldListarPerguntasVinculoProps) => {
  const { control, watch } = useFormContext();
  const [tipoPergunta, setTipoPerguntas] = useState<
    IFormularioDinamicoPergunta[]
  >([]);

  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetQuery = useThrottle(setQuery, 500);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(
    async (query?: string) => {
      setIsLoading(true);
      try {
        const response =
          await PerguntaFormularioDinamicoAPI.loadPerguntasParaVinculo(
            {
              enunciado: query,
              id: perguntaId,
              idFormularioDinamico,
            },
            {
              throwError: true,
            },
          );

        setTipoPerguntas(response.list);
      } catch {
        setTipoPerguntas([]);
      } finally {
        setIsLoading(false);
      }
    },
    [idFormularioDinamico, perguntaId],
  );

  useEffect(() => {
    load(query);
  }, [load, query]);

  const perguntaAtual = watch(name);

  const options = useMemo(() => {
    const data = tipoPergunta.map(item => ({
      label: item?.perguntaFormularioDinamico?.enunciado,
      value: item,
    }));

    // valida se a pergunta atual está listado nas options
    if (perguntaAtual) {
      const isPerguntaAtualVisivel = tipoPergunta.find(
        perg => perg.id === perguntaAtual.id,
      );

      if (!isPerguntaAtualVisivel) {
        data.unshift({
          label: perguntaAtual?.perguntaFormularioDinamico?.enunciado,
          value: perguntaAtual,
        });
      }
    }

    return data;
  }, [perguntaAtual, tipoPergunta]);

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      options={options}
      onFilter={e => handleSetQuery(e.filter)}
      loading={isLoading}
      carregandoFiltro={isLoading}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
