import { useEffect, useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import HistoricoCoombs from './HistoricoCoombs';
import validationSchema from './validationSchema';

import './Coombs.scss';

const aboRhOptions = [
  { label: 'A +', value: 'A_POSITIVO' },
  { label: 'B +', value: 'B_POSITIVO' },
  { label: 'AB +', value: 'AB_POSITIVO' },
  { label: 'O +', value: 'O_POSITIVO' },
  { label: 'A -', value: 'A_NEGATIVO' },
  { label: 'B -', value: 'B_NEGATIVO' },
  { label: 'AB -', value: 'AB_NEGATIVO' },
  { label: 'O -', value: 'O_NEGATIVO' },
];

const coombsIndiretoOptions = [
  { label: 'Positivo', value: 'POSITIVO' },
  { label: 'Negativo', value: 'NEGATIVO' },
];

const Coombs = () => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { preNatalAtual } = usePreNatal();
  const { atendimentoStatus } = useAtendimento();

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [historicoCoombs, setHistoricoCoombs] = useState<any[]>([]);
  const [idCoombExcluir, setIdCoombExcluir] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const loadHistoricoCoombs = useCallback(async () => {
    if (!preNatalAtual?.id) return;

    setLoading(true);

    const response = await PreNatalAPI.getHistoricoCoombs(preNatalAtual?.id);

    if (Array.isArray(response)) {
      setHistoricoCoombs(response);
    } else {
      setHistoricoCoombs([]);
    }

    setLoading(false);
  }, [preNatalAtual?.id]);

  useEffect(() => {
    loadHistoricoCoombs();
  }, [loadHistoricoCoombs]);

  const onSubmit = async (data: any) => {
    if (!preNatalAtual?.id) return;

    const payload = {
      ...data,
      idAtendimento,
    };

    const response = await PreNatalAPI.createCoombs(preNatalAtual?.id, payload);

    if (response?.status === 201) {
      loadHistoricoCoombs();
      reset();
    }
  };

  const onDelete = async () => {
    if (!idCoombExcluir) return;

    const response = await PreNatalAPI.deleteCoombs(idCoombExcluir);

    if (response?.status === 204) loadHistoricoCoombs();

    setIdCoombExcluir(null);
  };

  return (
    <>
      <form
        className="p-grid p-col-12 p-ai-end p-px-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DropdownControlled
          className="p-col-12 p-md-4"
          control={control}
          name="aboRh"
          label="ABO/RH"
          placeholder="Tipo"
          options={aboRhOptions}
          filter={false}
          errorMsg={errors.aboRh?.message}
          disabled={!isAtendimentoRunning}
        />

        <DropdownControlled
          className="p-col-12 p-md-4 p-lg-3"
          control={control}
          name="coombsIndireto"
          label="Coombs indireto"
          placeholder="Selecione o resultado"
          options={coombsIndiretoOptions}
          filter={false}
          errorMsg={errors.coombsIndireto?.message}
          disabled={!isAtendimentoRunning}
        />

        <CalendarInputControlled
          className="p-col-12 p-md-4 p-lg-3"
          control={control}
          name="dataExame"
          label="Data do exame"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          maxDate={new Date()}
          errorMsg={errors.dataExame?.message}
          disabled={!isAtendimentoRunning}
        />

        <div className="p-col-12 p-lg-2">
          <Button
            type="submit"
            className="p-col-12"
            btnType="tonal"
            label="Adicionar"
            loading={isSubmitting}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </form>

      <HistoricoCoombs
        historicoCoombs={historicoCoombs}
        loading={loading}
        onDelete={(idCoomb: number) => setIdCoombExcluir(idCoomb)}
      />

      <ConfirmDialog
        visible={!!idCoombExcluir}
        header="Excluir Coomb"
        text="A coomb será excluída. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdCoombExcluir(null)}
        onConfirm={onDelete}
      />
    </>
  );
};

export default Coombs;
