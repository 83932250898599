import { useContext } from 'react';

import {
  MultiSelect as PrimeMultiSelect,
  MultiSelectChangeParams as PrimeMultiSelectChangeParams,
  MultiSelectProps as PrimeMultiSelectProps,
} from 'primereact/multiselect';

import ThemeContext from 'src/core/themes/ThemeContext';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './MultiSelect.scss';

export interface MultiSelectProps extends PrimeMultiSelectProps {
  label?: string | undefined;
  errorMsg?: string | any;
  loading?: boolean;
}

export type MultiSelectChangeParams = PrimeMultiSelectChangeParams;

const MultiSelect = ({
  label,
  errorMsg,
  className,
  disabled,
  loading,
  ...rest
}: MultiSelectProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={`MultiSelect ${theme?.theme || ''} ${
        errorMsg ? 'error' : ''
      } ${className || ''} p-d-flex p-flex-column p-jc-center`}
    >
      <div>
        <SimpleText>{label}</SimpleText>
      </div>

      <div className="p-mt-1">
        <PrimeMultiSelect
          disabled={disabled || !!loading}
          dropdownIcon={
            loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'
          }
          panelClassName={!rest.filter ? 'hide-header' : ''}
          {...rest}
        />
      </div>
      {errorMsg && (
        <div className="p-mt-1">
          <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
            {errorMsg}
          </SimpleText>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
