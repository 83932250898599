import { useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';

import RecuperarSenhaAPI from 'src/APIs/SecurityAPI/RecuperarSenhaAPI/RecuperarSenhaAPI';
import ValidarCodigoAPI from 'src/APIs/SecurityAPI/ValidarCodigoAPI/ValidarCodigoAPI';

import { useTimer } from 'src/utils/hooks/useTimer';

import { Button } from 'src/components/_UI';
import Card from 'src/components/Basics/Card/Card';
import { InputText } from 'src/components/Basics/Input';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { RedefinicaoSenhaForm } from './RedefinicaoSenhaForm';

import './Redefinicao.scss';

export const RedefinicaoSenha = () => {
  const { state } = useLocation();

  const isUserExterno = !!state?.isNewUser && !!state?.username;

  const [isCodigoValido, setIsCodigoValido] = useState(isUserExterno);
  const codigoValidacao = useRef('');

  const title = isUserExterno ? 'Olá, seja bem vindo!' : 'Redefinir senha';
  const subtitle = isUserExterno ? (
    <p>
      <b>Primeiro acesso!</b>
      <br />
      Para maior segurança, no seu primeiro acesso é necessário a criação de uma
      nova senha.
    </p>
  ) : (
    'Valide o código enviado no e-mail para continuar e redefinir a sua senha.'
  );

  const handleValidateCodigo = (cod: string) => {
    codigoValidacao.current = cod;
    setIsCodigoValido(true);
  };

  return (
    <div
      id="Redefinicao-portal-container"
      className="p-d-flex p-ai-center p-jc-center"
    >
      <Card className="card-redefinicao p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="lg" medium>
          {title}
        </SimpleText>
        <SimpleText className="p-line-height-2" fontColor="color_40">
          {subtitle}
        </SimpleText>

        {isCodigoValido ? (
          <RedefinicaoSenhaForm codigoValidacao={codigoValidacao.current} />
        ) : (
          <FormValidacaoCodigo handleValidateCodigo={handleValidateCodigo} />
        )}
      </Card>

      <span className="box-bottom" />
    </div>
  );
};

const FormValidacaoCodigo = ({ handleValidateCodigo }: any) => {
  const { hashEmpresa } = useParams();
  const { state } = useLocation();

  const [cod1, setCod1] = useState('');
  const [cod2, setCod2] = useState('');
  const [cod3, setCod3] = useState('');
  const [cod4, setCod4] = useState('');
  const [cod5, setCod5] = useState('');
  const [cod6, setCod6] = useState('');
  const [cod7, setCod7] = useState('');
  const [cod8, setCod8] = useState('');

  const refInput1 = useRef<any>(null);
  const refInput2 = useRef<any>(null);
  const refInput3 = useRef<any>(null);
  const refInput4 = useRef<any>(null);
  const refInput5 = useRef<any>(null);
  const refInput6 = useRef<any>(null);
  const refInput7 = useRef<any>(null);
  const refInput8 = useRef<any>(null);

  const { time, start, reset, isActive } = useTimer({
    initial: 60 * 5,
    decrement: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.email) navigate(`portal/${hashEmpresa}/login`);
  }, [hashEmpresa, navigate, state?.email]);

  const handleChangeEmail = () => {
    navigate(`/portal/${hashEmpresa}/login`, { state: 'recovery_account' });
  };

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm();

  const handleReenviar = () => {
    RecuperarSenhaAPI.sendRecuperarSenha(
      {
        login: state.email,
        codigoEmpresa: state.empresaId,
      },
      {
        throwError: true,
      },
    ).catch(() => {});
  };

  const handleSubmit = () => {
    const codigoValidacao =
      cod1 + cod2 + cod3 + cod4 + cod5 + cod6 + cod7 + cod8;

    const data = {
      email: state?.email,
      codigoValidacao,
    };

    return ValidarCodigoAPI.sendValidarCodigo(data, {
      throwError: true,
    })
      .then(() => handleValidateCodigo(codigoValidacao))
      .catch(() => {});
  };

  const handlePastedFromClipboard = (pastedData: string) => {
    const codes = pastedData.split('');
    const setCodFunctions = [
      setCod1,
      setCod2,
      setCod3,
      setCod4,
      setCod5,
      setCod6,
      setCod7,
      setCod8,
    ];
    const refInputs = [
      refInput2,
      refInput3,
      refInput4,
      refInput5,
      refInput6,
      refInput7,
      refInput8,
    ];

    setCodFunctions.forEach((setCod, index) => {
      const code = codes[index] || '';
      setCod(code);

      if (code && refInputs[index]) {
        refInputs[index]?.current.focus();
      }
    });
  };

  const isCompleted =
    !!cod1 &&
    !!cod2 &&
    !!cod3 &&
    !!cod4 &&
    !!cod5 &&
    !!cod6 &&
    !!cod7 &&
    !!cod8;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="form p-col-12 p-d-flex p-flex-column p-gap-2 p-px-0 p-mt-2"
      >
        <SimpleText medium>Código</SimpleText>
        <div className="password-container p-d-flex p-gap-2 p-col-12 p-sm-9 p-px-0 p-mb-2">
          <InputText
            ref={refInput1}
            value={cod1}
            onChange={e => {
              setCod1(e.target.value);
              if (e.target.value) {
                refInput2.current?.focus();
              }
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput2}
            value={cod2}
            onChange={e => {
              setCod2(e.target.value);
              if (e.target.value) {
                refInput3.current?.focus();
              }
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput3}
            value={cod3}
            onChange={e => {
              setCod3(e.target.value);
              if (e.target.value) {
                refInput4.current?.focus();
              }
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput4}
            value={cod4}
            onChange={e => {
              setCod4(e.target.value);
              if (e.target.value) {
                refInput5.current?.focus();
              }
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput5}
            onChange={e => {
              setCod5(e.target.value);
              if (e.target.value) {
                refInput6.current?.focus();
              }
            }}
            value={cod5}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput6}
            value={cod6}
            onChange={e => {
              setCod6(e.target.value);
              if (e.target.value) {
                refInput7.current?.focus();
              }
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput7}
            value={cod7}
            onChange={e => {
              setCod7(e.target.value);
              if (e.target.value) {
                refInput8.current?.focus();
              }
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
          <InputText
            ref={refInput8}
            value={cod8}
            onChange={e => {
              setCod8(e.target.value);
            }}
            maxLength={1}
            minLength={0}
            onPaste={e =>
              handlePastedFromClipboard(e.clipboardData.getData('Text'))
            }
          />
        </div>

        <SimpleText fontColor="color_40" medium>
          Não recebeu?
        </SimpleText>
        <SimpleText fontColor="color_40">
          Verifique a caixa de spam e aguarde alguns minutos para reenviar o
          código. Email enviado para:
        </SimpleText>
        <SimpleText bold>{state?.email}</SimpleText>

        <Button
          label="Trocar e-mail"
          btnType="green-link"
          className="p-col-3 p-px-0"
          style={{
            justifyContent: 'flex-start',
          }}
          onClick={handleChangeEmail}
        />

        {isActive && (
          <SimpleText className="error">
            Tempo para enviar novo código: {time}
          </SimpleText>
        )}

        <Button
          btnType="outline"
          label="Reenviar código"
          className="p-mt-2 p-col-3"
          disabled={isActive || form.formState.isSubmitting}
          onClick={() => [reset(), start(), handleReenviar()]}
        />

        <Button
          label="Continuar"
          type="submit"
          className="p-mt-3"
          onClick={() => {}}
          disabled={!isCompleted}
          loading={form.formState.isSubmitting}
        />
      </form>
    </FormProvider>
  );
};
