import { useCallback, useEffect, useState } from 'react';

import {
  Image as PrimeImage,
  ImageProps as PrimeImageProps,
} from 'primereact/image';
type CustomImageProps = PrimeImageProps;

const CustomImage = ({ src, ...props }: CustomImageProps) => {
  const [image, setImage] = useState('');

  const loadImage = useCallback(async () => {
    if (src && src.includes('http')) {
      setImage(src);
    } else {
      try {
        setImage(`/imgs/${src || ''}`);
      } catch (e) {
        console.log('Error locally loading image: ', e);
      }
    }
  }, [src]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  return <PrimeImage src={image} {...props} />;
};

export default CustomImage;
