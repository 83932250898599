import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { FieldServicoTiss } from '../components/FieldServicosTiss';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import { FieldSearchPaciente } from 'src/components/Fields/FieldSearchPacientes/FieldSearchPacientes';
import { FieldSearchProfissionalSaude } from 'src/components/Fields/FieldSearchProfissionalSaude/FieldSearchProfissionalSaude';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { MonitoramentoTissAdvancedFilter } from './MonitoramentoTissAdvancedFilter';

export const MonitoramentoTissFilter = () => {
  const { setFilter } = useSearchList();
  const filtersRef = useRef<OverlayPanel>(null);

  const form = useForm({
    defaultValues: {
      periodoDe: new Date(dayjs().subtract(7, 'day') as any),
      ate: new Date(),
      tentativas: 0,
      paciente: '',
    } as any,
  });

  const onSubmit = (values: any) => {
    const { profissional, ...rest } = values;
    setFilter({ ...rest, idProfissional: profissional?.id });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-col-12 p-grid p-ai-center"
      >
        <FieldSearchPaciente className="p-col-12 p-sm-6" />

        <FieldSearchProfissionalSaude
          name="profissional"
          label="Profissional"
          placeholder="Selecione o profissional de saúde"
          className="p-col-6"
        />

        <FieldServicoTiss
          name="servico"
          label="Serviços"
          className="p-col-6 p-sm-4"
        />
        <CalendarInputControlled
          control={form.control}
          name="periodoDe"
          placeholder="dd/mm/aaaa"
          label="Período de"
          className="p-col-6 p-sm-4 p-md-4 p-lg-2"
          showIcon
        />
        <CalendarInputControlled
          control={form.control}
          name="ate"
          placeholder="dd/mm/aaaa"
          label="Período de"
          className="p-col-6 p-sm-4 p-md-4 p-lg-2"
          showIcon
        />
        <div
          style={{ height: '64px' }}
          className="p-d-flex p-ai-end p-jc-between p-col-12 p-md-6 p-lg-4 p-gap-1"
        >
          <Button
            label="Pesquisar"
            className="p-col-6 p-lg-4"
            btnType="tonal"
            type="submit"
          />
          <Button
            label="Pesquisa avançada"
            icon="fas fa-chevron-down"
            type="button"
            btnType="tonal"
            iconPos="right"
            className="p-col-6"
            onClick={e => filtersRef.current?.toggle(e)}
          />
        </div>

        <MonitoramentoTissAdvancedFilter
          onSubmit={(e: any) => form.handleSubmit(onSubmit)(e)}
          ref={filtersRef}
        />
      </form>
    </FormProvider>
  );
};
