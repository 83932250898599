import { useState } from 'react';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { CheckboxSearch } from './CheckboxSearch/CheckboxSearch';

const itemTemplate = (option: any, selecionados: any[]) => {
  const selecionado = option.value?.id
    ? selecionados?.some(selecionado => selecionado.id === option.value.id) ||
      false
    : selecionados?.includes(option.value) || false;

  return (
    <div
      className={`medicos-disponibildiade-listbox-options ${
        selecionado && 'selecionado'
      }`}
    >
      <div className="p-d-flex p-ai-center">
        <Checkbox checked={selecionado} />

        <Avatar className="p-mx-3" label={option.label} />

        <SimpleText fontColor={FONT_COLOR.COLOR_16}>{option.label}</SimpleText>
      </div>

      <div className="p-d-flex p-flex-column p-text-right">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>Médico</SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          {option.value.siglaConselhoTiss}
          {option.value.numeroRegistro}
        </SimpleText>
      </div>
    </div>
  );
};

export const ModalMedico = ({ isOpen, close, onAdd, state }: any) => {
  const [medicosSelected, setMedicosSelected] = useState<any[]>([]);

  const fetchMedicos = async ({ query, ...params }: any) =>
    ProfissionalAPI.loadProfissionaisSaudeBySearch({
      nome: query,
      tipoProfissionalSaude: 'MEDICO',
      pageSize: 1000,
      ...params,
    });

  const formatMedicosOptions = (medicos: any) => {
    if (!(Array.isArray(medicos?.list) && medicos?.list.length)) return [];

    const filteredSelectedMedicos = medicos?.list.filter(
      (medico: any) =>
        !state.some(
          (medicoSelected: any) =>
            medicoSelected.idProfissionalSaude === medico.id,
        ),
    );

    return filteredSelectedMedicos.map((medico: any) => ({
      label: medico.nome,
      value: {
        idProfissionalSaude: medico.id,
        nome: medico.nome,
        siglaConselhoTiss: medico.profissionalSaude.conselhoTiss.sigla,
        numeroRegistro: medico.profissionalSaude.numeroRegistro,
      },
    }));
  };

  const handleMedicoSelected = (medicos: any[]) => {
    setMedicosSelected(medicos);
  };

  const handleAdd = () => {
    onAdd(medicosSelected);
    setMedicosSelected([]);
    close();
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className="p-col-align-center"
        onClick={close}
        stretch
      />
      <Button label="Adicionar" onClick={handleAdd} stretch />
    </div>
  );

  return (
    <Dialog
      className="modal-especialidade-disponibilidade"
      header="Adicionar profissionais na lista de restrição"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
      footer={renderFooter}
    >
      <CheckboxSearch
        value={medicosSelected}
        fetchApi={fetchMedicos}
        formatOptions={formatMedicosOptions}
        onChange={handleMedicoSelected}
        itemTemplate={itemTemplate}
      />
    </Dialog>
  );
};
