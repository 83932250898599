import { useForm } from 'react-hook-form';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

const vacinasOptions = [
  { value: 'ESQUEMA_COMPLETO', label: 'Esquema completo' },
  { value: 'ESQUEMA_INCOMPLETO', label: 'Esquema Incompleto' },
  { value: 'NAO_REALIZOU', label: 'Não realizou' },
  { value: 'SEM_INFORMACAO', label: 'Sem informação' },
];

interface VacinasProps {
  idPreNatal: number | null;
  onHide: () => void;
}

const Vacinas = ({ idPreNatal, onHide }: VacinasProps) => {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data: any) => {
    if (!idPreNatal) return;

    await PreNatalAPI.updateVacinas(idPreNatal, data);
  };

  return (
    <form
      id="vacinas-pre-natal-historico"
      className="p-grid p-col-12 p-px-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SimpleText
        className="p-col-12 p-pb-0"
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Imunização Dupla Adulto
      </SimpleText>
      <div className="p-col-12 p-pt-0">
        <RadioButtonGroupControlled
          control={control}
          name="imunizacaoDuplaAdulto"
          error={errors.imunizacaoDuplaAdulto}
          items={vacinasOptions}
        />
      </div>

      <SimpleText
        className="p-col-12 p-pb-0"
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Influenza
      </SimpleText>
      <div className="p-col-12 p-pt-0">
        <RadioButtonGroupControlled
          control={control}
          name="imunizacaoDuplaInfluenza"
          error={errors.imunizacaoDuplaInfluenza}
          items={vacinasOptions}
        />
      </div>

      <TextareaInputControlled
        className="p-col-12"
        control={control}
        name="intercorrenciaPreNatal"
        label="Intercorrências do Pré-Natal"
        maxLength={500}
        maxLengthSpan
      />

      <div className="p-col-6">
        <Button
          btnType="ghost"
          label="Cancelar"
          className="p-col-align-center"
          onClick={onHide}
          loading={isSubmitting}
          stretch
        />
      </div>
      <div className="p-col-6">
        <Button type="submit" label="Salvar" loading={isSubmitting} stretch />
      </div>
    </form>
  );
};

export default Vacinas;
