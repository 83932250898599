import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  consultorios: JSX.Element;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { data, onEdit, onDelete, consultorios } = props;

  return (
    <CardItem>
      <div className="p-col-3 p-md-4 p-lg-5">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.descricao}
        </SimpleText>
      </div>
      <div className="p-col-3 p-md-4 p-lg-5">{consultorios}</div>
      <div className="p-col-3 p-md-2 p-lg-1 ">
        <span className={`text-status ${data?.ativo ? 'active' : ''}`}>
          {data?.ativo ? 'Ativo' : 'Inativo'}
        </span>
      </div>
      <div className="p-col-3 p-md-2 p-lg-1 p-d-flex p-jc-end p-gap-2">
        <Button
          checkPermission="AGE_MOTIVO_DE_ATENDIMENTO_ALTERAR"
          btnType="gray"
          icon="pi pi-pencil"
          onClick={() => onEdit(data)}
        />

        <Button
          checkPermission="AGE_MOTIVO_DE_ATENDIMENTO_EXCLUIR"
          btnType="gray"
          icon="pi pi-trash"
          onClick={() => onDelete(data)}
          className="p-ml-1"
        />
      </div>
    </CardItem>
  );
};
