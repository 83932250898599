import Http, { HttpParams } from 'src/core/http/Http';

export const loadMotivoAtendimentoById = (
  id: number,
  idMotivoAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `motivo-atendimento/atendimento/${id}/${idMotivoAtendimento}`,
    hideToast: true,
    ...options,
  });
};

export const loadMotivoAtendimento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `motivo-atendimento/atendimento/${params.id}`,
    params,
    hideToast: true,
    ...options,
  });
};

export const postMotivoAtendimento = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: 'motivo-atendimento',
    data: payload,
    ...options,
  });
};

export const putMotivoAtendimento = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `motivo-atendimento/${payload.idMotivoAtendimento}`,
    data: payload,
    ...options,
  });
};

export const putMotivoAtendimentoInativar = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `motivo-atendimento/${payload.idAtendimento}/inativar/${payload.idTipoMotivoAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const putMotivoAtendimentoLembrete = (
  idMotivoAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `motivo-atendimento/lembrete/${idMotivoAtendimento}`,
    return: true,
    ...options,
  });
};

export const deleteMotivoAtendimento = (
  idMotivoAtendimento: number,
  id: string | undefined,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `motivo-atendimento/${idMotivoAtendimento}/${id}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

const MotivoAtendimentoAPI = {
  loadMotivoAtendimento,
  loadMotivoAtendimentoById,
  putMotivoAtendimento,
  putMotivoAtendimentoInativar,
  postMotivoAtendimento,
  deleteMotivoAtendimento,
  putMotivoAtendimentoLembrete,
};

export default MotivoAtendimentoAPI;
