import { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AssinaturaHsmAPI } from 'src/APIs/AdminAPI/AssinaturaHsm/AssinaturaHsmAPI';
import * as Yup from 'yup';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { encodeStringToBase64 } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import FormInput from 'src/components/FormInput/FormInput';

interface ModalEnviarGuiaProps extends DisclosureType {
  guia?: any;
  close: any;
  isOpen: boolean;
  handleEnviarGuiasPorEmail: (email: string) => void;
  loading: boolean;
  loadingAssinatura?: boolean;
}

function ModalEnviarGuia({
  close,
  isOpen,
  guia,
  handleEnviarGuiasPorEmail,
  loading,
  loadingAssinatura,
}: ModalEnviarGuiaProps) {
  // colocar validação consicional quando houver uma guia. Exemplo abaixo:
  const { agenda } = useAppSelector((state: RootState) => state);

  const { paciente } = useAtendimento();
  const { isHsm, hsmPIN, hsmValidDate } = useAssinatura();

  const hasCertificadoAtivo = useMemo(() => {
    return hsmValidDate && hsmPIN;
  }, [hsmValidDate, hsmPIN]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail não é valido')
      .required('E-mail é obrigatório'),
    ...(!hasCertificadoAtivo &&
      isHsm && {
        pin: Yup.string().required('PIN é obrigatório'),
      }),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    try {
      if (!hasCertificadoAtivo && isHsm) {
        await AssinaturaHsmAPI.putPinHSM(
          agenda?.profissionalAtivo?.id,
          { hsmSenha: encodeStringToBase64(data.pin), hsmHorasVigencia: null },
          { throwError: true, hideToast: true },
        );
      }
      handleEnviarGuiasPorEmail(data.email);
    } catch (error) {
      console.error('Erro ao enviar por email:', error);
    }
  };

  useEffect(() => {
    if (paciente?.email) {
      setValue('email', paciente?.email);
    }
  }, [paciente?.email, setValue]);

  return (
    <ErrorBoundary>
      <Dialog
        id="modalEnviarGuia"
        visible={isOpen}
        onHide={() => close()}
        header="Enviar guia por e-mail"
        maximizedMobileSize
        style={{ minWidth: '415px' }}
      >
        <FormProvider {...useFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleText
              className="p-mb-2"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XS}
            >
              {/* Consicional 'Informe o e-mal e a senha PIN para enviar a guia.' : Informe o e-mail para enviar a guia.' */}
              Informe o e-mail para enviar a guia.
            </SimpleText>

            <FormInput
              className="p-my-2"
              name="email"
              label="E-mail"
              placeholder="Informe o e-mail para o qual a guia deverá ser enviada"
              errorMsg={errors.email?.message}
            />

            {!hasCertificadoAtivo && isHsm && (
              <FormInput
                name="pin"
                label="PIN"
                placeholder="Informe a senha PIN de assinatura de documentos"
                errorMsg={errors.pin?.message}
              />
            )}

            <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
              <Button
                stretch
                btnType="ghost"
                label="Cancelar"
                onClick={() => close()}
              />
              <Button
                stretch
                type="submit"
                label="Enviar"
                loading={loading || isSubmitting || !!loadingAssinatura}
              />
            </div>
          </form>
        </FormProvider>
      </Dialog>
    </ErrorBoundary>
  );
}

export default ModalEnviarGuia;
