import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const tiposItem: any = {
  RECEITA: 'Receita',
  RECEITA_OCULOS: 'Receita Oculos',
  ORIENTACAO: 'Orientação',
  DECLARACAO: 'Declaração',
  ATESTADO: 'Atestado',
};

export const ReceitasOrientacoesRow = ({ item }: any) => {
  const { isTablet } = useSize();

  const hasQuantidade = item?.tipo === 'RECEITA' && !!item.quantidade;

  return (
    <div className={`content-row p-d-flex`}>
      <div
        className={`p-col-${
          hasQuantidade ? '1' : '2'
        } p-d-flex p-flex-column p-gap-2`}
      >
        <SimpleText
          className="p-text-truncate"
          fontColor="color_40"
          fontSize="xxs"
        >
          Item
        </SimpleText>
        <SimpleText fontColor="primary">
          {tiposItem[item.tipo] || item.tipo}
        </SimpleText>
      </div>
      {hasQuantidade && (
        <div className="p-col-1 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_40" fontSize="xxs">
            {isTablet ? 'Qtde' : 'Quantidade'}
          </SimpleText>
          <SimpleText>{item.quantidade}</SimpleText>
        </div>
      )}
      <div className="p-col-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Posologia
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.posologia || '-'}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Profissional
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.profissional?.nome}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Data da ocorrência
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {!!item?.dataOcorrencia &&
            dayjs(item?.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-3 p-d-flex p-jc-end p-ai-center">
        <Button
          label="Receita"
          icon="fas fa-pills"
          btnType="tonal"
          onClick={() => window.open(item.link, '_blank')}
          style={{
            maxHeight: '35px',
          }}
        />
      </div>
    </div>
  );
};

export const ReceitasOrientacoesRowMobile = ({ item }: any) => {
  const hasQuantidade = item?.tipo === 'RECEITA' && !!item.quantidade;

  return (
    <div className={`content-row p-d-flex p-flex-column`}>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Item
        </SimpleText>
        <SimpleText fontColor="primary">
          {tiposItem[item.tipo] || item.tipo}
        </SimpleText>
      </div>
      {hasQuantidade && (
        <div className="p-col-1 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_40" fontSize="xxs">
            Quantidade
          </SimpleText>
          <SimpleText>{item.quantidade}</SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Posologia
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.posologia || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Profissional
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.profissional?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Data da ocorrência
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {!!item?.dataOcorrencia &&
            dayjs(item?.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-jc-end">
        <Button
          label="Receita"
          icon="fas fa-pills"
          btnType="tonal"
          onClick={() => window.open(item.link, '_blank')}
          stretch
        />
      </div>
    </div>
  );
};
