import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import { HistoricoAtendimentos } from './HistoricoAtendimentos/HistoricoAtendimentos';
import BarraLateralProntuario from './IniciarAtendimento/BarraLateralProntuario/BarraLateralProntuario';
import IniciarAtendimento from './IniciarAtendimento/IniciarAtendimento';
import { Telemedicina } from './Telemedicina/Telemedicina';

import './Atendimento.scss';

interface AtendimentoProps {
  usuario: GetUsuarioDTO | null;
  pacienteObito: boolean;
  carregandoDados: boolean;
  idPaciente: number | null;
}

const Atendimento = ({
  usuario,
  pacienteObito,
  carregandoDados,
  idPaciente,
}: AtendimentoProps) => {
  const { showBarraLateral, isTeleatendimento } = useAtendimento();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: ['pro_alterar_prontuario', 'pro_visualizar_prontuario'],
  });

  if (carregandoDados) {
    return <SpinnerLoading full className="p-mt-4" />;
  }

  return (
    <div id="atendimento-container" className="p-grid p-p-0">
      <div
        className={
          showBarraLateral || isTeleatendimento
            ? 'p-sm-9 atendimento-e-historico'
            : 'p-sm-11 atendimento-e-historico'
        }
      >
        <IniciarAtendimento
          usuario={usuario}
          carregandoDados={carregandoDados}
          disabled={pacienteObito}
          idPaciente={idPaciente}
        />

        <HistoricoAtendimentoProvider>
          <HistoricoAtendimentos usuario={usuario} idPaciente={idPaciente} />
        </HistoricoAtendimentoProvider>
      </div>
      <div
        id="barra-lateral-prontuario"
        className={showBarraLateral || isTeleatendimento ? 'p-sm-3' : 'p-sm-1'}
      >
        {isTeleatendimento && <Telemedicina />}
        <BarraLateralProntuario idPaciente={idPaciente} />
      </div>

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default Atendimento;
