import React, { useCallback, useEffect, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useLocation, useParams } from 'react-router';

import dayjs from 'dayjs';
import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Separator from 'src/components/Separator/Separator';
import Tooltip from 'src/components/Tooltip/Tooltip';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';

import ModalRevogarSigilo from 'src/pages/Emed/Agenda/Sigilo/ModalRevogarSigilo/ModalRevogarSigilo';
import ModalSigilo from 'src/pages/Emed/Agenda/Sigilo/ModalSigilo/ModalSigilo';

import { ModalHistoricoCompleto } from '../../../HistoricoAtendimentos/ModalHistoricoCompleto';

import './Evolucao.scss';
import { aleitamentosTypes } from '../../../HistoricoAtendimentos/Historicos/Resumo/utils';

export const Evolucao = () => {
  const [todosAtendimentos, setTodosAtendimentos] = useState(true);
  const [updateList, setUpdateList] = useState<number>(0);

  const options = [
    { label: 'Todos', value: true },
    { label: 'Meus atendimentos', value: false },
  ];

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { setSelectedAtendimento } = useHistoricoAtendimento();

  const historicoCompletoDisclosure = useDisclosure({ opened: false });
  const justificativaDisclosure = useDisclosure({ opened: false });

  const onExibirAtendimentoCompleto = useCallback(
    (atendimento: any) => {
      setSelectedAtendimento(atendimento);
      historicoCompletoDisclosure.open();
    },
    [historicoCompletoDisclosure, setSelectedAtendimento],
  );

  const onInativarAtendimento = useCallback(
    (atendimento: any) => {
      setSelectedAtendimento(atendimento);
      justificativaDisclosure.open();
    },
    [justificativaDisclosure, setSelectedAtendimento],
  );

  const fetchApi = useCallback(
    async (params?: any) => {
      const response = await HistoricoAtendimentosAPI.getHistoricoSOAP(
        Number(idAtendimento),
        {
          todosAtendimentos: todosAtendimentos,
          sortBy: 'atendimento.dataOcorrencia',
          sortDirection: 'DESC',
          ...params,
        },
      );

      return response;
    },
    [idAtendimento, todosAtendimentos],
  );

  return (
    <div className="p-grid">
      {/* if parametro geral */}
      <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
        <SelectButton
          className="select-soap"
          options={options}
          value={todosAtendimentos}
          onChange={e => setTodosAtendimentos(e.value)}
          unselectable={false}
        />
      </div>
      {/* ate aqui */}
      <div className="p-col-12">
        <InfiniteScroll
          fetchAPI={fetchApi}
          renderRow={item => (
            <RenderRow
              atendimento={item}
              onExibirAtendimentoCompleto={onExibirAtendimentoCompleto}
              fetchAPI={() => setUpdateList(old => old + 1)}
            />
          )}
          updateList={updateList}
        />
      </div>
      <ModalHistoricoCompleto
        initial={'RESUMO'}
        justificativaDisclosure={justificativaDisclosure}
        onInativarAtendimento={onInativarAtendimento}
        reload={() => setUpdateList(old => old + 1)}
        {...historicoCompletoDisclosure}
      />
    </div>
  );
};

const RenderRow = ({
  atendimento,
  onExibirAtendimentoCompleto,
  fetchAPI,
}: any) => {
  const [paciente, setPaciente] = useState<any>(null);

  const { agenda } = useAppSelector((state: RootState) => state);

  const menuOptions = useRef<any>();

  const modalSigilo = useDisclosure({ opened: false });
  const modalRevogarSigilo = useDisclosure({ opened: false });

  const { aleitamento, alimentacaoVariada, aleitamentoObservacao } =
    atendimento;

  const hasAllAleitamentos = !!(aleitamento && alimentacaoVariada);
  const hasAleitamento = !!(
    aleitamento ||
    alimentacaoVariada ||
    aleitamentoObservacao
  );

  const ativoSOAP = (soap: string, atendimento: any) => {
    switch (soap) {
      case 'S':
        if (
          atendimento?.motivoAtendimento ||
          atendimento?.anamnese ||
          hasAleitamento
        ) {
          return true;
        }
        break;
      case 'O':
        if (atendimento?.exameFisico) {
          return true;
        }
        break;
      case 'A':
        if (
          atendimento?.ciap ||
          atendimento?.patologiaDetectada ||
          atendimento?.decisaoTerapeutica ||
          atendimento?.prognostico
        ) {
          return true;
        }
        break;
      case 'P':
        if (
          atendimento?.exames ||
          atendimento?.receitas ||
          atendimento?.prescricoes ||
          atendimento?.encaminhamentos ||
          atendimento?.pedidoCuidados
        ) {
          return true;
        }
        break;
      default:
        break;
    }

    return false;
  };

  const handleTypeIconSigilo = (nvlSigilo: any) => {
    if (nvlSigilo === 0) {
      return 'fa-solid fa-lock';
    }
    return 'fa-solid fa-lock-open';
  };

  const handleColorIconSigilo = (nvlSigilo: any) => {
    if (nvlSigilo === 0) {
      return ' red';
    }
    if (nvlSigilo === 1) {
      return ' yellow';
    }
    return ' green';
  };

  const handleLiberarSigilo = (paciente: any) => {
    setPaciente(paciente);
    modalSigilo.open();
  };

  const nivelSigilo = atendimento?.atendimento?.nivelSigilo;

  const pacienteSigilo = {
    paciente,
    sigilo: atendimento.atendimento?.sigilo,
  };

  const isAtendimentoMedicoAtual =
    atendimento?.atendimento?.medico?.id === agenda?.profissionalAtivo?.id;

  if (
    !isAtendimentoMedicoAtual &&
    atendimento?.atendimento?.nivelSigilo === 0
  ) {
    return (
      <div className="p-grid">
        <div className="p-d-flex p-col-8 p-md-12 p-lg-9 p-ai-center p-my-2 p-p-0 ">
          <Avatar
            style={{ minWidth: '30px' }}
            label={atendimento?.atendimento.medico.nome}
          />
          <div className="p-d-flex p-flex-column p-ml-2">
            <div className="p-d-flex p-flex-column p-ai-start p-jc-left">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {atendimento?.atendimento.medico.nome}
              </SimpleText>
              <SimpleText
                className="p-js-start p-mt-1"
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                (CRM {atendimento?.atendimento.medico.numeroConselho}
                {' • '}
                {atendimento?.atendimento.medico.estadoConselho}
                {atendimento?.atendimento.especialidade
                  ? ' • ' + atendimento?.atendimento.especialidade.nome
                  : ''}
                )
              </SimpleText>
            </div>
            <div className="p-d-flex p-mt-1 p-ai-center">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                ({'Em '}
                {atendimento?.atendimento?.dataAgendamento
                  ? `${dayjs(atendimento?.atendimento.dataAgendamento).format(
                      'DD/MM/YYYY',
                    )} às ${dayjs(
                      atendimento?.atendimento.dataAgendamento,
                    ).format('HH:mm')}`
                  : 'hora não informada'}{' '}
                -{' '}
                {atendimento?.atendimento.status === 'TRIAGEM_FINALIZADA'
                  ? dayjs(
                      atendimento?.atendimento.horaAtendimentoTriagem,
                    ).format('HH:mm')
                  : atendimento?.atendimento.horaSaida
                  ? dayjs(atendimento?.atendimento.horaSaida).format('HH:mm')
                  : 'Não finalizado'}
                ) {atendimento?.status === 'INATIVO' && '- Inativado'}
                {atendimento?.atendimento.status === 'TRIAGEM_FINALIZADA' &&
                  '- Triagem'}
              </SimpleText>

              {!isAtendimentoMedicoAtual && (
                <i
                  onClick={() =>
                    handleLiberarSigilo(atendimento?.atendimento?.paciente)
                  }
                  className={
                    handleTypeIconSigilo(nivelSigilo ? nivelSigilo : 0) +
                    handleColorIconSigilo(nivelSigilo ? nivelSigilo : 0) +
                    ' p-ml-2'
                  }
                  style={{ cursor: 'pointer' }}
                ></i>
              )}
            </div>
          </div>
        </div>
        <div className="p-col-4 p-md-12 p-lg-3 p-d-flex p-jc-end">
          <Button
            id="options"
            onClick={event => menuOptions.current.toggle(event)}
            icon={'pi pi-ellipsis-h'}
            btnType="gray"
          />

          <TieredMenu
            model={[
              {
                label: 'Solicitar liberação de sigilo',
                command: () => {
                  atendimento?.atendimento?.nivelSigilo === 0
                    ? handleLiberarSigilo(atendimento?.atendimento?.paciente)
                    : null;
                },
              },
            ]}
            popup
            ref={menuOptions}
            style={{ width: '30%' }}
          />
        </div>

        {modalSigilo.isOpen && (
          <ModalSigilo
            onSuccess={() => [fetchAPI(), modalSigilo.close()]}
            paciente={paciente}
            visible={modalSigilo.isOpen}
            onCancel={() => [modalSigilo.close()]}
          />
        )}
      </div>
    );
  } else {
    return (
      <>
        <div className="p-w-100 atendimento-soap">
          <div className="p-col-12 p-d-flex p-flex-column p-gap-2 dados-atendimento">
            {atendimento?.atendimento?.justificativaOcorrencia && (
              <div className="retroativo-chip p-w-100">
                <SimpleText
                  id={`justificativa-retroatividade-${atendimento?.atendimento?.id}`}
                  className="justificativa-retroatividade"
                  fontSize={FONT_SIZE.XXXS}
                >
                  Retroativo
                </SimpleText>
                <Tooltip
                  className="tooltip-justificativa-retroatividade"
                  target={`#justificativa-retroatividade-${atendimento?.atendimento?.id}`}
                  position="top"
                  content={`Justificativa para registro retroativo:\n${
                    atendimento?.atendimento?.justificativaOcorrencia
                  }\nAdicionado por:\n${
                    atendimento?.atendimento?.medico.nome
                  }\nem ${dayjs(
                    atendimento?.atendimento?.dataOcorrencia,
                  ).format(`DD/MM/YYYY [às] HH:mm`)} `}
                />
              </div>
            )}

            <div className="p-d-flex p-jc-between p-ai-center">
              <div className="p-d-flex p-flex-column p-gap-1">
                <SimpleText fontSize={FONT_SIZE.XS} medium>
                  Data da ocorrência:
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {!!atendimento?.atendimento?.dataOcorrencia
                    ? dayjs(atendimento?.atendimento?.dataOcorrencia).format(
                        `DD/MM/YYYY [às] HH:mm`,
                      )
                    : '-'}
                </SimpleText>
                <SimpleText className="p-mt-1" fontSize={FONT_SIZE.XS} medium>
                  Profissional:
                </SimpleText>
                <SimpleText fontSize={FONT_SIZE.XS}>
                  {atendimento?.atendimento?.medico?.nome}
                </SimpleText>
              </div>

              {!isAtendimentoMedicoAtual && (
                <Button
                  btnType={nivelSigilo === 1 ? 'warn' : 'green-link'}
                  icon={handleTypeIconSigilo(
                    atendimento?.atendimento?.nivelSigilo,
                  )}
                  onClick={() => modalRevogarSigilo.open()}
                />
              )}
            </div>

            <div className="p-w-100 p-d-flex p-flex-column">
              {atendimento?.cids?.map((cid: any, index: number) => (
                <div key={index} className="p-d-flex p-my-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    CID:
                  </SimpleText>
                  <SimpleText
                    className="p-as-center"
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {`${cid.versao} - ${cid.codigo} - ${cid.nome}`}
                  </SimpleText>
                </div>
              ))}
            </div>
          </div>
          {ativoSOAP('S', atendimento) && (
            <>
              <div className="p-col-12 p-d-flex soap-header p-p-2 p-my-1">
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.PRIMARY}
                >
                  S
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  O
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  A
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  P
                </SimpleText>
              </div>
              {atendimento?.motivoAtendimentos && (
                <>
                  {atendimento?.motivoAtendimentos?.map(
                    (motivo: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="p-col-12 p-d-flex p-p-2 p-my-1"
                        >
                          {motivo?.justificativaOcorrenciaRetroativa && (
                            <div className="retroativo-chip p-col-12">
                              <SimpleText
                                id={`justificativa-retroatividade-${motivo?.id}`}
                                className="justificativa-retroatividade"
                                fontSize={FONT_SIZE.XXXS}
                              >
                                Retroativo
                              </SimpleText>
                              <Tooltip
                                className="tooltip-justificativa-retroatividade"
                                target={`#justificativa-retroatividade-${motivo?.id}`}
                                position="top"
                                content={`Justificativa para registro retroativo:\n${
                                  motivo?.justificativaOcorrenciaRetroativa
                                }\nAdicionado por:\n${
                                  motivo?.medico.nome
                                }\nem ${dayjs(motivo?.dataOcorrencia).format(
                                  `DD/MM/YYYY [às] HH:mm`,
                                )} `}
                              />
                            </div>
                          )}
                          <SimpleText
                            className="p-mr-1"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            Motivo do atendimento:
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.COLOR_40}
                          >
                            {motivo?.descricaoMotivo}
                          </SimpleText>
                        </div>
                      );
                    },
                  )}
                  <Separator layout="horizontal" />
                </>
              )}

              {atendimento?.anamnese && (
                <div className="p-col-12 p-d-flex p-flex-column p-p-2 p-my-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Anamnese:
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {atendimento?.anamnese}
                  </SimpleText>
                </div>
              )}
              <Separator layout="horizontal" />

              {hasAleitamento && (
                <div className="p-d-flex p-flex-column p-p-2 p-my-1 p-gap-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    {hasAllAleitamentos
                      ? 'Aleitamento e alimentação'
                      : 'Aleitamento'}
                  </SimpleText>
                  <SimpleText>
                    {aleitamento ? aleitamentosTypes[aleitamento] : ''}
                    {hasAllAleitamentos ? ' e ' : aleitamento ? ' ' : ''}
                    {alimentacaoVariada ? 'Alimentação variada. ' : ''}
                    {aleitamentoObservacao || ''}
                  </SimpleText>
                </div>
              )}
            </>
          )}
          {ativoSOAP('O', atendimento) && (
            <>
              <div className="p-col-12 p-d-flex soap-header p-p-2 p-my-1">
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  S
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.PRIMARY}
                >
                  O
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  A
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  P
                </SimpleText>
              </div>
              {atendimento?.exameFisico && (
                <div className="p-col-12 p-d-flex p-flex-column p-p-2 p-my-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Exame Físico:
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {atendimento?.exameFisico}
                  </SimpleText>
                </div>
              )}
              <Separator layout="horizontal" />
            </>
          )}
          {ativoSOAP('A', atendimento) && (
            <>
              <div className="p-col-12 p-d-flex soap-header p-p-2 p-my-1">
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  S
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  O
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.PRIMARY}
                >
                  A
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  P
                </SimpleText>
              </div>
              {atendimento?.ciap && (
                <>
                  {atendimento.ciap.map((ciap: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className="p-col-12 p-d-flex p-flex-column p-p-2 p-my-1"
                      >
                        <SimpleText
                          className="p-mr-1"
                          fontSize={FONT_SIZE.XS}
                          medium
                        >
                          CIAP:
                        </SimpleText>
                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_40}
                        >
                          ({ciap.codigo}) - {capitalizeFirstLetter(ciap.nome)}
                        </SimpleText>
                      </div>
                    );
                  })}
                  <Separator layout="horizontal" />
                </>
              )}
              {atendimento?.patologiaDetectada && (
                <div className="p-col-12 p-d-flex p-flex-column p-p-2 p-my-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Patologia detectada:
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {atendimento?.patologiaDetectada}
                  </SimpleText>
                  <Separator layout="horizontal" />
                </div>
              )}
              {atendimento?.prognostico && (
                <div className="p-col-12 p-d-flex p-flex-column p-p-2 p-my-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Prognóstico:
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {atendimento?.prognostico}
                  </SimpleText>
                  <Separator layout="horizontal" />
                </div>
              )}
              {atendimento?.decisaoTerapeutica && (
                <div className="p-col-12 p-d-flex p-flex-column p-p-2 p-my-1">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Decisão terapêutica:
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {atendimento?.decisaoTerapeutica}
                  </SimpleText>
                  <Separator layout="horizontal" />
                </div>
              )}
            </>
          )}
          {ativoSOAP('P', atendimento) && (
            <>
              <div className="p-col-12 p-d-flex soap-header p-p-2 p-my-1">
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  S
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  O
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_79}
                >
                  A
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.PRIMARY}
                >
                  P
                </SimpleText>
              </div>
              {/* EXAMES ainda nao estao implementados */}
              {/* {atendimento?.exames && (
                <>
                  <p>{atendimento?.exames}</p>
                </>
              )} */}
              {atendimento?.receitas && (
                <div className="p-d-flex p-flex-column">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Receitas:
                  </SimpleText>
                  {atendimento?.receitas?.map((receita: any, index: any) => (
                    <div key={index}>
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_40}
                        className="p-mt-1"
                      >
                        {receita.medicamento}
                      </SimpleText>
                    </div>
                  ))}
                  <Separator layout="horizontal" />
                </div>
              )}
              {atendimento?.prescricoes && (
                <div className="p-col-12 p-d-flex p-flex-column">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Prescrições:
                  </SimpleText>
                  {atendimento?.prescricoes?.map(
                    (prescricao: any, index: any) => (
                      <div key={index}>
                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_40}
                          className="p-mt-1"
                        >
                          {prescricao.descricao}
                        </SimpleText>
                      </div>
                    ),
                  )}
                  <Separator layout="horizontal" />
                </div>
              )}
              {atendimento?.encaminhamentos && (
                <div className="p-d-flex p-flex-column">
                  <SimpleText className="p-mr-1" fontSize={FONT_SIZE.XS} medium>
                    Encaminhamento:
                  </SimpleText>
                  {atendimento?.encaminhamentos?.map(
                    (encaminhamento: any, index: any) => (
                      <div key={index} className="p-d-flex p-flex-column">
                        <div className="p-d-flex">
                          <SimpleText
                            className="p-mr-1"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            Especialidade:
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.COLOR_40}
                          >
                            {encaminhamento?.nomeEspecialidade}
                          </SimpleText>
                        </div>
                        <div className="p-d-flex">
                          <SimpleText
                            className="p-mr-1"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            Tipo encaminhamento:
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.COLOR_40}
                          >
                            {encaminhamento?.descricaoTipoEncaminhamento}
                          </SimpleText>
                        </div>
                        <div>
                          <SimpleText
                            className="p-mr-1"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            Questionamento:
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.COLOR_40}
                          >
                            {encaminhamento?.questionamento}
                          </SimpleText>
                        </div>
                      </div>
                    ),
                  )}
                  <Separator layout="horizontal" />
                </div>
              )}
              {atendimento?.pedidoCuidados && (
                <>
                  {atendimento.pedidoCuidados.map((pedido: any, index: any) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="p-col-12 p-d-flex p-flex-column p-p-2 p-gap-2"
                        >
                          <SimpleText
                            className="p-mr-1"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            Pedido de cuidado
                          </SimpleText>
                          <SimpleText
                            className="p-mx-1"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            {pedido.programado
                              ? 'Programado'
                              : 'Próximo atendimento'}
                          </SimpleText>
                        </div>
                        {pedido.programado && (
                          <div className="p-col-12 p-d-flex">
                            <SimpleText
                              className="p-mr-1 p-ml-2"
                              fontSize={FONT_SIZE.XS}
                              medium
                            >
                              Tipo de cuidado:
                            </SimpleText>
                            <SimpleText
                              fontSize={FONT_SIZE.XS}
                              fontColor={FONT_COLOR.COLOR_40}
                            >
                              {pedido.tipoCuidado.descricao}
                            </SimpleText>
                          </div>
                        )}
                        <div className="p-col-12 p-d-flex">
                          <SimpleText
                            className="p-mr-1 p-ml-2"
                            fontSize={FONT_SIZE.XS}
                            medium
                          >
                            Descrição:
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.COLOR_40}
                          >
                            {pedido.observacaoSolicitante}
                          </SimpleText>
                        </div>
                        <Separator layout="horizontal" />
                      </>
                    );
                  })}
                </>
              )}
            </>
          )}
          <div className="col-12 p-mb-2 p-mt-1">
            <Button
              type="button"
              label="Exibir completo"
              onClick={() => onExibirAtendimentoCompleto(atendimento)}
              stretch
            />
            <Separator layout="horizontal" />
          </div>
        </div>

        {modalRevogarSigilo.isOpen && (
          <ModalRevogarSigilo
            onSuccess={() => [fetchAPI(), modalRevogarSigilo.close()]}
            paciente={pacienteSigilo}
            visible={modalRevogarSigilo.isOpen}
            onCancel={() => [modalRevogarSigilo.close()]}
          />
        )}
      </>
    );
  }
};
