import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHBContentProps {
  idGestacao: number;
  historicoReagentes: any;
}

export default function PanelHBContent({
  idGestacao,
  historicoReagentes,
}: PanelHBContentProps) {
  return (
    <>
      {historicoReagentes?.hbPrimeiroTrimestre ||
      historicoReagentes?.hbSegundoTrimestre ||
      historicoReagentes?.hbTerceiroTrimestre ? (
        <div className="p-grid">
          {historicoReagentes?.hbPrimeiroTrimestre && (
            <div className="p-grid p-col-12 item-historico p-p-1 p-mb-1">
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Exame
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  HB
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Trimestre
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  1º Trimestre
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Resultado
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {historicoReagentes?.hbPrimeiroTrimestre}
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Data da inclusão
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {dayjs(new Date(historicoReagentes?.dataInclusao)).format(
                    'DD/MM/YYYY',
                  )}
                </SimpleText>
              </div>
            </div>
          )}
          {historicoReagentes?.hbTerceiroTrimestre && (
            <div className="p-grid p-col-12 item-historico p-p-1 p-mb-1">
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Exame
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  HB
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Trimestre
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  3º Trimestre
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Resultado
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {historicoReagentes?.hbTerceiroTrimestre}
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Data da inclusão
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {dayjs(new Date(historicoReagentes?.dataInclusao)).format(
                    'DD/MM/YYYY',
                  )}
                </SimpleText>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="p-d-flex p-ai-center p-jc-center p-p-2">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XXS}
            medium
          >
            Nenhum resultado encontrado
          </SimpleText>
        </div>
      )}
    </>
  );
}
