import { useEffect, useState } from 'react';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

type Options = {
  label: string;
  value: number;
};

export interface FederativeUnitData {
  stateOptions: Options[];
}

export const useFederativeUnit = (): FederativeUnitData => {
  const [stateOptions, setStateOptions] = useState<Options[]>([]);

  const fetchStates = async () => {
    const statesData = await UtilsAPI.GetEstados();

    if (Array.isArray(statesData)) {
      const formattedStates = statesData.map(state => ({
        label: state.nome,
        value: state.id,
      }));

      setStateOptions(formattedStates);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  return {
    stateOptions,
  };
};
