import { useCallback, useContext } from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';
import useTheme from 'src/core/themes/useTheme';

import Button from 'src/components/Basics/Button/Buttons';
import PasswordInput from 'src/components/Basics/PasswordInput/PasswordInput';
import TextInput from 'src/components/Basics/TextInput/TextInput';

import './ComponentsTests.scss';

const ComponentsTests = () => {
  const theme = useContext(ThemeContext);
  const { toogleTheme } = useTheme();

  const toggleTheme = useCallback(() => {
    if (toogleTheme) toogleTheme();
  }, [toogleTheme]);

  return (
    <div className={`ComponentsTests ${theme?.theme || ''}`}>
      <h2>1. Pill Contrast button</h2>
      <div>
        <p>Enabled</p>
        <Button
          label="Normal button"
          btnType="pill"
          icon="pi pi-book"
          onClick={toggleTheme}
        />

        <p>Disabled</p>
        <Button
          label="Disabled button"
          btnType="pill"
          icon="pi pi-book"
          disabled
        />

        <p>Loading</p>
        <Button btnType="pill" loading />
      </div>

      <h2>2. Filled button (Default)</h2>
      <div>
        <p>Enabled</p>
        <Button label="Normal button" icon="pi pi-book" />

        <p>Disabled</p>
        <Button label="Disabled button" icon="pi pi-book" disabled />

        <p>Loading</p>
        <Button loading />
      </div>

      <h2>3. Tonal button</h2>
      <div>
        <p>Enabled</p>
        <Button label="Normal button" btnType="tonal" icon="pi pi-book" />

        <p>Disabled</p>
        <Button
          label="Disabled button"
          btnType="tonal"
          icon="pi pi-book"
          disabled
        />

        <p>Loading</p>
        <Button btnType="tonal" loading />
      </div>

      <h2>4. Outline button</h2>
      <div>
        <p>Enabled</p>
        <Button label="Normal button" btnType="outline" icon="pi pi-book" />

        <p>Disabled</p>
        <Button
          label="Disabled button"
          btnType="outline"
          icon="pi pi-book"
          disabled
        />

        <p>Loading</p>
        <Button btnType="outline" loading />
      </div>

      <h2>5. Ghost button</h2>
      <div>
        <p>Enabled</p>
        <Button label="Normal button" btnType="ghost" icon="pi pi-book" />

        <p>Disabled</p>
        <Button
          label="Disabled button"
          btnType="ghost"
          icon="pi pi-book"
          disabled
        />
      </div>

      <h2>6. Text Input</h2>
      <div>
        <p>Enabled</p>
        <TextInput label="Label" value={'Enabled Text'} />

        <p>Disabled</p>
        <TextInput label="Label" disabled={true} value={'Disabled Text'} />

        <p>Enabled Error</p>
        <TextInput
          label="Label"
          value={'Enabled Error Text'}
          errorMsg={'Error!'}
        />

        <p>Disabled Error</p>
        <TextInput
          label="Label"
          disabled={true}
          value={'Disabled Error Text'}
          errorMsg={'Disabled Error!'}
        />

        <p>Placeholder</p>
        <TextInput label="Label" placeholder={'Placeholder!'} />

        <p>Placeholder Disabled</p>
        <TextInput
          label="Label"
          disabled={true}
          placeholder={'Disabled Placeholder!'}
        />
      </div>
      <h2>6. Password Input</h2>
      <div>
        <PasswordInput label="Label" value={'Enabled'} />
        <PasswordInput label="Label" value={'Disabled'} disabled />
      </div>
    </div>
  );
};

export default ComponentsTests;
