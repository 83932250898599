import { memo } from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './DeleteRoom.scss';

interface DeleteRoomProps {
  visible: boolean;
  onHide(): void;
  handleRemove(): void;
}

const DeleteRoom = ({ visible, onHide, handleRemove }: DeleteRoomProps) => {
  return (
    <Dialog
      type="modal"
      header="Excluir sala"
      visible={visible}
      onHide={onHide}
    >
      <>
        <SimpleText fontSize={FONT_SIZE.XS}>
          Ao excluir a sala, ela não poderá mais ser utilizada para agendar
          consultas. Você confirma esta ação?
        </SimpleText>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button btnType={'ghost'} label="Cancelar" onClick={onHide} stretch />
          <Button
            className={'p-col-align-center p-d-flex button-cancel'}
            label="Confirmo, excluir"
            onClick={handleRemove}
            stretch
          />
        </div>
      </>
    </Dialog>
  );
};

export default DeleteRoom;
