import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

interface Filter {
  [key: string]: string | number;
}

interface SearchListData {
  listItems: any[];
  query: string;
  loading: boolean;
  hasMore: boolean;
  page: number;
  totalCount: number;
  filter: any;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  fetchNextPage: () => Promise<void>;
  reloadList: () => void;
}

const SearcListContext = createContext({} as SearchListData);

interface SearchListProps {
  children: JSX.Element | JSX.Element[];
  reloadWhenEmpresaChange: boolean;
  reloadWhenConsultorioChange: boolean;
  paginatedList: boolean;
  fetchApi: (params?: any) => Promise<any>;
  lastItem?: any;
  onlyQueryLength?: number | undefined;
}

const SearchListProvider = (props: SearchListProps) => {
  const {
    children,
    reloadWhenEmpresaChange,
    reloadWhenConsultorioChange,
    paginatedList,
    fetchApi,
    lastItem,
    onlyQueryLength,
  } = props;
  7;

  const { user, consultorios } = useAppSelector((state: RootState) => state);

  const page = useRef(0);
  const prevIdEmpresa = useRef();
  const prevConsultorioAtivoId = useRef();

  const [listItems, setListItems] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState({} as Filter);
  const [reload, setReload] = useState<number>(0);

  useEffectSkipFirst(() => {
    page.current = 0;
  }, [query, filter]);

  const fetch = useCallback(async () => {
    try {
      if (onlyQueryLength !== undefined && onlyQueryLength > query.length) {
        return [];
      }

      const response = await fetchApi({
        query,
        ...(paginatedList ? { page: page.current } : {}),
        ...filter,
      });

      if (!response.list) {
        lastItem && lastItem(response[0]);
        setTotalCount(response.length);
        return response;
      }

      setTotalCount(response.totalCount ?? 0);
      setHasMore(!response.last);

      lastItem && lastItem(response.list[0]);
      return response.list;
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filter, fetchApi]);

  const fetchNextPage = async () => {
    page.current += 1;

    const response = await fetch();

    if (!!response) {
      setListItems(prevListItems => prevListItems.concat(response));
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchItens = async () => {
      const response = await fetch();

      setListItems(response);
    };

    fetchItens();
  }, [fetch, reload]);

  const reloadList = useCallback(() => {
    page.current = 0;
    setReload(currentReload => currentReload + 1);
    setHasMore(true);
  }, []);

  useEffect(() => {
    if (reloadWhenEmpresaChange && user?.idEmpresa !== prevIdEmpresa.current) {
      if (prevIdEmpresa?.current !== undefined) {
        reloadList();
      }

      prevIdEmpresa.current = user.idEmpresa;
    }
  }, [reloadWhenEmpresaChange, user.idEmpresa, reloadList]);

  useEffect(() => {
    if (
      reloadWhenConsultorioChange &&
      consultorios?.ativo?.id !== prevConsultorioAtivoId.current
    ) {
      if (prevIdEmpresa?.current !== undefined) {
        reloadList();
      }

      prevConsultorioAtivoId.current = consultorios.ativo.id;
    }
  }, [reloadWhenConsultorioChange, consultorios?.ativo?.id, reloadList]);

  return (
    <SearcListContext.Provider
      value={{
        listItems,
        query,
        loading,
        hasMore,
        page: page.current,
        totalCount,
        filter,
        setQuery,
        setFilter,
        fetchNextPage,
        reloadList,
      }}
    >
      {children}
    </SearcListContext.Provider>
  );
};

function useSearchList() {
  const context = useContext(SearcListContext);

  if (!context) {
    throw new Error('useSearchList must be used within an SearcListProvider');
  }

  return context;
}

export { SearchListProvider, useSearchList };
