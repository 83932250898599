import { useEffect, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AnexoOpmeAPI } from 'src/APIs/ProntuarioAPI/AnexoOpmeAPI/AnexoOpmeAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { OPME } from './OPME/OPME';
import { Panel } from './Panel/Panel';
import { validationSchema } from './validationSchema';

import './ModalAnexarOPME.scss';

export const ModalAnexarOpme = (props: any) => {
  const {
    isOpen,
    close,
    state: { idProcedimento, numeroGuia },
  } = props;

  const { user } = useAppSelector((state: RootState) => state);

  const [idAnexoOpme, setIdAnexoOpme] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useFormMethods;

  useEffect(() => {
    const fetchAnexoOpme = async () => {
      try {
        const response = await AnexoOpmeAPI.getAnexosOpme(idProcedimento);

        setIdAnexoOpme(response?.id);

        const anexoOpmeServicoTuss =
          response?.anexoOpmeServicoTuss.map((anexoOpme: any) => ({
            id: anexoOpme.id,
            codigo: anexoOpme.servicoTuss.codigo,
            nome: anexoOpme.servicoTuss.nome,
            idServicoTuss: anexoOpme.servicoTuss.id,
            quantidade: anexoOpme.quantidade,
            quantidadeMaxima: anexoOpme.servicoTuss?.quantidadeMaxima,
            idServicoTussFornecedorOpme: anexoOpme.idServicoTussFornecedorOpme,
            fornecedoresOptions:
              anexoOpme.servicoTuss?.fornecedoresOpme.map(
                (fornecedor: any) => ({
                  label: fornecedor.nome,
                  value: fornecedor.id,
                }),
              ) || [],
          })) || [];

        reset({
          anexoOpmeServicoTuss,
          justificativaTecnica: response?.justificativaTecnica || '',
          especificacaoMaterial: response?.especificacaoMaterial || '',
          observacao: response?.observacao || '',
        });
      } catch (error) {
      } finally {
        setLoadingData(false);
      }
    };

    fetchAnexoOpme();
  }, [idProcedimento, reset]);

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        id: idAnexoOpme,
        idSolicitacaoExame: idProcedimento,
        numeroGuiaTiss: numeroGuia,
        idUsuarioAtualizacao: user.idUsuario,
        ...data,
      };

      const saveAnexoOpme = idAnexoOpme
        ? AnexoOpmeAPI.putAnexoOpme
        : AnexoOpmeAPI.postAnexoOpme;

      await saveAnexoOpme(payload);

      close();
    } catch (error) {}
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        onClick={close}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const content = () => {
    if (loadingData) {
      return <GridListLoading />;
    }

    return (
      <>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Pesquise o nome do OPME desejado para anexar ao exame/procedimento
        </SimpleText>

        <FormProvider {...useFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OPME />

            <TextareaInputControlled
              className="p-my-3"
              control={control}
              name="justificativaTecnica"
              label="Justificativa Técnica"
              errorMsg={errors.justificativaTecnica?.message}
              maxLength={1000}
              maxLengthSpan
            />

            <Panel title="Especificação do material">
              <TextareaInputControlled
                control={control}
                name="especificacaoMaterial"
                label="Especificação do material"
                maxLength={500}
                maxLengthSpan
              />
            </Panel>

            <Panel title="Observação/ Justificativa">
              <TextareaInputControlled
                control={control}
                name="observacao"
                label="Observação/ Justificativa"
                maxLength={500}
                maxLengthSpan
              />
            </Panel>
          </form>
        </FormProvider>
      </>
    );
  };

  return (
    <Dialog
      className="modal-anexar-opme"
      header="Anexar OPME"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
      footer={renderFooter}
    >
      {content()}
    </Dialog>
  );
};
