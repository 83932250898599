import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';

import { transformarEmMoeda } from 'src/utils/currency';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';
import './ModalHistoricoValoresServico.scss';

interface ModalHistoricoValoresServicoProps {
  visible: boolean;
  onHide(): void;
  valorServico: any;
}

const ModalHistoricoValoresServico = ({
  valorServico,
  visible,
  onHide,
}: ModalHistoricoValoresServicoProps) => {
  const [historico, setHistorico] = useState<any[]>([]);

  useEffect(() => {
    if (valorServico.id) {
      async function fetchApi() {
        const response = await ServicoTussValorAPI.loadServicoTussValor({
          tipo: 'HISTORICO',
          codigoServico: valorServico?.servicoTuss?.codigo,
          idServico: valorServico?.servicoTuss?.id,
          idUsuario: valorServico?.usuario?.id,
          idConvenio: valorServico?.servicoTuss?.idConvenio,
          idProfissionalSaude: valorServico.idProfissionalSaude,
        });

        if (Array.isArray(response.list)) setHistorico(response.list as any);
      }

      fetchApi();
    }
  }, [valorServico]);

  const HeaderTable = () => {
    return (
      <div className="p-grid p-col-12">
        <div className="p-col-4">
          <SimpleText fontSize={FONT_SIZE.XXS}>Início vigência</SimpleText>
        </div>
        <div className="p-col-4">
          <SimpleText fontSize={FONT_SIZE.XXS}>Vigência até</SimpleText>
        </div>
        <div className="p-col-4">
          <SimpleText fontSize={FONT_SIZE.XXS}>Valor</SimpleText>
        </div>
      </div>
    );
  };

  const rowTable = (data: any): JSX.Element => {
    return (
      <div className={'p-grid historico-row-table'}>
        <div className="p-col-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XXS}>
            {dayjs(data?.dataInicioVigencia).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
        </div>
        <div className="p-col-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XXS}>
            {data?.dataFimVigencia
              ? dayjs(data?.dataFimVigencia).format('DD/MM/YYYY HH:mm')
              : '-'}
          </SimpleText>
        </div>
        <div className="p-col-4">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XXS}
            bold
          >
            {transformarEmMoeda(data?.valor)}
          </SimpleText>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header="Histórico de valores do serviço"
      type="modal"
      maximizedMobileSize
      visible={visible}
      onHide={onHide}
    >
      <div className="modal-body-historico">
        <div className="p-grid servico-tuss">
          <div className="p-col-5 p-d-flex p-flex-column">
            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
              {valorServico?.servicoTuss?.codigo}
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_40}
              className="p-pt-1"
            >
              {valorServico?.servicoTuss?.nome}
            </SimpleText>
          </div>
          <div className="p-col-4">
            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
              {valorServico?.servicoTuss?.convenio?.nomeFantasia ||
                valorServico?.servicoTuss?.convenio?.razaoSocial}
            </SimpleText>
          </div>
          <div className="p-col-3">
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              bold
            >
              {valorServico?.usuario?.nome}
            </SimpleText>
          </div>
        </div>

        <Separator className="historico-separator" />

        {HeaderTable()}

        {historico.map(h => rowTable(h))}

        <footer>
          <Button
            className="historico-btn"
            btnType="ghost"
            label="Fechar"
            onClick={onHide}
            stretch
          />
        </footer>
      </div>
    </Dialog>
  );
};

export default ModalHistoricoValoresServico;
