import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  equipe: EquipeDTO;
  onEdit: (equipe: EquipeDTO) => void;
  onDelete: (id: number) => void;
  equipeStatus: JSX.Element;
  dataInclusao: string;
  profissionalResponsavel: any;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const {
    equipe,
    onEdit,
    onDelete,
    equipeStatus,
    dataInclusao,
    profissionalResponsavel,
  } = props;

  return (
    <CardItem>
      <SimpleText
        className="p-col-2 p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {equipe.nome}
      </SimpleText>

      <div className="p-col-3 p-d-flex p-flex-column p-px-0">
        <SimpleText
          className="p-col-12 p-wordbreak-all p-pb-0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {profissionalResponsavel?.profissionalSaude.usuario.nome}
        </SimpleText>
        <SimpleText
          className="p-col-12 p-wordbreak-all p-pt-0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          {
            profissionalResponsavel?.profissionalSaude.conselhoTissProfissional
              ?.descricao
          }{' '}
          • {profissionalResponsavel?.profissionalSaude.conselhoTiss?.sigla}{' '}
          {profissionalResponsavel?.profissionalSaude.conselhoTiss?.codigo}
        </SimpleText>
      </div>

      <SimpleText
        className="p-col-2 p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_40}
      >
        {equipe.pontuacao?.nome}
      </SimpleText>

      <SimpleText
        className="p-col-2 p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_40}
      >
        {dataInclusao}
      </SimpleText>

      <div className="p-col-3 p-d-flex p-ai-center p-jc-end p-pl-0 fixed">
        <>
          {equipeStatus}
          <Can
            checkPermission={[
              'ADM_CARTEIRA_APS_EQUIPE_ALTERAR',
              'ADM_CARTEIRA_APS_EQUIPE_EXCLUIR',
            ]}
          >
            <Divider layout="vertical" />
          </Can>
          <Button
            btnType="ghost"
            className="p-mr-3"
            icon="fa fa-pencil-alt"
            onClick={() => onEdit(equipe)}
            checkPermission="ADM_CARTEIRA_APS_EQUIPE_ALTERAR"
          />

          <Button
            btnType="ghost"
            icon="fa fa-trash"
            onClick={() => onDelete(equipe.id)}
            checkPermission="ADM_CARTEIRA_APS_EQUIPE_EXCLUIR"
          />
        </>
      </div>
    </CardItem>
  );
};
