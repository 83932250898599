import { useMemo, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';

import { useAppSelector } from 'src/core/redux/hooks';

import {
  allowedStatusPaciente,
  ordenarProximosPacientes,
  proximosPacientesMesmoProfissional,
} from './utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import { useAgenda } from '../../AgendaContext';

import CardProximoPaciente from './CardProximoPaciente/CardProximoPaciente';
import ProximosPacientesHeader from './ProximosPacientesHeader/ProximosPacientesHeader';
import ProximosPacientesSkeleton from './ProximosPacientesSkeleton/ProximosPacientesSkeleton';
import UpdateButton from './UpdateButton/UpdateButton';

import './ProximosPacientes.scss';

const opcoesOrdenacaoProximosPacientes = [
  { label: 'Data Agendamento', value: 'dataAgendamento' },
  { label: 'Ordem de chegada', value: 'dataPresenca' },
];

interface ProximosPacientesProps {
  items: AtendimentoAgendamento[];
  isLoading: boolean;
  renderSigilo: (atendimento: AtendimentoAgendamento) => JSX.Element;
  tipoProfissional: any;
  obrigaPresencaCuidado: boolean;
}

function ProximosPacientes({
  items,
  isLoading,
  renderSigilo,
  tipoProfissional,
  obrigaPresencaCuidado,
}: ProximosPacientesProps) {
  const [ordenacao, setOrdenacao] = useState('dataAgendamento');

  const { agenda } = useAppSelector(state => state);
  const { refetchAgenda, togglePesquisaProntuario } = useAgenda();

  const isEnfermeiro = tipoProfissional === 'ENFERMEIRO';

  const proximosPacientes = useMemo(() => {
    return items?.filter((atendimento: AtendimentoAgendamento) => {
      return (
        allowedStatusPaciente(isEnfermeiro).includes(atendimento.status) ||
        (atendimento.idMedico === agenda?.profissionalAtivo?.id &&
          proximosPacientesMesmoProfissional.includes(atendimento.status)) ||
        (atendimento.status === 'CUIDADO_PROGRAMADO' && !obrigaPresencaCuidado)
      );
    });
  }, [
    items,
    isEnfermeiro,
    agenda?.profissionalAtivo?.id,
    obrigaPresencaCuidado,
  ]);

  const proximosPacientesOrdenados = useMemo(
    () =>
      [...proximosPacientes].sort((a, b) => {
        return ordenarProximosPacientes(a, b, ordenacao);
      }),
    [ordenacao, proximosPacientes],
  );

  const proximosPacientesContent = useMemo(() => {
    if (isLoading) return <ProximosPacientesSkeleton />;

    if (!proximosPacientesOrdenados.length) {
      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Não há pacientes para atender. Reveja sua agenda ou converse com sua
          secretária.
        </SimpleText>
      );
    }

    return proximosPacientesOrdenados.map(atendimento => (
      <CardProximoPaciente key={atendimento.id} atendimento={atendimento}>
        {renderSigilo(atendimento)}
      </CardProximoPaciente>
    ));
  }, [isLoading, proximosPacientesOrdenados, renderSigilo]);

  return (
    <div id="agenda">
      <ProximosPacientesHeader
        updateButton={<UpdateButton onUpdate={() => refetchAgenda()} />}
        pesquisaProntuarioButton={
          <Button
            btnType="gray"
            icon="fas fa-search"
            onClick={() => {
              togglePesquisaProntuario();
            }}
          />
        }
        ordenacao={
          <Dropdown
            id="proximos-paciente-ordenacao"
            className="p-mr-4"
            value={ordenacao}
            onChange={e => setOrdenacao(e.value)}
            options={opcoesOrdenacaoProximosPacientes}
          />
        }
      />

      <div id="proximos-pacientes-container" className="p-d-flex">
        {proximosPacientesContent}
      </div>
    </div>
  );
}

export default ProximosPacientes;
