import dayjs from 'dayjs';

import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';

interface PanelContentProps {
  problema: Comorbidade;
  changeTabIndex: (target: string) => void;
}

function PanelContent({ problema, changeTabIndex }: PanelContentProps) {
  const headerTextClassName = `p-mb-2 ${
    problema.status === 'INATIVO' ? 'comorbidade-inativa' : ''
  }`;
  const dataTextClassName =
    problema.status === 'INATIVO' ? 'line-through comorbidade-inativa' : '';

  const handleChangeTab = () => {
    changeTabIndex('Plano de cuidado');
  };

  return (
    <div className="comorbidades-panel-content p-grid">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {problema.descricao}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data ínicio
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {!!problema?.dataInicio &&
            dayjs(problema.dataInicio).locale('pt-br').format('DD MMM[.] YYYY')}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data fim
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {!!problema?.dataFim &&
            dayjs(problema.dataFim).locale('pt-br').format('DD MMM[.] YYYY')}
        </SimpleText>
      </div>
      {problema?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {problema.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{problema.usuarioAtualizacao.nome}</b> em{' '}
          {dayjs(problema.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>

      <AlertBox
        className="p-col-12"
        type="WARN"
        text={`Existe um plano de cuidado para este ${
          problema?.cid?.length ? 'CID' : 'CIAP'
        }, clique `}
        visible={problema.existePlanoCuidadoCidCiap}
      >
        <a
          style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}
          onClick={() => handleChangeTab()}
        >
          aqui
        </a>
        {' para abrir a tela de plano de cuidado e adicionar ao paciente!'}
      </AlertBox>
    </div>
  );
}

export default PanelContent;
