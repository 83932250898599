import React, { CSSProperties, useEffect, useMemo } from 'react';

import { TIPO_USUARIO } from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setProfissionalAtivo } from 'src/core/redux/slices/agenda/AgendaSlice';
import { RootState } from 'src/core/redux/store';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import IconButton, {
  IconBtnSizes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ProfissionalSelect from 'src/pages/Emed/Agenda/UserHeader/ProfissionalSelect/ProfissionalSelect';
import './UserHeader.scss';
interface UserHeaderProps {
  rightSide?: any;
  backButton?: any;
  style?: CSSProperties;
  isConfigAgenda?: boolean;
}
const UserHeader = ({
  rightSide,
  backButton,
  style,
  isConfigAgenda,
}: UserHeaderProps) => {
  const { user, agenda } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  const isConfig = isConfigAgenda ? 'isConfig' : '';

  const shouldShowIconButton =
    user.tipoUsuario !== TIPO_USUARIO.PROFISSIONAL_SAUDE ||
    (user.tipoProfissionalSaude === 'ENFERMEIRO' && !isConfigAgenda);

  useEffect(() => {
    if (!(TIPO_USUARIO.SECRETARIO === user.tipoUsuario)) return;

    if (!agenda?.profissionaisDisponiveis?.length) {
      dispatch(setProfissionalAtivo(undefined));
      return;
    }

    const profissionalSaudeAtivo = agenda.profissionaisDisponiveis.find(
      (profissionalSaude: GetUsuarioDTO) =>
        user.tipoProfissionalSaude === 'ENFERMEIRO'
          ? profissionalSaude.id === user.idUsuario
          : profissionalSaude.id === agenda?.profissionalAtivo?.id,
    );

    dispatch(
      setProfissionalAtivo(
        profissionalSaudeAtivo || agenda.profissionaisDisponiveis[0],
      ),
    );
  }, [
    agenda.profissionaisDisponiveis,
    agenda?.profissionalAtivo?.id,
    user.tipoUsuario,
    dispatch,
    user.tipoProfissionalSaude,
    user.idUsuario,
  ]);

  const profissinalSaudeEspecialidades = useMemo(() => {
    const especialidades =
      agenda?.profissionalAtivo?.profissionalSaude?.especialidades;

    if (!(Array.isArray(especialidades) && especialidades.length)) return '';

    return especialidades
      .slice(0, 2)
      .map((especialidade: any) => especialidade.nome)
      .toString()
      .replace(',', ' • ');
  }, [agenda?.profissionalAtivo?.profissionalSaude?.especialidades]);

  const profissionalSaudeTodasEspecialidades = useMemo(
    () =>
      (agenda?.profissionalAtivo?.profissionalSaude?.especialidades || []).map(
        (especialidade: any) => especialidade.nome,
      ),
    [agenda?.profissionalAtivo?.profissionalSaude?.especialidades],
  );

  return (
    <div className={`UserHeader ${isConfig}`} style={style}>
      {backButton && (
        <Button
          icon="pi pi-arrow-left"
          btnType="green-link"
          label="Voltar"
          onClick={backButton}
        />
      )}
      <div className="infos">
        <CustomImage src={'perfil_secretaria.png'} />
        <div className="icon-aligner p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Unimed
          </SimpleText>

          <div className="infos">
            <SimpleText fontSize={FONT_SIZE.MD} bold>
              {agenda.profissionalAtivo?.nome || '-'}
            </SimpleText>

            {shouldShowIconButton && (
              <IconButton
                icon={'pi pi-chevron-down'}
                btnSize={IconBtnSizes.SMALL}
                overlay={<ProfissionalSelect />}
                type="button"
              />
            )}
          </div>

          <Tooltip target="#especialidades-agenda" />
          <SimpleText
            id="especialidades-agenda"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXXS}
            data-pr-tooltip={profissionalSaudeTodasEspecialidades.toString()}
            data-pr-position="top"
          >
            {profissinalSaudeEspecialidades}{' '}
            {profissionalSaudeTodasEspecialidades.length > 2 && ' e outras.'}
          </SimpleText>
        </div>
        <div className="rightside">{rightSide}</div>
      </div>
    </div>
  );
};

export default React.memo(UserHeader);
