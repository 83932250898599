import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import FornecedorTussGlobal from './FornecedorTussGlobal/FornecedorTussGlobal';
import './FornecedorTuss.scss';

const FornecedorTuss = () => {
  return (
    <Page className={'FornecedorTuss'}>
      <>
        <PageHeader
          title={'Cadastro de fornecedor TUSS'}
          subtitle={
            'Cadastre os fornecedores para utiliza-los na configuração TUSS'
          }
        />
        <FornecedorTussGlobal />
      </>
    </Page>
  );
};

export default FornecedorTuss;
