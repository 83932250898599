import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();
  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-m-1">
          <SimpleText
            className="p-col-6 p-lg-4"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Profissional cuidador
          </SimpleText>

          <SimpleText
            className="p-col-6 p-lg-4"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Grupo pontuação
          </SimpleText>
        </div>
      )}
    </>
  );
};
