export const tiposContato = [
  {
    value: 'CELULAR_PARTICULAR',
    label: 'Celular particular',
  },
  {
    value: 'FIXO_RESIDENCIAL',
    label: 'Fixo residencial',
  },
  {
    value: 'FIXO_COMERCIAL',
    label: 'Fixo comercial',
  },
  {
    value: 'CELULAR_COMERCIAL',
    label: 'Celular comercial',
  },
];
