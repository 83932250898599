import { useRef, useState } from 'react';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

interface FavoriteButtonProps {
  isSelected: boolean;
  idProcedimento: number;
  idExameFavorito?: number;
  onChange?: (isFavorite: boolean, idFavorito: number) => void;
}

export const FavoriteButton = (props: FavoriteButtonProps) => {
  const { isSelected, idProcedimento, idExameFavorito, onChange } = props;

  const [isFavorite, setIsFavorite] = useState(isSelected);

  const idExame = useRef(idExameFavorito);

  const handleFavorite = async () => {
    try {
      setIsFavorite(old => !old);

      const favoriteAction = isFavorite
        ? ServicoTussCRUDAPI.desfavoritarExame
        : ServicoTussCRUDAPI.favoritarExame;

      const idFavoriteAction = isFavorite ? idExame.current : idProcedimento;

      const response = await favoriteAction(idFavoriteAction as number, {
        hideToast: true,
      });

      idExame.current = response;

      onChange?.(!isFavorite, idExameFavorito || response);
    } catch (error) {
      setIsFavorite(old => !old);
    }
  };

  const favoriteClassName = `favorite ${
    isFavorite ? 'pi pi-star-fill' : 'pi pi-star'
  }`;

  return <i className={favoriteClassName} onClick={handleFavorite} />;
};
