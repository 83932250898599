import Http, { HttpParams } from 'src/core/http/Http';

const basePath = 'prontuario-documentos';

export const getDocumentos = (
  idAtendimento: any,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<Documentos[]>> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const postDocumentos = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,

    return: true,
    ...options,
  });

export const putDocumentos = (
  id: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${id}`,
    data: payload,

    return: true,
    ...options,
  });

export const inativarDocumentos = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${payload.id}/atendimento/${payload.idAtendimento}/inativar`,
    data: payload,
    return: true,
    ...options,
  });

export const deleteDocumentos = (
  id: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${id}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const DocumentosAPI = {
  getDocumentos,
  postDocumentos,
  putDocumentos,
  inativarDocumentos,
  deleteDocumentos,
};

export default DocumentosAPI;
