import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldListarRespostasPerguntaVinculoProps = DropdownProps & {
  name?: string;
  label: string;
  idPergunta: number | undefined;
};

export const FieldListarRespostasPerguntaVinculo = ({
  name = 'listarRespostas',
  label,
  idPergunta,
  ...rest
}: FieldListarRespostasPerguntaVinculoProps) => {
  const { control } = useFormContext();
  const [tipoResposta, setTipoRespostas] = useState<any[]>([]);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async () => {
    if (!idPergunta) return setTipoRespostas([]);

    try {
      const response =
        await PerguntaFormularioDinamicoAPI.loadRespostasPerguntaParaVinculo(
          {
            idPergunta,
          },
          { throwError: true },
        );

      setTipoRespostas(response || []);
    } catch {
      setTipoRespostas([]);
    }
  }, [idPergunta]);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = tipoResposta.map(item => ({
      label: item?.descricao,
      value: item,
    }));

    return data;
  }, [tipoResposta]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
