import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { EquipeAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/EquipeAPI/EquipeAPI';
import { PontuacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/PontuacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import ProfissionaisList from './ProfissinaisList/ProfissionaisList';
import { validationSchema } from './validationSchema';

import './FormEquipe.scss';

const FormEquipe = () => {
  const [editEquipe, setEditEquipe] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [pontuacaoOptions, setPontuacaoOptions] = useState<any[]>([]);

  const { idEquipe } = useParams();

  const navigate = useNavigate();

  const handleGoBack = useCallback(
    () =>
      navigate('/pacientes/carteira-aps', {
        replace: true,
        state: 'Equipe',
      }),
    [navigate],
  );

  const { user, consultorios } = useAppSelector((state: RootState) => state);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  useEffect(() => {
    const getEquipeById = async () => {
      try {
        setLoading(true);

        const response: any = await EquipeAPI.getEquipeById(
          Number(idEquipe),
          consultorios.ativo.id,
          {
            throwError: true,
          },
        );

        setEditEquipe(response);
        reset({
          descricao: response.descricao,
          pontuacao: response.pontuacao,
          nome: response.nome,
          equipeProfissionaisSaude: response.equipeProfissionaisSaude || [],
        });
      } catch (error) {
        handleGoBack();
      } finally {
        setLoading(false);
      }
    };

    if (idEquipe) {
      getEquipeById();
    }
  }, [idEquipe, handleGoBack, reset, consultorios?.ativo?.id]);

  useLayoutEffect(() => {
    const getPontuacoesOptions = async () => {
      const response = await PontuacaoCarteiraAPI.getPontuacaoByConsultorio(
        consultorios.ativo.id,
      );
      setPontuacaoOptions(response || []);
    };
    if (consultorios.ativo.id) getPontuacoesOptions();
  }, [consultorios.ativo.id]);

  const requestToAPI = useCallback(
    (data: any) => {
      if (idEquipe)
        return EquipeAPI.putEquipe(
          Number(idEquipe),
          consultorios.ativo.id,
          data,
        );

      return EquipeAPI.postEquipe(consultorios.ativo.id, data);
    },
    [consultorios?.ativo?.id, idEquipe],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        descricao: data.descricao,
        nome: data.nome,
        idPontuacao: data.pontuacao?.id || null,
        equipeProfissionaisSaude: data.equipeProfissionaisSaude.map(
          (profissional: any) => ({
            id: profissional.id,
            responsavel: profissional.responsavel,
          }),
        ),
        ativo: editEquipe.ativo || true,
      };
      const response = await requestToAPI(payload);

      if (response.status === 200) {
        handleGoBack();
      }
    },
    [editEquipe.ativo, handleGoBack, requestToAPI],
  );

  const pageTitle = editEquipe.id
    ? 'Edição de equipe'
    : 'Novo registro de equipe';

  const subtitle = `Preencha os campos para ${
    editEquipe.id ? 'editar' : 'cadastrar'
  } a equipe`;

  if (loading) {
    return (
      <Page id="form-complexidade-container">
        <>
          <PageHeader title={pageTitle} subtitle={subtitle} />
          <GridListLoading />
        </>
      </Page>
    );
  }

  if (!user?.idEmpresa) {
    return (
      <PageContainer title={pageTitle} subtitle={subtitle}>
        <AlertBox
          visible={true}
          text={`Você precisa selecionar um Cliente para esta funcionalidade!`}
        />
      </PageContainer>
    );
  }

  if (!consultorios?.ativo?.id) {
    return (
      <PageContainer title={pageTitle} subtitle={subtitle}>
        <AlertBox
          visible={true}
          text="Você precisa selecionar um Consultório para esta funcionalidade!"
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer title={pageTitle} subtitle={subtitle}>
      <Page content white>
        <>
          <Button
            className="btn-voltar"
            btnType="gray"
            label="Cancelar e voltar para a equipe"
            icon="pi pi-arrow-left"
            onClick={() => handleGoBack()}
          />
          <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className="p-grid p-my-2">
              <FormInput
                name="nome"
                label="Nome da equipe"
                className="p-col-12 p-md-3"
                maxLength={100}
                errorMsg={errors.nome?.message}
              />

              <FormInput
                name="descricao"
                label="Descrição"
                className="p-col-12 p-md-6"
                maxLength={200}
              />

              <DropdownControlled
                control={control}
                name="pontuacao"
                label="grupo de pontuação"
                className="p-col-12 p-md-3"
                optionLabel="nome"
                dataKey="id"
                showClear
                options={pontuacaoOptions}
              />

              <ProfissionaisList />

              {!!errors.equipeProfissionaisSaude?.message && (
                <SimpleText className="p-col-12 error" fontSize={FONT_SIZE.XXS}>
                  {errors.equipeProfissionaisSaude.message}
                </SimpleText>
              )}

              <div className="p-col-12 p-grid p-justify-end">
                <Button
                  className="p-col-12 p-sm-6 p-md-2"
                  label="Cancelar"
                  onClick={() => handleGoBack()}
                  btnType="ghost"
                />

                <Button
                  label="Salvar"
                  type="submit"
                  className="p-col-12 p-sm-6 p-md-4"
                  loading={isSubmitting}
                />
              </div>
            </form>
          </FormProvider>
        </>
      </Page>
    </PageContainer>
  );
};

interface PageContainerProps {
  children: JSX.Element;
  title: string;
  subtitle: string;
}

const PageContainer = (props: PageContainerProps) => {
  const { children, title, subtitle } = props;

  return (
    <Page id="form-equipe-container">
      <>
        <PageHeader title={title} subtitle={subtitle} />

        {children}
      </>
    </Page>
  );
};

export default FormEquipe;
