import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import useDebounce from 'src/utils/useDebounce';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldProfissionalProps = DropdownProps & {
  name?: string;
  label: string;
  params?: {
    idPaciente: number | string;
    idConsultorio?: number;
    isEquipe?: boolean;
  };
  customOnChange?: (value: any) => void;
};

const FieldProfissional = ({
  name = 'profissional',
  label,
  params,
  ...rest
}: FieldProfissionalProps) => {
  const { control, watch } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [profissional, setProfissional] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debouncedSearchConvenio = useDebounce(querySearch, 350);

  const loadprofissional = useCallback(
    async (queryParam: any) => {
      try {
        let res;

        if (params?.isEquipe) {
          res = await PortalPacienteAPI.loadProfissionaisEquipePorConsultorio(
            {
              profissional: queryParam,
              idConsultorio: params.idConsultorio!,
              idPaciente: params.idPaciente!,
            },
            { throwError: true },
          );
        } else {
          res = await PortalPacienteAPI.getProfissionalSaude(
            {
              profissional: queryParam,
              idConsultorio: params?.idConsultorio,
            },
            { throwError: true },
          );
        }

        setProfissional(res?.list || res);
      } catch (error) {
        setProfissional([]);
      } finally {
        setLoading(false);
      }
    },
    [params],
  );

  useEffect(() => {
    loadprofissional(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, loadprofissional]);

  const profissionalOptions = useMemo(() => {
    const options = profissional.map(value => ({
      label: value.usuario?.nome || value?.nome,
      value: value.usuario || value,
    }));

    return options;
  }, [profissional]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setLoading(true);
    setQuerySearch(filter);
  };

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loading}
      filterPlaceholder="Digite para pesquisar"
      options={profissionalOptions}
      resetFilterOnHide
      errorMsg={error?.message}
      {...rest}
    />
  );
};

export default FieldProfissional;
