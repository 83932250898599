import Http, { HttpParams } from 'src/core/http/Http';

export const getListaEspera = (
  idMedico: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'agenda',
    url: `/lista-espera/medico/${idMedico}`,
    hideToast: true,
    ...options,
  });
};

export const saveListaEspera = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: 'lista-espera',
    data: payload,
    ...options,
  });
};

export const updateListaEspera = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'agenda',
    url: 'lista-espera',
    data: payload,
    ...options,
  });
};

export const deleteItemListaEspera = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'agenda',
    url: `lista-espera/${id}`,
    ...options,
  });
};

const ListaEsperaAPI = {
  getListaEspera,
  saveListaEspera,
  updateListaEspera,
  deleteItemListaEspera,
};

export default ListaEsperaAPI;
