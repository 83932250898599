import { PanelHeaderTemplateOptions } from 'primereact/panel';
import { useLocation, useParams } from 'react-router';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Panel from 'src/components/Panel/Panel';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import { PanelSolicitacaoRecente } from 'src/pages/Emed/Prontuario/SolicitacaoExameCirurgia/components/PanelSolicitacaoRecente/PanelSolicitacaoRecente';

import './ExamesRecente.scss';

interface ExamesRecentesProps {
  codigoProcedimento: string;
}

export const ExamesRecentes = (props: ExamesRecentesProps) => {
  const { codigoProcedimento } = props;

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down'
      : 'pi pi-chevron-up';

    return (
      <div className="exame-recente-panel-header p-d-flex p-jc-between p-ai-center p-p-2">
        <div>
          <i className="fas fa-exclamation-circle p-mr-3" />
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            Exames Recentes
          </SimpleText>
        </div>

        <Button
          type="button"
          btnType="gray"
          icon={toggleIcon}
          onClick={options.onTogglerClick}
        />
      </div>
    );
  };

  const fetchHistorico = ({ query, ...params }: any) =>
    SolicitacoesProcedimentosAPI.getHistoricoSadt(Number(idAtendimento), {
      ...params,
      codigoProcedimento,
    });

  const renderRow = (procedimento: any) => {
    return (
      <PanelSolicitacaoRecente
        procedimento={procedimento}
        showActionsBtns={false}
      />
    );
  };

  return (
    <div className="p-col-12">
      <Panel
        className="exames-recente-panel"
        headerTemplate={headerTemplate}
        toggleable
        collapsed
      >
        <SearchList.Root fetchApi={fetchHistorico}>
          <SearchList.InfiniteScroll autoHeight={false} renderRow={renderRow} />
        </SearchList.Root>
      </Panel>
    </div>
  );
};
