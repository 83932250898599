import { useFormContext } from 'react-hook-form';

import { FieldECOG } from '../../components/FieldECOG';
import { FieldEstadiamento } from '../../components/FieldEstadiamento';
import { FieldFinalidade } from '../../components/FieldFinalidade';
import { FieldNodulo } from '../../components/FieldNodulo';
import { FieldTipoQuimio } from '../../components/FieldTipoQuimio';
import { FieldTumor } from '../../components/FieldTumor';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { VariantPanel } from 'src/components/Panel/VariantPanel/VariantPanel';

export const DiagnosticoOncologicoForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = !!Object.keys(errors?.diagnosticoOncologico || {})?.length;

  return (
    <VariantPanel
      isOpened
      hasError={hasError}
      title="Diagnostico Oncológico"
      subtitle="(Obrigatório)"
    >
      <form className="p-grid">
        <CalendarInputControlled
          control={control}
          name="diagnosticoOncologico.dataDiagnostico"
          placeholder="dd/mm/aaaa"
          className="p-col-6 p-sm-4"
          label="Data do diagnóstico"
          maxDate={new Date()}
          showIcon
        />
        <FieldEstadiamento
          name="diagnosticoOncologico.estadiamento"
          className="p-col-6 p-sm-4"
          sublabel="(Obrigatório)"
          label="Estadiamento"
          dataKey="codigo"
        />
        <FieldTipoQuimio
          name="diagnosticoOncologico.tipoQuimio"
          className="p-col-6 p-sm-4"
          sublabel="(Obrigatório)"
          label="Tipo quimioterapia"
          dataKey="codigo"
        />
        <FieldFinalidade
          name="diagnosticoOncologico.finalidade"
          className="p-col-6 p-sm-4"
          sublabel="(Obrigatório)"
          dataKey="codigo"
          label="Finalidade"
        />
        <FieldECOG
          name="diagnosticoOncologico.ecog"
          className="p-col-12 p-sm-8"
          sublabel="(Obrigatório)"
          dataKey="codigo"
          label="ECOG"
        />
        <FieldTumor
          name="diagnosticoOncologico.tumor"
          className="p-col-6 p-sm-4"
          sublabel="(Obrigatório)"
          dataKey="codigo"
          label="Tumor"
        />
        <FieldNodulo
          name="diagnosticoOncologico.nodulo"
          className="p-col-6 p-sm-4"
          sublabel="(Obrigatório)"
          dataKey="codigo"
          label="Nódulo"
        />
        <FieldNodulo
          name="diagnosticoOncologico.metastase"
          className="p-col-6 p-sm-4"
          sublabel="(Obrigatório)"
          dataKey="codigo"
          label="Metástase"
        />

        <TextareaInputControlled
          control={control}
          name="diagnosticoOncologico.planoTerapeutico"
          sublabel="(Obrigatório)"
          label="Plano terapêutico"
          className="p-col-12"
          maxLength={1000}
          style={{
            resize: 'none',
          }}
        />
        <TextareaInputControlled
          control={control}
          name="diagnosticoOncologico.diagnostico"
          label="Diagnósticos cito/histopatológico"
          className="p-col-12"
          maxLength={1000}
          style={{
            resize: 'none',
          }}
        />
        <TextareaInputControlled
          control={control}
          name="diagnosticoOncologico.informacoesRelevantes"
          label="Informações relevantes"
          className="p-col-12"
          maxLength={1000}
          style={{
            resize: 'none',
          }}
        />
      </form>
    </VariantPanel>
  );
};
