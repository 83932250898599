import { DetailedHTMLProps, forwardRef, HTMLAttributes, useCallback } from 'react';

import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import SimpleText, { FONT_SIZE } from 'src/components/Basics/SimpleText/SimpleText';
import './ImageRadioButton.scss';
export interface ImageRadioButtonOption {
  name: string;
  value?: any;
  img?: string;
  label?: string;
  acronym?: string;
}
export interface ImageRadioButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  options: ImageRadioButtonOption[];
  onPress?(o: ImageRadioButtonOption): void;
  value?: ImageRadioButtonOption;
  name?: string;
  label?: string;
  errorMsg?: string;
  customClassName?: string | undefined;
  acronym?: string;
  disabled?: boolean;
}

const ImageRadioButton = ({
  onPress,
  value,
  options,
  className,
  errorMsg,
  customClassName,
  name,
  disabled = false,
  ...props
}: ImageRadioButtonProps, ref:any) => {
  const option = useCallback(
    (o: ImageRadioButtonOption) => {
      const isSelected = value && value.name === o.name ? true : false;

      return (
        <div
          key={o.name}
          className={`option ${isSelected ? 'selected' : ''
            } ${disabled ? 'disabled' : ''} ${customClassName}`}
          onClick={() => {
            if (disabled) return;
            if (onPress) {
              onPress(o);
            }
          }}>
          {o.img && !o.acronym && (
            <div className={'img'}>
              {(o.img && <CustomImage src={o.img} />) || <></>}
            </div>
          )}
          {o.acronym && (
            <div className={'acronym'}>
              <SimpleText fontSize="lg" bold>
                {o.acronym}
              </SimpleText>
            </div>
          )}
          <SimpleText bold className={`label ${!isSelected ? 'text' : ''}`}>
            {o.label || ''}
          </SimpleText>
        </div>
      );
    },
    [onPress, value, customClassName],
  );

  return (
    <>
      <div
        key={name}
        className={`ImageRadioButton ${className || ''}`}
        {...props}>
        <div className='column'>
          <div className='row'>
            {(options || []).map(v => option(v))}
          </div>
          <div>
            {errorMsg && (
              <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                {errorMsg}
              </SimpleText>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(ImageRadioButton);
