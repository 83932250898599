import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { useLocation, useParams } from 'react-router';

import { PerfilSaudeAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PerfilSaudeAPI/PerfilSaudeAPI';
import QuestionariosAPI from 'src/APIs/ProntuarioAPI/Questionarios/QuestionariosAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { useAtendimento } from '../Atendimento/useAtendimento';

export const useQuestionarioController = () => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { user } = useAppSelector((state: RootState) => state);
  const { paciente } = useAtendimento();

  const [ultimoquestionario, setUltimoQuestionario] = useState<Questionarios>();
  const [sortBy, setSortBy] = useState('dataOcorrencia');
  const [permitirNovoQuestionario, setPermitirNovoQuestionario] =
    useState<any>();
  const [ultimoQuestionarioValidado, setUltimoQuestionarioValidado] =
    useState<Questionarios>();
  const [isLoading, setIsLoading] = useState(false);

  const novoDisclosure = useDisclosure({ opened: false });
  const inativarDisclosure = useDisclosure({ opened: false });
  const visualizarJustificativaDisclosure = useDisclosure({ opened: false });
  const deletarDisclosure = useDisclosure({ opened: false });

  const disclosures = {
    novoDisclosure,
    inativarDisclosure,
    visualizarJustificativaDisclosure,
    deletarDisclosure,
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await QuestionariosAPI.getPermitidoPreencherQuestionario(
        paciente?.id || user.idPaciente,
      );
      !!response && setPermitirNovoQuestionario(response);
      setIsLoading(false);
    })();
  }, [paciente?.id, user.idPaciente]);

  const [updateList, setUpdateList] = useState<number>(0);

  const menuOptions = useRef<any>();

  const fetchAPI = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ query, ...rest }: any): Promise<any> => {
      return QuestionariosAPI.getQuestionarioAtendimento(idAtendimento, {
        idPaciente: paciente?.id,
        sortBy: 'dataInclusao',
        sortDirection: 'DESC',
        ...rest,
      });
    },
    [idAtendimento, paciente?.id],
  );

  const fetchApiPortal = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ query, ...rest }: any): Promise<any> => {
      return PerfilSaudeAPI.getQuestionariosPortal(user?.idPaciente, {
        sortBy: sortBy,
        sortDirection: 'DESC',
        ...rest,
      });
    },
    [sortBy, user?.idPaciente],
  );

  const reloadList = useCallback(() => {
    setUpdateList(currentUpdate => currentUpdate + 1);
  }, []);

  const confirmExcluir = async (questionario: Questionarios) => {
    const idQuestionario = questionario?.id;
    const idAtendimentoQuestionario = questionario?.idAtendimento;

    let response: any;

    if (!idAtendimentoQuestionario) {
      response = await PerfilSaudeAPI.deleteQuestionarioPortal(idQuestionario);
    } else {
      response = await QuestionariosAPI.deleteQuestionarios(
        idQuestionario,
        String(idAtendimentoQuestionario),
      );
    }

    if (response?.status === 204) {
      reloadList();
      deletarDisclosure.close();
    }
  };

  const options = useMemo(() => {
    const items = [
      {
        label: 'Novo questionário',
        command: () => novoDisclosure.open({ state: { isNew: true } }),
      },
    ];

    if (ultimoquestionario) {
      items.push({
        label: 'Iniciar a partir do ultimo questionário',
        command: () =>
          novoDisclosure.open({
            state: { questionario: ultimoquestionario, isNew: true },
          }),
      });
    }
    return items;
  }, [novoDisclosure, ultimoquestionario]);

  const itemsOrdenation: MenuItem[] = [
    { label: 'Data de ocorrência', command: () => setSortBy('dataOcorrencia') },
    { label: 'Status', command: () => setSortBy('status') },
  ];

  return {
    ultimoquestionario,
    setUltimoQuestionario,
    novoDisclosure,
    inativarDisclosure,
    visualizarJustificativaDisclosure,
    deletarDisclosure,
    disclosures,
    updateList,
    setUpdateList,
    menuOptions,
    fetchAPI,
    fetchApiPortal,
    reloadList,
    confirmExcluir,
    user,
    options,
    sortBy,
    setSortBy,
    itemsOrdenation,
    permitirNovoQuestionario,
    ultimoQuestionarioValidado,
    setUltimoQuestionarioValidado,
    isLoading,
  };
};

export default useQuestionarioController;
