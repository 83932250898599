import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  parametro: Parametro;
  onEdit: (parametro: Parametro) => void;
  onDelete: (parametro: Parametro) => void;
}

export const Row = (props: RowProps) => {
  const { parametro, onEdit, onDelete } = props;

  const { isMobile } = useSize();

  const handleEdit = () => {
    onEdit(parametro);
  };

  const handleDelete = () => {
    onDelete(parametro);
  };

  const commonProps = {
    parametro,
    onEdit: handleEdit,
    onDelete: handleDelete,
  };

  return isMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
