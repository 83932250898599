import { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';
import * as Yup from 'yup';

import { FieldTipoFormulario } from './_components/FieldTipoFormulario';
import { Button } from 'src/components/_UI';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';

const validationschema = Yup.object({
  nome: Yup.string()
    .required('É necessário informar um nome.')
    .max(500, 'Valor máximo de caracteres excedido.'),
  descricao: Yup.string()
    .required('É necessário informar uma descrição.')
    .max(500, 'Valor máximo de caracteres excedido.'),
  tipo: Yup.object({
    codigo: Yup.string().required('É necessário informar um tipo.'),
  }).required('É necessário informar um tipo.'),
});

type FormData = Yup.InferType<typeof validationschema>;

export const AcervoFormulariosCreateGeral = ({
  item,
  handleSetItem,
  handleNextPage,
}: {
  item?: IFormularioDinamicoItem | null;
  handleSetItem(item: IFormularioDinamicoItem): void;
  handleNextPage(): void;
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const viewOnly = state?.to !== 'edit' && !!item;

  const defaultValues = useMemo(
    () => ({
      nome: item?.nome || '',
      descricao: item?.descricao || '',
      tipo: {
        codigo: item?.tipo || '',
      },
    }),
    [item],
  );

  const form = useForm<FormData>({
    resolver: yupResolver(validationschema),
    defaultValues,
  });

  const onSubmit = async (v: FormData) => {
    if (viewOnly) return handleNextPage();

    const payload = {
      ...v,
      tipo: v.tipo.codigo,
    };

    try {
      if (item) {
        const response = await FormularioDinamicoAPI.updateFormularioDinamico(
          { ...item, ...payload },
          { throwError: true },
        );
        return handleSetItem(response);
      }

      const response = await FormularioDinamicoAPI.createFormularioDinamico(
        payload,
        { throwError: true },
      );
      return navigate(
        `/cadastros/formulario-dinamico/acervo-formularios/${response.id}`,
        {
          state: {
            step: 1,
            to: 'edit'
          },
        },
      );
    } catch {}
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  return (
    <FormProvider {...form}>
      <form
        className="p-d-flex p-flex-column p-p-0 p-col-12 p-md-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <fieldset disabled={viewOnly}>
          <FormInput name="nome" label="Nome" max={500} />
          <TextareaInputControlled
            control={form.control}
            name="descricao"
            label="Descrição"
            maxLength={500}
            maxLengthSpan
          />
          <FieldTipoFormulario
            disabled={viewOnly}
            name="tipo"
            label="Tipo"
            className="p-p-0"
          />
        </fieldset>

        <div className="p-d-flex p-gap-2 p-my-4">
          <Button
            btnType="ghost"
            onClick={() =>
              navigate('/cadastros/formulario-dinamico/acervo-formularios')
            }
            label="Cancelar"
            stretch
            disabled={form.formState.isSubmitting}
          />
          <Button
            label="Próximo"
            type="submit"
            stretch
            loading={form.formState.isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};
