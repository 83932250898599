import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/pacote-exame';

const getPacotes = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: basePath,
    hideToast: true,
    throwError: true,
    params,
    ...options,
  });

const getPacoteById = async (
  idPacote: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPacote}`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const postPacote = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });

const putPacote = async (
  payload: any,
  idPacote: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPacote}`,
    data: payload,
    throwError: true,
    ...options,
  });

const switchStatusPacote = async (
  idPacote: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/alterar-status/${idPacote}`,
    throwError: true,
    ...options,
  });

const deletePacote = async (
  idPacote: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `${basePath}/${idPacote}`,
    throwError: true,
    ...options,
  });

export const PacoteAPI = {
  getPacotes,
  getPacoteById,
  postPacote,
  putPacote,
  switchStatusPacote,
  deletePacote,
};
