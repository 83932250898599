import { useAppDispatch } from 'src/core/redux/hooks';
import { clearProfissionais } from 'src/core/redux/slices/agenda/AgendaSlice';
import { clearUser } from 'src/core/redux/slices/system/UserSlice';
import { clearConsultorios } from 'src/core/redux/slices/user/ConsultoriosSlice';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const LogoutConfigSubMenu = () => {
  const dispatch = useAppDispatch();
  return (
    <div
      className={'ItemsConfigSubMenu'}
      onClick={() => {
        dispatch(clearUser());
        dispatch(clearProfissionais());
        dispatch(clearConsultorios());
      }}
    >
      <Button icon={'pi pi-sign-out'} btnType="gray" />
      <div className={'content'}>
        <SimpleText bold>Sair do eMed</SimpleText>
      </div>
    </div>
  );
};

export default LogoutConfigSubMenu;
