import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

interface PanelHeaderProps {
  opme: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  opme,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName = opme.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    opme.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  return (
    <div className="opme-panel-header p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-9 p-md-4 p-xl-5 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Nome Comercial
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {opme.material.nomeComercial}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-3 p-md-2 p-xl-1 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Código
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          <i className="fas fa-tag p-mr-2" />
          {opme.idMaterial}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-2 p-md-2 p-xl-1 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Quantidade
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          {opme.quantidade}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-6 p-md-2 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          {dayjs(opme.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      {opme.retroativo && (
        <div className="coluna-dados p-col-4 p-md-4 p-xl-1 retroativo-chip">
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        </div>
      )}
      <div className="coluna-acoes p-col p-md-coluna-dados p-d-flex p-jc-end p-ai-center">
        <Lembrete ativo={opme.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
