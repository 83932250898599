import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/tipos-cuidados';

export const getTipoCuidadoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoCuidadoDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const loadTipoCuidado = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoCuidadoDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};

export const sendTipoCuidado = (
  payload: PostTipoCuidadoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    ...options,
  });
};

export const updateTipoCuidado = (
  payload: PostTipoCuidadoDTO,
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    ...options,
  });
};

export const removeTipoCuidado = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

const TipoCuidadoAPI = {
  getTipoCuidadoById,
  loadTipoCuidado,
  sendTipoCuidado,
  updateTipoCuidado,
  removeTipoCuidado,
};

export default TipoCuidadoAPI;
