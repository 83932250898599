/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { ToggleButton } from 'primereact/togglebutton';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Mention from '../../../components/Mention/Mention';
import { Button } from 'src/components/_UI/Button';
import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ModalModelo from 'src/pages/Emed/Prontuario/components/ModalModelo/ModalModelo';
import { ModalNovoModelo } from 'src/pages/Emed/Prontuario/components/ModalNovoModelo/ModalNovoModelo';

import './AleitamentoAlimentacao.scss';

interface AleitamentoAlimentacaoProps {
  prontuario: any;
  campos: any[];
  getCampos: any;
  updateCamposProntuario: (campos: any) => void;
  ndnAleitamentoAlimentacao: any;
  selectedSubjetivos: any;
}

function AleitamentoAlimentacao({
  campos,
  prontuario,
  updateCamposProntuario,
  ndnAleitamentoAlimentacao,
  selectedSubjetivos,
}: AleitamentoAlimentacaoProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { agenda } = useAppSelector((state: RootState) => state);
  const { modelos, getModelos, setModelos } = useAtendimento();
  const { windowInnerWidth } = useSize();
  const hasLoaded = useRef(false);

  const isTablet = useMemo(() => windowInnerWidth <= 991, [windowInnerWidth]);

  const validationSchema = Yup.object().shape({
    valorCampoProntuario: Yup.string(),
  });
  const {} = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [valorCampoProntuario, setValorCampoProntuario] = useState<string>(
    prontuario.aleitamentoObservacao || '',
  );

  const [campoAleitamentoAlimentacao, setCampoAleitamentoAlimentacao] =
    useState<any>({});
  const [modelosAleitamentoAlimentacao, setModelosAleitamentoAlimentacao] =
    useState<any[]>([]);
  const [checked, setChecked] = useState<boolean>(
    campoAleitamentoAlimentacao.exibicaoCamposModelo === 'FIXO' ? true : false,
  );
  const [aleitamento, setAleitamento] = useState<string | null>(
    prontuario.aleitamento,
  );
  const [alimentacao, setAlimentacao] = useState<boolean>(
    prontuario.alimentacaoVariada || false,
  );

  const modalDisclosure = useDisclosure({ opened: false });

  const saveAction = useCallback(
    (payload: any) => {
      return UtilsAPI.putCampos(Number(idAtendimento), payload);
    },
    [idAtendimento],
  );

  const handleOnBlur = useCallback(
    async (data: any) => {
      const payload = {
        idAtendimento: Number(idAtendimento),
        camposModeloProntuario: 'ALEITAMENTO_ALIMENTACAO',
        campoExtra: 'ALEITAMENTO_OBSERVACAO',
        valorCampoProntuario: data,
        idPaciente: agenda?.idPaciente,
        idProfissionalSaude: agenda.profissionalAtivo.id,
      };

      updateCamposProntuario({
        ALEITAMENTO_ALIMENTACAO: {
          ALEITAMENTO_OBSERVACAO: data,
          ALEITAMENTO: aleitamento,
          ALIMENTACAO_VARIADA: alimentacao,
        },
      });

      await saveAction(payload);
    },
    [
      agenda?.idPaciente,
      agenda.profissionalAtivo.id,
      idAtendimento,
      saveAction,
      updateCamposProntuario,
    ],
  );

  const loadModeloPadrao = useCallback(() => {
    if (prontuario?.aleitamentoAlimentacao?.length > 0) {
      setValorCampoProntuario(prontuario.aleitamentoAlimentacao);
    } else if (
      !prontuario?.aleitamentoAlimentacao?.ALEITAMENTO_OBSERVACAO?.length
    ) {
      modelos.forEach(modelo => {
        if (modelo.campo === 'ALEITAMENTO_ALIMENTACAO' && modelo.padrao) {
          setValorCampoProntuario(modelo.texto + '\n');
          handleOnBlur(modelo.texto + '\n');
        }
      });
    }
  }, [modelos, prontuario.aleitamentoAlimentacao, handleOnBlur]);

  const loadModelosAleitamentoAlimentacao = useCallback(() => {
    const modelosCampo: any[] = [];
    modelos.forEach(modelo => {
      if (modelo.campo === 'ALEITAMENTO_ALIMENTACAO') {
        modelosCampo.push(modelo);
      }
    });
    setModelosAleitamentoAlimentacao(modelosCampo);
  }, [modelos]);

  const passData = useCallback(
    (data: any) => {
      setValorCampoProntuario(prev => prev + data.texto + '\n');
      handleOnBlur(valorCampoProntuario + data.texto + '\n');
    },
    [valorCampoProntuario],
  );

  useEffect(() => {
    const ndn = () => {
      if (
        (valorCampoProntuario?.length < 1 &&
          selectedSubjetivos.includes('ALEITAMENTO_ALIMENTACAO')) ||
        (valorCampoProntuario === null &&
          selectedSubjetivos.includes('ALEITAMENTO_ALIMENTACAO'))
      ) {
        setValorCampoProntuario('Nada digno de nota');
        handleOnBlur('Nada digno de nota');
      }
    };

    ndnAleitamentoAlimentacao.current = ndn;
  }, [
    handleOnBlur,
    ndnAleitamentoAlimentacao,
    selectedSubjetivos,
    valorCampoProntuario,
  ]);

  useEffect(() => {
    setCampoAleitamentoAlimentacao(
      campos.filter(
        campo => campo.funcionalidade === 'ALEITAMENTO_ALIMENTACAO',
      )[0],
    );
  }, [campos]);

  useEffect(() => {
    if (!hasLoaded.current) {
      loadModeloPadrao();
      hasLoaded.current = true;
    }
  }, [loadModeloPadrao]);

  useEffect(() => {
    setChecked(
      campoAleitamentoAlimentacao.exibicaoCamposModelo === 'FIXO'
        ? true
        : false,
    );
    loadModelosAleitamentoAlimentacao();
  }, [
    campoAleitamentoAlimentacao,
    getModelos,
    loadModelosAleitamentoAlimentacao,
  ]);

  const reloadCampos = () => {
    if (campoAleitamentoAlimentacao.exibicaoCamposModelo === 'FIXO') {
      campoAleitamentoAlimentacao.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoAleitamentoAlimentacao.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoAleitamentoAlimentacao.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }
    const exibixaoCampo =
      campoAleitamentoAlimentacao.exibicaoCamposModelo === 'FIXO'
        ? 'NENHUM'
        : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoAleitamentoAlimentacao.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const salvarAleitamento = async (data: string | null) => {
    setAleitamento(data);
    const payload = {
      idAtendimento: Number(idAtendimento),
      camposModeloProntuario: 'ALEITAMENTO_ALIMENTACAO',
      campoExtra: 'ALEITAMENTO',
      valorCampoProntuario: data,
      idPaciente: agenda?.idPaciente,
      idProfissionalSaude: agenda.profissionalAtivo.id,
    };
    updateCamposProntuario({
      ALEITAMENTO_ALIMENTACAO: {
        ALEITAMENTO_OBSERVACAO: valorCampoProntuario,
        ALEITAMENTO: data,
        ALIMENTACAO_VARIADA: alimentacao,
      },
    });

    await saveAction(payload);
  };

  const salvarAlimentacao = (data: boolean) => {
    setAlimentacao(data);
    const payload = {
      idAtendimento: Number(idAtendimento),
      camposModeloProntuario: 'ALEITAMENTO_ALIMENTACAO',
      campoExtra: 'ALIMENTACAO_VARIADA',
      valorCampoProntuario: data,
      idPaciente: agenda?.idPaciente,
      idProfissionalSaude: agenda.profissionalAtivo.id,
    };
    updateCamposProntuario({
      ALEITAMENTO_ALIMENTACAO: {
        ALEITAMENTO_OBSERVACAO: valorCampoProntuario,
        ALEITAMENTO: aleitamento,
        ALIMENTACAO_VARIADA: data,
      },
    });

    saveAction(payload);
  };

  const isRequired = campoAleitamentoAlimentacao.obrigatorio;

  return (
    <>
      <div className="p-d-flex p-flex-column">
        <div className="p-d-flex p-ai-center p-col-12">
          <SimpleText
            className="p-col-8 p-p-0"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Aleitamento
          </SimpleText>

          {!isTablet ? (
            <SimpleText
              className="p-col-4 p-pb-0 p-ml-4"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Alimentação
            </SimpleText>
          ) : (
            <Button
              label="Limpar"
              btnType="green-link"
              className="p-col-4 p-pb-0 p-ml-4"
              onClick={() => {
                salvarAlimentacao(false);
                salvarAleitamento('NENHUM');
              }}
            />
          )}
        </div>

        <div className="p-grid p-col-12 p-px-0 p-gap-2 p-ai-center">
          <RadioButton
            className="p-col-4 p-lg-3"
            name="aleitamento"
            label="Aleitamento materno"
            value="ALEITAMENTO_MATERNO"
            checked={aleitamento === 'ALEITAMENTO_MATERNO'}
            onChange={() => salvarAleitamento('ALEITAMENTO_MATERNO')}
          />
          <RadioButton
            className="p-col-4 p-lg-3"
            name="aleitamento"
            label="Aleitamento misto"
            value="ALEITAMENTO_MISTO"
            checked={aleitamento === 'ALEITAMENTO_MISTO'}
            onChange={() => salvarAleitamento('ALEITAMENTO_MISTO')}
          />
          <RadioButton
            className="p-col-3 p-lg-2"
            name="aleitamento"
            label="Artificial"
            value="ARTIFICIAL"
            checked={aleitamento === 'ARTIFICIAL'}
            onChange={() => salvarAleitamento('ARTIFICIAL')}
          />

          {isTablet && (
            <SimpleText
              className="p-col-12"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Alimentação
            </SimpleText>
          )}

          <RadioButton
            className="p-col-4 p-lg-2 p-ml-lg-2 p-ml-0"
            name="alimentacao"
            label="Alimentação variada"
            value="ALIMENTACAO_VARIADA"
            checked={alimentacao === true}
            onChange={() => salvarAlimentacao(true)}
          />

          {!isTablet && (
            <Button
              label="Limpar"
              btnType="green-link"
              className="p-col-2"
              onClick={() => {
                salvarAlimentacao(false);
                salvarAleitamento('NENHUM');
              }}
            />
          )}
        </div>

        <div>
          <div className="p-d-flex p-jc-between">
            <div className="p-as-end">
              <SimpleText
                className="p-ml-2"
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                Observações
              </SimpleText>
              {isRequired && (
                <SimpleText
                  className="p-ml-2 texto-obrigatorio"
                  fontSize={FONT_SIZE.XXXS}
                >
                  (Obrigatório)
                </SimpleText>
              )}
            </div>
            <div className="p-d-flex modelos">
              <Button
                btnType="green-link"
                label="Modelo"
                iconPos="right"
                icon="pi pi-chevron-down"
                overlay={
                  <ModalModelo
                    onOpenModal={modalDisclosure.open}
                    modelos={modelosAleitamentoAlimentacao}
                    passData={passData}
                    valorCampoProntuario={valorCampoProntuario}
                    setValorCampoProntuario={setValorCampoProntuario}
                  />
                }
              />

              <ToggleButton
                checked={checked}
                className={`p-px-4 p-py-1 pin ${
                  isRequired ? 'pin-disabled' : ''
                }`}
                onLabel={''}
                offLabel={''}
                onChange={e =>
                  !isRequired ? [handleFixar(), setChecked(e.value)] : null
                }
                onIcon="fa fa-thumbtack"
                offIcon="fa fa-thumbtack"
              />
            </div>
          </div>
          <div className="text-area p-pb-1">
            <form id="form-aleitamentoAlimentacao" className="p-mt-3">
              <Mention
                valorCampoProntuario={valorCampoProntuario}
                setValorCampoProntuario={setValorCampoProntuario}
                handleOnBlur={handleOnBlur}
              />
            </form>
          </div>
        </div>
      </div>

      {modalDisclosure.isOpen && (
        <ModalNovoModelo
          valorCampoProntuario={valorCampoProntuario}
          setValorCampoProntuario={setValorCampoProntuario}
          tipoCampo="ALEITAMENTO_ALIMENTACAO"
          {...modalDisclosure}
        />
      )}
    </>
  );
}

export default AleitamentoAlimentacao;
