import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface DorFrequenteProps {
  disabled?: boolean;
  readOnly?: boolean | undefined;
  values?: Questionarios | undefined;
}

enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  SEM_DOR = 'SEM_DOR',
  LIGEIRA = 'DOR_LIGEIRA',
  MODERADA = 'DOR_MODERADA',
  INTENSA = 'DOR_INTENSA',
  MAXIMA = 'DOR_MASSA',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

const DorFrequente = ({ values, readOnly }: DorFrequenteProps) => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();

  const dorNaoSeAplica = watch('dorNaoSeAplica');

  useEffect(() => {
    if (typeof dorNaoSeAplica === 'boolean') {
      const valueNaoSeAplica = dorNaoSeAplica
        ? Values.NAO_SE_APLICA
        : undefined;

      const fields = [
        'dorCostasFrequente',
        'dorCabecaFrequente',
        'dorRelacionadaTrabalho',
        'intensidadeDores',
      ];

      fields.forEach(field => {
        setValue(field, valueNaoSeAplica, {
          shouldValidate: dorNaoSeAplica,
        });
      });
    }
  }, [setValue, dorNaoSeAplica]);

  return (
    <Template
      title="Dor frequente"
      name="dorNaoSeAplica"
      readOnly={readOnly}
      resetField={resetField}
      control={control}
    >
      <div className="container-dor">
        <CardQuestions
          title="Sente dores nas costas frequentes?"
          name="dorCostasFrequente"
          control={control}
          disabled={readOnly || dorNaoSeAplica}
          defaultValue={values?.dorCostasFrequente}
          error={errors?.dorCostasFrequente?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="dorCostasFrequente"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || dorNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Sente dores de cabeça frequentes?"
          name="dorCabecaFrequente"
          control={control}
          disabled={readOnly || dorNaoSeAplica}
          defaultValue={values?.dorCabecaFrequente}
          error={errors?.dorCabecaFrequente?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="dorCabecaFrequente"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || dorNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Apresenta alguma dor relacionada ao seu trabalho?"
          name="dorRelacionadaTrabalho"
          control={control}
          disabled={readOnly || dorNaoSeAplica}
          defaultValue={values?.dorRelacionadaTrabalho}
          error={errors?.dorRelacionadaTrabalho?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="dorRelacionadaTrabalho"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || dorNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Em casos de dores, qual a intensidade:"
          name="intensidadeDores"
          control={control}
          disabled={readOnly || dorNaoSeAplica}
          defaultValue={values?.intensidadeDores}
          error={errors?.intensidadeDores?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="intensidadeDores"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sem dor',
                value: Values.SEM_DOR,
              },
              {
                label: 'Ligeira',
                value: Values.LIGEIRA,
              },
              {
                label: 'Moderada',
                value: Values.MODERADA,
              },
              {
                label: 'Intensa',
                value: Values.INTENSA,
              },
              {
                label: 'Máxima',
                value: Values.MAXIMA,
              },
            ]}
            disabled={readOnly || dorNaoSeAplica}
          />
        </CardQuestions>
      </div>
    </Template>
  );
};

export default DorFrequente;
