import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';


import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import { formatData } from '../helpers';

const ResponsavelLegal = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [vinculoFamiliarOptions, setVinculoFamiliarOptions] = useState<any>([]);

  const fetchVinculoFamiliarData = async () => {
    const response = await UtilsAPI.getVinculoFamiliar();

    setVinculoFamiliarOptions(formatData(response, 'descricao', 'id'));
  };

  useEffect(() => {
    fetchVinculoFamiliarData();
  }, []);

  return (
    <>
      <TextInputControlled
        className="p-col-12"
        control={control}
        name="nomeResponsavelLegal"
        label="Nome"
        errorMsg={errors.nomeResponsavelLegal?.message}
      />
      <DropdownControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="idGrauParentesco"
        label="Grau de parentesco"
        options={vinculoFamiliarOptions}
        errorMsg={errors.idGrauParentesco?.message}
      />
      <MaskedInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="cpfResponsavelLegal"
        label="CPF"
        placeholder="000.000.000-00"
        mask={MASK.CPF}
        errorMsg={errors.cpfResponsavelLegal?.message}
      />
    </>
  );
}

export default ResponsavelLegal;