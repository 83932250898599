import { memo } from 'react';

import './FormaPagamentoItem.scss';

import { ImageProps } from 'primereact/image';

import { ReactComponent as CardIcon } from 'src/assets/icons/card-ui.svg';

import AlertBox from 'src/components/AlertBox/AlertBox';
import CustomRadioButton from 'src/components/Basics/CustomRadioButton/CustomRadioButton';

interface FormaPagamentoItemProps extends ImageProps {
  name: string;
  checked: boolean;
  onClick: () => void;
  cartao: any;
  value?: any;
  alertMsg?: string | null;
}

const FormaPagamentoItem = ({
  src,
  value,
  checked,
  name,
  cartao,
  alertMsg,
  onClick,
}: FormaPagamentoItemProps) => {
  return (
    <>
      <CustomRadioButton
        name={name}
        checked={checked}
        value={value}
        onClick={onClick}
        component={
          <div className="FormaPagamentoItem">
            {src ? (
              <img
                src={src}
                alt={
                  cartao.convenio?.nomeFantasia || cartao.convenio.razaoSocial
                }
                className="FormaPagamentoItem-logo"
              />
            ) : (
              <div className="FormaPagamentoItem-logo p-d-flex p-jc-center p-ai-center">
                <i>
                  <CardIcon />
                </i>
              </div>
            )}

            <div className="FormaPagamentoItem-info">
              {cartao?.principal && (
                <span className="FormaPagamentoItem-slug">Principal</span>
              )}

              <span className="FormaPagamentoItem-codigo">
                {cartao?.numeroCartao}
              </span>

              <span className="FormaPagamentoItem-validade">
                {cartao?.convenio?.nomeFantasia ||
                  cartao?.convenio?.razaoSocial}
              </span>
            </div>
          </div>
        }
      />
      {alertMsg && <AlertBox visible text={alertMsg} />}
    </>
  );
};

export default memo(FormaPagamentoItem);
