import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onSwitchStatus: (data: any) => void;
}

export const Row = (props: RowProps) => {
  const { data, onEdit, onDelete, onSwitchStatus } = props;
  const { isMobile } = useSize();

  const cumonProps = {
    data,
    onEdit,
    onDelete,
    onSwitchStatus,
  };

  return isMobile ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
