import dayjs from 'dayjs';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape(
  {
    nome: Yup.string().required('Nome é obrigatório'),
    dataNascimento: Yup.date()
      .required('Data de nascimento é obrigatório')
      .max(dayjs().format('YYYY/MM/DD'), 'Data de nascimento inválida')
      .nullable(),
    idSexo: Yup.number().typeError('Selecione uma das opções'),
    nomeMae: Yup.string()
      .notRequired()
      .when('maeDesconhecida', {
        is: (maeDesconhecida: boolean) => !maeDesconhecida,
        then: Yup.string().required('Nome da mãe é obrigatório'),
      }),
    maeDesconhecida: Yup.boolean(),
    cpf: Yup.string()
      .when('estrangeiro', {
        is: (value: boolean) => !value,
        then: Yup.string().required('CPF é obrigatório'),
      })
      .nullable(),
    cns: Yup.string().nullable(),
    passaporte: Yup.string()
      .when('estrangeiro', {
        is: (value: boolean) => value,
        then: Yup.string().required('Passaporte é obrigatório'),
      })
      .nullable(),
    convenio: Yup.object().when(
      ['tipoPlano', 'numeroCartao', 'dataValidadeCartao'],
      {
        is: (...values: string[]) => values.some(value => !!value),
        then: Yup.object().required('Convênio é obrigatório'),
      },
    ),
    numeroCartao: Yup.string().when(
      ['convenio', 'tipoPlano', 'dataValidadeCartao'],
      {
        is: (...values: string[]) => values.some(value => !!value),
        then: Yup.string().required('Número do cartão é obrigatório'),
      },
    ),
    dataValidadeCartao: Yup.date()
      .nullable()
      .when(['convenio', 'tipoPlano', 'numeroCartao'], {
        is: (...values: string[]) => values.some(value => !!value),
        then: Yup.date()
          .nullable()
          .required('Validade do cartão é obrigatório'),
      }),
  },
  [
    ['convenio', 'tipoPlano'],
    ['convenio', 'numeroCartao'],
    ['convenio', 'dataValidadeCartao'],
    ['tipoPlano', 'numeroCartao'],
    ['tiploPlano', 'dataValidadeCartao'],
    ['numeroCartao', 'dataValidadeCartao'],
    ['numeroCartao', 'tipoPlano'],
    ['dataValidadeCartao', 'tipoPlano'],
    ['dataValidadeCartao', 'numeroCartao'],
  ],
);
