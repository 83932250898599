import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  historico: any;
  onEdit: () => void;
  onCopiar: () => void;
  onInativar: () => void;
  onDelete: () => void;
  isRetroativo: boolean;
  canEdit: boolean;
  isInativo: boolean;
  historicoDialogDisclosure: any;
}

const MobileRow = ({
  historico,
  onEdit,
  onCopiar,
  onInativar,
  onDelete,
  isRetroativo,
  canEdit,
  isInativo,
  historicoDialogDisclosure,
}: MobileRowProps) => {
  const options = [
    {
      label: canEdit ? 'Editar' : 'Copiar',
      className: '',
      command: canEdit ? onEdit : onCopiar,
    },
  ];

  isInativo
    ? options.push({
        label: 'Visualizar Inativação',
        className: '',
        command: onInativar,
      })
    : options.push({
        label: canEdit ? 'Excluir' : 'Inativar',
        className: 'warning-color',
        command: canEdit ? onDelete : onInativar,
      });

  return (
    <SearchListMobileRow options={options}>
      <div className="p-grid p-px-0 p-my-2 card-item mobile-card">
        <div className="p-col-12 p-p-1 p-d-flex p-ai-center">
          <SimpleText
            className={
              isInativo
                ? 'line-through p-px-3 p-py-2'
                : 'instrumento p-px-3 p-py-2'
            }
            onClick={() =>
              !isInativo
                ? historicoDialogDisclosure.open({ state: historico })
                : null
            }
            disabled={isInativo}
          >
            {historico.formulario}
          </SimpleText>

          <div className="p-d-flex p-ai-center p-jc-start">
            {isRetroativo && (
              <SimpleText className="tag tag-retroativo" fontSize="xxxs">
                Retroativo
              </SimpleText>
            )}
          </div>
        </div>
        <div className="p-col-12 p-grid p-px-3 p-pb-2">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className={isInativo ? 'line-through' : ''}
          >
            Preenchido por{' '}
            <strong className={isInativo ? '' : 'neutral-color'}>
              {historico.medico?.nome}
            </strong>
            {' • '}
            <span className={isInativo ? '' : 'neutral-color'}>
              {' '}
              {historico.medico?.conselhoTiss}/
              {historico.medico?.estadoConselho}{' '}
              {historico.medico?.numeroConselho}{' '}
            </span>
            em{' '}
            <span className={isInativo ? '' : 'neutral-color'}>
              {' '}
              {historico.dataOcorrencia
                ? dayjs(historico.dataOcorrencia).format(
                    'DD/MM/YYYY [às] HH:mm',
                  )
                : ''}{' '}
            </span>
          </SimpleText>
          {isRetroativo && (
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
              className={`p-col-12 p-px-0 ${isInativo ? 'line-through' : ''}`}
            >
              {' '}
              Registro retroativo em{' '}
              <span className={isInativo ? '' : 'neutral-color'}>
                {' '}
                {historico.dataOcorrencia
                  ? dayjs(historico.dataOcorrencia).format(
                      'DD/MM/YYYY [às] HH:mm',
                    )
                  : ''}{' '}
              </span>
            </SimpleText>
          )}
        </div>
        {historico.justificativaRetroativo && (
          <div className="p-grid p-px-3 p-pb-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
              className={`p-col-12 p-px-0 ${isInativo ? 'line-through' : ''}`}
            >
              Justificativa
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
              className={isInativo ? 'line-through' : ''}
            >
              {historico.justificativaRetroativo}
            </SimpleText>
          </div>
        )}
      </div>
    </SearchListMobileRow>
  );
};

export default MobileRow;
