import { useState, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import VacinasAPI from 'src/APIs/ProntuarioAPI/VacinasAPI/VacinasAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import DropdownVacina from './DropdownVacina/DropdownVacina';

import './ModalNovaVacina.scss';
interface ModalNovaVacinaProps {
  visible: boolean;
  editVacina: PacientesVacinas | null;
  novaDose: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

function ModalNovaVacina({
  visible,
  editVacina,
  novaDose,
  onHide,
  onSuccess,
}: ModalNovaVacinaProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { user } = useAppSelector((state: RootState) => state);

  const { atendimentoStatus } = useAtendimento();

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    descricao: Yup.string().required('Selecione ou digite uma nova vacina'),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(editVacina?.dataInclusao),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const [observacoesVisible, setObservacoesVisible] = useState<boolean>(false);
  const [dataProximaDoseVisible, setDataProximaDoseVisible] =
    useState<boolean>(false);

  useEffect(() => {
    reset();

    if (editVacina) {
      setValue('descricao', editVacina.descricao);

      setValue('lembrete', editVacina.lembrete);
      setValue('numeroDose', editVacina?.numeroDose);
      setValue('aplicadaEmAtraso', editVacina.aplicadaEmAtraso);
      if (editVacina?.motivoAtraso)
        setValue('motivoAtraso', editVacina.motivoAtraso);
      if (editVacina?.vacina) setValue('idVacina', editVacina?.vacina?.id);
      if (editVacina?.dataAplicacao)
        setValue('dataAplicacao', new Date(editVacina.dataAplicacao));
      if (editVacina?.dataProximaDose) {
        setValue('dataProximaDose', new Date(editVacina.dataProximaDose));
        setDataProximaDoseVisible(true);
      }
      if (editVacina?.observacao) {
        setValue('observacao', editVacina.observacao);
        setObservacoesVisible(true);
      }
      if (editVacina?.justificativaOcorrenciaRetroativa && !novaDose) {
        setValue(
          'justificativaOcorrenciaRetroativa',
          editVacina.justificativaOcorrenciaRetroativa,
        );
      }

      if (novaDose) {
        setValue('numeroDose', (editVacina?.numeroDose || 0) + 1);
      }
    } else {
      setValue('numeroDose', 1);
      setObservacoesVisible(false);
      setDataProximaDoseVisible(false);
    }
  }, [visible, reset, editVacina, setValue, novaDose]);

  const saveAction = useCallback(
    (payload: any) => {
      if (!novaDose && editVacina)
        return VacinasAPI.alterarPacienteVacina(editVacina.id, payload);

      return VacinasAPI.adicionarVacina(payload);
    },
    [editVacina, novaDose],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        ...data,
        idEmpresa: user.idEmpresa,
        idAtendimento: Number(idAtendimento),
        dataOcorrencia: dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
      };

      const response = await saveAction(payload);

      if (response?.status === 200 || response?.status === 201) {
        onSuccess();
        onHide();
      }
    },
    [user.idEmpresa, idAtendimento, saveAction, onSuccess, onHide],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const watchAplicadaEmAtraso = watch('aplicadaEmAtraso');

  const checkDisabledOnEdit = useMemo(() => {
    if (!editVacina || novaDose) return false;

    return !(
      editVacina.idAtendimento === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [atendimentoStatus, editVacina, idAtendimento, novaDose]);

  return (
    <Dialog
      id="modal-nova-vacina"
      type="modal"
      header={editVacina && !novaDose ? 'Editar vacina' : 'Nova vacina'}
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
      draggable={false}
      maximizedTabletSize
    >
      <form id="form-vacina" className="p-grid">
        <DataOcorrencia
          control={control}
          defaultValue={
            editVacina && !novaDose
              ? new Date(editVacina.dataOcorrencia)
              : new Date()
          }
          dataInclusao={editVacina?.dataInclusao}
          disabled={checkDisabledOnEdit}
        />

        <DropdownVacina control={control} disabled={checkDisabledOnEdit} />

        <TextInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="numeroDose"
          label="Dose da vacina"
          placeholder="00 Dose"
          permitirApenasNumeros
          errorMsg={errors.numeroDose?.message}
          disabled={checkDisabledOnEdit}
        />

        <div className="p-col-12 p-sm-6">
          <CalendarInputControlled
            control={control}
            name="dataAplicacao"
            label="Data da aplicação"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            maxDate={new Date()}
            errorMsg={errors.dataAplicacao?.message}
            disabled={checkDisabledOnEdit}
          />

          <CheckboxControlled
            className="checkbox-small-vacina"
            control={control}
            name="aplicadaEmAtraso"
            label="Vacina aplicada em atraso"
            disabled={checkDisabledOnEdit}
          />
        </div>

        {watchAplicadaEmAtraso && (
          <TextInputControlled
            className="p-col-12"
            control={control}
            name="motivoAtraso"
            label="Motivo do atraso"
            placeholder="Motivo do atraso"
            disabled={checkDisabledOnEdit}
          />
        )}

        {observacoesVisible && (
          <TextareaInputControlled
            className="p-col-12"
            control={control}
            name="observacao"
            label="Observações"
          />
        )}

        <div className="p-col-12">
          <Button
            type="button"
            btnType="green-link"
            label={`${
              observacoesVisible ? 'Remover' : 'Adicionar'
            } observações`}
            icon={observacoesVisible ? 'pi pi-minus' : 'pi pi-plus'}
            className={observacoesVisible ? 'danger' : ''}
            onClick={() => setObservacoesVisible(visible => !visible)}
          />
        </div>

        {dataProximaDoseVisible && (
          <CalendarInputControlled
            className="p-col-12"
            control={control}
            name="dataProximaDose"
            label="Data da próxima dose"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date(dayjs().add(1, 'day').format())}
          />
        )}

        <div className="p-col-12">
          <Button
            type="button"
            btnType="green-link"
            icon={dataProximaDoseVisible ? 'pi pi-minus' : 'pi pi-plus'}
            className={dataProximaDoseVisible ? 'danger' : ''}
            onClick={() => setDataProximaDoseVisible(visible => !visible)}
            label={`${
              dataProximaDoseVisible ? 'Remover' : 'Adicionar'
            } data para próxima dose`}
          ></Button>
        </div>

        <CheckboxControlled
          className="checkbox-small-vacina p-col-12"
          control={control}
          name="lembrete"
          label='Exibir em "lembretes"'
        />
      </form>
    </Dialog>
  );
}

export default ModalNovaVacina;
