import { useEffect, useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';

import { Header } from './Header';
import ModalAddProfissional from './ModalAddProfissional';
import Profissional from './Profissional';

const ProfissionaisList = () => {
  const { control, getValues, reset, clearErrors } = useFormContext();
  const {
    fields: equipeProfissionaisSaudeFields,
    append: appendProfissionalFields,
    remove: removeProfissionalFields,
    update: updateProfissionalFields,
  } = useFieldArray({
    control,
    name: 'equipeProfissionaisSaude',
    keyName: 'uuid',
  });
  const { isMobile } = useSize();
  const modalAddProfissionais = useDisclosure({ opened: false });

  const [filteredProfissionais, setFilteredProfissionais] = useState<any>(
    equipeProfissionaisSaudeFields,
  );
  const [query, setQuery] = useState<string>('');

  const updateOldResponsavelToFalse = () => {
    const oldResponsavel = equipeProfissionaisSaudeFields.findIndex(
      (old: any) => old.responsavel === true,
    );

    if (oldResponsavel !== -1)
      updateProfissionalFields(oldResponsavel, {
        ...equipeProfissionaisSaudeFields[oldResponsavel],
        responsavel: false,
      });
  };

  const handleChangeProfissionalResponsavel = (
    idx: number,
    profissional: any,
  ) => {
    updateOldResponsavelToFalse();

    updateProfissionalFields(idx, profissional);

    clearErrors('equipeProfissionaisSaude');
  };

  const handleRemove = (idx: number) => {
    removeProfissionalFields(idx);
    reset(getValues());
  };

  useEffect(() => {
    const filtered = equipeProfissionaisSaudeFields.filter(
      (profissional: any) => {
        const nome = profissional.profissionalSaude.usuario.nome.toLowerCase();
        const valueLowerCase = query.toLowerCase();

        return nome.includes(valueLowerCase);
      },
    );

    setFilteredProfissionais(filtered);
  }, [equipeProfissionaisSaudeFields, query]);

  return (
    <div className="p-col-12">
      <div className="p-grid p-col-12 p-px-0">
        <SimpleText className="p-col-12" medium>
          Profissionais
        </SimpleText>

        <div className="p-col-12 p-sm-7 p-md-9">
          <TextInputSearch
            placeholder="Pesquisar pelo nome"
            onChange={setQuery}
            value={query}
          />
        </div>

        <div className="p-col-12 p-sm-5 p-md-3 p-d-flex p-ai-end p-pb-0 p-pr-0">
          <Button
            icon="fa fa-plus"
            iconPos="left"
            btnType="tonal"
            type="button"
            label="Adicionar profissionais"
            onClick={() => modalAddProfissionais.open()}
            className="p-col-12 "
          />
        </div>
      </div>
      <div className="search-list-border-container p-col-12">
        {!isMobile && (
          <div className="search-list-header">
            <Header />
          </div>
        )}
        <div id="lista-profissionais">
          {!equipeProfissionaisSaudeFields.length && (
            <Card className="p-mb-2 p-text-center" type={CARD_TYPES.GRAY}>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                Não há profissionais na equipe.
              </SimpleText>
            </Card>
          )}

          {filteredProfissionais?.map((profissional: any, index: number) => (
            <Profissional
              key={profissional.uuid}
              index={index}
              onRemove={handleRemove}
              profissional={profissional}
              handleChangeProfissionalResponsavel={
                handleChangeProfissionalResponsavel
              }
            />
          ))}
        </div>
      </div>
      {modalAddProfissionais.isOpen && (
        <ModalAddProfissional
          {...modalAddProfissionais}
          profissionaisEquipe={equipeProfissionaisSaudeFields}
          appendProfissionalFields={appendProfissionalFields}
          updateOldResponsavelToFalse={updateOldResponsavelToFalse}
        />
      )}
    </div>
  );
};

export default ProfissionaisList;
