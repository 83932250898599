import { useFormContext } from 'react-hook-form';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

const options = [
  { label: 'Não realizada', value: 'NAO_REALIZADA' },
  { label: 'Realizada', value: 'REALIZADA' },
];

const Doencas = () => {
  const { control } = useFormContext();

  const { atendimentoStatus } = useAtendimento();

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
        HIV
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hivPrimeiroTrimestre"
        label="1º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hivSegundoTrimestre"
        label="2º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <SimpleText
        className="p-col-12 p-mt-3"
        fontColor={FONT_COLOR.COLOR_60}
        medium
      >
        Rubeola
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="rubeolaPrimeiroTrimestre"
        label="1º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="rubeolaSegundoTrimestre"
        label="2º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <SimpleText
        className="p-col-12 p-mt-3"
        fontColor={FONT_COLOR.COLOR_60}
        medium
      >
        VDRL
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="vdrlPrimeiroTrimestre"
        label="1º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="vdrlSegundoTrimestre"
        label="2º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <SimpleText
        className="p-col-12 p-mt-3"
        fontColor={FONT_COLOR.COLOR_60}
        medium
      >
        Hepatite B
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hepatitebPrimeiroTrimestre"
        label="1º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hepatitebSegundoTrimestre"
        label="2º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <SimpleText
        className="p-col-12 p-mt-3"
        fontColor={FONT_COLOR.COLOR_60}
        medium
      >
        Hepatite C
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hepatitecPrimeiroTrimestre"
        label="1º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hepatitecSegundoTrimestre"
        label="2º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <SimpleText
        className="p-col-12 p-mt-3"
        fontColor={FONT_COLOR.COLOR_60}
        medium
      >
        Toxoplasmose
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="toxoplasmosePrimeiroTrimestre"
        label="1º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="toxoplasmoseSegundoTrimestre"
        label="2º Trimestre"
        options={options}
        filter={false}
        disabled={!isAtendimentoRunning}
      />
    </>
  );
};

export default Doencas;
