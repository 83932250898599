import { useFormContext } from 'react-hook-form';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const Urocult = () => {
  const { control } = useFormContext();

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
        Urocult
      </SimpleText>

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="urocultPrimeiroTrimestre"
        label="1º Trimestre"
        maxLength={30}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="urocultTerceiroTrimestre"
        label="3º Trimestre"
        maxLength={30}
      />

      <div className="p-col-12 p-md-6 p-px-0">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
          Urina 1: Leucócitos
        </SimpleText>

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="urinaUrocultLeococitosPrimeiroTrimestre"
          label="1º Trimestre"
          maxLength={30}
        />
      </div>

      <div className="p-col-12 p-md-6 p-px-0">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
          Hemácias
        </SimpleText>

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="hemaciasUrocultPrimeiroTrimestre"
          label="1º Trimestre"
          maxLength={30}
        />
      </div>
    </>
  );
};

export default Urocult;
