import Http, { HttpParams } from 'src/core/http/Http';

export const getSuporteCpf = (
  cpf: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/usuarios/empresas/por-cpf/${cpf}`,
    return: true,
    hideToast: true,
    headers: {
      Authorization: '',
    },
    ...options,
  });
};

export const getSuporteEmpresa = (
  cpf: string,
  empresa: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/suporte/por-cpf/${cpf}/empresa/${empresa}`,
    return: true,
    hideToast: true,
    headers: {
      Authorization: '',
    },
    ...options,
  });
};

const SuporteAPI = {
  getSuporteCpf,
  getSuporteEmpresa,
};

export default SuporteAPI;
