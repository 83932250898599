import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps, DropdownFilterParams } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import HospitalAPI from 'src/APIs/AdminAPI/HospitalAPI/HospitalAPI';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type FieldHospitalProps = DropdownProps & {
  name?: string;
  label: string;
  params?: any;
};

export const FieldHospital = ({
  name = 'hospital',
  label,
  params,
  ...rest
}: FieldHospitalProps) => {
  const { control, watch } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [hospitais, setHospitais] = useState<Hospital[]>([]);
  const [loadingSearchHospital, setLoadingSearchHospital] = useState(false);
  const [querySearchHospital, setQuerySearchHospital] = useState('');

  const debouncedSearchConvenio = useDebounce(querySearchHospital, 350);

  const fetchHospitais = useCallback(
    async (queryParam: any) => {
      if (!queryParam) return setHospitais([]);

      try {
        const response = await HospitalAPI.loadHospitals({
          nome: queryParam,
          ...params,
        });

        setHospitais(response.list);
      } catch (error) {
        setHospitais([]);
      } finally {
        setLoadingSearchHospital(false);
      }
    },
    [params],
  );

  useEffect(() => {
    fetchHospitais(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, fetchHospitais]);

  const hospitalAtual: Hospital = watch(name);

  const hospitaisOptions = useMemo(() => {
    const options = hospitais.map(hospital => ({
      label: hospital.nome,
      value: hospital,
    }));

    // valida se convênio atual está listado nas options
    if (hospitalAtual) {
      const ishospitalAtualVisivel = hospitais.find(
        conv => conv.id === hospitalAtual.id,
      );

      if (!ishospitalAtualVisivel) {
        options.unshift({
          label: hospitalAtual.nome,
          value: hospitalAtual,
        });
      }
    }

    return options;
  }, [hospitais, hospitalAtual]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setLoadingSearchHospital(true);
    setQuerySearchHospital(filter);
  };

  const options = hospitaisOptions.length ? hospitaisOptions : defaultOption;
  const emptyFilterMessage = querySearchHospital.length
    ? 'Nenhuma opção encontrada'
    : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loadingSearchHospital}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
