/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment, useCallback, useEffect, useState } from 'react';

import Dropzone, { Accept } from 'react-dropzone';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import axios from 'axios';
import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

import { Button } from 'src/components/_UI';
import { Checkbox } from 'src/components/_UI/Checkbox';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';

import './LogoCliente.scss';

interface LogoProps {
  url: string | undefined;
  name: string;
  size: number;
  type: string;
}

interface LogoClientePageProps {
  dadosLogo: Partial<Empresa>;
  idEmpresa: number;
}

const LogoCliente = ({ dadosLogo, idEmpresa }: LogoClientePageProps) => {
  const navigate = useNavigate();

  const [temLogo, setTemLogo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dadosLocalLogo, setDadosLocalLogo] = useState<LogoProps>({
    url: '',
    name: '',
    size: 0,
    type: '',
  });
  const [logoRelatorios, setLogoRelatorios] = useState(false);
  const [logoSistema, setLogoSistema] = useState(false);
  const [fileBruto, setFileBruto] = useState<any>({});
  const [googleUrl, setGoogleUrl] = useState('');

  useEffect(() => {
    if (dadosLogo && Object.keys(dadosLogo).length > 0) {
      setGoogleUrl(dadosLogo?.logo!);
      setDadosLocalLogo({ ...dadosLocalLogo, url: dadosLogo?.logoUrl });
      setTemLogo(true);
      setLogoRelatorios(dadosLogo?.exibeLogoRelatorio!);
      setLogoSistema(dadosLogo?.exibeLogoSistema!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosLogo]);

  const toastData = useCallback((type: 'success' | 'error') => {
    return {
      title: type === 'success' ? 'Sucesso' : 'Atenção',
      message:
        type === 'success'
          ? 'Upload de imagem para o sistema concluída com sucesso'
          : 'Ocorreu um erro ao realizar o upload de imagem para o sistema',
      type,
    };
  }, []);

  const handleReset = useCallback(() => {
    setDadosLocalLogo({ url: '', name: '', size: 0, type: '' });
    setFileBruto({});
    setGoogleUrl('');
    setLogoRelatorios(false);
    setLogoSistema(false);
    setTemLogo(false);
    navigate(-1);
  }, [navigate]);

  const handleRemoveUrl = useCallback(() => {
    setDadosLocalLogo({ url: '', name: '', size: 0, type: '' });
    setFileBruto({});
    setGoogleUrl('');
    setTemLogo(false);
  }, []);

  const handleUploadLocal = useCallback(async (file: any) => {
    const localUrl = await URL.createObjectURL(file[0]);
    setFileBruto(file);

    await setDadosLocalLogo({
      url: localUrl,
      name: file[0]?.name,
      size: file[0]?.size,
      type: file[0]?.type,
    });
  }, []);

  const handleUploadToSystem = useCallback(async () => {
    const dataFile: FileUpload = {
      fileName: dadosLocalLogo?.name,
      contentType: dadosLocalLogo?.type,
      contentLength: dadosLocalLogo?.size,
      resourceType: 'EMPRESA',
      resourceId: idEmpresa,
    };
    const responseGoogleStorage: any = await FileUploadAPI.sendFileUpload(
      dataFile,
    );

    if (responseGoogleStorage?.status === 200) {
      const urlGoogle = responseGoogleStorage?.data?.uploadUrl;

      const data = fileBruto[0];

      const response: any = await axios.put(urlGoogle, data, {
        headers: {
          'Content-Type': dadosLocalLogo?.type,
        },
      });

      if (response?.status === 200) {
        setGoogleUrl(responseGoogleStorage?.data?.filePath);
        return toast(<Toast />, { data: toastData('success') });
      }
    }
    return toast(<Toast />, { data: toastData('error') });
  }, [dadosLocalLogo, toastData, fileBruto, idEmpresa]);

  const handleSendLogo = useCallback(async () => {
    const dataFile = {
      idEmpresa: idEmpresa,
      logo: googleUrl,
      exibeLogoSistema: logoSistema,
      exibeLogoRelatorio: logoRelatorios,
    };

    const responseSendLogo: any = await EmpresasAPI.updateLogoEmpresa(
      idEmpresa,
      dataFile,
    );

    if (responseSendLogo?.status === 200) {
      handleReset();
    }
  }, [googleUrl, logoRelatorios, logoSistema, idEmpresa, handleReset]);

  const accept: Accept = {
    'image/jpeg': ['.jpeg', '.png'],
  };

  return (
    <div className="LogoCliente p-grid p-col-12 p-px-0">
      <SimpleText
        className="p-col-12"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
      >
        Logo do cliente
      </SimpleText>
      <div className="p-col-12 logo-container">
        <div className="logo-c-header">
          <Dropzone
            onDrop={acceptedFiles => handleUploadLocal(acceptedFiles)}
            accept={accept}
            maxFiles={1}
          >
            {({ getRootProps, getInputProps }) => (
              <Fragment>
                <Button
                  btnType="tonal"
                  icon="fa fa-plus"
                  label="Adicionar"
                  disabled={!!dadosLocalLogo?.url}
                  {...getRootProps()}
                  stretch
                />
                <input {...getInputProps()} />
              </Fragment>
            )}
          </Dropzone>
        </div>
        {dadosLocalLogo?.url !== '' ? (
          <div className="logo-c-body-preview">
            <div className="logo-c-body-item">
              <div className="logo-c-body-item-img">
                <img src={dadosLocalLogo?.url} alt="logo-preview" />
              </div>
              <div className="logo-c-body-item-infos">
                <SimpleText
                  fontColor={FONT_COLOR.PRIMARY}
                  fontSize={FONT_SIZE.XS}
                  medium
                >
                  {dadosLocalLogo?.name}
                </SimpleText>

                <div className="logo-c-body-item-infos-right">
                  {dadosLocalLogo?.size > 0 && (
                    <SimpleText
                      className="p-mr-1"
                      fontColor={FONT_COLOR.COLOR_40}
                      fontSize={FONT_SIZE.XS}
                    >
                      {dadosLocalLogo?.size} KB
                    </SimpleText>
                  )}

                  <Button
                    btnType="gray"
                    icon="fa fa-trash"
                    onClick={handleRemoveUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Dropzone
            onDrop={acceptedFiles => handleUploadLocal(acceptedFiles)}
            accept={accept}
            maxFiles={1}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="logo-c-body p-ai-center" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="logo-c-body-icon">
                  <i className="fa fa-file"></i>
                </div>
                <SimpleText
                  className="p-mb-1"
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  Você pode arrastar seus arquivos aqui.
                </SimpleText>
                <SimpleText
                  className="p-mb-"
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Formato aceito: PNG e JPEG
                </SimpleText>
              </div>
            )}
          </Dropzone>
        )}
        <div className="logo-c-footer p-grid p-p-0">
          <div className="p-col-12 p-sm-4">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={handleReset}
              stretch
            />
          </div>
          <div className="p-col-12 p-sm-8">
            <Button
              btnType="tonal"
              icon="fa fa-arrow-up"
              label="Enviar para o sistema"
              disabled={dadosLocalLogo?.url === '' || temLogo}
              type="button"
              onClick={handleUploadToSystem}
              stretch
            />
          </div>
          {visible && (
            <AlertBox
              visible={visible}
              onHide={() => setVisible(false)}
              text="Pressione “enviar” para iniciar o upload"
            />
          )}
        </div>
      </div>

      <SimpleText
        className="p-col-12 p-mt-3"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
      >
        Customização
      </SimpleText>

      <div className="p-col-12 logo-container p-mb-3">
        <Checkbox
          label="Exibir logo do cliente nos relatórios gerados"
          checked={logoRelatorios}
          value={logoRelatorios}
          onChange={t => setLogoRelatorios(t.checked)}
        />
        <Checkbox
          label="Exibir logo do cliente no sistema"
          checked={logoSistema}
          value={logoSistema}
          onChange={t => setLogoSistema(t.checked)}
        />
      </div>

      <div className="p-col-12 p-sm-4">
        <Button
          btnType="ghost"
          label="Cancelar"
          onClick={handleReset}
          stretch
        />
      </div>
      <div className="p-col-12 p-sm-8">
        <Button
          label="Salvar"
          disabled={googleUrl === ''}
          onClick={handleSendLogo}
          stretch
        />
      </div>
    </div>
  );
};

export default LogoCliente;
