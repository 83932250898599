import { useFormContext } from 'react-hook-form';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import { useCep } from './hooks/useCep';
import { useCity } from './hooks/useCity';
import { useCountry } from './hooks/useCountry';
import { useFederativeUnit } from './hooks/useFederativeUnit';

const tipoLogradouroOptions = [
  {
    label: 'Residencial',
    value: 'RESIDENCIAL',
  },
  {
    label: 'Comercial',
    value: 'COMERCIAL',
  },
];

export const Address = () => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const { stateOptions } = useFederativeUnit();
  const { cityOptions, handleStateSelect } = useCity({ setValue, getValues });
  const { countryOptions, handleCountrySelect } = useCountry({ setValue });
  const { loadingCepSearch, handleSearchAddressByCep } = useCep({
    setValue,
    getValues,
    handleStateSelect,
  });

  const idPaisEstrangeiro = watch('endereco.idPaisEstrangeiro');

  return (
    <>
      <SimpleText
        className="p-col-12 p-my-2"
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Endereço
      </SimpleText>
      <SimpleText className="p-col-12 p-mb-2" fontColor={FONT_COLOR.COLOR_16}>
        Digite o CEP para preencher automaticamente os campos
      </SimpleText>
      <MaskedInputControlled
        control={control}
        name="endereco.cep"
        label="CEP"
        placeholder="00000-000"
        className="p-col-8"
        mask={MASK.CEP}
        errorMsg={errors.endereco?.cep?.message}
      />

      <div
        className={`p-col-4 p-as-${
          errors.endereco?.cep?.message ? 'center' : 'end'
        }`}
      >
        <Button
          className="p-w-100 "
          type="button"
          btnType={BtnTypes.TONAL}
          onClick={handleSearchAddressByCep}
          loading={loadingCepSearch}
          label={loadingCepSearch ? 'Pesquisando' : 'Pesquisar'}
        />
      </div>
      <Divider />
      <DropdownControlled
        control={control}
        name="endereco.idPaisEstrangeiro"
        label="País"
        className="p-col-12"
        options={countryOptions}
        customOnChange={handleCountrySelect}
        disabled={!countryOptions.length}
        errorMsg={errors.endereco?.idPaisEstrangeiro?.message}
      />
      <DropdownControlled
        control={control}
        name="endereco.estado"
        label="Estado"
        className="p-col-12 p-md-6"
        options={stateOptions}
        customOnChange={handleStateSelect}
        disabled={
          !stateOptions.length || loadingCepSearch || idPaisEstrangeiro !== 0
        }
        errorMsg={errors.endereco?.estado?.message}
      />
      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="endereco.idCidade"
        label="Cidade"
        options={cityOptions}
        disabled={
          !cityOptions.length || loadingCepSearch || idPaisEstrangeiro !== 0
        }
        errorMsg={errors.endereco?.idCidade?.message}
      />
      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="endereco.tipo"
        label="Tipo do logradouro"
        options={tipoLogradouroOptions}
        errorMsg={errors.endereco?.tipo?.message}
        filter={false}
      />
      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="endereco.bairro"
        label="Bairro"
        errorMsg={errors.endereco?.bairro?.message}
        maxLength={100}
      />
      <TextInputControlled
        className="p-col-12"
        control={control}
        name="endereco.logradouro"
        label="Logradouro"
        errorMsg={errors.endereco?.logradouro?.message}
        maxLength={255}
      />
      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="endereco.numero"
        label="Número"
        permitirApenasNumeros
        errorMsg={errors.endereco?.numero?.message}
        maxLength={10}
      />
      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="endereco.complemento"
        label="Complemento"
        maxLength={255}
      />
    </>
  );
};
