import { useCallback, useEffect, useMemo, useState } from 'react';

import { InputSwitchChangeParams } from 'primereact/inputswitch';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import MedicoAPI from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';
import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { handleDownloadXLS, handleOpenPDF } from 'src/utils/files';

import { Checkbox } from 'src/components/_UI/Checkbox';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { InputText } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldSelectConsultorios } from 'src/components/Fields/FieldSelectConsultorios/FieldSelectConsultorios';
import { FieldSelectMedicos } from 'src/components/Fields/FieldSelectMedicos/FieldSelectMedicos';
import { FieldSelectPacientes } from 'src/components/Fields/FieldSelectPaciente/FieldSelectPaciente';
import Separator from 'src/components/Separator/Separator';
import Switch from 'src/components/Switch/Switch';

import './RelatoriosAtendimentos.scss';

const RelatoriosAtendimentos = () => {
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(true);
  const [checkedPaciente, setCheckedPaciente] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const {
    user,
    consultorios: { ativo, disponiveis },
    agenda,
  } = useAppSelector((state: RootState) => state);

  const [medicosConsultorio, setMedicosConsultorio] = useState<Medico[]>([]);

  const [loadingXLS, setLoadingXLS] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const formValidation = Yup.object().shape({
    periodo: Yup.array()
      .test(
        'oneOfRequired',
        'É necessário adicionar o periodo de tempo, com data inicial e final',
        function (item: any) {
          let response = false;
          if (item) {
            response = item[1] !== null;
          }

          return response;
        },
      )
      .typeError(
        'É necessário adicionar o periodo de tempo, com data inicial e final',
      ),
  });

  const checkboxFields = useMemo(() => {
    const data = [
      { name: 'ATENDIDO', label: 'Atendido' },
      { name: 'ATENDENDO', label: 'Atendendo' },
      { name: 'CONTINUAR', label: 'Continuar' },
    ];

    return !!search.length
      ? data.filter(item => item.name.includes(search.toUpperCase()))
      : data;
  }, [search]);

  const form = useForm({
    resolver: yupResolver(formValidation),
  });

  useEffect(() => {
    if (ativo?.id)
      MedicoAPI.loadMedicosById(ativo?.id).then(setMedicosConsultorio);
  }, [ativo?.id]);

  const handleRequestReport = useCallback(async () => {
    const {
      periodo,
      idsPacientes,
      incluirExamesSolicitados,
      exibirComplementoProntuario,
      medicos = [],
      consultorios: consultoriosSelected,
      type: typeExport,
      ...values
    } = form.getValues();

    const dataInicio = dayjs(periodo[0]).startOf('date').toDate();
    const dataFim = dayjs(periodo[1]).endOf('date').toDate();

    const idsConsultorios = (
      consultoriosSelected?.length > 0 ? consultoriosSelected : []
    ).map((con: any) => con.id);

    const medicosSelecionados = medicos?.map((medico: any) => medico.id);

    const status = Object.keys(values).filter(key => !!values[key]);

    if (!status.length) {
      return setErrorStatus(true);
    } else setErrorStatus(false);

    if (typeExport === 'pdf') {
      setLoadingPDF(true);
    } else {
      setLoadingXLS(true);
    }

    const aux: any = {
      dataInicio,
      dataFim,
      idsConsultorios,
      idsPacientes,
      exibirComplementoProntuario,
      incluirExamesSolicitados,
      listStatus: status,
      ...(user.tipoUsuario === 'SECRETARIO' || user.tipoUsuario === 'SECRETARIA'
        ? {
            idsMedicos:
              medicosSelecionados.length > 0 ? medicosSelecionados : [],
          }
        : { idsMedicos: [agenda?.profissionalAtivo?.id] }),
    };

    await RelatoriosAPI.requestReportAtendimento(aux, typeExport, {
      throwError: true,
    })
      .then(function (response) {
        if (typeExport === 'pdf' && response) {
          handleOpenPDF(response, 'rel_atendimento');
        } else {
          handleDownloadXLS(response, 'rel_atendimento', true);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoadingXLS(false);
        setLoadingPDF(false);
      });
  }, [
    agenda?.profissionalAtivo?.id,
    disponiveis,
    form,
    medicosConsultorio,
    user.tipoUsuario,
  ]);

  const handleSetAllChecked = useCallback(
    (e: boolean) => {
      if (e) {
        setChecked(e);
        checkboxFields.forEach(box => form.setValue(box.name, true));
      } else {
        setChecked(e);
        checkboxFields.forEach(box => form.setValue(box.name, false));
      }
    },
    [checkboxFields, form],
  );

  const handleIsActive = useCallback(
    (t: InputSwitchChangeParams) => {
      if (!t.target.value) {
        form.setValue('idsPacientes', []);
      }

      setCheckedPaciente(t.target.value);
    },
    [form],
  );

  useEffect(() => {
    handleSetAllChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ativo?.id) {
    return (
      <div className="p-py-2 p-px-4">
        <AlertBox
          visible={true}
          text="Você precisa selecionar um Consultório para esta funcionalidade!"
        />
      </div>
    );
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleRequestReport)}
        className="p-grid p-col-12 p-xl-5 p-md-8"
      >
        <CalendarInputControlled
          name="periodo"
          control={form.control}
          hideOnDateTimeSelect
          showIcon
          label="Período"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          selectionMode="range"
          className="p-col-12 p-px-0"
        />

        <FieldSelectConsultorios
          name="consultorios"
          placeholder="Pesquise e selecione os consultórios que constarão no relatório"
          label="Consultório"
          className="p-col-12 p-px-0"
        />

        {user.tipoUsuario === 'SECRETARIO' && (
          <FieldSelectMedicos
            label="Médicos"
            placeholder="Pesquise e selecione os médicos que constarão no relatório"
            className="p-col-12"
          />
        )}

        <SimpleText className="p-col-12" bold>
          Situação
        </SimpleText>

        <div className="form-relatorio-situacao p-col-12 p-grid">
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Filtre a situação dos agendamentos que serão exibidos no documento
          </SimpleText>

          <InputText
            placeholder="Pesquisar"
            icon="fa fa-search"
            className="p-col-12"
            onChange={e => setSearch(e.target.value)}
            value={search}
            hideTextErrorSpace
          />

          <Checkbox
            onChange={e => handleSetAllChecked(e.checked)}
            label="Selecionar todos"
            className="p-col-12"
            checked={checked}
          />

          <Separator className="p-col-12 p-my-1" />

          <div className="p-col-12 p-grid">
            {checkboxFields.map(item => (
              <CheckboxControlled
                key={item.name}
                control={form.control}
                name={item.name}
                label={item.label}
                className="p-col-12"
              />
            ))}
          </div>
          {errorStatus && (
            <SimpleText className="p-col-12 error">
              Ao menos uma situação deve ser selecionada!
            </SimpleText>
          )}
        </div>

        <div className="p-col-12 p-mt-4">
          <SimpleText bold>Personalização</SimpleText>

          <CheckboxControlled
            className="p-my-2"
            control={form.control}
            name="incluirExamesSolicitados"
            label="Exames solicitados"
          />

          <CheckboxControlled
            control={form.control}
            name="exibirComplementoProntuario"
            label="Exibir complemento prontuário"
          />
        </div>

        <div className="paciente-container p-col-12 p-mt-4">
          <div className="content-paciente p-mb-2">
            <SimpleText
              bold
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              Pesquisar por paciente
            </SimpleText>

            <div className="switch">
              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {checkedPaciente ? 'Ativado' : 'Desativado'}
              </SimpleText>
              <Switch
                checked={checkedPaciente}
                onChange={t => handleIsActive(t)}
              />
            </div>
          </div>
          {checkedPaciente && <FieldSelectPacientes />}
        </div>

        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          className="p-col-12 p-mt-6"
        >
          Para concluir, gere o relatório optando pelo formato.
        </SimpleText>

        <div className="p-col-12 p-d-flex p-ai-center p-p-0 p-gap-1 p-mb-4">
          <Button
            btnType={BtnTypes.TONAL}
            label="Abrir em PDF"
            className="p-col-12 p-md-6"
            stretch
            type="submit"
            onClick={() => form.setValue('type', 'pdf')}
            loading={loadingPDF}
            disabled={loadingXLS}
          />
          <Button
            btnType={BtnTypes.TONAL}
            label="Baixar XLS (Excel)"
            className="p-col-6"
            stretch
            type="submit"
            onClick={() => form.setValue('type', 'excel')}
            loading={loadingXLS}
            disabled={loadingPDF}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default RelatoriosAtendimentos;
