import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  return (
    <div className="p-d-flex p-jc-between p-px-2">
      <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
        Nome
      </SimpleText>

      <SimpleText
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
        className="p-mr-3"
      >
        Ações
      </SimpleText>
    </div>
  );
};
