import { useCallback } from 'react';

import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

const situacoes: any = {
  EM_TRATAMENTO: 'Em tratamento',
  RESOLVIDA: 'Resolvida',
  CONTROLADA: 'Controlada',
};

interface PanelHeaderProps {
  alergia: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  alergia,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const renderSplitButton = useCallback(() => {
    const labelType: Record<string, SPLIT_BTN_TYPE> = {
      EM_TRATAMENTO: SPLIT_BTN_TYPE.YELLOW,
      RESOLVIDA: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
      CONTROLADA: SPLIT_BTN_TYPE.GREEN,
    };

    const splitButtonItems: any = [];

    for (const situacao in situacoes) {
      if (situacao !== alergia?.situacao) {
        splitButtonItems.push({
          label: situacoes[situacao],
        });
      }
    }

    const type = labelType[alergia?.situacao] as SPLIT_BTN_TYPE;

    return (
      <SplitButton
        menuClassName="status-alergia"
        label={situacoes[alergia?.situacao]}
        model={splitButtonItems}
        btnType={type}
        disabled
      />
    );
  }, [alergia?.situacao]);

  const headerTextClassName =
    alergia?.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    alergia?.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  return (
    <div className="p-grid p-col-12 p-align-center p-jc-between panel-header-prontuario">
      <div className="coluna-nome p-col-6 p-md-3 p-d-flex p-flex-column p-jc-center">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {alergia?.descricao || '-'}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-6 p-md-2 p-d-flex p-flex-column p-jc-center">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(alergia?.dataOcorrencia).format('DD/MM/YYYY  HH:mm')}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-6 p-md-2 retroativo-chip p-jc-center">
        {alergia?.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="coluna-dados p-col-6 p-md-3 p-d-flex p-flex-column p-jc-center">
        {renderSplitButton()}
      </div>
      <div className="coluna-acoes p-col-6 p-md-2 p-d-flex p-ai-center p-jc-end">
        <Lembrete ativo={alergia?.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
