import { useCallback, useState } from 'react';

import Tab from 'src/components/Tab/Tab';

import Contato from './Contato/Contato';
import DadosPessoais from './DadosPessoais/DadosPessoais';
import Documentos from './Documentos/Documentos';
import Endereco from './Endereco/Endereco';

const FormMasterCreateUserStep3 = (userData: any): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleActiveIndex = useCallback(() => {
    const nextTabIndex = activeIndex + 1;

    setActiveIndex(nextTabIndex);
  }, [activeIndex]);

  const renderTabs = useCallback(() => {
    return (
      <Tab
        setActiveIndex={activeIndex}
        onChange={(e: any) => {
          setActiveIndex(e);
        }}
        clean
        values={[
          {
            label: 'Dados pessoais',
            content: <DadosPessoais handleActiveIndex={handleActiveIndex} />,
          },
          {
            label: 'Documentos',
            content: <Documentos handleActiveIndex={handleActiveIndex} />,
          },
          {
            label: 'Endereço',
            content: <Endereco handleActiveIndex={handleActiveIndex} />,
          },
          {
            label: 'Contato',
            content: <Contato handleActiveIndex={handleActiveIndex} />,
          },
        ]}
      />
    );
  }, [activeIndex, handleActiveIndex]);

  return renderTabs();
};
export default FormMasterCreateUserStep3;
