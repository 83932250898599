import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PuericulturaAPI from 'src/APIs/ProntuarioAPI/PuericulturaAPI/PuericulturaAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';

import { schemaDadosNascimento } from '../validation';
import './DadosNascimento.scss';

interface DadosNascimentoProps {
  paciente: any | null;
}

const DadosNascimento = ({ paciente }: DadosNascimentoProps) => {
  const [submitting, setSubmitting] = useState(false);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus } = useAtendimento();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schemaDadosNascimento),
  });

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const getDadosNascimento = useCallback(async () => {
    if (!idAtendimento) return;

    const response = await PuericulturaAPI.getDadosNascimento(
      Number(idAtendimento),
    );

    if (response.id) {
      setValue('idadeGestacionalSemanas', response.idadeGestacionalSemanas);
      setValue('idadeGestacionalDias', response.idadeGestacionalDias);
      setValue('pesoAoNascer', response.pesoAoNascer);
      setValue('pesoAlta', response.pesoAlta);
      setValue('tipoSanguineoMae', response.tipoSanguineoMae);
      setValue('tipoSanguineoRn', response.tipoSanguineoRn);
      setValue('fatorSanguineoRn', response.fatorSanguineoRn);
      setValue('fatorSanguineoMae', response.fatorSanguineoMae);
      setValue('dataAlta', new Date(response.dataAlta));
      setValue('comprimentoNascimento', response.comprimentoNascimento);
      setValue('apgarUmMinuto', response.apgarUmMinuto);
      setValue('apgarCincoMinuto', response.apgarCincoMinuto);
      setValue('perimetroToracico', response.perimetroToracico);
      setValue('perimetroCefalico', response.perimetroCefalico);
    }
  }, [idAtendimento, setValue]);

  useEffect(() => {
    getDadosNascimento();
  }, [getDadosNascimento]);

  const renderIdade = useCallback(() => {
    return (
      <>
        <div className="p-grid p-ai-end p-jc-between p-mt-5 input-group">
          <TextInputControlled
            className="p-col-6 p-sm-4"
            label="Idade gestacional"
            name="idadeGestacionalSemanas"
            permitirApenasNumeros
            maxLength={2}
            control={control}
          />

          <TextInput
            className="p-col-6 p-sm-2 input-info"
            value="Semanas"
            readOnly
          />

          <TextInputControlled
            className="p-col-6 p-sm-4"
            name="idadeGestacionalDias"
            type="number"
            max={7}
            min={0}
            control={control}
          />

          <TextInput
            className="p-col-6 p-sm-2  input-info"
            readOnly
            value="Dias"
          />
        </div>

        <div className="p-grid p-ai-end p-jc-between p-mt-1">
          <TextInputControlled
            className="p-col-6 p-sm-3"
            label="Peso ao nascer (g)"
            name="pesoAoNascer"
            floatOnly
            maxLength={6}
            control={control}
          />

          <CalendarInputControlled
            className="p-col-6 p-sm-3 "
            icon="fa fa-calendar-alt"
            showIcon
            label="Data Alta"
            name="dataAlta"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            maxDate={new Date()}
            minDate={new Date(paciente?.dataNascimento)}
            control={control}
            errorMsg={errors.dataAlta?.message}
          />

          <TextInputControlled
            className="p-col-6"
            label="Peso da alta (g)"
            name="pesoAlta"
            floatOnly
            maxLength={7}
            control={control}
          />
        </div>

        <div className="p-grid p-ai-end p-jc-between p-mt-1 p-mb-3">
          <TextInputControlled
            className="p-col-12 p-sm-4"
            label="Comprimento ao nascer (cm)"
            name="comprimentoNascimento"
            floatOnly
            maxLength={7}
            control={control}
          />

          <TextInputControlled
            className="p-col-6 p-sm-4"
            label="Perímetro cefálico (cm)"
            name="perimetroCefalico"
            floatOnly
            maxLength={6}
            control={control}
          />

          <TextInputControlled
            className="p-col-6 p-sm-4"
            label="Perímetro torácico (cm)"
            name="perimetroToracico"
            floatOnly
            maxLength={6}
            control={control}
          />
        </div>
      </>
    );
  }, [control, errors, paciente?.dataNascimento]);

  const renderApgar = useCallback(() => {
    const tipoSanguineo = [
      { label: 'A', value: 'A' },
      { label: 'B', value: 'B' },
      { label: 'AB', value: 'AB' },
      { label: 'O', value: 'O' },
    ];

    const fatorSanguineo = [
      { label: 'Positivo', value: 'POSITIVO' },
      { label: 'Negativo', value: 'NEGATIVO' },
    ];

    return (
      <>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-pl-2"
          medium
        >
          Apgar
        </SimpleText>

        <div className="p-grid p-ai-end p-jc-between p-mt-2">
          <TextInputControlled
            className="p-col-6"
            label="1° minuto"
            name="apgarUmMinuto"
            permitirApenasNumeros
            maxLength={6}
            control={control}
          />

          <TextInputControlled
            className="p-col-6"
            label="5° minuto"
            name="apgarCincoMinuto"
            permitirApenasNumeros
            maxLength={6}
            control={control}
          />
        </div>

        <div className="p-grid p-ai-end p-jc-between p-mt-1 p-mb-3">
          <DropdownControlled
            className="p-col-6 p-sm-3"
            label="Tipo sanguíneo RN"
            name="tipoSanguineoRn"
            placeholder="Selecione"
            options={tipoSanguineo}
            control={control}
            disabled={!isAtendimentoRunning}
          />

          <DropdownControlled
            className="p-col-6 p-sm-3"
            name="fatorSanguineoRn"
            placeholder="Selecione"
            options={fatorSanguineo}
            control={control}
            disabled={!isAtendimentoRunning}
          />

          <DropdownControlled
            className="p-col-6 p-sm-3"
            label="Tipo sanguíneo da mãe"
            name="tipoSanguineoMae"
            placeholder="Selecione"
            options={tipoSanguineo}
            control={control}
            disabled={!isAtendimentoRunning}
          />

          <DropdownControlled
            className="p-col-6 p-sm-3"
            name="fatorSanguineoMae"
            placeholder="Selecione"
            options={fatorSanguineo}
            control={control}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </>
    );
  }, [control, isAtendimentoRunning]);

  const onSubmit = async (data: any) => {
    const payload = {
      idadeGestacionalSemanas: Number(data.idadeGestacionalSemanas),
      idadeGestacionalDias: Number(data.idadeGestacionalDias),
      pesoAoNascer: Number(data.pesoAoNascer),
      dataAlta: dayjs(data.dataAlta).format('YYYY-MM-DDTHH:mmZ'),
      pesoAlta: Number(data.pesoAlta),
      comprimentoNascimento: Number(data.comprimentoNascimento),
      perimetroCefalico: Number(data.perimetroCefalico),
      perimetroToracico: Number(data.perimetroToracico),
      apgarUmMinuto: Number(data.apgarUmMinuto),
      apgarCincoMinuto: Number(data.apgarCincoMinuto),
      tipoSanguineoRn: data.tipoSanguineoRn,
      fatorSanguineoRn: data.fatorSanguineoRn,
      tipoSanguineoMae: data.tipoSanguineoMae,
      fatorSanguineoMae: data.fatorSanguineoMae,
    };

    await PuericulturaAPI.postDadosNascimento(Number(idAtendimento), payload);
  };

  return (
    <ElevatedCard noMargin className="container-dadosNascimento">
      <SimpleText
        fontSize={FONT_SIZE.MD}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Dados do nascimento
      </SimpleText>

      <form>
        <fieldset disabled={!isAtendimentoRunning}>
          {renderIdade()}
          {renderApgar()}

          <Button
            label="Salvar"
            btnType="tonal"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
            disabled={!isAtendimentoRunning}
            stretch
          />
        </fieldset>
      </form>
    </ElevatedCard>
  );
};

export default DadosNascimento;
