import { ReactNode, useEffect, useState } from 'react';
import { CountdownContext } from 'src/core/contexts/Countdown/Countdown';

interface CountdownProviderProps {
  children: ReactNode;
}

export const CountdownProvider = ({ children }: CountdownProviderProps) => {
  const [countdown, setCountdown] = useState(0);

  const startCountdown = (initialCountdown: number) => {
    setCountdown(initialCountdown);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  return (
    <CountdownContext.Provider
      value={{
        countdown,
        startCountdown,
      }}
    >
      {children}
    </CountdownContext.Provider>
  );
};
