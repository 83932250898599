import { ReactNode } from 'react';

import { CreateProfissionalSaudeProvider } from './CreateProfissionalSaudeProvider';
import { CreateUserProvider } from './CreateUserProvider';
import { OptionalDataProvider } from './OptionalDataProvider';
import { SelectProfileCreateUserProvider } from './SelectProfileCreateUserProvider';
import { UserAccessDataProvider } from './UserAccessDataProvider';

interface ManterUsuarioProviderProps {
  children: ReactNode;
}

export function ManterUsuarioProvider({ children }: ManterUsuarioProviderProps) {
  return (
    <SelectProfileCreateUserProvider>
      <UserAccessDataProvider>
        <CreateUserProvider>
          <CreateProfissionalSaudeProvider>
            <OptionalDataProvider>
              {children}
            </OptionalDataProvider>
          </CreateProfissionalSaudeProvider>
        </CreateUserProvider>
      </UserAccessDataProvider>
    </SelectProfileCreateUserProvider>
  );
}