import { useNavigate } from 'react-router';

import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import './Row.scss';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

interface RowProps {
  carteira: any;
  searchType: string;
}

export const Row = (props: RowProps) => {
  const { carteira, searchType } = props;
  const navigate = useNavigate();

  const idCuidador =
    carteira.idEquipeCuidadora ?? carteira.idProfissionalCuidador;

  const onEdit = () => {
    if (idCuidador) {
      navigate(`/pacientes/carteira-aps/movimentacao/${idCuidador}`);
    }
  };

  return (
    <div className="p-mt-2 carteira-row">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            carteira={carteira}
            onEdit={onEdit}
            searchType={searchType}
          />
        )}
      >
        <PanelContent carteira={carteira} />
      </Panel>
    </div>
  );
};
