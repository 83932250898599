import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  motivoAtendimento: any;
}

export const PanelContent = ({ motivoAtendimento }: PanelContentProps) => {
  return (
    <div className="p-grid medicamentos-panel-content">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          descrição
        </SimpleText>

        <SimpleText
          className="p-mt-2 p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {motivoAtendimento.descricaoMotivo}
        </SimpleText>
      </div>

      {motivoAtendimento?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Justificativa para registro retroativo
          </SimpleText>

          <SimpleText
            className="p-mt-2 p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {motivoAtendimento.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}

      <div className="p-col-12 p-d-flex">
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Adicionado em{' '}
          <b>
            {dayjs(motivoAtendimento?.dataAtualizacao)
              .locale('pt-br')
              .format('DD [de] MMMM [de] YYYY [às] HH:mm')}
          </b>
          {'  '}
          por <b> {motivoAtendimento?.usuarioAtualizacao?.nome}</b>
        </SimpleText>
      </div>
    </div>
  );
};
