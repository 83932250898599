/* eslint-disable @typescript-eslint/ban-types */
import { useState, useEffect, useCallback, useContext, useRef } from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput, {
  TextInputProps,
} from 'src/components/Basics/TextInput/TextInput';

import './VerificationCodeInput.scss';

export interface VerificationCodeInputProps extends TextInputProps {
  length: number | undefined;
  setFieldValue: Function;
  name: string;
  errorMsg?: string | undefined;
  setFieldTouched: Function;
}

const VerificationCodeInput = ({
  label,
  length,
  setFieldValue,
  name,
  errorMsg,
  setFieldTouched,
}: VerificationCodeInputProps) => {
  const inputRefs = useRef<any>([]);
  const theme = useContext(ThemeContext);

  const inputs = Array(length).fill('');

  const [values, setValues] = useState(inputs);

  const changeValue = useCallback(
    (index: number, value: string) => {
      const newValues = JSON.parse(JSON.stringify(values));

      newValues[index] = value;
      setValues(newValues);

      const code = newValues.join('');
      setFieldValue(name, code);

      if (value !== '') {
        // focus next input, timeout to avoid multiple calls
        setTimeout(() => {
          inputRefs?.current?.[index]?.blur();
          inputRefs?.current?.[index + 1]?.focus();
        }, 100);
      } else if (index !== 0) {
        setTimeout(() => {
          inputRefs?.current?.[index]?.blur();
          inputRefs?.current?.[index - 1]?.focus();
        }, 100);
      }
    },
    [name, setFieldValue, values],
  );

  useEffect(() => {
    const joinValues = values.join('');

    if (joinValues.length === length) {
      setFieldTouched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, values]);

  return (
    <div className={`VerificationCodeInput ${theme?.theme || ''}`}>
      {label && (
        <div className={'p-col-12'}>
          <label>
            <SimpleText>{label}</SimpleText>
          </label>
        </div>
      )}

      <div className={'p-d-flex p-flex-row p-ml-0'}>
        {inputs.map((val, i) => (
          <div key={i} className={'p-ml-2 inputController'}>
            <TextInput
              key={i}
              ref={(element: any) => {
                inputRefs.current[i] = element;
              }}
              label={val}
              maxLength={1}
              onChange={e => changeValue(i, e.target.value)}
              value={values[i]}
              classNameInput={errorMsg ? 'p-invalid' : ''}
            />
          </div>
        ))}
        <div className={'p-d-flex p-ai-center p-ml-2'}>
          {errorMsg ? <i className={'pi pi-exclamation-circle'} /> : <></>}
        </div>
      </div>
      {errorMsg ? (
        <div className={'p-ml-2'}>
          <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
            {errorMsg}
          </SimpleText>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VerificationCodeInput;
