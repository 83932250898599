import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import useDebounce from 'src/utils/useDebounce';

import TextInput, {
  TextInputProps,
} from 'src/components/Basics/TextInput/TextInput';

import './TextInputSearch.scss';

export interface TextInputSearchProps extends Omit<TextInputProps, 'onChange'> {
  onChange(v: string): void;
  customOnChange?: () => void;
  onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateValue?: string;
}

const TextInputSearch = (props: TextInputSearchProps, ref: any) => {
  const { onChange, customOnChange, disabled, onChangeEvent, ...rest } = props;
  const theme = useContext(ThemeContext);

  const [value, setValue] = useState(props.value || '');

  // Debounce para evitar várias chamadas a API ao digitar para buscar
  const debouncedValue = useDebounce(value, 350);

  useEffectSkipFirst(() => {
    if (!props.value) {
      setValue('');
    }
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    clear() {
      setValue('');
      onChange('');
    },
  }));

  useEffect(() => {
    if (onChange) onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  useEffect(() => {
    if (props.updateValue) setValue(props.updateValue);
  }, [onChange, props.updateValue]);

  const clean = useCallback(() => {
    if (disabled) return;
    setValue('');

    if (customOnChange) customOnChange();
  }, [customOnChange, disabled]);

  return (
    <div className={`TextInputSearch ${theme?.theme || ''} `}>
      <span
        className={`p-input-icon-left ${value ? 'p-input-icon-right' : ''}`}
      >
        <i className="pi pi-search" style={{ marginRight: 5 }} />
        <TextInput
          {...rest}
          value={value}
          onChange={e => {
            setValue(e.target.value);
            if (customOnChange) customOnChange();
            if (onChangeEvent) onChangeEvent(e);
          }}
          disabled={disabled}
        />
        {!!value && <i className="pi pi-times" onClick={clean} />}
      </span>
    </div>
  );
};

export default forwardRef(TextInputSearch);
