import {
  Skeleton as PrimeSkeleton,
  SkeletonProps as PrimeSkeletonProps,
} from 'primereact/skeleton';

interface SkeletonProps extends PrimeSkeletonProps {
  loading: boolean;
  children?: JSX.Element;
}

function Skeleton({ loading, children, ...props }: SkeletonProps) {
  if (loading) return <PrimeSkeleton {...props} />;

  return children || null;
}

export default Skeleton;
