import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldEnfermeiroCuidadorProps = DropdownProps & {
  idConsultorio: number;
  name?: string;
  label: string;
};

export const FieldEnfermeiroCuidador = ({
  idConsultorio,
  name = 'enfermeiroCuidador',
  label,
  ...rest
}: FieldEnfermeiroCuidadorProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [enfermeiroCuidador, setEnfermeiroCuidador] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const loadEnfermeiroCuidador = useCallback(async () => {
    setLoadingSearch(true);

    const response = await ProfissionalAPI.loadProfissionalSaudeByTipo({
      consultorio: idConsultorio,
      cuidador: true,
      tipoProfissional: 'ENFERMEIRO',
    });

    if (!!response?.length) setEnfermeiroCuidador(response);

    setLoadingSearch(false);
  }, [idConsultorio]);

  useEffect(() => {
    loadEnfermeiroCuidador();
  }, [loadEnfermeiroCuidador]);

  const enfermeiroCuidadorOptions = useMemo(() => {
    const options = enfermeiroCuidador.map(item => ({
      label: item.nome,
      value: item,
    }));

    return options;
  }, [enfermeiroCuidador]);

  const equipeCuidadora = watch('equipeCuidadora');
  const enfermeiroCuidadorAtual = watch('enfermeiroCuidador');

  const textHelper = useMemo(() => {
    const equipeProfissionaisSaude =
      equipeCuidadora?.profissionaisSaude ??
      equipeCuidadora?.equipeProfissionaisSaude;

    if (!equipeProfissionaisSaude || !enfermeiroCuidadorAtual) return '';

    const enfermeiroBelongsToEquipeCuidadora =
      !!equipeProfissionaisSaude?.find?.(
        (profissionalSaude: any) =>
          profissionalSaude?.id === enfermeiroCuidadorAtual.id,
      );

    return enfermeiroBelongsToEquipeCuidadora
      ? ''
      : 'Não faz parte da equipe cuidadora';
  }, [enfermeiroCuidadorAtual, equipeCuidadora]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={enfermeiroCuidadorOptions}
      errorMsg={errors?.[name]?.message}
      customOnChange={rest.onChange ? rest.onChange : () => null}
      labelOverflow="hidden"
      textHelper={textHelper}
      loading={loadingSearch}
      {...rest}
    />
  );
};
