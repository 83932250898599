import { useState } from 'react';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import HistoricoAlergiasIntolerancias from './AlergiasIntolerancias/HistoricoAlergiasIntolerancias';
import HistoricoAvaliacaoRisco from './AvaliacaoRisco/HistoricoAvaliacaoRisco';
import HistoricoComorbidades from './Comorbidades/HistoricoComorbidades';
import HistoricoDocumentos from './Documentos/HistoricoDocumentos';
import HistoricoMedicamentos from './Medicamentos/HistoricoMedicamentos';
import HistoricoMotivoAtendimentos from './MotivoAtendimento.tsx/HistoricoMotivoAtendimento';
import HistoricoOPMEs from './OPMEs/HistoricoOPMEs';
import HistoricoPedidoCuidado from './PedidoCuidado/HistoricoPedidoCuidado';
import HistoricoPreAtendimento from './PreAtendimento/HistoricoPreAtendimento';
import { HistoricoPrescricaoMedicamentos } from './PrescricaoMedicamentos/HistoricoPrescricaoMedicamentos';
import HistoricoQuestionario from './Questionarios/HistoricoQuestionario';
import HistoricoVacinas from './Vacinas/HistoricoVacinas';

import './HistoricoFichaPaciente.scss';

export default function HistoricoFichaPaciente() {
  const [exibirHistoricoFichaPaciente, setExibirHistoricoFichaPaciente] =
    useState<boolean>(true);
  const { itensFichaPaciente } = useHistoricoAtendimento();

  useEffectSkipFirst(() => {
    if (itensFichaPaciente === 0) {
      setExibirHistoricoFichaPaciente(false);
    }
  }, [itensFichaPaciente]);

  return (
    <div id="historico-ficha-paciente">
      {exibirHistoricoFichaPaciente ? (
        <>
          <div className="p-my-2">
            <HistoricoPreAtendimento />
          </div>
          <div className="p-my-2">
            <HistoricoAlergiasIntolerancias />
          </div>
          <div className="p-my-2">
            <HistoricoMedicamentos />
          </div>
          <div className="p-my-2">
            <HistoricoMotivoAtendimentos />
          </div>
          <div className="p-my-2">
            <HistoricoComorbidades />
          </div>
          <div className="p-my-2">
            <HistoricoPedidoCuidado />
          </div>
          <div className="p-my-2">
            <HistoricoOPMEs />
          </div>
          <div className="p-my-2">
            <HistoricoQuestionario />
          </div>
          <div className="p-my-2">
            <HistoricoVacinas />
          </div>
          <div className="p-my-2">
            <HistoricoAvaliacaoRisco />
          </div>
          <div className="p-my-2">
            <HistoricoDocumentos />
          </div>
          <div className="p-my-2">
            <HistoricoPrescricaoMedicamentos />
          </div>
        </>
      ) : (
        <SimpleText
          fontSize={FONT_SIZE.SM}
          fontColor={FONT_COLOR.COLOR_40}
          medium
          className="p-col-12 p-mt-3 p-mb-3 p-text-center"
        >
          Não há itens cadastrados para este atendimento
        </SimpleText>
      )}
    </div>
  );
}
