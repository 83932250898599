import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <div className="p-grid">
      <SimpleText
        className="p-col-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        ID
      </SimpleText>

      <SimpleText
        className="p-col"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Mensagem
      </SimpleText>

      <SimpleText
        className="p-col-fixed acoes-col p-d-flex p-jc-end p-mr-2"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Ações
      </SimpleText>
    </div>
  );
};
