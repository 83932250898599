import { useEffect, useLayoutEffect } from 'react';

import { useLocation } from 'react-router-dom';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { CountdownProvider } from 'src/core/containers/Countdown/Countdown';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setProfissionaisDisponiveis } from 'src/core/redux/slices/agenda/AgendaSlice';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';

import AgendaCompleta from './AgendaCompleta/AgendaCompleta';
import { AgendaProvider, useAgenda } from './AgendaContext';
import AgendaProfissionalSaude from './AgendaProfissionalSaude/AgendaProfissionalSaude';
import DialogEtiqueta from './Etiqueta/DialogEtiqueta';
import RemarcarAgendamento from './MenuAgenda/RemarcarAgendamento/RemarcarAgendamento';
import MenuConfirmarPresenca from './MenuConfirmarPresenca/MenuConfirmarPresenca';
import ProcedimentosSeriados from './MenuConfirmarPresenca/ProcedimentoSeriados/ProcedimentosSeriados';
import PesquisaProntuario from './PesquisaProntuario/PesquisaProntuario';
import { PesquisarPaciente } from './PesquisarPaciente/PesquisarPaciente';

import './Agenda.scss';

const Agenda = () => {
  return (
    <AgendaProvider>
      <AgendaComponent />
    </AgendaProvider>
  );
};

const AgendaComponent = () => {
  const { user, consultorios } = useAppSelector((state: RootState) => state);
  const { visualizarAgenda } = useLocation()?.state || {};
  const pathname = useLocation().pathname || '';

  const {
    toggleViewAgenda,
    isAgendaCompletaOpened,
    isPesquisaProntuarioOpened,
    dialogRemarcarAgendamento,
    drawerConfirmarAgendamento,
    dialogProcedimentosSeriados,
    dialogImprimirEtiqueta,
  } = useAgenda();

  const dispatch = useAppDispatch();
  const pesquisarPacienteDisclosure = useDisclosure({ opened: false });

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'age_visualizar_agenda',
  });

  const isEnfermeiro =
    user.tipoUsuario === 'PROFISSIONAL_SAUDE' &&
    user.tipoProfissionalSaude === 'ENFERMEIRO';

  useEffect(() => {
    if (visualizarAgenda === 'AGENDA_COMPLETA') toggleViewAgenda(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEnfermeiro && consultorios.ativo?.id)
      UsuarioAPI.loadProfissionaisByUserConsultorio(consultorios.ativo.id, {
        throwError: true,
      })
        .then(res => dispatch(setProfissionaisDisponiveis(res)))
        .catch(() => {});
  }, [consultorios.ativo?.id, dispatch, isEnfermeiro]);

  useLayoutEffect(() => {
    const element = document.getElementById('pesquisa-prontuario');
    if (!element) return;

    isPesquisaProntuarioOpened && (element.style.display = 'block');

    return () => {
      if (pathname !== '/agenda') element.style.display = 'none';
    };
  }, [isPesquisaProntuarioOpened, pathname]);

  useLayoutEffect(() => {
    const agendaProfissionalElement = document.getElementById(
      'agenda-profissional-saude',
    );
    const pesquisaProntuarioElement = document.getElementById(
      'pesquisa-prontuario',
    );
    if (!agendaProfissionalElement || !pesquisaProntuarioElement) return;

    if (isPesquisaProntuarioOpened) {
      setTimeout(() => {
        agendaProfissionalElement.style.display = 'none';
      }, 500);
    } else {
      agendaProfissionalElement.style.display = 'block';
    }

    if (!isPesquisaProntuarioOpened) {
      setTimeout(() => {
        pesquisaProntuarioElement.style.display = 'none';
      }, 500);
    }
  }, [isPesquisaProntuarioOpened]);

  return (
    <>
      {isAgendaCompletaOpened ? (
        <AgendaCompleta
          pesquisarPacienteDisclosure={pesquisarPacienteDisclosure}
        />
      ) : (
        <div className="container">
          <div
            id="agenda-profissional-saude"
            className={`agenda-profissional-saude ${
              !isPesquisaProntuarioOpened
                ? 'show-agenda-profissional'
                : 'hide-agenda-profissional'
            }`}
          >
            <AgendaProfissionalSaude />
          </div>
          <div
            id="pesquisa-prontuario"
            className={`pesquisa-prontuario ${
              isPesquisaProntuarioOpened
                ? 'show-pesquisa-prontuario'
                : 'hide-pesquisa-prontuario'
            }`}
            style={{ display: 'none' }}
          >
            <PesquisaProntuario />
          </div>
        </div>
      )}

      <CountdownProvider>
        {drawerConfirmarAgendamento.isOpen && (
          <MenuConfirmarPresenca
            onHide={drawerConfirmarAgendamento.close}
            {...drawerConfirmarAgendamento}
          />
        )}
      </CountdownProvider>

      {dialogImprimirEtiqueta.isOpen && (
        <DialogEtiqueta {...dialogImprimirEtiqueta} />
      )}

      {pesquisarPacienteDisclosure.isOpen && (
        <PesquisarPaciente {...pesquisarPacienteDisclosure} />
      )}
      {dialogProcedimentosSeriados.isOpen && (
        <ProcedimentosSeriados {...dialogProcedimentosSeriados} />
      )}
      {dialogRemarcarAgendamento.isOpen && (
        <RemarcarAgendamento {...dialogRemarcarAgendamento} />
      )}
      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default Agenda;
