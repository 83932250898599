import React from 'react';
import { useCallback, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import OpmeAPI from 'src/APIs/ProntuarioAPI/OpmeAPI/OpmeAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './FieldSearchMateriais.scss';

type FieldSearchMateriaisProps = {
  onAdd: (items: any[]) => void;
};

type IMateriaisRow = {
  setItems: (old?: any) => void;
  item: any;
};

export const FieldSearchMateriais = ({ onAdd }: FieldSearchMateriaisProps) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const inputRef = useRef<any>();

  const [inputValue, setInputValue] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [itemsSelected, setItemsSelected] = useState<any[]>([]);

  const [loadingQuery, setLoadingQuery] = useState(false);

  const inputSearch = useThrottle(setInputValue, 500);

  const fetchAPI = async (queryParam: string) => {
    setLoadingQuery(true);

    OpmeAPI.getMateriais({ query: queryParam })
      .then(data => [setItems(data.list)])
      .catch(() => {})
      .finally(() => setLoadingQuery(false));
  };

  useEffectSkipFirst(() => {
    fetchAPI(inputValue);
  }, [inputValue]);

  const handleAdd = () => {
    setInputValue('');
    inputRef.current?.clear();

    onAdd(itemsSelected);

    setItemsSelected([]);
  };

  return (
    <div className="p-w-100 p-grid">
      <TextInputSearch
        aria-controls="overlay_panel"
        placeholder="Pesquisar por descrição"
        onChange={e => inputSearch(e)}
        value={inputValue}
        onClick={e => searchPanelRef.current?.show(e, e.target)}
      />

      <OverlayPanel
        id="overlay_panel"
        appendTo="self"
        className="panel-search-medicamentos"
        ref={searchPanelRef}
      >
        <div className="p-w-100">
          {!!items.length && !loadingQuery ? (
            <React.Fragment>
              <div className="p-d-flex p-col-12 p-py-0 p-ai-center">
                <div style={{ minWidth: '30px' }} />
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  bold
                  className="p-col-4"
                >
                  Material
                </SimpleText>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  bold
                  className="p-col-4 p-px-0"
                >
                  Especialidade do Produto
                </SimpleText>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  bold
                  className="p-col-3 p-px-0"
                >
                  Apresentação comercial
                </SimpleText>
              </div>

              <div
                style={{ maxHeight: '300px', overflowY: 'scroll' }}
                className="fav-content"
              >
                {items.map((item: any, idx: number) => (
                  <MateriaisRow
                    item={item}
                    setItems={setItemsSelected}
                    key={idx}
                  />
                ))}
              </div>

              {!!itemsSelected.length && (
                <div className="p-py-2">
                  <Button
                    label="Adicionar"
                    stretch
                    onClick={() => [
                      handleAdd(),
                      searchPanelRef.current?.hide(),
                    ]}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="p-d-flex p-jc-center p-my-2">
              {loadingQuery ? (
                <SpinnerLoading full size="sm" />
              ) : (
                <SimpleText>
                  {!!inputValue
                    ? 'Nenhum material encontrado'
                    : 'Começe a digitar para buscar por materiais'}
                </SimpleText>
              )}
            </div>
          )}
        </div>
      </OverlayPanel>
    </div>
  );
};

const MateriaisRow = ({ setItems, item }: IMateriaisRow) => {
  const [checked, setChecked] = useState(false);

  const onSubmit = useCallback(
    (event = true) => {
      if (event) {
        setItems((old: any) => {
          return [...old, item];
        });
      } else {
        setItems((old: any) => {
          const data = old.filter((value: any) => value.id !== item.id);

          return [...data];
        });
      }
    },
    [setItems, item],
  );

  return (
    <div className="row">
      <div className="p-d-flex p-col-12 p-ai-center p-py-0">
        <div className="w-30" onClick={e => e.stopPropagation()}>
          <Checkbox
            checked={checked}
            onChange={e => [onSubmit(e.checked), setChecked(e.checked)]}
          />
        </div>
        <SimpleText className="p-col-4 p-text-truncate">
          {item.nomeComercial}
        </SimpleText>
        <SimpleText className="p-col-4 p-text-truncate">
          {item.especialidadeProduto}
        </SimpleText>
        <SimpleText className="p-col-3 p-text-truncate">
          {item.apresentacaoComercial}
        </SimpleText>
      </div>
    </div>
  );
};
