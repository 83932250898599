import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface OcorrenciasProps {
  pendencia: any;
  hasJustificativa: boolean;
  hasNotLaudo: boolean;
  hasNotIndicacaoClinica: boolean;
}

export const Ocorrencias = (props: OcorrenciasProps) => {
  const { pendencia, hasNotLaudo, hasNotIndicacaoClinica, hasJustificativa } =
    props;

  const formatPendenciaDescricao = () => {
    let mensagem = pendencia?.mensagem
      .split('<br/>')
      .flatMap((mensagem: string) => {
        if (!mensagem) return [];

        const isJustificativa = mensagem?.includes('JUSTIFICATIVA');

        const hasNotPendencia = isJustificativa && hasJustificativa;

        if (hasNotPendencia) return [];

        return mensagem;
      });

    mensagem = mensagem?.join('; ');

    if (pendencia.necessitaIndicacaoClinica && hasNotIndicacaoClinica)
      mensagem += ' Necessário informar indicação clínica;';
    if (pendencia.necessitaIndicarAcidente)
      mensagem += ' Necessário informar indicação acidente;';
    if (pendencia.necessitaLaudo && hasNotLaudo)
      mensagem += ' Necessário informar laudo;';

    return mensagem;
  };

  return (
    <div className="p-col-12">
      <SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
        Ocorrências
      </SimpleText>

      <Card className="p-mt-2 p-mb-3" type={CARD_TYPES.GRAY}>
        <div className="column">
          <SimpleText className="p-mt-2" fontColor={FONT_COLOR.COLOR_16}>
            <b>Descrição -</b>{' '}
            <SimpleText fontColor={FONT_COLOR.COLOR_60}>
              {formatPendenciaDescricao()}
            </SimpleText>
          </SimpleText>

          <SimpleText className="p-mt-2" fontColor={FONT_COLOR.COLOR_16}>
            <b>Técnica -</b>{' '}
            <SimpleText fontColor={FONT_COLOR.COLOR_60}></SimpleText>
          </SimpleText>

          <SimpleText className="p-my-2" fontColor={FONT_COLOR.COLOR_16}>
            <b>Custo -</b>{' '}
            <SimpleText fontColor={FONT_COLOR.COLOR_60}></SimpleText>
          </SimpleText>

          <SimpleText fontColor={FONT_COLOR.COLOR_16}>
            <b>Orientação para o profissional -</b>{' '}
            <SimpleText fontColor={FONT_COLOR.COLOR_60}></SimpleText>
          </SimpleText>
        </div>
      </Card>
    </div>
  );
};
