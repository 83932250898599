import { memo, useCallback, useEffect } from 'react';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalTermoProps {
  visible: boolean;
  onHide(): void;
  relatorio: GetRelatorioEmails;
}

interface FooterProps {
  onCancel(): void;
}

const Footer = memo(({ onCancel }: FooterProps) => {
  return (
    <div className={'p-grid'}>
      <div className={'p-col-12'}>
        <Button
          btnType="ghost"
          label="Cancelar"
          className={'p-col-align-center p-d-flex'}
          onClick={onCancel}
          stretch
        />
      </div>
    </div>
  );
});

const ModalTermo = ({ relatorio, visible, onHide }: ModalTermoProps) => {
  useEffect(() => {
    const getDiv = setTimeout(() => {
      const divTermo: any = document.querySelector('#termo-html');

      divTermo.innerHTML =
        relatorio.telemedicinaLogTermo.termoConsentimentoTelemedicina.conteudo;
    }, 50);

    return () => {
      clearTimeout(getDiv);
    };
  }, [relatorio]);

  const handleCancel = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="TERMO"
      visible={visible}
      onHide={handleCancel}
      footer={() => <Footer onCancel={handleCancel} />}
    >
      <div id="termo-html"></div>
    </Dialog>
  );
};

export default ModalTermo;
