import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { FieldTelaApresentacao } from '../components/FieldTelaApresentacao';
import { FieldTipoUsuario } from '../components/FieldTipoUsuario';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

export const AssistenteVirtualCreateFormGeral = ({
  data,
  handleSubmit,
}: {
  data: any;
  handleSubmit(v: any): void;
}) => {
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      descricao: '',
      primeiroAcesso: false,
      novidade: false,
      ajudaDeTela: false,
      tipoUsuario: null,
      funcionalidade: null,
    },
    resolver: yupResolver(
      Yup.object({
        nome: Yup.string().required('Campo obrigatório'),
        primeiroAcesso: Yup.boolean(),
        tipoUsuario: Yup.string()
          .when('primeiroAcesso', {
            is: (v: boolean) => !!v,
            then: Yup.string()
              .required('Informe o tipo de usuário')
              .typeError('Favor, selecionar o tipo de usuário'),
          })
          .nullable(),
      }),
    ),
  });

  useEffect(() => {
    form.reset({
      ...data,
      primeiroAcesso: !!data.primeiroAcesso,
      novidade: !!data.novidade,
      ajudaDeTela: !!data.ajudaDeTela,
      funcionalidade: data.funcionalidade,
    });
  }, [data, form]);

  const [isPrimeiroAcesso, isNovidades, isAjusteTela] = form.watch([
    'primeiroAcesso',
    'novidade',
    'ajudaDeTela',
  ]);

  const isChecked = isPrimeiroAcesso || isNovidades || isAjusteTela;

  useEffectSkipFirst(() => {
    if (!isPrimeiroAcesso) {
      form.setValue('tipoUsuario', null);
    }
  }, [isPrimeiroAcesso]);

  useEffectSkipFirst(() => {
    if (!isAjusteTela) {
      form.setValue('funcionalidade', null);
    }
  }, [isPrimeiroAcesso]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="p-col-12 p-md-8 p-lg-7 p-xl-6 p-d-flex p-flex-column p-gap-2"
      >
        <FormInput name="nome" label="Nome da assistência" maxLength={100} />
        <TextareaInputControlled
          control={form.control}
          name="descricao"
          label="Descrição do assistente"
          maxLength={500}
          style={{
            resize: 'block',
          }}
        />

        <div className="p-d-flex p-gap-2">
          <div className="p-d-flex p-flex-column p-gap-1 p-col-4">
            <SimpleText medium>Novidades</SimpleText>
            <SwitchControlled
              control={form.control}
              name="novidade"
              disabled={isPrimeiroAcesso}
              showStatus
            />
          </div>
          <div className="p-d-flex p-flex-column p-gap-1 p-col-4">
            <SimpleText medium>Ajuda de tela</SimpleText>
            <SwitchControlled
              control={form.control}
              name="ajudaDeTela"
              disabled={isPrimeiroAcesso}
              showStatus
            />
          </div>
          <div className="p-d-flex p-flex-column p-gap-1 p-col-4">
            <SimpleText medium>Primeiro acesso</SimpleText>
            <SwitchControlled
              control={form.control}
              name="primeiroAcesso"
              showStatus
              disabled={isNovidades || isAjusteTela}
            />
          </div>
        </div>

        {isPrimeiroAcesso && (
          <FieldTipoUsuario name="tipoUsuario" label="Tipo de usuário" />
        )}

        {isAjusteTela && (
          <FieldTelaApresentacao
            name="funcionalidade"
            dataKey="id"
            label="Tela de ancoragem do assistente"
          />
        )}

        <div className="p-d-flex p-gap-1 p-my-2">
          <Button
            label="Voltar"
            btnType="ghost"
            onClick={() => navigate(-1)}
            stretch
          />
          <Button
            label="Próximo"
            btnType="tonal"
            type="submit"
            disabled={!isChecked}
            stretch
          />
        </div>
      </form>
    </FormProvider>
  );
};
