import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

interface PanelHeaderProps {
  pedidoCuidado: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  pedidoCuidado,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName =
    pedidoCuidado.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    pedidoCuidado.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  return (
    <div className="p-grid p-col-12 p-align-center borda-pedidos">
      <div className="coluna-nome p-col-12 p-md-4 p-d-flex">
        <Avatar label={pedidoCuidado.profissionalSaudeSolicitacao.nome} />
        <div className="p-d-flex p-flex-column p-ml-2">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {pedidoCuidado.profissionalSaudeSolicitacao.nome}
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Solicitado em{' '}
            {dayjs(pedidoCuidado.dataInclusao).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
        </div>
      </div>
      {pedidoCuidado.programado === true ? (
        <div className="p-col-7 p-md-4 p-ai-center p-jc-center">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Data programada
          </SimpleText>
          <br />
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {dayjs(pedidoCuidado.dataInclusao).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
          {pedidoCuidado.dataOcorrenciaExecucao && (
            <>
              <br />
              <SimpleText
                className={headerTextClassName}
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Data Ocorrencia
              </SimpleText>
              <br />
              <SimpleText
                className={dataTextClassName}
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
                medium
              >
                {dayjs(pedidoCuidado.dataOcorrenciaExecucao).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </SimpleText>
            </>
          )}
        </div>
      ) : (
        <div className="p-d-flex p-flex-column p-col-7 p-md-4 p-ai-center p-jc-center">
          {pedidoCuidado.status === 'ATIVO' && (
            <div className="retroativo-chip">
              <SimpleText fontSize={FONT_SIZE.XXXS} medium>
                Proximo atendimento
              </SimpleText>
            </div>
          )}
          {pedidoCuidado.dataOcorrenciaExecucao && (
            <>
              <br />
              <SimpleText
                className={headerTextClassName}
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Data Ocorrencia
              </SimpleText>
              <SimpleText
                className={dataTextClassName}
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
                medium
              >
                {dayjs(pedidoCuidado.dataOcorrenciaExecucao).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </SimpleText>
            </>
          )}
        </div>
      )}

      <div
        className={`p-d-flex p-col-5 p-md-4 p-ai-center p-jc-start ${
          pedidoCuidado.dataExecucao ? 'executado-chip' : 'novo-chip'
        }`}
      >
        {pedidoCuidado.status === 'ATIVO' && (
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            {pedidoCuidado.dataExecucao ? 'Executado' : 'Novo'}
          </SimpleText>
        )}
      </div>

      <div className="coluna-acoes p-col-9 p-md-10 p-d-flex p-flex-column p-my-1">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Pedido de Cuidado{' '}
          {pedidoCuidado.programado ? 'Programado' : 'Próximo Atendimento'}
        </SimpleText>

        <SimpleText
          className={`${dataTextClassName} p-mt-1`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          {pedidoCuidado.observacaoSolicitante}
        </SimpleText>
      </div>

      <div className="coluna-acoes p-col-3 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Lembrete ativo={pedidoCuidado.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
