import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';

interface UpdateButtonProps {
  onUpdate(): void;
}

const UpdateButton = ({ onUpdate }: UpdateButtonProps) => (
  <IconButton
    icon={'pi pi-refresh'}
    btnType={IconBtnTypes.GREY}
    onClick={onUpdate}
  />
);

export default UpdateButton;
