/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  useNavigate,
  useParams,
  useLocation,
  Location,
} from 'react-router-dom';

import ServicoTussComplementaresAPI from 'src/APIs/AdminAPI/ServicoTussComplementaresAPI/ServicoTussComplementaresAPI';
import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';
import ServicoTussRestricoesAPI from 'src/APIs/AdminAPI/ServicoTussRestricoesAPI/ServicoTussRestricoesAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
} from 'src/components/Timeline/Timeline';

import './FormServicoTuss.scss';

import FormServicoTussStep1 from './Steps/FormServicoTussStep1/FormServicoTussStep1';
import FormServicoTussStep2 from './Steps/FormServicoTussStep2/FormServicoTussStep2';
import FormServicoTussStep3 from './Steps/FormServicoTussStep3/FormServicoTussStep3';
import { FormServicoTussStep4 } from './Steps/FormServicoTussStep4/FormServicoTussStep4';

interface LocationParams extends Location {
  state: Partial<ServicoTussCRUD>;
}

const FormServicoTuss = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location: LocationParams = useLocation();

  const {
    user: { idEmpresa },
  } = useAppSelector((state: RootState) => state);

  const timelineRef = useRef<TimelineImperativeAPI>(null);

  const [step1, setStep1] = useState<Partial<ServicoTussCRUD>>({
    id: 0,
  } as Partial<ServicoTussCRUD>);
  const [step2, setStep2] = useState<Partial<ServicoTussComplementares>>(
    {} as Partial<ServicoTussComplementares>,
  );
  const [step3, setStep3] = useState<Partial<ServicoTussRestricoes>>(
    {} as Partial<ServicoTussRestricoes>,
  );
  const [servicoTussId, setServicoTussId] = useState(0);

  const loadStep1 = useCallback(async () => {
    if (servicoTussId) {
      try {
        const servicoTussEncontrado =
          await ServicoTussCRUDAPI.loadServicoTussById(servicoTussId);

        setStep1({ ...servicoTussEncontrado });
      } catch (error) {
        console.log(error);
      }
    }
  }, [servicoTussId]);

  const loadStep2 = useCallback(
    async (idDadosDuplicados = undefined) => {
      if (servicoTussId || idDadosDuplicados) {
        try {
          const dadosComplementaresServicoTussEncontrado =
            await ServicoTussComplementaresAPI.loadServicoTussComplementaresById(
              idDadosDuplicados ? idDadosDuplicados : servicoTussId,
            );

          setStep2({ ...dadosComplementaresServicoTussEncontrado });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [servicoTussId],
  );

  const loadStep3 = useCallback(
    async (idDadosDuplicados = undefined) => {
      if (servicoTussId || idDadosDuplicados) {
        try {
          const restricoesServicoTussEncontrado =
            await ServicoTussRestricoesAPI.loadServicoTussRestricoesById(
              idDadosDuplicados ? idDadosDuplicados : servicoTussId,
            );

          setStep3(restricoesServicoTussEncontrado);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [servicoTussId],
  );

  useEffect(() => {
    if (params?.id) {
      const idServicoTuss = parseInt(params.id, 10);

      setServicoTussId(idServicoTuss);
    }
  }, [params]);

  useEffect(() => {
    if (
      location &&
      location?.state &&
      Object.keys(location?.state).length > 0
    ) {
      setStep1({
        ...location.state,
        id: 0,
        tipo: location.state?.tipo!,
        codigo: location.state?.codigo || '',
        nome: '',
        codigoTabelaTiss: location.state?.codigoTabelaTiss!,
        classe: location.state?.classe!,
      });
    }
  }, [location]);

  useEffect(() => {
    loadStep1();
  }, [loadStep1, idEmpresa]);

  useEffect(() => {
    loadStep2(location.state?.id);
  }, [loadStep2, idEmpresa, location.state]);

  useEffect(() => {
    loadStep3(location.state?.id);
  }, [loadStep3, idEmpresa, location.state]);

  const saveStep1 = useCallback(
    async (data: any) => {
      let response: any;

      try {
        if (servicoTussId) {
          response = await ServicoTussCRUDAPI.updateServicoTuss(servicoTussId, {
            ...data,
          });
        } else {
          const dadosServicoTuss = {
            ...data,
            status: true,
            excluido: false,
          };

          response = await ServicoTussCRUDAPI.sendServicoTuss(dadosServicoTuss);
        }

        if (response?.status === 200) {
          setServicoTussId(response?.data?.id);
          setStep1({ ...response?.data });
          if (timelineRef && timelineRef.current && timelineRef.current.next) {
            timelineRef.current?.next();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [servicoTussId],
  );

  const saveStep2 = useCallback(
    async (dataStep2: any) => {
      try {
        const data = {
          ...dataStep2,
          status: dataStep2.status ? dataStep2.status : false,
          excluido: dataStep2.excluido ? dataStep2.excluido : false,
        };

        const response: any =
          await ServicoTussComplementaresAPI.updateServicoComplementaresTuss(
            servicoTussId,
            data,
          );

        if (response?.status === 200) {
          setStep2({ ...response?.data });
          if (timelineRef && timelineRef.current && timelineRef.current.next) {
            timelineRef.current?.next();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [servicoTussId],
  );

  const isOPME = step2.opme;

  const saveStep3 = useCallback(
    async (dataStep3: any) => {
      try {
        const data = {
          ...dataStep3,
          status: dataStep3.status ? dataStep3.status : false,
          excluido: dataStep3.excluido ? dataStep3.excluido : false,
        };
        const response: any =
          await ServicoTussRestricoesAPI.updateServicoTussRestricoes(
            servicoTussId,
            data,
          );

        if (response?.status === 200) {
          setStep3({ ...response?.data });

          if (
            isOPME &&
            timelineRef &&
            timelineRef.current &&
            timelineRef.current.next
          ) {
            timelineRef.current?.next();
          } else {
            navigate(-1);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [servicoTussId, isOPME, navigate],
  );

  const formSteps = [
    {
      title: 'Dados (obrigatório)',
      component: (
        <FormServicoTussStep1
          values={step1}
          onSubmit={(values: Partial<ServicoTussCRUD>) => {
            saveStep1(values);
          }}
        />
      ),
    },
    {
      title: 'Dados complementares',
      disabled: step1?.id === 0,
      component: (
        <FormServicoTussStep2
          values={step2}
          onSubmit={(values: Partial<ServicoTussComplementares>) => {
            saveStep2(values);
          }}
        />
      ),
    },
    {
      title: 'Restrições',
      disabled: Object.keys(step2).length === 0 || step1?.id === 0,
      component: (
        <FormServicoTussStep3
          values={step3}
          isOPME={!!isOPME}
          onSubmit={(values: Partial<ServicoTussRestricoes>) => {
            saveStep3(values);
          }}
        />
      ),
    },
    {
      title: 'OPME',
      disabled: Object.keys(step2).length === 0 || step1?.id === 0,
      component: (
        <FormServicoTussStep4
          servicoTussId={step1?.id}
          goBack={() => timelineRef.current?.previous?.()}
        />
      ),
    },
  ];

  if (!isOPME && formSteps.find(item => item.title === 'OPME')) {
    formSteps.pop();
  }

  return (
    <Page className={'FormServicoTuss'} white>
      <>
        <PageHeader
          title={
            servicoTussId
              ? 'Editar registro de serviço TUSS'
              : 'Novo registro de serviço TUSS'
          }
          subtitle={'Preencha o formulário para concluir'}
        />
        <Page content white style={{ height: '100%' }}>
          <Timeline values={formSteps} ref={timelineRef} />
        </Page>
      </>
    </Page>
  );
};

export default FormServicoTuss;
