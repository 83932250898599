import { memo, useState, useEffect } from 'react';

import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import './ModalDesativarEmpresa.scss';
import { Button } from 'src/components/_UI/Button';

interface ModalDesativarEmpresaProps {
  visible: boolean;
  onHide(): void;
  empresa: EspecialidadeDTO;
  handleDesativarEmpresa: any;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
  temEmpresa: boolean;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd, temEmpresa } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        btnType={temEmpresa ? 'danger' : 'default'}
        label={temEmpresa ? 'Sim, desativar' : 'Sim, ativar'}
        onClick={onAdd}
        stretch
      />
    </div>
  );
});

const ModalDesativarEmpresa = ({
  empresa,
  visible,
  onHide,
  handleDesativarEmpresa,
}: ModalDesativarEmpresaProps) => {
  const [temEmpresa, setTemEmpresa] = useState(false);
  const [visibleBox, setVisibleBox] = useState(true);

  useEffect(() => {
    if (empresa.status) {
      setTemEmpresa(true);
    } else {
      setTemEmpresa(false);
    }
  }, [empresa]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header={
        temEmpresa
          ? 'Confirmar ação de desativar cliente'
          : 'Confirmar ação de ativar cliente'
      }
      visible={visible}
      onHide={onHide}
      style={{
        width: '390px',
      }}
      footer={() => (
        <Footer
          onAdd={() => handleDesativarEmpresa(empresa)}
          onCancel={onHide}
          temEmpresa={temEmpresa}
        />
      )}
    >
      <>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          {temEmpresa
            ? 'Os usuários vinculados a este cliente perderão o acesso a plataforma eMed. Você realmente deseja desativar?'
            : 'Você confirma a ativação deste cliente?'}
        </SimpleText>
        {!temEmpresa && (
          <AlertBox
            visible={visibleBox}
            onHide={() => setVisibleBox(false)}
            text="Os usuários vinculados a este cliente deverão ser reativados individualmente"
          />
        )}
      </>
    </Dialog>
  );
};

export default ModalDesativarEmpresa;
