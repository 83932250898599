import Dropzone, { Accept } from 'react-dropzone';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import { useLogoUpload } from './hooks/useLogoUpload';

const accept: Accept = {
  'image/jpeg': ['.jpeg', '.png'],
};

export const LogoUpload = () => {
  const { additionalInfoStepData } = useManterConvenio();

  const { foto, loading, setFoto } = useLogoUpload();

  const renderDropzoneContent = () => {
    if (loading)
      return (
        <div className="p-py-5">
          <SpinnerLoading full />
        </div>
      );

    if (additionalInfoStepData?.urlLogotipo || foto?.path) {
      const imageSrc = foto?.path
        ? URL.createObjectURL(foto)
        : additionalInfoStepData?.urlLogotipo;

      return <img alt="Logo do convênio" width={200} src={imageSrc} />;
    }

    return (
      <>
        <i className="fas fa-file" />

        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_40}>
          Você pode arrastar seus arquivos aqui.
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Formato aceito: PNG e JPG
        </SimpleText>
      </>
    );
  };

  return (
    <div id="convenio-logo-upload-container" className="p-col-12 p-grid">
      <Dropzone
        accept={accept}
        onDrop={(f: any) => {
          setFoto(f[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="p-col-12">
            <Button
              type="button"
              btnType="tonal"
              label="Escolha o arquivo"
              icon="fas fa-plus"
              stretch
            />

            <Divider />

            <input {...getInputProps()} />

            <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
              {renderDropzoneContent()}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};
