import { ToastMessageType } from 'primereact/toast';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
  queue: ToastMessageType[];
}

const initialState: ToastState = {
  queue: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToasts: (
      state: ToastState,
      action: PayloadAction<ToastMessageType[]>,
    ) => {
      state.queue = [...state.queue, ...action.payload];
    },
    removeToast: (state: ToastState) => {
      state.queue.shift();
    },
    clearToasts: (state: ToastState) => {
      state.queue = [];
    },
  },
});

export const { addToasts, removeToast, clearToasts } = toastSlice.actions;

export default toastSlice.reducer;
