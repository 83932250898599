import React, { useEffect, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';

import dayjs from 'dayjs';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import { GetEncaminhamentoItem } from 'src/models/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';

import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tooltip from 'src/components/Tooltip/Tooltip';
import './CardEncaminhamento.scss';

type CardEncaminhamentoProps = {
  item?: any;
  highlighted?: boolean;
};

type EncaminhamentoProps = {
  item: {
    id: number | string;
    idTipoEncaminhamento: number;
    descricaoTipoEncaminhamento: string;
    idEspecialidade: number;
    nomeEspecialidade: string;
    idMedico: number;
    nomeMedico: string;
    dataEncaminhamento: Date;
    dataAtualizacao: Date;
  };
  highlighted?: boolean;
};

export const CardEncaminhamento = ({
  item,
  highlighted = false,
}: CardEncaminhamentoProps) => {
  return (
    <Accordion expandIcon="pi pi-chevron-up">
      <AccordionTab
        headerClassName={`accordion-encaminhamento ${
          highlighted ? 'highlighted' : ''
        }`}
        contentClassName={`accordion-encaminhamento ${
          highlighted ? 'highlighted' : ''
        }`}
        header={<EncaminhamentoAccordionHeader item={item} />}
      >
        <EncaminhamentoAccordionRow item={item} highlighted={highlighted} />
      </AccordionTab>
    </Accordion>
  );
};

const EncaminhamentoAccordionHeader = ({ item }: EncaminhamentoProps) => {
  const { isMobile, isTablet } = useSize();

  return (
    <div className="card-encaminhamento-header">
      <SimpleText
        fontSize={FONT_SIZE.XXS}
        className="tag p-text-truncate p-mx-2"
      >
        {isTablet ? 'E' : 'Encaminhamento'}
      </SimpleText>

      {!isMobile && (
        <div className="p-col-4 p-md-2 p-d-flex p-flex-column p-gap-2 p-p-0">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Tipo encaminhamento
          </SimpleText>
          <SimpleText>{item.descricaoTipoEncaminhamento}</SimpleText>
        </div>
      )}

      <div className="p-col-3 p-md-2 p-d-flex p-flex-column p-gap-2 p-py-0">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Especialidade
        </SimpleText>
        <SimpleText>{item.nomeEspecialidade}</SimpleText>
      </div>

      {!isMobile && (
        <div className="p-col-2 p-d-flex p-flex-column p-gap-2 p-py-0">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Médico
          </SimpleText>
          <SimpleText className="p-text-climp-2">{item.nomeMedico}</SimpleText>
        </div>
      )}

      <div className="p-col-4 p-md-3 p-d-flex p-flex-column p-gap-2 p-py-0">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Encaminhado em:
        </SimpleText>
        <div className="p-d-flex p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            <i className="fa fa-calendar-days" />
          </SimpleText>
          <SimpleText>
            {item.dataEncaminhamento
              ? dayjs(item.dataEncaminhamento).format('DD/MM/YYYY')
              : dayjs(item.dataAtualizacao).format('DD/MM/YYYY')}
          </SimpleText>
        </div>
      </div>
    </div>
  );
};

const EncaminhamentoAccordionRow = ({
  item,
  highlighted,
}: EncaminhamentoProps) => {
  const [encaminhamento, setEncaminhamento] =
    useState<GetEncaminhamentoItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (item.id) {
      setIsLoading(true);

      AtendimentoEncaminhamentoAPI.loadEncaminhamentoById(item.id.toString(), {
        throwError: true,
      })
        .then(data => setEncaminhamento(data))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [item.id]);

  return (
    <div className="card-encaminhamento-row p-gap-3">
      {isLoading ? (
        <SpinnerLoading full />
      ) : (
        <>
          <div className="p-d-flex p-flex-column">
            <div className="p-col-12 p-grid p-ai-center p-p-0 p-mb-3">
              <SimpleText className="p-mr-2" bold>
                CID:{' '}
              </SimpleText>
              {encaminhamento?.cids.map(item => (
                <>
                  <Tooltip target={`#${item.codigo}`} />
                  <SimpleText
                    className="tag-cids"
                    id={item.codigo}
                    key={item.codigo}
                    data-pr-tooltip={item.nome}
                    data-pr-position="top"
                  >
                    {item.codigo}
                  </SimpleText>
                </>
              ))}
            </div>
            <SimpleText bold>
              Resumo clínico/Resultado de exame(s) e procedimentos realizados
            </SimpleText>
            <SimpleText
              className={`row-content ${highlighted ? 'highlighted' : ''}`}
            >
              {encaminhamento?.resumoClinico || ''}
            </SimpleText>
          </div>
          <div className="p-d-flex p-flex-column">
            <SimpleText bold>Hipótese diagnóstica</SimpleText>
            <SimpleText
              className={`row-content ${highlighted ? 'highlighted' : ''}`}
            >
              {encaminhamento?.hipoteseDiagnostica || ''}
            </SimpleText>
          </div>
          <div className="p-d-flex p-flex-column">
            <SimpleText bold>Questionamento</SimpleText>
            <SimpleText
              className={`row-content ${highlighted ? 'highlighted' : ''}`}
            >
              {encaminhamento?.questionamento || ''}
            </SimpleText>
          </div>
        </>
      )}
    </div>
  );
};
