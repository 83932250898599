import { useState, useEffect } from 'react';

const useDebounce = (value: any, timeout = 250) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    let handler: any;
    if (!value || !timeout) {
      setState(value);
    } else {
      handler = setTimeout(() => setState(value), timeout);
    }

    return () => {
      if (handler) clearTimeout(handler);
    };
  }, [value, timeout]);

  return state;
};

export default useDebounce;
