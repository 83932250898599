import { useCallback, useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { PerfilSaudeAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PerfilSaudeAPI/PerfilSaudeAPI';
import QuestionariosAPI from 'src/APIs/ProntuarioAPI/Questionarios/QuestionariosAPI';
import * as Yup from 'yup';

import useQuestionarioController from 'src/core/hooks/Questionario/useQuestionarioController';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tab from 'src/components/Tab/Tab';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';
import ModalEvolucao from '../ModalEvolucao/ModalEvolucao';

import DorFrequente from './DorFrequente/DorFrequente';
import HistoricoFamiliar from './HistoricoFamiliar/HistoricoFamiliar';
import PerfilSaude from './PerfilSaude/PerfilSaude';
import PesquisaComplementar from './PesquisaComplementar/PesquisaComplementar';
import SaudeMental from './SaudeMental/SaudeMental';
import { validatioSchema } from './validation';
import Vicios from './Vicios/Vicios';

import './ModalNovoQuestionario.scss';

interface NovoQuestionarioProps extends DisclosureType {
  reloadList: () => void;
  idAtendimento?: string | undefined;
  portal?: boolean;
}

const ModalNovoQuestionario = ({
  idAtendimento,
  reloadList,
  state,
  close,
  isOpen,
  portal,
}: NovoQuestionarioProps) => {
  const { agenda, user } = useAppSelector((state: RootState) => state);
  const { permitirNovoQuestionario, isLoading } = useQuestionarioController();

  const [tabActive, setTabActive] = useState<number>(0);

  const evolucaoDisclosure = useDisclosure({ opened: false });

  const dialogFechar = useRef<OverlayPanel>(null);

  const values = state?.questionario || state;

  const readOnly = state?.readOnly || false;

  const isNew = state?.isNew || false;

  const showDataOcorrencia = !readOnly && !portal;

  const justificativaSchema = Yup.object().shape({
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(values?.dataInclusao),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validatioSchema.concat(justificativaSchema)),
    defaultValues: {
      // avaliacaoEnfermagemAlimentacao
      excessoGorduras: values?.avaliacaoEnfermagemAlimentacao?.excessoGorduras,
      excessoSal: values?.avaliacaoEnfermagemAlimentacao?.excessoSal,
      excessoCarboidratosAcucares:
        values?.avaliacaoEnfermagemAlimentacao?.excessoCarboidratosAcucares,
      alimentacaoNaoSeAplica:
        values?.avaliacaoEnfermagemAlimentacao?.naoSeAplica,
      // avaliacaoEnfermagemCirurgia
      cardiaca: values?.avaliacaoEnfermagemCirurgia?.cardiaca,
      amputacaoPernaPe: values?.avaliacaoEnfermagemCirurgia?.amputacaoPernaPe,
      bariatrica: values?.avaliacaoEnfermagemCirurgia?.bariatrica,
      cancer: values?.avaliacaoEnfermagemCirurgia?.cancer,
      outra: values?.avaliacaoEnfermagemCirurgia?.outra,
      naoRealizou: values?.avaliacaoEnfermagemCirurgia?.naoRealizou,
      cirurgiasNaoSeAplica: values?.avaliacaoEnfermagemCirurgia?.naoSeAplica,
      // avaliacaoEnfermagemDensitometriaOssea
      colunaLombar: values?.avaliacaoEnfermagemDensitometriaOssea?.colunaLombar,
      femurTotal: values?.avaliacaoEnfermagemDensitometriaOssea?.femurTotal,
      coloFemural: values?.avaliacaoEnfermagemDensitometriaOssea?.coloFemural,
      naoRealizouDensitometria:
        values?.avaliacaoEnfermagemDensitometriaOssea?.naoRealizou,
      densitometriaNaoSeAplica:
        values?.avaliacaoEnfermagemDensitometriaOssea?.naoSeAplica,

      ...values,
      ...(isNew && {
        justificativaOcorrenciaRetroativaValidation: null,
        dataOcorrencia: new Date(),
      }),
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  useEffect(() => {
    if (values?.justificativaOcorrenciaRetroativa && !isNew) {
      setValue(
        'justificativaOcorrenciaRetroativa',
        values.justificativaOcorrenciaRetroativa,
      );
    }
  }, [isNew, setValue, values.justificativaOcorrenciaRetroativa]);

  const handleFormSubmit = useCallback(
    async (data: any) => {
      const payload = {
        id: values?.id,
        idAtendimento: Number(idAtendimento),
        validarOuRejeitar:
          user?.tipoUsuario === 'PROFISSIONAL_SAUDE' ? 'VALIDADO' : 'PENDENTE',
        dataOcorrencia: portal
          ? dayjs().format('YYYY-MM-DDTHH:mmZ')
          : dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
        ...(portal && { idPaciente: user?.idPaciente }),
        idProfissionalSaude: agenda?.profissionalAtivo?.id,
        justificativaOcorrenciaRetroativa:
          data?.justificativaOcorrenciaRetroativa || undefined,

        // VICIOS
        fumante: data.fumante,
        fumanteDomicilio: data.fumanteDomicilio,
        atividadeFisica: data.atividadeFisica,
        bebidaAlcoolica: data.bebidaAlcoolica,
        medicamentoControlado: data.medicamentoControlado,

        // DOR FREQUENTE
        dorCostasFrequente: data.dorCostasFrequente,
        dorCabecaFrequente: data.dorCabecaFrequente,
        dorRelacionadaTrabalho: data.dorRelacionadaTrabalho,
        intensidadeDores: data.intensidadeDores,

        // SAUDE MENTAL
        transtornoMental: data.transtornoMental,
        suicidio: data.suicidio,
        depressao: data.depressao,
        ansiedade: data.ansiedade,

        // HISTORICO FAMILIAR
        mama: data.mama,
        intestino: data.intestino,
        prostata: data.prostata,
        outros: data.outros,
        descricaoOutros: data.descricaoOutros,

        // PERFIL SAUDE
        satisfacaoSaude: data.satisfacaoSaude,
        diabetes: data.diabetes,
        hipertensao: data.hipertensao,
        colesterolOuTriglicerides: data.colesterolOuTriglicerides,
        doencasCerebrovasculares: data.doencasCerebrovasculares,
        doencasCardiovasculares: data.doencasCardiovasculares,
        doencasRespiratorias: data.doencasRespiratorias,
        insuficienciaRenal: data.insuficienciaRenal,
        doencasNeurologicas: data.doencasNeurologicas,

        avaliacaoEnfermagemAlimentacao: {
          excessoGorduras: data.excessoGorduras,
          excessoCarboidratosAcucares: data.excessoCarboidratosAcucares,
          excessoSal: data.excessoSal,
          equilibrada: data.equilibrada,
          naoSeAplica: data.alimentacaoNaoSeAplica,
        },

        avaliacaoEnfermagemCirurgia: {
          cardiaca: data.cardiaca,
          amputacaoPernaPe: data.amputacaoPernaPe,
          bariatrica: data.bariatrica,
          cancer: data.cancer,
          outra: data.outra,
          naoRealizou: data.naoRealizou,
          naoSeAplica: data.cirurgiasNaoSeAplica,
        },

        cirurgiaColunaProgramada: data.cirurgiaColunaProgramada,
        usuarioDrogas: data.usuarioDrogas,
        calendarioVacinal: data.calendarioVacinal,
        tomouVacinaGripe: data.tomouVacinaGripe,
        gestante: data.gestante,

        avaliacaoEnfermagemDensitometriaOssea: {
          colunaLombar: data.colunaLombar,
          femurTotal: data.femurTotal,
          coloFemural: data.coloFemural,
          naoRealizou: data.naoRealizouDensitometria,
          naoSeAplica: data.densitometriaNaoSeAplica,
        },

        // PESQUISA COMPLEMENTAR
        realizouMamografia: data.realizouMamografia,
        realizouPapanicolau: data.realizouPapanicolau,
        realizouColonoscopia: data.realizouColonoscopia,
        realizouSangueOcultoFezes: data.realizouSangueOcultoFezes,
      };

      let response: any;

      if (idAtendimento) {
        if (values && !isNew) {
          response = await QuestionariosAPI.putQuestionarios(payload);
        } else {
          response = await QuestionariosAPI.postQuestionarios(payload);
        }
      } else {
        if (isNew) {
          response = await PerfilSaudeAPI.postQuestionarioPortal(payload);
        } else {
          response = await PerfilSaudeAPI.putQuestionarioPortal(payload);
        }
      }

      if (response.id) {
        close();
        reloadList();
      }
    },
    [
      values,
      idAtendimento,
      user?.tipoUsuario,
      user?.idPaciente,
      portal,
      agenda?.profissionalAtivo?.id,
      isNew,
      close,
      reloadList,
    ],
  );

  const renderFooter = useCallback(() => {
    return (
      <div className="p-grid p-col-12">
        <div className="p-col-12 p-d-flex p-gap-1 p-ai-center p-mt-1">
          <Button
            disabled={tabActive === 0}
            label="Anterior"
            btnType="ghost"
            onClick={() => setTabActive((prev: number) => prev - 1)}
            stretch
          />

          {tabActive === 5 ? (
            <Button
              type="submit"
              label="Salvar"
              loading={isSubmitting}
              onClick={handleSubmit(handleFormSubmit)}
              stretch
            />
          ) : (
            <Button
              btnType="tonal"
              label="Proxímo"
              onClick={() => setTabActive((prev: number) => prev + 1)}
              stretch
            />
          )}
        </div>
        {Object.keys(errors).length > 0 && (
          <SimpleText fontColor={FONT_COLOR.DANGER} fontSize={FONT_SIZE.XXS}>
            Existem campos não preenchidos
          </SimpleText>
        )}
      </div>
    );
  }, [errors, handleFormSubmit, handleSubmit, isSubmitting, tabActive]);

  const renderFooterReadOnly = useCallback(() => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <Button
            label="Fechar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
        </div>
      </div>
    );
  }, [close]);

  const renderHeader = useCallback(() => {
    return (
      <div className="p-d-flex p-ai-center p-jc-between">
        <SimpleText fontSize={FONT_SIZE.SM}>
          {readOnly
            ? 'Visualizar questionário de saúde'
            : values && !isNew
            ? 'Editar questionário de saúde'
            : 'Novo questionário de saúde'}
        </SimpleText>

        {readOnly && (
          <div className="p-mr-3">
            <Button
              label="Ver evolução"
              type="button"
              icon="fas fa-chart-line"
              btnType="tonal"
              onClick={() => evolucaoDisclosure.open({ state: values.id })}
              stretch
            />
          </div>
        )}
      </div>
    );
  }, [evolucaoDisclosure, isNew, readOnly, values]);

  const handleErrors = useCallback(
    (tab: any): boolean => {
      if (tab === 'vicios') {
        if (
          errors?.fumante ||
          errors?.fumanteDomicilio ||
          errors?.bebidaAlcoolica ||
          errors?.atividadeFisica ||
          errors?.medicamentoControlado
        ) {
          return true;
        }
      }

      if (tab === 'dorFrequente') {
        if (
          errors?.dorCostasFrequente ||
          errors?.dorCabecaFrequente ||
          errors?.dorRelacionadaTrabalho ||
          errors?.intensidadeDores
        ) {
          return true;
        }
      }

      if (tab === 'mental') {
        if (
          errors?.transtornoMental ||
          errors?.suicidio ||
          errors?.depressao ||
          errors?.ansiedade
        ) {
          return true;
        }
      }

      if (tab === 'historico') {
        if (
          errors?.mama ||
          errors?.intestino ||
          errors?.prostata ||
          errors?.outros ||
          errors?.descricaoOutros
        ) {
          return true;
        }
      }

      if (tab === 'perfil') {
        if (
          errors?.satisfacaoSaude ||
          errors?.diabetes ||
          errors?.hipertensao ||
          errors?.colesterolOuTriglicerides ||
          errors?.doencasCerebrovasculares ||
          errors?.doencasCardiovasculares ||
          errors?.doencasRespiratorias ||
          errors?.insuficienciaRenal ||
          errors?.doencasNeurologicas ||
          errors?.cirurgiaColunaProgramada ||
          errors?.usuarioDrogas ||
          errors?.calendarioVacinal ||
          errors?.tomouVacinaGripe ||
          errors?.gestante
        ) {
          return true;
        }
      }

      if (tab === 'pesquisa') {
        if (
          errors?.realizouMamografia ||
          errors?.realizouPapanicolau ||
          errors?.realizouColonoscopia ||
          errors?.realizouSangueOcultoFezes
        ) {
          return true;
        }
      }

      return false;
    },
    [errors],
  );

  if (isLoading) {
    return (
      <Dialog onHide={() => {}} visible closable={false}>
        <SpinnerLoading full />
      </Dialog>
    );
  }

  if (!permitirNovoQuestionario?.retorno && isNew && !isLoading) {
    return (
      <ConfirmDialog
        header="Ação não permitida"
        visible={true}
        cancelText="Fechar"
        text={permitirNovoQuestionario?.mensagem}
        onConfirm={() => close()}
        onHide={() => close()}
      />
    );
  }

  return (
    <FormProvider {...useFormMethods}>
      <Dialog
        header={renderHeader}
        visible={isOpen}
        onHide={() => {
          !readOnly
            ? dialogFechar.current?.setState({ visible: true })
            : close();
        }}
        footer={readOnly ? renderFooterReadOnly : renderFooter}
        maximizedTabletSize
        id="modalQuestionario"
      >
        {isLoading ? (
          <SpinnerLoading full />
        ) : (
          <>
            <div className="container-novoQuestionario">
              {showDataOcorrencia && (
                <DataOcorrencia
                  control={control}
                  defaultValue={
                    values && !isNew
                      ? new Date(values.dataOcorrencia)
                      : new Date()
                  }
                  dataInclusao={values?.dataInclusao}
                />
              )}

              <form>
                <Tab
                  className="p-mt-1"
                  setActiveIndex={tabActive}
                  showBadge
                  onChange={e => setTabActive(e)}
                  values={[
                    {
                      label: 'Vícios',
                      headerClass: handleErrors('vicios') ? 'error-tab' : 'tab',

                      content: <Vicios readOnly={readOnly} values={values} />,
                    },

                    {
                      label: 'Dor frequente',
                      headerClass: handleErrors('dorFrequente')
                        ? 'error-tab'
                        : 'tab',

                      content: (
                        <DorFrequente readOnly={readOnly} values={values} />
                      ),
                    },

                    {
                      label: 'Saúde mental',
                      headerClass: handleErrors('mental') ? 'error-tab' : 'tab',

                      content: (
                        <SaudeMental readOnly={readOnly} values={values} />
                      ),
                    },

                    {
                      label: 'Histórico familiar câncer',
                      headerClass: handleErrors('historico')
                        ? 'error-tab'
                        : 'tab',

                      content: (
                        <HistoricoFamiliar
                          readOnly={readOnly}
                          values={values}
                        />
                      ),
                    },

                    {
                      label: 'Perfil saúde',
                      headerClass: handleErrors('perfil') ? 'error-tab' : 'tab',

                      content: (
                        <PerfilSaude readOnly={readOnly} values={values} />
                      ),
                    },

                    {
                      label: 'Pesquisa complementar',
                      headerClass: handleErrors('pesquisa')
                        ? 'error-tab'
                        : 'tab',

                      content: (
                        <PesquisaComplementar
                          readOnly={readOnly}
                          values={values}
                        />
                      ),
                    },
                  ]}
                />
              </form>
            </div>

            <OverlayPanel
              style={{
                top: '40px',
                right: '15px',
                maxWidth: '463px',
                height: 'fit-content',
              }}
              appendTo="self"
              ref={dialogFechar}
            >
              <div className="p-col-12 p-d-flex p-flex-column p-gap-3">
                <SimpleText fontSize="md">
                  Deseja realmente sair do questionário?
                </SimpleText>
                <SimpleText className="p-line-height-2">
                  Existem dados que ainda não foram salvos no questionário e
                  serão perdidos. Clique em <b>“Sim, fechar questionário”</b>{' '}
                  pra continuar!
                </SimpleText>

                <div className="p-d-flex p-gap-2 p-col-12">
                  <Button
                    label="Não"
                    btnType="ghost"
                    onClick={() => dialogFechar.current?.hide()}
                    stretch
                  />
                  <Button
                    label="Sim, fechar questionário"
                    btnType="danger"
                    onClick={() => [dialogFechar.current?.hide(), close()]}
                    stretch
                  />
                </div>
              </div>
            </OverlayPanel>
          </>
        )}
      </Dialog>

      {evolucaoDisclosure.isOpen && (
        <ModalEvolucao
          control={control}
          idAtendimento={idAtendimento}
          {...evolucaoDisclosure}
        />
      )}
    </FormProvider>
  );
};

export default ModalNovoQuestionario;
