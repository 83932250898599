import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams, DropdownProps } from 'primereact/dropdown';
import { useController, useFormContext } from 'react-hook-form';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type FieldPacienteProps = DropdownProps & {
  name?: string;
  label: string;
  params?: any;
};

export const FieldPacientePreNatal = ({
  name = 'idPaciente',
  label,
  params,
  ...rest
}: FieldPacienteProps) => {
  const { control, watch } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [pacientes, setPacientes] = useState<GetPacienteDTO[]>([]);
  const [loadingSearchPaciente, setLoadingSearchPaciente] = useState(false);
  const [querySearchPaciente, setQuerySearchPaciente] = useState('');

  const debouncedSearchConvenio = useDebounce(querySearchPaciente, 350);

  const fetchPacientes = useCallback(
    async (queryParam: any) => {
      if (!queryParam) return setPacientes([]);

      try {
        const response = await PreNatalAPI.getPacientesPreNatal({
          nome: queryParam,
          ...params,
        });

        setPacientes(response.list);
      } catch (error) {
        setPacientes([]);
      } finally {
        setLoadingSearchPaciente(false);
      }
    },
    [params],
  );

  useEffect(() => {
    const getPacientes = async () => {
      try {
        setLoadingSearchPaciente(true);
        const response = await PreNatalAPI.getPacientesPreNatal();
        setPacientes(response.list);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSearchPaciente(false);
      }
    };
    getPacientes();
  }, []);

  useEffect(() => {
    fetchPacientes(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, fetchPacientes]);

  const pacientesOptions = useMemo(() => {
    const options = pacientes.map(paciente => ({
      label: paciente.nome,
      value: paciente.id,
    }));

    return options;
  }, [pacientes]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setLoadingSearchPaciente(true);
    setQuerySearchPaciente(filter);
  };

  const options = pacientesOptions.length ? pacientesOptions : defaultOption;
  const emptyFilterMessage = querySearchPaciente.length
    ? 'Nenhuma opção encontrada'
    : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loadingSearchPaciente}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
