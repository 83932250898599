import React, { ChangeEvent, useContext } from 'react';

import {
  InputTextarea as PrimeTextareaInput,
  InputTextareaProps as PrimeInputTextareaProps,
} from 'primereact/inputtextarea';

import ThemeContext from 'src/core/themes/ThemeContext';

import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './TextareaInput.scss';
export interface TextareaInputProps extends PrimeInputTextareaProps {
  label?: string | undefined;
  errorMsg?: string | any;
  onChange?(e: ChangeEvent<HTMLTextAreaElement>): void;
  value?: string;
  maxLengthSpan?: boolean;
  maxLengthSpanDesc?: boolean;
  sublabel?: string;
  resizeble?: boolean;
}

const TextareaInput = React.forwardRef<any, TextareaInputProps>(
  (
    {
      label,
      name,
      errorMsg,
      className,
      onChange,
      disabled,
      value = '',
      maxLength,
      maxLengthSpan,
      maxLengthSpanDesc,
      sublabel,
      resizeble = true,
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext);
    const resizebleClass = resizeble ? 'resizeble' : '';

    const renderLabel = () => {
      if (label || label === '') {
        return (
          <ComponentsLabel name={name} sublabel={sublabel} label={label} />
        );
      }
      return <></>;
    };

    return (
      <div
        className={`TextareaInput ${theme?.theme || ''} ${
          errorMsg ? 'error' : ''
        } ${disabled ? 'disabled-text-input' : ''} ${resizebleClass} ${
          className || ''
        }`}
        key={name}
      >
        {renderLabel()}
        <span className={`iconHandler ${errorMsg ? 'p-input-icon-right' : ''}`}>
          {errorMsg ? <i className={'pi pi-exclamation-circle'} /> : <></>}
          <PrimeTextareaInput
            {...rest}
            ref={ref}
            id={name}
            maxLength={maxLength}
            className={`${errorMsg ? 'p-invalid' : ''} ${
              rest.readOnly ? 'readOnly' : ''
            }`}
            value={value}
            disabled={disabled}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </span>
        <div className={'p-d-flex p-jc-between p-ai-center'}>
          {maxLengthSpanDesc && (
            <SimpleText
              className={'p-text-normal'}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {`Faltam ${Number(maxLength) - Number(value?.length)} caracteres`}
            </SimpleText>
          )}
          <div>
            {errorMsg && (
              <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                {errorMsg}
              </SimpleText>
            )}
          </div>

          {maxLengthSpan && (
            <SimpleText className={'p-text-normal'} fontSize={FONT_SIZE.XXS}>
              {`${value.length}/${maxLength}`}
            </SimpleText>
          )}
        </div>
      </div>
    );
  },
);

export default TextareaInput;
