import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/resultado-exames';

const postVisualizarResultados = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/visualizar/lista`,
    data: payload,
    throwError: true,
    responseType: 'arraybuffer',
    hideToast: true,
    ...options,
  });

export const ResultadoExamesAPI = {
  postVisualizarResultados,
};
