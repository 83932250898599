import Http, { HttpParams } from 'src/core/http/Http';

export const getTemperatura = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `temperatura/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesTemperatura = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'temperatura/valores-enum',
    hideToast: true,
    ...options,
  });

export const getEquipamentos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'temperatura/equipamentos-enum',
    hideToast: true,
    ...options,
  });

export const adicionarTemperatura = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'temperatura',
    data: payload,
    return: true,
    ...options,
  });

export const alterarTemperatura = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `temperatura/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarTemperatura = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `temperatura/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerTemperatura = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `temperatura/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const TemperaturaAPI = {
  getTemperatura,
  getUnidadesTemperatura,
  getEquipamentos,
  adicionarTemperatura,
  alterarTemperatura,
  inativarTemperatura,
  removerTemperatura,
};

export default TemperaturaAPI;
