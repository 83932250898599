import Page from 'src/components/Basics/Page/Page';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import ListProfessionals from './AbasAgendamentosOnline/ListProfessionals/ListProfessionals';

import './AgendamentosOnline.scss';

const AgendamentosOnline = () => {
  return (
    <Page className={`AgendamentosOnline`}>
      <VerticalTab
        tabItens={[
          {
            label: 'Bloqueio de pacientes',
            content: <ListProfessionals />,
          },
          {
            label: 'Alertas',
            content: (
              <div className="right">
                <p>Alertas</p>
              </div>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default AgendamentosOnline;
