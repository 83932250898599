import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

const sexoOptions = [
  { label: 'Masculino', value: 'M' },
  { label: 'Feminino', value: 'F' },
];

const faixasEtariasInitial = [
  {
    inicio: null,
    fim: null,
    sexo: null,
    pontuacao: null,
    ativo: true,
    id: null,
    uniqueId: generateUniqueId(),
  },
];

interface FaixaEtariaProps {
  onSubmit(v: any): void;
  values: any;
}

const FaixaEtaria = ({ values, onSubmit }: FaixaEtariaProps) => {
  const { id } = useParams();
  const { isTablet } = useSize();

  const checkFaixasEtariasDuplicadas = (
    currentFaixaEtaria: any,
    faixasEtarias: any[],
  ) => {
    if (
      currentFaixaEtaria.inicio === null ||
      currentFaixaEtaria.inicio === '' ||
      currentFaixaEtaria.fim === null ||
      currentFaixaEtaria.fim === ''
    )
      return true;

    const otherFaixasEtarias = faixasEtarias.filter(
      faixaEtaria => faixaEtaria.uniqueId !== currentFaixaEtaria.uniqueId,
    );

    return !otherFaixasEtarias.some(faixa => {
      return (
        faixa.inicio === currentFaixaEtaria.inicio &&
        faixa.fim === currentFaixaEtaria.fim &&
        faixa.sexo === currentFaixaEtaria.sexo
      );
    });
  };

  const faixasEtariasSchema = Yup.object({
    inicio: Yup.number()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    fim: Yup.number()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório')
      .test(
        'min',
        'A faixa etária final deve ser maior.',
        (value: any, context: any) => value > context.parent.inicio,
      ),
    sexo: Yup.string()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    pontuacao: Yup.number()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
  }).test(
    'faixasEtariasDuplicadas',
    'Faixa etária já cadastrada para o sexo informado!',
    (faixaEtaria: any, context) => {
      return checkFaixasEtariasDuplicadas(faixaEtaria, context.parent);
    },
  );

  const validationSchema = Yup.object().shape({
    nome: Yup.string()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    faixasEtarias: Yup.array().of(faixasEtariasSchema),
  });

  const defaultValues = {
    nome: values?.nome || '',
    faixasEtarias: values?.faixasEtarias || faixasEtariasInitial,
  };

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'faixasEtarias',
    keyName: 'uuid',
  });

  const handleAdd = () => {
    append({
      inicio: null,
      fim: null,
      sexo: null,
      pontuacao: null,
      ativo: true,
      id: null,
      uniqueId: generateUniqueId(),
    });
  };

  return (
    <div className="p-grid p-col-12 p-lg-10 p-xl-12 p-pl-5">
      <FormProvider {...useFormMethods}>
        <>
          <div className="formPontuacao-content">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              bold
            >
              {id
                ? 'Editar Pontuação carteira'
                : 'Cadastrar Pontuação carteira'}
            </SimpleText>
            <SimpleText
              className="p-mt-4"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              Insira um nome para o grupo
            </SimpleText>

            <FormInput
              className="p-col-12 p-lg-4 p-xl-6 p-px-0 p-mt-1"
              name="nome"
              label="Nome do grupo"
              maxLength={100}
              hideTextErrorSpace
              errorMsg={errors?.nome?.message}
            />

            <SimpleText
              className="p-mt-2"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              {id
                ? 'Editar faixa etária e pontuação'
                : 'Cadastrar faixa etária e pontuação'}
            </SimpleText>
            <div className="border p-mt-3">
              {fields.map((field, index) => (
                <div key={field.uuid}>
                  <div className="p-grid p-ai-end aling-itens">
                    <FormInput
                      className="p-col-12 p-lg-4 p-xl-2 p-py-0"
                      name={`faixasEtarias.${index}.inicio`}
                      label="Faixa etária"
                      type="number"
                      hideTextErrorSpace
                      max={199}
                      errorMsg={errors?.faixasEtarias?.[index]?.inicio?.message}
                    />

                    <SimpleText
                      className={`p-mx-2 ${isTablet ? 'p-mb-3' : 'p-mb-4'}`}
                      fontSize={FONT_SIZE.XS}
                      fontColor={FONT_COLOR.COLOR_16}
                      bold
                    >
                      a
                    </SimpleText>

                    <FormInput
                      className={`p-col-12 p-lg-4 p-xl-2 ${
                        isTablet ? 'p-py-0' : 'p-pt-4'
                      }`}
                      name={`faixasEtarias.${index}.fim`}
                      type="number"
                      hideTextErrorSpace
                      max={199}
                      errorMsg={errors?.faixasEtarias?.[index]?.fim?.message}
                    />

                    <DropdownControlled
                      className="p-col-12 p-lg-4 p-xl-2 p-py-0"
                      control={control}
                      name={`faixasEtarias.${index}.sexo`}
                      label="Sexo"
                      options={sexoOptions}
                      filter={false}
                      errorMsg={errors?.faixasEtarias?.[index]?.sexo?.message}
                    />

                    <FormInput
                      className="p-col-12 p-lg-4 p-xl-2 p-py-0"
                      name={`faixasEtarias.${index}.pontuacao`}
                      label="Pontuação"
                      type="number"
                      hideTextErrorSpace
                      errorMsg={
                        errors?.faixasEtarias?.[index]?.pontuacao?.message
                      }
                      mode="decimal"
                      maxFractionDigits={2}
                      max={999.99}
                    />

                    <div className="p-mt-2 p-col p-d-flex p-py-0 p-ai-center p-jc-end aling-center">
                      <>
                        <SwitchControlled
                          name={`faixasEtarias.${index}.ativo`}
                          control={control}
                          showStatus
                        />

                        <Divider layout="vertical" />

                        <Button
                          type="button"
                          icon="fa fa-trash"
                          disabled={fields.length === 1}
                          onClick={() => remove(index)}
                          btnType="gray"
                        />
                      </>
                    </div>

                    {errors.faixasEtarias?.[index]?.message && (
                      <SimpleText
                        className="p-col-12 error"
                        fontSize={FONT_SIZE.XXXS}
                      >
                        {errors.faixasEtarias?.[index]?.message}
                      </SimpleText>
                    )}
                  </div>
                </div>
              ))}

              <Button
                label="Faixa etária"
                onClick={handleAdd}
                icon="fas fa-plus"
                btnType="green-link"
              />
            </div>
          </div>

          <div className="p-col-12 p-lg-6 p-xl-4 p-mt-2">
            <Button
              className="p-my-1 p-w-100"
              label="Avançar"
              type="submit"
              disabled={fields.length === 0}
              onClick={handleSubmit(onSubmit)}
              loading={isSubmitting}
            />
          </div>
        </>
      </FormProvider>
    </div>
  );
};

export default FaixaEtaria;
