import { useEffect, useState, useMemo, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import Button from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { saveDadosPessoais, saveContato, saveEndereco } from '../helpers';
import { useManterPaciente } from '../MaterPacienteContext';

import CamposDemograficosObrigatorios from './CamposDemograficosObrigatorios';
import ModalPacientesSemelhantes from './ModalPacientesSemelhantes/ModalPacientesSemelhantes';
import validationSchema from './validationSchema';

const sexoOptions = [
  {
    label: 'Masculino',
    value: 3,
  },
  {
    label: 'Feminino',
    value: 1,
  },
];

interface DadosObrigatoriosProps {
  camposDemograficosObrigatorios: boolean;
  loadingCamposDemograficos: boolean;
}

export const DadosObrigatorios = ({
  camposDemograficosObrigatorios,
  loadingCamposDemograficos,
}: DadosObrigatoriosProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const { handleSetFormDirty, uploadingFoto } = useManterPaciente();

  const navigate = useNavigate();

  const { idPaciente } = useParams();

  const [modalSemelhantes, setModalSemelhantes] = useState<any>(null);
  const [quantidadeMesesObrigaCpf, setQuantidadeMesesObrigaCpf] =
    useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);

  const useFormMethods = useForm({
    resolver: yupResolver(
      validationSchema(
        camposDemograficosObrigatorios,
        quantidadeMesesObrigaCpf,
        !!idPaciente,
      ),
    ),
  });

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
  } = useFormMethods;

  useEffectSkipFirst(() => {
    handleSetFormDirty(isDirty, 'DADOS_OBRIGATORIOS');
  }, [isDirty]);

  const loadCpfMenoObrigatorio = async () => {
    const response = await ParametroAPI.loadParametros({
      codigoParametro: 'OBRIGA_CPF_MENOR',
    });

    if (response?.list?.[0]?.valor)
      setQuantidadeMesesObrigaCpf(Number(response.list[0].valor));
  };

  useEffect(() => {
    loadCpfMenoObrigatorio();
  }, []);

  const loadDadosObrigatorios = useCallback(async () => {
    if (!idPaciente) return;

    setLoading(true);

    try {
      const response = await PacienteAPI.getPacienteDadosObrigatorios(
        Number(idPaciente),
        { errorHideToast: true, return: true, throwError: true },
      );

      if (response.status === 404) {
        toast(<Toast />, {
          data: { message: 'Paciente não encontrado.', type: 'warning' },
        });
        return navigate('/pacientes');
      }

      setValue('nome', response.data.nome);
      setValue('idSexo', response.data.idSexo);
      setValue('cpf', response.data.cpf);
      setValue('dataNascimento', dayjs(response.data.dataNascimento).toDate());
      setValue('estrangeiro', response.data.estrangeiro);
      setValue('passaporte', response.data.passaporte);
      setValue('nomeMae', response.data?.nomeMae || '');
      setValue('maeDesconhecida', response.data.maeDesconhecida);
      setValue('recemNascido', response.data.tipoPaciente === 'RECEM_NASCIDO');
      setValue('convenioResponsavel', response.data?.convenioResponsavel);
      setValue(
        'numeroCartaoResponsavel',
        response.data?.numeroCartaoResponsavel || '',
      );
    } catch (error) {
      toast(<Toast />, {
        data: { message: 'Erro ao carregar paciente.', type: 'warning' },
      });
      return navigate('/pacientes');
    } finally {
      setLoading(false);
    }
  }, [idPaciente, navigate, setValue]);

  useEffect(() => {
    loadDadosObrigatorios();
  }, [loadDadosObrigatorios]);

  useEffect(() => {
    reset({
      cpf: null,
      maeDesconhecida: false,
      estrangeiro: false,
      recemNascido: false,
      nomeMae: '',
      cep: '',
    });
  }, [reset]);

  const watchEstrangeiro = watch('estrangeiro');
  const watchMaeDesconhecida = watch('maeDesconhecida');
  const watchRecemNascido = watch('recemNascido');

  useEffect(() => {
    const nomeMae = getValues('nomeMae');

    if (watchMaeDesconhecida && !!nomeMae) setValue('nomeMae', '');
  }, [watchMaeDesconhecida, getValues, setValue]);

  const checkPacienteExiste = async (payload: any) => {
    if (idPaciente) return false;

    const { pacientesDuplicados, pacientesSemelhantes } =
      await PacienteAPI.pacientesSemelhantes(payload);

    if (!Array.isArray(pacientesDuplicados)) return false;

    if (!!pacientesDuplicados.length || !!pacientesSemelhantes.length) {
      setModalSemelhantes({
        pacientesDuplicados,
        pacientesSemelhantes,
        payload,
      });
      return true;
    }

    return false;
  };

  const updatePacienteDadosDemograficos = async (
    idPaciente: number,
    payload: any,
  ) => {
    const [responseDadosPessoais, responseEndereco, responseContato] =
      await Promise.all([
        saveDadosPessoais(idPaciente, user?.idEmpresa, payload, true),
        saveEndereco(idPaciente, user?.idEmpresa, payload, true),
        saveContato(idPaciente, user?.idEmpresa, payload, true),
      ]);

    if (
      'status' in responseDadosPessoais ||
      'status' in responseEndereco ||
      'status' in responseContato
    ) {
      await PacienteAPI.removePaciente(idPaciente, true);

      return false;
    }

    return true;
  };

  const createPaciente = async (payload: any) => {
    const response = await PacienteAPI.createPaciente(
      payload,
      camposDemograficosObrigatorios,
    );

    const success =
      camposDemograficosObrigatorios && response?.id
        ? await updatePacienteDadosDemograficos(response.id, payload)
        : !!response?.id;

    if (success && camposDemograficosObrigatorios) {
      const data = { message: 'Paciente criado com sucesso.', type: 'success' };
      toast(<Toast />, { data });
    }

    if (success) navigate(`/pacientes/${response.id}`);
  };

  const onSubmit = async (data: any) => {
    const cpf = data.cpf ? data.cpf.match(/\d/g).join('') : null;
    const numeroCartaoResponsavel = data.numeroCartaoResponsavel
      ? data.numeroCartaoResponsavel.match(/\d/g).join('')
      : undefined;

    const payload = {
      ...data,
      cpf,
      numeroCartaoResponsavel,
      idConvenioResponsavel: data?.convenioResponsavel?.id || null,
      idEmpresa: user?.idEmpresa,
    };

    const pacienteExiste = await checkPacienteExiste(payload);

    if (pacienteExiste) return;

    const { updatePaciente } = PacienteAPI;

    const savePaciente = idPaciente ? updatePaciente : createPaciente;

    await savePaciente(payload, Number(idPaciente));

    reset(data, { keepValues: true });
  };

  if (loading || loadingCamposDemograficos)
    return (
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-4">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="p-grid p-col-12 p-md-6 p-lg-4"
        >
          <TextInputControlled
            className="p-col-12"
            control={control}
            name="nome"
            label="Nome completo"
            placeholder="Nome do paciente"
            errorMsg={errors.nome?.message}
          />
          <SelectButtonControlled
            className="p-col-12"
            control={control}
            name="idSexo"
            label="Sexo"
            options={sexoOptions}
            errorMsg={errors.idSexo?.message}
          />
          <div className="p-col-12 p-xl-6">
            <MaskedInputControlled
              control={control}
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              mask={MASK.CPF}
              errorMsg={errors.cpf?.message}
            />
            <CheckboxControlled
              className="checkbox-small"
              control={control}
              name="estrangeiro"
              label="Estrangeiro"
            />
          </div>

          <CalendarInputControlled
            className="p-col-12 p-xl-6"
            control={control}
            name="dataNascimento"
            label="Data de nascimento"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            errorMsg={errors.dataNascimento?.message}
            maxDate={new Date()}
          />

          {watchEstrangeiro && (
            <TextInputControlled
              className="p-col-12"
              control={control}
              name="passaporte"
              label="Passaporte"
              maxLength={8}
              errorMsg={errors.passaporte?.message}
            />
          )}

          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="nomeMae"
              label="Nome da mãe"
              placeholder="Nome da mãe"
              errorMsg={errors.nomeMae?.message}
              disabled={!!watchMaeDesconhecida}
            />
            <CheckboxControlled
              className="checkbox-small"
              control={control}
              name="maeDesconhecida"
              label="Mãe desconhecida"
            />
          </div>

          <div className="p-col-12">
            <CheckboxControlled
              className="checkbox-small"
              control={control}
              name="recemNascido"
              label="Recém nascido"
            />
          </div>

          {watchRecemNascido && (
            <>
              <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
                Para paciente recém nascido cadastre o convênio e o número da
                carteirinha do responsável
              </SimpleText>

              <FieldConvenio
                name="convenioResponsavel"
                label="Escolha o plano de saúde do responsável"
                placeholder="Selecione"
              />

              <MaskedInputControlled
                className="p-col-12"
                control={control}
                name="numeroCartaoResponsavel"
                label="Número da carteirinha do responsável"
                placeholder="0 000 000000000000 0"
                mask={MASK.CARTEIRINHA}
                autoClear={false}
                errorMsg={errors.numeroCartaoResponsavel?.message}
              />
            </>
          )}

          {!idPaciente && camposDemograficosObrigatorios && (
            <CamposDemograficosObrigatorios />
          )}

          <div className="p-col-12 p-mt-5">
            <Button
              type="submit"
              label={idPaciente ? 'Salvar alterações' : 'Concluir cadastro'}
              loading={isSubmitting || uploadingFoto}
            />
          </div>
        </form>
      </FormProvider>

      <ModalPacientesSemelhantes
        modalSemelhantes={modalSemelhantes}
        onHide={() => setModalSemelhantes(null)}
        onCreate={createPaciente}
      />
    </>
  );
};

export default DadosObrigatorios;
