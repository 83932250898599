import { verificarParametos } from 'src/utils/utils';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  parametro: Parametro;
  onEdit: () => void;
  onDelete: () => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { parametro, onEdit, onDelete } = props;

  const options = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_PARAMETROS_ALTERAR',
    },
    {
      label: 'Remover',
      command: onDelete,
      checkPermission: 'ADM_PARAMETROS_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {parametro?.codigo}
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {parametro?.descricao}
        </SimpleText>
      </div>

      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Valor
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {parametro?.valor}
        </SimpleText>
      </div>

      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Opções
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {parametro?.opcoes}
        </SimpleText>
      </div>

      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Personalização
        </SimpleText>

        <div className="p-mt-2">
          <SimpleText
            className={`personalizacao ${
              parametro?.listaCliente?.length > 0 ? 'personal' : ''
            }`}
            fontSize={FONT_SIZE.XXS}
          >
            {verificarParametos(parametro?.listaCliente?.length)}
          </SimpleText>
        </div>
      </div>
    </SearchListMobileRow>
  );
};
