import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { FieldAplicacao } from './_components/FieldAplicacao';
import { FieldFormulariosDinamico } from './_components/FieldFormulariosDinamico';
import { FieldPublicoAplicacao } from './_components/FieldPublicoAplicacao';
import { FieldSituacao } from './_components/FieldSituacao';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const FormularioDinamicoAplicacaoFormularioFilter = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { setFilter, filter } = useSearchList();

  const defaultValues = {
    ...(filter.convenio && {
      convenio: filter.convenio,
    }),
    ...(filter.formulario && {
      formulario: filter.formulario,
    }),
    ...(filter.publico && {
      publico: {
        codigo: filter.publico,
      },
    }),
    ...(filter.situacao && {
      situacao: {
        codigo: filter.situacao,
      },
    }),
    ...(filter.dataInicioVigencia && {
      dataInicioVigencia: filter.dataInicioVigencia,
    }),
    ...(filter.dataFimVigencia && {
      dataFimVigencia: filter.dataFimVigencia,
    }),
  };

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        dataInicio: Yup.date(),
        dataFim: Yup.date().when('dataInicio', {
          is: (dataInicio: Date) => !!dataInicio,
          then: Yup.date().min(
            Yup.ref('dataInicio'),
            'Data fim deve ser maior que data início',
          ),
        }),
      }),
    ),
    defaultValues,
  });

  const onSubmit = (v: any) => {
    setFilter({
      idConvenio: v.convenio?.id,
      convenio: v.convenio,
      idFormularioDinamico: v.formulario?.id,
      formulario: v.formulario,
      publico: v.publico?.codigo,
      situacao: v.situacao?.codigo,
      dataInicioVigencia: v.dataInicioVigencia,
      dataFimVigencia: v.dataFimVigencia,
    });

    onSuccess?.();
  };

  return (
    <div
      className="p-p-1"
      style={{
        width: '360px',
        borderRadius: '18px',
      }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="">
          <SimpleText fontColor="color_40">
            Pesquise as aplicações de forma detalhada, preenchendo um ou mais
            dos campos abaixo.
          </SimpleText>

          <div className="p-my-3 p-d-flex p-flex-wrap">
            <FieldConvenio
              name="convenio"
              label="Convênio"
              placeholder="Selecione"
              className="p-px-0 p-col-12"
              hideTextErrorSpace
              showClear
            />
            <FieldFormulariosDinamico
              label="Nome do formulário"
              name="formulario"
              className="p-px-0 p-col-12"
              placeholder="Selecione"
              hideTextErrorSpace
              showClear
            />

            <FieldAplicacao
              label="Nome da aplicação"
              name="nomeAplicacao"
              className="p-px-0 p-col-12"
              placeholder="Selecione"
              hideTextErrorSpace
              showClear
            />
            <FieldPublicoAplicacao
              label="Público da aplicação"
              name="publico"
              className="p-pl-0 p-col-6"
              placeholder="Selecione"
              hideTextErrorSpace
              showClear
            />
            <FieldSituacao
              label="Situação"
              name="situacao"
              className="p-pr-0 p-col-6"
              placeholder="Selecione"
              hideTextErrorSpace
              showClear
            />

            <CalendarInputControlled
              control={form.control}
              name="dataInicioVigencia"
              label="Data de início"
              className="p-pl-0 p-col-6"
              placeholder="Selecione"
              showIcon
              hideTextErrorSpace
            />
            <CalendarInputControlled
              control={form.control}
              name="dataFimVigencia"
              label="Data fim"
              className="p-pr-0 p-col-6"
              placeholder="Selecione"
              showIcon
              hideTextErrorSpace
            />
          </div>

          <div className="p-d-flex p-gap-2">
            <Button label="Cancelar" btnType="outline" />
            <Button type="submit" label="Pesquisar" stretch />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
