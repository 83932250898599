import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';

import { FieldListarPerguntasVinculo } from '../FieldListarPerguntasVinculo/FieldListarPerguntasVinculo';
import { FieldListarRespostasPerguntaVinculo } from '../FieldListarRespostasPerguntaVinculo/FieldListarRespostasPerguntaVinculo';

type FormData = {
  perguntaVinculada: IFormularioDinamicoPergunta;
  alternativaVinculada: any;
};

export const DialogNovaPerguntaVincularForm = ({
  close,
  perguntaValues,
  handleReload,
}: {
  close(): void;
  perguntaValues: IPerguntaFormularioItem;
  handleReload(): void;
}) => {
  const form = useForm<FormData>({
    defaultValues: {
      ...(perguntaValues?.idFormularioPerguntaVinculada && {
        perguntaVinculada: {
          id: perguntaValues.idFormularioPerguntaVinculada,
          perguntaFormularioDinamico: {
            id: perguntaValues.idPerguntaVinculada,
            enunciado: '',
          },
        },
        alternativaVinculada: {
          id: perguntaValues.idOpcaoPerguntaVinculada,
        },
      }),
    } as any,
    resolver: yupResolver(
      Yup.object({
        perguntaVinculada: Yup.object().nullable(),
        alternativaVinculada: Yup.object().when('perguntaVinculada', {
          is: (perguntaVinculada: any) => !!perguntaVinculada,
          then: Yup.object().required('É obrigatório informar uma alternativa'),
        }),
      }),
    ),
  });

  const perguntaVinculada = form.watch('perguntaVinculada');

  const onSubmit = async (v: FormData) => {
    if (!v.perguntaVinculada) return close();
    if (!perguntaValues) return;

    try {
      await PerguntaFormularioDinamicoAPI.updatePerguntaFormulario(
        {
          ...perguntaValues,
          idPerguntaVinculada:
            v.perguntaVinculada.perguntaFormularioDinamico.id,
          idOpcaoPerguntaVinculada: v.alternativaVinculada?.id,
          idFormularioPerguntaVinculada: v.perguntaVinculada.id,
          idFormularioDinamico: perguntaValues?.idFormularioDinamico,
          ...(perguntaValues && {
            id: perguntaValues.id,
          }),
          ...(perguntaValues?.idFormularioDinamicoPergunta && {
            idFormularioDinamicoPergunta:
              perguntaValues.idFormularioDinamicoPergunta,
          }),
        },
        {
          throwError: true,
        },
      );

      handleReload();
      close();
    } catch (error) {}
    return;
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <AlertBox
        text="Só é possível vincular à perguntas de escolha e múltipla escolha."
        visible
        type="WARN"
      />

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-wrap"
        >
          <FieldListarPerguntasVinculo
            label="Vincular à pergunta"
            name="perguntaVinculada"
            className="p-col-12 p-sm-6"
            placeholder="Selecione"
            perguntaId={perguntaValues?.id || 0}
            idFormularioDinamico={perguntaValues?.idFormularioDinamico || 0}
            onChange={() => form.setValue('alternativaVinculada', null)}
          />
          <FieldListarRespostasPerguntaVinculo
            label="Alternativa"
            name="alternativaVinculada"
            className="p-col-12 p-sm-6"
            placeholder="Selecione"
            idPergunta={perguntaVinculada?.perguntaFormularioDinamico?.id || 0}
            disabled={!perguntaVinculada}
          />

          <div className="p-col-12 p-d-flex p-gap-2">
            <Button
              label="Cancelar"
              btnType="ghost"
              stretch
              onClick={() => close()}
              disabled={form.formState?.isSubmitting}
            />
            <Button
              label="Salvar"
              stretch
              type="submit"
              loading={form.formState?.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
