import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import NotificacaoAPI from 'src/APIs/NotificacaoAPI/NotificacaoAPI';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { emojiTypes } from './utils';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';
import { AgradecimentoDialog } from 'src/pages/Portal/Admin/Home/components/AgradecimentoDialog/AgradecimentoDialog';

import { NotificationsList } from 'src/pages/Emed/LoggedRoot/MainMenu/MainMenuNotification/MainMenuNotification';

import { NavBarNotificacaoAvaliacao } from './NavBarNotificacaoAvaliacao';

type Props = {
  notifications: NotificationsList[];
  unreadNotifications: NotificationsList[];
  readNotifications: NotificationsList[];
  isLoading: boolean;
  onSuccessNotification(): void;
};

export const NavBarNotificacaoSistema = (items: Props) => {
  const dispatch = useDispatch();

  const notifications = items.notifications;

  const handleReadNotification = () => {
    const ids = items.unreadNotifications.map(item => item.id);
    return NotificacaoAPI.atualizarStatusNotificacoes(ids).then(() => {
      dispatch(
        setInvalidateQuery({
          invalidateNotificacoes: true,
        }),
      );
    });
  };

  return !items.isLoading && notifications.length ? (
    <div
      style={{
        maxHeight: '460px',
        overflowY: 'scroll',
      }}
    >
      {!!items.unreadNotifications?.length && (
        <>
          <div className="p-d-flex p-jc-between p-ai-center p-my-1">
            <SimpleText medium>Novos</SimpleText>
            <Button
              label="Marcar todos como lido"
              btnType="ghost"
              onClick={handleReadNotification}
            />
          </div>

          <div className="p-d-flex p-flex-column p-gap-2">
            {items.unreadNotifications.map((item, idx) => (
              <NavBarNotificacaoItem
                item={item}
                key={idx}
                handleOpenDialogSuccess={items.onSuccessNotification}
              />
            ))}
          </div>
        </>
      )}

      {!!items.readNotifications?.length && (
        <>
          <div className="p-d-flex p-jc-between p-ai-center p-mb-2 p-mt-3">
            <SimpleText medium>Visualizados</SimpleText>
          </div>

          <div className="p-d-flex p-flex-column p-gap-2">
            {items.readNotifications.map((item, idx) => (
              <NavBarNotificacaoItem
                key={idx}
                item={item}
                handleOpenDialogSuccess={items.onSuccessNotification}
                isVisualizado
              />
            ))}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="p-w-100 p-text-center">
      {!!items.isLoading ? (
        <LoadingComponent />
      ) : (
        <SimpleText fontColor="color_40">
          Suas notificações apareceram aqui
        </SimpleText>
      )}
    </div>
  );
};

const NavBarNotificacaoItem = ({
  isVisualizado,
  item,
  handleOpenDialogSuccess,
}: {
  isVisualizado?: boolean;
  item: NotificationsList;
  handleOpenDialogSuccess: () => void;
}) => {
  const title = item.titulo;
  const complemento = item.conteudo;

  const diffTempo = useMemo(() => {
    const diffInMinutes = dayjs(new Date()).diff(
      new Date(item.dataCriacao),
      'minute',
    );
    const diffInHours = dayjs(new Date()).diff(
      new Date(item.dataCriacao),
      'hour',
    );
    const diffInDays = dayjs(new Date()).diff(
      new Date(item.dataCriacao),
      'days',
    );

    return diffInMinutes < 60
      ? `Há ${diffInMinutes} min.`
      : diffInHours <= 24
      ? `Há ${diffInHours} ${diffInHours === 1 ? 'hora' : 'horas'}`
      : diffInDays <= 6
      ? `Há ${diffInDays} ${diffInHours === 1 ? 'dia' : 'dias'}`
      : '';
  }, [item.dataCriacao]);

  const isAvaliacaoAtendimento =
    item.funcionalidade === 'AVALIACAO_ATENDIMENTO';

  return (
    <div className="row-component">
      <div className="p-d-flex p-gap-1 p-ai-center p-p-2">
        {!isVisualizado && (
          <span className="tag-cid p-p-">
            <SimpleText fontSize="xxxs">Novo</SimpleText>
          </span>
        )}
        <SimpleText fontSize="xxxs" fontColor="color_60">
          • {diffTempo} •
        </SimpleText>
        <SimpleText fontSize="xxxs" fontColor="color_60">
          {dayjs(item.dataCriacao).format('DD [de] MMM [de] YYYY')}
        </SimpleText>
      </div>
      <div className="p-px-2 p-pb-2 p-d-flex p-flex-column p-gap-2">
        {!!title && (
          <SimpleText fontColor="color_40" medium>
            {title}
          </SimpleText>
        )}
        <SimpleText fontColor="color_40">{complemento}</SimpleText>

        {isAvaliacaoAtendimento ? (
          !!item.dadosComplementares?.notaPesquisaSatisfacaoPortalPaciente ? (
            <AlertBox
              visible
              text={`Sua resposta foi: ${
                emojiTypes[
                  item.dadosComplementares?.notaPesquisaSatisfacaoPortalPaciente
                ]
              }`}
              type="SUCCESS"
            />
          ) : (
            <NavBarNotificacaoAvaliacao
              onSuccess={() => handleOpenDialogSuccess()}
              idAtendimento={item.dadosComplementares?.idAtendimento}
            />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-1 ">
      <Skeleton loading width="100%" className="p-mt-2" />
      <Skeleton loading width="100%" height="60px" className="p-mt-1" />
      <Skeleton loading width="100%" height="60px" />
      <Skeleton loading width="100%" className="p-mt-2" />
      <Skeleton loading width="100%" height="60px" className="p-mt-1" />
      <Skeleton loading width="100%" height="60px" />
    </div>
  );
};
