import React, { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { AuditoriaAPI } from 'src/APIs/AdminAPI/AuditoriaAPI/AuditoriaAPI';
import * as yup from 'yup';

import { handleDownloadCSV } from 'src/utils/files';
import { stringToEnum } from 'src/utils/utils';

import Button from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { Filter } from './Filter';

interface SearchFormProps {
  habilitarExportacao: boolean;
  setReload: any;
}

const SearchForm = ({ habilitarExportacao, setReload }: SearchFormProps) => {
  const { setFilter } = useSearchList();

  const validationSchema = yup.object().shape({});

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    watch,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const menuEllipsis = useRef<TieredMenu>(null);

  const handleExport = async () => {
    try {
      const filter = getValues();
      const params = {
        ...(filter?.tipoOperacao?.codigo && {
          tipoOperacao: filter.tipoOperacao.codigo,
        }),
        ...(filter?.tabela?.codigo && { tabela: filter.tabela.codigo }),
        ...(filter.numeroAssinatura && {
          numeroAssinatura: filter.numeroAssinatura,
        }),
        ...(filter?.usuario && { usuario: filter.usuario?.id }),
        ...(filter?.tipoUsuario?.descricao && {
          tipoUsuario: stringToEnum(filter.tipoUsuario.descricao),
        }),
        ...(filter?.idPaciente && { idPaciente: filter.idPaciente }),
        ...(filter?.chavePrimaria && { chavePrimaria: filter?.chavePrimaria }),
        dataInicial: watchDataInicial
          ? dayjs(new Date(watchDataInicial)).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          : null,
        dataFinal: watchDataFinal
          ? dayjs(new Date(watchDataFinal)).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          : null,
      };
      const response = await AuditoriaAPI.exportCsv({
        params,
        throwError: true,
      });
      handleDownloadCSV(response, 'rel_auditoria');
    } catch (error) {}
  };

  const optionsMenuEllipsis = [
    {
      label: 'Exportar CSV',
      command: handleExport,
    },
  ];

  const watchDataFinal = watch('dataFinal');
  const watchDataInicial = watch('dataInicial');

  const habilitarFilter = watchDataInicial && watchDataFinal;

  const onSubmit = async (data: any) => {
    const params = {
      dataInicial: dayjs(new Date(data?.dataInicial)).format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ',
      ),
      dataFinal: dayjs(new Date(data?.dataFinal)).format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ',
      ),
      tipoOperacao: data?.tipoOperacao?.codigo || null,
      tabela: data?.tabela?.codigo || null,
      numeroAssinatura: data?.numeroAssinatura || null,
      usuario: data.usuario?.id || null,
      ...(data?.tipoUsuario?.descricao && {
        tipoUsuario: stringToEnum(data.tipoUsuario.descricao),
      }),
      idPaciente: data?.idPaciente || null,
      chavePrimaria: data?.chavePrimaria || null,
    };

    setFilter(params);
  };

  return (
    <FormProvider {...useFormMethods}>
      <form
        id="search-trilha-auditoria-form"
        className="p-grid p-align-end"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="p-col-6 p-md-3">
          <CalendarInputControlled
            control={control}
            name="dataInicial"
            label="Data Início"
            placeholder="dd/mm/aaaa hh:mm"
            showIcon
            showTime
            hideOnDateTimeSelect={false}
            maxDate={watchDataFinal || new Date()}
            errorMsg={errors.dataInicial?.message}
          />
        </div>
        <div className="p-col-6 p-md-3">
          <CalendarInputControlled
            control={control}
            name="dataFinal"
            label="Data Final"
            placeholder="dd/mm/aaaa hh:mm"
            showIcon
            showTime
            hideOnDateTimeSelect={false}
            maxDate={new Date()}
            minDate={watchDataInicial}
            errorMsg={errors.dataInicial?.message}
          />
        </div>
        <div className="p-col-2 p-xl-1 p-mb-1">
          <Button
            label="Pesquisar"
            type="submit"
            btnType="tonal"
            loading={isSubmitting}
            disabled={!habilitarFilter}
          />
        </div>
        <div className="p-grid p-col p-justify-end p-align-center p-mb-1">
          <Filter
            habilitarFilter={habilitarFilter}
            watchDataFinal={watchDataFinal}
            watchDataInicial={watchDataInicial}
          />
          <div className="p-ml-3">
            <IconButton
              icon="fa fa-ellipsis"
              type="button"
              btnType={IconBtnTypes.GREY}
              onClick={event => menuEllipsis?.current?.toggle(event)}
              disabled={!habilitarExportacao}
            />
            <TieredMenu
              className="panel-options-paciente"
              ref={menuEllipsis}
              model={optionsMenuEllipsis}
              popup
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default SearchForm;
