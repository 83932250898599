import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router';

import EmpresaProvedorSmsAPI from 'src/APIs/EmpresaProvedorSmsAPI/EmpresaProvedorSmsAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tab from 'src/components/Tab/Tab';

import './FormAdditionalData.scss';
import LogoCliente from './LogoCliente/LogoCliente';
import ServicoMensagem from './ServicoMensagem/ServicoMensagem';
interface FormAdditionalDataProps {
  idEmpresa: string | undefined;
  dadosLogo: Partial<Empresa>;
}

interface Step3Props {
  servicoMensagem: EmpresaProvedorSms;
}

const FormAdditionalData = ({
  idEmpresa,
  dadosLogo,
}: FormAdditionalDataProps) => {
  const params = useParams();

  const [step3, setStep3] = useState<Step3Props>({} as Step3Props);
  const [loading, setLoading] = useState(false);

  const loadPage = useCallback(async () => {
    if (params.id) {
      setLoading(true);
      const response: any = await EmpresaProvedorSmsAPI.loadEmpresaProvedorSms(
        params.id,
      );

      if (response.status === 200) {
        const empresaProvedorSmsEncontrada: EmpresaProvedorSms = response?.data;

        const passo3: Step3Props = {
          servicoMensagem: {
            provedor: empresaProvedorSmsEncontrada.provedor || '',
            urlApi: empresaProvedorSmsEncontrada.urlApi || '',
            usuarioApi: empresaProvedorSmsEncontrada.usuarioApi || '',
            senhaApi: empresaProvedorSmsEncontrada.senhaApi || '',
            callbackStatus:
              empresaProvedorSmsEncontrada.callbackStatus || false,
            callbackConfirmacao:
              empresaProvedorSmsEncontrada.callbackConfirmacao || '',
            callbackCancelamento:
              empresaProvedorSmsEncontrada.callbackCancelamento || '',
          },
        };

        setStep3({
          ...passo3,
          ...empresaProvedorSmsEncontrada,
        });
      }
      setLoading(false);
    }
  }, [params.id]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <div className="FormAdditionalData p-grid p-col-12 p-lg-8 p-xl-6">
      <Tab
        clean
        underHeader
        values={[
          {
            label: 'Logo do cliente',
            content: (
              <LogoCliente
                dadosLogo={dadosLogo}
                idEmpresa={parseInt(idEmpresa!)}
              />
            ),
          },
          {
            label: 'Serviço de mensagem',
            content: (
              <>
                {loading ? (
                  <div className="p-m-5">
                    <SpinnerLoading full size="md" />
                  </div>
                ) : (
                  <ServicoMensagem
                    idEmpresa={idEmpresa}
                    values={step3.servicoMensagem}
                  />
                )}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default FormAdditionalData;
