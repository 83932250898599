import { useEffect, useState } from 'react';

import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface SwitchStatusProps {
  idPerfil: number;
  idFuncionalidade: number;
  status: any;
  onChange: any;
}

const SwitchStatus = ({
  idPerfil,
  idFuncionalidade,
  status,
  onChange,
}: SwitchStatusProps) => {
  const [loading, setLoading] = useState(false);

  const handleSwitchStatus = async () => {
    setLoading(true);
    const newStatus = !status;

    const dataEdit = {
      idFuncionalidade,
      status: newStatus,
    };

    const response: any = await PerfisAPI.updatePerfilStatus(
      idPerfil,
      dataEdit,
    );

    if (response.status >= 200 && response.status < 300) {
      onChange(newStatus);
    }
    setLoading(false);
  };

  if (loading)
    return (
      <div className="p-mb-4 p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return <Switch checked={status} showStatus onChange={handleSwitchStatus} />;
};

export default SwitchStatus;
