import { useCallback, useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import ServicoTussAPI from 'src/APIs/FinanceiroAPI/ServicoTuss/ServicoTussAPI';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import './SearchTuss.scss';

type ListTuss = {
  idServicoTuss: number;
  nome: string | undefined;
  codigo: string | number;
  idConvenio: number;
  nomeConvenio: string;
  valor: any;
}[];

interface Props {
  idsConvenio?: any;
  setTuss: any;
  listTuss: ListTuss;
  tussRef: React.RefObject<OverlayPanel>;
}

const SearchTuss = ({ idsConvenio, setTuss, listTuss, tussRef }: Props) => {
  const [reload, setReload] = useState(0);
  const [selectedTuss, setSelectedTuss] = useState<any[]>([]);

  const fetchApi = useCallback(
    async (params?: any) => {
      try {
        const response = await ServicoTussAPI.loadServicoTuss(
          {
            nome: params?.query,
            idsConvenio: idsConvenio ? idsConvenio.toString() : null,
            page: params?.page,
          },
          { throwError: true },
        );

        if (Array.isArray(response.list) && !!response.list.length) {
          return response;
        }
        return [];
      } catch (error) {
        return [];
      }
    },
    [idsConvenio],
  );

  const onCidChange = (e: { value: any; checked: boolean }) => {
    const _selectedTuss = [...selectedTuss];

    if (e.checked) {
      _selectedTuss.push(e.value);
    } else {
      for (let i = 0; i < _selectedTuss.length; i++) {
        const selectedCid = _selectedTuss[i];

        if (selectedCid.id === e.value.id) {
          _selectedTuss.splice(i, 1);
          break;
        }
      }
    }

    setSelectedTuss(_selectedTuss);
  };
  const searchTussElement = document.getElementById('search-tuss');

  const renderRow = (tuss: any): JSX.Element => {
    if (listTuss?.some((item: any) => item.idServicoTuss === tuss?.id))
      return <></>;

    return (
      <div
        id="row-tuss"
        className="p-d-flex p-ai-center p-jc-between p-p-1 p-my-2 "
        style={{ width: `${searchTussElement?.offsetWidth}px` }}
      >
        <Checkbox
          inputId={tuss?.id}
          label={tuss?.nome + ' - ' + tuss?.codigo}
          name="tuss"
          value={tuss}
          onChange={onCidChange}
          checked={selectedTuss?.some((item: any) => item.id === tuss?.id)}
        />
      </div>
    );
  };

  return (
    <div>
      <SearchList.Root fetchApi={fetchApi}>
        <div id="search-tuss">
          <SearchList.SearchInput
            placeholder="Pesquisar serviço"
            className="p-w-100"
            onFocus={e => tussRef.current?.toggle(e)}
            disabled={idsConvenio?.length === 0}
          />
        </div>

        <OverlayPanel
          ref={tussRef}
          className="overlay-tuss"
          style={{
            width: `${searchTussElement?.offsetWidth || 'auto'}px !important`,
          }}
        >
          <SearchList.InfiniteScroll
            customEmptyText="Nenhum serviço encontrado."
            renderRow={renderRow}
            reload={reload}
            autoHeight={false}
          />
          <Button
            btnType="tonal"
            label="Adicionar selecionados"
            className="adicionar-btn"
            onClick={() => {
              setTuss(selectedTuss);
              setSelectedTuss([]);
            }}
            stretch
          />
        </OverlayPanel>
      </SearchList.Root>
    </div>
  );
};

export default SearchTuss;
