import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/guias-execucoes';

const getGuiasExecucoes = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/atendimento/${idAtendimento}`,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });
};

const postGuiaExecucao = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}/${payload.id}`,
    data: payload,
    hideToast: true,
    ...options,
  });
};

const ProcedimentosSeriadosAPI = {
  getGuiasExecucoes,
  postGuiaExecucao,
};

export default ProcedimentosSeriadosAPI;
