import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ServicoTussAPI from 'src/APIs/FinanceiroAPI/ServicoTuss/ServicoTussAPI';

import useDebounce from 'src/utils/useDebounce';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type IProps = DropdownProps & {
  name?: string;
  label: string;
  returnType?: 'all' | 'id';
  params?: any;
  showCodigo?: boolean;
};

export const FieldServicoTuss = ({
  name = 'servicoTuss',
  label,
  returnType = 'all',
  params = {},
  showCodigo = false,
  ...rest
}: IProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [items, setItems] = useState<ServicoTuss[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debouncedSearch = useDebounce(querySearch, 350);

  const loadAPI = useCallback(async (nome: string, params: any) => {
    try {
      if (nome.length < 3) return setItems([]);

      setLoadingSearch(true);

      const response = await ServicoTussAPI.loadServicoTuss(
        {
          nome,
          ...params,
        },
        { throwError: true },
      );

      setItems(response.list || []);
    } catch {
      setItems([]);
    } finally {
      setLoadingSearch(false);
    }
  }, []);

  useEffect(() => {
    loadAPI(debouncedSearch, params);
  }, [debouncedSearch, loadAPI, params]);

  const servicoTussAtual = watch(name);

  const servicoTussOptions = useMemo(() => {
    const options = items.map(item => ({
      label: showCodigo ? `${item.codigo} - ${item.nome}` : item.nome,
      value: returnType === 'all' ? item : item.id,
    }));

    // valida se convênio atual está listado nas options
    if (servicoTussAtual) {
      const isServicoTussAtualVisivel = items.find(
        conv => conv.id === servicoTussAtual.id,
      );

      if (!isServicoTussAtualVisivel) {
        options.unshift({
          label: servicoTussAtual.nome,
          value: returnType === 'all' ? servicoTussAtual : servicoTussAtual.id,
        });
      }
    }

    return options;
  }, [items, servicoTussAtual, showCodigo, returnType]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setQuerySearch(filter);
  };

  const options = servicoTussOptions.length
    ? servicoTussOptions
    : defaultOption;
  const emptyFilterMessage =
    querySearch.length >= 3
      ? 'Nenhuma opção encontrada'
      : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loadingSearch}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options || []}
      errorMsg={errors[name]?.message}
      {...rest}
    />
  );
};
