import { PanelHeaderTemplateOptions } from 'primereact/panel';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import PanelComponent from 'src/components/Panel/Panel';
import './Panel.scss';

export const Panel = (props: any) => {
  const { title, children } = props;

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-right'
      : 'pi pi-chevron-down';

    const className = `p-d-flex p-ai-center p-p-2 ${
      options.collapsed
        ? 'anexar-opme-panel-collapsed'
        : 'anexar-opme-panel-expanded'
    }`;

    return (
      <div className={className}>
        <Button
          className="p-mr-3"
          type="button"
          icon={toggleIcon}
          btnType="gray"
          onClick={options.onTogglerClick}
        />

        <SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
          {title}
        </SimpleText>
      </div>
    );
  };

  return (
    <PanelComponent
      className="anexar-opme-panel"
      headerTemplate={headerTemplate}
      toggleable
      collapsed
    >
      {children}
    </PanelComponent>
  );
};
