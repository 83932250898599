import React from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { bgColors } from '../DiagnosticoFuncionalGlobal';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { generateRandomString } from 'src/utils/utils';
import dayjs from 'dayjs';

interface AvaliacaoGeralProps {
  diagnostico: any;
}

const AvaliacaoGeral = ({ diagnostico }: AvaliacaoGeralProps) => {
  const idade = Array.isArray(diagnostico?.avaliacaoGeral?.idade)
    ? diagnostico?.avaliacaoGeral?.idade
    : [diagnostico?.avaliacaoGeral?.idade];
  const manIdoso = Array.isArray(diagnostico?.avaliacaoGeral?.manIdoso)
    ? diagnostico?.avaliacaoGeral?.manIdoso
    : [diagnostico?.avaliacaoGeral?.manIdoso];
  const fenotipoFried = Array.isArray(
    diagnostico?.avaliacaoGeral?.fenotipoFried,
  )
    ? diagnostico?.avaliacaoGeral?.fenotipoFried
    : [diagnostico?.avaliacaoGeral?.fenotipoFried];
  const comorbidades = Array.isArray(diagnostico?.avaliacaoGeral?.comorbidades)
    ? diagnostico?.avaliacaoGeral?.comorbidades
    : [diagnostico?.avaliacaoGeral?.comorbidades];

  const corIdade = idade[0]?.cor;
  const corManIdoso = manIdoso[0]?.cor;
  const corFenotipoFried = fenotipoFried[0]?.cor;
  const corComorbidades = comorbidades[0]?.cor;

  return (
    <div className="p-d-flex border-dashed-x p-ai-center border-dashed-bottom bg-96">
      <div className="w-210px p-d-flex p-jc-center">
        <SimpleText fontSize="xxs" className="p-p-1">
          AVALIAÇÃO GERAL
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column">
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              IDADE
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corIdade === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corIdade === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              75 – 84 anos - (1)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corIdade === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`>= 85 (2)`}
            </SimpleText>
          </div>

          {idade.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1 p-text-center">
              MAN IDOSO
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corManIdoso === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corManIdoso === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              (17 – 23,5) – (1)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corManIdoso === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`(< 17) - (2)`}
            </SimpleText>
          </div>

          {manIdoso.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              FENÓTIPO DE FRIED
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corFenotipoFried === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corFenotipoFried === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              2 pontos – (1)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corFenotipoFried === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`>= que 3 (2)`}
            </SimpleText>
          </div>

          {fenotipoFried.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              COMORBIDADES
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corComorbidades === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-661px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corComorbidades === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-95 p-text-center">
              {`Polipatologia (>5), Polifarmácia (>5) ou Internação nos 6 meses - (4)`}
            </SimpleText>
          </div>

          {comorbidades.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default AvaliacaoGeral;
