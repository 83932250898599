import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  exameFisico: any;
  isCollapsed: boolean;
  disabledOptionsMenu: boolean;
  onToggle: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

function PanelHeader({
  exameFisico,
  isCollapsed,
  disabledOptionsMenu,
  onToggle,
  onEdit,
  onDelete,
}: PanelHeaderProps) {
  const menuEllipsis = useRef<TieredMenu>(null);

  const dataTextClassName = 'p-mt-2';

  const menuEllipsisItens = [
    {
      label: 'Editar',
      command: onEdit,
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: onDelete,
    },
  ];

  return (
    <div className="pre-natal-panel-header p-grid p-col-12 p-align-center">
      <div className="p-col-12 p-md-10 p-d-flex">
        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            MF
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {capitalizeFirstLetter(exameFisico.mf)}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            AU
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {exameFisico.au}{' '}
            {exameFisico?.auUnidadeMedida?.codigo?.toLowerCase()}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            BCF
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {exameFisico.bcf}{' '}
            {exameFisico?.bcfUnidadeMedida?.codigo?.toLowerCase()}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-ml-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Data da ocorrência
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {dayjs(exameFisico.dataOcorrencia).format('DD/MM/YYYY')}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-12 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />

        <Button
          icon="fas fa-ellipsis-h"
          btnType="gray"
          onClick={event => menuEllipsis?.current?.toggle(event)}
          disabled={disabledOptionsMenu}
        />

        <TieredMenu
          className="panel-options-pre-natal"
          ref={menuEllipsis}
          model={menuEllipsisItens}
          popup
        />
      </div>
    </div>
  );
}

export default PanelHeader;
