import { useCallback, useMemo, useRef, useState } from 'react';

import { Menu } from 'primereact/menu';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { itemsNegadoOperadora } from './utils';

import { Button } from 'src/components/_UI';
import Separator from 'src/components/Separator/Separator';

import { useAgenda } from 'src/pages/Emed/Agenda/AgendaContext';

import Options from '../../Options/Options';

interface AgendamentoActionsProps {
  agendamento: any;
  selectedDate: Date;
  getGrupo?: (() => void) | undefined;
  handleAlterarStatus: any;
  sigilo: any;
  from?: 'GERENCIAR' | undefined;
}

export const AgendamentoActions = ({
  agendamento,
  getGrupo,
  handleAlterarStatus,
  sigilo,
  from,
}: AgendamentoActionsProps) => {
  const { isTablet } = useSize();
  const { tipoUsuario } = useAppSelector(state => state.user);
  const {
    dialogRemarcarAgendamento,
    dialogDetalhesAgendamento,
    dialogGerenciarGrupos,
    refetchAgenda,
  } = useAgenda();

  const navigate = useNavigate();
  const optionsFaltou = useRef<any>();

  const [confirmarIsSubmitting, setConfirmarIsSubmitting] = useState(false);

  const isAgendamentoGrupo = useMemo((): boolean => {
    if (agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo)
      return true;
    return false;
  }, [agendamento.idAtendimentoEmGrupo, agendamento.idGrupoAtendimento]);

  const hasEvolucaoIndidual =
    agendamento.idGrupoAtendimento && from === 'GERENCIAR';

  const isFlagAtiva = (flag: string): boolean => {
    if (agendamento.flagsAtivas && agendamento.flagsAtivas.includes(flag))
      return true;
    return false;
  };

  const handleEditAgendamento = useCallback(
    async ({ removeFlag, addFlag, status }: any) => {
      try {
        setConfirmarIsSubmitting(true);

        let flags: string[] = [];

        agendamento.flagsAtivas && (flags = [...agendamento?.flagsAtivas]);

        if (removeFlag?.length)
          flags = flags.filter(flag => flag !== removeFlag);
        if (addFlag?.length) flags.push(addFlag);

        const payload: any = {
          id: agendamento.id,
          status: status || agendamento.status,
          flagsAtendimentos: flags,
          observacao: agendamento.observacao,
          informacoesProfissional: agendamento.informacoesProfissional,
          informacoesPaciente: agendamento.informacoesPaciente,
        };

        await AgendamentosAPI.editarAgendamento(payload);
        refetchAgenda();

        if (getGrupo) {
          getGrupo();
        }

        setConfirmarIsSubmitting(false);
      } catch {}
    },
    [
      agendamento.flagsAtivas,
      agendamento.id,
      agendamento.informacoesPaciente,
      agendamento.informacoesProfissional,
      agendamento.observacao,
      agendamento.status,
      getGrupo,
      refetchAgenda,
    ],
  );

  const handleAtualizaGuiaOperadora = useCallback(async () => {
    try {
      await AgendamentosAPI.updateAgendamentosStatusGuia(
        { idAgendamento: agendamento?.id },
        { idConvenio: agendamento?.idConvenio, status: 'PRESENTE' },
        { throwError: true },
      );
      refetchAgenda();
      if (getGrupo) {
        getGrupo();
      }
    } catch {}
  }, [agendamento?.id, agendamento?.idConvenio, getGrupo, refetchAgenda]);

  const handleDesbloquearHorario = useCallback(async () => {
    try {
      const payload = {
        ...agendamento,
        status: 'DESBLOQUEADO',
      };
      const aux = await AgendamentosAPI.bloqueadoToLivre(payload);
      if (aux.status > 199 && aux.status < 300) {
        refetchAgenda();
        if (getGrupo) {
          getGrupo();
        }
      }
    } catch {}
  }, [agendamento, getGrupo, refetchAgenda]);

  const handleSetParticularAndPresente = useCallback(async () => {
    setConfirmarIsSubmitting(true);

    const flags: string[] = ['PARTICULAR'].concat(
      agendamento?.flagsAtivas || [],
    );

    const payload = {
      idEmpresa: agendamento.idEmpresa,
      idSala: agendamento.idSala,
      idEspecialidade: agendamento.idEspecialidade,
      status: 'PRESENTE',
      flagsAtendimentos: flags,
    };

    const response = await AutorizacaoAPI.realizarPresenca(
      agendamento.id,
      payload,
      { return: true },
    );

    if (response.status === 200) {
      refetchAgenda();
      if (getGrupo) {
        getGrupo();
      }
    }

    setConfirmarIsSubmitting(false);
  }, [
    agendamento?.flagsAtivas,
    agendamento.id,
    agendamento.idEmpresa,
    agendamento.idEspecialidade,
    agendamento.idSala,
    getGrupo,
    refetchAgenda,
  ]);

  const items = [
    {
      label: 'Desfazer falta/cancelamento',
      command: () => handleAlterarStatus(agendamento, 'AGENDADO'),
    },
  ];

  if (!getGrupo) {
    Array.prototype.push.apply(items, [
      {
        separator: true,
      },
      {
        label: 'Liberar horário',
        command: () => handleAlterarStatus(agendamento, 'LIVRE'),
      },
      {
        separator: true,
      },
      {
        label: 'Remarcar consulta',
        command: () => dialogRemarcarAgendamento.open({ state: agendamento }),
      },
    ]);
  }

  const renderInfoIcon = useMemo(() => {
    return (
      !agendamento.idAtendimentoEmGrupo &&
      !isTablet && (
        <>
          <Separator spaceOnly layout={'vertical'} />
          <Button
            btnType="green-link"
            label="Ver mais"
            onClick={() =>
              dialogDetalhesAgendamento.open({ state: agendamento })
            }
          />
        </>
      )
    );
  }, [agendamento, dialogDetalhesAgendamento, isTablet]);

  switch (agendamento?.status || '') {
    case 'ATENDIDO':
      return (
        <>
          {tipoUsuario !== 'SECRETARIO' && !isAgendamentoGrupo && (
            <Button
              className="p-mr-3"
              icon="fas fa-eye"
              onClick={() =>
                navigate(`/prontuario`, {
                  state: {
                    idAtendimento: agendamento.id,
                  },
                })
              }
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'ATENDENDO':
      return <>{renderInfoIcon}</>;
    case 'AGENDADO':
      return (
        <>
          {!isFlagAtiva('CONFIRMADO') && !isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              loading={confirmarIsSubmitting}
            />
          )}

          {isAgendamentoGrupo && (
            <Button
              icon="pi pi-pencil"
              btnType="outline"
              label="Gerenciar"
              onClick={() => dialogGerenciarGrupos.open({ state: agendamento })}
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'TRIAGEM':
      return (
        <>
          {isFlagAtiva('CONFIRMADO') && isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              loading={confirmarIsSubmitting}
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'TRIAGEM_FINALIZADA':
      return (
        <>
          {isFlagAtiva('CONFIRMADO') && isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              style={{
                minWidth: '160px',
              }}
              loading={confirmarIsSubmitting}
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'PRESCRICAO':
      return (
        <>
          {isFlagAtiva('CONFIRMADO') && isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              loading={confirmarIsSubmitting}
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'PRESCRICAO_EXECUCAO':
      return (
        <>
          {isFlagAtiva('CONFIRMADO') && isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              loading={confirmarIsSubmitting}
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'PRESCRICAO_FINALIZADA':
      return (
        <>
          {isFlagAtiva('CONFIRMADO') && isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              loading={confirmarIsSubmitting}
            />
          )}

          {renderInfoIcon}
        </>
      );
    case 'FALTOU':
      return (
        <>
          <Menu model={items} popup ref={optionsFaltou} />

          <Button
            icon="fas fa-ellipsis-v"
            btnType="gray"
            onClick={event => optionsFaltou.current.toggle(event)}
          />

          {renderInfoIcon}
        </>
      );
    case 'CANCELADO':
      return (
        <>
          <Menu model={items} popup ref={optionsFaltou} />

          <Button
            icon="fas fa-ellipsis-v"
            btnType="gray"
            onClick={event => optionsFaltou.current.toggle(event)}
          />

          {renderInfoIcon}
        </>
      );
    case 'LIVRE':
      return <Options agendamento={agendamento} />;
    case 'BLOQUEADO':
      if (!agendamento.idBloqueioAgenda) return <></>;
      return (
        <Button
          btnType="green-link"
          label="Desbloquear"
          onClick={handleDesbloquearHorario}
        />
      );

    case 'PRESENTE':
      return (
        <>
          <Separator layout={'vertical'} />
          {(!agendamento.idGrupoAtendimento ||
            (agendamento.idGrupoAtendimento &&
              agendamento.idAtendimentoEmGrupo)) &&
            sigilo}

          {renderInfoIcon}

          {hasEvolucaoIndidual && (
            <Button
              btnType="tonal"
              label="Evoluir individual"
              onClick={() =>
                navigate(`/prontuario`, {
                  state: {
                    idAtendimento: agendamento.id,
                    isEvolucaoIndividual: true,
                  },
                })
              }
              className="p-ml-2"
            />
          )}
        </>
      );

    case 'NEGADO_OPERADORA':
      return (
        <>
          <TieredMenu
            popup
            ref={optionsFaltou}
            model={itemsNegadoOperadora({
              agendamento,
              handleAtualizaOperadora: handleAtualizaGuiaOperadora,
              handleAlterarStatus: () =>
                handleAlterarStatus(agendamento, 'CANCELADO'),
              handleRemarcar: () =>
                dialogRemarcarAgendamento.open({
                  state: agendamento,
                }),
              handleRemoveFlag: (flag: string, status: string) =>
                handleEditAgendamento({ removeFlag: flag, status }),
              handleAddFlag: (flag: string, status: string) =>
                handleEditAgendamento({ addFlag: flag, status }),
              handleChangeParticular: handleSetParticularAndPresente,
            })}
          />

          <Button
            icon="fas fa-ellipsis-v"
            btnType="gray"
            onClick={event => optionsFaltou.current.toggle(event)}
          />

          {renderInfoIcon}
        </>
      );
    case 'CUIDADO_PROGRAMADO':
      return (
        <>
          {!isFlagAtiva('CONFIRMADO') && !isAgendamentoGrupo && (
            <Button
              icon="fas fa-check"
              btnType="outline"
              label="Confirmar"
              onClick={() =>
                handleEditAgendamento({
                  addFlag: 'CONFIRMADO',
                })
              }
              loading={confirmarIsSubmitting}
            />
          )}

          {isAgendamentoGrupo && (
            <Button
              icon="pi pi-pencil"
              btnType="outline"
              label="Gerenciar"
              onClick={() => dialogGerenciarGrupos.open({ state: agendamento })}
            />
          )}

          {renderInfoIcon}
        </>
      );

    default:
      return <></>;
  }
};
