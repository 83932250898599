import { useMemo } from 'react';

import { MenuItem } from 'primereact/menuitem';

import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

const situacoes: any = {
  ATIVO: 'Ativo',
  INATIVO: 'Inativo',
  RESOLVIDO: 'Resolvido',
};

const labelType: Record<string, SPLIT_BTN_TYPE> = {
  ATIVO: SPLIT_BTN_TYPE.YELLOW,
  RESOLVIDO: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
  INATIVO: SPLIT_BTN_TYPE.GREEN,
};

interface PanelHeaderProps {
  problema: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  problema,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName =
    problema.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    problema.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  const splitBtnType = labelType[problema.situacao] as SPLIT_BTN_TYPE;

  const splitButtonItems = () => {
    const items: MenuItem[] = [];

    for (const situacao in situacoes) {
      if (situacao !== problema.situacao) {
        items.push({
          label: situacoes[situacao],
        });
      }
    }

    return items;
  };

  const comorbidadeNome = useMemo(() => {
    if (problema?.ciap?.[0]?.ciap)
      return `${problema?.ciap?.[0]?.ciap?.codigo} - ${problema?.ciap?.[0]?.ciap?.nome}`;

    return `${problema?.cid?.[0]?.cid?.codigo} - ${problema?.cid?.[0]?.cid?.nome}`;
  }, [problema.ciap, problema?.cid]);

  return (
    <div className="panel-header-comorbidades p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-12 p-md-4 p-xl-5 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          {problema?.cid?.length ? 'CID' : 'CIAP'}
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {comorbidadeNome}
        </SimpleText>
      </div>
      <div className="coluna-acoes p-col-6 p-md-2 p-d-flex p-flex-column p-ai-end">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(problema.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-6 p-md-2 p-xl-1 retroativo-chip">
        {problema.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="coluna-dados p-col-6 p-md-2 p-xl-2 p-d-flex p-flex-column">
        <SplitButton
          className="p-mt-2"
          menuClassName="menu-comorbidade-splitbutton"
          btnType={splitBtnType}
          label={situacoes[problema.situacao]}
          model={splitButtonItems()}
          disabled
        />
      </div>
      <div className="coluna-acoes p-col-6 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Lembrete ativo={problema.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
