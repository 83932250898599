import React, { useCallback, useRef, useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

import Header from './Header';
import RespostaItem from './RespostaItem';

interface RespostasProps {
  tipoCuidado: any;
}

const Respostas = ({ tipoCuidado }: RespostasProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [edit, setEdit] = useState<any>(null);

  const { isMobile } = useSize();

  const {
    control,
    formState: { errors },
    watch,
    reset,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useFormContext();

  const watchDescricaoResposta = watch('descricaoResposta');

  const inputRef = useRef<any>(null);

  const {
    fields: respostaFields,
    append: appendRespostaFields,
    remove: removeRespostaFields,
    update: updateRespostaFields,
  } = useFieldArray({
    control,
    name: 'respostas',
  });

  const checkDescricaoIsDuplicate = useCallback(() => {
    const trimmedDescricao = watchDescricaoResposta?.trim();
    return (
      respostaFields?.some?.(
        (resposta: any) =>
          resposta.descricao.trim().toLowerCase() ===
          trimmedDescricao.toLowerCase(),
      ) || false
    );
  }, [respostaFields, watchDescricaoResposta]);

  const handleAddTipoPlano = () => {
    const isDuplicate = checkDescricaoIsDuplicate();

    if (isDuplicate) {
      const error = {
        type: 'custom',
        message: 'Já existe uma resposta cadastrada com esta descrição.',
      };

      return setError('descricaoResposta', error);
    }

    appendRespostaFields({
      descricao: watchDescricaoResposta.trim(),
      status: true,
      isNew: true,
    });

    setValue('descricaoResposta', '');
    clearErrors('descricaoResposta');
  };

  const handleRemove = (idx: number) => {
    removeRespostaFields(idx);
    reset(getValues());
  };

  const handleUpdateRespostaFields = (data: any) => {
    if (edit === null) {
      return;
    }
    const isDuplicate = checkDescricaoIsDuplicate();
    if (isDuplicate) {
      const error = {
        type: 'custom',
        message: 'Já existe uma resposta cadastrada com esta descrição.',
      };

      return setError('descricaoResposta', error);
    }
    updateRespostaFields(edit.index, {
      descricao: data,
      status: true,
      id: edit.id,
    });
    setValue('descricaoResposta', '');
    setIsUpdating(false);
    setEdit(null);
  };

  const handleUpdate = (resposta: any, index: any) => {
    setIsUpdating(true);
    setValue('descricaoResposta', resposta.descricao);
    setEdit({ index: index, ...(resposta.id && { id: resposta.id }) });
    inputRef.current?.focus();
  };

  const handleCancelUpdate = () => {
    setValue('descricaoResposta', '');
    setIsUpdating(false);
  };

  return (
    <div className="p-grid">
      <SimpleText className="p-my-2" medium>
        Resposta
      </SimpleText>
      <FormInput
        inputRef={inputRef}
        className="p-col-12 p-px-0 p-mb-1"
        name="descricaoResposta"
        placeholder="Digite a descrição"
        maxLength={200}
        hideTextErrorSpace
        errorMsg={errors.descricaoResposta?.message}
      />
      <Button
        label={isUpdating ? 'Editar a resposta' : 'Adicionar a resposta'}
        onClick={() =>
          isUpdating
            ? handleUpdateRespostaFields(getValues('descricaoResposta'))
            : handleAddTipoPlano()
        }
        icon="pi pi-plus"
        iconPos="left"
        disabled={!watchDescricaoResposta}
        stretch
      />
      {isUpdating && (
        <Button
          className="p-my-1"
          label={`Cancelar edição`}
          onClick={() => handleCancelUpdate()}
          icon="pi pi-times"
          iconPos="left"
          btnType="light-danger"
          disabled={!watchDescricaoResposta}
          stretch
        />
      )}

      <div className="p-col-12">
        <div
          className="border-container"
          style={{ overflowY: 'auto', maxHeight: '200px' }}
        >
          {!isMobile && <Header />}
          <div className="lista-respostas">
            {!respostaFields.length && (
              <Card className="p-mb-2 p-text-center" type={CARD_TYPES.GRAY}>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  Não há respostas cadastradas.
                </SimpleText>
              </Card>
            )}

            {respostaFields.map((resposta: any, index) => {
              resposta.id = getValues(`respostas[${index}].id`) || resposta.id;
              return (
                <RespostaItem
                  key={getValues(`respostas[${index}].id`) || resposta.id}
                  index={index}
                  handleRemove={handleRemove}
                  resposta={resposta}
                  updateResposta={handleUpdate}
                  updateRespostaFields={updateRespostaFields}
                  tipoCuidado={tipoCuidado}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Respostas;
