import Http, { HttpParams } from 'src/core/http/Http';

const basePath = 'documentos-assinados';

const getDocumentosAssinados = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/${basePath}`,
    params,
    hideToast: true,
    ...options,
  });
};

const getRelatorioDocumentosAssinados = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'report',
    url: `relatorios-pep/${basePath}`,
    responseType: 'blob',
    params,
    hideToast: true,
    ...options,
  });
};

const DocumentosAssinadosAPI = {
  getDocumentosAssinados,
  getRelatorioDocumentosAssinados,
};

export default DocumentosAssinadosAPI;
