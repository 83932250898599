import { memo, useCallback, useMemo, useState } from 'react';

import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ProfessionalsSelected from './ProfessionalsSelected';
import SearchProfessionals from './SearchProfessionals/SearchProfessionals';

import './FormProfessionals.scss';

interface FormProfessionalsProps {
  onSubmit(v: GetProfissionalDTO[], keepStep?: boolean): void;
  values: GetProfissionalDTO[] | any[];
  companyProfessionalsList: GetProfissionalDTO[] | [];
}

const FormProfessionals = ({
  onSubmit,
  values,
  companyProfessionalsList,
}: FormProfessionalsProps) => {
  const [modal, setModal] = useState(false);

  const handleChangeItems = useCallback(
    (items, keepStep = false) => {
      setModal(false);

      onSubmit(items, keepStep);
    },
    [onSubmit],
  );

  // Ordena os valores por
  const valueOrdered = useMemo(
    () => values?.sort((a, b) => (a.nome < b.nome ? -1 : 1)),
    [values],
  );

  return (
    <div className={'FormProfessionals'}>
      <SimpleText fontSize={FONT_SIZE.XS} bold>
        Equipe da clínica
      </SimpleText>

      <div className="form-container">
        <div>
          <ProfessionalsSelected
            value={valueOrdered}
            onChange={handleChangeItems}
          />

          <Button
            label={values?.length > 0 ? 'Adicionar' : 'Adicionar profissionais'}
            btnType="tonal"
            className="button-submit"
            icon="fas fa-plus"
            onClick={() => setModal(true)}
          />
        </div>
      </div>

      <SearchProfessionals
        visible={modal}
        onHide={() => setModal(false)}
        onSelect={handleChangeItems}
        initialValue={valueOrdered}
        companyProfessionalsList={companyProfessionalsList}
      />
    </div>
  );
};

export default memo(FormProfessionals);
