import React from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { bgColors } from '../DiagnosticoFuncionalGlobal';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { generateRandomString } from 'src/utils/utils';
import dayjs from 'dayjs';

interface MobilidadeProps {
  diagnostico: any;
}

const Mobilidade = ({ diagnostico }: MobilidadeProps) => {
  const TUG = Array.isArray(diagnostico?.mobilidade?.TUG)
    ? diagnostico?.mobilidade?.TUG
    : [diagnostico?.mobilidade?.TUG];
  const velocidadeMarcha = Array.isArray(
    diagnostico?.mobilidade?.velocidadeMarcha,
  )
    ? diagnostico?.mobilidade?.velocidadeMarcha
    : [diagnostico?.mobilidade?.velocidadeMarcha];
  const dinamometro = Array.isArray(diagnostico?.mobilidade?.dinamometro)
    ? diagnostico?.mobilidade?.dinamometro
    : [diagnostico?.mobilidade?.dinamometro];
  const alcanceFuncional = Array.isArray(
    diagnostico?.mobilidade?.alcanceFuncional,
  )
    ? diagnostico?.mobilidade?.alcanceFuncional
    : [diagnostico?.mobilidade?.alcanceFuncional];
  const preensaoPinca = Array.isArray(diagnostico?.mobilidade?.preensaoPinca)
    ? diagnostico?.mobilidade?.preensaoPinca
    : [diagnostico?.mobilidade?.preensaoPinca];
  const continenciaUrinaria = Array.isArray(
    diagnostico?.mobilidade?.continenciaUrinaria,
  )
    ? diagnostico?.mobilidade?.continenciaUrinaria
    : [diagnostico?.mobilidade?.continenciaUrinaria];
  const continenciaFecal = Array.isArray(
    diagnostico?.mobilidade?.cotinenciaFecal,
  )
    ? diagnostico?.mobilidade?.cotinenciaFecal
    : [diagnostico?.mobilidade?.cotinenciaFecal];

  const corTUG = TUG[0]?.cor;
  const corVelocidadeMarcha = velocidadeMarcha[0]?.cor;
  const corDinamometro = dinamometro[0]?.cor;
  const corAlcanceFuncional = alcanceFuncional[0]?.cor;
  const corPreensaoPinca = preensaoPinca[0]?.cor;
  const corContinenciaUrinaria = continenciaUrinaria[0]?.cor;
  const corContinenciaFecal = continenciaFecal[0]?.cor;
  return (
    <div className="p-d-flex border-dashed-x p-ai-center border-dashed-bottom bg-96">
      <div className="w-210px p-d-flex p-jc-center">
        <SimpleText fontSize="xxs" className="p-p-1">
          MOBILIDADE
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column">
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              TUG – Timed Up and Go
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corTUG === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corTUG === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Médio risco de queda (13 a 19 seg) (1){' '}
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corTUG === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`Alto risco de queda (20 ou >) (2)`}{' '}
            </SimpleText>
          </div>

          {TUG.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1 p-text-center">
              VELOCIDADE DA MARCHA{' '}
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corVelocidadeMarcha === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corVelocidadeMarcha === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Médio risco de disfunção (0,9 a 1,2 m/seg) (1){' '}
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corVelocidadeMarcha === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`Alto risco de disfunção (= ou < 0,8) (2)`}
            </SimpleText>
          </div>

          {velocidadeMarcha.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              DINAMÔMETRO
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corDinamometro === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corDinamometro === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Médio Risco para sarcopenia (1){' '}
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corDinamometro === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Alto Risco para sarcopenia (2)
            </SimpleText>
          </div>

          {dinamometro.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              ALCANCE FUNCIONAL
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corAlcanceFuncional === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corAlcanceFuncional === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Médio risco (17 a 30 cm) (0)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corAlcanceFuncional === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`Alto risco de limitação (= ou < 17 cm) (1)`}
            </SimpleText>
          </div>

          {alcanceFuncional.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              PREENSÃO E PINÇA
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corPreensaoPinca === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corPreensaoPinca === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Médio Risco (0)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corPreensaoPinca === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`Alto Risco de limitação (1)`}
            </SimpleText>
          </div>

          {preensaoPinca.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              CONTINÊNCIA URINÁRIA
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corContinenciaUrinaria === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corContinenciaUrinaria === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Transitória ou leve (0)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corContinenciaUrinaria === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Permanente ou grave (1)
            </SimpleText>
          </div>

          {continenciaUrinaria.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              CONTINÊNCIA FECAL
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corContinenciaFecal === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corContinenciaFecal === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Transitória ou leve (0)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corContinenciaFecal === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Permanente ou grave (1)
            </SimpleText>
          </div>

          {continenciaFecal.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default Mobilidade;
