import { useCallback, useState } from 'react';

import { MigraPEPAPI } from 'src/APIs/AdminAPI/MigraPEPAPI/MigraPEPAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Header, MobileRow } from './List';
import { ModalNovaMigracao } from './ModalNovaMigracao/ModalNovaMigracao';

import './CentralMigraPEP.scss';

export default function CentralMigraPEP() {
  const { isMobile } = useSize();

  const [reload, setReload] = useState<number>(0);

  const modalNovaMigracaoDisclosure = useDisclosure({ opened: false });

  const fetchApi = useCallback(async (params: any): Promise<any> => {
    const query = {
      chave: params.query,
      page: params.page,
      sortBy: 'dataOrigem',
      sortDirection: 'DESC',
    };

    const response = await MigraPEPAPI.getMigraPEP(query);
    if (response.status >= 200 && response.status < 300) {
      return response?.data;
    }
    return response;
  }, []);

  const onReverter = async (migracao: any) => {
    const response = await MigraPEPAPI.reverterMigraPEP(migracao.id);
    if (response.status >= 200 && response.status < 300) {
      setReload(reload + 1);
    }
  };

  const renderRow = (data: any): JSX.Element => {
    const newData = {
      id: data?.id,
      dataOcorrencia: data?.dataOrigem,
      idOrigem: data?.idOrigem,
      idDestino: data?.idDestino,
      nomePacienteOrigem: data?.nomePacienteOrigem,
      nomePacienteDestino: data?.nomePacienteDestino,
      login: data?.login,
      status: data?.acao,
      fotoUrlPacienteOrigem: data?.fotoUrlPacienteOrigem,
      fotoUrlPacienteDestino: data?.fotoUrlPacienteDestino,
    };

    const commonProps = {
      migracao: newData,
      onReverter: onReverter,
    };

    if (isMobile) return <MobileRow {...commonProps} />;

    return (
      <SearchList.CardItem>
        <DesktopRow {...commonProps} />
      </SearchList.CardItem>
    );
  };

  return (
    <Page white id="central-pacientes">
      <>
        <PageHeader
          title={'Migração de prontuários'}
          subtitle={
            'Tenha uma visão detalhada dos prontuarios que foram migrados.'
          }
        />

        <Page id="central-migracao-container" white content>
          <>
            <SearchList.Root
              fetchApi={fetchApi}
              reloadWhenEmpresaChange
              empresaRequired
              paginatedList={false}
            >
              <SearchList.SearchInput
                label="Pesquisar pacientes migrados"
                placeholder="Digite nome, nome da mãe, CPF, carteirinha ou ID do paciente"
              />
              <SearchList.BorderContainer>
                <div className="p-d-flex p-col-12 p-md-6 p-lg-3 p-flex-column p-flex-md-row p-jc-between p-ai-center">
                  <Button
                    type="button"
                    btnType="pill"
                    label="Nova migração"
                    icon="fas fa-user-plus"
                    stretch
                    onClick={() => modalNovaMigracaoDisclosure.open()}
                    checkPermission="ADM_MIGRA_PEP"
                  />
                </div>

                <SearchList.Header>
                  <Header />
                </SearchList.Header>
                <SearchList.NonPageableScrollArea
                  renderRow={renderRow}
                  reload={reload}
                />
              </SearchList.BorderContainer>
            </SearchList.Root>
            {modalNovaMigracaoDisclosure.isOpen && (
              <ModalNovaMigracao
                {...modalNovaMigracaoDisclosure}
                initial="ORIGEM"
                setReload={setReload}
              />
            )}
          </>
        </Page>
      </>
    </Page>
  );
}
