import { useCallback, useEffect, useState, useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ServicoTussOPMEAPI from 'src/APIs/AdminAPI/ServicoTussOPMEAPI/ServicoTussOPMEAPI';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './FormServicoTussStep4.scss';

export const FormServicoTussStep4 = ({ servicoTussId, goBack }: any) => {
  const menuEllipsis = useRef<TieredMenu>(null);

  const { id: idServicoTuss } = useParams();
  const [values, setValues] = useState<any>([]);
  const [editValue, setEditValue] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { isMobile } = useSize();

  const modalDelete = useDisclosure({ opened: false });

  const fetch = useCallback(() => {
    setLoading(true);
    ServicoTussOPMEAPI.loadServicoTussFornecedor(
      Number(idServicoTuss || servicoTussId),
      { throwError: true },
    )
      .then(data => setValues(data))
      .catch(() => goBack())
      .finally(() => setLoading(false));
  }, [goBack, idServicoTuss, servicoTussId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const validationSchema = Yup.object({
    codigo: Yup.string().required('Campo obrigatório'),
    nome: Yup.string().required('Campo obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (data: any) => {
    const response = await ServicoTussOPMEAPI.cadastrarServicoTussFornecedor({
      idServicoTuss: Number(idServicoTuss || servicoTussId),
      ...data,
    });

    if (response?.status === 200) {
      fetch();
      form.reset();
    }
  };

  const handleDelete = async (id: number) => {
    const response = await ServicoTussOPMEAPI.deletarServicoTussEndpoint({
      idServicoTuss: idServicoTuss || servicoTussId,
      id,
    });

    if (response?.status === 202) {
      fetch();
    }
  };

  const onEdit = (item: any) => {
    form.setValue('codigo', item.codigo);
    form.setValue('nome', item.nome);

    setEditValue(item);
  };

  const handleEdit = async (data: any) => {
    const response = await ServicoTussOPMEAPI.alterarServicoTussEndpoint({
      ...editValue,
      ...data,
    });

    if (response?.status === 200) {
      fetch();

      form.reset();
      setEditValue({});
    }
  };

  const handleAtivarInativar = useCallback(
    (item: any) => {
      if (item.status) {
        ServicoTussOPMEAPI.inativarServicoTussEndpoint({
          idServicoTuss: idServicoTuss || servicoTussId,
          id: item.id,
        }).then(() => fetch());
      } else {
        ServicoTussOPMEAPI.ativarServicoTussEndpoint({
          idServicoTuss: idServicoTuss || servicoTussId,
          id: item.id,
        }).then(() => fetch());
      }
    },
    [fetch, idServicoTuss, servicoTussId],
  );

  const renderActions = (item: any) => {
    const options: MenuItem[] = [
      {
        label: item.status ? 'Inativar' : 'Ativar',
        command: () => handleAtivarInativar(item),
      },
      {
        label: 'Editar',
        command: () => onEdit(item),
      },
      {
        label: 'Excluir',
        command: () => modalDelete.open(),
        className: 'warning-color',
      },
    ];

    if (isMobile) {
      return (
        <div className="p-col-2 p-d-flex p-jc-end">
          <Button
            icon="fas fa-ellipsis-h"
            btnType="gray"
            onClick={event => menuEllipsis?.current?.toggle(event)}
          />

          <TieredMenu
            className="panel-options-paciente"
            ref={menuEllipsis}
            model={options}
            popup
          />
        </div>
      );
    }

    return (
      <div className="p-col-4 p-d-flex p-gap-2 p-jc-between p-ai-center">
        <Switch
          name="inativo"
          checked={!!item.status}
          onChange={() => handleAtivarInativar(item)}
        />
        <div className="p-d-flex p-gap-2 p-jc-between">
          <Button
            icon="fas fa-edit"
            btnType="gray"
            onClick={() => onEdit(item)}
          />
          <Button
            icon="fas fa-trash"
            btnType="gray"
            onClick={() => modalDelete.open()}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="step4-servicotuss">
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(
            !Object.keys(editValue).length ? handleSubmit : handleEdit,
          )}
          className="p-d-flex p-flex-column"
        >
          <TextInputControlled
            name="codigo"
            className="grid p-col-12 p-sm-4"
            label="Código Anvisa"
            permitirApenasNumeros
            control={form.control}
            errorMsg={form.formState.errors?.codigo?.message}
            maxLength={10}
          />
          <div className="p-ai-center p-flex-column p-flex-sm-row p-d-flex">
            <TextInputControlled
              name="nome"
              className="grid p-col-12 p-sm-6"
              label="Nome do fabricante"
              control={form.control}
              errorMsg={form.formState.errors?.nome?.message}
              maxLength={150}
            />
            <Button
              type="submit"
              btnType="tonal"
              label={
                !Object.keys(editValue).length ? 'Adicionar na lista' : 'Editar'
              }
              className={`p-mt-${
                form.formState.errors?.nome?.message ? `2` : `4`
              } p-ml-2`}
            />
          </div>
        </form>

        <div className="step4-content">
          <div className="p-col-12 p-d-flex p-flex-row p-grid">
            <div className="p-col-2">
              <SimpleText fontSize={FONT_SIZE.XXS} className="label">
                Código
              </SimpleText>
            </div>
            <div className="p-col-8 p-sm-6">
              <SimpleText fontSize={FONT_SIZE.XXS} className="label">
                Fabricante
              </SimpleText>
            </div>
            <div className="p-col-2 p-sm-4">
              <SimpleText fontSize={FONT_SIZE.XXS} className="label">
                Status
              </SimpleText>
            </div>
          </div>
          <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
            {!!values?.length ? (
              values.map((item: any) => (
                <div
                  key={item.id}
                  className="p-col-12 p-grid content-item-step-4"
                >
                  <div className="p-col-2 p-py-0 p-text-truncate">
                    <Tooltip
                      target={`.opme-codigo-${item.codigo}`}
                      autoHide={false}
                    />
                    <SimpleText
                      className={`opme-codigo-${item.codigo}`}
                      data-pr-tooltip={item.codigo}
                      data-pr-position="top"
                    >
                      {item.codigo}
                    </SimpleText>
                  </div>
                  <div className="p-col-8 p-sm-6">
                    <SimpleText>{item.nome}</SimpleText>
                  </div>

                  {renderActions(item)}

                  {modalDelete.isOpen && (
                    <ModalDelete
                      onConfirm={() => handleDelete(item.id)}
                      {...modalDelete}
                    />
                  )}
                </div>
              ))
            ) : loading ? (
              <LoadingComp />
            ) : (
              <div className="content-item">
                <div className="p-d-flex p-jc-center p-ai-center p-w-100">
                  <SimpleText className="p-p-4">
                    Nenhum fabricante adicionado.
                  </SimpleText>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="step4-footer">
          <Button label="Salvar" stretch onClick={() => navigate(-1)} />
        </div>
      </FormProvider>
    </div>
  );
};

type ModalDeleteProps = DisclosureType & {
  onConfirm: () => void;
};

const ModalDelete = ({ isOpen, close, onConfirm }: ModalDeleteProps) => {
  return (
    <Dialog
      style={{ width: '480px' }}
      onHide={close}
      visible={isOpen}
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <div>
          <SimpleText>Tem certeza que deseja remover?</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            stretch
            btnType="ghost"
            onClick={() => [close()]}
          />
          <Button
            label="Confirmar"
            stretch
            btnType="danger"
            onClick={() => [onConfirm(), close()]}
          />
        </div>
      </div>
    </Dialog>
  );
};

const LoadingComp = () => {
  return (
    <>
      <Skeleton loading height="50px" />
      <Skeleton loading height="50px" />
      <Skeleton loading height="50px" />
    </>
  );
};
