import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DialogDeletarPergunta } from '../_components/DialogDeletarPergunta/DialogDeletarPergunta';
import { DialogNovaPergunta } from '../_components/DialogNovaPergunta/DialogNovaPergunta';
import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { FormularioDinamicoAcervoPerguntasFilter } from './FormularioDinamicoAcervoPerguntasFilter';
import { FormularioDinamicoAcervoPerguntasRow } from './FormularioDinamicoAcervoPerguntasRow';

export const FormularioDinamicoAcervoPerguntas = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <>
        <PageHeader
          title="Acervo de perguntas"
          subtitle="A listagem das perguntas que foram criadas e salvas"
          hasBreadcrumbs
          backButton
          onClickBackButton={() => navigate('/cadastros/formulario-dinamico')}
        />

        <Page className="p-pt-0" content white>
          <FormularioDinamicoAcervoPerguntasContent />
        </Page>
      </>
    </Page>
  );
};

const FormularioDinamicoAcervoPerguntasContent = () => {
  const [reload, setReload] = useState(0);
  const dialogDelete = useDisclosure({ opened: false });
  const dialogNovaPergunta = useDisclosure({ opened: false });

  const fetchApi = ({ query, tipoResposta, ...props }: any) => {
    return PerguntaFormularioDinamicoAPI.loadPerguntasFormulario({
      enunciado: query,
      tipoResposta: tipoResposta,
      acervoPerguntas: true,
      ...props,
    });
  };

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  return (
    <SearchList.Root fetchApi={fetchApi} empresaRequired>
      <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-gap-2">
        <SearchList.SearchInput
          label="Pesquisar por nome"
          placeholder="Digite uma palavra chave "
          className="p-col-12 p-md-6 p-lg-3"
        />

        <Button
          label="Pesquisa avançada"
          btnType="tonal"
          icon="fas fa-chevron-down"
          className="p-col-12 p-md-4 p-lg-3 p-xl-2"
          iconPos="right"
          overlay={ref => (
            <FormularioDinamicoAcervoPerguntasFilter
              close={() => ref?.hide()}
            />
          )}
        />

        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-mb-4">
            <Button
              label="Nova pergunta"
              icon="fas fa-plus"
              className="p-col-12 p-md-6 p-lg-3"
              btnType="pill"
              onClick={() => dialogNovaPergunta.open()}
              checkPermission={
                'ADM_MANTER_PERGUNTA_FORMULARIO_DINAMICO_CADASTRAR'
              }
            />
            <SearchList.TotalCount className="p-col-12 p-md-6 p-lg-3 p-text-right" />
          </div>

          <SearchList.InfiniteScroll
            reload={reload}
            renderRow={(item: IPerguntaFormularioItem) => (
              <FormularioDinamicoAcervoPerguntasRow
                item={item}
                onDelete={() =>
                  dialogDelete.open({
                    state: {
                      item,
                    },
                  })
                }
              />
            )}
          />
        </SearchList.BorderContainer>

        {dialogDelete.isOpen && (
          <DialogDeletarPergunta handleRefetch={reloadList} {...dialogDelete} />
        )}
        {dialogNovaPergunta.isOpen && (
          <DialogNovaPergunta
            {...dialogNovaPergunta}
            state={{ ...dialogNovaPergunta.state, from: 'ACERVO' }}
            handleReload={reloadList}
          />
        )}
      </div>
    </SearchList.Root>
  );
};
