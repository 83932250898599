import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldConsultoriosProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldConsultorio = ({
  name = 'consultorio',
  label,
  ...rest
}: FieldConsultoriosProps) => {
  const { control } = useFormContext();

  const [consultorio, setConsultorio] = useState<GetConsultorioDTO[]>([]);
  const [query, setQuery] = useState('');

  const handleQuery = useThrottle(setQuery, 500);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const loadPage = useCallback(async () => {
    try {
      const res = await ConsultorioAPI.loadConsultorios(
        {
          nome: query,
        },
        { throwError: true },
      );
      setConsultorio(res.list);
    } catch {}
  }, [query]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const options = useMemo(() => {
    const data = consultorio.map(con => ({
      label: con?.nome,
      value: con,
    }));

    return data;
  }, [consultorio]);

  return (
    <DropdownControlled
      control={control}
      className="p-col-12"
      name={name}
      dataKey="id"
      label={label}
      onFilter={e => handleQuery(e.filter)}
      options={options}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
