import { FormProvider, useForm } from 'react-hook-form';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { FieldDataExamesMultiple } from './components/FieldDataExamesMultiple';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import { FieldCidsFavoritos } from 'src/components/Fields/FieldCidsFavoritos/FieldCidsFavoritos';
import { FieldSelectCids } from 'src/components/Fields/FieldSelectCids/FieldSelectCids';
import FormInput from 'src/components/FormInput/FormInput';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import './ModalGeracaoGuia.scss';
import { Button } from 'src/components/_UI/Button';

type GeracaoGuiaTissFormProps = DisclosureType & {
  onOpenResumoGuia?: () => void;
};

export const ModalGeracaoGuia = ({ ...props }: GeracaoGuiaTissFormProps) => {
  return (
    <Dialog
      onHide={props.close}
      visible={props.isOpen}
      className="modal-geracao-guia"
      header="Seleção de procedimentos"
    >
      <ErrorBoundary>
        <ModalGeracaoGuiaContent {...props} />
      </ErrorBoundary>
    </Dialog>
  );
};

const ModalGeracaoGuiaContent = ({
  close,
  ...props
}: GeracaoGuiaTissFormProps) => {
  return (
    <div className="p-col-12">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2 p-mb-4 p-px-0">
        <SimpleText bold>Procedimentos</SimpleText>
        <TextInputSearch
          name="procedimento"
          onChange={() => {}}
          placeholder="Pesquisar por descrição, código ou apelido"
        />
      </div>

      <SimpleText fontColor={FONT_COLOR.COLOR_40} bold>
        Ocorrências
      </SimpleText>

      <div className="guia-content-ocorrencias">
        <div className="p-d-flex p-gap-2">
          <SimpleText>Descrição</SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Paciente em pós operatório de cirurgia cardíaca, atendido em
            ambulatório, duas a três vezes por semana.
          </SimpleText>
        </div>
        <div className="p-d-flex p-gap-2">
          <SimpleText>Técnica</SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>-</SimpleText>
        </div>
        <div className="p-d-flex p-gap-2">
          <SimpleText>Custo</SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>-</SimpleText>
        </div>
        <div className="p-d-flex p-gap-2">
          <SimpleText>Orientação para o profissional</SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>-</SimpleText>
        </div>
      </div>

      <GeracaoGuiaTissForm close={close} {...props} />
    </div>
  );
};

const GeracaoGuiaTissForm = ({ close, ...props }: GeracaoGuiaTissFormProps) => {
  const { onOpenResumoGuia } = props;

  const form = useForm({
    defaultValues: {
      cidProcedimento: '',
      dataExames: [{ value: '11/11/2000' }],
      cid: {} as any,
    },
  });

  const watchCid = form.watch('cid');

  useEffectSkipFirst(() => {
    form.setValue('cidProcedimento', watchCid.nome);
  }, [watchCid]);

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-mt-4">
      <SimpleText fontColor={FONT_COLOR.COLOR_40} bold>
        Nova solicitação de procedimento
      </SimpleText>

      <FormProvider {...form}>
        <form onSubmit={e => e.preventDefault()}>
          <div className="form-geracao-guia p-grid p-col-12">
            <FormInput
              name="indicacaoClinica"
              label="Indicação clínica"
              className="p-col-12 p-py-0"
            />
            <FormInput
              name="qtde"
              label="Quantidade"
              className="p-col-12 p-md-2 p-py-0"
            />
            <FormInput
              name="cidProcedimento"
              label="CID"
              className="p-col-12 p-md-6 p-py-0"
            />

            <FieldSelectCids name="cid" label="CID" />

            <FormInput
              name="doenca"
              label="T. Doença"
              className="p-col-12 p-md-6 p-py-0"
            />

            <DropdownControlled
              control={form.control}
              name="tempo"
              className="p-col-12 p-md-6"
              style={{ marginTop: '19px' }}
              options={[
                { label: 'Ano', value: 'ANO' },
                { label: 'Meses', value: 'MESES' },
                { label: 'Dias', value: 'DIAS' },
              ]}
              hideTextErrorSpace={false}
            />

            <div className="p-col-12 p-d-flex p-ai-center p-jc-between">
              <RadioButtonGroupControlled
                control={form.control}
                stretch
                name="Local"
                items={[
                  {
                    label: 'Consultório',
                    value: 'CONSULTORIO',
                  },
                  {
                    label: 'Hospital',
                    value: 'HOSPITAL',
                  },
                  {
                    label: 'Internado',
                    value: 'INTERNADO',
                  },
                  {
                    label: 'Ambulatório',
                    value: 'AMBULATORIO',
                  },
                ]}
              />
            </div>

            <Divider align="center" className="p-col-12" />

            <div className="p-col-12 p-p-0">
              <SimpleText fontColor={FONT_COLOR.COLOR_40} bold>
                Exame
              </SimpleText>

              <FieldDataExamesMultiple />
            </div>

            <Divider align="center" className="p-col-12" />

            <TextareaInputControlled
              name="observacoes"
              label="Observaçoes"
              control={form.control}
              className="p-col-12 p-px-0"
            />
          </div>

          <SwitchControlled
            control={form.control}
            label="Assinar digitalmente"
            name="assinar"
            className="p-col-12 p-mt-2"
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              stretch
              btnType="ghost"
              onClick={() => [close()]}
            />
            <Button
              type="submit"
              label="Enviar para operadora"
              stretch
              onClick={() => [onOpenResumoGuia && onOpenResumoGuia(), close()]}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
