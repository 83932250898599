import TemplateEmailAPI from "src/APIs/AdminAPI/TemplateEmailAPI/TemplateEmailAPI";

const saveAction = 
  (payload: any, idTemplate: number | null) => {
    if (idTemplate !== null) {
      return TemplateEmailAPI.editarTemplateEmail(payload, idTemplate);
    }
    return TemplateEmailAPI.criarTemplateEmail(payload);
  }


export const saveTemplate =
  async (data: any, idProfissional: number, getTemplateEmail: () => void , idTemplate: number | null) => {
    const { periodicidade, ...rest } = data;

    const payload = {
      idProfissional,
      ...(periodicidade === 'diario' && { emailDiario: true }),
      ...(periodicidade === 'mensal' && { emailMensal: true }),
      ...rest,
    };
    await saveAction(payload, idTemplate);

    getTemplateEmail();
  }
