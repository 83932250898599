import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useOptionalData } from 'src/core/hooks/ManterUsuario/useOptionalData';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

interface DocumentosProps {
  handleActiveIndex(): void;
}

const Contato = ({ handleActiveIndex }: DocumentosProps) => {
  const { isProfessionalHealth } = useSelectProfileCreateUser();
  const { idUser } = useCreateUser();
  const { contactData } = useOptionalData();
  const { user } = useAppSelector((state: RootState) => state);

  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const getSaveAction = useCallback(() => {
    if (contactData) {
      return isProfessionalHealth
        ? ProfissionalAPI.updateProfissional
        : UsuarioAPI.updateUsuario;
    }

    return isProfessionalHealth
      ? ProfissionalAPI.saveProfissionalOpcionais
      : UsuarioAPI.createUsuarioOpcionais;
  }, [isProfessionalHealth, contactData]);

  const handleSave = async (data: any) => {
    const contato = Object.entries(data)
      .filter(([, numero]) => !!numero)
      .map(([tipo, numero]: any) => ({
        numero: numero?.replace?.('-', ''),
        tipo,
      }));

    if (contato.length) {
      const payload = {
        contato,
        idEmpresa: user?.idEmpresa,
      };

      const saveAction = getSaveAction();

      const res = await saveAction('contato', idUser, payload);

      if (res?.status === 200) {
        navigate('/cadastros/usuario');
      }
    } else {
      navigate('/cadastros/usuario');
    }
  };

  const load = useCallback(async () => {
    if (contactData?.length) {
      contactData.forEach(contact => {
        const { numero, tipo } = contact || {};

        setValue(tipo, numero);
      });
    }

    setLoading(false);
  }, [contactData, setValue]);

  useEffect(() => {
    load();
  }, [load]);

  if (loading)
    return (
      <div className="formulario">
        <div className="loadingdados">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <>
      <form
        className="p-grid p-col-12 p-lg-6"
        onSubmit={handleSubmit(handleSave)}
        onReset={() => reset()}
      >
        <MaskedInputControlled
          className="p-col-12"
          control={control}
          label="Telefone residencial"
          name="FIXO_RESIDENCIAL"
          mask={MASK.TELEPHONE}
          errorMsg={errors.FIXO_RESIDENCIAL?.message}
        />

        <MaskedInputControlled
          className="p-col-12"
          control={control}
          label="Telefone consultorio"
          name="FIXO_COMERCIAL"
          mask={MASK.TELEPHONE}
          errorMsg={errors.FIXO_COMERCIAL?.message}
        />

        <MaskedInputControlled
          className="p-col-12"
          control={control}
          label="Celular"
          name="CELULAR_COMERCIAL"
          mask={MASK.PHONE}
          errorMsg={errors.CELULAR_COMERCIAL?.message}
        />

        <MaskedInputControlled
          className="p-col-12"
          control={control}
          label="Celular recado"
          name="CELULAR_PARTICULAR"
          mask={MASK.PHONE}
          errorMsg={errors.CELULAR_PARTICULAR?.message}
        />

        <div className="p-col-12">
          <Button
            label={'Salvar e concluir'}
            type="submit"
            onClick={handleSubmit(handleSave)}
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </>
  );
};

export default Contato;
