import { useMemo } from 'react';

import { useLocation } from 'react-router';

import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import { HistoricoAtendimentos } from '../Atendimento/HistoricoAtendimentos/HistoricoAtendimentos';

import Alergias from './Alergias/Alergias';
import AvaliacaoRisco from './AvaliacaoRisco/AvaliacaoRisco';
import Comorbidades from './Comorbidades/Comorbidades';
import Documentos from './Documentos/Documentos';
import './FichaPaciente.scss';
import MedicamentosUso from './MedicamentosUso/MedicamentosUso';
import MotivoAtendimento from './MotivoAtendimento/MotivoAtendimento';
import Opme from './Opme/Opme';
import PedidosCuidado from './PedidosCuidado/PedidosCuidado';
import PreAtendimento from './PreAtendimento/PreAtendimento';
import { PrescricaoMedicamentos } from './PrescricaoMedicamentos/PrescricaoMedicamentos';
import Questionarios from './Questionarios/Questionarios';
import Vacinas from './Vacinas/Vacinas';

interface FichaPacienteProps {
  idPaciente: number | null;
  paciente: any | null;
  isPrescricao?: boolean;
  usuario: any;
  changeTabIndex: (target: string) => void;
}

function FichaPaciente({
  idPaciente,
  paciente,
  isPrescricao,
  usuario,
  changeTabIndex,
}: FichaPacienteProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus, campos, iniciarPrescricaoExecucao } =
    useAtendimento();

  const tabItems = useMemo(() => {
    if (isPrescricao) {
      return [
        {
          label: 'Prescrição de Medicamentos',
          content: <PrescricaoMedicamentos />,
        },
      ];
    }

    return [
      {
        label: 'Motivo de atendimento',
        content: <MotivoAtendimento idPaciente={idPaciente} />,
        funcionalidade: 'MOTIVO_ATENDIMENTO',
      },
      {
        label: 'Pedido de Cuidado',
        content: <PedidosCuidado idPaciente={idPaciente} />,
        funcionalidade: 'CUIDADO_PROGRAMADO_EXECUCAO',
      },
      {
        label: 'Medicamentos em uso',
        content: <MedicamentosUso idPaciente={idPaciente} />,
        funcionalidade: 'MEDICAMENTO_USO',
      },
      {
        label: 'Problemas',
        content: (
          <Comorbidades
            idPaciente={idPaciente}
            changeTabIndex={changeTabIndex}
          />
        ),
        funcionalidade: 'PROBLEMA',
      },
      {
        label: 'Vacinas',
        content: <Vacinas idPaciente={idPaciente} />,
        funcionalidade: 'VACINA',
      },
      {
        label: 'Alergias e Intolerâncias',
        content: <Alergias idPaciente={idPaciente} />,
        funcionalidade: 'ALERGIA_INTOLERANCIA',
      },
      {
        label: 'Prescrição de Medicamentos',
        content: <PrescricaoMedicamentos view={true} />,
        funcionalidade: 'PRESCRICAO_EXECUCAO',
      },
      {
        label: 'Pré-Atendimento',
        content: <PreAtendimento paciente={paciente} />,
        funcionalidade: 'PRE_ATENDIMENTO',
      },
      {
        label: 'Questionários',
        content: <Questionarios />,
        funcionalidade: 'QUESTIONARIOS',
      },
      {
        label: 'OPMEs',
        content: <Opme idPaciente={idPaciente} />,
        funcionalidade: 'OPME',
      },
      {
        label: 'Documentos',
        content: <Documentos />,
        funcionalidade: 'DOCUMENTOS',
      },
      {
        label: 'Avaliação de risco',
        content: <AvaliacaoRisco paciente={paciente} />,
        funcionalidade: 'AVALIACAO_RISCO',
      },
    ];
  }, [changeTabIndex, idPaciente, isPrescricao, paciente]);

  const tabsFiltered = tabItems.filter((tab: any) => {
    if (!tab?.funcionalidade) return true;

    const campoTab = campos.find(
      campo => campo.funcionalidade === tab.funcionalidade,
    );
    return campoTab?.exibicaoCamposModelo !== 'OCULTO';
  });

  if (
    atendimentoStatus === 'PRESCRICAO' ||
    atendimentoStatus === 'PRESCRICAO_PAUSADA'
  ) {
    return (
      <ElevatedCard>
        <Button
          className="botao-iniciar-atendimento p-w-100 p-mt-3"
          label="Iniciar prescrição"
          onClick={() =>
            idAtendimento && iniciarPrescricaoExecucao(Number(idAtendimento))
          }
        />
      </ElevatedCard>
    );
  }

  return (
    <div id="ficha-paciente">
      <VerticalTab className="p-mb-4" tabItens={tabsFiltered} />

      {!isPrescricao && (
        <HistoricoAtendimentoProvider>
          <HistoricoAtendimentos usuario={usuario} idPaciente={idPaciente} />
        </HistoricoAtendimentoProvider>
      )}
    </div>
  );
}

export default FichaPaciente;
