import { useController, useFormContext } from 'react-hook-form';

import { Editor, EditorProps } from './Editor';

interface EditorControlledProps extends EditorProps {
  name: string;
}

export const EditorControlled = ({ name, ...props }: EditorControlledProps) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <Editor
      {...props}
      onTextChange={e => field.onChange(e.htmlValue)}
      value={field.value}
      errorMsg={fieldState.error?.message}
    />
  );
};
