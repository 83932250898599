import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import useDebounce from 'src/utils/useDebounce';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type FieldConsultorioProps = DropdownProps & {
  name?: string;
  label: string;
  params: {
    idProfissional?: number;
    idConsultorio?: number;
    isEquipeCuidadora: boolean;
    idPaciente: number;
  };
  customOnChange?: (value: any) => void;
};

export const FieldConsultorio = ({
  name = 'consultorio',
  label,
  params,
  ...rest
}: FieldConsultorioProps) => {
  const { control, watch } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [consultorios, setConsultorio] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debouncedSearchConvenio = useDebounce(querySearch, 350);

  const loadprofissional = useCallback(
    async (queryParam: any) => {
      let res;
      if (!queryParam) return setConsultorio([]);

      try {
        res = await PortalPacienteAPI.getConsultorios(
          { nome: queryParam, idProfissionalSaude: params?.idProfissional },
          { throwError: true },
        );
        setConsultorio(res.list);
      } catch (error) {
        setConsultorio([]);
      } finally {
        setLoading(false);
      }
    },
    [params],
  );

  useEffect(() => {
    loadprofissional(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, loadprofissional]);

  const valueAtual = watch(name);

  const profissionalOptions = useMemo(() => {
    const options = consultorios.map(value => ({
      label: value.nome,
      value: value,
    }));

    // valida se o valor atual está listado nas options
    if (valueAtual) {
      const isValueAtualVisivel = consultorios.find(
        value => value.id === valueAtual.id,
      );

      if (!isValueAtualVisivel) {
        options.unshift({
          label: valueAtual.nome,
          value: valueAtual,
        });
      }
    }

    return options;
  }, [consultorios, valueAtual]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setLoading(true);
    setQuerySearch(filter);
  };

  const options = profissionalOptions.length
    ? profissionalOptions
    : defaultOption;

  const emptyFilterMessage = querySearch.length
    ? 'Nenhuma opção encontrada'
    : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loading}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options}
      resetFilterOnHide
      errorMsg={error?.message}
      {...rest}
    />
  );
};
