import { useCallback, useState } from 'react';

import { MotivoTransferenciaAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/MotivoTransferenciaAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalAddMotivo from './ModalAddMotivo/ModalAddMotivo';
import { Row } from './MotivoTrasferenciaGrid/Row';

import './MotivoTransferencia.scss';

const MotivoTrasferencia = () => {
  const modalDisclosure = useDisclosure({ opened: false });
  const modalDeleteDisclosure = useDisclosure({ opened: false });

  const [reload, setReload] = useState<number>(0);

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const onEdit = (motivo: GetManterTrasferenciaApsDTO) => {
    modalDisclosure.open({ state: { id: motivo.id } });
  };

  const onSwitchStatus = async (motivo: GetManterTrasferenciaApsDTO) => {
    const payload = {
      ...motivo,
      status: !motivo.status,
    };
    const response = await MotivoTransferenciaAPI.putMotivo(payload);

    if (response.id) {
      reloadList();
    }
  };

  const onDelete = async (id: number) => {
    modalDeleteDisclosure.open({ state: { id: id } });
  };

  const onConfirmDelete = useCallback(async () => {
    const response = await MotivoTransferenciaAPI.deleteMotivo(
      modalDeleteDisclosure.state.id,
    );

    if (response && 'status' in response) {
      return;
    }
    reloadList();
    modalDeleteDisclosure.close();
  }, [modalDeleteDisclosure]);

  const renderRow = (motivo: GetManterTrasferenciaApsDTO) => (
    <Row
      motivo={motivo}
      onDelete={onDelete}
      onEdit={onEdit}
      onSwitchStatus={onSwitchStatus}
    />
  );

  const fetchAPI = ({ query, ...rest }: any) => {
    return MotivoTransferenciaAPI.getMotivo({
      nome: query,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...rest,
    });
  };

  return (
    <div className="motivo-transferencia p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.SearchInput
            label="Pesquisar motivo"
            placeholder="Pesquise pelo motivo"
          />

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
              <Button
                className="p-mb-2 p-col-12 p-md-6 p-lg-3"
                type="button"
                btnType="pill"
                label="Criar motivo"
                onClick={() => modalDisclosure.open()}
                checkPermission="ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_CADASTRAR"
              />

              <SearchList.TotalCount />
            </div>

            <Header />

            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reload}
              customEmptyText="Nenhum registro foi encontrado. Por favor, tente adicionar um motivo."
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>

      {modalDisclosure.isOpen && (
        <ModalAddMotivo
          idMotivo={modalDisclosure.state?.id || null}
          reload={reloadList}
          visible={modalDisclosure.isOpen}
          onHide={() => modalDisclosure.close()}
        />
      )}

      {modalDeleteDisclosure.isOpen && (
        <ConfirmDialog
          visible={modalDeleteDisclosure.isOpen}
          header="Excluir motivo transferência"
          text="Você confirma a exclusão do motivo transferência?"
          confirmText="Sim, excluir"
          onHide={() => modalDeleteDisclosure.close()}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default MotivoTrasferencia;
