import { useRef } from 'react';

import { ButtonProps } from 'primereact/button';
import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import useSize from 'src/core/hooks/useSize';
import useTheme from 'src/core/themes/useTheme';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './ButtonMenu.scss';

type Props = Omit<ButtonProps, 'onClick'> & {
  icon?: string;
  model: MenuItem[];
  hideLabelWhenIsTablet?: boolean;
  readOnly?: boolean;
  border?: boolean;
  menuClassName?: string;
  btnType?:
    | 'default'
    | 'tonal'
    | 'green-link'
    | 'ghost'
    | 'gray'
    | 'success'
    | 'light-success'
    | 'warning'
    | 'warning-pure'
    | 'danger'
    | 'light-danger';
};

type ITieredProps = TieredMenu & {
  hide: () => void;
};

export const ButtonMenu = ({
  label,
  icon,
  model,
  disabled,
  btnType = 'ghost',
  hideLabelWhenIsTablet = false,
  readOnly,
  border = true,
  className = '',
  menuClassName = '',
  loading,
  ...props
}: Props) => {
  const { theme } = useTheme();
  const { isTablet } = useSize();
  const ref = useRef<ITieredProps>(null);

  return (
    <>
      <button
        {...props}
        onClick={!readOnly ? e => ref.current?.toggle(e) : () => null}
        type="button"
        disabled={disabled}
        className={`btn-split-custom ${theme} ${className} ${btnType} ${
          readOnly ? 'readOnly' : ''
        } ${border ? 'border' : ''}`}
      >
        <div
          style={{ margin: '0 auto' }}
          className="p-d-flex p-gap-2 p-ai-center"
        >
          {icon && !loading && <i className={icon} />}
          {loading && <i className="fas fa-circle-notch fa-spin" />}
          {!(hideLabelWhenIsTablet && isTablet) && !!label && (
            <SimpleText className="actv">{label}</SimpleText>
          )}
        </div>
        {!readOnly && <i className="fas fa-chevron-down" />}
      </button>

      <TieredMenu
        className={`tired-menu-custom ${menuClassName}`}
        ref={ref}
        model={model.map(model => ({
          ...model,
          command: e => [
            model.command && model.command(e),
            ref.current?.hide(),
          ],
        }))}
        popup
      />
    </>
  );
};
