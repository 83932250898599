import React from 'react';

import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { PanelContent } from './PanelContent';
import { PanelHeader } from './PanelHeader';

interface Props {
  ciap: any;
  optionsMenu: any;
}

const RowCIAP = ({ ciap, optionsMenu }: Props) => {
  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            cidCiap={ciap}
            optionsMenu={optionsMenu(ciap, 'CIAP')}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
          />
        )}
      >
        <PanelContent cidCiap={ciap} />
      </Panel>
    </div>
  );
};

export default RowCIAP;
