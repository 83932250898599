import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/fornecedor-material';

export const loadFornecedorTuss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetFornecedorTussDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadFornecedorTussById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetFornecedorTussDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendFornecedorTuss = (
  payload: PostFornecedorTussDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFornecedorTussDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateFornecedorTuss = (
  id: number,
  payload: PostFornecedorTussDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFornecedorTussDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateFornecedorTussStatus = (
  id: number,
  payload: PostFornecedorTussDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFornecedorTussDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/status/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeFornecedorTussById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetFornecedorTussDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

const FornecedorTussAPI = {
  loadFornecedorTuss,
  loadFornecedorTussById,
  updateFornecedorTuss,
  updateFornecedorTussStatus,
  removeFornecedorTussById,
  sendFornecedorTuss,
};

export default FornecedorTussAPI;
