import { DetailedHTMLProps, useContext } from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';
import './ElevatedCard.scss';

interface ElevatedCardProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  stretch?: boolean;
  type?: 'white';
  noMargin?: boolean;
}

const ElevatedCard = ({
  children,
  className,
  noMargin,
  stretch,
  type,
  ...rest
}: ElevatedCardProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={`ElevatedCard ${theme.theme} ${stretch ? 'stretch' : ''} ${
        noMargin ? 'hide-margin' : ''
      } ${type || ''} ${className || ''}`}
      {...rest}>
      {children}
    </div>
  );
};

export default ElevatedCard;
