import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import ParametrosGlobais from './ParametrosGlobais/ParametrosGlobais';
import './Parametros.scss';

const Parametros = () => {
  return (
    <Page className={'Parametros'}>
      <>
        <PageHeader
          title={'Parâmetros'}
          subtitle={'Gerencie parâmetros que modificam o sistema'}
        />
        <ParametrosGlobais />
      </>
    </Page>
  );
};

export default Parametros;
