import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import Panel, { PanelProps } from 'src/components/Panel/Panel';
import './InvisiblePanel.scss';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import IconButton, {
  IconBtnSizes,
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';

export interface InvisiblePanelRef {
  collapse(): void;
  show(): void;
}
interface InvisiblePanelProps
  extends Omit<PanelProps, 'toggleable' | 'collapsed' | 'toggleable'> {
  title?: string;
  children: JSX.Element;
  disabled?: boolean;
  tieredMenuItems?: any;
  tieredMenuOptions?: any;
}
const InvisiblePanel = (
  {
    title = '',
    disabled,
    tieredMenuItems,
    tieredMenuOptions,
    ...rest
  }: InvisiblePanelProps,
  ref: Ref<InvisiblePanelRef> | undefined,
) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useImperativeHandle(ref, () => ({
    collapse: () => {
      setIsOpen(false);
    },
    show: () => {
      setIsOpen(true);
    },
  }));

  return (
    <Panel
      {...rest}
      toggleable
      collapsed={!isOpen}
      onToggle={e => {
        setIsOpen(e.value);
      }}
      className="invisible-panel-container p-col-12"
      headerTemplate={
        <div className="InvisiblePanel">
          <div className="invisible-panel-titles">
            <IconButton
              icon={isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
              btnSize={IconBtnSizes.SMALL}
              btnType={IconBtnTypes.ICON_ONLY}
              disabled={!!disabled}
              onClick={() => {
                setIsOpen((prev: boolean) => !prev);
              }}
            />
            <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
              {title}
            </SimpleText>
          </div>
          <TieredMenu
            model={tieredMenuItems}
            popup
            ref={tieredMenuOptions}
            style={{ width: '30%' }}
          />
          <IconButton
            id="options"
            onClick={event => tieredMenuOptions.current.toggle(event)}
            icon={'pi pi-ellipsis-v'}
            btnType={IconBtnTypes.ICON_ONLY}
          />
        </div>
      }
    />
  );
};

export default forwardRef(InvisiblePanel);
