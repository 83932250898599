import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import { OverlayAnexar } from './OverlayAnexar';

interface MobileRowProps {
  exportacao: any;
  onAlterarEntregue: any;
  setGoogleUrl: any;
  handleDownloadArquivo: any;
}

export const MobileRow = (props: MobileRowProps) => {
  const { exportacao, onAlterarEntregue, setGoogleUrl, handleDownloadArquivo } =
    props;

  const situacoes: any = {
    RECEBIDO: 'Sim',
    ENVIADO: 'Não',
  };

  const splitButtonItems: any = [];

  for (const situacao in situacoes) {
    if (situacao !== exportacao?.status) {
      splitButtonItems.push({
        label: situacoes[situacao],
        command: () =>
          onAlterarEntregue(exportacao.id, {
            // recibo: exportacao?.recibo,
            recebido: situacao === 'RECEBIDO' ? true : false,
          }),
      });
    }
  }

  return (
    <SearchListMobileRow options={[]}>
      <div className="column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Data da geração
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(exportacao?.dataAtualizacao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome do solicitante
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {exportacao?.nomeSolicitante}
        </SimpleText>
      </div>
      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CPF do solicitante
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {exportacao?.cpfSolicitante}
        </SimpleText>
      </div>
      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Pofissional saúde
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {exportacao?.profissionalSaude?.nome}
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {`CRM ${exportacao?.profissionalSaude?.numeroRegistro}`}
        </SimpleText>
      </div>
      <div className="column p-mt-3">
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Entregue
        </SimpleText>
        <SplitButton
          className="p-mt-2"
          menuClassName="menu-exportacao-em-uso"
          btnType={
            exportacao?.status === 'RECEBIDO'
              ? SPLIT_BTN_TYPE.GREEN
              : SPLIT_BTN_TYPE.YELLOW
          }
          label={exportacao?.status === 'RECEBIDO' ? 'Sim' : 'Não'}
          model={splitButtonItems}
        />
      </div>
      <div className="column p-mt-3">
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Recibo
        </SimpleText>
        {exportacao?.status === 'RECEBIDO' ? (
          <Button
            icon="fas fa-file-download"
            btnType="gray"
            onClick={() => handleDownloadArquivo(exportacao)}
          />
        ) : (
          <Button
            btnType="gray"
            className="anexar"
            icon="fas fa-file-upload"
            type="button"
            overlay={
              <OverlayAnexar
                exportacao={exportacao}
                index={exportacao.id}
                setGoogleUrl={setGoogleUrl}
                onAlterarEntregue={onAlterarEntregue}
              />
            }
          />
        )}
      </div>
    </SearchListMobileRow>
  );
};
