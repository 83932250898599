import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  ultrassom: any;
}

export default function PanelContent({ ultrassom }: PanelContentProps) {
  return (
    <div className="p-grid panel-content">
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Doppler
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.doppler ? 'Sim' : 'Não'}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Líquido
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.liquido}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Translucência nucal
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.translucenciaNucal}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Desc. Placenta
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.descPlacenta}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Vesícula
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.vesicula}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Osso nasal
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.dadosOssoNasal}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data DPP
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.dppUs
            ? dayjs(new Date(ultrassom?.dppUs)).format('DD/MM/YYYY')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          Adicionado em{' '}
          {dayjs(new Date(ultrassom?.dataInclusao)).format(
            'DD [de] MMMM [de] YYYY [às] HH:mm',
          )}
        </SimpleText>
      </div>
    </div>
  );
}
