import Http, { HttpParams } from 'src/core/http/Http';

export const getAparencia = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `aparencia-paciente/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
  });

export const getUnidadesAparencia = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'aparencia-paciente/valores-enum',
    hideToast: true,
  });

export const adicionarAparencia = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'aparencia-paciente',
    data: payload,
    return: true,
  });

export const alterarAparencia = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `aparencia-paciente/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
  });

export const inativarAparencia = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `aparencia-paciente/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
  });

export const removerAparencia = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `aparencia-paciente/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
  });

const AparenciaAPI = {
  getAparencia,
  getUnidadesAparencia,
  adicionarAparencia,
  alterarAparencia,
  inativarAparencia,
  removerAparencia,
};

export default AparenciaAPI;
