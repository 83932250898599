import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './tagPesoAltura.scss';

const formatValueViewImc = (value: number) => {
  let result = '';
  switch (true) {
    case value < 18.5:
      result = 'Baixo peso';
      break;
    case value >= 18.5 && value <= 24.9:
      result = 'Peso normal';
      break;
    case value > 24.9 && value <= 29.9:
      result = 'Sobrepeso';
      break;
    case value > 29.9 && value <= 34.9:
      result = 'Obesidade grau |';
      break;
    case value > 34.9 && value <= 39.9:
      result = 'Obesidade grau ||';
      break;
    case value > 39.9:
      result = 'Obesidade grau |||';
      break;
    default:
      result = '';
  }
  return result;
};

const classText = (value: number) => {
  let result = '';
  switch (true) {
    case value < 18.5:
      result = 'baixo-peso';
      break;
    case value >= 18.5 && value <= 24.9:
      result = 'peso-normal';
      break;
    case value > 24.9 && value <= 29.9:
      result = 'sobrepeso';
      break;
    case value > 29.9 && value <= 34.9:
      result = 'obesidade';
      break;
    case value > 34.9 && value <= 39.9:
      result = 'obesidade-2';
      break;
    case value > 39.9:
      result = 'obesidade-3';
      break;
    default:
      result = '';
  }
  return result;
};

export const TagPesoAltura = ({ imc, headerTextClassName = '' }: any) => {
  return (
    <div className="container-tag-peso-altura p-d-flex p-jc-center p-ai-center">
      <SimpleText
        className={`${headerTextClassName} ${classText(
          imc,
        )} text-color p-text-center`}
        fontSize="xxs"
        medium
      >
        {formatValueViewImc(imc)}
      </SimpleText>
    </div>
  );
};
