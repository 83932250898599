import { useState } from 'react';

import dayjs from 'dayjs';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';

import { downloadBase64PDF } from 'src/utils/files';

export const usePDF = (idAtendimento: string) => {
  const {
    webSigner: { handleSign },
    handleSignHSM,
    isHsm,
  } = useAssinatura();
  const [isPrinting, setPrinting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);

    ReportAPI.relatorioPrescricao({ idAtendimento }, { hideToast: true })
      .then(res => {
        const { base64, sequenciaAssinatura } = res;
        const documents = {
          documents: [
            {
              content: base64,
              contentType: 'BASE64',
            },
          ],
        };

        const signPromise = isHsm
          ? handleSignHSM(base64)
          : handleSign(documents, {
              sequenciaAssinatura: sequenciaAssinatura,
              tipoDocumento: 'PRESCRICAO',
            });

        signPromise
          .then((assinatura: any) => {
            if (!assinatura[0].data[0].signature) {
              setIsDownloading(false);
            } else {
              ReportAPI.relatorioPrescricaoAssinado(idAtendimento, {
                base64,
                base64Assinado: assinatura[0].data[0].signature,
              }).then(res => {
                const fileURL = URL.createObjectURL(
                  new Blob([res.data], {
                    type: 'application/pdf',
                  }),
                );

                if (res.status === 200) {
                  const reportName = `rel_prescrição_${dayjs().format(
                    'DD/MM/YYYY',
                  )}`;
                  const link = document.createElement('a');
                  link.href = fileURL;
                  link.download = reportName;
                  link.click();
                }
              });
            }
          })
          .catch(() => setIsDownloading(false));
      })
      .catch(() => setIsDownloading(false))
      .finally(() => setIsDownloading(false));
  };

  const handlePrint = () => {
    setPrinting(true);

    ReportAPI.relatorioPrescricaoImprimir({ idAtendimento })
      .then(res => {
        const fileURL = URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf',
          }),
        );

        if (res.status === 200) {
          window.open(fileURL);
        }
      })
      .finally(() => setPrinting(false));
  };

  return { isPrinting, isDownloading, handlePrint, handleDownload };
};
