import Http, { HttpParams } from 'src/core/http/Http';

export const loadTipoMotivoFalta = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoMotivoFaltaDTO[]> => {
  return Http.get({
    service: 'agenda',
    url: '/motivo-falta/pesquisa',
    hideToast: true,
    ...options,
  });
};

export const getTipoMotivoFalta = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetTipoMotivoFaltaDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: `/motivo-falta/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendTipoMotivoFalta = (
  payload: PostTipoMotivoFaltaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: '/motivo-falta',
    data: payload,
    ...options,
  });
};

export const updateTipoMotivoFalta = (
  id: number,
  payload: PostTipoMotivoFaltaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'agenda',
    url: `/motivo-falta/${id}`,
    data: payload,
    ...options,
  });
};

export const removeTipoMotivoFalta = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'agenda',
    url: `/motivo-falta/${id}`,
    ...options,
  });
};

export const getMotivoFaltaByConsultorio = (
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<TipoMotivoFalta[]> => {
  return Http.get({
    service: 'agenda',
    url: '/motivo-falta/busca-motivo-falta-por-consultorio',
    params: {
      idConsultorio,
    },
    hideToast: true,
    ...options,
  });
};

const TipoMotivoFaltaAPI = {
  loadTipoMotivoFalta,
  getTipoMotivoFalta,
  sendTipoMotivoFalta,
  updateTipoMotivoFalta,
  removeTipoMotivoFalta,
  getMotivoFaltaByConsultorio,
};

export default TipoMotivoFaltaAPI;
