import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

import './SearchList.scss';
import PacienteListItem from '../ListItemPaciente/ListItemPaciente';

interface SearchListProps {
  fetchAPI?(params?: any): Promise<Paginator<any>> | Paginator<any>;
  value?: any;
  setSearchPaciente: any;
  setListaPacientesSelecionados: any;
  listaPacientesSelecionados: any;
}

const SearchList = (props: SearchListProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const {
    fetchAPI,
    value: query,
    setSearchPaciente,
    listaPacientesSelecionados,
    setListaPacientesSelecionados,
  } = props;

  const page = useRef(0);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState<any[]>([]);
  const filter: any = useMemo(() => ({ query }), [query]);

  const fetch = useCallback(async selectedFilters => {
    if (!fetchAPI) return [];
    try {
      const aux = await fetchAPI({
        ...selectedFilters,
        page: page.current,
      });

      return aux.list;
    } catch (e) {
      console.log('Error fetching data from SearchList: ', e);
    }

    const objectConstructor = {}.constructor;

    return Array.from({ length: 50 }).filter(
      (v: any) => v && v.constructor === objectConstructor,
    );
  }, []);

  const load = useCallback(async () => {
    page.current = 0;
    setItems([]);

    if (!filter.query) return;

    setLoading(true);
    const newItems = await fetch(filter);

    setItems(prev => {
      return [...prev.concat(newItems)];
    });

    if (newItems.length === 0) {
      setHasMore(false);
    }

    setLoading(false);
  }, [filter, fetch]);

  const fetchMoreData = useCallback(async () => {
    page.current = page.current + 1;

    const newItems = await fetch(filter);
    setItems(prev => {
      return [...prev.concat(newItems)];
    });
  }, [fetch, filter]);

  useEffect(() => {
    load();
  }, [load]);

  const handleAddPaciente = useCallback(
    (pac: any) => {
      setSearchPaciente('');
      setListaPacientesSelecionados([...listaPacientesSelecionados, pac]);
    },
    [
      listaPacientesSelecionados,
      setListaPacientesSelecionados,
      setSearchPaciente,
    ],
  );

  return (
    <div className={'SearchList tes'}>
      <div className="grid-list border-list">
        {loading && hasMore ? (
          <GridListLoading />
        ) : items.length === 0 ? (
          <Empty />
        ) : (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={!hasMore ? <GridListLoading /> : <></>}
            height={400}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {items &&
              Array.isArray(items) &&
              (items || []).map((data, index) => {
                return (
                  <PacienteListItem
                    key={index}
                    paciente={data}
                    onClick={() => handleAddPaciente(data)}
                    handleRemove={() => console.log(data)}
                    isList={false}
                  />
                );
              })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const Empty = memo(() => {
  return (
    <div className="grid-list-content-empty">
      <SimpleText>Os resultados da busca irão aparecer aqui.</SimpleText>
      <SimpleText>Clique aqui para exibir todos os resultados.</SimpleText>
    </div>
  );
});

export default memo(SearchList);
