import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './AtendimentoEstado.scss';

type EstadosAtendimentos = {
  [key: string]: string;
};

const estadosAtendimentos: EstadosAtendimentos = {
  ATENDENDO: 'Atendendo',
  CONTINUAR: 'Continuar',
  PRESENTE: 'Presente',
  PRESCRICAO_COMPLETA: 'Prescricao OK',
  TRIAGEM: 'Triagem',
  TRIAGEM_FINALIZADA: 'Triagem Ok',
  ATENDENDO_TRIAGEM: 'Atendendo Triagem',
  CUIDADO_PROGRAMADO: 'Cuidado Programado',
  PRESCRICAO: 'Prescrição',
  PRESCRICAO_FINALIZADA: 'Prescrição finalizada',
  PRESCRICAO_EXECUCAO: 'Prescrição em andamento',
  PRESCRICAO_PAUSADA: 'Prescrição pausada',
};

interface AtendimentoEstadoProps {
  estado: string;
}

function AtendimentoEstado({ estado }: AtendimentoEstadoProps) {
  return (
    <SimpleText
      className={`agenda-status ${estado}`}
      fontSize={FONT_SIZE.XS}
      bold
    >
      {(estado.includes('COMPLETA') || estado.includes('FINALIZADA')) && (
        <i className="fas fa-check mr-1" />
      )}
      {estadosAtendimentos[estado]}
    </SimpleText>
  );
}

export default AtendimentoEstado;
