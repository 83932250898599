import { useState } from 'react';

import { useLocation } from 'react-router';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

interface UltrassomProps {
  ultrassomList: any[];
  loading: boolean;
  loadUltrassomList: () => void;
  onEdit: (ultrassom: any) => void;
  clearForm: () => void;
}

const Ultrassom = ({
  ultrassomList,
  loading,
  loadUltrassomList,
  onEdit,
  clearForm,
}: UltrassomProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const [idUltrassomExcluir, setIdUltrassomExcluir] = useState<number | null>(
    null,
  );
  const [ultrassomInativar, setUltrassomInativar] = useState<any>(null);

  const onExcluir = async () => {
    if (!idUltrassomExcluir) return;

    const response = await PreNatalAPI.deleteUltrassom(idUltrassomExcluir);

    if (response.status === 204) {
      loadUltrassomList();
      clearForm();
      onEdit(null);
    }

    setIdUltrassomExcluir(null);
  };

  const renderHistorico = () => {
    if (loading) return <GridListLoading />;

    if (!ultrassomList.length)
      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Não há histórico de ultrassom
        </SimpleText>
      );

    return ultrassomList.map((ultrassom: any) => (
      <Panel
        key={ultrassom.id}
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            ultrassom={ultrassom}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            onInativar={() => setUltrassomInativar(ultrassom)}
            onEdit={() => onEdit(ultrassom)}
            onDelete={() => setIdUltrassomExcluir(ultrassom.id)}
          />
        )}
      >
        <PanelContent ultrassom={ultrassom} />
      </Panel>
    ));
  };

  return (
    <>
      <SimpleText
        className="p-col-12"
        fontSize={FONT_SIZE.MD}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Histórico de ultrassom
      </SimpleText>

      <div id="historico-pre-natal-container" className="p-col-12">
        {renderHistorico()}
      </div>

      <ConfirmDialog
        visible={!!idUltrassomExcluir}
        header="Excluir Ultrassom"
        text="O ultrassom será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdUltrassomExcluir(null)}
        onConfirm={onExcluir}
      />

      {ultrassomInativar && (
        <ModalJustificativa
          ultrassom={ultrassomInativar}
          onHide={() => {
            setUltrassomInativar(null);
          }}
          onSuccess={loadUltrassomList}
        />
      )}
    </>
  );
};

export default Ultrassom;
