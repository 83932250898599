import { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';

import { useAgenda } from '../../AgendaContext';
import ConfirmarFalta from '../ConfirmarFalta/ConfirmarFalta';

import './Status.scss';

interface StatusProps {
  agendamento: AtendimentoAgendamento;
  selectedDate: Date;
  getGrupo?: (() => void) | undefined;
  onHide?: () => void;
}

const Status = ({
  agendamento,
  selectedDate,
  getGrupo,
  onHide,
}: StatusProps) => {
  const navigate = useNavigate();
  const { user, tiss } = useAppSelector((state: RootState) => state);
  const {
    handleConfirmarPresenca,
    refetchAgenda,
    isAgendaCompletaOpened,
    reloadList,
    obrigaPresencaCuidado,
  } = useAgenda();

  const [confirmarFaltaVisible, setConfirmarFaltaVisible] =
    useState<boolean>(false);

  const visualizacaoAgenda = isAgendaCompletaOpened
    ? 'AGENDA_COMPLETA'
    : 'AGENDA_MEDICO';

  const isAgendamentoFuturo = useMemo(() => {
    return (
      new Date(agendamento?.dataAgendamento).getDate() > new Date().getDate()
    );
  }, [agendamento]);

  const hasProcessamentoTissData = tiss?.processandoTiss?.find?.(
    (t: any) => t.idAgendamento === agendamento.id,
  );

  const handleReenviar = useCallback(async () => {
    await AutorizacaoAPI.realizarPresenca(
      agendamento.id,
      hasProcessamentoTissData.payload,
    );
    refetchAgenda();
    reloadList();
    if (getGrupo) {
      getGrupo();
    }
  }, [
    agendamento?.id,
    hasProcessamentoTissData?.payload,
    refetchAgenda,
    reloadList,
    getGrupo,
  ]);

  const handleAlterarStatus = useCallback(
    async (atendimento, novoStatus, idMotivoFalta = null) => {
      return new Promise(async (resolve, reject) => {
        let response;
        try {
          response = await AgendamentosAPI.alterarStatusAgenda({
            ...atendimento,
            status: novoStatus,
            ...(idMotivoFalta && { idMotivoFalta }),
          });

          if (response?.status === 200) {
            refetchAgenda();
            reloadList();
            if (getGrupo) {
              getGrupo();
            }

            if (onHide) {
              onHide();
            }
          }

          resolve(response);
        } catch (error) {
          reject(response);
          console.log({ error });
        }
      });
    },
    [getGrupo, onHide, refetchAgenda, reloadList],
  );
  const onConfirmarPresenca = useCallback(() => {
    handleConfirmarPresenca(agendamento, onHide, true);
  }, [agendamento, handleConfirmarPresenca, onHide]);

  const getAgendadoOptions = useCallback(() => {
    if (isAgendamentoFuturo || !!agendamento?.agendamentoRapidoNome) {
      return [
        {
          label: 'Cancelado',
          command: () => handleAlterarStatus(agendamento, 'CANCELADO'),
        },
      ];
    }

    return [
      {
        label: 'Presente',
        disabled:
          agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo,
        command: async () => {
          try {
            onConfirmarPresenca();
          } catch (e) {
            console.log(e);
          }
        },
      },
      {
        label: 'Faltou',
        command: () => setConfirmarFaltaVisible(true),
      },
      {
        label: 'Cancelado',
        command: () => handleAlterarStatus(agendamento, 'CANCELADO'),
      },
    ];
  }, [
    agendamento,
    handleAlterarStatus,
    isAgendamentoFuturo,
    onConfirmarPresenca,
  ]);

  const cuidadoProgramadoOptions = useMemo(() => {
    const options = [
      {
        label: 'Presente',
        disabled:
          agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo,
        command: async () => {
          try {
            onConfirmarPresenca();
          } catch (e) {
            console.log(e);
          }
        },
      },
      {
        label: 'Faltou',
        command: () => setConfirmarFaltaVisible(true),
      },
      {
        label: 'Cancelado',
        command: () => handleAlterarStatus(agendamento, 'CANCELADO'),
      },
    ];

    if (!obrigaPresencaCuidado) {
      options.shift();
    }
    return options;
  }, [
    agendamento,
    handleAlterarStatus,
    obrigaPresencaCuidado,
    onConfirmarPresenca,
  ]);

  const renderState = useCallback(() => {
    switch (agendamento?.status) {
      case 'LIVRE':
        return (
          <Button
            onClick={() => {
              navigate('/agenda/novo-agendamento', {
                state: {
                  agendamento: agendamento,
                  date: selectedDate,
                  voltarParaAgenda: visualizacaoAgenda,
                },
              });
            }}
            stretch
            label="Livre"
            btnType="gray"
            icon={'fas fa-plus'}
          />
        );

      case 'CONTINUAR':
        return <Button label="Continuar" btnType="warn" stretch />;

      case 'ATENDIDO':
        return (
          <Button
            label="Atendido"
            btnType="gray"
            icon={'fas fa-check'}
            stretch
            disabled
          />
        );

      case 'ATENDENDO':
        return <Button label="Atendendo" btnType="gray" stretch disabled />;

      case 'BLOQUEADO':
        return (
          <Button label="Bloqueado" btnType="light-danger" stretch disabled />
        );

      case 'TRIAGEM':
        return (
          <ButtonMenu
            label="Triagem"
            btnType="light-success"
            border={false}
            className="p-w-100"
            model={[
              {
                label: 'Presente',
                command: () => handleAlterarStatus(agendamento, 'PRESENTE'),
              },
            ]}
          />
        );

      case 'ATENDENDO_TRIAGEM':
        return (
          <Button
            label="Atendendo Triagem"
            btnType="light-success"
            stretch
            disabled
          />
        );

      case 'TRIAGEM_FINALIZADA':
        return (
          <Button
            label="Triagem OK"
            icon={'fas fa-check'}
            btnType="light-success"
            stretch
          />
        );
      case 'PRESCRICAO':
        return <Button label="Prescrição" btnType="success" stretch />;

      case 'PRESCRICAO_EXECUCAO':
        return (
          <Button label="Prescrição em andamento" btnType="success" stretch />
        );

      case 'PRESCRICAO_FINALIZADA':
        return (
          <Button
            label="Prescrição finalizada"
            icon={'fas fa-check'}
            btnType="success"
            stretch
          />
        );

      case 'PRESCRICAO_PAUSADA':
        return <Button label="Prescrição pausada" btnType="warn" stretch />;

      case 'FALTOU':
        return <Button label="Faltou" btnType="light-danger" stretch />;

      case 'CANCELADO':
        return <Button label="Cancelado" btnType="light-danger" stretch />;

      case 'NEGADO_OPERADORA':
        return (
          <Button
            label="Negado operadora"
            btnType="danger"
            icon="fas fa-triangle-exclamation"
            className="p-w-100"
          />
        );

      case 'PROCESSANDO_TISS':
        return (
          <Button label="Processando TISS" btnType="light-warning" stretch />
        );

      case 'AGENDADO':
        return (
          <ButtonMenu
            label="Agendado"
            btnType="warning"
            border={false}
            className="p-w-100"
            menuClassName="menu-btn-w"
            model={getAgendadoOptions()}
          />
        );

      case 'REENVIAR':
        return (
          <ButtonMenu
            label="Não processado"
            btnType="warning-pure"
            icon="fas fa-triangle-exclamation"
            border={false}
            className="p-w-100"
            menuClassName="menu-btn-w"
            model={[
              {
                label: 'Reenviar',
                command: () => handleReenviar(),
                className:
                  agendamento?.usuarioAlteracao?.id !== user.idUsuario ||
                  !hasProcessamentoTissData
                    ? 'p-hidden'
                    : '',
              },
              {
                label: 'Cancelado',
                command: () => handleAlterarStatus(agendamento, 'CANCELADO'),
              },
              {
                label: 'Agendado',
                command: () => handleAlterarStatus(agendamento, 'AGENDADO'),
              },
              {
                label: 'Presente',
                disabled:
                  agendamento.idGrupoAtendimento &&
                  !agendamento.idAtendimentoEmGrupo,
                command: () => {
                  onConfirmarPresenca();
                },
              },
            ]}
          />
        );

      case 'PRESENTE':
        return (
          <ButtonMenu
            label="Presente"
            btnType="light-success"
            className="p-w-100"
            border={false}
            model={[
              {
                label: 'Triagem',
                disabled:
                  agendamento.idAtendimentoEmGrupo ||
                  agendamento.idGrupoAtendimento,
                command: () => handleAlterarStatus(agendamento, 'TRIAGEM'),
              },
              {
                label: 'Cancelado',
                command: () => handleAlterarStatus(agendamento, 'CANCELADO'),
              },
            ]}
          />
        );

      case 'CUIDADO_PROGRAMADO':
        return (
          <ButtonMenu
            label="Cuidado Programado"
            btnType="warning"
            className="p-w-100"
            border={false}
            model={
              !isAgendamentoFuturo
                ? cuidadoProgramadoOptions
                : [
                    {
                      label: 'Cancelado',
                      command: () =>
                        handleAlterarStatus(agendamento, 'CANCELADO'),
                    },
                  ]
            }
          />
        );
      default:
        return <></>;
    }
  }, [
    agendamento,
    getAgendadoOptions,
    user.idUsuario,
    hasProcessamentoTissData,
    isAgendamentoFuturo,
    cuidadoProgramadoOptions,
    navigate,
    selectedDate,
    visualizacaoAgenda,
    handleAlterarStatus,
    handleReenviar,
    onConfirmarPresenca,
  ]);

  return (
    <div className="p-w-100">
      {renderState()}
      {confirmarFaltaVisible && (
        <ConfirmarFalta
          visible={confirmarFaltaVisible}
          onConfirm={idMotivoFalta =>
            handleAlterarStatus(agendamento, 'FALTOU', idMotivoFalta)
          }
          onHide={() => setConfirmarFaltaVisible(false)}
        />
      )}
    </div>
  );
};

export default Status;
