import { useState, useEffect, useCallback, memo } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';


import './Desfecho.scss';

interface DesfechoProps {
  campos: any[];
  usuario: any;
  updateCamposProntuario: (campos: any) => void;
  desfechoEmpresa: string | undefined;
}

function Desfecho({
  campos,
  usuario,
  updateCamposProntuario,
  desfechoEmpresa,
}: DesfechoProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [campoDesfecho, setCampoDesfecho] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(
    campoDesfecho.exibicaoCamposModelo === 'FIXO' ? true : false,
  );
  const [desfechos, setDesfechos] = useState<any[]>([]);
  const [desfechoSelecionado, setDesfechoSelecionado] = useState<any>({});

  const getDesfechos = useCallback(async () => {
    try {
      const response = await UtilsAPI.getDesfechos(usuario?.empresa.id, {
        throwError: true,
      });
      setDesfechos(response);
    } catch {
      setDesfechos([]);
    }
  }, [usuario?.empresa.id]);

  useEffect(() => {
    setCampoDesfecho(
      campos.filter(
        campo => campo.funcionalidade === 'DESFECHO_ATENDIMENTO',
      )[0],
    );
    setChecked(campoDesfecho?.exibicaoCamposModelo === 'FIXO' ? true : false);
  }, [campos, campoDesfecho]);

  useEffect(() => {
    getDesfechos();
  }, [getDesfechos]);

  useEffectSkipFirst(() => {
    if (desfechoEmpresa) {
      setDesfechoSelecionado(
        desfechos.find(desfecho => desfecho?.descricao === desfechoEmpresa),
      );
    } else {
      desfechos?.forEach(desfecho => {
        if (!!desfecho.principal) {
          setDesfechoSelecionado(desfecho);
        }
      });
    }
  }, [desfechos]);

  const reloadCampos = () => {
    if (campoDesfecho.exibicaoCamposModelo === 'FIXO') {
      campoDesfecho.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoDesfecho.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoDesfecho.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }
    const exibixaoCampo =
      campoDesfecho.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoDesfecho.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const saveAction = useCallback(
    (payload: any) => {
      return UtilsAPI.putCampos(Number(idAtendimento), payload);
    },
    [idAtendimento],
  );

  const handleSelectDesfecho = useCallback(
    async (data: any) => {
      const payload = {
        idAtendimento: Number(idAtendimento),
        camposModeloProntuario: 'DESFECHO_ATENDIMENTO',
        valorCampoProntuario: data?.id || null,
        idPaciente: Number(agenda?.paciente?.id),
        idProfissional: Number(agenda?.profissionalAtivo?.id),
      };
      updateCamposProntuario({ DESFECHO_ATENDIMENTO: data?.id });
      await saveAction(payload);
    },
    [
      agenda?.paciente?.id,
      agenda?.profissionalAtivo?.id,
      idAtendimento,
      saveAction,
      updateCamposProntuario,
    ],
  );

  const isRequired = campoDesfecho.obrigatorio;

  return (
    <>
      <div className="p-d-flex p-jc-between p-ai-end p-mt-2">
        <div>
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Desfecho
          </SimpleText>
          {isRequired && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div>
          <ToggleButton
            checked={checked}
            className={`p-px-4 p-py-1 pin ${isRequired ? 'pin-disabled' : ''}`}
            onLabel={''}
            offLabel={''}
            onChange={e =>
              !isRequired ? [handleFixar(), setChecked(e.value)] : null
            }
            onIcon="fa fa-thumbtack"
            offIcon="fa fa-thumbtack"
          />
        </div>
      </div>
      <Dropdown
        className="dropdown-desfecho p-mt-2"
        optionLabel="descricao"
        value={desfechoSelecionado}
        options={desfechos}
        onChange={e => {
          setDesfechoSelecionado(e.value);
          handleSelectDesfecho(e.value);
        }}
        placeholder="Selecione um desfecho"
        emptyMessage="Nenhum desfecho encontrado"
        showClear
      />
    </>
  );
}

export default memo(Desfecho);
