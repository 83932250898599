import { useState } from 'react';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

type BuscaRapidaSubmitType = {
  isSubmitting: boolean;
  handleAddProcedimentos: () => void;
};

interface BuscaRapidaProps {
  idAtendimento: number;
  procedimentosSelected: any[];
  setProcedimentosSelected: React.Dispatch<React.SetStateAction<any[]>>;
}

export const useBuscaRapidaSubmit = (
  props: BuscaRapidaProps,
): BuscaRapidaSubmitType => {
  const { idAtendimento, procedimentosSelected, setProcedimentosSelected } =
    props;
  const { numeroCartaoAtendimentoParticular } = useAtendimento();
  const { handleUpdateProcedimentosInGuias } = useSolicitacaoExameCirurgia();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddProcedimentos = async () => {
    try {
      setIsSubmitting(true);

      const payload = procedimentosSelected.map((solicitacao: any) => ({
        descricaoProcedimento: solicitacao.nome,
        codigoProcedimento: solicitacao?.codigo,
        idConvenio: solicitacao.idConvenio,
        ...(solicitacao.tipo === 'F' && { idServicoTuss: solicitacao.id }),
        ...(solicitacao.tipo === 'G' && { idGrupoExame: solicitacao.id }),
        ...(solicitacao.tipo === 'P' && { idPacoteExame: solicitacao.id }),
        ...(numeroCartaoAtendimentoParticular && {
          numeroCartaoAtendimentoParticular,
        }),
      }));

      const response = await SolicitacoesProcedimentosAPI.postProcedimento(
        Number(idAtendimento),
        payload,
      );

      setProcedimentosSelected([]);

      handleUpdateProcedimentosInGuias(response);
    } catch (error) {
      // error
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    handleAddProcedimentos,
  };
};
