import { useCallback, useEffect, useState } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { MemedAPI } from 'src/APIs/ProntuarioAPI/MemedAPI/MemedAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

export const FieldMedicamentos = ({
  name = 'principioAtivo',
  checkDisabledOnEdit,
  ...props
}: any) => {
  const { control, getValues } = useFormContext();
  const [items, setItems] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [loading, setLoading] = useState(false);

  const { principioAtivo } = getValues();

  const {
    fieldState: { error },
  } = useController({ control, name });

  useEffect(() => {
    if (principioAtivo) {
      defaultOption.push({
        label: principioAtivo.nome,
        value: principioAtivo,
        disabled: false,
      });
    }

    return () => {
      principioAtivo && defaultOption.pop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchDebounce: string = useDebounce(querySearch, 500);

  const fetchMedicamentos = useCallback(async (medicamento = '') => {
    const data = await MemedAPI.getMedicamentos(
      { medicamento },
      { throwError: false },
    );

    if (data.length) {
      const items = data.map((item: any) => ({
        label: item.nome,
        value: item,
      }));
      setItems(items);
    } else {
      setItems([]);
    }

    setLoading(false);
  }, []);

  useEffectSkipFirst(() => {
    if (searchDebounce.length > 3) {
      setLoading(true);
      fetchMedicamentos(searchDebounce);
    }
    if (searchDebounce.length === 0) setItems([]);
  }, [searchDebounce]);

  return (
    <DropdownControlled
      autoFocus
      className="p-sm-12"
      control={control}
      name={name}
      isSearchable
      onFilter={e => [setQuerySearch(e.filter)]}
      carregandoFiltro={loading}
      label="Princípio ativo"
      emptyFilterMessage={
        querySearch.length <= 3
          ? 'Digite para pesquisar'
          : 'Nenhuma opção encontrada'
      }
      placeholder="Selecione..."
      filterPlaceholder="Digite para pesquisar"
      options={items.length ? items : defaultOption}
      errorMsg={error?.message}
      disabled={checkDisabledOnEdit}
      {...props}
    />
  );
};
