import { Tooltip } from 'primereact/tooltip';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import { ComplexidadeStatus } from './ComplexidadeStatus';
import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

import './Row.scss';

interface RowProps {
  complexidade: ComplexidadePacienteDTO;
  onEdit: (complexidade: ComplexidadePacienteDTO) => void;
  onDelete: (id: number) => void;
}

export const Row = (props: RowProps) => {
  const { complexidade } = props;
  const { isMobile, isTablet } = useSize();

  const renderMobile = isMobile || isTablet;

  const complementMultipleConsultorios = (
    <>
      <Tooltip
        className="complexidade-consultorios-tooltip"
        autoHide={false}
        target={`.consultorios-tooltip-target${complexidade.id}`}
        position="top"
        appendTo={document.getElementById('complexidade-paciente-container')}
      >
        {complexidade?.consultoriosDto
          ?.slice(1)
          .map((consultorio: GetConsultorioDTO) => (
            <div key={consultorio.nome}>{consultorio.nome}</div>
          ))}
      </Tooltip>
      <span className={`p-ml-1 consultorios-tooltip-target${complexidade.id}`}>
        +{complexidade?.consultoriosDto?.length - 1}
      </span>
    </>
  );

  const consultoriosComplexidade = (
    <SimpleText
      className="p-col-2 p-wordbreak-all"
      fontSize={FONT_SIZE.XXS}
      fontColor={FONT_COLOR.COLOR_40}
    >
      {complexidade.consultoriosDto?.[0]?.nome}

      {complexidade.consultoriosDto.length > 1 &&
        complementMultipleConsultorios}
    </SimpleText>
  );

  const dataInclusao = complexidade?.dataInclusao
    ? dayjs(new Date(complexidade?.dataInclusao)).format('DD/MM/YYYY')
    : '';

  const complexidadeStatus = (
    <ComplexidadeStatus
      idComplexidade={complexidade.id}
      isAtivo={complexidade.ativo}
    />
  );

  const commonProps = {
    complexidadeStatus,
    consultoriosComplexidade,
    dataInclusao,
    ...props,
  };

  return renderMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
