import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import EspecialidadeServicoTussSelected from './EspecialidadeServicoTussSelected';
import SearchEspecialidadeServicoTuss from './SearchEspecialidadeServicoTuss/SearchEspecialidadeServicoTuss';

import './FormEspecialidadesServicoTuss.scss';

interface FormEspecialidadesServicoTussProps {
  onSubmit(v: Especialidade[]): void;
  values?: Especialidade[] | any;
}

const FormEspecialidadesServicoTuss = ({
  onSubmit,
  values,
}: FormEspecialidadesServicoTussProps) => {
  const [modal, setModal] = useState(false);

  const [value, setValue] = useState<Especialidade[]>([]);

  const load = useCallback(async () => {
    if (values && values?.length > 0) {
      const profs = await EspecialidadeAPI.loadEspecialidades({
        nome: '',
      });

      const servicosTussList: any[] = [];

      values.map((v: any) => {
        const findServicoTuss = profs?.list?.filter(
          (p: any) => p.id === (v.idEspecialidade ? v.idEspecialidade : v.id),
        );

        const profEncontrado = {
          ...findServicoTuss[0],
          ...v,
          id: v.idEspecialidade ? v.idEspecialidade : findServicoTuss[0]?.id,
        };

        return servicosTussList.push(profEncontrado);
      });

      setValue(servicosTussList);
    }
  }, [values]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeItems = useCallback(
    items => {
      setValue(items);
      setModal(false);

      onSubmit(items);
    },
    [onSubmit],
  );

  // Ordena os valores por
  const valueOrdered = useMemo(
    () => value.sort((a, b) => (a.nome < b.nome ? -1 : 1)),
    [value],
  );

  return (
    <div className={'FormEspecialidadesServicoTuss'}>
      <SimpleText fontSize={FONT_SIZE.XS} bold>
        Especialidades livres
      </SimpleText>

      <div className="form-container-especialidade-servico-tuss">
        <div>
          <EspecialidadeServicoTussSelected
            value={valueOrdered}
            onChange={handleChangeItems}
          />

          <Button
            label="Editar especialidades livres"
            btnType="tonal"
            className="button-submit-especialidade-servico-tuss"
            icon="pi pi-pencil"
            onClick={() => setModal(true)}
          />
        </div>
      </div>

      <SearchEspecialidadeServicoTuss
        visible={modal}
        onHide={() => setModal(false)}
        onSelect={handleChangeItems}
        initialValue={valueOrdered}
      />
    </div>
  );
};

export default memo(FormEspecialidadesServicoTuss);
