import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  return (
    <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
      <div className="p-col-1 p-lg-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Data do envio{' '}
        </SimpleText>
      </div>
      <div className="p-col-3 p-lg-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome/Cartão do beneficiário{' '}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Profissional de saúde{' '}
        </SimpleText>
      </div>
      <div className="p-col-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          E-mail do destinatário/Conteúdo{' '}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Tipo documento{' '}
        </SimpleText>
      </div>
      <div className="p-col-1">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Status{' '}
        </SimpleText>
      </div>
    </div>
  );
};
