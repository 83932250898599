import { useState } from 'react';

import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

type BuscaRapidaFiltrosType = {
  typeFilter: any[];
  handleChangeTypeFilter: (filters: any) => void;
};

export const useBuscaRapidaFiltros = (): BuscaRapidaFiltrosType => {
  const { setValue, storedValue } = useLocalStorage(`buscaRapidaFiltros`);

  const [typeFilter, setTypeFilter] = useState<any[]>(storedValue ?? []);

  const handleChangeTypeFilter = (filters: any) => {
    setTypeFilter([...filters]);
    setValue([...filters]);
  };

  return {
    typeFilter,
    handleChangeTypeFilter,
  };
};
