import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';

import { transformarEmMoeda } from 'src/utils/currency';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import Separator from 'src/components/Separator/Separator';

import { useNovoAgendamento } from './hooks/NovoAgendamentoContext';

export const NovoAgendamentoResumo = ({
  recorrencia,
  internalDateTime,
  selectedGrupo,
  contato,
  observacao,
  periodoRecorrencia,
  handleAgendamentoRecorrente,
  handleAgendamento,
  tipoOcorrencia,
  horario,
  handleSubmit,
  horarioRecorrencia,
}: any) => {
  const { agenda } = useAppSelector(state => state);
  const {
    paciente,
    planoSelected,
    isSubmitting,
    servicoTussSelected,
    agendamentoRapidoNome,
  } = useNovoAgendamento();

  const profissional = agenda?.profissionalAtivo;

  const getIsDisabled = () => {
    if (!!agendamentoRapidoNome) return !horario || !contato;
    return selectedGrupo ? !horario : !paciente || !planoSelected || !horario;
  };

  return (
    <div className="info">
      <ElevatedCard>
        <div className="detailed">
          <SimpleText
            bold
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.MD}
          >
            Confirmação
          </SimpleText>
          <Separator spaceOnly vertical />
          <SimpleText bold fontColor={FONT_COLOR.COLOR_16}>
            {profissional?.nome || ''}
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XXS}
            style={{ marginTop: 5 }}
          >
            {profissional?.formacaoProfissional || ''}
          </SimpleText>
          <Separator spaceOnly vertical />
          <div className="dist-holder">
            <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
              Data e hora
            </SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {recorrencia
                ? dayjs(horario).format('DD/MM/YYYY HH:mm')
                : dayjs(internalDateTime).isValid()
                ? dayjs(internalDateTime).format('DD/MM/YYYY HH:mm')
                : ''}
            </SimpleText>
          </div>
        </div>
        <Separator />
        <div className="detailed">
          <SimpleText bold fontColor={FONT_COLOR.COLOR_16}>
            {selectedGrupo ? 'Informações do grupo' : 'Informações paciente'}
          </SimpleText>

          <Separator spaceOnly vertical />

          <div className="dist-holder">
            <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
              {selectedGrupo ? 'Apelido' : 'Nome'}
            </SimpleText>

            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {paciente?.nome ?? agendamentoRapidoNome}

              {selectedGrupo?.apelido}
            </SimpleText>
          </div>

          <Separator spaceOnly vertical />

          <div className="dist-holder">
            <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
              {selectedGrupo ? 'Qtd. de pacientes' : 'Contato'}
            </SimpleText>

            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {contato || ''}

              {selectedGrupo?.grupoAtendimentoPacientes.length}
            </SimpleText>
          </div>
        </div>

        <Separator />

        <SimpleText bold fontColor={FONT_COLOR.COLOR_16}>
          Outras informações
        </SimpleText>
        <Separator spaceOnly vertical />
        {!!agendamentoRapidoNome && (
          <ul style={{ listStylePosition: 'inside' }}>
            <SimpleText fontColor={FONT_COLOR.COLOR_16}>
              <li>Reserva de horário</li>
            </SimpleText>
          </ul>
        )}
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {observacao || ''}
        </SimpleText>
        {!agendamentoRapidoNome && (
          <Separator type={!recorrencia ? 'dashed' : 'solid'} />
        )}
        {recorrencia && (
          <div className="detailed">
            <SimpleText bold fontColor={FONT_COLOR.COLOR_16}>
              Recorrência
            </SimpleText>

            <Separator spaceOnly vertical />

            <div className="dist-holder">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
              >
                Frequência
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {tipoOcorrencia === 'DIARIA'
                  ? 'Diária'
                  : tipoOcorrencia === 'SEMANAL'
                  ? 'Semanal'
                  : tipoOcorrencia === 'MENSAL'
                  ? 'Mensal'
                  : ''}
              </SimpleText>
            </div>
            <div className="dist-holder p-mt-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
              >
                Hora
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {horarioRecorrencia
                  ? dayjs(horarioRecorrencia).format('HH:mm')
                  : '-'}
              </SimpleText>
            </div>
            <div className="dist-holder p-mt-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
              >
                Período
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                A cada {periodoRecorrencia}
              </SimpleText>
            </div>
          </div>
        )}

        {!agendamentoRapidoNome && (
          <>
            <Separator spaceOnly={!recorrencia} />
            <div className="detailed">
              <SimpleText bold fontColor={FONT_COLOR.COLOR_16}>
                Cobrança
              </SimpleText>

              <Separator spaceOnly vertical />

              <div className="dist-holder">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XS}
                >
                  Convenio
                </SimpleText>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XXS}
                >
                  {planoSelected?.nomeFantasia ||
                    capitalizeFirstLetter(planoSelected)}
                </SimpleText>
              </div>
              <div className="dist-holder p-mt-3 p-gap-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XS}
                >
                  Serviço
                </SimpleText>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XXS}
                >
                  {servicoTussSelected?.nomeCodigoServicoTuss || ''}
                </SimpleText>
              </div>
              {servicoTussSelected?.servicoTussValor && (
                <div className="dist-holder p-mt-3">
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.SM}
                    medium
                  >
                    Valor
                  </SimpleText>
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}
                  >
                    {transformarEmMoeda(
                      servicoTussSelected?.servicoTussValor,
                    ) || ''}
                  </SimpleText>
                </div>
              )}
            </div>
          </>
        )}

        <Separator spaceOnly vertical />

        {recorrencia ? (
          <Button
            label="Concluir"
            disabled={
              selectedGrupo ? !horario : !paciente || !planoSelected || !horario
            }
            onClick={handleSubmit(handleAgendamentoRecorrente)}
            loading={isSubmitting}
            stretch
          />
        ) : (
          <Button
            label={!!agendamentoRapidoNome ? 'Salvar reserva' : 'Concluir'}
            disabled={getIsDisabled()}
            onClick={handleAgendamento}
            loading={isSubmitting}
            stretch
          />
        )}
      </ElevatedCard>
    </div>
  );
};
