import { memo, useCallback, useMemo, useRef, useState } from 'react';

import { useSubscription } from 'react-stomp-hooks';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { AgendamentoActions } from '../_utils/actions';
import { generateUniqueId } from 'src/utils/utils';

import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import SplitButton from 'src/components/SplitButton/SplitButton';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ChipAgendamento from 'src/pages/Emed/Agenda/MenuAgenda/ChipAgendamento/ChipAgendamento';

import { useAgenda } from '../../../AgendaContext';
import ModalCardPaciente from '../../ModalCardPaciente/ModalCardPaciente';
import Status from '../../Status/Status';
import CardAgendamento3Dias from '../CardAgendamento3Dias/CardAgendamento3Dias';
import CardAgendamentoSemana from '../CardAgendamentoSemana/CardAgendamentoSemana';

import './CardAgendamento.scss';

interface CardAgendamentoProps {
  agendamento: AtendimentoAgendamento;
  selectedDate: Date;
  checkbox: boolean;
  sigilo: JSX.Element;
  visualizacaoAgenda?: 'AGENDA_MEDICO' | 'AGENDA_COMPLETA';
  getGrupo?: () => void;
  setAgendamentosSelected?: React.Dispatch<React.SetStateAction<any[]>>;
  isGerenciamentoGrupo?: boolean;
}

export const CardAgendamento = ({
  agendamento,
  selectedDate,
  checkbox,
  sigilo,
  getGrupo,
  isGerenciamentoGrupo,
}: CardAgendamentoProps) => {
  const { isTablet, isMobile, windowInnerWidth } = useSize();
  const {
    dialogEditarPaciente,
    refetchAgenda,
    isBarraLateralOpened,
    selectAll,
    setSelectedAgendamentos,
    selectedAgendamentos,
  } = useAgenda();
  const timerRef = useRef<NodeJS.Timeout>();

  const [isOnline, setIsOnline] = useState(false);
  const [checked, setChecked] = useState(() =>
    selectedAgendamentos.includes(agendamento),
  );

  const atendimento = agendamento;
  const isCustomSize = useMemo(
    () => windowInnerWidth <= 960,
    [windowInnerWidth],
  );

  const uid = generateUniqueId();

  useSubscription('/user/topic/notificacao', (message: any) => {
    const data = JSON.parse(message.body);
    const idAtendimento = data?.dadosComplementares?.idAtendimento;

    if (idAtendimento && Number(idAtendimento) === atendimento.id) {
      setIsOnline(true);
      if (getGrupo) getGrupo();

      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        setIsOnline(false);
      }, 15000);
    }
  });

  const isAtendimentoAtual = useMemo(() => {
    return (
      dayjs(atendimento.dataAgendamento).format('DD/MM/YYYY') ===
      dayjs().format('DD/MM/YYYY')
    );
  }, [atendimento]);

  const renderChips = useCallback(() => {
    return (
      <div className="upper-middle">
        {atendimento?.flagsAtivas?.map(item => (
          <ChipAgendamento key={item} type={item} />
        ))}
        {isOnline &&
          atendimento?.flagsAtivas?.includes('TELECONSULTA') &&
          isAtendimentoAtual && (
            <div className="paciente-online-chip">
              <SimpleText>Paciente Online</SimpleText>
            </div>
          )}
      </div>
    );
  }, [atendimento?.flagsAtivas, isAtendimentoAtual, isOnline]);

  const handleAlterarStatus = useCallback(
    async (atendimento, novoStatus) => {
      let response;
      try {
        response = await AgendamentosAPI.alterarStatusAgenda({
          ...atendimento,
          status: novoStatus,
        });

        if (response?.status === 200) {
          refetchAgenda();
          if (getGrupo) getGrupo();
        }
      } catch {}
    },
    [getGrupo, refetchAgenda],
  );

  const isAgendamentoGrupo = useMemo((): boolean => {
    if (agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo)
      return true;
    return false;
  }, [agendamento.idAtendimentoEmGrupo, agendamento.idGrupoAtendimento]);

  const renderChipsInfo = useCallback(() => {
    return (
      <>
        {renderChips()}

        {isAgendamentoGrupo && (
          <div className="bottom-middle">
            <SimpleText
              bold
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.PRIMARY}
            >
              {agendamento.apelidoGrupo}
            </SimpleText>

            <SimpleText
              className="p-ml-2"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              • {agendamento.quantidadePaciente} pacientes
            </SimpleText>
          </div>
        )}

        {agendamento?.paciente?.nome ? (
          <div className="bottom-middle">
            <SplitButton
              label={agendamento?.paciente?.nome || ''}
              tooltip={agendamento?.paciente?.nome || ''}
              className="modal-card-paciente"
              model={[
                {
                  template: (
                    <ModalCardPaciente
                      paciente={agendamento?.paciente}
                      setExibirModal={() =>
                        dialogEditarPaciente.open({
                          state: agendamento?.paciente,
                        })
                      }
                    />
                  ),
                },
              ]}
              menuClassName="menu-card-paciente"
            />
          </div>
        ) : (
          <div className="bottom-middle">
            <SimpleText fontColor={FONT_COLOR.PRIMARY} medium>
              {agendamento?.agendamentoRapidoNome}
            </SimpleText>
          </div>
        )}
      </>
    );
  }, [
    renderChips,
    isAgendamentoGrupo,
    agendamento.apelidoGrupo,
    agendamento.quantidadePaciente,
    agendamento?.paciente,
    dialogEditarPaciente,
    agendamento?.agendamentoRapidoNome,
  ]);

  const handleCheckboxSelection = useCallback(
    (agendamento: AtendimentoAgendamento, event?: boolean) => {
      if (event) {
        setChecked(true);
        setSelectedAgendamentos(curr => [...curr, agendamento]);
      } else {
        setChecked(false);
        setSelectedAgendamentos(curr =>
          curr.filter(ag => ag.dataAgendamento !== agendamento.dataAgendamento),
        );
      }
    },
    [setSelectedAgendamentos],
  );

  useEffectSkipFirst(() => {
    if (selectAll) {
      handleCheckboxSelection(agendamento, true);
    } else {
      handleCheckboxSelection(agendamento, false);
    }
  }, [agendamento, handleCheckboxSelection, selectAll]);

  const commonPropsCardAgendamento: any = {
    agendamento,
    selectedDate,
    sigilo,
    isOnline,
    renderActions: (
      <AgendamentoActions
        agendamento={agendamento}
        selectedDate={selectedDate}
        getGrupo={getGrupo}
        handleAlterarStatus={handleAlterarStatus}
        sigilo={sigilo}
        from={isGerenciamentoGrupo ? 'GERENCIAR' : undefined}
      />
    ),
  };

  if (isCustomSize && isBarraLateralOpened)
    return <CardAgendamentoSemana {...commonPropsCardAgendamento} />;
  if (isMobile)
    return <CardAgendamentoSemana {...commonPropsCardAgendamento} />;
  if (isTablet) return <CardAgendamento3Dias {...commonPropsCardAgendamento} />;

  const canCheck =
    checkbox &&
    (agendamento.idBloqueioAgenda || agendamento.status === 'LIVRE');

  return (
    <>
      <div
        className={`CardAgendamento animationFadeRight p-d-flex p-flex-column p-jc-center
        ${
          atendimento?.status === 'BLOQUEADO'
            ? 'card-bloqueado'
            : atendimento?.status === 'LIVRE'
            ? 'card-livre'
            : ''
        }
        ${agendamento.checkbox ? 'border-green' : ''}
        `}
      >
        <div className="p-d-flex p-jc-around p-ai-center p-w-100 p-mb-2">
          <div className="left">
            {canCheck && (
              <Checkbox
                className="p-mr-1"
                name="checkbox"
                checked={checked}
                onChange={e => handleCheckboxSelection(agendamento, e.checked)}
              />
            )}

            <Status
              agendamento={agendamento}
              selectedDate={selectedDate}
              getGrupo={getGrupo}
            />

            <Separator spaceOnly />
            <SimpleText fontSize={FONT_SIZE.SM} fontColor={FONT_COLOR.COLOR_40}>
              {agendamento.dataAgendamento?.split('T')[1]
                ? agendamento.dataAgendamento?.split('T')[1].slice(0, 5)
                : ''}
            </SimpleText>
            <Separator layout={'vertical'} />
          </div>

          <div className="middle">
            {atendimento?.status !== 'BLOQUEADO' ? renderChipsInfo() : <></>}

            {atendimento?.status === 'BLOQUEADO' &&
              !atendimento.id &&
              atendimento?.observacao && (
                <div className="bottom-middle">
                  <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                    {atendimento.observacao}
                  </SimpleText>
                </div>
              )}
          </div>

          <div className="right">
            <AgendamentoActions
              agendamento={agendamento}
              selectedDate={selectedDate}
              getGrupo={getGrupo}
              handleAlterarStatus={handleAlterarStatus}
              sigilo={sigilo}
              from={isGerenciamentoGrupo ? 'GERENCIAR' : undefined}
            />
          </div>
        </div>
        {!!atendimento?.observacao?.trim() && (
          <div className="p-d-flex border-top p-p-1">
            <SimpleText
              className="p-mr-2"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_79}
            >
              Observação:
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              className={`observacao-agendamento-${uid} cursor-pointer`}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Tooltip
                target={`.observacao-agendamento-${uid}`}
                //className="p-w-100"
                autoHide={false}
                position="bottom"
              >
                {atendimento.observacao}
              </Tooltip>
              {atendimento.observacao}
            </SimpleText>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(CardAgendamento);
