import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

interface BaseFieldProps {
  label: string;
  mask?: string | null | undefined;
  convenioFieldName?: string;
  name?: string;
  className?: string;
  editPlanoSaude?: any;
}

type FieldNumeroCartaoConvenioProps = RequireAtLeastOne<
  BaseFieldProps,
  'convenioFieldName' | 'mask'
>;

export const FieldNumeroCartaoConvenio = (
  props: FieldNumeroCartaoConvenioProps,
) => {
  const {
    mask,
    convenioFieldName,
    label,
    editPlanoSaude,
    name = 'numeroCartao',
    className = 'p-col-12 p-sm-6',
  } = props;

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();

  const convenio = watch(convenioFieldName || '');

  useEffect(() => {
    if (editPlanoSaude?.numeroCartao) {
      setValue(name, editPlanoSaude.numeroCartao);
      register(name, { value: editPlanoSaude.numeroCartao });
    }
  }, [editPlanoSaude, name, register, setValue]);

  const numeroCartaoMask = convenio?.mascaraCarterinha || mask;

  return numeroCartaoMask ? (
    <MaskedInputControlled
      className={className}
      control={control}
      name={name}
      label={label}
      placeholder={numeroCartaoMask}
      mask={numeroCartaoMask}
      maxLength={30}
      errorMsg={errors?.[name]?.message}
    />
  ) : (
    <TextInputControlled
      className={className}
      control={control}
      name={name}
      label={label}
      maxLength={30}
      errorMsg={errors?.[name]?.message}
      value={editPlanoSaude?.numeroCartao || ''}
    />
  );
};
