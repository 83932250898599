import { useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { tipoUsuario } from './utils';
import { checkAuth } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

export const AssistenteVirtualRow = ({ item, reloadList }: any) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const [isActive, setIsActive] = useState<boolean>(item.ativo);
  const { windowInnerWidth } = useSize();

  const navigate = useNavigate();
  const ref = useRef<TieredMenu>(null);

  const isTablet = useMemo(() => windowInnerWidth <= 999, [windowInnerWidth]);

  const handleInativar = async () => {
    try {
      setIsActive(old => !old);

      await AssistenciaAPI.mudarStatusAssistencia(item.id, {
        throwError: true,
        hideToast: true,
      });
    } catch {
      setIsActive(old => !old);
    }
  };

  const handleDelete = async () => {
    try {
      await AssistenciaAPI.deleteAssistencia(item.id, {
        throwError: true,
      });

      reloadList();
    } catch {}
  };

  const menuItens = [
    {
      label: 'Editar',
      command: () => navigate(`/cadastros/assistente-virtual/${item.id}`),
      checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_ALTERAR',
    },
    {
      label: 'Excluir',
      command: handleDelete,
      className: 'error',
      checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_EXCLUIR',
    },
  ];

  const menuItensFiltered = menuItens.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  return (
    <div className="row-component p-col-12 p-d-flex p-ai-center p-my-2">
      <div className="p-col-2 p-xl-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Nome da assistência
        </SimpleText>
        <SimpleText className="p-text-climp-2">{item.nome}</SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Tela de apresentação
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item?.funcionalidade?.descricao || '-'}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Tipo de usuário
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.tipoUsuario ? tipoUsuario[item.tipoUsuario] : '-'}
        </SimpleText>
      </div>
      <div className="p-col-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Criado por
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.usuarioInclusao?.nome || '-'}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data da criação
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {dayjs(item?.dataInclusao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-2 p-lg-1 p-lg p-d-flex p-flex-column p-gap-1">
        {item?.ajudaDeTela && (
          <span className="tag tag-sm tag-tonal">Ajuda de tela</span>
        )}
        {item?.novidade && (
          <span className="tag tag-sm tag-tonal">Novidade</span>
        )}
        {item?.primeiroAcesso && (
          <span className="tag tag-sm tag-tonal">Primeiro acesso</span>
        )}
      </div>
      <div className="p-col">
        <Switch
          checked={isActive}
          showStatus={!isTablet}
          onChange={() => handleInativar()}
          checkPermission="ADM_ASSISTENTE_VIRTUAL_CADASTRO_ALTERAR"
        />
      </div>
      <Button
        btnType="gray"
        icon="fas fa-ellipsis"
        className="p-col-1"
        onClick={e => ref.current?.toggle(e)}
        disabled={!menuItensFiltered.length}
      />

      <TieredMenu ref={ref} popup model={menuItensFiltered} />
    </div>
  );
};

//? Mobile - 800px
export const AssistenteVirtualRowMobile = ({ item, reloadList }: any) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const [isActive, setIsActive] = useState<boolean>(item.ativo);

  const navigate = useNavigate();
  const ref = useRef<TieredMenu>(null);

  const handleInativar = async () => {
    try {
      setIsActive(old => !old);

      await AssistenciaAPI.mudarStatusAssistencia(item.id, {
        throwError: true,
        hideToast: true,
      });
    } catch {
      setIsActive(old => !old);
    }
  };

  const handleDelete = async () => {
    try {
      await AssistenciaAPI.deleteAssistencia(item.id, {
        throwError: true,
      });

      reloadList();
    } catch {}
  };

  const menuItens = [
    {
      label: 'Editar',
      command: () => navigate(`/cadastros/assistente-virtual/${item.id}`),
      checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_ALTERAR',
    },
    {
      label: 'Excluir',
      command: handleDelete,
      className: 'error',
      checkPermission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_EXCLUIR',
    },
  ];

  const menuItensFiltered = menuItens.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  return (
    <div className="row-component p-col-12 p-grid p-ai-center p-my-2">
      <div className="p-col-12 p-sm-6">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Nome da assistência
        </SimpleText>
        <SimpleText className="p-text-climp-2">{item.nome}</SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Tela de apresentação
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item?.funcionalidade?.descricao || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Tipo de usuário
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.tipoUsuario ? tipoUsuario[item.tipoUsuario] : '-'}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Criado por
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {item.usuarioInclusao?.nome || '-'}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data da criação
        </SimpleText>
        <SimpleText className="p-text-climp-2">
          {dayjs(item?.dataInclusao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-lg-1 p-lg p-d-flex p-flex-column p-jc-center p-gap-1">
        {item?.ajudaDeTela && (
          <span className="tag tag-sm tag-tonal">Ajuda de tela</span>
        )}
        {item?.novidade && (
          <span className="tag tag-sm tag-tonal">Novidade</span>
        )}
        {item?.primeiroAcesso && (
          <span className="tag tag-sm tag-tonal">Primeiro acesso</span>
        )}
      </div>
      <div className="p-col-6 p-sm-3">
        <Switch
          checked={isActive}
          showStatus
          onChange={() => handleInativar()}
        />
      </div>
      <Button
        btnType="gray"
        icon="fas fa-ellipsis"
        className="p-col-6 p-ml-auto"
        onClick={e => ref.current?.toggle(e)}
        disabled={!menuItensFiltered?.length}
      />

      <TieredMenu ref={ref} popup model={menuItensFiltered} />
    </div>
  );
};
