import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';

import { ModalAnexarOpme } from './ModalAnexarOpme/ModalAnexarOpme';

interface AnexoOpmeProps {
  hasAnexoOpme: boolean;
  idProcedimento: number;
  numeroGuia: number;
}

export const AnexoOpme = (props: AnexoOpmeProps) => {
  const { hasAnexoOpme, idProcedimento, numeroGuia } = props;

  const modalAnexarOpme = useDisclosure({ opened: false });

  if (!hasAnexoOpme) return null;

  return (
    <>
      <div id="anexo-opme-btn" className="p-col-12">
        <Button
          type="button"
          btnType="tonal"
          label="Anexo opme"
          onClick={() =>
            modalAnexarOpme.open({
              state: { idProcedimento, numeroGuia },
            })
          }
        />
      </div>
      {modalAnexarOpme.isOpen && <ModalAnexarOpme {...modalAnexarOpme} />}
    </>
  );
};
