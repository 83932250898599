import Http, { HttpParams } from 'src/core/http/Http';

const loadAplicacoesFormulario = async (
  params: {
    idFormularioDinamico?: number;
    nome?: string;
    dataInicioVigencia?: string;
    dataFimVigencia?: string;
    publico?: string;
    status?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IAplicacaoFormularioDinamicoItem[]>> =>
  Http.get({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico/pesquisar`,
    params,
    hideToast: true,
    ...options,
  });
const loadAplicacaoFormularioById = async (
  params: {
    id: number | string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IAplicacaoFormularioDinamicoItem> =>
  Http.get({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico/${params.id}`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
const createAplicacaoFormulario = async (
  payload: IAplicacaoFormularioDinamicoPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IAplicacaoFormularioDinamicoItem> =>
  Http.post({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico`,
    data: payload,
    throwError: true,
    ...options,
  });
const updateAplicacaoFormulario = async (
  payload: Omit<IAplicacaoFormularioDinamicoItem, 'formularioDinamico'>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico/${payload.id}`,
    data: payload,
    throwError: true,
    ...options,
  });
const deleteAplicacaoFormulario = async (
  params: { id: number | string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico/${params.id}`,
    throwError: true,
    ...options,
  });

const loadAplicacaoPublico = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico/publico`,
    hideToast: true,
    ...options,
  });
const loadAplicacaoStatus = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/aplicacao-formulario-dinamico/situacao`,
    hideToast: true,
    ...options,
  });

export const AplicacaoFormularioDinamicoAPI = {
  loadAplicacoesFormulario,
  loadAplicacaoFormularioById,
  createAplicacaoFormulario,
  updateAplicacaoFormulario,
  deleteAplicacaoFormulario,
  loadAplicacaoPublico,
  loadAplicacaoStatus,
};
