import { memo, useCallback } from 'react';

import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalExcluirValoresServico.scss';
import { Button } from 'src/components/_UI/Button';

interface ModalExcluirValoresServicoProps {
  visible: boolean;
  onHide(): void;
  reload(): void;
  valorServico: any;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Não, cancelar"
        onClick={onCancel}
        stretch
      />
      <Button btnType="danger" label="Sim, excluir" onClick={onAdd} stretch />
    </div>
  );
});

const ModalExcluirValoresServico = ({
  valorServico,
  visible,
  reload,
  onHide,
}: ModalExcluirValoresServicoProps) => {
  const handleDeletarValorServico = useCallback(async () => {
    const response = await ServicoTussValorAPI.removeServicoTussValor(
      valorServico.id!,
    );

    if (response?.status && response?.status >= 200 && response?.status < 300) {
      reload();
      onHide();
    }
  }, [valorServico.id, reload, onHide]);

  const handleCancelValoresSerivo = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Excluir cadastro de valor de serviço"
      visible={visible}
      onHide={handleCancelValoresSerivo}
      footer={() => (
        <Footer
          onAdd={handleDeletarValorServico}
          onCancel={handleCancelValoresSerivo}
        />
      )}
    >
      <div className="modal-body-excluir-valores-servico">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Ao excluir o valor do serviço, ele deixará de ser exibido no módulo
          financeiro. Você confirma a ação?
        </SimpleText>
      </div>
    </Dialog>
  );
};

export default ModalExcluirValoresServico;
