import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

const Header = () => {
  return (
    <div className="p-grid header p-pb-0">
      <div className="p-col-7 p-grid p-pb-0">
        <div className="p-col-4  p-pb-0">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Paciente / CPF
          </SimpleText>
        </div>
        <div className="p-col-3  p-pb-0">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Número cartão
          </SimpleText>
        </div>
        <div className="p-col-2  p-pb-0">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Data de nascimento
          </SimpleText>
        </div>
        <div className="p-col-3  p-pb-0">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome da mãe
          </SimpleText>
        </div>
      </div>
      <div className="p-col-5 p-grid p-pb-0">
        <div className="p-col-7 p-grid p-pb-0">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className="p-col"
          >
            Código
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className="p-col"
          >
            Tipo
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className="p-col"
          >
            Sexo
          </SimpleText>
        </div>
        <div className="p-col-5  p-pb-0">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Data ultimo atendimento
          </SimpleText>
        </div>
      </div>
    </div>
  );
};

export default Header;
