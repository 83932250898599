import React from 'react';
import { useContext } from 'react';

import {
  Dialog as PrimeDialog,
  DialogProps as PrimeDialogProps,
} from 'primereact/dialog';

import useSize from 'src/core/hooks/useSize';
import ThemeContext from 'src/core/themes/ThemeContext';

import './Dialog.scss';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

type DialogTypes = 'modal' | 'dialog';

export interface DialogProps extends PrimeDialogProps {
  children?: JSX.Element;
  showDismissWithoutHeader?: boolean;
  type?: DialogTypes;
  maximizedTabletSize?: boolean;
  maximizedMobileSize?: boolean;
}

const Dialog = ({
  children,
  className,
  showDismissWithoutHeader,
  type = 'dialog',
  maximizedTabletSize = false,
  maximizedMobileSize = false,
  ...rest
}: DialogProps) => {
  const { theme } = useContext(ThemeContext);

  const { isMobile, isTablet } = useSize();

  const isMaximized =
    (maximizedTabletSize && isTablet) || (maximizedMobileSize && isMobile);

  return (
    <div className={`Dialog ${theme || ''}`}>
      <PrimeDialog
        className={`${className || ''} ${theme || ''} ${
          isMaximized ? 'p-dialog-maximized' : ''
        } ${type === 'modal' ? 'p-dialog-modal' : ''} ${
          !!rest?.footer ? 'footer-on' : ''
        }`}
        blockScroll
        {...rest}
      >
        {showDismissWithoutHeader && (
          <div className="p-dialog-header-icons dialog-dismiss-without-header">
            <button
              type="button"
              className="p-dialog-header-icon p-dialog-header-close p-link"
              aria-label="Close"
              onClick={() => rest.onHide()}
            >
              <span className="p-dialog-header-close-icon pi pi-times"></span>
            </button>
          </div>
        )}
        <ErrorBoundary>{children || <React.Fragment />}</ErrorBoundary>
      </PrimeDialog>
    </div>
  );
};

export default Dialog;
