import { Skeleton } from 'primereact/skeleton';

const AgendaDiaSekeleton = () => (
  <>
    <Skeleton height="80px" borderRadius="8px"/>
    <Skeleton height="80px" borderRadius="8px"/>
    <Skeleton height="80px" borderRadius="8px"/>
  </>
);

export default AgendaDiaSekeleton;