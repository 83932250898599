import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {};
export const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setInvalidateQuery: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    resetInvalidateQuery: () => initialState,
  },
});

export const { setInvalidateQuery, resetInvalidateQuery } = querySlice.actions;

export default querySlice.reducer;
