import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import { AvaliacaoFormularioDinamicoAPI } from 'src/APIs/ProntuarioAPI/AvaliacaoFormularioDinamicoAPI/AvaliacaoFormularioDinamicoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DialogHistorico } from './components/DialogHistorico';
import { Button } from 'src/components/_UI';
import Card from 'src/components/Basics/Card/Card';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { DialogInativacao } from 'src/components/DialogInativacao/DialogInativacao';

import { useFormulario } from './useFormulariosContext';

export const FormularioHistorico = () => {
  const {
    prontuario,
    historicoAvaliacoes: items,
    handleRefetch,
    setCopyFormData,
    selectedAplicacaoFormulario,
  } = useFormulario();

  const menu = useRef<TieredMenu>(null);
  const dialogInativar = useDisclosure({ opened: false });
  const dialogHistorico = useDisclosure({ opened: false });

  return (
    <Card>
      <div className="Formularios-historico p-d-flex p-flex-column p-gap-2 p-p-1">
        <SimpleText fontSize="md" medium>
          Histórico do formulário
        </SimpleText>

        {items.length > 0 &&
          items.map(item => {
            const hasParecer = item.pontuacao;
            const isInativo = !item.ativo;
            const isSameProfessional =
              item.idProfissionalSaude === prontuario?.atendimento.medico.id;
            const canEdit =
              item.idAtendimento === prontuario?.atendimento.id &&
              isSameProfessional;

            return (
              <div
                key={item.id}
                className="row content-row p-ai-center p-flex-wrap form-item"
                data-inactive={isInativo}
              >
                <SimpleText
                  fontColor="primary"
                  fontSize="sm"
                  className="p-col-8 cursor-pointer"
                  onClick={() =>
                    dialogHistorico.open({
                      state: item,
                    })
                  }
                  medium
                >
                  {item.aplicacaoFormularioDinamico.nome}
                </SimpleText>
                <div className="p-col-4 p-d-flex p-gap-2 p-jc-end p-ai-center">
                  {isInativo ? (
                    <Button
                      icon="fas fa-eye"
                      btnType="ghost"
                      onClick={() =>
                        dialogInativar.open({
                          state: { isView: true, ...item },
                        })
                      }
                    />
                  ) : (
                    <>
                      {selectedAplicacaoFormulario?.situacao === 'ATIVO' && (
                        <Button
                          icon="fas fa-copy"
                          btnType="ghost"
                          onClick={() => setCopyFormData(item)}
                        />
                      )}

                      {!canEdit &&
                        isSameProfessional &&
                        selectedAplicacaoFormulario?.situacao === 'ATIVO' && (
                          <Button
                            icon="fas fa-ellipsis-h"
                            btnType="ghost"
                            onClick={e => menu.current?.toggle(e)}
                          />
                        )}

                      <TieredMenu
                        ref={menu}
                        popup
                        model={[
                          {
                            label: 'Inativar',
                            command: () => dialogInativar.open({ state: item }),
                            className: 'error',
                          },
                        ]}
                        style={{
                          width: '6rem',
                        }}
                      />
                    </>
                  )}
                </div>

                <div className="p-col-12 p-d-flex">
                  <SimpleText>
                    <SimpleText fontColor="color_60">Preenchido: </SimpleText>
                    <SimpleText medium>
                      {item.profissionalSaude.usuario.nome} •{' '}
                      {!!item.profissionalSaude.codigoConselho &&
                        `${item.profissionalSaude.codigoConselho}/${item.profissionalSaude.ufConselho} ${item.profissionalSaude.numeroRegistro}`}
                    </SimpleText>
                    <SimpleText fontColor="color_60"> em </SimpleText>
                    <SimpleText>
                      {dayjs(
                        item.dataAtualizacao
                          ? item.dataAtualizacao
                          : item.dataInclusao,
                      ).format('DD/MM/YYYY [às] HH:mm')}
                    </SimpleText>
                  </SimpleText>
                </div>

                {hasParecer && (
                  <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                    <SimpleText fontColor="color_60">Parecer: </SimpleText>
                    <SimpleText medium fontSize="xs">
                      {item.pontuacao?.nome}
                    </SimpleText>
                    <SimpleText>{item.pontuacao?.mensagemPaciente}</SimpleText>
                  </div>
                )}
              </div>
            );
          })}

        {!items.length && (
          <div className="row content-row p-jc-center p-p-4 p-my-2">
            <SimpleText fontSize="sm" medium>
              Nenhum histórico encontrado para esse paciente.
            </SimpleText>
          </div>
        )}

        {dialogInativar.isOpen && (
          <DialogInativacao
            {...dialogInativar}
            api={
              AvaliacaoFormularioDinamicoAPI.inativarAvaliacaoFormularioDinamico
            }
            description="Você está inativando este histórico de formulário. Ele continuará ser exibido como uma informação inativa. Para continuar, justifique."
            isView={dialogInativar.state?.isView}
            item={dialogInativar.state}
            onSuccess={handleRefetch}
          />
        )}
        {dialogHistorico.isOpen && (
          <DialogHistorico {...dialogHistorico} item={dialogHistorico.state} />
        )}
      </div>
    </Card>
  );
};
