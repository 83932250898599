import { useEffect, useState } from 'react';

import axios, { AxiosResponse } from 'axios';

export const useIPAdress = () => {
  //creating IP state
  const [IPv4, setIP] = useState('');

  //creating function to load ip address from the API
  const getData = async () => {
    try {
      const res: AxiosResponse<any> = await axios.get(
        'https://geolocation-db.com/json/',
      );
      setIP(res.data.IPv4);
    } catch {}
  };

  useEffect(() => {
    getData();
  }, []);

  return { IPv4 };
};
