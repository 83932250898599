import React from 'react';

import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  documento: any;
}

export const PanelContent = ({ documento }: PanelContentProps) => {
  const headerTextClassName = `p-mb-2 ${
    documento.status === 'INATIVO' ? 'documento-inativo' : ''
  }`;
  const dataTextClassName =
    documento.status === 'INATIVO' ? 'line-through documento-inativo' : '';

  return (
    <div className="p-grid documentos-panel-content">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}>
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium>
          {documento.descricao}
        </SimpleText>
      </div>
      
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{documento.usuarioInclusaoNome}</b> em {dayjs(documento.dataAtualizacao).locale('PT-br').format('DD MMM. YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
};
