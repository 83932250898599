import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/avaliacao-risco';

const loadParametros = async (idSexo: number) => {
  const promiseList = [
    getParametrosTipo(idSexo, 'idade'),
    getParametrosTipo(idSexo, 'ldl'),
    getParametrosTipo(idSexo, 'hdl'),
  ];

  return Promise.all(promiseList);
};

const loadCalculoRisco = async (form: FormDataAvaliacaoRisco) => {
  const {
    idAtendimento,
    idUsuarioAlteracao,
    idEmpresa,
    dataOcorrencia,
    justificativaOcorrenciaRetroativa,
    idSexo,
    idade,
    hdlColesterol,
    ldlColesterol,
    diabetes,
    tabagismo,
    sistolica,
    diastolica,
    unidadeMedida,
  } = form;

  const data: DataCalculoRisco = {
    dataOcorrencia: dataOcorrencia?.toISOString(),
    justificativaOcorrenciaRetroativa,
    idAtendimento,
    idUsuarioAlteracao,
    idEmpresa,
    idSexo,
    escoreFraminghanIdade: { id: idade },
    escoreFraminghanHdl: { id: hdlColesterol },
    escoreFraminghanLdl: { id: ldlColesterol },
    escoreFraminghanPa: {
      sistolica: Number.parseFloat(sistolica),
      diastolica: Number.parseFloat(diastolica),
      unidadeMedidaPa: unidadeMedida,
    },
    diabetes,
    tabagismo,
  };

  return calcularRisco(data);
};

const salvarCalculoRisco = async (
  form: FormDataAvaliacaoRisco,
  editAvaliacao?: any,
) => {
  const {
    idAtendimento,
    idUsuarioAlteracao,
    idEmpresa,
    dataOcorrencia,
    justificativaOcorrenciaRetroativa,
    idSexo,
    idade,
    hdlColesterol,
    ldlColesterol,
    diabetes,
    tabagismo,
    sistolica,
    diastolica,
    unidadeMedida,
  } = form;

  const {
    id,
    idEscoreFraminghanIdade,
    idEscoreFraminghanPa,
    idPorcentagemAvalRisco,
  } = editAvaliacao || {};

  const data: DataCalculoRisco = {
    dataOcorrencia: dataOcorrencia?.toISOString(),
    justificativaOcorrenciaRetroativa,
    idAtendimento,
    idUsuarioAlteracao,
    idEmpresa,
    idSexo,
    escoreFraminghanIdade: { id: idade },
    escoreFraminghanHdl: { id: hdlColesterol },
    escoreFraminghanLdl: { id: ldlColesterol },
    escoreFraminghanPa: {
      sistolica: Number.parseFloat(sistolica),
      diastolica: Number.parseFloat(diastolica),
      unidadeMedidaPa: unidadeMedida,
    },
    diabetes,
    tabagismo,
    ...(editAvaliacao && {
      idEscoreFraminghanHdl: hdlColesterol,
      idEscoreFraminghanLdl: ldlColesterol,
      idEscoreFraminghanIdade,
      idEscoreFraminghanPa,
      idPorcentagemAvalRisco,
    }),
  };

  if (editAvaliacao) return editarAvaliacaoRisco(id, data);

  return salvarAvaliacaoRisco(data);
};

export const getParametrosTipo = async (
  idSexo: number,
  tipoPonto: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/escore-framinghan/${idSexo}/${tipoPonto}`,
    hideToast: true,
    ...options,
  });
};

export const calcularRisco = async (
  data: DataCalculoRisco,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/calculo`,
    data,
    hideToast: true,
    ...options,
  });
};

export const salvarAvaliacaoRisco = async (
  data: DataCalculoRisco,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}`,
    data,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const editarAvaliacaoRisco = async (
  id: number,
  data: DataCalculoRisco,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${id}`,
    data,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const putLembreteAvaliacaoRisco = async (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/lembrete/${id}`,
    ...options,
  });
};

export const listAvaliacaoRisco = async (
  idAtendimento: any,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });
};

export const inativarAvaliacaoRisco = async (
  id: number,
  idAtendimento: any,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${id}/atendimento/${idAtendimento}/inativar`,
    return: true,
    data,
    ...options,
  });
};

const deletarAvaliacaoRisco = async (
  id: number,
  idAtendimento: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/${id}/atendimento/${idAtendimento}`,
    ...options,
  });
};

const AvaliacaoRiscoAPI = {
  loadParametros,
  loadCalculoRisco,
  salvarCalculoRisco,
  editarAvaliacaoRisco,
  putLembreteAvaliacaoRisco,
  listAvaliacaoRisco,
  inativarAvaliacaoRisco,
  deletarAvaliacaoRisco,
};

export default AvaliacaoRiscoAPI;
