import { useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';

import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';

import { useTelemedicina } from 'src/core/hooks/Telemedicina/useTelemedicina';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useIPAdress } from 'src/utils/hooks/useIPAdress';

import Button from 'src/components/Basics/Button/Buttons';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import './TermoAssinatura.scss';

export const TermoAssinatura = () => {
  const form = useForm();
  const navigate = useNavigate();
  const { idAtendimento: idAtendimentoParam } = useParams();

  const { state: codEmpresa } = useLocation();
  const { atendimentoData } = useTelemedicina();
  const { IPv4 } = useIPAdress();

  const idAtendimento = atendimentoData?.idAtendimento || idAtendimentoParam;

  const [isPrinting, setPrinting] = useState(false);

  const location = useRef<any>();

  const dialogTermoRecusado = useDisclosure({
    opened:
      atendimentoData?.telemedicinaLogTermo?.status === 'RECUSADO' || false,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(v => (location.current = v));
  }, []);

  useEffect(() => {
    if (!atendimentoData) {
      navigate(`/t/${codEmpresa}/${idAtendimento}/${idAtendimento}`);
    }
  }, [atendimentoData, codEmpresa, idAtendimento, navigate]);

  const onSubmit = (values: any) => {
    const geolocation = location.current
      ? `${location.current?.coords.latitude}, ${location.current?.coords.longitude}`
      : null;

    return TelemedicinaSalaAtendimentoAPI.sendTermoConsentimento(
      {
        idTermo: atendimentoData?.telemedicinaLogTermo.id,
        idAtendimento: idAtendimento,
      },
      {
        localRegistro: geolocation,
        status: values.ACEITE,
        ipConputador: IPv4,
        portaComputador: 0o0,
      },
    ).then(() => {
      if (values.ACEITE === 'ACEITO') {
        setTimeout(() => {
          navigate(`/t/${codEmpresa}/${idAtendimento}/${idAtendimento}`);
        }, 500);
      } else {
        setTimeout(() => {
          dialogTermoRecusado.open();
        }, 500);
      }
    });
  };

  const handleImprimir = () => {
    setPrinting(true);
    ReportAPI.relatorioTermoConsentimento({
      idLogEmail: atendimentoData?.telemedicinaLogTermo?.id,
    })
      .then(res => {
        const fileURL = URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf',
          }),
        );

        if (res.status === 200) {
          window.open(fileURL);
        }
      })
      .finally(() => setPrinting(false));
  };

  const isReaded = form.watch('DECLARACAO_LEITURA');
  const isCompleted = form
    .watch(['DECLARACAO_LEITURA', 'ACEITE'])
    .every(Boolean);

  return (
    <div className="termo-container">
      <div className="termo-head">
        <div className="termo-content">
          <p
            dangerouslySetInnerHTML={{
              __html:
                atendimentoData?.telemedicinaLogTermo
                  .termoConsentimentoTelemedicina.conteudo,
            }}
            className="p-p-4"
          />
        </div>
      </div>

      <div className="termo-options">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="p-d-flex p-flex-column"
          >
            <CheckboxControlled
              control={form.control}
              name="DECLARACAO_LEITURA"
              label="DECLARO QUE LI INTEGRALMENTE OS TERMOS DE ACIMA"
              className="p-col-12"
            />

            <RadioButtonGroupControlled
              control={form.control}
              name="ACEITE"
              dir="column"
              unstyled
              disabled={!isReaded}
              items={[
                {
                  label:
                    'Aceito e desejo confirmar o atendimento por telemedicina no dia e hora acima indicados.',
                  value: 'ACEITO',
                },
                {
                  label:
                    'Não aceito e estou ciente de que será cancelado o agendamento do atendimento de telemedicina no dia e hora acima indicados.',
                  value: 'RECUSADO',
                },
              ]}
            />

            <div className="p-col-12 p-d-flex p-gap-2 p-mb-2">
              <Button
                icon="fas fa-print"
                label="Imprimir termo"
                type="button"
                btnType="tonal"
                disabled={!isCompleted || form.formState.isSubmitting}
                loading={isPrinting}
                onClick={handleImprimir}
              />
              <Button
                label="Confirmar"
                type="submit"
                disabled={!isCompleted}
                loading={form.formState.isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>

      {dialogTermoRecusado.isOpen && (
        <DialogTermoRecusa {...dialogTermoRecusado} />
      )}
    </div>
  );
};

const DialogTermoRecusa = ({ isOpen, close }: DisclosureType) => {
  return (
    <Dialog
      header="Termo recusado!"
      visible={isOpen}
      onHide={close}
      closable={false}
    >
      <SimpleText>
        O termo atual não está mais disponível por ter sido recusado. <br />O
        atendimento foi cancelado.
      </SimpleText>
    </Dialog>
  );
};
