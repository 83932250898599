import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';


interface MobileRowProps {
  perfil: Perfil;
  onEdit: () => void;
  onDelete: () => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { perfil, onEdit, onDelete } = props;

  const options = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_PERFIL_ALTERAR',
    },
    {
      label: 'Remover',
      command: onDelete,
      checkPermission: 'ADM_PERFIL_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <SimpleText
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {perfil.descricao}
      </SimpleText>
    </SearchListMobileRow>
  );
};
