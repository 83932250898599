import { useCallback, useState } from 'react';

import { useLocation } from 'react-router';

import DocumentosAPI from 'src/APIs/ProntuarioAPI/DocumentosAPI/DocumentosAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import ModalNovo from './ModalNovo/ModalNovo';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

const Documentos = () => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus } = useAtendimento();

  const [updateList, setUpdateList] = useState<number>(0);
  const [editDocumento, setEditDocumento] = useState<Documentos | null>(null);
  const [documentoInativar, setDocumentoInativar] = useState<Documentos | null>(
    null,
  );
  const [modalNovoVisible, setModalNovoVisible] = useState<boolean>(false);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<Documentos | null>(null);

  const fetchAPI = useCallback(
    (params: any): Promise<any> => {
      return DocumentosAPI.getDocumentos(idAtendimento, {
        ...params,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
      });
    },
    [idAtendimento],
  );

  const onEdit = (documento: Documentos) => {
    setEditDocumento(documento);
    setModalNovoVisible(true);
  };

  const onDelete = async (documento: Documentos) => {
    await DocumentosAPI.deleteDocumentos(documento.id, Number(idAtendimento));
    reloadList();
  };

  const optionsMenu = (documento: Documentos) => (
    <PanelOptionsMenu
      hideLembreteOption
      ativo={documento.status === 'ATIVO'}
      idAtendimentoRegistro={documento.idAtendimento}
      idUsuarioCriacao={documento.idUsuarioInclusao}
      onEdit={() => onEdit(documento)}
      onInativar={() => setDocumentoInativar(documento)}
      onExcluir={() => onDelete(documento)}
      onVisualizarJustificativa={() => setVisualizarJustificativa(documento)}
      onAlterarLembrete={() => {}}
      hideEditOption={idAtendimento !== documento.idAtendimento}
    />
  );

  const renderRow = (documento: Documentos) => (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            documentos={documento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            optionsMenu={optionsMenu(documento)}
          />
        )}
      >
        <PanelContent documentos={documento} />
      </Panel>
    </div>
  );

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
  };

  const onHideModal = () => {
    setEditDocumento(null);
    setModalNovoVisible(false);
  };

  return (
    <div id="documentos-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center ">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Documentos
            </SimpleText>

            <Button
              type="button"
              btnType="tonal"
              label="Anexar documentos"
              icon="fas fa-plus"
              onClick={() => setModalNovoVisible(true)}
              disabled={
                atendimentoStatus !== 'ATENDENDO' &&
                atendimentoStatus !== 'ATENDENDO_TRIAGEM'
              }
            />
          </div>

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {modalNovoVisible && (
        <ModalNovo
          visible={modalNovoVisible}
          editDocumento={editDocumento}
          onHide={onHideModal}
          reloadList={reloadList}
        />
      )}

      {idAtendimento && (
        <ModalJustificativa
          documento={documentoInativar}
          visualizarJustificativa={visualizarJustificativa}
          onHide={() => {
            setDocumentoInativar(null);
            setVisualizarJustificativa(null);
          }}
          onSuccess={reloadList}
        />
      )}
    </div>
  );
};

export default Documentos;
