import { useState, useEffect, useCallback } from 'react';

import {
  UseFormSetValue,
  FieldValues,
  UseFormGetValues,
} from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

type Options = {
  label: string;
  value: number;
};

export interface CityData {
  cityOptions: Options[];
  handleStateSelect: (stateId: any) => void;
}

interface CityProps {
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}

export const useCity = ({ setValue, getValues }: CityProps): CityData => {
  const [cityOptions, setCityOptions] = useState<Options[]>([]);

  const handleStateSelect = (stateId: any) => {
    setValue('endereco.idCidade', '');

    getCitiesByState(stateId);
  };

  const getCitiesByState = useCallback(async (stateId: any) => {
    const citiesFromState: any = await UtilsAPI.GetCidades(stateId);

    if (Array.isArray(citiesFromState)) {
      const formattedCities = citiesFromState.map(city => ({
        label: city.nome,
        value: city.id,
      }));

      setCityOptions(formattedCities);
    }
  }, []);

  useEffect(() => {
    const estado = getValues('endereco.estado');

    if (!isNaN(estado) && estado) getCitiesByState(estado);
  }, [getValues, getCitiesByState]);

  return {
    cityOptions,
    handleStateSelect,
  };
};
