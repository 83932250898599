import { useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { AnexoOpmeAPI } from 'src/APIs/ProntuarioAPI/AnexoOpmeAPI/AnexoOpmeAPI';

import FormInput from 'src/components/FormInput/FormInput';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  index: number;
  servicoTuss: any;
  onDelete: (index: number) => void;
}

export const Row = (props: RowProps) => {
  const { index, servicoTuss, onDelete } = props;

  const { isMobile } = useSize();
  const {
    control,
    formState: { errors },
    unregister,
    getValues,
  } = useFormContext();

  const inputQuantidade = (
    <FormInput
      autoFocus
      className="light p-mr-2"
      name={`anexoOpmeServicoTuss.${index}.quantidade`}
      type="number"
      min={1}
      max={servicoTuss?.quantidadeMaxima}
      allowEmpty={false}
      hideTextErrorSpace
      editOnClick
    />
  );

  const fabricanteDropdown = (
    <DropdownControlled
      control={control}
      name={`anexoOpmeServicoTuss.${index}.idServicoTussFornecedorOpme`}
      options={servicoTuss.fornecedoresOptions}
      filter={false}
      errorMsg={
        errors.anexoOpmeServicoTuss?.[index]?.idServicoTussFornecedorOpme
          ?.message
      }
    />
  );

  const handleDelete = async () => {
    try {
      const idAnexoOpmeServicoTuss = getValues(
        `anexoOpmeServicoTuss.${index}.id`,
      );

      if (!!idAnexoOpmeServicoTuss)
        await AnexoOpmeAPI.deleteItemAnexoOpme(idAnexoOpmeServicoTuss);

      unregister(`anexoOpmeServicoTuss.${index}.idServicoTussFornecedorOpme`);
      onDelete(index);
    } catch (error) {}
  };

  const commonProps = {
    servicoTuss,
    inputQuantidade,
    fabricanteDropdown,
    onDelete: handleDelete,
  };

  return isMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
