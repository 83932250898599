import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/anexo-quimioterapia';

const getEstadiamento = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/estadiamento`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getTipoQuimio = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/tipo-quimioterapia`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getFinalidade = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/finalidade`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getEcog = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/ecog`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getTumor = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/tumor`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getNodulo = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/nodulo`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getMetastase = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/metastase`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getViaAdm = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/via-administracao`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const getUnidadeMedida = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/unidade-medida`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const getAnexoQuimio = async (
  idSolicitacaoExame: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/listar/${idSolicitacaoExame}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
const postAnexoQuimio = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basePath}`,
    throwError: true,
    ...options,
    data: payload,
  });
const putAnexoQuimio = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}`,
    throwError: true,
    ...options,
    data: payload,
  });
const getPesoAltura = async (
  idAtendimento: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `peso-altura/atendimento/ultimo/${idAtendimento}`,
    hideToast: true,
    throwError: true,
    ...options,
  });

export const AnexoQuimioterapiaAPI = {
  getEstadiamento,
  getTipoQuimio,
  getFinalidade,
  getEcog,
  getTumor,
  getNodulo,
  getMetastase,
  getViaAdm,
  getUnidadeMedida,
  getAnexoQuimio,
  postAnexoQuimio,
  putAnexoQuimio,
  getPesoAltura,
};
