import dayjs from 'dayjs';
import * as Yup from 'yup';

import { passaporteRegex } from 'src/utils/formUtils';
import { isValidCPF } from 'src/utils/utils';

const validationSchema = (
  camposDemograficosObrigatorios: boolean,
  quantidadeMesesObrigaCpf: number,
  isEdit: boolean,
) =>
  Yup.object().shape({
    nome: Yup.string().required('O campo nome é obrigatório'),
    idSexo: Yup.string().required('O campo sexo é obrigatório'),
    cpf: Yup.string()
      .nullable()
      .when(['estrangeiro', 'dataNascimento'], {
        is: (estrangeiro: boolean, dataNascimento: Date) => {
          if (camposDemograficosObrigatorios) return true;

          if (estrangeiro) return false;

          if (quantidadeMesesObrigaCpf === 0) return true;

          const now = dayjs(new Date());

          const idadeMeses = now.diff(dataNascimento, 'month');

          if (
            quantidadeMesesObrigaCpf > 0 &&
            idadeMeses <= quantidadeMesesObrigaCpf
          )
            return true;

          return now.diff(dataNascimento, 'year') >= 18 || !dataNascimento;
        },
        then: field => field.required('O campo CPF é obrigatório'),
      })
      .test('cpf', 'O CPF é inválido', cpf => (cpf ? isValidCPF(cpf) : true)),
    dataNascimento: Yup.date()
      .nullable()
      .required('O campo data de nascimento é obrigatório'),
    passaporte: Yup.string()
      .when('estrangeiro', {
        is: true,
        then: field =>
          field
            .required('O campo passaporte é obrigatório')
            .matches(passaporteRegex, 'O passaporte não é válido'),
      })
      .nullable(),
    nomeMae: Yup.string().when('maeDesconhecida', {
      is: false,
      then: field => field.required('O campo nome da mãe é obrigatório'),
    }),
    convenioResponsavel: Yup.object()
      .nullable()
      .when('recemNascido', {
        is: true,
        then: field =>
          field.required('O campo plano de saúde do responsável é obrigatório'),
      }),
    numeroCartaoResponsavel: Yup.string().when('recemNascido', {
      is: true,
      then: field =>
        field.required(
          'O campo número da carteirinha do responsável é obrigatório',
        ),
    }),
    ...(camposDemograficosObrigatorios &&
      !isEdit && {
        // Dados pessoais
        nomeSocial: Yup.string().required('O campo nome social é obrigatório'),
        idGenero: Yup.string().required('O campo gênero é obrigatório'),
        idRacaCor: Yup.string().required('O campo raça/cor é obrigatório'),
        rgNumero: Yup.string().required('O campo número é obrigatório'),
        rgOrgaoEmissor: Yup.string().required(
          'O campo orgão emissor é obrigatório',
        ),
        rgUf: Yup.string().required('O campo uf é obrigatório'),
        rgDataEmissao: Yup.date().required(
          'O campo data de emissão é obrigatório',
        ),
        complemento: Yup.string().required('O campo complemento é obrigatório'),
        cns: Yup.string().required('O campo número é obrigatório'),
        nomeResponsavelLegal: Yup.string().required(
          'O campo nome é obrigatório',
        ),
        idGrauParentesco: Yup.string().required(
          'O campo grau de parentesco é obrigatório',
        ),
        cpfResponsavelLegal: Yup.string().required('O campo cpf é obrigatório'),
        idPaisEmissorPassaporte: Yup.string().when('estrangeiro', {
          is: true,
          then: field => field.required('O campo país emissor é obrigatório'),
        }),
        passaporteDataValidade: Yup.date().when('estrangeiro', {
          is: true,
          then: field =>
            field.required('O campo data de validade é obrigatório'),
        }),
        passaporteDataEmissao: Yup.date().when('estrangeiro', {
          is: true,
          then: field =>
            field.required('O campo data de emissão é obrigatório'),
        }),

        // Endereco
        numero: Yup.string().required('O campo número é obrigatório'),
        complementoEndereco: Yup.string().required(
          'O campo complemento é obrigatório',
        ),
        logradouro: Yup.string().required('O campo logradouro é obrigatório'),
        tipo: Yup.string().required('O campo tipo de logradouro é obrigatório'),
        cep: Yup.string().required('O campo CEP é obrigatório'),
        bairro: Yup.string().required('O campo bairro é obrigatório'),
        idCidade: Yup.string().required('O campo cidade é obrigatório'),
        idEstado: Yup.string().required('O campo estado é obrigatório'),

        // Contato
        email: Yup.string().required('O campo email é obrigatório'),
        contato: Yup.string().when(
          [
            'CELULAR_PARTICULAR',
            'RECADO',
            'FIXO_RESIDENCIAL',
            'CELULAR_COMERCIAL',
          ],
          {
            is: (
              CELULAR_PARTICULAR: string,
              RECADO: string,
              FIXO_RESIDENCIAL: string,
              CELULAR_COMERCIAL: string,
            ) => {
              return !(
                CELULAR_PARTICULAR ||
                RECADO ||
                FIXO_RESIDENCIAL ||
                CELULAR_COMERCIAL
              );
            },
            then: Yup.string().required('Um número de contato é obrigatório'),
          },
        ),
      }),
  });

export default validationSchema;
