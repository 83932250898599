import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConsultoriosState {
  disponiveis?: GetConsultorioDTO[];
  ativo?: GetConsultorioDTO | undefined;
}

const initialState: ConsultoriosState = {};

export const consultoriosSlice = createSlice({
  name: 'consultorios',
  initialState,
  reducers: {
    setConsultorios: (
      state: ConsultoriosState,
      action: PayloadAction<GetConsultorioDTO[]>,
    ) => {
      state.disponiveis = action.payload;
    },
    setConsultorioAtivo: (
      state: ConsultoriosState,
      action: PayloadAction<GetConsultorioDTO | undefined>,
    ) => {
      state.ativo = action.payload;
    },
    clearConsultorios: (state: ConsultoriosState) => {
      Object.assign(state, {});
      return {};
    },
  },
});

export const { setConsultorios, setConsultorioAtivo, clearConsultorios } =
  consultoriosSlice.actions;

export default consultoriosSlice.reducer;
