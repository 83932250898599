import { Control, Controller } from 'react-hook-form';

import CardOption, { CardOptionProps } from '../CardOption/CardOption';

export interface CardOptionControlledProps extends CardOptionProps {
  control: Control;
  name: string;
}

const CardOptionControlled = ({
  name,
  control,
  value,
  ...rest
}: CardOptionControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value: valueRadio } }) => (
        <CardOption
          {...rest}
          value={value}
          checked={valueRadio === value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default CardOptionControlled;
