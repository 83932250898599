import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/solicitacoes-procedimentos-externo';

const postProcedimentoExterno = async (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/adicionar-procedimento`,
    data: payload,
    throwError: true,
    ...options,
  });

const putStatus = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  status: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimentoExterno}/alterar-status/${status}`,
    throwError: true,
    ...options,
  });

const putSeveridade = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimentoExterno}/criticidade`,
    data: payload,
    throwError: true,
    hideToast: true,
    ...options,
  });

const putLembrete = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/lembrete/${idSolicitacaoProcedimentoExterno}`,
    throwError: true,
    ...options,
  });

const putDataRepeticao = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  dataRepeticao: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimentoExterno}/data-repeticao/${dataRepeticao}`,
    throwError: true,
    ...options,
  });

const putParecer = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimentoExterno}/parecer`,
    data: payload,
    throwError: true,
    ...options,
  });

const putLaudo = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimentoExterno}/laudo`,
    data: payload,
    throwError: true,
    ...options,
  });

const putResultado = (
  idAtendimento: number,
  idSolicitacaoProcedimentoExterno: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimentoExterno}/resultado`,
    data: payload,
    throwError: true,
    ...options,
  });

export const SolicitacoesProcedimentosExternosAPI = {
  postProcedimentoExterno,
  putStatus,
  putSeveridade,
  putLembrete,
  putDataRepeticao,
  putParecer,
  putLaudo,
  putResultado,
};
