import { useCallback, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { MovimentacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/Movimentacao/MovimentacaoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import { InputText } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { FieldEquipeCuidadora } from 'src/components/Fields/FieldEquipeCuidadora/FieldEquipeCuidadora';
import { FieldMedicoCuidador } from 'src/components/Fields/FieldMedicoCuidador/FieldMedicoCuidador';
import { FieldMotivoTransferencia } from 'src/components/Fields/FieldMotivoTransferencia/FieldMotivoTransferencia';
import FormInput from 'src/components/FormInput/FormInput';

import './ModalTransferencia.scss';

type PostTransferencia = {
  idsPacientes?: number[];
  idConsultorio: number;
  idCuidadorOrigem: number;
  idCuidadorDestino: number;
  idMotivoTransferencia: number;
  outrosMotivos: string;
};

interface ModalTransferenciaProps {
  visible: boolean;
  equipe: any;
  pacientes: PacientesCarteiraDTO[];
  onHide(): void;
  reload(): void;
}

const ModalTransferencia = ({
  visible,
  pacientes,
  equipe,
  reload,
  onHide,
}: ModalTransferenciaProps) => {
  const { id } = useParams();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [profissionalResponsavelNome, setProfissionalResponsavelNome] =
    useState('');

  const isEquipeCuidadora =
    consultorios?.ativo?.tipoMonitoramentoCarteiraAps === 'EQUIPE';

  const cuidadorDestinoErrorMessage = `Selecione ${
    isEquipeCuidadora ? 'uma equipe' : 'um profissional'
  }`;

  const validationSchema = Yup.object()
    .shape({
      cuidadorDestino: Yup.object()
        .nullable()
        .required(cuidadorDestinoErrorMessage),
      motivoTransferencia: Yup.object().nullable(),
      outrosMotivos: Yup.string(),
    })
    .atLeastOneOf(['motivoTransferencia', 'outrosMotivos']);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const onSubmit = useCallback(
    async data => {
      const payload: PostTransferencia = {
        idConsultorio: consultorios?.ativo?.id,
        idCuidadorOrigem:
          equipe.idEquipeCuidadora ?? equipe.idProfissionalCuidador,
        idCuidadorDestino: data.cuidadorDestino.id,
        idMotivoTransferencia: data.motivoTransferencia?.id,
        outrosMotivos: data.outrosMotivos,
      };

      if (pacientes) {
        const idPacientes = pacientes.map((paciente: any) => paciente.id);
        payload.idsPacientes = idPacientes;
      }

      const saveTransferencia = pacientes
        ? MovimentacaoCarteiraAPI.postTrasferenciaPaciente
        : MovimentacaoCarteiraAPI.postTransferenciaCarteira;

      const response = await saveTransferencia(payload);

      if (response.status === 204) {
        reload();
        onHide();
      }
    },
    [
      consultorios?.ativo?.id,
      equipe.idEquipeCuidadora,
      equipe.idProfissionalCuidador,
      onHide,
      pacientes,
      reload,
    ],
  );

  const profissionalLabel = isEquipeCuidadora
    ? 'Profissional Responsável'
    : 'Profissional';
  const profissionalLabelClass = isEquipeCuidadora
    ? 'p-col-12 p-sm-6'
    : 'p-col-12';
  const profissional =
    equipe.equipeCuidadora?.profissionalResposavel ??
    equipe.profissionalCuidador;

  const handleSelectEquipe = (equipe: any) => {
    const profissionalResponsavel = equipe.equipeProfissionaisSaude.find(
      (profissional: any) => profissional.responsavel,
    );

    if (profissionalResponsavel)
      setProfissionalResponsavelNome(
        profissionalResponsavel?.profissionalSaude?.usuario?.nome ?? '',
      );
  };

  return (
    <Dialog
      className="modal-transferencia"
      header={pacientes ? 'Transferir pacientes' : 'Transferir carteira'}
      visible={visible}
      onHide={onHide}
      maximizedTabletSize
    >
      <>
        {!pacientes && (
          <div className="p-grid info-container">
            {isEquipeCuidadora && (
              <div className="p-col-12 p-sm-6">
                <div className="column">
                  <SimpleText
                    className="p-mb-1"
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Equipe
                  </SimpleText>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    {equipe.equipeCuidadora?.nome || ''}
                  </SimpleText>
                </div>
              </div>
            )}

            <div className={profissionalLabelClass}>
              <div className="column">
                <SimpleText
                  className="p-mb-1"
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {profissionalLabel}
                </SimpleText>

                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                >
                  {profissional?.nome}
                </SimpleText>
              </div>
            </div>
          </div>
        )}

        <div className="column p-mt-4 p-mb-2">
          <SimpleText
            bold
            className="p-mb-1"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            Transferir para
          </SimpleText>

          <i className="fa fa-angle-double-right icon" />
        </div>

        <FormProvider {...useFormMethods}>
          <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
            {isEquipeCuidadora ? (
              <FieldEquipeCuidadora
                className="p-col-12 p-sm-6"
                label="Equipe"
                name="cuidadorDestino"
                idConsultorio={consultorios?.ativo?.id}
                onChange={handleSelectEquipe}
                deleteEquipeFromOptions={Number(id)}
              />
            ) : (
              <FieldMedicoCuidador
                className="p-col-12"
                label="Profissional"
                name="cuidadorDestino"
                idConsultorio={consultorios?.ativo?.id}
                deleteMedicoFromOptions={Number(id)}
              />
            )}

            {isEquipeCuidadora && (
              <InputText
                className="p-col-12 p-sm-6"
                name="responsavel"
                label="Profissional responsável"
                value={profissionalResponsavelNome}
                readOnly
              />
            )}

            <FieldMotivoTransferencia label="Motivo" />

            <FormInput
              className="p-col-12"
              name="outrosMotivos"
              label="Outros Motivos"
              maxLengthSpan
              maxLength={100}
            />

            {!!errors?.atLeastOneOf?.message && (
              <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
                Selecione um motivo ou informe um outro motivo.
              </SimpleText>
            )}

            <div className="p-grid p-col-12 p-mt-1">
              <div className="p-col-6">
                <Button
                  className="p-w-100"
                  btnType="ghost"
                  label="Cancelar"
                  onClick={onHide}
                />
              </div>

              <div className="p-col-6">
                <Button
                  className="p-w-100"
                  label="Transferir"
                  type="submit"
                  loading={isSubmitting}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </>
    </Dialog>
  );
};

export default ModalTransferencia;
