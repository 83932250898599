import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CompromissosAPI from 'src/APIs/AgendaAPI/CompromissosAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import Dialog from 'src/components/Dialog/Dialog';

import DesktopRow from './DesktopRow';
import FormCompromisso from './FormCompromisso/FormCompromisso';
import MobileRowCompromissos from './MobileRow';
import SearchList from './SearchList/SearchList';

import './Compromissos.scss';

const tipos_compormissos: any = {
  FER: 'Férias',
  EVE: 'Eventos',
  COM: 'Compromisso pessoal',
  PRO: 'Procedimentos médicos',
  OUT: 'Outros',
};

interface DialogState {
  visible: boolean;
  item?: GetCompromissoDTO;
  update?: boolean;
}

interface DialogDeleteState {
  visible: boolean;
  id?: number | any;
}

const Compromissos = () => {
  const { isMobile, windowInnerWidth } = useSize();

  const { agenda } = useAppSelector(state => state);

  const isLg = useMemo(() => windowInnerWidth >= 1176, [windowInnerWidth]);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'age_compromisso_cadastrar',
      'age_compromisso_alterar',
      'age_compromisso_consultar',
      'age_compromisso_excluir',
    ],
  });

  const [reload, setReload] = useState<number>(0);
  const [dialog, setDialog] = useState<DialogState>({ visible: false });
  const [confirmDelete, setConfirmDelete] = useState<DialogDeleteState>({
    visible: false,
  });

  const prevIdProfissionalSaudeAtivo = useRef(null);

  useEffect(() => {
    if (agenda?.profissionalAtivo?.id !== prevIdProfissionalSaudeAtivo) {
      prevIdProfissionalSaudeAtivo.current = agenda?.profissionalAtivo?.id;
      reloadList();
    }
  }, [agenda?.profissionalAtivo?.id]);

  const fetchApi = useCallback(
    async (page: number): Promise<Paginator<any>> => {
      return await CompromissosAPI.loadCompromissoByProfissionalSaude(
        agenda.profissionalAtivo.id,
        { params: { page } },
      );
    },
    [agenda.profissionalAtivo.id],
  );

  function reloadList() {
    setReload(currentReload => currentReload + 1);
  }

  const handleEdit = useCallback((item: GetCompromissoDTO) => {
    setDialog({ visible: true, item, update: true });
  }, []);

  const handleDuplicate = useCallback((item: GetCompromissoDTO) => {
    setDialog({ visible: true, item: { ...item, id: 0 } });
  }, []);

  const handleDelete = useCallback((item: GetCompromissoDTO) => {
    setConfirmDelete({ visible: true, id: item?.id });
  }, []);

  const renderHeader = useCallback(() => {
    return (
      <>
        {isMobile === false && (
          <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
            <div className="p-col-3">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Tipo
              </SimpleText>
            </div>
            <div className="p-col-3">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Compromisso
              </SimpleText>
            </div>
            <div className="p-col-2 p-md-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Data inicial
              </SimpleText>
            </div>
            <div className={`p-col-${isLg ? '3' : '2'} p-px-0`}>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Data final
              </SimpleText>
            </div>
            <div className={`p-col-${isLg ? '1' : '2'} p-px-0`}>
              <Can
                checkPermission={[
                  'AGE_COMPROMISSO_ALTERAR',
                  'AGE_COMPROMISSO_CADASTRAR',
                  'AGE_COMPROMISSO_EXCLUIR',
                ]}
              >
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Ações
                </SimpleText>
              </Can>
            </div>
          </div>
        )}
      </>
    );
  }, [isLg, isMobile]);

  const renderRow = (
    data: any,
    index: number,
    arrLength: number,
  ): JSX.Element => {
    const isLastRow = index === arrLength - 1;

    if (isMobile) {
      return (
        <MobileRowCompromissos
          data={data}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
          handleEdit={handleEdit}
          tipos_compormissos={tipos_compormissos}
        />
      );
    }
    return (
      <DesktopRow
        data={data}
        handleDelete={handleDelete}
        handleDuplicate={handleDuplicate}
        handleEdit={handleEdit}
        tipos_compormissos={tipos_compormissos}
        isLastRow={isLastRow}
      />
    );
  };

  return (
    <>
      <div className="HorarioAtendimento">
        <div className="content-sub-title p-grid p-w-100 p-justify-between p-ai-center">
          <SimpleText
            className="p-col-12 p-md-8"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            Bloqueie dias ou horários da agenda do médico selecionado
            cadastrando compromissos. Ideal para férias, cirurgias, simpósios ou
            outros eventos sazonais
          </SimpleText>

          <Can checkPermission="AGE_COMPROMISSO_CADASTRAR">
            <div className="p-col-12 p-md-4 p-d-flex p-jc-center p-jc-md-end">
              <Button
                icon="fas fa-plus"
                label="Adicionar novo compromisso"
                btnType="pill"
                onClick={() => setDialog({ visible: true, update: false })}
                style={{
                  paddingInline: '30px',
                }}
              />
            </div>
          </Can>
        </div>

        <SearchList
          fetchAPI={fetchApi}
          renderRow={renderRow}
          renderHeader={renderHeader}
          reload={reload}
        />

        <FormCompromisso
          visible={dialog.visible}
          item={dialog.item}
          update={dialog.update}
          onHide={() => setDialog({ visible: false })}
          onSuccess={reloadList}
          // TODO: Adicionar callback para atualizar lista atual
        />
      </div>

      <Dialog
        className="footer-on"
        header="Excluir"
        visible={confirmDelete.visible}
        onHide={() => setConfirmDelete({ visible: false })}
        footer={() => (
          <Footer
            onCancel={() => setConfirmDelete({ visible: false })}
            onConfirm={async () => {
              await CompromissosAPI.deleteCompromisso(confirmDelete.id);

              reloadList();

              setConfirmDelete({ visible: false });
            }}
          />
        )}
      >
        <SimpleText>Você realmente deseja excluir este horário?</SimpleText>
      </Dialog>

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

interface FooterProps {
  onCancel(): void;
  onConfirm(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onConfirm } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        btnType="danger"
        label="Sim, excluir"
        onClick={onConfirm}
        stretch
      />
    </div>
  );
});

export default memo(Compromissos);
