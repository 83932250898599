import axios from 'axios';
import { sendFileUpload } from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

export const itemsFieldEtapa = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
];

export const itemsFieldTipoConteudo = [
  {
    label: 'Imagem',
    value: 'IMAGEM',
  },
  {
    label: 'Vídeo',
    value: 'VIDEO',
  },
];

export const tipoUsuario: { [key: string]: string } = {
  PROFISSIONAL_SAUDE: 'Profissional de Saúde',
  SECRETARIO: 'Secretário',
  ADMINISTRADOR: 'Administrador',
  MASTER: 'Master',
};

export async function submitImage(file: File, idEmpresa: number) {
  const response = await sendFileUpload(
    {
      fileName: file.name,
      contentType: file.type,
      contentLength: file.size,
      resourceType: 'OUTROS',
      resourceId: idEmpresa,
    },
    {
      hideToast: true,
      throwError: true,
    },
  );
  if (response?.status === 200 && response?.data?.uploadUrl) {
    await axios.put(response.data.uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  return response;
}
