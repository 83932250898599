import { useCallback, useEffect, useState } from 'react';

import { FaturamentoAPI } from 'src/APIs/FinanceiroAPI/FaturamentoAPI/FaturamentoAPI';

import { convertCurrencyToNumber } from 'src/utils/utils';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  servico: any;
  statusOptions: any;
  calculoValorTotalReceber: any;
}

function ServicosAutogerados({
  servico,
  statusOptions,
  calculoValorTotalReceber,
}: Props) {
  const [valorPago, setValorPago] = useState(servico.valorPago);
  const [valorGlosa, setValorGlosa] = useState(servico.valorGlosa);
  const [status, setStatus] = useState(servico.status);

  const handleChangeValorPago = useCallback(
    async (idProcedimento: any, novoValor: number, hideToast?: boolean) => {
      if (
        novoValor === servico.valorPago ||
        (novoValor === 0 && servico.valorPago === null)
      )
        return;

      return await FaturamentoAPI.putValorProcedimento(
        servico.idSolicitacao,
        {
          valorPago: novoValor,
        },
        {
          hideToast: hideToast || false,
        },
      );
    },
    [servico.idSolicitacao, servico.valorPago],
  );

  const handleChangeValorGlosa = async (servico: any, novoValor: number) => {
    if (
      novoValor === servico.valorGlosa ||
      (novoValor === 0 && servico.valorGlosa === null)
    )
      return;

    servico.valorGlosa = novoValor;
    await FaturamentoAPI.putValorProcedimento(servico.idSolicitacao, {
      valorGlosa: novoValor,
    });

    const calculoValorPago = servico?.valor - novoValor;

    setValorPago(calculoValorPago);
    handleChangeValorPago(servico.idSolicitacao, calculoValorPago, true);
    calculoValorTotalReceber();
  };

  const handleChangeStatus = async (servico: any, novoStatus: string) => {
    if (novoStatus === servico.status) return;
    return await FaturamentoAPI.putStatusProcedimento({
      idsProcedimento: servico.idSolicitacao,
      status: novoStatus,
    });
  };

  useEffect(() => {
    if (status === 'PAGO' && valorPago === null) {
      const calculoValorPago = servico.valor - servico.valorGlosa;
      setValorPago(calculoValorPago);
      handleChangeValorPago(servico, calculoValorPago, true);
    }
  }, [handleChangeValorPago, servico, status, valorPago]);

  return (
    <div className="p-col-12 p-grid p-mt-1 p-pb-0">
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Guia
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {servico?.guia}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Cod. TUSS
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {servico?.codigo}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Quantidade
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {servico?.quantidade}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Valor
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {servico?.valor}
        </SimpleText>
      </div>{' '}
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Valor glosa
        </SimpleText>
        <InputNumber
          mode="currency"
          locale="pt-BR"
          placeholder="R$"
          format={true}
          allowEmpty={false}
          max={9999999.99}
          value={valorGlosa}
          onBlur={e => {
            handleChangeValorGlosa(
              servico,
              convertCurrencyToNumber(e.target.value),
            );
          }}
          onChange={e => setValorGlosa(e.target.value)}
        />
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Valor pago
        </SimpleText>
        <InputNumber
          placeholder="R$"
          mode="currency"
          locale="pt-BR"
          format={true}
          allowEmpty={false}
          max={9999999.99}
          value={valorPago}
          onBlur={e => {
            handleChangeValorPago(
              servico,
              convertCurrencyToNumber(e.target.value),
            );
          }}
          onChange={e => setValorPago(e.target.value)}
        />
      </div>
      <div className="p-col-12 p-md-2 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Status
        </SimpleText>
        <Dropdown
          options={statusOptions}
          value={status}
          onChange={e => {
            setStatus(e.value);
            handleChangeStatus(servico, e.value);
          }}
          filter={false}
        />
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Descrição
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {servico?.descricao}
        </SimpleText>
      </div>
    </div>
  );
}

export default ServicosAutogerados;
