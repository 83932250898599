import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

export const DialogPontuacao = ({
  close,
  state,
}: DisclosureType<IFormularioDinamicoItem>) => {
  const pontuacoes = state?.pontuacoes || [];
  console.log('🚀 ~ pontuacoes:', pontuacoes);

  const pontuacaoLength = pontuacoes.length;

  return (
    <Dialog
      visible
      onHide={close}
      header={`Lista de pontuação do formulário (${pontuacaoLength})`}
      style={{
        width: '528px',
      }}
    >
      <div className="p-d-flex p-flex-column p-gap-2">
        <div className="row content-row-outline p-d-flex p-flex-wrap p-p-2">
          <SimpleText className="p-col-8" fontColor="color_40">
            Nome
          </SimpleText>
          <SimpleText className="p-col" fontColor="color_40">
            Intervalo de pontuação
          </SimpleText>

          <div
            className="p-d-flex p-flex-column p-gap-2 p-w-100"
            style={{
              maxHeight: '430px',
              overflowY: 'auto',
            }}
          >
            {pontuacoes.map((item, idx) => (
              <div
                key={idx}
                className="p-col-12 p-px-0 row content-row p-flex-wrap"
              >
                <SimpleText fontSize="sm" medium className="p-col-8">
                  {item.nome}
                </SimpleText>
                <div className="p-col p-d-flex p-gap-2">
                  <SimpleText fontSize="xxs">
                    Mínimo{' '}
                    <SimpleText fontColor="primary" fontSize="sm" medium>
                      {item.intervaloMinimo}
                    </SimpleText>
                  </SimpleText>
                  <SimpleText fontSize="xxs">
                    Máximo{' '}
                    <SimpleText fontColor="primary" fontSize="sm" medium>
                      {item.intervaloMaximo}
                    </SimpleText>
                  </SimpleText>
                </div>

                <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                  <SimpleText fontColor="color_40">
                    Mensagem para o paciente
                  </SimpleText>
                  <SimpleText>{item.mensagemPaciente}</SimpleText>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Button
          label="Fechar"
          stretch
          btnType="outline"
          onClick={() => close()}
        />
      </div>
    </Dialog>
  );
};
