import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import LembreteAPI from 'src/APIs/LembreteAPI/LembreteAPI';

import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import Destinatario from './FormsLembrete/Destinatario/Destinatario';
import Horarios from './FormsLembrete/Horarios/Horarios';
import Mensagem from './FormsLembrete/Mensagem/Mensagem';

import './FormLembrete.scss';

const NovoLembrete = () => {
  const tabIndex = useLocation().state;
  const { id } = useParams();
  const navigate = useNavigate();

  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const [textVariables, setTextVariables] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);
  const [editLembrete, setEditLembrete] = useState<GetLembreteAgendamentoDTO>();

  const [step1, setStep1] = useState<any>({});
  const [step2, setStep2] = useState<any>([]);
  const [step3, setStep3] = useState<any>();

  const handleNavigate = useCallback(() => {
    navigate('/config/general', { state: tabIndex });
  }, [navigate, tabIndex]);

  const getLembreteById = useCallback(async () => {
    if (!id) return;
    try {
      const response = await LembreteAPI.getLembreteById(id, {
        throwError: true,
      });

      if (response && !('status' in response)) {
        setEditLembrete(response);

        const consultoriosValues = response.consultoriosDto.map(
          (consultorio: any) => ({
            label: consultorio.nome,
            value: {
              id: consultorio.id,
            },
          }),
        );

        const medicosValues = response.medicosDto.map((medicos: any) => ({
          label: medicos.nome,
          value: {
            id: medicos.id,
          },
        }));

        setStep1({
          consultorios: consultoriosValues,
          medicos: medicosValues,
          todosMedicos: response.todosMedicos,
          todosConsultorios: response.todosConsultorios,
        });
        setStep2(response.datas);
        setStep3(response.mensagem);
      }
    } catch (error) {
      handleNavigate();
    }
  }, [handleNavigate, id]);

  const getTextVariables = useCallback(async () => {
    try {
      const response = await LembreteAPI.getLembreteVariaveis({
        throwError: true,
      });

      const variables =
        response?.map?.((text: any) => ({
          codigo: text.codigo.substring(1),
          nome: text.nome,
          tamanho: text.tamanho,
        })) || [];

      setTextVariables(variables);
    } catch (error) {
      handleNavigate();
    }
  }, [handleNavigate]);

  const getConsultorios = useCallback(async () => {
    try {
      const response: any = await ConsultorioAPI.loadConsultorios(
        {
          pageSize: 9999,
          sortBy: 'nome',
          sortDirection: 'ASC',
        },
        { throwError: true },
      );

      if (response && !('status' in response)) {
        setConsultorios(response.list);
      }
    } catch (error) {
      handleNavigate();
    }
  }, [handleNavigate]);

  const consultoriosOptions = useMemo(
    () =>
      consultorios.map(consultorio => ({
        label: consultorio.nome,
        value: {
          id: consultorio.id,
          nome: consultorio.nome,
        },
      })),
    [consultorios],
  );

  const getData = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      getConsultorios(),
      getTextVariables(),
      getLembreteById(),
    ]);

    setLoading(false);
  }, [getConsultorios, getLembreteById, getTextVariables]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = useCallback(
    async (mensagem: any) => {
      const idsConsultorio = step1.consultorios.map(
        (consultorio: any) => consultorio.value.id,
      );

      const idsMedicos = step1.medicos.map((medicos: any) => medicos.value.id);

      const payload = {
        id: editLembrete ? editLembrete.id : null,
        idsMedicos,
        idsConsultorio,
        mensagem,
        todosMedicos: step1.todosMedicos,
        todosConsultorios: step1.todosConsultorios,
        datas: step2,
      };

      let response: any;

      try {
        if (editLembrete) {
          response = await LembreteAPI.putLembrete(payload, {
            throwError: true,
          });
        } else {
          response = await LembreteAPI.postLembrete(payload, {
            throwError: true,
          });
        }

        if (response && !('status' in response)) {
          handleNavigate();
        }
      } catch (error) {}
    },

    [editLembrete, handleNavigate, step1, step2],
  );

  const handleNextStep = () => {
    if (timelineRef && timelineRef.current && timelineRef.current.next) {
      timelineRef.current?.next();
    }
  };

  const renderStep1 = useCallback(() => {
    return (
      <div className="p-w-100">
        {loading ? (
          <div className="p-ml-5">
            <GridListLoading />
            <GridListLoading />
          </div>
        ) : (
          <Destinatario
            consultoriosOptions={consultoriosOptions}
            handleNavigate={handleNavigate}
            values={step1}
            onSubmit={(values: any) => {
              setStep1(values);
              handleNextStep();
            }}
          />
        )}
      </div>
    );
  }, [consultoriosOptions, handleNavigate, loading, step1]);

  const steps = useCallback(
    (): TimelineValue[] => [
      {
        title: 'Destinatário',
        component: renderStep1(),
      },
      {
        title: 'Horário do envio ',
        component: (
          <Horarios
            values={step2}
            handleNavigate={handleNavigate}
            onSubmit={(values: any) => {
              setStep2(values);
              handleNextStep();
            }}
          />
        ),
        disabled: Object.keys(step1).length === 0,
      },
      {
        title: 'Mensagem',
        component: (
          <Mensagem
            handleNavigate={handleNavigate}
            textVariables={textVariables}
            values={step3}
            onSubmit={onSubmit}
          />
        ),
        disabled: Object.keys(step2).length === 0,
      },
    ],
    [handleNavigate, onSubmit, renderStep1, step1, step2, step3, textVariables],
  );

  return (
    <Page>
      <>
        <PageHeader
          title={id ? 'Editar lembrete' : 'Novo lembrete'}
          subtitle="Configure aqui o envio de lembrete de agendamento"
        />

        <Page content white>
          <div className="novoLembrete-container">
            <Timeline values={steps()} ref={timelineRef} />
          </div>
        </Page>
      </>
    </Page>
  );
};

export default NovoLembrete;
