import { createContext } from 'react';

export interface SolicitacaoExameCirurgiaData {
  guiasProcedimentosSolicitados: any[];
  loadingFetchProcedimentosSolicitados: boolean;
  idConvenio: number | null;
  procedimentoNomeSearchHistorico: string;
  exameFuturoIsEnabled: boolean;
  setProcedimentoNomeSearchHistorico: React.Dispatch<
    React.SetStateAction<string>
  >;
  fetchProcedimentosSolicitados: () => Promise<void>;
  handleDeleteProcedimentoFromGuia: (idProcedimento: number) => void;
  handleUpdateProcedimentosInGuias: (procedimentos: any[]) => void;
  handleUpdateProcedimentoPropValue: (
    idProcedimento: number,
    propName: string,
    newValue: any,
  ) => void;
  setLoadingFetchProcedimentosSolicitados: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIdConvenio: React.Dispatch<React.SetStateAction<number | null>>;
  getNextProcedimentoPendente: (idProcedimento: number) => any;
}

export const SolicitacaoExameCirurgiaContext = createContext(
  {} as SolicitacaoExameCirurgiaData,
);
