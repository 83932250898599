import { useState } from 'react';

import dayjs from 'dayjs';
import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';

import { handleOpenPDF } from 'src/utils/files';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './Voucher.scss';

export const Voucher = ({ values }: any) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const isTeleconsulta = values.flagsAgendamento.includes('TELEATENDIMENTO');

  const endereco = values.consultorio?.endereco;
  const enderecoFormatted = isTeleconsulta
    ? 'Teleconsulta'
    : !!endereco
    ? `${endereco.logradouro}, ${endereco.bairro}, ${capitalizeFirstLetter(
        endereco.cidade?.nome,
      )} - ${endereco.cidade?.estado?.sigla}`
    : '';

  const handlePrint = async () => {
    setIsPrinting(true);
    const res = await RelatoriosAPI.postRelatorioPortalVoucherAgendamento({
      medico: values.profissional?.nome,
      dataHora: dayjs(values.dataAgendamento).format('DD/MM/YYYY HH:mm'),
      local: enderecoFormatted,
      link: values.linkSalaTeleconsulta,
      contato: values.consultorio?.contatos?.[0]?.numero,
      beneficiario: values.nome,
      cpf: values.cpf,
      numeroCarteirinha: values.numCarteirinha || null,
      nomeMae: values.nomeMae,
      email: values.email,
      celular: values.celular,
    });

    handleOpenPDF(
      res,
      `Agendamento ${dayjs(values.dataAgendamento).format('DD/MM/YYYY HH:mm')}`,
    );
    setIsPrinting(false);
  };

  return (
    <div className="voucher-container p-grid">
      <div className="voucher-card p-col-12 p-md-6 p-lg-4 p-d-flex p-flex-column p-gap-3 p-mb-3">
        <SimpleText medium fontSize="sm">
          Informações do agendamento
        </SimpleText>

        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Médico</SimpleText>
          <SimpleText medium>{values.profissional.nome}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Data e hora</SimpleText>
          <SimpleText className="p-text-right">
            {dayjs(values.dataAgendamento).format(
              'dddd [,] D [de] MMM [de] YYYY [, às] HH:mm',
            )}
          </SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Local</SimpleText>
          <SimpleText className="p-text-right">{enderecoFormatted}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Contato</SimpleText>
          <SimpleText>{values.consultorio?.contatos?.[0]?.numero}</SimpleText>
        </div>

        <Divider layout="horizontal" />

        <SimpleText>Informações do paciente</SimpleText>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Benificiário</SimpleText>
          <SimpleText>{values.nome}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">CPF</SimpleText>
          <SimpleText>{values.cpf}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">N. Carteirinha</SimpleText>
          <SimpleText>{values.numCarteirinha || '-'}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Nome da mãe</SimpleText>
          <SimpleText>{values.nomeMae}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">E-mail</SimpleText>
          <SimpleText>{values.email}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Celular</SimpleText>
          <SimpleText>{values.celular}</SimpleText>
        </div>

        <Divider type="dashed" layout="horizontal" className="p-mb-0" />

        <Button
          label="Imprimir"
          icon="fas fa-print"
          btnType="tonal"
          onClick={handlePrint}
          loading={isPrinting}
        />
      </div>

      <div className="voucher-text p-col-12 p-md-6 p-lg-8 p-d-flex p-flex-column p-jc-center p-gap-2">
        <SimpleText fontSize="xl" medium className="p-mx-auto p-mb-4">
          Agendamento realizado com sucesso!
        </SimpleText>
        {!!values.linkSalaTeleconsulta && (
          <>
            <SimpleText fontSize="md" medium>
              Link teleatendimento:
            </SimpleText>
            <SimpleText fontSize="md" style={{ wordBreak: 'break-all' }}>
              {values.linkSalaTeleconsulta}
            </SimpleText>
          </>
        )}
        <SimpleText fontSize="xxs" fontColor="color_60">
          Não é necessário imprimir e apresentar nenhum comprovante deste
          agendamento no dia da consulta.
        </SimpleText>
      </div>
    </div>
  );
};
