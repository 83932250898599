import { useEffect, useState } from 'react';

import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import { ProfissionalSaude } from 'src/models/APIs/AdminAPI/ProfissionalSaude/ProfissionalSaudeDTO';

import { useAppSelector } from 'src/core/redux/hooks';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { FieldPlanosCuidado } from './components/FieldPlanosCuidado/FieldPlanosCuidado';
import { FieldSearchProfissional } from './components/FieldSearchProfissional/FieldSearchProfissional';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';

export const PlanoDeCuidadoCreateFormSelecionar = ({
  close,
  setActiveTab,
  onSubmit: handleSubmit,
  items = {},
  isEditing,
  loadingData,
}: any) => {
  const { ativo } = useAppSelector(state => state.consultorios);
  const { paciente } = useAppSelector(state => state.atendimento);

  const [loading, setLoading] = useState(false);

  const dialogCidDuplicado = useDisclosure({ opened: false });

  const form = useForm({
    defaultValues: {
      profissionais: [] as ProfissionalSaude[],
      planoCuidado: null,
      ...items,
    },
  });

  const { append, fields, remove, replace } = useFieldArray({
    control: form.control,
    name: 'profissionais',
    keyName: 'idForm',
  });

  useEffect(() => {
    if (!!items && items.profissionaisResponsaveis) {
      replace(items.profissionaisResponsaveis);
    }
  }, [items, items.profissionaisResponsaveis, replace]);

  useEffect(() => {
    if (!isEditing) {
      setLoading(true);
      PlanoCuidadoPacienteAPI.loadProfissionaisSugeridos({
        idConsultorio: ativo.id,
        idPaciente: paciente.id,
      })
        .then(prof => !!prof?.length && replace(prof))
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [ativo.id, isEditing, paciente.id, replace]);

  const onAddProfissional = (value: ProfissionalSaude) => {
    const isDuplicated = !!fields.find((field: any) => field.id === value.id);

    return !isDuplicated ? append(value) : null;
  };

  const onSubmit = async (values: any) => {
    if (!isEditing) {
      const possuiPlanoComMesmoCid =
        await PlanoCuidadoPacienteAPI.verifyPlanoCuidadoPacienteMesmoCid({
          idPaciente: paciente.id,
          idPlanoCuidado: values.planoCuidado.id,
        });

      if (!!possuiPlanoComMesmoCid) {
        return dialogCidDuplicado.open({
          state: {
            handleSubmit: () => [handleSubmit(values), setActiveTab(1)],
          },
        });
      }
    }

    return [handleSubmit(values), setActiveTab(1)];
  };

  const planoCuidado = form.watch('planoCuidado');
  const profissionais = form.watch('profissionais');

  if (loadingData) return <GridListLoading />;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="form-plano-cuidado-selecionar p-grid p-w-100 p-flex-column p-gap-2"
      >
        <SimpleText fontColor="color_40" className="p-my-2">
          Selecione o plano de cuidado que deseja inserir
        </SimpleText>

        <FieldPlanosCuidado
          name="planoCuidado"
          label="Plano de cuidado"
          placeholder="Selecione um plano de cuidado"
          className="p-w-100"
          disabled={isEditing}
        />

        <SimpleText fontColor="color_40" className="p-my-2">
          Selecione o profissional que ira acompanhar a execução do plano de
          cuidado
        </SimpleText>

        <FieldSearchProfissional onSelect={onAddProfissional} />

        {loading ? (
          <GridListLoading />
        ) : (
          <ProfissionalSelectGrid remove={remove} />
        )}

        <div className="p-d-flex p-gap-2">
          <Button
            label="Cancelar"
            btnType="ghost"
            stretch
            onClick={() => close()}
            disabled={form.formState.isSubmitting}
          />
          <Button
            label="Próximo"
            type="submit"
            stretch
            disabled={!profissionais.length || !planoCuidado}
            loading={form.formState.isSubmitting}
          />
        </div>

        {dialogCidDuplicado.isOpen && (
          <DialogCidDuplicado {...dialogCidDuplicado} />
        )}
      </form>
    </FormProvider>
  );
};

const ProfissionalSelectGrid = ({ remove }: any) => {
  const form = useFormContext();
  const fields: any[] = form.watch('profissionais');

  return (
    <div className="grid-profissionais">
      <div className="p-d-flex p-col-12 p-py-0">
        <SimpleText fontSize="xxs" className="p-col" fontColor="color_60">
          Nome do profissional responsável
        </SimpleText>
      </div>

      <div className="p-d-flex p-flex-column p-px-2">
        {!!fields.length ? (
          fields.map((field, idx) => {
            const profSaude = field.profissionalSaude;
            return (
              <div key={idx} className="row">
                <div className="p-d-flex p-flex-column p-gap-1 p-col-8">
                  <SimpleText medium>{field.nome}</SimpleText>
                  {!!profSaude ? (
                    <SimpleText fontSize="xxs" fontColor="color_60">
                      {profSaude?.conselhoTissProfissional?.descricao || '-'} •{' '}
                      {profSaude?.conselhoTiss?.sigla || ''}:{' '}
                      {profSaude?.conselhoTiss?.codigo || ''}
                    </SimpleText>
                  ) : (
                    <SimpleText fontSize="xxs" fontColor="color_60">
                      {field?.tipoProfissionalSaude || '-'} •{' '}
                      {field?.codigoConselho || ''}:{' '}
                      {field?.numeroRegistro || ''}
                    </SimpleText>
                  )}
                </div>
                <div className="p-d-flex p-gap-3 p-jc-end p-col-4">
                  <Button
                    icon="fas fa-trash"
                    btnType="gray"
                    onClick={() => remove(idx)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-w-100 p-d-flex p-mt-4">
            <SimpleText fontColor="color_40" className="p-m-auto p-text-center">
              Nenhum profissional selecionado. <br />
              Utilize a pesquisa para buscar por profissionais.
            </SimpleText>
          </div>
        )}
      </div>
    </div>
  );
};

const DialogCidDuplicado = ({
  close,
  state,
}: DisclosureType<{ handleSubmit: () => void }>) => (
  <Dialog
    visible
    onHide={close}
    header="Já existe um plano de cuidado vinculado a esse paciente"
    style={{
      width: '380px',
    }}
  >
    <div className="p-w-100 p-d-flex p-flex-column p-gap-2">
      <SimpleText>
        Esse paciente já possui um plano de cuidado vinculado com esse CID.
        Deseja realmente incluir um novo plano de cuidado?
      </SimpleText>

      <div className="p-d-flex p-gap-2">
        <Button
          stretch
          label="Cancelar"
          btnType="ghost"
          onClick={() => close()}
        />
        <Button
          stretch
          label="Prosseguir"
          onClick={() => [state?.handleSubmit(), close()]}
        />
      </div>
    </div>
  </Dialog>
);
