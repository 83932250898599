import React from 'react';
import { useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import dayjs from 'dayjs';
import { getPacientesFamiliar } from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import { capitalizeFirstLetter, formatCnpj, formatCPF } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './FieldSearchPaciente.scss';

export const FieldSearchPaciente = ({ onAdd }: any) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const inputRef = useRef<any>();

  const [inputValue, setInputValue] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);

  const [loadingQuery, setLoadingQuery] = useState(false);

  const inputSearch = useThrottle(setInputValue, 500);

  const fetchAPI = async (queryParam: string) => {
    setLoadingQuery(true);

    getPacientesFamiliar({ chave: queryParam })
      .then((data: any) => [setItems(data.list)])
      .catch(() => {})
      .finally(() => setLoadingQuery(false));
  };

  useEffectSkipFirst(() => {
    fetchAPI(inputValue);
  }, [inputValue]);

  const handleAdd = (item: any) => {
    setInputValue('');
    searchPanelRef.current?.hide();
    inputRef.current?.clear();

    onAdd(item);
  };

  useEffectSkipFirst(() => {
    if (inputValue && !searchPanelRef.current?.state.visible) {
      searchPanelRef.current?.setState({ visible: true });
    }
  }, [inputValue]);

  return (
    <div className="p-w-100 p-grid">
      <TextInputSearch
        ref={inputRef}
        aria-controls="overlay_panel"
        placeholder="Pesquisar por nome"
        onChange={e => inputSearch(e)}
        value={inputValue}
        onClick={e => searchPanelRef.current?.show(e, e.target)}
      />

      <OverlayPanel
        id="overlay_panel"
        appendTo="self"
        className="panel-search-paciente"
        ref={searchPanelRef}
      >
        <div className="p-w-100">
          {!!items.length && !loadingQuery ? (
            <div
              style={{ maxHeight: '300px', overflowY: 'scroll' }}
              className="fav-content"
            >
              {items.map((item: any, idx: number) => (
                <PacientesRow item={item} handleAdd={handleAdd} key={idx} />
              ))}
            </div>
          ) : (
            <div className="p-d-flex p-jc-center p-my-2">
              {loadingQuery ? (
                <SpinnerLoading full size="sm" />
              ) : (
                <SimpleText>
                  {!!inputValue
                    ? 'Nenhum paciente encontrado'
                    : 'Começe a digitar para buscar por pacientes'}
                </SimpleText>
              )}
            </div>
          )}
        </div>
      </OverlayPanel>
    </div>
  );
};

const PacientesRow = ({ handleAdd, item }: any) => {
  const cpf = item?.cpf ? formatCPF(item.cpf) : undefined;
  const cnpj = item?.cnpj ? formatCnpj(item.cnpj) : undefined;

  return (
    <div
      className="row p-col-12 p-ai-center p-py-0"
      onClick={() => handleAdd(item)}
    >
      <div className="p-col-4 p-sm-6 p-d-flex p-gap-2 p-ai-center">
        <Avatar label={item?.nome} className="avatar" />
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText>{item.nome}</SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            {capitalizeFirstLetter(item.sexo.descricao)}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column p-gap-1">
        <SimpleText>{cpf || cnpj || '-'}</SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          className="p-text-climp-3"
          fontColor={FONT_COLOR.COLOR_40}
        >
          {item.cartaoPrincipal?.numeroCartao} -{' '}
          {item.cartaoPrincipal?.convenio.nomeFantasia ||
            item.cartaoPrincipal?.convenio.razaoSocial}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column p-gap-1">
        <SimpleText>Ultimo atendimento</SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {dayjs(item?.dataUltimoAtendimento).format('DD/MM/YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
};
