import { forwardRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldSearchProfissionalSaude } from 'src/components/Fields/FieldSearchProfissionalSaude/FieldSearchProfissionalSaude';
import { FieldStatus } from 'src/components/Fields/FieldStatus/FieldStatus';
import { FieldPlanoStatus } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/FieldPlanoStatus/FieldPlanoStatus';
import { FieldPlanoTarefas } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/FieldPlanoTarefas/FieldPlanoTarefas';

import { usePlanoDeCuidado } from './hooks/PlanosDeCuidadoContext';

export const PlanoDeCuidadoFilters = forwardRef<any>((_, ref) => {
  const { filters, setFilters } = usePlanoDeCuidado();
  const reference: any = ref;

  const form = useForm({
    defaultValues: {
      concluido: filters?.concluido || null,
      ativo: filters?.ativo || null,
      emAtraso: filters?.emAtraso || null,
      profissionalSaude: {},
    },
  });

  const onSubmit = (values: any) => {
    const { concluido, ativo, emAtraso, profissionalSaude } = values;
    const idProfissionalResponsavel = profissionalSaude?.id;

    setFilters({
      concluido: concluido ?? undefined,
      ativo: ativo ?? undefined,
      emAtraso: emAtraso ?? undefined,
      idProfissionalResponsavel,
    });

    reference?.current?.hide();
  };

  const handleClean = () => {
    setFilters({});
    form.reset({
      concluido: null,
      ativo: null,
      emAtraso: null,
      profissionalSaude: {},
    });

    reference?.current?.hide();
  };

  return (
    <OverlayPanel ref={ref} className="filtro-avancado-planos-paciente">
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-column "
        >
          <SimpleText fontSize="sm" medium>
            Pesquisa avançada
          </SimpleText>

          <FieldPlanoStatus name="concluido" label="Plano" showClear />
          <FieldStatus name="ativo" label="Status" showClear />
          <FieldPlanoTarefas name="emAtraso" label="Tarefas" showClear />
          <FieldSearchProfissionalSaude
            label="Profissional responsável"
            showClear
          />

          <div className="p-d-flex p-col-12 p-ai-center">
            <Button
              type="button"
              label="Limpar"
              btnType="green-link"
              className="p-col"
              onClick={handleClean}
            />
            <Button type="submit" label="Pesquisar" className="p-col-8" />
          </div>
        </form>
      </FormProvider>
    </OverlayPanel>
  );
});
