import { cloneElement, useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import ReactInfiniteScroll, {
  Props as ReactInfiniteScrollProps,
} from 'react-infinite-scroll-component';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { RequireProperty } from 'src/utils/types';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
  TextProps,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch, {
  TextInputSearchProps,
} from 'src/components/Basics/TextInputSearch/TextInputSearch';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { SearchListProvider, useSearchList } from './SearchListContext';

import './SearchList.scss';

interface SearchListRootProps {
  children: JSX.Element | JSX.Element[];
  fetchApi: (params?: any) => Promise<any>;
  empresaRequired?: boolean;
  consultorioRequired?: boolean;
  reloadWhenEmpresaChange?: boolean;
  reloadWhenConsultorioChange?: boolean;
  paginatedList?: boolean;
  onlyQueryLength?: number;
  lastItem?: any;
}

const SearchListRoot = (props: SearchListRootProps) => {
  const {
    children,
    empresaRequired = false,
    consultorioRequired = false,
    reloadWhenEmpresaChange = false,
    reloadWhenConsultorioChange = false,
    paginatedList = true,
    fetchApi,
    onlyQueryLength,
    lastItem,
  } = props;

  const { user, consultorios } = useAppSelector((state: RootState) => state);

  if (empresaRequired && !user?.idEmpresa) {
    return (
      <AlertBox
        visible={true}
        text="Você precisa selecionar um Cliente para esta funcionalidade!"
      />
    );
  }

  if (consultorioRequired && !consultorios?.ativo?.id) {
    return (
      <AlertBox
        visible={true}
        text="Você precisa selecionar um Consultório para esta funcionalidade!"
      />
    );
  }

  return (
    <SearchListProvider
      fetchApi={fetchApi}
      reloadWhenEmpresaChange={reloadWhenEmpresaChange}
      reloadWhenConsultorioChange={reloadWhenConsultorioChange}
      paginatedList={paginatedList}
      lastItem={lastItem}
      onlyQueryLength={onlyQueryLength}
    >
      <div className="p-grid column">{children}</div>
    </SearchListProvider>
  );
};

interface SearchListInputProps extends Omit<TextInputSearchProps, 'onChange'> {
  label?: string;
  className?: string;
}

const SearchListInput = (props: SearchListInputProps) => {
  const { label, className, ...rest } = props;

  const { query, setQuery } = useSearchList();

  return (
    <div className={className || 'p-col-12 p-md-6 p-mb-3'}>
      {!!label && (
        <div className="p-mb-2">
          <SimpleText>{label}</SimpleText>
        </div>
      )}
      <TextInputSearch {...rest} value={query} onChange={setQuery} />
    </div>
  );
};

const SearchListBorderContainer = (props: any) => {
  const { children } = props;

  return (
    <div className="search-list-border-container p-col-12">{children}</div>
  );
};

const SearchListHeader = (props: any) => {
  const { children } = props;

  const { isMobile } = useSize();

  if (isMobile) return null;

  return <div className="search-list-header">{children}</div>;
};

interface SearchListCardItemProps {
  children: JSX.Element | JSX.Element[];
}

const SearchListCardItem = (props: SearchListCardItemProps) => {
  const { children } = props;

  return <div className="search-list-card-item">{children}</div>;
};

interface SearchListNonPageableScrollAreaBase {
  reload?: number;
  renderRow?: (data: any) => JSX.Element;
  renderRows?: (data: any) => JSX.Element | JSX.Element[];
}

type SearchListNonPageableScrollAreaProps =
  | RequireProperty<SearchListNonPageableScrollAreaBase, 'renderRow'>
  | RequireProperty<SearchListNonPageableScrollAreaBase, 'renderRows'>;

const SearchListNonPageableScrollArea = (
  props: SearchListNonPageableScrollAreaProps,
) => {
  const { reload, renderRow, renderRows } = props;

  const { listItems, loading, reloadList } = useSearchList();

  useEffectSkipFirst(() => {
    reloadList();
  }, [reload]);

  const render = () => {
    if (!listItems || !Array.isArray(listItems)) return null;

    if (renderRows) {
      return renderRows(listItems);
    }

    if (renderRow) {
      return listItems.map((data, index) => {
        return <div key={index}>{renderRow(data)}</div>;
      });
    }

    return null;
  };

  if (loading) {
    return <GridListLoading />;
  }

  if (!listItems.length) {
    return (
      <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-mt-2">
        <SimpleText
          fontSize={FONT_SIZE.SM}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Nenhum registro foi encontrado. Por favor, tente refazer a busca.
        </SimpleText>
      </div>
    );
  }

  return <div className="search-list-non-pageable-scroll-area">{render()}</div>;
};

interface SearchListInfiniteScrollBase
  extends Omit<
    ReactInfiniteScrollProps,
    'dataLength' | 'next' | 'hasMore' | 'loader' | 'children'
  > {
  reload?: any;
  renderRow?: (data: any, isMobile: boolean) => JSX.Element;
  renderRows?: (data: any) => JSX.Element | JSX.Element[];
  emptyResultLayout?: () => JSX.Element;
  customEmptyText?: string | JSX.Element;
  autoHeight?: boolean;
}

type SearchListInfiniteScrollProps =
  | RequireProperty<SearchListInfiniteScrollBase, 'renderRow'>
  | RequireProperty<SearchListInfiniteScrollBase, 'renderRows'>;

const SearchListInfiniteScroll = (props: SearchListInfiniteScrollProps) => {
  const {
    reload,
    renderRow,
    renderRows,
    emptyResultLayout,
    customEmptyText,
    autoHeight = true,
    ...rest
  } = props;

  const { listItems, loading, hasMore, reloadList, fetchNextPage, filter } =
    useSearchList();

  const { isMobile } = useSize();

  useEffectSkipFirst(() => {
    reloadList();
  }, [reload]);

  if (loading) {
    return <GridListLoading />;
  }

  const render = () => {
    if (!listItems || !Array.isArray(listItems)) return null;

    if (renderRows) {
      return renderRows(listItems);
    }

    if (renderRow) {
      return listItems.map((data, index) => {
        return <div key={data?.id ?? index}>{renderRow(data, isMobile)}</div>;
      });
    }

    return null;
  };

  if (!listItems.length) {
    if (!!emptyResultLayout && !!Object.keys(filter)?.length) {
      return emptyResultLayout();
    }

    if (typeof customEmptyText === 'object') {
      return customEmptyText;
    }

    return (
      <div className="content-empty p-d-flex p-flex-column p-ai-center p-jc-center p-mt-2 p-py-4">
        <SimpleText
          className="p-text-center"
          style={{ width: '300px' }}
          fontSize={FONT_SIZE.SM}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {customEmptyText ||
            'Nenhum registro foi encontrado. Por favor, tente refazer a busca.'}
        </SimpleText>
      </div>
    );
  }

  return (
    <ReactInfiniteScroll
      dataLength={listItems.length}
      next={fetchNextPage}
      hasMore={hasMore}
      loader={hasMore ? <GridListLoading /> : <></>}
      {...(!autoHeight && { height: '32vh' })}
      {...rest}
    >
      {render()}
    </ReactInfiniteScroll>
  );
};

const SearchListTotalCount = (props: TextProps) => {
  const { totalCount } = useSearchList();

  return <SimpleText {...props}>Resultados da busca ({totalCount})</SimpleText>;
};

interface SearchListFilter {
  children: JSX.Element | JSX.Element[] | any;
  onFilter: () => any;
  onClearFilters: () => void;
  btnType?: any;
  type?: any;
  isSearch?: boolean;
  btnClassName?: string;
  footer?: boolean;
}

const SearchListFilter = (props: SearchListFilter) => {
  const {
    children,
    onFilter,
    onClearFilters,
    btnType,
    type,
    isSearch,
    btnClassName = '',
    footer = true,
  } = props;

  const overlayRef = useRef<OverlayPanel>(null);

  const { isMobile, windowInnerWidth } = useSize();

  const { setFilter } = useSearchList();

  const handleFilter = () => {
    const filters = onFilter();
    setFilter(filters);

    overlayRef.current?.hide();
  };

  const handleOpen = (e: any) => {
    overlayRef.current?.toggle(e);
  };

  const handleCancel = () => {
    overlayRef.current?.hide();
  };

  const handleClearFilters = () => {
    setFilter({});
    onClearFilters();
  };

  const widthOverlayPanel = isMobile ? `${windowInnerWidth}px` : '500px';

  const handleLabel = (): string =>
    isSearch ? 'Pesquisa avançada' : 'Refinar busca';

  return (
    <>
      <Button
        label={handleLabel()}
        btnType={btnType || 'outline'}
        onClick={handleOpen}
        type={type || 'submit'}
        icon={isSearch && 'fa-solid fa-chevron-down'}
        iconPos={'right'}
        className={btnClassName}
      />

      <OverlayPanel ref={overlayRef} style={{ width: widthOverlayPanel }}>
        <div className="p-grid">
          <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
            <SimpleText medium fontSize={FONT_SIZE.SM}>
              {handleLabel()}
            </SimpleText>

            <Button
              type="button"
              label="Limpar"
              btnType="ghost"
              onClick={handleClearFilters}
            />
          </div>

          <div className="p-col-12 p-p-0">
            {cloneElement(children, overlayRef.current!)}
          </div>

          {footer && (
            <>
              <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
                <Button
                  btnType="outline"
                  label="Cancelar"
                  type="button"
                  onClick={handleCancel}
                  stretch
                />
                <Button
                  label={isSearch ? 'Pesquisar' : 'Aplicar filtros'}
                  type="button"
                  stretch
                  onClick={handleFilter}
                />
              </div>
            </>
          )}
        </div>
      </OverlayPanel>
    </>
  );
};

interface Option extends MenuItem {
  checkPermission?: string | string[];
}

interface SearchListMobileRowProps {
  children: JSX.Element | JSX.Element[];
  options: Option[];
  disabledOptions?: boolean;
  hideOptions?: boolean;
}

const SearchListMobileRow = (props: SearchListMobileRowProps) => {
  const { options, children, disabledOptions, hideOptions } = props;

  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const optionsWithPermission = options.filter(
    ({ checkPermission }) =>
      !checkPermission || authorities?.includes(checkPermission),
  );

  const menuEllipsis = useRef<TieredMenu>(null);

  return (
    <div className="search-list-mobile-card-item">
      {!hideOptions && (
        <div className="options-position">
          <Button
            icon="fas fa-ellipsis-h"
            type="button"
            btnType="gray"
            onClick={event => menuEllipsis?.current?.toggle(event)}
            disabled={
              disabledOptions ? disabledOptions : !optionsWithPermission?.length
            }
          />

          <TieredMenu
            className="panel-options-paciente"
            ref={menuEllipsis}
            model={optionsWithPermission}
            popup
          />
        </div>
      )}

      {children}
    </div>
  );
};

const Root = SearchListRoot;
const BorderContainer = SearchListBorderContainer;
const Header = SearchListHeader;
const InfiniteScroll = SearchListInfiniteScroll;
const SearchInput = SearchListInput;
const CardItem = SearchListCardItem;
const TotalCount = SearchListTotalCount;
const Filter = SearchListFilter;
const MobileRow = SearchListMobileRow;
const NonPageableScrollArea = SearchListNonPageableScrollArea;

export {
  Root,
  BorderContainer,
  Header,
  InfiniteScroll,
  SearchInput,
  CardItem,
  TotalCount,
  Filter,
  MobileRow,
  NonPageableScrollArea,
};
