import { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { TempoAtendimentoProps } from 'src/utils/hooks/useTimer';

import { Timer } from '../Cabecalho/utils/timer';

import { AtendimentoGrupoDialogListaPacientes } from './_components/DialogDetalhesPacientes';
import { AvatarGroupItemsProps, GroupAvatar } from './_components/GroupAvatar';
import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import MenuConfirmarConsulta from '../Atendimento/IniciarAtendimento/TipoConsulta/MenuConfirmarConsulta';

interface CabecalhoProps {
  pacientesGrupo: PacienteProntuario[];
  atendimento: any;
}

export interface HandlePausarProps {
  route?: string;
}

export function AtendimentoGrupoCabecalho({
  pacientesGrupo,
  atendimento,
}: CabecalhoProps) {
  const { user } = useAppSelector(state => state);
  const { isMobile } = useSize();

  const tempoAtendimento: React.MutableRefObject<TempoAtendimentoProps | null> =
    useRef(null);

  const { atendimentoStatus, sairPEPDialog, pausarAtendimento } =
    useAtendimento();

  const { state } = useLocation();
  const navigate = useNavigate();

  const confirmarFinalizacaoConsulta = useDisclosure({ opened: false });
  const dialogAtendimentoGrupoListaPacientes = useDisclosure({ opened: false });

  const idAtendimento = state?.idAtendimento;
  const { setValue } = useLocalStorage(`tempoAtendimento/${idAtendimento}`);

  function voltarParaAgenda() {
    if (atendimentoStatus === 'ATENDENDO') {
      sairPEPDialog.open({
        state: {
          ...sairPEPDialog.state,
          route: '/agenda',
        },
      });
      return;
    }
    navigate('/agenda', { replace: true });
  }

  const handlePausarAtendimento = async ({
    route = '/agenda',
  }: HandlePausarProps): Promise<void> => {
    if (atendimentoStatus === 'PRESCRICAO_EXECUCAO') {
      await PrescricaoAPI.pausarPrescricao(idAtendimento, user.idUsuario);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      pausarAtendimento(atendimento, tempoAtendimento.current!);
    }

    setValue(tempoAtendimento.current?.time);
    setTimeout(() => {
      navigate(route, { replace: true });
    }, 400);
  };

  useEffect(() => {
    const tempoAtendimentoCurrent = tempoAtendimento.current?.time || 0;

    return () => setValue(tempoAtendimentoCurrent);
  }, [setValue]);

  const pacientesItems: AvatarGroupItemsProps[] = pacientesGrupo.map(pac => ({
    label: pac.nome,
    imageUrl: pac.fotoUrl,
  }));

  const tituloPEP =
    pacientesGrupo.length === 1
      ? pacientesGrupo[0]!.nome
      : atendimento.apelidoGrupo || '';

  return (
    <Page id="cabecalho-prontuario" content white>
      <>
        <div className="p-d-flex p-w-100">
          <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontSize="md" medium>
              {tituloPEP}
            </SimpleText>
            <GroupAvatar
              items={pacientesItems}
              onClickBadge={() => dialogAtendimentoGrupoListaPacientes.open()}
            />
          </div>

          <div className="p-col-8 p-d-flex p-jc-end p-gap-2">
            <Button
              label={isMobile ? '' : 'Voltar para agenda'}
              btnType="gray"
              icon="fas fa-chevron-left"
              onClick={voltarParaAgenda}
              style={{
                height: '35px',
                minWidth: '35px',
              }}
            />

            {atendimentoStatus === 'ATENDENDO' && (
              <div style={{ position: 'relative' }}>
                <Button
                  label="Finalizar atendimento"
                  btnType="danger"
                  onClick={() => confirmarFinalizacaoConsulta.open()}
                  style={{
                    height: '35px',
                  }}
                />

                <Timer
                  tempoAtendimento={tempoAtendimento}
                  handlePausarAtendimento={handlePausarAtendimento}
                />
              </div>
            )}
          </div>
        </div>

        {confirmarFinalizacaoConsulta.isOpen && (
          <MenuConfirmarConsulta
            visible={confirmarFinalizacaoConsulta.isOpen}
            onHide={() => confirmarFinalizacaoConsulta.close()}
            idAtendimento={idAtendimento}
          />
        )}

        {sairPEPDialog.isOpen && <SairPEPDialog {...sairPEPDialog} />}

        {dialogAtendimentoGrupoListaPacientes.isOpen && (
          <AtendimentoGrupoDialogListaPacientes
            pacientes={pacientesGrupo}
            {...dialogAtendimentoGrupoListaPacientes}
          />
        )}
      </>
    </Page>
  );
}

const SairPEPDialog = ({ close, isOpen, state }: DisclosureType) => {
  const { atendimentoStatus } = useAtendimento();

  const pausarAtendimentoType = atendimentoStatus.includes('TRIAGEM')
    ? 'PAUSADO_TRIAGEM'
    : 'PAUSADO';

  const dialogText =
    atendimentoStatus === 'PRESCRICAO_EXECUCAO'
      ? 'Existe uma prescrição não executada ou dados não salvos, tem certeza que deseja sair?'
      : 'Você está saindo de um atendimento em andamento.';

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      closable={false}
      header="Sair do atendimento"
      className="dialog-sair-atendimento"
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <div>
          <SimpleText>{dialogText}</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-flex-sm-row p-gap-1 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Sair sem salvar"
            btnType="danger"
            onClick={() => close({ state: { ...state, type: 'DESCARTADO' } })}
            stretch
          />
          <Button
            label="Continuar depois"
            onClick={() =>
              close({ state: { ...state, type: pausarAtendimentoType } })
            }
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};
