import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import Doencas from '../Sorologia/Reagentes/Doencas';
import GlicemiaTtgo from '../Sorologia/Reagentes/GlicemiaTtgo';
import HiHb from '../Sorologia/Reagentes/HiHb';
import LeucocitosPlaquetas from '../Sorologia/Reagentes/LeucocitosPlaquetas';
import Pff from '../Sorologia/Reagentes/Pff';
import TshT4 from '../Sorologia/Reagentes/TshT4';
import Urocult from '../Sorologia/Reagentes/Urocult';

const aboRhOptions = [
  { label: 'A +', value: 'A_POSITIVO' },
  { label: 'B +', value: 'B_POSITIVO' },
  { label: 'AB +', value: 'AB_POSITIVO' },
  { label: 'O +', value: 'O_POSITIVO' },
  { label: 'A -', value: 'A_NEGATIVO' },
  { label: 'B -', value: 'B_NEGATIVO' },
  { label: 'AB -', value: 'AB_NEGATIVO' },
  { label: 'O -', value: 'O_NEGATIVO' },
];

const coombsIndiretoOptions = [
  { label: 'Positivo', value: 'POSITIVO' },
  { label: 'Negativo', value: 'NEGATIVO' },
];

interface SorologiaProps {
  idPreNatal: number | null;
  onHide: () => void;
}

const Sorologia = ({ idPreNatal, onHide }: SorologiaProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const useFormMethods = useForm();

  const { atendimentoStatus } = useAtendimento();

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    if (!idPreNatal) return;

    const payload = {
      ...data,
      idAtendimento,
    };

    await PreNatalAPI.updateSorologiaHistorico(idPreNatal, payload);
  };

  const renderReagentes = (children: JSX.Element) => (
    <div className="reagente-container p-grid p-col-12">{children}</div>
  );

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset
          className="p-grid p-col-12 p-p-0"
          disabled={!isAtendimentoRunning}
        >
          <DropdownControlled
            className="p-col-12 p-md-6"
            control={control}
            name="aboRh"
            label="ABO/RH"
            placeholder="Tipo"
            options={aboRhOptions}
            filter={false}
          />

          <DropdownControlled
            className="p-col-12 p-md-6"
            control={control}
            name="coombsIndireto"
            label="Coombs indireto"
            placeholder="Selecione o resultado"
            options={coombsIndiretoOptions}
            filter={false}
          />

          <VerticalTab
            filter
            filterLabel="Reagentes"
            tabItens={[
              {
                label: 'HI e HB',
                content: renderReagentes(<HiHb />),
              },
              {
                label: 'Leucócitos e plaquetas',
                content: renderReagentes(<LeucocitosPlaquetas />),
              },
              {
                label: 'Glicemia e TTGO',
                content: renderReagentes(<GlicemiaTtgo />),
              },
              {
                label: 'TSH e T4',
                content: renderReagentes(<TshT4 />),
              },
              {
                label: 'Urocult',
                content: renderReagentes(<Urocult />),
              },
              {
                label: 'PFF',
                content: renderReagentes(<Pff />),
              },
              {
                label: 'Doenças',
                content: renderReagentes(<Doencas />),
              },
            ]}
          />

          <div className="p-col-6">
            <Button
              btnType="ghost"
              label="Cancelar"
              className="p-col-align-center"
              onClick={onHide}
              loading={isSubmitting}
              stretch
            />
          </div>
          <div className="p-col-6">
            <Button
              type="submit"
              label="Salvar"
              loading={isSubmitting}
              stretch
            />
          </div>
        </fieldset>
      </form>
    </FormProvider>
  );
};

export default Sorologia;
