import { useCallback, useEffect, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { ToggleButton } from 'primereact/togglebutton';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import ImpressosAPI from 'src/APIs/ProntuarioAPI/ImpressosAPI/ImpressoaAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useMemed } from 'src/core/hooks/Atendimento/useMemed';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import ImpressoMemed from './ImpressoMemed';
import DesktopRow from './List/DesktopRow';

import './Impressos.scss';

export default function Impressos({ campos }: any) {
  const { state } = useLocation();
  const { memedStatus, isLoadingMemedImpressos } = useMemed();

  const idAtendimento = state?.idAtendimento;
  const { isLoading, isLoaded } = memedStatus;

  const [campoImpresso, setCampoImpresso] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(false);
  const [tiposImpressos, setTiposImpressos] = useState<any[]>([]);
  const [selectedTipoImpresso, setSelectedTipoImpresso] = useState<any>({});
  const [reload, setReload] = useState<number>(0);
  const { agenda, atendimento } = useAppSelector((state: RootState) => state);
  const { onCopy } = useCopyClipboard();

  const modalMemed = useDisclosure({ opened: false });

  const getTipoImpresso = useCallback(async () => {
    const response = await UtilsAPI.getTipoImpresso();
    if (response?.status === 200) {
      setTiposImpressos(response?.data);
    }
  }, []);

  useEffect(() => {
    getTipoImpresso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  const addImpressoOptions = tiposImpressos.map((tipoImpresso: any) => ({
    label: tipoImpresso.descricao,
    command: () => {
      setSelectedTipoImpresso(tipoImpresso);
      modalMemed.open();
    },
  }));

  const menuImpressos = useRef<any>(null);

  const reloadCampos = () => {
    if (campoImpresso.exibicaoCamposModelo === 'FIXO') {
      campoImpresso.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoImpresso.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoImpresso.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }

    const exibixaoCampo =
      campoImpresso.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';

    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoImpresso.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      setChecked(exibixaoCampo === 'FIXO' ? true : false);
      reloadCampos();
    }
  };

  useEffect(() => {
    setCampoImpresso(
      campos.filter((campo: any) => campo.funcionalidade === 'IMPRESSO')[0],
    );
  }, [campos]);

  useEffect(() => {
    if (campoImpresso) {
      setChecked(campoImpresso.exibicaoCamposModelo === 'FIXO' ? true : false);
    }
  }, [campoImpresso]);

  const fetchApi = useCallback(
    async (params: any): Promise<any> => {
      const response = await ImpressosAPI.getHistoricoImpressos(idAtendimento, {
        somenteAtendimento: true,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
      });
      return response;
    },
    [idAtendimento],
  );
  const handleCopiarLink = (impresso: any) => {
    onCopy(impresso.linkImpressoDigital);
    return toast(<Toast />, {
      data: {
        title: 'Sucesso',
        message: 'Link copiado com sucesso',
        type: 'success',
      },
    });
  };

  const onCopiarImpresso = (impresso: any) => {
    handleCopiarLink(impresso);
  };

  const onExcluirImpresso = async (impresso: any) => {
    await ImpressosAPI.deleteImpresso(impresso.id, idAtendimento);
    reloadList();
  };

  const renderRow = (impresso: any, isMobile: boolean) => {
    const newData = {
      ...impresso,
      titulo: `${impresso.tipoImpresso.descricao} - ${atendimento?.paciente?.nome}`,
    };
    const commomProps = {
      impresso: newData,
      onCopiarImpresso,
      onExcluirImpresso,
    };

    // if (isMobile) return <MobileRow {...commomProps} />;

    return <DesktopRow {...commomProps} />;
  };

  return (
    <div className="impressos-container p-mt-4">
      <div className="p-d-flex p-jc-between p-ai-center">
        <SimpleText
          className="p-ml-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Impressos
        </SimpleText>

        <div className="p-d-flex p-jc-end">
          <>
            <TieredMenu
              id="opcoes-menu"
              model={addImpressoOptions}
              popup
              ref={menuImpressos}
            />
            <Button
              label="Adicionar"
              iconPos="left"
              icon="pi pi-plus"
              className="p-mr-2"
              btnType="tonal"
              onClick={event => menuImpressos?.current?.toggle(event)}
              loading={isLoading || isLoadingMemedImpressos}
              disabled={!isLoaded}
            />
          </>

          <ToggleButton
            checked={checked}
            className="p-px-4 pin"
            onLabel={''}
            offLabel={''}
            onChange={e => {
              handleFixar();
              setChecked(e.value);
            }}
            onIcon="fa fa-thumbtack"
            offIcon="fa fa-thumbtack"
          />
        </div>
      </div>
      <div className="impresso-content">
        <SearchList.Root fetchApi={fetchApi}>
          <SearchList.InfiniteScroll
            renderRow={renderRow}
            reload={reload}
            customEmptyText="Atestados e orientações serão exibidos aqui. Adicione um novo,
            clicando no botão adicionar."
          />
        </SearchList.Root>
      </div>
      {modalMemed.isOpen && (
        <ImpressoMemed
          {...modalMemed}
          selectedTipoImpresso={selectedTipoImpresso}
          reloadList={reloadList}
        />
      )}
    </div>
  );
}
