import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAssistenciaVirtual } from '../../../../utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Filter, Header, MobileRow } from './List';

import './CentralUsuario.scss';
import useSize from 'src/core/hooks/useSize';

const CentralUsuario = () => {
  const [confirmDelete, setConfirmDelete] = useState<any>(null);
  const [reload, setReload] = useState<number>(0);
  const navigate = useNavigate();

  const { isMobile } = useSize();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_usuario_cadastrar',
      'adm_usuario_alterar',
      'adm_usuario_consultar',
      'adm_usuario_excluir',
    ],
  });

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const onEdit = (item: any) => {
    if (item.id) {
      navigate(`/cadastros/usuario/cadastro/${item.id}`, { state: item });
    }
  };

  const onDelete = async (item: any) => {
    try {
      await UsuarioAPI.removeUsuarioById(item.id, { throwError: true });
      setConfirmDelete(false);
      reloadList();
    } catch (error) {
      console.error('Erro ao deletar usuario', error);
    }
  };

  const handleBlock = async (data: any) => {
    const route = data.statusValue ? 'desbloquear' : 'bloquear';

    await UsuarioAPI.handleBlockChange(data?.id, route);

    reloadList();
  };

  const handleChangeStatus = async (data: any) => {
    const route = data.status === true ? 'inativar' : 'ativar';

    await UsuarioAPI.handleStatusChange(data?.id, route);

    reloadList();
  };

  const renderRow = (data: any, isMobile: boolean): JSX.Element => {
    const tipo = data?.tipo.toLowerCase();
    const healthProfessionalType =
      data?.profissionalSaude?.conselhoTissProfissional?.descricao;

    const userType = healthProfessionalType
      ? healthProfessionalType
      : tipo?.charAt(0).toUpperCase() + tipo?.slice(1);

    const newData = {
      id: data?.id,
      name: data?.nome,
      user_type: userType,
      link: 'Clínica Bolinha',
      vinculos: data.consultorios,
      login: data?.login,
      statusValue: data?.bloqueado,
      status: data?.status,
    };
    const userStatus = newData?.status === true ? 'Ativado' : 'Inativo';

    const vinculosNames =
      newData?.vinculos?.map((vinculo: any) => vinculo.nome) ?? [];

    const commonProps = {
      usuario: newData,
      userStatus,
      vinculosNames,
      onBlock: handleBlock,
      onDelete: setConfirmDelete,
      onEdit: onEdit,
      onChangeStatus: handleChangeStatus,
    };

    if (isMobile) return <MobileRow {...commonProps} />;

    return (
      <SearchList.CardItem>
        <DesktopRow {...commonProps} />
      </SearchList.CardItem>
    );
  };

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await UsuarioAPI.loadUsuarios({
      ...params,
    });
  };

  return (
    <>
      <SearchList.Root fetchApi={fetchApi} reloadWhenEmpresaChange>
        <SearchList.SearchInput
          label="Pesquisar usuários"
          placeholder="Digite nome, login, CRM ou CPF"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center">
            <Button
              type="button"
              label="Incluir novo usuário"
              btnType="pill"
              icon="fas fa-user-plus"
              onClick={() => navigate('/cadastros/usuario/cadastro')}
              checkPermission="ADM_USUARIO_CADASTRAR"
            />

            <div className="p-d-flex p-jc-between p-ai-center p-mt-3 p-mt-md-0">
              <SearchList.TotalCount className="p-mr-5" />
              <Filter />
            </div>
          </div>

          {!isMobile && <Header />}
          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ConfirmDialog
        visible={confirmDelete}
        header={'Excluir usuário'}
        text="O usuário será excluído da base de dados. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => {
          setConfirmDelete(null);
        }}
        onConfirm={() => {
          onDelete(confirmDelete);
        }}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default CentralUsuario;
