import Http, { HttpParams } from 'src/core/http/Http';

const getHistoricoAtendimentos = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'pep',
    url: `prontuario/atendimento/${idAtendimento}/historico`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getAtendimentoById = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `prontuario/atendimento/${idAtendimento}`,
    hideToast: true,
    ...options,
  });
};

const getHistoricoSOAP = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'pep',
    url: `prontuario/atendimento/${idAtendimento}/historico-soap`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getHistoricoSADT = (
  params: { idAtendimento: number | string; idConvenio: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'pep',
    url: `solicitacoes-procedimentos/${params.idAtendimento}/convenio/${params.idConvenio}/listar-procedimento`,
    hideToast: true,
    ...options,
  });
};

const putHistoricoSADTInativar = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `solicitacoes-procedimentos/${params.id}/${params.idAtendimento}/historico-sadt/inativar`,
    data: params,
    hideToast: true,
    ...options,
  });
};

const getCidsAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/atendimento/${idAtendimento}/cids`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getCiapsAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/atendimento/${idAtendimento}/ciaps`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getAlergiasAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/paciente-alergias/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getMedicamentosAtendimento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: '/medicamento-em-uso/pesquisa',
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getMotivoAtendimentos = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/motivo-atendimento/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getComorbidadesAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/comorbidade/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getPedidoCuidadoAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/pedido-cuidado/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getOPMEsAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/opme/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getQuestionarioAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/avaliacao-enfermagem/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getVacinasAtendimento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/paciente-vacina/pesquisa`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const getVacinaGeral = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/prontuario-persistente/pesquisa`,
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};

const getDocumentosAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `prontuario-documentos/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const inativarAtendimento = async (
  idAtendimento: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `prontuario/atendimento/${idAtendimento}/inativar`,
    return: true,
    data,
    ...options,
  });

const inativarCid = async (
  idAtendimento: number,
  idCid: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `atendimento/${idAtendimento}/cid/${idCid}/inativar`,
    return: true,
    data,
    ...options,
  });

const inativarCiap = async (
  idAtendimento: number,
  idCiap: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `atendimento/${idAtendimento}/ciap/${idCiap}/inativar`,
    return: true,
    data,
    ...options,
  });

const HistoricoAtendimentosAPI = {
  getHistoricoAtendimentos,
  getAtendimentoById,
  getHistoricoSOAP,
  getHistoricoSADT,
  getCidsAtendimento,
  getCiapsAtendimento,
  getAlergiasAtendimento,
  getMedicamentosAtendimento,
  getMotivoAtendimentos,
  getComorbidadesAtendimento,
  getPedidoCuidadoAtendimento,
  getOPMEsAtendimento,
  getQuestionarioAtendimento,
  getVacinasAtendimento,
  getDocumentosAtendimento,
  inativarAtendimento,
  inativarCid,
  inativarCiap,
  putHistoricoSADTInativar,
  getVacinaGeral,
};

export default HistoricoAtendimentosAPI;
