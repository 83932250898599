import useTheme from 'src/core/themes/useTheme';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './Card.scss';

type Props = {
  icon: string;
  title: string;
  subtitle: string;
  onAction(): void;
};

export const Card = ({ icon, title, subtitle, onAction }: Props) => {
  const { theme } = useTheme();

  return (
    <div
      className={`Card-home ${theme}`}
      onClick={onAction}
      style={{
        cursor: 'pointer',
      }}
    >
      <div className="p-col-10 p-d-flex p-flex-column">
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-px-0">
          <div className="btn-icon-card">
            <i className={icon} />
          </div>
          <SimpleText fontSize="md" fontColor="color_dark_green">
            {title}
          </SimpleText>
        </div>

        <SimpleText className="p-col-12 p-px-0" fontColor="color_40">
          {subtitle}
        </SimpleText>
      </div>

      <div className="p-col-2 p-text-right">
        <i className="fas fa-chevron-right" />
      </div>
    </div>
  );
};
