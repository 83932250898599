import { memo, useCallback } from 'react';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalExcluirEspecialidade.scss';

interface ModalExcluirEspecialidadeProps {
  visible: boolean;
  onHide(): void;
  especialidade: EspecialidadeDTO;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button btnType="danger" label="Sim, excluir" onClick={onAdd} stretch />
    </div>
  );
});

const ModalExcluirEspecialidade = ({
  especialidade,
  visible,
  onHide,
}: ModalExcluirEspecialidadeProps) => {
  const handleDeletarEspecialidade = useCallback(async () => {
    const response: any = await EspecialidadeAPI.removeEspecialidadeById(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      especialidade?.id!,
    );
    console.log({ response });
    if (response?.status === 204) {
      onHide();
    }
  }, [especialidade, onHide]);

  return (
    <Dialog
      className="footer-on modal-body-excluir-especialidade"
      type="modal"
      header="Excluir especialidade"
      visible={visible}
      onHide={onHide}
      footer={() => (
        <Footer onAdd={handleDeletarEspecialidade} onCancel={onHide} />
      )}
    >
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        Você realmente deseja excluir a especialidade da base de dados?
      </SimpleText>
    </Dialog>
  );
};

export default ModalExcluirEspecialidade;
