import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/tiss';

const getRetornoOperadorByGuiaPrestador = (
  guiaPrestador: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.get({
    service: 'pep',
    url: `${basePath}/dados-autorizacao/guia/${guiaPrestador}`,
    hideToast: true,
    ...options,
  });

const cancelarGuiasSadt = (
  idAtendimento: number,
  idConvenio: number,
  guias: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.post({
    service: 'pep',
    url: `${basePath}/cancelar-guia/convenio/${idConvenio}/atendimento/${idAtendimento}`,
    params: {
      guias,
    },
    hideToast: true,
    ...options,
  });

export const TissAPI = {
  getRetornoOperadorByGuiaPrestador,
  cancelarGuiasSadt,
};
