import { Page } from '../../_components/Page/Page';
import { PageHeader } from '../../_components/Page/PageHeader';
import Tab from 'src/components/Tab/Tab';

import { MeusAgendamentosHistorico } from './Historico/MeusAgendamentosHistorico';
import { MeusAgendamentosProximos } from './Proximos/MeusAgendamentosProximos';

import './MeusAgendamentos.scss';

export const MeusAgendamentos = () => {
  return (
    <Page>
      <>
        <PageHeader
          backButton
          hasBreadcrumbs
          title="Meus agendamentos"
          subtitle="Aqui estão todos os seu agendamentos."
        />

        <Tab
          style={{
            paddingInline: '5%',
          }}
          values={[
            {
              label: 'Próximos',
              content: <MeusAgendamentosProximos />,
            },
            {
              label: 'Histórico',
              content: <MeusAgendamentosHistorico />,
            },
          ]}
        />
      </>
    </Page>
  );
};
