import React, { useEffect } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import { Editor } from 'src/components/Basics/Editor/Editor';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalVisualizarEmailProps extends DisclosureType {
  email: any;
}

function ModalVisualizarEmail({
  isOpen,
  close,
  email,
}: ModalVisualizarEmailProps) {
  useEffect(() => {
    const getDiv = setTimeout(() => {
      const divTermo: any = document.querySelector('#email-html');

      divTermo.innerHTML = email;
    }, 50);

    return () => {
      clearTimeout(getDiv);
    };
  }, [email]);

  return (
    <Dialog
      id="visualizar-email"
      type="modal"
      header="Visualizar conteúdo do e-mail"
      visible={isOpen}
      onHide={close}
      maximizedMobileSize
      maximizedTabletSize
    >
      <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
        <div id="email-html"></div>
        <Button
          label="Fechar"
          onClick={() => close()}
          btnType="ghost"
          className="p-mt-2"
          stretch
        />
      </div>
    </Dialog>
  );
}

export default ModalVisualizarEmail;
