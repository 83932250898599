import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import TipoMotivoAtendimentoAPI from 'src/APIs/ConfigAPI/TipoMotivoAtendimento/TipoMotivoAtendimentoAPI';

import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

const DefinicaoMotivoAtendimento = () => {
  const [tiposMotivoList, setTiposMotivoList] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [edit, setEdit] = useState<any>(null);
  const [descricaoMotivo, setDescricaoMotivo] = useState<any>('');
  const [motivoSelected, setMotivoSelected] = useState<any>(null);

  const { control, setValue, getValues } = useFormContext();

  const {
    fields: motivoAtendimentosFields,
    append: appendMotivoAtendimentosFields,
    remove: removeMotivoAtendimentosFields,
    update: updateMotivoAtendimentosFields,
  } = useFieldArray({
    control,
    name: 'motivoAtendimentos',
  });

  const checkDescricaoIsDuplicate = useCallback(() => {
    if (!descricaoMotivo || !motivoSelected) return false;
    return (
      motivoAtendimentosFields?.some?.(
        (motivoAtendimento: any) =>
          motivoAtendimento.idMotivoAtendimento === motivoSelected?.id,
      ) || false
    );
  }, [descricaoMotivo, motivoAtendimentosFields, motivoSelected]);

  const handleAddMotivo = () => {
    const isDuplicate = checkDescricaoIsDuplicate();

    if (isDuplicate) {
      return toast(<Toast />, {
        data: {
          message:
            'Já existe um motivo de atendimento cadastrado com este nome e descricao.',
          type: 'warning',
        },
      });
    }

    appendMotivoAtendimentosFields({
      descricao: descricaoMotivo,
      idMotivoAtendimento: motivoSelected.id,
      motivoAtendimento: motivoSelected.descricao,
    });
    setDescricaoMotivo('');
    setMotivoSelected(null);
    return;
  };

  const handleRemove = (idx: number) => {
    removeMotivoAtendimentosFields(idx);
  };

  const handleUpdateMotivos = (data: any) => {
    if (edit === null) {
      return;
    }
    const isDuplicate = checkDescricaoIsDuplicate();
    if (isDuplicate) {
      return toast(<Toast />, {
        data: {
          message:
            'Já existe um motivo de atendimento cadastrado com este nome e descricao.',
          type: 'warning',
        },
      });
    }
    updateMotivoAtendimentosFields(edit.index, {
      descricao: data,
      idMotivoAtendimento: edit.id,
      motivoAtendimento: motivoSelected.descricao,
    });
    setDescricaoMotivo('');
    setIsUpdating(false);
    setEdit(null);
    return;
  };
  const handleUpdate = (motivo: any, index: any) => {
    setIsUpdating(true);
    setDescricaoMotivo(motivo.descricao);
    setMotivoSelected(
      tiposMotivoList.find(t => t.id === motivo.idMotivoAtendimento),
    );
    setEdit({ index: index, ...(motivo.id && { id: motivo.id }) });
  };

  const handleCancelUpdate = () => {
    setDescricaoMotivo('');
    setEdit(null);
    setMotivoSelected(null);
    setIsUpdating(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response =
          await TipoMotivoAtendimentoAPI.getTipoMotivoAtendimento(
            { pageSize: 1000 },
            { throwError: true },
          );
        setTiposMotivoList(response.list);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div className="definicao-container p-p-2">
      <Dropdown
        label="Motivo de atendimento"
        optionLabel="descricao"
        options={tiposMotivoList}
        value={motivoSelected}
        onChange={e => setMotivoSelected(e.value)}
      />
      <TextareaInput
        label="Descrição"
        value={descricaoMotivo}
        onChange={e => setDescricaoMotivo(e.target.value)}
        maxLength={4000}
        maxLengthSpanDesc
      />
      <Button
        label={
          isUpdating
            ? 'Editar o motivo de atendimento'
            : 'Adicionar o motivo de atendimento'
        }
        onClick={() =>
          isUpdating ? handleUpdateMotivos(descricaoMotivo) : handleAddMotivo()
        }
        icon="pi pi-plus"
        iconPos="left"
        disabled={!descricaoMotivo || !motivoSelected}
        stretch
      />
      {isUpdating && (
        <Button
          className="p-my-1"
          label={`Cancelar edição`}
          onClick={() => handleCancelUpdate()}
          icon="pi pi-times"
          iconPos="left"
          btnType="light-danger"
          disabled={!descricaoMotivo}
          stretch
        />
      )}

      <div className="p-col-12">
        <div
          className="border-container"
          style={{ overflowY: 'auto', maxHeight: '200px' }}
        >
          <div className="lista-respostas">
            {!motivoAtendimentosFields.length && (
              <Card className="p-mb-2 p-text-center" type={CARD_TYPES.GRAY}>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  Não há motivo de atendimento cadastrado.
                </SimpleText>
              </Card>
            )}

            {motivoAtendimentosFields.map((motivo: any, index) => {
              const idMotivoAtendimento =
                getValues(`motivoAtendimentos[${index}].idMotivoAtendimento`) ||
                motivo.idMotivoAtendimento;
              motivo.id = idMotivoAtendimento;
              return (
                <MotivoItem
                  key={idMotivoAtendimento}
                  index={index}
                  handleRemove={handleRemove}
                  motivo={motivo}
                  updateMotivo={handleUpdate}
                  tiposMotivoList={tiposMotivoList}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefinicaoMotivoAtendimento;

interface MotivoItemProps {
  motivo: any;
  index: number;
  tiposMotivoList: any[];
  updateMotivo: (motivo: any, index: any) => void;
  handleRemove: (idx: number) => void;
}
const MotivoItem = ({
  motivo,
  index,
  tiposMotivoList,
  updateMotivo,
  handleRemove,
}: MotivoItemProps) => {
  return (
    <div className="p-my-1">
      <Panel
        className="p-grid row-component p-align-center"
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            motivo={motivo}
            index={index}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            updateMotivo={updateMotivo}
            handleRemove={handleRemove}
          />
        )}
      >
        <PanelContent motivo={motivo} />
      </Panel>
    </div>
  );
};
