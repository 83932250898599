import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import TipoMotivoAtendimentoAPI from 'src/APIs/ConfigAPI/TipoMotivoAtendimento/TipoMotivoAtendimentoAPI';
import MotivoAtendimentoAPI from 'src/APIs/ProntuarioAPI/FichaPaciente/MotivoAtendimento/MotivoAtendimentoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import DataOcorrencia from '../../../components/DataOcorrencia/DataOcorrencia';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';

import { justificativaOcorrenciaRetroativaValidation } from '../../../FichaPaciente/helpers';

interface MotivoAtendimentoGrupoProps {
  editMotivo: any;
  idAtendimentoGrupo: number;
}

export const MotivoAtendimentoGrupo = ({
  editMotivo,
  idAtendimentoGrupo,
}: MotivoAtendimentoGrupoProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [motivoOptions, setMotivoOptions] = useState<any[]>([]);
  const [motivo, setMotivo] = useState(editMotivo);

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    motivo: Yup.number().required('Selecione um motivo de atendimento'),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(editMotivo?.dataInclusao).max(
        400,
        'O camp atual não deve possuir mais do que 400 caracteres.',
      ),
  });

  const form = useForm({
    resolver: yupResolver(validatioSchema),
  });

  useEffect(() => {
    if (motivo) {
      form.setValue('motivo', motivo.idTipoMotivoAtendimento);
      form.setValue('dataOcorrencia', new Date(motivo.dataOcorrencia));
      form.setValue('descricao', motivo.descricaoMotivo);
      form.setValue('lembrete', motivo.lembrete);
      form.setValue(
        'justificativaOcorrenciaRetroativa',
        motivo.justificativaOcorrenciaRetroativa,
      );
    }
  }, [motivo, form, form.setValue]);

  useEffect(() => {
    const getMotivo = async () => {
      const response =
        await TipoMotivoAtendimentoAPI.getBuscaMotivoAtendimento();

      const motivos = response.map(item => {
        return { label: item.descricao, value: item.id };
      });

      setMotivoOptions(motivos);
    };
    getMotivo();
  }, []);

  const onSubmit = async (data: any) => {
    const payload = {
      idAtendimento: idAtendimentoGrupo,
      idProfissionalSaude: user.idUsuario,
      idTipoMotivoAtendimento: data.motivo,
      descricaoMotivo: data.descricao,
      lembrete: data.lembrete,
      justificativaOcorrenciaRetroativa: data.justificativaOcorrenciaRetroativa,
      dataOcorrencia: dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
      idMotivoAtendimento: motivo && motivo.id,
    };

    try {
      if (!motivo) {
        const res = await MotivoAtendimentoAPI.postMotivoAtendimento(payload, {
          throwError: true,
        });
        setMotivo(res);
      } else {
        const res = await MotivoAtendimentoAPI.putMotivoAtendimento(payload, {
          throwError: true,
        });
        setMotivo(res);
      }
    } catch {}
  };

  const handleChangeDescricao = useThrottle(form.handleSubmit(onSubmit), 2000);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <DataOcorrencia
        control={form.control}
        defaultValue={motivo ? new Date(motivo.dataOcorrencia) : new Date()}
        dataInclusao={motivo?.dataInclusao}
      />

      <DropdownControlled
        control={form.control}
        name="motivo"
        label="Motivo"
        className="p-mb-2 p-md-12  "
        placeholder="Selecione o motivo"
        customOnChange={handleChangeDescricao}
        options={motivoOptions}
      />

      <TextareaInputControlled
        control={form.control}
        name="descricao"
        label="Descrição"
        className="grid p-col-12 p-md-12  p-mb-2"
        autoResize
        maxLength={1999}
        onCustomChange={handleChangeDescricao}
      />

      <CheckboxControlled
        control={form.control}
        name="lembrete"
        label="Exibir em 'lembretes'"
        onChangeCustom={handleChangeDescricao}
      />
    </form>
  );
};
