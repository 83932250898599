import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PontuacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PontuacaoFormularioDinamicoAPI/PontuacaoFormularioDinamicoAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

type Props = DisclosureType<IPontuacaoFormularioDinamicoItem | undefined> & {
  refetch(): void;
};

const validateSchema = Yup.object({
  nome: Yup.string().required('Campo obrigatório'),
  intervaloMinimo: Yup.number()
    .required('Campo obrigatório')
    .min(1, 'Valor mínimo é 1'),
  intervaloMaximo: Yup.number()
    .required('Campo obrigatório')
    .when('intervaloMinimo', (intervaloMinimo, schema) =>
      schema.test({
        test: (intervaloMaximo: number) => intervaloMaximo > intervaloMinimo,
        message: 'Intervalo máximo deve ser maior que intervalo minimo',
      }),
    ),
  mensagemPaciente: Yup.string().required('Campo obrigatório'),
});

type FormData = Yup.InferType<typeof validateSchema>;

export const AcervoFormularioCreatePontuacaoCreate = ({
  close,
  refetch,
  state,
}: Props) => {
  const item = state;

  const { id } = useParams();
  const form = useForm<FormData>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      ...(item && { ...item }),
    },
  });

  const onSubmit = async (v: FormData) => {
    if (!id) return;
    const payload = {
      ...v,
      idFormularioDinamico: Number(id),
    };

    try {
      if (item?.id) {
        await PontuacaoFormularioDinamicoAPI.updatePontuacaoFormularioDinamico({
          ...payload,
          id: item.id,
        }, { throwError: true });
      } else {
        await PontuacaoFormularioDinamicoAPI.createPontuacaoFormularioDinamico(
          payload,
          { throwError: true },
        );
      }

      return [close(), refetch()];
    } catch {
      return;
    }
  };

  return (
    <Dialog
      visible
      onHide={close}
      header="Nova pontuação"
      style={{
        width: '496px',
      }}
    >
      <FormProvider {...form}>
        <form
          className="p-col-12 p-p-0 p-flex-wrap p-d-flex"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormInput
            name="nome"
            label="Nome"
            sublabel="(Obrigatório)"
            // labelTooltip={TooltipField({
            //   description: '',
            //   id: 'InputNome',
            // })}
            className="p-col-12"
            hideTextErrorSpace
          />
          <FormInput
            name="intervaloMinimo"
            label="Intervalo mínimo"
            sublabel="(Obrigatório)"
            type="number"
            className="p-col-6"
          />
          <FormInput
            name="intervaloMaximo"
            label="Intervalo máximo"
            sublabel="(Obrigatório)"
            type="number"
            className="p-col-6"
          />
          <TextareaInputControlled
            control={form.control}
            name="mensagemPaciente"
            label="Mensagem para paciente"
            sublabel="(Obrigatório)"
            className="p-col-12"
            maxLength={500}
            maxLengthSpan
          />

          <div className="p-d-flex p-gap-2 p-col-12">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
              disabled={form.formState.isSubmitting}
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={form.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

// const TooltipField = ({
//   description,
//   id,
// }: {
//   description: string;
//   id: string;
// }) => {
//   return (
//     <>
//       <i className="fas fa-info-circle" id={id} data-pr-position="top" />
//       <Tooltip position="top" content={description} target={`#${id}`} />
//     </>
//   );
// };
