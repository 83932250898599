import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  mf: Yup.string().required('O campo é obrigatório'),
  dataOcorrencia: Yup.date()
    .typeError('O campo é obrigatório')
    .required('O campo é obrigatório'),
  au: Yup.string().required('O campo é obrigatório'),
  auUnidadeMedida: Yup.string().required('O campo é obrigatório'),
  bcf: Yup.string().required('O campo é obrigatório'),
  bcfUnidadeMedida: Yup.string().required('O campo é obrigatório'),
  edema: Yup.string().required('O campo é obrigatório'),
});

export default validationSchema;
