import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  lembrete: any;
  onEdit: (lembrete: any) => void;
  onDelete: (id: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { lembrete, onEdit, onDelete } = props;

  return (
    <div className="p-grid cardDesktop">
      <SimpleText
        className="p-col-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {lembrete.id}
      </SimpleText>

      <SimpleText
        className="p-col ellipsis"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {lembrete.mensagem}
      </SimpleText>

      <div className="p-col-fixed acoes-col  p-d-flex p-jc-end">
        <Button
          btnType="gray"
          icon="pi pi-pencil"
          onClick={() => onEdit(lembrete)}
          checkPermission="ADM_CONFIGURACAO_LEMBRETE_CONSULTA_ALTERAR"
        />

        <Button
          btnType="gray"
          icon="pi pi-trash"
          onClick={() => onDelete(lembrete.id)}
          className="p-ml-1"
          checkPermission="ADM_CONFIGURACAO_LEMBRETE_CONSULTA_EXCLUIR"
        />
      </div>
    </div>
  );
};
