import { useLocation, useParams } from 'react-router-dom';

import HistoricoAGAAPI from 'src/APIs/ProntuarioAPI/HistoricoAGAAPI/HistoricoAGAAPI';

import { useAGA } from 'src/core/hooks/AGA/useAGA';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import ModalEditarCopiarHistorico from '../ModalEditarCopiarHistorico/ModalEditarCopiarHistorico';
import { ModalInativarHistorico } from '../ModalInativarHistorico/ModalInativarHistorico';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

interface RowProps {
  historico: any;
}

const Row = ({ historico }: RowProps) => {
  const { isMobile } = useSize();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    historicoDialogDisclosure,
    setReloadHistorico,
    setIsEdit,
    setFormularioEdit,
  } = useAGA();

  const isRetroativo = historico.retroativo;
  const canEdit = historico.idAtendimento === Number(idAtendimento);
  const isInativo = historico.status === 'INATIVO';

  const inativarDisclosure = useDisclosure({ opened: false });
  const editarCopiarDisclosure = useDisclosure({ opened: false });

  const onInativar = () => {
    inativarDisclosure.open();
  };

  const onEdit = () => {
    setFormularioEdit(historico);
    setIsEdit(true);
  };

  const onCopiar = () => {
    setFormularioEdit(historico);
    setIsEdit(false);
  };

  const onDelete = async () => {
    try {
      await HistoricoAGAAPI.deleteHistoricoById(historico);
      setReloadHistorico((prev: number) => prev + 1);
    } catch {
      throw new Error('Erro ao deletar histórico');
    }
  };

  const commonProps = {
    historico,
    isRetroativo,
    canEdit,
    isInativo,
    onInativar,
    onEdit,
    onCopiar,
    onDelete,
    historicoDialogDisclosure,
  };

  return (
    <>
      {isMobile ? (
        <MobileRow {...commonProps} />
      ) : (
        <DesktopRow {...commonProps} />
      )}

      {inativarDisclosure.isOpen && (
        <ModalInativarHistorico {...inativarDisclosure} historico={historico} />
      )}

      {editarCopiarDisclosure.isOpen && (
        <ModalEditarCopiarHistorico
          {...editarCopiarDisclosure}
          canEdit={canEdit}
          historico={historico}
        />
      )}
    </>
  );
};

export default Row;
