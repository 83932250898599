import { useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import useSize from 'src/core/hooks/useSize';

import { useIsOverflow } from 'src/utils/hooks/useIsOverflow';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface DesktopRowProps {
  data: any;
  onEdit: (data: any) => void;
  onSwitchStatus: (data: any) => void;
  onDuplicar: (data: any) => void;
}

export const DesktopRow = ({
  data,
  onEdit,
  onSwitchStatus,
  onDuplicar,
}: DesktopRowProps) => {
  const { isLarge } = useSize();
  const menuEllipsis = useRef<TieredMenu>(null);

  const colNomeRef = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(colNomeRef);

  const style = {
    height: '50px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
  };

  const options: MenuItem[] = [
    {
      label: 'Duplicar',
      command: () => onDuplicar(data),
    },
  ];

  if (isLarge) {
    options.unshift({
      label: data?.status ? 'Inativar' : 'Ativar',
      command: () => onSwitchStatus(data),
    });

    options.push({
      label: 'Editar',
      command: () => onEdit(data?.id),
    });
  }

  return (
    <SearchList.CardItem>
      <div className="p-col-3 p-lg-2">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {data?.convenio?.nomeFantasia || data?.convenio?.razaoSocial || '-'}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {data?.guiaTiss?.descricao || '-'}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {data?.grupoTuss?.nome || '-'}
        </SimpleText>
      </div>
      <div className="p-col-1  p-px-0">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {data?.codigo || '-'}
        </SimpleText>
      </div>
      <div ref={colNomeRef} className="col-nome p-col-2  p-px-0">
        <SimpleText
          style={isOverflow ? style : { height: '50px' }}
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {data?.nome || '-'}
        </SimpleText>

        {isOverflow && (
          <>
            <Tooltip target="#coluna-nome-tooltip" />
            <span
              id="coluna-nome-tooltip"
              className={`fas fa-info-circle  `}
              data-pr-tooltip={data?.nome}
              data-pr-position="top"
            />
          </>
        )}
      </div>

      <div className="p-col-1  p-px-0">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {data?.classe}
        </SimpleText>
      </div>

      <>
        {!isLarge && (
          <div className="p-col-1 p-px-0">
            <Switch
              checkPermission="ADM_SERVICO_TUSS_ALTERAR"
              checked={data?.status}
              onChange={() => onSwitchStatus(data)}
            />
          </div>
        )}
      </>

      <div className="p-col-1 p-px-0 p-d-flex">
        {!isLarge && (
          <Button
            checkPermission="ADM_SERVICO_TUSS_ALTERAR"
            className="p-mr-2"
            btnType="gray"
            icon="pi pi-pencil"
            onClick={() => onEdit(data?.id)}
          />
        )}

        <Button
          btnType="gray"
          icon="pi pi-ellipsis-h"
          onClick={event => menuEllipsis?.current?.toggle(event)}
        />

        <TieredMenu
          className="panel-options-paciente"
          ref={menuEllipsis}
          model={options}
          popup
        />
      </div>
    </SearchList.CardItem>
  );
};
