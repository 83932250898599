import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import { PlanoCuidadoConfig } from './PlanoCuidadoConfig/PlanoCuidadoConfig';
import { PlanosPacientes } from './PlanosPacientes/PlanosPacientes';

export const PlanoCuidadoComp = () => {
  const tabs: any[] = [
    {
      label: 'Configuração de plano de cuidado',
      content: <PlanoCuidadoConfig />,
      checkPermission: 'ADM_PLANO_CUIDADO_CONFIGURACAO_CONSULTAR',
    },
    {
      label: 'Planos e pacientes',
      content: <PlanosPacientes />,
      checkPermission: 'PRO_PLANO_CUIDADO_GESTAO_CONSULTAR',
    },
  ];

  return (
    <Page id="plano-cuidado-config" white style={{ minHeight: '85vh' }}>
      <>
        <PageHeader
          title="Plano de cuidado"
          subtitle="Tenha uma visão detalhada sobre os planos de cuidados criados."
        />
        <Page className="page-content" content white>
          <Tab clean values={tabs} />
        </Page>
      </>
    </Page>
  );
};
