import Http, { HttpParams } from 'src/core/http/Http';

export const loadTipoGrupoAtendimentos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetTipoGrupoAtendimentoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: 'tipo-grupo-atendimento/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const sendTipoGrupoAtendimento = (
  payload: PostTipoGrupoAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostTipoGrupoAtendimentoDTO> => {
  return Http.post({
    service: 'agenda',
    url: '/tipo-grupo-atendimento',
    data: payload,
    ...options,
  });
};

export const updateTipoGrupoAtendimento = (
  id: number,
  payload: PostTipoGrupoAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostTipoGrupoAtendimentoDTO> => {
  return Http.put({
    service: 'agenda',
    url: `/tipo-grupo-atendimento/${id}`,
    data: payload,
    ...options,
  });
};

export const removeTipoGrupoAtendimento = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoGrupoAtendimentoDTO> => {
  return Http.delete({
    service: 'agenda',
    url: `/tipo-grupo-atendimento/${id}`,
    ...options,
  });
};

const TipoGrupoAtendimentoAPI = {
  loadTipoGrupoAtendimentos,
  sendTipoGrupoAtendimento,
  updateTipoGrupoAtendimento,
  removeTipoGrupoAtendimento,
};

export default TipoGrupoAtendimentoAPI;
