import { useCallback, useEffect, useState } from 'react';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import Panel from '../../components/Panel/Panel';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import Coombs from './Coombs/Commbs';
import PanelCoombs from './Historico/Coombs/PanelCoombs';
import PanelCoombsContent from './Historico/Coombs/PanelCoombsContent';
import PanelExames from './Historico/ExamesRealizados/PaneExames';
import PanelExamesContent from './Historico/ExamesRealizados/PanelExamesContent';
import PanelReagentes from './Historico/Reagentes/PanelReagentes';
import PanelReagentesContent from './Historico/Reagentes/PanelReagentesContent';
import Reagentes from './Reagentes/Reagentes';

import './Sorologia.scss';

const Sorologia = () => {
  const [historicoReagentes, setHistoricoReagentes] = useState<any>();
  const { preNatalAtual, selectedGestacao } = usePreNatal();
  const gestacao = selectedGestacao || preNatalAtual;

  const loadHistoricoReagentes = useCallback(async () => {
    if (!gestacao?.id) return;

    const response = await PreNatalAPI.getReagentes(gestacao.id);
    setHistoricoReagentes(response.data);
  }, [gestacao]);

  useEffect(() => {
    loadHistoricoReagentes();
  }, [loadHistoricoReagentes]);

  const hideHistorico =
    gestacao?.id === preNatalAtual?.id ? 'hide-historico' : '';

  const hideGestacaoAtual =
    gestacao?.id !== preNatalAtual?.id ? 'hide-gestacao-atual' : '';

  return (
    <>
      <div id="historico-sorologia-pre-natal" className={hideHistorico}>
        <SimpleText fontSize={FONT_SIZE.MD} className="p-ml-1" medium>
          Histórico de sorologia
        </SimpleText>

        <div className="p-my-1 p-mx-2" id="historico-sorologia">
          <div className="p-my-1">
            <Panel
              header={(isCollapsed, onToggle) => (
                <PanelCoombs isCollapsed={isCollapsed} onToggle={onToggle} />
              )}
            >
              <PanelCoombsContent idGestacao={gestacao?.id} />
            </Panel>
          </div>
        </div>
        <div className="p-my-1 p-mx-2" id="historico-reagentes">
          <div className="p-my-1">
            <Panel
              header={(isCollapsed, onToggle) => (
                <PanelReagentes isCollapsed={isCollapsed} onToggle={onToggle} />
              )}
            >
              <PanelReagentesContent
                idGestacao={gestacao?.id}
                historicoReagentes={historicoReagentes}
              />
            </Panel>
          </div>
        </div>
        <div className="p-my-1 p-mx-2" id="historico-exames">
          <div className="p-my-1">
            <Panel
              header={(isCollapsed, onToggle) => (
                <PanelExames isCollapsed={isCollapsed} onToggle={onToggle} />
              )}
            >
              <PanelExamesContent
                idGestacao={gestacao?.id}
                historicoReagentes={historicoReagentes}
              />
            </Panel>
          </div>
        </div>
      </div>
      <div id="sorologia-pre-natal" className={hideGestacaoAtual}>
        <Coombs />
        <Reagentes />
      </div>
    </>
  );
};

export default Sorologia;
