import { useState, useCallback, useEffect, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

interface HealthProfessionalProps {
  control: any;
  errors: any;
  setValue: (fieldName: any, value: any) => void;
}

function HealthProfessional({ setValue }: HealthProfessionalProps) {
  const { mandatoryData } = useCreateUser();
  const { control } = useFormContext();

  const [conselhoTiss, setConselhoTiss] = useState<any>([]);
  const [conselhoTissProf, setConselhoTissProf] = useState<any>([]);
  const [estados, setEstados] = useState<any>([]);

  const handleSelectConselhoTiss = useCallback(
    async (codigoConselhoTiss: string) => {
      return new Promise(async resolve => {
        const conselhotissprof =
          await ProfissionalAPI.retrieveConselhoTissProfissional(
            codigoConselhoTiss,
          );

        setConselhoTissProf(conselhotissprof);

        resolve(conselhotissprof);
      });
    },
    [],
  );

  const fetchUtilsData = useCallback(async () => {
    const [estados, conselhotiss] = await Promise.all([
      UtilsAPI.GetEstados(),
      ProfissionalAPI.retrieveConselhoTiss(),
    ]);

    setConselhoTiss(conselhotiss);
    setEstados(estados);
  }, []);

  const setFormValues = useCallback(async () => {
    if (mandatoryData?.profissionalSaude?.conselhoTiss) {
      await handleSelectConselhoTiss(
        mandatoryData?.profissionalSaude?.conselhoTiss?.codigo,
      );
    }

    setValue(
      'numeroRegistro',
      mandatoryData?.profissionalSaude?.numeroRegistro,
    );
    setValue(
      'idEstadoRegistro',
      mandatoryData?.profissionalSaude?.idEstadoRegistro,
    );
    setValue(
      'profissional',
      mandatoryData?.profissionalSaude?.conselhoTissProfissional,
    );
    setValue('conselhoTiss', mandatoryData?.profissionalSaude?.conselhoTiss);
  }, [mandatoryData, setValue, handleSelectConselhoTiss]);

  const load = useCallback(async () => {
    fetchUtilsData();
    setFormValues();
  }, [fetchUtilsData, setFormValues]);

  useEffect(() => {
    load();
  }, [load]);

  const conselhoTissOptionsFormatted = useMemo(
    () =>
      conselhoTiss.map((conselhoTissItem: any) => ({
        label: conselhoTissItem.descricao,
        value: conselhoTissItem,
      })),
    [conselhoTiss],
  );

  const conselhoTissProfOptionsFormatted = useMemo(
    () =>
      conselhoTissProf.map((conselhoTissProfItem: any) => ({
        label: conselhoTissProfItem.descricao,
        value: conselhoTissProfItem,
      })),
    [conselhoTissProf],
  );

  const estadosOptionsFormated = useMemo(
    () =>
      estados.map((estadoItem: any) => ({
        label: estadoItem.nome,
        value: estadoItem.id,
      })),
    [estados],
  );

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Informações profissionais
      </SimpleText>

      <DropdownControlled
        control={control}
        name="conselhoTiss"
        className="p-col-12"
        label={'Conselho TISS'}
        options={conselhoTissOptionsFormatted}
        customOnChange={value => handleSelectConselhoTiss(value?.codigo)}
      />
      <DropdownControlled
        control={control}
        name="profissional"
        className="p-col-12"
        label={'Profissional'}
        options={conselhoTissProfOptionsFormatted}
      />

      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Registro no conselho
      </SimpleText>

      <FormInput
        name="numeroRegistro"
        type="number"
        label="Número registro"
        className="p-col-12 p-md-6"
      />
      <DropdownControlled
        control={control}
        name="idEstadoRegistro"
        className="p-col-12 p-md-6"
        label={'Estado registro'}
        options={estadosOptionsFormated}
      />
    </>
  );
}

export default HealthProfessional;
