import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  complexidade: ComplexidadePacienteDTO;
  onEdit: (complexidade: ComplexidadePacienteDTO) => void;
  onDelete: (id: number) => void;
  complexidadeStatus: JSX.Element;
  consultoriosComplexidade: JSX.Element;
  dataInclusao: string;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const {
    complexidade,
    onEdit,
    onDelete,
    complexidadeStatus,
    consultoriosComplexidade,
    dataInclusao,
  } = props;

  return (
    <CardItem>
      <SimpleText
        className="p-col-2 p-lg-3 p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {complexidade.descricao}
      </SimpleText>
      <SimpleText
        className="p-col-2 p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_40}
      >
        {complexidade.pontuacao}
      </SimpleText>

      {consultoriosComplexidade}

      <SimpleText
        className="p-col-2 p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_40}
      >
        {dataInclusao}
      </SimpleText>

      <div className="p-md-4 p-lg-3 p-d-flex p-ai-center p-jc-end p-pl-0 fixed">
        <>
          {complexidadeStatus}
          <Can
            checkPermission={[
              'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_ALTERAR',
              'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_EXCLUIR',
            ]}
          >
            <Divider layout="vertical" />
          </Can>
          <Button
            btnType="gray"
            className="p-mr-2"
            icon="pi pi-pencil"
            onClick={() => onEdit(complexidade)}
            checkPermission="ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_ALTERAR"
          />

          <Button
            btnType="gray"
            icon="pi pi-trash"
            onClick={() => onDelete(complexidade.id)}
            checkPermission="ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_EXCLUIR"
          />
        </>
      </div>
    </CardItem>
  );
};
