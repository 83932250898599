import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import AlterarSenhaAPI from 'src/APIs/SecurityAPI/AlterarSenhaAPI/AlterarSenhaAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { validatePasswordRules } from 'src/utils/formUtils';

import { Button } from 'src/components/_UI';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './MeusDados.scss';

export const MeusDadosDialogAlterarSenha = ({
  close,
  state: cpf,
}: DisclosureType) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      senha: '',
      confirmarSenha: '',
    },
    resolver: yupResolver(
      Yup.object({
        senha: Yup.string()
          .matches(
            /^(?=.*[A-Z])/,
            'A senha deve conter pelo menos 1 letra maiúscula',
          )
          .matches(/^(?=.*[0-9])/, 'A senha deve conter pelo menos 1 número')
          .matches(
            /^(?=.*[!@#\$%\^&\*])/,
            'A senha deve conter pelo menos 1 caractere especial',
          ),
        confirmarSenha: Yup.string()
          .oneOf([Yup.ref('senha'), null], 'As senhas não são iguais.')
          .required('Campo obrigatório'),
      }),
    ),
  });

  const [watchSenha, watchConfirmar]: string[] = form.watch([
    'senha',
    'confirmarSenha',
  ]);
  const hasErrors = !!Object.keys(form.formState.errors)?.length;

  const {
    hasn8Digits,
    hasnCapitalLetter,
    hasnNumber,
    hasnSpecialCharacter,
    validateClassName,
  } = validatePasswordRules(watchSenha);

  const onSubmit = (v: any) => {
    return AlterarSenhaAPI.sendAlterarSenha(
      {
        email: cpf,
        senhaLogin: v.senhaAtual,
        novaSenha: v.senha,
      },
      { throwError: true, return: false },
    )
      .then(() => close())
      .catch(() => {});
  };

  return (
    <Dialog
      onHide={close}
      draggable={false}
      visible
      header="Alterar senha"
      id="DialogAlterarSenha"
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-column p-gap-2"
        >
          <SimpleText className="p-mb-2">
            Altere a senha de seu cadastro
          </SimpleText>

          <PasswordInputControlled
            control={form.control}
            name="senhaAtual"
            label="Senha atual"
            sublabel="(Obrigatório)"
            className="p-mb-2"
            toggleMask
          />

          <PasswordInputControlled
            control={form.control}
            name="senha"
            label="Nova senha"
            sublabel="(Obrigatório)"
            toggleMask
          />

          <div className="p-d-flex p-flex-column p-gap-2">
            <SimpleText>A nova senha precisa conter, pelo menos:</SimpleText>
            <SimpleText>
              <ul className="p-px-4">
                <li className={validateClassName(hasn8Digits)}>8 dígitos</li>
                <li className={validateClassName(hasnSpecialCharacter)}>
                  1 caractere especial (como !, @, #, etc.)
                </li>
                <li className={validateClassName(hasnCapitalLetter)}>
                  1 letra maíuscula
                </li>
                <li className={validateClassName(hasnNumber)}>1 número</li>
              </ul>
            </SimpleText>
          </div>

          <PasswordInputControlled
            control={form.control}
            name="confirmarSenha"
            label="Confirme sua nova senha"
            sublabel="(Obrigatório)"
            toggleMask
          />

          <div className="p-d-flex p-gap-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              className="p-mt-2"
              onClick={() => close()}
              stretch
              disabled={form.formState.isSubmitting}
            />
            <Button
              label="Alterar"
              type="submit"
              className="p-mt-2"
              disabled={!watchSenha || !watchConfirmar || hasErrors}
              stretch
              loading={form.formState?.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
