import { useState, useEffect } from 'react';

import { useLocation } from 'react-router';

import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { SolicitacaoExameCirurgiaProvider } from 'src/core/containers/Atendimento/SolicitacaoExameCirurgiaProvider';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { FieldMultiselectServicoTuss } from '../../../../components/FieldMultiselectServicoTuss/FieldMultiselectServicoTuss';
import { FieldCartoesPaciente } from '../components/FieldCartoesPaciente/FieldCartoesPaciente';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import { BuscaRapida } from './BuscaRapida';
import { HistoricoExamesResultados } from './HistoricoExamesResultados/HistoricoExamesResultados';
import { ProcedimentosSolicitadosList } from './ProcedimentosSolicitadosList';

import './SolicitacaoExameCirurgia.scss';

const SolicitacaoExame = () => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimento, user } = useAppSelector((state: RootState) => state);

  const {
    idConvenioAtendimento,
    idConvenioProcedimentos,
    setIdConvenioProcedimentos,
    numeroCartaoAtendimentoParticular,
    setNumeroCartaoAtendimentoParticular,
    atendimentoStatus,
    paciente,
  } = useAtendimento();

  const {
    idConvenio,
    handleUpdateProcedimentosInGuias,
    setIdConvenio,
    fetchProcedimentosSolicitados,
  } = useSolicitacaoExameCirurgia();

  const confirmChangeConvenioProcedimento = useDisclosure({ opened: false });

  const [favoriteProcedimentoChanged, setFavoriteProcedimentoChanged] =
    useState<any>(null);
  const [visualizarHistoricoSadtTopo, setVisualizarHistoricoSadtTopo] =
    useState(false);

  const handleChangeConvenio = async (e: any) => {
    confirmChangeConvenioProcedimento.open({ state: e.value });
  };

  const handleConfirmChangeConvenio = async () => {
    const { idConvenio, numeroCartao } =
      confirmChangeConvenioProcedimento.state;

    const [response] = await Promise.all([
      SolicitacoesProcedimentosAPI.putConvenioProcedimentos(
        Number(idAtendimento),
        idConvenio,
        {
          params: { numeroCartao },
          return: true,
        },
      ),
      SolicitacoesProcedimentosAPI.deleteAllProcedimentos(
        Number(idAtendimento),
      ),
    ]);

    fetchProcedimentosSolicitados();

    if (response.status === 200) {
      setNumeroCartaoAtendimentoParticular(numeroCartao);
      setIdConvenioProcedimentos(idConvenio);
      setIdConvenio(idConvenio);
    }

    confirmChangeConvenioProcedimento.close();
  };

  useEffect(() => {
    setIdConvenio(idConvenioProcedimentos ?? idConvenioAtendimento);
  }, [idConvenioAtendimento, idConvenioProcedimentos, setIdConvenio]);

  useEffect(() => {
    const fetchVisualizarHistoricoSadtTopo = async () => {
      const response = await ParametroAPI.loadParametros({
        codigoParametro: 'VISULIZAR_HISTORICO_SADT_TOPO',
      });

      if (response?.list?.length && response?.list[0]) {
        if (!response.list[0]?.listaCliente?.length) {
          return setVisualizarHistoricoSadtTopo(
            response?.list[0].valor === 'S',
          );
        }

        const parametroPersonalizadoEmpresa =
          response.list[0]?.listaCliente.find(
            cliente => cliente.idEmpresa === user.idEmpresa,
          );

        setVisualizarHistoricoSadtTopo(
          parametroPersonalizadoEmpresa?.valor === 'S',
        );
      }
    };

    fetchVisualizarHistoricoSadtTopo();
  }, [user.idEmpresa]);

  const handleAddProcedimentos = async (procedimentosSelected: any[]) => {
    try {
      const payload = procedimentosSelected.map((solicitacao: any) => ({
        descricaoProcedimento: solicitacao.nome,
        codigoProcedimento: solicitacao?.codigo,
        idServicoTuss: solicitacao.id,
        idConvenio: solicitacao.idConvenio,
        ...(numeroCartaoAtendimentoParticular && {
          numeroCartaoAtendimentoParticular,
        }),
      }));

      const response = await SolicitacoesProcedimentosAPI.postProcedimento(
        Number(idAtendimento),
        payload,
      );

      handleUpdateProcedimentosInGuias(response);
    } catch (error) {}
  };

  return (
    <div id="solicitaca-exame-container" className="p-grid p-p-0">
      <Card type={CARD_TYPES.BORDER} className="p-grid p-p-3 p-order-1">
        <SimpleText
          className="p-col-12 p-px-0"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Solicitação de exames e cirurgias
        </SimpleText>

        {(atendimento.particular || atendimento.retorno) && (
          <FieldCartoesPaciente
            className="p-col-12 p-md-6 p-lg-4 p-px-0"
            label="Convênio"
            numeroCartaoAtendimentoParticular={
              numeroCartaoAtendimentoParticular
            }
            idConvenio={idConvenio}
            idPaciente={paciente?.id}
            onChange={handleChangeConvenio}
          />
        )}

        <div className="p-align-end p-col-12 p-grid p-p-0 p-mb-3">
          <div className="p-mr-3 p-col p-sm-7 p-md-6 p-p-0">
            <FieldMultiselectServicoTuss
              handleAddProcedimentos={handleAddProcedimentos}
              disabled={atendimentoStatus !== 'ATENDENDO'}
              canFavorite
              params={{
                idConvenio,
                idAtendimento,
              }}
            />
          </div>

          <div className="p-col-fixed p-pb-0">
            <Dropdown
              label="Versão"
              value="TUSS"
              name="versao"
              filter={false}
              options={[{ value: 'TUSS', label: 'TUSS' }]}
            />
          </div>
        </div>

        <BuscaRapida
          favoriteProcedimentoChanged={favoriteProcedimentoChanged}
          selectedConvenioId={idConvenio}
        />

        <ProcedimentosSolicitadosList />
      </Card>

      <HistoricoExamesResultados
        className={
          visualizarHistoricoSadtTopo ? 'p-order-0 p-mb-4' : 'p-order-2 p-mt-4'
        }
      />

      {confirmChangeConvenioProcedimento.isOpen && (
        <ConfirmDialog
          header="Você realmente deseja trocar de convênio?"
          text="Para trocar de convênio as solicitações já feitas, serão excluídas aqui no sistema e canceladas na operadora! Para continuar clique em sim."
          visible={true}
          onHide={() => confirmChangeConvenioProcedimento.close()}
          onConfirm={handleConfirmChangeConvenio}
        />
      )}
    </div>
  );
};

export const SolicitacaoExameCirurgia = () => (
  <SolicitacaoExameCirurgiaProvider>
    <SolicitacaoExame />
  </SolicitacaoExameCirurgiaProvider>
);
