import { memo } from 'react';

import {
  Tooltip as PrimeTooltip,
  TooltipProps as PrimeTooltipProps,
} from 'primereact/tooltip';
import './Tooltip.scss';

type TooltipProps = PrimeTooltipProps & {
  children?: any;
};

const Tooltip = (props: TooltipProps) => {
  const { content, children } = props;
  return (
    <PrimeTooltip {...props} className={`Tooltip ${props.className ?? ''}`}>
      {!content && !!children && children}
    </PrimeTooltip>
  );
};

export default memo(Tooltip);
