import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const BuscaAvancadaHeader = () => {
  return (
    <div className="p-col-12 p-d-flex">
      <SimpleText className="p-col-2">Data Atendimento • Status</SimpleText>
      <SimpleText className="p-col-2">Paciente • CPF/Registro</SimpleText>
      <SimpleText className="p-col-1">CID</SimpleText>
      <SimpleText className="p-col">Anamnese</SimpleText>
      <SimpleText className="p-col">Exame físico</SimpleText>
      <SimpleText className="p-col">Patologia detectada</SimpleText>
      <SimpleText className="p-col">Prognóstico</SimpleText>
      <SimpleText className="p-col">Decisão terapêutica</SimpleText>
      <SimpleText className="p-col">Descrição TUSS</SimpleText>
    </div>
  );
};
