import { useEffect } from 'react';

import { Control, useFormContext } from 'react-hook-form';

import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface PerfilSaudeProps {
  readOnly?: boolean | undefined;
  values?: Questionarios | undefined;
}

enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_LEMBRO = 'NAO_LEMBRO',
  NAO_SE_APLICA = 'NAO_SE_APLICA',

  FAMILIA = 'FAMILIA',
  NINGUEM = 'NINGUEM',
  BENEFICIARIO = 'BENEFICIARIO',
  BENEFICIARIO_FAMILIA = 'BENEFICIARIO_FAMILIA',
  EVITADA = 'EVITADA',
  SATISFEITO = 'SATISFEITO',
  INSATISFEITO = 'INSATISFEITO',
  PARCIALMENTE_SATISFEITO = 'PARCIALMENTE_SATISFEITO',
  ATUALIZADO = 'ATUALIZADO',
  EM_ATRASO = 'EM_ATRASO',
  COLUNA_LOMBAR = 'COLUNA_LOMBAR',
  FEMUR_TOTAL = 'FEMUR_TOTAL',
  COLO_FEMURAL = 'COLO_FEMURAL',
}

const PerfilSaude = ({ values, readOnly }: PerfilSaudeProps) => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();

  const perfilNaoSeAplica = watch('perfilNaoSeAplica');

  const alimentacaoNaoSeAplica = watch('alimentacaoNaoSeAplica');
  const watchEquilibrada = watch('equilibrada');
  const watchExcessoGorduras = watch('excessoGorduras');
  const watchExcessoSal = watch('excessoSal');
  const watchExcessoCarboidratos = watch('excessoCarboidratosAcucares');

  const cirurgiasNaoSeAplica = watch('cirurgiasNaoSeAplica');
  const watchCardiaca = watch('cardiaca');
  const watchCancer = watch('cancer');
  const watchAmputacaoMembros = watch('amputacaoPernaPe');
  const watchOutra = watch('outra');
  const watchBariatrica = watch('bariatrica');
  const watchNaoRealizouCirurgia = watch('naoRealizou');

  const densitometriaNaoSeAplica = watch('densitometriaNaoSeAplica');
  const watchColunaLombar = watch('colunaLombar');
  const watchFemurTotal = watch('femurTotal');
  const watchColoFemural = watch('coloFemural');
  const watchNaoRealizouDensitometria = watch('naoRealizouDensitometria');

  useEffect(() => {
    if (typeof perfilNaoSeAplica === 'boolean') {
      const valueNaoSeAplica = perfilNaoSeAplica
        ? Values.NAO_SE_APLICA
        : undefined;

      setValue('satisfacaoSaude', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('diabetes', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('hipertensao', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('colesterolOuTriglicerides', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('doencasCerebrovasculares', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('doencasCardiovasculares', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('doencasRespiratorias', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('insuficienciaRenal', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('doencasNeurologicas', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('cirurgiaColunaProgramada', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });
      setValue('usuarioDrogas', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });
      setValue('calendarioVacinal', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });
      setValue('tomouVacinaGripe', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });
      setValue('gestante', valueNaoSeAplica, {
        shouldValidate: perfilNaoSeAplica,
      });

      // Alimentação
      setValue('alimentacaoNaoSeAplica', true, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('equilibrada', undefined);
      setValue('excessoGorduras', undefined);
      setValue('excessoSal', undefined);
      setValue('excessoCarboidratosAcucares', undefined);

      // cirurgia
      setValue('cirurgiasNaoSeAplica', true, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('cardiaca', undefined);
      setValue('cancer', undefined);
      setValue('amputacaoPernaPe', undefined);
      setValue('bariatrica', undefined);
      setValue('outra', undefined);
      setValue('naoRealizou', undefined);

      // densitometria
      setValue('densitometriaNaoSeAplica', true, {
        shouldValidate: perfilNaoSeAplica,
      });

      setValue('colunaLombar', undefined);
      setValue('femurTotal', undefined);
      setValue('coloFemural', undefined);
      setValue('naoRealizouDensitometria', undefined);
    }
  }, [perfilNaoSeAplica, setValue]);

  useEffect(() => {
    if (alimentacaoNaoSeAplica) {
      setValue('equilibrada', undefined);
      setValue('excessoGorduras', undefined);
      setValue('excessoSal', undefined);
      setValue('excessoCarboidratosAcucares', undefined);
    }

    if (cirurgiasNaoSeAplica) {
      setValue('cardiaca', undefined);
      setValue('cancer', undefined);
      setValue('amputacaoPernaPe', undefined);
      setValue('bariatrica', undefined);
      setValue('outra', undefined);
      setValue('naoRealizou', undefined);
    }

    if (densitometriaNaoSeAplica) {
      setValue('colunaLombar', undefined);
      setValue('femurTotal', undefined);
      setValue('coloFemural', undefined);
      setValue('naoRealizouDensitometria', undefined);
    }
  }, [
    alimentacaoNaoSeAplica,
    cirurgiasNaoSeAplica,
    densitometriaNaoSeAplica,
    setValue,
  ]);

  useEffect(() => {
    if (
      watchEquilibrada ||
      watchExcessoGorduras ||
      watchExcessoSal ||
      watchExcessoCarboidratos
    ) {
      setValue('alimentacaoNaoSeAplica', undefined, { shouldValidate: true });
    }
  }, [
    watchColunaLombar,
    watchFemurTotal,
    watchColoFemural,
    watchNaoRealizouDensitometria,
    setValue,
    watchEquilibrada,
    watchExcessoGorduras,
    watchExcessoSal,
    watchExcessoCarboidratos,
  ]);

  useEffect(() => {
    if (
      watchCardiaca ||
      watchCancer ||
      watchAmputacaoMembros ||
      watchOutra ||
      watchBariatrica ||
      watchNaoRealizouCirurgia
    ) {
      setValue('cirurgiasNaoSeAplica', undefined, { shouldValidate: true });
    }
  }, [
    watchColunaLombar,
    watchFemurTotal,
    watchColoFemural,
    watchNaoRealizouDensitometria,
    setValue,
    watchCardiaca,
    watchCancer,
    watchAmputacaoMembros,
    watchOutra,
    watchBariatrica,
    watchNaoRealizouCirurgia,
  ]);

  useEffect(() => {
    if (
      watchColunaLombar ||
      watchFemurTotal ||
      watchColoFemural ||
      watchNaoRealizouDensitometria
    ) {
      setValue('densitometriaNaoSeAplica', undefined, { shouldValidate: true });
    }
  }, [
    watchColunaLombar,
    watchFemurTotal,
    watchColoFemural,
    watchNaoRealizouDensitometria,
    setValue,
  ]);

  return (
    <Template
      title="Perfil saúde"
      name="perfilNaoSeAplica"
      readOnly={readOnly}
      resetField={resetField}
      control={control}
    >
      <div className="container-perfil">
        <CardQuestions
          title="Como anda sua satisfação com a sua saúde?"
          name="satisfacaoSaude"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.satisfacaoSaude}
          error={errors?.satisfacaoSaude?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="satisfacaoSaude"
            unstyled
            dir="row"
            items={[
              {
                label: 'Satisfeito',
                value: Values.SATISFEITO,
              },
              {
                label: 'Insatisfeito',
                value: Values.INSATISFEITO,
              },
              {
                label: 'Parcialmente satisfeito',
                value: Values.PARCIALMENTE_SATISFEITO,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem diabetes?"
          name="diabetes"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.diabetes}
          error={errors?.diabetes?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="diabetes"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem pressão alta (hipertensão arterial) ?"
          name="hipertensao"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.hipertensao}
          error={errors?.hipertensao?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="hipertensao"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem colesterol ou triglicérides altos?"
          name="colesterolOuTriglicerides"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.colesterolOuTriglicerides}
          error={errors?.colesterolOuTriglicerides?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="colesterolOuTriglicerides"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças cerebrovasculares (ex: AVC, Derrame ou Aneurisma) ?"
          name="doencasCerebrovasculares"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.doencasCerebrovasculares}
          error={errors?.doencasCerebrovasculares?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="doencasCerebrovasculares"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças cardiovasculares (ex: Infarto ou IAM, Insuficiência cardíaca, Angina ou Arritmia) ?"
          name="doencasCardiovasculares"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.doencasCardiovasculares}
          error={errors?.doencasCardiovasculares?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="doencasCardiovasculares"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças respiratórias (ex: Doença obstrutiva pulmonar crônica ou Asma) ?"
          name="doencasRespiratorias"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.doencasRespiratorias}
          error={errors?.doencasRespiratorias?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="doencasRespiratorias"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem insuficiência renal crônica ?"
          name="insuficienciaRenal"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.insuficienciaRenal}
          error={errors?.insuficienciaRenal?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="insuficienciaRenal"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças neurológicas (ex: Depressão, Ansiedade, Demência, Alzheimer ou Parkinson) ?"
          name="doencasNeurologicas"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.doencasNeurologicas}
          error={errors?.doencasNeurologicas?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="doencasNeurologicas"
            unstyled
            dir="row"
            items={[
              {
                label: 'Beneficiário',
                value: Values.BENEFICIARIO,
              },
              {
                label: 'Família',
                value: Values.FAMILIA,
              },
              {
                label: 'Beneficiário e família',
                value: Values.BENEFICIARIO_FAMILIA,
              },
              {
                label: 'Ninguém',
                value: Values.NINGUEM,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Como anda sua alimentação ?"
          name="alimentacaoNaoSeAplica"
          checkbox
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.avaliacaoEnfermagemAlimentacao?.naoSeAplica}
          error={errors.alimentacaoNaoSeAplica?.message}
        >
          <div className="p-grid p-gap-2">
            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="equilibrada"
              label="Equilibrada"
              disabled={readOnly || perfilNaoSeAplica || alimentacaoNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="excessoGorduras"
              label="Excesso de gorduras"
              disabled={readOnly || perfilNaoSeAplica || alimentacaoNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="excessoSal"
              label="Excesso de sal"
              disabled={readOnly || perfilNaoSeAplica || alimentacaoNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="excessoCarboidratosAcucares"
              label="Excesso de carboidratos e/ou açúcares"
              disabled={readOnly || perfilNaoSeAplica || alimentacaoNaoSeAplica}
            />
          </div>
        </CardQuestions>

        <CardQuestions
          title="Qual(is) cirurgia(s) você já realizou?"
          name="cirurgiasNaoSeAplica"
          checkbox
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.avaliacaoEnfermagemCirurgia?.naoSeAplica}
          error={errors.cirurgiasNaoSeAplica?.message}
        >
          <div className="p-grid p-gap-2">
            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="cardiaca"
              label="Cardiaca"
              disabled={readOnly || perfilNaoSeAplica || cirurgiasNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="cancer"
              label="Câncer"
              disabled={readOnly || perfilNaoSeAplica || cirurgiasNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="amputacaoPernaPe"
              label="Amputação de membros"
              disabled={readOnly || perfilNaoSeAplica || cirurgiasNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="bariatrica"
              label="Bariátrica"
              disabled={readOnly || perfilNaoSeAplica || cirurgiasNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="outra"
              label="Outra"
              disabled={readOnly || perfilNaoSeAplica || cirurgiasNaoSeAplica}
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="naoRealizou"
              label="Não realizou"
              disabled={readOnly || perfilNaoSeAplica || cirurgiasNaoSeAplica}
            />
          </div>
        </CardQuestions>

        <CardQuestions
          title="Existe cirurgia de coluna programada ?"
          name="cirurgiaColunaProgramada"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.cirurgiaColunaProgramada}
          error={errors?.cirurgiaColunaProgramada?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="cirurgiaColunaProgramada"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Evitada',
                value: Values.EVITADA,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Já fez ou faz uso de drogas lícitas ?"
          name="usuarioDrogas"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.usuarioDrogas}
          error={errors?.usuarioDrogas?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="usuarioDrogas"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="O calendário vacinal está em dia ?"
          name="calendarioVacinal"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.calendarioVacinal}
          error={errors?.calendarioVacinal?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="calendarioVacinal"
            unstyled
            dir="row"
            items={[
              {
                label: 'Atualizado',
                value: Values.ATUALIZADO,
              },
              {
                label: 'Em atraso',
                value: Values.EM_ATRASO,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Tomou vacina contra gripe no ultimo ano ?"
          name="tomouVacinaGripe"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.tomouVacinaGripe}
          error={errors?.tomouVacinaGripe?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="tomouVacinaGripe"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Não lembro',
                value: Values.NAO_LEMBRO,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Você está gestante ?"
          name="gestante"
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={values?.gestante}
          error={errors?.gestante?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="gestante"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Não lembro',
                value: Values.NAO_LEMBRO,
              },
            ]}
            disabled={readOnly || perfilNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Realizou densitometria óssea ?"
          name="densitometriaNaoSeAplica"
          checkbox
          control={control}
          disabled={readOnly || perfilNaoSeAplica}
          defaultValue={
            values?.avaliacaoEnfermagemDensitometriaOssea?.naoSeAplica
          }
          error={errors.densitometriaNaoSeAplica?.message}
        >
          <div className="p-grid p-gap-2">
            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="colunaLombar"
              label="Coluna Lombar"
              disabled={
                readOnly || perfilNaoSeAplica || densitometriaNaoSeAplica
              }
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="femurTotal"
              label="Fêmur total"
              disabled={
                readOnly || perfilNaoSeAplica || densitometriaNaoSeAplica
              }
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="coloFemural"
              label="Colo Femural"
              disabled={
                readOnly || perfilNaoSeAplica || densitometriaNaoSeAplica
              }
            />

            <CheckboxControlled
              className="p-mb-3"
              control={control}
              name="naoRealizouDensitometria"
              label="Não realizou"
              disabled={
                readOnly || perfilNaoSeAplica || densitometriaNaoSeAplica
              }
            />
          </div>
        </CardQuestions>
      </div>
    </Template>
  );
};

export default PerfilSaude;
