import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';


import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import Separator from 'src/components/Separator/Separator';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { formatData } from '../helpers';

const tipoLogradouro = [
  {
    label: 'Residencial',
    value: 'RESIDENCIAL',
  },
  {
    label: 'Comercial',
    value: 'COMERCIAL',
  },
];

interface EnderecoProps {
  showOnlyCamposDemograficosObrigatorios?: boolean;
}

const Endereco = ({
  showOnlyCamposDemograficosObrigatorios = true,
}: EnderecoProps) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const watchEstado = watch('idEstado');
  const watchCep = watch('cep');

  const [estadosOptions, setEstadosOptions] = useState<any>([]);
  const [cidadesOptions, setCidadesOptions] = useState<any>([]);
  const [loadingCidades, setLoadingCidades] = useState<boolean>(false);
  const [loadingCepSearch, setLoadingCepSearch] = useState<boolean>(false);

  const fetchEstadosData = async () => {
    const response = await UtilsAPI.GetEstados();

    setEstadosOptions(formatData(response, 'nome', 'id'));
  };

  useEffect(() => {
    fetchEstadosData();
  }, []);

  const loadCidadesByEstado = async (idEstado: string) => {
    setLoadingCidades(true);

    const response = await UtilsAPI.GetCidades(idEstado);

    if (Array.isArray(response)) {
      const cidadesFormatted = response.map(cidade => ({
        label: cidade.nome,
        value: cidade.id,
      }));

      setCidadesOptions(cidadesFormatted);
    }

    setLoadingCidades(false);
  };

  useEffect(() => {
    if (watchEstado) {
      loadCidadesByEstado(watchEstado);
    } else {
      setCidadesOptions([]);
    }
  }, [watchEstado]);

  const handleCEP = async (cep: string) => {
    try {
      const cepFormatted = cep.replace('-', '');

      if (!cepFormatted || cepFormatted.includes('_')) return;

      setLoadingCepSearch(true);

      const res = await UtilsAPI.GetAddressByCep(cepFormatted);

      if ('status' in res) throw Error('Load CEP data error');

      setValue('idEstado', res?.idEstado);
      setValue('idCidade', res?.idCidade);
      setValue('logradouro', res?.logradouro);
      setValue('bairro', res?.bairro);
    } catch (error) {
      const data = { message: 'CEP não encontrado.', type: 'warning' };
      toast(<Toast />, { data });
    } finally {
      setLoadingCepSearch(false);
    }
  };

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
        Digite o CEP para preencher automaticamente os campos
      </SimpleText>

      <div className="p-d-flex p-grid p-col-12 p-ai-end p-px-0">
        <MaskedInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="cep"
          label="CEP"
          placeholder="99999-999"
          mask={MASK.CEP}
        />

        <div className="p-col-12 p-sm-6">
          <Button
            className="p-col-12"
            type="button"
            btnType={BtnTypes.TONAL}
            label="Pesquisar"
            onClick={() => handleCEP(watchCep)}
            disabled={
              watchCep?.charAt(watchCep?.length - 1) === '_' || watchCep === ''
                ? true
                : false
            }
            loading={loadingCepSearch}
          />
        </div>
      </div>

      {errors.cep?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors.cep?.message}
        </SimpleText>
      )}

      {!showOnlyCamposDemograficosObrigatorios && (
        <>
          <RadioButtonControlled
            control={control}
            name="areaMoradia"
            label="Área urbana"
            value="U"
          />
          <RadioButtonControlled
            control={control}
            name="areaMoradia"
            label="Área rural"
            value="R"
          />
        </>
      )}

      <Separator />

      <DropdownControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="tipo"
        label="Tipo do logradouro"
        options={tipoLogradouro}
        errorMsg={errors.tipo?.message}
      />

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="logradouro"
        label="Logradouro"
        errorMsg={errors.logradouro?.message}
      />

      <TextInputControlled
        className="p-col-12 p-sm-4"
        control={control}
        name="numero"
        label="Número"
        permitirApenasNumeros
        errorMsg={errors.numero?.message}
      />

      <TextInputControlled
        className="p-col-12 p-sm-4"
        control={control}
        name="complementoEndereco"
        label="Complemento"
        errorMsg={errors.complementoEndereco?.message}
      />

      <TextInputControlled
        className="p-col-12 p-sm-4"
        control={control}
        name="bairro"
        label="Bairro"
        errorMsg={errors.bairro?.message}
      />

      <DropdownControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="idEstado"
        label="Estado"
        placeholder="Pesquisar"
        options={estadosOptions}
        errorMsg={errors.idEstado?.message}
      />
      <div className="p-col-12 p-sm-6">
        {loadingCidades && <ComponentsLabel label="Cidade" />}
        <Skeleton loading={loadingCidades} height="60%" borderRadius="8px">
          <DropdownControlled
            control={control}
            name="idCidade"
            label="Cidade"
            placeholder="Pesquisar"
            options={cidadesOptions}
            disabled={!cidadesOptions.length}
            errorMsg={errors.idCidade?.message}
          />
        </Skeleton>
      </div>
    </>
  );
};

export default Endereco;
