import { useCallback, useEffect, useRef, useState } from 'react';

import './Cid.scss';

import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ToggleButton } from 'primereact/togglebutton';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import CidAPI from 'src/APIs/ProntuarioAPI/CidAPI/CidAPI';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  resetInvalidateQuery,
  setInvalidateQuery,
} from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import Skeleton from 'src/components/Skeleton/Skeleton';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import DropdownCid from './DropdownCid';
import ModalBuscaAvancada from './ModalBuscaAvancada';
import ModalFavoritos from './ModalFavoritos';

interface CidProps {
  campos: any[];
  usuario?: any;
  canPin?: boolean;
  updateCamposProntuario: (campos: any) => void;
}

const diagnosticos: any = {
  SUSPEITO: 'Suspeito',
  CONFIRMADO: 'Confirmado',
};

const labelTypeDiagnosticos: Record<string, SPLIT_BTN_TYPE> = {
  SUSPEITO: SPLIT_BTN_TYPE.YELLOW,
  CONFIRMADO: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
};

const nivelDiagnosticos: any = {
  PRINCIPAL: 'Principal',
  SECUNDARIO: 'Secundário',
};

const labelTypeNivelDiagnosticos: Record<string, SPLIT_BTN_TYPE> = {
  PRINCIPAL: SPLIT_BTN_TYPE.YELLOW,
  SECUNDARIO: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
};

const statusCid: any = {
  true: 'Ativo',
  false: 'Inativo',
};

const labelTypeStatusCid: Record<string, SPLIT_BTN_TYPE> = {
  true: SPLIT_BTN_TYPE.YELLOW,
  false: SPLIT_BTN_TYPE.GREEN,
};

function Cid({ campos, canPin = true }: CidProps) {
  const { agenda, query } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { getListaProblemas, listaProblemas } = useAtendimento();

  const favoritosRef = useRef<OverlayPanel>(null);

  const dispatch = useAppDispatch();

  const { invalidateCIDs } = query;

  const { control } = useForm({});

  const [campoCid, setCampoCid] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(
    campoCid.exibicaoCamposModelo === 'FIXO' ? true : false,
  );
  const [cids, setCids] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCids, setLoadingCids] = useState(false);

  const modalDisclosure = useDisclosure({ opened: false });
  const buscaModalDisclosure = useDisclosure({ opened: false });

  const getCids = async (idAtendimento: any) => {
    setLoadingCids(true);
    try {
      const response = await CidAPI.getCids(Number(idAtendimento), {
        throwError: true,
      });
      setCids(response?.data?.cids?.list);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCids(false);
    }
  };

  const handleResetComorbidades = useCallback(() => {
    dispatch(setInvalidateQuery({ invalidateComorbidades: true }));
  }, [dispatch]);

  const adicionarComorbidade = useCallback(
    (values: any) => {
      const cid: any = { ...values, idCid: values.id };
      delete cid.id;
      delete cid.estadoDiagnosticoCid;
      delete cid.nivelDiagnosticoCid;

      setIsLoading(true);

      const payload = {
        idAtendimento: Number(idAtendimento),
        dataOcorrencia: new Date(),
        dataInicio: new Date(),
        idProfissionalSaude: agenda?.profissionalAtivo?.id
          ? Number(agenda?.profissionalAtivo?.id)
          : null,
        situacao: 'ATIVO',
        estadoDiagnostico: values.estadoDiagnosticoCid,
        nivelDiagnostico: values.nivelDiagnosticoCid,
        cid: [cid],
      };

      ComorbidadeAPI.adicionarComorbidade(payload, true, {
        throwError: true,
      })
        .then(() => [getListaProblemas(), handleResetComorbidades()])
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    [
      agenda?.profissionalAtivo?.id,
      getListaProblemas,
      handleResetComorbidades,
      idAtendimento,
    ],
  );

  const removerComorbidade = useCallback(
    async (cid: any) => {
      setIsLoading(true);

      const idComorbidade = listaProblemas?.filter(
        comorbidade => comorbidade.cid[0]?.idCid === cid.id,
      )[0].id;

      try {
        await ComorbidadeAPI.removerComorbidade(
          idComorbidade,
          Number(idAtendimento),
          true,
          { throwError: true },
        );
        await getListaProblemas();
        handleResetComorbidades();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [getListaProblemas, handleResetComorbidades, idAtendimento, listaProblemas],
  );

  useEffect(() => {
    setCampoCid(campos.filter(campo => campo.funcionalidade === 'CID')[0]);
    setChecked(campoCid?.exibicaoCamposModelo === 'FIXO' ? true : false);
    getCids(idAtendimento);
    getListaProblemas();
  }, [campoCid.exibicaoCamposModelo, campos, getListaProblemas, idAtendimento]);

  useEffect(() => {
    if (invalidateCIDs) {
      getListaProblemas();
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, getListaProblemas, idAtendimento, invalidateCIDs]);

  useEffect(() => {
    favoritosRef.current?.hide();
  }, [cids]);

  const reloadCampos = () => {
    if (campoCid.exibicaoCamposModelo === 'FIXO') {
      campoCid.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoCid.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoCid.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }
    const exibixaoCampo =
      campoCid.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoCid.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const isRequired = campoCid.obrigatorio;

  return (
    <div id="campo-cids" className="p-mt-4">
      <div className="p-d-flex p-grid p-jc-between p-ai-end p-mt-2">
        <div className="p-mb-1 p-col">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            CID
          </SimpleText>
          {isRequired && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div className="header-buttons p-col">
          <Button
            className="busca-avancada"
            icon="pi pi-plus"
            onClick={() => buscaModalDisclosure.open()}
            label="Busca avançada"
          />

          {canPin && (
            <ToggleButton
              checked={checked}
              className={`p-px-4 p-py-1 pin ${
                isRequired ? 'pin-disabled' : ''
              }`}
              onLabel={''}
              offLabel={''}
              onChange={e =>
                !isRequired ? [handleFixar(), setChecked(e.value)] : null
              }
              onIcon="fa fa-thumbtack"
              offIcon="fa fa-thumbtack"
            />
          )}
        </div>
      </div>
      <div className="p-grid p-ai-baseline">
        <div className="favoritos p-col-4 p-pr-0 p-mb-1">
          <Button
            btnType="ghost"
            label="Meus favoritos"
            iconPos="right"
            icon="pi pi-chevron-down"
            className="favoritos-btn"
            onClick={e => favoritosRef.current?.toggle(e)}
          />

          <OverlayPanel ref={favoritosRef}>
            <ModalFavoritos
              onOpenModal={modalDisclosure.open}
              capitalizeFirstLetter={capitalizeFirstLetter}
              idAtendimento={idAtendimento}
              getCids={getCids}
              {...modalDisclosure}
            />
          </OverlayPanel>
        </div>
        <div className="busca p-col-8 p-grid p-pl-0">
          <DropdownCid
            control={control}
            getCids={getCids}
            idAtendimento={idAtendimento}
          />
        </div>
        {loadingCids ? (
          <Skeleton height="45px" loading={true} />
        ) : (
          <div className="p-col-12 lista-cids">
            {listaCids(
              listaProblemas,
              cids,
              getCids,
              adicionarComorbidade,
              removerComorbidade,
              isLoading,
              idAtendimento,
            )}
          </div>
        )}
      </div>
      <ModalBuscaAvancada
        {...buscaModalDisclosure}
        listaComorbidades={listaProblemas}
        getCids={getCids}
        idAtendimento={idAtendimento}
        getListaProblemas={getListaProblemas}
        adicionarComorbidade={adicionarComorbidade}
        removerComorbidade={removerComorbidade}
      />
    </div>
  );
}
export default Cid;

const listaCids = (
  listaProblemas: any[],
  cids: any[],
  getCids: any,
  adicionarComorbidade: any,
  removerComorbidade: any,
  isLoading: boolean,
  idAtendimento: number,
) => {
  const checkComorbidade = (cid: any) => {
    return listaProblemas?.some(
      (comorbidade: any) => comorbidade.cid[0]?.idCid === cid.id,
    );
  };
  const onRemoveCid = async (cid: any) => {
    const idCid = cid.id;
    const response = await UtilsAPI.deleteCid(idAtendimento, idCid);
    if (response?.status === 204) {
      getCids(idAtendimento);
    }
  };

  const onUpdateCid = async (cid: any, key: string, value: any) => {
    const payload = {
      idAtendimento,
      idCid: cid.id,
      estadoDiagnosticoCid: cid.estadoDiagnosticoCid,
      nivelDiagnosticoCid: cid.nivelDiagnosticoCid,
      status: cid.status,
      [key]: value,
    };
    try {
      await CidAPI.updateCid(payload, { throwError: true });
      getCids(idAtendimento);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {cids?.map((cid: any, idx: number) => {
        const splitBtnTypeDiagnosticos = labelTypeDiagnosticos[
          cid.estadoDiagnosticoCid || 'SUSPEITO'
        ] as SPLIT_BTN_TYPE;

        const splitBtnTypeNivelDiagnosticos = labelTypeNivelDiagnosticos[
          cid.nivelDiagnosticoCid || 'PRINCIPAL'
        ] as SPLIT_BTN_TYPE;

        const splitBtnTypeStatusCid = labelTypeStatusCid[
          cid.ativo ? 'true' : 'false'
        ] as SPLIT_BTN_TYPE;

        const splitButtonItemsDiagnosticos = () => {
          const items: MenuItem[] = [];

          for (const diagnostico in diagnosticos) {
            if (diagnostico !== cid.estadoDiagnosticoCid) {
              items.push({
                label: diagnosticos[diagnostico],
                command: () =>
                  onUpdateCid(cid, 'estadoDiagnosticoCid', diagnostico),
              });
            }
          }

          return items;
        };

        const splitButtonItemsNivelDiagnosticos = () => {
          const items: MenuItem[] = [];

          for (const nivelDiagnostico in nivelDiagnosticos) {
            if (nivelDiagnostico !== cid.nivelDiagnosticoCid) {
              items.push({
                label: nivelDiagnosticos[nivelDiagnostico],
                command: () =>
                  onUpdateCid(cid, 'nivelDiagnosticoCid', nivelDiagnostico),
              });
            }
          }

          return items;
        };

        const splitButtonItems = () => {
          const items: MenuItem[] = [];

          for (const statusKey in statusCid) {
            const statusBoolean = statusKey === 'true';

            if (statusBoolean !== cid.ativo) {
              items.push({
                label: statusCid[statusKey],
                command: () => onUpdateCid(cid, 'status', statusBoolean),
              });
            }
          }

          return items;
        };

        return (
          <div key={idx} className="p-col-12 cid-item p-my-2">
            <div className="p-d-flex p-flex-column w-100 p-gap-2">
              <div className="p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  ({cid.codigo}) {capitalizeFirstLetter(cid.nome.toLowerCase())}
                </SimpleText>
              </div>

              <div className="p-grid p-jc-between white-bg">
                <Checkbox
                  className="p-col-12 p-lg-4"
                  name={cid.id}
                  label="Adicionar na lista de problemas"
                  value={cid}
                  onChange={e => {
                    !!e.checked
                      ? adicionarComorbidade(cid)
                      : removerComorbidade(cid);
                  }}
                  checked={checkComorbidade(cid)}
                  disabled={
                    isLoading ||
                    listaProblemas?.some(
                      (comorbidade: any) =>
                        comorbidade.cid[0]?.idCid === cid.id &&
                        comorbidade?.idAtendimento !== Number(idAtendimento),
                    )
                  }
                />
                <div className="p-d-flex p-gap-3 p-col-12 p-lg-8 p-jc-end">
                  <div className="p-d-flex p-gap-1">
                    <SplitButton
                      menuClassName="menu-comorbidade-splitbutton"
                      label={
                        diagnosticos[cid.estadoDiagnosticoCid] || 'Diagnóstico'
                      }
                      btnType={splitBtnTypeDiagnosticos}
                      model={splitButtonItemsDiagnosticos()}
                    />

                    <SplitButton
                      menuClassName="menu-comorbidade-splitbutton"
                      btnType={splitBtnTypeNivelDiagnosticos}
                      label={
                        nivelDiagnosticos[cid.nivelDiagnosticoCid] || 'Nível'
                      }
                      model={splitButtonItemsNivelDiagnosticos()}
                    />

                    <SplitButton
                      menuClassName="menu-comorbidade-splitbutton"
                      btnType={splitBtnTypeStatusCid}
                      label={statusCid[cid.ativo] || 'Status'}
                      model={splitButtonItems()}
                    />
                  </div>

                  <Button
                    icon="fas fa-trash"
                    btnType="gray"
                    onClick={() => {
                      onRemoveCid(cid);
                      listaProblemas?.some(
                        (comorbidade: any) =>
                          comorbidade.cid[0]?.idCid === cid.id &&
                          comorbidade.idAtendimento === Number(idAtendimento),
                      ) && removerComorbidade(cid);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
