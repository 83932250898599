import { useNavigate } from 'react-router-dom';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const OtherConfigSubMenu = () => {
  const navigation = useNavigate();

  return (
    <div
      className={'ItemsConfigSubMenu'}
      onClick={() => navigation('/config/bloqueio-paciente')}
    >
      <Button icon={'pi pi-cog'} btnType="gray" />
      <div className={'content'}>
        <SimpleText bold>Outras configurações</SimpleText>
      </div>
    </div>
  );
};

export default OtherConfigSubMenu;
