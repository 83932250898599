import { useState } from 'react';

import dayjs from 'dayjs';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  Button,
  RadioButton,
  Checkbox as CheckboxAlt,
} from 'src/components/_UI';
import Checkbox from 'src/components/Basics/Checkbox/Checkbox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const tipoResposta: { [key: string]: string } = {
  TEXTO: 'Texto',
  NUMERO: 'Número',
  ESCOLHA: 'Escolha',
  MULTIPLA_ESCOLHA: 'Múltipla escolha',
  DATA: 'Data',
};

export const DialogAcervoPerguntasAdicionarRow = ({
  item,
  onCheck,
  onUncheck,
  isChecked,
}: {
  item: IPerguntaFormularioItem;
  onCheck: (item: IPerguntaFormularioItem) => void;
  onUncheck: (id: number) => void;
  isChecked: boolean;
}) => {
  const [checked, setChecked] = useState(isChecked);

  const disclosure = useDisclosure({
    opened: false,
  });

  const type = item.tipoResposta;

  const handleCheck = (checked: boolean) => {
    setChecked(checked);
    if (checked) {
      onCheck(item);
    } else {
      onUncheck(item.id);
    }
  };

  return (
    <div
      data-checked={checked}
      className="p-col-12 p-flex-wrap row content-row p-ai-start p-mb-2"
    >
      <div
        className="p-as-center"
        style={{
          width: '30px',
        }}
      >
        <CheckboxAlt checked={checked} onChange={e => handleCheck(e.checked)} />
      </div>

      <div className="p-col-12 p-sm-6 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_40">
          Pergunta
        </SimpleText>
        <SimpleText>{item.enunciado}</SimpleText>
      </div>
      <div className="p-col-4 p-sm-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_40">
          Tipo
        </SimpleText>
        <SimpleText fontSize="xxs">
          {tipoResposta[item.tipoResposta]}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_40">
          Data da inclusão
        </SimpleText>
        {/* // TODO */}
        <SimpleText fontSize="xxs">
          {item.dataInclusao
            ? dayjs(item.dataInclusao).format('DD/MM/YYYY')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-jc-end">
        <Button
          onClick={() => disclosure.toggle()}
          icon="fas fa-chevron-down"
          btnType="gray"
          disabled={!['ESCOLHA', 'MULTIPLA_ESCOLHA'].includes(type)}
        />
      </div>

      {disclosure.isOpen && (
        <div className="p-col-12 p-py-0 p-px-1">
          {item.tipoResposta === 'ESCOLHA' && (
            <div className="p-d-flex p-flex-column p-gap-2 p-col-12 p-mb-2">
              {item.opcoes?.map((opcao, index) => (
                <RadioButton
                  panel={false}
                  disabled
                  key={index}
                  label={opcao.descricao}
                />
              ))}
            </div>
          )}

          {item.tipoResposta === 'MULTIPLA_ESCOLHA' && (
            <div className="p-d-flex p-flex-column p-mb-2">
              <Checkbox
                disabled
                options={
                  item.opcoes?.map(opcao => ({
                    label: opcao.descricao,
                    value: opcao.descricao,
                  })) || []
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
