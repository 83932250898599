import { useCallback, useMemo, useRef } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useFormContext } from 'react-hook-form';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';

import ModalPacientes from './ModalPacientes';

type FieldPacienteProps = DropdownProps & {
  name?: string;
};

export const FieldSelectPacientes = ({
  name = 'idsPacientes',
}: FieldPacienteProps) => {
  const PacientesRef = useRef<OverlayPanel>(null);
  const modalDisclosure = useDisclosure({ opened: false });
  const { setValue, watch } = useFormContext();

  const watchPacientes = watch(name, []);

  const handleAddPacientes = useCallback(
    (pacientes: any) => {
      if (!pacientes?.length) pacientes = [];

      setValue(name, pacientes);
    },
    [name, setValue],
  );

  const labelText = useMemo(() => {
    if (watchPacientes && watchPacientes?.length !== 0) {
      return watchPacientes?.length + ' Pacientes selecionados';
    }

    return 'Selecione os pacientes';
  }, [watchPacientes]);

  return (
    <div className="p-grid p-col-12 p-py-0 p-ai-center">
      <Button
        btnType="tonal"
        label={labelText}
        iconPos="right"
        icon="pi pi-chevron-down"
        stretch
        className="cids-favoritos-btn p-mt-2"
        type="button"
        onClick={e => PacientesRef.current?.toggle(e)}
      />

      <OverlayPanel ref={PacientesRef}>
        <ModalPacientes
          handleAdd={handleAddPacientes}
          onOpenModal={modalDisclosure.open}
          onHide={() => PacientesRef.current?.hide()}
          watchPacientes={watchPacientes}
          {...modalDisclosure}
        />
      </OverlayPanel>
    </div>
  );
};
