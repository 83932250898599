import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import { PontuacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/PontuacaoAPI';
import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import Consultorios from './FormsPontuacao/Consultorios/Consultorios';
import FaixaEtaria from './FormsPontuacao/FaixaEtaria/FaixaEtaria';

import './FormPontuacao.scss';

const FormPontuacao = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);

  const [step1, setStep1] = useState<any>({});
  const [step2, setStep2] = useState<any>([]);

  const handleNavigate = useCallback(() => {
    navigate('/pacientes/carteira-aps', {
      replace: true,
      state: 'Pontuação carteira',
    });
  }, [navigate]);

  const getPontuacaoData = useCallback(async () => {
    if (!id) return;

    const response: any = await PontuacaoCarteiraAPI.getPontuacaoById(
      Number(id),
      { throwError: true },
    );

    const consultoriosValues = response.consultoriosDto.map(
      (consultorio: any) => ({
        label: consultorio.nome,
        value: {
          id: consultorio.id,
        },
      }),
    );

    setStep1({
      nome: response.nome,
      faixasEtarias: response.faixasEtarias,
    });

    setStep2({
      consultorios: consultoriosValues,
    });
  }, [id]);

  const getConsultorios = useCallback(async () => {
    const response: any = await ConsultorioAPI.loadConsultorios(
      {
        pageSize: 9999,
        sortBy: 'nome',
        sortDirection: 'ASC',
      },
      { throwError: true },
    );
    if (response && !('status' in response)) {
      setConsultorios(response.list);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        await Promise.all([getConsultorios(), getPontuacaoData()]);
      } catch (error) {
        handleNavigate();
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [getConsultorios, getPontuacaoData, handleNavigate]);

  const consultoriosOptions = useMemo(
    () =>
      consultorios.map(consultorio => ({
        label: consultorio.nome,
        value: {
          id: consultorio.id,
          nome: consultorio.nome,
        },
      })),
    [consultorios],
  );

  const requestToAPI = useCallback(
    (data: any) => {
      if (id) return PontuacaoCarteiraAPI.putPontuacao(Number(id), data);
      return PontuacaoCarteiraAPI.postPontuacao(data);
    },
    [id],
  );

  const onSubmit = useCallback(
    async (consultorios: any) => {
      const idsConsultorio = consultorios.consultorios.map(
        (consultorio: any) => consultorio.value.id,
      );

      const payload = {
        idsConsultorios: idsConsultorio,
        faixasEtarias: step1.faixasEtarias,
        nome: step1.nome,
        consultoriosDto: consultorios.consultorios,
      };

      const response = await requestToAPI(payload);

      if (response.status === 200) {
        handleNavigate();
      }
    },
    [handleNavigate, requestToAPI, step1.faixasEtarias, step1.nome],
  );

  const handleNextStep = () => {
    timelineRef?.current?.next?.();
  };
  const handlePreviousStep = () => {
    timelineRef?.current?.previous?.();
  };

  const renderStep1 = useCallback(() => {
    return (
      <div className="p-w-100">
        {loading && !!id ? (
          <div className="p-ml-5">
            <GridListLoading />
            <GridListLoading />
          </div>
        ) : (
          <FaixaEtaria
            values={step1}
            onSubmit={(values: any) => {
              setStep1(values);
              handleNextStep();
            }}
          />
        )}
      </div>
    );
  }, [id, loading, step1]);

  const steps = useCallback(
    (): TimelineValue[] => [
      {
        title: 'Faixa etária e pontuação',
        component: renderStep1(),
      },
      {
        title: 'Consultórios',
        component: (
          <Consultorios
            consultoriosOptions={consultoriosOptions}
            handlePreviousStep={handlePreviousStep}
            values={step2}
            onSubmit={onSubmit}
          />
        ),
        disabled: Object.keys(step1).length === 0,
      },
    ],
    [consultoriosOptions, onSubmit, renderStep1, step1, step2],
  );

  return (
    <Page>
      <>
        <PageHeader
          title={
            id ? 'Editar Pontuação carteira' : 'Cadastrar Pontuação carteira'
          }
          subtitle="Configure as faixas de pontuação"
        />

        <Page content white>
          <div className="formPontuacao-container">
            <Button
              className="btn-voltar p-mb-2"
              btnType="gray"
              id="back-button"
              label="Cancelar e voltar para pontuação"
              icon="fa fa-arrow-left"
              onClick={handleNavigate}
            />

            <Timeline values={steps()} ref={timelineRef} />
          </div>
        </Page>
      </>
    </Page>
  );
};

export default FormPontuacao;
