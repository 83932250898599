import { useState } from 'react';

import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { GuiaResumidaAPI } from 'src/APIs/ReportAPI/GuiaResumidaAPI';
import { GuiaTissAPI } from 'src/APIs/ReportAPI/GuiaTissAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { handleDownloadPDF } from 'src/utils/files';

import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import Toast from 'src/components/Basics/Toast/Toast';
import { DialogAssinarDigitalmente } from 'src/pages/Emed/Admin/AssinaturasProntuario/components/DialogAssinarDigitalmente';

import { ButtonEnviarEmail } from './ButtonEnviarEmail/ButtonEnviarEmail';

export const ActionsButtonsAssinatura = ({
  idAtendimento,
  isDisabled,
}: {
  idAtendimento: number | string;
  isDisabled: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const modalEmailDisclosure = useDisclosure({ opened: false });

  const {
    webSigner: { handleSign, isSuccess, log },
    handleSignHSM,
    dialogAssinarDigitalmente,
    isHsm,
  } = useAssinatura();

  const handleEnviarGuiasPorEmail = (email: string) => {
    handleAssinaturaGuiaTiss('email', email);
  };

  const handleAssinaturaGuiaTiss = async (funcao: string, email?: string) => {
    if (!isSuccess && !isHsm) {
      const data = {
        title: log ? 'Verifique sua assinatura digital' : 'Erro',
        message:
          log ||
          'Falha ao baixar as guias, verifique sua assinatura digital e tente novamente.',
        type: 'error',
      };
      return toast(<Toast />, { data });
    }

    try {
      setLoading(true);

      const response = await SolicitacoesProcedimentosAPI.getBase64GuiaTiss(
        Number(idAtendimento),
      );

      const documents = {
        documents: [
          {
            content: response.base64,
            contentType: 'BASE64',
          },
        ],
      };

      const assinatura: any = isHsm
        ? await handleSignHSM(response.base64, {
            skipPIN: funcao === 'email',
          })
        : await handleSign(documents, {
            sequenciaAssinatura: response.sequenciaAssinatura,
            tipoDocumento: 'EXAMES_CIRURGIAS_GUIA',
          });

      if (!assinatura) {
        return setLoading(false);
      }

      if (funcao === 'PDF') {
        const pdfGuiaTiss =
          await SolicitacoesProcedimentosAPI.postAssinaturaGuiaTiss(
            Number(idAtendimento),
            {
              base64: response.base64,
              base64Assinado: !isHsm
                ? assinatura[0].data[0].signature
                : assinatura.data.documents[0].signatures[0].value,
            },
          );

        return handleDownloadPDF(pdfGuiaTiss, 'Guia TISS.pdf');
      }

      if (funcao === 'email') {
        await SolicitacoesProcedimentosAPI.postAssinaturaGuiaTiss(
          Number(idAtendimento),
          {
            base64: response.base64,
            base64Assinado: !isHsm
              ? assinatura[0].data[0].signature
              : assinatura.data.documents[0].signatures[0].value,
            email,
          },
          'email',
        );
        modalEmailDisclosure.close();
        return;
      }

      return setLoading(false);
    } catch {
      return setLoading(false);
    }
  };

  const handleAssinaturaGuiaResumida = async () => {
    if (!isSuccess && !isHsm) {
      const data = {
        title: log ? 'Verifique sua assinatura digital' : 'Erro',
        message:
          log ||
          'Falha ao baixar as guias, verifique sua assinatura digital e tente novamente.',
        type: 'error',
      };
      return toast(<Toast />, { data });
    }

    try {
      setLoading(true);

      const response = await GuiaResumidaAPI.getBase64GuiaResumida(
        Number(idAtendimento),
      );

      const documents = {
        documents: [
          {
            content: response.base64,
            contentType: 'BASE64',
          },
        ],
      };

      const assinatura: any = isHsm
        ? await handleSignHSM(response.base64)
        : await handleSign(documents, {
            sequenciaAssinatura: response.sequenciaAssinatura,
            tipoDocumento: 'EXAMES_CIRURGIAS_GUIA_RESUMIDA',
          });

      if (!assinatura) {
        return setLoading(false);
      }

      const pdfGuiaResumida = await GuiaResumidaAPI.pdfAssinaturaGuiaResumida(
        Number(idAtendimento),
        {
          base64: response.base64,
          base64Assinado: !isHsm
            ? assinatura[0].data[0].signature
            : assinatura.data.documents[0].signatures[0].value,
        },
      );

      handleDownloadPDF(pdfGuiaResumida, 'Guia Resumida.pdf');
    } catch {
      // error
    } finally {
      return setLoading(false);
    }
  };

  const handlePrintGuias = async () => {
    try {
      const response = await GuiaTissAPI.getGuiaTissPdf(Number(idAtendimento));

      if (typeof response !== 'object') {
        const data = {
          title: 'Erro',
          message: 'Falha ao baixar as guias.',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);
    } catch (error) {}

    return;
  };

  const handlePrintGuiasResumida = async () => {
    try {
      const response = await GuiaResumidaAPI.printGuiaResumidaPdf(
        Number(idAtendimento),
      );

      if (typeof response !== 'object') {
        const data = {
          title: 'Erro',
          message: 'Falha ao baixar as guias.',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);
    } catch (error) {}

    return;
  };

  return (
    <>
      <ButtonEnviarEmail
        handleEnviarGuiasPorEmail={handleEnviarGuiasPorEmail}
        modalEmailDisclosure={modalEmailDisclosure}
        loadingAssinatura={loading}
        loading={loading}
        disabled={isDisabled}
      />

      <ButtonMenu
        icon="fas fa-file-download"
        label=".PDF"
        btnType="tonal"
        disabled={isDisabled}
        model={[
          {
            label: 'Baixar (.PDF)',
            disabled: true,
            className: 'model-label',
          },
          {
            label: 'Guia TISS',
            command: () => {
              handleAssinaturaGuiaTiss('PDF');
            },
          },
          {
            label: 'Guia Resumida',
            command: handleAssinaturaGuiaResumida,
          },
        ]}
        hideLabelWhenIsTablet
      />

      <ButtonMenu
        icon="fas fa-print"
        btnType="tonal"
        disabled={isDisabled}
        model={[
          {
            label: 'Imprimir',
            disabled: true,
            className: 'model-label',
          },
          {
            label: 'Guia TISS',
            command: handlePrintGuias,
          },
          {
            label: 'Guia Resumida',
            command: handlePrintGuiasResumida,
          },
        ]}
      />
      {dialogAssinarDigitalmente.isOpen && (
        <DialogAssinarDigitalmente {...dialogAssinarDigitalmente} />
      )}
    </>
  );
};
