import React from 'react';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './ExamesProcedimentosRowContent.scss';

export const ExamesProcedimentosRowContent = ({ atendimento }: any) => {
  const isInativo = atendimento.status === 'INATIVO' ? true : false;
  const nivelSigilo: number | null = atendimento.atendimento.nivelSigilo;

  if (nivelSigilo !== null && nivelSigilo < 1) {
    return <React.Fragment />;
  }

  return (
    <>
      <SimpleText
        className={`row p-w-100 p-ml-2 p-my-1 ${isInativo ? 'inativo' : ''}`}
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
      >
        Exames e procedimentos
      </SimpleText>

      <div className="exames-procedimentos-row-content p-col-12">
        {atendimento.examesSolicitados.length > 0 &&
          atendimento.examesSolicitados.map((exame: any, idx: number) => (
            <div
              key={idx}
              className={`row p-w-100 ${
                (exame.status === 'INATIVO' || isInativo) && 'inativo'
              }`}
            >
              <SimpleText className="p-col-1" fontColor={FONT_COLOR.COLOR_60}>
                {exame.codigoProcedimento}
              </SimpleText>

              <SimpleText className="p-col">
                {exame.descricaoProcedimento}
              </SimpleText>
            </div>
          ))}

        {atendimento.examesSolicitados.length === 0 && (
          <div className={`row p-w-100 ${isInativo ? 'inativo' : ''}`}>
            <SimpleText className="p-col">Sem exame ou procedimento</SimpleText>
          </div>
        )}
      </div>
    </>
  );
};
