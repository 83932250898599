import React from 'react';

import dayjs from 'dayjs';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './Row.scss';

type Status = 'PRESENTE' | 'CANCELADO' | 'FALTOU';

interface RowProps {
  agendamento: any;
}

const Row = ({ agendamento }: RowProps) => {
  const { dataAgendamento, motivo, profissional, especialidade } = agendamento;

  const status: Status = agendamento?.status;

  const statusIconMap: { [key in Status]: string } = {
    PRESENTE: 'fas fa-check-circle',
    CANCELADO: 'fas fa-exclamation-triangle',
    FALTOU: 'fas fa-times-circle',
  };

  const statusIcon = statusIconMap[status];
  const encaixe = !!agendamento?.caracteristicas?.encaixe;
  const retorno = !!agendamento?.caracteristicas?.retorno;

  return (
    <div className="p-grid p-mb-1 row">
      <div className="p-col-4 p-sm-6 p-md-2 p-xl-1 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Status
        </SimpleText>
        <div className={`p-d-flex p-jc-center p-ai-center ${status} `}>
          <i className={statusIcon} />
        </div>
      </div>

      <div className="p-col-8 p-sm-6 p-md-3 p-xl-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          className="p-d-flex p-flex-1 p-jc-start p-ai-center"
        >
          {dataAgendamento &&
            dayjs(new Date(dataAgendamento))
              .locale('pt-br')
              .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>

      <div className="p-col-12 p-sm-6 p-md-4 p-xl-3 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Profissional e especialidade
        </SimpleText>
        <div className="p-d-flex p-jc-start p-ai-center p-gap-1">
          <Avatar className="avatar" label={profissional} />
          <div className="p-d-flex p-flex-column p-gap-1 p-jc-center">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {profissional}
            </SimpleText>
            <div className="p-d-flex">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {especialidade?.[0] || ''}
              </SimpleText>
              {especialidade.length > 1 && (
                <>
                  <SimpleText
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.PRIMARY}
                    className="especialidades cursor-pointer p-ml-1"
                    medium
                  >
                    {'  '}+{especialidade.length - 1}
                  </SimpleText>
                  <Tooltip
                    target=".especialidades"
                    content={especialidade.join(', ')}
                    position="top"
                    className="tooltip"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="p-col-12 p-sm-6 p-md-3 p-xl-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Características
        </SimpleText>
        <div className="p-d-flex  p-jc-start p-ai-center p-gap-1">
          {retorno && (
            <SimpleText
              className="tag tag-retorno"
              fontSize="xxxs"
              fontColor={FONT_COLOR.COLOR_16}
            >
              Retorno
            </SimpleText>
          )}
          {encaixe && (
            <SimpleText
              className="tag tag-encaixe"
              fontSize="xxxs"
              fontColor={FONT_COLOR.COLOR_40}
            >
              Encaixe
            </SimpleText>
          )}
        </div>
      </div>
      {motivo && (
        <div className="p-col-12 p-xl-4 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Motivo
          </SimpleText>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            className="p-d-flex p-flex-1 p-jc-start p-ai-center"
          >
            {motivo || ''}
          </SimpleText>
        </div>
      )}
    </div>
  );
};

export default Row;
