import { memo, useCallback, useEffect, useRef, useState } from 'react';

import SigiloAPI from 'src/APIs/AgendaAPI/Sigilo/SigiloAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { RadioButton } from 'src/components/_UI/RadioButton';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import MaskedInput, {
  MASK,
} from 'src/components/Basics/MaskedInput/MaskedInput';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalSigilo.scss';

interface ModalSigiloProps {
  visible: boolean;
  onSuccess(): void;
  onCancel(): void;
  paciente: any;
  setPaciente?: (p: any) => void;
}

interface FooterProps {
  onCancel(): void;
  onGenerateToken(): void;
  onValidateToken(): void;
  statusSigilo: boolean;
  isTokenValid: boolean;
  isFormValid: boolean;
}

interface NivelSigilo {
  id: number;
  nivel: 'BLOQUEADO' | 'SADT' | 'SOAP';
}

const Footer = memo(
  ({
    onCancel,
    onGenerateToken,
    onValidateToken,
    statusSigilo,
    isTokenValid,
    isFormValid,
  }: FooterProps) => {
    return (
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          btnType="ghost"
          label="Cancelar"
          className={'p-col-align-center p-d-flex'}
          onClick={onCancel}
          stretch
        />
        <Button
          label={statusSigilo ? 'Concluir' : 'Enviar SMS'}
          onClick={statusSigilo ? onValidateToken : onGenerateToken}
          disabled={statusSigilo ? !isTokenValid : !isFormValid}
          stretch
        />
      </div>
    );
  },
);

const ModalSigilo = ({
  paciente,
  setPaciente,
  visible,
  onSuccess,
  onCancel,
}: ModalSigiloProps) => {
  const { user, consultorios, agenda } = useAppSelector(
    (state: RootState) => state,
  );

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'pro_liberar_sigilo',
  });

  const [phone, setPhone] = useState('');
  const [sigiloEnviado, setSigiloEnviado] = useState(false);
  const [nivelLiberacao, setNivelLiberacao] = useState<NivelSigilo>({
    id: 0,
    nivel: 'BLOQUEADO',
  });
  const [primeiroNumero, setPrimeiroNumero] = useState('');
  const [segundoNumero, setSegundoNumero] = useState('');
  const [terceiroNumero, setTerceiroNumero] = useState('');
  const [quartoNumero, setQuartoNumero] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [reenviarAberto, setReenviarAberto] = useState(true);
  const [ultimosDigitosCel, setUltimosDigitosCel] = useState('');

  const primeiroNumeroRef = useRef<any>(null);
  const segundoNumeroRef = useRef<any>(null);
  const terceiroNumeroRef = useRef<any>(null);
  const quartoNumeroRef = useRef<any>(null);

  useEffect(() => {
    const underscoreExists = phone.includes('_');
    if (phone.length === 15 && !underscoreExists) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  }, [phone]);

  useEffect(() => {
    if (isPhoneValid && nivelLiberacao.id !== 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [isPhoneValid, phone, nivelLiberacao]);

  useEffect(() => {
    if (
      primeiroNumero.length === 1 &&
      segundoNumero.length === 1 &&
      terceiroNumero.length === 1 &&
      quartoNumero.length === 1
    ) {
      setIsTokenValid(true);
    } else {
      setIsTokenValid(false);
    }
  }, [
    primeiroNumero,
    segundoNumero,
    terceiroNumero,
    quartoNumero,
    isTokenValid,
  ]);

  const handleTokenSigilo = useCallback(async () => {
    const phoneNumbers = phone
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .replace('-', '');

    const data = {
      idPaciente: paciente?.paciente?.id || paciente?.id,
      nivel: nivelLiberacao.nivel,
      telefone: phoneNumbers,
    };

    const response: any = await SigiloAPI.generateTokenSigilo(data as any);
    if (response?.status === 200) {
      setUltimosDigitosCel(phoneNumbers.slice(-4));
      setSigiloEnviado(true);
    }
  }, [nivelLiberacao, paciente, phone]);

  const handleValidateTokenSigilo = useCallback(async () => {
    const token = `${primeiroNumero}${segundoNumero}${terceiroNumero}${quartoNumero}`;
    const data = {
      idPaciente: paciente?.paciente?.id || paciente?.id,
      idMedicoProprietario: null,
      idMedicoSolicitante: agenda?.profissionalAtivo?.id,
      idConsultorio: consultorios.ativo.id,
      tipo: 0,
      nivel: nivelLiberacao.id,
      senha: token,
    };
    const response: any = await SigiloAPI.validateTokenSigilo(data);
    if (response?.status === 200) {
      setSigiloEnviado(false);
      if (setPaciente) {
        setPaciente({
          ...paciente,
          sigiloExists: true,
          sigilo: response?.data,
        });
      }

      onSuccess();
    }
  }, [
    primeiroNumero,
    segundoNumero,
    terceiroNumero,
    quartoNumero,
    paciente,
    user,
    consultorios,
    nivelLiberacao,
    onSuccess,
    setPaciente,
  ]);

  const handleCancelSigilo = useCallback(() => {
    setSigiloEnviado(false);
    onCancel();
  }, [onCancel]);

  const contagemReenviar = useCallback(() => {
    setPrimeiroNumero('');
    setSegundoNumero('');
    setTerceiroNumero('');
    setQuartoNumero('');
    setTimeout(() => {
      setReenviarAberto(false);
    }, 60000);
  }, []);

  useEffect(() => {
    if (sigiloEnviado) {
      contagemReenviar();
    }
  }, [contagemReenviar, sigiloEnviado]);

  const handleReenviar = useCallback(() => {
    setReenviarAberto(true);
    handleTokenSigilo();
    contagemReenviar();
  }, [contagemReenviar, handleTokenSigilo]);

  const handleNivelLiberacao = useCallback(
    (nivel: NivelSigilo) => {
      setNivelLiberacao(nivel);
      if (setPaciente) {
        setPaciente({
          ...paciente,
          sigilo: nivel,
        });
      }
    },
    [paciente, setPaciente],
  );

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Liberar sigilo do prontuário do paciente"
      visible={visible}
      onHide={handleCancelSigilo}
      footer={() => (
        <Footer
          onGenerateToken={handleTokenSigilo}
          onValidateToken={handleValidateTokenSigilo}
          onCancel={handleCancelSigilo}
          statusSigilo={sigiloEnviado}
          isTokenValid={isTokenValid}
          isFormValid={isFormValid}
        />
      )}
      maximizedTabletSize
    >
      <div className="modal-body">
        {sigiloEnviado ? (
          <>
            <SimpleText className="p-mb-3" fontColor={FONT_COLOR.COLOR_40}>
              Enviado. Digite o código que o paciente recebeu no celular com
              final {ultimosDigitosCel}.
            </SimpleText>

            <div className="box-number">
              <TextInput
                ref={primeiroNumeroRef}
                value={primeiroNumero}
                onChange={t => {
                  setPrimeiroNumero(t.target.value);
                  if (t.target.value) {
                    segundoNumeroRef.current.focus();
                  }
                }}
                maxLength={1}
                min={0}
              />

              <TextInput
                ref={segundoNumeroRef}
                value={segundoNumero}
                onChange={t => {
                  setSegundoNumero(t.target.value);
                  if (t.target.value) {
                    terceiroNumeroRef.current.focus();
                  }
                }}
                maxLength={1}
                min={0}
              />
              <TextInput
                ref={terceiroNumeroRef}
                value={terceiroNumero}
                onChange={t => {
                  setTerceiroNumero(t.target.value);
                  if (t.target.value) {
                    quartoNumeroRef.current.focus();
                  }
                }}
                maxLength={1}
                min={0}
              />
              <TextInput
                ref={quartoNumeroRef}
                value={quartoNumero}
                onChange={t => setQuartoNumero(t.target.value)}
                maxLength={1}
                min={0}
              />
            </div>
            <Button
              btnType={'ghost'}
              label="Reenviar"
              className={'p-col-align-center p-d-flex'}
              onClick={() => handleReenviar()}
              disabled={reenviarAberto}
              style={{ backgroundColor: 'transparent' }}
            />
          </>
        ) : (
          <>
            <SimpleText className="p-mb-3" fontColor={FONT_COLOR.COLOR_40}>
              Enviaremos um SMS para o contato do paciente solicitando a
              liberação. Siga o passo-a-passo:
            </SimpleText>

            <SimpleText fontColor={FONT_COLOR.COLOR_40}>
              1. Revise o telefone do paciente:
            </SimpleText>

            <MaskedInput
              label="Telefone para recados"
              name="phone"
              value={phone}
              onChange={t => setPhone(t.target.value)}
              className={'p-mt-3'}
              mask={MASK.PHONE}
            />

            <SimpleText
              className="p-mb-3 p-mt-3"
              fontColor={FONT_COLOR.COLOR_40}
            >
              2. Selecione o nível de liberação do prontuário
            </SimpleText>

            <RadioButton
              onChange={() =>
                handleNivelLiberacao({
                  id: 1,
                  nivel: 'SADT',
                })
              }
              label="Nível 1 - Liberar apenas resultado de exames"
              name="tipos-cadastros"
              checked={nivelLiberacao.nivel === 'SADT'}
              value={'SADT'}
              className="first"
            />

            <div className="p-m-1" />

            <RadioButton
              onChange={() =>
                handleNivelLiberacao({
                  id: 2,
                  nivel: 'SOAP',
                })
              }
              label="Nível 2 - Liberar acesso total (prontuários e exames)"
              name="tipos-cadastros"
              checked={nivelLiberacao.nivel === 'SOAP'}
              value={'SADT'}
            />
          </>
        )}

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </Dialog>
  );
};

export default ModalSigilo;
