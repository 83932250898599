import { useRef, useState, useEffect } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Control, useFormContext } from 'react-hook-form';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

interface DropdownServicosTUSSProps {
  control: Control;
  servicoSelected: any;
  setServicoSelected: any;
}

const DropdownServicosTUSS = ({
  control,
  servicoSelected,
  setServicoSelected,
}: DropdownServicosTUSSProps) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);
  const [panelWidth, setPanelWidth] = useState(0);
  const [servicosOptions, setServicosOptions] = useState<any[]>([]);

  useEffect(() => {
    if (searchInputContainerRef.current) {
      const width = searchInputContainerRef.current.offsetWidth;
      setPanelWidth(width);
    }
  }, []);

  useEffect(() => {
    fetchApi({
      query: '',
      page: 0,
      pageSize: 10,
    });
  }, []);

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const fetchApi = async ({ query, ...params }: any): Promise<any> => {
    const response = await ServicoTussCRUDAPI.loadServicoTuss({
      nome: query,
      ...params,
    });
    setServicosOptions(response.list);
    return response;
  };

  const onSelectValue = async (servico: any) => {
    setServicoSelected(servico);
    searchPanelRef.current?.hide();
  };

  const renderRow = (servico: any) => {
    return (
      <div
        className="hover-row p-grid p-col-12 p-my-1 cursor-pointer"
        onClick={() => onSelectValue(servico)}
      >
        <SimpleText
          className="p-col-12 cursor-pointer pointer-events-none"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          {servico.codigo} - {capitalizeFirstLetter(servico.nome.toLowerCase())}
        </SimpleText>
      </div>
    );
  };

  return (
    <div id="dropdown-servicoTUSS" className="p-col-12 p-px-0">
      <div ref={searchInputContainerRef} className="p-col-12">
        <Dropdown
          onFocus={e => searchPanelRef.current?.show(e, null)}
          label="Código - Descrição"
          panelClassName="display-none"
          placeholder="Selecione"
          optionLabel="nome"
          optionValue="id"
          options={servicosOptions}
          value={servicoSelected?.id || ''}
        />

        <TextInputControlled
          control={control}
          name="solicitacaoExames"
          hidden
        />
      </div>
      <OverlayPanel
        ref={searchPanelRef}
        dismissable={false}
        className="servicos-overlay"
        style={{
          width: panelWidth,
        }}
      >
        <div className="p-grid" ref={searchPanelContainerRef}>
          <SearchList.Root fetchApi={fetchApi}>
            <SearchList.SearchInput
              placeholder="Pesquise um serviço TUSS"
              className="p-col-12"
            />
            <SearchList.InfiniteScroll
              renderRow={renderRow}
              className="p-col-12"
            />
          </SearchList.Root>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DropdownServicosTUSS;
