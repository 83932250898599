import React from 'react';

import Dialog from 'src/components/Dialog/Dialog';

interface ModalEditarCopiarHistoricoProps {
  historico: any;
  canEdit: boolean;
  isOpen: boolean;
  close: () => void;
}

const ModalEditarCopiarHistorico = ({
  historico,
  canEdit,
  isOpen,
  close,
}: ModalEditarCopiarHistoricoProps) => {
  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header={`${canEdit ? 'Editar' : 'Novo'}`}
    >
      <></>
    </Dialog>
  );
};

export default ModalEditarCopiarHistorico;
