import { useState, useCallback, useMemo, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import MedicamentosUsoAPI from 'src/APIs/ProntuarioAPI/MedicamentosUsoAPI/MedicamentosUsoAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import './ModalNovoMedicamento.scss';

interface ModalNovoMedicamentoProps {
  visible: boolean;
  editMedicamento: MedicamentosUso | null;
  onHide: () => void;
  onSuccess: () => void;
}

function ModalNovoMedicamento({
  visible,
  editMedicamento,
  onHide,
  onSuccess,
}: ModalNovoMedicamentoProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { agenda } = useAppSelector((state: RootState) => state);

  const { atendimentoStatus } = useAtendimento();

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    nomeMedicamento: Yup.string().required('Informe o nome do medicamento'),
    posologia: Yup.string().required('Informe a posologia'),
    via: Yup.string().required('Informe a via'),
    quantidade: Yup.string().required('Informe a quantidade'),
    dataInicio: Yup.date().nullable().required('Informe a data de ínicio'),
    fazUsoCorreto: Yup.boolean(),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(editMedicamento?.dataCriacao),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const [viasMedicamento, setViasMedicamento] = useState<ViasMedicamentos>({});

  const loadViaMedicamento = async () => {
    const response = await MedicamentosUsoAPI.getViaMedicamentos();

    setViasMedicamento(response);
  };

  useEffect(() => {
    loadViaMedicamento();
  }, []);

  useEffect(() => {
    reset();

    if (editMedicamento) {
      setValue(
        'justificativaOcorrenciaRetroativa',
        editMedicamento.justificativaOcorrenciaRetroativa,
      );
      setValue('nomeMedicamento', editMedicamento.nomeMedicamento);
      setValue('posologia', editMedicamento.posologia);
      setValue('via', editMedicamento.via);
      setValue('quantidade', editMedicamento.quantidade);
      setValue('usoContinuo', editMedicamento.usoContinuo);
      setValue('dataInicio', new Date(editMedicamento.dataInicio));
      setValue('lembrete', editMedicamento.lembrete);
      if (
        editMedicamento.fazUsoCorreto !== null &&
        editMedicamento.fazUsoCorreto !== undefined
      ) {
        setValue('fazUsoCorreto', editMedicamento.fazUsoCorreto);
      }

      if (editMedicamento.dataFim)
        setValue('dataFim', new Date(editMedicamento.dataFim));
    } else {
      setValue('usoContinuo', false);
      setValue('lembrete', false);
    }
  }, [visible, editMedicamento, reset, setValue]);

  const saveAction = useCallback(
    (payload: any) => {
      if (!editMedicamento)
        return MedicamentosUsoAPI.adicionarMedicamentoUso(payload);

      return MedicamentosUsoAPI.alterarMedicamentoUso(
        editMedicamento.id,
        payload,
      );
    },
    [editMedicamento],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        ...data,
        emUso: true,
        idProfissionalSaude: agenda?.profissionalAtivo?.id,
        idAtendimento: Number(idAtendimento),
        dataOcorrencia: dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
      };

      const response = await saveAction(payload);

      if (response?.status === 200 || response?.status === 201) {
        onSuccess();
        onHide();
      }
    },
    [
      agenda?.profissionalAtivo?.id,
      idAtendimento,
      saveAction,
      onHide,
      onSuccess,
    ],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const watchDataInicio = watch('dataInicio');

  const viasMedicamentosOptions = useMemo(
    () =>
      Object.entries(viasMedicamento).map((viaMedicamento: string[]) => ({
        label: viaMedicamento[1],
        value: viaMedicamento[0],
      })),
    [viasMedicamento],
  );

  const checkDisabledOnEdit = useMemo(() => {
    if (!editMedicamento) return false;

    return !(
      editMedicamento.idAtendimento === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [atendimentoStatus, editMedicamento, idAtendimento]);

  return (
    <Dialog
      id="modal-novo-medicamento"
      type="modal"
      header={
        editMedicamento
          ? 'Editar medicamento em uso'
          : 'Novo medicamento em uso'
      }
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
      maximizedTabletSize
    >
      <form id="form-medicamento-uso" className="p-grid">
        <DataOcorrencia
          control={control}
          defaultValue={
            editMedicamento
              ? new Date(editMedicamento.dataOcorrencia)
              : new Date()
          }
          dataInclusao={editMedicamento?.dataCriacao}
          disabled={checkDisabledOnEdit}
        />

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="nomeMedicamento"
          label="Nome medicamento"
          placeholder="Informe o nome do medicamento"
          errorMsg={errors.nomeMedicamento?.message}
          disabled={checkDisabledOnEdit}
        />

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="posologia"
          label="Posologia"
          placeholder="Informe a posologia do medicamento"
          errorMsg={errors.posologia?.message}
          disabled={checkDisabledOnEdit}
        />

        <DropdownControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="via"
          label="Via"
          placeholder="Selecione a via"
          options={viasMedicamentosOptions}
          errorMsg={errors.via?.message}
          disabled={checkDisabledOnEdit}
        />

        <div className="p-col-12 p-sm-6">
          <TextInputControlled
            control={control}
            name="quantidade"
            label="Quantidade"
            placeholder="Informe a quantidade"
            floatOnly
            errorMsg={errors.quantidade?.message}
            disabled={checkDisabledOnEdit}
          />

          <CheckboxControlled
            className="checkbox-small-novo-medicamento"
            control={control}
            name="usoContinuo"
            label="Uso contínuo"
          />
        </div>

        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="dataInicio"
          label="Data início"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          errorMsg={errors.dataInicio?.message}
        />

        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="dataFim"
          label="Data fim"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          minDate={watchDataInicio}
        />

        <SimpleText
          className="p-col-12 p-mt-1"
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Faz uso correto do medicamento?
        </SimpleText>

        <div className="faz-uso-correto p-col-12 p-sm-3">
          <RadioButtonControlled
            control={control}
            name="fazUsoCorreto"
            label="Sim"
            value={true}
          />
        </div>
        <div className="faz-uso-correto p-col-12 p-sm-3">
          <RadioButtonControlled
            control={control}
            name="fazUsoCorreto"
            label="Não"
            value={false}
          />
        </div>

        {errors.fazUsoCorreto?.message && (
          <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
            {errors.fazUsoCorreto?.message}
          </SimpleText>
        )}

        <div className="p-col-12">
          <CheckboxControlled
            className="checkbox-small-novo-medicamento"
            control={control}
            name="lembrete"
            label='Exibir em "lembretes"'
          />
        </div>
      </form>
    </Dialog>
  );
}

export default ModalNovoMedicamento;
