import { useCallback, useEffect, useState } from 'react';

import { CartoesPacienteAPI } from 'src/APIs/AdminAPI/CartoesPacienteAPI/CartoesPacienteAPI';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import './CartoesPaciente.scss';

import CardCartao from './CardCartao/CardCartao';
import { FormCartaoPaciente } from './FormCartaoPaciente/FormCartaoPaciente';

const CartoesPaciente = ({ paciente, idPaciente }: any) => {
  const [exibirForm, setExibirForm] = useState(false);
  const [dadosEditarCartao, setDadosEditarCartao] = useState<any>(null);
  const [cartoes, setCartoes] = useState<any[]>([]);
  const [idCartaoExcluir, setIdCartaoExcluir] = useState<number | null>(null);

  const cartoesLength = cartoes.filter(cartao => !!cartao?.status)?.length;

  const loadCartoesPaciente = useCallback(() => {
    CartoesPacienteAPI.loadCartoesPaciente(idPaciente).then((resp: any) => {
      if (Array.isArray(resp) && resp.length) setCartoes(resp);
    });
  }, [idPaciente]);

  useEffect(() => {
    loadCartoesPaciente();
  }, [loadCartoesPaciente]);

  const onRemove = async () => {
    if (!idCartaoExcluir) return;

    const response = await CartoesPacienteAPI.removerCartao(idCartaoExcluir);

    if (response?.status === 204) {
      loadCartoesPaciente();
      setIdCartaoExcluir(null);
    }
  };

  const handleSuccess = () => {
    loadCartoesPaciente();
    handleHideForm();
  };

  const handleHideForm = () => {
    setExibirForm(false);
    setDadosEditarCartao(null);
  };

  const renderCartoesPaciente = () => {
    if (exibirForm) {
      return (
        <FormCartaoPaciente
          editCartao={dadosEditarCartao}
          idPaciente={paciente.id}
          onSuccess={handleSuccess}
          onHide={handleHideForm}
          cartoesLength={cartoesLength || 0}
        />
      );
    }

    return (
      <div className="container-cartoes-paciente">
        {cartoes?.map((cartao: any) => (
          <CardCartao
            key={cartao.id}
            cartao={cartao}
            setDadosEditarCartao={setDadosEditarCartao}
            setExibirForm={setExibirForm}
            setCartoes={setCartoes}
            paciente={paciente}
            onRemove={(idCartao: number) => setIdCartaoExcluir(idCartao)}
          />
        ))}

        <Button
          type="button"
          btnType={BtnTypes.TONAL}
          label="Adicionar"
          icon={'fa fa-plus'}
          onClick={() => setExibirForm(true)}
        />
      </div>
    );
  };

  return (
    <div className="Form-cartoes">
      {renderCartoesPaciente()}

      <ConfirmDialog
        visible={!!idCartaoExcluir}
        header="Excluir plano de saúde"
        text="O plano de saúde será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdCartaoExcluir(null)}
        onConfirm={onRemove}
      />
    </div>
  );
};

export default CartoesPaciente;
