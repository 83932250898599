import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/relatorios';

export const requestReportAtendimento = async (
  params: any,
  typeExport: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `${basePath}/atendimento/${typeExport}`,
    data: params,
    responseType: 'blob',
    return: true,
    ...options,
  });
};

export const requestReportAgenda = async (
  params: any,
  typeExport: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `${basePath}/agenda/${typeExport}`,
    data: params,
    responseType: 'blob',
    return: true,
    ...options,
  });
};

export const requestReportExportacaoProntuario = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'report',
    url: `relatorios-pep/exportacao-prontuarios/pdf`,
    params,
    responseType: 'arraybuffer',
    return: true,
    ...options,
  });
};

export const requestReportExportacaoProntuarioAssinar = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'report',
    url: `relatorios-pep/exportacao-prontuarios/dados`,
    params,
    return: true,
    ...options,
  });
};

export const requestReportExportacaoProntuarioAssinado = async (
  data: { base64: string; base64Assinado: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `relatorios-pep/exportacao-prontuarios`,
    responseType: 'arraybuffer',
    data,
    return: true,
    ...options,
  });
};

export const getRelatoriosEmails = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/telemedicina-log-email/pesquisa',
    params,
    hideToast: true,
    ...options,
  });
};

export const postRelatoriosEmailsPdf = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: '/relatorios-adm/telemedicina-log-email/pdf',
    responseType: 'blob',
    data: params,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const postRelatorioPortalVoucherAgendamento = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: '/relatorios/agenda/portal-paciente/voucher-agendamento/pdf',
    responseType: 'blob',
    data: data,
    hideToast: true,
    return: true,
    ...options,
  });
};
export const postRelatorioPortalMeusAtendimentos = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: '/relatorios/agenda/portal-paciente/meus-agendamentos/pdf',
    responseType: 'blob',
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};
export const postRelatorioPortalSolicitacoesProcedimentos = async (
  params: {
    descricao?: string;
    idProfissionalSaude?: string;
    dataInicial?: string;
    dataFinal?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: 'relatorios-pep/portal-paciente/solicitacoes-resultado-exames/pdf',
    responseType: 'blob',
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};
export const postRelatorioPortalReceitasOrientacoes = async (
  params: {
    dataInicial?: string | undefined;
    dataFinal?: string | undefined;
    tipo?: string | undefined;
    idProfissionalSaude?: number | undefined;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: '/relatorios-pep/portal-paciente/receitas-orientacoes/pdf',
    responseType: 'blob',
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};

const RelatoriosAPI = {
  requestReportAtendimento,
  requestReportAgenda,
  postRelatoriosEmailsPdf,
  getRelatoriosEmails,
  requestReportExportacaoProntuario,
  requestReportExportacaoProntuarioAssinar,
  requestReportExportacaoProntuarioAssinado,
  postRelatorioPortalVoucherAgendamento,
  postRelatorioPortalMeusAtendimentos,
  postRelatorioPortalReceitasOrientacoes,
  postRelatorioPortalSolicitacoesProcedimentos,
};

export default RelatoriosAPI;
