import {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { MemedAPI } from 'src/APIs/ProntuarioAPI/MemedAPI/MemedAPI';

import { useMemed } from 'src/core/hooks/Atendimento/useMemed';
import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import {
  handleSetFeatureToggle,
  handleSetPacienteInfo,
  handleSetPrescricaoItem,
  handleShowMemed,
  addOnPrescricaoImpressa,
} from 'src/libs/memed/utils';

import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

interface ImpressoMemedProps extends DisclosureType {
  selectedTipoImpresso: any;
  reloadList: () => void;
}

interface ImpressoMemedCompProps extends ImpressoMemedProps {
  reloadList: () => void;
}

export default function ImpressoMemed({
  close,
  isOpen,
  selectedTipoImpresso,
  reloadList,
  ...props
}: ImpressoMemedProps) {
  return (
    <Dialog
      style={{ minWidth: '200px' }}
      onHide={close}
      className="dialog-memed"
      showDismissWithoutHeader
      showHeader={false}
      visible={isOpen}
    >
      <>
        <Suspense fallback={<SpinnerLoading full />}>
          <ImpressoMemedComp
            selectedTipoImpresso={selectedTipoImpresso}
            close={close}
            isOpen={isOpen}
            reloadList={reloadList}
            {...props}
          />
        </Suspense>
      </>
    </Dialog>
  );
}

const ImpressoMemedComp = memo(
  ({ close, selectedTipoImpresso, reloadList }: ImpressoMemedCompProps) => {
    const { atendimento } = useAppSelector((state: RootState) => state);
    const { memedStatus, memedImpressos } = useMemed();
    const [isLoading, setIsLoading] = useState(false);

    const { state } = useLocation();
    const dispatch = useDispatch();

    const idAtendimento = state?.idAtendimento;
    const { isLoaded, hasError } = memedStatus;

    const textoImpresso = useMemo(() => {
      const definicoes = localStorage.getItem('definicoes')
        ? JSON.parse(localStorage.getItem('definicoes') || '{}')
        : {};
      const { impressos } = definicoes;

      if (!impressos) return null;

      switch (selectedTipoImpresso.descricao) {
        case 'Atestado':
          return impressos.atestado;
        case 'Receita':
          return impressos.receita;
        case 'Orientação':
          return impressos.orientacao;
      }
      return;
    }, [selectedTipoImpresso]);

    const memedSalvaPrescricao = useCallback(
      data => {
        const item = {
          ...data,
          prescricao: {
            id: data.prescricao.id,
            prescricao_editada_id: data.prescricao.prescricao_editada_id,
            paciente: data.prescricao.paciente,
            medicamentos: data.prescricao.medicamentos,
          },
          idTipoImpressso: selectedTipoImpresso.id,
        };
        MemedAPI.adicionarImpressoPaciente(
          { idAtendimento },
          item,
          {
            idTipoImpressso: selectedTipoImpresso.id,
          },
          { throwError: true },
        )
          .then(() => {
            dispatch(setInvalidateQuery({ invalidateAlergias: true }));
            reloadList();
          })
          .catch(() => {});
      },
      [dispatch, idAtendimento, reloadList, selectedTipoImpresso.id],
    );

    const memedSetInfos = useCallback(async () => {
      try {
        addOnPrescricaoImpressa(memedSalvaPrescricao);
        await handleSetFeatureToggle(memedImpressos.atributosFeatureToogle);
        await handleSetPacienteInfo(atendimento.memed);
        await handleSetPrescricaoItem({
          nome: `${selectedTipoImpresso.descricao}`,
          titularidade: 'IMPRESSO',
          posologia: textoImpresso || '<p>Posologia</p>',
        });
        handleShowMemed();
        setIsLoading(false);
      } catch (e) {
        toast(<Toast />, {
          data: {
            title: 'Erro',
            message: 'Falha ao abrir a memed.',
            type: 'error',
          },
        });
      } finally {
        close();
      }
    }, [
      memedSalvaPrescricao,
      memedImpressos.atributosFeatureToogle,
      atendimento.memed,
      selectedTipoImpresso.descricao,
      textoImpresso,
      close,
    ]);

    useEffect(() => {
      setIsLoading(true);

      if (isLoaded && !hasError) {
        memedSetInfos();
      }
    }, [isLoaded, hasError, memedSetInfos]);

    useEffectSkipFirst(() => {
      if (isLoaded && hasError) close();
    }, [isLoaded, hasError, close]);

    if (isLoading) {
      return <SpinnerLoading size="md" full />;
    }

    return null;
  },
);
