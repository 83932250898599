import React, { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { TextInputSearchControlled } from 'src/components/Basics/TextInputSearch/TextInputSearchControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';

interface Props {
  defaultValues: any;
  onSubmit: (data: any) => void;
  resetFilter: () => void;
}

const PesquisaAvancada = memo(({ defaultValues, resetFilter }: Props) => {
  const [shouldResetToDefault, setShouldResetToDefault] = useState(true);
  const useFormMethods = useForm({
    defaultValues: {
      nome: defaultValues?.nome || '',
      cpf: defaultValues?.cpf || '',
      numeroCartao: defaultValues?.numeroCartao || '',
      nomeMae: '',
      dataNascimento: '',
      codigo: '',
    },
  });

  const { control, reset, getValues } = useFormMethods;

  const handleFilter = () => {
    resetFilter();
    const data = getValues();

    const { nome, nomeMae, numeroCartao, cpf, dataNascimento, codigo } = data;

    const formatField = (field: any) =>
      String(field)
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '') || null;

    const payload: any = {
      ...(nome && { nome }),
      ...(nomeMae && { nomeMae }),
      ...(numeroCartao && { numeroCartao: formatField(numeroCartao) }),
      ...(cpf && { cpf: formatField(cpf) }),
      ...(dataNascimento && {
        dataNascimento: dayjs(new Date(dataNascimento)).format('YYYY-MM-DD'),
      }),
      ...(codigo && { codigo }),
    };

    return payload;
  };

  const onCancel = () => {
    setShouldResetToDefault(false);
    reset({
      nome: '',
      nomeMae: '',
      numeroCartao: '',
      cpf: '',
      dataNascimento: '',
      codigo: '',
    });
  };

  useEffect(() => {
    if (shouldResetToDefault) {
      reset({
        nome: defaultValues?.nome || '',
        cpf: defaultValues?.cpf || '',
        numeroCartao: defaultValues?.numeroCartao || '',
        nomeMae: '',
        dataNascimento: '',
        codigo: '',
      });
    }
  }, [defaultValues, reset, shouldResetToDefault]);

  return (
    <SearchListFilter
      isSearch
      btnType={'tonal'}
      onFilter={handleFilter}
      actionBeforeClose={() => setShouldResetToDefault(true)}
      onClearFilters={onCancel}
      type="button"
    >
      <FormProvider {...useFormMethods}>
        <form className="p-d-flex p-flex-column p-gap-1 p-p-2">
          <SimpleText className="p-mb-2">
            Pesquise pacientes de forma detalhada, preenchendo um ou mais dos
            campos abaixo.
          </SimpleText>
          <SimpleText medium>Busque por</SimpleText>
          <TextInputSearchControlled
            className=" p-mb-2"
            name="nome"
            placeholder="Nome do paciente"
          />
          <TextInputSearchControlled
            className=" p-mb-2"
            name="nomeMae"
            placeholder="Nome da mãe"
          />
          <FormInput
            className=""
            name="numeroCartao"
            label="Numero cartão"
            hideTextErrorSpace
            permitirApenasNumeros
          />
          <MaskedInputControlled
            className=""
            control={control}
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            mask={MASK.CPF}
          />
          <SimpleText medium>Data de nascimento</SimpleText>
          <CalendarInputControlled
            control={control}
            name="dataNascimento"
            placeholder="dd/mm/aaaa"
            showIcon
            hideOnDateTimeSelect
          />
          <FormInput
            className=""
            name="codigo"
            label="Código do paciente"
            type="number"
          />
        </form>
      </FormProvider>
    </SearchListFilter>
  );
});

export default PesquisaAvancada;
