import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  data: any;
  consultorios: JSX.Element;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { data, onEdit, onDelete, consultorios } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(data),
      checkPermission: 'AGE_TIPO_DE_CUIDADO_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(data),
      checkPermission: 'AGE_TIPO_DE_CUIDADO_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Tipo de cuidado
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.descricao}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Consultório
        </SimpleText>
        <br />

        {consultorios}
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Status
        </SimpleText>
        <br />
        <div className="p-mt-2">
          <span className={`text-status ${data?.status ? 'active' : ''}`}>
            {data?.status ? 'Ativo' : 'Inativo'}
          </span>
        </div>
      </div>
    </SearchListMobileRow>
  );
};
