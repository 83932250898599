import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

const situacoes: any = {
  ATIVO: 'Ativo',
  INATIVO: 'Inativo',
  RESOLVIDO: 'Resolvido',
};

const chipType: Record<string, string> = {
  ATIVO: 'chip-yellow',
  RESOLVIDO: 'chip-success-light',
  INATIVO: 'chip-outline-green',
};

const diagnosticos: any = {
  Suspeito: 'Suspeito',
  Confirmado: 'Confirmado',
};

const chipTypeDiagnosticos: Record<string, string> = {
  Suspeito: 'chip-yellow',
  Confirmado: 'chip-success-light',
};

const nivelDiagnosticos: any = {
  Principal: 'Principal',
  Secundário: 'Secundário',
};

const chipTypeNivelDiagnosticos: Record<string, string> = {
  Principal: 'chip-yellow',
  Secundário: 'chip-success-light',
};

interface PanelContentProps {
  problema: any;
}

export const PanelContent = ({ problema }: PanelContentProps) => {
  const headerTextClassName = `p-mb-2 ${
    problema.status === 'INATIVO' ? 'comorbidade-inativa' : ''
  }`;
  const dataTextClassName =
    problema.status === 'INATIVO' ? 'line-through comorbidade-inativa' : '';

  return (
    <div className="comorbidades-panel-content p-grid">
      {problema ? (
        <div className="p-d-flex p-gap-1 fixed-corner-right">
          {problema.estadoDiagnostico ? (
            <div className={chipTypeDiagnosticos[problema.estadoDiagnostico]}>
              <SimpleText fontSize={FONT_SIZE.XXXS} medium>
                {diagnosticos[problema.estadoDiagnostico]}
              </SimpleText>
            </div>
          ) : null}
          {problema.nivelDiagnostico ? (
            <div
              className={chipTypeNivelDiagnosticos[problema.nivelDiagnostico]}
            >
              <SimpleText fontSize={FONT_SIZE.XXXS} medium>
                {nivelDiagnosticos[problema.nivelDiagnostico]}
              </SimpleText>
            </div>
          ) : null}

          <div className={chipType[problema.situacao]}>
            <SimpleText fontSize={FONT_SIZE.XXXS} medium>
              {situacoes[problema.situacao]}
            </SimpleText>
          </div>
        </div>
      ) : null}

      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {problema.descricao}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data ínicio
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {!!problema?.dataInicio &&
            dayjs(problema.dataInicio).locale('pt-br').format('DD MMM[.] YYYY')}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data fim
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {!!problema?.dataFim &&
            dayjs(problema.dataFim).locale('pt-br').format('DD MMM[.] YYYY')}
        </SimpleText>
      </div>
      {problema?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {problema.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{problema.usuarioAtualizacao.nome}</b> em{' '}
          {dayjs(problema.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
};
