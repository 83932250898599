import { useState, useMemo, useCallback } from 'react';

import dayjs from 'dayjs';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import CardAgendamento from 'src/pages/Emed/Agenda/MenuAgenda/AgendamentoListItem/CardAgendamento/CardAgendamento';

import { useAgenda } from '../../AgendaContext';
import DetalhesAgendamento from '../../MenuAgenda/DetalhesAgendamento/DetalhesAgendamento';
import EditarPaciente from '../../MenuAgenda/EditarPaciente/EditarPaciente';
import GerenciarGrupo from '../../MenuAgenda/GerenciarGrupo/GerenciarGrupo';
import Status from '../../MenuAgenda/Status/Status';

import AgendaDiaSkeleton from './AgendaDiaSkeleton/AgendaDiaSkeleton';

import './AgendaDia.scss';

enum AlterarDiaAgenda {
  PROXIMO_DIA = 24,
  DIA_ANTERIOR = -24,
}

interface AgendaDiaProps {
  items: AtendimentoAgendamento[];
  isLoading: boolean;
  dataAgenda: Date;
  renderSigilo: (atendimento: AtendimentoAgendamento) => JSX.Element;
}

function AgendaDia({
  items,
  isLoading,
  dataAgenda,
  renderSigilo,
}: AgendaDiaProps) {
  const {
    dialogEditarPaciente,
    dialogGerenciarGrupos,
    dialogDetalhesAgendamento,
  } = useAgenda();

  const [agendaVisivel, setAgendaVisivel] = useState<boolean>(true);

  const agendamentoHorarios = useMemo(() => {
    if (!items?.length) return ['', ''];

    const idxFinal = items.length - 1;

    const initial = dayjs(items[0]?.dataAgendamento).format('HH:mm');
    const final = dayjs(items[idxFinal]?.dataAgendamento).format('HH:mm');

    return [initial, final];
  }, [items]);

  const agendaDiaContent = useMemo(() => {
    if (isLoading) return <AgendaDiaSkeleton />;

    if (!items.length) {
      return (
        <div className="p-py-5">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Nenhum horário disponível nesta data.
          </SimpleText>
        </div>
      );
    }

    return items.map(atendimento => (
      <CardAgendamento
        key={generateUniqueId()}
        checkbox={false}
        agendamento={atendimento}
        selectedDate={new Date()}
        sigilo={renderSigilo(atendimento)}
        visualizacaoAgenda="AGENDA_MEDICO"
      />
    ));
  }, [items, isLoading, renderSigilo]);

  return (
    <>
      <Button
        id="botao-visualizar-agenda-dia"
        btnType="green-link"
        label="Agenda"
        icon={`pi pi-angle-${agendaVisivel ? 'up' : 'down'}`}
        onClick={() => setAgendaVisivel(visivel => !visivel)}
      />

      {agendaVisivel && (
        <AgendaContent dataAgenda={dataAgenda} horarios={agendamentoHorarios}>
          {agendaDiaContent}
        </AgendaContent>
      )}

      {dialogEditarPaciente.isOpen && (
        <EditarPaciente {...dialogEditarPaciente} />
      )}

      {dialogGerenciarGrupos.isOpen && (
        <GerenciarGrupo
          agendamento={dialogGerenciarGrupos.state}
          checkbox={false}
          selectedDate={dataAgenda}
          {...dialogGerenciarGrupos}
        />
      )} 

      {dialogDetalhesAgendamento.isOpen && (
        <DetalhesAgendamento
          agendamento={dialogDetalhesAgendamento.state}
          status={() => (
            <Status
              agendamento={dialogDetalhesAgendamento.state}
              selectedDate={dataAgenda}
              onHide={() => dialogDetalhesAgendamento.close()}
            />
          )}
          {...dialogDetalhesAgendamento}
        />
      )}
    </>
  );
}

const AgendaContent = ({ dataAgenda, horarios, children }: any) => {
  const { toggleViewAgenda, refetchAgendaList, togglePesquisaProntuario } =
    useAgenda();

  const [initial, final] = horarios;

  const alterarDiaAgenda = useCallback(
    (horasDia: AlterarDiaAgenda) => {
      const novaData = new Date(dataAgenda.getTime() + 3600 * 1000 * horasDia);
      refetchAgendaList(novaData);
    },
    [dataAgenda, refetchAgendaList],
  );

  return (
    <>
      <div className="p-d-flex p-ai-center p-jc-between p-flex-column-reverse p-flex-sm-row p-my-3">
        <div className="p-d-flex p-ai-center p-gap-2">
          <Button
            icon={'pi pi-chevron-left'}
            btnType="green-link"
            onClick={() => alterarDiaAgenda(AlterarDiaAgenda.DIA_ANTERIOR)}
          />
          <SimpleText>
            {dayjs(dataAgenda)
              .locale('pt-br')
              .format('dddd, DD/MM/YYYY ')
              .concat(`${initial} - ${final}`) || ''}
          </SimpleText>
          <Button
            icon={'pi pi-chevron-right'}
            btnType="green-link"
            onClick={() => alterarDiaAgenda(AlterarDiaAgenda.PROXIMO_DIA)}
          />
        </div>
        <div className="p-d-flex p-gap-2">
          <Button
            className="p-mb-3 p-mb-sm-0"
            icon={'pi pi-refresh'}
            btnType="gray"
            onClick={() => refetchAgendaList()}
          />
          <Button
            btnType="gray"
            icon="fas fa-search"
            onClick={() => {
              togglePesquisaProntuario();
            }}
          />
        </div>
      </div>

      <div id="agenda-dia-container">{children}</div>

      <Button
        id="botao-agenda-completa"
        className="p-mt-2"
        btnType="tonal"
        label="Visualizar agenda completa"
        onClick={() => toggleViewAgenda(true)}
      />
    </>
  );
};

export default AgendaDia;
