import { createContext } from 'react';

import {
  BasicInfoStepData,
  AdditionalInfoStepData,
  TissInfoData,
  WsdlTissInfoData,
} from 'src/core/containers/ManterConvenio/ManterConvenioProvider';

export interface ConvenioData {
  idConvenioEdit: number | null;
  idConvenioTissEdit: number | null;
  basicInfoStepData: BasicInfoStepData | null;
  additionalInfoStepData: AdditionalInfoStepData | null;
  loadingConvenioData: boolean;
  tissInfoData: TissInfoData | null;
  wsdlTissInfoData: WsdlTissInfoData | null;
  procedimentosConvenio: any;
  setBasicInfoStepData: React.Dispatch<
    React.SetStateAction<BasicInfoStepData | null>
  >;
  setAdditionalInfoStepData: React.Dispatch<
    React.SetStateAction<AdditionalInfoStepData | null>
  >;
  setIdConvenioEdit: (idConvenioEdit: number) => void;
  setIdConvenioTissEdit: (idConvenioTissEdit: number) => void;
  setTissInfoData: React.Dispatch<React.SetStateAction<TissInfoData | null>>;
  setWsdlTissInfoData: React.Dispatch<
    React.SetStateAction<WsdlTissInfoData | null>
  >;
}

export const ConvenioContext = createContext({} as ConvenioData);
