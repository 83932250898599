import React, { useState } from 'react';

import dayjs from 'dayjs';

import { useAGA } from 'src/core/hooks/AGA/useAGA';
import useSize from 'src/core/hooks/useSize';

import { generateUniqueId } from 'src/utils/utils';

import SubtextoPergunta14 from '../../QuestionarioAGA/components/FormularioAGA/SubtextoPergunta14';
import { Button, RadioButton } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';
import Tab from 'src/components/Tab/Tab';

import InformativoPergunta from '../../QuestionarioAGA/Informativos/InformativoPergunta';

import './ModalHistoricoAGA.scss';

interface ModalHistoricoAGAProps {
  isOpen: boolean;
  close: () => void;
  state?: any;
  idPaciente: number | null;
}

const ModalHistoricoAGA = ({
  isOpen,
  close,
  state,
  idPaciente,
}: ModalHistoricoAGAProps) => {
  const [verEvolucao, setVerEvolucao] = useState<boolean>(false);
  const { evolucaoHistorico, diagnosticoFuncionalDisclosure } = useAGA();
  const { isMobile } = useSize();
  const formulario = state;

  const header = (
    <div
      className={`p-d-flex p-ai-center p-jc-between ${
        isMobile ? 'p-flex-column' : ''
      }`}
    >
      <div className="p-d-flex p-ai-center">
        {verEvolucao && (
          <Button
            btnType="green-link"
            label="Voltar"
            onClick={() => setVerEvolucao(false)}
            icon="fas fa-arrow-left"
            iconPos="left"
          />
        )}

        <SimpleText
          className="p-px-2"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {formulario?.formulario} - Historico
        </SimpleText>
      </div>
      <div className={`p-d-flex p-ai-center ${isMobile ? 'p-mt-2' : ''}`}>
        {!verEvolucao && (
          <Button
            className="p-mr-2"
            btnType="tonal"
            icon="fas fa-chart-line"
            label="Ver Evolução"
            onClick={() => setVerEvolucao(true)}
          />
        )}
      </div>
    </div>
  );

  const flexRespostas =
    formulario?.formulario === 'MEEM'
      ? 'p-d-flex p-flex-column p-flex-sm-row p-grid'
      : 'p-d-flex p-flex-column p-ai-start p-jc-start';

  const respostasQuestionario = formulario?.respostas
    ?.sort(
      (a: any, b: any) => a.idAgaPerguntaFormulario - b.idAgaPerguntaFormulario,
    )
    .map((resposta: any, index: number) => (
      <div key={resposta.id} className="p-my-2 p-p-2 card-pergunta">
        <div className="p-d-flex p-ai-center">
          <SimpleText medium>
            {index + 1} - {resposta.agaPerguntaFormularioDto.texto || ''}
          </SimpleText>
          {resposta.agaPerguntaFormularioDto.textoAjuda && (
            <InformativoPergunta pergunta={resposta.agaPerguntaFormularioDto} />
          )}
        </div>
        <Separator />
        {resposta.agaPerguntaFormularioDto.id === 14 && (
          <>
            <SubtextoPergunta14 idPaciente={idPaciente} />
            <Separator />
          </>
        )}
        <div
          key={resposta.id}
          className={formulario.formulario === 'MEEM' ? 'p-col-2' : ''}
        >
          <div className={flexRespostas}>
            {resposta.textoResposta ? (
              <SimpleText className="p-px-2">
                {resposta?.textoResposta}
              </SimpleText>
            ) : (
              <RadioButton
                className="outline-none"
                checked
                label={resposta?.agaRespostaPerguntaFormularioDto?.texto ?? ''}
              />
            )}
          </div>
        </div>
      </div>
    ));

  const groupedEvolucao: any = {};

  evolucaoHistorico?.map((evolucao: any) => {
    evolucao.respostas.forEach((item: any) => {
      const idAgaPerguntaFormulario = item.idAgaPerguntaFormulario;

      if (!groupedEvolucao[idAgaPerguntaFormulario]) {
        groupedEvolucao[idAgaPerguntaFormulario] = {
          idAgaPerguntaFormulario,
          respostas: [],
        };
      }

      groupedEvolucao[idAgaPerguntaFormulario].respostas.push(item);
    });
  });

  for (const key in groupedEvolucao) {
    groupedEvolucao[key].respostas.sort((a: any, b: any) => {
      return (
        (new Date(a.dataOcorrencia) as any) -
        (new Date(b.dataOcorrencia) as any)
      );
    });
  }

  const respostasEvolucao: any = Object.values(groupedEvolucao).map(
    (evolucao: any, index: number) => {
      return (
        <div key={generateUniqueId()} className="p-my-2 p-p-2 card-pergunta">
          <div className="p-d-flex p-ai-center">
            <SimpleText medium>
              {index + 1} -{' '}
              {evolucao.respostas[0]?.agaPerguntaFormularioDto.texto || ''}
            </SimpleText>
            {evolucao.respostas[0]?.agaPerguntaFormularioDto.textoAjuda && (
              <InformativoPergunta
                pergunta={evolucao.respostas[0].agaPerguntaFormularioDto}
              />
            )}
          </div>
          <Separator />
          {evolucao.respostas[0]?.agaPerguntaFormularioDto.id === 14 && (
            <>
              <SubtextoPergunta14 idPaciente={idPaciente} />
              <Separator />
            </>
          )}
          <div className="p-d-flex row-evolucao">
            {evolucao.respostas.map((resposta: any) => (
              <div
                key={resposta.id}
                className="p-d-flex p-flex-column p-mx-2 p-mb-2 p-gap-2 resposta-evolucao"
              >
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  {resposta?.dataOcorrencia
                    ? dayjs(new Date(resposta?.dataOcorrencia)).format(
                        'DD/MM/YYYY',
                      )
                    : ''}
                </SimpleText>
                <SimpleText>
                  {resposta?.agaRespostaPerguntaFormularioDto?.texto ??
                    resposta?.textoResposta ??
                    ''}
                </SimpleText>
              </div>
            ))}
          </div>
        </div>
      );
    },
  );

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header={header}
      footer={<Button label="Fechar" onClick={close} stretch btnType="ghost" />}
      id="modal-historico-aga"
      maximizedMobileSize
    >
      <Tab
        className="p-my-2 p-px-3"
        values={[
          {
            label: formulario.formulario,
            content: (
              <div>
                {verEvolucao ? respostasEvolucao : respostasQuestionario}
              </div>
            ),
          },
        ]}
        setActiveIndex={0}
      />
    </Dialog>
  );
};

export default ModalHistoricoAGA;
