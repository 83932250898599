import { useCallback } from 'react';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { AnamneseRowContent } from '../components/AnamneseRowContent/AnamneseRowContent';
import { ExamesProcedimentosRowContent } from '../components/ExamesProcedimentosRowContent/ExamesProcedimentosRowContent';
import PanelOptionsMenu from '../components/PanelOptionsMenu/PanelOptionsMenu';
import { Button } from 'src/components/_UI/Button';

import { CabecalhoHistoricoAtendimento } from '../CabecalhoHistoricoAtendimento';

interface RowProps {
  atendimento: any;
  historicoCompletoDisclosure: DisclosureType;
  justificativaDisclosure: DisclosureType;
}

const Row = ({
  atendimento,
  historicoCompletoDisclosure,
  justificativaDisclosure,
}: RowProps) => {
  const {
    setSelectedAtendimento,

    filtroHistorico,
  } = useHistoricoAtendimento();

  const nivelSigilo: number | null = atendimento.atendimento.nivelSigilo;

  const onExibirAtendimentoCompleto = useCallback(
    (atendimento: any) => {
      setSelectedAtendimento(atendimento);
      historicoCompletoDisclosure.open();
    },
    [historicoCompletoDisclosure, setSelectedAtendimento],
  );

  const onVisualizarJustificativa = (atendimento: any) => {
    setSelectedAtendimento(atendimento);
    justificativaDisclosure.open();
  };

  const optionsMenu = (atendimento: any) => (
    <PanelOptionsMenu
      ativo={atendimento?.status !== 'INATIVO'}
      idUsuarioCriacao={atendimento?.atendimento?.medico?.id}
      idAtendimentoRegistro={atendimento?.id}
      onVisualizarJustificativa={function (): void {
        onVisualizarJustificativa(atendimento);
      }}
      onAlterarLembrete={function (): void {}}
      onEdit={function (): void {}}
      onInativar={() => {
        setSelectedAtendimento(atendimento);
        justificativaDisclosure.open();
      }}
      onExcluir={function (): void {}}
      hideLembreteOption
      hideEditOption
    />
  );

  const rowContent = useCallback(
    atendimento => {
      switch (filtroHistorico) {
        case 'ANAMNESE':
          return <AnamneseRowContent atendimento={atendimento} />;
        case 'EXAMES_PROCEDIMENTOS':
          return <ExamesProcedimentosRowContent atendimento={atendimento} />;
        default:
          return <></>;
      }
    },
    [filtroHistorico],
  );

  return (
    <div className="historico-atendimento p-col-12 p-mt-3 p-p-2">
      <div className="p-d-flex p-jc-between p-ai-center p-col-12">
        <CabecalhoHistoricoAtendimento atendimento={atendimento} />
        <div>{optionsMenu(atendimento)}</div>
      </div>

      {rowContent(atendimento)}

      {nivelSigilo !== 0 && (
        <div className="p-mt-2">
          <Button
            type="button"
            btnType="green-link"
            label="Exibir completo"
            onClick={() => onExibirAtendimentoCompleto(atendimento)}
          />
        </div>
      )}
    </div>
  );
};

export default Row;
