import { useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

// import { itemsFieldEtapa, itemsFieldTipoConteudo } from '../utils';

import { cleanObject } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import DefinicaoCamposTexto from './Definicoes/DefinicaoCamposTexto/DefinicaoCamposTexto';
import DefinicaoImpressos from './Definicoes/DefinicaoImpressos/DefinicaoImpressos';
import DefinicaoMotivoAtendimento from './Definicoes/DefinicaoMotivoAtendimento/DefinicaoMotivoAtendimento';
import DefinicaoPedidoCuidado from './Definicoes/DefinicaoPedidoCuidado/DefinicaoPedidoCuidado';
import DefinicaoSolicitacaoExames from './Definicoes/DefinicaoSolicitacaoExame/DefinicaoSolicitacaoExames';

const definicoesItem = [
  {
    label: 'Campos texto',
    value: 'camposTexto',
  },
  {
    label: 'Solicitação de exames',
    value: 'solicitacaoExames',
  },
  {
    label: 'Pedido de cuidado programado',
    value: 'pedidoCuidado',
  },
  {
    label: 'Motivo de atendimento',
    value: 'motivoAtendimentos',
  },
  {
    label: 'Impressos',
    value: 'impressos',
  },
];

type Props = DisclosureType & {
  handleAdd: (v: any) => void;
  handleUpdateDefinicao: (v: any) => void;
};

export const ProtocoloAtendimentoCreateDialog = ({
  close,
  handleAdd,
  handleUpdateDefinicao,
  state = {},
}: Props) => {
  const { definicao, tipo } = state;

  const form = useForm({
    defaultValues: {
      ...definicao,
      tipo: tipo || 'camposTexto',
    },
  });

  const watchTipo = form.watch('tipo');

  const onSubmit = (v: any) => {
    //remove all items that are undefined or null from v keys and values
    const cleanedObject = cleanObject(v);

    if (Object.keys(definicao).some((key: any) => key === watchTipo)) {
      handleUpdateDefinicao(cleanedObject);
      return close();
    }

    handleAdd(cleanedObject);

    close();
  };

  const isEditing = Object.keys(definicao).some(
    (key: any) => key === watchTipo,
  );

  const renderDefinicao = useMemo(() => {
    switch (watchTipo) {
      case 'solicitacaoExames':
        return <DefinicaoSolicitacaoExames />;
      case 'pedidoCuidado':
        return <DefinicaoPedidoCuidado />;
      case 'motivoAtendimentos':
        return <DefinicaoMotivoAtendimento />;
      case 'impressos':
        return <DefinicaoImpressos />;
      default:
        return <DefinicaoCamposTexto />;
    }
  }, [watchTipo]);

  return (
    <Dialog
      visible
      onHide={close}
      header={isEditing ? 'Editar definição' : 'Adicionar definição'}
      style={{
        width: '720px',
        minWidth: '380px',
      }}
      id="dialog-protocolo"
      maximizedMobileSize
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-column p-gap-2"
        >
          <SimpleText fontSize="xs" fontColor="color_60">
            Selecione o tipo de definição que deseja inserir
          </SimpleText>
          <DropdownControlled
            control={form.control}
            name="tipo"
            label="Tipo"
            options={definicoesItem}
            filter={false}
            placeholder="Selecione o tipo de definição"
          />
          {renderDefinicao}
          <div className="p-d-flex p-gap-2">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
            />
            <Button
              label={isEditing ? 'Salvar' : 'Inserir'}
              type="submit"
              stretch
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
