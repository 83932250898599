/* eslint-disable @typescript-eslint/ban-types */
import { CSSProperties, ReactNode } from 'react';

import {
  RadioButton as PrimeRadioButton,
  RadioButtonProps as PrimeRadioButtonProps,
} from 'primereact/radiobutton';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './CustomRadioButton.scss';

interface CustomRadioButtonProps extends PrimeRadioButtonProps {
  label?: string;
  onClick: Function;
  component?: ReactNode;
  style?: CSSProperties;
  checked: boolean;
}

const CustomRadioButton = ({
  label,
  onClick,
  component,
  checked,
  name,
  style,
  ...rest
}: CustomRadioButtonProps) => {
  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={style}
      className={`CustomRadioButton ${checked ? 'active' : ''}`}
    >
      <PrimeRadioButton {...rest} />
      {label && !component && label !== '' ? (
        <SimpleText htmlFor={name}>{label}</SimpleText>
      ) : (
        component
      )}
    </div>
  );
};

export default CustomRadioButton;
