import React, { useCallback, useEffect, useState } from 'react';

import AvaliacaoRiscoAPI from 'src/APIs/ProntuarioAPI/AvaliacaoRisco/AvaliacaoRiscoAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalJustificativa from 'src/pages/Emed/Prontuario/FichaPaciente/AvaliacaoRisco/ModalJustificativa/ModalJustificativa';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

import 'src/pages/Emed/Prontuario/FichaPaciente/AvaliacaoRisco/AvaliacaoRisco.scss';

export default function HistoricoAvaliacaoRisco() {
  const [quantidadeAvalRisco, setQuantidadeAvalRisco] = useState<number>(1);
  const [exibirLista, setExibirLista] = useState<boolean>(true);

  const [avalRiscoInativacao, setAvalRiscoInativacao] =
    useState<DataCalculoRisco | null>(null);

  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<boolean>(false);

  useEffect(() => {
    if (quantidadeAvalRisco === 0) {
      setExibirLista(false);
    }
  }, [quantidadeAvalRisco]);

  const { selectedAtendimento, reload, setItensFichaPaciente, setReload } =
    useHistoricoAtendimento();

  const fetchAvaliacaoRisco = useCallback(
    async (params: any) => {
      const response = await AvaliacaoRiscoAPI.listAvaliacaoRisco(
        selectedAtendimento?.idAtendimento,
        {
          sortBy: 'dataAtualizacao',
          sortDirection: 'DESC',
          somenteAtendimento: true,
          ...params,
        },
      );
      setQuantidadeAvalRisco(response?.totalCount);
      setItensFichaPaciente(
        (prev: number) => Number(prev) + Number(response?.totalCount),
      );
      return response;
    },
    [selectedAtendimento?.idAtendimento, setItensFichaPaciente],
  );

  const onVisualizarJustificativa = (avaliacao: DataCalculoRisco) => {
    setAvalRiscoInativacao(avaliacao);
    setVisualizarJustificativa(true);
  };

  const reloadList = useCallback(() => {
    setReload(true);
  }, []);

  const optionsMenu = (avaliacao: any) => (
    <PanelOptionsMenu
      hideLembreteOption
      hideExcluirOption
      hideEditOption
      ativo={avaliacao.status === 'ATIVO'}
      idAtendimentoRegistro={avaliacao.idAtendimento}
      idUsuarioCriacao={avaliacao.idUsuarioAtualizacao}
      onInativar={() => setAvalRiscoInativacao(avaliacao)}
      onVisualizarJustificativa={() => onVisualizarJustificativa(avaliacao)}
      onEdit={() => {}}
      onExcluir={() => {}}
      onAlterarLembrete={() => {}}
    />
  );

  const renderRowAvalRisco = (avaliacao: any) => {
    return (
      <div className="p-my-1">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHeader
              avaliacao={avaliacao}
              isCollapsed={isCollapsed}
              onToggle={onToggle}
              optionsMenu={optionsMenu(avaliacao)}
            />
          )}
        >
          <PanelContent avaliacao={avaliacao} />
        </Panel>
      </div>
    );
  };
  return (
    <div>
      {exibirLista && (
        <div id="avaliacao-risco-container">
          <SearchList.Root fetchApi={fetchAvaliacaoRisco}>
            <SearchList.Header>
              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_16}
                className="p-mb-2"
                medium
              >
                Avaliação de risco
              </SimpleText>
            </SearchList.Header>

            <SearchList.BorderContainer>
              <SearchList.InfiniteScroll
                renderRow={renderRowAvalRisco}
                reload={reload}
              />
            </SearchList.BorderContainer>
          </SearchList.Root>
        </div>
      )}

      <ModalJustificativa
        avaliacaoRisco={avalRiscoInativacao}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setAvalRiscoInativacao(null);
          setVisualizarJustificativa(false);
        }}
        onSuccess={reloadList}
      />
    </div>
  );
}
