import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  convenio: Yup.object().nullable().required('O campo convênio é obrigatório.'),
  codigoPacote: Yup.string().when('trafegarProcedimentos', {
    is: true,
    then: Yup.string().required('O campo código é obrigatório.'),
  }),
  nomePacote: Yup.string().required('O campo nome é obrigatório.'),
  servicoTussPrincipal: Yup.number()
    .nullable()
    .required('Pacote deve ter um procedimento principal, favor definir.'),
  bloquearEdicaoPacote: Yup.boolean(),
  trafegarProcedimentos: Yup.boolean(),
  servicosTuss: Yup.array().min(
    1,
    'Pelo menos um procedimento precisa ser adicionado.',
  ),
});
