import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';
import AlterarSenhaRecuperacaoAPI from 'src/APIs/SecurityAPI/AlterarSenhaRecuperacaoAPI/AlterarSenhaRecuperacaoAPI';
import * as Yup from 'yup';

import { validatePasswordRules } from 'src/utils/formUtils';

import { Button } from 'src/components/_UI';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const RedefinicaoSenhaForm = ({
  codigoValidacao,
}: {
  codigoValidacao: string;
}) => {
  const { state } = useLocation();
  const { hashEmpresa } = useParams();
  const navigate = useNavigate();

  const isUserExterno = !!state?.isNewUser && !!state?.username;

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      senha: '',
      confirmarSenha: '',
    },
    resolver: yupResolver(
      Yup.object({
        senha: Yup.string()
          .matches(
            /^(?=.*[A-Z])/,
            'A senha deve conter pelo menos 1 letra maiúscula',
          )
          .matches(/^(?=.*[0-9])/, 'A senha deve conter pelo menos 1 número')
          .matches(
            /^(?=.*[!@#\$%\^&\*])/,
            'A senha deve conter pelo menos 1 caractere especial',
          ),
        confirmarSenha: Yup.string()
          .oneOf([Yup.ref('senha'), null], 'As senhas não são iguais.')
          .required('Campo obrigatório'),
      }),
    ),
  });

  const [watchSenha, watchConfirmar]: string[] = form.watch([
    'senha',
    'confirmarSenha',
  ]);
  const hasErrors = !!Object.keys(form.formState.errors)?.length;

  const {
    hasn8Digits,
    hasnCapitalLetter,
    hasnNumber,
    hasnSpecialCharacter,
    validateClassName,
  } = validatePasswordRules(watchSenha);

  const handleSubmit = (values: any) => {
    const data = {
      codigoValidacao: codigoValidacao,
      email: state.email,
      novaSenha: values.senha,
    };

    if (isUserExterno) {
      const codigoEmpresa = state.username.split('/')[0];

      return PortalPacienteAPI.updateNovoPacienteSenha(
        {
          login: state.username,
          senhaLogin: values.senha,
          novaSenha: values.senha,
          codigoEmpresa,
        },
        { throwError: true },
      )
        .then(() => {})
        .catch(() => {});
    }

    return AlterarSenhaRecuperacaoAPI.sendAlterarSenhaRecuperacao(data, {
      throwError: true,
    })
      .then(() => navigate(`portal/${hashEmpresa}/login`))
      .catch(() => {});
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="p-d-flex p-flex-column p-gap-3 p-mt-4"
      >
        <PasswordInputControlled
          control={form.control}
          name="senha"
          label="Nova senha"
          placeholder="Insira a sua senha"
          hideErrorMsg
          toggleMask
        />

        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText>A nova senha precisa conter, pelo menos:</SimpleText>
          <SimpleText>
            <ul className="p-px-4">
              <li className={validateClassName(hasn8Digits)}>8 dígitos</li>
              <li className={validateClassName(hasnSpecialCharacter)}>
                1 caractere especial (como !, @, #, etc.)
              </li>
              <li className={validateClassName(hasnCapitalLetter)}>
                1 letra maíuscula
              </li>
              <li className={validateClassName(hasnNumber)}>1 número</li>
            </ul>
          </SimpleText>
        </div>

        <PasswordInputControlled
          control={form.control}
          name="confirmarSenha"
          label="Confirme a nova senha"
          placeholder="Confirme a sua senha"
          toggleMask
        />

        <Button
          label="Recuperar acesso"
          type="submit"
          className="p-mt-2"
          disabled={!watchSenha || !watchConfirmar || hasErrors}
          stretch
        />
      </form>
    </FormProvider>
  );
};
