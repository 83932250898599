import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  return (
    <div className="p-grid  p-ai-center ">
      <div className="p-col-3 p-xl-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome / Tipo de usuário e Conselho
        </SimpleText>
      </div>
      <div className="p-col-3 p-lg-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Vinculos{' '}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Login e usuário
        </SimpleText>
      </div>
      <Can checkPermission={['ADM_USUARIO_ALTERAR', 'ADM_USUARIO_EXCLUIR']}>
        <div className="p-col p-d-flex p-jc-end p-mr-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Ações
          </SimpleText>
        </div>
      </Can>
    </div>
  );
};
