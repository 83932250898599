import dayjs from 'dayjs';

import { Button, Checkbox } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  paciente: PacientesCarteiraDTO;
  checked: boolean;
  idade: string;
  isTransferencia: boolean;
  onDelete: (id: number) => void;
  onHistory: (id: number) => void;
  onCheckBox: (paciente: PacientesCarteiraDTO) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const {
    paciente,
    onDelete,
    onHistory,
    isTransferencia,
    onCheckBox,
    checked,
    idade,
  } = props;

  const isEquipeCuidadora =
    paciente.ultimaMovimentacaoCarteira?.carteiraOrigem?.equipeCuidadora;
  const profissionalLabel = isEquipeCuidadora
    ? `Do profissional responsável`
    : 'Do profissional';
  const profissional =
    paciente.ultimaMovimentacaoCarteira?.carteiraOrigem?.equipeCuidadora
      ?.profissionalResposavel ??
    paciente.ultimaMovimentacaoCarteira?.carteiraOrigem?.profissionalCuidador;

  const dataUltimaConsultaPaciente =
    paciente?.ultimaConsulta?.dataUltimaConsulta;
  const dataUltimaConsulta = dataUltimaConsultaPaciente
    ? dayjs(dataUltimaConsultaPaciente).format('DD/MM/YYYY')
    : '';

  return (
    <>
      <CardItem>
        <Checkbox
          checked={checked}
          value={paciente}
          onChange={e => onCheckBox(e.value)}
        />

        <div className="p-col-3 p-ml-1 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {paciente.nome}
          </SimpleText>

          <SimpleText
            className="p-mt-1"
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {paciente.cpf}
          </SimpleText>

          {isTransferencia && (
            <div className="p-d-flex p-ai-center p-mt-2">
              <i className="fa fa-angle-double-right icon" />

              <SimpleText
                className="transferido-label"
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Transferido
              </SimpleText>
            </div>
          )}
        </div>

        <div className="p-col-3 p-px-0 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {paciente?.convenio?.nomeFantasia ||
              paciente?.convenio?.razaoSocial}
          </SimpleText>

          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {paciente?.cartaoPlano}
          </SimpleText>
        </div>

        <SimpleText
          className="p-col-2 p-px-0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {paciente.sexoDescricao}
        </SimpleText>

        <SimpleText
          className="p-col-2 p-px-0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {paciente.pontuacaoAps}
        </SimpleText>

        <SimpleText
          className="p-col-1 p-px-0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {idade}
        </SimpleText>

        <div className="p-col p-d-flex p-py-3 p-jc-center p-ml-3">
          <Button
            btnType="ghost"
            icon="pi pi-trash"
            onClick={() => onDelete(paciente.id)}
          />
        </div>
      </CardItem>

      {isTransferencia && (
        <div className="p-col-12 transferencia-container">
          <div className="transferencia-content p-grid">
            {isEquipeCuidadora && (
              <div className="p-col-3 p-px-0 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  Da equipe
                </SimpleText>

                <SimpleText
                  className="p-mt-1"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                >
                  {
                    paciente.ultimaMovimentacaoCarteira?.carteiraOrigem
                      ?.equipeCuidadora?.nome
                  }
                </SimpleText>
              </div>
            )}

            <div className="p-col p-px-0 p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                {profissionalLabel}
              </SimpleText>

              <SimpleText
                className="p-mt-1"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {profissional?.nome}
              </SimpleText>
            </div>

            <div className="p-col-2 p-px-0 p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Data última consulta
              </SimpleText>

              <SimpleText
                className="p-mt-1"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {dataUltimaConsulta}
              </SimpleText>
            </div>

            <div className="p-col-3 p-px-0 p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Data da transferência
              </SimpleText>

              <SimpleText
                className="p-mt-1"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {dayjs(
                  paciente.ultimaMovimentacaoCarteira?.dataAtualizacao,
                ).format('DD/MM/YYYY')}
              </SimpleText>
            </div>

            <div className="p-col-1 p-px-0 p-d-flex p-jc-end">
              <Button
                btnType="ghost"
                icon="pi pi-history"
                onClick={() => onHistory(paciente.id)}
              />
            </div>

            <div className="p-col-12 p-px-0 p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                motivo
              </SimpleText>

              <SimpleText
                className="p-mt-1"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {paciente.ultimaMovimentacaoCarteira?.motivoTransferenciaAps
                  ?.nome ?? paciente.ultimaMovimentacaoCarteira?.outrosMotivos}
              </SimpleText>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
