import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldFormulariosDinamicoProps = DropdownProps & {
  name?: string;
  label: string;
  ativos?: boolean;
};

export const FieldFormulariosDinamico = ({
  name = 'formularioDinamico',
  label,
  ativos = false,
  ...rest
}: FieldFormulariosDinamicoProps) => {
  const { control, watch } = useFormContext();
  const [formularios, setFormularios] = useState<any[]>([]);

  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetQuery = useThrottle(setQuery, 500);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async (query?: string) => {
    setIsLoading(true);
    try {
      const response = await FormularioDinamicoAPI.loadFormulariosDinamico(
        { nome: query, ...(ativos && { status: 'ATIVO' }) },
        { throwError: true },
      );

      setFormularios(response.list);
    } catch {
      setFormularios([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    load(query);
  }, [load, query]);

  const formularioAtual = watch(name);

  const options = useMemo(() => {
    const data = formularios.map(item => ({
      label: item?.nome,
      value: item,
    }));

    // valida se o @item atual está listado nas options
    if (formularioAtual) {
      const isFormularioAtualVisivel = formularios.find(
        item => item.id === formularioAtual.id,
      );

      if (!isFormularioAtualVisivel) {
        data.unshift({
          label: formularioAtual?.nome,
          value: formularioAtual,
        });
      }
    }

    return data;
  }, [formularioAtual, formularios]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      options={options}
      onFilter={e => handleSetQuery(e.filter)}
      loading={isLoading}
      carregandoFiltro={isLoading}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
