import { ReactNode, useState } from 'react';

import {
  UserAccessDataContext,
  AccessData,
} from '../../contexts/ManterUsuario/UserAccessData';

interface UserAccessDataProviderProps {
  children: ReactNode;
}

export function UserAccessDataProvider({
  children,
}: UserAccessDataProviderProps) {
  const [accessData, setAccessData] = useState<AccessData>({
    login: '',
    perfis: [],
  });
  const [accessStepCompleted, setAccessStepCompleted] =
    useState<boolean>(false);

  function saveAccessData(data: AccessData) {
    setAccessData(data);

    setAccessStepCompleted(true);
  }

  return (
    <UserAccessDataContext.Provider
      value={{
        accessData,
        accessStepCompleted,
        saveAccessData,
        setAccessStepCompleted,
      }}
    >
      {children}
    </UserAccessDataContext.Provider>
  );
}
