import { Fragment } from 'react';

import { useFormContext, useFieldArray } from 'react-hook-form';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import FormInput from 'src/components/FormInput/FormInput';

export const ExamesFuturosFields = () => {
  const { control } = useFormContext();

  const {
    fields: examesFuturos,
    append: appendExamesFuturos,
    remove: removeExamesFuturos,
  } = useFieldArray({
    control,
    name: 'examesFuturos',
  });

  const handleAddExameFuturo = () => {
    appendExamesFuturos({});
  };

  return (
    <>
      <div className="p-col-12 p-sm-6 p-d-flex p-ai-center p-jc-center p-mt-3">
        <Button
          type="button"
          btnType="green-link"
          label="Adicionar exame futuro"
          icon="fas fa-plus"
          onClick={handleAddExameFuturo}
        />
      </div>

      {examesFuturos.map((field, index) => (
        <Fragment key={field.id}>
          <CalendarInputControlled
            className="p-col-12 p-sm-6"
            control={control}
            name={`examesFuturos.${index}.data`}
            label="Data da realização"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
          />

          <FormInput
            className="p-col-12 p-sm-6"
            classNameInput="p-w-100"
            name={`examesFuturos.${index}.quantidade`}
            label="Quantidade"
            type="number"
            hideTextErrorSpace
          />

          <div className="p-col-12">
            <Button
              type="button"
              btnType="danger-link"
              className="danger p-col-align-start"
              label="Remover exame futuro"
              icon="fas fa-minus"
              onClick={() => removeExamesFuturos(index)}
            />
          </div>
        </Fragment>
      ))}
    </>
  );
};
