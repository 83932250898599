import { useCallback, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI/Button';
import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import SelectButton from 'src/components/SelectButton/SelectButton';

import FormEspecialidadesServicoTuss from './FormEspecialidadesServicoTuss/FormEspecialidadesServicoTuss';
import './FormServicoTussStep3.scss';

const formFields = {
  idadeMinima: 0,
  idadeMaxima: 0,
  tipoRestricaoIdade: '',
  msgRestricaoIdade: '',

  sexo: '',
  tipoRestricaoSexo: '',
  msgRestricaoSexo: '',

  prazoRepeticao: 0,
  tipoRestricaoPrazoRepeticao: '',
  msgRestricaoPrazoRepeticao: '',

  quantidadeMaxima: 0,
  tipoRestricaoQuantidadeMaxima: '',
  msgRestricaoQuantidadeMaxima: '',

  especialidadeLivre: [],
  tipoRestricaoEspecialidade: '',
  msgRestricaoEspecialidade: '',
};

interface FormServicoTussStep3Props {
  onSubmit(v: Partial<ServicoTussRestricoes>): void;
  values?: any;
  isOPME: boolean;
}

const FormServicoTussStep3 = ({
  onSubmit,
  values,
  isOPME,
}: FormServicoTussStep3Props) => {
  const navigate = useNavigate();

  const [faixaEtaria, setFaixaEtaria] = useState<any>(false);
  const [sexoPermitido, setSexoPermitido] = useState<any>(false);
  const [prazorepeticao, setPrazorepeticao] = useState<any>(false);
  const [comportamentoQuantidade, setComportamentoQuantidade] =
    useState<any>(false);
  const [comportamentoEspecialidade, setComportamentoEspecialidade] =
    useState<any>(false);
  const [listaEspecialidades, setListaEspecialidades] = useState<any[]>([]);
  const [tipoRestricaoIdade, setTipoRestricaoIdade] = useState('');
  const [tipoRestricaoSexo, setTipoRestricaoSexo] = useState('');
  const [tipoRestricaoPrazoRepeticao, setTipoRestricaoPrazoRepeticao] =
    useState('');
  const [tipoRestricaoQuantidade, setTipoRestricaoQuantidade] = useState('');
  const [tipoRestricaoEspecialidade, setTipoRestricaoEspecialidade] =
    useState('');

  const validationSchema = Yup.object().shape({
    idadeMinima: Yup.number()
      .nullable()
      .typeError('Idade mínima deve ser um número.'),
    // .min(1, 'Mínimo 1 ano')
    // .required('Digite uma idade mínima.'),
    idadeMaxima: Yup.number()
      .nullable()
      .typeError('Idade máxima deve ser um número.'),
    // .min(1, 'Mínimo 1 ano')
    // .required('Digite uma idade máxima.'),
    // tipoRestricaoIdade: Yup.string(),
    // .required(
    //   'Escolha um tipo de comportamento padrão.',
    // ),
    // msgRestricaoIdade: Yup.string(),
    // .required(
    //   'Digite uma mensagem para o sistema.',
    // ),

    sexo: Yup.string().nullable().typeError('Escolha o sexo.'),
    // .required('Escolha o sexo.'),
    // tipoRestricaoSexo: Yup.string()
    // .required(
    //   'Escolha um tipo de comportamento padrão.',
    // ),
    // msgRestricaoSexo: Yup.string(),
    // .required(
    //   'Digite uma mensagem para o sistema.',
    // ),

    prazoRepeticao: Yup.number()
      .nullable()
      .typeError('Prazo de repetição deve ser um número.'),
    // .min(1, 'Mínimo 1 dia')
    // .required('Digite um prazo de repetição mínimo.'),
    // tipoRestricaoPrazoRepeticao: Yup.string(),
    // .required(
    //   'Escolha um tipo de comportamento padrão.',
    // ),
    // msgRestricaoPrazoRepeticao: Yup.string(),
    // .required(
    //   'Digite uma mensagem para o sistema.',
    // ),

    quantidadeMaxima: Yup.number()
      .nullable()
      .typeError('Quantidade máxima deve ser um número.'),
    // .min(1, 'Mínimo 1')
    // .required('Digite uma quantidade máxima.'),
    // tipoRestricaoQuantidadeMaxima: Yup.string(),
    // .required(
    //   'Escolha um tipo de comportamento padrão.',
    // ),
    // msgRestricaoQuantidadeMaxima: Yup.string(),
    // .required(
    //   'Digite uma mensagem para o sistema.',
    // ),

    // especialidadeLivre: Yup.array(),
    // .required(
    //   'Adicione ao menos uma especialidade livre.',
    // ),
    // tipoRestricaoEspecialidade: Yup.string(),
    // .required(
    //   'Escolha um tipo de comportamento padrão.',
    // ),
    // msgRestricaoEspecialidade: Yup.string(),
    // .required(
    //   'Digite uma mensagem para o sistema.',
    // ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = useCallback(() => {
    reset(formFields);
    navigate(-1);
  }, [reset, navigate]);

  useEffect(() => {
    if (values !== undefined || Object.keys(values).length > 0) {
      setValue('idadeMinima', values.idadeMinima);
      setValue('idadeMaxima', values.idadeMaxima);
      setValue('tipoRestricaoIdade', values.tipoRestricaoIdade);
      setTipoRestricaoIdade(values.tipoRestricaoIdade);
      setValue('msgRestricaoIdade', values.msgRestricaoIdade);
      setValue('sexo', values.sexo);
      setValue('tipoRestricaoSexo', values.tipoRestricaoSexo);
      setTipoRestricaoSexo(values.tipoRestricaoSexo);
      setValue('msgRestricaoSexo', values.msgRestricaoSexo);
      setValue('prazoRepeticao', values.prazoRepeticao);
      setValue(
        'tipoRestricaoPrazoRepeticao',
        values.tipoRestricaoPrazoRepeticao,
      );
      setTipoRestricaoPrazoRepeticao(values.tipoRestricaoPrazoRepeticao);
      setValue('msgRestricaoPrazoRepeticao', values.msgRestricaoPrazoRepeticao);
      setValue('quantidadeMaxima', values.quantidadeMaxima);
      setValue(
        'tipoRestricaoQuantidadeMaxima',
        values.tipoRestricaoQuantidadeMaxima,
      );
      setTipoRestricaoQuantidade(values.tipoRestricaoQuantidadeMaxima);
      setValue(
        'msgRestricaoQuantidadeMaxima',
        values.msgRestricaoQuantidadeMaxima,
      );
      setValue('especialidadeLivre', values.especialidadeLivre);
      setValue('tipoRestricaoEspecialidade', values.tipoRestricaoEspecialidade);
      setTipoRestricaoEspecialidade(values.tipoRestricaoEspecialidade);
      setValue('msgRestricaoEspecialidade', values.msgRestricaoEspecialidade);

      setListaEspecialidades(values.especialidadeLivre);
    }
  }, [reset, setValue, values]);

  const handleRestricoes = useCallback(
    async (data: any) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  return (
    <form
      className="step3-servicotuss"
      onSubmit={handleSubmit(handleRestricoes)}
      defaultValue={''}
      onReset={() => reset(formFields)}
    >
      <SimpleText bold className="titlefaixa">
        Faixa etária
      </SimpleText>
      <div className={'row'}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <TextInput
              label=""
              className="grid p-col-6 p-md-6"
              placeholder="Idade inicial"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              errorMsg={errors.idadeMinima?.message}
              type="number"
              min={1}
            />
          )}
          name="idadeMinima"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <TextInput
              label=""
              placeholder="Idade final"
              className="grid p-col-6 p-md-6"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              errorMsg={errors.idadeMaxima?.message}
              type="number"
              min={1}
            />
          )}
          name="idadeMaxima"
        />
      </div>

      {faixaEtaria && (
        <>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange } }: any) => (
              <div className="radioinput">
                <SimpleText bold>
                  Comportamento padrão para os demais
                </SimpleText>

                <div className="p-d-flex p-flex-column p-flex-sm-row">
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoIdade('ALERTA');
                      onChange('ALERTA');
                    }}
                    label="Alerta"
                    name="alerta"
                    checked={tipoRestricaoIdade === 'ALERTA'}
                  />
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoIdade('BLOQUEIO');
                      onChange('BLOQUEIO');
                    }}
                    label="Bloqueio"
                    name="bloqueio"
                    checked={tipoRestricaoIdade === 'BLOQUEIO'}
                  />

                  <RadioButton
                    onChange={() => {
                      setTipoRestricaoIdade('JUSTIFICATIVA');
                      onChange('JUSTIFICATIVA');
                    }}
                    label="Justificativa"
                    name="justificativa"
                    checked={tipoRestricaoIdade === 'JUSTIFICATIVA'}
                  />
                </div>
              </div>
            )}
            name="tipoRestricaoIdade"
          />
          {errors?.tipoRestricaoIdade?.message && (
            <div className="invalid-feedback">
              {errors.tipoRestricaoIdade?.message}
            </div>
          )}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <TextInput
                label="Mensagem informada pelo sistema"
                className="grid p-col-12 p-md-12"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                errorMsg={errors.msgRestricaoIdade?.message}
              />
            )}
            name="msgRestricaoIdade"
          />
        </>
      )}
      <div className="p-d-fles p-jc-start">
        <Button
          icon={faixaEtaria ? 'pi pi-minus' : 'pi pi-plus'}
          label={
            faixaEtaria
              ? 'Remover comportamento para demais'
              : 'Adicionar comportamento para demais'
          }
          btnType={faixaEtaria ? 'danger-link' : 'green-link'}
          className="btnadd p-mb-4"
          onClick={() => {
            setFaixaEtaria(!faixaEtaria);
          }}
        />
      </div>

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }: any) => (
          <SelectButton
            label="Sexo permitido"
            value={value}
            options={[
              { label: 'Masculino', value: 'M' },
              { label: 'Feminino', value: 'F' },
            ]}
            onChange={onChange}
            errorMsg={errors.sexo?.message}
          />
        )}
        name="sexo"
      />

      {sexoPermitido && (
        <>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <div className="radioinput">
                <SimpleText bold>
                  Comportamento padrão para os demais
                </SimpleText>

                <div className="p-d-flex p-flex-column p-flex-sm-row">
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoSexo('ALERTA');
                      onChange('ALERTA');
                    }}
                    label="Alerta"
                    name="alerta"
                    checked={tipoRestricaoSexo === 'ALERTA'}
                  />
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoSexo('BLOQUEIO');
                      onChange('BLOQUEIO');
                    }}
                    label="Bloqueio"
                    name="bloqueio"
                    checked={tipoRestricaoSexo === 'BLOQUEIO'}
                  />

                  <RadioButton
                    onChange={() => {
                      setTipoRestricaoSexo('JUSTIFICATIVA');
                      onChange('JUSTIFICATIVA');
                    }}
                    label="Justificativa"
                    name="justificativa"
                    checked={tipoRestricaoSexo === 'JUSTIFICATIVA'}
                  />
                </div>
              </div>
            )}
            name="tipoRestricaoSexo"
          />
          {errors?.tipoRestricaoSexo?.message && (
            <div className="invalid-feedback">
              {errors.tipoRestricaoSexo?.message}
            </div>
          )}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <TextInput
                label="Mensagem informada pelo sistema"
                className="grid p-col-12 p-md-12"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                errorMsg={errors.msgRestricaoSexo?.message}
              />
            )}
            name="msgRestricaoSexo"
          />
        </>
      )}
      <div className="p-d-fles p-jc-start">
        <Button
          icon={sexoPermitido ? 'pi pi-minus' : 'pi pi-plus'}
          label={
            sexoPermitido
              ? 'Remover comportamento para demais'
              : 'Adicionar comportamento para demais'
          }
          btnType={sexoPermitido ? 'danger-link' : 'green-link'}
          className="btnadd p-mb-4"
          onClick={() => {
            setSexoPermitido(!sexoPermitido);
          }}
        />
      </div>

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }: any) => (
          <TextInput
            label="Prazo de repetição (dias)"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            errorMsg={errors.prazoRepeticao?.message}
            type="number"
            min={1}
          />
        )}
        name="prazoRepeticao"
      />

      {prazorepeticao && (
        <>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange } }: any) => (
              <div className="radioinput">
                <SimpleText bold>
                  Comportamento padrão para os demais
                </SimpleText>

                <div className="p-d-flex p-flex-column p-flex-sm-row">
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoPrazoRepeticao('ALERTA');
                      onChange('ALERTA');
                    }}
                    label="Alerta"
                    name="alerta"
                    checked={tipoRestricaoPrazoRepeticao === 'ALERTA'}
                  />
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoPrazoRepeticao('BLOQUEIO');
                      onChange('BLOQUEIO');
                    }}
                    label="Bloqueio"
                    name="bloqueio"
                    checked={tipoRestricaoPrazoRepeticao === 'BLOQUEIO'}
                  />

                  <RadioButton
                    onChange={() => {
                      setTipoRestricaoPrazoRepeticao('JUSTIFICATIVA');
                      onChange('JUSTIFICATIVA');
                    }}
                    label="Justificativa"
                    name="justificativa"
                    checked={tipoRestricaoPrazoRepeticao === 'JUSTIFICATIVA'}
                  />
                </div>
              </div>
            )}
            name="tipoRestricaoPrazoRepeticao"
          />
          {errors?.tipoRestricaoPrazoRepeticao?.message && (
            <div className="invalid-feedback">
              {errors.tipoRestricaoPrazoRepeticao?.message}
            </div>
          )}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <TextInput
                label="Mensagem informada pelo sistema"
                className="grid p-col-12 p-md-12"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                errorMsg={errors.msgRestricaoPrazoRepeticao?.message}
              />
            )}
            name="msgRestricaoPrazoRepeticao"
          />
        </>
      )}
      <div className="p-d-fles p-jc-start">
        <Button
          icon={prazorepeticao ? 'pi pi-minus' : 'pi pi-plus'}
          label={
            prazorepeticao
              ? 'Remover comportamento para demais'
              : 'Adicionar comportamento para demais'
          }
          btnType={prazorepeticao ? 'danger-link' : 'green-link'}
          className="btnadd p-mb-4"
          onClick={() => {
            setPrazorepeticao(!prazorepeticao);
          }}
        />
      </div>

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }: any) => (
          <TextInput
            label="Quantidade máxima"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            errorMsg={errors.quantidadeMaxima?.message}
          />
        )}
        name="quantidadeMaxima"
      />

      {comportamentoQuantidade && (
        <>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <div className="radioinput">
                <SimpleText bold>
                  Comportamento padrão para os demais
                </SimpleText>

                <div className="p-d-flex p-flex-column p-flex-sm-row">
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoQuantidade('ALERTA');
                      onChange('ALERTA');
                    }}
                    label="Alerta"
                    name="alerta"
                    checked={tipoRestricaoQuantidade === 'ALERTA'}
                  />
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoQuantidade('BLOQUEIO');
                      onChange('BLOQUEIO');
                    }}
                    label="Bloqueio"
                    name="bloqueio"
                    checked={tipoRestricaoQuantidade === 'BLOQUEIO'}
                  />

                  <RadioButton
                    onChange={() => {
                      setTipoRestricaoQuantidade('JUSTIFICATIVA');
                      onChange('JUSTIFICATIVA');
                    }}
                    label="Justificativa"
                    name="justificativa"
                    checked={tipoRestricaoQuantidade === 'JUSTIFICATIVA'}
                  />
                </div>
              </div>
            )}
            name="tipoRestricaoQuantidadeMaxima"
          />
          {errors?.tipoRestricaoQuantidadeMaxima?.message && (
            <div className="invalid-feedback">
              {errors.tipoRestricaoQuantidadeMaxima?.message}
            </div>
          )}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <TextInput
                label="Mensagem informada pelo sistema"
                className="grid p-col-12 p-md-12"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                errorMsg={errors.msgRestricaoQuantidadeMaxima?.message}
              />
            )}
            name="msgRestricaoQuantidadeMaxima"
          />
        </>
      )}
      <div className="p-d-fles p-jc-start">
        <Button
          icon={comportamentoQuantidade ? 'pi pi-minus' : 'pi pi-plus'}
          label={
            comportamentoQuantidade
              ? 'Remover comportamento para demais'
              : 'Adicionar comportamento para demais'
          }
          btnType={comportamentoQuantidade ? 'danger-link' : 'green-link'}
          className="btnadd p-mb-4"
          onClick={() => {
            setComportamentoQuantidade(!comportamentoQuantidade);
          }}
        />
      </div>

      <FormEspecialidadesServicoTuss
        values={listaEspecialidades}
        onSubmit={v => {
          setListaEspecialidades(v);
          setValue('especialidadeLivre', v);
        }}
      />

      {comportamentoEspecialidade && (
        <>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <div className="radioinput">
                <SimpleText bold>
                  Comportamento padrão para os demais
                </SimpleText>

                <div className="p-d-flex p-flex-column p-flex-sm-row">
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoEspecialidade('ALERTA');
                      onChange('ALERTA');
                    }}
                    label="Alerta"
                    name="alerta"
                    checked={tipoRestricaoEspecialidade === 'ALERTA'}
                  />
                  <RadioButton
                    className="p-mr-0 p-mr-sm-3"
                    onChange={() => {
                      setTipoRestricaoEspecialidade('BLOQUEIO');
                      onChange('BLOQUEIO');
                    }}
                    label="Bloqueio"
                    name="bloqueio"
                    checked={tipoRestricaoEspecialidade === 'BLOQUEIO'}
                  />

                  <RadioButton
                    onChange={() => {
                      setTipoRestricaoEspecialidade('JUSTIFICATIVA');
                      onChange('JUSTIFICATIVA');
                    }}
                    label="Justificativa"
                    name="justificativa"
                    checked={tipoRestricaoEspecialidade === 'JUSTIFICATIVA'}
                  />
                </div>
              </div>
            )}
            name="tipoRestricaoEspecialidade"
          />
          {errors?.tipoRestricaoEspecialidade?.message && (
            <div className="invalid-feedback">
              {errors.tipoRestricaoEspecialidade?.message}
            </div>
          )}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <TextInput
                label="Mensagem informada pelo sistema"
                className="grid p-col-12 p-md-12"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                errorMsg={errors.msgRestricaoEspecialidade?.message}
              />
            )}
            name="msgRestricaoEspecialidade"
          />
        </>
      )}
      <div className="p-d-fles p-jc-start">
        <Button
          icon={comportamentoEspecialidade ? 'pi pi-minus' : 'pi pi-plus'}
          label={
            comportamentoEspecialidade
              ? 'Remover comportamento para demais'
              : 'Adicionar comportamento para demais'
          }
          btnType={comportamentoEspecialidade ? 'danger-link' : 'green-link'}
          className="btnadd p-mb-2"
          onClick={() => {
            setComportamentoEspecialidade(!comportamentoEspecialidade);
          }}
        />
      </div>

      <div className={'p-d-flex p-grid p-col-12 p-px-0'}>
        <div className={'p-col-12 p-sm-3'}>
          <Button
            btnType="ghost"
            stretch
            label="Voltar"
            className={'p-col-align-center p-d-flex'}
            onClick={handleCancel}
            loading={isSubmitting}
          />
        </div>
        <div className={'p-col-12 p-sm-9'}>
          <Button
            stretch
            label={isOPME ? 'Salvar e avançar' : 'Concluir'}
            type="submit"
            loading={isSubmitting}
            btnType={isOPME ? 'tonal' : 'default'}
          />
        </div>
      </div>
    </form>
  );
};

export default FormServicoTussStep3;
