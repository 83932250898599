/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import TipoCuidadoAPI from 'src/APIs/ConfigAPI/TipoCuidado/TipoCuidadoAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalAddTipoCuidado from './ModalAddTipoCuidado/ModalAddTipoCuidado';
import ModalExcluirTipoCuidado from './ModalExcluirTipoCuidado/ModalExcluirTipoCuidado';
import { Row } from './TipoCuidadoGrid/Row';

import './TipoCuidado.scss';

interface TipoCuidadoProps {
  consultoriosDisponiveis: any[];
  consultoriosUsuario: any[];
}

const TipoCuidado = ({
  consultoriosDisponiveis,
  consultoriosUsuario,
}: TipoCuidadoProps) => {
  const [reload, setReload] = useState(0);

  const [tipoCuidado, setTipoCuidado] = useState<any>({
    id: null,
    consultoriosIds: [],
    descricao: '',
    status: false,
  });

  const modalAddDisclosure = useDisclosure({ opened: false });
  const modalExcluirDisclosure = useDisclosure({ opened: false });

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'age_tipo_de_cuidado_cadastrar',
      'age_tipo_de_cuidado_alterar',
      'age_tipo_de_cuidado_consultar',
      'age_tipo_de_cuidado_excluir',
    ],
  });

  const fetchApi = async () => {
    return await TipoCuidadoAPI.loadTipoCuidado();
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleModalAddTipoCuidado = useCallback(() => {
    setTipoCuidado({
      id: null,
      consultorios: [],
      descricao: '',
      status: false,
    });
    modalAddDisclosure.open();
  }, []);

  const handleModalAddTipoCuidadoClose = useCallback(() => {
    setTipoCuidado({
      id: null,
      consultorios: [],
      descricao: '',
      status: false,
    });
    modalAddDisclosure.close();
  }, []);

  const handleModalEditTipoCuidado = useCallback((item: any) => {
    setTipoCuidado(item);
    modalAddDisclosure.open();
  }, []);

  const handleModalExcluirTipoCuidadoClose = useCallback(() => {
    modalExcluirDisclosure.close();
  }, []);

  const handleExcluirCuidado = useCallback((item: any) => {
    modalExcluirDisclosure.open();
    setTipoCuidado(item);
  }, []);

  const renderRow = (data: any) => (
    <Row
      data={data}
      onDelete={handleExcluirCuidado}
      onEdit={handleModalEditTipoCuidado}
    />
  );

  return (
    <>
      <SimpleText>
        Gerencie os tipos de cuidado que serão exibidos para os usuários do
        sistema
      </SimpleText>

      <div className="TipoCuidado">
        <SearchList.Root
          fetchApi={fetchApi}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.BorderContainer>
            <div className="p-d-flex p-flex-sm-row p-jc-end p-mb-3">
              <Button
                btnType="pill"
                label="Novo tipo de cuidado"
                icon="fa fa-plus"
                checkPermission="AGE_TIPO_DE_CUIDADO_CADASTRAR"
                disabled={!consultoriosDisponiveis?.length}
                onClick={handleModalAddTipoCuidado}
              />
            </div>

            <Header />

            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>

        {modalAddDisclosure.isOpen && (
          <ModalAddTipoCuidado
            consultoriosDisponiveis={consultoriosDisponiveis}
            consultoriosUsuario={consultoriosUsuario}
            tipoCuidado={tipoCuidado}
            reload={reloadList}
            visible={true}
            onHide={handleModalAddTipoCuidadoClose}
          />
        )}

        {modalExcluirDisclosure.isOpen && (
          <ModalExcluirTipoCuidado
            tipoCuidado={tipoCuidado}
            reload={reloadList}
            visible={true}
            onHide={handleModalExcluirTipoCuidadoClose}
          />
        )}

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </>
  );
};

export default TipoCuidado;
