import { useState } from 'react';

import dayjs from 'dayjs';
import { ItemCuidadoProps } from 'src/models/APIs/ProntuarioAPI/PlanoCuidado/PlanoCuidado';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PlanoCuidadoItem.scss';

type IProps = {
  handleDelete: () => void;
  onEdit: () => void;
  item: ItemCuidadoProps;
};

export const PlanoCuidadoPedidoCuidadoItem = ({
  handleDelete,
  onEdit,
  item,
}: IProps) => {
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector((state: RootState) => state);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`content-item ${!isOpen ? 'closed' : ''}`}>
      <div className="content-item-header p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Atividade
          </SimpleText>
          <SimpleText medium>Pedido de cuidado</SimpleText>
        </div>

        <div className="p-d-flex p-gap-2">
          <Button
            icon="fas fa-edit"
            btnType="ghost"
            type="button"
            onClick={onEdit}
          />
          <Button
            icon="fas fa-trash"
            btnType="ghost"
            type="button"
            onClick={() => handleDelete()}
          />
          <Button
            icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            onClick={() => setIsOpen(old => !old)}
            btnType="ghost"
            type="button"
          />
        </div>
      </div>

      <div className="p-grid content-item-body" aria-expanded={!isOpen}>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome
          </SimpleText>
          <SimpleText>{item.nome}</SimpleText>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Tipo de cuidado
          </SimpleText>
          <SimpleText>{item.tipo?.descricao}</SimpleText>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Recorrência
          </SimpleText>
          <SimpleText>{item.recorrencia} dias</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Execução
          </SimpleText>
          <SimpleText>{item.execucao}</SimpleText>

          <Divider className="p-col-12" layout="horizontal" />

          <div className="p-d-flex p-gap-2 p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Adicionado por{' '}
              <b>{item?.usuarioAtualizacao?.nome || profissionalAtivo?.nome}</b>{' '}
              em{' '}
              {dayjs(item?.dataAtualizacao || undefined).format(
                'DD [de] MMM [de] YYYY',
              )}{' '}
            </SimpleText>
            <i className="fas fa-lock-open lock-green" />
          </div>
        </div>
      </div>
    </div>
  );
};
