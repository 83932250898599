import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/dnpm-puericultura';

export const getPerguntas = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/valores-enum`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const getQuestionario = async (
  idPaciente: number,
  idMedico: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/paciente/${idPaciente}/medico/${idMedico}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const postQuestionario = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}`,
    data: payload,
    hideToast: true,
    errorHideToast: true,
    return: true,
    ...options,
  });
};

export const putQuestionario = async (
  idAtendimento: number,
  idQuestionario: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${idQuestionario}/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });
};

const DnpmAPI = {
  getPerguntas,
  getQuestionario,
  postQuestionario,
  putQuestionario,
};

export default DnpmAPI;
