import { useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import Separator from 'src/components/Separator/Separator';

import InputImagem from './InputImagem/InputImagem';
import { formValidation } from './validacao';

import './FormMessage.scss';

interface FormMessageProps {
  onSubmit(v: Partial<UsuarioDTO>): void;
  values?: any | undefined;
}

const FormMessage = ({ onSubmit, values }: FormMessageProps) => {
  const [adicionarLink, setAdicionarLink] = useState<boolean>(
    !!values.linkExterno,
  );
  const [adicionarImagem, setAdicionarImagem] = useState<boolean>(
    !!values?.imagem,
  );
  const [imagem, setImagem] = useState<File | null | undefined>(values?.image);

  const useFormMethods = useForm({
    defaultValues: {
      ...values,
      dataInicio: dayjs(values?.dataInicio).isValid()
        ? dayjs(values?.dataInicio).toDate()
        : null,
      dataFim: dayjs(values?.dataFim).isValid()
        ? dayjs(values?.dataFim).toDate()
        : null,
      adicionarLink: !!values?.linkExterno && !!values?.textoLink,
      adicionarImagem: !!values?.imagem,
    },
    resolver: yupResolver(formValidation),
  });

  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
    setValue,
  } = useFormMethods;

  const watchDataInicio = watch('dataInicio');
  const watchDataFim = watch('dataFim');

  const handleDataInicioChange = (e: any) => {
    const newDate = e.value;
    if (newDate && watchDataFim && newDate > watchDataFim) {
      setValue('dataFim', null);
    }
  };

  return (
    <div className={'FormMessage'}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((data: any) => onSubmit({ ...data, imagem }))}
          className="p-grid"
        >
          <FormInput
            className="p-col-12"
            name="titulo"
            label="Título"
            placeholder="Título"
            maxLength={50}
            maxLengthSpan
            errorMsg={errors?.titulo?.message}
          />

          <TextareaInputControlled
            className="p-col-12"
            control={control}
            name="mensagem"
            label="Mensagem"
            placeholder="Mensagem"
            maxLength={4000}
            maxLengthSpan
            autoResize
            errorMsg={errors?.mensagem?.message}
          />

          <Separator layout="horizontal" />

          <div className={'p-col-12 p-py-0 p-mt-3'}>
            <SimpleText fontColor="color_60" medium>
              Notificações do sistema
            </SimpleText>
          </div>
          <div className={'p-col-12 p-mt-3 p-mb-3'}>
            <SimpleText fontSize={FONT_SIZE.XS}>
              Defina a data em que a notificação ficar visível para o
              destinatário.
            </SimpleText>
          </div>

          <div className="p-col-12 p-grid">
            <CalendarInputControlled
              className="p-col-12 p-pl-0 p-md-6"
              control={control}
              name="dataInicio"
              label="Data do início"
              errorMsg={errors?.dataInicio?.message}
              minDate={new Date()}
              customOnChange={e => {
                handleDataInicioChange(e);
              }}
            />
            <CalendarInputControlled
              className="p-col-12 p-md-6"
              control={control}
              name="dataFim"
              label="Data do fim"
              errorMsg={errors?.dataFim?.message}
              minDate={watchDataInicio}
            />
          </div>

          <Separator layout="horizontal" />

          {!adicionarLink ? (
            <div className="p-col-12 p-px-0">
              <Button
                btnType="green-link"
                icon="fas fa-plus"
                iconPos="left"
                label="Adicionar botão para ação"
                onClick={() => setAdicionarLink(true)}
              />
            </div>
          ) : (
            <div className="p-col-12 p-grid">
              <FormInput
                className="p-col-12"
                name="textoLink"
                label="Texto do botão para ação"
                placeholder="Texto do botão para ação"
                errorMsg={errors?.textoLink?.message}
              />
              <FormInput
                className="p-col-12"
                name="linkExterno"
                label="Link do botão para ação"
                placeholder="http://"
                errorMsg={errors?.linkExterno?.message}
              />
              <div className="p-col-12 p-px-0">
                <Button
                  btnType="danger-link"
                  icon="fas fa-minus"
                  iconPos="left"
                  label="Remover link"
                  onClick={() => setAdicionarLink(false)}
                />
              </div>
            </div>
          )}

          {!adicionarImagem ? (
            <div className="p-col-12 p-px-0">
              <Button
                btnType="green-link"
                icon="fas fa-plus"
                iconPos="left"
                label="Adicionar imagem"
                onClick={() => setAdicionarImagem(true)}
              />
            </div>
          ) : (
            <div className="p-col-12 p-grid column">
              <div className="p-col-12">
                <InputImagem
                  setImagem={setImagem}
                  imagem={imagem}
                  editImagem={values?.imagem}
                />
              </div>
              <CheckboxControlled
                className="p-col-12"
                control={control}
                name="flagLinkImagem"
                label="Tornar a imagem clicável (retira o link do botão)."
              />
              <div className="p-col-12 p-px-0">
                <Button
                  btnType="danger-link"
                  icon="fas fa-minus"
                  iconPos="left"
                  label="Remover imagem"
                  onClick={() => setAdicionarImagem(false)}
                />
              </div>
            </div>
          )}

          <div className="p-col-12 p-grid">
            <div className="p-col-12 p-md-6">
              <Button
                stretch
                type="button"
                btnType="ghost"
                label="Cancelar"
                onClick={() => navigate(-1)}
              />
            </div>

            <div className="p-col-12 p-md-6">
              <Button
                stretch
                type="submit"
                btnType="tonal"
                label="Salvar"
                loading={isSubmitting}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormMessage;
