import { useRef } from 'react';

import {
  RadioButton as PrimeRadioButton,
  RadioButtonProps as PrimeRadioButtonProps,
  RadioButtonChangeParams,
} from 'primereact/radiobutton';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './style.scss';

export type RadioButtonProps = PrimeRadioButtonProps & {
  label?: string;
  panel?: boolean;
  onChange?: (e: Pick<RadioButtonChangeParams, 'value' | 'checked'>) => void;
  customOnChange?: () => void;
  secondText?: string | undefined;
};

export const RadioButton = ({
  className,
  label,
  panel = true,
  secondText,
  ...props
}: RadioButtonProps) => {
  const ref = useRef<any>(null);

  const changedProps = {
    checked: !props.checked,
    value: props.value,
  };

  return (
    <div
      aria-checked={!!props.checked}
      aria-disabled={!!props.disabled}
      className={`RadioButton-ui ${className || ''} ${
        panel ? 'radio-panel' : ''
      }`}
      onClick={() =>
        !props.disabled
          ? [
              ref.current?.props.onChange(changedProps),
              props.customOnChange?.(),
            ]
          : undefined
      }
      style={{
        width: !!secondText ? '100%' : '',
      }}
    >
      <PrimeRadioButton ref={ref} {...props} />

      {!!label && (
        <SimpleText aria-disabled={!!props.disabled}>{label}</SimpleText>
      )}

      {!!secondText && (
        <SimpleText className="p-ml-auto" fontColor="color_60">
          {secondText}
        </SimpleText>
      )}
    </div>
  );
};
