import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile, isTablet } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <SimpleText
            className="p-col-2 p-md-1 p-text-center"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Data do envio
          </SimpleText>
          <SimpleText
            className="p-col-3"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Nome / Cartão do beneficiário
          </SimpleText>
          <SimpleText
            className="p-col-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Nome médico
          </SimpleText>
          {!isTablet && (
            <SimpleText
              className="p-col-2"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
              medium
            >
              Contato / Forma de envio / Conteúdo
            </SimpleText>
          )}
          <SimpleText
            className="p-col-3 p-md-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Data consentimento / Status
          </SimpleText>
          <SimpleText
            className="p-col-1"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Termo
          </SimpleText>
          <SimpleText
            className="p-col-1 p-text-truncate"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Localização
          </SimpleText>
        </div>
      )}
    </>
  );
};
