import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelHB from './HB/PanelHB';
import PanelHBContent from './HB/PanelHBContent';
import PanelHI from './HI/PanelHI';
import PanelHIContent from './HI/PanelHIContent';
import PanelLeucocitosPlaquetas from './LeucocitosPlaquetas/PanelLeucocitosPlaquetas';
import PanelLeucocitosPlaquetasContent from './LeucocitosPlaquetas/PanelLeucocitosPlaquetasContent';

interface PanelReagentesContentProps {
  idGestacao: number;
  historicoReagentes: any;
}

export default function PanelReagentesContent({
  idGestacao,
  historicoReagentes,
}: PanelReagentesContentProps) {
  return (
    <>
      <div className="p-my-1">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHB isCollapsed={isCollapsed} onToggle={onToggle} />
          )}
        >
          <PanelHBContent
            idGestacao={idGestacao}
            historicoReagentes={historicoReagentes}
          />
        </Panel>
      </div>
      <div className="p-my-1">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHI isCollapsed={isCollapsed} onToggle={onToggle} />
          )}
        >
          <PanelHIContent
            idGestacao={idGestacao}
            historicoReagentes={historicoReagentes}
          />
        </Panel>
      </div>
      <div className="p-my-1">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelLeucocitosPlaquetas
              isCollapsed={isCollapsed}
              onToggle={onToggle}
            />
          )}
        >
          <PanelLeucocitosPlaquetasContent
            idGestacao={idGestacao}
            historicoReagentes={historicoReagentes}
          />
        </Panel>
      </div>
    </>
  );
}
