import { useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import FormInput from 'src/components/FormInput/FormInput';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  index: number;
  servicoTuss: any;
  onDelete: (index: number) => void;
}

export const Row = (props: RowProps) => {
  const { index, servicoTuss, onDelete } = props;

  const { isMobile } = useSize();
  const { control, setValue, getValues } = useFormContext();

  const inputQuantidade = (
    <FormInput
      autoFocus
      className="light"
      name={`servicosTuss.${index}.quantidade`}
      type="number"
      min={1}
      max={servicoTuss?.quantidadeMaxima}
      editOnClick
    />
  );

  const radioButtonPrincipal = (
    <RadioButtonControlled
      control={control}
      name="servicoTussPrincipal"
      value={servicoTuss.idServicoTuss}
      panel={false}
    />
  );

  const handleDelete = () => {
    const servicoTussPrincipal = getValues('servicoTussPrincipal');

    if (servicoTussPrincipal === servicoTuss.idServicoTuss)
      setValue('servicoTussPrincipal', null);

    onDelete(index);
  };

  const commonProps = {
    servicoTuss,
    inputQuantidade,
    radioButtonPrincipal,
    onDelete: handleDelete,
  };

  return isMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
