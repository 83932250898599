import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import AuthAPI from 'src/APIs/SecurityAPI/AuthAPI/AuthAPI';

import { loginAuthLink } from 'src/core/redux/slices/system/UserSlice';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

export const AuthLink = () => {
  const { hashLogin, hashEmpresa } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const returnLoginPage = useCallback(
    (err?: string) => {
      navigate(`/portal/${hashEmpresa}/login`, {
        state: {
          from: 'authLink',
          error:
            err ||
            'Não foi possível realizar o login automático. Contate o suporte se o problema persistir!',
        },
      });
    },
    [hashEmpresa, navigate],
  );

  const onValidateHashLogin = useCallback(async () => {
    if (!hashEmpresa) return returnLoginPage('Empresa não informada');
    if (!hashLogin) return returnLoginPage();

    try {
      const data = await AuthAPI.sendPortalLoginAutomatico(
        hashLogin,
        hashEmpresa,
        { throwError: true },
      );

      await dispatch(loginAuthLink(data));

      navigate(`/portal/${hashEmpresa}/inicio`);
    } catch (err) {
      returnLoginPage();
    }
  }, [dispatch, hashEmpresa, hashLogin, navigate, returnLoginPage]);

  useEffect(() => {
    onValidateHashLogin();
  }, [onValidateHashLogin]);

  return <SpinnerLoading full />;
};
