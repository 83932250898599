import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import useSize from 'src/core/hooks/useSize';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalFiltroServicoTuss from './ModalFiltroServicoTuss/ModalFiltroServicoTuss';
import { DesktopRow } from './ServicoTussGrid/DesktopRow';
import { MobileRow } from './ServicoTussGrid/MobileRow';

import './ServicoTussGlobal.scss';

const ServicoTussGlobal = () => {
  const navigate = useNavigate();
  const { isTablet } = useSize();
  const [reload, setReload] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [buttonVisible, setButtonVisible] = useState(false);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_servico_tuss_cadastrar',
      'adm_servico_tuss_alterar',
      'adm_servico_tuss_consultar',
      'adm_servico_tuss_excluir',
    ],
  });

  const fetchApi = async ({ query, ...rest }: any) => {
    return await ServicoTussCRUDAPI.loadServicoTuss({
      nome: query,
      ...rest,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleEdit = useCallback(
    (servicoTussId: number) => {
      navigate(`/config/config-tuss/servico-tuss/adicionar/${servicoTussId}`);
    },
    [navigate],
  );

  const handleIsActive = useCallback(
    async (item: any) => {
      let response: any;

      if (item?.status) {
        response = await ServicoTussCRUDAPI.updateServicoTussInativar(item?.id);
      } else {
        response = await ServicoTussCRUDAPI.updateServicoTussAtivar(item?.id);
      }

      if (response?.status === 204) {
        setItems([...items]);
        reloadList();
      }
    },
    [items],
  );

  const handleDuplicar = useCallback(
    async (servicoTuss: any) => {
      navigate('/config/config-tuss/servico-tuss/adicionar', {
        state: servicoTuss,
      });
      setButtonVisible(!buttonVisible);
    },
    [buttonVisible, navigate],
  );

  const renderRow = useCallback(
    (data: any): JSX.Element => {
      let content = null;

      if (isTablet) {
        content = (
          <MobileRow
            data={data}
            onEdit={handleEdit}
            onSwitchStatus={handleIsActive}
            onDuplicar={handleDuplicar}
          />
        );
      } else {
        content = (
          <DesktopRow
            data={data}
            onEdit={handleEdit}
            onSwitchStatus={handleIsActive}
            onDuplicar={handleDuplicar}
          />
        );
      }

      return content;
    },
    [handleDuplicar, handleEdit, handleIsActive, isTablet],
  );

  return (
    <div className="p-px-4">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
            <SearchList.SearchInput placeholder="Código ou nome TUSS" />

            <div className="p-col-6 p-lg-3 p-mb-2">
              <ModalFiltroServicoTuss />
            </div>

            <div className="p-col-6 p-lg-3 p-grid">
              <Button
                btnType="pill"
                className="p-mb-2 p-col-12 p-px-0"
                label="Novo"
                icon="fas fa-plus"
                checkPermission="ADM_SERVICO_TUSS_CADASTRAR"
                onClick={() => {
                  navigate('/config/config-tuss/servico-tuss/adicionar');
                }}
              />
            </div>
          </div>

          <SearchList.TotalCount className={isTablet ? 'p-mb-2' : 'p-ml-2'} />

          <SearchList.Header>
            <Header />
          </SearchList.Header>

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default ServicoTussGlobal;
