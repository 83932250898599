import { useCallback, useEffect, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { OverlayPanel } from 'primereact/overlaypanel';

import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import { LoadPrescricaoFavoritos } from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import {
  resetInvalidateQuery,
  setInvalidateQuery,
} from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './FieldMedicamentosFavoritos.scss';
import { useDispatch } from 'react-redux';

import { Button } from 'src/components/_UI/Button';

type FieldMedicamentosFavoritosProps = {
  onAdd: (items: any[], aditionalInfo?: any) => void;
};

type IMedicamentosRow = {
  item: LoadPrescricaoFavoritos;
  setItems: (old?: any) => void;
};

type AccordionHeaderProps = {
  item: LoadPrescricaoFavoritos;
  onSubmit: (value: boolean) => void;
};

export const FieldMedicamentosFavoritos = ({
  onAdd,
}: FieldMedicamentosFavoritosProps) => {
  const {
    agenda: { profissionalAtivo },
    query,
  } = useAppSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const searchPanelRef = useRef<OverlayPanel>(null);

  const { invalidatePrescricaoFavotiros } = query;

  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState<LoadPrescricaoFavoritos[]>([]);
  const [itemsSelected, setItemsSelect] = useState<LoadPrescricaoFavoritos[]>(
    [],
  );

  const [loadingQuery, setLoadingQuery] = useState(false);

  const inputSearch = useThrottle(setInputValue, 500);

  const handleAdd = () => {
    if (itemsSelected.length > 0) {
      const medicamentos = itemsSelected.flatMap(
        fav => fav.prescricaoMedicamentos,
      );
      const formatted = medicamentos.map(med => ({
        id: med?.idMedicamento,
        nome: med?.descricao,
        detentorRegistroAnvisa: med?.laboratorio,
        quantidade: med?.quantidade,
      }));

      onAdd(
        formatted,
        itemsSelected.length === 1 && {
          via: itemsSelected[0]?.via,
          posologia: itemsSelected[0]?.posologia,
        },
      );

      setItemsSelect([]);
    }
  };

  const fetchAPI = useCallback(
    (param?: string) => {
      setLoadingQuery(true);

      PrescricaoAPI.loadPrescricaoFavoritos(
        profissionalAtivo.id,
        { descricao: param },
        {
          throwError: true,
        },
      )
        .then(data => setItems(data))
        .catch(() => {})
        .finally(() => setLoadingQuery(false));
    },
    [profissionalAtivo.id],
  );

  useEffect(() => {
    fetchAPI(inputValue);
  }, [fetchAPI, inputValue]);

  useEffectSkipFirst(() => {
    if (invalidatePrescricaoFavotiros) {
      fetchAPI();
      dispatch(resetInvalidateQuery());
    }
  }, [invalidatePrescricaoFavotiros]);

  return (
    <div className="p-w-100 p-grid">
      <Button
        btnType="green-link"
        icon="fa fa-chevron-down"
        iconPos="right"
        label="Favoritos"
        stretch
        onClick={e => searchPanelRef.current?.toggle(e)}
      />

      <OverlayPanel
        id="overlay_panel"
        appendTo="self"
        className="panel-medicamentos-favoritos"
        ref={searchPanelRef}
      >
        <div className="p-w-100">
          <SimpleText className="p-col-12" bold>
            Favoritos
          </SimpleText>

          <div className="p-col-12 p-mb-4">
            <TextInputSearch
              onChange={e => inputSearch(e)}
              placeholder="Pesquisar por favoritos"
            />
          </div>

          <div className="fav-content">
            {!!items.length ? (
              items.map((item, idx) => (
                <MedicamentosRow
                  key={idx}
                  item={item}
                  setItems={setItemsSelect}
                />
              ))
            ) : (
              <div className="p-d-flex p-jc-center p-my-2">
                {loadingQuery ? (
                  <SpinnerLoading full size="sm" />
                ) : (
                  <SimpleText>{'Nenhum favorito encontrado'}</SimpleText>
                )}
              </div>
            )}
          </div>

          {!!itemsSelected.length && (
            <div className="p-py-2">
              <Button
                label="Adicionar"
                stretch
                onClick={() => [handleAdd(), searchPanelRef.current?.hide()]}
              />
            </div>
          )}
        </div>
      </OverlayPanel>
    </div>
  );
};

const MedicamentosRow = ({ item, setItems }: IMedicamentosRow) => {
  const accordionItems = item.prescricaoMedicamentos;

  const onSubmit = useCallback(
    (event = true) => {
      if (event) {
        setItems((old: any) => {
          return [...old, item];
        });
      } else {
        setItems((old: any) => {
          const data = old.filter((value: any) => value.id !== item.id);

          return [...data];
        });
      }
    },
    [setItems, item],
  );

  return (
    <Accordion>
      <AccordionTab
        header={<AccordionHeader onSubmit={onSubmit} item={item} />}
      >
        <div className="p-d-flex p-flex-column p-gap-2">
          {!!accordionItems?.length &&
            accordionItems.map((medicamento, idx) => (
              <div
                key={idx}
                className="row p-d-flex p-col-12 p-ai-center p-py-0"
              >
                <SimpleText className="p-col-10 p-text-truncate">
                  {medicamento.descricao}
                </SimpleText>

                <div className="p-col-2 p-d-flex p-flex-column p-gap-1">
                  <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
                  <SimpleText
                    className="p-text-truncate"
                    fontColor={FONT_COLOR.PRIMARY}
                  >
                    {medicamento?.quantidade || 0}
                  </SimpleText>
                </div>
              </div>
            ))}
        </div>
      </AccordionTab>
    </Accordion>
  );
};

const AccordionHeader = ({ onSubmit, item }: AccordionHeaderProps) => {
  const { isMobile } = useSize();
  const [checked, setChecked] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    setDeleting(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    PrescricaoAPI.deletePrescricaoFavoritos(item.id!, { throwError: true })
      .then(() =>
        dispatch(setInvalidateQuery({ invalidatePrescricaoFavotiros: true })),
      )
      .catch(() => {})
      .finally(() => setDeleting(false));
  };

  return (
    <div className="row p-d-flex p-col-12 p-ai-center p-py-0">
      <div className="w-30" onClick={e => e.stopPropagation()}>
        <Checkbox
          checked={checked}
          onChange={e => [onSubmit(e.checked), setChecked(e.checked)]}
        />
      </div>
      <SimpleText className="p-col-8 p-sm-4 p-text-climp-2">
        {item.descricao}
      </SimpleText>
      {!isMobile && (
        <div className="p-col-3 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>Posologia</SimpleText>
          <SimpleText className="p-text-truncate">{item.posologia}</SimpleText>
        </div>
      )}
      {!isMobile && (
        <div className="p-col-2 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>Via</SimpleText>
          <SimpleText className="p-text-truncate">
            {capitalizeFirstLetter(item.via || '')}
          </SimpleText>
        </div>
      )}
      <div className="p-col p-d-flex p-jc-end p-gap-1 p-py-0">
        <Button
          icon="fa fa-trash"
          type="button"
          btnType="gray"
          onClick={handleDelete}
          loading={isDeleting}
        />
      </div>
    </div>
  );
};
