import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';


interface MobileRowProps {
  funcionalidade: Funcionalidade;
  onConfig: () => void;
  onSwitchStatus: () => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { funcionalidade, onConfig, onSwitchStatus } = props;

  const options = [
    {
      label: funcionalidade?.status ? 'Inativar' : 'Ativar',
      command: onSwitchStatus,
      checkPermission: 'ADM_FUNCIONALIDADE_ALTERAR',
    },
    {
      label: 'Configuraçoes',
      command: onConfig,
      checkPermission: 'ADM_FUNCIONALIDADE_ALTERAR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText
          className="p-mb-4"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {funcionalidade?.nome}
        </SimpleText>

        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {funcionalidade?.descricao}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
