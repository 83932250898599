import { useNavigate } from 'react-router';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

import './FormularioDinamico.scss';

export const FormularioDinamico = () => {
  const navigate = useNavigate();

  const cardItems = [
    {
      label: 'Acervo de formulários',
      subtitle: 'Exiba a lista de formulários',
      checkPermission: 'ADM_MANTER_PERGUNTA_FORMULARIO_DINAMICO_CONSULTAR',
      onClick: () =>
        navigate('/cadastros/formulario-dinamico/acervo-formularios'),
    },
    {
      label: 'Acervo de perguntas',
      subtitle: 'Exiba a lista de perguntas já salvas',
      checkPermission: 'ADM_MANTER_FORMULARIO_DINAMICO_CADASTRAR',
      onClick: () =>
        navigate('/cadastros/formulario-dinamico/acervo-perguntas'),
    },
    {
      label: 'Aplicação de formulários',
      subtitle: 'Exiba a lista de aplicação dos formulários',
      checkPermission: 'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_CADASTRAR',
      onClick: () => navigate('/cadastros/formulario-dinamico/aplicacao'),
    },
    {
      label: 'Gestão e monitoramento',
      subtitle: 'Exiba a lista de monitoramento dos formulários',
      checkPermission: 'ADM_GESTAO_MONITORAMENTO_FORMULARIO_DINAMICO',
      onClick: () =>
        navigate('/cadastros/formulario-dinamico/gestao-monitoramento'),
    },
  ];

  return (
    <div className="FormularioDinamico p-grid p-gap-2 p-justify-between">
      {cardItems.map((item, idx) => (
        <Can key={idx} checkPermission={item.checkPermission}>
          <div
            key={idx}
            onClick={() => item.onClick()}
            className="card-item p-d-flex p-d-flex p-ai-center p-jc-between"
          >
            <div className="p-d-flex p-flex-column p-gap-3">
              <div className="p-d-flex p-ai-center p-gap-2">
                <div className="card-icon-container p-d-flex p-jc-center p-ai-center">
                  <i className="fas fa-hospital" />
                </div>
                <SimpleText fontSize="md">{item.label}</SimpleText>
              </div>

              <SimpleText>{item.subtitle}</SimpleText>
            </div>

            <i className="fas fa-chevron-right" />
          </div>
        </Can>
      ))}
    </div>
  );
};
