import { memo, useCallback, useEffect, useState } from 'react';

import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';

import { Button } from 'src/components/_UI/Button';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalEditValoresServico.scss';

interface ModalEditValoresServicoProps {
  visible: boolean;
  onHide(): void;
  valorServico: any;
  setValorServico(p: any): void;
  onSuccess(): void;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
  isFormValid: boolean;
}

const Footer = memo(({ onCancel, onAdd, isFormValid }: FooterProps) => {
  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        label={'Salvar'}
        onClick={onAdd}
        disabled={!isFormValid}
        stretch
      />
    </div>
  );
});

const ModalEditValoresServico = ({
  valorServico,
  visible,
  onHide,
  onSuccess,
}: ModalEditValoresServicoProps) => {
  const [valor, setValor] = useState<number>(0);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (Object.keys(valorServico)?.length > 0) {
      setValor(valorServico?.valor);
    }
  }, [valorServico]);

  useEffect(() => {
    if (valor) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [valor]);

  const handleEditValorServico = useCallback(async () => {
    const data = {
      idServicoTuss: valorServico.servicoTuss.id,
      valor,
    };

    const response = await ServicoTussValorAPI.updateServicoTussValor(
      valorServico.id,
      valorServico.idProfissionalSaude,
      data,
    );

    if (response?.status === 200) {
      onSuccess();
    }
  }, [valorServico, valor, onSuccess]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Editar cadastro de valor de serviço"
      visible={visible}
      maximizedMobileSize
      onHide={onHide}
      footer={() => (
        <Footer
          onAdd={handleEditValorServico}
          onCancel={onHide}
          isFormValid={isFormValid}
        />
      )}
    >
      <div className="modal-body-add-valores-servico">
        <TextInput
          className="p-field p-mb-2"
          value={valorServico?.profissionalSaude?.nome}
          disabled
          label="Profissionais"
        />

        <div className="p-pt-2">
          <div className="p-grid p-flex-row p-jc-between p-col-12 p-p-0">
            <div className="p-col-12 p-md-8 p-pl-0">
              <TextInput
                label="Convênio(s) / Serviços TUSS"
                value={
                  valorServico.servicoTuss?.convenio?.razaoSocial +
                  ' - ' +
                  valorServico.servicoTuss?.codigo +
                  ' - ' +
                  valorServico.servicoTuss?.nome
                }
                onChange={() => ({})}
                disabled
              />
            </div>
            <div className="p-col-12 p-md-4 p-px-0 TextInput p-field">
              <ComponentsLabel label="Valor (em reais)" />
              <span
                className={`iconHandler ${
                  !isFormValid ? 'p-input-icon-right' : ''
                }`}
              >
                {!isFormValid ? (
                  <i className={'pi pi-exclamation-circle'} />
                ) : (
                  <></>
                )}

                <InputNumber
                  inputClassName={!isFormValid ? 'p-invalid' : ''}
                  placeholder="R$"
                  mode="currency"
                  locale="pt-BR"
                  format={true}
                  allowEmpty={false}
                  max={99999}
                  value={valor}
                  onChange={e => setValor(e.target.value)}
                />
              </span>
              <div className={'p-d-flex p-jc-between p-ai-center p-mt-1'}>
                <div>
                  {!isFormValid && (
                    <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                      Valor é obrigatório
                    </SimpleText>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalEditValoresServico;
