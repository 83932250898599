import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import { Button } from '../_UI/Button';

import ListBox from './ListBox/ListBox';

import './PickList.scss';

interface PickListProps {
  label: string;
  arrayOptions: any[];
  disabled?: boolean;
  arraySelected: any;
  setSelected: (value: any) => void;
}

const PickList = (props: PickListProps) => {
  const {
    label,
    arrayOptions,
    setSelected,
    arraySelected,
    disabled = false,
  } = props;
  const useFormMethods = useForm();

  const { control, setValue, watch } = useFormMethods;

  const removerSelecaoDisclosure = useDisclosure({ opened: false });
  const removeTodosDisclosure = useDisclosure({ opened: false });
  const adicionarTodosDisclosure = useDisclosure({ opened: false });

  const handleClear = useCallback(() => {
    setValue('pickedOptions', []);
    setValue('pickedSelected', []);
  }, [setValue]);

  useEffect(() => {
    handleClear();
  }, [handleClear]);

  const watchPickedOption = watch('pickedOptions');
  const watchPickedSelected = watch('pickedSelected');

  useEffect(() => {
    if (watchPickedOption?.length > 0) {
      setValue('pickedSelected', []);
    }
  }, [setValue, watchPickedOption?.length]);

  useEffect(() => {
    if (watchPickedSelected?.length > 0) {
      setValue('pickedOptions', []);
    }
  }, [setValue, watchPickedSelected?.length]);

  const handleAdd = useCallback(() => {
    const picked: any = watchPickedOption.map((value: any) => ({
      label: value?.nome,
      value: {
        id: value?.id,
      },
    }));

    setSelected((prev: any) => [...prev, ...picked]);

    handleClear();
  }, [watchPickedOption, setSelected, handleClear]);

  const handleRemove = () => {
    const removeIds = watchPickedSelected.map((item: any) => item.id);
    const arrayFiltered = arraySelected.filter(
      (item: any) => !removeIds.includes(item.value?.id),
    );
    removerSelecaoDisclosure.close();
    setSelected(arrayFiltered);
    handleClear();
  };

  const optionsList = arrayOptions.filter(
    (optionItem: any) =>
      !arraySelected?.some?.(
        (itemSelected: any) => itemSelected?.value?.id === optionItem.value?.id,
      ) || false,
  );

  return (
    <div id="pickList-container" className="">
      <div className="p-col-12 p-px-0 p-d-flex content">
        <div className="p-col-4 p-px-0 box">
          <div className="p-mb-3">
            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
              {label} disponíveis
            </SimpleText>
          </div>

          <ListBox
            listClassName="pickList-list list"
            optionsClassName="list-item"
            control={control}
            name="pickedOptions"
            options={optionsList}
            disabled={disabled}
          />
        </div>

        <div className="p-col-4 p-px-6 p-d-flex p-flex-column p-jc-center p-mb-3 box">
          <Button
            type="button"
            className="p-mt-3"
            label="Adicionar seleção"
            btnType="tonal"
            icon="fa fa-angle-right"
            onClick={handleAdd}
            disabled={disabled || watchPickedOption?.length === 0}
          />
          <Button
            type="button"
            className="p-mt-3"
            label="Adicionar todos"
            btnType="tonal"
            icon="fa fa-angle-double-right"
            onClick={() => {
              adicionarTodosDisclosure.open();
            }}
            disabled={disabled || optionsList?.length === 0}
          />

          <Button
            type="button"
            className="p-mt-3 btn-remover"
            label="Remover seleção"
            btnType="danger"
            icon="fa fa-close"
            onClick={() => removerSelecaoDisclosure.open()}
            disabled={disabled || watchPickedSelected?.length === 0}
          />

          <Button
            type="button"
            className="p-mt-3 btn-remover"
            label="Remover todos"
            btnType="danger"
            icon="fa fa-close"
            onClick={() => removeTodosDisclosure.open()}
            disabled={disabled || arraySelected?.length === 0}
          />
        </div>

        <div className="p-col-4 p-px-0 box">
          <div className="p-mb-3">
            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
              {label} selecionados
            </SimpleText>
          </div>

          <ListBox
            listClassName="pickList-list list"
            optionsClassName="list-item"
            control={control}
            name="pickedSelected"
            options={arraySelected}
            disabled={disabled}
            customTextEmpyt={
              disabled
                ? `Todos os ${label} foram adicionados`
                : `Nenhum ${label.substring(0, label.length - 1)} adicionado`
            }
          />
        </div>
      </div>

      <ConfirmDialog
        visible={removerSelecaoDisclosure.isOpen}
        header={`Deseja remover os ${label.toLocaleLowerCase()}?`}
        text={`Se você realmente deseja remover os ${label.toLocaleLowerCase()} selecionados, clique em sim para continuar.`}
        confirmText="Sim, remover"
        onHide={removerSelecaoDisclosure.close}
        onConfirm={handleRemove}
      />

      <ConfirmDialog
        visible={removeTodosDisclosure.isOpen}
        header={`Deseja remover todos os ${label.toLocaleLowerCase()}?`}
        text={`Se você realmente deseja remover todos os ${label.toLocaleLowerCase()}, clique em sim para continuar.`}
        confirmText="Sim, remover"
        onHide={removeTodosDisclosure.close}
        onConfirm={() => {
          setSelected([]);
          handleClear();
          removeTodosDisclosure.close();
        }}
      />

      <ConfirmDialog
        visible={adicionarTodosDisclosure.isOpen}
        header={`Deseja adicionar todos os ${label.toLocaleLowerCase()}?`}
        text={`Se você realmente deseja adicionar todos os ${label.toLocaleLowerCase()}, clique em sim para continuar.`}
        confirmText="Sim, adicionar"
        onHide={adicionarTodosDisclosure.close}
        onConfirm={() => {
          setSelected(arrayOptions);
          handleClear();
          adicionarTodosDisclosure.close();
        }}
      />
    </div>
  );
};

export default PickList;
