import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { JitsiMeeting } from '@jitsi/react-sdk';

import './TelemedicinaConfirm.scss';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';

import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import Button from 'src/components/Basics/Button/Buttons';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

export const TelemedicinaConfirm = ({
  salaURL,
  agendamentoId,
}: {
  salaURL: string;
  agendamentoId: string | number;
}) => {
  const { profissionalAtivo } = useAppSelector(
    (state: RootState) => state.agenda,
  );

  const [isFinished, setIsFinished] = useState(false);
  const [hasInvited, setHasInvited] = useState(false);

  const api = useRef<IJitsiMeetExternalApi>();

  const handleDispensarPaciente = useCallback(() => {
    TelemedicinaSalaAtendimentoAPI.sendRemoverPacienteSala({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      idAtendimento: agendamentoId!,
    });

    // api.current?.removeAllListeners();
  }, [agendamentoId]);

  useEffect(() => {
    return () => {
      handleDispensarPaciente();
    };
  }, [handleDispensarPaciente]);

  useEffectSkipFirst(() => {
    // Desabilita o btn de entrada do paciente caso o mesmo já estiver na sala.
    api.current?.addListener('participantJoined', () => setHasInvited(true));
    // Habilita o btn de entrada do paciente caso o mesmo estiver saído da sala.
    api.current?.addListener('participantLeft', () => setHasInvited(false));
    // Finaliza conferência quando moderador finalizar a chamada
    api.current?.addListener('videoConferenceLeft', () => {
      handleDispensarPaciente();
    });
  }, [api.current]);

  const roomName = useMemo(() => {
    return salaURL ? salaURL.split('/')?.[3] || '' : '';
  }, [salaURL]);

  if (!salaURL && !roomName) {
    return null;
  }

  const handleHabilitarSalaPaciente = () => {
    TelemedicinaSalaAtendimentoAPI.sendSalaAtendimento({
      idAtendimento: agendamentoId,
    });
  };

  return (
    <div className="p-w-100 p-d-flex p-flex-column p-gap-3 p-pb-4">
      <div className="teleconsulta-frame-agenda">
        {isFinished ? (
          <div className="container-finalizada">
            <SimpleText bold>
              <i className="fas fa-phone p-mr-2" />
              Chamada encerrada!
            </SimpleText>
          </div>
        ) : (
          <JitsiMeeting
            domain="teleatendimento.appunimed.com"
            roomName={roomName}
            userInfo={{
              email: profissionalAtivo.emailProfissional,
              displayName: profissionalAtivo.nome,
            }}
            onApiReady={externalApi => (api.current = externalApi)}
            onReadyToClose={() => [setIsFinished(true)]}
            spinner={SpinnerLoading}
            configOverwrite={{
              height: '100%',
              defaultLanguage: 'pt-BR',
            }}
            interfaceConfigOverwrite={{
              LANG_DETECTION: false,
              VIDEO_LAYOUT_FIT: 'nocrop',
              MOBILE_APP_PROMO: false,
            }}
          />
        )}
      </div>

      {!isFinished && (
        <Button
          stretch
          label="Habilitar entrada do paciente"
          onClick={handleHabilitarSalaPaciente}
          disabled={hasInvited}
        />
      )}
    </div>
  );
};
