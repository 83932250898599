import { useState } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Dialog from 'src/components/Dialog/Dialog';
import Tab, { TabItem } from 'src/components/Tab/Tab';

import { useFormularioDinamicoAplicacaoFormulario } from '../../useFormularioDinamicoAplicacaoFormulario';

import { DialogVinculosConsultorio } from './DialogVinculosConsultorio';
import { DialogVinculosConvenio } from './DialogVinculosConvenio';
import { DialogVinculosEspecialidade } from './DialogVinculosEspecialidade';

export const DialogVinculos = ({
  close,
  state,
}: DisclosureType<IAplicacaoFormularioDinamicoItem>) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { consultorios, convenios, especialidades } =
    useFormularioDinamicoAplicacaoFormulario();

  const especialidadesItems = state?.especialidades?.length
    ? state.especialidades?.map(item => item.especialidade)
    : especialidades;
  const consultoriosItems = state?.consultorios?.length
    ? state.consultorios?.map(item => item.consultorio)
    : consultorios;
  const conveniosItems = state?.convenios?.length
    ? state.convenios?.map(item => item.convenio)
    : convenios;

  const tabs: TabItem[] = [
    {
      label: 'Especialidade',
      content: (
        <DialogVinculosEspecialidade
          especialidadesItems={especialidadesItems}
        />
      ),
    },
    {
      label: 'Consultório',
      content: (
        <DialogVinculosConsultorio consultoriosItems={consultoriosItems} />
      ),
    },
    {
      label: 'Convênio',
      content: <DialogVinculosConvenio conveniosItems={conveniosItems} />,
    },
  ];

  const title = tabs[tabIndex]!.label;
  const length =
    tabIndex === 0
      ? especialidadesItems.length
      : tabIndex === 1
      ? consultoriosItems.length
      : conveniosItems.length;

  return (
    <Dialog
      visible
      onHide={close}
      header={`Lista de vínculos - ${title} (${length})`}
      style={{
        width: '528px',
      }}
    >
      <>
        <Tab
          clean
          values={tabs}
          initialTabIndex={tabIndex}
          activeIndex={tabIndex}
          onChange={e => setTabIndex(e)}
        />
        <Button
          label="Fechar"
          btnType="outline"
          className="p-col-12 p-mt-2"
          onClick={() => close()}
        />
      </>
    </Dialog>
  );
};
