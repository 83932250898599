import { useMemo, useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { TemplateProps } from 'react-draggable-list';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import useSize from 'src/core/hooks/useSize';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { cn } from 'src/utils/cn';

import { DialogDeletarPergunta } from '../../_components/DialogDeletarPergunta/DialogDeletarPergunta';
import {
  DialogNovaPergunta,
  DialogNovaPerguntaStateProps,
} from '../../_components/DialogNovaPergunta/DialogNovaPergunta';
import { DialogCopyOrderQuetion } from './_components/DialogCopyOrderQuetion';
import { Button, Checkbox, RadioButton } from 'src/components/_UI';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import Divider from 'src/components/Basics/Divider/Divider';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';

import './style.scss';

type AcervoFormularioCreatePerguntasRowProps = TemplateProps<
  IFormularioDinamicoPergunta,
  void
> & {
  commonProps: { getItemIndex: (i: number) => number; perguntasLength: number };
};

export const AcervoFormulariosCreatePerguntasRow: React.FC<
  AcervoFormularioCreatePerguntasRowProps
> = ({
  item,
  itemSelected,
  dragHandleProps,
  commonProps,
}: AcervoFormularioCreatePerguntasRowProps) => {
  const { state } = useLocation();

  const type = item.perguntaFormularioDinamico.tipoResposta;
  const pergunta = item.perguntaFormularioDinamico;
  const isItemMoving = itemSelected !== 0;

  const viewOnly = state?.to !== 'edit';

  const itemIndex = useMemo(
    () => commonProps.getItemIndex(item.id),
    [commonProps, item.id],
  );

  return (
    <div
      className={cn(
        `pergunta-container p-col-12 p-flex-wrap row content-row`,
        viewOnly && 'pointer-events-none',
      )}
      data-isMoving={isItemMoving}
    >
      <div
        className="p-col-6 p-xl-9 p-py-0 p-d-flex p-ai-center"
        {...dragHandleProps}
        style={{
          cursor: isItemMoving ? 'move' : 'grab',
        }}
      >
        <SimpleText medium>
          {itemIndex + 1} {' - '}
          {pergunta?.enunciado}
        </SimpleText>
      </div>

      <Actions
        item={item}
        perguntasLength={commonProps.perguntasLength}
        className="p-col-6 p-xl-3 p-py-0 p-d-flex p-jc-end p-ai-center p-gap-2"
      />

      <Divider className="p-col-12 p-my-2" layout="horizontal" />

      {type === 'TEXTO' && (
        <div className="p-col-12 p-md-6 p-p-0">
          <TextareaInput
            className="input-bg-white"
            readOnly
            resizeble={false}
            placeholder="Resposta ..."
          />
        </div>
      )}
      {type === 'NUMERO' && (
        <div className="p-col-12 p-md-6 p-lg-3 p-p-0">
          <InputNumber
            className="input-bg-white"
            readOnly
            placeholder="Numérico ..."
          />
        </div>
      )}
      {type === 'DATA' && (
        <div className="p-col-12 p-md-6 p-lg-3 p-p-0">
          <CalendarInput
            disabled
            showIcon
            value={undefined}
            className="input-bg-white"
            placeholder="dd/mm/aaaa"
          />
        </div>
      )}
      {type === 'ESCOLHA' && (
        <div className="p-col-12 p-md-6 p-lg-3 p-py-0 p-d-flex p-flex-column p-gap-1">
          {pergunta.opcoes?.map((item, i) => (
            <RadioButton
              key={i}
              onChange={() => {}}
              panel={false}
              label={item.descricao}
              className="pointer-events-none p-col-12 p-px-0"
            />
          ))}
        </div>
      )}
      {type === 'MULTIPLA_ESCOLHA' && (
        <div className="p-col-12 p-md-6 p-lg-3 p-py-0 p-d-flex p-flex-column p-gap-1">
          {pergunta.opcoes?.map((item, i) => (
            <Checkbox
              key={i}
              onChange={() => {}}
              label={item.descricao}
              className="pointer-events-none p-col-12 p-px-0"
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Actions = ({
  className,
  item,
  perguntasLength,
}: {
  className: string;
  item: IFormularioDinamicoPergunta;
  perguntasLength: number;
}) => {
  const { windowInnerWidth } = useSize();

  const { state } = useLocation();
  const viewOnly = state?.to !== 'edit';

  const menu = useRef<TieredMenu>(null);
  const dialogCopiar = useRef<OverlayPanel>(null);
  const dialogOrdenar = useRef<OverlayPanel>(null);
  const deleteDialog = useDisclosure({ opened: false });
  const editDialog = useDisclosure({
    opened: false,
  }) as DisclosureType<DialogNovaPerguntaStateProps>;
  const dispatch = useDispatch();

  const handleRefetchFormulario = () => {
    dispatch(setInvalidateQuery({ invalidateFormularioById: true }));
  };

  const handleEdit = () => editDialog.open();

  const options: MenuItem[] = [
    {
      label: 'Editar',
      command: () => handleEdit(),
      icon: 'fas fa-pencil-alt',
    },
    {
      label: 'Vincular',
      command: () =>
        editDialog.open({
          state: {
            tab: 1,
          },
        }),
      icon: 'fas fa-link',
    },
    {
      label: 'Copiar',
      command: e => [
        dialogCopiar.current?.toggle(e.originalEvent),
        menu.current?.toggle(e.originalEvent),
      ],
      icon: 'fas fa-clone',
    },
    {
      label: 'Ordenar',
      command: e => [
        dialogOrdenar.current?.toggle(e.originalEvent),
        menu.current?.toggle(e.originalEvent),
      ],
      icon: 'fas fa-arrow-down-1-9',
    },
    {
      label: 'Apagar',
      className: 'error',
      command: e => [
        deleteDialog.open(),
        menu.current?.toggle(e.originalEvent),
      ],
      icon: 'fas fa-trash',
    },
  ];

  const isMobile = useMemo(() => windowInnerWidth < 670, [windowInnerWidth]);

  return (
    <div className={className}>
      <Divider
        layout="vertical"
        className="p-m-0"
        style={{
          height: '16px',
        }}
      />

      {!isMobile ? (
        <>
          <Button
            icon="fas fa-pencil-alt"
            onClick={handleEdit}
            btnType="gray"
            disabled={viewOnly}
          />
          <Button
            icon="fas fa-link"
            btnType="gray"
            onClick={() =>
              editDialog.open({
                state: {
                  tab: 1,
                },
              })
            }
            disabled={viewOnly}
          />
          <Button
            icon="fas fa-clone"
            onClick={e => [dialogCopiar.current?.toggle(e)]}
            btnType="gray"
            disabled={viewOnly}
          />
          <Button
            icon="fas fa-arrow-down-1-9"
            btnType="gray"
            onClick={e => [dialogOrdenar.current?.toggle(e)]}
            disabled={viewOnly}
          />
          <Button
            icon="fas fa-trash"
            btnType="gray"
            onClick={() => [deleteDialog.open()]}
            disabled={viewOnly}
          />
        </>
      ) : (
        <Button
          icon="fas fa-ellipsis"
          btnType="gray"
          onClick={e => menu.current?.toggle(e)}
          disabled={viewOnly}
        />
      )}

      <TieredMenu model={options} ref={menu} popup />

      <OverlayPanel ref={dialogCopiar}>
        <DialogCopyOrderQuetion
          idForm={item.idFormularioDinamico}
          idQuestion={item.id}
          item={item.perguntaFormularioDinamico}
          questionsLength={perguntasLength}
          type="copy"
          onSuccess={() => {
            handleRefetchFormulario();
            dialogCopiar.current?.hide();
          }}
        />
      </OverlayPanel>
      <OverlayPanel ref={dialogOrdenar}>
        <DialogCopyOrderQuetion
          idForm={item.idFormularioDinamico}
          idQuestion={item.id}
          item={item.perguntaFormularioDinamico}
          questionsLength={perguntasLength}
          type="order"
          onSuccess={() => {
            handleRefetchFormulario();
            dialogOrdenar.current?.hide();
          }}
        />
      </OverlayPanel>

      {editDialog.isOpen && (
        <DialogNovaPergunta
          {...editDialog}
          handleReload={handleRefetchFormulario}
          state={{
            ...editDialog.state,
            item: {
              ...item.perguntaFormularioDinamico,
              idFormularioDinamico: item.idFormularioDinamico,
              idFormularioPerguntaVinculada: item.idFormularioPerguntaVinculada,
              idFormularioDinamicoPergunta: item.id,
              idPerguntaFormularioDinamico: item.idPerguntaFormularioDinamico,
              ...(item.idPerguntaVinculada && {
                idPerguntaVinculada: item.idPerguntaVinculada,
                idOpcaoPerguntaVinculada: item.idOpcaoPerguntaVinculada!,
              }),
            },
          }}
        />
      )}

      {deleteDialog.isOpen && (
        <DialogDeletarPergunta
          {...deleteDialog}
          handleRefetch={() => handleRefetchFormulario()}
          from="FORMULARIO"
          state={{
            item: item.perguntaFormularioDinamico,
            idForm: item.idFormularioDinamico,
            idFormularioDinamicoPergunta: item.id,
            description:
              'Ao clicar em “sim, apagar" você estará removendo a seguinte pergunta do formulário. ',
          }}
        />
      )}
    </div>
  );
};
