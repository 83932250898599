import { useAGA } from 'src/core/hooks/AGA/useAGA';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import { StepItem, Steps } from 'src/components/Steps/Steps';

import { DialogOrientacoes } from '../DialogOrientacoes/DialogOrientacoes';

const StepList = () => {
  const {
    currentStep,
    setCurrentStep,
    setTabAtiva,
    isEdit,
    scrollToTopAGA,
    listaFormularios,
  } = useAGA();
  const { atendimentoStatus } = useAtendimento();

  const confirmDialogDisclosure = useDisclosure({ opened: false });
  const orientacaoDialog = useDisclosure({ opened: false });

  const handleTabChange = (value: number) => {
    if (value === currentStep) return;
    if (atendimentoStatus !== 'ATENDENDO' || isEdit === null) return;

    confirmDialogDisclosure.open({
      state: {
        ...confirmDialogDisclosure,
        target: value,
      },
    });
    return;
  };

  const handleOpenOrientacaoDialog = (mnemonico: string) => {
    return orientacaoDialog.open({
      state: {
        text: listaFormularios.find(
          (lista: any) => lista.mnemonico === mnemonico,
        )?.textoAjuda,
      },
    });
  };

  const items: StepItem[] = [
    {
      label: 'IVCF 20 - Triagem',
    },
    {
      label: 'Atividades de Vida Diária',
      icon: true,
      onClickIcon: () =>
        handleOpenOrientacaoDialog('ATIVIDADES DE VIDA DIARIA'),
    },
    {
      label: 'Cognição e Humor',
    },
    {
      label: 'Mobilidade',
      icon: true,
      onClickIcon: () => handleOpenOrientacaoDialog('MOBILIDADE'),
    },
    {
      label: 'Comunicação',
      icon: true,
      onClickIcon: () => handleOpenOrientacaoDialog('COMUNICACAO'),
    },
    {
      label: 'Avaliação Geral',
      icon: true,
      onClickIcon: () => handleOpenOrientacaoDialog('AVALIACAO GERAL'),
    },
    {
      label: 'Risco Sócio Ambiental',
    },
  ];

  return (
    <div id="stepList" className="p-d-flex p-ai-center">
      <Steps items={items} onChange={handleTabChange} stepValue={currentStep} />

      {orientacaoDialog.isOpen && <DialogOrientacoes {...orientacaoDialog} />}

      {confirmDialogDisclosure.isOpen && (
        <ConfirmDialog
          header="Confirmação"
          onConfirm={() => {
            setTabAtiva(0);
            scrollToTopAGA();
            setCurrentStep(confirmDialogDisclosure.state.target);
            confirmDialogDisclosure.close();
          }}
          text={
            'Existem dados que não foram salvos. Deseja deixar a aba mesmo assim?'
          }
          onHide={() => confirmDialogDisclosure.close()}
          visible={confirmDialogDisclosure.isOpen}
        />
      )}
    </div>
  );
};

export default StepList;
