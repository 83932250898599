import React, { useEffect, useState } from 'react';

import { DiagnosticoFuncionalAPI } from 'src/APIs/ProntuarioAPI/AGAAPI/DiagnosticoFuncionalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { mergeMultipleObjects, sumPontuacoesByIndex } from './utils';
import { cleanObject } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';

import EscalaFragilidade from './EscalaFragibilidad/EscalaFragilidade';
import AtividadeVidaDiaria from './FuncoesCondicoes/AtividadeVidaDiaria';
import AvaliacaoGeral from './FuncoesCondicoes/AvaliacaoGeral';
import CognicaoHumor from './FuncoesCondicoes/CognicaoHumor';
import Comunicacao from './FuncoesCondicoes/Comunicacao';
import Mobilidade from './FuncoesCondicoes/Mobilidade';
import RiscoSocioAmbiental from './FuncoesCondicoes/RiscoSocioAmbiental';

import './DiagnosticoFuncionalGlobal.scss';

export const bgColors = {
  0: 'chip-success-light',
  1: 'chip-yellow',
  2: 'chip-feedback-warning',
};

const DiagnosticoFuncionalGlobal = ({ close, state }: DisclosureType) => {
  const { paciente, idAtendimento } = useAtendimento();
  const [diagnostico, setDiagnostico] = useState<IDiagnosticoFuncional>();
  const [historico, setHistorico] = useState<IHistoricoDiagnosticoFuncional>();
  const [diagnosticoCompleto, setDiagnosticoCompleto] = useState<any>();
  const [somaPontos, setSomaPontos] = useState<number[]>([]);
  const [isMaximized, setIsMaximized] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isTablet } = useSize();
  const dialogClassName = isMaximized ? 'p-dialog-maximized bg-96' : 'bg-96';

  useEffect(() => {
    const getDiagnostico = async () => {
      try {
        const response = await DiagnosticoFuncionalAPI.getDiagnosticoFuncional(
          idAtendimento,
        );
        if (response) {
          setDiagnostico(cleanObject(response));
        }
      } catch (error) {
        console.error(error);
        close();
      }
    };
    const getHistorico = async () => {
      try {
        const response =
          await DiagnosticoFuncionalAPI.getHistoricoDiagnosticoFuncional(
            paciente.id,
            idAtendimento,
          );
        if (response) {
          setHistorico(cleanObject(response));
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    (async () => {
      setLoading(true);
      await getDiagnostico();
      await getHistorico();
      setLoading(false);
    })();
  }, [close, idAtendimento, paciente.id]);

  //Este useEffect e por que mudou a estrutura que tinhamos conversado no meio do caminho (sexta a tarde).
  useEffect(() => {
    if (!diagnostico || !historico) return;

    const diagnosticosHistoricos = Object.values(cleanObject(historico));

    const diagnosticosToMerge = [diagnostico, ...diagnosticosHistoricos];

    const mergedResult = mergeMultipleObjects(diagnosticosToMerge);

    setDiagnosticoCompleto(mergedResult);
  }, [diagnostico, historico]);

  useEffect(() => {
    if (!diagnosticoCompleto) return setDiagnosticoCompleto(diagnostico);

    const somas = sumPontuacoesByIndex(diagnosticoCompleto);
    setSomaPontos(somas);
  }, [diagnostico, diagnosticoCompleto]);

  return (
    <Dialog
      id="dialog-diagnostico-funcional-global"
      className={dialogClassName}
      header={() => (
        <div className="p-d-flex p-ai-center p-jc-between">
          <div className="p-d-flex p-gap-2 p-ai-center">
            <Button
              label="voltar"
              icon="pi pi-arrow-left"
              iconPos="left"
              btnType="green-link"
              onClick={() => close()}
            />
            <SimpleText fontSize="md">
              Diagnóstico funcional global - AGA
            </SimpleText>
          </div>
          {!isTablet && (
            <Button
              icon="fas fa-maximize"
              btnType="green-link"
              onClick={() => setIsMaximized(!isMaximized)}
            />
          )}
        </div>
      )}
      footer={() => (
        <Button
          label="Fechar"
          onClick={() => close()}
          btnType="outline"
          stretch
        />
      )}
      visible={true}
      onHide={() => close()}
      style={{ width: '90vw', height: '90vh' }}
      maximizedTabletSize
    >
      {!diagnosticoCompleto || loading ? (
        <Skeleton loading={!diagnostico} height="65vh" />
      ) : (
        <div className="diagnostico-funcional-content bg-96">
          <div className=" p-p-2">
            <div className="p-grid diagnostico p-p-1">
              <SimpleText fontSize="sm" className="p-col-12">
                Diagnóstico
              </SimpleText>

              <TableHeader />

              <AtividadeVidaDiaria diagnostico={diagnosticoCompleto} />

              <CognicaoHumor diagnostico={diagnosticoCompleto} />

              <Mobilidade diagnostico={diagnosticoCompleto} />

              <Comunicacao diagnostico={diagnosticoCompleto} />

              <AvaliacaoGeral diagnostico={diagnosticoCompleto} />

              <RiscoSocioAmbiental diagnostico={diagnosticoCompleto} />

              <div className="p-d-flex border-dashed-x border-dashed-bottom p-py-1">
                <div className="w-1128px p-d-flex p-jc-end p-ai-center">
                  <SimpleText fontSize="xxs" className="p-p-1">
                    Total de pontos (Máximo de 40)
                  </SimpleText>
                </div>
                {somaPontos.length > 0 ? (
                  somaPontos?.map((ponto, index) => (
                    <div
                      key={index}
                      className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 border-dashed-left`}
                    >
                      <SimpleText fontSize="xxs" className="p-p-1">
                        {ponto}
                      </SimpleText>
                    </div>
                  ))
                ) : (
                  <div
                    className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 border-dashed-left`}
                  >
                    <SimpleText fontSize="xxs" className="p-p-1">
                      {diagnostico?.total ? diagnostico?.total : ''}
                    </SimpleText>
                  </div>
                )}
              </div>
            </div>
            <EscalaFragilidade indice={diagnostico?.escalaFragilidade || 0} />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default DiagnosticoFuncionalGlobal;

const TableHeader = () => (
  <div className=" p-d-flex border-dashed p-ai-center p-w-100">
    <div className="w-420px p-d-flex p-jc-center">
      <SimpleText fontSize="xxs" className="p-px-1 p-py-2">
        FUNÇÕES / CONDIÇÕES
      </SimpleText>
    </div>
    <div className="w-756px border-dashed-x">
      <div className="p-d-flex p-ai-center p-jc-center">
        <SimpleText fontSize="xxs" className=" p-px-1 p-py-2">
          COMPROMETIMENTO FUNCIONAL
        </SimpleText>
      </div>
      <div className="w-756px p-d-flex border-dashed-top">
        <div className="w-47px border-dashed-right p-d-flex p-ai-center p-jc-center">
          <SimpleText fontSize="xxs" className="p-px-1 p-py-2">
            Não
          </SimpleText>
        </div>
        <div className="w-709px p-d-flex p-ai-center p-jc-center ">
          <SimpleText fontSize="xxs" className="p-px-1 p-py-2">
            Sim
          </SimpleText>
        </div>
      </div>
    </div>
    <div className="p-flex-1 p-d-flex p-jc-center p-w-100 ">
      <SimpleText fontSize="xxs" className="p-px-1 p-py-2">
        Histórico
      </SimpleText>
    </div>
  </div>
);
