export const localOptions = [
  { label: 'Consultório', value: 'CONSULTORIO' },
  { label: 'Hospital', value: 'HOSPITAL' },
];

export const situacaoLocalOptions = [
  { label: 'Internado', value: 'I' },
  { label: 'Ambulatório', value: 'A' },
];

export const escalaTempoOptions = [
  { label: 'Dia', value: 'D' },
  { label: 'Mês', value: 'M' },
  { label: 'Ano', value: 'A' },
];

export const tipoSolicitacaoOptions = [
  { label: 'Eletiva', value: 'ELETIVA' },
  { label: 'Urgência e Emergência', value: 'URGENCIA_EMERGENCIA' },
];

export const filtroTiposBuscaRapidaOptions = [
  {
    label: 'Favoritos',
    value: 'F',
  },
  {
    label: 'Meus Grupos',
    value: 'G',
  },
  {
    label: 'Pacotes',
    value: 'P',
  },
];

export const questionarioExameSimOuNaoOptions = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

export const tagsHistoricoOptions = [
  {
    label: 'Lembrete ativo',
    value: 'LEMBRETE_ATIVO',
  },
  {
    label: 'Solicitados no último atendimento',
    value: 'SOLICITADO_ULTIMO_ATENDIMENTO',
  },
  {
    label: 'Exames com laudo',
    value: 'COM_LAUDO',
  },
];

export const visualizacaoHistoricoOptions = [
  {
    label: 'Meus atendimentos',
    value: 'MEUS_ATENDIMENTOS',
  },
  {
    label: 'Todos',
    value: 'TODOS',
  },
];

export const ordenacaoHistoricoOptions = [
  {
    label: 'Data da solicitação',
    value: 'dataSolicitacao',
  },
  {
    label: 'Data do resultado',
    value: 'dataResultado',
  },
];

export const statusHistoricoOptions = [
  {
    label: 'Agendado',
    value: 'AGENDADO',
  },
  {
    label: 'Cancelado',
    value: 'CANCELADO',
  },
  {
    label: 'Complementado',
    value: 'COMPLEMENTADO',
  },
  {
    label: 'Completo',
    value: 'COMPLETO',
  },
  {
    label: 'Em pendência',
    value: 'EM_PENDENCIA',
  },
  {
    label: 'Em realização',
    value: 'EM_REALIZACAO',
  },
  {
    label: 'Realizado com laudo',
    value: 'REALIZADO_COM_LAUDO',
  },
  {
    label: 'Realizado sem laudo',
    value: 'REALIZADO_SEM_LAUDO',
  },
  {
    label: 'Solicitado',
    value: 'SOLICITADO',
  },
  {
    label: 'Suspenso',
    value: 'SUSPENSO',
  },
  {
    label: 'Verificado',
    value: 'VERIFICADO',
  },
];

export const severidadeOptions = [
  {
    label: 'Leve',
    value: 'LEVE',
  },
  {
    label: 'Moderada',
    value: 'MODERADA',
  },
  {
    label: 'Severa',
    value: 'SEVERA',
  },
];
