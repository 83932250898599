import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm, useFieldArray, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';

import AgendamentoPedidoCuidado from './AgendamentoPedidoCuidado';

import './ModalAddPedidoCuidado.scss';

interface ModalAddPedidoCuidadoProps extends DisclosureType {
  isOpen: boolean;
  idAtendimento: number;
  idProfissionalSaude: number;
  idPaciente: number | null;
  editPedidoCuidado?: any;
  getPedidosCuidado: () => void;
  setEditPedidoCuidado: (value: any) => void;
}

function ModalAddPedidoCuidado({
  isOpen,
  close,
  idAtendimento,
  idProfissionalSaude,
  idPaciente,
  editPedidoCuidado,
  getPedidosCuidado,
  setEditPedidoCuidado,
}: ModalAddPedidoCuidadoProps) {
  const [listaTiposPedidosCuidado, setListaTiposPedidosCuidado] = useState<
    any[]
  >([]);
  const [listaProfissionaisSaude, setListaProfissionaisSaude] = useState<any[]>(
    [],
  );
  const optionsTiposCuidado: any[] = [
    { label: 'Cuidado próximo atendimento', value: 'PROXIMO_ATENDIMENTO' },
    { label: 'Cuidado programado', value: 'PROGRAMADO' },
  ];

  const { consultorios } = useAppSelector((state: RootState) => state);

  const validationSchema = Yup.object().shape({
    observacaoSolicitante: Yup.string()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    tipoCuidadoProgramado: Yup.string()
      .nullable()
      .when('tipoCuidado', {
        is: 'PROGRAMADO',
        then: Yup.string()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
      }),
    profissionalSaude: Yup.string()
      .nullable()
      .when('tipoCuidado', {
        is: 'PROGRAMADO',
        then: Yup.string()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
      }),
    agendamentos: Yup.array().when('tipoCuidado', {
      is: 'PROGRAMADO',
      then: Yup.array()
        .min(1, 'Deve ter pelo menos um agendamento')
        .of(
          Yup.object({
            dataProgramada: Yup.date()
              .nullable()
              .required('Campo obrigatório')
              .typeError('Campo obrigatório'),
            horarioProgramado: Yup.date()
              .nullable()
              .required('Campo obrigatório')
              .typeError('Campo obrigatório'),
          }),
        ),
    }),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useFormMethods;

  const defaultObservacaoSolicitante =
    JSON.parse(localStorage.getItem('definicoes') || '{}')?.pedidoCuidado
      ?.pedidoCuidado || '';

  const {
    fields: agendamentosFields,
    append: appendAgendamentosFields,
    remove: removeAgendamentosFields,
  } = useFieldArray({
    control,
    name: 'agendamentos',
  });

  const handleRemove = (idx: number) => {
    removeAgendamentosFields(idx);
    reset(getValues());
  };

  const watchProfissionalSaude = watch('profissionalSaude');
  const watchTipoCuidadoProgramado = watch('tipoCuidadoProgramado');
  const watchTipoCuidado = watch('tipoCuidado');
  const watchObservacaoSolicitante = watch('observacaoSolicitante');

  useEffect(() => {
    if (watchTipoCuidado && watchObservacaoSolicitante === undefined) {
      setValue('observacaoSolicitante', defaultObservacaoSolicitante);
    }
  }, [
    defaultObservacaoSolicitante,
    setValue,
    watchTipoCuidado,
    watchObservacaoSolicitante,
  ]);

  useEffect(() => {
    if (editPedidoCuidado !== null) {
      reset({
        agendamentos: editPedidoCuidado?.atendimentosAgendados.map(
          (agendamento: any) => {
            return {
              dataProgramada: dayjs(agendamento?.dataAgendamento).toDate(),
              horarioProgramado: dayjs(agendamento?.dataAgendamento).toDate(),
              encaixe: agendamento?.encaixe,
              status: agendamento?.status,
            };
          },
        ),
      });
    } else {
      reset({
        agendamentos: [
          {
            dataProgramada: new Date(),
            horarioProgramado: null,
            encaixe: false,
          },
        ],
      });
    }
  }, [editPedidoCuidado, reset]);

  useEffect(() => {
    if (editPedidoCuidado !== null) {
      setValue(
        'tipoCuidado',
        editPedidoCuidado?.programado ? 'PROGRAMADO' : 'PROXIMO_ATENDIMENTO',
      );
      setValue(
        'observacaoSolicitante',
        editPedidoCuidado?.observacaoSolicitante,
      );
      setValue('tipoCuidadoProgramado', editPedidoCuidado?.idTipoCuidado);
      setValue('profissionalSaude', editPedidoCuidado?.idProfSaudeExec);
      setValue(
        'observacaoSolicitante',
        editPedidoCuidado?.observacaoSolicitante || '',
      );
    }
  }, [editPedidoCuidado, setValue]);

  useEffect(() => {
    const getTiposPedidosCuidado = async () => {
      const response = await PedidosCuidadoAPI.getTiposPedidoCuidado({
        ativo: true,
        idConsultorio: consultorios?.ativo?.id,
      });
      setListaTiposPedidosCuidado(
        Array.isArray(response?.data) ? response?.data : [response?.data],
      );
    };

    getTiposPedidosCuidado();
  }, [consultorios?.ativo?.id]);

  useEffectSkipFirst(() => {
    const getListaProfissionaisSaude = async () => {
      const response = await PedidosCuidadoAPI.getProfissionaisSaude(
        Number(watchTipoCuidadoProgramado),
      );
      setListaProfissionaisSaude(
        Array.isArray(response?.data) ? response?.data : [response?.data],
      );
    };

    if (watchTipoCuidadoProgramado) {
      getListaProfissionaisSaude();
    }
  }, [watchTipoCuidadoProgramado]);

  const saveAction = useCallback(
    (payload: any) => {
      if (editPedidoCuidado) {
        return PedidosCuidadoAPI.editarPedidoCuidado(
          idAtendimento,
          editPedidoCuidado.id,
          payload,
        );
      }
      return PedidosCuidadoAPI.adicionarPedidoCuidado(payload);
    },
    [editPedidoCuidado, idAtendimento],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const { tipoCuidado, ...rest } = data;

      const payload = {
        ...(watchTipoCuidado === 'PROGRAMADO'
          ? {
              programado: true,
              idTipoCuidado: getValues('tipoCuidadoProgramado'),
            }
          : { programado: false, idTipoCuidado: null }),
        idProfSaudeSolicit: Number(idProfissionalSaude),
        idProfSaudeExec: getValues('profissionalSaude'),
        dataInclusao: dayjs(new Date()).toDate(),
        ...rest,
        idAtendimento: Number(idAtendimento),
        idAtendimentoSolicita: Number(idAtendimento),
        dataOcorrencia: dayjs(new Date()).toDate(),
        agendamentos: getValues('agendamentos').map((agendamento: any) => ({
          dataAgendamento: dayjs(
            dayjs(agendamento.dataProgramada).format('YYYY-MM-DD') +
              'T' +
              dayjs(agendamento.horarioProgramado).format('HH:mm:ss'),
          ).toDate(),
          encaixe: agendamento?.encaixe,
          ...(editPedidoCuidado && {
            id: agendamento?.id,
          }),
        })),
        idPaciente: Number(idPaciente),
        lembrete: false,
      };

      const response = await saveAction(payload);

      if (response?.status === 200 || response?.status === 201) {
        getPedidosCuidado();
        setEditPedidoCuidado(null);
        reset();
        close();
      }
    },
    [
      close,
      editPedidoCuidado,
      getPedidosCuidado,
      getValues,
      idAtendimento,
      idPaciente,
      idProfissionalSaude,
      reset,
      saveAction,
      setEditPedidoCuidado,
      watchTipoCuidado,
    ],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={() => {
          close();
        }}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  return (
    <>
      <Dialog
        id="modal-add-pedido-cuidado"
        header="Pedido de cuidado"
        visible={isOpen}
        onHide={() => {
          close();
        }}
        footer={renderFooter}
      >
        <FormProvider {...useFormMethods}>
          <form className="p-d-flex p-flex-column" id="form-pedido-cuidado">
            <div className="tipo-de-cuidado">
              <SelectButtonControlled
                control={control}
                label="Tipo de cuidado"
                options={optionsTiposCuidado}
                name="tipoCuidado"
              />
            </div>
            {watchTipoCuidado === 'PROXIMO_ATENDIMENTO' && (
              <div className="p-my-3">
                <TextareaInputControlled
                  control={control}
                  label="Descrição"
                  name="observacaoSolicitante"
                  rows={4}
                  maxLength={2500}
                  maxLengthSpanDesc={true}
                  errorMsg={errors.observacaoSolicitante?.message}
                />
              </div>
            )}
            {watchTipoCuidado === 'PROGRAMADO' && (
              <>
                <div className="p-d-flex w-100 p-my-2">
                  <div className="w-50">
                    <DropdownControlled
                      control={control}
                      label="Tipo de cuidado"
                      options={listaTiposPedidosCuidado}
                      optionLabel="descricao"
                      optionValue="id"
                      name="tipoCuidadoProgramado"
                      errorMsg={errors.tipoCuidadoProgramado?.message}
                    />
                  </div>
                  <Separator spaceOnly />
                  <div className="w-50">
                    <DropdownControlled
                      control={control}
                      label="Profissional responsável"
                      options={listaProfissionaisSaude}
                      optionLabel="nome"
                      optionValue="id"
                      name="profissionalSaude"
                      errorMsg={errors.profissionalSaude?.message}
                    />
                  </div>
                </div>
                {watchProfissionalSaude && (
                  <div className="data-hora p-px-2 p-py-1 p-mt-1">
                    {agendamentosFields.map(
                      (agendamento: any, index: number) => (
                        <AgendamentoPedidoCuidado
                          key={index}
                          agendamento={agendamento}
                          index={index}
                          onRemove={handleRemove}
                          isEditing={!!editPedidoCuidado}
                        />
                      ),
                    )}
                    <div className="p-mt-3 p-ml-3">
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.PRIMARY}
                        medium
                        className="add-dias"
                        onClick={() => {
                          appendAgendamentosFields({
                            dataProgramada: null,
                            horarioProgramado: null,
                            encaixe: false,
                          });
                        }}
                      >
                        Adicionar mais dias
                      </SimpleText>
                    </div>
                  </div>
                )}

                {errors.agendamentos?.message && (
                  <div className="p-col-12">
                    <SimpleText fontColor={FONT_COLOR.DANGER}>
                      {errors.agendamentos?.message}
                    </SimpleText>
                  </div>
                )}

                <div className="p-my-3">
                  <TextareaInputControlled
                    control={control}
                    label="Execução"
                    name="observacaoSolicitante"
                    rows={4}
                    maxLength={2500}
                    maxLengthSpanDesc={true}
                    errorMsg={errors.observacaoSolicitante?.message}
                  />
                </div>
              </>
            )}
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
}

export default ModalAddPedidoCuidado;
