import { useCallback, useState, useEffect } from 'react';

import { Skeleton } from 'primereact/skeleton';

import { Formik } from 'formik';
import SalaAPI from 'src/APIs/AdminAPI/ConsultorioAPI/SalaAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';

import DeleteRoom from './DeleteRoom/DeleteRoom';
import EditRoom from './EditRoom/EditRoom';
import RoomsItems from './RoomsItems/RoomsItems';

import './FormRooms.scss';
interface FormRoomsProps {
  id?: string;
}

const FormRooms = ({ id }: FormRoomsProps) => {
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [roomSelected, setRoomSelected] = useState({} as any);
  const [editModal, setEditModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      async function loadSalas() {
        const response = await SalaAPI.loadSalasByConsultorioId(id!);

        setList(response);
        setLoading(false);
      }

      loadSalas();
    }
  }, [id]);

  const handleAddRoom = useCallback(
    async (data: any) => {
      setLoadingButton(true);

      try {
        const room = {
          nome: data.name,
          ativo: true,
        };

        const response = await SalaAPI.sendSala(id!, room as any);

        if (response.id) {
          setList([...list, response]);
          setLoadingButton(false);
        }
      } catch (error) {
        setLoadingButton(false);
      }
    },
    [id, list],
  );

  const handleEditRoom = useCallback(() => {
    const salaAtualizada = {
      ...roomSelected,
    };

    try {
      SalaAPI.updateSala(id!, salaAtualizada);

      SalaAPI.loadSalasByConsultorioId(id!);

      const salaExistente = list.findIndex(
        sala => sala.id === salaAtualizada.id,
      );

      list.splice(salaExistente, 1, salaAtualizada);

      setEditModal(false);
    } catch (error) {}
  }, [roomSelected, id, list]);

  const handleRemoveRoom = useCallback(() => {
    try {
      const roomsExists = list.filter((r: Sala) => r.id !== roomSelected.id);
      setList(roomsExists);

      SalaAPI.removeSalaById(id!, roomSelected.id);

      setRemoveModal(false);
    } catch (error) {}
  }, [list, id, roomSelected]);

  const handleIsEditing = useCallback((room: Sala) => {
    setRoomSelected(room);
    setEditModal(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setRoomSelected({});
    setEditModal(false);
  }, []);

  const handleIsRemoving = useCallback((room: Sala) => {
    setRoomSelected(room);
    setRemoveModal(true);
  }, []);

  const handleRemoveCancel = useCallback(() => {
    setRoomSelected({});
    setRemoveModal(false);
  }, []);

  return (
    <div className="FormRooms p-grid p-col-12 p-md-9 p-lg-7 p-xl-5">
      <SimpleText className="p-col-12" fontSize={FONT_SIZE.XS}>
        Preencha o formulário para adicionar as salas do consultório.
      </SimpleText>

      <div className="p-col-12 form-container">
        {loading ? (
          <>
            <div style={{ marginBottom: 8 }}>
              <Skeleton height="56px" borderRadius="8px" />
            </div>
          </>
        ) : list && list.length > 0 ? (
          list.map((room: SalaDTO, index: number) => (
            <RoomsItems
              key={index}
              index={index}
              text={room.nome}
              checked={room.ativo}
              setChecked={() => setRoomSelected(room)}
              onEdit={() => handleIsEditing(room)}
              onDelete={() => handleIsRemoving(room)}
              roomSelected={room}
              roomList={list}
            />
          ))
        ) : (
          <SimpleText className={'text'} fontSize={FONT_SIZE.XS}>
            Cadastre uma sala preenchendo o campo abaixo
          </SimpleText>
        )}

        <Divider />

        <SimpleText fontSize={FONT_SIZE.XS}>Adicione salas</SimpleText>

        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required('O campo Nome é obrigatório.'),
          })}
          initialValues={{ name: '' }}
          onSubmit={(values, { resetForm }) => {
            handleAddRoom(values);
            resetForm({ values: { name: '' } });
          }}
        >
          {({ values, handleChange, isValid, handleSubmit, errors }) => (
            <>
              <TextInput
                label="Nome da Sala"
                value={values.name}
                onChange={handleChange('name')}
                className={'p-mt-3'}
                errorMsg={errors.name}
              />

              <Button
                label="Adicionar"
                icon="fa fa-plus"
                btnType="tonal"
                onClick={() => handleSubmit()}
                disabled={!isValid}
                loading={loadingButton}
                stretch
              />
            </>
          )}
        </Formik>

        <EditRoom
          visible={editModal}
          onHide={handleEditCancel}
          handleEdit={handleEditRoom}
          initialValue={roomSelected}
          setRoomSelected={setRoomSelected}
        />

        <DeleteRoom
          visible={removeModal}
          onHide={handleRemoveCancel}
          handleRemove={handleRemoveRoom}
        />
      </div>
    </div>
  );
};

export default FormRooms;
