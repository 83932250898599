import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import PersonalizarProntuarioMedicoAPI from 'src/APIs/ProntuarioAPI/PersonalizarProntuarioMedicoAPI/PersonalizarProntuarioMedicoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Skeleton from 'src/components/Skeleton/Skeleton';

import RadioButton from '../RadioButton/RadioButton';



import './Modelos.scss';

const validationSchema = Yup.object().shape({
  nome: Yup.string().required('Informe o nome do modelo'),
  texto: Yup.string().required('Informe o texto do modelo'),
});

function Modelos() {
  const { agenda } = useAppSelector((state: RootState) => state);
  const {} = useAppSelector(state => state.query);

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const menuEllipsis = useRef<TieredMenu>(null);

  const [camposProntuario, setCamposProntuario] = useState<any>({});
  const [campoSelecionado, setCampoSelecionado] = useState<string | null>(null);
  const [modelos, setModelos] = useState<ModeloCampoProntuario[]>([]);
  const [modeloFormVisible, setModeloFormVisible] = useState<boolean>(false);
  const [modeloIdEdit, setModeloIdEdit] = useState<number | null>(null);
  const [modeloFormSubmitting, setModeloFormSubmitting] =
    useState<boolean>(false);
  const [excluirIdModelo, setExcluirIdModelo] = useState<number | null>(null);
  const [loadingCampos, setLoadingCampos] = useState<boolean>(false);
  const [loadingModelos, setLoadingModelos] = useState<boolean>(false);
  const [isNenhum, setIsNenhum] = useState<boolean>(false);

  const menuEllipsisItens = useMemo(
    () => [
      {
        label: 'Excluir',
        command: () => setExcluirIdModelo(modeloIdEdit),
      },
    ],
    [modeloIdEdit],
  );

  const loadCampos = useCallback(async () => {
    setLoadingCampos(true);

    const response =
      await PersonalizarProntuarioMedicoAPI.getCamposProntuario();

    if (response?.status === 200) setCamposProntuario(response.data);

    setLoadingCampos(false);
  }, []);

  useEffect(() => {
    loadCampos();
  }, [loadCampos]);

  const loadModelos = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      setLoadingModelos(true);
      setModelos([]);

      const response = await PersonalizarProntuarioMedicoAPI.getModelosByCampo(
        campoSelecionado as string,
      );

      setLoadingModelos(false);

      if (response && !('status' in response)) {
        setModelos(response);
        return resolve(response);
      }

      reject(response);
    });
  }, [campoSelecionado]);

  useEffect(() => {
    if (campoSelecionado) {
      loadModelos();
    } else {
      setModelos([]);
    }
  }, [campoSelecionado, loadModelos]);

  const definirModeloPadrao = async (idModelo: number) => {
    await PersonalizarProntuarioMedicoAPI.definirModeloPadrao(idModelo);
    setIsNenhum(false);
    loadModelos();
  };

  const onClearDefaultModel = useCallback(async () => {
    campoSelecionado &&
      (await PersonalizarProntuarioMedicoAPI.limparPadrao(campoSelecionado));

    setIsNenhum(true);

    loadModelos();
  }, [campoSelecionado, loadModelos]);

  useEffect(() => {
    const padrao = modelos.filter(modelo => modelo.padrao === true);
    padrao.length === 0 ? setIsNenhum(true) : setIsNenhum(false);
  }, [modelos]);

  const onCancelModelo = useCallback(() => {
    reset({
      nome: '',
      texto: '',
    });
    setModeloFormVisible(false);
    setModeloIdEdit(null);
  }, [reset]);

  const onExcluir = async () => {
    const response = await PersonalizarProntuarioMedicoAPI.removerModelo(
      excluirIdModelo as number,
    );

    if (response?.status === 204) {
      await loadModelos();
      setExcluirIdModelo(null);
      onCancelModelo();
      dispatch(setInvalidateQuery({ invalidateModelos: true }));
    }
  };

  const saveAction = useCallback(
    async (payload: any) => {
      try {
        const { addNewModelo, alterarModelo } = PersonalizarProntuarioMedicoAPI;

        if (modeloIdEdit) {
          await alterarModelo(modeloIdEdit, payload, {
            throwError: true,
          });
        } else {
          await addNewModelo(payload, {
            throwError: true,
          });
        }
      } catch {
         throw new Error()
      }
    },
    [modeloIdEdit],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setModeloFormSubmitting(true);

        const payload = {
          ...data,
          campo: campoSelecionado,
          idProfissionalSaude: agenda?.profissionalAtivo?.id,
        };
  
        await saveAction(payload);
        await loadModelos();

        onCancelModelo();
        dispatch(setInvalidateQuery({ invalidateModelos: true }));
      } catch {
      } finally {
        setModeloFormSubmitting(false);
      }

    },
    [agenda?.profissionalAtivo?.id, campoSelecionado, dispatch, loadModelos, onCancelModelo, saveAction],
  );

  const renderDefaultModeloContent = useCallback(
    () => (
      <div id="modelo-default-content">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          Clique em um dos botões ao lado, para editar um modelo existente ou
        </SimpleText>

        <Button
          className="p-mt-3"
          type="button"
          btnType="green-link"
          icon="fas fa-plus"
          label="Adicione um novo modelo"
          onClick={() => setModeloFormVisible(true)}
          disabled={!campoSelecionado}
        />
      </div>
    ),
    [campoSelecionado],
  );

  const renderModeloForm = useCallback(
    () => (
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInputControlled
          control={control}
          name="nome"
          label="Nome do modelo"
          errorMsg={errors.nome?.message}
        />

        <TextareaInputControlled
          control={control}
          name="texto"
          label="Texto do modelo"
          rows={14}
          maxLength={2000}
          errorMsg={errors.texto?.message}
        />

        <div className="p-d-flex p-jc-end p-mt-3 p-gap-1">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={onCancelModelo}
            loading={isSubmitting}
          />
          <Button
            type="submit"
            btnType="tonal"
            label="Salvar"
            loading={isSubmitting}
          />
          {modeloIdEdit && (
            <div className="p-ml-3">
              <Button
                type="button"
                icon="fas fa-ellipsis-h"
                btnType="gray"
                onClick={event => menuEllipsis?.current?.toggle(event)}
              />
              <TieredMenu ref={menuEllipsis} model={menuEllipsisItens} popup />
            </div>
          )}
        </div>
      </form>
    ),
    [
      handleSubmit,
      onSubmit,
      control,
      errors.nome?.message,
      errors.texto?.message,
      onCancelModelo,
      isSubmitting,
      modeloIdEdit,
      menuEllipsisItens,
    ],
  );

  const renderModeloContent = useCallback(() => {
    if (modeloFormVisible) return renderModeloForm();

    return renderDefaultModeloContent();
  }, [modeloFormVisible, renderDefaultModeloContent, renderModeloForm]);

  const handleEditModelo = (modelo: ModeloCampoProntuario) => {
    setValue('nome', modelo.nome);
    setValue('texto', modelo.texto);

    setModeloFormVisible(true);
    setModeloIdEdit(modelo.id);
  };

  const renderModelosItens = (modelo: ModeloCampoProntuario) => (
    <div className="p-d-flex p-jc-between p-my-1">
      <RadioButton
        label={modelo.nome}
        onClick={() => !modelo.padrao && definirModeloPadrao(modelo.id)}
        checked={modelo.padrao}
      />
      <Button
        btnType="gray"
        icon="fas fa-pencil-alt"
        onClick={() => handleEditModelo(modelo)}
      />
    </div>
  );

  const modeloPadrao = useMemo(
    () => modelos.find(modelo => modelo.padrao === true),
    [modelos],
  );

  const outrosModelos = useMemo(
    () => modelos.filter(modelo => modelo.padrao !== true),
    [modelos],
  );

  const camposOptions = useMemo(
    () =>
      Object.entries(camposProntuario).map(([value, label]) => ({
        label,
        value,
      })),
    [camposProntuario],
  );

  return (
    <>
      <div id="modelos-container" className="p-grid">
        <div className="p-col-12">
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            Gerencie modelos
          </SimpleText>
        </div>

        <div className="p-col-12">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Campos de texto podem ser utilizados com modelos para facilitar o
            preenchimento. Selecione o campo desejado na caixa de seleção
            abaixo, no lado esquerdo
          </SimpleText>
        </div>

        <div className="p-col-12 p-sm-4">
          <Skeleton loading={loadingCampos} height="50px" borderRadius="8px">
            <Dropdown
              label="Campo"
              options={camposOptions}
              value={campoSelecionado}
              onChange={e => setCampoSelecionado(e.value)}
            />
          </Skeleton>

          <SimpleText
            className="p-d-block p-my-3"
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Modelos
          </SimpleText>

          <div className="p-d-flex p-jc-between">
            <SimpleText
              className="p-d-block p-mb-3"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
              medium
            >
              Inicialização
            </SimpleText>

            {modeloPadrao && (
              <span
                className="clear-default-model-btn"
                onClick={onClearDefaultModel}
              >
                Limpar
              </span>
            )}
          </div>

          {modeloPadrao ? (
            renderModelosItens(modeloPadrao)
          ) : (
            <Skeleton loading={loadingModelos} height="30px" borderRadius="8px">
              <SimpleText
                className="p-d-block"
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_79}
                medium
              >
                Assinale algum modelo abaixo, para que o campo sempre inicie com
                este
              </SimpleText>
            </Skeleton>
          )}

          <SimpleText
            className="p-d-block p-my-3"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Outros
          </SimpleText>

          {loadingModelos ? (
            <GridListLoading />
          ) : (
            outrosModelos.map(renderModelosItens)
          )}
        </div>

        <div id="modelo-content" className="p-col">
          {renderModeloContent()}
        </div>
      </div>

      <ConfirmDialog
        visible={!!excluirIdModelo}
        header="Excluir Modelo"
        text="O modelo será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setExcluirIdModelo(null)}
        onConfirm={onExcluir}
      />
    </>
  );
}

export default Modelos;
