import { useNavigate } from 'react-router';

import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  pacote: any;
  onSwitchStatus: (idPacote: number) => void;
  onDelete: (idPacote: number) => void;
}

export const Row = (props: RowProps) => {
  const navigate = useNavigate();

  const { pacote, onSwitchStatus, onDelete } = props;

  const { isMobile } = useSize();

  const handleEdit = () => {
    navigate(`/config/config-tuss/pacote/${pacote.id}`);
  };

  const handleSwitchStatus = () => {
    onSwitchStatus(pacote.id);
  };

  const handleDelete = () => onDelete(pacote.id);

  const commonProps = {
    pacote,
    onSwitchStatus: handleSwitchStatus,
    onEdit: handleEdit,
    onDelete: handleDelete,
  };

  return isMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
