import { useCallback, useEffect, useState } from 'react';

import UsuarioAPI, {
  TIPO_USUARIO,
} from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  setProfissionaisDisponiveis,
  setProfissionalAtivo,
} from 'src/core/redux/slices/agenda/AgendaSlice';
import { RootState } from 'src/core/redux/store';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Tab from 'src/components/Tab/Tab';

import AgendamentosOnline from './AgendamentosOnline/AgendamentosOnline';
import './BloqueioPaciente.scss';
import TemplateEmail from './TemplateEmail/TemplateEmail';

const BloqueioPaciente = () => {
  const { user, consultorios } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const loadProfissionais = useCallback(async () => {
    try {
      if (!consultorios.ativo) {
        return;
      }
      const aux = await UsuarioAPI.loadProfissionaisByUserConsultorio(
        consultorios.ativo.id,
      );
      dispatch(setProfissionaisDisponiveis(aux));
    } catch (e) {
      console.log(e);
    }
  }, [consultorios.ativo, dispatch]);

  const loadMedico = useCallback(async () => {
    try {
      const aux = await UsuarioAPI.loadUsuarioById(user?.idUsuario || -1);
      if (aux) {
        dispatch(setProfissionalAtivo(aux));
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, user?.idUsuario]);

  useEffect(() => {
    if (user.tipoUsuario !== TIPO_USUARIO.PROFISSIONAL_SAUDE) {
      loadProfissionais();
    } else {
      loadMedico();
    }
  }, [user, loadProfissionais, loadMedico]);

  return (
    <div className={'BloqueioPaciente'}>
      <div className={'header'}>
        <SimpleText className="title">Outras configurações</SimpleText>
        <SimpleText>
          Consulte outras configurações pessoais do sistema
        </SimpleText>
      </div>
      <div className="content">
        <Tab
          fullHeight
          clean
          noPanel
          headerColor={false}
          initialTabIndex={activeTabIndex}
          onChange={(v: number) => {
            setActiveTabIndex(v);
          }}
          className="p-w-100"
          values={[
            {
              label: 'Prontuário',
              content: <span>Prontuário</span>,
            },
            {
              label: 'Agendamento online',
              content: <AgendamentosOnline />,
              checkPermission: 'ADM_BLOQUEIO_PACIENTES_CONSULTAR',
            },
            {
              label: 'Template e-mail',
              content: <TemplateEmail />,
              checkPermission: 'ADM_TEMPLATE_ANIVERSARIO_CONSULTAR',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default BloqueioPaciente;
