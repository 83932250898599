import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isTablet } = useSize();
  const { consultorios } = useAppSelector((state: RootState) => state);
  if (isTablet) return null;

  const isEquipeCuiadora =
    consultorios?.ativo?.tipoMonitoramentoCarteiraAps === 'EQUIPE';
  const label = `Profissional ${isEquipeCuiadora ? 'responsável' : ''}`;

  return (
    <>
      {isTablet === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <div className="p-col">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              {label}
            </SimpleText>
          </div>

          {isEquipeCuiadora && (
            <div className="p-col-3">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Equipe
              </SimpleText>
            </div>
          )}

          <div className="p-col-3">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Quantidade de pacientes
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Pontuação
            </SimpleText>
          </div>

          <div className="p-col-fixed" style={{ width: '100px' }}></div>
        </div>
      )}
    </>
  );
};
