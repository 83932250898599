import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { PanelHeaderTemplateOptions } from 'primereact/panel';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { SeveridadeButton } from './SeveridadeButton/SeveridadeButton';
import { StatusButton } from './StatusButton/StatusButton';

type VisualizarResultadosType = {
  idsSolicitacaoExame: number[];
  idsSolicitacaoExameExterno: number[];
};
interface HistoricoExamesPanelProcedimentoHeaderProps {
  options: PanelHeaderTemplateOptions;
  isSelected: boolean;
  isProcedimentoExterno: boolean;
  idProcedimento: number;
  procedimento: any;
  dataRepeticaoFormatted: string;
  children: JSX.Element;
  hasLaudo: boolean;
  disabled: boolean;
  isInactive: boolean;
  onOpenIncluirResultado: () => void;
  onSelectResultado: React.Dispatch<
    React.SetStateAction<VisualizarResultadosType>
  >;
}

export const HistoricoExamesPanelProcedimentoHeader = (
  props: HistoricoExamesPanelProcedimentoHeaderProps,
) => {
  const {
    options,
    isSelected,
    isProcedimentoExterno,
    idProcedimento,
    procedimento,
    dataRepeticaoFormatted,
    children,
    hasLaudo,
    disabled,
    isInactive,
    onOpenIncluirResultado,
    onSelectResultado,
  } = props;

  const laudoOverlayRef = useRef<OverlayPanel>(null);

  const toggleIcon = options.collapsed
    ? 'fas fa-angle-down'
    : 'fas fa-angle-up';

  const removeFromArray = (array: number[], id: number) =>
    array.filter(item => item !== id);
  const addToArray = (array: number[], id: number) => [...array, id];

  const updateIdsSolicitacaoExame = (prevState: VisualizarResultadosType) => {
    if (isSelected) {
      const updatedIdsSolicitacaoExame = removeFromArray(
        prevState.idsSolicitacaoExame,
        idProcedimento,
      );
      return { ...prevState, idsSolicitacaoExame: updatedIdsSolicitacaoExame };
    }

    const updatedIdsSolicitacaoExame = addToArray(
      prevState.idsSolicitacaoExame,
      idProcedimento,
    );
    return { ...prevState, idsSolicitacaoExame: updatedIdsSolicitacaoExame };
  };

  const updateIdsSolicitacaoExameExterno = (
    prevState: VisualizarResultadosType,
  ) => {
    if (isSelected) {
      const updatedIdsSolicitacaoExameExterno = removeFromArray(
        prevState.idsSolicitacaoExameExterno,
        idProcedimento,
      );
      return {
        ...prevState,
        idsSolicitacaoExameExterno: updatedIdsSolicitacaoExameExterno,
      };
    }

    const updatedIdsSolicitacaoExameExterno = addToArray(
      prevState.idsSolicitacaoExameExterno,
      idProcedimento,
    );
    return {
      ...prevState,
      idsSolicitacaoExameExterno: updatedIdsSolicitacaoExameExterno,
    };
  };

  const handleSelectionChange = (prevState: VisualizarResultadosType) => {
    if (isProcedimentoExterno) {
      return updateIdsSolicitacaoExameExterno(prevState);
    }

    return updateIdsSolicitacaoExame(prevState);
  };

  const handleSelectProcedimento = () => {
    onSelectResultado(prevState => handleSelectionChange(prevState));
  };

  return (
    <div className="p-grid p-align-center">
      <div className="p-col-fixed p-order-0">
        <Checkbox
          checked={isSelected}
          onChange={handleSelectProcedimento}
          disabled={!hasLaudo}
        />
      </div>

      <div className="p-order-1 p-col p-d-flex p-ai-center p-jc-between p-jc-lg-start">
        <div className="p-d-flex p-ai-center">
          {isProcedimentoExterno || isInactive ? (
            <SimpleText
              className={`descricao-btn ${isInactive ? 'line-through' : ''}`}
              fontColor={FONT_COLOR.PRIMARY}
              medium
            >
              {procedimento.descricaoProcedimento}
            </SimpleText>
          ) : (
            <Button
              className="descricao-btn"
              type="button"
              btnType="green-link"
              label={procedimento.descricaoProcedimento}
              icon="fas fa-file-medical"
              onClick={onOpenIncluirResultado}
              disabled={disabled}
            />
          )}

          <SimpleText
            className="p-mx-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            {procedimento?.codigoProcedimento}
          </SimpleText>
        </div>

        <div className="p-d-flex p-ai-center">
          <Button
            type="button"
            btnType="ghost"
            icon="fas fa-table-list"
            onMouseEnter={e => laudoOverlayRef.current?.show(e, null)}
            disabled={
              !procedimento?.resultadoExame?.laudoDigitado &&
              !procedimento?.resultadoExame?.resultadoDigitado
            }
          />

          <OverlayPanel
            className="historico-resultado-panel"
            ref={laudoOverlayRef}
          >
            <div className="p-grid">
              <SimpleText
                className="p-col-12"
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                Laudo Informado
              </SimpleText>

              <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento?.resultadoExame?.laudoDigitado || 'Não informado'}
              </SimpleText>

              <SimpleText
                className="p-col-12 p-mt-3"
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                Resultado Informado
              </SimpleText>
              <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento?.resultadoExame?.resultadoDigitado ||
                  'Não informado'}
              </SimpleText>
            </div>
          </OverlayPanel>

          <div className="p-d-flex p-flex-column p-ml-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Resultado
            </SimpleText>

            <SimpleText
              className="p-mt-1"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XXS}
            >
              {procedimento?.resultadoExame?.resultadoDigitado}
            </SimpleText>
          </div>

          <StatusButton
            idAtendimento={procedimento.idAtendimento}
            idProcedimento={idProcedimento}
            isProcedimentoExterno={isProcedimentoExterno}
            situacaoExame={procedimento.situacaoExame}
            disabled={disabled || isInactive}
          />
        </div>
      </div>

      <div className="historico-actions-col p-col-fixed p-order-0 p-order-lg-1 p-d-flex p-jc-end p-ai-center">
        <SeveridadeButton
          idAtendimento={procedimento.idAtendimento}
          idProcedimento={idProcedimento}
          isProcedimentoExterno={isProcedimentoExterno}
          severidadeAlteracao={procedimento.severidadeAlteracao}
          disabled={disabled || isInactive}
        />

        <Divider layout="vertical" />

        {!isProcedimentoExterno && (
          <>
            <Tooltip target={`.repeticao-${idProcedimento}`} />
            <i
              className={`fas fa-calendar repeticao-${idProcedimento}`}
              data-pr-tooltip={dataRepeticaoFormatted}
              data-pr-position="top"
            />
          </>
        )}

        {children}

        <Button
          type="button"
          btnType="ghost"
          icon={toggleIcon}
          onClick={options.onTogglerClick}
        />
      </div>
    </div>
  );
};
