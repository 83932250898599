/**
 * @deprecated The component should not be used
 */

import { useController, useFormContext } from 'react-hook-form';

import Checkbox, { CheckboxProps } from '../Checkbox/Checkbox';

export interface MultipleCheckboxControlledProps extends CheckboxProps {
  name: string;
  onChangeCustom?: (checked: any) => void;
  checkboxClassName?: string;
}

const MultipleCheckboxControlled = ({
  name,
  onChangeCustom,
  checkboxClassName = '',
  ...rest
}: MultipleCheckboxControlledProps) => {
  const { control } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <Checkbox
      {...rest}
      className={checkboxClassName}
      value={value}
      onChange={e => {
        onChange(e);
        onChangeCustom?.(e);
      }}
      /* onClick={() => [
            onChange(!value),
            onChangeCustom && onChangeCustom(!value),
          ]}*/
    />
  );
};

export default MultipleCheckboxControlled;
