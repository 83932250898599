import { useState, useEffect, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import Button from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

import Genero from '../../CamposDemograficos/Genero';
import Passaporte from '../../CamposDemograficos/Passaporte';
import RacaCor from '../../CamposDemograficos/RacaCor';
import RegistroGeral from '../../CamposDemograficos/RegistroGeral';
import ResponsavelLegal from '../../CamposDemograficos/ResponsavelLegal';
import { saveDadosPessoais } from '../../helpers';
import { formatData } from '../../helpers';
import { useManterPaciente } from '../../MaterPacienteContext';

import validationSchema from './validationSchema';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

const situacoesConjugais = [
  {
    id: 'DI',
    label: 'DIVORCIADO',
    value: 'DIVORCIADO',
  },

  {
    id: 'VI',
    label: 'VIUVO',
    value: 'VIUVO',
  },
  {
    id: 'SE',
    label: 'SEPARADO',
    value: 'SEPARADO',
  },
  {
    id: 'CA',
    label: 'CASADO',
    value: 'CASADO',
  },
  {
    id: 'SO',
    label: 'SOLTEIRO',
    value: 'SOLTEIRO',
  },
];

interface DadosPessoaisProps {
  camposDemograficosObrigatorios: boolean;
  estadosOptions: any;
  ufOptions: any;
}

const DadosPessoais = ({
  camposDemograficosObrigatorios,
  estadosOptions,
  ufOptions,
}: DadosPessoaisProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const { handleSetFormDirty } = useManterPaciente();

  const { idPaciente } = useParams();

  const [escolaridadeOptions, setEscolaridadeOptions] = useState<any>([]);
  const [situacaoFamiliarOptions, setSituacaoFamiliarOptions] = useState<any>(
    [],
  );
  const [paisesOptions, setPaisesOptions] = useState<any>([]);
  const [cidadesOptions, setCidadesOptions] = useState<any>([]);
  const [estrangeiro, setEstrangeiro] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const useFormMethods = useForm({
    resolver: yupResolver(
      validationSchema(camposDemograficosObrigatorios, estrangeiro),
    ),
  });

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    watch,
    setValue,
    reset,
    handleSubmit,
  } = useFormMethods;

  useEffectSkipFirst(() => {
    handleSetFormDirty(isDirty, 'DADOS_OPCIONAIS');
  }, [isDirty]);

  const watchPaisNaturalidade = watch('idPaisNaturalidade');
  const watchEstadoNaturalidade = watch('idEstadoNaturalidade');

  const loadDadosPessoais = useCallback(async () => {
    if (!idPaciente) return;

    setLoading(true);

    const response = await PacienteAPI.getPacienteDadosPessoais(
      Number(idPaciente),
    );

    setValue('nomeSocial', response?.nomeSocial || '');
    setValue('etnia', response?.etnia || '');
    setValue('religiao', response?.religiao || '');
    setValue('rgNumero', response?.rgNumero || '');
    setValue('rgOrgaoEmissor', response?.rgOrgaoEmissor || '');
    setValue('complemento', response?.complemento || '');
    setValue('nomeResponsavelLegal', response?.nomeResponsavelLegal || '');
    setValue('cpfResponsavelLegal', response?.cpfResponsavelLegal || '');
    setValue('nomePessoaContato', response?.nomePessoaContato || '');
    setValue('pessoaContato', response?.pessoaContato?.numero || '');
    setValue('cns', response?.cns || '');

    setValue('menorIdade', response?.menorIdade || false);
    setValue('nomePai', response?.nomePai || '');

    if (response?.rgUf) setValue('rgUf', response.rgUf);
    if (response?.idGenero) setValue('idGenero', response.idGenero);
    if (response?.idGrauEscolar)
      setValue('idGrauEscolar', response.idGrauEscolar);
    if (response?.estadoCivil) setValue('estadoCivil', response.estadoCivil);
    if (response?.idRacaCor || response?.idRacaCor === 0)
      setValue('idRacaCor', response.idRacaCor);
    if (response?.idGrauParentesco || response?.idGrauParentesco === 0)
      setValue('idGrauParentesco', response.idGrauParentesco);
    if (response?.idSituacaoConjugal || response?.idSituacaoConjugal === 0)
      setValue('idSituacaoConjugal', response.idSituacaoConjugal);
    if (response?.idPaisNaturalidade || response?.idPaisNaturalidade === 0)
      setValue('idPaisNaturalidade', response.idPaisNaturalidade);
    if (response?.idEstadoNaturalidade || response?.idEstadoNaturalidade === 0)
      setValue('idEstadoNaturalidade', response.idEstadoNaturalidade);
    if (response?.idCidadeNaturalidade || response?.idCidadeNaturalidade === 0)
      setValue('idCidadeNaturalidade', response.idCidadeNaturalidade);
    if (
      response?.idPaisEmissorPassaporte ||
      response?.idPaisEmissorPassaporte === 0
    )
      setValue('idPaisEmissorPassaporte', response.idPaisEmissorPassaporte);
    if (response?.idPaisNacionalidade || response?.idPaisNacionalidade === 0)
      setValue('idPaisNacionalidade', response.idPaisNacionalidade);
    if (response?.rgDataEmissao)
      setValue('rgDataEmissao', new Date(response?.rgDataEmissao));
    if (response?.passaporteDataValidade)
      setValue(
        'passaporteDataValidade',
        new Date(response?.passaporteDataValidade),
      );
    if (response?.passaporteDataEmissao)
      setValue(
        'passaporteDataEmissao',
        new Date(response?.passaporteDataEmissao),
      );
    if (response?.passaporteDataNaturalizacao)
      setValue(
        'passaporteDataNaturalizacao',
        new Date(response?.passaporteDataNaturalizacao),
      );

    setEstrangeiro(response?.estrangeiro || false);

    if (response?.biometriaFacial) {
      setValue('biometriaFacial', response.biometriaFacial);
    } else {
      setValue('biometriaFacial', true);
    }

    setLoading(false);
  }, [idPaciente, setValue]);

  useEffect(() => {
    loadDadosPessoais();
  }, [loadDadosPessoais]);

  const loadEscolaridadeData = async () => {
    const response = await UtilsAPI.GetGrauEscolar();

    setEscolaridadeOptions(formatData(response, 'descricao', 'id'));
  };

  const loadSituacaoFamiliarData = async () => {
    const response = await UtilsAPI.getSituacaoFamiliar();

    setSituacaoFamiliarOptions(formatData(response, 'descricao', 'id'));
  };

  const loadPaisData = async () => {
    const response = await UtilsAPI.GetPaises();

    setPaisesOptions(formatData(response, 'descricao', 'id'));
  };

  useEffect(() => {
    loadEscolaridadeData();
    loadSituacaoFamiliarData();
    loadPaisData();
  }, []);

  useEffect(() => {
    reset({
      menorIdade: false,
    });
  }, [reset]);

  const loadCidadesByEstado = async (idEstado: string) => {
    const response = await UtilsAPI.GetCidades(idEstado);

    setCidadesOptions(formatData(response, 'nome', 'id'));
  };

  useEffect(() => {
    if (watchEstadoNaturalidade) loadCidadesByEstado(watchEstadoNaturalidade);
  }, [watchEstadoNaturalidade]);

  const onSubmit = async (data: any) => {
    await saveDadosPessoais(Number(idPaciente), user?.idEmpresa, data);

    reset(data, { keepValues: true });
  };

  if (loading)
    return (
      <div className="p-grid">
        <div className="p-col-12 p-lg-6 p-xl-5">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <FormProvider {...useFormMethods}>
      <form
        className="p-grid p-col-12 p-lg-6 p-xl-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInputControlled
          className="p-col-12"
          control={control}
          name="nomeSocial"
          label="Nome social/apelido"
          errorMsg={errors.nomeSocial?.message}
        />
        <TextInputControlled
          className="p-col-12"
          control={control}
          name="etnia"
          label="Etnia"
          errorMsg={errors.etnia?.message}
        />
        <Genero />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="idGrauEscolar"
          label="Escolaridade"
          placeholder="Selecione"
          options={escolaridadeOptions}
          errorMsg={errors.idGrauEscolar?.message}
        />
        <TextInputControlled
          className="p-col-12"
          control={control}
          name="religiao"
          label="Religião"
          errorMsg={errors.religiao?.message}
        />
        <DropdownControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="estadoCivil"
          label="Estado civil"
          options={situacoesConjugais}
          errorMsg={errors.estadoCivil?.message}
        />
        <RacaCor />

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Registro geral (RG)
        </SimpleText>

        <RegistroGeral />

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Responsável legal
        </SimpleText>

        <ResponsavelLegal />

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Pessoa para contato
        </SimpleText>

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="nomePessoaContato"
          label="Nome"
          errorMsg={errors.nomePessoaContato?.message}
        />
        <MaskedInputControlled
          className="p-col-12"
          control={control}
          name="pessoaContato"
          label="Telefone para recados"
          placeholder="(99) 9999-9999"
          mask={MASK.PHONE}
          errorMsg={errors.pessoaContato?.message}
        />

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Cartão Nacional de Saúde (CNS)
        </SimpleText>

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="cns"
          label="Número"
          maxLength={15}
          permitirApenasNumeros
          errorMsg={errors.cns?.message}
        />

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Naturalidade
        </SimpleText>

        <DropdownControlled
          className="p-col-12"
          control={control}
          name="idPaisNaturalidade"
          label="Nacionalidade"
          options={paisesOptions}
          errorMsg={errors.idPaisNaturalidade?.message}
        />

        {watchPaisNaturalidade === 0 || !watchPaisNaturalidade ? (
          <>
            <DropdownControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="idEstadoNaturalidade"
              label="Estado de naturalidade"
              placeholder="Pesquisar"
              options={estadosOptions}
              errorMsg={errors.idEstadoNaturalidade?.message}
            />
            <DropdownControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="idCidadeNaturalidade"
              label="Cidade natal"
              placeholder="Pesquisar"
              options={cidadesOptions}
              errorMsg={errors.idCidadeNaturalidade?.message}
            />
          </>
        ) : (
          <>
            <CalendarInputControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="dataEntrada"
              label="Data de entrada"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              maxDate={new Date()}
              errorMsg={errors.dataEntrada?.message}
            />
            <CalendarInputControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="dataNaturalizacao"
              label="Data de naturalização"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              maxDate={new Date()}
              errorMsg={errors.dataNaturalizacao?.message}
            />
          </>
        )}

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Passaporte
        </SimpleText>

        <Passaporte />
        <DropdownControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="idPaisNacionalidade"
          label="País de nascimento"
          placeholder="Pesquisar"
          options={paisesOptions}
          errorMsg={errors.idPaisNacionalidade?.message}
        />
        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="passaporteDataNaturalizacao"
          label="Data de naturalização"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          maxDate={new Date()}
          errorMsg={errors.passaporteDataNaturalizacao?.message}
        />

        <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
          Outros
        </SimpleText>

        <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
          <SimpleText medium>Biometria facial</SimpleText>
          <SwitchControlled
            name="biometriaFacial"
            control={control}
            showStatus
          />
        </div>

        <div className="p-col-12">
          <CheckboxControlled
            className="checkbox-small"
            control={control}
            name="menorIdade"
            label="Menor de idade"
          />
        </div>
        <TextInputControlled
          className="p-col-12"
          control={control}
          name="nomePai"
          label="Nome do pai"
          errorMsg={errors.nomePai?.message}
        />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="idSituacaoConjugal"
          label="Situação conjugal/familiar"
          options={situacaoFamiliarOptions}
          errorMsg={errors.idSituacaoConjugal?.message}
        />

        <div className="p-col-12 p-mt-5">
          <Button
            type="submit"
            label="Salvar alterações"
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default DadosPessoais;
