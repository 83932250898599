import { useContext } from 'react';

import { Divider as PrimeDivider, DividerProps } from 'primereact/divider';

import ThemeContext from 'src/core/themes/ThemeContext';

const Divider = ({ className, ...rest }: DividerProps) => {
  const { theme } = useContext(ThemeContext);

  return (
    <PrimeDivider
      className={`
          ${className || ''}
          ${theme || ''}
        `}
      {...rest}
    />
  );
};

export default Divider;
