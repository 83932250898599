import { memo, useCallback } from 'react';

import GrupoTussAPI from 'src/APIs/AdminAPI/GrupoTussAPI/GrupoTussAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalExcluirGrupoTuss.scss';

interface ModalExcluirGrupoTussProps {
  visible: boolean;
  onHide(): void;
  onSuccess(): void;
  grupoTuss: GrupoTuss;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button btnType="danger" label="Sim, excluir" onClick={onAdd} stretch />
    </div>
  );
});

const ModalExcluirGrupoTuss = ({
  grupoTuss,
  visible,
  onHide,
  onSuccess,
}: ModalExcluirGrupoTussProps) => {
  const handleDeletarGrupoTuss = useCallback(async () => {
    const response: any = await GrupoTussAPI.removeGrupoTussById(grupoTuss.id!);

    if (response.status === 204) {
      onHide();
      onSuccess();
    }
  }, [grupoTuss, onHide, onSuccess]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Excluir grupo TUSS"
      visible={visible}
      onHide={onHide}
      footer={() => <Footer onAdd={handleDeletarGrupoTuss} onCancel={onHide} />}
    >
      <>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Você realmente deseja excluir este grupo TUSS?
        </SimpleText>
      </>
    </Dialog>
  );
};

export default ModalExcluirGrupoTuss;
