import * as Yup from 'yup';

const equipeProfissionaisSaudeSchema = Yup.object().shape({
  responsavel: Yup.boolean(),
});

export const validationSchema = Yup.object().shape({
  nome: Yup.string().required('O campo nome é obrigatório.'),
  equipeProfissionaisSaude: Yup.array()
    .of(equipeProfissionaisSaudeSchema)
    .min(1, 'A equipe deve possuir pelo menos um membro')
    .test(
      'profissionalResponsavel',
      'Deve existir um profissional responsável pela equipe',
      (equipeProfissionaisSaude: any) => {
        return (
          equipeProfissionaisSaude?.some?.(
            (profissionalSaude: any) => !!profissionalSaude.responsavel,
          ) ?? true
        );
      },
    ),
});
