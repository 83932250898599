import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { EquipeAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/EquipeAPI/EquipeAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './EquipeGrid/Row';
import { Header } from './Header';

import './Equipe.scss';

const Equipe = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [reload, setReload] = useState<number>(0);

  const { isMobile, isTablet } = useSize();

  const renderMobile = isMobile || isTablet;

  const navigate = useNavigate();

  const fetchAPI = async ({ query, ...rest }: any) =>
    EquipeAPI.getEquipe(consultorios.ativo.id, {
      nome: query,
      sortBy: 'nome',
      sortDirection: 'ASC',
      ...rest,
    });

  const modalDeleteDisclosure = useDisclosure({ opened: false });

  const reloadList = () => {
    setReload((currentReload: number) => currentReload + 1);
  };

  const onDelete = async (id: number) => {
    modalDeleteDisclosure.open({ state: { id: id } });
  };

  const onConfirmDelete = useCallback(async () => {
    const response = await EquipeAPI.deleteEquipe(
      modalDeleteDisclosure.state.id,
      consultorios?.ativo?.id,
    );

    if (response && 'status' in response) {
      return;
    }
    reloadList();
    modalDeleteDisclosure.close();
  }, [consultorios?.ativo?.id, modalDeleteDisclosure]);

  const onEdit = (equipe: EquipeDTO) => {
    if (equipe.id) {
      navigate(`/pacientes/carteira-aps/equipe/${equipe.id}`);
    }
  };

  const renderRow = (equipe: EquipeDTO) => (
    <Row equipe={equipe} onDelete={onDelete} onEdit={onEdit} />
  );

  return (
    <div id="equipe-container" className="p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          consultorioRequired
          reloadWhenEmpresaChange
        >
          <SearchList.SearchInput
            label="Pesquisar equipe"
            placeholder="Pesquise pela descrição ou pontuação"
          />

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
              <Button
                className="p-mb-2 p-mb-sm-0"
                type="button"
                label="Incluir nova equipe"
                onClick={() => navigate('/pacientes/carteira-aps/equipe')}
                checkPermission="ADM_CARTEIRA_APS_EQUIPE_CADASTRAR"
              />

              <SearchList.TotalCount />
            </div>

            {!renderMobile && (
              <SearchList.Header>
                <Header />
              </SearchList.Header>
            )}

            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reload}
              customEmptyText="Nenhum registro foi encontrado. Por favor, tente adicionar uma equipe."
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
      {modalDeleteDisclosure.isOpen && (
        <ConfirmDialog
          visible={modalDeleteDisclosure.isOpen}
          header="Excluir equipe"
          text="Deseja excluir a equipe selecionada?"
          confirmText="Sim, excluir"
          onHide={() => modalDeleteDisclosure.close()}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default Equipe;
