import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ComplexidadePacienteAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/ComplexidadePacienteAPI.ts/ComplexidadePacienteAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './ComplexidadePacienteGrid/Row';
import { Header } from './Header';

import './ComplexidadePaciente.scss';

const ComplexidadePaciente = () => {
  const [reload, setReload] = useState<number>(0);

  const { isMobile, isTablet } = useSize();

  const renderMobile = isMobile || isTablet;

  const navigate = useNavigate();

  const reloadList = () => {
    setReload((currentReload: number) => currentReload + 1);
  };

  const fetchAPI = async ({ query, ...rest }: any) => {
    const response = await ComplexidadePacienteAPI.getComplexidadePaciente({
      descricao: query,
      sortBy: 'descricao',
      sortDirection: 'ASC',
      ...rest,
    });

    return response;
  };

  const modalDeleteDisclosure = useDisclosure({ opened: false });

  const onDelete = async (id: number) => {
    modalDeleteDisclosure.open({ state: { id: id } });
  };

  const onConfirmDelete = useCallback(async () => {
    const response = await ComplexidadePacienteAPI.deleteComplexidade(
      modalDeleteDisclosure.state.id,
    );

    if (response && 'status' in response) {
      return;
    }
    reloadList();
    modalDeleteDisclosure.close();
  }, [modalDeleteDisclosure]);

  const onEdit = (complexidade: ComplexidadePacienteDTO) => {
    if (complexidade.id) {
      navigate(`/pacientes/carteira-aps/complexidade/${complexidade.id}`);
    }
  };

  const renderRow = (complexidade: ComplexidadePacienteDTO) => (
    <Row complexidade={complexidade} onDelete={onDelete} onEdit={onEdit} />
  );

  return (
    <div id="complexidade-paciente-container" className="p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.SearchInput
            label="Pesquisar complexidade"
            placeholder="Pesquise pela descrição"
          />

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
              <Button
                className="p-mb-2 p-col-12 p-md-6 p-lg-3"
                type="button"
                btnType="pill"
                label="Criar complexidade do paciente"
                onClick={() => navigate('/pacientes/carteira-aps/complexidade')}
                checkPermission="ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_CADASTRAR"
              />
            </div>

            {!renderMobile && <Header />}

            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reload}
              customEmptyText="Nenhum registro foi encontrado. Por favor, tente adicionar uma complexidade."
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
      {modalDeleteDisclosure.isOpen && (
        <ConfirmDialog
          visible={modalDeleteDisclosure.isOpen}
          header="Excluir complexidade"
          text="Deseja excluir a complexidade selecionada??"
          confirmText="Sim, excluir"
          onHide={() => modalDeleteDisclosure.close()}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default ComplexidadePaciente;
