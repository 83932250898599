import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalVisualizarDocumentoProps extends DisclosureType {
  documento: any;
}

export default function ModalVisualizarDocumento({
  isOpen,
  close,
  documento,
}: ModalVisualizarDocumentoProps) {
  return (
    <Dialog
      id="vizualizar-documento"
      type="modal"
      header="Visualizar documento"
      visible={isOpen}
      onHide={() => {
        close();
      }}
    >
      <div>
        {documento?.status === 'INATIVO' && (
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Este item foi inativado.
          </SimpleText>
        )}

        <TextareaInput
          className="p-col-12"
          value={documento?.justificativaInativacao}
          name="documento"
          disabled={true}
        />

        <div className="p-col-12 p-d-flex p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            type="reset"
            label="Fechar"
            className="p-col-align-center"
            onClick={() => {
              close();
            }}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
}
