import React from 'react';

import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

interface DesktopRowProps {
  exportacao: any;
  onAlterarEntregue: any;
  setGoogleUrl: any;
  handleDownloadArquivo: any;
}

export default function DesktopRow({
  exportacao,
  onAlterarEntregue,
  setGoogleUrl,
  handleDownloadArquivo,
}: DesktopRowProps) {
  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed: boolean, onToggle: () => void) => (
          <PanelHeader
            exportacao={exportacao}
            onAlterarEntregue={onAlterarEntregue}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            setGoogleUrl={setGoogleUrl}
            handleDownloadArquivo={handleDownloadArquivo}
          />
        )}
      >
        <PanelContent exportacao={exportacao} />
      </Panel>
    </div>
  );
}
