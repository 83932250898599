import { useEffect, useState } from 'react';

import { PontuacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/PontuacaoAPI';
import { ProfissionalAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/ProfissionalAPI/ProfissionalAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import Row from './ProfissionalGrid/Row';

import './Profissional.scss';

const Profissional = () => {
  const [pontuacaoOptions, setPontuacaoOptions] = useState<any[]>([]);

  const { isMobile } = useSize();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const idConsultorio = consultorios?.ativo?.id;

  useEffect(() => {
    const getGruposPontuacao = async () => {
      const response = await PontuacaoCarteiraAPI.getPontuacaoByConsultorio(
        idConsultorio,
      );
      if ('status' in response) return;
      setPontuacaoOptions(response);
    };
    getGruposPontuacao();
  }, [idConsultorio]);

  const fetchAPI = async ({ query, ...rest }: any) => {
    return await ProfissionalAPI.getProfissionais(idConsultorio, {
      filtro: query,
      sortBy: 'usuario.nome',
      sortDirection: 'ASC',
      ...rest,
    });
  };

  const renderRow = (profissional: any) => (
    <Row
      profissional={profissional.profissionalSaude}
      pontuacao={profissional.pontuacao}
      pontuacaoOptions={pontuacaoOptions}
    />
  );

  return (
    <div id="profissional-aps-container" className="p-py-3 p-px-4">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenEmpresaChange
      >
        <SearchList.SearchInput
          label="Pesquisar profissional"
          placeholder="Pesquise pelo nome do profissional ou conselho"
        />

        <SearchList.BorderContainer>
          <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap"></div>

          {!isMobile && (
            <SearchList.Header>
              <Header />
            </SearchList.Header>
          )}

          <SearchList.InfiniteScroll
            renderRow={renderRow}
            customEmptyText="Nenhum registro foi encontrado."
          />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </div>
  );
};

export default Profissional;
