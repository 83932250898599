import { forwardRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { useSearchList } from './components/SearchList/SearchListContext';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldConsultorio } from 'src/components/Fields/FieldConsultorios/FieldConsultorio';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { FieldEspecialidade } from 'src/components/Fields/FieldEspecialidade/FieldEspecialidade';
import { FieldProfissionalSaudeByCliente } from 'src/components/Fields/FieldProfissionalSaude/FieldProfissionalSaude';
import { FieldSexo } from 'src/components/Fields/FieldSexo/FieldSexo';
import FormInput from 'src/components/FormInput/FormInput';

import './GestaoMonitoramento.scss';

const defaultValues = {
  convenio: null,
  consultorio: null,
  especialidade: null,
  profissionalSaude: null,
  sexo: null,
  idade: null,
  tipoBusca: null,
  pontuacao: null,
  dataInicio: null,
  dataFim: null,
  periodo: null,
} as any;

export const FormularioDinamicoGestaoMonitoramentoFilter = forwardRef<any>(
  (_, ref) => {
    const { setFilter } = useSearchList();
    const reference: any = ref;

    const form = useForm({
      defaultValues,
    });

    const onSubmit = (values: any) => {
      setFilter({
        idConvenio: values.convenio?.id,
        idConsultorio: values.consultorio?.id,
        idEspecialidade: values.especialidade?.id,
        idProfissionalSaude: values.profissionalSaude?.id,
        idSexoPaciente: values.sexo,
        idadePaciente: values.idade,
        totalPontuacao: values.pontuacao,
        parametroPontuacao: values.tipoBusca,
        dataInicio: values.dataInicio,
        dataFim: values.dataFim,
      });

      reference?.current?.hide();
    };

    const handleClean = () => {
      setFilter({});
      form.reset({
        convenio: null,
        consultorio: null,
        especialidade: null,
        profissionalSaude: null,
        sexo: null,
        idade: null,
        tipoBusca: null,
        pontuacao: null,
        dataInicio: null,
        dataFim: null,
        periodo: null,
      });

      reference?.current?.hide();
    };

    const periodo = form.watch('periodo');

    useEffectSkipFirst(() => {
      if (periodo) {
        form.setValue(
          'dataInicio',
          dayjs().subtract(periodo, 'month').toDate(),
        );
        form.setValue('dataFim', new Date());
      }

      if (!periodo) {
        form.setValue('dataInicio', null);
        form.setValue('dataFim', null);
      }
    }, [periodo]);

    return (
      <OverlayPanel ref={ref} className="filtro-avancado">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="p-d-flex p-flex-wrap p-col-12 p-p-0"
          >
            <SimpleText fontColor="color_40" className="p-w-100 p-p-2">
              Pesquise os formulários de forma detalhada, preenchendo os campos
              abaixo.
            </SimpleText>

            <SimpleText className="p-w-100 p-p-2" fontColor="color_40">
              Vínculos
            </SimpleText>

            <FieldConvenio
              name="convenio"
              label="Convênio"
              className="p-col-12 p-md-6"
              showClear
              placeholder="Selecione o convênio"
            />
            <FieldConsultorio
              name="consultorio"
              label="Consultório"
              className="p-col-12 p-md-6"
              showClear
              placeholder="Selecione o consultório"
            />
            <FieldEspecialidade
              name="especialidade"
              label="Especialidade"
              className="p-col-12 p-md-6"
              showClear
              placeholder="Selecione a especialidade"
            />
            <FieldProfissionalSaudeByCliente
              name="profissionalSaude"
              label="Profissional monitor"
              className="p-col-12 p-md-6"
              showClear
              placeholder="Selecione o profissional"
            />

            <Divider className="p-col-12 p-m-0" layout="horizontal" />

            <SimpleText className="p-w-100 p-p-2" fontColor="color_40">
              Paciente
            </SimpleText>

            <FieldSexo
              name="sexo"
              label="Sexo"
              className="p-col-6 p-md-3"
              showClear
              placeholder="Selecione"
            />

            <FormInput name="idade" label="Idade" className="p-col-6 p-md-3" />

            <DropdownControlled
              control={form.control}
              name="tipoBusca"
              label="Buscar"
              options={[
                { label: 'Maior que', value: 'MAIOR_QUE' },
                { label: 'Menor que', value: 'MENOR_QUE' },
                { label: 'Igual', value: 'IGUAL' },
              ]}
              className="p-col-6 p-md-3"
              showClear
              placeholder="Selecione"
              filter={false}
            />

            <FormInput
              name="pontuacao"
              type="number"
              label="Pontuação"
              className="p-col-6 p-md-3"
            />

            <Divider className="p-col-12 p-m-0" layout="horizontal" />

            <SimpleText className="p-w-100 p-p-2" fontColor="color_40">
              Aplicação
            </SimpleText>

            <CalendarInputControlled
              name="dataInicio"
              label="Data de início"
              className="p-col-6 p-md-4"
              showIcon
              control={form.control}
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
            />
            <CalendarInputControlled
              name="dataFim"
              label="Data final"
              className="p-col-6 p-md-4"
              showIcon
              control={form.control}
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
            />

            <DropdownControlled
              name="periodo"
              control={form.control}
              label="Período"
              options={[
                { label: '3 meses', value: 3 },
                { label: '6 meses', value: 6 },
                { label: '1 ano', value: 12 },
              ]}
              showClear
              filter={false}
              className="p-col-12 p-md-4"
            />

            <div className="p-d-flex p-col-12 p-ai-center">
              <Button
                type="button"
                label="Limpar"
                btnType="green-link"
                className="p-col"
                onClick={handleClean}
              />
              <Button type="submit" label="Pesquisar" className="p-col-8" />
            </div>
          </form>
        </FormProvider>
      </OverlayPanel>
    );
  },
);
