import { useCallback, useState } from 'react';
import './ConfiguracaoGuiaTissGlobal.scss';

import { useNavigate } from 'react-router';

import ConfiguracaoGuiaTissAPI from 'src/APIs/ConfigAPI/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './ConfigGuiaTissGrid/Row';
import { Header } from './Header';
import ModalExcluirConfiguracaoGuiaTiss from './ModalExcluirConfiguracaoGuiaTiss/ModalExcluirConfiguracaoGuiaTiss';

const ConfiguracaoGuiaTissGlobal = () => {
  const navigate = useNavigate();

  const [
    modalExcluirConfiguracaoGuiaTiss,
    setModalExcluirConfiguracaoGuiaTiss,
  ] = useState(false);
  const [configuracaoGuiaTiss, setConfiguracaoGuiaTiss] = useState({} as any);

  const [items, setItems] = useState<any[]>([]);
  const [reload, setReload] = useState(0);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_configuracao_tiss_cadastrar',
      'adm_configuracao_tiss_alterar',
      'adm_configuracao_tiss_consultar',
      'adm_configuracao_tiss_excluir',
    ],
  });

  const fetchApi = async ({ query, ...rest }: any) => {
    return await ConfiguracaoGuiaTissAPI.loadConfiguracaoGuiaTiss({
      descricao: query,
      ...rest,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleModalAddConfigTiss = useCallback(() => {
    navigate('/config/config-tiss/novo');
  }, [navigate]);

  const handleModalEditConfigTiss = useCallback(
    (item: any) => {
      if (item.id) {
        navigate(`/config/config-tiss/${item.id}`, { state: item });
      }
      setConfiguracaoGuiaTiss(item);
    },
    [navigate],
  );

  const handleModalExcluirConfiguracaoGuiaTissClose = useCallback(() => {
    setModalExcluirConfiguracaoGuiaTiss(false);
  }, []);

  const handleExcluirConfiguracaoGuiaTiss = useCallback((item: any) => {
    setModalExcluirConfiguracaoGuiaTiss(true);
    setConfiguracaoGuiaTiss(item);
  }, []);

  const handleIsActive = useCallback(
    async (item: any) => {
      const data = {
        status: !item.status,
      };

      const response: any =
        await ConfiguracaoGuiaTissAPI.updateStatusConfiguracaoGuiaTiss(
          item?.id,
          data,
        );

      if (response?.status === 200) {
        setItems([...items]);
        reloadList();
      }
    },
    [items],
  );

  const renderRow = (data: any): JSX.Element => (
    <Row
      data={data}
      onSwitchStatus={handleIsActive}
      onDelete={handleExcluirConfiguracaoGuiaTiss}
      onEdit={handleModalEditConfigTiss}
    />
  );

  return (
    <div id="ConfiguracaoGuiaTiss" className="p-px-4">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center">
            <SearchList.SearchInput placeholder="Pesquisar por descrição" />

            <Button
              btnType="pill"
              className="p-mb-3"
              label="Nova guia TISS"
              icon="fas fa-plus"
              onClick={handleModalAddConfigTiss}
              checkPermission="ADM_CONFIGURACAO_TISS_CADASTRAR"
            />
          </div>

          <SearchList.Header>
            <Header />
          </SearchList.Header>

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalExcluirConfiguracaoGuiaTiss
        configuracaoGuiaTiss={configuracaoGuiaTiss}
        visible={modalExcluirConfiguracaoGuiaTiss}
        onHide={handleModalExcluirConfiguracaoGuiaTissClose}
        configuracaoGuiaTissList={items}
        setConfiguracaoGuiaTiss={setItems}
        onSuccess={reloadList}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default ConfiguracaoGuiaTissGlobal;
