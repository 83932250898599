import { useCallback, useEffect, useState } from 'react';

import './PesquisarPaciente.scss';

import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import { PesquisaPacienteAPI } from 'src/APIs/AgendaAPI/PesquisaPacienteAPI/PesquisaPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { useAgenda } from '../AgendaContext';

import { DesktopRow, Filter, Header, MobileRow } from './List';

type FormValues = {
  buscaCombinada: string;
  idsMedicos: number[];
  dataInicial: any;
  dataFinal: any;
};
type PesquisarPacienteProps = DisclosureType;

export const PesquisarPaciente = ({ close }: PesquisarPacienteProps) => {
  const { toggleViewAgenda, reloadList, reload } = useAgenda();
  const { user, consultorios, agenda } = useAppSelector(
    (state: RootState) => state,
  );

  const [profissionais, setProfissionais] = useState<any[]>([
    { nome: 'Todos', id: -1 },
  ]);
  const [selectedAgendamento, setSelectedAgendamento] = useState<any>(null);
  const [prevProfissionais, setPrevProfissionais] = useState<any[]>([]);

  const useFormMethods = useForm<FormValues>();

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormMethods;

  useEffect(() => {
    setValue('dataInicial', new Date());
    const dateFinal = new Date();
    dateFinal.setDate(dateFinal.getDate() + 60);
    setValue('dataFinal', dateFinal);
  }, [setValue]);

  const watchDataInicial = watch('dataInicial');
  const watchIdsMedicos = watch('idsMedicos');

  useEffect(() => {
    setPrevProfissionais(watchIdsMedicos);

    if (
      !prevProfissionais?.includes(-1) &&
      prevProfissionais?.length > 0 &&
      watchIdsMedicos?.includes(-1)
    ) {
      setValue('idsMedicos', [-1]);
    }

    if (prevProfissionais?.includes(-1) && watchIdsMedicos?.length > 1) {
      const filterTodos = watchIdsMedicos?.filter(id => id !== -1);
      setValue('idsMedicos', filterTodos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIdsMedicos]);

  useEffect(() => {
    const getProfissionais = async () => {
      const response = await PesquisaPacienteAPI.loadProfissionaisConsultorio(
        consultorios?.ativo?.id,
      );

      setProfissionais(prevData => [...prevData, ...response?.data]);
      if (
        user.tipoProfissionalSaude !== 'MEDICO' &&
        user.tipoProfissionalSaude !== 'MEDICA'
      ) {
        setValue('idsMedicos', [-1]);
      }
    };

    getProfissionais();
  }, [
    agenda.profissionalAtivo,
    agenda.profissionalAtivo.id,
    consultorios?.ativo?.id,
    setValue,
    user.tipoProfissionalSaude,
  ]);

  useEffect(() => {
    if (
      user.tipoProfissionalSaude === 'MEDICO' ||
      user.tipoProfissionalSaude === 'MEDICA'
    ) {
      setValue('idsMedicos', [agenda?.profissionalAtivo?.id]);
    }
  }, [agenda?.profissionalAtivo?.id, setValue, user.tipoProfissionalSaude]);

  const renderRow = (data: any): JSX.Element => {
    const newData = {
      ...data,
      id: data?.id,
      status: data?.status,
      nomePaciente: data?.paciente?.nome,
      dataAgendamento: data?.dataAgendamento,
      telefoneContato:
        data?.paciente?.celularParticular ||
        data?.paciente?.fixoResidencial ||
        data?.paciente?.telefoneRecado ||
        data?.paciente?.fixoComercial ||
        'Não informado',
      plano:
        data?.paciente?.convenio?.nomeFantasia ||
        data?.paciente?.convenio?.razaoSocial,
      cartao: data?.paciente?.cartaoPlano,
      profissionalSaude: data?.medico?.nome,
    };

    const commonProps = {
      agendamento: newData,
      setSelectedAgendamento: setSelectedAgendamento,
    };

    if (isMobile) return <MobileRow {...commonProps} />;

    return (
      <SearchList.CardItem>
        <DesktopRow {...commonProps} />
      </SearchList.CardItem>
    );
  };

  const returnMedicosId = useCallback(async () => {
    const response = await PesquisaPacienteAPI.loadProfissionaisConsultorio(
      consultorios?.ativo?.id,
    );
    const idMedicosConsultorio = response?.data?.map(
      (profissional: { id: any }) => profissional.id,
    );

    return idMedicosConsultorio;
  }, [consultorios?.ativo?.id]);

  const getIdMedicos = useCallback(async () => {
    const idMedicos: any = [];
    if (
      user.tipoProfissionalSaude === 'MEDICO' ||
      user.tipoProfissionalSaude === 'MEDICA'
    ) {
      return agenda?.profissionalAtivo?.id.toString();
    }

    if (
      getValues('idsMedicos') === undefined ||
      getValues('idsMedicos').includes(-1)
    ) {
      const id = await returnMedicosId();
      idMedicos.push(id);
    } else {
      idMedicos.push(getValues('idsMedicos'));
    }

    return idMedicos.toString();
  }, [
    agenda?.profissionalAtivo?.id,
    getValues,
    returnMedicosId,
    user.tipoProfissionalSaude,
  ]);

  const fetchApi = useCallback(
    async (filterParams: any) => {
      const params = {
        idsMedicos: await getIdMedicos(),
        buscaCombinada: getValues('buscaCombinada'),
        ...filterParams,
        sortBy: 'dataAgendamento',
        sortDirection: 'DESC',
      };

      const response = await PesquisaPacienteAPI.loadPesquisaPacientes(
        consultorios?.ativo?.id,
        dayjs(getValues('dataInicial')).format('YYYYMMDD') ||
          dayjs(new Date()).format('YYYYMMDD'),
        dayjs(getValues('dataFinal')).format('YYYYMMDD') ||
          dayjs(new Date()).format('YYYYMMDD'),
        params,
      );

      return response?.data;
    },
    [consultorios?.ativo?.id, getIdMedicos, getValues],
  );

  return (
    <Dialog
      header={
        <Button
          btnType="green-link"
          label="Voltar para agenda completa"
          icon="fa fa-arrow-left"
          onClick={() => {
            toggleViewAgenda(true);
            close();
          }}
        />
      }
      visible
      onHide={close}
      maximized
      className={`Agenda`}
    >
      <>
        <SearchList.Root fetchApi={fetchApi} paginatedList={false}>
          <div className={'agenda-content'}>
            <div className="p-d-flex p-flex-column p-gap-2 p-mt-2 p-mx-4">
              <SimpleText fontSize={FONT_SIZE.SM} medium>
                Pesquisar paciente na agenda
              </SimpleText>
              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
                medium
              >
                Procure um paciente agendado no seu consultório
              </SimpleText>
            </div>

            <FormProvider {...useFormMethods}>
              <form className="pesquisar-paciente-container p-p-2">
                <div className="p-grid p-d-flex p-jc-center p-ai-end p-my-2">
                  <div className="p-col-12 p-sm-6 p-as-end">
                    <TextInputControlled
                      control={control}
                      name="buscaCombinada"
                      placeholder="Pesquisar por nome, CPF ou número da carteirinha"
                      icon="fa fa-search"
                      rounded
                    />
                  </div>
                  <div className="p-col-12 p-sm-6 p-mb-1">
                    <MultiSelectControlled
                      control={control}
                      name="idsMedicos"
                      label="Profissionais"
                      options={profissionais}
                      optionLabel="nome"
                      optionValue="id"
                      placeholder="Selecione um ou mais profissionais"
                      disabled={
                        user.tipoProfissionalSaude === 'MEDICO' ||
                        user.tipoProfissionalSaude === 'MEDICA'
                          ? true
                          : false
                      }
                      showSelectAll
                      display="chip"
                    />
                  </div>
                </div>
                <div className="p-grid p-d-flex p-ai-center p-my-2">
                  <div className="p-grid p-col-12 p-px-0 p-d-flex">
                    <div className="p-grid p-col-12 p-md-8">
                      <CalendarInputControlled
                        className="p-col-6 p-sm-5 p-md-4"
                        control={control}
                        name="dataInicial"
                        label="Período de"
                        placeholder="dd/mm/aaaa"
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        errorMsg={errors.dataInicial?.message}
                        showIcon
                        hideTextErrorSpace
                      />
                      <CalendarInputControlled
                        className="p-col-6 p-sm-5 p-md-4"
                        control={control}
                        name="dataFinal"
                        label="Data fim"
                        placeholder="dd/mm/aaaa"
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        minDate={watchDataInicial}
                        errorMsg={errors.dataFinal?.message}
                        showIcon
                        hideTextErrorSpace
                      />
                      <div
                        className="p-sm-12 p-md-4 p-d-flex p-ai-center"
                        style={{
                          paddingTop: '30px',
                        }}
                      >
                        <Button
                          type="button"
                          label="Pesquisar"
                          onClick={() => {
                            reloadList();
                          }}
                          btnType="tonal"
                        />
                      </div>
                    </div>

                    <div
                      className="p-col-12 p-md-4 p-d-flex p-ai-center p-jc-end"
                      style={{
                        paddingTop: '30px',
                      }}
                    >
                      <Filter />
                    </div>
                  </div>

                  <div className="p-col-12 p-d-flex  p-flex-column">
                    <SearchList.Header>
                      <Header />
                    </SearchList.Header>
                    <ErrorBoundary>
                      <SearchList.NonPageableScrollArea
                        renderRow={renderRow}
                        reload={reload}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </SearchList.Root>
      </>
    </Dialog>
  );
};
