import { useCallback, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';
import * as Yup from 'yup';

import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';
import { useUserAccessData } from 'src/core/hooks/ManterUsuario/useUserAccessData';

import { Button } from 'src/components/_UI';
import MultiSelect from 'src/components/Basics/MultiSelect/MultiSelect';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import Customization from './Customization/Customization';
import SelectUserProfile from './SelectUserProfile/SelectUserProfile';

interface UserAccessDataProps {
  nextStep: () => void;
  isActive: boolean;
}

const formFields = {
  tipo: '',
  login: '',
  perfis: [],
  grupo: '',
  realizaBloqueio: '',
  transacaoTissPessoaJuridica: '',
  confirmacaoCartao: '',
  autorizarSemCartao: '',
  cuidador: '',
  cuidadorViverBem: '',
  profissionalCuidadorAgendamentoOnline: '',
  naoRealizaTransacaoTiss: '',
  habilitarNotificacaoAvaliacaoContrarreferencia: '',
  habilitaEnvioSms: '',
  habilitarAlertaUploadExameAgendamentoOnline: '',
  restringirBloqueioAps: '',
  restringirBloqueioHorario: '',
  atendimentoRetornoPadrao: '',
  prontuarioRestrito: '',
  ocultarCid: '',
  ocultarCiap: '',
  profissionalNaoCooperado: '',
  executaPrescricao: '',
};

const UserAccessData = ({ nextStep, isActive }: UserAccessDataProps) => {
  const [perfisOpt, setPerfisOpt] = useState<any>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [loadingAccessData, setLoadingAccessData] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(isActive);

  const params = useParams();

  const { selectedProfile } = useSelectProfileCreateUser();
  const { accessData, saveAccessData } = useUserAccessData();

  const validationSchema = Yup.object().shape({
    login: Yup.string()
      .trim('O login não pode conter espaços')
      .strict(true)
      .lowercase('O login deve conter apenas letras minúsculas')
      .strict(true)
      .matches(/^\S*$/, 'O login não pode conter espaços')
      .max(20, 'O campo deve conter o máximo de 20 caracteres')
      .required('Digite um valor para login'),
    perfis: Yup.array().nullable().min(1, 'Selecione um perfil'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(formFields);
  }, [selectedProfile, reset]);

  useEffect(() => {
    setIsVisible(isActive);
    if (!isActive && isVisible) {
      const { login, perfis, ...customization } = getValues();

      for (const customItem in customization) {
        customization[customItem] = customization[customItem] || false;
      }

      const newAccessData = {
        login,
        perfis,
        customization,
      };

      saveAccessData(newAccessData);
    }
  }, [getValues, isActive, isVisible, saveAccessData]);

  async function handleSave(formData: any) {
    const { login, perfis, ...customization } = formData;

    for (const customItem in customization) {
      customization[customItem] = customization[customItem] || false;
    }

    const newAccessData = {
      login,
      perfis,
      customization,
    };

    saveAccessData(newAccessData);
    nextStep();
  }

  const handlePerfis = useCallback(
    async (currentPerfis: any[]) => {
      if (!selectedProfile?.value || (!!params?.id && !currentPerfis.length))
        return;

      return new Promise(async resolve => {
        const res = await PerfisAPI.loadPerfis({
          tipoUsuario: selectedProfile.value,
        });

        const aux = res?.list?.map((v: any) => {
          return { label: v?.descricao || '', value: v };
        });

        const formattedPerfisUser = currentPerfis?.map((item: any) => {
          return { label: item?.descricao || '', value: item };
        });

        const uniquePerfisArray = [
          ...new Map(
            [...aux, ...formattedPerfisUser].map(item => [item.value.id, item]),
          ).values(),
        ];
        setPerfisOpt([...uniquePerfisArray]);

        if (!aux.length) {
          setError('perfis', {
            type: 'custom',
            message: 'O cliente selecionado não tem perfis disponíveis.',
          });
        }

        resolve(aux);
      });
    },
    [params?.id, selectedProfile?.value, setError],
  );

  const loadAccessData = useCallback(async () => {
    const currentPerfis = accessData?.perfis?.map((item): any => {
      const {
        id,
        idEmpresa,
        dataAtualizacao,
        dataCriacao,
        descricao,
        perfilFuncionalidades,
      } = item;

      return {
        id,
        idEmpresa,
        dataAtualizacao,
        dataCriacao,
        descricao,
        perfilFuncionalidades,
        empresa: null,
      };
    });

    await handlePerfis(currentPerfis);

    if (!accessData?.login) return;

    setLoadingAccessData(true);

    setValue('login', accessData?.login);
    setValue('perfis', currentPerfis);

    const customizations: any = accessData?.customization;

    for (const customItem in customizations) {
      setValue(customItem, customizations[customItem] || false);
    }

    setLoadingAccessData(false);
  }, [
    accessData?.customization,
    accessData?.login,
    accessData?.perfis,
    handlePerfis,
    setValue,
  ]);

  useEffect(() => {
    loadAccessData();
  }, [loadAccessData]);

  if (loadingAccessData)
    return (
      <div className="formulario">
        <div className="loadingdados">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <form
      className="p-grid p-col-12 p-p-0"
      onSubmit={handleSubmit(handleSave)}
      defaultValue={''}
      onReset={() => reset(formFields)}
    >
      <SelectUserProfile isUpdate={update} />

      <SimpleText className="p-col-12">Dados para o acesso</SimpleText>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }: any) => (
          <TextInput
            label="Login"
            className="p-col-12"
            value={value}
            onBlur={onBlur}
            maxLength={20}
            maxLengthSpan
            onChange={e => {
              onChange({
                ...e,
                target: {
                  ...e.target,
                  value: e.target.value.toLowerCase().trim(),
                },
              });
            }}
            ref={ref}
            errorMsg={errors.login?.message}
            disabled={!!params?.id}
          />
        )}
        name="login"
      />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }: any) => (
          <MultiSelect
            label="Perfil"
            className="p-col-12 p-pb-0"
            value={value}
            onBlur={onBlur}
            onChange={e => {
              clearErrors('perfis');
              onChange(e);
            }}
            options={perfisOpt || []}
            errorMsg={errors.perfis?.message}
            display={'chip'}
            filter
            showSelectAll
            emptyFilterMessage="Perfil não encontrado"
            disabled={!perfisOpt.length}
            dataKey="id"
          />
        )}
        name="perfis"
      />

      <SimpleText
        fontSize={FONT_SIZE.XXXS}
        fontColor={FONT_COLOR.COLOR_60}
        className="p-col-12"
      >
        {'Configure os perfis no menu Configurações > Cardápio.'}
      </SimpleText>
      <br />

      <Customization control={control} />

      <div className="p-col-12">
        <Button
          btnType="tonal"
          label="Avançar"
          type="submit"
          onClick={handleSubmit(handleSave)}
          disabled={selectedProfile === null}
          loading={isSubmitting}
          stretch
        />
      </div>
    </form>
  );
};

export default UserAccessData;
