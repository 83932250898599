import { useState } from 'react';

import { ProgressBar } from 'primereact/progressbar';
import Dropzone, { Accept } from 'react-dropzone';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { useUploadFile } from '../../hooks/useUploadFile';

import './ModalUploadLaudo.scss';

const accept: Accept = {
  'image/jpeg': ['.jpeg', '.png', '.pdf'],
};

export const ModalUploadLaudo = (props: any) => {
  const {
    isOpen,
    close,
    state: { idAtendimento, idProcedimento, isProcedimentoExterno, onSuccess },
  } = props;

  const [showAlert, setShowAlert] = useState(true);

  const handleSuccess = (laudoArquivo: string) => {
    onSuccess(laudoArquivo);
    close();
  };

  const {
    loading,
    loadedFile,
    handleLoadFile,
    handleDeleteFile,
    handleUploadFile,
  } = useUploadFile({ isProcedimentoExterno, onSuccess: handleSuccess });

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleDeleteFile();
  };

  const renderDropzoneContent = () => {
    if (!!loadedFile) {
      return (
        <div
          key={loadedFile.id}
          className="p-w-100 p-d-flex p-jc-between p-ai-center"
        >
          <div className="p-d-flex p-ai-center">
            {loadedFile.isImage ? (
              <img src={loadedFile.preview} width={100} />
            ) : (
              <i className="fas fa-file-pdf" />
            )}
            <SimpleText
              className="p-ml-3"
              fontColor={FONT_COLOR.PRIMARY}
              medium
            >
              {loadedFile.name}
            </SimpleText>
          </div>

          <div className="p-d-flex p-ai-center">
            <SimpleText className="p-mr-3" fontColor={FONT_COLOR.COLOR_40}>
              {loadedFile.readableSize}
              {loadedFile.isImage &&
                ` - ${loadedFile.width}x${loadedFile.height}px`}
            </SimpleText>
            <Button
              type="button"
              btnType="gray"
              icon="fas fa-trash"
              onClick={handleDelete}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <i className="fas fa-file" />

        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_40}>
          Você pode arrastar seus arquivos aqui.
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Formato aceito: PDF, PNG e JPG
        </SimpleText>
      </>
    );
  };

  const handleSendFile = () =>
    handleUploadFile(Number(idAtendimento), idProcedimento);

  return (
    <Dialog
      id="modal-upload-laudo"
      header="Upload do laudo"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
    >
      <div className="p-grid">
        <div id="convenio-logo-upload-container" className="p-col-12 p-grid">
          <Dropzone
            accept={accept}
            onDrop={handleLoadFile}
            maxFiles={1}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="p-col-12">
                <Button
                  className="p-col-align-start"
                  type="button"
                  btnType="tonal"
                  label="Escolha o arquivo"
                  icon="fas fa-plus"
                  disabled={loading}
                />

                <Divider />

                <input {...getInputProps()} />

                <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                  {renderDropzoneContent()}
                </div>
              </div>
            )}
          </Dropzone>
        </div>

        {loading && (
          <div className="p-col-8 p-px-0">
            <ProgressBar value={loadedFile.progress} showValue={false} />
          </div>
        )}

        {!!loadedFile && !loading && (
          <AlertBox
            className="p-py-0 p-p-px-2"
            visible={showAlert}
            text="Pressione “enviar” para iniciar o upload"
            onHide={() => setShowAlert(false)}
          />
        )}
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={() => close()}
            loading={loading}
            stretch
          />
          <Button
            type="button"
            label="Enviar"
            onClick={handleSendFile}
            loading={loading}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};
