import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import {
  ListaMateriaisPrescricao,
  ListaMedicamentoPrescricao,
  LoadPrescricao,
} from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { DialogFinalizarExecucao } from './components/DialogEncaminharExecucao/DialogEncaminharExecucao';
import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { MateriaisEdit } from './Edit/MateriaisEdit';
import { MateriaisRow } from './PanelContentMateriaisRow';
import { MedicamentosRow } from './PanelContentMedicamentosRow copy';

import './PanelContent.scss';

type PanelContentProps = {
  refetch?: () => void;
  item: LoadPrescricao;
  view?: boolean;
  isExecutada: boolean;
  setIsExecutada: (v: boolean) => void;
  setItem: (v: LoadPrescricao) => void;
};

type ContentNovaProps = Pick<PanelContentProps, 'item'> & {
  setIsExecutada: (v: boolean) => void;
  setItem: (v: LoadPrescricao) => void;
};

type ContentExecutadaProps = Pick<PanelContentProps, 'item' | 'view'> & {
  setIsExecutada: (v: boolean) => void;
};

export const PanelContent = ({
  item,
  view,
  isExecutada,
  setIsExecutada,
  setItem,
}: PanelContentProps) => {
  return isExecutada ? (
    <PanelContentExecutada
      view={!!view}
      item={item}
      setIsExecutada={setIsExecutada}
    />
  ) : (
    <PanelContentNova
      item={item}
      setIsExecutada={setIsExecutada}
      setItem={setItem}
    />
  );
};

const PanelContentExecutada = ({
  setIsExecutada,
  item,
  view,
}: ContentExecutadaProps) => {
  const { isTablet } = useSize();
  const dialogFinalizar = useDisclosure({ opened: false });

  const [materiais, setMateriais] = useState<ListaMateriaisPrescricao[]>(
    item?.prescricaoMateriais || [],
  );
  const [medicamentos, setMedicamentos] = useState<
    ListaMedicamentoPrescricao[]
  >(item?.prescricaoMedicamentos || []);

  const fetchMateriais = useCallback(() => {
    PrescricaoAPI.getPrescricaoMateriais(item.id).then(data => {
      setMateriais(data);
    });
  }, [item.id]);
  const fetchMedicamentos = useCallback(() => {
    PrescricaoAPI.getPrescricaoMedicamentos(item.id).then(data => {
      setMedicamentos(data);
    });
  }, [item.id]);

  useEffect(() => {
    Promise.all([fetchMateriais(), fetchMedicamentos()]);
  }, [fetchMateriais, fetchMedicamentos]);

  const hideActions = !!view;
  const isInativo = item.status === 'INATIVO';
  const isExecutado = item?.situacaoPrescricao === 'EXECUTADA';

  return (
    <div className={`prescricao-panel-content ${isInativo ? 'inativo' : ''}`}>
      <div className="p-d-flex p-gap-1 p-col-12 p-mb-1">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Data da ocorrência
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.PRIMARY}>
          {item.dataOcorrencia
            ? dayjs(item.dataOcorrencia).format('DD/MM/YYYY [às] HH:mm')
            : 'Nenhuma data registrada'}
        </SimpleText>
      </div>

      <SimpleText className="p-col-12" bold>
        Prescrição
      </SimpleText>

      {!!medicamentos.length &&
        medicamentos.map((medicamento, idx) => (
          <div
            key={idx}
            className="grid row p-d-flex p-ai-center p-col-12 p-p-0"
          >
            <SimpleText className="p-col-3 p-xl-4">
              {medicamento.descricao}
            </SimpleText>
            <div className="p-d-flex p-flex-column p-col-3 p-md-2 p-xl-1 p-px-1">
              {medicamento?.dataAplicacao ? (
                <span data-isInativo={isInativo} className="tag-outline">
                  Aplicado
                </span>
              ) : (
                <span data-isInativo={isInativo} className="tag-helper">
                  Não aplicado
                </span>
              )}
            </div>
            {!isTablet && (
              <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                  Posologia
                </SimpleText>
                <SimpleText>{item.posologia}</SimpleText>
              </div>
            )}
            {!isTablet && (
              <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>Via</SimpleText>
                <SimpleText>{item.via}</SimpleText>
              </div>
            )}
            <div className="p-d-flex p-flex-column p-gap-1 p-col-1">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
              <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                {medicamento.quantidade}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1 p-col-4 p-lg-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                className="p-text-truncate"
              >
                Data da aplicação
              </SimpleText>
              <SimpleText>
                {medicamento?.dataAplicacao
                  ? dayjs(medicamento.dataAplicacao).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </SimpleText>
            </div>
          </div>
        ))}

      <div className="p-col-12 p-d-flex p-ai-center p-p-0">
        <SimpleText className="p-col-6 p-pb-0" bold>
          Materiais
        </SimpleText>
      </div>

      {!!materiais.length &&
        materiais.map((item, idx) => (
          <div key={idx} className="row p-d-flex p-ai-center p-col-12 p-p-0">
            <SimpleText className="p-col-4 p-lg-5 p-text-climp-2">
              {item.nome}
            </SimpleText>
            <div className="p-col-3 p-md-4 p-px-1 p-d-flex p-justify-end">
              {item?.dataAplicacao ? (
                <span data-isInativo={isInativo} className="tag-outline">
                  Aplicado
                </span>
              ) : (
                <span data-isInativo={isInativo} className="tag-helper">
                  Não aplicado
                </span>
              )}
            </div>
            <div className="p-d-flex p-flex-column p-gap-1 p-col-2 p-md-1">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
              <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                {item.quantidade}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1 p-col-3 p-md-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                Data da aplicação
              </SimpleText>
              <SimpleText>
                {item.dataAplicacao
                  ? dayjs(item.dataAplicacao).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </SimpleText>
            </div>
          </div>
        ))}

      {item?.idEnfermeiroExecucao && (
        <>
          <SimpleText className="p-col-12 p-pb-0" bold>
            Atendido por
          </SimpleText>

          <div className="p-col-12 p-d-flex p-gap-1">
            <Avatar
              className="p-avatar-prescricao"
              label={item.nomeEnfermeiroExecucao}
            />
            <div className="row p-p-2 p-d-flex p-flex-column p-gap-1">
              <div className="p-d-flex p-flex-column">
                <div className="p-col-12 p-py-0 p-d-flex p-ai-center p-gap-1">
                  <SimpleText>{item.nomeEnfermeiroExecucao}</SimpleText>
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XXS}
                  >
                    ({item?.conselhoTissSigla} {item.numeroRegistro})
                  </SimpleText>
                </div>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                  className="p-col-12"
                >
                  {dayjs(item.dataOcorrencia).format(
                    '[Em] DD [de] MMM [de] YYYY [às] HH:mm ',
                  )}
                </SimpleText>
              </div>

              <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Observações
                </SimpleText>
                <SimpleText>{item?.observacao || '-'}</SimpleText>
              </div>
              <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Reações adversas
                </SimpleText>
                <SimpleText>{item?.reacaoAdversa || '-'}</SimpleText>
              </div>
              <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Justificativa para registro retroativo
                </SimpleText>
                <SimpleText>{item?.justificativaOcorrencia || '-'}</SimpleText>
              </div>
            </div>
          </div>
        </>
      )}

      {!hideActions && (
        <div className="p-col-12 p-d-flex p-ai-center p-gap-1">
          <Button
            label="Editar"
            btnType="gray"
            type="button"
            stretch
            onClick={() => setIsExecutada(false)}
          />

          {!isExecutado && (
            <Button
              label="Finalizar prescrição"
              onClick={() => dialogFinalizar.open()}
              stretch
            />
          )}
        </div>
      )}

      {dialogFinalizar.isOpen && (
        <DialogFinalizarExecucao {...dialogFinalizar} />
      )}
    </div>
  );
};

const PanelContentNova = memo(
  ({ setIsExecutada, setItem, item }: ContentNovaProps) => {
    const {
      agenda: { profissionalAtivo },
    } = useAppSelector((state: RootState) => state);

    const [exibirObservacoes, setObservacoes] = useState(!!item?.observacao);
    const [exibirReacoes, setReacoes] = useState(!!item?.reacaoAdversa);

    const editPrescricaoMateriais = useDisclosure({ opened: false });
    const modalDelete = useDisclosure({ opened: false });

    const defaultValues = useMemo(() => {
      return {
        dataOcorrencia: item?.dataOcorrencia || undefined,
        justificativaOcorrencia: item?.justificativaOcorrencia || '',
        observacao: item?.observacao || '',
        reacaoAdversa: item?.reacaoAdversa || '',
        prescricaoMedicamentos: item?.prescricaoMedicamentos || [],
        prescricaoMateriais: item?.prescricaoMateriais || [],
      };
    }, [item]);

    const form = useForm({
      defaultValues,
    });

    const { fields: prescricaoMedicamentos, replace: replaceMed } =
      useFieldArray({
        control: form.control,
        keyName: 'idx',
        name: 'prescricaoMedicamentos',
      });

    const { fields: prescricaoMateriais, replace } = useFieldArray({
      control: form.control,
      keyName: 'idx',
      name: 'prescricaoMateriais',
    });

    const presMedicamentos = form.watch('prescricaoMedicamentos');
    const presMateriais = form.watch('prescricaoMateriais');

    const hasMedicamentosChecked = presMedicamentos.some(
      (pres: any) => pres.aplicado,
    );
    const hasMateriaisChecked = presMateriais.some(
      (pres: any) => pres.aplicado,
    );

    const onSubmit = useCallback(
      (values: typeof defaultValues) => {
        const prescricaoMateriaisIds = values.prescricaoMateriais
          .filter((mat: any) => !!mat?.aplicado)
          .map(el => el.id);
        const prescricaoMedicamentosIds = values.prescricaoMedicamentos
          .filter((mat: any) => !!mat?.aplicado)
          .map(el => el.id);

        PrescricaoAPI.updatePrescricaoDadosExecucao(item.id, {
          dataOcorrencia: values.dataOcorrencia,
          justificativaOcorrencia: values.justificativaOcorrencia,
          observacao: values.observacao,
          reacaoAdversa: values.reacaoAdversa,
          prescricaoMateriaisIds,
          prescricaoMedicamentosIds,
        }).then(() => {
          setItem({ ...item, ...values });
          setIsExecutada(true);
        });
      },
      [item, setIsExecutada, setItem],
    );

    const fetchMateriais = useCallback(() => {
      PrescricaoAPI.getPrescricaoMateriais(item.id).then(data => {
        replace(
          data.map(pres => ({
            ...pres,
            aplicado: !!pres?.dataAplicacao,
          })),
        );
      });
    }, [item.id, replace]);

    const fetchMedicamentos = useCallback(() => {
      PrescricaoAPI.getPrescricaoMedicamentos(item.id).then(data => {
        replaceMed(
          data.map(pres => ({
            ...pres,
            aplicado: !!pres?.dataAplicacao,
          })),
        );
      });
    }, [item.id, replaceMed]);

    useEffect(() => {
      Promise.all([fetchMateriais(), fetchMedicamentos()]);
    }, [fetchMateriais, fetchMedicamentos]);

    useEffectSkipFirst(() => {
      if (!exibirReacoes) form.setValue('reacaoAdversa', '');
    }, [exibirReacoes]);

    useEffectSkipFirst(() => {
      if (!exibirObservacoes) form.setValue('observacao', '');
    }, [exibirObservacoes]);

    const handleDeleteAll = useCallback(() => {
      prescricaoMateriais.forEach(material => {
        PrescricaoAPI.deletePrescricaoMateriais(item.id, material.idMaterial, {
          hideToast: true,
        }).then(() => fetchMateriais());
      });
    }, [fetchMateriais, item.id, prescricaoMateriais]);

    return (
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="prescricao-panel-content"
        >
          <DataOcorrencia
            nameJustificativaRetroativa="justificativaOcorrencia"
            control={form.control}
            defaultValue={new Date()}
          />

          <SimpleText className="p-col-12" bold>
            Prescrição
          </SimpleText>

          {!!prescricaoMedicamentos.length ? (
            prescricaoMedicamentos.map((med, idx) => (
              <MedicamentosRow
                key={idx}
                med={med}
                idx={idx}
                prescricao={item}
              />
            ))
          ) : (
            <SimpleText className="p-col-12 p-text-center">
              Não há prescrições
            </SimpleText>
          )}

          <div className="p-col-12 p-d-flex p-ai-center p-p-0">
            <SimpleText className="p-col-6 p-pb-0" bold>
              Materiais
            </SimpleText>
            <div className="p-col-6 p-pb-0 p-d-flex p-jc-end">
              {!!prescricaoMateriais?.length && (
                <>
                  <Button
                    icon="fa fa-edit"
                    type="button"
                    onClick={() => editPrescricaoMateriais.open()}
                    btnType="ghost"
                  />
                  <Button
                    icon="fa fa-trash"
                    type="button"
                    onClick={() => modalDelete.open()}
                    btnType="ghost"
                  />
                </>
              )}
            </div>
          </div>

          {!!prescricaoMateriais.length ? (
            prescricaoMateriais.map((mat, idx) => (
              <MateriaisRow key={idx} mat={mat} idx={idx} />
            ))
          ) : (
            <SimpleText className="p-col-12 p-text-center">
              Nenhum material adicionado.
            </SimpleText>
          )}

          {exibirObservacoes && (
            <TextareaInputControlled
              control={form.control}
              className="p-col-12"
              name="observacao"
              label="Observações"
              maxLength={400}
              maxLengthSpan
            />
          )}

          <div className="p-col-12 p-py-0">
            <Button
              icon={!exibirObservacoes ? 'fa-plus' : 'fa-solid fa-minus'}
              label={
                !exibirObservacoes
                  ? 'Adicionar observações'
                  : 'Remover observações '
              }
              type="button"
              className={exibirObservacoes ? 'error' : ''}
              onClick={() => {
                setObservacoes(old => !old);
              }}
              btnType={!exibirObservacoes ? 'green-link' : 'danger-link'}
              stretch
            />
          </div>

          {exibirReacoes && (
            <TextareaInputControlled
              control={form.control}
              className="p-col-12"
              name="reacaoAdversa"
              label="Reações Adversas"
              maxLength={400}
              maxLengthSpan
            />
          )}

          <div className="p-col-12 p-py-0">
            <Button
              icon={!exibirReacoes ? 'fa-plus' : 'fa-solid fa-minus'}
              label={
                !exibirReacoes
                  ? 'Adicionar reações adversas'
                  : 'Remover reações adversas '
              }
              type="button"
              className={exibirReacoes ? 'error' : ''}
              onClick={() => {
                setReacoes(old => !old);
              }}
              btnType={!exibirReacoes ? 'green-link' : 'danger-link'}
              stretch
            />
          </div>

          <SimpleText className="p-col-12 p-pb-0" bold>
            Atendido por
          </SimpleText>

          <div className="p-col-12 p-d-flex p-ai-center p-gap-1">
            <div className="p-col">
              <Avatar
                className="p-avatar-prescricao"
                label={profissionalAtivo?.nome}
              />
            </div>
            <div className="p-col-11 p-d-flex p-gap-1">
              <Button
                label="Adicionar Materiais"
                btnType="outline"
                type="button"
                onClick={() => editPrescricaoMateriais.open()}
                stretch
              />
              <Button
                label="Aplicar"
                type="submit"
                stretch
                disabled={!hasMedicamentosChecked && !hasMateriaisChecked}
              />
            </div>
          </div>
        </form>

        {editPrescricaoMateriais.isOpen && (
          <MateriaisEdit
            item={form.getValues()}
            prescricaoId={item.id}
            refetchList={fetchMateriais}
            {...editPrescricaoMateriais}
          />
        )}

        {modalDelete.isOpen && (
          <ConfirmDialog
            visible={modalDelete.isOpen}
            header="Apagar materiais?"
            text="Você estará deletando todos os materiais inseridos. Deseja confirmar?"
            onConfirm={() => [handleDeleteAll(), modalDelete.close()]}
            confirmBtnType="danger"
            onHide={modalDelete.close}
          />
        )}
      </FormProvider>
    );
  },
);
