import { useCallback, useState, useEffect, useMemo } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';

const situacoesConjugais = [
  {
    id: 'DI',
    label: 'DIVORCIADO',
    value: 'DIVORCIADO',
  },

  {
    id: 'VI',
    label: 'VIUVO',
    value: 'VIUVO',
  },
  {
    id: 'SE',
    label: 'SEPARADO',
    value: 'SEPARADO',
  },
  {
    id: 'CA',
    label: 'CASADO',
    value: 'CASADO',
  },
  {
    id: 'SO',
    label: 'SOLTEIRO',
    value: 'SOLTEIRO',
  },
];

const formFields = {
  Nome: '',
  nomeResumido: '',
  religiao: '',
  nomeMae: '',
  nomePai: '',
  idPaisNaturalidade: '',
  estadoCivil: '',
  idGenero: '',
  idRacaCor: '',
  idEstadoNaturalidade: '',
  idCidadeNaturalidade: '',
  indicadorEstrangeiro: '',
  dataEntradaPais: '',
  passaporteNumero: '',
  idPaisEmissorPassaporte: '',
  passaporteDataValidade: '',
  passaporteDataEmissao: '',
  passaporteDataNaturalizacao: '',
  descCidade: '',
};

interface DadosPessoaisProps {
  handleActiveIndex(): void;
}

const DadosPessoais = ({ handleActiveIndex }: DadosPessoaisProps) => {
  const params = useParams();

  const { isProfessionalHealth } = useSelectProfileCreateUser();
  const { idUser } = useCreateUser();

  const [unknownFather, setUnknownFather] = useState<any>();
  const [racas, setRacas] = useState<any>();
  const [generos, setGeneros] = useState<any>();
  const [paises, setPaises] = useState<any>();
  const [estados, setEstados] = useState<any>();
  const [cidades, setCidades] = useState<any>();
  const [usuario, setUsuario] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [countryId, setCountryId] = useState<number | null>(null);

  const useFormMethods = useForm({});

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const handleSave = async (data: any) => {
    let id = null;

    if (params.id) {
      id = params.id;
    } else {
      id = idUser;
    }

    const isEmpty = Object.values(data).every(
      x => x === null || x === undefined,
    );

    if (isEmpty) {
      handleActiveIndex();
    } else {
      const payload = {
        ...data,
        idEmpresa: usuario.idEmpresa,
      };

      let res: any = undefined;

      if (isProfessionalHealth) {
        res = await ProfissionalAPI.updateProfissional(
          'dadosPessoais',
          idUser,
          payload,
        );
      } else {
        res = await UsuarioAPI.updateUsuario('dadosPessoais', id, payload);
      }

      if (res?.status === 200) {
        handleActiveIndex();
      }
    }
  };

  const handleCidades = useCallback(async estadoId => {
    const cidade: any = await UtilsAPI.GetCidades(estadoId);

    setCidades(cidade);
  }, []);

  const fetchUtilsData = useCallback(async () => {
    return new Promise(async resolve => {
      const [racaCor, paises, estados, generos] = await Promise.all([
        UtilsAPI.GetRacaCor(),
        UtilsAPI.GetPaises(),
        UtilsAPI.GetEstados(),
        UtilsAPI.GetGeneros(),
      ]);

      setGeneros(generos);
      setEstados(estados);
      setPaises(paises);
      setRacas(racaCor);

      resolve({ racaCor, estados, paises, generos });
    });
  }, []);

  const getDadosPessoaisAction = useCallback(() => {
    if (isProfessionalHealth) return ProfissionalAPI.getProfissional;

    return UsuarioAPI.getUser;
  }, [isProfessionalHealth]);

  function formatDate(date: string) {
    if (!date) return null;

    const dateFormmated = date.replace('-', '/');

    return new Date(dateFormmated);
  }

  const load = useCallback(async () => {
    if (!idUser) return;
    const getDadosPessoais = getDadosPessoaisAction();

    const dadosPessoais = await getDadosPessoais(idUser, 'dadosPessoais');

    if (dadosPessoais !== undefined) {
      setUsuario(dadosPessoais);

      setUnknownFather(dadosPessoais?.nomeMae && !dadosPessoais?.nomePai);

      await fetchUtilsData();

      const selectedEstadoCivil = situacoesConjugais?.find((e: any) => {
        return e.id === dadosPessoais?.estadoCivil;
      });

      if (dadosPessoais?.idEstadoNaturalidade) {
        handleCidades(dadosPessoais?.idEstadoNaturalidade);
      }

      setCountryId(dadosPessoais?.idPaisNaturalidade);

      setValue('nomeSocial', dadosPessoais?.nomeSocial);
      setValue('nomeResumido', dadosPessoais?.nomeResumido);
      setValue('idRacaCor', dadosPessoais?.idRacaCor);
      setValue('idGenero', dadosPessoais?.idGenero);
      setValue('nomeMae', dadosPessoais?.nomeMae);
      setValue('nomePai', dadosPessoais?.nomePai);
      setValue('idPaisNaturalidade', dadosPessoais?.idPaisNaturalidade);
      setValue('idEstadoNaturalidade', dadosPessoais?.idEstadoNaturalidade);
      setValue('idCidadeNaturalidade', dadosPessoais?.idCidadeNaturalidade);
      setValue('estadoCivil', selectedEstadoCivil?.value);
      setValue(
        'paiDesconhecido',
        dadosPessoais?.nomeMae && !dadosPessoais?.nomePai,
      );
      setValue('dataEntradaPais', formatDate(dadosPessoais?.dataEntradaPais));
      setValue('descCidade', dadosPessoais?.descCidade);

      setValue('passaporteNumero', dadosPessoais?.documentos?.passaporteNumero);
      setValue(
        'idPaisEmissorPassaporte',
        dadosPessoais?.documentos?.idPaisEmissorPassaporte,
      );
      setValue(
        'passaporteDataValidade',
        formatDate(dadosPessoais?.documentos?.passaporteDataValidade),
      );
      setValue(
        'passaporteDataEmissao',
        formatDate(dadosPessoais?.documentos?.passaporteDataEmissao),
      );
      setValue(
        'passaporteDataNaturalizacao',
        formatDate(dadosPessoais?.documentos?.passaporteDataNaturalizacao),
      );

      setLoading(false);
    }
  }, [idUser, fetchUtilsData, getDadosPessoaisAction, handleCidades, setValue]);

  useEffect(() => {
    load();
  }, [load]);

  const racasOptions = useMemo(
    () =>
      racas?.map((raca: any) => ({
        label: raca?.descricao,
        value: raca.id,
      })),
    [racas],
  );

  const generosOptions = useMemo(
    () =>
      generos?.map((genero: any) => ({
        label: genero?.descGenero,
        value: genero.id,
      })),
    [generos],
  );

  const paisesOptions = useMemo(
    () =>
      paises?.map((pais: any) => ({
        label: pais?.descricao,
        value: pais.id,
      })),
    [paises],
  );

  const estadosOptions = useMemo(
    () =>
      estados?.map((estado: any) => ({
        label: estado?.nome,
        value: estado.id,
      })),
    [estados],
  );

  const cidadesOptions = useMemo(
    () =>
      cidades?.map((cidade: any) => ({
        label: cidade?.nome,
        value: cidade.id,
      })),
    [cidades],
  );

  const BrazilLocationForm = useCallback(
    () => (
      <>
        <DropdownControlled
          control={control}
          name="idEstadoNaturalidade"
          label="Estado de naturalidade"
          className="p-col-12 p-sm-6"
          options={estadosOptions}
          customOnChange={value => handleCidades(value)}
          disabled={countryId !== 0}
        />
        <DropdownControlled
          control={control}
          name="idCidadeNaturalidade"
          label="Cidade natal"
          className="p-col-12 p-sm-6"
          options={cidadesOptions}
          errorMsg={errors.idCidadeNaturalidade?.message}
          disabled={!cidadesOptions?.length}
        />
      </>
    ),
    [
      cidadesOptions,
      control,
      countryId,
      errors.idCidadeNaturalidade?.message,
      estadosOptions,
      handleCidades,
    ],
  );

  const ForeignLocationForm = useCallback(
    () => (
      <>
        <FormInput
          name="descCidade"
          label="Cidade"
          placeholder="Digite a cidade"
          className="p-col-12"
          maxLength={40}
        />

        <FormInput
          name="passaporteNumero"
          label="Passaporte"
          placeholder="Digite o número do passaporte"
          className="p-col-12 p-sm-6"
          maxLength={40}
        />

        <DropdownControlled
          control={control}
          name="idPaisEmissorPassaporte"
          label="País emissor"
          placeholder="Selecione o País"
          className="p-col-12 p-sm-6"
          options={paisesOptions}
        />

        <CalendarInputControlled
          control={control}
          name="passaporteDataValidade"
          label="Data de validade"
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-sm-6"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
        />
        <CalendarInputControlled
          control={control}
          name="passaporteDataEmissao"
          label="Data de emissão"
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-sm-6"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
        />
        <CalendarInputControlled
          control={control}
          name="dataEntradaPais"
          label="Data de entrada"
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-sm-6"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
        />
        <CalendarInputControlled
          control={control}
          name="dataEntradaPais"
          label="Data de entrada"
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-sm-6"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
        />
        <CalendarInputControlled
          control={control}
          name="passaporteDataNaturalizacao"
          label="Data da naturalização"
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-sm-6"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
        />
      </>
    ),
    [control, paisesOptions],
  );

  const LocationForm = useCallback(() => {
    if (countryId === null || countryId === 0) {
      return <BrazilLocationForm />;
    }

    return <ForeignLocationForm />;
  }, [BrazilLocationForm, ForeignLocationForm, countryId]);

  return (
    <FormProvider {...useFormMethods}>
      <form
        className="p-grid"
        onSubmit={handleSubmit(handleSave)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        {loading ? (
          <div className="loadingdados">
            <GridListLoading />
          </div>
        ) : (
          <>
            <div className="p-grid p-col-12 p-lg-6 p-p-0">
              <SimpleText className="p-col-12">Informações pessoais</SimpleText>

              <FormInput
                name="nomeSocial"
                label="Nome social / Apelido"
                placeholder="Digite o nome social ou apelido"
                className="p-col-12"
                hideTextErrorSpace
                maxLength={50}
              />

              <FormInput
                hideTextErrorSpace
                name="nomeResumido"
                label="Nome resumido"
                placeholder="Digite o nome resumido"
                className="p-col-12"
                maxLength={15}
              />

              <DropdownControlled
                control={control}
                name="idGenero"
                label="Gênero"
                className="p-col-12 p-sm-6"
                options={generosOptions}
              />

              <DropdownControlled
                control={control}
                name="idRacaCor"
                label="Raça/cor"
                className="p-col-12 p-sm-6"
                options={racasOptions}
              />

              <DropdownControlled
                control={control}
                name="estadoCivil"
                label="Estado civil"
                className="p-col-12"
                options={situacoesConjugais}
              />

              <div className="p-col-12">
                <Separator />
              </div>

              <SimpleText className="p-col-12">Familiares</SimpleText>

              <FormInput
                hideTextErrorSpace
                name="nomeMae"
                label="Nome da mãe"
                placeholder="Digite o nome da mãe"
                className="p-col-12"
                maxLength={50}
              />

              <FormInput
                hideTextErrorSpace
                name="nomePai"
                label="Nome do pai"
                placeholder="Digite o nome do pai"
                className="p-col-12 p-pt-0"
                maxLength={50}
              />

              <div className="p-col-12">
                <CheckboxControlled
                  control={control}
                  name="paiDesconhecido"
                  label="Pai desconhecido"
                  className="p-col-12"
                />
              </div>
            </div>

            <div className="p-col-12 p-lg-6 p-px-0 p-mt-3 p-mt-lg-0">
              <SimpleText className="p-col-12">Naturalidade</SimpleText>

              <DropdownControlled
                control={control}
                name="idPaisNaturalidade"
                label="País de naturalidade"
                className="p-col-12"
                options={paisesOptions}
                customOnChange={v => [setCountryId(v)]}
              />

              <div className="p-grid">
                <LocationForm />
              </div>
            </div>

            <div className="p-col-12 p-lg-6">
              <Button
                btnType="tonal"
                label="Salvar e continuar"
                type="submit"
                onClick={handleSubmit(handleSave)}
                stretch
                loading={isSubmitting}
              />
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default DadosPessoais;
