import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { DialogExamesPlanoCuidado } from './Dialogs/DialogExames';

import './PlanoCuidadoItem.scss';

export const PlanoCuidadoExamesItem = ({
  handleDelete,
  onEdit,
  idx,
  item,
  isNotAtendimentoCorrente,
  ...props
}: any) => {
  const dialogAgendamento = useDisclosure({ opened: false });
  const form = useFormContext();

  const [isOpen, setIsOpen] = useState(false);

  const isRealizado =
    item?.exames?.some(
      (itemExames: any) => itemExames.statusRealizacao === 'REALIZADO',
    ) ?? false;

  const hasError = !!form.formState.submitCount && !item.exames?.length;

  return (
    <div
      className={`content-item ${!isOpen ? 'closed' : ''} ${
        hasError ? 'acc-error' : ''
      }`}
    >
      <div className="content-item-header p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_60" fontSize={FONT_SIZE.XXS}>
            Atividade
          </SimpleText>
          <SimpleText medium>Exames</SimpleText>
        </div>

        <div className="p-d-flex p-gap-2">
          <Button
            icon="fas fa-calendar-plus"
            btnType="ghost"
            onClick={() => dialogAgendamento.open()}
            disabled={isNotAtendimentoCorrente}
          />
          <Button
            icon="fas fa-edit"
            btnType="ghost"
            onClick={onEdit}
            disabled={isNotAtendimentoCorrente || isRealizado}
          />
          <Button
            icon="fas fa-trash"
            btnType="ghost"
            onClick={() => handleDelete()}
            disabled={isNotAtendimentoCorrente}
          />
          <Button
            icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            onClick={() => setIsOpen(old => !old)}
            btnType="ghost"
          />
        </div>
      </div>

      <div className="p-grid content-item-body" aria-expanded={!isOpen}>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome
          </SimpleText>
          <SimpleText>{item?.servicoTuss.nome}</SimpleText>
        </div>
        <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Quantidade
          </SimpleText>
          <SimpleText>{item?.quantidade}</SimpleText>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Recorrência
          </SimpleText>
          <SimpleText>{item?.recorrencia} dias</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Execução
          </SimpleText>
          <SimpleText>{item?.execucao}</SimpleText>

          <Divider className="p-col-12" layout="horizontal" />

          {!!item?.exames && (
            <SimpleText className="p-col-12" medium>
              Data programada
            </SimpleText>
          )}

          {!!item?.exames && (
            <div className="p-col-12 p-d-flex" style={{ overflowX: 'scroll' }}>
              {item.exames.map(({ data }: any, index: number) => (
                <div
                  key={index}
                  className="p-col-3 p-jc-between p-d-flex p-gap-3"
                >
                  <div className="data-programada p-d-flex p-flex-column p-gap-2 p-jc-center">
                    <SimpleText>
                      <i className="fas fa-calendar p-mr-2" />
                      {dayjs(data).format('DD/MM/YYYY')}
                    </SimpleText>
                  </div>

                  <Divider className="p-mx-2" layout="vertical" />
                </div>
              ))}
            </div>
          )}

          {!!item?.exames && (
            <Divider className="p-col-12" layout="horizontal" />
          )}

          <div className="p-d-flex p-gap-2 p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Adicionado por <b>{item.usuarioAtualizacao?.nome}</b> em{' '}
              {dayjs(item.dataAtualizacao).format('DD [de] MMMM [de] YYYY')}
            </SimpleText>
            <i className="fas fa-lock-open lock-green" />
          </div>
        </div>
      </div>

      {dialogAgendamento.isOpen && (
        <DialogExamesPlanoCuidado idx={idx} {...props} {...dialogAgendamento} />
      )}
    </div>
  );
};
