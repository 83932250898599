import React, { useCallback, useState } from 'react';

import DocumentosAPI from 'src/APIs/ProntuarioAPI/DocumentosAPI/DocumentosAPI';
import ImpressosAPI from 'src/APIs/ProntuarioAPI/ImpressosAPI/ImpressoaAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { EmptyData } from '../../components/EmptyData';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import InativarImpresso from './InativarImpresso';
import DesktopRow from './List/DesktopRow';
import MobileRow from './List/MobileRow';
import VisualizarInativacaoImpresso from './VisualizarInativacaoImpresso';

import './HistoricoImpressos.scss';

export default function HistoricoImpressos() {
  const [reload, setReload] = useState(0);
  const [selectedImpresso, setSelectedImpresso] = useState<any>(null);
  const [showList, setShowList] = useState(true);
  const { selectedAtendimento } = useHistoricoAtendimento();

  const idAtendimento = selectedAtendimento?.idAtendimento;
  const vizualizarInativacaoDisclosure = useDisclosure({ opened: false });
  const inativarDisclosure = useDisclosure({ opened: false });

  const fetchApi = useCallback(
    async (params: any): Promise<any> => {
      const responce = await ImpressosAPI.getHistoricoImpressos(idAtendimento, {
        ...params,
        somenteAtendimento: true,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
      });

      if (responce?.totalCount < 1) {
        setShowList(false);
      }
      return responce;
    },
    [idAtendimento],
  );

  const onInativar = (impresso: any) => {
    setSelectedImpresso(impresso);
    inativarDisclosure.open();
  };

  const onVisualizarInativacao = (impresso: any) => {
    setSelectedImpresso(impresso);
    vizualizarInativacaoDisclosure.open();
  };

  const renderRow = (impresso: any, isMobile: boolean) => {
    const commomProps = {
      impresso,
      onInativar,
      onVisualizarInativacao,
    };

    if (isMobile) return <MobileRow {...commomProps} />;

    return <DesktopRow {...commomProps} />;
  };

  return (
    <div id="hitorico-impressos">
      {showList ? (
        <SearchList.Root fetchApi={fetchApi}>
          <SearchList.BorderContainer>
            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>
      ) : (
        <div className="content-empty-row">
          <EmptyData />
        </div>
      )}

      {inativarDisclosure.isOpen && (
        <InativarImpresso
          {...inativarDisclosure}
          impresso={selectedImpresso}
          idAtendimento={idAtendimento}
          reload={reload}
          setReload={setReload}
        />
      )}
      {vizualizarInativacaoDisclosure.isOpen && (
        <VisualizarInativacaoImpresso
          {...vizualizarInativacaoDisclosure}
          impresso={selectedImpresso}
        />
      )}
    </div>
  );
}
