import { useState } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import { DialogNovaPerguntaForm } from './DialogNovaPerguntaForm';
import { DialogNovaPerguntaFormParams } from './DialogNovaPerguntaForm';
import { DialogNovaPerguntaVincularForm } from './DialogNovaPerguntaVincularForm';

export type DialogNovaPerguntaStateProps = Pick<
  DialogNovaPerguntaFormParams,
  'from'
> & {
  item?: IPerguntaFormularioItem;
  idFormularioDinamico?: number;
  tab?: number | null;
};

type Props = DisclosureType<DialogNovaPerguntaStateProps> & {
  handleReload(): void;
};

export const DialogNovaPergunta = ({
  isOpen,
  close,
  state,
  handleReload,
}: Props) => {
  const [tab, setTab] = useState(state?.tab || 0);
  const [item, setItem] = useState<IPerguntaFormularioItem | null>(
    state?.item || null,
  );

  const handleSetPergunta = (item: IPerguntaFormularioItem) => {
    setItem(item);
    handleReload();

    if (item?.idFormularioDinamico) {
      setTab(1);
    } else {
      close();
    }
  };

  return (
    <Dialog
      header={item ? 'Editar pergunta' : 'Nova pergunta'}
      visible={isOpen}
      onHide={close}
      blockScroll
      maximizedMobileSize
      style={{
        width: '796px',
      }}
    >
      <Tab
        clean
        setActiveIndex={tab}
        initialTabIndex={tab}
        onTabChange={e => setTab(e.index)}
        values={[
          {
            label: 'Pergunta',
            content: (
              <DialogNovaPerguntaForm
                item={item}
                from={state?.from || null}
                close={close}
                handleSetPergunta={handleSetPergunta}
                idFormularioDinamico={state?.idFormularioDinamico || null}
              />
            ),
          },
          {
            label: 'Vincular',
            disabled: !item?.idFormularioDinamico || !item,
            content: (
              <DialogNovaPerguntaVincularForm
                close={close}
                perguntaValues={item!}
                handleReload={handleReload}
              />
            ),
          },
        ]}
      />
    </Dialog>
  );
};
