import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/login';

export const sendRecuperarSenha = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/enviarCodigo`,
    data: payload,
    return: true,
    headers: {
      Authorization: '',
    },
    ...options,
  });
};

const RecuperarSenhaAPI = {
  sendRecuperarSenha,
};

export default RecuperarSenhaAPI;
