import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldTipoFormularioProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldTipoFormulario = ({
  name = 'tipo',
  label,
  ...rest
}: FieldTipoFormularioProps) => {
  const { control } = useFormContext();
  const [tipoFormulario, setTipoFormulario] = useState<any[]>([]);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async () => {
    try {
      const response =
        await FormularioDinamicoAPI.loadFormularioDinamicoTiposFormulario({
          throwError: true,
        });

      setTipoFormulario(response);
    } catch {
      setTipoFormulario([]);
    } finally {
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = tipoFormulario.map(item => ({
      label: item?.descricao,
      value: item,
    }));

    return data;
  }, [tipoFormulario]);

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      control={control}
      name={name}
      dataKey="codigo"
      label={label}
      options={options}
      filter={false}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
