import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const FeedbackAgradecimento = ({
  close,
}: Pick<DisclosureType, 'close'>) => {
  return (
    <div className="p-d-flex p-flex-column p-gap-3">
      <SimpleText
        className="p-line-height-2 p-text-center"
        fontColor={FONT_COLOR.COLOR_40}
        fontSize={FONT_SIZE.XS}
      >
        Sua opinião é muito importante para continuarmos melhorando o nosso
        atendimento!
      </SimpleText>

      <Divider layout="horizontal" className="p-p-0 p-m-0" />

      <div className="p-col-12 p-d-flex p-flex-row p-gap-2 p-ai-center">
        <Button
          label="Fechar"
          type="button"
          btnType="ghost"
          onClick={() => close()}
          stretch
        />
      </div>
    </div>
  );
};
