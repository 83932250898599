import React from 'react';

import HelpConfigSubMenu from './HelpConfigSubMenu/HelpConfigSubMenu';
import LogoutConfigSubMenu from './LogoutConfigSubMenu/LogoutConfigSubMenu';
import OtherConfigSubMenu from './OtherConfigSubMenu/OtherConfigSubMenu';
import PasswordResetSubMenu from './PasswordResetSubMenu/PasswordResetSubMenu';
import SignConfigSubMenu from './SignConfigSubMenu/SignConfigSubMenu';
import ThemeConfigSubMenu from './ThemeConfigSubMenu/ThemeConfigSubMenu';
import './ConfigSubMenu.scss';

interface ConfigSubMenuProps {
  canSign: boolean;
}

const ConfigSubMenu = ({ canSign }: ConfigSubMenuProps) => {
  return (
    <div className={'ConfigSubMenu'}>
      <>
        <OtherConfigSubMenu />
        <HelpConfigSubMenu />
        <PasswordResetSubMenu />
        {canSign && <SignConfigSubMenu />}
        <ThemeConfigSubMenu />
        <LogoutConfigSubMenu />
      </>
    </div>
  );
};

export default ConfigSubMenu;
