import { useCallback, useEffect, useState, useRef } from 'react';

import { ToggleButton } from 'primereact/togglebutton';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Mention from '../../../components/Mention/Mention';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ModalModelo from 'src/pages/Emed/Prontuario/components/ModalModelo/ModalModelo';
import { ModalNovoModelo } from 'src/pages/Emed/Prontuario/components/ModalNovoModelo/ModalNovoModelo';

import './HistoricoPessoal.scss';

interface HistoricoPessoalProps {
  prontuario: any;
  prontuarioPersistente: any;
  campos: any[];
  getCampos: any;
  updateCamposProntuario: (campos: any) => void;
  updateProntuarioPersistente: (newValues: any) => void;
  ndnHistoricoPessoal: any;
  selectedSubjetivos: any;
}

function HistoricoPessoal({
  prontuario,
  prontuarioPersistente,
  campos,
  getCampos,
  updateCamposProntuario,
  updateProntuarioPersistente,
  ndnHistoricoPessoal,
  selectedSubjetivos,
}: HistoricoPessoalProps) {
  const { state } = useLocation();
  const hasLoaded = useRef(false);
  const idAtendimento = state?.idAtendimento;
  const { agenda } = useAppSelector((state: RootState) => state);
  const { modelos, getModelos, setModelos } = useAtendimento();

  const validationSchema = Yup.object().shape({
    valorCampoProntuario: Yup.string(),
  });

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [valorCampoProntuario, setValorCampoProntuario] = useState<string>('');

  const [campoHistoricoPessoal, setCampoHistoricoPessoal] = useState<any>({});
  const [modelosHistoricoPessoal, setModelosHistoricoPessoal] = useState<any[]>(
    [],
  );
  const [checked, setChecked] = useState<boolean>(
    campoHistoricoPessoal.exibicaoCamposModelo === 'FIXO' ? true : false,
  );

  const modalDisclosure = useDisclosure({ opened: false });

  const handleOnBlur = useCallback(
    async (data: any) => {
      const saveAction = (payload: any) => {
        return UtilsAPI.putCampos(Number(idAtendimento), payload);
      };

      const payload = {
        idAtendimento: Number(idAtendimento),
        camposModeloProntuario: 'HISTORICO_PESSOAL',
        valorCampoProntuario: data,
        idPaciente: Number(prontuario?.atendimento?.paciente?.id),
        idProfissionalSaude: agenda.profissionalAtivo.id,
      };

      updateCamposProntuario({ HISTORICO_PESSOAL: data });

      await saveAction(payload);

      updateProntuarioPersistente({
        historicoPessoal: data,
        dataAtualizacao: new Date(),
      });
    },
    [
      agenda.profissionalAtivo.id,
      idAtendimento,
      prontuario?.atendimento?.paciente?.id,
      updateCamposProntuario,
      updateProntuarioPersistente,
    ],
  );

  const loadModeloPadrao = useCallback(() => {
    if (prontuarioPersistente.historicoPessoal?.length > 0) {
      setValorCampoProntuario(prontuarioPersistente.historicoPessoal);
    } else {
      modelos?.forEach(modelo => {
        if (modelo.campo === 'HISTORICO_PESSOAL' && modelo.padrao) {
          setValorCampoProntuario(modelo.texto + '\n');
          handleOnBlur(modelo.texto + '\n');
        }
      });
    }
  }, [modelos, prontuarioPersistente.historicoPessoal, handleOnBlur]);

  const loadModelosHistoricoPessoal = useCallback(() => {
    const modelosCampo: any[] = [];
    modelos?.forEach(modelo => {
      if (modelo.campo === 'HISTORICO_PESSOAL') {
        modelosCampo.push(modelo);
      }
    });
    setModelosHistoricoPessoal(modelosCampo);
  }, [modelos]);

  const passData = useCallback(
    (data: any) => {
      setValorCampoProntuario(prev => prev + data.texto + '\n');
      handleOnBlur(valorCampoProntuario + data.texto + '\n');
    },
    [handleOnBlur, valorCampoProntuario],
  );

  useEffect(() => {
    const ndn = () => {
      if (
        (valorCampoProntuario?.length < 1 &&
          selectedSubjetivos.includes('HISTORICO_PESSOAL')) ||
        (valorCampoProntuario === null &&
          selectedSubjetivos.includes('HISTORICO_PESSOAL'))
      ) {
        setValorCampoProntuario('Nada digno de nota');
        handleOnBlur('Nada digno de nota');
      }
    };

    ndnHistoricoPessoal.current = ndn;
  }, [
    handleOnBlur,
    ndnHistoricoPessoal,
    selectedSubjetivos,
    valorCampoProntuario,
  ]);

  useEffect(() => {
    setCampoHistoricoPessoal(
      campos.filter(campo => campo.funcionalidade === 'HISTORICO_PESSOAL')[0],
    );
  }, [campos]);

  useEffect(() => {
    if (!hasLoaded.current) {
      loadModeloPadrao();
      hasLoaded.current = true;
    }
  }, [loadModeloPadrao]);

  useEffect(() => {
    loadModelosHistoricoPessoal();
    setChecked(
      campoHistoricoPessoal.exibicaoCamposModelo === 'FIXO' ? true : false,
    );
  }, [campoHistoricoPessoal, getModelos, loadModelosHistoricoPessoal]);

  const reloadCampos = () => {
    if (campoHistoricoPessoal.exibicaoCamposModelo === 'FIXO') {
      campoHistoricoPessoal.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoHistoricoPessoal.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoHistoricoPessoal.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }
    const exibixaoCampo =
      campoHistoricoPessoal.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoHistoricoPessoal.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const isRequired = campoHistoricoPessoal.obrigatorio;

  return (
    <>
      <div className="p-d-flex p-jc-between">
        <div className="p-as-end">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Historico Pessoal
          </SimpleText>
          {isRequired && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div className="p-d-flex modelos">
          <Button
            btnType="green-link"
            label="Modelo"
            iconPos="right"
            icon="pi pi-chevron-down"
            overlay={
              <ModalModelo
                onOpenModal={modalDisclosure.open}
                modelos={modelosHistoricoPessoal}
                passData={passData}
                valorCampoProntuario={valorCampoProntuario}
                setValorCampoProntuario={setValorCampoProntuario}
              />
            }
          />

          <ToggleButton
            checked={checked}
            className={`p-px-4 p-py-1 pin ${isRequired ? 'pin-disabled' : ''}`}
            onLabel={''}
            offLabel={''}
            onChange={e =>
              !isRequired ? [handleFixar(), setChecked(e.value)] : null
            }
            onIcon="fa fa-thumbtack"
            offIcon="fa fa-thumbtack"
          />
        </div>
      </div>
      <div className="text-area p-pb-1">
        <form id="form-historicoPessoal" className="p-mt-3">
          <Mention
            valorCampoProntuario={valorCampoProntuario}
            setValorCampoProntuario={setValorCampoProntuario}
            handleOnBlur={handleOnBlur}
          />
        </form>
      </div>

      {modalDisclosure.isOpen && (
        <ModalNovoModelo
          valorCampoProntuario={valorCampoProntuario}
          setValorCampoProntuario={setValorCampoProntuario}
          tipoCampo="HISTORICO_PESSOAL"
          {...modalDisclosure}
        />
      )}
    </>
  );
}

export default HistoricoPessoal;
