import { useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';
import { InferType } from 'yup';

import { dateFormats, validationSchema } from './utils';

import { Button } from 'src/components/_UI';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';

import { FieldTipoRespostas } from '../FieldTipoRespostas/FieldTipoRespostas';
import { FieldTipoRespostasAdicional } from '../FieldTipoRespostas/FieldTipoRespostasAdicional';
import { FieldTipoRespostasAdicionalValidacao } from '../FieldTipoRespostas/FieldTipoRespostasAdicionalValidacao';

import './style.scss';

type FormOpcoesType = {
  descricao: string;
  pontuacao: string;
};
export type FormNovaPergunta = InferType<typeof validationSchema>;
export type DialogNovaPerguntaFormParams = {
  from?: 'ACERVO' | null;
  close(): void;
  item: IPerguntaFormularioItem | null;
  handleSetPergunta(item: IPerguntaFormularioItem): void;
  idFormularioDinamico: number | null;
};

export const DialogNovaPerguntaForm = ({
  close,
  from,
  item,
  handleSetPergunta,
  idFormularioDinamico,
}: DialogNovaPerguntaFormParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingOption, setIsEditingOption] = useState<{
    idx: number | null;
    state: boolean;
  }>({ idx: null, state: false });

  const defaultItemValues = item || {};

  const form = useForm<FormNovaPergunta>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...defaultItemValues,
      tipoResposta: {
        codigo: item?.tipoResposta || '',
      },
      ...(item?.tipoRespostaAdicional && {
        tipoRespostaAdicional: {
          codigo: item?.tipoRespostaAdicional,
        },
      }),
      ...(item?.tipoValidacaoRespostaAdicional && {
        tipoValidacaoRespostaAdicional: {
          codigo: item?.tipoValidacaoRespostaAdicional,
        },
      }),
      acervoPerguntas: from === 'ACERVO' ? true : !!item?.acervoPerguntas,
      opcoes: item?.opcoes || [],
    },
  });

  const { fields, append, remove, update } = useFieldArray<FormNovaPergunta>({
    control: form.control,
    name: 'opcoes',
  });

  const [tipoResposta] = form.watch(['tipoResposta']);
  const [opcao, pontuacao] = form.watch(['opcao', 'pontuacao']);
  const [respostaAdicional, tipoRespostaAdicional] = form.watch([
    'respostaAdicional',
    'tipoRespostaAdicional',
  ]);

  const errorOpcoes = form.formState.errors.opcoes as
    | { message: string }
    | undefined;

  const onEditOpcao = (item: FormOpcoesType, idx: number) => {
    form.setValue('opcao', item.descricao);
    form.setValue('pontuacao', item.pontuacao);
    setIsEditingOption({ idx, state: true });
  };

  const handleEditOpcao = () => {
    if (isEditingOption.idx !== null) {
      update(isEditingOption.idx, { descricao: opcao!, pontuacao: pontuacao! });
      setIsEditingOption({ idx: null, state: false });
      form.setValue('opcao', '');
      form.setValue('pontuacao', '');
    }
  };

  const handleAddOpcao = () => {
    append({ descricao: opcao!, pontuacao: pontuacao! });
    form.setValue('opcao', '');
    form.setValue('pontuacao', '');
  };

  const onSubmit = async (v: FormNovaPergunta) => {
    setIsLoading(true);

    const payload = {
      ...v,
      idFormularioDinamico: idFormularioDinamico,
      tipoResposta: v.tipoResposta.codigo,
      tipoRespostaAdicional: v.tipoRespostaAdicional?.codigo,
      tipoValidacaoRespostaAdicional: v.tipoValidacaoRespostaAdicional?.codigo,
      ...(item && {
        id: item.id,
      }),
      ...(item?.idFormularioDinamicoPergunta && {
        idFormularioDinamicoPerguntaVinculada:
          item.idFormularioDinamicoPergunta,
      }),
      ...(item?.idFormularioDinamico && {
        idFormularioDinamico: item.idFormularioDinamico,
      }),
    };

    const performUpdate = async () => {
      const response =
        await PerguntaFormularioDinamicoAPI.updatePerguntaFormulario(
          payload as any,
          { throwError: true },
        );
      handleSetPergunta(response);
      close();
    };

    const performCreate = async () => {
      const response =
        await PerguntaFormularioDinamicoAPI.createPerguntaFormulario(
          payload as any,
          { throwError: true },
        );
      handleSetPergunta(response);
    };

    try {
      if (item) {
        await performUpdate();
      } else {
        await performCreate();
      }
    } catch (error) {
      console.error('Erro ao submeter formulário:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        id="dialog-nova-pergunta"
        className="p-d-flex p-flex-wrap"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <TextareaInputControlled
          control={form.control}
          label="Enunciado"
          sublabel="(Obrigatório)"
          name="enunciado"
          className="p-col-12 p-sm-6"
          maxLength={500}
          maxLengthSpan
          disabled={isEditingOption.state}
        />
        <TextareaInputControlled
          control={form.control}
          label="Orientação de preenchimento"
          name="orientacao"
          className="p-col-12 p-sm-6"
          maxLength={500}
          maxLengthSpan
          disabled={isEditingOption.state}
        />
        <FieldTipoRespostas
          name="tipoResposta"
          label="Tipo de resposta"
          sublabel="(Obrigatório)"
          className="p-col-12 p-sm-6"
          disabled={isEditingOption.state}
        />
        {['MULTIPLA_ESCOLHA'].includes(tipoResposta.codigo) && (
          <FormInput
            name="quantidadeOpcoesObrigatorias"
            label="Quantidade de opções obrigatórias"
            className="p-col-12 p-sm-6"
            type="number"
            max={5}
            disabled={isEditingOption.state}
          />
        )}
        {['DATA'].includes(tipoResposta.codigo) && (
          <DropdownControlled
            control={form.control}
            name="formatoData"
            label="Formato da data"
            sublabel="(Obrigatório)"
            className="p-col-12 p-sm-6"
            options={dateFormats}
            placeholder="Selecione"
            filter={false}
          />
        )}
        {['NUMERO'].includes(tipoResposta.codigo) && (
          <div className="p-col-12 p-p-0 p-d-flex">
            <FormInput
              name="valorMinimo"
              label="Valor mínimo"
              className="p-col-12 p-sm-6"
              max={9999}
              type="number"
            />
            <FormInput
              name="valorMaximo"
              label="Valor máximo"
              className="p-col-12 p-sm-6"
              max={9999}
              type="number"
            />
          </div>
        )}

        {['MULTIPLA_ESCOLHA', 'ESCOLHA'].includes(tipoResposta.codigo) && (
          <div className="p-col-12 p-d-flex p-flex-wrap p-p-0 p-align-center">
            <FormInput
              name="opcao"
              label="Opção"
              className="p-col-12 p-sm-6"
              maxLength={500}
              maxLengthSpan
            />
            <FormInput
              name="pontuacao"
              label="Pontuação"
              className="p-col-10 p-sm-4 p-md-5"
              max={9999}
              type="number"
            />
            <Button
              label={isEditingOption.state ? 'Editar' : 'Adicionar'}
              btnType="tonal"
              className="p-col p-mt-1"
              style={{ height: '35px' }}
              onClick={isEditingOption.state ? handleEditOpcao : handleAddOpcao}
              disabled={!opcao || pontuacao === null}
            />
          </div>
        )}

        <div className="p-col-12 p-d-flex p-gap-2">
          <CheckboxControlled
            control={form.control}
            name="acervoPerguntas"
            label="Incluir no acervo de perguntas"
            disabled={isEditingOption.state || from === 'ACERVO'}
          />
          <CheckboxControlled
            control={form.control}
            name="obrigatorio"
            label="Obrigatório"
            disabled={isEditingOption.state}
          />
        </div>

        {['MULTIPLA_ESCOLHA', 'ESCOLHA'].includes(tipoResposta.codigo) && (
          <div className="p-col-12 row content-row-outline p-d-flex p-flex-column p-p-2 p-m-2 p-gap-2">
            {fields.length ? (
              fields.map((field, idx) => (
                <OptionsRow
                  key={field.id}
                  item={field}
                  onDelete={() => remove(idx)}
                  onEdit={() => onEditOpcao(field, idx)}
                  isEditing={idx === isEditingOption.idx}
                  disabledActions={isEditingOption.state}
                />
              ))
            ) : (
              <SimpleText
                className="p-my-2 p-text-center p-line-height-2"
                fontColor="color_40"
              >
                Nenhuma opção adicionada. <br />
                Adicione opções para aparecer na lista.
              </SimpleText>
            )}
          </div>
        )}
        {errorOpcoes && (
          <SimpleText className="error p-col-12 p-pt-0" fontSize="xxs">
            {errorOpcoes.message}
          </SimpleText>
        )}

        {['MULTIPLA_ESCOLHA', 'ESCOLHA'].includes(tipoResposta.codigo) && (
          <CheckboxControlled
            control={form.control}
            className="p-col-12"
            name="respostaAdicional"
            label="Adicionar outro tipo de resposta"
            disabled={isEditingOption.state}
          />
        )}

        {respostaAdicional && (
          <>
            <FormInput
              className="p-col-12"
              name="textoRespostaAdicional"
              label="Texto explicativo para a resposta adicional"
              sublabel="(Obrigatório)"
              hideTextErrorSpace
              disabled={isEditingOption.state}
            />
            <FieldTipoRespostasAdicional
              className="p-col-12 p-sm-6"
              name="tipoRespostaAdicional"
              label="Tipo resposta"
              disabled={isEditingOption.state}
            />
            {tipoRespostaAdicional?.codigo === 'RESPOSTA' && (
              <FieldTipoRespostasAdicionalValidacao
                className="p-col-12 p-sm-6"
                name="tipoValidacaoRespostaAdicional"
                label="Tipo de validação"
                disabled={isEditingOption.state}
              />
            )}
          </>
        )}

        <div className="p-col-12 p-d-flex p-gap-2">
          <Button
            label="Cancelar"
            btnType="ghost"
            stretch
            onClick={() => close()}
            disabled={isLoading}
          />
          <Button
            label="Salvar"
            stretch
            type="submit"
            loading={isLoading}
            disabled={isEditingOption.state}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const OptionsRow = ({
  item,
  onDelete,
  onEdit,
  disabledActions,
  isEditing,
}: {
  item: IPerguntaFormularioItemOpcoes;
  onDelete(): void;
  onEdit(): void;
  disabledActions: boolean;
  isEditing: boolean;
}) => {
  return (
    <div data-isEditing={isEditing} className="row content-row">
      <div className="p-d-flex p-flex-column p-gap-2 p-col-4 p-sm-5">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Descrição
        </SimpleText>
        <SimpleText>{item.descricao}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-2 p-col-4 p-sm-5">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Pontuação
        </SimpleText>
        <SimpleText>{item.pontuacao}</SimpleText>
      </div>
      <div className="p-d-flex p-gap-2 p-col p-align-center p-jc-end">
        <Divider
          layout="vertical"
          className="p-m-0"
          style={{
            height: '20px',
          }}
        />
        <Button
          icon="fas fa-edit"
          btnType="gray"
          onClick={onEdit}
          disabled={disabledActions}
        />
        <Button
          icon="fas fa-trash"
          btnType="gray"
          onClick={onDelete}
          disabled={disabledActions}
        />
      </div>
    </div>
  );
};
