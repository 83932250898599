import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import {
  GetContrarreferenciaItem,
  GetEncaminhamentos,
} from 'src/models/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { CardEncaminhamento } from '../../_components/CardEncaminhamento/CardEncaminhamento';
import { Button } from 'src/components/_UI/Button';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './ModalContrarreferencia.scss';

type ContrarreferenciaProps = DisclosureType<GetEncaminhamentos> & {
  item: any;
  reload: () => void;
};

export const ModalEditContrarreferencia = ({
  ...props
}: ContrarreferenciaProps) => {
  return (
    <Dialog
      onHide={() => props.close()}
      visible={props.isOpen}
      className="nova-contrarreferencia"
      header="Contrarreferência"
    >
      <ErrorBoundary>
        <ModalContrarreferenciaEditContent {...props} />
      </ErrorBoundary>
    </Dialog>
  );
};

const ModalContrarreferenciaEditContent = ({
  close,
  item: contrarreferencia,
  reload,
}: ContrarreferenciaProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    atendimento: { paciente },
  } = useAppSelector((state: RootState) => state);

  const [loading, setLoading] = useState(false);
  const [dataConsultas, setDataConsultas] = useState<any>();
  const [contrarreferenciaDetalahada, setContrarreferenciaDetalhada] =
    useState<GetContrarreferenciaItem | null>(null);

  useEffect(() => {
    if (contrarreferencia?.id) {
      setLoading(true);
      Promise.all([
        AtendimentoEncaminhamentoAPI.loadContrarreferenciaById(
          contrarreferencia?.id.toString(),
          {
            throwError: true,
          },
        )
          .then(setContrarreferenciaDetalhada)
          .catch(() => {}),
        AtendimentoEncaminhamentoAPI.loadPacienteDatasConsulta(
          paciente?.id,
        ).then(setDataConsultas),
      ]).finally(() => setLoading(false));
    }
  }, [contrarreferencia?.id, paciente?.id]);

  const validationSchema = Yup.object({
    alta: Yup.boolean(),
    chavePeriodicidade: Yup.string().when('alta', {
      is: (alta: boolean) => !alta,
      then: Yup.string().required('Campo obrigatório'),
    }),
    resumoClinicoContrarreferencia: Yup.string().required('Campo obrigatório'),
    hipoteseDiagnosticaContrarreferencia:
      Yup.string().required('Campo obrigatório'),
    questionamentoContrarreferencia: Yup.string().required('Campo obrigatório'),
  });

  const defaultValues = useMemo(() => {
    return { ...contrarreferenciaDetalahada };
  }, [contrarreferenciaDetalahada]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffectSkipFirst(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const periodicidadeOptions = [
    {
      label: 'Semanal',
      value: 'SEMANAL',
    },
    {
      label: 'Quinzenal',
      value: 'QUINZENAL',
    },
    {
      label: 'Mensal',
      value: 'MENSAL',
    },
    {
      label: 'Trimestral',
      value: 'TRIMESTRAL',
    },
    {
      label: 'Quadrimestral',
      value: 'QUADRIMESTRAL',
    },
    {
      label: 'Semestral',
      value: 'SEMESTRAL',
    },
    {
      label: 'Anual',
      value: 'ANUAL',
    },
  ];

  const onSubmit = useCallback(
    (values: any) => {
      const payload = {
        idAtendimento: Number(idAtendimento),
        idAtendimentoContrarreferencia: contrarreferenciaDetalahada?.id,
        dataPrimeiraConsulta: dataConsultas?.dataPrimeiraConsulta,
        dataUltimaConsulta: dataConsultas?.dataUltimaConsulta,
        encaminhamentoExterno: !!values.encaminhamentoExterno,
        alta: !!values.alta,
        ...values,
      };

      AtendimentoEncaminhamentoAPI.updateContrarreferencia(
        { ...payload, idContrarreferencia: contrarreferenciaDetalahada?.id },
        {
          throwError: true,
        },
      )
        .then(() => [reload(), close()])
        .catch(() => {});
    },
    [
      close,
      contrarreferenciaDetalahada?.id,
      dataConsultas?.dataPrimeiraConsulta,
      dataConsultas?.dataUltimaConsulta,
      idAtendimento,
      reload,
    ],
  );

  if (loading) {
    return <SpinnerLoading full />;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="p-grid p-col-12">
        <div className="p-col-12">
          {!!contrarreferenciaDetalahada && (
            <CardEncaminhamento
              highlighted
              item={contrarreferenciaDetalahada?.encaminhamento}
            />
          )}
        </div>

        <div className="p-grid p-col-12">
          <div className="p-col-6 p-md-3 p-d-flex p-flex-column">
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
              Primeira Consulta
            </SimpleText>
            <div className="p-col-12 p-py-0">
              <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                <i className="fa fa-calendar-days" />
              </SimpleText>
              <SimpleText className="p-col-12 p-py-0">
                {dayjs(dataConsultas?.dataPrimeiraConsulta).format(
                  'DD/MM/YYYY',
                )}
              </SimpleText>
            </div>
          </div>
          <div className="p-col-6 p-md-3 p-d-flex p-flex-column">
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
              Última consulta
            </SimpleText>
            <div className="p-col-12 p-py-0">
              <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                <i className="fa fa-calendar-days" />
              </SimpleText>
              <SimpleText className="p-col-12 p-py-0">
                {dayjs(dataConsultas?.dataUltimaConsulta).format('DD/MM/YYYY')}
              </SimpleText>
            </div>
          </div>
        </div>

        <DropdownControlled
          control={form.control}
          options={periodicidadeOptions}
          name="chavePeriodicidade"
          label="Periodicidade prevista para reavaliação"
          placeholder="Selecione..."
          className="p-col-12 p-md-6"
          filter={false}
          hideTextErrorSpace={false}
          errorMsg={form.formState.errors?.chavePeriodicidade?.message}
        />

        <CheckboxControlled
          name="alta"
          control={form.control}
          label="Alta"
          className="p-col-6 p-md-2"
        />

        <CheckboxControlled
          name="encaminhamentoExterno"
          control={form.control}
          label="Fora da área de ação"
          className="p-col-6 p-md-4"
        />

        <TextareaInputControlled
          control={form.control}
          name="resumoClinicoContrarreferencia"
          label="Resumo clínico/Resultado de exame (s) e procedimentos realizados"
          maxLength={4000}
          maxLengthSpan
          className="p-col-12"
        />
        <TextareaInputControlled
          control={form.control}
          name="hipoteseDiagnosticaContrarreferencia"
          label="Hipótese diagnóstica"
          maxLength={4000}
          maxLengthSpan
          className="p-col-12"
        />
        <TextareaInputControlled
          control={form.control}
          name="questionamentoContrarreferencia"
          label="Conduta Orientações"
          maxLength={4000}
          maxLengthSpan
          className="p-col-12"
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            stretch
            btnType="ghost"
            onClick={() => [close()]}
            loading={form.formState.isSubmitting}
          />
          <Button
            type="submit"
            label="Salvar"
            stretch
            loading={form.formState.isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};
