import './SpinnerLoading.scss';

type SpinnerLoadingProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  full?: boolean;
  className?: string;
};

const SpinnerLoading = ({
  size = 'md',
  full = false,
  className = '',
}: SpinnerLoadingProps) => {
  const fullContent = full ? `full-content` : '';

  return (
    <div className={`traditional size-${size} ${fullContent} ${className}`} />
  );
};

export default SpinnerLoading;
