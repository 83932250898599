
import { useCallback, useState } from 'react';

import { InputSwitchChangeParams } from 'primereact/inputswitch';

import TipoGrupoAtendimentoAPI from 'src/APIs/AgendaAPI/TipoGrupoAtendimento/TipoGrupoAtendimentoAPI';

import Button from 'src/components/Basics/Button/Buttons';
import Divider from 'src/components/Basics/Divider/Divider';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import Switch from 'src/components/Switch/Switch';
import './TipoGrupoItem.scss';

interface TipoGrupoItemProps {
  text: string;
  index: number;
  checked: boolean;
  setChecked: any;
  onEdit(): void;
  onDelete(): void;
  tipoGrupoSelecionado: TipoGrupoAtendimentoDTO;
  tipoGrupoList: TipoGrupoAtendimentoDTO[];
}

const TipoGrupoItem = ({
  text,
  index,
  checked,
  onEdit,
  onDelete,
  setChecked,
  tipoGrupoSelecionado,
  tipoGrupoList,
}: TipoGrupoItemProps) => {
  const [tooltipActive, setTooltipActive] = useState(false);

  const Footer = () => {
    return (
      <div className={'p-grid footer-delete'}>
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
          Você realmente deseja excluir este tipo de grupo?
        </SimpleText>

        <div className={'footer-delete-row'}>
          <Button
            className="btn ghost"
            btnType={'ghost'}
            label="Cancelar"
            onClick={() => setTooltipActive(false)}
          />
          <Button
            className="btn filled"
            btnType={'filled'}
            label="Sim, excluir"
            onClick={() => {
              onDelete();
              setTooltipActive(false);
            }}
          />
        </div>
      </div>
    );
  };

  const handleIsActive = useCallback(
    (t: InputSwitchChangeParams) => {
      console.log({ tipoGrupoSelecionado });
      const tipoGrupoEditado = {
        ...tipoGrupoSelecionado,
        status: !tipoGrupoSelecionado.status,
      };

      tipoGrupoList.splice(index, 1, tipoGrupoEditado);

      setChecked(t.target.value);

      const tipoGrupoAtendimentoId = tipoGrupoSelecionado.id;

      TipoGrupoAtendimentoAPI.updateTipoGrupoAtendimento(
        tipoGrupoAtendimentoId!,
        tipoGrupoEditado,
      );

      TipoGrupoAtendimentoAPI.loadTipoGrupoAtendimentos({
        id: tipoGrupoAtendimentoId,
      });
    },
    [tipoGrupoSelecionado, tipoGrupoList, index, setChecked],
  );

  return (
    <div className="TipoGrupoItem p-flex-column p-flex-sm-row p-jc-between p-ai-center">
      <SimpleText fontSize={FONT_SIZE.XS}>{text}</SimpleText>

      <div className="tipo-gruposOptions p-mt-3 p-mt-sm-0">
        <div className="switch">
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
            {checked ? 'Ativado' : 'Desativado'}
          </SimpleText>
          <Can checkPermission="AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_ALTERAR">
            {can => (
              <Switch
                checked={checked}
                onChange={t => handleIsActive(t)}
                disabled={!can}
              />
            )}
          </Can>
        </div>
        <Divider layout={'vertical'} />
        <div className="btns">
          <Can checkPermission="AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_ALTERAR">
            <IconButton
              icon="fas fa-edit"
              btnType={IconBtnTypes.GREY}
              onClick={onEdit}
            />
          </Can>
          <Can checkPermission="AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_EXCLUIR">
            <IconButton
              icon="fas fa-trash"
              btnType={IconBtnTypes.GREY}
              onClick={() => setTooltipActive(true)}
            />
          </Can>
          {tooltipActive && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default TipoGrupoItem;
