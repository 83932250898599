import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isTablet } = useSize();
  if (isTablet) return null;

  return (
    <>
      {isTablet === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <div className="p-col">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Nome do grupo
            </SimpleText>
          </div>

          <div className="p-col-3">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Consultório
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Data de inclusão
            </SimpleText>
          </div>

          <div className="p-d-flex p-col-fixed p-jc-end p-pl-3 fixed">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Ações
            </SimpleText>
          </div>
        </div>
      )}
    </>
  );
};
