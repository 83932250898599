import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import {
  ListaMedicamentoPrescricao,
  LoadPrescricao,
} from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

type IProps = {
  med: ListaMedicamentoPrescricao;
  idx: number;
  prescricao: LoadPrescricao;
};

export const MedicamentosRow = ({ med, idx, prescricao }: IProps) => {
  const form = useFormContext();

  return (
    <div key={idx} className="row p-d-flex p-ai-center p-col-12 p-p-0">
      <CheckboxControlled
        name={`prescricaoMedicamentos.${idx}.aplicado`}
        className="p-col"
        control={form.control}
      />
      <SimpleText className="p-col-3">{med.descricao}</SimpleText>
      <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>Posologia</SimpleText>
        <SimpleText>{prescricao.posologia}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>Via</SimpleText>
        <SimpleText>{prescricao.via}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
        <SimpleText fontColor={FONT_COLOR.PRIMARY}>{med.quantidade}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-jc-end p-gap-1 p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Data da aplicação
        </SimpleText>
        <SimpleText>
          {med.dataAplicacao
            ? dayjs(med.dataAplicacao).format('DD/MM/YYYY HH:mm')
            : '-'}
        </SimpleText>
      </div>
    </div>
  );
};
