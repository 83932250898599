import { useFieldArray, useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ModalAddTipoPlano from './ModalAddTipoPlano';
import TipoPlanoItem from './TipoPlanoItem';

const TipoPlano = () => {
  const modalDisclosure = useDisclosure({
    opened: false,
  });

  const { isMobile } = useSize();

  const { control, getValues, reset } = useFormContext();

  const {
    fields: tiposPlanoFields,
    append: appendtipoPlanoFields,
    remove: removetipoPlanoFields,
    update: updatetipoPlanoFields,
  } = useFieldArray({
    control,
    name: 'tiposPlano',
  });

  const handleRemove = (idx: number) => {
    removetipoPlanoFields(idx);
    reset(getValues());
  };

  const Header = () => (
    <div className="p-grid p-col-12 p-p-0">
      <SimpleText
        className="p-col-6"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Descrição
      </SimpleText>

      <SimpleText
        className="p-col-4 p-d-flex"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Auto. Carteira APS
      </SimpleText>

      <SimpleText
        className="p-col-2 p-text-right"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Ações
      </SimpleText>
    </div>
  );

  return (
    <div className="p-col-12">
      <div className="border-container">
        {!isMobile && <Header />}
        <div className="lista-tipo-plano">
          {!tiposPlanoFields.length && (
            <Card className="p-mb-2 p-text-center" type={CARD_TYPES.GRAY}>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                Não há tipos de plano.
              </SimpleText>
            </Card>
          )}

          {tiposPlanoFields.map((tipoPlano: any, index) => (
            <TipoPlanoItem
              key={generateUniqueId()}
              index={index}
              onRemove={handleRemove}
              tipoPlano={tipoPlano}
              updatetipoPlanoFields={updatetipoPlanoFields}
            />
          ))}
        </div>
        <Button
          className="p-col-12"
          type="button"
          label="Adicionar tipo plano"
          icon="fas fa-plus"
          iconPos="left"
          onClick={() => modalDisclosure.open()}
          btnType="tonal"
        />
      </div>
      {modalDisclosure.isOpen && (
        <ModalAddTipoPlano
          {...modalDisclosure}
          appendtipoPlanoFields={appendtipoPlanoFields}
          tiposPlanoFields={tiposPlanoFields}
        />
      )}
    </div>
  );
};

export default TipoPlano;
