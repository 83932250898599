import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import { Address } from './Address';
import { BasicData } from './BasicData';
import { validationSchema } from './validationSchema';

interface FormBasicInfoProps {
  goToNextStep: () => void;
}

export const FormBasicInfo = (props: FormBasicInfoProps) => {
  const { goToNextStep } = props;

  const navigate = useNavigate();

  const { basicInfoStepData, setBasicInfoStepData, setAdditionalInfoStepData } =
    useManterConvenio();

  const useFormMethods = useForm({
    defaultValues: {
      ...(basicInfoStepData || {}),
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = useFormMethods;

  const onSubmit = (data: any) => {
    setBasicInfoStepData(data);

    if (data.isParticular)
      setAdditionalInfoStepData((prevState: any) => ({
        ...prevState,
        tiposPlano: undefined,
        mascaraCarterinha: undefined,
        exibirCuidadorViverBemFolhaRosto: undefined,
      }));

    goToNextStep();
  };

  const handleChangeParticular = (e: any) => {
    if (e.value) {
      return reset({
        nomeFantasia: 'Particular',
        isParticular: true,
      });
    }

    reset({
      nomeFantasia: '',
      isParticular: false,
    });
  };

  const isParticular = watch('isParticular');

  return (
    <FormProvider {...useFormMethods}>
      <form
        className="p-grid p-col-12 p-md-10 p-lg-8 p-xl-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SimpleText
          className="p-col-12 p-mb-2"
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Dados cadastrais
        </SimpleText>

        <SwitchControlled
          className="p-col-12"
          name="isParticular"
          control={control}
          label="Particular"
          onChange={handleChangeParticular}
        />

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
          Deve estar ativo, quando a categoria de atendimento for particular.
        </SimpleText>

        <FormInput
          className="p-col-12"
          name="nomeFantasia"
          label="Nome fantasia"
          maxLength={255}
          hideTextErrorSpace
          disabled={!!isParticular}
        />

        {!isParticular && (
          <>
            <BasicData />
            <Address />
          </>
        )}

        <div className="p-col-12 p-md-3">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={() => navigate('/cadastros/convenio', { replace: true })}
            stretch
          />
        </div>

        <div className="p-col-12 p-md-9">
          <Button
            btnType="tonal"
            type="submit"
            label="Próximo"
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};
