import { useState, useEffect, useCallback, useContext } from 'react';

import { Message } from 'primereact/message';
import { useNavigate } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';

import * as faceapi from 'face-api.js';

import ThemeContext from 'src/core/themes/ThemeContext';

import Button from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import LoginLayout from 'src/components/LoginLayout/LoginLayout';
import Switch from 'src/components/Switch/Switch';

import './BiometriaFacialAuth.scss';

export const BiometriaFacialAuth = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<string>('renderPermission');
  const [camPermission, setCamPermission] = useState(false);
  const [camController, setCamController] = useState<any>(null);
  const [findFace, setFindFace] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const { theme } = useContext(ThemeContext);

  const getBlobFromMediaStream = useCallback(() => {
    const video: any = document.querySelector('video');
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
      video.addEventListener('pause', async () => {
        const { videoWidth, videoHeight } = video;
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        try {
          context.drawImage(video, 0, 0, videoWidth, videoHeight);
          canvas.toBlob(resolve, 'image/png');
        } catch (error) {
          reject(error);
        }
      });

      video.pause();
    });
  }, []);

  const showAuthenticationError = useCallback(() => {
    const toastConfig: ToastOptions = {
      position: 'bottom-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: 'colored',
    };

    const toastContent = (
      <div>
        <h2 className={'p-mb-2'}>Falha</h2>
        <p>
          A biometria facial falhou, certifique-se de seguir as instruções e
          tente novamente.
        </p>
      </div>
    );

    toast.error(toastContent, toastConfig);
  }, []);

  const makeAuthentication = useCallback(
    async (findFaceSuccessTimes: number) => {
      // after 4 correct scam, get scam image and set to validation
      if (findFaceSuccessTimes >= 4 && authenticating === false) {
        setAuthenticating(true);
        const imageToValidation = await getBlobFromMediaStream();
        console.log(imageToValidation);

        // TODO: request to send image to biometric auth

        setTimeout(() => {
          setFormState('renderPermission');
          setAuthenticating(false);
          setFindFace(false);
          showAuthenticationError();
        }, 2000);
      }
    },
    [authenticating, getBlobFromMediaStream, showAuthenticationError],
  );

  const validFaceScam = useCallback(
    async (findFaceSuccessTimes: number) => {
      let successGettingResult = false;

      // check if faceapi scanner is loaded
      if (faceapi.nets.ssdMobilenetv1.params) {
        const videoEl: any = document.getElementById('video');

        if (videoEl && formState === 'renderFacialScam') {
          const options = new faceapi.SsdMobilenetv1Options({
            minConfidence: 0.9,
          });

          const result = await faceapi.detectSingleFace(videoEl, options);

          if (result && result.score >= 0.95) {
            successGettingResult = true;
          }
        }
      } else {
        await faceapi.nets.ssdMobilenetv1.loadFromUri('/face-recognize-models');
      }

      if (successGettingResult === true) {
        findFaceSuccessTimes += 1;
        setFindFace(true);
        makeAuthentication(findFaceSuccessTimes);
      } else {
        setFindFace(false);
        findFaceSuccessTimes = 0;
      }

      // run a new scan every 0.5 seconds
      setTimeout(() => validFaceScam(findFaceSuccessTimes), 500);
    },
    [formState, makeAuthentication],
  );

  const onClickBackLogin = () => {
    stopVideoRecording();
    navigate('/login');
  };

  const onClickRenderFacialScam = () => {
    setFormState('renderFacialScam');
  };

  useEffect(() => {
    if (formState === 'renderFacialScam' && camController) {
      const video = document.querySelector('video');

      if (video) {
        video.srcObject = camController;
        video.onloadedmetadata = function () {
          video?.play();
          validFaceScam(0);
        };
      }
    }
  }, [formState, camController, validFaceScam]);

  const stopVideoRecording = () => {
    camController?.getTracks().forEach(function (track: any) {
      track.stop();
    });
    setAuthenticating(false);
  };

  const renderStateForm = () => {
    switch (formState) {
      case 'renderFacialScam':
        return renderFacialScam();
      default:
        return renderPermission();
    }
  };

  const enableCamera = () => {
    if (camPermission === false) {
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: 'user', width: 600, height: 500 },
        })
        .then(mediaStream => {
          console.log(mediaStream);
          setCamController(mediaStream);
        });
    } else {
      stopVideoRecording();
      setCamController(null);
    }

    setCamPermission(!camPermission);
  };

  const renderPermission = () => {
    return (
      <div className={`BiometricAuth ${theme}`}>
        <div className={'loginForm'}>
          <div onClick={onClickBackLogin} className={'linkbackLogin'}>
            <i className={'pi pi-arrow-left'} />
            <SimpleText className="textLink">
              Cancelar e voltar ao login
            </SimpleText>
          </div>
          <SimpleText className={'p-mt-4'} fontSize={FONT_SIZE.LG}>
            Entrar com biometria facial
          </SimpleText>
          <SimpleText className={'p-mt-3'}>
            Para acesse o e-Med, certifique-se de permitir o acesso a sua câmera
            e evite erros seguindo as instruções:
          </SimpleText>
          <SimpleText>
            <ul className={'p-ml-5 p-mt-2'}>
              <li>Não usar boné ou chapéu;</li>
              <li className={'p-mt-1'}>Permanecer em um ambiente iluminado;</li>
              <li className={'p-mt-1'}>Evitar sombras no seu rosto.</li>
            </ul>
          </SimpleText>

          <div className="p-d-flex p-jc-between permission-container">
            <div className={'p-mt-1'}>
              <SimpleText>Permitir o e-Med o acesso à câmera</SimpleText>
            </div>
            <div>
              <Switch checked={camPermission} onChange={() => enableCamera()} />
            </div>
          </div>

          <Button
            disabled={!camPermission}
            className={'p-mt-4'}
            onClick={onClickRenderFacialScam}
            label={'Avançar'}
          />

          {!camPermission && (
            <Message
              severity="warn"
              className={'p-mt-2'}
              text="Permita o acesso à câmera para avançar."
            ></Message>
          )}
        </div>
      </div>
    );
  };

  const getScamMessage = () => {
    let message = '';

    if (authenticating === true) {
      message = 'Imagem capturada. Processando...';
    } else if (findFace === true) {
      message = 'Mantenha-se fixo';
    } else {
      message = 'Centralize seu rosto na forma abaixo';
    }

    return message;
  };

  const renderFacialScam = () => {
    return (
      <div className={`BiometricAuth ${theme}`}>
        <div className={'loginForm'}>
          <div onClick={onClickBackLogin} className={'linkbackLogin p-mb-4'}>
            <i className={'pi pi-arrow-left'} />
            <SimpleText className="textLink">
              Cancelar e voltar ao login
            </SimpleText>
          </div>
          <div className={'video-container'}>
            {!camController && (
              <div className={'spinner-container'}>
                <i className="pi pi-spin pi-spinner spinner-config"></i>
              </div>
            )}

            <video id={'video'} />

            {camController && authenticating === false && (
              <div
                className={`box ${
                  authenticating || findFace ? 'box-success' : ''
                }`}
              />
            )}

            {camController && (
              <div className={'warnings'}>
                <SimpleText className={'warning-message'}>
                  {getScamMessage()}
                </SimpleText>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <LoginLayout content={renderStateForm()} maxWidth={700} />;
};
