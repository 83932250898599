import { memo, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import TipoMotivoAtendimentoAPI from 'src/APIs/ConfigAPI/TipoMotivoAtendimento/TipoMotivoAtendimentoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import FieldConsultorios from '../../components/FieldConsultorios';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import Skeleton from 'src/components/Skeleton/Skeleton';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import { validationSchema } from '../../Utils';

interface ModalAddTipoMotivoProps {
  visible: boolean;
  onHide(): void;
  tipoMotivoAtendimento: any;
  reload(): void;
  consultoriosDisponiveis: any[];
  consultoriosUsuario: any[];
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
  loading: boolean;
}

const Footer = memo(({ onCancel, onAdd, loading }: FooterProps) => {
  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType={'ghost'} label="Cancelar" onClick={onCancel} stretch />
      <Button label={'Salvar'} onClick={onAdd} stretch loading={loading} />
    </div>
  );
});

const ModalAddTipoMotivo = ({
  tipoMotivoAtendimento,
  consultoriosDisponiveis,
  consultoriosUsuario,
  visible,
  onHide,
  reload,
}: ModalAddTipoMotivoProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const [fetchedTipoMotivo, setFetchedTipoMotivo] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const hasTipoMotivoAtendimento = !!tipoMotivoAtendimento?.id;

  const defaultValues = useMemo(() => {
    const consultoriosValue = fetchedTipoMotivo?.todosConsultorios
      ? [{ id: 'Todos', name: 'Todos' }]
      : fetchedTipoMotivo?.consultoriosTiposMotivo?.map(
          (item: any) => item.consultorio,
        ) || [];

    return {
      consultorios: consultoriosValue ?? [],
      descricao: fetchedTipoMotivo?.descricao ?? '',
      ativo: fetchedTipoMotivo?.ativo ?? false,
    };
  }, [fetchedTipoMotivo]);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { isSubmitting },
  } = useFormMethods;

  useEffect(() => {
    const getTipoMotivoAtendimento = async () => {
      try {
        setLoading(true);
        const response =
          await TipoMotivoAtendimentoAPI.getTipoMotivoAtendimentoById(
            tipoMotivoAtendimento.id,
            { throwError: true },
          );

        setFetchedTipoMotivo(response);
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (!!tipoMotivoAtendimento.id) {
      getTipoMotivoAtendimento();
    }
  }, [tipoMotivoAtendimento.id]);

  useEffectSkipFirst(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleCancelAddTipoMotivo = () => {
    setFetchedTipoMotivo(null);
    onHide();
    clearErrors();
  };

  const options = useMemo(() => {
    const consultoriosSelecionados =
      fetchedTipoMotivo?.consultoriosTiposMotivo?.map((c: any) => {
        return {
          nome: c.consultorio.nome,
          label: c.consultorio.nome,
          value: {
            id: c.consultorio.id,
            name: c.consultorio.nome,
          },
        };
      }) || [];

    /**
     * Utilizar consultorios selecionados para quando for edição e o consultorio não
     * pertencer mais ao usuario
     */
    const options = [
      {
        nome: 'Todos',
        label: 'Todos',
        value: { id: 'Todos', name: 'Todos' },
      },
    ].concat(consultoriosUsuario, consultoriosSelecionados);

    // Remove os consultorios duplicados das opções
    const uniqueOptions = Array.from(
      new Set(options.map((a: any) => a?.value.id)),
    ).map(id => {
      return options.find((a: any) => a?.value.id === id);
    });

    return uniqueOptions;
  }, [consultoriosUsuario, fetchedTipoMotivo?.consultoriosTiposMotivo]);

  const onSubmit = async (data: any) => {
    const { descricao, consultorios, ativo } = data;

    let todos = false;
    let consults: any[] = [];
    if (consultorios[0]?.id === 'Todos') {
      todos = true;

      consults = consultoriosDisponiveis.map((c: any) => {
        return {
          id: c.id,
          name: c.nome,
        };
      });
    }

    const payload: TipoMotivoAtendimento = {
      consultorios: todos ? consults : consultorios,
      descricao,
      ativo,
      todosConsultorios: todos,
      idUsuarioAlteracao: user?.id,
      registroEditavel: true,
    };

    const saveAction = () =>
      hasTipoMotivoAtendimento
        ? TipoMotivoAtendimentoAPI.updateTipoMotivoAtendimento(
            tipoMotivoAtendimento?.id,
            payload as any,
            { return: true },
          )
        : TipoMotivoAtendimentoAPI.sendTipoMotivoAtendimento(payload as any, {
            return: true,
          });

    const response = await saveAction();

    if (response.status < 200 || response.status > 299) {
      return;
    }

    reload();
    handleCancelAddTipoMotivo();
  };

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header={
        hasTipoMotivoAtendimento
          ? 'Editar tipo de motivo de atendimento'
          : 'Adicionar tipo de motivos de atendimento'
      }
      visible={visible}
      onHide={handleCancelAddTipoMotivo}
      footer={() => (
        <Footer
          onAdd={handleSubmit(onSubmit)}
          onCancel={handleCancelAddTipoMotivo}
          loading={isSubmitting}
        />
      )}
      maximizedMobileSize
    >
      <FormProvider {...useFormMethods}>
        <form className="modal-body" onSubmit={handleSubmit(onSubmit)}>
          <FieldConsultorios
            className="p-mt-3"
            label="Consultório"
            options={options}
            loading={loading}
          />

          <FormInput
            className="p-mt-3"
            label="Descrição"
            name="descricao"
            maxLength={30}
            loading={loading}
          />

          <SimpleText className="p-mb-2" fontColor={FONT_COLOR.COLOR_40} medium>
            Status do cadastro
          </SimpleText>

          {loading ? (
            <div>
              <Skeleton
                className="p-mt-2"
                height="33px"
                borderRadius="8px"
                loading={true}
              />
            </div>
          ) : (
            <div className="switch p-d-flex p-jc-start">
              <SwitchControlled control={control} name="ativo" showStatus />
            </div>
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalAddTipoMotivo;
