import Http, { HttpParams } from 'src/core/http/Http';

export const loadUsuariosSimultaneos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetUsuariosSimultaneosDTO[]> => {
  return Http.get({
    requestDescription: 'listagem de usuários simultâneos',
    service: 'adm',
    url: '/usuarios/limite-sessoes',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const updateUsuariosSimultaneos = (
  payload: PostUsuariosSimultaneosDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostUsuariosSimultaneosDTO> => {
  return Http.put({
    service: 'adm',
    url: '/usuarios/limite-sessoes',
    data: payload,
    hideToast: true,
    ...options,
  });
};

const UsuariosSimultaneosAPI = {
  loadUsuariosSimultaneos,
  updateUsuariosSimultaneos,
};

export default UsuariosSimultaneosAPI;
