import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const items = [
  {
    label: 'Todos',
    value: 'TODOS',
  },
  {
    label: 'Descrição CID',
    value: 'DESCRICAO_CID',
  },
  {
    label: 'Descrição TUSS',
    value: 'DESCRICAO_TUSS',
  },
  {
    label: 'Anamnese',
    value: 'ANAMNESE',
  },
  {
    label: 'Exame Físico',
    value: 'EXAME_FISICO',
  },
  {
    label: 'Patologia Detectada',
    value: 'PATOLOGIA_DETECTADA',
  },
  {
    label: 'Prognóstico',
    value: 'PROGNOSTICO',
  },
  {
    label: 'Decisão Terapêutica',
    value: 'DECISAO_TERAPEUTICA',
  },
];

type FieldCamposProntuarioProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldCamposProntuario = ({
  name = 'campoEm',
  label,
  ...rest
}: FieldCamposProntuarioProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={items || []}
      errorMsg={errors?.[name]?.message}
      filter={false}
      {...rest}
    />
  );
};
