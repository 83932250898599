import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';

import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { useProcedimentosSolicitadosDataRealizacaoChange } from '../hooks/useProcedimentosSolicitadosDataRealizacaoChange';
import { useProcedimentosSolicitadosQuantidadeChange } from '../hooks/useProcedimentosSolicitadosQuantidadeChange';

export const ProcedimentosSolicitadosPanelHeader = (props: any) => {
  const { procedimento, panelHeaderOptions, children } = props;

  const { atendimentoStatus } = useAtendimento();

  const disabledWhenAtendimentoStatusIsNotAtendendo =
    atendimentoStatus !== 'ATENDENDO';

  const { isLarge, windowInnerWidth } = useSize();

  const {
    dataRealizacao,
    isSubmitting: isSubmittingDataRealizacao,
    handleSelectDataRealizacao,
  } = useProcedimentosSolicitadosDataRealizacaoChange({
    idAtendimento: procedimento.idAtendimento,
    idProcedimento: procedimento.idSolicitacaoProcedimento,
    procedimentoDataRealizacao: procedimento?.dataRealizacao,
  });

  const {
    quantidade,
    isSubmitting: isSubmittingQuantidade,
    handleBlurQuantidade,
    handleChangeQuantidade,
  } = useProcedimentosSolicitadosQuantidadeChange({
    idAtendimento: procedimento.idAtendimento,
    idProcedimento: procedimento.idSolicitacaoProcedimento,
    quantidadeProcedimento: procedimento?.quantidade,
  });

  const toggleIcon = panelHeaderOptions.collapsed
    ? 'pi pi-chevron-down'
    : 'pi pi-chevron-up';

  const hasPendenciaIndicacaoClinica =
    procedimento.pendencia?.necessitaIndicacaoClinica &&
    !procedimento.indicacaoClinica;
  const hasPendenciaJustificativa =
    procedimento?.pendencia?.necessitaJustificativa &&
    !procedimento.justificativa;
  const hasPendenciaLaudo =
    procedimento.pendencia?.necessitaLaudo &&
    !procedimento?.resultadoExame?.laudoArquivo;

  const hasPendencia =
    procedimento?.pendencia?.alerta ||
    procedimento?.pendencia?.bloqueio ||
    hasPendenciaIndicacaoClinica ||
    procedimento?.pendencia?.necessitaIndicarAcidente ||
    hasPendenciaJustificativa ||
    hasPendenciaLaudo;

  const getBtnContainerClassName = () => {
    const className =
      'p-col p-order-0 p-order-lg-1 p-d-flex p-ai-center p-gap-2';
    if (windowInnerWidth <= 1300) {
      return `${className} p-lg-2 p-xl-3 p-jc-end`;
    }

    if (!procedimento.flagPossuiHistorico) {
      return `${className} p-lg-5 p-jc-end p-gap-4`;
    }

    return `${className} p-lg-5 p-jc-between`;
  };

  return (
    <div className="p-grid p-align-center">
      <div className="checkbox-col p-col-fixed p-order-0 p-d-flex p-ai-center">
        {hasPendencia && <i className="fas fa-exclamation-triangle" />}
      </div>

      <div className="p-col-12 p-order-1 p-lg-2 p-xl-1 p-d-flex p-flex-column">
        {isLarge && (
          <SimpleText
            className="p-mb-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Código
          </SimpleText>
        )}

        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {procedimento.codigoProcedimento}
        </SimpleText>
      </div>

      <div className="p-col p-order-1 p-d-flex p-flex-column">
        {isLarge && (
          <SimpleText
            className="p-mb-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Descrição
          </SimpleText>
        )}

        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XXS}>
          {procedimento.descricaoProcedimento}
        </SimpleText>
      </div>

      <div className="p-col-12 p-lg-1 p-order-1 p-d-flex p-flex-column">
        {isLarge && (
          <SimpleText
            className="p-mb-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Orientação
          </SimpleText>
        )}

        {!!procedimento?.orientacao && (
          <div>
            <Tooltip
              target={`.orientacao-${procedimento.codigoProcedimento}`}
            />
            <i
              className={`fas fa-clipboard-question orientacao-${procedimento.codigoProcedimento}`}
              data-pr-tooltip={procedimento.orientacao}
              data-pr-position="top"
            />
          </div>
        )}
      </div>

      <div className="p-col-12 p-lg-1 p-d-flex p-flex-column p-order-1">
        {isLarge && (
          <SimpleText
            className="p-mb-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Quantidade
          </SimpleText>
        )}

        <Skeleton
          loading={isSubmittingQuantidade}
          height="20px"
          width="15px"
          borderRadius="4px"
        >
          <InputNumber
            autoFocus
            className="light p-mr-2"
            value={quantidade}
            min={1}
            max={procedimento?.quantidadeMaxima}
            allowEmpty={false}
            hideTextErrorSpace
            editOnClick
            onChange={handleChangeQuantidade}
            onBlur={handleBlurQuantidade}
            disabled={disabledWhenAtendimentoStatusIsNotAtendendo}
          />
        </Skeleton>
      </div>

      <div className="data-realizacao-col p-col-fixed p-d-flex p-flex-column p-order-1">
        {isLarge && (
          <SimpleText
            className="p-mb-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Data de realização
          </SimpleText>
        )}

        <Skeleton
          loading={isSubmittingDataRealizacao}
          height="33px"
          borderRadius="8px"
        >
          <CalendarInput
            name="dataRealizacao"
            placeholder="dd/mm/aaaa"
            value={dataRealizacao}
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
            onSelect={handleSelectDataRealizacao}
            hideOnDateTimeSelect
            disabled={disabledWhenAtendimentoStatusIsNotAtendendo}
          />
        </Skeleton>
      </div>

      <div className={getBtnContainerClassName()}>
        {procedimento.flagPossuiHistorico && (
          <div
            className="solicitacoes-recentes-btn"
            onClick={panelHeaderOptions.onTogglerClick}
          >
            <div className="record-icon blink" />
            {windowInnerWidth > 1200 && 'Solicitações recentes'}
            <i className={toggleIcon} />
          </div>
        )}

        {children}
      </div>
    </div>
  );
};
