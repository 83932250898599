import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/lembrete-consulta';

export const getLembrete = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetLembreteAgendamentoDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: basePath,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const getLembreteById = async (
  id?: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetLembreteAgendamentoDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const getLembreteVariaveis = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/variaveis`,
    hideToast: true,
    ...options,
  });
};

export const postLembrete = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data,
    ...options,
  });
};

export const putLembrete = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${data.id}`,
    data,
    ...options,
  });
};

export const deleteLembrete = async (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

const LembreteAPI = {
  getLembrete,
  deleteLembrete,
  getLembreteVariaveis,
  getLembreteById,
  postLembrete,
  putLembrete,
};

export default LembreteAPI;
