import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import { OverlayAnexar } from './OverlayAnexar';

interface Props {
  exportacao: any;
  onAlterarEntregue: any;
  isCollapsed: boolean;
  onToggle: () => void;
  setGoogleUrl: any;
  handleDownloadArquivo: any;
}

export default function PanelHeader({
  exportacao,
  onAlterarEntregue,
  isCollapsed,
  onToggle,
  setGoogleUrl,
  handleDownloadArquivo,
}: Props) {
  const situacoes: any = {
    RECEBIDO: 'Sim',
    ENVIADO: 'Não',
  };

  const splitButtonItems: any = [];

  for (const situacao in situacoes) {
    if (situacao !== exportacao?.status) {
      splitButtonItems.push({
        label: situacoes[situacao],
        command: () =>
          onAlterarEntregue(exportacao.id, {
            // recibo: exportacao?.recibo,
            recebido: situacao === 'RECEBIDO' ? true : false,
          }),
      });
    }
  }

  return (
    <div className="p-grid p-d-flex p-ai-center">
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {dayjs(exportacao?.dataAtualizacao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {exportacao?.nomeSolicitante}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {exportacao?.cpfSolicitante}
        </SimpleText>
      </div>
      <div className="p-col-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {exportacao?.profissionalSaude?.nome}
        </SimpleText>
        <div className="p-d-flex">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            CRM
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            &nbsp;{exportacao?.profissionalSaude?.numeroRegistro}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-1 p-md-2">
        <SplitButton
          className="p-mt-2"
          menuClassName="menu-exportacao-em-uso"
          btnType={
            exportacao?.status === 'RECEBIDO'
              ? SPLIT_BTN_TYPE.GREEN
              : SPLIT_BTN_TYPE.YELLOW
          }
          label={exportacao?.status === 'RECEBIDO' ? 'Sim' : 'Não'}
          model={splitButtonItems}
        />
      </div>
      <div className="p-col-2 p-md-1 p-d-flex p-jc-end p-ai-center">
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
        {exportacao?.status === 'RECEBIDO' ? (
          <Button
            icon="fas fa-file-download"
            btnType="gray"
            onClick={() => handleDownloadArquivo(exportacao)}
          />
        ) : (
          <Button
            btnType="gray"
            className="anexar"
            icon="fas fa-file-upload"
            type="button"
            overlay={
              <OverlayAnexar
                exportacao={exportacao}
                index={exportacao.id}
                setGoogleUrl={setGoogleUrl}
                onAlterarEntregue={onAlterarEntregue}
              />
            }
          />
        )}
      </div>
    </div>
  );
}
