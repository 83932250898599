import * as Yup from 'yup';

const msg = {
  required: 'Este campo é obrigatório',
};

export const schemaPreNatal = Yup.object().shape({
  //GRAVIDEZ
  numeroConsultas: Yup.string().required(msg.required),
  trimestreInicioPrenatal: Yup.string().required(msg.required),
  tipoGravidez: Yup.string().required(msg.required),

  //INTERCORRENCIAS
  imunizacaoDuplaAdulto: Yup.string().required(msg.required),
  tipoParto: Yup.string().required(msg.required),

  //SOROLOGIAS
  dataCitopatologia: Yup.date().required(msg.required).nullable(),
  // hivStatus: Yup.string().required(msg.required),
  // vdrlStatus: Yup.string().required(msg.required),
  // toxoplasmoseStatus: Yup.string().required(msg.required),
  // hepatitebStatus: Yup.string().required(msg.required),
  // rubeolaStatus: Yup.string().required(msg.required),
});

export const schemaDadosNascimento = Yup.object().shape({
  // IDADE GESTACIONAL
  dataAlta: Yup.date().required(msg.required),
  idadeGestacionalSemanas: Yup.string().required(msg.required),
  idadeGestacionalDias: Yup.number()
    .required(msg.required)
    .positive('Deve ser um numero positivo')
    .max(7, 'O valor não pode ser maior que a "7" dias')
    .typeError('Idade em dias deve ser um número'),
  pesoAoNascer: Yup.string().required(msg.required),
  pesoAlta: Yup.string().required(msg.required),
  comprimentoNascimento: Yup.string().required(msg.required),
  perimetroToracico: Yup.string().required(msg.required),
  perimetroCefalico: Yup.string().required(msg.required),

  //APGAR
  apgarUmMinuto: Yup.string().required(msg.required),
  apgarCincoMinuto: Yup.string().required(msg.required),
  fatorSanguineoMae: Yup.string().required(msg.required),
  fatorSanguineoRn: Yup.string().required(msg.required),
  tipoSanguineoRn: Yup.string().required(msg.required),
  tipoSanguineoMae: Yup.string().required(msg.required),
});

export const schemaTriagem = Yup.object().shape({
  sinalOrtolani: Yup.string().required(msg.required),
  testeCoracaozinho: Yup.string().required(msg.required),
  testeLinguinha: Yup.string().required(msg.required),
  testeOlhinho: Yup.string().required(msg.required),
  testeOrelhinha: Yup.string().required(msg.required),
  testePezinho: Yup.string().required(msg.required),
});
