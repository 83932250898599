import Http, { HttpParams } from 'src/core/http/Http';

export const getPulso = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `pulso/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesPulso = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'pulso/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarPulso = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'pulso',
    data: payload,
    return: true,
    ...options,
  });

export const alterarPulso = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `pulso/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarPulso = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `pulso/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerPulso = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `pulso/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const PulsoAPI = {
  getPulso,
  getUnidadesPulso,
  adicionarPulso,
  alterarPulso,
  inativarPulso,
  removerPulso,
};

export default PulsoAPI;
