import React from 'react';
import {
  colesterolWithLabel,
  faixaEtariaWithLabel,
} from 'src/utils/escoreFraminghan';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
interface PanelHeaderProps {
  avaliacao: any;
}
export default function PanelContent({ avaliacao }: PanelHeaderProps) {
  const headerTextClassName = `p-mb-1 ${
    avaliacao.status === 'INATIVO' ? 'avaliacao-inativa' : ''
  }`;
  const dataTextClassName =
    avaliacao.status === 'INATIVO' ? 'line-through avaliacao-inativa' : '';

  const idade = faixaEtariaWithLabel(avaliacao.escoreFraminghanIdade);
  const hdl = colesterolWithLabel(avaliacao.escoreFraminghanHdl);
  const ldl = colesterolWithLabel(avaliacao.escoreFraminghanLdl);

  return (
    <div className="avaliacao-panel-content p-grid p-p-1">
      <div className="p-col p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Idade
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {idade?.label}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Colesterol
        </SimpleText>
        <div className="p-d-flex">
          <div className="p-d-flex">
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
              medium
            >
              LDL:&nbsp;
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {ldl?.valor}
            </SimpleText>
          </div>
          <div className="p-d-flex">
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
              medium
            >
              &nbsp;|&nbsp;HDL:&nbsp;
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {hdl?.valor}
            </SimpleText>
          </div>
        </div>
      </div>
      <div className="p-col p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Pressão arterial
        </SimpleText>
        <div className="p-d-flex">
          <div className="p-d-flex">
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
              medium
            >
              Sistólica:&nbsp;
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {avaliacao.escoreFraminghanPa?.sistolica}
            </SimpleText>
          </div>
          <div className="p-d-flex">
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
              medium
            >
              &nbsp;|&nbsp;Diastólica:&nbsp;
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {avaliacao.escoreFraminghanPa?.diastolica}
            </SimpleText>
          </div>
        </div>
      </div>
      <div className="p-col p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Diabetes
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {avaliacao.diabetes ? 'SIM' : 'NÃO'}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Tabagismo
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {avaliacao.tabagismo ? 'SIM' : 'NÃO'}
        </SimpleText>
      </div>
      {avaliacao?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {avaliacao.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
    </div>
  );
}
