import { useEffect, useRef, useState } from 'react';

import { Menu } from 'primereact/menu';
import { Line } from 'react-chartjs-2';

import { CategoryScale, ChartData, ChartOptions } from 'chart.js';
import ChartJS from 'chart.js/auto';
import dayjs from 'dayjs';
import { GraficoCrescimentoProps } from 'src/models/APIs/ProntuarioAPI/PreAtendimento/GraficoDeCrescimento/GraficoDeCrescimento';

import {
  initializeChartFromIdade,
  pacienteSexoCor,
  validateDaysToMonth,
  validateMainPosition,
} from './utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

ChartJS.register(CategoryScale);

const chartHistorico = [
  {
    label: 'Faixa de 2 a 5 anos',
    idadeInicial: 24,
    idadeFinal: 60,
  },
  {
    label: 'Faixa de 5 a 19 anos',
    idadeInicial: 60,
    idadeFinal: 228,
  },
];

export function GraficoImcChart({ chartData, paciente }: any) {
  const [chartVisible, setChartVisible] = useState(0);

  const menuOptions = useRef<any>();

  useEffect(() => {
    //* Inicializar o gráfico de acordo com a idade do paciente
    if (paciente?.idadeAnos)
      setChartVisible(initializeChartFromIdade(paciente.idadeAnos));
  }, [paciente?.idadeAnos]);

  const pacienteSexo = paciente?.sexoDescricao;

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const PESOJson: GraficoCrescimentoProps[] = require(pacienteSexo ===
    'MASCULINO'
    ? './utils/data/Imc_Mas.json'
    : './utils/data/Imc_Fem.json');

  const getGraficosFiltred = (
    chartIndexData: number,
  ): GraficoCrescimentoProps[] => {
    if (!chartHistorico[chartIndexData]?.idadeFinal) return [];

    const graficos = PESOJson.filter(
      item =>
        item.tipoGraficoScorez.idadeFinal ===
        chartHistorico[chartIndexData]?.idadeFinal,
    );

    return graficos;
  };

  const getIdadePerimetro = (chartIndexData: number) => {
    if (!chartData?.length) return [];

    return chartData[chartIndexData].reduce((acc: any, curr: any) => {
      const idadeFinal = chartHistorico[chartIndexData]?.idadeFinal;

      let newArray = acc;
      let idadeEmDias = dayjs(new Date(2022, curr.idedeMesesTotal, 30)).diff(
        new Date(2022, 1, 1),
        'day',
      );

      if (idadeFinal === 60) {
        idadeEmDias = idadeEmDias - 730;
      }

      if (idadeFinal === 228) {
        idadeEmDias = curr.idedeMesesTotal - 61;
      }

      if (!acc.length) {
        newArray = new Array(10000).fill({ IMC: null, MT: null });
      }

      newArray[idadeEmDias] = { IMC: curr.imc, MT: idadeEmDias };

      return newArray;
    }, []);
  };

  const getBasicData = (chartIndexData: number): ChartData<'line'> => {
    const graficoFiltred = getGraficosFiltred(chartIndexData);

    return {
      labels: graficoFiltred.map(el =>
        validateDaysToMonth(el.medidaTempo, el.tipoGraficoScorez.idadeFinal),
      ),
      datasets: [
        {
          label: '',
          data: getIdadePerimetro(chartIndexData).map((el: any) => ({
            x: el.MT,
            y: el.IMC,
          })),
          borderColor: pacienteSexoCor[pacienteSexo],
          borderWidth: 3,
          spanGaps: true,
          pointBackgroundColor: '#ffffff',
        },
        {
          label: 'SD4',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd4Positivo,
          })),
          borderColor: 'rgba(175, 177, 254, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD3',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd3Positivo,
          })),
          borderColor: 'rgba(51, 51, 51, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD2',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd2Positivo,
          })),
          borderColor: 'rgba(255, 80, 78, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD1',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd1Positivo,
          })),
          borderColor: 'rgba(255, 193, 23, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD0',
          data: graficoFiltred.map(el => ({ x: el.medidaTempo, y: el.sd0 })),
          borderColor: 'rgba(61, 214, 59, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD1neg',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd1Negativo,
          })),
          borderColor: 'rgb(255, 193, 23, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD2neg',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd2Negativo,
          })),
          borderColor: 'rgba(255, 80, 78, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD3neg',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd3Negativo,
          })),
          borderColor: 'rgba(51, 51, 51, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
        {
          label: 'SD4neg',
          data: graficoFiltred.map(el => ({
            x: el.medidaTempo,
            y: el.sd4Negativo,
          })),
          borderColor: 'rgba(175, 177, 254, 0.6)',
          pointHoverRadius: 0,
          pointRadius: 0,
        },
      ],
    };
  };

  const basicOptions: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 0.6,
    animation: false,
    datasets: {
      line: {
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
    },
    plugins: {
      title: {
        display: true,
        font: { size: 16, weight: 'bold' },
        position: 'bottom',
        text: 'Idade em anos',
      },
      subtitle: {
        display: true,
        position: 'left',
        padding: { bottom: 10 },
        font: { size: 16, weight: 'bold' },
        text: 'IMC',
      },
      legend: {
        display: false,
        labels: {
          color: '#495057',
        },
      },
      tooltip: {
        boxPadding: 4,
        // Remove title from tooltip
        callbacks: {
          title: () => '',
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0;
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
          autoSkip: true,
          font: (ctx: any) =>
            validateMainPosition(ctx, {
              result: { weight: 'bold', size: 16, textAlign: 'center' },
              fallback: { weight: 'boldness', size: 14 },
            }),
          maxRotation: 1,
        },
        grid: {
          color: (ctx: any) =>
            validateMainPosition(ctx, {
              result: '#d5d5d5',
              fallback: '#ebedef',
            }),
        },
      },
      y: {
        ticks: {
          color: '#495057',
          autoSkip: true,
          stepSize: 1,
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  };

  const canvasRef = useRef<any>(null);

  const items = chartHistorico.map(({ label }, index) => ({
    label,
    command: () => setChartVisible(index),
  }));

  const renderCharts = () =>
    chartHistorico.map((_, index) => (
      <div
        key={index}
        style={{ height: '420px' }}
        className={
          index === chartVisible
            ? 'chart-container'
            : 'chart-container p-chart-hidden'
        }
      >
        <Line data={getBasicData(index)} options={basicOptions} />
      </div>
    ));

  return (
    <>
      <div className="p-grid p-jc-end p-mb-3">
        <Menu model={items} popup ref={menuOptions} />

        <Button
          type="button"
          btnType="green-link"
          label="Histórico"
          icon="fas fa-caret-down"
          iconPos="right"
          onClick={event => menuOptions.current?.toggle(event)}
        />
      </div>

      <div id="graphic-element-imc" className="chart-content" ref={canvasRef}>
        <div className="chartTitle">
          <SimpleText>IMC X Idade</SimpleText>
        </div>

        {renderCharts()}

        <div className="source">
          <SimpleText className="label">Fonte (WHO)</SimpleText>
        </div>
      </div>
    </>
  );
}
