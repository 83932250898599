import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <div className="p-grid">
      <SimpleText
        className="p-col-2 p-lg-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Código
      </SimpleText>

      <SimpleText
        className="p-col-4 p-lg-5"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Descrição
      </SimpleText>

      <SimpleText
        className="p-col-3 p-lg-4"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Fabricante
      </SimpleText>

      <SimpleText
        className="p-col"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Qtd.
      </SimpleText>
    </div>
  );
};
