import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { useSearchList } from '../components/SearchList/SearchListContext';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import validationSchema from './validationSchema';

const statusOptions = [
  { label: 'Pendente avaliação', value: 'PENDENTE' },
  { label: 'Validado', value: 'VALIDADO' },
  { label: 'Rejeitado', value: 'REJEITADO' },
];

const Filter = () => {
  const { setFilter } = useSearchList();

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const periodo = watch('periodo');
  const status = watch('status');

  const onSubmit = async () => {
    const dataInicial = periodo?.[0]
      ? dayjs(periodo[0]).format('YYYY-MM-DD')
      : null;
    const dataFinal = periodo?.[1]
      ? dayjs(periodo[1]).format('YYYY-MM-DD')
      : null;

    const aux: any = {
      dataInicial,
      dataFinal,
      status,
    };
    setFilter(aux);
  };

  return (
    <form className="p-grid p-ai-start" onSubmit={handleSubmit(onSubmit)}>
      <CalendarInputControlled
        name="periodo"
        control={control}
        hideOnDateTimeSelect
        showIcon
        label="Período"
        dateFormat="dd/mm/yy"
        selectionMode="range"
        placeholder="dd/mm/aaaa - dd/mm/aaaa"
        readOnlyInput
        className="p-col-12 p-md-6 p-lg-3"
      />

      <DropdownControlled
        className="p-col-12 p-md-6 p-lg-3"
        control={control}
        name="status"
        label="Status"
        placeholder="Selecione"
        options={statusOptions}
        filter={false}
        showClear
      />
      <div className="p-p-2 p-mt-4">
        <Button label="Pesquisar" type="submit" btnType="tonal" />
      </div>
    </form>
  );
};

export default Filter;
