import * as Yup from 'yup';

export const folhaRostoValidationSchema = Yup.object().shape({
  tipoGestacao: Yup.string().required('O campo é obrigatório'),
  tipoPartoHistorico: Yup.string().required('O campo é obrigatório'),
  pesoAoNascerHistorico: Yup.string().required('O campo é obrigatório'),
  pesoNascimentoUnidadeMedidaHist: Yup.string().required(
    'O campo é obrigatório',
  ),
  dataPartoHistorico: Yup.date().nullable().required('O campo é obrigatório'),
  quantidadeFetos: Yup.string().required('O campo é obrigatório'),
});
