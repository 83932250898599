import React, { useCallback, useEffect } from 'react';
import { useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useFormContext } from 'react-hook-form';

import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './FieldSearchPaciente.scss';

export const FieldSearchPaciente = ({ onAdd, className = '' }: any) => {
  const form = useFormContext();

  const searchPanelRef = useRef<OverlayPanel>(null);

  const [items, setItems] = useState<any[]>([]);

  const [loadingQuery, setLoadingQuery] = useState(false);

  const watchPaciente: string = form.watch('paciente');

  const fetchAPI = useCallback(async () => {
    if (watchPaciente?.length < 3) return;

    setLoadingQuery(true);

    PacienteAPI.loadPacientes({ nome: watchPaciente })
      .then((data: any) => [setItems(data.list)])
      .catch(() => {})
      .finally(() => setLoadingQuery(false));
  }, [watchPaciente]);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  const handleAdd = (item: any) => {
    form.setValue('paciente', item.nome);
    searchPanelRef.current?.hide();

    !!onAdd && onAdd(item);
  };

  return (
    <div className={className}>
      <FormInput
        name="paciente"
        aria-controls="overlay_panel"
        label="Paciente"
        placeholder="Pesquisar por nome"
        className="p-w-100 input-search"
        icon="fas fa-search"
        iconPos="left"
        onClick={e => searchPanelRef.current?.toggle(e)}
        hideTextErrorSpace
      />

      <OverlayPanel
        id="overlay_panel"
        style={{ width: '100%', maxWidth: '600px' }}
        className="panel-search-paciente-field"
        ref={searchPanelRef}
      >
        <div className="p-w-100">
          {!!items.length && !loadingQuery ? (
            <div
              style={{ maxHeight: '300px', overflowY: 'scroll' }}
              className="fav-content"
            >
              {items.map((item: any, idx: number) => (
                <PacientesRow item={item} handleAdd={handleAdd} key={idx} />
              ))}
            </div>
          ) : (
            <div className="p-d-flex p-jc-center p-my-2">
              {loadingQuery ? (
                <SpinnerLoading full size="sm" />
              ) : (
                <SimpleText>
                  {!!watchPaciente && watchPaciente?.length > 3
                    ? 'Nenhum paciente encontrado'
                    : 'Digite no mínimo 3 caracteres para buscar por pacientes'}
                </SimpleText>
              )}
            </div>
          )}
        </div>
      </OverlayPanel>
    </div>
  );
};

const PacientesRow = ({ handleAdd, item }: any) => {
  const { fotoUrl, urlFoto } = item;
  const foto = fotoUrl || urlFoto || '';
  return (
    <div
      className="row p-col-12 p-ai-center p-py-0"
      onClick={() => handleAdd(item)}
    >
      <div className="p-col-4 p-sm-6 p-d-flex p-gap-2 p-ai-center">
        <Avatar label={item?.nome} className="avatar" image={foto} />
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText>{item.nome}</SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            {capitalizeFirstLetter(item.sexo.descricao)}
          </SimpleText>
        </div>
      </div>
    </div>
  );
};
