import { useState, useEffect } from 'react';

import { Skeleton } from 'primereact/skeleton';
import { useFormContext, useController } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface FieldProfissionalExecutanteProps {
  name: string;
  label?: string;
  className?: string;
}

export const FieldProfissionalExecutante = (
  props: FieldProfissionalExecutanteProps,
) => {
  const {
    name,
    label = 'Profissional executante',
    className = 'p-col-12',
  } = props;

  const { user } = useAppSelector((state: RootState) => state);

  const { idConvenio } = useSolicitacaoExameCirurgia();

  const [profissionalExecutanteOptions, setProfissionalExecutante] = useState<
    any[]
  >([]);
  const [loadingFecth, setLoadingFetch] = useState(true);

  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  useEffect(() => {
    const fetchProfissionalExecutante = async () => {
      try {
        setLoadingFetch(true);

        const response = await ProfissionalAPI.getProfissionalExecutante(
          user.idUsuario,
          {
            idConvenio,
          },
        );

        const profissionalExecutanteFormatted = response.map(
          (profissional: any) => ({
            label: profissional.nomeContratado,
            value: profissional.id,
          }),
        );

        setProfissionalExecutante(profissionalExecutanteFormatted);
      } catch (error) {
      } finally {
        setLoadingFetch(false);
      }
    };

    fetchProfissionalExecutante();
  }, [idConvenio, user.idUsuario]);

  if (loadingFecth) {
    return (
      <div className={className}>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {label}
        </SimpleText>
        <Skeleton className="p-mt-2" height="33px" borderRadius="8px" />
      </div>
    );
  }

  return (
    <DropdownControlled
      className={className}
      control={control}
      name={name}
      label={label}
      options={profissionalExecutanteOptions}
      errorMsg={error?.message}
    />
  );
};
