import { Checkbox } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  paciente: PacientesCarteiraDTO;
  checked: boolean;
  idade: string;
  isTransferencia: boolean;
  onDelete: (id: number) => void;
  onHistory: (id: number) => void;
  onCheckBox: (paciente: PacientesCarteiraDTO) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const {
    paciente,
    onDelete,
    onHistory,
    isTransferencia,
    idade,
    checked,
    onCheckBox,
  } = props;

  const options = [
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(paciente.id),
    },
  ];

  if (isTransferencia) {
    options.push({
      label: 'Historico de transferencia',
      className: '',
      command: () => onHistory(paciente.id),
    });
  }

  return (
    <SearchListMobileRow options={options}>
      <div className="p-d-flex">
        <Checkbox
          checked={checked}
          value={paciente}
          onChange={e => onCheckBox(e.value)}
        />

        {isTransferencia && (
          <div className="p-d-flex p-ai-center p-ml-5">
            <i className="fa fa-angle-double-right icon" />

            <SimpleText
              className="transferido-label"
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Transferido
            </SimpleText>
          </div>
        )}
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
        <br />

        <SimpleText
          className="p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {paciente.nome}
        </SimpleText>

        <SimpleText
          className="p-ml-1"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {paciente.cpf}
        </SimpleText>
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Convênio
        </SimpleText>
        <br />

        <SimpleText
          className="p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {paciente?.convenio?.nomeFantasia || 'Não informado'}
        </SimpleText>

        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_40}>
          {paciente?.cartaoPlano || 'Não informado'}
        </SimpleText>
      </div>

      <div className="p-d-flex">
        <div className="p-col-6">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Sexo
          </SimpleText>
          <br />

          <SimpleText
            className="p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {paciente.sexoDescricao}
          </SimpleText>
        </div>

        <div className="p-col-6">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Pontuação
          </SimpleText>
          <br />

          <SimpleText
            className="p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {paciente.pontuacaoAps}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Idade
        </SimpleText>
        <br />

        <SimpleText
          className="p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {idade}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
