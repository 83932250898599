import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { AnexoQuimioterapiaAPI } from 'src/APIs/ProntuarioAPI/AnexoQuimioterapiaAPI/AnexoQuimioterapiaAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldPlanoStatusProps = DropdownProps & {
  name?: string;
};

export const FieldEstadiamento = ({
  name = 'estadiamento',
  ...rest
}: FieldPlanoStatusProps) => {
  const { control } = useFormContext();

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const loadAPI = useCallback(async () => {
    setIsLoading(true);
    AnexoQuimioterapiaAPI.getEstadiamento()
      .then((d: any) => setItems(d))
      .finally(() => setIsLoading(false))
      .catch(() => {});
  }, []);

  useEffect(() => {
    loadAPI();
  }, [loadAPI]);

  const options = useMemo(() => {
    const options = items.map((item: any) => ({
      label: item.descricao,
      value: item,
    }));

    return options;
  }, [items]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      options={options}
      errorMsg={error?.message}
      loading={isLoading}
      placeholder="Selecione"
      {...rest}
    />
  );
};
