import { useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldTiposEncaminhamentoProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldTiposEncaminhamento = ({
  name = 'tipoEncaminhamento',
  label,
  ...rest
}: FieldTiposEncaminhamentoProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [encaminhamentos, setEncaminhamentos] = useState<any[]>([]);

  const load = async () => {
    const response =
      await AtendimentoEncaminhamentoAPI.loadTiposEncaminhamentos();

    setEncaminhamentos(response?.list || response);
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const encaminhamentoOptions = useMemo(() => {
    const options = (encaminhamentos || []).map(item => ({
      label: item.descricao,
      value: item,
    }));

    return options;
  }, [encaminhamentos]);

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={encaminhamentoOptions}
      errorMsg={errors?.tipoEncaminhamento?.message}
      hideTextErrorSpace={false}
      filter={false}
      {...rest}
    />
  );
};
