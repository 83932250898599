import { createContext } from 'react';

export interface SelectedProfile {
  label: string;
  value: string;
  acronym: string;
  name: string;
}

export interface SelectProfileCreateUserContextData {
  selectedProfile: SelectedProfile | null;
  isProfessionalHealth: boolean;
  selectProfile: (profile: SelectedProfile) => void;
}

export const SelectProfileCreateUserContext = createContext(
  {} as SelectProfileCreateUserContextData
);
