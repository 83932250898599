/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

type IProps = DisclosureType & {
  refetch: () => void;
};

export const DialogInativacaoEncaminhamento = ({
  isOpen,
  close,
  state: stateProp,
  refetch,
}: IProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        justificativaInativacao: Yup.string().required(
          'Justificativa é obrigatório',
        ),
      }),
    ),
  });

  const handleInativar = async (values: any) => {
    if (stateProp && stateProp.tipo === 'Encaminhamento') {
      await AtendimentoEncaminhamentoAPI.inativarEncaminhamento(
        stateProp.id,
        idAtendimento!,
        { ...values },
      );
    }

    if (stateProp && stateProp.tipo === 'Contrarreferencia') {
      await AtendimentoEncaminhamentoAPI.inativarContrarreferencia(
        stateProp.id,
        idAtendimento!,
        { ...values },
      );
    }
    return [close(), refetch()];
  };

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header="Você tem certeza que deseja inativar?"
      style={{ width: '420px' }}
      onClick={e => e.stopPropagation()}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleInativar)}
          className="p-w-100 p-d-flex p-flex-column p-gap-2"
        >
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            className="p-line-height-2"
          >
            Você está inativando a inclusão deste item no prontuário do
            paciente. Ele continuará ser exibido como uma informação inativa.
            Para continuar, justifique.
          </SimpleText>

          <TextareaInputControlled
            name="justificativaInativacao"
            label="Justificativa"
            control={form.control}
            maxLength={200}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
              disabled={form.formState.isSubmitting}
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={form.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export const DialogVisualizarInativacaoEncaminhamento = ({
  isOpen,
  close,
  state,
}: DisclosureType<{ justificativa: string }>) => {
  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header="Inativo"
      onClick={e => e.stopPropagation()}
    >
      <div className="p-w-100 p-d-flex p-flex-column p-gap-2">
        <SimpleText>
          Você está inativando a inclusão deste item no prontuário do paciente.
          Ele continuará ser exibido como uma informação inativa. Para
          continuar, justifique.
        </SimpleText>

        <TextareaInput
          name="justificativa"
          label="Justificativa"
          value={state?.justificativa || ''}
          disabled
        />

        <Button
          label="Fechar"
          btnType="ghost"
          onClick={() => close()}
          stretch
        />
      </div>
    </Dialog>
  );
};
