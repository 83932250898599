import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ListaEsperaAPI from 'src/APIs/AgendaAPI/ListaEsperaAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { generateUniqueId, replaceSpecialChars } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ChipSelect from 'src/components/ChipSelect/ChipSelect';
import GridListLoading from 'src/components/GridList/GridListLoading';

const tipoEspera = [
  { value: 'TODOS', label: 'Todos' },
  { value: 'PRIMEIRA_CONSULTA', label: 'Primeira consulta' },
  { value: 'SEGUIMENTO', label: 'Seguimento' },
];

interface ListaEsperaProps {
  onHide: () => void;
  setPacienteEspera: any;
  setIdListaEspera: any;
}

const ListaEspera = ({
  onHide,
  setPacienteEspera,
  setIdListaEspera,
}: ListaEsperaProps) => {
  const { agenda } = useAppSelector((state: RootState) => state);
  const [listaEspera, setListaEspera] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tipoEsperaFilters, setTipoEsperaFilters] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const filteredValues = useRef<GetListaEsperaDTO[]>([]);

  const getListaEspera = useCallback(async () => {
    try {
      const response = await ListaEsperaAPI.getListaEspera(
        agenda.profissionalAtivo.id,
      );

      const list = response.map((e: any) => {
        const tipoEsperaFilter = [
          e?.tipoEspera?.descricao?.replace(' ', '_').toUpperCase(),
        ];

        return { label: tipoEsperaFilter, value: e };
      });

      setListaEspera(list);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [agenda.profissionalAtivo.id]);

  useEffect(() => {
    getListaEspera();
  }, [getListaEspera]);

  const handleChangeTipoEsperaFilter = (values: string[] = []) => {
    if (values.includes('TODOS') && !tipoEsperaFilters.includes('TODOS')) {
      setTipoEsperaFilters(['TODOS']);
      return;
    }

    const removeTodos = values.filter((value: any) => value !== 'TODOS');
    setTipoEsperaFilters(removeTodos);
  };

  const waitListFiltered = useMemo(() => {
    if (tipoEsperaFilters.includes('TODOS') || !tipoEsperaFilters.length) {
      return listaEspera;
    }

    const filtered = listaEspera.filter((list: any) => {
      const tipoEspera =
        list.label && tipoEsperaFilters.includes(list.label[0]);
      return tipoEspera;
    });

    return filtered;
  }, [tipoEsperaFilters, listaEspera]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setSearchValue(value);

      if (listaEspera) {
        const results = listaEspera?.filter((element: any) => {
          return (
            replaceSpecialChars(
              element.value.paciente.nome.toUpperCase(),
            ).includes(replaceSpecialChars(value?.toUpperCase())) ||
            element.value.contato === value
          );
        });

        filteredValues.current = results;
      }
    },
    [listaEspera],
  );

  const items = !searchValue ? waitListFiltered : filteredValues.current;

  const renderCard = useCallback(
    (listaEspera: any) => {
      const { value } = listaEspera;
      return (
        <div
          className="p-p-2 card-espera"
          onClick={() => {
            setPacienteEspera(value?.paciente);

            setIdListaEspera(value?.id);
            onHide();
          }}
        >
          <div className="p-d-flex p-ai-center">
            <Avatar
              className="avatar p-mr-2"
              label={value?.paciente?.nome}
              image={value?.paciente?.fotoUrl || ''}
            />

            <div className="p-flex-column">
              <div>
                <SimpleText
                  bold
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                >
                  {value?.paciente?.nome}
                </SimpleText>
              </div>

              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {value?.paciente?.sexoDescricao?.toLowerCase()}
              </SimpleText>
            </div>
          </div>

          <div className="p-ml-2 p-mt-2">
            <SimpleText
              bold
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              {value?.paciente?.telefonePrincipal
                ? value?.paciente?.telefonePrincipal
                : 'Não informado'}
            </SimpleText>
          </div>
        </div>
      );
    },
    [onHide, setIdListaEspera, setPacienteEspera],
  );

  return (
    <div className="container-listaEspera">
      <TextInput
        icon="pi pi-search"
        placeholder="Pesquisar"
        value={searchValue}
        onChange={e => handleSearch(e)}
      />

      <ChipSelect
        className="p-my-3"
        value={tipoEsperaFilters}
        options={tipoEspera}
        onChange={handleChangeTipoEsperaFilter}
      />

      <SimpleText bold fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
        Lista Espera
      </SimpleText>

      <div className="scroll p-mt-3">
        {items?.length > 0 &&
          items.map((list: any) => (
            <div key={generateUniqueId()}>{renderCard(list)}</div>
          ))}

        {loading && <GridListLoading />}
      </div>
    </div>
  );
};

export default ListaEspera;
