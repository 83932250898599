import React from 'react';

import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const DefinicaoCamposTexto = () => {
  const { control, watch } = useFormContext();

  const watchCamposTexto = watch('camposTexto');

  return (
    <div className="definicao-container p-p-2">
      <TextInputControlled control={control} name="camposTexto" hidden />
      <TextareaInputControlled
        control={control}
        label="Anamnese"
        name="camposTexto.anamnese"
        placeholder="Excreva os sintomas do paciente"
        maxLength={4000}
        maxLengthSpanDesc
      />
      <TextareaInputControlled
        control={control}
        label="Exame físico"
        name="camposTexto.exameFisico"
        maxLength={4000}
        maxLengthSpanDesc
      />
      <TextareaInputControlled
        control={control}
        label="Prognóstico"
        name="camposTexto.prognostico"
        maxLength={4000}
        maxLengthSpanDesc
      />
      <TextareaInputControlled
        control={control}
        label="Decisão terapêutica"
        name="camposTexto.decisaoTerapeuta"
        maxLength={4000}
        maxLengthSpanDesc
      />
      <TextareaInputControlled
        control={control}
        label="Patologia detectada"
        name="camposTexto.patologiaDetectada"
        placeholder="Excreva os sintomas do paciente"
        maxLength={4000}
        maxLengthSpanDesc
      />
    </div>
  );
};

export default DefinicaoCamposTexto;
