import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import useDebounce from 'src/utils/useDebounce';
import { stringToEnum } from 'src/utils/utils';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type Props = DropdownProps & {
  name?: string;
  label?: string;
  tipoUsuario?: any;
};

export const FieldSearchUsuario = ({
  name = 'usuario',
  label = 'Usuário',
  tipoUsuario,
  ...props
}: Props) => {
  const [users, setUsers] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setIsLoading] = useState(false);

  const form = useFormContext();

  if (!form) {
    throw new Error('Field should be used with a form context');
  }

  const debouncedSearchConvenio = useDebounce(searchQuery, 350);

  const fetchAPI = useCallback(
    async (query: string) => {
      if (query?.length > 0) {
        try {
          const response = await UsuarioAPI.loadUsuarios({
            query: query,
            ...(tipoUsuario?.descricao && {
              tipo: stringToEnum(tipoUsuario.descricao),
            }),
          });

          setUsers(response.list);
          setIsLoading(false);
        } catch {}
      }
    },
    [tipoUsuario?.descricao],
  );

  useEffect(() => {
    fetchAPI(debouncedSearchConvenio);
  }, [fetchAPI, debouncedSearchConvenio]);

  const userAtual = form.watch(name);

  const usersOtions = useMemo(() => {
    const options = users.map(user => ({
      label: user.nome,
      value: user,
    }));

    // valida se user atual está listado nas options
    if (userAtual) {
      const isUserAtualVisivel = users.find(conv => conv.id === userAtual.id);

      if (!isUserAtualVisivel) {
        options.unshift({
          label: userAtual.nome,
          value: userAtual,
        });
      }
    }

    return options;
  }, [users, userAtual]);

  const options = useMemo(
    () => (!!usersOtions.length ? usersOtions : defaultOption),
    [usersOtions],
  );

  return (
    <DropdownControlled
      control={form.control}
      name={name}
      label={label}
      placeholder="Pesquisar por usuário"
      onFilter={e => [setSearchQuery(e.filter), setIsLoading(true)]}
      options={options}
      className="p-col-12 input-search"
      dataKey="id"
      carregandoFiltro={loading}
      {...props}
    />
  );
};
