import {
  Editor as PrimeEditor,
  EditorProps as PrimeEditorProps,
} from 'primereact/editor';

import useTheme from 'src/core/themes/useTheme';

import { isEqualElement } from 'src/utils/domUtils';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './Editor.scss';

export interface EditorProps extends PrimeEditorProps {
  errorMsg?: string | undefined | null;
}

export const Editor = ({ errorMsg, ...props }: EditorProps) => {
  const { theme = '' } = useTheme();
  const handleChange = (event: any) => {
    const editorValue = document.createElement('div');
    editorValue.innerHTML = props.value || '';

    const newEditorValue = document.createElement('div');
    newEditorValue.innerHTML = event.htmlValue || '';

    if (!isEqualElement(editorValue, newEditorValue)) {
      props.onTextChange?.(event);
    }
  };

  return (
    <>
      <PrimeEditor
        className={`p-editor ${theme}`}
        {...props}
        onTextChange={handleChange}
      />
      {!!errorMsg && (
        <SimpleText className="error" fontSize={FONT_SIZE.XXXS}>
          {' '}
          {errorMsg}{' '}
        </SimpleText>
      )}
    </>
  );
};
