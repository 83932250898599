import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/medicamento-em-uso';

export const getMedicamentosUso = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<MedicamentosUso[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};

export const adicionarMedicamentoUso = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });

export const alterarMedicamentoUso = (
  idMedicamento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idMedicamento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarMedicamento = (
  idMedicamento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/status/${idMedicamento}`,
    data: payload,
    return: true,
    ...options,
  });

export const alterarMedicamentoLembrete = (
  idMedicamento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/lembrete/${idMedicamento}`,
    return: true,
    ...options,
  });

export const alterarMedicamentoEmUso = (
  idMedicamento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/em-uso/${idMedicamento}`,
    return: true,
    ...options,
  });

export const removerMedicamento = (
  idMedicamento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idMedicamento}`,
    return: true,
    ...options,
  });

export const getViaMedicamentos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ViasMedicamentos> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/via-medicamentos`,
    hideToast: true,
    ...options,
  });

const MedicamentosUsoAPI = {
  getMedicamentosUso,
  adicionarMedicamentoUso,
  alterarMedicamentoUso,
  inativarMedicamento,
  alterarMedicamentoLembrete,
  alterarMedicamentoEmUso,
  removerMedicamento,
  getViaMedicamentos,
};

export default MedicamentosUsoAPI;
