import { useMemo, useState, useEffect } from 'react';

import { MovimentacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/Movimentacao/MovimentacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { generateUniqueId } from 'src/utils/utils';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './MovimentacaoGrid/Header';
import { Row } from './MovimentacaoGrid/Row';

import './Movimentacao.scss';

const Movimentacao = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);
  const [reload, setReload] = useState<number>(0);
  const [searchType, setSearchType] = useState<string>(
    consultorios?.ativo?.tipoMonitoramentoCarteiraAps || 'PACIENTES',
  );

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const renderRow = (carteira: any) => (
    <Row key={generateUniqueId()} carteira={carteira} searchType={searchType} />
  );

  const searchOptions = useMemo(() => {
    const result = [
      {
        label: 'Pacientes',
        value: 'PACIENTES',
      },
    ];

    if (consultorios?.ativo?.tipoMonitoramentoCarteiraAps === 'PROFISSIONAL') {
      result.push({ label: 'Profissional', value: 'PROFISSIONAL' });
      return result;
    }

    result.push({ label: 'Equipe', value: 'EQUIPE' });
    return result;
  }, [consultorios?.ativo?.tipoMonitoramentoCarteiraAps]);

  useEffect(() => {
    if (consultorios?.ativo?.tipoMonitoramentoCarteiraAps)
      setSearchType(consultorios?.ativo?.tipoMonitoramentoCarteiraAps);
  }, [consultorios?.ativo?.tipoMonitoramentoCarteiraAps]);

  if (searchType === 'PACIENTES') {
    return (
      <PacienteSearch
        idConsultorio={consultorios?.ativo?.id}
        searchOptions={searchOptions}
        searchType={searchType}
        setSearchType={setSearchType}
        reload={reload}
        renderRow={renderRow}
        reloadList={reloadList}
        isEquipeCuidadora={
          consultorios?.ativo?.tipoMonitoramentoCarteiraAps === 'EQUIPE'
        }
      />
    );
  }

  if (searchType === 'PROFISSIONAL') {
    return (
      <ProfissionalCarteira
        idConsultorio={consultorios?.ativo?.id}
        searchOptions={searchOptions}
        searchType={searchType}
        setSearchType={setSearchType}
        reload={reload}
        renderRow={renderRow}
        reloadList={reloadList}
      />
    );
  }

  return (
    <EquipeSearch
      idConsultorio={consultorios?.ativo?.id}
      searchOptions={searchOptions}
      searchType={searchType}
      setSearchType={setSearchType}
      reload={reload}
      renderRow={renderRow}
      reloadList={reloadList}
    />
  );
};

const PacienteSearch = ({
  idConsultorio,
  searchOptions,
  searchType,
  setSearchType,
  reload,
  renderRow,
  reloadList,
  isEquipeCuidadora,
}: any) => {
  const fetchAPI = ({ query, ...rest }: any) => {
    return MovimentacaoCarteiraAPI.getPacientes({
      filtro: query,
      idConsultorio,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...rest,
    });
  };

  const placeholder = isEquipeCuidadora
    ? 'Pesquisar pela equipe ou paciente'
    : 'Pesquisar pelo profissional ou paciente';

  return (
    <div id="movimentacao-carteira-container" className="p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          consultorioRequired
          reloadWhenEmpresaChange
          paginatedList={false}
        >
          <div className="p-grid p-ai-end p-flex-wrap p-mb-3 search-content">
            <SearchList.SearchInput
              className="p-col-12 p-lg-4"
              label="Pesquisar"
              placeholder={placeholder}
            />

            <div className="p-col-12 p-lg-2 p-pb-0">
              <Dropdown
                filter={false}
                options={searchOptions}
                onChange={e => {
                  setSearchType(e.value);
                  reloadList();
                }}
                value={searchType}
                hideTextErrorSpace
              />
            </div>
          </div>

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-end p-ai-center p-flex-wrap">
              <SearchList.TotalCount />
            </div>

            <SearchList.Header>
              <Header />
            </SearchList.Header>

            <SearchList.NonPageableScrollArea
              renderRow={renderRow}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
    </div>
  );
};

const EquipeSearch = ({
  idConsultorio,
  searchOptions,
  searchType,
  setSearchType,
  reload,
  renderRow,
  reloadList,
}: any) => {
  const fetchAPI = ({ query, ...rest }: any) => {
    return MovimentacaoCarteiraAPI.getEquipeCarteira({
      filtro: query,
      idConsultorio,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...rest,
    });
  };

  return (
    <div id="movimentacao-carteira-container" className="p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          consultorioRequired
          reloadWhenEmpresaChange
          paginatedList={false}
        >
          <div className="p-grid p-ai-end p-flex-wrap p-mb-3 search-content">
            <SearchList.SearchInput
              className="p-col-12 p-lg-4"
              label="Pesquisar"
              placeholder="Pesquisar pela equipe ou paciente"
            />

            <div className="p-col-12 p-lg-2 p-pb-0">
              <Dropdown
                filter={false}
                options={searchOptions}
                onChange={e => {
                  setSearchType(e.value);
                  reloadList();
                }}
                value={searchType}
                hideTextErrorSpace
              />
            </div>
          </div>

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-end p-ai-center p-flex-wrap">
              <SearchList.TotalCount />
            </div>

            <SearchList.Header>
              <Header />
            </SearchList.Header>

            <SearchList.NonPageableScrollArea
              renderRow={renderRow}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
    </div>
  );
};

const ProfissionalCarteira = ({
  idConsultorio,
  searchOptions,
  searchType,
  setSearchType,
  reload,
  renderRow,
  reloadList,
}: any) => {
  const fetchAPI = ({ query, ...rest }: any) => {
    return MovimentacaoCarteiraAPI.getEquipeCarteira({
      filtro: query,
      idConsultorio,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...rest,
    });
  };

  return (
    <div id="movimentacao-carteira-container" className="p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          consultorioRequired
          reloadWhenEmpresaChange
          paginatedList={false}
        >
          <div className="p-grid p-ai-end p-flex-wrap p-mb-3 search-content">
            <SearchList.SearchInput
              className="p-col-12 p-lg-4"
              label="Pesquisar"
              placeholder="Pesquisar pelo profissional"
            />

            <div className="p-col-12 p-lg-2 p-pb-0">
              <Dropdown
                filter={false}
                options={searchOptions}
                onChange={e => {
                  setSearchType(e.value);
                  reloadList();
                }}
                value={searchType}
                hideTextErrorSpace
              />
            </div>
          </div>

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-end p-ai-center p-flex-wrap">
              <SearchList.TotalCount />
            </div>

            <SearchList.Header>
              <Header />
            </SearchList.Header>

            <SearchList.NonPageableScrollArea
              renderRow={renderRow}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
    </div>
  );
};

export default Movimentacao;
