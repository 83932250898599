import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import { List } from './List/List';

import './Pacote.scss';

export const Pacote = () => {
  return (
    <Page>
      <>
        <PageHeader
          title="Pacote"
          subtitle="Preencha os campos para completar o cadastro"
        />

        <Page id="pacote-container" white content>
          <List />
        </Page>
      </>
    </Page>
  );
};
