import { Image } from 'primereact/image';

import { convertYoutubeURLtoEmbed } from 'src/utils/utils';

import { Button } from '../_UI';
import Divider from '../Basics/Divider/Divider';
import SimpleText from '../Basics/SimpleText/SimpleText';

import { Etapas } from './types';

export const AssistenteVirtualDialogContent = ({ item }: { item: Etapas }) => {
  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-col-12 p-py-0">
      <Divider layout="horizontal" className="p-w-100 p-m-0" />

      <SimpleText className="p-col-12 p-px-0">{item.descricaoDica}</SimpleText>

      {item.linkSaibaMais && (
        <Button
          className="p-col-3 p-md-2"
          label="Saiba mais"
          btnType="outline"
          onClick={() => window.open(item.linkSaibaMais, '_blank')}
        />
      )}

      {item.tipoConteudo === 'VIDEO' ? (
        <iframe
          className="p-mt-2"
          src={convertYoutubeURLtoEmbed(item.linkConteudo)}
          style={{
            width: '100%',
            height: '330px',
            backgroundColor: 'grey',
            borderRadius: '6px',
          }}
        />
      ) : (
        <Image
          src={item.linkConteudo}
          className="image-etapa-content"
          preview
        />
      )}
    </div>
  );
};
