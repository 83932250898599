import { useState } from 'react';

type BuscaRapidaSelecionadosType = {
  procedimentosSelected: any[];
  setProcedimentosSelected: React.Dispatch<React.SetStateAction<any[]>>;
  handleRemoveProcedimentoFromSelectedList: (procedimentoId: number) => void;
};

export const useBuscaRapidaSelecionados = (): BuscaRapidaSelecionadosType => {
  const [procedimentosSelected, setProcedimentosSelected] = useState<any[]>([]);

  const handleRemoveProcedimentoFromSelectedList = (procedimentoId: number) => {
    setProcedimentosSelected(prevSelected => {
      const newSelected = prevSelected.filter(
        (procedimento: any) => procedimento?.id !== procedimentoId,
      );

      return newSelected;
    });
  };

  return {
    procedimentosSelected,
    setProcedimentosSelected,
    handleRemoveProcedimentoFromSelectedList,
  };
};
