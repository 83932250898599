import { memo } from 'react';

import {
  InputNumber as PrimeInputNumber,
  InputNumberProps as PrimeInputNumberProps,
} from 'primereact/inputnumber';

import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';

import SimpleText, { FONT_SIZE } from '../SimpleText/SimpleText';

export interface TextInputMinuteProps extends PrimeInputNumberProps {
  label?: string | undefined;
  errorMsg?: string | any;
}

const TextInputMinute = (props: TextInputMinuteProps) => {
  const { className, name, label, errorMsg, ...rest } = props;

  const renderLabel = () => {
    if (label || label === '') {
      return <ComponentsLabel name={name} label={label} required />;
    }
    return <></>;
  };

  return (
    <div className={`TextInput ${className || ''} ${errorMsg ? 'error' : ''}`}>
      {renderLabel()}
      <PrimeInputNumber {...rest} autoFocus suffix=" minutos" />
      <div className={'p-d-flex p-jc-between p-ai-center p-mt-1'}>
        <div>
          {errorMsg && (
            <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
              {errorMsg}
            </SimpleText>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TextInputMinute);
