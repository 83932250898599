import {
  Panel as PrimePanel,
  PanelProps as PrimePanelProps,
} from 'primereact/panel';
import './Panel.scss';

export interface PanelProps extends PrimePanelProps {
  children: JSX.Element | JSX.Element[];
}
const Panel = ({ ...rest }: PanelProps) => {
  return (
    <div className="Panel">
      <PrimePanel className="Panel" {...rest} />
    </div>
  );
};

export default Panel;
