import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './ProximosPacientesHeader.scss';

interface ProximosPacientesProps {
  updateButton: JSX.Element;
  pesquisaProntuarioButton: JSX.Element;
  ordenacao: JSX.Element;
}

function ProximosPacientesHeader({
  updateButton,
  ordenacao,
  pesquisaProntuarioButton,
}: ProximosPacientesProps) {
  return (
    <div id="proximos-pacientes-header">
      <div className="p-grid p-align-center p-justify-between">
        <SimpleText
          className="p-col-10 p-md-4"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Próximos pacientes
        </SimpleText>

        <div className="p-col-12 p-md-7 p-d-flex p-ai-center p-jc-start p-jc-md-end p-order-3 p-order-md-2">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Ordenar por:
          </SimpleText>

          {ordenacao}
        </div>

        <div className="p-col p-d-flex p-jc-end p-order-2 p-order-md-3 p-gap-1">
          {updateButton} {pesquisaProntuarioButton}
        </div>
      </div>
    </div>
  );
}

export default ProximosPacientesHeader;
