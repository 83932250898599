import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldProps = DropdownProps & {
  name?: string;
  label?: string;
};

const tipoUusario = [
  {
    label: 'Administrador',
    value: 'ADMINISTRADOR',
  },
  {
    label: 'Master',
    value: 'MASTER',
  },
  {
    label: 'Profissional Saúde',
    value: 'PROFISSIONAL_SAUDE',
  },
  {
    label: 'Secretariado',
    value: 'SECRETARIO',
  },
];

export const FieldTipoUsuario = ({
  name = 'tipoUsuario',
  label,
  ...rest
}: FieldProps) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <DropdownControlled
      className="p-col-12 p-px-0"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={tipoUusario}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
