export const orderDatasProgramadas = (
  datasProgramadas: any[],
  props: 'dataExecucao' | 'dataPrevistaRealizacao',
) => {
  return datasProgramadas.sort((dataProgramada1, dataProgramada2) => {
    const data1 = new Date(dataProgramada1[props]);
    const data2 = new Date(dataProgramada2[props]);
    return data1.getTime() - data2.getTime();
  });
};
