import { useContext } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';

import MenuConfig from './MenuConfig/MenuConfig';
import './Config.scss';

const Config = () => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const isMenu = pathname === '/config';
  return (
    <Page className={`Config ${theme?.theme || ''}`}>
      {isMenu ? <MenuConfig /> : <Outlet />}
    </Page>
  );
};

export default Config;
