import { memo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { FieldCamposProntuario, FieldTipoProntuario } from './components';
import Button from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import { TextInputSearchControlled } from 'src/components/Basics/TextInputSearch/TextInputSearchControlled';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const BuscaAvancadaSearchHeader = memo(() => {
  const { setFilter: setFilterSearchList } = useSearchList();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        palavraChave: Yup.string().matches(/.{5,}/, {
          excludeEmptyString: true,
          message: 'Digite no mínimo 5 caracteres para seguir com a pesquisa.',
        }),
        paciente: Yup.string().matches(/.{5,}/, {
          excludeEmptyString: true,
          message: 'Digite no mínimo 5 caracteres para seguir com a pesquisa.',
        }),
        dataInicial: Yup.date().nullable(),
        dataFinal: Yup.date()
          .when('dataInicial', {
            is: (value: any) => !!value,
            then: Yup.date().required('Data final é obrigatório'),
          })
          .nullable(),
      }),
    ),
  });

  const requiredData = form.watch(['palavraChave', 'paciente']);
  const dataInicialWatch = form.watch('dataInicial');

  const onSubmit = (values: any) => {
    if (!requiredData.some(el => !!el)) {
      form.setError('paciente', {
        message: 'Preencha ao menos uma das informações acima para pesquisar.',
      });
      return;
    }

    const dataInicial = values.dataInicial
      ? dayjs(values.dataInicial).format('YYYYMMDD')
      : undefined;
    const dataFinal = values.dataFinal
      ? dayjs(values.dataFinal).format('YYYYMMDD')
      : undefined;

    setFilterSearchList({ ...values, dataInicial, dataFinal });
  };

  const onClean = () => {
    form.reset({});
    setFilterSearchList({});
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-grid p-w-100 p-ai-center"
      >
        <div className="p-col-12 p-mb-3">
          <TextInputSearchControlled
            name="palavraChave"
            errorMsg={form.formState.errors?.palavraChave?.message}
            placeholder="Digite o que deseja pesquisar"
          />
        </div>
        <div className="p-col-12 p-mb-3">
          <TextInputSearchControlled
            name="paciente"
            errorMsg={form.formState.errors?.paciente?.message}
            placeholder="Digite o nome do paciente"
          />
        </div>

        <FieldCamposProntuario label="Em" className="p-md-3 p-col-6" />
        <FieldTipoProntuario label="Que tenha" className="p-md-3 p-col-6" />
        <CalendarInputControlled
          control={form.control}
          label="Data Inicial"
          name="dataInicial"
          className="p-md-3 p-col-6"
          maxDate={new Date()}
          showIcon
        />
        <CalendarInputControlled
          control={form.control}
          label="Data Final"
          name="dataFinal"
          minDate={dataInicialWatch || undefined}
          className="p-md-3 p-col-6"
          showIcon
        />

        <div className="p-d-flex p-w-100 p-ai-center">
          <div className="p-col-4 p-md-2">
            <Button
              label="Limpar"
              btnType="outline"
              type="button"
              onClick={onClean}
            />
          </div>
          <Button label="Pesquisar" stretch />
        </div>
      </form>
    </FormProvider>
  );
});
