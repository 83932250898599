import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/hospitais';
export const loadHospitals = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<Hospital[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadHospitalById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetHospitalDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendHospital = (
  payload: PostHospitalDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateHospital = (
  id: number,
  payload: PostHospitalDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeHospitalById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetHospitalDTO> => {
  return Http.delete({ service: 'adm', url: `${basePath}/${id}`, ...options });
};

const HospitalAPI = {
  loadHospitals,
  loadHospitalById,
  sendHospital,
  removeHospitalById,
  updateHospital,
};

export default HospitalAPI;
