import { useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import FuncionalidadeAPI from 'src/APIs/AdminAPI/FuncionalidadeAPI/FuncionalidadeAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './DialogEditarFuncionalidade.scss';

type DialogEditarFuncionalidadeProps = DisclosureType & {
  reloadList(): void;
};

export const DialogEditarFuncionalidade = ({
  close,
  state: item,
  reloadList,
}: DialogEditarFuncionalidadeProps) => {
  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Digite um nome para funcionalidade'),
    descricao: Yup.string().nullable(),
    informacoesComplementares: Yup.string().nullable(),
    descricaoParametrizacao: Yup.string().nullable(),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: item?.nome,
      descricao: item?.descricao,
      informacoesComplementares: item?.informacoesComplementares,
      descricaoParametrizacao: item?.descricaoParametrizacao,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const handleFuncionalidadeUpdate = useCallback(
    async (data: any) => {
      try {
        const funcionalidadeEditada = {
          ...item,
          nome: data.nome,
          descricao: data.descricao,
          informacoesComplementares: data.informacoesComplementares,
          descricaoParametrizacao: data.descricaoParametrizacao,
          assistencia: data.assistencia,
        };

        await FuncionalidadeAPI.updateFuncionalidade(
          item.id!,
          funcionalidadeEditada,
        );

        reloadList();
        close();
      } catch {}
    },
    [close, item, reloadList],
  );

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Nova configuração da funcionalidade"
      visible
      onHide={close}
      footer={() => <div />}
      maximizedTabletSize
    >
      <div className="modal-body-add-config-funcionalidade">
        <SimpleText
          className="p-mb-2"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Título da função
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
          Função pertencente à{' '}
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {item?.modulo}
          </SimpleText>
        </SimpleText>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleFuncionalidadeUpdate)}>
            <TextareaInputControlled
              control={control}
              name="nome"
              label="Descrição da funcionalidade"
              className="grid p-col-12 p-md-12"
              maxLength={300}
              readOnly
            />
            <TextareaInputControlled
              control={control}
              name="descricao"
              label="Descrição técnica da funcionalidade"
              className="grid p-col-12 p-md-12"
              maxLength={300}
              disabled
            />
            <TextareaInputControlled
              control={control}
              name="informacoesComplementares"
              label="Informações complementares"
              className="grid p-col-12 p-md-12"
              maxLength={300}
              disabled
            />
            <TextareaInputControlled
              control={control}
              name="descricaoParametrizacao"
              label="Parametrização"
              className="grid p-col-12 p-md-12"
              maxLength={300}
              disabled
            />

            <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
              <Button
                btnType="ghost"
                label="Voltar"
                onClick={() => close()}
                stretch
              />
              <Button
                label={'Salvar'}
                type="submit"
                stretch
                loading={isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
};
