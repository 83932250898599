import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldProps = DropdownProps & {
  name?: string;
  label?: string;
};

export const FieldTelaApresentacao = ({
  name = 'funcionalidade',
  label,
  ...rest
}: FieldProps) => {
  const { control } = useFormContext();
  const [item, setItem] = useState<any[]>([]);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const orderList = (item: any) => {
    return item.sort((a: any, b: any) => {
      if (a.descricao < b.descricao) return -1;
      if (a.descricao > b.descricao) return 1;
      return 0;
    });
  };

  const load = useCallback(async () => {
    try {
      const res = await AssistenciaAPI.getFuncionalidadesAssistencia({
        throwError: true,
      });

      !!res?.length && setItem(orderList(res));
    } catch {
      setItem([]);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = item.map(via => ({
      label: via?.descricao,
      value: via,
    }));

    return data;
  }, [item]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      showClear
      {...rest}
    />
  );
};
