import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { useLocation, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import ProntuarioInfoAPI from 'src/APIs/ProntuarioAPI/ProntuarioInfo/ProntuarioInfoAPI';
import ProntuarioPersistenteAPI from 'src/APIs/ProntuarioAPI/ProntuarioPersistenteAPI/ProntuarioPersistenteAPI';
import { ReactComponent as IconeChevronsLeft } from 'src/assets/icons/chevrons-left.svg';
import { ReactComponent as IconeChevronsRight } from 'src/assets/icons/chevrons-right.svg';
import { ReactComponent as IconeHistoricoFamiliar } from 'src/assets/icons/icon-historico-familiar.svg';
import { ReactComponent as IconeHistoricoPessoal } from 'src/assets/icons/icon-historico-pessoal.svg';
import { ReactComponent as IconeInfoPaciente } from 'src/assets/icons/icon-info-paciente.svg';
import { ReactComponent as IconeSigiloMedico } from 'src/assets/icons/icon-sigilo-profissional.svg';

import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import Badge from 'src/components/Badge/Badge';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Separator from 'src/components/Separator/Separator';

import { Evolucao } from './Evolucao/Evolucao';

import './BarraLateralProntuario.scss';
interface BarraLateralProntuarioProps {
  idPaciente: number | null;
}

function BarraLateralProntuario({ idPaciente }: BarraLateralProntuarioProps) {
  // será arrumado mais tarde
  // const accordionCustom = document.querySelector('.accordion-custom');
  // if (accordionCustom) {
  //   window.addEventListener('scroll', function() {
  //     const accordion = document.querySelector('.p-accordion') as HTMLElement;
  //     const accordionRect = accordion.getBoundingClientRect();
  //     const parent = accordion.parentElement;
  //     const parentTop = parent ? parent.offsetTop : 0;
  //     const headerElement = document.querySelector('#cabecalho-prontuario') as HTMLElement;
  //     const headerHeight = headerElement.offsetHeight;
  //     if (accordionRect.top < 0 && accordionRect.bottom > 0) {
  //       const maxTop = Math.max(accordionRect.top, parentTop + (parent ? parent.offsetHeight : 200) - accordion.offsetHeight);
  //       const newTop = Math.min(maxTop, parentTop + (parent ? parent.offsetHeight : 0) - accordion.offsetHeight);
  //       accordion.style.top = `${newTop}px`;
  //     } else {
  //       accordion.style.top = `${headerHeight}px`;
  //     }
  //   });
  // }

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    getInfoLembretes,
    infoLembretes,
    showBarraLateral,
    setShowBarraLateral,
    prontuarioPersistente,
    atendimentoStatus,
  } = useAtendimento();

  const [infoSecretaria, setInfoSecretaria] = useState<any>({});
  const [textoInfoPaciente, setTextoInfoPaciente] = useState<string>('');
  const [textoInfoSigilo, setTextoInfoSigilo] = useState<string>('');
  const [textoInfoLegado, setTextoInfoLegado] = useState<string>('');
  const [, setActiveIndex] = useState<number | null>(null);

  const accordionRef = useRef(null);

  const putInfoPaciente = useCallback(async () => {
    const payload = {
      informacaoPaciente: textoInfoPaciente,
      sigiloMedico: textoInfoSigilo,
      historicoLegado: textoInfoLegado,
    };
    await ProntuarioInfoAPI.putInfoPaciente(Number(idPaciente), payload);
  }, [textoInfoPaciente, textoInfoSigilo, textoInfoLegado, idPaciente]);

  useEffect(() => {
    const getInfoSecretaria = async () => {
      if (!idAtendimento) return;
      const response = await ProntuarioInfoAPI.getInfoSecretaria(
        Number(idAtendimento),
      );
      setInfoSecretaria(response.data);
    };
    getInfoSecretaria();
  }, [idAtendimento]);

  useEffect(() => {
    const getInfoPaciente = async () => {
      if (idPaciente) {
        const response = await ProntuarioInfoAPI.getInfoPaciente(
          Number(idPaciente),
        );
        if (response.status === 200) {
          setTextoInfoPaciente(
            response.data.informacaoPaciente
              ? response.data.informacaoPaciente
              : '',
          );
          setTextoInfoSigilo(
            response.data.sigiloMedico ? response.data.sigiloMedico : '',
          );
          setTextoInfoLegado(
            response.data.historicoLegado ? response.data.historicoLegado : '',
          );
        }
      }
    };
    getInfoPaciente();
  }, [idPaciente]);

  useEffect(() => {
    getInfoLembretes(Number(idPaciente));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPaciente]);

  const lembretes = useMemo(() => {
    let quantidadeLembretes = 0;
    let lembretesPaciente: any[] = [];

    for (const lembrete in infoLembretes) {
      if (Array.isArray(infoLembretes[lembrete])) {
        quantidadeLembretes += infoLembretes[lembrete].length;
        switch (lembrete) {
          case 'motivosAtendimento':
            lembretesPaciente = [
              ...lembretesPaciente,
              {
                lembrete: 'Motivo de atendimento',
                info: infoLembretes[lembrete],
              },
            ];
            break;
          case 'alergias':
            lembretesPaciente = [
              ...lembretesPaciente,
              {
                lembrete: 'Alergias e intolerâncias',
                info: infoLembretes[lembrete],
              },
            ];
            break;
          case 'medicamentosEmUso':
            lembretesPaciente = [
              ...lembretesPaciente,
              {
                lembrete: 'Medicamentos em uso',
                info: infoLembretes[lembrete],
              },
            ];
            break;
          case 'opmes':
            lembretesPaciente = [
              ...lembretesPaciente,
              { lembrete: 'OPME’s', info: infoLembretes[lembrete] },
            ];
            break;
          case 'vacinas':
            lembretesPaciente = [
              ...lembretesPaciente,
              { lembrete: 'Vacinas', info: infoLembretes[lembrete] },
            ];
            break;
          case 'pedidosCuidado':
            lembretesPaciente = [
              ...lembretesPaciente,
              { lembrete: 'Pedidos de cuidado', info: infoLembretes[lembrete] },
            ];
            break;
          case 'comorbidades':
            lembretesPaciente = [
              ...lembretesPaciente,
              { lembrete: 'Problemas', info: infoLembretes[lembrete] },
            ];
            break;
          case 'avaliacaoRisco':
            lembretesPaciente = [
              ...lembretesPaciente,
              { lembrete: 'Avaliação de risco', info: infoLembretes[lembrete] },
            ];
            break;
          default:
            lembretesPaciente = [
              ...lembretesPaciente,
              { lembrete: 'Outro', info: infoLembretes[lembrete] },
            ];
            break;
        }
      }
    }

    return showBarraLateral ? (
      <AccordionTab
        // onClick={(e) => handleHeaderClick(e)}
        header={
          quantidadeLembretes > 0 ? (
            <>
              <i
                style={{ color: '#54a275' }}
                className="fa-solid fa-bell p-overlay-badge"
              >
                {/* {destaque} */}
              </i>
              <span style={{ color: '#54a275' }}>Lembretes</span>
              <Badge
                className="p-mx-2 lembretes-badge"
                value={String(quantidadeLembretes)}
              ></Badge>
            </>
          ) : (
            <>
              <i className="fa-solid fa-bell p-overlay-badge"></i>
              <span style={{ color: '#74777e' }}>Lembretes</span>
            </>
          )
        }
      >
        {lembretesPaciente.map(lembrete => {
          if (lembrete.info.length > 0) {
            switch (lembrete.lembrete) {
              case 'Motivo de atendimento':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <p className="display-linebreak p-mx-2 texto-lateral">
                            {motivo.tipoMotivo.descricao}
                          </p>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'Alergias e intolerâncias':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <p className="display-linebreak p-mx-2 texto-lateral">
                            {motivo.descricao}
                          </p>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'Medicamentos em uso':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XXS}
                            fontColor={FONT_COLOR.COLOR_16}
                            className="display-linebreak p-mx-2 p-mt-1"
                            medium
                          >
                            {motivo.nomeMedicamento}
                          </SimpleText>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'OPME’s':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XXS}
                            fontColor={FONT_COLOR.COLOR_16}
                            className="display-linebreak p-mx-2 p-mt-1"
                            medium
                          >
                            {motivo.material?.nomeComercial}
                          </SimpleText>
                          <p className="display-linebreak p-mx-2 texto-lateral">
                            Quantidade: {motivo.quantidade}
                          </p>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'Vacinas':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XXS}
                            fontColor={FONT_COLOR.COLOR_16}
                            className="display-linebreak p-mx-2 p-mt-1"
                            medium
                          >
                            {motivo.descricao}
                          </SimpleText>
                          <p className="display-linebreak p-mx-2 texto-lateral">
                            {motivo.numeroDose}{' '}
                            {motivo.numeroDose > 1 ? 'doses' : 'dose'}
                          </p>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'Pedidos de cuidado':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <p className="display-linebreak p-mx-2 texto-lateral">
                            {motivo.tipoCuidado?.descricao}
                          </p>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'Problemas':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        {lembrete.lembrete}
                      </SimpleText>
                      {lembrete.info.map((motivo: any) => (
                        <>
                          <SimpleText
                            fontSize={FONT_SIZE.XXXS}
                            fontColor={FONT_COLOR.COLOR_40}
                            className="display-linebreak p-mx-2 p-mt-3"
                          >
                            {dayjs(motivo.dataOcorrencia)
                              .locale('pt-br')
                              .format('DD/MM/YYYY [às] HH:mm')}
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XXS}
                            fontColor={FONT_COLOR.COLOR_16}
                            className="display-linebreak p-mx-2 p-mt-1"
                            medium
                          >
                            {motivo.cid[0]?.cid?.nome}{' '}
                            {motivo.cid[0]?.cid?.codigo}
                            {motivo.ciap[0]?.ciap?.nome}{' '}
                            {motivo.ciap[0]?.ciap?.codigo}
                          </SimpleText>
                          <p className="display-linebreak p-mx-2 texto-lateral">
                            {motivo.observacoes}
                          </p>
                        </>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              case 'Avaliação de risco':
                return (
                  <>
                    <div className="p-d-flex p-flex-column p-my-2">
                      <SimpleText
                        fontSize={FONT_SIZE.XXS}
                        fontColor={FONT_COLOR.COLOR_16}
                        className="display-linebreak p-mx-2"
                        medium
                      >
                        Últimas avalições de risco
                      </SimpleText>
                      {lembrete.info.map((item: any) => (
                        <SimpleText
                          key={item.id}
                          fontSize={FONT_SIZE.XXXS}
                          fontColor={FONT_COLOR.COLOR_40}
                          className="display-linebreak p-mx-2 p-mt-3 p-line-height-2"
                        >
                          {dayjs(item.dataOcorrencia)
                            .locale('pt-br')
                            .format('DD/MM/YYYY [às] HH:mm')}{' '}
                          - Resultado:{' '}
                          {item.porcentagemAvalRisco?.porcentagemRisco}%
                        </SimpleText>
                      ))}
                    </div>
                    <Separator />
                  </>
                );
              default:
                return <></>;
            }
          }
          return <></>;
        })}
      </AccordionTab>
    ) : (
      <div className="icon-lembretes">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(0)]}
          icon={'fa-solid fa-bell'}
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge p-mt-4"
          style={
            quantidadeLembretes > 0
              ? { color: '#54a275' }
              : { color: '#74777e' }
          }
          tooltip="Lembretes"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        ></Button>
        {quantidadeLembretes > 0 ? (
          <Badge
            className="p-mx-2 lembretes-badge"
            value={String(quantidadeLembretes)}
          ></Badge>
        ) : (
          <></>
        )}
      </div>
    );
  }, [infoLembretes, setShowBarraLateral, showBarraLateral]);

  const evolucao = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          // infoSecretaria.informacoesProfissional ?
          <>
            <i
              style={{ color: '#74777e' }}
              className="fa-solid fa-file-waveform p-overlay-badge"
            >
              {/* {destaque} */}
            </i>
            <span style={{ color: '#74777e' }}>Evolução</span>
          </>
          // : (
          //   <>
          //     <i className="fa-solid fa-file-waveform p-overlay-badge"></i>
          //     <span style={{ color: '#74777e' }}>Evolução</span>
          //   </>
          // )
        }
        className="evolucao-content"
      >
        <HistoricoAtendimentoProvider>
          <Evolucao />
        </HistoricoAtendimentoProvider>
      </AccordionTab>
    ) : (
      <div className="icon-evolucao">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(1)]}
          icon="fa-solid fa-solid fa-file-waveform"
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge p-mt-3"
          // style={
          //   infoSecretaria.informacoesProfissional
          //     ? { color: '#74777e' }
          //     : { color: '#74777e' }
          // }
          tooltip="Evolução"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        ></Button>
      </div>
    );
  }, [setShowBarraLateral, showBarraLateral]);

  const historicoPessoal = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          prontuarioPersistente.historicoPessoal ? (
            <>
              <i className="p-overlay-badge">
                {/* {destaque} */}
                <IconeHistoricoPessoal
                  fill="#54a275"
                  style={{
                    marginRight: '.8rem',
                    fontSize: '1.5rem',
                    height: '28px',
                    width: '28px',
                  }}
                />
              </i>
              <span style={{ color: '#54a275' }}>Histórico pessoal</span>
            </>
          ) : (
            <>
              <IconeHistoricoPessoal
                fill="#74777e"
                style={{
                  marginRight: '.8rem',
                  fontSize: '1.5rem',
                  height: '28px',
                  width: '28px',
                }}
              />
              <span style={{ color: '#74777e' }}>Histórico pessoal</span>
            </>
          )
        }
      >
        <div className="p-d-flex p-flex-column p-mb-3">
          {prontuarioPersistente.dataAtualizacao && (
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
              className="display-linebreak p-mx-2 p-my-2"
              medium
            >
              Atualizado em{' '}
              {dayjs(prontuarioPersistente.dataAtualizacao)
                .locale('pt-br')
                .format('DD/MM/YYYY [às] HH:mm')}
            </SimpleText>
          )}

          <p className="display-linebreak p-mx-2 texto-lateral">
            {prontuarioPersistente.historicoPessoal || ''}
          </p>
        </div>
      </AccordionTab>
    ) : (
      <div className="p-d-flex p-ai-center p-jc-center p-mt-3">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(2)]}
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge"
          tooltip="Histórico pessoal"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        >
          <IconeHistoricoPessoal
            id="pessoal-icon"
            className="imported-icon"
            fill={
              prontuarioPersistente.historicoPessoal ? '#54a275' : '#74777e'
            }
            data-pr-tooltip="Histórico pessoal"
            data-pr-position="left"
          />
        </Button>
      </div>
    );
  }, [
    prontuarioPersistente.dataAtualizacao,
    prontuarioPersistente.historicoPessoal,
    setShowBarraLateral,
    showBarraLateral,
  ]);

  const historicoFamiliar = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          prontuarioPersistente.historicoFamiliar ? (
            <>
              <i className="p-overlay-badge">
                {/* {destaque} */}
                <IconeHistoricoFamiliar
                  fill="#54a275"
                  style={{
                    marginRight: '.8rem',
                    fontSize: '1.5rem',
                    height: '28px',
                    width: '28px',
                  }}
                />
              </i>
              <span style={{ color: '#54a275' }}>Histórico familiar</span>
            </>
          ) : (
            <>
              <IconeHistoricoFamiliar
                fill="#74777e"
                style={{
                  marginRight: '.8rem',
                  fontSize: '1.5rem',
                  height: '28px',
                  width: '28px',
                }}
              />
              <span style={{ color: '#74777e' }}>Histórico familiar</span>
            </>
          )
        }
      >
        <div className="p-d-flex p-flex-column p-mb-3">
          {prontuarioPersistente.dataAtualizacao && (
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
              className="display-linebreak p-mx-2 p-my-2"
              medium
            >
              Atualizado em{' '}
              {dayjs(prontuarioPersistente.dataAtualizacao)
                .locale('pt-br')
                .format('DD/MM/YYYY [às] HH:mm')}
            </SimpleText>
          )}

          <p className="display-linebreak p-mx-2 texto-lateral">
            {prontuarioPersistente.historicoFamiliar || ''}
          </p>
        </div>
      </AccordionTab>
    ) : (
      <div className="p-d-flex p-ai-center p-jc-center p-mt-3">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(3)]}
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge"
          tooltip="Histórico familiar"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        >
          <IconeHistoricoFamiliar
            id="familiar-icon"
            className="imported-icon"
            fill={
              prontuarioPersistente.historicoFamiliar ? '#54a275' : '#74777e'
            }
            data-pr-tooltip="Histórico familiar"
            data-pr-position="left"
          />
        </Button>
      </div>
    );
  }, [
    prontuarioPersistente.dataAtualizacao,
    prontuarioPersistente.historicoFamiliar,
    setShowBarraLateral,
    showBarraLateral,
  ]);

  const informacoesSecretaria = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          infoSecretaria.informacoesProfissional ? (
            <>
              <i
                style={{ color: '#54a275' }}
                className="fa-solid fa-headset p-overlay-badge"
              >
                {/* {destaque} */}
              </i>
              <span style={{ color: '#54a275' }}>Informações secretária</span>
            </>
          ) : (
            <>
              <i className="fa-solid fa-headset p-overlay-badge"></i>
              <span style={{ color: '#74777e' }}>Informações secretária</span>
            </>
          )
        }
      >
        <div className="p-d-flex p-flex-column p-mb-3">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            className="display-linebreak p-mx-2 p-my-2"
            medium
          >
            Atualizado em{' '}
            {dayjs(infoSecretaria?.dataAtualizacao)
              .locale('pt-br')
              .format('DD/MM/YYYY [às] HH:mm')}
          </SimpleText>
          <p className="display-linebreak p-mx-2 texto-lateral">
            {infoSecretaria?.informacoesProfissional}
          </p>
        </div>
      </AccordionTab>
    ) : (
      <div className="icon-lembretes">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(4)]}
          icon="fa-solid fa-headset"
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge p-mt-3"
          style={
            infoSecretaria.informacoesProfissional
              ? { color: '#54a275' }
              : { color: '#74777e' }
          }
          tooltip="Informações secretária"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        ></Button>
      </div>
    );
  }, [
    infoSecretaria?.dataAtualizacao,
    infoSecretaria.informacoesProfissional,
    setShowBarraLateral,
    showBarraLateral,
  ]);

  const informacoesPaciente = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          textoInfoPaciente ? (
            <>
              <i className="p-overlay-badge">
                {/* {destaque} */}
                <IconeInfoPaciente
                  fill="#54a275"
                  style={{
                    marginRight: '.8rem',
                    fontSize: '1.5rem',
                    height: '28px',
                    width: '28px',
                  }}
                />
              </i>
              <span style={{ color: '#54a275' }}>Informações paciente</span>
            </>
          ) : (
            <>
              <IconeInfoPaciente
                fill="#74777e"
                style={{
                  marginRight: '.8rem',
                  fontSize: '1.5rem',
                  height: '28px',
                  width: '28px',
                }}
              />
              <span style={{ color: '#74777e' }}>Informações paciente</span>
            </>
          )
        }
      >
        <div className="p-d-flex p-flex-column p-mb-3">
          <p className="display-linebreak p-mx-2 p-my-2 texto-lateral">
            Você pode editar o campo abaixo.
          </p>
          <TextareaInput
            className="p-mx-2"
            value={textoInfoPaciente}
            onChange={e => setTextoInfoPaciente(e.target.value)}
            maxLength={1000}
            maxLengthSpan={true}
            onBlur={() => putInfoPaciente()}
            style={{ maxHeight: '207px' }}
            autoResize
            disabled={atendimentoStatus !== 'ATENDENDO'}
          />
        </div>
      </AccordionTab>
    ) : (
      <div className="p-d-flex p-ai-center p-jc-center p-mt-3">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(5)]}
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge"
          tooltip="Informações paciente"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        >
          <IconeInfoPaciente
            id="pacienteIcon"
            className="paciente-icon imported-icon"
            fill={textoInfoPaciente ? '#54a275' : '#74777e'}
            data-pr-tooltip="Informações paciente"
            data-pr-position="left"
          />
        </Button>
      </div>
    );
  }, [
    atendimentoStatus,
    putInfoPaciente,
    setShowBarraLateral,
    showBarraLateral,
    textoInfoPaciente,
  ]);

  const sigiloProfissional = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          textoInfoSigilo ? (
            <>
              <i className="p-overlay-badge">
                {/* {destaque} */}
                <IconeSigiloMedico
                  fill="#54a275"
                  style={{
                    marginRight: '.8rem',
                    fontSize: '1.5rem',
                    height: '28px',
                    width: '28px',
                  }}
                />
              </i>
              <span style={{ color: '#54a275' }}>Sigilo profissional</span>
            </>
          ) : (
            <>
              <IconeSigiloMedico
                fill="#74777e"
                style={{
                  marginRight: '.8rem',
                  fontSize: '1.5rem',
                  height: '28px',
                  width: '28px',
                }}
              />
              <span style={{ color: '#74777e' }}>Sigilo profissional</span>
            </>
          )
        }
      >
        <div className="p-d-flex p-flex-column p-mb-3">
          <p className="display-linebreak p-mx-2 p-my-2 texto-lateral">
            Você pode editar o campo abaixo.
          </p>
          <TextareaInput
            className="p-mx-2"
            value={textoInfoSigilo}
            onChange={e => setTextoInfoSigilo(e.target.value)}
            maxLength={1000}
            maxLengthSpan={true}
            onBlur={() => putInfoPaciente()}
            style={{ maxHeight: '207px' }}
            autoResize
            disabled={atendimentoStatus !== 'ATENDENDO'}
          />
        </div>
      </AccordionTab>
    ) : (
      <div className="p-d-flex p-ai-center p-jc-center p-mt-3">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(6)]}
          className="p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge"
          tooltip="Sigilo profissional"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        >
          <IconeSigiloMedico
            id="sigilo-icon"
            className="imported-icon"
            fill={textoInfoSigilo ? '#54a275' : '#74777e'}
            data-pr-tooltip="Sigilo profissional"
            data-pr-position="left"
          />
        </Button>
      </div>
    );
  }, [
    atendimentoStatus,
    putInfoPaciente,
    setShowBarraLateral,
    showBarraLateral,
    textoInfoSigilo,
  ]);

  const sistemaLegado = useMemo(() => {
    return showBarraLateral ? (
      <AccordionTab
        header={
          textoInfoLegado ? (
            <>
              <i
                style={{ color: '#54a275' }}
                className="fa-solid fa-folder-open p-overlay-badge"
              >
                {/* {destaque} */}
              </i>
              <span style={{ color: '#54a275' }}>Sistema legado</span>
            </>
          ) : (
            <>
              <i className="fa-solid fa-folder-open p-overlay-badge"></i>
              <span style={{ color: '#74777e' }}>Sistema legado</span>
            </>
          )
        }
      >
        <div className="p-d-flex p-flex-column p-mb-3">
          <p className="display-linebreak p-mx-2 p-my-2 texto-lateral">
            Você pode editar o campo abaixo.
          </p>
          <TextareaInput
            className="p-mx-2"
            value={textoInfoLegado}
            onChange={e => setTextoInfoLegado(e.target.value)}
            onBlur={() => putInfoPaciente()}
            style={{ maxHeight: '600px' }}
            autoResize
            disabled={atendimentoStatus !== 'ATENDENDO'}
            maxLengthSpan
            maxLength={4000}
          />
        </div>
      </AccordionTab>
    ) : (
      <div className="icon-lembretes">
        <Button
          btnType="gray"
          btnSize="medium"
          onClick={() => [setShowBarraLateral(true), setActiveIndex(7)]}
          icon="fa-solid fa-folder-open"
          className="legado-icon p-button p-component p-button-rounded p-button-outlined light-thme p-button-icon-only p-as-center p-overlay-badge p-mt-3"
          style={textoInfoLegado ? { color: '#54a275' } : { color: '#74777e' }}
          tooltip="Sistema legado"
          tooltipOptions={{
            position: 'left',
            mouseTrack: true,
            mouseTrackLeft: 8,
          }}
        ></Button>
      </div>
    );
  }, [
    atendimentoStatus,
    putInfoPaciente,
    setShowBarraLateral,
    showBarraLateral,
    textoInfoLegado,
  ]);

  return (
    <div className={`barra-lateral-conteudo ${showBarraLateral ? 'full' : ''}`}>
      {showBarraLateral ? (
        <>
          <i
            className="chevron-barra-lateral"
            onClick={() => {
              showBarraLateral
                ? setShowBarraLateral(false)
                : setShowBarraLateral(true);
            }}
          >
            <IconeChevronsRight fill="#74777e" className="toggle-button" />
          </i>
          <Accordion
            className="accordion-custom p-mt-5"
            ref={accordionRef}
            // onTabOpen={e => handleHeaderClick(e)}
            // transitionOptions={{ transition: '50ms' }}
          >
            {lembretes}
            {evolucao}
            {historicoPessoal}
            {historicoFamiliar}
            {informacoesSecretaria}
            {informacoesPaciente}
            {sigiloProfissional}
            {sistemaLegado}
          </Accordion>
        </>
      ) : (
        <>
          <i
            onClick={() => {
              !showBarraLateral
                ? setShowBarraLateral(true)
                : setShowBarraLateral(false);
            }}
          >
            <IconeChevronsLeft fill="#74777e" className="toggle-button" />
          </i>
          {lembretes}
          {evolucao}
          {historicoPessoal}
          {historicoFamiliar}
          {informacoesSecretaria}
          {informacoesPaciente}
          {sigiloProfissional}
          {sistemaLegado}
        </>
      )}
    </div>
  );
}

export default BarraLateralProntuario;
