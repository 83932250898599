import './Lembrete.scss';

interface LembreteProps {
  ativo: boolean;
}

const Lembrete = ({ ativo }: LembreteProps) => {
  return (
    <i className={`fas fa-exclamation-triangle ${ativo ? 'lembrete-ativo' : 'lembrete'}`} />
  );
}

export default Lembrete;