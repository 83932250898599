import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useNovoAgendamento } from '../../hooks/NovoAgendamentoContext';

const particularItem = {
  label: 'Particular',
  value: 'PARTICULAR',
};

export const ConvenioSelectForm = ({
  convenioInitial,
}: {
  convenioInitial: string;
}) => {
  const [convenios, setConvenios] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { setPlanoSelected, paciente, planoSelected } = useNovoAgendamento();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      convenio: planoSelected,
    },
  });

  const onSubmit = (value: any) => {
    setPlanoSelected(value?.convenio);
  };

  useEffect(() => {
    if (convenioInitial === 'PARTICULAR') {
      setValue('convenio', 'PARTICULAR');
      setPlanoSelected(particularItem.value);
      return;
    }

    if (convenioInitial && convenios.length) {
      const convenio = convenios.find(
        con => con.secondText === convenioInitial,
      );

      convenio && [
        setValue('convenio', convenio.value),
        setPlanoSelected(convenio),
      ];
    }
  }, [convenioInitial, convenios, setPlanoSelected, setValue]);

  useEffect(() => {
    if (paciente?.id) {
      setIsLoading(true);

      ConvenioAPI.loadConvenioResumidoByPaciente(paciente?.id, {
        throwError: true,
      })
        .then(response => {
          const data = response
            .map((item: any) => ({
              label: item?.nomeFantasia || item?.razaoSocial,
              secondText: item?.numeroCartao,
              value: item,
            }))
            .sort((a: any, b: any) => {
              if (a.value.principal) return -1;
              if (b.value.principal) return 1;
              return a.label.localeCompare(b.label);
            });

          setConvenios([...data, particularItem]);

          if (!data.length) {
            setValue('convenio', 'PARTICULAR');
            setPlanoSelected(particularItem.value);
          }
          if (data.length === 1) {
            setValue('convenio', data[0].value);
            setPlanoSelected(data[0].value);
          }
        })
        .finally(() => setIsLoading(false))
        .catch(() => {});
    } else {
      setConvenios([]);
    }
  }, [paciente?.id, setPlanoSelected, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleText bold className="p-w-100">
        Convênios
      </SimpleText>

      {isLoading ? (
        <LoadingComp />
      ) : (
        <RadioButtonGroupControlled
          control={control}
          name="convenio"
          dir="column"
          idKey="idPacienteCartao"
          customOnChange={handleSubmit(onSubmit)}
          items={convenios}
        />
      )}
    </form>
  );
};

const LoadingComp = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-col-12 p-px-0">
      <Skeleton loading borderRadius="8px" height="30px" />
      <Skeleton loading borderRadius="8px" height="30px" />
      <Skeleton loading borderRadius="8px" height="30px" />
    </div>
  );
};
