import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface SaudeMentalProps {
  readOnly?: boolean | undefined;
  values?: Questionarios | undefined;
}

enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

const SaudeMental = ({ values, readOnly }: SaudeMentalProps) => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();

  const mentalNaoSeAplica = watch('mentalNaoSeAplica');

  useEffect(() => {
    if (typeof mentalNaoSeAplica === 'boolean') {
      const valueNaoSeAplica = mentalNaoSeAplica
        ? Values.NAO_SE_APLICA
        : undefined;

      const fields = ['transtornoMental', 'suicidio', 'depressao', 'ansiedade'];

      fields.forEach(field => {
        setValue(field, valueNaoSeAplica, {
          shouldValidate: mentalNaoSeAplica,
        });
      });
    }
  }, [setValue, mentalNaoSeAplica]);

  return (
    <Template
      title="Saúde mental"
      name="mentalNaoSeAplica"
      readOnly={readOnly}
      resetField={resetField}
      control={control}
    >
      <div className="container-mental">
        <CardQuestions
          title="Transtornos relacionados a saúde mental na família?"
          name="transtornoMental"
          control={control}
          disabled={readOnly || mentalNaoSeAplica}
          defaultValue={values?.transtornoMental}
          error={errors?.transtornoMental?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="transtornoMental"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || mentalNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Ocorrência de suicídio na Família?"
          name="suicidio"
          control={control}
          disabled={readOnly || mentalNaoSeAplica}
          defaultValue={values?.suicidio}
          error={errors?.suicidio?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="suicidio"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || mentalNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Sintomas de depressão?"
          name="depressao"
          control={control}
          disabled={readOnly || mentalNaoSeAplica}
          defaultValue={values?.depressao}
          error={errors?.depressao?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="depressao"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || mentalNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Sintomas de ansiedade?"
          name="ansiedade"
          control={control}
          disabled={readOnly || mentalNaoSeAplica}
          defaultValue={values?.ansiedade}
          error={errors?.ansiedade?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            unstyled
            name="ansiedade"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || mentalNaoSeAplica}
          />
        </CardQuestions>
      </div>
    </Template>
  );
};

export default SaudeMental;
