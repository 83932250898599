import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/pendencia-tiss';

const getPendenciasTiss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });

const sendLoteGuiaTiss = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.post({
    service: 'pep',
    url: `${basePath}/enviar-lote-guia`,
    ...options,
  });
const sendSADT = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.post({
    service: 'pep',
    url: `${basePath}/enviar-sadt`,
    ...options,
  });
const sendExamesFuturos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.post({
    service: 'pep',
    url: `${basePath}/enviar-exames-futuros`,
    ...options,
  });

export const PendenciasTissAPI = {
  getPendenciasTiss,
  sendLoteGuiaTiss,
  sendSADT,
  sendExamesFuturos,
};
