import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { formatCPF } from 'src/utils/utils';

import { Page } from '../_components/Page/Page';
import { PageHeader } from '../_components/Page/PageHeader';
import { Button } from 'src/components/_UI';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import { MeusDadosAvatar } from './Form/MeusDadosAvatar';
import { MeusDadosContatoEnderecoForm } from './Form/MeusDadosContatoEnderecoForm';
import { MeusDadosDadosPessoaisDocumentosForm } from './Form/MeusDadosDadosPessoaisDocumentosForm';
import { MeusDadosPlanoDeSaude } from './Form/MeusDadosPlanoDeSaudeForm';
import { MeusDadosDialogAlterarSenha } from './MeusDadosDialogAlterarSenha';

export const MeusDados = () => {
  const [userData, setUserData] = useState<any>(null);

  const navigate = useNavigate();
  const dialogAlterarSenha = useDisclosure({ opened: false });

  useEffect(() => {
    PortalPacienteAPI.getPacienteUser({
      throwError: true,
      hideToast: true,
    })
      .then(data => setUserData(data))
      .catch(() => navigate(-1));
  }, [navigate]);

  return (
    <Page>
      <>
        <PageHeader
          title={userData?.nome}
          subtitle={`${formatCPF(userData?.cpf)} • ${dayjs(
            userData?.dataNascimento,
          ).format('DD/MM/YYYY')}`}
          hasBreadcrumbs={false}
          secondaryAction
          backButton
          before={<MeusDadosAvatar avatarUrl={userData?.urlFoto} />}
        >
          <Button
            btnType="tonal"
            label="Alterar a senha"
            icon="fas fa-key"
            className="p-ml-4"
            onClick={() => dialogAlterarSenha.open()}
          />
        </PageHeader>

        <Page content white className="p-py-4">
          <MeusDadosContent userData={userData} />
        </Page>

        {dialogAlterarSenha.isOpen && (
          <MeusDadosDialogAlterarSenha
            {...dialogAlterarSenha}
            state={userData.cpf}
          />
        )}
      </>
    </Page>
  );
};

const MeusDadosContent = ({ userData }: any) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleNext = () => {
    setTabIndex(old => old++);
  };

  return (
    <VerticalTab
      updateTabIndex={tabIndex}
      tabItens={[
        {
          label: 'Dados pessoais e documentos',
          content: (
            <MeusDadosDadosPessoaisDocumentosForm
              userData={userData}
              handleNext={handleNext}
            />
          ),
        },
        {
          label: 'Contato e endereço',
          content: (
            <MeusDadosContatoEnderecoForm
              userData={userData}
              handleNext={handleNext}
            />
          ),
        },
        {
          label: 'Plano de saúde',
          content: <MeusDadosPlanoDeSaude />,
        },
      ]}
    />
  );
};
