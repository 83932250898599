import * as Yup from 'yup';

const requiredMessage = 'O preenchimento do campo é obrigatório';
const maxLengthMessage = 'Limite de caracteres excedido';

export const validationSchema = Yup.object({
  enunciado: Yup.string().required(requiredMessage).max(500, maxLengthMessage),
  orientacao: Yup.string().max(500, maxLengthMessage).nullable(),
  tipoResposta: Yup.object({
    codigo: Yup.string().required(requiredMessage),
  })
    .required(requiredMessage)
    .nullable(),

  formatoData: Yup.string().when('tipoResposta.codigo', {
    is: 'DATA',
    then: Yup.string().required(requiredMessage),
    otherwise: Yup.string().notRequired().nullable().optional(),
  }),

  valorMinimo: Yup.number()
    .when('tipoResposta.codigo', {
      is: 'NUMERO',
      then: Yup.number()
        .required(requiredMessage)
        .min(1, 'O valor deve ser igual ou maior que "1"')
        .typeError(requiredMessage),
      otherwise: Yup.number().nullable().notRequired(),
    })
    .typeError(requiredMessage),
  valorMaximo: Yup.number().when('tipoResposta.codigo', {
    is: 'NUMERO',
    then: Yup.number()
      .required(requiredMessage)
      .min(1, 'O valor deve ser igual ou maior que "1"')

      .test(
        'is-greater-than-min',
        'O valor máximo deve ser maior que o valor mínimo',
        function (value = 0) {
          const { valorMinimo } = this.parent;
          return value > valorMinimo;
        },
      )
      .typeError(requiredMessage),
    otherwise: Yup.number().nullable().notRequired(),
  }),

  quantidadeOpcoesObrigatorias: Yup.number().when('tipoResposta.codigo', {
    is: 'MULTIPLA_ESCOLHA',
    then: Yup.number().required(requiredMessage).typeError(requiredMessage),
    otherwise: Yup.number().notRequired().nullable(),
  }),

  opcoes: Yup.array(
    Yup.object({
      descricao: Yup.string()
        .max(500, maxLengthMessage)
        .required(requiredMessage),
      pontuacao: Yup.string()
        .required(requiredMessage)
        .nullable()
        .typeError(requiredMessage),
    }),
  ).when('tipoResposta.codigo', {
    is: 'ESCOLHA' || 'MULTIPLA_ESCOLHA',
    then: Yup.array(
      Yup.object({
        descricao: Yup.string()
          .max(500, maxLengthMessage)
          .required(requiredMessage),
        pontuacao: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage),
      }),
    )
      .required(requiredMessage)
      .min(1, requiredMessage),
    otherwise: Yup.array().notRequired(),
  }),

  acervoPerguntas: Yup.boolean().default(false),
  obrigatorio: Yup.boolean().default(false),

  respostaAdicional: Yup.boolean().default(false),
  textoRespostaAdicional: Yup.string().when('respostaAdicional', {
    is: true,
    then: Yup.string().required(requiredMessage).max(500, maxLengthMessage),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  tipoRespostaAdicional: Yup.object({
    codigo: Yup.string().nullable(),
  }).when('respostaAdicional', {
    is: true,
    then: Yup.object({
      codigo: Yup.string().required(requiredMessage).nullable(),
    }).required(requiredMessage),
    otherwise: Yup.object().notRequired().nullable(),
  }),
  tipoValidacaoRespostaAdicional: Yup.object({
    codigo: Yup.string().nullable(),
  }).when('tipoRespostaAdicional', {
    is: 'resposta',
    then: Yup.object({
      codigo: Yup.string().required(requiredMessage).nullable(),
    }).required(requiredMessage),
    otherwise: Yup.object().notRequired().nullable(),
  }),

  opcao: Yup.string(),
  pontuacao: Yup.string(),
});

export const dateFormats = [
  {
    label: 'dd/mm/aaaa',
    value: 'dd/mm/yyyy',
  },
  {
    label: 'dd/mm',
    value: 'dd/mm',
  },
  {
    label: 'mm/aaaa',
    value: 'mm/yyyy',
  },
];
