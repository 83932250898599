import React from 'react';

import { Button } from '../_UI/Button';
import SimpleText from '../Basics/SimpleText/SimpleText';

import Dialog, { DialogProps } from './Dialog';
import './Dialog.scss';

interface ConfirmDialogProps extends DialogProps {
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  text: string | JSX.Element;
  confirmBtnType?: any;
}

const ConfirmDialog = ({
  children,
  className,
  showDismissWithoutHeader,
  header,
  type = 'dialog',
  confirmText = 'Sim',
  confirmBtnType = 'danger',
  cancelText = 'Cancelar',
  onConfirm,
  visible,
  onHide,
  text,
  ...rest
}: ConfirmDialogProps) => {
  return (
    <Dialog
      visible={visible || false}
      position={'center'}
      closable
      dismissableMask={true}
      closeOnEscape={true}
      header={header}
      onHide={onHide}
      className="footer-on"
      style={{ maxWidth: '600px' }}
    >
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        {React.isValidElement(text) ? text : <SimpleText>{text}</SimpleText>}
        {children}
        <div className="p-d-flex p-gap-2 p-col-12 p-px-0">
          <div className={'p-col-6 p-px-0'}>
            <Button
              label={cancelText}
              onClick={onHide}
              btnType="ghost"
              stretch
            />
          </div>
          <div className={'p-col-6 p-px-0'}>
            <Button
              label={confirmText}
              onClick={onConfirm}
              btnType={confirmBtnType}
              stretch
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
