import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Card } from './components/Card';
import { Feedback } from './components/Feedback/Feedback';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './Home.scss';

export const Home = () => {
  const { hashEmpresa } = useParams();
  const { pacienteDados, idPaciente } = useAppSelector(state => state.user);
  const navigate = useNavigate();

  const feedbackDialog = useDisclosure({ opened: false });

  useEffect(() => {
    if (idPaciente)
      PortalPacienteAPI.getAvaliacaoAtendimentoPendente({
        errorHideToast: true,
        throwError: true,
      })
        .then(res => {
          if (!!res) {
            feedbackDialog.open({
              state: {
                idAtendimento: res.dadosComplementares?.idAtendimento,
                conteudo: res.conteudo,
              },
            });
          }
        })
        .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPaciente]);

  return (
    <main className="home-container p-d-flex p-flex-column p-gap-3">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-3 p-px-0">
        <SimpleText fontSize="md" medium>
          Bem vindo ao Portal do Paciente,
        </SimpleText>
        <SimpleText fontSize="sm" fontColor="color_40">
          {pacienteDados?.nome}
        </SimpleText>
      </div>

      <SimpleText className="p-col-12 p-px-0" medium>
        Escolha um dos seguintes menus{' '}
      </SimpleText>

      <div className="card-home-container p-col-12 p-d-flex p-grid p-px-0">
        <Card
          title="Novo agendamento"
          subtitle="Realize um novo agendamento."
          icon="fas fa-edit"
          onAction={() =>
            navigate(`/portal/${hashEmpresa}/agendamento/novoAgendamento`)
          }
        />
        <Card
          title="Meus agendamentos"
          subtitle="Visualize, edite ou cancele seus agendamentos."
          icon="fas fa-calendar"
          onAction={() =>
            navigate(`/portal/${hashEmpresa}/agendamento/meusAgendamentos`)
          }
        />
        <Card
          title="Meus dados"
          subtitle="Mantenha seus dados atualizados."
          icon="fas fa-user"
          onAction={() => navigate(`/portal/${hashEmpresa}/meusDados`)}
        />
        <Card
          title="Perfil Saúde"
          subtitle="Visualize seus questionários de saúde  "
          icon="fas fa-clipboard-list"
          onAction={() => navigate(`/portal/${hashEmpresa}/perfilSaude`)}
        />
        <Card
          title="Minhas receitas e orientações "
          subtitle="Visualize suas receitas e orientações."
          icon="fas fa-pills"
          onAction={() =>
            navigate(`/portal/${hashEmpresa}/receitasOrientacoes`)
          }
        />
        <Card
          title="Minhas solicitações e resultados de exames"
          subtitle="Visualize suas guias e resultados de exames, anexe laudos e/ou resultados de exames."
          icon="fas fa-file-waveform"
          onAction={() =>
            navigate(`/portal/${hashEmpresa}/solicitacoesResultadosExames`)
          }
        />

        {feedbackDialog.isOpen && <Feedback {...feedbackDialog} />}
      </div>
    </main>
  );
};
