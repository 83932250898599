import useSize from 'src/core/hooks/useSize';

import Button from 'src/components/Basics/Button/Buttons';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import ModalEnviarGuia from 'src/pages/Emed/Prontuario/components/ModalEnviarGuia/ModalEnviarGuia';

import './ButtonEnviarEmail.scss';

type IProps = {
  handleEnviarGuiasPorEmail: (email: string) => void;
  disabled?: boolean;
  modalEmailDisclosure?: any;
  loading?: boolean;
  loadingAssinatura?: boolean;
};

export const ButtonEnviarEmail = ({
  handleEnviarGuiasPorEmail,
  modalEmailDisclosure,
  disabled = false,
  loading,
  loadingAssinatura,
}: IProps) => {
  const { isMobile } = useSize();

  return (
    <div>
      {isMobile ? (
        <IconButton
          btnType={IconBtnTypes.GREEN}
          icon="fas fa-envelope"
          type="button"
          squared
          style={{ borderRadius: '8px' }}
          onClick={() => modalEmailDisclosure?.open()}
          disabled={disabled}
        />
      ) : (
        <Button
          label="Enviar por e-mail"
          icon="fas fa-envelope"
          btnType="tonal"
          type="button"
          onClick={() => modalEmailDisclosure?.open()}
          disabled={disabled}
        />
      )}

      {modalEmailDisclosure?.isOpen && (
        <ModalEnviarGuia
          handleEnviarGuiasPorEmail={handleEnviarGuiasPorEmail}
          loaging={loading}
          loadingAssinatura={loadingAssinatura}
          {...modalEmailDisclosure}
        />
      )}
    </div>
  );
};
