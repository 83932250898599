import { forwardRef, useCallback, useEffect, useState, useMemo } from 'react';

import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import CompromissosAPI from 'src/APIs/AgendaAPI/CompromissosAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { utcToGMT } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

const formFields = {
  descricao: '',
  tipoCompromisso: '',
  dataInicio: '',
  dataFim: '',
};

export const tipos_compormissos = [
  {
    value: 'FER',
    label: 'Férias',
  },
  {
    value: 'EVE',
    label: 'Eventos',
  },
  {
    value: 'COM',
    label: 'Compromisso pessoal',
  },
  {
    value: 'PRO',
    label: 'Procedimentos médicos',
  },
  {
    value: 'OUT',
    label: 'Outros',
  },
];

const twoYearsFromNow = new Date(
  new Date().setFullYear(new Date().getFullYear() + 2),
);
const dataInicioYearRange = `${new Date().getFullYear()}:${twoYearsFromNow.getFullYear()}`;

interface FormCompromissoProps {
  visible: boolean;
  onHide(): void;
  item?: GetCompromissoDTO | any;
  update?: boolean | undefined;
  onSuccess(): void;
}

const FormCompromisso = forwardRef(
  ({ visible, onHide, item, update, onSuccess }: FormCompromissoProps, ref) => {
    const { agenda, user } = useAppSelector((state: RootState) => state);
    const [currentItem, setCurrentItem] = useState<any>();
    const [dataInicioCompromisso, setDataInicioCompromisso] = useState<Date>(
      new Date(),
    );

    const validationSchema = Yup.object().shape({
      descricao: Yup.string().required('Descrição é obrigatória'),
      tipoCompromisso: Yup.string().required(
        'Tipo do compromiso é obrigatório',
      ),
      dataInicio: Yup.date()
        .required('Data inicial é obrigatória')
        .typeError('Data inicial é obrigatória'),
      dataFim: Yup.date()
        .required('Data final é obrigatória')
        .typeError('Data final é obrigatória'),
    });

    const {
      control,
      handleSubmit,
      setValue,
      reset,
      formState: { errors, isSubmitting },
    } = useForm({
      resolver: yupResolver(validationSchema),
    });

    /**
     * Identify current item and load information from API
     */
    const loadPage = useCallback(() => {
      if (item) {
        const i: any = item;
        i.dataInicio = new Date(i.dataInicio);
        i.dataFim = new Date(i.dataFim);

        setValue('descricao', item.descricao);
        setValue('tipoCompromisso', item.tipoCompromisso);
        setValue('dataInicio', i.dataInicio);
        setValue('dataFim', i.dataFim);

        setCurrentItem(i);
      }
    }, [item, setValue]);

    useEffect(() => {
      loadPage();
    }, [loadPage]);

    /**
     * Handles save or update of information of current item
     */
    const handleSave = useCallback(
      async (data: any) => {
        const aux: PostCompromissoDTO = {
          id: currentItem?.id,
          descricao: data.descricao,
          dataInicio: utcToGMT(data.dataInicio),
          dataFim: utcToGMT(data.dataFim),
          idMedico: agenda.profissionalAtivo.id,
          idUsuarioCadastro: user.idUsuario,
          tipoCompromisso: data.tipoCompromisso,
        };

        let response;

        if (item !== undefined && currentItem?.id !== 0) {
          response = await CompromissosAPI.updateCompromisso(aux);
        }

        if (item === undefined || currentItem?.id === 0) {
          response = await CompromissosAPI.saveCompromisso(aux);
        }

        if (response) {
          reset(formFields);
          onSuccess();
          onHide();
        }
      },
      [
        currentItem?.id,
        agenda.profissionalAtivo?.id,
        user.idUsuario,
        item,
        reset,
        onSuccess,
        onHide,
      ],
    );

    const dataFimYearRange = useMemo(
      () =>
        `${dataInicioCompromisso.getFullYear()}:${twoYearsFromNow.getFullYear()}`,
      [dataInicioCompromisso],
    );

    return (
      <Dialog
        className="FormCompromisso"
        header={`${
          update ? 'Atualizar ' : item?.id === 0 ? 'Duplicar' : 'Novo'
        } compromisso`}
        visible={visible}
        onHide={() => {
          reset(formFields);
          onHide();
        }}
        maskClassName="configuracao-agenda-dialog"
        maximizedTabletSize
      >
        <div>
          <div className={'Form'}>
            <form
              className="p-fluid p-formgrid p-grid"
              onSubmit={handleSubmit(handleSave)}
              defaultValue={item}
              onReset={() => reset(formFields)}
            >
              <SimpleText className={'text p-col-12 p-md-12'}>
                Digite o motivo do compromisso que será exibido na agenda e no
                relatório
              </SimpleText>

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <TextInput
                    label=""
                    className="grid p-col-12 p-md-12 mb-1"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
                name={'descricao'}
              />
              <div className="invalid-feedback">
                {errors.descricao?.message}
              </div>

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <Dropdown
                    className={'p-col-12 p-md-12 mb-1'}
                    label={'Tipo do compromisso'}
                    options={tipos_compormissos}
                    placeholder={'Escolha o tipo do compromisso'}
                    onChange={onChange}
                    inputRef={ref}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
                name="tipoCompromisso"
              />
              <div className="invalid-feedback">
                {errors.tipoCompromisso?.message}
              </div>

              <SimpleText className={'text p-col-12 p-md-12 mb-1 mt-1'}>
                Defina o dia e hora inicial e final do compromisso
              </SimpleText>

              <div className="row">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <div className="column">
                      <CalendarInput
                        value={value}
                        onBlur={onBlur}
                        onChange={e => {
                          onChange(e);
                          if (e.value)
                            setDataInicioCompromisso(new Date(e.value as Date));
                        }}
                        inputRef={ref}
                        className={'p-col-12 p-md-12 mb-1'}
                        label={'Data e hora inicial'}
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        minDate={new Date()}
                        maxDate={twoYearsFromNow}
                        yearRange={dataInicioYearRange}
                        showTime
                      />

                      <div className="invalid-feedback">
                        {errors.dataInicio?.message}
                      </div>
                    </div>
                  )}
                  name="dataInicio"
                />

                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <div className="column">
                      <CalendarInput
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        inputRef={ref}
                        className={'p-col-12 p-md-12 mb-1'}
                        label={'Data e hora final'}
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        minDate={dataInicioCompromisso}
                        maxDate={twoYearsFromNow}
                        yearRange={dataFimYearRange}
                        showTime
                      />

                      <div className="invalid-feedback">
                        {errors.dataFim?.message}
                      </div>
                    </div>
                  )}
                  name="dataFim"
                />
              </div>

              <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
                <Button
                  type="button"
                  label="Cancelar"
                  btnType="ghost"
                  onClick={() => onHide()}
                  stretch
                />
                <Button
                  type="submit"
                  label="Salvar"
                  stretch
                  loading={isSubmitting}
                />
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    );
  },
);

export default FormCompromisso;
