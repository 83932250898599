import { BuscaRapidaProcedimentoPanel } from './BuscaRapidaProcedimentoPanel';

interface BuscaRapidaListProps {
  listaProcedimentosFilteredBySearchQuery: any[];
  procedimentosSelected: any[];
  setProcedimentosSelected: any;
  handleGrupoEdit: any;
  handleRemoveGrupoFromList: any;
}

export const BuscaRapidaList = (props: BuscaRapidaListProps) => {
  const {
    listaProcedimentosFilteredBySearchQuery,
    procedimentosSelected,
    setProcedimentosSelected,
    handleGrupoEdit,
    handleRemoveGrupoFromList,
  } = props;

  return (
    <div id="busca-rapida-content">
      {listaProcedimentosFilteredBySearchQuery.map((solicitacao: any) => (
        <BuscaRapidaProcedimentoPanel
          key={solicitacao.id}
          isSelected={procedimentosSelected.includes(solicitacao)}
          procedimento={solicitacao}
          onSelect={setProcedimentosSelected}
          onEdit={handleGrupoEdit}
          onRemoveItemFromList={handleRemoveGrupoFromList}
        />
      ))}
    </div>
  );
};
