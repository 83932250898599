import {
  SplitButton as PrimeSplitButton,
  SplitButtonProps as PrimeSplitButtonProps,
} from 'primereact/splitbutton';

import useTheme from 'src/core/themes/useTheme';
import './SplitButton.scss';

export enum SPLIT_BTN_TYPE {
  AGENDADO = 'AGENDADO',
  PRESENTE = 'PRESENTE',
  NEGADO_OPERADORA = 'NEGADO_OPERADORA',
  GREEN = 'green',
  YELLOW = 'yellow',
  SUCCESS_LIGHT = 'success_light',
  GHOST = 'ghost',
  TONAL = 'tonal',
}
interface SplitButtonProps extends PrimeSplitButtonProps {
  btnType?: SPLIT_BTN_TYPE;
  className?: string;
}

const SplitButton = ({ btnType, className, ...rest }: SplitButtonProps) => {
  return (
    <PrimeSplitButton
      className={`SplitButton ${btnType || ''} ${className ? className : ''}`}
      {...rest}
    />
  );
};

export default SplitButton;
