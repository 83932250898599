import dayjs from 'dayjs';

import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface PanelHeaderProps {
  gestacao: any;
  isCollapsed: boolean;
  onToggle: () => void;
  index: number | string;
}

export default function PanelHeader({
  gestacao,
  isCollapsed,
  onToggle,
  index,
}: PanelHeaderProps) {
  const { loadingPreNatal } = usePreNatal();
  return (
    <Skeleton loading={loadingPreNatal} height="55px" borderRadius="8px">
      <div className="p-grid">
        <div className="p-col-12 p-md-3 p-lg-2 p-d-flex">
          <Avatar icon="fas fa-person-pregnant p-as-center" />

          <div className="p-d-flex p-flex-column p-ml-3">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Gestação
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              {gestacao?.tipoGestacao?.split('_')[1]}
            </SimpleText>
          </div>
        </div>

        <div className="p-col-6 p-md-3 p-lg-2 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Idade gestacional
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {gestacao?.idadeGestacional}
          </SimpleText>
        </div>

        <div className="p-col-6 p-md-3 p-lg-1 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Sexo
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {gestacao?.fetos[index]?.sexo?.descricao
              ? capitalizeFirstLetter(gestacao?.fetos[index]?.sexo?.descricao)
              : 'Não informado'}
          </SimpleText>
        </div>

        <div className="p-col-6 p-md-3 p-lg-2 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Nome do bebê
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {gestacao?.fetos[index]?.nome}
          </SimpleText>
        </div>

        <div className="p-col-6 p-md-3 p-lg-2 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Nome do pai
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {gestacao?.nomePai}
          </SimpleText>
        </div>
        <div className="p-col-10 p-md-3 p-lg-2 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Data do desfecho
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {gestacao?.dataEncerramentoCiclo
              ? dayjs(new Date(gestacao?.dataEncerramentoCiclo)).format(
                  'DD/MM/YYYY',
                )
              : '-'}
          </SimpleText>
        </div>
        <div className="p-col-2 p-md-6 p-lg-1 p-d-flex p-jc-end">
          <Button
            icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            btnType="ghost"
            onClick={onToggle}
          />
        </div>
      </div>
    </Skeleton>
  );
}
