import { formatCPF } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  carteira: CarteiraAPSDTO;
}

const PanelContent = ({ carteira }: PanelContentProps) => {
  return (
    <div className="movimentacao-panelContent">
      <div className="p-grid">
        <SimpleText
          className="p-col-6 p-lg-4 p-xl-3 p-px0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          bold
        >
          Paciente
        </SimpleText>

        <SimpleText
          className="p-col-6 p-lg-4 p-xl-3 p-px0"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          bold
        >
          CPF
        </SimpleText>
      </div>

      {carteira?.pacientes?.map((paciente: any, index: number) => (
        <div key={index} className="p-grid paciente-info">
          <SimpleText
            className="p-col-6 p-lg-4 p-xl-3 p-px0"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {paciente.nome}
          </SimpleText>

          <SimpleText
            className="p-col-6 p-lg-4 p-xl-3 p-px0"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {formatCPF(paciente.cpf)}
          </SimpleText>
        </div>
      ))}
    </div>
  );
};

export default PanelContent;
