//@ts-disable

//=======================================================================================================
//============================= Versão do EVALWebSigner-API =============================================
//=======================================================================================================
const EVALWEBSIGNER_API_VERSION = '1.3.1';
const EVALWEBSIGNER_EXT_MIN_VERSION = '1.0.14';
const EVALWEBSIGNER_HOST_MIN_VERSION = '1.0.4';

//=======================================================================================================
//====================================== Constantes =====================================================
//=======================================================================================================
const EVENT_EVAL_WEBSIGNER_CLIENT = 'FROM_EVAL_WEBSIGNER_CLIENT';
const EVENT_EVAL_WEBSIGNER_CONTENT = 'FROM_EVAL_WEBSIGNER_CONTENT';
const EWS_EVAL_EXTENSION_ID = 'mjcdameipbkbogogocmbaojcpbfenbaj';

const EX_ORIGIN_API = 'API_CLIENT';
const EWS_LOG_API_TAG = '[eval-websigner-api]: ';
const DOMAIN_ERROR_CODE = -501;

const EWS_MSG_EXTENSION_NOT_INSTALLED =
  'A extensão EVALWebSigner não está instalada.';
const EWS_MSG_HOST_NOT_INSTALLED =
  'O EVALWebSignerHost não está instalado no sistema operacional.';
const EWS_MSG_EXTENSION_NOT_SUPPORTED =
  'A versão da extensão EVALWebSigner instalada no navegador não é compatível. Versão esperada: ' +
  EVALWEBSIGNER_EXT_MIN_VERSION;
const EWS_MSG_HOST_NOT_SUPPORTED =
  'A versão do EVALWebSignerHost instalado no sistema não é compatível. Versão esperada: ' +
  EVALWEBSIGNER_HOST_MIN_VERSION;

let EWS_isReady = false;

/**
 * Classe que representa o componente Eval WebSigner
 */
export class EvalWebSigner {
  constructor(license) {
    this.license = null;
    EWS_isReady = false;

    if (!license) {
      throw 'O parâmetro license não pode ser nulo ou vazio.';
    }

    if (typeof license !== 'string') {
      throw 'O parâmetro license precisa ser do tipo string.';
    }

    if (license.replace(/ /g, '') === '') {
      throw 'O parâmetro license não pode ser um valor vazio.';
    }

    this.license = license;
  }
}

(function ($) {
  // ==================================================
  // ================ Promise subclass ================
  // ==================================================

  $.Promise = function () {
    (this.successCallback = null),
      (this.failCallback = null),
      (this.notInstalledCallback = null);
  };

  $.Promise.prototype.success = function (callback) {
    this.successCallback = callback;
  };

  $.Promise.prototype.fail = function (callback) {
    this.failCallback = callback;
  };

  $.Promise.prototype.notInstalled = function (callback) {
    this.notInstalledCallback = callback;
  };

  // Métodos de retorno do promise
  $.Promise.prototype._responseSuccess = function (result) {
    var callback = this.successCallback;
    if (callback) {
      callback(result);
    } else {
      $._evalLog(
        'Sucesso na operação, porém nenhum callback foi registrado para retornar a resposta.',
      );
    }
  };

  $.Promise.prototype._responseFail = function (exception) {
    var callback = this.failCallback;
    if (callback) {
      callback(exception);
    } else {
      $._evalLog(
        'Falha na operação, porém nenhum callback foi registrado para retornar o erro.',
      );
      throw JSON.stringify(exception);
    }
  };

  $.Promise.prototype._responseNotInstalled = function (status, message) {
    var callback = this.notInstalledCallback;
    if (callback) {
      callback(status, message);
    } else {
      $._evalLog(
        'Componente não instalado, porem nenhum callback foi registrado para retornar o status.',
      );
      throw (
        'Componente não instalado, status: ' + status + '. mensagem: ' + message
      );
    }
  };

  // ===================================================
  // ================ Constantes =======================
  // ===================================================

  $._notInstalledStates = {
    EXTENSION_NOT_INSTALLED: 1,
    EXTENSION_NOT_SUPPORTED: 2,
    HOST_NOT_INSTALLED: 3,
    HOST_NOT_SUPPORTED: 4,
  };

  // ===================================================
  // ================ Private Functions ================
  // ===================================================

  //Retona 0 se a versão for compatível
  //Retorna -1 se o digito primário for incompatível (mudança de integração).
  //Retorna -2 se não for compatível com a versão mínima esperada.
  //Retorna -3 se não for possível determinar a compatibilidade.
  $._compareVersions = function (actVersion, minVersion) {
    let actVersionArray = actVersion.split('.');
    let minVersionArray = minVersion.split('.');

    if (actVersionArray.length < 3) {
      return -3;
    }
    if (minVersionArray.length < 3) {
      return -3;
    }

    //verificar compatibilidade do primeiro digito
    let actVersionPrimary = parseInt(actVersionArray[0], 10);
    let minVersionPrimary = parseInt(minVersionArray[0], 10);
    if (actVersionPrimary !== minVersionPrimary) {
      return -1;
    }

    //verificar compatibilidade do segundo digito
    let actVersionSecundary1 = parseInt(actVersionArray[1], 10);
    let minVersionSecundary1 = parseInt(minVersionArray[1], 10);
    if (actVersionSecundary1 < minVersionSecundary1) {
      return -2;
    } else if (actVersionSecundary1 > minVersionSecundary1) {
      return 0; //versão superior, não precisa validar o terceiro digito.
    }

    //verificar compatibilidade do terceiro digito
    let actVersionSecundary2 = parseInt(actVersionArray[2], 10);
    let minVersionSecundary2 = parseInt(minVersionArray[2], 10);
    if (actVersionSecundary2 < minVersionSecundary2) {
      return -2;
    }

    return 0;
  };

  // Insere "promise" e a "licença" no contexto.
  $._buildFunctionScenario = function (param) {
    var promise = new $.Promise();

    if (param && param.toPromiseSuccess) {
      promise.success(param.toPromiseSuccess);
    }

    if (param && param.toPromiseFail) {
      promise.fail(param.toPromiseFail);
    }

    var functionScenario = {
      promise: promise,
      license: this.license,
    };
    return functionScenario;
  };

  $._buildWebSignerException = function (exception) {
    var exceptionJSON = {
      message: null,
      stackTrace: null,
      origin: EX_ORIGIN_API,
    };

    exceptionJSON.message =
      typeof exception === 'string' ? exception : exception.message;

    return exceptionJSON;
  };

  // ==============================================================
  // ================ Public Functions: Initialize ================
  // ==============================================================

  $.initialize = function (params) {
    $._evalLog('Iniciando extensão.');

    if (!params) {
      try {
        // cria um cenário do Initialize, salvando os callbacks e licença.
        var functionScenario = this._buildFunctionScenario();

        // === CHAMA O MÉTODO QUE DÁ INÍCIO AO INITIALIZE ===
        // 1. verfica se a extensão está instalada e atualizada
        // 2. verifica se o host está instalado e atualizado
        $._requestHandler.tryDetectExtension(functionScenario, 20, null);
      } catch (exception) {
        setTimeout(function () {
          functionScenario.promise._responseFail(
            $._buildWebSignerException(exception),
          );
        }, 250);
      }

      return functionScenario.promise;
    } else {
      if (params.success === undefined || params.success === null) {
        throw 'A chave success não foi definida no parâmetro de entrada.';
      }

      if (typeof params.success !== 'function') {
        throw 'O valor da chave success no parâmetro de entrada precisa ser uma função.';
      }

      if (params.fail === undefined || params.fail === null) {
        throw 'A chave fail não foi definida no parâmetro de entrada.';
      }

      if (typeof params.fail !== 'function') {
        throw 'O valor da chave fail no parâmetro de entrada precisa ser uma função.';
      }

      if (params.notInstalled === undefined || params.notInstalled === null) {
        throw 'A chave notInstalled não foi definida no parâmetro de entrada.';
      }

      if (typeof params.notInstalled !== 'function') {
        throw 'O valor da chave notInstalled no parâmetro de entrada precisa ser uma função.';
      }

      // === PREPARA O RETORNO DO INITIALIZE ===
      // chamado quando extensão e host estiverem instalados e atualizados.
      var successRedirect = function () {
        EWS_isReady = true;
        $._evalLog('Iniciado com sucesso.');
        params.success();
      };

      // chamado quando houver falha, ou componente não instalado, ou desatualizado.
      var installExceptionRedirect = function (exception) {
        EWS_isReady = false;
        if (exception.notInstalled) {
          $._evalLog('Componente não instalado. ' + exception.message);
          params.notInstalled(exception.notInstalledStatus, exception.message);
        } else {
          $._evalLog('Falha na inicialização. ' + exception.message);
          params.fail(exception);
        }
      };

      // cria um cenário do Initialize, salvando os callbacks e licença.
      var functionScenario = this._buildFunctionScenario({
        toPromiseSuccess: successRedirect,
        toPromiseFail: installExceptionRedirect,
      });

      // === CHAMA O MÉTODO QUE DÁ INÍCIO AO INITIALIZE ===
      // 1. verfica se a extensão está instalada e atualizada
      // 2. verifica se o host está instalado e atualizado
      $._requestHandler.tryDetectExtension(functionScenario, 20, params);
    }
  };

  $.error = function (exception, promise, params) {
    EWS_isReady = false;
    if (promise.failCallback.name == 'installExceptionRedirect') {
      if (exception.notInstalled) {
        $._evalLog('Componente não instalado. ' + exception.message);
        params.notInstalled(exception.notInstalledStatus, exception.message);
      } else {
        $._evalLog('Falha na inicialização. ' + exception.message);
        params.fail(exception);
      }
    } else {
      if (exception.notInstalled) {
        $._evalLog('Componente não instalado. ' + exception.message);
        promise._responseNotInstalled(
          exception.notInstalledStatus,
          exception.message,
        );
      } else {
        $._evalLog('Falha na inicialização. ' + exception.message);
        promise._responseFail(exception);
      }
    }
  };

  // ====================================================================
  // ================ Public Functions: listCertificates ================
  // ====================================================================

  $.listCertificates = function (filters) {
    var functionScenario = this._buildFunctionScenario();

    try {
      if (!EWS_isReady) {
        throw 'O EVALWebSigner não foi iniciado.';
      }

      $._evalLog('Executando listCertificates.');

      var filterFunctions = [];

      if (filters) {
        if (typeof filters === 'function') {
          filterFunctions.push(filters);
        } else if (Array.isArray(filters)) {
          for (var i = 0; i < filters.length; i++) {
            var filterFunction = filters[i];
            if (typeof filterFunction !== 'function') {
              throw (
                'O parâmetro filters deve ser uma lista de funções. Valor recebido: ' +
                typeof filterFunction
              );
            }
          }
          filterFunctions = filters;
        } else {
          throw 'O parâmetro filters deve ser uma função ou uma lista de funções.';
        }
      }

      $._requestHandler.callExtensionFunction(
        functionScenario,
        'listCertificates',
        null,
        function (response) {
          return $._applyFilters(response, filterFunctions);
        },
      );
    } catch (exception) {
      setTimeout(function () {
        functionScenario.promise._responseFail(
          $._buildWebSignerException(exception),
        );
      }, 250);
    }

    // Esse retorno é fundamental para que o callback seja definido diretamente na chamada ao listCertificates na aplicacao cliente.
    return functionScenario.promise;
  };

  $._applyFilters = function (response, filterFunctions) {
    if (filterFunctions.length === 0) {
      return response; //nenhum filtro será aplicado.
    }

    var certificatesList = response.data;
    var certsFiltered = [];
    for (var i = 0; i < certificatesList.length; i++) {
      var cert = certificatesList[i];
      var isCertFiltered = true;
      for (var j = 0; j < filterFunctions.length; j++) {
        var filterFunction = filterFunctions[j];
        if (!filterFunction(cert)) {
          isCertFiltered = false;
          break; //cert não passou em um dos filtros.
        }
      }
      if (isCertFiltered) {
        certsFiltered.push(cert); //cert passou por todos os filtros! Salva na lista.
      }
    }

    let responseFiltered = {};
    responseFiltered.status = response.status;
    responseFiltered.data = certsFiltered;
    return responseFiltered;
  };

  $.filters = {
    // retorna uma função de filtro que filtra o certificado pelo CPF.
    isCpfEquals: function (cpf) {
      if (typeof cpf !== 'string') {
        throw (
          'O parametro cpf deve ser do tipo string. Valor recebido: ' +
          typeof cpf
        );
      }
      return function (cert) {
        if (cert.icpBr) {
          return cert.icpBr.cpf && cpf === cert.icpBr.cpf;
        }
        return false;
      };
    },
    // retorna uma função de filtro que filtra o certificado pelo CNPJ.
    isCnpjEquals: function (cnpj) {
      if (typeof cnpj !== 'string') {
        throw (
          'O parametro cnpj deve ser do tipo string. Valor recebido: ' +
          typeof cnpj
        );
      }
      return function (cert) {
        if (cert.icpBr) {
          return cert.icpBr.cnpj && cnpj === cert.icpBr.cnpj;
        }
        return false;
      };
    },
  };

  // =====================================s========================
  // ============= Public Functions: RequestAuth ==================
  // ==============================================================

  $.requestAuth = function (params) {
    var functionScenario = this._buildFunctionScenario();

    try {
      if (!EWS_isReady) {
        throw 'O EVALWebSigner não foi iniciado.';
      }

      $._evalLog('Executando requestAuth.');
      $._requestHandler.callExtensionFunction(
        functionScenario,
        'requestAuth',
        params,
        function (response) {
          return response;
        },
      );
    } catch (exception) {
      setTimeout(function () {
        functionScenario.promise._responseFail(
          $._buildWebSignerException(exception),
        );
      }, 250);
    }

    return functionScenario.promise;
  };

  // =====================================s=========================
  // ================ Public Functions: sign ======================
  // ==============================================================

  $.sign = function (params) {
    var functionScenario = this._buildFunctionScenario();

    try {
      if (!EWS_isReady) {
        throw 'O EVALWebSigner não foi iniciado.';
      }

      $._evalLog('Executando sign.');
      $._requestHandler.callExtensionFunction(
        functionScenario,
        'sign',
        params,
        function (response) {
          return response;
        },
      );
    } catch (exception) {
      setTimeout(function () {
        functionScenario.promise._responseFail(
          $._buildWebSignerException(exception),
        );
      }, 250);
    }

    // Esse retorno é fundamental para que o callback seja definido diretamente na chamada ao sign na aplicacao cliente.
    return functionScenario.promise;
  };

  $._applyCPFandDocId = function (response, request) {
    var requestSignList = request.data;
    var responseSignList = response.data;

    if (requestSignList.length === responseSignList.length) {
      for (var i = 0; i < responseSignList.length; i++) {
        if (requestSignList[i].docid) {
          responseSignList[i].docid = requestSignList[i].docid;
        }
        if (request.cpf) {
          responseSignList[i].cpf = request.cpf;
        }
      }
    }

    return response;
  };

  $.getBytes = function (string) {
    //return Buffer.byteLength(string, 'utf8');
    return new TextEncoder('utf-8').encode(string).length;
  };

  $.signaturePolicy = {
    AD_RB: 'AD-RB',
    AD_RT: 'AD-RT',
  };

  $.commitmentType = {
    proofOfOrigin: '1.2.840.113549.1.9.16.6.1',
    proofOfReceipt: '1.2.840.113549.1.9.16.6.2',
    proofOfDelivery: '1.2.840.113549.1.9.16.6.3',
    proofOfSender: '1.2.840.113549.1.9.16.6.4',
    proofOfApproval: '1.2.840.113549.1.9.16.6.5',
    proofOfCreation: '1.2.840.113549.1.9.16.6.6',
  };

  $.signatureType = {
    CMS: 'CMS',
    CADES: 'CAdES',
    XMLDSIG: 'XMLDSig',
    XADES: 'XAdES',
    ADOBEPDF: 'Adobe_PDF',
    PADES: 'PAdES',
  };

  $.signatureMode = {
    SIGN: 'sign',
    COSIGN: 'cosign',
    COUNTERSIGN: 'countersign',
  };

  $.attachedMode = {
    ATTACHED: 'attached',
    ENVELOPED: 'enveloped',
    ENVELOPING: 'enveloping',
    DETACHED: 'detached',
  };

  $.contentType = {
    BASE64: 'BASE64',
    PATH: 'PATH',
    XML: 'XML',
    HASH: 'HASH',
  };

  // ==============================================================
  // ============== Public Functions: saveLogs ====================
  // ==============================================================

  $.saveLogs = function () {
    $._evalLog('Executando saveLogs.');
    let functionScenario = this._buildFunctionScenario();
    $._requestHandler.callExtensionFunction(
      functionScenario,
      'saveLogs',
      null,
      null,
    );
    return functionScenario.promise;
  };

  // ==============================================================
  // =================== Detecção de navegador ====================
  // ==============================================================
  $.detectedBrowser = function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  };

  if ($._requestHandler === undefined) {
    var browserDetected = $.detectedBrowser();
    var isChrome = browserDetected.indexOf('Chrome') >= 0;
    var isFirefox = browserDetected.indexOf('Firefox') >= 0;
    var isIE = browserDetected.indexOf('IE') >= 0;

    //! DEFINIR UMA TRATATIVA FORA DO SCRIPT
    // if (!isChrome && !isFirefox) {
    //   throw 'Apenas os navegadores Chrome e Firefox são suportados.';
    // }

    // --------------------------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------ WEB EXTENSION REQUEST HANDLER -------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------------

    $._requestHandler = new (function () {
      const EVAL_EXT_REQUEST_EVENT =
        'br.com.evaltec.EVALWebSigner.RequestEvent';
      const EVAL_EXT_RESPONSE_EVENT =
        'br.com.evaltec.EVALWebSigner.ResponseEvent';

      //===================== para comunicação entre popup e event ======================
      const EVAL_PORT_EVENT = 'br.com.evaltec.EVALWebSigner.Port';
      let port = null;
      //=================================================================================

      var pendingRequests = {};

      //Cada requisição precisa ter um identificador único
      // gera um ID de 128 bits em forma canonica
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };

      var genUUID = function () {
        return (
          S4() +
          S4() +
          '-' +
          S4() +
          '-4' +
          S4().substr(0, 3) +
          '-' +
          S4() +
          '-' +
          S4() +
          S4() +
          S4()
        ).toLowerCase();
      };

      // ============================================================
      // ================ Comunicação com a extensão ================
      // ============================================================

      var callExtensionFunction = function (
        functionScenario,
        functionName,
        functionParams,
        responseProcessor,
      ) {
        var requestUUID = genUUID();
        pendingRequests[requestUUID] = {
          promise: functionScenario.promise,
          responseProcessor: responseProcessor,
        };

        var clientRequest = {
          requestUUID: requestUUID,
          license: functionScenario.license,
          functionName: functionName,
          functionParams: functionParams,
          addDomain: functionScenario.addDomain,
        };

        var requestSended = false;
        if (window.location.href) {
          if (
            window.location.href.indexOf('chrome-extension://') === 0 ||
            window.location.href.indexOf('moz-extension://') === 0
          ) {
            // manda pela porta da popup direto para o event-page
            if (port === null) {
              if (isChrome) {
                port = chrome.runtime.connect({
                  name: EVAL_PORT_EVENT,
                });
              } else {
                port = browser.runtime.connect({
                  name: EVAL_PORT_EVENT,
                });
              }
              port.onMessage.addListener(onExtensionFunctionResponseReceived);
            }
            port.postMessage(clientRequest);
            requestSended = true;
          }
        }

        if (!requestSended) {
          if (isChrome) {
            var event = new CustomEvent('build', {
              detail: clientRequest,
            });
            event.initEvent(EVAL_EXT_REQUEST_EVENT);
            document.dispatchEvent(event);
          } else {
            window.postMessage(
              {
                port: EVAL_EXT_REQUEST_EVENT,
                request: clientRequest,
              },
              '*',
            );
          }
        }
      };

      var onExtensionFunctionResponseReceived = function (extensionResponse) {
        var request = pendingRequests[extensionResponse.requestUUID];
        delete pendingRequests[extensionResponse.requestUUID];

        if (extensionResponse.success) {
          if (request.responseProcessor) {
            // ex.: listCertificates aplica um filtro na resposta
            extensionResponse.response = request.responseProcessor(
              extensionResponse.response,
            );
          }
          request.promise._responseSuccess(extensionResponse.response);
        } else {
          request.promise._responseFail(extensionResponse.exception);
        }
      };

      // ===================================================
      // ================ Initialize Method ================
      // ===================================================

      // Tenta detectar se a extensão está instalada (timeout = 5seg)
      var tryDetectExtension = function (functionScenario, count, params) {
        $._evalLog('Detectando instalação.');
        // Obtém o elemento que a extensão injetou na página
        var meta = document.getElementById(EWS_EVAL_EXTENSION_ID);

        if (meta === null) {
          if (count > 0) {
            setTimeout(function () {
              tryDetectExtension(functionScenario, count - 1, params);
            }, 250);
          } else {
            // A extensão não está instalada, retorna ao cliente
            let exception = {
              notInstalled: true,
              notInstalledStatus: $._notInstalledStates.EXTENSION_NOT_INSTALLED,
              message: 'A extensão não está instalada.',
              stackTrace: null,
              origin: EX_ORIGIN_API,
            };

            $.error(exception, functionScenario.promise, params);
            // functionScenario.promise._responseFail(exception);
          }
          return;
        }

        // A extensão está instalada. Agora verifica a versão.
        checkExtensionVersion(functionScenario, params);
      };

      var checkExtensionVersion = function (functionScenario, params) {
        $._evalLog('Verificando versão.');

        var subPromise = new $.Promise();

        // Chamado quando obtém a versão da extensão
        subPromise.success(function (extensionVersion) {
          if (
            $._compareVersions(
              extensionVersion,
              EVALWEBSIGNER_EXT_MIN_VERSION,
            ) < 0
          ) {
            // A extensão não é compatível com a versão mínima esperada
            let exception = {
              notInstalled: true,
              notInstalledStatus: $._notInstalledStates.EXTENSION_NOT_SUPPORTED,
              message: EWS_MSG_EXTENSION_NOT_SUPPORTED,
              stackTrace: null,
              origin: EX_ORIGIN_API,
            };

            $.error(exception, functionScenario.promise, params);
          } else {
            // A extensão está instalada e atualizada. Agora verifica o Host.
            initializeHost(functionScenario, params);
          }
        });

        // Chamado quando não for possível determinar a versão da extensão
        subPromise.fail(function (exception) {
          // Verifica se é erro de domínio não autorizado e tenta novamente.
          let message = exception.message;
          if (message.includes(DOMAIN_ERROR_CODE)) {
            let domain = extractRootDomain();
            let bol = false;
            bol = window.confirm(
              'EVALWebSigner:\nPermitir que o site "' +
                domain +
                '" realize assinaturas digitais?',
            );
            if (bol) {
              $._evalLog(
                'Será adicionado à lista de sites confiáveis: ' + domain,
              );
              // pega a versão da extensão novamente
              $._requestHandler.callExtensionFunction(
                {
                  license: functionScenario.license,
                  promise: subPromise,
                  addDomain: domain,
                },
                'getExtensionVersion',
                null,
              );
              return;
            }
          }
          // retorna o erro definitivamente.
          $.error(exception, functionScenario.promise, params);
        });

        // pega a versão da extensão
        $._requestHandler.callExtensionFunction(
          {
            license: functionScenario.license,
            promise: subPromise,
          },
          'getExtensionVersion',
          null,
        );
      };

      var initializeHost = function (functionScenario, params) {
        var subPromise = new $.Promise();

        // Chamado quando obtém as informações do HOST (getInfo)
        subPromise.success(function (response) {
          if (
            $._compareVersions(
              response.info.hostVersion,
              EVALWEBSIGNER_HOST_MIN_VERSION,
            ) < 0
          ) {
            // O Host não é compatível com a versão mínima esperada
            let exception = {
              notInstalled: true,
              notInstalledStatus: $._notInstalledStates.HOST_NOT_SUPPORTED,
              message: EWS_MSG_HOST_NOT_SUPPORTED,
              stackTrace: null,
              origin: EX_ORIGIN_API,
            };

            $.error(exception, functionScenario.promise, params);
          } else {
            // O Host está instalado e atualizado, assim como a extensão. Incializado com sucesso!
            EWS_isReady = true;
            $._evalLog('Iniciado com sucesso.');
            functionScenario.promise._responseSuccess();
          }
        });

        // Chamado quando não for possível determinar a versão do Host
        subPromise.fail(function (exception) {
          if (exception.hostNotInstalled) {
            // O HOST não respondeu
            let exc = {
              notInstalled: true,
              notInstalledStatus: $._notInstalledStates.HOST_NOT_INSTALLED,
              message: EWS_MSG_HOST_NOT_INSTALLED,
              stackTrace: null,
              origin: EX_ORIGIN_API,
            };
            $.error(exc, functionScenario.promise, params);
          } else {
            $.error(exception, functionScenario.promise, params);
          }
        });

        // inicializa o HostgetInfo
        $._requestHandler.callExtensionFunction(
          {
            license: functionScenario.license,
            promise: subPromise,
          },
          'getInfo',
          null,
        );
      };

      var extractRootDomain = function () {
        let url = window.location.href;
        var hostname;
        url.indexOf('://') > -1
          ? (hostname = url.split('/')[2])
          : (hostname = url.split('/')[0]);
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        hostname = hostname.replace(/www./g, '');
        return hostname;
      };

      // =================================================================================
      // ================ Ações que devem ser executadas com antecedência ================
      // =================================================================================

      this.callExtensionFunction = callExtensionFunction;
      this.tryDetectExtension = tryDetectExtension;

      if (isChrome) {
        document.addEventListener(EVAL_EXT_RESPONSE_EVENT, function (event) {
          onExtensionFunctionResponseReceived(event.detail);
        });
      } else {
        window.addEventListener('message', function (event) {
          if (
            event &&
            event.data &&
            event.data.port === EVAL_EXT_RESPONSE_EVENT
          ) {
            onExtensionFunctionResponseReceived(event.data.message);
          }
        });
      }
    })();
  }

  // =================================================================================
  // ========================== Configurações de Logs ================================
  // =================================================================================

  $._evalLog = function (message) {
    if (window.console) {
      window.console.log(EWS_LOG_API_TAG + message);
    }
  };
})(EvalWebSigner.prototype);
