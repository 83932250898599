import { useState } from 'react';

import dayjs from 'dayjs';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ModalTermo from '../ModalTermo/ModalTermo';

interface CardMobileProps {
  relatorio: GetRelatorioEmails;
  modalVisualizarEmailDisclosure: any;
}

const CardMobile = ({
  relatorio,
  modalVisualizarEmailDisclosure,
}: CardMobileProps) => {
  const [visible, setVisible] = useState(false);

  const handleText = (text: string): string => {
    if (text) {
      return text;
    }

    return 'Não informado';
  };

  return (
    <>
      <div className="cardMobile-container p-mt-3">
        <div className="p-col-12 column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Data de envio
          </SimpleText>

          <SimpleText
            className="p-my-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            {dayjs(relatorio?.dataEnvio).format('DD/MM/YYYY hh:mm:ss')}
          </SimpleText>
        </div>

        <div className="p-col-12 column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Nome/Cartão do beneficiário
          </SimpleText>

          <SimpleText className="p-my-2" fontColor={FONT_COLOR.COLOR_16}>
            {handleText(relatorio?.nomePaciente)}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            {handleText(relatorio?.cartaoBeneficiario)}
          </SimpleText>
        </div>

        <div className="p-col-12 column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Nome médico
          </SimpleText>

          <SimpleText className="p-mt-2" fontColor={FONT_COLOR.COLOR_16}>
            {handleText(relatorio?.nomeMedico)}
          </SimpleText>
        </div>

        <div className="p-col-12 column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Contato/Forma de envio/Conteúdo
          </SimpleText>
          <SimpleText className="p-my-2" fontColor={FONT_COLOR.COLOR_16}>
            {relatorio?.formaEnvio === 'EMAIL'
              ? relatorio?.emailDestinatario
              : relatorio?.numeroDestinatarioSms}
          </SimpleText>
          <div className="p-d-flex p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              {relatorio?.formaEnvio}
            </SimpleText>
            <IconButton
              btnType={IconBtnTypes.ICON_ONLY}
              icon="fas fa-paperclip"
              onClick={() => modalVisualizarEmailDisclosure.open()}
            />
          </div>
        </div>

        <div className="p-col-12 column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Data consentimento/Status
          </SimpleText>
          <SimpleText
            className="p-my-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            {dayjs(relatorio?.dataEnvio).format('DD/MM/YYYY hh:mm:ss')}
          </SimpleText>
        </div>

        <div
          onClick={() => setVisible(true)}
          className="p-col-12 column p-mt-2"
        >
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Termo
          </SimpleText>

          <IconButton
            btnType={IconBtnTypes.ICON_ONLY}
            icon="fa fa-file"
            className="p-mt-2"
          />
        </div>

        <div id="location" className="p-col-12 column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Localização
          </SimpleText>

          <IconButton
            btnType={IconBtnTypes.ICON_ONLY}
            icon="fa fa-map-marker-alt"
            className="p-mt-2"
          />

          <div className="tolltip-location">
            <SimpleText
              bold
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
            >
              Detalhes da localização:
            </SimpleText>

            <div className="item">
              <SimpleText
                bold
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                IP do computador:
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {handleText(relatorio?.telemedicinaLogTermo?.ipComputador)}
              </SimpleText>
            </div>
            <div className="item">
              <SimpleText
                bold
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Porta:
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {handleText(relatorio?.telemedicinaLogTermo?.portaComputador)}
              </SimpleText>
            </div>

            <div className="item">
              <SimpleText
                bold
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Localização:
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {handleText(relatorio?.telemedicinaLogTermo?.localRegistro)}
              </SimpleText>
            </div>
          </div>
        </div>
      </div>

      {visible && (
        <ModalTermo
          relatorio={relatorio}
          visible={visible}
          onHide={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default CardMobile;
