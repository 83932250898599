import Http, { HttpParams } from 'src/core/http/Http';

export const inativarClienteStatus = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/cardapio/${id}/inativar`,
    ...options,
  });
};

export const ativarClienteStatus = (
  idCardapio: number,
  idFuncionalidade: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/cardapio/${idFuncionalidade}/${idCardapio}/ativar`,
    ...options,
  });
};

const ClienteAPI = { inativarClienteStatus, ativarClienteStatus };

export default ClienteAPI;
