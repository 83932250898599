import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

interface DeleteProcedimentosSolicitadosProps {
  idAtendimento: number;
  onDelete: () => void;
}

export const useDeleteProcedimentosSolicitados = (
  props: DeleteProcedimentosSolicitadosProps,
) => {
  const { idAtendimento, onDelete } = props;

  const {
    fetchProcedimentosSolicitados,
    setLoadingFetchProcedimentosSolicitados,
  } = useSolicitacaoExameCirurgia();

  const handleDeleteProcedimentoSolicitado = async (idProcedimento: number) => {
    try {
      setLoadingFetchProcedimentosSolicitados(true);

      await SolicitacoesProcedimentosAPI.deleteProcedimento(
        Number(idAtendimento),
        idProcedimento,
      );

      fetchProcedimentosSolicitados();
      onDelete();
    } catch (error) {
      setLoadingFetchProcedimentosSolicitados(false);
    }
  };

  return {
    handleDeleteProcedimentoSolicitado,
  };
};
