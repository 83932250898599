import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { ReceitasOrientacoesAPI } from 'src/APIs/ProntuarioAPI/ReceitasOrientacoesAPI/ReceitaAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldProps = DropdownProps & {
  name?: string;
  label?: string;
};

export const FieldOrdenacao = ({
  name = 'ordenacao',
  label,
  ...rest
}: FieldProps) => {
  const { control } = useFormContext();
  const [item, setItem] = useState<any[]>([]);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async () => {
    try {
      const res = await ReceitasOrientacoesAPI.getReceitasOrientacoesTipos({
        throwError: true,
      });
      setItem(res);
    } catch {
      setItem([]);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = item.map(via => ({
      label: via.descricao,
      value: via.codigo,
    }));

    return data;
  }, [item]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
