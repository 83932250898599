import { useEffect, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface ServicoTussFieldProps {
  name: string;
  idConvenio: number;
}

export const ServicoTussField = (props: ServicoTussFieldProps) => {
  const { name, idConvenio } = props;

  const [isFetching, setIsFetching] = useState(true);
  const [servicosTuss, setServicosTuss] = useState<Hospital[]>([]);
  const [query, setQuery] = useState('');

  const { control } = useFormContext();

  const handleSetQuery = useThrottle(setQuery, 500);

  const fetchServicosTuss = async (query: string, idConvenio: number) => {
    try {
      setIsFetching(true);

      const response = await ServicoTussCRUDAPI.getSolicitacaoExame({
        codigoNomeApelido: query,
        idConvenio,
        opme: false,
      });

      setServicosTuss(response);
    } catch (error) {
      setServicosTuss([]);
    } finally {
      setIsFetching(false);
    }
  };

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  useEffect(() => {
    if (!idConvenio) return;
    fetchServicosTuss(query, idConvenio);
  }, [idConvenio, query]);

  const servicosTussOptions = servicosTuss.map((servicoTuss: any) => ({
    label: servicoTuss.nome,
    value: servicoTuss.id,
    nomeCodigo: servicoTuss.nome + servicoTuss.codigo,
    servicoTuss,
  }));

  const itemTemplate = (option: any) => (
    <div className="p-d-flex">
      <div className="codigo-servico-tuss-field">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {option.servicoTuss.codigo}
        </SimpleText>
      </div>
      <SimpleText fontColor={FONT_COLOR.COLOR_16}>
        {option.servicoTuss.nome}
      </SimpleText>
    </div>
  );

  return (
    <DropdownControlled
      className="p-col-12 p-sm-9"
      control={control}
      name={name}
      label="Código ou descrição"
      filterPlaceholder="Digite para pesquisar"
      filterBy="nomeCodigo"
      itemTemplate={itemTemplate}
      options={servicosTussOptions}
      onFilter={e => handleSetQuery(e.filter)}
      carregandoFiltro={isFetching}
      loading={isFetching}
      errorMsg={error?.message}
    />
  );
};
