import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

type IProps = DisclosureType & {
  onConfirm: (retornar: boolean) => void;
};

export const DialogEncaminharExecucao = ({
  onConfirm,
  close,
  isOpen,
  state,
}: IProps) => {
  return (
    <ConfirmDialog
      visible={isOpen}
      header="Tem certeza que deseja encaminhar a prescrição para a execução?"
      text="Ao confirmar, você estará direcionando o atendimento para a enfermaria, e não poderá 
      editá-lo até que a prescrição seja executada. "
      confirmBtnType="default"
      onConfirm={() => [onConfirm(state.retornar), close()]}
      onHide={() => close()}
    />
  );
};
