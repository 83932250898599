import { useState, useCallback } from 'react';

import { useLocation, useParams } from 'react-router';

import CircunferenciaAbdominalAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaAbdominalAPI/CircunferenciaAbdominalAPI';

import useSize from 'src/core/hooks/useSize';

import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from '../PanelContent/PanelContent';
import PanelHeader from '../PanelHeader/PanelHeader';

interface CircunferenciaAbdominalProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const CircunferenciaAbdominal = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: CircunferenciaAbdominalProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { isMobile } = useSize();

  const [unidadeMedidaVisualizacao, setUnidadeMedidaVisualizacao] = useState<
    'MOSTRAR_ORIGINAL' | 'METRO' | 'CENTIMETRO' | 'MILIMETRO'
  >('MOSTRAR_ORIGINAL');

  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      return CircunferenciaAbdominalAPI.getCircunferenciaAbdominal({
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        ...params,
      });
    },
    [idAtendimento, selectedAtendimento?.idAtendimento],
  );

  const manterDuasDecimais = (value: number) => {
    return String(value)
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\.\d{2}).+/g, '$1');
  };

  const formatValueView = (value: number, unidadeMedida: any) => {
    if (
      unidadeMedida.codigo === 'M' &&
      unidadeMedidaVisualizacao === 'CENTIMETRO'
    ) {
      return `${manterDuasDecimais(value * 100)} Centímetros`;
    } else if (
      unidadeMedida.codigo === 'M' &&
      unidadeMedidaVisualizacao === 'MILIMETRO'
    ) {
      return `${manterDuasDecimais(value * 1000)} Milímetros`;
    }

    if (
      unidadeMedida.codigo === 'CM' &&
      unidadeMedidaVisualizacao === 'MILIMETRO'
    ) {
      return `${manterDuasDecimais(Number(value) * 10)} Milímetros`;
    } else if (
      unidadeMedida.codigo === 'CM' &&
      unidadeMedidaVisualizacao === 'METRO'
    ) {
      return `${manterDuasDecimais(Number(value) / 100)} Metros`;
    }

    if (
      unidadeMedida.codigo === 'MM' &&
      unidadeMedidaVisualizacao === 'CENTIMETRO'
    ) {
      return `${manterDuasDecimais(Number(value) / 10)} Centímetros`;
    } else if (
      unidadeMedida.codigo === 'MM' &&
      unidadeMedidaVisualizacao === 'METRO'
    ) {
      return `${manterDuasDecimais(Number(value) / 1000)} Metros`;
    }

    return `${value} ${unidadeMedida.descricao}`;
  };

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
  ) => {
    return (
      <div className="p-col-3 p-d-flex">
        <div className="column p-jc-center">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            C. Abdominal
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {formatValueView(
              preAtendimento.circunferenciaAbdominal,
              preAtendimento.unidadeMedida,
            )}
          </SimpleText>
        </div>

        {!isMobile && <Separator layout="vertical" />}
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento} />
      </Panel>
    );
  };

  return (
    <div className="container-unidade-medida">
      <div className="p-grid">
        <SimpleText
          className="p-col-12 p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Exibir unidades de medida em:
        </SimpleText>

        <div className="p-col-6 p-md-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Mostrar original"
            value="MOSTRAR_ORIGINAL"
            checked={unidadeMedidaVisualizacao === 'MOSTRAR_ORIGINAL'}
            onChange={() => setUnidadeMedidaVisualizacao('MOSTRAR_ORIGINAL')}
          />
        </div>
        <div className="p-col-6 p-md-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Metro"
            value="METRO"
            checked={unidadeMedidaVisualizacao === 'METRO'}
            onChange={() => setUnidadeMedidaVisualizacao('METRO')}
          />
        </div>
        <div className="p-col-6 p-md-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Centímetro"
            value="CENTIMETRO"
            checked={unidadeMedidaVisualizacao === 'CENTIMETRO'}
            onChange={() => setUnidadeMedidaVisualizacao('CENTIMETRO')}
          />
        </div>
        <div className="p-col-6 p-md-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Milímetro"
            value="MILIMETRO"
            checked={unidadeMedidaVisualizacao === 'MILIMETRO'}
            onChange={() => setUnidadeMedidaVisualizacao('MILIMETRO')}
          />
        </div>
      </div>

      <InfiniteScroll
        fetchAPI={fetchAPI}
        renderRow={renderRow}
        updateList={updateList}
      />
    </div>
  );
};

export default CircunferenciaAbdominal;
