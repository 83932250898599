import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();
  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-m-1">
          <SimpleText
            className="p-col-2 p-lg-3"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Descrição
          </SimpleText>

          <SimpleText
            className="p-col-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Pontuação
          </SimpleText>

          <SimpleText
            className="p-col-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Consultório
          </SimpleText>

          <SimpleText
            className="p-col-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Data de inclusão
          </SimpleText>

          <SimpleText
            className="p-grid p-md-4 p-lg-3 p-jc-end"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Ações
          </SimpleText>
        </div>
      )}
    </>
  );
};
