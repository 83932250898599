import { ReactNode, useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { ConvenioContext } from '../../contexts/ManterConvenio/ManterConvenio';

export interface BasicInfoStepData {
  razaoSocial?: string;
  registroAns?: string;
  nomeFantasia?: string;
  cnpj?: string;
  site?: string;
  telefoneContato1?: string;
  telefoneContato2?: string;
  endereco?: {
    numero?: string;
    complemento?: string;
    logradouro?: string;
    tipo?: string | null;
    cep?: string;
    bairro?: string;
    idCidade?: number;
    idPaisEstrangeiro?: number;
  };
  isParticular: boolean;
  tipoConvenio?: string;
}

interface ServicoTussData {
  id: number;
  nome: string;
  codigo: string;
}

export interface AdditionalInfoStepData {
  exibirRelatorios: boolean;
  exibirSistema: boolean;
  exibirCuidadorViverBemFolhaRosto: boolean;
  mascaraCarterinha?: string;
  urlLogotipo?: string;
  tiposPlano?: any[];
}

export interface TissInfoData {
  tissProcedimentoAgrupado: boolean;
  exigirExecutanteSpSadt: boolean;
  habilitaEnvioCredencialTiss: boolean;
  tissEtapaAutorizacaoPrestFixo: boolean;
  ativaEnvioCobrancaWs: boolean;
  enviarViaCartao: boolean;
  habilitarGa: boolean;
  habilitarGaObrigaRnint: boolean;
  statusGuiaAposTimeout: boolean;
  executanteFixoTiss: boolean;
  habilitarGuiaAutorizacaoEncaminhamento: boolean;
  justificativaPresenca: string;
  executanteNomeFixo?: string;
  executanteCnesFixo?: string;
  executanteCrmFixo?: string;
  horariosSchedulerEnvioTiss?: string;
  limiteTentativasSchedulerTiss?: string;
  limiteGuiasPorLote?: string;
  limiteLotesPorEnvio?: string;
  telemedicinaLoteSpSadt?: string;
  tissEtapaAutorizacao?: string;
  codigoGlosaPresenca?: string;
  servicoTussEncaminhamento: ServicoTussData | null;
  servicoTussContrarreferencia: ServicoTussData | null;
  servicoTussPuericultura: ServicoTussData | null;
  servicoTussTelemedicina: ServicoTussData | null;
  servicoTussConsulta: ServicoTussData | null;
  utilizaTiss: boolean;
  mensagemConexaoOperadora?: string;
  mensagemFalhaComunicacao?: string;
}

export interface WsdlTissInfoData {
  versaoTiss?: string;
  urlCancelaGuia?: string;
  urlLoteGuias?: string;
  urlSolicitacaoDemonstrativoRetorno?: string;
  urlSolicitacaoProcedimento?: string;
  urlSolicitacaoStatusAutorizacao?: string;
  urlSolicitacaoStatusProtocoloRecurso?: string;
  urlSolicitacaoStatusProtocolo?: string;
  urlVerificaElegibilidade?: string;
  urlRecursoGlosa?: string;
  timeoutCancelaGuia?: number;
  timeoutLoteGuia?: number;
  timeoutRecursoGlosa?: number;
  timeoutSolicitacaoDemonstrativoRetorno?: number;
  timeoutSolicitacaoProcedimento?: number;
  timeoutSolicitacaoStatusAutorizacao?: number;
  timeoutSolicitacaoStatusProtocolo?: number;
  timeoutSolicitacaoStatusProtocoloRecurso?: number;
  timeoutVerificaElegibilidade?: number;
}

const formatServicoTuss = (servicoTuss: ServicoTussData) => {
  if (!servicoTuss) return null;

  return {
    codigo: servicoTuss.codigo,
    nome: servicoTuss.nome,
    id: servicoTuss.id,
  };
};

interface ManterConvenioProviderProps {
  children: ReactNode;
}

export function ManterConvenioProvider({
  children,
}: ManterConvenioProviderProps) {
  const { idConvenio } = useParams();

  const navigate = useNavigate();

  const [loadingConvenioData, setLoadingConvenioData] = useState(false);
  const [idConvenioEdit, setIdConvenioEdit] = useState<number | null>(null);
  const [idConvenioTissEdit, setIdConvenioTissEdit] = useState<number | null>(
    null,
  );
  const [basicInfoStepData, setBasicInfoStepData] =
    useState<BasicInfoStepData | null>(null);
  const [additionalInfoStepData, setAdditionalInfoStepData] =
    useState<AdditionalInfoStepData | null>(null);
  const [tissInfoData, setTissInfoData] = useState<TissInfoData | null>(null);
  const [wsdlTissInfoData, setWsdlTissInfoData] =
    useState<WsdlTissInfoData | null>(null);
  const [procedimentosConvenio, setProcedimentosConvenio] = useState<any>();

  useEffect(() => {
    const loadConvenioData = async () => {
      if (!idConvenio) return setIdConvenioEdit(null);

      setLoadingConvenioData(true);

      setIdConvenioEdit(Number(idConvenio));

      try {
        const response = await ConvenioAPI.loadConveniosById(
          Number(idConvenio),
        );

        const {
          timeoutConsultaProcedimentosSeriados,
          urlConsultaProcedimentosSeriados,
          habilitarConsultaProcedimentosSeriados,
        } = response;

        setProcedimentosConvenio({
          timeoutConsultaProcedimentosSeriados,
          urlConsultaProcedimentosSeriados,
          habilitarConsultaProcedimentosSeriados,
        });

        const {
          exibirRelatorios = false,
          exibirSistema = false,
          exibirCuidadorViverBemFolhaRosto = false,
          mascaraCarterinha = '',
          urlLogotipo = '',
          tiposPlano = [],
          ...rest
        } = response;

        const estado = rest.endereco?.cidade?.idEstado;
        const idCidade = rest.endereco?.idCidade;
        const idPaisEstrangeiro = idCidade
          ? rest.endereco?.cidade?.estado?.idPais
          : rest.endereco?.idPaisEstrangeiro;

        const data =
          rest?.tipoConvenio === 'PARTICULAR'
            ? {
                nomeFantasia: capitalizeFirstLetter(
                  rest.nomeFantasia || 'Particular',
                ),
                isParticular: true,
              }
            : {
                ...rest,
                isParticular: false,
                endereco: {
                  cep: rest.endereco?.cep || '',
                  tipo: rest.endereco?.tipo || null,
                  bairro: rest.endereco?.bairro || '',
                  logradouro: rest.endereco?.logradouro || '',
                  numero: rest.endereco?.numero || '',
                  complemento: rest.endereco?.complemento || '',
                  ...(estado ? { estado } : {}),
                  ...(idCidade ? { idCidade } : {}),
                  ...(idPaisEstrangeiro !== null &&
                  idPaisEstrangeiro !== undefined
                    ? { idPaisEstrangeiro }
                    : {}),
                },
              };

        setBasicInfoStepData(data as any);

        setAdditionalInfoStepData({
          exibirRelatorios,
          exibirSistema,
          exibirCuidadorViverBemFolhaRosto,
          mascaraCarterinha,
          urlLogotipo,
          tiposPlano,
        });
      } catch {
        navigate('/cadastros/convenio', { replace: true });
      } finally {
        setLoadingConvenioData(false);
      }
    };

    loadConvenioData();
  }, [idConvenio, navigate]);

  useEffect(() => {
    const fetchConvenioTiss = async () => {
      if (!idConvenioEdit) return;

      try {
        const response = await ConvenioAPI.getConveniosTissById(idConvenioEdit);

        setIdConvenioTissEdit(response?.id || null);

        setTissInfoData({
          ...response,
          tissProcedimentoAgrupado: response?.tissProcedimentoAgrupado || false,
          exigirExecutanteSpSadt: response?.exigirExecutanteSpSadt || false,
          habilitaEnvioCredencialTiss:
            response?.habilitaEnvioCredencialTiss || false,
          tissEtapaAutorizacaoPrestFixo:
            response?.tissEtapaAutorizacaoPrestFixo || false,
          ativaEnvioCobrancaWs: response?.ativaEnvioCobrancaWs || false,
          enviarViaCartao: response?.enviarViaCartao || false,
          habilitarGa: response?.habilitarGa || false,
          habilitarGaObrigaRnint: response?.habilitarGaObrigaRnint || false,
          statusGuiaAposTimeout: response?.statusGuiaAposTimeout || false,
          executanteFixoTiss: response?.executanteFixoTiss || false,
          habilitarGuiaAutorizacaoEncaminhamento:
            response?.habilitarGuiaAutorizacaoEncaminhamento || false,
          justificativaPresenca: response?.justificativaPresenca || '',
          executanteNomeFixo: response?.executanteNomeFixo || '',
          executanteCnesFixo: response?.executanteCnesFixo || '',
          executanteCrmFixo: response?.executanteCrmFixo || '',
          horariosSchedulerEnvioTiss:
            response?.horariosSchedulerEnvioTiss || '',
          limiteTentativasSchedulerTiss:
            response?.limiteTentativasSchedulerTiss || '',
          limiteGuiasPorLote: response?.limiteGuiasPorLote || '',
          limiteLotesPorEnvio: response?.limiteLotesPorEnvio || '',
          telemedicinaLoteSpSadt: response?.telemedicinaLoteSpSadt,
          tissEtapaAutorizacao: response?.tissEtapaAutorizacao || '',
          utilizaTiss: response?.utilizaTiss || false,
          servicoTussEncaminhamento: formatServicoTuss(
            response?.servicoTussEncaminhamento,
          ),
          servicoTussContrarreferencia: formatServicoTuss(
            response?.servicoTussContrarreferencia,
          ),
          servicoTussPuericultura: formatServicoTuss(
            response?.servicoTussPuericultura,
          ),
          servicoTussTelemedicina: formatServicoTuss(
            response?.servicoTussTelemedicina,
          ),
          servicoTussConsulta: formatServicoTuss(response?.servicoTussConsulta),
          codigoGlosaPresenca: response?.codigoGlosaPresenca,
          mensagemConexaoOperadora: response?.mensagemConexaoOperadora || '',
          mensagemFalhaComunicacao: response?.mensagemFalhaComunicacao || '',
        });

        setWsdlTissInfoData({
          versaoTiss: response?.versaoTiss || '',
          urlCancelaGuia: response?.urlCancelaGuia || '',
          urlLoteGuias: response?.urlLoteGuias || '',
          urlSolicitacaoDemonstrativoRetorno:
            response?.urlSolicitacaoDemonstrativoRetorno || '',
          urlSolicitacaoProcedimento:
            response?.urlSolicitacaoProcedimento || '',
          urlSolicitacaoStatusAutorizacao:
            response?.urlSolicitacaoStatusAutorizacao || '',
          urlSolicitacaoStatusProtocoloRecurso:
            response?.urlSolicitacaoStatusProtocoloRecurso || '',
          urlSolicitacaoStatusProtocolo:
            response?.urlSolicitacaoStatusProtocolo || '',
          urlVerificaElegibilidade: response?.urlVerificaElegibilidade || '',
          urlRecursoGlosa: response?.urlRecursoGlosa || '',
          timeoutCancelaGuia: response?.timeoutCancelaGuia,
          timeoutLoteGuia: response?.timeoutLoteGuia,
          timeoutRecursoGlosa: response?.timeoutRecursoGlosa,
          timeoutSolicitacaoDemonstrativoRetorno:
            response?.timeoutSolicitacaoDemonstrativoRetorno,
          timeoutSolicitacaoProcedimento:
            response?.timeoutSolicitacaoProcedimento,
          timeoutSolicitacaoStatusAutorizacao:
            response?.timeoutSolicitacaoStatusAutorizacao,
          timeoutSolicitacaoStatusProtocolo:
            response?.timeoutSolicitacaoStatusProtocolo,
          timeoutSolicitacaoStatusProtocoloRecurso:
            response?.timeoutSolicitacaoStatusProtocoloRecurso,
          timeoutVerificaElegibilidade: response?.timeoutVerificaElegibilidade,
        });
      } catch (error) {
        navigate('/cadastros/convenio', { replace: true });
      }
    };

    fetchConvenioTiss();
  }, [idConvenioEdit, navigate]);

  return (
    <ConvenioContext.Provider
      value={{
        idConvenioEdit,
        idConvenioTissEdit,
        basicInfoStepData,
        additionalInfoStepData,
        loadingConvenioData,
        tissInfoData,
        wsdlTissInfoData,
        procedimentosConvenio,
        setBasicInfoStepData,
        setAdditionalInfoStepData,
        setIdConvenioEdit,
        setIdConvenioTissEdit,
        setTissInfoData,
        setWsdlTissInfoData,
      }}
    >
      {children}
    </ConvenioContext.Provider>
  );
}
