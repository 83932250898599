import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PanelHeader.scss';

interface PanelHeaderProps {
  preAtendimento: PreAtendimento;
  isCollapsed: boolean;
  optionsMenu: JSX.Element;
  leftContent: (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
    isCollapsed: boolean,
  ) => JSX.Element;
  selectedTipo: string;
  onToggle: () => void;
  hideOptionsMenu: boolean;
}

function PanelHeader({
  preAtendimento,
  isCollapsed,
  optionsMenu,
  leftContent,
  selectedTipo,
  onToggle,
  hideOptionsMenu,
}: PanelHeaderProps) {
  const headerTextClassName =
    preAtendimento.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    preAtendimento.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  return (
    <>
      <div className="panel-header-preAtendimentos p-grid p-col-12 p-align-center">
        {leftContent(
          preAtendimento,
          headerTextClassName,
          dataTextClassName,
          isCollapsed,
        )}

        <div className="p-col p-sm-3 p-d-flex">
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data de Ocorrência
            </SimpleText>

            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {dayjs(preAtendimento.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
            </SimpleText>
          </div>

          <div className="coluna-dados retroativo-chip p-as-center">
            {preAtendimento.justificativaOcorrenciaRetroativa && (
              <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
            )}
          </div>
        </div>
        <div className="p-col p-sm-2 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Inserido por:
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.usuarioAtualizacao?.nome}
          </SimpleText>
        </div>
        <div className="p-col-1 p-d-flex p-ai-center p-jc-end">
          <Button
            icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            btnType="ghost"
            onClick={onToggle}
          />

          {!hideOptionsMenu && optionsMenu}
        </div>
      </div>
    </>
  );
}

export default PanelHeader;
