import { useFormContext } from 'react-hook-form';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { VariantPanel } from 'src/components/Panel/VariantPanel/VariantPanel';

export const TratamentosAnterioresForm = () => {
  const { control } = useFormContext();

  return (
    <VariantPanel title="Tratamentos anteriores">
      <form className="p-grid">
        <FormInput
          label="Cirurgia"
          name="tratamentosAnteriores.cirurgia"
          placeholder="Digite"
          className="p-col-6"
          maxLength={40}
          hideTextErrorSpace
        />
        <CalendarInputControlled
          control={control}
          name="tratamentosAnteriores.dataRealizacao"
          label="Data da realização"
          placeholder="dd/mm/aaaa"
          className="p-col-6"
          showIcon
        />
        <FormInput
          label="Área irradiada"
          name="tratamentosAnteriores.areaIrradiada"
          placeholder="cm"
          className="p-col-6"
          maxLength={40}
          hideTextErrorSpace
        />
        <CalendarInputControlled
          control={control}
          name="tratamentosAnteriores.dataAplicacao"
          label="Data da aplicação"
          placeholder="dd/mm/aaaa"
          className="p-col-6"
          showIcon
        />
      </form>
    </VariantPanel>
  );
};
