import { useEffect, useState } from 'react';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import { useNovoAgendamento } from '../../hooks/NovoAgendamentoContext';

import './Dialog.scss';

type IProps = DisclosureType & {
  handleAgendar: () => void;
  idConvenio: number;
};

export const DialogPacienteNaoElegivel = ({
  close,
  handleAgendar,
  idConvenio,
}: IProps) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [canProceed, setCanProceed] = useState(false);

  const { resetElegibilidade } = useNovoAgendamento();

  useEffect(() => {
    setIsLoading(true);
    ConvenioAPI.getConveniosTissById(idConvenio)
      .then(tiss => {
        setMessage(tiss.mensagemRetornoNegativaElegibilidadePortalPaciente);
        setCanProceed(!tiss.bloquearAgendamentoNegativaElegibilidade);
      })
      .finally(() => setIsLoading(false));
  }, [idConvenio]);

  const title = canProceed
    ? 'Não foi possivel validar o seu plano. Verifique o motivo com a operadora. Você pode cancelar o agendamento ou continuar para verificar o ocorrido mais tarde.'
    : 'Não foi possivel validar o seu plano. Verifique o motivo com a operadora e volta a realizar o agendamento mais tarde!';

  return (
    <Dialog
      id="DialogPacienteNaoElegivel"
      visible
      onHide={close}
      header="Atenção erro ao validar o plano"
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-3 p-w-100">
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <SimpleText className="p-col-12 p-px-0 p-line-height-2">
            {!!message ? message : title}
          </SimpleText>
        )}

        <div className="p-d-flex p-gap-2 p-w-100">
          <Button
            label="Cancelar agendamento"
            btnType="danger"
            stretch
            onClick={() => [close(), resetElegibilidade()]}
          />

          {canProceed && (
            <Button
              label="Continuar"
              stretch
              onClick={() => [close(), handleAgendar()]}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
