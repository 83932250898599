import { memo, useCallback } from 'react';

import TipoCuidadoAPI from 'src/APIs/ConfigAPI/TipoCuidado/TipoCuidadoAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalExcluirTipoCuidado {
  visible: boolean;
  onHide(): void;
  tipoCuidado: any;
  reload: any;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        btnType="danger"
        className={'danger'}
        label="Sim, excluir"
        onClick={onAdd}
        stretch
      />
    </div>
  );
});

const ModalExcluirTipoCuidado = ({
  tipoCuidado,
  visible,
  onHide,
  reload,
}: ModalExcluirTipoCuidado) => {
  const handleDeletarTipoCuidado = useCallback(async () => {
    const response = await TipoCuidadoAPI.removeTipoCuidado(tipoCuidado.id, {
      return: true,
    });

    if (response.status === 204) {
      reload();
      onHide();
    }
  }, [onHide, reload, tipoCuidado.id]);

  return (
    <Dialog
      id="excluir-tipo-motivo"
      className="footer-on"
      type="modal"
      header="Excluir tipo de motivo de cuidado"
      visible={visible}
      onHide={onHide}
      footer={() => (
        <Footer onAdd={handleDeletarTipoCuidado} onCancel={onHide} />
      )}
    >
      <SimpleText style={{ paddingLeft: 10 }} fontColor={FONT_COLOR.COLOR_40}>
        Você confirma a exclusão do tipo de motivo de cuidado?
      </SimpleText>
    </Dialog>
  );
};

export default ModalExcluirTipoCuidado;
