import { useMemo } from 'react';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalPacientes.scss';

export const PacienteDuplicadoModal = ({
  isOpen,
  close,
  onSelect,
  setExibirModal,
  state,
}: any) => {
  const pacientes = useMemo(() => state || [], [state]);

  const duplicateData = useMemo(
    () =>
      pacientes.length &&
      pacientes
        .map((pac: any) => pac?.tipoDadoDuplicado)
        .join(pacientes.length > 2 ? ', ' : ' ou '),
    [pacientes],
  );

  const CustomSimpleText = ({ text, separator = true }: any) => {
    return (
      <>
        {text && (
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            className={`${FONT_COLOR.COLOR_60} p-mr-1`}
          >
            {separator && '•'} {text}
          </SimpleText>
        )}
      </>
    );
  };

  return (
    <Dialog
      style={{ width: '750px' }}
      header="Paciente duplicado"
      visible={isOpen}
      onHide={() => close()}
    >
      <>
        <SimpleText className={`${FONT_COLOR.COLOR_40}`}>
          Já existe um ou mais pacientes com este{' '}
          <strong>{duplicateData}</strong>. Ajuste as seguintes informações ou
          clique no nome de um paciente já existente para utiliza-lo.
        </SimpleText>
        <div className=" p-mt-4 p-mb-2">
          <SimpleText className={`${FONT_COLOR.COLOR_16}`}>
            Lista de pacientes duplicados
          </SimpleText>
        </div>
        <div className="pacientes-semelhantes">
          {pacientes.map((paciente: any) => (
            <div
              className="card-paciente__semelhante"
              onClick={() => {
                onSelect(paciente);
                setExibirModal(false);
              }}
              key={paciente.id}
            >
              <div className="paciente-foto">
                <img
                  src={
                    paciente?.urlFoto ||
                    'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
                  }
                  alt={paciente.nome}
                />
              </div>
              <div className="paciente-infos">
                <div className="p-d-flex p-jc-between">
                  <CustomSimpleText
                    separator={false}
                    text={`Registro no. ${paciente.id}`}
                  />
                  {paciente?.tipoDadoDuplicado && (
                    <SimpleText
                      className="nome-paciente"
                      fontSize={FONT_SIZE.XXS}
                    >
                      {paciente.tipoDadoDuplicado} duplicado
                    </SimpleText>
                  )}
                </div>
                <SimpleText className="nome-paciente" fontSize={FONT_SIZE.XXS}>
                  {paciente.nome}
                </SimpleText>
                <div className="paciente-dados">
                  <CustomSimpleText separator={false} text={paciente.cpf} />
                  <CustomSimpleText
                    text={paciente.idSexo === 1 ? 'Feminino' : 'Masculino'}
                  />
                  <CustomSimpleText text={paciente.dataNascimento} />
                </div>
                <div className="paciente-dados">
                  <CustomSimpleText separator={false} text={paciente.cns} />
                  <CustomSimpleText text={paciente.passaporte} />
                  <CustomSimpleText text={paciente.nomeMae} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="btns-modal-duplicados">
          <Button
            className="p-col-12"
            label="Cancelar"
            btnType="outline"
            onClick={() => close()}
          />
        </div>
      </>
    </Dialog>
  );
};

export const PacientesSelemelhantesModal = ({
  isOpen,
  close,
  onSelect,
  setExibirModal,
  state,
}: any) => {
  const CustomSimpleText = ({ text, separator = true }: any) => {
    return (
      <>
        {text && (
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            className={`${FONT_COLOR.COLOR_60} p-mr-1`}
          >
            {separator && '•'} {text}
          </SimpleText>
        )}
      </>
    );
  };

  return (
    <Dialog
      style={{ width: '750px' }}
      header="Pacientes semelhantes"
      visible={isOpen}
      onHide={() => close()}
    >
      <>
        <SimpleText className={`${FONT_COLOR.COLOR_40}`}>
          Parece que já existe um ou mais pacientes com este{' '}
          <strong>nome</strong>. Crie um novo ou clique no nome de um paciente
          já existente para utiliza-lo.
        </SimpleText>
        <div className=" p-mt-4 p-mb-2">
          <SimpleText className={`${FONT_COLOR.COLOR_16}`}>
            Lista de pacientes semelhantes
          </SimpleText>
        </div>
        <div className="pacientes-semelhantes">
          {(state?.pacientesSemelhantes || []).map((paciente: any) => (
            <div
              className="card-paciente__semelhante"
              onClick={() => {
                onSelect(paciente);
                setExibirModal(false);
              }}
              key={paciente.id}
            >
              <div className="paciente-foto">
                <img
                  src={
                    paciente?.urlFoto ||
                    'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
                  }
                  alt={paciente.nome}
                />
              </div>
              <div className="paciente-infos">
                <div className="p-d-flex p-jc-between">
                  <CustomSimpleText
                    separator={false}
                    text={`Registro no. ${paciente.id}`}
                  />
                </div>
                <SimpleText className="nome-paciente" fontSize={FONT_SIZE.XXS}>
                  {paciente.nome}
                </SimpleText>
                <div className="paciente-dados">
                  <CustomSimpleText separator={false} text={paciente.cpf} />
                  <CustomSimpleText
                    text={paciente.idSexo === 1 ? 'Feminino' : 'Masculino'}
                  />
                  <CustomSimpleText text={paciente.dataNascimento} />
                </div>
                <div className="paciente-dados">
                  <CustomSimpleText separator={false} text={paciente.cns} />
                  <CustomSimpleText text={paciente.passaporte} />
                  <CustomSimpleText text={paciente.nomeMae} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-3">
          <Button
            btnType="ghost"
            label="Cancelar"
            type="button"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Criar um novo"
            type="submit"
            stretch
            onClick={async () => {
              await state.onProceed();
              close();
            }}
            btnType="tonal"
          />
        </div>
      </>
    </Dialog>
  );
};
