import Http, { HttpParams } from 'src/core/http/Http';

export const getMigraPEP = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/pacientes/migracao-pacientes`,
    params,
    hideToast: true,
    ...options,
  });
};

export const postMigraPEP = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `/pacientes/migracao-pacientes`,
    data: params,
    return: true,
    ...options,
  });
};

export const reverterMigraPEP = (
  idMigracao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `/pacientes/reverter-migracoes/${idMigracao}`,
    return: true,
    ...options,
  });
};

export const MigraPEPAPI = {
  postMigraPEP,
  getMigraPEP,
  reverterMigraPEP,
};
