import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isTablet } = useSize();

  if (isTablet) return null;

  return (
    <div className="p-grid p-my-1">
      <div className="p-col-3">
        <SimpleText fontSize={FONT_SIZE.XXS}>Código TUSS</SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS}>Convênio</SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS}>Profissional</SimpleText>
      </div>
      <div className="p-col-1">
        <SimpleText fontSize={FONT_SIZE.XXS}>Valor</SimpleText>
      </div>

      {!isTablet && (
        <div className="p-col-1">
          <SimpleText fontSize={FONT_SIZE.XXS}>Início vigência</SimpleText>
        </div>
      )}
      {!isTablet && (
        <div className="p-col-1">
          <SimpleText fontSize={FONT_SIZE.XXS}>Situação</SimpleText>
        </div>
      )}

      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS}>Ações</SimpleText>
      </div>
    </div>
  );
};
