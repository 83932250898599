import React, { useCallback, useEffect, useState } from 'react';

import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { PanelHeader } from './PanelHeader';

export default function HistoricoQuestionario() {
  const [quantidadeQuestionario, setQuantidadeQuestionario] =
    useState<number>(1);
  const [exibirLista, setExibirLista] = useState<boolean>(true);

  useEffect(() => {
    if (quantidadeQuestionario === 0) {
      setExibirLista(false);
    }
  }, [quantidadeQuestionario]);

  const { selectedAtendimento, reload, setItensFichaPaciente } =
    useHistoricoAtendimento();

  const fetchQuestionario = useCallback(
    async (params: any) => {
      const response =
        await HistoricoAtendimentosAPI.getQuestionarioAtendimento(
          Number(selectedAtendimento?.idAtendimento),
          {
            sortBy: 'dataAtualizacao',
            sortDirection: 'DESC',
            somenteAtendimento: true,
            ...params,
          },
        );
      setQuantidadeQuestionario(response?.totalCount);
      setItensFichaPaciente(
        (prev: number) => Number(prev) + Number(response?.totalCount),
      );
      return response;
    },
    [selectedAtendimento?.idAtendimento, setItensFichaPaciente],
  );

  const renderRowQuestionario = (questionario: any) => {
    return (
      <div className="p-my-1">
        <PanelHeader
          questionario={questionario}
          isCollapsed={false}
          onToggle={function (): void {}}
        />
      </div>
    );
  };
  return (
    <div>
      {exibirLista && (
        <SearchList.Root fetchApi={fetchQuestionario}>
          <SearchList.Header>
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              className="p-mb-2"
              medium
            >
              Questionários
            </SimpleText>
          </SearchList.Header>

          <SearchList.BorderContainer>
            <SearchList.InfiniteScroll
              renderRow={renderRowQuestionario}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      )}
    </div>
  );
}
