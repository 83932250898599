import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import DropdownCid from './DropdownCid';

type FieldSelectCidsProps = {
  name?: string;
  className?: string;
  label?: string;
};

export const FieldSelectCids = ({
  name = 'cids',
  className,
  label,
}: FieldSelectCidsProps) => {
  const { control, setValue, watch } = useFormContext();

  const watchCids = watch(name);

  const handleAddCids = useCallback(
    (cids: any) => {
      if (!cids?.length) cids = [cids];

      // Valida se já existe os cids selecionados no data do form
      const cidsFiltered = cids.filter(
        (cid: any) =>
          !watchCids.find((formCid: any) => cid.codigo === formCid.codigo),
      );

      setValue('cids', [...watchCids, ...cidsFiltered]);
    },
    [setValue, watchCids],
  );

  return (
    <div className={className || 'p-col-12 p-grid'}>
      <div style={{ minHeight: '19px' }} className="p-w-100 p-py-1">
        {label && <SimpleText bold>{label}</SimpleText>}
      </div>

      <DropdownCid
        control={control}
        name={name}
        handleAddCids={handleAddCids}
      />
    </div>
  );
};
