import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  funcionalidade: Funcionalidade;
  onConfig: () => void;
  onSwitchStatus: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { funcionalidade, onConfig, onSwitchStatus } = props;

  return (
    <CardItem>
      <SimpleText
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
        className="p-col-4 p-text-truncate p-wordbreak-normal"
      >
        {funcionalidade?.nome}
      </SimpleText>
      <SimpleText
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XS}
        className="p-col-6 p-text-truncate p-wordbreak-normal"
      >
        {funcionalidade?.descricao}
      </SimpleText>
      <div className="p-col-2">
        <div className="p-d-flex p-jc-end p-ai-center">
          <Switch
            checked={funcionalidade?.status}
            onChange={onSwitchStatus}
            checkPermission="ADM_FUNCIONALIDADE_ALTERAR"
            showStatus
          />
          <Divider layout="vertical" />
          <div className="btns">
            <Button
              btnType="gray"
              icon="pi pi-cog"
              onClick={onConfig}
              checkPermission="ADM_FUNCIONALIDADE_ALTERAR"
            />
          </div>
        </div>
      </div>
    </CardItem>
  );
};
