import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import CentroCustoAPI from 'src/APIs/AdminAPI/CentroCustoAPI/CentroCustoAPI';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import FormCentroCusto from './FormCentroCusto';

const ManterCusto = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Page className={'Manter centro de custo'}>
      <>
        <PageHeader
          title={params.id ? 'Editar centro de custo' : 'Novo centro de Custo'}
          subtitle={'Preencha os campo para completar o cadastro'}
        />
        <Page content white className="p-pt-2">
          <>
            <Button
              className="btn-voltar p-mb-2 p-ml-2"
              btnType="ghost"
              id="back-button"
              label="Voltar"
              icon="fa fa-arrow-left"
              onClick={() => navigate('/faturamento/centro-custo')}
            />
            <FormCentroCusto />
          </>
        </Page>
      </>
    </Page>
  );
};

export default ManterCusto;
