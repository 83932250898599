import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldViaProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldVia = ({ name = 'via', label, ...rest }: FieldViaProps) => {
  const { control } = useFormContext();
  const [vias, setVias] = useState<{ [key: string]: string }>({});

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(() => {
    PrescricaoAPI.getPrescricaoVias({ throwError: true })
      .then(data => setVias(data))
      .catch(() => {});
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = Object.keys(vias).map(via => ({
      label: vias[via],
      value: via,
    }));

    return data;
  }, [vias]);

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      control={control}
      name={name}
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
