import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  cardapio: Cardapio;
  onConfig: () => void;
  alertDivergencia: any;
  switchStatus: JSX.Element;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { cardapio, onConfig, alertDivergencia, switchStatus } = props;
  return (
    <>
      <CardItem>
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
          className="p-col-4  p-text-truncate p-wordbreak-normal"
          medium
        >
          {cardapio.funcionalidade.nome}
        </SimpleText>

        <SimpleText
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XS}
          className="p-col-6 p-text-truncate p-wordbreak-normal"
        >
          {cardapio.funcionalidade.descricao}
        </SimpleText>

        <div className="p-col-2">
          <div className="p-d-flex p-jc-end p-ai-center">
            {switchStatus}
            <Divider layout="vertical" />
            <div className="btns">
              <Button btnType="gray" icon="pi pi-cog" onClick={onConfig} />
            </div>
          </div>
        </div>
      </CardItem>
      {alertDivergencia}
    </>
  );
};
