import { useCallback, useEffect, useMemo, useState } from 'react';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { usePlanosPacientes } from '../../hooks/PlanosPacientesContext';

export const PlanosSearch = () => {
  const { ativo } = useAppSelector(state => state.consultorios);
  const { setSelectedPaciente, setSelectedPlano, selectedPlano } =
    usePlanosPacientes();

  const [atraso, setAtraso] = useState(false);
  const [nome, setNome] = useState('');

  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);

      const data =
        await PlanoCuidadoPacienteAPI.loadPlanoCuidadoPacienteDadosPlanos({
          idConsultorio: ativo.id,
        });

      if (!data.length) return setLoading(false);

      setItems(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [ativo.id]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  const handleInput = useThrottle(setNome, 150);

  const itemsFiltered = useMemo(() => {
    let data = items;

    if (!!atraso) data = data.filter(item => !!item.emAtraso);

    data = data.filter(item => item.nome.toLowerCase().includes(nome));

    return data;
  }, [atraso, items, nome]);

  const planosItems = atraso || !!nome.length ? itemsFiltered : items;

  const handleFilter = (item: any) => {
    setSelectedPlano(item.idPlanoCuidado), setSelectedPaciente(null);
  };

  return (
    <div
      id="plano-search"
      className="p-col-12 p-md-6 p-pl-0 p-d-flex p-flex-column"
    >
      <SimpleText className="p-col-12" medium fontSize={FONT_SIZE.SM}>
        Planos
      </SimpleText>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Planos de cuidado com pacientes ativos
      </SimpleText>

      <div className="p-col-12 p-mb-2">
        <TextInputSearch
          onChange={handleInput}
          value={nome}
          placeholder="Pesquise pelo nome do plano"
        />
      </div>

      <div className="p-w-100 p-py-2 p-d-flex p-gap-2 p-ai-center p-gap-2">
        <SelectButton
          onChange={e => setAtraso(e.value)}
          value={atraso}
          options={[
            {
              label: 'Planos com atraso',
              value: true,
            },
            {
              label: 'Todos',
              value: false,
            },
          ]}
        />
        <SimpleText
          fontColor={FONT_COLOR.COLOR_60}
          className="p-text-end p-wordbreak-normal"
        >
          Exibindo {itemsFiltered?.length || 0} registros de{' '}
          {items?.length || 0}
        </SimpleText>
      </div>

      <div className="plano-border-container p-px-2">
        <div className="p-d-flex p-col-12">
          <SimpleText
            className="p-col-8"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Nome do plano
          </SimpleText>
          <SimpleText
            className="p-col-4"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Numero de paciente
          </SimpleText>
        </div>

        <div className="content-items p-d-flex p-flex-column p-gap-2 p-pb-2">
          {isLoading ? (
            <LoadingComponent />
          ) : !!planosItems.length ? (
            planosItems.map(item => (
              <div
                onClick={() => handleFilter(item)}
                key={item.idPlanoCuidado}
                className={`item p-d-flex p-col-12 ${
                  selectedPlano === item.idPlanoCuidado ? 'selected' : ''
                }`}
              >
                <SimpleText
                  lines={1}
                  className="p-col-8 p-text-truncate"
                  medium
                >
                  {item.nome}
                </SimpleText>
                <SimpleText className="p-col-4" medium>
                  {item.numeroPacientes}
                </SimpleText>
              </div>
            ))
          ) : (
            <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-py-2">
              <SimpleText>Nenhum plano de cuidado foi encontrado.</SimpleText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  const props = {
    loading: true,
    height: '40px',
    borderRadius: '8px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};
