import { useForm, FormProvider } from 'react-hook-form';

import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';

const sexoOptions = [
  { label: 'Masculino', value: 'M' },
  { label: 'Feminino', value: 'F' },
  { label: 'Ambos', value: 'A' },
];

const tipoPerfilOptions = [
  { label: 'Secretária', value: 'SECRETARIO' },
  { label: 'Master', value: 'MASTER' },
  { label: 'Administrador', value: 'ADMINISTRADOR' },
  { label: 'Profissional da saúde', value: 'PROFISSIONAL_SAUDE' },
];

const tipoProfissionalSaude = [
  { label: 'Médico', value: 'MEDICO' },
  { label: 'Enfermeira', value: 'ENFERMEIRO' },
  { label: 'Assitente Social', value: 'ASSISTENTE_SOCIAL' },
  { label: 'Técnico de Enfermagem', value: 'TECNICO_DE_ENFERMAGEM' },
  { label: 'Farmacêutico', value: 'FARMACEUTICO' },
  { label: 'Fonoaudiólogo', value: 'FONOAUDIOLOGO' },
  { label: 'Fisioterapeuta', value: 'FISIOTERAPEUTA' },
  { label: 'Nutricionista', value: 'NUTRICIONISTA' },
  { label: 'Dentista', value: 'DENTISTA' },
  { label: 'Psicólogo', value: 'PSICOLOGO' },
];

export const Filter = () => {
  const useFormMethods = useForm();

  const { getValues, reset, control } = useFormMethods;

  const handleFilter = () => {
    const {
      sexo = [],
      tipo = [],
      tipoProfissionalSaude = [],
      status = null,
    } = getValues();

    return {
      status: status,
      sexo: sexo.toString(),
      tipo: tipo.toString(),
      tipoProfissionalSaude: tipoProfissionalSaude.toString(),
    };
  };

  const handleClearFilters = () => {
    reset();
  };

  return (
    <SearchListFilter
      onFilter={handleFilter}
      onClearFilters={handleClearFilters}
    >
      <FormProvider {...useFormMethods}>
        <form>
          <SimpleText className="p-col-12">Status</SimpleText>
          <div className="p-col-12 p-mb-3">
            <DropdownControlled
              name="status"
              control={control}
              options={[
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false },
                { label: 'Todos', value: null },
              ]}
              showClear
            />
          </div>

          <SimpleText className="p-col-12">Sexo</SimpleText>
          <div className="p-col-12 p-mb-3">
            <MultipleCheckboxControlled name="sexo" options={sexoOptions} />
          </div>

          <SimpleText className="p-col-12">Tipo de perfil</SimpleText>
          <div className="p-col-12 p-mb-3">
            <MultipleCheckboxControlled
              name="tipo"
              options={tipoPerfilOptions}
            />
          </div>

          <SimpleText className="p-col-12">Profissional da saúde</SimpleText>
          <div className="p-col-12">
            <MultipleCheckboxControlled
              name="tipoProfissionalSaude"
              options={tipoProfissionalSaude}
            />
          </div>
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};
