import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/login';

export const sendAlterarSenha = (
  payload: PostAlterarSenhaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostAlterarSenhaDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/alterarSenha`,
    data: payload,
    return: true,
    ...options,
  });
};

const AlterarSenhaAPI = {
  sendAlterarSenha,
};

export default AlterarSenhaAPI;
