import Page from 'src/components/Basics/Page/Page';

import './ConfiguracaoGuiaTiss.scss';
import PageHeader from 'src/components/PageHeader/PageHeader';

import ConfiguracaoGuiaTissGlobal from './ConfiguracaoGuiaTissGlobal/ConfiguracaoGuiaTissGlobal';

const ConfiguracaoGuiaTiss = () => {
  return (
    <Page className={'ConfiguracaoGuiaTiss'}>
      <>
        <PageHeader
          title={'Configuração TISS'}
          subtitle={
            'Gerencie os registros enviados para a ANS através do padrão “Troca de Informação de Saúde Suplementar”'
          }
        />
        <ConfiguracaoGuiaTissGlobal />
      </>
    </Page>
  );
};

export default ConfiguracaoGuiaTiss;
