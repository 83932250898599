import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  convenio: Convenio;
  onViewConvenio: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onSwitchStatus: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { convenio, onViewConvenio, onEdit, onDelete, onSwitchStatus } = props;

  const menu = useRef<any>(null);

  const itemsOptions = [
    {
      label: 'Editar',
      command: () => onEdit(),
      checkPermission: 'ADM_CONVENIO_ALTERAR',
    },
    {
      label: 'Visualizar',
      command: () => onViewConvenio(),
    },
    {
      label: 'Excluir',
      command: () => onDelete(),
      checkPermission: 'ADM_CONVENIO_EXCLUIR',
      className: 'error',
    },
  ];

  return (
    <CardItem>
      <SimpleText
        className="p-col-2 p-lg-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {convenio.id}
      </SimpleText>
      <SimpleText
        className="p-col-4 p-lg-6 p-xl-8"
        lines={2}
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {convenio.razaoSocial}
      </SimpleText>

      <div className="p-col p-d-flex p-gap-2 p-jc-end">
        <Can checkPermission="ADM_CONVENIO_ALTERAR">
          {can => (
            <Switch
              checked={convenio.ativo}
              onChange={onSwitchStatus}
              disabled={!can}
              className="p-mr-3"
              showStatus
            />
          )}
        </Can>

        <Button
          className="p-mr-1"
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={event => menu?.current?.toggle(event)}
        />

        <TieredMenu
          className="tiered-menu"
          model={itemsOptions}
          popup
          ref={menu}
        />
      </div>
    </CardItem>
  );
};
