import React, { useEffect, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';
import { useFormContext } from 'react-hook-form';

import ServicoTussAPI from 'src/APIs/FinanceiroAPI/ServicoTuss/ServicoTussAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  name: string;
  label?: string;
  className?: string;
  conveniosListAPI: any;
}

const FieldProcedimento = (props: Props) => {
  const [listaProcedimentos, setListaProcedimentos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { name, label, className, conveniosListAPI } = props;

  const { control, watch } = useFormContext();

  const watchConvenio = watch('convenios');

  const idTodosConvenios = conveniosListAPI
    .map((convenio: any) => convenio.id)
    .filter((id: any) => id !== -1);

  useEffect(() => {
    const loadServicoTuss = async () => {
      setLoading(true);
      const response = await ServicoTussAPI.loadServicoTuss({
        idsConvenio: watchConvenio.includes(-1)
          ? idTodosConvenios.toString()
          : watchConvenio.length > 0
          ? watchConvenio.toString()
          : null,
      });

      if (Array.isArray(response.list) && !!response.list.length) {
        const lista = response.list.map((item: any) => ({
          ...item,
          descricao: item?.nome + ' - ' + item?.codigo,
        }));
        setListaProcedimentos(lista);
      }
      setLoading(false);
    };
    if (watchConvenio?.length) {
      loadServicoTuss();
    }
  }, [watchConvenio]);

  if (loading) {
    return (
      <div className={className}>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {label}
        </SimpleText>
        <Skeleton className="p-mt-2" height="33px" borderRadius="8px" />
      </div>
    );
  }

  return (
    <div className="p-colidTodos2 p-md-6 p-lg-3">
      <DropdownControlled
        name="codigoProcedimento"
        label="Código do procedimento"
        filter
        options={listaProcedimentos || []}
        optionLabel="descricao"
        optionValue="id"
        disabled={!watchConvenio?.length}
        control={control}
      />
    </div>
  );
};

export default FieldProcedimento;
