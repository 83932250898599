import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';
import useSize from 'src/core/hooks/useSize';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';

import { wsdlValidationSchema } from './validationSchema';

const versaoOptions = [
  {
    label: 'v3.02.00',
    value: 'v3.02.00',
  },
  {
    label: 'v3.02.01',
    value: 'v3.02.01',
  },
  {
    label: 'v3.02.02',
    value: 'v3.02.02',
  },
  {
    label: 'v3.02.03',
    value: 'v3.02.03',
  },
  {
    label: 'v3.04.01',
    value: 'v3.04.01',
  },
  {
    label: 'v3.05.00',
    value: 'v3.05.00',
  },
  {
    label: 'v4.01.00',
    value: 'v4.01.00',
  },
];

export const FormWsdlTiss = () => {
  const { isMobile } = useSize();

  const {
    wsdlTissInfoData,
    idConvenioEdit,
    idConvenioTissEdit,
    tissInfoData,
    setIdConvenioTissEdit,
    setWsdlTissInfoData,
  } = useManterConvenio();

  const navigate = useNavigate();

  const form = useForm({
    defaultValues: wsdlTissInfoData || {},
    resolver: yupResolver(wsdlValidationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
  } = form;

  const { onCopy, hasCopied } = useCopyClipboard();

  const handleLinkCopy = (fieldName: any) => {
    const fieldValue = getValues(fieldName);

    onCopy(fieldValue);
  };

  useEffectSkipFirst(() => {
    if (hasCopied) {
      toast(<Toast />, {
        data: {
          title: 'Sucesso!',
          message: 'Link copiado com sucesso!',
          type: 'success',
        },
      });
    }
  }, [hasCopied]);

  const onSubmit = async (data: any) => {
    const payload = {
      idConvenio: idConvenioEdit,
      ...tissInfoData,
      ...data,
    };

    try {
      const saveConvenioTiss = idConvenioTissEdit
        ? ConvenioAPI.updateConvenioTiss
        : ConvenioAPI.createConvenioTiss;

      const response = await saveConvenioTiss(
        payload,
        idConvenioEdit as number,
      );

      setWsdlTissInfoData(data);

      if (idConvenioTissEdit === null && response?.id)
        setIdConvenioTissEdit(response.id);
    } catch (error) {}
  };

  return (
    <FormProvider {...form}>
      <form className="p-grid p-col-12" onSubmit={handleSubmit(onSubmit)}>
        <div className="p-w-100">
          <DropdownControlled
            className="p-col-12 p-sm-6 p-md-4 p-lg-3"
            control={control}
            name="versaoTiss"
            label="Versão TISS "
            placeholder="Selecione"
            filter={false}
            options={versaoOptions}
          />
        </div>

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlCancelaGuia"
          label="Cancela guia TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlCancelaGuia')}
          errorMsg={errors.urlCancelaGuia?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5"
          name="timeoutCancelaGuia"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
          max={300}
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlLoteGuias"
          label="Lote da guia TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlLoteGuias')}
          errorMsg={errors.urlLoteGuias?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutLoteGuia"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
          max={300}
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlSolicitacaoDemonstrativoRetorno"
          label="Solicitação demonstrativo de retorno TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() =>
            handleLinkCopy('urlSolicitacaoDemonstrativoRetorno')
          }
          errorMsg={errors.urlSolicitacaoDemonstrativoRetorno?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutSolicitacaoDemonstrativoRetorno"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlSolicitacaoProcedimento"
          label="Solicitação de procedimento TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlSolicitacaoProcedimento')}
          errorMsg={errors.urlSolicitacaoProcedimento?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutSolicitacaoProcedimento"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlSolicitacaoStatusAutorizacao"
          label="Solicitação status autorização TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlSolicitacaoStatusAutorizacao')}
          errorMsg={errors.urlSolicitacaoStatusAutorizacao?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutSolicitacaoStatusAutorizacao"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlSolicitacaoStatusProtocoloRecurso"
          label="Solicitação status protocolo recurso TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() =>
            handleLinkCopy('urlSolicitacaoStatusProtocoloRecurso')
          }
          errorMsg={errors.urlSolicitacaoStatusProtocoloRecurso?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutSolicitacaoStatusProtocoloRecurso"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlSolicitacaoStatusProtocolo"
          label="Solicitação status protocolo TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlSolicitacaoStatusProtocolo')}
          errorMsg={errors.urlSolicitacaoStatusProtocolo?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutSolicitacaoStatusProtocolo"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlVerificaElegibilidade"
          label="Verifica elegibilidade TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlVerificaElegibilidade')}
          errorMsg={errors.urlVerificaElegibilidade?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutVerificaElegibilidade"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <TextInputControlled
          className="p-xl-10 p-lg-9 p-md-8 p-col-7"
          control={control}
          name="urlRecursoGlosa"
          label="Recurso glosa TISS"
          placeholder="Informe o link"
          icon="icon-btn fa-sharp fa-solid fa-link"
          onClickIcon={() => handleLinkCopy('urlRecursoGlosa')}
          errorMsg={errors.urlRecursoGlosa?.message}
          maxLength={200}
        />

        <FormInput
          className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
          name="timeoutRecursoGlosa"
          hideTextErrorSpace
          type="number"
          label="Timeout"
          sublabel={!isMobile ? '(em segundos)' : ''}
          placeholder="Informe o tempo"
        />

        <div className="p-d-flex p-gap-1 p-col-12">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            stretch
            onClick={() => navigate('/cadastros/convenio', { replace: true })}
          />
          <Button type="submit" label="Salvar" loading={isSubmitting} stretch />
        </div>
      </form>
    </FormProvider>
  );
};
