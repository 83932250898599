import { useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

interface TipoPlanoItemProps {
  tipoPlano: {
    descricao: string;
    automatizarCarteiraAPS: boolean;
    id?: number;
  };
  updatetipoPlanoFields: (index: number, tipoPlano: any) => void;
  index: number;
  onRemove: (index: number) => void;
}

const TipoPlanoItem = ({
  tipoPlano,
  index,
  onRemove,
  updatetipoPlanoFields,
}: TipoPlanoItemProps) => {
  const { control, watch } = useFormContext();

  const { isMobile } = useSize();

  const watchTiposPlanos = watch(`tiposPlano.${index}.automatizarCarteiraAPS`);

  useEffectSkipFirst(() => {
    updatetipoPlanoFields(index, {
      ...tipoPlano,
      automatizarCarteiraAPS: watchTiposPlanos,
    });
  }, [watchTiposPlanos]);

  return (
    <>
      <div className="p-grid tipoPlano p-my-1 p-align-center">
        {isMobile ? (
          <>
            <SimpleText
              className="p-col-12 p-mb-0 p-pb-0"
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Descrição:
            </SimpleText>
            <SimpleText
              className="p-col-12"
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {tipoPlano.descricao}
            </SimpleText>
          </>
        ) : (
          <SimpleText
            className="p-col-12 p-sm-6"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {tipoPlano.descricao}
          </SimpleText>
        )}

        <div className="p-col-3">
          <SwitchControlled
            name={`tiposPlano.${index}.automatizarCarteiraAPS`}
            control={control}
            checked={tipoPlano.automatizarCarteiraAPS}
            showStatus
          />
        </div>

        <Button
          className="p-col-2 p-my-2 p-ml-auto p-mr-2"
          btnType="gray"
          icon="pi pi-trash"
          onClick={() => onRemove(index)}
          type="button"
        />
      </div>
    </>
  );
};

export default TipoPlanoItem;
