import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type IProps = DropdownProps & {
  name?: string;
  label: string;
  returnType?: 'all' | 'id';
};

export const FieldProfissao = ({
  name = 'profissao',
  label,
  returnType = 'all',
  ...rest
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [parentesco, setParentesco] = useState<any[]>([]);

  const loadAPI = useCallback(async () => {
    const response = await ProfissionalAPI.retrieveBuscaCbo();

    if (!!response.length) setParentesco(response);
  }, []);

  useEffect(() => {
    loadAPI();
  }, [loadAPI]);

  const options = useMemo(() => {
    const options = parentesco.map(item => ({
      label: item.descricao,
      value: returnType === 'all' ? item : item.id,
    }));

    return options;
  }, [parentesco, returnType]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={options}
      errorMsg={errors[name]?.message}
      {...rest}
    />
  );
};
