import { Control, Controller } from 'react-hook-form';

import { Checkbox, CheckboxProps } from 'src/components/_UI/Checkbox';

export interface CheckboxControlledProps extends CheckboxProps {
  control: Control<any>;
  name: string;
  onChangeCustom?: (checked: boolean) => void;
}

const CheckboxControlled = ({
  name,
  control,
  onChangeCustom,
  ...rest
}: CheckboxControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          {...rest}
          checked={value}
          onChange={e => [
            onChange(e.checked),
            onChangeCustom && onChangeCustom(!value),
          ]}
        />
      )}
    />
  );
};

export default CheckboxControlled;
