import { Button } from 'src/components/_UI/Button';

import './LembreteButton.scss';

interface LembreteButtonProps {
  isLembreteActive: boolean;
  disabled: boolean;
  handleLembreteChange: () => void;
  isUpdating: boolean;
}

export const LembreteButton = (props: LembreteButtonProps) => {
  const { handleLembreteChange, disabled, isUpdating, isLembreteActive } =
    props;

  const lembreteIcon = isLembreteActive ? 'fas fa-bell' : 'fas fa-bell-slash';

  return (
    <Button
      className="historico-lembrete-btn"
      type="button"
      btnType="gray"
      icon={lembreteIcon}
      onClick={handleLembreteChange}
      disabled={disabled}
      loading={isUpdating}
    />
  );
};
