import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import FormHospital from './FormHospital/FormHospital';

import './Hospital.scss';

const Hospital = () => {
  const { theme } = useContext(ThemeContext);

  const params = useParams();

  return (
    <Page className={`Hospital ${theme}`} content>
      <>
        <PageHeader
          title={params.id ? 'Editar hospital' : 'Novo hospital'}
          subtitle={'Preencha os campos para completar o cadastro'}
        />

        <Page className={'HospitalForm'} content white>
          <div className={'content-container p-xl-4 p-lg-6 p-md-8 p-col-12'}>
            <FormHospital />
          </div>
        </Page>
      </>
    </Page>
  );
};

export default Hospital;
