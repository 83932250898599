import { useState, useEffect, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import Button from 'src/components/Basics/Button/Buttons';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import ChipSelectControlled from 'src/components/ChipSelect/ChipSelectControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { useManterPaciente } from '../../MaterPacienteContext';

const horariosTrabalho = [
  { value: 'MANHA', label: 'Manhã' },
  { value: 'TARDE', label: 'Tarde' },
  { value: 'NOITE', label: 'Noite' },
  { value: 'MADRUGADA', label: 'Madrugada' },
];

const DadosProfissionais = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const { handleSetFormDirty } = useManterPaciente();

  const { idPaciente } = useParams();

  const {
    formState: { isDirty, isSubmitting },
    control,
    reset,
    setValue,
    handleSubmit,
  } = useForm();

  useEffectSkipFirst(() => {
    handleSetFormDirty(isDirty, 'DADOS_OPCIONAIS');
  }, [isDirty]);

  const [loading, setLoading] = useState<boolean>(true);
  const [cboOptions, setCboOptions] = useState<any>([]);

  const loadDadosProfissionais = useCallback(async () => {
    if (!idPaciente) return;

    setLoading(true);

    const response = await PacienteAPI.getPacienteDadosProfissionais(
      Number(idPaciente),
    );

    setValue('estudante', response?.estudante || false);
    setValue('pacienteSemOcupacao', response?.pacienteSemOcupacao || false);
    setValue(
      'idFormacaoProfissional',
      response?.idFormacaoProfissional || null,
    );
    setValue('nomeEmpresa', response?.nomeEmpresa || '');
    setValue('cnpj', response?.cnpj || null);
    setValue('numeroRegistro', response?.numeroRegistro || '');
    setValue('vinculoEmpregaticio', response?.vinculoEmpregaticio || '');
    setValue('idOcupacao', response?.idOcupacao || null);
    setValue('horarioTrabalho', response?.horarioTrabalho || null);

    setLoading(false);
  }, [idPaciente, setValue]);

  useEffect(() => {
    loadDadosProfissionais();
  }, [loadDadosProfissionais]);

  const loadCbo = async () => {
    const response = await ProfissionalAPI.retrieveBuscaCbo();

    if (Array.isArray(response)) {
      const cboFormatted = response.map(cbo => ({
        label: cbo.descricao,
        value: cbo.id,
      }));

      setCboOptions(cboFormatted);
    }
  };

  useEffect(() => {
    loadCbo();
  }, []);

  useEffect(() => {
    reset({
      estudante: false,
      pacienteSemOcupacao: false,
    });
  }, [reset]);

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      idEmpresa: user?.idEmpresa,
    };

    await PacienteAPI.updatePacienteDadosProfissionais(
      Number(idPaciente),
      payload,
    );

    reset(data, { keepValues: true });
  };

  if (loading)
    return (
      <div className="p-grid">
        <div className="p-col-12 p-lg-6 p-xl-5">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <form
      className="p-grid p-col-12 p-lg-6 p-xl-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="p-col-12">
        <CheckboxControlled
          className="checkbox-small"
          control={control}
          name="estudante"
          label="Paciente estudante"
        />
      </div>
      <div className="p-col-12">
        <CheckboxControlled
          className="checkbox-small"
          control={control}
          name="pacienteSemOcupacao"
          label="Paciente sem ocupação"
        />
      </div>

      <DropdownControlled
        className="p-col-12"
        control={control}
        name="idFormacaoProfissional"
        label="Formação profissional"
        placeholder="Pesquisar"
        options={cboOptions}
      />

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="nomeEmpresa"
        label="Nome da empresa"
      />

      <MaskedInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="cnpj"
        label="CNPJ"
        mask={MASK.CNPJ}
      />
      <TextInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="numeroRegistro"
        label="Num. registro funcional"
      />

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="vinculoEmpregaticio"
        label="Vínculo empregatício"
      />

      <DropdownControlled
        className="p-col-12"
        control={control}
        name="idOcupacao"
        label="Ocupação"
        placeholder="Pesquisar"
        options={cboOptions}
      />

      <SimpleText
        className="p-col-12 p-mt-2"
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Horário de trabalho
      </SimpleText>

      <ChipSelectControlled
        control={control}
        name="horarioTrabalho"
        options={horariosTrabalho}
        multipleSelect={false}
      />

      <div className="p-col-12 p-mt-5">
        <Button
          type="submit"
          label="Salvar alterações"
          loading={isSubmitting}
        />
      </div>
    </form>
  );
};

export default DadosProfissionais;
