import { Control, Controller } from 'react-hook-form';

import ChipSelect, { ChipSelectProps } from './ChipSelect';

export interface ChipSelectControlledProps extends ChipSelectProps {
  control: Control;
  name: string;
}

const ChipSelectControlled = ({
  name,
  control,
  ...rest
}: ChipSelectControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }: any) => (
        <ChipSelect
          {...rest}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export default ChipSelectControlled;
