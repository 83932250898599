import { createContext, useContext, useEffect, useState } from 'react';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';
import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

type ContextValues = {
  especialidades: GetEspecialidadeDTO[];
  consultorios: GetConsultorioDTO[];
  convenios: GetConvenioDTO[];
};

const FormularioDinamicoContext = createContext({} as ContextValues);

export const FormularioDinamicoProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [especialidades, setEspecialidades] = useState<GetEspecialidadeDTO[]>(
    [],
  );
  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);
  const [convenios, setConvenios] = useState<GetConvenioDTO[]>([]);

  const fetchEspecialidades = () => {
    EspecialidadeAPI.loadEspecialidades(
      {
        pageSize: 999,
      },
      { throwError: true },
    )
      .then(response => {
        setEspecialidades(response.list);
      })
      .catch(() => {
        setEspecialidades([]);
      });
  };

  const fetchConsultorios = () => {
    ConsultorioAPI.loadConsultorios(
      {
        pageSize: 999,
      },
      { throwError: true },
    )
      .then(response => {
        setConsultorios(response.list);
      })
      .catch(() => {
        setConsultorios([]);
      });
  };

  const fetchConvenios = () => {
    ConvenioAPI.loadConveniosAtivosSemPaginacao({}, { throwError: true })
      .then(response => {
        setConvenios(response);
      })
      .catch(() => {
        setConvenios([]);
      });
  };

  useEffect(() => {
    fetchEspecialidades();
    fetchConsultorios();
    fetchConvenios();
  }, []);

  return (
    <FormularioDinamicoContext.Provider
      value={{
        especialidades,
        consultorios,
        convenios,
      }}
    >
      {children}
    </FormularioDinamicoContext.Provider>
  );
};

export const useFormularioDinamicoAplicacaoFormulario = () => {
  const context = useContext(FormularioDinamicoContext);

  if (!context) {
    throw new Error(
      'useFormularioDinamico must be used within an FormularioDinamicoProvider',
    );
  }

  return context;
};
