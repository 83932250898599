import { useRef, useState } from 'react';

import { useNavigate } from 'react-router';

import CentroCustoAPI from 'src/APIs/AdminAPI/CentroCustoAPI/CentroCustoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { checkAuth } from 'src/utils/utils';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

interface RowProps {
  data: any;
  reloadList: () => void;
}

const Row = ({ data, reloadList }: RowProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [ativo, setAtivo] = useState(data.status);
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const menuRef = useRef<any>(null);
  const { isTablet } = useSize();

  const centroCusto = data;

  const onSwitchStatus = async () => {
    const payload = {
      ...centroCusto,
      status: !ativo,
    };

    setLoadingStatus(true);
    const response = await CentroCustoAPI.putCentroCusto(
      centroCusto.id,
      payload,
    );

    if (response?.status === 200) {
      setAtivo(response.data.status);
      data.status = response.data.status;
    }

    setLoadingStatus(false);
  };

  const onEdit = async () => {
    navigate(`/faturamento/centro-custo/${data.id}`);
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      const response = await CentroCustoAPI.deleteCentroCusto(data.id);

      if (response?.status >= 200 && response?.status < 300) {
        reloadList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const menuItens = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_MANTER_CENTRO_CUSTO_ALTERAR',
    },

    {
      label: 'Excluir',
      command: onDelete,
      className: 'error',
      checkPermission: 'ADM_MANTER_CENTRO_CUSTO_EXCLUIR',
    },
  ];

  const items = menuItens.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  const commonProps = {
    centroCusto,
    loadingStatus,
    onSwitchStatus,
    menuRef,
    ativo,
    items,
  };

  return (
    <div className="p-grid search-list-card-item">
      {loading ? (
        <div>
          <SpinnerLoading size="sm"></SpinnerLoading>
        </div>
      ) : isTablet ? (
        <MobileRow {...commonProps} />
      ) : (
        <DesktopRow {...commonProps} />
      )}
    </div>
  );
};

export default Row;
