import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  impresso: any;
}

export default function PanelContent({ impresso }: PanelContentProps) {
  function getPContent(str: string) {
    const parser = new DOMParser();
    const html = parser.parseFromString(str, 'text/html');
    const pTag = html.querySelector('p');
    return pTag?.textContent || str;
  }

  return (
    <div className="panel-content p-d-flex p-flex-column p-p-2">
      <SimpleText
        fontSize={FONT_SIZE.XXXS}
        fontColor={FONT_COLOR.COLOR_60}
        className="p-mb-1"
      >
        Descrição:
      </SimpleText>
      <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
        {impresso?.descricao
          ? getPContent(impresso?.descricao)
          : 'Não informado'}
      </SimpleText>
    </div>
  );
}
