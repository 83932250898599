import BroomIcon from 'src/assets/icons/broom-icon.svg';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { usePlanosPacientes } from '../../hooks/PlanosPacientesContext';

import { PacientesSearch } from './PacientesSearch';
import { PlanosSearch } from './PlanosSearch';

const PanelFiltros = () => {
  const { quantidadeFiltros, limparFiltros, reloadList, selectedPaciente } =
    usePlanosPacientes();

  const filtroText = (quantidade: number) => {
    return `(${quantidade}) Filtro${quantidade !== 1 ? 's' : ''} aplicado${
      quantidade !== 1 ? 's' : ''
    }`;
  };

  return (
    <Panel
      defaultExpanded
      header={(isCollapsed, ontoggle) => (
        <div className="p-d-flex p-jc-between p-ai-center p-py-1 p-px-3">
          <SimpleText fontSize={FONT_SIZE.SM} medium>
            Filtros de seleção
          </SimpleText>
          <div className="p-d-flex p-ai-center p-gap-2">
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {filtroText(quantidadeFiltros)}
            </SimpleText>

            <Button
              label="Limpar filtros"
              type="button"
              btnType="green-link"
              onClick={() => {
                limparFiltros();
              }}
              icon={<img src={BroomIcon} alt="Broom Icon" />}
            />
            <Button
              icon={`fas fa-chevron-${isCollapsed ? 'up' : 'down'}`}
              type="button"
              btnType="gray"
              onClick={ontoggle}
            />
          </div>
        </div>
      )}
    >
      <div className="p-grid p-w-100 p-pt-0">
        <PlanosSearch />
        <PacientesSearch />
        <div className="p-col-12">
          <Button
            label="Aplicar filtros"
            type="button"
            btnType="tonal"
            onClick={() => reloadList()}
            stretch
            disabled={!selectedPaciente}
          />
        </div>
      </div>
    </Panel>
  );
};

export default PanelFiltros;
