import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router';

import ComunicadosAPI from 'src/APIs/AdminAPI/ComunicadosAPI/ComunicadosAPI';
import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';
import EmedLogo from 'src/assets/imgs/emed_logo_text.svg';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import FormMessage from './FormsComunicado/FormMessage/FormMessage';
import FormRecipient from './FormsComunicado/FormRecipient/FormRecipient';
import { enviarImagemDoComunicado } from './helpers';

import './Comunicado.scss';

export const ComunicadosPaciente = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const { theme } = useContext(ThemeContext);
  const { id } = useParams();

  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const navigate = useNavigate();
  const comunicado = useLocation().state;
  const [logoEmpresa, setLogoEmpresa] = useState<string>();

  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const [step1, setStep1] = useState<any>({
    titulo: comunicado?.titulo,
    mensagem: comunicado?.mensagem,
    dataInicio: comunicado?.dataInicio,
    dataFim: comunicado?.dataFim,
    linkExterno: comunicado?.linkExterno,
    textoLink: comunicado?.textoLink,
    flagLinkImagem: comunicado?.flagLinkImagem,
  });
  const [step2, setStep2] = useState<any>({
    pacientes: comunicado?.comunicadoDestinatarios
      ?.filter((com: any) => !!com.paciente)
      .map((com: any) => com.paciente),
  });

  const [loading, setLoading] = useState(false);

  const showPreview = useCallback(async () => {
    setPreviewVisible(true);
  }, []);

  useEffect(() => {
    if (!user.idEmpresa) return;
    (async () => {
      try {
        const empresa = await EmpresasAPI.loadEmpresaById(user.idEmpresa, {
          return: false,
          throwError: true,
        });
        setLogoEmpresa(empresa?.logoUrl);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user.idEmpresa]);

  const submitForm = async () => {
    setLoading(true);
    const idsConvenios = step2.convenios.map((conv: any) => conv.id);
    const hasPacientes = step2.pacientes.some(Boolean);
    const comunicadoDestinatarios = step2.pacientes.map((pac: any) => ({
      idUsuarioLoginPaciente: pac.usuarioLogin?.id,
      tipoUsuario: 'PACIENTE',
    }));

    const params = {
      idsConvenio: !hasPacientes ? idsConvenios.toString().trim() : undefined,
      enviarParaTodosPacientes: !hasPacientes || step2.todosPacientes,
    };

    if (id) {
      return ComunicadosAPI.updateComunicadosPortalPaciente(
        id,
        params,
        {
          ...step1,
          ativo: true,
          comunicadoDestinatarios: hasPacientes
            ? comunicadoDestinatarios
            : undefined,
        },
        {
          throwError: true,
        },
      )
        .then(() => navigate(`/cadastros/comunicadosPacientes`))
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }

    const imagem = !!step1.imagem
      ? await enviarImagemDoComunicado(step1.imagem)
      : null;

    return ComunicadosAPI.sendComunicadosPortalPaciente(
      params,
      {
        ...step1,
        imagem,
        ativo: true,
        comunicadoDestinatarios: hasPacientes
          ? comunicadoDestinatarios
          : undefined,
      },
      {
        throwError: true,
      },
    )
      .then(() => navigate(`/cadastros/comunicadosPacientes`))
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const steps = useMemo(
    (): TimelineValue[] => [
      {
        title: 'Mensagem',
        component: (
          <FormMessage
            values={{ ...step1 }}
            onSubmit={(values: any) => {
              setStep1(values);
              if (
                timelineRef &&
                timelineRef.current &&
                timelineRef.current.next
              ) {
                timelineRef.current?.next();
              }
            }}
          />
        ),
      },
      {
        title: 'Destinatário',
        disabled: !step1,
        component: (
          <FormRecipient
            values={{ ...step1, ...step2 }}
            onSubmit={(values: any) => {
              setStep2(values);
              showPreview();
            }}
          />
        ),
      },
    ],
    [step1, step2, showPreview],
  );

  const renderPreview = useCallback((): JSX.Element => {
    const qtdeConvenios = step2?.convenios?.length;
    const quantidade =
      step2?.pacientes?.length && !step2?.todosPacientes
        ? `${step2.pacientes.length} paciente(s)`
        : `${qtdeConvenios} convênios`;

    const values = {
      ...step1,
      ...step2,
      imagem: step1?.imagem && URL.createObjectURL(step1.imagem),
    };

    return (
      <>
        <div>
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
            Envie o comunicado caso os dados estejam corretos.
          </SimpleText>
        </div>

        <div className={'preview-container'}>
          <div className={'preview-notification p-grid'}>
            {values?.imagem && (
              <div className={'p-col-12 p-d-flex p-jc-center'}>
                <div>
                  <img
                    alt={'Imagem da notificação'}
                    src={values?.imagem}
                    onClick={() =>
                      values?.flagLinkImagem
                        ? () => {
                            window.open(values?.linkExterno);
                          }
                        : () => {}
                    }
                    style={{
                      width: '100%',
                      objectFit: 'contain',
                      cursor: values.flagLinkImagem ? 'pointer' : 'default',
                    }}
                  />
                </div>
              </div>
            )}

            <div className={'p-col-3 p-d-flex p-jc-center p-ai-center'}>
              <img
                style={{ maxWidth: '100%', maxHeight: '160px' }}
                alt={'Logo'}
                src={logoEmpresa || EmedLogo}
              />
            </div>

            <div className={'p-col-9'}>
              <div className={'p-mb-2'}>
                <SimpleText
                  className={'system-notification-time-ago-text'}
                  fontColor={FONT_COLOR.COLOR_79}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Há XX minutos
                </SimpleText>
              </div>

              <div>
                <SimpleText
                  className={'p-text-bold'}
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  {values?.titulo}
                </SimpleText>
              </div>

              <div>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  {values?.mensagem}
                </SimpleText>
              </div>

              {!values.flagLinkImagem &&
                values?.linkExterno &&
                values?.textoLink && (
                  <div
                    className={'p-mt-3'}
                    onClick={() => {
                      window.open(values.linkExterno);
                    }}
                  >
                    <SimpleText
                      className={'dialog-text-link'}
                      fontSize={FONT_SIZE.XXS}
                    >
                      {values.textoLink}
                    </SimpleText>
                  </div>
                )}
            </div>
          </div>
        </div>
        <AlertBox visible={true} text={quantidade} />
      </>
    );
  }, [logoEmpresa, step1, step2]);

  return (
    <Page className={`CadastroComunicado ${theme}`}>
      <>
        <PageHeader
          title={id ? 'Editar comunicado' : 'Novo comunicado'}
          subtitle={
            id
              ? 'Edite os campos desejados'
              : 'Envie uma mensagem para os pacientes'
          }
        />

        <Page white content>
          <div className={'p-xl-7 p-lg-9 p-md-11 p-col-12'}>
            <Timeline values={steps} ref={timelineRef} />

            <Dialog
              header="Pré-visualizar comunicado"
              visible={previewVisible}
              className={'Preview-comunicado-paciente'}
              onHide={() => setPreviewVisible(false)}
              maximizedMobileSize
            >
              <>
                {renderPreview()}
                <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
                  <Button
                    btnType="ghost"
                    label="Cancelar"
                    type="button"
                    onClick={() => setPreviewVisible(false)}
                    stretch
                  />
                  <Button
                    label="Enviar"
                    onClick={submitForm}
                    stretch
                    loading={loading}
                  />
                </div>
              </>
            </Dialog>
          </div>
        </Page>
      </>
    </Page>
  );
};
