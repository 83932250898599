import { utcToShow } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import MobileRow from 'src/components/SearchList/MobileRow';

import './Compromissos.scss';

interface Props {
  data: any;
  tipos_compormissos: any;
  handleEdit: (data: any) => void;
  handleDuplicate: (data: any) => void;
  handleDelete: (data: any) => void;
}

const MobileRowCompromissos = ({
  data,
  tipos_compormissos,
  handleDelete,
  handleDuplicate,
  handleEdit,
}: Props) => {
  const options = (
    <>
      <div className="p-grid">
        <>
          <Can checkPermission="AGE_COMPROMISSO_ALTERAR">
            <div className="p-col-12">
              <Button
                btnType="ghost"
                label="Editar"
                onClick={() => handleEdit(data)}
                stretch
              />
            </div>
          </Can>
          <Can checkPermission="AGE_COMPROMISSO_CADASTRAR">
            <div className="p-col-12">
              <Button
                btnType="ghost"
                label="Duplicar"
                onClick={() => handleDuplicate(data)}
                stretch
              />
            </div>
          </Can>
          <Can checkPermission="AGE_COMPROMISSO_EXCLUIR">
            <div className="p-col-12">
              <Button
                btnType="ghost"
                label="Excluir"
                onClick={() => handleDelete(data)}
                stretch
              />
            </div>
          </Can>
        </>
      </div>
    </>
  );

  return (
    <MobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Tipo
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {tipos_compormissos[data.tipoCompromisso]}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Compromisso
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data.descricao}</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Data inicial
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {utcToShow(new Date(data.dataInicio))}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Data final
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {utcToShow(new Date(data.dataFim))}
        </SimpleText>
      </div>
    </MobileRow>
  );
};

export default MobileRowCompromissos;
