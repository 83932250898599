import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  gestacao: any;
  index: number | string;
}

export default function PanelContent({ gestacao, index }: PanelContentProps) {
  function convertTrimestre(inputString: any) {
    const parts = inputString?.split('_');

    const trimestreNumber = parseInt(parts[1]);

    const outputString = `${trimestreNumber}º Trimestre`;

    return outputString;
  }

  const classText = (risco: any) => {
    if (risco === 'HABITUAL') {
      return 'risco-habitual risco';
    } else if (risco === 'ALTO') {
      return 'risco-alto risco';
    }
    return '';
  };

  const formatValueViewRisco = (risco: any) => {
    if (risco === 'HABITUAL') {
      return 'Risco habitual';
    } else if (risco === 'ALTO') {
      return 'Risco alto';
    }
    return '';
  };

  function converterParaDias() {
    // Extrair o número de semanas e dias da string de entrada
    const partes = gestacao.idadeGestacional.split(' ');
    const semanas = parseInt(partes[0]);
    const dias = parseInt(partes[3]);

    // Calcular o número total de dias
    const totalDias = semanas * 7 + dias;

    // Retornar o número total de dias
    if (isNaN(totalDias)) {
      return 'Erro ao calcular';
    }
    return totalDias;
  }

  return (
    <div className="p-grid panel-content">
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Tipo Gestaçao
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.tipoGestacao
            ? capitalizeFirstLetter(gestacao?.tipoGestacao).replaceAll('_', ' ')
            : '-'}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Pré-natal
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.trimestre ? convertTrimestre(gestacao?.trimestre) : '-'}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Gravidez
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.tipoGravidez
            ? capitalizeFirstLetter(gestacao?.tipoGravidez)
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          CID
        </SimpleText>
        <div className="p-d-flex p-jc-between">
          {gestacao?.cid?.map((cid: any, index: any) => (
            <SimpleText
              key={index}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              className="comorbidades-ativas"
            >
              {cid?.codigo}
            </SimpleText>
          ))}
        </div>
      </div>
      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <div
          className={classText(gestacao?.risco) + ' p-jc-center p-ai-center'}
        >
          <SimpleText
            className=" text-color p-text-center p-p-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {formatValueViewRisco(gestacao?.risco)}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Ciclo encerrado
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.dataEncerramentoCiclo
            ? dayjs(new Date(gestacao?.dataEncerramentoCiclo)).format(
                'DD/MM/YYYY HH:mm',
              )
            : '-'}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Dias de gestação
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao.idadeGestacional ? converterParaDias() : '-'}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Nº de consultas
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          X
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Tipo de parto
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.tipoPartoHistorico
            ? capitalizeFirstLetter(gestacao?.tipoPartoHistorico)
            : '-'}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data DUM
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.dum
            ? dayjs(new Date(gestacao?.dum)).format('DD/MM/YYYY')
            : '-'}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data DPP
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.dpp
            ? dayjs(new Date(gestacao?.dpp)).format('DD/MM/YYYY')
            : '-'}
        </SimpleText>
      </div>

      <div className="p-col-6 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Peso ao nascer
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.fetos[index]?.pesoAoNascer}
          {gestacao?.fetos[
            index
          ]?.pesoNascimentoUnidadeMedida?.codigo?.toLowerCase() ?? '-'}
        </SimpleText>
      </div>
    </div>
  );
}
