import { useState, useCallback, useContext } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import AlterarSenhaRecuperacaoAPI from 'src/APIs/SecurityAPI/AlterarSenhaRecuperacaoAPI/AlterarSenhaRecuperacaoAPI';
import * as Yup from 'yup';

import ThemeContext from 'src/core/themes/ThemeContext';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import PasswordInput from 'src/components/Basics/PasswordInput/PasswordInput';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import LoginLayout from 'src/components/LoginLayout/LoginLayout';

const formFields = {
  novaSenha: '',
  confirmacaoSenha: '',
};

import './RedefinirSenha.scss';

export const RedefinirSenha = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { theme } = useContext(ThemeContext);

  const [supportVisible, setSupportVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    novaSenha: Yup.string()
      .min(8, 'Mínimo 8 Dígitos')
      .required('Digite a nova senha')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'A senha deve conter 1 maiúscula, 1 número e 1 caractere especial (como !, @, #, etc.)',
      ),
    confirmacaoSenha: Yup.string()
      .min(8, 'Mínimo 8 Dígitos')
      .oneOf([Yup.ref('novaSenha'), null], 'Digite a mesma senha')
      .required('As senhas não coincidem'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleRedefinirSenha = useCallback(
    async (credentials: any) => {
      const data = {
        idUsuario: state.idUsuario,
        codigoValidacao: state.codigoValidacao,
        email: state.email,
        novaSenha: credentials.novaSenha,
      };

      try {
        await AlterarSenhaRecuperacaoAPI.sendAlterarSenhaRecuperacao(
          data as PostAlterarSenhaRecuperacaoDTO,
          {
            throwError: true,
          },
        );

        navigate('/login');
      } catch {}
    },
    [state, navigate],
  );

  const renderNewPasswordForm = () => {
    return (
      <div className={`RedefinePassword ${theme}`}>
        <div className={'redefineForm'}>
          <SimpleText fontSize={FONT_SIZE.LG} className="p-mb-2">
            Redefinir senha
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
            Crie uma nova senha para acessar o e-Med.
          </SimpleText>
          <div className={'formAligner'}>
            <form
              className="modal-body-password-reset"
              onSubmit={handleSubmit(handleRedefinirSenha)}
              defaultValue={''}
              onReset={() => reset(formFields)}
            >
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }: any) => (
                  <PasswordInput
                    label="Nova senha"
                    className="p-mb-2"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    toggleMask
                    errorMsg={errors.novaSenha?.message}
                  />
                )}
                name={'novaSenha'}
              />

              <SimpleText
                className="p-pl-2 p-mb-1"
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                A nova senha precisa conter, pelo menos:
              </SimpleText>
              <ul className="p-pl-4 p-mb-4">
                <li className="p-pl-1 danger-text">8 Dígitos</li>
                <li className="p-pl-1 danger-text">
                  1 caractere especial (como !, @, #, etc.)
                </li>
                <li className="p-pl-1 danger-text">1 letra maíuscula</li>
                <li className="p-pl-1 danger-text">1 número</li>
              </ul>

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }: any) => (
                  <PasswordInput
                    label="Confirme nova senha"
                    className="p-mb-2"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    toggleMask
                    errorMsg={errors.confirmacaoSenha?.message}
                  />
                )}
                name={'confirmacaoSenha'}
              />
              <Button
                type="submit"
                btnType={BtnTypes.FILLED}
                label={'Recuperar acesso'}
                style={{ marginTop: 48 }}
                loading={isSubmitting}
              />
            </form>

            <Button
              onClick={() => {
                setSupportVisible(true);
              }}
              btnType={BtnTypes.LINK}
              className="textLink support p-p-0"
            >
              Solicitar Suporte
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <LoginLayout
      content={renderNewPasswordForm()}
      supportVisible={supportVisible}
      onHideSupport={() => {
        setSupportVisible(false);
      }}
      maxWidth={700}
    />
  );
};
