import React, { useEffect, useState } from 'react';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { aleitamentosTypes } from './utils';

import { EmptyData } from '../../components/EmptyData';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export function HistoricoResumo() {
  const { selectedAtendimento } = useHistoricoAtendimento();

  const [exibirResumo, setExibirResumo] = useState(true);

  useEffect(() => {
    if (
      !selectedAtendimento?.anamnese &&
      !selectedAtendimento?.exameFisico &&
      !selectedAtendimento?.patologiaDetectada &&
      !selectedAtendimento?.prognostico &&
      !selectedAtendimento?.decisaoTerapeutica
    ) {
      setExibirResumo(false);
    }
  }, [selectedAtendimento]);

  const headerTextInavito =
    selectedAtendimento?.status === 'INATIVO'
      ? 'p-mb-1 atendimento-inativo'
      : '';
  const dataTextInativo = `${
    selectedAtendimento?.status === 'INATIVO'
      ? 'line-through atendimento-inativo'
      : ''
  }`;

  // * Aleitamento
  const { aleitamento, alimentacaoVariada, aleitamentoObservacao } =
    selectedAtendimento;

  const hasAllAleitamentos = !!(aleitamento && alimentacaoVariada);
  const isAleitamento = !!(
    aleitamento ||
    alimentacaoVariada ||
    aleitamentoObservacao
  );

  return (
    <div className="historico-resumo p-d-flex p-flex-column p-p-3">
      {exibirResumo ? (
        <>
          {selectedAtendimento?.anamnese && (
            <div className="p-d-flex p-flex-column p-my-2 p-gap-1">
              <SimpleText
                className={headerTextInavito}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Anamnese
              </SimpleText>
              <SimpleText
                className={dataTextInativo}
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                {selectedAtendimento?.anamnese}
              </SimpleText>
            </div>
          )}
          {isAleitamento && (
            <div className="p-d-flex p-flex-column p-my-2 p-gap-1">
              <SimpleText
                className={headerTextInavito}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {alimentacaoVariada
                  ? 'Aleitamento e alimentação'
                  : 'Aleitamento'}
              </SimpleText>
              <SimpleText
                className={dataTextInativo}
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                {aleitamento ? aleitamentosTypes[aleitamento] : ''}
                {hasAllAleitamentos ? ' e ' : aleitamento ? ' ' : ''}
                {alimentacaoVariada ? 'Alimentação variada. ' : ''}
                {selectedAtendimento?.aleitamentoObservacao || ''}
              </SimpleText>
            </div>
          )}
          {selectedAtendimento?.exameFisico && (
            <div className="p-d-flex p-flex-column p-my-2 p-gap-1">
              <SimpleText
                className={headerTextInavito}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Exame físico
              </SimpleText>
              <SimpleText
                className={dataTextInativo}
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                {selectedAtendimento?.exameFisico}
              </SimpleText>
            </div>
          )}
          {selectedAtendimento?.patologiaDetectada && (
            <div className="p-d-flex p-flex-column p-my-2 p-gap-1">
              <SimpleText
                className={headerTextInavito}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Patologia detectada
              </SimpleText>
              <SimpleText
                className={dataTextInativo}
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                {selectedAtendimento?.patologiaDetectada}
              </SimpleText>
            </div>
          )}
          {selectedAtendimento?.prognostico && (
            <div className="p-d-flex p-flex-column p-my-2 p-gap-1">
              <SimpleText
                className={headerTextInavito}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Prognóstico
              </SimpleText>
              <SimpleText
                className={dataTextInativo}
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                {selectedAtendimento?.prognostico}
              </SimpleText>
            </div>
          )}
          {selectedAtendimento?.decisaoTerapeutica && (
            <div className="p-d-flex p-flex-column p-my-2 p-gap-1">
              <SimpleText
                className={headerTextInavito}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Decisão terapêutica
              </SimpleText>
              <SimpleText
                className={dataTextInativo}
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                {selectedAtendimento?.decisaoTerapeutica}
              </SimpleText>
            </div>
          )}
        </>
      ) : (
        <EmptyData />
      )}
    </div>
  );
}
