import { useContext } from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Breadcrumbs from 'src/components/Breadcrumbs/Breadcrumbs';

import { Button } from '../_UI/Button';
import './PageHeader.scss';

export interface PageHeaderProps {
  title: string;
  subtitle: string;
  clean?: boolean;
  children?: JSX.Element | JSX.Element[];
  hasBreadcrumbs?: boolean;
  backButton?: boolean;
  onClickBackButton?: any;
  style?: any;
  secondaryAction?: boolean;
  before?: any;
  preventBreadcrumbClick?: boolean;
}

const PageHeader = ({
  title,
  subtitle,
  clean,
  children,
  hasBreadcrumbs = true,
  before,
  backButton,
  onClickBackButton,
  secondaryAction,
  preventBreadcrumbClick = false,
  ...props
}: PageHeaderProps): JSX.Element => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`PageHeader ${theme || ''} ${clean ? 'clean-mode' : ''}`}
      {...props}
    >
      {backButton && (
        <Button
          btnType="ghost"
          label="Voltar"
          icon="fa fa-arrow-left"
          className={'back-button p-mb-1'}
          onClick={onClickBackButton}
        />
      )}

      {hasBreadcrumbs && (
        <Breadcrumbs preventBreadcrumbClick={preventBreadcrumbClick} />
      )}

      <div className="p-d-flex p-ai-center">
        {!!before && before}
        <div className="p-d-flex p-flex-column ">
          <SimpleText
            fontSize={FONT_SIZE.MD}
            medium
            className={'p-mt-2 p-mb-2'}
          >
            {title}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XS} className={'subtitle p-mb-2'}>
            {subtitle}
          </SimpleText>
        </div>

        {secondaryAction && <div className="p-mx-4">{children}</div>}
      </div>

      {!secondaryAction && children}
    </div>
  );
};

export default PageHeader;
