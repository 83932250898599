import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldPlanoStatusProps = DropdownProps & {
  name?: string;
  label: string;
};

const options = [
  {
    label: 'Sucesso',
    value: 'SUCESSO',
  },
  {
    label: 'Glosa',
    value: 'GLOSA',
  },
  {
    label: 'Timeout',
    value: 'TIMEOUT',
  },
  {
    label: 'Erro de ambiente / Erro desconhecido',
    value: 'ERRO_AMBIENTE_ERRO_DESCONHECIDO',
  },
  {
    label: 'Erro no servidor',
    value: 'ERRO_SERVIDOR',
  },
];

export const FieldStatusServico = ({
  name = 'statusChamada',
  label,
  ...rest
}: FieldPlanoStatusProps) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      placeholder="Selecione"
      hideTextErrorSpace
      showClear
      {...rest}
    />
  );
};
