import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  questionario: any;
  onVisualizarQuestionario: () => void;
  optionsMenu: (questionario: Questionarios) => JSX.Element;
  formatStatus: (status: string) => string;
  headerTextClassName?: string;
}

const DesktopRow = ({
  questionario,
  optionsMenu,
  onVisualizarQuestionario,
  formatStatus,
  headerTextClassName,
}: DesktopRowProps) => {
  return (
    <div
      className={`p-grid p-col-12 p-align-center continer-panel row-questionario p-my-2 ${
        questionario.status === 'INATIVO' ? 'inativo' : ''
      }`}
    >
      <div
        className="p-col-5 p-d-flex cursor-pointer"
        onClick={() => onVisualizarQuestionario()}
      >
        <i className="fas fa-file-medical-alt cursor-pointer" />

        <div className="p-d-flex p-flex-column p-ml-2">
          <SimpleText
            medium
            className="cursor-pointer"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.PRIMARY}
          >
            Questionário de saúde
          </SimpleText>

          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            Preenchido por{' '}
            {questionario?.usuario?.nome
              ? `Preenchido por ${questionario?.usuario?.nome}`
              : 'Preenchido pelo(a) paciente'}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-2 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data de ocorrência
        </SimpleText>

        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {dayjs(questionario.dataOcorrencia).format('DD/MM/YYYY  HH:mm')}
        </SimpleText>
      </div>

      <div className="p-col-2 retroativo-chip">
        {questionario.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>

      <div className="p-col-2 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Status
        </SimpleText>

        <SimpleText
          className={`${headerTextClassName} ${formatStatus(
            questionario.validarOuRejeitar,
          )}`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {formatStatus(questionario.validarOuRejeitar)}
        </SimpleText>
      </div>

      <div className="p-col-1 p-d-flex p-jc-end action">
        {optionsMenu(questionario)}
      </div>
    </div>
  );
};

export default DesktopRow;
