import { toast } from 'react-toastify';

import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';

import { HttpParams } from 'src/core/http/Http';
import { buildUrl } from 'src/core/http/HttpUtils';

import { blobToJson } from 'src/utils/utils';

import Toast from 'src/components/Basics/Toast/Toast';

import { store } from '../redux/store';

export type AxiosActionTypes = 'get' | 'post' | 'put' | 'delete';
export interface QueryParamAsJSON {
  [label: string]: string | number | undefined;
}

export interface RequestURL {
  url: string;
  vars?: QueryParamAsJSON;
}

export interface RequestParams extends Omit<AxiosRequestConfig, 'url'> {
  url: string | RequestURL;
  axiosInstance: AxiosInstance;
  data?: any;
  params?: any;
  return?: boolean;
  hideToast?: boolean;
  errorHideToast?: boolean;
  successMessage?: string;
  requestDescription?: string;
  throwError?: boolean;
}

const arrayBufferToJson = (arrayBuffer: any) => {
  const decoder = new TextDecoder('utf-8');
  const jsonText = decoder.decode(arrayBuffer);
  const jsonData = JSON.parse(jsonText);
  return jsonData;
};

const decodeErrorDescription = (encodedDescription: any) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = encodedDescription;
  return textarea.value;
};

const getErrorMessage = (response: any, requestDescription = '') => {
  const isClientError = response?.status >= 400 && response?.status < 500;
  // const isSessionExpired = response?.status === 401;
  const isForbidden = response?.status === 403;
  const encodedErrorDescription = response?.data?.error_description;
  const errorMessage = response?.data?.errorMessage || response?.errorMessage;

  if (!isClientError)
    return 'Ocorreu um erro inesperado, por favor, entre em contato com o suporte.';

  // if (isSessionExpired) return 'Sessão expirada.';

  if (isForbidden && requestDescription)
    return `Usuário sem permissão para acessar ${requestDescription}`;

  if (isForbidden) return 'Usuário sem acesso a essa funcionalidade.';

  if (encodedErrorDescription) {
    return decodeErrorDescription(encodedErrorDescription);
  }

  return errorMessage;
};

const handleError = async (error: any, requestDescription = '') => {
  const response = error.response;

  if (
    response?.data?.error_description ===
    'Full authentication is required to access this resource'
  )
    return;

  if (response?.request?.responseType === 'arraybuffer') {
    response.data = arrayBufferToJson(response.data);
  }

  if (response?.data instanceof Blob) {
    response.data = await blobToJson(response.data);
  }

  const message = getErrorMessage(response, requestDescription);

  const data = { message, type: 'error' };
  toast(<Toast />, { data });
};

const handleSuccess = (e: any, message = 'Ação concluida com sucesso.') => {
  const data = { message, type: 'success' };
  toast(<Toast />, { data });
};

const request = async (method: AxiosActionTypes, params: RequestParams) => {
  const user = store?.getState()?.user;
  const currentDate: Date = new Date();

  try {
    const urlAux = buildUrl(params.url);
    const response: AxiosResponse = await params.axiosInstance.request({
      ...params,
      method: method,
      url: urlAux,
      data: params.data,
      params: params.params,
      ...(params?.onUploadProgress && {
        onUploadProgress: params.onUploadProgress,
      }),
    });

    if (!params.hideToast) {
      handleSuccess(response, params.successMessage);
    }
    if (response && params?.return) return response;
    if (response) return response.data;
  } catch (e: any) {
    console.log(e);

    if (user?.access_token) {
      const decodedToken: { exp: number } = jwt_decode(user?.access_token);

      const isInvalid: boolean =
        decodedToken.exp * 1000 < currentDate.getTime() ? true : false;

      if (!params.errorHideToast && !isInvalid) {
        handleError(e, params?.requestDescription);
      }
    } else if (!params.errorHideToast) {
      handleError(e, params?.requestDescription);
    }

    if (params?.throwError) {
      throw new Error(e.response);
    }

    return e.response;
  }
};

const getAction = (params: RequestParams) => request('get', params);
const postAction = (params: RequestParams) => request('post', params);
const putAction = (params: RequestParams) => request('put', params);
const deleteAction = (params: RequestParams) => request('delete', params);

export interface RefreshCache {
  method: AxiosActionTypes;
  params: HttpParams;
}
export interface HttpCoreInterface {
  get(params: RequestParams): any;
  post(params: RequestParams): any;
  put(params: RequestParams): any;
  delete(params: RequestParams): any;
}

const HttpCore: HttpCoreInterface = {
  get: getAction,
  post: postAction,
  put: putAction,
  delete: deleteAction,
};

export default HttpCore;
