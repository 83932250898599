import { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import AGAAPI from 'src/APIs/ProntuarioAPI/AGAAPI/AGAAPI';
import * as Yup from 'yup';

import { useAGA } from 'src/core/hooks/AGA/useAGA';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { generateUniqueId, scrollAllDivsToTop } from 'src/utils/utils';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import Separator from 'src/components/Separator/Separator';
import Tab from 'src/components/Tab/Tab';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { DialogOrientacoes } from '../DialogOrientacoes/DialogOrientacoes';
import FooterFormulario from '../FooterFormulario/FooterFormulario';
import IndiceVulnerabilidadeBar from '../IndiceVulnerabilidadeBar/IndiceVulnerabilidadeBar';
import StepList from '../StepList/StepList';

import PerguntasFormulario from './PerguntasFormulario';

interface FormularioAGAProps {
  idFormularioPai: number;
  idPaciente: number | null;
  idadePaciente: number | null;
}

type ActiveTabData = {
  [key: string]: any;
};

const FormularioAGA = ({
  idFormularioPai,
  idPaciente,
  idadePaciente,
}: FormularioAGAProps) => {
  const {
    groupedForms,
    currentStep,
    setMaxTabs,
    nextCurrentStep,
    tabAtiva,
    setTabAtiva,
    questionariosList,
    formularioEdit,
    isEdit,
    ultimoStep,
    diagnosticoFuncionalDisclosure,
    setIsEdit,
  } = useAGA();

  const [currentTab, setCurrentTab] = useState(tabAtiva);
  const [pontuacaoRisco, setPontuacaoRisco] = useState<number>(0);
  const [hasAlerted, setHasAlerted] = useState<boolean>(false);
  const [showJustificativaMap, setShowJustificativaMap] = useState<any>({});

  const orientacaoDialog = useDisclosure({ opened: false });

  const { isMobile } = useSize();

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const fieldNames = groupedForms[idFormularioPai]?.lista[
    currentTab
  ]?.perguntas?.map((pergunta: any) => `pergunta${pergunta.id}`);

  const validation = Yup.object().shape(
    fieldNames?.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = Yup.mixed()
        .typeError('Resposta obrigatória')
        .required('Resposta obrigatória');
      return acc;
    }, {}),
  );

  const methods = useForm({
    resolver: yupResolver(validation),
  });

  const { control, handleSubmit, watch, setValue, reset } = methods;

  const watchValues: Record<string, any> = useMemo(() => {
    const values: Record<string, any> = {};
    questionariosList?.map((questionario: any) => {
      questionario.perguntas?.forEach((pergunta: any) => {
        const fieldName = `pergunta${pergunta.id}`;
        const value = watch(fieldName);
        const newValue: any = {
          idAgaPerguntaFormulario: pergunta.id,
          naoAplicavel: showJustificativaMap[pergunta.id],
        };

        if (
          pergunta.tipoPergunta === 'CAMPO_NUMERICO' &&
          !showJustificativaMap[pergunta.id]
        ) {
          newValue.textoResposta = value;
        } else if (!showJustificativaMap[pergunta.id]) {
          newValue.idAgaRespostaPerguntaFormulario = value;
        } else {
          newValue.justificativaNaoAplicacao = value;
        }

        values[fieldName] = newValue;
      });
    });
    return values;
  }, [questionariosList, showJustificativaMap, watch]);

  useEffect(() => {
    if (formularioEdit) {
      formularioEdit.respostas.forEach((resposta: any) => {
        const {
          idAgaPerguntaFormulario,
          idAgaRespostaPerguntaFormulario,
          textoResposta,
        } = resposta;

        if (textoResposta) {
          setValue(`pergunta${idAgaPerguntaFormulario}`, textoResposta);
          return;
        }

        setValue(
          `pergunta${idAgaPerguntaFormulario}`,
          idAgaRespostaPerguntaFormulario,
        );
      });
    }
  }, [formularioEdit, setValue, watchValues]);

  const tabs = useMemo(
    () =>
      questionariosList?.map((questionario: any) => ({
        label: questionario.nome,
        customHeader: true,
        iconAction: questionario?.textoAjuda
          ? () =>
              orientacaoDialog.open({
                state: {
                  text: questionario?.textoAjuda,
                },
              })
          : undefined,
        content: (
          <PerguntasFormulario
            idPaciente={idPaciente}
            idadePaciente={idadePaciente}
            questionario={questionario}
            setPontuacaoRisco={setPontuacaoRisco}
            showJustificativaMap={showJustificativaMap}
            setShowJustificativaMap={setShowJustificativaMap}
            watchValues={watchValues}
          />
        ),
      })),
    [
      idPaciente,
      idadePaciente,
      orientacaoDialog,
      questionariosList,
      showJustificativaMap,
      watchValues,
    ],
  );

  useEffect(() => {
    setCurrentTab(0);
  }, [currentStep]);

  useEffect(() => {
    setMaxTabs(tabs?.length - 1);
  }, [tabs, setMaxTabs]);

  useEffect(() => {
    if (currentStep !== 0) return;
    if (pontuacaoRisco >= 7 && !hasAlerted) {
      toast(<Toast />, {
        data: {
          title: 'Atenção',
          message:
            'O paciente encontra-se elegível para seguir com o preenchimento do questionário!',
          type: 'warning',
        },
      });
      setHasAlerted(true);
    } else if (pontuacaoRisco < 7 && hasAlerted) {
      setHasAlerted(false);
    }
  }, [currentStep, hasAlerted, pontuacaoRisco]);

  const onSubmit = async (data: any, questionario: any) => {
    const { dataOcorrencia, justificativaOcorrenciaRetroativa } = data;
    const activeTabData: ActiveTabData = {};
    fieldNames?.forEach((fieldName: string) => {
      activeTabData[fieldName] = watchValues[fieldName];
    });

    const payload = {
      dataOcorrencia: justificativaOcorrenciaRetroativa
        ? dataOcorrencia
        : new Date(),
      idAtendimento,
      idAgaFormulario: questionario.id,
      justificativaOcorrenciaRetroativa,
      respostas: Object.values(activeTabData),
      ...(formularioEdit && { idFormularioEdit: formularioEdit.id }),
    };

    try {
      const response = isEdit
        ? await AGAAPI.putFormulario(payload)
        : await AGAAPI.postFormulario(payload);

      if (ultimoStep) {
        diagnosticoFuncionalDisclosure.open();
        setIsEdit(null);
      }
      reset();
      setCurrentTab(old => old + 1);
      nextCurrentStep();
      scrollAllDivsToTop();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={e =>
          handleSubmit(data => onSubmit(data, questionariosList[currentTab]))(e)
        }
        id={generateUniqueId()}
      >
        <DataOcorrencia control={control} />
        <div className="border-questionario">
          <StepList />
          <Tab
            id="TabAGA"
            className={`p-mb-2 p-mt-3 p-px-3 p-py-1 ${isMobile && 'mobile'}`}
            values={tabs}
            setActiveIndex={currentTab}
            onChange={e => [
              setTabAtiva(e),
              setCurrentTab(e),
              scrollAllDivsToTop(),
            ]}
          />
        </div>

        {currentStep === 0 && (
          <div className="border-questionario p-my-2">
            <SimpleText fontSize={FONT_SIZE.MD} medium>
              Índice de vulnerabilidade
            </SimpleText>
            <IndiceVulnerabilidadeBar value={pontuacaoRisco} />
          </div>
        )}

        <Separator />

        <FooterFormulario />

        {orientacaoDialog.isOpen && <DialogOrientacoes {...orientacaoDialog} />}
      </form>
    </FormProvider>
  );
};

export default FormularioAGA;
