import { useFormContext } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

interface Props {
  idConsultorio: number | string;
  label?: string;
  name?: string;
  className?: string;
  profissionaisSaudeList: any[];
}

export const FieldSelectProfissionalSaudeByConsultorio = ({
  idConsultorio,
  label = 'Profissional de saúde',
  name = 'profissional',
  className,
  profissionaisSaudeList,
}: Props) => {
  const { control } = useFormContext();

  return (
    <DropdownControlled
      control={control}
      name={name}
      label={label}
      options={profissionaisSaudeList.map(profissional => ({
        label: profissional.nome,
        value: profissional.id,
      }))}
      className={className || 'p-mb-2'}
      disabled={!idConsultorio}
    />
  );
};
