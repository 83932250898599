import React from 'react';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const AnamneseRowContent = ({ atendimento }: any) => {
  const headerTextInativo =
    atendimento.status === 'INATIVO' ? 'line-through atendimento-inativo' : '';
  const dataTextInativo = `p-mt-2 ${
    atendimento.status === 'INATIVO' ? 'line-through atendimento-inativo' : ''
  }`;

  const nivelSigilo: number | null = atendimento.atendimento.nivelSigilo;

  if (nivelSigilo !== null && nivelSigilo !== 2) {
    return <React.Fragment />;
  }

  return (
    <div className="p-d-flex p-flex-column p-p-2 p-my-1">
      <SimpleText
        className={headerTextInativo}
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
      >
        Anamnese
      </SimpleText>
      <SimpleText
        className={dataTextInativo}
        fontColor={FONT_COLOR.COLOR_40}
        fontSize={FONT_SIZE.XS}
      >
        {atendimento.anamnese ? atendimento.anamnese : 'Sem anamnese'}
      </SimpleText>
    </div>
  );
};
