import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';
interface PanelContentProps {
  pedidoCuidado: PedidoCuidado;
  onAtender: (isEdit?: boolean) => void;
  onExcluirAtendimento: () => void;
}

function PanelContent({
  pedidoCuidado,
  onAtender,
  onExcluirAtendimento,
}: PanelContentProps) {
  const menuEllipsis = useRef<TieredMenu>(null);

  const { atendimentoStatus } = useAtendimento();

  const { user } = useAppSelector((state: RootState) => state);

  const headerTextClassName =
    pedidoCuidado.status === 'INATIVO' ? 'pedido-cuidado-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    pedidoCuidado.status === 'INATIVO'
      ? 'line-through pedido-cuidado-inativo'
      : ''
  }`;
  const profissionalExecucaoNome = pedidoCuidado?.programado
    ? pedidoCuidado.profissionalSaudeExecucao.nome
    : pedidoCuidado.profissionalSaudeSolicitacao.nome;

  const menuEllipsisItens = [
    {
      label: 'Editar',
      command: () => onAtender(true),
    },
    { separator: true },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: onExcluirAtendimento,
    },
  ];

  return (
    <div>
      {pedidoCuidado.dataExecucao ? (
        <div className="p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Atendido por:
          </SimpleText>
          <div className="p-d-flex p-align-center pedido-panel-content p-my-2">
            <div className="p-as-end p-mb-2">
              <Avatar label={profissionalExecucaoNome} />
            </div>
            <div className="p-grid p-align-center card-atendido bg-content p-ml-2">
              <div className="coluna-nome p-col-5 p-sm-5 p-md-5 p-lg-5 p-xl-5 p-d-flex">
                <div className="p-d-flex p-flex-column">
                  <SimpleText
                    className={headerTextClassName}
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                  >
                    {profissionalExecucaoNome}
                  </SimpleText>
                  {pedidoCuidado.profissionalSaudeExecucao?.especialidade && (
                    <SimpleText
                      className={dataTextClassName}
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_60}
                    >
                      (
                      <span>
                        {pedidoCuidado.profissionalSaudeExecucao.codigoConselho}
                      </span>
                      <span className="p-mx-1">
                        {pedidoCuidado.profissionalSaudeExecucao.numeroRegistro}
                      </span>
                      <span>
                        {pedidoCuidado.profissionalSaudeExecucao.ufConselho}
                      </span>
                      <span className="p-mx-1">•</span>
                      <span>
                        {pedidoCuidado.profissionalSaudeExecucao.especialidade}
                      </span>
                      )
                    </SimpleText>
                  )}
                </div>
              </div>
              <div className="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-ai-center p-jc-center">
                <SimpleText
                  className={headerTextClassName}
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  Data do registro
                </SimpleText>
                <br />
                <SimpleText
                  className={dataTextClassName}
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                  medium
                >
                  {pedidoCuidado.dataOcorrencia &&
                    dayjs(pedidoCuidado.dataOcorrencia).format(
                      'DD/MM/YYYY HH:mm',
                    )}
                </SimpleText>
              </div>
              {pedidoCuidado?.justificativaOcorrenciaRetroativaExecucao ? (
                <div className="p-d-flex p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 retroativo-chip p-ai-center p-jc-start">
                  <SimpleText fontSize={FONT_SIZE.XXXS} medium>
                    Retroativo
                  </SimpleText>
                </div>
              ) : (
                <div className="p-d-flex p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 novo-chip p-ai-center p-jc-start"></div>
              )}

              <div className="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 p-d-flex p-jc-between p-ai-center">
                <>
                  <Button
                    icon="fas fa-ellipsis-h"
                    btnType="gray"
                    onClick={event => menuEllipsis?.current?.toggle(event)}
                    disabled={
                      atendimentoStatus !== 'ATENDENDO' ||
                      user.idUsuario !== pedidoCuidado.idProfSaudeExec
                    }
                  />

                  <TieredMenu
                    className="panel-options-ficha-paciente"
                    ref={menuEllipsis}
                    model={menuEllipsisItens}
                    popup
                  />
                </>
              </div>

              <div className="p-col-12 p-d-flex p-flex-column p-my-1">
                <SimpleText
                  className={headerTextClassName}
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  Descrição
                </SimpleText>
                <SimpleText
                  className={dataTextClassName}
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  {pedidoCuidado.observacaoExecutante}
                </SimpleText>
              </div>
              {pedidoCuidado?.justificativaOcorrenciaRetroativaExecucao && (
                <>
                  <div className="borda-pedidos" />
                  <div className="p-col-12 p-d-flex p-flex-column p-my-1">
                    <SimpleText
                      className={headerTextClassName}
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_60}
                    >
                      Justificativa para registro retroativo
                    </SimpleText>
                    <SimpleText
                      className={dataTextClassName}
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_60}
                    >
                      {pedidoCuidado?.justificativaOcorrenciaRetroativaExecucao}
                    </SimpleText>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {user.idUsuario === pedidoCuidado.idProfSaudeExec ||
          !pedidoCuidado.programado ? (
            <div className="p-d-flex p-align-center pedido-panel-content p-my-2">
              <Avatar label={profissionalExecucaoNome} />
              <Button
                className="p-mx-2"
                label="Atender"
                onClick={() => onAtender()}
                disabled={
                  pedidoCuidado.status === 'INATIVO' ||
                  (atendimentoStatus !== 'ATENDENDO' &&
                    atendimentoStatus !== 'ATENDENDO_TRIAGEM')
                }
                stretch
              />
            </div>
          ) : (
            <div className="p-d-flex p-align-center pedido-panel-content p-my-2">
              <Avatar />
              <div className="p-d-flex p-flex-column p-ml-2">
                <SimpleText
                  className={headerTextClassName}
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  Atribuido para:
                </SimpleText>
                <SimpleText
                  className={dataTextClassName}
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {pedidoCuidado.profissionalSaudeExecucao?.nome}
                </SimpleText>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PanelContent;
