import React, { useCallback, useEffect, useState } from 'react';

import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import ModalVisualizarDocumento from './ModalVisualizarDocumento';
import { PanelContent } from './PanelContent';
import { PanelHeader } from './PanelHeader';

export default function HistoricoDocumentos() {
  const [quantidadeDocumentos, setQuantidadeDocumentos] = useState<number>(1);
  const [exibirLista, setExibirLista] = useState<boolean>(true);
  const [selectedDocumento, setSelectedDocumento] = useState<any>(null);

  const { selectedAtendimento, setItensFichaPaciente } =
    useHistoricoAtendimento();

  useEffect(() => {
    if (quantidadeDocumentos === 0) {
      setExibirLista(false);
    }
  }, [quantidadeDocumentos]);

  const { reload } = useHistoricoAtendimento();

  const visualizarDocumentoDisclosure = useDisclosure({ opened: false });

  const fetchDocumentos = useCallback(
    async (params: any) => {
      const response = await HistoricoAtendimentosAPI.getDocumentosAtendimento(
        Number(selectedAtendimento?.idAtendimento),
        {
          sortBy: 'dataAtualizacao',
          sortDirection: 'DESC',
          somenteAtendimento: true,
          ...params,
        },
      );
      setQuantidadeDocumentos(response?.totalCount);
      setItensFichaPaciente(
        (prev: number) => Number(prev) + Number(response?.totalCount),
      );

      return response;
    },
    [selectedAtendimento?.idAtendimento, setItensFichaPaciente],
  );

  const renderRowDocumento = (documento: any) => {
    return (
      <div className="p-my-2">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHeader
              documento={documento}
              isCollapsed={isCollapsed}
              onToggle={onToggle}
            />
          )}
        >
          <PanelContent documento={documento} />
        </Panel>
      </div>
    );
  };

  return (
    <div>
      {exibirLista && (
        <SearchList.Root fetchApi={fetchDocumentos}>
          <SearchList.Header>
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              className="p-mb-2"
              medium
            >
              Documentos
            </SimpleText>
          </SearchList.Header>

          <SearchList.BorderContainer>
            <SearchList.InfiniteScroll
              renderRow={renderRowDocumento}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      )}
      <ModalVisualizarDocumento
        documento={selectedDocumento}
        {...visualizarDocumentoDisclosure}
      />
    </div>
  );
}
