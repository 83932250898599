import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  consultorio: any;
  onEdit: (consultorio: any) => void;
  onDelete: (consultorio: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { consultorio, onEdit, onDelete } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(consultorio),
      checkPermission: 'ADM_CONSULTORIO_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(consultorio),
      checkPermission: 'ADM_CONSULTORIO_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {consultorio?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {consultorio?.cnes}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Colaboradores
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {consultorio?.contributors}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
