import { Control } from 'react-hook-form';

import dayjs from 'dayjs';

import { enumToText } from 'src/utils/questionario';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';


import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface SaudeProps {
  control: Control;
  values?: Questionarios[] | undefined;
}

const PerfilEvolucao = ({ control, values }: SaudeProps) => {
  return (
    <Template title="Perfil saúde" control={control} readOnly>
      <div className="container-PerfilEvolucao">
        <CardQuestions
          title="Como anda sua satisfação com a sua saúde ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.satisfacaoSaude)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions title="Quem tem diabetes ?" control={control} readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.diabetes)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem pressão alta (hipertensão arterial) ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.hipertensao)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem colesterol ou triglicérides altos ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.colesterolOuTriglicerides)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças cerebrovasculares (ex: AVC, Derrame ou Aneurisma) ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.doencasCerebrovasculares)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças cardiovasculares (ex: Infarto ou IAM, Insuficiência cardíaca, Angina ou Arritmia) ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.doencasCardiovasculares)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças respiratórias (ex: Doença obstrutiva pulmonar crônica ou Asma) ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.doencasRespiratorias)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem insuficiência renal crônica ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.insuficienciaRenal)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Quem tem doenças neurológicas (ex: Depressão, Ansiedade, Demência, Alzheimer ou Parkinson) ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.doencasNeurologicas)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Como anda sua alimentação ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-start p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  className="p-mb-2"
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                {value.avaliacaoEnfermagemAlimentacao.naoSeAplica && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Não se aplica
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemAlimentacao.equilibrada && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Equilibrada
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemAlimentacao
                  .excessoCarboidratosAcucares && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Excesso Carboidratos Acucares
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemAlimentacao.excessoGorduras && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Excesso de gorduras
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemAlimentacao.excessoSal && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Excesso de sal
                  </SimpleText>
                )}
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Qual(is) cirurgia(s) você já realizou ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-start p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  className="p-mb-2"
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                {value.avaliacaoEnfermagemCirurgia.naoSeAplica && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Não se aplica
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemCirurgia.cancer && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Câncer
                  </SimpleText>
                )}
                {value.avaliacaoEnfermagemCirurgia.cardiaca && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Cardiaca
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemCirurgia.bariatrica && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Bariatrica
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemCirurgia.amputacaoPernaPe && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Amputação de membros
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemCirurgia.naoRealizou && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Não realizou
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemCirurgia.outra && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Outras
                  </SimpleText>
                )}
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Existe cirurgia de coluna programada ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.cirurgiaColunaProgramada)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Já fez ou faz uso de drogas lícitas ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.usuarioDrogas)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="O calendário vacinal está em dia ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.calendarioVacinal)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Tomou vacina contra gripe no ultimo ano ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.tomouVacinaGripe)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions title="Você está gestante ?" control={control} readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.gestante)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Realizou densitometria óssea ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-start p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  className="p-mb-2"
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                {value.avaliacaoEnfermagemDensitometriaOssea.naoSeAplica && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Não se aplica
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemDensitometriaOssea.coloFemural && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Colo Femural
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemDensitometriaOssea.colunaLombar && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Coluna Lombar
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemDensitometriaOssea.femurTotal && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Fêmur total
                  </SimpleText>
                )}

                {value.avaliacaoEnfermagemDensitometriaOssea.naoRealizou && (
                  <SimpleText
                    className="p-mt-2"
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}>
                    Não realizou
                  </SimpleText>
                )}
              </div>
            ))}
          </div>
        </CardQuestions>
      </div>
    </Template>
  );
};

export default PerfilEvolucao;
