import { formatarNumeros } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onSwitchStatus: (item: any) => void;
}

export const DesktopRow = ({
  data,
  onEdit,
  onDelete,
  onSwitchStatus,
}: DesktopRowProps) => {
  return (
    <SearchList.CardItem>
      <div className="p-col-1">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {formatarNumeros(data?.id)}
        </SimpleText>
      </div>
      <div className="p-col-8">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {data?.descricao}
        </SimpleText>
      </div>

      <div className="p-col-2 p-px-0">
        <Switch
          checkPermission="ADM_CONFIGURACAO_TISS_ALTERAR"
          className="p-as-center"
          checked={data?.status}
          onChange={() => onSwitchStatus(data)}
        />
      </div>

      <div className="p-col-1 p-d-flex p-px-0">
        <Button
          checkPermission="ADM_CONFIGURACAO_TISS_ALTERAR"
          className="p-mr-3"
          btnType="gray"
          icon="pi pi-pencil"
          onClick={() => onEdit(data)}
        />

        <Button
          checkPermission="ADM_CONFIGURACAO_TISS_EXCLUIR"
          btnType="gray"
          icon="pi pi-trash"
          onClick={() => onDelete(data)}
        />
      </div>
    </SearchList.CardItem>
  );
};
