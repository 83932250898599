import { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  resetInvalidateQuery,
  setInvalidateQuery,
} from 'src/core/redux/slices/query/QuerySlice';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import ModalNovaComorbidade from './ModalNovaComorbidade/ModalNovaComorbidade';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './Comorbidades.scss';

interface ComorbidadesProps {
  idPaciente: number | null;
  changeTabIndex: (target: string) => void;
}

function Comorbidades({ idPaciente, changeTabIndex }: ComorbidadesProps) {
  const { query } = useAppSelector(state => state);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getInfoLembretes, atendimentoStatus, getListaProblemas } =
    useAtendimento();
  const dispatch = useAppDispatch();

  const [modalNovaComordidadeVisible, setModalNovaComorbidadeVisible] =
    useState<boolean>(false);
  const [comorbidadeInativacao, setComorbidadeInativacao] =
    useState<Comorbidade | null>(null);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<boolean>(false);
  const [idComorbidadeExcluir, setIdComorbidadeExcluir] = useState<
    number | null
  >(null);
  const [editComorbidade, setEditComorbidade] = useState<Comorbidade | null>(
    null,
  );
  const [updateList, setUpdateList] = useState<number>(0);

  const { invalidateComorbidades } = query;

  const reloadList = useCallback(() => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));
    getListaProblemas();
  }, [getInfoLembretes, getListaProblemas, idPaciente]);

  const fetchAPI = useCallback(
    async (params): Promise<any> => {
      return await ComorbidadeAPI.getComorbidades(Number(idAtendimento), {
        ...params,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
      });
    },
    [idAtendimento],
  );

  useEffect(() => {
    if (invalidateComorbidades) {
      reloadList();
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, fetchAPI, invalidateComorbidades, reloadList]);

  const onAlterarLembrete = async (idComorbidade: number) => {
    const response = await ComorbidadeAPI.alterarComorbidadeLembrete(
      idComorbidade,
    );

    if (response?.status === 204) reloadList();
  };

  const onAlterarSituacao = async (
    comorbidade: Comorbidade,
    situacao: string,
  ) => {
    const response = await ComorbidadeAPI.alterarSituacaoComorbidade(
      comorbidade.id,
      Number(idAtendimento),
      situacao,
    );

    if (response?.status === 204) reloadList();
  };

  const onAlterarDiagnostico = async (
    comorbidade: Comorbidade,
    diagnostico: any,
    type: string,
  ) => {
    if (!comorbidade?.cid?.[0]) return;
    if (type === 'nivelDiagnostico') {
      comorbidade.nivelDiagnostico = diagnostico;
      try {
        await ComorbidadeAPI.alterarComorbidade(comorbidade.id, comorbidade, {
          throwError: true,
        });
        reloadList();
      } catch (error) {
        console.error(error);
      }
    } else if (comorbidade.cid) {
      comorbidade.estadoDiagnostico = diagnostico;
      try {
        await ComorbidadeAPI.alterarComorbidade(comorbidade.id, comorbidade, {
          throwError: true,
        });
        reloadList();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onEdit = (comorbidade: Comorbidade) => {
    setEditComorbidade(comorbidade);
    setModalNovaComorbidadeVisible(true);
  };

  const onVisualizarJustificativa = (comorbidade: Comorbidade) => {
    setComorbidadeInativacao(comorbidade);
    setVisualizarJustificativa(true);
  };

  const optionsMenu = (comorbidade: Comorbidade) => (
    <PanelOptionsMenu
      ativo={comorbidade.status === 'ATIVO'}
      lembreteAtivo={comorbidade.lembrete}
      idAtendimentoRegistro={comorbidade.idAtendimento}
      idUsuarioCriacao={comorbidade.usuarioAtualizacao.id}
      onAlterarLembrete={() => onAlterarLembrete(comorbidade.id)}
      onEdit={() => onEdit(comorbidade)}
      onInativar={() => setComorbidadeInativacao(comorbidade)}
      onExcluir={() => setIdComorbidadeExcluir(comorbidade.id)}
      onVisualizarJustificativa={() => onVisualizarJustificativa(comorbidade)}
    />
  );

  const renderRow = (problema: Comorbidade) => (
    <div className="p-mt-2">
      <Panel
        defaultExpanded={problema.existePlanoCuidadoCidCiap}
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            problema={problema}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            onAlterarSituacao={(situacao: string) =>
              onAlterarSituacao(problema, situacao)
            }
            onAlterarDiagnostico={(diagnostico: string, type: string) =>
              onAlterarDiagnostico(problema, diagnostico, type)
            }
            optionsMenu={optionsMenu(problema)}
          />
        )}
      >
        <PanelContent problema={problema} changeTabIndex={changeTabIndex} />
      </Panel>
    </div>
  );

  const onExcluir = async () => {
    const response = await ComorbidadeAPI.removerComorbidade(
      idComorbidadeExcluir as number,
      Number(idAtendimento),
    );

    if (response?.status === 204) {
      reloadList();
      dispatch(
        setInvalidateQuery({ invalidateCIDs: true, invalidateCIAPs: true }),
      );
      setIdComorbidadeExcluir(null);
    }
  };

  const onHideModalComorbidade = () => {
    setEditComorbidade(null);
    setModalNovaComorbidadeVisible(false);
  };

  return (
    <div id="comorbidades-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Problemas
            </SimpleText>

            <Button
              className="p-mr-4"
              type="button"
              btnType="tonal"
              label="Problemas"
              icon="fas fa-plus"
              onClick={() => setModalNovaComorbidadeVisible(true)}
              disabled={
                atendimentoStatus !== 'ATENDENDO' &&
                atendimentoStatus !== 'ATENDENDO_TRIAGEM'
              }
            />
          </div>

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalNovaComorbidade
        visible={modalNovaComordidadeVisible}
        editComorbidade={editComorbidade}
        onHide={onHideModalComorbidade}
        onSuccess={reloadList}
        changeTabIndex={changeTabIndex}
      />

      <ModalJustificativa
        comorbidade={comorbidadeInativacao}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setComorbidadeInativacao(null);
          setVisualizarJustificativa(false);
        }}
        onSuccess={reloadList}
      />

      <ConfirmDialog
        visible={!!idComorbidadeExcluir}
        header="Excluir Comorbidade"
        text="A Comorbidade será excluída. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdComorbidadeExcluir(null)}
        onConfirm={onExcluir}
      />
    </div>
  );
}

export default Comorbidades;
