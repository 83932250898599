import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/comunicados';

export const createComunicado = (
  payload: Partial<PostComunicadoDTO>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostComunicadoDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    ...options,
  });
};

export const loadComunicados = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetComunicadosDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadComunicadosPacientes = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetComunicadosDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pacientes/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadComunicadoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetComunicadoDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const updateComunicado = (
  id: number,
  payload: PostComunicadoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostComunicadoDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    ...options,
  });
};

export const inativarComunicado = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/inativar/${id}`,
    ...options,
  });
};

export const deleteComunicado = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

export const loadQuantidadeDeDestinatarios = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/quantidade-usuario`,
    data: payload,
    hideToast: true,
    ...options,
  });
};

export const sendComunicadosPortalPaciente = async (
  params: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/portal-paciente`,
    params,
    data: payload,
    ...options,
  });
};
export const updateComunicadosPortalPaciente = async (
  idComunicado: number | string,
  params: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/portal-paciente/${idComunicado}`,
    params,
    data: payload,
    ...options,
  });
};

const ComunicadosAPI = {
  loadComunicados,
  updateComunicado,
  createComunicado,
  deleteComunicado,
  inativarComunicado,
  loadComunicadoById,
  loadQuantidadeDeDestinatarios,
  updateComunicadosPortalPaciente,
  sendComunicadosPortalPaciente,
  loadComunicadosPacientes,
};

export default ComunicadosAPI;
