import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ServicosTussVinculadosAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ServicosTussVinculadosAPI';

import useDebounce from 'src/utils/useDebounce';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type IProps = DropdownProps & {
  name?: string;
  label: string;
  returnType?: 'all' | 'id';
  params?: any;
  idConsultorio: string;
  showCodigo?: boolean;
};

export const FieldServicoTussVinculado = ({
  name = 'servicoTuss',
  label,
  returnType = 'all',
  params = {},
  showCodigo = false,
  idConsultorio,
  ...rest
}: IProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [items, setItems] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debouncedSearch = useDebounce(querySearch, 350);

  const loadAPI = useCallback(
    async (nome: string, params: any) => {
      if (!params.idConvenio || !params.idMedico) {
        return setItems([]);
      }
      try {
        setLoadingSearch(true);
        const response = await ServicosTussVinculadosAPI.getServicosVinculados(
          idConsultorio,
          {
            filtro: nome,
            ...params,
          },
          { throwError: true },
        );

        if (!!response.length) {
          setItems(response);
        } else {
          setItems([]);
        }
      } catch {
        setItems([]);
      } finally {
        setLoadingSearch(false);
      }
    },
    [idConsultorio],
  );

  useEffect(() => {
    loadAPI(debouncedSearch, params);
  }, [debouncedSearch, loadAPI, params]);

  const servicoTussOptions = useMemo(() => {
    const options = items.map(item => ({
      label: item.nomeCodigoServicoTuss,
      value: item,
    }));
    return options;
  }, [items]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setQuerySearch(filter);
  };

  const options = servicoTussOptions.length
    ? servicoTussOptions
    : defaultOption;
  const emptyFilterMessage =
    querySearch.length >= 3
      ? 'Nenhuma opção encontrada'
      : 'Digite para pesquisar';

  if (loadingSearch) {
    return <Skeleton loading height="64px" />;
  }

  if (items.length === 0) {
    return <></>;
  }

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loadingSearch}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options}
      errorMsg={errors[name]?.message}
      panelClassName="dropdown-servico-tuss"
      style={{
        maxWidth: '100%',
      }}
      itemTemplate={option => {
        return (
          <SimpleText title={option.label} className="p-text-truncate">
            {option.label}
          </SimpleText>
        );
      }}
      {...rest}
    />
  );
};
