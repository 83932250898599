import React, { useState } from 'react';

import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import FolhaRosto from './FolhaRosto';
import Sorologia from './Sorologia';
import Vacinas from './Vacinas';

import './RegistrarHistorico.scss';

interface RegistrarHistoricoProps {
  idPaciente: number | null;
  valoresEnum: any;
  visible: boolean;
  onHide: () => void;
}

const RegistrarHistorico = ({
  idPaciente,
  valoresEnum,
  visible,
  onHide,
}: RegistrarHistoricoProps) => {
  const { loadPreNatal } = usePreNatal();
  const [idPreNatal, setIdPreNatal] = useState<number | null>(null);

  const onHideHistorico = () => {
    setIdPreNatal(null);
    onHide();
  };

  return (
    <Dialog
      className="registrar-historico-modal"
      visible={visible}
      header="Novo registro do histórico de gestação"
      onHide={onHideHistorico}
      maximizedMobileSize
    >
      <Tab
        clean
        values={[
          {
            label: 'Folha de rosto',
            content: (
              <FolhaRosto
                idPreNatal={idPreNatal}
                onSuccess={(idPreNatal: number) => [
                  setIdPreNatal(idPreNatal),
                  loadPreNatal(idPaciente!),
                ]}
                idPaciente={idPaciente}
                valoresEnum={valoresEnum}
                onHide={onHideHistorico}
              />
            ),
          },
          {
            label: 'Sorologia',
            content: (
              <Sorologia idPreNatal={idPreNatal} onHide={onHideHistorico} />
            ),
            disabled: !idPreNatal,
          },
          {
            label: 'Vacinas',
            content: (
              <Vacinas idPreNatal={idPreNatal} onHide={onHideHistorico} />
            ),
            disabled: !idPreNatal,
          },
        ]}
      />
    </Dialog>
  );
};

export default RegistrarHistorico;
