import React from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import StatusCentroCusto from './StatusCentroCusto';

interface MobileRowProps {
  centroCusto: any;
  items: any;
  menuRef: any;
  loadingStatus: boolean;
  onSwitchStatus: () => void;
  ativo: boolean;
}

const MobileRow = ({
  centroCusto,
  items,
  menuRef,
  loadingStatus,
  onSwitchStatus,
  ativo,
}: MobileRowProps) => {
  return (
    <div className="p-grid p-flex-1 p-p-1">
      <div className="p-col-6 p-sm-4 p-md-2 p-d-flex p-flex-column p-gap-1 ">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Consultorio:
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {centroCusto.consultorio?.nome || ''}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 p-d-flex p-flex-column p-gap-1 ">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Codigo:
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS}>{centroCusto.id}</SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 p-d-flex p-flex-column p-gap-1 ">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Centro de custo:
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS}>{centroCusto.nome}</SimpleText>
      </div>
      <div className="p-col-12 p-sm-4 p-md-3 p-d-flex p-flex-column p-gap-1 ">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Descrição:
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {centroCusto.descricao}
        </SimpleText>
      </div>

      <div className="p-col-6 p-sm-4 p-md-3 p-d-flex p-flex-column p-gap-1 ">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Status:
        </SimpleText>

        <StatusCentroCusto
          onSwitchStatus={onSwitchStatus}
          loadingStatus={loadingStatus}
          ativo={ativo}
        />
      </div>
      <div className="p-col-6 p-sm-4 p-md-3 p-d-flex p-flex-column p-gap-1 p-flex-1 p-ai-end">
        <div className="p-d-flex p-ai-center">
          <Button
            className="p-mr-1"
            btnType="gray"
            icon="fas fa-ellipsis-h"
            onClick={event => menuRef?.current?.toggle(event)}
          />

          <TieredMenu model={items} popup ref={menuRef} />
        </div>
      </div>
    </div>
  );
};

export default MobileRow;
