import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import ModalDnpm from './ModalDnpm';

import './Dnpm.scss';

interface DnpmProps {
  visible: boolean;
  paciente: any | null;
  idAtendimento: number | null;
  onClose: () => void;
}

function Dnpm({ visible, paciente, idAtendimento, onClose }: DnpmProps) {
  const modalDisclosure = useDisclosure({ opened: visible, onClose });
  const idPaciente = paciente?.id;

  return (
    <>
      {modalDisclosure.isOpen && (
        <ModalDnpm
          {...modalDisclosure}
          idPaciente={idPaciente}
          idAtendimento={idAtendimento}
        />
      )}
    </>
  );
}

export default Dnpm;
