import React, { useRef } from 'react';

import { useReactToPrint } from 'react-to-print';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import Etiqueta from './Etiqueta';

const DialogEtiqueta = ({ isOpen, close, state }: DisclosureType) => {
  const cartaoPlano = state || '';
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });

  const footer = (
    <div className="p-d-flex p-gap-2 p-col-12 p-px-0">
      <div className={'p-col-6 p-px-0'}>
        <Button
          label={'Cancelar'}
          onClick={() => {
            close();
          }}
          btnType="ghost"
          stretch
        />
      </div>
      <div className={'p-col-6 p-px-0'}>
        <Button
          label={'Sim, imprimir etiqueta'}
          onClick={() => {
            handlePrint();
            close();
          }}
          stretch
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header="Deseja imprimir etiqueta com os dados do paciente?"
      visible={isOpen}
      onHide={close}
      style={{ maxWidth: '600px' }}
      footer={footer}
    >
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText>
          O paciente foi marcado como presente, clique em sim para imprimir a
          etiqueta com as informações do paciente.
        </SimpleText>
        <div ref={componentRef}>
          <Etiqueta cartaoPlano={cartaoPlano} />
        </div>
      </div>
    </Dialog>
  );
};

export default DialogEtiqueta;
