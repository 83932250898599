import Avatar from 'src/components/Avatar/Avatar';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './GroupAvatar.scss';

export type AvatarGroupItemsProps = {
  label: string;
  imageUrl?: string | undefined;
  state?: any;
};

export const GroupAvatar = ({
  items,
  onClickBadge,
}: {
  items: AvatarGroupItemsProps[];
  onClickBadge?(): void;
}) => {
  const itemsLengh = items.length;
  const showedItems = items.slice(0, 4);
  const notShowedItems = items.slice(5);

  if (!itemsLengh) return <></>;

  return (
    <div className="group-avatar p-d-flex p-gap-0.5">
      {showedItems.map((item, idx) => (
        <Avatar
          key={idx}
          label={item.label}
          image={item.imageUrl || ''}
          size="small"
        />
      ))}

      {!!notShowedItems?.length && (
        <div
          onClick={() => onClickBadge?.()}
          className="badge-avatar-container p-d-flex p-align-center p-gap-0.5"
        >
          <span className="badge-avatar">+5</span>
          <SimpleText fontColor="primary" fontSize="xxs" medium>
            pacientes
          </SimpleText>
        </div>
      )}

      {!notShowedItems.length && (
        <SimpleText
          onClick={() => onClickBadge?.()}
          fontSize="xxs"
          fontColor="primary"
          aria-role="button"
          medium
        >
          Ver detalhes
        </SimpleText>
      )}
    </div>
  );
};
