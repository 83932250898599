import { useState, useCallback } from 'react';

import { useLocation, useParams } from 'react-router';

import SaturacaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/SaturacaoAPI/SaturacaoAPI';

import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from '../PanelContent/PanelContent';
import PanelHeader from '../PanelHeader/PanelHeader';

interface SaturacaoProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const Saturacao = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: SaturacaoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      return SaturacaoAPI.getSaturacao({
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        ...params,
      });
    },
    [idAtendimento, selectedAtendimento?.idAtendimento],
  );

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
  ) => {
    return (
      <div className="p-col-3 p-d-flex">
        <div className="column p-jc-center p-ml-3">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Saturação
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.saturacao.toFixed(2).replace(/[.,]00$/, '')}%
          </SimpleText>
        </div>
        <Separator layout="vertical" />
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento} />
      </Panel>
    );
  };

  return (
    <>
      <InfiniteScroll
        fetchAPI={fetchAPI}
        renderRow={renderRow}
        updateList={updateList}
      />
    </>
  );
};

export default Saturacao;
