import { useState, useCallback } from 'react';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';

import { useAgenda } from '../AgendaContext';

import ModalRevogarSigilo from './ModalRevogarSigilo/ModalRevogarSigilo';
import ModalSigilo from './ModalSigilo/ModalSigilo';

enum NivelSigilo {
  BLOQUEADO = 0,
  SADT = 1,
  SOAP = 2,
}

interface SigiloProps {
  atendimento: AtendimentoAgendamento;
}

function Sigilo({ atendimento }: SigiloProps) {
  const { refetchAgenda } = useAgenda();

  const [modalSigilo, setModalSigilo] = useState(false);
  const [modalRevogarSigilo, setModalRevogarSigilo] = useState(false);
  const [paciente, setPaciente] = useState({
    id: null,
    sigiloExists: false,
    sigilo: null,
    idAtendimento: null,
  });

  const onSigiloSuccess = useCallback(() => {
    setPaciente({
      id: null,
      sigiloExists: false,
      sigilo: null,
      idAtendimento: null,
    });

    setModalSigilo(false);
    setModalRevogarSigilo(false);
    refetchAgenda();
  }, [refetchAgenda]);

  function onCancel() {
    setPaciente({
      id: null,
      sigiloExists: false,
      sigilo: null,
      idAtendimento: null,
    });

    setModalRevogarSigilo(false);
    setModalSigilo(false);
  }

  const handleTypeIconSigilo = useCallback((nvlSigilo: NivelSigilo) => {
    if (nvlSigilo === 0) {
      return 'fas fa-lock';
    }
    return 'fas fa-lock-open';
  }, []);

  const handleColorIconSigilo = useCallback((nvlSigilo: NivelSigilo) => {
    if (nvlSigilo === 0) {
      return IconBtnTypes.RED;
    }
    if (nvlSigilo === 1) {
      return IconBtnTypes.YELLOW;
    }
    return IconBtnTypes.GREEN;
  }, []);

  const handleSigiloModal = useCallback((pac: any) => {
    if (pac.sigiloExists) {
      setModalRevogarSigilo(true);
    } else {
      setModalSigilo(true);
    }
  }, []);

  const handleSigilo = useCallback(
    atendimento => {
      const newPaciente = {
        ...paciente,
        paciente: atendimento.paciente,
        id: atendimento.idPaciente,
        sigilo: atendimento.sigilo,
        sigiloExists: atendimento.nivelSigilo !== 0 ? true : false,
      };
      setPaciente({ ...newPaciente });
      handleSigiloModal({ ...newPaciente });
    },
    [paciente, handleSigiloModal, setPaciente],
  );

  return (
    <>
      <IconButton
        color="red"
        icon={handleTypeIconSigilo(
          atendimento.nivelSigilo ? atendimento.nivelSigilo : 0,
        )}
        btnType={handleColorIconSigilo(
          atendimento.nivelSigilo ? atendimento.nivelSigilo : 0,
        )}
        onClick={() => handleSigilo(atendimento)}
      />

      {modalRevogarSigilo && (
        <ModalRevogarSigilo
          paciente={paciente}
          setPaciente={setPaciente}
          visible={modalRevogarSigilo}
          onSuccess={onSigiloSuccess}
          onCancel={onCancel}
        />
      )}

      {modalSigilo && (
        <ModalSigilo
          paciente={paciente}
          setPaciente={setPaciente}
          visible={modalSigilo}
          onSuccess={onSigiloSuccess}
          onCancel={onCancel}
        />
      )}
    </>
  );
}

export default Sigilo;
