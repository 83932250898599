import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import ModalInfoLiberacaoSigilo from './ModalInfoLiberacaoSigilo';
import { permissionsOptions } from './permissionsOptions';

import './FormRequiredData.scss';
interface FormRequiredDataProps {
  onSubmit(v: Partial<UsuarioDTO>, goToNextStep?: boolean): void;
  values?: Partial<ConsultorioDTO> | any;
  loadingData: boolean;
}

const FormRequiredData = ({
  onSubmit,
  values,
  loadingData,
}: FormRequiredDataProps) => {
  const modalInfoLiberacaoSigilo = useDisclosure({ opened: false });

  const [isProntuarioCompletoSelected, setIsProntuarioCompletoSelected] =
    useState(false);

  const validationSchema = Yup.object().shape({
    nome: Yup.string()
      .max(50, 'Máximo 50 caracteres')
      .required('O campo nome é obrigatório.'),
    codigo: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('O campo código é obrigatório.'),
    descricao: Yup.string()
      .max(500, 'Máximo 500 caracteres')
      .required('O campo descrição é obrigatório.'),
  });

  const useFormMethods = useForm({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const permissoes = watch('permissoes');

  const handleValidateNota = (permissions: string[]) => {
    if (
      permissions.includes('exibirCpfEtiqueta') &&
      !permissions.includes('identificacaoEtiqueta')
    ) {
      setValue(
        'permissoes',
        permissions.filter(permission => permission !== 'exibirCpfEtiqueta'),
      );
    }

    const hasProntuarioCompletoPermission =
      permissions.includes('prontuarioCompleto');

    if (hasProntuarioCompletoPermission) {
      if (!isProntuarioCompletoSelected) {
        modalInfoLiberacaoSigilo.open();
        setIsProntuarioCompletoSelected(true);
      }
    } else {
      setIsProntuarioCompletoSelected(false);
    }
  };

  useEffect(() => {
    const hasProntuarioCompletoPermission =
      values?.permissoes?.includes('prontuarioCompleto');

    if (hasProntuarioCompletoPermission) {
      setIsProntuarioCompletoSelected(true);
    }
  }, [values]);

  // Redifinir o default values para quando values for alterado
  useEffect(() => {
    reset(values);
  }, [values, reset]);

  function FinishFormButtons() {
    return (
      <>
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
          Você precisa salvar para continuar. Deseja preencher os dados
          opcionais?
        </SimpleText>
        <div className="p-col-12 p-sm-6">
          <Button
            className="p-w-100"
            btnType="outline"
            label="Sim, salvar e preencher"
            type="submit"
          />
        </div>

        <div className="p-col-12 p-sm-6">
          <Button
            className="p-w-100"
            label="Não, concluir"
            type="button"
            onClick={handleSubmit(data => onSubmit(data, false))}
          />
        </div>
      </>
    );
  }

  function handleBack() {
    window.history.back();
  }

  function UpdateFormButtons() {
    return (
      <>
        <div className="p-col-12 p-md-6">
          <Button
            btnType="ghost"
            label="Cancelar"
            className="p-w-100"
            type="button"
            onClick={handleBack}
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Button
            className="p-w-100"
            btnType="tonal"
            label="Salvar"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </>
    );
  }

  function RenderButtons() {
    if (!!values) return UpdateFormButtons();

    return FinishFormButtons();
  }

  if (loadingData)
    return (
      <div className="FormRequiredData p-w-100">
        <GridListLoading />
      </div>
    );

  const permissionOptionsFormatted = permissionsOptions.map(permission => {
    if (permission.value !== 'exibirCpfEtiqueta') return permission;
    return {
      ...permission,
      disabled: !permissoes?.includes('identificacaoEtiqueta'),
    };
  });

  return (
    <>
      <FormProvider {...useFormMethods}>
        <form
          className="FormRequiredData p-grid p-col-12 p-lg-9 p-xl-8"
          onSubmit={handleSubmit(data => onSubmit(data))}
        >
          <FormInput
            hideTextErrorSpace
            label="Nome"
            name="nome"
            errorMsg={errors.nome?.message}
            className="p-col-12"
          />

          <FormInput
            hideTextErrorSpace
            label="Nome resumido"
            name="nomeResumido"
            maxLength={15}
            errorMsg={errors.nomeResumido?.message}
            className="p-col-12"
          />

          <FormInput
            hideTextErrorSpace
            label="Código"
            name="codigo"
            errorMsg={errors.codigo?.message}
            className="p-col-12"
          />

          <FormInput
            hideTextErrorSpace
            label="Descrição da clínica"
            name="descricao"
            errorMsg={errors.descricao?.message}
            className="p-col-12  p-mb-5"
          />

          <MultipleCheckboxControlled
            label="Permissões"
            className="p-col-12"
            classNameContainer="checkbox-options-container"
            name="permissoes"
            options={permissionOptionsFormatted}
            onChangeCustom={handleValidateNota}
          />

          <RenderButtons />
        </form>
      </FormProvider>

      {modalInfoLiberacaoSigilo.isOpen && (
        <ModalInfoLiberacaoSigilo {...modalInfoLiberacaoSigilo} />
      )}
    </>
  );
};

export default FormRequiredData;
