import { useState, useCallback, useRef, useEffect, useMemo } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Control, useController } from 'react-hook-form';

import VacinasAPI from 'src/APIs/ProntuarioAPI/VacinasAPI/VacinasAPI';

import { Button } from 'src/components/_UI/Button';
import Chip from 'src/components/Basics/Chip/Chip';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';

import './DropdownVacina.scss';

interface DropdownVacinaProps {
  control: Control;
  disabled?: boolean;
}

const DropdownVacina = ({ control, disabled = false }: DropdownVacinaProps) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const {
    field: { value, onChange: onChangeDescricao },
    formState: { errors },
  } = useController({
    name: 'descricao',
    control,
  });

  const {
    field: { value: idVacinaSelecionada, onChange: onChangeIdVacina },
  } = useController({
    name: 'idVacina',
    control,
  });

  const [vacinas, setVacinas] = useState<Vacinas[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filtroSelected, setFiltroSelected] = useState<string>('');

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const loadVacinas = async () => {
    const response = await VacinasAPI.getVacina();

    if (Array.isArray(response) && !!response?.length) setVacinas(response);
  };

  useEffect(() => {
    loadVacinas();
  }, []);

  const onVacinaSelect = useCallback(
    (descricao: string, idVacina: number | null) => {
      onChangeDescricao(descricao);
      onChangeIdVacina(idVacina);

      searchPanelRef.current?.hide();
    },
    [onChangeDescricao, onChangeIdVacina],
  );

  const vacinasFilteredBySearch = useMemo(
    () =>
      vacinas.filter(vacina => vacina.nome.includes(searchQuery.toUpperCase())),
    [vacinas, searchQuery],
  );

  const vacinasFilteredByIdadeMeses = useMemo(
    () =>
      vacinasFilteredBySearch.reduce((acc: any, vacina) => {
        const key =
          vacina.idadeMeses === 0 ? 'Ao nascer' : `${vacina.idadeMeses} meses`;
        (acc[key] = acc[key] || []).push(vacina);

        return acc;
      }, {}),
    [vacinasFilteredBySearch],
  );

  const idadesMesesFiltros = useMemo(() => {
    const idadeMeses = vacinas.map(vacina =>
      vacina.idadeMeses === 0 ? 'Ao nascer' : `${vacina.idadeMeses} meses`,
    );

    return [...new Set(idadeMeses)];
  }, [vacinas]);

  return (
    <div className="p-col-12">
      <div ref={searchInputContainerRef}>
        <TextInputSearch
          placeholder="Pesquise ou digite uma nova"
          value={searchQuery}
          onChange={setSearchQuery}
          updateValue={value || ''}
          onClick={e => searchPanelRef.current?.show(e, null)}
          disabled={disabled}
        />
      </div>

      <TextInputControlled control={control} name="descricao" hidden />

      <TextInputControlled control={control} name="idVacina" hidden />

      {errors.descricao?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors.descricao?.message}
        </SimpleText>
      )}

      <OverlayPanel
        className="vacina-search-panel"
        ref={searchPanelRef}
        dismissable={false}
      >
        <div className="p-grid" ref={searchPanelContainerRef}>
          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Filtrar por
          </SimpleText>
          <div id="filtro-chips-container">
            {idadesMesesFiltros.map((idadeMeses, i) => (
              <Chip
                key={i}
                label={idadeMeses}
                checked={filtroSelected === idadeMeses}
                onChange={e => setFiltroSelected(e ? idadeMeses : '')}
              />
            ))}
          </div>
          <div id="search-results-container" className="p-col-12">
            {Object.entries(vacinasFilteredByIdadeMeses).map(
              ([idadeMeses, vacinas]: any) => {
                if (filtroSelected && filtroSelected !== idadeMeses)
                  return null;

                return (
                  <div className="p-grid" key={vacinas.id}>
                    <SimpleText
                      className="p-col-12 p-my-2"
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_40}
                      medium
                    >
                      {idadeMeses}
                    </SimpleText>
                    <SimpleText
                      className="p-col-2"
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_60}
                    >
                      Idade
                    </SimpleText>
                    <SimpleText
                      className="p-col-10"
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_60}
                    >
                      Nome da vacina
                    </SimpleText>

                    {vacinas.map((vacina: Vacinas, idx: number) => (
                      <div
                        key={idx}
                        className={`vacina-item p-grid p-col-12 ${
                          vacina.id === idVacinaSelecionada
                            ? 'vacina-selecionada'
                            : ''
                        }`}
                        onClick={() => onVacinaSelect(vacina.nome, vacina.id)}
                      >
                        <SimpleText
                          className="p-col-2"
                          fontSize={FONT_SIZE.XXS}
                          fontColor={FONT_COLOR.COLOR_40}
                        >
                          {idadeMeses}
                        </SimpleText>
                        <SimpleText
                          className="p-col-10"
                          fontSize={FONT_SIZE.XXS}
                          fontColor={FONT_COLOR.COLOR_16}
                          medium
                        >
                          {vacina.nome} -{' '}
                          {Number(vacina.dose) === 1
                            ? 'Dose única'
                            : `${vacina.dose} doses`}
                        </SimpleText>
                      </div>
                    ))}
                  </div>
                );
              },
            )}

            {!Object.entries(vacinasFilteredByIdadeMeses)?.length && (
              <div className="adicionar-nova-vacina p-d-flex p-jc-center p-ai-center">
                <Button
                  type="button"
                  btnType="green-link"
                  label={`Adicionar nova vacina: ${searchQuery}`}
                  onClick={() => onVacinaSelect(searchQuery, null)}
                />
              </div>
            )}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DropdownVacina;
