import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldPublicoAplicacaoProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldPublicoAplicacao = ({
  name = 'publicoAplicacao',
  label,
  ...rest
}: FieldPublicoAplicacaoProps) => {
  const { control } = useFormContext();
  const [publicoAplicacao, setPublicoAplicacao] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const response =
        await AplicacaoFormularioDinamicoAPI.loadAplicacaoPublico({
          throwError: true,
        });

      setPublicoAplicacao(response);
    } catch {
      setPublicoAplicacao([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = publicoAplicacao.map(item => ({
      label: item?.descricao,
      value: item,
    }));

    return data;
  }, [publicoAplicacao]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="codigo"
      label={label}
      filter={false}
      options={options}
      loading={isLoading}
      carregandoFiltro={isLoading}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
