import React from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { bgColors } from '../DiagnosticoFuncionalGlobal';
import { generateRandomString, generateUniqueId } from 'src/utils/utils';
import dayjs from 'dayjs';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface AtividadeVidaDiariaProps {
  diagnostico: any;
}

const AtividadeVidaDiaria = ({ diagnostico }: AtividadeVidaDiariaProps) => {
  const autoCuidadoKATZ = Array.isArray(
    diagnostico?.atividadesVidaDiaria?.autoCuidadoKATZ,
  )
    ? diagnostico?.atividadesVidaDiaria?.autoCuidadoKATZ
    : [diagnostico?.atividadesVidaDiaria?.autoCuidadoKATZ];

  const avdsInstrumentais = Array.isArray(
    diagnostico?.atividadesVidaDiaria?.avdsInstrumentais,
  )
    ? diagnostico?.atividadesVidaDiaria?.avdsInstrumentais
    : [diagnostico?.atividadesVidaDiaria?.avdsInstrumentais];

  const avdsAvancadas = Array.isArray(
    diagnostico?.atividadesVidaDiaria?.avdsAvancadas,
  )
    ? diagnostico?.atividadesVidaDiaria?.avdsAvancadas
    : [diagnostico?.atividadesVidaDiaria?.avdsAvancadas];

  const corAutocuidado = autoCuidadoKATZ[0]?.cor;
  const corAvds = avdsInstrumentais[0]?.cor;
  const corAvdsAvancadas = avdsAvancadas[0]?.cor;

  return (
    <div className="p-d-flex border-dashed-x p-ai-center border-dashed-bottom bg-96">
      <div className="w-210px p-d-flex p-jc-center">
        <SimpleText fontSize="xxs" className="p-p-1">
          ATIVIDADES DE VIDA DIÁRIA
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column">
        <div className="p-d-flex p-flex-1">
          <div className={`w-210px p-d-flex p-jc-center border-dashed-x`}>
            <SimpleText fontSize="xxs" className="p-p-1">
              AUTOCUIDADO (ABVD) KATZ
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center  p-p-1 ${
              corAutocuidado === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corAutocuidado === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Dependência moderada (8 a 14) - 2
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corAutocuidado === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Dependência severa (15 a 21) - 4{' '}
            </SimpleText>
          </div>

          {autoCuidadoKATZ.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              AVDS INSTRUMENTAIS (Lawton)
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corAvds === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corAvds === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Dependência moderada (14 a 20) - 1
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corAvds === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Dependência severa (7 - 13) - 2
            </SimpleText>
          </div>

          {avdsInstrumentais.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              AVDS AVANÇADAS{' '}
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corAvdsAvancadas === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x  p-p-1 ${
              corAvdsAvancadas === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Dependência moderada (14 - 20) - 0
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corAvdsAvancadas === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Dependência severa (7 - 13 ) - 0
            </SimpleText>
          </div>

          {avdsAvancadas.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default AtividadeVidaDiaria;
