import Http, { HttpParams } from 'src/core/http/Http';

const getHistoricoImpressos = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'pep',
    url: `impresso/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

const inativarImpresso = (
  idImpresso: number,
  idAtendimento: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `impresso/${idImpresso}/atendimento/${idAtendimento}/inativar`,
    data,
    return: true,
    ...options,
  });
};

const deleteImpresso = (
  idImpresso: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `impresso/${idImpresso}/atendimento/${idAtendimento}`,
    ...options,
  });
};

const ImpressosAPI = {
  getHistoricoImpressos,
  inativarImpresso,
  deleteImpresso,
};

export default ImpressosAPI;
