import { Control } from 'react-hook-form';

import dayjs from 'dayjs';

import { enumToText } from 'src/utils/questionario';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';


import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface HistoricoProps {
  control: Control;
  values?: Questionarios[] | undefined;
}

const HistoricoEvolucao = ({ control, values }: HistoricoProps) => {
  return (
    <Template title="Dor frequente" control={control} readOnly>
      <div className="container-HistoricoEvolucao">
        <CardQuestions title="Mama:" control={control} readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.mama)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions title="Intestino:" control={control} readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.intestino)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions title="Próstata:" control={control} readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.prostata)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions title="Outros:" control={control} readOnly>
          <div>
            {values?.map(value => (
              <div key={value.id} className="p-grid  p-ai-start column">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.outros)}
                </SimpleText>

                <SimpleText
                  className="p-mt-2"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.descricaoOutros)}
                </SimpleText>

                <Separator />
              </div>
            ))}
          </div>
        </CardQuestions>
      </div>
    </Template>
  );
};

export default HistoricoEvolucao;
