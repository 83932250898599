import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/pacientes';

export const getPacientesPesquisaCombinada = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa-combinada`,
    params,
    hideToast: true,
    return: true,
    ...options,
  });

export const pacientesPesquisaCompleta = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa-completa`,
    params,
    hideToast: true,
    ...options,
  });

export const visualizarPaciente = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/visualizar`,
    hideToast: true,
    ...options,
  });

export const loadPacientes = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPacienteDTO[]> => {
  return Http.get({
    service: 'adm',
    params,
    url: '/pacientes/pesquisa',
    hideToast: true,
    ...options,
  });
};

export const loadPacienteById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPacienteDTO> => {
  return Http.get({
    service: 'adm',
    url: `/pacientes/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendPaciente = (
  payload: PostPacienteDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostPacienteDTO> => {
  return Http.post({
    service: 'adm',
    url: '/paciente/salva-paciente',
    data: payload,
    ...options,
  });
};

export const updatePaciente = (
  id: number,
  payload: PostPacienteDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostPacienteDTO> => {
  return Http.put({
    service: 'adm',
    url: `/paciente/salva-paciente/${id}`,
    data: payload,
    ...options,
  });
};

export const removePacienteById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPacienteDTO> => {
  return Http.delete({
    service: 'adm',
    url: `/paciente/remove-paciente/${id}`,
    ...options,
  });
};

export const removeCpfPaciente = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPacienteDTO> => {
  return Http.put({
    service: 'adm',
    url: `/pacientes/${id}/apagar-cpf`,
    ...options,
  });
};

export const loadPacienteByNome = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    service: 'adm',
    url: 'pacientes/pesquisa?pageSize=100',
    params,
    hideToast: true,
    ...options,
  });
};

export const getPacientesFamiliar = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: 'pacientes/pesquisa-familiar',
    params,
    hideToast: true,
    ...options,
  });
};

export const editarDadosPaciente = (
  id: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'adm',
    url: `pacientes/${id}/edicao-rapida`,
    data: payload,
    ...options,
    ...options,
  });
};

export const editarEnderecoPaciente = (
  id: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'adm',
    url: `pacientes/${id}/endereco`,
    data: payload,
    ...options,
  });
};

export const criarPaciente = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'adm',
    url: 'pacientes/cadastro-rapido',
    data: payload,
    ...options,
  });
};

export const pacientesSemelhantes = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'adm',
    url: 'pacientes/cadastro-rapido/semelhantes',
    data: payload,
    hideToast: true,
    ...options,
  });
};

export const getPacientesEmpresa = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: 'pacientes/pesquisa-simples',
    params,
    hideToast: true,
    ...options,
  });
};
const PacienteAPI = {
  getPacientesPesquisaCombinada,
  loadPacientes,
  loadPacienteById,
  sendPaciente,
  removePacienteById,
  updatePaciente,
  loadPacienteByNome,
  editarDadosPaciente,
  editarEnderecoPaciente,
  criarPaciente,
  pacientesSemelhantes,
  pacientesPesquisaCompleta,
  visualizarPaciente,
  removeCpfPaciente,
  getPacientesEmpresa,
};

export default PacienteAPI;
