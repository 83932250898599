import { useCallback, useEffect, useState, useRef } from 'react';

import { ToggleButton } from 'primereact/togglebutton';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Mention from '../../../components/Mention/Mention';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ModalModelo from 'src/pages/Emed/Prontuario/components/ModalModelo/ModalModelo';
import { ModalNovoModelo } from 'src/pages/Emed/Prontuario/components/ModalNovoModelo/ModalNovoModelo';

import './Prognostico.scss';

interface PrognosticoProps {
  prontuario: any;
  campos: any[];
  getCampos: any;
  updateCamposProntuario: (campos: any) => void;
  ndnPrognostico: any;
  selectedAvaliacao: any;
}

function Prognostico({
  campos,
  prontuario,
  updateCamposProntuario,
  ndnPrognostico,
  selectedAvaliacao,
}: PrognosticoProps) {
  const { state } = useLocation();
  const hasLoaded = useRef(false);
  const idAtendimento = state?.idAtendimento;
  const { agenda } = useAppSelector((state: RootState) => state);
  const { modelos, getModelos, setModelos } = useAtendimento();

  const validationSchema = Yup.object().shape({
    valorCampoProntuario: Yup.string(),
  });

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [valorCampoProntuario, setValorCampoProntuario] = useState<string>('');
  const [cidsCiapsBusca, setCidsCiapsBusca] = useState<any[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [campoPrognostico, setCampoPrognostico] = useState<any>({});
  const [modelosPrognostico, setModelosPrognostico] = useState<any[]>([]);
  const [checked, setChecked] = useState<boolean>(
    campoPrognostico.exibicaoCamposModelo === 'FIXO' ? true : false,
  );

  const modalDisclosure = useDisclosure({ opened: false });

  const saveAction = useCallback(
    (payload: any) => {
      return UtilsAPI.putCampos(Number(idAtendimento), payload);
    },
    [idAtendimento],
  );

  const handleOnBlur = useCallback(
    async (data: any) => {
      const payload = {
        idAtendimento: Number(idAtendimento),
        camposModeloProntuario: 'PROGNOSTICO',
        valorCampoProntuario: data,
        idPaciente: prontuario?.atendimento?.paciente?.id,
        idProfissionalSaude: agenda.profissionalAtivo.id,
      };
      updateCamposProntuario({ PROGNOSTICO: data });
      const response = await saveAction(payload);
    },
    [
      agenda.profissionalAtivo.id,
      idAtendimento,
      prontuario?.atendimento?.paciente?.id,
      saveAction,
      updateCamposProntuario,
    ],
  );

  const loadModeloPadrao = useCallback(() => {
    if (prontuario.prognostico?.length > 0) {
      setValorCampoProntuario(prontuario.prognostico);
    } else {
      modelos?.forEach(modelo => {
        if (modelo.campo === 'PROGNOSTICO' && modelo.padrao) {
          setValorCampoProntuario(modelo.texto + '\n');
          handleOnBlur(modelo.texto + '\n');
        }
      });
    }
  }, [modelos, prontuario.prognostico, handleOnBlur]);

  const loadModelosPrognostico = useCallback(() => {
    const modelosCampo: any[] = [];
    modelos?.forEach(modelo => {
      if (modelo.campo === 'PROGNOSTICO') {
        modelosCampo.push(modelo);
      }
    });
    setModelosPrognostico(modelosCampo);
  }, [modelos]);

  const passData = useCallback(
    (data: any) => {
      setValorCampoProntuario(prev => prev + data.texto + '\n');
      handleOnBlur(valorCampoProntuario + data.texto + '\n');
    },
    [handleOnBlur, valorCampoProntuario],
  );

  useEffect(() => {
    const ndn = () => {
      if (
        (valorCampoProntuario?.length < 1 &&
          selectedAvaliacao.includes('PROGNOSTICO')) ||
        (valorCampoProntuario === null &&
          selectedAvaliacao.includes('PROGNOSTICO'))
      ) {
        setValorCampoProntuario('Nada digno de nota');
        handleOnBlur('Nada digno de nota');
      }
    };

    ndnPrognostico.current = ndn;
  }, [handleOnBlur, ndnPrognostico, selectedAvaliacao, valorCampoProntuario]);

  useEffect(() => {
    setCampoPrognostico(
      campos.filter(campo => campo.funcionalidade === 'PROGNOSTICO')[0],
    );
  }, [campos]);

  useEffect(() => {
    if (!hasLoaded.current) {
      loadModeloPadrao();
      hasLoaded.current = true;
    }
  }, [loadModeloPadrao]);

  useEffect(() => {
    loadModelosPrognostico();
    setChecked(campoPrognostico.exibicaoCamposModelo === 'FIXO' ? true : false);
  }, [campoPrognostico, getModelos, loadModelosPrognostico]);

  const reloadCampos = () => {
    if (campoPrognostico.exibicaoCamposModelo === 'FIXO') {
      campoPrognostico.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoPrognostico.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoPrognostico.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }

    const exibixaoCampo =
      campoPrognostico.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoPrognostico.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const getCidsCiapsBusca = useCallback(async (query: any) => {
    await UtilsAPI.getCidsCiapsBusca({ chave: query }).then(response => {
      const cidsCiaps = response.list;
      cidsCiaps.forEach((cidCiap: any) => {
        cidCiap.nome = cidCiap.nome.replaceAll(' ', '_').toLowerCase();
      });
      setCidsCiapsBusca(cidsCiaps);
    });
  }, []);

  const itemTemplate = (suggestion: any) => {
    return <span>{suggestion.nome}</span>;
  };

  const onSearch = (event: any) => {
    const query = event.query;
    let suggestions;

    if (query.length > 2) {
      getCidsCiapsBusca(query);
    }

    if (!query.trim().length) {
      suggestions = [...cidsCiapsBusca];
    } else {
      suggestions = cidsCiapsBusca.filter(cidCiap =>
        cidCiap.nome.toLowerCase().includes(query),
      );
      suggestions = [
        ...cidsCiapsBusca.sort((a, b) => {
          const aStarts = a.nome.toLowerCase().startsWith(query);
          const bStarts = b.nome.toLowerCase().startsWith(query);
          if (aStarts && bStarts) return a.nome.localeCompare(b.nome);
          if (aStarts && !bStarts) return -1;
          if (!aStarts && bStarts) return 1;
          return a.nome.localeCompare(b.nome);
        }),
      ];
    }
    setSuggestions(suggestions);
  };

  const isRequired = campoPrognostico.obrigatorio;

  return (
    <>
      <div className="p-d-flex p-jc-between">
        <div className="p-as-end">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Prognostico
          </SimpleText>
          {isRequired && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div className="p-d-flex modelos">
          <Button
            btnType="green-link"
            label="Modelo"
            iconPos="right"
            icon="pi pi-chevron-down"
            overlay={
              <ModalModelo
                onOpenModal={modalDisclosure.open}
                modelos={modelosPrognostico}
                passData={passData}
                valorCampoProntuario={valorCampoProntuario}
                setValorCampoProntuario={setValorCampoProntuario}
              />
            }
          />

          <ToggleButton
            checked={checked}
            className={`p-px-4 p-py-1 pin ${isRequired ? 'pin-disabled' : ''}`}
            onLabel={''}
            offLabel={''}
            onChange={e =>
              !isRequired ? [handleFixar(), setChecked(e.value)] : null
            }
            onIcon="fa fa-thumbtack"
            offIcon="fa fa-thumbtack"
          />
        </div>
      </div>
      <div className="text-area p-pb-1">
        <form id="form-prognostico" className="p-mt-3">
          <Mention
            valorCampoProntuario={valorCampoProntuario}
            setValorCampoProntuario={setValorCampoProntuario}
            handleOnBlur={handleOnBlur}
          />
        </form>
      </div>

      {modalDisclosure.isOpen && (
        <ModalNovoModelo
          valorCampoProntuario={valorCampoProntuario}
          setValorCampoProntuario={setValorCampoProntuario}
          tipoCampo="PROGNOSTICO"
          {...modalDisclosure}
        />
      )}
    </>
  );
}

export default Prognostico;
