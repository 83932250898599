import { useFormContext } from 'react-hook-form';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';

import { questionarioExameSimOuNaoOptions } from '../../../helpers';

interface ExameLaboratorialFieldsProps {
  hasQuestionarioLab: boolean;
}

export const ExameLaboratorialFields = (
  props: ExameLaboratorialFieldsProps,
) => {
  const { hasQuestionarioLab } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!hasQuestionarioLab) return null;

  return (
    <>
      <SimpleText
        className="p-col-12 p-mt-3 p-px-0"
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Questionário exame laboratorial
      </SimpleText>

      <Card className="p-grid p-mt-2" type={CARD_TYPES.BORDER}>
        <DropdownControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacao.repeticao"
          label="Trata-se de repetição?"
          filter={false}
          options={questionarioExameSimOuNaoOptions}
          errorMsg={errors.questionarioSolicitacao?.repeticao?.message}
        />

        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacao.dataAnterior"
          label="Data da realização"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          maxDate={new Date()}
          errorMsg={errors.questionarioSolicitacao?.dataAnterior?.message}
        />

        <TextareaInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacao.tratamentosRealizados"
          label="Tratamentos clínicos e cirúrgicos realizados"
          maxLength={500}
          errorMsg={
            errors.questionarioSolicitacao?.tratamentosRealizados?.message
          }
        />

        <TextareaInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacao.outrasInformacoes"
          label="Outas informações relevantes"
          maxLength={500}
          errorMsg={errors.questionarioSolicitacao?.outrasInformacoes?.message}
        />
      </Card>
    </>
  );
};
