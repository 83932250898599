import { useEffect, useState, useCallback, useContext } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';
import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';
import TipoGrupoAtendimentoAPI from 'src/APIs/AgendaAPI/TipoGrupoAtendimento/TipoGrupoAtendimentoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import { tiposPeriodicidade } from 'src/utils/tiposPeriodicidade';

import { Button } from 'src/components/_UI/Button';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Can from 'src/components/Can/Can';
import PageHeader from 'src/components/PageHeader/PageHeader';

import FormProfessionals from './FormProfessionals/FormProfessionals';
import PacienteListItem from './ListItemPaciente/ListItemPaciente';
import ModalGerenciarTipoGrupo from './ModalGerenciarTipoGrupo/ModalGerenciarTipoGrupo';
import ModalSairSemSalvarGrupoAtendimento from './ModalSairSemSalvarGrupoAtendimento/ModalSairSemSalvarGrupoAtendimento';
import SearchList from './SearchListPaciente/SearchList';







import './AddGrupoAtendimento.scss';

const formFields = {
  apelido: '',
  tipoGrupo: '',
  periodicidade: '',
  dataAtendimento: '',
};

const AddGrupoAtendimento = () => {
  const { user } = useAppSelector((state: RootState) => state);

  const navigate = useNavigate();
  const params = useParams();

  const theme = useContext(ThemeContext);

  const validationSchema = Yup.object().shape({
    apelido: Yup.string().required('Apelido é obrigatório'),
    idTipoGrupoAtendimento: Yup.string().required(
      'Tipo de Grupo é obrigatório',
    ),
    periodicidade: Yup.string().required('Periodicidade é obrigatória'),
    periodo: Yup.string().required('Data de Atendimento é obrigatória'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [grupoAtendimento, setGrupoAtendimento] = useState({} as any);
  const [temGrupoAtendimento, setTemGrupoAtendimento] = useState(false);
  const [
    modalSairSemSalvarGrupoAtendimento,
    setModalSairSemSalvarGrupoAtendimento,
  ] = useState(false);
  const [modalGerenciarTipoGrupo, setModalGerenciarTipoGrupo] = useState(false);
  const [searchPaciente, setSearchPaciente] = useState('');
  const [listaPacientesSelecionados, setListaPacientesSelecionados] = useState<
    Usuario[]
  >([]);
  const [listaProfissionaisSelecionados, setListaProfissionaisSelecionados] =
    useState<any[]>([]);
  const [listTipoGrupoAtendimento, setListTipoGrupoAtendimento] = useState<
    any[]
  >([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  const loadGrupoAtendimento = useCallback(async () => {
    if (params?.id) {
      const idGrupoAtendimento = parseInt(params?.id);

      const grupoAtendimentoEncontrado: GrupoAtendimentoDTO =
        await GrupoAtendimentoAPI.loadGrupoAtendimentoById(idGrupoAtendimento);

      const partesPeriodo = grupoAtendimentoEncontrado.periodo.split('/');
      const periodo = new Date(
        Number(`${partesPeriodo[1]}`),
        Number(parseInt(`${partesPeriodo[0]}`) - 1),
        Number('01'),
      );

      setValue('apelido', grupoAtendimentoEncontrado.apelido);
      setValue(
        'idTipoGrupoAtendimento',
        grupoAtendimentoEncontrado.idTipoGrupoAtendimento,
      );
      setValue('periodicidade', grupoAtendimentoEncontrado.periodicidade);
      setValue('periodo', periodo);

      if (grupoAtendimentoEncontrado.grupoAtendimentoPacientes.length > 0) {
        const pacientesFormatados =
          grupoAtendimentoEncontrado.grupoAtendimentoPacientes.map(
            (p: any) => ({
              ...p,
              id: p.idPaciente,
            }),
          );

        setListaPacientesSelecionados(pacientesFormatados);
      }

      setListaProfissionaisSelecionados(
        grupoAtendimentoEncontrado.grupoAtendimentoProfissionais,
      );

      setGrupoAtendimento(grupoAtendimentoEncontrado);
    }
  }, [params, setValue]);

  useEffect(() => {
    loadGrupoAtendimento();
  }, [loadGrupoAtendimento]);

  const loadTipoGrupoAtendimento = useCallback(async () => {
    const tipoGrupoAtivoEncontrado =
      await TipoGrupoAtendimentoAPI.loadTipoGrupoAtendimentos({ ativo: true });

    const opa = tipoGrupoAtivoEncontrado?.list.map((ta: any) => {
      return {
        label: ta.nome,
        value: ta.id,
      };
    });

    setListTipoGrupoAtendimento(opa as any);
  }, []);

  useEffect(() => {
    loadTipoGrupoAtendimento();
  }, [loadTipoGrupoAtendimento]);

  useEffect(() => {
    if (reload) {
      setReload(false);
      loadTipoGrupoAtendimento();
    }
  }, [reload, loadTipoGrupoAtendimento]);

  useEffect(() => {
    if (params?.id) {
      setTemGrupoAtendimento(true);
    } else {
      setTemGrupoAtendimento(false);
    }
  }, [params]);

  const handleRemoverPaciente = useCallback(
    (pac: any) => {
      const pacientesSelecionados = listaPacientesSelecionados?.filter(
        (p: any) => p.id !== pac.id,
      );
      setListaPacientesSelecionados(pacientesSelecionados);
    },
    [listaPacientesSelecionados],
  );

  const onChangeSearch = useCallback(async (value: string) => {
    setSearchPaciente(value);
  }, []);

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await PacienteAPI.loadPacienteByNome({ nome: params?.query });
  };

  const tranformaDateEmString = (data: any) => {
    const dataPeriodo = new Date(data);
    const mesSelecionado = dataPeriodo.getMonth() + 1;
    const mes = mesSelecionado < 10 ? `0${mesSelecionado}` : mesSelecionado;

    return `${mes}/${dataPeriodo.getFullYear()}`;
  };

  const handleAddGrupoAtendimento = useCallback(
    async (data: Partial<GrupoAtendimento>) => {
      let response;
      const periodo = tranformaDateEmString(data.periodo!);

      const grupoAtendimentoPacientes = listaPacientesSelecionados.map(
        (pac: any) => {
          return {
            idPaciente: pac.id!,
            status: true,
          };
        },
      );
      const grupoAtendimentoProfissionais = listaProfissionaisSelecionados.map(
        (prof: any) => {
          return {
            idProfissionalSaude: prof.idProfissionalSaude!,
          };
        },
      );

      if (temGrupoAtendimento) {
        const grupoAtendimentoExistente: PostGrupoAtendimentoDTO = {
          ...grupoAtendimento,
          apelido: data.apelido!,
          periodicidade: data.periodicidade!,
          periodo,
          idTipoGrupoAtendimento: data.idTipoGrupoAtendimento,
          grupoAtendimentoPacientes,
          grupoAtendimentoProfissionais,
        };

        response = await GrupoAtendimentoAPI.updateGrupoAtendimento(
          grupoAtendimento.id,
          grupoAtendimentoExistente,
        );
      } else {
        const newData: any = {
          idEmpresa: user.idEmpresa,
          idTipoGrupoAtendimento: data.idTipoGrupoAtendimento,
          periodicidade: data.periodicidade!,
          apelido: data.apelido!,
          periodo,
          status: true,
          grupoAtendimentoPacientes,
          grupoAtendimentoProfissionais,
        };

        response = await GrupoAtendimentoAPI.sendGrupoAtendimento(newData);
      }

      if (response?.periodo) {
        reset(formFields);
        setListaProfissionaisSelecionados([]);
        setListaPacientesSelecionados([]);
        setSearchPaciente('');
        navigate('/pacientes/grupo-atendimento');
      }
    },
    [
      user,
      listaPacientesSelecionados,
      listaProfissionaisSelecionados,
      temGrupoAtendimento,
      grupoAtendimento,
      reset,
      navigate,
    ],
  );

  const handleCloseModalSairSemSalvarGrupoAtendimento = useCallback(() => {
    setModalSairSemSalvarGrupoAtendimento(false);
  }, []);

  const handleModalSairSemSalvar = useCallback(() => {
    setModalSairSemSalvarGrupoAtendimento(true);
  }, []);

  const handleCloseModalGerenciarTipoGrupo = useCallback(() => {
    setModalGerenciarTipoGrupo(false);
    setReload(true);
  }, []);

  const handleGerenciarTipoGrupo = useCallback(() => {
    setModalGerenciarTipoGrupo(true);
  }, []);

  return (
    <Page className={`container Config ${theme?.theme || ''}`} white>
      <>
        <PageHeader
          backButton
          onClickBackButton={handleModalSairSemSalvar}
          title={
            params?.id
              ? 'Editar grupo de atendimento'
              : 'Novo grupo de atendimento'
          }
          subtitle={
            'Preencha os campos, adicione pacientes e profissionais e salve para criar um novo grupo'
          }
        />
        <div className="p-grid modal-body-grupo-atendimento">
          <div className="p-col-12 p-lg-6">
            <form
              onSubmit={handleSubmit(handleAddGrupoAtendimento)}
              className="p-col-12 p-p-0"
              defaultValue={grupoAtendimento}
              onReset={() => reset(formFields)}
            >
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <TextInput
                    placeholder="Digite um apelido"
                    label="Apelido do grupo"
                    className={'p-mt-3 p-col-12'}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    errorMsg={errors.apelido?.message}
                  />
                )}
                name="apelido"
              />

              <div className="p-grid p-px-0 p-d-flex p-direction-row p-jc-end p-ai-end">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }: any) => (
                    <Dropdown
                      placeholder="Selecione"
                      label="Tipo de grupo"
                      className="p-col-12 p-md-6"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      options={listTipoGrupoAtendimento}
                      errorMsg={errors.idTipoGrupoAtendimento?.message}
                    />
                  )}
                  name="idTipoGrupoAtendimento"
                />
                <div className="p-col-12 p-md-6" style={{ paddingBottom: 8 }}>
                  <Can
                    checkPermission={[
                      'AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_CADASTRAR',
                      'AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_ALTERAR',
                      'AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_EXCLUIR',
                    ]}
                  >
                    {can => (
                      <Button
                        btnType="tonal"
                        label="Gerenciar tipos de grupo"
                        className=""
                        style={{ width: '100%' }}
                        onClick={handleGerenciarTipoGrupo}
                        type="button"
                        disabled={!can}
                      />
                    )}
                  </Can>
                </div>
              </div>

              <div className="p-grid p-px-0 p-d-flex p-direction-row p-jc-end p-ai-end">
                <div className="p-col-12 p-sm-6 p-p-0">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }: any) => (
                      <Dropdown
                        placeholder="Selecione"
                        label="Defina a periodicidade"
                        className={'p-col-12'}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        errorMsg={errors.periodicidade?.message}
                        options={tiposPeriodicidade}
                      />
                    )}
                    name="periodicidade"
                  />
                </div>

                <div className="p-col-12 p-sm-6 p-p-0">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }: any) => (
                      <CalendarInput
                        placeholder="mm/aa"
                        dateFormat="mm/yy"
                        view="month"
                        label="Mês e ano inicial dos atendimentos"
                        className={'p-col-12 placeeee'}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        errorMsg={errors.periodo?.message}
                      />
                    )}
                    name="periodo"
                  />
                </div>
              </div>

              <SimpleText bold className="p-col-12 p-d-flex p-mt-3 p-mb-2">
                Pesquise e selecione o paciente para adicionar no grupo
              </SimpleText>

              <div className="p-col-12">
                <TextInputSearch
                  autoFocus
                  placeholder="Digite o nome"
                  onChange={onChangeSearch}
                  value={searchPaciente}
                />
              </div>

              {searchPaciente ? (
                <SearchList
                  fetchAPI={fetchApi}
                  value={searchPaciente}
                  setSearchPaciente={setSearchPaciente}
                  listaPacientesSelecionados={listaPacientesSelecionados}
                  setListaPacientesSelecionados={setListaPacientesSelecionados}
                />
              ) : (
                <>
                  <SimpleText bold className="p-col-12 p-d-flex p-mt-3 p-mb-2">
                    Lista de pacientes
                  </SimpleText>

                  <div className="lista-selecionados p-col-12">
                    {listaPacientesSelecionados &&
                    listaPacientesSelecionados?.length > 0 ? (
                      <>
                        <div className="p-mb-1 p-d-flex p-pr-2">
                          <span className="p-col-7 text-empty">Paciente</span>
                          <span className="p-col-2 text-empty">Presenças</span>
                          <span className="p-col-2 text-empty">Status</span>
                          <span className=" p-col-1 text-empty">Ação</span>
                        </div>
                        {listaPacientesSelecionados?.map(
                          (i: any, idx: number) => {
                            return (
                              <PacienteListItem
                                key={idx}
                                paciente={i}
                                handleRemove={() => handleRemoverPaciente(i)}
                                isList
                              />
                            );
                          },
                        )}
                      </>
                    ) : (
                      <span className="text-empty">
                        Não há pacientes neste grupo.
                      </span>
                    )}
                  </div>
                </>
              )}

              <Button
                className="p-mt-4"
                label="Salvar"
                type="submit"
                stretch
                loading={isSubmitting}
              />
            </form>
          </div>
          <div className="p-col-12 p-lg-6 p-pt-4">
            <FormProfessionals
              onSubmit={prof => setListaProfissionaisSelecionados(prof)}
              values={listaProfissionaisSelecionados as any}
            />
          </div>
        </div>

        <ModalSairSemSalvarGrupoAtendimento
          visible={modalSairSemSalvarGrupoAtendimento}
          onHide={handleCloseModalSairSemSalvarGrupoAtendimento}
        />

        <ModalGerenciarTipoGrupo
          visible={modalGerenciarTipoGrupo}
          onHide={handleCloseModalGerenciarTipoGrupo}
        />
      </>
    </Page>
  );
};

export default AddGrupoAtendimento;
