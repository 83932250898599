import { useEffect, useState, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import Panel from '../../components/Panel/Panel';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import Form from './Form';
import Historico from './Historico';
import PanelContent from './Historico/PanelContent';
import PanelHeader from './Historico/PanelHeader';
import validationSchema from './validationSchema';

interface UltrassomProps {
  valoresEnum: any;
  idPaciente: number | null;
}

const Ultrassom = ({ valoresEnum, idPaciente }: UltrassomProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    preNatalAtual,
    setUtilizarDppUsAcompanhamento,
    loadPreNatal,
    selectedGestacao,
  } = usePreNatal();

  const gestacao = selectedGestacao || preNatalAtual;

  const hideHistorico =
    gestacao?.id === preNatalAtual?.id ? 'hide-historico' : '';

  const hideGestacaoAtual =
    gestacao?.id !== preNatalAtual?.id ? 'hide-gestacao-atual' : '';

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { reset } = useFormMethods;

  const [ultrassomList, setUltrassomList] = useState<any[]>([]);
  const [idUltrassomEditar, setIdUltrassomEditar] = useState<number | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const loadUltrassomList = useCallback(async () => {
    if (!preNatalAtual?.id) return;

    setLoading(true);

    const response = await PreNatalAPI.listUltrassom(preNatalAtual.id);

    if (Array.isArray(response)) setUltrassomList(response);

    setLoading(false);
  }, [preNatalAtual?.id]);

  useEffect(() => {
    loadUltrassomList();
  }, [loadUltrassomList]);

  const clearForm = useCallback(() => {
    reset({
      doppler: false,
      utilizarDppUsAcompanhamento: false,
      pesoUnidadeMedida:
        valoresEnum?.unidadeMedidaPreferencialUltrassom?.codigo || 'G',
      idadeGestacionalDias: '',
      idadeGestacionalSemanas: '',
    });
  }, [reset, valoresEnum?.unidadeMedidaPreferencialUltrassom?.codigo]);

  useEffect(() => {
    clearForm();
  }, [clearForm]);

  const onSubmit = async (data: any) => {
    if (!preNatalAtual?.id) return;

    setSubmitting(true);

    const saveAction = idUltrassomEditar
      ? PreNatalAPI.updateUltrassom
      : PreNatalAPI.createUltrassom;
    const idSaveAction = idUltrassomEditar
      ? idUltrassomEditar
      : preNatalAtual.id;

    const payload = {
      ...data,
      idAtendimento,
      ...(data?.peso && { peso: String(data.peso).replace(',', '.') }),
    };

    const response = await saveAction(idSaveAction, payload);

    if (response?.id) {
      setUtilizarDppUsAcompanhamento(data.utilizarDppUsAcompanhamento);
      loadUltrassomList();
      clearForm();
      setIdUltrassomEditar(null);
      loadPreNatal(Number(idPaciente));
    }

    setSubmitting(false);
  };

  const onEdit = (ultrassom: any) => {
    if (!ultrassom) return setIdUltrassomEditar(null);

    setIdUltrassomEditar(ultrassom.id);

    reset({
      dataUs: new Date(ultrassom.dataUs),
      liquido: ultrassom?.liquido || '',
      apresentacao: ultrassom.apresentacao,
      translucenciaNucal: ultrassom?.translucenciaNucal || '',
      peso: ultrassom?.peso || '',
      pesoUnidadeMedida: ultrassom?.pesoUnidadeMedida?.codigo,
      dadosOssoNasal: ultrassom?.dadosOssoNasal || '',
      vesicula: ultrassom?.vesicula || '',
      descPlacenta: ultrassom?.descPlacenta || '',
      doppler: ultrassom.doppler,
      idadeGestacionalDias: ultrassom.idadeGestacionalDias,
      idadeGestacionalSemanas: ultrassom.idadeGestacionalSemanas,
      dppUs: ultrassom.dppUs ? new Date(ultrassom.dppUs) : null,
      utilizarDppUsAcompanhamento: ultrassom.utilizarDppUsAcompanhamento,
    });
  };

  return (
    <>
      {gestacao?.ultrassons?.length > 0 && (
        <div className={hideHistorico}>
          <SimpleText fontSize={FONT_SIZE.MD} className="p-ml-2" medium>
            Histórico de ultrasom
          </SimpleText>
          <div className="p-my-1" id="gestacao-card-container">
            {gestacao?.ultrassons?.map((_ultrassom: any, index: any) => (
              <div key={index} className="p-my-1">
                <Panel
                  key={index}
                  header={(isCollapsed, onToggle) => (
                    <PanelHeader
                      ultrassom={_ultrassom}
                      isCollapsed={isCollapsed}
                      onToggle={onToggle}
                    />
                  )}
                >
                  <PanelContent ultrassom={_ultrassom} />
                </Panel>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={hideGestacaoAtual}>
        <FormProvider {...useFormMethods}>
          <Form
            idUltrassomEditar={idUltrassomEditar}
            valoresEnum={valoresEnum}
            submitting={submitting}
            onSubmit={onSubmit}
          />
        </FormProvider>

        <Historico
          ultrassomList={ultrassomList}
          loading={loading}
          loadUltrassomList={loadUltrassomList}
          onEdit={onEdit}
          clearForm={clearForm}
        />
      </div>
    </>
  );
};

export default Ultrassom;
