import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/funcionalidades';

export const loadFuncionalidades = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetFuncionalidadeDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadFuncionalidadeById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetFuncionalidadeDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const updateFuncionalidade = (
  id: number,
  payload: PostFuncionalidadeDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFuncionalidadeDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateFuncionalidadeAtivar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFuncionalidadeDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/ativar`,
    return: true,
    ...options,
  });
};

export const updateFuncionalidadeInativar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFuncionalidadeDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/inativar`,
    return: true,
    ...options,
  });
};

export const removeFuncionalidadeById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetFuncionalidadeDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

const FuncionalidadeAPI = {
  loadFuncionalidades,
  loadFuncionalidadeById,
  updateFuncionalidade,
  updateFuncionalidadeAtivar,
  updateFuncionalidadeInativar,
  removeFuncionalidadeById,
};

export default FuncionalidadeAPI;
