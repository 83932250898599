import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  pontuacao: GetPontuacaoCarteiraDTO;
  consultoriosPontuacao: JSX.Element;
  dataInclusao: string;
  pontuacaoStatus: JSX.Element;
  onEdit: (pontuacao: GetPontuacaoCarteiraDTO) => void;
  onDelete: (id: number) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const {
    pontuacao,
    onEdit,
    onDelete,
    dataInclusao,
    pontuacaoStatus,
    consultoriosPontuacao,
  } = props;

  return (
    <CardItem>
      <SimpleText
        className="p-col p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {pontuacao.nome}
      </SimpleText>

      {consultoriosPontuacao}

      <SimpleText
        className="p-col-2"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {dataInclusao}
      </SimpleText>

      <div className="p-col-fixed p-d-flex p-ai-center p-jc-end p-pl-0 fixed">
        <>
          {pontuacaoStatus}
          <Can
            checkPermission={[
              'ADM_CARTEIRA_APS_PONTUACAO_ALTERAR',
              'ADM_CARTEIRA_APS_PONTUACAO_EXCLUIR',
            ]}
          >
            <Divider layout="vertical" />
          </Can>

          <Button
            className="p-mr-2"
            btnType="gray"
            icon="pi pi-pencil"
            onClick={() => onEdit(pontuacao)}
            checkPermission="ADM_CARTEIRA_APS_PONTUACAO_ALTERAR"
          />
          <Button
            btnType="gray"
            icon="pi pi-trash"
            onClick={() => onDelete(pontuacao.id)}
            checkPermission="ADM_CARTEIRA_APS_PONTUACAO_EXCLUIR"
          />
        </>
      </div>
    </CardItem>
  );
};
