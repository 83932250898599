import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useLocation } from 'react-router';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  ultrassom: any;
  isCollapsed: boolean;
  onToggle: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onInativar: () => void;
}

function PanelHeader({
  ultrassom,
  isCollapsed,
  onToggle,
  onEdit,
  onDelete,
  onInativar,
}: PanelHeaderProps) {
  const menuEllipsis = useRef<TieredMenu>(null);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const isInactivated = !!ultrassom.justificativaInativacao;
  const dataTextClassName = `p-mt-2 ${isInactivated ? 'line-through' : ''}`;
  const dataTextColor = isInactivated
    ? FONT_COLOR.COLOR_79
    : FONT_COLOR.COLOR_16;

  const menuEllipsisItens =
    Number(idAtendimento) === ultrassom.idAtendimento
      ? [
          {
            label: 'Editar',
            command: onEdit,
          },
          {
            label: 'Excluir',
            className: 'warning-color',
            command: onDelete,
          },
        ]
      : [
          {
            label: 'Inativar',
            className: 'warning-color',
            command: onInativar,
          },
        ];

  return (
    <div className="pre-natal-panel-header p-grid p-col-12 p-align-center">
      <div className="p-col-12 p-md-10 p-d-flex">
        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            IG Semanas
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={dataTextColor}
            medium
          >
            {ultrassom.idadeGestacionalSemanas}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            IG Dias
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={dataTextColor}
            medium
          >
            {ultrassom.idadeGestacionalDias}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Apresentação
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={dataTextColor}
            medium
          >
            {ultrassom.apresentacao === 'CEFALICA' ? 'Cefálica' : 'Pélvica'}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-mr-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Peso
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={dataTextColor}
            medium
          >
            {ultrassom.peso}{' '}
            {ultrassom?.pesoUnidadeMedida?.codigo?.toLowerCase()}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-ml-5">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Data US
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={dataTextColor}
            medium
          >
            {dayjs(ultrassom.dataUs).format('DD/MM/YYYY')}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-12 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />

        {ultrassom.justificativaInativacao ? (
          <Button icon="fas fa-eye" btnType="gray" onClick={onInativar} />
        ) : (
          <Button
            icon="fas fa-ellipsis-h"
            btnType="gray"
            onClick={event => menuEllipsis?.current?.toggle(event)}
            disabled={atendimentoStatus !== 'ATENDENDO'}
          />
        )}

        <TieredMenu
          className="panel-options-pre-natal"
          ref={menuEllipsis}
          model={menuEllipsisItens}
          popup
        />
      </div>
    </div>
  );
}

export default PanelHeader;
