import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { ProcedimentosQuimio } from './ProcedimentosQuimio';

import './ModalAnexarQuimio.scss';

export const ModalAnexarQuimio = ({
  close,
  state,
}: DisclosureType<{ idProcedimento: number; numeroGuia: number }>) => {
  return (
    <Dialog
      id="Dialog-anexar-quimio"
      header="Anexar quimioterapia"
      maximizedTabletSize
      visible
      onHide={close}
    >
      <>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Pesquise o nome do medicamento/droga desejado para anexar ao
          exame/procedimento{' '}
        </SimpleText>

        <ProcedimentosQuimio close={close} {...state!} />
      </>
    </Dialog>
  );
};
