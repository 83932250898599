import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import { formatEnum } from '../helpers';

const apresentacaoOptions = [
  { label: 'Cefálica', value: 'CEFALICA' },
  { label: 'Pélvica', value: 'PELVICA' },
];

interface FormProps {
  idUltrassomEditar: number | null;
  valoresEnum: any;
  submitting: boolean;
  onSubmit: (data: any) => Promise<void>;
}

const Form = ({ idUltrassomEditar, valoresEnum, onSubmit }: FormProps) => {
  const { atendimentoStatus } = useAtendimento();

  const {
    control,
    formState: { isSubmitting, errors },
    watch,
    setValue,
    handleSubmit,
  } = useFormContext();

  const dataUs = watch('dataUs');
  const idadeGestacionalSemanas = watch('idadeGestacionalSemanas');
  const idadeGestacionalDias = watch('idadeGestacionalDias');

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  useEffect(() => {
    if (!idadeGestacionalSemanas && !idadeGestacionalDias)
      return setValue('dppUs', null);

    const igDias =
      7 * Number(idadeGestacionalSemanas) + Number(idadeGestacionalDias);

    const dppUs = dayjs(dataUs)
      .subtract(igDias, 'days')
      .add(9, 'months')
      .add(7, 'days')
      .toDate();

    setValue('dppUs', dppUs);
  }, [idadeGestacionalSemanas, idadeGestacionalDias, dataUs, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset
        className="p-grid p-col-12 p-px-0"
        disabled={!isAtendimentoRunning}
      >
        <CalendarInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="dataUs"
          label="Data US"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          maxDate={new Date()}
          errorMsg={errors?.dataUs?.message}
        />

        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="liquido"
          label="Líquido"
          maxLength={250}
        />

        <DropdownControlled
          className="p-col-12 p-md-6"
          control={control}
          name="apresentacao"
          label="Apresentação"
          placeholder="Selecione..."
          options={apresentacaoOptions}
          filter={false}
          disabled={!isAtendimentoRunning}
          errorMsg={errors?.apresentacao?.message}
        />

        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="translucenciaNucal"
          label="Translucência nucal"
          maxLength={250}
        />

        <TextInputControlled
          className="p-col-6 p-md-3"
          control={control}
          name="peso"
          label="Peso estimado"
          floatOnly
          maxLength={5}
        />
        <div className="field-center-without-label p-col-6 p-md-3">
          <DropdownControlled
            control={control}
            name="pesoUnidadeMedida"
            options={formatEnum(valoresEnum?.unidadeMedidaUltrassom)}
            filter={false}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="dadosOssoNasal"
          label="Dados do osso nasal"
          maxLength={250}
        />

        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="vesicula"
          label="Vesícula"
          maxLength={250}
        />

        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="descPlacenta"
          label="Desc. placenta"
          maxLength={500}
        />

        <div className="p-col-12 p-md-6">
          <CheckboxControlled
            className="checkbox-small"
            control={control}
            name="doppler"
            label="Doppler"
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-col-12 p-md-6">
          <Divider layout="horizontal" />
        </div>

        <div className="p-grid p-col-12 p-md-6 p-p-0">
          <SimpleText
            className="p-col-12 p-pb-0"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            IG US:
          </SimpleText>

          <TextInputControlled
            className="p-col-6 "
            control={control}
            name="idadeGestacionalSemanas"
            label="Semanas"
            placeholder="Semanas"
            permitirApenasNumeros
            maxLength={2}
            errorMsg={errors?.idadeGestacionalSemanas?.message}
          />
          <TextInputControlled
            className="p-col-6 p-pb-5"
            control={control}
            name="idadeGestacionalDias"
            label="Dias"
            placeholder="Dias"
            type="number"
            maxLength={2}
            errorMsg={errors?.idadeGestacionalDias?.message}
          />
        </div>

        <div className="p-col-12 p-md-6 p-mt-4">
          <CalendarInputControlled
            control={control}
            name="dppUs"
            label="Data DPP US"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
          />

          <CheckboxControlled
            className="checkbox-small"
            control={control}
            name="utilizarDppUsAcompanhamento"
            label="Utilizar DPP US no acompanhamento"
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-col-12 p-mt-3">
          <Button
            type="submit"
            className="p-col-12"
            btnType="tonal"
            label={idUltrassomEditar ? 'Salvar' : 'Adicionar'}
            icon={!idUltrassomEditar ? 'fas fa-plus' : ''}
            loading={isSubmitting}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </fieldset>
    </form>
  );
};

export default Form;
