import { useFormContext } from 'react-hook-form';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import Contato from '../CamposDemograficos/Contato';
import Endereco from '../CamposDemograficos/Endereco';
import Genero from '../CamposDemograficos/Genero';
import Passaporte from '../CamposDemograficos/Passaporte';
import RacaCor from '../CamposDemograficos/RacaCor';
import RegistroGeral from '../CamposDemograficos/RegistroGeral';
import ResponsavelLegal from '../CamposDemograficos/ResponsavelLegal';

const CamposDemograficosObrigatorios = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const watchEstrangeiro = watch('estrangeiro');

  return (
    <>
      <SimpleText
        className="p-col-12 p-mt-5"
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Dados Pessoais
      </SimpleText>

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="nomeSocial"
        label="Nome social/apelido"
        errorMsg={errors.nomeSocial?.message}
      />

      <Genero className="p-col-12 p-sm-6" />

      <RacaCor />

      <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
        Registro geral (RG)
      </SimpleText>

      <RegistroGeral />

      <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
        Responsável legal
      </SimpleText>

      <ResponsavelLegal />

      <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
        Cartão Nacional de Saúde (CNS)
      </SimpleText>

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="cns"
        label="Número"
        maxLength={15}
        permitirApenasNumeros
        errorMsg={errors.cns?.message}
      />

      {watchEstrangeiro && (
        <>
          <SimpleText className="p-col-12 p-mt-3" fontColor={FONT_COLOR.COLOR_60}>
            Passaporte
          </SimpleText>

          <Passaporte />
        </>
      )}

      <SimpleText
        className="p-col-12 p-mt-5"
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Endereço
      </SimpleText>

      <Endereco />

      <SimpleText
        className="p-col-12 p-mt-5"
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Contato
      </SimpleText>
      <Contato />
    </>
  );
}

export default CamposDemograficosObrigatorios;