import Http, { HttpParams } from 'src/core/http/Http';

export const getSaturacao = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `saturacao/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesSaturacao = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'saturacao/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarSaturacao = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'saturacao',
    data: payload,
    return: true,
    ...options,
  });

export const alterarSaturacao = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `saturacao/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarSaturacao = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `saturacao/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerSaturacao = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `saturacao/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const SaturacaoAPI = {
  getSaturacao,
  getUnidadesSaturacao,
  adicionarSaturacao,
  alterarSaturacao,
  inativarSaturacao,
  removerSaturacao,
};

export default SaturacaoAPI;
