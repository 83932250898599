import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { capitalizeFirstLetter, validateSiglaIdade } from 'src/utils/utils';

import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';

type Props = {
  questionario: any;
  setRespostasQuestionario: (value: any) => void;
  setRespostasQuestionarioInicial: (value: any) => void;
  setDisableSalvar: (value: boolean) => void;
};

export const DnpmHeader = ({
  questionario,
  setRespostasQuestionario,
  setRespostasQuestionarioInicial,
  setDisableSalvar,
}: Props) => {
  const [atrasado, setAtrasado] = useState<boolean | null>(null);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { atendimentoStatus } = useAtendimento();

  const changeAtrasado = (value: boolean) => {
    setAtrasado(value);
    setRespostasQuestionario((prevState: any) => ({
      ...prevState,
      atrasado: value,
    }));
    setValue('atrasado', value);
  };

  useEffect(() => {
    if (questionario?.id) {
      setRespostasQuestionarioInicial(questionario);
      setRespostasQuestionario(questionario);
      changeAtrasado(questionario.atrasado);
      setValue('observacao', questionario?.observacao);
      setDisableSalvar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionario]);

  const paciente = questionario?.paciente;

  return (
    <div className="p-w-100 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-md-4 p-pl-0 p-d-flex p-flex-column p-gap-2">
        <SimpleText medium>Informações do paciente</SimpleText>

        <div className="row content-row p-col-12">
          <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Paciente
            </SimpleText>
            <SimpleText>{paciente?.nome}</SimpleText>
          </div>
          <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Idade
            </SimpleText>
            <SimpleText>
              {' '}
              {validateSiglaIdade(paciente?.idadeAnos, paciente?.idadeMeses)}
            </SimpleText>
          </div>
          <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Sexo
            </SimpleText>
            <SimpleText>
              {' '}
              {questionario?.paciente?.sexoDescricao &&
                capitalizeFirstLetter(questionario?.paciente.sexoDescricao)}
            </SimpleText>
          </div>
        </div>
      </div>

      <div className="p-col-4 p-md-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText medium>DNPM</SimpleText>
        <RadioButtonControlled
          name="atrasado"
          label="Atrasado"
          value={true}
          customOnChange={() => changeAtrasado(true)}
          checked={atrasado === true}
          disabled={atendimentoStatus !== 'ATENDENDO'}
          control={control}
          errorMsg={errors.atrasado?.message}
          className="p-mt-1"
        />
        <RadioButtonControlled
          name="atrasado"
          label="Adequado"
          value={false}
          customOnChange={() => changeAtrasado(false)}
          checked={atrasado === false}
          disabled={atendimentoStatus !== 'ATENDENDO'}
          control={control}
          errorMsg={errors.atrasado?.message}
        />
      </div>

      <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText medium>Observações</SimpleText>
        <TextareaInputControlled
          control={control}
          name="observacao"
          rows={4}
          disabled={atendimentoStatus !== 'ATENDENDO'}
        />
      </div>
    </div>
  );
};
