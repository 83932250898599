import React from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const labelRow: Record<string, string> = {
  camposTexto: 'Campos texto',
  solicitacaoExames: 'Solicitação de exames',
  pedidoCuidado: 'Pedido de cuidado',
  motivoAtendimentos: 'Motivo de atendimento',
  impressos: 'Impressos',
};

interface PanelHeaderProps {
  item: any;
  isCollapsed: boolean;
  onToggle: () => void;
  handleEdit: () => void;
  handleRemove: () => void;
}

const PanelHeader = ({
  item,
  handleEdit,
  handleRemove,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  return (
    <div className="row-component p-d-flex p-col-12">
      <div className="p-col-8 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Tipo
        </SimpleText>
        <SimpleText medium>{labelRow[item.tipo] || item.tipo}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-gap-1 p-jc-end">
        <Button icon="fas fa-pencil" btnType="gray" onClick={handleEdit} />
        <Button icon="fas fa-trash" btnType="gray" onClick={handleRemove} />
        <Button
          btnType="ghost"
          icon={!isCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'}
          onClick={onToggle}
          type="button"
        />
      </div>
    </div>
  );
};

export default PanelHeader;
