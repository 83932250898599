import React, { useEffect } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import DropdownTipoPlano from './components/DropdownTipoPlano';
import { Button } from 'src/components/_UI';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import FormInput from 'src/components/FormInput/FormInput';

interface OperadoresProps {
  editHospitalConvenios: any[] | undefined;
}

const Operadores = ({ editHospitalConvenios }: OperadoresProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    fields: hospitalConvenioFields,
    append: appendConvenioField,
    remove: removeConvenioField,
  } = useFieldArray({
    control,
    name: 'hospitalConvenios',
  });

  const handleAddOperadora = () => {
    appendConvenioField({
      convenio: null,
      codigoConvenio: '',
    });
  };

  return (
    <div className="p-grid p-col-12 p-p-0 p-my-5">
      {hospitalConvenioFields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FieldConvenio
            name={`hospitalConvenios.[${index}].convenio`}
            label="Convênio de saúde"
          />

          <FormInput
            className="p-col-12"
            name={`hospitalConvenios.${index}.codigoConvenio`}
            label="Código"
            errorMsg={
              errors.hospitalConvenios?.[index]?.codigoConvenio?.message
            }
          />

          <DropdownTipoPlano
            index={index}
            editHospitalConvenios={editHospitalConvenios}
          />

          <div className="p-col-12 p-p-0 p-mt-2">
            <Button
              btnType="danger-link"
              type="button"
              className="width p-mb-5"
              label="Remover operadora"
              icon="fas fa-minus"
              onClick={() => removeConvenioField(index)}
            />
          </div>
        </React.Fragment>
      ))}

      <div className="p-col-12 p-p-0">
        <Button
          type="button"
          btnType="green-link"
          className="width"
          label="Adicionar operadora"
          icon="fas fa-plus"
          onClick={handleAddOperadora}
        />
      </div>
    </div>
  );
};

export default Operadores;
