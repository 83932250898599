import { useEffect, useState } from 'react';

import { DropdownChangeParams } from 'primereact/dropdown';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';
import { useUserAccessData } from 'src/core/hooks/ManterUsuario/useUserAccessData';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import ImageRadioButton from 'src/components/Basics/ImageRadioButton/ImageRadioButton';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const tiposUsuario = [
  {
    label: 'Secretariado',
    value: 'SECRETARIO',
    acronym: 'Se',
    name: 'secretariado',
  },
  {
    label: 'Profissional de saúde',
    value: 'PROFISSIONAL_SAUDE',
    acronym: 'Ps',
    name: 'medicina',
  },
  {
    label: 'Administrador',
    value: 'ADMINISTRADOR',
    acronym: 'Ad',
    name: 'enfermagem',
  },
];

interface SelectUserProfileProps {
  isUpdate: boolean;
}

interface SelectedProfile {
  label: string;
  value: string;
  acronym: string;
  name: string;
}

function SelectUserProfile({ isUpdate }: SelectUserProfileProps) {
  const { user } = useAppSelector((state: RootState) => state);

  const { isMobile } = useSize();

  const { idUser, setMandatoryData } = useCreateUser();
  const { setAccessStepCompleted } = useUserAccessData();
  const { selectedProfile, selectProfile } = useSelectProfileCreateUser();

  const [userType, setUserType] = useState<any>(tiposUsuario);

  useEffect(() => {
    if (user.tipoUsuario === 'MASTER') {
      const masterUserType = {
        label: 'Master',
        value: 'MASTER',
        acronym: 'Ma',
        name: 'master',
      };

      return setUserType(() => {
        return user?.idEmpresa
          ? [...tiposUsuario, masterUserType]
          : [masterUserType];
      });
    }
  }, [user?.idEmpresa, user.tipoUsuario]);

  function handleSelectProfile(profile: SelectedProfile) {
    if (!isUpdate) {
      selectProfile(profile);
      setAccessStepCompleted(false);
      setMandatoryData(null);
    }
  }

  function handleChangeProfile(e: DropdownChangeParams) {
    const profile = userType.find((user: any) => user.value === e.value);

    handleSelectProfile(profile);
  }

  return (
    <>
      <SimpleText className="p-col-12" bold>
        Tipo de usuário
      </SimpleText>

      {isMobile ? (
        <Dropdown
          className="p-col-12"
          value={selectedProfile?.value}
          options={userType}
          onChange={handleChangeProfile}
          filter={false}
          disabled={!!idUser}
        />
      ) : (
        <ImageRadioButton
          label="Tipo de usuário"
          className="grid p-col-12 p-md-12 dadosacesso"
          options={userType}
          value={selectedProfile as SelectedProfile}
          onPress={handleSelectProfile}
          disabled={!!idUser}
        />
      )}
    </>
  );
}

export default SelectUserProfile;
