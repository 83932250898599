import { Tooltip } from 'primereact/tooltip';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';
import { PontuacaoStatus } from './PontuacaoStatus';
import './Row.scss';

interface RowProps {
  pontuacao: GetPontuacaoCarteiraDTO;
  onDelete: (id: number) => void;
}

export const Row = (props: RowProps) => {
  const { pontuacao } = props;
  const navigate = useNavigate();
  const { isTablet } = useSize();

  const onEdit = (pontuacao: GetPontuacaoCarteiraDTO) => {
    if (pontuacao.id) {
      navigate(`/pacientes/carteira-aps/pontuacao/${pontuacao.id}`);
    }
  };

  const complementMultipleConsultorios = (
    <>
      <Tooltip
        className="pontuacao-consultorios-tooltip"
        autoHide={false}
        target={`.consultorios-tooltip-target${pontuacao.id}`}
        position="top"
        appendTo={document.getElementById('pontuacao-carteira-container')}
      >
        {pontuacao.consultoriosDto
          ?.slice(1)
          .map((consultorio: GetConsultorioDTO) => (
            <div key={consultorio.nome}>{consultorio.nome}</div>
          ))}
      </Tooltip>
      <span className={`p-ml-1 consultorios-tooltip-target${pontuacao.id}`}>
        +{pontuacao?.consultoriosDto?.length - 1}
      </span>
    </>
  );

  const consultoriosPontuacao = (
    <SimpleText
      className="p-col-3 teste"
      fontSize={FONT_SIZE.XXS}
      fontColor={FONT_COLOR.COLOR_40}
    >
      {pontuacao.consultoriosDto?.[0]?.nome}

      {pontuacao.consultoriosDto.length > 1 && complementMultipleConsultorios}
    </SimpleText>
  );

  const pontuacaoStatus = (
    <PontuacaoStatus idPontuacao={pontuacao.id} isAtivo={pontuacao.ativo} />
  );

  const dataInclusao = pontuacao?.dataInclusao
    ? dayjs(new Date(pontuacao?.dataInclusao)).format('DD/MM/YYYY')
    : '';

  const cumonProps = {
    consultoriosPontuacao,
    pontuacaoStatus,
    dataInclusao,
    onEdit,
    ...props,
  };

  return isTablet ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
