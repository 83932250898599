import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldMotivoTransferencia } from 'src/components/Fields/FieldMotivoTransferencia/FieldMotivoTransferencia';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import './AdvancedSearch.scss';

const sexoOptions = [
  {
    label: 'Masculino',
    value: 3,
  },
  {
    label: 'Feminino',
    value: 1,
  },
];

const defaultValues = {
  idMotivoTransferencia: '',
  idSexo: '',
  idadeInicial: '',
  idadeFinal: '',
};

const AdvancedSearch = () => {
  const ref = useRef<OverlayPanel>(null);
  const { setFilter } = useSearchList();
  const useFormMethods = useForm({
    defaultValues,
  });
  const { reset, control, handleSubmit } = useFormMethods;

  const onSubmit = (values: any) => {
    const { motivoTransferencia, ...rest } = values;

    setFilter(prevFilter => ({
      ...prevFilter,
      ...rest,
      idMotivoTransferencia: motivoTransferencia?.id,
    }));

    ref?.current?.hide();
  };

  const handleClean = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      ...defaultValues,
    }));
    reset();

    ref?.current?.hide();
  };

  return (
    <>
      <Button
        className="p-col-12 p-lg-3 p-xl-2"
        label="Pesquisa avançada"
        icon="fas fa-chevron-down"
        type="button"
        btnType="tonal"
        iconPos="right"
        onClick={e => ref?.current?.toggle(e)}
      />

      <OverlayPanel className="filtro-avancado-pacientes-carteira" ref={ref}>
        <FormProvider {...useFormMethods}>
          <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
              Pesquise a carteira de forma detalhada, preenchendo um ou mais dos
              campos abaixo.
            </SimpleText>

            <FieldMotivoTransferencia
              className="p-col-12 p-sm-6"
              label="Motivo da transferência"
            />

            <div className="p-col-12 p-sm-6">
              <DropdownControlled
                control={control}
                name="idSexo"
                label="Sexo"
                placeholder="Selecione"
                options={sexoOptions}
              />
            </div>
            <div className="p-col-12 p-sm-6">
              <FormInput
                name="idadeInicial"
                label="Idade inicial"
                type="number"
                hideTextErrorSpace
              />
            </div>
            <div className="p-col-12 p-sm-6">
              <FormInput
                name="idadeFinal"
                label="Idade final"
                type="number"
                hideTextErrorSpace
              />
            </div>

            <div className="p-d-flex p-col-12 p-ai-center">
              <Button
                type="button"
                label="Limpar"
                btnType="green-link"
                className="p-col"
                onClick={handleClean}
              />
              <Button type="submit" label="Pesquisar" className="p-col-8" />
            </div>
          </form>
        </FormProvider>
      </OverlayPanel>
    </>
  );
};

export default AdvancedSearch;
