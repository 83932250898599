import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldSituacaoProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldSituacao = ({
  name = 'situacao',
  label,
  ...rest
}: FieldSituacaoProps) => {
  const { control } = useFormContext();
  const [situacao, setSituacao] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await AplicacaoFormularioDinamicoAPI.loadAplicacaoStatus(
        { throwError: true },
      );

      setSituacao(response);
    } catch {
      setSituacao([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = situacao.map(item => ({
      label: item?.descricao,
      value: item,
    }));

    return data;
  }, [situacao]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="codigo"
      label={label}
      options={options}
      loading={isLoading}
      filter={false}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
