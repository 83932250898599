import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelReagentesProps {
  isCollapsed: boolean;
  onToggle: () => void;
}

export default function PanelReagentes({
  isCollapsed,
  onToggle,
}: PanelReagentesProps) {
  return (
    <div className="p-d-flex p-jc-between p-ai-center p-px-2">
      <SimpleText medium fontSize={FONT_SIZE.XS}>
        Histórico de Reagentes
      </SimpleText>
      <Button
        icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
        onClick={onToggle}
        btnType="ghost"
      />
    </div>
  );
}
