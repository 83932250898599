import React from 'react';

import Dropzone, { Accept } from 'react-dropzone';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import './InputImagem.scss';

const accept: Accept = {
  'image/jpeg': ['.jpeg', '.png'],
};

interface InputImagemProps {
  setImagem: (file: File | null | undefined) => void;
  imagem: File | null | undefined;
  editImagem?: string;
}

export default function InputImagem({ setImagem, imagem, editImagem }: InputImagemProps) {
  const onDrop = (files: File[]) => {
    setImagem(files[0]);
  };
  const renderChangeButton = (getRootProps:any, getInputProps:any) => (
    <>      
      <Button
        btnType={BtnTypes.GHOST}
        label="Alterar arquivo"
        type="button"
        {...getRootProps()}
      />
      <input {...getInputProps()} />
    </>      
  )
  const renderEditImagem = (getRootProps:any, getInputProps:any) => (
    <>      
      <div className={'p-col-12 p-d-flex p-ai-center'}>
        <div>
          <img alt={'Imagem da notificação'} width={100} src={editImagem} />
        </div>
        {renderChangeButton(getRootProps, getInputProps)}
      </div>
    </>
  );

  const renderPickImagem = (getRootProps:any, getInputProps:any) => (
    <>
      <Button
        btnType={BtnTypes.TONAL}
        label="Escolher arquivo"
        type="button"
        {...getRootProps()}
      />
      <input {...getInputProps()} />
    </>
  );


  const renderImagem = (imagem:File, getRootProps:any, getInputProps:any) => (
    <div className="input-imagem__opcoes">
      <SimpleText className="">
        <i className="fas fa-file"></i>
        {imagem.name}
      </SimpleText>

      {renderChangeButton(getRootProps, getInputProps)}
      <Button
        btnType={BtnTypes.GHOST}
        label="Excluir"
        type="button"
        onClick={() => {
          setImagem(null);
        }}
      />
    </div> 
  );

  const render = ({ getRootProps, getInputProps }:any) => {
    if (imagem) return renderImagem(imagem, getRootProps, getInputProps);
    if (editImagem) return renderEditImagem(getRootProps, getInputProps);
    return renderPickImagem(getRootProps, getInputProps);
  }

  return (
    <div className="Input-imagem">
      <Dropzone onDrop={onDrop} accept={accept} maxFiles={1}>
        {render}
      </Dropzone>
    </div>
  );
}
