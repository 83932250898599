import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  migracao: any;
  onReverter: (migracao: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { migracao, onReverter } = props;
  const { isTablet } = useSize();

  return (
    <div className="p-grid p-col-12 p-px-0 p-ai-center">
      <div
        className="p-col-2 p-md-1"
        title={dayjs(new Date(migracao?.dataOcorrencia)).format(
          'DD/MM/YYYY HH:mm',
        )}
      >
        <SimpleText
          className="p-text-climp-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {dayjs(new Date(migracao?.dataOcorrencia)).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-2 p-text-truncate" title={migracao?.login}>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {migracao?.login}
        </SimpleText>
      </div>
      {!isTablet && (
        <div className="p-col-1">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {migracao?.idOrigem}
          </SimpleText>
        </div>
      )}
      {!isTablet && (
        <div className="p-col-1">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {migracao?.idDestino}
          </SimpleText>
        </div>
      )}
      <div className="p-col-2 p-px-0 p-d-flex p-ai-center p-text-truncate">
        {!isTablet && (
          <Avatar
            className="avatar p-mr-2"
            label={migracao?.nomePacienteOrigem}
            image={migracao?.fotoUrlPacienteOrigem || ''}
          />
        )}
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {migracao?.nomePacienteOrigem}
        </SimpleText>
      </div>
      <div className="p-col-1">
        <IconButton
          icon="fas fa-angles-right"
          btnType={IconBtnTypes.ICON_ONLY}
        />
      </div>
      <div className="p-col-2 p-px-0 p-md-2 p-d-flex p-ai-center p-text-truncate">
        {!isTablet && (
          <Avatar
            className="p-mr-2"
            label={migracao?.nomePacienteDestino}
            image={migracao?.fotoUrlPacienteDestino || ''}
          />
        )}
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {migracao?.nomePacienteDestino}
        </SimpleText>
      </div>
      <div className="p-col-3 p-md-2 p-d-flex p-ai-center p-jc-center">
        <SimpleText
          className={`p-mt-1 p-py-1 p-px-2 circle-${migracao?.status}`}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {migracao.status}
        </SimpleText>
        {migracao.status === 'MIGRADO' && (
          <Button
            style={{ minWidth: '2rem' }}
            btnType="gray"
            icon="fas fa-rotate-left"
            onClick={() => onReverter(migracao)}
            className="p-ml-2"
          />
        )}
      </div>
    </div>
  );
};
