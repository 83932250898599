import { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { PlanoCuidadoAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoAPI/PlanoCuidadoAPI';
import { LoadPlanoCuidadoByIdProps } from 'src/models/APIs/ProntuarioAPI/PlanoCuidado/PlanoCuidado';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import { usePlanoDeCuidado } from 'src/pages/Emed/Prontuario/PlanoDeCuidado/hooks/PlanosDeCuidadoContext';

type IProps = DropdownProps & {
  name?: string;
  label: string;
  returnType?: 'all' | 'id';
};

export const FieldPlanosCuidado = ({
  name = 'planoCuidado',
  label,
  returnType = 'all',
  ...rest
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { idConvenioAtendimento } = useAtendimento();
  const { idConvenio } = usePlanoDeCuidado();

  const [items, setItems] = useState<LoadPlanoCuidadoByIdProps[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const loadAPI = useCallback(async () => {
    try {
      setLoadingSearch(true);

      const response = await PlanoCuidadoAPI.loadPlanosCuidadoLista(
        {
          status: 'ATIVO',
          idConvenio: idConvenio || idConvenioAtendimento,
        },
        { throwError: true },
      );

      if (Array.isArray(response)) setItems(response);
    } catch {
      setItems([]);
    } finally {
      setLoadingSearch(false);
    }
  }, [idConvenio, idConvenioAtendimento]);

  useEffect(() => {
    loadAPI();
  }, [loadAPI]);

  const options = items.map(item => ({
    label: item.nome,
    value: item,
  }));

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      loading={loadingSearch}
      filterPlaceholder="Digite para pesquisar"
      options={options}
      errorMsg={errors[name]?.message}
      {...rest}
    />
  );
};
