import { ReactNode, useState } from 'react';

import { OptionalDataContext, Address, Contact } from '../../contexts/ManterUsuario/OptionalData';

interface OptionalDataProviderProps {
  children: ReactNode;
}

export function OptionalDataProvider({ children }: OptionalDataProviderProps) {
  const [addressData, setAddressData] = useState<Address | null>(null);
  const [contactData, setContactData] = useState<Contact[] | null>(null);

  function saveAddress(address: Address) {
    setAddressData(address);
  }

  function saveContact(contact: Contact[]) {
    setContactData(contact);
  }

  return (
    <OptionalDataContext.Provider
      value={{
        addressData,
        contactData,
        saveAddress,
        saveContact,
      }}
    >
      {children}
    </OptionalDataContext.Provider>
  );
}
