import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PacoteAPI } from 'src/APIs/AdminAPI/PacoteAPI/PacoteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Page from 'src/components/Basics/Page/Page';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import { Disponibilidade } from './Disponibilidade/Disponibilidade';
import { Procedimentos } from './Procedimentos/Procedimentos';
import { validationSchema } from './validationSchema';

const tabValues = [
  {
    label: 'Procedimentos',
    content: <Procedimentos />,
  },
  {
    label: 'Disponibilidade',
    content: <Disponibilidade />,
  },
];

type FormValues = {
  convenio: Convenio;
  codigoPacote: string;
  nomePacote: string;
  servicoTussPrincipal: number | null;
  bloquearEdicaoPacote: boolean;
  trafegarProcedimentos: boolean;
  servicosTuss: any[];
  especialidades: any[];
  profissionaisSaude: any[];
};

export const FormPacote = () => {
  const { user } = useAppSelector((state: RootState) => state);

  const { idPacote } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [pacoteEdit, setPacoteEdit] = useState<any>(null);

  const defaultValues = useMemo(() => {
    const servicosTuss =
      pacoteEdit?.servicosTuss.map((servicoTuss: any) => ({
        codigo: servicoTuss.servicoTuss.codigo,
        nome: servicoTuss.servicoTuss.nome,
        idServicoTuss: servicoTuss.servicoTuss.id,
        quantidade: servicoTuss.quantidade,
        quantidadeMaxima: servicoTuss.servicoTuss?.quantidadeMaxima,
        servicoTussPrincipal: servicoTuss.servicoTussPrincipal,
      })) || [];

    const especialidades =
      pacoteEdit?.especialidades.map(({ especialidade }: any) => ({
        nome: especialidade.nome,
        idEspecialidade: especialidade.id,
      })) || [];

    const profissionaisSaude =
      pacoteEdit?.profissionaisSaude.map((profissionalSaude: any) => ({
        idProfissionalSaude: profissionalSaude.idProfissionalSaude,
        nome: profissionalSaude.nomeProfissional,
        siglaConselhoTiss:
          profissionalSaude.profissionalSaude.conselhoTiss.sigla,
        numeroRegistro: profissionalSaude.profissionalSaude.numeroRegistro,
      })) || [];

    const servicoTussPrincipal =
      servicosTuss?.filter(
        (servicoTuss: any) => servicoTuss.servicoTussPrincipal,
      )[0]?.idServicoTuss || null;

    return {
      convenio: pacoteEdit?.convenio || null,
      codigoPacote: pacoteEdit?.codigoPacote || '',
      nomePacote: pacoteEdit?.nomePacote || '',
      bloquearEdicaoPacote: pacoteEdit?.bloquearEdicaoPacote || false,
      trafegarProcedimentos: pacoteEdit?.trafegarProcedimentos || false,
      servicoTussPrincipal,
      servicosTuss,
      especialidades,
      profissionaisSaude,
    };
  }, [pacoteEdit]);

  const handleGoBack = useCallback(
    () => navigate('/config/config-tuss/pacote', { replace: true }),
    [navigate],
  );

  useEffect(() => {
    const fetchPacote = async () => {
      if (!idPacote) return;

      try {
        setLoading(true);

        const response = await PacoteAPI.getPacoteById(Number(idPacote));

        setPacoteEdit(response);
      } catch (error) {
        handleGoBack();
      } finally {
        setLoading(false);
      }
    };

    fetchPacote();
  }, [handleGoBack, idPacote]);

  const useFormMethods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors, isSubmitting },
    control,
    reset,
    handleSubmit,
  } = useFormMethods;

  useEffectSkipFirst(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (data: FormValues) => {
    try {
      const { convenio, servicosTuss, servicoTussPrincipal, ...rest } = data;

      const servicoTussFormatted = servicosTuss.map(servicoTuss => {
        if (servicoTuss.idServicoTuss !== servicoTussPrincipal)
          return servicoTuss;

        return {
          ...servicoTuss,
          servicoTussPrincipal: true,
        };
      });

      const payload = {
        ...rest,
        servicosTuss: servicoTussFormatted,
        idConvenio: convenio.id,
      };

      const savePacote = idPacote ? PacoteAPI.putPacote : PacoteAPI.postPacote;

      await savePacote(payload, Number(idPacote));

      handleGoBack();
    } catch (error) {}
  };

  const pageTitle = idPacote ? 'Editar Pacote' : 'Novo pacote';

  if (!user?.idEmpresa)
    return (
      <PagePacote withOutContentContainer={false}>
        <div className="p-px-5">
          <AlertBox
            visible={true}
            text="Você precisa selecionar um Cliente para esta funcionalidade!"
          />
        </div>
      </PagePacote>
    );

  if (loading)
    return (
      <PagePacote pageTitle={pageTitle}>
        <GridListLoading />
      </PagePacote>
    );

  return (
    <PagePacote pageTitle={pageTitle}>
      <FormProvider {...useFormMethods}>
        <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
          <FieldConvenio className="p-col-12 p-sm-6 p-lg-4" label="Convênio" />

          <div className="p-col-12 p-grid p-px-0">
            <TextInputControlled
              className="p-col-4 p-sm-3 p-lg-2"
              control={control}
              name="codigoPacote"
              label="Código do pacote"
              maxLength={10}
              errorMsg={errors.codigoPacote?.message}
            />

            <TextInputControlled
              className="p-col-8 p-sm-7 p-lg-6"
              control={control}
              name="nomePacote"
              label="Nome do pacote"
              maxLength={50}
              errorMsg={errors.nomePacote?.message}
            />
          </div>

          <div className="p-col-12 column">
            <CheckboxControlled
              control={control}
              name="bloquearEdicaoPacote"
              label="Bloquear edições no pacote"
            />

            <CheckboxControlled
              control={control}
              name="trafegarProcedimentos"
              label="Trafega pacote pelo código dos procedimentos"
            />
          </div>

          <Tab clean values={tabValues} />

          <div className="p-col-2">
            <Button
              btnType="ghost"
              label="Cancelar"
              loading={isSubmitting}
              onClick={handleGoBack}
              stretch
            />
          </div>
          <div className="p-col-4">
            <Button
              label="Salvar"
              type="submit"
              loading={isSubmitting}
              stretch
            />
          </div>
        </form>
      </FormProvider>
    </PagePacote>
  );
};

const PagePacote = ({
  children,
  pageTitle,
  withOutContentContainer = true,
}: any) => (
  <Page>
    <>
      <PageHeader title={pageTitle} subtitle="" />

      {withOutContentContainer ? (
        <Page white content>
          {children}
        </Page>
      ) : (
        children
      )}
    </>
  </Page>
);
