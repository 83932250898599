import React from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

import StatusCentroCusto from './StatusCentroCusto';

interface DesktopRowProps {
  centroCusto: any;
  items: any;
  menuRef: any;
  onSwitchStatus: () => void;
  loadingStatus: boolean;
  ativo: boolean;
}

const DesktopRow = ({
  centroCusto,
  items,
  menuRef,
  loadingStatus,
  ativo,
  onSwitchStatus,
}: DesktopRowProps) => {
  return (
    <div className="p-grid p-flex-1 p-p-1">
      <div className="p-col-6 p-sm-4 p-md-2 ">
        <SimpleText fontSize={FONT_SIZE.XS}>
          {centroCusto.consultorio?.nome || ''}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 ">
        <SimpleText fontSize={FONT_SIZE.XS}>{centroCusto.id}</SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 ">
        <SimpleText fontSize={FONT_SIZE.XS}>{centroCusto.nome}</SimpleText>
      </div>
      <div className="p-col-12 p-sm-4 ">
        <SimpleText fontSize={FONT_SIZE.XS}>{centroCusto.descricao}</SimpleText>
      </div>

      <div className="p-col-6 p-sm-4 p-md-2 p-d-flex p-flex-1 p-ai-end p-jc-end">
        <div className="p-d-flex p-ai-center">
          <>
            <StatusCentroCusto
              onSwitchStatus={onSwitchStatus}
              loadingStatus={loadingStatus}
              ativo={ativo}
            />
            <Separator layout="vertical" />
          </>
          <Button
            className="p-mr-1"
            btnType="gray"
            icon="fas fa-ellipsis-h"
            onClick={event => menuRef?.current?.toggle(event)}
          />

          <TieredMenu model={items} popup ref={menuRef} />
        </div>
      </div>
    </div>
  );
};

export default DesktopRow;
