import React, { useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  historico: any;
  onEdit: () => void;
  onCopiar: () => void;
  onInativar: () => void;
  onDelete: () => void;
  isRetroativo: boolean;
  canEdit: boolean;
  isInativo: boolean;
  historicoDialogDisclosure: any;
}

type EllipsisMenu = TieredMenu & {
  hide: () => void;
};

const DesktopRow = ({
  historico,
  onEdit,
  onCopiar,
  onInativar,
  onDelete,
  isRetroativo,
  canEdit,
  isInativo,
  historicoDialogDisclosure,
}: DesktopRowProps) => {
  const menuEllipsis = useRef<EllipsisMenu>(null);

  const menuEllipsisItens: MenuItem[] = [
    {
      label: canEdit ? 'Excluir' : 'Inativar',
      className: 'warning-color',
      command: canEdit ? onDelete : onInativar,
    },
  ];

  return (
    <div className="p-grid p-px-0 p-my-2 card-item">
      <div className="p-col-12 p-p-1 p-d-flex p-ai-center p-jc-between">
        <SimpleText
          className={
            isInativo
              ? 'line-through p-px-3 p-py-2'
              : 'instrumento p-px-3 p-py-2'
          }
          onClick={() =>
            !isInativo
              ? historicoDialogDisclosure.open({ state: historico })
              : null
          }
          disabled={isInativo}
        >
          {historico.formulario}
        </SimpleText>

        <div className="p-d-flex p-ai-center p-jc-end">
          {isRetroativo && (
            <SimpleText className="tag tag-retroativo" fontSize="xxxs">
              Retroativo
            </SimpleText>
          )}
          {canEdit ? (
            <Button
              icon="pi pi-pencil"
              btnType="gray"
              className="p-mx-1"
              onClick={onEdit}
            />
          ) : (
            <Button
              icon="fas fa-copy"
              btnType="gray"
              className="p-mx-1"
              onClick={onCopiar}
            />
          )}
          {isInativo ? (
            <Button icon="fas fa-eye" btnType="gray" onClick={onInativar} />
          ) : (
            <Button
              icon="fas fa-ellipsis-h"
              btnType="gray"
              onClick={event => menuEllipsis?.current?.toggle(event)}
            />
          )}
          <TieredMenu ref={menuEllipsis} model={menuEllipsisItens} popup />
        </div>
      </div>
      <div className="p-col-12 p-grid p-px-3 p-pb-2">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XXS}
          className={isInativo ? 'line-through' : ''}
        >
          Preenchido por{' '}
          <strong className={isInativo ? '' : 'neutral-color'}>
            {historico.medico?.nome}
          </strong>
          {' • '}
          <span className={isInativo ? '' : 'neutral-color'}>
            {' '}
            {historico.medico?.conselhoTiss}/{historico.medico?.estadoConselho}{' '}
            {historico.medico?.numeroConselho}{' '}
          </span>
          em{' '}
          <span className={isInativo ? '' : 'neutral-color'}>
            {' '}
            {historico.dataOcorrencia
              ? dayjs(historico.dataOcorrencia).format('DD/MM/YYYY [às] HH:mm')
              : ''}{' '}
          </span>
        </SimpleText>
        {isRetroativo && (
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className={`p-col-12 p-px-0 ${isInativo ? 'line-through' : ''}`}
          >
            {' '}
            Registro retroativo em{' '}
            <span className={isInativo ? '' : 'neutral-color'}>
              {' '}
              {historico.dataOcorrencia
                ? dayjs(historico.dataOcorrencia).format(
                    'DD/MM/YYYY [às] HH:mm',
                  )
                : ''}{' '}
            </span>
          </SimpleText>
        )}
      </div>
      {historico.justificativaRetroativo && (
        <div className="p-grid p-px-3 p-pb-2">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className={`p-col-12 p-px-0 ${isInativo ? 'line-through' : ''}`}
          >
            Justificativa
          </SimpleText>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
            className={isInativo ? 'line-through' : ''}
          >
            {historico.justificativaRetroativo}
          </SimpleText>
        </div>
      )}
    </div>
  );
};

export default DesktopRow;
