import { useRef, useMemo, useEffect } from 'react';

import { useLocation } from 'react-router';

import { ManterConvenioProvider } from 'src/core/containers/ManterConvenio/ManterConvenioProvider';
import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import { ConfigTiss } from './ConfigTiss/ConfigTiss';
import { FormAdditionalInfo } from './FormAdditionalInfo/FormAdditionalInfo';
import { FormBasicInfo } from './FormBasicInfo/FormBasicInfo';

import './FormConvenio.scss';

export const FormContainer = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const { idConvenioEdit, basicInfoStepData, loadingConvenioData } =
    useManterConvenio();

  const timelineRef = useRef<TimelineImperativeAPI>(null);

  const goToNextStep = () => {
    timelineRef?.current?.next?.();
  };

  useEffect(() => {
    if (state?.toStep) {
      timelineRef?.current?.goToStep?.(state?.toStep);
    }
  }, [state?.toStep]);

  const steps = useMemo(
    (): TimelineValue[] => [
      {
        title: 'Informações básicas',
        component: <FormBasicInfo goToNextStep={goToNextStep} />,
      },
      {
        title: 'Informações complementares',
        component: <FormAdditionalInfo goToNextStep={goToNextStep} />,
        disabled: !basicInfoStepData,
      },
      {
        title: 'Integrações',
        component: <ConfigTiss />,
        disabled: idConvenioEdit === null,
      },
    ],
    [basicInfoStepData, idConvenioEdit],
  );

  const pageTitle =
    idConvenioEdit !== null
      ? 'Edição de convênio'
      : 'Novo registro de convênio';

  return (
    <Page id="form-convenio-container">
      <>
        <PageHeader
          title={pageTitle}
          subtitle="Preencha os campo para completar o cadastro"
        />

        {!user?.idEmpresa ? (
          <div className="p-px-5">
            <AlertBox
              visible={true}
              text="Você precisa selecionar um Cliente para esta funcionalidade!"
            />
          </div>
        ) : (
          <Page content white>
            <Timeline
              values={steps}
              loading={loadingConvenioData}
              ref={timelineRef}
            />
          </Page>
        )}
      </>
    </Page>
  );
};

export const FormConvenio = () => (
  <ManterConvenioProvider>
    <FormContainer />
  </ManterConvenioProvider>
);
