import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  pacote: any;
  onSwitchStatus: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { pacote, onSwitchStatus, onEdit, onDelete } = props;

  return (
    <CardItem>
      <SimpleText
        className="p-col-2 p-lg-1"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XXS}
        medium
      >
        {pacote?.codigoPacote}
      </SimpleText>

      <SimpleText
        className="p-col"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XXS}
      >
        {pacote.nomePacote}
      </SimpleText>

      <SimpleText
        className="p-col-3"
        fontColor={FONT_COLOR.COLOR_40}
        fontSize={FONT_SIZE.XXS}
      >
        {pacote.convenio?.nomeFantasia || pacote.convenio.razaoSocial}
      </SimpleText>

      <Switch
        className="p-col-2 p-px-0 p-md-1"
        checked={pacote.status}
        onChange={onSwitchStatus}
        checkPermission="ADM_PACOTES_ALTERAR"
      />

      <div className="p-col-1 p-px-0 p-d-flex p-jc-evenly">
        <Button
          btnType="gray"
          icon="pi pi-pencil"
          onClick={onEdit}
          checkPermission="ADM_PACOTES_ALTERAR"
        />
        <Button
          className="p-ml-3"
          icon="pi pi-trash"
          btnType="gray"
          onClick={onDelete}
          checkPermission="ADM_PACOTES_EXCLUIR"
        />
      </div>
    </CardItem>
  );
};
