import { memo, useMemo } from 'react';

import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { DialogOrientacoes } from '../AGA/QuestionarioAGA/components/DialogOrientacoes/DialogOrientacoes';
import { Button, Checkbox } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import { FormularioPerguntasItem } from './FormulariosContent';

export const FormulariosContentQuestion = ({
  field,
}: {
  field: FormularioPerguntasItem;
}) => {
  const dialogOrientacao = useDisclosure({ opened: false });

  const form = useFormContext();

  const hasOrientacao = !!field.orientacao;
  const valuePerguntaVinculada = useWatch({
    name: `respostas.${field.idPerguntaVinculada}`,
    control: form.control,
  });

  const isPerguntaVisible =
    field.idPerguntaVinculada &&
    field.idOpcaoPerguntaVinculada &&
    valuePerguntaVinculada?.id === field.idOpcaoPerguntaVinculada;

  useEffectSkipFirst(() => {
    if (!isPerguntaVisible) {
      form.setValue(
        `respostas.${field.idPerguntaFormularioDinamico}`,
        field.tipoResposta === 'TEXTO' ? '' : undefined,
      );
    }
  }, [isPerguntaVisible]);

  if (isPerguntaVisible === false) return null;

  return (
    <div className="row content-row white p-p-2 p-flex-column">
      <div className="p-d-flex p-ai-center">
        <SimpleText className="p-px-2" fontSize="sm" medium>
          {field.enunciado}
        </SimpleText>

        {hasOrientacao && (
          <Button
            icon="fas fa-info-circle"
            btnType="ghost"
            onClick={() =>
              dialogOrientacao.open({
                state: {
                  text: field.orientacao,
                },
              })
            }
          />
        )}
      </div>

      <Divider layout="horizontal" className="p-my-2" />

      <PerguntasFieldRender field={field} />

      {dialogOrientacao.isOpen && <DialogOrientacoes {...dialogOrientacao} />}
    </div>
  );
};

const PerguntasFieldRender = memo(
  ({ field }: { field: FormularioPerguntasItem }) => {
    const { control, setValue } = useFormContext();
    const { errors } = useFormState({ control });

    const questionType = useMemo(
      () => field.tipoResposta,
      [field.tipoResposta],
    );

    const hasAditionAnswer = useWatch({
      name: `respostas.${field.idPerguntaFormularioDinamico}_adicional`,
      control,
    });
    const values = useWatch({
      name: `respostas.${field.idPerguntaFormularioDinamico}`,
      control,
    });

    const errorMsg =
      errors?.respostas?.[field.idPerguntaFormularioDinamico]?.message;

    //* Only MULTIPLA_ESCOLHA
    const handleCheck = (
      e: { id: number; value: boolean },
      fieldName: string,
    ) => {
      if (!e.value) {
        setValue(
          fieldName,
          values.filter((v: typeof e) => v.id !== e.id),
        );
      } else {
        setValue(fieldName, [...(values || []), e]);
      }
    };

    return (
      <>
        {['TEXTO'].includes(questionType) && (
          <TextareaInputControlled
            control={control}
            name={`respostas.${field.idPerguntaFormularioDinamico}`}
            maxLength={2000}
          />
        )}
        {['NUMERO'].includes(questionType) && (
          <FormInput
            name={`respostas.${field.idPerguntaFormularioDinamico}`}
            type="number"
            min={field.valorMinimo || 0}
            max={field.valorMaximo || 100}
            className="p-col-12 p-md-6 p-lg-3"
          />
        )}
        {['DATA'].includes(questionType) && (
          <CalendarInputControlled
            control={control}
            name={`respostas.${field.idPerguntaFormularioDinamico}`}
            showIcon
            showTime={false}
            className="p-col-12 p-md-6 p-lg-3"
            dateFormat={field.formatoData || 'dd/mm/yy'}
          />
        )}
        {['ESCOLHA'].includes(questionType) && (
          <RadioButtonGroupControlled
            control={control}
            name={`respostas.${field.idPerguntaFormularioDinamico}`}
            customOnChange={() =>
              hasAditionAnswer
                ? setValue(
                    `respostas.${field.idPerguntaFormularioDinamico}_adicional`,
                    undefined,
                  )
                : null
            }
            dir="column"
            unstyled
            items={field.opcoes!.map(opcao => ({
              label: opcao.descricao,
              value: opcao,
            }))}
          />
        )}
        {['MULTIPLA_ESCOLHA'].includes(questionType) && (
          <>
            {field.opcoes?.map(opcao => (
              <Checkbox
                key={opcao.id}
                checked={!!values?.find((v: any) => v?.id === opcao.id)}
                onChange={e =>
                  handleCheck(
                    {
                      id: Number(opcao.id),
                      value: e.checked,
                    },
                    `respostas.${field.idPerguntaFormularioDinamico}`,
                  )
                }
                label={opcao.descricao}
                className="p-p-2"
              />
            ))}

            {!!errorMsg && (
              <SimpleText
                fontSize="xxxs"
                fontColor="danger"
                className="p-my-2"
                medium
              >
                {errorMsg}
              </SimpleText>
            )}
          </>
        )}

        {field.respostaAdicional &&
          field.tipoRespostaAdicional === 'COMENTARIO' && (
            <div className="p-px-2 p-d-flex p-flex-column p-gap-2">
              <SimpleText htmlFor="respostaAdicional">
                {field.textoRespostaAdicional}
              </SimpleText>
              {['TEXTO'].includes(field.tipoValidacaoRespostaAdicional!) && (
                <TextareaInputControlled
                  id="respostaAdicional"
                  control={control}
                  name={`respostas.${field.idPerguntaFormularioDinamico}_comentario`}
                  onCustomChange={e =>
                    field.tipoResposta === 'ESCOLHA' &&
                    setValue(
                      `respostas.${field.idPerguntaFormularioDinamico}`,
                      e,
                    )
                  }
                  maxLength={2000}
                />
              )}
            </div>
          )}

        {field.respostaAdicional &&
          field.tipoRespostaAdicional === 'RESPOSTA' && (
            <div className="p-px-2 p-d-flex p-flex-column p-gap-2">
              <SimpleText htmlFor="respostaAdicional">
                {field.textoRespostaAdicional}
              </SimpleText>
              {['TEXTO'].includes(field.tipoValidacaoRespostaAdicional!) && (
                <TextareaInputControlled
                  id="respostaAdicional"
                  control={control}
                  name={`respostas.${field.idPerguntaFormularioDinamico}_adicional`}
                  onCustomChange={e =>
                    field.tipoResposta === 'ESCOLHA' &&
                    setValue(
                      `respostas.${field.idPerguntaFormularioDinamico}`,
                      e,
                    )
                  }
                  maxLength={2000}
                />
              )}
              {['NUMERO'].includes(field.tipoValidacaoRespostaAdicional!) && (
                <FormInput
                  id="respostaAdicional"
                  name={`respostas.${field.idPerguntaFormularioDinamico}_adicional`}
                  type="number"
                  min={0}
                  max={2147483647}
                  className="p-col-12 p-md-6 p-lg-3"
                  onChange={e =>
                    field.tipoResposta === 'ESCOLHA' &&
                    setValue(
                      `respostas.${field.idPerguntaFormularioDinamico}`,
                      e.target.value,
                    )
                  }
                />
              )}
            </div>
          )}
      </>
    );
  },
);
