import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import AvaliacaoRiscoAPI from 'src/APIs/ProntuarioAPI/AvaliacaoRisco/AvaliacaoRiscoAPI';
import PressaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PressaoAPI/PressaoAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import {
  colesterolWithLabel,
  corRiscoCardiaco,
  faixaEtariaWithLabel,
  sexoWithLabel,
  removeComparisonAndGetNumber,
  valueFaixaEtaria,
} from 'src/utils/escoreFraminghan';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import PorcentagemRisco from '../components/PorcentagemRisco/PorcentagemRisco';
import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import './ModalNovaAvaliacaoRisco.scss';

interface Props extends DisclosureType {
  editAvaliacao?: any;
  paciente: any | null;
  onSuccess: () => void;
  setEditAvaliacao: (editAvaliacao: any) => void;
}

export default function ModalNovaAvaliacaoRisco({
  editAvaliacao,
  paciente,
  isOpen,
  close,
  onSuccess,
  setEditAvaliacao,
}: Props) {
  const { user } = useAppSelector((state: RootState) => state);
  const { idUsuario, idEmpresa } = user;

  const [selectedUnidadeMedida, setSelectedUnidadeMedida] = useState({});
  const [unidadesMedida, setUnidadesMedida] = useState<any[]>([]);
  const [faixaIdades, setFaixaIdades] = useState<any[]>([]);
  const [sexoOptions, setSexoOptions] = useState<any[]>([]);
  const [LDLOptions, setLDLOptions] = useState<any[]>([]);
  const [HDLOptions, setHDLOptions] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [riscoCardiaco, setRiscoCardiaco] = useState<number>();

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus } = useAtendimento();
  const { isTablet, isMobile } = useSize();

  const ultimoCalculo = useRef<FormDataAvaliacaoRisco>();

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    idade: Yup.number().required('Informe a idade'),
    idSexo: Yup.number().required('Informe o sexo'),
    sistolica: Yup.string().required('Obrigatório'),
    diastolica: Yup.string().required('Obrigatório'),
    unidadeMedida: Yup.string().required('Obrigatório'),
    ldlColesterol: Yup.number().required('Informe o Colesterol LDL'),
    hdlColesterol: Yup.number().required('Informe o Colesterol HDL'),
    diabetes: Yup.bool().required('Informe diabetes'),
    tabagismo: Yup.bool().required('Informe tabagismo'),
    justificativaOcorrenciaRetroativa: Yup.string().when('dataOcorrencia', {
      is: (v: any) => new Date(v).getUTCDate() !== new Date().getUTCDate(),
      then: Yup.string().required('Campo obrigatório'),
    }),
  });

  const useFormMethods = useForm<FormDataAvaliacaoRisco>({
    defaultValues: {
      idAtendimento: Number.parseFloat(idAtendimento || ''),
      idUsuarioAlteracao: idUsuario,
      idEmpresa: idEmpresa,
      idSexo: paciente.idSexo,
      dataOcorrencia: editAvaliacao?.dataOcorrencia || new Date(),

      diastolica: editAvaliacao?.diastolicaInformadaPa,
      sistolica: editAvaliacao?.sistolicaInformadaPa,
      hdlColesterol: editAvaliacao?.escoreFraminghanHdl.id || null,
      ldlColesterol: editAvaliacao?.escoreFraminghanLdl.id || null,
      unidadeMedida: editAvaliacao?.unidadeMedidaInformadaPa?.codigo,
      justificativaOcorrenciaRetroativa:
        editAvaliacao?.justificativaOcorrenciaRetroativa || '',
      ...(editAvaliacao?.tabagismo !== undefined && {
        tabagismo: editAvaliacao?.tabagismo,
      }),
      ...(editAvaliacao?.diabetes !== undefined && {
        diabetes: editAvaliacao?.diabetes,
      }),
    },
    resolver: yupResolver(validatioSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useFormMethods;

  const value = useWatch({ control });

  useEffect(() => {
    AvaliacaoRiscoAPI.loadParametros(paciente.idSexo).then(response => {
      const [faixaIdades, ldl, hdl] = response;

      setFaixaIdades(faixaIdades.map(faixaEtariaWithLabel));
      setValue('idade', valueFaixaEtaria(paciente?.idadeAnos, faixaIdades));

      setLDLOptions(ldl.map(colesterolWithLabel));
      setHDLOptions(hdl.map(colesterolWithLabel));
    });

    UtilsAPI.GetSexos().then(response => {
      setSexoOptions(response?.map(sexoWithLabel));
      setValue('idSexo', paciente.idSexo);
    });
  }, [paciente, setValue]);

  useEffect(() => {
    PressaoAPI.getUnidadesPressao().then(unidades => {
      setUnidadesMedida(unidades.unidadeMedida);

      const sortBy = 'dataOcorrencia';
      const sortDirection = 'DESC';

      PressaoAPI.getPressao({ idAtendimento, sortBy, sortDirection }).then(
        response => {
          if (response?.list[0]) {
            const { sistolica, diastolica, unidadeMedida } = response?.list[0];
            if (!editAvaliacao) {
              setValue('sistolica', sistolica);
              setValue('diastolica', diastolica);
              setValue('unidadeMedida', unidadeMedida.codigo);
            }

            return;
          }
        },
      );

      if (!editAvaliacao) {
        setValue('unidadeMedida', unidades.unidadePreferencial.codigo);
      }
    });
  }, [editAvaliacao, idAtendimento, setValue]);

  const onTipoChange = (e: any) => {
    setSelectedUnidadeMedida(e.value);
  };

  const onUnidadeChange = (e: any) => {
    const values = getValues(['sistolica', 'diastolica', 'unidadeMedida']);
    const [_sistolica, _diastolica, unidadeMedida] = values;

    const sistolica = Number.parseFloat(_sistolica);
    const diastolica = Number.parseFloat(_diastolica);

    if (unidadeMedida === 'MMHG') {
      if (sistolica) setValue('sistolica', (sistolica * 10).toString());
      if (diastolica) setValue('diastolica', (diastolica * 10).toString());
    }
    if (unidadeMedida === 'CMHG') {
      if (sistolica) setValue('sistolica', (sistolica / 10).toString());
      if (diastolica) setValue('diastolica', (diastolica / 10).toString());
    }
  };
  const checkDisabledOnEdit = useMemo(() => {
    if (paciente?.idadeAnos < 30 || paciente?.idadeAnos > 74) {
      return true;
    }

    if (!editAvaliacao) return false;

    return !(
      editAvaliacao.idAtendimento === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [atendimentoStatus, editAvaliacao, idAtendimento, paciente?.idadeAnos]);

  const colesterolClassName = useMemo(() => {
    if (isMobile || isTablet) return 'p-col-12 p-p-0 p-my-0';
    return 'p-col p-pt-0';
  }, [isMobile, isTablet]);

  const calcularRisco = useCallback(
    async (data: FormDataAvaliacaoRisco) => {
      if (isEqual(ultimoCalculo.current, data)) return;

      ultimoCalculo.current = data;

      data.unidadeMedidaPa = data.unidadeMedida;

      if (editAvaliacao) {
        data.dataOcorrencia = new Date(editAvaliacao.dataOcorrencia);
      }

      if (!data.unidadeMedidaPa || !data.idade) return;

      setLoading(true);
      try {
        const response = await AvaliacaoRiscoAPI.loadCalculoRisco(data);
        if (response) {
          setRiscoCardiaco(response.porcentagemRisco);
        }
      } catch (e) {
      } finally {
        setLoading(false);
        if (isEqual(ultimoCalculo.current, data)) return;
      }
    },
    [editAvaliacao],
  );

  useEffect(() => {
    const keys = Object.keys(value);
    const values = keys.map(k => value[k as keyof FormDataAvaliacaoRisco]);
    if (!keys || keys.length === 0) return;

    const valid = values.filter(i => i === undefined).length === 0;
    if (!valid) return;

    // Verifica se existe campos `sistolica` e `diastolica`
    const { sistolica, diastolica } = value;
    if (!sistolica || !diastolica) {
      setRiscoCardiaco(undefined);
      return;
    }

    if (valid) {
      const formValue = { ...value };
      delete formValue.justificativaOcorrenciaRetroativa;

      calcularRisco(formValue as FormDataAvaliacaoRisco);
    }
  }, [calcularRisco, value]);

  const onSubmit = useCallback(
    async (data: FormDataAvaliacaoRisco) => {
      try {
        if (editAvaliacao) {
          const response = await AvaliacaoRiscoAPI.salvarCalculoRisco(
            data,
            editAvaliacao,
          );
          if (response?.id) onSuccess();
        } else {
          const response = await AvaliacaoRiscoAPI.salvarCalculoRisco(data);
          if (response?.id) onSuccess();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setEditAvaliacao(null);
      }
    },
    [editAvaliacao, onSuccess, setEditAvaliacao],
  );

  const onClose = () => {
    setEditAvaliacao(null);
    close();
  };

  return (
    <Dialog
      id="modal-nova-avaliacao-risco"
      header={
        editAvaliacao ? 'Editar Avaliação de Risco' : 'Nova Avaliação de Risco'
      }
      visible={isOpen}
      onHide={onClose}
      maximizedTabletSize
    >
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-grid p-p-0 p-my-0">
          <div className="p-col-12 p-p-0 p-my-0">
            <DataOcorrencia
              control={control}
              defaultValue={
                editAvaliacao
                  ? new Date(editAvaliacao.dataOcorrencia)
                  : new Date()
              }
              dataInclusao={editAvaliacao?.dataInclusao}
              disabled={checkDisabledOnEdit}
            />
          </div>

          {(paciente?.idadeAnos < 30 || paciente?.idadeAnos > 74) && (
            <AlertBox
              text="A idade do paciente deve estar entre 30 e 74 anos para realizar a avaliação de risco."
              visible={true}
            />
          )}

          <div className="p-grid p-col-12 p-md-6 p-p-0 p-my-0">
            <div className="p-col-12 p-pb-0">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Informe idade e sexo do paciente
              </SimpleText>
            </div>
            <div className="p-col-12 p-md-6">
              <DropdownControlled
                label="Idade"
                className="dropdown-idade"
                control={control}
                optionLabel="label"
                optionValue="id"
                options={faixaIdades}
                placeholder="Selecione"
                name="idade"
                filter={false}
                errorMsg={errors.idade?.message}
                disabled
              />
            </div>
            <div className="p-col-12 p-md-6">
              <DropdownControlled
                label="Sexo"
                className="dropdown-sexo"
                control={control}
                optionLabel="label"
                optionValue="value"
                options={sexoOptions}
                placeholder="Selecione"
                name="idSexo"
                filter={false}
                errorMsg={errors.idSexo?.message}
                disabled
              />
            </div>
          </div>
          <div className="p-grid p-col-12 p-md-6 p-p-0 p-my-0">
            <div className="p-col-12 p-pb-0">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Informe a pressão arterial
              </SimpleText>
            </div>
            <div className="p-col-12 p-md-3">
              <TextInputControlled
                required
                label="Sistólica"
                name="sistolica"
                control={control}
                errorMsg={errors.sistolica?.message}
                disabled={checkDisabledOnEdit}
              />
            </div>
            <div className="p-col-12 p-md-3">
              <TextInputControlled
                required
                label="Diastólica"
                name="diastolica"
                control={control}
                errorMsg={errors.diastolica?.message}
                disabled={checkDisabledOnEdit}
              />
            </div>
            <div className="p-col-12 p-md-6 p-pt-5">
              <DropdownControlled
                className="dropdown-unidade-medida"
                control={control}
                value={selectedUnidadeMedida}
                optionLabel="descricao"
                optionValue="codigo"
                options={unidadesMedida}
                onChange={onTipoChange}
                customOnChange={onUnidadeChange}
                placeholder="Selecione"
                name="unidadeMedida"
                filter={false}
                errorMsg={errors.unidadeMedida?.message}
                disabled={checkDisabledOnEdit}
              />
            </div>
          </div>
          <div className="p-grid p-col-12 p-p-0 p-my-0">
            <div className="p-col-12 p-pb-0">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Escolha entre uma das opções.
              </SimpleText>
            </div>
            <div className="p-col-12 p-d-flex p-flex-column p-p-0 p-my-0">
              <div className="p-col-12 p-pb-0">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  LDL Colesterol
                </SimpleText>
              </div>
              <div className="p-grid p-col-12 p-p-0 p-my-0">
                <Skeleton
                  loading={LDLOptions?.length === 0}
                  width="100%"
                  height="34px"
                  borderRadius="10px"
                  className="p-mb-3 p-mt-2 p-mx-2"
                >
                  <>
                    {LDLOptions.map(item => (
                      <div key={item.id} className={colesterolClassName}>
                        <RadioButtonControlled
                          control={control}
                          name="ldlColesterol"
                          label={item.label}
                          value={item.id}
                          disabled={checkDisabledOnEdit}
                        />
                      </div>
                    ))}
                  </>
                </Skeleton>
              </div>
              {errors.ldlColesterol?.message && (
                <div className="p-mb-3 p-mx-2">
                  <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                    {errors.ldlColesterol?.message}
                  </SimpleText>
                </div>
              )}
            </div>
            <div className="p-col-12 p-d-flex p-flex-column p-p-0 p-my-0">
              <div className="p-col-12 p-pb-0">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  HDL Colesterol
                </SimpleText>
              </div>
              <div className="p-grid p-col-12 p-p-0 p-my-0">
                <Skeleton
                  loading={HDLOptions?.length === 0}
                  width="100%"
                  height="34px"
                  borderRadius="10px"
                  className="p-mb-3 p-mt-2 p-mx-2"
                >
                  <>
                    {HDLOptions.map(item => (
                      <div key={item.id} className={colesterolClassName}>
                        <RadioButtonControlled
                          control={control}
                          name="hdlColesterol"
                          label={item.label}
                          value={item.id}
                          disabled={checkDisabledOnEdit}
                        />
                      </div>
                    ))}
                  </>
                </Skeleton>
              </div>
              {errors.hdlColesterol?.message && (
                <div className="p-mb-3 p-mx-2">
                  <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                    {errors.hdlColesterol?.message}
                  </SimpleText>
                </div>
              )}
            </div>
          </div>
          <div className="p-grid p-col-12 p-md-7 p-p-0 p-my-0">
            <div className="p-grid p-col-12 p-md-6 p-p-0 p-my-0 p-d-flex p-flex-column">
              <div className="p-col-12 p-pb-0">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  Diabetes
                </SimpleText>
              </div>
              <div className="p-grid p-col-12 p-py-0 p-px-0">
                <div className="p-col-12 p-md-6 p-pt-0 p-pb-0">
                  <RadioButtonControlled
                    control={control}
                    name="diabetes"
                    label="Sim"
                    value={true}
                    disabled={checkDisabledOnEdit}
                  />
                </div>
                <div className="p-col-12 p-md-6 p-pt-0 p-pb-0">
                  <RadioButtonControlled
                    control={control}
                    name="diabetes"
                    label="Não"
                    value={false}
                    disabled={checkDisabledOnEdit}
                  />
                </div>
              </div>
              {errors.diabetes?.message && (
                <div className="p-mx-2">
                  <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                    {errors.diabetes?.message}
                  </SimpleText>
                </div>
              )}
            </div>
            <div className="p-grid p-col-12 p-md-6 p-p-0 p-my-0 p-d-flex p-flex-column">
              <div className="p-col-12 p-pb-0">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  Tabagismo
                </SimpleText>
              </div>
              <div className="p-grid p-col-12 p-p-0 p-px-0">
                <div className="p-col-12 p-md-6 p-pt-0 p-pb-0">
                  <RadioButtonControlled
                    control={control}
                    name="tabagismo"
                    label="Sim"
                    value={true}
                    disabled={checkDisabledOnEdit}
                  />
                </div>
                <div className="p-col-12 p-md-6 p-pt-0 p-pb-0">
                  <RadioButtonControlled
                    control={control}
                    name="tabagismo"
                    label="Não"
                    value={false}
                    disabled={checkDisabledOnEdit}
                  />
                </div>
              </div>
              {errors.tabagismo?.message && (
                <div className="p-mx-2">
                  <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                    {errors.tabagismo?.message}
                  </SimpleText>
                </div>
              )}
            </div>
          </div>
          <div className="p-grid p-col-12 p-md-5 p-py-0 p-my-0">
            <div className="p-col-12 p-pb-0">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                Resultado
              </SimpleText>
            </div>

            <div className="p-grid p-col-12">
              <PorcentagemRisco
                size="large"
                loading={loading}
                className={corRiscoCardiaco(riscoCardiaco, paciente.idSexo)}
                porcentagem={riscoCardiaco}
              />

              <div className="p-col-12 p-md-9 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.SM}
                  fontColor={FONT_COLOR.COLOR_16}
                  className="p-mb-2"
                  medium
                >
                  Risco Cardíaco
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_40}
                  className="p-mb-1"
                >
                  Fonte do cálculo:
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                  medium
                >
                  Escala de Framingham
                </SimpleText>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Fechar"
              btnType="ghost"
              onClick={() => onClose()}
              stretch
            />
            <Button
              type="submit"
              label="Salvar"
              loading={isSubmitting}
              disabled={checkDisabledOnEdit}
              stretch
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}
