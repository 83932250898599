import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type Props = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldPlanoTarefas = ({
  name = 'planoTarefas',
  label,
  ...rest
}: Props) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const options = [
    {
      label: 'Em dia',
      value: false,
    },
    {
      label: 'Em atraso',
      value: true,
    },
  ];

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      placeholder="Selecione"
      {...rest}
    />
  );
};
