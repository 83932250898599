import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { MovimentacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/Movimentacao/MovimentacaoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import './ModalHistorico.scss';

const ModalHistorico = ({ isOpen, close, state }: DisclosureType) => {
  const { isMobile } = useSize();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [historicos, setHistoricos] = useState<MovimentacoesCarteiraAPS[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getHistorico = async () => {
      setLoading(true);

      try {
        const response: any =
          await MovimentacaoCarteiraAPI.getHistoricoTransferencia(
            consultorios?.ativo?.id,
            Number(state.id),
            { throwError: true },
          );

        setHistoricos(response);
      } catch (error) {
        close();
      } finally {
        setLoading(false);
      }
    };

    getHistorico();
  }, [consultorios?.ativo?.id, close, state.id]);

  const doubleAngleIconClass = `p-col-12 p-sm-2 fa fa-angle-double-${
    isMobile ? 'down' : 'right'
  } icon p-col-2 p-jc-center`;

  return (
    <Dialog
      className="modal-historicoTransferencia"
      type="modal"
      header="Histórico de movimentação"
      visible={isOpen}
      onHide={() => close()}
      footer={
        <Button className="p-w-100" label="Fechar" onClick={() => close()} />
      }
      maximizedTabletSize
    >
      <>
        <div className="p-w-full">{loading && <GridListLoading />}</div>

        {!loading &&
          historicos.map((historico, index) => {
            const isEquipeCuidadora =
              !!historico.carteiraOrigem?.equipeCuidadora;
            const carteiraOrigem =
              historico.carteiraOrigem?.equipeCuidadora ??
              historico.carteiraOrigem?.profissionalCuidador;
            const carteiraDestino =
              historico.carteiraDestino?.equipeCuidadora ??
              historico.carteiraDestino?.profissionalCuidador;

            return (
              <div
                key={generateUniqueId()}
                className="p-grid container-history"
              >
                <div className="p-col-12 p-mb-3">
                  <div className="column">
                    <SimpleText
                      className="p-mb-2"
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      Data da transferência
                    </SimpleText>

                    <SimpleText
                      fontSize={FONT_SIZE.XS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {dayjs(historico?.dataAtualizacao).format(
                        'DD/MM/YYYY  HH:MM',
                      )}
                    </SimpleText>
                  </div>
                </div>

                <div className="content p-col-12 p-sm-5">
                  <div className="column">
                    <SimpleText
                      className="p-mb-3"
                      bold
                      fontSize={FONT_SIZE.SM}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      De
                    </SimpleText>

                    <SimpleText
                      className="p-mb-1"
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      Da equipe
                    </SimpleText>

                    <SimpleText
                      fontSize={FONT_SIZE.XS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {carteiraOrigem?.nome}
                    </SimpleText>

                    {isEquipeCuidadora && (
                      <>
                        <SimpleText
                          className="p-mb-1 p-mt-3"
                          fontSize={FONT_SIZE.XXXS}
                          fontColor={FONT_COLOR.COLOR_40}
                        >
                          Do Profissional Responsável
                        </SimpleText>

                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_16}
                        >
                          {carteiraOrigem?.profissionalResposavel?.nome}
                        </SimpleText>
                      </>
                    )}
                  </div>
                </div>

                <i className={doubleAngleIconClass} />

                <div
                  className={`content p-col-12 p-sm-5  ${
                    index === 0 ? 'atual' : ''
                  }`}
                >
                  <div className="column">
                    <SimpleText
                      className="p-mb-3 text"
                      bold
                      fontSize={FONT_SIZE.SM}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      {index === 0 ? 'Atual' : 'Para'}
                    </SimpleText>

                    <SimpleText
                      className="p-mb-1"
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      Para equipe
                    </SimpleText>

                    <SimpleText
                      fontSize={FONT_SIZE.XS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {carteiraDestino?.nome}
                    </SimpleText>

                    {isEquipeCuidadora && (
                      <>
                        <SimpleText
                          className="p-mb-1 p-mt-3"
                          fontSize={FONT_SIZE.XXXS}
                          fontColor={FONT_COLOR.COLOR_40}
                        >
                          Para o Profissional Responsável
                        </SimpleText>

                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_16}
                        >
                          {carteiraDestino?.profissionalResposavel?.nome}
                        </SimpleText>
                      </>
                    )}
                  </div>
                </div>

                <div className="column p-mt-3">
                  <SimpleText
                    className="p-mb-1"
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Motivo da transferência
                  </SimpleText>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    {historico.motivoTransferenciaAps?.nome ??
                      historico.outrosMotivos}
                  </SimpleText>
                </div>
              </div>
            );
          })}
      </>
    </Dialog>
  );
};

export default ModalHistorico;
