import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/comorbidade';

export const getComorbidades = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const adicionarComorbidade = (
  payload: any,
  hideToast?: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    return: true,
    hideToast: hideToast || false,
    ...options,
  });

export const alterarComorbidade = (
  idComorbidade: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idComorbidade}`,
    data: payload,
    return: true,
    ...options,
  });

export const alterarComorbidadeLembrete = (
  idComorbidade: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/lembrete/${idComorbidade}`,
    return: true,
    ...options,
  });

export const alterarSituacaoComorbidade = (
  idComorbidade: number,
  idAtendimento: number,
  situacao: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/alterar-situacao/${idComorbidade}`,
    params: { situacao },
    return: true,
    ...options,
  });

export const inativarComorbidade = (
  idComorbidade: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/inativar/${idComorbidade}`,
    data: payload,
    return: true,
    ...options,
  });

export const removerComorbidade = (
  idComorbidade: number,
  idAtendimento: number,
  hideToast?: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idComorbidade}/${idAtendimento}`,
    return: true,
    hideToast: hideToast || false,
    ...options,
  });

export const favoritarComorbidade = (
  idProfissionaSaude: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/favoritar/${idProfissionaSaude}`,
    params: payload,
    return: true,
    hideToast: true,
    errorHideToast: false,
    ...options,
  });

export const getFavoritos = (
  idProfissionaSaude: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/favoritos/${idProfissionaSaude}`,
    hideToast: true,
    ...options,
  });

const ComorbidadeAPI = {
  getComorbidades,
  adicionarComorbidade,
  alterarComorbidade,
  alterarComorbidadeLembrete,
  alterarSituacaoComorbidade,
  inativarComorbidade,
  removerComorbidade,
  favoritarComorbidade,
  getFavoritos,
};

export default ComorbidadeAPI;
