import { useContext } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';

import './Pacientes.scss';
import MenuPacientes from './MenuPacientes';

const Pacientes = () => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const isMenu = pathname === '/pacientes';
  return (
    <Page className={`Pacientes ${theme?.theme || ''}`}>
      {isMenu ? <MenuPacientes /> : <Outlet />}
    </Page>
  );
};

export default Pacientes;
