import { useMemo } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';

type FieldConsultoriosProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldSelectConsultorios = ({
  name = 'consultorios',
  label,
  ...rest
}: FieldConsultoriosProps) => {
  const { control } = useFormContext();
  const {
    consultorios: { disponiveis = [] },
  } = useAppSelector((state: RootState) => state);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const options = useMemo(() => {
    const data = disponiveis.map((consultorio: ConsultorioDTO) => ({
      label: consultorio?.nome,
      value: consultorio,
    }));

    return data;
  }, [disponiveis]);

  return (
    <MultiSelectControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      display="chip"
      {...rest}
    />
  );
};
