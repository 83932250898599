import React, { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface DropdownTipoPlano {
  index: number;
  editHospitalConvenios?: any[] | undefined;
}

const DropdownTipoPlano = ({
  index,
  editHospitalConvenios,
}: DropdownTipoPlano) => {
  const [tipoPlanoOptions, setTipoPlanoOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const watchConvenio = watch(`hospitalConvenios.[${index}].convenio`);

  const getTipoPlanoOptions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await ConvenioAPI.getConvenioTiposPlano(watchConvenio?.id);
      const options = res.map((tipoPlano: any) => ({
        label: tipoPlano.descricao,
        value: tipoPlano.id,
      }));
      return setTipoPlanoOptions(options);
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoading(false);
    }
  }, [watchConvenio?.id]);

  useEffect(() => {
    if (!watchConvenio?.id) return;
    getTipoPlanoOptions();
  }, [getTipoPlanoOptions, watchConvenio?.id]);

  useEffect(() => {
    if (
      editHospitalConvenios?.some(
        convenio => convenio.convenio.id === watchConvenio?.id,
      )
    ) {
      const value = editHospitalConvenios
        .find(convenio => convenio.convenio.id === watchConvenio?.id)
        ?.tiposPlanosHospital.map((tipoPlano: any) => tipoPlano.tipoPlano.id);
      setValue(`hospitalConvenios.${index}.idsTiposPlanosHospital`, value);
    }
  }, [editHospitalConvenios, index, setValue, watchConvenio?.id]);

  if (loading) return <Skeleton height="45px" loading={loading} />;

  return (
    <MultiSelectControlled
      className="p-col-12"
      control={control}
      name={`hospitalConvenios.${index}.idsTiposPlanosHospital`}
      label="Tipo de plano"
      options={tipoPlanoOptions || []}
      errorMsg={errors.hospitalConvenios?.[index]?.tipo?.message}
      disabled={tipoPlanoOptions.length < 1}
    />
  );
};

export default DropdownTipoPlano;
