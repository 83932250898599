import { useEffect, useState } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import useDebounce from 'src/utils/useDebounce';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import { formatEnum } from '../helpers';

import validationSchema from './validationSchema';

import './EncerrarCiclo.scss';

const desfechoOptions = [
  { label: 'Nascimento', value: 'NASCIMENTO' },
  { label: 'Aborto', value: 'ABORTO' },
  { label: 'Descontinuado', value: 'DESCONTINUADO' },
];

const tipoPartoOptions = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'Cesárea', value: 'CESARIA' },
];

interface EncerrarCicloProps {
  valoresEnum: any;
  visible: boolean;
  onHide: () => void;
  onEncerrarCiclo: () => void;
}

const EncerrarCiclo = ({
  valoresEnum,
  visible,
  onHide,
  onEncerrarCiclo,
}: EncerrarCicloProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { preNatalAtual } = usePreNatal();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
    reset,
    unregister,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [pesquisaCids, setPesquisaCids] = useState<string>('');
  const [cids, setCids] = useState<any[]>([]);
  const [carregandoPesquisaCids, setCarregandoPesquisaCids] = useState(false);

  const debouncedPesquisaCids = useDebounce(pesquisaCids, 350);

  const { fields: bebesFields } = useFieldArray({
    control,
    name: 'bebes',
  });

  const desfecho = watch('desfecho');
  const bebes = watch('bebes');

  useEffect(() => {
    const bebes = preNatalAtual?.fetos.map(({ nome, idSexo }: any) => ({
      nomeBebe: nome,
      idSexoBebe: idSexo,
      pesoAoNascer: undefined,
      pesoNascimentoUnidadeMedida: undefined,
      natirmorto: false,
    }));

    reset({
      bebes,
    });
  }, [preNatalAtual?.fetos, reset]);

  useEffect(() => {
    loadCids(debouncedPesquisaCids);
  }, [debouncedPesquisaCids]);

  const loadCids = async (query: string) => {
    const response = await UtilsAPI.GetCids({
      nome: query,
      codigo: query,
      page: 0,
    });

    if (!!response?.list?.length)
      setCids(
        response.list.map(cid => ({
          label: `${cid.codigo} - ${cid.nome}`,
          value: cid.id,
        })),
      );

    setCarregandoPesquisaCids(false);
  };

  const onSubmit = async (data: any) => {
    if (!preNatalAtual?.id) return;

    const historicoParto = data.bebes.map((bebe: any) => ({
      ...bebe,
      ...data,
      idAtendimento,
      ...(bebe?.pesoAoNascer && {
        pesoAoNascer: String(bebe.pesoAoNascer).replace(',', '.'),
      }),
    }));

    const payload = {
      historicoParto,
    };
    const response = await PreNatalAPI.encerrarCiclo(preNatalAtual.id, payload);

    if (response?.status === 200) {
      onEncerrarCiclo();
      onHide();
    }
  };

  const handleDesfechoChange = (newDesfecho: string) => {
    if (newDesfecho === 'NASCIMENTO') {
      bebesFields.forEach((field: any, index) => {
        if (!field.natirmorto) {
          setValue(`bebes[${index}].natirmorto`, false);
        }
      });
    }
    if (newDesfecho !== 'NASCIMENTO') {
      unregister(['tipoParto', 'dataParto', '']);
      bebesFields.forEach((_, index) => {
        unregister(`bebes[${index}].pesoAoNascer`);
        unregister(`bebes[${index}].pesoNascimentoUnidadeMedida`);
        unregister(`bebes[${index}].natirmorto`);
        unregister(`bebes[${index}].idCid`);
      });
    }
    if (newDesfecho !== 'ABORTO') {
      unregister('idCid');
    }
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Voltar"
        className="p-col-align-center"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Encerrar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      className="encerrar-ciclo-modal"
      visible={visible}
      header="Encerrar pré-natal"
      onHide={onHide}
      footer={renderFooter}
      maximizedMobileSize
    >
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="desfecho"
          label="Desfecho"
          options={desfechoOptions}
          filter={false}
          errorMsg={errors.desfecho?.message}
          customOnChange={handleDesfechoChange}
        />

        {desfecho !== 'ABORTO' && desfecho !== 'DESCONTINUADO' && (
          <>
            <SelectButtonControlled
              className="p-col-12 p-md-5"
              control={control}
              name="tipoParto"
              label="Tipo do parto"
              options={tipoPartoOptions}
              errorMsg={errors.tipoParto?.message}
            />

            <CalendarInputControlled
              className="p-col-12 p-md-7"
              control={control}
              name="dataParto"
              label="Data do parto"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              maxDate={new Date()}
              errorMsg={errors.dataParto?.message}
            />
          </>
        )}

        {bebesFields.map((field, index) => {
          return (
            <div key={index} className="p-grid p-col-12 p-p-0">
              <TextInputControlled
                className="p-col-12 p-md-5 p-px-0"
                control={control}
                label={`Nome do bebê 0${index + 1}`}
                name={`bebes.${index}.nomeBebe`}
                maxLength={50}
                errorMsg={errors.bebes?.[index]?.nomeBebe?.message}
              />

              <div className="p-col-12 p-md-7 p-px-0 p-pl-md-3">
                <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                  Sexo
                </SimpleText>
                <RadioButtonGroupControlled
                  name={`bebes.${index}.idSexoBebe`}
                  control={control}
                  error={errors.bebes?.[index]?.idSexoBebe}
                  items={[
                    { value: 1, label: 'Feminino' },
                    { value: 3, label: 'Masculino' },
                  ]}
                />
              </div>

              {desfecho !== 'ABORTO' && desfecho !== 'DESCONTINUADO' && (
                <>
                  <div className="p-col-12 p-md-6 p-px-0 p-pr-md-2 p-pb-0">
                    <TextInputControlled
                      control={control}
                      name={`bebes.${index}.pesoAoNascer`}
                      label="Peso ao nascer"
                      floatOnly
                      maxLength={5}
                      errorMsg={errors.bebes?.[index]?.pesoAoNascer?.message}
                    />
                  </div>
                  <div className="field-center-without-label p-col-12 p-md-6 p-px-0 p-pl-md-2 p-pb-0">
                    <DropdownControlled
                      control={control}
                      name={`bebes.${index}.pesoNascimentoUnidadeMedida`}
                      options={formatEnum(
                        valoresEnum?.unidadeMedidaEncerrarCiclo,
                      )}
                      filter={false}
                      errorMsg={
                        errors.bebes?.[index]?.pesoNascimentoUnidadeMedida
                          ?.message
                      }
                    />
                  </div>

                  <div className="p-col-12 p-p-0 p-mb-3">
                    <CheckboxControlled
                      className="checkbox-small"
                      control={control}
                      name={`bebes.${index}.natirmorto`}
                      label="Natimorto"
                    />
                  </div>

                  {bebes[index].natirmorto && (
                    <DropdownControlled
                      className="p-col-12 p-p-0"
                      control={control}
                      name={`bebes.${index}.idCid`}
                      label="CID"
                      placeholder="pesquisar"
                      options={cids}
                      onFilter={e => {
                        setCarregandoPesquisaCids(true);
                        setPesquisaCids(e.filter);
                      }}
                      carregandoFiltro={carregandoPesquisaCids}
                      errorMsg={errors.bebes?.[index]?.idCid?.message}
                    />
                  )}
                </>
              )}

              {index + 1 < bebesFields.length && (
                <Divider layout="horizontal" />
              )}
            </div>
          );
        })}

        {desfecho === 'ABORTO' && (
          <DropdownControlled
            className="p-col-12"
            control={control}
            name="idCid"
            label="CID"
            placeholder="pesquisar"
            options={cids}
            onFilter={e => {
              setCarregandoPesquisaCids(true);
              setPesquisaCids(e.filter);
            }}
            carregandoFiltro={carregandoPesquisaCids}
            errorMsg={errors.idCid?.message}
          />
        )}

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="observacao"
          label="Observação"
          maxLength={500}
          maxLengthSpan
        />
      </form>
    </Dialog>
  );
};

export default EncerrarCiclo;
