import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isTablet } = useSize();

  return (
    <div className="p-grid">
      <div className="p-col-2 p-md-1">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Data da ocorrência
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Login
        </SimpleText>
      </div>
      {!isTablet && (
        <div className="p-col-1">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            ID Origem
          </SimpleText>
        </div>
      )}
      {!isTablet && (
        <div className="p-col-1">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            ID Destino
          </SimpleText>
        </div>
      )}
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome paciente - Origem
        </SimpleText>
      </div>
      <div className="p-col-1">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          /
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome paciente - Destino
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-jc-center p-mt-1">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Ação
        </SimpleText>
      </div>
    </div>
  );
};
