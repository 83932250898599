import { useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';

type EllipsisMenu = TieredMenu & {
  hide: () => void;
};

export const ProcedimentosSolicitadosPanelHeaderBtns = (props: any) => {
  const {
    descricaoProcedimento,
    isPacote,
    isNotProcedimentoPrincipalPacote,
    hasAnexoLaudo,
    hasParecer,
    onUploadLaudo,
    onEdit,
    onDelete,
    onOpenParecer,
  } = props;
  const menuEllipsis = useRef<EllipsisMenu>(null);

  const { atendimentoStatus } = useAtendimento();
  const { setProcedimentoNomeSearchHistorico } = useSolicitacaoExameCirurgia();

  const disabledWhenAtendimentoStatus = atendimentoStatus !== 'ATENDENDO';

  const { windowInnerWidth } = useSize();

  const disabledDelete = isPacote && isNotProcedimentoPrincipalPacote;

  const handleEllipsis = (onHandle: () => void) => {
    onHandle();
    menuEllipsis?.current?.hide();
  };

  const handleVerHistorico = () => {
    setProcedimentoNomeSearchHistorico(descricaoProcedimento);
    const element = document.getElementById('historico-exames-resultados');
    element?.scrollIntoView();
  };

  if (windowInnerWidth <= 1300) {
    const anexoLaudoText = hasAnexoLaudo
      ? 'Visualizar laudo anexado'
      : 'Anexar laudo';
    const parecerText = hasParecer
      ? 'Editar parecer do exame'
      : 'Informar parecer do exame';

    const menuEllipsisItens: MenuItem[] = [
      {
        label: 'Ver histórico',
        command: () => handleEllipsis(handleVerHistorico),
      },
      {
        label: anexoLaudoText,
        command: () => handleEllipsis(onUploadLaudo),
      },
      {
        label: parecerText,
        command: () => handleEllipsis(onOpenParecer),
      },
      {
        label: 'Laudo importado',
        disabled: true,
      },
      {
        label: 'Editar',
        command: () => handleEllipsis(onEdit),
      },
      {
        separator: true,
      },
      {
        label: 'Excluir',
        className: 'warning-color',
        command: () => handleEllipsis(onDelete),
        disabled: disabledDelete,
      },
    ];

    return (
      <>
        <Button
          icon="fas fa-ellipsis-h"
          btnType="gray"
          onClick={event => menuEllipsis?.current?.toggle(event)}
          disabled={disabledWhenAtendimentoStatus}
        />

        <TieredMenu
          className="solicitacao-exame-tiered-menu"
          ref={menuEllipsis}
          model={menuEllipsisItens}
          popup
        />
      </>
    );
  }

  return (
    <>
      <div className="historico-btn" onClick={handleVerHistorico}>
        Ver histórico
      </div>
      <Button
        type="button"
        btnType="green-link"
        icon="fas fa-paperclip"
        onClick={onUploadLaudo}
        disabled={disabledWhenAtendimentoStatus}
      />

      <Button
        btnType="green-link"
        icon="fas fa-file-medical"
        onClick={onOpenParecer}
        disabled={disabledWhenAtendimentoStatus}
      />

      <Button btnType="green-link" icon="fas fa-file-import" disabled />

      <div className="p-d-flex p-gap-1">
        <Divider layout="vertical" />

        <Button
          btnType="gray"
          icon="fas fa-pencil"
          onClick={onEdit}
          disabled={disabledWhenAtendimentoStatus}
        />
        <Button
          className="p-ml-3"
          btnType="gray"
          icon="fas fa-trash"
          onClick={onDelete}
          disabled={disabledDelete || disabledWhenAtendimentoStatus}
        />
      </div>
    </>
  );
};
