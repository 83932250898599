import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldCiapMultiselect } from 'src/components/Fields/FieldCiapMultiselect/FieldCiapMultiselect';
import { FieldCidsMultiselect } from 'src/components/Fields/FieldCidsMultiselect/FieldCidsMultiselect';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const Filter = () => {
  const { setFilter } = useSearchList();

  const ref = useRef<OverlayPanel>(null);

  const form = useForm({
    defaultValues: {
      status: null,
      cids: [],
      ciap: [],
    },
  });

  const onSubmit = (values: any) => {
    const idsCids = values.cids?.map((cid: Cids) => cid.id);
    const idsCiap = values.ciap?.map((ciap: Ciap) => ciap.id);

    setFilter({
      status: values.status,
      idsCiap: idsCiap.toString(),
      idsCid: idsCids.toString(),
    });

    ref.current?.hide();
  };

  const handleClean = () => {
    form.reset({
      status: null,
      cids: [],
      ciap: [],
    });

    setFilter({});
    ref.current?.hide();
  };

  return (
    <FormProvider {...form}>
      <Button
        label="Pesquisa avançada"
        btnType="tonal"
        onClick={e => ref?.current?.toggle(e)}
        type="button"
      />

      <OverlayPanel ref={ref} style={{ width: '400px' }}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="p-w-100 p-pb-2 p-d-flex p-ai-center">
            <SimpleText className="p-col-8" bold>
              Pesquisa avançada
            </SimpleText>
            <Button
              label="Limpar"
              btnType="ghost"
              onClick={handleClean}
              className="p-col"
              type="button"
            />
          </div>

          <div className="p-col-12 p-pt-0">
            <DropdownControlled
              control={form.control}
              name="status"
              label="Status"
              filter={false}
              options={[
                {
                  label: 'Ativo',
                  value: 'ATIVO',
                },
                {
                  label: 'Inativo',
                  value: 'INATIVO',
                },
              ]}
            />
          </div>

          <FieldCidsMultiselect
            label="CID"
            placeholder="Selecione"
            className="p-col-12"
          />
          <FieldCiapMultiselect
            label="CIAP"
            placeholder="Selecione"
            className="p-col-12"
          />

          <div className="p-col-12 p-d-flex p-gap-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => ref.current?.hide()}
              type="button"
              stretch
            />
            <Button label="Aplicar filtro" type="submit" stretch />
          </div>
        </form>
      </OverlayPanel>
    </FormProvider>
  );
};
