import { ReactNode, useState } from 'react';

import { CreateUserContext } from '../../contexts/ManterUsuario/CreateUser';


interface CreateUserProviderProps {
  children: ReactNode;
}

export function CreateUserProvider({ children }: CreateUserProviderProps) {
  const [mandatoryData, setMandatoryData] = useState<any>({});
  const [idUser, setIdUser] = useState<number | null>(null);

  function saveMandatoryData(newData: any) {
    const {
      cpf,
      dataNascimento,
      idSexo,
      numeroRegistro,
      idEstadoRegistro,
      conselhoTiss, 
      profissional: conselhoTissProfissional,
      ...rest
    } = newData;

    setMandatoryData((oldState: any) => ({
      ...oldState,
      dadosPessoais: {
        ...oldState?.dadosPessoais,
        dataNascimento,
        idSexo,
      },
      documentos: {
        ...oldState?.documentos,
        cpf,
      },
      profissionalSaude: {
        ...oldState?.profissionalSaude,
        conselhoTiss,
        conselhoTissProfissional,
        numeroRegistro,
        idEstadoRegistro
      },
      ...rest
    }))
  }

  return (
    <CreateUserContext.Provider
      value={{
        mandatoryData,
        idUser,
        setIdUser,
        saveMandatoryData,
        setMandatoryData,
      }}
    >
      {children}
    </CreateUserContext.Provider>
  );
}
