import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import TipoEstabelecimentoAPI from 'src/APIs/AdminAPI/ConsultorioAPI/TipoEstabelecimentoAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';
import * as Yup from 'yup';

import { tiposContato } from 'src/utils/tiposContato';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import ResponsableListItem from './ResponsableListItem/ResponsableListItem';

import './FormAdditionalData.scss';

interface FormAdditionalDataProps {
  clinicProfessionalsList: GetProfissionalDTO[] | [];
  onSubmit(v: any): void;
  values?: any | undefined;
  adicionarProfissionais: () => void;
}

interface Cities {
  id: number;
  nome: string;
}

interface States {
  id: number;
  nome: string;
}

const FormAdditionalData = ({
  clinicProfessionalsList,
  onSubmit,
  values,
  adicionarProfissionais,
}: FormAdditionalDataProps) => {
  const [list, setList] = useState<GetProfissionalDTO[]>();
  const [responsableSelected, setResponsableSelected] = useState({} as any);
  const [tiposEstabelecimento, setTiposEstabelecimento] = useState<any[]>([]);
  const [typeContact, setTypeContact] = useState<string>('');
  const [cities, setCities] = useState<Cities[]>([]);
  const [states, setStates] = useState<States[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingCepSearch, setLoadingCepSearch] = useState<boolean>(false);
  const [
    mostrarSelecaoResponsavelTecnico,
    setMostrarSelecaoResponsavelTecnico,
  ] = useState<boolean>(true);

  const validationSchema = Yup.object().shape({
    cnpj: Yup.string().required('O campo CNPJ é obrigatório.'),
    cnes: Yup.string().required('O campo CNES é obrigatório.'),
    cep: Yup.string().required('O campo CEP é obrigatório.'),
    estado: Yup.string().required('O campo estado é obrigatório.'),
    idCidade: Yup.string().required('O campo cidade é obrigatório.'),
    logradouro: Yup.string().required('O campo logradouro é obrigatório.'),
    numero: Yup.string().required('O campo número é obrigatório.'),
    bairro: Yup.string().required('O campo bairro é obrigatório.'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('O campo e-mail é obrigatório.'),
    tipo: Yup.string().required('O campo tipo de contato é obrigatório.'),
    telefone: Yup.string().required('O campo telefone é obrigatório.'),
    responsavelTecnico: Yup.object()
      .nullable()
      .required('Responsavel tecnico é obrigatório'),
  });

  const useFormMethods = useForm({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const handleStateSelect = useCallback(async stateId => {
    return new Promise(async resolve => {
      const citiesFromState: any = await UtilsAPI.GetCidades(stateId);

      if (Array.isArray(citiesFromState)) setCities(citiesFromState);

      resolve(true);
    });
  }, []);

  const searchAddressByZipCode = useCallback(async () => {
    const cep = getValues('cep');

    if (cep && cep.length === 9) {
      setLoadingCepSearch(true);

      const resp = await UtilsAPI.GetAddressByCep(cep);

      if (resp !== undefined) {
        const { logradouro, bairro, idEstado, idCidade, complemento } = resp;
        setValue('logradouro', logradouro);
        setValue('bairro', bairro);
        setValue('complemento', complemento);
        setValue('estado', idEstado);

        await handleStateSelect(idEstado);

        setValue('idCidade', idCidade);

        setLoadingCepSearch(false);
      }
    }
  }, [handleStateSelect, getValues, setValue]);

  const professionalsHealthList = useMemo(
    () =>
      clinicProfessionalsList.filter(
        professional => professional?.tipo === 'PROFISSIONAL_SAUDE',
      ),
    [clinicProfessionalsList],
  );

  useEffect(() => {
    setList(professionalsHealthList);
  }, [professionalsHealthList]);

  useEffect(() => {
    if (tiposEstabelecimento.length === 0) {
      async function loadTipoEstabelecimentos() {
        const response =
          await TipoEstabelecimentoAPI.loadTipoEstabelecimentos();

        const estabelecimentosFormatados = response.map(
          (tipoEstabelecimento: TipoEstabelecimento) => ({
            ...tipoEstabelecimento,
            value: tipoEstabelecimento.id,
            label: tipoEstabelecimento.nome,
          }),
        );

        setTiposEstabelecimento(estabelecimentosFormatados as any);
      }

      loadTipoEstabelecimentos();
    }
  }, [tiposEstabelecimento]);

  const load = useCallback(async () => {
    setLoadingData(true);

    if (values && values?.idUsuarioResponsavel) {
      const findProfessional = professionalsHealthList.find(
        (p: any) => p.id === values?.idUsuarioResponsavel,
      );

      setResponsableSelected(findProfessional || {});
      setValue('responsavelTecnico', findProfessional || null);
    }

    const statesData = await UtilsAPI.GetEstados();

    setStates(statesData);

    if (values?.estado) await handleStateSelect(values?.estado);

    setLoadingData(false);
  }, [values, professionalsHealthList, handleStateSelect, setValue]);

  useEffect(() => {
    load();
  }, [load]);

  const statesOptions = useMemo(
    () =>
      states.map((estado: any) => ({
        label: estado?.nome,
        value: estado?.id,
      })),
    [states],
  );

  const citiesOptions = useMemo(
    () =>
      cities.map((cidade: any) => ({
        label: cidade?.nome,
        value: cidade?.id,
      })),
    [cities],
  );

  const handleResponsable = useCallback((resp: Usuario) => {
    setMostrarSelecaoResponsavelTecnico(false);
    setResponsableSelected(resp);
  }, []);

  const handleFormSubmit = useCallback(
    (formData: any) => {
      const data = {
        ...formData,
        idUsuarioResponsavel: responsableSelected.id,
      };

      onSubmit(data);
    },
    [onSubmit, responsableSelected],
  );

  const renderResponsavelTecnico = useCallback(() => {
    if (!list?.length) {
      return (
        <div id="botao-adicionar-responsavel-container">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Não há profissionais da sáude cadastrados na equipe da clínica.
          </SimpleText>

          <Button
            label="Adicionar profissional"
            btnType="tonal"
            className="p-col-12 p-mt-3"
            icon="fas fa-plus"
            onClick={adicionarProfissionais}
          />
        </div>
      );
    }

    if (mostrarSelecaoResponsavelTecnico) {
      return (
        <DropdownControlled
          control={control}
          name="responsavelTecnico"
          placeholder="Digite o nome"
          className="p-col-12"
          dropdownIcon="pi pi-search"
          options={list?.map(i => ({ label: i.nome, value: i })) || []}
          itemTemplate={({ value }) => (
            <ResponsableListItem
              id={value.id}
              name={value.nome}
              avatar={value.nome}
              ocupation={
                value.profissionalSaude?.conselhoTissProfissional.descricao
              }
              crm={`${value.profissionalSaude?.conselhoTiss.sigla}${value.profissionalSaude?.numeroRegistro}`}
              onClick={() => handleResponsable(value)}
            />
          )}
          customOnChange={setTypeContact}
        />
      );
    }

    return (
      <>
        <ResponsableListItem
          id={responsableSelected?.id}
          name={responsableSelected?.nome}
          avatar={responsableSelected?.nome}
          ocupation={
            responsableSelected?.profissionalSaude?.conselhoTissProfissional
              .descricao
          }
          crm={`${responsableSelected?.profissionalSaude?.conselhoTiss.sigla}${responsableSelected?.profissionalSaude?.numeroRegistro}`}
        />
        <Button
          label="Trocar de responsável técnico"
          btnType="ghost"
          onClick={() => {
            setValue('responsavelTecnico', null);
            setMostrarSelecaoResponsavelTecnico(true);
            setResponsableSelected({});
          }}
        />
      </>
    );
  }, [
    adicionarProfissionais,
    control,
    handleResponsable,
    list,
    mostrarSelecaoResponsavelTecnico,
    responsableSelected?.id,
    responsableSelected?.nome,
    responsableSelected?.profissionalSaude?.conselhoTiss.sigla,
    responsableSelected?.profissionalSaude?.conselhoTissProfissional.descricao,
    responsableSelected?.profissionalSaude?.numeroRegistro,
    setValue,
  ]);

  if (loadingData)
    return (
      <div className="FormAdditionalData">
        <GridListLoading />
      </div>
    );

  return (
    <FormProvider {...useFormMethods}>
      <form
        className="FormAdditionalData p-grid p-col-12 p-md-9 p-lg-7 p-xl-5"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <MaskedInputControlled
          control={control}
          name="cnpj"
          placeholder="00.000.000/0000-00"
          label="CNPJ"
          className="p-col-12"
          mask={MASK.CNPJ}
          errorMsg={errors.cnpj?.message}
        />

        <DropdownControlled
          control={control}
          name="estabelecimento"
          label="Tipo de estabelecimento"
          className="p-col-12"
          options={tiposEstabelecimento}
          errorMsg={errors.estabelecimento?.message}
        />

        <FormInput
          name="cnes"
          label="CNES"
          className="p-col-12 p-sm-6"
          errorMsg={errors.cnes?.message}
          maxLength={7}
          hideTextErrorSpace
        />

        <FormInput
          name="origem_tiss"
          label="Origem TISS"
          className="p-col-12 p-sm-6"
          errorMsg={errors.origem_tiss?.message}
          hideTextErrorSpace
        />

        <Divider />

        <MaskedInputControlled
          control={control}
          name="cep"
          label="CEP"
          placeholder="00000-000"
          className="p-col-12 p-md-8"
          mask={MASK.CEP}
          errorMsg={errors.cep?.message}
        />

        <div className="p-col-12 p-md-4 p-as-end">
          <Button
            type="button"
            btnType="tonal"
            icon="pi pi-search"
            className="search-address-btn"
            onClick={searchAddressByZipCode}
            loading={loadingCepSearch}
            label={loadingCepSearch ? 'Pesquisando' : 'Pesquisar'}
          />
        </div>

        <Divider />

        <DropdownControlled
          control={control}
          name="estado"
          label="Estado"
          className="p-col-12 p-sm-6"
          options={statesOptions}
          errorMsg={errors.estado?.message}
          disabled={!statesOptions.length || loadingCepSearch}
        />

        <DropdownControlled
          control={control}
          name="idCidade"
          label="Cidade"
          className="p-col-12 p-sm-6"
          options={citiesOptions}
          errorMsg={errors.idCidade?.message}
          disabled={!citiesOptions.length || loadingCepSearch}
        />

        <FormInput
          name="logradouro"
          label="Logradouro"
          className="p-col-12"
          errorMsg={errors.logradouro?.message}
          disabled={loadingCepSearch}
          hideTextErrorSpace
        />

        <FormInput
          name="complemento"
          label="Complemento"
          className="p-col-12"
          errorMsg={errors.complemento?.message}
          disabled={loadingCepSearch}
          hideTextErrorSpace
        />

        <FormInput
          name="numero"
          label="Número"
          className="p-col-12 p-sm-6"
          errorMsg={errors.numero?.message}
          hideTextErrorSpace
        />

        <FormInput
          name="bairro"
          label="Bairro"
          className="p-col-12 p-sm-6"
          errorMsg={errors.bairro?.message}
          disabled={loadingCepSearch}
          hideTextErrorSpace
        />

        <FormInput
          name="enderecoResumido"
          label="Endereço resumido"
          className="p-col-12"
          errorMsg={errors.enderecoResumido?.message}
          hideTextErrorSpace
        />

        <Divider />

        <FormInput
          name="email"
          type="email"
          label="E-mail"
          className="p-col-12"
          errorMsg={errors.email?.message}
          hideTextErrorSpace
        />

        <DropdownControlled
          control={control}
          name="tipo"
          label="Tipo de Contato"
          className="p-col-12 p-sm-6"
          options={tiposContato}
          customOnChange={setTypeContact}
          errorMsg={errors.tipo?.message}
        />
        <MaskedInputControlled
          control={control}
          name="telefone"
          label="Telefone"
          className="p-col-12 p-sm-6"
          mask={MASK.PHONE}
          errorMsg={errors.telefone?.message}
        />

        <Divider />

        <div className="reponsavelTecnico p-grid p-col-12 p-p-0">
          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XS}
            bold
            fontColor={FONT_COLOR.COLOR_16}
          >
            Responsável técnico
          </SimpleText>

          {renderResponsavelTecnico()}
        </div>

        <div className="p-col-12">
          <Button
            label="Salvar"
            btnType="tonal"
            className="p-mt-5 p-w-100"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default FormAdditionalData;
