import { useState } from 'react';

import { useNavigate } from 'react-router';

import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Header, MobileRow } from './EmpresaGrid';
import ModalDesativarEmpresa from './ModalDesativarEmpresa/ModalDesativarEmpresa';
import ModalVisualizarEmpresa from './ModalVisualizarEmpresa/ModalVisualizarEmpresa';

import './CentralEmpresa.scss';

const CentralEmpresa = () => {
  const navigate = useNavigate();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_empresa_cadastrar',
      'adm_empresa_alterar',
      'adm_empresa_consultar',
      'adm_empresa_excluir',
    ],
  });

  const [modalDesativarEmpresa, setModalDesativarEmpresa] = useState(false);
  const [empresa, setEmpresa] = useState({} as EspecialidadeDTO);
  const [modalVisualizarEmpresa, setModalVisualizarEmpresa] = useState(false);
  const [reload, setReload] = useState(0);

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await EmpresasAPI.GetAll({
      nome: params?.query,
      ...params,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleSwitchStatus = (empresa: any) => {
    setModalDesativarEmpresa(true);
    setEmpresa(empresa);
  };

  const handleSwitchStatusConfirm = async (item: any) => {
    let response: any = '';

    if (!item.status) {
      response = await EmpresasAPI.updateEmpresaAtivarStatus(item?.id);
    } else {
      response = await EmpresasAPI.updateEmpresaInativarStatus(item?.id);
    }

    if (response.status === 200) {
      setModalDesativarEmpresa(false);
      reloadList();
    }
  };

  const handleView = (empresa: any) => {
    setEmpresa(empresa);
    setModalVisualizarEmpresa(true);
  };

  const handleEdit = (item: any) => {
    if (item.id) {
      navigate(`/cadastros/empresa/cadastro/${item.id}`, { state: item });
    }
  };

  const handleModalDesativarEmpresaClose = () => {
    setModalDesativarEmpresa(false);
  };

  const handleModalVisualizarEmpresaClose = () => {
    setModalVisualizarEmpresa(false);
  };

  const renderRow = (empresa: any, isMobile: boolean) => {
    const commomProps = {
      empresa,
      onEdit: handleEdit,
      onSwitchStatus: handleSwitchStatus,
      onView: handleView,
    };

    if (isMobile) return <MobileRow {...commomProps} />;

    return <DesktopRow {...commomProps} />;
  };

  return (
    <>
      <SearchList.Root fetchApi={fetchApi} reloadWhenEmpresaChange>
        <SearchList.SearchInput
          label="Pesquisar clientes"
          placeholder="Digite um nome"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
            <Button
              type="button"
              btnType="pill"
              label="Incluir novo cliente"
              icon="fas fa-users"
              onClick={() => navigate('/cadastros/empresa/cadastro')}
              checkPermission="ADM_EMPRESA_CADASTRAR"
            />

            <SearchList.TotalCount className="p-mt-3 p-mt-sm-0" />
          </div>

          <Header />

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalVisualizarEmpresa
        empresaSelecionada={empresa}
        visible={modalVisualizarEmpresa}
        onHide={handleModalVisualizarEmpresaClose}
        onEdit={handleEdit}
        reloadList={reloadList}
      />

      <ModalDesativarEmpresa
        empresa={empresa}
        visible={modalDesativarEmpresa}
        onHide={handleModalDesativarEmpresaClose}
        handleDesativarEmpresa={handleSwitchStatusConfirm}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default CentralEmpresa;
