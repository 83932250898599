import { useEffect, useMemo } from 'react';

import { Control, useController } from 'react-hook-form';

import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import CalendarControlled from 'src/components/Basics/CalendarControlled/CalendarControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';

import { checkIsRetroativo } from '../../FichaPaciente/helpers';

import './DataOcorrencia.scss';

interface DataOcorrenciaProps {
  control: Control<any>;
  disabled?: boolean;
  defaultValue?: Date;
  nameDataOcorrencia?: string;
  nameJustificativaRetroativa?: string;
  dataInclusao?: string | undefined;
  onBlurJustificativa?: any;
  onChangeDataOcorrencia?: (date: Date | Date[] | undefined) => void;
}

const DataOcorrencia = ({
  control,
  disabled = false,
  defaultValue = new Date(),
  nameDataOcorrencia = 'dataOcorrencia',
  nameJustificativaRetroativa = 'justificativaOcorrenciaRetroativa',
  dataInclusao,
  onBlurJustificativa,
  onChangeDataOcorrencia,
}: DataOcorrenciaProps) => {
  const {
    field: { value },
    formState: { errors },
  } = useController({
    name: nameDataOcorrencia,
    control,
    defaultValue: defaultValue,
  });

  const {
    field: { onChange },
  } = useController({
    name: nameJustificativaRetroativa,
    control,
  });

  const dataOcorrenciaIsToday = useMemo(() => {
    const currentDate = dayjs(new Date());

    const diferrenceBetweenDates = currentDate.diff(value, 'day');

    return diferrenceBetweenDates === 0;
  }, [value]);

  useEffect(() => {
    if (dataOcorrenciaIsToday) onChange('');
  }, [dataOcorrenciaIsToday, onChange]);

  const dataOcorrenciaLabel = useMemo(() => {
    if (dataOcorrenciaIsToday) return 'hoje';

    return dayjs(value).format('DD/MM/YYYY [às] HH:mm');
  }, [dataOcorrenciaIsToday, value]);

  const isRetroativo = checkIsRetroativo(value, dataInclusao);

  return (
    <div className="data-ocorrencia-container p-col-12 p-px-0">
      <div className="p-col-12 p-d-flex p-ai-center">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Data da ocorrência
        </SimpleText>

        <Button
          type="button"
          btnType="green-link"
          label={dataOcorrenciaLabel}
          icon="fas fa-caret-down"
          iconPos="right"
          disabled={disabled}
          overlay={
            <CalendarControlled
              control={control}
              name={nameDataOcorrencia}
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              maxDate={new Date()}
              onChange={e => onChangeDataOcorrencia?.(e.value)}
              hideOnDateTimeSelect
              showTime
            />
          }
        />
      </div>

      {isRetroativo && !dataOcorrenciaIsToday && (
        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name={nameJustificativaRetroativa}
          autoResize
          label="Justificativa para data de ocorrência anterior"
          placeholder="Justificativa para data de ocorrência anterior"
          errorMsg={errors?.[nameJustificativaRetroativa]?.message}
          disabled={disabled}
          maxLength={400}
          maxLengthSpan
          onBlur={() => onBlurJustificativa && onBlurJustificativa()}
        />
      )}
    </div>
  );
};

export default DataOcorrencia;
