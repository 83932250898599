export const tipoRecorrenciaLabel = (
  watchTipoRecorrencia: string,
  dias?: number,
) => {
  if (!watchTipoRecorrencia) return '';

  let value =
    watchTipoRecorrencia === 'DIARIA'
      ? 'Dias'
      : watchTipoRecorrencia === 'SEMANAL'
      ? 'Semanas'
      : 'Meses';

  if (!!dias) value = `${dias} ${value}`;

  return value;
};
