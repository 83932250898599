import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react';

import useDebounce from 'src/utils/useDebounce';

interface SearchListData {
  debouncedValue: string;
  searchQuery: string;
  loading: boolean;
  servicosTuss: any[];
  servicosTussSelected: any[];
  handleSelectServicoTuss: (isChecked: boolean, servicoTuss: any) => void;
  handleChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearSearchInput: () => void;
  handleClearServicosTussSelected: () => void;
  handleChangeFavorito: (
    idServicoTuss: number,
    idExameFavorito: number,
    favorito: boolean,
  ) => void;
}

const ServicosTussSelectContext = createContext({} as SearchListData);

interface ServicosTussSelectProps {
  children: JSX.Element | JSX.Element[];
  servicosTussAdded?: any[];
  fetchServicosTuss: (params?: any) => Promise<any>;
  lengthToSearch?: boolean;
}

const ServicosTussSelectProvider = (props: ServicosTussSelectProps) => {
  const {
    children,
    servicosTussAdded,
    fetchServicosTuss,
    lengthToSearch = true,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [servicosTussSelected, setServicosTussSelected] = useState<any[]>([]);
  const [servicosTuss, setServicosTuss] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const debouncedValue = useDebounce(searchQuery, 350);

  const formatOptions = useCallback(
    (servicosTussResponse: any) => {
      if (!servicosTussAdded?.length) return servicosTussResponse;

      const servicosTussAddedIds = servicosTussAdded?.map(
        (servicoTuss: any) => servicoTuss.idServicoTuss,
      );

      const solicitacoesExamesFiltered = servicosTussResponse.filter(
        (servicoTuss: any) => !servicosTussAddedIds.includes(servicoTuss.id),
      );

      return solicitacoesExamesFiltered;
    },
    [servicosTussAdded],
  );

  useEffect(() => {
    const fetch = async () => {
      if (lengthToSearch && debouncedValue.length < 3) return;

      setLoading(true);

      try {
        const params = {
          query: debouncedValue,
        };

        const response = await fetchServicosTuss(params);

        const formattedOptions = formatOptions(response);

        setServicosTuss(formattedOptions);
      } catch (error) {
        setServicosTuss([]);
        setServicosTussSelected([]);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [debouncedValue, fetchServicosTuss, formatOptions, lengthToSearch]);

  const handleSelectServicoTuss = (isChecked: boolean, servicoTuss: any) => {
    if (isChecked) {
      return setServicosTussSelected((prevServicosTussSelected: any) => [
        ...prevServicosTussSelected,
        servicoTuss,
      ]);
    }

    setServicosTussSelected((prevServicosTussSelected: any) =>
      prevServicosTussSelected.filter(
        (prevServicoTussSelected: any) =>
          prevServicoTussSelected.id !== servicoTuss.id,
      ),
    );
  };

  const handleClearServicosTussSelected = () => setServicosTussSelected([]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const handleClearSearchInput = () => setSearchQuery('');

  const handleChangeFavorito = (
    idServicoTuss: number,
    idExameFavorito: number,
    favorito: boolean,
  ) => {
    setServicosTuss((prevServicosTuss: any) =>
      prevServicosTuss.map((prevServicoTuss: any) => {
        if (prevServicoTuss.id !== idServicoTuss) return prevServicoTuss;

        return {
          ...prevServicoTuss,
          idExameFavorito,
          favorito,
        };
      }),
    );
  };

  return (
    <ServicosTussSelectContext.Provider
      value={{
        debouncedValue,
        searchQuery,
        loading,
        servicosTuss,
        servicosTussSelected,
        handleSelectServicoTuss,
        handleChangeSearchInput,
        handleClearSearchInput,
        handleClearServicosTussSelected,
        handleChangeFavorito,
      }}
    >
      {children}
    </ServicosTussSelectContext.Provider>
  );
};

function useServicoTussSelect() {
  const context = useContext(ServicosTussSelectContext);

  if (!context) {
    throw new Error(
      'useServicoTussSelect must be used within an ServicosTussSelectProvider',
    );
  }

  return context;
}

export { useServicoTussSelect, ServicosTussSelectProvider };
