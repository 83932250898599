import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

export const MeusDadosDadosPessoaisDocumentosForm = ({
  handleNext,
  userData,
}: any) => {
  const { idPaciente, idEmpresa } = useAppSelector(state => state.user);

  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(
      Yup.object({
        dataNascimento: Yup.date()
          .required('Campo obrigatório')
          .typeError('Informe uma data válida'),
      }),
    ),
  });

  useEffect(() => {
    form.reset({
      nome: userData?.nomeSocial || userData?.nome,
      idSexo: userData?.idSexo,
      estadoCivil: userData?.estadoCivil,
      dataNascimento: new Date(userData?.dataNascimento),
      rgNumero: userData?.rg,
      cpf: userData?.cpf,
      passaporte: userData?.passaporte,
      cns: userData?.cns,
    });
  }, [form, userData]);

  const onSubmit = (v: any) => {
    return PortalPacienteAPI.updatePacienteDadosBasicosDocumentos(
      idPaciente,
      {
        ...v,
        idEmpresa,
      },
      {
        throwError: true,
      },
    )
      .then(() => handleNext())
      .catch(() => {});
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2"
      >
        <SimpleText fontColor="color_60" medium>
          Dados pessoais
        </SimpleText>

        <FormInput name="nome" label="Nome social" hideTextErrorSpace />

        <DropdownControlled
          control={form.control}
          name="idSexo"
          label="Sexo"
          filter={false}
          options={[
            {
              label: 'Masculino',
              value: 3,
            },
            {
              label: 'Feminino',
              value: 1,
            },
          ]}
        />

        <DropdownControlled
          control={form.control}
          name="estadoCivil"
          label="Estado Cívil"
          filter={false}
          options={[
            {
              label: 'Solteiro',
              value: 'SOLTEIRO',
            },
            {
              label: 'Casado',
              value: 'CASADO',
            },
            {
              label: 'Separado',
              value: 'SEPARADO',
            },
            {
              label: 'Divorciado',
              value: 'DIVORCIADO',
            },
            {
              label: 'União Estável',
              value: 'UNIAO_ESTAVEL',
            },
            {
              label: 'Viúvo',
              value: 'VIUVO',
            },
          ]}
        />

        <CalendarInputControlled
          control={form.control}
          name="dataNascimento"
          label="Data de nascimento"
          placeholder="dd/mm/aaaa"
        />

        <SimpleText className="p-my-2" fontColor="color_60" medium>
          Documentos
        </SimpleText>

        <FormInput
          name="rgNumero"
          label="RG"
          placeholder="00.000.000-0"
          hideTextErrorSpace
        />
        <MaskedInputControlled
          control={form.control}
          name="cpf"
          label="CPF"
          mask={MASK.CPF}
          disabled
        />
        <FormInput
          name="passaporte"
          label="Passaporte"
          disabled
          hideTextErrorSpace
        />
        <FormInput name="cns" label="CNS" hideTextErrorSpace />

        <Button
          type="submit"
          label="Salvar alterações"
          stretch
          loading={form.formState?.isSubmitting}
        />
      </form>
    </FormProvider>
  );
};
