import { useCallback, useState } from 'react';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import PickList from 'src/components/PickList/PickList';

interface ConsultoriosProps {
  values: any;
  consultoriosOptions: any[];
  onSubmit(v: any): void;
  handlePreviousStep(): void;
}

const Consultorios = ({
  values,
  onSubmit,
  consultoriosOptions,
  handlePreviousStep,
}: ConsultoriosProps) => {
  const [consultoriosSelected, setConsultoriosSelected] = useState<any>(
    values.consultorios || [],
  );
  const modalAddConsultoriosDisclosure = useDisclosure({
    opened: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const renderConsultorios = () => {
    return (
      <div className="p-grid p-flex-column">
        <SimpleText
          className="p-my-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Selecione os consultórios.
        </SimpleText>

        <SimpleText
          bold
          className="p-mt-3"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Filtre os resultados utilizando o campo de pesquisa abaixo.
        </SimpleText>

        <PickList
          label="Consultórios"
          setSelected={setConsultoriosSelected}
          arraySelected={consultoriosSelected}
          arrayOptions={consultoriosOptions}
        />

        {modalAddConsultoriosDisclosure.isOpen && (
          <ConfirmDialog
            visible={modalAddConsultoriosDisclosure.isOpen}
            header="Deseja ativar todos os consultório?"
            text="Você realmente deseja ativar todos os consultórios? Seleções ja feitas serão perdidas!"
            confirmText="Sim, ativar"
            confirmBtnType="filled"
            onHide={() => {
              modalAddConsultoriosDisclosure.close();
            }}
            onConfirm={() => {
              setConsultoriosSelected([]);
              modalAddConsultoriosDisclosure.close();
            }}
          />
        )}
      </div>
    );
  };

  const submit = useCallback(() => {
    setLoading(true);
    onSubmit({
      consultorios: consultoriosSelected,
    });
    setLoading(false);
  }, [consultoriosSelected, onSubmit]);

  return (
    <div className="p-grid p-col-12 p-lg-8 p-xl-11 p-pl-5">
      <div className="formPontuacao-content">
        <SimpleText
          className="p-mb-3"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          bold
        >
          Cadastrar pontuação carteira
        </SimpleText>

        {renderConsultorios()}
      </div>

      <div className="p-col-12 p-lg-4 p-xl-1 p-px-0">
        <Button
          className="p-w-100"
          label="Voltar"
          btnType="green-link"
          onClick={handlePreviousStep}
        />
      </div>

      <div className="p-col-12 p-lg-6 p-xl-4">
        <Button
          disabled={!consultoriosSelected.length}
          className="p-w-100"
          label="Salvar"
          loading={loading}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default Consultorios;
