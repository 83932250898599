import { useEffect, useState, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import GridListLoading from 'src/components/GridList/GridListLoading';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import validationSchema from './validationSchema';

import './FolhaRosto.scss';

const quantidadeFetosOptions = [
  { label: '02', value: 2 },
  { label: '03', value: 3 },
  { label: '04', value: 4 },
  { label: '05', value: 5 },
];

const riscoOptions = [
  { label: 'Habitual', value: 'HABITUAL' },
  { label: 'Alto Risco', value: 'ALTO_RISCO' },
];

interface FolhaRostoProps {
  idPaciente: number | null;
}

const FolhaRosto = ({ idPaciente }: FolhaRostoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const {
    preNatalAtual,
    proximaGestacao,
    utilizarDppUsAcompanhamento,
    loadPreNatal,
    selectedGestacao,
    tipoGestacaoOptions,
  } = usePreNatal();

  const { atendimentoStatus } = useAtendimento();

  const gestacao = selectedGestacao || preNatalAtual;

  const hideHistorico =
    gestacao?.id === preNatalAtual?.id ? 'hide-historico' : '';

  const hideGestacaoAtual =
    gestacao?.id !== preNatalAtual?.id ? 'hide-gestacao-atual' : '';

  const refOverlay = useRef<OverlayPanel>(null);
  const refInputSearch = useRef<any>(null);

  const {
    control,
    formState: { isSubmitting },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: fetosFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'fetos',
  });

  const {
    fields: cidsFields,
    append: appendCids,
    remove: removeCids,
  } = useFieldArray({
    control,
    name: 'cids',
  });

  const [cidsList, setCidsList] = useState<any[]>([]);
  const [querySearchCid, setQuerySearchCid] = useState('');
  const [loading, setLoading] = useState(false);

  const tipoGravidez = watch('tipoGravidez');
  const dum = watch('dum');
  const risco = watch('risco');

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  useEffect(() => {
    if (utilizarDppUsAcompanhamento) setValue('utilizarDpp', false);
  }, [setValue, utilizarDppUsAcompanhamento]);

  useEffect(() => {
    setValue('utilizarDpp', preNatalAtual?.utilizarDpp);
  }, [preNatalAtual?.utilizarDpp, setValue]);

  useEffect(() => {
    const loadFolhaRosto = async () => {
      if (!preNatalAtual?.id) {
        return reset({
          tipoGestacao: proximaGestacao,
          risco: 'HABITUAL',
          quantidadeFetos: 1,
          fetos: [{ nome: '', idSexo: '' }],
          utilizarDpp: false,
        });
      }

      setLoading(true);

      const response = await PreNatalAPI.getFolhaRosto(
        preNatalAtual?.id as number,
      );

      setLoading(false);

      if (response?.id) {
        const fetos = response.fetos.map(({ nome, idSexo }: any) => ({
          nome,
          idSexo,
        }));

        const cids = response.cids.map((cidItem: any) => ({
          idCid: cidItem.cid.id,
          nome: cidItem.cid.nome,
          codigo: cidItem.cid.codigo,
          idAtendimento: cidItem.idAtendimento,
        }));

        return reset({
          tipoGestacao: response.tipoGestacao,
          risco: response.risco,
          trimestre: response.trimestre,
          tipoGravidez: response.tipoGravidez,
          quantidadeFetos: response.quantidadeFetos,
          fetos: fetos,
          cids: cids,
          nomePai: response.nomePai,
          utilizarDpp: response.utilizarDpp,
          dum: new Date(response.dum),
          dpp: new Date(response.dpp),
        });
      }
    };

    loadFolhaRosto();
  }, [preNatalAtual?.id, reset, proximaGestacao]);

  useEffect(() => {
    if (!dum) return setValue('dpp', null);

    const dpp = dayjs(dum).add(9, 'months').add(7, 'days').toDate();

    setValue('dpp', dpp);
  }, [dum, setValue]);

  const onChangeTipoGravidez = (tipoGravidez: string) => {
    let newQuantidadeFetos = 1;

    if (tipoGravidez === 'MULTIPLA') newQuantidadeFetos = 2;

    updateFetosFieldsArray(newQuantidadeFetos);

    setValue('quantidadeFetos', newQuantidadeFetos);
  };

  const updateFetosFieldsArray = (newQuantidadeFetos: number) => {
    const oldQuantidadeFetos = fetosFields.length;

    if (newQuantidadeFetos > oldQuantidadeFetos) {
      for (let i = oldQuantidadeFetos; i < newQuantidadeFetos; i++) {
        append({ nome: '', idSexo: '' });
      }
    } else {
      for (let i = oldQuantidadeFetos; i > newQuantidadeFetos; i--) {
        remove(i - 1);
      }
    }
  };

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      idPaciente,
      registrarHistorico: false,
    };

    const saveAction = preNatalAtual?.id
      ? PreNatalAPI.updateFolhaRosto
      : PreNatalAPI.createFolhaRosto;

    const response = await saveAction(
      payload,
      Number(idAtendimento),
      preNatalAtual?.id as number,
    );

    if (response?.status === 200 || response?.status === 201)
      loadPreNatal(Number(idPaciente));
  };

  const onSelectCid = (cid: any) => {
    appendCids({
      idCid: cid.id,
      nome: cid.nome,
      codigo: cid.codigo,
      idAtendimento: Number(idAtendimento),
    });

    refInputSearch?.current?.clear?.();

    refOverlay.current?.hide();
  };

  useEffect(() => {
    const searchCid = async () => {
      const params = {
        nome: querySearchCid,
        codigo: querySearchCid,
      };

      const response = await UtilsAPI.GetCids(params);

      if (Array.isArray(response?.list)) setCidsList(response.list);
    };

    searchCid();
  }, [querySearchCid]);

  if (loading)
    return (
      <div className="p-col-12">
        <GridListLoading />
      </div>
    );

  return (
    <>
      <div className={hideHistorico}></div>
      <form id="folha-rosto-pre-natal" onSubmit={handleSubmit(onSubmit)}>
        <fieldset
          className={`p-grid p-col-12 p-px-0 ${hideGestacaoAtual}`}
          disabled={!isAtendimentoRunning}
        >
          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            Nova gestação
          </SimpleText>

          <DropdownControlled
            className={`p-col-12 ${
              risco === 'ALTO_RISCO' ? 'p-lg-5' : 'p-md-6'
            }`}
            control={control}
            name="tipoGestacao"
            label="Tipo da gestação"
            options={tipoGestacaoOptions}
            filter={false}
            disabled={!!preNatalAtual?.id || !isAtendimentoRunning}
          />

          <DropdownControlled
            className={`p-col-12 ${
              risco === 'ALTO_RISCO' ? 'p-md-6 p-lg-3' : 'p-md-6'
            }`}
            control={control}
            name="risco"
            label="Risco"
            options={riscoOptions}
            filter={false}
            disabled={!isAtendimentoRunning}
          />

          {risco === 'ALTO_RISCO' && (
            <div className="p-col-12 p-md-6 p-lg-4 p-grid">
              <SimpleText
                className="p-col-12 p-pb-0"
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                CID
              </SimpleText>

              <div className="p-col-12 p-d-flex p-p-0">
                <div id="search-cid-input" className="p-mr-4">
                  <TextInputSearch
                    ref={refInputSearch}
                    placeholder="Buscar por código ou nome"
                    onChange={setQuerySearchCid}
                    value={querySearchCid}
                  />
                </div>

                <Button
                  type="button"
                  icon="fa fa-search"
                  btnType="gray"
                  onClick={e => refOverlay.current?.toggle(e)}
                  disabled={!isAtendimentoRunning}
                />

                <OverlayPanel
                  ref={refOverlay}
                  className="pre-natal-cids-result-search"
                >
                  {cidsList.map((cid: any) => (
                    <li key={cid.id} onClick={() => onSelectCid(cid)}>
                      {cid.codigo} - {cid.nome}
                    </li>
                  ))}
                </OverlayPanel>
              </div>
            </div>
          )}

          {cidsFields.map((cid: any, index) => (
            <div key={index} className="pre-natal-cids-container p-col-12">
              <li>
                ({cid.codigo}) {cid.nome}
              </li>

              <Button
                type="button"
                icon="fa fa-trash"
                btnType="gray"
                onClick={() => removeCids(index)}
                disabled={
                  !(
                    Number(idAtendimento) === cid.idAtendimento &&
                    atendimentoStatus === 'ATENDENDO'
                  )
                }
              />
            </div>
          ))}

          <SimpleText
            className="p-col-12 p-pb-0"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Pré-natal iniciou no
          </SimpleText>
          <div className="p-col-12 p-pt-0">
            <RadioButtonGroupControlled
              control={control}
              name="trimestre"
              items={[
                { value: 'TRIMESTRE_1', label: '1º Trimestre' },
                { value: 'TRIMESTRE_2', label: '2º Trimestre' },
                { value: 'TRIMESTRE_3', label: '3º Trimestre' },
              ]}
              disabled={!isAtendimentoRunning}
            />
          </div>

          <SimpleText
            className="p-col-12 p-pb-0"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Gravidez
          </SimpleText>
          <div className="p-col-9 p-pt-0">
            <RadioButtonGroupControlled
              name="tipoGravidez"
              control={control}
              customOnChange={onChangeTipoGravidez}
              items={[
                { value: 'SIMPLES', label: 'Simples' },
                { value: 'MULTIPLA', label: 'Multipla' },
              ]}
              disabled={!isAtendimentoRunning}
            />
          </div>

          <DropdownControlled
            className="p-col-3"
            control={control}
            name="quantidadeFetos"
            options={quantidadeFetosOptions}
            customOnChange={updateFetosFieldsArray}
            filter={false}
            disabled={tipoGravidez !== 'MULTIPLA' || !isAtendimentoRunning}
          />

          {fetosFields.map((field, index) => {
            return (
              <div key={index} className="p-grid p-col-12 p-p-0">
                <TextInputControlled
                  className="p-col-12 p-md-6"
                  control={control}
                  label="Nome do bebê"
                  name={`fetos.${index}.nome`}
                  maxLength={50}
                />

                <div className="p-col-12 p-md-6">
                  <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                    Sexo
                  </SimpleText>
                  <RadioButtonGroupControlled
                    name={`fetos.${index}.idSexo`}
                    control={control}
                    disabled={!isAtendimentoRunning}
                    items={[
                      { value: 1, label: 'Feminino' },
                      { value: 3, label: 'Masculino' },
                    ]}
                  />
                </div>
              </div>
            );
          })}

          <TextInputControlled
            className="p-col-12"
            control={control}
            name="nomePai"
            label="Nome do pai"
            maxLength={50}
          />

          <div className="p-col-12">
            <Divider layout="horizontal" />
          </div>

          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_60}
            medium
          >
            IG Atual DUM
          </SimpleText>

          <div className="p-col-12 p-md-6">
            <CalendarInputControlled
              control={control}
              name="dum"
              label="DUM"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              maxDate={new Date()}
            />
            <CheckboxControlled
              className="checkbox-small"
              control={control}
              name="utilizarDpp"
              label="Utilizar DPP DUM para acompanhamento"
              disabled={!isAtendimentoRunning}
            />
          </div>

          <CalendarInputControlled
            className="p-col-12 p-md-6"
            control={control}
            name="dpp"
            label="DPP"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
          />

          <div className="p-col-12 p-mt-3">
            <Button
              type="submit"
              className="p-col-12"
              btnType="tonal"
              label="Salvar"
              loading={isSubmitting}
              disabled={!isAtendimentoRunning}
              stretch
            />
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default FolhaRosto;
