import Http, { HttpParams } from 'src/core/http/Http';

// PreNatal
export const getPreNatal = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `puericultura/prenatal/atendimento/${idAtendimento}`,
    params: params,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });
};

export const postPreNatal = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: 'puericultura/prenatal',
    data: payload,
    ...options,
  });
};

// DADOS NASCIMENTO
export const getDadosNascimento = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `puericultura/dados-nascimento/${idAtendimento}`,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });
};

export const postDadosNascimento = (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `puericultura/dados-nascimento/${idAtendimento}`,
    data: payload,
    ...options,
  });
};

// TRIAGEM
export const getTriagem = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `puericultura/triagem/${idAtendimento}`,
    hideToast: true,
    ...options,
  });
};

export const postTriagem = (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `puericultura/triagem/${idAtendimento}`,
    data: payload,
    ...options,
  });
};

// OBSERVACAO
export const getObservacao = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `puericultura/observacao/${idAtendimento}`,
    hideToast: true,
    ...options,
  });
};

export const postObservacao = (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `puericultura/observacao/${idAtendimento}`,
    data: payload,
    ...options,
  });
};

const PuericulturaAPI = {
  getPreNatal,
  postPreNatal,
  getDadosNascimento,
  postDadosNascimento,
  getTriagem,
  postTriagem,
  getObservacao,
  postObservacao,
};

export default PuericulturaAPI;
