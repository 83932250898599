import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/opme';

export const getOpmes = (
  idAtendimento: any,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<Opme[]>> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getOpmeById = (
  idOpme: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/${idOpme}/atendimento/${idAtendimento}`,
    hideToast: true,
    ...options,
  });

export const adicionarOpme = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });

export const alterarOpme = (
  idOpme: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idOpme}/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const alterarOpmeLembrete = (
  idOpme: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/lembrete/${idOpme}`,
    return: true,
    ...options,
  });

export const inativarOpme = (
  idOpme: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idOpme}/atendimento/${idAtendimento}/inativar`,
    data: payload,
    return: true,
    ...options,
  });

export const removerOpme = (
  idOpme: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idOpme}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

export const getMateriais = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'materiais',
    params,
    hideToast: true,
    ...options,
  });

const OpmeAPI = {
  getOpmes,
  getOpmeById,
  adicionarOpme,
  alterarOpme,
  alterarOpmeLembrete,
  inativarOpme,
  removerOpme,
  getMateriais,
};

export default OpmeAPI;
