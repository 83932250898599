import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { Button, Checkbox } from 'src/components/_UI';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';

const VinculosConvenios = () => {
  const [conveniosOptions, setConveniosOptions] = useState<any[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { watch, reset, setValue } = useFormContext();

  const watchConveniosVinculo = watch('convenios');

  useEffect(() => {
    (async () => {
      try {
        const response = await ConvenioAPI.loadConvenios(
          {
            pageSize: 999,
          },
          { throwError: true },
        );
        const convenios = response.list.map((convenio: any) => ({
          value: convenio.id,
          label: convenio.nomeFantasia,
        }));
        setConveniosOptions(convenios);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleLimparSelecao = () => {
    setValue('convenios', []);
  };

  const handleSelectAll = () => {
    const convenios = conveniosOptions.map(convenio => convenio.value);
    setValue('convenios', convenios);
  };

  useEffect(() => {
    if (!searchQuery) return setFilteredOptions(conveniosOptions);
    const filtered = conveniosOptions.filter(convenio =>
      convenio.label.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    setFilteredOptions(filtered);
  }, [conveniosOptions, searchQuery]);

  const isAllSelected = watchConveniosVinculo
    ? conveniosOptions?.length === watchConveniosVinculo?.length
    : false;

  return (
    <div>
      <TextInputSearch
        placeholder="Buscar convênio"
        className="p-col-12"
        onChange={e => setSearchQuery(e)}
        value={searchQuery}
      />
      <div className="p-d-flex p-jc-between p-px-2">
        <Checkbox
          label="Selecionar todos"
          value={isAllSelected}
          onChange={() => handleSelectAll()}
          checked={isAllSelected}
        />
        <Button
          type="button"
          label="Limpar"
          btnType="green-link"
          onClick={() => handleLimparSelecao()}
        />
      </div>
      <div className="lista-vinculos p-p-2">
        <MultipleCheckboxControlled
          name="convenios"
          options={filteredOptions}
          classNameContainer="p-gap-2"
          checkboxClassName="p-p-2 row-component p-my-1"
        />
      </div>
    </div>
  );
};

export default VinculosConvenios;
