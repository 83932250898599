import { useState } from 'react';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';
import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { dateFormats } from '../DialogNovaPergunta/utils';

import { Button, RadioButton } from 'src/components/_UI';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import Checkbox from 'src/components/Basics/Checkbox/Checkbox';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

type Props = DisclosureType<{
  item: IPerguntaFormularioItem;
  description?: string;
  idForm?: number;
  idFormularioDinamicoPergunta?: number;
}> & {
  handleRefetch(): void;
  from?: 'FORMULARIO' | undefined;
};

export const DialogDeletarPergunta = ({
  isOpen,
  close,
  state,
  handleRefetch,
  from,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const item = state?.item;

  const questionTitle = item?.enunciado;
  const questionType = item?.tipoResposta || 'TEXTO';
  const questionOptions = item?.opcoes
    ? item.opcoes.map(item => ({
        label: item.descricao,
        value: item,
      }))
    : [];

  const handleDelete = async () => {
    if (!state?.item.id) return;
    try {
      setIsDeleting(true);
      await PerguntaFormularioDinamicoAPI.deletePerguntaFormulario(
        {
          id: state?.item.id,
        },
        {
          throwError: true,
        },
      );

      handleRefetch();
      close();
    } catch {
      setIsDeleting(false);
    }
  };

  const handleDesvincular = async () => {
    if (!state?.idForm || !state.idFormularioDinamicoPergunta) return;
    try {
      setIsDeleting(true);
      await FormularioDinamicoAPI.desvincularPerguntaFormularioDinamico(
        {
          idForm: state.idForm,
          idFormularioDinamicoPergunta: state.idFormularioDinamicoPergunta,
        },
        {
          throwError: true,
        },
      );

      handleRefetch();
      close();
    } catch {
      setIsDeleting(false);
    }
  };

  return (
    <Dialog
      header="Tem certeza que deseja excluir a pergunta?"
      visible={isOpen}
      onHide={close}
      style={{
        width: '700px',
      }}
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText>
          {state?.description || (
            <>
              Ao clicar em <b>“sim, apagar”</b> você estará excluindo a seguinte
              pergunta da lista de perguntas e não terá mais acesso a ela.{' '}
            </>
          )}
        </SimpleText>

        <div className="row content-row p-flex-column p-p-2">
          <SimpleText medium>{questionTitle}</SimpleText>
          <Divider layout="horizontal" className="p-my-2" />

          {['MULTIPLA_ESCOLHA'].includes(questionType) && (
            <Checkbox options={questionOptions} />
          )}
          {['ESCOLHA'].includes(questionType) &&
            questionOptions.map((item, i) => (
              <RadioButton
                className="pointer-events-none p-mb-2"
                key={i}
                panel={false}
                label={item.label}
                value={item.value}
              />
            ))}
          {['TEXTO'].includes(questionType) && (
            <TextareaInput
              className="pointer-events-none input-bg-white"
              placeholder="Resposta em texto"
            />
          )}
          {['NUMERO'].includes(questionType) && (
            <TextInput
              className="pointer-events-none input-bg-white"
              placeholder="Resposta númerica"
            />
          )}
          {['DATA'].includes(questionType) && (
            <CalendarInput
              value={undefined}
              showIcon
              className="pointer-events-none input-bg-white"
              placeholder={
                dateFormats.find(date => date.value === item?.formatoData)
                  ?.label || 'DD/MM/AAAA'
              }
            />
          )}
        </div>

        <div className="p-d-flex p-gap-2">
          <Button
            label="Não"
            stretch
            btnType="ghost"
            onClick={() => close()}
            disabled={isDeleting}
          />
          <Button
            label="Sim, apagar"
            btnType="danger"
            stretch
            onClick={() =>
              from === 'FORMULARIO' ? handleDesvincular() : handleDelete()
            }
            loading={isDeleting}
          />
        </div>
      </div>
    </Dialog>
  );
};
