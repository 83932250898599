import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/notificacoes';

export const buscarNotificacoes = async (
  route: string,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${route}`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const atualizarStatusNotificacao = async (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/visualizar/${id}`,
    hideToast: true,
    ...options,
  });
};

export const atualizarStatusNotificacoes = async (
  ids: any[],
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/visualizar/bloco`,
    data: { ids },
    hideToast: true,
    ...options,
  });
};

export const atualizarStatusNotificacaoParaNaoLido = async (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/remover-visualizar/${id}`,
    hideToast: true,
    ...options,
  });
};

const NotificacaoAPI = {
  buscarNotificacoes,
  atualizarStatusNotificacao,
  atualizarStatusNotificacoes,
  atualizarStatusNotificacaoParaNaoLido,
};

export default NotificacaoAPI;
