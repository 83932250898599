import { Control, Controller } from 'react-hook-form';

import TextareaInput, {
  TextareaInputProps,
} from '../TextareaInput/TextareaInput';

export interface TextareaInputControlledProps extends TextareaInputProps {
  control: Control<any>;
  name: string;
  onCustomChange?: (value: string) => void;
}

const TextareaInputControlled = ({
  name,
  control,
  onCustomChange,
  ...rest
}: TextareaInputControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
      }: any) => (
        <TextareaInput
          {...rest}
          value={value || ''}
          onChange={e => [onChange(e), onCustomChange?.(e.target.value)]}
          ref={ref}
          errorMsg={error?.message}
        />
      )}
    />
  );
};

export default TextareaInputControlled;
