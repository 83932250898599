import { useLocation } from 'react-router';

import Tab from 'src/components/Tab/Tab';

import { AtendimentoGrupoCabecalho } from './AtendimentoGrupoCabecalho';
import { AtendimentoGrupoContent } from './AtendimentoGrupoContent';

type Props = {
  dadosCabecalho: any;
};

export const AtendimentoGrupo = ({ dadosCabecalho }: Props) => {
  const { state } = useLocation();

  const pacientesGrupo: PacienteProntuario[] = state?.isEvolucaoIndividual
    ? [dadosCabecalho.paciente]
    : dadosCabecalho?.pacientesGrupo || [];

  if (!dadosCabecalho) return <></>;

  return (
    <>
      <AtendimentoGrupoCabecalho
        atendimento={dadosCabecalho.atendimento}
        pacientesGrupo={pacientesGrupo}
      />

      <Tab
        underHeader
        values={[
          {
            label: 'Atendimento',
            content: (
              <AtendimentoGrupoContent
                atendimento={dadosCabecalho.atendimento}
                pacientesGrupo={pacientesGrupo!}
              />
            ),
          },
        ]}
      />
    </>
  );
};
