import * as Yup from 'yup';

const cnpjValidation = (value: string | undefined | null) => {
  if (!value) return true;

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += (n as number) * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
};

const addressValdiation = (endereco: any, field: string) => {
  if (
    endereco[field] !== undefined &&
    endereco[field] !== null &&
    endereco[field] !== ''
  )
    return false;

  if (
    (field === 'estado' || field === 'idCidade') &&
    endereco.idPaisEstrangeiro !== 0
  )
    return false;

  return Object.values(endereco).some(
    enderecoField =>
      enderecoField !== undefined &&
      enderecoField !== null &&
      enderecoField !== '',
  );
};

export const validationSchema = Yup.object().shape({
  isParticular: Yup.boolean(),
  razaoSocial: Yup.string().when('isParticular', {
    is: false,
    then: field => field.required('O campo é obrigatório'),
  }),
  registroAns: Yup.string().when('isParticular', {
    is: false,
    then: field => field.required('O campo é obrigatório'),
  }),
  cnpj: Yup.string()
    .nullable()
    .notRequired()
    .test('teste', 'CNPJ inválido', cnpjValidation),
  site: Yup.string().nullable().url('Formato do link inválido').notRequired(),
  emailInstitucional: Yup.string()
    .nullable()
    .email('Formato de e-mail inválido')
    .notRequired(),
  'endereco.cep': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'cep'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.idPaisEstrangeiro': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'idPaisEstrangeiro'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.estado': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'estado'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.idCidade': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'idCidade'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.tipo': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'tipo'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.bairro': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'bairro'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.logradouro': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'logradouro'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
  'endereco.numero': Yup.string()
    .nullable()
    .notRequired()
    .when(['endereco', 'isParticular'], {
      is: (endereco: any, isParticular: boolean) =>
        !isParticular && addressValdiation(endereco, 'numero'),
      then: Yup.string().required('O campo é obrigatório'),
    }),
});
