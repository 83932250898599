import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DropdownFilterParams,
  DropdownChangeParams,
} from 'primereact/dropdown';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import useDebounce from 'src/utils/useDebounce';

import Dropdown, {
  DropdownProps,
} from 'src/components/Basics/Dropdown/Dropdown';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type UncontrolledFieldConvenioProps = DropdownProps & {
  convenio: Convenio | null;
  name?: string;
  label: string;
  params?: any;
  onChange: (event: DropdownChangeParams) => void;
};

export const UncontrolledFieldConvenio = ({
  convenio,
  name = 'convenio',
  label,
  params,
  onChange,
  ...rest
}: UncontrolledFieldConvenioProps) => {
  const [convenios, setConvenios] = useState<Convenio[]>([]);
  const [loadingSearchConvenio, setLoadingSearchConvenio] = useState(false);
  const [querySearchConvenio, setQuerySearchConvenio] = useState('');

  const debouncedSearchConvenio = useDebounce(querySearchConvenio, 350);

  const loadConvenios = useCallback(
    async (queryParam: any) => {
      if (!queryParam) return setConvenios([]);

      try {
        const response = await ConvenioAPI.loadConveniosAtivos(
          {
            nome: queryParam,
            ...params,
          },
          { throwError: true },
        );

        setConvenios(response.list);
      } catch (error) {
        setConvenios([]);
      } finally {
        setLoadingSearchConvenio(false);
      }
    },
    [params],
  );

  useEffect(() => {
    loadConvenios(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, loadConvenios]);

  const conveniosOptions = useMemo(() => {
    const options = convenios.map(convenio => ({
      label: convenio?.nomeFantasia || convenio.razaoSocial,
      value: convenio,
    }));

    // valida se convênio atual está listado nas options
    if (convenio) {
      const isConvenioAtualVisivel = convenios.find(
        conv => conv.id === convenio.id,
      );

      if (!isConvenioAtualVisivel) {
        options.unshift({
          label: convenio?.nomeFantasia || convenio.razaoSocial,
          value: convenio,
        });
      }
    }

    return options;
  }, [convenios, convenio]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setLoadingSearchConvenio(true);
    setQuerySearchConvenio(filter);
  };

  const options = conveniosOptions.length ? conveniosOptions : defaultOption;
  const emptyFilterMessage = querySearchConvenio.length
    ? 'Nenhuma opção encontrada'
    : 'Digite para pesquisar';

  return (
    <Dropdown
      panelClassName="panel-convenio"
      name={name}
      dataKey="id"
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loadingSearchConvenio}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options}
      value={convenio}
      onChange={onChange}
      {...rest}
    />
  );
};
