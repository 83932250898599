import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { FormularioDinamicoAcervoFormulariosFilter } from './FormularioDinamicoAcervoFormulariosFilter';
import { FormularioDinamicoAcervoFormulariosRow } from './FormularioDinamicoAcervoFormulariosRow';

export const FormularioDinamicoAcervoFormularios = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <>
        <PageHeader
          title="Acervo de formulários"
          subtitle="A listagem dos formulários que foram criadas e salvas"
          hasBreadcrumbs
          backButton
          onClickBackButton={() => navigate('/cadastros/formulario-dinamico')}
        />

        <Page className="p-pt-0" content white>
          <FormularioDinamicoAcervoFormulariosContent />
        </Page>
      </>
    </Page>
  );
};

const FormularioDinamicoAcervoFormulariosContent = () => {
  const [reload, setReload] = useState(0);

  const dialogDelete = useDisclosure({ opened: false });
  const navigate = useNavigate();

  const fetchApi = ({ query, ...props }: any) => {
    return FormularioDinamicoAPI.loadFormulariosDinamico({
      nome: query,
      sortDirection: 'ASC',
      sortBy: 'nome',
      ...props,
    });
  };

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  const handleView = (id: number) => {
    navigate(`/cadastros/formulario-dinamico/acervo-formularios/${id}`);
  };

  const handleEdit = (id: number) => {
    navigate(`/cadastros/formulario-dinamico/acervo-formularios/${id}`, {
      state: { to: 'edit' },
    });
  };

  const handleCopy = async (item: IFormularioDinamicoListItem) => {
    const payload: Omit<IFormularioDinamicoPayload, 'id'> = {
      nome: `Cópia ${item.nome}`,
      descricao: item.descricao,
      tipo: item.tipo,
    };

    try {
      await FormularioDinamicoAPI.createFormularioDinamico(payload);
      return reloadList();
    } catch {
      return;
    }
  };

  return (
    <SearchList.Root fetchApi={fetchApi} empresaRequired>
      <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-gap-2">
        <SearchList.SearchInput
          label="Pesquisar por nome"
          placeholder="Busque pelo nome do formulário"
          className="p-col-12 p-md-6 p-lg-3"
        />

        <Button
          label="Pesquisa avançada"
          btnType="tonal"
          icon="fas fa-chevron-down"
          className="p-col-12 p-md-4 p-lg-3 p-xl-2"
          iconPos="right"
          overlay={e => (
            <FormularioDinamicoAcervoFormulariosFilter
              onSuccess={() => e?.hide()}
            />
          )}
        />

        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-mb-4">
            <Button
              label="Novo formulário"
              icon="fas fa-plus"
              className="p-col-12 p-md-6 p-lg-3"
              btnType="pill"
              checkPermission={'ADM_MANTER_FORMULARIO_DINAMICO_CADASTRAR'}
              onClick={() =>
                navigate(
                  '/cadastros/formulario-dinamico/acervo-formularios/novo',
                )
              }
            />
            <SearchList.TotalCount className="p-col-12 p-md-6 p-lg-3 p-text-right" />
          </div>

          <SearchList.InfiniteScroll
            reload={reload}
            renderRow={item => (
              <FormularioDinamicoAcervoFormulariosRow
                onDelete={() =>
                  dialogDelete.open({
                    state: { id: item.id, onSuccess: reloadList },
                  })
                }
                handleReload={reloadList}
                handleView={handleView}
                handleEdit={handleEdit}
                handleCopy={handleCopy}
                item={item}
              />
            )}
          />
        </SearchList.BorderContainer>

        {dialogDelete.isOpen && <DialogDelete {...dialogDelete} />}
      </div>
    </SearchList.Root>
  );
};

const DialogDelete = ({
  isOpen,
  close,
  state,
}: DisclosureType<{ id: number; onSuccess(): void }>) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    if (!state?.id) return;
    setIsLoading(true);

    try {
      await FormularioDinamicoAPI.deletarFormularioDinamico(state.id, {
        throwError: true,
      });
      state?.onSuccess();
      close();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      header="Tem certeza que deseja excluir o formulário"
      visible={isOpen}
      onHide={close}
      closable={!isLoading}
    >
      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40">
          Ao prosseguir, o formulário será apagado e você não terá mais acesso a
          ele.
        </SimpleText>

        <div className="p-d-flex p-gap-2 p-mt-2">
          <Button
            btnType="ghost"
            label="Cancelar"
            onClick={() => close()}
            stretch
            disabled={isLoading}
          />
          <Button
            label="Sim, excluir"
            btnType="danger"
            stretch
            onClick={handleDelete}
            loading={isLoading}
          />
        </div>
      </div>
    </Dialog>
  );
};
