import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

interface ProfissionalProps {
  profissional: any;
  index: number;
  onRemove: (index: number) => void;
  handleChangeProfissionalResponsavel: any;
}

const Profissional = ({
  profissional,
  index,
  onRemove,
  handleChangeProfissionalResponsavel,
}: ProfissionalProps) => {
  return (
    <div className="search-list-card-item p-d-flex p-jc-between">
      <SimpleText
        className="p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {profissional.profissionalSaude.usuario.nome}
      </SimpleText>

      <div className="p-d-flex p-ai-center p-jc-end p-px-0 fixed">
        <>
          <SimpleText
            fontColor={
              profissional.responsavel
                ? FONT_COLOR.COLOR_16
                : FONT_COLOR.COLOR_79
            }
            fontSize={FONT_SIZE.XXS}
            medium={profissional.responsavel}
          >
            Responsável
          </SimpleText>
          <Switch
            className="p-ml-1"
            checked={profissional.responsavel}
            onChange={() => {
              profissional.responsavel
                ? handleChangeProfissionalResponsavel(index, {
                    ...profissional,
                    responsavel: false,
                  })
                : handleChangeProfissionalResponsavel(index, {
                    ...profissional,
                    responsavel: true,
                  });
            }}
          />
          <Button
            className="p-ml-2"
            btnType="ghost"
            icon="fa fa-trash"
            onClick={() => onRemove(index)}
          />
        </>
      </div>
    </div>
  );
};

export default Profissional;
