import { ReactElement, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import { Button } from '../_UI/Button';
import './MobileRow.scss';

interface MobileRowProps {
  children: JSX.Element[] | JSX.Element;
  options: ReactElement;
  className?: string;
}

const MobileRow = ({ children, options, className }: MobileRowProps) => {
  const overlayRef = useRef<OverlayPanel>(null);

  return (
    <div className={'div-container'}>
      <div className={'options-position'}>
        <Button
          type="button"
          icon="fas fa-ellipsis-h"
          btnType="gray"
          onClick={e => overlayRef.current?.toggle(e)}
        />
      </div>

      <OverlayPanel className={className ?? ''} ref={overlayRef}>
        {options}
      </OverlayPanel>
      {children}
    </div>
  );
};

export default MobileRow;
