import { useNavigate } from 'react-router';

import { formatarData } from 'src/utils/date';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  data: any;
  onDelete: (data: any) => void;
  onSwitchStatus: (data: any) => void;
  btnEspecialidades: JSX.Element;
  btnConsultorios: JSX.Element;
}

export const MobileRow = ({
  data,
  onDelete,
  onSwitchStatus,
  btnEspecialidades,
  btnConsultorios,
}: MobileRowProps) => {
  const navigate = useNavigate();

  const options = [
    {
      label: 'Editar',
      command: () => navigate(`/config/personalizacao-prontuarios/${data.id}`),
      checkPermission: 'PRO_MODELO_PRONTUARIO_ALTERAR',
    },

    {
      label: data?.status ? 'Inativar' : 'Ativar',
      command: () => onSwitchStatus(data),
      checkPermission: 'PRO_MODELO_PRONTUARIO_ALTERAR',
    },

    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(data.id),
      checkPermission: 'AGE_COMPROMISSO_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Modelo
        </SimpleText>

        <div className="p-d-flex p-ai-center p-mt-2 p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {data.nomeModelo}
          </SimpleText>
          {data.tipoModelo === 'PADRAO' && (
            <span className="tag-modelo-padrao">Padrão</span>
          )}
          {data.triagem && <span className="tag-modelo-triagem">Triagem</span>}
        </div>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Especialidade
        </SimpleText>

        <div className="p-mt-2">{btnEspecialidades}</div>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Consultórios
        </SimpleText>

        <div className="p-mt-2">{btnConsultorios}</div>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Criado em / Autor
        </SimpleText>

        <div className="p-d-flex p-flex-column p-mt-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {formatarData(data?.dataCriacao)}
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.PRIMARY}
            medium
          >
            {data?.tipoUsuarioCriacao}
          </SimpleText>
        </div>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Editado em / Autor
        </SimpleText>

        <div className="p-d-flex p-flex-column p-mt-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {formatarData(data?.dataAtualizacao)}
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.PRIMARY}
            medium
          >
            {data?.tipoUsuarioCriacao}
          </SimpleText>
        </div>
      </div>
    </SearchListMobileRow>
  );
};
