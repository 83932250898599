import { memo, useCallback, useMemo, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';

import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import './SearchListServicoTuss.scss';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ConfiguracaoGuiaTissAPI from 'src/APIs/ConfigAPI/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissAPI';

interface SearchListServicoTussProps {
  selected: ServicosTussComGuiaTiss[];
  onSelectItem(v: ServicosTussComGuiaTiss): void;
  onRemoveItem(v: ServicosTussComGuiaTiss): void;
  idConvenio: number;
}

const SearchListServicoTuss = (props: SearchListServicoTussProps) => {
  const { selected, onSelectItem, onRemoveItem, idConvenio } = props;

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ServicosTussComGuiaTiss[]>();

  const onChangeSearch = useCallback(
    async (value: string) => {
      setList([]);
      setSearch(value);

      if (value) {
        setLoading(true);
        const response: any =
          await ConfiguracaoGuiaTissAPI.loadServicosTussComConfiguracaoGuiaTiss(
            {
              nome: value,
              idConvenio,
            },
          );

        if (response?.status === 200) {
          // Ordena os dados por nome
          setList(
            response?.data?.sort((a: any, b: any) =>
              a?.nome < b?.nome ? -1 : 1,
            ),
          );
        }
        setLoading(false);
      }
    },
    [idConvenio],
  );

  const listUnselected = useMemo(
    () => selected.filter(i => !list?.some(s => s?.id === i?.id)) || [],
    [list, selected],
  );

  const isSelected = useCallback(
    (item: ServicosTussComGuiaTiss) => selected.some(i => i?.id === item?.id),
    [selected],
  );

  return (
    <div className="SearchListServicoTuss-GuiaTiss">
      <TextInputSearch
        autoFocus
        placeholder="Adicione serviços pesquisando"
        onChange={onChangeSearch}
      />

      <div className="search-list">
        {listUnselected.map(item => (
          <SearchListItemServicosTuss
            selected
            key={item.id}
            item={item}
            onClick={onRemoveItem}
          />
        ))}
        <>
          {loading ? (
            <>
              <div style={{ marginBottom: 8 }}>
                <Skeleton height="56px" borderRadius="8px" />
              </div>
            </>
          ) : (
            <>
              {search ? (
                list?.map(item => (
                  <SearchListItemServicosTuss
                    selected={isSelected(item)}
                    key={item.id}
                    item={item}
                    onClick={isSelected(item) ? onRemoveItem : onSelectItem}
                  />
                ))
              ) : listUnselected?.length === 0 ? (
                <div className="search-list-empty">
                  Os resultados da busca irão aparecer aqui.
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

interface SearchListItemServicosTussProps {
  item: ServicosTussComGuiaTiss;
  onClick(v: ServicosTussComGuiaTiss): void;
  selected?: boolean;
}

const SearchListItemServicosTuss = memo(
  (props: SearchListItemServicosTussProps) => {
    const { item, onClick, selected } = props;

    return (
      <button
        disabled={item?.idGuiaTiss > 0}
        onClick={() => onClick(item)}
        className={`
          SearchListItemServicosTuss-GuiaTiss
          ${selected ? 'item-selected' : ''}
        `}>
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {item?.codigo}
        </SimpleText>

        <SimpleText
          className="item-title"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}>
          - {item?.nome}
        </SimpleText>

        <span
          className={`
            item-button fas
            ${selected ? 'item-button-selected fa-minus' : ' fa-plus'}
          `}
        />
      </button>
    );
  },
);

export default memo(SearchListServicoTuss);
