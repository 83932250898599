import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  motivo: GetManterTrasferenciaApsDTO;
  onEdit: (motivo: GetManterTrasferenciaApsDTO) => void;
  onDelete: (id: number) => void;
  onSwitchStatus: (motivo: GetManterTrasferenciaApsDTO) => void;
}

export const Row = (props: RowProps) => {
  const { motivo, onEdit, onDelete, onSwitchStatus } = props;
  const { isMobile } = useSize();

  const cumonProps = {
    motivo,
    onEdit,
    onDelete,
    onSwitchStatus,
  };

  return isMobile ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
