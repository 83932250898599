import Http, { HttpParams } from 'src/core/http/Http';

import { Paginator } from 'src/components/Table/Table';

const basePath = '/cardapio';

export const loadCardapios = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetCardapioDTO[]>> => {
  return Http.get({
    requestDescription: 'listagem das configurações de cliente',
    service: 'adm',
    url: `${basePath}/todos`,
    hideToast: true,
    ...options,
  });
};

export const sendCardapioNovo = (
  payload: PostCardapioDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostCardapioDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const loadCardapioById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetCardapioDTO> => {
  return Http.get({ service: 'adm', url: `/cardapio/${id}`, ...options });
};

export const sendCardapio = (
  payload: PostCardapioDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostCardapioDTO> => {
  return Http.post({
    service: 'adm',
    url: '/cardapio/salva-cardapio',
    data: payload,
    ...options,
  });
};

export const removeCardapioById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetCardapioDTO> => {
  return Http.delete({
    service: 'adm',
    url: `/cardapio/remove-cardapio/${id}`,
    ...options,
  });
};

export const toggleCardapio = (
  empresa: number,
  funcionalidade: number,
  data: {
    //idUsuarioAtualizacao: number;
    ativa: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'adm',
    url: `/cardapio/ativa-cardapio-empresa/${empresa}/funcionalidade/${funcionalidade}`,
    data,
    ...options,
  });
};

export const CardapioAPI = {
  loadCardapioById,
  sendCardapio,
  removeCardapioById,
  toggleCardapio,
  loadCardapios,
  sendCardapioNovo,
};
