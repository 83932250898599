import React from 'react';

import useSize from 'src/core/hooks/useSize';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface VulnerabilidadeProps {
  value: number;
}

const Vulnerabilidade = ({ value }: VulnerabilidadeProps) => {
  const { isMobile } = useSize();

  const renderSegment = () => {
    const segments = [
      {
        range: [0, 6],
        className: 'border-robusto',
        color: 'var(--brand-color-primary-light)',
        text: (
          <div className="p-d-flex p-jc-center">
            Idoso Robusto
            <br />
            Sem complexidade (0 - 6)
          </div>
        ),
      },
      {
        range: [7, 14],
        className: 'border-pre-fragil',
        color: 'var(--feedback-color-helper-light)',
        text: (
          <div className="p-d-flex p-jc-center">
            Idoso Pré-Frágil
            <br />
            Sem complexidade (7 - 14)
          </div>
        ),
      },
      {
        range: [15, 24],
        className: 'border-fragil-baixa',
        color: 'var(--feedback-color-helper-pure)',
        text: (
          <div className="p-d-flex p-jc-center">
            Idoso frágil
            <br />
            Baixa complexidade (15 - 24)
          </div>
        ),
      },
      {
        range: [25, 34],
        className: 'border-fragil-alta',
        color: 'var(--feedback-color-warning-light)',
        text: (
          <div className="p-d-flex p-jc-center">
            Idoso frágil
            <br />
            Alta complexidade (25 - 34)
          </div>
        ),
      },
      {
        range: [35, 40],
        className: 'border-fragil-final',
        color: 'var(--feedback-color-warning-medium)',
        text: (
          <div className="p-d-flex p-jc-center">
            Idoso frágil
            <br />
            Fase final de vida (35 - 40)
          </div>
        ),
      },
    ];

    const segment: any = segments.find(
      ({ range }: any) => value >= range[0] && value <= range[1],
    );

    if (!segment) return null;

    const percentage =
      ((value - segment.range[0]) / (segment.range[1] - segment.range[0])) *
      100;

    return (
      <div
        className={`${
          segment.className
        } p-d-flex p-ai-center p-jc-center p-p-1 ${isMobile ? 'mobile' : ''}`}
        style={{
          background: `linear-gradient(to right, ${segment.color} 0%, ${segment.color} ${percentage}%, rgba(255, 255, 255, 0.75) ${percentage}%, rgba(255, 255, 255, 0.75) 100%)`,
        }}
      >
        <SimpleText fontSize="xxxs" medium className="p-text-center">
          {segment.text}
        </SimpleText>
      </div>
    );
  };

  return (
    <div
      className={`progress-bar-container-diagnostico p-my-2 ${
        isMobile ? 'mobile' : ''
      }`}
    >
      {renderSegment()}
    </div>
  );
};

export default Vulnerabilidade;
