import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/profissional-saude';

export const retrieveConselhoTiss = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/busca-conselho-tiss`,
    hideToast: true,
    ...options,
  });
};

export const retrieveConselhoTissProfissional = (
  codigoConselhoTiss: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/busca-conselho-tiss-profissional`,
    params: { codigoConselhoTiss },
    hideToast: true,
    ...options,
  });
};

export const retrieveBuscaCbo = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/busca-cbo`,
    hideToast: true,
    ...options,
  });
};

export const retrieveBuscaConvenio = (
  idEmpresa: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/busca-convenio-empresa`,
    params: { idEmpresa },
    hideToast: true,
    ...options,
  });
};

export const updateDadosProfissionais = (
  id: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/dadosProfissionais`,
    data: payload,
    return: true,
    ...options,
  });
};

export const getProfissional = (
  id: any,
  route: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/${route}`,
    hideToast: true,
    ...options,
  });
};

export const postProfissional = (
  payload: Partial<any>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateProfissional = (
  route: string,
  id: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const routeToUse = 'profissional-saude';

  return Http.put({
    service: 'adm',
    url: `/${routeToUse}/${id}/${route}`,
    data: payload,
    return: true,
    // errorHideToast: true
    ...options,
  });
};

export const saveProfissionalOpcionais = (
  route: string,
  id: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const routeToUse = 'profissional-saude';

  return Http.post({
    service: 'adm',
    url: `/${routeToUse}/${id}/${route}`,
    data: payload,
    return: true,
    // errorHideToast: true
    ...options,
  });
};

export const getProfissionalExecutante = (
  idProfissional: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idProfissional}/executante`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
    ...options,
  });

export const getPacientesVinculadosCarteiraAps = (
  idProfissional: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idProfissional}/carteira-aps/pacientes-vinculados`,
    hideToast: true,
    return: true,
    ...options,
  });

const ProfissionalAPI = {
  retrieveConselhoTiss,
  retrieveConselhoTissProfissional,
  postProfissional,
  retrieveBuscaCbo,
  updateProfissional,
  retrieveBuscaConvenio,
  updateDadosProfissionais,
  getProfissional,
  saveProfissionalOpcionais,
  getProfissionalExecutante,
  getPacientesVinculadosCarteiraAps,
};

export default ProfissionalAPI;
