import { useState, useEffect, useMemo } from 'react';

import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';

import { useCountdown } from 'src/core/hooks/Countdown/useCountdown';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface SendTokenBtnProps {
  paciente: any;
}

export const SendTokenBtn = (props: SendTokenBtnProps) => {
  const { paciente } = props;

  const [sendingToken, setSendindToken] = useState(false);
  const [tokenSent, setTokenSent] = useState(false);
  const { countdown, startCountdown } = useCountdown();

  const timeLeftToResend = useMemo(() => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return `0${minutes.toFixed(0)}:${seconds >= 10 ? seconds : '0' + seconds}`;
  }, [countdown]);

  useEffect(() => {
    if (countdown > 0) {
      setTokenSent(true);
    } else {
      setTokenSent(false);
    }
  }, [countdown]);

  const handleSendToken = async () => {
    try {
      setSendindToken(true);

      const payload = {
        idPaciente: paciente.id,
        telefone: paciente.celular,
      };

      await AutorizacaoAPI.postTokenPresenca(payload);

      setTokenSent(true);
      startCountdown(60);
      localStorage.setItem('countdown', '60');
    } catch (error) {
      //
    } finally {
      setSendindToken(false);
    }
  };

  const buttonLabel = tokenSent ? 'Reenviar token' : 'Enviar token';

  return (
    <div className="p-col-12 p-sm-4">
      <Button
        type="button"
        btnType={BtnTypes.TONAL}
        label={buttonLabel}
        onClick={handleSendToken}
        loading={sendingToken}
        disabled={tokenSent && countdown > 0}
      />

      {tokenSent && countdown > 0 && (
        <SimpleText
          className="countdown-token"
          fontColor={FONT_COLOR.DANGER}
          medium
        >
          Tempo para enviar novo token: {timeLeftToResend}
        </SimpleText>
      )}
    </div>
  );
};
