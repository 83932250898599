import { memo, useCallback, useState, useRef, useEffect, useMemo } from 'react';

import HorarioAtendimentoAPI from 'src/APIs/AgendaAPI/HorarioAtendimentoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import Dialog from 'src/components/Dialog/Dialog';

import DesktopRow from './DesktopRow';
import FormHorarioAtendimento from './FormHorarioAtendimento/FormHorarioAtendimento';
import MobileRowHorarioAtendimento from './MobileRowHorarioAtendimento';
import SearchList from './SearchList/SearchList';

import './HorarioAtendimento.scss';

interface DialogState {
  visible: boolean;
  item?: GetHorarioAtendimentoDTO;
}

interface DialogDeleteState {
  visible: boolean;
  id?: number;
}

const HorarioAtendimento = () => {
  const { isMobile, windowInnerWidth } = useSize();

  const { agenda, consultorios } = useAppSelector(state => state);

  const isLg = useMemo(() => windowInnerWidth >= 1176, [windowInnerWidth]);

  const prevIdProfissionalSaudeAtivo = useRef(null);
  const prevIdConsultorioAtivo = useRef(null);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'age_horario_de_atendimento_cadastrar',
      'age_horario_de_atendimento_alterar',
      'age_horario_de_atendimento_consultar',
      'age_horario_de_atendimento_excluir',
    ],
  });

  const [reload, setReload] = useState<number>(0);
  const [dialog, setDialog] = useState<DialogState>({ visible: false });
  const [confirmDelete, setConfirmDelete] = useState<DialogDeleteState>({
    visible: false,
  });

  const fetchApi = useCallback(
    async (page: number): Promise<Paginator<any>> => {
      return await HorarioAtendimentoAPI.loadHorarioAtendimento(
        agenda?.profissionalAtivo?.id,
        consultorios?.ativo?.id,
        page,
      );
    },
    [agenda?.profissionalAtivo?.id, consultorios?.ativo?.id],
  );

  function reloadList() {
    setReload(currentReload => currentReload + 1);
  }

  useEffect(() => {
    if (agenda?.profissionalAtivo?.id !== prevIdProfissionalSaudeAtivo) {
      prevIdProfissionalSaudeAtivo.current = agenda?.profissionalAtivo?.id;
      reloadList();
    }
  }, [agenda?.profissionalAtivo?.id]);

  useEffect(() => {
    if (consultorios?.ativo?.id !== prevIdConsultorioAtivo) {
      prevIdConsultorioAtivo.current = consultorios?.ativo.id;
      reloadList();
    }
  }, [consultorios?.ativo?.id]);

  const handleEdit = useCallback((item: GetHorarioAtendimentoDTO) => {
    setDialog({ visible: true, item });
  }, []);

  const handleDuplicate = useCallback((item: GetHorarioAtendimentoDTO) => {
    setDialog({ visible: true, item: { ...item, id: 0 } });
  }, []);

  const handleDelete = useCallback((item: GetHorarioAtendimentoDTO) => {
    setConfirmDelete({ visible: true, id: item.id });
  }, []);

  const renderHeader = useCallback(() => {
    return (
      <>
        {isMobile === false && (
          <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
            <div className="p-col-2 p-px-0">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Dia
              </SimpleText>
            </div>
            <div className="p-col-2 p-px-0">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Hr. inicial
              </SimpleText>
            </div>
            <div className="p-col-2 p-px-0">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Hr. final
              </SimpleText>
            </div>
            <div className="p-col-2 p-px-0">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Duração
              </SimpleText>
            </div>
            <div className={`p-col-${isLg ? '3' : '2'} p-px-0`}>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Temporário
              </SimpleText>
            </div>
            <div className={`p-col-${isLg ? '1' : '2'} p-px-0`}>
              <Can
                checkPermission={[
                  'AGE_HORARIO_DE_ATENDIMENTO_CADASTRAR',
                  'AGE_HORARIO_DE_ATENDIMENTO_ALTERAR',
                  'AGE_HORARIO_DE_ATENDIMENTO_EXCLUIR',
                ]}
              >
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Ações
                </SimpleText>
              </Can>
            </div>
          </div>
        )}
      </>
    );
  }, [isLg, isMobile]);

  const renderRow = (
    data: any,
    index: number,
    arrLength: number,
  ): JSX.Element => {
    const isLastRow = index === arrLength - 1;

    if (isMobile) {
      return (
        <MobileRowHorarioAtendimento
          data={data}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
          handleEdit={handleEdit}
        />
      );
    }
    return (
      <DesktopRow
        data={data}
        handleDelete={handleDelete}
        handleDuplicate={handleDuplicate}
        handleEdit={handleEdit}
        isLastRow={isLastRow}
      />
    );
  };
  return (
    <>
      <div className="p-mb-4 p-mt-1 p-d-flex p-jc-between p-ai-center">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
          Exiba a agenda do médico com base nos horários cadastrados
        </SimpleText>

        <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_CADASTRAR">
          <Button
            btnType="pill"
            label="Adicionar novo horário"
            icon="fas fa-plus"
            onClick={() => setDialog({ visible: true })}
            style={{
              paddingInline: '30px',
            }}
          />
        </Can>
      </div>

      <div className="HorarioAtendimento">
        <SearchList
          fetchAPI={fetchApi}
          renderRow={renderRow}
          renderHeader={renderHeader}
          reload={reload}
        />

        <FormHorarioAtendimento
          visible={dialog.visible}
          item={dialog.item}
          onHide={() => setDialog({ visible: false })}
          onSuccess={reloadList}
        />
      </div>

      <Dialog
        className="footer-on"
        header="Excluir"
        visible={confirmDelete.visible}
        onHide={() => setConfirmDelete({ visible: false })}
        footer={() => (
          <Footer
            onCancel={() => setConfirmDelete({ visible: false })}
            onConfirm={async () => {
              await HorarioAtendimentoAPI.deleteHorarioAtendimento(
                confirmDelete?.id as number,
                agenda?.profissionalAtivo?.id,
                consultorios?.ativo.id,
              );

              reloadList();

              setConfirmDelete({ visible: false });
            }}
          />
        )}
      >
        <SimpleText>Você realmente deseja excluir este horário?</SimpleText>
      </Dialog>

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

interface FooterProps {
  onCancel(): void;
  onConfirm(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onConfirm } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType={'ghost'} label="Cancelar" onClick={onCancel} stretch />
      <Button
        btnType="danger"
        label="Sim, excluir"
        onClick={onConfirm}
        stretch
      />
    </div>
  );
});

export default memo(HorarioAtendimento);
