import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled, {
  DropdownControlledProps,
} from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type FieldEspecialidadeProps = Omit<DropdownControlledProps, 'control'> & {
  name?: string;
  label: string;
};

export const FieldEspecialidade = ({
  name = 'especialidade',
  label,
  ...rest
}: FieldEspecialidadeProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [especialidades, setEspecialidades] = useState<EspecialidadeDTO[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debounceSearch = useDebounce(querySearch, 350);

  const loadEspecialidades = useCallback(async (queryParam: any) => {
    try {
      if (queryParam.length < 3) return setEspecialidades([]);
      const response = await EspecialidadeAPI.loadEspecialidades(
        {
          nome: queryParam,
        },
        { throwError: true },
      );

      if (response?.list) setEspecialidades(response?.list);
    } catch (error) {
      setEspecialidades([]);
    } finally {
      setLoadingSearch(false);
    }
  }, []);

  useEffect(() => {
    loadEspecialidades(debounceSearch);
  }, [debounceSearch, loadEspecialidades]);

  const especialidadeAtual = watch(name);

  const especialidadeOptions = useMemo(() => {
    const options = especialidades.map(item => ({
      label: item.nome,
      value: item,
    }));

    // valida se a especialidade atual está listado nas options
    if (especialidadeAtual) {
      const isEspecialidadeAtualVisivel = especialidades.find(
        item => item.id === especialidadeAtual.id,
      );

      if (!isEspecialidadeAtualVisivel) {
        options.unshift({
          label:
            especialidadeAtual?.razaoSocial ||
            especialidadeAtual?.nomeFantasia ||
            especialidadeAtual.nome,
          value: especialidadeAtual,
        });
      }
    }

    return options;
  }, [especialidades, especialidadeAtual]);

  const options = especialidadeOptions.length
    ? especialidadeOptions
    : defaultOption;
  const emptyFilterMessage =
    querySearch.length >= 3
      ? 'Nenhuma opção encontrada'
      : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      onFilter={e => {
        setLoadingSearch(true);
        setQuerySearch(e.filter);
      }}
      carregandoFiltro={loadingSearch}
      options={options}
      errorMsg={errors?.[name]?.message}
      filterPlaceholder="Digite para pesquisar"
      hideTextErrorSpace={false}
      emptyFilterMessage={emptyFilterMessage}
      {...rest}
    />
  );
};
