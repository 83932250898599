import { useEffect, useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import CardOptionControlled from '../../components/CardOptionControlled/CardOptionControlled';
import Button from 'src/components/Basics/Button/Buttons';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { saveContato } from '../helpers';

import validationSchema from './validationSchema';

import './Contato.scss';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { useManterPaciente } from '../MaterPacienteContext';

interface ContatoProps {
  camposDemograficosObrigatorios: boolean;
}

const Contato = ({ camposDemograficosObrigatorios }: ContatoProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const { handleSetFormDirty } = useManterPaciente();
  const { idPaciente } = useParams();

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema(camposDemograficosObrigatorios)),
  });

  const [loading, setLoading] = useState<boolean>(true);

  const loadDadosContato = useCallback(async () => {
    if (!idPaciente) return;

    setLoading(true);

    const response = await PacienteAPI.getPacienteContato(Number(idPaciente));

    if (response?.email) setValue('email', response.email);
    if (response?.aceitaMalaDireta)
      setValue('aceitaMalaDireta', response.aceitaMalaDireta);
    if (response?.aceitaWhatsapp)
      setValue('aceitaWhatsapp', response.aceitaWhatsapp);
    if (response?.tipoContatoPrincipal)
      setValue('tipoContatoPrincipal', response.tipoContatoPrincipal);

    response?.contatos?.map((contato: any) =>
      setValue(contato.tipo, contato.numero),
    );

    setLoading(false);
  }, [idPaciente, setValue]);

  useEffect(() => {
    loadDadosContato();
  }, [loadDadosContato]);

  useEffect(() => {
    reset({
      aceitaMalaDireta: false,
      aceitaWhatsapp: false,
    });
  }, [reset]);

  useEffectSkipFirst(() => {
    handleSetFormDirty(isDirty, 'CONTATOS');
  }, [isDirty]);

  const onSubmit = async (data: any) => {
    await saveContato(Number(idPaciente), user?.idEmpresa, data);

    reset(data, { keepValues: true });
  };

  if (loading)
    return (
      <div className="p-grid">
        <div className="p-col-12 p-md-8 p-lg-6">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <form
      id="contato-manter-paciente"
      onSubmit={handleSubmit(onSubmit)}
      className="p-grid p-col-12 p-md-8 p-lg-6"
    >
      <div className="p-col-12 p-md-8">
        <TextInputControlled
          control={control}
          name="email"
          label="E-mail"
          placeholder="exemplo@teste.com.br"
          errorMsg={errors.email?.message}
        />
        <CheckboxControlled
          className="checkbox-small"
          control={control}
          name="aceitaMalaDireta"
          label="Aceita receber mala direta"
        />
      </div>

      <SimpleText className="p-col-12 p-md-8" fontColor={FONT_COLOR.COLOR_16}>
        O quadro marcado será o contato principal do paciente e irá aparecer na
        agenda de forma fácil e prática.
      </SimpleText>

      <div className="p-grid p-col-12 p-d-flex">
        <div className="p-col-12 p-xl-6">
          <CardOptionControlled
            className="p-d-flex"
            control={control}
            name="tipoContatoPrincipal"
            value="CELULAR_PARTICULAR"
          >
            <div className="p-ml-2 column">
              <MaskedInputControlled
                control={control}
                name="CELULAR_PARTICULAR"
                label="Celular"
                placeholder="(99) 99999-9999"
                mask={MASK.PHONE}
                errorMsg={errors.CELULAR_PARTICULAR?.message}
              />
              <CheckboxControlled
                className="checkbox-small"
                control={control}
                name="aceitaWhatsapp"
                label="Aceita receber mensagens no WhatsApp"
              />
            </div>
          </CardOptionControlled>
        </div>

        <div className="p-col-12 p-xl-6">
          <CardOptionControlled
            className="p-d-flex"
            control={control}
            name="tipoContatoPrincipal"
            value="RECADO"
          >
            <MaskedInputControlled
              className="p-ml-2 p-w-100"
              control={control}
              name="RECADO"
              label="Telefone para recados"
              placeholder="(99) 99999-9999"
              mask={MASK.PHONE}
            />
          </CardOptionControlled>
        </div>

        <div className="p-col-12 p-xl-6">
          <CardOptionControlled
            className="p-d-flex"
            control={control}
            name="tipoContatoPrincipal"
            value="FIXO_RESIDENCIAL"
          >
            <MaskedInputControlled
              className="p-ml-2 p-w-100"
              control={control}
              name="FIXO_RESIDENCIAL"
              label="Telefone residencial"
              placeholder="(99) 9999-9999"
              mask={MASK.TELEPHONE}
            />
          </CardOptionControlled>
        </div>

        <div className="p-col-12 p-xl-6">
          <CardOptionControlled
            className="p-d-flex"
            control={control}
            name="tipoContatoPrincipal"
            value="CELULAR_COMERCIAL"
          >
            <MaskedInputControlled
              className="p-ml-2 p-w-100"
              control={control}
              name="CELULAR_COMERCIAL"
              label="Telefone comercial"
              placeholder="(99) 99999-9999"
              mask={MASK.PHONE}
            />
          </CardOptionControlled>
        </div>

        {errors.tipoContatoPrincipal?.message && (
          <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
            {errors.tipoContatoPrincipal?.message}
          </SimpleText>
        )}
      </div>

      <div className="p-col-12 p-mt-5">
        <Button
          type="submit"
          label="Salvar alterações"
          loading={isSubmitting}
        />
      </div>
    </form>
  );
};

export default Contato;
