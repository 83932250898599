import Http, { HttpParams } from 'src/core/http/Http';

export const getCids = async (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/atendimento/${idAtendimento}/cids`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const getVersoesCids = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: '/utils/versoes-cids',
    hideToast: true,
    return: true,
    ...options,
  });
};

export const buscaCids = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: '/utils/busca-cids',
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const updateCid = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: '/atendimento/cid',
    data: payload,
    throwError: true,
    return: true,
    ...options,
  });
};

const CidAPI = {
  getCids,
  getVersoesCids,
  buscaCids,
  updateCid,
};

export default CidAPI;
