import { forwardRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useFormContext } from 'react-hook-form';

import { FieldStatusServico } from '../components/FieldStatusServico';
import { Button } from 'src/components/_UI';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const MonitoramentoTissAdvancedFilter = forwardRef<any, any>(
  ({ onSubmit }: any, ref: any) => {
    const form = useFormContext();
    const { setFilter } = useSearchList();

    const handleClean = () => {
      form.reset();
      form.reset({
        statusChamada: null,
        tempoResposta: null,
        periodoDe: null,
        ate: null,
      });
      setFilter({});

      ref?.current?.hide();
    };

    return (
      <OverlayPanel ref={ref} className="filtro-avancado-monitoramento-tiss">
        <SimpleText fontColor="color_40">
          Pesquise de forma detalhada, preenchendo um ou mais dos campos abaixo.
        </SimpleText>

        <FieldStatusServico
          label="Status de chamada"
          className="p-col-12 p-px-0"
        />

        <FormInput
          name="tempoResposta"
          label="Tempo de resposta (segundos)"
          type="number"
          placeholder="Acima de ..."
          className="p-col-12 p-px-0"
          hideTextErrorSpace
          max={9999}
        />

        <div className="p-d-flex p-col-12 p-ai-center p-gap-1">
          <Button
            type="button"
            label="Limpar"
            btnType="green-link"
            className="p-col"
            onClick={handleClean}
          />
          <Button
            onClick={e => [onSubmit(e), ref?.current?.hide()]}
            label="Pesquisar"
            className="p-col-8"
          />
        </div>
      </OverlayPanel>
    );
  },
);
