import React from 'react';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  data: any;
  handleConfigFuncionalidade: any;
  alertDivergencia: any;
  switchStatus: JSX.Element;
}

const DesktopRow = ({
  data,
  handleConfigFuncionalidade,
  alertDivergencia,
  switchStatus,
}: DesktopRowProps) => {
  return (
    <>
      <div className={'grid-list-item'}>
        <SimpleText
          className="p-col-4 p-lg-3 p-wordbreak-all"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
          medium
        >
          {data?.funcionalidade?.nome}
        </SimpleText>
        <SimpleText
          className="p-col p-wordbreak-normal"
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XS}
        >
          {data?.funcionalidade?.descricao}
        </SimpleText>
        <div className="p-col-5 p-md-3 p-lg-2">
          <div className={'ClientesOptions'}>
            {switchStatus}
            <Divider layout={'vertical'} />
            <div className="btns">
              <Button
                btnType="gray"
                icon="pi pi-cog"
                onClick={() => handleConfigFuncionalidade(data)}
              />
            </div>
          </div>
        </div>
      </div>
      {alertDivergencia}
    </>
  );
};

export default DesktopRow;
