import { useEffect, useCallback, useState } from 'react';

import { toast } from 'react-toastify';

import ReceitaAPI from 'src/APIs/ProntuarioAPI/ReceitaAPI/ReceitaAPI';
import { ReceitaMemedProps } from 'src/models/APIs/ProntuarioAPI/Receita/ReceitaDTOs';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { EmptyData } from '../../../components/EmptyData';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import './ReceitaHistorico.scss';

export default function ReceitaHistorico() {
  const { selectedAtendimento } = useHistoricoAtendimento();

  const [receitas, setReceitas] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadReceitas = useCallback(async () => {
    setIsLoading(true);
    ReceitaAPI.getReceitasAtendimento(
      { idAtendimento: selectedAtendimento.idAtendimento },
      {},
      { somenteAtendimento: true, pageSize: 9999 },
    )
      .then(data => data?.list && setReceitas(data.list))
      .finally(() => setIsLoading(false));
  }, [selectedAtendimento?.idAtendimento]);

  useEffect(() => {
    loadReceitas();
  }, [loadReceitas]);

  if (isLoading) {
    return (
      <div className="receita-historico-container">
        <div className="p-d-flex p-jc-between p-ai-center">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Receita
          </SimpleText>
        </div>

        <div className="receita-content p-mt-3">
          <GridListLoading />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="receita-historico-container">
        <div className="p-d-flex p-jc-between p-ai-center">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Receita
          </SimpleText>
        </div>

        <div className="receita-content p-mt-3">
          {!!receitas?.length ? (
            <>
              {receitas.map((item: ReceitaMemedProps, idx: number) => {
                return (
                  <div className={idx !== 0 ? 'p-mt-3' : ''} key={idx}>
                    <ReceitaContent item={item} />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="content-empty-row">
              <EmptyData />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function ReceitaContent({ item }: { item: ReceitaMemedProps }) {
  const { onCopy, hasCopied } = useCopyClipboard();

  const handleCopiarLink = () => {
    onCopy(item.linkReceitaDigital);
  };

  useEffectSkipFirst(() => {
    if (hasCopied) {
      toast(<Toast />, {
        data: {
          title: 'Sucesso!',
          message: 'Link copiado com sucesso!',
          type: 'success',
        },
      });
    }
  }, [hasCopied]);

  const localDate = new Date(item.dataAtualizacao).toLocaleDateString('pt-BR');

  return (
    <Panel
      header={(isCollapsed, onToggle) => (
        <div className="panel-header-receita p-grid p-col-12 p-align-center">
          <div className={'p-col-1 p-d-flex p-flex-column p-gap-1 p-mr-4'}>
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Tipo
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Receita
            </SimpleText>
          </div>

          <Separator layout="vertical" />

          <div className="p-col-4 p-d-flex p-flex-column p-gap-1 p-mr-4">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Medicamento
            </SimpleText>
            <SimpleText
              className="p-text-truncate"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {item.medicamento}
            </SimpleText>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {localDate || '-'}
            </SimpleText>
          </div>
          <div className="p-d-flex p-flex-1 p-jc-end p-align-center p-gap-2">
            <Button
              icon="fa-regular fa-copy"
              btnType="ghost"
              onClick={handleCopiarLink}
            />
            <Button
              icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
              btnType="ghost"
              onClick={onToggle}
            />
          </div>
        </div>
      )}
    >
      <div className="panel-content-receita">
        <div className="content-receita">
          <div>
            <div className="w-10 p-d-flex p-flex-column p-gap-1 ">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Via
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {/* {item.via} */}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-1 p-flex-column p-gap-1 ">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Quantidade
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {item.quantidade !== '0' && item.quantidade !== null
                  ? `${item.quantidade} embalagem`
                  : 'Uso contínuo'}
              </SimpleText>
            </div>
          </div>
          <div className="p-d-flex p-flex-1 p-flex-column p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Posologia
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {item.posologia}
            </SimpleText>
          </div>
        </div>
      </div>
    </Panel>
  );
}
