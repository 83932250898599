import { useState } from 'react';

import { UncontrolledFieldConvenio } from 'src/components/Fields/FieldConvenio/UncontrolledFieldConvenio';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const ConvenioFilter = () => {
  const { setFilter } = useSearchList();

  const [convenio, setConvenio] = useState<Convenio | null>(null);

  const handleChangeConvenio = (e: any) => {
    const convenio: Convenio = e.value;

    setConvenio(convenio);
    setFilter({ idConvenio: convenio?.id });
  };

  return (
    <UncontrolledFieldConvenio
      className="p-col-12 p-md-6 p-lg-4 p-pl-0 p-pr-0 p-pr-md-2"
      label="Convênio"
      convenio={convenio}
      onChange={handleChangeConvenio}
    />
  );
};
