import { Button } from 'src/components/_UI/Button';
import Skeleton from 'src/components/Skeleton/Skeleton';

const PickListSkeleton = () => {
  return (
    <div
      id="pickList-container"
      className="p-d-flex p-jc-between"
      style={{ width: '650px' }}
    >
      <div className="p-col-4 p-px-0">
        <div className="p-col-12 p-px-0 box">
          <Skeleton width="100%" height="400px" loading={true} />
        </div>
      </div>
      <div className="p-col-4 p-px-4 p-d-flex p-flex-column p-jc-center p-mb-3 box">
        <Button
          type="button"
          className="p-mt-3"
          label="Adicionar seleção"
          btnType="tonal"
          icon="fa fa-angle-right"
          onClick={() => {}}
          disabled
        />
        <Button
          type="button"
          className="p-mt-3"
          label="Adicionar todos"
          btnType="tonal"
          icon="fa fa-angle-double-right"
          onClick={() => {}}
          disabled
        />

        <Button
          type="button"
          className="p-mt-3 btn-remover"
          label="Remover seleção"
          btnType="danger"
          icon="fa fa-close"
          onClick={() => {}}
          disabled
        />

        <Button
          type="button"
          className="p-mt-3 btn-remover"
          label="Remover todos"
          btnType="danger"
          icon="fa fa-close"
          onClick={() => {}}
          disabled
        />
      </div>
      <div className="p-col-4 p-px-0 p-d-flex p-jc-end content">
        <div className="p-col-12 p-px-0 box">
          <Skeleton width="100%" height="400px" loading={true} />
        </div>
      </div>
    </div>
  );
};

export default PickListSkeleton;
