import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

type IProps = DisclosureType & {
  description: string;
  api: (id: number, values: any, options: any) => void;
  isView?: boolean;
  item: any;
  onSuccess?: () => void;
};

export const DialogInativacao = ({
  description,
  api,
  isView,
  close,
  item,
  onSuccess,
}: IProps) => {
  const form = useForm({
    defaultValues: {
      justificativa: item.justificativa || '',
    },
    resolver: yupResolver(
      !item?.isView
        ? Yup.object({
            justificativa: Yup.string().required('Justificativa é obrigatória'),
          })
        : Yup.object({}),
    ),
  });

  const onSubmit = async (values: any) => {
    if (!item.id) return;

    try {
      await api(item.id, values, { throwError: true });
      onSuccess?.();
      close();
    } catch {
    } finally {
      return;
    }
  };

  const text = isView
    ? `Este item foi inativo em ${dayjs(item.dataAtualizacao).format(
        'DD [de] MMM [de] YYYY',
      )} por ${item.usuarioAlteracao?.nome || '...'}`
    : description;

  return (
    <Dialog
      onHide={close}
      visible
      closable={form.formState?.isSubmitting ? false : true}
      header="Inativar prescrição?"
      style={{ width: '480px' }}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-column p-gap-3"
        >
          <SimpleText className="p-w-100">{text}</SimpleText>
          <TextareaInputControlled
            name="justificativa"
            control={form.control}
            label="Justificativa da inativação"
            readOnly={isView}
          />
          <div className="p-col-12 p-px-0 p-d-flex p-gap-2 p-ai-center p-mt-1">
            {isView ? (
              <Button
                label="Fechar"
                btnType="outline"
                onClick={() => close()}
                stretch
              />
            ) : (
              <>
                <Button
                  btnType="ghost"
                  label="Cancelar"
                  onClick={() => close()}
                  disabled={form.formState.isSubmitting}
                  stretch
                />
                <Button
                  label="Confirmar"
                  type="submit"
                  btnType="danger"
                  stretch
                  loading={form.formState.isSubmitting}
                />
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
