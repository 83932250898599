import { forwardRef, memo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { DIA_SEMANA, formatHourMinuteToDate } from 'src/utils/date';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import ChipSelectControlled from 'src/components/ChipSelect/ChipSelectControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

interface FormProps {
  edit: boolean | undefined;
  initialValue?: any;
  onSubmit(form: any): void;
  onCancel(): void;
}

const validationSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  diaDaSemana: Yup.string().when('id', {
    is: (id: number) => !!id,
    then: Yup.string().required(
      'O campo dia de disponibilidade é obrigatório.',
    ),
  }),
  diaDaSemanaList: Yup.array()
    .nullable()
    .when('id', {
      is: (id: number) => !id,
      then: Yup.array().required(
        'O campo dia de disponibilidade é obrigatório.',
      ),
    }),
  horarioInicial: Yup.date()
    .nullable()
    .required('O campo horário inicial é obrigatório.')
    .max(
      Yup.ref('horarioFinal'),
      'Horário inicial não pode ser maior que horário final',
    ),
  horarioFinal: Yup.date()
    .nullable()
    .required('O campo horário final é obrigatório.')
    .min(
      Yup.ref('horarioInicial'),
      'Horário final não pode ser menor que horário inicial',
    ),
  intervalo: Yup.mixed().required(
    'O campo de duração das consultas é obrigatório.',
  ),
  intervaloPersonalizado: Yup.number()
    .when('intervalo', {
      is: 'OUTRO',
      then: Yup.number()
        .required('O campo duração personalizada é obrigatório')
        .typeError('O campo duração personalizada é obrigatório'),
    })
    .typeError('O campo duração personalizada é obrigatório'),
});

const FormStep1HorarioAtendimento = forwardRef((props: FormProps) => {
  const { edit, initialValue, onSubmit, onCancel } = props;

  const useFormMethods = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
  } = useFormMethods;

  const watchIntervalo = watch('intervalo');

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit((data: any) => onSubmit(data))}
        className="p-grid"
      >
        <SimpleText className="p-col-12">
          Selecione o dia de disponibilidade do médico
        </SimpleText>

        {edit ? (
          <div className="p-col-12">
            <RadioButtonGroupControlled
              name="diaDaSemana"
              control={control}
              items={WEEK_OPTIONS}
            />
          </div>
        ) : (
          <div className="p-col-12">
            <ChipSelectControlled
              name="diaDaSemanaList"
              control={control}
              options={WEEK_OPTIONS}
            />
          </div>
        )}

        <SimpleText className="p-col-12">
          Defina o horário de início da primeira e última consulta do dia
          selecionado
        </SimpleText>

        <CalendarInputControlled
          name="horarioInicial"
          control={control}
          className="p-col-12 p-md-6"
          placeholder="Horário inicial"
          showTime
          hourFormat="24"
          showIcon
          timeOnly
          hideOnDateTimeSelect
          errorMsg={errors?.horarioInicial?.message}
          icon="fas fa-clock"
        />

        <CalendarInputControlled
          name="horarioFinal"
          control={control}
          className="p-col-12 p-md-6"
          placeholder="Horário final"
          showTime
          hourFormat="24"
          showIcon
          timeOnly
          hideOnDateTimeSelect
          errorMsg={errors?.horarioInicial?.message}
          icon="fas fa-clock"
        />

        <SimpleText className="p-col-12">
          Defina a duração das consultas (em minutos)
        </SimpleText>

        <div className="p-col-12">
          <RadioButtonGroupControlled
            name="intervalo"
            control={control}
            items={DURACAO_OPTIONS}
            error={errors?.intervalo?.message}
          />
        </div>

        {watchIntervalo === 'OUTRO' && (
          <FormInput
            name="intervaloPersonalizado"
            className="p-col-6"
            label="Duração personalizada (em minutos)"
            errorMsg={errors?.intervaloPersonalizado?.message}
            permitirApenasNumeros
          />
        )}

        <CheckboxControlled
          name="prestadorExecutante"
          control={control}
          label="Prestador executante"
          className="p-col-12"
        />

        <div className="p-col-12 p-grid">
          <div className="p-col-6 p-d-flex p-jc-center">
            <Button
              label="Cancelar"
              btnType={BtnTypes.GHOST}
              onClick={onCancel}
              disabled={isSubmitting}
            />
          </div>
          <div className="p-col-6">
            <Button
              label="Próximo"
              btnType={BtnTypes.TONAL}
              type="submit"
              loading={isSubmitting}
              stretch
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
});

export const transformItemStep1 = (item: any | undefined) => {
  if (!item) return;

  const horarioInicial = formatHourMinuteToDate(item?.horarioInicial);
  const horarioFinal = formatHourMinuteToDate(item?.horarioFinal);

  const { id, diaDaSemana, prestadorExecutante } = item;

  // Verifica se value de duração é um option
  const isOption = DURACAO_OPTIONS.find(i => i.value === item.intervalo);
  const intervalo = isOption ? item.intervalo : 'OUTRO';
  // - Duração personalizada
  const intervaloPersonalizado = isOption ? undefined : item.intervalo;

  const newItem = {
    id,
    diaDaSemana,
    horarioInicial,
    horarioFinal,
    intervalo,
    intervaloPersonalizado,
    prestadorExecutante,
  };
  return newItem;
};

export const DURACAO_OPTIONS = [
  { label: '05', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: 'Personalizado', value: 'OUTRO' },
];

const classNameContainer = 'p-ml-2 p-mb-4';
const WEEK_OPTIONS = [
  { label: 'Segunda', value: DIA_SEMANA.Segunda, classNameContainer },
  { label: 'Terça', value: DIA_SEMANA.Terca, classNameContainer },
  { label: 'Quarta', value: DIA_SEMANA.Quarta, classNameContainer },
  { label: 'Quinta', value: DIA_SEMANA.Quinta, classNameContainer },
  { label: 'Sexta', value: DIA_SEMANA.Sexta, classNameContainer },
  { label: 'Sábado', value: DIA_SEMANA.Sabado, classNameContainer },
  { label: 'Domingo', value: DIA_SEMANA.Domingo, classNameContainer },
];

export default memo(FormStep1HorarioAtendimento);
