import { ReactNode, useEffect, useState } from 'react';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { UserContext } from 'src/core/contexts/Users/Users';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const { user: userRedux } = useAppSelector((state: RootState) => state);
  const [user, setUser] = useState<GetUsuarioDTO>();

  const getUser = async (id: number) => {
    const response = await UsuarioAPI.loadUsuarioById(id);
    setUser(response);
  };

  useEffect(() => {
    if (userRedux?.idUsuario) {
      getUser(userRedux.idUsuario);
    }
  }, [userRedux?.idUsuario]);

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
