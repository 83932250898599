import * as Yup from 'yup';

const bebesValidationSchema = (desfecho: string) =>
  Yup.object({
    nomeBebe: Yup.string()
      .nullable()
      .when({
        is: () => !(desfecho === 'ABORTO'),
        then: field => field.required('O campo é obrigatório'),
      }),
    idSexoBebe: Yup.string()
      .nullable()
      .when({
        is: () => !(desfecho === 'ABORTO'),
        then: field => field.required('O campo é obrigatório'),
      }),
    pesoAoNascer: Yup.string().when({
      is: () => desfecho === 'NASCIMENTO',
      then: field => field.required('O campo é obrigatório'),
    }),
    pesoNascimentoUnidadeMedida: Yup.string().when({
      is: () => desfecho === 'NASCIMENTO',
      then: field => field.required('O campo é obrigatório'),
    }),
    natirmorto: Yup.boolean(),
    idCid: Yup.string().when('natirmorto', {
      is: (natirmorto: boolean) => natirmorto,
      then: field => field.required('O campo é obrigatório'),
    }),
  });

const validationSchema = Yup.object().shape({
  desfecho: Yup.string().required('O campo é obrigatório'),
  tipoParto: Yup.string().when('desfecho', {
    is: (desfecho: string) => desfecho === 'NASCIMENTO',
    then: field => field.required('O campo é obrigatório'),
  }),
  dataParto: Yup.date().when('desfecho', {
    is: (desfecho: string) => desfecho === 'NASCIMENTO',
    then: field => field.required('O campo é obrigatório'),
  }),
  idCid: Yup.string().when('desfecho', {
    is: (desfecho: string) => desfecho === 'ABORTO',
    then: field => field.required('O campo é obrigatório'),
  }),
  bebes: Yup.array().when('desfecho', desfecho => {
    return Yup.array().of(bebesValidationSchema(desfecho));
  }),
});

export default validationSchema;
