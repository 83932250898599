import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { Button } from 'src/components/_UI';

export const NavBarNotificacaoAvaliacao = ({
  idAtendimento,
  onSuccess,
}: {
  idAtendimento: number;
  onSuccess(): void;
}) => {
  const [selectedEmoji, setSelectedEmoji] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSendFeecback = () => {
    if (selectedEmoji) {
      setIsLoading(true);

      PortalPacienteAPI.sendAvaliacaoAtendimento(
        {
          idAtendimento,
          nota: selectedEmoji,
        },
        {
          hideToast: true,
        },
      )
        .then(() => {
          onSuccess();
          dispatch(
            setInvalidateQuery({
              invalidateNotificacoes: true,
            }),
          );
        })
        .finally(() => [setIsLoading(false), close()]);
    }
  };

  return (
    <>
      <div className="emoji-area-notification p-d-flex p-gap-2 p-mt-1">
        <div
          className={`emoji-container ${selectedEmoji === 1 ? 'selected' : ''}`}
          onClick={() => setSelectedEmoji(1)}
        >
          😣
        </div>
        <div
          className={`emoji-container ${selectedEmoji === 2 ? 'selected' : ''}`}
          onClick={() => setSelectedEmoji(2)}
        >
          ☹️
        </div>
        <div
          className={`emoji-container ${selectedEmoji === 3 ? 'selected' : ''}`}
          onClick={() => setSelectedEmoji(3)}
        >
          😐
        </div>
        <div
          className={`emoji-container ${selectedEmoji === 4 ? 'selected' : ''}`}
          onClick={() => setSelectedEmoji(4)}
        >
          😊
        </div>
        <div
          className={`emoji-container ${selectedEmoji === 5 ? 'selected' : ''}`}
          onClick={() => setSelectedEmoji(5)}
        >
          🥰
        </div>
      </div>

      <Button
        label="Enviar"
        disabled={!selectedEmoji}
        loading={isLoading}
        className="p-mt-1 p-col-6 p-ml-auto"
        onClick={handleSendFeecback}
      />
    </>
  );
};
