import './AssinaturasProntuario.scss';
import { useEffect, useState } from 'react';

import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DialogAssinarDigitalmente } from './components/DialogAssinarDigitalmente';
import Row from './components/Row';
import { SOAPResumo } from './components/SOAPResumo';
import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

export const AssinaturaProntuarioList = () => {
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);

  const [quantidadeAssinaturaBloco, setQuantidadeAssinaturaBloco] =
    useState<any>(0);
  const [listaProntuarios, setListaProntuarios] = useState<any>([]);
  const [selectedAtendimento, setSelectedAtendimento] = useState<any>(null);

  const {
    webSigner: { handleSign },
    modeloAssinatura,
    handleSignBlocoHSM,
    dialogAssinarDigitalmente,
  } = useAssinatura();

  const dialogHistoricoSOAP = useDisclosure({ opened: false });

  useEffect(() => {
    const getQuantidadeAssinaturaBloco = async () => {
      const responseQuantidadeAssinaturaBloco =
        await ParametroAPI.loadParametros(
          {
            codigoParametro: 'ASSINATURA_PRONTUARIO_QUANTIDADE',
          },
          { throwError: true },
        );
      setQuantidadeAssinaturaBloco(responseQuantidadeAssinaturaBloco.list[0]);
    };

    getQuantidadeAssinaturaBloco();
  }, []);

  const fetchAPI = async (params: any) => {
    const fetchParams = {
      sortBy: 'dataOcorrencia',
      sortDirection: 'DESC',
      pageSize: 100,
      ...params,
    };
    const response = await AssinaturaAPI.getProntuariosNaoAssinados(
      fetchParams,
    );
    if (response.status === 200) {
      setListaProntuarios((prev: any) => [...prev, ...response?.data?.list]);
      return response?.data;
    }
    return [];
  };

  const onSignBloco = async () => {
    setLoading(true);
    const prontuariosParaAssinar = listaProntuarios.slice(
      0,
      quantidadeAssinaturaBloco?.valor,
    );

    const base64Promises = prontuariosParaAssinar.map(
      async (prontuario: any) => {
        const response = await AtendimentoAPI.getProntuarioAssinar(
          prontuario.id,
        );
        return {
          atendimentoId: prontuario.id,
          prontuarioBase64: response?.data,
        };
      },
    );

    const listaBase64 = await Promise.all(base64Promises);

    const documents = listaBase64.map((item: any) => ({
      content: item.prontuarioBase64.prontuarioBase64,
      contentType: 'BASE64',
      docId: item.prontuarioBase64.sequenciaAssinatura,
    }));

    const atendimentoIds = listaBase64.map((item: any) => item.atendimentoId);

    try {
      if (modeloAssinatura === 'HSM') {
        await handleSignBlocoHSM(listaBase64);
      } else {
        await handleSign(
          {
            documents: documents,
          },
          {
            tipoDocumento: 'EXAMES_CIRURGIAS_GUIA',
          },
          atendimentoIds,
        );
      }
      setListaProntuarios((prev: any) =>
        prev.filter((item: any) => !atendimentoIds.includes(item.id)),
      );
    } catch (err: any) {
      console.error(err);
    } finally {
      setReload(prev => prev + 1);
      setLoading(false);
    }
  };

  const renderRow = (data: any): JSX.Element => (
    <Row
      dataDocumento={data}
      setReload={setReload}
      setSelectedAtendimento={setSelectedAtendimento}
      dialogHistoricoSOAP={dialogHistoricoSOAP}
    />
  );

  return (
    <Page id="assinaturas-prontuario-container" white>
      <>
        <PageHeader
          title="Assinatura de prontuários"
          subtitle="Tenha uma visão detalhada sobre os prontuários com pendência de assinatura."
        />

        <div className="assinaturas-prontuario-content">
          <SearchList.Root
            fetchApi={fetchAPI}
            empresaRequired
            reloadWhenEmpresaChange
          >
            <SearchList.BorderContainer>
              <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-end p-ai-center p-my-2">
                <Button
                  type="button"
                  label={`Assinar últimos ${
                    quantidadeAssinaturaBloco?.valor || 0
                  }`}
                  icon="fas fa-file-signature"
                  onClick={onSignBloco}
                  loading={loading}
                  style={{
                    width: '240px',
                  }}
                  //! Validar permissão
                  checkPermission="PRO_ASSINATURA_ELETRONICA"
                />
              </div>

              <Header />

              <SearchList.InfiniteScroll
                renderRow={renderRow}
                reload={reload}
              />
            </SearchList.BorderContainer>
          </SearchList.Root>
        </div>

        {dialogAssinarDigitalmente.isOpen && (
          <DialogAssinarDigitalmente {...dialogAssinarDigitalmente} />
        )}

        {dialogHistoricoSOAP.isOpen && (
          <SOAPResumo
            {...dialogHistoricoSOAP}
            selectedAtendimento={selectedAtendimento}
          />
        )}
      </>
    </Page>
  );
};

const Header = () => {
  const { isTablet } = useSize();

  return (
    <div className="p-grid">
      <SimpleText
        className="p-col-4 p-md-2"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Data atendimento
      </SimpleText>
      <SimpleText
        className="p-col-4 p-md-5"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Nome
      </SimpleText>
      {!isTablet && (
        <SimpleText
          className="p-col-3 p-md-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data nascimento
        </SimpleText>
      )}
      <SimpleText
        className="p-col p-text-right p-mr-5"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Ações
      </SimpleText>
    </div>
  );
};
