import { useState, useRef, useEffect, useCallback, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import RecuperarSenhaAPI from 'src/APIs/SecurityAPI/RecuperarSenhaAPI/RecuperarSenhaAPI';
import ValidarCodigoAPI from 'src/APIs/SecurityAPI/ValidarCodigoAPI/ValidarCodigoAPI';

import ThemeContext from 'src/core/themes/ThemeContext';

import { validateEmail } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import LoginLayout from 'src/components/LoginLayout/LoginLayout';

import './ValidacaoCodigo.scss';

export const ValidacaoCodigo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { theme } = useContext(ThemeContext);

  const [login, setLogin] = useState('');
  const [supportVisible, setSupportVisible] = useState(false);
  const [primeiroNumero, setPrimeiroNumero] = useState('');
  const [segundoNumero, setSegundoNumero] = useState('');
  const [terceiroNumero, setTerceiroNumero] = useState('');
  const [quartoNumero, setQuartoNumero] = useState('');
  const [quintoNumero, setQuintoNumero] = useState('');
  const [sextoNumero, setSextoNumero] = useState('');
  const [setimoNumero, setSetimoNumero] = useState('');
  const [oitavoNumero, setOitavoNumero] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [reenviarAberto, setReenviarAberto] = useState(true);
  const [trocarEmail, setTrocarEmail] = useState(false);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(0);
  const [errorEmail, setErrorEmail] = useState(false);

  const primeiroNumeroRef = useRef<any>(null);
  const segundoNumeroRef = useRef<any>(null);
  const terceiroNumeroRef = useRef<any>(null);
  const quartoNumeroRef = useRef<any>(null);
  const quintoNumeroRef = useRef<any>(null);
  const sextoNumeroRef = useRef<any>(null);
  const setimoNumeroRef = useRef<any>(null);
  const oitavoNumeroRef = useRef<any>(null);

  useEffect(() => {
    if (!Object.keys(state)?.length) return navigate('/login');

    if (state.login) {
      setLogin(state.login);
    }
  }, [navigate, state]);

  useEffect(() => {
    if (
      primeiroNumero.length === 1 &&
      segundoNumero.length === 1 &&
      terceiroNumero.length === 1 &&
      quartoNumero.length === 1 &&
      quintoNumero.length === 1 &&
      sextoNumero.length === 1 &&
      setimoNumero.length === 1 &&
      oitavoNumero.length === 1
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    primeiroNumero,
    segundoNumero,
    terceiroNumero,
    quartoNumero,
    quintoNumero,
    sextoNumero,
    setimoNumero,
    oitavoNumero,
  ]);

  const getTimeToResend = useCallback((): string => {
    const minutes = Math.floor(totalTimeInSeconds / 60);
    const seconds = totalTimeInSeconds % 60;

    return `0${minutes.toFixed(0)}:${seconds >= 10 ? seconds : '0' + seconds}`;
  }, [totalTimeInSeconds]);

  useEffect(() => {
    if (totalTimeInSeconds === 0) {
      setReenviarAberto(true);
      setTotalTimeInSeconds(0);
      return;
    } else {
      setTimeout(() => {
        setTotalTimeInSeconds(totalTimeInSeconds - 1);
      }, 1000);
    }
  }, [totalTimeInSeconds]);

  const handleValidarCodigo = useCallback(async () => {
    const codigoValidacao =
      primeiroNumero +
      segundoNumero +
      terceiroNumero +
      quartoNumero +
      quintoNumero +
      sextoNumero +
      setimoNumero +
      oitavoNumero;

    const isEmail = validateEmail(login);

    const data = {
      email: isEmail ? login : undefined,
      login: !isEmail ? login : undefined,
      codigoValidacao,
    };

    try {
      const res = await ValidarCodigoAPI.sendValidarCodigo(data, {
        throwError: true,
      });

      navigate(`/redefine`, {
        state: {
          idUsuario: res.data.id,
          login: data.login,
          codigoValidacao: data.codigoValidacao,
        },
      });
    } catch {}
  }, [
    login,
    navigate,
    oitavoNumero,
    primeiroNumero,
    quartoNumero,
    quintoNumero,
    segundoNumero,
    setimoNumero,
    sextoNumero,
    terceiroNumero,
  ]);

  const contagemReenviar = useCallback(async () => {
    setPrimeiroNumero('');
    setSegundoNumero('');
    setTerceiroNumero('');
    setQuartoNumero('');
    setQuintoNumero('');
    setSextoNumero('');
    setSetimoNumero('');
    setOitavoNumero('');

    const isEmail = validateEmail(login);

    try {
      await RecuperarSenhaAPI.sendRecuperarSenha(
        {
          email: isEmail ? login : undefined,
          login: !isEmail ? login : undefined,
          codigoEmpresa: state?.codigoEmpresa,
        },
        {
          throwError: true,
        },
      );

      setTotalTimeInSeconds(60 * 5);
    } catch {
      setReenviarAberto(true);
    }
  }, [login, state]);

  const handleReenviar = useCallback(() => {
    setReenviarAberto(false);
    contagemReenviar();
  }, [contagemReenviar]);

  function handleEmailError() {
    if (login?.length > 0) {
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
    }
  }

  const handlePastedFromClipboard = (pastedData: string) => {
    const codes = pastedData.split('');

    setPrimeiroNumero(codes[0] || '');
    setSegundoNumero(codes[1] || '');
    setTerceiroNumero(codes[2] || '');
    setQuartoNumero(codes[3] || '');
    setQuintoNumero(codes[4] || '');
    setSextoNumero(codes[5] || '');
    setSetimoNumero(codes[6] || '');
    setOitavoNumero(codes[7] || '');
  };

  const renderEmailRecoverPasswordForm = () => {
    return (
      <div className={`RecoverPassword ${theme}`}>
        <div className={'loginForm'}>
          <SimpleText fontSize={FONT_SIZE.LG} className="marginText">
            Redefinir senha
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} className="p-mb-4">
            Valide o código enviado no e-mail para continuar e redefinir a sua
            senha.
          </SimpleText>
          <SimpleText bold>Código</SimpleText>
          <div className="box-number">
            <TextInput
              ref={primeiroNumeroRef}
              value={primeiroNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setPrimeiroNumero(t.target.value);
                if (t.target.value) {
                  segundoNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />

            <TextInput
              ref={segundoNumeroRef}
              value={segundoNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setSegundoNumero(t.target.value);
                if (t.target.value) {
                  terceiroNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />
            <TextInput
              ref={terceiroNumeroRef}
              value={terceiroNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setTerceiroNumero(t.target.value);
                if (t.target.value) {
                  quartoNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />
            <TextInput
              ref={quartoNumeroRef}
              value={quartoNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setQuartoNumero(t.target.value);
                if (t.target.value) {
                  quintoNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />
            <TextInput
              ref={quintoNumeroRef}
              value={quintoNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setQuintoNumero(t.target.value);
                if (t.target.value) {
                  sextoNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />
            <TextInput
              ref={sextoNumeroRef}
              value={sextoNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setSextoNumero(t.target.value);
                if (t.target.value) {
                  setimoNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />
            <TextInput
              ref={setimoNumeroRef}
              value={setimoNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => {
                setSetimoNumero(t.target.value);
                if (t.target.value) {
                  oitavoNumeroRef.current.focus();
                }
              }}
              maxLength={1}
              min={0}
            />

            <TextInput
              ref={oitavoNumeroRef}
              value={oitavoNumero}
              onPaste={e =>
                handlePastedFromClipboard(e.clipboardData.getData('Text'))
              }
              onChange={t => setOitavoNumero(t.target.value)}
              maxLength={1}
              min={0}
            />
          </div>

          <SimpleText fontColor={FONT_COLOR.COLOR_40} className="p-mb-1" bold>
            Não recebeu?
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} className="p-mb-1">
            Verifique a caixa de spam e aguarde alguns minutos para reenviar o
            código. Email enviado para:
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            className="p-mb-2 p-mt-3"
            bold
          >
            {login}
          </SimpleText>
          {trocarEmail ? (
            <TextInput
              value={login}
              onChange={t => setLogin(t.target.value)}
              onBlur={handleEmailError}
              errorMsg={errorEmail && 'Digite um acesso válido'}
            />
          ) : (
            <Button
              btnType="green-link"
              className={'p-mt-1 p-p-0 p-col-3'}
              label={'Trocar acesso'}
              onClick={() => setTrocarEmail(true)}
              style={{ justifyContent: 'flex-start' }}
            />
          )}
          <Button
            btnType="outline"
            label="Reenviar código"
            className={'p-pl-0 p-pr-0 p-mt-1 p-mb-2'}
            onClick={() => handleReenviar()}
            disabled={!reenviarAberto || errorEmail}
            style={{ width: 150 }}
          />

          {totalTimeInSeconds > 0 && (
            <SimpleText fontColor={FONT_COLOR.DANGER} fontSize={FONT_SIZE.XXS}>
              Tempo para enviar novo código: {getTimeToResend()}
            </SimpleText>
          )}

          <Button
            label={'Continuar'}
            onClick={handleValidarCodigo}
            className="p-my-2"
            disabled={!isFormValid}
          />

          <Button
            onClick={() => {
              setSupportVisible(true);
            }}
            btnType="green-link"
            className="p-p-0 p-mt-2"
            style={{ justifyContent: 'flex-start' }}
          >
            Solicitar Suporte
          </Button>
        </div>
      </div>
    );
  };

  return (
    <LoginLayout
      content={renderEmailRecoverPasswordForm()}
      supportVisible={supportVisible}
      onHideSupport={() => {
        setSupportVisible(false);
      }}
      maxWidth={700}
    />
  );
};
