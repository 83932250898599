import { useEffect, useState } from 'react';

import { UseFormSetValue, FieldValues } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

type Options = {
  label: string;
  value: number;
};

export interface CountryData {
  countryOptions: Options[];
  handleCountrySelect: (idCountry: number) => void;
}

interface CountryProps {
  setValue: UseFormSetValue<FieldValues>;
}

export const useCountry = ({ setValue }: CountryProps): CountryData => {
  const [countryOptions, setCountryOptions] = useState<Options[]>([]);

  const fetchCountries = async () => {
    const countriesData = await UtilsAPI.GetPaises();

    if (Array.isArray(countriesData)) {
      const formattedCountries = countriesData.map(country => ({
        label: country.descricao,
        value: country.id,
      }));

      setCountryOptions(formattedCountries);
    }
  };

  const handleCountrySelect = (idCountry: number) => {
    if (idCountry !== 0) {
      setValue('endereco.estado', '');
      setValue('endereco.idCidade', '');
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return {
    countryOptions,
    handleCountrySelect,
  };
};
