import { useState, useCallback } from 'react';

import { filesize } from 'filesize';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

export interface UploadFileType {
  loading: boolean;
  loadedFile: any;
  handleLoadFile: (files: File[]) => void;
  handleDeleteFile: () => void;
  handleUploadFile: (
    idAtendimento: number,
    idSolicitacaoProcedimento: number,
    saveFile?: boolean,
  ) => void;
}

interface UploadFileProps {
  isProcedimentoExterno: boolean;
  onSuccess: (laudoArquivo: string) => void;
}

export const useUploadFile = (props: UploadFileProps): UploadFileType => {
  const { isProcedimentoExterno, onSuccess } = props;

  const [loadedFile, setLoadedFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const onUploadProgress = (e: any) => {
    const progress = Math.round((e.loaded * 100) / e.total);
    setLoadedFile((prevUploadedFile: any) => ({
      ...prevUploadedFile,
      progress,
    }));
  };

  const handleUploadFile = useCallback(
    async (
      idAtendimento: number,
      idSolicitacaoProcedimento: number,
      saveFile = true,
    ) => {
      try {
        const dataFile: FileUpload = {
          fileName: loadedFile.name,
          contentType: loadedFile.file.type,
          contentLength: loadedFile.file.size,
          resourceType: 'OUTROS',
          resourceId: 1,
        };

        setLoading(true);

        const responseGoogleStorage: any =
          await FileUploadAPI.sendImageComunicado(dataFile, onUploadProgress);

        if (responseGoogleStorage.status !== 200) return setLoading(false);

        const { filePath: laudoArquivo, uploadUrl } =
          responseGoogleStorage.data;

        const res = await FileUploadAPI.updateFileUpload(
          uploadUrl,
          loadedFile.file,
        );

        if (res.status !== 200) return setLoading(false);

        if (!saveFile) {
          setLoading(false);
          return laudoArquivo;
        }

        const payload = {
          laudoArquivo,
        };

        const putLaudo = isProcedimentoExterno
          ? SolicitacoesProcedimentosExternosAPI.putLaudo
          : SolicitacoesProcedimentosAPI.putProcedimentoLaudo;

        const response = await putLaudo(
          idAtendimento,
          idSolicitacaoProcedimento,
          payload,
        );

        onSuccess(response?.resultadoExame?.laudoArquivo || '');

        return laudoArquivo;
      } catch (error) {
        // error
      } finally {
        setLoading(false);
      }
    },
    [isProcedimentoExterno, onSuccess, loadedFile],
  );

  const getImageData = (imagePreview: string) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => resolve(image);
      image.onerror = reject;

      image.src = imagePreview;
    });
  };

  const handleLoadFile = async (files: File[]) => {
    try {
      const file = files[0] as File;

      const uploadedFileFormatted: any = {
        file,
        name: file.name,
        // readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        isImage: file.type.includes('image'),
      };

      if (uploadedFileFormatted.isImage) {
        const { width, height }: any = await getImageData(
          uploadedFileFormatted.preview,
        );

        uploadedFileFormatted.width = width;
        uploadedFileFormatted.height = height;
      }

      setLoadedFile(uploadedFileFormatted);
    } catch (error) {
      setLoadedFile(null);
    }
  };

  const handleDeleteFile = () => {
    setLoadedFile(null);
  };

  return {
    loadedFile,
    loading,
    handleUploadFile,
    handleLoadFile,
    handleDeleteFile,
  };
};
