import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/registro-obito';

export const getRegistroObitoByIdPaciente = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetRegistroObito> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/paciente/${id}`,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });
};

export const postRegistroObito = (
  payload: PostRegistroObito,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetRegistroObito> => {
  return Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    ...options,
  });
};

export const updateRegistroObito = (
  id: number,
  payload: PostRegistroObito,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetRegistroObito> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${id}`,
    data: payload,
    ...options,
  });
};

const RegistroObitoAPI = {
  getRegistroObitoByIdPaciente,
  postRegistroObito,
  updateRegistroObito,
};

export default RegistroObitoAPI;
