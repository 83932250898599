import { useState } from 'react';

import { toast } from 'react-toastify';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';
import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';

import { Button } from 'src/components/_UI';
import { InputNumber } from 'src/components/Basics/Input';
import Toast from 'src/components/Basics/Toast/Toast';

export const DialogCopyOrderQuetion = ({
  type,
  idQuestion,
  idForm,
  item,
  onSuccess,
  questionsLength,
}: {
  type: 'copy' | 'order';
  idQuestion: number;
  idForm: number;
  onSuccess(): void;
  item: IPerguntaFormularioItem;
  questionsLength: number;
}) => {
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOrder = async () => {
    if (!value) return;
    setIsLoading(true);

    const positionIsGreaterThanLength = value > questionsLength;

    try {
      await FormularioDinamicoAPI.ordenarFormularioDinamicoPergunta(
        {
          idFormulario: idForm,
          idFormularioDinamicoPergunta: idQuestion,
          ordem: value,
        },
        { throwError: true, hideToast: positionIsGreaterThanLength },
      );

      if (positionIsGreaterThanLength) {
        toast(<Toast />, {
          data: {
            title: 'Atenção',
            message:
              'A posição inserida não existe. A pergunta será ordenada para o final da lista.',
            type: 'warning',
          },
        });
      }

      onSuccess();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    if (!value) return;
    setIsLoading(true);

    const positionIsGreaterThanLength = value > questionsLength;

    try {
      const questionPayload = { ...item, id: undefined };

      await PerguntaFormularioDinamicoAPI.createPerguntaFormulario(
        {
          ...questionPayload,
          idFormularioDinamico: idForm,
          ordem: value,
        } as any,
        { throwError: true, hideToast: positionIsGreaterThanLength },
      );

      if (positionIsGreaterThanLength) {
        toast(<Toast />, {
          data: {
            title: 'Atenção',
            message:
              'A posição inserida não existe. A pergunta será copiada para o final da lista.',
            type: 'warning',
          },
        });
      }

      onSuccess();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-d-flex p-gap-2 p-ai-start" style={{ width: '160px' }}>
      <InputNumber
        onChange={e => setValue(e.target.value)}
        value={value}
        className="p-col-8 p-p-0"
        hideTextErrorSpace
        max={99}
      />
      <Button
        className="p-col-4 p-px-1"
        label={type === 'copy' ? 'copiar' : 'ordenar'}
        btnType="tonal"
        onClick={type === 'order' ? handleOrder : handleCopy}
        disabled={isLoading}
        style={{
          height: '33px',
        }}
      />
    </div>
  );
};
