import { useCallback } from 'react';

import { MonitoramentoTissAPI } from 'src/APIs/AdminAPI/MonitoramentoTissAPI/MonitoramentoTissAPI';

import useSize from 'src/core/hooks/useSize';

import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { MonitoramentoTissFilter } from './MonitoramentoTissFilter';
import {
  MonitoramentoRowMobile,
  MonitoramentoTissRow,
} from './MonitoramentoTissRow';
import { MonitoramentoTissHeader } from './MonitoramentoTissRowHeader';

import './MonitoramentoTiss.scss';

export const MonitoramentoTiss = () => {
  const { isTablet } = useSize();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchApi = useCallback(async ({ query, page, ...params }) => {
    if (!Object.keys(params)?.length) return { list: [] };

    return await MonitoramentoTissAPI.loadMonitoramentoTissPesquisaAvancada({
      page,
      sortDirection: 'DESC',
      ...params,
    });
  }, []);

  return (
    <Page id="Monitoramento-tiss" white content>
      <>
        <SearchList.Root empresaRequired fetchApi={fetchApi}>
          <MonitoramentoTissFilter />

          <div className="p-col-12 p-d-flex p-flex-column">
            <SearchList.BorderContainer>
              <SearchList.InfiniteScroll
                emptyResultLayout={EmptyCustomLayout}
                customEmptyText="Os resultados das buscas irão aparecer aqui."
                renderRows={(items: any[]) => (
                  <>
                    {!isTablet && <MonitoramentoTissHeader />}

                    <div className="p-d-flex p-flex-column p-gap-2">
                      {items.map((item, idx) =>
                        isTablet ? (
                          <MonitoramentoRowMobile item={item} key={idx} />
                        ) : (
                          <MonitoramentoTissRow item={item} key={idx} />
                        ),
                      )}
                    </div>
                  </>
                )}
              />
            </SearchList.BorderContainer>
          </div>
        </SearchList.Root>
      </>
    </Page>
  );
};

const EmptyCustomLayout = () => {
  return (
    <div className="card-empty">
      <SimpleText fontSize={FONT_SIZE.SM} medium>
        Não há resultados...
      </SimpleText>
      <SimpleText>Faça uma busca utilizando os filtros</SimpleText>
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        os resultados apareceram aqui.
      </SimpleText>
    </div>
  );
};
