import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './ModalAddConfiguracaoFuncionalidade.scss';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';
import { Button } from 'src/components/_UI/Button';
interface ModalAddConfiguracaoFuncionalidadeProps {
  visible: boolean;
  onHide(): void;
  funcionalidadeSelecionada: Funcionalidade;
}

const ModalAddConfiguracaoFuncionalidade = ({
  funcionalidadeSelecionada,
  visible,
  onHide,
}: ModalAddConfiguracaoFuncionalidadeProps) => {
  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Nova configuração da funcionalidade"
      visible={visible}
      onHide={onHide}
      maximizedMobileSize
      footer={() => <div />}
    >
      <div className="modal-body-add-config-funcionalidade">
        <SimpleText
          className="p-mb-2"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Título da função
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
          Função pertencente à{' '}
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {funcionalidadeSelecionada?.modulo}
          </SimpleText>
        </SimpleText>

        <div>
          <TextareaInput
            label="Descrição da funcionalidade"
            className="grid p-col-12 p-md-12"
            value={funcionalidadeSelecionada?.nome}
            autoResize
            disabled
          />

          <TextareaInput
            label="Descrição técnica da funcionalidade"
            className="grid p-col-12 p-md-12"
            value={funcionalidadeSelecionada?.descricao}
            autoResize
            disabled
          />

          <TextareaInput
            label="Informações complementares"
            className="grid p-col-12 p-md-12"
            value={funcionalidadeSelecionada?.informacoesComplementares}
            autoResize
            disabled
          />

          <TextareaInput
            label="Parametrização"
            className="grid p-col-12 p-md-12"
            value={funcionalidadeSelecionada?.descricaoParametrizacao}
            autoResize
            disabled
          />

          <div className={'p-grid'}>
            <div className={'p-col-12'}>
              <Button
                label={'Fechar'}
                btnType="outline"
                onClick={onHide}
                stretch
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalAddConfiguracaoFuncionalidade;
