import { FormProvider, useForm } from 'react-hook-form';

import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';

const FilterSearch = () => {
  const useFormMethods = useForm();
  const { getValues, reset, control } = useFormMethods;

  const onFilter = () => {
    const idsSexoArr = [];

    const { idadeInicial, idadeFinal, sexoMasculino, sexoFeminino } =
      getValues();

    if (sexoMasculino) idsSexoArr.push(3);
    if (sexoFeminino) idsSexoArr.push(1);

    const idsSexo = idsSexoArr.length ? idsSexoArr.join(', ') : undefined;

    const filters = {
      idadeInicial,
      idadeFinal,
      idsSexo,
    };

    return filters;
  };

  const clearFilters = () => {
    reset({
      sexoMasculino: false,
      sexoFeminino: false,
      idadeInicial: '',
      idadeFinal: '',
    });
  };

  return (
    <SearchListFilter onFilter={onFilter} onClearFilters={clearFilters}>
      <FormProvider {...useFormMethods}>
        <form className="p-mt-2">
          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Sexo
          </SimpleText>
          <div className="p-col-12">
            <CheckboxControlled
              control={control}
              name="sexoMasculino"
              label="Masculino"
            />
          </div>

          <div className="p-col-12">
            <CheckboxControlled
              control={control}
              name="sexoFeminino"
              label="Feminino"
            />
          </div>

          <SimpleText
            className="p-col-12 p-mt-3"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Idade
          </SimpleText>
          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="idadeInicial"
              label="Idade inicial"
              placeholder="Idade inicial"
              permitirApenasNumeros
            />
          </div>
          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="idadeFinal"
              label="Idade final"
              placeholder="Idade final"
              permitirApenasNumeros
            />
          </div>
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};

export default FilterSearch;
