import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/guia-resumida';

const printGuiaResumidaPdf = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.post({
    service: 'report',
    url: `${basePath}/${idAtendimento}/imprimir`,
    hideToast: true,
    throwError: true,
    responseType: 'arraybuffer',
    ...options,
 });

const getBase64GuiaResumida = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'report',
    url: `${basePath}/${idAtendimento}/dados`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const pdfAssinaturaGuiaResumida = (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `${basePath}/${idAtendimento}/pdf`,
    data: payload,
    throwError: true,
    return: true,
    responseType: 'arraybuffer',
    ...options,
  });

export const GuiaResumidaAPI = {
  printGuiaResumidaPdf,
  getBase64GuiaResumida,
  pdfAssinaturaGuiaResumida,
};
