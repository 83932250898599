import { useState } from 'react';

import { PanelHeaderTemplateOptions } from 'primereact/panel';

import { AuditoriaAPI } from 'src/APIs/AdminAPI/AuditoriaAPI/AuditoriaAPI';

import Panel from 'src/components/Panel/Panel';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

interface Props {
  item: any;
}

const MobileRow = ({ item }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [auditoriaColumns, setAuditoriaColumns] = useState<any[]>([]);

  const handleGetAuditoriaColumns = async (isCollapsed: boolean) => {
    if (!isCollapsed || !!auditoriaColumns.length) return;
    try {
      setIsLoading(true);
      const response = await AuditoriaAPI.getAuditoriaById(item.id, {
        throwError: true,
      });
      if (Array.isArray(response)) setAuditoriaColumns(response);
    } catch (error) {
      setAuditoriaColumns([]);
    } finally {
      setIsLoading(false);
    }
  };

  const HeaderPanel = (options: PanelHeaderTemplateOptions) => (
    <PanelHeader
      item={item}
      isCollapsed={options.collapsed}
      onToggle={e => [
        options.onTogglerClick(e),
        handleGetAuditoriaColumns(options.collapsed),
      ]}
    />
  );

  return (
    <div className="p-my-1">
      <Panel
        className="auditoria-panel"
        headerTemplate={HeaderPanel}
        toggleable
        collapsed
      >
        <PanelContent
          auditoriaColumns={auditoriaColumns}
          isLoading={isLoading}
        />
      </Panel>
    </div>
  );
};

export default MobileRow;
