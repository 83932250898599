import { memo, useEffect, useMemo, useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import TipoCuidadoAPI from 'src/APIs/ConfigAPI/TipoCuidado/TipoCuidadoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import FieldConsultorios from '../../components/FieldConsultorios';
import { Button } from 'src/components/_UI/Button';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import Skeleton from 'src/components/Skeleton/Skeleton';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import { validationSchema } from '../../Utils';

import Respostas from './Respostas/Respostas';

interface ModalAddTipoMotivoProps {
  visible: boolean;
  onHide(): void;
  tipoCuidado: any;
  reload: any;
  consultoriosDisponiveis: any[];
  consultoriosUsuario: any[];
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
  loading: boolean;
}

const Footer = memo(({ onCancel, onAdd, loading }: FooterProps) => {
  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType={'ghost'} label="Cancelar" onClick={onCancel} stretch />
      <Button label={'Salvar'} onClick={onAdd} stretch loading={loading} />
    </div>
  );
});

const ModalAddTipoCuidado = ({
  tipoCuidado,
  consultoriosDisponiveis,
  consultoriosUsuario,
  visible,
  onHide,
  reload,
}: ModalAddTipoMotivoProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const [fetchedTipoCuidado, setFetchedTipoCuidado] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const hasTipoCuidado = !!tipoCuidado?.id;

  const defaultValues = useMemo(() => {
    const consultoriosValue = fetchedTipoCuidado?.todosConsultorios
      ? [{ id: 'Todos', name: 'Todos' }]
      : fetchedTipoCuidado?.consultorios;

    return {
      consultorios: consultoriosValue ?? [],
      descricao: fetchedTipoCuidado?.descricao ?? '',
      status: fetchedTipoCuidado?.status ?? false,
      obrigaPreenchimentoDescricao:
        fetchedTipoCuidado?.obrigaPreenchimentoDescricao ?? false,
      respostas: fetchedTipoCuidado?.respostas ?? [],
    };
  }, [fetchedTipoCuidado]);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useFormMethods;

  useEffect(() => {
    const getTipoCuidado = async () => {
      try {
        setLoading(true);

        const response = await TipoCuidadoAPI.getTipoCuidadoById(
          tipoCuidado.id,
          { throwError: true },
        );

        setFetchedTipoCuidado(response);
        setLoading(false);
      } catch (error) {
        onHide();
      } finally {
        setLoading(false);
      }
    };

    if (!!tipoCuidado.id) {
      getTipoCuidado();
    }
  }, [onHide, tipoCuidado.id]);

  useEffectSkipFirst(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const options = useMemo(() => {
    const consultoriosSelecionados =
      fetchedTipoCuidado?.consultorios?.map((c: any) => {
        return {
          nome: c.nome,
          label: c.nome,
          value: {
            id: c.id,
            name: c.nome,
          },
        };
      }) || [];

    /**
     * Utilizar consultorios selecionados para quando for edição e o consultorio não
     * pertencer mais ao usuario
     */
    const options = [
      {
        nome: 'Todos',
        label: 'Todos',
        value: { id: 'Todos', name: 'Todos' },
      },
    ].concat(consultoriosUsuario, consultoriosSelecionados);

    // Remove os consultorios duplicados das opções
    const uniqueOptions = Array.from(
      new Set(options.map((a: any) => a?.value.id)),
    ).map(id => {
      return options.find((a: any) => a?.value.id === id);
    });

    return uniqueOptions;
  }, [consultoriosUsuario, fetchedTipoCuidado?.consultorios]);

  const onSubmit = async (data: any) => {
    delete data.descricaoResposta;
    const {
      descricao,
      consultorios,
      status,
      obrigaPreenchimentoDescricao,
      respostas,
    } = data;

    const respostasTipoCuidado = respostas.map((r: any) => {
      delete r.new;
      return r;
    });

    let todos = false;
    let consults: any[] = [];
    if (consultorios[0]?.id === 'Todos') {
      todos = true;

      consults = consultoriosDisponiveis.map((c: any) => {
        return {
          id: c.id,
          name: c.nome,
        };
      });
    }

    const payload: TipoCuidado = {
      consultorios: todos ? consults : consultorios,
      descricao,
      status,
      obrigaPreenchimentoDescricao,
      todosConsultorios: todos,
      idUsuarioAlteracao: user?.id,
      registroEditavel: true,
      respostas: respostasTipoCuidado,
    };

    const saveAction = () =>
      hasTipoCuidado
        ? TipoCuidadoAPI.updateTipoCuidado(payload as any, tipoCuidado?.id, {
            return: true,
          })
        : TipoCuidadoAPI.sendTipoCuidado(payload as any, {
            return: true,
          });

    const response = await saveAction();

    if (response.status < 200 || response.status > 299) {
      return;
    }
    reload();
    onHide();
  };

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header={
        hasTipoCuidado ? 'Editar tipo de cuidado' : 'Adicionar tipo de cuidado'
      }
      visible={visible}
      onHide={onHide}
      footer={() => (
        <Footer
          onAdd={handleSubmit(onSubmit)}
          onCancel={onHide}
          loading={isSubmitting}
        />
      )}
      maximizedMobileSize
      style={{ minWidth: '450px' }}
    >
      <FormProvider {...useFormMethods}>
        <form className="modal-body" onSubmit={handleSubmit(onSubmit)}>
          <FieldConsultorios
            className="p-mt-3"
            label="Consultório"
            options={options}
            loading={loading}
          />

          <FormInput
            className="p-mt-3"
            label="Descrição"
            name="descricao"
            maxLength={30}
            loading={loading}
          />

          <CheckboxControlled
            className="p-mb-1"
            control={control}
            name="obrigaPreenchimentoDescricao"
            label="Obrigar o preenchimento da descrição"
          />

          <SimpleText className="p-my-2" medium>
            Status do cadastro
          </SimpleText>

          {loading ? (
            <div>
              <Skeleton
                className="p-mt-2"
                height="33px"
                borderRadius="8px"
                loading={true}
              />
            </div>
          ) : (
            <div className="switch p-d-flex p-jc-start p-mb-1">
              <SwitchControlled control={control} name="status" showStatus />
            </div>
          )}
          <Skeleton loading={loading} height="100px">
            <Respostas tipoCuidado={tipoCuidado} />
          </Skeleton>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalAddTipoCuidado;
