/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import { LoadPrescricao } from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Panel from '../../components/Panel/Panel';
import { DialogInativarPrescricao } from './PanelContent/components/DialogInativarPrescricao/DialogInativarPrescricao';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import { PanelContent } from './PanelContent/PanelContent';
import { PanelHeader } from './PanelHeader/PanelHeader';

import './PrescricaoMedicamentos.scss';

export const PrescricaoMedicamentos = ({ view }: { view?: boolean }) => {
  const { state } = useLocation();
  const { atendimentoStatus } = useAtendimento();
  const { paciente } = useAppSelector(state => state.atendimento);
  const { idUsuario } = useAppSelector(state => state.user);

  const navigate = useNavigate();

  const idAtendimento = state?.idAtendimento;

  const [update, setUpdate] = useState(0);
  const [queryParam, setQueryParam] = useState('dataInclusao');
  const [idEnfermeiroPrescricao, setIdEnfermeiroPrescricao] = useState('');

  const isPrescricaoEnfermeiro = atendimentoStatus?.includes(
    'PRESCRICAO_EXECUCAO',
  );

  //? @CDU 144 - RN28
  useEffect(() => {
    if (
      !!idEnfermeiroPrescricao &&
      isPrescricaoEnfermeiro &&
      idEnfermeiroPrescricao !== idUsuario
    ) {
      toast(<Toast />, {
        data: {
          title: 'Acesso não permitido',
          message: `Acesso não permitido pois a prescrição já está em andamento!`,
          type: 'error',
        },
      });

      navigate('/agenda');
    }
  }, [idEnfermeiroPrescricao, idUsuario, isPrescricaoEnfermeiro, navigate]);

  const fetchAPI = useCallback(
    async (params): Promise<any> => {
      if (isPrescricaoEnfermeiro) {
        const data: any = await PrescricaoAPI.loadPrescricaoExecucao(
          idAtendimento,
          { page: params.page, sortBy: queryParam, sortDirection: 'ASC' },
          { errorHideToast: true },
        );

        !!data?.list &&
          setIdEnfermeiroPrescricao(data?.list[0].idEnfermeiroExecucao);
        return data.list;
      }

      const data: any = await PrescricaoAPI.loadPrescricaoHistoricoPaciente(
        paciente.id,
        { page: params.page, sortBy: queryParam, sortDirection: 'ASC' },
        { errorHideToast: true },
      );

      return data;
    },
    [paciente?.id, queryParam, idAtendimento, isPrescricaoEnfermeiro],
  );

  const handleUpdate = () => {
    setUpdate(old => old + 1);
  };

  const handleInativar = (prescricao: any, justificativaInativacao: string) => {
    PrescricaoAPI.inativarPrescricao(
      prescricao.id,
      prescricao?.atendimento.id,
      {
        justificativaInativacao,
      },
      { throwError: true },
    )
      .then(() => handleUpdate())
      .catch(() => {});
  };

  return (
    <div id="prescricao-medicamentos-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        paginatedList={false}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Prescrição de Medicamento
            </SimpleText>

            <OptionsQueryParamsHeader
              queryParam={queryParam}
              setQueryParam={setQueryParam}
            />
          </div>

          {isPrescricaoEnfermeiro ? (
            <SearchList.InfiniteScroll
              className="p-mt-5"
              reload={update}
              customEmptyText="Nenhum item encontrado para esta busca."
              autoHeight={false}
              renderRow={(item: LoadPrescricao) => (
                <Row
                  key={item.id}
                  item={item}
                  view={view}
                  handleInativar={handleInativar}
                  handleUpdate={handleUpdate}
                />
              )}
            />
          ) : (
            <SearchList.InfiniteScroll
              className="p-mt-5"
              reload={update}
              autoHeight={false}
              renderRows={(items: LoadPrescricao[]) => {
                const itemsFiltered = items.filter(
                  item => item.situacaoPrescricao === 'EXECUTADA',
                );

                return !itemsFiltered.length ? (
                  <div
                    className="p-d-flex p-jc-center p-ai-center"
                    style={{
                      height: '100%',
                    }}
                  >
                    <SimpleText fontSize="xs" fontColor="color_40">
                      Nenhum item encontrado para esta busca.
                    </SimpleText>
                  </div>
                ) : (
                  itemsFiltered.map(item => (
                    <Row
                      key={item.id}
                      item={item}
                      view={view}
                      handleInativar={handleInativar}
                      handleUpdate={handleUpdate}
                    />
                  ))
                );
              }}
            />
          )}
        </SearchList.BorderContainer>
      </SearchList.Root>
    </div>
  );
};

const Row = ({ item, view, handleInativar, handleUpdate }: any) => {
  const [itemPrescricao, setItemPrescricao] = useState<LoadPrescricao>(item);
  const [isExecutada, setIsExecutada] = useState(!!item.dataOcorrencia);

  useEffect(() => {
    if (view) setIsExecutada(true);
  }, [view]);

  return (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            item={item}
            optionsMenu={
              <OptionMenu
                handleInativar={(value: string) => handleInativar(item, value)}
              />
            }
          />
        )}
      >
        <PanelContent
          isExecutada={isExecutada}
          setIsExecutada={setIsExecutada}
          item={itemPrescricao}
          setItem={setItemPrescricao}
          view={!!view}
          refetch={handleUpdate}
        />
      </Panel>
    </div>
  );
};

const OptionMenu = ({ handleInativar }: any) => {
  const menuEllipsis = useRef<TieredMenu>(null);

  const inativarDialog = useDisclosure({ opened: false });

  return (
    <>
      <Button
        icon="fas fa-ellipsis-h"
        btnType="gray"
        onClick={event => menuEllipsis?.current?.toggle(event)}
      />

      <TieredMenu
        className="panel-options-ficha-paciente"
        ref={menuEllipsis}
        model={[
          {
            label: 'Inativar',
            className: 'warning-color',
            command: () => inativarDialog.open(),
          },
        ]}
        popup
      />

      {inativarDialog.isOpen && (
        <DialogInativarPrescricao
          handleInativar={handleInativar}
          {...inativarDialog}
        />
      )}
    </>
  );
};

const OptionsQueryParamsHeader = ({ queryParam, setQueryParam }: any) => {
  const { setFilter } = useSearchList();

  return (
    <div className="p-d-flex p-gap-1 p-ai-center">
      <SimpleText>Ordenar por: </SimpleText>
      <SplitButton
        btnType={SPLIT_BTN_TYPE.GHOST}
        label={
          queryParam === 'dataInclusao'
            ? 'Data da solicitação'
            : 'Data da ocorrência'
        }
        model={[
          {
            label: 'Data da solicitação',
            command: () => {
              setFilter({
                sortBy: 'dataInclusao',
              });
              setQueryParam('dataInclusao');
            },
          },
          {
            label: 'Data da ocorrência',
            command: () => {
              setFilter({
                sortBy: 'dataOcorrencia',
              });
              setQueryParam('dataOcorrencia');
            },
          },
        ]}
      />
    </div>
  );
};
