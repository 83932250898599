const regex = /[^0-9]/g;
//let quantidadeZeros = 0;
// const mascaraCartao = '9999.9999.999999.99-9';
//let habilitaMultiplosConvenios = false;
let lMask = 0;
//var lMinMask = 0;
//var lMaxMask = 0;
//var auxNumCartao = '';
//var linha = 0;
//var leitora = false;

// %MIGUEL FREITAS:
// ç00586064000001108=012508=005805643102:

//%LORENZO DE MEIRA ULCHAK  ?
//;00320000076889327=012007=003206143172?
//;101258860000000716270520170000000000000000000000000000000000000000000000000000000000507201800000011107?

export function getNumCartao(text: string, mascaraCartao: string) {
  lMask = mascaraCartao.replace(regex, '').length;
  const n = text.indexOf('=');
  const mascaraLimpa = mascaraCartao.replace(/[.-]/g, '');
  const tamanhoMascaraLimpa = mascaraLimpa.length;
  if (n > 0) {
    text = text.substring(0, n);
    let textTemp = '';

    text = text.replace(regex, '');
    if (text.length < tamanhoMascaraLimpa) {
      for (let i = 0; i < tamanhoMascaraLimpa - text.length; i++) {
        textTemp += '0';
      }
      text = textTemp + text;
    } else if (text.length > tamanhoMascaraLimpa) {
      text = text.substring(text.length - tamanhoMascaraLimpa);
    }
  }
  text = text.replace(regex, '');
  if (text.length > lMask && n <= 0) {
    text = text.substring(17 - tamanhoMascaraLimpa, text.length);
  }
  return text;
}

export function isCartaoLido(text: any) {
  const count = [...text].filter(x => x === '=').length;
  return count >= 2 ? true : false;
}
