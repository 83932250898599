import { useMemo, useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { useLocation, useParams } from 'react-router';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';

import './PanelOptionsMenu.scss';

interface PanelOptionsMenuProps {
  ativo: boolean;
  lembreteAtivo?: boolean;
  idUsuarioCriacao: number;
  idAtendimentoRegistro: number;
  showEditOnlyAtendimentoCorrente?: boolean;
  showValidarRejeitar?: boolean;
  hideLembreteOption?: boolean;
  hideEditOption?: boolean;
  hideExcluirOption?: boolean;
  hideInativarOption?: boolean;
  onVisualizarJustificativa: () => void;
  onAlterarLembrete: () => void;
  onEdit: () => void;
  onInativar: () => void;
  onExcluir: () => void;
  onValidar?: () => void;
  onRejeitar?: () => void;
}

const PanelOptionsMenu = ({
  ativo,
  lembreteAtivo,
  idUsuarioCriacao,
  idAtendimentoRegistro,
  showEditOnlyAtendimentoCorrente,
  showValidarRejeitar,
  hideLembreteOption,
  hideExcluirOption,
  hideEditOption,
  hideInativarOption,
  onVisualizarJustificativa,
  onAlterarLembrete,
  onEdit,
  onInativar,
  onExcluir,
  onValidar,
  onRejeitar,
}: PanelOptionsMenuProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { user } = useAppSelector((state: RootState) => state);

  const { atendimentoStatus } = useAtendimento();

  const menuEllipsis = useRef<TieredMenu>(null);

  /**
   * Apenas o usuário que incluiu o registro poderá inativa-lo
   * Se o registro for do atendimento atual e ainda estiver em andamento o usuário só poderá excluir
   */
  const showInativar = useMemo(
    () =>
      idUsuarioCriacao === user.idUsuario &&
      !(
        atendimentoStatus === 'ATENDENDO' &&
        idAtendimentoRegistro === Number(idAtendimento)
      ) &&
      !hideInativarOption,
    [
      atendimentoStatus,
      hideInativarOption,
      idAtendimento,
      idAtendimentoRegistro,
      idUsuarioCriacao,
      user.idUsuario,
    ],
  );

  const showExcluir = useMemo(
    () =>
      idAtendimentoRegistro === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO',
    [atendimentoStatus, idAtendimento, idAtendimentoRegistro],
  );

  const showEditAtendimentoCorrente = useMemo(() => {
    if (!showEditOnlyAtendimentoCorrente) return true;

    return (
      Number(idAtendimento) === idAtendimentoRegistro &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [
    atendimentoStatus,
    idAtendimento,
    idAtendimentoRegistro,
    showEditOnlyAtendimentoCorrente,
  ]);

  const menuEllipsisItens = useMemo(() => {
    const menu: MenuItem[] = [];

    if (!hideLembreteOption) {
      menu.push({
        label: lembreteAtivo ? 'Silenciar' : 'Ativar lembrete',
        icon: lembreteAtivo ? 'fas fa-bell-slash' : 'fas fa-bell',
        command: onAlterarLembrete,
      });
    }

    if (showEditAtendimentoCorrente && !hideEditOption) {
      if (!!menu.length) menu.push({ separator: true });
      menu.push({
        label: 'Editar',
        command: onEdit,
      });
    }

    if (onValidar && showValidarRejeitar) {
      menu.push({ separator: true });
      menu.push({
        label: 'Validar',
        icon: 'fas fa-thumbs-up',
        className: 'validar-questionario',
        command: () => onValidar(),
      });
    }

    if (onRejeitar && showValidarRejeitar) {
      menu.push({
        label: 'Rejeitar',
        icon: 'fas fa-thumbs-down',
        className: 'rejeitar-questionario',
        command: () => onRejeitar(),
      });
    }

    if (showInativar) {
      if (!!menu.length) menu.push({ separator: true });
      menu.push({
        label: 'Inativar',
        className: 'warning-color',
        command: onInativar,
      });
    }

    if (showExcluir && !hideExcluirOption) {
      menu.push({ separator: true });
      menu.push({
        label: 'Excluir',
        className: 'warning-color',
        command: onExcluir,
      });
    }

    return menu;
  }, [
    hideLembreteOption,
    showEditAtendimentoCorrente,
    hideEditOption,
    showInativar,
    showExcluir,
    hideExcluirOption,
    onValidar,
    showValidarRejeitar,
    onRejeitar,
    lembreteAtivo,
    onAlterarLembrete,
    onEdit,
    onInativar,
    onExcluir,
  ]);

  if (!ativo)
    return (
      <Button
        icon="fas fa-eye"
        btnType="gray"
        onClick={onVisualizarJustificativa}
      />
    );

  return (
    <>
      <Button
        icon="fas fa-ellipsis-h"
        btnType="gray"
        onClick={event => menuEllipsis?.current?.toggle(event)}
        disabled={
          (idUsuarioCriacao !== user.idUsuario && !showValidarRejeitar) ||
          !menuEllipsisItens.length ||
          (atendimentoStatus !== 'ATENDENDO' &&
            atendimentoStatus !== 'ATENDENDO_TRIAGEM')
        }
      />

      <TieredMenu
        className="panel-options-ficha-paciente"
        ref={menuEllipsis}
        model={menuEllipsisItens}
        popup
      />
    </>
  );
};

export default PanelOptionsMenu;
