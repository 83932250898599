import { useState } from 'react';

import xmlFormat from 'xml-formatter';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './DialogXml.scss';

type IProps = DisclosureType & {
  xmlRequest: string;
  xmlResponse: string;
};

export const DialogXml = ({
  close,
  state,
  xmlRequest = '',
  xmlResponse = '',
}: IProps) => {
  const [comparison, setComparison] = useState(false);
  const type: any = state;

  const handleOpenXmls = () => {
    setComparison(old => !old);
  };

  const label = type === 'ENVIO' ? 'Requisição' : 'Resposta';

  const renderXml = (xml: string) => {
    try {
      const xmlFormatted = xmlFormat(xml, {
        collapseContent: true,
      });
      return xmlFormatted;
    } catch (error) {
      return xml;
    }
  };

  return (
    <Dialog
      id="Dialog-xml"
      header={
        <DialogHeader
          handleOpen={() => handleOpenXmls()}
          type={type}
          comparison={comparison}
          hasXml={type === 'ENVIO' ? !!xmlResponse : !!xmlRequest}
        />
      }
      draggable={false}
      visible
      onHide={close}
      className={comparison ? 'p-w-624' : ''}
      maximizedTabletSize
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText medium>
          {comparison ? 'Requisição/Resposta' : label}
        </SimpleText>

        <div className="p-d-flex p-gap-2">
          {!comparison ? (
            <div className="content-xml p-col-12">
              <textarea disabled>
                {renderXml(type === 'ENVIO' ? xmlRequest : xmlResponse)}
              </textarea>
            </div>
          ) : (
            <div className="p-w-100 p-grid">
              <div className="p-col-12 p-lg-6 p-p-1 ">
                <textarea className="content-xml" disabled>
                  {renderXml(xmlRequest)}
                </textarea>
              </div>
              <div className="p-col-12 p-lg-6 p-p-1">
                <textarea className="content-xml" disabled>
                  {renderXml(xmlResponse)}
                </textarea>
              </div>
            </div>
          )}
        </div>

        <Button
          label="Fechar"
          btnType="outline"
          stretch
          onClick={() => close()}
        />
      </div>
    </Dialog>
  );
};

const DialogHeader = ({ handleOpen, type, comparison, hasXml }: any) => {
  const labelType = type === 'ENVIO' ? 'resposta' : 'envio';

  return (
    <div className="p-d-flex p-jc-between p-ai-center">
      <SimpleText fontSize="sm" medium>
        XML
      </SimpleText>
      {!comparison && (
        <Button
          label={`XML de ${labelType}`}
          btnType="tonal"
          onClick={handleOpen}
          disabled={!hasXml}
        />
      )}
    </div>
  );
};
