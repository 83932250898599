import { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

export const MeusAgendamentosProximosRow = ({
  item,
  refetch,
}: any): React.ReactElement => {
  const [loading, setLoading] = useState(false);

  const { isTablet, windowInnerWidth } = useSize();
  const { idPaciente } = useAppSelector(state => state.user);

  const isTabletCustom = useMemo(
    () => windowInnerWidth <= 830,
    [windowInnerWidth],
  );

  const dialogCancelar = useDisclosure({ opened: false });

  const handleCancelar = () => {
    setLoading(true);

    AgendamentosAPI.cancelarPacienteAgendamento({
      idAtendimento: item.id,
      idPaciente,
    })
      .then(() => refetch())
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const endereco = item.consultorio?.endereco;
  const contato = item.consultorio?.contatoDto?.[0]?.numero || '-';
  const enderecoFormatado = !!endereco
    ? `${endereco?.logradouro} - ${endereco?.bairro}, ${endereco?.nomeCidade} - ${endereco?.siglaEstado}, ${endereco?.cep}`
    : '';

  return (
    <div className="proximos-row p-col-12 p-mb-1">
      <div className="p-d-flex p-col-12 p-p-0">
        <div className="p-col-2 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Data e hora
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {dayjs(item.dataAgendamento).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Profissional
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {item.medico?.nome}
          </SimpleText>
        </div>
        <div className="p-col-3 p-lg-2 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Contato
          </SimpleText>
          <SimpleText className="p-text-truncate">{contato}</SimpleText>
        </div>
        <div className="p-col p-d-flex p-jc-end p-gap-1">
          {!!item.linkSalaEsperaTelemedicina && (
            <Button
              label={!isTablet ? 'Teleconsulta' : ''}
              tooltip={isTablet ? 'Teleconsulta' : ''}
              tooltipOptions={{
                position: 'top',
              }}
              btnType="tonal"
              icon="fas fa-link"
              onClick={() =>
                window.open(item.linkSalaEsperaTelemedicina, '_blank')
              }
              disabled={loading}
            />
          )}
          <Button
            label={!isTabletCustom ? 'Cancelar' : ''}
            tooltip={isTabletCustom ? 'Cancelar' : ''}
            tooltipOptions={{
              position: 'top',
            }}
            btnType="danger"
            icon="fas fa-trash"
            loading={loading}
            onClick={() => dialogCancelar.open({ state: { handleCancelar } })}
          />
        </div>
      </div>

      <Divider layout="horizontal" className="p-col-12 p-p-0 p-m-0" />

      <div className="p-col-12 p-d-flex p-gap-1 p-ai-center">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Endereço:
        </SimpleText>
        <SimpleText>{enderecoFormatado}</SimpleText>
      </div>

      {dialogCancelar.isOpen && <DialogCancelar {...dialogCancelar} />}
    </div>
  );
};

export const MeusAgendamentosProximosRowMobile = ({ item, refetch }: any) => {
  const [loading, setLoading] = useState(false);

  const dialogCancelar = useDisclosure({ opened: false });
  const { idPaciente } = useAppSelector(state => state.user);

  const handleCancelar = () => {
    setLoading(true);

    AgendamentosAPI.cancelarPacienteAgendamento({
      idAtendimento: item.id,
      idPaciente,
    })
      .then(() => refetch())
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const endereco = item.consultorio?.endereco;
  const contato = item.consultorio?.contato?.numero || '-';
  const enderecoFormatado = `${endereco.logradouro} - ${endereco.bairro}, ${endereco.nomeCidade} - ${endereco.siglaEstado}, ${endereco.cep}`;

  return (
    <div className="proximos-row p-grid p-col-12 p-mb-1">
      <div className="p-col-6 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data e hora
        </SimpleText>
        <SimpleText>
          {dayjs(item.dataAgendamento).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-6">
        {!!item.linkSalaEsperaTelemedicina && (
          <Button
            className="p-ml-auto"
            label="Teleconsulta"
            icon="fas fa-link"
            btnType="tonal"
            onClick={() =>
              window.open(item.linkSalaEsperaTelemedicina, '_blank')
            }
            disabled={loading}
          />
        )}
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Profissional
        </SimpleText>
        <SimpleText>{item.medico?.nome}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Contato
        </SimpleText>
        <SimpleText>{contato}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Endereço
        </SimpleText>
        <SimpleText className="p-line-height-2">{enderecoFormatado}</SimpleText>
      </div>

      <Button
        stretch
        className="p-mt-2"
        label="Cancelar"
        btnType="danger"
        icon="fas fa-trash"
        loading={loading}
        onClick={() => dialogCancelar.open({ state: { handleCancelar } })}
      />

      {dialogCancelar.isOpen && <DialogCancelar {...dialogCancelar} />}
    </div>
  );
};

const DialogCancelar = ({ close, state }: DisclosureType) => {
  return (
    <Dialog
      visible
      onHide={close}
      header="Deseja realmente cancelar o agendamento?"
      closable={false}
      id="DialogCancelar"
    >
      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40">
          Se o agendamento for cancelado, o procedimento não poderá ser
          executado e o horário será disponibilizado a outro paciente. Se
          desejar cancelar mesmo assim clique em sim.
        </SimpleText>

        <div className="p-d-flex p-gap-2">
          <Button
            stretch
            label="Fechar"
            btnType="ghost"
            onClick={() => close()}
          />
          <Button
            stretch
            label="Sim, cancelar"
            btnType="danger"
            onClick={() => [state?.handleCancelar(), close()]}
          />
        </div>
      </div>
    </Dialog>
  );
};
