import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

interface RespostaItemProps {
  resposta: {
    descricao: string;
    status: boolean;
    id?: number;
    isNew?: boolean;
  };
  index: number;
  updateRespostaFields: (index: number, resposta: any) => void;
  updateResposta: (resposta: any, index: any) => void;
  handleRemove: (index: number) => void;
  tipoCuidado: any;
}

const RespostaItem = ({
  resposta,
  index,
  tipoCuidado,
  updateResposta,
  updateRespostaFields,
  handleRemove,
}: RespostaItemProps) => {
  const { isMobile } = useSize();
  const { control } = useFormContext();

  const canRemove = resposta.isNew;

  return (
    <>
      <div className="p-grid row-component p-my-1 p-align-center">
        {isMobile ? (
          <>
            <SimpleText
              className="p-col-12 p-mb-0 p-pb-0"
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Descrição:
            </SimpleText>
            <SimpleText
              className="p-col-12"
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {resposta.descricao}
            </SimpleText>
          </>
        ) : (
          <SimpleText
            className="p-col-12 p-sm-6"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {resposta.descricao}
          </SimpleText>
        )}

        <div className="p-col-3">
          <SwitchControlled
            name={`respostas.${index}.status`}
            control={control}
            checked={resposta.status}
            onChange={e =>
              updateRespostaFields(index, {
                ...resposta,
                status: e.target.value,
              })
            }
            showStatus
          />
        </div>
        <div className="p-col-3  p-d-flex p-jc-end p-gap-2">
          {canRemove && (
            <Button
              btnType="gray"
              icon="pi pi-trash"
              onClick={() => handleRemove(index)}
              type="button"
            />
          )}

          <Button
            btnType="gray"
            icon="pi pi-pencil"
            onClick={() => updateResposta(resposta, index)}
            type="button"
          />
        </div>
      </div>
    </>
  );
};

export default RespostaItem;
