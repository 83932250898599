import { useCallback, useEffect, useState } from 'react';

import { Calendar } from 'primereact/calendar';

import './NovoAgendamentoStyle.scss';
import dayjs from 'dayjs';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { validateFlags } from './utils';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

type HorariosProps = {
  atendimentos: any[];
  data: string;
  quantidadeAtendimentos: number;
};

export const NovoAgendamentoAgendamentoForm = ({
  handleSetData,
  values,
  handleGoBack,
}: {
  handleSetData(v: any): void;
  handleGoBack(): void;
  values: any;
}): React.ReactElement => {
  const initialDate = values?.dataAgendamento
    ? new Date(values?.dataAgendamento)
    : new Date();

  const [calendarValue, setCalendarValue] = useState<any>(initialDate);
  const [value, setValue] = useState<any>({
    dataAgendamento: values?.dataAgendamento,
    idAgendamento: values?.idAgendamento,
  });
  const [numDates, setNumDates] = useState<any[]>([]);
  const [viewDate, setViewDate] = useState<Date>(calendarValue);
  const [horarios, setHorarios] = useState<HorariosProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNumHorarios = useCallback(
    (date: Date) => {
      setNumDates([]);
      return PortalPacienteAPI.getNumeroHorariosProfissional(
        {
          idMedico: values?.profissional?.id || 4,
          idConsultorio: values?.consultorio?.id || 40502,
          dataInicial: dayjs(date).startOf('month').format('YYYYMMDD'),
          dataFinal: dayjs(date).endOf('month').format('YYYYMMDD'),
          somenteHorariosLivres: true,
        },
        { throwError: true },
      )
        .then((data: any) => setNumDates(data))
        .catch(() => setNumDates([]));
    },
    [values?.consultorio?.id, values?.profissional?.id],
  );

  const fetchHorariosProfissional = useCallback(
    (date: Date) => {
      setIsLoading(true);

      return PortalPacienteAPI.getHorariosProfissional(
        {
          idMedico: values?.profissional?.id || 4,
          idConsultorio: values?.consultorio?.id || 40502,
          data: dayjs(date).format('YYYYMMDD'),
          somenteHorariosLivres: true,
        },
        {
          throwError: true,
        },
      )
        .then((data: any) => data?.length && setHorarios(data[0]))
        .catch(() => setHorarios(null))
        .finally(() => setIsLoading(false));
    },
    [values?.consultorio?.id, values?.profissional?.id],
  );

  useEffect(() => {
    fetchNumHorarios(calendarValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNumHorarios]);

  useEffect(() => {
    fetchHorariosProfissional(calendarValue);
  }, [fetchHorariosProfissional, calendarValue]);

  const onSubmit = () => {
    handleSetData(value);
  };

  const datesToDisable = numDates
    .filter(d => !d.quantidadeAtendimentos)
    .map(d => new Date(dayjs(d.data) as any));

  const handlePreviousDate = () => {
    setCalendarValue(
      (old: Date) => new Date(dayjs(old).subtract(1, 'day') as any),
    );
  };
  const handleNextDate = () => {
    setCalendarValue((old: Date) => new Date(dayjs(old).add(1, 'day') as any));
  };

  return (
    <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2 p-px-0 p-px-sm-3">
      <SimpleText fontColor="color_60">
        Selecione as informações para o agendamento
      </SimpleText>

      <Calendar
        inline
        id="Calendar-agendamentos-form"
        locale="pt"
        value={calendarValue}
        viewDate={viewDate}
        onViewDateChange={e => [
          setViewDate(e.value),
          fetchNumHorarios(e.value),
        ]}
        onChange={e => setCalendarValue(e.value)}
        disabledDates={datesToDisable}
        dateTemplate={date => {
          const numDataAtual = numDates.find(d => {
            return dayjs(d.data).get('D') === date.day;
          });

          if (!!numDataAtual?.quantidadeAtendimentos && !date.otherMonth)
            return (
              <div style={{ position: 'relative' }}>
                <SimpleText style={{ pointerEvents: 'none' }}>
                  {date.day}
                </SimpleText>
                <div
                  className="badge-calendar-icon"
                  data-count={numDataAtual.quantidadeAtendimentos}
                  data-variant-color="green"
                />
              </div>
            );
          return date.day;
        }}
      />

      <div className="p-d-flex p-gap-1 p-ai-center p-px-1">
        <span className="legenda-calendar p-text-center">n</span>
        <SimpleText fontColor="color_60" fontSize="xxs">
          numero de horários disponíveis no dia.
        </SimpleText>
      </div>

      <div
        id="Agendamentos-horarios-container"
        className="p-border-container p-col-12 p-mt-2"
      >
        <div className="p-d-flex p-ai-center p-jc-center p-gap-3">
          <Button
            btnType="ghost"
            icon="fas fa-chevron-left"
            onClick={handlePreviousDate}
          />
          <SimpleText className="p-text-center">
            {dayjs(calendarValue).format('dddd, DD [de] MMMM')}
          </SimpleText>
          <Button
            btnType="ghost"
            icon="fas fa-chevron-right"
            onClick={handleNextDate}
          />
        </div>

        <Divider layout="horizontal" />

        <div className="scroll-content">
          {isLoading ? (
            <LoadingComp />
          ) : !!horarios?.atendimentos?.length ? (
            horarios.atendimentos.map((horario, idx) => (
              <div
                key={idx}
                onClick={() =>
                  setValue({
                    dataAgendamento: horario.dataAgendamento,
                    idAgendamento: horario.id,
                    flagsAgendamento: horario.flagsAtivas,
                  })
                }
                className="horarios-item"
                data-isDisabled={dayjs(horario.dataAgendamento).isBefore(
                  dayjs(),
                )}
                data-isSelected={horario.id === value?.idAgendamento}
              >
                <SimpleText
                  fontSize="sm"
                  className="p-flex-1 p-text-center"
                  medium
                >
                  {dayjs(horario.dataAgendamento).format('HH:mm')}
                </SimpleText>
                <div className="p-flex-3 p-grid p-gap-1">
                  {horario.flagsAtivas.map((flag: string) => {
                    if (flag === 'INTERNET') flag = 'CONSULTA';
                    return (
                      validateFlags(flag) && (
                        <SimpleText
                          key={flag}
                          className="tag-item"
                          data-tag-type={flag.toLowerCase()}
                          medium
                        >
                          {capitalizeFirstLetter(flag)}
                        </SimpleText>
                      )
                    );
                  })}
                </div>
              </div>
            ))
          ) : (
            <div className="p-d-flex p-jc-center">
              <SimpleText>Não há horários disponiveis nesse dia.</SimpleText>
            </div>
          )}
        </div>
      </div>

      <div className="p-d-flex p-gap-2 p-mt-3">
        <Button
          label="Voltar"
          btnType="ghost"
          className="p-col-3"
          onClick={handleGoBack}
        />
        <Button
          label="Salvar e avançar"
          disabled={!value?.idAgendamento}
          onClick={onSubmit}
          stretch
        />
      </div>
    </div>
  );
};

const LoadingComp = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-1 p-col-12 p-px-0">
      <Skeleton loading borderRadius="6px" height="30px" />
      <Skeleton loading borderRadius="6px" height="30px" />
      <Skeleton loading borderRadius="6px" height="30px" />
    </div>
  );
};
