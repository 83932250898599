import { useCallback, useContext, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { SessaoAPI } from 'src/APIs/SecurityAPI/SessaoAPI/SessaoAPI';
import { PASS_TYPES } from 'src/models/APIs/SecurityAPI/AuthAPI/PasswordModels';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { clearProfissionais } from 'src/core/redux/slices/agenda/AgendaSlice';
import { logInAction } from 'src/core/redux/slices/system/UserSlice';
import { RootState } from 'src/core/redux/store';
import { ROUTES } from 'src/core/routes/Emed/utils';
import ThemeContext from 'src/core/themes/ThemeContext';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import { BtnTypes } from 'src/components/Basics/Button/Buttons';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import LoginLayout from 'src/components/LoginLayout/LoginLayout';

import ModalSessoes from './ModalSessoes/ModalSessoes';

import './Login.scss';

export const Login = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAppSelector((state: RootState) => state);
  const { theme } = useContext(ThemeContext);
  const modalSessoes = useDisclosure({ opened: false });
  const { isMobile } = useSize();

  const formLoginValidation = Yup.object().shape({
    username: Yup.string().required('O campo usuário é obrigatório.'),
    password: Yup.string().required('O campo senha é obrigatório.'),
  });

  const form = useForm({
    resolver: yupResolver(formLoginValidation),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const loginRedirectCB = useCallback(
    (stateFrom: any, redirectToLastPage: boolean) => {
      const origin: Location = !redirectToLastPage
        ? ROUTES.HOME
        : stateFrom?.pathname || ROUTES.HOME;

      navigate(origin, { replace: true });
    },
    [navigate],
  );

  useEffect(() => {
    dispatch(clearProfissionais());
  }, [dispatch]);

  useEffect(() => {
    const isLogin: any =
      location.pathname === ROUTES.LOGIN ||
      location.pathname === ROUTES.LOGIN_FACIAL ||
      location.pathname === ROUTES.LOGIN_FORGOT_PASSWORD;
    if (isLogin && user.access_token) {
      const redirectToLastPage =
        user?.idUltimoUsuarioAutenticado === user?.idUsuario;

      loginRedirectCB(location?.state?.from, redirectToLastPage);
    }
  }, [location, user, loginRedirectCB]);

  const handleLogin = async (data: any) => {
    try {
      const sessoesParams = {
        usuario: data.username,
        senha: data.password,
      };

      const response = await SessaoAPI.checkSessoes(sessoesParams);

      const signIn = () =>
        dispatch(
          logInAction({
            ...data,
            grant_type: PASS_TYPES.PASSWORD,
          }),
        );

      if (!response?.flagNecessarioInativarSessao) {
        return await signIn();
      }

      return modalSessoes.open({
        state: {
          ...response,
          handleLogin: signIn,
        },
      });
    } catch (error) {
      // error
    }
  };

  const renderlogin = () => {
    return (
      <div
        style={{
          paddingInline: isMobile ? '20px' : '20%',
        }}
        className={`MainLogin ${theme}`}
      >
        <div className="p-col-12 p-d-flex p-flex-column p-gap-3">
          <SimpleText fontSize={FONT_SIZE.XL}>Bem-vindo</SimpleText>
          <SimpleText
            fontSize={FONT_SIZE.SM}
            fontColor="color_40"
            className="p-line-height-2"
          >
            Entre para tornar o gerenciamento da sua clínica mais fácil e
            prático com o novo e-Med.
          </SimpleText>
        </div>

        <FormProvider {...form}>
          <form
            className="p-col-12 p-mt-2"
            onSubmit={handleSubmit(handleLogin)}
          >
            <FormInput
              name="username"
              label="Usuário"
              className="p-mb-3"
              placeholder="Usuário"
            />

            <PasswordInputControlled
              name="password"
              errorMsg={errors.password?.message}
              control={control}
              label="Senha"
              placeholder="Insira sua senha"
              toggleMask
            />

            <div className={'p-col-12 p-p-0 p-mt-5'}>
              <Button
                label="Autenticar com senha"
                type="submit"
                className="btn-submit"
                loading={isSubmitting}
                stretch
              />
            </div>
          </form>
        </FormProvider>

        <div className="strike p-col-12">
          <SimpleText>Ou</SimpleText>
        </div>

        <div className="p-col-12">
          <Button
            onClick={() => {
              navigate(ROUTES.LOGIN_FACIAL);
            }}
            btnType={BtnTypes.OUT}
            label="Autenticar com biometria facial"
            type="button"
            className="p-mb-4"
            stretch
          />
          <Button
            onClick={() => {
              navigate(ROUTES.LOGIN_FORGOT_PASSWORD);
            }}
            label="Esqueceu sua senha?"
            btnType="green-link"
            className="p-px-0 bnt-esqueceu-senha"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {modalSessoes.isOpen && <ModalSessoes {...modalSessoes} />}
      <LoginLayout content={renderlogin()} maxWidth={640} />
    </>
  );
};
