import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldSexoProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldSexo = ({
  name = 'sexo',
  label,
  ...rest
}: FieldSexoProps) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const sexoOptions = [
    {
      label: 'Masculino',
      value: 3,
    },
    {
      label: 'Feminino',
      value: 1,
    },
  ];

  return (
    <DropdownControlled
      control={control}
      className="p-col-12"
      name={name}
      label={label}
      filter={false}
      errorMsg={error?.message}
      options={sexoOptions}
      {...rest}
    />
  );
};
