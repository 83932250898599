import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const ProcedimentosSolicitadosListHeader = () => {
  const { isLarge, windowInnerWidth } = useSize();

  if (isLarge) return null;

  return (
    <div className="p-grid">
      <div className="checkbox-col p-col-fixed" />

      <SimpleText
        className="p-col-2 p-xl-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Código
      </SimpleText>

      <SimpleText
        className={`p-col`}
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Descrição
      </SimpleText>

      <SimpleText
        className="p-col-2 p-lg-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Orientação
      </SimpleText>

      <SimpleText
        className="p-col-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Qtd.
      </SimpleText>

      <SimpleText
        className="data-realizacao-col p-col-fixed"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Data de realização
      </SimpleText>

      <div
        className={`p-col-6 ${
          windowInnerWidth <= 1300 ? 'p-lg-2 p-xl-3' : 'p-lg-5'
        }`}
      />
    </div>
  );
};
