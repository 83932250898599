import Http, { HttpParams } from 'src/core/http/Http';

export const postLoginEndpoint = (
  form: FormData,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<User> => {
  return Http.post({
    service: 'security',
    url: '/oauth/token',
    data: form,
    ...options,
  });
};
export const sendPortalLoginAutomatico = (
  hash: string,
  codigoEncriptado: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<User> => {
  return Http.get({
    service: 'sessao',
    url: '/auto-login',
    params: {
      codigoEncriptado,
      hash,
    },
    ...options,
  });
};

export const postRefreshTokenEndpoint = (
  refresh_token: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<User> => {
  const formAux: FormData = new FormData();
  formAux.append('grant_type', 'refresh_token');
  formAux.append('refresh_token', refresh_token);
  return Http.post({
    service: 'security',
    url: '/oauth/token',
    data: formAux,
    hideToast: true,
    ...options,
  });
};

export const refreshtokenEmpresa = (
  refresh_token: string,
  idEmpresa: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<User> => {
  const formAux: FormData = new FormData();
  formAux.append('grant_type', 'refresh_token');
  formAux.append('refresh_token', refresh_token);
  return Http.post({
    service: 'security',
    url: `/oauth/token?idEmpresa=${idEmpresa}`,
    data: formAux,
    hideToast: true,
    ...options,
  });
};

export const AuthAPI = {
  refreshtokenEmpresa,
  sendPortalLoginAutomatico,
};

export default AuthAPI;
