import { useState, useEffect, useContext, useCallback } from 'react';

import { useLocation, Link, useParams } from 'react-router-dom';

import './Breadcrumbs.scss';

import { userRoutes, RouteItem } from 'src/core/routes/Emed/RouterMap';
import ThemeContext from 'src/core/themes/ThemeContext';

interface BreadcrumbsProps {
  preventBreadcrumbClick?: boolean;
}

const Breadcrumbs = ({
  preventBreadcrumbClick,
}: BreadcrumbsProps): JSX.Element => {
  const location = useLocation();
  const { hashEmpresa } = useParams();
  const theme = useContext(ThemeContext);
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

  /**
   * Set breadcrumbs value based on split of current path
   */
  const readBreadcrumbs = useCallback((pathname: string): void => {
    const currentRoutes = pathname.split('/').filter(i => i);
    setBreadcrumbs(currentRoutes);
  }, []);

  useEffect(() => {
    readBreadcrumbs(location.pathname);
  }, [location.pathname, readBreadcrumbs]);

  /**
   * Find in list of routes the info about the target path
   */
  const findRouteByPath = useCallback(
    (userRoutes: RouteItem[], routePath: string): RouteItem => {
      // empty route
      let route: RouteItem = {
        element: <></>,
        route: '',
        label: '',
      };

      // find route or child route that matchs the route name
      userRoutes.every(currentRoute => {
        if (currentRoute.route === routePath) {
          route = currentRoute;
        } else if (currentRoute.items) {
          route = findRouteByPath(currentRoute.items, routePath);
        }
        return route.label === '';
      });
      return route;
    },
    [],
  );

  /**
   * Generate breadcrumbs JSX to view based on split of '/' in url, always have
   * a home link
   */
  const renderBreadcrumbsElements = useCallback((): JSX.Element[] => {
    const isPortal = location.pathname.includes('portal');

    const renderContent = [];
    // Add home breadcrumb
    renderContent.push(
      <Link
        className={'breadcrumbs'}
        to={isPortal ? `/portal/${hashEmpresa}/inicio` : '/'}
        key={9999}
      >
        Início
      </Link>,
    );

    let pathSequence = '';

    breadcrumbs.forEach((link, i) => {
      pathSequence += '/' + link;

      const routeInfo = findRouteByPath(userRoutes, pathSequence);

      const isLastBreadcrumbs = breadcrumbs.length === i + 1;

      if (routeInfo.label) {
        let content = null;
        if (isLastBreadcrumbs) {
          content = (
            <span key={i} className={'breadcrumbs'}>
              {' > ' + routeInfo.label}
            </span>
          );
        } else {
          content = (
            <span key={i}>
              <span className={'breadcrumbs'}>{' > '}</span>
              {preventBreadcrumbClick ? (
                <span className={'breadcrumbs'}>{routeInfo.label}</span>
              ) : (
                <Link className={'breadcrumbs'} to={routeInfo.route}>
                  {routeInfo.label}
                </Link>
              )}
            </span>
          );
        }

        renderContent.push(content);
      }
    });

    return renderContent;
  }, [
    location.pathname,
    hashEmpresa,
    breadcrumbs,
    findRouteByPath,
    preventBreadcrumbClick,
  ]);

  return (
    <div className={`breadcrumb ${theme?.theme || ''}`}>
      {renderBreadcrumbsElements()}
    </div>
  );
};

export default Breadcrumbs;
