/* eslint-disable @typescript-eslint/ban-types */
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

import './SearchList.scss';
import { isMobile } from 'react-device-detect';

interface SearchListProps {
  fetchAPI?(params?: any): Promise<Paginator<any>> | Paginator<any>;
  renderHeader?: Function;
  renderRow: Function;
  renderMobileRow?: Function;
  reload: number;
}

const SearchList = (props: SearchListProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { fetchAPI, renderHeader, renderRow, renderMobileRow, reload } = props;

  const page = useRef(0);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState<any[]>([]);

  const fetch = useCallback(async () => {
    if (!fetchAPI) return [];
    // TODO: Alterar para chamada da API

    try {
      const aux = await fetchAPI(page.current);

      return aux.list;
    } catch (e) {
      console.log('Error fetching data from SearchList: ', e);
    }

    // TODO: Validar quantidade de resultado
    // para desabilitar scroll infinito
    const objectConstructor = {}.constructor;

    return Array.from({ length: 50 }).filter(
      (v: any) => v && v.constructor === objectConstructor,
    );
  }, [fetchAPI]);

  const load = useCallback(async () => {
    setHasMore(true);
    page.current = 0;
    setItems([]);

    setLoading(true);
    const newItems = await fetch();

    setItems(newItems);

    if (newItems.length === 0) {
      setHasMore(false);
    }

    setLoading(false);
  }, [fetch]);

  const fetchMoreData = useCallback(async () => {
    page.current = page.current + 1;

    const newItems = await fetch();
    setItems(prev => {
      return [...prev.concat(newItems)];
    });
  }, [fetch]);

  useEffect(() => {
    load();
  }, [load, reload]);

  return (
    <div id="horario-atendimento-search-list">
      <div className="grid-list-horarios-atendimento">
        {renderHeader && renderHeader()}
        {loading && hasMore ? (
          <GridListLoading />
        ) : items && items.length === 0 ? (
          <Empty />
        ) : (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={!hasMore ? <GridListLoading /> : <></>}
          >
            {items &&
              Array.isArray(items) &&
              (items || []).map((item, index) => {
                return (
                  <div key={index}>
                    {isMobile && renderMobileRow
                      ? renderMobileRow(item)
                      : renderRow(item, index, items.length)}
                  </div>
                );
              })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const Empty = memo(() => {
  return (
    <div className="grid-list-content-empty">
      <SimpleText>
        Médico não possui nenhum horário de atendimento para o consultório
        selecionado
      </SimpleText>
    </div>
  );
});

export default memo(SearchList);
