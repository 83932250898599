import { useCallback, useEffect, useState } from 'react';

import './ModalAddFornecedorTuss.scss';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

const formFields = {
  nome: '',
};

interface ModalAddFornecedorTussProps {
  visible: boolean;
  onHide(): void;
  reload: any;
  fornecedorTussSelecionado: any;
}

const ModalAddFornecedorTuss = ({
  fornecedorTussSelecionado,
  visible,
  onHide,
  reload,
}: ModalAddFornecedorTussProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [hasFornecedorTussSelecionado, setHasFornecedorTussSelecionado] =
    useState(false);

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Digite um nome para o Fornecedor'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const loadPage = useCallback(() => {
    if (Object.keys(fornecedorTussSelecionado).length > 0) {
      setValue('nome', fornecedorTussSelecionado?.nome || '');
      setHasFornecedorTussSelecionado(true);
    } else {
      setHasFornecedorTussSelecionado(false);
    }
  }, [fornecedorTussSelecionado, setValue]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const handleCancel = useCallback(() => {
    reset(formFields);
    onHide();
  }, [onHide, reset]);

  const handleFornecedorTuss = useCallback(
    async (data: any) => {
      let response: any;

      const fornecedorTussEditado = {
        ...fornecedorTussSelecionado,
        nome: data.nome,
        status: true,
        idUsuario: user?.idUsuario,
        idEmpresa: user?.idEmpresa,
      };

      if (hasFornecedorTussSelecionado) {
        response = await FornecedorTussAPI.updateFornecedorTuss(
          fornecedorTussSelecionado.id!,
          fornecedorTussEditado,
        );
      } else {
        response = await FornecedorTussAPI.sendFornecedorTuss(
          fornecedorTussEditado,
        );
      }

      if (response?.status === 200 || response?.status === 201) {
        reload();
        handleCancel();
      }
    },
    [
      fornecedorTussSelecionado,
      user?.idUsuario,
      user?.idEmpresa,
      hasFornecedorTussSelecionado,
      reload,
      handleCancel,
    ],
  );

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header={
        hasFornecedorTussSelecionado
          ? 'Editar fornecedor TUSS'
          : 'Adicionar fornecedor TUSS'
      }
      visible={visible}
      onHide={handleCancel}
      footer={() => <div />}
      maximizedMobileSize
    >
      <form
        className="modal-body-add-fornecedor-tuss"
        onSubmit={handleSubmit(handleFornecedorTuss)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <TextInput
              label="Nome"
              className="grid p-col-12 p-md-12"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              errorMsg={errors.nome?.message}
            />
          )}
          name="nome"
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Voltar"
            onClick={handleCancel}
            stretch
            loading={isSubmitting}
          />
          <Button
            label={'Salvar'}
            type="submit"
            onClick={handleSubmit(handleFornecedorTuss)}
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default ModalAddFornecedorTuss;
