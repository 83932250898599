import { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './FieldTipoPlano.scss';

export const FieldTipoPlano = () => {
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const watchConvenio = watch('convenio');

  const fetch = useCallback(async (value: any) => {
    if (!value?.id) return;
    setLoading(true);

    const data = await ConvenioAPI.getConvenioTiposPlano(value?.id);

    if (!('status' in data)) setList(data);

    setLoading(false);
  }, []);

  useEffect(() => {
    fetch(watchConvenio);
  }, [fetch, watchConvenio]);

  const options = list.map((item): any => ({
    label: item.descricao,
    value: item,
  }));

  const itemTemplate = (option: any) => (
    <div className="p-w-100 p-d-flex list-row">
      <div className="p-col-6 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Tipo
        </SimpleText>
        <SimpleText>{option.label}</SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Automatização carteira APS
        </SimpleText>
        <SimpleText>
          {!!option.value.automatizarCarteiraAPS ? 'Ativo' : 'Inativo'}
        </SimpleText>
      </div>
    </div>
  );

  return (
    <div className="p-col-12">
      <DropdownControlled
        panelClassName="tipo-plano-panel"
        control={control}
        name="tipoPlano"
        label="Tipo do plano"
        placeholder="Selecione o tipo do plano"
        emptyMessage="Não há tipos para esse plano."
        dataKey="id"
        options={options}
        itemTemplate={itemTemplate}
        filter={false}
        loading={loading}
        disabled={!watchConvenio}
        errorMsg={errors.tipoPlano?.message}
      />
    </div>
  );
};
