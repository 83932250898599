import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import './ModalExcluirRecorrencia.scss';

interface ModalExcluirRecorrenciaProps {
  idAtendimentoRecorrente: number | null;
  dataAgendamento: string | null;
}

const ModalExcluirRecorrencia = ({
  idAtendimentoRecorrente,
  dataAgendamento,
}: ModalExcluirRecorrenciaProps) => {
  const navigate = useNavigate();

  const [quantidadeAptos, setQuantidadeAptos] = useState<number>(0);
  const [confirmado, setConfirmado] = useState<boolean>(false);

  const handleExcluirRecorrencia = useCallback( async() => {

    const response = await AgendamentosAPI.deleteAgendamentoRecorrente(
      Number(idAtendimentoRecorrente),
      {
        data: dayjs(dataAgendamento).format('YYYY-MM-DD'),
        confirmado: confirmado,
      }
    );

    setQuantidadeAptos(response.data.quantidadeAptos);

    if (response.status === 200 && confirmado) {
      navigate('/agenda');
    }
    },
    [confirmado, dataAgendamento, idAtendimentoRecorrente, navigate],
  );

  useEffect(() => {
    handleExcluirRecorrencia();
  }, [confirmado, handleExcluirRecorrencia]);

  return (
    <div id="modal-excluir" className="p-d-flex p-flex-column modelos p-p-2">
      <div className="p-d-flex p-flex-column p-jc-between p-ai-center">
        <SimpleText
          className="p-ml-2 p-mb-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          O agendamento selecionado pertence a um agendamento recorrente, este e
          todos os agendamentos futuros incluídos na mesma recorrência serão
          excluídos.
        </SimpleText>
        <SimpleText
          className="p-ml-2 p-my-3"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Você realmente deseja excluir {quantidadeAptos} agendamentos recorrentes?
        </SimpleText>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12">
          <Button
            label="Sim, excluir todos"
            type="submit"
            btnType={BtnTypes.DANGER}
            onClick={() => setConfirmado(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalExcluirRecorrencia;
