import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/empresa-provedor-sms';

export const loadEmpresaProvedorSms = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaProvedorSmsDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

const loadEmpresaProvedores = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/provedores`,
    hideToast: true,
    ...options,
  });
};

export const sendEmpresaProvedorSms = (
  payload: PostEmpresaProvedorSmsDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaProvedorSmsDTO> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateEmpresaProvedorSms = (
  payload: PostEmpresaProvedorSmsDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaProvedorSmsDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeEmpresaProvedorSms = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaProvedorSmsDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}`,
    return: true,
    ...options,
  });
};

const EmpresaProvedorSmsAPI = {
  loadEmpresaProvedorSms,
  sendEmpresaProvedorSms,
  removeEmpresaProvedorSms,
  updateEmpresaProvedorSms,
  loadEmpresaProvedores,
};

export default EmpresaProvedorSmsAPI;
