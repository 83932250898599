import { useState } from 'react';

import { PanelHeaderTemplateOptions } from 'primereact/panel';
import { useLocation } from 'react-router';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { generateUniqueId } from 'src/utils/utils';

import { ModalParecer } from '../../components/ModalParecer/ModalParecer';
import { ModalUploadLaudo } from '../../components/ModalUploadLaudo/ModalUploadLaudo';
import { ModalVisualizarLaudo } from '../../components/ModalVisualizarLaudo/ModalVisualizarLaudo';
import { PanelSolicitacaoRecente } from '../../components/PanelSolicitacaoRecente/PanelSolicitacaoRecente';
import { SelectablePanel } from '../../components/SelectablePanel/SelectablePanel';
import AlertBox from 'src/components/AlertBox/AlertBox';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { useDeleteProcedimentosSolicitados } from '../hooks/useDeleteProcedimentosSolicitados';
import { ModalEditarProcedimento } from '../ModalEditarProcedimento/ModalEditarProcedimento';

import { ProcedimentosSolicitadosPanelHeader } from './ProcedimentosSolicitadosPanelHeader';
import { ProcedimentosSolicitadosPanelHeaderBtns } from './ProcedimentosSolicitadosPanelHeaderBtns';

interface ProcedimentosSolicitadosPanelProps {
  procedimento: any;
  onDelete: () => void;
}

export const ProcedimentosSolicitadosPanel = (
  props: ProcedimentosSolicitadosPanelProps,
) => {
  const { procedimento, onDelete } = props;
  const { atendimento } = useAppSelector((state: RootState) => state);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [reload, setReload] = useState(0);

  const modalUploadLaudo = useDisclosure({ opened: false });
  const modalVisualizarLaudo = useDisclosure({ opened: false });
  const modalParecer = useDisclosure({ opened: false });
  const modalEditarProcedimento = useDisclosure({ opened: false });

  const {
    handleUpdateProcedimentoPropValue,
    fetchProcedimentosSolicitados,
    handleUpdateProcedimentosInGuias,
  } = useSolicitacaoExameCirurgia();

  const { handleDeleteProcedimentoSolicitado } =
    useDeleteProcedimentosSolicitados({
      idAtendimento: Number(idAtendimento),
      onDelete,
    });

  const reloadList = () => {
    setReload(prevReload => prevReload + 1);
  };

  const handleEdit = () =>
    modalEditarProcedimento.open({
      state: {
        procedimento,
        onReloadList: fetchProcedimentosSolicitados,
        onUpdateList: handleUpdateProcedimentosInGuias,
      },
    });

  const handleDelete = () =>
    handleDeleteProcedimentoSolicitado(procedimento.idSolicitacaoProcedimento);

  const onUploadLaudoSuccess = (laudoArquivo: string) => {
    handleUpdateProcedimentoPropValue(
      procedimento.idSolicitacaoProcedimento,
      'resultadoExame',
      { ...procedimento?.resultadoExame, laudoArquivo },
    );
    reloadList();
  };

  const handleUploadLaudo = () => {
    const laudoArquivo = procedimento?.resultadoExame?.laudoArquivo;

    if (laudoArquivo) {
      return modalVisualizarLaudo.open({ state: { laudoArquivo } });
    }

    return modalUploadLaudo.open({
      state: {
        idAtendimento: procedimento.idAtendimento,
        idProcedimento: procedimento.idSolicitacaoProcedimento,
        isProcedimentoExterno: false,
        onSuccess: onUploadLaudoSuccess,
      },
    });
  };

  const handleOpenModalParecer = () =>
    modalParecer.open({
      state: {
        parecer: procedimento?.parecerMedicoResultadoExame,
        idAtendimento: procedimento.idAtendimento,
        idProcedimento: procedimento.idSolicitacaoProcedimento,
        isProcedimentoExterno: false,
        onSuccess: (parecer: string) =>
          handleUpdateProcedimentoPropValue(
            procedimento.idSolicitacaoProcedimento,
            'parecerMedicoResultadoExame',
            parecer,
          ),
      },
    });

  const renderProcedimentosSolicitadosPanelHeader = (
    options: PanelHeaderTemplateOptions,
  ) => (
    <ProcedimentosSolicitadosPanelHeader
      procedimento={procedimento}
      panelHeaderOptions={options}
    >
      <ProcedimentosSolicitadosPanelHeaderBtns
        descricaoProcedimento={procedimento.descricaoProcedimento}
        isPacote={!!procedimento.pacoteExame}
        isNotProcedimentoPrincipalPacote={!procedimento.servicoTussPrincipal}
        hasAnexoLaudo={!!procedimento?.resultadoExame?.laudoArquivo}
        hasParecer={!!procedimento.parecerMedicoResultadoExame}
        onUploadLaudo={handleUploadLaudo}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onOpenParecer={handleOpenModalParecer}
      />
    </ProcedimentosSolicitadosPanelHeader>
  );

  const fetchHistorico = ({ query, ...params }: any) =>
    SolicitacoesProcedimentosAPI.getHistoricoSadt(Number(idAtendimento), {
      ...params,
      codigoProcedimento: procedimento.codigoProcedimento,
    });

  const renderRow = (procedimento: any) => {
    const idProcedimento =
      procedimento?.idSolicitacaoProcedimentoExterno ??
      procedimento?.idSolicitacaoProcedimento;
    const isProcedimentoExterno =
      !!procedimento?.idSolicitacaoProcedimentoExterno;

    const handleUploadLaudo = () => {
      const laudoArquivo = procedimento?.resultadoExame?.laudoArquivo;

      if (laudoArquivo) {
        return modalVisualizarLaudo.open({ state: { laudoArquivo } });
      }

      return modalUploadLaudo.open({
        state: {
          idAtendimento: procedimento.idAtendimento,
          idProcedimento,
          isProcedimentoExterno,
          onSuccess: () => setReload(prevReload => prevReload + 1),
        },
      });
    };

    const handleOpenModalParecer = () =>
      modalParecer.open({
        state: {
          parecer: procedimento?.parecerMedicoResultadoExame,
          idAtendimento: procedimento.idAtendimento,
          idProcedimento,
          isProcedimentoExterno,
          onSuccess: () => setReload(prevReload => prevReload + 1),
        },
      });

    return (
      <PanelSolicitacaoRecente
        procedimento={procedimento}
        onUploadLaudo={handleUploadLaudo}
        onOpenParecer={handleOpenModalParecer}
      />
    );
  };

  return (
    <div className="procedimento-solicitado-panel">
      {modalVisualizarLaudo.isOpen && (
        <ModalVisualizarLaudo {...modalVisualizarLaudo} />
      )}
      {modalParecer.isOpen && <ModalParecer {...modalParecer} />}
      {modalUploadLaudo.isOpen && <ModalUploadLaudo {...modalUploadLaudo} />}
      {modalEditarProcedimento.isOpen && (
        <ModalEditarProcedimento {...modalEditarProcedimento} />
      )}

      <SelectablePanel
        headerTemplate={renderProcedimentosSolicitadosPanelHeader}
      >
        <SearchList.Root fetchApi={fetchHistorico}>
          <SearchList.InfiniteScroll
            autoHeight={false}
            renderRow={renderRow}
            reload={reload}
          />
        </SearchList.Root>
      </SelectablePanel>

      {procedimento.pendencia?.mensagem
        .split('<br/>')
        .map((mensagem: string) => {
          if (!mensagem) return;

          const [type, msg] = mensagem.split(':', 2);

          const hasNotPendencia =
            type === 'JUSTIFICATIVA' && procedimento.justificativa;

          if (!msg || hasNotPendencia) return;

          return (
            <AlertBox
              key={generateUniqueId()}
              type={procedimento.pendencia.bloqueio ? 'DANGER' : 'WARN'}
              visible={true}
              text={msg}
            />
          );
        })}

      {procedimento.pendencia?.necessitaIndicacaoClinica &&
        !procedimento.indicacaoClinica && (
          <AlertBox visible text="Necessário informar indicação clínica" />
        )}

      {procedimento.pendencia?.necessitaIndicarAcidente &&
        !atendimento.indicacaoAcidente && (
          <AlertBox visible text="Necessário informar indicação acidente" />
        )}

      {procedimento.pendencia?.necessitaLaudo &&
        !procedimento?.resultadoExame?.laudoArquivo && (
          <AlertBox visible text="Necessário informar laudo" />
        )}
    </div>
  );
};
