import Http, { HttpParams } from 'src/core/http/Http';

export const loadGrupoAtendimentoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetGrupoAtendimentoDTO> => {
  return Http.get({
    service: 'agenda',
    url: `grupos-atendimento/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendGrupoAtendimento = (
  payload: PostGrupoAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostGrupoAtendimentoDTO> => {
  return Http.post({
    service: 'agenda',
    url: '/grupos-atendimento',
    data: payload,
    ...options,
  });
};

export const updateGrupoAtendimento = (
  id: number,
  payload: PostGrupoAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostGrupoAtendimentoDTO> => {
  return Http.put({
    service: 'agenda',
    url: `/grupos-atendimento/${id}`,
    data: payload,
    ...options,
  });
};

export const removeGrupoAtendimento = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetGrupoAtendimentoDTO> => {
  return Http.delete({
    service: 'agenda',
    url: `/grupos-atendimento/${id}`,
    return: true,
    ...options,
  });
};

export const loadGrupoAtendimentos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetGrupoAtendimentoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: 'grupos-atendimento/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadGrupoAtendimentosTodos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetGrupoAtendimentoDTO[]> => {
  return Http.get({
    service: 'agenda',
    url: 'grupos-atendimento/listar-todos',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadGrupoAtendimentoDetalhe = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GrupoAtendimentoGerenciar> => {
  return Http.get({
    service: 'agenda',
    url: `agendas/buscar-atendimento-grupo-detalhe/${id}`,
    hideToast: true,
    ...options,
  });
};

export const putLiberarAtendimentoGrupo = (
  id: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GrupoAtendimentoGerenciar> => {
  return Http.put({
    service: 'agenda',
    url: `agendas/grupo-atendimento/liberar/${id}`,
    data: params,
    successMessage: 'Sucesso grupo liberado para atendimento',
    ...options,
  });
};

export const loadTiposGrupoAtendimentos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetGrupoAtendimentoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: 'tipo-grupo-atendimento/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

const GrupoAtendimentoAPI = {
  loadGrupoAtendimentos,
  loadGrupoAtendimentoById,
  sendGrupoAtendimento,
  updateGrupoAtendimento,
  removeGrupoAtendimento,
  loadTiposGrupoAtendimentos,
  loadGrupoAtendimentosTodos,
  loadGrupoAtendimentoDetalhe,
  putLiberarAtendimentoGrupo,
};

export default GrupoAtendimentoAPI;
