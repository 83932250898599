import React, { useCallback, useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { ToggleButton } from 'primereact/togglebutton';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import CiapAPI from 'src/APIs/ProntuarioAPI/CiapAPI/CiapAPI';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  resetInvalidateQuery,
  setInvalidateQuery,
} from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import DropdownCiap from './DropdownCiap';
import ModalBuscaAvancada from './ModalBuscaAvancada';
import ModalFavoritos from './ModalFavoritos';

import './Ciap.scss';

interface CiapProps {
  campos: any[];
  usuario: any;
  updateCamposProntuario: (campos: any) => void;
  atendimentoStatus: string;
}

function Ciap({ campos, atendimentoStatus }: CiapProps) {
  const { agenda, query } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getListaProblemas, listaProblemas } = useAtendimento();
  const favoritosRef = useRef<OverlayPanel>(null);

  const dispatch = useAppDispatch();

  const { invalidateCIAPs } = query;

  const { control } = useForm({});

  const [campoCiap, setCampoCiap] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(
    atendimentoStatus === 'ATENDENDO_TRIAGEM'
      ? true
      : campoCiap?.exibicaoCamposModelo === 'FIXO'
      ? true
      : false,
  );
  const [ciaps, setCiaps] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const modalDisclosure = useDisclosure({ opened: false });
  const buscaModalDisclosure = useDisclosure({ opened: false });

  const getCiaps = async (idAtendimento: any) => {
    const response = await CiapAPI.getCiaps(Number(idAtendimento));
    setCiaps(response?.data?.ciaps?.list);
  };

  const handleResetComorbidades = useCallback(() => {
    dispatch(setInvalidateQuery({ invalidateComorbidades: true }));
  }, [dispatch]);

  const adicionarComorbidade = useCallback(
    async (ciap: any) => {
      setIsLoading(true);

      const payload = {
        idAtendimento: Number(idAtendimento),
        dataOcorrencia: new Date(),
        dataInicio: new Date(),
        idProfissionalSaude: Number(agenda?.profissionalAtivo?.id),
        situacao: 'ATIVO',
        ciap: [ciap],
      };

      ComorbidadeAPI.adicionarComorbidade(payload, true, {
        throwError: true,
      })
        .then(() => [getListaProblemas(), handleResetComorbidades()])
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    [
      agenda?.profissionalAtivo?.id,
      getListaProblemas,
      handleResetComorbidades,
      idAtendimento,
    ],
  );

  const removerComorbidade = useCallback(
    (ciap: any) => {
      setIsLoading(true);

      const idComorbidade = listaProblemas?.filter(
        comorbidade => comorbidade.ciap[0]?.idCiap === ciap.id,
      )[0].id;

      ComorbidadeAPI.removerComorbidade(
        idComorbidade,
        Number(idAtendimento),
        true,
        { throwError: true },
      )
        .then(() => [getListaProblemas(), handleResetComorbidades()])
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    [getListaProblemas, handleResetComorbidades, idAtendimento, listaProblemas],
  );

  useEffect(() => {
    setCampoCiap(campos.filter(campo => campo.funcionalidade === 'CIAP')[0]);
    setChecked(campoCiap.exibicaoCamposModelo === 'FIXO' ? true : false);
    getCiaps(idAtendimento);
    getListaProblemas();
  }, [
    campoCiap.exibicaoCamposModelo,
    campos,
    getListaProblemas,
    idAtendimento,
  ]);

  useEffect(() => {
    if (invalidateCIAPs) {
      getListaProblemas();
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, getListaProblemas, invalidateCIAPs]);

  useEffect(() => {
    favoritosRef.current?.hide();
  }, [ciaps]);

  const reloadCampos = () => {
    if (campoCiap.exibicaoCamposModelo === 'FIXO') {
      campoCiap.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoCiap.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoCiap.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }
    const exibixaoCampo =
      campoCiap.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoCiap.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const onRemoveCiap = useCallback(async (ciap: any, idAtendimento: any) => {
    const idCiap = ciap.id;
    const response = await UtilsAPI.deleteCiap(idAtendimento, idCiap);
    if (response?.status === 204) {
      getCiaps(idAtendimento);
    }
  }, []);

  const listaCiaps = useCallback(() => {
    return (
      <>
        {ciaps?.map((ciap, idx) => {
          return (
            <div key={idx} className="p-col-12 ciap-item p-my-2">
              <div className="p-d-flex p-ai-center w-100">
                <SimpleText
                  className="p-ml-2 p-col-6 p-p-0"
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  ({ciap.codigo}){' '}
                  {capitalizeFirstLetter(ciap.nome.toLowerCase())}
                </SimpleText>
                <Checkbox
                  className="p-ml-3 p-col-4  p-p-0"
                  name={ciap.id}
                  label="Adicionar na lista de problemas"
                  value={ciap}
                  onChange={e => {
                    !!e.checked
                      ? adicionarComorbidade(ciap)
                      : removerComorbidade(ciap);
                  }}
                  checked={listaProblemas?.some(
                    (comorbidade: any) =>
                      comorbidade.ciap[0]?.idCiap === ciap.id,
                  )}
                  disabled={
                    isLoading ||
                    listaProblemas?.some(
                      (comorbidade: any) =>
                        comorbidade.ciap[0]?.idCiap === ciap.id &&
                        comorbidade?.idAtendimento !== Number(idAtendimento),
                    )
                  }
                />
                <div className="p-col p-p-0 p-d-flex p-jc-end">
                  <Button
                    icon="fas fa-trash"
                    btnType="ghost"
                    onClick={() => {
                      onRemoveCiap(ciap, idAtendimento);
                      listaProblemas?.some(
                        (comorbidade: any) =>
                          comorbidade.ciap[0]?.idCiap === ciap.id &&
                          comorbidade.idAtendimento === Number(idAtendimento),
                      ) && removerComorbidade(ciap);
                    }}
                  />
                </div>
              </div>

              <SimpleText
                className="p-ml-2"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Adicionado por {ciap.usuarioAtualizacao?.nome} em{' '}
                {dayjs(ciap.dataAtualizacao).format('DD/MM/YYYY')}
              </SimpleText>
            </div>
          );
        })}
      </>
    );
  }, [
    ciaps,
    listaProblemas,
    isLoading,
    adicionarComorbidade,
    removerComorbidade,
    onRemoveCiap,
    idAtendimento,
  ]);

  const isRequired = campoCiap?.obrigatorio;

  return (
    <div id="campo-ciaps" className="p-mt-4">
      <div className="p-d-flex p-grid p-jc-between p-ai-end p-mt-2">
        <div className="p-mb-1 p-col">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            CIAP
          </SimpleText>
          {isRequired && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div className="header-buttons p-col">
          <Button
            className="busca-avancada p-as-center"
            icon="pi pi-plus"
            onClick={() => buscaModalDisclosure.open()}
            label="Busca avançada"
          />

          <ToggleButton
            checked={checked}
            className={`p-px-4 p-py-1 pin ${isRequired ? 'pin-disabled' : ''}`}
            onLabel={''}
            offLabel={''}
            onChange={e =>
              !isRequired ? [handleFixar(), setChecked(e.value)] : null
            }
            onIcon="fa fa-thumbtack"
            offIcon="fa fa-thumbtack"
          />
        </div>
      </div>
      <div className="p-grid p-ai-baseline">
        <div className="favoritos p-col-4 p-pr-0 p-mb-1">
          <Button
            btnType="ghost"
            label="Meus favoritos"
            iconPos="right"
            icon="pi pi-chevron-down"
            className="favoritos-btn"
            onClick={e => favoritosRef.current?.toggle(e)}
          />

          <OverlayPanel ref={favoritosRef}>
            <ModalFavoritos
              capitalizeFirstLetter={capitalizeFirstLetter}
              idAtendimento={idAtendimento}
              getCiaps={getCiaps}
              {...modalDisclosure}
            />
          </OverlayPanel>
        </div>
        <div className="busca p-col-8 p-grid p-pl-0">
          <DropdownCiap
            control={control}
            getCiaps={getCiaps}
            idAtendimento={idAtendimento}
          />
        </div>
        <div className="p-col-12 lista-ciaps">{listaCiaps()}</div>
      </div>
      <ModalBuscaAvancada
        {...buscaModalDisclosure}
        listaComorbidades={listaProblemas}
        getCiaps={getCiaps}
        idAtendimento={idAtendimento}
        getComorbidades={getListaProblemas}
        adicionarComorbidade={adicionarComorbidade}
        removerComorbidade={removerComorbidade}
      />
    </div>
  );
}

export default Ciap;
