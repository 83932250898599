import { Component } from 'react';

import './ErrorBoundary.scss';

interface ErrorBoundaryProps {
  children?: JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    if (process.env.REACT_APP_ENV === 'dev') {
      console.error(error);
      console.warn(info);
    }

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="error-boundary-container">
          <h1>Ops, algo deu errado</h1>
          <p>Houve um problema inesperado.</p>
        </div>
      );
    }
    return this.props.children;
  }
}
