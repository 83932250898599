import Http, { HttpParams } from 'src/core/http/Http';

export const getQuestionarioById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `aga/formularios/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getQuestionarioByMnemonico = (
  mnemonico: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `aga/formularios/mnemonico/${mnemonico}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getListaFormularios = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `aga/formularios/listar`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getVelociadeDeMarcha = (
  idPaciente: number | null,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `aga/atendimento-formularios/respostas/paciente/${idPaciente}`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const postFormulario = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `aga/atendimento-formularios`,
    data: payload,
    throwError: true,
    ...options,
  });
};

export const putFormulario = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `aga/atendimento-formularios/${payload.idFormularioEdit}`,
    data: payload,
    throwError: true,
    ...options,
  });
};

const IdosoBemCuidadoAPI = {
  getQuestionarioById,
  getQuestionarioByMnemonico,
  getListaFormularios,
  getVelociadeDeMarcha,
  postFormulario,
  putFormulario,
};

export default IdosoBemCuidadoAPI;
