import { useFormContext } from 'react-hook-form';

import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

export const BasicData = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <TextInputControlled
        className="p-col-12"
        control={control}
        name="razaoSocial"
        label="Razão social"
        errorMsg={errors.razaoSocial?.message}
        maxLength={255}
      />

      <MaskedInputControlled
        className="p-col-12"
        control={control}
        name="cnpj"
        label="CNPJ"
        mask={MASK.CNPJ}
        errorMsg={errors.cnpj?.message}
      />

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="registroAns"
        label="Registro da Operadora na ANS"
        permitirApenasNumeros
        errorMsg={errors.registroAns?.message}
        maxLength={50}
      />

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="site"
        label="Site"
        placeholder="Opcional"
        errorMsg={errors.site?.message}
        maxLength={255}
      />

      <TextInputControlled
        className="p-col-12"
        control={control}
        name="emailInstitucional"
        label="E-mail institucional"
        errorMsg={errors.emailInstitucional?.message}
        maxLength={255}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="telefoneContato1"
        label="Telefone"
        placeholder="Opcional"
        errorMsg={errors.telefoneContato1?.message}
        maxLength={50}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="telefoneContato2"
        label="Telefone"
        placeholder="Opcional"
        errorMsg={errors.telefoneContato2?.message}
        maxLength={50}
      />
    </>
  );
};
