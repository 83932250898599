import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { clearProfissionais } from 'src/core/redux/slices/agenda/AgendaSlice';
import { clearUser, setUser } from 'src/core/redux/slices/system/UserSlice';
import { clearConsultorios } from 'src/core/redux/slices/user/ConsultoriosSlice';
import { RootState } from 'src/core/redux/store';

import { useValidateAuth } from 'src/pages/Portal/Auth/utils';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

export const AuthEnforcer = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pathArray = location.pathname.split('/');
  const hashEmpresa = pathArray[2];

  const handleAuth = useCallback(() => {
    if (!user.idPaciente)
      PortalPacienteAPI.getPacienteUser({
        throwError: true,
        errorHideToast: true,
      })
        .then(pac => {
          dispatch(setUser({ ...pac, pacienteDados: pac, idPaciente: pac.id }));
        })
        .catch(() => dispatch(clearUser()));
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(clearProfissionais());
    dispatch(clearConsultorios());
  }, [dispatch]);

  useEffect(() => {
    if (!user?.idUsuario)
      navigate(`/portal/${hashEmpresa}/login`, { state: { from: location } });
    else handleAuth();
  }, [
    dispatch,
    handleAuth,
    hashEmpresa,
    location,
    navigate,
    user,
    user?.idUsuario,
  ]);

  return <Outlet />;
};

export const ValidateAuth = () => {
  const { isLoggingIn } = useValidateAuth();

  return isLoggingIn ? <SpinnerLoading full /> : <Outlet />;
};

export const Redirect = () => {
  const location = useLocation();

  const pathArray = location.pathname.split('/');
  const hashEmpresa = pathArray[2];

  return <Navigate to={`/portal/${hashEmpresa}/login`} />;
};
