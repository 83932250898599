import Http, { HttpParams } from 'src/core/http/Http';

export const getHistoricoByFormId = (
  idPaciente: number,
  formId: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `aga/atendimento-formularios/historico/paciente/${idPaciente}/formulario/${formId}`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const inativarHistoricoById = (
  historico: any,
  justificativaInativacao: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const { id, idAtendimento } = historico;
  return Http.put({
    service: 'pep',
    url: `aga/atendimento-formularios/atendimento/${idAtendimento}/inativar/${id}`,
    data: justificativaInativacao,
    throwError: true,
    ...options,
  });
};

export const deleteHistoricoById = (
  historico: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const { id, idAtendimento } = historico;
  return Http.delete({
    service: 'pep',
    url: `aga/atendimento-formularios/atendimento/${idAtendimento}/deletar/${id}`,
    throwError: true,
    ...options,
  });
};

const HistoricoAGAAPI = {
  getHistoricoByFormId,
  deleteHistoricoById,
  inativarHistoricoById,
};

export default HistoricoAGAAPI;
