import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/folhas-rostos';

type IUpdateFolhaRosto = {
  id: number;
  idMedicoCuidador?: number | null;
  idMeuMedico?: number | null;
  idEnfermeiroCuidador?: number | null;
  idMedicoCuidadorViverBem?: number | null;
  idPaciente: number;
  idConsultorio: number;
  idComplexidadePaciente?: number | null;
};

export const loadFolhasRostosPaciente = async (
  idPaciente: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/consultorio/${idConsultorio}/paciente/${idPaciente}`,
    hideToast: true,
    ...options,
  });
};

export const updateFolhasRostosPaciente = (
  payload: IUpdateFolhaRosto,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basepath}`,
    data: payload,
    hideToast: true,
    return: true,
    ...options,
  });

export const postFolhaRostoCuidador = (
  idConsultorio: number,
  idPaciente: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basepath}/consultorio/${idConsultorio}/paciente/${idPaciente}/cuidador`,
    data: payload,
    hideToast: true,
    return: true,
    ...options,
  });

export const postFolhaRostoTransferirCuidador = (
  idConsultorio: number,
  idPaciente: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basepath}/consultorio/${idConsultorio}/paciente/${idPaciente}/transferencia`,
    data: payload,
    return: true,
    ...options,
  });

export const loadFolhasRostosEquipeCuidadora = async (
  params: {
    idConsultorio: number | undefined;
    idPaciente: number | string;
    profissional: string | undefined;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/consultorio/${params.idConsultorio}/paciente/${params.idPaciente}/equipe`,
    params,
    hideToast: true,
    ...options,
  });
};

export const FolhaRostoAPI = {
  loadFolhasRostosPaciente,
  updateFolhasRostosPaciente,
  postFolhaRostoCuidador,
  postFolhaRostoTransferirCuidador,
  loadFolhasRostosEquipeCuidadora,
};
