/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';

import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { FieldMateriaisFavoritos } from '../components/FieldMateriaisFavoritos/FieldMateriaisFavoritos';
import { FieldSearchMateriais } from '../components/FieldSearchMateriais/FieldSearchMateriais';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import FormInput from 'src/components/FormInput/FormInput';

import './MateriaisEdit.scss';

type IPrescricaoEditProps = DisclosureType & {
  prescricaoId: string | number;
  item?: any;
  refetchList: () => void;
};

export const MateriaisEdit = ({ ...props }: IPrescricaoEditProps) => {
  return (
    <Dialog
      className="prescricao-create-dialog"
      visible={props.isOpen}
      onHide={props.close}
      header="Prescrição"
      maximizedMobileSize
    >
      <ErrorBoundary>
        <MateriaisEditComp {...props} />
      </ErrorBoundary>
    </Dialog>
  );
};

const MateriaisEditComp = ({
  close,
  item: prescricao,
  refetchList,
  prescricaoId,
}: IPrescricaoEditProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    prescricaoMateriais: Yup.array().of(
      Yup.object({
        quantidade: Yup.number()
          .positive('O valor deve ser positivo')
          .typeError('O valor deve ser numérico'),
      }),
    ),
  });

  const formList = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: undefined,
      prescricaoMateriais: prescricao?.prescricaoMateriais || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'prescricaoMateriais',
    control: formList.control,
  });

  const handleAdd = (items: any[]) => {
    const data = items.map((item: any) => ({
      idMaterial: item.id,
      nome: item.nomeComercial,
      quantidade: item?.quantidade || 1,
    }));

    append(data);
  };

  const handleRemove = (idx: number) => {
    remove(idx);
  };

  const watchDescricao = formList.watch('nome');

  const handleFavoritar = useCallback(
    async (values: any) => {
      const materiaisLength = values.prescricaoMateriais?.length > 1;

      if (materiaisLength && !watchDescricao) {
        formList.setError('nome', { message: 'O titulo é obrigatório' });
        return;
      }

      const payload: any = {
        ...values,
        idAtendimento: Number(idAtendimento),
        descricao: materiaisLength ? values.nome : undefined,
      };

      return PrescricaoAPI.createPrescricaoFavoritos(
        profissionalAtivo.id,
        payload,
      ).then(() =>
        //Invalida query de prescrições favoritas
        dispatch(setInvalidateQuery({ invalidatePrescricaoFavotiros: true })),
      );
    },
    [dispatch, formList, idAtendimento, profissionalAtivo.id, watchDescricao],
  );

  const onSubmit = useCallback(
    async (values: any, type = 'submit') => {
      if (type === 'favorite') {
        return handleFavoritar(values);
      }

      const isEditing = !!prescricao;
      const payload = isEditing ? { ...prescricao, ...values } : { ...values };

      try {
        const action = isEditing
          ? PrescricaoAPI.updatePrescricaoMateriais(prescricaoId, payload, {
              throwError: true,
            })
          : PrescricaoAPI.createPrescricaoMateriais(prescricaoId, payload, {
              throwError: true,
            });
        await action;

        return [close(), refetchList()];
      } catch {
        return;
      }
    },
    [close, handleFavoritar, prescricao, prescricaoId, refetchList],
  );

  return (
    <div id="prescricao-create" className="prescricao-create-container">
      <div className="p-grid p-w-100 p-ai-center">
        <div className="p-col-12 p-sm-10">
          <FieldSearchMateriais onAdd={handleAdd} />
        </div>
        <div className="p-col-12 p-sm-2">
          <FieldMateriaisFavoritos onAdd={handleAdd} />
        </div>
      </div>

      <FormProvider {...formList}>
        <form onSubmit={formList.handleSubmit(onSubmit)}>
          <div className="prescricao-content">
            {!!fields.length ? (
              fields.map((item, idx) => (
                <PrescricaoContentRow
                  key={idx}
                  item={item}
                  idx={idx}
                  handleRemove={() => handleRemove(idx)}
                />
              ))
            ) : (
              <div className="p-d-flex p-flex-1 p-ai-center p-jc-center">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                  Nenhum. Adicione um material utilizando a barra de pesquisa.
                </SimpleText>
              </div>
            )}
          </div>

          <div className="prescricao-footer">
            <div className="p-col-12 p-sm-9">
              {formList.getValues().prescricaoMateriais?.length >= 2 && (
                <FormInput name="nome" maxLength={40} label="Título" />
              )}
            </div>

            <div className="p-col-12 p-d-flex p-ai-center p-sm-3">
              <Button
                label="Adicionar aos Favoritos"
                type="button"
                onClick={formList.handleSubmit(v => onSubmit(v, 'favorite'))}
                disabled={!formList.getValues().prescricaoMateriais.length}
                loading={formList.formState.isSubmitting}
                btnType="tonal"
                stretch
              />
            </div>
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              type="button"
              onClick={() => close()}
              btnType="ghost"
              disabled={formList.formState.isSubmitting}
              stretch
            />
            <Button
              type="submit"
              label="Salvar"
              disabled={!formList.getValues().prescricaoMateriais.length}
              loading={formList.formState.isSubmitting}
              stretch
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const PrescricaoContentRow = ({ item, idx, handleRemove }: any) => {
  const isEditing = useDisclosure({ opened: false });

  const form = useFormContext();

  const fieldQtdeError = form.getFieldState(
    `prescricaoMateriais.${idx}.quantidade`,
  ).error?.message;

  const watchQtde = form.watch(`prescricaoMateriais.${idx}.quantidade`);

  return (
    <div className="row p-ai-center">
      <SimpleText className="p-col-7 p-text-climp-2">{item?.nome}</SimpleText>

      {isEditing.isOpen ? (
        <div className="p-col-3 p-d-flex p-flex-column p-ai-center p-gap-1">
          <FormInput
            autoFocus
            className="light"
            name={`prescricaoMateriais.${idx}.quantidade`}
            type="number"
            hideTextErrorSpace
            max={99}
            classNameInput="p-w-100"
          />
        </div>
      ) : (
        <div className="p-col-2 p-d-flex p-jc-center">
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText
              fontColor={
                !!fieldQtdeError ? FONT_COLOR.DANGER : FONT_COLOR.COLOR_60
              }
            >
              Qtde.
            </SimpleText>
            <SimpleText
              fontColor={
                !!fieldQtdeError ? FONT_COLOR.DANGER : FONT_COLOR.PRIMARY
              }
            >
              {watchQtde || 0}
            </SimpleText>
          </div>
        </div>
      )}

      {isEditing.isOpen ? (
        <div className="p-d-flex p-col p-jc-end p-p-0 p-gap-1">
          <Button
            className="slide-left"
            icon="fa fa-check"
            type="button"
            btnType="gray"
            onClick={() => isEditing.close()}
          />
          <Button
            icon="fa fa-close"
            type="button"
            btnType="gray"
            onClick={() => [
              isEditing.close(),
              form.setValue(`prescricaoMateriais.${idx}.quantidade`, 0),
            ]}
          />
        </div>
      ) : (
        <div className="p-d-flex p-col p-jc-end p-p-0 p-gap-1">
          <Button
            icon="fa fa-edit"
            type="button"
            btnType="gray"
            onClick={e => [isEditing.open(), e.preventDefault()]}
          />
          <Button
            icon="fa fa-trash"
            type="button"
            onClick={() => handleRemove()}
            btnType="gray"
          />
        </div>
      )}
    </div>
  );
};
