import * as Yup from 'yup';

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list: string[]) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Ao menos um campo preenchido é obrigatório',
    test: function (value) {
      const anyHasValue = value == null || list.some(field => !!value[field]);

      const { createError } = this;

      return !anyHasValue
        ? createError({
            path: 'atLeastOneOf',
            message: 'Ao menos um campo preenchido é obrigatório',
          })
        : true;
    },
  });
});

declare module 'yup' {
  interface ObjectSchema<TShape, TContext, TIn, TOut> {
    atLeastOneOf(list?: string[]): this;
  }
}

const reagentesFormFields = {
  'HI e HB': [
    'hbPrimeiroTrimestre',
    'hbTerceiroTrimestre',
    'hiPrimeiroTrimestre',
    'hiTerceiroTrimestre',
  ],
  'Leucócitos e plaquetas': [
    'leucocitosPrimeiroTrimestre',
    'leucocitosTerceiroTrimestre',
    'plaquetasPrimeiroTrimestre',
    'plaquetasTerceiroTrimestre',
  ],
  'Glicemia e TTGO': [
    'glicemiaPrimeiroTrimestre',
    'glicemiaTerceiroTrimestre',
    'ttgoPrimeiroTrimestre',
    'ttgoTerceiroTrimestre',
  ],
  'TSH e T4': [
    'tshPrimeiroTrimestre',
    'tshTerceiroTrimestre',
    't4PrimeiroTrimestre',
    't4TerceiroTrimestre',
  ],
  Urocult: [
    'urocultPrimeiroTrimestre',
    'urocultTerceiroTrimestre',
    'urinaUrocultLeococitosPrimeiroTrimestre',
    'hemaciasUrocultPrimeiroTrimestre',
  ],
  PFF: [
    'pffPrimeiroTrimestre',
    'pffTerceiroTrimestre',
    'urinaPffLeococitosTerceiroTrimestre',
    'hemaciasPffTerceiroTrimestre',
  ],
  Doenças: [
    'hivPrimeiroTrimestre',
    'hivSegundoTrimestre',
    'rubeolaPrimeiroTrimestre',
    'rubeolaSegundoTrimestre',
    'vdrlPrimeiroTrimestre',
    'vdrlSegundoTrimestre',
    'hepatitebPrimeiroTrimestre',
    'hepatitebSegundoTrimestre',
    'hepatitecPrimeiroTrimestre',
    'hepatitecSegundoTrimestre',
    'toxoplasmosePrimeiroTrimestre',
    'toxoplasmoseSegundoTrimestre',
  ],
};

const validationSchema = (formName: string) => {
  const formFields =
    reagentesFormFields[formName as keyof typeof reagentesFormFields];

  if (!formFields) return Yup.object();

  const shape = formFields.reduce(
    (acc: any, field: string) => ({ ...acc, [field]: Yup.string() }),
    {},
  );

  return Yup.object().shape(shape).atLeastOneOf(formFields);
};

export default validationSchema;
