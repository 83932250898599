import { useFormContext, useFieldArray } from 'react-hook-form';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import { ModalEspecialidade } from './ModalEspecialidade';
import { ModalMedico } from './ModalMedico';

import './Disponibilidade.scss';

export const Disponibilidade = () => {
  const { control } = useFormContext();

  const {
    fields: especialidades,
    append: appendEspecialidades,
    remove: removeEspecialidades,
  } = useFieldArray({
    control,
    name: 'especialidades',
  });

  const {
    fields: medicos,
    append: appendMedicos,
    remove: removeMedicos,
  } = useFieldArray({
    control,
    name: 'profissionaisSaude',
  });

  const modalEspecialidade = useDisclosure({ opened: false });
  const modalMedico = useDisclosure({ opened: false });

  const handleOpenModalEspecialidade = () =>
    modalEspecialidade.open({ state: especialidades });
  const handleOpenModalMedico = () => modalMedico.open({ state: medicos });

  const handleAddEspecialidades = (especialidades: any[]) =>
    appendEspecialidades(especialidades);

  const handleAddMedicos = (medicos: any[]) => appendMedicos(medicos);

  const renderEspecialidades = () => {
    if (!especialidades?.length)
      return (
        <div className="empty-disponibilidade p-col-12">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Não ha cadastros.
          </SimpleText>
        </div>
      );

    return (
      <div className="disponibilidade-content p-col-12">
        {especialidades.map((especialidade: any, index: number) => (
          <div key={index}>
            <SimpleText fontColor={FONT_COLOR.COLOR_16}>
              {especialidade.nome}
            </SimpleText>

            <Button
              btnType="gray"
              icon="fas fa-trash"
              onClick={() => removeEspecialidades(index)}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderMedicos = () => {
    if (!medicos?.length)
      return (
        <div className="empty-disponibilidade p-col-12">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Não ha cadastros.
          </SimpleText>
        </div>
      );

    return (
      <div className="disponibilidade-content p-col-12">
        {medicos.map((medico: any, index: number) => (
          <div key={index} className="p-d-flex p-flex-column p-flex-sm-row">
            <div className="p-d-flex p-flex-column p-flex-sm-row p-ai-center p-text-center p-text-sm-left">
              <Avatar className="p-mx-3" label={medico.nome} />

              <SimpleText
                className="p-mt-2 p-mb-3 p-mt-sm-0 p-mb-sm-0"
                fontColor={FONT_COLOR.COLOR_16}
              >
                {medico.nome}
              </SimpleText>
            </div>

            <div className="p-d-flex p-ai-center">
              <div className="p-d-flex p-flex-column p-text-right">
                <SimpleText fontColor={FONT_COLOR.COLOR_40}>Médico</SimpleText>
                <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                  {medico.siglaConselhoTiss}
                  {medico.numeroRegistro}
                </SimpleText>
              </div>

              <Button
                btnType="gray"
                icon="fas fa-trash"
                onClick={() => removeMedicos(index)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-lg-6">
          <div className="disponibilidade-border-container p-col-12 p-grid">
            <SimpleText
              className="p-col-12"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Especialidades
            </SimpleText>
            <SimpleText
              className="p-col-12"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Disponibilizar pacote para as seguintes especialidades:
            </SimpleText>

            {renderEspecialidades()}

            <div className="p-col-12">
              <Button
                btnType="tonal"
                label="Adicionar especialidades"
                icon="fas fa-plus"
                stretch
                onClick={handleOpenModalEspecialidade}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-lg-6">
          <div className="disponibilidade-border-container p-col-12 p-grid">
            <SimpleText
              className="p-col-12"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Profissionais de Saúde
            </SimpleText>
            <SimpleText
              className="p-col-12"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Disponibilizar pacote para as seguintes profissionais de saúde:
            </SimpleText>

            {renderMedicos()}

            <div className="p-col-12 p-px-0">
              <Button
                btnType="tonal"
                label="Adicionar médico"
                icon="fas fa-plus"
                stretch
                onClick={handleOpenModalMedico}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalEspecialidade
        {...modalEspecialidade}
        onAdd={handleAddEspecialidades}
      />
      <ModalMedico {...modalMedico} onAdd={handleAddMedicos} />
    </>
  );
};
