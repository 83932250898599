import React from 'react';

import dayjs from 'dayjs';

import { corRiscoCardiaco } from 'src/utils/escoreFraminghan';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';
import PorcentagemRisco from 'src/pages/Emed/Prontuario/FichaPaciente/AvaliacaoRisco/components/PorcentagemRisco/PorcentagemRisco';

import { checkIsRetroativo } from 'src/pages/Emed/Prontuario/FichaPaciente/helpers';

interface PanelHeaderProps {
  avaliacao: any;
  isCollapsed: boolean;
  optionsMenu: JSX.Element;
  onToggle: () => void;
}

export default function PanelHeader({
  avaliacao,
  isCollapsed,
  optionsMenu,
  onToggle,
}: PanelHeaderProps) {
  const headerTextClassName =
    avaliacao.status === 'INATIVO' ? 'avaliacao-inativa' : '';

  const dataTextClassName = `p-mt-1 ${
    avaliacao.status === 'INATIVO' ? 'line-through avaliacao-inativa' : ''
  }`;

  const { porcentagemRisco, idSexo } = avaliacao.porcentagemAvalRisco || {};

  const riscoCardiacoColor =
    avaliacao.status === 'ATIVO'
      ? corRiscoCardiaco(porcentagemRisco, idSexo)
      : 'disabled';

  const isRetroativo = checkIsRetroativo(
    avaliacao.dataOcorrencia,
    avaliacao.dataInclusao,
  );

  const isInativo = avaliacao.status === 'INATIVO';

  return (
    <div className="panel-header-avaliacao p-grid p-col-12 p-align-center">
      <div className="coluna-dados p-col-4 p-md-2 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(avaliacao.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="coluna-nome p-col-6 p-md-4 p-xl-5 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Nome do profissional de saúde
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {avaliacao.usuarioAtualizacao?.nome}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-md-2 p-xl-2 p-d-flex">
        <PorcentagemRisco
          size="small"
          porcentagem={avaliacao.porcentagemAvalRisco?.porcentagemRisco}
          className={riscoCardiacoColor}
        />
        <div className="p-col">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            Risco Cardíaco
          </SimpleText>
        </div>
      </div>
      <div
        className={`coluna-dados p-col-4 p-md-2 p-xl-1 retroativo-chip ${headerTextClassName}`}
      >
        {isRetroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="coluna-acoes p-col-6 p-md-2 p-d-flex p-jc-between p-ai-center">
        {!isInativo && <Lembrete ativo={avaliacao.lembrete} />}

        {optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
}
