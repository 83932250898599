import { useCallback } from 'react';

import { toast } from 'react-toastify';

import { BloqueioPacienteAPI } from 'src/APIs/AdminAPI/BloqueioPacienteAPI/BloqueioPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalBloqueio.scss';

interface ModalBloqueioProps {
  visible: boolean;
  onHide(): void;
  paciente: any;
  listaPacientesBloqueados?: any;
  setListaPacientesBloqueados?: any;
}

const ModalBloqueio = ({
  paciente,
  visible,
  onHide,
  listaPacientesBloqueados,
  setListaPacientesBloqueados,
}: ModalBloqueioProps) => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const handleCancelSigilo = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleBloqueio = useCallback(async () => {
    await BloqueioPacienteAPI.sendBloqueioPaciente({
      tipo: 'AGENDAMENTO ONLINE',
      excluido: !!paciente.excluido,
      pacienteId: paciente.id,
      usuarioId: agenda.profissionalAtivo.id,
    })
      .then((res: any) => {
        if (res.id) {
          const pacienteBloqueado = {
            ...res,
            paciente: paciente,
          };
          setListaPacientesBloqueados([
            ...listaPacientesBloqueados,
            pacienteBloqueado,
          ]);
          const data = {
            title: 'Paciente bloqueado',
            message: 'Paciente não pode mais agendar consultas online',
            type: 'warning',
          };
          toast(<Toast />, { data });
          onHide();
        }

        if (res.status === 422) {
          const data = {
            title: res.data.errorCode,
            message: res.data.errorMessage,
            type: 'error',
          };
          toast(<Toast />, { data });
        }
      })
      .catch(error => {
        console.log({ error });
        const data = {
          title: 'Erro',
          message: 'Algo deu errado, tente novamente mais tarde',
          type: 'error',
        };
        toast(<Toast />, { data });
      });
  }, [
    paciente,
    agenda,
    setListaPacientesBloqueados,
    listaPacientesBloqueados,
    onHide,
  ]);

  const handleDesloqueio = useCallback(async () => {
    await BloqueioPacienteAPI.removeBloqueioPacienteById(paciente.id)
      .then((res: any) => {
        if (res === '') {
          const pacientesBloqueados = listaPacientesBloqueados?.filter(
            (p: any) => p.id !== paciente.id,
          );
          setListaPacientesBloqueados(pacientesBloqueados);

          const data = {
            title: 'Paciente desbloqueado',
            message: 'Paciente pode voltar a agendar consultas online',
            type: 'success',
          };
          toast(<Toast />, { data });
          onHide();
        }

        if (res.status === 422) {
          const data = {
            title: res.data.errorCode,
            message: res.data.errorMessage,
            type: 'error',
          };
          toast(<Toast />, { data });
        }
      })
      .catch(error => {
        console.log({ error });

        const data = {
          title: 'Erro',
          message: 'Algo deu errado, tente novamente mais tarde',
          type: 'error',
        };
        toast(<Toast />, { data });
      });
  }, [paciente, listaPacientesBloqueados, setListaPacientesBloqueados, onHide]);

  return (
    <Dialog
      type="modal"
      header={
        paciente.excluido === null
          ? 'Confirma o bloqueio do paciente'
          : 'Confirma o desbloqueio do paciente'
      }
      visible={visible}
      onHide={handleCancelSigilo}
      style={{ width: 460 }}
      className="footer-on"
      footer={() => <div />}
    >
      <div className="modal-body-bloqueio-pacientes">
        <SimpleText fontColor={FONT_COLOR.COLOR_40} className="p-pl-2">
          {paciente.excluido === null
            ? 'Você tem certeza que deseja bloquear o agendamento de consultas do paciente selecionado?'
            : 'Você tem certeza que deseja desbloquear o paciente e permitir o agendamento de consultas online?'}
        </SimpleText>

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Cancelar"
            type="button"
            onClick={handleCancelSigilo}
            stretch
          />
          <Button
            label={
              paciente.excluido === null ? 'Sim, bloquear' : 'Sim, desbloquear'
            }
            onClick={
              paciente.excluido === null ? handleBloqueio : handleDesloqueio
            }
            btnType={paciente.excluido === null ? 'danger' : 'default'}
            type="submit"
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ModalBloqueio;
