/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useEffect, useState, useCallback } from 'react';

import { toast } from 'react-toastify';

import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';

import useSize from 'src/core/hooks/useSize';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Switch from 'src/components/Switch/Switch';

import './ModalVisualizarEmpresa.scss';

interface ModalVisualizarEmpresaProps {
  visible: boolean;
  onHide(): void;
  empresaSelecionada: Partial<Empresa>;
  onEdit: any;
  reloadList: () => void;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo(({ onCancel, onAdd }: FooterProps) => {
  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button label={'Editar cliente'} onClick={onAdd} stretch />
    </div>
  );
});

const ModalVisualizarEmpresa = ({
  empresaSelecionada,
  visible,
  onHide,
  onEdit,
  reloadList,
}: ModalVisualizarEmpresaProps) => {
  const [empresa, setEmpresa] = useState<Partial<Empresa>>({});
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const { onCopy, hasCopied } = useCopyClipboard();
  const { isTablet } = useSize();

  useEffectSkipFirst(() => {
    if (hasCopied)
      toast(<Toast />, {
        data: {
          title: 'Sucesso!',
          message: 'O link foi copiado com sucesso',
          type: 'success',
        },
      });
  }, [hasCopied]);

  useEffect(() => {
    async function loadEmpresaByEmpresaId() {
      const response: any = await EmpresasAPI.loadEmpresaById(
        empresaSelecionada.id!,
      );

      if (response.status === 200) {
        setEmpresa(response.data);
        setChecked(response.data?.status);
      }
      setLoading(false);
    }

    if (empresaSelecionada?.id) {
      setLoading(true);
      loadEmpresaByEmpresaId();
    }
  }, [empresaSelecionada]);

  const handleIsActive = useCallback(
    async (item: any) => {
      let response: any = '';

      if (!checked) {
        response = await EmpresasAPI.updateEmpresaAtivarStatus(item?.id);
      } else {
        response = await EmpresasAPI.updateEmpresaInativarStatus(item?.id);
      }

      if (response.status === 200) {
        const empresaEditada = {
          ...item,
          status: response?.data?.status,
        };

        setChecked(response?.data?.status);
        reloadList();
      }
    },
    [checked, reloadList],
  );

  const urlPortal = empresa?.baseUrlPortal;
  const codigoEncriptado = empresa?.codigoEncriptado;
  const linkPortalPaciente = `${urlPortal}/${codigoEncriptado}/login`;

  return (
    <Dialog
      className="footer-on modal-content-body"
      type="modal"
      header="Visualizar cliente"
      visible={visible}
      onHide={onHide}
      footer={() => (
        <Footer onAdd={() => onEdit(empresaSelecionada)} onCancel={onHide} />
      )}
    >
      {loading ? (
        <GridListLoading />
      ) : (
        <>
          {empresa && Object.keys(empresa).length > 0 ? (
            <div className="modal-body-visualizar-empresa">
              <div className="modal-empresa-header">
                <div className="modal-empresa-header-left">
                  <div className="modal-empresa-header-left-top">
                    <SimpleText
                      fontSize={FONT_SIZE.MD}
                      fontColor={FONT_COLOR.COLOR_40}
                      medium
                    >
                      {empresa?.codigo}
                    </SimpleText>
                  </div>
                  <div className="modal-empresa-header-left-bottom">
                    <SimpleText
                      className="p-mb-1"
                      fontSize={FONT_SIZE.MD}
                      fontColor={FONT_COLOR.COLOR_40}
                      medium
                    >
                      {empresa?.nome}
                    </SimpleText>
                    <SimpleText
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_60}
                      medium
                    >
                      {empresa?.nome} - {empresa?.razaoSocial} -{empresa?.cnpj}
                    </SimpleText>
                  </div>
                </div>
                <div className="modal-empresa-header-right  ">
                  <div className="modal-empresa-header-right-infos">
                    <SimpleText
                      className="p-mb-2"
                      fontSize={FONT_SIZE.XS}
                      fontColor={FONT_COLOR.COLOR_16}
                      medium
                    >
                      Status
                    </SimpleText>
                    <SimpleText
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      Caso seja desativado, o cliente perderá o acesso à
                      plataforma.
                    </SimpleText>
                  </div>
                  <Switch
                    checked={checked}
                    onChange={() => handleIsActive(empresa)}
                  />
                </div>
              </div>
              <div className="modal-inputs">
                <div className="modal-inputs-item">
                  <TextInput
                    disabled
                    value={empresa?.telefone1! || ''}
                    placeholder="41 99999-8888"
                  />
                  <span className="modal-inputs-item-text">
                    <i className="pi pi-whatsapp"></i>
                    Enviar
                  </span>
                </div>
                <div className="modal-inputs-item">
                  <TextInput
                    disabled
                    value={empresa?.email!}
                    placeholder="email@institucional"
                  />
                  <span className="modal-inputs-item-text">
                    <i className="pi pi-envelope"></i>
                    Enviar
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-d-flex">
                <div className="p-col-6">
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                  >
                    Dados cadastrais
                  </SimpleText>

                  <div className="modal-body-empresa-info">
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <SimpleText
                        className="p-mr-2"
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_60}
                      >
                        Site
                      </SimpleText>
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.PRIMARY}
                        medium
                      >
                        {empresa?.site!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <SimpleText
                        className="p-mr-2"
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_60}
                      >
                        Código ANS
                      </SimpleText>
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.codigo!}
                      </SimpleText>
                    </div>
                    <div className="p-d-flex p-flex-column">
                      <div className="p-d-flex p-mb-2">
                        <SimpleText
                          className="p-mr-2"
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_60}
                        >
                          Telefone
                        </SimpleText>
                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.PRIMARY}
                          medium
                        >
                          {empresa?.telefone1!}
                        </SimpleText>
                      </div>
                      <div className="p-d-flex p-mb-2">
                        <SimpleText
                          className="p-mr-2"
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_60}
                        >
                          Telefone
                        </SimpleText>
                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.PRIMARY}
                          medium
                        >
                          {empresa?.telefone2!}
                        </SimpleText>
                      </div>
                    </div>
                    <div className="modal-body-empresa-info-line">
                      <SimpleText
                        className="p-mr-2"
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_60}
                      >
                        E-mail
                      </SimpleText>
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.PRIMARY}
                        medium
                      >
                        {empresa?.email!}
                      </SimpleText>
                    </div>
                    <Divider />
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <SimpleText
                        className="p-mr-2"
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_60}
                      >
                        CEP
                      </SimpleText>
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.endereco?.cep!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <SimpleText
                        className="p-mr-2"
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_60}
                      >
                        Endereço
                      </SimpleText>
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.endereco?.logradouro!},{' '}
                        {empresa?.endereco?.numero!} -{' '}
                        {empresa?.endereco?.complemento!} •
                        {empresa?.endereco?.bairro} •{' '}
                        {empresa?.endereco?.cidade?.nome!}/
                        {empresa?.endereco?.cidade?.estado?.nome!}
                      </SimpleText>
                    </div>
                  </div>

                  {codigoEncriptado && (
                    <>
                      <div className="p-mt-3">
                        <SimpleText medium>Acessos</SimpleText>
                      </div>
                      <div className="modal-body-empresa-info p-d-flex p-flex-column p-gap-2">
                        <div className="p-d-flex p-flex-column p-flex-md-row  p-gap-1 p-col-12 p-p-0 p-ai-center">
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.COLOR_60}
                            className="p-wordbreak-normal"
                            style={{
                              minWidth: '110px',
                            }}
                          >
                            Portal do paciente:{' '}
                          </SimpleText>
                          <SimpleText
                            fontSize={FONT_SIZE.XS}
                            fontColor={FONT_COLOR.PRIMARY}
                            className="p-text-truncate"
                            medium
                          >
                            <a
                              href="google.com"
                              className="link-portal-paciente"
                            >
                              {isTablet
                                ? 'Link Portal Paciente'
                                : linkPortalPaciente}
                            </a>
                          </SimpleText>

                          <Button
                            icon="fas fa-copy"
                            btnType="ghost"
                            tooltip="Copiar link"
                            onClick={() => onCopy(linkPortalPaciente)}
                            style={{
                              minWidth: '30px',
                            }}
                            tooltipOptions={{
                              position: 'top',
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="p-col-6 p-d-flex p-flex-column">
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                  >
                    Responsável Geral
                  </SimpleText>

                  <div className="modal-body-empresa-info">
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-user-alt" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.responsavelGeral?.nome!},{' '}
                        {empresa?.responsavelGeral?.cargo!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-phone-alt" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.responsavelGeral?.telefone!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-envelope" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.PRIMARY}
                        medium
                      >
                        {empresa?.responsavelGeral?.email!}
                      </SimpleText>
                    </div>
                  </div>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                  >
                    Responsável financeiro
                  </SimpleText>

                  <div className="modal-body-empresa-info">
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-user-alt" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.responsavelFinanceiro?.nome!},{' '}
                        {empresa?.responsavelFinanceiro?.cargo!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-phone-alt" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.responsavelFinanceiro?.telefone!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-envelope" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.PRIMARY}
                        medium
                      >
                        {empresa?.responsavelFinanceiro?.email!}
                      </SimpleText>
                    </div>
                  </div>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                  >
                    Responsável técnico
                  </SimpleText>

                  <div className="modal-body-empresa-info">
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-user-alt" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.responsavelTecnico?.nome!},{' '}
                        {empresa?.responsavelTecnico?.cargo!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-phone-alt" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.COLOR_16}
                        medium
                      >
                        {empresa?.responsavelTecnico?.telefone!}
                      </SimpleText>
                    </div>
                    <div className="modal-body-empresa-info-line p-mb-2">
                      <i className="fa fa-envelope" />
                      <SimpleText
                        fontSize={FONT_SIZE.XS}
                        fontColor={FONT_COLOR.PRIMARY}
                        medium
                      >
                        {empresa?.responsavelTecnico?.email!}
                      </SimpleText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-d-flex p-jc-center p-ai-center">
              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                Empresa não encontrada
              </SimpleText>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default ModalVisualizarEmpresa;
