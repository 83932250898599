import { useParams } from 'react-router';

import ListaPlanoSaude from '../../../components/ListaPlanoSaude/ListaPlanoSaude';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';


import './ListaCartoes.scss';

const ListaCartoes = () => {
  const { idPaciente } = useParams();

  return (
    <div id="lista-cartoes-paciente" className="p-grid p-col-12 p-lg-6">
      <SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
        Lista de cartões
      </SimpleText>

      <ListaPlanoSaude idPaciente={Number(idPaciente)} />
    </div>
  );
};

export default ListaCartoes;
