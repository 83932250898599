import { useCallback, useState } from 'react';

import { PlanoCuidadoAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoAPI/PlanoCuidadoAPI';

import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { ContentHeader } from './ContentHeader/ContentHeader';
import { ContentRow } from './ContentRow/ContentRow';
import { Filter } from './Filter/Filter';

import './PlanoCuidadoConfig.scss';

export const PlanoCuidadoConfig = () => {
  const [refetch, setRefetch] = useState(0);

  const fetchAPI = useCallback(async ({ query, ...params }: any) => {
    const data = await PlanoCuidadoAPI.loadPlanosCuidado({
      ...params,
      nome: query || '',
    });

    return data;
  }, []);

  const reloadList = () => {
    setRefetch(currentReload => currentReload + 1);
  };

  return (
    <SearchList.Root fetchApi={fetchAPI} empresaRequired>
      <div className="p-grid p-ai-end p-jc-between p-gap-2 p-mb-3">
        <SearchList.SearchInput
          label="Pesquisar plano"
          placeholder="Pesquise pelo nome do plano de cuidado"
          className="p-col-12 p-md-6 p-px-0"
        />

        <Filter />
      </div>
      <SearchList.BorderContainer>
        <ContentHeader refetch={() => reloadList()} />

        <SearchList.InfiniteScroll
          className="plano-cuidado-search-list"
          renderRow={item => (
            <ContentRow item={item} refetch={() => reloadList()} />
          )}
          reload={refetch}
        />
      </SearchList.BorderContainer>
    </SearchList.Root>
  );
};
