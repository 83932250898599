import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { GestaoMonitoramentoFormularioAPI } from 'src/APIs/AdminAPI/GestaoMonitoramentoFormularioAPI/GestaoMonitoramentoFormularioAPI';


import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import { Button, Checkbox } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useGestaoMonitoramento } from './hooks/GestaoMonitoramentoContext';

export const FormulariosSearch = () => {
  const [situacao, setSituacao] = useState('ATIVO');
  const [nome, setNome] = useState('');

  const [items, setItems] = useState<
    IGestaoMonitoramentoFormulariosAplicacaoesItem[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const [count, setCount] = useState({ count: 0, totalCount: 0 });

  const { setSelectedAllForms, selectedAllForms, setSelectedForms, filters } =
    useGestaoMonitoramento();

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);

      const data =
        await GestaoMonitoramentoFormularioAPI.loadFormulariosAplicacoes(
          {
            status: situacao === 'TODOS' ? undefined : situacao,
            nome,
            orderBy: 'nome',
            idsPaciente: filters.idsPacientes,
          },
          { throwError: true },
        );

      return [
        setItems(data.list),
        setCount({ count: data.count, totalCount: data.totalCount }),
        setLoading(false),
      ];
    } catch {
      return setLoading(false);
    }
  }, [filters.idsPacientes, nome, situacao]);

  const handleInput = useThrottle(setNome, 150);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  return (
    <div
      id="plano-search"
      className="p-col-12 p-md-6 p-pl-0 p-d-flex p-flex-column"
    >
      <SimpleText className="p-col-12" medium fontSize={FONT_SIZE.SM}>
        Formulários e aplicações
      </SimpleText>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Busque pelos formulários que foram aplicados a determinados pacientes.
      </SimpleText>

      <div className="p-col-12 p-mb-2">
        <TextInputSearch
          onChange={handleInput}
          placeholder="Pesquise pelo nome do formulário"
        />
      </div>

      <div className="p-w-100 p-py-2 p-d-flex p-ai-center p-gap-2">
        <SelectButton
          onChange={e => setSituacao(e.value)}
          value={situacao}
          options={[
            {
              label: 'Ativo',
              value: 'ATIVO',
            },
            {
              label: 'Inativo',
              value: 'INATIVO',
            },
            {
              label: 'Todos',
              value: 'TODOS',
            },
          ]}
        />
        <SimpleText
          fontColor={FONT_COLOR.COLOR_60}
          className="p-text-end p-wordbreak-normal"
        >
          Exibindo {count.count} registros de {count.totalCount}
        </SimpleText>
      </div>

      <div className="p-w-100 p-px-2 p-pb-1 p-d-flex p-jc-between">
        <Checkbox
          onChange={e => setSelectedAllForms(e.checked)}
          checked={selectedAllForms}
          label="Selecionar todos"
        />
        <Button
          btnType="ghost"
          label="Limpar"
          onClick={() => [setSelectedForms([]), setSelectedAllForms(false)]}
        />
      </div>

      <div className="plano-border-container p-px-2">
        <div className="p-d-flex p-col-12">
          <SimpleText
            className="p-col-12 p-px-0"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Formulário
          </SimpleText>
        </div>

        <div className="content-items p-d-flex p-flex-column p-gap-2 p-pb-2">
          {isLoading ? (
            <LoadingComponent />
          ) : !!items.length ? (
            items.map((item, idx) => <ContentRow key={idx} item={item} />)
          ) : (
            <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-py-2">
              <SimpleText>Nenhum formulário foi encontrado.</SimpleText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ContentRow = ({
  item,
}: {
  item: IGestaoMonitoramentoFormulariosAplicacaoesItem;
}) => {
  const { isOpen, toggle } = useDisclosure({ opened: false });
  const {
    setSelectedForms,
    selectedForms,
    selectedAllForms,
    setFilters,
    filters,
  } = useGestaoMonitoramento();

  const handleCheck = (e: boolean) => {
    setSelectedForms(old => {
      if (e) {
        return [...old, item];
      } else {
        return old.filter(i => i.id !== item.id);
      }
    });
  };

  const isChecked = useMemo(
    () => !!selectedForms.find(i => i.id === item.id),
    [selectedForms, item],
  );

  const handleCheckAplicacao = (id: number) => {
    if (filters.idsAplicacao.find(i => i === id)) {
      setFilters(old => ({
        ...old,
        idsAplicacao: old.idsAplicacao.filter(i => i !== id),
      }));
      return;
    }

    return setFilters(old => ({
      ...old,
      idsAplicacao: [...old.idsAplicacao, id],
    }));
  };

  return (
    <div
      className={`item p-d-flex p-col-12 p-flex-wrap`}
      data-isSelected={isChecked}
    >
      <Checkbox
        checked={isChecked}
        onChange={e => handleCheck(e.checked)}
        style={{
          width: '30px',
        }}
        disabled={selectedAllForms}
      />
      <SimpleText
        className="p-col-6 p-my-auto"
        medium
        style={{
          wordBreak: 'normal',
        }}
      >
        {item.nome}
      </SimpleText>
      <Button
        className="p-col p-text-right"
        label={`Num. de aplicações (${item.aplicacoes?.length ?? 0})`}
        btnType="green-link"
        icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
        iconPos="right"
        onClick={() => toggle()}
        disabled={!item.aplicacoes?.length}
      />

      {isOpen && (
        <>
          {item.aplicacoes?.map((app, idx) => (
            <div
              key={idx}
              onClick={() => handleCheckAplicacao(app.id)}
              className="p-col-12 row content-row white shadow-sm cursor-pointer"
            >
              <div
                className="p-w-100 row content-row"
                data-checked={!!filters.idsAplicacao.find(i => i === app.id)}
              >
                <div className="p-d-flex p-flex-column p-gap-2 p-col-4">
                  <SimpleText
                    fontSize="xxxs"
                    fontColor="color_60"
                    className="pointer-events-none"
                  >
                    Aplicação
                  </SimpleText>
                  <SimpleText medium className="pointer-events-none">
                    {app.nome}{' '}
                  </SimpleText>
                </div>
                <div className="p-d-flex p-flex-column p-gap-2 p-col-3">
                  <SimpleText fontSize="xxs" fontColor="color_60">
                    data de vigência
                  </SimpleText>
                  <div className="p-d-flex p-gap-2">
                    <SimpleText fontColor="color_60" fontSize="xxxs">
                      inicio{' '}
                      <SimpleText>
                        {app.dataInicioVigencia
                          ? dayjs(app.dataInicioVigencia).format('DD/MM/YYYY')
                          : '-'}
                      </SimpleText>
                    </SimpleText>
                    <SimpleText fontColor="color_60" fontSize="xxxs">
                      fim{' '}
                      <SimpleText>
                        {app.dataFimVigencia
                          ? dayjs(app.dataFimVigencia).format('DD/MM/YYYY')
                          : '-'}
                      </SimpleText>
                    </SimpleText>
                  </div>
                </div>
                <div className="p-col p-d-flex p-flex-column p-gap-2 p-ai-end">
                  <SimpleText fontSize="xxxs" fontColor="color_60">
                    N. de pacientes
                  </SimpleText>
                  <SimpleText medium fontColor="primary" fontSize="md">
                    {app.numeroPacientes}
                  </SimpleText>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const LoadingComponent = () => {
  const props = {
    loading: true,
    height: '40px',
    borderRadius: '8px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};
