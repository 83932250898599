import { useState } from 'react';

import { EquipeAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/EquipeAPI/EquipeAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface EquipeStatusProps {
  idEquipe: number;
  isAtivo: boolean;
}

export const EquipeStatus = (props: EquipeStatusProps) => {
  const { consultorios } = useAppSelector((state: RootState) => state);

  const { idEquipe, isAtivo } = props;
  const [ativo, setAtivo] = useState(isAtivo);
  const [loading, setLoading] = useState(false);

  const onSwitchStatus = async () => {
    setLoading(true);
    const response = await EquipeAPI.putEquipeStatus(
      idEquipe,
      consultorios.ativo.id,
    );

    if (response?.status === 200) {
      setAtivo(response.data);
    }

    setLoading(false);
  };

  if (loading)
    return (
      <div className="p-mb-4 p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return (
    <Switch
      checked={ativo}
      showStatus
      onChange={onSwitchStatus}
      checkPermission="ADM_CARTEIRA_APS_EQUIPE_ALTERAR"
    />
  );
};
