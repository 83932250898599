import { useState, useEffect, useCallback } from 'react';

import { useLocation, useParams } from 'react-router';

import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';

import './TipoConsulta.scss';

interface TipoConsultaProps {
  updateCamposProntuario: (campos: any) => void;
}

function TipoConsulta({ updateCamposProntuario }: TipoConsultaProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const [tiposConsulta, setTiposConsulta] = useState<any[]>([]);
  const [tipoConsultaSelecionado, setTipoConsultaSelecionado] = useState<any>(
    {},
  );

  const getTiposConsultas = async () => {
    return await UtilsAPI.getTiposConsulta(Number(idAtendimento));
  };

  useEffect(() => {
    getTiposConsultas().then(response => {
      setTiposConsulta(response.listTipoConsulta);
      setTipoConsultaSelecionado(
        response.listTipoConsulta.find(
          (tipoConsulta: any) => tipoConsulta.codigo === response.tipoConsulta,
        ),
      );
      handleSelectTipoConsulta(
        response.listTipoConsulta.find(
          (tipoConsulta: any) => tipoConsulta.codigo === response.tipoConsulta,
        ),
      );
    });
  }, []);

  const saveAction = useCallback(
    (payload: any) => {
      return UtilsAPI.putCampos(Number(idAtendimento), payload);
    },
    [idAtendimento],
  );

  const handleSelectTipoConsulta = useCallback(
    async (data: any) => {
      const payload = {
        idAtendimento: Number(idAtendimento),
        camposModeloProntuario: 'TIPO_CONSULTA',
        valorCampoProntuario: data?.codigo,
        idPaciente: Number(agenda?.paciente?.id),
        idProfissional: Number(agenda?.profissionalAtivo?.id),
      };
      updateCamposProntuario({ TIPO_CONSULTA: data?.codigo });
      const response = await saveAction(payload);
    },
    [tipoConsultaSelecionado],
  );

  return (
    <Dropdown
      className="dropdown-tipo-consulta p-mt-2"
      optionLabel="descricao"
      value={tipoConsultaSelecionado}
      options={tiposConsulta}
      onChange={e => {
        setTipoConsultaSelecionado(e.value);
        handleSelectTipoConsulta(e.value);
      }}
      placeholder="Selecione"
      required
      emptyMessage="Nenhum desfecho encontrado"
      label="Tipo da consulta"
    />
  );
}

export default TipoConsulta;
