import { useCallback, useEffect, useState, useMemo } from 'react';

import { DropdownChangeParams } from 'primereact/dropdown';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import Dropdown, {
  DropdownProps,
} from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

type FieldCartoesPacienteProps = DropdownProps & {
  numeroCartaoAtendimentoParticular: string | null;
  idConvenio: number | null | undefined;
  name?: string;
  label: string;
  params?: any;
  idPaciente: number | undefined;
  onChange: (event: DropdownChangeParams) => void;
};

export const FieldCartoesPaciente = ({
  idConvenio,
  numeroCartaoAtendimentoParticular,
  name = 'convenio',
  label,
  idPaciente,
  onChange,
  ...rest
}: FieldCartoesPacienteProps) => {
  const [convenios, setConvenios] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const loadConvenios = useCallback(async () => {
    if (!idPaciente) return;

    try {
      setLoading(true);

      const response = await ConvenioAPI.loadConvenioResumidoByPaciente(
        idPaciente,
        {
          params: { isSolicitacaoProcedimentos: true },
          throwError: true,
        },
      );

      setConvenios(response);
    } catch (error) {
      setConvenios([]);
    } finally {
      setLoading(false);
    }
  }, [idPaciente]);

  useEffect(() => {
    loadConvenios();
  }, [loadConvenios]);

  const itemTemplate = (option: any) => {
    const [convenioName] = option.label.split('-');
    return (
      <div className="p-d-flex p-justify-between">
        <SimpleText className="p-mr-3">{convenioName.trim()}</SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          {option.value.numeroCartao}
        </SimpleText>
      </div>
    );
  };

  const conveniosOptions = convenios.map(convenio => {
    const convenioName = convenio?.nomeFantasia || convenio.razaoSocial;
    const convenioCartao = convenio?.numeroCartao
      ? ` - ${convenio?.numeroCartao}`
      : '';
    return {
      label: convenioName + convenioCartao,
      value: convenio,
    };
  });

  const convenioValue = useMemo(() => {
    const conveioSelected = convenios.find(
      convenio => convenio.numeroCartao === numeroCartaoAtendimentoParticular,
    );
    if (conveioSelected) return conveioSelected;
    const convenioParticular = convenios.find(
      convenio => convenio.razaoSocial === 'PARTICULAR',
    );
    return convenioParticular ?? null;
  }, [convenios, numeroCartaoAtendimentoParticular]);

  return (
    <Dropdown
      panelClassName="panel-convenio"
      name={name}
      label={label}
      options={conveniosOptions}
      itemTemplate={itemTemplate}
      value={convenioValue}
      onChange={onChange}
      loading={loading}
      {...rest}
    />
  );
};
