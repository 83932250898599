import Http, { HttpParams } from 'src/core/http/Http';

export const getPesquisaProntuario = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    service: 'pep',
    url: `/prontuario/paciente/pesquisa-avancada`,
    params,
    hideToast: true,
    ...options,
  });
};

const PesquisaProntuarioAPI = {
  getPesquisaProntuario,
};

export default PesquisaProntuarioAPI;
