import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <div className="p-col-3">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Convênio
            </SimpleText>
          </div>
          <div className="p-col-7">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Grupo TUSS
            </SimpleText>
          </div>
          <div className="p-col-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Status
            </SimpleText>
          </div>
          <div className="p-col-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Ações
            </SimpleText>
          </div>
        </div>
      )}
    </>
  );
};
