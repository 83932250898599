import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';
import useSize from 'src/core/hooks/useSize';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

const ProcedimentosSeriadosConvenio = () => {
  const { isMobile } = useSize();
  const { onCopy, hasCopied } = useCopyClipboard();
  const { idConvenioEdit, procedimentosConvenio } = useManterConvenio();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: procedimentosConvenio,
  });

  const handleLinkCopy = (fieldName: any) => {
    const fieldValue = getValues(fieldName);

    onCopy(fieldValue);
  };

  useEffectSkipFirst(() => {
    if (hasCopied) {
      toast(<Toast />, {
        data: {
          title: 'Sucesso!',
          message: 'Link copiado com sucesso!',
          type: 'success',
        },
      });
    }
  }, [hasCopied]);

  const onSubmit = async (data: any) => {
    const payload = { idConvenio: idConvenioEdit, ...data };
    try {
      await ConvenioAPI.updateProcedimentosSeriadosConvenio(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting },
  } = form;

  return (
    <div className="p-d-flex p-flex-column p-mt-2 p-gap-3">
      <SimpleText fontColor="color_60">
        Campos para a liberação de procedimentos seriados com a operadora.
      </SimpleText>

      <FormProvider {...form}>
        <form
          id="procedimentos-convenio"
          className="p-w-100 p-d-flex p-flex-column p-gap-3 p-mb-2"
          onSubmit={() => {
            handleSubmit(onSubmit)();
          }}
        >
          <div className="p-col-12 p-md-7 p-lg-6">
            <SwitchControlled
              control={control}
              name="habilitarConsultaProcedimentosSeriados"
              title="Consulta do procedimento seriado"
              showStatus
            />
          </div>

          <div className="p-grid">
            <FormInput
              className="p-xl-10 p-lg-9 p-md-8 p-col-7"
              name="urlConsultaProcedimentosSeriados"
              label="Endereço do webservice para o procedimento seriado"
              placeholder="Informe o link"
              icon="icon-btn fa-sharp fa-solid fa-link"
              onClickIcon={() =>
                handleLinkCopy('urlConsultaProcedimentosSeriados')
              }
              maxLength={200}
            />

            <FormInput
              className="p-xl-2 p-lg-3 p-md-4 p-col-5 "
              name="timeoutConsultaProcedimentosSeriados"
              hideTextErrorSpace
              type="number"
              label="Timeout"
              sublabel={!isMobile ? '(em segundos)' : ''}
              placeholder="Informe o tempo"
              max={300}
            />
          </div>

          <div className="p-d-flex p-gap-2 p-mt-2">
            <Button
              btnType="ghost"
              label="Cancelar"
              onClick={() => navigate('/cadastros/convenio')}
              className="p-flex-1"
              loading={isSubmitting}
            />
            <Button
              label="Salvar"
              type="button"
              onClick={() => handleSubmit(onSubmit)()}
              className="p-flex-2"
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ProcedimentosSeriadosConvenio;
