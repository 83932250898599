import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import useSize from 'src/core/hooks/useSize';

import { handleCalculoSuperficieCorporal } from '../utils';

import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { VariantPanel } from 'src/components/Panel/VariantPanel/VariantPanel';

export const DadosBeneficiarioForm = () => {
  const { isMobile } = useSize();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [peso, altura] = watch([
    'dadosBeneficiario.peso',
    'dadosBeneficiario.altura',
  ]);

  useEffect(() => {
    if (peso && altura)
      setValue(
        'dadosBeneficiario.superficie',
        handleCalculoSuperficieCorporal(peso, altura),
      );
  }, [peso, altura, setValue]);

  const hasError = !!Object.keys(errors?.dadosBeneficiario || {})?.length;

  return (
    <VariantPanel
      isOpened
      hasError={hasError}
      title="Dados do beneficiário"
      subtitle="(Obrigatório)"
    >
      <form className="p-grid">
        <TextInputControlled
          className="p-col-4"
          label="Peso"
          name="dadosBeneficiario.peso"
          placeholder="kg"
          sublabel="(Obrigatório)"
          control={control}
          errorMsg={errors.dadosBeneficiario?.peso?.message}
          floatOnly
          virgulaDecimal
          duasDecimais
          maxLength={5}
        />
        <TextInputControlled
          className="p-col-4"
          label="Altura"
          name="dadosBeneficiario.altura"
          placeholder="cm"
          sublabel="(Obrigatório)"
          control={control}
          errorMsg={errors.dadosBeneficiario?.altura?.message}
          floatOnly
          virgulaDecimal
          duasDecimais
          maxLength={5}
        />

        <FormInput
          label={isMobile ? 'Sup. corporal' : 'Superfície corporal'}
          sublabel="(Obrigatório)"
          name="dadosBeneficiario.superficie"
          placeholder="cm"
          className="p-col-4"
          hideTextErrorSpace
          disabled
        />
      </form>
    </VariantPanel>
  );
};
