import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  perfil: Perfil;
  onEdit: (perfil: Perfil) => void;
  onDelete: (idPerfil: number) => void;
}

export const Row = (props: RowProps) => {
  const { perfil, onEdit, onDelete } = props;

  const { isMobile } = useSize();

  const handleEdit = () => {
    onEdit(perfil);
  };

  const handleDelete = () => {
    onDelete(perfil.id);
  };

  const commonProps = {
    perfil,
    onEdit: handleEdit,
    onDelete: handleDelete,
  };

  return isMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
