import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import HistoricoAGAAPI from 'src/APIs/ProntuarioAPI/HistoricoAGAAPI/HistoricoAGAAPI';

import { useAGA } from 'src/core/hooks/AGA/useAGA';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { generateUniqueId } from 'src/utils/utils';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import Separator from 'src/components/Separator/Separator';
import Skeleton from 'src/components/Skeleton/Skeleton';

import Row from './List/Row';
import ModalHistoricoAGA from './ModalHistoricoAGA/ModalHistoricoAGA';
import * as SearchList from './SearchList/SearchListRoot';

import './HistoricoAGA.scss';
import { Button } from 'src/components/_UI';

interface HistoricoAGAProps {
  idPaciente: number | null;
}

const HistoricoAGA = ({ idPaciente }: HistoricoAGAProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    activeFormulario,
    reloadHistorico,
    historicoDialogDisclosure,
    setReloadHistorico,
    setEvolucaoHistorico,
    isEdit,
    diagnosticoFuncionalDisclosure,
  } = useAGA();
  const { consultorios } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const idConsultorio = consultorios?.ativo?.id;

  const renderRow = (historico: any) => (
    <Row key={generateUniqueId()} historico={historico} />
  );

  useEffect(() => {
    if (!activeFormulario) return;

    setReloadHistorico((prev: number) => prev + 1);
  }, [activeFormulario, setReloadHistorico]);

  const fetchApi = useCallback(async () => {
    if (!idPaciente || !activeFormulario?.id || !idConsultorio) return;

    try {
      setLoading(true);
      const response = await HistoricoAGAAPI.getHistoricoByFormId(
        idPaciente,
        activeFormulario?.id,
        {
          idConsultorio,
          idAtendimento,
          pageSize: 9999,
          sortDirection: 'DESC',
          sortBy: 'dataOcorrencia',
        },
      );
      setEvolucaoHistorico(response.list);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    idPaciente,
    activeFormulario?.id,
    idConsultorio,
    idAtendimento,
    setEvolucaoHistorico,
  ]);

  return (
    <div id="historico-AGA" className="p-col-12 p-p-0">
      <ElevatedCard className="neutral-background" noMargin>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.MD}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Histórico Idoso bem cuidado - AGA
          </SimpleText>
          <Button
            className="p-mr-2"
            btnType="tonal"
            icon="fas fa-chart-column"
            label="Ver diagnostico"
            onClick={() => diagnosticoFuncionalDisclosure.open()}
          />
        </div>
        <Separator layout="horizontal" className="p-px-2" />
        <SearchList.Root fetchApi={fetchApi}>
          <Skeleton loading={loading} height="140px" borderRadius="8px">
            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reloadHistorico}
              customEmptyText="Nenhum registro foi encontrado no histórico."
            />
          </Skeleton>
        </SearchList.Root>
      </ElevatedCard>
      {historicoDialogDisclosure.isOpen && (
        <ModalHistoricoAGA
          {...historicoDialogDisclosure}
          idPaciente={idPaciente}
        />
      )}
    </div>
  );
};

export default HistoricoAGA;
