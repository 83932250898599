import { useCallback, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import './FormResponsables.scss';

interface FormResponsablesProps {
  onSubmit: any;
  values: Partial<EmpresaDTO>;
}

const FormResponsables = ({ onSubmit, values }: FormResponsablesProps) => {
  const navigate = useNavigate();

  const [checkboxFinacial, setCheckboxFinacial] = useState<boolean>(true);

  const formValidation = useCallback((): any => {
    return Yup.object().shape({
      nomeResponsavelGeral: Yup.string().required('O campo é obrigatório.'),
      nomeResponsavelFinanceiro: Yup.string().required(
        'O campo é obrigatório.',
      ),
      nomeResponsavelTecnico: Yup.string().required('O campo é obrigatório.'),
    });
  }, []);

  const useFormMethods = useForm<any>({
    resolver: yupResolver(formValidation()),
    defaultValues: {
      nomeResponsavelGeral: values.responsavelGeral?.nome || '',
      cargoResponsavelGeral: values.responsavelGeral?.cargo || '',
      emailResponsavelGeral: values.responsavelGeral?.email || '',
      telefoneResponsavelGeral: values.responsavelGeral?.telefone || '',
      isResponsavelFinanceiro: values.responsavelGeral?.responsavelFinanceiro,
      nomeResponsavelFinanceiro: values.responsavelFinanceiro?.nome || '',
      emailResponsavelFinanceiro: values.responsavelFinanceiro?.email || '',
      telefoneResponsavelFinanceiro:
        values.responsavelFinanceiro?.telefone || '',
      nomeResponsavelTecnico: values.responsavelTecnico?.nome || '',
      emailResponsavelTecnico: values.responsavelTecnico?.email || '',
      telefoneResponsavelTecnico: values.responsavelTecnico?.telefone || '',
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const handleResponsaveis = useCallback(
    (data: any) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  const handleCancel = useCallback(() => {
    reset();
    navigate(-1);
  }, [navigate, reset]);

  const handleFinancial = () => {
    setCheckboxFinacial(getValues('isResponsavelFinanceiro'));

    if (checkboxFinacial) {
      setValue('nomeResponsavelFinanceiro', getValues('nomeResponsavelGeral'));

      setValue(
        'emailResponsavelFinanceiro',
        getValues('emailResponsavelGeral'),
      );

      setValue(
        'telefoneResponsavelFinanceiro',
        getValues('telefoneResponsavelGeral'),
      );
    } else {
      setValue('nomeResponsavelFinanceiro', '');
      setValue('emailResponsavelFinanceiro', '');
      setValue('telefoneResponsavelFinanceiro', '');
    }
  };

  return (
    <div className="FormResponsables p-grid p-col-12 p-lg-8 p-xl-6">
      <SimpleText
        className="p-col-12"
        fontSize={FONT_SIZE.XS}
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Responsável geral
      </SimpleText>
      <FormProvider {...useFormMethods}>
        <form
          className="p-grid p-col-12 p-px-0 p-pb-4"
          onSubmit={handleSubmit(handleResponsaveis)}
        >
          <FormInput
            className="p-col-12"
            errorMsg={errors.nomeResponsavelGeral?.message}
            name="nomeResponsavelGeral"
            label="Nome"
            hideTextErrorSpace
            maxLength={50}
          />
          <FormInput
            className="p-col-12"
            errorMsg={errors.cargoResponsavelGeral?.message}
            name="cargoResponsavelGeral"
            label="Cargo"
            hideTextErrorSpace
            maxLength={50}
          />
          <FormInput
            className="p-col-12"
            errorMsg={errors.emailResponsavelGeral?.message}
            name="emailResponsavelGeral"
            type="email"
            label="E-mail"
            hideTextErrorSpace
            maxLength={50}
          />

          <div className="p-col-12">
            <MaskedInputControlled
              errorMsg={errors.telefoneResponsavelGeral?.message}
              name="telefoneResponsavelGeral"
              control={control}
              label="Telefone"
              mask={MASK.PHONE}
            />
            <CheckboxControlled
              name="isResponsavelFinanceiro"
              control={control}
              onMouseDown={handleFinancial}
              label="Marcar como responsável financeiro"
              className="isResponsavelFinanceiro p-mt-2"
            />
          </div>

          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {' '}
            Responsável financeiro
          </SimpleText>

          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            name="nomeResponsavelFinanceiro"
            label="Nome"
            readOnly={!checkboxFinacial}
            maxLength={50}
          />

          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            name="emailResponsavelFinanceiro"
            type="email"
            label="E-mail"
            readOnly={!checkboxFinacial}
            maxLength={50}
          />

          <MaskedInputControlled
            className="p-col-12"
            errorMsg={errors.telefoneResponsavelFinanceiro?.message}
            name="telefoneResponsavelFinanceiro"
            control={control}
            label="Telefone"
            mask={MASK.PHONE}
            readOnly={!checkboxFinacial}
          />

          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Responsável técnico
          </SimpleText>

          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.nomeResponsavelTecnico?.message}
            name="nomeResponsavelTecnico"
            label="Nome"
            maxLength={50}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.emailResponsavelTecnico?.message}
            name="emailResponsavelTecnico"
            type="email"
            label="E-mail"
            maxLength={50}
          />
          <MaskedInputControlled
            className="p-col-12"
            errorMsg={errors.telefoneResponsavelTecnico?.message}
            name="telefoneResponsavelTecnico"
            control={control}
            label="Telefone"
            mask={MASK.PHONE}
          />

          <div className="p-col-12 p-sm-4">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={handleCancel}
              stretch
            />
          </div>
          <div className="p-col-12 p-sm-8">
            <Button
              btnType="tonal"
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormResponsables;
