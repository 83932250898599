import Http, { HttpParams } from 'src/core/http/Http';

export const getCircunferenciaQuadril = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `circunferencia-quadril/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesCircunferenciaQuadril = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'circunferencia-quadril/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarCircunferenciaQuadril = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'circunferencia-quadril',
    data: payload,
    return: true,
    ...options,
  });

export const alterarCircunferenciaQuadril = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-quadril/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarCircunferenciaQuadril = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-quadril/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerCircunferenciaQuadril = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `circunferencia-quadril/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const CircunferenciaQuadrilAPI = {
  getCircunferenciaQuadril,
  getUnidadesCircunferenciaQuadril,
  adicionarCircunferenciaQuadril,
  alterarCircunferenciaQuadril,
  inativarCircunferenciaQuadril,
  removerCircunferenciaQuadril,
};

export default CircunferenciaQuadrilAPI;
