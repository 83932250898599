import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';


interface MobileRowProps {
  servicoTuss: any;
  radioButtonPrincipal: JSX.Element;
  inputQuantidade: JSX.Element;
  onDelete: () => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { servicoTuss, radioButtonPrincipal, inputQuantidade, onDelete } =
    props;

  const options = [
    {
      label: 'Excluir',
      className: 'warning-color',
      command: onDelete,
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Principal
        </SimpleText>

        {radioButtonPrincipal}
      </div>

      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {servicoTuss.codigo}
        </SimpleText>
      </div>

      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Descrição
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {servicoTuss.nome}
        </SimpleText>
      </div>

      <div className="column p-mt-3">
        <SimpleText
          className="p-mb-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Quantidade
        </SimpleText>

        {inputQuantidade}
      </div>
    </SearchListMobileRow>
  );
};
