import Http, { HttpParams } from 'src/core/http/Http';

export const getServicosVinculados = (
  consultorioId: string,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/consultorios/${consultorioId}/servicos-tuss-vinculados`,
    params,
    hideToast: true,
    ...options,
  });
};

export const adicionarServicosVinculados = (
  consultorioId: string,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/consultorios/${consultorioId}/servicos-tuss-vinculados`,
    data,
    return: true,
    throwError: true,
    ...options,
  });
};

export const deleteServicosVinculados = (
  consultorioId: string,
  idServico: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `/consultorios/${consultorioId}/servicos-tuss-vinculados/${idServico}`,
    return: true,
    ...options,
  });
};

const ServicosTussVinculadosAPI = {
  getServicosVinculados,
  adicionarServicosVinculados,
  deleteServicosVinculados,
};

export default ServicosTussVinculadosAPI;
