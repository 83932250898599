import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  capitalizeFirstLetter,
  formatCarteirinhaMask,
  formatCPF,
  validateSiglaIdade,
} from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Tooltip from 'src/components/Tooltip/Tooltip';

import {
  AtendimentoGrupoDialogProblemasAlergias,
  AtendimentoGrupoDialogProblemasAlergiasType,
} from './DialogDetalhesPacientesProblemasAlergias';

import './DialogDetalhesPacientes.scss';

type Props = DisclosureType & {
  pacientes: PacienteProntuario[];
};

export const AtendimentoGrupoDialogListaPacientes = ({ close, pacientes }: Props) => {
  const dialogProblemas = useDisclosure({
    opened: false,
  }) as DisclosureType<AtendimentoGrupoDialogProblemasAlergiasType>;

  return (
    <Dialog
      visible
      onHide={close}
      header="Lista de pacientes"
      className="DialogGrupoListaPacientes"
    >
      <>
        <div className="border-container">
          <SimpleText className="p-col-12" fontColor="color_60" fontSize="xxs">
            Paciente
          </SimpleText>

          <div className="p-d-flex p-flex-column p-gap-1 p-mt-1">
            {pacientes.map((pac, idx) => {
              const alergiasLength = pac.alergiasPaciente?.length || 0;
              const problemasLength = pac.comorbidadesPaciente?.length || 0;
              const problemas =
                pac.comorbidadesPaciente?.map(item => item.cid?.[0]) || [];

              return (
                <div
                  key={idx}
                  className="content-item p-d-flex p-flex-row p-ai-center p-gap-1 p-w-100"
                >
                  <Avatar
                    label={pac.nome}
                    image={pac.fotoUrl || ''}
                    className="p-col-3"
                  />
                  <div className="p-d-flex p-flex-column p-col">
                    <PacienteDetalhes pac={pac} />
                    <ProblemasAlergiasComp
                      problemas={problemas}
                      problemasLength={problemasLength}
                      alergiasLength={alergiasLength}
                      alergias={pac.alergiasPaciente}
                      dialogProblemas={dialogProblemas}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {dialogProblemas.isOpen && (
          <AtendimentoGrupoDialogProblemasAlergias {...dialogProblemas} />
        )}
        <Button
          btnType="outline"
          label="Fechar"
          className="p-mt-2"
          stretch
          onClick={() => close()}
        />
      </>
    </Dialog>
  );
};

const ProblemasAlergiasComp = ({
  problemas,
  problemasLength,
  alergiasLength,
  dialogProblemas,
  alergias,
}: {
  problemas: any[];
  problemasLength: number;
  alergiasLength: number;
  alergias: any[];
  dialogProblemas: DisclosureType<AtendimentoGrupoDialogProblemasAlergiasType>;
}) => {
  return (
    <div className="p-d-flex p-gap-1 p-ai-center p-px-2">
      {!!problemasLength &&
        problemas.map((item, idx) => {
          if (!item || idx > 3) return null;
          return (
            <>
              <Tooltip target={`#${item.cid.codigo}`} />
              <span
                key={idx}
                id={item.cid.codigo}
                className="tag tag-cid"
                data-pr-tooltip={item.cid.nome || '-'}
                data-pr-position="top"
              >
                {item.cid?.codigo}
              </span>
            </>
          );
        })}

      {!!(problemasLength > 3 || alergiasLength) && (
        <SimpleText fontSize="xxs">
          e{' '}
          {problemasLength > 3 && (
            <SimpleText
              role="button"
              fontColor="primary"
              fontSize="xxs"
              onClick={() =>
                dialogProblemas.open({
                  state: {
                    type: 'PROBLEMAS',
                    items: problemas,
                  },
                })
              }
            >
              {problemasLength - 3} problema(s) |
            </SimpleText>
          )}
          {!!alergiasLength && (
            <SimpleText
              role="button"
              fontColor="primary"
              fontSize="xxs"
              onClick={() =>
                dialogProblemas.open({
                  state: {
                    type: 'ALERGIAS',
                    items: alergias,
                  },
                })
              }
            >
              {' '}
              {alergiasLength} alergia(s)
            </SimpleText>
          )}
        </SimpleText>
      )}
    </div>
  );
};

const PacienteDetalhes = ({ pac }: { pac: any }) => {
  return (
    <div className="p-col p-d-flex p-flex-column p-gap-2">
      <SimpleText>{pac.nome}</SimpleText>
      <SimpleText fontColor="color_40">
        {validateSiglaIdade(pac.idadeAnos, pac.idadeMeses, pac.idadeDias)} •{' '}
        {capitalizeFirstLetter(pac.sexoDescricao)} •{' '}
        {pac.cpf ? formatCPF(pac.cpf) : ''}
      </SimpleText>
      <SimpleText fontColor="color_60" fontSize="xxs">
        ({pac.id}) •{' '}
        <SimpleText fontColor="color_40" fontSize="xxs">
          {pac.convenio?.razaoSocial} •{' '}
          {pac.mascaraCarterinha
            ? formatCarteirinhaMask(pac.cartaoPlano, pac.mascaraCarterinha)
            : pac.cartaoPlano}
        </SimpleText>
      </SimpleText>
    </div>
  );
};