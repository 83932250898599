import { useRef, useState } from 'react';

import { useNavigate } from 'react-router';

import TipoContaPagarAPI from 'src/APIs/AdminAPI/TipoContaPagarAPI/TipoContaPagarAPI';

import useSize from 'src/core/hooks/useSize';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

interface RowProps {
  data: any;
  reloadList: () => void;
}

const Row = ({ data, reloadList }: RowProps) => {
  const [tipoContaPagar, setTipoContaPagar] = useState(data);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef<any>(null);
  const { isTablet } = useSize();

  const onEdit = async () => {
    navigate(`/faturamento/tipo-conta-pagar/${data.id}`);
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      const response = await TipoContaPagarAPI.deleteTipoContaPagar(data.id);

      if (response?.status >= 200 && response?.status < 300) {
        reloadList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const items = [
    {
      label: 'Editar',
      command: onEdit,
    },

    {
      label: 'Excluir',
      command: onDelete,
      className: 'error',
    },
  ];

  const commonProps = {
    tipoContaPagar,
    menuRef,
    items,
  };

  return (
    <div className="p-grid search-list-card-item">
      {loading ? (
        <div>
          <SpinnerLoading size="sm"></SpinnerLoading>
        </div>
      ) : isTablet ? (
        <MobileRow {...commonProps} />
      ) : (
        <DesktopRow {...commonProps} />
      )}
    </div>
  );
};

export default Row;
