import { useEffect, useState } from 'react';

import {
  FormProvider,
  useFieldArray,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './Dialog.scss';

import Skeleton from 'src/components/Skeleton/Skeleton';

const defaultValue = [
  {
    data: new Date(),
    hora: new Date(),
  },
];

type IProps = DisclosureType & {
  idx: number;
  handleUpdate: UseFieldArrayUpdate<any>;
};

export const DialogConsultaPlanoCuidado = ({
  close,
  isOpen,
  idx,
  handleUpdate,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const formContext = useFormContext();

  const field = formContext.watch(`planos.${idx}`);
  const formValues = formContext.watch();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        consultas: Yup.array().of(
          Yup.object({
            data: Yup.date().required('Campo obrigatório'),
            hora: Yup.date().required('Campo obrigatório'),
          }),
        ),
      }),
    ),
    defaultValues: {
      consultas: !!field?.consultas?.length ? field.consultas : defaultValue,
    },
  });

  const { fields, remove, append, replace } = useFieldArray({
    control: form.control,
    name: 'consultas',
    keyName: 'idForm',
  });

  useEffect(() => {
    if (!!field.consultas?.length) return;

    setIsLoading(true);
    PlanoCuidadoPacienteAPI.generatePlanoCuidadoRecorrencia({
      idPlanoCuidado: formValues.planoCuidado?.id,
      idPlanoCuidadoPaciente: formValues.idAtendimentoOrigem
        ? formValues.id
        : undefined,
      recorrencia: field.recorrencia,
    })
      .then(d => {
        const datas = d.datasSugeridas.map((date: any) => ({
          data: new Date(date),
          hora: new Date(date),
        }));
        replace(datas);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [
    field.consultas?.length,
    field.recorrencia,
    formValues.id,
    formValues.idAtendimentoOrigem,
    formValues.planoCuidado?.id,
    replace,
  ]);

  const onSubmit = (values: any) => {
    handleUpdate(idx, { ...field, consultas: values.consultas });
    close();
  };

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header="Data de execução"
      id="dialog-plano-cuidado"
      className="p-width-consulta"
      modal={false}
      maximizedMobileSize
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="p-col-12 p-d-flex p-flex-column "
        >
          <div className="form-execucao">
            {isLoading ? (
              <LoadingComp />
            ) : (
              fields.map((consulta, idx) => (
                <div
                  className="p-grid p-col-12 p-px-0 p-gap-1 p-ai-center"
                  key={consulta.idForm}
                >
                  <CalendarInputControlled
                    control={form.control}
                    name={`consultas.${idx}.data`}
                    label="Dia"
                    className="p-col-5 p-py-0"
                    hideTextErrorSpace={false}
                    icon="fas fa-calendar"
                    iconPos="right"
                    minDate={new Date()}
                  />
                  <CalendarInputControlled
                    control={form.control}
                    name={`consultas.${idx}.hora`}
                    label="Hora"
                    className="p-col-4 p-sm-5 p-py-0"
                    hourFormat="HH:mm"
                    timeOnly
                    hideTextErrorSpace={false}
                  />

                  <div className="p-col p-mb-1">
                    <Button
                      icon="fas fa-trash"
                      onClick={() => remove(idx)}
                      btnType="gray"
                    />
                  </div>
                </div>
              ))
            )}

            <Button
              label="Adicionar mais dias"
              btnType="green-link"
              onClick={() =>
                append({
                  data: new Date(),
                  hora: undefined,
                })
              }
              className="p-m-2"
            />
          </div>

          <div className="p-col-12 p-d-flex p-gap-2">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
            />
            <Button label="Salvar" type="submit" stretch />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

const LoadingComp = () => {
  const props = {
    loading: true,
    height: '30px',
    borderRadius: '8px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-col-12">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};
