import {
  CreatePlanoCuidado,
  LoadPlanoCuidadoByIdProps,
  LoadPlanoCuidadoParams,
  UpdatePlanoCuidadoById,
} from 'src/models/APIs/ProntuarioAPI/PlanoCuidado/PlanoCuidado';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/plano-cuidado';

const loadPlanoCuidadoById = (
  idPlanoCuidado: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPlanoCuidadoByIdProps> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/${idPlanoCuidado}`,
    hideToast: true,
    ...options,
  });
};

const updatePlanoCuidadoById = (
  params: { id: string | number },
  payload: UpdatePlanoCuidadoById,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPlanoCuidadoByIdProps> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${params.id}`,
    ...options,
    data: payload,
  });
};

const createPlanoCuidado = (
  payload: CreatePlanoCuidado,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPlanoCuidadoByIdProps> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}`,
    ...options,
    data: payload,
  });
};

const deletePlanoCuidado = (
  params: { id: string | number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPlanoCuidadoByIdProps> => {
  return Http.delete({
    service: 'pep',
    url: `${basePath}/${params.id}`,
    ...options,
  });
};

const inativarPlanoCuidado = (
  params: { id: string | number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPlanoCuidadoByIdProps> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/inativar/${params.id}`,
    ...options,
  });
};
const ativarPlanoCuidado = (
  params: { id: string | number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPlanoCuidadoByIdProps> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/ativar/${params.id}`,
    ...options,
  });
};

const loadPlanosCuidado = (
  params: LoadPlanoCuidadoParams,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<LoadPlanoCuidadoByIdProps[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/pesquisar`,
    hideToast: true,
    params,
    ...options,
  });
};

const loadPlanosCuidadoLista = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: basePath,
    hideToast: true,
    params,
    ...options,
  });
};

export const PlanoCuidadoAPI = {
  loadPlanoCuidadoById,
  updatePlanoCuidadoById,
  createPlanoCuidado,
  loadPlanosCuidado,
  deletePlanoCuidado,
  inativarPlanoCuidado,
  loadPlanosCuidadoLista,
  ativarPlanoCuidado,
};
