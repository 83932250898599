import { memo, useCallback, useEffect, useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import SearchListServicoTuss from './SearchListServicoTuss';
import './SearchListServicoTuss.scss';

interface FormDialogProps {
  visible: boolean;
  onHide(): void;
  onSelect(i: ServicosTussComGuiaTiss[]): void;
  initialValue: ServicosTussComGuiaTiss[] | undefined;
  idConvenio: number;
}

const SearchServicoTuss = (props: FormDialogProps) => {
  const { visible, onHide, onSelect, idConvenio } = props;

  const [selected, setSelected] = useState<ServicosTussComGuiaTiss[]>([]);

  const handleAdd = useCallback(() => {
    onSelect(selected);
  }, [onSelect, selected]);

  const handleSelectItem = useCallback((item: any) => {
    const itemsReformuled = {
      ...item,
      idServicoTuss: item?.id,
    };

    setSelected(v => {
      if (v.find(i => i?.id === itemsReformuled?.idServicoTuss)) return v;
      return [...v, itemsReformuled].sort((a, b) =>
        a?.nome < b?.nome ? -1 : 1,
      );
    });
  }, []);

  const handleRemoveItem = useCallback((item: ServicosTussComGuiaTiss) => {
    setSelected(v => {
      if (!v.find(i => i?.id === item?.id)) return v;
      return v.filter(i => i?.id !== item?.id);
    });
  }, []);

  useEffect(() => {
    setSelected(props.initialValue || []);
  }, [props.initialValue, visible]);

  return (
    <Dialog
      type="modal"
      className="footer-on"
      header="Adicionar Serviços Tuss"
      visible={visible}
      onHide={onHide}
      footer={() => <Footer onAdd={handleAdd} onCancel={onHide} />}
      maximizedTabletSize
    >
      <div className="SearchServicoTuss-GuiaTiss">
        <div className="search-title">
          <SimpleText fontColor={FONT_COLOR.COLOR_16}>
            Pesquise o nome do Serviço Tuss desejado e adicione clicando no
            resultado da busca.
          </SimpleText>
        </div>

        <SearchListServicoTuss
          selected={selected}
          onSelectItem={handleSelectItem}
          onRemoveItem={handleRemoveItem}
          idConvenio={idConvenio}
        />
      </div>
    </Dialog>
  );
};

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button label="Salvar" onClick={onAdd} stretch />
    </div>
  );
});

export default memo(SearchServicoTuss);
