import { ReactNode, useState, useMemo } from 'react';

import { SelectProfileCreateUserContext, SelectedProfile } from '../../contexts/ManterUsuario/SelectProfileCreateUser';


interface SelectProfileCreateUserProviderProps {
  children: ReactNode;
}

export function SelectProfileCreateUserProvider({ children }: SelectProfileCreateUserProviderProps) {
  const [selectedProfile, setSelectedProfile] = useState<SelectedProfile | null>(null);
  const isProfessionalHealth = selectedProfile?.value === 'PROFISSIONAL_SAUDE';

  function selectProfile(profile: SelectedProfile) {
    setSelectedProfile(profile);
  }

  return (
    <SelectProfileCreateUserContext.Provider
      value={{
        selectedProfile,
        isProfessionalHealth,
        selectProfile,
      }}
    >
      {children}
    </SelectProfileCreateUserContext.Provider>
  );
}
