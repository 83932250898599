import Http, { HttpParams } from 'src/core/http/Http';

const loadFormulariosDinamico = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IFormularioDinamicoListItem[]>> =>
  Http.get({
    service: 'adm',
    url: `/formulario-dinamico/pesquisar`,
    params,
    hideToast: true,
    ...options,
  });
const loadFormularioDinamicoById = async (
  params: { id: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IFormularioDinamicoItem> =>
  Http.get({
    service: 'adm',
    url: `/formulario-dinamico/${params.id}`,
    params,
    hideToast: true,
    ...options,
  });
const loadFormularioDinamicoTiposFormulario = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> =>
  Http.get({
    service: 'adm',
    url: `/formulario-dinamico/tipos-formulario`,
    hideToast: true,
    ...options,
  });
const postFormularioDinamicoAdicionarPergunta = async (
  params: IFormularioDinamicoPerguntaPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico/${params.idPergunta}`,
    params,
    ...options,
  });
const postFormularioDinamicoOrdenarPergunta = async (
  params: IFormularioDinamicoPerguntaPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico/${params.idPergunta}`,
    params,
    ...options,
  });
const postFormularioDinamicoDesvincularPergunta = async (
  params: IFormularioDinamicoPerguntaPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico/${params.idPergunta}`,
    params,
    ...options,
  });
const createFormularioDinamico = async (
  payload: Omit<IFormularioDinamicoPayload, 'id'>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico`,
    data: payload,
    ...options,
  });
const updateFormularioDinamico = async (
  payload: IFormularioDinamicoItem,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `/formulario-dinamico/${payload.id}`,
    data: payload,
    ...options,
  });
const insertFormularioDinamicoPerguntaAcervo = async (
  params: { idFormulario: number; idsPergunta: number[] },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico/${params.idFormulario}/adicionar-perguntas`,
    data: {
      idsPergunta: params.idsPergunta || [],
    },
    ...options,
  });

const ordenarFormularioDinamicoPergunta = async (
  params: {
    idFormulario: number;
    idFormularioDinamicoPergunta: number;
    ordem: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico/${params.idFormulario}/ordenar-pergunta`,
    data: {
      idFormularioDinamicoPergunta: params.idFormularioDinamicoPergunta,
      ordem: params.ordem,
    },
    ...options,
  });
const loadStatusFormularioDinamico = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/formulario-dinamico/status-formulario`,
    ...options,
    hideToast: true,
  });
const desvincularPerguntaFormularioDinamico = async (
  params: { idForm: number; idFormularioDinamicoPergunta: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/formulario-dinamico/${params.idForm}/desvincular-pergunta/${params.idFormularioDinamicoPergunta}`,
    ...options,
  });
const deletarFormularioDinamico = async (
  idForm: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `/formulario-dinamico/${idForm}`,
    ...options,
  });

export const FormularioDinamicoAPI = {
  loadFormulariosDinamico,
  loadFormularioDinamicoById,
  loadFormularioDinamicoTiposFormulario,
  postFormularioDinamicoAdicionarPergunta,
  postFormularioDinamicoOrdenarPergunta,
  postFormularioDinamicoDesvincularPergunta,
  createFormularioDinamico,
  updateFormularioDinamico,
  insertFormularioDinamicoPerguntaAcervo,
  ordenarFormularioDinamicoPergunta,
  loadStatusFormularioDinamico,
  desvincularPerguntaFormularioDinamico,
  deletarFormularioDinamico,
};
