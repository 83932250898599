import { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import { MovimentacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/Movimentacao/MovimentacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button, Checkbox } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import AdvancedSearch from './AdvancedSearch/AdvancedSearch';
import ModalAddPaciente from './EditarMovimentacaoGrid/ModalAddPaciente/ModalAddPaciente';
import ModalHistorico from './EditarMovimentacaoGrid/ModalHistorico/ModalHistorico';
import { Row } from './EditarMovimentacaoGrid/Row';
import { Header } from './Header';
import ModalTransferencia from './ModalTransferirCarteira/ModalTransferencia';

import './FormMovimentacao.scss';

const FormMovimentacao = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [pacienteSelected, setPacienteSelected] = useState<
    PacientesCarteiraDTO[]
  >([]);
  const [pacienteList, setPacienteList] = useState<PacientesCarteiraDTO[]>([]);
  const [cabecalhoInfo, setCabecalhoInfo] = useState<any>();

  const modalTransferencia = useDisclosure({ opened: false });
  const modalDeleteDisclosure = useDisclosure({ opened: false });
  const modalAddPaciente = useDisclosure({ opened: false });
  const modalHistorico = useDisclosure({ opened: false });

  const [loading, setLoading] = useState<boolean>(false);

  const idCuidador = Number(id);

  const handleNavigate = useCallback(() => {
    navigate('/pacientes/carteira-aps', {
      replace: true,
      state: 'Movimentação carteira',
    });
  }, [navigate]);

  const getCabecalhoInfo = useCallback(async () => {
    if (!consultorios?.ativo?.id) return;

    setLoading(true);

    try {
      const response: any = await MovimentacaoCarteiraAPI.getCabecalhoInfo(
        consultorios?.ativo?.id,
        idCuidador,
        { throwError: true },
      );

      setCabecalhoInfo(response);
    } catch (error) {
      handleNavigate();
    } finally {
      setLoading(false);
    }
  }, [consultorios?.ativo?.id, handleNavigate, idCuidador]);

  useEffect(() => {
    getCabecalhoInfo();
  }, [idCuidador, handleNavigate, consultorios?.ativo?.id, getCabecalhoInfo]);

  const onDelete = async (id: number) => {
    modalDeleteDisclosure.open({
      state: { id: id },
    });
  };

  const handleHistory = (id: number) => {
    modalHistorico.open({
      state: { id },
    });
  };

  const handleConfirmDelete = async () => {
    const payload = {
      idConsultorio: consultorios?.ativo?.id,
      idCuidador,
      ids: [modalDeleteDisclosure.state.id],
    };

    const response = await MovimentacaoCarteiraAPI.putExcluirPaciente(payload);

    if (response.status === 200) {
      modalDeleteDisclosure.close();
      getCabecalhoInfo();
    }
  };

  const onCheckBox = (paciente: any) => {
    if (pacienteSelected.some((item: any) => item.id === paciente.id)) {
      const listFiltered = pacienteSelected.filter(
        (item: any) => item.id !== paciente.id,
      );
      setPacienteSelected(listFiltered);
      return;
    }

    setPacienteSelected((prev: any) => [...prev, paciente]);
  };

  const handleCheckAll = () => {
    if (pacienteSelected?.length > 0) {
      setPacienteSelected([]);
      return;
    }

    setPacienteSelected(pacienteList);
  };

  const renderRow = (paciente: any) => (
    <Row
      paciente={paciente}
      pacienteSelected={pacienteSelected}
      onDelete={onDelete}
      onCheckBox={onCheckBox}
      onHistory={handleHistory}
    />
  );

  const fetchAPI = async ({ query, ...rest }: any) => {
    const response = await MovimentacaoCarteiraAPI.getPacientesCarteira(
      consultorios?.ativo?.id,
      idCuidador,
      {
        nome: query,
        sortBy: 'id',
        sortDirection: 'DESC',
        ...rest,
      },
    );

    if ('status' in response) return;

    setPacienteList(response.list);
    return response;
  };

  const checkCoresTags = (total: number, limite: number) => {
    if (!limite || total === null || total === undefined) return;

    const porcentagem = (total / limite) * 100;

    const cores =
      porcentagem <= 90 ? 'verde' : porcentagem <= 100 ? 'laranja' : 'vermelho';

    return cores;
  };

  if (loading) {
    return (
      <Page>
        <>
          <PageHeader
            title="Movimentação carteira APS"
            subtitle="Gerencie a carteira APS"
          />

          <GridListLoading />
        </>
      </Page>
    );
  }

  const title =
    cabecalhoInfo?.equipeCuidadora?.nome ??
    cabecalhoInfo?.profissionalCuidador?.nome;
  const profissionalSaudeDescricao = `${cabecalhoInfo?.profissionalCuidador?.tipoProfissionalSaude} • ${cabecalhoInfo?.profissionalCuidador?.codigoConselho}/${cabecalhoInfo?.profissionalCuidador?.ufConselho} ${cabecalhoInfo?.profissionalCuidador?.numeroRegistro}`;
  const subtitle =
    cabecalhoInfo?.equipeCuidadora?.descricao ?? profissionalSaudeDescricao;

  const checkboxAllChecked =
    pacienteSelected?.length === pacienteList?.length && !!pacienteList?.length;

  const getCheckboxLabel = () => {
    if (checkboxAllChecked) return 'Todos';

    if (!pacienteSelected?.length) return 'Nenhum selecionado';

    return `${pacienteSelected?.length} Selecionados`;
  };

  if (!consultorios?.ativo?.id) {
    return (
      <Page>
        <>
          <PageHeader
            title="Movimentação carteira APS"
            subtitle="Gerencie a carteira APS"
          />
          <div className="p-px-5">
            <AlertBox
              text="Você precisa selecionar um Consultório para esta funcionalidade!"
              visible
            />
          </div>
        </>
      </Page>
    );
  }

  return (
    <Page>
      <>
        <PageHeader
          title="Movimentação carteira APS"
          subtitle="Gerencie a carteira APS"
        />

        <Page content white>
          <div className="FormMovimentacao-container">
            <Button
              className="btn-voltar p-mb-2"
              btnType="gray"
              id="back-button"
              label="Voltar para Movimentação Carteira"
              icon="fa fa-arrow-left"
              onClick={handleNavigate}
            />

            <div className="p-mt-2 p-grid p-col-12 equipe-operatorio p-flex-wrap">
              <div className="p-col-12 p-lg-4">
                <div className="column">
                  <SimpleText
                    bold
                    className="p-mb-2"
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    {title}
                  </SimpleText>

                  <SimpleText
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {subtitle}
                  </SimpleText>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-lg-2 p-d-flex p-ai-center p-flex-column">
                <div
                  className={`total-pacientes ${checkCoresTags(
                    cabecalhoInfo?.totalPacientes,
                    consultorios?.ativo?.limitePacientes,
                  )}`}
                >
                  <SimpleText
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    Total de pacientes: {cabecalhoInfo?.totalPacientes}
                  </SimpleText>
                </div>
                {!!consultorios?.ativo?.limitePacientes && (
                  <SimpleText
                    className="p-text-center p-mt-2"
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Total de permitido: {consultorios?.ativo?.limitePacientes}
                  </SimpleText>
                )}
              </div>

              <div className="p-col-12 p-md-4 p-lg-2 p-d-flex p-ai-center p-flex-column">
                <div
                  className={`total-pontos ${checkCoresTags(
                    cabecalhoInfo?.totalPontos,
                    consultorios?.ativo?.limitePontos,
                  )}`}
                >
                  <SimpleText
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    Total de pontos: {cabecalhoInfo?.totalPontos}
                  </SimpleText>
                </div>
                {!!consultorios?.ativo?.limitePontos && (
                  <SimpleText
                    className="p-text-center p-mt-2"
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Total de permitido: {consultorios?.ativo?.limitePontos}
                  </SimpleText>
                )}
              </div>

              <div className="p-d-flex p-jc-end p-col-12 p-md-4">
                <Button
                  className="btn-transferir-carteira"
                  btnType="tonal"
                  label="Transferir carteira"
                  onClick={() =>
                    modalTransferencia.open({
                      state: { pacientes: null, equipe: cabecalhoInfo },
                    })
                  }
                />
              </div>
            </div>

            <div className="searchList-container">
              <SearchList.Root
                fetchApi={fetchAPI}
                empresaRequired
                reloadWhenEmpresaChange
              >
                <div className="p-grid p-jc-between p-ai-end p-flex-wrap p-mb-3 search-content">
                  <SimpleText
                    className="p-col-12 p-mt-3 p-px-0"
                    bold
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    Pacientes
                  </SimpleText>

                  <SearchList.SearchInput
                    className="p-col-12 p-lg-6 p-xl-4 p-px-0 p-mb-2 p-mb-lg-0"
                    label="Pesquisar paciente"
                    placeholder="Pesquisar pelo paciente"
                  />

                  <AdvancedSearch />
                </div>

                <SearchList.BorderContainer>
                  <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
                    <Button
                      className="p-col-12 p-lg-2 p-px-0 p-mb-2"
                      type="button"
                      label="Incluir paciente"
                      onClick={() =>
                        modalAddPaciente.open({
                          state: {
                            idCuidador,
                          },
                        })
                      }
                    />

                    <SearchList.TotalCount />
                  </div>

                  <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap p-mt-3 p-mb-2">
                    <Checkbox
                      checked={checkboxAllChecked}
                      onChange={handleCheckAll}
                      label={getCheckboxLabel()}
                    />

                    <Button
                      className="p-col-12 p-lg-2 p-px-0 p-mt-2"
                      btnType="tonal"
                      type="button"
                      label="Transferir pacientes"
                      disabled={pacienteSelected?.length <= 0}
                      onClick={() =>
                        modalTransferencia.open({
                          state: {
                            pacientes: pacienteSelected,
                            equipe: cabecalhoInfo,
                          },
                        })
                      }
                    />
                  </div>

                  <SearchList.Header>
                    <Header />
                  </SearchList.Header>

                  <SearchList.InfiniteScroll renderRow={renderRow} />
                </SearchList.BorderContainer>
              </SearchList.Root>
            </div>

            {modalTransferencia.isOpen && (
              <ModalTransferencia
                visible={modalTransferencia.isOpen}
                onHide={() => modalTransferencia.close()}
                pacientes={modalTransferencia.state.pacientes}
                equipe={modalTransferencia.state.equipe}
                reload={getCabecalhoInfo}
              />
            )}

            {modalDeleteDisclosure.isOpen && (
              <ConfirmDialog
                visible={modalDeleteDisclosure.isOpen}
                header="Excluir paciente"
                text="Você confirma a exclusão do paciente?"
                confirmText="Sim, excluir"
                onHide={() => modalDeleteDisclosure.close()}
                onConfirm={handleConfirmDelete}
              />
            )}

            {modalAddPaciente.isOpen && (
              <ModalAddPaciente
                {...modalAddPaciente}
                reload={getCabecalhoInfo}
              />
            )}

            {modalHistorico.isOpen && <ModalHistorico {...modalHistorico} />}
          </div>
        </Page>
      </>
    </Page>
  );
};

export default FormMovimentacao;
