import Http, { HttpParams } from 'src/core/http/Http';

const getAssistencias = (
  params?: any,
  payload?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: '/assistencias/pesquisa',
    params,
    data: payload || {},
    hideToast: true,
    ...options,
  });
};
const getAssistenciasById = (
  id: number | string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/assistencias/${id}`,
    hideToast: true,
    ...options,
  });
};
const getFuncionalidadesAssistencia = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/funcionalidades/com-assistencia',
    hideToast: true,
    ...options,
  });
};
const sendAssistencia = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: '/assistencias',
    data,
    ...options,
  });
};
const updateAssistencia = (
  id: string | number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/assistencias/${id}`,
    data,
    ...options,
  });
};
const mudarStatusAssistencia = (
  idAssistencia: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `/assistencias/${idAssistencia}/mudar-status`,
    ...options,
  });
};
const deleteAssistencia = (
  idAssistencia: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `/assistencias/${idAssistencia}`,
    ...options,
  });
};
const getAssistenciasNaoVisualizadas = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/assistencias/nao-ocultadas`,
    hideToast: true,
    ...options,
  });
};
const sendOcultarAssistencia = (
  idAssistencia: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `/assistencias/${idAssistencia}/ocultar-para-usuario`,
    hideToast: true,
    ...options,
  });
};

export const AssistenciaAPI = {
  getAssistencias,
  getFuncionalidadesAssistencia,
  getAssistenciasById,
  sendAssistencia,
  mudarStatusAssistencia,
  deleteAssistencia,
  updateAssistencia,
  getAssistenciasNaoVisualizadas,
  sendOcultarAssistencia,
};
