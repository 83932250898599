import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  dataUs: Yup.date()
    .typeError('O campo é obrigatório')
    .required('O campo é obrigatório'),
  apresentacao: Yup.string().required('O campo é obrigatório'),
  idadeGestacionalSemanas: Yup.string().required('O campo é obrigatório'),
  idadeGestacionalDias: Yup.number()
    .required('O campo é obrigatório')
    .max(7, 'A quantidade de dias não pode ser maior que "7"')
    .moreThan(-1, 'O valor deve ser positivo')
    .typeError('O campo é obrigatório')
    .nullable(),
});

export default validationSchema;
