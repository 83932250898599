import React, { useState } from 'react';

import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

const label: Record<string, string> = {
  anamnese: 'Anamnese',
  exameFisico: 'Exame físico',
  prognostico: 'Prognóstico',
  decisaoTerapeuta: 'Decisão terapêutica',
  patologiaDetectada: 'Patologia detectada',
};
interface PanelContentProps {
  item: any;
}

const PanelContent = ({ item }: PanelContentProps) => {
  const { tipo, ...rest } = item;
  const definicaoItem = rest;
  const tipoItem = tipo?.toLowerCase().replaceAll(' ', '');

  switch (tipoItem) {
    case 'impressos':
      return <ImpressosContent items={definicaoItem} />;
    case 'motivoatendimentos':
      return <MotivoAtendimentoContent items={definicaoItem} />;
    case 'pedidocuidado':
    case 'pedidodecuidado':
      return <PedidoCuidadoContent items={definicaoItem} />;
    case 'solicitacaoexames':
      return <SolicitacaoExamesContent items={definicaoItem} />;
    case 'campostexto':
      return <CamposTextoContent items={definicaoItem} />;
    default:
      return null;
  }
};

export default PanelContent;

const CamposTextoContent = ({ items }: any) => {
  return (
    <div className="p-d-flex p-flex-column definicao-content">
      {Object.entries(items).map(([key, value]) => (
        <div key={key} className="p-d-flex p-flex-column p-my-1">
          <SimpleText fontSize="xxs" fontColor="color_60">
            {label[key]}
          </SimpleText>
          <SimpleText medium fontColor="color_40">
            {value}
          </SimpleText>
        </div>
      ))}
    </div>
  );
};

const SolicitacaoExamesContent = ({ items }: any) => {
  return (
    <div className="p-grid definicao-content">
      {Object.values(items).map((item: any, index: number) => {
        return (
          <div key={index} className="p-d-flex p-my-1 p-gap-2 p-col-12">
            <div className="p-d-flex p-flex-column p-col-12 p-sm-8">
              <SimpleText fontSize="xxs" fontColor="color_60">
                Nome
              </SimpleText>
              <SimpleText medium fontColor="color_40">
                {item.servicoTuss.nome}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-col-12 p-sm-4">
              <SimpleText fontSize="xxs" fontColor="color_60">
                Quantidade
              </SimpleText>
              <SimpleText medium fontColor="color_40">
                {item.quantidade}
              </SimpleText>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PedidoCuidadoContent = ({ items }: any) => {
  const value = Object.values(items);
  return (
    <div className="p-d-flex p-flex-column definicao-content">
      <SimpleText fontSize="xxs" fontColor="color_60">
        Pedido de cuidado
      </SimpleText>
      <SimpleText medium fontColor="color_40">
        {value[0]}
      </SimpleText>
    </div>
  );
};

const MotivoAtendimentoContent = ({ items }: any) => {
  return (
    <div className="p-d-flex p-flex-column definicao-content">
      {Object.values(items).map((item: any, index: number) => {
        return (
          <div key={index} className="p-d-flex p-flex-column p-my-1 p-gap-2">
            <div className="p-d-flex p-flex-column">
              <SimpleText fontSize="xxs" fontColor="color_60">
                Motivo de atendimento
              </SimpleText>
              <SimpleText medium fontColor="color_40">
                {item.movitoAtendimento?.descricaoTipoMotivo ||
                  item.motivoAtendimento ||
                  ''}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column">
              <SimpleText fontSize="xxs" fontColor="color_60">
                Descrição
              </SimpleText>
              <SimpleText medium fontColor="color_40">
                {item.descricao || ''}
              </SimpleText>
            </div>
            {/* Is last item ? */}
            {index !== Object.values(items).length - 1 && (
              <Separator layout="horizontal" />
            )}
          </div>
        );
      })}
    </div>
  );
};

const ImpressosContent = ({ items }: any) => {
  const values = Object.entries(items);
  const label: Record<string, string> = {
    atestado: 'Atestado',
    receita: 'Receita',
    orientacao: 'Orientação',
  };
  return (
    <div className="p-d-flex p-flex-column definicao-content">
      {values.map((item: any, index: number) => (
        <div key={index} className="p-d-flex p-flex-column p-my-1">
          <SimpleText fontSize="xxs" fontColor="color_60">
            {label[item[0]]}
          </SimpleText>
          <SimpleText medium fontColor="color_40">
            {item[1]}
          </SimpleText>
        </div>
      ))}
    </div>
  );
};
