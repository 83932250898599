import * as Yup from 'yup';

const validationSchema = (camposDemograficosObrigatorios: boolean) => (
  Yup.object().shape({
    email: Yup.string().when('aceitaMalaDireta', {
      is: (aceitaMalaDireta: boolean) => {
        if (camposDemograficosObrigatorios) return true;

        return aceitaMalaDireta;
      },
      then: Yup.string().required('o campo email é obrigatório'),
    }),
    CELULAR_PARTICULAR: Yup.string().when('aceitaWhatsapp', {
      is: true,
      then: Yup.string().required('O campo celular é obrigatório')
    }),
    tipoContatoPrincipal: Yup.string().when(['CELULAR_PARTICULAR', 'RECADO', 'FIXO_RESIDENCIAL', 'CELULAR_COMERCIAL'], {
      is: (CELULAR_PARTICULAR: string, RECADO: string, FIXO_RESIDENCIAL: string, CELULAR_COMERCIAL: string) => {
        if (camposDemograficosObrigatorios) return !(CELULAR_PARTICULAR || RECADO || FIXO_RESIDENCIAL || CELULAR_COMERCIAL)

        return false;
      },
      then: Yup.string().required('Um número de contato é obrigatório')
    })
  })
);

export default validationSchema