import { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';
import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';

import { useCreateProfissionalSaude } from 'src/core/hooks/ManterUsuario/useCreateProfissionalSaude';
import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

import ModalAddConvenio from './ModalAddConvenio/ModalAddConvenio';
import ModalAddEspecialidade from './ModalAddEspecialidade/ModalAddEspecialidade';

interface FormMasterCreateUserProfProps {
  //onSubmit(v: any): void;
  handleBack(): void;
  nextStep(): void;
}

interface FormButtonsProps {
  registerProfissionalExecutante: boolean;
}

const FormMasterCreateUserProf = ({
  handleBack,
  nextStep,
}: FormMasterCreateUserProfProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    dadosProfissionais,
    checkedProfissionalExecutante,
    setStep3,
    setDadosProfissionaisStepCompleted,
    setCheckedProfissionalExecutante,
  } = useCreateProfissionalSaude();
  const { idUser } = useCreateUser();

  const [loading, setLoading] = useState<boolean>(true);
  const [modalEspecialidade, setModalEspecialidade] = useState<any>(false);
  const [modalConvenio, setModalConvenio] = useState<any>(false);
  const [convenioEditData, setConvenioEditData] = useState<any>(null);
  const [especialidadesList, setEspecialidadesList] = useState<any>(
    dadosProfissionais?.listaEspecialidade || [],
  );
  const [conveniosList, setConveniosList] = useState<any>(
    dadosProfissionais?.listaConfigTiss || [],
  );
  const [especialidades, setEspecialidades] = useState<any>([]);
  const [cbos, setCbos] = useState<any>([]);

  console.log('conveniosList: ', conveniosList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadPage = useCallback(async () => {
    const [especialidadesRes, cboRes] = await Promise.all([
      EspecialidadeAPI.loadEspecialidades({
        pageSize: 9999,
      }),
      ProfissionalAPI.retrieveBuscaCbo(),
    ]);

    setEspecialidades(especialidadesRes?.list);
    setCbos(cboRes);
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const saveInfoProfissionais = async (finishFormAfterSaver = true) => {
    if (conveniosList !== undefined && especialidadesList !== undefined) {
      const aux: any = {
        cadastrarProfissionalExecutante: false,
        listaEspecialidade: especialidadesList,
        listaConfigTiss: conveniosList,
      };

      setStep3([conveniosList, especialidadesList]);

      if (checkedProfissionalExecutante) {
        setDadosProfissionaisStepCompleted(true);
        return nextStep();
      }

      const res: any = await ProfissionalAPI.updateDadosProfissionais(
        idUser,
        aux,
      );

      if (res?.status === 200) {
        if (finishFormAfterSaver) return navigate('/cadastros/usuario');

        setDadosProfissionaisStepCompleted(true);

        nextStep();
      }
    }
  };

  const handleRemoveEspecialidade = useCallback(
    (idx: any) => {
      const especs = especialidadesList;

      especs.splice(idx, 1);

      setEspecialidadesList(especs);
      setModalEspecialidade(false);
    },
    [especialidadesList],
  );

  const getEspecialidadeNome = useCallback(
    (especialidadeId: number) => {
      const especialidadeDados = especialidades.find(
        (especialidade: any) => especialidade.id === especialidadeId,
      );

      return especialidadeDados?.nome || '';
    },
    [especialidades],
  );

  const RenderList = useCallback(
    () =>
      especialidadesList?.map((e: any, idx: any) => (
        <div key={idx} className="p-grid p-col-12 itemprof ">
          <div className="p-col columnprof">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Nome
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_0} fontSize={FONT_SIZE.XXS}>
              {getEspecialidadeNome(Number(e?.idEspecialidade))}
            </SimpleText>
          </div>

          <div className="p-col-12 p-sm-2 columnprof">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Ano
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_0} fontSize={FONT_SIZE.XXS}>
              {e?.anoRenovacao}
            </SimpleText>
          </div>

          <div className="p-col-12 p-sm-2 p-d-flex p-jc-sm-end p-ai-center">
            <Button
              type="button"
              icon="pi pi-trash"
              btnType="gray"
              onClick={() =>
                setEspecialidadesList((oldEspecialidadeList: any) =>
                  oldEspecialidadeList.filter(
                    (especialidade: any) =>
                      especialidade.idEspecialidade !== e.idEspecialidade,
                  ),
                )
              }
            />
          </div>
        </div>
      )),
    [especialidadesList, getEspecialidadeNome],
  );

  const renderListConvenios = useCallback(
    () =>
      conveniosList?.map((e: any, idx: any) => (
        <div key={idx} className="p-grid p-col-12 itemprof p-wordbreak-all">
          <div className="p-col columnprof">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Nome do prestador
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_0} fontSize={FONT_SIZE.XXS}>
              {e?.nomeContratado}
            </SimpleText>
          </div>

          <div className="p-col-12 p-sm-3 columnprof">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Código
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_0} fontSize={FONT_SIZE.XXS}>
              {e?.codigoContratado}
            </SimpleText>
          </div>

          <div className="p-col-12 p-sm-3 p-gap-2 p-d-flex p-jc-sm-end p-ai-center">
            <Button
              type="button"
              icon="pi pi-pencil"
              btnType="gray"
              onClick={() => {
                setModalConvenio(true);
                setConvenioEditData(e);
              }}
            />

            <Button
              type="button"
              icon="pi pi-trash"
              btnType="gray"
              onClick={() =>
                setConveniosList((oldConvenioList: any) =>
                  oldConvenioList.filter((convenio: any) => convenio !== e),
                )
              }
            />
          </div>
        </div>
      )),
    [conveniosList],
  );

  const FinishRegisterButtons = () => (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Deseja preencher os dados opcionais?{' '}
      </SimpleText>
      <div className="p-col-12 p-md-6">
        <Button
          btnType="outline"
          label="Sim, preencher"
          className="p-col-align-center p-d-flex"
          type="button"
          onClick={() => saveInfoProfissionais(false)}
          stretch
        />
      </div>
      <div className="p-col-12 p-md-6">
        <Button
          label="Não, concluir cadastro"
          type="button"
          onClick={() => {
            setLoading(true);
            saveInfoProfissionais();
          }}
          stretch
        />
      </div>
    </>
  );

  const SaveAndContinueButtons = () => (
    <>
      <div className="p-col-12 p-md-6">
        <Button
          btnType="ghost"
          label="Voltar"
          className={'p-col-align-center p-d-flex'}
          type="button"
          onClick={() => handleBack()}
          stretch
        />
      </div>
      <div className="p-col-12 p-md-6">
        <Button
          label={'Salvar'}
          btnType="tonal"
          type="button"
          onClick={() => {
            setLoading(true);
            saveInfoProfissionais();
          }}
          stretch
        />
      </div>
    </>
  );

  const SaveAndCancelButtons = () => (
    <>
      <div className="p-col-12 p-md-6">
        <Button
          btnType="ghost"
          label="Cancelar"
          className={'p-col-align-center p-d-flex'}
          type="button"
          onClick={() => navigate('/cadastros/usuario')}
          stretch
        />
      </div>
      <div className="p-col-12 p-md-6">
        <Button
          label={'Salvar'}
          btnType="tonal"
          type="button"
          onClick={() => {
            setLoading(true);
            saveInfoProfissionais(false);
          }}
          stretch
        />
      </div>
    </>
  );

  const FormButtons = ({
    registerProfissionalExecutante,
  }: FormButtonsProps) => {
    if (!!params?.id) return <SaveAndCancelButtons />;

    if (registerProfissionalExecutante) return <SaveAndContinueButtons />;

    return <FinishRegisterButtons />;
  };

  return (
    <>
      <form className="p-grid p-col-12 p-px-0">
        <div className="p-col-12 p-lg-6">
          <SimpleText className="p-col-12 p-px-0" bold>
            Lista de especialidade
          </SimpleText>

          <div className="listas p-col-12 p-mt-2">
            <div className="contentlistas">
              <SimpleText
                className="p-col-12"
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Adicione as especialidades atendidas pelo profissional
              </SimpleText>

              <div className="itemslistas">
                <RenderList />
              </div>
            </div>

            <Button
              stretch
              btnType="tonal"
              label={'Adicionar especialidades'}
              type="button"
              onClick={() => {
                setModalEspecialidade(true);
              }}
            />
          </div>
        </div>

        <div className="p-col-12 p-lg-6">
          <SimpleText className="p-col-12 p-px-0" bold>
            Lista de Convênios e Configuração TISS
          </SimpleText>

          <div className="listas p-col-12 p-mt-2">
            <div className="contentlistas">
              <SimpleText
                className="p-col-12"
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Adicione os convênios e Configurações TISS
              </SimpleText>

              <div className="itemslistas">{renderListConvenios()}</div>
            </div>
            <Button
              stretch
              btnType="tonal"
              label={'Adicionar convênio'}
              type="button"
              onClick={() => {
                setModalConvenio(true);
              }}
            />
          </div>
        </div>

        <div className="p-col-12">
          <div className="profexecutante p-d-flex p-flex-column p-flex-sm-row p-ai-center p-jc-between">
            <div className="columnprofexecutante p-text-center p-text-sm-left p-mb-3 p-mb-sm-0">
              <SimpleText>
                Cadastrar profissional/prestador executante
              </SimpleText>
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                Ative para profissionais que realizam procedimentos.
              </SimpleText>
            </div>

            <Switch
              checked={checkedProfissionalExecutante}
              onChange={(t: any) => {
                setCheckedProfissionalExecutante(
                  !checkedProfissionalExecutante,
                );
              }}
              showStatus
            />
          </div>
        </div>

        <FormButtons
          registerProfissionalExecutante={checkedProfissionalExecutante}
        />
      </form>

      <ModalAddEspecialidade
        especialidades={especialidades}
        cbos={cbos}
        addEspecialidade={async (e: any) => {
          const especsList = especialidadesList;
          especsList.push(e);
          setEspecialidadesList(especsList);
          setModalEspecialidade(false);
        }}
        visible={modalEspecialidade}
        onHide={() => {
          setModalEspecialidade(false);
        }}
      />

      {modalConvenio ? (
        <ModalAddConvenio
          addConvenio={async (e: any) => {
            const convsList = conveniosList;
            convsList.push(e);
            setConveniosList(convsList);
            setModalConvenio(false);
          }}
          editConvenio={(convenioData: any) => {
            const convenioEditedIndex = conveniosList.findIndex(
              (convenio: any) => convenio === convenioEditData,
            );

            setConveniosList((oldConvenioList: any) =>
              oldConvenioList.map((convenio: any, index: number) =>
                index === convenioEditedIndex ? convenioData : convenio,
              ),
            );

            setModalConvenio(false);
            setConvenioEditData(null);
          }}
          visible={modalConvenio}
          convenioEditData={convenioEditData}
          onHide={() => {
            setModalConvenio(false);
            setConvenioEditData(null);
          }}
        />
      ) : null}
    </>
  );
};

export default FormMasterCreateUserProf;
