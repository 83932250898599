import { ChangeEvent, useEffect, useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

import './EditRoom.scss';

interface EditRoomProps {
  visible: boolean;
  onHide(): void;
  handleEdit(): void;
  initialValue: Sala;
  setRoomSelected: any;
}

const EditRoom = ({
  visible,
  onHide,
  handleEdit,
  initialValue,
  setRoomSelected,
}: EditRoomProps) => {
  const [nameRoom, setNameRoom] = useState('');

  useEffect(() => {
    if (initialValue) {
      setNameRoom(initialValue.nome);
    }
  }, [initialValue]);

  const handleChange = (t: ChangeEvent<HTMLInputElement>) => {
    setNameRoom(t.target.value);

    setRoomSelected({
      ...initialValue,
      nome: t.target.value,
    });
  };

  return (
    <Dialog
      type="modal"
      header="Editar Sala"
      visible={visible}
      onHide={onHide}
      maximizedMobileSize
    >
      <>
        <TextInput
          label="Nome da Sala"
          value={nameRoom}
          onChange={handleChange}
          className={'p-mt-3'}
        />
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button btnType="ghost" label="Cancelar" onClick={onHide} stretch />
          <Button label="Confirmar" onClick={handleEdit} stretch />
        </div>
      </>
    </Dialog>
  );
};

export default EditRoom;
