import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const EmptyData = () => {
  return (
    <SimpleText
      fontColor={FONT_COLOR.COLOR_60}
      className="p-col-12 p-py-2 p-text-center p-line-height-2"
      medium
    >
      Dados não encontrados! <br />
      Este paciente não possui histórico para ser apresentado.
    </SimpleText>
  );
};
