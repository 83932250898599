import dayjs from 'dayjs';
import * as Yup from 'yup';

export const formValidation = Yup.object().shape({
  titulo: Yup.string().nullable(),

  mensagem: Yup.string().required('O campo mensagem é obrigatório.'),

  textoLink: Yup.string()
    .when('adicionarLink', {
      is: true,
      then: Yup.string()
        .required('O campo texto do link é obrigatório.')
        .nullable(),
    })
    .nullable(),

  linkExterno: Yup.string()
    .when('adicionarLink', {
      is: true,
      then: Yup.string().required('O campo link é obrigatório.'),
    })
    .nullable(),

  dataInicio: Yup.date()
    .required('O campo data de início é obrigatório.')
    .min(
      dayjs(new Date()).format('YYYY-MM-DD'),
      'A data de início deve ser igual ou maior que a data atual.',
    )
    .nullable(),

  dataFim: Yup.date()
    .when('dataInicio', (values, _fields) => {
      return Yup.date()
        .min(
          dayjs(values).format('YYYY-MM-DD'),
          'A data de fim deve ser igual ou maior que a data de início.',
        )
        .nullable();
    })
    .nullable(),
});
