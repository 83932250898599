import { useFormContext } from 'react-hook-form';

import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const Contato = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <TextInputControlled
        className="p-col-12"
        control={control}
        name="email"
        label="E-mail"
        placeholder="exemplo@teste.com.br"
        errorMsg={errors.email?.message}
      />

      <MaskedInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="CELULAR_PARTICULAR"
        label="Celular"
        placeholder="(99) 99999-9999"
        mask={MASK.PHONE}
      />
      <MaskedInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="RECADO"
        label="Telefone para recados"
        placeholder="(99) 99999-9999"
        mask={MASK.PHONE}
      />
      <MaskedInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="FIXO_RESIDENCIAL"
        label="Telefone residencial"
        placeholder="(99) 9999-9999"
        mask={MASK.TELEPHONE}
      />
      <MaskedInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="CELULAR_COMERCIAL"
        label="Telefone comercial"
        placeholder="(99) 99999-9999"
        mask={MASK.PHONE}
      />

      {errors.contato?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors.contato?.message}
        </SimpleText>
      )}
    </>
  );
}

export default Contato;