import { useEffect, useState, useCallback } from 'react';

import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { InputText } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import { useAgenda } from '../../AgendaContext';

import './Token.scss';

interface TokenProps {
  onHide: () => void;
  idEspecialidade: any;
  idSala: any;
  cartaoSelected?: any;
}

const Token = ({
  onHide,
  idEspecialidade,
  idSala,
  cartaoSelected,
}: TokenProps) => {
  const { consultorios, agenda } = useAppSelector((state: RootState) => state);
  const { agendamento } = useAgenda();

  const [primeiroNumero, setPrimeiroNumero] = useState('');

  const handleValidateTokenSigilo = useCallback(async () => {
    const data: any = {
      flagsAtendimentos: agendamento?.flagsAtivas,
      status: 'PRESENTE',
      token: primeiroNumero,
      idEmpresa: consultorios?.ativo?.idEmpresa,
      tipoValidacao: 'TOKEN',
      idEspecialidade,
      idMedico: agenda?.profissionalAtivo?.id,
      idConsultorio: consultorios?.ativo?.id,
      idPaciente: agendamento?.idPaciente,
      idSala,
    };

    if (cartaoSelected) {
      data.numeroCartao = cartaoSelected?.numeroCartao;
      data.idConvenio = cartaoSelected?.convenio?.id;
    }

    await AutorizacaoAPI.realizarPresenca(agendamento.id, data, {
      throwError: true,
    })
      .then(() => {
        setPrimeiroNumero('');
        onHide();
      })
      .catch((error: any) => console.log({ error }));
  }, [
    agendamento?.flagsAtivas,
    agendamento?.idPaciente,
    agendamento.id,
    primeiroNumero,
    consultorios?.ativo?.idEmpresa,
    consultorios?.ativo?.id,
    idEspecialidade,
    agenda?.profissionalAtivo?.id,
    idSala,
    cartaoSelected,
    onHide,
  ]);

  useEffect(() => {
    if (primeiroNumero.length === 10) {
      handleValidateTokenSigilo();
    }
  }, [primeiroNumero, handleValidateTokenSigilo]);

  return (
    <div className="token-container">
      <SimpleText fontColor={FONT_COLOR.COLOR_40} className={'p-mb-4'}>
        Solicite e insira o código que o paciente recebeu no aplicativo.
      </SimpleText>

      <div className="box-number">
        <InputText
          value={primeiroNumero}
          onChange={t => {
            setPrimeiroNumero(t.target.value);
          }}
          maxLength={10}
          type="number"
          className="p-col-6 p-p-0"
        />
      </div>
    </div>
  );
};

export default Token;
