import { useCallback, useEffect, useState } from 'react';

import { DropdownChangeParams } from 'primereact/dropdown';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import Dropdown, {
  DropdownProps,
} from 'src/components/Basics/Dropdown/Dropdown';

type FieldConvenioByPacienteProps = DropdownProps & {
  idConvenio: number | null | undefined;
  name?: string;
  label: string;
  params?: any;
  idPaciente: number | undefined;
  onChange: (event: DropdownChangeParams) => void;
};

export const FieldConvenioByPaciente = ({
  idConvenio,
  name = 'convenio',
  label,
  idPaciente,
  onChange,
  ...rest
}: FieldConvenioByPacienteProps) => {
  const [convenios, setConvenios] = useState<Convenio[]>([]);
  const [loading, setLoading] = useState(true);

  const loadConvenios = useCallback(async () => {
    if (!idPaciente) return;

    try {
      setLoading(true);

      const response = await ConvenioAPI.getConveniosByPaciente(idPaciente, {
        throwError: true,
      });

      setConvenios(response);
    } catch (error) {
      setConvenios([]);
    } finally {
      setLoading(false);
    }
  }, [idPaciente]);

  useEffect(() => {
    loadConvenios();
  }, [loadConvenios]);

  const conveniosOptions = convenios.map(convenio => ({
    label: convenio?.nomeFantasia || convenio.razaoSocial,
    value: convenio.id,
  }));

  return (
    <Dropdown
      panelClassName="panel-convenio"
      name={name}
      label={label}
      options={conveniosOptions}
      value={idConvenio}
      onChange={onChange}
      loading={loading}
      {...rest}
    />
  );
};
