import { useState } from 'react';

interface SelectedProcedimentosSolicitadosType {
  guiasSelected: number[];
  allGuiaSelected: boolean;
  handleSelectAllGuias: (checked: boolean) => void;
  handleSelectGuia: (checked: boolean, numeroGuia: number) => void;
  checkGuiaIsSelected: (numeroGuia: number) => boolean;
  getGuiasSelectedLabel: () => string;
}

export const useSelectedProcedimentosSolicitados = ({
  guiasProcedimentosSolicitados,
}: any): SelectedProcedimentosSolicitadosType => {
  const [guiasSelected, setGuiasSelected] = useState<any[]>([]);

  const allGuiaSelected = !!guiasSelected.length
    ? guiasSelected.length === guiasProcedimentosSolicitados.length
    : false;

  const handleSelectAllGuias = (checked: boolean) => {
    setGuiasSelected(
      checked ? guiasProcedimentosSolicitados.map((guia: any) => guia[0]) : [],
    );
  };

  const handleSelectGuia = (checked: boolean, numeroGuia: number) => {
    setGuiasSelected(prev =>
      checked
        ? [...prev, numeroGuia]
        : prev.filter(guiaSelected => guiaSelected !== numeroGuia),
    );
  };

  const checkGuiaIsSelected = (numeroGuia: number) =>
    guiasSelected.some(numeroGuiaSelected => numeroGuiaSelected === numeroGuia);

  const getGuiasSelectedLabel = () => {
    if (guiasSelected.length === 0) return 'Nenhuma guia selecionada';

    return `${guiasSelected.length} Selecionada(s)`;
  };

  return {
    allGuiaSelected,
    guiasSelected,
    handleSelectAllGuias,
    handleSelectGuia,
    checkGuiaIsSelected,
    getGuiasSelectedLabel,
  };
};
