import { useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { FormProvider, useForm } from 'react-hook-form';

import { FolhaRostoAPI } from 'src/APIs/ProntuarioAPI/FolhaRosto/FolhaRostoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import {
  useLocalStorage,
  useSessionStorage,
} from 'src/utils/hooks/useLocalStorage';

import {
  FieldCuidadorViveBem,
  FieldEnfermeiroCuidador,
  FieldMeuProfissional,
} from './components';
import { DialogMotivoTransferencia } from './components/DialogMotivoTransferencia';
import { FieldComplexidadePaciente } from './components/FieldComplexidadePaciente';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldEquipeCuidadora } from 'src/components/Fields/FieldEquipeCuidadora/FieldEquipeCuidadora';
import { FieldMedicoCuidador } from 'src/components/Fields/FieldMedicoCuidador/FieldMedicoCuidador';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './FolhaRosto.scss';

interface FolhaRostoProntuarioProps extends DisclosureType {
  idPaciente: number | null;
}

export const FolhaRostoProntuario = ({
  idPaciente,
}: FolhaRostoProntuarioProps) => {
  const { setValue, storedValue, removeStoredValue } = useLocalStorage(
    'accordion-folha-rosto',
    undefined,
  );

  return (
    <div id="folha-rosto-prontuario">
      <Accordion
        activeIndex={storedValue}
        onTabOpen={() => setValue(0)}
        onTabClose={() => removeStoredValue()}
      >
        <AccordionTab
          key={0}
          header={<SimpleText>Folha Rosto</SimpleText>}
          headerClassName="accordion-header"
          contentClassName="accordion-content"
        >
          <FolhaRostoProntuarioContent idPaciente={idPaciente} />
        </AccordionTab>
      </Accordion>
    </div>
  );
};

interface FolhaRostoProntuarioContentProps {
  idPaciente: number | null;
}

const FolhaRostoProntuarioContent = ({
  idPaciente,
}: FolhaRostoProntuarioContentProps) => {
  const { storedValue } = useSessionStorage('folhaRostoProntuario');
  const {
    consultorios: { ativo },
  } = useAppSelector((state: RootState) => state);
  const { windowInnerWidth } = useSize();

  const [folhaRosto, setFolhaRosto] = useState<any>(null);

  const motivoDialog = useDisclosure({ opened: false });

  const defaultValues = useMemo(() => {
    return {
      equipeCuidadora: folhaRosto?.carteiraAps?.equipeCuidadora,
      medicoCuidador: folhaRosto?.carteiraAps?.profissionalCuidador,
      meuProfissional: folhaRosto?.meuMedico,
      enfermeiroCuidador: folhaRosto?.enfermeiroCuidador,
      cuidadorViverBem: folhaRosto?.medicoCuidadorViverBem,
      complexidadePaciente: folhaRosto?.complexidadePaciente,
    };
  }, [folhaRosto]);

  const form = useForm({
    defaultValues,
  });

  const isLarge = useMemo(() => windowInnerWidth >= 1250, [windowInnerWidth]);

  const showViverBem = !!storedValue?.exibirFolhaRosto;
  const className =
    isLarge && showViverBem ? 'fixed p-mb-2' : `p-sm-6 p-md-4 p-lg-3 p-col-12`;

  const fetchApi = useCallback(() => {
    FolhaRostoAPI.loadFolhasRostosPaciente(Number(idPaciente), ativo?.id, {
      throwError: true,
    })
      .then(data => !!data && setFolhaRosto(data))
      .catch(() => null);
  }, [idPaciente, ativo?.id]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  useEffectSkipFirst(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const onSubmit = useCallback(
    (values: any) => {
      FolhaRostoAPI.updateFolhasRostosPaciente({
        idPaciente: Number(idPaciente),
        id: folhaRosto?.id,
        idConsultorio: ativo?.id,
        idEnfermeiroCuidador: values.enfermeiroCuidador?.id || null,
        idMeuMedico: values.meuProfissional?.id || null,
        idMedicoCuidadorViverBem: values.cuidadorViverBem?.id || null,
        idComplexidadePaciente: values.complexidadePaciente?.id || null,
      }).then(() => fetchApi());
    },
    [idPaciente, ativo?.id, fetchApi, folhaRosto?.id],
  );

  const isEquipeCuidadora = ativo.tipoMonitoramentoCarteiraAps === 'EQUIPE';
  const cuidadorFieldName = isEquipeCuidadora
    ? 'equipeCuidadora'
    : 'medicoCuidador';
  const cuidador = form.watch(cuidadorFieldName);

  const handleChangeCuidador = async (value: any) => {
    const novoCuidador = form.getValues(cuidadorFieldName);

    if (!!cuidador && !!value)
      return motivoDialog.open({
        state: {
          idPaciente: Number(idPaciente),
          idConsultorio: ativo?.id,
          idCuidadorOrigem: cuidador?.id || null,
          idCuidadorDestino: novoCuidador?.id || null,
          reloadFolhaRosto: fetchApi,
        },
      });

    const payload = {
      idCuidador: novoCuidador?.id || null,
    };

    await FolhaRostoAPI.postFolhaRostoCuidador(
      ativo?.id,
      Number(idPaciente),
      payload,
    );
    fetchApi();
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`p-grid p-col-12 p-p-0 ${
          isLarge && showViverBem ? 'p-gap-1 p-jc-between' : ''
        }`}
      >
        {isEquipeCuidadora ? (
          <FieldEquipeCuidadora
            idConsultorio={ativo?.id}
            name="equipeCuidadora"
            label="Equipe cuidadora"
            className={className + ' light'}
            onChange={e => [handleChangeCuidador(e)]}
            showClear
            showTooltip
          />
        ) : (
          <FieldMedicoCuidador
            idConsultorio={ativo?.id}
            name="medicoCuidador"
            label="Médico cuidador"
            className={className + ' light'}
            showClear
            onChange={handleChangeCuidador}
          />
        )}
        <FieldMeuProfissional
          name="meuProfissional"
          label="Meu Profissional"
          className={className + ' light'}
          onChange={() => onSubmit(form.getValues())}
          showClear
        />
        <FieldEnfermeiroCuidador
          idConsultorio={ativo?.id}
          name="enfermeiroCuidador"
          label="Enfermeiro Cuidador"
          className={className + ' light'}
          onChange={() => onSubmit(form.getValues())}
          showClear
        />
        {showViverBem && (
          <FieldCuidadorViveBem
            idConsultorio={ativo?.id}
            name="cuidadorViverBem"
            label="Cuidador Viver Bem"
            className={className + ' light'}
            onChange={() => onSubmit(form.getValues())}
            showClear
          />
        )}
        <FieldComplexidadePaciente
          idConsultorio={ativo?.id}
          name="complexidadePaciente"
          label="Complexidade Paciente"
          className={className + ' light'}
          onChange={() => onSubmit(form.getValues())}
          labelTooltip={TooltipComplexidade()}
          showClear
        />
      </form>

      {motivoDialog.isOpen && <DialogMotivoTransferencia {...motivoDialog} />}
    </FormProvider>
  );
};

const TooltipComplexidade = () => {
  return (
    <>
      <i
        className="fas fa-info"
        id="complexidade-paciente-folha-rosto"
        data-pr-position="top"
      />
      <Tooltip target="#complexidade-paciente-folha-rosto">
        A complexidade do estado de doença do paciente aqui definida irá
        interferir na pontuação do paciente em sua carteira
      </Tooltip>
    </>
  );
};
