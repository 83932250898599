import { memo, useCallback, useEffect, useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import SearchListServicoTuss from './SearchListServicoTuss';

import './SearchListServicoTuss.scss';

interface FormDialogProps {
  visible: boolean;
  onHide(): void;
  onSelect(i: ServicoTuss[]): void;
  initialValue: ServicoTuss[] | undefined;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType={'ghost'} label="Cancelar" onClick={onCancel} stretch />
      <Button label="Salvar" onClick={onAdd} type="button" stretch />
    </div>
  );
});

const SearchServicoTuss = (props: FormDialogProps) => {
  const { visible, onHide, onSelect } = props;

  const [selected, setSelected] = useState<ServicoTuss[]>([]);

  const handleAdd = useCallback(() => {
    onSelect(selected);
  }, [onSelect, selected]);

  const handleSelectItem = useCallback((item: ServicoTuss) => {
    setSelected(v => {
      if (v.find(i => i?.id === item?.id)) return v;
      return [...v, item].sort((a, b) => (a?.nome < b?.nome ? -1 : 1));
    });
  }, []);

  const handleRemoveItem = useCallback((item: ServicoTuss) => {
    setSelected(v => {
      if (!v.find(i => i?.id === item?.id)) return v;
      return v.filter(i => i?.id !== item?.id);
    });
  }, []);

  useEffect(() => {
    setSelected(props.initialValue || []);
  }, [props.initialValue, visible]);

  return (
    <Dialog
      type="modal"
      className="footer-on"
      header="Adicionar serviço TUSS"
      visible={visible}
      onHide={onHide}
      footer={() => <Footer onAdd={handleAdd} onCancel={onHide} />}
      maximizedTabletSize
    >
      <div className="SearchServicoTuss">
        <Tab
          underHeader
          clean
          values={[
            {
              label: 'Exames e cirurgia',
              content: (
                <SearchListServicoTuss
                  selected={selected}
                  onSelectItem={handleSelectItem}
                  onRemoveItem={handleRemoveItem}
                />
              ),
            },
          ]}
        />
      </div>
    </Dialog>
  );
};

export default memo(SearchServicoTuss);
