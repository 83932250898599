const getAttributeNames = (node: any) => {
  const rv = [];
  const attributes = node.attributes;

  for (let i = 0; i < attributes.length; i++) {
    rv.push(attributes[i]?.nodeName);
  }

  rv.sort();
  return rv;
};

//  Compare two elements for equality.  Even will compare if the style element
//  is out of order for example:
// elem1 = style = 'color: red; font-size: 28px';
// elem2 = style = 'font-size: 28px; color: red';

export const isEqualElement = (elm1: any, elm2: any) => {
  let name, node1, node2;

  // Compare attributes without order sensitivity
  const attrs1 = getAttributeNames(elm1);
  const attrs2 = getAttributeNames(elm2);
  if (attrs1.join(',') !== attrs2.join(',')) {
    // console.log("Found nodes with different sets of attributes; not equiv");
    return false;
  }

  // ...and values
  // unless you want to compare DOM0 event handlers
  // (onclick="...")
  for (let index = 0; index < attrs1.length; ++index) {
    name = attrs1[index];
    if (name === 'style') {
      const astyle: any = elm1.style;
      const bstyle: any = elm2.style;
      const rexDigitsOnly = /^\d+$/;
      for (const key of Object.keys(astyle)) {
        if (!rexDigitsOnly.test(key) && astyle[key] !== bstyle[key]) {
          // Not equivalent, stop
          //console.log("Found nodes with mis-matched values for attribute '" + name + "'; not equiv");
          return false;
        }
      }
    } else {
      if (
        elm1.getAttribute(name as string) !== elm2.getAttribute(name as string)
      ) {
        // console.log("Found nodes with mis-matched values for attribute '" + name + "'; not equiv");
        return false;
      }
    }
  }

  // Walk the children
  for (
    node1 = elm1.firstChild, node2 = elm2.firstChild;
    node1 && node2;
    node1 = node1.nextSibling, node2 = node2.nextSibling
  ) {
    if (node1.nodeType !== node2.nodeType) {
      // display("Found nodes of different types; not equiv");
      return false;
    }
    if (node1.nodeType === 1) {
      // Element
      if (!isEqualElement(node1, node2)) {
        return false;
      }
    } else if (node1.nodeValue !== node2.nodeValue) {
      // console.log("Found nodes with mis-matched nodeValues; not equiv");
      return false;
    }
  }
  if (node1 || node2) {
    // One of the elements had more nodes than the other
    // console.log("Found more children of one element than the other; not equivalent");
    return false;
  }

  // Seem the same
  return true;
};
