import * as Yup from 'yup';

const msgRequired = 'Campo é obrigatório';

export const validationSchema = Yup.object({
  id: Yup.number().notRequired(),
  medicamentosQuimioterapia: Yup.array(
    Yup.object({
      codigoProcedimento: Yup.string().nullable(),
      descricao: Yup.string().nullable(),
      unidadeMedida: Yup.object().required(msgRequired),
      viaAdm: Yup.object().required(msgRequired),
      dataPrevistaAdministracao: Yup.date()
        .required(msgRequired)
        .typeError('Informe uma data válida'),
      dose: Yup.number().required(msgRequired),
      frequencia: Yup.number().required(msgRequired),
    }),
  )
    .min(1, 'É obrigatório informar ao menos um medicamento.')
    .required(msgRequired),
  dadosBeneficiario: Yup.object({
    peso: Yup.number().required(msgRequired).typeError(msgRequired),
    altura: Yup.number().required(msgRequired).typeError(msgRequired),
    superficie: Yup.string(),
  }).required('É necessário prencher os dados do beneficiário'),
  diagnosticoOncologico: Yup.object({
    dataDiagnostico: Yup.date()
      .notRequired()
      .nullable()
      .typeError('Informe uma data válida'),
    estadiamento: Yup.object().required(msgRequired),
    tipoQuimio: Yup.object().required(msgRequired),
    finalidade: Yup.object().required(msgRequired),
    ecog: Yup.object().required(msgRequired),
    tumor: Yup.object().required(msgRequired),
    nodulo: Yup.object().required(msgRequired),
    metastase: Yup.object().required(msgRequired),
    planoTerapeutico: Yup.string().required(msgRequired),
    diagnostico: Yup.string().notRequired().nullable(),
    informacoesRelevantes: Yup.string().notRequired().nullable(),
  }).required('É necessário prencher o diagnostico oncológico'),
  ciclo: Yup.object({
    ciclosPrevistos: Yup.number().required(msgRequired).typeError(msgRequired),
    cicloAtual: Yup.number().required(msgRequired).typeError(msgRequired),
    intervaloCiclos: Yup.number().required(msgRequired).typeError(msgRequired),
    diasCicloAtual: Yup.number().required(msgRequired).typeError(msgRequired),
  }),
  tratamentosAnteriores: Yup.object({
    cirurgia: Yup.string().notRequired().nullable(),
    areaIrradiada: Yup.string().notRequired().nullable(),
    dataAplicacao: Yup.date()
      .notRequired()
      .typeError('Informe uma data válida'),
    dataRealizacao: Yup.date()
      .notRequired()
      .typeError('Informe uma data válida'),
  }),
  observacoesJustificativa: Yup.string().notRequired().nullable(),
});

export const handleCalculoSuperficieCorporal = (
  peso: number,
  altura: number,
) => {
  const superficieCorporal = Math.sqrt((altura * peso) / 3600);
  return superficieCorporal.toFixed(2);
};

export const handlePayloadSubmit = (
  values: typeof validationSchema.__outputType,
  id: number,
  numeroGuiaTiss: number,
) => {
  return {
    id: values?.id || undefined,
    idSolicitacaoExame: id,
    numeroGuiaTiss,
    //* MedicamentosQuimio
    medicamentoQuimioterapia: values.medicamentosQuimioterapia.map(value => ({
      ...value,
      codigoUnidadeMedida: value.unidadeMedida.codigo,
      codigoViaAdministracaoQuimio: value.viaAdm.codigo,
    })),
    //* Dados Beneficiário
    peso: values.dadosBeneficiario.peso,
    altura: values.dadosBeneficiario.altura,
    superficieCorporal: values.dadosBeneficiario.superficie,
    //* Diagnostico Oncológico
    dataDiagnostico: values.diagnosticoOncologico?.dataDiagnostico,
    codigoEstadiamento: values.diagnosticoOncologico.estadiamento.codigo,
    codigoTipoQuimioterapia: values.diagnosticoOncologico.tipoQuimio.codigo,
    codigoFinalidadeTratamento: values.diagnosticoOncologico.finalidade.codigo,
    codigoEcog: values.diagnosticoOncologico.ecog.codigo,
    codigoTerminologiaTumor: values.diagnosticoOncologico.tumor.codigo,
    codigoTerminologiaNodulo: values.diagnosticoOncologico.nodulo.codigo,
    codigoTerminologiaMetastase: values.diagnosticoOncologico.metastase.codigo,
    planoTerapeutico: values.diagnosticoOncologico.planoTerapeutico,
    diagnosticoCitoHisp: values.diagnosticoOncologico.diagnostico,
    informacoesRelevantes: values.diagnosticoOncologico.informacoesRelevantes,
    //* Ciclo
    numeroCicloPrevisto: values.ciclo.ciclosPrevistos,
    numeroCicloAtual: values.ciclo.cicloAtual,
    intervaloEntreCiclos: values.ciclo.intervaloCiclos,
    diasCicloAtual: values.ciclo.diasCicloAtual,
    //* Tratamentos anteriores
    cirurgia: values.tratamentosAnteriores.cirurgia,
    dataRealizacao: values.tratamentosAnteriores.dataRealizacao,
    areaIrradiada: values.tratamentosAnteriores.areaIrradiada,
    dataAplicacao: values.tratamentosAnteriores.dataRealizacao,
    //* Observação / Justificativa
    observacao: values.observacoesJustificativa,
  };
};
export const handleDefaultValues = (values: any) => {
  return {
    ...values,
    medicamentosQuimioterapia: values.medicamentoQuimioterapia.map(
      (item: any) => ({
        ...item,
        dataPrevistaAdministracao: new Date(item.dataPrevistaAdministracao),
        unidadeMedida: { codigo: item.codigoUnidadeMedida },
        viaAdm: { codigo: item.codigoViaAdministracaoQuimio },
      }),
    ),
    dadosBeneficiario: {
      altura: values.altura,
      peso: values.peso,
      superficie: values.superficieCorporal,
    },
    diagnosticoOncologico: {
      dataDiagnostico: new Date(values.dataDiagnostico),
      estadiamento: { codigo: values.codigoEstadiamento },
      tipoQuimio: { codigo: values.codigoTipoQuimioterapia },
      finalidade: { codigo: values.codigoFinalidadeTratamento },
      ecog: { codigo: values.codigoEcog },
      tumor: { codigo: values.codigoTerminologiaTumor },
      nodulo: { codigo: values.codigoTerminologiaNodulo },
      metastase: { codigo: values.codigoTerminologiaMetastase },
      planoTerapeutico: values.planoTerapeutico,
      diagnostico: values.diagnosticoCitoHisp,
      informacoesRelevantes: values.informacoesRelevantes,
    },
    ciclo: {
      ciclosPrevistos: values.numeroCicloPrevisto,
      cicloAtual: values.numeroCicloAtual,
      intervaloCiclos: values.intervaloEntreCiclos,
      diasCicloAtual: values.diasCicloAtual,
    },
    tratamentosAnteriores: {
      cirurgia: values.cirurgia,
      areaIrradiada: values.areaIrradiada,
      dataAplicacao: values.dataAplicacao
        ? new Date(values.dataAplicacao)
        : undefined,
      dataRealizacao: values.dataRealizacao
        ? new Date(values.dataRealizacao)
        : undefined,
    },
    observacoesJustificativa: values.observacao,
  };
};
