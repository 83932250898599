import dayjs from 'dayjs';

import { TelemedicinaAtendimentoProps } from 'src/core/contexts/Telemedicina/Telemedicina';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const validateMessageTelemedicina = (
  atendimentoData: TelemedicinaAtendimentoProps,
  isFinalizada?: boolean,
) => {
  if (!atendimentoData?.idAtendimento) return;

  const dataAtual = dayjs().format('DD/MM/YYYY');
  const dataAgendamento = dayjs(atendimentoData?.dataAgendamento).format(
    'DD/MM/YYYY',
  );

  if (
    dataAgendamento === dataAtual &&
    !isFinalizada &&
    atendimentoData.statusAtendimento !== 'ATENDIDO'
  ) {
    return (
      <>
        <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.LG}>
          Aguarde até ser chamado para a teleconsulta...
        </SimpleText>
        <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.MD}>
          {
            'Dentro de instantes um profissional entrará na sala e dará prosseguimento ao seu atendimento!'
          }
        </SimpleText>
      </>
    );
  }

  if (dataAgendamento > dataAtual) {
    return (
      <>
        <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.LG}>
          Prezado {atendimentoData.nomePaciente}, a sua teleconsulta está
          agendada para o dia{' '}
          {dayjs(atendimentoData?.dataAgendamento).format(
            'dddd[,] DD [de] MMM[. de] YYYY[, às] HH:mm',
          )}
        </SimpleText>

        {atendimentoData.flagsAtendimento.includes('CONFIRMADO') ? (
          <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.MD}>
            {
              'O termo de consentimento está aceito, então no dia da consulta acesse este mesmo link e aguarde ser chamado. Até lá!!'
            }
          </SimpleText>
        ) : (
          <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.MD}>
            {
              'No dia da consulta acesse este mesmo link e aguarde ser chamado. Até lá!!'
            }
          </SimpleText>
        )}
      </>
    );
  }

  return (
    <>
      <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.LG}>
        Sua teleconsuta acabou...
      </SimpleText>
      <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.MD}>
        {'Pode contar conosco, sempre que precisar. Até, mais!  : )'}
      </SimpleText>
    </>
  );
};
