import { useCallback } from 'react';

import { InputSwitchChangeParams } from 'primereact/inputswitch';

import SalaAPI from 'src/APIs/AdminAPI/ConsultorioAPI/SalaAPI';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

import './RoomsItems.scss';

interface RoomsItemsProps {
  text: string;
  index: number;
  checked: boolean;
  setChecked: any;
  onEdit(): void;
  onDelete(): void;
  roomSelected: Sala;
  roomList: Sala[];
}

const RoomsItems = ({
  text,
  index,
  checked,
  onEdit,
  onDelete,
  setChecked,
  roomSelected,
  roomList,
}: RoomsItemsProps) => {
  const handleIsActive = useCallback(
    (t: InputSwitchChangeParams) => {
      const salaEditada = {
        ...roomSelected,
        ativo: !roomSelected.ativo,
      };

      roomList.splice(index, 1, salaEditada);

      setChecked(t.target.value);

      const consultorioId = roomSelected.consultorio.id.toString();

      SalaAPI.updateSala(consultorioId, salaEditada);

      SalaAPI.loadSalasByConsultorioId(consultorioId);
    },
    [roomSelected, roomList, index, setChecked],
  );

  return (
    <div className="roomsItems p-d-flex p-flex-column p-flex-sm-row">
      <SimpleText fontSize={FONT_SIZE.XS}>{text}</SimpleText>

      <div className="roomsOptions p-mt-2 p-mt-sm-0">
        <Switch
          checked={checked}
          onChange={t => handleIsActive(t)}
          showStatus
        />

        <Divider layout={'vertical'} />

        <div className="p-d-flex p-gap-2">
          <Button icon="fas fa-edit" btnType="gray" onClick={onEdit} />
          <Button icon="fas fa-trash" btnType="gray" onClick={onDelete} />
        </div>
      </div>
    </div>
  );
};

export default RoomsItems;
