import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/grupo-tuss';

export const loadGrupoTuss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadGrupoTussById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendGrupoTuss = (
  payload: PostGrupoTussDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateGrupoTuss = (
  id: number,
  payload: PostGrupoTussDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateGrupoTussAtivar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/ativar`,
    return: true,
    ...options,
  });
};

export const updateGrupoTussInativar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/inativar`,
    return: true,
    ...options,
  });
};

export const removeGrupoTussById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

const GrupoTussAPI = {
  loadGrupoTuss,
  loadGrupoTussById,
  updateGrupoTuss,
  updateGrupoTussAtivar,
  updateGrupoTussInativar,
  removeGrupoTussById,
  sendGrupoTuss,
};

export default GrupoTussAPI;
