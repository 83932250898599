import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

import './PanelHeader.scss';
import ModalJustificativa from '../ModalJustificativa/ModalJustificativa';

interface PanelHeaderProps {
  vacina: PacientesVacinas;
  optionsMenu: JSX.Element;
  isCollapsed: boolean;
  onToggle: () => void;
  onNovaDose: () => void;
  reloadList: () => void;
}

function PanelHeader({
  vacina,
  optionsMenu,
  isCollapsed,
  onToggle,
  onNovaDose,
  reloadList,
}: PanelHeaderProps) {
  const { isTablet, isMobile } = useSize();
  const { atendimentoStatus } = useAtendimento();

  const modalJustificativa = useDisclosure({ opened: false });

  const isInativo = vacina.status === 'INATIVO';

  const headerTextClassName = isInativo ? 'vacina-inativa' : '';
  const dataTextClassName = `p-mt-2 ${
    isInativo ? 'line-through vacina-inativa' : ''
  }`;

  return (
    <div className="vacina-panel-header p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-6 p-md-2 p-xl-3 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {vacina.descricao}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-md-2 p-d-flex p-flex-column ">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(vacina.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-md-2 p-xl-1 retroativo-chip">
        {!!vacina?.justificativaOcorrenciaRetroativa && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>

      <div className="coluna-dados p-col-4 p-md-2 p-d-flex p-flex-column p-ai-end">
        {!!vacina?.dataProximaDose && (
          <>
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Próxima Dose
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {dayjs(vacina.dataProximaDose)
                .locale('pt-br')
                .format('DD MMM[.] YYYY')}
            </SimpleText>
          </>
        )}
      </div>

      <div className="coluna-dados p-col p-d-flex p-flex-column p-ai-md-end p-ai-start">
        {!isInativo && (
          <Button
            stretch
            type="button"
            btnType="tonal"
            icon="fas fa-plus"
            title="Adicionar dose"
            onClick={onNovaDose}
            disabled={
              atendimentoStatus !== 'ATENDENDO' &&
              atendimentoStatus !== 'ATENDENDO_TRIAGEM'
            }
          />
        )}
      </div>
      <div className="coluna-acoes p-col p-d-flex p-gap-2 p-ai-center p-jc-end">
        {!isInativo && <Lembrete ativo={vacina.lembrete} />}

        {isInativo && (
          <Button
            icon="fas fa-eye"
            btnType="gray"
            onClick={() => modalJustificativa.open()}
          />
        )}

        {optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>

      {modalJustificativa.isOpen && (
        <ModalJustificativa
          onHide={modalJustificativa.close}
          onSuccess={reloadList}
          visualizarJustificativa={vacina}
          idVacina={vacina.id}
          {...modalJustificativa}
        />
      )}
    </div>
  );
}

export default PanelHeader;
