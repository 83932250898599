import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';
interface PanelHeaderProps {
  isCollapsed: boolean;
  motivoAtendimento: MotivoAtendimento;
  optionsMenu: JSX.Element;
  onToggle: () => void;
}

const PanelHeader = ({
  isCollapsed,
  optionsMenu,
  onToggle,
  motivoAtendimento,
}: PanelHeaderProps) => {
  const headerTextClassName =
    motivoAtendimento.status === 'INATIVO' ? 'p-mt-2 motivo-inativo' : 'p-mt-2';

  return (
    <div className="p-grid p-col-12 p-align-center">
      <div className="p-col-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Motivo
        </SimpleText>
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {motivoAtendimento.tipoMotivoAtendimento.descricao}
        </SimpleText>
      </div>

      <div className="p-col-4 p-sm-2 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(motivoAtendimento.dataOcorrencia).format('DD/MM/YYYY  HH:mm')}
        </SimpleText>
      </div>

      <div className="p-col-2 retroativo-chip">
        {motivoAtendimento.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>

      <div className="p-col p-d-flex p-jc-between p-jc-sm-end p-ai-center p-gap-2">
        <div className="p-mr-2">
          <Lembrete ativo={motivoAtendimento.lembrete} />
        </div>

        {optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};

export default PanelHeader;
