import { createContext } from 'react';

export interface CreateUserData {
  mandatoryData: any;
  idUser: number | null, 
  setIdUser: (id: number) => void;
  saveMandatoryData: (newData: any) => void;
  setMandatoryData: (data: any) => void;
}

export const CreateUserContext = createContext(
  {} as CreateUserData
);
