import { memo } from 'react';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';

import './ViewModal.scss';
import { DisclosureType } from 'src/utils/hooks/useDisclosure';

interface FooterProps {
  onCancel(): void;
  onPrint(): void;
}

const Footer = memo(({ onCancel, onPrint }: FooterProps) => {
  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Fechar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button btnType="tonal" label={'Imprimir'} onClick={onPrint} stretch />
    </div>
  );
});

function ViewModal({ state, close, isOpen }: DisclosureType) {
  const content = state;
  function onPrint() {
    const termIframe = document?.getElementById(
      'telemedicine-term-content',
    ) as HTMLIFrameElement;

    termIframe?.contentWindow?.focus();
    termIframe?.contentWindow?.print();
  }

  return (
    <Dialog
      className="telemedicine-term-view-modal"
      onHide={close}
      visible={isOpen}
      header="Visualizar termo de consetimento"
      footer={() => <Footer onPrint={onPrint} onCancel={close} />}
    >
      <div style={{ width: '60vw', height: '60vh' }}>
        <iframe
          id="telemedicine-term-content"
          title="Visualizar termo de consetimento"
          name="telemedicine-term-content"
          width={'100%'}
          height={'100%'}
          srcDoc={content}
        />
      </div>
    </Dialog>
  );
}

export default ViewModal;
