import { useMemo, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import { useAgenda } from '../../AgendaContext';

import './AlterarHorarioBloco.scss';

const recorrenciaOptions = [
  { value: 'DIARIA', label: 'Diário' },
  { value: 'SEMANAL', label: 'Semanal' },
  { value: 'MENSAL', label: 'Mensal' },
];

const recorrenciaSemanalOptions = [
  { value: 'SEGUNDA', label: 'Segunda-feira' },
  { value: 'TERCA', label: 'Terça-feira' },
  { value: 'QUARTA', label: 'Quarta-feira' },
  { value: 'QUINTA', label: 'Quinta-feira' },
  { value: 'SEXTA', label: 'Sexta-feira' },
  { value: 'SABADO', label: 'Sábado' },
  { value: 'DOMINGO', label: 'Domingo' },
];

const recorrenciaMensalSemanasOptions = [
  { value: 'PRIMEIRA', label: 'Primeira' },
  { value: 'SEGUNDA', label: 'Segunda' },
  { value: 'TERCEIRA', label: 'Terceira' },
  { value: 'QUARTA', label: 'Quarta' },
];

const AlterarHorarioBloco = ({ close }: DisclosureType) => {
  const { refetchAgenda } = useAgenda();
  const { agenda, consultorios } = useAppSelector((state: RootState) => state);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'age_alterar_horarios_em_bloco',
  });

  const validatioSchema = Yup.object().shape({
    status: Yup.string().required('O campo status é obrigatório'),
    recorrencia: Yup.string()
      .nullable()
      .required('O campo padrão de recorrência é obrigatório'),
    recorrenciaDias: Yup.string()
      .nullable()
      .required('O campo à cada é obrigatório.'),
    dataInicial: Yup.date()
      .nullable()
      .required('O campo data de inicio é obrigatório.'),
    horaInicial: Yup.string()
      .nullable()
      .required('O campo hora inicial é obrigatório.'),
    dataFinal: Yup.date()
      .when('dataInicial', (dataInicial, schema) => {
        if (dataInicial) {
          const diaFinal = new Date(dataInicial.getTime() - 86400000);
          return schema.min(
            diaFinal,
            'A data final deve ser maior que a data inicial.',
          );
        }
        return schema;
      })
      .nullable()
      .required('O campo data final é obrigatório.'),
    horaFinal: Yup.string()
      .nullable()
      .required('O campo hora final é obrigatório.'),
    recorrenciaSemanal: Yup.string()
      .nullable()
      .when('recorrencia', (recorrencia, field) =>
        recorrencia === 'SEMANAL'
          ? field.required('O campo dia da semana é obrigatório')
          : field,
      ),
    bloqueado_descricao: Yup.string()
      .nullable()
      .when('status', (status, field) =>
        status === 'BLOQUEADO'
          ? field.required('O campo motivo do bloqueio é obrigatório')
          : field,
      ),
    internetOptions: Yup.string()
      .nullable()
      .when('status', (status, field) =>
        status === 'INTERNET'
          ? field.required('O campo tipo de agendamento online é obrigatório')
          : field,
      ),
    recorrenciaMensal: Yup.string()
      .nullable()
      .when('recorrencia', (recorrencia, field) =>
        recorrencia === 'MENSAL'
          ? field.required('O campo tipo de seleção das datas é obrigatório')
          : field,
      ),
    recorrenciaMensalDiaEspecifico: Yup.string()
      .nullable()
      .when('recorrenciaMensal', (recorrenciaMensal, field) =>
        recorrenciaMensal === 'MENSAL'
          ? field.required('O campo lista semana é obrigatório')
          : field,
      ),
    recorrenciaMensalSemanaEspecifico: Yup.string()
      .nullable()
      .when('recorrenciaMensal', (recorrenciaMensal, field) =>
        recorrenciaMensal === 'MENSAL'
          ? field.required('O campo lista dia da semana é obrigatório')
          : field,
      ),
    recorrenciaMensalEspecifico: Yup.string()
      .nullable()
      .when('recorrenciaMensal', (recorrenciaMensal, field) =>
        recorrenciaMensal === 'ESPECIFICO'
          ? field.required('O campo dia específico do mês é obrigatório')
          : field,
      ),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const handleCamposNull = (value: any) => {
    return value ? value : null;
  };

  const onSubmit = async (value?: any) => {
    const dataInicial = dayjs(value.dataInicial).format('YYYY-MM-DD');
    const horaInicial = dayjs(value.horaInicial).format('HH:mm:ss');

    const dataFinal = dayjs(value.dataFinal).format('YYYY-MM-DD');
    const horaFinal = dayjs(value.horaFinal).format('HH:mm:ss');

    const {
      status,
      recorrenciaDias,
      internetOptions,
      recorrencia = null,
      bloqueado_descricao = null,
      recorrenciaMensalEspecifico = null,
      recorrenciaMensalSemanaEspecifico = null,
    } = value;

    const flags = [];

    internetOptions === 'PADRAO' && flags.push('INTERNET');
    internetOptions === 'RETORNO' &&
      Array.prototype.push.apply(flags, ['INTERNET', 'RETORNO']);
    internetOptions === 'TELECONSULTA' &&
      Array.prototype.push.apply(flags, ['INTERNET', 'TELECONSULTA']);
    status === 'CENTRAL' && flags.push('CENTRAL_AGENDAMENTO');

    const statusFormatted = status === 'BLOQUEADO' ? 'BLOQUEADO' : 'LIVRE';

    const payload = {
      idMedico: agenda?.profissionalAtivo?.id,
      idConsultorio: consultorios.ativo?.id,
      status: statusFormatted,
      flags,
      tipoRecorrencia: recorrencia,
      observacao: bloqueado_descricao,
      intervalo: Number(recorrenciaDias),
      diaEspecifico: recorrenciaMensalEspecifico,
      semanaMes: recorrenciaMensalSemanaEspecifico,
      dataInicio: `${dataInicial}T${horaInicial}-03:00`,
      dataFim: `${dataFinal}T${horaFinal}-03:00`,
      diaSemana: handleCamposNull(
        value.recorrenciaSemanal
          ? value.recorrenciaSemanal
          : value.recorrenciaMensalDiaEspecifico,
      ),
    };

    const response = await AgendamentosAPI.putAlterarHorarioBloco(payload);

    if (response?.status === 200) {
      refetchAgenda();
      close();
    }
  };

  const statusWatch = watch('status');
  const recorrenciaWatch = watch('recorrencia');
  const recorrenciaMensalWatch = watch('recorrenciaMensal');
  const dataInicialWatch = watch('dataInicial');

  useEffect(() => {
    if (statusWatch !== 'INTERNET') {
      setValue('internetOptions', null);
    }

    if (statusWatch !== 'BLOQUEADO') {
      setValue('bloqueado_descricao', '');
    }
  }, [setValue, statusWatch]);

  useEffect(() => {
    if (recorrenciaWatch !== 'MENSAL') {
      setValue('recorrenciaMensal', null);
    }

    if (recorrenciaWatch !== 'SEMANAL') {
      setValue('recorrenciaSemanal', null);
    }
  }, [recorrenciaWatch, setValue]);

  useEffect(() => {
    if (recorrenciaMensalWatch !== 'MENSAL') {
      setValue('recorrenciaMensalSemanaEspecifico', null);
      setValue('recorrenciaMensalDiaEspecifico', null);
    }

    if (recorrenciaMensalWatch !== 'ESPECIFICO') {
      setValue('recorrenciaMensalEspecifico', '');
    }
  }, [recorrenciaMensalWatch, setValue]);

  const recorrenciaCadaPlaceholder = useMemo(() => {
    if (recorrenciaWatch === 'SEMANAL') return 'Semanas';

    if (recorrenciaWatch === 'MENSAL') return 'Meses';

    return 'Dias';
  }, [recorrenciaWatch]);

  return (
    <Dialog
      header={'Alterar horários em bloco'}
      visible
      onHide={close}
      maskClassName="alterar-horarios-bloco-dialog"
      maximizedTabletSize
    >
      <div>
        <form
          id="form-alterar-horario"
          className="p-grid"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Status
          </SimpleText>

          <div
            className={`p-col-12 p-d-flex p-flex-column p-flex-md-row ${
              statusWatch === 'LIVRE' ? 'p-pb-0' : ''
            }`}
          >
            <RadioButtonControlled
              className="p-mr-md-3"
              control={control}
              name="status"
              label="Livre"
              value="LIVRE"
            />
            <RadioButtonControlled
              className="p-mr-md-3"
              control={control}
              name="status"
              label="Bloqueado"
              value="BLOQUEADO"
            />
            <RadioButtonControlled
              className="p-mr-md-3"
              control={control}
              name="status"
              label="Internet"
              value="INTERNET"
            />
            <RadioButtonControlled
              control={control}
              name="status"
              label="Central de Agendamento"
              value="CENTRAL"
            />
          </div>

          {errors.status?.message && (
            <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
              {errors.status?.message}
            </SimpleText>
          )}

          {statusWatch === 'LIVRE' && (
            <div className="p-col-12 p-pt-0 p-mb-2">
              <AlertBox
                text="Ao selecionar o status 'Livre', todas as flags serão desmarcadas."
                visible
              />
            </div>
          )}

          {statusWatch === 'BLOQUEADO' && (
            <TextareaInputControlled
              className="p-col-12"
              control={control}
              name="bloqueado_descricao"
              label="Motivo do bloqueio"
              errorMsg={errors?.bloqueado_descricao?.message}
            />
          )}

          {statusWatch === 'INTERNET' && (
            <>
              <SimpleText
                className="p-col-12"
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                Liberar internet
              </SimpleText>
              <div className="p-col-12 p-d-flex p-flex-column p-flex-md-row">
                <RadioButtonControlled
                  className="p-mr-md-3"
                  control={control}
                  name="internetOptions"
                  label="Consulta Padrão"
                  value="PADRAO"
                />
                <RadioButtonControlled
                  className="p-mr-md-3"
                  control={control}
                  name="internetOptions"
                  label="Retorno"
                  value="RETORNO"
                />
                <RadioButtonControlled
                  control={control}
                  name="internetOptions"
                  label="Teleconsulta"
                  value="TELECONSULTA"
                />
              </div>

              {errors.internetOptions?.message && (
                <SimpleText
                  className="error p-col-12"
                  fontSize={FONT_SIZE.XXXS}
                >
                  {errors.internetOptions?.message}
                </SimpleText>
              )}
            </>
          )}

          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Defina o padrão de recorrência
          </SimpleText>

          <SelectButtonControlled
            className="p-col-12"
            control={control}
            name="recorrencia"
            options={recorrenciaOptions}
            errorMsg={errors?.recorrencia?.message}
          />

          <TextInputControlled
            className="p-col-12"
            control={control}
            name="recorrenciaDias"
            label="À Cada"
            placeholder={recorrenciaCadaPlaceholder}
            permitirApenasNumeros
            errorMsg={errors?.recorrenciaDias?.message}
          />

          {recorrenciaWatch === 'SEMANAL' && (
            <DropdownControlled
              className="p-col-12"
              control={control}
              name="recorrenciaSemanal"
              options={recorrenciaSemanalOptions}
              label="Dia da semana"
              placeholder="Selecione"
              errorMsg={errors?.recorrenciaSemanal?.message}
            />
          )}

          {recorrenciaWatch === 'MENSAL' && (
            <>
              <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
                Tipo
              </SimpleText>
              <div className="p-col-12 p-sm-6">
                <RadioButtonControlled
                  control={control}
                  name="recorrenciaMensal"
                  label="Dia específico"
                  value="ESPECIFICO"
                />
              </div>
              <div className="p-col-12 p-sm-6">
                <RadioButtonControlled
                  control={control}
                  name="recorrenciaMensal"
                  label="Dia da semana"
                  value="MENSAL"
                />
              </div>

              {errors.recorrenciaMensal?.message && (
                <SimpleText
                  className="error p-col-12"
                  fontSize={FONT_SIZE.XXXS}
                >
                  {errors.recorrenciaMensal?.message}
                </SimpleText>
              )}
            </>
          )}

          {recorrenciaMensalWatch === 'MENSAL' && (
            <>
              <DropdownControlled
                className="p-col-12 p-md-6"
                control={control}
                name="recorrenciaMensalSemanaEspecifico"
                label="Na semana"
                options={recorrenciaMensalSemanasOptions}
                placeholder="Selecione"
                errorMsg={errors?.recorrenciaMensalSemanaEspecifico?.message}
              />
              <DropdownControlled
                className="p-col-12 p-md-6"
                control={control}
                name="recorrenciaMensalDiaEspecifico"
                label="Dia"
                options={recorrenciaSemanalOptions}
                placeholder="Selecione"
                errorMsg={errors?.recorrenciaMensalDiaEspecifico?.message}
              />
            </>
          )}

          {recorrenciaMensalWatch === 'ESPECIFICO' && (
            <TextInputControlled
              className="p-col-12"
              control={control}
              name="recorrenciaMensalEspecifico"
              label="Dia"
              permitirApenasNumeros
              errorMsg={errors?.recorrenciaMensalEspecifico?.message}
            />
          )}

          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Defina o período da recorrência
          </SimpleText>

          <CalendarInputControlled
            control={control}
            className="p-col-12 p-md-6"
            name="dataInicial"
            label="Data de início"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
            errorMsg={errors?.dataInicial?.message}
          />

          <CalendarInputControlled
            control={control}
            className="p-col-12 p-md-6"
            name="horaInicial"
            label="Hora de início"
            dateFormat="dd/mm/yy"
            showIcon
            showTime
            mask="99:99"
            timeOnly
            icon="fas fa-clock"
            errorMsg={errors?.horaInicial?.message}
          />

          <CalendarInputControlled
            control={control}
            className="p-col-12 p-md-6"
            name="dataFinal"
            label="Data final"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={dataInicialWatch || new Date()}
            errorMsg={errors?.dataFinal?.message}
          />

          <CalendarInputControlled
            control={control}
            className="p-col-12 p-md-6"
            name="horaFinal"
            label="Hora final"
            dateFormat="dd/mm/yy"
            showIcon
            showTime
            mask="99:99"
            timeOnly
            icon="fas fa-clock"
            errorMsg={errors?.horaFinal?.message}
          />

          <div
            id="alterar-horario-btns"
            className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1"
          >
            <Button
              type="button"
              btnType="ghost"
              label="Cancelar"
              onClick={() => close()}
              loading={isSubmitting}
              stretch
            />
            <Button
              type="submit"
              label="Salvar"
              loading={isSubmitting}
              stretch
            />
          </div>
        </form>

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </Dialog>
  );
};

export default AlterarHorarioBloco;
