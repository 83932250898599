import React, { useEffect } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import Agendamentos from './Agendamentos/Agendamentos';

import './ModalHistoricoAgendamento.scss';

const ModalHistoricoAgendamento = ({ close, state }: DisclosureType) => {
  return (
    <Dialog
      id="modal-historico-agendamento"
      className="footer-on p-h-100"
      type="modal"
      header="Histórico do paciente"
      visible={true}
      onHide={close}
      footer={
        <Button
          btnType="outline"
          label="Fechar"
          className="p-col-align-center"
          onClick={() => close()}
          stretch
        />
      }
    >
      <Tab
        values={[
          {
            label: 'Agendamento',
            content: <Agendamentos agendamento={state} />,
          },
          // {
          //   label: 'Programas',
          //   content: <Programas agendamento={agendamento} />,
          // },
        ]}
      />
    </Dialog>
  );
};

export default ModalHistoricoAgendamento;
