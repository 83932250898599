import { useState } from 'react';

import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { handleOpenPDF } from 'src/utils/files';

import { Page } from '../../../_components/Page/Page';
import { Button } from 'src/components/_UI';
import { InputText } from 'src/components/Basics/Input';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import {
  MeusAgendamentosProximosRow,
  MeusAgendamentosProximosRowMobile,
} from './MeusAgendamentosProximosRow';

import './MeusAgendamentosProximos.scss';

export const MeusAgendamentosProximos = () => {
  const { isMobile } = useSize();
  const { idPaciente } = useAppSelector(state => state.user);
  const [isPrinting, setIsPrinting] = useState(false);

  const [refetch, setRefetch] = useState(0);

  const fetchData = ({ query, ...params }: any) => {
    return AgendamentosAPI.getAgendaPaciente({
      id: idPaciente,
      filtroAtendimentoEnum: 'FUTUROS',
      profissional: query,

      ...params,
    });
  };

  const handlePrint = async () => {
    setIsPrinting(true);
    const res = await RelatoriosAPI.postRelatorioPortalMeusAtendimentos({
      idPaciente,
      filtroAtendimentoEnum: 'FUTUROS',
    });

    handleOpenPDF(res, 'Relatorio_atendimentos');
    setIsPrinting(false);
  };

  const refetchApi = () => {
    setRefetch(prev => prev + 1);
  };

  return (
    <Page white content className="p-py-4">
      <div id="MeusAgendamentosProximos">
        <SearchList.Root fetchApi={fetchData}>
          <MeusAgendamentosProximosHeader />

          <SearchList.BorderContainer>
            <div className="p-col-12">
              <Button
                label="Imprimir"
                icon="fas fa-print"
                className="p-ml-auto"
                btnType="tonal"
                stretch={!!isMobile}
                onClick={handlePrint}
                loading={isPrinting}
              />
            </div>

            <SearchList.InfiniteScroll
              autoHeight
              reload={refetch}
              renderRows={items =>
                items.map((item: any) => {
                  return isMobile ? (
                    <MeusAgendamentosProximosRowMobile
                      refetch={refetchApi}
                      item={item}
                    />
                  ) : (
                    <MeusAgendamentosProximosRow
                      refetch={refetchApi}
                      item={item}
                    />
                  );
                })
              }
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
    </Page>
  );
};

const MeusAgendamentosProximosHeader = () => {
  const [value, setValue] = useState('');
  const { setQuery } = useSearchList();

  return (
    <div className="p-grid p-d-flex p-col-12 p-px-0">
      <InputText
        icon="fas fa-search"
        label="Pesquisar"
        iconPos="left"
        className="input-search p-col-8 p-sm-9 p-md-5 p-px-0"
        placeholder="Pesquise pelo nome do profissional"
        onChange={e => setValue(e.target.value)}
        value={value}
        hideTextErrorSpace
      />
      <div className="p-col p-d-flex p-ai-center p-mt-3">
        <Button
          btnType="tonal"
          type="submit"
          label="Pesquisar"
          onClick={() => setQuery(value)}
        />
      </div>
    </div>
  );
};
