import { useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { PanelHeaderTemplateOptions } from 'primereact/panel';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Panel from 'src/components/Panel/Panel';

import { statusHistoricoOptions } from '../../helpers';

import './PanelSolicitacaoRecente.scss';

interface PanelSolicitacaoRecenteProps {
  procedimento: any;
  showActionsBtns?: boolean;
  onUploadLaudo?: () => void;
  onOpenParecer?: () => void;
}

export const PanelSolicitacaoRecente = (
  props: PanelSolicitacaoRecenteProps,
) => {
  const {
    showActionsBtns = true,
    procedimento,
    onUploadLaudo,
    onOpenParecer,
  } = props;
  const menuEllipsis = useRef<TieredMenu>(null);

  const { user } = useAppSelector((state: RootState) => state);

  const { atendimentoStatus } = useAtendimento();

  const disabledWhenAtendimentoStatusIsNotAtendendo =
    atendimentoStatus !== 'ATENDENDO';
  const disabledWhenProfissionalSaudeIsNotOwner =
    procedimento.idUsuarioAtualizacao !== user.idUsuario;

  const { isLarge } = useSize();

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down'
      : 'pi pi-chevron-up';

    const columnClassName = isLarge ? 'p-col-12 p-sm-4 p-md-3' : 'p-col';
    const actionColumnClassName = isLarge ? 'p-col-12' : 'p-col';

    const formatDate = (date: string) => {
      if (!date) return '-';

      return dayjs(date).format('DD/MM/YYYY');
    };

    const ActionsBtns = () => {
      if (!showActionsBtns) return null;

      if (isLarge) {
        const menuEllipsisItens: MenuItem[] = [
          {
            label: 'Anexo laudo',
            icon: 'fas fa-paperclip',
            command: onUploadLaudo,
          },
          {
            label: 'Parecer laudo',
            icon: 'fas fa-file-medical',
            command: onOpenParecer,
          },
          {
            label: 'Laudo importado',
            icon: 'fas fa-file-import',
            disabled: true,
          },
        ];

        return (
          <>
            <Button
              icon="fas fa-ellipsis-h"
              btnType="gray"
              onClick={event => menuEllipsis?.current?.toggle(event)}
              disabled={
                disabledWhenAtendimentoStatusIsNotAtendendo ||
                disabledWhenProfissionalSaudeIsNotOwner
              }
            />

            <TieredMenu
              className="solicitacao-exame-tiered-menu"
              ref={menuEllipsis}
              model={menuEllipsisItens}
              popup
            />
          </>
        );
      }

      return (
        <>
          <Divider layout="vertical" />
          <Button
            btnType="green-link"
            icon="fas fa-paperclip"
            onClick={onUploadLaudo}
            disabled={
              disabledWhenAtendimentoStatusIsNotAtendendo ||
              disabledWhenProfissionalSaudeIsNotOwner
            }
          />
          <Button
            btnType="green-link"
            icon="fas fa-file-medical"
            onClick={onOpenParecer}
            disabled={
              disabledWhenAtendimentoStatusIsNotAtendendo ||
              disabledWhenProfissionalSaudeIsNotOwner
            }
          />
          <Button btnType="green-link" icon="fas fa-file-import" disabled />
        </>
      );
    };

    return (
      <div className="solicitacao-recente-header-panel p-grid p-col-12 p-p-0 p-align-center">
        {!isLarge && <i className="p-mx-3 fas fa-lock-open" />}

        <div className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Solicitação
          </SimpleText>

          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {formatDate(
              procedimento.dataSolicitacao ?? procedimento.dataInclusao,
            )}
          </SimpleText>
        </div>

        <div className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Status
          </SimpleText>

          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {getStatusLabel(procedimento.situacaoExame)}
          </SimpleText>
        </div>

        {procedimento.situacaoLocal === 'I' && (
          <div
            className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}
          >
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Tipo
            </SimpleText>

            <SimpleText fontColor={FONT_COLOR.COLOR_40}>Internação</SimpleText>
          </div>
        )}

        <div className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            {procedimento.situacaoLocal === 'I'
              ? 'Data internação'
              : 'Data resultado'}
          </SimpleText>

          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {formatDate(
              procedimento.situacaoLocal === 'I'
                ? procedimento.dataSugerInternacao
                : procedimento?.dataResultado,
            )}
          </SimpleText>
        </div>

        <div className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            CID
          </SimpleText>

          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {procedimento.cidIndicacaoClinica || '-'}
          </SimpleText>
        </div>

        <div className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Origem
          </SimpleText>

          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {procedimento?.idSolicitacaoProcedimento ? 'eMed' : 'Externo'}
          </SimpleText>
        </div>

        <div className={`${columnClassName} p-order-1 p-d-flex p-flex-column`}>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Solicitante
          </SimpleText>

          <SimpleText
            className="p-text-truncate"
            fontColor={FONT_COLOR.COLOR_40}
          >
            {procedimento?.idSolicitacaoProcedimento
              ? procedimento.nomeMedicoInclusao
              : procedimento.nomeMedico}
          </SimpleText>
        </div>

        <div
          className={`${actionColumnClassName} p-order-0 p-order-lg-2 p-d-flex p-ai-center p-jc-between p-jc-lg-end`}
        >
          <div>
            {isLarge && <i className="p-mx-3 fas fa-lock-open" />}

            <SimpleText
              className={`tag tag-${procedimento.status}`}
              fontSize={FONT_SIZE.XXS}
            >
              {capitalizeFirstLetter(procedimento.status || '')}
            </SimpleText>
          </div>

          <div className="p-d-flex p-ai-center">
            <ActionsBtns />

            <Button
              btnType="ghost"
              icon={toggleIcon}
              onClick={options.onTogglerClick}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Panel
      className="solicitacao-recente-panel"
      headerTemplate={headerTemplate}
      toggleable
      collapsed
    >
      <div className="solicitacao-recente-panel-content column">
        <SimpleText
          className="p-mb-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Justificativa
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          {procedimento.justificativa}
        </SimpleText>
      </div>
    </Panel>
  );
};

const getStatusLabel = (statusValue: string | undefined) => {
  if (!statusValue) return '-';

  const statusOption = statusHistoricoOptions.find(
    status => status.value === statusValue,
  );

  return statusOption?.label || '-';
};
