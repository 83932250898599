import { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import TipoMotivoAtendimentoAPI from 'src/APIs/ConfigAPI/TipoMotivoAtendimento/TipoMotivoAtendimentoAPI';
import MotivoAtendimentoAPI from 'src/APIs/ProntuarioAPI/FichaPaciente/MotivoAtendimento/MotivoAtendimentoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import './AdicionarMotivo.scss';
interface AdicionarProps {
  visible: boolean;
  onHide: () => void;
  reloadList: () => void;
  idAtendimento: string | undefined;
  editMotivo: MotivoAtendimento | undefined;
}

const AdicionarMotivo = ({
  visible,
  onHide,
  idAtendimento,
  editMotivo,
  reloadList,
}: AdicionarProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [motivoOptions, setMotivoOptions] = useState<any[]>([]);

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    motivo: Yup.number().required('Selecione um motivo de atendimento'),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(editMotivo?.dataInclusao).max(
        400,
        'O camp atual não deve possuir mais do que 400 caracteres.',
      ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  useEffect(() => {
    if (editMotivo) {
      setValue('motivo', editMotivo.idTipoMotivoAtendimento);
      setValue('dataOcorrencia', new Date(editMotivo.dataOcorrencia));
      setValue('descricao', editMotivo.descricaoMotivo);
      setValue('lembrete', editMotivo.lembrete);
      setValue(
        'justificativaOcorrenciaRetroativa',
        editMotivo.justificativaOcorrenciaRetroativa,
      );
    }
  }, [editMotivo, setValue]);

  useEffect(() => {
    const getMotivo = async () => {
      const response =
        await TipoMotivoAtendimentoAPI.getBuscaMotivoAtendimento();

      const motivos = response.map(item => {
        return { label: item.descricao, value: item.id };
      });

      setMotivoOptions(motivos);
    };
    getMotivo();
  }, []);

  const onSubmit = async (data: any) => {
    const payload = {
      idAtendimento: idAtendimento,
      idProfissionalSaude: user.idUsuario,
      idTipoMotivoAtendimento: data.motivo,
      descricaoMotivo: data.descricao,
      lembrete: data.lembrete,
      justificativaOcorrenciaRetroativa: data.justificativaOcorrenciaRetroativa,
      dataOcorrencia: dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
      idMotivoAtendimento: editMotivo && editMotivo.id,
    };

    let response: any;

    !editMotivo &&
      (response = await MotivoAtendimentoAPI.postMotivoAtendimento(payload));
    editMotivo &&
      (response = await MotivoAtendimentoAPI.putMotivoAtendimento(payload));

    if (response.id) {
      reloadList();
      onHide();
    }
  };

  return (
    <Dialog
      header={
        editMotivo
          ? 'Editar motivo de atendimento'
          : 'Novo motivo de atendimento'
      }
      type="modal"
      visible={visible}
      onHide={onHide}
      modal={false}
      maximizedTabletSize
    >
      <div className="container-adicionar">
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
          <DataOcorrencia
            control={control}
            defaultValue={
              editMotivo ? new Date(editMotivo.dataOcorrencia) : new Date()
            }
            dataInclusao={editMotivo?.dataInclusao}
          />

          <Controller
            name="motivo"
            control={control}
            render={({ field: { onChange, onBlur, value } }: any) => (
              <Dropdown
                className="p-mb-2 p-md-12  "
                label="Motivo"
                placeholder="Selecione o motivo"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                options={motivoOptions}
                errorMsg={errors.motivo?.message}
              />
            )}
          />

          <Controller
            name="descricao"
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <TextareaInput
                label="Descrição"
                className="grid p-col-12 p-md-12  p-mb-2"
                value={value}
                onChange={onChange}
                autoResize
                maxLength={1999}
              />
            )}
          />

          <Controller
            name="lembrete"
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <Checkbox
                label="Exibir em 'lembretes'"
                value={value}
                checked={value}
                onChange={onChange}
              />
            )}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={onHide}
              stretch
              loading={isSubmitting}
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default AdicionarMotivo;
