import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

import { escalaTempoOptions } from '../../../helpers';
import { useUploadFile } from '../../../hooks/useUploadFile';
import { ExamesFields } from '../ExamesFields';
import { LaudoField } from '../LaudoField';
import { UploadLaudo } from '../UploadLaudo/UploadLaudo';

import { validationSchema } from './validationSchema';

import './ModalFormExameExterno.scss';

type FormValues = {
  solicitante: string;
  conselhoSolicitante: string;
  cidIndicacaoClinica?: string;
  tempoDoenca?: number;
  unidadeTempoDoenca?: number;
  exames: {
    idServicoTuss: number | null;
    quantidade: number | null;
    nivelAlteracao?: string;
    resultadoNumerico?: number;
    dataResultado: Date | null;
  }[];
  laudoArquivo?: string;
  laudoDigitado?: string;
};

export const ModalFormExameExterno = (props: any) => {
  const {
    isOpen,
    close,
    state: { onSuccess },
  } = props;

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const defaultValues = {
    solicitante: '',
    conselhoSolicitante: '',
    exames: [
      {
        idServicoTuss: null,
        quantidade: null,
        dataResultado: null,
      },
    ],
  };

  const { loadedFile, handleLoadFile, handleDeleteFile, handleUploadFile } =
    useUploadFile({ isProcedimentoExterno: true, onSuccess: () => {} });

  const useFormMethods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema(!!loadedFile)),
  });

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useFormMethods;

  const onSubmit = async (data: FormValues) => {
    try {
      const laudoArquivo = await handleUploadFile(1, 1, false);

      const payload = {
        ...data,
        laudoArquivo,
      };

      await SolicitacoesProcedimentosExternosAPI.postProcedimentoExterno(
        Number(idAtendimento),
        payload,
      );

      onSuccess();

      close();
    } catch (error) {}
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        onClick={close}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      className="modal-form-exame-externo"
      header="Adicionar exames externos"
      visible={isOpen}
      type="modal"
      modal={false}
      onHide={close}
      maximizedTabletSize
      footer={renderFooter}
    >
      <FormProvider {...useFormMethods}>
        <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Médico solicitante
          </SimpleText>

          <FormInput
            className="p-col-12 p-sm-6"
            name="solicitante"
            label="Solicitante"
            hideTextErrorSpace
          />

          <FormInput
            className="p-col-12 p-sm-6"
            name="conselhoSolicitante"
            label="Número conselho"
            placeholder="00000-PR"
            hideTextErrorSpace
          />

          <SimpleText
            className="p-col-12 p-mt-2"
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Exames / Guia
          </SimpleText>

          <FormInput
            className="p-col-12 p-sm-6"
            name="cidIndicacaoClinica"
            label="CID / Indicação clínica"
            hideTextErrorSpace
          />

          <div className="p-grid p-p-0 p-d-flex p-ai-end p-col-12 p-sm-6 p-p-0">
            <FormInput
              className="p-col-12 p-sm-6"
              classNameInput="p-w-100"
              name="tempoDoenca"
              label="Tempo de doença"
              type="number"
              min={1}
              hideTextErrorSpace
            />

            <DropdownControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="unidadeTempoDoenca"
              options={escalaTempoOptions}
              filter={false}
              errorMsg={errors.unidadeTempoDoenca?.message}
            />
          </div>

          <ExamesFields />

          <SimpleText
            className="p-col-12 p-mt-3"
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Resultado
          </SimpleText>

          <UploadLaudo
            loadedFile={loadedFile}
            handleDeleteFile={handleDeleteFile}
            handleLoadFile={handleLoadFile}
          />

          <LaudoField />
        </form>
      </FormProvider>
    </Dialog>
  );
};
