import { useState, useEffect } from 'react';

import { SessaoAPI } from 'src/APIs/SecurityAPI/SessaoAPI/SessaoAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalSessoes.scss';

function ModalSessoes({ isOpen, close, state }: any) {
  const [sessoes, setSessoes] = useState(state?.sessoes || []);

  useEffect(() => {
    setSessoes(state?.sessoes || []);
  }, [state]);

  const handleClose = (tryLogin = false) => {
    close();

    if (sessoes.length < state?.limiteSessoes || tryLogin) {
      state?.handleLogin();
    }
  };

  const handleLogout = async (identificacaoSessao: string[]) => {
    try {
      const response = await SessaoAPI.removeSessoes(identificacaoSessao, {
        return: true,
      });

      if (response?.status === 202) {
        setSessoes((prevSessoes: any) =>
          prevSessoes.filter(
            (sessao: any) =>
              !identificacaoSessao.includes(sessao.identificacaoSessao),
          ),
        );

        if (identificacaoSessao.length === sessoes.length) handleClose(true);
      }
    } catch (error) {}
  };

  const handleLogoutAll = () => {
    const identificacaoSessoes = sessoes.map(
      (item: any) => item.identificacaoSessao,
    );

    handleLogout(identificacaoSessoes);
  };

  const renderFooter = () => (
    <div className="p-grid p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Fechar" onClick={() => close()} stretch />
      <Button
        btnType="danger"
        label="Sair de todas as sessões"
        onClick={handleLogoutAll}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      className="modal-sessoes"
      type="modal"
      header="Sessões abertas para o usuario"
      visible={isOpen}
      onHide={handleClose}
      footer={renderFooter}
      maximizedTabletSize
    >
      <div id="modal-sessoes-container">
        <div className="p-mb-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Limite de sessões: {state?.limiteSessoes}
          </SimpleText>
        </div>

        {sessoes.map((item: any, idx: number) => (
          <div key={idx} className="card-modal-sessoes">
            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {item.ip}
              </SimpleText>
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                {item.identificacaoSessao}
              </SimpleText>
            </div>

            <Button
              btnType="ghost"
              icon="pi pi-sign-out"
              onClick={() => handleLogout([item.identificacaoSessao])}
              stretch
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
}

export default ModalSessoes;
