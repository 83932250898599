import { useFormContext } from 'react-hook-form';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import { localOptions, situacaoLocalOptions } from '../../../helpers';

interface LocalFieldsProps {
  localFieldDisabled: boolean;
}

export const LocalFields = (props: LocalFieldsProps) => {
  const { localFieldDisabled } = props;

  const {
    control,
    formState: { errors },
    unregister,
    watch,
  } = useFormContext();

  const local = watch('local');

  const handleLocalChange = (localValue: string) => {
    if (localValue === 'CONSULTORIO') unregister('situacaoLocal');
  };

  const SituacaoLocalField = () => (
    <div className="p-col-12 p-sm-6">
      <SimpleText
        className="p-col-12 p-px-0"
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Caráter atendimento
      </SimpleText>

      <RadioButtonGroupControlled
        name="situacaoLocal"
        control={control}
        error={errors?.situacaoLocal}
        items={situacaoLocalOptions}
        stretch
      />
    </div>
  );

  return (
    <>
      <div className="p-col-12 p-sm-6">
        <SimpleText
          className="p-col-12 p-px-0"
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Local
        </SimpleText>

        <RadioButtonGroupControlled
          name="local"
          control={control}
          error={errors?.local}
          items={localOptions}
          customOnChange={handleLocalChange}
          disabled={localFieldDisabled}
          stretch
        />
      </div>

      {local === 'HOSPITAL' && <SituacaoLocalField />}
    </>
  );
};
