import Http, { HttpParams } from 'src/core/http/Http';

export const realizarAutorizacaoAtendimento = (
  idAtendimento: string,
  payload: any,
  options?: Omit<HttpParams, 'url' | 'service'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/${idAtendimento}`,
    data: payload,
    return: true,
    successMessage: 'Autorização realizada com sucesso.',
    ...options,
  });
};

export const realizarPresenca = (
  idAtendimento: string | number,
  payload: any,
  options?: Omit<HttpParams, 'url' | 'service'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/${idAtendimento}/registro-presenca`,
    data: payload,
    hideToast: true,
    ...options,
  });
};

export const getSalas = (
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    service: 'adm',
    url: `/consultorios/${idConsultorio}/salas`,
    return: true,
    hideToast: true,
    ...options,
  });
};

export const getCartoes = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    service: 'adm',
    url: `/cartoes-paciente`,
    params,
    return: true,
    hideToast: true,
    ...options,
  });
};

export const postTokenPresenca = (
  payload: any,
  options?: Omit<HttpParams, 'url' | 'service'>,
) =>
  Http.post({
    service: 'agenda',
    url: 'token-presenca/gerar',
    data: payload,
    throwError: true,
    ...options,
  });

const AutorizacaoAPI = {
  getSalas,
  getCartoes,
  realizarAutorizacaoAtendimento,
  postTokenPresenca,
  realizarPresenca,
};

export default AutorizacaoAPI;
