import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

import './PanelHeader.scss';

interface PanelHeaderProps {
  isCollapsed: boolean;
  medicamento: MedicamentosUso;
  optionsMenu: JSX.Element;
  onToggle: () => void;
  onAlterarEmUso: () => void;
}

function PanelHeader({
  medicamento,
  isCollapsed,
  optionsMenu,
  onToggle,
  onAlterarEmUso,
}: PanelHeaderProps) {
  const { atendimentoStatus } = useAtendimento();

  const headerTextClassName =
    medicamento.status === 'INATIVO' ? 'medicamento-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    medicamento.status === 'INATIVO' ? 'line-through medicamento-inativo' : ''
  }`;

  return (
    <div className="panel-header-prontuario p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-6 p-sm-9 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Nome
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {medicamento.nomeMedicamento}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-md-2 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(medicamento.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-md-2 p-xl-1 retroativo-chip">
        {medicamento.justificativaOcorrenciaRetroativa && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="coluna-dados p-col-4 p-md-2 p-xl-1 p-d-flex p-flex-column">
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Em uso?
        </SimpleText>
        <SplitButton
          className="p-mt-2"
          menuClassName="menu-medicamento-em-uso"
          btnType={
            medicamento.emUso ? SPLIT_BTN_TYPE.YELLOW : SPLIT_BTN_TYPE.GREEN
          }
          label={medicamento.emUso ? 'Sim' : 'Não'}
          model={[
            {
              label: medicamento.emUso ? 'Não' : 'Sim',
              command: onAlterarEmUso,
            },
          ]}
          disabled={
            atendimentoStatus !== 'ATENDENDO' &&
            atendimentoStatus !== 'ATENDENDO_TRIAGEM'
          }
        />
      </div>
      <div className="coluna-acoes p-col p-d-flex p-gap-2 p-ai-center p-jc-end">
        <div className="p-mr-2">
          <Lembrete ativo={medicamento.lembrete} />
        </div>

        {optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
}

export default PanelHeader;
