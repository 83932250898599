import { useState, useCallback } from 'react';

import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';

const formFields = {
  especialidade: '',
  cbos: '',
  anoobtencao: '',
  anorenovacao: '',
  rqe: '',
};

interface ModalAddEspecialidadeProps {
  especialidades: any;
  cbos: any;
  visible: boolean;
  onHide(): void;
  addEspecialidade(espec: any): void;
}

const ModalAddEspecialidade = ({
  especialidades,
  cbos,
  visible,
  onHide,
  addEspecialidade,
}: ModalAddEspecialidadeProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [ano, setAno] = useState<any>(false);
  const [rqe, setRqe] = useState<any>(false);

  const validationSchema = Yup.object().shape({
    especialidade: Yup.object().shape({
      id: Yup.string().required('Selecione a especialidade'),
    }),
    cbos: Yup.object().shape({
      id: Yup.string().required('Selecione o correspondente CBO'),
    }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = useCallback(() => {
    reset(formFields);
    onHide();
  }, [onHide, reset]);

  const handleEspecialidade = useCallback(
    async (data: any) => {
      const aux: any = {
        idEspecialidade: data?.especialidade?.id,
        especialidade: data?.especialidade,
        idCorrespondenteCBO: data?.cbos?.id,
        cbo: data?.cbos,
        anoObtencao: data?.anoobtencao,
        anoRenovacao: data?.anorenovacao,
        rqe: data?.rqe,
      };

      addEspecialidade(aux);

      setAno(false);
      setRqe(false);
      reset(formFields);
    },
    [addEspecialidade, reset],
  );

  const especialidadesOpt = especialidades?.map((v: any) => {
    return { label: v?.nome || '', value: v };
  });

  const cbosOpt = cbos?.map((v: any) => {
    return { label: v?.descricao || '', value: v };
  });

  return (
    <Dialog
      type="modal"
      header={'Adicionar Especialidade'}
      visible={visible}
      onHide={handleCancel}
      footer={() => <div />}
      maximizedMobileSize
    >
      <form
        className="modal-body-add-fornecedor-tuss"
        onSubmit={handleSubmit(handleEspecialidade)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Dropdown
              name="especialidade"
              className="grid p-col-12 p-md-12"
              label={'Especialidade'}
              value={value}
              options={especialidadesOpt}
              errorMsg={errors.especialidade?.id?.message}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
          name="especialidade"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Dropdown
              name="cbos"
              className="grid p-col-12 p-md-12"
              label={'Correspondente CBO'}
              value={value}
              options={cbosOpt}
              errorMsg={errors.cbos?.id?.message}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
          name="cbos"
        />

        <Separator />

        {ano ? (
          <div className="rowteste">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <TextInput
                  permitirApenasNumeros
                  maxLength={4}
                  label="Ano de obtenção"
                  className="grid p-col-6 p-md-6"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  errorMsg={errors.anoobtencao?.message}
                />
              )}
              name="anoobtencao"
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <TextInput
                  permitirApenasNumeros
                  maxLength={4}
                  label="Ano de renovação"
                  className="grid p-col-6 p-md-6"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  errorMsg={errors.anorenovacao?.message}
                />
              )}
              name="anorenovacao"
            />
          </div>
        ) : null}

        <div className="p-jc-start">
          <Button
            type="button"
            btnType={ano ? 'danger-link' : 'green-link'}
            icon={ano ? 'pi pi-minus' : 'pi pi-plus'}
            className="buttonaddespecialidade"
            onClick={() => {
              setAno(!ano);
            }}
            label="Adicionar ano de obtenção e renovação (opcional)"
          />
        </div>

        {rqe ? (
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
              <TextInput
                label="RQE"
                className="grid p-col-12 p-md-12"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                errorMsg={errors.rqe?.message}
              />
            )}
            name="rqe"
          />
        ) : null}
        <div className="p-jc-start">
          <Button
            type="button"
            btnType={rqe ? 'danger-link' : 'green-link'}
            icon={rqe ? 'pi pi-minus' : 'pi pi-plus'}
            className="buttonaddespecialidade"
            onClick={() => {
              setRqe(!rqe);
            }}
            label="RQE"
          />
        </div>

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Voltar"
            className={'p-col-align-center p-d-flex'}
            type="button"
            onClick={handleCancel}
            stretch
          />
          <Button
            label={'Adicionar'}
            type="submit"
            onClick={handleSubmit(handleEspecialidade)}
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default ModalAddEspecialidade;
