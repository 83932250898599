import React, { useCallback, useEffect, useRef, useState } from 'react';

import { OverlayPanel, OverlayPanelEventType } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { ToggleButton } from 'primereact/togglebutton';
import { useLocation, useParams } from 'react-router';

import dayjs from 'dayjs';
import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ModalAddPedidoCuidado from './ModalAddPedidoCuidado';

import './PedidoCuidado.scss';

interface PedidoCuidadoProps {
  campos: any[];
  idPaciente: number | null;
}

function PedidoCuidado({ campos, idPaciente }: PedidoCuidadoProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { agenda } = useAppSelector((state: RootState) => state);

  const modalDisclosure = useDisclosure({ opened: false });

  const [campoPedidoCuidado, setCampoPedidoCuidado] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(
    campoPedidoCuidado.exibicaoCamposModelo === 'FIXO' ? true : false,
  );
  const [listaPedidosCuidado, setListaPedidosCuidado] = useState<any>([]);
  const [editPedidoCuidado, setEditPedidoCuidado] = useState<any>(null);

  const getPedidosCuidado = useCallback(async () => {
    const response = await PedidosCuidadoAPI.getPedidosCuidado(
      Number(idAtendimento),
      {
        sortDirection: 'DESC',
        sortBy: 'dataOcorrencia',
        somenteAtendimentoCorrete: true,
      },
    );
    if (Array.isArray(response?.list)) {
      setListaPedidosCuidado(response?.list);
    }
  }, [idAtendimento]);

  useEffect(() => {
    getPedidosCuidado();
  }, [getPedidosCuidado]);

  const reloadCampos = () => {
    if (campoPedidoCuidado.exibicaoCamposModelo === 'FIXO') {
      campoPedidoCuidado.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoPedidoCuidado.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    const exibixaoCampo =
      campoPedidoCuidado.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoPedidoCuidado.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  useEffect(() => {
    setCampoPedidoCuidado(
      campos.filter(campo => campo.funcionalidade === 'PEDIDO_CUIDADO')[0],
    );
  }, [campos]);

  useEffect(() => {
    setChecked(
      campoPedidoCuidado.exibicaoCamposModelo === 'FIXO' ? true : false,
    );
  }, [campoPedidoCuidado.exibicaoCamposModelo]);

  const handleDeletePedidoCuidado = useCallback(
    async (idPedidoCuidado: number, idAtendimentoSolicitante: number) => {
      if (idAtendimentoSolicitante !== Number(idAtendimento)) {
        await PedidosCuidadoAPI.inativarPedidoCuidado(
          Number(idPedidoCuidado),
          Number(idAtendimentoSolicitante),
          { justificativaInativacao: 'N/A' },
        );
        return getPedidosCuidado();
      }

      const response = await PedidosCuidadoAPI.removerPedidoCuidado(
        Number(idPedidoCuidado),
        Number(idAtendimentoSolicitante),
      );

      if (response?.status >= 200 && response?.status < 300) {
        setListaPedidosCuidado(
          (prev: any) =>
            (prev = prev.filter(
              (pedido: any) => pedido.id !== idPedidoCuidado,
            )),
        );
      }
    },
    [getPedidosCuidado, idAtendimento],
  );

  const isRequired = campoPedidoCuidado.obrigatorio;

  return (
    <div id="pedido-cuidado">
      <div className="p-d-flex p-jc-between p-ai-end p-mt-2">
        <div>
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Pedido de cuidado
          </SimpleText>
          {isRequired && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div className="p-d-flex  p-jc-end p-gap-2">
          <Button
            label="Adicionar"
            iconPos="left"
            icon="pi pi-plus"
            className="add-pedido-cuidado"
            btnType="tonal"
            onClick={() => {
              setEditPedidoCuidado(null);
              modalDisclosure.open();
            }}
          />
          <ToggleButton
            checked={checked}
            className={`p-px-4 p-py-1 pin ${isRequired ? 'pin-disabled' : ''}`}
            onLabel={''}
            offLabel={''}
            onChange={e =>
              !isRequired ? [handleFixar(), setChecked(e.value)] : null
            }
            onIcon="fa fa-thumbtack"
            offIcon="fa fa-thumbtack"
          />
        </div>
      </div>
      <div className="lista-pedidos-cuidado p-mt-2">
        {listaPedidosCuidado.map((pedidoCuidado: any, idx: any) => (
          <PedidoCuidadoRow
            key={idx}
            item={pedidoCuidado}
            idx={idx}
            setEditPedidoCuidado={setEditPedidoCuidado}
            modalDisclosure={modalDisclosure}
            handleDeletePedidoCuidado={handleDeletePedidoCuidado}
            idAtendimento={idAtendimento}
          />
        ))}
      </div>

      {modalDisclosure.isOpen && (
        <ModalAddPedidoCuidado
          {...modalDisclosure}
          idAtendimento={Number(idAtendimento)}
          idProfissionalSaude={Number(agenda?.profissionalAtivo?.id)}
          idPaciente={idPaciente}
          editPedidoCuidado={editPedidoCuidado}
          getPedidosCuidado={getPedidosCuidado}
          setEditPedidoCuidado={setEditPedidoCuidado}
        />
      )}
    </div>
  );
}

export default PedidoCuidado;

const PedidoCuidadoRow = ({
  item: pedidoCuidado,
  idx: index,
  ...props
}: any) => {
  const menuEllipsis = useRef<TieredMenu>(null);
  const overlayExclusao = useRef<OverlayPanel>(null);

  const {
    setEditPedidoCuidado,
    modalDisclosure,
    handleDeletePedidoCuidado,
    idAtendimento,
  } = props;

  const isInativo = pedidoCuidado?.status !== 'ATIVO';

  const editable =
    pedidoCuidado?.idAtendimentoSolicita === Number(idAtendimento);

  return (
    <div key={index} className="pedido-cuidado p-p-3 p-my-2">
      <div className="p-d-flex p-jc-between p-ai-center p-px-3">
        <div className="p-d-flex p-flex-column p-mt-2">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
            medium
          >
            {pedidoCuidado?.profissionalSaudeSolicitacao?.nome}
          </SimpleText>
          <SimpleText
            className={`${isInativo ? 'text-inativo' : ''} p-mt-2`}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Solicitado em{' '}
            {dayjs(pedidoCuidado?.dataInclusao).format('DD/MM/YYYY')}
          </SimpleText>
        </div>
        <div className="p-d-flex p-mt-2">
          {!pedidoCuidado?.programado && (
            <div className="retroativo-chip p-mr-6 p-as-center">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={
                  isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16
                }
                medium
              >
                Proximo atendimento
              </SimpleText>
            </div>
          )}

          {editable && !isInativo ? (
            <>
              <Button
                icon="fas fa-pencil"
                className="p-button-rounded p-button-text p-button-plain p-button-secondary p-mr-2"
                onClick={() => {
                  setEditPedidoCuidado(pedidoCuidado);
                  modalDisclosure.open();
                }}
                btnType="gray"
              />
              <Button
                icon="fas fa-trash"
                className="p-button-rounded p-button-text p-button-plain p-button-secondary"
                onClick={(event: any) =>
                  overlayExclusao?.current?.toggle(event)
                }
                btnType="gray"
              />
              {pedidoCuidado?.idAtendimentoSolicita ===
              Number(idAtendimento) ? (
                <OverlayPanel
                  className="overlay-exclusao"
                  ref={overlayExclusao}
                >
                  <SimpleText
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_40}
                    className="p-mb-2"
                  >
                    {pedidoCuidado?.programado
                      ? 'O agendamento do pedido de cuidado programado para o profissional também será excluído. Você realmente deseja excluir o pedido de cuidado?'
                      : 'O agendamento do pedido de cuidado para o profissional também será excluído. Você realmente deseja excluir o pedido de cuidado?'}
                  </SimpleText>
                  <Button
                    className="p-mt-2"
                    label="Sim, excluir"
                    type="submit"
                    btnType="danger"
                    onClick={e => {
                      handleDeletePedidoCuidado(
                        pedidoCuidado?.id,
                        pedidoCuidado?.idAtendimentoSolicita,
                      );
                      overlayExclusao?.current?.hide();
                    }}
                  />
                </OverlayPanel>
              ) : (
                <OverlayPanel
                  className="overlay-exclusao"
                  ref={overlayExclusao}
                >
                  <SimpleText
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_40}
                    className="p-mb-2"
                  >
                    {pedidoCuidado?.programado
                      ? 'O agendamento do pedido programado para o profissional também será excluído. Você realmente deseja INATIVAR o pedido de cuidado?'
                      : 'O agendamento do pedido de cuidado para o profissional também será excluído. Você realmente deseja INATIVAR o pedido de cuidado?'}
                  </SimpleText>
                  <Button
                    className="p-mt-2"
                    label="Sim, inativar"
                    type="submit"
                    btnType="danger"
                    onClick={e => {
                      handleDeletePedidoCuidado(
                        pedidoCuidado?.id,
                        pedidoCuidado?.idAtendimentoSolicita,
                      );
                      overlayExclusao?.current?.hide();
                    }}
                  />
                </OverlayPanel>
              )}
            </>
          ) : (
            <>
              {pedidoCuidado.status !== 'INATIVO' && (
                <>
                  <Button
                    icon="fas fa-ellipsis-h"
                    btnType="gray"
                    onClick={event => menuEllipsis?.current?.toggle(event)}
                  />
                  <TieredMenu
                    className="panel-options-ficha-paciente"
                    ref={menuEllipsis}
                    model={[
                      {
                        label: 'Inativar',
                        command: () => {
                          handleDeletePedidoCuidado(
                            pedidoCuidado?.id,
                            pedidoCuidado?.idAtendimentoSolicita,
                          );
                        },
                      },
                    ]}
                    popup
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {pedidoCuidado?.programado === true ? (
        <>
          <div className="p-grid p-col-12 p-mt-2">
            <SimpleText
              className="p-col-12"
              fontSize={FONT_SIZE.XXS}
              fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
              medium
            >
              Data programada
            </SimpleText>
            <div className="p-col-12 datas-pedido-programado p-grid">
              {pedidoCuidado?.atendimentosAgendados?.map(
                (atendimento: any, index: any) => (
                  <div
                    key={index}
                    className="p-col-2 p-d-flex p-ai-center p-jc-center data-programada"
                  >
                    <div className="p-d-flex p-flex-column">
                      <div>
                        <i className="fas fa-calendar-days p-mr-1" />
                        <SimpleText
                          className="p-mt-2 p-col-3"
                          fontSize={FONT_SIZE.XXS}
                          fontColor={FONT_COLOR.COLOR_60}
                        >
                          {dayjs(atendimento?.dataAgendamento).format(
                            'DD/MM/YYYY',
                          )}
                        </SimpleText>
                      </div>
                      <div>
                        <i className="fas fa-clock p-mr-1 p-mt-1" />
                        <SimpleText
                          className="p-mt-2 p-col-3"
                          fontSize={FONT_SIZE.XXS}
                          fontColor={FONT_COLOR.COLOR_60}
                        >
                          {dayjs(atendimento?.dataAgendamento).format('HH:mm')}
                        </SimpleText>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
          <div className="p-d-flex p-flex-column p-mt-2 p-px-1">
            <SimpleText
              className={`${isInativo ? 'text-inativo' : ''} p-ml-2`}
              fontSize={FONT_SIZE.XXS}
              fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
              medium
            >
              Cuidado Programado:{' '}
              {pedidoCuidado?.tipoCuidado
                ? pedidoCuidado?.tipoCuidado?.descricao
                : ''}
            </SimpleText>
            <SimpleText
              className={`${isInativo ? 'text-inativo' : ''} p-mt-2 p-ml-2`}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              {pedidoCuidado?.observacaoSolicitante}
            </SimpleText>
          </div>
        </>
      ) : (
        <div className="p-d-flex p-flex-column p-mt-2  p-px-1">
          <SimpleText
            className={`${isInativo ? 'text-inativo' : ''} p-ml-2`}
            fontSize={FONT_SIZE.XXS}
            fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
            medium
          >
            Cuidado Próximo atendimento:
          </SimpleText>
          <SimpleText
            className={`${isInativo ? 'text-inativo' : ''} p-mt-2 p-ml-2`}
            fontSize={FONT_SIZE.XXS}
            style={{
              wordBreak: 'break-word',
            }}
            fontColor={isInativo ? FONT_COLOR.COLOR_60 : FONT_COLOR.COLOR_16}
          >
            {pedidoCuidado?.observacaoSolicitante !== null
              ? pedidoCuidado?.observacaoSolicitante
              : ''}
          </SimpleText>
        </div>
      )}
    </div>
  );
};
