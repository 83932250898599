import { useState } from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { handleFileDownload } from 'src/utils/files';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ModalVisualizar from '../ModalVisualizar/ModalVisualizar';

import './PanelHeader.scss';

interface PanelHeaderProps {
  documentos: Documentos;
  isCollapsed: boolean;
  optionsMenu: JSX.Element;
  onToggle: () => void;
}

function PanelHeader({
  documentos,
  isCollapsed,
  optionsMenu,
  onToggle,
}: PanelHeaderProps) {
  const [visible, setVisible] = useState(false);
  const { isTablet } = useSize();

  const isRetroativo = !!documentos.justificativaOcorrenciaRetroativa;
  const isInativo = documentos.status === 'INATIVO';

  const headerTextClassName = isInativo ? 'opme-inativa' : '';

  return (
    <>
      {!isInativo && (
        <Tooltip
          target={`.tooltip-${documentos.id}`}
          position="top"
          content="Visualizar documento"
          mouseTrackTop={10}
        />
      )}

      <div
        data-isInativo={documentos.status === 'INATIVO'}
        className={
          'p-grid p-col-12 p-ai-center p-jc-between documentos-panel-header'
        }
      >
        <div
          className={`p-d-flex p-ai-center p-col-12 p-md-6 tooltip-${documentos.id}`}
          onClick={() =>
            !isInativo ? window.open(documentos.documentoUrl) : null
          }
        >
          <i
            className="fas fa-file"
            data-isInativo={documentos.status === 'INATIVO'}
          />

          <div className="p-d-flex p-jc-center p-flex-column p-ml-2">
            <SimpleText className="doc-label doc-name">
              {documentos.nomeDocumento}
            </SimpleText>
          </div>
        </div>
        <div
          className={`p-d-flex p-gap-3 p-col ${
            isTablet ? 'p-jc-between' : 'p-jc-end'
          }`}
        >
          {isRetroativo && (
            <div>
              <SimpleText className="tag tag-retroativo" fontSize="xxxs">
                Retroativo
              </SimpleText>
            </div>
          )}
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data de ocorrência
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              className="doc-label p-mt-2"
              medium
            >
              {documentos.dataOcorrencia
                ? dayjs(documentos.dataOcorrencia).format('DD/MM/YYYY HH:mm')
                : ''}
            </SimpleText>
          </div>

          <div className="coluna-acoes p-d-flex p-jc-between p-jc-sm-end p-gap-2">
            <Button
              icon="fa fa-file-download"
              btnType="gray"
              disabled={isInativo}
              onClick={() =>
                handleFileDownload(documentos.documentoUrl, 'Documento')
              }
            />

            {optionsMenu}

            <Button
              icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
              btnType="ghost"
              onClick={onToggle}
            />
          </div>
        </div>
      </div>

      <ModalVisualizar
        visible={visible}
        documento={documentos}
        onHide={() => setVisible(false)}
      />
    </>
  );
}

export default PanelHeader;
