import { useState } from 'react';

import './TabVariant.scss';

export type TabVariantItems = {
  title: string;
  content: JSX.Element;
  disabled?: boolean;
  bgColor?: 'light-grey' | 'dark-grey';
};

export type TabVariantProps = {
  items: TabVariantItems[];
  initialIndex?: number;
  onChange?: (index: number) => void;
  activeIndex?: number;
  maxH?: string;
};

export const TabVariant = ({
  items,
  initialIndex = 0,
  onChange,
  activeIndex,
  maxH,
}: TabVariantProps) => {
  const [tabIndex, setTabIndex] = useState(initialIndex);

  const tab = activeIndex !== undefined ? activeIndex : tabIndex;

  const handleTabChange = (index: number) => {
    if (onChange && activeIndex !== undefined) {
      onChange(index);
      return;
    }
    return setTabIndex(index);
  };

  return (
    <div className="TabV p-d-flex p-flex-column p-w-100">
      <div className="TabV-header p-d-flex p-ai-center">
        {items.map((item, index) => (
          <div
            key={index}
            className="TabV-header-item p-px-3 p-py-2"
            data-active={tab === index}
            data-disabled={item.disabled}
            data-bgcolor={item.bgColor}
            onClick={() => !item.disabled && handleTabChange(index)}
          >
            <span className="p-text-truncate">{item.title}</span>
          </div>
        ))}
      </div>

      {items.map((item, index) => {
        if (tab !== index) return null;
        return (
          <div
            key={index}
            className="TabV-content"
            data-bgcolor={item.bgColor}
            data-isFirstSelected={tab === 0}
            data-active={tab === index}
            style={{
              maxHeight: maxH ? maxH : 'auto',
              overflowY: 'auto',
            }}
          >
            {item.content}
          </div>
        );
      })}
    </div>
  );
};
