import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import LembreteAgendamento from './LembreteAgendamento/LembreteAgendamento';
import TipoCuidado from './TipoCuidado/TipoCuidado';
import TipoMotivoAtendimento from './TipoMotivoAtendimento/TipoMotivoAtendimento';
import TipoMotivoFalta from './TipoMotivoFalta/TipoMotivoFalta';
import UsuariosSimultaneos from './UsuariosSimultaneos/UsuariosSimultaneos';

const Configuracoes = () => {
  const { idEmpresa } = useAppSelector(state => state.user);

  const [consultoriosDisponiveis, setConsultoriosDisponiveis] = useState([]);

  useEffect(() => {
    const getConsultorios = async () => {
      if (idEmpresa) {
        const response = await ConsultorioAPI.loadConsultorios({
          nome: '',
          pageSize: 9999,
        });
        if ('status' in response) return;
        setConsultoriosDisponiveis(response.list as any);
      }
    };

    getConsultorios();
  }, [idEmpresa]);

  const consultoriosUsuario = consultoriosDisponiveis.map((c: any) => {
    return {
      nome: c.nome,
      label: c.nome,
      value: {
        id: c.id,
        name: c.nome,
      },
    };
  });

  const tab = useLocation().state;

  return (
    <Page className={'Configuracoes'}>
      <>
        <PageHeader
          title={'Configurações gerais'}
          subtitle={'Ajuste configurações básicas do eMed'}
        />
        <Tab
          initialTabIndex={tab || ''}
          underHeader
          values={[
            {
              label: 'Tipos de cuidado',
              content: (
                <TipoCuidado
                  consultoriosDisponiveis={consultoriosDisponiveis}
                  consultoriosUsuario={consultoriosUsuario}
                />
              ),
              checkPermission: 'AGE_TIPO_DE_CUIDADO_CONSULTAR',
            },
            {
              label: 'Tipos de motivos de atendimento',
              content: (
                <TipoMotivoAtendimento
                  consultoriosDisponiveis={consultoriosDisponiveis}
                  consultoriosUsuario={consultoriosUsuario}
                />
              ),
              checkPermission: 'AGE_MOTIVO_DE_ATENDIMENTO_CONSULTAR',
            },
            {
              label: 'Motivos de falta',
              content: (
                <TipoMotivoFalta
                  consultoriosDisponiveis={consultoriosDisponiveis}
                  consultoriosUsuario={consultoriosUsuario}
                />
              ),
              checkPermission: 'AGE_MOTIVO_DE_FALTA_CONSULTAR',
            },
            {
              label: 'Usuários simultâneos',
              content: <UsuariosSimultaneos />,
              checkPermission: 'ADM_USUARIOS_SIMULTANEOS_CONSULTAR',
            },

            {
              label: 'Lembrete de Agendamento',
              content: tabIndex => <LembreteAgendamento tabIndex={tabIndex} />,
              checkPermission: 'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_CONSULTAR',
            },
          ]}
        />
      </>
    </Page>
  );
};

export default Configuracoes;
