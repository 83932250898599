import { createContext } from 'react';

import { AtendimentoState } from 'src/core/redux/slices/atendimento/AtendimentoSlice';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { TempoAtendimentoProps } from 'src/utils/hooks/useTimer';

type TeleconsultaState = 'INITIAL' | 'STARTED' | 'FINISHED' | 'IDLE';

export interface AtendimentoData {
  atendimentoStatus: string;
  setAtendimentoStatus: (status: string) => void;
  showBarraLateral: boolean;
  setShowBarraLateral: (state: boolean) => void;
  iniciarAtendimento: (
    idAtendimento: number,
    onSaveDataOcorrencia?: (
      idAtendimento: string | number,
      dataOcorrencia: Date,
    ) => void,
  ) => void;
  pausarAtendimento: (
    atendimento: AtendimentoState,
    tempoAtendimento: TempoAtendimentoProps,
  ) => void;
  finalizarAtendimento: (idAtendimento: number, payload: any) => any;
  finalizarTriagem: (idAtendimento: number) => void;
  infoLembretes: any;
  setInfoLembretes: (info: any) => void;
  getInfoLembretes: (idPaciente: number) => void;
  camposPEP: any;
  setCamposPEP: (state: any) => void;
  sairPEPDialog: DisclosureType;
  prontuarioPersistente: any;
  setProntuarioPersistente: (state: any) => void;
  guiasExecucoes: any[];
  modelos: any[];
  getModelos: () => void;
  setModelos: (state: any) => void;
  getGuiasExecucoes: () => void;
  iniciarPrescricaoExecucao: (idAtendimento: number) => void;
  setIsTeleatendimento: (v: boolean) => void;
  finalizarPrescricaoExecucao: (
    idAtendimento: number,
    justificativa?: string,
  ) => void;
  isTeleatendimento: boolean;
  setTabValue: (v: number) => void;
  tabValue: number;
  setTeleconsulta: (v: TeleconsultaState) => void;
  teleconsultaState: TeleconsultaState;
  setRoomName: (v: string) => void;
  roomName: string;
  idConvenioAtendimento: number | null;
  setIdConvenioAtendimento: React.Dispatch<React.SetStateAction<number | null>>;
  paciente: any;
  setPaciente: any;
  idConvenioProcedimentos: number | null;
  setIdConvenioProcedimentos: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  numeroCartaoAtendimentoParticular: string | null;
  setNumeroCartaoAtendimentoParticular: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  listaProblemas: any[];
  getListaProblemas: () => void;
  campos: any[];
  getCampos: () => void;
  checkRequiredJustificativa: (data: any) => void;
  requiredJustificativa: boolean;
  camposObrigatoriosNaoPreenchidos: any[];
  isFormularioDirty: boolean;
  setIsFormularioDirty: (v: boolean) => void;
  infoProtocoloDialog: DisclosureType;
  protocoloDialog: DisclosureType;
  protocoloAplicado: boolean;
  setProtocoloAplicado: (v: boolean) => void;
  protocolosAtendimento: any[];
  idAtendimento: number;
}

export const AtendimentoContext = createContext({} as AtendimentoData);
