import dayjs from 'dayjs';

export const itemsSelecaoAgendamentos = ({
  dialogJustificativaBloqueio,
  handleAlterarHorariosSelected,
  handleFlags,
  handleRevogar,
  selectedAgendamentos,
}: any) => {
  const hasBlockedAgendamento = selectedAgendamentos.find(
    (ag: any) => ag.status === 'BLOQUEADO',
  );
  const hasInternetFlag = selectedAgendamentos.some(
    (agendamento: any) =>
      agendamento.status === 'LIVRE' &&
      agendamento?.flagsAtivas?.includes?.('INTERNET'),
  );
  const hasCentralFlag = selectedAgendamentos.some(
    (agendamento: any) =>
      agendamento.status === 'LIVRE' &&
      agendamento?.flagsAtivas?.includes?.('CENTRAL_AGENDAMENTO'),
  );

  return [
    {
      label: 'Bloqueado',
      command: () =>
        dialogJustificativaBloqueio.open({
          state: { submit: handleAlterarHorariosSelected },
        }),
      disabled: hasBlockedAgendamento,
    },
    {
      separator: true,
    },

    {
      label: 'Livre',
      command: () => handleAlterarHorariosSelected('', 'LIVRE'),
    },
    {
      separator: true,
    },
    {
      label: 'Disponibilizar para a internet:',
      items: [
        {
          label: 'Consulta padrão',
          command: () => handleFlags(['INTERNET']),
          disabled: hasBlockedAgendamento,
        },
        {
          label: 'Consulta de retorno',
          command: () => handleFlags(['INTERNET', 'RETORNO']),
          disabled: hasBlockedAgendamento,
        },
        {
          label: 'Teleconsulta',
          command: () => handleFlags(['INTERNET', 'TELECONSULTA']),
          disabled: hasBlockedAgendamento,
        },
        {
          className: 'warning-color error',
          label: 'Revogar disponibilidade',
          icon: 'fa-solid fa-arrow-rotate-left',
          command: () => handleRevogar(['INTERNET', 'RETORNO', 'TELECONSULTA']),
          disabled: hasBlockedAgendamento || !hasInternetFlag,
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: 'Disponibilizar para Central de Agendamento',
      command: () => handleFlags(['CENTRAL_AGENDAMENTO']),
      disabled: hasBlockedAgendamento,
    },
    {
      className: 'warning-color error',
      label: 'Revogar disponibilidade para Central de Agendamento',
      icon: 'fa-solid fa-arrow-rotate-left',
      command: () => handleRevogar('CENTRAL_AGENDAMENTO'),
      disabled: hasBlockedAgendamento || !hasCentralFlag,
    },
  ];
};

export const optionsAgenda = ({
  dialogHorarioBloco,
  setIsSelecao,
  handleBloqueioDia,
}: any) => [
  {
    label: 'Alterar horários em bloco',
    command: () => dialogHorarioBloco.open(),
  },
  {
    separator: true,
  },
  {
    label: 'Selecionar horários',
    command: () => {
      setIsSelecao(true);
    },
  },
  {
    separator: true,
  },
  {
    label: 'Bloquear agenda do dia',
    icon: 'pi pi-ban',
    command: () => handleBloqueioDia(),
  },
];

export const getAgendamentosHorarios = (items: any) => {
  if (!items?.length) return ['', ''];

  const idxFinal = items.length - 1;

  const initial = dayjs(items[0]?.dataAgendamento).format('HH:mm');
  const final = dayjs(items[idxFinal]?.dataAgendamento).format('HH:mm');

  return [initial, final];
};
