import Http, { HttpParams } from 'src/core/http/Http';

export const getCircunferenciaAbdominal = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `circunferencia-abdominal/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
  });

export const getUnidadesCircunferenciaAbdominal = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'circunferencia-abdominal/valores-enum',
    hideToast: true,
  });

export const adicionarCircunferenciaAbdominal = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'circunferencia-abdominal',
    data: payload,
    return: true,
  });

export const alterarCircunferenciaAbdominal = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-abdominal/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
  });

export const inativarCircunferenciaAbdominal = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-abdominal/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
  });

export const removerCircunferenciaAbdominal = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `circunferencia-abdominal/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
  });

const CircunferenciaAbdominalAPI = {
  getCircunferenciaAbdominal,
  getUnidadesCircunferenciaAbdominal,
  adicionarCircunferenciaAbdominal,
  alterarCircunferenciaAbdominal,
  inativarCircunferenciaAbdominal,
  removerCircunferenciaAbdominal,
};

export default CircunferenciaAbdominalAPI;
