import { Dispatch, SetStateAction, useMemo } from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

import {
  conteudoModalComunicado,
  copiarComunicadoPacientes,
  EComunicadoStatus,
  editarComunicadoPacientes,
  statusDoComunicado,
} from '../helpers';

import { IConteudoModal } from './CardComunicado';

interface MobileRowProps {
  data: ComunicadoDTO;
  setExibirModalComunicado: Dispatch<SetStateAction<boolean>>;
  setConteudoModal: Dispatch<SetStateAction<IConteudoModal>>;
}

export const MobileRow: React.FC<MobileRowProps> = ({
  data,
  setExibirModalComunicado,
  setConteudoModal,
}: any): JSX.Element => {
  const statusDoComunicadoAtual = useMemo(
    () => statusDoComunicado(data.ativo, data.dataInicio, data.dataFim),
    [data],
  );

  const navigate = useNavigate();

  const options = useMemo(() => {
    if (statusDoComunicadoAtual === EComunicadoStatus.Ativo) {
      return [
        {
          label: 'Inativar',
          command: () => {
            setConteudoModal(conteudoModalComunicado['inativar']);
            setExibirModalComunicado(true);
          },
          checkPermission: 'ADM_COMUNICADO_ALTERAR',
        },
      ];
    }

    if (statusDoComunicadoAtual !== EComunicadoStatus.Inativo) {
      return [
        {
          label: 'Editar',
          command: () => editarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_COMUNICADO_ALTERAR',
        },
        {
          label: 'Copiar',
          command: () => copiarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_COMUNICADO_CADASTRAR',
        },
        {
          label: 'Excluir',
          className: 'warning-color',
          command: () => {
            setConteudoModal(conteudoModalComunicado['excluir']);
            setExibirModalComunicado(true);
          },
          checkPermission: 'ADM_COMUNICADO_EXCLUIR',
        },
      ];
    }

    if (!data.ativo) {
      return [
        {
          label: 'Copiar',
          command: () => copiarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_COMUNICADO_CADASTRAR',
        },
      ];
    }

    return [];
  }, [
    data,
    navigate,
    setConteudoModal,
    setExibirModalComunicado,
    statusDoComunicadoAtual,
  ]);

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>Status</SimpleText>
        <div className="p-mt-2">
          <SimpleText className={`status ${statusDoComunicadoAtual}`}>
            {statusDoComunicadoAtual}
          </SimpleText>
        </div>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Título / Mensagem
        </SimpleText>
        <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16} medium>
          {data.titulo}
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16}>{data.mensagem}</SimpleText>
      </div>
      <div className="column p-mt-2 p-d-flex p-flex-col p-gap-1">
        <SimpleText fontColor="color_60">Data de inicio</SimpleText>
        <SimpleText>{dayjs(data.dataInicio).format('DD/MM/YYYY')}</SimpleText>
      </div>
      <div className="column p-mt-2 p-d-flex p-flex-col p-gap-1">
        <SimpleText fontColor="color_60">Data de fim</SimpleText>
        <SimpleText>{dayjs(data.dataFim).format('DD/MM/YYYY')}</SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
