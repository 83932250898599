import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';

import './Error.scss';

export const ErrorPage = () => {
  return (
    <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '80%' }}>
      <ElevatedCard className="error-container p-d-flex p-flex-column p-gap-4">
        <SimpleText fontSize={FONT_SIZE.LG} className="p-text-center" bold>
          Erro ao concluir sua solicitação
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.MD} className="p-text-center">
          A página que procura não está disponível no momento. <br /> Caso esse
          não seja o comportamento esperado, entre em contato com o suporte.
        </SimpleText>
      </ElevatedCard>
    </div>
  );
};
