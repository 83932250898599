import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import Campos from './Campos/Campos';
import Modelos from './Modelos/Modelos';

import './ModalPersonalizarProntuario.scss';

interface ModalPersonalizarProntuarioProps {
  visivel: boolean;
  onHide: () => void;
}

function ModalPersonalizarProntuario({
  visivel,
  onHide,
}: ModalPersonalizarProntuarioProps) {
  const { isMobile } = useSize();
  return (
    <Dialog
      type="modal"
      header="Personalizar Prontuário"
      visible={visivel}
      onHide={onHide}
      maximizedMobileSize
      className={isMobile ? 'mobile' : ''}
      id="modal-personalizar-prontuario"
    >
      <>
        <Tab
          clean
          values={[
            {
              label: 'Ocultar e fixar campos',
              content: <Campos />,
            },
            {
              label: 'Modelos',
              content: <Modelos />,
            },
          ]}
        />

        <Button
          className="p-mt-1"
          type="button"
          btnType="outline"
          stretch
          label="Cancelar"
          onClick={onHide}
        />
      </>
    </Dialog>
  );
}

export default ModalPersonalizarProntuario;
