import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AssinaturaHsmAPI } from 'src/APIs/AdminAPI/AssinaturaHsm/AssinaturaHsmAPI';
import * as Yup from 'yup';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { encodeStringToBase64 } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

export const DialogAssinarDigitalmente = ({
  isOpen,
  close,
  state,
}: DisclosureType) => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        PIN: Yup.string().required('A senha PIN é obrigatória'),
      }),
    ),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;
  const onSubmit = async (values: any) => {
    const senhaBase64 = encodeStringToBase64(values.PIN);
    values.hsmSenha = senhaBase64;
    values.hsmHorasVigencia = null;
    try {
      await AssinaturaHsmAPI.putPinHSM(agenda?.profissionalAtivo?.id, values, {
        throwError: true,
        hideToast: !!state,
      });
    } catch (error) {
      console.error(error);
    }

    if (state.onSubmit) {
      state.onSubmit();
    }
    close();
  };

  const onClose = () => {
    if (state.onClose) {
      state.onClose();
    }
    close();
  };

  return (
    <Dialog
      visible={isOpen}
      onHide={onClose}
      header="Assinar digitalmente"
      style={{ maxWidth: '520px' }}
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-mb-4">
            <SimpleText fontColor={FONT_COLOR.COLOR_60}>
              Informe a senha PIN e clique em salvar. Ela ficará disponível para
              próximas assinaturas de documentos e prontuários.{' '}
            </SimpleText>
          </div>

          <FormInput
            name="PIN"
            label="Senha PIN"
            placeholder="Informe a senha PIN da assinatura de documentos"
            errorMsg={errors.PIN?.message}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={() => onClose()}
              stretch
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
