import { useRef, useState } from 'react';

import { Page } from '../../_components/Page/Page';
import { PageHeader } from '../../_components/Page/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import { NovoAgendamentoProvider } from './Form/hooks/NovoAgendamentoContext';
import { NovoAgendamentoAgendamentoForm } from './Form/NovoAgendamentoAgendamentoForm';
import { NovoAgendamentoConfirmacaoForm } from './Form/NovoAgendamentoConfirmacaoForm';
import { NovoAgendamentoConsultorioProfissionalForm } from './Form/NovoAgendamentoConsultorioProfissionalForm';
import { NovoAgendamentoIdentificacaoPacienteForm } from './Form/NovoAgendamentoIdentificacaoPacienteForm';
import { Voucher } from './Voucher/Voucher';

export const NovoAgendamento = () => {
  const [values, setValues] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const ref = useRef<TimelineImperativeAPI>(null);

  const handleSetData = (data: any, tabIdx: number) => {
    setTabIndex(tabIdx + 1);
    setValues(old => ({ ...old, ...data }));
    ref.current?.next?.();
  };

  const handleGoBack = () => ref.current?.previous?.();

  const timelineValues: TimelineValue[] = [
    {
      title: 'Identificação do paciente',
      component: (
        <NovoAgendamentoIdentificacaoPacienteForm
          values={values}
          handleSetData={d => handleSetData(d, 0)}
        />
      ),
    },
    {
      title: 'Consultório e profissional',
      component: (
        <NovoAgendamentoConsultorioProfissionalForm
          values={values}
          handleSetData={d => handleSetData(d, 1)}
          handleGoBack={handleGoBack}
        />
      ),
      disabled: tabIndex < 1,
    },
    {
      title: 'Agendamento',
      component: (
        <NovoAgendamentoAgendamentoForm
          values={values}
          handleSetData={d => handleSetData(d, 2)}
          handleGoBack={handleGoBack}
        />
      ),
      disabled: tabIndex < 2,
    },
    {
      title: 'Confirmação do agendamento',
      component: (
        <NovoAgendamentoConfirmacaoForm
          values={values}
          handleSetData={d => handleSetData(d, 3)}
          handleSetCompleted={() => setIsCompleted(true)}
          handleGoBack={handleGoBack}
        />
      ),
      disabled: tabIndex < 3,
    },
  ];

  return (
    <Page>
      <NovoAgendamentoProvider>
        <PageHeader
          backButton
          hasBreadcrumbs
          title="Novo agendamento"
          subtitle="Insira as informações do paciente para o novo agendamento"
        />

        <Page content white className="p-py-4">
          {isCompleted ? (
            <Voucher values={values} />
          ) : (
            <Timeline ref={ref} values={timelineValues} />
          )}
        </Page>
      </NovoAgendamentoProvider>
    </Page>
  );
};
