import {
  ScrollPanel as PrimeScrollPanel,
  ScrollPanelProps as PrimeScrollPanelProps,
} from 'primereact/scrollpanel';
import './ScrollPanel.scss';

export interface ScrollPanelProps extends PrimeScrollPanelProps {
  activityIndicator?: boolean;
  children: JSX.Element;
  stretch?: boolean;
}
const ScrollPanel = ({
  activityIndicator,
  children,
  stretch,
  ...rest
}: ScrollPanelProps) => {
  const indicator = !activityIndicator ? 'transparent' : '';
  return (
    <PrimeScrollPanel
      style={{ width: '100%', height: '100%' }}
      className={`ScrollPanel ${indicator} ${stretch ? 'stretch' : ''} bar`}
      {...rest}
    >
      {children || <></>}
    </PrimeScrollPanel>
  );
};

export default ScrollPanel;
