import { useCallback } from 'react';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

import './PanelHeader.scss';

const situacoes: any = {
  EM_TRATAMENTO: 'Em tratamento',
  RESOLVIDA: 'Resolvida',
  CONTROLADA: 'Controlada',
};

interface PanelHeaderProps {
  alergia: AlergiaDTO;
  optionsMenu: JSX.Element;
  isCollapsed: boolean;
  onToggle: () => void;
  onAlterarSituacao: (situacao: string) => void;
}

function PanelHeader({
  alergia,
  optionsMenu,
  isCollapsed,
  onToggle,
  onAlterarSituacao,
}: PanelHeaderProps) {
  const { atendimentoStatus } = useAtendimento();

  const renderSplitButton = useCallback(() => {
    const labelType: Record<string, SPLIT_BTN_TYPE> = {
      EM_TRATAMENTO: SPLIT_BTN_TYPE.YELLOW,
      RESOLVIDA: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
      CONTROLADA: SPLIT_BTN_TYPE.GREEN,
    };

    const splitButtonItems: any = [];

    for (const situacao in situacoes) {
      if (situacao !== alergia.situacao) {
        splitButtonItems.push({
          label: situacoes[situacao],
          command: () => onAlterarSituacao(situacao),
        });
      }
    }

    const type = labelType[alergia.situacao] as SPLIT_BTN_TYPE;

    return (
      <SplitButton
        menuClassName="status-alergia"
        label={situacoes[alergia.situacao]}
        model={splitButtonItems}
        btnType={type}
        disabled={
          atendimentoStatus !== 'ATENDENDO' &&
          atendimentoStatus !== 'ATENDENDO_TRIAGEM'
        }
      />
    );
  }, [alergia.situacao, atendimentoStatus, onAlterarSituacao]);

  const headerTextClassName =
    alergia.status === 'INATIVO' ? 'alergia-inativa' : '';
  const dataTextClassName = `p-mt-2 ${
    alergia.status === 'INATIVO' ? 'line-through alergia-inativa' : ''
  }`;

  return (
    <div className="p-grid p-col-12 p-align-center p-jc-between panel-header-prontuario">
      <div className="p-col-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {alergia.descricao || '-'}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(alergia.dataOcorrencia).format('DD/MM/YYYY  HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-1 retroativo-chip">
        {alergia.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column">
        {renderSplitButton()}
      </div>

      <div className="p-col p-d-flex p-jc-between p-jc-md-end p-gap-1">
        <div className="p-d-flex p-ai-center p-mr-2">
          <Lembrete ativo={alergia.lembrete} />
        </div>

        {optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
}

export default PanelHeader;
