import { Route } from 'react-router-dom';

import { TelemedicinaProvider } from 'src/core/containers/Telemedicina/TelemedicinaProvider';

import { ErrorPage } from 'src/pages/Emed/Telemedicina/Error/Error';
import { SalaEsperaTelemedicina } from 'src/pages/Emed/Telemedicina/SalaEspera/SalaEspera';
import { SalaTeleconsulta } from 'src/pages/Emed/Telemedicina/SalaTeleconsulta/SalaTeleconsulta';
import { TermoAssinatura } from 'src/pages/Emed/Telemedicina/TermoAssinatura/TermoAssinatura';

export default function TelemedicinaRoutes() {
  return (
    <Route element={<TelemedicinaProvider />}>
      <Route
        path="/t/termo/:idTermo/atendimento/:idAtendimento"
        element={<TermoAssinatura />}
      />
      <Route
        path="/t/:codEmpresa/:idAtendimento/:idAtendimento1"
        element={<SalaEsperaTelemedicina />}
      />
      <Route
        path="/t/sala/:idSala/atendimento/:idAtendimento"
        element={<SalaTeleconsulta />}
      />
      <Route path="/t/*" element={<ErrorPage />} />
    </Route>
  );
}
