import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = '/faturamento';

const getFaturamento = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${baseUrl}`,
    params,
    hideToast: true,
    ...options,
  });
};

const putStatusAtendimento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'agenda',
    url: `${baseUrl}/atendimento/status-faturamento`,
    params,
    ...options,
  });
};

const putValorAtendimento = (
  idItem: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'agenda',
    url: `${baseUrl}/atendimento/${idItem}`,
    params,
    ...options,
  });
};

const putStatusProcedimento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${baseUrl}/procedimento/status-faturamento`,
    params,
    ...options,
  });
};

const putValorProcedimento = (
  idItem: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${baseUrl}/procedimento/${idItem}`,
    params,
    ...options,
  });
};

const getRelatorioPdf = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'report',
    url: `relatorios-pep${baseUrl}/pdf`,
    params,
    hideToast: true,
    ...options,
    responseType: 'arraybuffer',
    return: true
  });
};

const getRelatorioCsv = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'report',
    url: `relatorios-pep${baseUrl}/csv`,
    params,
    hideToast: true,
    ...options,
  });
};

export const FaturamentoAPI = {
  getFaturamento,
  putStatusAtendimento,
  putValorAtendimento,
  putStatusProcedimento,
  putValorProcedimento,
  getRelatorioPdf,
  getRelatorioCsv,
};
