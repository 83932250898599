import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { Procedimento } from './Procedimento';

import './ProcedimentosSeriadosAtendimento.scss';

interface ProcedimentosSeriadosAtendimentoProps {
  guiasExecucoes: any[];
}

export const ProcedimentosSeriadosAtendimento = ({
  guiasExecucoes,
}: ProcedimentosSeriadosAtendimentoProps) => {
  const [showProcedimentoIndex, setShowProcedimentoIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    setMaxIndex(guiasExecucoes.length - 1);
  }, [guiasExecucoes]);

  const useFormMethods = useForm({});

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormMethods;

  const showProcedimento = (data: any) => {
    data === '+' &&
      showProcedimentoIndex < maxIndex &&
      setShowProcedimentoIndex(prev => prev + 1);
    data === '-' &&
      showProcedimentoIndex > 0 &&
      setShowProcedimentoIndex(prev => prev - 1);
  };

  return (
    <div id="procedimentos-seriados">
      <SimpleText
        className="p-ml-2 p-pt-2"
        fontSize={FONT_SIZE.SM}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Execução de procedimentos
      </SimpleText>
      <div className="aba">
        <FormProvider {...useFormMethods}>
          <form className="p-grid" id="form-procedimentos-seriados">
            {guiasExecucoes.map((guiaExecucao: any, index: number) => {
              if (index === showProcedimentoIndex) {
                return (
                  <Procedimento
                    key={index}
                    guiaExecucao={guiaExecucao}
                    index={index}
                    {...useFormMethods}
                  />
                );
              } else {
                return null;
              }
            })}
            <div className="p-d-flex p-col-12 p-jc-end">
              <Button
                type="button"
                label="Anterior"
                className="p-mx-1 anterior-btn"
                onClick={() => showProcedimento('-')}
                disabled={showProcedimentoIndex === 0}
                loading={isSubmitting}
              />
              <Button
                type="button"
                label="Proximo"
                className="p-mx-1"
                onClick={() => showProcedimento('+')}
                disabled={showProcedimentoIndex === maxIndex}
                loading={isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
