import { memo, useCallback, useState, useEffect } from 'react';

import Button from 'src/components/Basics/Button/Buttons';
import './Chip.scss';

interface ChipProps {
  label?: string | undefined;
  className?: string | undefined;
  onChange?(v: boolean): void;
  checked?: boolean | undefined;
  hide?: boolean | undefined;
  removable?: boolean
}

const Chip = ({
  label,
  removable,
  checked = false,
  onChange,
  className,
  hide,
}: ChipProps) => {
  const [selected, setSelected] = useState(checked || false);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  const handleChange = useCallback(() => {
    if (hide) {
      return;
    }

    setSelected(!selected);
    if (onChange) onChange(!selected);
  }, [hide, onChange, selected]);


  return (
    <div className={className ? 'Chip ' + className : 'Chip'}>
      <Button
        icon={selected ? 'fas fa-times p-mr-1' : ''}
        iconPos="right"
        className={selected ? 'selected' : hide ? 'hide' : ''}
        label={label}
        btnType="chip"
        onClick={handleChange}
        type="button"
      />
    </div>
  );
};

export default memo(Chip);
