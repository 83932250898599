import { useCallback, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import { PlanosRow } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/PlanosRow/PlanosRow';
import * as SearchList from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/SearchList/SearchListRoot';

import { usePlanoDeCuidado } from './hooks/PlanosDeCuidadoContext';
import { PlanoDeCuidadoFilters } from './PlanoDeCuidadoFilters';

export const PlanoDeCuidadoContent = () => {
  const [nome, setNome] = useState('');

  const { ativo } = useAppSelector(state => state.consultorios);
  const { paciente } = useAppSelector(state => state.atendimento);
  const { invalidatePlanosList } = useAppSelector(state => state.query);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { idConvenioAtendimento } = useAtendimento();
  const { filters, idConvenio } = usePlanoDeCuidado();
  const dispatch = useDispatch();

  const dialogRef = useRef<OverlayPanel>(null);

  const fetchAPI = useCallback(
    async (params: any) => {
      if (
        paciente?.id &&
        idAtendimento &&
        (idConvenio || idConvenioAtendimento)
      ) {
        return await PlanoCuidadoPacienteAPI.loadPlanoCuidadoPaciente({
          idPaciente: paciente.id,
          idConsultorio: ativo?.id,
          idConvenio: idConvenio || idConvenioAtendimento,
          idAtendimento,
          nome,
          sortBy: 'dataInclusao',
          sortDirection: 'DESC',
          ...filters,
          ...params,
        })
          .catch(() => {})
          .finally(() => {
            dispatch(resetInvalidateQuery());
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ativo?.id,
      filters,
      idAtendimento,
      idConvenio,
      idConvenioAtendimento,
      paciente?.id,
      invalidatePlanosList,
      nome,
    ],
  );

  const handleNome = useThrottle((v: string) => setNome(v), 750);

  return (
    <>
      <div className="p-grid p-mb-3 p-ai-center">
        <div className="p-col-8 p-lg-10 p-pl-0 ">
          <TextInputSearch
            placeholder="Pesquise pelo nome do plano"
            onChange={v => handleNome(v)}
          />
        </div>

        <Button
          type="button"
          label="Pesquisa avançada"
          icon="fas fa-chevron-down"
          iconPos="right"
          className="p-col-4 p-lg-2"
          btnType="tonal"
          onClick={e => dialogRef.current?.toggle(e)}
          style={{ height: '30px' }}
        />
      </div>

      <PlanoDeCuidadoFilters ref={dialogRef} />

      <div className="content-planos">
        <div className="p-d-flex p-px-1" style={{ width: '98%' }}>
          <SimpleText
            className="p-col-5 p-md-7"
            fontSize="xxs"
            fontColor="color_60"
          >
            Nome do plano
          </SimpleText>
          <SimpleText className="p-col-2" fontSize="xxs" fontColor="color_60">
            Status
          </SimpleText>
          <SimpleText
            className="p-col-5 p-md-3 p-text-right p-pr-6"
            fontSize="xxs"
            fontColor="color_60"
          >
            Ações
          </SimpleText>
        </div>

        <SearchList.Root fetchApi={fetchAPI}>
          <div className="p-col-12 p-pt-0 p-d-flex p-flex-column">
            <SearchList.InfiniteScroll
              customEmptyText="Utilize os filtros ou faça uma busca para encontrar os planos de
            cuidado."
              renderRow={item => <PlanosRow type="PRONTUARIO" item={item} />}
            />
          </div>
        </SearchList.Root>
      </div>
    </>
  );
};
