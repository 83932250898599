import { memo, useCallback, useMemo, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';

import './SearchListEspecialidadeServicoTuss.scss';
interface SearchListEspecialidadeServicoTussProps {
  selected: Especialidade[];
  onSelectItem(v: Especialidade): void;
  onRemoveItem(v: Especialidade): void;
}

const SearchListEspecialidadeServicoTuss = (
  props: SearchListEspecialidadeServicoTussProps,
) => {
  const { selected, onSelectItem, onRemoveItem } = props;

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<Especialidade[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const onChangeSearch = useCallback(async (value: string) => {
    setList([]);
    setSearch(value);

    if (value) {
      setLoading(true);
      const response: any = await EspecialidadeAPI.loadEspecialidades({
        nome: value,
      });

      // Ordena os dados por nome
      setList(
        response?.list?.sort((a: any, b: any) => (a?.nome < b?.nome ? -1 : 1)),
      );
      setLoading(false);
    }
  }, []);

  const listUnselected = useMemo(
    () => selected.filter(i => !list?.some(s => s?.id === i?.id)) || [],
    [list, selected],
  );

  const isSelected = useCallback(
    (item: Especialidade) => selected.some(i => i?.id === item?.id),
    [selected],
  );

  const handleSelectAll = useCallback(() => {
    if (!selectAll) {
      list.forEach(item => onSelectItem(item));
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [list, onSelectItem, selectAll]);

  const handleUnselectAll = useCallback(() => {
    if (list.length) {
      list.forEach(item => onRemoveItem(item));
    }
    if (listUnselected.length) {
      listUnselected.forEach(item => onRemoveItem(item));
    }

    setSelectAll(false);
  }, [list, listUnselected, onRemoveItem]);

  return (
    <div className="SearchListEspecialidadeServicoTuss">
      <TextInputSearch
        autoFocus
        placeholder="Adicione especialidades pesquisando"
        onChange={onChangeSearch}
      />

      <div className="p-d-flex p-jc-between p-pt-5 p-px-2">
        <Checkbox
          checked={selectAll}
          label="Selecionar todos"
          onChange={handleSelectAll}
        />
        <Button
          btnType="green-link"
          label="Limpar"
          onClick={handleUnselectAll}
        />
      </div>

      <div className="search-list">
        {listUnselected.map(item => (
          <SearchListItemServicosTuss
            selected
            key={item.id}
            item={item}
            onClick={onRemoveItem}
          />
        ))}
        <>
          {loading ? (
            <>
              <div style={{ marginBottom: 8 }}>
                <Skeleton height="56px" borderRadius="8px" />
              </div>
            </>
          ) : (
            <>
              {search ? (
                list?.map(item => (
                  <SearchListItemServicosTuss
                    selected={isSelected(item)}
                    key={item.id}
                    item={item}
                    onClick={isSelected(item) ? onRemoveItem : onSelectItem}
                  />
                ))
              ) : listUnselected?.length === 0 ? (
                <div className="search-list-empty">
                  Os resultados da busca irão aparecer aqui.
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

interface SearchListItemServicosTussProps {
  item: Especialidade;
  onClick(v: Especialidade): void;
  selected?: boolean;
}

const SearchListItemServicosTuss = memo(
  (props: SearchListItemServicosTussProps) => {
    const { item, onClick, selected } = props;

    return (
      <div
        className={`SearchListItemServicosTuss
          ${selected ? 'item-selected' : ''}
        `}
      >
        <Checkbox
          name={item.nome || item.especialidade.nome}
          label={item.nome || item.especialidade.nome}
          checked={!!selected}
          onChange={() => onClick(item)}
        />
      </div>
    );
  },
);

export default memo(SearchListEspecialidadeServicoTuss);
