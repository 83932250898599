import { useEffect, useCallback, useState, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import ProntuarioPersistenteAPI from 'src/APIs/ProntuarioAPI/ProntuarioPersistenteAPI/ProntuarioPersistenteAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

import './CalendarioVacinal.scss';

const validatioSchema = Yup.object().shape({
  statusVacinal: Yup.bool()
    .required('Selecione o status vacinal do paciente')
    .nullable(),
});

interface CalendarioVacinalProps {
  idPaciente: number | null;
}

const CalendarioVacinal = ({ idPaciente }: CalendarioVacinalProps) => {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { atendimentoStatus } = useAtendimento();

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [statusVacinal, setStatusVacinal] = useState<boolean>();
  const [observacaoVacinal, setObservacaoVacinal] = useState<string>();

  const loadCalendarioVacinal = useCallback(async () => {
    if (!idPaciente) return;

    setLoading(true);

    const response = await ProntuarioPersistenteAPI.getProntuarioPersistente({
      idPaciente,
    });

    if (response?.id) {
      setValue('statusVacinal', response.statusVacinal);
      setValue('observacaoVacinal', response?.observacaoVacinal || '');

      setStatusVacinal(response.statusVacinal);
      setObservacaoVacinal(response?.observacaoVacinal || '');
    }

    setLoading(false);
  }, [idPaciente, setValue]);

  useEffect(() => {
    loadCalendarioVacinal();
  }, [loadCalendarioVacinal]);

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        ...data,
        idPaciente,
        idProfissionalSaude: agenda?.profissionalAtivo?.id,
      };

      await ProntuarioPersistenteAPI.alterarProntuarioPersistente(payload);
    },
    [agenda?.profissionalAtivo?.id, idPaciente],
  );

  const watchStatusVacinal = watch('statusVacinal');
  const watchObservacaoVacinal = watch('observacaoVacinal');

  const showSaveButton = useMemo(() => {
    return (
      watchStatusVacinal !== statusVacinal ||
      watchObservacaoVacinal !== observacaoVacinal
    );
  }, [
    observacaoVacinal,
    statusVacinal,
    watchObservacaoVacinal,
    watchStatusVacinal,
  ]);

  if (loading) return <GridListLoading />;

  return (
    <form
      id="calendario-vacinal"
      className="p-grid p-mb-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
        Calendário Vacinal
      </SimpleText>

      <div className="p-col p-md-4">
        <RadioButtonControlled
          control={control}
          name="statusVacinal"
          label="Atrasado"
          value={false}
        />
      </div>
      <div className="p-col p-md-4">
        <RadioButtonControlled
          control={control}
          name="statusVacinal"
          label="Adequado"
          value={true}
        />
      </div>
      {errors.statusVacinal?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors.statusVacinal?.message}
        </SimpleText>
      )}

      <TextareaInputControlled
        className="p-col-12"
        control={control}
        name="observacaoVacinal"
        label="Observações"
      />

      {showSaveButton && (
        <div className="p-col-12">
          <Button
            type="submit"
            btnType="tonal"
            label="Salvar"
            loading={isSubmitting}
            disabled={
              atendimentoStatus !== 'ATENDENDO' &&
              atendimentoStatus !== 'ATENDENDO_TRIAGEM'
            }
          />
        </div>
      )}
    </form>
  );
};

export default CalendarioVacinal;
