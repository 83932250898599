import { useCallback, useRef, useState } from 'react';

import {
  useForm,
  Controller,
  FormProvider,
  useFieldArray,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import * as Yup from 'yup';

import { useCreateProfissionalSaude } from 'src/core/hooks/ManterUsuario/useCreateProfissionalSaude';
import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import FormInput from 'src/components/FormInput/FormInput';
import Separator from 'src/components/Separator/Separator';

interface FormMasterCreateUserProfExecutanteProps {
  handleBack(): void;
  nextStep(): void;
}

const FormMasterCreateUserProfExecutante = ({
  nextStep,
}: FormMasterCreateUserProfExecutanteProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const { dadosProfissionais, setStep4 } = useCreateProfissionalSaude();
  const { idUser } = useCreateUser();

  const [loading, setLoading] = useState<boolean>(false);

  const hasInsetedBeforeSaved = useRef(false);

  const validationSchema = Yup.object().shape({
    convenio: Yup.object().nullable().required('Selecione um convênio'),
    codigoOperadora: Yup.string()
      .required('Digite o código da operadora')
      .typeError('Digite o código da operadora'),
    nomeContratado: Yup.string()
      .required('Digite  o nome do contratado')
      .typeError('Digite  o nome do contratado'),
    cnes: Yup.string().required('Digite o CNES').typeError('Digite o CNES'),
  });

  const useFormMethods = useForm({
    defaultValues: {
      profissionalExecutante: dadosProfissionais?.profissionalExecutante || [],
    } as any,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useFormMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'profissionalExecutante',
    keyName: 'idForm',
  });

  const handleReset = useCallback(() => {
    setValue('codigoOperadora', '');
    setValue('nomeContratado', '');
    setValue('cnes', '');
    setValue('convenio', null);
  }, [setValue]);

  const handleAddProfissional = useCallback(
    (e: any) => {
      const { convenio, ...rest } = e;
      const newProfExec = {
        ...rest,
        idConvenio: convenio?.id,
      };

      append(newProfExec);
      handleReset();

      if (!hasInsetedBeforeSaved.current) {
        hasInsetedBeforeSaved.current = true;
      }
    },
    [append, handleReset],
  );

  const saveProfissionalExecutante = useCallback(
    async (actionAfterSave: () => void) => {
      const { listaConfigTiss = [], listaEspecialidade = [] } =
        dadosProfissionais;

      const res = await ProfissionalAPI.updateDadosProfissionais(idUser, {
        cadastrarProfissionalExecutante: true,
        profissionalExecutante: fields,
        listaConfigTiss,
        listaEspecialidade,
      });

      if (res?.status === 200) {
        hasInsetedBeforeSaved.current = false;

        setStep4(fields);
        actionAfterSave();
      }

      setLoading(false);
    },
    [dadosProfissionais, fields, idUser, setStep4],
  );

  const renderList = useCallback(
    () =>
      fields.length === 0 ? (
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          {' '}
          Não ha cadastros, preencha os campos e adicione.
        </SimpleText>
      ) : (
        fields?.map((e: any, idx: any) => (
          <div key={idx} className="p-grid p-col-12 itemprofexec">
            <div className="p-col-6 p-md-3 p-d-flex p-flex-column p-gap-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Código operadora
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_0}
                fontSize={FONT_SIZE.XXS}
              >
                {e?.codigoOperadora}
              </SimpleText>
            </div>

            <div className="p-col-6 p-md-4 p-d-flex p-flex-column p-gap-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Nome do contratado
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_0}
                fontSize={FONT_SIZE.XXS}
              >
                {e?.nomeContratado}
              </SimpleText>
            </div>

            <div className="p-col-6 p-md-3 p-d-flex p-flex-column p-gap-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                CNES
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_0}
                fontSize={FONT_SIZE.XXS}
              >
                {e?.cnes}
              </SimpleText>
            </div>

            <div className="p-col-6 p-md-2 p-d-flex  p-jc-end">
              <Button
                type="button"
                btnType="gray"
                icon="pi pi-trash"
                onClick={() => remove(idx)}
              />
            </div>
          </div>
        ))
      ),
    [fields, remove],
  );

  return (
    <FormProvider {...useFormMethods}>
      <form
        className="p-grid p-col-12 p-lg-9 p-xl-7"
        onSubmit={handleSubmit(handleAddProfissional)}
        defaultValue={''}
      >
        <div className="p-grid p-col-12 listasprofexec">
          <SimpleText className="p-col-12" bold>
            Profissional Executante
          </SimpleText>

          <FieldConvenio
            className="p-col-12 p-md-6"
            name="convenio"
            label="Convênio"
          />
          <FormInput
            name="codigoOperadora"
            type="number"
            label="Código operadora"
            className="p-col-12 p-md-6"
            hideTextErrorSpace
            maxLength={255}
          />
          <FormInput
            name="nomeContratado"
            label="Nome do contratado"
            className="p-col-12"
            hideTextErrorSpace
            maxLength={255}
          />
          <FormInput
            name="cnes"
            label="CNES"
            type="number"
            className="p-col-12"
            hideTextErrorSpace
            maxLength={255}
          />

          <div className="p-col-12">
            <Button
              btnType="tonal"
              icon="pi pi-plus"
              label="Adicionar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>

          <AlertBox
            text="Há alterações a serem salvas."
            visible={hasInsetedBeforeSaved.current}
            className="p-mx-2"
          />

          <Separator />

          <div className="p-col-12">
            <div className="itemslistasexec">{renderList()}</div>
          </div>
        </div>

        {!!params?.id ? (
          <>
            <div className="p-col-12 p-md-6">
              <Button
                btnType="ghost"
                label="Cancelar"
                className="p-col-align-center p-d-flex"
                type="button"
                stretch
                onClick={() => navigate('/cadastros/usuario')}
              />
            </div>
            <div className="p-col-12 p-md-6">
              <Button
                label="Salvar"
                type="button"
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  saveProfissionalExecutante(nextStep);
                }}
                stretch
              />
            </div>
          </>
        ) : (
          <>
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
              Deseja preencher os dados opcionais?{' '}
            </SimpleText>
            <div className="p-col-12 p-md-6">
              <Button
                btnType="outline"
                label="Sim, preencher"
                className="p-col-align-center p-d-flex"
                type="button"
                onClick={() => {
                  setLoading(true);
                  saveProfissionalExecutante(nextStep);
                }}
                stretch
              />
            </div>
            <div className="p-col-12 p-md-6">
              <Button
                label="Não, concluir cadastro"
                type="button"
                loading={loading}
                onClick={() =>
                  saveProfissionalExecutante(() =>
                    navigate('/cadastros/usuario'),
                  )
                }
                stretch
              />
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default FormMasterCreateUserProfExecutante;
