import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/complexidade-paciente';

export const getComplexidadeLista = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetComplexidadePacienteDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: basePath,
    params,
    hideToast: true,
    ...options,
  });
};

export const getComplexidadePaciente = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetComplexidadePacienteDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};

const getComplexidadeById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetComplexidadePacienteDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

const postComplexidade = (
  data: PostComplexidadePacienteDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data,
    return: true,
    ...options,
  });
};

const putComplexidade = (
  id: number,
  data: PostComplexidadePacienteDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data,
    return: true,
    ...options,
  });
};

export const putStatusComplexidade = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/atualizar-status/${id}`,
    return: true,
    ...options,
  });
};

const deleteComplexidade = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

export const ComplexidadePacienteAPI = {
  getComplexidadePaciente,
  getComplexidadeById,
  postComplexidade,
  putComplexidade,
  putStatusComplexidade,
  deleteComplexidade,
  getComplexidadeLista,
};
