import dayjs from 'dayjs';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  ultrassom: any;
}

function PanelContent({ ultrassom }: PanelContentProps) {
  const isInactivated = !!ultrassom.justificativaInativacao;
  const dataTextClassName = `p-wordbreak-all p-mt-2 ${
    isInactivated ? 'line-through' : ''
  }`;
  const dataTextColor = isInactivated
    ? FONT_COLOR.COLOR_79
    : FONT_COLOR.COLOR_16;

  return (
    <div className="pre-natal-panel-content p-grid">
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Doppler
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.doppler ? 'Sim' : 'Não'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Líquido
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.liquido}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Trasnusencia nucal
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.translucenciaNucal}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Desc. Placenta
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.descPlacenta}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Vesícula
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.vesicula}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Osso Nasal
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.dadosOssoNasal}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data DPP
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={dataTextColor}
          medium
        >
          {ultrassom.dppUs ? dayjs(ultrassom.dppUs).format('DD/MM/YYYY') : '-'}
        </SimpleText>
      </div>

      <div className="p-col-12 p-d-flex">
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Adicionado por <b>{ultrassom.usuarioInclusao.nome}</b>{' '}
          {dayjs(ultrassom.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
}

export default PanelContent;
