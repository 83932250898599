import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/telemedicina-sala-atendimento';

const getSalaAtendimento = (
  params: {
    idAtendimento: string | number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<{ link: string }> => {
  return Http.get({
    service: 'agenda',
    url: `telemedicina/sala-atendimento/${params.idAtendimento}`,
    hideToast: true,
    ...options,
  });
};

const sendSalaAtendimento = (
  params: {
    idAtendimento: string | number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: `telemedicina/sala-atendimento/${params.idAtendimento}`,
    hideToast: true,
    ...options,
  });
};

const getInformacoes = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${params.codigoEmpresa}/${params.hashProfissionalSaude}/${params.hashPaciente}`,
    hideToast: true,
    ...options,
    ...options,
  });
};

const sendPacienteOnline = (
  params: {
    idAtendimento: string | number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/paciente-online/${params.idAtendimento}`,
    hideToast: true,
    ...options,
  });
};

const sendTermoConsentimento = (
  params: any,
  payload?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/termo/${params.idTermo}/atendimento/${params.idAtendimento}`,
    data: payload,
    hideToast: true,
    ...options,
  });
};

const sendFeedbackTelemedicina = (
  params: {
    nota: number;
    idAtendimento: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: `telemedicina/pesquisa-satisfacao/${params.idAtendimento}/nota/${params.nota}`,
    hideToast: true,
    ...options,
  });
};

const sendRemoverPacienteSala = (
  params: {
    idAtendimento: number | string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: `telemedicina/remover-paciente/${params.idAtendimento}`,
    hideToast: true,
    ...options,
  });
};
const sendPacienteLinkTelemedicina = (
  params: {
    idAtendimento: number | string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: `telemedicina/sala-atendimento/enviar-link-ao-paciente/${params.idAtendimento}`,
    ...options,
  });
};

const authorizationTelemedicina = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const form = new FormData();
  form.append('grant_type', 'client_credentials');

  return Http.post({
    service: 'security',
    url: `/oauth/token`,
    hideToast: true,
    headers: {
      'Content-Type': 'multipart/form-data',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Authorization: process.env.REACT_APP_BASIC_AUTH!,
    },
    data: form,
    ...options,
    ...options,
  });
};

export const TelemedicinaSalaAtendimentoAPI = {
  getSalaAtendimento,
  getInformacoes,
  sendPacienteOnline,
  sendTermoConsentimento,
  sendSalaAtendimento,
  authorizationTelemedicina,
  sendFeedbackTelemedicina,
  sendRemoverPacienteSala,
  sendPacienteLinkTelemedicina,
};
