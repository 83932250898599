import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface ViciosProps {
  disabled?: boolean;
  readOnly?: boolean | undefined;
  values?: Questionarios | undefined;
}

enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  ABSTINENCIA = 'ABSTINENCIA',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

const Vicios = ({ values, readOnly }: ViciosProps) => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();

  const viciosNaoSeAplica = watch('viciosNaoSeAplica');

  useEffect(() => {
    if (typeof viciosNaoSeAplica === 'boolean') {
      const valueNaoSeAplica = viciosNaoSeAplica
        ? Values.NAO_SE_APLICA
        : undefined;

      const fields = [
        'fumante',
        'fumanteDomicilio',
        'bebidaAlcoolica',
        'atividadeFisica',
        'medicamentoControlado',
      ];

      fields.forEach(field => {
        setValue(field, valueNaoSeAplica, {
          shouldValidate: viciosNaoSeAplica,
        });
      });
    }
  }, [setValue, viciosNaoSeAplica]);

  return (
    <Template
      title="Vícios"
      name="viciosNaoSeAplica"
      readOnly={readOnly}
      resetField={resetField}
      control={control}
    >
      <div className="container-vicios">
        <CardQuestions
          title="Você costuma fumar?"
          name="fumante"
          control={control}
          disabled={readOnly || viciosNaoSeAplica}
          defaultValue={values?.fumante}
          error={errors?.fumante?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="fumante"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Abstinência',
                value: Values.ABSTINENCIA,
              },
            ]}
            disabled={readOnly || viciosNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Fumante no domicílio?"
          name="fumanteDomicilio"
          control={control}
          disabled={readOnly || viciosNaoSeAplica}
          defaultValue={values?.fumanteDomicilio}
          error={errors?.fumanteDomicilio?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="fumanteDomicilio"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Abstinência',
                value: Values.ABSTINENCIA,
              },
            ]}
            disabled={readOnly || viciosNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Você consome bebidas alcoólicas?"
          name="bebidaAlcoolica"
          control={control}
          disabled={readOnly || viciosNaoSeAplica}
          defaultValue={values?.bebidaAlcoolica}
          error={errors?.bebidaAlcoolica?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="bebidaAlcoolica"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Abstinência',
                value: Values.ABSTINENCIA,
              },
            ]}
            disabled={readOnly || viciosNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Pratica exercícios físicos em seus horários de lazer?"
          name="atividadeFisica"
          control={control}
          disabled={readOnly || viciosNaoSeAplica}
          defaultValue={values?.atividadeFisica}
          error={errors?.atividadeFisica?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="atividadeFisica"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Abstinência',
                value: Values.ABSTINENCIA,
              },
            ]}
            disabled={readOnly || viciosNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Você está tomando alguma medicação de uso controlado?"
          name="medicamentoControlado"
          control={control}
          disabled={readOnly || viciosNaoSeAplica}
          defaultValue={values?.medicamentoControlado}
          error={errors?.medicamentoControlado?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="medicamentoControlado"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
              {
                label: 'Abstinência',
                value: Values.ABSTINENCIA,
              },
            ]}
            disabled={readOnly || viciosNaoSeAplica}
          />
        </CardQuestions>
      </div>
    </Template>
  );
};

export default Vicios;
