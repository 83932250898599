import * as Yup from 'yup';

const validationSchema = (camposDemograficosObrigatorios: boolean, estrangeiro: boolean) => (
  Yup.object().shape({
    ...(camposDemograficosObrigatorios && { 
      nomeSocial: Yup.string().required('O campo nome social é obrigatório'),
       idGenero: Yup.string().required('O campo gênero é obrigatório'),
       idRacaCor: Yup.string().required('O campo raça/cor é obrigatório'),
       rgNumero: Yup.string().required('O campo número é obrigatório'),
       rgOrgaoEmissor: Yup.string().required('O campo orgão emissor é obrigatório'),
       rgUf: Yup.string().required('O campo uf é obrigatório'),
       rgDataEmissao: Yup.date().required('O campo data de emissão é obrigatório'),
       complemento: Yup.string().required('O campo complemento é obrigatório'),
       cns: Yup.string().required('O campo número é obrigatório'),
       nomeResponsavelLegal: Yup.string().required('O campo nome é obrigatório'),
       idGrauParentesco: Yup.string().required('O campo grau de parentesco é obrigatório'),
       cpfResponsavelLegal: Yup.string().required('O campo cpf é obrigatório')
    }),
    ...(camposDemograficosObrigatorios && estrangeiro && {
      idPaisEmissorPassaporte: Yup.string().required('O campo país emissor é obrigatório'),
      passaporteDataValidade: Yup.date().required('O campo data de validade é obrigatório'),
      passaporteDataEmissao: Yup.date().required('O campo data de emissão é obrigatório')
    }),
  })
);

export default validationSchema;