import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useLocation } from 'react-router';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { checkAuth } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { DialogDelete } from 'src/components/DialogDelete/DialogDelete';

export const AcervoFormularioCreatePontuacaoRow = ({
  item,
  handleEdit,
  handleDelete,
}: {
  item: IPontuacaoFormularioDinamicoItem;
  handleEdit(item: IPontuacaoFormularioDinamicoItem): void;
  handleDelete(id: number): void;
}) => {
  const { state } = useLocation();
  const {
    user: { authorities },
  } = useAppSelector(state => state);

  const disclosure = useDisclosure({ opened: false });
  const deleteDialog = useDisclosure({ opened: false });

  const menu = useRef<TieredMenu>(null);

  const viewOnly = state?.to !== 'edit';

  return (
    <div className="p-col-12 p-flex-wrap row content-row p-ai-center">
      <div className="p-col-6 p-d-flex p-flex-column p-gap-2 p-py-0">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Nome
        </SimpleText>
        <SimpleText>{item.nome}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2 p-py-0">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Intervalo de pontuação
        </SimpleText>
        <div className="p-d-flex p-gap-2 p-ai-center">
          <SimpleText fontSize="xxs" fontColor="color_40" medium>
            mínimo{' '}
            <SimpleText fontColor="primary" fontSize="xs">
              {item.intervaloMinimo}
            </SimpleText>
          </SimpleText>
          <SimpleText fontSize="xxs" fontColor="color_40" medium>
            máximo{' '}
            <SimpleText fontColor="primary" fontSize="xs">
              {item.intervaloMaximo}
            </SimpleText>
          </SimpleText>
        </div>
      </div>
      <div className="p-col p-d-flex p-gap-2 p-jc-end p-ai-center">
        <Divider
          layout="vertical"
          className="p-px-0 p-mx-0"
          style={{
            height: '8px',
          }}
        />

        <Button
          btnType="gray"
          icon="fas fa-ellipsis"
          onClick={e => menu.current?.toggle(e)}
          disabled={viewOnly}
        />
        <Button
          btnType="gray"
          icon={disclosure.isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          onClick={() => disclosure.toggle()}
          disabled={viewOnly}
        />
      </div>
      <TieredMenu
        popup
        ref={menu}
        model={[
          {
            label: 'Editar',
            command: () => handleEdit(item),
            disabled: checkAuth(
              authorities,
              'ADM_MANTER_PONTUACAO_FORMULARIO_DINAMICO_ALTERAR',
            ),
          },
          {
            label: 'Apagar',
            command: () => deleteDialog.open(),
            className: 'error',
            disabled: checkAuth(
              authorities,
              'ADM_MANTER_PONTUACAO_FORMULARIO_DINAMICO_EXCLUIR',
            ),
          },
        ]}
        style={{
          width: '6rem',
        }}
      />

      {disclosure.isOpen && (
        <div className="p-col-12 p-flex-column row bg-white p-gap-1">
          <SimpleText fontColor="color_40" fontSize="xxs">
            Mensagem para o paciente:
          </SimpleText>
          <SimpleText>{item.mensagemPaciente}</SimpleText>
        </div>
      )}

      {deleteDialog.isOpen && (
        <DialogDelete
          onConfirm={() => handleDelete(item.id)}
          subtitle="Após apagar a pontuação, você não terá mais acesso a ela. Deseja realmente apagar?"
          {...deleteDialog}
        />
      )}
    </div>
  );
};
