import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const items = [
  {
    label: 'Todos',
    value: 'TODOS',
  },
  {
    label: 'Consulta',
    value: 'CONSULTA',
  },
  {
    label: 'Procedimento',
    value: 'PROCEDIMENTO',
  },
];

type FieldTipoProntuarioProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldTipoProntuario = ({
  name = 'queTenha',
  label,
  ...rest
}: FieldTipoProntuarioProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={items || []}
      errorMsg={errors?.[name]?.message}
      filter={false}
      {...rest}
    />
  );
};
