import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/centro-custo';
export const getCentroCusto = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    params: params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getCentroCustoById = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const postCentroCusto = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data,
    throwError: true,
    ...options,
  });
};

export const putCentroCusto = (
  id: any,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data,
    throwError: true,
    return: true,

    ...options,
  });
};

export const deleteCentroCusto = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    throwError: true,
    return: true,
    ...options,
  });
};

const CentroCustoAPI = {
  getCentroCusto,
  getCentroCustoById,
  postCentroCusto,
  putCentroCusto,
  deleteCentroCusto,
};

export default CentroCustoAPI;
