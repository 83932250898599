import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';

import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Timeline, {
  TimelineImperativeAPI,
} from 'src/components/Timeline/Timeline';

import ProtocoloAtendimentoCreateFormCampos from './ProtocoloAtendimentoCreateFormCampos';
import ProtocoloAtendimentoCreateFormVinculos from './ProtocoloAtendimentoCreateFormVinculos';

import './ProtocoloAtendimento.scss';

export const ProtocoloAtendimento = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const timelineRef = useRef<TimelineImperativeAPI>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ProtocoloAtendimentoAPI.getProtocoloAtendimentoById(id!, {
        throwError: true,
      })
        .then(data => {
          setData(data);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleSubmitFormVinculos = (value: any) => {
    setData({ ...data, ...value });
    timelineRef.current?.next?.();
  };

  const handleGoBack = () => {
    timelineRef.current?.previous?.();
  };

  return (
    <Page>
      <div id="protocolo-atendimento">
        <PageHeader
          title="Novo protocolo de atendimento"
          subtitle="Edite os campos texto e vínculos dos protocolos"
        />
        <Page content white>
          <Skeleton loading={loading} height="50vh">
            <Timeline
              ref={timelineRef}
              values={[
                {
                  title: 'Configurações e vínculos',
                  component: (
                    <ProtocoloAtendimentoCreateFormVinculos
                      data={data}
                      handleSubmit={handleSubmitFormVinculos}
                    />
                  ),
                },
                {
                  title: 'Campos preenchidos',
                  component: (
                    <ProtocoloAtendimentoCreateFormCampos
                      data={data}
                      handleGoBack={handleGoBack}
                    />
                  ),
                  disabled: !Object.keys(data)?.length,
                },
              ]}
            />
          </Skeleton>
        </Page>
      </div>
    </Page>
  );
};
