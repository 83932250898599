import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/login';

export const sendValidarCodigo = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/validarCodigo`,
    data: payload,
    return: true,
    headers: {
      Authorization: '',
    },
    ...options,
  });
};

export const validarEmailPortal = (
  hashConfirmation: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/validar-email/${hashConfirmation}`,
    headers: {
      Authorization: '',
    },
    successMessage: 'Email confirmado, realize o login.',
    ...options,
  });
};

const ValidarCodigoAPI = {
  sendValidarCodigo,
  validarEmailPortal,
};

export default ValidarCodigoAPI;
