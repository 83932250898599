import { useCallback, useEffect, useMemo, useState } from 'react';

import CiapAPI from 'src/APIs/ProntuarioAPI/CiapAPI/CiapAPI';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Dialog from 'src/components/Dialog/Dialog';
import './ModalBuscaAvancada.scss';

interface ModalBuscaAvancadaProps extends DisclosureType {
  listaComorbidades: any[];
  getCiaps: (idAtendimento: any) => void;
  idAtendimento: any;
  getComorbidades: () => void;
  adicionarComorbidade: (cid: any) => void;
  removerComorbidade: (cid: any, idComorbidade: any) => void;
}

function ModalBuscaAvancada({
  close,
  isOpen,
  listaComorbidades,
  getCiaps,
  getComorbidades,
  idAtendimento,
  adicionarComorbidade,
  removerComorbidade,
}: ModalBuscaAvancadaProps) {
  const { agenda } = useAppSelector((state: RootState) => state);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [ciaps, setCiaps] = useState<any>();
  const [favoritos, setFavoritos] = useState<any[]>([]);
  const [ciapsSelecionadas, setCiapsSelecionadas] = useState<any[]>([]);
  const [versoesCiaps, setVersoesCiaps] = useState<any[]>([]);
  const [versaoCiapSelecionada, setVersaoCiapSelecionada] =
    useState<any>('CIAP2');
  const [selectedCiaps, setSelectedCiaps] = useState<any[]>([]);

  const closeModal = () => {
    setSearchQuery('');
    setCiapsSelecionadas([]);
    setCiaps(undefined);
    setFavoritos([]);
    close();
  };

  const loadFavoritos = useCallback(async () => {
    const response = await ComorbidadeAPI.getFavoritos(
      agenda?.profissionalAtivo?.id,
    );

    if (Array.isArray(response) && !!response.length) setFavoritos(response);
  }, [agenda?.profissionalAtivo?.id]);

  useEffect(() => {
    const getVersoesCiap = async () => {
      const response = await CiapAPI.getVersoesCiaps();
      setVersoesCiaps(response.data);
    };
    loadFavoritos();
    getVersoesCiap();
    getComorbidades();
  }, [getComorbidades, loadFavoritos]);

  useEffect(() => {
    ciapsSelecionadas.forEach((ciap: any) => {
      if (
        listaComorbidades?.some(
          (comorbidade: any) =>
            comorbidade.ciap[0]?.idCiap === ciap.id &&
            !selectedCiaps?.some(
              (ciap: any) => ciap.id === comorbidade.ciap[0]?.idCiap,
            ),
        )
      ) {
        setSelectedCiaps([...selectedCiaps, ciap]);
      }
    });
  }, [ciapsSelecionadas, listaComorbidades, selectedCiaps]);

  const pesquisarCiaps = useCallback(
    async (params: any) => {
      getComorbidades();
      const response = await CiapAPI.buscaCiaps({
        chave: searchQuery,
        versao: versaoCiapSelecionada,
        ...params,
      });
      setCiaps(response.data.list);
    },
    [getComorbidades, searchQuery, versaoCiapSelecionada],
  );

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const ciapsFavoritos = useMemo(
    () =>
      favoritos
        .filter(favorito => !!favorito?.idCiap)
        .map(favorito => favorito.ciap.nome),
    [favoritos],
  );

  const resultadoCiaps = useMemo(() => {
    const onSelectValue = (ciap: any) => {
      if (!ciapsSelecionadas.some(c => c.id === ciap.id)) {
        setCiapsSelecionadas([...ciapsSelecionadas, ciap]);
      }
    };

    const handleFavoritar = async (ciap: any) => {
      const response = await ComorbidadeAPI.favoritarComorbidade(
        agenda?.profissionalAtivo?.id,
        { idCiap: ciap.id },
      );

      if (response?.status === 200) loadFavoritos();
    };

    return (
      <div className="p-d-flex p-flex-column p-col-12">
        <SimpleText
          className="p-mb-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Resultado para: {searchQuery}
        </SimpleText>
        <div className="lista-ciap">
          {ciaps?.map((ciap: any, idx: number) => (
            <div key={idx} className="ciap-item p-grid p-col-12 p-my-1">
              <div
                className="p-col-11 p-grid"
                onClick={() => onSelectValue(ciap)}
              >
                <SimpleText
                  className="p-col-12"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                  medium
                >
                  {ciap.codigo} -{' '}
                  {capitalizeFirstLetter(ciap.nome.toLowerCase())}
                </SimpleText>
              </div>
              <div
                className="p-col-1 p-d-flex p-jc-center p-ai-center"
                onClick={() => handleFavoritar(ciap)}
              >
                <i
                  className={`fas fa-star favoritar-button ${
                    ciapsFavoritos.includes(ciap.nome) ? 'favoritado' : ''
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [
    agenda?.profissionalAtivo?.id,
    ciaps,
    ciapsFavoritos,
    ciapsSelecionadas,
    loadFavoritos,
    searchQuery,
  ]);

  const listaCiaps = useMemo(() => {
    const onRemoveCiap = (ciap: any) => {
      setCiapsSelecionadas(ciapsSelecionadas.filter(c => c.id !== ciap.id));
    };

    const onCiapChange = (e: { value: any; checked: boolean }) => {
      const _selectedCiaps = [...selectedCiaps];

      if (e.checked) {
        _selectedCiaps.push(e.value);
      } else {
        for (let i = 0; i < _selectedCiaps.length; i++) {
          const selectedCiap = _selectedCiaps[i];

          if (selectedCiap.id === e.value.id) {
            _selectedCiaps.splice(i, 1);
            break;
          }
        }
      }

      setSelectedCiaps(_selectedCiaps);
    };

    return (
      <>
        {ciapsSelecionadas?.map((ciap, idx) => (
          <div key={idx} className="p-col-12 ciap-item p-my-2">
            <div className="p-d-flex p-jc-between p-ai-center">
              <div className="p-d-flex p-ai-center w-100">
                <SimpleText
                  className="p-ml-2"
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  ({ciap.codigo}){' '}
                  {capitalizeFirstLetter(ciap.nome.toLowerCase())}
                </SimpleText>
                <Checkbox
                  className="p-ml-3"
                  name={ciap.id}
                  label="Adicionar na lista de problemas"
                  value={ciap}
                  onChange={onCiapChange}
                  checked={selectedCiaps.some(
                    (item: any) => item.id === ciap.id,
                  )}
                  disabled={listaComorbidades?.some(
                    (comorbidade: any) =>
                      comorbidade.ciap[0]?.idCiap === ciap.id &&
                      comorbidade.idAtendimento !== Number(idAtendimento),
                  )}
                />
              </div>
              <div>
                <i
                  className="fas fa-trash p-mr-2"
                  onClick={() => onRemoveCiap(ciap)}
                />
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }, [ciapsSelecionadas, idAtendimento, listaComorbidades, selectedCiaps]);

  const addCiaps = async (idAtendimento: any) => {
    ciapsSelecionadas.forEach(ciap => {
      if (ciap.checked === true && ciap.disabled === false) {
        adicionarComorbidade(ciap);
      }
    });
    const payload = ciapsSelecionadas.map(ciap => ciap.id);
    const response = await UtilsAPI.postAddCiap(idAtendimento, payload);
    if (response?.status === 201) {
      ciapsSelecionadas.forEach((ciap: any) => {
        if (
          selectedCiaps.some((item: any) => item.id === ciap.id) &&
          !listaComorbidades.some(
            (comorbidade: any) => comorbidade.ciap[0]?.idCiap === ciap.id,
          )
        ) {
          adicionarComorbidade(ciap);
        } else if (
          listaComorbidades?.some(
            (comorbidade: any) => comorbidade.ciap[0]?.idCiap === ciap.id,
          ) &&
          !selectedCiaps.some((item: any) => item.id === ciap.id)
        ) {
          removerComorbidade(
            ciap,
            listaComorbidades?.filter(
              comorbidade => comorbidade.ciap[0]?.idCiap === ciap.id,
            )[0].id,
          );
        }
      });
      getCiaps(String(idAtendimento));
      closeModal();
    }
  };

  return (
    <Dialog header="Busca avançada" visible={isOpen} onHide={closeModal}>
      <div id="busca-avancada" className="p-grid">
        <div className="busca p-grid p-col-12">
          <div className="p-col-7">
            <TextInputSearch
              name="pesquisa"
              placeholder="Pesquisar por descrição ou códico"
              value={searchQuery}
              onChange={setSearchQuery}
              onKeyPress={e => {
                if (e.key === 'Enter') pesquisarCiaps(searchQuery);
              }}
            />
          </div>
          <div className="p-col-3">
            <Dropdown
              options={versoesCiaps}
              value={versaoCiapSelecionada}
              onChange={e => setVersaoCiapSelecionada(e.value)}
            />
          </div>
          <div className="p-col-2">
            <Button
              label="Pesquisar"
              className="pesquisar"
              onClick={() => {
                pesquisarCiaps(searchQuery);
              }}
              disabled={!searchQuery}
              stretch
            />
          </div>
        </div>
        {ciaps && <div className="p-col-12 p-grid">{resultadoCiaps}</div>}
        {!ciaps && (
          <div className="p-col-12 p-d-flex p-ai-center p-jc-center p-mt-4">
            <p>Faça a busca utilizando os campos acima.</p>
          </div>
        )}
        <div className="p-col-12 lista-adicionados">{listaCiaps}</div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            className="p-as-center"
            label="Cancelar"
            btnType="ghost"
            onClick={() => closeModal()}
            stretch
          />
          <Button
            label="Salvar"
            onClick={() => addCiaps(idAtendimento)}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ModalBuscaAvancada;
