import { useMemo } from 'react';

import { MenuItem } from 'primereact/menuitem';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

import './PanelHeader.scss';

const diagnosticos: any = {
  Suspeito: 'Suspeito',
  Confirmado: 'Confirmado',
};

const labelTypeDiagnosticos: Record<string, SPLIT_BTN_TYPE> = {
  Suspeito: SPLIT_BTN_TYPE.YELLOW,
  Confirmado: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
};

const nivelDiagnosticos: any = {
  Principal: 'Principal',
  Secundário: 'Secundário',
};

const labelTypeNivelDiagnosticos: Record<string, SPLIT_BTN_TYPE> = {
  Principal: SPLIT_BTN_TYPE.YELLOW,
  Secundário: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
};

const situacoes: any = {
  ATIVO: 'Ativo',
  INATIVO: 'Inativo',
  RESOLVIDO: 'Resolvido',
};

const labelType: Record<string, SPLIT_BTN_TYPE> = {
  ATIVO: SPLIT_BTN_TYPE.YELLOW,
  RESOLVIDO: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
  INATIVO: SPLIT_BTN_TYPE.GREEN,
};

interface PanelHeaderProps {
  problema: Comorbidade;
  isCollapsed: boolean;
  optionsMenu: JSX.Element;
  onToggle: () => void;
  onAlterarSituacao: (situacao: string) => void;
  onAlterarDiagnostico: (dagnostico: string, type: string) => void;
}

function PanelHeader({
  problema,
  isCollapsed,
  optionsMenu,
  onToggle,
  onAlterarSituacao,
  onAlterarDiagnostico,
}: PanelHeaderProps) {
  const { atendimentoStatus } = useAtendimento();

  const isCid = !!problema?.cid?.length;

  const headerTextClassName =
    problema.status === 'INATIVO' ? 'comorbidade-inativa' : '';
  const dataTextClassName = `p-mt-2 ${
    problema.status === 'INATIVO' ? 'line-through comorbidade-inativa' : ''
  }`;

  const splitBtnType = labelType[problema.situacao] as SPLIT_BTN_TYPE;

  const splitBtnTypeDiagnosticos = labelTypeDiagnosticos[
    problema?.estadoDiagnostico || 'CONFIRMADO'
  ] as SPLIT_BTN_TYPE;

  const splitBtnTypeNivelDiagnosticos = labelTypeNivelDiagnosticos[
    problema?.nivelDiagnostico || 'PRINCIPAL'
  ] as SPLIT_BTN_TYPE;

  const disableButtons =
    atendimentoStatus !== 'ATENDENDO' &&
    atendimentoStatus !== 'ATENDENDO_TRIAGEM';

  const splitButtonItems = () => {
    const items: MenuItem[] = [];

    for (const situacao in situacoes) {
      if (situacao !== problema.situacao) {
        items.push({
          label: situacoes[situacao],
          command: () => onAlterarSituacao(situacao),
        });
      }
    }

    return items;
  };

  const splitButtonItemsDiagnosticos = () => {
    const items: MenuItem[] = [];

    for (const diagnostico in diagnosticos) {
      if (diagnostico !== problema?.estadoDiagnostico) {
        items.push({
          label: diagnosticos[diagnostico],
          command: () => onAlterarDiagnostico(diagnostico, 'estadoDiagnostico'),
        });
      }
    }

    return items;
  };

  const splitButtonItemsNivelDiagnosticos = () => {
    const items: MenuItem[] = [];

    for (const nivelDiagnostico in nivelDiagnosticos) {
      if (nivelDiagnostico !== problema?.nivelDiagnostico) {
        items.push({
          label: nivelDiagnosticos[nivelDiagnostico],
          command: () =>
            onAlterarDiagnostico(nivelDiagnostico, 'nivelDiagnostico'),
        });
      }
    }

    return items;
  };

  const comorbidadeNome = useMemo(() => {
    if (problema?.ciap?.[0]?.ciap)
      return `${problema?.ciap?.[0]?.ciap?.codigo} - ${problema?.ciap?.[0]?.ciap?.nome}`;

    return `${problema?.cid?.[0]?.cid?.codigo} - ${problema?.cid?.[0]?.cid?.nome}`;
  }, [problema.ciap, problema?.cid]);

  return (
    <div className="panel-header-comorbidades p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-8 p-md-4 p-lg-7 p-xl-3  p-d-flex p-flex-column p-order-lg-0">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          {problema?.cid?.length ? 'CID' : 'CIAP'}
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {comorbidadeNome}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-sm-12 p-md-4 p-lg-4 p-xl-2 p-d-flex p-flex-column p-ai-start p-order-lg-2">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(problema.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className=" p-col-4 p-sm-4 p-md-4 p-lg-3 p-xl-1 retroativo-chip p-order-2 p-order-sm-3 p-order-md-0 p-order-lg-1">
        {problema.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="coluna-dados p-col-12 p-sm-8 p-md-9 p-lg-8 p-xl-4 p-d-flex p-gap-1 p-order-lg-2 p-order-xl-1 p-jc-lg-end">
        {isCid && problema?.cid?.[0] ? (
          <>
            <SplitButton
              className="p-mt-2"
              menuClassName="menu-comorbidade-splitbutton"
              label={diagnosticos[problema?.estadoDiagnostico] || 'Diagnóstico'}
              btnType={splitBtnTypeDiagnosticos}
              model={splitButtonItemsDiagnosticos()}
              disabled={disableButtons}
            />

            <SplitButton
              className="p-mt-2"
              menuClassName="menu-comorbidade-splitbutton"
              btnType={splitBtnTypeNivelDiagnosticos}
              label={nivelDiagnosticos[problema?.nivelDiagnostico] || 'Nível'}
              model={splitButtonItemsNivelDiagnosticos()}
              disabled={disableButtons}
            />
          </>
        ) : null}

        <SplitButton
          className="p-mt-2"
          menuClassName="menu-comorbidade-splitbutton"
          btnType={splitBtnType}
          label={situacoes[problema.situacao]}
          model={splitButtonItems()}
          disabled={disableButtons}
        />
      </div>
      <div className="coluna-acoes p-col-4 p-md-3 p-lg-2 p-xl-2 p-d-flex p-jc-between p-jc-md-end p-ai-center p-order-lg-1 p-order-xl-2">
        <div className="p-mr-2">
          <Lembrete ativo={problema.lembrete} />
        </div>

        {optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
}

export default PanelHeader;
