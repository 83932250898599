import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

interface IPaciente {
  id: number;
  nome: string;
  dataNascimento: string;
  cpf: string;
  cartaoPlano: string;
  idSexo: number;
  sexoDescricao: string;
  foto: string;
  fotoUrl: string;
  idadeAnos: number;
  idadeMeses: number;
  idadeDias: number;
}

interface IMedico {
  id: number;
  nome: string;
}

export interface AtendimentoState {
  id?: number | string;
  dataAgendamento?: string;
  status?: string;
  medico?: IMedico;
  paciente?: IPaciente;
  sairPEPDialog?: DisclosureType;
  tempoGastoAtendimento?: string;
  particular?: boolean;
  retorno?: boolean;
  memed?: any;
}

const initialState: AtendimentoState | any = {};

export const atendimentoSlice = createSlice({
  name: 'atendimento',
  initialState,
  reducers: {
    setAtendimento: (
      state: AtendimentoState,
      action: PayloadAction<AtendimentoState>,
    ) => {
      return { ...state, ...action.payload };
    },
    onSairAtendimento: (
      state: AtendimentoState,
      action: PayloadAction<any>,
    ) => {
      state.sairPEPDialog?.open({
        state: {
          route: action.payload.route,
        },
      });
    },
    resetAtendimento: () => initialState,
  },
});

export const { setAtendimento, onSairAtendimento, resetAtendimento } =
  atendimentoSlice.actions;

export default atendimentoSlice.reducer;
