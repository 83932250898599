import { useContext } from 'react';

import {
  Dropdown as PrimeDropdown,
  DropdownProps as PrimeDropdownProps,
} from 'primereact/dropdown';

import ThemeContext from 'src/core/themes/ThemeContext';

import ComponentsLabel, {
  ComponentsLabelProps,
} from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

import './Dropdown.scss';

export type DropdownProps = Omit<PrimeDropdownProps, 'disabled'> &
  Omit<ComponentsLabelProps, 'name' | 'label' | 'required'> & {
    name?: string;
    options?: { label: any; value: any }[];
    label?: string | undefined;
    responsive?: boolean;
    disabled?: boolean | undefined;
    className?: string;
    errorMsg?: string | undefined;
    carregandoFiltro?: boolean;
    hideTextErrorSpace?: boolean;
    labelOverflow?: 'visible' | 'hidden';
    textHelper?: string;
    loading?: boolean;
  };

const Dropdown = ({
  name,
  className,
  label,
  responsive,
  disabled,
  options,
  errorMsg,
  required,
  carregandoFiltro,
  filter = true,
  panelClassName,
  hideTextErrorSpace = true,
  labelOverflow = 'visible',
  textHelper,
  loading,
  emptyMessage,
  ...props
}: DropdownProps) => {
  const { theme } = useContext(ThemeContext);

  const renderLabel = () => {
    if (label || label === '' || required) {
      return (
        <ComponentsLabel
          name={name}
          label={label}
          overflowX={labelOverflow}
          sublabel={props.sublabel}
          labelTooltip={props.labelTooltip}
          required
        />
      );
    }
    return <></>;
  };

  return (
    <div
      className={`Dropdown ${theme} ${errorMsg ? 'error' : ''} ${
        textHelper ? 'warn' : ''
      } ${className || ''}`}
      key={name}
    >
      {renderLabel()}

      <PrimeDropdown
        key={name}
        {...props}
        name={name || ''}
        filter={filter}
        options={options || []}
        className={className || ''}
        disabled={disabled || !!loading}
        emptyMessage={emptyMessage ?? 'Nenhuma opção encontrada'}
        dropdownIcon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'}
        emptyFilterMessage={
          carregandoFiltro ? (
            <GridListLoading />
          ) : (
            props.emptyFilterMessage || 'Nenhum resultado encontrado'
          )
        }
        panelClassName={`${panelClassName} ${theme}`}
      />

      <div
        className="p-mt-1"
        style={{ minHeight: hideTextErrorSpace ? '0' : '19px' }}
      >
        <SimpleText
          className={!!errorMsg ? 'error' : 'warn'}
          fontSize={FONT_SIZE.XXXS}
        >
          {errorMsg || textHelper || ''}
        </SimpleText>
      </div>
    </div>
  );
};

export default Dropdown;
