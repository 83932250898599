import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './CardInfo.scss';

export const CardInfo = ({ text }: { text: string }) => {
  return (
    <div className="card-info-tele">
      <SimpleText className="p-text-center" fontColor={FONT_COLOR.COLOR_96}>
        {text}
      </SimpleText>
    </div>
  );
};
