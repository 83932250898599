import Http, { HttpParams } from 'src/core/http/Http';

export const generateTokenSigilo = (
  payload: PostSigiloDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostSigiloDTO> => {
  return Http.post({
    service: 'pep',
    url: '/liberar-prontuario/gerar',
    data: payload,
    successMessage: 'Token gerado com sucesso.',
    return: true,
    ...options,
  });
};

export const validateTokenSigilo = (
  payload: PostSigiloTokenValidationDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostSigiloTokenValidationDTO> => {
  return Http.post({
    service: 'pep',
    url: '/liberar-prontuario/validar',
    data: payload,
    successMessage: 'Token validado com sucesso.',
    return: true,
    ...options,
  });
};

export const revokeTokenSigilo = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSigiloDTO> => {
  return Http.delete({
    service: 'pep',
    url: `/liberar-prontuario/${id}`,
    successMessage: 'Token revogado com sucesso.',
    ...options,
  });
};

export const loadTokenSigiloToRevoke = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSigiloDTO> => {
  return Http.get({
    service: 'pep',
    url: `/liberar-prontuario/cosulta-liberacao/${id}`,
    hideToast: true,
    ...options,
  });
};

export const updateRevokeSigilo = (
  id: number,
  tipoSigilo: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `/liberar-prontuario/revogar/${id}?tipoSigilo=${tipoSigilo}`,
    return: true,
    ...options,
  });
};

const SigiloAPI = {
  generateTokenSigilo,
  validateTokenSigilo,
  revokeTokenSigilo,
  loadTokenSigiloToRevoke,
  updateRevokeSigilo,
};

export default SigiloAPI;
