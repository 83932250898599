import { useCallback, useState, useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import { CartoesPacienteAPI } from 'src/APIs/AdminAPI/CartoesPacienteAPI/CartoesPacienteAPI';
import avatarSemFoto from 'src/assets/imgs/avatar-sem-foto.png';

import Avatar from 'src/components/Avatar/Avatar';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import Switch from 'src/components/Switch/Switch';
import CardOption from 'src/pages/Emed/Pacientes/CentralPacientes/components/CardOption/CardOption';

const CardCartao = ({
  cartao,
  setCartoes,
  paciente,
  setDadosEditarCartao,
  setExibirForm,
  onRemove,
}: any) => {
  const menuEllipsis = useRef<TieredMenu>(null);

  const [cartaoAtivo, setCartaoAtivo] = useState(cartao.status);

  const loadCartoes = useCallback(() => {
    CartoesPacienteAPI.loadCartoesPaciente(paciente?.id).then((resp: any) => {
      setCartoes(resp);
    });
  }, [paciente?.id, setCartoes]);

  const hendleActiveCartao = useCallback(
    async (e, idCartao: any) => {
      setCartaoAtivo(e.value);
      e.value
        ? await CartoesPacienteAPI.activeCartao(idCartao)
        : await CartoesPacienteAPI.inactiveCartao(idCartao);

      loadCartoes();
    },
    [loadCartoes],
  );

  const onEdit = () => {
    setDadosEditarCartao(cartao);
    setExibirForm(true);
  };

  const menuItems = [
    {
      label: 'Editar',
      command: onEdit,
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onRemove(cartao.id),
    },
  ];

  const handleSetCartaoPrincipal = () => {
    CartoesPacienteAPI.updateCartao(
      {
        ...cartao,
        principal: true,
      },
      {
        throwError: true,
      },
    )
      .then(() => loadCartoes())
      .catch(() => {});
  };

  return (
    <div key={cartao.id} className="cartao-paciente">
      <CardOption
        className="p-w-100 p-grid p-gap-1 p-align-center card-plano-saude"
        checked={cartao.principal}
        onChange={() => handleSetCartaoPrincipal()}
      >
        <div className="p-col-2 convenio-img">
          <Avatar
            image={cartao.convenio?.urlLogotipo || avatarSemFoto}
            imageAlt="convenio"
            size="large"
          />
        </div>
        <div className="p-col-12 p-sm-4 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            {cartao.convenio?.nomeFantasia || cartao.convenio.razaoSocial}
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16}>
            {cartao.numeroCartao.replace(
              /(\d{1})(\d{3})(\d{12})(\d{1})/,
              '$1 $2 $3 $4',
            )}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            Val. {dayjs(cartao.dataValidadeCartao).format('DD/MM/YYYY')}
          </SimpleText>
        </div>
        <div className="p-col-4 p-sm-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            Tipo plano
          </SimpleText>
          <SimpleText>{cartao.tipoPlano?.descricao || '-'}</SimpleText>
        </div>
        <div className="p-col p-d-flex p-gap-2 p-ai-center p-jc-end">
          <Switch
            checked={cartaoAtivo}
            onChange={e => hendleActiveCartao(e, cartao.id)}
          />

          <Separator layout="vertical" />

          <IconButton
            btnType={IconBtnTypes.GREY}
            icon="fas fa-ellipsis-h"
            onClick={event => menuEllipsis?.current?.toggle(event)}
          />

          <TieredMenu
            className="options-convenio-paciente"
            ref={menuEllipsis}
            model={menuItems}
            popup
          />
        </div>
      </CardOption>
    </div>
  );
};

export default CardCartao;
