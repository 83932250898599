import { useState } from 'react';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface CardComunicadoProps {
  data: ComunicadoDTO;
  reloadList: () => void;
}

export interface IConteudoModal {
  titulo: string;
  mensagem: string;
  labelBtnConfirmar: string;
  labelBtnCancelar: string;
  onConfirmar: (...args: any) => void;
}

const CardComunicado: React.FC<CardComunicadoProps> = ({
  data,
  reloadList,
}: any): JSX.Element => {
  const [exibirModalComunicado, setExibirModalComunicado] = useState(false);
  const [conteudoModal, setConteudoModal] = useState<IConteudoModal>(
    {} as IConteudoModal,
  );
  const { isMobile } = useSize();

  return (
    <div className="comunicado">
      {isMobile ? (
        <MobileRow
          data={data}
          setExibirModalComunicado={setExibirModalComunicado}
          setConteudoModal={setConteudoModal}
        />
      ) : (
        <CardItem>
          <DesktopRow
            data={data}
            setExibirModalComunicado={setExibirModalComunicado}
            setConteudoModal={setConteudoModal}
          />
        </CardItem>
      )}
      <Dialog
        visible={exibirModalComunicado}
        header={conteudoModal.titulo}
        footer={
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              onClick={() => setExibirModalComunicado(false)}
              label={conteudoModal.labelBtnCancelar}
              stretch
            />
            <Button
              btnType="danger"
              onClick={() => {
                conteudoModal.onConfirmar(data, reloadList);
                setExibirModalComunicado(false);
              }}
              label={conteudoModal.labelBtnConfirmar}
              stretch
            />
          </div>
        }
        className="modal-inativar-comunicado"
        onHide={() => setExibirModalComunicado(false)}
      >
        <div className="p-d-flex p-justify-center p-mb-1">
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {conteudoModal.mensagem}
          </SimpleText>
        </div>
      </Dialog>
    </div>
  );
};

export default CardComunicado;
