import React, { useEffect, useState } from 'react';

import './SubtextoPergunta14.scss';
import { useLocation, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import AGAAPI from 'src/APIs/ProntuarioAPI/AGAAPI/AGAAPI';
import CircunferenciaPanturrilhaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaPanturrilhaAPI/CircunferenciaPanturrilhaAPI';
import PesoAlturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PesoAlturaAPI/PesoAlturaAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface SubtextoPergunta14Props {
  idPaciente: number | null;
}

const SubtextoPergunta14 = ({ idPaciente }: SubtextoPergunta14Props) => {
  const [imcPaciente, setImcPaciente] = useState<any>(null);
  const [circunferenciaPanturrilha, setCircunferenciaPanturrilha] =
    useState<any>(null);
  const [velocidadeDeMarcha, setVelocidadeDeMarcha] = useState<any>();

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { consultorios } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    (async () => {
      const response =
        await CircunferenciaPanturrilhaAPI.getCircunferenciaPanturrilha({
          idAtendimento,
          sortDirection: 'DESC',
          sortBy: 'dataInclusao',
        });
      setCircunferenciaPanturrilha(response.list[0] || null);
    })();
  }, [idAtendimento]);

  useEffect(() => {
    (async () => {
      const response = await PesoAlturaAPI.getPesoAltura({
        idAtendimento,
        sortDirection: 'DESC',
        sortBy: 'dataInclusao',
      });
      setImcPaciente(response.list[0] || null);
    })();
  }, [idAtendimento]);

  useEffect(() => {
    (async () => {
      const response = await AGAAPI.getVelociadeDeMarcha(idPaciente, {
        idConsultorio: consultorios.ativo?.id,
        idAgaFormulario: 11,
        idAgaPerguntaFormulario: 22,
        sortDirection: 'DESC',
        sortBy: 'dataOcorrencia',
      });
      setVelocidadeDeMarcha(response.list[0]?.respostas[0] || null);
    })();
  }, [consultorios.ativo?.id, idAtendimento, idPaciente]);

  return (
    <div className="p-grid">
      <SimpleText className="p-col-12" medium>
        1. Perda de peso não intencional de 4,5 kg ou 5% do peso corporal no
        último ano ou 6kg nos últimos 6 meses ou 3kg no último mês?{' '}
      </SimpleText>

      <div className="p-col-12 p-px-0">
        <SimpleText className="p-col" medium>
          2. Índice de Massa Corporal (IMC) {'<'} 22kg/m²?
        </SimpleText>

        <SimpleText className="p-col" fontColor={FONT_COLOR.COLOR_60}>
          IMC: <strong>{imcPaciente?.imc}</strong> •{' '}
          <i className="fas fa-calendar" />{' '}
          <strong>
            {imcPaciente?.dataInclusao
              ? dayjs(new Date(imcPaciente.dataInclusao)).format('DD/MM/YYYY')
              : ''}
          </strong>
        </SimpleText>
      </div>

      <div className="p-col-12 p-px-0">
        <SimpleText className="p-col" medium>
          3. O tamanho da circunferência da panturrilha {'<'} 31cm?
        </SimpleText>

        <SimpleText className="p-col" fontColor={FONT_COLOR.COLOR_60}>
          Circunferência da panturrilha (cm):{' '}
          <strong>
            {circunferenciaPanturrilha?.circunferenciaPanturrilha}
          </strong>{' '}
          • <i className="fas fa-calendar" />{' '}
          <strong>
            {circunferenciaPanturrilha?.dataInclusao
              ? dayjs(new Date(circunferenciaPanturrilha.dataInclusao)).format(
                  'DD/MM/YYYY',
                )
              : ''}
          </strong>
        </SimpleText>
      </div>

      <div className="p-col-12 p-px-0">
        <SimpleText className="p-col" medium>
          4. Tempo gasto no teste de velocidade da marcha (4m) {'<'} 5 segundos?
        </SimpleText>

        <SimpleText className="p-col" fontColor={FONT_COLOR.COLOR_60}>
          Velocidade da marcha (m/s):{' '}
          <strong>{velocidadeDeMarcha?.textoResposta}</strong>•{' '}
          <i className="fas fa-calendar" />{' '}
          <strong>
            {velocidadeDeMarcha?.dataOcorrencia
              ? dayjs(new Date(velocidadeDeMarcha?.dataOcorrencia)).format(
                  'DD/MM/YYYY',
                )
              : ''}
          </strong>
        </SimpleText>
      </div>
    </div>
  );
};

export default SubtextoPergunta14;
