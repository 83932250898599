import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

type IProps = {
  campos: any;
  reloadCampos: () => void;
};

export const useFixarFuncionalidade = ({ campos, reloadCampos }: IProps) => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const [campo, setCampo] = useState<any>([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!!campo.exibicaoCamposModelo?.includes('FIXO'));
  }, [campo]);

  useEffect(() => {
    setCampo(
      campos.filter((item: any) => item.funcionalidade === 'PRESCRICAO')[0] ||
        [],
    );
  }, [campos]);

  const handleFixar = async (value: boolean) => {
    setChecked(!!value);

    if (campo?.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }

    const exibixaoCampo =
      campo?.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campo.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  return { checked, handleFixar };
};
