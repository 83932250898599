import { useMemo, useRef } from 'react';

import { Line } from 'react-chartjs-2';

import { CategoryScale, ChartData, ChartOptions } from 'chart.js';
import ChartJS from 'chart.js/auto';
import dayjs from 'dayjs';
import { GraficoCrescimentoProps } from 'src/models/APIs/ProntuarioAPI/PreAtendimento/GraficoDeCrescimento/GraficoDeCrescimento';

import {
  pacienteSexoCor,
  validateDaysToMonth,
  validateMainPosition,
} from './utils/utils';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

ChartJS.register(CategoryScale);

export function GraficoCrescimentoChart({ paciente, chartData }: any) {
  const pacienteSexo = paciente?.sexoDescricao;

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const PCJson: GraficoCrescimentoProps[] = require(pacienteSexo === 'Masculino'
    ? './utils/data/PC_Mas.json'
    : './utils/data/PC_Fem.json');

  const getIdadePerimetro = useMemo(() => {
    return chartData.reduce((acc: any, curr: any) => {
      let newArray = acc;
      let idadeEmDias = dayjs(new Date(2022, curr.idadeMeses, 30)).diff(
        new Date(2022, 1, 0),
        'day',
      );

      if (curr.idadeMeses === 0) {
        idadeEmDias = 1;
      }

      if (idadeEmDias > 729) {
        idadeEmDias = 729;
      }

      if (!acc.length) {
        newArray = new Array(729).fill({ PC: null, MT: null });
      }

      newArray[idadeEmDias] = { PC: curr.perimetroCefalico, MT: idadeEmDias };
      return newArray;
    }, []);
  }, [chartData]);

  const basicData: ChartData<'line'> = {
    labels: PCJson.map(el => validateDaysToMonth(el.medidaTempo)),
    datasets: [
      {
        label: '',
        data: getIdadePerimetro.map((el: any) => ({
          x: el.MT,
          y: el.PC,
        })),
        borderColor: pacienteSexoCor[pacienteSexo],
        borderWidth: 3,
        spanGaps: true,
        pointBackgroundColor: '#fff',
      },
      {
        label: 'SD4',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd4Positivo })),
        borderColor: 'rgba(175, 177, 254, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD3',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd3Positivo })),
        borderColor: 'rgba(51, 51, 51, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD2',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd2Positivo })),
        borderColor: 'rgba(255, 80, 78, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD1',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd1Positivo })),
        borderColor: 'rgba(255, 193, 23, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD0',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd0 })),
        borderColor: 'rgba(61, 214, 59, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD1neg',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd1Negativo })),
        borderColor: 'rgb(255, 193, 23, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD2neg',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd2Negativo })),
        borderColor: 'rgba(255, 80, 78, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD3neg',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd3Negativo })),
        borderColor: 'rgba(51, 51, 51, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
      {
        label: 'SD4neg',
        data: PCJson.map(el => ({ x: el.medidaTempo, y: el.sd4Negativo })),
        borderColor: 'rgba(175, 177, 254, 0.6)',
        pointHoverRadius: 0,
        pointRadius: 0,
      },
    ],
  };

  const basicOptions: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 0.6,
    animation: false,
    datasets: {
      line: {
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
    },
    plugins: {
      title: {
        display: true,
        font: { size: 16, weight: 'bold' },
        position: 'bottom',
        text: 'Idade em meses',
      },
      subtitle: {
        display: true,
        position: 'left',
        padding: { bottom: 10 },
        font: { size: 16, weight: 'bold' },
        text: 'PC (centímetros)',
      },
      legend: {
        display: false,
        labels: {
          color: '#495057',
        },
      },
      tooltip: {
        boxPadding: 4,
        // Remove title from tooltip
        callbacks: {
          title: () => '',
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0;
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        suggestedMin: 0,
        min: 0,
        ticks: {
          color: '#495057',
          autoSkip: true,
          font: (ctx: any) =>
            validateMainPosition(ctx, {
              result: { weight: 'bold', size: 16, textAlign: 'center' },
              fallback: { weight: 'boldness', size: 14 },
            }),
          maxRotation: 1,
        },
        grid: {
          color: (ctx: any) =>
            validateMainPosition(ctx, {
              result: '#d5d5d5',
              fallback: '#ebedef',
            }),
        },
      },
      y: {
        ticks: {
          color: '#495057',
          autoSkip: true,
          stepSize: 2,
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  };

  const canvasRef = useRef<any>(null);

  return (
    <div id="graphic-element" className="chart-content" ref={canvasRef}>
      <div className="chartTitle">
        <SimpleText>Perímetro Cefálico X Idade</SimpleText>
      </div>

      <div className="chart-container" style={{ height: '420px' }}>
        <Line data={basicData} options={basicOptions} />
      </div>

      <div className="source">
        <SimpleText className="label">Fonte (WHO)</SimpleText>
      </div>
    </div>
  );
}
