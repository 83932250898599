import dayjs from 'dayjs';

export const formatFraseAlert = ({
  dataUltimaRealizacao,
  dataUltimaSolicitacao,
}: any) => {
  if (dataUltimaRealizacao) {
    const dataRealizacao = dayjs().diff(dataUltimaRealizacao, 'day');
    const diaPreffix = dataRealizacao > 1 ? 'dias' : 'dia';
    const diaText =
      dataRealizacao === 0 ? 'hoje' : `há ${dataRealizacao} ${diaPreffix}`;

    return `Última realização ${diaText}`;
  }

  const dataSolicitacao = dayjs().diff(dataUltimaSolicitacao, 'day');
  const diaPreffix = dataSolicitacao > 1 ? 'dias' : 'dia';
  const diaText =
    dataSolicitacao === 0 ? 'hoje' : `há ${dataSolicitacao} ${diaPreffix}`;

  return `Última solicitação ${diaText}`;
};
