import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  email: any;
  onVisualizarEmail: (email: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { email, onVisualizarEmail } = props;

  const options = [
    {
      label: 'Visualizar e-mail',
      command: () => onVisualizarEmail(email),
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          {email?.dataEnvio
            ? dayjs(new Date(email?.dataEnvio)).format('DD/MM/YYYY HH:mm:ss')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome/Cartão do beneficiário{' '}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.nomeBeneficiario ? email?.nomeBeneficiario : '-'}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          {email?.cartaoBeneficiario ? email?.cartaoBeneficiario : ''}
        </SimpleText>
      </div>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Profissional de saúde{' '}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.nomeProfissionalSaude ? email?.nomeProfissionalSaude : '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          E-mail do destinatário{' '}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.emailDestinatario ? email?.emailDestinatario : '-'}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          {email?.tituloEmail ? email?.tituloEmail : '-'}•
        </SimpleText>
      </div>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Tipo documento{' '}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.tipoDocumento
            ? capitalizeFirstLetter(email?.tipoDocumento).replaceAll('_', ' ')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Status{' '}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.status ? capitalizeFirstLetter(email?.status) : '-'}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
