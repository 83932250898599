import { useCallback, useEffect, useMemo, useState } from 'react';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import PickList from 'src/components/PickList/PickList';
import PickListSkeleton from 'src/components/PickList/PickListSkeleton/PickListSkeleton';
import Switch from 'src/components/Switch/Switch';

interface FormConsultoriosProps {
  goToPreviousStep: () => void;
  values: any;
  onSubmit: (v: any) => void;
  editComplexidade?: any;
  onGoBack: () => void;
}

const FormConsultorios = ({
  goToPreviousStep,
  values,
  onSubmit,
  editComplexidade,
  onGoBack,
}: FormConsultoriosProps) => {
  const [consultoriosSelected, setConsultoriosSelected] = useState<any>([]);
  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const modalAddConsultoriosDisclosure = useDisclosure({
    opened: false,
  });

  const getConsultorios = useCallback(async () => {
    setLoading(true);
    try {
      const response: any = await ConsultorioAPI.loadConsultorios(
        {
          pageSize: 9999,
          sortBy: 'nome',
          sortDirection: 'ASC',
        },
        { throwError: true },
      );

      setConsultorios(response.list);
    } catch (error) {
      onGoBack();
    } finally {
      setLoading(false);
      return;
    }
  }, [onGoBack]);

  const consultoriosOptions = useMemo(
    () =>
      consultorios.map(consultorio => ({
        label: consultorio.nome,
        value: {
          id: consultorio.id,
          nome: consultorio.nome,
        },
      })),
    [consultorios],
  );

  useEffect(() => {
    getConsultorios();
  }, [getConsultorios]);

  const submit = () => {
    onSubmit({
      descricao: values?.descricao,
      pontuacao: values?.pontuacao,
      consultoriosDto: consultoriosSelected,
      idsConsultorios: consultoriosSelected.map(
        (consultorio: any) => consultorio.value.id,
      ),
    });
  };

  useEffect(() => {
    if (editComplexidade?.id) {
      const consultoriosSelecionados = editComplexidade.consultoriosDto?.map(
        (consultorio: any) => ({
          label: consultorio.nome,
          value: {
            id: consultorio.id,
            nome: consultorio.nome,
          },
        }),
      );
      setConsultoriosSelected(consultoriosSelecionados);
    }
  }, [editComplexidade]);

  return (
    <div className="p-grid p-flex-column p-ml-md-3 p-w-100">
      <SimpleText
        bold
        className="p-my-2"
        fontSize={FONT_SIZE.SM}
        fontColor={FONT_COLOR.COLOR_16}
      >
        Todos os consultórios
      </SimpleText>

      <SimpleText
        bold
        className="p-mt-3"
        fontSize={FONT_SIZE.XS}
        fontColor={FONT_COLOR.COLOR_40}
      >
        Filtre os resultados utilizando o campo de pesquisa abaixo.
      </SimpleText>

      {loading ? (
        <PickListSkeleton />
      ) : (
        <PickList
          label="Consultórios"
          setSelected={setConsultoriosSelected}
          arraySelected={consultoriosSelected}
          arrayOptions={consultoriosOptions}
        />
      )}

      <div className="p-col-12 p-grid">
        <Button
          className="p-col-12 p-sm-6 p-md-2"
          label="Voltar"
          onClick={() => {
            goToPreviousStep();
          }}
          btnType="ghost"
        />

        <Button
          label="Salvar"
          onClick={submit}
          type="button"
          className="p-col-12 p-sm-6 p-md-4"
          disabled={!consultoriosSelected.length}
        />
      </div>

      {modalAddConsultoriosDisclosure.isOpen && (
        <ConfirmDialog
          visible={modalAddConsultoriosDisclosure.isOpen}
          header="Deseja ativar todos os consultório?"
          text="Se você realmente deseja ativar todos os consultórios, clique em sim para continuar. Seleções ja feitas serão perdidas!"
          confirmText="Sim, ativar"
          confirmBtnType="filled"
          onHide={() => {
            modalAddConsultoriosDisclosure.close();
          }}
          onConfirm={() => {
            setConsultoriosSelected([]);
            modalAddConsultoriosDisclosure.close();
          }}
        />
      )}
    </div>
  );
};

export default FormConsultorios;
