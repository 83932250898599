import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface Props {
  label?: string;
  className?: string;
  options: any[];
  loading: boolean;
}

const FieldConsultorios = ({ label, className, options, loading }: Props) => {
  const [prevConsultorios, setPrevConsultorios] = useState<any[]>([]);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const watchConsultorios = watch('consultorios');

  useEffect(() => {
    //Este useEffect serve para selecionar apenas a opcao Todos quando o usuario selecionar todos os consultorios e remover a opcao Todos quando o usuario selecionar algum consultorio
    setPrevConsultorios(watchConsultorios);
    if (
      !prevConsultorios?.some((c: any) => c?.id === 'Todos') &&
      prevConsultorios?.length > 0 &&
      watchConsultorios?.some((c: any) => c?.id === 'Todos')
    ) {
      setValue('consultorios', [{ id: 'Todos', name: 'Todos' }]);
    }

    if (
      prevConsultorios?.some((c: any) => c?.id === 'Todos') &&
      watchConsultorios?.length > 1
    ) {
      const filterTodos = watchConsultorios.filter(
        (c: any) => c?.id !== 'Todos',
      );
      setValue('consultorios', filterTodos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchConsultorios]);

  if (loading) {
    return (
      <div className={className}>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {label}
        </SimpleText>
        <Skeleton
          className="p-mt-2"
          height="33px"
          borderRadius="8px"
          loading={true}
        />
      </div>
    );
  }

  return (
    <MultiSelectControlled
      control={control}
      name="consultorios"
      className="p-mt-2"
      placeholder="Selecione pelo menos um consultório"
      label="Consultório"
      errorMsg={errors.consultorios?.message}
      options={options}
      showSelectAll
      display={'chip'}
      dataKey="id"
    />
  );
};

export default FieldConsultorios;
