import { ToggleButton } from 'primereact/togglebutton';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { useFixarFuncionalidade } from './hooks/useFixarFuncionalidade';
import { PrescricaoList } from './List/PrescricaoList';

import './Prescricao.scss';

export const Prescricao = ({ campos, reloadCampos }: any) => {
  const { checked, handleFixar } = useFixarFuncionalidade({
    campos,
    reloadCampos,
  });

  return (
    <div className="prescricao-container">
      <div className="p-d-flex p-jc-between p-ai-center">
        <SimpleText
          className="p-ml-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Prescrição
        </SimpleText>
        <ToggleButton
          checked={checked}
          className="p-px-4 pin"
          onLabel={''}
          offLabel={''}
          onChange={e => {
            handleFixar(e.value);
          }}
          onIcon="fa fa-thumbtack"
          offIcon="fa fa-thumbtack"
        />
      </div>

      <PrescricaoList />
    </div>
  );
};
