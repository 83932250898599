interface PermissionsProps {
  biometria: boolean;
  prontuarioCompleto: boolean;
  pa: boolean;
  aps: boolean;
  contrarreferencia: boolean;
  exibirMotivoAtendimento: boolean;
  exibirMotivoFalta: boolean;
  presencaRetroativa: boolean;
  identificacaoEtiqueta: boolean;
  ciap: boolean;
  desfecho: boolean;
}

const listaPermissoes = [
  'biometria',
  'prontuarioCompleto',
  'pa',
  'aps',
  'contrarreferencia',
  'exibirMotivoAtendimento',
  'exibirMotivoFalta',
  'presencaRetroativa',
  'identificacaoEtiqueta',
  'exibirCpfEtiqueta',
  'ciap',
  'desfecho',
];

export const getPermissions = (permissoes: any): PermissionsProps => {
  const listaPermissoesValues = listaPermissoes.map((permissao: any) => ({
    [permissao]: permissoes?.includes(permissao),
  }));

  return Object.assign({}, ...listaPermissoesValues);
};
export const getPermissionsChecked = (values: any): string[] => {
  const permissionsChecked = [];

  for (const key in values) {
    if (listaPermissoes.includes(key) && values[key] === true) {
      permissionsChecked.push(key);
    }
  }

  return permissionsChecked;
};
