import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  data: any;
  onEdit: (data: any) => void;
  onSwitchStatus: (data: any) => void;
  onDuplicar: (data: any) => void;
}

export const MobileRow = ({
  data,
  onEdit,
  onSwitchStatus,
  onDuplicar,
}: MobileRowProps) => {
  const options = [
    {
      label: 'Editar',
      command: () => onEdit(data?.id),
      checkPermission: 'ADM_SERVICO_TUSS_ALTERAR',
    },

    {
      label: data?.status ? 'Inativar' : 'Ativar',
      command: () => onSwitchStatus(data),
      checkPermission: 'ADM_SERVICO_TUSS_ALTERAR',
    },

    {
      label: 'Duplicar',
      command: () => onDuplicar(data),
      checkPermission: 'ADM_SERVICO_TUSS_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Convênio
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.convenio?.nomeFantasia || data?.convenio?.razaoSocial || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Guia TISS
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.guiaTiss?.descricao || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Grupo TUSS
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.grupoTuss?.nome || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Código
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.codigo || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.nome || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Classe
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.classe}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
