import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/anexo-opme';

const getAnexosOpme = async (
  idSolicitacaoExame: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/listar/${idSolicitacaoExame}`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const postAnexoOpme = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });

const putAnexoOpme = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });

const deleteItemAnexoOpme = async (
  idItemAnexoOpme: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/item/${idItemAnexoOpme}`,
    throwError: true,
    ...options,
  });

export const AnexoOpmeAPI = {
  getAnexosOpme,
  postAnexoOpme,
  putAnexoOpme,
  deleteItemAnexoOpme,
};
