import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { Checkbox } from 'src/components/_UI/Checkbox';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { ProcedimentosSolicitadosListHeader } from './ProcedimentosSolicitadosListHeader';
import { ProcedimentosSolicitadosPanel } from './ProcedimentosSolicitadosPanel';

interface ProcedimentosSolicitadosGuiasListProps {
  handleSelectGuia: (checked: boolean, numeroGuia: number) => void;
  checkGuiaIsSelected: (numeroGuia: number) => boolean;
}

export const ProcedimentosSolicitadosGuiasList = (
  props: ProcedimentosSolicitadosGuiasListProps,
) => {
  const { handleSelectGuia, checkGuiaIsSelected } = props;

  const {
    guiasProcedimentosSolicitados,
    loadingFetchProcedimentosSolicitados,
  } = useSolicitacaoExameCirurgia();

  if (loadingFetchProcedimentosSolicitados) {
    return <GridListLoading />;
  }

  if (!guiasProcedimentosSolicitados.length) {
    return (
      <Card className="p-d-flex p-jc-center p-py-5" type={CARD_TYPES.GRAY}>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Nenhum procedimento solicitado.
        </SimpleText>
      </Card>
    );
  }

  return (
    <>
      {guiasProcedimentosSolicitados.map(([numeroGuia, procedimentos]) => (
        <Card
          key={numeroGuia}
          className="card-guia p-py-3"
          type={CARD_TYPES.BORDER}
        >
          <div className="p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.SM}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Guia número: {numeroGuia}{' '}
            </SimpleText>
          </div>

          <Checkbox
            checked={checkGuiaIsSelected(numeroGuia)}
            onChange={e => handleSelectGuia(e.target.checked, numeroGuia)}
          />

          <ProcedimentosSolicitadosListHeader />

          <div className="procedimentos-guia-container">
            {procedimentos.map((procedimento: any) => (
              <ProcedimentosSolicitadosPanel
                key={procedimento.idSolicitacaoProcedimento}
                procedimento={procedimento}
                onDelete={() =>
                  procedimentos.length === 1 &&
                  handleSelectGuia(false, numeroGuia)
                }
              />
            ))}
          </div>
        </Card>
      ))}
    </>
  );
};
