import { memo, useCallback } from 'react';

import TipoMotivoAtendimentoAPI from 'src/APIs/ConfigAPI/TipoMotivoAtendimento/TipoMotivoAtendimentoAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalExcluirTipoMotivoProps {
  visible: boolean;
  onHide(): void;
  tipoMotivoAtendimento: any;
  reloadData(): void;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        btnType="danger"
        className={'danger'}
        label="Sim, excluir"
        onClick={onAdd}
        stretch
      />
    </div>
  );
});

const ModalExcluirTipoMotivo = ({
  tipoMotivoAtendimento,
  visible,
  onHide,
  reloadData,
}: ModalExcluirTipoMotivoProps) => {
  const handleDeletarTipoMotivoAtendimento = useCallback(async () => {
    const response = await TipoMotivoAtendimentoAPI.removeTipoMotivoAtendimento(
      tipoMotivoAtendimento.id,
      { return: true },
    );

    if (response.status === 204) {
      reloadData();
      onHide();
    }
  }, [onHide, reloadData, tipoMotivoAtendimento.id]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Excluir tipo de motivo de atendimento"
      visible={visible}
      onHide={onHide}
      footer={() => (
        <Footer onAdd={handleDeletarTipoMotivoAtendimento} onCancel={onHide} />
      )}
    >
      <SimpleText style={{ paddingLeft: 10 }} fontColor={FONT_COLOR.COLOR_40}>
        Você confirma a exclusão do tipo de motivo de atendimento?
      </SimpleText>
    </Dialog>
  );
};

export default ModalExcluirTipoMotivo;
