import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { PontuacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PontuacaoFormularioDinamicoAPI/PontuacaoFormularioDinamicoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { AcervoFormularioCreatePontuacaoCreate } from './AcervoFormulariosCreatePontuacaoCreate';
import { AcervoFormularioCreatePontuacaoRow } from './AcervoFormulariosCreatePontuacaoRow';

export const AcervoFormularioCreatePontuacao = ({
  handleGoBack,
  item,
}: {
  handleGoBack(): void;
  item: IFormularioDinamicoItem;
}) => {
  const { state } = useLocation();
  const createDialog = useDisclosure({ opened: false });

  const navigate = useNavigate();

  const [items, setItems] = useState<IPontuacaoFormularioDinamicoItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const hasMultiselectQuestion = item.perguntas?.some(question =>
    Boolean(question.perguntaFormularioDinamico.opcoes?.length),
  );

  const viewOnly = state?.to !== 'edit';

  const fetchApi = useCallback(async (id: number) => {
    try {
      setIsLoading(true);

      const data =
        await PontuacaoFormularioDinamicoAPI.loadPontuacaoFormularioDinamico(
          {
            idFormularioDinamico: id,
            pageSize: 99,
          },
          { throwError: true },
        );

      setItems(data.list);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!item.id) return;
    fetchApi(item.id);
  }, [fetchApi, item.id]);

  const handleDelete = async (id: number) => {
    try {
      setIsLoading(true);
      await PontuacaoFormularioDinamicoAPI.deletePontuacaoFormularioDinamico(
        { id },
        { throwError: true },
      );

      fetchApi(item.id);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-3">
      <div
        className="row content-row-outline p-flex-column p-col-12 p-gap-3"
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
        }}
      >
        <Button
          label="Nova pontuação"
          icon="fas fa-plus"
          btnType="pill"
          className="p-col-12 p-sm-6 p-md-4 p-lg-3"
          disabled={!hasMultiselectQuestion || viewOnly}
          onClick={() => createDialog.open()}
          checkPermission={'ADM_MANTER_PONTUACAO_FORMULARIO_DINAMICO_CADASTRAR'}
        />

        {isLoading && <LoadingComponent />}

        {!!items.length &&
          !isLoading &&
          items.map((pontuacao, idx) => (
            <div key={idx} className="p-d-flex p-flex-column">
              <AcervoFormularioCreatePontuacaoRow
                item={pontuacao}
                handleDelete={handleDelete}
                handleEdit={item =>
                  createDialog.open({
                    state: item,
                  })
                }
              />
            </div>
          ))}

        {!items.length && !isLoading && (
          <div
            className="p-gap-2 row content-row p-flex-column p-jc-center p-ai-center p-col-12"
            style={{
              minHeight: '320px',
            }}
          >
            <SimpleText className="">Nenhuma pontuação adicionada.</SimpleText>
            <SimpleText fontColor="primary">
              Clique em nova pontuação.
            </SimpleText>
          </div>
        )}
      </div>

      <div className="p-col-12 p-d-flex p-gap-2 p-md-6 p-py-3">
        <Button
          label="Voltar"
          stretch
          btnType="ghost"
          onClick={() => handleGoBack()}
        />
        <Button
          label="Salvar"
          stretch
          onClick={() =>
            navigate('/cadastros/formulario-dinamico/acervo-formularios')
          }
        />
      </div>

      {createDialog.isOpen && (
        <AcervoFormularioCreatePontuacaoCreate
          refetch={() => fetchApi(item.id)}
          {...createDialog}
        />
      )}
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <div
      className="p-d-flex p-flex-column p-gap-2"
      style={{
        minHeight: '320px',
      }}
    >
      <Skeleton loading height="45px" borderRadius="8px" />
      <Skeleton loading height="45px" borderRadius="8px" />
      <Skeleton loading height="45px" borderRadius="8px" />
      <Skeleton loading height="45px" borderRadius="8px" />
    </div>
  );
};
