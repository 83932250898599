import { useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import MedicamentosUsoAPI from 'src/APIs/ProntuarioAPI/MedicamentosUsoAPI/MedicamentosUsoAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalJustificativaInativacao.scss';

interface ModalJustificativaInativacaoProps {
  idMedicamento: number | null;
  onHide: () => void;
  onSuccess: () => void;
}

const validationSchema = Yup.object().shape({
  justificativaInativacao: Yup.string().required(
    'Informe a justificativa da inativação',
  ),
});

function ModalJustificativaInativacao({
  idMedicamento,
  onHide,
  onSuccess,
}: ModalJustificativaInativacaoProps) {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      const response = await MedicamentosUsoAPI.inativarMedicamento(
        idMedicamento as number,
        data,
      );

      if (response?.status === 204) {
        onSuccess();
        onHide();
      }
    },
    [idMedicamento, onHide, onSuccess],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header="Você tem certeza que deseja inativar?"
      visible={!!idMedicamento}
      onHide={onHide}
      footer={renderFooter}
    >
      <form id="form-medicamento-uso" className="p-grid">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          Você está inativando a inclusão deste item no prontuário do paciente.
          Ele continuará ser exibido como uma informação inativa. Para
          continuar, justifique.
        </SimpleText>

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="justificativaInativacao"
          label="Justificativa"
          placeholder="Informe a justificativa"
          errorMsg={errors.justificativaInativacao?.message}
        />
      </form>
    </Dialog>
  );
}

export default ModalJustificativaInativacao;
