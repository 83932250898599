import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

const ListHeader = () => {
  return (
    <div className="p-grid p-d-flex p-ai-center">
      <div className="p-col-2 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Data de atendimento
        </SimpleText>
      </div>
      <div className="p-col-3 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Paciente
        </SimpleText>
      </div>
      <div className="p-col-3 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Médico
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Convênio
        </SimpleText>
      </div>
      <div className="p-col-1 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Valor à receber
        </SimpleText>
      </div>
      <div className="p-col-1 p-d-flex"></div>
    </div>
  );
};

export default ListHeader;
