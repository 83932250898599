import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { useServicoTussSelect } from './ServicosTussSelectContext';

interface FavoriteButtonProps {
  isSelected: boolean;
  idProcedimento: number;
  idExameFavorito?: number;
  onChange?: (isFavorite: boolean, idFavorito: number) => void;
}

export const FavoriteButton = (props: FavoriteButtonProps) => {
  const { isSelected, idProcedimento, idExameFavorito, onChange } = props;

  const { handleChangeFavorito } = useServicoTussSelect();

  const handleFavorite = async () => {
    try {
      const favoriteAction = isSelected
        ? ServicoTussCRUDAPI.desfavoritarExame
        : ServicoTussCRUDAPI.favoritarExame;

      const idFavoriteAction = isSelected ? idExameFavorito : idProcedimento;

      const response = await favoriteAction(idFavoriteAction as number);

      handleChangeFavorito(
        idProcedimento,
        idExameFavorito || response,
        !isSelected,
      );

      onChange?.(!isSelected, idExameFavorito || response);
    } catch (error) {}
  };

  const favoriteClassName = `favorite ${
    isSelected ? 'pi pi-star-fill' : 'pi pi-star'
  }`;

  return <i className={favoriteClassName} onClick={handleFavorite} />;
};
