import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  especialidade: any;
  onSwitchStatus: (especialidade: any) => void;
  onEdit: (especialidade: any) => void;
  onDelete: (especialidade: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { especialidade, onSwitchStatus, onEdit, onDelete } = props;
  const menu = useRef<any>(null);

  const itemsOptions = [
    {
      label: 'Editar',
      command: () => onEdit(especialidade),
      checkPermission: 'ADM_ESPECIALIDADE_ALTERAR',
    },
    {
      label: 'Excluir',
      command: () => onDelete(especialidade),
      checkPermission: 'ADM_ESPECIALIDADE_EXCLUIR',
      className: 'error',
    },
  ];

  return (
    <CardItem>
      <div className="p-col">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {especialidade?.nome}
        </SimpleText>
      </div>
      <div className="p-col-3 p-xl-2 p-d-flex p-ai-center p-jc-end p-gap-3">
        <Switch
          className="p-as-center"
          checked={!!especialidade?.status}
          onChange={() => onSwitchStatus(especialidade)}
          checkPermission="ADM_ESPECIALIDADE_ALTERAR"
          showStatus
        />

        <div className="p-d-flex p-jc-end">
          <Button
            className="p-mr-1"
            btnType="gray"
            icon="fas fa-ellipsis-h"
            onClick={event => menu?.current?.toggle(event)}
          />

          <TieredMenu
            className="tiered-menu"
            model={itemsOptions}
            popup
            ref={menu}
          />
        </div>
      </div>
    </CardItem>
  );
};
