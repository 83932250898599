import { useState } from 'react';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import RadioButton from '../../RadioButton/RadioButton';

import './CamposList.scss';

interface CamposListProps {
  campos: CampoProntuario[];
  loadingAlteracao: string | null;
  onAlterarExibicao: (campo: string, tipoExibicao: string) => void;
}

function CamposList({
  campos,
  loadingAlteracao,
  onAlterarExibicao,
}: CamposListProps) {
  const { campos: configuracaoCampos } = useAtendimento();
  const [alertVisible, setAlertVisible] = useState<boolean>(true);

  return (
    <>
      <ul className="campos-list">
        {campos.map((campo, i) => {
          const campoIsRequired =
            configuracaoCampos?.find(
              configCampo => configCampo.funcionalidade === campo.campo,
            )?.obrigatorio ?? false;
          return (
            <li key={i} className="p-d-flex p-jc-between">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
                medium
              >
                {campo.descricaoCampo}
              </SimpleText>
              <Skeleton
                loading={loadingAlteracao === campo.campo}
                width="27%"
                height="25px"
                borderRadius="8px"
              >
                <div className="p-d-flex">
                  <RadioButton
                    label="Oculto"
                    checked={
                      campoIsRequired ? false : campo.tipoExibicao === 'OCULTO'
                    }
                    onClick={() => onAlterarExibicao(campo.campo, 'OCULTO')}
                    disabled={campoIsRequired}
                  />
                  <RadioButton
                    label="Fixo"
                    onClick={() => onAlterarExibicao(campo.campo, 'FIXO')}
                    checked={campoIsRequired || campo.tipoExibicao === 'FIXO'}
                    disabled={campoIsRequired}
                  />
                  <RadioButton
                    label="Nenhum"
                    onClick={() => onAlterarExibicao(campo.campo, 'NENHUM')}
                    checked={
                      campoIsRequired ? false : campo.tipoExibicao === 'NENHUM'
                    }
                    disabled={campoIsRequired}
                  />
                </div>
              </Skeleton>
            </li>
          );
        })}
      </ul>

      <div className="p-mt-5">
        <AlertBox
          visible={alertVisible}
          onHide={() => setAlertVisible(false)}
          text="Os campos que forem definidos como obrigatório pelo seu consultório, não poderão ser ocultos"
        />
      </div>
    </>
  );
}

export default CamposList;
