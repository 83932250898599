import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import CardPaciente from './CardPaciente';

import './ModalPacientesSemelhantes.scss';
import { Button } from 'src/components/_UI/Button';

interface ModalPacientesSemelhantesProps {
  modalSemelhantes: any;
  onHide: () => void;
  onCreate: (payload: any) => void;
}

const ModalPacientesSemelhantes = ({
  modalSemelhantes,
  onHide,
  onCreate,
}: ModalPacientesSemelhantesProps) => {
  const isPacientesDuplicados = modalSemelhantes?.pacientesDuplicados.length;
  const modalTitle = isPacientesDuplicados
    ? 'Pacientes duplicados'
    : 'Pacientes semelhantes';
  const pacientes = isPacientesDuplicados
    ? modalSemelhantes.pacientesDuplicados
    : modalSemelhantes?.pacientesSemelhantes || [];

  const handleCreate = () => {
    onCreate(modalSemelhantes.payload);
    onHide();
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onHide} stretch />
      <Button
        label="Criar um novo paciente"
        onClick={handleCreate}
        disabled={isPacientesDuplicados}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-pacientes-semelhantes"
      type="modal"
      header={modalTitle}
      visible={!!modalSemelhantes}
      onHide={onHide}
      footer={renderFooter}
    >
      <div className="p-grid">
        <SimpleText
          className="p-col-4"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Nome/Sexo/Planos de saúde
        </SimpleText>
        <SimpleText
          className="p-col-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          CPF/Registro
        </SimpleText>
        <SimpleText
          className="p-col-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de nascimento
        </SimpleText>
        <SimpleText
          className="p-col-3"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Nome da mãe
        </SimpleText>

        {pacientes?.map((paciente: any) => (
          <CardPaciente key={String(paciente.id)} paciente={paciente} />
        ))}
      </div>
    </Dialog>
  );
};

export default ModalPacientesSemelhantes;
