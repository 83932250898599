import { createContext, useCallback, useContext, useState } from 'react';

type ContextProps = {
  timeOutElegibilidade: number;
  isTimerRunning: boolean;
  elegibilidadeObservacao: string;
  setTimeOutElegibilidade: React.Dispatch<React.SetStateAction<any>>;
  setIsTimerRunning: React.Dispatch<React.SetStateAction<any>>;
  handleElegibilidadeObservacao: (observacao?: string) => void;
  resetElegibilidade: () => void;
};

const NovoAgendamentoContext = createContext({} as ContextProps);

export function NovoAgendamentoProvider({ children }: any) {
  const [timeOutElegibilidade, setTimeOutElegibilidade] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [elegibilidadeObservacao, setElegibilidadeObservacao] = useState('');

  const handleElegibilidadeObservacao = useCallback(
    (observacao: string | undefined) => {
      if (!observacao)
        return setElegibilidadeObservacao(
          'O tempo excendeu para validação da Elegibilidade do paciente e não houve resposta do servidor',
        );
      return setElegibilidadeObservacao(observacao);
    },
    [],
  );

  const resetElegibilidade = () => setElegibilidadeObservacao('');

  return (
    <NovoAgendamentoContext.Provider
      value={{
        setTimeOutElegibilidade,
        timeOutElegibilidade,
        isTimerRunning,
        setIsTimerRunning,
        handleElegibilidadeObservacao,
        elegibilidadeObservacao,
        resetElegibilidade,
      }}
    >
      {children}
    </NovoAgendamentoContext.Provider>
  );
}

export const useNovoAgendamento = () => useContext(NovoAgendamentoContext);
