import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

interface CardDesktopProps {
  paciente: any;
  cardAction: JSX.Element;
}

const CardDesktop = ({ paciente, cardAction }: CardDesktopProps) => {
  const renderConveniosNomes = () =>
    paciente?.cartoes?.map((cartao: any, index: number, cartoes: any) => {
      let convenioNome = index === 0 ? ' • ' : '';

      convenioNome += capitalizeFirstLetter(
        cartao?.convenio?.nomeFantasia || cartao?.convenio.razaoSocial,
      );

      if (index + 1 !== cartoes.length) convenioNome += ' • ';

      return convenioNome;
    });

  return (
    <SearchList.CardItem>
      <div className="p-col-4 p-d-flex p-ai-center">
        <Avatar
          className="avatar"
          label={paciente?.dadosGeraisObrigatorios.nome}
          image={paciente?.urlFoto || ''}
        />
        <div className="column">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {paciente.dadosGeraisObrigatorios.nome}
          </SimpleText>

          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {capitalizeFirstLetter(
              paciente.dadosGeraisObrigatorios.sexo.descricao,
            )}{' '}
            {renderConveniosNomes()}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2">
        <div className="column">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {paciente.dadosGeraisObrigatorios.cpf?.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              '$1.$2.$3-$4',
            )}
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            Registro no. {paciente.id}
          </SimpleText>
        </div>
      </div>
      <SimpleText
        className="p-col-2"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {dayjs(paciente.dadosGeraisObrigatorios.dataNascimento).format(
          'DD/MM/YYYY',
        )}
      </SimpleText>
      <SimpleText
        className="p-col-2"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {paciente.dadosGeraisObrigatorios.nomeMae}
      </SimpleText>
      <SimpleText
        className="p-col-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {paciente.status ? 'SIM' : 'NÃO'}
      </SimpleText>

      <div style={{ flex: 1 }} className="p-col-1 p-d-flex p-jc-end">
        {cardAction}
      </div>
    </SearchList.CardItem>
  );
};

export default CardDesktop;
