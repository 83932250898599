import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { AddPlanoCuidado } from '../AddPlanoCuidado/AddPlanoCuidado';

import './ContentHeader.scss';

export const ContentHeader = ({ refetch }: { refetch: () => void }) => {
  const { totalCount } = useSearchList();
  const { isTablet, isMobile } = useSize();

  const addPlanoCuidado = useDisclosure({ opened: false });

  return (
    <div id="plano-cuidado-content-header">
      <div className="p-d-flex p-flex-wrap p-ai-center p-jc-between p-mb-2">
        <Button
          label="Incluir novo plano de cuidado"
          icon="fas fa-user-plus"
          className="p-col-12 p-md-6 p-lg-3"
          onClick={() => addPlanoCuidado.open()}
          btnType="pill"
        />

        <SimpleText className="p-col-6 p-text-right">
          Resultados da busca <strong>{totalCount}</strong>
        </SimpleText>
      </div>

      <div className="p-w-100 p-d-flex">
        <SimpleText
          className="p-col-2 p-md-1"
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XXS}
        >
          Código
        </SimpleText>
        <SimpleText
          className="p-col-5 p-md-2"
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XXS}
        >
          Nome
        </SimpleText>
        {!isTablet && (
          <SimpleText
            className="p-col-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Usuário responsável
          </SimpleText>
        )}

        {!isTablet && (
          <SimpleText
            className="p-col-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Período de vigência
          </SimpleText>
        )}
        {!isMobile && (
          <SimpleText
            className="p-col-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Status
          </SimpleText>
        )}
        <SimpleText
          className="p-col p-d-flex p-jc-end p-mr-2"
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XXS}
        >
          Ações
        </SimpleText>
      </div>

      {addPlanoCuidado.isOpen && (
        <AddPlanoCuidado refetch={refetch} {...addPlanoCuidado} />
      )}
    </div>
  );
};
