import { useState } from 'react';

import { PendenciasTissAPI } from 'src/APIs/ProntuarioAPI/PendenciasTissAPI/PendenciasTissAPI';

import useSize from 'src/core/hooks/useSize';

import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { PendenciasTissFilter } from './PendenciasTissFilter';
import { PendenciasTissHeader } from './PendenciasTissHeader';
import {
  PendenciasTissRow,
  PendenciasTissRowMobile,
} from './PendenciasTissRow';

import './PendenciasTiss.scss';

export const PendenciasTiss = () => (
  <Page id="Pendencias-Tiss" className="p-p-0" white content>
    <PendenciasTissComp />
  </Page>
);

export const PendenciasTissComp = () => {
  const [reload, setReload] = useState(0);

  const { isMobile } = useSize();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchApi = async ({ query, page, ...params }: any) => {
    if (!Object.values(params)?.length) return;

    return PendenciasTissAPI.getPendenciasTiss(
      {
        page,
        ...params,
      },
      { throwError: true },
    ).catch(() => {});
  };

  const handleReload = () => setTimeout(() => setReload(old => old + 1), 400);

  return (
    <>
      <div className="p-col-12 p-d-flex p-flex-column">
        <SearchList.Root empresaRequired fetchApi={fetchApi}>
          <PendenciasTissFilter />

          <SearchList.BorderContainer>
            <PendenciasTissHeader handleSuccess={handleReload} />

            <div className="p-d-flex p-col-12 p-pb-0">
              <SimpleText fontColor="color_60" className="p-col-1">
                Guia
              </SimpleText>
              <SimpleText fontColor="color_60" className="p-col-3">
                Serviço
              </SimpleText>
              <SimpleText fontColor="color_60" className="p-col-2">
                Auxiliar
              </SimpleText>
              {!isMobile && (
                <SimpleText
                  fontColor="color_60"
                  className="p-col-1 p-text-truncate"
                >
                  Tentativas
                </SimpleText>
              )}
              <SimpleText fontColor="color_60" className="p-col-2 ">
                Ultimas tentativas
              </SimpleText>
              <SimpleText fontColor="color_60" className="p-col-3">
                Erro (ultimas tentativas)
              </SimpleText>
            </div>

            <SearchList.InfiniteScroll
              reload={reload}
              customEmptyText="Nenhum resultado. Faça a busca utilizando os filtros."
              renderRows={(items: any[]) => (
                <div className="p-d-flex p-flex-column p-gap-2">
                  {items.map(item =>
                    isMobile ? (
                      <PendenciasTissRowMobile item={item} key={item.id} />
                    ) : (
                      <PendenciasTissRow item={item} key={item.id} />
                    ),
                  )}
                </div>
              )}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>
    </>
  );
};
