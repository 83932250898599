import { createContext } from 'react';

export interface PreNatalData {
  preNatalAtual: any;
  gestacoes: any[];
  selectedGestacao: any;
  proximaGestacao: string;
  loadingPreNatal: boolean;
  utilizarDppUsAcompanhamento: boolean;
  loadPreNatal: (idPaciente: number) => void;
  clearPreNatal: () => void;
  setUtilizarDppUsAcompanhamento: (state: boolean) => void;
  setProximaGestacao: (proximaGestacao: string) => void;
  setSelectedGestacao: (selectedGestacao: any) => void;
  tipoGestacaoOptions: any[];
}

export const PreNatalContext = createContext({} as PreNatalData);
