import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as IconeHistoricoFamiliar } from 'src/assets/icons/icon-historico-familiar.svg';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import { userRoutes, RouteItem } from 'src/core/routes/Emed/RouterMap';

import { checkAuth } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import PageHeader from 'src/components/PageHeader/PageHeader';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

const MenuPacientes = () => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const pacientesItems = useMemo(() => {
    const pacientes = userRoutes.find(route => route.label === 'Pacientes');
    return pacientes?.items?.filter(item => item.hideFromMenu !== true);
  }, []);

  const menuItensFiltered = pacientesItems?.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  return (
    <Page id="menu-pacientes">
      <>
        <PageHeader title="Pacientes" subtitle="" />
        <div
          className="menu-card p-d-flex p-flex-column p-gap-3 p-mx-5 p-p-3"
          style={{ maxWidth: '375px' }}
        >
          <div className="p-d-flex p-gap-3 p-ai-center">
            <Button btnType="gray" icon="fas fa-users" />
            <SimpleText fontSize="md">Menu Pacientes</SimpleText>
          </div>
          <SimpleText fontSize="xs">
            Acesse as funcionalidades de pacientes
          </SimpleText>
          <div>
            {menuItensFiltered?.map((item, idx) => (
              <Button
                className="p-pl-0"
                key={idx}
                label={item.label}
                btnType="green-link"
                onClick={() => navigate(item.route)}
              />
            ))}
          </div>
        </div>
      </>
    </Page>
  );
};

export default MenuPacientes;
