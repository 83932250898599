import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import ConfiguracaoGuiaTissAPI from 'src/APIs/ConfigAPI/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import SearchServicoTuss from './SearchServicoTuss/SearchServicoTuss';
import ServicoTussSelected from './ServicoTussSelected';

import './FormServicoTuss.scss';

interface FormServicoTussProps {
  onSubmit(v: ServicosTussComGuiaTiss[]): void;
  values?: ServicosTussComGuiaTiss[] | undefined;
  idConvenio: number;
}

const FormServicoTuss = ({
  onSubmit,
  values,
  idConvenio = 0,
}: FormServicoTussProps) => {
  const [modal, setModal] = useState(false);

  const [value, setValue] = useState<ServicosTussComGuiaTiss[]>([]);

  const load = useCallback(async () => {
    if (values && values?.length > 0) {
      const profs: any =
        await ConfiguracaoGuiaTissAPI.loadServicosTussComConfiguracaoGuiaTiss({
          nome: '',
        });

      const servicosTussList: any[] = [];

      values.map((v: any) => {
        const findServicoTuss = profs?.data?.filter(
          (p: any) => p.id === (v.idServicoTuss ? v.idServicoTuss : v.id),
        );

        const profEncontrado = {
          ...findServicoTuss[0],
          ...v,
          id: v.idServicoTuss ? v.idServicoTuss : findServicoTuss[0]?.id,
        };

        return servicosTussList.push(profEncontrado);
      });

      setValue(servicosTussList);
    }
  }, [values]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeItems = useCallback(
    items => {
      setValue(items);
      setModal(false);

      onSubmit(items);
    },
    [onSubmit],
  );

  // Ordena os valores por
  const valueOrdered = useMemo(
    () => value.sort((a, b) => (a.nome < b.nome ? -1 : 1)),
    [value],
  );

  return (
    <div className={'FormServicoTuss-ConfigTiss'}>
      <SimpleText fontSize={FONT_SIZE.XS} bold>
        Serviços TUSS
      </SimpleText>

      <div className="form-container">
        <div>
          <ServicoTussSelected
            value={valueOrdered}
            onChange={handleChangeItems}
          />

          <Button
            label="Adicionar"
            btnType="tonal"
            className="button-submit"
            icon="fas fa-plus"
            onClick={() => setModal(true)}
          />
        </div>
      </div>

      <SearchServicoTuss
        visible={modal}
        onHide={() => setModal(false)}
        onSelect={handleChangeItems}
        initialValue={valueOrdered}
        idConvenio={idConvenio}
      />
    </div>
  );
};

export default memo(FormServicoTuss);
