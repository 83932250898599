import { useMemo } from 'react';

import {
  RadioButton as PrimeRadioButton,
  RadioButtonProps as PrimeRadioButtonProps,
} from 'primereact/radiobutton';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './RadioButton.scss';

interface RadioButtonProps extends PrimeRadioButtonProps {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const RadioButton = (props: RadioButtonProps) => {
  const { label, onClick, disabled = false, className, ...rest } = props;

  const radioContentClass = useMemo(() => {
    let contentClassName = 'radio-button';

    if (contentClassName) contentClassName += ` ${className}`;

    if (disabled) contentClassName += ' disabled';

    return contentClassName;
  }, [className, disabled]);

  return (
    <div
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
      }}
      className={radioContentClass}
    >
      <PrimeRadioButton {...rest} disabled={disabled} />
      {label && label !== '' ? (
        <SimpleText
          htmlFor={props.name}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {label}
        </SimpleText>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RadioButton;
