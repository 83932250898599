import { Controller, useFormContext } from 'react-hook-form';

import { TextInputControlledProps } from '../TextInputControlled/TextInputControlled';

import TextInputSearch from './TextInputSearch';

export const TextInputSearchControlled = ({
  name,
  ...rest
}: Omit<TextInputControlledProps, 'control'>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <TextInputSearch
          {...rest}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
        />
      )}
    />
  );
};
