import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/prontuario-persistente';

export const getProntuarioPersistente = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });

export const alterarProntuarioPersistente = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });

const ProntuarioPersistenteAPI = {
  getProntuarioPersistente,
  alterarProntuarioPersistente,
};

export default ProntuarioPersistenteAPI;
