import dayjs from 'dayjs';
import NotificacaoAPI from 'src/APIs/NotificacaoAPI/NotificacaoAPI';

export const getNotificationTimeDistance = (
  initialDate: Date | string,
  finalDate = Date.now(),
) => {
  if (!dayjs(initialDate).isValid()) {
    return;
  }

  const diff = Math.abs(dayjs(initialDate).diff(dayjs(finalDate), 'minute'));

  const MINUTES_IN_HOUR = 60;
  const MINUTES_IN_DAY = 1440;

  if (diff <= 1) {
    return 'Há 1 minuto';
  }
  if (diff >= MINUTES_IN_DAY * 8) {
    return;
  }
  if (diff >= MINUTES_IN_DAY * 7) {
    return 'Há 1 semana';
  }
  if (diff >= MINUTES_IN_DAY * 2) {
    return `Há ${Math.floor(diff / 1440)} dias`;
  }
  if (diff >= MINUTES_IN_DAY) {
    return 'Há 1 dia';
  }
  if (diff >= MINUTES_IN_HOUR * 2) {
    return `Há ${Math.floor(diff / 60)} horas`;
  }
  if (diff >= MINUTES_IN_HOUR) {
    return 'Há 1 hora';
  }

  return `Há ${diff} minutos`;
};

export const marcarTodosComoLido = async (notifications: any) => {
  const notificacoesId = notifications.map(
    (notification: any) => notification.id,
  );

  await NotificacaoAPI.atualizarStatusNotificacoes(notificacoesId);
};

export const marcarNotificacaoComoNaoLida = async (id: number) => {
  await NotificacaoAPI.atualizarStatusNotificacaoParaNaoLido(id);
};
