import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/login';

export const sendAlterarSenhaRecuperacao = (
  payload: PostAlterarSenhaRecuperacaoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostAlterarSenhaRecuperacaoDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/alterarSenhaRecuperacao`,
    data: payload,
    return: true,
    headers: {
      Authorization: '',
    },
    ...options,
  });
};

const AlterarSenhaRecuperacaoAPI = {
  sendAlterarSenhaRecuperacao,
};

export default AlterarSenhaRecuperacaoAPI;
