import React from 'react';

import { useFormContext, useFieldArray } from 'react-hook-form';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

import { severidadeOptions } from '../../helpers';

import { ServicoTussField } from './ServicoTussField';

export const ExamesFields = () => {
  const { idConvenio } = useSolicitacaoExameCirurgia();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    fields: exames,
    append: appendExames,
    remove: removeExames,
  } = useFieldArray({
    control,
    name: 'exames',
  });

  const renderExameField = (exameField: any, index: number) => {
    const exameNumber = index + 1;

    const handleAddExame = () => {
      appendExames({});
    };

    const handleRemoveExame = () => removeExames(index);

    return (
      <React.Fragment key={exameField.id}>
        <SimpleText
          className="p-col-12 p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Exame {exameNumber}
        </SimpleText>

        <ServicoTussField
          name={`exames.${index}.idServicoTuss`}
          idConvenio={idConvenio!}
        />

        <FormInput
          className="p-col-12 p-sm-3"
          classNameInput="p-w-100"
          name={`exames.${index}.quantidade`}
          label="Quantidade"
          type="number"
          hideTextErrorSpace
        />

        <DropdownControlled
          className="p-col-12 p-sm-4"
          control={control}
          name={`exames.${index}.nivelAlteracao`}
          label="Nível de alteração"
          filter={false}
          options={severidadeOptions}
        />

        <FormInput
          className="p-col-12 p-sm-4"
          classNameInput="p-w-100"
          name={`exames.${index}.resultadoDigitado`}
          label="Resultado numérico"
          type="number"
          hideTextErrorSpace
        />

        <CalendarInputControlled
          className="p-col-12 p-sm-4 p-pb-0"
          control={control}
          name={`exames.${index}.dataResultado`}
          label="Data do resultado"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          errorMsg={errors.exames?.[index]?.dataResultado?.message}
        />

        <div className="p-d-flex p-flex-column">
          {exames.length > 1 && (
            <Button
              className="danger"
              type="button"
              btnType="ghost"
              label="Remover exame"
              icon="fas fa-minus"
              onClick={handleRemoveExame}
            />
          )}

          {exameNumber === exames.length && (
            <Button
              type="button"
              btnType="ghost"
              label="Adicionar exame"
              icon="fas fa-plus"
              onClick={handleAddExame}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      {exames.map((exameField, index) => renderExameField(exameField, index))}
    </>
  );
};
