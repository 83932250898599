import * as Yup from 'yup';

import { localOptions } from '../../helpers';

const localValues = localOptions.map((localOption: any) => localOption.value);

export const validationSchema = (
  indicacaoClinicaIsRequired: boolean,
  justificativaIsRequired: boolean,
  hasQuestionarioExameImg: boolean,
  hasQuestionarioExameLab: boolean,
) =>
  Yup.object().shape({
    indicacaoClinica: Yup.string()
      .nullable()
      .when([], {
        is: () => indicacaoClinicaIsRequired,
        then: field =>
          field.required('O campo indicação clínica é obrigatório.'),
      }),
    flagAplicarIndicacaoTodosProcedimentos: Yup.boolean(),
    quantidade: Yup.number()
      .nullable()
      .required('O campo quantidade é obrigatório'),
    local: Yup.string()
      .oneOf(localValues, 'O campo local é obrigatório')
      .nullable()
      .required('O campo local é obrigatório'),
    justificativa: Yup.string()
      .nullable()
      .when([], {
        is: () => justificativaIsRequired,
        then: field => field.required('O campo justificativa é obrigatório.'),
      }),
    flagAplicarJustificativaTodosProcedimentos: Yup.boolean(),
    tempoDoenca: Yup.number().nullable(),
    escalaTempo: Yup.string().nullable(),
    idProfissionalExecutante: Yup.number().nullable(),
    situacaoLocal: Yup.string()
      .when('local', {
        is: (local: string) => local === 'HOSPITAL',
        then: Yup.string()
          .required('O campo situação local é obrigatório')
          .nullable(),
      })
      .nullable(),
    dataSugerInternacao: Yup.date()
      .nullable()
      .when('situacaoLocal', {
        is: (situacaoLocal: string) => situacaoLocal === 'I',
        then: field =>
          field.required('O campo data sugerida internação é obrigatório'),
      }),
    quantidadeDiarias: Yup.number()
      .nullable()
      .when('situacaoLocal', {
        is: (situacaoLocal: string) => situacaoLocal === 'I',
        then: field =>
          field.required('O campo data quantidade de diárias é obrigatório'),
      }),
    idTipoInternacao: Yup.number()
      .nullable()
      .when('situacaoLocal', {
        is: (situacaoLocal: string) => situacaoLocal === 'I',
        then: field =>
          field.required('O campo data tipo internação é obrigatório'),
      }),
    idRegimeInternacao: Yup.number()
      .nullable()
      .when('situacaoLocal', {
        is: (situacaoLocal: string) => situacaoLocal === 'I',
        then: field =>
          field.required('O campo data regime internamento é obrigatório'),
      }),
    previsaoOpme: Yup.boolean(),
    hospital: Yup.object()
      .nullable()
      .when('situacaoLocal', {
        is: (situacaoLocal: string) => situacaoLocal === 'I',
        then: field =>
          field
            .required('O campo hospital é obrigatório')
            .typeError('O campo hospital é obrigatório'),
      }),
    tipoSolicitacao: Yup.string()
      .required('O campo tipo de solicitação é obrigatório')
      .nullable(),
    observacao: Yup.string().nullable(),
    dataRealizacao: Yup.date().nullable(),
    examesFuturos: Yup.array().of(
      Yup.object().shape({
        data: Yup.date().nullable().required('O campo data é obrigatório'),
        quantidade: Yup.number()
          .nullable()
          .required('O campo quantidade é obrigatório'),
      }),
    ),
    ...(hasQuestionarioExameImg && {
      questionarioSolicitacaoImg: questionarioSolicitacaoImgSchema,
    }),
    ...(hasQuestionarioExameLab && {
      questionarioSolicitacao: questionarioSolicitacaoLabSchema,
    }),
  });

const questionarioSolicitacaoImgSchema = Yup.object().shape({
  historiaClinica: Yup.string()
    .nullable()
    .required('A resposta da pergunta é obrigatória'),
  possuiSubsidiarios: Yup.boolean().required(
    'A resposta da pergunta é obrigatória',
  ),
  dentroParametros: Yup.boolean().required(
    'A resposta da pergunta é obrigatória',
  ),
  subsidiarios: Yup.string()
    .nullable()
    .when('possuiSubsidiarios', {
      is: true,
      then: field => field.required('A resposta da pergunta é obrigatória'),
    }),
  motivo: Yup.string()
    .nullable()
    .required('A resposta da pergunta é obrigatória'),
});

const questionarioSolicitacaoLabSchema = Yup.object().shape({
  repeticao: Yup.boolean()
    .required('A resposta da pergunta é obrigatória')
    .typeError('A resposta da pergunta é obrigatória'),
  dataAnterior: Yup.date().nullable(),
  tratamentosRealizados: Yup.string().nullable(),
  outrasInformacoes: Yup.string().nullable(),
});
