import React from 'react';

import dayjs from 'dayjs';

import { formatCPF, formatCarteirinhaMask } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

interface DesktopRowProps {
  prontuarioButton: JSX.Element;
  data: any;
}

const DesktopRow = ({ prontuarioButton, data }: DesktopRowProps) => {
  const {
    nome,
    cpf,
    cartaoPlano,
    mascaraCarterinha,
    dataNascimento,
    nomeMae,
    id,
    particular,
    idSexo,
    dataUltimoAtendimento,
  } = data;

  return (
    <div
      className="p-d-flex p-jc-between
     row p-my-1"
    >
      <div className="p-grid p-w-100">
        <div className="p-col-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-jc-start p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome do paciente / CPF
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {nome}
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            {cpf ? formatCPF(cpf) : ''}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-jc-start p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Número cartão
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XS}
            style={{ maxWidth: '100%', wordWrap: 'break-word' }}
          >
            {cartaoPlano
              ? formatCarteirinhaMask(cartaoPlano, mascaraCarterinha)
              : ''}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-jc-start p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Data de nascimento
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {dataNascimento ? dayjs(dataNascimento).format('DD/MM/YYYY') : ''}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-jc-start p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome da mãe
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {nomeMae}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-1 p-d-flex p-flex-column p-jc-start">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Código
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            className="p-col"
          >
            {id}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-1 p-d-flex p-flex-column p-jc-start">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Tipo
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            className="p-col"
          >
            {particular ? 'Particular' : 'Convênio'}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-1 p-d-flex p-flex-column p-jc-start">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Sexo
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            className="p-col"
          >
            {idSexo === 1 ? 'Feminino' : 'Masculino'}
          </SimpleText>
        </div>
        <div className="p-col-4 p-md-3 p-lg-1 p-d-flex p-flex-column p-jc-start">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Data último atendimento
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {dataUltimoAtendimento
              ? dayjs(dataUltimoAtendimento).format('DD/MM/YYYY')
              : ''}
          </SimpleText>
        </div>
      </div>
      <div className="p-d-flex p-ai-center p-px-1 p-mx-1">
        {prontuarioButton}
      </div>
    </div>
  );
};

export default DesktopRow;
