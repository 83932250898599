import { useCallback, useEffect, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { handleFileDownload } from 'src/utils/files';
import { groupBy, preventDefaultAndStopPropagation } from 'src/utils/utils';

import { EmptyData } from '../../components/EmptyData';
import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './SADT.scss';

export const HistoricoSADT = () => {
  const { selectedAtendimento } = useHistoricoAtendimento();

  const [guiaExames, setGuiaExames] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchApi = useCallback(async () => {
    if (!selectedAtendimento?.atendimento?.idConvenio) return;

    setLoading(true);
    const response: any = await HistoricoAtendimentosAPI.getHistoricoSADT({
      idAtendimento: selectedAtendimento.idAtendimento,
      idConvenio: selectedAtendimento?.atendimento?.idConvenio,
    });

    if (response.length !== 0) {
      const arr = Object.entries(groupBy(response, 'numeroGuia'));
      setGuiaExames(arr);
    }

    setLoading(false);
  }, [
    selectedAtendimento?.atendimento?.idConvenio,
    selectedAtendimento.idAtendimento,
  ]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  return (
    <div className="historico-sadt-container">
      {!loading &&
        guiaExames.length > 0 &&
        guiaExames.map(([numeroGuia, exames]) => (
          <div key={numeroGuia}>
            <div className="p-mb-2">
              <SimpleText fontSize={FONT_SIZE.SM} bold>
                Guia número: {numeroGuia}
              </SimpleText>
            </div>

            {exames.map((exame: any) => (
              <Accordion key={exame.id}>
                <AccordionTab
                  header={
                    <AccordionHeader reloadList={fetchApi} exame={exame} />
                  }
                  className="acc-historico-sadt"
                >
                  <AccordionContent exame={exame} />
                </AccordionTab>
              </Accordion>
            ))}
          </div>
        ))}

      {guiaExames.length === 0 && (
        <div className="content-empty-row">
          <EmptyData />
        </div>
      )}
    </div>
  );
};

const AccordionHeader = ({ exame, reloadList }: any) => {
  const { selectedAtendimento } = useHistoricoAtendimento();

  const isInativo = exame.status === 'INATIVO' ? true : false;
  const isAtendimentoInativo =
    selectedAtendimento.status === 'INATIVO' ? true : false;
  const { isTablet, isMobile } = useSize();

  const handleText = (text?: string) => {
    return text ? text : 'Não informado';
  };

  const menuEllipsis = useRef<TieredMenu>(null);
  const overlayLaudo = useRef<OverlayPanel>(null);

  const inativarDialog = useDisclosure({ opened: false });

  const optionsMobileSize = [
    {
      label: 'Laudo profissional',
      icon: 'fa-solid fa-table-list',
      command: (e: any) => [
        menuEllipsis.current?.toggle(e.originalEvent),
        overlayLaudo.current?.toggle(e.originalEvent),
      ],
    },
    {
      label: 'Data da repetição',
      icon: 'fa-solid fa-calendar',
    },
    {
      label: 'Laudo',
      icon: 'fa-solid fa-paperclip',
    },
    {
      separator: true,
    },
    {
      label: 'Inativar',
      className: 'error',
      icon: 'fa-solid fa-circle-exclamation',
      command: (e: any) => [
        menuEllipsis.current?.toggle(e.originalEvent),
        inativarDialog.open(),
      ],
    },
  ];

  const handleStatus = (status: string): string => {
    switch (status) {
      case 'AGENDADO':
        return 'Agendado';

      case 'CANCELADO':
        return 'Cancelado';

      case 'COMPLEMENTADO':
        return 'Complementado';

      case 'COMPLETO':
        return 'Completo';

      case 'EM_PENDENCIA':
        return 'Em pendência';

      case 'EM_REALIZACAO':
        return 'Em realização';

      case 'REALIZADO_COM_LAUDO':
        return 'Realizado com laudo';

      case 'REALIZADO_SEM_LAUDO':
        return 'Realizado sem laudo';

      case 'SOLICITADO':
        return 'Solicitado';

      case 'SUSPENSO':
        return 'Suspenso';

      case 'VERIFICADO':
        return 'Verificado';

      default:
        break;
    }

    return 'Não informado';
  };

  return (
    <div className="row p-w-100 p-gap-2 p-ai-center">
      <SimpleText
        className={`p-col-2 p-md-1 p-px-1'  ${
          (isInativo || isAtendimentoInativo) && 'inativo'
        }`}
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        {handleText(exame?.codigoProcedimento)}
      </SimpleText>

      <SimpleText
        className={`p-col-4 p-sm-3'  ${
          (isInativo || isAtendimentoInativo) && 'inativo'
        }`}
        fontSize={FONT_SIZE.XXS}
      >
        {handleText(exame?.descricaoProcedimento)}
      </SimpleText>

      {(isInativo || isAtendimentoInativo) && (
        <div className="p-col-6 p-md-3 p-d-flex p-flex-column p-gap-2 inativo">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            Data de solicitação
          </SimpleText>

          <SimpleText fontSize={FONT_SIZE.XXS}>
            {dayjs(exame?.dataSolicitacao).format('DD/MM/YYYY  HH:mm')}
          </SimpleText>
        </div>
      )}

      {!isInativo && !isAtendimentoInativo && (
        <>
          {!isTablet && (
            <SimpleText
              className="tag tag-moderado p-col-1"
              fontSize={FONT_SIZE.XXS}
            >
              {handleText(exame?.serveriaddeAlteracao)}
            </SimpleText>
          )}

          {!isTablet && (
            <SimpleText className="tag tag-laudo p-col-2 p-text-truncate">
              {handleStatus(exame?.situacaoExame)}
            </SimpleText>
          )}

          <div className="p-col-2 p-md-1 p-d-flex p-flex-column p-gap-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Resultado
            </SimpleText>

            <SimpleText fontSize={FONT_SIZE.XXS}>
              {handleText(exame?.resultadoExame?.resultadoDigitado)}
            </SimpleText>
          </div>

          {isMobile ? (
            <div
              onClick={preventDefaultAndStopPropagation}
              className="p-col-2 p-d-flex icons-pre-line"
            >
              <Button
                btnType="ghost"
                icon="fa-solid fa-ellipsis-h"
                onClick={e => menuEllipsis.current?.toggle(e)}
              />

              <TieredMenu ref={menuEllipsis} model={optionsMobileSize} popup />
            </div>
          ) : (
            <div
              onClick={preventDefaultAndStopPropagation}
              className="p-col-4 p-md-3 p-d-flex p-jc-end icons-pre-line"
            >
              <Tooltip
                target=".tooltip-data"
                position="top"
                content={`data repetição:  ${
                  exame.dataRepeticao
                    ? dayjs(exame?.dataRepeticao).format('DD/MM/YYYY')
                    : 'não informado'
                }`}
              />

              <Button
                btnType="ghost"
                icon="fa-solid fa-table-list"
                onClick={e => overlayLaudo.current?.toggle(e)}
              />

              <Button
                btnType="ghost"
                icon="fa-solid fa-calendar tooltip-data"
              />

              <Button
                btnType="ghost"
                icon="fa-solid fa-paperclip"
                onClick={() =>
                  handleFileDownload(
                    exame?.resultadoExame?.laudoArquivo,
                    'Documento',
                  )
                }
              />

              <Button
                btnType="ghost"
                icon="fa-solid fa-ellipsis-h"
                onClick={e => menuEllipsis.current?.toggle(e)}
              />

              <TieredMenu
                ref={menuEllipsis}
                model={[
                  {
                    label: 'Inativar',
                    className: 'error',
                    command: e => [
                      menuEllipsis.current?.toggle(e.originalEvent),
                      inativarDialog.open(),
                    ],
                  },
                ]}
                popup
              />
            </div>
          )}

          <OverlayPanel ref={overlayLaudo} className="overlay-laudo">
            <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
              <SimpleText bold>Laudo informado</SimpleText>
              <SimpleText>
                {handleText(exame?.resultadoExame?.laudoDigitado)}
              </SimpleText>
            </div>
            <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
              <SimpleText bold>Resultado</SimpleText>
              <SimpleText>
                {handleText(exame?.resultadoExame?.resultadoDigitado)}
              </SimpleText>
            </div>
          </OverlayPanel>

          <Dialog
            onHide={inativarDialog.close}
            visible={inativarDialog.isOpen}
            appendTo="self"
            className="overlay-inativar-sadt"
            showDismissWithoutHeader
            modal={false}
            showHeader={false}
            onClick={e => e.stopPropagation()}
          >
            <DialogInativar
              id={
                exame?.idSolicitacaoProcedimento
                  ? exame.idSolicitacaoProcedimento
                  : exame?.idSolicitacaoProcedimentoExterno
              }
              reloadList={reloadList}
              close={() => inativarDialog.close()}
            />
          </Dialog>
        </>
      )}
    </div>
  );
};

const AccordionContent = ({ exame }: any) => {
  const { selectedAtendimento } = useHistoricoAtendimento();
  const isSigiloLiberado = false;

  const handleText = (text?: string) => {
    return text ? text : 'Não informado';
  };

  return (
    <div className="acc-content p-grid p-ai-center">
      <div className="p-col-6 p-md-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>Qtd. Solicitada</SimpleText>
        <SimpleText>{handleText(exame?.quantidade)}</SimpleText>
      </div>
      <div className="p-col-6 p-md-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>Qtd. Aprovada</SimpleText>
        <SimpleText>01</SimpleText>
      </div>
      <div className="p-col-6 p-md-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Data de solicitação
        </SimpleText>
        <SimpleText>
          {' '}
          {dayjs(exame?.dataSolicitacao).format('DD/MM/YYYY  HH:mm')}
        </SimpleText>
      </div>

      <div className="p-col-12 p-mt-2">
        <SimpleText>
          Adicionado por
          <strong>
            {' '}
            {selectedAtendimento.atendimento.medico.nome}
          </strong> em{' '}
          {dayjs(selectedAtendimento?.atendimento.dataAgendamento).format(
            'DD/MM/YYYY',
          )}{' '}
          <i
            className={
              isSigiloLiberado
                ? 'fa-solid fa-lock-open green-icon'
                : 'fa-solid fa-lock red-icon'
            }
          />
        </SimpleText>
      </div>
    </div>
  );
};

const DialogInativar = ({ close, id, reloadList }: any) => {
  const form = useForm();
  const { selectedAtendimento } = useHistoricoAtendimento();

  const onSubmit = async (data: any) => {
    const payload = {
      id: id,
      idAtendimento: selectedAtendimento.idAtendimento,
      justificativaInativacao: data.justificativa,
    };
    await HistoricoAtendimentosAPI.putHistoricoSADTInativar(payload);

    close();
    reloadList();
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="p-d-flex p-flex-column p-gap-3"
    >
      <SimpleText bold fontSize={FONT_SIZE.SM}>
        Você tem certeza que deseja inativar?
      </SimpleText>

      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Você está inativando este registro no prontuário do paciente. Ele
          continuará ser exibido como uma informação inativa. Para continuar,
          justifique.
        </SimpleText>
        <TextareaInputControlled
          name="justificativa"
          label="Justificativa"
          maxLength={200}
          control={form.control}
        />
      </div>

      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          label="Cancelar"
          btnType="ghost"
          onClick={() => close()}
          stretch
        />

        <Button label="Inativar" btnType="danger" type="submit" stretch />
      </div>
    </form>
  );
};
