import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import {
  severidadeOptions,
  statusHistoricoOptions,
  tipoSolicitacaoOptions,
} from '../../../helpers';
import { useUploadFile } from '../../../hooks/useUploadFile';
import { LaudoField } from '../LaudoField';
import { UploadLaudo } from '../UploadLaudo/UploadLaudo';

import { validationSchema } from './validationSchema';

import './ModalIncluirResultado.scss';

type FormValues = {
  dataRealizacao?: Date;
  laudoDigitado?: string;
  severidadeAlteracao?: string;
  resutladoNumerico?: number;
  dataRepeticao?: Date;
  statusExame?: string;
  dataResultado?: Date;
  observacao?: string;
  lembrete: boolean;
};

export const ModalIncluirResultado = (props: any) => {
  const {
    isOpen,
    close,
    state: { procedimento, laudoArquivo: laudoArquivoAnexado, onSuccess },
  } = props;

  const { loadedFile, handleDeleteFile, handleLoadFile, handleUploadFile } =
    useUploadFile({ isProcedimentoExterno: false, onSuccess: () => {} });

  const laudoProcedimento = procedimento?.resultadoExame?.laudoArquivo;

  const isPDF =
    laudoArquivoAnexado?.includes('.pdf') ||
    procedimento?.resultadoExame?.laudoArquivo?.includes('.pdf');

  const laudo = laudoArquivoAnexado || laudoProcedimento;

  const useFormMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema(!!loadedFile)),
    defaultValues: {
      severidadeAlteracao: procedimento.severidadeAlteracao,
      resutladoNumerico: procedimento.resultadoExame?.resultadoDigitado,
      statusExame: procedimento.situacaoExame,
      observacao: procedimento.observacao,
      lembrete: procedimento.lembrete || false,
      laudoDigitado: procedimento.resultadoExame?.laudoDigitado || '',
      ...(procedimento.dataRealizacao && {
        dataRealizacao: new Date(procedimento.dataRealizacao),
      }),
      ...(procedimento.dataRepeticao && {
        dataRepeticao: new Date(procedimento.dataRepeticao),
      }),
      ...(procedimento.dataResultado && {
        dataResultado: new Date(procedimento.dataResultado),
      }),
    },
  });

  const {
    control,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    try {
      const laudoArquivo = await handleUploadFile(1, 1, false);

      const payload = {
        ...data,
        id: procedimento.idSolicitacaoProcedimento,
        ...(data.dataRepeticao && {
          dataRepeticao: dayjs(data.dataRepeticao).format('YYYYMMDD'),
        }),
        laudoArquivo,
      };

      await SolicitacoesProcedimentosAPI.putResultado(
        procedimento.idAtendimento,
        procedimento.idSolicitacaoProcedimento,
        payload,
      );

      onSuccess();

      close();
    } catch (error) {}
  };

  const formatLocal = (local: string) => {
    if (local === 'NAO_DEFINIDO') return '';

    return capitalizeFirstLetter(local);
  };

  const formatData = (data: string) => {
    if (!data) return '';

    return dayjs(data).format('DD/MM/YYYY');
  };

  const getTipoSolicitacaoLabel = (tipoSolicitacao: string) => {
    if (!tipoSolicitacao) return '';

    const tipoSolicitacaoOption = tipoSolicitacaoOptions.find(
      status => status.value === tipoSolicitacao,
    );

    return tipoSolicitacaoOption?.label || '';
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        onClick={close}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={!isDirty}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-incluir-resultado"
      header="Incluir resultado"
      visible={isOpen}
      onHide={close}
      type="modal"
      modal={false}
      footer={renderFooter}
      maximizedTabletSize
    >
      <FormProvider {...useFormMethods}>
        <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-col-12 p-grid p-p-0">
            <DropdownControlled
              className="p-col-fixed"
              control={control}
              name="statusExame"
              placeholder="Status"
              options={statusHistoricoOptions}
              errorMsg={errors.statusExame?.message}
            />

            <div className="p-col p-d-flex">
              <Divider layout="vertical" />

              <div className="p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  medium
                >
                  Código
                </SimpleText>

                <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                  {procedimento?.codigoProcedimento}
                </SimpleText>
              </div>
            </div>

            <div className="p-col p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Qtd.
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.quantidade}
              </SimpleText>
            </div>

            <div className="p-col p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Indicação clínica
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.indicacaoClinica}
              </SimpleText>
            </div>
          </div>

          <CalendarInputControlled
            className="p-col-12 p-sm-6"
            control={control}
            name="dataRealizacao"
            label="Data realização"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
          />

          <CalendarInputControlled
            className="p-col-12 p-sm-6"
            control={control}
            name="dataResultado"
            label="Data resultado"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            errorMsg={errors.dataResultado?.message}
          />

          {laudo ? (
            <div className="p-grid p-col-12 p-px-0">
              <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16}>
                Laudo anexado:
              </SimpleText>
              {isPDF ? (
                <Button
                  onClick={() => window.open(laudo, '_blank')}
                  label="Visualizar laudo"
                  btnType="tonal"
                  className="p-col-12"
                  icon="fas fa-file-pdf"
                />
              ) : (
                <div className="p-col-12">
                  <img className="p-col-12 laudo-anexado" src={laudo} />
                </div>
              )}
            </div>
          ) : (
            <UploadLaudo
              loadedFile={loadedFile}
              handleDeleteFile={handleDeleteFile}
              handleLoadFile={handleLoadFile}
            />
          )}

          <LaudoField />

          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Resultado
          </SimpleText>

          <DropdownControlled
            className="p-col-12 p-sm-4"
            control={control}
            name="severidadeAlteracao"
            label="Nível de alteração"
            filter={false}
            options={severidadeOptions}
          />

          <FormInput
            className="p-col-12 p-sm-4"
            classNameInput="p-w-100"
            name="resutladoNumerico"
            label="Resultado do exame"
            type="number"
            hideTextErrorSpace
          />

          <CalendarInputControlled
            className="p-col-12 p-sm-4"
            control={control}
            name="dataRepeticao"
            label="Data para repetição"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
          />

          <TextareaInputControlled
            className="p-col-12"
            control={control}
            name="observacao"
            label="Observações"
          />

          <div className="p-col-12">
            <Card
              className=" p-d-flex p-flex-column p-flex-sm-row p-ai-center p-jc-between"
              type={CARD_TYPES.BORDER}
            >
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Alertar nos próximos atendimentos
              </SimpleText>

              <SwitchControlled
                className="p-mt-3 p-mt-sm-0"
                control={control}
                name="lembrete"
                showStatus
              />
            </Card>
          </div>

          <div className="p-col-12 p-grid p-px-0 p-d-flex p-jc-between">
            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Origem
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                eMed
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Orienteção
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.orientacao}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Exame Futuro
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.exameFuturo && procedimento.dataExameFuturo
                  ? 'Sim'
                  : 'Não'}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Data do Exame Futuro
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {formatData(procedimento.dataExameFuturo)}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Caráter da solicitação
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {getTipoSolicitacaoLabel(procedimento.tipoSolicitacao)}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Local
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {formatLocal(procedimento.local)}
              </SimpleText>
            </div>
          </div>

          <div className="p-col-12 p-grid p-p-0 p-d-flex p-jc-between">
            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Data sugerida de internação
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {formatData(procedimento.dataSugerInternacao)}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Qtd. diarias
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.quantidadeDiarias}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Tipo Internação
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.tipoInternacao?.descricao}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Hospital
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {capitalizeFirstLetter(procedimento.hospital?.nome || '')}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                Regime Int.
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.regimeInternacao?.descricao}
              </SimpleText>
            </div>

            <div className="p-col-fixed p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                medium
              >
                OPME
              </SimpleText>

              <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16}>
                {procedimento.anexoOpme ? 'Sim' : 'Não'}
              </SimpleText>
            </div>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
