import React, { useCallback, useEffect, useState } from 'react';

import { FaturamentoAPI } from 'src/APIs/FinanceiroAPI/FaturamentoAPI/FaturamentoAPI';

import { convertCurrencyToNumber } from 'src/utils/utils';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import ServicosAutogerados from './ServicosAutogerados';

const PanelContent = ({ item, calculoValorTotalReceber }: any) => {
  const [valorPago, setValorPago] = useState(item.valorPago || 0);
  const [valorGlosa, setValorGlosa] = useState(item.valorGlosa || 0);
  const [status, setStatus] = useState(item.status);

  const statusOptions = [
    { label: 'Pago', value: 'PAGO' },
    { label: 'Glosa', value: 'GLOSA' },
    { label: 'Pendente', value: 'PENDENTE' },
    { label: 'Faturado', value: 'FATURADO' },
  ];

  const handleChangeValorPago = useCallback(
    async (idItem: any, novoValor: number, hideToast?: boolean) => {
      if (
        novoValor === item.valorPago ||
        (novoValor === 0 && item.valorPago === null)
      )
        return;

      return await FaturamentoAPI.putValorAtendimento(
        idItem,
        {
          valorPago: novoValor,
        },
        {
          hideToast: hideToast || false,
        },
      );
    },
    [item.valorPago],
  );

  const handleChangeValorGlosa = async (idItem: any, novoValor: number) => {
    if (
      novoValor === item.valorGlosa ||
      (novoValor === 0 && item.valorGlosa === null)
    )
      return;

    item.valorGlosa = novoValor;
    await FaturamentoAPI.putValorAtendimento(idItem, {
      valorGlosa: novoValor,
    });

    const calculoValorPago = item?.valor - novoValor;

    setValorPago(calculoValorPago);
    handleChangeValorPago(idItem, calculoValorPago, true);
    calculoValorTotalReceber();
  };

  const handleChangeStatus = async (idItem: any, novoStatus: string) => {
    if (novoStatus === item.status) return;
    return await FaturamentoAPI.putStatusAtendimento({
      idsAtendimento: idItem,
      status: novoStatus,
    });
  };

  useEffect(() => {
    if (status === 'PAGO' && valorPago === null) {
      const calculoValorPago = item.valorReceber - valorGlosa;
      setValorPago(calculoValorPago);
      handleChangeValorPago(item.idAtendimento, calculoValorPago, true);
    }
  }, [
    handleChangeValorPago,
    item.idAtendimento,
    item.valorReceber,
    status,
    valorGlosa,
    valorPago,
  ]);

  return (
    <div className="p-grid p-mt-1">
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Guia
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {item.guia}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Cod. TUSS
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {item.codigo}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Quantidade
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {item.quantidade}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-3"
        >
          Valor
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {item.valor}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Valor glosa
        </SimpleText>
        <InputNumber
          mode="currency"
          locale="pt-BR"
          placeholder="R$"
          format={true}
          allowEmpty={false}
          max={9999999.99}
          value={valorGlosa}
          onBlur={e => {
            handleChangeValorGlosa(
              item.idAtendimento,
              convertCurrencyToNumber(e.target.value),
            );
          }}
          onChange={e => setValorGlosa(e.target.value)}
        />
      </div>
      <div className="p-col-6 p-md-1 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Valor pago
        </SimpleText>
        <InputNumber
          placeholder="R$"
          mode="currency"
          locale="pt-BR"
          format={true}
          allowEmpty={false}
          max={9999999.99}
          value={valorPago}
          onBlur={e => {
            handleChangeValorPago(
              item.idAtendimento,
              convertCurrencyToNumber(e.target.value),
            );
          }}
          onChange={e => setValorPago(e.target.value)}
        />
      </div>

      <div className="p-col-12 p-md-2 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Status
        </SimpleText>
        <Dropdown
          options={statusOptions}
          value={status}
          onChange={e => {
            setStatus(e.value);
            handleChangeStatus(item.idAtendimento, e.value);
          }}
          filter={false}
        />
      </div>

      <div className="p-col-12 p-md-4 p-d-flex p-flex-column p-py-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Descrição
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-js-center"
        >
          {item.descricao}
        </SimpleText>
      </div>
      {item?.servicosAutogerados?.length > 0 && (
        <div className="p-col-12 p-grid p-p-0">
          <div
            className="p-col-12"
            style={{ background: 'var(--neutral-color-grayscale-88)' }}
          >
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Serviços autogerados
            </SimpleText>
          </div>

          {item?.servicosAutogerados?.map(
            (servico: any, index: React.Key | null | undefined) => (
              <ServicosAutogerados
                key={index}
                servico={servico}
                statusOptions={statusOptions}
                calculoValorTotalReceber={calculoValorTotalReceber}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default PanelContent;
