import Http, { HttpParams } from 'src/core/http/Http';

export const getCiaps = async (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/atendimento/${idAtendimento}/ciaps`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const getVersoesCiaps = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: '/utils/versoes-ciaps',
    hideToast: true,
    return: true,
    ...options,
  });
};

export const buscaCiaps = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: '/utils/busca-ciaps',
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};

const CiapAPI = {
  getCiaps,
  getVersoesCiaps,
  buscaCiaps,
};

export default CiapAPI;
