import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  pontuacao: GetPontuacaoCarteiraDTO;
  consultoriosPontuacao: JSX.Element;
  dataInclusao: string;
  pontuacaoStatus: JSX.Element;
  onEdit: (pontuacao: GetPontuacaoCarteiraDTO) => void;
  onDelete: (id: number) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const {
    pontuacao,
    onEdit,
    onDelete,
    dataInclusao,
    pontuacaoStatus,
    consultoriosPontuacao,
  } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(pontuacao),
      checkPermission: 'ADM_CARTEIRA_APS_PONTUACAO_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(pontuacao.id),
      checkPermission: 'ADM_CARTEIRA_APS_PONTUACAO_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome do grupo
        </SimpleText>
        <br />

        <SimpleText
          className="p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {pontuacao.nome}
        </SimpleText>
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Consultório
        </SimpleText>
        <br />

        {consultoriosPontuacao}
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Data de inclusão
        </SimpleText>
        <br />

        <SimpleText
          className="p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dataInclusao}
        </SimpleText>
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Status
        </SimpleText>
        <br />

        {pontuacaoStatus}
      </div>
    </SearchListMobileRow>
  );
};
