import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import Separator from 'src/components/Separator/Separator';
import SwitchControlled from 'src/components/Switch/SwitchControlled';
import Tab from 'src/components/Tab/Tab';
import * as Yup from 'yup';

interface FormBiometriaProps {
  onSubmit: any;
  values: any;
}

const FormBiometria = ({ onSubmit, values }: FormBiometriaProps) => {
  return (
    <Tab
      clean
      values={[
        {
          label: 'Biometria facial paciente',
          content: <BiometriaPaciente onSubmit={onSubmit} values={values} />,
        },
      ]}
    />
  );
};

export default FormBiometria;

interface FormData {
  ativa: boolean;
  provedor: string;
  faixaInicial: number;
  faixaFinal: number;
}

interface BiometriaPacienteProps {
  onSubmit: any;
  values: any;
}

const BiometriaPaciente = ({ onSubmit, values }: BiometriaPacienteProps) => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    ativa: Yup.boolean().required('O campo biometria ativa é obrigatório.'),
    provedor: Yup.string().required(
      'O campo provedor de biometria é obrigatório.',
    ),
    faixaInicial: Yup.number()
      .required('O campo faixa etária início é obrigatório.')
      .min(1, 'O campo faixa etária início deve ser maior que 0.')
      .typeError('Campo obrigatório'),
    faixaFinal: Yup.number()
      .required('O campo faixa etária fim é obrigatório.')
      .min(
        Yup.ref('faixaInicial'),
        'O campo faixa etária fim não pode ser menor que o campo faixa etária início.',
      )
      .min(1, 'O campo faixa etária fim deve ser maior que 0.')
      .typeError('Campo obrigatório'),
  });

  const formMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ativa: values.ativa || false,
      provedor: values.provedor || '',
      faixaInicial: values.faixaInicial || 0,
      faixaFinal: values.faixaFinal || 0,
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const watchBiometriaAtiva = watch('ativa');

  const handleSubmitBiometria = (data: FormData) => {
    const submitData = { biometriaFacial: data };
    onSubmit(submitData);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="p-grid p-col-12">
        <SimpleText
          medium
          fontColor="color_40"
          className="p-col-12 p-lg-8 p-xl-7"
        >
          Configuração de biometria facial
        </SimpleText>
        <SimpleText fontColor="color_40" className="p-col-12 p-lg-8 p-xl-7">
          Ative para permitir a configuração de biometria facial.
        </SimpleText>

        <div className="p-col-12 p-lg-8 p-xl-7">
          <SwitchControlled name="ativa" control={control} showStatus />
        </div>

        <SimpleText
          medium
          className="p-col-12 p-lg-8 p-xl-7 p-pb-0"
          disabled={!watchBiometriaAtiva}
        >
          Provedor do serviço
        </SimpleText>
        <DropdownControlled
          className="p-col-12 p-lg-8 p-xl-7"
          name="provedor"
          control={control}
          options={[
            { label: 'Biodoc', value: 'BIO_DOC' },
            { label: 'Univision', value: 'UNIVISION' },
          ]}
          placeholder="Selecione"
          disabled={!watchBiometriaAtiva}
          errorMsg={errors.provedor?.message}
        />

        <SimpleText
          fontColor="color_40"
          className="p-col-12 p-lg-8 p-xl-7"
          disabled={!watchBiometriaAtiva}
        >
          Insira uma faixa etária, para a utilização do recurso de biometria
          facial.
        </SimpleText>

        <SimpleText
          medium
          className="p-col-12 p-lg-8 p-xl-7 p-pb-0"
          disabled={!watchBiometriaAtiva}
        >
          Faixa etária (anos)
        </SimpleText>
        <div className="p-grid p-col-12 p-lg-8 p-xl-7 p-px-0 p-pt-0">
          <FormInput
            className="p-col-12 p-md-6"
            name="faixaInicial"
            type="number"
            disabled={!watchBiometriaAtiva}
            errorMsg={errors.faixaInicial?.message}
          />
          <FormInput
            className="p-col-12 p-md-6"
            name="faixaFinal"
            type="number"
            disabled={!watchBiometriaAtiva}
            errorMsg={errors.faixaInicial?.message}
          />
        </div>

        <Separator layout="horizontal" />
        <div className="p-col-12 p-sm-4">
          <Button
            btnType="ghost"
            label="Cancelar"
            onClick={() => navigate(-1)}
            stretch
          />
        </div>
        <div className="p-col-12 p-sm-8">
          <Button
            label="Salvar"
            type="button"
            stretch
            loading={isSubmitting}
            onClick={() => handleSubmit(handleSubmitBiometria)()}
          />
        </div>
      </form>
    </FormProvider>
  );
};
