import React from 'react';

import MenuSidebar from 'src/components/MenuSidebar/MenuSidebar';

import HorarioAtendimento from 'src/pages/Emed/Agenda/MenuEditarHorarios/HorarioAtendimento/HorarioAtendimento';
import './MenuEditarHorarios.scss';

const MenuEditarHorarios = () => {
  return (
    <div className="MenuEditarHorarios">
      <HorarioAtendimento />
    </div>
  );
};

export default React.memo(MenuEditarHorarios);
