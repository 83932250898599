import { useMemo } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

const diaOptions = [
  { label: 'No dia', value: 'NO_DIA' },
  { label: 'Um dia antes', value: 'UM_DIA_ANTES' },
  { label: 'Uma semana antes', value: 'UMA_SEMANA_ANTES' },
];

interface HorariosProps {
  onSubmit(v: any): void;
  handleNavigate(): void;
  values: any;
}

const Horarios = ({ values, onSubmit, handleNavigate }: HorariosProps) => {
  const { isTablet } = useSize();

  const validationSchema = Yup.object().shape({
    datas: Yup.array().of(
      Yup.object({
        diaEnvio: Yup.string()
          .nullable()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
        horaEnvio: Yup.string()
          .nullable()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
      }),
    ),
  });

  const defaultValues = useMemo(() => {
    if (values.length !== 0) {
      const date = new Date();

      return values.map((value: any) => {
        date.setHours(value.horaEnvio.substring(2, 0));
        date.setMinutes(value.horaEnvio.substring(5, 3));

        return {
          diaEnvio: value.diaEnvio,
          horaEnvio: new Date(date),
          id: value.id,
        };
      });
    }

    return [
      {
        diaEnvio: '',
        horaEnvio: '',
        id: null,
      },
    ];
  }, [values]);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { datas: defaultValues },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const {
    fields: dataFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'datas',
  });

  const handleAdicionarData = () => {
    append({
      diaEnvio: '',
      horaEnvio: '',
      id: null,
    });
  };

  const submit = (data: any) => {
    const values = data.datas.map((item: any) => ({
      diaEnvio: item.diaEnvio,
      horaEnvio: dayjs(item.horaEnvio).format('HH:mm'),
      id: item.id,
    }));

    onSubmit(values);
  };

  const columnSize = isTablet ? 'p-col-12' : 'p-col';

  return (
    <div className="p-grid p-col-12 p-lg-10 p-xl-6 p-pl-5">
      <div className="novoLembrete-content">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
          Selecione o dia e a hora do envio desejado.
        </SimpleText>

        <SimpleText
          className="p-mt-3"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Horário do envio
        </SimpleText>

        <div className="border p-mt-3">
          {dataFields.map((field, index) => (
            <div key={field.id}>
              <div className="p-grid p-ai-center">
                <DropdownControlled
                  className={columnSize}
                  control={control}
                  placeholder="Selecione..."
                  name={`datas.${index}.diaEnvio`}
                  label="Dia"
                  options={diaOptions}
                  filter={false}
                  errorMsg={errors?.datas?.[index]?.diaEnvio?.message}
                />

                <CalendarInputControlled
                  className={columnSize}
                  label="Hora"
                  name={`datas.${index}.horaEnvio`}
                  placeholder="hh:mm"
                  control={control}
                  icon={'fas fa-clock'}
                  stepMinute={15}
                  showIcon
                  timeOnly
                  readOnlyInput
                  errorMsg={errors?.datas?.[index]?.horaEnvio?.message}
                />

                <Button
                  className="p-col-fixed horario-trash-col p-mt-3"
                  icon="fa fa-trash"
                  disabled={dataFields.length === 1}
                  onClick={() => remove(index)}
                  btnType="gray"
                />
              </div>
            </div>
          ))}

          <Button
            className="p-mt-3"
            label="Adicionar data"
            onClick={handleAdicionarData}
            icon="fas fa-plus"
            btnType="green-link"
          />
        </div>
      </div>

      <Button
        className="p-my-1 p-col-3"
        label="Cancelar"
        btnType="ghost"
        onClick={handleNavigate}
        loading={isSubmitting}
      />

      <Button
        className="p-my-1 p-col-4"
        label="Próximo"
        btnType="tonal"
        disabled={dataFields.length === 0}
        onClick={handleSubmit(submit)}
        loading={isSubmitting}
      />
    </div>
  );
};

export default Horarios;
