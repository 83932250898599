import { memo, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import useDeepCompareEffect from 'src/utils/hooks/useDeepCompareEffect';

import { ConvenioSelectForm } from './components/Form/ConvenioSelectForm';
import { Button } from 'src/components/_UI/Button';
import { FieldServicoTussVinculado } from 'src/components/Fields/FieldServicoTussVinculado/FieldServicoTussVinculado';

import { useNovoAgendamento } from './hooks/NovoAgendamentoContext';

const FaturamentoAgendamento = ({ convenioInitial }: any) => {
  const {
    step3Ref,
    step4Ref,
    planoSelected,
    profissionalAtivo,
    consultorioAtivo,
    servicoTussSelected,
    setServicoTussSelected,
  } = useNovoAgendamento();
  const useFormMethods = useForm({
    defaultValues: {
      servicoTuss: servicoTussSelected,
      convenio: planoSelected,
    },
  });
  const { watch, reset } = useFormMethods;

  const watchServicoTuss = watch('servicoTuss');

  useEffect(() => {
    if (watchServicoTuss) {
      setServicoTussSelected(watchServicoTuss);
    }
  }, [setServicoTussSelected, watchServicoTuss]);

  useDeepCompareEffect(() => {
    if (planoSelected) {
      reset({ servicoTuss: null });
      setServicoTussSelected(null);
    }
  }, [planoSelected]);

  return (
    <div>
      <div>
        <ConvenioSelectForm convenioInitial={convenioInitial} />
      </div>
      <FormProvider {...useFormMethods}>
        <form>
          <FieldServicoTussVinculado
            name="servicoTuss"
            label="Serviço"
            returnType="all"
            className="p-w-100"
            idConsultorio={consultorioAtivo}
            params={useMemo(
              () => ({
                idConvenio: planoSelected?.idConvenio,
                idMedico: profissionalAtivo,
              }),
              [planoSelected?.idConvenio, profissionalAtivo],
            )}
            disabled={!planoSelected}
          />
        </form>
      </FormProvider>
      <div>
        <Button
          label={'Próximo'}
          btnType="tonal"
          stretch
          onClick={() => {
            step3Ref.current?.collapse();
            step4Ref.current?.show();
          }}
        />
      </div>
    </div>
  );
};

export default memo(FaturamentoAgendamento);
