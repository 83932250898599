import { useEffect, useState, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router';

import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import AvatarUpload from '../../components/AvatarUpload/AvatarUpload';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import Page from 'src/components/Basics/Page/Page';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';


import { useManterPaciente } from '../MaterPacienteContext';

const Header = () => {
  const navigate = useNavigate();

  const { isFormsDirty } = useManterPaciente();
  const { idPaciente } = useParams();

  const [urlFoto, setUrlFoto] = useState<string | null>(null);

  const { isOpen, open, close } = useDisclosure({ opened: false });

  const loadPacienteFoto = useCallback(async () => {
    if (!idPaciente) return;

    const response = await PacienteAPI.getPacienteFoto(Number(idPaciente));

    if (response?.urlFoto) setUrlFoto(response.urlFoto);
  }, [idPaciente]);

  useEffect(() => {
    loadPacienteFoto();
  }, [loadPacienteFoto]);

  const onGoBackToPacientes = () => {
    if (isFormsDirty) {
      open();
    } else {
      goBackToPacientes();
    }
  };

  const goBackToPacientes = () => {
    navigate('/pacientes', {
      replace: true,
    });
  };

  return (
    <Page id="manter-paciente-header" content>
      <>
        <Button
          btnType={BtnTypes.LINK}
          id="back-button"
          label="Cancelar e voltar aos pacientes"
          icon="fa fa-arrow-left"
          onClick={onGoBackToPacientes}
        />

        <div className="p-grid p-col-12">
          <AvatarUpload urlFoto={urlFoto} />
        </div>

        {isOpen && (
          <ConfirmDialog
            visible={isOpen}
            header="Sair"
            text="Você realmente deseja sair do cadastro do paciente? O progresso será perdido."
            confirmText="Sim, sair"
            onHide={() => close()}
            onConfirm={goBackToPacientes}
          />
        )}
      </>
    </Page>
  );
};

export default Header;
