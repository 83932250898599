import { useState } from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { handleFileDownload } from 'src/utils/files';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ModalVisualizar from './ModalVisualizarDocumento';

import './PanelHeader.scss';

interface PanelHeaderProps {
  documento: Documentos;
  isCollapsed: boolean;
  onToggle: () => void;
}

export function PanelHeader({
  documento,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) {
  const { isTablet } = useSize();

  const dialogVisualizar = useDisclosure({ opened: false });

  const isRetroativo = !!documento.justificativaOcorrenciaRetroativa;
  const isInativo = documento.status === 'INATIVO';

  const headerTextClassName = isInativo ? 'opme-inativa' : '';

  return (
    <>
      {!isInativo && (
        <Tooltip
          target={`.tooltip-${documento.id}`}
          position="top"
          content="Visualizar documento"
          mouseTrackTop={10}
        />
      )}

      <div
        data-isInativo={documento.status === 'INATIVO'}
        className={
          'p-grid p-col-12 p-ai-center p-jc-between documento-panel-header'
        }
      >
        <div
          className={`p-d-flex p-ai-center p-col-12 p-md-6 tooltip-${documento.id}`}
          onClick={() =>
            !isInativo ? window.open(documento.documentoUrl) : null
          }
        >
          <i
            className="fas fa-file"
            data-isInativo={documento.status === 'INATIVO'}
          />

          <div className="p-d-flex p-jc-center p-flex-column p-ml-2">
            <SimpleText className="doc-label doc-name">
              {documento.nomeDocumento}
            </SimpleText>
          </div>
        </div>
        <div
          className={`p-d-flex p-gap-3 p-col ${
            isTablet ? 'p-jc-between' : 'p-jc-end'
          }`}
        >
          {isRetroativo && (
            <div>
              <SimpleText className="tag tag-retroativo" fontSize="xxxs">
                Retroativo
              </SimpleText>
            </div>
          )}
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data de ocorrência
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              className="doc-label p-mt-2"
              medium
            >
              {documento.dataOcorrencia
                ? dayjs(documento.dataOcorrencia).format('DD/MM/YYYY HH:mm')
                : ''}
            </SimpleText>
          </div>

          <div className="coluna-acoes p-d-flex p-jc-between p-jc-sm-end p-gap-2">
            <Button
              icon="fa fa-file-download"
              btnType="gray"
              disabled={isInativo}
              onClick={() =>
                handleFileDownload(documento.documentoUrl, 'Documento')
              }
            />

            {isInativo && (
              <Button
                icon="fa fa-eye"
                btnType="gray"
                onClick={() => dialogVisualizar.open()}
              />
            )}

            <Button
              icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
              btnType="ghost"
              onClick={onToggle}
            />
          </div>
        </div>
      </div>

      {dialogVisualizar.isOpen && (
        <ModalVisualizar documento={documento} {...dialogVisualizar} />
      )}
    </>
  );
}
