import Http, { HttpParams } from 'src/core/http/Http';

export const getCids = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'utils/busca-combinada-cids',
    params,
    hideToast: true,
    ...options,
  });

export const getCiaps = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'utils/busca-combinada-ciaps',
    params,
    hideToast: true,
    ...options,
  });

export const getCidsCiapsBusca = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'utils/buscas-combinadas-cids-ciaps',
    params,
    hideToast: true,
    ...options,
  });

export const getProntuario = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IProntuario> =>
  Http.get({
    url: `prontuario/atendimento/${idAtendimento}`,
    hideToast: true,
    service: 'pep',
    ...options,
  });

export const getModelos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'personalizar-prontuario-medico/modelos/pesquisa',
    params,
    hideToast: true,
    ...options,
  });

export const getCampos = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `prontuario/configuracao-campos/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getDesfechos = (
  idEmpresa: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'desfecho-empresa/listagem/',
    hideToast: true,
    ...options,
  });

export const getTiposConsulta = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `prontuario/atendimento/${idAtendimento}/tipos-consulta`,
    hideToast: true,
    ...options,
  });

export const putTipoExibicaoCampos = (
  idProfissionalSaude: number,
  campo: string,
  tipoExibicao: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: 'personalizar-prontuario-medico/campos/atualiza-exibicao',
    data: {
      idProfissionalSaude,
      campo,
      tipoExibicao,
    },
    return: true,
    ...options,
  });

export const putCampos = (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: 'prontuario/campos-prontuarios',
    data: payload,
    return: true,
    hideToast: true,
    errorHideToast: false,
    ...options,
  });

export const postAddCid = (
  idAtendimento: number,
  cidsComDiagnosticos: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'atendimento/cids',
    data: {
      idAtendimento,
      cidsComDiagnosticos,
    },
    return: true,
    hideToast: true,
    errorHideToast: false,
    ...options,
  });

export const postAddCiap = (
  idAtendimento: number,
  idCiaps: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'atendimento/ciaps',
    data: {
      idAtendimento,
      idCiaps,
    },
    return: true,
    hideToast: true,
    errorHideToast: false,
    ...options,
  });

export const deleteCid = (
  idAtendimento: number,
  idCid: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `atendimento/${idAtendimento}/cid/${idCid}`,
    return: true,
    hideToast: true,
    errorHideToast: false,
    ...options,
  });

export const deleteCiap = (
  idAtendimento: number,
  idCiap: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `atendimento/${idAtendimento}/ciap/${idCiap}`,
    return: true,
    hideToast: true,
    errorHideToast: false,
    ...options,
  });

export const getTipoImpresso = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'impresso/tipo-impresso',
    hideToast: true,
    return: true,
    ...options,
  });

const UtilsAPI = {
  getCids,
  getCiaps,
  getCidsCiapsBusca,
  getProntuario,
  getModelos,
  getCampos,
  getDesfechos,
  getTiposConsulta,
  putTipoExibicaoCampos,
  putCampos,
  postAddCid,
  postAddCiap,
  deleteCid,
  deleteCiap,
  getTipoImpresso,
};

export default UtilsAPI;
