export const permissionsOptions = [
  { label: 'Biometria', isActive: false, value: 'biometria' },
  {
    label: 'Prontuário completo',
    isActive: false,
    value: 'prontuarioCompleto',
  },
  { label: 'PA', isActive: false, value: 'pa' },
  { label: 'APS', isActive: false, value: 'aps' },
  {
    label: 'Bonificação contrarreferência',
    isActive: false,
    value: 'contrarreferencia',
  },
  {
    label: 'Exibir motivo atendimento',
    isActive: false,
    value: 'exibirMotivoAtendimento',
  },
  {
    label: 'Motivo falta obrigatório',
    isActive: false,
    value: 'exibirMotivoFalta',
  },
  {
    label: 'Presença retroativa',
    isActive: false,
    value: 'presencaRetroativa',
  },
  {
    label: 'Identificação por etiqueta',
    isActive: false,
    value: 'identificacaoEtiqueta',
  },
  {
    label: 'Exibir CPF na etiqueta',
    isActive: false,
    value: 'exibirCpfEtiqueta',
  },
  {
    label: 'Obrigatoriedade CIAP',
    isActive: false,
    value: 'ciap',
  },
  { label: 'Desfecho', isActive: false, value: 'desfecho' },
];
