import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

const ModalInfoLiberacaoSigilo = ({ isOpen, close }: DisclosureType) => {
  const footer = (
    <Button
      label="Fechar"
      onClick={() => {
        close();
      }}
      btnType="ghost"
      stretch
    />
  );

  return (
    <Dialog
      header="Nota sobre liberação/bloqueio de sigilo"
      visible={isOpen}
      onHide={close}
      style={{ maxWidth: '500px' }}
      footer={footer}
    >
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          A Resolução CFM n° 1.821/2007 regulamenta as normas técnicas
          concernentes à digitalização e uso dos sistemas informatizados para a
          guarda e manuseio dos documentos dos prontuários dos pacientes. O
          acesso ao prontuário eletrônico e aos atendimentos passados,
          realizados por diferentes médicos assistentes, não configura quebra de
          sigilo, tendo em vista tratar-se de atendimento por staffs da mesma
          instituição de saúde.
        </SimpleText>
      </div>
    </Dialog>
  );
};

export default ModalInfoLiberacaoSigilo;
