import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import { formatData } from '../helpers';


interface GeneroProps {
  className?: string;
}

const Genero = ({ 
  className = 'p-col-12'
}: GeneroProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [generosOptions, setGenerosOptions] = useState<any>([]);

  const fetchGeneroData = async () => {
    const response = await UtilsAPI.GetGeneros();

    setGenerosOptions(formatData(response, 'descGenero', 'id'));
  };

  useEffect(() => {
    fetchGeneroData();
  }, []);

  return (
    <DropdownControlled
      className={className}
      control={control}
      name="idGenero"
      label="Gênero"
      placeholder="Selecione"
      options={generosOptions}
      errorMsg={errors.idGenero?.message}
    />
  );
}

export default Genero;