import { useCallback, useEffect, useState } from 'react';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import TipoGrupoAtendimentoAPI from 'src/APIs/AgendaAPI/TipoGrupoAtendimento/TipoGrupoAtendimentoAPI';

import { RadioButton } from 'src/components/_UI/RadioButton';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import './ModalFiltroGrupoAtendimento.scss';
import { Button } from 'src/components/_UI/Button';

interface ModalFiltroGrupoAtendimentoProps {
  visible: boolean;
  onHide(): void;
}

const Header = ({ reset }: any) => {
  return (
    <div className="p-d-flex p-flex-row p-ai-center p-jc-between">
      <SimpleText fontSize={FONT_SIZE.MD} fontColor={FONT_COLOR.COLOR_16}>
        Filtros
      </SimpleText>

      <Button btnType="green-link" label="Limpar" onClick={() => reset()} />
    </div>
  );
};

const ModalFiltroGrupoAtendimento = ({
  visible,
  onHide,
}: ModalFiltroGrupoAtendimentoProps) => {
  const [medicosList, setMedicosList] = useState<any[]>([]);
  const [tiposGruposList, setTiposGruposList] = useState<any>([]);
  const [idProfissional, setIdProfissional] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [idTipoGrupo, setIdTipoGrupo] = useState<any>();

  const loadMedicos = useCallback(async () => {
    const responseProfs = await ProfissionalAPI.loadProfissionais();

    const opa = responseProfs.map(m => {
      return {
        label: m.nome,
        value: m.id,
      };
    });

    setMedicosList(opa as any);
  }, []);

  useEffect(() => {
    loadMedicos();
  }, [loadMedicos]);

  const loadTiposGrupos = useCallback(async () => {
    const responseTiposGrupo =
      await TipoGrupoAtendimentoAPI.loadTipoGrupoAtendimentos({ nome: '' });

    const opa = responseTiposGrupo.list?.map(m => {
      return {
        label: m.nome,
        value: m.id,
      };
    });

    setTiposGruposList(opa);
  }, []);

  useEffect(() => {
    loadTiposGrupos();
  }, [loadTiposGrupos]);

  const handleCancelGrupoAtendimento = useCallback(() => {
    onHide();
  }, [onHide]);

  const { setFilter } = useSearchList();

  const handleFilter = useCallback(() => {
    let result: any = {};
    if (idProfissional) {
      result = {
        ...result,
        idProfissional,
      };
    }
    if (status) {
      result = {
        ...result,
        status: status === 'Ativa' ? true : false,
      };
    }

    if (idTipoGrupo) {
      result = {
        ...result,
        idTipoGrupo,
      };
    }

    setFilter(result);
    onHide();
  }, [idProfissional, idTipoGrupo, onHide, setFilter, status]);

  const hanldeLimpar = useCallback(() => {
    setIdProfissional(null);
    setStatus(null);
    setIdTipoGrupo(null);
    setFilter({});
  }, [setFilter]);

  return (
    <Dialog
      className="footer-on modal-body-filtro-grupo"
      type="modal"
      header={() => <Header reset={hanldeLimpar} />}
      visible={visible}
      closable={false}
      onHide={handleCancelGrupoAtendimento}
      footer={() => <div />}
    >
      <div className="modal-body-filtro-grupo">
        <Dropdown
          className={'p-col-12 mb-1'}
          label={'Médico'}
          options={medicosList}
          onChange={t => setIdProfissional(t.target.value)}
          value={idProfissional}
        />

        <SimpleText fontSize={FONT_SIZE.XS} bold className="p-pl-2 p-pb-2">
          Situação
        </SimpleText>

        <div className="p-pl-2 p-pb-2">
          <RadioButton
            label="Ativa"
            value="Ativa"
            name="status"
            onChange={e => setStatus(e.value)}
            checked={status === 'Ativa'}
            panel={false}
          />
        </div>

        <div className="p-pl-2 p-pb-3">
          <RadioButton
            label="Desativa"
            value="Desativa"
            name="status"
            onChange={e => setStatus(e.value)}
            checked={status === 'Desativa'}
            panel={false}
          />
        </div>

        <Dropdown
          className={'p-col-12'}
          label={'Tipo de grupo'}
          options={tiposGruposList}
          onChange={t => setIdTipoGrupo(t.target.value)}
          value={idTipoGrupo}
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            type="button"
            label="Cancelar"
            btnType="ghost"
            onClick={() => onHide()}
            stretch
          />

          <Button
            type="button"
            label="Aplicar filtros"
            onClick={handleFilter}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ModalFiltroGrupoAtendimento;
