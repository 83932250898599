import React, { useEffect, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { TieredMenu } from 'primereact/tieredmenu';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { PacienteFamiliaresAPI } from 'src/APIs/ProntuarioAPI/PacienteFamiliaresAPI/PacienteFamiliaresAPI';
import { LoadPacienteFamiliares } from 'src/models/APIs/ProntuarioAPI/PacienteFamiliaresAPI/PacienteFamiliares';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  capitalizeFirstLetter,
  preventDefaultAndStopPropagation,
} from 'src/utils/utils';

import { FieldSearchPaciente } from './components/FiedlSearchPaciente';
import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { ModalAdicionarFamiliaresCreate } from './Create/ModalAdicionarFamiliaresCreate';

import './ModalAdicionarFamiliares.scss';

type IProps = LoadPacienteFamiliares & {
  plano: any;
};

type AccordionHeaderProps = {
  handleRemove: () => void;
  handleEdit: (item: any) => void;
  idx: number;
  item: IProps;
};

export const ModalAdicionarFamiliares = ({ isOpen, close }: DisclosureType) => {
  const { isMobile } = useSize();
  const {
    atendimento: { paciente },
  } = useAppSelector((state: RootState) => state);
  const dialogCreate = useDisclosure({ opened: false });

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    pacientes: Yup.array().of(
      Yup.object({
        idVinculoFamiliar: Yup.number().required(),
        nome: Yup.string().required(),
        sexo: Yup.mixed().required(),
      }),
    ),
  });

  const formList = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      pacientes: [] as any[],
    },
  });

  const { fields, append, remove, update, replace } = useFieldArray({
    name: 'pacientes',
    keyName: 'idx',
    control: formList.control,
  });

  useEffect(() => {
    setLoading(true);

    PacienteFamiliaresAPI.loadPacienteFamiliares(paciente.id, {
      throwError: true,
    })
      .then(data => replace(data))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [paciente.id, replace]);

  const handleAdd = (item: any) => {
    const data = {
      nome: item.nome,
      sexo: item.sexo,
      dataNascimento: item.dataNascimento,
      plano: item.cartaoPrincipal?.convenio,
      idVinculoFamiliar: item.grauParentesco?.id,
      descricaoVinculoFamiliar: item.grauParentesco?.descricao,
      idOcupacao: item?.idOcupacao,
      causaObito: item.causaObito,
      dataObito: item.dataObito,
      observacoes: item.observacoes,
      cids: item?.cids || [],
      idPaciente: item?.id || undefined,
    };

    append(data);
  };

  const handleEdit = (item: any) => {
    update(item.idx, {
      ...item,
      idVinculoFamiliar: item.grauParentesco?.id,
      descricaoVinculoFamiliar: item.grauParentesco?.descricao,
    });
  };

  const onSubmit = (values: any) => {
    const payload = values.pacientes.map((item: any) => ({
      id: item?.id || undefined,
      nome: item.nome,
      dataNascimento: item.dataNascimento,
      idSexo: item.sexo.id,
      idOcupacao: item?.idOcupacao,
      idVinculoFamiliar: item.idVinculoFamiliar,
      dataObito: item.dataObito,
      causaObito: item.causaObito,
      observacoes: item.observacoes,
      cids: item.cids,
    }));

    return PacienteFamiliaresAPI.createPacienteFamiliares(
      paciente.id,
      payload,
    ).then(() => close());
  };

  return (
    <Dialog
      className="dialog-add-familiares"
      header="Adicionar familiares"
      maximizedMobileSize
      visible={isOpen}
      onHide={close}
    >
      <FormProvider {...formList}>
        <form onSubmit={formList.handleSubmit(onSubmit)}>
          <div className="p-grid p-w-100 p-ai-center">
            <div className="p-col-12 p-sm-10">
              <FieldSearchPaciente onAdd={handleAdd} />
            </div>
            <div className="p-col-12 p-sm-2">
              <Button
                label="Novo Familiar"
                stretch
                type="button"
                icon="fa-solid fa-user-plus"
                btnType="green-link"
                onClick={() => dialogCreate.open()}
              />
            </div>
          </div>

          <div className="list-content p-d-flex p-flex-column p-w-100 p-py-2">
            <SimpleText className="p-col-12" bold>
              Lista de familiares
            </SimpleText>

            {!!fields.length && !loading && (
              <div className="row-header p-col-12 p-d-flex">
                <SimpleText
                  className="p-col-6 p-sm-4"
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  Nome/Plano de saúde
                </SimpleText>
                {!isMobile && (
                  <SimpleText
                    className="p-col-2"
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Sexo
                  </SimpleText>
                )}
                {!isMobile && (
                  <SimpleText
                    className="p-col-2"
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Data de nascimento
                  </SimpleText>
                )}
                <SimpleText
                  className="p-col-4 p-sm-2"
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  Parentesco
                </SimpleText>
                <SimpleText
                  className="p-col-2 p-sm-2 p-text-center"
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  Ações
                </SimpleText>
              </div>
            )}

            <div className="familiares-content">
              {!!fields.length && !loading ? (
                fields.map((item: IProps, idx) => (
                  <Accordion key={idx}>
                    <AccordionTab
                      headerClassName="acc-header-dialog-familiares"
                      header={
                        <AccordionHeader
                          item={item}
                          idx={idx}
                          handleRemove={() => remove(idx)}
                          handleEdit={dialogCreate.open}
                        />
                      }
                    >
                      <AccordionContent item={item} />
                    </AccordionTab>
                  </Accordion>
                ))
              ) : (
                <div className="p-d-flex p-flex-1 p-my-auto p-p-4 p-ai-center p-jc-center">
                  {loading ? (
                    <SpinnerLoading full />
                  ) : (
                    <SimpleText
                      style={{ lineHeight: '20px' }}
                      className="p-text-center"
                    >
                      Os familiares adicionados serão exibidos aqui. Para
                      adicionar, pesquise o paciente e clique em “+”. Você
                      também pode adicionar um paciente externo ao sistema
                      utilizando o botāo “+ adicionar”.
                    </SimpleText>
                  )}
                </div>
              )}
            </div>
          </div>

          <AlertBox
            className="p-col-12"
            visible={Object.keys(formList.formState.errors).length >= 1}
            text="Edite o familiar adicionado para selecionar um grau de parentesco"
          />

          <footer className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              type="button"
              onClick={() => close()}
              stretch
              btnType="ghost"
              disabled={formList.formState.isSubmitting}
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              disabled={!formList.getValues()?.pacientes.length}
              loading={formList.formState.isSubmitting}
            />
          </footer>

          {dialogCreate.isOpen && (
            <ModalAdicionarFamiliaresCreate
              handleAdd={handleAdd}
              handleEdit={handleEdit}
              {...dialogCreate}
            />
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};

const AccordionHeader = ({
  handleRemove,
  handleEdit,
  item,
  idx,
}: AccordionHeaderProps) => {
  const { isMobile } = useSize();
  const tieredMenuOptions = useRef<TieredMenu>(null);

  const options = [
    {
      label: 'Editar',
      command: () => [
        handleEdit({ state: { ...item, idx } }),
        tieredMenuOptions.current?.setState({ visible: false }),
      ],
    },
    {
      label: 'Excluir',
      command: () => [
        handleRemove(),
        tieredMenuOptions.current?.setState({ visible: false }),
      ],
      className: 'text-error',
    },
  ];

  return (
    <div className="row-content p-w-100 p-d-flex p-ai-center">
      <div className="p-col-6 p-sm-4 p-d-flex p-gap-2 p-ai-center">
        <Avatar label={item.nome} />

        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText className="p-text-climp-2">
            {item.nome}
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
              className="p-ml-2"
            >
              {item.plano?.nomeFantasia || item.plano?.razaoSocial}
            </SimpleText>
          </SimpleText>

          <div className="p-d-flex p-gap-1">
            {item.cids.map((item: any, idx: number, arr: any[]) => {
              if (idx > 3) return;
              if (idx === 3 && arr.length > 3) {
                return (
                  <SimpleText
                    key={item.codigo}
                    className="tag tag-grey"
                    id={item.codigo}
                    data-pr-tooltip={item.nome}
                    data-pr-position="top"
                  >
                    +{arr.length - 3}
                  </SimpleText>
                );
              }

              return (
                <React.Fragment key={item.codigo}>
                  <Tooltip target={`#${item.codigo}`} />
                  <SimpleText
                    className="tag"
                    id={item.codigo}
                    data-pr-tooltip={item.nome}
                    data-pr-position="top"
                  >
                    {item.codigo}
                  </SimpleText>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>

      {!isMobile && (
        <SimpleText className="p-col-2">
          {capitalizeFirstLetter(item.sexo?.descricao || '')}
        </SimpleText>
      )}
      {!isMobile && (
        <SimpleText className="p-col-2">
          {item.dataNascimento
            ? dayjs(item.dataNascimento).format('DD/MM/YYYY')
            : '-'}
        </SimpleText>
      )}

      <SimpleText className="p-col-4 p-sm-2">
        {item.descricaoVinculoFamiliar || '-'}
      </SimpleText>

      <div className="p-col-1 p-sm-2 p-d-flex p-jc-center">
        <Button
          type="button"
          onClick={e => [
            preventDefaultAndStopPropagation(e),
            tieredMenuOptions.current?.toggle(e),
          ]}
          btnType="gray"
          icon="fa-solid fa-ellipsis"
        />

        <TieredMenu model={options} popup ref={tieredMenuOptions} />
      </div>
    </div>
  );
};

const AccordionContent = ({ item }: Pick<AccordionHeaderProps, 'item'>) => {
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector((state: RootState) => state);

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      {item?.dataAtualizacao && (
        <div className="row">
          <SimpleText>
            Alterado por <b>{item.usuarioAtualizacao?.nome || '-'}</b> em{' '}
            <b>
              {dayjs(item?.dataAtualizacao).format('DD/MM/YYYY [às] HH:mm')}
            </b>
          </SimpleText>
        </div>
      )}
      <div className="row">
        <SimpleText>
          Incluido por{' '}
          <b>{item?.usuarioInclusao?.nome || profissionalAtivo?.nome || '-'}</b>{' '}
          em <b>{dayjs(item?.dataInclusao).format('DD/MM/YYYY [às] HH:mm')}</b>
        </SimpleText>
      </div>
    </div>
  );
};
