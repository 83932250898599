import {
  GetProfissionalDTO,
  ProfissionalPorTipo,
} from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/usuarios/profissionais-consultorio';

export const loadProfissionais = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetProfissionalDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    hideToast: true,
    ...options,
  });
};

export const loadProfissionaisByConsultorioId = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetProfissionalDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    hideToast: true,
    ...options,
  });
};

export const loadProfissionaisBySearch = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetProfissionalDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadProfissionaisSaudeBySearch = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: 'profissional-saude/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadProfissionalSaudeByTipo = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ProfissionalPorTipo[]> => {
  return Http.get({
    service: 'adm',
    url: 'profissional-saude/por-tipo',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadProfissionalSaudeByConsultorio = (
  params: {
    idConsultorio?: number | string;
    profissional?: string;
    status?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'adm',
    url: `profissional-saude/por-consultorio/${params.idConsultorio}`,
    params,
    hideToast: true,
    ...options,
  });
};
export const loadProfissionalSaudeEquipePorConsultorio = (
  params: {
    profissional: string;
    idConsultorio: number | undefined;
    idPaciente: string | number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: `profissional-saude/consultorio/${params.idConsultorio}/equipe`,
    hideToast: true,
    params,
    ...options,
  });
};

const ProfissionalAPI = {
  loadProfissionaisByConsultorioId,
  loadProfissionaisBySearch,
  loadProfissionais,
  loadProfissionaisSaudeBySearch,
  loadProfissionalSaudeByTipo,
  loadProfissionalSaudeByConsultorio,
  loadProfissionalSaudeEquipePorConsultorio,
};

export default ProfissionalAPI;
