import { useState, useCallback, useEffect } from 'react';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

import { AdditionalInfoStepData } from 'src/core/containers/ManterConvenio/ManterConvenioProvider';
import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';


export interface LogoUploadData {
  foto: any;
  loading: boolean;
  setFoto: (foto: any) => void;
}

export const useLogoUpload = (): LogoUploadData => {
  const { idConvenioEdit, setAdditionalInfoStepData } = useManterConvenio();

  const [loading, setLoading] = useState(false);
  const [foto, setFoto] = useState<any>();

  const saveImagePaciente = async (idConvenio: number, foto: string) => {
    const payload = {
      logotipo: foto,
    };

    try {
      const response = await ConvenioAPI.updateConvenioLogo(
        payload,
        idConvenio,
      );

      setAdditionalInfoStepData(prevState => ({
        exibirRelatorios: false,
        exibirSistema: false,
        exibirCuidadorViverBemFolhaRosto:
          !!prevState?.exibirCuidadorViverBemFolhaRosto,
        ...prevState,
        urlLogotipo: response.urlLogotipo,
      }));

      setFoto(null);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = useCallback(async (idConvenio: number, file: File) => {
    const dataFile: FileUpload = {
      fileName: file?.name,
      contentType: file?.type,
      contentLength: file?.size,
      resourceType: 'OUTROS',
      resourceId: 1,
    };

    setLoading(true);

    const responseGoogleStorage: any = await FileUploadAPI.sendImageComunicado(
      dataFile,
    );

    if (responseGoogleStorage.status !== 200) return setLoading(false);

    const res = await FileUploadAPI.updateFileUpload(
      responseGoogleStorage.data.uploadUrl,
      file,
    );

    if (res?.status === 200)
      return saveImagePaciente(idConvenio, responseGoogleStorage.data.filePath);
  }, []);

  useEffect(() => {
    if (foto?.path && idConvenioEdit) uploadImage(idConvenioEdit, foto);
  }, [foto, idConvenioEdit, uploadImage]);

  return {
    foto,
    setFoto,
    loading,
  };
};
