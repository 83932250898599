import { useState } from 'react';

import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import { useTelemedicina } from 'src/core/hooks/Telemedicina/useTelemedicina';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './Feedback.scss';

export const Feedback = ({ isOpen, close }: DisclosureType) => {
  const { atendimentoData } = useTelemedicina();
  const [selectedEmoji, setSelectedEmoji] = useState<number | null>(null);

  const isPesquisaEMED =
    atendimentoData?.linkPesquisaSatisfacao === 'EMED' || true;

  const handleEnviar = () => {
    if (!atendimentoData?.idAtendimento) return close();

    return TelemedicinaSalaAtendimentoAPI.sendFeedbackTelemedicina({
      idAtendimento: atendimentoData.idAtendimento,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      nota: selectedEmoji!,
    }).then(() => close());
  };

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      closable={false}
      modal={false}
      className="dialog-feedback"
      position="bottom-right"
      showHeader={false}
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.SM}>
          Como foi seu atendimento?
        </SimpleText>

        {isPesquisaEMED ? (
          <>
            <div className="emoji-area p-d-flex p-gap-2">
              <div
                className={`emoji-container ${
                  selectedEmoji === 1 ? 'selected' : ''
                }`}
                onClick={() => setSelectedEmoji(1)}
              >
                😣
              </div>
              <div
                className={`emoji-container ${
                  selectedEmoji === 2 ? 'selected' : ''
                }`}
                onClick={() => setSelectedEmoji(2)}
              >
                ☹️
              </div>
              <div
                className={`emoji-container ${
                  selectedEmoji === 3 ? 'selected' : ''
                }`}
                onClick={() => setSelectedEmoji(3)}
              >
                😐
              </div>
              <div
                className={`emoji-container ${
                  selectedEmoji === 4 ? 'selected' : ''
                }`}
                onClick={() => setSelectedEmoji(4)}
              >
                😊
              </div>
              <div
                className={`emoji-container ${
                  selectedEmoji === 5 ? 'selected' : ''
                }`}
                onClick={() => setSelectedEmoji(5)}
              >
                🥰
              </div>
            </div>

            <Divider layout="horizontal" />

            <div className="p-grid p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
              <Button
                label="Fechar"
                type="button"
                btnType="green-link"
                onClick={() => close()}
                stretch
              />
              <Button
                label="Enviar"
                type="button"
                onClick={() => handleEnviar()}
                disabled={!selectedEmoji}
                stretch
              />
            </div>
          </>
        ) : (
          // TODO: ADD LINK
          <FeedbackLink link={''} close={close} />
        )}
      </div>
    </Dialog>
  );
};

const FeedbackLink = ({ link, close }: { link: string; close: () => void }) => {
  return (
    <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-gap-3 p-p-3">
      <a className="p-text-center" target="_blank" href={link} rel="noreferrer">
        <SimpleText className="p-text-center" fontSize={FONT_SIZE.MD} bold>
          Clique{' '}
          <SimpleText
            fontColor={FONT_COLOR.PRIMARY}
            fontSize={FONT_SIZE.MD}
            bold
          >
            aqui
          </SimpleText>{' '}
          e responda uma pesquisa de satisfação.
        </SimpleText>
      </a>

      <SimpleText className="p-text-center" fontSize={FONT_SIZE.SM}>
        É rapidinho e ajuda-nos a melhorar o nosso atendimento!
      </SimpleText>

      <div className="p-w-100 p-d-flex p-jc-end">
        <Button btnType="green-link" label="Fechar" onClick={close} />
      </div>
    </div>
  );
};
