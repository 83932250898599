import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { saveTemplate } from './utils/saveTemplateEmail';
import { useTemplateEmail } from './utils/useTemplateEmail';

import { Button } from 'src/components/_UI/Button';
import Badge from 'src/components/Badge/Badge';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import { EditorControlled } from 'src/components/Basics/Editor/EditorControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import UserHeader from 'src/pages/Emed/Agenda/UserHeader/UserHeader';

import { EditorHeader } from './EditorHeader';
import { validationSchema } from './validationSchema';

export interface FormValues {
  conteudo: string;
  horario: Date;
  periodicidade: string | null;
}

function EmailAniversario() {
  const { user, agenda } = useAppSelector((state: RootState) => state);

  const useFormMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      conteudo:
        '{{nome}} - Nome do paciente; {{nome_social}} - Apelido do paciente; {{data_nasc}} - Data de nascimento do paciente; {{idade}} - Idade do paciente; {{nome_profissional}} - Nome do profissional selecionado',
      periodicidade: null,
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const { getTemplateEmail, template } = useTemplateEmail({ setValue });

  const onSubmit = (data: FormValues) => {
    saveTemplate(
      data,
      agenda?.profissionalAtivo?.id,
      getTemplateEmail,
      template?.id,
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <form className="p-grid p-w-100">
        <SimpleText
          className="header-title p-col-12"
          fontSize={FONT_SIZE.MD}
          medium
        >
          Template e-mail de aniversário
        </SimpleText>

        <SimpleText className="p-mt-2 p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          Escreva uma mensagem padrão para ser enviada no aniversário do
          paciente
        </SimpleText>

        <SimpleText className="p-col-12 p-text-bold p-mt-3">
          {user.tipoProfissionalSaude === 'MEDICO'
            ? 'Usuário selecionado'
            : 'Selecione o médico'}
        </SimpleText>

        <div className="user p-col-12">
          <UserHeader />
        </div>

        <SimpleText className="header-subtitle p-col-12 p-text-bold p-mt-3">
          Defina a periodicidade do email
        </SimpleText>

        <SimpleText
          className="header-subtitle p-col-12"
          fontColor={FONT_COLOR.COLOR_40}
        >
          Seu email será enviado para todos os aniversariantes do:
        </SimpleText>

        <div className="periodicidade p-grid p-col-12">
          <div className="p-col-12 p-lg-6 p-p-1">
            <RadioButtonControlled
              control={control}
              label="Dia (diário)"
              name="periodicidade"
              errorMsg={errors.periodicidade?.message}
              className="w-100"
              value="diario"
            />
          </div>
          <div className="p-col-12 p-lg-6 p-p-1">
            <RadioButtonControlled
              control={control}
              label="Mês (mensal)"
              name="periodicidade"
              errorMsg={errors.periodicidade?.message}
              className="w-100"
              value="mensal"
            />
          </div>
        </div>

        <div className="p-mb-2 p-col-12 p-lg-6">
          <CalendarInputControlled
            control={control}
            label="Hora do disparo do e-mail"
            placeholder="HH:mm"
            name="horario"
            errorMsg={errors.horario?.message}
            icon={'fas fa-clock'}
            showIcon
            timeOnly
          />
        </div>

        <div className="p-my-2 p-col-12">
          <Tooltip
            target=".p-badge"
            position="top"
            content="
            {{nome}} - Nome do paciente 
            {{nome_social}} - Apelido do paciente
            {{data_nasc}} - Data de nascimento do paciente
            {{idade}} - Idade do paciente
            {{nome_profissional}} - Nome do profissional selecionado
            "
          />
          <Badge className="p-mr-2" value="i" />
          <SimpleText
            className="header-subtitle p-mt-2"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            E-mail para o paciente:
          </SimpleText>
        </div>

        <div className="p-mb-3 p-col-12">
          <EditorControlled
            name="conteudo"
            headerTemplate={<EditorHeader />}
            style={{ height: '320px' }}
          />
        </div>

        <Button
          type="submit"
          label="Salvar"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          checkPermission={[
            'ADM_TEMPLATE_ANIVERSARIO_CADASTRAR',
            'ADM_TEMPLATE_ANIVERSARIO_ALTERAR',
          ]}
        />
      </form>
    </FormProvider>
  );
}

export default EmailAniversario;
