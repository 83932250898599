import { Control, Controller, useController } from 'react-hook-form';

import Dropdown, { DropdownProps } from '../Dropdown/Dropdown';

export interface DropdownControlledProps extends DropdownProps {
  control: Control<any>;
  name: string;
  customOnChange?: (value: any) => void;
}

const DropdownControlled = ({
  name,
  control,
  customOnChange,
  ...rest
}: DropdownControlledProps) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <Dropdown
      {...rest}
      name={name}
      value={value}
      errorMsg={error?.message}
      onChange={e => {
        if (e.target?.value === undefined) e.target.value = null;
        onChange(e);
        if (customOnChange) customOnChange(e.value);
      }}
    />
  );
};

export default DropdownControlled;
