import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSubscription } from 'react-stomp-hooks';

import NotificacaoAPI from 'src/APIs/NotificacaoAPI/NotificacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { NotificationsList } from 'src/pages/Emed/LoggedRoot/MainMenu/MainMenuNotification/MainMenuNotification';

export type NotificationPortalProps = {
  notifications: NotificationsList[];
  comunicados: NotificationsList[];
  unreadComunicados: NotificationsList[];
  readComunicados: NotificationsList[];
  unreadNotifications: NotificationsList[];
  readNotifications: NotificationsList[];
  isLoading: boolean;
  qtdeNotificacoesUnread: number;
};

export const useNotifications = (): NotificationPortalProps => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [comunicados, setComunicados] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { query } = useAppSelector(state => state);

  const { invalidateNotificacoes } = query;

  const dispatch = useDispatch();

  const unreadNotifications = useRef<any[]>([]);
  const readNotifications = useRef<any[]>([]);
  const unreadComunicados = useRef<any[]>([]);
  const readComunicados = useRef<any[]>([]);

  const carregarListaDeNotificacoesSistema = useCallback(async () => {
    setIsLoading(true);
    NotificacaoAPI.buscarNotificacoes(
      'sistema',
      {
        pageSize: 100,
      },
      {
        errorHideToast: true,
      },
    )
      .then(response => {
        !!response.list?.length && setNotifications(response.list);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const carregarListaDeNotificacoesComunicados = useCallback(async () => {
    setIsLoading(true);
    NotificacaoAPI.buscarNotificacoes(
      'comunicados',
      {
        pageSize: 100,
      },
      {
        errorHideToast: true,
      },
    )
      .then(response => {
        !!response.list?.length && setComunicados(response.list);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffectSkipFirst(() => {
    unreadNotifications.current = notifications.filter((res: any) => !res.lida);
    readNotifications.current = notifications.filter((res: any) => !!res.lida);
    unreadComunicados.current = comunicados.filter((res: any) => !res.lida);
    readComunicados.current = comunicados.filter((res: any) => !!res.lida);
  }, [notifications, comunicados]);

  useEffect(() => {
    carregarListaDeNotificacoesSistema();
    carregarListaDeNotificacoesComunicados();
  }, [
    carregarListaDeNotificacoesComunicados,
    carregarListaDeNotificacoesSistema,
  ]);

  useEffect(() => {
    if (invalidateNotificacoes) {
      carregarListaDeNotificacoesSistema();
      carregarListaDeNotificacoesComunicados();
      dispatch(setInvalidateQuery({ invalidateNotificacoes: false }));
    }
  }, [
    invalidateNotificacoes,
    carregarListaDeNotificacoesSistema,
    carregarListaDeNotificacoesComunicados,
    dispatch,
  ]);

  useSubscription('/topic/messages', (message: any) => {
    const newNotification = JSON.parse(message.body).message;

    setNotifications(currentNotifications => [
      newNotification,
      ...currentNotifications,
    ]);
  });

  useSubscription('/user/topic/notificacao', (message: any) => {
    const newNotification = JSON.parse(message.body);

    if (!!newNotification.titulo) {
      setNotifications(currentNotifications => [
        newNotification,
        ...currentNotifications,
      ]);
    }
  });

  const qtdeNotificacoesUnread =
    unreadComunicados.current.length + unreadNotifications.current.length;

  return {
    notifications,
    comunicados,
    unreadNotifications: unreadNotifications.current,
    readNotifications: readNotifications.current,
    unreadComunicados: unreadComunicados.current,
    readComunicados: readComunicados.current,
    isLoading,
    qtdeNotificacoesUnread,
  };
};
