import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  impresso: any;
  onInativar: (impresso: any) => void;
  onVisualizarInativacao: (impresso: any) => void;
}

export default function MobileRow({
  impresso,
  onInativar,
  onVisualizarInativacao,
}: MobileRowProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  function getPContent(str: string) {
    const parser = new DOMParser();
    const html = parser.parseFromString(str, 'text/html');
    const pTag = html.querySelector('p');
    return pTag?.textContent;
  }
  const options =
    impresso.status === 'INATIVO'
      ? [
          {
            label: 'Visualizar inativação',
            command: () => onVisualizarInativacao(impresso),
          },
        ]
      : [
          {
            label: 'Inativar',
            command: () => onInativar(impresso),
          },
        ];

  const headerTextClassName =
    impresso.status === 'INATIVO' ? 'impresso-inativo' : '';

  const dataTextClassName = `p-mt-2 ${
    impresso.status === 'INATIVO' ? 'line-through impresso-inativo' : ''
  }`;

  return (
    <SearchListMobileRow
      options={options}
      disabledOptions={
        impresso.idUsuarioAtualizacao !== agenda?.profissionalAtivo?.id
      }
    >
      <div className="column ">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {impresso.usuario.nome}
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Tipo documento:{' '}
          <b>{impresso.tipoImpresso.descricao || 'Não informado'}</b>
        </SimpleText>
      </div>

      <div className="column p-mt-3">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de Ocorrência
        </SimpleText>

        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {dayjs(impresso.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="column p-mt-3">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Descrição:
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {impresso?.descricao
            ? getPContent(impresso?.descricao)
            : 'Não informado'}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
}
