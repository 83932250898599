import React from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

interface CamposRowProps {
  item: any;
  handleRemove: any;
  handleEdit: any;
}

const CamposRow = ({ item, handleRemove, handleEdit }: CamposRowProps) => {
  return (
    <div className="p-my-1">
      <Panel
        className="p-grid row-component p-align-center"
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            item={item}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
          />
        )}
      >
        <PanelContent item={item} />
      </Panel>
    </div>
  );
};

export default CamposRow;
