import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import Panel, { PanelProps } from 'src/components/Panel/Panel';

import './Panel.scss';

export interface ProntuarioPanelRef {
  collapse(): void;
  show(): void;
}

type ProntuarioPanelHeaderType = (
  isCollapsed: boolean,
  onToggle: () => void,
) => JSX.Element;

interface ProntuarioPanelProps
  extends Omit<PanelProps, 'toggleable' | 'collapsed' | 'toggleable'> {
  title?: string;
  subTitle?: string;
  children: JSX.Element;
  disabled?: boolean;
  header: ProntuarioPanelHeaderType;
  defaultExpanded?: boolean;
}
const ProntuarioPanel = (
  {
    title,
    subTitle,
    disabled,
    header,
    defaultExpanded,
    ...rest
  }: ProntuarioPanelProps,
  ref: Ref<ProntuarioPanelRef> | undefined,
) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultExpanded || false);

  useImperativeHandle(ref, () => ({
    collapse: () => {
      setIsOpen(false);
    },
    show: () => {
      setIsOpen(true);
    },
  }));

  const headerTemplate = header(isOpen, () => setIsOpen(isOpen => !isOpen));

  return (
    <Panel
      {...rest}
      className="prontuario-panel"
      toggleable
      collapsed={!isOpen}
      onToggle={e => {
        setIsOpen(e.value);
      }}
      headerTemplate={
        <div
          className={`prontuario-panel-header ${
            isOpen ? 'prontuario-panel-header-active' : ''
          }`}
        >
          {headerTemplate}
        </div>
      }
    />
  );
};

export default forwardRef(ProntuarioPanel);
