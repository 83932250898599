import { useCallback, useState } from 'react';

import { useLocation } from 'react-router';

import AvaliacaoRiscoAPI from 'src/APIs/ProntuarioAPI/AvaliacaoRisco/AvaliacaoRiscoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import PanelOptionsMenu from '../../components/PanelOptionsMenu/PanelOptionsMenu';
import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { DialogDelete } from 'src/components/DialogDelete/DialogDelete';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import ModalNovaAvaliacaoRisco from './ModalNovaAvaliacaoRisco/ModalNovaAvaliacaoRisco';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './AvaliacaoRisco.scss';

interface AvaliacaoRiscoProps {
  paciente: any | null;
}

export default function AvaliacaoRisco({ paciente }: AvaliacaoRiscoProps) {
  const { isMobile } = useSize();

  const [updateList, setUpdateList] = useState<number>(0);
  const { atendimentoStatus } = useAtendimento();

  const [avalRiscoInativacao, setAvalRiscoInativacao] =
    useState<DataCalculoRisco | null>(null);

  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<boolean>(false);

  const [editAvaliacao, setEditAvaliacao] = useState<DataCalculoRisco | null>(
    null,
  );

  const modalCalcularRiscoDisclosure = useDisclosure({ opened: false });
  const dialogDeleteAvaliacao = useDisclosure({ opened: false });

  const { state } = useLocation();
  const { getInfoLembretes } = useAtendimento();

  const idAtendimento = state?.idAtendimento;

  const fetchApi = useCallback(
    async (params: any) => {
      const response = await AvaliacaoRiscoAPI.listAvaliacaoRisco(
        idAtendimento,
        { ...params, sortBy: 'dataOcorrencia', sortDirection: 'DESC' },
      );

      return response;
    },
    [idAtendimento],
  );

  const reloadList = useCallback(() => {
    modalCalcularRiscoDisclosure.close();
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(paciente.id);
  }, [getInfoLembretes, modalCalcularRiscoDisclosure, paciente.id]);

  const onVisualizarJustificativa = (avaliacao: DataCalculoRisco) => {
    setAvalRiscoInativacao(avaliacao);
    setVisualizarJustificativa(true);
  };

  const onEdit = (avaliacao: DataCalculoRisco) => {
    setEditAvaliacao(avaliacao);
    modalCalcularRiscoDisclosure.open();
  };

  const handleDelete = async (avaliacao: DataCalculoRisco) => {
    try {
      await AvaliacaoRiscoAPI.deletarAvaliacaoRisco(
        avaliacao.id!,
        avaliacao.idAtendimento,
      );

      reloadList();
    } catch {}
  };

  const onAlterarLembrete = async (idAvaliacaoRisco: number) => {
    const response = await AvaliacaoRiscoAPI.putLembreteAvaliacaoRisco(
      idAvaliacaoRisco,
      { return: true },
    );
    if (response.status >= 200 && response.status < 300) {
      reloadList();
    }
  };

  const optionsMenu = (avaliacao: any) => (
    <PanelOptionsMenu
      hideEditOption={avaliacao.idAtendimento !== Number(idAtendimento)}
      ativo={avaliacao.status === 'ATIVO'}
      idAtendimentoRegistro={avaliacao.idAtendimento}
      idUsuarioCriacao={avaliacao.idUsuarioAtualizacao}
      onInativar={() => setAvalRiscoInativacao(avaliacao)}
      onVisualizarJustificativa={() => onVisualizarJustificativa(avaliacao)}
      onEdit={() => onEdit(avaliacao)}
      onExcluir={() =>
        dialogDeleteAvaliacao.open({
          state: avaliacao,
        })
      }
      onAlterarLembrete={() => {
        onAlterarLembrete(avaliacao.id);
      }}
      lembreteAtivo={avaliacao.lembrete}
    />
  );

  const renderRow = (avaliacao: any) => (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            avaliacao={avaliacao}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            optionsMenu={optionsMenu(avaliacao)}
          />
        )}
      >
        <PanelContent avaliacao={avaliacao} />
      </Panel>
    </div>
  );

  return (
    <div id="avaliacao-risco-container">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Avaliação de risco
            </SimpleText>

            <Button
              className="p-mr-4"
              type="button"
              btnType="tonal"
              label={!isMobile ? 'Calcular risco cárdico' : 'Calcular'}
              title="Calcular risco cárdico"
              icon="fas fa-clipboard-user"
              onClick={() => modalCalcularRiscoDisclosure.open()}
              disabled={
                atendimentoStatus !== 'ATENDENDO' &&
                atendimentoStatus !== 'ATENDENDO_TRIAGEM'
              }
            />
          </div>

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {modalCalcularRiscoDisclosure.isOpen && (
        <ModalNovaAvaliacaoRisco
          {...modalCalcularRiscoDisclosure}
          editAvaliacao={editAvaliacao}
          setEditAvaliacao={setEditAvaliacao}
          onSuccess={reloadList}
          paciente={paciente}
        />
      )}

      {dialogDeleteAvaliacao && (
        <DialogDelete
          title="Excluir avaliação de risco"
          subtitle="Os dados dessa avaliação de risco serão perdidos. Você realmente deseja excluir?"
          onConfirm={() => handleDelete(dialogDeleteAvaliacao.state)}
          {...dialogDeleteAvaliacao}
        />
      )}

      <ModalJustificativa
        avaliacaoRisco={avalRiscoInativacao}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setAvalRiscoInativacao(null);
          setVisualizarJustificativa(false);
        }}
        onSuccess={reloadList}
      />
    </div>
  );
}
