import { memo, useCallback, useMemo } from 'react';

import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './EspecialidadeServicoTussSelected.scss';

interface EspecialidadeServicoTussSelectedProps {
  value: Especialidade[] | undefined;
  onChange(i: Especialidade[]): void;
}

interface SelectedList {
  profissionalSaude: Especialidade[];
}

const EspecialidadeServicoTussSelected = (
  props: EspecialidadeServicoTussSelectedProps,
) => {
  const { value = [], onChange } = props;

  const list: SelectedList = useMemo(() => {
    return value.reduce(
      (value: SelectedList, item) => {
        value.profissionalSaude.push(item);

        return value;
      },
      { profissionalSaude: [] },
    );
  }, [value]);

  const handleRemove = useCallback(
    (item: Especialidade) => {
      const items = value.filter(i => i.id !== item.id);
      onChange(items);
    },
    [onChange, value],
  );

  return (
    <div
      className="EspecialidadeServicoTussSelected"
      style={{ marginBottom: 0 }}
    >
      <div style={{ marginBottom: 16 }}>
        <div>
          {list.profissionalSaude.length > 0 ? (
            <>
              {list.profissionalSaude?.map(item => (
                <EspecialidadeServicoTussSelectedItem
                  key={`${item.id}`}
                  item={item}
                  onClick={handleRemove}
                />
              ))}
            </>
          ) : (
            <EmptyText />
          )}
        </div>
      </div>
    </div>
  );
};

const EmptyText = memo(() => {
  return (
    <SimpleText
      fontSize={FONT_SIZE.XXXS}
      fontColor={FONT_COLOR.COLOR_60}
      className="search-list-empty"
    >
      Não há cadastros.
    </SimpleText>
  );
});

const EspecialidadeServicoTussSelectedItem = memo(
  (props: EspecialidadeServicoTussSelectedItemProps) => {
    const { item } = props;

    return (
      <div className="EspecialidadeServicoTussSelectedItem">
        <SimpleText
          className="item-title"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {item.nome || item.especialidade.nome}
        </SimpleText>
      </div>
    );
  },
);

interface EspecialidadeServicoTussSelectedItemProps {
  item: Especialidade;
  onClick(v: Especialidade): void;
}

export default memo(EspecialidadeServicoTussSelected);
