import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from '../_UI/Button';
import SimpleText from '../Basics/SimpleText/SimpleText';
import Dialog from '../Dialog/Dialog';

type IProps = DisclosureType & {
  onConfirm: () => void;
  title?: string;
  subtitle: string;
  modal?: boolean;
  className?: string;
};

export const DialogDelete = ({
  onConfirm,
  title,
  subtitle,
  isOpen,
  close,
  modal = true,
  className = '',
}: IProps) => {
  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header={title || 'Tem certeza que deseja deletar este item?'}
      modal={modal}
      closable={false}
      className={className}
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <SimpleText>{subtitle}</SimpleText>

        <div className="p-d-flex p-gap-2">
          <Button
            label="Cancelar"
            onClick={() => close()}
            type="button"
            btnType="ghost"
            stretch
          />
          <Button
            label="Confirmar"
            onClick={() => [onConfirm(), close()]}
            type="button"
            btnType="danger"
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};
