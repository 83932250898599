import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface MobileRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onSwitchStatus: (data: any) => void;
}

export const MobileRow = ({
  data,
  onEdit,
  onDelete,
  onSwitchStatus,
}: MobileRowProps) => {
  const options = [
    {
      label: 'Editar',
      command: () => onEdit(data),
      checkPermission: 'ADM_GRUPO_TUSS_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(data),
      checkPermission: 'ADM_GRUPO_TUSS_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Convênio
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.convenio?.nomeFantasia || data?.convenio?.razaoSocial || '-'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Status
        </SimpleText>
        <br />
        <Can checkPermission="ADM_GRUPO_TUSS_ALTERAR">
          {can => (
            <Switch
              checked={data?.status}
              onChange={() => onSwitchStatus(data)}
              disabled={!can}
              showStatus
            />
          )}
        </Can>
      </div>
    </SearchListMobileRow>
  );
};
