import { useState } from 'react';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';

import { CheckboxSearch } from './CheckboxSearch/CheckboxSearch';

export const ModalEspecialidade = ({ isOpen, close, onAdd, state }: any) => {
  const [especialidadesSelected, setEspecialidadesSelected] = useState<any[]>(
    [],
  );

  const fetchEspecialidades = async ({ query, ...params }: any) =>
    EspecialidadeAPI.loadEspecialidades({
      nome: query,
      pageSize: 1000,
      ...params,
    });

  const formatEspecialidadesOptions = (
    especialidades: Paginator<Especialidade[]>,
  ) => {
    if (!(Array.isArray(especialidades.list) && especialidades.list.length))
      return [];

    const filteredSelectedEspecialidades = especialidades.list.filter(
      especialidade =>
        !state.some(
          (especialidadeSelected: any) =>
            especialidadeSelected.idEspecialidade === especialidade.id,
        ),
    );

    return filteredSelectedEspecialidades.map((especialidade: any) => ({
      label: especialidade.nome,
      value: {
        idEspecialidade: especialidade.id,
        nome: especialidade.nome,
      },
    }));
  };

  const handleEspecialidadesSelected = (especialidades: any[]) => {
    setEspecialidadesSelected(especialidades);
  };

  const handleAdd = () => {
    onAdd(especialidadesSelected);
    setEspecialidadesSelected([]);
    close();
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={close} stretch />
      <Button label="Adicionar" onClick={handleAdd} stretch />
    </div>
  );

  return (
    <Dialog
      className="modal-especialidade-disponibilidade"
      header="Adicionar especialidades na lista de restrição"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
      footer={renderFooter}
    >
      <CheckboxSearch
        value={especialidadesSelected}
        fetchApi={fetchEspecialidades}
        formatOptions={formatEspecialidadesOptions}
        onChange={handleEspecialidadesSelected}
      />
    </Dialog>
  );
};
