import * as Yup from 'yup';

export const validationSchema = (diasVigencia: number) => {
  return Yup.object({
    atividade: Yup.string().required('Campo obrigatório'),
    recorrencia: Yup.number()
      .required('Campo obrigatório')
      .max(
        diasVigencia,
        'O número de dias não pode ser maior que o período de vigência plano',
      )
      .typeError('Campo obrigatório'),

    especialidade: Yup.object().when('atividade', {
      is: 'CONSULTA',
      then: Yup.object().required('Campo obrigatório'),
    }),
    resumoClinico: Yup.string().when('atividade', {
      is: 'CONSULTA',
      then: Yup.string().required('Campo obrigatório'),
    }),
    hipoteseDiagnostica: Yup.string().when('atividade', {
      is: 'CONSULTA',
      then: Yup.string().required('Campo obrigatório'),
    }),
    questionamento: Yup.string().when('atividade', {
      is: 'CONSULTA',
      then: Yup.string().required('Campo obrigatório'),
    }),

    nomeCuidado: Yup.string().when('atividade', {
      is: 'PEDIDO_CUIDADO',
      then: Yup.string().required('Campo obrigatório'),
    }),
    tipo: Yup.object().when('atividade', {
      is: 'PEDIDO_CUIDADO',
      then: Yup.object().required('Campo obrigatório'),
    }),

    servicoTuss: Yup.object().when('atividade', {
      is: 'EXAMES',
      then: Yup.object().required('Campo obrigatório'),
    }),
    quantidade: Yup.number()
      .when('atividade', {
        is: 'EXAMES',
        then: Yup.number().required('Campo obrigatório'),
      })
      .typeError('Campo obrigatório'),
  });
};
