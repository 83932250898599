import { useNavigate } from 'react-router';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { checkAuth } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import PageHeader from 'src/components/PageHeader/PageHeader';

const cardapioItems = [
  {
    label: 'Lista de Funcionalidades',
    route: '/config/cardapio',
    checkPermission: 'ADM_FUNCIONALIDADE_CONSULTAR',
  },
  {
    label: 'Configurações de cliente',
    route: '/config/cardapio',
    checkPermission: 'ADM_CARDAPIO_CONSULTAR',
  },
  {
    label: 'Perfís de usuário',
    route: '/config/cardapio',
    checkPermission: 'ADM_PERFIL_CONSULTAR',
  },
];

const configGeraisItems = [
  {
    label: 'Tipos de cuidado',
    route: '/config/general',
    checkPermission: 'AGE_TIPO_DE_CUIDADO_CONSULTAR',
  },
  {
    label: 'Tipos de motivos de atendimento',
    route: '/config/general',
    checkPermission: 'AGE_MOTIVO_DE_ATENDIMENTO_CONSULTAR',
  },
  {
    label: 'Motivos de falta',
    route: '/config/general',
    checkPermission: 'AGE_MOTIVO_DE_FALTA_CONSULTAR',
  },
  {
    label: 'Usuários simultâneos',
    route: '/config/general',
    checkPermission: 'ADM_USUARIOS_SIMULTANEOS_CONSULTAR',
  },

  {
    label: 'Lembrete de Agendamento',
    route: '/config/general',
    checkPermission: 'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_CONSULTAR',
  },
];

const personalizacaoProntuarioItems = [
  {
    label: 'Personalização de Prontuário',
    route: '/config/personalizacao-prontuarios',
    checkPermission: 'PRO_MODELO_PRONTUARIO_CONSULTAR',
  },
];

const parametrosItems = [
  {
    label: 'Parâmetros',
    route: '/config/parametros',
    checkPermission: 'ADM_PARAMETROS_CONSULTAR',
  },
];

const configTISSItems = [
  {
    label: 'Configuração TISS',
    route: '/config/config-tiss',
    checkPermission: 'ADM_CONFIGURACAO_TISS_CONSULTAR',
  },
];

const configTUSSItems = [
  {
    label: 'Serviço TUSS',
    route: '/config/config-tuss/servico-tuss',
    checkPermission: 'ADM_SERVICO_TUSS_CONSULTAR',
  },
  {
    label: 'Grupos TUSS',
    route: '/config/config-tuss/grupo-tuss',
    checkPermission: 'ADM_GRUPO_TUSS_CONSULTAR',
  },
  {
    label: 'Fornecedor TUSS',
    route: '/config/config-tuss/fornecedor-tuss',
    checkPermission: 'ADM_FORNECEDOR_TUSS_CONSULTAR',
  },
  {
    label: 'Pacote',
    route: '/config/config-tuss/pacote',
    checkPermission: 'ADM_PACOTES_CONSULTAR',
  },
];

const MenuConfig = () => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();

  function filterMenuItems(items: any[], authorities: string[]) {
    return items?.filter((item: any) => {
      return item?.checkPermission
        ? checkAuth(authorities, item?.checkPermission)
        : true;
    });
  }

  const cardapioItemsFiltered = filterMenuItems(cardapioItems, authorities);

  const configGeraisItemsFiltered = filterMenuItems(
    configGeraisItems,
    authorities,
  );

  const personalizacaoProntuarioItemsFiltered = filterMenuItems(
    personalizacaoProntuarioItems,
    authorities,
  );

  const parametrosItemsFiltered = filterMenuItems(parametrosItems, authorities);

  const configTISSItemsFiltered = filterMenuItems(configTISSItems, authorities);

  const configTUSSItemsFiltered = filterMenuItems(configTUSSItems, authorities);

  return (
    <Page id="menu-config">
      <>
        <PageHeader title="configurações" subtitle="" />
        <div className="p-grid p-gap-3 p-w-100 p-p-3 ">
          <div className="menu-card p-col  p-d-flex p-flex-column p-gap-3  p-p-3">
            <div className="p-d-flex p-gap-3 p-ai-center">
              <Button btnType="gray" icon="fas fa-hospital" />
              <SimpleText fontSize="md">Cardápio</SimpleText>
            </div>
            <SimpleText fontSize="xs">
              Acesse as funcionalidades de cardápio
            </SimpleText>
            <div>
              {cardapioItemsFiltered?.map((item, idx) => (
                <Button
                  className="p-pl-0"
                  key={idx}
                  label={item.label}
                  btnType="green-link"
                  onClick={() => navigate(item.route, { state: idx })}
                />
              ))}
            </div>
          </div>
          <div className="menu-card p-col  p-d-flex p-flex-column p-gap-3 p-p-3">
            <div className="p-d-flex p-gap-3 p-ai-center">
              <Button btnType="gray" icon="fas fa-cog" />
              <SimpleText fontSize="md">Configurações gerais</SimpleText>
            </div>
            <SimpleText fontSize="xs">
              Acesse as funcionalidades de configurações gerais
            </SimpleText>
            <div>
              {configGeraisItemsFiltered?.map((item, idx) => (
                <Button
                  className="p-pl-0"
                  key={idx}
                  label={item.label}
                  btnType="green-link"
                  onClick={() => navigate(item.route, { state: idx })}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="p-grid p-gap-3 p-w-100 p-p-3 ">
          <div className="menu-card p-col  p-d-flex p-flex-column p-gap-3  p-p-3">
            <div className="p-d-flex p-gap-3 p-ai-center">
              <Button
                btnType="gray"
                icon="fas fa-user-gear"
                style={{ minWidth: '32px' }}
              />
              <SimpleText fontSize="md">Personalização prontuário</SimpleText>
            </div>

            <div>
              {personalizacaoProntuarioItemsFiltered?.map((item, idx) => (
                <Button
                  className="p-pl-0"
                  key={idx}
                  label={item.label}
                  btnType="green-link"
                  onClick={() => navigate(item.route)}
                />
              ))}
            </div>
          </div>
          <div className="menu-card p-col  p-d-flex p-flex-column p-gap-3  p-p-3">
            <div className="p-d-flex p-gap-3 p-ai-center">
              <Button
                btnType="gray"
                icon="fas fa-list-ul"
                style={{ minWidth: '32px' }}
              />
              <SimpleText fontSize="md">Parâmetros</SimpleText>
            </div>

            <div>
              {parametrosItemsFiltered?.map((item, idx) => (
                <Button
                  className="p-pl-0"
                  key={idx}
                  label={item.label}
                  btnType="green-link"
                  onClick={() => navigate(item.route)}
                />
              ))}
            </div>
          </div>
          <div className="menu-card p-col  p-d-flex p-flex-column p-gap-3  p-p-3">
            <div className="p-d-flex p-gap-3 p-ai-center">
              <Button
                btnType="gray"
                icon="fas fa-gears"
                style={{ minWidth: '32px' }}
              />
              <SimpleText fontSize="md">Config. TISS</SimpleText>
            </div>

            <div>
              {configTISSItemsFiltered?.map((item, idx) => (
                <Button
                  className="p-pl-0"
                  key={idx}
                  label={item.label}
                  btnType="green-link"
                  onClick={() => navigate(item.route)}
                />
              ))}
            </div>
          </div>
          <div className="menu-card p-col  p-d-flex p-flex-column p-gap-3  p-p-3">
            <div className="p-d-flex p-gap-3 p-ai-center">
              <Button
                btnType="gray"
                icon="fas fa-users-gear"
                style={{ minWidth: '32px' }}
              />
              <SimpleText fontSize="md">Config. TUSS</SimpleText>
            </div>

            <div>
              {configTUSSItemsFiltered?.map((item, idx) => (
                <Button
                  className="p-pl-0"
                  key={idx}
                  label={item.label}
                  btnType="green-link"
                  onClick={() => navigate(item.route)}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    </Page>
  );
};

export default MenuConfig;
