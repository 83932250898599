import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelCoombsContentProps {
  idGestacao: number;
}

export default function PanelCoombsContent({
  idGestacao,
}: PanelCoombsContentProps) {
  const [historicoCoombs, setHistoricoCoombs] = useState<any[]>([]);

  const loadHistoricoCoombs = useCallback(async () => {
    const response = await PreNatalAPI.getHistoricoCoombs(idGestacao);

    if (Array.isArray(response)) {
      setHistoricoCoombs(response);
    } else {
      setHistoricoCoombs([]);
    }
  }, [idGestacao]);

  useEffect(() => {
    loadHistoricoCoombs();
  }, [loadHistoricoCoombs]);

  interface TiposSiglas {
    [key: string]: string;
  }

  const siglaTipoSanguineo = (tipoSanguineo: string) => {
    switch (tipoSanguineo) {
      case 'A_POSITIVO':
        return 'A+';
      case 'A_NEGATIVO':
        return 'A-';
      case 'B_POSITIVO':
        return 'B+';
      case 'B_NEGATIVO':
        return 'B-';
      case 'AB_POSITIVO':
        return 'AB+';
      case 'AB_NEGATIVO':
        return 'AB-';
      case 'O_POSITIVO':
        return 'O+';
      case 'O_NEGATIVO':
        return 'O-';
      default:
        return '';
    }
  };

  return (
    <>
      {historicoCoombs.length > 0 ? (
        <>
          {' '}
          {historicoCoombs.map((coombs, index) => (
            <div
              key={index}
              className="p-d-flex p-ai-center item-historico p-p-2 p-mb-1"
            >
              <div className="p-mr-4">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                  medium
                >
                  {`Data do exame: ${dayjs(new Date(coombs?.dataExame)).format(
                    'DD/MM/YYYY',
                  )}`}
                </SimpleText>
              </div>
              <div className="p-mr-2 chip">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XXS}
                  medium
                >
                  {siglaTipoSanguineo(coombs?.aboRh)}
                </SimpleText>
              </div>
              <div className="p-mr-2 chip">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XXS}
                  medium
                >
                  {capitalizeFirstLetter(coombs?.coombsIndireto)}
                </SimpleText>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="p-d-flex p-ai-center p-jc-center p-p-2">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XXS}
            medium
          >
            Nenhum resultado encontrado
          </SimpleText>
        </div>
      )}
    </>
  );
}
