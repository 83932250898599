import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  tipoGestacao: Yup.string().required('O campo é obrigatório'),
  risco: Yup.string().required('O campo é obrigatório'),
  trimestre: Yup.string().required('O campo é obrigatório'),
  dum: Yup.date().nullable().required('O campo é obrigatório'),
  dpp: Yup.date().nullable().required('O campo é obrigatório'),
  tipoGravidez: Yup.string().required('O campo é obrigatório'),
});

export default validationSchema;
