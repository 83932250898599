import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldStatusFormularioProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldStatusFormulario = ({
  name = 'statusFormulario',
  label,
  ...rest
}: FieldStatusFormularioProps) => {
  const { control } = useFormContext();
  const [tipoperguntas, setTipoPerguntas] = useState<
    IPerguntasFormularioDinamicoTiposResposta[]
  >([]);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async () => {
    try {
      const response = await FormularioDinamicoAPI.loadStatusFormularioDinamico(
        {
          throwError: true,
        },
      );

      setTipoPerguntas(response);
    } catch {
      setTipoPerguntas([]);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const data = tipoperguntas.map(item => ({
      label: item?.descricao,
      value: item,
    }));

    return data;
  }, [tipoperguntas]);

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      control={control}
      name={name}
      dataKey="codigo"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
