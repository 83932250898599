import { FormProvider, useForm } from 'react-hook-form';

import { FieldTipoRespostas } from '../_components/FieldTipoRespostas/FieldTipoRespostas';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

type FormData = {
  convenio?: Convenio;
  tipoRespostas?: IPerguntasFormularioDinamicoTiposResposta;
};

export const FormularioDinamicoAcervoPerguntasFilter = ({
  close,
}: {
  close(): void;
}) => {
  const { setFilter, filter } = useSearchList();

  const form = useForm<FormData>({
    defaultValues: {
      tipoRespostas: {
        codigo: filter?.tipoResposta,
      },
    },
  });

  const onSubmit = (v: FormData) => {
    setFilter({
      tipoResposta: v?.tipoRespostas?.codigo,
    });
    close();
  };

  return (
    <div
      className="p-p-2"
      style={{
        width: '371px',
      }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="">
          <SimpleText fontColor="color_40">
            Pesquise questionários de forma detalhada, preenchendo um ou mais
            dos campos abaixo.
          </SimpleText>

          <div className="p-my-3 p-d-flex p-flex-column p-gap-2">
            <FieldTipoRespostas
              name="tipoRespostas"
              label="Tipo"
              placeholder="Selecione"
              className="p-px-0"
              showClear
            />
          </div>

          <div className="p-d-flex p-gap-2">
            <Button label="Cancelar" btnType="outline" onClick={close} />
            <Button type="submit" label="Pesquisar" stretch />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
