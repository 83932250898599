import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { MotivoTransferenciaAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/MotivoTransferenciaAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';
import './ModalAddMotivo.scss';
interface ModalAddMotivoProps {
  visible: boolean;
  onHide(): void;
  idMotivo: number;
  reload(): void;
}

const ModalAddMotivo = ({
  idMotivo,
  visible,
  reload,
  onHide,
}: ModalAddMotivoProps) => {
  const [motivo, setMotivo] = useState<GetManterTrasferenciaApsDTO>();
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Informe o nome'),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: '',
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const getMotivoById = useCallback(
    async (id: number) => {
      setLoading(true);

      try {
        const response = await MotivoTransferenciaAPI.getMotivoById(id, {
          throwError: true,
        });
        setValue('nome', response.nome);
        setMotivo(response);
      } catch (error) {
        onHide();
      } finally {
        setLoading(false);
      }
    },
    [onHide, setValue],
  );

  useEffect(() => {
    if (idMotivo) {
      getMotivoById(idMotivo);
    }
  }, [getMotivoById, idMotivo]);

  const onSubmit = useCallback(
    async data => {
      const payload = {
        nome: data.nome,
        ...(motivo && motivo.id && { id: motivo.id }),
        status: motivo ? motivo.status : true,
      };

      const saveMotivo = motivo
        ? MotivoTransferenciaAPI.putMotivo
        : MotivoTransferenciaAPI.postMotivo;

      const response = await saveMotivo(payload);

      if (response.id) {
        reload();
        onHide();
      }
    },
    [onHide, motivo, reload],
  );

  const headerTitle = motivo ? 'Editar' : 'Criar';

  return (
    <Dialog
      className="modal-add-motivoTrasferencia"
      type="modal"
      header={`${headerTitle} motivo de transferência`}
      visible={visible}
      onHide={onHide}
      maximizedMobileSize
    >
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ComponentsLabel name="nome" label="Motivo" required />

          <Skeleton loading={loading} height="33px" borderRadius="10px">
            <TextInputControlled
              name="nome"
              maxLength={100}
              maxLengthSpan
              control={control}
              errorMsg={errors.nome?.message}
            />
          </Skeleton>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button btnType="ghost" label="Cancelar" onClick={onHide} stretch />

            <Button
              label="Salvar"
              type="submit"
              loading={isSubmitting}
              disabled={loading}
              stretch
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalAddMotivo;
