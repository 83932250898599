import Http, { HttpParams } from 'src/core/http/Http';

import {
  GetDadosIniciaisEmpresa,
  GetPacienteInfoBasico,
  PostPacienteInfoBasico,
} from './PortalPaciente';

const basePath = '/portal-paciente';

//* Authentication
const getDadosIniciaisEmpresa = async (
  params: { hashEmpresa: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetDadosIniciaisEmpresa> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/empresa/${params.hashEmpresa}`,
    hideToast: true,
    ...options,
  });

const getPacienteInfoBasico = async (
  params: { hashEmpresa: string },
  data: GetPacienteInfoBasico,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/paciente/pesquisa`,
    hideToast: true,
    params: {
      codigoEncriptado: params.hashEmpresa,
      ...data,
    },
    ...options,
  });
};

const postPacienteInfo = async (
  params: { hashEmpresa: string },
  data: PostPacienteInfoBasico,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `${basePath}/paciente`,
    params: {
      codigoEncriptado: params.hashEmpresa,
    },
    data,
    ...options,
  });

const updateNovoPacienteSenha = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `${basePath}/primeiro-acesso/alterarSenha`,
    data,
    ...options,
  });

//* Paciente
const getPacienteUser = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/pacientes/portal-paciente/usuario`,
    hideToast: true,
    ...options,
  });

//* Agendamento
const getProfissionalSaude = async (
  params: {
    idConsultorio?: number | undefined;
    profissional?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `profissional-saude/por-filtro/paginado`,
    hideToast: true,
    params,
    ...options,
  });

const getConsultorios = async (
  params: { nome?: string; idProfissionalSaude?: number | undefined },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'adm',
    url: `consultorios/pesquisa`,
    hideToast: true,
    params,
    ...options,
  });

const getNumeroHorariosProfissional = async (
  params: {
    idMedico: number;
    idConsultorio: number;
    dataInicial: string;
    dataFinal: string;
    somenteHorariosLivres?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'agenda',
    url: `agendas/portal-paciente/${params.idMedico}/consultorio/${params.idConsultorio}/periodo/${params.dataInicial}/${params.dataFinal}`,
    hideToast: true,
    params,
    ...options,
  });

const getHorariosProfissional = async (
  params: {
    idMedico: number;
    idConsultorio: number;
    data: string;
    somenteHorariosLivres?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'agenda',
    url: `agendas/portal-paciente/${params.idMedico}/consultorio/${params.idConsultorio}/dia/${params.data}`,
    hideToast: true,
    params,
    ...options,
  });

const sendPacienteAgendamento = async (
  idAtendimento: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'agenda',
    url: `/agendas/portal-paciente/${idAtendimento}`,
    hideToast: true,
    data,
    ...options,
  });

const getCartaoPaciente = async (
  params: {
    idPaciente: number;
    numeroCartao: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `cartoes-paciente/paciente/${params.idPaciente}/numero-cartao/${params.numeroCartao}`,
    hideToast: true,
    ...options,
  });

const updatePacienteContato = async (
  idPaciente: string,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `pacientes/portal-paciente/${idPaciente}/contato`,
    data,
    ...options,
  });
const updatePacienteEndereco = async (
  idPaciente: string,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `pacientes/portal-paciente/${idPaciente}/endereco-completo`,
    data,
    ...options,
  });

const updatePacienteDadosBasicosDocumentos = async (
  idPaciente: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `/pacientes/portal-paciente/${idPaciente}/dadosPessoaisDocumentos`,
    data,
    ...options,
  });

const loadProfissionaisEquipePorConsultorio = async (
  params: {
    idPaciente: number | string;
    idConsultorio: number;
    profissional?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/profissional-saude/consultorio/${params.idConsultorio}/equipe`,
    params,
    hideToast: true,
    ...options,
  });

const sendAvaliacaoAtendimento = async (
  params: {
    idAtendimento: number;
    nota: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'agenda',
    url: `/avaliacao-atendimento/portal-paciente/avaliar/${params.idAtendimento}/nota/${params.nota}`,
    params,
    ...options,
  });

const getAvaliacaoAtendimentoPendente = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/notificacoes/portal-paciente/notificacao-avaliacao/ultima-nao-respondida`,
    hideToast: true,
    ...options,
  });

export const PortalPacienteAPI = {
  getDadosIniciaisEmpresa,
  getPacienteInfoBasico,
  postPacienteInfo,
  updateNovoPacienteSenha,
  getPacienteUser,
  getProfissionalSaude,
  getConsultorios,
  getNumeroHorariosProfissional,
  getHorariosProfissional,
  sendPacienteAgendamento,
  getCartaoPaciente,
  updatePacienteContato,
  updatePacienteDadosBasicosDocumentos,
  updatePacienteEndereco,
  loadProfissionaisEquipePorConsultorio,
  sendAvaliacaoAtendimento,
  getAvaliacaoAtendimentoPendente,
};
