import React from 'react';

import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  exportacao: any;
}

export default function PanelContent({ exportacao }: Props) {
  return (
    <div className="panel-content">
      <div className="p-col-12 p-d-flex">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Gerado por:
        </SimpleText>
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          &nbsp;{exportacao?.usuario?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Justificativa:
        </SimpleText>
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {exportacao?.justificativaExportacao}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Recibo entregue em:
        </SimpleText>
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {dayjs(exportacao?.dataAtualizacao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
}
