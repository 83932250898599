import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type IProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldParentesco = ({
  name = 'parentesco',
  label,
  ...rest
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [parentesco, setParentesco] = useState<any[]>([]);

  const loadAPI = useCallback(async () => {
    const response = await UtilsAPI.getVinculoFamiliar();

    if (!!response.length) setParentesco(response);
  }, []);

  useEffect(() => {
    loadAPI();
  }, [loadAPI]);

  const options = useMemo(() => {
    const options = parentesco.map(item => ({
      label: item.descricao,
      value: item,
    }));

    return options;
  }, [parentesco]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={options}
      errorMsg={errors[name]?.message}
      {...rest}
    />
  );
};
