import { useEffect, useState, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import GrupoTussAPI from 'src/APIs/AdminAPI/GrupoTussAPI/GrupoTussAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import './ModalAddGrupoTuss.scss';

const formFields = {
  nome: '',
};

interface ModalAddGrupoTussProps {
  visible: boolean;
  onHide(): void;
  grupoTussSelecionado: any;
  setReload: (param: boolean) => void;
}

const ModalAddGrupoTuss = ({
  grupoTussSelecionado,
  visible,
  onHide,
  setReload,
}: ModalAddGrupoTussProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [hasGrupoTussSelecionado, setHasGrupoTussSelecionado] = useState(false);

  const validationSchema = Yup.object().shape({
    convenio: Yup.object().required('Selecione um convênio'),
    nome: Yup.string().required('Digite um nome para o Grupo'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  useEffect(() => {
    if (grupoTussSelecionado) {
      setValue('nome', grupoTussSelecionado?.nome || '');
      setValue('convenio', grupoTussSelecionado?.convenio);
      setHasGrupoTussSelecionado(true);
    } else {
      setHasGrupoTussSelecionado(false);
    }
  }, [grupoTussSelecionado, reset, setValue]);

  const handleCancel = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleGrupoTuss = useCallback(
    async (data: any) => {
      let response: any;

      const grupoTussEditado = {
        ...grupoTussSelecionado,
        nome: data.nome,
        idConvenio: data.convenio.id,
        status: true,
        idUsuario: user?.idUsuario,
        idEmpresa: user?.idEmpresa,
      };

      if (hasGrupoTussSelecionado) {
        response = await GrupoTussAPI.updateGrupoTuss(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          grupoTussSelecionado.id!,
          grupoTussEditado,
        );
      } else {
        response = await GrupoTussAPI.sendGrupoTuss(grupoTussEditado);
      }

      if (response?.status === 200) {
        handleCancel();
        setReload(true);
      }
    },
    [
      grupoTussSelecionado,
      user?.idUsuario,
      user?.idEmpresa,
      hasGrupoTussSelecionado,
      handleCancel,
      setReload,
    ],
  );

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header={
        hasGrupoTussSelecionado ? 'Edição de grupo TUSS' : 'Novo grupo TUSS'
      }
      visible={visible}
      onHide={handleCancel}
      footer={() => <div />}
      maximizedMobileSize
    >
      <FormProvider {...form}>
        <form
          className="modal-body-add-grupo-tuss"
          onSubmit={handleSubmit(handleGrupoTuss)}
          defaultValue={''}
          onReset={() => reset(formFields)}
        >
          {hasGrupoTussSelecionado && (
            <SimpleText
              className="p-mb-2 p-pl-2"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_40}
              medium
            >
              Para acrescentrar um novo utilize o formulário abaixo
            </SimpleText>
          )}

          <FieldConvenio name="convenio" label="Convênio" />

          <TextInputControlled
            control={control}
            name="nome"
            required
            label="Nome do grupo TUSS"
            className="grid p-col-12 p-md-12"
            errorMsg={errors.nome?.message}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Voltar"
              onClick={handleCancel}
              stretch
              loading={isSubmitting}
            />
            <Button
              label={'Salvar'}
              type="submit"
              onClick={handleSubmit(handleGrupoTuss)}
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalAddGrupoTuss;
