import Http, { HttpParams } from 'src/core/http/Http';

export const getCircunferenciaCintura = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `circunferencia-cintura/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesCircunferenciaCintura = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'circunferencia-cintura/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarCircunferenciaCintura = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'circunferencia-cintura',
    data: payload,
    return: true,
    ...options,
  });

export const alterarCircunferenciaCintura = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-cintura/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarCircunferenciaCintura = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-cintura/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerCircunferenciaCintura = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `circunferencia-cintura/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const CircunferenciaCinturaAPI = {
  getCircunferenciaCintura,
  getUnidadesCircunferenciaCintura,
  adicionarCircunferenciaCintura,
  alterarCircunferenciaCintura,
  inativarCircunferenciaCintura,
  removerCircunferenciaCintura,
};

export default CircunferenciaCinturaAPI;
