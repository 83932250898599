import { Dispatch, SetStateAction, useMemo, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import {
  conteudoModalComunicado,
  copiarComunicado,
  EComunicadoStatus,
  editarComunicado,
  statusDoComunicado,
} from '../helpers';

import { IConteudoModal } from './CardComunicado';

interface DesktopRowProps {
  data: ComunicadoDTO;
  setExibirModalComunicado: Dispatch<SetStateAction<boolean>>;
  setConteudoModal: Dispatch<SetStateAction<IConteudoModal>>;
}

export const DesktopRow: React.FC<DesktopRowProps> = ({
  data,
  setExibirModalComunicado,
  setConteudoModal,
}: any) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const comunicadoRef = useRef<OverlayPanel>(null);

  const navigate = useNavigate();

  const itemsWithPermission = useMemo(() => {
    const itemsOptions = [
      {
        label: 'Copiar',
        command: () => copiarComunicado(data, navigate),
        checkPermission: 'ADM_COMUNICADO_CADASTRAR',
      },
      {
        label: 'Editar',
        command: () => editarComunicado(data, navigate),
        checkPermission: 'ADM_COMUNICADO_ALTERAR',
      },
      {
        label: 'Excluir',
        command: () => {
          setConteudoModal(conteudoModalComunicado['excluir']);
          setExibirModalComunicado(true);
        },
        checkPermission: 'ADM_COMUNICADO_EXCLUIR',
        className: 'error',
      },
    ];
    return itemsOptions.filter(
      ({ checkPermission }) =>
        !checkPermission || authorities?.includes(checkPermission),
    );
  }, [authorities, data, navigate, setConteudoModal, setExibirModalComunicado]);

  const statusDoComunicadoAtual = useMemo(
    () => statusDoComunicado(data.ativo, data.dataInicio, data.dataFim),
    [data],
  );

  return (
    <>
      <div className="p-col-2">
        <SimpleText
          className={`status ${statusDoComunicadoAtual}`}
          fontSize={FONT_SIZE.XXS}
        >
          {statusDoComunicadoAtual}
        </SimpleText>
      </div>
      <div className="p-col-4">
        <div>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {data.titulo}
          </SimpleText>
        </div>
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {data.mensagem}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {dayjs(data.dataInicio).format('DD/MM/YYYY')}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText
          className={`data ${data?.dataFim ? '' : 'invalid-date'}`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {data.dataFim ? dayjs(data.dataFim).format('DD/MM/YYYY') : '-'}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-jc-end">
        {statusDoComunicadoAtual === EComunicadoStatus.Ativo ? (
          <Button
            btnType="ghost"
            onClick={() => {
              setConteudoModal(conteudoModalComunicado['inativar']);
              setExibirModalComunicado(true);
            }}
            label="Inativar"
            checkPermission="ADM_COMUNICADO_ALTERAR"
          />
        ) : (
          <>
            {data.ativo && (
              <>
                <Button
                  btnType="gray"
                  onClick={e => {
                    comunicadoRef.current?.toggle(e);
                  }}
                  icon="pi pi-ellipsis-h"
                />
                <TieredMenu
                  model={itemsWithPermission}
                  popup
                  ref={comunicadoRef}
                />
              </>
            )}
            {!data.ativo && (
              <Button
                icon="fas fa-clone"
                btnType="gray"
                onClick={() => copiarComunicado(data, navigate)}
                checkPermission="ADM_COMUNICADO_CADASTRAR"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
