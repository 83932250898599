import { useCallback, useContext, useEffect, useState } from 'react';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import TelemedicinaAPI from 'src/APIs/ConfigAPI/Telemedicina/TelemedicinaAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { stringDateToShow } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Can from 'src/components/Can/Can';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import FormInput from 'src/components/FormInput/FormInput';
import PageHeader from 'src/components/PageHeader/PageHeader';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';

import ViewModal from './ViewModal/ViewModal';

import './Telemedicina.scss';

let formFields = {
  conteudo: '',
  concordar: '',
  aceitar: '',
  recusar: '',
};

const Telemedicina = () => {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);

  const [termos, setTermos] = useState<any>();
  const [editTerm, setEditTerm] = useState<any>(null);
  const { user } = useAppSelector((state: RootState) => state);

  const modalViewDisclosure = useDisclosure({ opened: false });
  const confirmNewDisclosure = useDisclosure({ opened: false });
  const confirmTermDeleteDisclosure = useDisclosure({ opened: false });

  const validationSchema = Yup.object().shape({
    conteudo: Yup.string().required('Conteúdo é obrigatório'),
    concordar: Yup.string().required('Texto de concordância é obrigatório'),
    aceitar: Yup.string().required('Texto de aceitação é obrigatório'),
    recusar: Yup.string().required('Texto de recusa é obrigatório'),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const loadTermos = useCallback(async () => {
    const res = await TelemedicinaAPI.loadTermos();

    if (Array.isArray(res)) {
      setTermos(res);
    }
  }, []);

  const handleReset = useCallback(
    () =>
      reset({
        conteudo: '',
        concordar: '',
        aceitar: '',
        recusar: '',
      }),
    [reset],
  );

  const deleteConsent = useCallback(
    async (id: number) => {
      try {
        await TelemedicinaAPI.removeTermoConsentimentoById(id);

        setTermos((termosState: any) =>
          termosState.filter((termo: any) => termo.id !== id),
        );
        setEditTerm(null);
        handleReset();

        confirmTermDeleteDisclosure.close();
      } catch (error) {
        console.error('Erro:', error);
      }
    },
    [confirmTermDeleteDisclosure, handleReset],
  );

  const handleGetTermo = useCallback(
    async (versao: any) => {
      setEditTerm(null);

      formFields = {
        conteudo: versao?.conteudo,
        concordar: versao?.textoConcordaLeitura,
        aceitar: versao?.textoConfirmaAtendimento,
        recusar: versao?.textoRecusa,
      };

      setValue('conteudo', versao?.conteudo);
      setValue('concordar', versao?.textoConcordaLeitura);
      setValue('aceitar', versao?.textoConfirmaAtendimento);
      setValue('recusar', versao?.textoRecusa);
    },
    [setValue],
  );

  const handleEditTermo = useCallback(
    (versao: any) => {
      handleGetTermo(versao);

      setEditTerm(versao);
    },
    [handleGetTermo],
  );

  const handleSave = useCallback(
    async (values: any) => {
      try {
        const aux = {
          idEmpresa: user?.idEmpresa,
          conteudo: values?.conteudo,
          textoConcordaLeitura: values?.concordar,
          textoConfirmaAtendimento: values?.aceitar,
          textoRecusa: values?.recusar,
        };

        if (editTerm) {
          await TelemedicinaAPI.updateTermoConsentimento(editTerm.id, aux, {
            throwError: true,
          });
        } else {
          await TelemedicinaAPI.saveTermos(aux, { throwError: true });
        }

        loadTermos();

        handleReset();

        setEditTerm(null);
      } catch (error) {
        console.error('Erro:', error);
      }
    },
    [user?.idEmpresa, editTerm, loadTermos, handleReset],
  );

  useEffect(() => {
    loadTermos();
  }, [loadTermos]);

  const handleGoBack = useCallback(() => {
    reset(formFields);
    navigate(-1);
  }, [reset, navigate]);

  const hasLastTerm =
    !!termos?.length && termos.filter((t: any) => !t.excluido).length;

  const handleSaveNewTerm = () => {
    if (!editTerm && hasLastTerm) {
      return confirmNewDisclosure.open();
    }

    return handleSubmit(handleSave)();
  };

  return (
    <Page className={`Telemedicina ${theme?.theme || ''}`}>
      <>
        <PageHeader title={'Termo de consentimento'} subtitle={''} />
        <div className="form-area p-grid p-p-2">
          <FormProvider {...formMethods}>
            <form
              className="p-col-12 p-md-8 p-lg-6"
              onSubmit={handleSubmit(handleSave)}
              defaultValue={''}
              onReset={() => reset(formFields)}
            >
              <TextareaInputControlled
                name="conteudo"
                className="p-mb-2"
                control={control}
                label="Conteúdo"
              />

              <FormInput
                label="Texto para opção de concordar com a leitura"
                name="concordar"
                className="p-mb-2"
              />

              <FormInput
                label="Texto para opção de aceitar e confirmar atendimento"
                name="aceitar"
                className="p-mb-2"
              />

              <FormInput
                label="Texto para opção de recusar"
                name="recusar"
                className="p-mb-2"
              />

              <div className={'p-grid'}>
                <div className={'p-col-6'}>
                  <Button
                    btnType="ghost"
                    label="Voltar"
                    className={'p-col-align-center p-d-flex'}
                    onClick={handleGoBack}
                    stretch
                  />
                </div>
                <Can
                  checkPermission={[
                    'ADM_TERMO_DE_CONSENTIMENTO_CADASTRAR',
                    'ADM_TERMO_DE_CONSENTIMENTO_ALTERAR',
                  ]}
                >
                  <div className={'p-col-6'}>
                    <Button
                      label={
                        editTerm
                          ? `Atualizar versão ${editTerm.numeroVersao}`
                          : 'Salvar'
                      }
                      onClick={() => handleSaveNewTerm()}
                      loading={isSubmitting}
                      stretch
                    />
                  </div>
                </Can>
              </div>
            </form>
          </FormProvider>
          {/* Table area with termos de consentimento */}
          <div className="table-area p-col-12 p-md-4 p-lg-6">
            <SimpleText bold>Lista de termos de consentimento</SimpleText>
            <div className="table">
              <div className="table-header">
                <div className="p-col-2 p-md-2">
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_79}
                    fontSize={FONT_SIZE.XXXS}
                  >
                    Versão
                  </SimpleText>
                </div>
                <div className="p-col-5 p-md-5">
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_79}
                    fontSize={FONT_SIZE.XXXS}
                  >
                    Data da adição
                  </SimpleText>
                </div>
                <div className="p-col-3 p-md-3">
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_79}
                    fontSize={FONT_SIZE.XXXS}
                  >
                    Responsável
                  </SimpleText>
                </div>
                <div className="p-col-2 p-md-2">
                  <Can
                    checkPermission={[
                      'ADM_TERMO_DE_CONSENTIMENTO_ALTERAR',
                      'ADM_TERMO_DE_CONSENTIMENTO_CADASTRAR',
                      'ADM_TERMO_DE_CONSENTIMENTO_EXCLUIR',
                    ]}
                  >
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_79}
                      fontSize={FONT_SIZE.XXXS}
                    >
                      Ações
                    </SimpleText>
                  </Can>
                </div>
              </div>
              <div className="table-content">
                <ScrollPanel>
                  <>
                    {termos?.length > 0 ? (
                      termos
                        ?.slice()
                        .reverse()
                        .map((e: any, index: number) => (
                          <div key={index} className="itemTermo">
                            <div
                              className="p-col-2 p-md-2"
                              onClick={() => {
                                modalViewDisclosure.open({ state: e.conteudo });
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <SimpleText
                                fontColor={FONT_COLOR.PRIMARY}
                                fontSize={FONT_SIZE.XS}
                                bold
                              >
                                {e.numeroVersao}
                              </SimpleText>
                            </div>
                            <div className="p-col-5 p-md-5">
                              <SimpleText
                                fontColor={FONT_COLOR.COLOR_60}
                                fontSize={FONT_SIZE.XXS}
                              >
                                {stringDateToShow(e?.dataCriacao)}
                              </SimpleText>
                            </div>
                            <div className="p-col-3 p-md-3">
                              <SimpleText
                                fontColor={FONT_COLOR.COLOR_0}
                                fontSize={FONT_SIZE.XXXS}
                              >
                                {e?.usuarioAtualizacao?.nome}
                              </SimpleText>
                            </div>
                            <div className="p-d-flex p-flex-row p-flex-wrap p-col-2 p-md-2">
                              {index === 0 && !e.enviado ? (
                                <>
                                  <Can checkPermission="ADM_TERMO_DE_CONSENTIMENTO_ALTERAR">
                                    <Button
                                      btnType="gray"
                                      icon="pi pi-pencil"
                                      onClick={() => handleEditTermo(e)}
                                      style={{ marginRight: 5 }}
                                    />
                                  </Can>
                                  <Can checkPermission="ADM_TERMO_DE_CONSENTIMENTO_EXCLUIR">
                                    <Button
                                      btnType="gray"
                                      icon="pi pi-trash"
                                      onClick={() =>
                                        confirmTermDeleteDisclosure.open({
                                          state: e.id,
                                        })
                                      }
                                    />
                                  </Can>
                                </>
                              ) : (
                                <Can checkPermission="ADM_TERMO_DE_CONSENTIMENTO_CADASTRAR">
                                  <Button
                                    btnType="gray"
                                    icon="pi pi-clone"
                                    onClick={() => handleGetTermo(e)}
                                  />
                                </Can>
                              )}
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="p-d-flex p-jc-center p-ai-center">
                        <SimpleText
                          fontColor={FONT_COLOR.COLOR_0}
                          fontSize={FONT_SIZE.XXXS}
                        >
                          Nenhum termo disponível
                        </SimpleText>
                      </div>
                    )}
                  </>
                </ScrollPanel>
              </div>
            </div>
          </div>

          {modalViewDisclosure.isOpen && <ViewModal {...modalViewDisclosure} />}
          {confirmNewDisclosure.isOpen && (
            <ConfirmDialog
              visible={confirmNewDisclosure.isOpen}
              header="Confirmar novo termo de consentimento"
              text="Você tem certeza que deseja salvar o novo termo de consentimento, inativando a versão anterior?"
              confirmText="Sim, salvar"
              cancelText="Não, cancelar"
              onHide={() => confirmNewDisclosure.close()}
              onConfirm={() => {
                handleSubmit(handleSave)();
                confirmNewDisclosure.close();
              }}
            />
          )}
          {confirmTermDeleteDisclosure.isOpen && (
            <ConfirmDialog
              visible={confirmTermDeleteDisclosure.isOpen}
              header="Excluir termo de consentimento?"
              text="Você realmente deseja excluir o termo da base de dados?"
              confirmText="Sim, excluir"
              cancelText="Não, cancelar"
              onHide={() => confirmTermDeleteDisclosure.close()}
              onConfirm={() => deleteConsent(confirmTermDeleteDisclosure.state)}
            />
          )}
        </div>
      </>
    </Page>
  );
};

export default Telemedicina;
