import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';

import './Box.scss';

interface BoxProps {
  children?: JSX.Element;
  label?: string | undefined;
  labelOverflow?: 'visible' | 'hidden';
}
const Box = ({ children, label, labelOverflow = 'visible' }: BoxProps) => {
  const renderLabel = () => {
    if (label || label === '') {
      return (
        <ComponentsLabel overflowX={labelOverflow} label={label} required />
      );
    }
    return <></>;
  };

  return (
    <>
      {renderLabel()}

      <div className="Box">{children}</div>
    </>
  );
};

export default Box;
