import { useCallback, useState } from 'react';

import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Paciente } from '../Paciente';

import { Header, MobileRow } from './List';

interface PacienteDestinoProps {
  setSelectedDestino: any;
  selectedDestino: any;
  selectedOrigem: any;
}

export default function PacienteDestino({
  setSelectedDestino,
  selectedDestino,
  selectedOrigem,
}: PacienteDestinoProps) {
  const [reload, setReload] = useState<number>(0);

  const fetchApi = async (params: any): Promise<any> => {
    const query = {
      chave: params.query,
      page: params.page,
    };

    const response = await PacienteAPI.getPacientesPesquisaCombinada(query);
    if (response.status >= 200 && response.status < 300) {
      return response?.data;
    }
  };

  const handleDeleteCpf = useCallback(async (id: number) => {
    await PacienteAPI.removeCpfPaciente(id);
    setReload(current => current + 1);
  }, []);

  const renderRow = (data: any, isMobile: boolean): JSX.Element => {
    if (selectedOrigem?.paciente?.id === data?.id) return <></>;
    const newData = {
      paciente: {
        id: data?.id,
        nome: data?.nome,
        dataNascimento: data?.dataNascimento,
        fotoUrl: data?.fotoUrl,
      },
      nomeMae: data?.nomeMae,
      cartaoConvenio: data?.numeroCartao,
      cns: data?.cns,
      cpf: data?.cpf,
      passaporte: data?.passaporte,
      atendimentos: data?.atendimentos,
    };

    const commonProps = {
      migracao: newData,
      onSelectDestino: () => setSelectedDestino(newData),
      onDeleteCpf: () => handleDeleteCpf(data?.id),
      isSelected: selectedDestino?.paciente.id === data?.id,
    };

    if (isMobile) return <MobileRow {...commonProps} />;

    return (
      <SearchList.CardItem>
        <Paciente {...commonProps} />
      </SearchList.CardItem>
    );
  };

  return (
    <>
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
        reloadWhenConsultorioChange
      >
        <SearchList.SearchInput
          label="Paciente destino"
          placeholder="Digite nome, nome da mãe, CPF, carteirinha ou ID do paciente"
        />
        <SearchList.BorderContainer>
          <SearchList.Header>
            <Header />
          </SearchList.Header>
          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </>
  );
}
