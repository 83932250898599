import { useCallback, useState } from 'react';

import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { EmptyData } from '../../components/EmptyData';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import RowCIAP from './List/RowCIAP';
import RowCID from './List/RowCID';
import { ModalInativarCIDCIAP } from './ModalInativarCIDCIAP';
import PanelOptionsMenu from './PanelOptionsMenu/PanelOptionsMenu';

import './HistoricoCIDSCIAPS.scss';

interface Props {
  justificativaDisclosure: any;
  reload: () => void;
}

export const HistoricoCIDSCIAPS = ({
  justificativaDisclosure,
  reload,
}: Props) => {
  const [quantidadeCIDS, setQuantidadeCIDS] = useState<number | null>(null);
  const [quantidadeCIAPS, setQuantidadeCIAPS] = useState<number | null>(null);

  const { selectedAtendimento, setSelectedCidCiap } = useHistoricoAtendimento();

  const inativarCIDCIAPDisclosure = useDisclosure({ opened: false });

  const idAtendimento = selectedAtendimento?.idAtendimento;

  const fetchCIDS = useCallback(
    async (params: any) => {
      const response = await HistoricoAtendimentosAPI.getCidsAtendimento(
        Number(idAtendimento),
        {
          sortBy: 'dataAtualizacao',
          sortDirection: 'DESC',
          ...params,
        },
      );

      setQuantidadeCIDS(response?.cids.totalCount);
      return response?.cids;
    },
    [idAtendimento],
  );

  const fetchCIAPS = useCallback(
    async (params: any) => {
      const response = await HistoricoAtendimentosAPI.getCiapsAtendimento(
        Number(idAtendimento),
        {
          sortBy: 'dataAtualizacao',
          sortDirection: 'DESC',
          ...params,
        },
      );

      setQuantidadeCIAPS(response?.ciaps.totalCount);
      return response?.ciaps;
    },
    [idAtendimento],
  );

  const optionsMenu = (cidCiap: any, tipo: string) => (
    <PanelOptionsMenu
      ativo={cidCiap.ativo ? true : false}
      lembreteAtivo={false}
      idUsuarioCriacao={cidCiap.usuarioAtualizacao.id}
      onAlterarLembrete={() => {}}
      onEdit={() => {}}
      onInativar={() => {
        setSelectedCidCiap({ ...cidCiap, tipo });
        inativarCIDCIAPDisclosure.open();
      }}
      onExcluir={() => {}}
      onVisualizarJustificativa={() => {
        setSelectedCidCiap({ ...cidCiap, tipo });
        inativarCIDCIAPDisclosure.open();
      }}
      idAtendimentoRegistro={idAtendimento}
      hideEditOption
      hideLembreteOption
    />
  );

  const renderRowCID = (cid: any) => (
    <RowCID cid={cid} optionsMenu={optionsMenu} />
  );

  const renderRowCIAP = (ciap: any) => (
    <RowCIAP ciap={ciap} optionsMenu={optionsMenu} />
  );

  return (
    <div className="p-grid" id="historico-cids-ciaps">
      {quantidadeCIDS === 0 && quantidadeCIAPS === 0 ? (
        <SimpleText
          fontColor={FONT_COLOR.COLOR_40}
          className="p-col-12 p-my-1 p-py-4 content p-text-center"
        >
          Não há CID/CIAP cadastrados para este atendimento
        </SimpleText>
      ) : (
        <>
          <div className="p-col-12">
            <SearchList.Root fetchApi={fetchCIDS}>
              <SearchList.Header>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  CID
                </SimpleText>
              </SearchList.Header>
              <SearchList.BorderContainer>
                <SearchList.InfiniteScroll
                  renderRow={renderRowCID}
                  reload={reload}
                  customEmptyText={
                    <div className="content-empty-row">
                      <EmptyData />
                    </div>
                  }
                />
              </SearchList.BorderContainer>
            </SearchList.Root>
          </div>
          <div className="p-col-12">
            <SearchList.Root fetchApi={fetchCIAPS}>
              <SearchList.Header>
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  CIAP
                </SimpleText>
              </SearchList.Header>
              <SearchList.BorderContainer>
                <SearchList.InfiniteScroll
                  renderRow={renderRowCIAP}
                  reload={reload}
                  customEmptyText={
                    <div className="content-empty-row">
                      <EmptyData />
                    </div>
                  }
                />
              </SearchList.BorderContainer>
            </SearchList.Root>
          </div>
        </>
      )}
      <ModalInativarCIDCIAP {...inativarCIDCIAPDisclosure} />
    </div>
  );
};
