import { createContext } from 'react';

export interface TelemedicinaAtendimentoProps {
  telemedicinaLogTermo: any;
  idAtendimento: number;
  statusAtendimento: string;
  linkPesquisaSatisfacao: string;
  pesquisaResponsida: boolean;
  nomeConvenio: any;
  nomeMedico: string;
  dataAgendamento: string;
  local: string;
  nomePaciente: string;
  contatos: any[];
  telefonePrincipal: any;
  nomeEspecialidade: any;
  flagsAtendimento: string[];
}

export interface TelemedicinaProps {
  atendimentoData: null | TelemedicinaAtendimentoProps;
  handleSetAtendimento: (data: any) => void;
  roomURL: string | null;
  setRoomURL: (v: string) => void;
}

export const TelemedicinaContext = createContext({} as TelemedicinaProps);
