import { useState } from 'react';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

type DisfavorProcedimentoType = {
  isDisfavoring: boolean;
  handleDisfavorProcedimento: (
    idProcedimento: number,
    onDeleteItemFromList: () => void,
  ) => void;
};

export const useBuscaRapidaDisfavorProcedimento =
  (): DisfavorProcedimentoType => {
    const [isDisfavoring, setIsDisfavoring] = useState(false);

    const handleDisfavorProcedimento = async (
      idProcedimento: number,
      onRemoveItemFromList: () => void,
    ) => {
      try {
        setIsDisfavoring(true);
        await ServicoTussCRUDAPI.desfavoritarExame(idProcedimento);
        onRemoveItemFromList();
      } catch (error) {
        // error
      } finally {
        setIsDisfavoring(false);
      }
    };

    return {
      isDisfavoring,
      handleDisfavorProcedimento,
    };
  };
