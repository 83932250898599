import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const DialogVinculosConsultorio = ({
  consultoriosItems,
}: {
  consultoriosItems: any[];
}) => {
  return (
    <div
      className="row content-row-outline p-col-12 p-d-flex p-flex-column p-gap-2"
      style={{
        height: '265px',
        overflowY: 'auto',
      }}
    >
      <SimpleText className="p-col-12" fontColor="color_60" medium>
        Nome do consultório
      </SimpleText>

      {consultoriosItems.map((item, idx) => (
        <div key={idx} className="row content-row p-col-12 p-py-3">
          <SimpleText medium>{item.nome}</SimpleText>
        </div>
      ))}
    </div>
  );
};
