import { Control, useController } from 'react-hook-form';

import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

import './CardQuestions.scss';

interface CardQuestionsProps {
  title: string;
  control: Control;
  name?: string;
  disabled?: boolean;
  checkbox?: boolean;
  readOnly?: boolean | undefined;
  defaultValue?: any | undefined;
  error?: any;
  children: JSX.Element;
}

const CardQuestions = ({
  control,
  name = '',
  readOnly,
  disabled = false,
  defaultValue,
  children,
  error,
  title,
  checkbox,
}: CardQuestionsProps) => {
  const {
    field: { value },
  } = useController({
    name: name,
    control,
    defaultValue,
  });

  return (
    <div
      className={
        error ? 'container-card error-cardsQuestionario' : 'container-card'
      }
    >
      <div className="p-d-flex p-ai-center p-jc-between">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
          medium
        >
          {title}
        </SimpleText>

        {!readOnly && !checkbox && (
          <RadioButtonControlled
            className="nao-se-aplica"
            control={control}
            name={name}
            panel={false}
            label="Não se aplica"
            value="NAO_SE_APLICA"
            disabled={disabled}
          />
        )}

        {!readOnly && checkbox && (
          <CheckboxControlled
            className="nao-se-aplica"
            label="Não se aplica"
            control={control}
            name={name}
            disabled={disabled}
          />
        )}
      </div>

      {error && (
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.DANGER}
          fontSize={FONT_SIZE.XXXS}
        >
          {error}
        </SimpleText>
      )}

      <Separator />

      {children}
    </div>
  );
};

export default CardQuestions;
