import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { CartoesPacienteAPI } from 'src/APIs/AdminAPI/CartoesPacienteAPI/CartoesPacienteAPI';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { FieldNumeroCartaoConvenio } from 'src/components/Fields/FieldNumeroCartaoConvenio/FieldNumeroCartaoConvenio';
import { FieldTipoPlano } from 'src/components/Fields/FieldTipoPlano/FieldTipoPlano';
import FormInput from 'src/components/FormInput/FormInput';

import { validationSchema } from './validationSchema';

type FormValues = {
  convenio: Convenio | null;
  tipoPlano: string;
  numeroCartao: string;
  dataValidadeCartao: Date | null;
  principal: boolean;
};

interface FormCartaoPacienteProps {
  editCartao: any | null;
  idPaciente: number;
  onSuccess: () => void;
  onHide: () => void;
  cartoesLength: number;
}

const formInitialValues = {
  convenio: null,
  tipoPlano: '',
  numeroCartao: '',
  dataValidadeCartao: null,
  principal: false,
};

export const FormCartaoPaciente = (props: FormCartaoPacienteProps) => {
  const { editCartao, idPaciente, onSuccess, onHide, cartoesLength } = props;
  const hasCartoes = !!(cartoesLength < 1);

  const initialDataValidadeCartao = editCartao?.dataValidadeCartao
    ? new Date(editCartao.dataValidadeCartao)
    : null;

  const useFormMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...(editCartao || formInitialValues),
      principal: editCartao?.principal || !hasCartoes,
      dataValidadeCartao: initialDataValidadeCartao,
    },
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    const payload = {
      id: editCartao?.id || undefined,
      idConvenio: data.convenio.id,
      idTipoPlano: data.tipoPlano?.id,
      outroTipoPlano: data.outroTipoPlano,
      numeroCartao: data.numeroCartao.match(/\d/g).join(''),
      dataValidadeCartao: data.dataValidadeCartao,
      principal: data.principal,
      idPaciente,
    };

    const { saveCartao, updateCartao } = CartoesPacienteAPI;

    const saveAction = !!editCartao ? updateCartao : saveCartao;

    const response = await saveAction(payload);

    if (response?.status === 200) {
      onSuccess();
    }
  };

  const handleConvenioChange = () => {
    setValue('numeroCartao', '');
    setValue('tipoPlano', '');
  };

  const isCartaoInativo = !editCartao?.status;

  return (
    <FormProvider {...useFormMethods}>
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <FieldConvenio
          name="convenio"
          label="Plano de saúde"
          placeholder="Selecione"
          customOnChange={handleConvenioChange}
        />

        <FieldTipoPlano />

        <FormInput
          className="p-col-12"
          name="outroTipoPlano"
          label="Outro tipo de plano"
          maxLength={50}
          hideTextErrorSpace
        />
        <FieldNumeroCartaoConvenio
          label="Número da carteirinha"
          convenioFieldName="convenio"
          editPlanoSaude={editCartao}
        />

        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="dataValidadeCartao"
          label="Validade"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          yearRange={
            new Date().getFullYear() -
            20 +
            ':' +
            (new Date().getFullYear() + 10)
          }
          errorMsg={errors.dataValidadeCartao?.message}
        />

        <div className="p-col-12">
          <CheckboxControlled
            className="checkbox-small"
            control={control}
            name="principal"
            label="Marcar como principal"
            disabled={!hasCartoes || isCartaoInativo}
          />
        </div>

        <div className="p-col-6">
          <Button
            btnType={BtnTypes.GHOST}
            label="Cancelar"
            className="p-col-align-center"
            onClick={onHide}
            loading={isSubmitting}
          />
        </div>
        <div className="p-col-6">
          <Button type="submit" label="Salvar" loading={isSubmitting} />
        </div>
      </form>
    </FormProvider>
  );
};
