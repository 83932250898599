import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <SimpleText
            className="p-col-4"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Nome/Sexo/Planos de saúde
          </SimpleText>
          <SimpleText
            className="p-col-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            CPF/Registro
          </SimpleText>
          <SimpleText
            className="p-col-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Data de nascimento
          </SimpleText>
          <SimpleText
            className="p-col-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Nome da mãe
          </SimpleText>
          <SimpleText
            className="p-col-1"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Ativo
          </SimpleText>
          <SimpleText
            className="p-col p-text-right"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Ação
          </SimpleText>
        </div>
      )}
    </>
  );
};
