import { useCallback, useEffect, useState, useRef } from 'react';

import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import useDebounce from 'src/utils/useDebounce';

import Avatar from 'src/components/Avatar/Avatar';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';
import Separator from 'src/components/Separator/Separator';

import './SearchPaciente.scss';
import { useNovoAgendamento } from 'src/pages/Emed/Agenda/NovoAgendamento/hooks/NovoAgendamentoContext';

import { Button } from '../_UI/Button';

interface SeatchPacienteProps {
  value: GetPacienteDTO | undefined;
  label: string;
  onSelect(v: GetPacienteDTO): void;
  disabled?: boolean;
  idConsultorio?: number;
  paramsBusca?: any;
  onBooking?: any;
}
const SearchPaciente = ({
  onSelect,
  value,
  label,
  idConsultorio,
  paramsBusca,
  onBooking,
  ...props
}: SeatchPacienteProps) => {
  const searchPacientesContentRef = useRef<HTMLDivElement>(null);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [pacientes, setPacientes] = useState<GetPacienteDTO[]>();
  const [current, setCurrent] = useState<string>('');

  const { modalOutrosDisclosure } = useNovoAgendamento();

  const debouncedPesquisaPaciente = useDebounce(current, 350);

  const loadPacientes = useCallback(
    async value => {
      try {
        const params = {
          chave: value,
          ...(idConsultorio && { idConsultorio }),
          ...(paramsBusca && { ...paramsBusca }),
        };

        const aux = await PacienteAPI.loadPacienteByNome(params);
        if (aux) {
          setPacientes(aux.list);
        }
      } catch (e) {
        setPacientes([]);
      }
    },
    [idConsultorio, paramsBusca],
  );

  useEffect(() => {
    if (debouncedPesquisaPaciente) {
      loadPacientes(debouncedPesquisaPaciente);
    } else {
      setPacientes([]);
    }
  }, [loadPacientes, debouncedPesquisaPaciente]);

  useEffect(() => {
    function handleClickOutsidePacientesContent(event: any) {
      if (
        searchPacientesContentRef.current &&
        !searchPacientesContentRef.current.contains(event.target)
      ) {
        setShowOverlay(false);
        setCurrent('');
        setPacientes([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutsidePacientesContent);

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsidePacientesContent,
      );
    };
  }, [searchPacientesContentRef]);

  const renderPacienteCard = useCallback(
    (paciente: GetPacienteDTO, i: number, last = false) => {
      return (
        <>
          <Card
            className="search-paciente-card-container"
            type={CARD_TYPES.GRAY}
          >
            <div
              key={i}
              className="search-paciente-card"
              onClick={e => {
                onSelect(paciente);
                setShowOverlay(false);
              }}
            >
              <div className="search-paciente-card-detail">
                <Avatar image={paciente.urlFoto || ''} label={paciente.nome} />
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {paciente.nome}
                </SimpleText>
              </div>
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {paciente.cartaoPlano}
              </SimpleText>
            </div>
          </Card>
          {last ? <></> : <Separator spaceOnly vertical />}
        </>
      );
    },
    [onSelect],
  );

  const renderOverlay = useCallback(() => {
    return (
      <div className="search-paciente-overlay-content">
        {current && onBooking ? (
          <Card
            className="search-paciente-card-container"
            type={CARD_TYPES.GRAY}
            onClick={() => {
              onBooking(current);
              setShowOverlay(false);
            }}
          >
            <SimpleText fontColor={FONT_COLOR.COLOR_40}>
              Reservar horário para...{' '}
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                {current}
              </SimpleText>
            </SimpleText>
          </Card>
        ) : (
          <SimpleText>
            {current
              ? `Reservar horário para... ${current}`
              : 'O resultado da busca será exibido aqui'}
          </SimpleText>
        )}

        <Separator spaceOnly vertical />

        {!!pacientes?.length && (
          <div className="scroll-place">
            <ScrollPanel stretch={true} activityIndicator={false}>
              <>
                {(pacientes || []).map((p: GetPacienteDTO, i: number) =>
                  renderPacienteCard(
                    p,
                    i,
                    pacientes && pacientes.length - 1 === i ? true : false,
                  ),
                )}
              </>
            </ScrollPanel>
          </div>
        )}
        <Button label={'Mais resultados'} />
        <Separator spaceOnly vertical />

        <Button
          stretch={true}
          label={'Novo paciente'}
          btnType="green-link"
          icon={'fas fa-user-plus'}
          onClick={() =>
            modalOutrosDisclosure.open({ state: { activeTab: 1 } })
          }
        />
      </div>
    );
  }, [
    current,
    pacientes,
    renderPacienteCard,
    modalOutrosDisclosure,
    onBooking,
  ]);

  return (
    <div ref={searchPacientesContentRef} className="SearchPaciente">
      <TextInput
        label={label}
        rounded
        icon={'pi pi-search'}
        value={!value || showOverlay ? current : value?.nome}
        placeholder="Digite o nome, CPF ou a carteirinha"
        onChange={v => {
          setCurrent(v.target.value);
        }}
        onFocus={e => {
          setShowOverlay(true);
          setCurrent('');
        }}
      />

      <div className={`OverlayPanel ${showOverlay ? 'show' : 'hidden'}`}>
        <ElevatedCard type={'white'} stretch noMargin>
          {renderOverlay()}
        </ElevatedCard>
      </div>
    </div>
  );
};

export default SearchPaciente;
