import React, { ChangeEvent, useContext, useState } from 'react';

import {
  InputText as PrimeTextInput,
  InputTextProps as PrimeInputTextProps,
} from 'primereact/inputtext';

import ThemeContext from 'src/core/themes/ThemeContext';

import { BtnTypes, Button } from 'src/components/_UI/Button';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './Input.scss';

export interface TextInputProps extends PrimeInputTextProps {
  label?: string | undefined;
  errorMsg?: string | any;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  value?: string;
  icon?: string | undefined;
  classNameInput?: string;
  rounded?: boolean;
  maxLengthSpan?: boolean;
  permitirApenasNumeros?: boolean;
  floatOnly?: boolean;
  virgulaDecimal?: boolean;
  duasDecimais?: boolean;
  onClickIcon?: () => void;
  hideTextErrorSpace?: boolean;
  editOnClick?: boolean;
  iconPos?: 'left' | 'right';
  sublabel?: string;
  labelOverflow?: 'visible' | 'hidden';
  loading?: boolean;
  labelTooltip?: JSX.Element | undefined;
  insideButton?: string;
  insideButtonType?: BtnTypes;
  onClickInsideButton?: () => void;
}

export const InputText = React.forwardRef<any, TextInputProps>(
  (
    {
      label,
      name,
      errorMsg,
      className,
      onChange,
      disabled,
      icon,
      value = '',
      type,
      classNameInput,
      maxLength,
      required,
      rounded,
      maxLengthSpan,
      onClickIcon,
      hideTextErrorSpace,
      editOnClick = false,
      iconPos = 'right',
      sublabel = '',
      labelOverflow = 'visible',
      loading,
      labelTooltip,
      permitirApenasNumeros,
      insideButton,
      insideButtonType,
      onClickInsideButton,
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext);

    const [editOn, setEditOn] = useState(false);

    const renderLabel = () => {
      if (label || label === '' || required) {
        return (
          <ComponentsLabel
            name={name}
            label={label}
            sublabel={sublabel}
            overflowX={labelOverflow}
            labelTooltip={labelTooltip}
            required
          />
        );
      }
      return <></>;
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (editOnClick && editOn) {
        setEditOn(false);
      }

      rest?.onBlur?.(e);
    };

    const renderIcon = () => {
      if (!icon) return;
      if (!errorMsg) {
        if (loading) return <i className={'pi pi-spin pi-spinner'} />;
        else return <i className={`${icon}`} onClick={onClickIcon} />;
      } else return <i className={'pi pi-exclamation-circle'} />;
    };

    const renderInsideButton = () => {
      if (!insideButton) return;
      return (
        <div className="btn-container p-p-1">
          <Button
            icon={insideButton}
            btnType={insideButtonType || 'default'}
            onClick={onClickInsideButton}
          />
        </div>
      );
    };

    if (editOnClick && !editOn) {
      return (
        <SimpleText
          className={`text-input-edit-only-click ${className}`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.PRIMARY}
          onClick={() => setEditOn(true)}
        >
          {value}
        </SimpleText>
      );
    }

    const classNameIcon = iconPos === 'left' ? 'p-pl-5' : 'p-pr-5';

    return (
      <div
        className={`TextInput ${theme?.theme || ''} ${
          rounded ? 'rounded' : ''
        } ${errorMsg ? 'error' : ''} ${disabled ? 'disabled-text-input' : ''} ${
          className || ''
        }`}
        key={name}
      >
        {renderLabel()}
        <span
          className={`iconHandler ${
            errorMsg || icon || loading ? `p-input-icon-${iconPos}` : ''
          } ${insideButton ? 'insideButton' : ''}`}
        >
          {renderIcon()}
          {renderInsideButton()}

          <PrimeTextInput
            {...rest}
            type={type}
            maxLength={maxLength}
            id={name}
            ref={ref}
            className={`${errorMsg ? 'p-invalid' : ''} ${classNameInput} ${
              icon ? classNameIcon : ''
            }`}
            value={value}
            disabled={disabled || loading}
            autoFocus={rest?.autoFocus || editOnClick}
            onBlur={handleBlur}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (
                permitirApenasNumeros &&
                !/^[\d-/ .,]*$/.test(e.target.value)
              ) {
                return;
              }

              if (maxLength && e.target.value?.length > maxLength) {
                return;
              }

              if (onChange) {
                onChange(e);
              }
            }}
          />
        </span>
        <div
          className={`p-d-flex p-jc-between p-ai-center ${
            hideTextErrorSpace ? 'p-mt-0' : 'p-mt-1'
          }`}
        >
          <div style={{ minHeight: hideTextErrorSpace ? '0' : '14px' }}>
            <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
              {errorMsg || ''}
            </SimpleText>
          </div>

          {maxLength && maxLengthSpan && (
            <SimpleText className={'p-text-normal'} fontSize={FONT_SIZE.XXS}>
              {`${value.length}/${maxLength}`}
            </SimpleText>
          )}
        </div>
      </div>
    );
  },
);
