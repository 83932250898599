import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  impresso: any;
  onCopiarImpresso: (impresso: any) => void;
  onExcluirImpresso: (impresso: any) => void;
}

export default function DesktopRow({
  impresso,
  onCopiarImpresso,
  onExcluirImpresso,
}: DesktopRowProps) {
  const retirarTagP = (htmlString: any) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, 'text/html');
    const pTag = parsedHtml.getElementsByTagName('p')[0];
    return pTag ? pTag.textContent : htmlString;
  };

  const overlayRef = useRef<OverlayPanel>(null);

  return (
    <div className="row-impresso p-p-2 p-my-1">
      <div className="p-d-flex p-jc-between">
        <div className="p-d-flex p-flex-column p-p-1">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XXS}
            medium
          >
            Solicitado por: {impresso?.profissionalSaude}
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXXS}>
            Em{' '}
            {dayjs(new Date(impresso?.dataOcorrencia)).format(
              'DD/MM/YYYY [às] HH:mm',
            )}
          </SimpleText>
        </div>
        <div className="p-mb-2 p-d-flex p-jc-end p-p-1">
          <Button
            icon="fa-regular fa-copy"
            btnType="gray"
            onClick={() => onCopiarImpresso(impresso)}
            className="p-mr-2"
          />
          <Button
            icon="fa-solid fa-trash"
            btnType="gray"
            onClick={e => overlayRef.current?.toggle(e)}
          />
          <OverlayPanel ref={overlayRef}>
            <div className="p-grid">
              <div className="p-col-12 p-d-flex p-jc-center">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                  medium
                >
                  Você tem certeza que deseja excluir este{' '}
                  {impresso.tipoImpresso.descricao.toLowerCase()}?
                </SimpleText>
              </div>
              <div className="p-col-12">
                <Button
                  label="Sim"
                  btnType="danger"
                  onClick={() => onExcluirImpresso(impresso)}
                  stretch
                />
              </div>
            </div>
          </OverlayPanel>
        </div>
      </div>
      <div className="p-d-flex p-flex-column p-p-1">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          Titulo:
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXXS}>
          {impresso?.titulo}
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-p-1">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          Tipo:
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXXS}>
          {impresso.tipoImpresso.descricao}
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-p-1">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          Descrição:
        </SimpleText>
        <SimpleText
          className="p-wordbreak-word"
          fontColor={FONT_COLOR.COLOR_60}
          fontSize={FONT_SIZE.XXXS}
        >
          {retirarTagP(impresso?.descricao)}
        </SimpleText>
      </div>
    </div>
  );
}
