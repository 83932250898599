import Http, { HttpParams } from 'src/core/http/Http';

const basePath = 'avaliacao-enfermagem/portal-paciente';

const getQuestionariosPortal = async (
  idPaciente: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/paciente/${idPaciente}`,
    params,
    hideToast: true,
    ...options,
  });
};

const getEvolucaoPortal = async (
  idPaciente: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/${idPaciente}/evolucao`,
    params,
    hideToast: true,
    ...options,
  });
};

const getUltimoQuestionarioValidadoPortal = async (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/copia`,
    params,
    hideToast: true,
    errorHideToast: true,
    return: true,
    ...options,
  });
};

const postQuestionarioPortal = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}`,
    data,
    ...options,
  });
};

const putQuestionarioPortal = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${data.id}`,
    data,
    ...options,
  });
};

const putInativarQuestionarioPortal = async (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${data.id}/inativar`,
    data,
    ...options,
  });
};

const deleteQuestionarioPortal = async (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

export const PerfilSaudeAPI = {
  getQuestionariosPortal,
  getEvolucaoPortal,
  getUltimoQuestionarioValidadoPortal,
  postQuestionarioPortal,
  putQuestionarioPortal,
  putInativarQuestionarioPortal,
  deleteQuestionarioPortal,
};
