import Http, { HttpParams } from 'src/core/http/Http';

const getQuestionarioAtendimento = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/avaliacao-enfermagem/atendimento/${idAtendimento}`,
    data: params,
    params,
    hideToast: true,
    ...options,
  });
};

export const getQuestionariosEvolucao = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'pep',
    url: `avaliacao-enfermagem/${params.id}/atendimento/${params.idAtendimento}/evolucao`,
    params,
    hideToast: true,
    ...options,
  });
};

export const postQuestionarios = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: 'avaliacao-enfermagem',
    data: payload,
    ...options,
  });
};

export const putQuestionarios = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `avaliacao-enfermagem/${payload.id}/atendimento/${payload.idAtendimento}`,
    data: payload,
    ...options,
  });
};

export const putQuestionariosInativar = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `avaliacao-enfermagem/${payload.id}/atendimento/${payload.idAtendimento}/inativar`,
    data: payload,
    ...options,
  });
};

export const putValidarOuRejeitarQuestionario = (
  idAtendimento: string | number,
  idQuestionario: number,
  validarOuRejeitar: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `avaliacao-enfermagem/${idQuestionario}/atendimento/${idAtendimento}/validar-rejeitar`,
    data: { validarOuRejeitar },
    return: true,
    ...options,
  });
};

export const deleteQuestionarios = (
  idQuestionario: number,
  id: string | undefined,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `avaliacao-enfermagem/${idQuestionario}/atendimento/${id}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

const getPermitidoPreencherQuestionario = async (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `avaliacao-enfermagem/paciente/${idPaciente}/permitido-preencher-novo`,
    hideToast: true,
    ...options,
  });
};

const QuestionariosAPI = {
  postQuestionarios,
  getQuestionarioAtendimento,
  deleteQuestionarios,
  putQuestionarios,
  putQuestionariosInativar,
  putValidarOuRejeitarQuestionario,
  getQuestionariosEvolucao,
  getPermitidoPreencherQuestionario,
};

export default QuestionariosAPI;
