import dayjs from 'dayjs';
import { LoadPrescricao } from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import useTheme from 'src/core/themes/useTheme';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { preventDefaultAndStopPropagation } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';

import './PanelHeader.scss';

dayjs.locale('pt-br');

interface PanelHeaderProps {
  isCollapsed: boolean;
  item: LoadPrescricao;
  optionsMenu?: JSX.Element;
  onToggle: () => void;
}

export const PanelHeader = ({
  isCollapsed,
  onToggle,
  optionsMenu,
  item,
}: PanelHeaderProps) => {
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector((state: RootState) => state);
  const { theme = '' } = useTheme();

  const modalJustificativa = useDisclosure({ opened: false });

  const isAtivo = item.status === 'ATIVO';
  const isExecutado = item?.situacaoPrescricao === 'EXECUTADA';
  const isRetroativa = !!item?.justificativaOcorrencia;

  const isSameProfissional =
    profissionalAtivo.id === item.atendimento?.idMedico;

  return (
    <div
      className={`prescricao-medicamentos-header p-grid p-col-12 p-align-center ${
        !isAtivo ? 'inativo' : ''
      }`}
    >
      <div className="p-col-6 p-d-flex p-gap-2">
        <Avatar
          className="p-avatar-prescricao"
          label={item?.atendimento?.medico?.nome}
        />
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText className="medico-nome">
            {item?.atendimento?.medico?.nome}
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            {item?.dataInclusao
              ? dayjs(item.dataInclusao).format(
                  '[Em] DD [de] MMM [de] YYYY [às] HH:mm',
                )
              : '-'}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-4 p-d-flex p-gap-1 p-jc-end">
        {isAtivo && (
          <>
            {isExecutado ? (
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                className={`tag tag-executada ${theme}`}
              >
                Executada
              </SimpleText>
            ) : (
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                className={`tag tag-nova ${theme}`}
              >
                Nova
              </SimpleText>
            )}

            {isRetroativa && (
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                className={`tag tag-retroativo ${theme}`}
              >
                Retroativo
              </SimpleText>
            )}
          </>
        )}
      </div>

      <div className="p-col-2 p-d-flex p-jc-end">
        {!isAtivo && (
          <Button
            icon="fa fa-eye"
            btnType="gray"
            onClick={e => [
              preventDefaultAndStopPropagation(e),
              modalJustificativa.open(),
            ]}
            className="p-p-0"
          />
        )}

        {isExecutado && isSameProfissional && isAtivo && optionsMenu}

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
          className="p-p-0"
        />
      </div>

      {modalJustificativa.isOpen && (
        <ModalJustificativa item={item} {...modalJustificativa} />
      )}
    </div>
  );
};

const ModalJustificativa = ({ isOpen, close, item }: any) => {
  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      header="Justificativa de inativação"
      style={{ width: '420px' }}
    >
      <div className="p-d-flex p-flex-column p-gap-2">
        <TextareaInput disabled value={item?.justificativaInativacao} />
        <Button
          label="Fechar"
          btnType="ghost"
          onClick={() => close()}
          stretch
        />
      </div>
    </Dialog>
  );
};
