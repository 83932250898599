import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  motivo: GetManterTrasferenciaApsDTO;
  onEdit: (motivo: GetManterTrasferenciaApsDTO) => void;
  onDelete: (id: number) => void;
  onSwitchStatus: (motivo: GetManterTrasferenciaApsDTO) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { motivo, onEdit, onDelete, onSwitchStatus } = props;

  return (
    <CardItem>
      <SimpleText
        className="p-col p-wordbreak-all"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {motivo.nome}
      </SimpleText>

      <div className="p-col-fixed p-d-flex p-ai-center p-jc-end p-pl-0 fixed">
        <>
          <Switch
            checked={motivo?.status}
            showStatus
            onChange={() => onSwitchStatus(motivo)}
            checkPermission="ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_ALTERAR"
          />
          <Can
            checkPermission={[
              'ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_ALTERAR',
              'ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_EXCLUIR',
            ]}
          >
            <Divider layout="vertical" />
          </Can>

          <Button
            className="p-mr-2"
            btnType="gray"
            icon="pi pi-pencil"
            onClick={() => onEdit(motivo)}
            checkPermission="ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_ALTERAR"
          />

          <Button
            btnType="gray"
            icon="pi pi-trash"
            onClick={() => onDelete(motivo.id)}
            checkPermission="ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_EXCLUIR"
          />
        </>
      </div>
    </CardItem>
  );
};
