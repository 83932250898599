import { useState, useEffect, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import HospitalAPI from 'src/APIs/AdminAPI/HospitalAPI/HospitalAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';

import Operadores from './Operadores/Operadores';
import { validationSchema } from './validationSchema';

const FormHospital = () => {
  const navigate = useNavigate();
  const { id: idHospital } = useParams();

  const [loadingData, setLoadingData] = useState(false);
  const [exibirModalDeConfirmacao, setExibirModalDeConfirmacao] =
    useState<boolean>(false);
  const [editHospitalConvenios, setEditHospitalConvenios] = useState<
    any[] | undefined
  >();

  const { user } = useAppSelector(state => state);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useFormMethods;

  const hospitalConveniosWatch = watch('hospitalConvenios');

  const fetchHospital = useCallback(async () => {
    if (idHospital) {
      setLoadingData(true);

      const response = await HospitalAPI.loadHospitalById(Number(idHospital));

      if (response.id) {
        const hospitalConvenios =
          response?.hospitalConvenios?.map(
            ({ codigoConvenio, convenio }: any) => ({
              codigoConvenio,
              convenio,
            }),
          ) || [];

        setEditHospitalConvenios(response?.hospitalConvenios);

        reset({
          ...response,
          hospitalConvenios,
        });
      }

      setLoadingData(false);
    }
  }, [idHospital, reset]);

  useEffect(() => {
    fetchHospital();
  }, [fetchHospital]);

  const handleGoBack = () => {
    navigate('/cadastros/hospital', { replace: true });
  };

  const saveHospital = (payload: any) => {
    if (idHospital)
      return HospitalAPI.updateHospital(Number(idHospital), payload);

    return HospitalAPI.sendHospital(payload);
  };

  const onSubmit = async (data: any) => {
    const hospitalConvenios = data.hospitalConvenios.map(
      ({ codigoConvenio, convenio, idsTiposPlanosHospital }: any) => ({
        codigoConvenio,
        idConvenio: convenio.id,
        idsTiposPlanosHospital,
      }),
    );

    const payload = {
      ...data,
      hospitalConvenios: hospitalConvenios,
      idEmpresa: user?.idEmpresa,
    };
    const response = await saveHospital(payload);

    if (response?.status === 200 || response?.status === 201) {
      handleGoBack();
    }
  };

  if (loadingData) return <GridListLoading />;

  return (
    <>
      <FormProvider {...useFormMethods}>
        <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
          <TextInputControlled
            className="p-col-12"
            control={control}
            name="nome"
            label="Nome"
            placeholder="Nome do hospital"
            errorMsg={errors.nome?.message}
          />

          <MaskedInputControlled
            className="p-col-12"
            control={control}
            name="cnpj"
            label="CNPJ"
            placeholder="CNPJ do hospital"
            mask={MASK.CNPJ}
            errorMsg={errors.cnpj?.message}
          />

          <Operadores editHospitalConvenios={editHospitalConvenios} />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              type="button"
              btnType="ghost"
              onClick={() => setExibirModalDeConfirmacao(true)}
              label="Cancelar"
              loading={isSubmitting}
              stretch
            />
            <Button
              type="submit"
              disabled={!hospitalConveniosWatch?.length}
              label="Salvar"
              loading={isSubmitting}
              stretch
            />
          </div>
        </form>
      </FormProvider>

      <Dialog
        visible={exibirModalDeConfirmacao}
        header="Sair sem salvar"
        onHide={() => setExibirModalDeConfirmacao(false)}
      >
        <div className="modal-confimacao">
          <p className="modal-confimacao__text">
            Você tem certeza que deseja sair e descartar todas as edições feitas
            no cadastro?
          </p>
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              onClick={() => setExibirModalDeConfirmacao(false)}
              label="Não, cancelar"
              stretch
            />
            <Button
              btnType="danger"
              onClick={handleGoBack}
              label="Sim, sair"
              stretch
            />
          </div>
        </div>
      </Dialog>

      <AlertBox
        visible={!hospitalConveniosWatch?.length}
        text="Necessário adicionar ao menos uma operadora."
      />
    </>
  );
};

export default FormHospital;
