import { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useCreateProfissionalSaude } from 'src/core/hooks/ManterUsuario/useCreateProfissionalSaude';
import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useOptionalData } from 'src/core/hooks/ManterUsuario/useOptionalData';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';
import { useUserAccessData } from 'src/core/hooks/ManterUsuario/useUserAccessData';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
} from 'src/components/Timeline/Timeline';

import MandatoryUserData from './MandatoryUserData/MandatoryUserData';
import OptionalData from './OptionalData/OptionalData';
import ProfessionalHealthProfessionalInfoStep from './ProfissionalSaude/DadosProfissionais/DadosProfissionais';
import ProfessionalHealthProfessionalStep from './ProfissionalSaude/ProfissionalExecutante/ProfissionalExecutante';
import UserAccessData from './UserAccessData/UserAccessData';

import './ManterUsuario.scss';

const tiposUsuario = [
  {
    label: 'Secretariado',
    value: 'SECRETARIO',
    acronym: 'Se',
    name: 'secretariado',
  },
  {
    label: 'Profissional de saúde',
    value: 'PROFISSIONAL_SAUDE',
    acronym: 'Ps',
    name: 'medicina',
  },
  {
    label: 'Administrador',
    value: 'ADMINISTRADOR',
    acronym: 'Ad',
    name: 'enfermagem',
  },
  {
    label: 'Master',
    value: 'MASTER',
    acronym: 'Ma',
    name: 'master',
  },
];
const ManterUsuario = () => {
  const { isProfessionalHealth, selectProfile } = useSelectProfileCreateUser();
  const { accessStepCompleted, saveAccessData } = useUserAccessData();
  const { idUser, mandatoryData, setMandatoryData, setIdUser } =
    useCreateUser();
  const { saveAddress, saveContact } = useOptionalData();
  const {
    checkedProfissionalExecutante,
    dadosProfissionaisStepCompleted,
    profExecutanteStepCompleted,
    setDadosProfissionaisStepCompleted,
    setDadosProfissionais,
    setCheckedProfissionalExecutante,
    setProfExecutanteStepCompleted,
  } = useCreateProfissionalSaude();

  const navigate = useNavigate();

  const params = useParams();
  const { user } = useAppSelector((state: RootState) => state);

  const [namepage, setNamepage] = useState<any>('');
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const timelineRef = useRef<TimelineImperativeAPI>(null);

  function formatDate(date: string) {
    if (!date) return null;

    const dateFormmated = date.replace('-', '/');

    return new Date(dateFormmated);
  }

  const loadPage = useCallback(async () => {
    //TODO após carregar o usuario setar os steps com os dados que vieram da api
    if (params.id) {
      setIsLoading(true);
      const id = parseInt(params.id);
      const userEdit: any = await UsuarioAPI.loadUsuarioById(id);

      if (!userEdit?.id) return navigate('/cadastros/usuarios');

      const userProfile = tiposUsuario.find(
        type => type.value === userEdit?.tipo,
      );

      if (userProfile) selectProfile(userProfile);

      const {
        login,
        perfis,
        restringirBloqueioAps,
        restringirBloqueioHorario,
        profissionalSaude,
      } = userEdit;

      let customization: any = {
        restringirBloqueioAps,
        restringirBloqueioHorario,
      };

      if (userProfile?.value === 'PROFISSIONAL_SAUDE') {
        const {
          ocultarCid,
          ocultarCiap,
          habilitaObrigarMotivoAtendimento,
          profissionalNaoCooperado,
          transacaoTissPessoaJuridica,
          confirmacaoCartao,
          autorizarSemCartao,
          cuidador,
          cuidadorViverBem,
          profissionalCuidadorAgendamentoOnline,
          naoRealizaTransacaoTiss,
          habilitarNotificacaoAvaliacaoContrarreferencia,
          habilitaEnvioSms,
          habilitarAlertaUploadExameAgendamentoOnline,
          atendimentoRetornoPadrao,
          prontuarioRestrito,
          atendeObstetricia,
          exibirFolhaRostoProntuario,
          executaPrescricao,
        } = profissionalSaude;

        customization = {
          ...customization,
          ocultarCid,
          ocultarCiap,
          habilitaObrigarMotivoAtendimento,
          profissionalNaoCooperado,
          transacaoTissPessoaJuridica,
          confirmacaoCartao,
          autorizarSemCartao,
          cuidador,
          cuidadorViverBem,
          profissionalCuidadorAgendamentoOnline,
          naoRealizaTransacaoTiss,
          habilitarNotificacaoAvaliacaoContrarreferencia,
          habilitaEnvioSms,
          habilitarAlertaUploadExameAgendamentoOnline,
          atendimentoRetornoPadrao,
          prontuarioRestrito,
          atendeObstetricia,
          exibirFolhaRostoProntuario,
          executaPrescricao,
        };
      }

      for (const customItem in customization) {
        customization[customItem] = customization[customItem] || false;
      }

      const accessData = {
        login,
        perfis,
        customization,
      };

      saveAccessData(accessData);

      if (userEdit?.enderecos?.length) saveAddress(userEdit.enderecos[0]);
      if (userEdit?.contatos?.length) saveContact(userEdit.contatos);

      if (userEdit?.dadosPessoais?.dataNascimento) {
        userEdit.dadosPessoais.dataNascimento = formatDate(
          userEdit.dadosPessoais.dataNascimento,
        );
      }

      setIdUser(Number(params?.id));
      setMandatoryData(userEdit);

      if (userProfile?.value === 'PROFISSIONAL_SAUDE') {
        setDadosProfissionaisStepCompleted(true);

        const dadosProfissionais = await ProfissionalAPI.getProfissional(
          params?.id,
          'dadosProfissionais',
        );
        setDadosProfissionais(dadosProfissionais);
        setCheckedProfissionalExecutante(
          dadosProfissionais?.cadastrarProfissionalExecutante || false,
        );
        setProfExecutanteStepCompleted(
          dadosProfissionais?.cadastrarProfissionalExecutante || false,
        );
      }

      setNamepage('Alterar usuário');
      setIsLoading(false);
    } else {
      setNamepage('Novo usuário');
    }
  }, [params.id]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const backTab = () => {
    if (timelineRef && timelineRef.current && timelineRef.current.previous) {
      timelineRef.current?.previous();
    }
  };

  function nextStep() {
    if (timelineRef && timelineRef.current && timelineRef.current.next) {
      timelineRef.current?.next();
    }
  }

  function handleChangeStep(tabIndex: number) {
    setActiveStep(tabIndex);
  }

  const masterCreateUserSteps = useCallback(() => {
    let options = [];

    options = [
      {
        title: 'Perfil de usuário e acesso',
        component: (
          <UserAccessData nextStep={nextStep} isActive={activeStep === 0} />
        ),
      },
      {
        title: 'Dados obrigatórios',
        component: (
          <MandatoryUserData handleBack={backTab} nextStep={nextStep} />
        ),
        disabled: !accessStepCompleted,
      },
    ];

    if (isProfessionalHealth) {
      options.push({
        title: 'Informações profissionais',
        disabled: !idUser,
        component: (
          <ProfessionalHealthProfessionalInfoStep
            handleBack={backTab}
            nextStep={nextStep}
          />
        ),
      });
    }

    if (checkedProfissionalExecutante) {
      options.push({
        title: 'Profissional executante',
        component: (
          <ProfessionalHealthProfessionalStep
            handleBack={backTab}
            nextStep={nextStep}
          />
        ),
        disabled: !dadosProfissionaisStepCompleted,
      });
    }

    options.push({
      title: 'Dados opcionais',
      disabled: !(
        (!isProfessionalHealth && idUser) ||
        profExecutanteStepCompleted ||
        (!checkedProfissionalExecutante && dadosProfissionaisStepCompleted)
      ),
      component: <OptionalData handleBack={backTab} />,
    });
    return options;
  }, [
    accessStepCompleted,
    isProfessionalHealth,
    checkedProfissionalExecutante,
    profExecutanteStepCompleted,
    idUser,
    dadosProfissionaisStepCompleted,
    activeStep,
  ]);

  if (isLoading) {
    return (
      <Page className="ManterUsuario">
        <>
          <PageHeader
            title={namepage}
            subtitle="Preencha os campo para completar o cadastro"
          />
          <Page content white>
            <GridListLoading />
          </Page>
        </>
      </Page>
    );
  }

  if (!user?.idEmpresa && !!mandatoryData?.idEmpresa) {
    return (
      <Page className="ManterUsuario">
        <>
          <PageHeader
            title={namepage}
            subtitle="Preencha os campo para completar o cadastro"
          />
          <Page content white>
            <AlertBox
              visible={true}
              text="Você precisa selecionar um Cliente para esta funcionalidade!"
            />
          </Page>
        </>
      </Page>
    );
  }

  return (
    <Page className={'ManterUsuario'}>
      <>
        <PageHeader
          title={namepage}
          subtitle={'Preencha os campo para completar o cadastro'}
        />
        <Page content white>
          <Timeline
            values={masterCreateUserSteps()}
            ref={timelineRef}
            onChange={handleChangeStep}
          />
        </Page>
      </>
    </Page>
  );
};

export default ManterUsuario;
