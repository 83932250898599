import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import PuericulturaAPI from 'src/APIs/ProntuarioAPI/PuericulturaAPI/PuericulturaAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';

import './Observacoes.scss';

const Observacoes = () => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus } = useAtendimento();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const getObservacao = useCallback(async () => {
    if (!idAtendimento) return;

    const response = await PuericulturaAPI.getObservacao(Number(idAtendimento));

    if (response.id) {
      setValue('observacao', response.observacao);
    }
  }, [idAtendimento, setValue]);

  useEffect(() => {
    getObservacao();
  }, [getObservacao]);

  const onSubmit = async (data: any) => {
    await PuericulturaAPI.postObservacao(Number(idAtendimento), data);
  };

  return (
    <ElevatedCard noMargin className="container-observacoes">
      <SimpleText
        fontSize={FONT_SIZE.MD}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Observações gerais
      </SimpleText>

      <form className="p-mt-5">
        <fieldset disabled={!isAtendimentoRunning}>
          <TextareaInputControlled
            label="Observações"
            name="observacao"
            control={control}
          />

          <Button
            className="p-mt-5"
            label="Salvar"
            btnType="tonal"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
            disabled={!isAtendimentoRunning}
            stretch
          />
        </fieldset>
      </form>
    </ElevatedCard>
  );
};

export default Observacoes;
