import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './ModalListaComorbidades.scss';

interface ModalListaComorbidadesProps {
  modalLista: Comorbidade[];
  onHide: () => void;
}

function ModalListaComorbidades({
  modalLista,
  onHide,
}: ModalListaComorbidadesProps) {
  return (
    <Dialog
      type="modal"
      header={`Lista de problemas ativas (${modalLista.length})`}
      visible={!!modalLista.length}
      onHide={onHide}
      maximizedTabletSize
    >
      <>
        <div id="prontuario-header-lista-container">
          <div className="p-mb-2">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Código - Descrição da CID
            </SimpleText>
          </div>

          <div className="p-grid">
            {modalLista.map((comorbidade: Comorbidade) => (
              <div
                key={comorbidade.id}
                className="card-modal-lista p-grid p-col-12"
              >
                <div className="p-col-12 p-sm-8">
                  <Tooltip target={`.${comorbidade.cid?.[0]?.cid?.codigo}`} />
                  <SimpleText
                    className={comorbidade.cid?.[0]?.cid?.codigo}
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                    data-pr-tooltip={comorbidade.cid?.[0]?.cid?.nome}
                    data-pr-position="top"
                  >
                    {comorbidade.cid?.[0]?.cid?.codigo} -{' '}
                    {comorbidade.cid?.[0]?.cid?.nome}
                  </SimpleText>
                </div>
                <SimpleText
                  className="p-col-12 p-sm-4 p-wordbreak-normal"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  Adicionado em{' '}
                  {dayjs(comorbidade.dataInclusao).format(
                    'DD/MM/YYYY [às] HH:mm',
                  )}
                </SimpleText>
              </div>
            ))}
          </div>
        </div>

        <Button
          className="p-mt-4"
          btnType="outline"
          label="Fechar"
          onClick={onHide}
          stretch
        />
      </>
    </Dialog>
  );
}

export default ModalListaComorbidades;
