import React, { useState } from 'react';

import dayjs from 'dayjs';
import { GuiaTissAPI } from 'src/APIs/ReportAPI/GuiaTissAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Solicitacao } from './utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { ModalUploadLaudo } from './ModalUploadLaudo/ModalUploadLaudo';
import { ModalVisualizarLaudo } from './ModalVisualizarLaudo/ModalVisualizarLaudo';

export const SolicitacoesResultadosExamesRow = ({
  item,
  reload,
}: {
  item: Solicitacao;
  reload(): void;
}) => {
  const { isTablet } = useSize();
  const [isPrinting, setPrinting] = useState(false);

  const uploadLaudoModal = useDisclosure({ opened: false });
  const viewLaudoModal = useDisclosure({ opened: false });

  const handlePrintGuias = async () => {
    setPrinting(true);
    try {
      const response = await GuiaTissAPI.getGuiaTissPdf(
        Number(item.solicitacoes?.[0]?.atendimento?.id),
        { throwError: true },
      );

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);
    } catch (error) {
    } finally {
      setPrinting(false);
    }
  };

  return (
    <div className="p-grid content-row">
      <div className="p-col-2 p-xl-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Numero de guia
        </SimpleText>
        <SimpleText>{item.idGuiaTiss}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Profissional de saúde
        </SimpleText>
        <SimpleText>{item.nomeProfissionalSaude}</SimpleText>
      </div>
      <div className="p-col-3 p-d-flex p-ai-end p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data da solicitação
        </SimpleText>
        <SimpleText>
          {dayjs(item.dataSolicitacao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-jc-end">
        <Button
          label="Guia Tiss"
          icon="fas fa-file"
          btnType="tonal"
          onClick={handlePrintGuias}
          loading={isPrinting}
        />
      </div>

      <div className="content-row-info p-col-12 p-d-flex p-flex-column p-gap-2">
        {!!item.solicitacoes?.length &&
          item.solicitacoes.map((solic, idx) => (
            <div className="p-d-flex" key={idx}>
              <div className="p-col-2 p-xl-1 p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  Código TUSS
                </SimpleText>
                <SimpleText fontColor="color_60" medium>
                  {solic.codigoProcedimento}
                </SimpleText>
              </div>
              <div className="p-col p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  Descrição
                </SimpleText>
                <SimpleText fontColor="primary" className="p-text-climp-3">
                  {solic.descricaoProcedimento}
                </SimpleText>
              </div>
              <div className="p-col-2 p-lg-1 p-ai-center p-ai-lg-end p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  {isTablet ? 'Qtde' : 'Quantidade'}
                </SimpleText>
                <SimpleText>{solic.quantidade}</SimpleText>
              </div>
              <div className="p-col-1 p-d-flex p-ai-end p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  Resultado
                </SimpleText>
                <SimpleText>{solic.resultadoInformado}</SimpleText>
              </div>
              <div className="p-col-2 p-xl-1 p-d-flex p-jc-end">
                <Button
                  label="Laudo"
                  icon="fas fa-paperclip"
                  btnType="tonal"
                  onClick={() =>
                    !!solic.resultadoExame?.laudoArquivo
                      ? viewLaudoModal.open({
                          state: {
                            laudoArquivo: solic.resultadoExame?.laudoArquivo,
                          },
                        })
                      : uploadLaudoModal.open({
                          state: {
                            idAtendimento: solic.idAtendimento,
                            idProcedimento: solic.idSolicitacaoProcedimento,
                            isProcedimentoExterno: solic.solicitacaoProcExt,
                            onSuccess: reload,
                          },
                        })
                  }
                  style={{
                    maxHeight: '35px',
                  }}
                />
              </div>
            </div>
          ))}
      </div>

      {viewLaudoModal.isOpen && <ModalVisualizarLaudo {...viewLaudoModal} />}
      {uploadLaudoModal.isOpen && <ModalUploadLaudo {...uploadLaudoModal} />}
    </div>
  );
};

export const SolicitacoesResultadosExamesRowMobile = ({
  item,
  reload,
}: {
  item: Solicitacao;
  reload(): void;
}) => {
  const { isTablet } = useSize();
  const [isPrinting, setPrinting] = useState(false);

  const uploadLaudoModal = useDisclosure({ opened: false });
  const viewLaudoModal = useDisclosure({ opened: false });

  const handlePrintGuias = async () => {
    setPrinting(true);
    try {
      const response = await GuiaTissAPI.getGuiaTissPdf(
        Number(item.solicitacoes?.[0]?.atendimento?.id),
        { throwError: true },
      );

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);
    } catch (error) {
    } finally {
      setPrinting(false);
    }
  };

  return (
    <div className="p-grid content-row">
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Numero de guia
        </SimpleText>
        <SimpleText>{item.idGuiaTiss}</SimpleText>
      </div>
      <div className="p-col-8 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Profissional de saúde
        </SimpleText>
        <SimpleText>{item.nomeProfissionalSaude}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data da solicitação
        </SimpleText>
        <SimpleText>
          {dayjs(item.dataSolicitacao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <Button
        className="p-col-12 p-my-3"
        label="Guia Tiss"
        icon="fas fa-file"
        btnType="tonal"
        onClick={handlePrintGuias}
        loading={isPrinting}
      />

      <div className="content-row-info p-col-12 p-grid">
        {!!item.solicitacoes?.length &&
          item.solicitacoes.map((solic, idx) => (
            <React.Fragment key={idx}>
              <div className="p-col-3 p-xl-1 p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  Código TUSS
                </SimpleText>
                <SimpleText fontColor="color_60" medium>
                  {solic.codigoProcedimento}
                </SimpleText>
              </div>
              <div className="p-col-9 p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  Descrição
                </SimpleText>
                <SimpleText fontColor="primary" className="p-text-climp-3">
                  {solic.descricaoProcedimento}
                </SimpleText>
              </div>
              <div className="p-col-6 p-lg-1 p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  {isTablet ? 'Qtde' : 'Quantidade'}
                </SimpleText>
                <SimpleText>{solic.quantidade}</SimpleText>
              </div>
              <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
                <SimpleText fontColor="color_60" fontSize="xxs">
                  Resultado
                </SimpleText>
                <SimpleText>{solic.resultadoInformado}</SimpleText>
              </div>
              <div className="p-col-12 p-d-flex ">
                <Button
                  stretch
                  label="Laudo"
                  icon="fas fa-paperclip"
                  btnType="tonal"
                  style={{
                    maxHeight: '35px',
                  }}
                  onClick={() =>
                    !!solic.resultadoExame?.laudoArquivo
                      ? viewLaudoModal.open({
                          state: {
                            laudoArquivo: solic.resultadoExame?.laudoArquivo,
                          },
                        })
                      : uploadLaudoModal.open({
                          state: {
                            idAtendimento: solic.idAtendimento,
                            idProcedimento: solic.idSolicitacaoProcedimento,
                            isProcedimentoExterno: solic.solicitacaoProcExt,
                            onSuccess: reload,
                          },
                        })
                  }
                />
              </div>
            </React.Fragment>
          ))}
      </div>

      {viewLaudoModal.isOpen && <ModalVisualizarLaudo {...viewLaudoModal} />}
      {uploadLaudoModal.isOpen && <ModalUploadLaudo {...uploadLaudoModal} />}
    </div>
  );
};
