import { PanelHeaderTemplateOptions } from 'primereact/panel';
import { useLocation, useParams } from 'react-router';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { ModalFormGrupo } from '../../components/ModalFormGrupo/ModalFormGrupo';
import { Button } from 'src/components/_UI/Button';
import CheckboxList from 'src/components/Basics/Checkbox/Checkbox';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Panel from 'src/components/Panel/Panel';

import { filtroTiposBuscaRapidaOptions } from '../../helpers';
import { useBuscaRapidaFavoriteChanged } from '../hooks/useBuscaRapidaFavoriteChange';
import { useBuscaRapidaFetchProcedimentos } from '../hooks/useBuscaRapidaFetchProcedimentos';
import { useBuscaRapidaFiltros } from '../hooks/useBuscaRapidaFiltros';
import { useBuscaRapidaSelecionados } from '../hooks/useBuscaRapidaSelecionados';
import { useBuscaRapidaSubmit } from '../hooks/useBuscaRapidaSubmit';

import { BuscaRapidaContent } from './BuscaRapidaContent';
import { BuscaRapidaList } from './BuscaRapidaList';
import { BuscaRapidaPanelHeader } from './BuscaRapidaPanelHeader';

import './BuscaRapida.scss';

interface BuscaRapidaProps {
  favoriteProcedimentoChanged: any;
  selectedConvenioId: number | null;
}

export const BuscaRapida = (props: BuscaRapidaProps) => {
  const { favoriteProcedimentoChanged } = props;

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const modalFormGrupo = useDisclosure({ opened: false });

  const { typeFilter, handleChangeTypeFilter } = useBuscaRapidaFiltros();

  const {
    listaProcedimentosFilteredBySearchQuery,
    loadingFetch,
    query,
    fetchProcedimentos,
    setQuery,
    setProcedimentos,
    handleRemoveProcedimentoFromList,
  } = useBuscaRapidaFetchProcedimentos({
    idAtendimento: Number(idAtendimento),
    typeFilter,
  });

  const {
    procedimentosSelected,
    handleRemoveProcedimentoFromSelectedList,
    setProcedimentosSelected,
  } = useBuscaRapidaSelecionados();

  const { isSubmitting, handleAddProcedimentos } = useBuscaRapidaSubmit({
    idAtendimento: Number(idAtendimento),
    procedimentosSelected,
    setProcedimentosSelected,
  });

  const handleRemoveProcedimento = (idProcedimento: number) => {
    handleRemoveProcedimentoFromList(idProcedimento);
    handleRemoveProcedimentoFromSelectedList(idProcedimento);
  };

  useBuscaRapidaFavoriteChanged({
    favoriteProcedimentoChanged,
    handleRemoveProcedimento,
    setProcedimentos,
  });

  const handleOpenModalFormGrupo = () => modalFormGrupo.open();

  const renderBuscaRapidaPanelHeader = (
    options: PanelHeaderTemplateOptions,
  ) => (
    <BuscaRapidaPanelHeader
      options={options}
      onCreateGrupo={handleOpenModalFormGrupo}
    />
  );

  const handleGrupoEdit = (grupoId: number) =>
    modalFormGrupo.open({ state: grupoId });

  return (
    <>
      <Panel
        id="busca-rapida-container"
        headerTemplate={renderBuscaRapidaPanelHeader}
        toggleable
      >
        <CheckboxList
          classNameContainer="filtro-tipos-busca-rapida"
          name="tiposBuscaRapida"
          value={typeFilter}
          options={filtroTiposBuscaRapidaOptions}
          onChange={handleChangeTypeFilter}
        />

        <div className="p-mb-3">
          <TextInputSearch
            placeholder="Pesquise"
            value={query}
            onChange={setQuery}
          />
        </div>

        <BuscaRapidaContent
          loadingFetch={loadingFetch}
          listaProcedimentosFilteredBySearchQuery={
            listaProcedimentosFilteredBySearchQuery
          }
          typeFilter={typeFilter}
        >
          <BuscaRapidaList
            listaProcedimentosFilteredBySearchQuery={
              listaProcedimentosFilteredBySearchQuery
            }
            handleGrupoEdit={handleGrupoEdit}
            procedimentosSelected={procedimentosSelected}
            setProcedimentosSelected={setProcedimentosSelected}
            handleRemoveGrupoFromList={handleRemoveProcedimento}
          />
        </BuscaRapidaContent>

        <Button
          className="p-mt-2"
          type="button"
          label="Adicionar selecionados"
          btnType="tonal"
          onClick={handleAddProcedimentos}
          loading={isSubmitting}
          disabled={
            !procedimentosSelected.length || atendimentoStatus !== 'ATENDENDO'
          }
          stretch
        />
      </Panel>

      {modalFormGrupo.isOpen && (
        <ModalFormGrupo {...modalFormGrupo} onSuccess={fetchProcedimentos} />
      )}
    </>
  );
};
