import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import AlergiaAPI from 'src/APIs/ProntuarioAPI/AlergiaAPI/AlergiaAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { FieldMedicamentos } from '../components/FieldMedicamentos/FieldMedicamentos';
import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import './ModalNovaAlergia.scss';

interface ModalNovaAlergiaProps {
  visible: boolean;
  editarAlergia: AlergiaDTO | null;
  onHide: () => void;
  onSuccess: () => void;
}

function ModalNovaAlergia({
  visible,
  editarAlergia,
  onHide,
  onSuccess,
}: ModalNovaAlergiaProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const { user } = useAppSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    descricao: Yup.string().required('informe a descrição'),
    tipo: Yup.string().required('Informe o tipo').nullable(),
    categoria: Yup.string().required('Informe a categoria'),
    dataInicio: Yup.date()
      .nullable()
      .required('informe a data inicio')
      .when('dataFim', {
        is: (dataFim: Date) => dataFim,
        then: Yup.date().max(
          Yup.ref('dataFim'),
          'Data início deve ser menor que data fim',
        ),
      }),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(editarAlergia?.dataInclusao),
    principioAtivo: Yup.object().when('tipo', (tipo, field) => {
      return tipo === 'MEDICAMENTOS'
        ? field.required('Informe o princípio ativo')
        : field;
    }),
  });

  const { formState, ...form } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    form.reset();
    form.setValue('categoria', 'ALERGIA');
    form.setValue('situacao', 'EM_TRATAMENTO');
    if (editarAlergia) {
      form.setValue(
        'justificativaOcorrenciaRetroativa',
        editarAlergia.justificativaOcorrenciaRetroativa,
      );
      form.setValue('categoria', editarAlergia.categoria);
      form.setValue('descricao', editarAlergia.descricao);
      form.setValue('dataOcorrencia', editarAlergia.dataOcorrencia);
      form.setValue(
        'tipo',
        editarAlergia.categoria === 'ALERGIA'
          ? editarAlergia.tipoAlergia
          : editarAlergia.tipoIntolerancia,
      );
      form.setValue('dataInicio', new Date(editarAlergia.dataInicio));
      if (editarAlergia?.dataFim)
        form.setValue('dataFim', new Date(editarAlergia.dataFim));
      form.setValue('situacao', editarAlergia.situacao);

      form.setValue('principioAtivo', {
        id: editarAlergia?.idPrincipioAtivoMemed,
        nome: editarAlergia?.principioAtivo,
      });

      form.setValue('lembrete', editarAlergia.lembrete);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editarAlergia]);

  const saveAction = useCallback(
    async (payload: any) => {
      if (!editarAlergia) return await AlergiaAPI.saveAlergia(payload);

      return (
        idAtendimento &&
        (await AlergiaAPI.updateAlergia(
          editarAlergia?.id,
          +idAtendimento,
          payload,
        ))
      );
    },
    [editarAlergia, idAtendimento],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      setSubmitting(true);

      const { tipo, ...rest } = data;

      const payload = {
        ...rest,
        idAtendimento: Number(idAtendimento),
        idPrincipioAtivoMemed: data?.principioAtivo?.id || null,
        principioAtivo: data?.principioAtivo?.nome || null,
        idEmpresa: user?.idEmpresa || 1,
        tipoAlergia: data?.categoria === 'ALERGIA' ? data.tipo : null,
        tipoIntolerancia: data?.categoria === 'INTOLERANCIA' ? data.tipo : null,
      };

      const response = await saveAction(payload);

      if (response?.status === 200 || response?.status === 201) {
        dispatch(setInvalidateQuery({ invalidateMemed: true }));
        onSuccess();
        onHide();
      }

      setSubmitting(false);
    },
    [dispatch, idAtendimento, onHide, onSuccess, saveAction, user.idEmpresa],
  );

  const renderFooter = useCallback(
    () => (
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          btnType="ghost"
          label="Cancelar"
          className="p-col-align-center"
          onClick={onHide}
          stretch
          loading={submitting}
        />
        <Button
          type="submit"
          label="Salvar"
          onClick={form.handleSubmit(onSubmit)}
          loading={submitting}
          stretch
        />
      </div>
    ),
    [onHide, submitting, form, onSubmit],
  );

  const watchCategoria = form.watch('categoria');
  const watchDataInicio = form.watch('dataInicio');
  const watchDataFim = form.watch('dataFim');
  const watchTipo = form.watch('tipo');

  const tipoAlergiaIntolerancia = useMemo(() => {
    const intolerancias = [
      'LACTOSE',
      'GLUTEN',
      'FRUTOSE',
      'SACAROSE',
      'MILHO',
      'LEVEDURA',
    ];
    const alergias = [
      'MEDICAMENTOS',
      'ALIMENTAR',
      'ALERGIAS_DIVERSAS',
      'CONTRAINDICACOES_DIVERSAS',
      'AMBIENTAL',
      'ANIMAL',
      'PLANTAS',
      'POLEN',
    ];

    return (watchCategoria === 'ALERGIA' ? alergias : intolerancias)?.map(
      viaMedicamento => ({
        label: viaMedicamento,
        value: viaMedicamento,
      }),
    );
  }, [watchCategoria]);

  const checkDisabledOnEdit = useMemo(() => {
    if (!editarAlergia) return false;

    return !(
      editarAlergia.idAtendimento === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [atendimentoStatus, editarAlergia, idAtendimento]);

  return (
    <Dialog
      id="modal-novo-medicamento"
      type="modal"
      header={
        editarAlergia
          ? 'Editar alergia ou intolerância'
          : 'Nova alergia ou intolerância'
      }
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
      maximizedTabletSize
    >
      <FormProvider formState={formState} {...form}>
        <form id="form-medicamento-uso" className="p-grid">
          <DataOcorrencia
            control={form.control}
            defaultValue={
              editarAlergia
                ? new Date(editarAlergia.dataOcorrencia)
                : new Date()
            }
            dataInclusao={editarAlergia?.dataInclusao}
            disabled={checkDisabledOnEdit}
          />
          <div className="p-col-6 p-sm-6">
            <RadioButtonControlled
              control={form.control}
              name="categoria"
              label="Alergia"
              value={'ALERGIA'}
              customOnChange={() => form.setValue('tipo', null)}
              disabled={checkDisabledOnEdit}
            />
          </div>
          <div className="p-col-6 p-sm-6">
            <RadioButtonControlled
              control={form.control}
              name="categoria"
              label="Intolerância"
              value={'INTOLERANCIA'}
              customOnChange={() => form.setValue('tipo', null)}
              disabled={checkDisabledOnEdit}
            />
          </div>

          <TextareaInputControlled
            className=" p-col-12"
            control={form.control}
            name="descricao"
            label="Descrição (Obrigatório)"
            errorMsg={formState.errors.descricao?.message}
            disabled={checkDisabledOnEdit}
            maxLength={255}
          />

          <DropdownControlled
            className="p-sm-12"
            control={form.control}
            name="tipo"
            label="Tipo"
            placeholder="Selecione..."
            options={tipoAlergiaIntolerancia}
            errorMsg={formState.errors.tipo?.message}
            disabled={checkDisabledOnEdit}
          />

          {watchCategoria === 'ALERGIA' && watchTipo === 'MEDICAMENTOS' && (
            <FieldMedicamentos checkDisabledOnEdit={checkDisabledOnEdit} />
          )}

          <CalendarInputControlled
            className="p-col-12 p-sm-6"
            control={form.control}
            name="dataInicio"
            label="Data início"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            maxDate={new Date()}
            disabled={checkDisabledOnEdit}
            errorMsg={formState.errors.dataInicio?.message}
            showIcon
          />

          <CalendarInputControlled
            className="p-col-12 p-sm-6"
            control={form.control}
            name="dataFim"
            label="Data fim"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={watchDataInicio}
            errorMsg={formState.errors.dataFim?.message}
            showIcon
          />

          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Situação
          </SimpleText>

          <div className="p-sm-4">
            <RadioButtonControlled
              control={form.control}
              name="situacao"
              label="Em tratamento"
              value={'EM_TRATAMENTO'}
            />
          </div>
          <div className="p-sm-4">
            <RadioButtonControlled
              control={form.control}
              name="situacao"
              label="Resolvida"
              value={'RESOLVIDA'}
            />
          </div>
          <div className="p-sm-4">
            <RadioButtonControlled
              control={form.control}
              name="situacao"
              label="Controlada"
              value={'CONTROLADA'}
            />
          </div>
          <div className="p-col-12">
            <CheckboxControlled
              className="checkbox-small-nova-alergia"
              control={form.control}
              name="lembrete"
              label='Exibir em "lembretes"'
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default ModalNovaAlergia;
