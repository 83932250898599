import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { checkAuth } from 'src/utils/utils';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';

import CardDesktop from './CardDesktop';
import CardMobile from './CardMobile';

interface CardPacienteProps {
  paciente: any;
  onExcluir: (idPaciente: number) => void;
  refetchList: (state: any) => void;
}

const CardPaciente = ({
  paciente,
  onExcluir,
  refetchList,
}: CardPacienteProps) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const { isMobile } = useSize();
  const { inativarPaciente, ativarPaciente } = PacienteAPI;

  const menuEllipsis = useRef<TieredMenu>(null);

  const navigate = useNavigate();

  const pacienteStatus = !!paciente.status;

  const handleInativarPaciente = async () => {
    const queryFc = pacienteStatus ? inativarPaciente : ativarPaciente;

    const response = await queryFc(paciente?.id);

    if (response?.status === 200) {
      refetchList((current: number) => current + 1);
    }
  };

  const menuEllipsisItens = [
    {
      label: 'Visualizar',
      command: () => navigate(`/pacientes/visualizar/${paciente.id}`),
    },
    {
      label: 'Editar',
      command: () => navigate(`/pacientes/${paciente.id}`),
      checkPermission: 'ADM_PACIENTE_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: (e: any) => [
        onExcluir(paciente.id),
        menuEllipsis.current?.toggle(e),
      ],
      checkPermission: 'ADM_PACIENTE_EXCLUIR',
    },
    {
      label: pacienteStatus ? 'Inativar' : 'Ativar',
      command: (e: any) => [
        handleInativarPaciente(),
        menuEllipsis.current?.toggle(e),
      ],
      checkPermission: 'ADM_PACIENTE_ALTERAR',
    },
  ];

  const menuItensFiltered = menuEllipsisItens.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  const renderCardAction = () => (
    <>
      <IconButton
        icon="fas fa-ellipsis-h"
        btnType={IconBtnTypes.GREY}
        onClick={event => menuEllipsis?.current?.toggle(event)}
      />

      <TieredMenu
        className="panel-options-paciente"
        ref={menuEllipsis}
        model={menuItensFiltered}
        popup
      />
    </>
  );

  return isMobile ? (
    <CardMobile paciente={paciente} cardAction={renderCardAction()} />
  ) : (
    <CardDesktop paciente={paciente} cardAction={renderCardAction()} />
  );
};

export default CardPaciente;
