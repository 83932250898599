import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Button from 'src/components/Basics/Button/Buttons';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './Home.scss';

import { useAssistenciaVirtual } from './hooks/useAssistenciaVirtual';

const Home = () => {
  const {
    agenda: { profissionalAtivo },
    consultorios: { ativo },
    user,
  } = useAppSelector((state: RootState) => state);

  const [listaPendencias] = useState([]);
  const [
    quantidadeProntuariosNaoAssinados,
    setQuantidadeProntuariosNaoAssinados,
  ] = useState(0);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual();

  const canSign = true;

  useEffect(() => {
    const getQuantidadeProntuariosNaoAssinados = async () => {
      const response = await AssinaturaAPI.getProntuariosNaoAssinados();
      setQuantidadeProntuariosNaoAssinados(response?.data?.totalCount);
    };
    if (user.tipoUsuario === 'PROFISSIONAL_SAUDE') {
      getQuantidadeProntuariosNaoAssinados();
    }
  }, [user.tipoUsuario]);

  return (
    <Page content className="Home">
      <div className="p-d-flex p-flex-column p-gap-2 p-w-100 ">
        <SimpleText fontSize={FONT_SIZE.MD} bold>
          Bom te ver novamente
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          {profissionalAtivo?.nome || ''}
        </SimpleText>

        <div className="p-mt-4">
          <SimpleText style={{ fontSize: '1.2rem' }} bold>
            {dayjs().format('DD [de] MMM [de] YYYY')}
            {', '}
            {ativo && `Consultório ${ativo?.nome}`}
          </SimpleText>
        </div>

        {user.tipoUsuario === 'PROFISSIONAL_SAUDE' ? (
          <div className="home-content-agendamentos">
            {canSign && (
              <>
                <Card
                  quantidade={quantidadeProntuariosNaoAssinados}
                  route="/pacientes/assinatura-prontuarios"
                  title="Prontuários pendentes de assinatura"
                  label="Assinar"
                />

                {listaPendencias.map((_, idx) => (
                  <div
                    key={idx}
                    className="card-agendamento p-d-flex p-flex-column p-gap-3"
                  >
                    <SimpleText
                      fontSize={FONT_SIZE.XL}
                      fontColor={FONT_COLOR.COLOR_40}
                    >
                      {idx}
                    </SimpleText>
                    <SimpleText
                      style={{ lineHeight: '26px' }}
                      fontSize={FONT_SIZE.MD}
                      bold
                    >
                      Titulo
                    </SimpleText>
                    <Button
                      label="Botão"
                      btnType="tonal"
                      stretch
                      className="p-mt-5"
                    />
                  </div>
                ))}
              </>
            )}

            <Card
              quantidade={10}
              route="/monitoramento/pendencias-tiss"
              title="Transações TISS pendentes"
            />
          </div>
        ) : null}

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </Page>
  );
};

const Card = ({
  quantidade,
  route,
  title,
  label = 'Acessar',
}: {
  quantidade: number;
  route: string;
  title: string;
  label?: string;
}) => {
  const navigate = useNavigate();

  return (
    <div className="card-agendamento p-d-flex p-flex-column p-gap-3">
      <SimpleText fontSize={FONT_SIZE.XL} fontColor={FONT_COLOR.COLOR_40}>
        {quantidade}
      </SimpleText>
      <SimpleText style={{ lineHeight: '26px' }} fontSize={FONT_SIZE.MD} bold>
        {title}
      </SimpleText>
      <Button
        label={label}
        btnType="tonal"
        stretch
        className="p-mt-5"
        onClick={() => navigate(route)}
      />
    </div>
  );
};

export default Home;
