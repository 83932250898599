import { useMemo } from 'react';

import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { useAgenda } from '../../AgendaContext';

interface DesktopRowProps {
  agendamento: any;
  setSelectedAgendamento: React.Dispatch<React.SetStateAction<any>>;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { dialogDetalhesAgendamento } = useAgenda();
  const { agendamento, setSelectedAgendamento } = props;

  const formatTelephone = useMemo(() => {
    const telefone = agendamento?.paciente?.telefonePrincipal || '';

    if (telefone.length !== 11) return telefone;

    return telefone.substring(0, 2) + ' ' + telefone.substring(2, 10);
  }, [agendamento?.paciente?.telefonePrincipal]);

  return (
    <div className="p-grid p-col-12 p-px-0 p-ai-center">
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {capitalizeFirstLetter(agendamento?.status).replaceAll('_', ' ')}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {dayjs(agendamento?.dataAgendamento).format('DD [de] MMM. [de] YYYY')}
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-mt-1"
        >
          {dayjs(agendamento?.dataAgendamento).format('[às] HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {agendamento?.nomePaciente}
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {formatTelephone}
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {agendamento?.plano}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-jc-between p-ai-center">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {agendamento?.profissionalSaude}
        </SimpleText>
        <IconButton
          type="button"
          btnType={IconBtnTypes.ICON_ONLY}
          icon="fa fa-chevron-right"
          onClick={() => {
            setSelectedAgendamento(agendamento);
            dialogDetalhesAgendamento.open({ state: agendamento });
          }}
        />
      </div>
    </div>
  );
};
