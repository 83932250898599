import { useCallback, useState } from 'react';

import { useLocation } from 'react-router';

import MotivoAtendimentoAPI from 'src/APIs/ProntuarioAPI/FichaPaciente/MotivoAtendimento/MotivoAtendimentoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import Panel from '../../components/Panel/Panel';
import PanelOptionsMenu from '../../components/PanelOptionsMenu/PanelOptionsMenu';
import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import AdicionarMotivo from './AdicionarMotivo/AdicionarMotivo';
import InativarMotivo from './InativarMotivo/InativarMotivo';
import ModalVisualizarJustificativa from './ModalVisualizarJustificativa/ModalVisualizarJustificativa';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './MotivoAtendimento.scss';

interface MotivoAtendimentoProps {
  idPaciente: number | null;
}

const MotivoAtendimento = ({ idPaciente }: MotivoAtendimentoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getInfoLembretes, atendimentoStatus } = useAtendimento();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'age_motivo_de_atendimento_cadastrar',
      'age_motivo_de_atendimento_alterar',
      'age_motivo_de_atendimento_consultar',
      'age_motivo_de_atendimento_excluir',
    ],
  });

  const [visible, setVisible] = useState<boolean>(false);
  const [modalInativar, setModalInativar] = useState<boolean>(false);
  const [modalVisualizarJustificativa, setModalVisualizarJustificativa] =
    useState<boolean>(false);

  const [editMotivo, setEditMotivo] = useState<MotivoAtendimento>();
  const [updateList, setUpdateList] = useState<number>(0);

  const fetchAPI = useCallback(
    (params): Promise<any> => {
      return MotivoAtendimentoAPI.loadMotivoAtendimento({
        id: idAtendimento,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        ...params,
      });
    },
    [idAtendimento],
  );

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));
  };

  const onEdit = (motivo: MotivoAtendimento) => {
    setEditMotivo(motivo);
    setVisible(true);
  };

  const onInativar = (motivo: MotivoAtendimento) => {
    setEditMotivo(motivo);
    setModalInativar(true);
  };

  const onVisualizarJustificativa = (motivo: MotivoAtendimento) => {
    setEditMotivo(motivo);
    setModalVisualizarJustificativa(true);
  };

  const onHideModalNovoMedicamento = () => {
    setEditMotivo(undefined);

    setVisible(false);
    setModalInativar(false);
    setModalVisualizarJustificativa(false);
  };

  const onAlterarLembrete = async (idMotivoAtendimento: number) => {
    const response = await MotivoAtendimentoAPI.putMotivoAtendimentoLembrete(
      idMotivoAtendimento,
    );

    if (response?.status === 204) reloadList();
  };

  const onExcluir = async (idMotivoAtendimento: number) => {
    const response = await MotivoAtendimentoAPI.deleteMotivoAtendimento(
      idMotivoAtendimento,
      idAtendimento,
    );

    if (response?.status === 204) reloadList();
  };

  const optionsMenu = (motivoAtendimento: MotivoAtendimento) => (
    <PanelOptionsMenu
      ativo={motivoAtendimento.status === 'ATIVO'}
      lembreteAtivo={motivoAtendimento.lembrete}
      idAtendimentoRegistro={motivoAtendimento.idAtendimento}
      idUsuarioCriacao={motivoAtendimento.idUsuarioAtualizacao}
      onAlterarLembrete={() => onAlterarLembrete(motivoAtendimento.id)}
      onEdit={() => onEdit(motivoAtendimento)}
      onInativar={() => onInativar(motivoAtendimento)}
      onExcluir={() => onExcluir(motivoAtendimento.id)}
      onVisualizarJustificativa={() =>
        onVisualizarJustificativa(motivoAtendimento)
      }
      showEditOnlyAtendimentoCorrente
    />
  );

  const renderRow = (motivoAtendimento: MotivoAtendimento) => {
    return (
      <div className="p-mt-2">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHeader
              isCollapsed={isCollapsed}
              onToggle={onToggle}
              motivoAtendimento={motivoAtendimento}
              optionsMenu={optionsMenu(motivoAtendimento)}
            />
          )}
        >
          <PanelContent motivoAtendimento={motivoAtendimento} />
        </Panel>
      </div>
    );
  };

  return (
    <div id="motivo-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Motivo de atendimento
            </SimpleText>

            <Button
              className="p-mr-4"
              type="button"
              btnType="tonal"
              label="Adicionar Motivo de atendimento"
              icon="fas fa-plus"
              onClick={() => setVisible(true)}
              disabled={
                atendimentoStatus !== 'ATENDENDO' &&
                atendimentoStatus !== 'ATENDENDO_TRIAGEM'
              }
            />
          </div>

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {visible && (
        <AdicionarMotivo
          visible={visible}
          editMotivo={editMotivo}
          idAtendimento={idAtendimento}
          onHide={onHideModalNovoMedicamento}
          reloadList={reloadList}
        />
      )}

      {modalInativar && (
        <InativarMotivo
          visible={modalInativar}
          motivoAtendimento={editMotivo}
          idAtendimento={idAtendimento}
          onHide={onHideModalNovoMedicamento}
          reloadList={reloadList}
        />
      )}

      <ModalVisualizarJustificativa
        motivoAtendimento={editMotivo}
        visible={modalVisualizarJustificativa}
        onHide={onHideModalNovoMedicamento}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default MotivoAtendimento;
