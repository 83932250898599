import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import LembreteAPI from 'src/APIs/LembreteAPI/LembreteAPI';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import { Row } from './LembreteGrid/Row';

import './LembreteAgendamento.scss';

interface LembreteProps {
  tabIndex: number;
}

const LembreteAgendamento = ({ tabIndex }: LembreteProps) => {
  const navigate = useNavigate();
  const [reload, setReload] = useState(0);
  const modalDeleteDisclosure: DisclosureType<any> = useDisclosure({
    opened: false,
  });

  const fetchAPI = ({ query, ...rest }: any) => {
    return LembreteAPI.getLembrete({
      nome: query,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...rest,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const onDelete = useCallback(async () => {
    const response = await LembreteAPI.deleteLembrete(
      modalDeleteDisclosure.state.id,
    );

    if (response.status === undefined) {
      reloadList();
    }

    modalDeleteDisclosure.close();
  }, [modalDeleteDisclosure]);

  const renderRow = (lembrete: GetLembreteAgendamentoDTO) => (
    <Row
      tabIndex={tabIndex}
      lembrete={lembrete}
      onDelete={(id: number) => {
        modalDeleteDisclosure.open({ state: { id: id } });
      }}
    />
  );

  return (
    <div className="lembreteAgendamento">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <div className="p-d-flex p-jc-end p-ai-center">
          <Button
            btnType="pill"
            className="p-col-12 p-md-6 p-lg-4 p-mb-2"
            label="Novo Lembrete"
            icon="fas fa-plus"
            onClick={() =>
              navigate('/config/general/lembrete', { state: tabIndex })
            }
            checkPermission="ADM_CONFIGURACAO_LEMBRETE_CONSULTA_CADASTRAR"
          />
        </div>

        <SearchList.BorderContainer>
          <Header />

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {modalDeleteDisclosure.isOpen && (
        <ConfirmDialog
          visible={modalDeleteDisclosure.isOpen}
          header="Deseja excluir lembrete?"
          text="Você confirma a exclusão do lembrete? essa alteração não podera ser desfeita"
          confirmText="Sim, excluir"
          onHide={() => {
            modalDeleteDisclosure.close();
          }}
          onConfirm={onDelete}
        />
      )}
    </div>
  );
};

export default LembreteAgendamento;
