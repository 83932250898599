import React from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import MobileRow from 'src/components/SearchList/MobileRow';

interface RowMobileProps {
  data: any;
  alertDivergencia: any;
  statusItem: any;
  switchStatus: JSX.Element;
  handleConfigFuncionalidade: any;
}

const RowMobile = ({
  data,
  alertDivergencia,
  statusItem,
  switchStatus,
  handleConfigFuncionalidade,
}: RowMobileProps) => {
  const options = (
    <>
      <div className={'p-grid'} style={{ width: 200 }}>
        <>
          <div className={'p-col-12'}>{switchStatus}</div>
          <div className={'p-col-12'}>
            <Button
              btnType="green-link"
              label={'Configurar'}
              onClick={() => handleConfigFuncionalidade(data)}
            />
          </div>
        </>
      </div>
    </>
  );

  return (
    <MobileRow options={options}>
      <div className="p-col-12">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.funcionalidade?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.funcionalidade?.descricao}
        </SimpleText>{' '}
      </div>
      <div className="p-col-12">
        {' '}
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          <b>Status: </b>
          {statusItem ? 'Ativo' : 'Inativo'}
        </SimpleText>
      </div>

      {alertDivergencia}
    </MobileRow>
  );
};

export default RowMobile;
