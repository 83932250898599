import {CardapioAPI} from 'src/APIs/AdminAPI/CardapioAPI/CardapioAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import ConsultorioAPI from './ConsultorioAPI/ConsultorioAPI';
import ProfissionalAPI from './ConsultorioAPI/ProfissionalAPI';
import ConvenioAPI from './ConvenioAPI/ConvenioAPI';
import EspecialidadeAPI from './EspecialidadeAPI/EspecialidadeAPI';
import HospitalAPI from './HospitalAPI/HospitalAPI';
import PacienteAPI from './PacienteAPI/PacienteAPI';
import SatisfacaoAPI from './SatisfacaoAPI/SatisfacaoAPI';



const AdminAPI = {
  UsuarioAPI: UsuarioAPI,
  CardapioAPI: CardapioAPI,
  ConsultorioAPI: ConsultorioAPI,
  ProfissionalAPI: ProfissionalAPI,
  EspecialidadeAPI: EspecialidadeAPI,
  SatisfacaoAPI: SatisfacaoAPI,
  HospitalAPI: HospitalAPI,
  PacienteAPI: PacienteAPI,
  ConvenioAPI: ConvenioAPI
}

export default AdminAPI;