import { useCallback, useEffect, useState } from 'react';

import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

type Response = {
  assistenteVirtualDialog: DisclosureType;
  assistenciasVirtuaisPendentes: any[];
  handleClose(id: number, isChecked?: boolean): void;
};

type Props = {
  funcionalidade: string | string[];
};

export const useAssistenciaVirtual = ({ funcionalidade }: Props): Response => {
  const [assistenciasVirtuaisPendentes, setAssistenciasVirtuais] = useState<
    any[]
  >([]);

  const assistenteVirtualDialog = useDisclosure({ opened: false });

  const onSetAssistencias = useCallback(
    (data: any) => {
      const assistencias = data.filter((item: any) => {
        if (Array.isArray(funcionalidade)) {
          return funcionalidade.includes(item.funcionalidade?.nome);
        } else {
          return item.funcionalidade?.nome === funcionalidade;
        }
      });

      setAssistenciasVirtuais(assistencias);
    },
    [funcionalidade],
  );

  useEffect(() => {
    AssistenciaAPI.getAssistenciasNaoVisualizadas({ throwError: true })
      .then(data => data.length && onSetAssistencias(data))
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!assistenciasVirtuaisPendentes.length) {
      assistenteVirtualDialog.open({
        state: assistenciasVirtuaisPendentes[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistenciasVirtuaisPendentes, assistenciasVirtuaisPendentes.length]);

  const handleClose = useCallback((id: number, isChecked = false) => {
    setAssistenciasVirtuais(old => old.filter(data => data.id !== id));

    if (isChecked) {
      AssistenciaAPI.sendOcultarAssistencia(id);
    }
  }, []);

  return {
    assistenteVirtualDialog,
    assistenciasVirtuaisPendentes,
    handleClose,
  };
};
