import { useForm, FormProvider } from 'react-hook-form';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

export const ModalInformarResultado = (props: any) => {
  const {
    isOpen,
    close,
    state: {
      resutladoNumerico,
      idAtendimento,
      idProcedimento,
      isProcedimentoExterno,
      onSuccess,
    },
  } = props;

  const useFormMethods = useForm({
    defaultValues: {
      resutladoNumerico,
    },
  });

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        ...data,
        id: idProcedimento,
      };

      const putResultado = isProcedimentoExterno
        ? SolicitacoesProcedimentosExternosAPI.putResultado
        : SolicitacoesProcedimentosAPI.putResultado;

      await putResultado(idAtendimento, idProcedimento, payload);

      onSuccess(data.parecer);

      close();
    } catch (error) {}
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        onClick={close}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={!isDirty}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      header="Informar resultado do exame"
      visible={isOpen}
      onHide={close}
      type="modal"
      modal={false}
      footer={renderFooter}
      maximizedMobileSize
      style={{ width: '40vw' }}
    >
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-mb-3">
            <SimpleText fontColor={FONT_COLOR.COLOR_40}>
              Você está informando o resultado do exame.
            </SimpleText>
          </div>

          <FormInput
            classNameInput="p-w-100"
            name="resutladoNumerico"
            label="Valor do resultado"
            type="number"
            hideTextErrorSpace
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
