import { useCallback, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import { ProfissionalSaude } from 'src/models/APIs/AdminAPI/ProfissionalSaude/ProfissionalSaudeDTO';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Skeleton from 'src/components/Skeleton/Skeleton';

import './FieldSearchProfissional.scss';
import { useFormContext } from 'react-hook-form';

type IProps = {
  onSelect: (value: ProfissionalSaude) => void;
};

export const FieldSearchProfissional = ({ onSelect }: IProps) => {
  const overlay = useRef<OverlayPanel>(null);

  const [search, setSearch] = useState('');
  const [list, setList] = useState<ProfissionalSaude[]>([]);
  const [loading, setLoading] = useState(false);

  const form = useFormContext();

  const profissionaisSelected = form.watch('profissionais');

  const fetch = useCallback(async () => {
    setLoading(true);

    const data = await ProfissionalAPI.loadProfissionaisSaudeBySearch({
      nome: search,
    });

    const dataFiltered = data.list.filter(
      (item: any) =>
        !profissionaisSelected.map((prof: any) => prof.id).includes(item.id),
    );

    return [setList(dataFiltered || []), setLoading(false)];
  }, [profissionaisSelected, search]);

  useEffectSkipFirst(() => {
    fetch();
  }, [search]);

  const setNome = useThrottle(setSearch, 500);

  const textEmpty = !search.length
    ? 'Digite para pesquisar por profissionais'
    : 'Sem resultado para busca';

  return (
    <div className="p-w-100" style={{ height: '40px' }}>
      <TextInputSearch
        onClick={e => overlay.current?.toggle(e)}
        onChange={e => setNome(e)}
        placeholder="Pesquisar"
      />

      <OverlayPanel
        style={{ width: '100%', maxWidth: '700px' }}
        id="Overlay-profissionais-search"
        className="p-mx-2"
        ref={overlay}
      >
        <div className="p-d-flex p-flex-column p-gap-1 p-w-100">
          {loading ? (
            <LoadingComponent
              loading={loading}
              height="30px"
              borderRadius="8px"
            />
          ) : !!list.length ? (
            list.map(item => (
              <div
                key={item.id}
                onClick={() => [onSelect(item), overlay.current?.hide()]}
                className="p-col-12 overlay-item"
              >
                <SimpleText className="p-col-12">{item.nome}</SimpleText>
              </div>
            ))
          ) : (
            <SimpleText className="p-col-12">{textEmpty}</SimpleText>
          )}
        </div>
      </OverlayPanel>
    </div>
  );
};

const LoadingComponent = ({ ...props }: any) => (
  <>
    <Skeleton {...props} />
    <Skeleton {...props} />
    <Skeleton {...props} />
  </>
);
