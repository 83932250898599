import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

import { useAgenda } from '../../AgendaContext';

interface MobileRowProps {
  agendamento: any;
  setSelectedAgendamento: React.Dispatch<React.SetStateAction<any>>;
}

export const MobileRow = (props: MobileRowProps) => {
  const { dialogDetalhesAgendamento } = useAgenda();
  const { agendamento, setSelectedAgendamento } = props;

  const options = [
    {
      label: 'Visualizar detalhes',
      command: () => {
        setSelectedAgendamento(agendamento);
        dialogDetalhesAgendamento.open({ state: agendamento });
      },
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Status
        </SimpleText>
        <br />
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {capitalizeFirstLetter(agendamento?.status).replaceAll('_', ' ')}
        </SimpleText>
        <div className={`circle-${agendamento?.status}`}></div>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Data
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {dayjs(agendamento?.dataAgendamento).format('DD [de] MMM. [de] YYYY')}
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          className="p-mt-1"
        >
          {dayjs(agendamento?.dataAgendamento).format('[às] HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Paciente / Contato
        </SimpleText>
        <br />
        <SimpleText
          className="p-mt-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {agendamento?.nomePaciente} - {agendamento?.telefoneContato}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Plano / Particular
        </SimpleText>
        <br />
        <SimpleText
          className="p-mt-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {agendamento?.plano}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Profissional
        </SimpleText>
        <br />
        <SimpleText
          className="p-mt-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {agendamento?.profissionalSaude}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
