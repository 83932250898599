import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/medicos';

export const loadMedicos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetMedicoDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/por-lista-consultorios`,
    params,
    hideToast: true,
    ...options,
  });
};

export const loadMedicosById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetMedicoDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/por-consultorio/${id}`,
    hideToast: true,
    ...options,
  });
};
export const loadMedicosByEspecialidade = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetMedicoDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/por-especialidade`,
    hideToast: true,
    params,
    ...options,
  });
};

const MedicoAPI = {
  loadMedicos,
  loadMedicosById,
  loadMedicosByEspecialidade,
};

export default MedicoAPI;
