import { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { EquipeAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/EquipeAPI/EquipeAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import Tooltip from 'src/components/Tooltip/Tooltip';

type FieldEquipeCuidadoraProps = DropdownProps & {
  idConsultorio: number;
  name?: string;
  label: string;
  showTooltip?: boolean;
  deleteEquipeFromOptions?: number;
};

export const FieldEquipeCuidadora = ({
  idConsultorio,
  name = 'equipeCuidadora',
  label,
  showTooltip = false,
  deleteEquipeFromOptions,
  ...rest
}: FieldEquipeCuidadoraProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [equipesCuidadora, setEquipesCuidadora] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const loadEquipeCuidadora = useCallback(async () => {
    setLoadingSearch(true);

    const response = await EquipeAPI.getEquipeList(idConsultorio);

    if (Array.isArray(response)) setEquipesCuidadora(response);

    setLoadingSearch(false);
  }, [idConsultorio]);

  useEffect(() => {
    loadEquipeCuidadora();
  }, [loadEquipeCuidadora]);

  const equipeCuidadoraAtual = watch(name);

  const options = equipesCuidadora.flatMap(item => {
    if (deleteEquipeFromOptions && item.id === deleteEquipeFromOptions)
      return [];

    return {
      label: item.nome,
      value: item,
    };
  });

  const TooltipComponent = () => {
    if (!equipeCuidadoraAtual || !showTooltip) return undefined;

    const equipeProfissionaisSaude = equipesCuidadora.find(
      equipeCuidadora => equipeCuidadora.id === equipeCuidadoraAtual.id,
    )?.equipeProfissionaisSaude;

    if (!equipeProfissionaisSaude) return undefined;

    return (
      <>
        <i className="fas fa-info" id={name} data-pr-position="top" />
        <Tooltip
          className="equipe-cuidadora-field-tooltip"
          target={`#${name}`}
          autoHide={false}
        >
          <b>Equipe</b>
          <br />
          <br />
          {equipeProfissionaisSaude.map((profissionalSaude: any) => (
            <div key={profissionalSaude.profissionalSaude.usuario.id}>
              {profissionalSaude.profissionalSaude.usuario.nome}{' '}
              {profissionalSaude.responsavel && ' - Profissional responsável'}
            </div>
          ))}
        </Tooltip>
      </>
    );
  };

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={options}
      errorMsg={errors?.[name]?.message}
      customOnChange={value => rest.onChange?.(value)}
      labelOverflow="hidden"
      loading={loadingSearch}
      labelTooltip={TooltipComponent()}
      {...rest}
    />
  );
};
