import { useCallback, useState } from 'react';

import Dropzone, { Accept } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import axios from 'axios';
import ProcedimentosSeriadosAPI from 'src/APIs/ProntuarioAPI/ProcedimentosSeriadosAPI/ProcedimentosSeriadosAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';

import './OverlayAnexar.scss';

interface LaudoProps {
  url: string | undefined;
  name: string;
  size: number;
  type: string;
}

interface OverlayAnexarProps {
  guiaExecucao: any;
  index: number;
  setGoogleUrl: any;
}

export const OverlayAnexar = ({
  guiaExecucao,
  index,
  setGoogleUrl,
}: OverlayAnexarProps) => {
  const [fileBruto, setFileBruto] = useState<any>({});
  const [dadosLocalLaudo, setDadosLocalLaudo] = useState<LaudoProps>({
    url: '',
    name: '',
    size: 0,
    type: '',
  });
  const [temLaudo, setTemLaudo] = useState(false);

  const [visible, setVisible] = useState(false);

  const { setValue, getValues } = useFormContext();
  const { getGuiasExecucoes } = useAtendimento();

  const accept: Accept = {
    'image/jpeg': ['.jpeg', '.png'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc', '.docx'],
  };

  const handleUploadLocal = useCallback(async (file: any) => {
    const localUrl = URL.createObjectURL(file[0]);
    setFileBruto(file);

    setDadosLocalLaudo({
      url: localUrl,
      name: file[0]?.name,
      size: file[0]?.size,
      type: file[0]?.type,
    });
  }, []);

  const handleRemoveUrl = useCallback(() => {
    setDadosLocalLaudo({ url: '', name: '', size: 0, type: '' });
    setFileBruto({});
    setGoogleUrl('');
    setTemLaudo(false);
  }, [setGoogleUrl]);

  const toastData = useCallback((type: 'success' | 'error') => {
    return {
      title: type === 'success' ? 'Sucesso' : 'Atenção',
      message:
        type === 'success'
          ? 'Upload de imagem para o sistema concluída com sucesso'
          : 'Ocorreu um erro ao realizar o upload de imagem para o sistema',
      type,
    };
  }, []);

  const handleUploadToSystem = useCallback(async () => {
    const dataFile: FileUpload = {
      fileName: dadosLocalLaudo?.name,
      contentType: dadosLocalLaudo?.type,
      contentLength: dadosLocalLaudo?.size,
      resourceType: 'OUTROS',
      resourceId: guiaExecucao.id,
    };
    const responseGoogleStorage: any = await FileUploadAPI.sendFileUpload(
      dataFile,
    );

    if (responseGoogleStorage?.status === 200) {
      const urlGoogle = responseGoogleStorage?.data?.uploadUrl;

      const data = fileBruto[0];

      const response: any = await axios.put(urlGoogle, data, {
        headers: {
          'Content-Type': dadosLocalLaudo?.type,
        },
      });

      if (response?.status > 200 && response?.status < 300) {
        setGoogleUrl(responseGoogleStorage?.data?.filePath);
        setValue(`anexo${index}`, responseGoogleStorage?.data?.filePath);

        const payload = {
          id: guiaExecucao.id,
          laudo: getValues(`laudo${index}`),
          anexo: responseGoogleStorage?.data?.filePath,
        };

        await ProcedimentosSeriadosAPI.postGuiaExecucao(payload);
      }
      getGuiasExecucoes();
      return toast(<Toast />, { data: toastData('success') });
    }
    return toast(<Toast />, { data: toastData('error') });
  }, [
    dadosLocalLaudo?.name,
    dadosLocalLaudo?.type,
    dadosLocalLaudo?.size,
    guiaExecucao.id,
    toastData,
    fileBruto,
    setGoogleUrl,
    setValue,
    index,
    getValues,
    getGuiasExecucoes,
  ]);

  return (
    <div className="laudo-container">
      <div className="laudo-c-header">
        <Dropzone
          onDrop={acceptedFiles => handleUploadLocal(acceptedFiles)}
          accept={accept}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <Button
                btnType="tonal"
                icon="fa fa-plus"
                label="Adicionar"
                disabled={!!dadosLocalLaudo?.url}
                {...getRootProps()}
              />
              <input {...getInputProps()} />
            </>
          )}
        </Dropzone>
      </div>
      {dadosLocalLaudo?.url !== '' ? (
        <div className="laudo-c-body-preview">
          <div className="laudo-c-body-item">
            <div className="laudo-c-body-item-infos">
              <SimpleText
                fontColor={FONT_COLOR.PRIMARY}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {dadosLocalLaudo?.name}
              </SimpleText>

              <div className="laudo-c-body-item-infos-right">
                {dadosLocalLaudo?.size > 0 && (
                  <SimpleText
                    className="p-mr-1"
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XS}
                  >
                    {dadosLocalLaudo?.size} KB
                  </SimpleText>
                )}

                <Button
                  btnType="gray"
                  icon="fa fa-trash"
                  onClick={handleRemoveUrl}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Dropzone
          onDrop={acceptedFiles => handleUploadLocal(acceptedFiles)}
          accept={accept}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="laudo-c-body p-ai-center" {...getRootProps()}>
              <input {...getInputProps()} />

              <SimpleText
                className="p-mb-1"
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                Você pode arrastar seus arquivos aqui.
              </SimpleText>
              <SimpleText
                className="p-mb-"
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Formato aceito: PDF, WORD, PNG eJPEG
              </SimpleText>
            </div>
          )}
        </Dropzone>
      )}
      <div className="laudo-c-footer">
        <div className="laudo-c-footer-btns">
          <div className="p-col-12">
            <Button
              icon="fa fa-arrow-up"
              label="Enviar para o sistema"
              disabled={dadosLocalLaudo?.url === '' || temLaudo}
              type="button"
              onClick={handleUploadToSystem}
              stretch
            />
          </div>
        </div>
        {visible && (
          <AlertBox
            visible={visible}
            onHide={() => setVisible(false)}
            text="Pressione “enviar” para iniciar o upload"
          />
        )}
      </div>
    </div>
  );
};
