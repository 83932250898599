import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Menu } from 'primereact/menu';

import AdminAPI from 'src/APIs/AdminAPI/AdminAPI';
import ListaEsperaAPI from 'src/APIs/AgendaAPI/ListaEsperaAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  replaceSpecialChars,
  stringToDate,
  toFirstCharUpperCase,
} from 'src/utils/utils';

import { ModalAddListaEspera } from './components/ModalAddListaEspera';
import { PacienteAccordion } from './components/PacienteAccordion';
import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import IconButton, {
  IconBtnSizes,
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Calendar from 'src/components/Calendar/Calendar';
import ChipSelect from 'src/components/ChipSelect/ChipSelect';
import Dialog from 'src/components/Dialog/Dialog';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';
import Separator from 'src/components/Separator/Separator';

import { useAgenda } from '../../AgendaContext';

import './BarraLateralMenuAgenda.scss';

export enum FILTROS_ATENDIMENTOS_AGENDA {
  AGENDADO = 'AGENDADO',
  AGENDADO_INTERNET = 'AGENDADO_INTERNET',
  ATENDENDO = 'ATENDENDO',
  ATENDIDO = 'ATENDIDO',
  ATRASADO = 'ATRASADO',
  ATUALIZAR_GUIA = 'ATUALIZAR_GUIA',
  ATUALIZAR = 'ATUALIZAR',
  AUTORIZAR = 'AUTORIZAR',
  BLOQUEADO_AGENDA = 'BLOQUEADO_AGENDA',
  BLOQUEADO_PACIENTE = 'BLOQUEADO_PACIENTE',
  CANCELADO_SMS = 'CANCELADO_SMS',
  CANCELOU = 'CANCELOU',
  COMPROMISSO = 'COMPROMISSO',
  CONFIRMADO = 'CONFIRMADO',
}

export enum FILTROS_PERIODO_AGENDA {
  MANHA = 'MANHA',
  TARDE = 'TARDE',
}

const turno = [
  { value: 'MANHA', label: 'Manhã' },
  { value: 'TARDE', label: 'Tarde' },
  { value: 'NOITE', label: 'Noite' },
];

const tipoEspera = [
  { value: 'TODOS', label: 'Todos' },
  { value: 'PRIMEIRA_CONSULTA', label: 'Primeira consulta' },
  { value: 'SEGUIMENTO', label: 'Seguimento' },
];

interface BarraLateralMenuAgendaProps {
  selectedDate: Date;
  onChangeSelectedDate(date: Date): void;
  setHorarioDisponivelSelecionado(v: string): void;
  openSelectedHorarioDisponivelModal(paciente: any): void;
}
const BarraLateralMenuAgenda = ({
  selectedDate,
  onChangeSelectedDate,
  openSelectedHorarioDisponivelModal,
}: BarraLateralMenuAgendaProps) => {
  // const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
  //   funcionalidade: [
  //     'age_lista_de_espera_cadastrar',
  //     'age_lista_de_espera_alterar',
  //     'age_lista_de_espera_consultar',
  //     'age_lista_de_espera_excluir',
  //   ],
  // });

  const [calendar, setCalendar] = useState(true);

  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<any>('');
  const [activeAccordion, setActiveAccordion] = useState<number>();

  const [profissionalAtivo, setProfissionalAtivo] = useState<any>();

  const [firstWaitlist, setFirstWaitList] = useState<any[]>([]);
  const [waitlist, setWaitList] = useState<any>();
  const [paciente, setPaciente] = useState<any>();
  const [especialidades, setEspecialidades] = useState<any>();
  const [waitlistItem, setWaitListItem] = useState<any>();
  const [tipoEsperaFilters, setTipoEsperaFilters] = useState<any>([]);
  const [turnoFilters, setTurnoFilters] = useState<any>([]);
  const [update, setUpdate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<any>({ visible: false });

  const [isFilterStatusHidden, setIsFilterStatusHidden] = useState(true);
  const [isFilterPeriodoHidden, setIsFilterPeriodoHidden] = useState(false);
  const [statusFilter, setStatusFilter] = useState<
    { label: string; isActive: boolean; value: FILTROS_ATENDIMENTOS_AGENDA }[]
  >([
    {
      label: 'Agendado',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.AGENDADO,
    },
    {
      label: 'Agendado Internet',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.AGENDADO_INTERNET,
    },
    {
      label: 'Atendendo',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.ATENDENDO,
    },
    {
      label: 'Atrasado',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.ATRASADO,
    },
    {
      label: 'Atualizar Guia',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.ATUALIZAR_GUIA,
    },
    {
      label: 'Atualizar',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.ATUALIZAR,
    },
    {
      label: 'Autorizar',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.AUTORIZAR,
    },
    {
      label: 'Bloqueado Agenda',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.BLOQUEADO_AGENDA,
    },
    {
      label: 'Bloqueado Paciente',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.BLOQUEADO_PACIENTE,
    },
    {
      label: 'Cancelado SMS',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.CANCELADO_SMS,
    },
    {
      label: 'Cancelou',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.CANCELOU,
    },
    {
      label: 'Compromisso',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.COMPROMISSO,
    },
    {
      label: 'Confirmado',
      isActive: true,
      value: FILTROS_ATENDIMENTOS_AGENDA.CONFIRMADO,
    },
  ]);
  const [periodoFilter, setPeriodoFilter] = useState<
    { label: string; isActive: boolean; value: FILTROS_PERIODO_AGENDA }[]
  >([
    {
      label: 'Manhã',
      isActive: true,
      value: FILTROS_PERIODO_AGENDA.MANHA,
    },
    {
      label: 'Tarde',
      isActive: false,
      value: FILTROS_PERIODO_AGENDA.TARDE,
    },
  ]);

  const { isTablet } = useSize();
  const { isBarraLateralOpened, setBarraLateralOpened } = useAgenda();

  const modalAddListaEspera = useDisclosure({
    opened: false,
    onClose: () => setPaciente(null),
  });

  const { agenda } = useAppSelector((state: RootState) => state);

  const optionsItems = useCallback((list: any) => {
    const item = [
      {
        label: 'Editar informações',
        command: async () => {
          setWaitListItem(list);
          modalAddListaEspera.open();
          setPaciente(list.paciente);
          setUpdate(true);
        },
      },
      {
        label: 'Excluir da lista de espera',
        command: () => setConfirmDelete({ visible: true, list }),
      },
    ];

    return item;
  }, []);

  const loadPage = useCallback(async () => {
    if (!agenda.profissionalAtivo) return;
    setProfissionalAtivo(agenda.profissionalAtivo);

    try {
      await ListaEsperaAPI.getListaEspera(agenda.profissionalAtivo.id).then(
        (res: any) => {
          const list: any = [];

          res.map((e: any) => {
            e.turnoFilter = [e?.turno?.toUpperCase()];

            e.tipoEsperaFilter = [
              e?.tipoEspera?.descricao?.replace(' ', '_').toUpperCase(),
            ];

            e.turno = [e?.turno?.toLowerCase()] || null;
            list.push(e);
          });

          setWaitList(list);
          setFirstWaitList(list);
        },
      );
    } catch (error) {
      console.log(error);
    }

    try {
      const espec = await AdminAPI.EspecialidadeAPI.loadEspecialidades('');

      setEspecialidades(espec);
    } catch (error) {
      console.log(error);
    }

    if (waitlist) {
      const list: any = [];
      waitlist?.map((item: any) => {
        item.data = [
          stringToDate(item?.dataPrefInicial || ''),
          stringToDate(item?.dataPrefFinal || ''),
        ];

        list.push(item);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda.profissionalAtivo]);

  const statusFilters = useCallback(() => {
    return (
      <div className="filter">
        <Button
          btnType="green-link"
          label="Status"
          onClick={() => {
            setIsFilterStatusHidden(prev => !prev);
          }}
          icon={
            isFilterStatusHidden ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
        />
        <div
          className={`filter-content ${isFilterStatusHidden ? 'hide' : 'show'}`}
        >
          {(statusFilter || []).map((v, i) => {
            return (
              <Checkbox
                key={v.value}
                label={v.label}
                value={v.value}
                checked={v.isActive}
                onChange={() => {
                  const aux: any[] = [...statusFilter];
                  aux[i] = { ...aux[i], isActive: !v.isActive };
                  setStatusFilter(aux);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }, [isFilterStatusHidden, statusFilter]);

  const periodoFilters = useCallback(() => {
    return (
      <div className="filter">
        <Button
          btnType="green-link"
          label="Periodo"
          onClick={() => {
            setIsFilterPeriodoHidden(prev => !prev);
          }}
          icon={
            isFilterPeriodoHidden ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
        />
        <div
          className={`filter-content ${
            isFilterPeriodoHidden ? 'hide' : 'show'
          }`}
        >
          {(periodoFilter || []).map((v, i) => {
            return (
              <Checkbox
                key={v.value}
                label={v.label}
                value={v.value}
                checked={v.isActive}
                onChange={() => {
                  const aux: any[] = [...periodoFilter];
                  aux[i] = { ...aux[i], isActive: !v.isActive };
                  setPeriodoFilter(aux);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }, [isFilterPeriodoHidden, periodoFilter]);

  const getMaxAvailableDay = (): Date => {
    const aux = new Date();
    aux.setDate(aux.getDate() * 36);
    return aux;
  };

  const filteredValues = useRef([]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setSearchValue(value);

      if (waitlist) {
        const results = waitlist?.filter((element: any) => {
          return (
            replaceSpecialChars(element.paciente.nome.toUpperCase()).includes(
              replaceSpecialChars(value.toUpperCase()),
            ) || element.contato === value
          );
        });

        filteredValues.current = results;
      }
    },
    [waitlist],
  );

  const handleChangeTipoEsperaFilter = (values: string[] = []) => {
    if (values.includes('TODOS') && !tipoEsperaFilters.includes('TODOS')) {
      setTurnoFilters([]);
      setTipoEsperaFilters(['TODOS']);
      return;
    }

    const removeTodos = values.filter((value: any) => value !== 'TODOS');

    setTipoEsperaFilters(removeTodos);
  };

  const handleChangeTurnoFilter = (values: string[] = []) => {
    if (tipoEsperaFilters.includes('TODOS')) {
      setTipoEsperaFilters([]);
    }

    setTurnoFilters(values);
  };

  const waitListFiltered = useMemo(() => {
    if (
      tipoEsperaFilters.includes('TODOS') ||
      (!turnoFilters.length && !tipoEsperaFilters.length)
    ) {
      return firstWaitlist;
    }

    const filtered = firstWaitlist.filter((list: any) => {
      const turno = turnoFilters.some((x: any) => list.turnoFilter.includes(x));
      const tipoEspera = tipoEsperaFilters.some((x: any) =>
        list.tipoEsperaFilter.includes(x),
      );

      if (!!turnoFilters.length && !!tipoEsperaFilters.length) {
        return turno && tipoEspera;
      }

      if (!!turnoFilters.length) {
        return turno;
      }

      return tipoEspera;
    });

    return filtered;
  }, [turnoFilters, tipoEsperaFilters, firstWaitlist]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  function formatPhoneNumber(number?: string) {
    if (!number) return 'Não informado';
    const cleanedNumber = number.replace(/\D/g, '');
    if (cleanedNumber.length === 11) {
      return cleanedNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    if (cleanedNumber.length === 10) {
      return cleanedNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return cleanedNumber;
  }

  const items = !searchValue ? waitListFiltered : filteredValues.current;

  return (
    <div
      className={`BarraLateralMenuAgenda ${
        !isBarraLateralOpened ? 'hidden' : 'open'
      } ${isTablet ? 'full' : ''}`}
    >
      <div className="menu-agenda-scroll">
        <div className="menu-agenda-content">
          {calendar ? (
            /* Calendar render */
            <>
              <div className="p-d-flex p-jc-center p-mb-3">
                <Button
                  icon="pi pi-plus"
                  btnType="pill"
                  label="Novo agendamento"
                  stretch
                  style={{
                    paddingBlock: '12px',
                  }}
                  onClick={() => openSelectedHorarioDisponivelModal({})}
                />
              </div>
              <div className="list-espera-btn-aligner">
                <Button
                  btnType="ghost"
                  onClick={() => {
                    setCalendar(!calendar);
                  }}
                  label="Ver lista de espera"
                />
              </div>
              <Separator />
              <Calendar
                //readOnlyInput
                maxDate={getMaxAvailableDay()}
                value={selectedDate}
                onChange={e => {
                  if (e.value && !Array.isArray(e.value)) {
                    onChangeSelectedDate(e.value);
                  }
                }}
              />
              <Separator />
              <SimpleText>Filtrar agenda por:</SimpleText>
              {statusFilters()}
              {periodoFilters()}
              <Separator />
            </>
          ) : (
            /* End Calendar render */
            <>
              <Button
                icon="pi pi-arrow-left"
                btnType="ghost"
                label="Voltar"
                className="gray"
                onClick={() => setCalendar(!calendar)}
              />
              <div className="listaespera-header">
                <SimpleText className="listaespera-title bold">
                  Lista de espera
                </SimpleText>
                <div className="listaespera-header-buttons">
                  <IconButton
                    icon="pi pi-search"
                    btnType={search ? IconBtnTypes.GREEN : IconBtnTypes.GREY}
                    onClick={() => setSearch(!search)}
                  />
                  <IconButton
                    icon="pi pi-plus"
                    btnType={IconBtnTypes.GREEN}
                    onClick={() => {
                      setPaciente(null);
                      setUpdate(false);
                      setWaitListItem(undefined);
                      modalAddListaEspera.open();
                    }}
                  />
                </div>
              </div>
              {!search ? (
                <div>
                  <ScrollPanel activityIndicator={false}>
                    <div className="chips">
                      <ChipSelect
                        value={tipoEsperaFilters}
                        options={tipoEspera}
                        onChange={handleChangeTipoEsperaFilter}
                      />

                      <ChipSelect
                        value={turnoFilters}
                        options={turno}
                        onChange={handleChangeTurnoFilter}
                      />
                    </div>
                  </ScrollPanel>
                </div>
              ) : (
                <div>
                  <TextInput
                    icon="pi pi-search"
                    placeholder="Pesquisar"
                    value={searchValue}
                    onChange={e => handleSearch(e)}
                  />
                </div>
              )}
              <Separator />
              {/* <div className='accordion'> */}

              <Accordion
                activeIndex={activeAccordion}
                onTabChange={e => setActiveAccordion(e.index)}
                expandIcon="pi pi-chevron-up"
              >
                {items ? (
                  items?.map((item: any) => {
                    const celular = item?.paciente?.contatos?.find(
                      (contato: any) => contato.tipo === 'CELULAR_PARTICULAR',
                    );
                    return (
                      <AccordionTab
                        key={item.id}
                        header={
                          <div className="accordion-header">
                            {item?.paciente?.cartaoPrincipal?.numeroCartao ? (
                              <SimpleText
                                fontColor={FONT_COLOR.COLOR_60}
                                fontSize={FONT_SIZE.XXXS}
                                className="header-simple-text"
                              >
                                {item.paciente?.cartaoPrincipal?.convenio
                                  ?.nomeFantasia ??
                                  item.paciente?.cartaoPrincipal?.convenio
                                    ?.razaoSocial}{' '}
                                • {item.paciente.cartaoPrincipal.numeroCartao}
                              </SimpleText>
                            ) : (
                              <SimpleText
                                fontColor={FONT_COLOR.COLOR_60}
                                fontSize={FONT_SIZE.XXXS}
                              >
                                Cartão não informado
                              </SimpleText>
                            )}
                            <SimpleText
                              className="p-my-1"
                              fontColor={FONT_COLOR.PRIMARY}
                              fontSize={FONT_SIZE.XS}
                            >
                              {item.paciente.nome}
                            </SimpleText>
                            <SimpleText
                              fontColor={FONT_COLOR.COLOR_40}
                              fontSize={FONT_SIZE.XXS}
                              className="header-simple-text p-d-flex p-align-center"
                            >
                              <i className="fas fa-phone p-mr-1 phone-icon-espera" />
                              {formatPhoneNumber(celular?.numero)}
                            </SimpleText>
                          </div>
                        }
                      >
                        <PacienteAccordion
                          item={item}
                          optionsItems={optionsItems}
                          openSelectedHorarioDisponivelModal={
                            openSelectedHorarioDisponivelModal
                          }
                        />
                      </AccordionTab>
                    );
                  })
                ) : (
                  <SimpleText
                    color={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XXS}
                  >
                    Nenhum resultado correspondente
                  </SimpleText>
                )}
              </Accordion>
              {items?.length > 0 ? null : (
                <SimpleText
                  color={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XXS}
                >
                  Nenhum resultado correspondente
                </SimpleText>
              )}
              {/* </div> */}
              <Separator />
            </>
          )}
          <div className="list-espera-btn-aligner">
            <Button
              btnType="ghost"
              label="Ocutar painel lateral"
              icon="pi pi-angle-double-left"
              onClick={() => {
                setBarraLateralOpened(false);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`open-btn ${
          !isBarraLateralOpened ? 'show-btn' : 'hide-btn'
        }`}
        onClick={() => {
          setBarraLateralOpened(true);
        }}
      >
        <i className="pi pi-angle-double-right" />
      </div>

      {modalAddListaEspera.isOpen && (
        <ModalAddListaEspera
          isUpdate={update}
          profissionalAtivo={profissionalAtivo}
          especialidades={especialidades?.list}
          waitlistItem={waitlistItem}
          loadPage={loadPage}
          paciente={paciente}
          setPaciente={setPaciente}
          {...modalAddListaEspera}
        />
      )}

      <Dialog
        className="footer-on"
        header="Retirar paciente da lista de espera"
        visible={confirmDelete.visible}
        onHide={() => setConfirmDelete({ visible: false })}
        footer={() => (
          <Footer
            onCancel={() => setConfirmDelete({ visible: false })}
            onConfirm={async () => {
              await ListaEsperaAPI.deleteItemListaEspera(confirmDelete.list.id);

              loadPage();

              setConfirmDelete({ visible: false });
            }}
          />
        )}
      >
        <SimpleText>
          Você realmente deseja retirar o paciente da lista de espera deste
          médico?
        </SimpleText>
      </Dialog>

      {/* {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )} */}
    </div>
  );
};

interface FooterProps {
  onCancel(): void;
  onConfirm(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onConfirm } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        label="Sim, excluir"
        onClick={onConfirm}
        btnType="danger"
        stretch
      />
    </div>
  );
});

export default memo(BarraLateralMenuAgenda);
