import Page from 'src/components/Basics/Page/Page';

import './ServicoTuss.scss';
import PageHeader from 'src/components/PageHeader/PageHeader';

import ServicoTussGlobal from './ServicoTussGlobal/ServicoTussGlobal';

const ServicoTuss = () => {
  return (
    <Page className={'ServicoTuss'}>
      <>
        <div className="headertuss">
          <PageHeader
            title={'Configuração TUSS'}
            subtitle={
              'Gerencie os registros TUSS que padronizam a nomeclatura dos procedimentos médicos'
            }
          />
        </div>
        <ServicoTussGlobal />
      </>
    </Page>
  );
};

export default ServicoTuss;
