import { useState, useRef, useCallback, useContext, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import FormAdditionalData from './FormsConsultorio/FormAdditionalData/FormAdditionalData';
import FormCarteiraAps from './FormsConsultorio/FormCarteiraAps/FormCarteiraAps';
import FormProfessionals from './FormsConsultorio/FormProfessionals/FormProfessionals';
import FormRequiredData from './FormsConsultorio/FormRequiredData/FormRequiredData';
import FormRooms from './FormsConsultorio/FormRooms/FormRooms';
import FormServicosTussVinculados from './FormsConsultorio/FormServicosTussVinculados/FormServicosTussVinculados';
import { getPermissions, getPermissionsChecked } from './listaPermissoes';

import './Consultorio.scss';

interface Step1Props {
  nome: string;
  nomeResumido?: string;
  codigo: string;
  descricao: string;
  permissoes?: string[];
}

interface Step2Props {
  // Profissionais
  id: string;
  nome: string;
  tipo: string;
  crm?: string;
}

interface Step4Props {
  // formulário complementar
  cnpj: string;
  idTipoEstabelecimento: number;
  cnes: string;
  origemTiss: string;
  endereco: {
    numero: string;
    complemento: string;
    logradouro: string;
    estado: string;
    cep: string;
    bairro: string;
    cidade: string;
    enderecoResumido?: string;
  };
  email: string;
  idUsuarioResponsavel: number;
  contatos: {
    ddd: string;
    telefone: string;
  };
}

const Consultorio = () => {
  const navigation = useNavigate();
  const params = useParams();
  const { user } = useAppSelector((state: RootState) => state);

  const { theme } = useContext(ThemeContext);

  const [step1, setStep1] = useState<Step1Props>(null as any);
  const [step2, setStep2] = useState<GetProfissionalDTO[] | []>([]);
  const [step4, setStep4] = useState<Step4Props>({} as Step4Props);
  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const [consultorioAtual, setConsultorioAtual] = useState({} as any);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [companyProfessionalsList, setCompanyProfessionalsList] = useState<
    GetProfissionalDTO[] | []
  >([]);
  const [carteiraAps, setCarteiraAps] = useState<any>(null);
  const [profissionaisSaudeList, setProfissionaisSaudeList] = useState<any[]>(
    [],
  );
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (submitting) {
      document.documentElement.classList.add('p-cursor-wait');
      document.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
      });
    } else {
      document.documentElement.classList.remove('p-cursor-wait');
      document.removeEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
      });
    }
    return () => {
      document.documentElement.classList.remove('p-cursor-wait');
      document.removeEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
      });
    };
  }, [submitting]);

  const getProfissionaisSaudeAtivos = useCallback(async () => {
    if (!consultorioAtual?.id && !params?.id) return;
    try {
      const response = await ProfissionalAPI.loadProfissionalSaudeByConsultorio(
        {
          idConsultorio: consultorioAtual?.id || params?.id,
          status: true,
        },
      );
      setProfissionaisSaudeList(response);
    } catch (error) {
      console.error(error);
      setProfissionaisSaudeList([]);
    }
  }, [consultorioAtual?.id, params?.id]);

  useEffect(() => {
    getProfissionaisSaudeAtivos();
  }, [getProfissionaisSaudeAtivos]);

  const canCreateConsultorio =
    user.tipoUsuario === 'MASTER' || user.tipoUsuario === 'ADMINISTRADOR';

  const loadConsultorio = useCallback(async () => {
    setLoadingData(true);

    const profs = await ProfissionalAPI.loadProfissionais();

    setCompanyProfessionalsList(profs);

    if (params?.id) {
      const idConsultorio = parseInt(params?.id);

      const consultorio: Partial<ConsultorioDTO> =
        await ConsultorioAPI.loadConsultorioById(idConsultorio);

      const permissoes = getPermissionsChecked(consultorio);

      const passo1: Step1Props = {
        nome: consultorio.nome!,
        nomeResumido: consultorio.nomeResumido!,
        codigo: consultorio.codigo!,
        descricao: consultorio.descricao!,
        permissoes,
      };

      if (!!consultorio?.idsUsuarios?.length) {
        const professionalsList: any[] = [];

        consultorio?.idsUsuarios.map((v: any) => {
          const findProfessional = profs.filter((p: any) => p.id === v);

          return professionalsList.push(findProfessional[0]);
        });

        setStep2(professionalsList as any);
      }

      const passo4: any = {
        cnpj: consultorio.cnpj || '',
        estabelecimento: consultorio?.idTipoEstabelecimento || 0,
        cnes: consultorio.cnes || '',
        origem_tiss: consultorio.origemTiss || '',
        cep: consultorio.endereco?.cep || '',
        estado: consultorio.endereco?.cidade?.estado?.id || '',
        idCidade: consultorio.endereco?.cidade?.id || '',
        logradouro: consultorio.endereco?.logradouro || '',
        complemento: consultorio.endereco?.complemento || '',
        numero: consultorio.endereco?.numero || '',
        bairro: consultorio.endereco?.bairro || '',
        email: consultorio.email || '',
        idUsuarioResponsavel: consultorio?.idUsuarioResponsavel || 0,
        telefone: consultorio?.contatos?.[0]?.numero || '',
        tipo: consultorio?.contatos?.[0]?.tipo || '',
      };

      const carteiraAps = {
        tipoMonitoramentoCarteiraAps: consultorio.tipoMonitoramentoCarteiraAps,
        limitePacientes: consultorio.limitePacientes,
        limitePontos: consultorio.limitePontos,
      };

      setCarteiraAps(carteiraAps);

      setStep1({ ...passo1 });

      setStep4({ ...passo4 } as any);

      setConsultorioAtual({
        ...passo1,
        ...passo4,
        ...consultorio,
      });
    }

    setLoadingData(false);
  }, [params?.id]);

  useEffect(() => {
    loadConsultorio();
  }, [loadConsultorio]);

  const submitForm = useCallback(
    async (values: any, goToNextStep = true) => {
      setSubmitting(true);
      const permissoes = getPermissions(values?.permissoes || []);

      const { nome, nomeResumido, descricao, codigo } = values;

      const novoConsultorio = {
        nome,
        nomeResumido,
        codigo,
        descricao,
        ...permissoes,
      };

      try {
        if (consultorioAtual?.id) {
          const consultorioAtualizado = {
            ...consultorioAtual,
            ...novoConsultorio,
          };

          const consultorioId: number = consultorioAtual?.id;

          setConsultorioAtual(consultorioAtualizado);

          const resUpdate = await ConsultorioAPI.updateConsultorio(
            consultorioId,
            consultorioAtualizado,
            { throwError: true },
          );

          if (resUpdate.id) {
            if (
              timelineRef &&
              timelineRef.current &&
              timelineRef.current.next
            ) {
              timelineRef.current.next();
            }
            setStep1(values);
          }
        } else {
          const data = await ConsultorioAPI.sendConsultorio(
            novoConsultorio as any,
            { throwError: true },
          );

          setConsultorioAtual(data);

          if (
            goToNextStep &&
            timelineRef &&
            timelineRef.current &&
            timelineRef.current.next
          ) {
            timelineRef.current.next();
          } else {
            navigation('/cadastros/consultorio');
          }
          setStep1(values);
        }
      } catch (error) {
        setStep1(null as any);
      } finally {
        setSubmitting(false);
      }
    },
    [consultorioAtual, navigation],
  );

  const updateForm = useCallback(
    async (step: string, values: any, keepStep = false) => {
      setSubmitting(true);
      if (step === 'step2') {
        const idsUsuarios = values.map((equipe: Step2Props) => {
          return equipe?.id;
        });

        const consultorioAtualizado = {
          ...consultorioAtual,
          idsUsuarios: idsUsuarios || [], // Step2 (Profissionais ?)
        };

        const consultorioId: number = consultorioAtual?.id;

        ConsultorioAPI.updateConsultorio(consultorioId, consultorioAtualizado)
          .then(resUpdate => {
            if (resUpdate?.id) {
              setConsultorioAtual(consultorioAtualizado);
              setStep2(values);

              getProfissionaisSaudeAtivos();

              if (
                !keepStep &&
                timelineRef &&
                timelineRef.current &&
                timelineRef.current.next
              ) {
                timelineRef.current?.next();
              }
            }
          })
          .catch(error => console.log({ error }))
          .finally(() => setSubmitting(false));
      }

      if (step === 'step4') {
        const {
          cnpj,
          estabelecimento,
          cnes,
          origem_tiss,
          cep,
          logradouro,
          complemento,
          numero,
          bairro,
          email,
          tipo,
          telefone,
          idUsuarioResponsavel,
          idCidade,
          enderecoResumido,
        } = values;

        const consultorioAtualizado = {
          ...consultorioAtual,
          cnes,
          email,
          cnpj,
          idUsuarioResponsavel,
          endereco: {
            numero,
            complemento,
            logradouro,
            tipo: 'RESIDENCIAL',
            cep,
            principal: true,
            bairro,
            idCidade,
            enderecoResumido,
          },
          contatos: [
            {
              tipo,
              numero: telefone,
            },
          ],
          idTipoEstabelecimento: estabelecimento,
          origemTiss: origem_tiss,
        };

        const consultorioId: number = consultorioAtual?.id || params?.id;

        await ConsultorioAPI.updateConsultorio(
          consultorioId,
          consultorioAtualizado,
        )
          .then(res => {
            if (res?.id) {
              setConsultorioAtual(consultorioAtualizado);

              if (
                timelineRef &&
                timelineRef.current &&
                timelineRef.current.next
              ) {
                timelineRef.current?.next();
              }
            }
          })
          .catch(error => console.log({ error }))
          .finally(() => setSubmitting(false));
      }
    },
    [consultorioAtual, getProfissionaisSaudeAtivos, params?.id],
  );

  const stepProfissionalSaude = [
    {
      title: 'Serviços TUSS vinculados',
      component: (
        <FormServicosTussVinculados
          idConsultorio={consultorioAtual?.id || params?.id}
          profissionaisSaudeList={profissionaisSaudeList}
        />
      ),
      disabled: step1 === null,
    },
  ];

  const steps = useCallback(
    (): TimelineValue[] => [
      {
        title: 'Dados (obrigatório)',
        component: (
          <FormRequiredData
            values={step1}
            loadingData={loadingData}
            onSubmit={(values: any, goToNextStep = true) => {
              submitForm(values, goToNextStep);
            }}
          />
        ),
      },
      {
        title: 'Profissionais',
        component: (
          <FormProfessionals
            values={step2}
            companyProfessionalsList={companyProfessionalsList}
            onSubmit={(values: any, keepStep?: boolean) => {
              updateForm('step2', values, keepStep);
            }}
          />
        ),
        disabled: step1 === null,
      },
      {
        title: 'Serviços TUSS vinculados',
        component: (
          <FormServicosTussVinculados
            idConsultorio={consultorioAtual?.id || params?.id}
            profissionaisSaudeList={profissionaisSaudeList}
          />
        ),
        disabled: step1 === null,
      },
      {
        title: 'Salas',
        component: <FormRooms id={consultorioAtual?.id || params?.id} />,
        disabled: step1 === null,
      },
      {
        title: 'Dados complementares',
        component: (
          <FormAdditionalData
            values={step4}
            clinicProfessionalsList={step2}
            onSubmit={(values: any) => {
              setStep4(values);
              updateForm('step4', values);
            }}
            adicionarProfissionais={() => timelineRef?.current?.goToStep?.(1)}
          />
        ),
        disabled: step1 === null,
      },
      {
        title: 'Carteira APS',
        component: (
          <FormCarteiraAps
            idConsultorio={consultorioAtual?.id || params?.id}
            carteiraAps={carteiraAps}
            goBackStep={() => timelineRef.current?.previous?.()}
          />
        ),
        disabled: step1 === null,
      },
    ],
    [
      step1,
      loadingData,
      step2,
      companyProfessionalsList,
      consultorioAtual?.id,
      params?.id,
      profissionaisSaudeList,
      step4,
      carteiraAps,
      submitForm,
      updateForm,
    ],
  );

  return (
    <Page className={`CadastroConsultorio ${theme}`}>
      <>
        <PageHeader
          title={'Novo consultório'}
          subtitle={'Preencha os campos para completar o cadastro'}
        />

        {user?.idEmpresa ? (
          <Page content white>
            <div>
              <Timeline
                values={canCreateConsultorio ? steps() : stepProfissionalSaude}
                ref={timelineRef}
              />
            </div>
          </Page>
        ) : (
          <Page content white>
            <AlertBox
              visible={true}
              text="Você precisa selecionar um Cliente para esta funcionalidade!"
            />
          </Page>
        )}
      </>
    </Page>
  );
};

export default Consultorio;
