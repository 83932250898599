import { Control, Controller } from 'react-hook-form';

import SimpleText from '../Basics/SimpleText/SimpleText';

import Switch, { SwitchProps } from './Switch';

export interface SwitchControlledProps extends SwitchProps {
  control: Control<any>;
  name: string;
  title?: string;
}

const SwitchControlled = ({
  name,
  title,
  control,
  onChange: customOnChange,
  ...rest
}: SwitchControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }: any) => (
        <div className="p-d-flex p-jc-between p-ai-center">
          {title && <SimpleText medium>{title}</SimpleText>}
          <Switch
            {...rest}
            onChange={e => [onChange(e), customOnChange?.(e)]}
            checked={value}
          />
        </div>
      )}
    />
  );
};

export default SwitchControlled;
