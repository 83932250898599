import { useEffect } from 'react';

import { PerfilSaudeAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PerfilSaudeAPI/PerfilSaudeAPI';

import useQuestionarioController from 'src/core/hooks/Questionario/useQuestionarioController';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import * as SearchList from '../components/SearchList/SearchListRoot';
import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import InativarQuestionario from 'src/pages/Emed/Prontuario/FichaPaciente/Questionarios/InativarQuestionario/InativarQuestionario';
import ModalNovoQuestionario from 'src/pages/Emed/Prontuario/FichaPaciente/Questionarios/ModalNovoQuestionario/ModalNovoQuestionario';
import ModalVisualizarJustificativa from 'src/pages/Emed/Prontuario/FichaPaciente/Questionarios/ModalVisualizarJustificativa/ModalVisualizarJustificativa';

import Filter from './Filter';
import Row from './Grid/Row';

const items: any = {
  dataOcorrencia: 'Data de ocorrência',
  status: 'Status',
};

const List = () => {
  const { user } = useAppSelector((state: RootState) => state);

  const {
    confirmExcluir,
    deletarDisclosure,
    inativarDisclosure,
    visualizarJustificativaDisclosure,
    novoDisclosure,
    disclosures,
    fetchApiPortal,
    updateList,
    reloadList,
    sortBy,
    itemsOrdenation,
    ultimoQuestionarioValidado,
    setUltimoQuestionarioValidado,
  } = useQuestionarioController();

  useEffect(() => {
    (async () => {
      const response = await PerfilSaudeAPI.getUltimoQuestionarioValidadoPortal(
        {
          idPaciente: user?.idPaciente,
        },
      );
      if (response.status >= 200 && response.status < 300) {
        setUltimoQuestionarioValidado(response.data);
      }
    })();
  }, [setUltimoQuestionarioValidado, user?.idPaciente]);

  const renderRow = (questionario: any): JSX.Element => {
    return (
      <Row
        key={questionario.id}
        questionario={questionario}
        reloadList={reloadList}
        disclosures={disclosures}
      />
    );
  };

  return (
    <>
      <SearchList.Root fetchApi={fetchApiPortal}>
        <Filter />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-ai-center p-jc-between p-mb-2">
            <Button
              label="Novo questionário de saúde"
              btnType="pill"
              onClick={() => {
                novoDisclosure.open({
                  state: {
                    questionario: ultimoQuestionarioValidado,
                    isNew: true,
                  },
                });
              }}
              icon="pi pi-plus"
              iconPos="left"
            />
            <div className="p-d-flex p-jc-end p-ai-center p-gap-2">
              <SimpleText>Ordenar por:</SimpleText>
              <ButtonMenu
                label={items[sortBy]}
                btnType="green-link"
                model={itemsOrdenation}
              />
            </div>
          </div>

          <SearchList.InfiniteScroll
            reload={updateList}
            renderRow={renderRow}
            customEmptyText="Preencha um novo questionário de saúde ou utilize os filtros e faça uma busca para encontrar os questionários."
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {novoDisclosure.isOpen && (
        <ModalNovoQuestionario
          reloadList={reloadList}
          {...novoDisclosure}
          portal
        />
      )}

      {inativarDisclosure.isOpen && (
        <InativarQuestionario reloadList={reloadList} {...inativarDisclosure} />
      )}

      {visualizarJustificativaDisclosure.isOpen && (
        <ModalVisualizarJustificativa {...visualizarJustificativaDisclosure} />
      )}

      {deletarDisclosure.isOpen && (
        <ConfirmDialog
          visible={deletarDisclosure.isOpen}
          header="Excluir questionário"
          text="O questionário será excluído. Você realmente deseja excluir?"
          confirmText="Sim, excluir"
          onHide={() => {
            deletarDisclosure.close();
          }}
          onConfirm={() => confirmExcluir(deletarDisclosure.state)}
        />
      )}
    </>
  );
};

export default List;
