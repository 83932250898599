import { BaseSyntheticEvent, useCallback, useEffect, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';
import * as Yup from 'yup';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

export const ModalInativarCIDCIAP = ({ close, isOpen }: DisclosureType) => {
  const { selectedAtendimento, selectedCidCiap, setReload } =
    useHistoricoAtendimento();
  const { agenda } = useAppSelector((state: RootState) => state);

  const inativarComorbidadeRef = useRef<OverlayPanel>(null);

  const validationSchema = Yup.object().shape({
    justificativaInativacao: Yup.string().required(
      'Informe a justificativa da inativação',
    ),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    reset,
  } = useFormMethods;

  type CustomEvent = BaseSyntheticEvent<any, any> & {
    inativarComorbidade: boolean;
  };

  const onSubmit = useCallback(
    async (data: any, e?: any) => {
      const handleInativarComorbidade = async (data: any) => {
        const payloadComorbidade = {
          ...data,
          idProfissionalSaude: agenda?.profissionalAtivo?.id,
          dataOcorrencia: selectedCidCiap?.dataOcorrencia,
          idAtendimento: selectedAtendimento?.idAtendimento,
        };

        const responseInativarComorbidade =
          await ComorbidadeAPI.inativarComorbidade(
            selectedCidCiap?.idComorbidade,
            selectedAtendimento?.idAtendimento,
            payloadComorbidade,
          );
        if (responseInativarComorbidade < 300) {
          return;
        }
      };

      if (
        selectedCidCiap?.idComorbidade &&
        e !== undefined &&
        e.inativarComorbidade !== false
      ) {
        inativarComorbidadeRef?.current?.toggle(e);
        return;
      } else if (selectedCidCiap?.idComorbidade && e === undefined) {
        await handleInativarComorbidade(data);
      }

      const getInativarHistorico = () => {
        return selectedCidCiap?.tipo === 'CID'
          ? HistoricoAtendimentosAPI.inativarCid
          : HistoricoAtendimentosAPI.inativarCiap;
      };

      const inativarHistorico = getInativarHistorico();

      const response = await inativarHistorico(
        selectedAtendimento?.idAtendimento,
        selectedCidCiap?.id,
        data,
      );

      if (response?.status >= 200 && response?.status < 300) {
        setReload(true);
        reset();
        close();
      }
    },
    [
      agenda?.profissionalAtivo?.id,
      close,
      reset,
      selectedAtendimento?.idAtendimento,
      selectedCidCiap?.dataOcorrencia,
      selectedCidCiap?.id,
      selectedCidCiap?.idComorbidade,
      selectedCidCiap?.tipo,
      setReload,
    ],
  );

  useEffect(() => {
    if (selectedCidCiap?.ativo === false) {
      setValue(
        'justificativaInativacao',
        selectedCidCiap?.justificativaInativacao,
      );
    }
  }, [
    selectedCidCiap?.ativo,
    selectedCidCiap?.justificativaInativacao,
    setValue,
  ]);

  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header="Você tem certeza que deseja inativar?"
      visible={isOpen}
      onHide={close}
    >
      <FormProvider {...useFormMethods}>
        <form id="inativacao-atendimento">
          {selectedCidCiap?.ativo === false ? (
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
              Este item foi inativado em{' '}
              {dayjs(selectedCidCiap?.dataAtualizacao).format(
                'DD/MM/YYYY HH:mm',
              )}{' '}
              por {selectedCidCiap?.usuarioAtualizacao?.nome}.
            </SimpleText>
          ) : (
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
              Você está inativando a inclusão deste item no prontuário do
              paciente. Ele continuará ser exibido como uma informação inativa.
              Para continuar, justifique.
            </SimpleText>
          )}

          <TextareaInputControlled
            className="p-col-12"
            control={control}
            name="justificativaInativacao"
            label="Justificativa"
            placeholder="Informe a justificativa"
            errorMsg={errors.justificativaInativacao?.message}
            disabled={selectedCidCiap?.ativo === false}
          />
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              type="reset"
              label="Cancelar"
              className="p-col-align-center"
              onClick={() => {
                close();
                reset();
              }}
              stretch
            />
            <Button
              type="submit"
              label="Salvar"
              onClick={e => handleSubmit(onSubmit)(e)}
              disabled={selectedCidCiap?.ativo === false}
              loading={isSubmitting}
              stretch
            />
            <OverlayPanel ref={inativarComorbidadeRef} dismissable>
              <div>
                <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                  Você Deseja inativar a comorbidade do atendimento?
                </SimpleText>
                <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
                  <Button
                    type="submit"
                    label="Não"
                    onClick={e =>
                      handleSubmit(onSubmit)({
                        ...e,
                        inativarComorbidade: false,
                      } as CustomEvent)
                    }
                    loading={isSubmitting}
                    btnType="ghost"
                    stretch
                  />
                  <Button
                    type="submit"
                    label="Sim"
                    onClick={e => {
                      handleSubmit(onSubmit)(undefined);
                    }}
                    loading={isSubmitting}
                    stretch
                  />
                </div>
              </div>
            </OverlayPanel>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
