import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

const formatContacts = (data: any) => {
  const contatos = [];

  if (data?.CELULAR_PARTICULAR)
    contatos.push({
      numero: data.CELULAR_PARTICULAR.match(/\d/g).join(''),
      tipo: 'CELULAR_PARTICULAR',
    });

  if (data?.RECADO)
    contatos.push({
      numero: data.RECADO.match(/\d/g).join(''),
      tipo: 'RECADO',
    });

  if (data?.FIXO_RESIDENCIAL)
    contatos.push({
      numero: data.FIXO_RESIDENCIAL.match(/\d/g).join(''),
      tipo: 'FIXO_RESIDENCIAL',
    });

  if (data?.CELULAR_COMERCIAL)
    contatos.push({
      numero: data.CELULAR_COMERCIAL.match(/\d/g).join(''),
      tipo: 'CELULAR_COMERCIAL',
    });

  return contatos;
};

const formatAddress = (data: any) => {
  const {
    numero,
    complementoEndereco,
    logradouro,
    tipo,
    cep,
    bairro,
    idCidade,
    idEstado,
  } = data;

  const enderecos = [
    {
      numero,
      complemento: complementoEndereco,
      logradouro,
      tipo,
      cep,
      bairro,
      idCidade,
      cidade: { idEstado },
    },
  ];

  return enderecos;
};

export const formatData = (data: any, label: string, value: string) => {
  if (!Array.isArray(data) || !data?.length) return [];

  const dataFormatted = data.map(item => ({
    label: item[label],
    value: item[value],
  }));

  return dataFormatted;
};

export const saveDadosPessoais = async (
  idPaciente: number,
  idEmpresa: number,
  data: any,
  hideToast = false,
) => {
  const pessoaContato = data?.pessoaContato
    ? { numero: data.pessoaContato.match(/\d/g).join('') }
    : undefined;
  const cpfResponsavelLegal = data?.cpfResponsavelLegal
    ? data?.cpfResponsavelLegal?.match(/\d/g).join('')
    : null;

  const payload = {
    ...data,
    pessoaContato,
    cpfResponsavelLegal,
    idEmpresa,
  };

  return await PacienteAPI.updatePacienteDadosPessoais(
    idPaciente,
    payload,
    hideToast,
  );
};

export const saveEndereco = async (
  idPaciente: number,
  idEmpresa: number,
  data: any,
  hideToast = false,
) => {
  const enderecos = formatAddress(data);

  const payload = {
    ...data,
    enderecos,
    idEmpresa,
  };

  return await PacienteAPI.updatePacienteEnderecoCompleto(
    idPaciente,
    payload,
    hideToast,
  );
};

export const saveContato = async (
  idPaciente: number,
  idEmpresa: number,
  data: any,
  hideToast = false,
) => {
  const contatos = formatContacts(data);

  const payload = {
    ...data,
    contatos,
    idEmpresa,
  };

  return await PacienteAPI.updatePacienteContato(
    Number(idPaciente),
    payload,
    hideToast,
  );
};
