import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { validationSchemaStep1, validationSchemaStep2 } from './utils';
import { passaporteRegex, validatePasswordRules } from 'src/utils/formUtils';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Card from 'src/components/Basics/Card/Card';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import './Registro.scss';

export const Registro = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState<any>({});

  const handleNextStep = () => setStep(1);

  return (
    <div
      id="Registro-portal-container"
      className="p-d-flex p-ai-center p-jc-center"
    >
      <Card className="card-registro p-d-flex p-flex-column p-gap-4">
        {!step ? (
          <FormRegistro handleNextStep={handleNextStep} setData={setData} />
        ) : (
          <FormRegistroEtapa2 data={data} />
        )}
      </Card>

      <span className="box-bottom" />
    </div>
  );
};

const FormRegistro = ({ handleNextStep, setData }: any) => {
  const { hashEmpresa }: any = useParams();
  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(validationSchemaStep1),
  });

  const isParticular: boolean = form.watch('particular');
  const captcha = !!form.watch('captcha');
  const watchDataNasc = form.watch('dataNascimento') || 0;
  const isMenorIdade = dayjs().diff(watchDataNasc, 'year') < 18;

  useEffect(() => {
    form.setValue('isMenorIdade', isMenorIdade);
  }, [form, isMenorIdade]);

  useEffectSkipFirst(() => {
    form.reset({
      particular: isParticular,
    });
  }, [isParticular]);

  const handleSubmit = async (v: any) => {
    const isPassaporte = passaporteRegex.test(v.cpfPassaporte);

    const payload = {
      numeroCarteirinha: !v.particular
        ? v.numeroCarteirinha.replace(/ /g, '')
        : undefined,
      cpf: !isPassaporte ? v.cpfPassaporte : undefined,

      nome: v.nome,
      passaporte: isPassaporte ? v.cpfPassaporte : undefined,
      nomeMae: v.nomeMae,
      dataNascimento: v.dataNascimento
        ? dayjs(v.dataNascimento).format('YYYYMMDD')
        : '',
      particular: v.particular,
    };

    try {
      const res = await PortalPacienteAPI.getPacienteInfoBasico(
        {
          hashEmpresa,
        },
        payload,
        {
          throwError: true,
        },
      );

      setData({
        ...payload,
        nome: res.nome,
        idPaciente: res.id,
        dataNascimento: res.dataNascimento,
        nomeMae: payload?.nomeMae || res.nomeMae,
      });
      return handleNextStep();
    } catch {}
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="p-col-12 p-d-flex p-flex-column p-gap-2 p-px-0"
      >
        <SimpleText fontSize="lg">Criar conta no Portal do Paciente</SimpleText>

        <div className="p-d-flex p-jc-between">
          <SimpleText>Cadastrar como atendimento particular</SimpleText>
          <SwitchControlled control={form.control} name="particular" />
        </div>

        {!isParticular ? (
          <div className="p-d-flex p-flex-column p-gap-3">
            <FormInput
              name="numeroCarteirinha"
              label="Número Carteirinha"
              placeholder="_ ___ ____________ _"
            />

            <FormInput
              name="cpfPassaporte"
              label="CPF ou Passaporte"
              placeholder="Insira seu CPF ou passaporte"
            />
          </div>
        ) : (
          <div className="p-d-flex p-flex-column p-gap-3">
            <FormInput
              name="nome"
              label="Nome"
              sublabel="(Obrigatório)"
              placeholder="Insira seu nome completo"
              hideTextErrorSpace
            />
            <CalendarInputControlled
              control={form.control}
              name="dataNascimento"
              label="Data de Nascimento"
              sublabel="(Obrigatório)"
              placeholder="dd/mm/aaaa"
            />
            <FormInput
              name="cpfPassaporte"
              label="CPF ou Passaporte"
              sublabel={isMenorIdade ? '' : '(Obrigatório)'}
              placeholder="Insira seu CPF ou passaporte"
              hideTextErrorSpace
            />
            <FormInput
              name="nomeMae"
              label="Nome da mãe"
              sublabel={!isMenorIdade ? '' : '(Obrigatório)'}
              placeholder="Insira o nome de sua mãe"
              hideTextErrorSpace
            />
          </div>
        )}

        <CheckboxControlled
          control={form.control}
          label="Não sou um robo"
          name="captcha"
          className="p-mt-4"
        />

        <div className="p-d-flex p-gap-4 p-jc-between p-mt-4">
          <Button
            label="Fazer login"
            btnType="ghost"
            className="p-col-4"
            onClick={() => navigate(`/portal/${hashEmpresa}/login`)}
            disabled={form.formState?.isSubmitting}
          />
          <Button
            label="Continuar"
            type="submit"
            className="p-col-5"
            disabled={!captcha}
            loading={form.formState?.isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const FormRegistroEtapa2 = ({ data }: any) => {
  const { hashEmpresa }: any = useParams();
  const navigate = useNavigate();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      senha: '',
      confirmacaoSenha: '',
    },
    resolver: yupResolver(validationSchemaStep2),
  });

  const watchSenha = form.watch('senha');

  const {
    hasn8Digits,
    hasnCapitalLetter,
    hasnNumber,
    hasnSpecialCharacter,
    validateClassName,
  } = validatePasswordRules(watchSenha);

  const handleSubmit = async (v: any) => {
    const payload = {
      ...data,
      ...v,
    };

    try {
      await PortalPacienteAPI.postPacienteInfo(
        {
          hashEmpresa,
        },
        payload,
        {
          hideToast: true,
          throwError: true,
        },
      );

      toast(<Toast />, {
        data: {
          title: 'Registrado com sucesso',
          message: 'Verifique seu e-mail para confirmar o cadastro.',
          type: 'success',
        },
      });

      return navigate(`portal/${hashEmpresa}/login`);
    } catch {}
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="p-d-flex p-flex-column p-gap-2"
      >
        <SimpleText fontSize="md">Bem vindo,</SimpleText>
        <SimpleText fontSize="md" medium>
          {data.nome}
        </SimpleText>

        <Button
          label="Não sou eu"
          btnType="green-link"
          className="p-col-4 p-sm-3 p-lg-2 p-px-0"
          style={{ justifyContent: 'flex-start' }}
        />

        <FormInput
          name="email"
          label="E-mail"
          sublabel="(Obrigatório)"
          placeholder="exemplo@exemplo.com.br"
        />

        <SimpleText>Crie uma senha para seu cadastro</SimpleText>
        <PasswordInputControlled
          control={form.control}
          name="senha"
          label="Senha"
          sublabel="(Obrigatório)"
          className="p-mt-2"
          toggleMask
        />

        <div className="p-d-flex p-flex-column p-gap-2 p-mb-2">
          <SimpleText>A nova senha precisa conter, pelo menos:</SimpleText>
          <SimpleText>
            <ul className="p-px-4">
              <li className={validateClassName(hasn8Digits)}>8 dígitos</li>
              <li className={validateClassName(hasnSpecialCharacter)}>
                1 caractere especial (como !, @, #, etc.)
              </li>
              <li className={validateClassName(hasnCapitalLetter)}>
                1 letra maíuscula
              </li>
              <li className={validateClassName(hasnNumber)}>1 número</li>
            </ul>
          </SimpleText>
        </div>

        <PasswordInputControlled
          control={form.control}
          name="confirmacaoSenha"
          label="Confirme sua senha"
          sublabel="(Obrigatório)"
          toggleMask
        />

        <Button
          loading={form.formState.isSubmitting}
          className="p-mt-6"
          label="Concluir cadastro"
          type="submit"
        />
      </form>
    </FormProvider>
  );
};
