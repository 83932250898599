import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  cardapio: Cardapio;
  onConfig: () => void;
  onSwitchStatus: () => void;
  alertDivergencia: any;
  statusItem: boolean;
}

export const MobileRow = (props: MobileRowProps) => {
  const { cardapio, onConfig, onSwitchStatus, alertDivergencia, statusItem } =
    props;

  const options = [
    {
      label: statusItem ? 'Inativar' : 'Ativar',
      command: onSwitchStatus,
    },
    {
      label: 'Configuraçoes',
      command: onConfig,
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText
          className="p-mb-4"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {cardapio.funcionalidade.nome}
        </SimpleText>

        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {cardapio.funcionalidade.descricao}
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          <b>Status: </b>
          {statusItem ? 'Ativo' : 'Inativo'}
        </SimpleText>
        {alertDivergencia}
      </div>
    </SearchListMobileRow>
  );
};
