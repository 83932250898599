import { useCallback, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { AssinaturaHsmAPI } from 'src/APIs/AdminAPI/AssinaturaHsm/AssinaturaHsmAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import Toast from 'src/components/Basics/Toast/Toast';

import { EvalWebSigner } from '../../libs/eval-websigner';
import { useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';

export const useWebSigner = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [log, setLog] = useState('');
  const [license, setLicense] = useState('');
  const [userInfo, setUserInfo] = useState<any>();

  const isMountedRef = useRef<any>(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loaduserInfo = async () => {
      if (user?.idUsuario) {
        const u = await UsuarioAPI.loadUsuarioById(user.idUsuario);

        if (isMountedRef.current) setUserInfo(u);
      }
    };
    loaduserInfo();
  }, [user.idUsuario]);

  useEffect(() => {
    if (user?.tipoUsuario !== 'PROFISSIONAL_SAUDE') {
      return;
    }
    try {
      (async () => {
        const response = await ParametroAPI.loadParametrosByCodigo(
          {
            codigo: 'LICENCA_EVAL_WEB_SIGNER',
          },
          { throwError: true },
        );

        if (isMountedRef.current) setLicense(response.valor);
      })();
    } catch (error) {
      console.error(error);
    }
  }, [user?.tipoUsuario]);

  const evalWebSignerRef = useRef<any>(null);

  useEffect(() => {
    if (!license) {
      return;
    }
    evalWebSignerRef.current = new EvalWebSigner(license);
  }, [license]);

  useEffect(() => {
    if (
      !license ||
      !evalWebSignerRef.current ||
      user?.tipoUsuario !== 'PROFISSIONAL_SAUDE'
    ) {
      return;
    }

    const onReady = () => {
      setIsSuccess(true);
      setLoaded(true);
    };

    const onNotInstalled = () => {
      setIsSuccess(false);
      setLoaded(true);
      setLog('WebSigner não instalado');
    };

    const onFail = (e: any) => {
      setLog(e?.message || 'Erro ao iniciar');
      setIsSuccess(false);
      setLoaded(true);
    };

    evalWebSignerRef.current.initialize({
      success: onReady,
      notInstalled: onNotInstalled,
      fail: onFail,
    });
  }, [license, user?.tipoUsuario]);

  const handleSign = useCallback(
    async (
      docsToSign: any,
      aditionalData: {
        sequenciaAssinatura?: number;
        tipoDocumento: string;
      },
      idAtendimento?: any,
    ) => {
      if (!isSuccess) {
        toast(<Toast />, {
          data: {
            type: 'error',
            message: `${log}`,
            title: 'Erro ao iniciar assinatura eletrônica',
          },
        });
        throw new Error(log);
      }

      const { sequenciaAssinatura, tipoDocumento } = aditionalData;

      return new Promise<any>((resolve, reject) => {
        // Método de assinatura do EvalWebSigner
        const promise = evalWebSignerRef.current.sign({
          requestVersion: 2,
          signerCpf: userInfo?.dadosPessoais?.documentos?.cpf,
          // sempre CAdES
          signatureType: 'CAdES',
          signatureMode: 'sign',
          settings: {
            // policyId Subelemento do tipo string presente dentro do elemento principal settings e que é obrigatório para assinaturas nos padrões CAdES, XAdES e PAdES. Deve receber o identificador da política de assinatura. Os valores aceitos são AD-RA, AD-RB, AD-RC, AD-RT, AD-RV. COMO SABER QUAL USAR? vem por parametro.
            policyId: 'AD-RB',
            commitmentType: '',
            attachedMode: 'attached',
            icpBrOnly: false,
          },
          documents: docsToSign?.documents,
        });

        promise.success(async function (response: any) {
          const promises = response.data.map(
            async (document: any, index: any) => {
              const verify = await AssinaturaHsmAPI.verificarAssinatura({
                documentoAssinado: document.signature,
                sequenciaAssinatura: sequenciaAssinatura || document.docId,
                tipoDocumento,
              });

              if (!verify || verify.status !== 200) {
                return reject('Erro ao verificar assinatura');
              }

              if (idAtendimento) {
                const response = await AssinaturaAPI.putAssinarDocumento(
                  idAtendimento[index],
                  { assinatura: document.signature },
                );
                if (response.status === 200) {
                  return response;
                }
              }
              return response;
            },
          );

          const results = await Promise.all(promises);
          const counterAssinaturas = results.length;
          if (results[0] !== undefined && idAtendimento) {
            toast(<Toast />, {
              data: {
                type: 'success',
                message: `${counterAssinaturas} Prontuário${
                  counterAssinaturas === 1 ? '' : 's'
                } assinado${counterAssinaturas === 1 ? '' : 's'}.`,
                title: 'Sucesso',
              },
            });
          }
          resolve(results);
        });

        promise.fail(function (exception: any) {
          if (
            exception?.message ===
            'Nenhum certificado foi selecionado para assinatura digital.'
          ) {
            toast(<Toast />, {
              data: {
                type: 'warning',
                message:
                  'Nenhum certificado foi selecionado para assinatura digital. O documento ficará com assinatura pendente.',
                title: 'Aviso',
                life: 10000,
              },
            });
          } else {
            toast(<Toast />, {
              data: {
                type: 'error',
                message: 'Erro ao assinar documento.',
                title: 'Erro',
              },
            });
          }

          reject(exception);
        });
      });
    },
    [isSuccess, log, userInfo?.dadosPessoais?.documentos?.cpf],
  );

  return {
    isSuccess,
    isLoaded: loaded,
    log,
    evalWebSignerRef,
    handleSign,
  };
};
