import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { MultiSelectProps } from 'primereact/multiselect';
import { useFormContext, useController } from 'react-hook-form';

import { loadMedicosById } from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';

type FieldConsultoriosProps = MultiSelectProps & {
  name?: string;
  label: string;
};

export const FieldSelectMedicos = ({
  name = 'medicos',
  label,
  ...rest
}: FieldConsultoriosProps) => {
  const { control } = useFormContext();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [medicos, setMedicos] = useState<Medico[]>([]);

  const consultorio = consultorios?.ativo?.id;

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const loadPage = useCallback(async () => {
    if (consultorio) {
      const responseMedicos = await loadMedicosById(consultorio);

      setMedicos(responseMedicos || []);
    }
  }, [consultorio]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const options = useMemo(() => {
    const data = medicos.map(medico => ({
      label: medico?.nome,
      value: medico,
    }));

    return data;
  }, [medicos]);

  return (
    <MultiSelectControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      display="chip"
      {...rest}
    />
  );
};
