import Page from 'src/components/Basics/Page/Page';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import EmailAniversario from './EmailAniversario/EmailAniversario';

const TemplateEmail = () => {
  return (
    <Page className={`AgendamentosOnline p-w-100`}>
      <VerticalTab
        tabItens={[
          {
            label: 'Email aniversário',
            content: <EmailAniversario />,
          },
        ]}
      />
    </Page>
  );
};

export default TemplateEmail;
