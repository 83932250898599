import { useFormContext } from 'react-hook-form';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const LeucocitosPlaquetas = () => {
  const { control } = useFormContext();

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
        Leucócitos
      </SimpleText>

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="leucocitosPrimeiroTrimestre"
        label="1º Trimestre"
        maxLength={30}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="leucocitosTerceiroTrimestre"
        label="3º Trimestre"
        maxLength={30}
      />

      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
        Plaquetas
      </SimpleText>

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="plaquetasPrimeiroTrimestre"
        label="1º Trimestre"
        maxLength={30}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="plaquetasTerceiroTrimestre"
        label="3º Trimestre"
        maxLength={30}
      />
    </>
  );
};

export default LeucocitosPlaquetas;
