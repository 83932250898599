import { useEffect, useState, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { tiposUsuario, groupByModule } from './utils';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import Page from 'src/components/Basics/Page/Page';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import ModalAddConfiguracaoFuncionalidade from './ModalAddConfiguracaoFuncionalidade/ModalAddConfiguracaoFuncionalidade';
import SearchListFuncionalidades from './SearchListFuncionalidades/SearchListFuncionalidades';

import './AddPerfil.scss';

const formFields = {
  descricao: '',
};

const AddPerfil = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { tabIndex } = useLocation()?.state || {};

  const { user } = useAppSelector((state: RootState) => state);

  const [parametro, setPerfil] = useState({} as any);
  const [modalEditConfigFuncionalidade, setModalEditConfigFuncionalidade] =
    useState(false);
  const [funcionalidadeSelecionada, setFuncionalidadeSelecionada] =
    useState<any>({} as any);
  const [idPerfil, setIdPerfil] = useState(0);
  const [listaPerfil, setListaPerfil] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    descricao: Yup.string()
      .max(30, 'Deve conter no máximo 30 caractéres')
      .required('Nome é obrigatório'),
    tipoUsuario: Yup.string().required('O campo é obrigatório'),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting, errors },
  } = useFormMethods;

  const goBack = useCallback(
    () => navigate('/config/cardapio', { state: tabIndex }),
    [navigate, tabIndex],
  );

  const loadParametro = useCallback(async () => {
    if (!params?.id) return;
    try {
      setLoading(true);

      const perfilId = parseInt(params?.id);

      const perfilEncontrado: Perfil = await PerfisAPI.loadPerfilById(
        perfilId,
        { throwError: true },
      );

      setValue('descricao', perfilEncontrado.descricao);
      setValue('descricaoCompleta', perfilEncontrado?.descricaoCompleta || '');
      setValue('tipoUsuario', perfilEncontrado.tipoUsuario);

      setIdPerfil(perfilId);
      setPerfil(perfilEncontrado);

      if (perfilEncontrado?.perfilFuncionalidades?.length > 0) {
        return setListaPerfil(
          groupByModule(perfilEncontrado?.perfilFuncionalidades),
        );
      }
    } catch (error) {
      goBack();
    } finally {
      setLoading(false);
    }
  }, [goBack, params?.id, setValue]);

  useEffect(() => {
    loadParametro();
    //if (user?.idEmpresa) loadParametro();
  }, [loadParametro, user?.idEmpresa]);

  const handleModalEditConfigFuncionalidadeClose = useCallback(() => {
    setModalEditConfigFuncionalidade(false);
  }, []);

  const handleConfigFuncionalidade = useCallback((item: any) => {
    setFuncionalidadeSelecionada(item?.funcionalidade);
    setModalEditConfigFuncionalidade(true);
  }, []);

  const savePerfil = (payload: any, idPerfil?: any) => {
    if (idPerfil) return PerfisAPI.updatePerfil(idPerfil, payload);

    return PerfisAPI.sendPerfil(payload);
  };

  const handleAddPerfil = useCallback(
    async (data: any) => {
      const response: any = await savePerfil(data, idPerfil);

      if (response.status === 200) {
        goBack();
      }
    },
    [goBack, idPerfil],
  );

  if (loading) {
    return (
      <PageContainer white>
        <div className="p-px-5">
          <GridListLoading />
        </div>
      </PageContainer>
    );
  }

  const tiposUsuarioFiltered = !!user?.idEmpresa
    ? tiposUsuario.filter(tipo => tipo.value !== 'MASTER')
    : tiposUsuario;

  return (
    <PageContainer white>
      <div className="modal-body-add-perfil">
        <FormProvider {...useFormMethods}>
          <form
            className="p-grid p-col-12"
            onSubmit={handleSubmit(handleAddPerfil)}
            defaultValue={parametro}
            onReset={() => reset(formFields)}
          >
            <FormInput
              className="p-mt-3 p-col-12 p-md-8 p-lg-6"
              name="descricao"
              label="Nome do perfil"
              hideTextErrorSpace
            />
            <TextareaInputControlled
              className="p-col-12 p-md-8 p-lg-6 p-mr-1"
              control={control}
              name="descricaoCompleta"
              label="Descrição"
              maxLength={500}
              autoResize
            />
            <DropdownControlled
              className="p-col-12 p-md-8 p-lg-6 p-mr-1"
              control={control}
              label="Perfil de usuário"
              options={tiposUsuarioFiltered}
              name="tipoUsuario"
              errorMsg={errors.tipoUsuario?.message}
            />

            {listaPerfil?.length > 0 && (
              <SearchListFuncionalidades
                data={listaPerfil}
                handleConfigFuncionalidade={handleConfigFuncionalidade}
              />
            )}
            <div className="p-col-12 p-grid p-gap-2">
              <Button
                btnType="ghost"
                label="Cancelar e voltar"
                className="p-col"
                onClick={goBack}
                loading={isSubmitting}
              />

              <Button
                className="p-col-12 p-md-8"
                label="Salvar"
                type="submit"
                loading={isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>

      <ModalAddConfiguracaoFuncionalidade
        visible={modalEditConfigFuncionalidade}
        onHide={handleModalEditConfigFuncionalidadeClose}
        funcionalidadeSelecionada={funcionalidadeSelecionada}
      />
    </PageContainer>
  );
};

interface PageContainerProps {
  children: JSX.Element | JSX.Element[];
  white?: boolean;
}

const PageContainer = ({ children, white = false }: PageContainerProps) => (
  <Page className="container" white={white}>
    <>
      <PageHeader
        title="Configurar perfil de usuário"
        subtitle="Gerencie as funcionalidades do sistema eMed por usuário"
      />

      {children}
    </>
  </Page>
);

export default AddPerfil;
