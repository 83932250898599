import { useCallback, useEffect, useMemo, useState } from 'react';

import MedicoAPI from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import PickList from 'src/components/PickList/PickList';
import Switch from 'src/components/Switch/Switch';
import Tab from 'src/components/Tab/Tab';

interface DestinatarioProps {
  onSubmit(v: any): void;
  consultoriosOptions: any[];
  handleNavigate(): void;
  values: any;
}

const Destinatario = ({
  values,
  onSubmit,
  consultoriosOptions,
  handleNavigate,
}: DestinatarioProps) => {
  const [consultoriosSelected, setConsultoriosSelected] = useState<any>(
    values.consultorios || [],
  );
  const modalAddConsultoriosDisclosure = useDisclosure({
    opened: false,
  });
  const [isInativoConsultorio, setIsInativoConsultorio] = useState<boolean>(
    values.todosConsultorios || false,
  );

  const [medicos, setMedicos] = useState<GetMedicoDTO[]>([]);
  const modalAddMedicosDisclosure = useDisclosure({
    opened: false,
  });
  const [medicosSelected, setMedicosSelected] = useState<any>(
    values.medicos || [],
  );
  const [isInativoMedico, setIsInativoMedico] = useState<boolean>(
    values.todosMedicos || false,
  );

  useEffect(() => {
    if (consultoriosSelected.length === 0 || isInativoConsultorio) {
      setMedicosSelected([]);
    }
  }, [consultoriosSelected.length, isInativoConsultorio]);

  const getMedicos = useCallback(async () => {
    if (consultoriosSelected.length === 0) {
      return;
    }
    const idConsultorios = consultoriosSelected.map(
      (consultorio: any) => consultorio.value.id,
    );

    const params = {
      idsConsultorios: String(idConsultorios),
      envioSms: true,
    };

    try {
      const response: any = await MedicoAPI.loadMedicos(params, {
        throwError: true,
      });
      const medicosArray: any = [];

      Object.keys(response).map((key: any) =>
        medicosArray.push(...response[key]),
      );

      if (response && !('status' in response)) {
        setMedicos(medicosArray);
      }
    } catch (error) {
      handleNavigate();
    }
  }, [consultoriosSelected, handleNavigate]);

  useEffect(() => {
    getMedicos();
  }, [getMedicos]);

  const medicosOptions = useMemo(
    () =>
      medicos?.map(medico => ({
        label: medico.nome,
        value: {
          id: medico.id,
          nome: medico.nome,
        },
      })),
    [medicos],
  );

  const renderConsultorios = () => {
    const handleInative = (e: any) => {
      if (e.value) {
        modalAddConsultoriosDisclosure.open();
      } else {
        setConsultoriosSelected([]);
        setIsInativoConsultorio(false);
      }
    };

    return (
      <div className="p-grid p-flex-column">
        <SimpleText
          bold
          className="p-my-2"
          fontSize={FONT_SIZE.SM}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Todos os consultórios
        </SimpleText>

        <div className="p-d-flex p-ai-center p-mb-1">
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
            Inativo
          </SimpleText>

          <Switch
            className="p-ml-3"
            checked={isInativoConsultorio}
            onChange={handleInative}
          />
        </div>

        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Quando ativo todos os consultórios serão considerados como
          selecionados.
        </SimpleText>

        <SimpleText
          bold
          className="p-mt-3"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Filtre os resultados utilizando o campo de pesquisa abaixo.
        </SimpleText>

        <PickList
          label="Consultórios"
          disabled={isInativoConsultorio}
          setSelected={setConsultoriosSelected}
          arraySelected={consultoriosSelected}
          arrayOptions={consultoriosOptions}
        />

        {modalAddConsultoriosDisclosure.isOpen && (
          <ConfirmDialog
            visible={modalAddConsultoriosDisclosure.isOpen}
            header="Deseja ativar todos os consultório?"
            text="Você realmente deseja ativar todos os consultórios? Seleções ja feitas serão perdidas!"
            confirmText="Sim, ativar"
            confirmBtnType="filled"
            onHide={() => {
              modalAddConsultoriosDisclosure.close();
            }}
            onConfirm={() => {
              setIsInativoConsultorio(true);
              setIsInativoMedico(false);
              setConsultoriosSelected([]);
              modalAddConsultoriosDisclosure.close();
            }}
          />
        )}
      </div>
    );
  };

  const renderMedicos = () => {
    const handleInative = (e: any) => {
      if (e.value) {
        modalAddMedicosDisclosure.open();
      } else {
        setMedicosSelected([]);
        setIsInativoMedico(false);
      }
    };

    return (
      <>
        <div className="p-grid p-flex-column">
          <SimpleText
            bold
            className="p-my-2"
            fontSize={FONT_SIZE.SM}
            fontColor={FONT_COLOR.COLOR_16}
          >
            Todos os médicos
          </SimpleText>

          <div className="p-d-flex p-ai-center p-mb-1">
            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
              Inativo
            </SimpleText>

            <Switch
              className="p-ml-3"
              checked={isInativoMedico}
              onChange={handleInative}
            />
          </div>

          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Quando ativo todos os médicos serão considerados como selecionados.
          </SimpleText>

          <SimpleText
            bold
            className="p-mt-3"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            Filtre os resultados utilizando o campo de pesquisa abaixo.
          </SimpleText>

          <PickList
            label="Médicos"
            disabled={isInativoMedico}
            setSelected={setMedicosSelected}
            arraySelected={medicosSelected}
            arrayOptions={medicosOptions}
          />
        </div>

        {modalAddMedicosDisclosure.isOpen && (
          <ConfirmDialog
            visible={modalAddMedicosDisclosure.isOpen}
            header="Deseja ativar todos os médicos?"
            text="Você realmente deseja ativar todos os médicos? Seleções ja feitas serão perdidas!"
            confirmText="Sim, ativar"
            confirmBtnType="filled"
            onHide={() => {
              modalAddMedicosDisclosure.close();
            }}
            onConfirm={() => {
              setIsInativoMedico(true);
              setIsInativoConsultorio(false);
              setMedicosSelected([]);
              modalAddMedicosDisclosure.close();
            }}
          />
        )}
      </>
    );
  };

  const submit = useCallback(() => {
    onSubmit({
      medicos: medicosSelected,
      todosMedicos: isInativoMedico,

      consultorios: consultoriosSelected,
      todosConsultorios: isInativoConsultorio,
    });
  }, [
    consultoriosSelected,
    isInativoConsultorio,
    isInativoMedico,
    medicosSelected,
    onSubmit,
  ]);

  return (
    <div className="p-grid p-col-12 p-xl-10 p-pl-2 p-gap-2">
      <div className="novoLembrete-content">
        <SimpleText
          className="p-mb-3"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Selecione o destinatário por consultórios e médicos.
        </SimpleText>

        <Tab
          className="p-mt-1"
          values={[
            {
              label: 'Consultório',
              content: renderConsultorios(),
            },

            {
              label: 'Médicos',
              content: renderMedicos(),
              disabled:
                isInativoConsultorio || consultoriosSelected.length === 0,
            },
          ]}
        />
      </div>

      <Button
        className="p-my-1 p-col-3"
        label="Cancelar"
        btnType="ghost"
        onClick={handleNavigate}
      />

      <Button
        className="p-my-1 p-col-4"
        label="Próximo"
        onClick={submit}
        disabled={!isInativoConsultorio && !consultoriosSelected.length}
        btnType="tonal"
      />
    </div>
  );
};

export default Destinatario;
