export const getLabelFuncionalidades: { [key: string]: string } = {
  ANAMNESE: 'Anamnese',
  HISTORICO_PESSOAL: 'Histórico pessoal',
  HISTORICO_FAMILIAR: 'Histórico familiar',
  ALEITAMENTO_ALIMENTACAO: 'Aleitamento / Alimentação',
  EXAME_FISICO: 'Exame fisico',
  PATOLOGIA_DETECTADA: 'Patologia detectada',
  PROGNOSTICO: 'Prognóstico',
  CIAP: 'CIAP',
  CID: 'CID',
  DECISAO_TERAPEUTICA: 'Decisão terapêutica',
  PEDIDO_CUIDADO: 'Pedido de cuidado',
  ENCAMINHAMENTO: 'Encaminhamento',
  CONTRARREFERENICA: 'Contra-referência',
  DESFECHO_ATENDIMENTO: 'Desfecho atendimento',
  OBSTETRICIA_PRE_ATENDIMENTO: 'Histórico pessoal',
  PRE_ATENDIMENTO_GLICEMIA: 'Histórico familiar',
  PRE_ATENDIMENTO_PERIMETRO_CEFALICO: 'Pré atendimento Perimetro cefálico',
  PRE_ATENDIMENTO_PULSO: 'Pré atendimento Pulso',
  PRE_ATENDIMENTO_APARENCIA: 'Pré atendimento Aparência',
  PRE_ATENDIMENTO_DOR: 'Pré atendimento Dor',
  PRE_ATENDIMENTO_CIRCUNFERENCIA_BRACO:
    'Pré atendimento Circunferência do braço',
  PRE_ATENDIMENTO_CIRCUNFERENCIA_CINTURA:
    'Pré atendimento Circunferência da cintura',
  PRE_ATENDIMENTO_CIRCUNFERENCIA_PANTURRILHA:
    'Pré atendimento Circunferência da panturrilha',
  PRE_ATENDIMENTO_CIRCUNFERENCIA_QUARIL:
    'Pré atendimento Circunferência do quadril',
  PRE_ATENDIMENTO_CIRCUNFERENCIA_ABDOMINAL:
    'Pré atendimento Circunferência abdominal',
  PRE_ATENDIMENTO_CIRCUNFERENCIA_TEMPERATURA: 'Pré atendimento Temperatura',
  PRE_ATENDIMENTO_SATURACAO: 'Pré atendimento Saturação',
  PRE_ATENDIMENTO_PRESSÃO: 'Pré atendimento Pressão',
  PRE_ATENDIMENTO_PESO_ALTURA: 'Pré atendimento Peso e altura',
  PRE_ATENDIMENTO_RESPIRACAO: 'Pré atendimento Respiração',
  PRE_ATENDIMENTO_TEMPERATURA: 'Pré atendimento Temperatura',
  PRE_ATENDIMENTO_PRESSAO: 'Pré atendimento Pressão',
  MEDICAMENTO_USO: 'Medicamento em uso',
  MOTIVO_ATENDIMENTO: 'Motivo de atendimento',
  VACINA: 'Vacina',
  OPME: 'OPME',
  ALERGIA_INTOLERANCIA: 'Alergia / Intolerância',
  AVALIACAO_ENFERMAGEM: 'Avaliação enfermagem',
  CUIDADO_PROGRAMADO_EXECUCAO: 'Cuidado programado em execução',
  PRESCRICAO_EXECUCAO: 'Prescrição execução',
  HISTORICO_PRE_NATAL: 'Histórico Pré-natal',
  PUERICULTURA_PRE_NATAL: 'Puericultura Pré-natal',
  DNPM: 'DNPM',
  AGA: 'AGA',
  SOLICITACAO_EXAME: 'Solicitação de exame',
  TIPO_CONSULTA: 'Tipo de consulta',
  RECEITA: 'Receita',
  ENCAMINHAMENTO_CONTRARREFERENCIA: 'Encaminhamento e Contrarreferencia',
  PRESCRICAO: 'Prescrição',
  EXECUCAO_PROCEDIMENTOS: 'Execução de procedimentos',
  IMPRESSO: 'Impressos',
  RECEITA_OCULOS: 'Receita óculos',
};

export const convertFieldKeyToCamelCase = (field: {
  [key: string]: string;
}) => {
  const originalKey = Object.keys(field)[0];

  if (!originalKey) return;

  let newKey = originalKey
    .toLowerCase()
    .replace(/_(.)/g, (_, group1) => group1.toUpperCase());
  // Renomear a chave específica
  newKey = newKey === 'desfechoAtendimento' ? 'desfechoEmpresa' : newKey;

  const newField = {
    [newKey]: field[originalKey],
  };

  return newField;
};
