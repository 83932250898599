import * as Yup from 'yup';

const validationSchema = (camposDemograficosObrigatorios: boolean) => (
  Yup.object().shape({
    ...(camposDemograficosObrigatorios && {
      numero: Yup.string().required('O campo número é obrigatório'),
      complementoEndereco: Yup.string().required('O campo complemento é obrigatório'),
      logradouro: Yup.string().required('O campo logradouro é obrigatório'),
      tipo: Yup.string().required('O campo tipo de logradouro é obrigatório'),
      cep: Yup.string().required('O campo CEP é obrigatório'),
      bairro: Yup.string().required('O campo bairro é obrigatório'),
      idCidade: Yup.string().required('O campo cidade é obrigatório'),
      idEstado: Yup.string().required('O campo estado é obrigatório')
    }),
  })
);

export default validationSchema;