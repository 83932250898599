import { useCallback, useState } from 'react';

import { CardapioAPI } from 'src/APIs/AdminAPI/CardapioAPI/CardapioAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { groupBy } from 'src/utils/utils';

import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DialogEditarFuncionalidade } from './DialogEditarFuncionalidade/DialogEditarFuncionalidade';
import { Row } from './List/Row';

import './Clientes.scss';

type ModulosTypes = {
  [key: string]: string;
};

const modulosTypes: ModulosTypes = {
  AGE: 'Agenda',
  PRO: 'Prontuário',
  ADM: 'Administrativo',
  FIN: 'Financeiro',
  HOM: 'Home Care',
  AGA: 'Aga',
  MON: 'Monitoramento',
  REL: 'Relatório',
  SUP: 'Suporte',
  AGO: 'Agenda online',
  TRI: 'Triagem',
  PRE: 'Prescrição',
};

const Clientes = () => {
  const [reloadSearchList, setReloadSearchList] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const dialogEditar = useDisclosure({ opened: false });

  const fetchApi = async () => {
    return await CardapioAPI.loadCardapios();
  };

  const reloadList = () => {
    setReloadSearchList(currentReload => currentReload + 1);
  };

  const handleConfig = useCallback(
    ({ funcionalidade }: Cardapio) => {
      dialogEditar.open({
        state: funcionalidade,
      });
    },
    [dialogEditar],
  );

  const renderRow = useCallback(
    (cardapio: Cardapio) => <Row cardapio={cardapio} onConfig={handleConfig} />,
    [handleConfig],
  );

  const renderRows = useCallback(
    (cardapios: Cardapio[]) => {
      const cardapiosFiltered = cardapios.filter(cardapio =>
        cardapio.funcionalidade.nome
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      );
      const cardapioWithModulo = cardapiosFiltered.map(cardapio => ({
        ...cardapio,
        modulo: cardapio.funcionalidade.modulo,
      }));

      const cardapiosFormatted: [string, Cardapio[]][] = Object.entries(
        groupBy(cardapioWithModulo, 'modulo'),
      );

      return cardapiosFormatted.map(
        ([modulo, cardapiosFiltered], moduloIndex) => (
          <div key={moduloIndex}>
            {!!cardapiosFiltered.length && (
              <div className="p-d-flex p-flex-column p-mb-4">
                <SimpleText
                  bold
                  className="p-mb-3"
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XS}
                >
                  {modulosTypes[modulo]}
                </SimpleText>
                {cardapiosFiltered.map((cardapio: Cardapio) => (
                  <div key={cardapio.idFuncionalidade}>
                    {renderRow(cardapio)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ),
      );
    },
    [renderRow, searchQuery],
  );

  return (
    <Page className="Clientes" white>
      <>
        <div className="p-p-3">
          <SearchList.Root
            fetchApi={fetchApi}
            paginatedList={false}
            empresaRequired
            reloadWhenEmpresaChange
          >
            <div className="p-col-12 p-md-6 p-mb-3">
              <TextInputSearch
                placeholder="Pesquise uma função"
                value={searchQuery}
                onChange={setSearchQuery}
              />
            </div>
            <SearchList.NonPageableScrollArea
              renderRows={renderRows}
              reload={reloadSearchList}
            />
          </SearchList.Root>
        </div>

        {dialogEditar.isOpen && (
          <DialogEditarFuncionalidade
            reloadList={reloadList}
            {...dialogEditar}
          />
        )}
      </>
    </Page>
  );
};

export default Clientes;
