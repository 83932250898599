import { ReactNode, useState, useEffect } from 'react';

import ThemeContext, {
  DarkTheme,
  LightTheme,
  ThemeType,
} from 'src/core/themes/ThemeContext';

import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { storedValue: savedTheme, setValue: saveTheme } =
    useLocalStorage('globalTheme');

  const [theme, setTheme] = useState<ThemeType>(savedTheme || LightTheme);

  useEffect(() => {
    saveTheme(theme);

    const body = document.body;

    body.className = theme;
  }, [saveTheme, theme]);

  const toogleTheme = () => {
    setTheme(prev => {
      if (prev === LightTheme) {
        return DarkTheme;
      }
      return LightTheme;
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toogleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
