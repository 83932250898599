import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { FormularioDinamicoAplicacaoFormularioFilter } from './FormularioDinamicoAplicacaoFormularioFilter';
import { FormularioDinamicoAplicacaoFormularioRow } from './FormularioDinamicoAplicacaoFormularioRow';
import { FormularioDinamicoProvider } from './useFormularioDinamicoAplicacaoFormulario';

export const FormularioDinamicoAplicacaoFormulario = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <>
        <PageHeader
          title="Aplicação de formulários"
          subtitle="Crie aplicações e vínculos para os formulários"
          hasBreadcrumbs
          backButton
          onClickBackButton={() => navigate('/cadastros/formulario-dinamico')}
        />

        <Page className="p-pt-0" content white>
          <FormularioDinamicoProvider>
            <FormularioDinamicoAplicacaoFormularioContent />
          </FormularioDinamicoProvider>
        </Page>
      </>
    </Page>
  );
};

const FormularioDinamicoAplicacaoFormularioContent = () => {
  const [reload, setReload] = useState(0);

  const navigate = useNavigate();

  const fetchApi = ({ query, ...props }: any) => {
    // eslint-disable-next-line react/prop-types
    delete props.convenio, delete props.formulario;

    return AplicacaoFormularioDinamicoAPI.loadAplicacoesFormulario({
      nome: query,
      sortDirection: 'ASC',
      sortBy: 'nome',
      ...props,
    });
  };

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  const handleView = (id: number) => {
    navigate(`/cadastros/formulario-dinamico/aplicacao/${id}`);
  };

  const handleEdit = (id: number) => {
    navigate(`/cadastros/formulario-dinamico/aplicacao/${id}`, {
      state: { to: 'edit' },
    });
  };

  const handleCancel = async (item: IAplicacaoFormularioDinamicoItem) => {
    try {
      await AplicacaoFormularioDinamicoAPI.updateAplicacaoFormulario(
        {
          ...item,
          situacao: 'CANCELADO',
        },
        { throwError: true },
      );

      reloadList();
    } catch {}
  };

  return (
    <SearchList.Root fetchApi={fetchApi} empresaRequired>
      <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-gap-2">
        <SearchList.SearchInput
          label="Pesquise por nome"
          placeholder="Busque pelo nome do formulário"
          className="p-col-12 p-md-6 p-lg-3"
        />

        <Button
          label="Pesquisa avançada"
          btnType="tonal"
          icon="fas fa-chevron-down"
          className="p-col-12 p-md-4 p-lg-3 p-xl-2"
          iconPos="right"
          overlay={e => (
            <FormularioDinamicoAplicacaoFormularioFilter
              onSuccess={() => e?.hide()}
            />
          )}
        />

        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-mb-4">
            <Button
              label="Nova aplicação"
              icon="fas fa-plus"
              className="p-col-12 p-md-6 p-lg-3"
              btnType="pill"
              checkPermission={
                'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_CADASTRAR'
              }
              onClick={() =>
                navigate('/cadastros/formulario-dinamico/aplicacao/novo')
              }
            />
            <SearchList.TotalCount className="p-col-12 p-md-6 p-lg-3 p-text-right" />
          </div>

          <SearchList.InfiniteScroll
            reload={reload}
            renderRow={(item: IAplicacaoFormularioDinamicoItem) => (
              <FormularioDinamicoAplicacaoFormularioRow
                handleCancel={handleCancel}
                handleView={handleView}
                handleEdit={handleEdit}
                item={item}
              />
            )}
          />
        </SearchList.BorderContainer>
      </div>
    </SearchList.Root>
  );
};
