import { useState, useEffect } from 'react';

import {
  ListBox as PrimeListBox,
  ListBoxProps as PrimeListBoxProps,
} from 'primereact/listbox';

import useDebounce from 'src/utils/useDebounce';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import './CheckboxSearch.scss';

interface ListBoxValue {
  id: number;
  nome: string;
}

function customItemTemplate(option: any, selecionados: ListBoxValue[]) {
  const selecionado = option.value?.id
    ? selecionados?.some(selecionado => selecionado.id === option.value.id) ||
      false
    : selecionados?.includes(option.value) || false;

  return (
    <div
      className={`modelo-listbox-options p-d-flex ${
        selecionado && 'selecionado'
      }`}
    >
      <Checkbox checked={selecionado} />
      <span>{option.label}</span>
    </div>
  );
}

interface CheckboxSearchProps
  extends Omit<PrimeListBoxProps, 'onChange' | 'itemTemplate'> {
  value: any[];
  fetchApi: (params: any) => any;
  formatOptions: (options: any) => any[];
  onChange: (values: any[]) => void;
  itemTemplate?: (option: any, value: any) => any;
}

export const CheckboxSearch = (props: CheckboxSearchProps) => {
  const { fetchApi, value, formatOptions, onChange, ...rest } = props;

  const [filter, setFilter] = useState<string>('');
  const [options, setOptions] = useState<any>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const debouncedQuery = useDebounce(filter, 350);

  useEffect(() => {
    const fetch = async (query: string) => {
      setLoading(true);

      const response = await fetchApi({ query });

      const formattedOptions = formatOptions(response);

      setOptions(formattedOptions);

      setLoading(false);
    };

    if (debouncedQuery.length >= 3) fetch(debouncedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    const checkSelectedAll = options.every((option: any) =>
      value.includes(option.value),
    );

    setSelectedAll(checkSelectedAll);
  }, [options, value]);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleClearFilter = () => {
    setFilter('');
    setOptions([]);
  };

  const itemTemplate = (option: any) => {
    if (rest?.itemTemplate) return rest?.itemTemplate(option, value);

    return customItemTemplate(option, value);
  };

  const render = () => {
    if (loading) {
      return (
        <div className="p-mt-5">
          <GridListLoading />
        </div>
      );
    }

    if (!options.length && filter.length < 3) {
      return (
        <div className="p-mt-3">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Digite para pesquisar
          </SimpleText>
        </div>
      );
    }

    if (!options.length && filter.length >= 3) {
      return (
        <div className="p-mt-3">
          <SimpleText
            className="p-mt-3"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Nenhum resultado encontrado.
          </SimpleText>
        </div>
      );
    }

    return (
      <>
        <div className="modelo-listbox-buttons p-d-flex p-jc-between">
          <Checkbox
            label="Selecionar todos"
            checked={selectedAll}
            onChange={() => {
              if (selectedAll) return onChange([]);

              onChange(options.map((option: any) => option.value));
            }}
          />
          <Button
            btnType="green-link"
            label="Limpar"
            onClick={() => onChange([])}
          />
        </div>

        <PrimeListBox
          {...rest}
          options={options}
          value={value}
          onChange={e => onChange(e.value)}
          itemTemplate={itemTemplate}
          multiple
          listClassName="checkbox-search"
        />
      </>
    );
  };

  return (
    <>
      <div className="TextInputSearch">
        <span
          className={`p-input-icon-left ${filter ? 'p-input-icon-right' : ''}`}
        >
          <i className="pi pi-search" style={{ marginRight: 5 }} />
          <TextInput value={filter} onChange={handleFilter} />
          {!!filter && (
            <i className="pi pi-times" onClick={handleClearFilter} />
          )}
        </span>
      </div>

      {render()}
    </>
  );
};
