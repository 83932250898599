import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import CentroCustoAPI from 'src/APIs/AdminAPI/CentroCustoAPI/CentroCustoAPI';

import useSize from 'src/core/hooks/useSize';

import ConsultorioMultiSelect from '../components/ConsultorioMultiSelect';
import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Header from './CentroCustoContent/ManterCusto/Grid/Header';
import Row from './CentroCustoContent/ManterCusto/Grid/Row';

const CentroCusto = () => {
  const [reload, setReload] = useState(0);
  const navigate = useNavigate();
  const { isMobile, isTablet } = useSize();

  const useFormMethods = useForm();

  const { getValues } = useFormMethods;

  const fetchApi = async ({ query, ...params }: any): Promise<any> => {
    const idConsultorio = getValues('idConsultorio') || [];

    const response = await CentroCustoAPI.getCentroCusto({
      nome: query,
      ...(idConsultorio.length > 0 &&
        !idConsultorio.includes('todos') && {
          consultoriosIds: idConsultorio.join(','),
        }),
      ...params,
    });
    return response || [];
  };

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const renderRow = (data: any) => {
    return <Row data={data} reloadList={reloadList} />;
  };

  return (
    <Page white content title="Centro de custo" className="p-px-0">
      <>
        <SimpleText className="p-m-0 p-py-0" fontSize={FONT_SIZE.MD} medium>
          Centro de custo
        </SimpleText>
        <SearchList.Root fetchApi={fetchApi}>
          <FormProvider {...useFormMethods}>
            <form className="p-grid p-ai-end p-my-2 p-gap-1">
              <SearchList.SearchInput
                placeholder="Pesquise um centro de custo"
                className=" p-col-12 p-md-5 p-lg-4 p-mb-1"
              />
              <ConsultorioMultiSelect className="p-col-12 p-md-5 p-lg-4" />
              <div className={`p-col-12 p-sm-3 p-md-2 p-lg-1`}>
                <Button
                  label="Filtrar"
                  btnType="tonal"
                  onClick={() => reloadList()}
                  type="button"
                  stretch
                />
              </div>
            </form>
          </FormProvider>
          <SearchList.BorderContainer>
            <Button
              className="p-mb-2"
              label="Novo centro de custo"
              icon="pi pi-plus"
              iconPos="left"
              btnType="pill"
              onClick={() => navigate('/faturamento/centro-custo/novo')}
              stretch={isMobile}
              checkPermission="ADM_MANTER_CENTRO_CUSTO_CADASTRAR"
            />
            {!isTablet && <Header />}
            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </>
    </Page>
  );
};

export default CentroCusto;
