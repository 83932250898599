import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

type BuscaRapidaProps = {
  favoriteProcedimentoChanged: any;
  handleRemoveProcedimento: (procedimentoId: number) => void;
  setProcedimentos: React.Dispatch<React.SetStateAction<any[]>>;
};

export const useBuscaRapidaFavoriteChanged = (props: BuscaRapidaProps) => {
  const {
    favoriteProcedimentoChanged,
    handleRemoveProcedimento,
    setProcedimentos,
  } = props;

  const handleFavoriteProcedimentoChanged = (procedimento: any) => {
    if (!procedimento) return;

    const { isFavorite, ...restProcedimento } = procedimento;

    if (!isFavorite) {
      handleRemoveProcedimento(procedimento.id);
    }

    return setProcedimentos(prevProcedimentos => [
      ...prevProcedimentos,
      restProcedimento,
    ]);
  };

  useEffectSkipFirst(() => {
    handleFavoriteProcedimentoChanged(favoriteProcedimentoChanged);
  }, [favoriteProcedimentoChanged]);
};
