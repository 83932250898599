import { useEffect, useState } from 'react';

import FuncionalidadeAPI from 'src/APIs/AdminAPI/FuncionalidadeAPI/FuncionalidadeAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { groupBy } from 'src/utils/utils';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DialogEditarFuncionalidade } from './DialogEditarFuncionalidade/DialogEditarFuncionalidade';
import { Row } from './List/Row';

import './Funcionalidades.scss';

type ModulosTypes = {
  [key: string]: string;
};

const modulosTypes: ModulosTypes = {
  AGE: 'Agenda',
  PRO: 'Prontuário',
  ADM: 'Administrativo',
  FIN: 'Financeiro',
  HOM: 'Home Care',
  AGA: 'Aga',
  MON: 'Monitoramento',
  REL: 'Relatório',
  SUP: 'Suporte',
  AGO: 'Agenda online',
  TRI: 'Triagem',
  PRE: 'Prescrição',
};

const Funcionalidades = () => {
  const dialogEditar = useDisclosure({ opened: false });

  const [reloadSearchList, setReloadSearchList] = useState(0);

  const fetchApi = async ({ query, ...params }: any): Promise<any> => {
    return await FuncionalidadeAPI.loadFuncionalidades({
      nome: query,
      ...params,
    });
  };

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_funcionalidade_alterar',
      'adm_funcionalidade_consultar',
    ],
  });

  const reloadList = () => {
    setReloadSearchList(currentReload => currentReload + 1);
  };

  const handleConfig = (funcionalidade: Funcionalidade) => {
    dialogEditar.open({
      state: funcionalidade,
    });
  };

  const handleSwitchStatus = async (funcionalidade: Funcionalidade) => {
    const switchAction: any = funcionalidade?.status
      ? FuncionalidadeAPI.updateFuncionalidadeInativar
      : FuncionalidadeAPI.updateFuncionalidadeAtivar;

    const response = await switchAction(funcionalidade?.id as number);

    if (response?.status === 200) {
      reloadList();
    }
  };

  const renderRow = (funcionalidade: Funcionalidade) => (
    <Row
      funcionalidade={funcionalidade}
      onConfig={handleConfig}
      onSwitchStatus={handleSwitchStatus}
    />
  );

  const renderRows = (funcionalidades: Funcionalidade[]) => {
    const funcionalidadesFormatted: [string, Funcionalidade[]][] =
      Object.entries(groupBy(funcionalidades, 'modulo'));

    return funcionalidadesFormatted.map(
      ([modulo, funcionalidades], moduloIndex) => (
        <div key={moduloIndex}>
          {!!funcionalidades.length && (
            <div className="p-d-flex p-flex-column p-mb-4">
              <SimpleText
                bold
                className="p-mb-3"
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
              >
                {modulosTypes[modulo]}
              </SimpleText>
              {funcionalidades.map((funcionalidade: Funcionalidade) => (
                <div key={funcionalidade.id}>{renderRow(funcionalidade)}</div>
              ))}
            </div>
          )}
        </div>
      ),
    );
  };

  return (
    <Page className="Funcionalidades" white>
      <>
        <div className="p-p-3">
          <SearchList.Root fetchApi={fetchApi}>
            <SearchList.SearchInput placeholder="Pesquise uma função" />

            <SearchList.InfiniteScroll
              renderRows={renderRows}
              reload={reloadSearchList}
            />
          </SearchList.Root>
        </div>

        {dialogEditar.isOpen && (
          <DialogEditarFuncionalidade
            reloadList={reloadList}
            {...dialogEditar}
          />
        )}

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </>
    </Page>
  );
};

export default Funcionalidades;
