import { useCallback } from 'react';

import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import { useUser } from 'src/core/hooks/User/User';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useIPAdress } from 'src/utils/hooks/useIPAdress';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Avatar from 'src/components/Avatar/Avatar';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Sidebar from 'src/components/Sidebar/Sidebar';

import './SupportAssistenteVirtual.scss';

export const SupportAssistenteVirtual = ({ close }: DisclosureType) => {
  const { user } = useUser();

  const { IPv4 } = useIPAdress();

  const dialogAssistenteVirtual = useDisclosure({ opened: false });

  return (
    <Sidebar
      onHide={close}
      visible
      modal={false}
      position="right"
      header={
        <SimpleText fontSize="lg" medium>
          Obter Suporte
        </SimpleText>
      }
    >
      <div className="p-d-flex p-flex-column p-gap-3 p-mt-2">
        <SimpleText fontSize="md" className="p-col-12 p-px-0">
          Olá;
        </SimpleText>

        <div className="p-d-flex p-gap-2 p-ai-center">
          <Avatar size="large" label={user?.nome} />

          <div className="p-d-flex p-flex-column p-gap-2">
            <SimpleText medium>{user.nome}</SimpleText>
            <SimpleText fontSize="xxs" fontColor="color_40">
              Numero de IP: {IPv4}
            </SimpleText>
          </div>
        </div>

        <Divider layout="horizontal" className="p-m-0" />

        <div className="p-col-12 p-d-flex p-gap-3 p-ai-center">
          <div className="row-square-icon">
            <i
              className="fas fa-puzzle-piece"
              style={{
                fontSize: '2rem',
              }}
            />
          </div>

          <div className="p-d-flex p-flex-column p-gap-2">
            <SimpleText fontSize="sm" medium>
              Assistente Virtual
            </SimpleText>
            <SimpleText>
              Reveja as assistências e novidades disponíveis no sistema.
            </SimpleText>
          </div>
        </div>

        <SimpleText fontColor="color_60">Assistências disponíveis </SimpleText>

        <AssistenciasList
          handleOpen={item => dialogAssistenteVirtual.open({ state: item })}
        />

        {dialogAssistenteVirtual.isOpen && (
          <AssistenteVirtualDialog {...dialogAssistenteVirtual} />
        )}
      </div>
    </Sidebar>
  );
};

const AssistenciasList = ({ handleOpen }: { handleOpen(item: any): void }) => {
  const fetch = useCallback(async params => {
    try {
      const data = await AssistenciaAPI.getAssistencias(
        {
          ...params,
        },
        {
          ativo: true,
          novidade: true,
          ajudaDeTela: true,
        },
        {
          throwError: true,
        },
      );

      if (!data.list.length) return { list: [] };

      return data;
    } catch {}
  }, []);

  return (
    <SearchList.Root fetchApi={fetch}>
      <SearchList.InfiniteScroll
        autoHeight={false}
        style={{
          height: '48vh',
        }}
        renderRow={item => (
          <div
            className="row-component hover p-px-2 p-py-3 p-mb-2"
            onClick={() => handleOpen(item)}
          >
            <SimpleText
              medium
              style={{
                pointerEvents: 'none',
              }}
            >
              {item.nome}
            </SimpleText>
          </div>
        )}
      />
    </SearchList.Root>
  );
};
