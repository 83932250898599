import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import { GetDadosIniciaisEmpresa } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPaciente';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { clearUser } from 'src/core/redux/slices/system/UserSlice';

const authRoutes = (hashEmpresa: string) => {
  return {
    LOGIN: `/portal/${hashEmpresa}/login`,
    REGISTRO: `/portal/${hashEmpresa}/registro`,
    REDEFINICAO: `/portal/${hashEmpresa}/redefinicao`,
  };
};

export const useEmpresa = () => {
  const [isLoading, setLoading] = useState(false);

  const { hashEmpresa } = useParams();
  const empresaData = useRef<GetDadosIniciaisEmpresa | null>(null);

  useEffect(() => {
    setLoading(true);

    if (hashEmpresa)
      PortalPacienteAPI.getDadosIniciaisEmpresa({
        hashEmpresa,
      })
        .then(d => {
          empresaData.current = d;
        })
        .finally(() => setLoading(false));
  }, [hashEmpresa]);

  return { empresaData: empresaData.current, isLoading };
};

export const useValidateAuth = () => {
  const { user } = useAppSelector(state => state);
  const { empresaData } = useEmpresa();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const pathArray = location.pathname.split('/');
  const hashEmpresa = pathArray[2];

  const loginRoute = `/portal/${hashEmpresa}/login`;
  const homeRoute = `/portal/${hashEmpresa}/inicio`;

  const loginRedirectCB = useCallback(
    (stateFrom: any, redirectToLastPage: boolean) => {
      const origin = !redirectToLastPage
        ? homeRoute
        : stateFrom?.pathname || homeRoute;

      navigate(origin, { replace: true });
      setLoading(false);
    },
    [homeRoute, navigate],
  );

  useEffect(() => {
    setLoading(true);

    const isAuthRoutes = Object.values(authRoutes(hashEmpresa!)).some(
      (route: string) => location.pathname === route,
    );

    if (isAuthRoutes && user.access_token) {
      if (user?.tipoUsuario !== 'PACIENTE') {
        dispatch(clearUser());
        navigate(loginRoute);
      }

      if (user?.codigoEmpresa !== empresaData?.codigo) {
        dispatch(clearUser());
        navigate(loginRoute);
      }

      const redirectToLastPage =
        user?.idUltimoUsuarioAutenticado === user?.idUsuario;

      return loginRedirectCB(location?.state?.from, redirectToLastPage);
    }

    return setLoading(false);
  }, [
    location,
    user,
    loginRedirectCB,
    loginRoute,
    hashEmpresa,
    dispatch,
    navigate,
  ]);

  return { isLoggingIn: isLoading };
};
