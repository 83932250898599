export function cn(...inputs: any[]): string {
  const processInput = (input: any): string[] => {
    if (typeof input === 'string') {
      return input.split(' ').filter(Boolean); // Filtra strings vazias
    } else if (Array.isArray(input)) {
      return input.flatMap(processInput); // Processa recursivamente
    } else if (typeof input === 'object' && input !== null) {
      return Object.keys(input).filter(key => input[key]); // Inclui chave se o valor for verdadeiro
    }
    return [];
  };

  const classes = inputs.flatMap(processInput); // Processa todos os inputs
  const uniqueClasses = [...new Set(classes)]; // Remove duplicatas
  return uniqueClasses.join(' '); // Combina em uma string única
}
