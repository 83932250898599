import { useEffect, useState } from 'react';

import {
  ListBox as PrimeListBox,
  ListBoxProps as PrimeListBoxProps,
} from 'primereact/listbox';
import { Control, Controller } from 'react-hook-form';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';

import './ListBox.scss';

interface ListBoxValue {
  id: number;
  nome: string;
}

interface ListBoxProps extends PrimeListBoxProps {
  control: Control;
  name: string;
  options: any;
  errorMsg?: string;
  optionsClassName?: string;
  setValue?: any;
  showHeader?: boolean;
  onlyShowFilter?: boolean;
}

function ListBox({
  control,
  name,
  options,
  errorMsg,
  optionsClassName = 'vinculos-options',
  setValue,
  showHeader = true,
  onlyShowFilter = false,
  className = '',
  ...rest
}: ListBoxProps) {
  const [filter, setFilter] = useState<string>('');
  const [optionsState, setOptionsState] = useState<any>(options);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  useEffect(() => {
    const filterOptions = options.filter((option: any) =>
      option.label.toLowerCase().includes(filter.toLowerCase()),
    );

    setOptionsState(filterOptions);
  }, [filter, options]);

  function itemTemplate(option: any, selecionados: ListBoxValue[]) {
    const selecionado = option.value?.id
      ? selecionados?.some(selecionado => selecionado.id === option.value.id) ||
        false
      : selecionados?.includes(option.value) || false;

    return (
      <div
        className={`modelo-listbox-options p-d-flex ${optionsClassName} ${
          selecionado && 'selecionado'
        }`}
      >
        <Checkbox checked={selecionado} />
        <span>{option.label}</span>
      </div>
    );
  }

  if (!options.length) {
    return (
      <SimpleText fontColor={FONT_COLOR.COLOR_60}>
        Não há dados para a empresa selecionada.
      </SimpleText>
    );
  }
  return (
    <div className={className}>
      {showHeader && (
        <div className="TextInputSearch">
          <span
            className={`p-input-icon-left ${
              filter ? 'p-input-icon-right' : ''
            }`}
          >
            <i className="pi pi-search" style={{ marginRight: 5 }} />
            <TextInput
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
            {!!filter && (
              <i className="pi pi-times" onClick={() => setFilter('')} />
            )}
          </span>
        </div>
      )}

      {!optionsState.length && (
        <div className="p-mt-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Nenhum resultado encontrado.
          </SimpleText>
        </div>
      )}

      {showHeader && !onlyShowFilter && !!optionsState.length && (
        <>
          <div className="modelo-listbox-buttons p-d-flex p-jc-between">
            <Checkbox
              label="Selecionar todos"
              checked={selectedAll}
              onChange={() => {
                if (selectedAll) return setValue?.(name, []);

                setValue?.(
                  name,
                  options.map((option: any) => option.value),
                );
              }}
            />
            <Button
              btnType="green-link"
              label="Limpar"
              onClick={() => setValue?.(name, [])}
            />
          </div>
        </>
      )}

      {!!optionsState.length && (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }: any) => {
            setSelectedAll(value?.length === options.length);

            return (
              <PrimeListBox
                options={optionsState}
                value={value}
                onChange={onChange}
                itemTemplate={option => itemTemplate(option, value)}
                multiple
                listClassName="campos-obrigatorios-list"
                {...rest}
              />
            );
          }}
        />
      )}

      {errorMsg && (
        <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
          {errorMsg}
        </SimpleText>
      )}
    </div>
  );
}

export default ListBox;
