import { useCallback, useContext, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import AdminAPI from 'src/APIs/AdminAPI/AdminAPI';
import * as Yup from 'yup';

import ThemeContext from 'src/core/themes/ThemeContext';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Switch from 'src/components/Switch/Switch';

import FormServicoTuss from './FormServicoTuss/FormServicoTuss';

import './Especialidade.scss';

const formFields = {
  nome: '',
  status: true,
  prenatalObrigatorio: false,
  especialidadeServicosTuss: [],
};

const Especialidade = (props: any) => {
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const navigate = useNavigate();

  const [servicoTuss, setServicoTuss] = useState<any[]>([]);
  const [especialidadeEditada, setEspecialidadeEditada] = useState<any>();

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('O campo nome é obrigatório.'),
    status: Yup.boolean(),
    prenatalObrigatorio: Yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const loadPage = useCallback(async () => {
    if (params.id) {
      const id = parseInt(params.id);

      const auxById = await AdminAPI.EspecialidadeAPI.loadEspecialidadeById(id);

      if (auxById) {
        setValue('nome', auxById.nome);
        setValue('status', auxById.status);
        setValue('prenatalObrigatorio', auxById.prenatalObrigatorio);

        const servicoTussList = auxById.especialidadeServicosTuss!.map(
          (serv: any) => ({
            ...serv,
            id: serv.idServicoTuss,
          }),
        );

        setValue('especialidadeServicosTuss', servicoTussList);
        setServicoTuss(servicoTussList);
        setEspecialidadeEditada(auxById);
      }
    } else {
      setValue('status', formFields.status);
      setValue('prenatalObrigatorio', formFields.prenatalObrigatorio);
    }
  }, [params, setValue]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const handleEspecialidade = useCallback(
    async (values: any) => {
      let response: any = '';
      let servicoTussList: any = null;

      if (values!.especialidadeServicosTuss) {
        servicoTussList = values!.especialidadeServicosTuss.map(
          (serv: any) => ({
            idServicoTuss: serv!.id,
            principal: serv!.principal ? serv!.principal : false,
          }),
        );
      }

      if (params.id) {
        const data: any = {
          nome: values.nome,
          prenatalObrigatorio: values.prenatalObrigatorio,
          status: values.status,
          especialidadeServicosTuss: servicoTussList,
        };

        response = await AdminAPI.EspecialidadeAPI.updateEspecialidade(
          parseInt(params.id),
          data,
        );
      } else {
        const data: any = {
          nome: values.nome,
          prenatalObrigatorio: values.prenatalObrigatorio,
          status: values.status,
          especialidadeServicosTuss: servicoTussList,
        };

        response = await AdminAPI.EspecialidadeAPI.sendEspecialidade(data);
      }

      if (response.status === 200 || response.status === 201) {
        navigate(-1);
      }
    },
    [params.id, navigate],
  );

  const handleCancel = useCallback(() => {
    reset();
    navigate(-1);
  }, [navigate, reset]);

  return (
    <Page className={`Especialidade ${theme}`} white>
      <>
        <PageHeader
          title={params.id ? 'Editar especialidade' : 'Nova especialidade'}
          subtitle={
            'Preencha os campos e adicione serviço TUSS para completar o cadastro.'
          }
        />
        <div className="modal-body-especialidade">
          <form
            className="p-col-12 p-lg-6"
            onSubmit={handleSubmit(handleEspecialidade)}
            defaultValue={''}
            onReset={() => reset(formFields)}
          >
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <TextInput
                  label="Nome"
                  className="p-mb-2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                  errorMsg={errors.nome?.message}
                />
              )}
              name={'nome'}
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any) => (
                <div className={'p-col-12 switch-container'}>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    bold
                  >
                    Pré natal obrigatório
                  </SimpleText>
                  <div className="p-ai-center p-d-flex p-flex-row">
                    <SimpleText
                      className="p-pb-1 p-pr-2"
                      fontSize={FONT_SIZE.XS}
                      fontColor={
                        value ? FONT_COLOR.COLOR_40 : FONT_COLOR.COLOR_79
                      }
                    >
                      {value ? 'Ativado' : 'Desativado'}
                    </SimpleText>
                    <Switch checked={value} onChange={onChange} />
                  </div>
                </div>
              )}
              name={'prenatalObrigatorio'}
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any) => (
                <div className={'p-col-12 switch-container'}>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    bold
                  >
                    Status
                  </SimpleText>
                  <div className="p-ai-center p-d-flex p-flex-row">
                    <SimpleText
                      className="p-pb-1 p-pr-2"
                      fontSize={FONT_SIZE.XS}
                      fontColor={
                        value ? FONT_COLOR.COLOR_40 : FONT_COLOR.COLOR_79
                      }
                    >
                      {value ? 'Ativado' : 'Desativado'}
                    </SimpleText>
                    <Switch checked={value} onChange={onChange} />
                  </div>
                </div>
              )}
              name={'status'}
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }: any) => (
                <FormServicoTuss
                  values={servicoTuss}
                  onSubmit={servTuss => {
                    setServicoTuss(servTuss);
                    setValue('especialidadeServicosTuss', servTuss);
                  }}
                />
              )}
              name={'especialidadeServicosTuss'}
            />

            <div className="p-pt-2 p-pb-4">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.DANGER}
                bold
              >
                {errors.especialidadeServicosTuss?.message}
              </SimpleText>
            </div>

            <div className="p-grid">
              <div className="p-col-12 p-sm-3">
                <Button
                  btnType="ghost"
                  label="Cancelar"
                  onClick={() => handleCancel()}
                  stretch
                />
              </div>
              <div className="p-col-12 p-sm-9">
                <Button
                  label="Salvar"
                  type="submit"
                  stretch
                  loading={isSubmitting}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    </Page>
  );
};

export default Especialidade;
