import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DadosCadastraisProps {
  convenio: Convenio;
}

export const DadosCadastrais = (props: DadosCadastraisProps) => {
  const { convenio } = props;

  const formatAddress = () => {
    if (!convenio?.endereco?.logradouro) return '';

    const complemento = convenio?.endereco?.complemento
      ? `- ${convenio?.endereco?.complemento}`
      : '';

    const cidade = convenio?.endereco?.cidade?.nome
      ? `• ${capitalizeFirstLetter(convenio?.endereco?.cidade?.nome)}-${
          convenio?.endereco?.cidade?.estado?.sigla
        }`
      : '';

    return `${convenio.endereco.logradouro}, ${convenio.endereco.numero} ${complemento} • ${convenio.endereco.bairro} ${cidade}`;
  };

  return (
    <div className="p-d-flex p-jc-between p-gap-3 p-flex-column p-flex-lg-row p-mt-5 p-wordbreak-all">
      <div className="p-d-flex p-flex-column p-flex-1">
        <SimpleText className="p-mb-3" fontColor={FONT_COLOR.COLOR_16} medium>
          Dados cadastrais
        </SimpleText>

        <div className="data-container">
          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Site:
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.PRIMARY} medium>
              {convenio?.site}
            </SimpleText>
          </div>

          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Código ANS:
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {convenio.registroAns}
            </SimpleText>
          </div>

          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Telefone:
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {convenio?.telefoneContato1 || '-'}
            </SimpleText>

            <SimpleText
              className="p-ml-5 p-mr-2"
              fontColor={FONT_COLOR.COLOR_60}
            >
              Telefone:
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {convenio?.telefoneContato2 || '-'}
            </SimpleText>
          </div>
        </div>
      </div>

      <div className="p-d-flex p-flex-column p-flex-1">
        <SimpleText className="p-mb-3" fontColor={FONT_COLOR.COLOR_16} medium>
          Endereço
        </SimpleText>

        <div className="data-container">
          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              CEP:
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {convenio?.endereco?.cep}
            </SimpleText>
          </div>

          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Endereço:
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {formatAddress()}
            </SimpleText>
          </div>
        </div>
      </div>
    </div>
  );
};
