import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { checkAuth } from 'src/utils/utils';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
} from 'src/components/Timeline/Timeline';

import { AcervoFormulariosCreateGeral } from './AcervoFormulariosCreateGeral';
import { AcervoFormulariosCreatePerguntas } from './AcervoFormulariosCreatePerguntas';
import { AcervoFormularioCreatePontuacao } from './AcervoFormulariosCreatePontuacao';

export const AcervoFormulariosCreate = () => {
  const [item, setItem] = useState<IFormularioDinamicoItem | null>(null);

  const {
    query,
    user: { authorities },
  } = useAppSelector(state => state);
  const { id } = useParams();
  const { state } = useLocation();
  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSetItem = (value: IFormularioDinamicoItem) => {
    setItem(value);
    timelineRef.current?.goToStep?.(1);
  };

  const { invalidateFormularioById } = query;

  const fetchFormulario = useCallback(
    async (id: string) => {
      try {
        const response = await FormularioDinamicoAPI.loadFormularioDinamicoById(
          {
            id: Number(id),
          },
        );
        setItem(response);
      } catch {
        navigate('/cadastros/formulario-dinamico/acervo-formularios');
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (id) fetchFormulario(id);
  }, [fetchFormulario, id]);

  useEffect(() => {
    if (invalidateFormularioById && id) {
      fetchFormulario(id);
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, fetchFormulario, id, invalidateFormularioById]);

  useEffect(() => {
    if (state?.step === 1) {
      timelineRef.current?.goToStep?.(1);
    }
  }, [state]);

  const title = !item
    ? 'Novo formulário'
    : state?.to === 'edit'
    ? 'Edição de formulário'
    : 'Visualização de formulário';

  const canAccessPontuacao = checkAuth(
    authorities,
    'ADM_MANTER_PONTUACAO_FORMULARIO_DINAMICO_CONSULTAR',
  );

  return (
    <Page>
      <>
        <PageHeader
          title={title}
          subtitle="Crie e edite os formulários"
          hasBreadcrumbs
        />

        <Page content white>
          <Timeline
            ref={timelineRef}
            values={[
              {
                title: 'Geral',
                component: (
                  <AcervoFormulariosCreateGeral
                    item={item}
                    handleSetItem={handleSetItem}
                    handleNextPage={() => timelineRef.current?.next?.()}
                  />
                ),
              },
              {
                title: 'Perguntas',
                component: (
                  <AcervoFormulariosCreatePerguntas
                    item={item!}
                    handleNext={() =>
                      canAccessPontuacao ? timelineRef.current?.next?.() : null
                    }
                    handleRefresh={() => fetchFormulario(id!)}
                    handleGoBack={() => timelineRef.current?.previous?.()}
                  />
                ),
                disabled: !item,
              },
              {
                title: 'Pontuação',
                component: (
                  <AcervoFormularioCreatePontuacao
                    item={item!}
                    handleGoBack={() => timelineRef.current?.previous?.()}
                  />
                ),
                disabled: !item || !canAccessPontuacao,
              },
            ]}
          />
        </Page>
      </>
    </Page>
  );
};
