import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldCuidadorViveBemProps = DropdownProps & {
  idConsultorio: number;
  name?: string;
  label: string;
};

export const FieldCuidadorViveBem = ({
  idConsultorio,
  name = 'cuidadorViveBem',
  label,
  ...rest
}: FieldCuidadorViveBemProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [cuidadorViveBem, setCuidadorViveBem] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debounceSearch = useDebounce(querySearch, 350);

  const loadCuidadorViveBem = useCallback(
    async (queryParam: any) => {
      const response = await ProfissionalAPI.loadProfissionalSaudeByTipo({
        consultorio: idConsultorio,
        nome: queryParam,
        cuidadorViverBem: true,
        tipoProfissional: 'MEDICO',
      });

      if (!!response?.length) setCuidadorViveBem(response);

      setLoadingSearch(false);
    },
    [idConsultorio],
  );

  useEffect(() => {
    loadCuidadorViveBem(debounceSearch);
  }, [debounceSearch, loadCuidadorViveBem]);

  const cuidadorViveBemOptions = useMemo(() => {
    const options = cuidadorViveBem.map(item => ({
      label: item.nome,
      value: item,
    }));

    return options;
  }, [cuidadorViveBem]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      onFilter={e => {
        setLoadingSearch(true);
        setQuerySearch(e.filter);
      }}
      carregandoFiltro={loadingSearch}
      options={cuidadorViveBemOptions}
      errorMsg={errors?.[name]?.message}
      customOnChange={rest.onChange ? rest.onChange : () => null}
      labelOverflow="hidden"
      {...rest}
    />
  );
};
