import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { useAgenda } from '../../AgendaContext';
import Sigilo from '../../Sigilo/Sigilo';
import CardAgendamento from '../AgendamentoListItem/CardAgendamento/CardAgendamento';

import './GerenciarGrupo.scss';

interface GerenciarGrupoProps extends DisclosureType {
  selectedDate: Date;
  checkbox: boolean;
  agendamento: AtendimentoAgendamento;
}

const GerenciarGrupo = ({
  agendamento,
  selectedDate,
  checkbox,
  close,
}: GerenciarGrupoProps) => {
  const {
    refetchAgenda,
    getGrupo,
    grupoAtendimento,
    isLoadingGrupoAtendimento,
  } = useAgenda();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean | undefined>(false);

  const canAccessPEP = useMemo(
    () =>
      ['PRESENTE', 'ATENDENDO', 'CONTINUAR'].includes(
        grupoAtendimento?.status || '',
      ),
    [grupoAtendimento?.status],
  );

  const handleSubmit = useCallback(async () => {
    if (!grupoAtendimento) return;

    if (canAccessPEP) {
      return navigate('/prontuario', {
        state: {
          idAtendimento: grupoAtendimento.id,
        },
      });
    }

    await GrupoAtendimentoAPI.putLiberarAtendimentoGrupo(agendamento.id, {
      ...agendamento,
      status: 'PRESENTE',
      tipoValidacao: 'GRUPO_ATENDIMENTO',
    });

    refetchAgenda();
    close();
  }, [
    agendamento,
    canAccessPEP,
    close,
    grupoAtendimento,
    navigate,
    refetchAgenda,
  ]);

  const getGrupoAtendimento = useCallback(() => {
    getGrupo(agendamento.id);
  }, [agendamento.id, getGrupo]);

  const validateGrupoAtendimento = useCallback(() => {
    if (!grupoAtendimento) return false;

    const { status, atendimentos } = grupoAtendimento;

    if (['ATENDENDO', 'CONTINUAR'].includes(status || '')) {
      return true;
    }

    const agendamentosDiff =
      atendimentos.filter(ag => ag.status !== 'PRESENTE') || [];

    if (agendamentosDiff.length === 0) {
      return true;
    }

    const validStatuses = [
      'ATENDENDO',
      'CONTINUAR',
      'CANCELADO',
      'ATENDIDO',
      'FALTOU',
    ];
    return agendamentosDiff.every(ag => validStatuses.includes(ag.status));
  }, [grupoAtendimento]);

  useEffect(() => {
    getGrupoAtendimento();
  }, [agendamento, getGrupoAtendimento]);

  useEffect(() => {
    setIsValid(validateGrupoAtendimento());
  }, [grupoAtendimento, validateGrupoAtendimento]);

  const buttonSubmitlabel = canAccessPEP
    ? 'Evoluir em grupo'
    : 'Liberar para o atendimento';

  return (
    <>
      <Dialog
        className="modal-gerenciar"
        header="Gerenciar"
        visible
        onHide={close}
        position={'center'}
        type="modal"
      >
        <div className="container-gerenciar">
          <div className="infos p-mb-5">
            <SimpleText
              bold
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              {agendamento.apelidoGrupo}
            </SimpleText>

            <SimpleText
              bold
              className="p-my-2"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {dayjs(agendamento.dataAgendamento).format(
                'DD/MM/YYYY [às] HH:mm',
              )}
            </SimpleText>

            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
              {agendamento.medico.nome}
            </SimpleText>
          </div>

          <div className="pacientes">
            <SimpleText
              bold
              fontSize={FONT_SIZE.SM}
              fontColor={FONT_COLOR.COLOR_16}
            >
              Pacientes
            </SimpleText>

            {isLoadingGrupoAtendimento ? (
              <GridListLoading />
            ) : (
              grupoAtendimento?.atendimentos?.map(agendamento => (
                <CardAgendamento
                  key={agendamento.id}
                  checkbox={checkbox}
                  agendamento={agendamento}
                  selectedDate={selectedDate}
                  getGrupo={getGrupoAtendimento}
                  sigilo={<Sigilo atendimento={agendamento} />}
                  isGerenciamentoGrupo
                />
              ))
            )}
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Fechar"
              btnType="ghost"
              onClick={() => close()}
              stretch
            />
            <Button
              label={buttonSubmitlabel}
              onClick={handleSubmit}
              disabled={!isValid}
              stretch
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default memo(GerenciarGrupo);
