import dayjs from 'dayjs';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface CardPacienteProps {
  paciente: any;
}

const CardPaciente = ({ paciente }: CardPacienteProps) => {
  return (
    <div className="card-paciente-semelhante p-grid p-col-12 p-align-center">
      <div className="p-col-4">
        <Avatar
          className="avatar"
          label={paciente?.nome}
          image={paciente?.urlFoto}
        />
        <SimpleText fontColor={FONT_COLOR.COLOR_16}>{paciente.nome}</SimpleText>
      </div>
      <div className="p-col-2">
        <div className="column">
          <SimpleText fontColor={FONT_COLOR.COLOR_16}>
            {paciente.cpf?.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})/,
              '$1.$2.$3-$4',
            )}
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Registro no. {paciente.id}
          </SimpleText>
        </div>
      </div>
      <SimpleText className="p-col-2" fontColor={FONT_COLOR.COLOR_16}>
        {dayjs(paciente.dataNascimento).format('DD.MM.YYYY')}
      </SimpleText>
      <SimpleText className="p-col-3" fontColor={FONT_COLOR.COLOR_16}>
        {paciente.nomeMae}
      </SimpleText>
    </div>
  );
};

export default CardPaciente;
