import Http, { HttpParams } from 'src/core/http/Http';

export const getSatatusPermitidos = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: '/agendas/status-permitidos',
    hideToast: true,
    return: true,
    ...options,
  });
};

export const loadPesquisaPacientes = async (
  idConsultorio: number,
  dataInicial: string,
  dataFinal: string,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/consultorio/${idConsultorio}/periodo/${dataInicial}/${dataFinal}/busca-pacientes`,
    data: params,
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const loadProfissionaisConsultorio = async (
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/profissional-saude/por-consultorio/${idConsultorio}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const PesquisaPacienteAPI = {
  loadPesquisaPacientes,
  getSatatusPermitidos,
  loadProfissionaisConsultorio,
};
