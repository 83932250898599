import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/relatorios-pep';

const relatorioPepPlanoCuidado = (
  params: { idPlanoCuidadoPaciente: string | number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'report',
    url: `${basePath}/plano-cuidado/pdf`,
    responseType: 'arraybuffer',
    params,
    hideToast: true,
    ...options,
  });

const RelatorioPepAPI = {
  relatorioPepPlanoCuidado,
};

export default RelatorioPepAPI;
