import { useState } from 'react';

import { PendenciasTissAPI } from 'src/APIs/ProntuarioAPI/PendenciasTissAPI/PendenciasTissAPI';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const PendenciasTissHeader = ({
  handleSuccess,
}: {
  handleSuccess(): void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { totalCount } = useSearchList();

  const handleEnviarLote = () => {
    setIsLoading(true);
    PendenciasTissAPI.sendLoteGuiaTiss()
      .then(() => handleSuccess())
      .finally(() => setIsLoading(false));
  };
  const handleSADT = () => {
    setIsLoading(true);
    PendenciasTissAPI.sendSADT()
      .then(() => handleSuccess())
      .finally(() => setIsLoading(false));
  };
  const handleExamesFuturos = () => {
    setIsLoading(true);
    PendenciasTissAPI.sendExamesFuturos()
      .then(() => handleSuccess())
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="content-header">
      <Button
        label="Enviar lote guia"
        icon="fas fa-paper-plane"
        btnType="tonal"
        disabled={!totalCount}
        loading={isLoading}
        onClick={handleEnviarLote}
      />
      <Button
        label="Enviar SADT"
        icon="fas fa-paper-plane"
        btnType="tonal"
        disabled={!totalCount}
        loading={isLoading}
        onClick={handleSADT}
      />
      <Button
        label="Enviar exames futuros"
        icon="fas fa-paper-plane"
        btnType="tonal"
        disabled={!totalCount}
        loading={isLoading}
        onClick={handleExamesFuturos}
      />
      <SimpleText medium>Resultados da busca ({totalCount || 0})</SimpleText>
    </div>
  );
};
