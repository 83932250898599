import { memo } from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalSairServicoTussProps {
  visible: boolean;
  onHide(): void;
  goBack(): void;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Não, cancelar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button
        btnType="danger"
        className={'danger'}
        label="Sim, sair"
        onClick={onAdd}
        stretch
      />
    </div>
  );
});

const ModalSairServicoTuss = ({
  visible,
  onHide,
  goBack,
}: ModalSairServicoTussProps) => {
  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Sair sem salvar"
      style={{ width: '450px' }}
      visible={visible}
      onHide={onHide}
      footer={() => <Footer onAdd={goBack} onCancel={onHide} />}
    >
      <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
        Você tem certeza que deseja sair e descartar todas as edições feitas no
        cadastro?
      </SimpleText>
    </Dialog>
  );
};

export default ModalSairServicoTuss;
