import { useState } from 'react';

type LocalStorageProps = {
  storedValue: any;
  setValue: (value: any) => void;
  removeStoredValue: () => void;
};

const prefix = '@EMED4ALL/';

export function useLocalStorage(
  key: string,
  initialValue: any = undefined,
): LocalStorageProps {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(`${prefix}${key}`);
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = (value: any) => {
    const valueToStore =
      typeof value === 'function' ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(
      `${prefix}${key}`,
      JSON.stringify(valueToStore),
    );
  };

  const removeStoredValue = () => {
    window.localStorage.removeItem(`${prefix}${key}`);
    setStoredValue(undefined);
  };

  return { storedValue, setValue, removeStoredValue };
}

export function useSessionStorage(
  key: string,
  initialValue: any = undefined,
): LocalStorageProps {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.sessionStorage.getItem(`${prefix}${key}`);
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = (value: any) => {
    const valueToStore =
      typeof value === 'function' ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.sessionStorage.setItem(
      `${prefix}${key}`,
      JSON.stringify(valueToStore),
    );
  };

  const removeStoredValue = () => {
    window.sessionStorage.removeItem(`${prefix}${key}`);
    setStoredValue(undefined);
  };

  return { storedValue, setValue, removeStoredValue };
}
