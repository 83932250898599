import { useCallback, useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router';

import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { AtendimentoState } from 'src/core/redux/slices/atendimento/AtendimentoSlice';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { TempoAtendimentoProps, useTimer } from 'src/utils/hooks/useTimer';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { HandlePausarProps } from '../Cabecalho';

import './Timer.scss';

type TimerType = {
  tempoAtendimento: React.MutableRefObject<TempoAtendimentoProps | null>;
  handlePausarAtendimento: ({ route }: HandlePausarProps) => void;
  className?: string;
};

export const Timer = ({
  tempoAtendimento: time,
  handlePausarAtendimento,
  className,
}: TimerType) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { setValue, storedValue } = useLocalStorage(
    `tempoAtendimento/${idAtendimento}`,
    undefined,
  );

  const { atendimentoStatus, sairPEPDialog } = useAtendimento();
  const atendimento: AtendimentoState = useAppSelector(
    (state: RootState) => state.atendimento,
  );

  const tempoAtendimento = useTimer({ initial: 0 });

  const { tempoGastoAtendimento } = atendimento;

  time.current = tempoAtendimento;

  const handleAddTimer = useCallback(() => {
    setValue(tempoAtendimento.time);
  }, [setValue, tempoAtendimento.time]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleAddTimer);

    return () => {
      window.removeEventListener('beforeunload', handleAddTimer);
    };
  }, [handleAddTimer]);

  useEffect(() => {
    tempoAtendimento.setTime(storedValue || tempoGastoAtendimento || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempoGastoAtendimento]);

  useEffect(() => {
    if (
      atendimentoStatus.includes('ATENDENDO') ||
      atendimentoStatus === 'PRESCRICAO_EXECUCAO'
    ) {
      tempoAtendimento.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentoStatus]);

  const handleDescartarPrescricao = useCallback(() => {
    return PrescricaoAPI.descartarPrescricao(idAtendimento, {
      throwError: true,
    })
      .then(() => {
        navigate(sairPEPDialog.state?.route, { replace: true });
        setValue(tempoAtendimento.time);
      })
      .catch(() => navigate(sairPEPDialog.state?.route || '/agenda'));
  }, [
    idAtendimento,
    navigate,
    sairPEPDialog.state,
    setValue,
    tempoAtendimento.time,
  ]);

  useEffectSkipFirst(() => {
    if (sairPEPDialog?.state?.type === 'DESCARTADO') {
      if (atendimentoStatus === 'PRESCRICAO_EXECUCAO') {
        handleDescartarPrescricao();
      } else {
        navigate(sairPEPDialog.state.route, { replace: true });
        setValue(tempoAtendimento.time);
      }
    }
    if (sairPEPDialog?.state?.type === 'PAUSADO') {
      handlePausarAtendimento({ route: sairPEPDialog.state.route });
      setValue(tempoAtendimento.time);
    }
  }, [sairPEPDialog.state, tempoAtendimento.time]);

  return (
    <SimpleText fontSize="xxs" className={`tempo-atendiment-info ${className}`}>
      {`Tempo de atendimento: ${tempoAtendimento.time}`}
    </SimpleText>
  );
};
