import { Outlet } from 'react-router';

import Page from 'src/components/Basics/Page/Page';

export const Monitoramento = () => {
  return (
    <Page>
      <Outlet />
    </Page>
  );
};
