import Http, { HttpParams } from 'src/core/http/Http';

export const getCircunferenciaPanturrilha = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `circunferencia-panturrilha/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesCircunferenciaPanturrilha = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'circunferencia-panturrilha/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarCircunferenciaPanturrilha = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'circunferencia-panturrilha',
    data: payload,
    return: true,
    ...options,
  });

export const alterarCircunferenciaPanturrilha = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-panturrilha/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarCircunferenciaPanturrilha = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-panturrilha/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerCircunferenciaPanturrilha = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `circunferencia-panturrilha/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const CircunferenciaPanturrilhaAPI = {
  getCircunferenciaPanturrilha,
  getUnidadesCircunferenciaPanturrilha,
  adicionarCircunferenciaPanturrilha,
  alterarCircunferenciaPanturrilha,
  inativarCircunferenciaPanturrilha,
  removerCircunferenciaPanturrilha,
};

export default CircunferenciaPanturrilhaAPI;
