import React from 'react';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  motivo: any;
  index: number;
  isCollapsed: boolean;
  onToggle: () => void;
  updateMotivo: (motivo: any, index: number) => void;
  handleRemove: (index: number) => void;
}

const PanelHeader = ({
  motivo,
  index,
  isCollapsed,
  onToggle,
  updateMotivo,
  handleRemove,
}: PanelHeaderProps) => {
  return (
    <div className="p-d-flex p-jc-between p-p-2 p-px-4">
      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Motivo de atendimento
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {motivo?.motivoAtendimento ||
            motivo?.movitoAtendimento?.descricaoTipoMotivo}
        </SimpleText>
      </div>

      <div className="p-d-flex p-gap-2">
        <Button
          btnType="gray"
          icon="pi pi-pencil"
          onClick={() => updateMotivo(motivo, index)}
          type="button"
        />
        <Button
          btnType="gray"
          icon="pi pi-trash"
          onClick={() => handleRemove(index)}
          type="button"
        />
        <Button
          btnType="ghost"
          icon={!isCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'}
          onClick={onToggle}
          type="button"
        />
      </div>
    </div>
  );
};

export default PanelHeader;
