import { useCallback, useMemo, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useLocation, useParams } from 'react-router';

import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import PanelOptionsMenu from '../../components/PanelOptionsMenu/PanelOptionsMenu';
import Chip from 'src/components/Basics/Chip/Chip';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import ModalAtender from './ModalAtender/ModalAtender';
import ModalEditPedidoCuidado from './ModalEditPedidoCuidado/ModalEditPedidoCuidado';
import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './PedidosCuidado.scss';

interface PedidosCuidadoProps {
  idPaciente: number | null;
}

function PedidosCuidado({ idPaciente }: PedidosCuidadoProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getInfoLembretes } = useAtendimento();

  const [ordenacaoPedidosCuidado, setOrdenacaoPedidosCuidado] =
    useState<string>('dataInclusao');
  const [filtroSelecionado, setFiltroSelecionado] = useState<string>('');

  const [editPedidoCuidado, setEditPedidoCuidado] =
    useState<PedidoCuidado | null>(null);
  const [updateList, setUpdateList] = useState<number>(0);
  const [pedidoCuidadoInativacao, setPedidoCuidadoInativacao] =
    useState<PedidoCuidado | null>(null);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<boolean>(false);
  const [idPedidoCuidadoExcluir, setIdPedidoCuidadoExcluir] = useState<
    number | null
  >(null);
  const [excluirAtendimentoPedidoCuidado, setExcluirAtendimentoPedidoCuidado] =
    useState<PedidoCuidado | null>(null);
  const [atenderPedidoCuidado, setAtenderPedidoCuidado] =
    useState<PedidoCuidado | null>(null);
  const [editAtendimentoPedido, setEditAtendimentoPedido] =
    useState<boolean>(false);
  const [modalAtenderVisible, setModalAtenderVisible] =
    useState<boolean>(false);

  const fetchAPI = useCallback(
    ({ query, ...rest }: any): Promise<any> => {
      return PedidosCuidadoAPI.getPedidosCuidado(Number(idAtendimento), {
        filtro: filtroSelecionado,
        sortBy: ordenacaoPedidosCuidado,
        sortDirection: 'DESC',
        ...rest,
      });
    },
    [idAtendimento, filtroSelecionado, ordenacaoPedidosCuidado],
  );

  const onAtender = (pedidoCuidado: PedidoCuidado, isEdit = false) => {
    setAtenderPedidoCuidado(pedidoCuidado);
    setEditAtendimentoPedido(isEdit);
    setModalAtenderVisible(true);
  };

  const onHideAtenderPedidoCuidado = () => {
    setAtenderPedidoCuidado(null);
    setModalAtenderVisible(false);
    setEditAtendimentoPedido(false);
  };

  const onEdit = (pedidoCuidado: PedidoCuidado) => {
    setEditPedidoCuidado(pedidoCuidado);
  };

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));
  };

  const onExcluir = async () => {
    const response = await PedidosCuidadoAPI.removerPedidoCuidado(
      idPedidoCuidadoExcluir as number,
      Number(idAtendimento),
    );

    if (response?.status === 204) {
      reloadList();
      setIdPedidoCuidadoExcluir(null);
    }
  };

  const onAlterarLembrete = async (idPedidoCuidado: number) => {
    const response = await PedidosCuidadoAPI.alterarPedidoCuidadoLembrete(
      idPedidoCuidado,
    );

    if (response?.status === 204) reloadList();
  };

  const onExcluirAtendimento = async () => {
    if (!excluirAtendimentoPedidoCuidado) return;

    const response = await PedidosCuidadoAPI.excluirAtendimentoPedidoCuidado(
      excluirAtendimentoPedidoCuidado.id,
      excluirAtendimentoPedidoCuidado.idAtendimentoSolicita,
      Number(idAtendimento),
    );

    if (response?.status === 200) {
      reloadList();
      setExcluirAtendimentoPedidoCuidado(null);
    }
  };

  const opcoesOrdenacaoPedidosCuidado = useMemo(() => {
    const options = [
      { label: 'Data da solicitação', value: 'dataInclusao' },
      {
        label: 'Data programada',
        value: 'atendimentosAgendados.dataAgendamento',
      },
      { label: 'Data da ocorrência', value: 'dataOcorrencia' },
    ];

    if (filtroSelecionado === 'programado') {
      setOrdenacaoPedidosCuidado('dataEnfermeiro');
      options.shift();
      options.push({
        label: 'Data programada',
        value: 'dataEnfermeiro',
      });
    }

    return options;
  }, [filtroSelecionado]);

  const onVisualizarJustificativa = (PedidoCuidado: PedidoCuidado) => {
    setPedidoCuidadoInativacao(PedidoCuidado);
    setVisualizarJustificativa(true);
  };

  const optionsMenu = useCallback(
    (pedidoCuidado: PedidoCuidado) => (
      <PanelOptionsMenu
        ativo={pedidoCuidado.status === 'ATIVO'}
        lembreteAtivo={pedidoCuidado.lembrete}
        idAtendimentoRegistro={pedidoCuidado.idAtendimentoSolicita}
        idUsuarioCriacao={pedidoCuidado.idProfSaudeSolicit}
        onAlterarLembrete={() => onAlterarLembrete(pedidoCuidado.id)}
        onEdit={() => onEdit(pedidoCuidado)}
        onInativar={() => setPedidoCuidadoInativacao(pedidoCuidado)}
        onExcluir={() => setIdPedidoCuidadoExcluir(pedidoCuidado.id)}
        onVisualizarJustificativa={() =>
          onVisualizarJustificativa(pedidoCuidado)
        }
        showEditOnlyAtendimentoCorrente
      />
    ),
    [],
  );

  const renderRow = useCallback(
    (pedidoCuidado: PedidoCuidado) => {
      if (filtroSelecionado === 'programado' && !pedidoCuidado.programado)
        return <></>;

      if (
        filtroSelecionado === 'proximoAtendimento' &&
        !!pedidoCuidado.programado
      )
        return <></>;

      return (
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHeader
              pedidoCuidado={pedidoCuidado}
              isCollapsed={isCollapsed}
              onToggle={onToggle}
              optionsMenu={optionsMenu(pedidoCuidado)}
            />
          )}
        >
          <PanelContent
            pedidoCuidado={pedidoCuidado}
            onAtender={(isEdit?: boolean) => onAtender(pedidoCuidado, isEdit)}
            onExcluirAtendimento={() =>
              setExcluirAtendimentoPedidoCuidado(pedidoCuidado)
            }
          />
        </Panel>
      );
    },
    [filtroSelecionado, optionsMenu],
  );

  return (
    <div id="cuidados-container" className="p-p-3">
      <div className="p-my-3 cuidados-header p-d-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Pedidos de Cuidado
        </SimpleText>
        <div className="p-grid p-align-center p-justify-between p-mt-3">
          <div className="p-d-flex">
            <Chip
              label="Cuidado Próximo Atendimento"
              className="p-m-1"
              checked={filtroSelecionado === 'PROXIMO_ATENDIMENTO'}
              onChange={e =>
                setFiltroSelecionado(e ? 'PROXIMO_ATENDIMENTO' : '')
              }
            />
            <Chip
              label="Cuidado Programado"
              className="p-m-1"
              checked={filtroSelecionado === 'PROGRAMADO'}
              onChange={e => setFiltroSelecionado(e ? 'PROGRAMADO' : '')}
            />
          </div>
          <div className="p-d-flex p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_40}
              medium
            >
              Ordenar por:
            </SimpleText>
            <Dropdown
              className="p-mr-4"
              value={ordenacaoPedidosCuidado}
              onChange={e => setOrdenacaoPedidosCuidado(e.value)}
              options={opcoesOrdenacaoPedidosCuidado}
            />
          </div>
        </div>
      </div>

      <InfiniteScroll
        fetchAPI={fetchAPI}
        renderRow={renderRow}
        updateList={updateList}
      />

      <ModalJustificativa
        pedidoCuidado={pedidoCuidadoInativacao}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setPedidoCuidadoInativacao(null);
          setVisualizarJustificativa(false);
        }}
        onSuccess={reloadList}
      />

      <ConfirmDialog
        visible={!!idPedidoCuidadoExcluir}
        header="Excluir pedido cuidado"
        text="O pedido cuidado será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdPedidoCuidadoExcluir(null)}
        onConfirm={onExcluir}
      />

      <ConfirmDialog
        visible={!!excluirAtendimentoPedidoCuidado}
        header="Excluir atendimento pedido cuidado"
        text="O atendimento do pedido cuidado será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setExcluirAtendimentoPedidoCuidado(null)}
        onConfirm={onExcluirAtendimento}
      />

      <ModalAtender
        visible={modalAtenderVisible}
        pedidoCuidado={atenderPedidoCuidado}
        isEdit={editAtendimentoPedido}
        onHide={onHideAtenderPedidoCuidado}
        onSuccess={reloadList}
      />

      <ModalEditPedidoCuidado
        pedidoCuidado={editPedidoCuidado}
        onHide={() => setEditPedidoCuidado(null)}
        onSuccess={reloadList}
      />
    </div>
  );
}

export default PedidosCuidado;
