import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useFormContext, useFieldArray } from 'react-hook-form';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldMultiselectServicoTuss } from 'src/components/FieldMultiselectServicoTuss/FieldMultiselectServicoTuss';
import * as ServicosTussSelect from 'src/components/ServicosTussSelect';

import { Header } from './List/Header';
import { Row } from './List/Row';

import './OPME.scss';

export const OPME = () => {
  const { idConvenio } = useSolicitacaoExameCirurgia();

  const searchPanelRef = useRef<OverlayPanel>(null);

  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const {
    fields: anexosOPME,
    append: appendAnexosOPME,
    remove: removeAnexosOPME,
  } = useFieldArray({
    control,
    name: 'anexoOpmeServicoTuss',
  });

  const handleAddAnexosOPME = (anexos: any[]) => {
    const procedimentosFormatted = anexos.map(anexo => ({
      codigo: anexo.codigo,
      nome: anexo.nome,
      idServicoTuss: anexo.id,
      quantidade: anexo?.quantidadePadrao || 1,
      quantidadeMaxima: anexo?.quantidadeMaxima,
      fornecedoresOptions:
        anexo?.fornecedoresOpme.map((fornecedor: any) => ({
          label: fornecedor.nome,
          value: fornecedor.id,
        })) || [],
    }));

    appendAnexosOPME(procedimentosFormatted);

    clearErrors('anexoOpmeServicoTuss');
  };

  const renderProcedimento = (
    isSelected: boolean,
    procedimento: any,
    onSelect: (procedimento: any) => void,
  ) => {
    return (
      <ServicosTussSelect.ItemContainer key={procedimento.id}>
        <ServicosTussSelect.Item
          isSelected={isSelected}
          procedimento={procedimento}
          onSelect={onSelect}
        />
        <ServicosTussSelect.FavoriteButton
          isSelected={procedimento.favorito}
          idProcedimento={procedimento.id}
          idExameFavorito={procedimento.idExameFavorito}
        />
      </ServicosTussSelect.ItemContainer>
    );
  };

  const render = () => {
    if (!anexosOPME.length) {
      return (
        <div id="anexos-opme-empty-container">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Nenhum. Adicione um OPME utilizando a barra de pesquisa.
          </SimpleText>
        </div>
      );
    }

    return (
      <>
        <Header />
        <div id="anexos-opme-list-container">
          {anexosOPME.map((anexoOPME, index) => (
            <Row
              key={anexoOPME.id}
              index={index}
              onDelete={removeAnexosOPME}
              servicoTuss={anexoOPME}
            />
          ))}
        </div>
      </>
    );
  };

  const borderContainerClassName = errors.anexoOpmeServicoTuss?.message
    ? 'error-border'
    : '';

  const fetchServicosTuss2 = ({ query, ...params }: any) =>
    ServicoTussCRUDAPI.getServicoTussFavoritos({
      nome: query,
      idConvenio,
      opme: true,
      quimio: false,
      ...params,
    });

  return (
    <>
      <Card
        id="anexos-opme-container"
        type={CARD_TYPES.BORDER}
        className={borderContainerClassName}
      >
        <div className="p-d-flex">
          <div className="p-flex-1 p-mr-3">
            <FieldMultiselectServicoTuss
              handleAddProcedimentos={handleAddAnexosOPME}
              params={{
                idConvenio,
                opme: true,
              }}
            />
          </div>

          <Button
            type="button"
            btnType="green-link"
            label="Favoritos"
            icon="pi pi-chevron-down"
            iconPos="right"
            onClick={e => searchPanelRef.current?.toggle(e)}
          />

          <OverlayPanel
            className="opme-favoritos-panel"
            ref={searchPanelRef}
            appendTo="self"
          >
            <ServicosTussSelect.ServicosTussSelectProvider
              fetchServicosTuss={fetchServicosTuss2}
            >
              <ServicosTussSelect.MultiSelect
                onAdd={handleAddAnexosOPME}
                renderProcedimento={renderProcedimento}
                panel={false}
              />
            </ServicosTussSelect.ServicosTussSelectProvider>
          </OverlayPanel>
        </div>

        {render()}
      </Card>

      {!!errors.anexoOpmeServicoTuss?.message && (
        <SimpleText className="error p-my-3" fontSize={FONT_SIZE.XXXS}>
          {errors.anexoOpmeServicoTuss?.message}
        </SimpleText>
      )}
    </>
  );
};
