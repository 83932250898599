import { memo, useCallback, useMemo, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';

import AdminAPI from 'src/APIs/AdminAPI/AdminAPI';

import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import './SearchListProfessionals.scss';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

const { ProfissionalAPI } = AdminAPI;

interface SearchListProfessionalProps {
  selected: GetProfissionalDTO[];
  onSelectItem(v: GetProfissionalDTO): void;
  onRemoveItem(v: GetProfissionalDTO): void;
}

const SearchListProfessionals = (props: SearchListProfessionalProps) => {
  const { selected, onSelectItem, onRemoveItem } = props;

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<GetProfissionalDTO[]>();

  const onChangeSearch = useCallback(async (value: string) => {
    setList([]);
    setSearch(value);

    if (value) {
      setLoading(true);
      const response = await ProfissionalAPI.loadProfissionaisSaudeBySearch({
        nome: value,
      });

      // Ordena os dados por nome
      setList(
        response?.list?.sort((a: any, b: any) => (a?.nome < b?.nome ? -1 : 1)),
        // response.sort((a: any, b: any) => (a?.nome < b?.nome ? -1 : 1)),
      );
      setLoading(false);
    }
  }, []);

  const listUnselected = useMemo(
    () => selected.filter(i => !list?.some(s => s?.id === i?.id)) || [],
    [list, selected],
  );

  const isSelected = useCallback(
    (item: GetProfissionalDTO) => selected.some(i => i?.id === item?.id),
    [selected],
  );

  return (
    <div className="SearchListProfessionals">
      <TextInputSearch
        autoFocus
        placeholder="Adicione usuários pesquisando"
        onChange={onChangeSearch}
      />

      <div className="search-list">
        {listUnselected.map(item => (
          <SearchListItemProfessionals
            selected
            key={item.id}
            item={item}
            onClick={onRemoveItem}
          />
        ))}
        <>
          {loading ? (
            <>
              <div style={{ marginBottom: 8 }}>
                <Skeleton height="56px" borderRadius="8px" />
              </div>
            </>
          ) : (
            <>
              {search ? (
                list?.map(item => (
                  <SearchListItemProfessionals
                    selected={isSelected(item)}
                    key={item.id}
                    item={item}
                    onClick={isSelected(item) ? onRemoveItem : onSelectItem}
                  />
                ))
              ) : listUnselected?.length === 0 ? (
                <div className="search-list-empty">
                  Os resultados da busca irão aparecer aqui.
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

interface SearchListItemProfessionalsProps {
  item: GetProfissionalDTO;
  onClick(v: GetProfissionalDTO): void;
  selected?: boolean;
}

const SearchListItemProfessionals = memo(
  (props: SearchListItemProfessionalsProps) => {
    const { item, onClick, selected } = props;

    const tipo = item?.tipo === 'PROFISSIONAL_SAUDE' ? 'Médico' : 'Secretária';

    return (
      <button
        onClick={() => onClick(item)}
        className={`
          SearchListItemProfessionals
          ${selected ? 'item-selected' : ''}
        `}
      >
        <Avatar />

        <SimpleText
          className="item-title"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {item?.nome}
        </SimpleText>

        <div className="item-description">
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
            {tipo}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
            {item?.tipo === 'PROFISSIONAL_SAUDE' ? item?.crm : ''}
          </SimpleText>
        </div>

        <span
          className={`
            item-button fas
            ${selected ? 'item-button-selected fa-minus' : ' fa-plus'}
          `}
        />
      </button>
    );
  },
);

export default memo(SearchListProfessionals);
