import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { Button } from 'src/components/_UI/Button';
import Can from 'src/components/Can/Can';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { DadosCadastrais } from './DadosCadastrais';
import { Header } from './Header';
import { Tiss } from './Tiss';

import './ModalVisualizarConvenio.scss';

interface ModalVisualizarConvenioProps {
  convenio: Convenio | null;
  isOpen: boolean;
  onClose: () => void;
  onSwitchStatus: (idConvenio: number, currentState: boolean) => void;
}

export const ModalVisualizarConvenio = (
  props: ModalVisualizarConvenioProps,
) => {
  const navigate = useNavigate();
  const { isOpen, convenio: item, onClose, onSwitchStatus } = props;

  const [convenio, setConvenio] = useState<any>(null);
  const [convenioTiss, setConvenioTiss] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async (idConvenio: number) => {
      try {
        setLoading(true);

        const [responseConvenio, responseConvenioTiss] = await Promise.all([
          ConvenioAPI.loadConveniosById(idConvenio),
          ConvenioAPI.getConveniosTissById(idConvenio),
        ]);

        setConvenio(responseConvenio);
        setConvenioTiss(responseConvenioTiss);
      } catch (error) {
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (!!item?.id) loadData(item.id);
  }, [item?.id, onClose]);

  const renderContent = () => {
    if (loading) {
      return <GridListLoading />;
    }

    return (
      <>
        <Header convenio={convenio} onSwitchStatus={onSwitchStatus} />

        <DadosCadastrais convenio={convenio} />

        <Tiss convenioTiss={convenioTiss} />

        <Can checkPermission="ADM_CONVENIO_ALTERAR">
          {can => (
            <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
              <Button
                type="button"
                btnType="ghost"
                label="Fechar"
                onClick={onClose}
                stretch
              />
              <Button
                type="button"
                label="Editar convênio"
                onClick={() => navigate(`/cadastros/convenio/${convenio?.id}`)}
                stretch
                disabled={!can}
              />
            </div>
          )}
        </Can>
      </>
    );
  };

  return (
    <Dialog
      id="modal-visualizar-convenio"
      type="modal"
      header="Visualizar convênio"
      visible={isOpen}
      onHide={onClose}
    >
      {renderContent()}
    </Dialog>
  );
};
