import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';
import * as Yup from 'yup';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalJustificativaInativacaoProps extends DisclosureType {
  historicoCompletoDisclosure: any;
  reloadList: any;
}

export const ModalJustificativaInativacao = ({
  close,
  isOpen,
  historicoCompletoDisclosure,
  reloadList,
}: ModalJustificativaInativacaoProps) => {
  const { selectedAtendimento } = useHistoricoAtendimento();

  const validationSchema = Yup.object().shape({
    justificativaInativacao: Yup.string().required(
      'Informe a justificativa da inativação',
    ),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      const response = await HistoricoAtendimentosAPI.inativarAtendimento(
        selectedAtendimento?.idAtendimento,
        data,
      );

      if (response?.status >= 200 && response?.status < 300) {
        reloadList();
        historicoCompletoDisclosure.close();
        reset();
        close();
      }
    },
    [
      close,
      historicoCompletoDisclosure,
      reloadList,
      reset,
      selectedAtendimento?.idAtendimento,
    ],
  );

  useEffect(() => {
    if (selectedAtendimento?.justificativaInativacao !== null) {
      setValue(
        'justificativaInativacao',
        selectedAtendimento?.justificativaInativacao,
      );
    } else {
      setValue('justificativaInativacao', '');
    }
  }, [selectedAtendimento?.justificativaInativacao, setValue]);

  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header="Você tem certeza que deseja inativar?"
      visible={isOpen}
      onHide={() => {
        reset();
        close();
      }}
    >
      <form id="inativacao-atendimento">
        {selectedAtendimento?.status === 'INATIVO' ? (
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Este item foi inativado.
          </SimpleText>
        ) : (
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Você está inativando a inclusão deste item no prontuário do
            paciente. Ele continuará ser exibido como uma informação inativa.
            Para continuar, justifique.
          </SimpleText>
        )}

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="justificativaInativacao"
          label="Justificativa"
          placeholder="Informe a justificativa"
          errorMsg={errors.justificativaInativacao?.message}
          disabled={selectedAtendimento?.status === 'INATIVO'}
        />
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            type="reset"
            label="Cancelar"
            onClick={() => {
              close();
              reset();
            }}
            stretch
          />
          <Button
            type="submit"
            label="Salvar"
            onClick={handleSubmit(onSubmit)}
            disabled={selectedAtendimento?.status === 'INATIVO'}
            loading={isSubmitting}
            stretch
          />
        </div>
      </form>
    </Dialog>
  );
};
