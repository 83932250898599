import React, { useCallback, useEffect, useState } from 'react';

import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import IconButton, { IconBtnTypes } from '../Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from '../Basics/SimpleText/SimpleText';
import Toast from '../Basics/Toast/Toast';
import './UploadFile.scss';

interface UploadFileProps {
  onUpload?: (file: any) => void;
  selectedFile: (files: File[]) => void;
  setFile?: any;
  accept: Accept;
  maxFiles?: number;
  textAcceptedFiles?: string;
  customRowFiles?: (file: File, onRemove: () => void) => JSX.Element;
  addMultiples?: boolean;
  maxSize?: number;
  initialFile?: File[];
}

const UploadFile = ({
  onUpload,
  selectedFile,
  accept,
  setFile,
  maxFiles = 1,
  textAcceptedFiles,
  customRowFiles,
  addMultiples = false,
  maxSize = 1000000,
  initialFile = [],
}: UploadFileProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>(initialFile);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (files.length + acceptedFiles.length > maxFiles) {
        return toast(<Toast />, {
          data: {
            title: 'Erro ao fazer o upload!',
            message: 'Limite máximo de arquivos excedido!',
            type: 'error',
          },
        });
      }

      if (!!rejectedFiles.length) {
        rejectedFiles.forEach((file: FileRejection) => {
          // Valida o tamanho do arquivo baseado no tamanho max fornecido
          file.errors.forEach(err => {
            if (err.code === 'file-too-large') {
              toast(<Toast />, {
                data: {
                  title: 'Erro ao fazer o upload!',
                  message: 'Tamanho máximo do arquivo excedido!',
                  type: 'error',
                },
              });
            }
          });
        });
      }

      setSelected(true);
      setFiles(old => [...old, ...acceptedFiles]);

      return;
    },
    [files.length, maxFiles],
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    accept: accept,
    maxSize: maxSize,
  });

  useEffect(() => {
    if (setFile) {
      setFiles([setFile]);
      setSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFile]);

  useEffectSkipFirst(() => {
    selectedFile(files || []);

    if (!files.length) {
      setSelected(false);
    }
  }, [files]);

  const onRemove = (idx: number) => {
    setFiles(old => old.filter((_, idxF) => idxF !== idx));
  };

  return (
    <div className="file-card">
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        {!selected && (
          <div className="upload">
            <div className="round">
              <i className="fa fa-file" />
            </div>

            <SimpleText
              className="text"
              fontSize={FONT_SIZE.SM}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Arraste seus arquivos aqui ou <strong>clique</strong>
            </SimpleText>

            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
              Formato aceito: {textAcceptedFiles || 'PDF, TIFF e JPG'}
            </SimpleText>
          </div>
        )}
      </div>

      {selected && addMultiples && (
        <div className="btn-upload-file" {...getRootProps()}>
          <i className="fas fa-plus" />
          <SimpleText bold>Adicionar</SimpleText>
          <input {...getInputProps()} />
        </div>
      )}

      {files.map((file: any, idx) => (
        <React.Fragment key={file.path}>
          {customRowFiles ? (
            customRowFiles(file, () => onRemove(idx))
          ) : (
            <div className="file" key={file.path}>
              <div>
                <i className="fa fa-file" />

                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_40}
                  medium
                >
                  {file.path || setFile}
                </SimpleText>
              </div>

              <IconButton
                type="button"
                btnType={IconBtnTypes.GREY}
                icon="fa fa-trash"
                onClick={() => onRemove(idx)}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default UploadFile;
