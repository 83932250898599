import { useMemo } from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { formatCPF } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PacienteProps {
  migracao: any;
  onDeleteCpf: () => void;
  onSelectOrigem?: (migracao: any) => void;
  onSelectDestino?: (migracao: any) => void;
  isSelected: boolean;
}

export const Paciente = (props: PacienteProps) => {
  const { windowInnerWidth, isTablet } = useSize();
  const { migracao, onSelectOrigem, onSelectDestino, isSelected, onDeleteCpf } =
    props;

  const isLarge = useMemo(() => windowInnerWidth > 1050, [windowInnerWidth]);

  const formatDate = (date: string): string => {
    const dateArray = date.split('-');
    const formattedDate =
      dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
    return formattedDate;
  };

  const getCpfOrPassaporte = () => {
    if (migracao?.cpf) {
      return formatCPF(migracao?.cpf);
    }

    if (migracao?.passaporte) {
      return migracao?.passaporte;
    }

    return 'Não informado';
  };

  return (
    <div
      className={
        isSelected
          ? 'p-grid p-col-12 p-p-0 p-ai-center origem selected'
          : 'p-grid p-col-12 p-p-0 p-ai-center origem'
      }
      onClick={onSelectOrigem ? onSelectOrigem : onSelectDestino}
    >
      <div className="p-col-3 p-d-flex p-ai-center">
        <div>
          <Avatar
            className="avatar p-mr-2"
            label={migracao?.paciente?.nome}
            image={migracao?.paciente?.fotoUrl || ''}
          />
        </div>
        <div className="p-d-flex p-flex-column p-ml-1 p-gap-1">
          <SimpleText
            fontSize={isLarge ? FONT_SIZE.XS : FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {migracao?.paciente?.nome}
          </SimpleText>
          <SimpleText
            fontSize={isLarge ? FONT_SIZE.XS : FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            {migracao?.paciente?.id} -{' '}
            {migracao?.paciente?.dataNascimento
              ? formatDate(migracao?.paciente?.dataNascimento)
              : 'Não informado'}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-1 p-md-2 p-d-flex p-ai-center p-jc-between">
        {!isTablet && (
          <SimpleText
            className="p-mr-1"
            fontSize={isLarge ? FONT_SIZE.XS : FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {migracao?.nomeMae}
          </SimpleText>
        )}
        <IconButton
          className="atendimentos-info"
          icon="fas fa-clipboard-question"
          btnType={IconBtnTypes.ICON_ONLY}
          overlay={
            <div className="p-grid detalhe-atendimento-migracao">
              <div className="p-col-12 p-d-flec p-jc-center">
                <SimpleText
                  fontSize={isLarge ? FONT_SIZE.XXS : FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  Informações de atendimento:
                </SimpleText>
              </div>
              <div className="p-col-12 p-grid p-py-1">
                <div className="p-col-2 p-py-1">
                  <SimpleText
                    fontSize={isLarge ? FONT_SIZE.XXXS : FONT_SIZE.XXXXS}
                    fontColor={FONT_COLOR.COLOR_60}
                    medium
                  >
                    Número
                  </SimpleText>
                </div>
                <div className="p-col-5 p-py-1">
                  <SimpleText
                    fontSize={isLarge ? FONT_SIZE.XXXS : FONT_SIZE.XXXXS}
                    fontColor={FONT_COLOR.COLOR_60}
                    medium
                  >
                    Data ocorrência
                  </SimpleText>
                </div>
                <div className="p-col-5 p-py-1">
                  <SimpleText
                    fontSize={isLarge ? FONT_SIZE.XXXS : FONT_SIZE.XXXXS}
                    fontColor={FONT_COLOR.COLOR_60}
                    medium
                  >
                    Nome médico
                  </SimpleText>
                </div>
              </div>
              {migracao?.atendimentos?.map((atendimento: any, index: any) => (
                <div
                  key={index}
                  className="p-col-12 p-grid detalhe-atendimento p-mb-1 p-py-1"
                >
                  <div className="p-col-2 p-py-1">
                    <SimpleText
                      fontSize={isLarge ? FONT_SIZE.XXXS : FONT_SIZE.XXXXS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {atendimento?.id}
                    </SimpleText>
                  </div>
                  <div className="p-col-5 p-py-1">
                    <SimpleText
                      fontSize={isLarge ? FONT_SIZE.XXXS : FONT_SIZE.XXXXS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {atendimento?.dataOcorrencia
                        ? dayjs(new Date(atendimento?.dataOcorrencia)).format(
                            'DD/MM/YYYY HH:mm',
                          )
                        : 'Não informado'}
                    </SimpleText>
                  </div>
                  <div className="p-col-5 p-py-1">
                    <SimpleText
                      fontSize={isLarge ? FONT_SIZE.XXXS : FONT_SIZE.XXXXS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {atendimento?.nomeMedico}
                    </SimpleText>
                  </div>
                </div>
              ))}
            </div>
          }
        />
      </div>
      <div
        title={migracao?.cartaoConvenio || 'Não informado'}
        className="p-col-3 p-md-2 p-text-truncate"
      >
        <SimpleText
          fontSize={isLarge ? FONT_SIZE.XS : FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.cartaoConvenio || 'Não informado'}
        </SimpleText>
      </div>
      <div
        title={migracao?.cns || 'Não informado'}
        className="p-col-2 p-text-truncate"
      >
        <SimpleText
          fontSize={isLarge ? FONT_SIZE.XS : FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.cns || 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col">
        <div className="column">
          <SimpleText
            fontSize={isLarge ? FONT_SIZE.XXS : FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {getCpfOrPassaporte()}
          </SimpleText>
        </div>
      </div>
      <div className="p-col p-d-flex p-ai-center p-jc-end">
        <Button
          label={isLarge ? 'Apagar CPF' : ''}
          btnType="tonal"
          icon="fas fa-eraser"
          iconPos="left"
          onClick={onDeleteCpf}
          style={{ maxHeight: '35px' }}
          {...(!isLarge && {
            tooltip: 'Apagar CPF',
            tooltipOptions: { position: 'top' },
          })}
        />
      </div>
    </div>
  );
};
