import * as Yup from 'yup';

export const validationSchemaGeral = Yup.object({
  formularioDinamico: Yup.object({
    id: Yup.number(),
  }).required('Formulário é obrigatório'),
  nome: Yup.string()
    .required('Nome é obrigatório')
    .max(50, 'Nome deve ter no máximo 50 caracteres'),
  publico: Yup.object({
    codigo: Yup.string().required('Público é obrigatório'),
  }).required('Público é obrigatório'),
  descricao: Yup.string()
    .required('Descrição é obrigatória')
    .max(500, 'Descrição deve ter no máximo 500 caracteres'),
  dataInicioVigencia: Yup.date().required('Data início é obrigatória'),
  dataFimVigencia: Yup.date()
    .when('dataInicioVigencia', {
      is: (dataInicio: Date) => !!dataInicio,
      then: Yup.date()
        .min(
          Yup.ref('dataInicioVigencia'),
          'Data fim deve ser maior que data início',
        )
        .nullable(),
    })
    .nullable()
    .default(null),
});
