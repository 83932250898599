import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalLista.scss';

interface ModalListaProps {
  modalLista: any;
  onHide: () => void;
}

function ModalLista({ modalLista, onHide }: ModalListaProps) {
  return (
    <Dialog
      type="modal"
      header={modalLista?.title}
      visible={modalLista}
      onHide={onHide}
      maximizedMobileSize
    >
      <>
        <div id="modal-lista-container">
          <div className="p-mb-2">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              {modalLista?.subtitle}
            </SimpleText>
          </div>

          {modalLista?.data?.map((item: any, idx: number) => (
            <SimpleText
              key={idx}
              className="card-modal-lista"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {item.nome}
            </SimpleText>
          ))}
        </div>

        <Button
          className="p-mt-4"
          btnType="ghost"
          label="Fechar"
          onClick={onHide}
          stretch
        />
      </>
    </Dialog>
  );
}

export default ModalLista;
