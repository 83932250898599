import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  processandoTiss: any[];
}

const initialState: any = {};

export const tissSlice = createSlice({
  name: 'atendimento',
  initialState: { processandoTiss: [] },
  reducers: {
    setNewAgendamentoProcessandoTiss: (
      state: any,
      action: PayloadAction<any>,
    ) => {
      return {
        ...state,
        processandoTiss: action.payload,
      };
    },
  },
});

export const { setNewAgendamentoProcessandoTiss } = tissSlice.actions;

export default tissSlice.reducer;
