import { memo, useCallback, useMemo } from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './ServicoTussSelected.scss';
import { Button } from 'src/components/_UI';

interface ServicoTussSelectedProps {
  value: ServicosTussComGuiaTiss[] | undefined;
  onChange(i: ServicosTussComGuiaTiss[]): void;
}

interface SelectedList {
  profissionalSaude: ServicosTussComGuiaTiss[];
}

const ServicoTussSelected = (props: ServicoTussSelectedProps) => {
  const { value = [], onChange } = props;

  const list: SelectedList = useMemo(() => {
    return value.reduce(
      (value: SelectedList, item) => {
        value.profissionalSaude.push(item);

        return value;
      },
      { profissionalSaude: [] },
    );
  }, [value]);

  const handleRemove = useCallback(
    (item: ServicosTussComGuiaTiss) => {
      const items = value.filter(i => i.id !== item.id);
      onChange(items);
    },
    [onChange, value],
  );

  return (
    <div className="ServicoTussSelected" style={{ marginBottom: 0 }}>
      <div style={{ marginBottom: 16 }}>
        <div>
          {list.profissionalSaude.length > 0 ? (
            <>
              {list.profissionalSaude?.map(item => (
                <ServicoTussSelectedItem
                  key={`${item.id}`}
                  item={item}
                  onClick={handleRemove}
                />
              ))}
            </>
          ) : (
            <EmptyText />
          )}
        </div>
      </div>
    </div>
  );
};

const EmptyText = memo(() => {
  return (
    <SimpleText
      fontSize={FONT_SIZE.XXXS}
      fontColor={FONT_COLOR.COLOR_60}
      className="search-list-empty"
    >
      Não há cadastros.
    </SimpleText>
  );
});

const ServicoTussSelectedItem = memo((props: ServicoTussSelectedItemProps) => {
  const { item, onClick } = props;

  return (
    <div className="ServicoTussSelectedItem">
      <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
        {item?.codigo}
      </SimpleText>
      <SimpleText
        className="p-ml-2"
        fontSize={FONT_SIZE.XS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        -
      </SimpleText>
      <SimpleText
        className="item-title"
        fontSize={FONT_SIZE.XS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {item.nome}
      </SimpleText>

      <Button
        btnType="gray"
        onClick={() => onClick(item)}
        icon="fas fa-trash"
      />
    </div>
  );
});

interface ServicoTussSelectedItemProps {
  item: ServicosTussComGuiaTiss;
  onClick(v: ServicosTussComGuiaTiss): void;
}

export default memo(ServicoTussSelected);
