/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import useSize from 'src/core/hooks/useSize';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Header, MobileRow } from './List';

import './CentralConsultorio.scss';

const CentralConsultório = () => {
  const { isMobile } = useSize();

  const navigate = useNavigate();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_consultorio_cadastrar',
      'adm_consultorio_alterar',
      'adm_consultorio_consultar',
      'adm_consultorio_excluir',
    ],
  });

  const [confirmDelete, setConfirmDelete] = useState<any>(null);
  const [reload, setReload] = useState(0);

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const onEdit = (item: any) => {
    if (item.id) {
      navigate(`/cadastros/consultorio/cadastro/${item.id}`, { state: item });
    }
  };

  const onDelete = async (item: any) => {
    if (!item.id) return;

    try {
      setConfirmDelete(false);
      await ConsultorioAPI.removeConsultorioById(item.id);

      reloadList();
    } catch {}
  };

  const renderRow = useCallback(
    (data: any): JSX.Element => {
      const commomProps = {
        consultorio: data,
        onEdit: onEdit,
        onDelete: setConfirmDelete,
      };

      if (isMobile) return <MobileRow {...commomProps} />;

      return (
        <SearchList.CardItem>
          <DesktopRow {...commomProps} />
        </SearchList.CardItem>
      );
    },
    [isMobile],
  );

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await ConsultorioAPI.loadConsultorios({
      nome: params?.query,
      ...params,
    });
  };

  return (
    <>
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.SearchInput
          label="Pesquisar consultórios"
          placeholder="Digite um nome"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
            <Button
              type="button"
              btnType="pill"
              label="Incluir novo consultório"
              icon="fas fa-clinic-medical"
              onClick={() => navigate('/cadastros/consultorio/cadastro')}
              checkPermission="ADM_CONSULTORIO_CADASTRAR"
            />

            <SearchList.TotalCount className="p-mt-3 p-mt-sm-0" />
          </div>

          {!isMobile && <Header />}

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ConfirmDialog
        visible={confirmDelete}
        header={'Excluir consultório'}
        text="O consultório será excluído da base de dados. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => {
          setConfirmDelete(null);
        }}
        onConfirm={() => {
          onDelete(confirmDelete);
        }}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default CentralConsultório;
