import { useRef, useMemo, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import RelatorioPepAPI from 'src/APIs/ReportAPI/RelatorioPepAPI';
import { LoadPlanoCuidadoByIdProps } from 'src/models/APIs/ProntuarioAPI/PlanoCuidado/PlanoCuidado';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { preventDefaultAndStopPropagation } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';
import { ProgressBar } from 'src/components/ProgressBar/ProgressBar';

export const PlanosPacientesRowHeader = ({
  item,
  isInativo,
  edicaoDialog,
  percentual,
  type,
}: any) => {
  const [isPrinting, setIsPrinting] = useState(false);

  const { idUsuario } = useAppSelector(state => state.user);
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const dispatch = useDispatch();

  const inativarDialog = useDisclosure({ opened: false });
  const deleteDialog = useRef<OverlayPanel>(null);

  const menuRef = useRef<TieredMenu>(null);

  const { atendimentoStatus } = useAtendimento();

  const planoCuidadoOrigem: LoadPlanoCuidadoByIdProps = item.planoCuidado;
  const isAtendendo = atendimentoStatus === 'ATENDENDO';

  const handleDelete = () => {
    PlanoCuidadoPacienteAPI.deletarPlanoCuidadoPaciente({
      id: item.id,
      idAtendimento: idAtendimento!,
    })
      .then(() => {
        dispatch(setInvalidateQuery({ invalidatePlanosList: true }));
      })
      .catch(() => {});
  };

  const handlePrint = async () => {
    try {
      setIsPrinting(true);

      const response = await RelatorioPepAPI.relatorioPepPlanoCuidado({
        idPlanoCuidadoPaciente: item.id,
      });

      if (typeof response !== 'object') {
        const data = {
          title: 'Erro',
          message: 'Falha ao baixar as guias, tente novamente',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);

      return;
    } catch {
      return;
    } finally {
      setIsPrinting(false);
    }
  };

  const canEdit = item.profissionaisResponsaveis.some(
    (prof: any) => prof.id === idUsuario,
  );

  const ellipsisOptions = useMemo(() => {
    if (item.idAtendimentoOrigem == idAtendimento) {
      return [
        {
          label: 'Excluir',
          command: (e: any) => {
            deleteDialog.current?.toggle(e as any);
            menuRef.current?.toggle(e as any);
            e.originalEvent.stopPropagation();
          },
          className: 'error',
        },
      ];
    }

    return [
      {
        label: 'Inativar',
        command: (e: any) => [
          inativarDialog.open({
            state: { id: item.id, idAtendimento: idAtendimento },
          }),
          menuRef.current?.toggle(e as any),
          e.originalEvent.stopPropagation(),
        ],
        className: 'error',
      },
    ];
  }, [idAtendimento, inativarDialog, item.id, item.idAtendimentoOrigem]);

  const renderEllipsisBtn = () => {
    if (isInativo) {
      return (
        <Button
          icon="fas fa-eye"
          btnType="ghost"
          onClick={e => [
            preventDefaultAndStopPropagation(e),
            inativarDialog.open({
              state: {
                justificativaInativacao: item.justificativaInativacao,
                dataInativacao: item.dataAtualizacao,
                usuarioInativacao: item.usuarioAtualizacao,
              },
            }),
          ]}
        />
      );
    }

    return (
      <>
        <Button
          icon="fas fa-ellipsis"
          btnType="ghost"
          onClick={e => [
            preventDefaultAndStopPropagation(e),
            menuRef.current?.toggle(e),
          ]}
          disabled={type === 'PRONTUARIO' ? !isAtendendo : isInativo}
        />

        <TieredMenu ref={menuRef} popup model={ellipsisOptions} />
      </>
    );
  };

  return (
    <div className="p-w-100 p-d-flex p-ai-center acc-header-plano">
      <SimpleText className="p-col-5 p-md-7 " fontSize={FONT_SIZE.SM} medium>
        {planoCuidadoOrigem.nome}
      </SimpleText>

      <div className="p-col-2">
        <ProgressBar value={percentual} />
      </div>
      <div className="p-col-5 p-md-3 p-d-flex p-gap-1 p-jc-end p-pr-4">
        {type !== 'HISTORICO' && (
          <Button
            icon="fas fa-print"
            btnType="ghost"
            loading={isPrinting}
            onClick={e => [preventDefaultAndStopPropagation(e), handlePrint()]}
          />
        )}
        {type === 'PRONTUARIO' && (
          <Button
            icon="fas fa-edit"
            onClick={e => [
              preventDefaultAndStopPropagation(e),
              edicaoDialog.open({
                state: {
                  item,
                },
              }),
            ]}
            btnType="ghost"
            disabled={!canEdit || !isAtendendo}
            type="button"
          />
        )}
        {renderEllipsisBtn()}

        <OverlayPanel
          appendTo="self"
          className="delete-dialog-plano-cuidado"
          ref={deleteDialog}
        >
          <SimpleText className="p-w-100">
            Esta exclusão será permanente. Você tem certeza que deseja excluir?
          </SimpleText>
          <div className="p-d-flex p-gap-2 p-mt-2">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={e => [
                preventDefaultAndStopPropagation(e),
                deleteDialog.current?.hide(),
              ]}
            />
            <Button
              btnType="danger"
              label="Confirmar"
              onClick={e => [
                preventDefaultAndStopPropagation(e),
                handleDelete(),
                deleteDialog.current?.hide(),
              ]}
            />
          </div>
        </OverlayPanel>
      </div>

      {inativarDialog.isOpen && <DialogInativar {...inativarDialog} />}
    </div>
  );
};

const DialogInativar = ({
  close,
  isOpen,
  state,
}: DisclosureType<{
  id?: string;
  justificativaInativacao?: string;
  dataInativacao?: string;
  usuarioInativacao?: any;
  idAtendimento?: number | string;
}>) => {
  const dispatch = useDispatch();

  const isView = !state?.id;

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        justificativaInativacao: Yup.string().required(
          'Justificativa é obrigatória',
        ),
      }),
    ),
    defaultValues: { justificativaInativacao: state?.justificativaInativacao },
  });

  const onSubmit = (values: any) => {
    return PlanoCuidadoPacienteAPI.inativarPlanoCuidadoPaciente(
      {
        id: state?.id,
      },
      {
        ...values,
        idAtendimento: state?.idAtendimento,
      },
    )
      .then(() => [
        close(),
        dispatch(setInvalidateQuery({ invalidatePlanosList: true })),
      ])
      .catch(() => {});
  };

  const renderBtns = () => {
    if (isView) {
      return (
        <Button
          type="button"
          btnType="outline"
          label="Fechar"
          onClick={() => close()}
          stretch
        />
      );
    }

    return (
      <>
        <Button
          type="button"
          btnType="green-link"
          label="Cancelar"
          onClick={() => close()}
          disabled={form.formState.isSubmitting}
          stretch
        />
        <Button
          type="submit"
          label="Salvar"
          loading={form.formState.isSubmitting}
          stretch
          onClick={e => e.stopPropagation()}
        />
      </>
    );
  };

  const renderSubtitle = () => {
    if (isView) {
      const dataInativacao = state?.dataInativacao
        ? dayjs(new Date(state?.dataInativacao))
            .locale('pt-br')
            .format('DD [de] MMMM [de] YYYY')
        : '';

      return (
        <>
          Este item no prontuário foi inativo em{' '}
          <strong>{dataInativacao}</strong> por{' '}
          <strong>{state?.usuarioInativacao?.nome || ''}</strong>
        </>
      );
    }

    return 'Você está inativando um plano de cuidado. Preencha a justificativa para prosseguir com a inativação.';
  };

  return (
    <Dialog
      className="modal-inativar-plano-cuidado-paciente"
      header="Inativar plano de cuidado"
      visible={isOpen}
      onHide={close}
      onClick={preventDefaultAndStopPropagation}
      maximizedMobileSize
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-d-flex p-flex-column p-gap-2"
      >
        <SimpleText
          className="p-col-12 p-py-0 p-line-height-2 p-wordbreak-normal"
          fontColor="color_40"
        >
          {renderSubtitle()}
        </SimpleText>
        <TextareaInputControlled
          control={form.control}
          name="justificativaInativacao"
          label="Justificativa"
          className="p-col-12"
          readOnly={isView}
        />

        <div className="p-d-flex p-ai-center p-col-12">{renderBtns()}</div>
      </form>
    </Dialog>
  );
};
