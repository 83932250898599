import { useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { ConvenioRow } from './ConvenioGrid/ConvenioRow';
import { Header } from './Header';
import { ModalVisualizarConvenio } from './ModalVisualizarConvenio/ModalVisualizarConvenio';

import './CentralConvenio.scss';
import useSize from 'src/core/hooks/useSize';

const CentralConvenio = () => {
  const navigate = useNavigate();
  const { windowInnerWidth } = useSize();

  const visualizarConvenio = useDisclosure({ opened: false });

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_convenio_cadastrar',
      'adm_convenio_alterar',
      'adm_convenio_consultar',
      'adm_convenio_excluir',
    ],
  });

  const [reload, setReload] = useState(0);
  const [idConvenioExcluir, setIdConvenioExcluir] = useState<number | null>(
    null,
  );
  const [idConvenioDesativar, setIdConvenioDesativar] = useState<number | null>(
    null,
  );

  const isMobile = useMemo(() => windowInnerWidth <= 650, [windowInnerWidth]);

  const fetchAPI = ({ query, ...rest }: any) => {
    return ConvenioAPI.loadConvenios({
      nome: query,
      ...rest,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const renderRow = (convenio: Convenio) => (
    <ConvenioRow
      convenio={convenio}
      onViewConvenio={() => visualizarConvenio.open({ state: convenio })}
      onDelete={() => setIdConvenioExcluir(convenio.id)}
      onSwitchStatus={() => handleSwitchStatus(convenio.id, convenio.ativo)}
    />
  );

  const handleConfirmDelete = async () => {
    if (!idConvenioExcluir) return;

    try {
      await ConvenioAPI.deleteConvenio(idConvenioExcluir);

      reloadList();
    } catch (error) {
    } finally {
      setIdConvenioExcluir(null);
    }
  };

  const handleSwitchStatus = async (
    idConvenio: number,
    currentState: boolean,
  ) => {
    if (currentState) return setIdConvenioDesativar(idConvenio);

    handleConfirmSwitchStatus(idConvenio, !currentState);
  };

  const handleConfirmSwitchStatus = async (
    idConvenio: number,
    state: boolean,
  ) => {
    try {
      await ConvenioAPI.updateConvenioStatus({ ativo: state }, idConvenio);

      if (visualizarConvenio.isOpen)
        visualizarConvenio.open({
          state: { ...visualizarConvenio.state, ativo: state },
        });

      reloadList();
    } catch (error) {
    } finally {
      setIdConvenioDesativar(null);
    }
  };

  return (
    <>
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.SearchInput
          label="Pesquisar convênios"
          placeholder="Nome do convênio"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
            <Button
              type="button"
              btnType="pill"
              label="Incluir novo convênio"
              icon="fas fa-plus"
              onClick={() => navigate('/cadastros/convenio/novo')}
              checkPermission="ADM_CONVENIO_CADASTRAR"
            />

            <SearchList.TotalCount className="p-mt-3 p-mt-sm-0" />
          </div>

          {!isMobile && <Header />}

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalVisualizarConvenio
        convenio={visualizarConvenio.state}
        isOpen={visualizarConvenio.isOpen}
        onClose={visualizarConvenio.close}
        onSwitchStatus={handleSwitchStatus}
      />

      <ConfirmDialog
        visible={!!idConvenioExcluir}
        header="Excluir convênio"
        text="Ao excuir, o convênio não poderá ser vinculado a pacientes e médicos. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdConvenioExcluir(null)}
        onConfirm={handleConfirmDelete}
      />

      <ConfirmDialog
        visible={!!idConvenioDesativar}
        header="Confirmar ação de desativar convênio"
        text="Não será mais possível selecionar este convênio no eMed. Você realmente deseja desativar?"
        confirmText="Sim, desativar"
        onHide={() => setIdConvenioDesativar(null)}
        onConfirm={() =>
          handleConfirmSwitchStatus(idConvenioDesativar as number, false)
        }
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default CentralConvenio;
