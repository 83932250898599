/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useMemo } from 'react';

export type TempoAtendimentoProps = {
  isActive: boolean;
  pause: () => void;
  reset: () => void;
  start: () => void;
  time: string;
  setTime: (newTime: string | number) => void;
};

type SettingsType = {
  format?: 'hh:mm:ss' | 'descriptive' | 'mm:ss';
  initial?: any;
  decrement?: boolean;
};

export function useTimer({
  initial = 0,
  format = 'mm:ss',
  decrement = false,
}: SettingsType): TempoAtendimentoProps {
  let coundownTimeout: NodeJS.Timeout;

  const [time, setTime] = useState(initial);
  const [isActive, setIsActive] = useState(false);

  const seconds = time % 60;
  const minutes = Math.floor((time / 60) % 60);
  const hours = Math.floor(time / 60 / 60);

  if (hours > 0) {
    format = 'hh:mm:ss';
  }

  function start() {
    if (isActive) return;
    setIsActive(true);
  }

  function reset() {
    clearTimeout(coundownTimeout);
    setIsActive(false);
    setTime(initial);
  }

  function pause() {
    setIsActive(false);
  }

  function handleSetTime(time: number | string) {
    if (typeof time === 'string') {
      const seconds = time.split(':');
      const timeLength = time.split(':').length;

      // transform time hh:mm:ss/mm:ss to seconds
      if (timeLength === 3) {
        format = 'hh:mm:ss';
        setTime(+seconds[0]! * 60 * 60 + +seconds[1]! * 60 + +seconds[2]!);
      } else {
        setTime(+seconds[0]! * 60 + +seconds[1]!);
      }
    } else {
      setTime(time);
    }
  }

  useEffect(() => {
    if (isActive) {
      if (time === 0 && decrement) return setIsActive(false);

      coundownTimeout = setTimeout(() => {
        setTime(decrement ? time - 1 : time + 1);
      }, 1000);
    }

    return () => {
      clearTimeout(coundownTimeout);
    };
  }, [decrement, isActive, time]);

  const timeToLocale = useMemo(() => {
    if (format === 'descriptive') {
      const minuteLabel = minutes > 1 ? 'minutos' : 'minuto';
      const secondLabel = seconds > 1 ? 'segundos' : 'segundo';

      if (!minutes) return `${String(seconds).padStart(2, '0')} ${secondLabel}`;

      return `${String(minutes).padStart(2, '0')} ${minuteLabel} ${String(
        seconds,
      ).padStart(2, '0')} ${secondLabel}`;
    }

    if (format === 'hh:mm:ss') {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0',
      )}:${String(seconds).padStart(2, '0')}`;
    }

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0',
    )}`;
  }, [format, hours, minutes, seconds]);

  return {
    time: timeToLocale,
    setTime: handleSetTime,
    isActive,
    start,
    reset,
    pause,
  };
}
