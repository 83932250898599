import { useState, useCallback } from 'react';

import { useLocation, useParams } from 'react-router';

import TemperaturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/TemperaturaAPI/TemperaturaAPI';

import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from '../PanelContent/PanelContent';
import PanelHeader from '../PanelHeader/PanelHeader';

interface TemperaturaProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const Temperatura = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: TemperaturaProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [unidadeMedidaVisualizacao, setUnidadeMedidaVisualizacao] = useState<
    'MOSTRAR_ORIGINAL' | 'CELSIUS' | 'KELVIN' | 'FAHRENHEIT'
  >('MOSTRAR_ORIGINAL');

  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      return TemperaturaAPI.getTemperatura({
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        ...params,
      });
    },
    [idAtendimento, selectedAtendimento?.idAtendimento],
  );

  const manterDuasDecimais = (value: number) => {
    return String(value)
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\.\d{2}).+/g, '$1');
  };

  const formatValueView = (value: number, unidadeMedida: any) => {
    if (
      unidadeMedida.codigo === 'C' &&
      unidadeMedidaVisualizacao === 'KELVIN'
    ) {
      return `${manterDuasDecimais(value + 273.15)} Kelvin`;
    } else if (
      unidadeMedida.codigo === 'C' &&
      unidadeMedidaVisualizacao === 'FAHRENHEIT'
    ) {
      return `${manterDuasDecimais((value * 9) / 5 + 32)} Fahrenheit`;
    }

    if (
      unidadeMedida.codigo === 'K' &&
      unidadeMedidaVisualizacao === 'CELSIUS'
    ) {
      return `${manterDuasDecimais(value - 273.15)} Celsius`;
    } else if (
      unidadeMedida.codigo === 'K' &&
      unidadeMedidaVisualizacao === 'FAHRENHEIT'
    ) {
      return `${manterDuasDecimais(
        ((value - 273.15) * 9) / 5 + 32,
      )} Fahrenheit`;
    }

    if (
      unidadeMedida.codigo === 'F' &&
      unidadeMedidaVisualizacao === 'CELSIUS'
    ) {
      return `${manterDuasDecimais(((value - 32) * 5) / 9)} Celsius`;
    } else if (
      unidadeMedida.codigo === 'F' &&
      unidadeMedidaVisualizacao === 'KELVIN'
    ) {
      return `${manterDuasDecimais(((value - 32) * 5) / 9 + 273.15)} Kelvin`;
    }

    return `${value} ${unidadeMedida.descricao}`;
  };

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
  ) => {
    return (
      <div className="p-col-8 p-md-6 p-d-flex">
        <div className="column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Temperatura
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {formatValueView(
              preAtendimento.temperatura,
              preAtendimento.unidadeMedida,
            )}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
        <div className="column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Local
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.temperaturaLocalAfericao}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
        <div className="column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Equipamento
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.temperaturaEquipamentoAfericao}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento} />
      </Panel>
    );
  };

  return (
    <div className="container-unidade-medida">
      <div className="p-grid">
        <SimpleText
          className="p-col-12 p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Exibir unidades de medida em:
        </SimpleText>

        <div className="p-col-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Mostrar original"
            value="MOSTRAR_ORIGINAL"
            checked={unidadeMedidaVisualizacao === 'MOSTRAR_ORIGINAL'}
            onChange={() => setUnidadeMedidaVisualizacao('MOSTRAR_ORIGINAL')}
          />
        </div>
        <div className="p-col-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Celsius"
            value="CELSIUS"
            checked={unidadeMedidaVisualizacao === 'CELSIUS'}
            onChange={() => setUnidadeMedidaVisualizacao('CELSIUS')}
          />
        </div>
        <div className="p-col-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Kelvin"
            value="KELVIN"
            checked={unidadeMedidaVisualizacao === 'KELVIN'}
            onChange={() => setUnidadeMedidaVisualizacao('KELVIN')}
          />
        </div>
        <div className="p-col-3 p-as-center p-js-center">
          <RadioButton
            name="unidadeMedida"
            label="Fahrenheit"
            value="FAHRENHEIT"
            checked={unidadeMedidaVisualizacao === 'FAHRENHEIT'}
            onChange={() => setUnidadeMedidaVisualizacao('FAHRENHEIT')}
          />
        </div>
      </div>

      <InfiniteScroll
        fetchAPI={fetchAPI}
        renderRow={renderRow}
        updateList={updateList}
      />
    </div>
  );
};

export default Temperatura;
