import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import UsuarioAPI, {
  TIPO_USUARIO,
} from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  setProfissionaisDisponiveis,
  setProfissionalAtivo,
} from 'src/core/redux/slices/agenda/AgendaSlice';
import { RootState } from 'src/core/redux/store';

import './ConfiguracoesAgenda.scss';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tab from 'src/components/Tab/Tab';

import MenuEditarHorarios from 'src/pages/Emed/Agenda/MenuEditarHorarios/MenuEditarHorarios';
import UserHeader from 'src/pages/Emed/Agenda/UserHeader/UserHeader';

import Compromissos from '../Compromissos/Compromissos';

const ConfiguracoesAgenda = () => {
  const { user, consultorios } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadProfissionais = useCallback(async () => {
    try {
      if (!consultorios.ativo) {
        return;
      }
      const aux = await UsuarioAPI.loadProfissionaisByUserConsultorio(
        consultorios.ativo.id,
      );
      dispatch(setProfissionaisDisponiveis(aux));
    } catch (e) {
      console.log(e);
    }
  }, [consultorios.ativo, dispatch]);

  const loadMedico = useCallback(async () => {
    try {
      const aux = await UsuarioAPI.loadUsuarioById(user?.idUsuario || -1);
      if (aux) {
        dispatch(setProfissionalAtivo(aux));
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user.tipoUsuario !== TIPO_USUARIO.PROFISSIONAL_SAUDE) {
      loadProfissionais();
    } else {
      loadMedico();
    }
  }, [user, loadProfissionais, loadMedico]);

  return (
    <Page className={'ConfiguracoesAgenda'}>
      <>
        <div className={'agenda-content'}>
          <div>
            <UserHeader isConfigAgenda backButton={() => navigate('/agenda')} />
          </div>
        </div>
        <div className="header">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.SM}
            bold
          >
            Configuração da agenda
          </SimpleText>
        </div>
        <div className="content">
          <Tab
            fullHeight
            clean
            className="tab-panel-config-agenda"
            noPanel
            headerColor
            values={[
              {
                label: 'Horário de atendimento',
                content: <MenuEditarHorarios />,
                checkPermission: 'AGE_HORARIO_DE_ATENDIMENTO_CONSULTAR',
              },
              {
                label: 'Compromissos',
                content: <Compromissos />,
                checkPermission: 'AGE_COMPROMISSO_CONSULTAR',
              },
            ]}
          />
        </div>
      </>
    </Page>
  );
};

export default ConfiguracoesAgenda;
