import { useCallback, useState, useEffect, useRef } from 'react';

import { loadProfissionalSaudeByConsultorio } from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Avatar from 'src/components/Avatar/Avatar';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import GridListLoading from 'src/components/GridList/GridListLoading';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';

import './SearchProfissionais.scss';

interface SearchProfissionaisProps {
  label?: string;
  setProfissionaisSelecionados(profissional: any): void;
  profissionaisAdded: any;
  profissionaisSelecionados: any;
}

const SearchProfissionais = (props: SearchProfissionaisProps) => {
  const { consultorios } = useAppSelector((state: RootState) => state);

  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [profissionaisSearch, setProfissionaisSearch] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const {
    label,
    profissionaisSelecionados,
    profissionaisAdded,
    setProfissionaisSelecionados,
  } = props;

  const getProfissionaisConsultorio = useCallback(async () => {
    try {
      setLoading(true);
      const response = await loadProfissionalSaudeByConsultorio({
        idConsultorio: consultorios.ativo.id,
      });

      if ('status' in response) return;

      setProfissionaisSearch(response);
    } catch (e) {
      setProfissionaisSearch([]);
    } finally {
      setLoading(false);
    }
  }, [consultorios.ativo.id]);

  useEffect(() => {
    getProfissionaisConsultorio();
  }, [getProfissionaisConsultorio]);

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnPanel) {
        setShowOverlay(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, []);

  const profissionaisFiltered = profissionaisSearch.filter(
    (profissional: any) => {
      const nameIncluded = profissional.nome
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const idNotIncluded =
        !profissionaisAdded.some(
          (added: any) => added.id === profissional.id,
        ) &&
        !profissionaisSelecionados.some(
          (selecionado: any) => selecionado.id === profissional.id,
        );

      return nameIncluded && idNotIncluded;
    },
  );

  const renderOverlay = useCallback(() => {
    if (loading) return <GridListLoading />;

    if (!profissionaisFiltered.length)
      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Nenhum profissional encontrado
        </SimpleText>
      );

    return (
      <div className="scroll-place">
        <ScrollPanel stretch={true} activityIndicator={false}>
          <>
            {profissionaisFiltered?.map((profissional: any, index: number) => (
              <div
                key={index}
                className="p-my-1"
                id="search-profissionais-card"
                onClick={() => {
                  const profissionalExistente = profissionaisSelecionados.find(
                    (p: any) => p.id === profissional.id,
                  );

                  if (!profissionalExistente) {
                    setProfissionaisSelecionados((prev: any) => [
                      ...prev,
                      profissional,
                    ]);
                  }

                  setShowOverlay(false);
                }}
              >
                <Card type={CARD_TYPES.GRAY}>
                  <div className="p-d-flex p-p-1">
                    <div className="p-d-flex">
                      <div className="p-mr-1">
                        <Avatar
                          className="avatar"
                          label={profissional?.nome}
                          image={profissional?.urlFoto || ''}
                        />
                      </div>
                      <div className="p-d-flex p-flex-column p-jc-center">
                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_16}
                          className="p-mb-1"
                          medium
                        >
                          {profissional?.nome}
                        </SimpleText>
                        <SimpleText
                          fontSize={FONT_SIZE.XXS}
                          fontColor={FONT_COLOR.COLOR_60}
                        >
                          {profissional.tipoProfissional} •{' '}
                          {profissional.siglaConselhoTiss}{' '}
                          {profissional.numeroRegistro}
                        </SimpleText>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </>
        </ScrollPanel>
      </div>
    );
  }, [
    loading,
    profissionaisFiltered,
    profissionaisSelecionados,
    setProfissionaisSelecionados,
  ]);

  return (
    <div ref={searchPanelContainerRef} className="search-input">
      <TextInput
        label={label || ''}
        rounded
        icon="pi pi-search"
        placeholder="Pesquisar"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        onFocus={() => setShowOverlay(true)}
      />

      {showOverlay && (
        <div className="search-input-overlay">
          <ElevatedCard type={'white'} stretch noMargin>
            <div className="search-input-overlay-content">
              {renderOverlay()}
            </div>
          </ElevatedCard>
        </div>
      )}
    </div>
  );
};

export default SearchProfissionais;
