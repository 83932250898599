import Http, { HttpParams } from 'src/core/http/Http';

import { Paginator } from 'src/components/Table/Table';

const basePath = '/pontuacao';

export const getPontuacao = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetPontuacaoCarteiraDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getPontuacaoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPontuacaoCarteiraDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const postPontuacao = (
  data: PostPontuacaoCarteiraDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data,
    return: true,
    ...options,
  });
};

export const putPontuacao = (
  id: number,
  data: PostPontuacaoCarteiraDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data,
    return: true,
    ...options,
  });
};

export const putPontuacaoStatus = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/atualizar-status/${id}`,
    return: true,
    ...options,
  });
};

export const deletePontuacao = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPontuacaoCarteiraDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

export const getPontuacaoByConsultorio = (
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa-por-consultorio`,
    params: { idConsultorio },
    hideToast: true,
    ...options,
  });
};

export const PontuacaoCarteiraAPI = {
  getPontuacao,
  getPontuacaoById,
  postPontuacao,
  putPontuacao,
  deletePontuacao,
  putPontuacaoStatus,
  getPontuacaoByConsultorio,
};
