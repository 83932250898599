import { useState, useEffect } from 'react';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import DadosPessoais from './DadosPessoais/DadosPessoais';
import DadosProfissionais from './DadosProfissionais/DadosProfissionais';
import Endereco from './Endereco/Endereco';

const formatData = (data: any, label: string, value: string) => {
  if (!Array.isArray(data) || !data?.length) return [];

  const dataFormatted = data.map(item => ({
    label: item[label],
    value: item[value]
  }));

  return dataFormatted
}

interface DadosOpcionaisProps {
  camposDemograficosObrigatorios: boolean;
}

const DadosOpcionais = ({
  camposDemograficosObrigatorios
}: DadosOpcionaisProps) => {
  const [estadosOptions, setEstadosOptions] = useState<any>([]);
  const [ufOptions, setUfOptions] = useState<any>([]);

  const loadEstadosData = async () => {
    const response = await UtilsAPI.GetEstados();

    setEstadosOptions(formatData(response, 'nome', 'id'));
    setUfOptions(formatData(response, 'sigla', 'id'));
  }

  useEffect(() => {
    loadEstadosData();
  }, []);

  return (
    <VerticalTab
      tabItens={[
        {
          label: 'Dados pessoais e documentos',
          content: (
            <DadosPessoais
              camposDemograficosObrigatorios={camposDemograficosObrigatorios}
              estadosOptions={estadosOptions}
              ufOptions={ufOptions}
            />
          )
        },
        {
          label: 'Endereço',
          content: (
            <Endereco
              camposDemograficosObrigatorios={camposDemograficosObrigatorios}
              estadosOptions={estadosOptions}
            />
          )
        },
        {
          label: 'Dados profissionais',
          content: <DadosProfissionais />
        },
      ]}
    />
  );
}

export default DadosOpcionais;