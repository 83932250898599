import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  email: any;
  onVisualizarEmail: (email: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { email, onVisualizarEmail } = props;

  return (
    <div className="p-grid p-col-12 p-px-0 p-d-flex p-ai-center">
      <div className="p-col-1 p-lg-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          {email?.dataEnvio
            ? dayjs(new Date(email?.dataEnvio)).format('DD/MM/YYYY HH:mm:ss')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-3 p-lg-2 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.nomeBeneficiario ? email?.nomeBeneficiario : '-'}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          {email?.cartaoBeneficiario ? email?.cartaoBeneficiario : ''}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.nomeProfissionalSaude ? email?.nomeProfissionalSaude : '-'}
        </SimpleText>
      </div>
      <div className="p-col-3 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.emailDestinatario ? email?.emailDestinatario : '-'}
        </SimpleText>
        <div className="p-d-flex p-ai-center">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            {email?.tituloEmail ? email?.tituloEmail : '-'} {' •'}
          </SimpleText>
          <IconButton
            btnType={IconBtnTypes.ICON_ONLY}
            icon="fas fa-paperclip"
            onClick={() => onVisualizarEmail(email)}
          />
        </div>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.tipoDocumento
            ? capitalizeFirstLetter(email?.tipoDocumento).replaceAll('_', ' ')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-1">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {email?.status ? capitalizeFirstLetter(email?.status) : '-'}
        </SimpleText>
      </div>
    </div>
  );
};
