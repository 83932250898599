import Http, { HttpParams } from 'src/core/http/Http';

export const getPressao = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `pressao-arterial/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesPressao = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'pressao-arterial/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarPressao = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'pressao-arterial',
    data: payload,
    return: true,
    ...options,
  });

export const alterarPressao = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `pressao-arterial/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarPressao = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `pressao-arterial/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerPressao = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `pressao-arterial/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const PressaoAPI = {
  getPressao,
  getUnidadesPressao,
  adicionarPressao,
  alterarPressao,
  inativarPressao,
  removerPressao,
};

export default PressaoAPI;
