import { useFormContext } from 'react-hook-form';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const HiHb = () => {
  const { control } = useFormContext();

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
        Hb
      </SimpleText>

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hbPrimeiroTrimestre"
        label="1º Trimestre"
        maxLength={30}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hbTerceiroTrimestre"
        label="3º Trimestre"
        maxLength={30}
      />

      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60} medium>
        HI
      </SimpleText>

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hiPrimeiroTrimestre"
        label="1º Trimestre"
        maxLength={30}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="hiTerceiroTrimestre"
        label="3º Trimestre"
        maxLength={30}
      />
    </>
  );
};

export default HiHb;
