import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/sessoes';

const checkSessoes = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'sessao',
    url: basePath,
    data: payload,
    hideToast: true,
    throwError: true,
    ...options,
  });

const removeSessoes = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'sessao',
    url: basePath,
    data: payload,
    throwError: true,
    hideToast: true,
    ...options,
  });

export const SessaoAPI = {
  checkSessoes,
  removeSessoes,
};
