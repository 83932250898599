import React from 'react';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

interface BuscaRapidaContentProps {
  loadingFetch: boolean;
  typeFilter: any[];
  listaProcedimentosFilteredBySearchQuery: any[];
  children: JSX.Element;
}

export const BuscaRapidaContent = (props: BuscaRapidaContentProps) => {
  const {
    loadingFetch,
    typeFilter,
    listaProcedimentosFilteredBySearchQuery,
    children,
  } = props;

  if (loadingFetch) {
    return <GridListLoading />;
  }

  if (!typeFilter.length) {
    return (
      <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
        Selecione os tipos da busca rápida acima.
      </SimpleText>
    );
  }

  if (!listaProcedimentosFilteredBySearchQuery.length) {
    return (
      <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
        Nenhum resultado encontrado.
      </SimpleText>
    );
  }

  return children;
};
