import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import RecuperarSenhaAPI from 'src/APIs/SecurityAPI/RecuperarSenhaAPI/RecuperarSenhaAPI';

import useSize from 'src/core/hooks/useSize';

import { validateEmail } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import LoginLayout from 'src/components/LoginLayout/LoginLayout';

import validationSchema from './validationSchema';

import './RecuperacaoSenha.scss';

export const RecuperacaoSenha = () => {
  const [supportVisible, setSupportVisible] = useState(false);

  const { isMobile } = useSize();
  const navigate = useNavigate();

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormMethods;

  const onClickBackLogin = () => {
    navigate('/login');
  };

  const onClickNextToRecoverPass = async (data: PostRecuperarSenha) => {
    try {
      const isEmail = validateEmail(data.login);

      const payload = {
        ...data,
        email: isEmail ? data.login : undefined,
        login: !isEmail ? data.login : undefined,
      };

      await RecuperarSenhaAPI.sendRecuperarSenha(payload, {
        throwError: true,
      });

      navigate(`/recover-password/confirmar`, {
        state: {
          login: data.login,
          codigoEmpresa: data?.codigoEmpresa,
        },
      });
    } catch {}
  };

  const renderEmailRecoverPasswordForm = () => {
    return (
      <div
        style={{
          paddingInline: isMobile ? '20px' : '20%',
        }}
        className="p-d-flex p-flex-column p-gap-3 p-ai-start"
      >
        <Button
          label="Cancelar e voltar ao login"
          icon="fas fa-arrow-left"
          btnType="green-link"
          onClick={onClickBackLogin}
        />

        <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize={FONT_SIZE.LG} medium>
            Recuperação do acesso
          </SimpleText>
          <SimpleText fontColor="color_40">
            Por segurança, informe seu e-mail para continuar.
          </SimpleText>
        </div>

        <FormProvider {...useFormMethods}>
          <form
            onSubmit={handleSubmit((data: any) =>
              onClickNextToRecoverPass(data),
            )}
            className="p-grid"
          >
            <FormInput
              name="login"
              label="E-mail / Login"
              placeholder="E-mail ou login cadastrado"
              required
              className="p-col-12"
            />
            <FormInput
              name="codigoEmpresa"
              label="Código da Empresa"
              placeholder="Código da Empresa"
              className="p-col-12"
            />

            <div className="p-col-12">
              <Button
                type="submit"
                label="Próximo"
                loading={isSubmitting}
                stretch
              />
            </div>
          </form>
        </FormProvider>

        <Button
          onClick={() => {
            setSupportVisible(true);
          }}
          label="Solicitar Suporte"
          btnType="green-link"
          className="bnt-esqueceu-senha"
        />
      </div>
    );
  };

  return (
    <LoginLayout
      content={renderEmailRecoverPasswordForm()}
      supportVisible={supportVisible}
      onHideSupport={() => {
        setSupportVisible(false);
      }}
      maxWidth={700}
    />
  );
};
