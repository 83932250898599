import { useState } from 'react';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';
import { PerguntaFormularioDinamicoAPI } from 'src/APIs/AdminAPI/PerguntaFormularioAPI/PerguntaFormularioAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Dialog from 'src/components/Dialog/Dialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DialogAcervoPerguntasAdicionarRow } from './DialogAcervoPerguntasAdicionarRow';

type Props = DisclosureType<{ idForm: number }> & {
  onSuccess?: () => void;
};

export const DialogAcervoPerguntasAdicionar = ({
  close,
  state,
  onSuccess,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemsChecked, setItemsChecked] = useState<number[]>([]);

  const idForm = state!.idForm;

  const fetchApi = ({ query, ...props }: any) => {
    return PerguntaFormularioDinamicoAPI.loadPerguntasFormulario({
      enunciado: query,
      acervoPerguntas: true,
      ...props,
    });
  };

  const onCheck = (item: IPerguntaFormularioItem) => {
    setItemsChecked(prev => [...prev, item.id]);
  };
  const onUncheck = (id: number) => {
    setItemsChecked(prev => prev.filter(itemId => id !== itemId));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await FormularioDinamicoAPI.insertFormularioDinamicoPerguntaAcervo(
        {
          idFormulario: idForm,
          idsPergunta: itemsChecked,
        },
        { throwError: true },
      );
      onSuccess?.();
      close();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      visible
      onHide={close}
      header="Adicionar do acervo de perguntas"
      style={{
        width: '953px',
      }}
    >
      <div className="p-w-100 p-d-flex p-flex-wrap">
        <SearchList.Root fetchApi={fetchApi}>
          <>
            <div className="p-d-flex p-flex-wrap p-ai-center p-w-100 p-mb-2">
              <SearchList.SearchInput
                className="p-col-12 p-sm-5 p-lg-6"
                placeholder="Pesquisar pelo nome da pergunta"
              />
            </div>

            <SearchList.BorderContainer className="p-col-12 row content-row-outline">
              <SearchList.InfiniteScroll
                autoHeight={false}
                height={400}
                renderRow={(item: IPerguntaFormularioItem) => (
                  <DialogAcervoPerguntasAdicionarRow
                    onUncheck={onUncheck}
                    onCheck={onCheck}
                    isChecked={itemsChecked.some(itemId => itemId === item.id)}
                    item={item}
                  />
                )}
              />
            </SearchList.BorderContainer>

            <div className="p-col-12 p-d-flex p-gap-2">
              <Button
                label="Cancelar"
                btnType="ghost"
                onClick={() => close()}
                disabled={isLoading}
                stretch
              />
              <Button
                label="Adicionar"
                loading={isLoading}
                onClick={() => handleSubmit()}
                stretch
              />
            </div>
          </>
        </SearchList.Root>
      </div>
    </Dialog>
  );
};
