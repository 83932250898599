import { useForm } from 'react-hook-form';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalParecer.scss';

export const ModalParecer = (props: any) => {
  const {
    isOpen,
    close,
    state: {
      parecer,
      idAtendimento,
      idProcedimento,
      isProcedimentoExterno,
      onSuccess,
    },
  } = props;

  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      parecer,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const putParecer = isProcedimentoExterno
        ? SolicitacoesProcedimentosExternosAPI.putParecer
        : SolicitacoesProcedimentosAPI.putParecer;

      await putParecer(idAtendimento, idProcedimento, data);

      onSuccess(data.parecer);

      close();
    } catch (error) {}
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        onClick={close}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={!isDirty}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-parecer"
      header="Resultado do exame - Parecer Profissional"
      visible={isOpen}
      onHide={close}
      type="modal"
      modal={false}
      footer={renderFooter}
      maximizedMobileSize
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-mb-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            Você está informando um parecer sobre o resultado do exame.
          </SimpleText>
        </div>

        <TextareaInputControlled
          control={control}
          name="parecer"
          label="Parecer"
          maxLength={1000}
        />
      </form>
    </Dialog>
  );
};
