import * as Yup from 'yup';

import { FormularioPerguntasItem } from './FormulariosContent';

export const createValidationSchema = (
  perguntas: FormularioPerguntasItem[],
) => {
  const validationSchema: { [key: string]: any } = {};

  perguntas.forEach(pergunta => {
    let validator;

    switch (pergunta.tipoResposta) {
      case 'TEXTO':
        validator = Yup.string().nullable();
        break;
      case 'NUMERO':
        validator = Yup.number();

        if (pergunta.valorMinimo && pergunta.valorMaximo) {
          validator = Yup.number()
            .typeError('Insira um valor numérico')
            .min(pergunta.valorMinimo, `Valor mínimo: ${pergunta.valorMinimo}`)
            .max(pergunta.valorMaximo, `Valor máximo: ${pergunta.valorMaximo}`);
        }
        break;
      case 'ESCOLHA':
        validator = Yup.mixed();
        break;
      case 'MULTIPLA_ESCOLHA':
        validator = Yup.array().test(
          'Min length',
          `Selecione ao menos ${pergunta.quantidadeOpcoesObrigatorias} opções`,
          function (value = []) {
            const values = value.filter(Boolean);
            const { parent } = this;

            if (!pergunta.quantidadeOpcoesObrigatorias || !pergunta.obrigatorio)
              return true;

            if (pergunta.idPerguntaVinculada) {
              const valuePerguntaVinculada: any = Object.entries(parent).find(
                arr => arr[0]?.includes(String(pergunta.idPerguntaVinculada)),
              )?.[1];

              const isPerguntaVisible =
                pergunta.idPerguntaVinculada &&
                pergunta.idOpcaoPerguntaVinculada &&
                valuePerguntaVinculada?.id ===
                  pergunta.idOpcaoPerguntaVinculada;

              if (!isPerguntaVisible) return true;
            }

            if (!values.length) return false;
            if (values.length >= pergunta.quantidadeOpcoesObrigatorias)
              return true;

            return false;
          },
        );
        break;
      case 'DATA':
        validator = Yup.date();
        break;
      default:
        validator = Yup.mixed();
    }

    //* Condition the validation based on the visibility of the question
    if (pergunta.obrigatorio && pergunta.tipoResposta !== 'MULTIPLA_ESCOLHA') {
      validator = validator.test(
        'required-if-visible',
        'Campo obrigatório',
        function (value: any) {
          const { parent } = this;

          // If there is no link with another question, proceed with the default validation
          if (!pergunta.idPerguntaVinculada) {
            return value !== undefined && value !== null && value !== '';
          }

          const valuePerguntaVinculada: any = Object.entries(parent).find(arr =>
            arr[0]?.includes(String(pergunta.idPerguntaVinculada)),
          )?.[1];

          const isPerguntaVisible =
            pergunta.idPerguntaVinculada &&
            pergunta.idOpcaoPerguntaVinculada &&
            valuePerguntaVinculada?.id === pergunta.idOpcaoPerguntaVinculada;

          if (!isPerguntaVisible) return true;

          return value !== undefined && value !== null && value !== '';
        },
      );
    }

    validationSchema[`${pergunta.idPerguntaFormularioDinamico}`] = validator;
  });

  return Yup.object().shape({
    respostas: Yup.object().shape(validationSchema),
  });
};

export const convertFormDataToSubmit = (formData: any) => {
  const respostas = [];

  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const value = formData[key]; // Get the value associated with the key.

      //* Check if the value is an object with an 'id' property. * "ESCOLHA"
      if (typeof value === 'object' && value !== null && !!value.id) {
        respostas.push({
          idPerguntaFormularioDinamico: parseInt(key),
          idResposta: value.id, // Use the 'id' property of the value.
          textoResposta: null,
        });
      }
      //* Check if the value is a non-empty array. * "MULTIPLA_ESCOLHA"
      else if (Array.isArray(value)) {
        if (value.length === 0) continue;
        value.forEach(
          (value: { id: string; value: boolean }) =>
            value &&
            respostas.push({
              idPerguntaFormularioDinamico: parseInt(key),
              idResposta: value.id, // Use the index of the array as idResposta.
              textoResposta: null,
            }),
        );
      }
      //* Check if the value is a date object. * "DATA"
      else if (
        typeof value === 'object' &&
        !isNaN(Date.parse(value.toString()))
      ) {
        respostas.push({
          idPerguntaFormularioDinamico: parseInt(key),
          idResposta: null,
          textoResposta: value, // Use the date value as textoResposta.
        });
      }
      //* For all other types of values.
      else {
        if (key.includes('_adicional')) {
          respostas.push({
            idPerguntaFormularioDinamico: parseInt(key),
            idResposta: null,
            textoResposta: value?.split('_')?.[0],
          });
        } else if (key.includes('_comentario')) {
          respostas.push({
            idPerguntaFormularioDinamico: parseInt(key),
            idResposta: null,
            textoResposta: null,
            comentarioResposta: value?.split('_')?.[0],
          });
        } else {
          respostas.push({
            idPerguntaFormularioDinamico: parseInt(key),
            idResposta: null,
            textoResposta: value,
          });
        }
      }
    }
  }

  return respostas;
};

export const convertResponsesToInitialValues = (data: any) => {
  const respostas: any = {};

  //* Iterates over each key of the response object
  Object.keys(data).forEach(key => {
    const item = data[key];

    if (!item) return;

    const tipoResposta = item?.perguntaFormularioDinamico.tipoResposta;

    switch (tipoResposta) {
      case 'DATA':
        respostas[item.perguntaFormularioDinamico.id] = item.resposta[0]
          ?.textoResposta
          ? new Date(item.resposta[0].textoResposta)
          : null;
        break;

      case 'TEXTO':
        respostas[item.perguntaFormularioDinamico.id] = item.resposta[0]
          ?.textoResposta
          ? item.resposta[0].textoResposta
          : null;
        break;

      case 'NUMERO':
        respostas[item.perguntaFormularioDinamico.id] = item.resposta[0]
          ?.textoResposta
          ? Number(item.resposta[0].textoResposta)
          : null;
        break;

      case 'ESCOLHA':
        // For "ESCOLHA", returns an object with the description and other details
        respostas[item.perguntaFormularioDinamico.id] = {
          descricao: item.resposta[0]!.descricao,
          id: item.resposta[0]!.id,
          idPerguntaFormularioResposta:
            item.resposta[0]!.idPerguntaFormularioResposta,
          pontuacao: item.resposta[0]!.pontuacao,
        };
        break;

      case 'MULTIPLA_ESCOLHA':
        // For "MULTIPLA_ESCOLHA", returns an array with the selected responses
        respostas[item.perguntaFormularioDinamico.id] =
          item.perguntaFormularioDinamico?.opcoes?.reduce(
            (
              acc: { id: number; value: boolean }[],
              opcao: IPerguntaFormularioItemOpcoes,
            ) => {
              if (
                item.resposta?.some((r: { id: number }) => r.id === opcao.id)
              ) {
                acc.push({ id: Number(opcao.id), value: true });
              }
              return acc;
            },
            [],
          );
        break;

      default:
        break;
    }
  });

  return respostas;
};
