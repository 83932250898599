import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldProps = DropdownProps & {
  name?: string;
  label?: string;
  customOnChange?: (v: any) => void;
};

export const FieldSearchCliente = ({
  name = 'cliente',
  label,
  ...rest
}: FieldProps) => {
  const [item, setItem] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const setSearchQuery = useThrottle(setQuery, 200);
  const { control, watch } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async (query = '') => {
    try {
      setIsLoading(true);
      const res = await EmpresasAPI.GetAll({ nome: query });

      !!res?.list?.length && setItem(res.list);
    } catch {
      setItem([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    load(query);
  }, [load, query]);

  const valueAtual = watch(name);

  const options = useMemo(() => {
    const data = item.map(via => ({
      label: via.razaoSocial,
      value: via,
    }));

    // valida se convênio atual está listado nas options
    if (valueAtual) {
      const isConvenioAtualVisivel = item.find(
        conv => conv.id === valueAtual.id,
      );

      if (!isConvenioAtualVisivel) {
        data.unshift({
          label: valueAtual.razaoSocial,
          value: valueAtual,
        });
      }
    }

    return data;
  }, [item, valueAtual]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      onFilter={({ filter }) => {
        setIsLoading(true);
        setSearchQuery(filter);
      }}
      loading={isLoading}
      options={options}
      errorMsg={error?.message}
      showClear
      {...rest}
    />
  );
};
