import { useCallback, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import ServicosTussVinculadosAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ServicosTussVinculadosAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { FieldSelectProfissionalSaudeByConsultorio } from 'src/components/Fields/FieldSelectProfissionalSaudeByConsultorio/FieldSelectProfissionalSaudeByConsultorio';
import { FieldServicoTuss } from 'src/components/Fields/FieldServicoTuss/FieldServicoTuss';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

import Row from './Row';

interface Props {
  idConsultorio: string | number;
  profissionaisSaudeList: any[];
}

const FormServicosTussVinculados = ({
  idConsultorio,
  profissionaisSaudeList,
}: Props) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [servicosVinculados, setServicosVinculados] = useState<any[]>([]);
  const [reload, setReload] = useState(0);

  const useFormMethods = useForm<any>({});

  const { watch, setValue } = useFormMethods;

  const watchConvenio = watch('convenio');
  const watchServicoTuss = watch('servicoTuss');
  const watchProfissional = watch('profissional');

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  const isDisabled = useMemo(() => {
    if (!watchConvenio || !idConsultorio) return true;
    if (user?.tipoUsuario !== 'PROFISSIONAL_SAUDE' && !watchProfissional)
      return true;
    return false;
  }, [idConsultorio, user?.tipoUsuario, watchConvenio, watchProfissional]);

  const addServicosVinculados = useCallback(async () => {
    const payload = {
      idMedico: watchProfissional,
      idConvenio: watchConvenio?.id,
      servicosTuss: [{ idServicoTuss: watchServicoTuss?.id }],
    };

    try {
      await ServicosTussVinculadosAPI.adicionarServicosVinculados(
        String(idConsultorio),
        payload,
      );
      setValue('servicoTuss', null);
      return reloadList();
    } catch (error) {
      console.error(error);
    }
  }, [
    idConsultorio,
    reloadList,
    setValue,
    watchConvenio?.id,
    watchProfissional,
    watchServicoTuss?.id,
  ]);

  const removeServicosVinculados = useCallback(
    async (idServico: string) => {
      if (!idConsultorio) return;

      try {
        await ServicosTussVinculadosAPI.deleteServicosVinculados(
          String(idConsultorio),
          idServico,
        );
        reloadList();
      } catch (error) {
        console.error(error);
      }
    },
    [idConsultorio, reloadList],
  );

  const disableAdd = useMemo(() => {
    return (
      !watchConvenio ||
      !watchServicoTuss ||
      servicosVinculados?.some(servico => servico.id === watchServicoTuss.id)
    );
  }, [watchConvenio, watchServicoTuss, servicosVinculados]);

  const fetchApi = async ({
    query,
    ...params
  }: {
    query: string;
    [key: string]: any;
  }) => {
    try {
      const response = await ServicosTussVinculadosAPI.getServicosVinculados(
        String(idConsultorio),
        {
          filtro: query,
          ...params,
        },
        { throwError: true },
      );

      setServicosVinculados(response);
      return response;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const renderRow = (data: any): JSX.Element => {
    return (
      <Row removeServicosVinculados={removeServicosVinculados} data={data} />
    );
  };

  return (
    <SearchList.Root fetchApi={fetchApi}>
      <FormProvider {...useFormMethods}>
        <form className="p-grid  p-flex-column">
          <div className="p-col-12 p-md-10 p-lg-9 p-xl-6">
            <div className="p-d-flex p-flex-column p-gap-2">
              <SimpleText className="p-mb-3" medium>
                Adicionar Serviços TUSS
              </SimpleText>
              <SimpleText
                className="p-mb-3"
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Selecione um convênio e profissional de saúde para ver abaixo os
                serviços TUSS vinculados.
              </SimpleText>
            </div>

            <SearchFields
              idConsultorio={idConsultorio}
              profissionaisSaudeList={profissionaisSaudeList}
            />

            <SimpleText
              className="p-mb-4"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Para adicionar um serviço a lista de serviços vinculados,
              selecione e clique em adicionar.
            </SimpleText>

            <FieldServicoTuss
              name="servicoTuss"
              label="Serviço TUSS"
              placeholder="Selecione"
              returnType="all"
              className="p-w-100 p-my-2"
              params={useMemo(
                () => ({ idConvenio: watchConvenio?.id, status: true }),
                [watchConvenio],
              )}
              disabled={isDisabled}
              showCodigo
            />

            <Button
              type="button"
              label="Adicionar serviço"
              btnType="tonal"
              stretch
              className="p-mt-2"
              disabled={disableAdd}
              icon="pi pi-plus"
              iconPos="left"
              onClick={() => addServicosVinculados()}
            />
          </div>
          <div className="p-col-12">
            <Separator />
            <SimpleText className="p-mb-3" medium>
              Serviços TUSS vinculados
            </SimpleText>
            <SearchList.SearchInput
              placeholder="Pesquisar serviços"
              className="p-w-100 p-my-2"
            />

            <div className="p-my-4">
              <SearchList.NonPageableScrollArea
                reload={reload}
                renderRow={renderRow}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </SearchList.Root>
  );
};

interface Props {
  idConsultorio: string | number;
  profissionaisSaudeList: any[];
}

const SearchFields = ({ idConsultorio, profissionaisSaudeList }: Props) => {
  const { user } = useAppSelector((state: RootState) => state);

  return (
    <>
      <FieldConvenio name="convenio" label="Convênio" className="p-my-2" />

      {user?.tipoUsuario !== 'PROFISSIONAL_SAUDE' && (
        <FieldSelectProfissionalSaudeByConsultorio
          idConsultorio={idConsultorio}
          profissionaisSaudeList={profissionaisSaudeList}
        />
      )}
    </>
  );
};

export default FormServicosTussVinculados;
