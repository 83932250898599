import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import { PontuacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/PontuacaoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import { Row } from './PontuacaoCarteiraGrid/Row';

import './PontuacaoCarteira.scss';

const PontuacaoCarteira = () => {
  const navigate = useNavigate();
  const modalDeleteDisclosure = useDisclosure({ opened: false });
  const [reload, setReload] = useState<number>(0);

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const onDelete = async (id: number) => {
    modalDeleteDisclosure.open({ state: { id: id } });
  };

  const onConfirmDelete = useCallback(async () => {
    const response = await PontuacaoCarteiraAPI.deletePontuacao(
      modalDeleteDisclosure.state.id,
    );

    if (response && 'status' in response) {
      return;
    }
    reloadList();
    modalDeleteDisclosure.close();
  }, [modalDeleteDisclosure]);

  const renderRow = (pontuacao: GetPontuacaoCarteiraDTO) => (
    <Row key={generateUniqueId()} pontuacao={pontuacao} onDelete={onDelete} />
  );

  const fetchAPI = ({ query, ...rest }: any) => {
    return PontuacaoCarteiraAPI.getPontuacao({
      nome: query,
      sortBy: 'nome',
      sortDirection: 'ASC',
      ...rest,
    });
  };

  return (
    <div id="pontuacao-carteira-container" className="p-py-3">
      <div className="p-px-4">
        <SearchList.Root
          fetchApi={fetchAPI}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.SearchInput
            label="Pesquisar grupo"
            placeholder="Pesquise pelo nome do grupo de pontuação"
          />

          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
              <Button
                className="create-pontuacao-button p-mb-2 p-mb-sm-0"
                type="button"
                btnType="pill"
                label="Criar grupo de pontuação"
                onClick={() =>
                  navigate(`/pacientes/carteira-aps/pontuacao`, {
                    replace: true,
                    state: 'Pontuação carteira',
                  })
                }
                checkPermission="ADM_CARTEIRA_APS_PONTUACAO_CADASTRAR"
              />

              <SearchList.TotalCount />
            </div>

            <Header />

            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reload}
              customEmptyText="Nenhum registro foi encontrado. Por favor, tente adicionar um grupo de pontuação."
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>

      {modalDeleteDisclosure.isOpen && (
        <ConfirmDialog
          visible={modalDeleteDisclosure.isOpen}
          header="Excluir pontuação carteira"
          text="Você confirma a exclusão da pontuação carteira?"
          confirmText="Sim, excluir"
          onHide={() => modalDeleteDisclosure.close()}
          onConfirm={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default PontuacaoCarteira;
