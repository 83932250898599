import { PanelHeaderTemplateOptions } from 'primereact/panel';

import Panel from 'src/components/Panel/Panel';

import './SelectablePanel.scss';

export const SelectablePanel = (props: any) => {
  const { className, isSelected, headerTemplate, children } = props;

  const selectableHeaderPanel = (options: PanelHeaderTemplateOptions) => (
    <div className="procedimentos-selecionados-header-panel">
      {headerTemplate(options)}
    </div>
  );

  const panelClassName = `procedimento-selecionado-panel ${
    isSelected ? 'panel-selected' : ''
  } ${className ? className : ''}`;

  return (
    <Panel
      className={panelClassName}
      headerTemplate={selectableHeaderPanel}
      toggleable
      collapsed
    >
      {children}
    </Panel>
  );
};
