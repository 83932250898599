import Http, { HttpParams } from 'src/core/http/Http';

export const getPinHSM = (
  idProfissional: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `profissional-saude/${idProfissional}/hsm`,
    return: true,
    hideToast: true,
    ...options,
  });

export const putPinHSM = (
  idProfissional: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `profissional-saude/${idProfissional}/hsm`,
    data: params,
    return: true,
    ...options,
  });

const assinarDocumentoHSM = async (
  documento: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: '/assinatura-eletronica/assinar',
    data: { documento: documento },
    hideToast: true,
    return: true,
    throwError: true,
    ...options,
  });
};

const verificarAssinatura = async (
  data: {
    documentoAssinado: string;
    sequenciaAssinatura: number;
    tipoDocumento: string;
  },

  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: '/assinatura-eletronica/verificar',
    data,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const AssinaturaHsmAPI = {
  getPinHSM,
  putPinHSM,
  assinarDocumentoHSM,
  verificarAssinatura,
};
