import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-d-flex p-my-1">
          <div className="p-col-3 p-md-4">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Nome
            </SimpleText>
          </div>
          <div className="p-col-2 p-md-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Login
            </SimpleText>
          </div>
          <div className="p-col-3 p-md-4">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Cliente
            </SimpleText>
          </div>
          <div className="p-col-2 p-md-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Qtd. de sessões
            </SimpleText>
          </div>

          <div className="p-col-2 p-md-1 p-d-flex p-jc-end">
            <Can checkPermission="ADM_USUARIOS_SIMULTANEOS_ALTERAR">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
                className="p-mr-1"
              >
                Ações
              </SimpleText>
            </Can>
          </div>
        </div>
      )}
    </>
  );
};
