import { useEffect, useState } from 'react';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import { ProfissionalSaude } from 'src/models/APIs/AdminAPI/ProfissionalSaude/ProfissionalSaudeDTO';
import { LoadPlanoCuidadoByIdProps } from 'src/models/APIs/ProntuarioAPI/PlanoCuidado/PlanoCuidado';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import { PlanoDeCuidadoFormConfigurar } from './FormConfigurar/PlanoDeCuidadoFormConfigurar';
import { PlanoDeCuidadoCreateFormSelecionar } from './FormSelecionar/PlanoDeCuidadoCreateFormSelecionar';

import './PlanoDeCuidadoCreate.scss';

export type SectionDataProps = {
  planoCuidado: LoadPlanoCuidadoByIdProps;
  profissionais: ProfissionalSaude[];
  id?: number;
  idAtendimentoOrigem?: number;
};

export const PlanoDeCuidadoCreate = ({
  close,
  isOpen,
  state,
}: DisclosureType) => {
  const items = state?.item;

  const [activeTab, setActiveTab] = useState(0);
  const [sectionData, setData] = useState<SectionDataProps | null>(items);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (items?.id) {
      setLoading(true);
      PlanoCuidadoPacienteAPI.loadPlanoCuidadoPacienteById(items?.id, {
        idAtendimento: items.idAtendimentoOrigem,
      })
        .then(response => setData(response))
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [items?.id, items?.idAtendimentoOrigem]);

  const isEditing = typeof items === 'object' && !!Object.keys(items).length;

  const title = isEditing
    ? 'Editar plano de cuidado'
    : 'Adicionar plano de cuidado';

  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      header={title}
      id="Plano-de-cuidado-create"
    >
      <Tab
        clean
        setActiveIndex={activeTab}
        onChange={e => setActiveTab(e)}
        values={[
          {
            label: 'Selecionar plano de cuidado',
            content: (
              <PlanoDeCuidadoCreateFormSelecionar
                isEditing={isEditing}
                close={close}
                onSubmit={(values: SectionDataProps) =>
                  setData(old => ({ ...old, ...values }))
                }
                setActiveTab={setActiveTab}
                items={sectionData}
                loadingData={loading}
              />
            ),
          },
          {
            label: 'Configurar atividades do plano',
            content: (
              <PlanoDeCuidadoFormConfigurar
                isEditing={isEditing}
                sectionData={sectionData!}
                close={close}
              />
            ),
            disabled: activeTab === 0,
          },
        ]}
      />
    </Dialog>
  );
};
