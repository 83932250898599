import { createContext } from 'react';

export interface Address {
  cep: string;
  logradouro: string;
  complemento: string;
  tipo: string;
  numero: string;
  bairro: string;
  idPais: number;
  idEstado?: number;
  idCidade?: number;
  descCidade?: string;
}

export interface Contact {
  numero: string,
  tipo: string;
}

export interface OptionalDataContextData {
  addressData: Address | null;
  contactData: Contact[] | null;
  saveAddress: (address: Address) => void;
  saveContact: (contact: Contact[]) => void;
}

export const OptionalDataContext = createContext(
  {} as OptionalDataContextData
);

