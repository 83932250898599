import Http, { HttpParams } from 'src/core/http/Http';

export const getCircunferenciaBraco = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `circunferencia-braco/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesCircunferenciaBraco = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'circunferencia-braco/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarCircunferenciaBraco = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'circunferencia-braco',
    data: payload,
    return: true,
    ...options,
  });

export const alterarCircunferenciaBraco = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-braco/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarCircunferenciaBraco = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `circunferencia-braco/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerCircunferenciaBraco = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `circunferencia-braco/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const CircunferenciaBracoAPI = {
  getCircunferenciaBraco,
  getUnidadesCircunferenciaBraco,
  adicionarCircunferenciaBraco,
  alterarCircunferenciaBraco,
  inativarCircunferenciaBraco,
  removerCircunferenciaBraco,
};

export default CircunferenciaBracoAPI;
