import { useState } from 'react';

import { formatCnpj } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Can from 'src/components/Can/Can';
import Switch from 'src/components/Switch/Switch';

interface HeaderProps {
  convenio: Convenio;
  onSwitchStatus: (idConvenio: number, currentState: boolean) => void;
}

export const Header = (props: HeaderProps) => {
  const { convenio, onSwitchStatus } = props;

  const [emailInstitucional, setEmailInstitucional] = useState(
    convenio?.emailInstitucional || '',
  );

  const handleSendEmail = () => {
    window.location.href = `mailto:${emailInstitucional}`;
  };

  return (
    <>
      <div className="p-d-flex p-jc-between">
        <div className="p-d-flex p-ai-center">
          <SimpleText
            className="convenio-codigo"
            fontSize={FONT_SIZE.MD}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {convenio?.id}
          </SimpleText>

          <div className="p-d-flex p-flex-column">
            <SimpleText
              className="line-height"
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {convenio?.nomeFantasia}
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              {convenio?.razaoSocial}{' '}
              {!!convenio?.cnpj ? (
                <>
                  <span className="p-mx-1">•</span>{' '}
                  {formatCnpj(convenio?.cnpj || '')}
                </>
              ) : null}
            </SimpleText>
          </div>
        </div>

        <div id="status-container">
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            Status
          </SimpleText>

          <div className="p-d-flex p-jc-between p-ai-center p-mt-2">
            <SimpleText
              className="p-mr-3"
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Caso seja desativado, os médicos perderão o acesso ao convênio.
            </SimpleText>

            <Can checkPermission="ADM_CONVENIO_ALTERAR">
              {can => (
                <Switch
                  checked={convenio.ativo}
                  onChange={() => onSwitchStatus(convenio.id, convenio.ativo)}
                  disabled={!can}
                />
              )}
            </Can>
          </div>
        </div>
      </div>

      <div className="p-inputgroup p-mt-3">
        <TextInput
          placeholder="email@institucional"
          value={emailInstitucional}
          onChange={e => setEmailInstitucional(e.target.value)}
        />
        <Button
          type="button"
          btnType="tonal"
          icon="fa-solid fa-envelope"
          label="Enviar"
          onClick={handleSendEmail}
          disabled={!emailInstitucional}
        />
      </div>
    </>
  );
};
