import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/especialidades';

export const loadEspecialidades = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetEspecialidadeDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadEspecialidadeById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetEspecialidadeDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendEspecialidade = (
  payload: PostEspecialidadeDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostEspecialidadeDTO> => {
  return Http.post({
    requestDescription: 'cadastrar uma nova especialidade',
    service: 'adm',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateEspecialidade = (
  id: number,
  payload: PostEspecialidadeDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostEspecialidadeDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateEspecialidadeStatus = (
  id: number,
  payload: PutEspecialidadeDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PutEspecialidadeDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/status/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeEspecialidadeById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetEspecialidadeDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

const EspecialidadeAPI = {
  loadEspecialidades,
  loadEspecialidadeById,
  sendEspecialidade,
  removeEspecialidadeById,
  updateEspecialidade,
  updateEspecialidadeStatus,
};

export default EspecialidadeAPI;
