import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { VariantPanel } from 'src/components/Panel/VariantPanel/VariantPanel';

export const ObservacaoForm = () => {
  const { control } = useFormContext();

  return (
    <VariantPanel title="Observação / Justificativa">
      <form className="p-grid">
        <TextareaInputControlled
          control={control}
          name="observacoesJustificativa"
          label="Observação / Justificativa"
          className="p-col-12"
          maxLength={500}
          style={{
            resize: 'none',
          }}
        />
      </form>
    </VariantPanel>
  );
};
