import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Menu } from 'primereact/menu';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSubscription } from 'react-stomp-hooks';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Formik } from 'formik';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import { ElegibilidadeAPI } from 'src/APIs/AgendaAPI/ElegibilidadeAPI';
import ListaEsperaAPI from 'src/APIs/AgendaAPI/ListaEsperaAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { getPacienteDateInfo } from '../utils';
import { tipoRecorrenciaLabel } from './utils';
import { dateAndHourToDate } from 'src/utils/date';
import { dateToHourMinutsString, dateToRequest } from 'src/utils/utils';

import { DialogErroComunicacao } from './components/DialogsTiss/DialogErroComunicacao';
import { DialogComunicacao } from './components/DialogsTiss/DialogLoadingComunicacao';
import { DialogPacienteNaoElegivel } from './components/DialogsTiss/DialogPacienteNaoElegivel';
import { ConvenioSelectForm } from './components/Form/ConvenioSelectForm';
import ModalEncaixeRecorrente from './components/Modais/ModalEncaixeRecorrente';
import ModalExcluirRecorrencia from './components/Modais/ModalExcluirRecorrencia';
import ModalOutros from './components/Modais/ModalOutros';
import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import { RadioButton } from 'src/components/_UI/RadioButton';
import AlertBox from 'src/components/AlertBox/AlertBox';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Avatar from 'src/components/Avatar/Avatar';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import IconButton from 'src/components/Basics/IconButton/IconButton';
import MaskedInput from 'src/components/Basics/MaskedInput/MaskedInput';
import Page from 'src/components/Basics/Page/Page';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import Form, { FIELD } from 'src/components/Form/Form';
import BasicPanel, {
  BasicPanelRef,
} from 'src/components/Panel/BasicPanel/BasicPanel';
import SearchPaciente from 'src/components/SearchPaciente/SearchPaciente';
import Separator from 'src/components/Separator/Separator';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

import EscolhaHorarioLivre from '../MenuAgenda/EscolhaHorarioLivre/EscolhaHorarioLivre';

import FaturamentoAgendamento from './FaturamentoAgendamento';
import {
  NovoAgendamentoProvider,
  useNovoAgendamento,
} from './hooks/NovoAgendamentoContext';
import { NovoAgendamentoResumo } from './NovoAgendamentoResumo';

import './NovoAgendamento.scss';

const NovoAgendamento = () => {
  return (
    <NovoAgendamentoProvider>
      <NovoAgendamentoComp />
    </NovoAgendamentoProvider>
  );
};

const NovoAgendamentoComp = () => {
  const { agenda, consultorios } = useAppSelector((state: RootState) => state);
  const {
    setPaciente: handleSetPaciente,
    planoSelected,
    setTimeOutElegibilidade,
    isTimerRunning,
    isSubmitting,
    setIsSubmitting,
    handleElegibilidadeObservacao,
    elegibilidadeObservacao,
    profissionalAtivo,
    consultorioAtivo,
    servicoTussSelected,
    step1Ref,
    step2Ref,
    step3Ref,
    step4Ref,
    modalOutrosDisclosure,
    agendamentoRapidoNome,
    setAgendamentoRapidoNome,
  } = useNovoAgendamento();
  const navigate = useNavigate();
  const {
    screen = 'Agendamento',
    agendamento,
    date,
    pacienteEspera,
    editAgendamento,
    voltarParaAgenda = 'AGENDA_COMPLETA',
  } = useLocation().state || {};

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'age_agendar_paciente',
  });

  const [paciente, setPaciente] = useState<GetPacienteDTO>();
  const [idListaEspera, setIdListaEspera] = useState<number>(
    pacienteEspera?.idListaEspera,
  );
  const [internalDateTime, setInternalDateTime] = useState<Date | undefined>(
    new Date(date),
  );
  const [ultimosAtendimentos, setUltimosAtendimentos] =
    useState<UltimosAgendamentosPaciente | null>();
  const [loadingData, setLoadingData] = useState(false);

  const [contato, setContato] = useState<string>('');
  const [observacao, setObservacao] = useState<string>(
    editAgendamento?.observacao || agendamento?.observacao || '',
  );
  const [enviarSMS, setEnviarSMS] = useState<boolean>(false);
  const [recorrencia, setRecorrencia] = useState<boolean>(false);

  const [guiaTiss, setGuiaTiss] = useState(false);

  const [selectedGrupo, setSelectedGrupo] = useState<GrupoAtendimentoDTO>();

  const [exibirModalSairSemSalvar, setExibirModalSairSemSalvar] =
    useState(false);

  const [diaSemana, setDiaSemana] = useState<string | null>(null);
  const [semanaMes, setSemanaMes] = useState<string | null>(null);
  const [tipoMensal, setTipoMensal] = useState<string | null>(null);
  const [encaixeHorario, setEncaixeHorario] = useState<boolean>(false);
  const [horario, setHorario] = useState<any>(new Date());
  const [horariosIndisponiveis, setHorariosIndisponiveis] = useState<any>(null);
  const [dataRecorrencia, setDataRecorrencia] = useState<any>(null);
  const [step4InitialValue, setStep4InitalValues] = useState();
  const exibirModalDisclosure = useDisclosure({ opened: false });

  const validatioSchema = Yup.object().shape({
    dataInicio: Yup.date().required('Informe a data de ínicio'),
    dataFim: Yup.date().required('Informe a data de término'),
    horario: Yup.string().required('Informe o horário'),
    intervalo: Yup.string().required('Informe o intervalo'),
    diaSemana: Yup.string().when('tipoRecorrencia', {
      is: 'SEMANAL',
      then: Yup.string().required('Informe o dia da semana'),
    }),
    semanaMes: Yup.string().when(String(tipoMensal), {
      is: 'DIA_DA_SEMANA',
      then: Yup.string().required('Informe a semana do mês'),
    }),
    diaEspecifico: Yup.string().when(String(tipoMensal), {
      is: 'DIA_ESPECIFICO',
      then: Yup.string().required('Informe o dia específico'),
    }),
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  dayjs.extend(customParseFormat);

  const optionsPaciente = useRef<any>();
  const painelRef = useRef<BasicPanelRef>(null);
  const formRef = useRef<any>();

  //* TISS
  const dialogTissErroOperadora = useDisclosure({ opened: false });
  const dialogTissPacienteNaoElegivel = useDisclosure({ opened: false });
  const dialogComunicacaoTiss = useDisclosure({ opened: false });

  const modalEncaixeDisclosure = useDisclosure({ opened: false });

  const values = formRef.current?.getValues();

  useEffect(() => {
    if (editAgendamento || screen === 'Encaixe') {
      step1Ref.current?.show();
      step2Ref.current?.show();
      step3Ref.current?.show();
      step4Ref.current?.show();
    }
  }, [editAgendamento, screen, step1Ref, step2Ref, step3Ref, step4Ref]);

  const hasToVerifyElegibilidade: boolean = useMemo(() => {
    const formAux = formRef?.current?.getValues();

    if (
      agenda?.profissionalAtivo?.profissionalSaude?.naoRealizaTransacaoTiss ===
      true
    ) {
      return false;
    }

    const isTissUsed = planoSelected?.utilizaTiss;
    const isNotReturn = !values?.tags?.includes('RETORNO');

    return isTissUsed && isNotReturn;
  }, [
    agenda?.profissionalAtivo?.profissionalSaude?.naoRealizaTransacaoTiss,
    planoSelected?.utilizaTiss,
    values?.tags,
  ]);

  const watchTipoRecorrencia = watch('tipoRecorrencia');
  const watchRecorrenciaDias = watch('intervalo');
  const watchHorarioRecorrencia = watch('horario');

  useEffect(() => {
    if (pacienteEspera) {
      setPaciente(pacienteEspera.paciente);
      setIdListaEspera(pacienteEspera.idListaEspera);

      handleSetPaciente(pacienteEspera.paciente);
    }
  }, [handleSetPaciente, pacienteEspera]);

  useEffectSkipFirst(() => {
    if (!paciente || !consultorios.ativo) {
      return;
    }

    const getDatasUltimosAtendimentos = async () => {
      try {
        setLoadingData(true);
        const response = await AgendamentosAPI.getUltimosAtendimentosPaciente(
          paciente.id,
          {
            idConsultorio: consultorios.ativo.id,
            idProfissionalSaude: agenda.profissionalAtivo?.id,
          },
        );
        setUltimosAtendimentos(response);
      } catch (error) {
        setUltimosAtendimentos(null);
      } finally {
        return setLoadingData(false);
      }
    };

    getDatasUltimosAtendimentos();
  }, [paciente, consultorios.ativo]);

  useEffect(() => {
    if (editAgendamento) {
      setAgendamentoRapidoNome(editAgendamento.agendamentoRapidoNome);
      setPaciente(editAgendamento.paciente);
      handleSetPaciente(editAgendamento.paciente);

      setValue(
        'tipoRecorrencia',
        editAgendamento.atendimentoRecorrente?.tipoRecorrencia
          ? editAgendamento.atendimentoRecorrente?.tipoRecorrencia
          : null,
      );

      setValue(
        'intervalo',
        editAgendamento.atendimentoRecorrente?.intervalo
          ? editAgendamento.atendimentoRecorrente?.intervalo
          : null,
      );
      setValue(
        'horario',
        editAgendamento.atendimentoRecorrente?.horario
          ? new Date(editAgendamento.dataAgendamento)
          : null,
      );
      setValue(
        'diaSemana',
        editAgendamento.atendimentoRecorrente?.diaSemana
          ? editAgendamento.atendimentoRecorrente?.diaSemana
          : null,
      );
      setTipoMensal(
        editAgendamento.atendimentoRecorrente?.tipoRecorrenciaMensal
          ? editAgendamento.atendimentoRecorrente?.tipoRecorrenciaMensal
          : null,
      );
      setValue(
        'tipoMensal',
        editAgendamento.atendimentoRecorrente?.tipoRecorrenciaMensal
          ? editAgendamento.atendimentoRecorrente?.tipoMensal
          : null,
      );
      setValue(
        'diaMes',
        editAgendamento.atendimentoRecorrente?.diaEspecifico
          ? editAgendamento.atendimentoRecorrente?.diaEspecifico
          : null,
      );
      setValue(
        'semanaMes',
        editAgendamento.atendimentoRecorrente?.semanaMes
          ? editAgendamento.atendimentoRecorrente?.semanaMes
          : null,
      );
      setValue(
        'dataInicio',
        editAgendamento.atendimentoRecorrente?.dataInicio
          ? new Date(editAgendamento.atendimentoRecorrente?.dataInicio)
          : null,
      );
      setValue(
        'dataFim',
        editAgendamento.atendimentoRecorrente?.dataFim
          ? new Date(editAgendamento.atendimentoRecorrente?.dataFim)
          : null,
      );

      setInternalDateTime(new Date(editAgendamento.dataAgendamento));
      setContato(editAgendamento.agendamentoRapidoTelefone);
    }
  }, [editAgendamento, handleSetPaciente, setAgendamentoRapidoNome, setValue]);

  useEffect(() => {
    if (!date || !agendamento) {
      return;
    }

    const aux = dateAndHourToDate(
      new Date(agendamento.dataAgendamento),
      dayjs(agendamento.dataAgendamento).format('HH:mm'),
    );
    if (editAgendamento) {
      setPaciente(editAgendamento.paciente);
      handleSetPaciente(editAgendamento.paciente);

      setEnviarSMS(editAgendamento.enviaSms);
      setContato(editAgendamento.agendamentoRapidoTelefone);
      setObservacao(editAgendamento.observacao);
    }

    setInternalDateTime(aux);
  }, [date, agendamento, editAgendamento, setValue, handleSetPaciente]);

  useEffect(() => {
    if (date) {
      step2Ref.current?.show();
    } else {
      step1Ref.current?.show();
    }
  }, [date]);

  useEffect(() => {
    if (recorrencia) {
      setValue('horario', internalDateTime);
    }
  }, [internalDateTime, recorrencia, setValue]);

  useEffectSkipFirst(() => {
    if (hasToVerifyElegibilidade && !!planoSelected) {
      ConvenioAPI.getConveniosTissById(planoSelected?.idConvenio, {
        throwError: true,
      })
        .then(data =>
          setTimeOutElegibilidade(data?.timeoutVerificaElegibilidade),
        )
        .catch(() => {});
    }
  }, [hasToVerifyElegibilidade, planoSelected]);

  //* WS ELEGIBILIDADE TISS
  useSubscription('/user/topic/notificacao', (message: any) => {
    const data = JSON.parse(message.body);

    if (
      data.tipoNotificacao === 'TISS' &&
      data?.dadosComplementares?.transacao === 'ELEGIBILIDADE' &&
      isTimerRunning
    ) {
      dialogComunicacaoTiss.close();

      if (
        !data.dadosComplementares?.sucesso ||
        data.dadosComplementares?.sucesso === 'false'
      )
        return [
          dialogTissErroOperadora.open(),
          handleElegibilidadeObservacao(
            data.dadosComplementares?.motivoNegativa,
          ),
        ];

      if (data.dadosComplementares.elegivel === 'S' && !isSubmitting) {
        recorrencia
          ? handleAgendamentoRecorrente(dataRecorrencia)
          : handleAgendamento();
      }

      if (data.dadosComplementares.elegivel === 'N') {
        dialogTissPacienteNaoElegivel.open();
        handleElegibilidadeObservacao(data.dadosComplementares?.motivoNegativa);
      }
    }
    return;
  });

  const onSelectPaciente = (value: GetPacienteDTO | undefined) => {
    setPaciente(value);
    handleSetPaciente(value);

    const pacienteCelular = value?.contatos?.find(
      contato => contato.tipo === 'CELULAR_PARTICULAR',
    );
    if (pacienteCelular?.numero) {
      setContato(pacienteCelular.numero);
    }
  };

  const getSaveAction = useCallback(() => {
    if (recorrencia) return AgendamentosAPI.postAgendamentoRecorrente;

    if (selectedGrupo?.id) return AgendamentosAPI.postAgendamentoGrupo;

    if (editAgendamento) return AgendamentosAPI.postReagendar;

    if (agendamento?.id) return AgendamentosAPI.editarAgendamento;

    return AgendamentosAPI.saveNewAgendamento;
  }, [agendamento?.id, editAgendamento, recorrencia, selectedGrupo?.id]);

  const handleVerifyElegibilidade = useCallback(() => {
    setIsSubmitting(true);

    if (paciente?.id)
      ElegibilidadeAPI.verifyElegibilidade(
        {
          idPaciente: paciente?.id,
          idProfissionalSaude: agenda.profissionalAtivo?.id,
          idCartaoPaciente: planoSelected?.idPacienteCartao,
        },
        {
          throwError: true,
        },
      )
        .then(() => dialogComunicacaoTiss.open())
        .catch(() => dialogTissErroOperadora.open())
        .finally(() => setIsSubmitting(false));
  }, [
    agenda.profissionalAtivo?.id,
    dialogComunicacaoTiss,
    dialogTissErroOperadora,
    paciente?.id,
    planoSelected?.idPacienteCartao,
    setIsSubmitting,
  ]);

  const handleSaveAction = useCallback(
    async payload => {
      const saveAction = getSaveAction();

      const aux: any = await saveAction(payload);

      if (aux?.status > 199 && aux?.status < 300) {
        if (idListaEspera) {
          await ListaEsperaAPI.deleteItemListaEspera(idListaEspera, {
            hideToast: true,
          });
        }
        navigate('/agenda', {
          state: { visualizarAgenda: voltarParaAgenda },
        });
      }

      setIsSubmitting(false);
    },
    [getSaveAction, setIsSubmitting, idListaEspera, navigate, voltarParaAgenda],
  );
  const handleAgendamento = useCallback(async () => {
    let obsFinal = '';

    if (!consultorios.ativo) return;

    setIsSubmitting(true);

    const formAux = formRef?.current?.getValues();
    const id = agendamento?.id || editAgendamento?.id || undefined;
    const flagsAtendimentos = formAux?.tags || [];

    if (
      planoSelected !== 'PARTICULAR' &&
      flagsAtendimentos.includes('PARTICULAR')
    ) {
      flagsAtendimentos.splice(flagsAtendimentos.indexOf('PARTICULAR'), 1);
    }

    if (screen === 'Encaixe') {
      flagsAtendimentos.push('ENCAIXE');
    }

    if (planoSelected === 'PARTICULAR') {
      flagsAtendimentos.push('PARTICULAR');
    }

    if (formAux && formAux.observacoes) {
      obsFinal = formAux.observacoes;
    } else if (observacao && observacao.length) {
      obsFinal = observacao;
    }

    if (elegibilidadeObservacao) {
      if (obsFinal) obsFinal = obsFinal + ' \n';
      obsFinal = obsFinal + elegibilidadeObservacao;
    }

    const payload: any = {
      ...(!selectedGrupo?.id && { id }),
      idPaciente: paciente?.id,
      idMedico: profissionalAtivo,
      idConsultorio: consultorioAtivo,
      dataAgendamento: dateToRequest(internalDateTime),
      enviaSms: enviarSMS || false,
      status: 'AGENDADO',
      observacao: obsFinal,
      flagsAtendimentos,
      agendamentoRapidoTelefone: contato,
      idGrupoAtendimento: selectedGrupo?.id,
      origemAgendamento: 'E',
      ...(editAgendamento && {
        registrarHistoricoFalta: editAgendamento.registrarHistoricoFalta,
      }),
      ...(servicoTussSelected && {
        idServicoTuss: servicoTussSelected.idServicoTuss,
      }),
      ...(planoSelected && {
        idCartaoPaciente: planoSelected?.idPacienteCartao,
        idConvenio: planoSelected?.idConvenio,
      }),
      agendamentoRapidoNome,
    };

    handleSaveAction(payload);
  }, [
    consultorios.ativo,
    setIsSubmitting,
    agendamento?.id,
    editAgendamento,
    servicoTussSelected,
    paciente?.id,
    profissionalAtivo,
    consultorioAtivo,
    internalDateTime,
    enviarSMS,
    observacao,
    elegibilidadeObservacao,
    contato,
    selectedGrupo?.id,
    screen,
    planoSelected,
    handleSaveAction,
    agendamentoRapidoNome,
  ]);

  const handleAgendamentoRecorrente = useCallback(
    async (data: any) => {
      if (!consultorios.ativo) {
        return;
      }

      setDataRecorrencia(data);

      const id = agendamento?.id || editAgendamento?.id || undefined;

      const formAux = formRef?.current?.getValues();

      const flagsAtendimentos = formAux?.tags || [];

      const payload: any = {
        id,
        idPaciente: paciente?.id,
        idMedico: profissionalAtivo,
        idConsultorio: consultorioAtivo,
        enviaSms: enviarSMS || false,
        status: 'AGENDADO',
        observacao: formAux?.observacoes || '' + ` ${elegibilidadeObservacao}`,
        flagsAtendimentos,
        agendamentoRapidoTelefone: contato,
        idGrupoAtendimento: selectedGrupo?.id,
        ...(editAgendamento && {
          registrarHistoricoFalta: editAgendamento.registrarHistoricoFalta,
        }),

        encaixeHorario: encaixeHorario,
        intervalo: data.intervalo,
        tipoRecorrencia: getValues('tipoRecorrencia'),
        ...(watchTipoRecorrencia === 'SEMANAL' && {
          diaSemana: data.diaSemana,
        }),
        ...(tipoMensal === 'DIA_ESPECIFICO' && {
          diaEspecifico: data.diaMes,
        }),
        ...(tipoMensal === 'DIA_DA_SEMANA' && {
          diaSemana: data.diaSemana,
          semanaMes: data.semanaMes,
        }),
        ...(encaixeHorario && {
          horario: dateToHourMinutsString(new Date(data.horario)) + ':00',
        }),
        ...(!encaixeHorario && {
          horario: dateToHourMinutsString(new Date(data.horario)) + ':00',
        }),
        dataInicio: data.dataInicio,
        dataFim: data.dataFim,
        ...(servicoTussSelected && {
          idServicoTuss: servicoTussSelected.idServicoTuss,
        }),
      };

      if (screen === 'Encaixe') {
        payload.flagsAtendimentos = [...flagsAtendimentos, 'ENCAIXE'];
      }
      if (planoSelected === 'PARTICULAR') {
        payload.flagsAtendimentos = [...flagsAtendimentos, 'PARTICULAR'];
      }

      const saveAction = getSaveAction();

      const aux: any = await saveAction(payload);

      if (aux?.status === 200) {
        setHorariosIndisponiveis(aux.data);
        modalEncaixeDisclosure.open();
      } else if (aux?.status === 204 || aux?.status === 201) {
        navigate('/agenda', {
          state: { visualizarAgenda: voltarParaAgenda },
        });
      }
    },
    [
      consultorios.ativo,
      agendamento?.id,
      editAgendamento,
      paciente?.id,
      profissionalAtivo,
      consultorioAtivo,
      enviarSMS,
      elegibilidadeObservacao,
      contato,
      selectedGrupo?.id,
      encaixeHorario,
      getValues,
      watchTipoRecorrencia,
      tipoMensal,
      servicoTussSelected,
      screen,
      planoSelected,
      getSaveAction,
      modalEncaixeDisclosure,
      navigate,
      voltarParaAgenda,
    ],
  );

  useEffect(() => {
    if (encaixeHorario) {
      handleAgendamentoRecorrente(dataRecorrencia);
      setEncaixeHorario(false);
    }
  }, [encaixeHorario, handleAgendamentoRecorrente, dataRecorrencia]);

  //* JSX ------

  const renderStep1Encaixe = useCallback(() => {
    return (
      <BasicPanel
        ref={step1Ref}
        title={'Profissional e data'}
        subTitle="Com o profissional já selecionado, escolha o dia e o horário."
      >
        <div>
          <div className="Profissional-infos">
            <CustomImage src={'perfil_secretaria.png'} />
            <div className="Profissional-infos__text">
              <SimpleText fontSize={FONT_SIZE.XS} bold>
                {agenda.profissionalAtivo?.nome || '-'}
              </SimpleText>
            </div>
          </div>
          <Formik
            onSubmit={(values: any) => {
              step1Ref.current?.collapse();
              step2Ref.current?.show();
            }}
            validationSchema={Yup.object().shape({
              dia: Yup.date()
                .required('Campo obrigatório')
                .nullable()
                .min(dayjs().format('YYYY-MM-DD'), 'Data inválida'),
              hora: Yup.string().required('Campo obrigatório').nullable(),
            })}
            initialValues={{
              dia: internalDateTime,
              hora: internalDateTime,
            }}
          >
            {({ errors, handleSubmit, handleChange, values, isValid }) => (
              <form className="data-encaixe-form" onSubmit={handleSubmit}>
                <div className="data-encaixe">
                  <CalendarInput
                    name="dia"
                    label="Dia"
                    onChange={e => {
                      const data = dayjs(
                        dayjs(e.value as any).format('YYYY-MM-DD') +
                          'T' +
                          dayjs(values.hora).format('HH:mm:ss'),
                      ).toDate();
                      setInternalDateTime(data);
                      handleChange(e);
                    }}
                    value={values.dia}
                    showIcon
                    errorMsg={(errors?.dia as any) || ''}
                  />
                  <CalendarInput
                    label="Hora"
                    name="hora"
                    onChange={e => {
                      const diaPart = dayjs(values.dia).format('YYYY-MM-DD');
                      const horaPart = dayjs(e.value as any).format('HH:mm:ss');
                      const combinedDateTime = dayjs(
                        `${diaPart}T${horaPart}`,
                      ).toDate();
                      setInternalDateTime(combinedDateTime);
                      handleChange(e);
                    }}
                    value={values.hora}
                    icon={'fas fa-clock'}
                    errorMsg={(errors?.hora as any) || ''}
                    showIcon
                    timeOnly
                  />
                </div>
                <Button
                  label={'Próximo'}
                  type="submit"
                  stretch
                  btnType="tonal"
                  disabled={!isValid}
                  loading={isSubmitting}
                />
              </form>
            )}
          </Formik>
        </div>
      </BasicPanel>
    );
  }, [agenda.profissionalAtivo?.nome, internalDateTime, isSubmitting]);

  const renderStep1 = useCallback(() => {
    return (
      <BasicPanel
        ref={step1Ref}
        title={'Profissional e data'}
        subTitle="Com o profissional já selecionado, escolha o dia e o horário."
      >
        <div>
          <div className="days-aligner p-flex-column p-flex-sm-row p-ai-center p-ai-sm-end">
            <CalendarInput
              label="Dia"
              value={internalDateTime}
              showIcon
              disabled
            />
            <Separator spaceOnly />
            <CalendarInput
              label="Hora"
              value={internalDateTime}
              icon={'fas fa-clock'}
              showIcon
              disabled
              timeOnly
            />
            <Separator spaceOnly />
            <Button
              label="Trocar horário"
              onClick={() => {
                exibirModalDisclosure.isOpen
                  ? exibirModalDisclosure.close()
                  : exibirModalDisclosure.open();
              }}
              btnType="ghost"
            />
          </div>
          <Separator spaceOnly vertical />
          <Button
            label={'Próximo'}
            btnType="tonal"
            stretch
            onClick={() => {
              step1Ref.current?.collapse();
              step2Ref.current?.show();
            }}
          />
        </div>
      </BasicPanel>
    );
  }, [internalDateTime]);

  const renderPacienteCard = useCallback(() => {
    if (!paciente) {
      return;
    }

    const dataUltimaTriagem = getPacienteDateInfo(
      ultimosAtendimentos?.dataUltimaTriagem,
    );
    const dataUltimoAtendimento = getPacienteDateInfo(
      ultimosAtendimentos?.dataUltimoAtendimento,
    );
    return (
      <div className={'paciente-card'}>
        <Card type={CARD_TYPES.GRAY}>
          <>
            <div className="novo-agendamento-paciente-ativo p-d-flex p-flex-column p-gap-2">
              <div className="info">
                <div className="paciente">
                  <Avatar
                    image={paciente.urlFoto || ''}
                    label={paciente.nome}
                  />
                  <Separator spaceOnly />
                  <div className="paciente-personal">
                    <SimpleText fontColor={FONT_COLOR.COLOR_16}>
                      {paciente.nome}
                    </SimpleText>
                    <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                      {paciente.sexo?.descricao || ''} • {paciente.cartaoPlano}
                    </SimpleText>
                  </div>
                </div>
                <div className="details">
                  <SimpleText fontColor={FONT_COLOR.COLOR_16}>
                    {paciente.cpf}
                  </SimpleText>
                  <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                    Registro no.
                  </SimpleText>
                </div>
              </div>

              <Skeleton loading={loadingData}>
                <div className="p-d-flex p-flex-column p-jc-around p-gap-2">
                  {dataUltimaTriagem && (
                    <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                      Última triagem há: {dataUltimaTriagem.daysDifferenceToNow}{' '}
                      {dataUltimaTriagem.dayPreffix}, em{' '}
                      {dataUltimaTriagem.dateFormatted}
                    </SimpleText>
                  )}

                  {dataUltimoAtendimento && (
                    <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                      Último atendimento há{' '}
                      {dataUltimoAtendimento.daysDifferenceToNow}{' '}
                      {dataUltimoAtendimento.dayPreffix}, em{' '}
                      {dataUltimoAtendimento.dateFormatted}
                    </SimpleText>
                  )}
                </div>
              </Skeleton>
            </div>
          </>
        </Card>
        <Separator spaceOnly vertical />
        <div className="paciente-card-actions">
          <Button
            label={'Trocar de paciente'}
            btnType={'green-link'}
            onClick={() => {
              setPaciente(undefined);
              setContato('');
              handleSetPaciente(null);
            }}
            disabled={editAgendamento ? true : false}
          />
        </div>
        <Separator spaceOnly vertical />
      </div>
    );
  }, [
    editAgendamento,
    handleSetPaciente,
    loadingData,
    paciente,
    ultimosAtendimentos?.dataUltimaTriagem,
    ultimosAtendimentos?.dataUltimoAtendimento,
  ]);

  const renderGrupoPacienteCard = useCallback(
    (paciente: GrupoAtendimentoPacienteDTO) => {
      const dataNascimento =
        new Date(paciente.dataNascimento).getFullYear() -
        new Date().getFullYear();

      return (
        <div className="p-grid card-paciente">
          <div className="p-col-6">
            <Avatar
              size="large"
              image={paciente.urlFoto || ''}
              label={paciente.nome}
            />

            <div className="infos">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                {paciente.sexoDescricao?.toLocaleLowerCase()}
              </SimpleText>

              <SimpleText
                className="p-my-2"
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
              >
                {paciente.nome}
              </SimpleText>

              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                {dataNascimento * -1} anos | {paciente.celularParticular}
              </SimpleText>
            </div>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              05
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              00
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              00
            </SimpleText>
          </div>
        </div>
      );
    },
    [],
  );

  const renderAccordion = useCallback(
    (grupo: GrupoAtendimentoDTO) => {
      const template = () => {
        return (
          <div className="p-grid p-w-100">
            <div className="p-col-4">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
                bold
              >
                {grupo?.apelido}
              </SimpleText>
            </div>

            <div className="p-col-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo?.periodo}
              </SimpleText>
            </div>

            <div className="p-col-3">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo.grupoAtendimentoPacientes.length <= 9
                  ? '0' + grupo.grupoAtendimentoPacientes.length
                  : grupo.grupoAtendimentoPacientes.length}

                {grupo.grupoAtendimentoPacientes.length === 1
                  ? ' Paciente'
                  : ' Pacientes'}
              </SimpleText>
            </div>

            <div className="p-col-3 text-max">
              <SimpleText
                title={grupo.nomeTipoGrupo}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo.nomeTipoGrupo}
              </SimpleText>
            </div>
          </div>
        );
      };

      return (
        <BasicPanel ref={painelRef} template={template()}>
          <>
            <div className="p-grid p-mt-3 p-mb-1">
              <div className="p-col-6">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Paciente
                </SimpleText>
              </div>

              <div className="p-col-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Presenças
                </SimpleText>
              </div>

              <div className="p-col-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  faltas
                </SimpleText>
              </div>

              <div className="p-col-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Cancelamentos
                </SimpleText>
              </div>
            </div>

            {grupo.grupoAtendimentoPacientes.map(paciente => (
              <div key={paciente.id}>{renderGrupoPacienteCard(paciente)}</div>
            ))}
          </>
        </BasicPanel>
      );
    },
    [renderGrupoPacienteCard],
  );

  const renderSelectePaciente = useCallback(() => {
    if (paciente) return renderPacienteCard();
    if (agendamentoRapidoNome) {
      return (
        <div className={'paciente-card'}>
          <Card type={CARD_TYPES.GRAY}>
            <SimpleText fontColor={FONT_COLOR.COLOR_40}>
              Reservar horário para...{' '}
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                {agendamentoRapidoNome}
              </SimpleText>
            </SimpleText>
          </Card>
          <Separator spaceOnly vertical />
          <div className="paciente-card-actions">
            <Button
              label="Trocar de paciente"
              btnType="green-link"
              onClick={() => {
                formRef?.current?.setValue('tags', []);
                setAgendamentoRapidoNome(undefined);
              }}
            />
          </div>
          <Separator spaceOnly vertical />
        </div>
      );
    }

    const items = [
      {
        label: 'Abrir lista de espera',
        command: () => modalOutrosDisclosure.open({ state: { activeTab: 0 } }),
      },
      {
        label: 'Cadastrar recém-nascido',
        command: () => modalOutrosDisclosure.open({ state: { activeTab: 1 } }),
      },
      {
        label: 'Agendar grupos',
        command: () => modalOutrosDisclosure.open({ state: { activeTab: 2 } }),
      },
    ];

    return (
      <div className="container-input p-flex-column p-flex-sm-row p-ai-center p-ai-sm-end">
        <SearchPaciente
          label="Paciente"
          value={paciente}
          onSelect={(value: GetPacienteDTO | undefined) => {
            setPaciente(value);
            handleSetPaciente(value);
            const pacienteCelular = value?.contatos?.find(
              contato => contato.tipo === 'CELULAR_PARTICULAR',
            );
            if (pacienteCelular?.numero) setContato(pacienteCelular?.numero);
          }}
          onBooking={(name: string) => {
            setRecorrencia(false);
            setAgendamentoRapidoNome(name);
            formRef?.current?.setValue('tags', ['RESERVA_HORARIO']);
            setStep4InitalValues((prevState: any) => ({
              ...prevState,
              tags: ['RESERVA_HORARIO'],
            }));
            step4Ref.current?.show();
          }}
          idConsultorio={consultorioAtivo}
          disabled={editAgendamento ? true : false}
          paramsBusca={{ obito: false, status: true }}
        />

        <Menu model={items} popup ref={optionsPaciente} />

        <Button
          className="p-ml-0 p-ml-sm-3"
          label={'Outros...'}
          icon="fas fa-chevron-down"
          iconPos="right"
          btnType={'tonal'}
          onClick={event => optionsPaciente.current.toggle(event)}
        />
      </div>
    );
  }, [
    agendamentoRapidoNome,
    consultorioAtivo,
    editAgendamento,
    handleSetPaciente,
    modalOutrosDisclosure,
    paciente,
    renderPacienteCard,
    setAgendamentoRapidoNome,
    step4Ref,
  ]);

  const renderStep2 = useCallback(() => {
    const items = [
      {
        label: 'Abrir lista de espera',
        command: () => modalOutrosDisclosure.open({ state: { activeTab: 0 } }),
      },
      {
        label: 'Cadastrar recém-nascido',
        command: () => modalOutrosDisclosure.open({ state: { activeTab: 1 } }),
      },
      {
        label: 'Agendar grupos',
        command: () => modalOutrosDisclosure.open({ state: { activeTab: 2 } }),
      },
    ];

    return (
      <BasicPanel
        ref={step2Ref}
        title={'Paciente'}
        subTitle="Agende um paciente cadastrado ou adicione um novo."
      >
        <div>
          {selectedGrupo ? (
            <>
              <div className="accordion">{renderAccordion(selectedGrupo)}</div>

              <div className="p-d-flex p-ai-center p-jc-center p-my-4">
                <Button
                  label="Trocar grupo"
                  className="p-mr-6"
                  btnType={'green-link'}
                  onClick={() =>
                    modalOutrosDisclosure.open({ state: { activeTab: 2 } })
                  }
                />
              </div>

              <Checkbox
                label="Gerar guia de consultas para os pacientes"
                className="grid p-col-12 p-md-12"
                value={guiaTiss}
                checked={guiaTiss}
                onChange={e => {
                  setGuiaTiss(!e.target.value);
                }}
              />
            </>
          ) : (
            <div>{renderSelectePaciente()}</div>
          )}

          {!selectedGrupo && (
            <div className="paciente-options p-flex-column p-flex-sm-row p-mt-4">
              <MaskedInput
                label="Telefone celular"
                name={'contato'}
                mask="(99) 99999-999?9"
                placeholder={'(99) 99999-9999'}
                value={contato}
                onChange={e => {
                  setContato(e.value);
                }}
              />

              <div className="paciente-options switch p-mt-3 p-mt-sm-0">
                <SimpleText bold>Confirmação via SMS</SimpleText>
                <Tooltip
                  target=".info-button"
                  position="top"
                  content="A confirmação via SMS será enviada
para o contato registrado no sistema."
                />
                <Button
                  icon="fas fa-info-circle"
                  btnType="ghost"
                  className="info-button p-p-0 p-m-0 p-d-flex p-jc-start"
                />
                <Separator spaceOnly />
                <Switch
                  checked={enviarSMS}
                  onChange={e => {
                    setEnviarSMS(e.value);
                  }}
                />
              </div>
            </div>
          )}

          <Separator vertical spaceOnly />

          <Button
            label={'Próximo'}
            btnType={'tonal'}
            stretch
            onClick={() => {
              step2Ref.current?.collapse();
              step3Ref.current?.show();
            }}
          />
        </div>
      </BasicPanel>
    );
  }, [
    contato,
    enviarSMS,
    guiaTiss,
    modalOutrosDisclosure,
    renderAccordion,
    renderSelectePaciente,
    selectedGrupo,
    step2Ref,
    step3Ref,
  ]);

  const renderStep3 = useCallback(() => {
    return (
      <BasicPanel
        ref={step3Ref}
        title={'Faturamento'}
        disabled={paciente ? false : true}
        subTitle="Selecione um convenio e os serviços que serão prestados."
      >
        <FaturamentoAgendamento
          convenioInitial={
            editAgendamento?.atendimentoParticular
              ? 'PARTICULAR'
              : editAgendamento?.cartaoPlano
          }
        />
      </BasicPanel>
    );
  }, [
    editAgendamento?.atendimentoParticular,
    editAgendamento?.cartaoPlano,
    paciente,
    step3Ref,
  ]);

  const renderStep4 = useCallback(() => {
    const watchDataInicio = watch('dataInicio');

    const onDiaSemanaChange = (e: any) => {
      setDiaSemana(e.value);
    };

    const onSemanaMesChange = (e: any) => {
      setSemanaMes(e.value);
    };

    const recorrenciaOptions = [
      { label: 'Diário', value: 'DIARIA' },
      { label: 'Semanal', value: 'SEMANAL' },
      { label: 'Mensal', value: 'MENSAL' },
    ];

    const dias = [
      { label: 'Domingo', value: 'DOMINGO' },
      { label: 'Segunda', value: 'SEGUNDA' },
      { label: 'Terça', value: 'TERCA' },
      { label: 'Quarta', value: 'QUARTA' },
      { label: 'Quinta', value: 'QUINTA' },
      { label: 'Sexta', value: 'SEXTA' },
      { label: 'Sábado', value: 'SABADO' },
    ];

    const semana = [
      { label: '1ª semana', value: 'PRIMEIRA' },
      { label: '2ª semana', value: 'SEGUNDA' },
      { label: '3ª semana', value: 'TERCEIRA' },
      { label: '4ª semana', value: 'QUARTA' },
    ];

    const options = [
      {
        label: 'Retorno',
        value: 'RETORNO',
        hide: !!agendamentoRapidoNome,
      },
      {
        label: 'Teleconsulta',
        value: 'TELECONSULTA',
        hide: !!agendamentoRapidoNome,
      },
      {
        label: 'Reserva de horário',
        value: 'RESERVA_HORARIO',
        hide: !agendamentoRapidoNome,
      },
      {
        label: 'Procedimento',
        value: 'PROCEDIMENTO',
        hide: !!agendamentoRapidoNome,
      },
      {
        label: 'Puericultura',
        value: 'PUERICULTURA',
        hide: !!agendamentoRapidoNome,
      },
      {
        label: 'Recém-nascido',
        value: 'RECEM_NASCIDO',
        hide: !!agendamentoRapidoNome,
      },
    ];

    const agendamentoFlagsAtivas =
      agendamento?.flagsAtivas || editAgendamento?.flagsAtivas || [];
    const agendamentoPrestadorExecutante = (agendamento?.prestadorExecutante ||
      editAgendamento?.prestadorExecutante) &&
      !agendamentoFlagsAtivas?.tags?.includes('PROCEDIMENTO') && [
        'PROCEDIMENTO',
      ];

    const initialValues = step4InitialValue
      ? step4InitialValue
      : {
          tags: [
            ...agendamentoFlagsAtivas,
            ...(agendamentoPrestadorExecutante || []),
          ],
          observacoes:
            editAgendamento?.observacao || agendamento?.observacao || '',
        };

    return (
      <BasicPanel
        ref={step4Ref}
        disabled={paciente ? false : true}
        title={'Outras informações'}
        subTitle="Selecione as características da consulta e adicione observações para o médico."
      >
        <>
          <Form
            ref={formRef}
            onChangeValues={v => setStep4InitalValues(v)}
            initialValues={initialValues}
            fields={[
              [
                {
                  name: 'tags',
                  type: FIELD.CHIP_SELECT,
                  options: options,
                  value: (step4InitialValue as any)?.tags || [],
                },
              ],
              [
                {
                  name: 'observacoes',
                  type: FIELD.TEXTAREA_INPUT,
                  value: observacao,
                  label: 'Observações',
                  customOnChange: (e: any) => {
                    setObservacao(e);
                  },
                },
              ],
            ]}
          />
          {!agendamentoRapidoNome && (
            <form id="agendamento-recorrente">
              <div className="recorrencia">
                <div className="p-d-flex p-flex-column p-flex-sm-row p-ai-center p-jc-center p-jc-sm-between p-p-1">
                  <SimpleText
                    className="p-text-center p-text-sm-left p-mb-3 p-mb-sm-0"
                    bold
                  >
                    Consulta recorrente
                  </SimpleText>
                  <div className="p-d-flex p-ai-center p-jc-center">
                    <SimpleText>
                      {recorrencia ? 'Ativado' : 'Desativado'}
                    </SimpleText>
                    <Separator spaceOnly />
                    {/* // TODO: Controlled */}
                    <Switch
                      checked={recorrencia}
                      onChange={e => {
                        setRecorrencia(e.value);
                      }}
                      disabled={editAgendamento ? true : false}
                    />
                    <Separator spaceOnly />
                    <IconButton
                      id="btnExcluirRecorrencia"
                      icon="fas fa-trash"
                      className="p-button-rounded p-button-outlined"
                      onClick={e => e.preventDefault()}
                      type="button"
                      overlay={
                        <ModalExcluirRecorrencia
                          idAtendimentoRecorrente={
                            editAgendamento
                              ? editAgendamento.idAtendimentoRecorrente
                              : null
                          }
                          dataAgendamento={
                            editAgendamento
                              ? editAgendamento.dataAgendamento
                              : null
                          }
                        />
                      }
                      disabled={
                        !editAgendamento?.atendimentoRecorrente ? true : false
                      }
                    />
                  </div>
                </div>
                {(recorrencia || editAgendamento) && (
                  <div className="p-d-flex p-flex-column">
                    <div className="recorrencia-options p-d-flex">
                      <SelectButtonControlled
                        control={control}
                        options={recorrenciaOptions}
                        className="select-recorrencia p-mt-3"
                        responsive
                        disabled={editAgendamento ? true : false}
                        name="tipoRecorrencia"
                      />
                    </div>
                    <div className="periodo p-d-flex p-jc-between p-ai-end">
                      <TextInputControlled
                        control={control}
                        label="A cada"
                        name="intervalo"
                        errorMsg={errors.intervalo?.message}
                        className="w-50"
                        disabled={editAgendamento ? true : false}
                      />
                      <TextInput
                        value={tipoRecorrenciaLabel(watchTipoRecorrencia)}
                        disabled
                        className="w-50"
                      />
                    </div>
                    <div className="hora-recorrente">
                      {watchTipoRecorrencia === 'DIARIA' && (
                        <>
                          <CalendarInputControlled
                            control={control}
                            label="Hora"
                            name="horario"
                            icon={'fas fa-clock'}
                            showIcon
                            timeOnly
                            className="p-mb-2"
                            onChange={e => (e.value ? setHorario(e.value) : {})}
                            errorMsg={errors.horario?.message}
                            disabled={editAgendamento ? true : false}
                          />
                          <SimpleText
                            className="p-p-1"
                            fontColor={FONT_COLOR.COLOR_40}
                            fontSize={FONT_SIZE.XS}
                          >
                            Defina o período da recorrência deste horário
                          </SimpleText>
                        </>
                      )}
                      {watchTipoRecorrencia === 'SEMANAL' && (
                        <>
                          <div className="p-grid p-px-0">
                            <DropdownControlled
                              className="p-col-12 p-sm-6 p-px-0"
                              control={control}
                              options={dias}
                              placeholder="Dia da semana"
                              label="Dia da semana"
                              name="diaSemana"
                              errorMsg={errors.diaSemana?.message}
                              disabled={editAgendamento ? true : false}
                            />
                            <CalendarInputControlled
                              className="p-col-12 p-sm-6 p-px-0 p-mb-2"
                              control={control}
                              label="Hora"
                              name="horario"
                              icon={'fas fa-clock'}
                              showIcon
                              timeOnly
                              onChange={e =>
                                e.value ? setHorario(e.value) : {}
                              }
                              errorMsg={errors.horario?.message}
                              disabled={editAgendamento ? true : false}
                            />
                          </div>
                        </>
                      )}
                      {watchTipoRecorrencia === 'MENSAL' && (
                        <>
                          <SimpleText
                            className="p-p-1"
                            fontColor={FONT_COLOR.COLOR_40}
                            fontSize={FONT_SIZE.XS}
                          >
                            Tipo
                          </SimpleText>
                          <div className="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
                            <RadioButton
                              name="tipoMensal"
                              value="DIA_ESPECIFICO"
                              label="Dia específico"
                              onChange={() => setTipoMensal('DIA_ESPECIFICO')}
                              checked={tipoMensal === 'DIA_ESPECIFICO'}
                              className="p-flex-1 p-mr-0 p-mr-sm-2"
                              disabled={editAgendamento ? true : false}
                            />
                            <RadioButton
                              name="tipoMensal"
                              value="DIA_DA_SEMANA"
                              label="Dia da semana"
                              onChange={() => setTipoMensal('DIA_DA_SEMANA')}
                              checked={tipoMensal === 'DIA_DA_SEMANA'}
                              className="p-flex-1 p-ml-0 p-ml-sm-2"
                              disabled={editAgendamento ? true : false}
                            />
                          </div>
                          {tipoMensal === 'DIA_ESPECIFICO' && (
                            <>
                              <div className="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
                                <TextInputControlled
                                  control={control}
                                  name="diaMes"
                                  label="Dia"
                                  className="p-flex-1 p-pl-0 p-mr-0 p-mr-sm-4 p-mb-2 p-mb-sm-0"
                                  permitirApenasNumeros
                                  min={1}
                                  max={31}
                                  errorMsg={errors.diaMes?.message}
                                  disabled={editAgendamento ? true : false}
                                />
                                <CalendarInputControlled
                                  control={control}
                                  name="horario"
                                  label="Hora"
                                  icon={'fas fa-clock'}
                                  showIcon
                                  timeOnly
                                  className="p-flex-1 p-pr-0"
                                  onChange={e =>
                                    e.value ? setHorario(e.value) : {}
                                  }
                                  errorMsg={errors.horario?.message}
                                  disabled={editAgendamento ? true : false}
                                />
                              </div>
                            </>
                          )}
                          {tipoMensal === 'DIA_DA_SEMANA' && (
                            <>
                              <div className="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
                                <DropdownControlled
                                  className="p-flex-1 p-mr-0 p-mr-sm-4 p-mb-2 p-mb-sm-0"
                                  control={control}
                                  name="semanaMes"
                                  value={semanaMes}
                                  options={semana}
                                  onChange={onSemanaMesChange}
                                  placeholder="Semana do Mês"
                                  label="Na semana"
                                  errorMsg={errors.semanaMes?.message}
                                  disabled={editAgendamento ? true : false}
                                />
                                <DropdownControlled
                                  className="p-flex-1 p-mb-2"
                                  control={control}
                                  name="diaSemana"
                                  value={diaSemana}
                                  options={dias}
                                  onChange={onDiaSemanaChange}
                                  placeholder="Dia da semana"
                                  label="Dia"
                                  errorMsg={errors.diaSemana?.message}
                                  disabled={editAgendamento ? true : false}
                                />
                              </div>
                              <CalendarInputControlled
                                control={control}
                                name="horario"
                                label="Hora"
                                icon={'fas fa-clock'}
                                showIcon
                                timeOnly
                                className="p-mb-2"
                                onChange={e =>
                                  e.value ? setHorario(e.value) : {}
                                }
                                errorMsg={errors.horario?.message}
                                disabled={editAgendamento ? true : false}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="p-grid p-px-0 data-inicial-final p-d-flex">
                      <CalendarInputControlled
                        className="p-col-12 p-sm-6"
                        control={control}
                        name="dataInicio"
                        label="Data de início"
                        placeholder="dd/mm/aaaa"
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        errorMsg={errors.dataInicio?.message}
                        minDate={new Date()}
                        showIcon
                        disabled={editAgendamento ? true : false}
                      />

                      <CalendarInputControlled
                        className="p-col-12 p-sm-6"
                        control={control}
                        name="dataFim"
                        label="Data de fim"
                        placeholder="dd/mm/aaaa"
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        minDate={watchDataInicio}
                        errorMsg={errors.dataFim?.message}
                        disabled={
                          !watchDataInicio || editAgendamento ? true : false
                        }
                        showIcon
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
        </>
      </BasicPanel>
    );
  }, [
    watch,
    step4InitialValue,
    agendamento?.flagsAtivas,
    agendamento?.observacao,
    editAgendamento,
    step4Ref,
    paciente,
    observacao,
    recorrencia,
    control,
    errors.intervalo?.message,
    errors.horario?.message,
    errors.diaSemana?.message,
    errors.diaMes?.message,
    errors.semanaMes?.message,
    errors.dataInicio?.message,
    errors.dataFim?.message,
    watchTipoRecorrencia,
    tipoMensal,
    semanaMes,
    diaSemana,
    agendamentoRapidoNome,
  ]);

  if (!consultorios?.ativo?.id) {
    return (
      <div className="p-p-5">
        <Button
          btnType="green-link"
          label="Cancelar e voltar para a agenda"
          icon="pi pi-arrow-left"
          onClick={() => {
            navigate('/agenda', {
              replace: true,
              state: { visualizarAgenda: voltarParaAgenda },
            });
          }}
        />
        <AlertBox
          visible={true}
          text="Para realizar um agendamento, você deve fazer parte de um consultório e tê-lo selecionado. Para mais informações, consulte o administrador do sistema."
        />
      </div>
    );
  }

  return (
    <Page className="NovoAgendamento p-flex-column p-flex-lg-row">
      <>
        <div className="content">
          <Button
            btnType="green-link"
            label="Cancelar e voltar para a agenda"
            icon="pi pi-arrow-left"
            style={{
              justifyContent: 'start',
              paddingLeft: '0',
            }}
            onClick={() => {
              if (screen === 'Encaixe') {
                return setExibirModalSairSemSalvar(prev => !prev);
              } else {
                navigate('/agenda', {
                  replace: true,
                  state: { visualizarAgenda: voltarParaAgenda },
                });
              }
            }}
          />
          <div className="header">
            {editAgendamento ? (
              <SimpleText fontSize={FONT_SIZE.MD} bold>
                Editar Agendamento
              </SimpleText>
            ) : (
              <SimpleText fontSize={FONT_SIZE.MD} bold>
                Novo {screen === 'Encaixe' ? 'Encaixe' : 'Agendamento'}
              </SimpleText>
            )}

            <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.SM}>
              Agenda uma consulta para seus pacientes.
            </SimpleText>
          </div>
          {screen === 'Encaixe' || editAgendamento?.atendimentoRecorrente
            ? renderStep1Encaixe()
            : renderStep1()}
          <Separator spaceOnly vertical />
          {renderStep2()}
          {!agendamentoRapidoNome && (
            <>
              <Separator spaceOnly vertical />
              {renderStep3()}
            </>
          )}
          <Separator spaceOnly vertical />
          {renderStep4()}
        </div>

        <NovoAgendamentoResumo
          recorrencia={recorrencia}
          internalDateTime={internalDateTime}
          selectedGrupo={selectedGrupo}
          paciente={paciente}
          contato={contato}
          observacao={observacao}
          periodoRecorrencia={tipoRecorrenciaLabel(
            watchTipoRecorrencia,
            watchRecorrenciaDias,
          )}
          handleAgendamentoRecorrente={handleAgendamentoRecorrente}
          handleAgendamento={() =>
            hasToVerifyElegibilidade
              ? handleVerifyElegibilidade()
              : handleAgendamento()
          }
          tipoOcorrencia={watchTipoRecorrencia}
          horario={horario}
          horarioRecorrencia={watchHorarioRecorrencia}
          handleSubmit={handleSubmit}
        />

        {exibirModalDisclosure.isOpen && (
          <Dialog
            visible={exibirModalDisclosure.isOpen}
            position={'center'}
            closable
            dismissableMask={true}
            closeOnEscape={true}
            header={'Horários disponíveis'}
            onHide={() => exibirModalDisclosure.close()}
          >
            <EscolhaHorarioLivre
              onSelect={agendamento => {
                const novaData = dateAndHourToDate(
                  new Date(agendamento.dataAgendamento),
                  dayjs(agendamento?.dataAgendamento).format('HH:mm'),
                );
                setInternalDateTime(novaData);
                exibirModalDisclosure.close();
              }}
              editAgendamento={editAgendamento}
              onHide={() => exibirModalDisclosure.close()}
            />
          </Dialog>
        )}

        {modalOutrosDisclosure.isOpen && (
          <ModalOutros
            setPacienteEspera={(value: any) => [
              setPaciente(value),
              handleSetPaciente(value),
            ]}
            setIdListaEspera={setIdListaEspera}
            setSelectedGrupo={setSelectedGrupo}
            onSelectPaciente={onSelectPaciente}
            {...modalOutrosDisclosure}
          />
        )}

        <Dialog
          visible={exibirModalSairSemSalvar}
          className="sair-encaixe"
          closable
          dismissableMask={true}
          closeOnEscape={true}
          header={'O encaixe será descartado'}
          onHide={() => {
            setExibirModalSairSemSalvar(prev => !prev);
          }}
        >
          <div className="content-sair">
            <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
              Você não agendou nenhum paciente no encaixe. Você realmente deseja
              sair? Os campos preenchidos e o horário gerado serão perdidos.
            </SimpleText>
            <div className="p-d-flex p-gap-2">
              <Button
                label="Cancelar"
                btnType={'ghost'}
                onClick={() => {
                  setExibirModalSairSemSalvar(prev => !prev);
                }}
                stretch
              />
              <Button
                label="Sim, descartar"
                btnType="danger"
                onClick={() => {
                  navigate('/agenda', {
                    replace: true,
                    state: { visualizarAgenda: voltarParaAgenda },
                  });
                }}
                stretch
              />
            </div>
          </div>
        </Dialog>

        <ModalEncaixeRecorrente
          {...modalEncaixeDisclosure}
          setEncaixeHorario={setEncaixeHorario}
          horariosIndisponiveis={horariosIndisponiveis}
        />

        {dialogTissErroOperadora.isOpen && (
          <DialogErroComunicacao
            onRetry={handleVerifyElegibilidade}
            onAgendar={() =>
              recorrencia
                ? handleAgendamentoRecorrente(recorrencia)
                : handleAgendamento()
            }
            {...dialogTissErroOperadora}
          />
        )}
        {dialogTissPacienteNaoElegivel.isOpen && (
          <DialogPacienteNaoElegivel
            handleAgendar={() =>
              recorrencia
                ? handleAgendamentoRecorrente(recorrencia)
                : handleAgendamento()
            }
            {...dialogTissPacienteNaoElegivel}
          />
        )}
        {dialogComunicacaoTiss.isOpen && (
          <DialogComunicacao
            onTimeout={() => dialogTissErroOperadora.open()}
            {...dialogComunicacaoTiss}
          />
        )}
        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </>
    </Page>
  );
};

export default NovoAgendamento;
