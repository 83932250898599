import { useEffect, useState, useCallback, useContext } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import FormInput from 'src/components/FormInput/FormInput';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Separator from 'src/components/Separator/Separator';

import { FieldSearchCliente } from './FieldSearchCliente';

import './AddParametro.scss';

const formFields = {
  codigo: '',
  descricao: '',
  opcoes: '',
  valor: '',
  personalizacao: '',
  listaClientes: [],
};

const AddParametro = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const params = useParams();

  const theme = useContext(ThemeContext);

  const validationSchema = Yup.object().shape({
    codigo: Yup.string().required('Parametro é obrigatório'),
    descricao: Yup.string().required('Descrição é obrigatória'),
    opcoes: Yup.string()
      .required('Opção é obrigatória')
      .typeError('O campo opções é obrigatório'),
    valor: Yup.string().required('Valor é obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = form;

  const [parametro, setParametro] = useState({} as any);
  const [listValorPersonalizado, setListaValorPersonalizado] = useState<any>(
    [],
  );

  const [temParametro, setTemParametro] = useState(false);

  const confirmDialog = useDisclosure({ opened: false });

  const loadParametro = useCallback(async () => {
    if (params?.id) {
      try {
        const idParametro = parseInt(params?.id);

        const { listaCliente, ...parametroEncontrado }: Parametro =
          await ParametroAPI.loadParametroById(idParametro, {
            throwError: true,
          });

        setValue('codigo', parametroEncontrado.codigo);
        setValue('descricao', parametroEncontrado.descricao);
        setValue('opcoes', parametroEncontrado.opcoes);
        setValue('valor', parametroEncontrado.valor);

        listaCliente.forEach((client, idx) => {
          setValue(`cliente.${idx}`, {
            razaoSocial: client.razaoSocial,
            id: client.idEmpresa,
          });
        });

        setListaValorPersonalizado(listaCliente);
        setParametro(parametroEncontrado);
      } catch (error) {
        window.history.back();
      }
    }
  }, [params, setValue]);

  useEffect(() => {
    loadParametro();
  }, [loadParametro]);

  useEffect(() => {
    if (params?.id) {
      setTemParametro(true);
    } else {
      setTemParametro(false);
    }
  }, [params]);

  const handleAddParametro = useCallback(
    async (data: Partial<Parametro>) => {
      let response;

      if (temParametro) {
        const parametroExistente: PostParametroDTO = {
          ...parametro,
          codigo: data.codigo!,
          descricao: data.descricao!,
          opcoes: data.opcoes!,
          valor: data.valor!,
          personalizacao: data.personalizacao!,
          listaCliente: listValorPersonalizado,
        };

        response = await ParametroAPI.updateParametro(
          parametro.id,
          parametroExistente,
        );
      } else {
        const newData: PostParametroDTO = {
          codigo: data.codigo!,
          descricao: data.descricao!,
          opcoes: data.opcoes!,
          valor: data.valor!,
          personalizacao: data.personalizacao!,
          listaCliente: listValorPersonalizado,
        };

        response = await ParametroAPI.sendParametro(newData);
      }

      if (response?.id) {
        reset(formFields);
        navigate(-1);
      }
    },
    [temParametro, parametro, listValorPersonalizado, reset, navigate],
  );

  const handleCancelAddParametro = useCallback(() => {
    navigate('/config/parametros');
  }, [navigate]);

  const addValorPersonalizado = useCallback(() => {
    setListaValorPersonalizado([...listValorPersonalizado, { valor: '' }]);
  }, [listValorPersonalizado]);

  const removeValorPersonalizado = useCallback(
    (index: any) => {
      if (temParametro) {
        confirmDialog.open({ state: index });
      } else {
        const valPersonal = [...listValorPersonalizado];

        valPersonal.splice(index, 1);

        setListaValorPersonalizado(valPersonal);
      }
    },
    [confirmDialog, listValorPersonalizado, temParametro],
  );

  const removeValorPersonalizadoModal = useCallback(
    (index: number) => {
      const valPersonal = [...listValorPersonalizado];

      valPersonal.splice(index, 1);

      setListaValorPersonalizado(valPersonal);
      confirmDialog.close();
    },
    [confirmDialog, listValorPersonalizado],
  );

  const handleChangeValorPersonalizado = useCallback(
    (e: any, index: any) => {
      const aux: any = [...listValorPersonalizado];

      if (e?.id) {
        aux[index] = {
          ...e,
          idUsuario: user?.idUsuario,
          idEmpresa: e.id,
          nomeCliente: e?.nome,
        };
      } else {
        aux[index]['valor'] = e.target.value;
      }
      setListaValorPersonalizado(aux);
    },
    [listValorPersonalizado, user],
  );

  const operadores = useCallback(() => {
    return (
      <div className="Operadores">
        {listValorPersonalizado?.map((operador: any, index: any) => {
          return (
            <div key={operador.id || generateUniqueId()}>
              <div className=" p-d-flex p-flex-row">
                <FieldSearchCliente
                  name={`cliente.${index}`}
                  label="Valor personalizado"
                  className="p-col-6"
                  customOnChange={value =>
                    handleChangeValorPersonalizado(value, index)
                  }
                />
                <div className="p-col-6">
                  <TextInput
                    className="p-field p-mb-0"
                    name="valor"
                    onChange={e => handleChangeValorPersonalizado(e, index)}
                    value={operador.valor}
                    label="Valor"
                  />
                </div>
              </div>

              <Button
                label="Remover"
                icon="fas fa-minus"
                type="button"
                btnType="danger-link"
                onClick={() => removeValorPersonalizado(index)}
              />
              <Separator />
            </div>
          );
        })}

        <Button
          label="Adicionar valor personalizado"
          icon="fas fa-plus"
          type="button"
          btnType="green-link"
          onClick={addValorPersonalizado}
        />
      </div>
    );
  }, [
    addValorPersonalizado,
    handleChangeValorPersonalizado,
    removeValorPersonalizado,
    listValorPersonalizado,
  ]);

  return (
    <Page className={`container Config ${theme?.theme || ''}`} white>
      <>
        <PageHeader
          title={params?.id ? 'Editar parâmetro' : 'Novo parâmetro'}
          subtitle={''}
        />
        <div className="modal-body">
          <form
            onSubmit={handleSubmit(handleAddParametro)}
            defaultValue={parametro}
            onReset={() => reset(formFields)}
            className="p-col-12 p-lg-6"
          >
            <FormProvider {...form}>
              <FormInput
                name="codigo"
                label="Parâmetro"
                className={'p-col-12'}
              />
              <FormInput
                name="descricao"
                label="Descrição"
                className={'p-col-12'}
              />
              <FormInput name="opcoes" label="Opções" className={'p-col-12'} />
              <FormInput
                name="valor"
                label="Valor global"
                className={'p-col-12'}
              />

              <Separator />

              {operadores()}

              <div className="p-grid p-col-12">
                <div className="p-col-4">
                  <Button
                    btnType="ghost"
                    label="Cancelar"
                    className="p-col-align-center p-d-flex"
                    onClick={handleCancelAddParametro}
                    loading={isSubmitting}
                    stretch
                  />
                </div>
                <div className="p-col-8">
                  <Button
                    label="Salvar"
                    type="submit"
                    stretch
                    loading={isSubmitting}
                  />
                </div>
              </div>
            </FormProvider>
          </form>
        </div>

        {confirmDialog.isOpen && (
          <ConfirmDialog
            visible={confirmDialog.isOpen}
            header="Excluir personalização"
            text="Você tem certeza que deseja excluir a personalização do valor deste parâmetro para o cliente selecionado?"
            onConfirm={() => {
              removeValorPersonalizadoModal(confirmDialog.state);
            }}
            onHide={confirmDialog.close}
          />
        )}
      </>
    </Page>
  );
};

export default AddParametro;
