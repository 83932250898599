import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  data: any;
  edit: JSX.Element;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { data, edit } = props;

  return (
    <CardItem>
      <div className="p-col-3 p-md-4 user">
        <Button icon={'pi pi-user'} btnType="gray" />

        <div className="user-info">
          <SimpleText fontSize={FONT_SIZE.XS} bold>
            {data?.nome}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXXS}>{data?.tipo}</SimpleText>
        </div>
      </div>
      <div className="p-col-2 p-md-2">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS}>{data?.login}</SimpleText>
        </div>
      </div>
      <div className="p-col-3 p-md-4">
        {data?.empresa !== null ? (
          <div>
            <SimpleText fontSize={FONT_SIZE.XXS}>
              {data?.empresa.nome}
            </SimpleText>
          </div>
        ) : null}
      </div>

      {edit}
    </CardItem>
  );
};
