import { useMemo } from 'react';

import dayjs from 'dayjs';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import CardAgendamento from 'src/pages/Emed/Agenda/MenuAgenda/AgendamentoListItem/CardAgendamento/CardAgendamento';
import Sigilo from 'src/pages/Emed/Agenda/Sigilo/Sigilo';

import 'dayjs/locale/pt-br';
import './AgendaDia.scss';
import { useAgenda } from '../../AgendaContext';

dayjs().format();

interface AgendaDiaProps {
  selectedDate: Date;
  checkbox: boolean;
  selectAll: boolean;
}

const AgendaDia = (props: any) => {
  const { selectedDate, checkbox, selectAll }: AgendaDiaProps = props;
  const { agendaDiaItems, setSelectedAgendamentos } = useAgenda();

  useEffectSkipFirst(() => {
    const data = agendaDiaItems?.map((itemAgenda: any) => {
      return {
        ...itemAgenda,
        checkbox: !!selectAll,
      };
    });

    if (selectAll) {
      setSelectedAgendamentos(
        data.filter(
          item => item.status === 'LIVRE' || item.status === 'BLOQUEADO',
        ),
      );
    } else {
      setSelectedAgendamentos([]);
    }
  }, [selectAll]);

  const agendamentosLivresEBloqueados = useMemo(() => {
    if (!agendaDiaItems?.length) return [];

    return agendaDiaItems.filter(
      el => el.status === 'LIVRE' || el.status === 'BLOQUEADO',
    );
  }, [agendaDiaItems]);

  return (
    <div className="AgendaDia">
      {checkbox ? (
        <>
          {!!agendamentosLivresEBloqueados.length ? (
            agendamentosLivresEBloqueados.map((item: any, idx: number) => (
              <div className="row" key={idx}>
                <CardAgendamento
                  checkbox={checkbox}
                  agendamento={item}
                  selectedDate={selectedDate}
                  sigilo={<Sigilo atendimento={item} />}
                />
              </div>
            ))
          ) : (
            <div className="noContent">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                Nenhum horário disponível nesta data.
              </SimpleText>
            </div>
          )}
        </>
      ) : (
        <>
          {!!agendaDiaItems?.length &&
            agendaDiaItems.map((v: any, i: number) => {
              return (
                <div key={i} className="row">
                  <CardAgendamento
                    checkbox={checkbox}
                    agendamento={v}
                    selectedDate={selectedDate}
                    sigilo={<Sigilo atendimento={v} />}
                  />
                </div>
              );
            })}
        </>
      )}

      {agendaDiaItems.length === 0 && (
        <div className="noContent">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Nenhum horário disponível nesta data.
          </SimpleText>
        </div>
      )}
    </div>
  );
};

export default AgendaDia;
