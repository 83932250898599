import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { FieldGrupoTuss } from './components/FieldGrupoTuss/FieldGrupoTuss';
import { FieldGuiaTiss } from './components/FieldGuiaTiss/FieldGuiaTiss';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { TextInputSearchControlled } from 'src/components/Basics/TextInputSearch/TextInputSearchControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';

import './ModalFiltroServicoTuss.scss';

const ModalFiltroServicoTuss = () => {
  const [listaClasses, setListaClasses] = useState<any>([]);
  const useFormMethods = useForm();

  const { getValues, reset, control, watch, resetField } = useFormMethods;

  const loadClasses = useCallback(async () => {
    const responseClasses = await ServicoTussCRUDAPI.loadClassesByServicoTuss();

    const classesFormatadas = responseClasses?.map(m => {
      return {
        label: m,
        value: m,
      };
    });

    setListaClasses(classesFormatadas);
  }, []);

  useEffect(() => {
    loadClasses();
  }, [loadClasses]);

  const handleFilter = () => {
    const { nome, convenio, guiaTISS, grupoTUSS, status, tipo, classe } =
      getValues();

    return {
      tipo,
      classe,
      idConvenio: convenio?.id,
      idGuiaTISS: guiaTISS?.id,
      idGrupoTUSS: grupoTUSS?.id,
      status: status ? (status === 'ATIVO' ? true : false) : undefined,
      ...(!!nome && { nome: nome }),
    };
  };

  const handleClearFilters = () => {
    reset({
      nome: '',
      tipo: '',
      classe: '',
      convenio: '',
      guiaTISS: '',
      grupoTUSS: '',
      status: undefined,
    });
  };

  const watchConvenio = watch('convenio');

  return (
    <SearchListFilter
      isSearch
      btnType="tonal"
      onFilter={handleFilter}
      onClearFilters={handleClearFilters}
    >
      <FormProvider {...useFormMethods}>
        <form className="p-mt-2">
          <TextInputSearchControlled
            className="p-mx-2 p-mb-2"
            name="nome"
            placeholder="Código ou nome TUSS"
          />

          <FieldConvenio label="Convênio" placeholder="Selecione" />

          <FieldGuiaTiss
            name="guiaTISS"
            label="Guia TISS"
            params={{ idConvenio: watchConvenio?.id }}
            placeholder="Selecione"
          />

          <FieldGrupoTuss
            name="grupoTUSS"
            label={'Grupo TUSS'}
            params={{ idConvenio: watchConvenio?.id }}
            placeholder="Selecione"
          />

          <DropdownControlled
            name="classe"
            className={'p-col-12'}
            label={'Classe'}
            control={control}
            options={listaClasses}
            placeholder="Selecione"
          />

          <DropdownControlled
            name="status"
            className={'p-col-12'}
            label={'Situação'}
            control={control}
            placeholder="Selecione"
            filter={false}
            options={[
              { label: 'Ativo', value: 'ATIVO' },
              { label: 'Inativo', value: 'INATIVO' },
            ]}
          />
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};

export default ModalFiltroServicoTuss;
