import { useState, useCallback, useRef, useEffect, useMemo } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Control, useController } from 'react-hook-form';

import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';

import './DropdownCiap.scss';

interface DropdownCiapProps {
  control: Control;
  getCiaps: (idAtendimento: any) => void;
  idAtendimento: any;
}

const DropdownCiap = ({
  control,
  getCiaps,
  idAtendimento,
}: DropdownCiapProps) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const { agenda } = useAppSelector((state: RootState) => state);

  const {
    field: { value },
    formState: { errors },
  } = useController({
    name: 'ciap',
    control,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [ciapSelecionada, setCiapSelecionada] = useState<any>(null);
  const [favoritos, setFavoritos] = useState<any[]>([]);

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const loadFavoritos = useCallback(async () => {
    const response = await ComorbidadeAPI.getFavoritos(
      agenda?.profissionalAtivo?.id,
    );

    if (Array.isArray(response) && !!response.length) setFavoritos(response);
  }, [agenda?.profissionalAtivo?.id]);

  useEffect(() => {
    loadFavoritos();
  }, [loadFavoritos]);

  const loadCiaps = useCallback(
    async (params: any) => {
      return await UtilsAPI.getCiaps({
        nome: searchQuery,
        codigo: searchQuery,
        ...params,
      });
    },
    [searchQuery],
  );

  const fetchAPI = useCallback(
    (params: any) => {
      if (searchQuery?.length > 0) return loadCiaps(params);

      return { list: [] };
    },
    [loadCiaps, searchQuery],
  );

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const onSelectValue = async (ciap: any) => {
    const idCiaps = [ciap?.id];
    const response = await UtilsAPI.postAddCiap(idAtendimento, idCiaps);
    if (response?.status === 201) {
      getCiaps(String(idAtendimento));
      searchPanelRef.current?.hide();
    }
  };

  const handleFavoritar = async (ciap: any) => {
    const response = await ComorbidadeAPI.favoritarComorbidade(
      agenda?.profissionalAtivo?.id,
      { idCiap: ciap.id },
    );

    if (response?.status === 200) loadFavoritos();
  };

  useEffect(() => {
    setCiapSelecionada(value?.value?.[0]?.[value.name] || null);
  }, [value]);

  const ciapsFavoritos = useMemo(
    () =>
      favoritos
        .filter(favorito => !!favorito?.idCiap)
        .map(favorito => favorito.ciap.nome),
    [favoritos],
  );

  const renderRow = (ciap: any) => {
    const favoritado = ciapsFavoritos.includes(ciap.nome);

    return (
      <div className="ciap-item p-grid p-col-12">
        <div
          className="p-col-11 p-grid p-p-0"
          onClick={() => onSelectValue(ciap)}
        >
          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            {ciap.codigo} - {capitalizeFirstLetter(ciap.nome.toLowerCase())}
          </SimpleText>
        </div>
        <div
          className="p-col-1 p-d-flex p-jc-center p-ai-center"
          onClick={() => handleFavoritar(ciap)}
        >
          <i
            className={`fas fa-star favoritar-button ${
              favoritado ? 'favoritado' : ''
            }`}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-col-12">
      <div ref={searchInputContainerRef} className="p-col-12">
        <TextInputSearch
          placeholder="Pesquisar CIAP"
          value={searchQuery}
          updateValue={ciapSelecionada?.nome || ''}
          onChange={setSearchQuery}
          onChangeEvent={e =>
            e.target.value.length >= 2
              ? searchPanelRef.current?.show(e, null)
              : searchPanelRef.current?.hide()
          }
        />
      </div>

      <TextInputControlled control={control} name="ciap" hidden />

      {errors?.ciap?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors?.ciap?.message}
        </SimpleText>
      )}

      <OverlayPanel
        className="ciap-search-panel"
        ref={searchPanelRef}
        dismissable={false}
      >
        <div className="p-grid" ref={searchPanelContainerRef}>
          <div id="search-results-container" className="p-col-12">
            <InfiniteScroll fetchAPI={fetchAPI} renderRow={renderRow} />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DropdownCiap;
