import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { Header as SearchListHeader } from 'src/components/SearchList/SearchListRoot';

export const Header = () => (
  <SearchListHeader>
    <div className="p-grid">
      <SimpleText
        className="p-col-2 p-lg-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Código
      </SimpleText>
      <SimpleText
        className="p-col"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Nome
      </SimpleText>

      <SimpleText
        className="p-col-3"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Convênio
      </SimpleText>

      <SimpleText
        className="p-col-2 p-md-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Status
      </SimpleText>

      <SimpleText
        className="p-col-2 p-lg-1 p-text-center"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Ações
      </SimpleText>
    </div>
  </SearchListHeader>
);
