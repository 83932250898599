import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface Props {
  loading?: boolean;
  porcentagem?: number | undefined;
  className?: string;
  size: 'small' | 'large';
}

export default React.memo(function PorcentagemRisco({
  porcentagem,
  className,
  loading,
  size,
}: Props) {
  return (
    <Skeleton
      loading={loading || false}
      width="4rem"
      height="4rem"
      borderRadius="50%"
    >
      <div
        className={`p-md-3 p-d-flex p-ai-center p-jc-center risco-cardiaco-circle ${size} ${className}`}
      >
        <SimpleText
          fontSize={size === 'small' ? FONT_SIZE.SM : FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
          className="p-d-flex p-ai-center p-jc-center"
        >
          {porcentagem ? `${porcentagem}%` : '--%'}
        </SimpleText>
      </div>
    </Skeleton>
  );
});
