import Avatar from 'src/components/Avatar/Avatar';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

import './ListItemPaciente.scss';
import { calcularIdade } from 'src/utils/date';

interface ListItemPacienteProps {
  paciente: any;
  isList: boolean;
  onClick?: () => void;
  handleRemove?: () => void;
}

const ListItemPaciente = ({
  paciente,
  onClick,
  handleRemove,
  isList,
}: ListItemPacienteProps) => {
  const contactNumber =
    paciente?.contatos?.[0]?.telefone || paciente?.contatos?.[0]?.numero;

  return (
    <div
      key={paciente?.id}
      className={`PacienteItems ${isList ? 'isList' : ''}`}
      onClick={onClick}
    >
      <div className="p-col-7 left-info">
        <Avatar
          label={paciente?.nome}
          image={paciente?.urlFoto || ''}
          className="avatar"
        />

        <div className={'pacienteInfos'}>
          <SimpleText
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_40}
            className="p-mb-1"
          >
            {paciente?.sexo?.descricao === 'MASCULINO'
              ? 'Masculino'
              : 'Feminino'}
          </SimpleText>
          <SimpleText className={'text'} fontSize={FONT_SIZE.XXS} bold>
            {paciente?.nome}
          </SimpleText>
          <div className="p-d-flex p-ai-center p-gap-1 ">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {calcularIdade(paciente?.dataNascimento)}
            </SimpleText>
            {contactNumber && (
              <div className="p-d-flex p-ai-center">
                <span className="p-pr-1">•</span>
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {contactNumber}
                </SimpleText>
              </div>
            )}
          </div>
        </div>
      </div>

      {isList && (
        <div className="p-col-2">
          <span className="presenca">
            {paciente?.numeroPresencas < 10
              ? `0${paciente?.numeroPresencas}`
              : paciente?.numeroPresencas || '00'}
          </span>
        </div>
      )}

      {isList && (
        <div className="p-col-2">
          <Switch checked={paciente?.status} />
        </div>
      )}

      {isList && (
        <div className="p-col-1">
          <IconButton
            icon="fas fa-trash"
            btnType={IconBtnTypes.GREY}
            onClick={handleRemove}
            className="icon"
          />
        </div>
      )}
    </div>
  );
};

export default ListItemPaciente;
