import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

const Header = () => {
  return (
    <div className="p-grid p-col-12 p-p-0">
      <SimpleText
        className="p-col-6"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Descrição
      </SimpleText>

      <SimpleText
        className="p-col-4 p-d-flex"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Status
      </SimpleText>

      <SimpleText
        className="p-col-2 p-text-right"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Ações
      </SimpleText>
    </div>
  );
};

export default Header;
