import { useCallback } from 'react';

import dayjs from 'dayjs';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import FormNovoPaciente from 'src/components/CadastroRapidoPaciente/FormNovoPaciente/FormNovoPaciente';
import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import AgendamentoGrupo from './AgendamentoGrupo/AgendamentoGrupo';
import ListaEspera from './ListaEspera/ListaEspera';
import {
  PacienteDuplicadoModal,
  PacientesSelemelhantesModal,
} from './ModalPacientes/ModalPacientes';

import './ModalOutros.scss';

interface ModalOutrosProps extends DisclosureType {
  setPacienteEspera: any;
  setSelectedGrupo: any;
  setIdListaEspera: any;
  onSelectPaciente: any;
}

const ModalOutros = ({
  isOpen,
  close,
  state,
  setPacienteEspera,
  setSelectedGrupo,
  setIdListaEspera,
  onSelectPaciente,
}: ModalOutrosProps) => {
  const modalPacientesDuplicados = useDisclosure({ opened: false });
  const modalPacientesSemelhantes = useDisclosure({ opened: false });

  const handleCreatePaciente = useCallback(
    async (dados: any) => {
      const dadosPaciente = await PacienteAPI.criarPaciente(dados);

      if (dadosPaciente?.status !== 400) {
        close();
        onSelectPaciente(dadosPaciente);
      }
    },
    [close, onSelectPaciente],
  );

  const handleSubmit = useCallback(
    async (d: any) => {
      const data = {
        ...d,
        cpf: d.cpf || null,
        nomeMae: !d.maeDesconhecida ? d.nomeMae : null,
        dataNascimento: dayjs(d.dataNascimento).format('YYYY-MM-DD'),
        idConvenio: d?.convenio?.id || null,
        idTipoPlano: d?.tipoPlano?.id,
      };

      const { pacientesDuplicados, pacientesSemelhantes } =
        await PacienteAPI.pacientesSemelhantes(data);

      if (!!pacientesDuplicados?.length) {
        return modalPacientesDuplicados.open({
          state: pacientesDuplicados,
        });
      }

      if (!!pacientesSemelhantes?.length) {
        return modalPacientesSemelhantes.open({
          state: {
            pacientesSemelhantes,
            onProceed: () => {
              handleCreatePaciente({
                ...data,
                dataNacimento: dayjs(data.dataNacimento).format('YYYY-MM-DD'),
              }).then(() => onSelectPaciente(data));
            },
          },
        });
      }

      return handleCreatePaciente({
        ...data,
        dataNacimento: dayjs(data.dataNacimento).format('YYYY-MM-DD'),
      });
    },
    [
      handleCreatePaciente,
      modalPacientesDuplicados,
      modalPacientesSemelhantes,
      onSelectPaciente,
    ],
  );

  const handleCancel = () => {
    close();
  };

  return (
    <Dialog
      header="Agendamento"
      visible={isOpen}
      onHide={close}
      position={'center'}
      type="modal"
      maximizedTabletSize
      id="modal-outros"
    >
      <div className="modal-outros">
        {modalPacientesDuplicados.isOpen && (
          <PacienteDuplicadoModal
            onSelect={onSelectPaciente}
            setExibirModal={close}
            {...modalPacientesDuplicados}
          />
        )}

        {modalPacientesSemelhantes.isOpen && (
          <PacientesSelemelhantesModal
            onSelect={onSelectPaciente}
            setExibirModal={close}
            {...modalPacientesSemelhantes}
          />
        )}

        <Tab
          clean
          className="p-mt-1"
          initialTabIndex={state.activeTab || 0}
          values={[
            {
              label: 'Lista de espera',
              content: (
                <ListaEspera
                  onHide={close}
                  setIdListaEspera={setIdListaEspera}
                  setPacienteEspera={setPacienteEspera}
                />
              ),
            },
            {
              label: 'Novo paciente',
              content: (
                <FormNovoPaciente
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              ),
            },

            {
              label: 'Grupo',
              content: (
                <AgendamentoGrupo
                  onHide={close}
                  selectedGrupo={setSelectedGrupo}
                />
              ),
            },
          ]}
        />
      </div>
    </Dialog>
  );
};

export default ModalOutros;
