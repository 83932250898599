import {
  getPacientesVacinasEndpoint,
  adicionarVacinaEndpoint,
  alterarPacienteVacinaEndpoint,
  alterarPacienteVacinaLembreteEndpoint,
  inativarPacienteVacinaEndpoint,
  removerPacienteVacinaEndpoint,
  getVacinaEndpoint,
} from './VacinasEndpoints';

const getPacientesVacinas = async (params: any): Promise<Paginator<PacientesVacinas>> => (
  await getPacientesVacinasEndpoint(params)
);

const adicionarVacina = async (payload: any): Promise<any> => (
  await adicionarVacinaEndpoint(payload)
);

const alterarPacienteVacina = async (
  idPacienteVacina: number,
  payload: any
): Promise<any> => (
  await alterarPacienteVacinaEndpoint(idPacienteVacina, payload)
);

const alterarPacienteVacinaLembrete = async (
  idPacienteVacina: number
): Promise<any> => (
  await alterarPacienteVacinaLembreteEndpoint(idPacienteVacina)
);

const inativarPacienteVacina = async (
  idPacienteVacina: number,
  payload: any
): Promise<any> => (
  await inativarPacienteVacinaEndpoint(idPacienteVacina, payload)
);

const removerPacienteVacina = async (
  idPacienteVacina: number
): Promise<any> => (
  await removerPacienteVacinaEndpoint(idPacienteVacina)
);

const getVacina = async (): Promise<Vacinas[]> => (
  await getVacinaEndpoint()
);

const VacinasAPI = {
  getPacientesVacinas,
  adicionarVacina,
  alterarPacienteVacina,
  alterarPacienteVacinaLembrete,
  inativarPacienteVacina,
  removerPacienteVacina,
  getVacina,
}

export default VacinasAPI;