import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import './ValoresServico.scss';
import ValoresServicoContent from './ValoresServicoContent/ValoresServicoContent';

const ValoresServico = () => {
  return (
    <Page className={'ValoresServico'}>
      <>
        <PageHeader
          title={'Consultar valores de serviço'}
          subtitle={
            'Cadastre o valor dos serviços disponibilizados pela clínica'
          }
        />
        <ValoresServicoContent />
      </>
    </Page>
  );
};

export default ValoresServico;
