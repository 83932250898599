import { useCallback, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import { RadioButton } from 'src/components/_UI/RadioButton';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';

import './FormServicoTussStep2.scss';

const formFields = {
  registroAnvisa: '',
  quantidadePadrao: 0,
  valorCustoTotal: 0,
  versao: '',
  apelido: '',
  local: '',
  obrigatoriedadeIndicacaoClinica: false,
  obrigatoriedadeIndicacaoAcidente: false,
  bloquearFavoritos: false,
  consentimento: false,
  opme: false,
  anexoOpme: false,
  quimio: false,
  anexoQuimio: false,
  questionarioLab: false,
  questionarioImg: false,
  somenteInternacao: false,
  autoGeradoLoteGuia: false,
  obrigatoriedadeLaudo: false,
};

type DialogOPMEProps = DisclosureType & {
  setValue: any;
};
interface FormServicoTussStep2Props {
  onSubmit(v: Partial<ServicoTussComplementares>): void;
  values?: any;
}

const FormServicoTussStep2 = ({
  onSubmit,
  values,
}: FormServicoTussStep2Props) => {
  const navigate = useNavigate();
  const dialogOPME = useDisclosure({ opened: false });

  const [localRadio, setLocalRadio] = useState<any>('');

  const validationSchema = Yup.object().shape({
    // registroAnvisa: Yup.string().required('Registro Anvisa é obrigatório'),
    // quantidadePadrao: Yup.number().required('Quantidade Padrão é obrigatória'),
    // valorCustoTotal: Yup.number().required(
    //   'Valor do Custo Total é obrigatório',
    // ),
    // versao: Yup.string().required('Versão é obrigatória'),
    // apelido: Yup.string().required('Apelido é obrigatório'),

    local: Yup.string().nullable().required('Escolha um local para o Serviço'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = useCallback(() => {
    reset(formFields);
    navigate(-1);
  }, [reset, navigate]);

  const handleGrupoTuss = useCallback(
    async (data: any) => {
      onSubmit({
        ...data,
        obrigatoriedadeIndicacaoClinica: data.obrigatoriedadeIndicacaoClinica
          ? data.obrigatoriedadeIndicacaoClinica
          : false,
        obrigatoriedadeIndicacaoAcidente: data.obrigatoriedadeIndicacaoAcidente
          ? data.obrigatoriedadeIndicacaoAcidente
          : false,
        bloquearFavoritos: data.bloquearFavoritos
          ? data.bloquearFavoritos
          : false,
        consentimento: data.consentimento ? data.consentimento : false,
        opme: data.opme ? data.opme : false,
        anexoOpme: data.anexoOpme ? data.anexoOpme : false,
        quimio: data.quimio ? data.quimio : false,
        anexoQuimio: data.anexoQuimio ? data.anexoQuimio : false,
        questionarioLab: data.questionarioLab ? data.questionarioLab : false,
        questionarioImg: data.questionarioImg ? data.questionarioImg : false,
        somenteInternacao: data.somenteInternacao
          ? data.somenteInternacao
          : false,
        autoGeradoLoteGuia: data.autoGeradoLoteGuia
          ? data.autoGeradoLoteGuia
          : false,
        obrigatoriedadeLaudo: data.obrigatoriedadeLaudo
          ? data.obrigatoriedadeLaudo
          : false,
      });
    },
    [onSubmit],
  );

  useEffect(() => {
    if (values !== undefined || Object.keys(values).length > 0) {
      // setValue('registroAnvisa', values.registroAnvisa);
      setValue('quantidadePadrao', values.quantidadePadrao);
      setValue('valorCustoTotal', values.valorCustoTotal);
      setValue('apelido', values.apelido);
      setValue('local', values.local);

      setLocalRadio(values.local);
      setValue(
        'obrigatoriedadeIndicacaoClinica',
        values.obrigatoriedadeIndicacaoClinica,
      );
      setValue(
        'obrigatoriedadeIndicacaoAcidente',
        values.obrigatoriedadeIndicacaoAcidente,
      );
      setValue('bloquearFavoritos', values.bloquearFavoritos);
      setValue('consentimento', values.consentimento);
      setValue('opme', values.opme);
      setValue('anexoOpme', values.anexoOpme);
      setValue('bloquearMaisSolicitados', values.bloquearMaisSolicitados);
      setValue('quimio', values.quimio);
      setValue('anexoQuimio', values.anexoQuimio);
      setValue('questionarioLab', values.questionarioLab);
      setValue('questionarioImg', values.questionarioImg);
      setValue('somenteInternacao', values.somenteInternacao);
      setValue('autoGeradoLoteGuia', values.autoGeradoLoteGuia);
      setValue('obrigatoriedadeLaudo', values.obrigatoriedadeLaudo);
    }
  }, [reset, setValue, values]);

  const isOPME = watch('opme');

  useEffectSkipFirst(() => {
    if (!isOPME && values.opme) {
      dialogOPME.open();
    }
  }, [isOPME, values.opme]);

  return (
    <form
      className="step2-servicotuss"
      onSubmit={handleSubmit(handleGrupoTuss)}
      defaultValue={''}
      onReset={() => reset(formFields)}
    >
      <div className={'row'}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <TextInput
              label="Quantidade padrão"
              className="grid p-col-6 p-md-6"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              errorMsg={errors.quantidadePadrao?.message}
              type="number"
              min={1}
            />
          )}
          name="quantidadePadrao"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <TextInput
              label="Custo total"
              className="grid p-col-6 p-md-6"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              errorMsg={errors.valorCustoTotal?.message}
              type="number"
              min={1}
            />
          )}
          name="valorCustoTotal"
        />
      </div>

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }: any) => (
          <TextInput
            label="Apelido"
            className="grid p-col-12 p-md-12"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            errorMsg={errors.apelido?.message}
          />
        )}
        name="apelido"
      />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange } }: any) => (
          <div className="radioinput">
            <SimpleText bold>Local</SimpleText>

            <div className="p-d-flex p-flex-column p-flex-sm-row">
              <RadioButton
                className="p-mr-0 p-mr-sm-3"
                onChange={() => {
                  setLocalRadio('CONSULTORIO');
                  onChange('CONSULTORIO');
                }}
                label="Consultório"
                name="CONSULTORIO"
                checked={localRadio === 'CONSULTORIO'}
              />
              <RadioButton
                className="p-mr-0 p-mr-sm-3"
                onChange={() => {
                  setLocalRadio('HOSPITAL');
                  onChange('HOSPITAL');
                }}
                label="Hospital"
                name="HOSPITAL"
                checked={localRadio === 'HOSPITAL'}
              />

              <RadioButton
                onChange={() => {
                  setLocalRadio('NAO_DEFINIDO');
                  onChange('NAO_DEFINIDO');
                }}
                label="Não definido"
                name="NAO_DEFINIDO"
                checked={localRadio === 'NAO_DEFINIDO'}
              />
            </div>

            <SimpleText className="errorMsg">
              {errors.local?.message}
            </SimpleText>
          </div>
        )}
        name="local"
      />

      <SimpleText className="p-mt-4 p-mb-2" bold>
        Parametrização
      </SimpleText>

      <div className="checkbox-servicos-tuss-container">
        <CheckboxControlled
          control={control}
          name="obrigatoriedadeIndicacaoClinica"
          label="Obrigatoriedade indicação clínica"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="obrigatoriedadeIndicacaoAcidente"
          label="Obrigatoriedade indicação acidente"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="bloquearFavoritos"
          label="Bloquear favoritos"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="consentimento"
          label="Consentimento"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="opme"
          label="OPME"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="anexoOpme"
          label="Anexo OPME"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="quimio"
          label="Quimioterapia"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="anexoQuimio"
          label="Anexo Quimioterapia"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="questionarioLab"
          label="Exibe Questionário na Solicitação Lab"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="questionarioImg"
          label="Exibe Questionário na Solicitação Img"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="somenteInternacao"
          label="Somente internação"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="autoGeradoLoteGuia"
          label="Auto gerado - Lote guia"
          className="checkboxParametros"
        />
        <CheckboxControlled
          control={control}
          name="obrigatoriedadeLaudo"
          label="Obrigatoriedade de Laudo"
          className="checkboxParametros"
        />
      </div>

      <div className={'p-d-flex p-grid p-col-12 p-px-0'}>
        <div className={'p-col-12 p-sm-3'}>
          <Button
            btnType="ghost"
            stretch
            label="Voltar"
            className={'p-col-align-center p-d-flex'}
            onClick={handleCancel}
          />
        </div>
        <div className={'p-col-12 p-sm-9'}>
          <Button
            stretch
            label={'Salvar'}
            loading={isSubmitting}
            type="submit"
            onClick={handleSubmit(handleGrupoTuss)}
            btnType="tonal"
          />
        </div>
      </div>

      {dialogOPME.isOpen && <DialogOPME setValue={setValue} {...dialogOPME} />}
    </form>
  );
};

const DialogOPME = ({ close, isOpen, setValue }: DialogOPMEProps) => {
  const handleCancelar = () => {
    setValue('opme', true);
  };

  return (
    <Dialog
      style={{ width: '480px' }}
      onHide={close}
      visible={isOpen}
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <div>
          <SimpleText>
            Esta opção irá excluir os fabricantes já cadastrados. Deseja
            realmente desmarcar e excluir os fabricantes?
          </SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => [close(), handleCancelar()]}
            stretch
          />
          <Button
            label="Continuar"
            onClick={() => close()}
            stretch
            btnType="tonal"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default FormServicoTussStep2;
