import { useCallback, useEffect, useState } from 'react';

import { getPacientesEmpresa } from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Skeleton from 'src/components/Skeleton/Skeleton';

import './ModalPaciente.scss';

interface ModalPcientesProps {
  onOpenModal: () => void;

  handleAdd: (paciente: any) => void;
  onHide: () => void;
  watchPacientes: any;
}

function ModalPacientes({
  handleAdd,
  onHide,
  watchPacientes,
}: ModalPcientesProps) {
  const [pacientes, setPacientes] = useState<GetPacienteDTO[]>([]);
  const [selectedPacientes, setSelectedPacientes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const loadPage = useCallback(async () => {
    const params = {
      chave: searchQuery.toLowerCase(),
    };
    const response: any = await getPacientesEmpresa(params);

    setPacientes(response.list || []);
  }, [searchQuery]);

  useEffect(() => {
    loadPage().then(() => setLoading(false));
  }, [loadPage]);

  const handleAdicionar = async () => {
    handleAdd(selectedPacientes);
    onHide();
  };

  useEffect(() => {
    if (watchPacientes !== undefined && watchPacientes?.length !== 0) {
      return setSelectedPacientes(watchPacientes);
    }
    return setSelectedPacientes([]);
  }, [watchPacientes]);

  const onPacienteChange = (e: { value: any; checked: boolean }) => {
    const _selectedPacientes = [...selectedPacientes];

    if (e.checked) {
      _selectedPacientes.push(e.value);
    } else {
      for (let i = 0; i < _selectedPacientes.length; i++) {
        const selectedPaciente = _selectedPacientes[i];

        if (selectedPaciente === e.value) {
          _selectedPacientes.splice(i, 1);
          break;
        }
      }
    }

    setSelectedPacientes(_selectedPacientes);
  };

  return (
    <div id="modal-pacientes" className="p-grid p-col-12">
      <div className="p-mb-3">
        <TextInputSearch
          placeholder="Pesquisar Pacientes"
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </div>
      <div>
        <SimpleText
          bold
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Pacientes
        </SimpleText>
        <div className="content p-mt-1">
          <Skeleton className="p-mt-1" height="3rem" loading={loading}>
            <>
              {pacientes?.length ? (
                pacientes?.map(paciente => (
                  <div key={paciente.id} className="card-paciente">
                    <div className="left">
                      <Checkbox
                        name="paciente"
                        value={paciente.id}
                        onChange={onPacienteChange}
                        checked={selectedPacientes?.some(
                          (item: any) => item === paciente?.id,
                        )}
                      />

                      <Avatar
                        className="avatar"
                        label={paciente?.nome}
                        image={paciente?.urlFoto || ''}
                      />
                    </div>
                    <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                      {paciente.nome}
                    </SimpleText>
                  </div>
                ))
              ) : (
                <div
                  style={{ height: '100%' }}
                  className="p-d-flex p-flex-1 p-ai-center p-jc-center"
                >
                  <SimpleText className="label">
                    Nenhum item encontrado para esta busca.
                  </SimpleText>
                </div>
              )}
            </>
          </Skeleton>
        </div>

        <div className="p-mt-4">
          <SimpleText>
            {selectedPacientes?.length} Pacientes selecionados
          </SimpleText>
        </div>

        <div className="p-mt-2">
          <Button
            label="Confirmar selecionados"
            onClick={() => handleAdicionar()}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalPacientes;
