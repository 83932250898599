import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ComunicadosAPI from 'src/APIs/AdminAPI/ComunicadosAPI/ComunicadosAPI';

import { Button } from 'src/components/_UI';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import CardComunicado from './ComunicadoGrid/CardComunicado';
import { Header } from './ComunicadoGrid/Header';

import './CentralComunicado.scss';

export const CentralComunicadosPacientes = () => {
  const navigate = useNavigate();

  const [reload, setReload] = useState(0);

  const fetchApi = useCallback(async params => {
    const { query, ...rest } = params;
    return ComunicadosAPI.loadComunicadosPacientes({
      query,
      sortBy: 'id',
      sortDirection: 'DESC',
      ...rest,
    });
  }, []);

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const renderRow = useCallback((data: any): JSX.Element => {
    return <CardComunicado data={data} reloadList={reloadList} />;
  }, []);

  return (
    <>
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.SearchInput
          label="Pesquisar comunicados"
          placeholder="Digite uma parte do titulo ou mensagem"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
            <Button
              type="button"
              btnType="pill"
              label="Enviar novo comunicado"
              icon="fas fa-comment-alt"
              onClick={() =>
                navigate('/cadastros/comunicadosPacientes/cadastro')
              }
              checkPermission="ADM_COMUNICADO_CADASTRAR"
            />

            <SearchList.TotalCount className="p-mt-3 p-mt-sm-0" />
          </div>

          <Header />

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </>
  );
};
