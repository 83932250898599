import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

import {
  addBearerInterceptor,
  refreshTokenInterceptor,
  unauthorizedInterceptor,
} from 'src/core/http/HttpUtils';

import { Service } from './Http';

const securityConfigAxiosInstance: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SECURITY_API_URL || '',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: process.env.REACT_APP_BASIC_AUTH || '',
  },
};
const securityInstance: AxiosInstance = axios.create(
  securityConfigAxiosInstance,
);

const sessaoConfigAxiosInstance: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SESSAO_API_URL || '',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
};
const sessaoInstance: AxiosInstance = axios.create(sessaoConfigAxiosInstance);

const authConfigAxiosInstance = (service: Service) => {
  let baseURL;

  switch (service) {
    case 'adm':
      baseURL = process.env.REACT_APP_ADM_API_URL;
      break;

    case 'agenda':
      baseURL = process.env.REACT_APP_AGENDA_API_URL;
      break;

    case 'pep':
      baseURL = process.env.REACT_APP_PEP_API_URL;
      break;

    case 'report':
      baseURL = process.env.REACT_APP_REPORT_API_URL;
      break;

    case 'storage':
      baseURL = process.env.REACT_APP_STORAGE_API_URL;
      break;
  }

  const configInstance: AxiosRequestConfig = {
    baseURL: baseURL || '',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  const axiosInstance: AxiosInstance = axios.create(configInstance);

  axiosInstance.interceptors.request.use(addBearerInterceptor);
  axiosInstance.interceptors.request.use(refreshTokenInterceptor);
  axiosInstance.interceptors.response.use(
    undefined,
    unauthorizedInterceptor(axiosInstance),
  );

  return axiosInstance;
};

interface HttpInstancesInterface {
  security: AxiosInstance;
  sessao: AxiosInstance;
  adm: AxiosInstance;
  agenda: AxiosInstance;
  pep: AxiosInstance;
  report: AxiosInstance;
  storage: AxiosInstance;
}
const HttpInstances: HttpInstancesInterface = {
  security: securityInstance,
  sessao: sessaoInstance,
  adm: authConfigAxiosInstance('adm'),
  agenda: authConfigAxiosInstance('agenda'),
  pep: authConfigAxiosInstance('pep'),
  report: authConfigAxiosInstance('report'),
  storage: authConfigAxiosInstance('storage'),
};

export default HttpInstances;
