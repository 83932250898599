import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import { formatData } from '../helpers';


const RegistroGeral = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [ufOptions, setUfOptions] = useState<any>([]);

  const fetchUfData = async () => {
    const response = await UtilsAPI.GetEstados();

    setUfOptions(formatData(response, 'sigla', 'id'));
  };

  useEffect(() => {
    fetchUfData();
  }, []);

  return (
    <>
      <TextInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="rgNumero"
        label="Número"
        maxLength={20}
        permitirApenasNumeros
        errorMsg={errors.rgNumero?.message}
      />
      <TextInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="rgOrgaoEmissor"
        label="Orgão emissor"
        maxLength={4}
        errorMsg={errors.rgOrgaoEmissor?.message}
      />
      <DropdownControlled
        className="p-col-4 p-sm-3"
        control={control}
        name="rgUf"
        label="UF"
        options={ufOptions}
        errorMsg={errors.rgUf?.message}
      />
      <CalendarInputControlled
        className="p-col-8 p-sm-9"
        control={control}
        name="rgDataEmissao"
        label="Data de emissão"
        placeholder="dd/mm/aaaa"
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
        maxDate={new Date()}
        errorMsg={errors.rgDataEmissao?.message}
      />
      <TextInputControlled
        className="p-col-12"
        control={control}
        name="complemento"
        label="Complemento"
        errorMsg={errors.complemento?.message}
      />
    </>
  );
};

export default RegistroGeral;
