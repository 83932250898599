import { useEffect, useState, useCallback } from 'react';

import { useParams } from 'react-router';

import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Tab from 'src/components/Tab/Tab';

import Contato from './Contato/Contato';
import DadosObrigatorios from './DadosObrigatorios/DadosObrigatorios';
import DadosOpcionais from './DadosOpcionais/DadosOpcionais';
import Header from './Header/Header';
import { ManterPacienteProvider } from './MaterPacienteContext';
import PlanoSaude from './PlanoSaude/PlanoSaude';

import './ManterPaciente.scss';

const ManterPaciente = () => {
  const { idPaciente } = useParams();

  const { user } = useAppSelector((state: RootState) => state);

  const [loadingCamposDemograficos, setLoadingCamposDemograficos] =
    useState<boolean>(true);
  const [camposDemograficosObrigatorios, setCamposDemograficosObrigatorios] =
    useState<boolean>(false);

  const loadCamposDemograficosObrigatorios = useCallback(async () => {
    setLoadingCamposDemograficos(true);

    const response = await ParametroAPI.loadParametros({
      codigoParametro: 'OBRIGATORIO_CAMPOS_PACIENTE',
    });

    if (response?.list?.length && response?.list[0]) {
      if (!response.list[0]?.listaCliente?.length) {
        setCamposDemograficosObrigatorios(response?.list[0].valor === 'S');
        return setLoadingCamposDemograficos(false);
      }

      const parametroPersonalizadoEmpresa = response.list[0]?.listaCliente.find(
        cliente => cliente.idEmpresa === user.idEmpresa,
      );

      setCamposDemograficosObrigatorios(
        parametroPersonalizadoEmpresa?.valor === 'S',
      );
    }

    setLoadingCamposDemograficos(false);
  }, [user.idEmpresa]);

  useEffect(() => {
    loadCamposDemograficosObrigatorios();
  }, [loadCamposDemograficosObrigatorios]);

  return (
    <ManterPacienteProvider>
      <div id="manter-paciente-container">
        <Header />
        <Tab
          underHeader
          values={[
            {
              label: 'Dados obrigatórios',
              content: (
                <DadosObrigatorios
                  loadingCamposDemograficos={loadingCamposDemograficos}
                  camposDemograficosObrigatorios={
                    camposDemograficosObrigatorios
                  }
                />
              ),
            },
            {
              label: 'Dados opcionais',
              content: (
                <DadosOpcionais
                  camposDemograficosObrigatorios={
                    camposDemograficosObrigatorios
                  }
                />
              ),
              disabled: !idPaciente,
            },
            {
              label: 'Contato',
              content: (
                <Contato
                  camposDemograficosObrigatorios={
                    camposDemograficosObrigatorios
                  }
                />
              ),
              disabled: !idPaciente,
            },
            {
              label: 'Plano de saúde',
              content: <PlanoSaude />,
              disabled: !idPaciente,
            },
          ]}
        />
      </div>
    </ManterPacienteProvider>
  );
};

export default ManterPaciente;
