import { createContext, useContext, useState } from 'react';

type IContextProps = {
  filters: null | {
    concluido?: boolean;
    ativo?: boolean;
    emAtraso?: boolean;
    idProfissionalResponsavel?: number;
  };
  setFilters: (v: any) => void;
  idConvenio: number | undefined;
  setIdConvenio: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const PlanoDeCuidadoContext = createContext({} as IContextProps);

export const PlanoDeCuidadoProvider = (props: {
  children: React.ReactChild;
}) => {
  const [filters, setFilters] = useState<any>({
    ativo: true,
    emAtraso: true,
  });
  const [idConvenio, setIdConvenio] = useState<number>();

  return (
    <PlanoDeCuidadoContext.Provider
      value={{
        filters,
        setFilters,
        idConvenio,
        setIdConvenio,
      }}
    >
      {props.children}
    </PlanoDeCuidadoContext.Provider>
  );
};

export const usePlanoDeCuidado = () => useContext(PlanoDeCuidadoContext);
