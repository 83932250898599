import Http, { HttpParams } from 'src/core/http/Http';

export const loadCompromissos = (
  idProfissional: any,
  page?: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<CompromissoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: `/compromissos?page=${page}`,
    data: idProfissional,
    hideToast: true,
    ...options,
  });
};

export const loadCompromissoByProfissionalSaude = (
  idMedico: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<CompromissoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: `/compromissos/profissional-saude/${idMedico}`,
    hideToast: true,
    ...options,
  });
};

export const saveCompromisso = (
  data: CompromissoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Paginator<CompromissoDTO[]> => {
  return Http.post({
    service: 'agenda',
    url: '/compromissos/',
    data: data,
    ...options,
  });
};

export const updateCompromisso = (
  data: CompromissoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Paginator<CompromissoDTO[]> => {
  return Http.put({
    service: 'agenda',
    url: '/compromissos/',
    data: data,
    ...options,
  });
};

export const deleteCompromisso = (
  idMedico: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Compromisso> => {
  return Http.delete({
    service: 'agenda',
    url: `/compromissos/${idMedico}`,
    ...options,
  });
};

const CompromissosAPI = {
  loadCompromissos,
  loadCompromissoByProfissionalSaude,
  saveCompromisso,
  updateCompromisso,
  deleteCompromisso,
};

export default CompromissosAPI;
