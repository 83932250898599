import { memo, useCallback, useRef } from 'react';

import { Menu } from 'primereact/menu';

import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';

import { useAgenda } from '../../AgendaContext';

import './Options.scss';

interface OptionsProps {
  agendamento: AtendimentoAgendamento;
}

const Options = ({ agendamento }: OptionsProps) => {
  const { refetchAgenda, dialogGerenciarGrupos } = useAgenda();
  const { consultorios, user, agenda } = useAppSelector(
    (state: RootState) => state,
  );
  const optionsAgendamento = useRef<any>();

  const handleInternet = useCallback(
    async (value: string[]) => {
      let response: any;

      if (agendamento.id) {
        const payload: any = {
          id: agendamento.id,
          status: agendamento.status,
          flagsAtendimentos: value,
        };

        response = await AgendamentosAPI.editarAgendamento(payload);
      } else {
        const payload: any = {
          idMedico: agenda?.profissionalAtivo?.id,
          idConsultorio: consultorios.ativo?.id,
          dataAgendamento: agendamento.dataAgendamento,
          status: 'LIVRE',
          flagsAtendimentos: value,
        };

        response = await AgendamentosAPI.saveNewAgendamento(
          payload,
          'Hórario reservado com sucesso',
        );
      }

      if (response.status === 200) {
        refetchAgenda();
      }
    },
    [
      agenda?.profissionalAtivo?.id,
      agendamento.dataAgendamento,
      agendamento.id,
      agendamento.status,
      consultorios.ativo?.id,
      refetchAgenda,
    ],
  );

  const isAgendamentoGrupo = useCallback((): boolean => {
    if (agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo) {
      return true;
    }

    return false;
  }, [agendamento.idAtendimentoEmGrupo, agendamento.idGrupoAtendimento]);

  const blockHorarioLivre = useCallback(async () => {
    try {
      if (
        !agendamento?.dataAgendamento ||
        !agenda ||
        !agenda.profissionalAtivo ||
        !consultorios ||
        !consultorios.ativo
      ) {
        return;
      }

      await AgendamentosAPI.livreToBloqueado({
        dataBloqueio: agendamento?.dataAgendamento,
        idConsultorio: consultorios?.ativo?.id,
        idUsuario: agenda?.profissionalAtivo?.id,
        observacaoBloqueio: '',
        idUsuarioAlteracao: user?.idUsuario,
        idEmpresa: consultorios?.ativo?.idEmpresa,
      });

      refetchAgenda();
    } catch (e) {
      console.log(e);
    }
  }, [agenda, agendamento, consultorios, refetchAgenda, user]);

  const items = [
    {
      label: 'Alterar status para:',
      items: [
        {
          label: 'Bloqueado',
          command: () => blockHorarioLivre(),
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: 'Disponibilizar para a internet:',
      items: [
        {
          label: 'Consulta padrão',
          command: () =>
            agendamento.id && agendamento.flagsAtivas.includes('INTERNET', -1)
              ? handleInternet(
                  agendamento.flagsAtivas.filter(flag => flag === 'INTERNET'),
                )
              : handleInternet(['INTERNET']),
          disabled: agendamento.id
            ? agendamento.flagsAtivas.includes('INTERNET', -1)
            : false,
        },

        {
          label: 'Consulta de retorno',
          command: () => handleInternet(['INTERNET', 'RETORNO']),
          disabled: agendamento.id
            ? agendamento.flagsAtivas.includes('RETORNO')
            : false,
        },
        {
          label: 'Teleconsulta',
          command: () => handleInternet(['INTERNET', 'TELECONSULTA']),
          disabled: agendamento.id
            ? agendamento.flagsAtivas.includes('TELECONSULTA')
            : false,
        },
      ],
    },
    {
      className: 'warning-color error',
      label: 'Revogar disponibilidade',
      icon: 'fa-solid fa-arrow-rotate-left',
      command: () =>
        handleInternet(
          agendamento.flagsAtivas.filter(
            flag => !['INTERNET', 'RETORNO', 'TELECONSULTA'].includes(flag),
          ),
        ),
      hide: !agendamento?.flagsAtivas?.includes?.('INTERNET'),
    },
    {
      separator: true,
    },
    {
      label: 'Disponibilizar para Central de Agendamento',
      command: () => handleInternet(['CENTRAL_AGENDAMENTO']),
      disabled: agendamento.id
        ? agendamento.flagsAtivas.includes('CENTRAL_AGENDAMENTO')
        : false,
    },
    {
      className: 'warning-color error',
      label: 'Revogar disponibilidade para Central de Agendamento',
      icon: 'fa-solid fa-arrow-rotate-left',
      command: () => handleInternet([]),
      hide: !agendamento?.flagsAtivas?.includes?.('CENTRAL_AGENDAMENTO'),
    },
  ];

  const itemsGrupo = [
    {
      label: 'Gerenciar Grupo',
      command: () => dialogGerenciarGrupos.open({ state: agendamento }),
    },
  ];

  return (
    <>
      <Menu
        model={
          isAgendamentoGrupo() ? itemsGrupo : items.filter(item => !item.hide)
        }
        popup
        ref={optionsAgendamento}
      />

      <Button
        icon="fas fa-ellipsis-v"
        btnType="gray"
        onClick={event => optionsAgendamento.current.toggle(event)}
      />
    </>
  );
};

export default memo(Options);
