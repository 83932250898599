import { useState } from 'react';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import Tab from 'src/components/Tab/Tab';

import { CabecalhoHistoricoAtendimento } from './CabecalhoHistoricoAtendimento';
import { HistoricoCIDSCIAPS } from './Historicos/CidsCiaps/HistoricoCIDSCIAPS';
import { HistoricoEncaminhamentos } from './Historicos/Encaminhamentos/Encaminhamentos';
import HistoricoFichaPaciente from './Historicos/FichaPaciente/HistoricoFichaPaciente';
import HistoricoImpressos from './Historicos/Impressos/HistoricoImpressos';
import { HistoricoPlanoCuidado } from './Historicos/PlanoCuidado/HistoricoPlanoCuidado';
import HistoricoReceitas from './Historicos/Receitas/HistoricoReceitas';
import { HistoricoResumo } from './Historicos/Resumo/HistoricoResumo';
import { HistoricoSADT } from './Historicos/SADT/SADT';

type Props = DisclosureType & {
  initial: string;
  onInativarAtendimento: any;
  justificativaDisclosure: any;
  reload: () => void;
};

export function ModalHistoricoCompleto({
  isOpen,
  initial,
  close,
  ...props
}: Props) {
  const { setItensFichaPaciente, setQuantidadePreAtendimento } =
    useHistoricoAtendimento();
  return (
    <ErrorBoundary>
      <Dialog
        header="Histórico de atendimento completo"
        visible={isOpen}
        onHide={() => {
          close();
          setItensFichaPaciente(0);
          setQuantidadePreAtendimento(0);
        }}
        maximizedMobileSize
        className="Dialog dialog-historico-completo"
      >
        <HistoricosComp
          isOpen={isOpen}
          close={close}
          initial={initial}
          {...props}
        />
      </Dialog>
    </ErrorBoundary>
  );
}

const initialTab: any = {
  RESUMO: 0,
  'CID/CIAP': 1,
};

export function HistoricosComp({
  close,
  onInativarAtendimento,
  justificativaDisclosure,
  initial,
  reload,
}: Props) {
  const [tabAtiva, setTabAtiva] = useState(initialTab[initial]);
  const {
    selectedAtendimento,
    setItensFichaPaciente,
    setQuantidadePreAtendimento,
    isHistoricoAssinatura,
  } = useHistoricoAtendimento();

  const nivelSigilo: number | null =
    selectedAtendimento.atendimento?.nivelSigilo;
  const showAllTabs = nivelSigilo === null || nivelSigilo === 2;

  const tabSADT = [
    {
      label: 'SADT',
      content: (
        <ErrorBoundary>
          <HistoricoSADT />
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <div className="dialog-container">
      <div className="p-d-flex p-jc-between p-ai-center p-col-12">
        <CabecalhoHistoricoAtendimento atendimento={selectedAtendimento} />
        <div>
          {selectedAtendimento.status === 'ATIVO' ? (
            <>
              {!isHistoricoAssinatura && (
                <Button
                  btnType="green-link"
                  onClick={() => onInativarAtendimento(selectedAtendimento)}
                  label="Inativar"
                />
              )}
            </>
          ) : (
            <Button
              icon="fas fa-eye"
              onClick={() => onInativarAtendimento(selectedAtendimento)}
              btnType="ghost"
            />
          )}
        </div>
      </div>
      <Tab
        clean
        setActiveIndex={tabAtiva}
        onChange={setTabAtiva}
        values={
          showAllTabs
            ? [
                {
                  label: 'Resumo',
                  content: (
                    <div className="content">
                      <ErrorBoundary>
                        <HistoricoResumo />
                      </ErrorBoundary>
                    </div>
                  ),
                },
                {
                  label: 'Ficha do Paciente',
                  content: (
                    <ErrorBoundary>
                      <HistoricoFichaPaciente />
                    </ErrorBoundary>
                  ),
                },
                {
                  label: 'CID/CIAP',
                  content: (
                    <ErrorBoundary>
                      <HistoricoCIDSCIAPS
                        justificativaDisclosure={justificativaDisclosure}
                        reload={reload}
                      />
                    </ErrorBoundary>
                  ),
                },
                {
                  label: 'Receitas',
                  content: (
                    <ErrorBoundary>
                      <HistoricoReceitas />
                    </ErrorBoundary>
                  ),
                },
                {
                  label: 'Encaminhamentos',
                  content: (
                    <ErrorBoundary>
                      <HistoricoEncaminhamentos />
                    </ErrorBoundary>
                  ),
                },
                {
                  label: 'SADT',
                  content: (
                    <ErrorBoundary>
                      <HistoricoSADT />
                    </ErrorBoundary>
                  ),
                },
                {
                  label: 'Impressos',
                  content: (
                    <ErrorBoundary>
                      <HistoricoImpressos />
                    </ErrorBoundary>
                  ),
                },
                {
                  label: 'Plano de cuidado',
                  content: (
                    <ErrorBoundary>
                      <HistoricoPlanoCuidado />
                    </ErrorBoundary>
                  ),
                },
                // {
                //   label: 'Gestação',
                //   content: (
                //     <ErrorBoundary>
                //       <HistoricoGestacao />
                //     </ErrorBoundary>
                //   ),
                // },
              ]
            : tabSADT
        }
      />
      <div className="btn-footer p-mt-2">
        <Button
          btnType="ghost"
          label="Fechar"
          onClick={() => {
            close();
            setItensFichaPaciente(0);
            setQuantidadePreAtendimento(0);
          }}
          stretch
        />
      </div>
    </div>
  );
}
