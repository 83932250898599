/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import TipoMotivoFaltaAPI from 'src/APIs/ConfigAPI/TipoMotivoFalta/TipoMotivoFaltaAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalAddTipoMotivo from './ModalAddTipoMotivo/ModalAddTipoMotivo';
import ModalExcluirTipoMotivo from './ModalExcluirTipoMotivo/ModalExcluirTipoMotivo';
import { Row } from './TipoMotivoFaltaGrid/Row';

import './TipoMotivoFalta.scss';

interface TipoMotivoFaltaProps {
  consultoriosDisponiveis: any[];
  consultoriosUsuario: any[];
}

const TipoMotivoFalta = ({
  consultoriosDisponiveis,
  consultoriosUsuario,
}: TipoMotivoFaltaProps) => {
  const [reload, setReload] = useState(0);

  const [tipoMotivoFalta, setTipoMotivoFalta] = useState({
    id: null,
    consultoriosIds: [],
    descricao: '',
    ativo: false,
  });

  const modalAddDisclosure = useDisclosure({ opened: false });
  const modalExcluirDisclosure = useDisclosure({ opened: false });

  const fetchApi = async () => {
    return await TipoMotivoFaltaAPI.loadTipoMotivoFalta();
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };
  const handleModalAddTipoMotivo = useCallback(() => {
    setTipoMotivoFalta({
      id: null,
      consultoriosIds: [],
      descricao: '',
      ativo: false,
    });
    modalAddDisclosure.open();
  }, []);

  const handleModalAddTipoMotivoClose = useCallback(() => {
    setTipoMotivoFalta({
      id: null,
      consultoriosIds: [],
      descricao: '',
      ativo: false,
    });

    modalAddDisclosure.close();
  }, []);

  const handleModalEditTipoMotivo = useCallback((item: any) => {
    setTipoMotivoFalta(item);
    modalAddDisclosure.open();
  }, []);

  const handleModalExcluirTipoMotivoClose = useCallback(() => {
    modalExcluirDisclosure.close();
  }, []);

  const handleExcluirMotivo = useCallback((item: any) => {
    modalExcluirDisclosure.open();
    setTipoMotivoFalta(item);
  }, []);

  const renderRow = (data: any) => (
    <Row
      data={data}
      onDelete={handleExcluirMotivo}
      onEdit={handleModalEditTipoMotivo}
    />
  );

  return (
    <>
      <SimpleText>
        Gerencie os motivos de falta que serão exibidos para os usuários do
        sistema na função agenda
      </SimpleText>

      <div className="TipoMotivoFalta">
        <SearchList.Root
          fetchApi={fetchApi}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.BorderContainer>
            <div className="p-d-flex p-flex-sm-row p-jc-end p-mb-3">
              <Button
                btnType="pill"
                label="Novo tipo de falta"
                icon="fa fa-plus"
                checkPermission="AGE_MOTIVO_DE_FALTA_CADASTRAR"
                onClick={handleModalAddTipoMotivo}
              />
            </div>

            <Header />

            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>
        {modalAddDisclosure.isOpen && (
          <ModalAddTipoMotivo
            consultoriosDisponiveis={consultoriosDisponiveis}
            consultoriosUsuario={consultoriosUsuario}
            tipoMotivoFalta={tipoMotivoFalta}
            reload={reloadList}
            visible={true}
            onHide={handleModalAddTipoMotivoClose}
          />
        )}

        {modalExcluirDisclosure.isOpen && (
          <ModalExcluirTipoMotivo
            tipoMotivoFalta={tipoMotivoFalta}
            visible={true}
            reload={reloadList}
            onHide={handleModalExcluirTipoMotivoClose}
          />
        )}
      </div>
    </>
  );
};

export default TipoMotivoFalta;
