/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import TipoMotivoAtendimentoAPI from 'src/APIs/ConfigAPI/TipoMotivoAtendimento/TipoMotivoAtendimentoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalAddTipoMotivo from './ModalAddTipoMotivo/ModalAddTipoMotivo';
import ModalExcluirTipoMotivo from './ModalExcluirTipoMotivo/ModalExcluirTipoMotivo';
import { Row } from './TipoMotivoAtendimentoGrid/Row';

import './TipoMotivoAtendimento.scss';

interface TipoMotivoAtendimentoProps {
  consultoriosDisponiveis: any[];
  consultoriosUsuario: any[];
}

const TipoMotivoAtendimento = ({
  consultoriosDisponiveis,
  consultoriosUsuario,
}: TipoMotivoAtendimentoProps) => {
  const [reload, setReload] = useState<number>(0);

  const [tipoMotivoAtendimento, setTipoMotivoAtendimento] = useState({
    id: null,
    consultorios: [],
    descricao: '',
    status: false,
  });

  const modalAddDisclosure = useDisclosure({ opened: false });
  const modalExcluirDisclosure = useDisclosure({ opened: false });

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await TipoMotivoAtendimentoAPI.getTipoMotivoAtendimento({
      descricao: params?.query,
      ...params,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleModalAddTipoMotivo = useCallback(() => {
    setTipoMotivoAtendimento({
      id: null,
      consultorios: [],
      descricao: '',
      status: false,
    });
    modalAddDisclosure.open();
  }, []);

  const handleModalAddTipoMotivoClose = useCallback(() => {
    setTipoMotivoAtendimento({
      id: null,
      consultorios: [],
      descricao: '',
      status: false,
    });
    modalAddDisclosure.close();
  }, []);

  const handleModalEditTipoMotivo = useCallback((item: any) => {
    setTipoMotivoAtendimento(item);
    modalAddDisclosure.open();
  }, []);

  const handleModalExcluirTipoMotivoClose = useCallback(() => {
    modalExcluirDisclosure.close();
  }, []);

  const handleExcluirMotivo = useCallback((item: any) => {
    modalExcluirDisclosure.open();
    setTipoMotivoAtendimento(item);
  }, []);

  const renderRow = (data: any): JSX.Element => (
    <Row
      data={data}
      onDelete={handleExcluirMotivo}
      onEdit={handleModalEditTipoMotivo}
    />
  );

  return (
    <>
      <SimpleText>
        Gerencie os motivos de atendimento que serão exibidos para os usuários
        do sistema, em cada consultório
      </SimpleText>

      <div className="TipoMotivoAtendimento">
        <SearchList.Root
          fetchApi={fetchApi}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.BorderContainer>
            <div className="p-d-flex  p-jc-between p-ai-center p-flex-wrap">
              <SearchList.SearchInput placeholder="Pesquise por motivo" />

              <Button
                btnType="pill"
                className="p-mb-3"
                label="Novo tipo de motivo de atendimento"
                icon="fas fa-plus"
                onClick={handleModalAddTipoMotivo}
                disabled={!consultoriosDisponiveis?.length}
                checkPermission="AGE_MOTIVO_DE_ATENDIMENTO_CADASTRAR"
              />
            </div>

            <Header />

            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>
        {modalAddDisclosure.isOpen && (
          <ModalAddTipoMotivo
            consultoriosDisponiveis={consultoriosDisponiveis}
            consultoriosUsuario={consultoriosUsuario}
            tipoMotivoAtendimento={tipoMotivoAtendimento}
            reload={reloadList}
            visible={true}
            onHide={handleModalAddTipoMotivoClose}
          />
        )}

        {modalExcluirDisclosure.isOpen && (
          <ModalExcluirTipoMotivo
            tipoMotivoAtendimento={tipoMotivoAtendimento}
            visible={true}
            reloadData={reloadList}
            onHide={handleModalExcluirTipoMotivoClose}
          />
        )}
      </div>
    </>
  );
};

export default TipoMotivoAtendimento;
