/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext } from 'react';

import { addLocale } from 'primereact/api';
import {
  Calendar as PrimeCalendar,
  CalendarChangeParams,
  CalendarProps as PrimeCalendarProps,
} from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import ThemeContext from 'src/core/themes/ThemeContext';

import { generateUniqueId } from 'src/utils/utils';

import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './CalendarInput.scss';

addLocale('pt', {
  firstDayOfWeek: 1,
  dayNames: [
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  ],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Sx', 'Sa'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  today: 'Hoje',
  clear: 'Limpar',
});

export interface CalendarInputProps
  extends Omit<PrimeCalendarProps, 'disabled' | 'required' | 'value'> {
  errorMsg?: string | undefined;
  label?: string | undefined;
  sublabel?: string;
  value: Date | Date[] | undefined;
  name?: string;
  options?: any;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  selectionMode?: string;
  yearRange?: string;
  hideTextErrorSpace?: boolean;
}

const CalendarInput = ({
  onChange,
  errorMsg,
  className,
  name,
  label,
  sublabel,
  value,
  required,
  disabled,
  selectionMode,
  yearRange = new Date().getFullYear() -
    150 +
    ':' +
    (new Date().getFullYear() + 10),
  hideTextErrorSpace = true,
  readOnlyInput,
  dateFormat = 'dd/mm/yy',
  ...props
}: CalendarInputProps) => {
  const theme = useContext(ThemeContext);

  if (dateFormat.includes('yyyy')) {
    dateFormat = dateFormat.replace('yyyy', 'yy');
  }

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        key={e.value}
        onChange={event => e.onChange(event.originalEvent, event.value)}
      />
    );
  };

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options.length > 15 ? e.options.reverse() : e.options}
        key={e.value}
        virtualScrollerOptions={{
          itemSize: 35,
          scrollHeight: '135px',
        }}
        onChange={event => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
      />
    );
  };

  const renderLabel = () => {
    if (label || label === '' || required) {
      return (
        <ComponentsLabel
          overflowX="visible"
          name={name}
          label={label}
          sublabel={sublabel}
          required
        />
      );
    }
    return <></>;
  };

  const renderInput = () => {
    const aux = (
      //@ts-ignore
      <PrimeCalendar
        id={name || generateUniqueId()}
        //@ts-ignore
        value={value}
        dateFormat="dd/mm/yy"
        icon="pi pi-calendar"
        {...props}
        onChange={(e: CalendarChangeParams) => {
          if (onChange) {
            onChange(e);
          }
        }}
        readOnlyInput={!!readOnlyInput}
        monthNavigator
        yearNavigator
        yearRange={yearRange}
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        disabled={disabled || false}
        required={required || false}
        selectionMode={selectionMode}
        locale="pt"
        inputClassName={`${errorMsg ? 'p-invalid' : ''}`}
      />
    );
    return aux;
  };

  return (
    <div
      className={`CalendarInput ${theme?.theme || ''} ${
        errorMsg ? 'error' : ''
      } ${className || ''}`}
      key={name}
    >
      {renderLabel()}
      {renderInput()}
      <div
        className="p-mt-1"
        style={{ minHeight: hideTextErrorSpace ? '0' : '19px' }}
      >
        <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
          {errorMsg || ''}
        </SimpleText>
      </div>
    </div>
  );
};

export default CalendarInput;
