import { createContext } from 'react';

export type LightThemeType = 'light-theme';
export type DarkThemeType = 'dark-theme';
export type ThemeType = LightThemeType | DarkThemeType;
export type ThemeContextType = {
  theme: ThemeType;
  toogleTheme?(): void;
  icon?: string;
};
export const LightTheme: LightThemeType = 'light-theme';
export const DarkTheme: DarkThemeType = 'dark-theme';

const ThemeContext = createContext<ThemeContextType>({ theme: LightTheme });

export default ThemeContext;
