import { useState, useMemo, useCallback, useEffect } from 'react';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

type BuscaRapidaFetchProcedimentosType = {
  loadingFetch: boolean;
  listaProcedimentosFilteredBySearchQuery: any[];
  query: string;
  fetchProcedimentos: () => Promise<void>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setProcedimentos: React.Dispatch<React.SetStateAction<any[]>>;
  handleRemoveProcedimentoFromList: (procedimentoId: number) => void;
};

interface BuscaRapidaFetchProcedimentosProps {
  idAtendimento: number;
  typeFilter: string[];
}

export const useBuscaRapidaFetchProcedimentos = (
  props: BuscaRapidaFetchProcedimentosProps,
): BuscaRapidaFetchProcedimentosType => {
  const { idAtendimento, typeFilter } = props;

  const [query, setQuery] = useState('');
  const [procedimentos, setProcedimentos] = useState<any[]>([]);
  const [loadingFetch, setLoadingFetch] = useState(false);

  const { idConvenio } = useSolicitacaoExameCirurgia();

  const fetchProcedimentos = useCallback(async () => {
    if (!idAtendimento || !typeFilter.length || !idConvenio) return;

    try {
      setLoadingFetch(true);

      const response = await SolicitacoesProcedimentosAPI.getBuscaRapida(
        idAtendimento,
        {
          tipos: typeFilter.toString(),
          idConvenio,
          query: '',
        },
      );

      if (Array.isArray(response)) return setProcedimentos(response);

      setProcedimentos([]);
    } catch (error) {
      setProcedimentos([]);
    } finally {
      setLoadingFetch(false);
    }
  }, [idAtendimento, idConvenio, typeFilter]);

  useEffect(() => {
    fetchProcedimentos();
  }, [fetchProcedimentos]);

  const handleRemoveProcedimentoFromList = (procedimentoId: number) => {
    setProcedimentos(prevSelected =>
      prevSelected.filter(
        (procedimento: any) => procedimento?.id !== procedimentoId,
      ),
    );
  };

  const listaProcedimentosFilteredBySearchQuery = useMemo(() => {
    const queryLowerCase = query.toLowerCase();

    return procedimentos.filter(
      (procedimento: any) =>
        procedimento.nome.toLowerCase().includes(queryLowerCase) ||
        procedimento?.codigo.toLowerCase().includes(queryLowerCase),
    );
  }, [procedimentos, query]);

  return {
    loadingFetch,
    listaProcedimentosFilteredBySearchQuery,
    query,
    fetchProcedimentos,
    setQuery,
    setProcedimentos,
    handleRemoveProcedimentoFromList,
  };
};
