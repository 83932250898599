import { memo, useCallback, useEffect, useState } from 'react';

import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import SearchListProfessionals from './SearchListProfessionals';

import './SearchListProfessionals.scss';

interface FormDialogProps {
  visible: boolean;
  companyProfessionalsList: GetProfissionalDTO[] | [];
  onHide(): void;
  onSelect(i: GetProfissionalDTO[], keepStep?: boolean): void;
  initialValue: GetProfissionalDTO[] | undefined;
}

const SearchProfessionals = (props: FormDialogProps) => {
  const { visible, companyProfessionalsList, onHide, onSelect } = props;

  const [selected, setSelected] = useState<GetProfissionalDTO[]>([]);

  const handleAdd = useCallback(() => {
    onSelect(selected, true);
  }, [onSelect, selected]);

  const handleSelectItem = useCallback((item: GetProfissionalDTO) => {
    setSelected(v => {
      if (v.find(i => i?.id === item?.id)) return v;
      return [...v, item].sort((a, b) => (a?.nome < b?.nome ? -1 : 1));
    });
  }, []);

  const handleRemoveItem = useCallback((item: GetProfissionalDTO) => {
    setSelected(v => {
      if (!v.find(i => i?.id === item?.id)) return v;
      return v.filter(i => i?.id !== item?.id);
    });
  }, []);

  useEffect(() => {
    setSelected(props.initialValue || []);
  }, [props.initialValue, visible]);

  return (
    <Dialog
      type="modal"
      header="Adicionar profissionais na equipe"
      visible={visible}
      onHide={onHide}
      footer={() => <Footer onAdd={handleAdd} onCancel={onHide} />}
      maximizedTabletSize
    >
      <div className="SearchProfessionals">
        <div className="search-title">
          <SimpleText fontColor={FONT_COLOR.COLOR_16}>
            Pesquise o nome do profissional desejado e adicione clicando no
            resultado da busca.
          </SimpleText>
        </div>

        <SearchListProfessionals
          selected={selected}
          onSelectItem={handleSelectItem}
          onRemoveItem={handleRemoveItem}
          companyProfessionalsList={companyProfessionalsList}
        />
      </div>
    </Dialog>
  );
};

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-d-flex p-flex-column p-flex-sm-row p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button label="Salvar" onClick={onAdd} stretch />
    </div>
  );
});

export default memo(SearchProfessionals);
