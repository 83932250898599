import { useMemo, useRef, useState } from 'react';

import DraggableList from 'react-draggable-list';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { DialogNovaPergunta } from '../../_components/DialogNovaPergunta/DialogNovaPergunta';
import { DialogAcervoPerguntasAdicionar } from './_components/DialogAcervoPerguntasAdicionar/DialogAcervoPerguntasAdicionar';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { AcervoFormulariosCreatePerguntasRow } from './AcervoFormulariosCreatePerguntasRow';

export const AcervoFormulariosCreatePerguntas = ({
  item,
  handleGoBack,
  handleRefresh,
  handleNext,
}: {
  item: IFormularioDinamicoItem;
  handleGoBack(): void;
  handleRefresh(): void;
  handleNext(): void;
}) => {
  const novaPerguntaDialog = useDisclosure({ opened: false });
  const dialogAcervo = useDisclosure({ opened: false });

  const { state } = useLocation();
  const dispatch = useDispatch();

  const viewOnly = state?.to !== 'edit';

  const orderedQuestions = useMemo(
    () =>
      item?.perguntas?.sort?.((a, b) => {
        return a.ordem - b.ordem;
      }) || [],
    [item],
  );

  const [items, setItems] = useState<IFormularioDinamicoPergunta[]>(
    orderedQuestions || [],
  );

  const containerRef = useRef(null);

  const _onListChange = (
    newList: IFormularioDinamicoPergunta[],
    item: IFormularioDinamicoPergunta,
    newIndex: number,
  ) => {
    if (viewOnly) return;

    const oldList = items;
    setItems(newList);

    try {
      FormularioDinamicoAPI.ordenarFormularioDinamicoPergunta(
        {
          idFormulario: item.idFormularioDinamico,
          idFormularioDinamicoPergunta: item.id,
          ordem: newIndex + 1,
        },
        { throwError: true },
      );
    } catch {
      setItems(oldList);
    }
  };

  useEffectSkipFirst(() => {
    setItems(orderedQuestions || []);
  }, [item]);

  const getItemIndex = (id: number) => {
    return items.findIndex(item => item.id === id);
  };

  const perguntasLength = items.length;

  return (
    <div>
      <div className="p-col-12 p-d-flex p-flex-column row content-row-outline">
        <div className="p-d-flex p-flex-wrap p-ai-center p-jc-bwtween p-col-12 p-p-0 p-gap-2">
          <Button
            btnType="pill"
            label="Nova pergunta"
            icon="fas fa-plus"
            className="p-col-12 p-sm-6 p-md-3"
            onClick={() => novaPerguntaDialog.open()}
            disabled={viewOnly}
          />
          <Button
            label="Adicionar do acervo"
            btnType="tonal"
            icon="fas fa-table-list"
            className="p-ml-auto"
            onClick={() =>
              dialogAcervo.open({
                state: {
                  idForm: item.id,
                },
              })
            }
            disabled={viewOnly}
          />
        </div>

        {items.length ? (
          <div
            ref={containerRef}
            className={`p-mt-2 p-d-flex p-flex-column p-gap-2 p-p-2`}
            style={{
              minWidth: '400px',
              overflowY: 'auto',
              maxHeight: '600px',
            }}
          >
            <DraggableList
              itemKey="id"
              template={AcervoFormulariosCreatePerguntasRow as any}
              list={items}
              onMoveEnd={(
                newList: any,
                movedItem: any,
                _: any,
                newIndex: number,
              ) => _onListChange(newList, movedItem, newIndex)}
              container={() => containerRef.current}
              commonProps={{ getItemIndex, perguntasLength }}
            />
          </div>
        ) : (
          <div
            className="p-mt-2 content-row row p-d-flex p-jc-center p-ai-center"
            style={{
              height: '400px',
            }}
          >
            <SimpleText className="p-text-center">
              Nenhuma pergunta adicionada. <br />
              <SimpleText fontColor="primary">
                Clique em nova pergunta ou em obter do acervo.
              </SimpleText>
            </SimpleText>
          </div>
        )}
      </div>

      <div className="p-d-flex p-col-12 p-md-6 p-gap-2 p-my-3">
        <Button
          btnType="ghost"
          stretch
          label="Voltar"
          onClick={() => handleGoBack()}
        />
        <Button
          label="Próximo"
          btnType="tonal"
          stretch
          onClick={() => handleNext()}
        />
      </div>

      {dialogAcervo.isOpen && (
        <DialogAcervoPerguntasAdicionar
          onSuccess={handleRefresh}
          {...dialogAcervo}
        />
      )}
      {novaPerguntaDialog.isOpen && (
        <DialogNovaPergunta
          {...novaPerguntaDialog}
          handleReload={() =>
            dispatch(setInvalidateQuery({ invalidateFormularioById: true }))
          }
          state={{
            ...novaPerguntaDialog.state,
            idFormularioDinamico: item.id,
          }}
        />
      )}
    </div>
  );
};
