import { useCallback } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import PersonalizarProntuarioMedicoAPI from 'src/APIs/ProntuarioAPI/PersonalizarProntuarioMedicoAPI/PersonalizarProntuarioMedicoAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

interface ModalNovoModeloProps extends DisclosureType {
  valorCampoProntuario: string;
  setValorCampoProntuario: any;
  tipoCampo: string;
}

const tipoCampoLabel: any = {
  ANAMNESE: 'anamnese',
  DECISAO_TERAPEUTICA: 'decisão terapêutica',
  EXAME_FISICO: 'exame físico',
  HISTORICO_FAMILIAR: 'histórico familiar',
  HISTORICO_PESSOAL: 'histórico pessoal',
  PATOLOGIA_DETECTADA: 'patologia detectada',
  PROGNOSTICO: 'prognostico',
  ALEITAMENTO_ALIMENTACAO: 'aleitamento e alimentação',
  EXECUCAO_PROCEDIMENTOS: 'Execução de procedimentos',
};

export function ModalNovoModelo({
  close,
  isOpen,
  valorCampoProntuario,
  tipoCampo,
}: ModalNovoModeloProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { getModelos } = useAtendimento();

  const validatioSchema = Yup.object().shape({
    nome: Yup.string()
      .trim()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    texto: Yup.string()
      .trim()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
  });

  const formMethods = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const saveAction = useCallback((payload: any) => {
    return PersonalizarProntuarioMedicoAPI.addNewModelo(payload);
  }, []);

  const submitModelo = async (data: any) => {
    const payload = {
      nome: data.nome,
      texto: data.texto,
      campo: tipoCampo,
      idProfissionalSaude: agenda.profissionalAtivo.id,
    };
    const response = await saveAction(payload);

    if (response.status === 201) {
      getModelos();
      close();
    }
  };

  return (
    <Dialog
      header="Salvar como novo modelo"
      visible={isOpen}
      onHide={() => close()}
    >
      <>
        <div className="p-pl-8">
          <SimpleText
            className="p-ml-2 p-mt-4"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {`Você tem certeza que deseja salvar o texto como um novo modelo
            para o campo "${tipoCampoLabel[tipoCampo]}"? `}
          </SimpleText>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(submitModelo)}>
              <FormInput
                className="p-col-12 "
                label="Nome do modelo"
                name="nome"
                errorMsg={errors?.nome?.message}
                maxLength={30}
              />

              <TextareaInputControlled
                name="texto"
                control={control}
                label="Texto do modelo"
                className="grid p-col-12 p-md-12  p-mb-2"
                maxLength={1999}
                value={valorCampoProntuario}
                errorMsg={errors.texto?.message}
              />

              <div className="footer p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
                <Button
                  label="Cancelar"
                  btnType="ghost"
                  onClick={() => close()}
                  loading={isSubmitting}
                  stretch
                />
                <Button
                  label="Sim, salvar"
                  stretch
                  type="submit"
                  loading={isSubmitting}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </>
    </Dialog>
  );
}
