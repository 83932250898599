import { useCallback, useEffect, useState } from 'react';

import { Control } from 'react-hook-form';

import QuestionariosAPI from 'src/APIs/ProntuarioAPI/Questionarios/QuestionariosAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Tab from 'src/components/Tab/Tab';

import Template from '../Components/Template/Template';

import DorEvolucao from './DorEvolucao/DorEvolucao';
import HistoricoEvolucao from './HistoricoEvolucao/HistoricoEvolucao';
import PerfilEvolucao from './PerfilEvolucao/PerfilEvolucao';
import PesquisaEvolucao from './PesquisaEvolucao/PesquisaEvolucao';
import SaudeEvolucao from './SaudeEvolucao/SaudeEvolucao';
import ViciosEvolucao from './ViciosEvolucao/ViciosEvolucao';

import './ModalEvolucao.scss';
import { PerfilSaudeAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PerfilSaudeAPI/PerfilSaudeAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

interface EvolucaoProps extends DisclosureType {
  control: Control;
  idAtendimento: string | undefined;
}

const ModalEvolucao = ({
  isOpen,
  close,
  control,
  state,
  idAtendimento,
}: EvolucaoProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const [values, setValues] = useState<Questionarios[]>();

  const id = state;

  const getEvolucao = useCallback(async () => {
    const params = {
      id: id,
      pageSize: 6,
      sortBy: 'dataInclusao',
      sortDirection: 'DESC',
      ...(idAtendimento && { idAtendimento }),
    };

    let response: any;
    if (idAtendimento) {
      response = await QuestionariosAPI.getQuestionariosEvolucao(params);
    } else {
      response = await PerfilSaudeAPI.getEvolucaoPortal(
        user?.idPaciente,
        params,
      );
    }

    setValues(response.list);
  }, [id, idAtendimento, user?.idPaciente]);

  useEffect(() => {
    getEvolucao();
  }, [getEvolucao]);

  const renderFooter = useCallback(() => {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <Button label="Fechar" btnType="ghost" onClick={() => close()} />
        </div>
      </div>
    );
  }, [close]);

  return (
    <Dialog
      header="Evolução avaliação enfermagem"
      type="modal"
      visible={isOpen}
      onHide={close}
      modal={false}
      footer={renderFooter}
    >
      <div className="container-evolucao">
        <Tab
          className="p-mt-1"
          values={[
            {
              label: 'Vícios',
              headerClass: 'tab',
              content: values ? (
                <ViciosEvolucao values={values} control={control} />
              ) : (
                <Template title="Vícios" control={control} readOnly>
                  <GridListLoading />
                </Template>
              ),
            },

            {
              label: 'Dor frequente',
              headerClass: 'tab',
              content: <DorEvolucao values={values} control={control} />,
            },

            {
              label: 'Saúde mental',
              headerClass: 'tab',
              content: <SaudeEvolucao values={values} control={control} />,
            },

            {
              label: 'Histórico familiar câncer',
              headerClass: 'tab',
              content: <HistoricoEvolucao values={values} control={control} />,
            },

            {
              label: 'Perfil saúde',
              headerClass: 'tab',
              content: <PerfilEvolucao values={values} control={control} />,
            },

            {
              label: 'Pesquisa complementar',
              headerClass: 'tab',
              content: <PesquisaEvolucao values={values} control={control} />,
            },
          ]}
        />
      </div>
    </Dialog>
  );
};

export default ModalEvolucao;
