import Http, { HttpParams } from 'src/core/http/Http';

export enum STATUS_AGENDAMENTO {
  LIVRE = 'LIVRE',
}

export const loadAgendamentosDia = (
  idMedico: number,
  consultorio: number,
  dataInicio: string,
  dataFim: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<AgendamentosDia[]> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/${idMedico}/consultorio/${consultorio}/periodo/${dataInicio}/${dataFim}`,
    hideToast: true,
    ...options,
  });
};

export const loadHorariosLivres = (
  idMedico: number,
  consultorio: number,
  dataInicial: string,
  dataFinal: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<AgendamentosDia[]> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/${idMedico}/consultorio/${consultorio}/periodo/${dataInicial}/${dataFinal}?somenteHorariosLivres=true`,
    hideToast: true,
    ...options,
  });
};

export const getAgendamentosByMedicoByDiasEndpoint = (
  idMedico: number,
  data: DataDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<AgendamentosDias[]> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/${idMedico}/periodo/${data.inicial}/${data.final}`,
    hideToast: true,
    ...options,
  });
};

export const saveNewAgendamento = (
  payload: Partial<AtendimentoAgendamento>,
  message?: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Partial<AtendimentoAgendamento>> => {
  return Http.post({
    service: 'agenda',
    url: '/agendas',
    data: payload,
    return: true,
    successMessage: message ? message : 'Agendamento efetuado com sucesso.',
    ...options,
  });
};

export const agendadoToPresente = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/${payload.id}`,
    data: payload,
    return: true,
    successMessage: 'Agendamento alterado para Presente com sucesso.',
    ...options,
  });
};

export const saveAgendamentoList = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Partial<AtendimentoAgendamento>> => {
  return Http.post({
    service: 'agenda',
    url: '/agendas/list',
    data: payload,
    return: true,
    ...options,
  });
};

export const editarAgendamento = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/${payload.id}`,
    data: payload,
    return: true,
    successMessage: 'Agendamento foi alterado com sucesso.',
    ...options,
  });
};

export const presenteToPrescricao = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/${payload.id}`,
    data: payload,
    return: true,
    successMessage: `Agendamento alterado para ${payload.status} com sucesso.`,
    ...options,
  });
};

export const livreToBloqueado = (
  payload: Partial<any>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'agenda',
    url: '/agendas/bloquear-atendimento',
    data: payload,
    return: true,
    successMessage: 'Horário bloqueado.',
    ...options,
  });
};

export const saveBloqueioDia = (
  payload: Partial<any>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'agenda',
    url: '/agendas/alterar-status-agenda-do-dia',
    data: payload,
    return: true,
    successMessage: 'Horários bloqueados.',
    ...options,
  });
};

export const putAlterarHorarioBloco = (
  payload: Partial<any>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: '/agendas/alterar-bloco',
    data: payload,
    return: true,
    ...options,
  });
};

export const bloqueadoToLivre = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/desbloquear-atendimento/${payload.id}`,
    data: payload,
    return: true,
    successMessage: 'Horário desbloqueado.',
    ...options,
  });
};

export const alterarStatusAgenda = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/${payload.id}`,
    data: payload,
    return: true,
    successMessage: 'Agendamento alterado com sucesso.',
    ...options,
  });
};

export const postReagendar = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'agenda',
    url: `agendas/reagendar/${payload.id}`,
    data: payload,
    return: true,
    successMessage: 'Agendamento remarcado com sucesso.',
    ...options,
  });
};

export const postAgendamentoGrupo = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'agenda',
    url: 'agendas/agendar-em-grupo',
    data: payload,
    return: true,
    successMessage: 'Agendamento remarcado com sucesso.',
    ...options,
  });
};

export const postAgendamentoRecorrente = (
  payload: Partial<AtendimentoAgendamento>,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'agenda',
    url: 'agendas/agendamento-recorrente',
    data: payload,
    return: true,
    successMessage: 'Agendamento recorrente feito com sucesso.',
    hideToast: true,
    errorHideToast: false,
    ...options,
  });
};

export const deleteAgendamentoRecorrente = (
  idAgendamentoRecorrente: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.delete({
    service: 'agenda',
    url: `agendas/agendamento-recorrente/${idAgendamentoRecorrente}/excluir`,
    params,
    return: true,
    hideToast: true,
    ...options,
  });
};

export const updateAgendamentosStatus = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `agendas/alterar-status`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateAgendamentosStatusGuia = (
  params: { idAgendamento: string | number },
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'agenda',
    url: `agendas/${params.idAgendamento}/status-guia`,
    data: payload,
    return: true,
    ...options,
  });
};

export const getUltimosAtendimentosPaciente = (
  idPaciente: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<UltimosAgendamentosPaciente> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/paciente/${idPaciente}/datas-ultimos-atendimentos`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getAgendaPaciente = (
  params: {
    id: number;
    filtroAtendimentoEnum?: 'HISTORICO' | 'FUTUROS';
    profissional?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<UltimosAgendamentosPaciente> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/portal-paciente/${params.id}`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};
export const cancelarPacienteAgendamento = (
  params: {
    idAtendimento: number;
    idPaciente: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<UltimosAgendamentosPaciente> => {
  return Http.put({
    service: 'agenda',
    url: `agendas/portal-paciente/atendimento/${params.idAtendimento}/cancelar`,
    params,
    throwError: true,
    ...options,
  });
};

const getHistricoAgendamento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: `/agendas/atendimento/historico-paciente`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const AgendamentosAPI = {
  getHistricoAgendamento,
  loadAgendamentosDia,
  loadHorariosLivres,
  getUltimosAtendimentosPaciente,
  saveNewAgendamento,
  agendadoToPresente,
  presenteToPrescricao,
  livreToBloqueado,
  bloqueadoToLivre,
  editarAgendamento,
  alterarStatusAgenda,
  putAlterarHorarioBloco,
  saveAgendamentoList,
  postReagendar,
  postAgendamentoGrupo,
  postAgendamentoRecorrente,
  deleteAgendamentoRecorrente,
  updateAgendamentosStatus,
  saveBloqueioDia,
  updateAgendamentosStatusGuia,
  getAgendaPaciente,
  cancelarPacienteAgendamento,
};

export default AgendamentosAPI;
