export const EditorHeader = () => {
  return (
    <span className="ql-formats">
      <button className="ql-bold" aria-label="Bold"></button>
      <button className="ql-italic" aria-label="Italic"></button>
      <button className="ql-underline" aria-label="Underline"></button>
      <button className="ql-strike" aria-label="Strike"></button>
      <select className="ql-color" aria-label="Text Color"></select>
      <select className="ql-background" aria-label="Background Color"></select>
      <button className="ql-script" value="sub" aria-label="Subscript"></button>
      <button
        className="ql-script"
        value="super"
        aria-label="Superscript"
      ></button>
      <button className="ql-blockquote" aria-label="Quote"></button>
      <button className="ql-code-block" aria-label="Code Block"></button>
      <button
        className="ql-list"
        value="ordered"
        aria-label="Ordered List"
      ></button>
      <button
        className="ql-list"
        value="bullet"
        aria-label="Bullet List"
      ></button>
      <button
        className="ql-indent"
        value="-1"
        aria-label="Decrease Indent"
      ></button>
      <button
        className="ql-indent"
        value="+1"
        aria-label="Increase Indent"
      ></button>
      <button
        className="ql-direction"
        value="rtl"
        aria-label="Text Direction"
      ></button>
      <select className="ql-align" aria-label="Text Align"></select>
      <button className="ql-formula" aria-label="Insert Formula"></button>
      <button className="ql-clean" aria-label="Remove Styles"></button>
    </span>
  );
};
