enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_LEMBRO = 'NAO_LEMBRO',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
  ABSTINENCIA = 'ABSTINENCIA',

  SEM_DOR = 'SEM_DOR',
  LIGEIRA = 'DOR_LIGEIRA',
  MODERADA = 'DOR_MODERADA',
  INTENSA = 'DOR_INTENSA',
  MAXIMA = 'DOR_MASSA',

  FAMILIA = 'FAMILIA',
  NINGUEM = 'NINGUEM',
  BENEFICIARIO = 'BENEFICIARIO',
  BENEFICIARIO_FAMILIA = 'BENEFICIARIO_FAMILIA',
  EVITADA = 'EVITADA',
  SATISFEITO = 'SATISFEITO',
  INSATISFEITO = 'INSATISFEITO',
  PARCIALMENTE_SATISFEITO = 'PARCIALMENTE_SATISFEITO',
  ATUALIZADO = 'ATUALIZADO',
  EM_ATRASO = 'EM_ATRASO',
}

export const enumToText = (text: string): string => {
  if (text === Values.SIM) {
    return 'Sim';
  }

  if (text === Values.NAO) {
    return 'Não';
  }

  if (text === Values.NAO_LEMBRO) {
    return 'Não lembro';
  }

  if (text === Values.NAO_SE_APLICA) {
    return 'Não se aplica';
  }

  if (text === Values.ABSTINENCIA) {
    return 'Abstinência';
  }

  if (text === Values.SEM_DOR) {
    return 'Sem dor';
  }

  if (text === Values.LIGEIRA) {
    return 'Ligeira';
  }

  if (text === Values.MODERADA) {
    return 'moderada';
  }

  if (text === Values.INTENSA) {
    return 'Intensa';
  }

  if (text === Values.MAXIMA) {
    return 'Maxima';
  }

  if (text === Values.FAMILIA) {
    return 'Família';
  }

  if (text === Values.NINGUEM) {
    return 'Ninguém';
  }

  if (text === Values.BENEFICIARIO) {
    return 'Beneficiário';
  }

  if (text === Values.BENEFICIARIO_FAMILIA) {
    return 'Beneficiário e família';
  }

  if (text === Values.EVITADA) {
    return 'Evitada';
  }

  if (text === Values.SATISFEITO) {
    return 'Satisfeito';
  }

  if (text === Values.INSATISFEITO) {
    return 'Insatisfeito';
  }

  if (text === Values.PARCIALMENTE_SATISFEITO) {
    return 'Parcialmente satisfeito';
  }

  if (text === Values.ATUALIZADO) {
    return 'Atualizado';
  }

  if (text === Values.EM_ATRASO) {
    return 'Em atraso';
  }

  return '';
};
