import { useLocation, useParams } from 'react-router';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

interface HistoricoCoombsProps {
  historicoCoombs: any[];
  loading: boolean;
  onDelete: (idCoomb: number) => void;
}

const HistoricoCoombs = ({
  historicoCoombs,
  loading,
  onDelete,
}: HistoricoCoombsProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const renderHistorico = () => {
    if (loading)
      return (
        <div className="p-col-12">
          <GridListLoading />
        </div>
      );

    if (!historicoCoombs.length)
      return (
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
          Não há histórico de coombs indireto
        </SimpleText>
      );

    return (
      <ul className="p-col-12">
        {historicoCoombs.map((coomb: any) => (
          <li
            key={coomb.id}
            className="coomb-item p-d-flex p-jc-between p-ai-center"
          >
            <div>
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_40}
                medium
              >
                Data do exame: {dayjs(coomb.dataExame).format('DD/MM/YYYY')}
              </SimpleText>

              <span>{capitalizeFirstLetter(coomb.coombsIndireto)}</span>
            </div>

            <Button
              type="button"
              icon="fa fa-trash"
              btnType="gray"
              onClick={() => onDelete(coomb.id)}
              disabled={
                !(
                  coomb.idAtendimento === Number(idAtendimento) &&
                  atendimentoStatus === 'ATENDENDO'
                )
              }
            />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div id="historico-coombs-container" className="p-grid p-col-12">
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
        Histórico de Coombs indireto
      </SimpleText>

      {renderHistorico()}
    </div>
  );
};

export default HistoricoCoombs;
