import * as Yup from 'yup';

export const tissValidationSchema = Yup.object().shape({
  justificativaPresenca: Yup.string().nullable(),
  horariosSchedulerEnvioTiss: Yup.string()
    .notRequired()
    .matches(
      /^(?:[01]\d|2[1-3]):[0-5]\d?(?:,\s?(?:[01]\d|2[1-3]):[0-5]\d?)*$/,
      {
        message: 'Formato esperado: HH:MM separado por vírgula',
        excludeEmptyString: true,
      },
    ),
});

export const wsdlValidationSchema = Yup.object().shape({
  urlCancelaGuia: Yup.string().url('Formato do link inválido').notRequired(),
  urlLoteGuias: Yup.string().url('Formato do link inválido').notRequired(),
  urlSolicitacaoDemonstrativoRetorno: Yup.string()
    .url('Formato do link inválido')
    .notRequired(),
  urlSolicitacaoProcedimento: Yup.string()
    .url('Formato do link inválido')
    .notRequired(),
  urlSolicitacaoStatusAutorizacao: Yup.string()
    .url('Formato do link inválido')
    .notRequired(),
  urlSolicitacaoStatusProtocoloRecurso: Yup.string()
    .url('Formato do link inválido')
    .notRequired(),
  urlSolicitacaoStatusProtocolo: Yup.string()
    .url('Formato do link inválido')
    .notRequired(),
  urlVerificaElegibilidade: Yup.string()
    .url('Formato do link inválido')
    .notRequired(),
  urlRecursoGlosa: Yup.string().url('Formato do link inválido').notRequired(),
});

export const portalPacienteValidationSchema = Yup.object().shape({
  mensagemRetorno: Yup.string().when('usaElegibilidade', {
    is: true,
    then: Yup.string()
      .required('Mensagem de retorno é obrigatório quando Usa elegibilidade')
      .typeError('Mensagem de retorno é obrigatório quando Usa elegibilidade')
      .min(3, 'Menor que o mínimo de caracteres (3)'),
    otherwise: Yup.string().nullable(),
  }),
});
