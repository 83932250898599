import { useCallback, useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import AlterarSenhaAPI from 'src/APIs/SecurityAPI/AlterarSenhaAPI/AlterarSenhaAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import PasswordInput from 'src/components/Basics/PasswordInput/PasswordInput';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import './ModalPasswordReset.scss';

const formFields = {
  senhaAtual: '',
  novaSenha: '',
  confirmacaoSenha: '',
};

const ModalPasswordReset = ({ isOpen, close }: DisclosureType) => {
  const { user } = useAppSelector((state: RootState) => state);
  const [email, setEmail] = useState('');

  const validationSchema = Yup.object().shape({
    senhaAtual: Yup.string().required('Digite a senha atual'),
    novaSenha: Yup.string()
      .min(8, 'Mínimo 8 Dígitos')
      .required('Digite a nova senha')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'A senha deve conter 1 maiúscula, 1 número e 1 caractere especial (como !, @, #, etc.)',
      ),
    confirmacaoSenha: Yup.string()
      .min(8, 'Mínimo 8 Dígitos')
      .oneOf([Yup.ref('novaSenha'), null], 'Digite a mesma senha')
      .required('As senhas não coincidem'),
  });

  const loadUsuario = useCallback(async () => {
    const usuario = await UsuarioAPI.loadUsuarioById(user.idUsuario);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    setEmail(usuario?.emailProfissional!);
  }, [user]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      loadUsuario();
    }
  }, [loadUsuario, user]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleRedefinirSenha = useCallback(
    async (credentials: any) => {
      const data = {
        email,
        novaSenha: credentials.novaSenha,
        senhaLogin: credentials.senhaAtual,
      };

      const response: any = await AlterarSenhaAPI.sendAlterarSenha(
        data as PostAlterarSenhaDTO,
      );

      if (response?.status === 201) {
        close();
      }
    },
    [close, email],
  );

  const handleCancel = useCallback(() => {
    reset();
    close();
  }, [close, reset]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Redefinir senha"
      visible={isOpen}
      onHide={() => handleCancel()}
      footer={() => <div />}
    >
      <form
        className="modal-body-password-reset"
        onSubmit={handleSubmit(handleRedefinirSenha)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }: any) => (
            <PasswordInput
              label="Senha atual"
              className="grid p-col-12 p-md-12 mb-1"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              toggleMask
              errorMsg={errors.senhaAtual?.message}
            />
          )}
          name={'senhaAtual'}
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }: any) => (
            <PasswordInput
              label="Nova senha"
              className="grid p-col-12 p-md-12 mb-1"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              toggleMask
              errorMsg={errors.novaSenha?.message}
            />
          )}
          name={'novaSenha'}
        />

        <SimpleText
          className="p-pl-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          A nova senha precisa conter, pelo menos:
        </SimpleText>
        <ul className="p-pl-4">
          <li className="p-pl-1 danger-text">8 Dígitos</li>
          <li className="p-pl-1 danger-text">
            1 caractere especial (como !, @, #, etc.)
          </li>
          <li className="p-pl-1 danger-text">1 letra maíuscula</li>
          <li className="p-pl-1 danger-text">1 número</li>
        </ul>

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }: any) => (
            <PasswordInput
              label="Confirme nova senha"
              className="grid p-col-12 p-md-12 mb-1"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              toggleMask
              errorMsg={errors.confirmacaoSenha?.message}
            />
          )}
          name={'confirmacaoSenha'}
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Cancelar"
            onClick={() => handleCancel()}
            type="button"
            stretch
            loading={isSubmitting}
          />
          <Button
            type="submit"
            className="btn-password-reset"
            label={'Salvar'}
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default ModalPasswordReset;
