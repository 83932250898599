import { combineReducers, configureStore, Store } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auxiliarsReducer from 'src/core/redux/slices/system/AuxiliarsSlice';

import agendaReducer from './slices/agenda/AgendaSlice';
import atendimentoReducer from './slices/atendimento/AtendimentoSlice';
import queryReducer from './slices/query/QuerySlice';
import toastReducer from './slices/system/ToastSlice';
import userReducer from './slices/system/UserSlice';
import telemedicinaReducer from './slices/telemedicina/TelemecinaSlice';
import tissReducer from './slices/tiss/TissSlice';
import consultoriosReducer from './slices/user/ConsultoriosSlice';

const reducers = combineReducers({
  toast: toastReducer,
  user: userReducer,
  auxiliars: auxiliarsReducer,
  consultorios: consultoriosReducer,
  agenda: agendaReducer,
  atendimento: atendimentoReducer,
  query: queryReducer,
  telemedicina: telemedicinaReducer,
  tiss: tissReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store: Store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
