import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/equipe-carteira-aps';

export const getEquipe = (
  idConsultorio: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getEquipeById = (
  idEquipe: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe/${idEquipe}`,
    hideToast: true,
    ...options,
  });
};

export const postEquipe = (
  idConsultorio: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe`,
    data,
    return: true,
    ...options,
  });
};

export const putEquipe = (
  idEquipe: number,
  idConsultorio: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe/${idEquipe}`,
    data,
    return: true,
    ...options,
  });
};

export const putEquipeStatus = (
  idEquipe: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe/atualizar-status/${idEquipe}`,
    return: true,
    ...options,
  });
};

export const deleteEquipe = (
  idEquipe: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe/${idEquipe}`,
    ...options,
  });
};

export const getEquipeList = (
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/consultorio/${idConsultorio}/equipe/lista`,
    hideToast: true,
    ...options,
  });
};

export const EquipeAPI = {
  getEquipe,
  getEquipeById,
  postEquipe,
  putEquipe,
  putEquipeStatus,
  deleteEquipe,
  getEquipeList,
};
