import dayjs from 'dayjs';

type onLoadedProps = {
  onInit: () => void;
  onHide: () => void;
};

//* Add funcionalidades da memed
export function handleSetFeatureToggle(features: any): Promise<void> {
  return window.MdHub.command.send(
    'plataforma.prescricao',
    'setFeatureToggle',
    features,
  );
}

//* Add informações do paciente na memed
export async function handleSetPacienteInfo(
  data: any,
  aditional = {},
): Promise<void> {
  await window.MdHub.command.send('plataforma.prescricao', 'setPaciente', {
    idExterno: data.idPaciente,
    nome: data.nomePaciente,
    nome_social: data.nomePaciente,
    cpf: data.cpfPaciente,
    data_nascimento: dayjs(data.dataNascimentoPaciente).format(`DD/MM/YYYY`),
    endereco: data.enderecoPaciente,
    cidade: data.cidadePaciente,
    telefone: data.telefonePaciente,
    ...aditional,
  });
  await window.MdHub.command.send(
    'plataforma.prescricao',
    'setAllergy',
    data.alergias,
  );
}

//* Add item prescrição
export async function handleSetPrescricaoItem(item: any): Promise<void> {
  if (typeof item === 'object' && item?.length) {
    await Promise.all(
      item.map(async (element: any) => {
        await window.MdHub.command.send(
          'plataforma.prescricao',
          'addItem',
          element,
        );
      }),
    );

    return;
  }

  return window.MdHub.command.send('plataforma.prescricao', 'addItem', item);
}

//* Evento de iniciação de fechamento da memed

export function onLoadMemedScript({ onInit, onHide }: onLoadedProps) {
  window.MdSinapsePrescricao.event.add(
    'core:moduleInit',
    function moduleInitHandler(module: any) {
      if (module.name === 'plataforma.prescricao') {
        onInit();
      }
    },
  );

  window.MdSinapsePrescricao.event.add(
    'core:moduleHide',
    function moduloFechado(modulo: any) {
      if (modulo.moduleName === 'plataforma.prescricao') onHide();
      if (modulo.moduleName === 'platform.sms-modal') onHide();
      //remove style overflow que a memed subscreve
      document.querySelector('body')!.removeAttribute('style');
    },
  );
}

//* Abre modal da mememd
export function handleShowMemed() {
  window.MdHub.module.show('plataforma.prescricao');
}

//* Seta funcionalidade para salvar prescrição memed
export function addOnPrescricaoImpressa(fn: any) {
  window.MdHub.event.add(
    'prescricaoImpressa',
    function prescricaoImpressa(data: any) {
      fn(data || null);
    },
  );
}
