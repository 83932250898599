import { memo, useCallback, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import GridListLoading from 'src/components/GridList/GridListLoading';
import './SearchListFuncionalidades.scss';

import Row from './Row';

interface SearchListFuncionalidadesProps {
  data?: any;
  handleConfigFuncionalidade?: any;
}

const SearchListFuncionalidades = ({
  data,
  handleConfigFuncionalidade,
}: SearchListFuncionalidadesProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const loading = false;
  const hasMore = false;

  const renderRow = useCallback(
    (data: any) => {
      return (
        <Row
          key={data.funcionalidade.id}
          data={data}
          handleConfigFuncionalidade={handleConfigFuncionalidade}
        />
      );
    },
    [handleConfigFuncionalidade],
  );

  const dataFiltered: any = data.flatMap((functionalityModule: any) => {
    const funcFiltered = functionalityModule.data.filter((func: any) => {
      return (
        func.funcionalidade.descricao
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        func.funcionalidade.nome
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });

    return !!funcFiltered.length
      ? {
          ...functionalityModule,
          data: funcFiltered,
        }
      : [];
  });

  return (
    <div className="p-col-12">
      <div className={'SearchListFuncionalidades-funcionalidades-clientes'}>
        <div className="p-col-12 p-md-8 p-lg-6 p-mb-3">
          <TextInputSearch
            placeholder="Pesquise uma função"
            value={searchQuery}
            onChange={setSearchQuery}
          />
        </div>
        <div className="grid-list-clientes">
          {loading && hasMore ? (
            <GridListLoading />
          ) : dataFiltered.length === 0 ? (
            <Empty />
          ) : (
            <InfiniteScroll
              dataLength={dataFiltered}
              next={() => ({})}
              hasMore={false}
              loader={!hasMore ? <GridListLoading /> : <></>}
              height={400}
            >
              {dataFiltered &&
                Array.isArray(dataFiltered) &&
                (dataFiltered || []).map((data, index) => {
                  return (
                    <div key={index}>
                      {data?.data?.length > 0 && (
                        <div className="p-d-flex p-flex-column p-mb-4">
                          <SimpleText
                            bold
                            className="p-mb-3"
                            fontColor={FONT_COLOR.COLOR_60}
                            fontSize={FONT_SIZE.XS}
                          >
                            {data?.type}
                          </SimpleText>
                          {data?.data?.map((d: any, i: number) => renderRow(d))}
                        </div>
                      )}
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

const Empty = memo(() => {
  return (
    <div className="grid-list-content-empty">
      <SimpleText>
        Nenhum registro foi encontrado. Por favor, tente refazer a busca.
      </SimpleText>
    </div>
  );
});

export default memo(SearchListFuncionalidades);
