import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { CartoesPacienteAPI } from 'src/APIs/AdminAPI/CartoesPacienteAPI/CartoesPacienteAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { FieldNumeroCartaoConvenio } from 'src/components/Fields/FieldNumeroCartaoConvenio/FieldNumeroCartaoConvenio';
import { FieldTipoPlano } from 'src/components/Fields/FieldTipoPlano/FieldTipoPlano';
import FormInput from 'src/components/FormInput/FormInput';

interface ModalPlanoSaudeProps {
  idPaciente: number;
  visible: boolean;
  editPlanoSaude: any;
  onHide: () => void;
  onSuccess: () => void;
  cartoesLength: number;
}

const validationSchema = Yup.object().shape({
  convenio: Yup.object()
    .nullable()
    .required('O campo plano de saúde é obrigatório'),
  numeroCartao: Yup.string().required(
    'O campo número da carteirinha é obrigatório',
  ),
  dataValidadeCartao: Yup.date()
    .nullable()
    .required('O campo validade é obrigatório'),
});

const ModalPlanoSaude = ({
  idPaciente,
  visible,
  editPlanoSaude,
  onHide,
  onSuccess,
  cartoesLength,
}: ModalPlanoSaudeProps) => {
  const hasCartoes = !!(cartoesLength - 1);

  const useFormMethods = useForm({
    defaultValues: {
      convenio: editPlanoSaude?.convenio || null,
      numeroCartao: editPlanoSaude?.numeroCartao || '',
      tipoPlano: editPlanoSaude?.tipoPlano || '',
      outroTipoPlano: editPlanoSaude?.outroTipoPlano || '',
      principal: editPlanoSaude?.principal || !hasCartoes,
      dataValidadeCartao: editPlanoSaude?.dataValidadeCartao
        ? new Date(editPlanoSaude?.dataValidadeCartao)
        : null,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    const payload = {
      id: editPlanoSaude?.id || undefined,
      idConvenio: data.convenio.id,
      idTipoPlano: data.tipoPlano?.id,
      outroTipoPlano: data.outroTipoPlano,
      numeroCartao: data.numeroCartao.match(/\d/g).join(''),
      dataValidadeCartao: data.dataValidadeCartao,
      principal: data.principal,
      idPaciente,
    };

    const { saveCartao, updateCartao } = CartoesPacienteAPI;

    const saveAction = !!editPlanoSaude ? updateCartao : saveCartao;

    const response = await saveAction(payload);

    if (response?.status === 200) {
      onSuccess();
      onHide();
    }
  };

  const handleConvenioChange = () => {
    setValue('numeroCartao', '');
    setValue('tipoPlano', '');
  };

  const isCartaoInativo = !editPlanoSaude?.status;

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-plano-saude"
      type="modal"
      header={`${
        !!editPlanoSaude ? 'Editar' : 'Adicionar novo'
      } plano de saúde`}
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
      maximizedMobileSize
    >
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-grid">
          <FieldConvenio
            name="convenio"
            label="Plano de saúde"
            placeholder="Selecione"
            customOnChange={handleConvenioChange}
          />

          <FieldTipoPlano />

          <FormInput
            className="p-col-12"
            name="outroTipoPlano"
            label="Outro tipo de plano"
            maxLength={50}
            hideTextErrorSpace
          />

          <FieldNumeroCartaoConvenio
            label="Número da carteirinha"
            convenioFieldName="convenio"
            editPlanoSaude={editPlanoSaude}
          />

          <CalendarInputControlled
            className="p-col-12 p-sm-6"
            control={control}
            name="dataValidadeCartao"
            label="Validade"
            placeholder="dd/mm/aaaa"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            yearRange={
              new Date().getFullYear() -
              20 +
              ':' +
              (new Date().getFullYear() + 10)
            }
            errorMsg={errors.dataValidadeCartao?.message}
          />

          <div className="p-col-12">
            <CheckboxControlled
              className="checkbox-small"
              control={control}
              name="principal"
              label="Marcar como principal"
              disabled={!hasCartoes || isCartaoInativo}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalPlanoSaude;
