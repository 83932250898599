import {
  LoadPrescricao,
  LoadPrescricaoFavoritos,
  PrescricaoPayload,
} from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/prescricao';

const loadPrescricao = async (
  idAtendimento: string,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<LoadPrescricao[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}`,
    hideToast: true,
    params,
    ...options,
  });
};

const loadPrescricaoExecucao = async (
  idAtendimento: string,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<LoadPrescricao[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/atendimento/execucao/${idAtendimento}`,
    hideToast: true,
    params,
    ...options,
  });
};

const createPrescricao = async (
  idAtendimento: string,
  payload: PrescricaoPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}`,
    data: payload,
    ...options,
  });
};

const updatePrescricao = async (
  idAtendimento: string,
  payload: PrescricaoPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}`,
    data: payload,
    ...options,
  });
};
const deletePrescricao = async (
  idPrescricao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/atendimento/${idPrescricao}`,
    ...options,
  });
};

const getPrescricaoVias = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/via-medicamentos`,
    hideToast: true,
    ...options,
  });
};

const loadPrescricaoFavoritos = async (
  idMedico: string,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPrescricaoFavoritos[]> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/favoritos/${idMedico}`,
    hideToast: true,
    params,
    ...options,
  });
};

const createPrescricaoFavoritos = async (
  idMedico: string,
  payload: PrescricaoPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/favoritos/${idMedico}`,
    data: payload,
    ...options,
  });
};

const deletePrescricaoFavoritos = async (
  idPrescricaoFavorito: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/favoritos/${idPrescricaoFavorito}`,
    ...options,
  });
};

const loadPrescricaoHistoricoPaciente = async (
  idPaciente: string,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<LoadPrescricaoFavoritos[]> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/historicos/${idPaciente}`,
    hideToast: true,
    params,
    ...options,
  });
};

const sendPrescricaoExecutar = async (
  idAtendimento: string,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/atendimento/encaminhar-para-execucao/${idAtendimento}`,
    data: payload,
    ...options,
  });
};

const sendPrescricaoIniciarExecucao = async (
  idAtendimento: string | number,
  idProfissional: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}/iniciar-execucoes/profissional-saude/${idProfissional}`,
    hideToast: true,
    ...options,
  });
};

const sendPrescricaoFinalizarExecucao = async (
  idAtendimento: string | number,
  payload?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/atendimento/finalizar-execucoes/${idAtendimento}`,
    hideToast: true,
    data: payload,
    ...options,
  });
};

const updatePrescricaoDadosExecucao = async (
  idPrescricao: string | number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/adicionar-dados-execucao`,
    data: payload,
    ...options,
  });
};

const getPrescricaoMateriais = async (
  idPrescricao: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/prescricao-materiais`,
    hideToast: true,
    ...options,
  });
};

const createPrescricaoMateriais = async (
  idPrescricao: string | number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/prescricao-materiais`,
    data: payload,
    ...options,
  });
};

const updatePrescricaoMateriais = async (
  idPrescricao: string | number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/prescricao-materiais`,
    data: payload,
    ...options,
  });
};

const deletePrescricaoMateriais = async (
  idPrescricao: number | string,
  idPrescricaoMaterial: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/remover-material/${idPrescricaoMaterial}`,
    ...options,
  });
};

const getPrescricaoMedicamentos = async (
  idPrescricao: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/prescricao-medicamentos`,
    hideToast: true,
    ...options,
  });
};

const inativarPrescricao = async (
  idPrescricao: string | number,
  idAtendimento: string | number,
  payload: { justificativaInativacao: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${idPrescricao}/atendimento/${idAtendimento}/inativar`,
    data: payload,
    ...options,
  });
};

const pausarPrescricao = async (
  idAtendimento: string | number,
  idProfissionalSaude: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}/pausar-execucoes/profissional-saude/${idProfissionalSaude}`,
    ...options,
  });
};

const descartarPrescricao = async (
  idAtendimento: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.post({
    service: 'pep',
    url: `prescricao/atendimento/${idAtendimento}/descartar-execucao`,
    ...options,
  });
};

export const PrescricaoAPI = {
  loadPrescricao,
  createPrescricao,
  updatePrescricao,
  deletePrescricao,
  getPrescricaoVias,
  loadPrescricaoFavoritos,
  createPrescricaoFavoritos,
  deletePrescricaoFavoritos,
  loadPrescricaoHistoricoPaciente,
  sendPrescricaoExecutar,
  sendPrescricaoIniciarExecucao,
  sendPrescricaoFinalizarExecucao,
  updatePrescricaoDadosExecucao,
  getPrescricaoMateriais,
  createPrescricaoMateriais,
  updatePrescricaoMateriais,
  deletePrescricaoMateriais,
  getPrescricaoMedicamentos,
  inativarPrescricao,
  pausarPrescricao,
  loadPrescricaoExecucao,
  descartarPrescricao,
};
