import { useFormContext } from 'react-hook-form';

import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import { questionarioExameSimOuNaoOptions } from '../../../helpers';

interface ExameImagemFieldsProps {
  hasQuestionarioImg: boolean;
}

export const ExameImagemFields = (props: ExameImagemFieldsProps) => {
  const { hasQuestionarioImg } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!hasQuestionarioImg) return null;

  return (
    <>
      <SimpleText
        className="p-col-12 p-mt-3 p-px-0"
        fontColor={FONT_COLOR.COLOR_40}
        medium
      >
        Questionário exame de imagem
      </SimpleText>

      <Card className="p-grid p-mt-2" type={CARD_TYPES.BORDER}>
        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="questionarioSolicitacaoImg.historiaClinica"
          label="1 - História Clínica e Exames Fisio pertinentes a patologia e/ou segmento corpóreo objeto da presente solicitação?"
          maxLength={100}
          errorMsg={errors.questionarioSolicitacaoImg?.historiaClinica?.message}
        />

        <DropdownControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacaoImg.possuiSubsidiarios"
          label="2 - Possui ou tem conhecimento de Exames Subsidiários relacionados a patologia/segmento corpóreo objeto da presente solicitação?"
          options={questionarioExameSimOuNaoOptions}
          filter={false}
          errorMsg={
            errors.questionarioSolicitacaoImg?.possuiSubsidiarios?.message
          }
        />

        <DropdownControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacaoImg.dentroParametros"
          label="4 - A presente solicitação se encontra dentro dos paramentos determinados pelo Protocolo de Especialidade?"
          options={questionarioExameSimOuNaoOptions}
          filter={false}
          errorMsg={
            errors.questionarioSolicitacaoImg?.dentroParametros?.message
          }
        />

        <TextareaInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacaoImg.subsidiarios"
          label="3 - Descreva o(s) Laudo(s) de Exames Subsidiários relacionados a patologia/segmento corpóreo objeto da presente solicitação:"
          maxLength={100}
          errorMsg={errors.questionarioSolicitacaoImg?.subsidiarios?.message}
        />

        <TextareaInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="questionarioSolicitacaoImg.motivo"
          label="5 - De acordo com as respostas aos itens atenores, qual(is) o(s) motivos(s) para a indicação da realização deste exame de imagem?"
          maxLength={100}
          errorMsg={errors.questionarioSolicitacaoImg?.motivo?.message}
        />
      </Card>
    </>
  );
};
