import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';

interface PanelContentProps {
  documentos: Documentos;
}

function PanelContent({ documentos }: PanelContentProps) {
  const headerTextClassName = `p-mb-2 ${
    documentos.status === 'INATIVO' ? 'opme-inativa' : ''
  }`;
  const dataTextClassName =
    documentos.status === 'INATIVO' ? 'line-through opme-inativa' : '';

  return (
    <div className="opme-panel-content p-grid">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {documentos.descricao}
        </SimpleText>
      </div>
      {documentos.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {documentos.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{documentos.usuarioInclusaoNome}</b> em{' '}
          {dayjs(documentos.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
}

export default PanelContent;
