import React, { useCallback, useState } from 'react';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { EmptyData } from '../../../components/EmptyData';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalJustificativa from 'src/pages/Emed/Prontuario/FichaPaciente/PreAtendimento/ModalJustificativa/ModalJustificativa';

import Aparencia from './Aparencia/Aparencia';
import CircunferenciaAbdominal from './CircunferenciaAbdominal/CircunferenciaAbdominal';
import CircunferenciaBraco from './CircunferenciaBraco/CircunferenciaBraco';
import CircunferenciaCintura from './CircunferenciaCintura/CircunferenciaCintura';
import CircunferenciaPanturrilha from './CircunferenciaPanturrilha/CircunferenciaPanturrilha';
import CircunferenciaQuadril from './CircunferenciaQuadril/CircunferenciaQuadril';
import DorPaciente from './DorPaciente/DorPaciente';
import GlicemiaCapilar from './GlicemiaCapilar/GlicemiaCapilar';
import ObservacaoGeral from './ObservacaoGeral/ObservacaoGeral';
import PerimetroCefalico from './PerimetroCefalico/PerimetroCefalico';
import PesoAltura from './PesoAltura/PesoAltura';
import Pressao from './Pressao/Pressao';
import Pulso from './Pulso/Pulso';
import Respiracao from './Respiracao/Respiracao';
import Saturacao from './Saturacao/Saturacao';
import Temperatura from './Temperatura/Temperatura';

export default function HistoricoPreAtendimento() {
  const [selectedTipo, setSelectedTipo] = useState('');
  const [updateList, setUpdateList] = useState<number>(0);
  const [visualizarJustificativa, setVisualizarJustificativa] = useState(false);
  const [preAtendimentoInativacao, setPreAtendimentoInativacao] =
    useState<PreAtendimento | null>(null);
  const { selectedAtendimento, quantidadePreAtendimento } =
    useHistoricoAtendimento();

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
  };

  const onVisualizarJustificativa = (preAtendimento: PreAtendimento) => {
    setPreAtendimentoInativacao(preAtendimento);
    setVisualizarJustificativa(true);
  };

  const optionsMenu = (preAtendimento: any) => (
    <PanelOptionsMenu
      ativo={preAtendimento.status === 'ATIVO'}
      idAtendimentoRegistro={preAtendimento.idAtendimento}
      idUsuarioCriacao={preAtendimento.usuarioAtualizacao.id}
      onAlterarLembrete={() => null}
      onEdit={() => {}}
      onInativar={() => {}}
      onExcluir={() => {}}
      onVisualizarJustificativa={() =>
        onVisualizarJustificativa(preAtendimento)
      }
      hideLembreteOption
      hideEditOption
      showEditOnlyAtendimentoCorrente
    />
  );

  const renderPreAtendimento = () => {
    return (
      <>
        <Pressao
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <PesoAltura
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <Saturacao
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <CircunferenciaAbdominal
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <CircunferenciaQuadril
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <CircunferenciaPanturrilha
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <CircunferenciaCintura
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <CircunferenciaBraco
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <DorPaciente
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <GlicemiaCapilar
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <Temperatura
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        <Aparencia
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <Pulso
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <Respiracao
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <PerimetroCefalico
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />
        <ObservacaoGeral
          panelHeaderOptionsMenu={optionsMenu}
          updateList={updateList}
          selectedTipo={selectedTipo}
          selectedAtendimento={selectedAtendimento}
        />

        {quantidadePreAtendimento === 0 && (
          <div className="content-empty-row">
            <EmptyData />
          </div>
        )}
      </>
    );
  };

  const fetchApi = useCallback(async (params: any) => {
    return null;
  }, []);

  return (
    <div>
      <SearchList.Root fetchApi={fetchApi}>
        <SearchList.Header>
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            className="p-mb-2"
            medium
          >
            Pré-Atendimento
          </SimpleText>
        </SearchList.Header>
        <div className="pre-atendimento-historico">
          {renderPreAtendimento()}
        </div>
      </SearchList.Root>
      <ModalJustificativa
        preAtendimento={preAtendimentoInativacao}
        selectedTipo={selectedTipo}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setPreAtendimentoInativacao(null);
          setVisualizarJustificativa(false);
        }}
        onSuccess={reloadList}
      />
    </div>
  );
}
