import { useAGA } from 'src/core/hooks/AGA/useAGA';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import FormularioAGA from './components/FormularioAGA/FormularioAGA';
import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import Skeleton from 'src/components/Skeleton/Skeleton';
import { scrollAllDivsToTop } from 'src/utils/utils';

interface QuestionarioAGAProps {
  idPaciente: number | null;
  idadePaciente: number | null;
}

const QuestionarioAGA = ({
  idPaciente,
  idadePaciente,
}: QuestionarioAGAProps) => {
  const {
    loading,
    idFormularioPai,
    isEdit,
    setIsEdit,
    cancelPreenchimentoFormulario,
  } = useAGA();
  const { atendimentoStatus } = useAtendimento();

  if (atendimentoStatus !== 'ATENDENDO')
    return (
      <AlertBox
        visible
        text="Inicie ou continue o atendimento para responder ao questionário "
      />
    );

  return (
    <div id="questionario-AGA" className="p-col-12 p-p-0">
      <ElevatedCard className="neutral-background" noMargin>
        <div className="p-d-flex p-jc-between p-ai-center">
          <div className="p-px-2 p-d-flex">
            {isEdit !== null && (
              <SimpleText
                className={`p-mr-2 ${isEdit ? 'tag-edit' : 'tag-novo'}`}
                medium
              >
                {isEdit ? 'Editando' : 'Novo'}
              </SimpleText>
            )}

            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Questionário Idoso bem cuidado (AGA)
            </SimpleText>
          </div>

          {isEdit === null ? (
            <Button
              btnType="tonal"
              icon="fas fa-plus"
              label="Novo Questionario"
              onClick={() => [setIsEdit(false), scrollAllDivsToTop()]}
            />
          ) : (
            <Button
              btnType="green-link"
              label="Cancelar"
              onClick={() => cancelPreenchimentoFormulario()}
            />
          )}
        </div>
        <>
          {loading ? (
            <div>
              <Skeleton
                className="p-my-2 p-px-3"
                height={'450px'}
                loading={true}
              />
            </div>
          ) : (
            <>
              {isEdit !== null && (
                <FormularioAGA
                  idFormularioPai={idFormularioPai}
                  idPaciente={idPaciente}
                  idadePaciente={idadePaciente}
                />
              )}
            </>
          )}
        </>
      </ElevatedCard>
    </div>
  );
};

export default QuestionarioAGA;
