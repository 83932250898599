import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import './GestacaoCard.scss';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

interface GestacaoCardProps {
  showOnlyGestacaoAtual?: boolean;
}

const GestacaoCard = (props: GestacaoCardProps) => {
  const { showOnlyGestacaoAtual } = props;

  const { preNatalAtual, loadingPreNatal, selectedGestacao } = usePreNatal();

  if (!preNatalAtual && !selectedGestacao) return null;

  const gestacao = showOnlyGestacaoAtual
    ? preNatalAtual
    : selectedGestacao || preNatalAtual;

  if (!gestacao && !loadingPreNatal) return null;

  return (
    <>
      {gestacao?.id !== preNatalAtual?.id ? (
        <div className="p-my-1" id="gestacao-card-container">
          {gestacao?.fetos?.map((feto: any, index: any) => (
            <div key={index} className="p-my-1">
              <Panel
                key={index}
                header={(isCollapsed, onToggle) => (
                  <PanelHeader
                    index={index}
                    gestacao={gestacao}
                    isCollapsed={isCollapsed}
                    onToggle={onToggle}
                  />
                )}
              >
                <PanelContent gestacao={gestacao} index={index} />
              </Panel>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton loading={loadingPreNatal} height="55px" borderRadius="8px">
          <div id="gestacao-card-container" className="p-d-flex p-jc-between">
            <div className="p-d-flex">
              <Avatar icon="fas fa-person-pregnant" />

              <div className="p-d-flex p-flex-column p-ml-3">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  Gestação
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                >
                  {gestacao?.tipoGestacao?.split('_')[1]}
                </SimpleText>
              </div>
            </div>

            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Idade gestacional
              </SimpleText>
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {gestacao?.idadeGestacional}
              </SimpleText>
            </div>

            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Sexo
              </SimpleText>
              <ul>
                {gestacao?.fetos.map((feto: any) => (
                  <li key={feto.id}>
                    <SimpleText
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {capitalizeFirstLetter(
                        feto?.sexo?.descricao || 'Não informado',
                      )}
                    </SimpleText>
                  </li>
                ))}
              </ul>
            </div>

            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Nome do bebê
              </SimpleText>
              <ul>
                {gestacao?.fetos.map((feto: any) => (
                  <li key={feto.id}>
                    <SimpleText
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_16}
                    >
                      {feto.nome}
                    </SimpleText>
                  </li>
                ))}
              </ul>
            </div>

            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Nome do pai
              </SimpleText>
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {gestacao?.nomePai}
              </SimpleText>
            </div>
          </div>
        </Skeleton>
      )}
    </>
  );
};

export default GestacaoCard;
