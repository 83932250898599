import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  cidCiap: any;
  optionsMenu: JSX.Element;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  cidCiap,
  optionsMenu,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName =
    cidCiap.ativo === true ? '' : 'line-through row-inativa';

  return (
    <div className="p-grid p-col-12 panel-header-prontuario">
      <div className="p-col-12 p-sm-5 p-md-6 p-d-flex p-ai-center">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          ({cidCiap.codigo}) {capitalizeFirstLetter(cidCiap.nome)}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-3 p-d-flex p-ai-center p-jc-between">
        {cidCiap.retroativo && (
          <div className="retroativo-chip">
            <SimpleText fontSize={FONT_SIZE.XXXS} medium>
              Retroativo
            </SimpleText>
          </div>
        )}
        {cidCiap.idComorbidade && (
          <div className="comorbidade-chip">
            <SimpleText fontSize={FONT_SIZE.XXXS} medium>
              Problema
            </SimpleText>
          </div>
        )}
      </div>
      <div className="p-col-6 p-sm-3 p-d-flex p-jc-end">
        {optionsMenu}
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
