import { useMemo, useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import { FormularioDinamicoAPI } from 'src/APIs/AdminAPI/FormularioDinamicoAPI/FormularioDinamicoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter, checkAuth } from 'src/utils/utils';

import { DialogPontuacao } from '../_components/DialogPontuacao/DialogPontuacao';
import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

export const FormularioDinamicoAcervoFormulariosRow = ({
  item: responseItem,
  onDelete,
  handleView,
  handleEdit,
  handleCopy,
}: {
  item: IFormularioDinamicoItem;
  onDelete(state?: any): void;
  handleReload(): void;
  handleView(id: number): void;
  handleEdit(id: number): void;
  handleCopy(item: IFormularioDinamicoItem): void;
}) => {
  const {
    user: { authorities },
  } = useAppSelector(state => state);

  const dialogPontuacao = useDisclosure({ opened: false });

  const [item, setItem] = useState<IFormularioDinamicoItem>(responseItem);
  const [status, setStatus] = useState<boolean>(item.status === 'ATIVO');

  const { windowInnerWidth } = useSize();
  const menu = useRef<TieredMenu>(null);

  const handleChangeStatus = async (e: boolean) => {
    setStatus(e);
    try {
      const res = await FormularioDinamicoAPI.updateFormularioDinamico(
        {
          ...item,
          status: e ? 'ATIVO' : 'INATIVO',
        },
        { throwError: true },
      );

      setItem(res);
    } catch {
      return setStatus(prev => !prev);
    }
  };

  const canView = checkAuth(
    authorities,
    'ADM_MANTER_FORMULARIO_DINAMICO_CONSULTAR',
  );

  const canEdit =
    item.status === 'RASCUNHO' &&
    checkAuth(authorities, 'ADM_MANTER_FORMULARIO_DINAMICO_ALTERAR');

  const canCopy = checkAuth(
    authorities,
    'ADM_MANTER_FORMULARIO_DINAMICO_CADASTRAR',
  );

  const canDelete =
    item.status === 'RASCUNHO' &&
    checkAuth(authorities, 'ADM_MANTER_FORMULARIO_DINAMICO_EXCLUIR');

  const itemsOptions: MenuItem[] = [
    {
      label: 'Visualizar',
      command: () => handleView(item.id),
      disabled: !canView,
      className: canView ? '' : 'p-d-none',
    },
    {
      label: 'Editar',
      command: () => handleEdit(item.id),
      disabled: !canEdit,
      className: canEdit ? '' : 'p-d-none',
    },
    {
      label: 'Copiar',
      command: () => handleCopy(item),
      disabled: !canCopy,
      className: canCopy ? '' : 'p-d-none',
    },
    {
      label: 'Excluir',
      command: () => onDelete(),
      disabled: !canDelete,
      className: `error ${canDelete ? '' : 'p-d-none'}`,
    },
  ];

  const customWidth = useMemo(() => windowInnerWidth < 938, [windowInnerWidth]);

  return (
    <div className="p-d-flex p-flex-wrap row content-row p-ai-center p-mb-2">
      <div className="p-col-12 p-sm-6 p-md-2 p-xl-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Nome
        </SimpleText>
        <SimpleText className="p-wordbreak-normal">{item.nome}</SimpleText>
      </div>
      <div className="p-col-5 p-md-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Tipo
        </SimpleText>
        <SimpleText>{capitalizeFirstLetter(item.tipo)}</SimpleText>
      </div>
      <div
        className="p-col-5 p-sm-2 p-md-2 p-d-flex p-flex-column p-gap-2 cursor-pointer"
        onClick={() => dialogPontuacao.open()}
      >
        <SimpleText
          className="pointer-events-none"
          fontSize="xxs"
          fontColor="color_60"
        >
          Pontuação
        </SimpleText>
        <SimpleText className="pointer-events-none" fontColor="primary">
          {item.pontuacoes?.length
            ? `${item.pontuacoes.length} intervalo(s)`
            : 'Sem pontuação'}
        </SimpleText>
      </div>

      <div className="p-col-2 p-sm-2 p-md-1 p-text-center">
        {item.status === 'RASCUNHO' && (
          <span
            className="tag-helper-outline p-wordbreak-normal"
            style={{
              fontSize: customWidth ? '0.6rem' : '0.7rem',
              minWidth: '100%',
            }}
          >
            Rascunho
          </span>
        )}
      </div>

      <div className="p-col-6 p-sm-4 p-md-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Ultima alteração
        </SimpleText>
        <SimpleText>
          {item.dataAtualizacao
            ? dayjs(item.dataAtualizacao).format('DD/MM/YYYY')
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-gap-2 p-jc-end p-ai-center">
        <Switch
          onChange={e => handleChangeStatus(e.target.value)}
          checked={status}
          checkPermission={'ADM_MANTER_FORMULARIO_DINAMICO_ALTERAR'}
          showStatus
          checkedLabel="Ativo"
          uncheckedLabel="Inativo"
        />
        <Divider layout="vertical" className="p-px-0 p-mx-0" />
        <Button
          btnType="gray"
          icon="fas fa-ellipsis"
          onClick={event => menu?.current?.toggle(event)}
        />

        <TieredMenu
          className="tiered-menu"
          style={{
            width: 'fit-content',
            minWidth: '110px',
          }}
          model={itemsOptions}
          popup
          ref={menu}
        />
      </div>

      {dialogPontuacao.isOpen && (
        <DialogPontuacao {...dialogPontuacao} state={item} />
      )}
    </div>
  );
};
