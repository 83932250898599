import { memo, useCallback } from 'react';

import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalExcluirFornecedorTuss.scss';

interface ModalExcluirFornecedorTussProps {
  visible: boolean;
  onHide(): void;
  reload: any;
  fornecedorTuss: FornecedorTuss;
  fornecedorTussList: FornecedorTuss[];
  setFornecedorTussList: any;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button
        btnType="danger"
        className={'danger'}
        label="Sim, excluir"
        onClick={onAdd}
        stretch
      />
    </div>
  );
});

const ModalExcluirFornecedorTuss = ({
  fornecedorTuss,
  visible,
  onHide,
  fornecedorTussList,
  reload,
  setFornecedorTussList,
}: ModalExcluirFornecedorTussProps) => {
  const handleDeletarFornecedorTuss = useCallback(async () => {
    const response: any = await FornecedorTussAPI.removeFornecedorTussById(
      fornecedorTuss.id!,
    );

    if (response.status === 204) {
      const fornecedorTussRemovida = fornecedorTussList.filter(
        c => c.id !== fornecedorTuss.id,
      );
      setFornecedorTussList([...fornecedorTussRemovida]);

      onHide();
      reload();
    }
  }, [
    fornecedorTuss.id,
    fornecedorTussList,
    onHide,
    reload,
    setFornecedorTussList,
  ]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Excluir fornecedor TUSS"
      visible={visible}
      onHide={onHide}
      footer={() => (
        <Footer onAdd={handleDeletarFornecedorTuss} onCancel={onHide} />
      )}
    >
      <>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Você confirma a exclusão do fornecedor TUSS?
        </SimpleText>
      </>
    </Dialog>
  );
};

export default ModalExcluirFornecedorTuss;
