import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isTablet } = useSize();
  if (isTablet) return null;

  return (
    <>
      {isTablet === false && (
        <div className="p-grid p-mt-1 p-ml-2 p-mr-1 p-ml-4">
          <div className="p-col-3">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Nome
            </SimpleText>
          </div>

          <div className="p-col-3">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Convênio
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Sexo
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Pontuação
            </SimpleText>
          </div>

          <div className="p-col-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              idade
            </SimpleText>
          </div>

          <div className="p-col-1 p-d-flex p-jc-end">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Ações
            </SimpleText>
          </div>
        </div>
      )}
    </>
  );
};
