import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  justificativaTecnica: Yup.string().required('O campo nome é obrigatório.'),
  anexoOpmeServicoTuss: Yup.array()
    .of(
      Yup.object({
        idServicoTussFornecedorOpme: Yup.number().required(
          'O campo Fabricante é obrigatório',
        ),
      }),
    )
    .min(1, 'Pelo menos um OPME precisa ser adicionado.'),
});
