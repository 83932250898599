import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import List from './List/List';

import './RelatoriosFaturamento.scss';

const RelatoriosFaturamento = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);

  return (
    <Page id="relatorio-faturamento-container" white>
      <>
        <PageHeader title={'Relatório de faturamento'} subtitle={''} />
        {!consultorios?.ativo?.id ? (
          <AlertBox
            visible={true}
            text="Você precisa selecionar um Consultório para esta funcionalidade!"
          />
        ) : (
            <List/>
        )}
      </>
    </Page>
  );
};

export default RelatoriosFaturamento;
