import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

interface Props {
  auditoriaColumns: any[];
  isLoading: boolean;
}
const PanelContent = (props: Props) => {
  const { auditoriaColumns, isLoading } = props;

  if (isLoading) {
    return <GridListLoading />;
  }

  return (
    <div className="auditoria-panel-content">
      {auditoriaColumns.map(auditoriaColumn => (
        <div
          key={auditoriaColumn.id}
          className="auditoria-column-card p-grid bg-96 p-align-center p-justify-between p-p-0 p-m-0"
        >
          <div className="p-col-4 p-md-3 p-lg-2 p-d-flex p-flex-column">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
              className="p-mb-1"
            >
              Num. id
            </SimpleText>
            <SimpleText>{auditoriaColumn?.id}</SimpleText>
          </div>
          <div className="p-col-6 p-md-5 p-lg-4 p-d-flex p-flex-column">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
              className="p-mb-1"
            >
              Coluna
            </SimpleText>
            <SimpleText>{auditoriaColumn?.nomeColuna}</SimpleText>
          </div>
          <div className="p-col-6 p-md-4 p-lg-3 p-d-flex p-flex-column">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
              className="p-mb-1"
            >
              Valor antigo
            </SimpleText>
            <SimpleText>
              {auditoriaColumn?.valorAnterior || (
                <SimpleText fontColor="color_60">
                  Valor não encontrado
                </SimpleText>
              )}
            </SimpleText>
          </div>
          <div className="p-col-6 p-md-4 p-lg-3 p-d-flex p-flex-column">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
              className="p-mb-1"
            >
              Valor novo
            </SimpleText>
            <SimpleText>
              {auditoriaColumn?.novoValor ? (
                <b>{auditoriaColumn?.novoValor}</b>
              ) : (
                <SimpleText fontColor="color_60">
                  Valor não encontrado
                </SimpleText>
              )}
            </SimpleText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PanelContent;
