import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  pacote: any;
  onSwitchStatus: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { pacote, onSwitchStatus, onEdit, onDelete } = props;

  const options = [
    {
      label: pacote.status ? 'Inativar' : 'Ativar',
      command: onSwitchStatus,
      checkPermission: 'ADM_PACOTES_ALTERAR',
    },
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_PACOTES_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: onDelete,
      checkPermission: 'ADM_PACOTES_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {pacote?.codigoPacote}
        </SimpleText>
      </div>

      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {pacote.nomePacote}
        </SimpleText>
      </div>

      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Convênio
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {pacote.convenio?.nomeFantasia || pacote.convenio.razaoSocial}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
