// import { Container } from './styles';

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';
import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tooltip from 'src/components/Tooltip/Tooltip';

import SearchTuss from './SearchTuss/SearchTuss';
import { validationSchema } from './validation';

import './AddValoresServicos.scss';

const AddValoresServicos = () => {
  const { user, agenda } = useAppSelector((state: RootState) => state);

  const navigate = useNavigate();
  const tussRef = useRef<OverlayPanel>(null);

  const [conveniosListAPI, setConveniosListAPI] = useState<any[]>([]);
  const [profissionais, setProfissionais] = useState<any[]>([]);
  const [convenioDisabled, setConvenioDisabled] = useState(true);
  const [loadingProfissionais, setLoadingProfissionais] = useState(true);
  const [loadingConvenios, setLoadingConvenios] = useState(false);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'listServicoTuss',
  });

  const loadConvenios = useCallback(
    async (id: number) => {
      try {
        setLoadingConvenios(true);
        const response = await ConvenioAPI.loadConveniosByProfissional(id, {
          throwError: true,
        });
        setConveniosListAPI(response);
      } catch (error) {
        navigate(-1);
      } finally {
        setLoadingConvenios(false);
      }
    },
    [navigate],
  );

  const loadProfissionais = useCallback(
    async (nome: string) => {
      try {
        setLoadingProfissionais(true);

        const response = await ProfissionalAPI.loadProfissionaisSaudeBySearch(
          {
            nome:
              user.tipoProfissionalSaude === 'MEDICO' ||
              user.tipoProfissionalSaude === 'MEDICA'
                ? agenda.profissionalAtivo?.nome
                : nome,
          },
          { throwError: true },
        );

        setProfissionais(response.list);
      } catch (error) {
        navigate(-1);
      } finally {
        setLoadingProfissionais(false);
      }
    },
    [agenda.profissionalAtivo?.nome, navigate, user.tipoProfissionalSaude],
  );

  useLayoutEffect(() => {
    loadProfissionais('');
  }, [loadProfissionais]);

  useEffect(() => {
    if (
      user.tipoProfissionalSaude === 'MEDICO' ||
      user.tipoProfissionalSaude === 'MEDICA'
    ) {
      setValue('nome', agenda.profissionalAtivo?.id);
    }
  }, [agenda.profissionalAtivo, setValue, user.tipoProfissionalSaude]);

  const watchProfissional = watch('nome');
  const watchListTuss = watch('listServicoTuss');

  useMemo(() => {
    setValue('convenios', []);
    setValue('listServicoTuss', undefined);
    if (watchProfissional !== '' && watchProfissional !== undefined) {
      loadConvenios(watchProfissional);
      setConvenioDisabled(false);
    } else {
      setConvenioDisabled(true);
    }
  }, [loadConvenios, setValue, watchProfissional]);

  const onSubmit = async (data: any) => {
    data?.listServicoTuss?.map((servico: any) => {
      servico.valor = Number(servico.valor);
      servico.idProfissionalSaude = data?.nome;
    });

    const payload = {
      ...data,
      idProfissionalSaude: data?.nome,
    };

    const response = await ServicoTussValorAPI.sendServicoTussValor(payload);

    if (response?.status === 201 || response?.status === 200) {
      navigate(-1);
    }
  };

  const handleSelectedTuss = useCallback(
    (selectedTuss?: any) => {
      selectedTuss.map((tuss: any) => {
        append({
          idServicoTuss: tuss.id,
          nome: tuss?.nome,
          codigo: tuss.codigo,
          idConvenio: tuss.idConvenio,
          nomeConvenioAndTuss: `${tuss?.convenio?.razaoSocial} - ${tuss?.codigo} - ${tuss?.nome}`,
          valor: null,
        });
      });
      tussRef.current?.hide();
    },
    [append],
  );

  const idsConvenio = watch('convenios');

  useEffect(() => {
    if (
      user.tipoProfissionalSaude === 'MEDICO' ||
      user.tipoProfissionalSaude === 'MEDICA'
    ) {
      setValue('nome', agenda.profissionalAtivo.id);
    }
  }, [agenda.profissionalAtivo, setValue, user.tipoProfissionalSaude]);

  const renderTuss = useCallback(() => {
    const items = fields;
    return (
      <div className="tuss">
        {idsConvenio?.length === 0 && (
          <Tooltip
            position="top"
            target={'.inputTooltip'}
            content="Selecione um convênio primeiro"
          />
        )}

        <SearchTuss
          setTuss={handleSelectedTuss}
          idsConvenio={idsConvenio}
          listTuss={watchListTuss}
          tussRef={tussRef}
        />

        <div className="p-mt-5">
          {items.map((tuss: any, index) => (
            <div className="p-grid p-col-12 p-p-0 p-mt-1" key={tuss.id}>
              <FormInput
                className="p-col-8"
                readOnly
                label="Serviços TUSS"
                name={`listServicoTuss.${index}.nomeConvenioAndTuss`}
                errorMsg={errors.listServicoTuss?.[index]?.nome?.message}
              />

              <Controller
                control={control}
                name={`listServicoTuss.${index}.valor`}
                render={({ field: { onChange, value } }) => (
                  <div className="TextInput p-col">
                    <ComponentsLabel label="Valor (em reais)" />
                    <span
                      className={`iconHandler ${
                        errors.listServicoTuss?.[index]?.valor?.message
                          ? 'p-input-icon-right'
                          : ''
                      }`}
                    >
                      {errors.listServicoTuss?.[index]?.valor?.message ? (
                        <i className={'pi pi-exclamation-circle'} />
                      ) : (
                        <></>
                      )}
                      <InputNumber
                        inputClassName={
                          errors.listServicoTuss?.[index]?.valor?.message
                            ? 'p-invalid'
                            : ''
                        }
                        placeholder="R$"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        allowEmpty={false}
                        value={value}
                        onValueChange={onChange}
                      />
                    </span>
                    <div className={'p-d-flex p-jc-between p-ai-center p-mt-1'}>
                      <div>
                        {errors.listServicoTuss?.[index]?.valor?.message && (
                          <SimpleText
                            className={'error'}
                            fontSize={FONT_SIZE.XXXS}
                          >
                            {errors.listServicoTuss?.[index]?.valor?.message}
                          </SimpleText>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              />

              <IconButton
                className="p-mt-5"
                type="button"
                icon="fa fa-trash"
                btnType={IconBtnTypes.GHOST}
                onClick={() => remove(index)}
              />
            </div>
          ))}
        </div>

        {items.length === 0 && items && (
          <div className="no-content">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Serviço TUSS selecionados serão exibidos aqui
            </SimpleText>
          </div>
        )}
      </div>
    );
  }, [
    fields,
    idsConvenio,
    handleSelectedTuss,
    watchListTuss,
    errors.listServicoTuss,
    control,
    remove,
  ]);

  const renderContet = () => {
    return (
      <div className="content">
        <FormProvider {...useFormMethods}>
          <form>
            <div className="p-col-12 p-md-6">
              <DropdownControlled
                className="p-mb-4"
                onFilter={e => loadProfissionais(e.filter)}
                name="nome"
                options={profissionais}
                optionLabel="nome"
                optionValue="id"
                control={control}
                errorMsg={errors?.nome?.message}
                disabled={
                  user.tipoProfissionalSaude === 'MEDICO' ||
                  user.tipoProfissionalSaude === 'MEDICA'
                }
                loading={loadingProfissionais}
              />

              <MultiSelectControlled
                className="p-mb-4"
                name="convenios"
                label="Convênios"
                display={'chip'}
                filter
                showSelectAll
                options={conveniosListAPI || []}
                optionLabel="razaoSocial"
                optionValue="id"
                disabled={convenioDisabled}
                control={control}
                loading={loadingConvenios}
              />

              <SimpleText
                fontSize={FONT_SIZE.XS}
                medium
                fontColor={FONT_COLOR.COLOR_0}
              >
                Serviço TUSS
              </SimpleText>

              {renderTuss()}

              <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
                <Button
                  btnType="ghost"
                  onClick={() => navigate(-1)}
                  label="Cancelar"
                  type="button"
                  stretch
                />
                <Button
                  label={'Salvar'}
                  onClick={handleSubmit(onSubmit)}
                  loading={isSubmitting}
                  stretch
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    );
  };

  const render = () => {
    if (!user?.idEmpresa)
      return (
        <div className="p-p-5">
          <AlertBox
            visible
            text="Você precisa selecionar um Cliente para esta funcionalidade!"
          />
        </div>
      );

    return renderContet();
  };

  return (
    <Page className={'add-servico'}>
      <>
        <PageHeader
          title={'Novo cadastro de valor de serviço'}
          subtitle={
            'Adicione o valor por convênio(s) do código TUSS escolhido acima'
          }
        />

        {render()}
      </>
    </Page>
  );
};

export default AddValoresServicos;
