import { useEffect, useState } from 'react';

import { TabPanel, TabView, TabViewProps } from 'primereact/tabview';
import { useNavigate } from 'react-router';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import './Tab.scss';

import { TabHeaderTemplate } from './TabCustomIconTemplate';

export interface TabItem {
  label?: string;
  headerClass?: any;
  iconAction?(): void;
  customHeader?: boolean;
  content?: JSX.Element | ((tabIndex: number) => JSX.Element);
  checkPermission?: string | boolean;
  hide?: boolean;
  disabled?: boolean;
  path?: string;
}
interface TabProps extends TabViewProps {
  values: TabItem[];
  clean?: boolean;
  underHeader?: boolean;
  noPanel?: boolean;
  fullHeight?: boolean;
  setActiveIndex?: number;
  initialTabIndex?: number;
  headerColor?: boolean;
  showBadge?: boolean;
  onChange?(tabIndex: number, value: any): void;
  returnOnChange?: boolean;
  renderAllAtOnce?: boolean;
  mode?: 'default' | 'path';
}
const Tab = ({
  id,
  values,
  clean,
  underHeader,
  noPanel,
  fullHeight,
  initialTabIndex,
  headerColor,
  setActiveIndex = 0,
  returnOnChange,
  showBadge,
  onChange,
  mode = 'default',
  renderAllAtOnce = false,
  ...props
}: TabProps) => {
  const navigate = useNavigate();
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const [active, setActive] = useState<number>(initialTabIndex || 0);
  const [rendered, setRendered] = useState<number[]>(
    renderAllAtOnce ? values.map((_, index: number) => index) : [active],
  );

  useEffectSkipFirst(() => {
    setActive(setActiveIndex);
  }, [setActiveIndex]);

  useEffect(() => {
    if (!rendered.includes(active)) setRendered(old => [...old, active]);
  }, [active, rendered, setActiveIndex]);

  //* Path Mode
  const handleTabChange = (value: any) => {
    navigate(value.path);
  };

  useEffect(() => {
    if (initialTabIndex && mode === 'path')
      window.history.replaceState(
        null,
        'eMedBR',
        values[initialTabIndex]?.path,
      );
  }, [initialTabIndex, mode, values]);

  const handleChange = (idx: number) => {
    if (mode === 'path') handleTabChange(values[idx]);
    if (onChange) onChange(idx, values[idx]);
    if (!returnOnChange) setActive(idx);
    return;
  };

  return (
    <div
      id={id}
      className={`
        Tab
        ${clean ? 'clean-mode' : ''}
        ${underHeader ? 'under-header' : ''}
        ${noPanel ? 'no-panel' : ''}
        ${fullHeight ? 'full-height' : ''}
        ${headerColor ? 'header-transparent' : ''}
      `}
    >
      <TabView
        {...props}
        activeIndex={active}
        renderActiveOnly={false}
        onTabChange={e => handleChange(e.index)}
      >
        {(values || [])
          .filter((v: TabItem) => {
            if (
              (v.checkPermission !== true &&
                !authorities?.includes(v.checkPermission)) ||
              v.hide
            ) {
              return false;
            }

            return true;
          })
          .map((v: TabItem, index: number) => (
            <TabPanel
              key={index}
              header={v.label}
              headerClassName={v.headerClass}
              headerTemplate={
                !!showBadge || !!v.customHeader ? (
                  <TabHeaderTemplate
                    badge={!!showBadge && String(index + 1)}
                    label={v.label || ''}
                    onClick={() => handleChange(index)}
                    onClickIcon={v.iconAction}
                    isActive={index === active}
                  />
                ) : null
              }
              disabled={v?.disabled || false}
            >
              {rendered.includes(index) && (
                <>
                  {typeof v.content === 'function'
                    ? v.content(index)
                    : v.content}
                </>
              )}
            </TabPanel>
          ))}
      </TabView>
    </div>
  );
};

export default Tab;
