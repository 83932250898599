import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import AvatarUpload from '../../components/AvatarUpload/AvatarUpload';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import MaskedInput, {
  MASK,
} from 'src/components/Basics/MaskedInput/MaskedInput';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import './Header.scss';

interface HeaderProps {
  paciente: any;
  loadingData: boolean;
}

const Header = ({ paciente, loadingData }: HeaderProps) => {
  const navigate = useNavigate();

  const [numeroCelular, setNumeroCelular] = useState<string>('');

  const goBackToSearch = () => {
    navigate('/pacientes', { replace: true });
  };

  const idade = useMemo(() => {
    if (!paciente?.dataNascimento) return {};

    const now = new Date();

    const lastMonthBirthday = new Date(
      paciente?.dataNascimento.replace('-', '/'),
    );
    lastMonthBirthday.setMonth(now.getMonth() - 1);
    lastMonthBirthday.setFullYear(now.getFullYear());

    const daysInLastMonth = dayjs(lastMonthBirthday).daysInMonth();

    const anos = dayjs(now).diff(paciente?.dataNascimento, 'year');
    const meses =
      dayjs(now).diff(paciente?.dataNascimento, 'month') - 12 * anos;
    const dias = daysInLastMonth + now.getDate() - lastMonthBirthday.getDate();

    return {
      anos,
      meses,
      dias,
    };
  }, [paciente]);

  const celular = useMemo(
    () =>
      paciente?.contatos?.contatos.find(
        (contato: any) => contato.tipo === 'CELULAR_PARTICULAR',
      ),
    [paciente?.contatos],
  );

  useEffect(() => {
    if (celular?.numero) setNumeroCelular(celular.numero);
  }, [celular]);

  return (
    <Page id="visualizar-paciente-header" content white>
      <>
        <Button
          btnType={BtnTypes.LINK}
          id="back-button"
          label="Voltar para pesquisa"
          icon="fa fa-arrow-left"
          onClick={goBackToSearch}
        />

        <div className="p-grid">
          <div className="p-grid p-col-12 p-sm-8 p-mt-2 p-align-center">
            <div
              id="paciente-data"
              className="p-col-12 p-d-flex p-flex-column p-flex-sm-row p-ai-center"
            >
              <Skeleton
                loading={loadingData}
                shape="circle"
                width="64px"
                height="64px"
              >
                <AvatarUpload
                  className="p-mr-0 p-mr-sm-3"
                  urlFoto={paciente?.urlFoto}
                />
              </Skeleton>

              <div className="p-d-flex p-flex-column p-text-center p-text-sm-left">
                <Skeleton
                  loading={loadingData}
                  width="30%"
                  height="14px"
                  borderRadius="8px"
                  className="p-mb-2"
                >
                  <SimpleText
                    className="line-height"
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_60}
                  >
                    Paciente {paciente?.id}
                  </SimpleText>
                </Skeleton>

                <Skeleton
                  loading={loadingData}
                  width="45%"
                  height="22px"
                  borderRadius="8px"
                  className="p-mb-2"
                >
                  <SimpleText
                    className="line-height"
                    bold
                    fontSize={FONT_SIZE.MD}
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    {paciente?.dadosGeraisObrigatorios.nome}
                  </SimpleText>
                </Skeleton>

                <Skeleton
                  loading={loadingData}
                  width="40%"
                  height="11px"
                  borderRadius="8px"
                  className="p-mb-2"
                >
                  <SimpleText
                    className="line-height"
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {capitalizeFirstLetter(
                      paciente?.dadosGeraisObrigatorios.sexo.descricao || '',
                    )}
                    <span className="p-mx-2">•</span>
                    {idade.anos} anos, {idade.meses} meses e {idade.dias} dias
                  </SimpleText>
                </Skeleton>
              </div>
            </div>

            <Skeleton
              loading={loadingData}
              width="30%"
              height="40px"
              borderRadius="8px"
            >
              <div className="p-col-12 p-inputgroup p-mt-3">
                <MaskedInput
                  name="whatsapp"
                  placeholder="(00) 00000-0000"
                  mask={MASK.PHONE}
                  value={numeroCelular}
                  onChange={e => setNumeroCelular(e.target.value)}
                  disabled={!!celular?.numero}
                />
                <Button
                  type="button"
                  btnType={BtnTypes.TONAL}
                  icon="pi pi-whatsapp"
                  label="Enviar"
                  onClick={() =>
                    window.open(
                      `http://wa.me/55${numeroCelular?.match(/\d/g)?.join('')}`,
                      '_blank',
                    )
                  }
                  disabled={
                    numeroCelular?.charAt(numeroCelular?.length - 1) === '_' ||
                    numeroCelular === ''
                      ? true
                      : false
                  }
                />
              </div>
            </Skeleton>
          </div>

          {/*
          <div className="p-col-12 p-sm-4 p-d-flex p-jc-end">
            <Skeleton
              loading={loadingData}
              width="192px"
              height="100%"
              borderRadius="8px"
            >
              <div id="card-sigilo" className="p-grid p-col-12">
                <SimpleText className="p-col-12" medium>
                  Sigilo
                </SimpleText>
                <SimpleText className="p-col-12" fontSize={FONT_SIZE.XXXS}>
                  O sigilo está bloqueado e o médico não poderá ver todo o histórico do paciente
                </SimpleText>

                <Button
                  type="button"
                  btnType={BtnTypes.TEXT_BUTTON}
                  label="Desbloquear"
                />
              </div>
            </Skeleton>
          </div>
          */}
        </div>
      </>
    </Page>
  );
};

export default Header;
