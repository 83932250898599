import { Suspense, useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';

import { useLocation } from 'react-router';

import html2canvas from 'html2canvas';
import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import PerimetroCefalicoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PerimetroCefalicoAPI/PerimetroCefalicoAPI';
import PesoAlturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PesoAlturaAPI/PesoAlturaAPI';
import ProntuarioAPI from 'src/APIs/ProntuarioAPI/ProntuarioAPI';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Tab from 'src/components/Tab/Tab';

import { GraficoDeAlturaChart } from './GraficoDeAlturaChart';
import { GraficoDePesoChart } from './GraficoDePesoChart';
import { GraficoImcChart } from './GraficoImcChart';
import { GraficoCrescimentoChart } from './GraficoPC';
import './GraficoDeCrescimento.scss';

interface PacienteProps {
  id: number;
  nome: string;
  dataNascimento: string;
  cpf: string;
  cartaoPlano?: any;
  idSexo: number;
  sexoDescricao: string;
  foto: string;
  fotoUrl: string;
  idadeAnos: number;
  idadeMeses: number;
  idadeDias: number;
}

type Props = DisclosureType & {
  initial: string;
};

const initialTab: any = {
  PESO_ALTURA: 0,
  PESO: 1,
  IMC: 2,
  PERIMETRO_CEFALICO: 3,
};

const chartPesoAlturaHistorico = [
  {
    label: 'Faixa de 0 a 2 anos',
    idadeInicial: 0,
    idadeFinal: 24,
  },
  {
    label: 'Faixa de 2 a 5 anos',
    idadeInicial: 24,
    idadeFinal: 60,
  },
  {
    label: 'Faixa de 5 a 10 anos',
    idadeInicial: 60,
    idadeFinal: 120,
  },
  {
    label: 'Faixa de 5 a 19 anos',
    idadeInicial: 60,
    idadeFinal: 228,
  },
];

export function Graficos({ isOpen, initial, close, ...props }: Props) {
  return (
    <Dialog
      header="Gráfico de crescimento"
      className="Dialog dialog-grafico-crescimento"
      onHide={close}
      visible={isOpen}
    >
      <Suspense fallback={<div>carregando...</div>}>
        <GraficosComp
          isOpen={isOpen}
          initial={initial}
          close={close}
          {...props}
        />
      </Suspense>
    </Dialog>
  );
}

export function GraficosComp({ close, initial }: Props) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [pacienteData, setPacienteData] = useState<PacienteProps>();
  const [profissional, setProfissional] = useState<any>([]);
  const [isLoadingImpressao, setIsLoadingImpressao] = useState(false);
  const [tabAtiva, setTabAtiva] = useState(initialTab[initial]);
  const [loadingCharts, setLoadingCharts] = useState(true);
  const [pesoAlturaChartData, setPesoAlturaChartData] = useState<any[]>([]);
  const [pcChartData, setPcChartData] = useState<any[]>([]);

  const dataImpresso = useRef(null);

  useEffect(() => {
    async function showLoadingCharts() {
      await new Promise(r => setTimeout(r, 100));

      setLoadingCharts(false);
    }

    showLoadingCharts();
  }, []);

  useEffect(() => {
    const fetchChartPesoAltura = async () => {
      const pesoAlturaFaixasEtariasData = await Promise.all(
        chartPesoAlturaHistorico.map(({ idadeInicial, idadeFinal }) =>
          PesoAlturaAPI.getPesoAltura({
            idAtendimento,
            idadeFinal,
            idadeInicial,
            ativo: true,
          }),
        ),
      );

      const pesoAlturaFaixasEtariasDataFormatted =
        pesoAlturaFaixasEtariasData.map(
          (pesoAlturaData: any) => pesoAlturaData?.list || [],
        );

      setPesoAlturaChartData(pesoAlturaFaixasEtariasDataFormatted);
    };

    fetchChartPesoAltura();
  }, [idAtendimento]);

  useEffect(() => {
    PerimetroCefalicoAPI.getPerimetroCefalico({ idAtendimento })
      .then(({ list }) => {
        if (list.length) {
          setPcChartData(list);

          dataImpresso.current = list[0]?.dataGeracaoRelatorio;

          ProfissionalAPI.getProfissional(
            list[0]?.usuarioAtualizacao.id,
            '',
          ).then(setProfissional);
        }
      })
      .catch(() => setProfissional([]));
  }, [idAtendimento]);

  useEffect(() => {
    ProntuarioAPI.getCabecalhoProntuario(Number(idAtendimento)).then(
      (data: any) => setPacienteData(data?.paciente),
    );
  }, [idAtendimento]);

  const generateBlobFiles = async (
    tabsPanel: NodeListOf<Element>,
  ): Promise<Blob[]> => {
    const blobsFiles: Blob[] = [];
    const generateBlob = async (indexChart: number, tabPanel: Element) => {
      const canvas = await html2canvas(tabPanel as HTMLElement, {
        onclone: (_, element) => {
          element.style.visibility = 'visible';
          const charts2: any = element.querySelectorAll('.chart-container');
          return [...charts2].forEach((chart2: any, index: number) => {
            if (indexChart === index)
              return (chart2.className = 'chart-container');
            return (chart2.className = 'chart-container p-chart-hidden ');
          });
        },
      });
      const base64File = canvas.toDataURL('image/png');
      const blobFile = await fetch(base64File).then(res => res.blob());
      return blobFile;
    };
    const promises = [...tabsPanel].map(tabPanel => {
      const charts = tabPanel.querySelectorAll('.chart-container');
      return [...charts].map((_, indexChart) =>
        generateBlob(indexChart, tabPanel),
      );
    });
    const blobs = await Promise.all(promises.flat());
    blobsFiles.push(...blobs);
    return blobsFiles;
  };

  const createFormData = (blobsFiles: Blob[]) => {
    const formData = new FormData();
    formData.append('idAtendimento', idAtendimento);

    blobsFiles.forEach(blobFile => formData.append('graficos', blobFile));

    return formData;
  };

  const createBlobURL = (data: any) => {
    const fileURL = URL.createObjectURL(
      new Blob([data], { type: 'application/pdf' }),
    );
    return fileURL;
  };

  const handleDownloadChart = async () => {
    try {
      const tabsPanel = document
        .querySelector('#grafico-crescimento-tabs')
        ?.querySelectorAll('.p-tabview-panel');

      if (!tabsPanel?.length) return;

      const blobsFiles = await generateBlobFiles(tabsPanel);
      const formData = createFormData(blobsFiles);

      const res = await ReportAPI.relatorioGraficos(formData);

      if (res.status === 200) {
        const fileURL = createBlobURL(res.data);
        window.open(fileURL);
      }

      PerimetroCefalicoAPI.atualizarPerimetroCefalicoDataRelatorio(
        idAtendimento,
      );
    } catch (error) {
      //console.error('Ocorreu um erro:', error);
    } finally {
      setIsLoadingImpressao(false);
    }
  };

  function validateSigla(
    idadeAnos: number,
    idadeMeses: number,
    idadeDias: number,
  ) {
    if (!isNaN(idadeAnos) && !isNaN(idadeMeses) && !isNaN(idadeDias)) {
      return `${idadeAnos} ano${
        idadeAnos > 1 || idadeAnos === 0 ? 's' : ''
      }, ${idadeMeses} ${
        idadeMeses === 0 || idadeMeses > 1 ? 'meses' : 'mês'
      } e ${idadeDias} dia${idadeDias > 1 ? 's' : ''}`;
    } else {
      return null;
    }
  }

  const imcChartData = !!pesoAlturaChartData?.length
    ? [pesoAlturaChartData[1], pesoAlturaChartData[3]]
    : [];

  return (
    <div className="dialog-container">
      <div className="card-info p-grid p-col-12">
        <div className="p-d-flex p-flex-column p-col-3">
          <SimpleText className="label">Paciente</SimpleText>
          <SimpleText>{pacienteData?.nome}</SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-col-3">
          <SimpleText className="label">Idade atual</SimpleText>
          <SimpleText>
            {validateSigla(
              Number(pacienteData?.idadeAnos),
              Number(pacienteData?.idadeMeses),
              Number(pacienteData?.idadeDias),
            )}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-col-2">
          <SimpleText className="label">Sexo</SimpleText>
          <SimpleText>
            {capitalizeFirstLetter(pacienteData?.sexoDescricao || '-')}
          </SimpleText>
        </div>
        {/* <div className="p-d-flex p-flex-column p-col-3">
          <SimpleText className="label">Data da impressão</SimpleText>
          <SimpleText>
            {dataImpresso?.current
              ? dayjs(dataImpresso.current).format('DD/MM/YYYY HH:mm')
              : '-'}
          </SimpleText>
        </div> */}
        <div className="p-d-flex p-flex-column p-col-2">
          <Button
            btnType="tonal"
            label="Imprimir gráfico"
            onClick={() => {
              setIsLoadingImpressao(true);

              setTimeout(() => {
                handleDownloadChart();
              }, 900);
            }}
            loading={isLoadingImpressao}
            stretch
          />
        </div>
      </div>

      <div className="p-d-flex">
        <SimpleText fontSize="xxs" bold>
          Profissional: {profissional?.nome} -{' '}
          {profissional?.profissionalSaude?.conselhoTiss.sigla}:{' '}
          {profissional?.profissionalSaude?.numeroRegistro}
        </SimpleText>
      </div>

      {loadingCharts && <GridListLoading />}

      <div
        id="grafico-tabs-container"
        className={`p-mt-3 ${loadingCharts ? 'tabs-content-hidden' : ''}`}
      >
        <Tab
          id="grafico-crescimento-tabs"
          setActiveIndex={tabAtiva}
          onChange={setTabAtiva}
          clean
          renderAllAtOnce
          values={[
            {
              label: 'Altura',
              content: (
                <div className="content">
                  <GraficoDeAlturaChart
                    chartData={pesoAlturaChartData.slice(0, 2)}
                    paciente={pacienteData}
                  />
                </div>
              ),
            },
            {
              label: 'Peso',
              content: (
                <div className="content">
                  <GraficoDePesoChart
                    chartData={pesoAlturaChartData.slice(0, 3)}
                    paciente={pacienteData}
                  />
                </div>
              ),
            },
            {
              label: 'IMC',
              content: (
                <div className="content">
                  <GraficoImcChart
                    chartData={imcChartData}
                    paciente={pacienteData}
                  />
                </div>
              ),
            },
            {
              label: 'PC',
              content: (
                <div className="content">
                  <GraficoCrescimentoChart
                    chartData={pcChartData}
                    paciente={pacienteData}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
      <div className="btn-footer">
        <Button
          btnType="ghost"
          label="Fechar"
          onClick={() => close()}
          stretch
        />
      </div>
    </div>
  );
}
