import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/cartoes-paciente';

export const loadCartoesPaciente = (
  idPaciente: number,
  params?: {
    somenteAtivos?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    url: `${basePath}?idPaciente=${idPaciente}`,
    service: 'adm',
    hideToast: true,
    params,
    ...options,
  });
};

export const loadCartaoById = (
  idCartao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    url: `${basePath}/${idCartao}`,
    service: 'adm',
    ...options,
  });
};

export const saveCartao = (
  cartao: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    url: `${basePath}`,
    service: 'adm',
    data: cartao,
    return: true,
    ...options,
  });
};

export const updateCartao = (
  cartao: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    url: `${basePath}/${cartao.id}`,
    service: 'adm',
    data: cartao,
    return: true,
    ...options,
  });
};

export const activeCartao = (
  idCartao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    url: `${basePath}/${idCartao}/ativar`,
    service: 'adm',
    hideToast: true,
    return: true,
    ...options,
  });
};

export const inactiveCartao = (
  idCartao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    url: `${basePath}/${idCartao}/inativar`,
    service: 'adm',
    hideToast: true,
    return: true,
    ...options,
  });
};

export const removerCartao = (
  idCartao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.delete({
    url: `${basePath}/${idCartao}`,
    service: 'adm',
    return: true,
    ...options,
  });

export const CartoesPacienteAPI = {
  loadCartoesPaciente,
  loadCartaoById,
  saveCartao,
  updateCartao,
  activeCartao,
  inactiveCartao,
  removerCartao,
};
