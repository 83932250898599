import { verificarParametos } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

interface DesktopRowProps {
  parametro: Parametro;
  onEdit: () => void;
  onDelete: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { parametro, onEdit, onDelete } = props;

  return (
    <CardItem>
      <div className="p-col-4 p-lg-6 p-d-flex p-flex-column">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {parametro?.codigo}
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {parametro?.descricao}
        </SimpleText>
      </div>

      <SimpleText
        className="p-col-2"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XXS}
      >
        {parametro?.valor}
      </SimpleText>

      <SimpleText
        className="p-col-2"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XXS}
      >
        {parametro?.opcoes}
      </SimpleText>

      <div className="p-col-2 p-lg-1">
        <SimpleText
          className={`personalizacao ${
            parametro?.listaCliente?.length > 0 ? 'personal' : ''
          }`}
          fontSize={FONT_SIZE.XXS}
        >
          {verificarParametos(parametro?.listaCliente?.length)}
        </SimpleText>
      </div>

      <div className="p-col-2 p-lg-1 p-d-flex p-ai-center">
        <Button
          btnType="gray"
          icon="pi pi-pencil"
          onClick={onEdit}
          checkPermission="ADM_PARAMETROS_ALTERAR"
        />

        <Separator spaceOnly />

        <Button
          btnType="gray"
          icon="pi pi-trash"
          onClick={onDelete}
          checkPermission="ADM_PARAMETROS_EXCLUIR"
        />
      </div>
    </CardItem>
  );
};
