import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import AlergiaAPI from 'src/APIs/ProntuarioAPI/AlergiaAPI/AlergiaAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import {
  resetInvalidateQuery,
  setInvalidateQuery,
} from 'src/core/redux/slices/query/QuerySlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalExcluirAlergia from './ModalExcluirAlergia/ModalExcluirAlergia';
import ModalJustificativaInativacao from './ModalJustificativaInativacao/ModalJustificativaInativacao';
import ModalNovaAlergia from './ModalNovaAlergia/ModalNovaAlergia';
import ModalVisualizarJustificativa from './ModalVisualizarJustificativa/ModalVisualizarJustificativa';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './Alergias.scss';

interface AlergiasProps {
  idPaciente: number | null;
}

function Alergias({ idPaciente }: AlergiasProps) {
  const [modalNovaVacinaVisible, setModalNovaVacinaVisible] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const { invalidateAlergias } = useAppSelector(state => state.query);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getInfoLembretes, atendimentoStatus } = useAtendimento();

  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

  const [dataInativarAlergia, setDataInativarAlergia] =
    useState<AlergiaDTO | null>(null);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<MedicamentosUso | null>(null);
  const [editarAlergia, setEditarAlergia] = useState<AlergiaDTO | null>(null);
  const [updateList, setUpdateList] = useState<number>(0);

  const [excluirAlergia, setExcluirAlergia] = useState<AlergiaDTO | null>(null);

  const fetchAPI = async ({ query, ...rest }: any) => {
    if (!idAtendimento) return;

    return await AlergiaAPI.loadAlergias(+idAtendimento, {
      sortBy: 'dataOcorrencia',
      sortDirection: 'DESC',
      query: query,
      ...rest,
    });
  };

  const onEdit = (alergia: any) => {
    setEditarAlergia(alergia);
    setModalNovaVacinaVisible(true);
  };

  const onInativar = (alergia: any) => {
    setDataInativarAlergia(alergia);
  };

  const onDelete = (alergia: any) => {
    setExcluirAlergia(alergia);
  };

  const reloadList = useCallback(() => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));

    if (invalidateAlergias) dispatch(resetInvalidateQuery());
  }, [dispatch, getInfoLembretes, idPaciente, invalidateAlergias]);

  useEffectSkipFirst(() => {
    if (invalidateAlergias) reloadList();
  }, [invalidateAlergias, reloadList]);

  const handdleLembrete = (idAlergia: any) => {
    AlergiaAPI.toggleLembreteAlergia(idAlergia).then(() => {
      reloadList();
    });
  };

  const onAlterarSituacao = async (alergia: any, situacao: string) => {
    const response = await AlergiaAPI.alterarSituacaoAlergia(
      alergia.id,
      alergia.idAtendimento,
      situacao,
    );

    if (response?.status === 204) {
      dispatch(setInvalidateQuery({ invalidateMemed: true }));

      reloadList();
    }
  };

  const toggleShowSearchInput = () => setShowSearchInput(prev => !prev);

  const optionsMenu = (alergia: any) => (
    <PanelOptionsMenu
      ativo={alergia.status === 'ATIVO'}
      lembreteAtivo={alergia.lembrete}
      idAtendimentoRegistro={alergia.idAtendimento}
      idUsuarioCriacao={alergia.idUsuarioAtualizacao}
      onAlterarLembrete={() => handdleLembrete(alergia.id)}
      onEdit={() => onEdit(alergia)}
      onInativar={() => onInativar(alergia)}
      onExcluir={() => onDelete(alergia)}
      onVisualizarJustificativa={() => setVisualizarJustificativa(alergia)}
    />
  );

  const renderRow = (alergia: any) => (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            alergia={alergia}
            optionsMenu={optionsMenu(alergia)}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            onAlterarSituacao={(situacao: string) =>
              onAlterarSituacao(alergia, situacao)
            }
          />
        )}
      >
        <PanelContent alergia={alergia} />
      </Panel>
    </div>
  );

  return (
    <div id="alergia-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Alergias e Intolerâncias
            </SimpleText>

            <div className="p-d-flex p-ai-center">
              <Button
                className="p-mr-4"
                type="button"
                btnType="tonal"
                label="Adicionar alergias ou intolerâncias"
                icon="fas fa-plus"
                onClick={() => setModalNovaVacinaVisible(true)}
                disabled={
                  atendimentoStatus !== 'ATENDENDO' &&
                  atendimentoStatus !== 'ATENDENDO_TRIAGEM'
                }
              />

              <Button
                icon="fas fa-search"
                btnType="gray"
                onClick={toggleShowSearchInput}
              />
            </div>
          </div>

          {showSearchInput && (
            <div className="p-mb-5 p-mt-3">
              <SearchList.SearchInput
                className="p-col-12"
                placeholder="Pesquisar"
              />
            </div>
          )}

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {modalNovaVacinaVisible && (
        <ModalNovaAlergia
          visible={modalNovaVacinaVisible}
          editarAlergia={editarAlergia}
          onHide={() => {
            setModalNovaVacinaVisible(false);
            setEditarAlergia(null);
          }}
          onSuccess={reloadList}
        />
      )}

      <ModalJustificativaInativacao
        alergia={dataInativarAlergia}
        onHide={() => setDataInativarAlergia(null)}
        onSuccess={reloadList}
      />

      <ModalVisualizarJustificativa
        alergia={visualizarJustificativa}
        onHide={() => setVisualizarJustificativa(null)}
      />

      <ModalExcluirAlergia
        alergia={excluirAlergia}
        onHide={() => setExcluirAlergia(null)}
        reloadList={reloadList}
      />
    </div>
  );
}

export default Alergias;
