import { useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import {
  useFieldArray,
  useForm,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';
import { AnexoQuimioterapiaAPI } from 'src/APIs/ProntuarioAPI/AnexoQuimioterapiaAPI/AnexoQuimioterapiaAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import {
  handleDefaultValues,
  handlePayloadSubmit,
  validationSchema,
} from './utils';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as ServicosTussSelect from 'src/components/ServicosTussSelect';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { CicloForm } from './AccForms/CicloForm';
import { DadosBeneficiarioForm } from './AccForms/DadosBeneficiarioForm';
import { DiagnosticoOncologicoForm } from './AccForms/DiagnosticoOncologicoForm';
import { ObservacaoForm } from './AccForms/ObservacaoForm';
import { TratamentosAnterioresForm } from './AccForms/TratamentosAnterioresForm';
import { Row } from './ListProcedimentos/Row';

export const ProcedimentosQuimio = ({
  idProcedimento,
  close,
  numeroGuia,
}: {
  idProcedimento: number;
  numeroGuia: number;
  close(): void;
}) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { idConvenio } = useSolicitacaoExameCirurgia();

  const searchPanelRef = useRef<OverlayPanel>(null);

  const [loading, setLoading] = useState(false);
  const [defaultValues, setValues] = useState<any>({
    medicamentosQuimioterapia: [] as any,
  });

  const isEditing = useRef(false);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffectSkipFirst(() => {
    useFormMethods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!isEditing.current)
      AnexoQuimioterapiaAPI.getPesoAltura(idAtendimento!).then(
        ({ altura, peso }) => {
          if (altura && peso) {
            useFormMethods.setValue('dadosBeneficiario.altura', altura);
            useFormMethods.setValue('dadosBeneficiario.peso', peso);
          }
        },
      );
  }, [idAtendimento, useFormMethods]);

  useEffect(() => {
    setLoading(true);
    AnexoQuimioterapiaAPI.getAnexoQuimio(idProcedimento, { throwError: true })
      .then(data => {
        !!data && [
          setValues(handleDefaultValues(data)),
          (isEditing.current = true),
        ];
      })
      .catch(() => close())
      .finally(() => setLoading(false));
  }, [close, idProcedimento]);

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting },
  } = useFormMethods;

  const {
    fields: anexosQuimio,
    append: appendAnexos,
    remove: handleRemoveAnexo,
  }: any = useFieldArray({
    control,
    name: 'medicamentosQuimioterapia',
    keyName: 'idForm',
  });

  const handleAddAnexoQuimio = (anexos: any[]) => {
    const initialDate =
      !!anexosQuimio?.length && !!anexosQuimio[0]?.dataPrevista
        ? new Date(anexosQuimio[0].dataPrevista)
        : undefined;

    const procedimentosFormatted = anexos.map(anexo => ({
      idServicoTuss: anexo.id,
      codigoProcedimento: anexo.codigo,
      descricao: anexo.nome,
      dose: 1,
      frequencia: 1,
      dataPrevista: initialDate,
    }));

    const items = procedimentosFormatted.filter(
      anexo =>
        !anexosQuimio.some(
          (item: any) => item.codigoProcedimento === anexo.codigoProcedimento,
        ),
    );

    appendAnexos(items);
    clearErrors('medicamentosQuimioterapia');
  };

  const fetchServicosTuss = ({ query, ...params }: any) =>
    ServicoTussCRUDAPI.getSolicitacaoExame({
      codigoNomeApelido: query,
      idConvenio,
      quimio: true,
      ...params,
    });

  const fetchServicosTuss2 = ({ query, ...params }: any) =>
    ServicoTussCRUDAPI.getServicoTussFavoritos({
      nome: query,
      idConvenio,
      quimio: true,
      opme: false,
      ...params,
    });

  const onSubmit = (values: typeof validationSchema.__outputType) => {
    const payload = handlePayloadSubmit(values, idProcedimento, numeroGuia);

    if (isEditing.current)
      return AnexoQuimioterapiaAPI.putAnexoQuimio(payload)
        .then(() => close())
        .catch(() => {});

    return AnexoQuimioterapiaAPI.postAnexoQuimio(payload)
      .then(() => close())
      .catch(() => {});
  };

  const renderProcedimento = (
    isSelected: boolean,
    procedimento: any,
    onSelect: (procedimento: any) => void,
  ) => {
    return (
      <ServicosTussSelect.ItemContainer key={procedimento.id}>
        <ServicosTussSelect.Item
          isSelected={isSelected}
          procedimento={procedimento}
          onSelect={onSelect}
        />
        <ServicosTussSelect.FavoriteButton
          isSelected={procedimento.favorito}
          idProcedimento={procedimento.id}
          idExameFavorito={procedimento.idExameFavorito}
        />
      </ServicosTussSelect.ItemContainer>
    );
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onSubmit as any)}>
        <fieldset disabled={loading}>
          <div id="anexos-quimio-container">
            <div className="p-d-flex">
              <div className="p-flex-1 p-mr-3">
                <ServicosTussSelect.ServicosTussSelectProvider
                  fetchServicosTuss={fetchServicosTuss}
                >
                  <ServicosTussSelect.MultiSelect
                    onAdd={handleAddAnexoQuimio}
                    renderProcedimento={renderProcedimento}
                  />
                </ServicosTussSelect.ServicosTussSelectProvider>
              </div>

              <Button
                type="button"
                btnType={BtnTypes.TEXT_BUTTON}
                label="Favoritos"
                icon="pi pi-chevron-down"
                iconPos="right"
                onClick={e => searchPanelRef.current?.toggle(e)}
              />

              <OverlayPanel
                className="quimio-favoritos-panel"
                ref={searchPanelRef}
                appendTo="self"
              >
                <ServicosTussSelect.ServicosTussSelectProvider
                  fetchServicosTuss={fetchServicosTuss2}
                  lengthToSearch={false}
                >
                  <ServicosTussSelect.MultiSelect
                    onAdd={handleAddAnexoQuimio}
                    lengthToSearch={false}
                    renderProcedimento={renderProcedimento}
                    panel={false}
                  />
                </ServicosTussSelect.ServicosTussSelectProvider>
              </OverlayPanel>
            </div>

            <Divider layout="horizontal" />

            {loading ? (
              <LoadingComp />
            ) : (
              <ProcedimentoQuimioRow
                items={anexosQuimio}
                handleRemove={handleRemoveAnexo}
              />
            )}

            <DadosBeneficiarioForm />
            <DiagnosticoOncologicoForm />
            <CicloForm />
            <TratamentosAnterioresForm />
            <ObservacaoForm />

            <div className="p-d-flex p-mt-2 p-gap-2">
              <Button
                label="Cancelar"
                onClick={() => close()}
                btnType="ghost"
                disabled={isSubmitting}
                stretch
              />
              <Button
                label="Salvar"
                type="submit"
                stretch
                loading={isSubmitting}
              />
            </div>
          </div>
        </fieldset>
      </form>
    </FormProvider>
  );
};

const ProcedimentoQuimioRow = ({
  items,
  handleRemove,
}: {
  items: any[];
  handleRemove(): void;
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  if (!items.length) {
    return (
      <>
        {errors?.medicamentosQuimioterapia?.message && (
          <div className="p-my-1">
            <SimpleText className="error">
              {errors?.medicamentosQuimioterapia?.message}
            </SimpleText>
          </div>
        )}
        <div id="anexos-quimio-empty-container">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Nenhum. Adicione um medicamento utilizando a barra de pesquisa.
          </SimpleText>
        </div>
      </>
    );
  }

  return (
    <div
      id="anexos-quimio-list-container"
      className="p-d-flex p-flex-column p-gap-1"
    >
      {items.map((item, index) => (
        <Row
          key={item.id}
          index={index}
          onDelete={handleRemove}
          medicamento={item}
        />
      ))}
    </div>
  );
};

const LoadingComp = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-1 p-my-2">
      <Skeleton loading borderRadius="8px" height="60px" />
      <Skeleton loading borderRadius="8px" height="60px" />
    </div>
  );
};
