import { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import PressaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PressaoAPI/PressaoAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

interface PressaoProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const Pressao = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: PressaoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { setQuantidadePreAtendimento } = useHistoricoAtendimento();

  const [unidadeMedidaVisualizacao, setUnidadeMedidaVisualizacao] = useState<
    'MOSTRAR_ORIGINAL' | 'CENTIMETROS_MERCURIO' | 'MILIMETROS_MERCURIO'
  >('MOSTRAR_ORIGINAL');

  const [quantidadeItens, setQuantidadeItens] = useState<number>(1);
  const [exibirLista, setExibirLista] = useState<boolean>(true);

  useEffect(() => {
    if (quantidadeItens === 0 && selectedAtendimento) {
      setExibirLista(false);
    }
  }, [quantidadeItens, selectedAtendimento]);

  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      const response = await PressaoAPI.getPressao({
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        ...params,
      });
      setQuantidadeItens(response?.totalCount);
      setQuantidadePreAtendimento(
        (prev: number) => prev + response?.totalCount,
      );
      return response;
    },
    [
      idAtendimento,
      selectedAtendimento?.idAtendimento,
      setQuantidadePreAtendimento,
    ],
  );

  const manterDuasDecimais = (value: number) => {
    return String(value)
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\.\d{2}).+/g, '$1');
  };

  const formatValueView = (value: number, unidadeMedida: any) => {
    if (
      unidadeMedida.codigo === 'CMHG' &&
      unidadeMedidaVisualizacao === 'MILIMETROS_MERCURIO'
    ) {
      return `${manterDuasDecimais(Number(value) * 10)} Milímetros de mercúrio`;
    }

    if (
      unidadeMedida.codigo === 'MMHG' &&
      unidadeMedidaVisualizacao === 'CENTIMETROS_MERCURIO'
    ) {
      return `${manterDuasDecimais(
        Number(value) / 10,
      )} Centímetros de mercúrio`;
    }

    return `${value} ${unidadeMedida.descricao}`;
  };

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
  ) => {
    return (
      <div className="p-col p-sm-7 p-d-flex">
        <div className="column p-jc-center p-ml-3">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Sistólica
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {formatValueView(
              preAtendimento.sistolica,
              preAtendimento.unidadeMedida,
            )}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
        <div className="column p-jc-center p-ml-3">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Diastólica
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {formatValueView(
              preAtendimento.diastolica,
              preAtendimento.unidadeMedida,
            )}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento} />
      </Panel>
    );
  };

  return (
    <div>
      {exibirLista && (
        <div className="container-unidade-medida p-my-2 container-pre-atendimento">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            className="p-mb-2"
            medium
          >
            Pressão
          </SimpleText>
          <div className="p-grid">
            <SimpleText
              className="p-col-12 p-mt-2"
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Exibir unidades de medida em:
            </SimpleText>

            <div className="p-col-6 p-sm-3 p-as-center p-js-center">
              <RadioButton
                name="unidadeMedida"
                label="Mostrar original"
                value="MOSTRAR_ORIGINAL"
                checked={unidadeMedidaVisualizacao === 'MOSTRAR_ORIGINAL'}
                onChange={() =>
                  setUnidadeMedidaVisualizacao('MOSTRAR_ORIGINAL')
                }
              />
            </div>
            <div className="p-col-6 p-sm-3 p-as-center p-js-center">
              <RadioButton
                name="unidadeMedida"
                label="Centímetros de mercúrio"
                value="CENTIMETROS_MERCURIO"
                checked={unidadeMedidaVisualizacao === 'CENTIMETROS_MERCURIO'}
                onChange={() =>
                  setUnidadeMedidaVisualizacao('CENTIMETROS_MERCURIO')
                }
              />
            </div>
            <div className="p-col-6 p-sm-3 p-as-center p-js-center">
              <RadioButton
                name="unidadeMedida"
                label="Milímetros de mercúrio"
                value="MILIMETROS_MERCURIO"
                checked={unidadeMedidaVisualizacao === 'MILIMETROS_MERCURIO'}
                onChange={() =>
                  setUnidadeMedidaVisualizacao('MILIMETROS_MERCURIO')
                }
              />
            </div>
          </div>

          <InfiniteScroll
            fetchAPI={fetchAPI}
            renderRow={renderRow}
            updateList={updateList}
          />
        </div>
      )}
    </div>
  );
};

export default Pressao;
