import { useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import { useServicoTuss } from './hooks/useServicoTuss';
import { tissValidationSchema } from './validationSchema';

const justificativaPresencaOptions = [
  {
    label: 'Automático',
    value: 'AUTOMATICO',
  },
  {
    label: 'Manual',
    value: 'MANUAL',
  },
  {
    label: 'Desativado',
    value: 'DESATIVADO',
  },
];

type Options = {
  label: string | null;
  value: number | null;
  disabled: boolean;
};

const defaultOption: Options[] = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

export const FormTiss = () => {
  const {
    basicInfoStepData,
    idConvenioEdit,
    idConvenioTissEdit,
    tissInfoData,
    wsdlTissInfoData,
    setTissInfoData,
    setIdConvenioTissEdit,
  } = useManterConvenio();

  const navigate = useNavigate();

  const useFormMethods = useForm({
    defaultValues: {
      ...(tissInfoData || {}),
    },
    resolver: yupResolver(tissValidationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useFormMethods;

  const executanteFixoTiss = watch('executanteFixoTiss');

  const {
    loadingSearchServicoTuss: loadingSearchServicoTussEncaminhamento,
    servicoTussOptions: servicoTussEncaminhamentoOptions,
    handleServicoTussFilter: handleServicoTussEncaminhamento,
  } = useServicoTuss({
    selected: tissInfoData?.servicoTussEncaminhamento?.nome || '',
  });

  const {
    loadingSearchServicoTuss: loadingSearchServicoTussContrarreferencia,
    servicoTussOptions: servicoTussContrarreferenciaOptions,
    handleServicoTussFilter: handleServicoTussContrarreferencia,
  } = useServicoTuss({
    selected: tissInfoData?.servicoTussContrarreferencia?.nome || '',
  });

  const {
    loadingSearchServicoTuss: loadingSearchServicoTussPuericultura,
    servicoTussOptions: servicoTussPuericulturaOptions,
    handleServicoTussFilter: handleServicoTussPuericultura,
  } = useServicoTuss({
    selected: tissInfoData?.servicoTussPuericultura?.nome || '',
  });

  const {
    loadingSearchServicoTuss: loadingSearchServicoTussTelemedicina,
    servicoTussOptions: servicoTussTelemedicinaOptions,
    handleServicoTussFilter: handleServicoTussTelemedicina,
  } = useServicoTuss({
    selected: tissInfoData?.servicoTussTelemedicina?.nome || '',
  });

  const {
    loadingSearchServicoTuss: loadingSearchServicoTussConsulta,
    servicoTussOptions: servicoTussConsultaOptions,
    handleServicoTussFilter: handleServicoTussConsulta,
  } = useServicoTuss({
    selected: tissInfoData?.servicoTussConsulta?.nome || '',
  });

  const selectedCountryTemplate = (option: any) => {
    const [code, label] = option?.label?.split(' - ') || [];

    return (
      <div className="servico-tuss-item">
        <span>{code}</span> {label}
      </div>
    );
  };

  const onSubmit = async (data: any) => {
    const payload = {
      idConvenio: idConvenioEdit,
      ...wsdlTissInfoData,
      ...data,
      idServicoTussEncaminhamento: data?.servicoTussEncaminhamento?.id || null,
      idServicoTussContrarreferencia:
        data?.servicoTussContrarreferencia?.id || null,
      idServicoTussPuericultura: data?.servicoTussPuericultura?.id || null,
      idServicoTussTelemedicina: data?.servicoTussTelemedicina?.id || null,
      idServicoTussConsulta: data?.servicoTussConsulta?.id || null,
    };

    if (!data.executanteFixoTiss) {
      payload.executanteNomeFixo = '';
      payload.executanteCnesFixo = '';
      payload.executanteCrmFixo = '';
    }

    try {
      const saveConvenioTiss = idConvenioTissEdit
        ? ConvenioAPI.updateConvenioTiss
        : ConvenioAPI.createConvenioTiss;

      const response = await saveConvenioTiss(
        payload,
        idConvenioEdit as number,
      );

      setTissInfoData(data);

      if (idConvenioTissEdit === null && response?.id)
        setIdConvenioTissEdit(response.id);
    } catch (error) {}
  };

  return (
    <FormProvider {...useFormMethods}>
      <form
        id="form-tiss"
        className="p-grid p-col-12 p-md-10 p-lg-8 p-xl-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            Utilizar TISS
          </SimpleText>
          <SwitchControlled control={control} name="utilizaTiss" showStatus />
        </div>

        <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            Agrupar procedimento TISS
          </SimpleText>

          <SwitchControlled
            control={control}
            name="tissProcedimentoAgrupado"
            showStatus
          />
        </div>
        {!basicInfoStepData?.isParticular && (
          <>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Exigir executante SP SADT
              </SimpleText>

              <SwitchControlled
                control={control}
                name="exigirExecutanteSpSadt"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Habilitar envio credencial TISS
              </SimpleText>

              <SwitchControlled
                control={control}
                name="habilitaEnvioCredencialTiss"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Fixar etapa de autorização prestador
              </SimpleText>

              <SwitchControlled
                control={control}
                name="tissEtapaAutorizacaoPrestFixo"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Ativar envio de cobrança
              </SimpleText>

              <SwitchControlled
                control={control}
                name="ativaEnvioCobrancaWs"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Enviar via cartão
              </SimpleText>

              <SwitchControlled
                control={control}
                name="enviarViaCartao"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Habilitar guia de autorização
              </SimpleText>

              <SwitchControlled
                control={control}
                name="habilitarGa"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Habilitar guia de autorização para recém nascido
              </SimpleText>

              <SwitchControlled
                control={control}
                name="habilitarGaObrigaRnint"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Habilitar guia de autorização no encaminhamento
              </SimpleText>

              <SwitchControlled
                control={control}
                name="habilitarGuiaAutorizacaoEncaminhamento"
                showStatus
              />
            </div>

            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Verificar status guia após timeout
              </SimpleText>

              <SwitchControlled
                control={control}
                name="statusGuiaAposTimeout"
                showStatus
              />
            </div>
            <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                Fixar executante TISS
              </SimpleText>

              <SwitchControlled
                control={control}
                name="executanteFixoTiss"
                showStatus
              />
            </div>
          </>
        )}
        {executanteFixoTiss && (
          <>
            <TextInputControlled
              className="p-col-12"
              control={control}
              name="executanteNomeFixo"
              label="Nome do executante"
              maxLength={100}
            />

            <TextInputControlled
              className="p-col-12 p-md-6"
              control={control}
              name="executanteCnesFixo"
              label="CNES do executante"
              maxLength={20}
              permitirApenasNumeros
            />

            <TextInputControlled
              className="p-col-12 p-md-6"
              control={control}
              name="executanteCrmFixo"
              label="CRM do executante"
              maxLength={20}
            />
          </>
        )}

        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="horariosSchedulerEnvioTiss"
          label="Horários de envio"
          placeholder="HH:MM separado por vírgula"
          maxLength={50}
          errorMsg={errors.horariosSchedulerEnvioTiss?.message}
        />
        <TextInputControlled
          className="p-col-12 p-md-6"
          control={control}
          name="limiteTentativasSchedulerTiss"
          label="Lim. de tentativas Scheduler TISS"
          permitirApenasNumeros
          maxLength={3}
        />
        <FormInput
          name="limiteGuiasPorLote"
          label="Limite de guias por lote"
          type="number"
          className="p-col-12 p-md-6"
          max={100}
        />
        <FormInput
          name="limiteLotesPorEnvio"
          label="Limite de lotes por envio"
          type="number"
          className="p-col-12 p-md-6"
          max={100}
        />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="telemedicinaLoteSpSadt"
          label="Ativar o envio de telemedicina com .xml SP-SADT"
          options={[
            { label: 'Sim', value: 'S' },
            { label: 'Não', value: 'N' },
          ]}
          filter={false}
        />
        <TextInputControlled
          className="p-col-12"
          control={control}
          name="tissEtapaAutorizacao"
          label="Etapa de autorização"
          maxLength={20}
        />

        <DropdownControlled
          className="p-col-12"
          control={control}
          name="servicoTussEncaminhamento"
          label="Serviço TUSS encaminhamento"
          options={
            servicoTussEncaminhamentoOptions.length
              ? servicoTussEncaminhamentoOptions
              : defaultOption
          }
          onFilter={e => handleServicoTussEncaminhamento(e.filter)}
          carregandoFiltro={loadingSearchServicoTussEncaminhamento}
          filterPlaceholder="Digite para pesquisar"
          itemTemplate={selectedCountryTemplate}
        />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="servicoTussContrarreferencia"
          label="Serviço TUSS contrarreferência"
          options={
            servicoTussContrarreferenciaOptions.length
              ? servicoTussContrarreferenciaOptions
              : defaultOption
          }
          onFilter={e => handleServicoTussContrarreferencia(e.filter)}
          carregandoFiltro={loadingSearchServicoTussContrarreferencia}
          filterPlaceholder="Digite para pesquisar"
          itemTemplate={selectedCountryTemplate}
        />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="servicoTussPuericultura"
          label="Serviço TUSS puericultura"
          options={
            servicoTussPuericulturaOptions.length
              ? servicoTussPuericulturaOptions
              : defaultOption
          }
          onFilter={e => handleServicoTussPuericultura(e.filter)}
          carregandoFiltro={loadingSearchServicoTussPuericultura}
          filterPlaceholder="Digite para pesquisar"
          itemTemplate={selectedCountryTemplate}
        />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="servicoTussTelemedicina"
          label="Serviço TUSS telemedicina"
          options={
            servicoTussTelemedicinaOptions.length
              ? servicoTussTelemedicinaOptions
              : defaultOption
          }
          onFilter={e => handleServicoTussTelemedicina(e.filter)}
          carregandoFiltro={loadingSearchServicoTussTelemedicina}
          filterPlaceholder="Digite para pesquisar"
          itemTemplate={selectedCountryTemplate}
        />
        <DropdownControlled
          className="p-col-12"
          control={control}
          name="servicoTussConsulta"
          label="Serviço TUSS consulta"
          options={
            servicoTussConsultaOptions.length
              ? servicoTussConsultaOptions
              : defaultOption
          }
          onFilter={e => handleServicoTussConsulta(e.filter)}
          carregandoFiltro={loadingSearchServicoTussConsulta}
          filterPlaceholder="Digite para pesquisar"
          itemTemplate={selectedCountryTemplate}
        />
        {!basicInfoStepData?.isParticular && (
          <>
            <DropdownControlled
              className="p-col-12"
              control={control}
              name="justificativaPresenca"
              label="Justificativa ao marcar presença"
              options={justificativaPresencaOptions}
              errorMsg={errors.justificativaPresenca?.message}
            />
            <TextInputControlled
              className="p-col-12"
              control={control}
              name="codigoGlosaPresenca"
              label="Código Glosa ao justificar a presença"
              maxLength={20}
            />
            <TextareaInputControlled
              className="p-col-12"
              control={control}
              name="mensagemConexaoOperadora"
              label="Mensagem de conexão com a operadora"
              maxLength={200}
              maxLengthSpanDesc
            />

            <TextareaInputControlled
              className="p-col-12"
              control={control}
              name="mensagemFalhaComunicacao"
              label="Mensagem de falha na comunicação"
              maxLength={200}
              maxLengthSpanDesc
            />
          </>
        )}

        <div className="p-col-12 p-md-3">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={() => navigate('/cadastros/convenio', { replace: true })}
            stretch
          />
        </div>
        <div className="p-col-12 p-md-9">
          <Button type="submit" label="Salvar" loading={isSubmitting} stretch />
        </div>
      </form>
    </FormProvider>
  );
};
