import * as Yup from 'yup';

export const validationSchemaStep1 = Yup.object({
  isMenorIdade: Yup.boolean(),
  particular: Yup.boolean(),

  numeroCarteirinha: Yup.string().when('particular', {
    is: (value: boolean) => !value,
    then: Yup.string().required('Campo obrigatório'),
  }),
  nome: Yup.string().when('particular', {
    is: (value: boolean) => value,
    then: Yup.string().required('Campo obrigatório'),
  }),
  dataNascimento: Yup.date()
    .when('particular', {
      is: (value: boolean) => value,
      then: Yup.date().required('Campo obrigatório'),
    })
    .typeError('Informe uma data válida'),
  cpfPassaporte: Yup.string()
    .when('isMenorIdade', {
      is: (value: boolean) => value,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('Campo obrigatório'),
    })
    .test('cpfPassaporte', 'O campo não pode conter pontuação', value => {
      return /^[a-zA-Z0-9]+$/.test(value || '');
    }),
  nomeMae: Yup.string().when('isMenorIdade', {
    is: (value: boolean) => !value,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Campo obrigatório'),
  }),
});

export const validationSchemaStep2 = Yup.object({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  senha: Yup.string()
    .matches(/^(?=.*[A-Z])/, 'A senha deve conter pelo menos 1 letra maiúscula')
    .matches(/^(?=.*[0-9])/, 'A senha deve conter pelo menos 1 número')
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      'A senha deve conter pelo menos 1 caractere especial',
    ),
  confirmacaoSenha: Yup.string()
    .oneOf([Yup.ref('senha'), null], 'As senhas não são iguais.')
    .required('Campo obrigatório'),
});
