import { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Sidebar from 'src/components/Sidebar/Sidebar';

import { ConteudoHistoricoAtendimento } from './ConteudoHistoricoAtendimento';
import Row from './List/Row';
import { ModalHistoricoCompleto } from './ModalHistoricoCompleto';
import { ModalJustificativaInativacao } from './ModalJustificativaInativacao';

import './HistoricoAtendimentos.scss';

export const diagnosticos: any = {
  SUSPEITO: 'Suspeito',
  CONFIRMADO: 'Confirmado',
};

export const chipTypeDiagnosticos: Record<string, string> = {
  SUSPEITO: 'chip-yellow',
  CONFIRMADO: 'chip-success-light',
};

export const nivelDiagnosticos: any = {
  PRINCIPAL: 'Principal',
  SECUNDARIO: 'Secundário',
};

export const chipTypeNivelDiagnosticos: Record<string, string> = {
  PRINCIPAL: 'chip-yellow',
  SECUNDARIO: 'chip-success-light',
};

export const statusCid: any = {
  true: 'Ativo',
  false: 'Inativo',
};

export const chipTypeStatusCid: Record<string, string> = {
  true: 'chip-yellow',
  false: 'chip-outline-green',
};

interface HistoricoAtendimentosProps {
  usuario: GetUsuarioDTO | null;
  idPaciente: number | null;
}

export const HistoricoAtendimentos = ({}: HistoricoAtendimentosProps) => {
  const { atendimentoStatus } = useAtendimento();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    todosAtendimentos,
    setSelectedAtendimento,
    setQuantidadeRegistros,
    setTotalRegistros,
    atualAtendimento,
    reload,
    setReload,
    setUpdateList,
    updateList,
  } = useHistoricoAtendimento();

  const [expandSidebar, setExpandSidebar] = useState<boolean>(false);

  const reloadList = () => {
    setUpdateList((old: number) => old + 1);
  };

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload, setReload]);

  const historicoCompletoDisclosure = useDisclosure({ opened: false });
  const sidebarDisclosure = useDisclosure({ opened: false });
  const justificativaDisclosure = useDisclosure({ opened: false });

  const onInativarAtendimento = useCallback(
    (atendimento: any) => {
      setSelectedAtendimento(atendimento);
      justificativaDisclosure.open();
    },
    [justificativaDisclosure, setSelectedAtendimento],
  );

  const fetchAPI = useCallback(
    async (params?: any) => {
      if (!idAtendimento) {
        return;
      }
      let response: any;
      const pageApi: any = {
        list: [],
      };
      if (atualAtendimento) {
        response = await UtilsAPI.getProntuario(Number(idAtendimento));
        pageApi.list.push(response);

        return pageApi;
      } else {
        response = await HistoricoAtendimentosAPI.getHistoricoAtendimentos(
          Number(idAtendimento),
          {
            todosAtendimentos: todosAtendimentos,
            sortBy: 'atendimento.dataOcorrencia',
            sortDirection: 'DESC',
            ...params,
          },
        );

        setQuantidadeRegistros((prev: any) => prev + response?.list?.length);
        setTotalRegistros(response?.totalCount);
        return response;
      }
    },
    [
      atualAtendimento,
      idAtendimento,
      setQuantidadeRegistros,
      setTotalRegistros,
      todosAtendimentos,
    ],
  );

  const renderRow = (atendimento: any) => {
    return (
      <Row
        atendimento={atendimento}
        historicoCompletoDisclosure={historicoCompletoDisclosure}
        justificativaDisclosure={justificativaDisclosure}
      />
    );
  };

  return (
    <div>
      {atendimentoStatus !== 'ATENDENDO' &&
      atendimentoStatus !== 'ATENDENDO_TRIAGEM' ? (
        <ConteudoHistoricoAtendimento
          fetchAPI={fetchAPI}
          renderRow={renderRow}
          historicoCompletoDisclosure={historicoCompletoDisclosure}
          justificativaDisclosure={justificativaDisclosure}
          atendimentoStatus={atendimentoStatus}
          updateList={updateList}
        />
      ) : (
        <div
          onClick={() => {
            sidebarDisclosure.open();
          }}
          className={`open-btn p-d-flex p-ai-center ${
            sidebarDisclosure.isOpen ? 'hidden' : ''
          }`}
        >
          <Button icon={'pi pi-angle-double-up'} btnType="ghost" />
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            Historico
          </SimpleText>
        </div>
      )}
      {sidebarDisclosure.isOpen && (
        <Sidebar
          position="bottom"
          onHide={sidebarDisclosure.close}
          className={`SidebarHistorico p-shadow-3 ${
            expandSidebar ? 'expand' : ''
          }`}
          visible={sidebarDisclosure.isOpen}
        >
          <ConteudoHistoricoAtendimento
            fetchAPI={fetchAPI}
            renderRow={renderRow}
            historicoCompletoDisclosure={historicoCompletoDisclosure}
            justificativaDisclosure={justificativaDisclosure}
            atendimentoStatus={atendimentoStatus}
            expandSidebar={expandSidebar}
            setExpandSidebar={setExpandSidebar}
            updateList={updateList}
          />
        </Sidebar>
      )}
      <ModalJustificativaInativacao
        {...justificativaDisclosure}
        historicoCompletoDisclosure={historicoCompletoDisclosure}
        reloadList={reloadList}
      />
      <ModalHistoricoCompleto
        initial={'RESUMO'}
        justificativaDisclosure={justificativaDisclosure}
        onInativarAtendimento={onInativarAtendimento}
        reload={reloadList}
        {...historicoCompletoDisclosure}
      />
    </div>
  );
};
