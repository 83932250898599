import { ReceitaMemedProps } from 'src/models/APIs/ProntuarioAPI/Receita/ReceitaDTOs';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/receitas';

const getReceitasAtendimento = (
  params: { idAtendimento: string },
  options?: any,
  receitaOculos?: any,
): Promise<Paginator<ReceitaMemedProps>> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/atendimento/${params.idAtendimento}`,
    ...options,
    params: receitaOculos,
    hideToast: true,
  });
};

const deleteReceita = async (
  idReceita: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> => {
  return Http.delete({
    service: 'pep',
    url: `${basePath}/${idReceita}/atendimento/${idAtendimento}`,
    ...options,
  });
};

const inativarReceita = async (
  idReceita: number,
  idAtendimento: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${idReceita}/atendimento/${idAtendimento}/inativar`,
    data,
    ...options,
  });
};

const ReceitaAPI = {
  getReceitasAtendimento,
  deleteReceita,
  inativarReceita,
};

export default ReceitaAPI;
