import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentHistoricoProps {
  gestacao: any;
}

export default function PanelContentHistorico({
  gestacao,
}: PanelContentHistoricoProps) {
  return (
    <div className="p-grid item-historico">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Edema
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {gestacao?.examesFisicos?.[0]?.edema}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          Adicionado em{' '}
          {gestacao?.examesFisicos?.[0]?.dataAtualizacao
            ? dayjs(
                new Date(gestacao?.examesFisicos?.[0]?.dataAtualizacao),
              ).format('DD/MM/YYYY HH:mm')
            : 'Não informado'}
        </SimpleText>
      </div>
    </div>
  );
}
