import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PerfilSaudeAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PerfilSaudeAPI/PerfilSaudeAPI';
import QuestionariosAPI from 'src/APIs/ProntuarioAPI/Questionarios/QuestionariosAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

interface InativarProps extends DisclosureType {
  reloadList: () => void;
  idAtendimento?: string | undefined;
}

const InativarQuestionario = ({
  idAtendimento,
  isOpen,
  close,
  state,
  reloadList,
}: InativarProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const validationSchema = Yup.object().shape({
    justificativaInativacao: Yup.string().required(
      'Informe a justificativa da inativação',
    ),
  });

  const questionario = state;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const payload = {
      idAtendimento: idAtendimento,
      id: questionario?.id,
      idProfissionalSaude: user.idUsuario,
      dataOcorrencia: questionario?.dataOcorrencia,
      justificativaInativacao: data.justificativaInativacao,
    };

    if (idAtendimento) {
      await QuestionariosAPI.putQuestionariosInativar(payload);
    } else {
      await PerfilSaudeAPI.putInativarQuestionarioPortal(payload);
    }

    reloadList();
    close();
  };

  return (
    <Dialog
      header="Você tem certeza que deseja inativar?"
      type="modal"
      visible={isOpen}
      onHide={close}
      modal={false}
    >
      <div>
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
          Você está inativando a inclusão deste item no prontuário do paciente.
          Ele continuará ser exibido como uma informação inativa. Para
          continuar, justifique.
        </SimpleText>

        <form
          className="p-mt-3"
          onSubmit={handleSubmit(data => onSubmit(data))}
        >
          <TextareaInputControlled
            className="p-col-12"
            control={control}
            name="justificativaInativacao"
            label="Justificativa"
            placeholder="Informe a justificativa"
            errorMsg={errors.justificativaInativacao?.message}
          />

          <div className="footer p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
              loading={isSubmitting}
            />
            <Button
              label="Salvar"
              stretch
              loading={isSubmitting}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default InativarQuestionario;
