import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import './ComponentsLabel.scss';

export interface ComponentsLabelProps {
  name?: string | undefined;
  label?: string | undefined;
  required?: boolean;
  info?: string;
  sublabel?: string | undefined;
  overflowX?: 'visible' | 'hidden';
  labelTooltip?: JSX.Element | undefined;
}
const ComponentsLabel = ({
  name,
  label,
  required,
  sublabel,
  info,
  overflowX = 'visible',
  labelTooltip,
}: ComponentsLabelProps) => {
  return (
    <div className={'ComponentsLabel'}>
      <div className={'label-aligner'} style={{ overflowX }}>
        <label htmlFor={name} aria-hidden={overflowX === 'hidden'}>
          {label} {sublabel && <small>{sublabel}</small>}
        </label>
      </div>

      {!!labelTooltip && labelTooltip}
      {!!info && <SimpleText>{info}</SimpleText>}
    </div>
  );
};

export default ComponentsLabel;
