import { AssinaturaProvider } from 'src/core/containers/Assinatura/AssinaturaProvider';

import Page from 'src/components/Basics/Page/Page';

import MainMenu from 'src/pages/Emed/LoggedRoot/MainMenu/MainMenu';

import './LoggedRoot.scss';

const LoggedRoot = () => {
  return (
    <Page className={'LoggedRoot'}>
      <AssinaturaProvider>
        <MainMenu />
      </AssinaturaProvider>
    </Page>
  );
};

export default LoggedRoot;
