import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

interface PanelHeaderProps {
  medicamento: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  medicamento,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName =
    medicamento?.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    medicamento?.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  return (
    <div className="panel-header-prontuario p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-6 p-md-4 p-xl-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Nome
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {medicamento?.nomeMedicamento}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-12 p-md-2 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(medicamento?.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-6 p-md-2 p-xl-1 retroativo-chip">
        {medicamento?.justificativaOcorrenciaRetroativa && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>
      <div className="coluna-dados p-col-6 p-md-2 p-xl-1 p-d-flex p-flex-column">
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Em uso?
        </SimpleText>
        <SplitButton
          className="p-mt-2"
          menuClassName="menu-medicamento-em-uso"
          btnType={
            medicamento?.emUso ? SPLIT_BTN_TYPE.YELLOW : SPLIT_BTN_TYPE.GREEN
          }
          label={medicamento?.emUso ? 'Sim' : 'Não'}
          disabled
        />
      </div>
      <div className="coluna-acoes p-col-6 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Lembrete ativo={medicamento?.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
