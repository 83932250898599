import Http, { HttpParams } from 'src/core/http/Http';

import { FormNovaPergunta } from 'src/pages/Emed/Admin/FormularioDinamico/_components/DialogNovaPergunta/DialogNovaPerguntaForm';

type PayloadNovaPergunta = FormNovaPergunta & {
  tipoResposta: string;
  tipoRespostaAdicional?: string;
};

const loadPerguntasFormulario = async (
  params: {
    enunciado?: string;
    tipoResposta?: string;
    acervoPerguntas?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IPerguntaFormularioItem[]>> =>
  Http.get({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/pesquisar`,
    params,
    hideToast: true,
    ...options,
  });

const loadPerguntaFormularioById = async (
  params: { id: number | string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IPerguntaFormularioItem> =>
  Http.get({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/${params.id}`,
    hideToast: true,
    ...options,
  });

const createPerguntaFormulario = async (
  payload: PayloadNovaPergunta,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IPerguntaFormularioItem> =>
  Http.post({
    service: 'adm',
    url: `/pergunta-formulario-dinamico`,
    data: payload,
    ...options,
  });
const updatePerguntaFormulario = async (
  payload: IPerguntaFormularioItem,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IPerguntaFormularioItem> =>
  Http.put({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/${payload.id}`,
    data: payload,
    ...options,
  });

const deletePerguntaFormulario = async (
  params: { id: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/${params.id}`,
    ...options,
  });

const loadTiposRespostaFormulario = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IPerguntasFormularioDinamicoTiposResposta[]> =>
  Http.get({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/tipos-resposta`,
    hideToast: true,
    ...options,
  });
const loadTiposRespostaAdicional = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/tipos-resposta-adicional`,
    hideToast: true,
    ...options,
  });
const loadTiposValidacaoRespostaAdicional = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/tipos-validacao-resposta-adicional`,
    hideToast: true,
    ...options,
  });
const loadPerguntasParaVinculo = async (
  params: {
    enunciado?: string | undefined;
    id: number;
    idFormularioDinamico: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IFormularioDinamicoPergunta[]>> =>
  Http.get({
    service: 'adm',
    url: `/formulario-dinamico/${params.idFormularioDinamico}/listar-perguntas-vincular`,
    params,
    hideToast: true,
    ...options,
  });
const loadRespostasPerguntaParaVinculo = async (
  params: { idPergunta: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/pergunta-formulario-dinamico/listar-opcoes-resposta/${params.idPergunta}`,
    hideToast: true,
    ...options,
  });


export const PerguntaFormularioDinamicoAPI = {
  loadPerguntasFormulario,
  loadPerguntaFormularioById,
  createPerguntaFormulario,
  updatePerguntaFormulario,
  deletePerguntaFormulario,
  loadTiposRespostaFormulario,
  loadTiposRespostaAdicional,
  loadTiposValidacaoRespostaAdicional,
  loadPerguntasParaVinculo,
  loadRespostasPerguntaParaVinculo,
};
