import React from 'react';

import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { PanelContent } from './PanelContent';
import { PanelHeader } from './PanelHeader';

interface Props {
  cid: any;
  optionsMenu: any;
}

const RowCID = ({ cid, optionsMenu }: Props) => {
  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            cidCiap={cid}
            optionsMenu={optionsMenu(cid, 'CID')}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
          />
        )}
      >
        <PanelContent cidCiap={cid} />
      </Panel>
    </div>
  );
};

export default RowCID;
