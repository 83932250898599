import { memo, useMemo, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import TipoCuidadoAPI from 'src/APIs/ConfigAPI/TipoCuidado/TipoCuidadoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Skeleton from 'src/components/Skeleton/Skeleton';

import './FormStep2HorarioAtendimento.scss';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import dayjs from 'dayjs';

const twoYearsFromNow = new Date(
  new Date().setFullYear(new Date().getFullYear() + 2),
);
const dataInicioYearRange = `${new Date().getFullYear()}:${twoYearsFromNow.getFullYear()}`;

interface FormProps {
  initialValue?: any;
  onSubmit(form: any): void;
  onCancel(): void;
}

// Seta data mínima como dia anterior
const MIN_DATE = new Date();
MIN_DATE.setDate(MIN_DATE.getDate() - 1);

const validationSchema = Yup.object().shape({
  dataPeriodoIni: Yup.date()
    .when('horarioTemporario', {
      is: true,
      then: Yup.date()
        .nullable()
        .min(MIN_DATE, 'O campo data não pode ser retroativo')
        .required('O Campo data inicial é obrigatório'),
    })
    .nullable(),
  dataPeriodoFim: Yup.date()
    .when('horarioTemporario', {
      is: true,
      then: Yup.date()
        .min(
          Yup.ref('dataPeriodoIni'),
          'Data final não pode ser menor que data inicial',
        )
        .required('O Campo data final é obrigatório'),
    })
    .nullable(),
});

const FormStep2HorarioAtendimento = (props: FormProps) => {
  const { initialValue, onSubmit, onCancel } = props;

  const { consultorios } = useAppSelector((state: RootState) => state);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(validationSchema),
  });

  const watchHorarioTemporario = watch('horarioTemporario');
  const watchDataInicio = watch('dataPeriodoIni');

  useEffectSkipFirst(() => {
    if (!watchHorarioTemporario && !!watchDataInicio) {
      setValue('dataPeriodoIni', undefined);
      setValue('dataPeriodoFim', undefined);
    }
  }, [watchHorarioTemporario, watchDataInicio]);

  const [loadingTiposCuidados, setLoadingTiposCuidados] = useState(true);
  const [tiposCuidado, setTiposCuidado] = useState<TipoCuidadoDTO[]>([]);

  const dataFimYearRange = useMemo(
    () =>
      watchDataInicio
        ? `${watchDataInicio.getFullYear()}:${twoYearsFromNow.getFullYear()}`
        : dataInicioYearRange,
    [watchDataInicio],
  );

  const fetchTiposCuidado = async (idConsultorio: number) => {
    setLoadingTiposCuidados(true);

    try {
      const response = await TipoCuidadoAPI.loadTipoCuidado({
        ativo: true,
        idConsultorio,
      });

      setTiposCuidado(response);
    } catch (error) {
      setTiposCuidado([]);
    } finally {
      setLoadingTiposCuidados(false);
    }
  };

  useEffect(() => {
    if (consultorios?.ativo?.id) fetchTiposCuidado(consultorios.ativo.id);
  }, [consultorios?.ativo?.id]);

  const tiposCuidadoOptions = useMemo(() => {
    const options = tiposCuidado.map(tipoCuidado => ({
      label: tipoCuidado.descricao,
      value: tipoCuidado.id,
    }));

    return options;
  }, [tiposCuidado]);

  return (
    <form
      id="step2-horario-container"
      className="p-grid"
      onSubmit={handleSubmit(onSubmit)}
    >
      <CheckboxControlled
        control={control}
        className="p-col-12"
        name="horarioTemporario"
        label="Horário temporário"
      />

      {watchHorarioTemporario && (
        <>
          <SimpleText className="p-col-12">
            Defina o período da disponibilidade deste horário
          </SimpleText>

          <CalendarInputControlled
            control={control}
            className="p-col-12 p-md-6"
            name="dataPeriodoIni"
            label="Data inicial"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
            maxDate={twoYearsFromNow}
            yearRange={dataInicioYearRange}
            errorMsg={errors?.dataPeriodoIni?.message}
          />

          <CalendarInputControlled
            control={control}
            className="p-col-12 p-md-6"
            name="dataPeriodoFim"
            label="Data final"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            minDate={new Date()}
            maxDate={twoYearsFromNow}
            yearRange={dataFimYearRange}
            errorMsg={errors?.dataPeriodoFim?.message}
          />
        </>
      )}

      <div className="p-col-12">
        <Skeleton
          loading={loadingTiposCuidados}
          width="100%"
          height="40px"
          borderRadius="4px"
        >
          <MultiSelectControlled
            control={control}
            name="idsTipoCuidados"
            label="Tipo de cuidado"
            options={tiposCuidadoOptions}
          />
        </Skeleton>
      </div>

      <TextareaInputControlled
        control={control}
        className="p-col-12"
        name="observacoes"
        label="Observações"
      />

      <div className="p-col-12 p-grid">
        <Button
          className="p-col-6"
          type="button"
          btnType={BtnTypes.GHOST}
          label="Voltar"
          onClick={onCancel}
        />

        <Button
          className="p-col-6"
          type="submit"
          btnType={BtnTypes.TONAL}
          label="Salvar"
        />
      </div>
    </form>
  );
};

export const transformItemStep2 = (item: HorarioAtendimentoDTO | undefined) => {
  if (!item) return;

  const {
    dataPeriodoIni,
    dataPeriodoFim,
    observacoes,
    tipoAgenda,
    idMedico,
    idConsultorio,
    idsTipoCuidados,
  } = item;
  const horarioTemporario = !!(dataPeriodoIni && dataPeriodoFim);

  const newItem = {
    horarioTemporario,
    observacoes,
    tipoAgenda,
    idMedico,
    idConsultorio,
    idsTipoCuidados,
    ...(dataPeriodoIni && {
      dataPeriodoIni: new Date(dayjs(dataPeriodoIni).format()),
    }),
    ...(dataPeriodoFim && {
      dataPeriodoFim: new Date(dayjs(dataPeriodoFim).format()),
    }),
  };
  return newItem;
};

export default memo(FormStep2HorarioAtendimento);
