import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

export const DialogFinalizarExecucao = ({ isOpen, close }: DisclosureType) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { finalizarPrescricaoExecucao } = useAtendimento();

  const [hasItemsPendentes, setPendentes] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    PrescricaoAPI.loadPrescricaoExecucao(idAtendimento!, { throwError: true })
      .then(({ list }) => {
        if (!list?.length) return;

        const prescricao = list.filter(
          pres => pres.situacaoPrescricao === 'EM_EXECUCAO',
        );

        const hasPrescricao = prescricao.map(pres => {
          const materiaisPendentes = pres?.prescricaoMateriais?.find(
            mat => !mat.dataAplicacao,
          );
          const medicamentosPendentes = pres?.prescricaoMedicamentos?.find(
            med => !med.dataAplicacao,
          );

          if (!!materiaisPendentes || !!medicamentosPendentes) {
            return true;
          }
          return false;
        });

        setPendentes(hasPrescricao.some(Boolean));
      })
      .finally(() => setLoading(false))
      .catch(() => {});
  }, [idAtendimento]);

  const validationSchema = Yup.object({
    justificativa: Yup.string().required('Campo obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values?: any) => {
    await finalizarPrescricaoExecucao(
      Number(idAtendimento),
      values?.justificativa,
    );

    close();
  };

  if (loading) {
    return (
      <Dialog visible={true} onHide={close} closable={false}>
        <SpinnerLoading full />
      </Dialog>
    );
  }

  if (!hasItemsPendentes) {
    return (
      <ConfirmDialog
        header="Tem certeza que deseja finalizar a prescrição?"
        text="Ao confirmar, você estará devolvendo o atendimento para o médico e não poderá editá-lo novamente."
        onConfirm={() => [close(), onSubmit()]}
        visible={isOpen}
        onHide={close}
      />
    );
  }

  return (
    <Dialog
      header="Tem certeza que deseja finalizar a prescrição?"
      style={{ width: '440px' }}
      visible={isOpen}
      onHide={close}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-col-12 p-grid p-gap-3"
        >
          <SimpleText className="p-w-100 p-p-0">
            Existem medicamentos e/ou materiais que não foram utilizados. Deixe
            uma justificativa. Ao confirmar, você estará devolvendo o
            atendimento para o médico e não poderá editá-lo novamente.
          </SimpleText>
          <TextareaInputControlled
            name="justificativa"
            className="p-col-12 p-p-0"
            control={form.control}
            label="Justificativa"
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              onClick={() => close()}
              btnType="ghost"
              stretch
            />
            <Button
              label="Confirmar"
              type="submit"
              stretch
              disabled={form.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
