import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/termo-consentimento-telemedicinar';

export const loadTermos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTermoConsentimentoDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    hideToast: true,
    ...options,
  });
};

export const saveTermos = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostTermoConsentimentoDTO> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    ...options,
  });
};

export const duplicateTermos = (
  payload: any,
  idTermo: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    ...options,
  });
};

export const updateTermoConsentimento = (
  id: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostTermoConsentimentoDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    // errorHideToast: true
    ...options,
  });
};

export const removeTermoConsentimentoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

const TelemedicinaAPI = {
  loadTermos,
  saveTermos,
  duplicateTermos,
  updateTermoConsentimento,
  removeTermoConsentimentoById,
};

export default TelemedicinaAPI;
