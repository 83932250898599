import { useCallback, useState } from 'react';

import VacinasAPI from 'src/APIs/ProntuarioAPI/VacinasAPI/VacinasAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import CalendarioVacinal from './CalendarioVacinal/CalendarioVacinal';
import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import ModalNovaVacina from './ModalNovaVacina/ModalNovaVacina';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './Vacinas.scss';

interface VacinasProps {
  idPaciente: number | null;
}

function Vacinas({ idPaciente }: VacinasProps) {
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const { getInfoLembretes, atendimentoStatus } = useAtendimento();
  const [modalNovaVacinaVisible, setModalNovaVacinaVisible] =
    useState<boolean>(false);
  const [idVacinaInativacao, setIdVacinaInativacao] = useState<number | null>(
    null,
  );
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<PacientesVacinas | null>(null);
  const [idVacinaExcluir, setIdVacinaExcluir] = useState<number | null>(null);
  const [editVacina, setEditVacina] = useState<PacientesVacinas | null>(null);
  const [novaDose, setNovaDose] = useState<boolean>(false);
  const [updateList, setUpdateList] = useState<number>(0);

  const fetchAPI = useCallback(
    ({ query, ...rest }: any): Promise<any> => {
      return VacinasAPI.getPacientesVacinas({
        idPaciente,
        nomeVacina: query,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        ...rest,
      });
    },
    [idPaciente],
  );

  const onAlterarLembrete = async (idPacienteVacina: number) => {
    const response = await VacinasAPI.alterarPacienteVacinaLembrete(
      idPacienteVacina,
    );

    if (response?.status === 204) reloadList();
  };

  const onEdit = (vacina: PacientesVacinas) => {
    setEditVacina(vacina);
    setModalNovaVacinaVisible(true);
  };

  const onNovaDose = (vacina: PacientesVacinas) => {
    setEditVacina(vacina);
    setNovaDose(true);
    setModalNovaVacinaVisible(true);
  };

  const optionsMenu = (vacina: PacientesVacinas) => (
    <PanelOptionsMenu
      ativo={vacina.status}
      lembreteAtivo={vacina.lembrete}
      idAtendimentoRegistro={vacina.idAtendimento}
      idUsuarioCriacao={vacina.idUsuarioAtualizacao}
      onAlterarLembrete={() => onAlterarLembrete(vacina.id)}
      onEdit={() => onEdit(vacina)}
      onInativar={() => setIdVacinaInativacao(vacina.id)}
      onExcluir={() => setIdVacinaExcluir(vacina.id)}
      onVisualizarJustificativa={() => setVisualizarJustificativa(vacina)}
      hideInativarOption={vacina.status === 'INATIVO'}
    />
  );

  const renderRow = (vacina: PacientesVacinas) => (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            vacina={vacina}
            optionsMenu={optionsMenu(vacina)}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            onNovaDose={() => onNovaDose(vacina)}
            reloadList={reloadList}
          />
        )}
      >
        <PanelContent vacina={vacina} />
      </Panel>
    </div>
  );

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));
  };

  const onExcluir = async () => {
    const response = await VacinasAPI.removerPacienteVacina(
      idVacinaExcluir as number,
    );

    if (response?.status === 204) {
      reloadList();
      setIdVacinaExcluir(null);
    }
  };

  const onHideModalVacina = () => {
    setEditVacina(null);
    setNovaDose(false);
    setModalNovaVacinaVisible(false);
  };

  const toggleShowSearchInput = () => setShowSearchInput(prev => !prev);

  if (!idPaciente) return null;

  return (
    <div id="vacinas-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Vacinas
            </SimpleText>

            <div className="p-d-flex p-ai-center">
              <Button
                className="p-mr-4"
                type="button"
                btnType="tonal"
                label="Adicionar Vacina"
                icon="fas fa-plus"
                onClick={() => setModalNovaVacinaVisible(true)}
                disabled={
                  atendimentoStatus !== 'ATENDENDO' &&
                  atendimentoStatus !== 'ATENDENDO_TRIAGEM'
                }
              />

              <Button
                icon="fas fa-search"
                btnType="gray"
                onClick={toggleShowSearchInput}
              />
            </div>
          </div>

          {showSearchInput && (
            <div className="p-mb-5 p-mt-3">
              <SearchList.SearchInput
                className="p-col-12"
                placeholder="Pesquisar"
              />
            </div>
          )}

          <CalendarioVacinal idPaciente={idPaciente} />

          <SearchList.InfiniteScroll
            className="p-mt-5 infinite-scroll-vacinas"
            customEmptyText="Nenhum item encontrado para esta busca."
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalNovaVacina
        visible={modalNovaVacinaVisible}
        editVacina={editVacina}
        novaDose={novaDose}
        onHide={onHideModalVacina}
        onSuccess={reloadList}
      />

      <ModalJustificativa
        idVacina={idVacinaInativacao}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setIdVacinaInativacao(null);
          setVisualizarJustificativa(null);
        }}
        onSuccess={reloadList}
      />

      <ConfirmDialog
        visible={!!idVacinaExcluir}
        header="Excluir Vacina"
        text="A Vacina será excluída. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdVacinaExcluir(null)}
        onConfirm={onExcluir}
      />
    </div>
  );
}

export default Vacinas;
