import React, { useState } from 'react';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import SimpleText from '../Basics/SimpleText/SimpleText';
import Separator from '../Separator/Separator';

import './Steps.scss';

export type StepItem = {
  label: string;
  sublabel?: string;
  icon?: boolean;
  onClickIcon?: () => void;
  onClick?: () => void;
  disabled?: boolean;
};

type StepProps = {
  items: StepItem[];
  initialStep?: number;
  onChange?: (step: number) => void;
  stepValue?: number;
};

export const Steps = ({ items, onChange, stepValue }: StepProps) => {
  const [step, setStep] = useState(stepValue || 0);

  const handleChangeStep = (step: number) => {
    if (onChange) {
      return onChange(step);
    }
    return setStep(step);
  };

  useEffectSkipFirst(() => {
    if (stepValue || stepValue === 0) {
      setStep(stepValue);
    }
  }, [stepValue]);

  return (
    <div className="Steps">
      {items.map((item, idx, arr) => {
        const hasMoreThanOneStep = arr.length > 1;
        const isLastItem = idx === arr.length - 1;

        return (
          <React.Fragment key={idx}>
            <div
              className="step-container"
              data-isActive={step === idx}
              data-isDisabled={item.disabled}
              onClick={() => handleChangeStep(idx)}
            >
              <span className="step-count">{idx + 1}</span>

              <div className="p-d-flex p-flex-column p-gap-1">
                <SimpleText className="step-label">{item.label}</SimpleText>
                {item.sublabel && (
                  <SimpleText
                    fontSize="xxs"
                    fontColor="color_40"
                    className="step-sublabel"
                  >
                    {item.sublabel}
                  </SimpleText>
                )}
              </div>

              {item.icon && (
                <i
                  className="step-icon fas fa-info-circle"
                  onClick={e => [item.onClickIcon?.(), e.stopPropagation()]}
                />
              )}
            </div>

            {hasMoreThanOneStep && !isLastItem && (
              <Separator
                vertical
                style={{
                  width: '15px',
                  padding: 0,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
