import { Control, Controller } from 'react-hook-form';

import MultiSelect, { MultiSelectProps } from '../MultiSelect/MultiSelect';

export interface MultiSelectControlledProps extends MultiSelectProps {
  control: Control<any>;
  name: string;
}

const MultiSelectControlled = ({
  name,
  control,
  ...rest
}: MultiSelectControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }: any) => (
        <MultiSelect
          {...rest}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    />
  );
};

export default MultiSelectControlled;
