import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/prontuario-info';

export const getInfoLembretes = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/lembretes/pesquisa`,
    params,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });

export const getInfoPaciente = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/paciente/${idPaciente}`,
    hideToast: true,
    errorHideToast: true,
    return: true,
    ...options,
  });

export const putInfoPaciente = (
  idPaciente: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/paciente/${idPaciente}`,
    data: payload,
    hideToast: true,
    errorHideToast: false,
    return: true,
    ...options,
  });

export const getInfoSecretaria = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/secretaria`,
    hideToast: true,
    return: true,
    ...options,
  });

const ProntuarioInfoAPI = {
  getInfoLembretes,
  getInfoPaciente,
  putInfoPaciente,
  getInfoSecretaria,
};

export default ProntuarioInfoAPI;
