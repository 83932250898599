import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <div className="p-col-2">
            <SimpleText fontSize={FONT_SIZE.XXS}>Apelido do grupo</SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText fontSize={FONT_SIZE.XXS}>
              M/A inicial dos atend.
            </SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText fontSize={FONT_SIZE.XXS}>Total de pacientes</SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText fontSize={FONT_SIZE.XXS}>Tipo de grupo</SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText fontSize={FONT_SIZE.XXS}>
              Qtd. de agendamentos
            </SimpleText>
          </div>
          <div className="p-col-1">
            <SimpleText fontSize={FONT_SIZE.XXS}>Situação</SimpleText>
          </div>
          <Can
            checkPermission={[
              'AGE_GRUPO_DE_ATENDIMENTO_ALTERAR',
              'AGE_GRUPO_DE_ATENDIMENTO_EXCLUIR',
            ]}
          >
            <div className="p-col-1">
              <SimpleText fontSize={FONT_SIZE.XXS}>Ações</SimpleText>
            </div>
          </Can>
        </div>
      )}
    </>
  );
};
