import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { FolhaRostoAPI } from 'src/APIs/ProntuarioAPI/FolhaRosto/FolhaRostoAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import { FieldMotivoTransferencia } from 'src/components/Fields/FieldMotivoTransferencia/FieldMotivoTransferencia';

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list: string[]) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Ao menos um campo preenchido é obrigatório',
    test: function (value) {
      const anyHasValue = value == null || list.some(field => !!value[field]);

      const { createError }: any = this;

      return !anyHasValue
        ? createError({
            path: 'atLeastOneOf',
            message: 'Ao menos um campo preenchido é obrigatório',
          })
        : true;
    },
  });
});

declare module 'yup' {
  interface ObjectSchema<TShape, TContext, TIn, TOut> {
    atLeastOneOf(list?: string[]): this;
  }
}

export const DialogMotivoTransferencia = ({
  isOpen,
  close,
  state,
}: DisclosureType) => {
  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        motivo: Yup.object(),
        outrosMotivos: Yup.string(),
      }).atLeastOneOf(['motivo', 'outrosMotivos']),
    ),
  });

  const onSubmit = async (data: any) => {
    const { idPaciente, idConsultorio, reloadFolhaRosto, ...rest } = state;

    const payload = {
      ...rest,
      ...data,
      idMotivoTransferencia: data.motivo?.id,
    };

    const response = await FolhaRostoAPI.postFolhaRostoTransferirCuidador(
      idConsultorio,
      idPaciente,
      payload,
    );

    if (response.status === 204) {
      reloadFolhaRosto();
      close();
    }
  };

  return (
    <Dialog
      className="folha-rosto-motivo-transferencia-modal"
      style={{ width: '375px' }}
      visible={isOpen}
      onHide={close}
      maximizedMobileSize
      header="Motivo"
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="p-d-flex p-flex-column"
        >
          <FieldMotivoTransferencia name="motivo" label="Motivo" />

          <TextareaInputControlled
            control={form.control}
            name="outrosMotivos"
            label="Outros motivos"
            className="p-col-12"
            maxLength={100}
            maxLengthSpanDesc
          />

          {!!form?.formState?.errors?.atLeastOneOf?.message && (
            <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
              {form.formState.errors.atLeastOneOf.message}
            </SimpleText>
          )}

          <div className="p-d-flex p-col-12 p-gap-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              onClick={() => close()}
              stretch
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={form.formState?.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
