import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';

import { ModalAnexarQuimio } from './ModalAnexarQuimio/ModalAnexarQuimio';

interface IProps {
  hasAnexoQuimio: boolean;
  idProcedimento: number;
  numeroGuia: number;
}

export const AnexoQuimioterapia = (props: IProps) => {
  const { hasAnexoQuimio, idProcedimento, numeroGuia } = props;

  const modalAnexoQuimio = useDisclosure({ opened: false });

  if (!hasAnexoQuimio) return null;

  return (
    <>
      <div className="p-col-12">
        <Button
          type="button"
          btnType="tonal"
          label="Anexo Quimioterapia"
          onClick={() =>
            modalAnexoQuimio.open({
              state: { idProcedimento, numeroGuia },
            })
          }
        />
      </div>
      {modalAnexoQuimio.isOpen && <ModalAnexarQuimio {...modalAnexoQuimio} />}
    </>
  );
};
