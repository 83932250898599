import { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import PerimetroCefalicoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PerimetroCefalicoAPI/PerimetroCefalicoAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

interface PerimetroCefalicoProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const PerimetroCefalico = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: PerimetroCefalicoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { setQuantidadePreAtendimento } = useHistoricoAtendimento();

  const [quantidadeItens, setQuantidadeItens] = useState<number>(1);
  const [exibirLista, setExibirLista] = useState<boolean>(true);

  useEffect(() => {
    if (quantidadeItens === 0 && selectedAtendimento) {
      setExibirLista(false);
    }
  }, [quantidadeItens, selectedAtendimento]);

  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      const response = await PerimetroCefalicoAPI.getPerimetroCefalico({
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        ...params,
      });
      setQuantidadeItens(response?.totalCount);
      setQuantidadePreAtendimento(
        (prev: number) => prev + response?.totalCount,
      );
      return response;
    },
    [
      idAtendimento,
      selectedAtendimento?.idAtendimento,
      setQuantidadePreAtendimento,
    ],
  );

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
  ) => {
    return (
      <div className="p-col p-sm-4 p-d-flex">
        <div className="column p-jc-center p-ml-3">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Idade em meses
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.idadeMeses}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
        <div className="column p-jc-center p-ml-3">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Perímetro Cefálico
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.perimetroCefalico} cm
          </SimpleText>
        </div>
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento} />
      </Panel>
    );
  };

  return (
    <>
      {exibirLista && (
        <div className="p-my-2 container-pre-atendimento">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            className="p-mb-2"
            medium
          >
            Perímetro Cefálico
          </SimpleText>
          <div className="p-my-2">
            <InfiniteScroll
              fetchAPI={fetchAPI}
              renderRow={renderRow}
              updateList={updateList}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PerimetroCefalico;
