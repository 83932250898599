import {
  PacienteMemedProps,
  MemedReceitaPrepararReceitaPaciente,
  MemedReceitaRetornoReceitaPaciente,
} from 'src/models/APIs/ProntuarioAPI/Memed/MemedDTOs';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/memed';

const getPrepararReceitaPaciente = (
  params: MemedReceitaPrepararReceitaPaciente,
  options?: any,
): Promise<PacienteMemedProps> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/preparar-receita/paciente/${params.idPaciente}`,
    ...options,
    hideToast: true,
  });
};

const getPrepararReceitaOculosOrImpressoPaciente = (
  params: MemedReceitaPrepararReceitaPaciente,
  options?: any,
): Promise<PacienteMemedProps> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/preparar-receita/paciente/${params.idPaciente}`,
    params: params,
    ...options,
    hideToast: true,
  });
};

const getMedicamentos = (params?: any, options?: any): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/medicamentos`,
    params: params,
    ...options,
    hideToast: true,
  });
};

const adicionarReceitaPaciente = (
  params: MemedReceitaRetornoReceitaPaciente,
  payload: any,
  options?: any,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}/${params.idAtendimento}/retornoReceita`,
    data: payload,
    ...options,
    hideToast: true,
  });
};

const adicionarImpressoPaciente = (
  params: MemedReceitaRetornoReceitaPaciente,
  payload: any,
  idTipoImpresso: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}/${params.idAtendimento}/retornoReceita`,
    data: payload,
    params: idTipoImpresso,
    hideToast: true,
    ...options,
  });
};

export const MemedAPI = {
  getPrepararReceitaPaciente,
  adicionarReceitaPaciente,
  adicionarImpressoPaciente,
  getMedicamentos,
  getPrepararReceitaOculosOrImpressoPaciente,
};
