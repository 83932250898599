import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

import { formatData } from 'src/pages/Emed/Pacientes/CentralPacientes/ManterPaciente/helpers';

export const MeusDadosContatoEnderecoForm = ({ handleNext, userData }: any) => {
  const [estadosOptions, setEstadosOptions] = useState<any>([]);
  const [cidadesOptions, setCidadesOptions] = useState<any>([]);

  const { idPaciente, idEmpresa } = useAppSelector(state => state.user);
  const form = useForm({});

  useEffect(() => {
    const contatoTelefone = userData?.contatos?.find(
      (contato: any) => contato.tipo === 'FIXO_RESIDENCIAL',
    );
    const contatoCelular = userData?.contatos?.find?.(
      (contato: any) =>
        contato.tipo === 'CELULAR_PARTICULAR' || contato.tipo === 'RECADO',
    );

    form.reset({
      email: userData?.email,
      dddTelefone: contatoTelefone?.numero.substring(0, 2),
      telefone: contatoTelefone?.numero?.slice(2),
      dddCelular: contatoCelular?.numero.substring(0, 2),
      celular: contatoCelular?.numero?.slice(2),
      logradouro: userData?.enderecoResidencial?.logradouro,
      bairro: userData?.enderecoResidencial?.bairro,
      cep: userData?.enderecoResidencial?.cep,
      idCidade: userData?.enderecoResidencial?.idCidade,
      idEstado: userData?.enderecoResidencial?.cidade?.idEstado,
    });
  }, [form, userData]);

  const onSubmit = async (v: any) => {
    const celular = !!v.celular ? `${v.dddCelular} ${v.celular}` : null;
    const telefone = !!v.telefone ? `${v.dddTelefone} ${v.telefone}` : null;

    await PortalPacienteAPI.updatePacienteEndereco(idPaciente, {
      enderecos: [
        {
          logradouro: v.logradouro,
          bairro: v.bairro,
          cep: v.cep,
          cidade: {
            idEstado: v.idEstado,
          },
          idCidade: v.idCidade,
        },
      ],
    });

    if (!!celular || !!telefone) {
      let contatos = [
        !!celular && {
          numero: celular,
          tipo: 'CELULAR_PARTICULAR',
        },
        !!telefone && {
          numero: telefone,
          tipo: 'FIXO_RESIDENCIAL',
        },
      ];

      contatos = contatos.filter(Boolean);

      await PortalPacienteAPI.updatePacienteContato(
        idPaciente,
        {
          idEmpresa,
          email: v.email,
          contatos,
        },
        {
          hideToast: true,
        },
      );
    }

    handleNext();
  };

  const watchEstado = form.watch('idEstado');

  const fetchEstadosData = async () => {
    const response = await UtilsAPI.GetEstados();
    setEstadosOptions(formatData(response, 'nome', 'id'));
  };

  useEffect(() => {
    fetchEstadosData();
  }, []);

  const loadCidadesByEstado = async (idEstado: string) => {
    const response = await UtilsAPI.GetCidades(idEstado);
    if (Array.isArray(response)) {
      const cidadesFormatted = response.map(cidade => ({
        label: cidade.nome,
        value: cidade.id,
      }));
      setCidadesOptions(cidadesFormatted);
    }
  };

  useEffect(() => {
    if (watchEstado) {
      loadCidadesByEstado(watchEstado);
    } else {
      setCidadesOptions([]);
    }
  }, [watchEstado]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2"
      >
        <SimpleText fontColor="color_60" medium>
          Contato
        </SimpleText>

        <FormInput name="email" label="E-mail" hideTextErrorSpace />

        <SimpleText medium>Telefone</SimpleText>
        <div className="p-d-flex p-gap-2">
          <FormInput
            name="dddTelefone"
            className="p-col-3 p-md-2 p-p-0"
            type="number"
            hideTextErrorSpace
          />
          <FormInput
            type="number"
            name="telefone"
            className="p-col-9 p-md-10 p-p-0"
            hideTextErrorSpace
          />
        </div>
        <SimpleText medium>Celular</SimpleText>
        <div className="p-d-flex p-gap-2">
          <FormInput
            name="dddCelular"
            className="p-col-3 p-md-2 p-p-0"
            type="number"
            hideTextErrorSpace
          />
          <FormInput
            type="number"
            name="celular"
            className="p-col-9 p-md-10 p-p-0"
            hideTextErrorSpace
          />
        </div>

        <SimpleText fontColor="color_60" medium className="p-my-2">
          Endereço
        </SimpleText>

        <FormInput name="logradouro" label="Logradouro" hideTextErrorSpace />
        <FormInput name="bairro" label="Bairro" hideTextErrorSpace />
        <FormInput name="cep" label="CEP" hideTextErrorSpace />
        <DropdownControlled
          control={form.control}
          name="idEstado"
          label="Estado"
          placeholder="Pesquisar"
          options={estadosOptions}
        />
        <DropdownControlled
          control={form.control}
          name="idCidade"
          label="Cidade"
          placeholder="Pesquisar"
          options={cidadesOptions}
          disabled={!cidadesOptions.length}
        />

        <Button
          type="submit"
          label="Salvar alterações"
          stretch
          loading={form.formState?.isSubmitting}
        />
      </form>
    </FormProvider>
  );
};
