import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import SearchProfissionais from '../components/SearchProfissionais';
import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Switch from 'src/components/Switch/Switch';

interface ModalAddProfissionalProps extends DisclosureType {
  profissionaisEquipe: any[];
  appendProfissionalFields: (profissional: any) => void;
  updateOldResponsavelToFalse: () => void;
}

const ModalAddProfissional = ({
  isOpen,
  close,
  profissionaisEquipe,
  appendProfissionalFields,
  updateOldResponsavelToFalse,
}: ModalAddProfissionalProps) => {
  const [profissionaisSelecionados, setProfissionaisSelecionados] =
    useState<any>([]);

  const { clearErrors } = useFormContext();

  const handleAdicionarProfissionais = () => {
    profissionaisSelecionados.forEach(
      ({ id, responsavel = false, ...profissional }: any) => {
        profissional = {
          id,
          profissionalSaude: {
            usuario: { nome: profissional.nome },
            ...profissional,
          },
          responsavel,
        };
        if (responsavel) updateOldResponsavelToFalse();
        appendProfissionalFields(profissional);
      },
    );

    clearErrors('equipeProfissionaisSaude');
    close();
  };

  const handleProfissionalResponsavel = (idProfissional: number) =>
    setProfissionaisSelecionados((prevProfissionaisSelecionados: any) =>
      prevProfissionaisSelecionados.map((profissionalSelecionado: any) => ({
        ...profissionalSelecionado,
        responsavel: profissionalSelecionado.id === idProfissional,
      })),
    );

  const renderProfissionais = () => {
    if (!profissionaisSelecionados.length)
      return (
        <Card className="p-text-center" type={CARD_TYPES.GRAY}>
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            Selecione os profissionais.
          </SimpleText>
        </Card>
      );

    return profissionaisSelecionados.map(
      (profissionalSelecionado: any, index: number) => (
        <div key={index} className="p-grid profissionais-selecionados">
          <div className="p-grid p-col-12 p-sm-8">
            <SimpleText medium className="p-col-12 p-px-0">
              {profissionalSelecionado?.nome}
            </SimpleText>
            <SimpleText
              className="p-col-12 p-pt-0 p-px-0"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              {profissionalSelecionado?.tipoProfissional}{' '}
              {profissionalSelecionado?.siglaConselhoTiss}{' '}
              {profissionalSelecionado?.numeroRegistro}
            </SimpleText>
          </div>
          <div className="p-grid p-col-12 p-sm-4 p-justify-end p-ai-center">
            <>
              <SimpleText
                fontColor={
                  profissionalSelecionado.responsavel
                    ? FONT_COLOR.COLOR_16
                    : FONT_COLOR.COLOR_79
                }
                fontSize={FONT_SIZE.XXS}
                medium={profissionalSelecionado.responsavel}
              >
                Responsável
              </SimpleText>
              <Switch
                className="p-ml-1"
                checked={profissionalSelecionado.responsavel}
                onChange={() =>
                  handleProfissionalResponsavel(profissionalSelecionado.id)
                }
              />
              <Button
                className="p-ml-3"
                btnType="ghost"
                icon="fa fa-trash"
                onClick={() => {
                  const novosProfissionaisSelecionados =
                    profissionaisSelecionados.filter(
                      (profissional: any) =>
                        profissional.id !== profissionalSelecionado.id,
                    );
                  setProfissionaisSelecionados(novosProfissionaisSelecionados);
                }}
              />
            </>
          </div>
        </div>
      ),
    );
  };

  return (
    <Dialog
      header="Adicionar profissionais"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
      id="modal-add-profissional"
    >
      <>
        <SearchProfissionais
          label={''}
          profissionaisAdded={profissionaisEquipe}
          setProfissionaisSelecionados={setProfissionaisSelecionados}
          profissionaisSelecionados={profissionaisSelecionados}
        />

        <div className="selected-list p-p-2 p-my-2">
          <div className="p-mb-2">
            <SimpleText medium>
              {profissionaisSelecionados?.length > 1
                ? 'Profissionais'
                : 'Profissional'}
            </SimpleText>
          </div>
          {renderProfissionais()}
        </div>

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />

          <Button
            label="Adicionar"
            onClick={() => handleAdicionarProfissionais()}
            disabled={!profissionaisSelecionados.length}
            stretch
          />
        </div>
      </>
    </Dialog>
  );
};

export default ModalAddProfissional;
