import { Control, Controller, useController } from 'react-hook-form';

import { RadioButton } from '../_UI/RadioButton';
import SimpleText, { FONT_SIZE } from '../Basics/SimpleText/SimpleText';

import './RadioButtonGroupControlled.scss';

type ItemsProp = {
  label: string;
  secondText?: string;
  value: any;
  state?: any;
};

type RadioButtonGroupProps = {
  name: string;
  items: ItemsProp[];
  error?: any;
  control: Control<any>;
  className?: string;
  customOnChange?: (value: any, state?: any) => void;
  stretch?: boolean;
  dir?: 'row' | 'column';
  unstyled?: boolean;
  disabled?: boolean;
  idKey?: string;
};

export const RadioButtonGroupControlled = ({
  name,
  items,
  control,
  className,
  customOnChange,
  stretch,
  dir = 'row',
  unstyled = false,
  idKey = 'id',
  ...rest
}: RadioButtonGroupProps) => {
  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <div className="radio-group p-col-12 p-px-0">
      <div
        className={`p-d-flex p-flex-${dir} p-gap-2 container-group ${
          stretch ? 'stretch' : ''
        }`}
      >
        {items.map((item, idx) => (
          <Controller
            key={idx}
            control={control}
            name={name}
            render={({ field: { onChange, value: valueRadio } }) => {
              const isItemChecked = !!item.value?.[idKey]
                ? valueRadio?.[idKey] === item.value?.[idKey]
                : valueRadio === item.value;

              return (
                <>
                  <RadioButton
                    {...rest}
                    className={`${className || ''} ${
                      stretch ? 'p-flex-1' : ''
                    } ${unstyled ? 'unstyled' : ''}`}
                    label={item.label}
                    value={item.value}
                    secondText={item.secondText}
                    checked={isItemChecked}
                    onChange={() => onChange(item.value)}
                    customOnChange={() =>
                      customOnChange?.(item.value, item?.state)
                    }
                  />
                </>
              );
            }}
          />
        ))}
      </div>

      {!!error?.message && (
        <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
          {error?.message || ''}
        </SimpleText>
      )}
    </div>
  );
};
