import React, { useCallback, useEffect, useState } from 'react';

import { FileUpload } from 'primereact/fileupload';
import { useForm, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ProcedimentosSeriadosAPI from 'src/APIs/ProntuarioAPI/ProcedimentosSeriadosAPI/ProcedimentosSeriadosAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import ModalModelo from 'src/pages/Emed/Prontuario/components/ModalModelo/ModalModelo';
import { ModalNovoModelo } from 'src/pages/Emed/Prontuario/components/ModalNovoModelo/ModalNovoModelo';

import { OverlayAnexar } from './OverlayAnexar';
interface ProcedimentoProps {
  guiaExecucao: any;
  index: number;
}

//Esta funcionalidade esta com bastante gambiarras, pois o prazo para entrega era curto e nao foi possivel refatorar o codigo.

export const Procedimento = ({ guiaExecucao, index }: ProcedimentoProps) => {
  const [modelosProcedimentos, setModelosProcedimentos] = useState<any[]>([]);
  const [googleUrl, setGoogleUrl] = useState<string | null>('');
  const [possuiAnexo, setPossuiAnexo] = useState<boolean>(false);

  const { getGuiasExecucoes } = useAtendimento();

  const {
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { modelos, getModelos, setModelos } = useAtendimento();

  const modalDisclosure = useDisclosure({ opened: false });

  const passData = useCallback(
    (data: any) => {
      setValue(
        `laudo${index}`,
        getValues(`laudo${index}`)
          ? getValues(`laudo${index}`) + data?.texto + '\n'
          : data?.texto + '\n',
      );
    },
    [getValues, index, setValue],
  );

  useEffect(() => {
    setValue(`laudo${index}`, guiaExecucao.laudo);
    setValue(`anexo${index}`, guiaExecucao.anexo);
  }, [guiaExecucao.anexo, guiaExecucao.laudo, index, setValue]);

  //load modelo padrao
  useEffect(() => {
    Array.isArray(modelos)
      ? modelos?.forEach(modelo => {
          if (modelo.campo === 'EXECUCAO_PROCEDIMENTOS' && modelo.padrao) {
            setValue(`laudo${index}`, modelo.texto + '\n');
          }
        })
      : getModelos();
  }, [getModelos, index, modelos, setValue]);

  // load modelos do campo EXECUCAO_PROCEDIMENTOS
  useEffect(() => {
    const modelosCampo: any[] = [];
    Array.isArray(modelos)
      ? modelos?.forEach(modelo => {
          if (modelo.campo === 'EXECUCAO_PROCEDIMENTOS') {
            modelosCampo.push(modelo);
          }
        })
      : getModelos();
    setModelosProcedimentos(modelosCampo);
  }, [getModelos, modelos]);

  useEffect(() => {
    setValue(
      `procedimento${index}`,
      capitalizeFirstLetter(guiaExecucao.descricaoProcedimento),
    );
    setValue('profissionalSolicitante', 'Dr. João da Silva');
  }, [guiaExecucao.descricaoProcedimento, index, setValue]);

  const onBasicUploadAuto = () => {
    toast.success('Arquivo enviado com sucesso!');
  };

  const chooseOptions = {
    icon: 'fa-solid fa-paperclip',
    iconOnly: true,
    className: 'p-button-outlined',
  };

  const handleOnBlur = useCallback(
    async (data: any) => {
      const payload = {
        id: guiaExecucao.id,
        laudo: getValues(`laudo${index}`),
        anexo: getValues(`anexo${index}`) || null,
      };

      const response = await ProcedimentosSeriadosAPI.postGuiaExecucao(payload);

      if (response.status > 200 && response.status < 300) {
        getGuiasExecucoes();
      }
    },
    [getGuiasExecucoes, getValues, guiaExecucao.id, index],
  );

  const checkAnexo = useCallback(() => {
    if (
      getValues(`anexo${index}`) !== undefined &&
      getValues(`anexo${index}`) !== null
    ) {
      return (
        <div className="anexo-ok">
          <Button
            icon="fa-solid fa-paperclip"
            label="Ver anexo"
            className="p-button-outlined"
            type="button"
            onClick={() => window.open(guiaExecucao.anexo, '_blank')}
          />
        </div>
      );
    } else {
      return null;
    }
  }, [getValues, guiaExecucao.anexo, index]);

  return (
    <div className="p-grid p-col-12">
      <div className="p-d-flex p-flex-column p-col-12 p-md-6 p-lg-5">
        <SimpleText
          className="p-mb-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
          medium
        >
          Procedimento:
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
          {capitalizeFirstLetter(guiaExecucao.descricaoProcedimento)}
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-col-12 p-md-6 p-lg-5">
        <SimpleText
          className="p-mb-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
          medium
        >
          Profissional Solicitante:
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
          Dr. João da Silva
        </SimpleText>
      </div>
      <div className="p-d-flex p-col-12 p-jc-between p-ai-center">
        <SimpleText
          className="p-ml-2 p-pt-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Laudo
        </SimpleText>
        <div className="p-d-flex modelos">
          <Button
            btnType="green-link"
            label="Modelo"
            iconPos="right"
            icon="pi pi-chevron-down"
            type="button"
            overlay={
              <ModalModelo
                onOpenModal={modalDisclosure.open}
                modelos={modelosProcedimentos}
                passData={passData}
                valorCampoProntuario={''}
                setValorCampoProntuario={() => {}}
              />
            }
          />
          <Button
            btnType="green-link"
            className="p-px-4 anexar"
            icon="fa-solid fa-paperclip"
            type="button"
            overlay={
              <OverlayAnexar
                guiaExecucao={guiaExecucao}
                index={index}
                setGoogleUrl={setGoogleUrl}
              />
            }
          ></Button>
        </div>
      </div>
      <div className="p-col-12 p-pt-0">
        <TextareaInputControlled
          control={control}
          name={`laudo${index}`}
          onBlur={() => handleOnBlur(getValues())}
          rows={4}
        />
        {checkAnexo()}
      </div>
      {modalDisclosure.isOpen && (
        <ModalNovoModelo
          valorCampoProntuario={''}
          setValorCampoProntuario={() => {}}
          tipoCampo="EXECUCAO_PROCEDIMENTOS"
          {...modalDisclosure}
        />
      )}
    </div>
  );
};
