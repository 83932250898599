import { useNavigate } from 'react-router';

import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';
import { useMemo } from 'react';

interface ConvenioRowProps {
  convenio: Convenio;
  onViewConvenio: () => void;
  onDelete: () => void;
  onSwitchStatus: () => void;
}

export const ConvenioRow = (props: ConvenioRowProps) => {
  const { convenio, onViewConvenio, onDelete, onSwitchStatus } = props;

  const navigate = useNavigate();

  const { windowInnerWidth } = useSize();

  const isMobile = useMemo(() => windowInnerWidth <= 650, [windowInnerWidth]);

  const handleEdit = () => {
    navigate(`/cadastros/convenio/${convenio.id}`);
  };

  return isMobile ? (
    <MobileRow
      convenio={convenio}
      onViewConvenio={onViewConvenio}
      onEdit={handleEdit}
      onDelete={onDelete}
      onSwitchStatus={onSwitchStatus}
    />
  ) : (
    <DesktopRow
      convenio={convenio}
      onViewConvenio={onViewConvenio}
      onEdit={handleEdit}
      onDelete={onDelete}
      onSwitchStatus={onSwitchStatus}
    />
  );
};
