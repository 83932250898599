import { useCallback, useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { CategoryScale } from 'chart.js';
import ChartJS from 'chart.js/auto';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import DnpmAPI from 'src/APIs/ProntuarioAPI/DnpmAPI/DnpmAPI';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { cn } from 'src/utils/cn';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { DnpmHeader } from './DnpmHeader';
import Table from './Table';

ChartJS.register(CategoryScale);

interface ModalDnpmProps extends DisclosureType {
  idPaciente: number | null;
  idAtendimento: any;
}

function ModalDnpm({
  idPaciente,
  idAtendimento,
  isOpen,
  close,
}: ModalDnpmProps) {
  const { agenda } = useAppSelector((state: RootState) => state);

  const validatioSchema = Yup.object().shape({
    atrasado: Yup.bool()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = form;

  const canvasRef = useRef<any>(null);

  const { atendimentoStatus } = useAtendimento();

  const [questionario, setQuestionario] = useState<any>();
  const [respostasQuestionario, setRespostasQuestionario] = useState<any>({});
  const [respostasQuestionarioInicial, setRespostasQuestionarioInicial] =
    useState<any>({});
  const [isLoadingImpressao, setIsLoadingImpressao] = useState(false);
  const [disabledSalvar, setDisableSalvar] = useState(false);

  const sairDNPMDialog = useDisclosure({ opened: false });

  const closeSairSemSalvar = () => sairDNPMDialog.close();

  const loadData = useCallback(async () => {
    const getQuestionario = async () => {
      if (!idPaciente || !agenda?.profissionalAtivo?.id) return false;

      const response = await DnpmAPI.getQuestionario(
        idPaciente,
        agenda?.profissionalAtivo?.id,
      );

      if (response?.status === 200) {
        setQuestionario(response?.data);
        return true;
      }

      return false;
    };

    const postQuestionario = async () => {
      const payload = {
        idAtendimento: idAtendimento,
        idPaciente: idPaciente,
      };
      const postResponse = await DnpmAPI.postQuestionario(payload);
      if (postResponse.status >= 200 && postResponse.status < 300) {
        getQuestionario();
      }
    };

    const hasQuestionario = await getQuestionario();

    if (!hasQuestionario) {
      postQuestionario();
    }
  }, [agenda?.profissionalAtivo?.id, idAtendimento, idPaciente]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        ...respostasQuestionario,
        ...data,
      };

      respostasQuestionario.atrasado = data.atrasado;

      const response = await DnpmAPI.putQuestionario(
        idAtendimento,
        questionario.id,
        payload,
      );

      setRespostasQuestionario((prevState: any) => ({
        ...prevState,
        ...data,
      }));

      if (response?.status === 200) {
        reset(data);
        loadData();
      }
    },
    [idAtendimento, loadData, questionario?.id, reset, respostasQuestionario],
  );

  useEffectSkipFirst(() => {
    if (
      !isDirty &&
      _.isEqual(respostasQuestionario, respostasQuestionarioInicial)
    ) {
      setDisableSalvar(true);
    } else {
      setDisableSalvar(false);
    }
  }, [questionario, respostasQuestionario, isDirty]);

  const handleDownloadChart = useCallback(
    async element => {
      setIsLoadingImpressao(true);

      const html: any = document.getElementsByTagName('html')[0];
      const body: any = document.getElementsByTagName('body')[0];
      let htmlWidth: number = html.clientWidth;
      let bodyWidth: number = body.clientWidth;
      const newWidth = element.scrollWidth - element.clientWidth;
      if (newWidth > element.clientWidth) {
        htmlWidth += newWidth;
        bodyWidth += newWidth;
      }
      html.style.width = htmlWidth + 'px';
      body.style.width = bodyWidth + 'px';

      const canvas = await html2canvas(element);
      const base64File = canvas.toDataURL('image/png', 1.0);
      const blobFile = await fetch(base64File).then(res => res.blob());

      const formData = new FormData();
      formData.append('grafico', blobFile);
      formData.append('idAtendimento', idAtendimento);

      ReportAPI.relatorioGraficoDnpm(formData)
        .then(res => {
          const fileURL = URL.createObjectURL(
            new Blob([res?.data], {
              type: 'application/pdf',
            }),
          );

          window.open(fileURL);
        })
        .then(() => {
          html.style.width = null;
          body.style.width = null;
        })
        .finally(() => setIsLoadingImpressao(false));
    },
    [idAtendimento],
  );

  return (
    <>
      <Dialog
        id="DNPM"
        visible={isOpen}
        onHide={disabledSalvar ? close : sairDNPMDialog.open}
        maximizedTabletSize
        maximizable
        header={() => (
          <div className="p-w-100 p-d-flex p-jc-between">
            <SimpleText medium fontSize="sm">
              DNPM - Desenvolvimento Neuropsicomotor
            </SimpleText>
            <Button
              btnType="tonal"
              label="Imprimir gráficos"
              className="p-mr-4"
              onClick={() => {
                setIsLoadingImpressao(true);
                handleDownloadChart(canvasRef.current);
              }}
              disabled={isLoadingImpressao}
            />
          </div>
        )}
      >
        <FormProvider {...form}>
          <form id="dnpm-form">
            <DnpmHeader
              questionario={questionario}
              setDisableSalvar={setDisableSalvar}
              setRespostasQuestionario={setRespostasQuestionario}
              setRespostasQuestionarioInicial={setRespostasQuestionarioInicial}
            />

            <div
              className={cn(
                'row content-row-outline',
                isLoadingImpressao && 'impressao',
              )}
              id="graphic-element"
              ref={canvasRef}
            >
              <Table
                setRespostasQuestionario={setRespostasQuestionario}
                questionario={questionario}
              />
            </div>
            <div className="p-col-12 p-d-flex p-flex-row p-gap-1 p-ai-center p-mt-1">
              <Button
                btnType="ghost"
                label="Cancelar"
                type="button"
                onClick={() => {
                  disabledSalvar ? close() : sairDNPMDialog.open();
                }}
                loading={isSubmitting}
                stretch
              />
              <Button
                type="submit"
                label="Salvar"
                onClick={handleSubmit(onSubmit)}
                loading={isSubmitting}
                disabled={
                  !atendimentoStatus.includes('ATENDENDO') ||
                  isSubmitting ||
                  disabledSalvar
                }
                stretch
              />
            </div>
          </form>
        </FormProvider>
      </Dialog>
      {sairDNPMDialog.isOpen && (
        <SairDNPMDialog
          {...sairDNPMDialog}
          closeSairSemSalvar={closeSairSemSalvar}
          close={close}
        />
      )}
    </>
  );
}

interface SairDNPMDialogProps extends DisclosureType {
  close: () => void;
  closeSairSemSalvar: () => void;
}

const SairDNPMDialog = ({
  close,
  closeSairSemSalvar,
  ...sairDNPMDialog
}: SairDNPMDialogProps) => {
  return (
    <Dialog
      visible={sairDNPMDialog.isOpen}
      onHide={closeSairSemSalvar}
      closable={false}
      header="Sair do DNPM"
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <div>
          <SimpleText>
            As alterações não foram salvas. Deseja sair sem salvar?
          </SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            id="cancelar"
            label="Cancelar"
            btnType="ghost"
            onClick={closeSairSemSalvar}
            stretch
          />
          <Button
            id="sairSemSalvar"
            label="Sair sem salvar"
            btnType="danger"
            onClick={() => close()}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ModalDnpm;
