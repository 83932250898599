import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldPlanoStatusProps = DropdownProps & {
  name?: string;
  label: string;
};

const options = [
  {
    label: 'Cancelamento de guia',
    value: 'CANCELAMENTO_GUIA',
  },
  {
    label: 'Envio de guias de cobrança',
    value: 'ENVIO_GUIAS_COBRANCA',
  },
  {
    label: 'Solicitação de elegibilidade',
    value: 'ELEGIBILIDADE',
  },
  {
    label: 'Solicitação de procedimentos de internação',
    value: 'INTERNACAO',
  },
  {
    label: 'Solicitação de procedimentos de SP/SADT',
    value: 'SADT',
  },
  {
    label: 'Solicitação de status de autorização',
    value: 'STATUS_AUTORIZACAO',
  },
  {
    label: 'Solicitação de status de protocolo',
    value: 'STATUS_PROTOCOLO',
  },
  {
    label: 'Solicitar demonstrativo de análise ou pagamento',
    value: 'DEMONSTRATIVO_ANALISE_PAGAMENTO',
  },
];

export const FieldServicoTiss = ({
  name = 'servicoTiss',
  label,
  ...rest
}: FieldPlanoStatusProps) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      placeholder="Selecione"
      hideTextErrorSpace
      showClear
      {...rest}
    />
  );
};
