import { useState } from 'react';

import { FaturamentoAPI } from 'src/APIs/FinanceiroAPI/FaturamentoAPI/FaturamentoAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  handleDownloadCSV,
  handleDownloadPDF,
  handleOpenPDF,
} from 'src/utils/files';

import { Checkbox } from 'src/components/_UI/Checkbox';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import ModalAlterarStatus from '../ModalAlterarStatus';
import { useSelectedFaturamentos } from '../useSelectedFaturamentos';

import Filter from './Filter';
import ListHeader from './ListHeader';
import ListRow from './ListRow';

const List = () => {
  const [habilitarBotoesRelatorio, setHabilitarBotoesRelatorio] =
    useState(false);
  const [filtroPesquisa, setFiltroPesquisa] = useState<any>({});

  const { selectedFaturamentos, setSelectedFaturamentos, reload } =
    useSelectedFaturamentos();

  const { isMobile, isTablet } = useSize();

  const modalAlterarStatusDisclosure = useDisclosure({ opened: false });

  const desktopSize = !isMobile && !isTablet;

  const fetchApi = async (params: any) => {
    if (!params?.dataInicial || !params?.dataFinal) {
      return [];
    }
    console.log(params);
    const response = await FaturamentoAPI.getFaturamento(params);

    if (response?.list?.length > 0) {
      setHabilitarBotoesRelatorio(true);
    }
    return response;
  };

  const renderRow = (item: any): JSX.Element => {
    return <ListRow key={item.id} item={item} />;
  };

  const gerarRelatorio = async (type?: string) => {
    if (!filtroPesquisa) return;

    if (type === 'pdf') {
      const response = await FaturamentoAPI.getRelatorioPdf(filtroPesquisa);
      console.log(response);
      handleDownloadPDF(response, 'relatorio-faturamento');
    }

    if (type === 'csv') {
      const response = await FaturamentoAPI.getRelatorioCsv(filtroPesquisa);
      return handleDownloadCSV(response, 'relatorio-faturamento');
    }

    const response = await FaturamentoAPI.getRelatorioPdf(filtroPesquisa);
    handleOpenPDF(response, 'relatorio-faturamento');
  };

  return (
    <div className="p-p-3">
      <SearchList.Root
        fetchApi={fetchApi}
        consultorioRequired
        reloadWhenConsultorioChange
      >
        <Filter setFiltroPesquisa={setFiltroPesquisa} />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-jc-between p-ai-center p-p-2">
            <div>
              <Checkbox
                label={`${selectedFaturamentos?.length} marcado${
                  selectedFaturamentos?.length > 1 ? 's' : ''
                }`}
                checked={selectedFaturamentos?.length > 0}
                onChange={() => {
                  if (selectedFaturamentos?.length > 0) {
                    setSelectedFaturamentos([]);
                  }
                }}
                value={selectedFaturamentos?.length > 0}
                disabled={selectedFaturamentos?.length === 0}
              />
            </div>
            <div>
              <Button
                type="button"
                label="Alterar status geral"
                disabled={selectedFaturamentos?.length === 0}
                onClick={() => {
                  modalAlterarStatusDisclosure.open();
                }}
              />
            </div>
          </div>
          {desktopSize && <ListHeader />}
          <SearchList.InfiniteScroll reload={reload} renderRow={renderRow} />
        </SearchList.BorderContainer>
      </SearchList.Root>
      <div className="p-mt-3">
        <div>
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            Para concluir, gere o relatório optando pelo formato.
          </SimpleText>
        </div>
        <div className="p-d-flex p-mt-1">
          <Button
            className="p-mr-2"
            type="button"
            label="Gerar relatório"
            btnType={BtnTypes.TONAL}
            onClick={() => {
              gerarRelatorio();
            }}
            disabled={!habilitarBotoesRelatorio}
          />
          <Button
            className="p-mr-2"
            type="button"
            label="Gerar CSV"
            btnType={BtnTypes.TONAL}
            onClick={() => {
              gerarRelatorio('csv');
            }}
            disabled={!habilitarBotoesRelatorio}
          />
          <Button
            className="p-mr-2"
            type="button"
            label="Baixar PDF"
            btnType={BtnTypes.TONAL}
            onClick={() => {
              gerarRelatorio('pdf');
            }}
            disabled={!habilitarBotoesRelatorio}
          />
        </div>
      </div>
      {modalAlterarStatusDisclosure.isOpen && (
        <ModalAlterarStatus {...modalAlterarStatusDisclosure} />
      )}
    </div>
  );
};

export default List;
