import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';

import { Button } from 'src/components/_UI';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import { portalPacienteValidationSchema } from './validationSchema';

const PortalPaciente = () => {
  const navigate = useNavigate();

  const { idConvenio } = useParams();

  const { tissInfoData, idConvenioTissEdit } = useManterConvenio();

  const useFormMethods = useForm({
    resolver: yupResolver(portalPacienteValidationSchema),
    defaultValues: {
      ...tissInfoData,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormMethods;

  const onSubmit = (v: any) => {
    const api = idConvenioTissEdit
      ? ConvenioAPI.updatePortalPacienteTiss
      : ConvenioAPI.sendPortalPacienteTiss;

    return api({
      ...v,
      idConvenio: idConvenioTissEdit ? Number(idConvenio) : undefined,
    }).catch(() => {});
  };

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-grid p-col-12 p-md-10 p-lg-8 p-xl-6"
      >
        <div className="p-col-12">
          <SwitchControlled
            name="utilizaElegibilidadePortalPaciente"
            title="Usa elegibilidade"
            control={control}
          />
        </div>

        <div className="p-col-12">
          <SwitchControlled
            name="bloquearAgendamentoNegativaElegibilidade"
            title="Bloquear agendamento na negativa de elegibilidade"
            control={control}
          />
        </div>

        <TextareaInputControlled
          className="p-col-12"
          name="mensagemRetornoNegativaElegibilidadePortalPaciente"
          label="Mensagem de retorno na negativa da elegibilidade"
          control={control}
          rows={4}
          maxLength={250}
          autoResize
        />

        <div className="p-col-12 p-d-flex p-gap-1 p-ai-center">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={() => navigate('/cadastros/convenio', { replace: true })}
            disabled={isSubmitting}
            stretch
          />
          <Button type="submit" label="Salvar" loading={isSubmitting} stretch />
        </div>
      </form>
    </FormProvider>
  );
};

export default PortalPaciente;
