import { useEffect, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import './ModalAddTipoPlano.scss';

interface ModalAddTipoPlanoProps extends DisclosureType {
  appendtipoPlanoFields: (data: any) => void;
  tiposPlanoFields?: any;
}
const ModalAddTipoPlano = ({
  isOpen,
  close,
  appendtipoPlanoFields,
  tiposPlanoFields,
}: ModalAddTipoPlanoProps) => {
  const {
    control,
    formState: { errors },
    watch,
    unregister,
    setError,
    clearErrors,
  } = useFormContext();

  const watchDescricao = watch('descricao');
  const watchAutomatizarCarteiraAps = watch('automatizarCarteiraAPS');

  const handleClose = () => {
    unregister(['descricao', 'automatizarCarteiraAPS']);
    close();
  };

  const checkDescricaoIsDuplicate = useCallback(() => {
    const trimmedDescricao = watchDescricao.trim();
    return (
      tiposPlanoFields?.some?.(
        (tipoPlano: any) =>
          tipoPlano.descricao.trim().toLowerCase() ===
          trimmedDescricao.toLowerCase(),
      ) || false
    );
  }, [tiposPlanoFields, watchDescricao]);

  const handleAddTipoPlano = () => {
    const isDuplicate = checkDescricaoIsDuplicate();

    if (isDuplicate) {
      const error = {
        type: 'custom',
        message: 'Já existe um Tipo de Plano cadastrado com esta descrição.',
      };

      return setError('descricao', error);
    }

    appendtipoPlanoFields({
      descricao: watchDescricao.trim(),
      automatizarCarteiraAPS: watchAutomatizarCarteiraAps || false,
    });

    handleClose();
  };

  useEffect(() => {
    if (errors?.descricao?.message) {
      const isDuplicate = checkDescricaoIsDuplicate();
      if (!isDuplicate) clearErrors('descricao');
    }
  }, [errors?.descricao?.message, clearErrors, checkDescricaoIsDuplicate]);

  return (
    <>
      <Dialog
        id="modal-add-tipo-plano"
        header="Adicionar tipo plano"
        visible={isOpen}
        onHide={handleClose}
        className="p-grid"
        maximizedMobileSize
      >
        <>
          <FormInput
            className="p-col-12 p-px-0 p-mb-2"
            label="Descrição"
            name="descricao"
            placeholder="Descrição"
            maxLength={255}
            hideTextErrorSpace
          />

          <SimpleText className="p-col-12 p-px-0" medium>
            Automatizar Carteira APS
          </SimpleText>

          <SwitchControlled
            control={control}
            className="p-col-12 p-px-0"
            name="automatizarCarteiraAPS"
            showStatus
          />
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1 p-px-0">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={() => handleClose()}
              stretch
            />
            <Button
              label="Salvar"
              onClick={handleAddTipoPlano}
              disabled={!watchDescricao}
              stretch
            />
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ModalAddTipoPlano;
