import HistoricoReceitaOculos from './Oculos/HistoricoReceitaOculos';
import ReceitaHistorico from './ReceitaHistorico/ReceitaHistorico';

export default function HistoricoReceitas() {
  return (
    <div>
      <ReceitaHistorico />
    </div>
  );
}
