import { useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import { Button } from 'src/components/_UI/Button';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Switch from 'src/components/Switch/Switch';

interface ConteudoHistoricoAtendimentoProps {
  fetchAPI: any;
  renderRow: any;
  historicoCompletoDisclosure: any;
  justificativaDisclosure: any;
  atendimentoStatus: string;
  expandSidebar?: boolean;
  setExpandSidebar?: any;
  onInativarAtendimento?: any;
  updateList: number;
}

export const ConteudoHistoricoAtendimento = ({
  fetchAPI,
  renderRow,
  atendimentoStatus,
  expandSidebar,
  setExpandSidebar,
  onInativarAtendimento,
  updateList,
}: ConteudoHistoricoAtendimentoProps) => {
  const {
    quantidadeRegistros,
    totalRegistros,
    setQuantidadeRegistros,
    ocultarRegistros,
    setOcultarRegistros,
    setAtualAtendimento,
    setTodosAtendimentos,
    optionsHistorico,
    filtroHistorico,
    setFiltroHistorico,
    todosAtendimentos,
    selectedAtendimento,
    reload,
  } = useHistoricoAtendimento();

  const [filtroValue, setFiltroValue] = useState(2);
  const [options, setOptions] = useState();

  const overlay = useRef<OverlayPanel>(null);

  useEffect(() => {
    const options: any = [];

    if (atendimentoStatus === 'ATENDIDO') {
      options.push(
        { label: 'Atual', value: 1 },
        { label: 'Todos', value: 2 },
        { label: 'Meus atendimentos', value: 3 },
      );
    } else {
      options.push(
        { label: 'Todos', value: 2 },
        { label: 'Meus atendimentos', value: 3 },
      );
    }

    setOptions(options);
  }, [atendimentoStatus]);

  return (
    <div className="p-col-12">
      <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-center p-jc-sm-between p-ai-center p-col-12">
        <div className="p-d-flex p-ai-center expand-button">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.SM}
            medium
          >
            Histórico
          </SimpleText>
          {atendimentoStatus === 'ATENDENDO' ||
          atendimentoStatus === 'ATENDENDO_TRIAGEM' ? (
            <Button
              className="p-ml-2"
              icon={`pi pi-angle-double-${
                expandSidebar ? 'down' : 'up'
              } expand`}
              btnType="ghost"
              onClick={() => setExpandSidebar(!expandSidebar)}
            />
          ) : null}
        </div>
        <div className="p-d-flex p-ai-center">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Exibindo {quantidadeRegistros} registros de {totalRegistros}
          </SimpleText>
        </div>
      </div>
      <div className="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-col-12">
        <div>
          <SelectButton
            options={options}
            value={filtroValue}
            onChange={e => {
              const value = e.value;
              setFiltroValue(value);
              setQuantidadeRegistros(0);

              switch (value) {
                case 1:
                  setTodosAtendimentos(false);
                  setAtualAtendimento(true);
                  break;

                case 2:
                  setTodosAtendimentos(true);
                  setAtualAtendimento(false);
                  break;

                case 3:
                  setTodosAtendimentos(false);
                  setAtualAtendimento(false);
                  break;

                default:
                  break;
              }
            }}
            unselectable={false}
          />
        </div>
        <div className="p-col-12 p-md-3">
          <Dropdown
            options={optionsHistorico}
            value={filtroHistorico}
            onChange={e => setFiltroHistorico(e.value)}
            placeholder="Historico de"
            // disabled
          />
        </div>
      </div>
      <InfiniteScroll
        fetchAPI={fetchAPI}
        renderRow={renderRow}
        updateList={updateList}
      />
    </div>
  );
};
