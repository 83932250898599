import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { EquipeStatus } from './EquipeStatus';
import { MobileRow } from './MobileRow';

interface RowProps {
  equipe: EquipeDTO;
  onEdit: (equipe: EquipeDTO) => void;
  onDelete: (id: number) => void;
}

export const Row = (props: RowProps) => {
  const { equipe } = props;
  const { isMobile, isTablet } = useSize();

  const renderMobile = isMobile || isTablet;

  const dataInclusao = equipe?.dataInclusao
    ? dayjs(new Date(equipe?.dataInclusao)).format('DD/MM/YYYY')
    : '';

  const equipeStatus = (
    <EquipeStatus idEquipe={equipe.id} isAtivo={equipe.ativo} />
  );

  const profissionalResponsavel = equipe.equipeProfissionaisSaude.find(
    (profissional: any) => profissional.responsavel === true,
  );

  const commonProps = {
    profissionalResponsavel,
    equipeStatus,
    dataInclusao,
    ...props,
  };

  return renderMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
