import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useOptionalData } from 'src/core/hooks/ManterUsuario/useOptionalData';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';

import './Endereco.scss';

const formFields = {
  cep: '',
  logradouro: '',
  complemento: '',
  bairro: '',
  idCidade: '',
  idEstado: '',
  tipo: '',
  numero: '',
};

interface EnderecoProps {
  handleActiveIndex(): void;
}

const Endereco = ({ handleActiveIndex }: EnderecoProps) => {
  const { isProfessionalHealth } = useSelectProfileCreateUser();
  const { idUser } = useCreateUser();
  const { addressData, saveAddress } = useOptionalData();
  const { user } = useAppSelector((state: RootState) => state);

  const [paises, setPaises] = useState<any>([]);
  const [estados, setEstados] = useState<any>([]);
  const [cidades, setCidades] = useState<any>([]);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingCepSearch, setLoadingCepSearch] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    idPais: Yup.string().required('Informe o País'),
    logradouro: Yup.string().required('Informe o logradouro'),
    bairro: Yup.string().required('Informe o bairro'),
    tipo: Yup.string().required('Informe o tipo do logradouro'),
    numero: Yup.string().required('Informe o Número'),
    ...(countryId === 0 || countryId === null
      ? {
          cep: Yup.string().required('Informe o CEP'),
          idCidade: Yup.string().required('Informe a cidade'),
          idEstado: Yup.string().required('Informe o estado'),
        }
      : {
          descCidade: Yup.string().required('Informe a cidade'),
        }),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    watch,
  } = form;

  const getSaveAction = useCallback(() => {
    if (addressData) {
      return isProfessionalHealth
        ? ProfissionalAPI.updateProfissional
        : UsuarioAPI.updateUsuario;
    }

    return isProfessionalHealth
      ? ProfissionalAPI.saveProfissionalOpcionais
      : UsuarioAPI.createUsuarioOpcionais;
  }, [isProfessionalHealth, addressData]);

  const handleSave = async (data: any) => {
    const { idPais, idEstado, idCidade } = data;

    const payload: any = {
      ...data,
      ...(idPais && { idPais: Number(idPais) }),
      ...(idEstado && { idEstado: Number(idEstado) }),
      ...(idCidade && { idCidade: Number(idCidade) }),
      indicadorEstrangeiro: data?.idPais !== '0' ? true : false,
      idEmpresa: user?.idEmpresa,
    };

    const saveAction = getSaveAction();

    const res = await saveAction('endereco', idUser, payload);

    if (res?.status === 200) {
      delete payload.idEmpresa;
      saveAddress(payload);

      handleActiveIndex();
    }
  };

  const handleCEP = async (cep: any) => {
    try {
      const cepFormatted = cep.replace('-', '');

      if (!cepFormatted || cepFormatted.includes('_')) return;

      setLoadingCepSearch(true);

      const res = await UtilsAPI.GetAddressByCep(cepFormatted);

      if ('status' in res) throw Error('Load CEP data error');

      handleEstadoSelect(res?.idEstado);

      setValue('idEstado', res?.idEstado);
      setValue('idCidade', res?.idCidade);
      setValue('logradouro', res?.logradouro);
      setValue('bairro', res?.bairro);
    } catch (error) {
      const data = { message: 'CEP não encontrado.', type: 'warning' };
      toast(<Toast />, { data });
    } finally {
      setLoadingCepSearch(false);
    }
  };

  const handlePaisSelect = useCallback(async paisId => {
    setCountryId(paisId);

    if (paisId !== 0) return;

    const estados = await UtilsAPI.GetEstados();

    setEstados(estados);
  }, []);

  const handleEstadoSelect = useCallback(async estadoId => {
    return new Promise(async resolve => {
      const cidades: any = await UtilsAPI.GetCidades(estadoId);

      if (Array.isArray(cidades)) setCidades(cidades);

      resolve(true);
    });
  }, []);

  const getIdPaisAndIdEstado = useCallback(async (): Promise<{
    idPais: number;
    idEstado: number | null;
  }> => {
    const { idPais = null, idEstado = null } = addressData || {};

    if (idPais) return { idPais, idEstado };

    return new Promise(async resolve => {
      const { cidade, idPaisEstrangeiro } = await UsuarioAPI.getUser(
        idUser,
        'endereco',
      );

      if (cidade === null) {
        resolve({ idPais: idPaisEstrangeiro, idEstado: null });
      }

      const data = {
        idEstado: cidade?.estado?.id,
        idPais: cidade?.estado?.idPais,
      };

      resolve(data);
    });
  }, [addressData, idUser]);

  const load = useCallback(async () => {
    setLoadingData(true);

    const paises = await UtilsAPI.GetPaises();

    setPaises(paises);

    if (addressData) {
      const { idPais, idEstado } = await getIdPaisAndIdEstado();

      setCountryId(idPais);

      handlePaisSelect(idPais);

      if (idEstado) {
        await handleEstadoSelect(idEstado);
        setValue('idEstado', idEstado);
        setValue('idCidade', addressData?.idCidade);
      }

      setValue('bairro', addressData?.bairro);
      setValue('logradouro', addressData?.logradouro);
      setValue('tipo', addressData?.tipo);
      setValue('numero', addressData?.numero);
      setValue('complemento', addressData?.complemento);
      setValue('cep', addressData?.cep);
      setValue('idPais', idPais);
      setValue('descCidade', addressData?.descCidade);
    }

    setLoadingData(false);
  }, [
    addressData,
    getIdPaisAndIdEstado,
    handleEstadoSelect,
    handlePaisSelect,
    setValue,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const paisesOptions = useMemo(
    () =>
      paises.map((pais: any) => ({
        label: pais?.descricao,
        value: pais?.id,
      })),
    [paises],
  );

  const estadosOptions = useMemo(
    () =>
      estados.map((estado: any) => ({
        label: estado?.nome,
        value: estado?.id,
      })),
    [estados],
  );

  const cidadesOptions = useMemo(
    () =>
      cidades.map((cidade: any) => ({
        label: cidade?.nome,
        value: cidade?.id,
      })),
    [cidades],
  );

  const BrazilLocationForm = useCallback(
    () => (
      <>
        <DropdownControlled
          control={control}
          name="idEstado"
          label="Estado"
          className="p-col-12 p-md-6"
          options={estadosOptions}
          customOnChange={v => handleEstadoSelect(v)}
        />
        <DropdownControlled
          control={control}
          name="idCidade"
          label="Cidade"
          className="p-col-12 p-md-6"
          options={cidadesOptions}
          disabled={!cidadesOptions.length || loadingCepSearch}
        />
      </>
    ),
    [
      cidadesOptions,
      control,
      estadosOptions,
      loadingCepSearch,
      handleEstadoSelect,
    ],
  );

  const ForeignLocationForm = useCallback(
    () => (
      <FormInput
        name="descCidade"
        label="Cidade"
        placeholder="Digite a cidade"
        className="p-col-12"
        maxLength={50}
      />
    ),
    [],
  );

  const LocationForm = useCallback(() => {
    if (countryId === null || countryId === 0) {
      return <BrazilLocationForm />;
    }

    return <ForeignLocationForm />;
  }, [countryId, BrazilLocationForm, ForeignLocationForm]);

  useEffect(() => {
    setValue('cep', '');
    setValue('idCidade', '');
    setValue('descCidade', '');
    setValue('idEstado', '');
    setValue('logradouro', '');
    setValue('tipo', '');
    setValue('bairro', '');
    setValue('numero', '');
    setValue('complemento', '');
  }, [countryId, setValue]);

  if (loadingData)
    return (
      <div className="formulario">
        <div className="loadingdados">
          <GridListLoading />
        </div>
      </div>
    );

  const watchCep = watch('cep');

  return (
    <FormProvider {...form}>
      <form
        className="p-grid"
        onSubmit={handleSubmit(handleSave)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        <DropdownControlled
          control={control}
          name="idPais"
          label="País"
          className="p-col-12"
          options={paisesOptions}
          customOnChange={v => handlePaisSelect(v)}
        />

        <div className="p-col-12">
          <Separator />
        </div>

        <SimpleText className="p-col-12">
          Digite o CEP para preencher automaticamente os campo
        </SimpleText>

        <MaskedInputControlled
          control={control}
          label="CEP"
          name="cep"
          mask={MASK.CEP}
          placeholder="Digite o CEP"
          className="p-col-9"
          disabled={countryId !== 0}
        />

        <Button
          type="button"
          btnType="tonal"
          label={loadingCepSearch ? 'Pesquisando' : 'Pesquisar'}
          className="search-address-btn"
          icon="pi pi-search"
          disabled={
            watchCep?.charAt(watchCep?.length - 1) === '_' || watchCep === ''
              ? true
              : false
          }
          onClick={() => handleCEP(watchCep)}
          loading={loadingCepSearch}
        />

        <LocationForm />

        <FormInput
          name="logradouro"
          label="Logradouro"
          placeholder="Digite o logradouro"
          className="p-col-12"
          disabled={loadingCepSearch}
          maxLength={255}
        />

        <DropdownControlled
          control={control}
          name="tipo"
          label="Tipo do logradouro"
          placeholder="Selecione o tipo"
          className="p-col-12 p-md-6"
          disabled={loadingCepSearch}
          options={[
            {
              label: 'Residencial',
              value: 'RESIDENCIAL',
            },
            {
              label: 'Comercial',
              value: 'COMERCIAL',
            },
          ]}
        />

        <FormInput
          name="bairro"
          label="Bairro"
          placeholder="Digite o bairro"
          className="p-col-12 p-md-6"
          disabled={loadingCepSearch}
          maxLength={50}
        />
        <FormInput
          name="numero"
          label="Número"
          placeholder="Digite o número"
          className="p-col-12 p-md-6"
          disabled={loadingCepSearch}
          maxLength={10}
        />
        <FormInput
          name="complemento"
          label="Complemento"
          placeholder="Digite o complemento"
          className="p-col-12 p-md-6"
          disabled={loadingCepSearch}
          maxLength={255}
        />

        <div className="p-col-12">
          <Button
            btnType="tonal"
            label={'Salvar e continuar'}
            type="submit"
            onClick={handleSubmit(handleSave)}
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default Endereco;
