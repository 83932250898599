import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { FieldHospital } from 'src/components/Fields/FieldHospital/FieldHospital';
import FormInput from 'src/components/FormInput/FormInput';

export const InternadoFields = () => {
  const [tipoInternacaoOptions, setTipoInternacaoOptions] = useState<any[]>([]);
  const [regimeInternacaoOptions, setRegimeInternacaoOptions] = useState<any[]>(
    [],
  );
  const { idConvenio } = useSolicitacaoExameCirurgia();

  const {
    control,
    formState: { errors },
    unregister,
    watch,
  } = useFormContext();

  const situacaoLocal = watch('situacaoLocal');

  useEffect(() => {
    if (situacaoLocal !== 'I') {
      unregister([
        'dataSugerInternacao',
        'quantidadeDiarias',
        'previsaoOpme',
        'idTipoInternacao',
        'hospital',
        'idRegimeInternacao',
      ]);
    }
  }, [situacaoLocal, unregister]);

  const fetchTipoInternacao = async () => {
    try {
      const response = await SolicitacoesProcedimentosAPI.getTipoInternacao();

      const tipoInternacaoOptionsFormatted = response.map(
        (tipoInternacao: any) => ({
          label: tipoInternacao.descricao,
          value: tipoInternacao.id,
        }),
      );

      setTipoInternacaoOptions(tipoInternacaoOptionsFormatted);
    } catch (e) {
      //error
    }
  };

  const fetchRegimeInternacao = async () => {
    try {
      const response = await SolicitacoesProcedimentosAPI.getRegimeInternacao();

      const regimeInternacaoOptionsFormatted = response.map(
        (regimeInternacao: any) => ({
          label: regimeInternacao.descricao,
          value: regimeInternacao.id,
        }),
      );

      setRegimeInternacaoOptions(regimeInternacaoOptionsFormatted);
    } catch (error) {
      //error
    }
  };

  useEffect(() => {
    fetchTipoInternacao();
    fetchRegimeInternacao();
  }, []);

  if (situacaoLocal !== 'I') return null;

  return (
    <>
      <div className="p-grid p-col-12 p-p-0 p-align-center">
        <CalendarInputControlled
          className="p-col-12 p-sm-4 p-md-6 p-lg-4"
          control={control}
          name="dataSugerInternacao"
          label="Data sugerida internação"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          minDate={new Date()}
          errorMsg={errors.dataSugerInternacao?.message}
        />

        <FormInput
          className="p-col-12 p-sm-4 p-md-6 p-lg-4"
          classNameInput="p-w-100"
          name="quantidadeDiarias"
          label="Quantidade de diárias"
          type="number"
          hideTextErrorSpace
        />

        <div className="p-col-12 p-sm-4 p-md-12 p-lg-4 p-mt-3">
          <CheckboxControlled
            control={control}
            name="previsaoOpme"
            label="Previsão de uso OPME"
          />
        </div>
      </div>

      <DropdownControlled
        className="p-col-12 p-md-4"
        control={control}
        name="idTipoInternacao"
        label="Tipo internação"
        options={tipoInternacaoOptions}
        filter={false}
        errorMsg={errors.idTipoInternacao?.message}
      />

      <FieldHospital
        className="p-col-12 p-md-4"
        label="Hospital"
        params={{ idConvenio }}
      />

      <DropdownControlled
        className="p-col-12 p-md-4"
        control={control}
        name="idRegimeInternacao"
        label="Regime internamento"
        options={regimeInternacaoOptions}
        filter={false}
        errorMsg={errors.idRegimeInternacao?.message}
      />
    </>
  );
};
