import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { TIPO_USUARIO } from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { Button, Checkbox } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CheckboxList from 'src/components/Basics/Checkbox/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Tab from 'src/components/Tab/Tab';

import { Destiantarios } from '../../Comunicado';

import './FormRecipient.scss';

import {
  converterValoresParaOForm,
  destinatariosText,
  quantidadeDeUsuarios,
} from './helpers';

import { yupResolver } from '@hookform/resolvers/yup';

import { formValidation } from './validacao';

interface FormRecipientProps {
  onSubmit(v: any): void;
  values: ComunicadoDTO;
  consultorios: ConsultorioDTO[];
  especialidades: EspecialidadeDTO[];
  destinatarios: any;
  setDestinatarios: React.Dispatch<React.SetStateAction<Destiantarios>>;
  firstRender: boolean;
  setFirstRender: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormRecipient = ({
  onSubmit,
  values,
  especialidades,
  consultorios,
  destinatarios,
  setDestinatarios,
  firstRender,
  setFirstRender,
}: FormRecipientProps) => {
  const [search, setSearch] = useState<string>('');
  const [quantidadeDestinatarios, setQuantidadeDestinatarios] = useState(0);
  const [selectedAll, setSelectedAll] = useState<any>({
    comunicadoDestinatarios: false,
    comunicadoEspecialidades: false,
    comunicadoConsultorios: false,
  });

  const useFormMethods = useForm({
    resolver: yupResolver(formValidation),
  });

  useEffect(() => {
    if (destinatarios.comunicadoDestinatarios) {
      quantidadeDeUsuarios(destinatarios).then(res =>
        setQuantidadeDestinatarios(res?.quantidadeUsuario || 0),
      );
    }
  }, [destinatarios]);

  const recipientOptions: Record<string, any> = useMemo(() => {
    return {
      comunicadoDestinatarios: [
        { label: 'Médicos', value: TIPO_USUARIO.PROFISSIONAL_SAUDE },
        { label: 'Secretaria', value: 'SECRETARIO' },
      ],
      comunicadoEspecialidades: especialidades.map(especialidade => ({
        label: especialidade.nome,
        value: especialidade.id,
      })),
      comunicadoConsultorios: consultorios.map(consultorio => ({
        label: consultorio.nome,
        value: consultorio.id,
      })),
    };
  }, [especialidades, consultorios]);

  const initialValues: any = useMemo(() => {
    if (firstRender) {
      setFirstRender(false);
      const valores = converterValoresParaOForm({
        ...values,
      });

      setDestinatarios(valores);
      return { ...values, ...valores };
    }
    return { ...values, ...destinatarios };
  }, [firstRender, values, destinatarios, setFirstRender, setDestinatarios]);

  const renderForm = useCallback(
    (recipientTab: string) => {
      const options = () => {
        const filtered = recipientOptions[recipientTab].filter((item: any) =>
          item.label.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()),
        );

        return filtered;
      };

      const handleSelectAll = (e: any) => {
        setSelectedAll((prev: any) => ({ ...prev, [recipientTab]: e.checked }));

        if (e.checked) {
          const selectedAll = recipientOptions[recipientTab].map(
            (item: any) => item.value,
          );
          return setDestinatarios((prev: any) => ({
            ...prev,
            [recipientTab]: selectedAll,
          }));
        }

        return setDestinatarios((prev: any) => ({
          ...prev,
          [recipientTab]: [],
        }));
      };

      return (
        <FormProvider {...useFormMethods}>
          <div className="form-recipient">
            <form className="p-grid">
              <SimpleText
                className="p-ml-2 p-mt-2"
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                Filtre os resultados utilizando o campo de pesquisa abaixo.
              </SimpleText>
              <div className="p-col-12 p-px-0 p-mt-2 p-mb-3">
                <TextInputSearch
                  placeholder="Pesquisar"
                  onChange={e => setSearch(e)}
                />
              </div>

              <div className="p-my-2 p-ml-2">
                <Checkbox
                  label="Selecionar Todos"
                  checked={selectedAll[recipientTab]}
                  onChange={handleSelectAll}
                />
              </div>

              <CheckboxList
                name={recipientTab}
                value={destinatarios[recipientTab]}
                options={options()}
                onChange={(e: any) =>
                  setDestinatarios((prev: any) => ({
                    ...prev,
                    [recipientTab]: [...e],
                  }))
                }
              />
            </form>
          </div>
          <AlertBox
            visible={true}
            text={destinatariosText(quantidadeDestinatarios, destinatarios)}
          />
        </FormProvider>
      );
    },
    [
      destinatarios,
      quantidadeDestinatarios,
      recipientOptions,
      search,
      selectedAll,
      setDestinatarios,
      useFormMethods,
    ],
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Tipo de usuário',
        value: 'comunicadoDestinatarios',
        content: renderForm('comunicadoDestinatarios'),
      },
      {
        label: 'Especialidades',
        value: 'comunicadoEspecialidades',
        content: renderForm('comunicadoEspecialidades'),
      },
      {
        label: 'Consultórios',
        value: 'comunicadoConsultorios',
        content: renderForm('comunicadoConsultorios'),
      },
    ],
    [renderForm],
  );

  return (
    <div className="p-w-100">
      <div className={'FormRecipient'}>
        <SimpleText>
          Selecione o destinatário por perfil de usuário, especialidade,
          consultório ou hospitais.
        </SimpleText>

        <Tab className={'p-mt-2 tab-recipient'} values={tabs} />
      </div>

      <Button
        className="p-mt-4 p-w-100"
        label="Pré-visualizar"
        onClick={() =>
          onSubmit({
            ...destinatarios,
            ...initialValues,
            quantidadeDestinatarios: destinatariosText(
              quantidadeDestinatarios,
              destinatarios,
            ),
          })
        }
      />
    </div>
  );
};

export default FormRecipient;
