import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/servico-tuss';

interface ServicoTussOPMEIdPayloadProps {
  idServicoTuss: number;
  id: number;
}

const loadServicoTussFornecedor = (
  id: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/fornecedor`,
    hideToast: true,
    ...options,
  });
};

const cadastrarServicoTussFornecedor = (
  payload: Omit<ServicoTussOPMEIdPayloadProps, 'id'>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/${payload.idServicoTuss}/fornecedor`,
    data: payload,
    return: true,
    ...options,
  });
};

const alterarServicoTussEndpoint = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${payload.idServicoTuss}/fornecedor/${payload.id}`,
    data: payload,
    return: true,
    ...options,
  });
};

const deletarServicoTussEndpoint = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${payload.idServicoTuss}/fornecedor/${payload.id}`,
    data: payload,
    return: true,
    ...options,
  });
};

const inativarServicoTussEndpoint = (
  payload: ServicoTussOPMEIdPayloadProps,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${payload.idServicoTuss}/fornecedor/${payload.id}/inativar`,
    data: payload,
    return: true,
    ...options,
  });
};

const ativarServicoTussEndpoint = (
  payload: ServicoTussOPMEIdPayloadProps,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${payload.idServicoTuss}/fornecedor/${payload.id}/ativar`,
    data: payload,
    return: true,
    ...options,
  });
};

const ServicoTussOPMEAPI = {
  loadServicoTussFornecedor,
  cadastrarServicoTussFornecedor,
  alterarServicoTussEndpoint,
  deletarServicoTussEndpoint,
  inativarServicoTussEndpoint,
  ativarServicoTussEndpoint,
};

export default ServicoTussOPMEAPI;
