import { indexOf } from 'lodash';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  nome: Yup.string().required('O campo nome é obrigatório.'),
  cnpj: Yup.string().required('O campo CNPJ é obrigatório.'),
  hospitalConvenios: Yup.array().of(
    Yup.object()
      .shape({
        convenio: Yup.object()
          .nullable()
          .required('O campo convenio é obrigatório.'),
        codigoConvenio: Yup.string().required(
          'O campo código do convenio é obrigatório.',
        ),
      })
      .test(
        'is-unique',
        'O convenio não pode ser duplicado.',
        function (value) {
          const { createError, parent } = this;
          const index = indexOf(parent, value);

          parent.forEach((item: any, i: number) => {
            if (i !== index && item.convenio?.id === value?.convenio?.id) {
              throw createError({
                path: `hospitalConvenios.${index}.convenio`,
                message: 'O convenio não pode ser duplicado.',
              });
            }
          });
          return true;
        },
      ),
  ),
});
