import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { formatCPF, formatCarteirinhaMask } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { useAgenda } from '../AgendaContext';

interface EtiquetaProps {
  cartaoPlano: string;
}

const Etiqueta = ({ cartaoPlano }: EtiquetaProps) => {
  const { agendamento } = useAgenda();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const { paciente, convenio } = agendamento;

  const calculateAge = () => {
    if (!paciente.dataNascimento) return '';
    const birthDateDayjs = dayjs(paciente.dataNascimento);
    const today = dayjs();
    const age = today.diff(birthDateDayjs, 'year');
    return `${age} ${age === 1 ? 'ano' : 'anos'}`;
  };

  const formatTelefone = () => {
    if (!paciente.telefonePrincipal) return '';
    if (paciente.telefonePrincipal.length === 10) {
      return paciente.telefonePrincipal.replace(
        /(\d{2})(\d{4})(\d{4})/,
        '$1 $2-$3',
      );
    }

    return paciente.telefonePrincipal.replace(
      /(\d{2})(\d{1})(\d{4})(\d{4})/,
      '$1 $2 $3-$4',
    );
  };

  const getGenerateText = () => {
    const now = dayjs();

    const day = now.format('D');
    const month = now.format('MMM').toLowerCase();
    const year = now.format('YYYY');
    const hour = now.format('H');

    const result = `Gerado por eMed às ${hour}h de ${day} de ${month}. de ${year}`;

    return result;
  };

  return (
    <div
      id="etiqueta"
      className="p-d-flex p-flex-column p-p-1"
      style={{
        maxWidth: '600px',
      }}
    >
      <h2>
        <SimpleText fontColor={FONT_COLOR.COLOR_40} medium className="p-ml-1">
          IDENTIFICAÇÃO DO PACIENTE
        </SimpleText>
      </h2>
      <div
        className="p-grid p-p-1 p-m-1"
        style={{ borderLeft: '2px solid #54a275' }}
      >
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className="p-mb-1"
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
          >
            Nome completo
          </SimpleText>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.LG}
            medium
            ellipsisOverflow
          >
            {paciente.nome || ''}
          </SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-pt-0 p-pb-1">
          <SimpleText
            className="p-mb-1"
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
          >
            Nome da mãe:{' '}
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XS}
              medium
              ellipsisOverflow
            >
              {paciente.nomeMae || ''}
            </SimpleText>
          </SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-gap-3 p-py-0">
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className="p-mb-1"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XS}
            >
              Idade:{' '}
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {calculateAge()}
              </SimpleText>
            </SimpleText>
          </div>
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className="p-mb-1"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XS}
            >
              Data de nascimento:{' '}
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {paciente.dataNascimento
                  ? dayjs(paciente.dataNascimento).format(
                      'DD [de] MMM [de] YYYY',
                    )
                  : ''}
              </SimpleText>
            </SimpleText>
          </div>
        </div>
        <div className="p-col-12 p-d-flex p-gap-3 p-py-0">
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className="p-mb-1"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XS}
            >
              Fone:{' '}
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {formatTelefone()}
              </SimpleText>
            </SimpleText>
          </div>
          {consultorios?.ativo?.exibirCpfEtiqueta && (
            <div className="p-d-flex p-flex-column">
              <SimpleText
                className="p-mb-1"
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XS}
              >
                CPF:{' '}
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                  medium
                >
                  {paciente.cpf ? formatCPF(paciente.cpf) : ''}
                </SimpleText>
              </SimpleText>
            </div>
          )}
        </div>
        <div className="p-col-12 p-d-flex p-gap-3 p-pt-1 p-pb-0">
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className="p-mb-1"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XS}
              ellipsisOverflow
            >
              Convênio:{' '}
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {convenio?.nomeFantasia || convenio?.razaoSocial}
              </SimpleText>
            </SimpleText>
          </div>
        </div>
        <div className="p-col-12 p-d-flex p-gap-3 p-pt-0">
          <div className="p-d-flex p-flex-column">
            <SimpleText
              className="p-mb-1"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XS}
            >
              N. da carterinha:{' '}
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {cartaoPlano && convenio?.mascaraCartao
                  ? formatCarteirinhaMask(cartaoPlano, convenio.mascaraCartao)
                  : cartaoPlano || ''}
              </SimpleText>
            </SimpleText>
          </div>
        </div>
      </div>
      <SimpleText
        className="p-ml-3 p-mt-2"
        fontColor={FONT_COLOR.COLOR_40}
        fontSize={FONT_SIZE.XXS}
      >
        {getGenerateText()}
      </SimpleText>
    </div>
  );
};

export default Etiqueta;
