import { useState } from 'react';

import { MovimentacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/Movimentacao/MovimentacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { formatCPF, capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import SearchPacientes from './SearchPacientes/SearchPacientes';

import './ModalAddPaciente.scss';

interface ModalAddPacienteProps extends DisclosureType {
  reload(): void;
}

const ModalAddPaciente = ({
  isOpen,
  close,
  reload,
  state,
}: ModalAddPacienteProps) => {
  const { consultorios } = useAppSelector((state: RootState) => state);
  const [pacienteSelected, setPacienteSelected] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAdicionarPacientes = async () => {
    setLoading(true);
    const idPacientes = pacienteSelected.map((paciente: any) => paciente.id);

    const payload = {
      ids: idPacientes,
      idConsultorio: consultorios?.ativo?.id,
      idCuidador: state.idCuidador,
    };

    const response = await MovimentacaoCarteiraAPI.putIncluirPaciente(payload);

    setLoading(false);
    if (response.status === 200) {
      reload();
      close();
    }
  };

  const renderPacientes = () => {
    if (!pacienteSelected.length)
      return (
        <Card className="p-text-center content-empyt" type={CARD_TYPES.GRAY}>
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            Nenhum paciente cadastrado. <br />
            Faça a busca utilizando o campo acima.
          </SimpleText>
        </Card>
      );

    return pacienteSelected.map((paciente: any, index: number) => (
      <div key={index} className="p-grid pacientes-selecionados">
        <div className="p-grid p-col-12 p-sm-8">
          <SimpleText medium className="p-col-12 p-px-0">
            {paciente?.nome}
          </SimpleText>

          <SimpleText
            className="p-col-12 p-pt-0 p-px-0"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            {formatCPF(paciente.cpf)} •{' '}
            {capitalizeFirstLetter(paciente.sexoDescricao)}{' '}
            {paciente.convenio || ''}
          </SimpleText>
        </div>

        <div className="p-grid p-col-12 p-sm-4 p-justify-end p-ai-center">
          <>
            <Button
              className="p-ml-3"
              btnType="ghost"
              icon="fa fa-trash"
              onClick={() => {
                const novosPacientesSelected = pacienteSelected.filter(
                  (item: any) => item.id !== paciente.id,
                );

                setPacienteSelected(novosPacientesSelected);
              }}
            />
          </>
        </div>
      </div>
    ));
  };

  return (
    <Dialog
      header="Adicionar Pacientes"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
      id="modal-add-paciente"
    >
      <>
        <SearchPacientes
          label={''}
          setPacienteSelected={setPacienteSelected}
          pacienteSelected={pacienteSelected}
        />

        <div className="selected-list p-p-2 p-my-2">
          <div className="p-mt-1 p-mb-3">
            <SimpleText medium>Lista de pacientes</SimpleText>
          </div>

          {renderPacientes()}
        </div>

        <div className="p-grid p-col-12 p-px-0">
          <Button
            className="p-col-12 p-md-6"
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
          />

          <Button
            className="p-col-12 p-md-6"
            label="Adicionar na lista"
            onClick={() => handleAdicionarPacientes()}
            disabled={!pacienteSelected.length}
            loading={loading}
          />
        </div>
      </>
    </Dialog>
  );
};

export default ModalAddPaciente;
