import { useDispatch } from 'react-redux';

import AlergiaAPI from 'src/APIs/ProntuarioAPI/AlergiaAPI/AlergiaAPI';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

interface ExcluirAlergiaProps {
  alergia: any;
  onHide: () => void;
  reloadList: () => void;
}

function ModalExcluirAlergia({
  alergia,
  onHide,
  reloadList,
}: ExcluirAlergiaProps) {
  const dispatch = useDispatch();

  const deleteAlergia = async () => {
    await AlergiaAPI.deleteAlergia(alergia.id, alergia.atendimento.id);

    onHide();
    reloadList();
    dispatch(setInvalidateQuery({ invalidateMemed: true }));
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onHide} stretch />
      <Button
        btnType="danger"
        label="Sim, excluir"
        onClick={deleteAlergia}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-visualizar-justificativa"
      type="modal"
      header="Excluir alergia"
      visible={!!alergia}
      onHide={onHide}
      footer={renderFooter}
    >
      <div className="p-grid">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          Deseja realmente excluir esse item?
        </SimpleText>
      </div>
    </Dialog>
  );
}

export default ModalExcluirAlergia;
