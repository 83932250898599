import React, { useCallback, useEffect, useState } from 'react';

import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { PanelContent } from './PanelContent';
import { PanelHeader } from './PanelHeader';

export default function Historicoopme() {
  const [quantidadeOpme, setQuantidadeOpme] = useState<number>(1);
  const [exibirLista, setExibirLista] = useState<boolean>(true);

  useEffect(() => {
    if (quantidadeOpme === 0) {
      setExibirLista(false);
    }
  }, [quantidadeOpme]);

  const { selectedAtendimento, reload, setItensFichaPaciente } =
    useHistoricoAtendimento();

  const fetchOpme = useCallback(
    async (params: any) => {
      const response = await HistoricoAtendimentosAPI.getOPMEsAtendimento(
        Number(selectedAtendimento?.idAtendimento),
        {
          sortBy: 'dataAtualizacao',
          sortDirection: 'DESC',
          somenteAtendimento: true,
          ...params,
        },
      );
      setQuantidadeOpme(response?.totalCount);
      setItensFichaPaciente(
        (prev: number) => Number(prev) + Number(response?.totalCount),
      );
      return response;
    },
    [selectedAtendimento?.idAtendimento, setItensFichaPaciente],
  );

  const renderRowOpme = (opme: any) => {
    return (
      <div className="p-my-1">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHeader
              opme={opme}
              isCollapsed={isCollapsed}
              onToggle={onToggle}
            />
          )}
        >
          <PanelContent opme={opme} />
        </Panel>
      </div>
    );
  };
  return (
    <div>
      {exibirLista && (
        <SearchList.Root fetchApi={fetchOpme}>
          <SearchList.Header>
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              className="p-mb-2"
              medium
            >
              OPMEs
            </SimpleText>
          </SearchList.Header>

          <SearchList.BorderContainer>
            <SearchList.InfiniteScroll
              renderRow={renderRowOpme}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      )}
    </div>
  );
}
