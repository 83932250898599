import { ManterUsuarioProvider } from 'src/core/containers/ManterUsuario';

import CreateUserForm from './CreateUserForm';

const ManterUsuario = () => {
  return (
    <ManterUsuarioProvider>
      <CreateUserForm />
    </ManterUsuarioProvider>
  );
};

export default ManterUsuario;
