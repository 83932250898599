import { memo, useCallback, useEffect, useState } from 'react';

import { SidebarProps } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AssinaturaHsmAPI } from 'src/APIs/AdminAPI/AssinaturaHsm/AssinaturaHsmAPI';
import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

import { encodeStringToBase64 } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import { InputText } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Sidebar from 'src/components/Sidebar/Sidebar';

import TipoConsulta from './TipoConsulta';

import './MenuConfirmarConsulta.scss';

interface MenuConfirmarConsultaProps extends SidebarProps {
  idAtendimento: number;
}

const MenuConfirmarConsulta = ({
  onHide,
  idAtendimento,
  ...rest
}: MenuConfirmarConsultaProps) => {
  const navigate = useNavigate();
  const { atendimento, agenda } = useAppSelector((state: RootState) => state);
  const { camposPEP, setCamposPEP, camposObrigatoriosNaoPreenchidos } =
    useAtendimento();

  //TODO: isLoaded do webSigner estava causando um erro pois ele nunca entrava na funcao de callback para setar isLoaded como true. Por isso não está sendo verificado aqui. Verificar se o webSigner está carregado antes de tentar assinar o prontuario quando implementar assinatura HSM.

  const {
    webSigner: { handleSign, isSuccess },
    hsmPIN,
    setHsmPIN,
    canSignHSM,
    isHsm,
    handleSignHSM,
  } = useAssinatura();

  const { user } = useAppSelector((state: RootState) => state);

  const { removeStoredValue } = useLocalStorage(
    `tempoAtendimento/${idAtendimento}`,
  );

  const [hasPrescricaoNaoExecutada, setValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PrescricaoAPI.loadPrescricao(
      idAtendimento.toString(),
      {},
      { throwError: true },
    )
      .then(({ list }) => {
        setValue(
          list.filter(data => {
            if (!!data.idAtendimentoExecucao) {
              return data?.retornar && data?.situacaoPrescricao !== 'EXECUTADA';
            } else {
              return [true];
            }
          }).length > 0,
        );
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [idAtendimento]);

  const updateCamposProntuario = useCallback(
    (novosCampos: any) => {
      setCamposPEP((prevState: any) => ({
        ...prevState,
        ...novosCampos,
      }));
    },
    [setCamposPEP],
  );

  const handleAssinarProntuario: () => Promise<void> = useCallback(async () => {
    if (!canSignHSM && isHsm) {
      const senhaBase64 = encodeStringToBase64(hsmPIN);
      const values = {
        hsmSenha: senhaBase64,
        hsmHorasVigencia: null,
      };

      await AssinaturaHsmAPI.putPinHSM(user.idUsuario, values, {
        hideToast: true,
      });
    }

    const prontuario = await AtendimentoAPI.getProntuarioAssinar(idAtendimento);

    try {
      if (isHsm) {
        await handleSignHSM(prontuario?.data?.prontuarioBase64, {
          idAtendimento,
          skipPIN: true,
        });
      } else {
        await handleSign(
          {
            documents: [
              {
                content: prontuario?.data?.prontuarioBase64,
                contentType: 'BASE64',
              },
            ],
          },
          {
            sequenciaAssinatura: prontuario?.data?.sequenciaAssinatura,
            tipoDocumento: 'EXAMES_CIRURGIAS_GUIA',
          },
          [idAtendimento],
        );
      }
    } catch ({ message }: any) {
      console.log(message);

      onHide();
    }
  }, [
    handleSign,
    handleSignHSM,
    hsmPIN,
    idAtendimento,
    isHsm,
    onHide,
    user.idUsuario,
    canSignHSM,
  ]);

  const handleSubmitConsulta = useCallback(async () => {
    setLoading(true);
    const payload = {
      idAtendimento: Number(idAtendimento),
      idPaciente: Number(atendimento?.paciente?.id),
      idProfissionalSaude: Number(atendimento.medico.id),
      camposProntuario: camposPEP,
    };

    const response = await AtendimentoAPI.finalizarAtendimento(
      Number(idAtendimento),
      payload,
      { hideToast: true },
    );

    if (response?.status > 200 && response?.status < 300) {
      await handleAssinarProntuario();
      removeStoredValue();
      navigate('/agenda');
    }

    setLoading(false);
  }, [
    atendimento.medico.id,
    atendimento?.paciente?.id,
    camposPEP,
    handleAssinarProntuario,
    idAtendimento,
    navigate,
    removeStoredValue,
  ]);

  useEffect(() => {
    return () => {
      setHsmPIN(null);
    };
  }, [setHsmPIN]);

  return (
    <>
      <Sidebar
        position="right"
        {...rest}
        onHide={onHide}
        showCloseIcon={false}
        className="MenuConfirmarConsulta"
        loading={loading}
        baseZIndex={99999}
        icons={() => (
          <>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.MD}
              className="title"
              bold
            >
              Confirmação da finalização de consulta
            </SimpleText>
            <button className="p-sidebar-close p-link" onClick={onHide}>
              <span
                className="p-sidebar-close-icon pi pi-times close-button"
                style={{ color: '#54A275' }}
              />
            </button>
          </>
        )}
      >
        <div className="p-d-flex p-flex-column p-gap-3">
          <TipoConsulta updateCamposProntuario={updateCamposProntuario} />

          {!canSignHSM && isHsm && (
            <InputText
              value={hsmPIN}
              onChange={e => setHsmPIN(e.target.value)}
              label="Senha PIN"
              placeholder="Insira a sua senha PIN de assinatura de documentos "
              maxLength={99}
            />
          )}
        </div>

        <AlertBox
          visible={hasPrescricaoNaoExecutada}
          text="O atendimento não pode ser finalizado pois existe uma prescrição em andamento para o paciente!."
          type="DANGER"
        />
        <div className="p-d-flex p-mt-5 p-w-100 p-ai-center p-gap-2">
          <Button
            label="Cancelar"
            btnType="green-link"
            onClick={onHide}
            stretch
          />
          <Button
            label="Sim, finalizar"
            type="submit"
            disabled={hasPrescricaoNaoExecutada || (isHsm && !hsmPIN)}
            onClick={handleSubmitConsulta}
            loading={loading}
            stretch
          />
        </div>
        <div>
          {!isSuccess && !isHsm ? (
            <AlertBox
              visible={true}
              text="Não foi possivel inicializar o certificado digital para assinatura do prontuário. Ao finalizar o atendimento, o prontuário não será assinado. Você poderá assiná-lo posteriormente na funcionalidade de assinaturas pendentes."
              type="WARN"
            />
          ) : (
            <AlertBox
              visible={true}
              text="Ao finalizar o atendimento, você poderá assinar o prontuário."
              type="WARN"
            />
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default memo(MenuConfirmarConsulta);
