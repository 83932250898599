import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams, DropdownProps } from 'primereact/dropdown';
import { useController, useFormContext } from 'react-hook-form';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';
import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

type FieldProfissionalSaudeProps = DropdownProps & {
  idConsultorio: string | number;
  name?: string;
  label: string;
  params?: any;
};

export const FieldProfissionalSaudePreNatal = ({
  idConsultorio,
  name = 'idProfissionalSaude',
  label,
  params,
  ...rest
}: FieldProfissionalSaudeProps) => {
  const { control, watch } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [profissionaisSaude, setProfissionaisSaude] = useState<
    GetProfissionalDTO[]
  >([]);
  const [loadingSearchProfissionalSaude, setLoadingSearchProfissionalSaude] =
    useState(false);
  const [querySearchProfissionalSaude, setQuerySearchProfissionalSaude] =
    useState('');

  const debouncedSearchConvenio = useDebounce(
    querySearchProfissionalSaude,
    350,
  );

  const fetchProfissionalSaudes = useCallback(
    async (queryParam: any) => {
      if (!queryParam || !idConsultorio) return setProfissionaisSaude([]);

      try {
        const response = await PreNatalAPI.getProfissionaisSaudePreNatal({
          nome: queryParam,
          idConsultorio,
          ...params,
        });

        setProfissionaisSaude(response.list);
      } catch (error) {
        setProfissionaisSaude([]);
      } finally {
        setLoadingSearchProfissionalSaude(false);
      }
    },
    [idConsultorio, params],
  );

  useEffect(() => {
    const getProfissionalSaudes = async () => {
      if (!idConsultorio) return;
      try {
        setLoadingSearchProfissionalSaude(true);
        const response = await PreNatalAPI.getProfissionaisSaudePreNatal({
          idConsultorio,
        });
        setProfissionaisSaude(response.list);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSearchProfissionalSaude(false);
      }
    };
    getProfissionalSaudes();
  }, []);

  useEffect(() => {
    fetchProfissionalSaudes(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, fetchProfissionalSaudes]);

  const profissionalSaudesOptions = useMemo(() => {
    const options = profissionaisSaude.map(profissionalSaude => ({
      label: profissionalSaude.nome,
      value: profissionalSaude.id,
    }));

    return options;
  }, [profissionaisSaude]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setLoadingSearchProfissionalSaude(true);
    setQuerySearchProfissionalSaude(filter);
  };

  const options = profissionalSaudesOptions.length
    ? profissionalSaudesOptions
    : defaultOption;
  const emptyFilterMessage = querySearchProfissionalSaude.length
    ? 'Nenhuma opção encontrada'
    : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      onFilter={handleFilter}
      carregandoFiltro={loadingSearchProfissionalSaude}
      filterPlaceholder="Digite para pesquisar"
      emptyFilterMessage={emptyFilterMessage}
      options={options}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
