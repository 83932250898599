import { useCallback, useEffect, useRef, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import BasicPanel, {
  BasicPanelRef,
} from 'src/components/Panel/BasicPanel/BasicPanel';

import './AgendamentoGrupo.scss';
import { generateUniqueId } from 'src/utils/utils';

interface AgendamentoGrupoProps {
  onHide: () => void;
  selectedGrupo: any;
}

const AgendamentoGrupo = ({ onHide, selectedGrupo }: AgendamentoGrupoProps) => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const painelRef = useRef<BasicPanelRef>(null);

  const [grupos, setGrupos] = useState<GrupoAtendimentoDTO[]>([]);
  const [gruposFiltrados, setGruposFiltrados] = useState<GrupoAtendimentoDTO[]>(
    [],
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const getGrupos = useCallback(async () => {
    setValue('tipoGrupo', '');
    setValue('periodo', '');

    const params = {
      idProfissional: agenda.profissionalAtivo?.id,
    };

    const response = await GrupoAtendimentoAPI.loadGrupoAtendimentosTodos(
      params,
    );

    setGrupos(response);
    setGruposFiltrados(response);
  }, [agenda.profissionalAtivo?.id, setValue]);

  useEffect(() => {
    getGrupos();
  }, [agenda.profissionalAtivo?.id, getGrupos]);

  const handleFilter = useCallback(
    async (data: any) => {
      if (!data.tipoGrupo && !data.periodo) {
        return;
      }

      setGruposFiltrados([]);

      if (!data.periodo) {
        const filtradosIdTipoGrupo = grupos.filter(
          item => item.idTipoGrupoAtendimento === data.tipoGrupo,
        );

        setGruposFiltrados(filtradosIdTipoGrupo);
        return;
      }

      if (!data.tipoGrupo) {
        const filtradosPeriodo = grupos.filter(
          item => item.periodo === data.periodo,
        );

        setGruposFiltrados(filtradosPeriodo);
        return;
      }

      const filtrados = grupos.filter(
        item =>
          item.idTipoGrupoAtendimento === data.tipoGrupo &&
          item.periodo === data.periodo,
      );

      setGruposFiltrados(filtrados);
    },
    [grupos],
  );

  const removerDuplicados = (array: any[], label: string, value: string) => {
    const setFilter = new Set();

    const lista = array?.map(item => {
      return { label: item[label], value: item[value] };
    });

    const newArray = lista.filter(item => {
      const uniqueArr = setFilter.has(item.value);
      setFilter.add(item.value);

      return !uniqueArr;
    });

    return newArray;
  };

  const handleLimparBusca = () => {
    setValue('tipoGrupo', '');
    setValue('periodo', '');

    setGruposFiltrados(grupos);
  };

  const renderInputs = useCallback(() => {
    const periodoOptions = removerDuplicados(grupos, 'periodo', 'periodo');

    const tipoGrupoOptions = removerDuplicados(
      grupos,
      'nomeTipoGrupo',
      'idTipoGrupoAtendimento',
    );

    return (
      <form className="formulario" onSubmit={handleSubmit(handleFilter)}>
        <div className="p-mb-3 p-grid p-jc-between p-ai-end">
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange, onBlur, value } }: any) => (
              <Dropdown
                label="Período"
                className="p-col-4"
                placeholder="Selecione o período"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                options={periodoOptions}
                errorMsg={errors.periodo?.message}
              />
            )}
          />

          <Controller
            name="tipoGrupo"
            control={control}
            render={({ field: { onChange, onBlur, value } }: any) => (
              <Dropdown
                label="Tipo de grupo"
                className="p-col-5"
                placeholder="Selecione o tipo de grupo"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                options={tipoGrupoOptions}
                errorMsg={errors.tipoGrupo?.message}
              />
            )}
          />

          <Button
            type="submit"
            className="p-mb-2"
            label="Pesquisar"
            btnType="tonal"
            loading={isSubmitting}
          />
        </div>
      </form>
    );
  }, [
    control,
    errors.periodo?.message,
    errors.tipoGrupo?.message,
    grupos,
    handleFilter,
    handleSubmit,
    isSubmitting,
  ]);

  const renderCardPaciente = useCallback(
    (paciente: GrupoAtendimentoPacienteDTO) => {
      const dataNascimento =
        new Date(paciente.dataNascimento).getFullYear() -
        new Date().getFullYear();

      return (
        <div className="p-grid card-paciente">
          <div className="p-col-6">
            <Avatar
              size="large"
              image={paciente.urlFoto || ''}
              label={paciente.nome}
            />

            <div className="infos">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                {paciente.sexoDescricao?.toLocaleLowerCase()}
              </SimpleText>

              <SimpleText
                className="p-my-2"
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
              >
                {paciente.nome}
              </SimpleText>

              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                {dataNascimento * -1} anos | {paciente.celularParticular}
              </SimpleText>
            </div>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              05
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              00
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              00
            </SimpleText>
          </div>
        </div>
      );
    },
    [],
  );

  const renderAccordion = useCallback(
    (grupo: GrupoAtendimentoDTO) => {
      const handleSelectGrupo = () => {
        selectedGrupo(grupo);
        onHide();
      };

      const template = () => {
        return (
          <div onClick={handleSelectGrupo} className="p-grid p-w-100">
            <div className="p-col-4">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
                bold
              >
                {grupo?.apelido}
              </SimpleText>
            </div>

            <div className="p-col-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo?.periodo}
              </SimpleText>
            </div>

            <div className="p-col-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo.grupoAtendimentoPacientes?.length <= 9
                  ? '0' + grupo.grupoAtendimentoPacientes?.length
                  : grupo.grupoAtendimentoPacientes?.length}

                {grupo.grupoAtendimentoPacientes?.length === 1
                  ? ' Paciente'
                  : ' Pacientes'}
              </SimpleText>
            </div>

            <div className="p-col-3 text-max">
              <SimpleText
                title={grupo.nomeTipoGrupo}
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo.nomeTipoGrupo}
              </SimpleText>
            </div>

            <div className="p-col-1">
              <SimpleText
                className="text-qtd"
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {grupo.grupoAtendimentoPacientes?.length <= 9
                  ? '0' + grupo.grupoAtendimentoPacientes?.length
                  : grupo.grupoAtendimentoPacientes?.length}
              </SimpleText>
            </div>
          </div>
        );
      };

      return (
        <BasicPanel ref={painelRef} template={template()}>
          <>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
              bold
            >
              Pacientes
            </SimpleText>

            <div className="p-grid p-mt-3 p-mb-1">
              <div className="p-col-5">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Paciente
                </SimpleText>
              </div>

              <div className="p-col-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Presenças
                </SimpleText>
              </div>

              <div className="p-col-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  faltas
                </SimpleText>
              </div>

              <div className="p-col-2">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  Cancelamentos
                </SimpleText>
              </div>
            </div>

            {grupo.grupoAtendimentoPacientes?.map(paciente => (
              <div key={paciente.id}>{renderCardPaciente(paciente)}</div>
            ))}
          </>
        </BasicPanel>
      );
    },
    [onHide, renderCardPaciente, selectedGrupo],
  );

  return (
    <div className="container-modal">
      {renderInputs()}

      <div className="content">
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            bold={true}
          >
            Resultados da busca
          </SimpleText>

          <Button
            label="Limpar busca"
            onClick={handleLimparBusca}
            btnType="outline"
          />
        </div>

        <div className="p-grid p-col-11">
          <div className="p-col-4">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Apelido
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              M/A inicial
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Total
            </SimpleText>
          </div>

          <div className="p-col-3">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Tipo
            </SimpleText>
          </div>

          <div className="p-col-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Qtd.
            </SimpleText>
          </div>
        </div>

        <div className="scroll">
          {gruposFiltrados.length !== 0 ? (
            gruposFiltrados.map(grupo => (
              <div key={generateUniqueId()}>{renderAccordion(grupo)}</div>
            ))
          ) : (
            <div className="p-d-flex p-ai-center p-jc-center p-mt-6">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
              >
                Nenhum grupo encontrado
              </SimpleText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgendamentoGrupo;
