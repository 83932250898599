import { useFormContext } from 'react-hook-form';

import FormInput from 'src/components/FormInput/FormInput';
import { VariantPanel } from 'src/components/Panel/VariantPanel/VariantPanel';

export const CicloForm = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const hasError = !!Object.keys(errors?.ciclo || {})?.length;

  return (
    <VariantPanel
      isOpened
      hasError={hasError}
      title="Ciclo"
      subtitle="(Obrigatório)"
    >
      <form className="p-grid">
        <FormInput
          label="N. de ciclos previstos"
          sublabel="(Obrigatório)"
          name="ciclo.ciclosPrevistos"
          type="number"
          placeholder="Digite"
          className="p-col-6 p-sm-3"
          max={99}
          hideTextErrorSpace
        />
        <FormInput
          label="Ciclo atual"
          sublabel="(Obrigatório)"
          name="ciclo.cicloAtual"
          type="number"
          placeholder="Digite"
          className=" p-col-6 p-sm-3"
          max={99}
          hideTextErrorSpace
        />
        <FormInput
          label="Intervalo entre ciclos"
          sublabel="(Obrigatório)"
          name="ciclo.intervaloCiclos"
          type="number"
          placeholder="Digite"
          className=" p-col-6 p-sm-3"
          max={999}
          hideTextErrorSpace
        />
        <FormInput
          label="N. dias ciclo atual "
          sublabel="(Obrigatório)"
          name="ciclo.diasCicloAtual"
          type="number"
          placeholder="Digite"
          className=" p-col-6 p-sm-3"
          max={999}
          hideTextErrorSpace
        />
      </form>
    </VariantPanel>
  );
};
