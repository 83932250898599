import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  pendencias: Yup.array().of(
    Yup.object().shape({
      indicacaoClinica: Yup.string().when(['isIndicacaoRequired'], {
        is: true,
        then: field =>
          field.required(
            'Você precisa informar a indicação clínica para finalizar a solicitação.',
          ),
      }),
      justificativa: Yup.string().when('isJustificativaRequired', {
        is: true,
        then: field =>
          field.required(
            'Você precisa informar a justificativa para finalizar a solicitação.',
          ),
      }),
      showIndicacao: Yup.boolean(),
      showJustificativa: Yup.boolean(),
      isIndicacaoRequired: Yup.boolean(),
      isJustificativaRequired: Yup.boolean(),
    }),
  ),
});
