import Http, { HttpParams } from 'src/core/http/Http';

export const getGlicemiaCapilar = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `glicemia-capilar/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesGlicemiaCapilar = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'glicemia-capilar/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarGlicemiaCapilar = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'glicemia-capilar',
    data: payload,
    return: true,
    ...options,
  });

export const alterarGlicemiaCapilar = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `glicemia-capilar/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarGlicemiaCapilar = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `glicemia-capilar/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerGlicemiaCapilar = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `glicemia-capilar/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const GlicemiaCapilarAPI = {
  getGlicemiaCapilar,
  getUnidadesGlicemiaCapilar,
  adicionarGlicemiaCapilar,
  alterarGlicemiaCapilar,
  inativarGlicemiaCapilar,
  removerGlicemiaCapilar,
};

export default GlicemiaCapilarAPI;
