import { useCallback, useEffect, useState, useRef } from 'react';

import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import useDebounce from 'src/utils/useDebounce';

import Avatar from 'src/components/Avatar/Avatar';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';
import Separator from 'src/components/Separator/Separator';

import './SearchPacientes.scss';
import { capitalizeFirstLetter } from 'src/utils/utils';

interface SeatchPacienteProps {
  value: GetPacienteDTO | undefined;
  label: string;
  onSelect(v: GetPacienteDTO): void;
  disabled?: boolean;
}
const SearchPacientes = ({
  onSelect,
  value,
  label,
  ...props
}: SeatchPacienteProps) => {
  const searchPacientesContentRef = useRef<HTMLDivElement>(null);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [pacientes, setPacientes] = useState<GetPacienteDTO[]>();
  const [current, setCurrent] = useState<string>('');
  const [exibirModal, setExibirModal] = useState<boolean>(false);

  const debouncedPesquisaPaciente = useDebounce(current, 350);

  const loadPacientes = useCallback(async value => {
    try {
      const params = {
        chave: value,
      };

      const aux = await PacienteAPI.loadPacienteByNome(params);
      if (aux) {
        setPacientes(aux.list);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (debouncedPesquisaPaciente) {
      loadPacientes(debouncedPesquisaPaciente);
    } else {
      setPacientes([]);
    }
  }, [loadPacientes, debouncedPesquisaPaciente]);

  useEffect(() => {
    function handleClickOutsidePacientesContent(event: any) {
      if (
        searchPacientesContentRef.current &&
        !searchPacientesContentRef.current.contains(event.target)
      ) {
        setShowOverlay(false);
        setCurrent('');
        setPacientes([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutsidePacientesContent);

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsidePacientesContent,
      );
    };
  }, [searchPacientesContentRef]);

  const renderPacienteCard = useCallback(
    (paciente: any, i: number, last = false) => {
      return (
        <div className="p-my-1">
          <Card type={CARD_TYPES.GRAY}>
            <div
              key={i}
              className="search-paciente-card p-p-1"
              onClick={e => {
                onSelect(paciente);
                setShowOverlay(false);
              }}
            >
              <div className="search-paciente-card-detail p-d-flex p-ai-center">
                <div>
                  <Avatar
                    className="avatar"
                    label={paciente?.nome}
                    image={paciente?.urlFoto || ''}
                  />
                </div>
                <div className="p-d-flex p-flex-column p-jc-center">
                  <SimpleText
                    fontSize={FONT_SIZE.XXXS}
                    fontColor={FONT_COLOR.COLOR_60}
                  >
                    Registro nº {paciente?.id}
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_16}
                    className="p-mb-1"
                  >
                    {paciente?.nome}
                  </SimpleText>
                  <SimpleText
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_60}
                  >
                    {paciente?.cpf ? paciente?.cpf : 'CPF não informado'} •{' '}
                    {paciente?.sexo?.descricao
                      ? capitalizeFirstLetter(paciente?.sexo?.descricao)
                      : 'Sexo não informado'}{' '}
                    •{' '}
                    {paciente?.cartaoPrincipal?.convenio?.nomeFantasia ||
                      paciente?.cartaoPrincipal?.convenio?.razaoSocial ||
                      'Convênio não informado'}
                  </SimpleText>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    },
    [onSelect],
  );

  const renderOverlay = useCallback(() => {
    return (
      <div className="search-paciente-overlay-content">
        <SimpleText>
          {current
            ? `Busca por "${current}"`
            : 'O resultado da busca será exibido aqui'}
        </SimpleText>

        <Separator spaceOnly vertical />

        {!!pacientes?.length && (
          <div className="scroll-place">
            <ScrollPanel stretch={true} activityIndicator={false}>
              <>
                {(pacientes || []).map((p: GetPacienteDTO, i: number) =>
                  renderPacienteCard(
                    p,
                    i,
                    pacientes && pacientes.length - 1 === i ? true : false,
                  ),
                )}
              </>
            </ScrollPanel>
          </div>
        )}
      </div>
    );
  }, [pacientes, current, renderPacienteCard]);

  return (
    <div ref={searchPacientesContentRef} className="SearchPacientes">
      <TextInput
        label={label}
        rounded
        icon={'pi pi-search'}
        value={!value || showOverlay ? current : value?.nome}
        placeholder="Pesquisar paciente por nome, CPF ou carteirinha"
        onChange={v => {
          setCurrent(v.target.value);
        }}
        onFocus={e => {
          setShowOverlay(true);
          setCurrent('');
        }}
      />

      <div className={`OverlayPanel ${showOverlay ? 'show' : 'hidden'}`}>
        <ElevatedCard type={'white'} stretch noMargin>
          {renderOverlay()}
        </ElevatedCard>
      </div>
    </div>
  );
};

export default SearchPacientes;
