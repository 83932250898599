import { useState, useEffect } from 'react';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

interface ConvenioConfigTiss {
  idConvenio: number;
  utilizaTiss: boolean;
  timeoutCancelaGuia?: number;
  timeoutSolicitacaoProcedimento?: number;
  mensagemConexaoOperadora?: string;
  mensagemFalhaComunicacao?: string;
}

export const useConvenioConfigTiss = () => {
  const { idConvenio } = useSolicitacaoExameCirurgia();

  const [convenioConfigTiss, setConvenioConfigTiss] =
    useState<ConvenioConfigTiss>();
  const [loadingConvenioConfigTiss, setLoadingConvenioConfigTiss] =
    useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!idConvenio) return;

      try {
        setLoadingConvenioConfigTiss(true);

        const response = await ConvenioAPI.getConveniosTissById(idConvenio);

        setConvenioConfigTiss(response);
      } catch {
        setLoadingConvenioConfigTiss(false);
      } finally {
        setLoadingConvenioConfigTiss(false);
      }
    };

    fetch();
  }, [idConvenio]);

  return {
    convenioConfigTiss,
    loadingConvenioConfigTiss,
  };
};
