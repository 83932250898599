import { useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { ProtocoloAtendimentoFilter } from './Grid/ProtocoloAtendimentoFilter';
import { ProtocoloAtendimentoRow } from './Grid/ProtocoloAtendimentoRow';

export const CentralProtocoloAtendimento = () => {
  const navigate = useNavigate();

  const [reload, setReload] = useState(0);

  const fetchApi = async ({ query, ...params }: any) => {
    try {
      return await ProtocoloAtendimentoAPI.getProtocoloAtendimentos({
        nome: query,
        ...params,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  return (
    <SearchList.Root
      reloadWhenEmpresaChange
      empresaRequired
      fetchApi={fetchApi}
    >
      <>
        <div className="p-d-flex p-jc-md-between p-gap-2 p-align-center p-flex-wrap p-ai-center p-jc-center p-col-12 p-px-0">
          <SearchList.SearchInput
            label="Pesquise por nome de Pesquisar por nome do protocolo"
            placeholder="Digite uma palavra chave"
            className="p-col-12 p-md-6 p-lg-4"
          />

          <ProtocoloAtendimentoFilter />
        </div>

        <SearchList.BorderContainer>
          <div className="p-d-flex p-gap-2 p-flex-wrap p-ai-center p-jc-between">
            <Button
              btnType="pill"
              label="Novo protocolo"
              icon="fas fa-plus"
              onClick={() =>
                navigate('/cadastros/protocolo-atendimento/cadastro')
              }
              className="p-col-12 p-md-6 p-lg-3"
              checkPermission="ADM_PROTOCOLO_ATENDIMENTO_CADASTRAR"
            />
            <SearchList.TotalCount />
          </div>

          <SearchList.InfiniteScroll
            reload={reload}
            renderRows={items =>
              items.map((item: any, idx: number) => (
                <ProtocoloAtendimentoRow
                  key={idx}
                  item={item}
                  reloadList={reloadList}
                />
              ))
            }
          />
        </SearchList.BorderContainer>
      </>
    </SearchList.Root>
  );
};
