import { useState } from 'react';

import { useNavigate } from 'react-router';

import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Page from 'src/components/Basics/Page/Page';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './List/Row';

import './Perfis.scss';

interface PerfisProps {
  tabIndex: number;
}

const Perfis = ({ tabIndex }: PerfisProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const navigate = useNavigate();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_perfil_cadastrar',
      'adm_perfil_alterar',
      'adm_perfil_consultar',
      'adm_perfil_excluir',
    ],
  });

  const [idPerfilDelete, setIdPerfilDelete] = useState<number | null>(null);
  const [reloadSearchList, setReloadSearchList] = useState(0);

  const fetchApi = async ({
    query,
    ...params
  }: any): Promise<Paginator<GetPerfilDTO[]>> => {
    return await PerfisAPI.loadPerfis({
      idEmpresa: user?.idEmpresa,
      nome: query,
      ...params,
    });
  };

  const reloadList = () => {
    setReloadSearchList(currentReload => currentReload + 1);
  };

  const handleModalAddPerfil = () => {
    navigate('/config/cardapio/perfil', { state: { tabIndex } });
  };

  const handleEdit = (perfil: Perfil) => {
    if (perfil.id) {
      navigate(`/config/cardapio/perfil/${perfil.id}`, { state: { tabIndex } });
    }
  };

  const handleDelete = (idPerfil: number) => {
    setIdPerfilDelete(idPerfil);
  };

  const handleDeleteDialogClose = () => {
    setIdPerfilDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!idPerfilDelete) return null;

    const response = await PerfisAPI.removePerfilById(idPerfilDelete);

    if (response.status === 204) {
      reloadList();
    }

    return handleDeleteDialogClose();
  };

  const renderRow = (perfil: Perfil) => (
    <Row perfil={perfil} onEdit={handleEdit} onDelete={handleDelete} />
  );

  return (
    <Page className="Perfis" white>
      <>
        <div className="p-px-3">
          <SearchList.Root fetchApi={fetchApi} reloadWhenEmpresaChange>
            <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
              <SearchList.SearchInput placeholder="Pesquisar parâmetro" />

              <Button
                icon="fa fa-plus"
                className="p-mb-3"
                btnType="pill"
                label="Configurar novo perfil"
                onClick={handleModalAddPerfil}
                checkPermission="ADM_PERFIL_CADASTRAR"
              />
            </div>

            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reloadSearchList}
            />
          </SearchList.Root>
        </div>

        <ConfirmDialog
          visible={!!idPerfilDelete}
          header="Excluir Perfil"
          text="O perfil será excluído da base de dados. Você realmente deseja excluir?"
          confirmText="Sim, excluir"
          onHide={handleDeleteDialogClose}
          onConfirm={handleDeleteConfirm}
        />

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </>
    </Page>
  );
};

export default Perfis;
