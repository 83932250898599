import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { FieldNumeroCartaoConvenio } from 'src/components/Fields/FieldNumeroCartaoConvenio/FieldNumeroCartaoConvenio';
import { FieldTipoPlano } from 'src/components/Fields/FieldTipoPlano/FieldTipoPlano';

import './FormNovoPaciente.scss';

const sexoOptions = [
  { label: 'Masculino', value: 3 },
  { label: 'Feminino', value: 1 },
];

import FormInput from 'src/components/FormInput/FormInput';

import { validationSchema } from './validationSchema';

import { Button } from 'src/components/_UI/Button';

type FormValues = {
  nome: string;
  idSexo: number;
  cpf: string;
  cns: string;
  dataNascimento: Date;
  nomeMae: string;
  maeDesconhecida: boolean;
  estrangeiro: boolean;
  passaporte: string;
  tipoPlano: string;
  numeroCartao: string;
  dataValidadeCartao: Date;
};

interface FormNovoPacienteProps {
  onSubmit: (data: FormValues) => void;
  onCancel: () => void;
}

const FormNovoPaciente = (props: FormNovoPacienteProps) => {
  const { onSubmit, onCancel } = props;

  const useFormMethods = useForm<FormValues>({
    defaultValues: {
      idSexo: 3,
      maeDesconhecida: false,
      estrangeiro: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
    setValue,
  } = useFormMethods;

  const handleConvenioChange = () => {
    setValue('numeroCartao', '');
    setValue('tipoPlano', '');
  };

  const maeDesconhecida = watch('maeDesconhecida');
  const estrangeiro = watch('estrangeiro');

  return (
    <div className="novo-paciente">
      <FormProvider {...useFormMethods}>
        <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            className="p-col-12 p-md-8"
            name="nome"
            label="Nome completo*"
            placeholder="Nome do paciente"
            errorMsg={errors?.nome?.message}
            hideTextErrorSpace
          />

          <SelectButtonControlled
            className="p-col p-mt-2"
            control={control}
            name="idSexo"
            label="Sexo"
            options={sexoOptions}
            errorMsg={errors.idSexo?.message}
          />

          <MaskedInputControlled
            className="p-col-12 p-md-4"
            control={control}
            name="cpf"
            label="CPF*"
            mask={MASK.CPF}
            placeholder="CPF do paciente"
            errorMsg={errors.cpf?.message}
          />

          <FormInput
            className="p-col-12 p-md-4"
            name="cns"
            label="CNS"
            placeholder="CNS do paciente"
            errorMsg={errors.cns?.message}
            hideTextErrorSpace
          />

          <CalendarInputControlled
            className="p-col-12 p-md-4"
            control={control}
            name="dataNascimento"
            label="Data de nascimento*"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            maxDate={new Date()}
            errorMsg={errors.dataNascimento?.message}
          />

          {!maeDesconhecida ? (
            <FormInput
              className="p-col-12"
              name="nomeMae"
              label="Nome da mãe*"
              placeholder="Nome da mãe do paciente"
              errorMsg={errors.nomeMae?.message}
              hideTextErrorSpace
            />
          ) : null}

          <div className="p-col-12">
            <CheckboxControlled
              control={control}
              name="maeDesconhecida"
              label="Mãe desconhecida"
            />
          </div>

          <div className="p-col-12">
            <CheckboxControlled
              control={control}
              name="estrangeiro"
              label="Estrangeiro"
            />
          </div>

          {estrangeiro ? (
            <FormInput
              className="p-col-12"
              name="passaporte"
              label="Passaporte"
              placeholder="Passaporte do paciente"
              maxLength={9}
              errorMsg={errors.passaporte?.message}
              hideTextErrorSpace
            />
          ) : null}

          <FieldConvenio
            name="convenio"
            label="Plano de Saúde"
            placeholder="Selecione"
            customOnChange={handleConvenioChange}
          />

          <FieldTipoPlano />

          <FormInput
            className="p-col-12"
            name="outroTipoPlano"
            label="Outro tipo de plano"
            maxLength={50}
            hideTextErrorSpace
          />

          <FieldNumeroCartaoConvenio
            label="Número do cartão"
            convenioFieldName="convenio"
          />

          <CalendarInputControlled
            className="p-col-12 p-md-6"
            control={control}
            name="dataValidadeCartao"
            label="Validade"
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
            errorMsg={errors.dataValidadeCartao?.message}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={onCancel}
              stretch
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormNovoPaciente;
