import { useContext } from 'react';

import {
  InputMask as PrimeInputMask,
  InputMaskChangeParams,
  InputMaskProps as PrimeInputMaskProps,
} from 'primereact/inputmask';

import ThemeContext from 'src/core/themes/ThemeContext';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ComponentsLabel from '../ComponentsLabel/ComponentsLabel';

import './MaskedInput.scss';

export enum MASK {
  CPF = '999.999.999-99',
  CNPJ = '99.999.999/9999-99',
  CEP = '99999-999',
  PHONE = '(99) 99999-9999',
  NODDDPHONE = '99999-9999',
  TELEPHONE = '(99) 9999-9999',
  CARTEIRINHA = '9 999 999999999999 9',
}

export interface MaskerInputProps
  extends Omit<PrimeInputMaskProps, 'disabled' | 'required'> {
  name: string;
  errorMsg?: string | undefined;
  label?: string | undefined;
  options?: any;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
}
const MaskedInput = ({
  name,
  errorMsg,
  className,
  label,
  onChange,
  disabled,
  required,
  mask,
  ...props
}: MaskerInputProps) => {
  const theme = useContext(ThemeContext);

  const renderLabel = () => {
    if (label || label === '' || required) {
      return <ComponentsLabel name={name} label={label} required />;
    }
    return <></>;
  };

  return (
    <div
      className={`MaskedInput ${theme?.theme || ''} ${
        errorMsg ? 'error' : ''
      } ${className || ''}`}
      key={name}
    >
      {renderLabel()}

      <PrimeInputMask
        {...props}
        id={name}
        //autoClear={false}
        className={`${errorMsg ? 'p-invalid' : ''}`}
        onChange={(e: InputMaskChangeParams) => {
          if (onChange) {
            onChange(e);
          }
        }}
        mask={mask?.replace(/\d/g, '9') || ''}
        disabled={disabled ? true : false}
        required={required ? true : false}
      />
      {errorMsg ? (
        <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
          {errorMsg}
        </SimpleText>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MaskedInput;
