import dayjs from 'dayjs';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const PendenciasTissRow = ({ item }: any) => {
  const formattedServico =
    !!item.servico &&
    item.servico
      .toLowerCase()
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <div className={`p-col-12 p-d-flex p-grid p-ai-center content-row`}>
      <SimpleText className="p-col-1 p-text-truncate" title={item.guia}>
        {item.guia}
      </SimpleText>
      <SimpleText className="p-col-3" lines={3}>
        {formattedServico || ''}
      </SimpleText>
      <SimpleText className="p-col-2 p-text-truncate">
        {item.auxiliar || ''}
      </SimpleText>
      <SimpleText className="p-col-1">{item.tentativas}</SimpleText>
      <SimpleText className="p-col-2">
        {item.ultimaTentativa
          ? dayjs(item.ultimaTentativa).format('DD/MM/YYYY HH:mm')
          : ''}
      </SimpleText>
      <SimpleText
        className="p-col-12 p-sm-3 p-py-0 p-wordbreak-normal"
        lines={3}
      >
        {item.erroUltimaTentativa || ''}
      </SimpleText>
    </div>
  );
};

export const PendenciasTissRowMobile = ({ item }: any) => {
  const formattedServico =
    !!item.servico &&
    item.servico
      .toLowerCase()
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <div className={`p-col-12 p-grid content-row`}>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Guia</SimpleText>
        <SimpleText>{item.guia}</SimpleText>
      </div>
      <div className="p-col-8 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Serviço</SimpleText>
        <SimpleText>{formattedServico || ''}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Auxiliar</SimpleText>
        <SimpleText>{item.auxiliar || ''}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Tentativas</SimpleText>
        <SimpleText>{item.tentativas}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Ultimas tentativas</SimpleText>
        <SimpleText>
          {item.ultimaTentativa
            ? dayjs(item.ultimaTentativa).format('DD/MM/YYYY HH:mm')
            : ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Erro (ultimas tentativas)</SimpleText>
        <SimpleText>{item.erroUltimaTentativa || ''}</SimpleText>
      </div>
    </div>
  );
};
