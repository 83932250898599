import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  periodo: Yup.array()
    .test(
      'oneOfRequired',
      'É necessário adicionar o periodo de tempo, com data inicial e final',
      function (item: any) {
        let response = false;
        if (item) {
          response = item[1] !== null;
        }

        return response;
      },
    )
    .typeError(
      'É necessário adicionar o periodo de tempo, com data inicial e final',
    ),
});

export default validationSchema;
