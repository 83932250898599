import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import HorarioAtendimentoAPI from 'src/APIs/AgendaAPI/HorarioAtendimentoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { dateToHourMinutsString } from 'src/utils/utils';

import Dialog from 'src/components/Dialog/Dialog';

import FormStep1, { transformItemStep1 } from './FormStep1HorarioAtendimento';
import FormStep2, { transformItemStep2 } from './FormStep2HorarioAtendimento';

interface FormHorarioAtendimentoProps {
  visible: boolean;
  onHide(): void;
  item?: GetHorarioAtendimentoDTO | undefined;
  onSuccess(): void;
}

const FormHorarioAtendimento = memo(
  ({ visible, onHide, item, onSuccess }: FormHorarioAtendimentoProps) => {
    const { agenda, consultorios } = useAppSelector(
      (state: RootState) => state,
    );
    const [step, setStep] = useState(1);

    const [form1Value, setForm1Value] = useState<any>();
    const [form2Value, setForm2Value] = useState<any>();

    const isEdit = !!item?.id;

    const title = useMemo(
      () =>
        isEdit
          ? 'Editar horário de atendimento'
          : 'Adicionar horário de atendimento',
      [isEdit],
    );

    const submitStep1 = useCallback(value => {
      setForm1Value((v: any) => ({ ...(v || {}), ...value }));
      setStep(2);
    }, []);

    const cancelStep1 = useCallback(() => {
      onHide();
    }, [onHide]);

    const submitStep2 = useCallback(
      async value => {
        setForm2Value((v: any) => ({ ...(v || {}), ...value }));

        const form = {
          ...(form1Value || {}),
          ...value,
          idsTipoCuidados: value?.idsTipoCuidados
            ? value.idsTipoCuidados
            : null,
        };
        const {
          intervalo,
          intervaloPersonalizado,
          horarioInicial,
          horarioFinal,
          prestadorExecutante,
          observacoes,
        } = form;

        if (intervalo === 'OUTRO') form.intervalo = intervaloPersonalizado;
        form.horarioInicial = dateToHourMinutsString(horarioInicial);
        form.horarioFinal = dateToHourMinutsString(horarioFinal);

        delete form.horarioTemporario;
        delete form.intervaloPersonalizado;
        form.prestadorExecutante = prestadorExecutante || false;
        form.observacoes = observacoes || '';
        form.idMedico = agenda.profissionalAtivo?.id;
        form.idConsultorio = consultorios.ativo?.id;

        const saveAction = isEdit
          ? HorarioAtendimentoAPI.updateHorarioAtendimento
          : HorarioAtendimentoAPI.createHorarioAtendimento;

        const response = await saveAction(form);

        if (response && !('status' in response)) onSuccess();

        // setFormValue(v => ({ ...v, value }));
        onHide();
      },
      [
        agenda.profissionalAtivo?.id,
        consultorios.ativo?.id,
        form1Value,
        isEdit,
        onHide,
        onSuccess,
      ],
    );

    const cancelStep2 = useCallback(() => {
      setStep(1);
    }, []);

    useEffect(() => {
      if (visible) {
        setForm1Value(undefined);
        setForm2Value(undefined);
        setStep(1);
      }
    }, [visible]);

    useEffect(() => {
      setForm1Value(transformItemStep1(item));
      setForm2Value(transformItemStep2(item));
    }, [item]);

    return (
      <Dialog
        header={`${title} (${step}/2)`}
        visible={visible}
        onHide={onHide}
        maskClassName="configuracao-agenda-dialog"
        maximizedTabletSize
      >
        <div>
          {step === 1 && (
            <FormStep1
              edit={isEdit}
              initialValue={form1Value}
              onSubmit={submitStep1}
              onCancel={cancelStep1}
            />
          )}

          {step === 2 && (
            <FormStep2
              initialValue={form2Value}
              onSubmit={submitStep2}
              onCancel={cancelStep2}
            />
          )}
        </div>
      </Dialog>
    );
  },
);

export default memo(FormHorarioAtendimento);
