import {
  GetContrarreferenciaItem,
  GetEncaminhamentoItem,
  GetEncaminhamentos,
  PostContrarreferenciaItem,
  PostEncaminhamentoItemPayload,
  PutContrarreferenciaItem,
  PutEncaminhamentoItemPayload,
} from 'src/models/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';

import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/encaminhamento-contrarreferencia';

type Options = Omit<HttpParams, 'service' | 'url'>;

const loadEncaminhamentoById = async (
  idEncaminhamento: string | number,
  options?: Options,
): Promise<GetEncaminhamentoItem> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/encaminhamento/${idEncaminhamento}`,
    hideToast: true,
    ...options,
  });
};
const loadEncaminhamentos = async (
  idAtendimento: string,
  params?: any,
  options?: Options,
): Promise<GetEncaminhamentos[]> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });
};
const createEncaminhamento = async (
  payload: PostEncaminhamentoItemPayload,
  options?: Options,
): Promise<null> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/encaminhamento`,
    data: payload,
    ...options,
  });
};
const updateEncaminhamento = async (
  payload: PutEncaminhamentoItemPayload,
  options?: Options,
): Promise<null> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/encaminhamento/${payload.idEncaminhamento}`,
    data: payload,
    ...options,
  });
};
const deleteEncaminhamento = async (
  idEncaminhamento: string | number,
  options?: Options,
): Promise<null> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/encaminhamento/${idEncaminhamento}`,
    ...options,
  });
};

//CONTRARREFERENCIA
const loadContrarreferenciaById = async (
  idContrarreferencia: string,
  options?: Options,
): Promise<GetContrarreferenciaItem> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/contrarreferencia/${idContrarreferencia}`,
    hideToast: true,
    ...options,
  });
};
const createContrarreferencia = async (
  payload: PostContrarreferenciaItem,
  options?: Options,
): Promise<null> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/contrarreferencia`,
    data: payload,
    ...options,
  });
};
const updateContrarreferencia = async (
  payload: PutContrarreferenciaItem,
  options?: Options,
): Promise<null> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/contrarreferencia/${payload.idContrarreferencia}`,
    data: payload,
    ...options,
  });
};
const deleteContrarreferencia = async (
  idContrarreferencia: string | number,
  options?: Options,
): Promise<null> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/contrarreferencia/${idContrarreferencia}`,
    ...options,
  });
};

const loadTiposEncaminhamentos = async (options?: Options): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/tipo-encaminhamentos`,
    hideToast: true,
    ...options,
  });
};

const loadPacienteDatasConsulta = async (
  idPaciente: string | number,
  options?: Options,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/paciente-data-consulta/${idPaciente}`,
    hideToast: true,
    ...options,
  });
};

const inativarEncaminhamento = async (
  idEncaminhamento: string | number,
  idAtendimento: string | number,
  payload: any,
  options?: Options,
): Promise<null> => {
  return Http.put({
    service: 'pep',
    data: payload,
    url: `${basepath}/${idEncaminhamento}/atendimento/${idAtendimento}/inativar-encaminhamento`,
    ...options,
  });
};

const inativarContrarreferencia = async (
  idContrarreferencia: string | number,
  idAtendimento: string | number,
  payload: any,
  options?: Options,
): Promise<null> => {
  return Http.put({
    service: 'pep',
    data: payload,
    url: `${basepath}/${idContrarreferencia}/atendimento/${idAtendimento}/inativar-contrarreferencia`,
    ...options,
  });
};

export const AtendimentoEncaminhamentoAPI = {
  loadEncaminhamentoById,
  loadEncaminhamentos,
  createEncaminhamento,
  updateEncaminhamento,
  deleteEncaminhamento,
  loadContrarreferenciaById,
  createContrarreferencia,
  updateContrarreferencia,
  deleteContrarreferencia,
  loadTiposEncaminhamentos,
  loadPacienteDatasConsulta,
  inativarEncaminhamento,
  inativarContrarreferencia,
};
