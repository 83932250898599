/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './List/Header';
import { Row } from './List/Row';

import './ParametrosGlobais.scss';

const ParametrosGlobais = () => {
  const [reloadSearchList, setReloadSearchList] = useState(0);

  const confirmModalExclusao = useDisclosure({ opened: false });

  const navigate = useNavigate();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_parametros_cadastrar',
      'adm_parametros_alterar',
      'adm_parametros_consultar',
      'adm_parametros_excluir',
    ],
  });

  const fetchApi = async ({
    query,
    ...params
  }: any): Promise<Paginator<any>> => {
    return await ParametroAPI.loadParametros(
      {
        codigoParametro: query,
        ...params,
      },
      { throwError: true },
    );
  };

  const reloadList = () => {
    setReloadSearchList(currentReload => currentReload + 1);
  };

  const handleModalAddParametro = () => {
    navigate('/config/parametros/novo');
  };

  const handleModalEditParametro = (item: any) => {
    if (item.id) {
      navigate(`/config/parametros/novo/${item.id}`, { state: item });
    }
  };

  const handleExcluirParametro = (item: any) => {
    confirmModalExclusao.open({ state: item.id });
  };

  const handleDeletarParametro = async (id: number) => {
    const response = await ParametroAPI.removeParametro(id);

    if (response.status === 204) {
      reloadList();
      confirmModalExclusao.close();
    }
  };

  const renderRow = (parametro: Parametro) => (
    <Row
      parametro={parametro}
      onEdit={handleModalEditParametro}
      onDelete={handleExcluirParametro}
    />
  );

  return (
    <div className="ParametrosGlobais">
      <div className="p-p-3">
        <SearchList.Root fetchApi={fetchApi} reloadWhenEmpresaChange>
          <SearchList.BorderContainer>
            <div className="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-mb-2 p-mb-md-0">
              <SearchList.SearchInput
                label="Pesquisar parâmetro"
                placeholder="Digite..."
              />

              <Button
                icon="fa fa-plus"
                label="Novo parâmetro"
                btnType="pill"
                onClick={handleModalAddParametro}
                checkPermission="ADM_PARAMETROS_CADASTRAR"
              />
            </div>

            <SearchList.Header>
              <Header />
            </SearchList.Header>

            <SearchList.InfiniteScroll
              renderRow={renderRow}
              reload={reloadSearchList}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </div>

      {confirmModalExclusao.isOpen && (
        <ConfirmDialog
          visible={confirmModalExclusao.isOpen}
          onHide={confirmModalExclusao.close}
          onConfirm={() => handleDeletarParametro(confirmModalExclusao.state)}
          text="Usuários poderão ser impactados pela exclusão do parâmetro. Você realmente deseja excluir o parâmetro da base de dados?
          "
          header="Excluir parâmetro"
          confirmText="Sim, excluir"
        />
      )}

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default ParametrosGlobais;
