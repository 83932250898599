import React from 'react';

import { useNavigate, useParams } from 'react-router';

import PageHeaderComp, {
  PageHeaderProps,
} from 'src/components/PageHeader/PageHeader';

export const PageHeader = ({ ...props }: PageHeaderProps) => {
  const { hashEmpresa } = useParams();
  const navigate = useNavigate();

  return (
    <PageHeaderComp
      onClickBackButton={() => navigate(`/portal/${hashEmpresa}/inicio`)}
      style={{
        paddingInline: '5%',
        paddingBlock: '8px',
      }}
      {...props}
    />
  );
};
