import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/pedido-cuidado';

export const getPedidosCuidado = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const adicionarPedidoCuidado = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });

export const alterarPedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicita: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento/${idAtendimentoSolicita}`,
    data: payload,
    return: true,
    ...options,
  });

export const alterarPedidoCuidadoLembrete = (
  idPedidoCuidado: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/lembrete/${idPedidoCuidado}`,
    return: true,
    ...options,
  });

export const inativarPedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicita: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento/${idAtendimentoSolicita}/inativar`,
    data: payload,
    return: true,
    ...options,
  });

export const removerPedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicita: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento/${idAtendimentoSolicita}`,
    return: true,
    ...options,
  });

export const atenderPedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicita: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento/${idAtendimentoSolicita}/atender`,
    data: payload,
    return: true,
    ...options,
  });

export const alterarAtendimentoPedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicita: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento/${idAtendimentoSolicita}/editar-atendimento`,
    data: payload,
    return: true,
    ...options,
  });

export const excluirAtendimentoPedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicita: number,
  idAtendimentoExecucao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento-solicitante/${idAtendimentoSolicita}/atendimento-execucao/${idAtendimentoExecucao}/excluir-atendimento`,
    return: true,
    ...options,
  });

export const getTiposPedidoCuidado = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: '/tipos-cuidados/pesquisa',
    params,
    hideToast: true,
    return: true,
    ...options,
  });

export const getProfissionaisSaude = (
  tipoPedidoCuidado: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/profissional-saude/por-tipo-cuidado/${tipoPedidoCuidado}`,
    hideToast: true,
    return: true,
    ...options,
  });

export const getHorariosDisponiveis = (
  idProfissionalSaude: number,
  idConsultorio: number,
  data: any,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const formAux: FormData = new FormData();
  formAux.append('somenteHorariosLivres ', 'true');
  return Http.get({
    service: 'agenda',
    url: `/agendas/${idProfissionalSaude}/consultorio/${idConsultorio}/dia/${data}`,
    data: formAux,
    params,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const editarPedidoCuidado = (
  idAtendimento: number,
  idPedidoCuidado: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const deletePedidoCuidado = (
  idPedidoCuidado: number,
  idAtendimentoSolicitante: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idPedidoCuidado}/atendimento-solicitante/${idAtendimentoSolicitante}/atendimento-execucao/${idAtendimento}/excluir-atendimento`,
    return: true,
    ...options,
  });

const PedidosCuidadoAPI = {
  getPedidosCuidado,
  adicionarPedidoCuidado,
  inativarPedidoCuidado,
  alterarPedidoCuidadoLembrete,
  removerPedidoCuidado,
  atenderPedidoCuidado,
  alterarPedidoCuidado,
  alterarAtendimentoPedidoCuidado,
  excluirAtendimentoPedidoCuidado,
  getTiposPedidoCuidado,
  getProfissionaisSaude,
  getHorariosDisponiveis,
  editarPedidoCuidado,
  deletePedidoCuidado,
};

export default PedidosCuidadoAPI;
