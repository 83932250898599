import { createContext } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

export const INITIAL_STATE = {};

export interface AGAContextProps {
  state: any;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  prevCurrentStep: () => void;
  nextCurrentStep: () => void;
  groupedForms: any;
  loading: boolean;
  tabAtiva: number;
  setTabAtiva: (tabAtiva: number) => void;
  maxTabs: number;
  setMaxTabs: (maxTabs: number) => void;
  ultimoStep: boolean;
  confirmDialogDisclosure: DisclosureType;
  respondeuTodasPerguntas: boolean;
  setRespondeuTodasPerguntas: (respondeuTodasPerguntas: boolean) => void;
  avancarSemResponder: boolean;
  setAvancarSemResponder: (avancarSemResponder: boolean) => void;
  idFormularioPai: number;
  questionariosList: any;
  activeFormulario: any;
  reloadHistorico: number;
  setReloadHistorico: (reloadHistorico: any) => void;
  evolucaoDialogDisclosure: DisclosureType;
  historicoDialogDisclosure: DisclosureType;
  isEdit: boolean | null;
  setIsEdit: (isEdit: boolean | null) => void;
  cancelPreenchimentoFormulario: () => void;
  formularioEdit: any;
  setFormularioEdit: (formularioEdit: any) => void;
  evolucaoHistorico: any;
  setEvolucaoHistorico: (evolucaoHistorico: any) => void;
  scrollToTopAGA: () => void;
  listaFormularios: any;
  diagnosticoFuncionalDisclosure: DisclosureType;
}

export const AGAContext = createContext({
  state: INITIAL_STATE,
} as AGAContextProps);
