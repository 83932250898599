import { formatCnpj } from 'src/utils/utils';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface MobileRowProps {
  empresa: any;
  onSwitchStatus: (empresa: any) => void;
  onView: (empresa: any) => void;
  onEdit: (empresa: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { empresa, onSwitchStatus, onView, onEdit } = props;

  const options = [
    {
      label: 'Visualizar',
      command: () => onView(empresa),
    },
    {
      label: 'Editar',
      command: () => onEdit(empresa),
      checkPermission: 'ADM_HOSPITAL_ALTERAR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {empresa?.codigo}
        </SimpleText>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Razão social
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {empresa?.razaoSocial}
        </SimpleText>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CNPJ
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {formatCnpj(empresa?.cnpj)}
        </SimpleText>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Status
        </SimpleText>
        <Switch
          checked={empresa?.status}
          onChange={() => onSwitchStatus(empresa)}
          checkPermission="ADM_EMPRESA_ALTERAR"
          showStatus
        />
      </div>
    </SearchListMobileRow>
  );
};
