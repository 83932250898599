import { useState } from 'react';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

type ProcedimentoSolicitadosDataRealizacaoChangeType = {
  dataRealizacao: Date | undefined;
  isSubmitting: boolean;
  handleSelectDataRealizacao: (e: any) => void;
};

interface ProcedimentoSolicitadosDataRealizacaoChangeProps {
  idProcedimento: number;
  idAtendimento: number;
  procedimentoDataRealizacao?: Date | null;
}

export const useProcedimentosSolicitadosDataRealizacaoChange = (
  props: ProcedimentoSolicitadosDataRealizacaoChangeProps,
): ProcedimentoSolicitadosDataRealizacaoChangeType => {
  const { idProcedimento, procedimentoDataRealizacao, idAtendimento } = props;

  const { handleUpdateProcedimentoPropValue } = useSolicitacaoExameCirurgia();

  const dataRealizacaoInitialValue = procedimentoDataRealizacao
    ? new Date(procedimentoDataRealizacao)
    : undefined;

  const [dataRealizacao, setDataRealizacao] = useState(
    dataRealizacaoInitialValue,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffectSkipFirst(() => {
    if (procedimentoDataRealizacao)
      setDataRealizacao(dataRealizacaoInitialValue);
  }, [procedimentoDataRealizacao]);

  const handleSelectDataRealizacao = async (e: any) => {
    const newDataRealizacao = e.value;

    if (newDataRealizacao === dataRealizacaoInitialValue) return;

    try {
      const payload = {
        dataRealizacao: newDataRealizacao,
      };

      setIsSubmitting(true);

      await SolicitacoesProcedimentosAPI.putDataRealizacao(
        idAtendimento,
        idProcedimento,
        payload,
      );

      handleUpdateProcedimentoPropValue(
        idProcedimento,
        'dataRealizacao',
        newDataRealizacao,
      );

      setDataRealizacao(newDataRealizacao);
    } catch (error) {
      setDataRealizacao(dataRealizacaoInitialValue);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    dataRealizacao,
    isSubmitting,
    handleSelectDataRealizacao,
  };
};
