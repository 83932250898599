import { useEffect } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Page from 'src/components/Basics/Page/Page';

import './ConfiguracaoTuss.scss';

const ConfiguracaoTuss = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/config/config-tuss') {
      navigate('/config');
    }
  }, [location, navigate]);

  return (
    <Page className={'ConfiguracaoTuss'}>
      <Outlet />
    </Page>
  );
};

export default ConfiguracaoTuss;
