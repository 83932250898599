import { useEffect, useState, useCallback } from 'react';

import { useForm, Controller, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI/Button';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Dialog from 'src/components/Dialog/Dialog';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import Separator from 'src/components/Separator/Separator';

const formFields = {
  nomeContratado: '',
  cnes: '',
  codigoContratado: '',
  login: '',
  senha: '',
  origem: '',
};

interface ModalAddConvenioProps {
  visible: boolean;
  onHide(): void;
  addConvenio(espec: any): void;
  editConvenio(convenioData: any): void;
  convenioEditData?: any;
}

const ModalAddConvenio = ({
  visible,
  convenioEditData,
  onHide,
  addConvenio,
  editConvenio,
}: ModalAddConvenioProps) => {
  const [config, setConfig] = useState<any>(false);

  const validationSchema = Yup.object().shape({
    convenio: Yup.object().required('Selecione o convênio'),
    nomeContratado: Yup.string().required(
      'Informe o nome do prestador contratado para o procedimento.',
    ),
    codigoContratado: Yup.string().required(
      'Informe o código do prestador contratado na operadora',
    ),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  useEffect(() => {
    if ('nomeContratado' in errors || 'codigoContratado' in errors)
      setConfig(true);
  }, [errors]);

  const loadPage = useCallback(async () => {
    if (convenioEditData) {
      setValue('convenio', convenioEditData?.convenio);
      setValue('nomeContratado', convenioEditData?.nomeContratado);
      setValue('cnes', convenioEditData?.cnes);
      setValue('codigoContratado', convenioEditData?.codigoContratado);
      setValue('login', convenioEditData?.login);
      setValue('password', convenioEditData?.password);
      setValue('origem', convenioEditData?.origem);

      setConfig(true);
    }
  }, [convenioEditData, setValue]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const handleCancel = useCallback(() => {
    reset(formFields);
    onHide();
  }, [onHide, reset]);

  const handleConvenio = useCallback(
    async (data: any) => {
      const { id: idConvenio } = data.convenio;

      const payload = {
        ...data,
        idConvenio,
      };

      if (convenioEditData) {
        editConvenio(payload);
      } else {
        addConvenio(payload);
      }

      setConfig(false);
      reset(formFields);
    },
    [addConvenio, convenioEditData, editConvenio, reset],
  );

  return (
    <Dialog
      type="modal"
      header={
        convenioEditData
          ? 'Editar convênio e configuração TISS'
          : 'Adicionar convênio e configuração TISS'
      }
      visible={visible}
      onHide={handleCancel}
      footer={() => <div />}
      maximizedMobileSize
    >
      <FormProvider {...useFormMethods}>
        <form
          className="modal-body-add-fornecedor-tuss"
          onSubmit={handleSubmit(handleConvenio)}
          defaultValue={''}
          onReset={() => reset(formFields)}
        >
          <FieldConvenio
            name="convenio"
            label="Selecione o convênio atendidos pelo profissional"
          />

          <Separator />

          {config ? (
            <>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <TextInput
                    label="Nome do contratado"
                    className="grid p-col-12 p-md-12"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    errorMsg={errors.nomeContratado?.message}
                  />
                )}
                name="nomeContratado"
              />
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <TextInput
                    permitirApenasNumeros
                    label="CNES"
                    className="grid p-col-12 p-md-12"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    errorMsg={errors.cnes?.message}
                  />
                )}
                name="cnes"
              />
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <TextInput
                    permitirApenasNumeros
                    label="Código do contratado"
                    className="grid p-col-12 p-md-12"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    errorMsg={errors.codigoContratado?.message}
                  />
                )}
                name="codigoContratado"
              />
              <div className="rowteste">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <TextInput
                      label="Login"
                      className="grid p-col-6 p-md-6"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      errorMsg={errors.login?.message}
                    />
                  )}
                  name="login"
                />

                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                  }: any) => (
                    <TextInput
                      label="Senha"
                      className="grid p-col-6 p-md-6"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      errorMsg={errors.password?.message}
                    />
                  )}
                  name="senha"
                />
              </div>

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value, ref } }: any) => (
                  <TextInput
                    permitirApenasNumeros
                    label="Origem"
                    className="grid p-col-12 p-md-12"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    errorMsg={errors.origem?.message}
                  />
                )}
                name="origem"
              />
            </>
          ) : null}

          <Button
            type="button"
            btnType={config ? 'danger-link' : 'green-link'}
            icon={config ? 'pi pi-minus' : 'pi pi-plus'}
            className="buttonaddespecialidade"
            onClick={() => {
              setConfig(!config);
            }}
            label={`${
              convenioEditData ? 'Editar' : 'Adicionar'
            } configuração TISS`}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Voltar"
              type="button"
              onClick={handleCancel}
              stretch
            />
            <Button
              label={convenioEditData ? 'Editar' : 'Adicionar'}
              type="submit"
              onClick={handleSubmit(handleConvenio)}
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalAddConvenio;
