import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/empresas';

export const retrieveEmpresas = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<EmpresaDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisar`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadEmpresaById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const sendEmpresa = (
  payload: PostEmpresaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaDTO> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateEmpresa = (
  id: number,
  payload: PostEmpresaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateEmpresaAtivarStatus = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PutEmpresaDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/ativar`,
    return: true,
    ...options,
  });
};

export const updateEmpresaInativarStatus = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PutEmpresaDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/inativar`,
    return: true,
    ...options,
  });
};

export const updateLogoEmpresa = (
  id: number,
  payload: PutEmpresaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PutEmpresaDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/logo`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeEmpresaById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<EmpresaDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

const EmpresasAPI = {
  GetAll: retrieveEmpresas,
  loadEmpresaById,
  sendEmpresa,
  removeEmpresaById,
  updateEmpresa,
  updateEmpresaAtivarStatus,
  updateEmpresaInativarStatus,
  updateLogoEmpresa,
};

export default EmpresasAPI;
