import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = 'peso-altura';

export const getPesoAltura = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getPesoAlturaEnum = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/valores-enum`,
    hideToast: true,
    ...options,
  });

export const postPesoAltura = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: baseUrl,
    data: payload,
    return: true,
    ...options,
  });

export const putPesoAltura = (
  idPesoAltura: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idPesoAltura}/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarPesoAltura = (
  idPesoAltura: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idPesoAltura}/atendimento/${idAtendimento}/inativar`,
    data: payload,
    return: true,
    ...options,
  });

export const removerPesoAltura = (
  idPesoAltura: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${baseUrl}/${idPesoAltura}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const PesoAlturaAPI = {
  getPesoAltura,
  getPesoAlturaEnum,
  postPesoAltura,
  putPesoAltura,
  inativarPesoAltura,
  removerPesoAltura,
};

export default PesoAlturaAPI;
