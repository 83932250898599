import { createContext } from 'react';

export const INITIAL_STATE = {};

export interface HistoricoAtendimentoData {
  state: any;
  quantidadeRegistros: number;
  setQuantidadeRegistros: (quantidade: any) => void;
  totalRegistros: number;
  setTotalRegistros: (total: number) => void;
  ocultarRegistros: boolean;
  setOcultarRegistros: (ocultar: boolean) => void;
  todosAtendimentos: boolean;
  setTodosAtendimentos: (todos: boolean) => void;
  atualAtendimento: boolean;
  setAtualAtendimento: (todos: boolean) => void;
  optionsHistorico: any[];
  filtroHistorico: string | null;
  setFiltroHistorico: (filtro: string | null) => void;
  selectedAtendimento: any;
  setSelectedAtendimento: (atendimento: any) => void;
  selectedCidCiap: any;
  setSelectedCidCiap: (cidCiap: any) => void;
  reload: boolean;
  setReload: (reload: boolean) => void;
  itensFichaPaciente: any;
  setItensFichaPaciente: (itens: any) => void;
  quantidadePreAtendimento: number;
  setQuantidadePreAtendimento: any;
  updateList: number;
  setUpdateList: React.Dispatch<React.SetStateAction<number>>;
  isHistoricoAssinatura: boolean;
}

export const HistoricoAtendimentoContext = createContext({
  state: INITIAL_STATE,
} as HistoricoAtendimentoData);
