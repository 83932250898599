import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownFilterParams } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import useDebounce from 'src/utils/useDebounce';

import { MultiSelectProps } from 'src/components/Basics/MultiSelect/MultiSelect';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

type FieldConsultoriosProps = MultiSelectProps & {
  name?: string;
  label: string;
};

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

export const FieldCiapMultiselect = ({
  name = 'ciap',
  label,
  ...rest
}: FieldConsultoriosProps) => {
  const { control, watch } = useFormContext();

  const [items, setItems] = useState<Cids[]>([]);
  const [querySearch, setQuerySearch] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedSearchConvenio = useDebounce(querySearch, 350);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async (params: string) => {
    if (params?.length < 2) return setItems([]);

    setLoading(true);

    const res = await UtilsAPI.getCiaps({
      codigo: params,
      nome: params,
    });

    setItems(res.list || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    load(debouncedSearchConvenio);
  }, [debouncedSearchConvenio, load]);

  const cidsSelected: Cids[] = watch(name);

  const itemsOptions = useMemo(() => {
    const data = items.map(item => ({
      label: item?.codigo,
      value: item,
    }));

    if (!!cidsSelected?.length) {
      cidsSelected.forEach(cid => {
        const exists = data.some(item => item.value.id === cid.id);

        if (!exists) {
          data.push({
            label: cid.codigo,
            value: cid,
          });
        }
      });
    }

    return data;
  }, [items, cidsSelected]);

  const handleFilter = ({ filter }: DropdownFilterParams) => {
    setQuerySearch(filter);
  };

  const itemTemplate = (option: any) => (
    <>
      <span style={{ width: '42px', display: 'inline-block' }}>
        {option.value?.codigo} -{' '}
      </span>
      {option.value?.nome}
    </>
  );

  const options = itemsOptions.length ? itemsOptions : defaultOption;
  const emptyFilterMessage =
    querySearch.length >= 2
      ? 'Nenhuma opção encontrada'
      : 'Digite no mínimo 2 caracteres para pesquisar';

  return (
    <MultiSelectControlled
      className="p-col-12"
      onFilter={handleFilter}
      filter
      control={control}
      name={name}
      dataKey="id"
      label={label}
      options={options}
      errorMsg={error?.message}
      display="chip"
      emptyFilterMessage={loading ? <GridListLoading /> : emptyFilterMessage}
      filterPlaceholder="Digite para pesquisar ..."
      filterBy="value.nome,value.codigo"
      itemTemplate={itemTemplate}
      showSelectAll={false}
      resetFilterOnHide
      {...rest}
    />
  );
};
