import { useState } from 'react';

import { MigraPEPAPI } from 'src/APIs/AdminAPI/MigraPEPAPI/MigraPEPAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import Tab from 'src/components/Tab/Tab';

import ConfirmarMigracao from './ConfirmarMigracao/ConfirmarMigracao';
import PacienteDestino from './PacienteDestino/PacienteDestino';
import PacienteOrigem from './PacienteOrigem/PacienteOrigem';

import './ModalNovaMigracao.scss';
type Props = DisclosureType & {
  initial: string;
  setReload: any;
};

export function ModalNovaMigracao({ isOpen, close, initial, ...props }: Props) {
  return (
    <Dialog
      maximizedTabletSize
      style={{ width: '95%' }}
      visible={isOpen}
      onHide={() => close()}
    >
      <MigracaoComp
        isOpen={isOpen}
        close={close}
        initial={initial}
        {...props}
      />
    </Dialog>
  );
}

const initialTab: any = {
  ORIGEM: 0,
  DESTINO: 1,
  CONFIRMACAO: 2,
};

export function MigracaoComp({ close, initial, setReload }: Props) {
  const [tabAtiva, setTabAtiva] = useState(initialTab[initial]);
  const [selectedOrigem, setSelectedOrigem] = useState<any>(null);
  const [selectedDestino, setSelectedDestino] = useState<any>(null);

  const handleMigrar = async () => {
    const params = {
      idOrigem: selectedOrigem.paciente?.id,
      idDestino: selectedDestino.paciente?.id,
    };

    const response = await MigraPEPAPI.postMigraPEP(params);

    if (response.status >= 200 && response.status < 300) {
      setReload((prev: number) => prev + 1);
      close();
    }
  };

  return (
    <div className="migracao-container">
      <Tab
        setActiveIndex={tabAtiva}
        onChange={setTabAtiva}
        clean
        values={[
          {
            label: 'Paciente origem',
            content: (
              <ErrorBoundary>
                <PacienteOrigem
                  setSelectedOrigem={setSelectedOrigem}
                  selectedOrigem={selectedOrigem}
                  selectedDestino={selectedDestino}
                />
              </ErrorBoundary>
            ),
          },
          {
            label: 'Paciente detino',
            content: (
              <ErrorBoundary>
                <PacienteDestino
                  setSelectedDestino={setSelectedDestino}
                  selectedDestino={selectedDestino}
                  selectedOrigem={selectedOrigem}
                />
              </ErrorBoundary>
            ),
          },
          {
            label: 'Confirmação da migração',
            content: (
              <ErrorBoundary>
                <ConfirmarMigracao
                  selectedDestino={selectedDestino}
                  selectedOrigem={selectedOrigem}
                />
              </ErrorBoundary>
            ),
          },
        ]}
      />
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        {tabAtiva !== 0 ? (
          <Button
            label="Voltar"
            btnType="outline"
            onClick={() => setTabAtiva(tabAtiva - 1)}
            stretch
          />
        ) : (
          <Button
            btnType="ghost"
            label="Fechar"
            onClick={() => close()}
            stretch
          />
        )}
        {tabAtiva !== 2 ? (
          <Button
            type="button"
            btnType="tonal"
            label="Proximo"
            onClick={() => setTabAtiva(tabAtiva + 1)}
            stretch
          />
        ) : (
          <Button
            type="button"
            label="Migrar"
            onClick={handleMigrar}
            disabled={!selectedOrigem || !selectedDestino}
            stretch
          />
        )}
      </div>
    </div>
  );
}
