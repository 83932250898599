import dayjs from 'dayjs';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  exameFisico: any;
}

function PanelContent({ exameFisico }: PanelContentProps) {
  const headerTextClassName = 'p-mb-2';

  return (
    <div className="pre-natal-panel-content p-grid">
      <div className="p-col-12 p-md-4 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Edema
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {exameFisico.edema}
        </SimpleText>
      </div>

      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{exameFisico.usuarioInclusao.nome}</b>{' '}
          {dayjs(exameFisico.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
}

export default PanelContent;
