import { useFormContext } from 'react-hook-form';

import { AnexoOpmeAPI } from 'src/APIs/ProntuarioAPI/AnexoOpmeAPI/AnexoOpmeAPI';

import { DesktopRow } from './DesktopRow';

interface RowProps {
  index: number;
  medicamento: any;
  onDelete: (index: number) => void;
}

export const Row = (props: RowProps) => {
  const { index, medicamento, onDelete } = props;

  const { unregister, getValues } = useFormContext();

  const handleDelete = async () => {
    try {
      const idAnexoOpmeServicoTuss = getValues(
        `medicamentosQuimioterapia.${index}.id`,
      );

      if (!!idAnexoOpmeServicoTuss)
        await AnexoOpmeAPI.deleteItemAnexoOpme(idAnexoOpmeServicoTuss);

      unregister(
        `medicamentosQuimioterapia.${index}.idServicoTussFornecedorQuimio`,
      );
      onDelete(index);
    } catch (error) {}
  };

  const commonProps = {
    medicamento,
    onDelete: handleDelete,
    idx: index,
  };

  return <DesktopRow {...commonProps} />;
};
