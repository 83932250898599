import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import MedicoAPI from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldEspecialidadeProps = DropdownProps & {
  name?: string;
  label: string;
  params: {
    idEspecialidade: string | number;
  };
};

export const FieldMedicoEspecialidade = ({
  name = 'medico',
  label,
  params,
  ...rest
}: FieldEspecialidadeProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [medicos, setMedicos] = useState<Medico[]>([]);
  const [loading, setLoading] = useState(false);

  const loadMedicos = useCallback(async () => {
    setLoading(true);
    if (params?.idEspecialidade)
      await MedicoAPI.loadMedicosByEspecialidade(params).then(setMedicos);
    setLoading(false);
  }, [params]);

  useEffect(() => {
    loadMedicos();
  }, [loadMedicos]);

  const medicosOptions = useMemo(() => {
    const options = (medicos || []).map((item: any) => ({
      label: item.nome,
      value: item,
    }));

    return options;
  }, [medicos]);

  return (
    <DropdownControlled
      className="p-col-12"
      panelClassName="panel-convenio"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={medicosOptions || []}
      errorMsg={errors?.[name]?.message}
      hideTextErrorSpace={false}
      filter={false}
      loading={loading}
      {...rest}
    />
  );
};
