import { memo, useCallback, useMemo } from 'react';

import { RadioButton } from 'primereact/radiobutton';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './ServicoTussSelected.scss';

interface ServicoTussSelectedProps {
  value: any[] | undefined;
  onChange(i: any[]): void;
}

interface SelectedList {
  servicoTussList: any[];
}

interface TitleTextProps {
  title: string;
}

interface ServicoTussSelectedItemProps {
  item: any;
  index: number;
  onClick(v: any): void;
  onClickPrincipal(v: any, i: number): void;
}

const ServicoTussSelected = (props: ServicoTussSelectedProps) => {
  const { value = [], onChange } = props;

  const list: SelectedList = useMemo(() => {
    const newValues = value.map(val => ({
      ...val,
    }));

    return newValues.reduce(
      (value: SelectedList, item) => {
        value.servicoTussList.push(item);

        return value;
      },
      { servicoTussList: [] },
    );
  }, [value]);

  const handleRemove = useCallback(
    (item: any) => {
      const items = value.filter(i => i.id !== item.id);
      onChange(items);
    },
    [onChange, value],
  );

  const handleSelectPrincipal = useCallback(
    (item: any) => {
      const newList = [...list?.servicoTussList];
      const itemEncontrado = newList.map((i: any) => ({
        ...i,
        principal: i.id === item.id ? true : false,
      }));

      list.servicoTussList.splice(0);
      list.servicoTussList.push(...itemEncontrado);

      onChange(list.servicoTussList);
    },
    [list],
  );

  return (
    <div className="ServicoTussSelected" style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 16 }}>
        <TitleText title="Adicionar serviço TUSS" />

        <div>
          {list?.servicoTussList?.length > 0 ? (
            <>
              {list?.servicoTussList?.map((item: any, index: number) => (
                <ServicoTussSelectedItem
                  key={`${item.nomeServicoTuss}-${item.id}`}
                  item={item}
                  index={index}
                  onClick={handleRemove}
                  onClickPrincipal={() => handleSelectPrincipal(item)}
                />
              ))}
            </>
          ) : (
            <EmptyText />
          )}
        </div>
      </div>
    </div>
  );
};

const TitleText = memo(({ title }: TitleTextProps) => {
  return (
    <SimpleText bold fontSize={FONT_SIZE.XXS} className="list-title">
      {title}
    </SimpleText>
  );
});

const EmptyText = memo(() => {
  return (
    <SimpleText
      fontSize={FONT_SIZE.XXXS}
      fontColor={FONT_COLOR.COLOR_60}
      className="search-list-empty"
    >
      Não ha cadastros, adicione novos clicando no botão abaixo
    </SimpleText>
  );
});

const ServicoTussSelectedItem = memo((props: ServicoTussSelectedItemProps) => {
  const { item, onClick, index, onClickPrincipal } = props;

  return (
    <div className="ServicoTussSelectedItem p-grid p-col-12 p-text-center p-text-sm-left">
      <div className="p-col-12 p-sm-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {item.codigo ?? item.codigoServicoTuss}
        </SimpleText>
      </div>

      <SimpleText
        className="p-col-12 p-sm-6"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {item.nomeServicoTuss ?? item.nome}
      </SimpleText>

      <div className="p-col-12 p-sm-4 p-d-flex p-flex-row p-jc-center p-jc-sm-flex-end p-ai-center">
        <RadioButton
          inputId="principal"
          value={item.principal}
          onChange={c => onClickPrincipal(c, index)}
          checked={item.principal}
        />

        <label className="label-status" htmlFor="principal">
          Principal
        </label>

        <Divider layout={'vertical'} />
        <Button
          btnType="gray"
          icon="fas fa-trash"
          onClick={() => onClick(item)}
        />
      </div>
    </div>
  );
});

export default memo(ServicoTussSelected);
