import React from 'react';

import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  vacina: any;
}

export const PanelContent = ({ vacina }: PanelContentProps) => {
  const headerTextClassName = `p-mb-2 ${
    vacina.status === 'INATIVO' ? 'vacina-inativa' : ''
  }`;
  const dataTextClassName =
    vacina.status === 'INATIVO' ? 'line-through vacina-inativa' : '';

  return (
    <div className="p-grid vacinas-panel-content">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Observações
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {vacina?.observacao}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data da aplicação
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {!!vacina.dataAplicacao &&
            dayjs(vacina.dataAplicacao)
              .locale('pt-br')
              .format('DD MMM[.] YYYY')}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        {vacina.aplicadaEmAtraso && (
          <>
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Motivo do atraso
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {vacina.motivoAtraso}
            </SimpleText>
          </>
        )}
      </div>
      {vacina?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {vacina.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{vacina.atendimento.medico.nome}</b> em{' '}
          {dayjs(vacina.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
};
