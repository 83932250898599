import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { ConvenioAuditoriaPayload } from 'src/APIs/AdminAPI/ConvenioAPI/@types/ConvenioAuditoria';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

type ConvenioAuditoriaData = Omit<ConvenioAuditoriaPayload, 'idConvenio'> & {
  id?: number;
};

export const ConfigTissAuditoria = () => {
  const { idConvenio } = useParams();

  const [isEditing, setIsEditing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      urlApiListaGuias: '',
      urlApiListaMensagens: '',
      urlApiEnvioMensagem: '',
      urlApiEnvioArquivo: '',
      urlApiDownloadArquivo: '',
    },
  });

  const handleSubmit = async (data: ConvenioAuditoriaData) => {
    try {
      setIsLoading(true);

      if (isEditing && data?.id) {
        return await ConvenioAPI.updateConvenioAuditoria(data.id, {
          ...data,
          idConvenio: Number(idConvenio),
        });
      }

      return await ConvenioAPI.sendConvenioAuditoria({
        ...data,
        idConvenio: Number(idConvenio),
      });
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (idConvenio) {
      setIsLoading(true);
      ConvenioAPI.loadConvenioAuditoria(Number(idConvenio))
        .then(items => {
          items
            ? form.reset({
                ...items,
              })
            : setIsEditing(false);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [form, idConvenio]);

  return (
    <div className="p-d-flex p-flex-column p-mt-2 p-gap-3">
      <SimpleText fontColor="color_60">
        Campos para o preenchimento do link das APIs de auditoria.
      </SimpleText>

      <FormProvider {...form}>
        <form id="form-auditoria" onSubmit={form.handleSubmit(handleSubmit)}>
          <fieldset
            className="p-w-100 p-d-flex p-flex-column p-gap-3 p-mb-2"
            disabled={isLoading}
          >
            <FormInput
              name="urlApiListaGuias"
              label="API lista de Guias"
              placeholder="Informe o link"
              hideTextErrorSpace
              iconPos="right"
              icon="fas fa-link"
            />
            <FormInput
              name="urlApiListaMensagens"
              label="API lista de mensagens"
              placeholder="Informe o link"
              hideTextErrorSpace
              iconPos="right"
              icon="fas fa-link"
            />
            <FormInput
              name="urlApiEnvioMensagem"
              label="API envio de mensagem"
              placeholder="Informe o link"
              hideTextErrorSpace
              iconPos="right"
              icon="fas fa-link"
            />
            <FormInput
              name="urlApiEnvioArquivo"
              label="API envio de arquivo"
              placeholder="Informe o link"
              hideTextErrorSpace
              iconPos="right"
              icon="fas fa-link"
            />
            <FormInput
              name="urlApiDownloadArquivo"
              label="API download de arquivo"
              placeholder="Informe o link"
              hideTextErrorSpace
              iconPos="right"
              icon="fas fa-link"
            />

            <div className="p-d-flex p-gap-2 p-mt-2">
              <Button
                btnType="ghost"
                label="Cancelar"
                onClick={() => navigate('/cadastros/convenio')}
                className="p-flex-1"
                disabled={isLoading}
              />
              <Button
                label="Salvar"
                type="submit"
                className="p-flex-2"
                disabled={isLoading}
              />
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </div>
  );
};
