import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import MobileRow from 'src/components/SearchList/MobileRow';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './HorarioAtendimento.scss';

interface Props {
  data: any;
  handleEdit: (data: any) => void;
  handleDuplicate: (data: any) => void;
  handleDelete: (data: any) => void;
}

const MobileRowHorarioAtendimento = ({
  data,
  handleDelete,
  handleDuplicate,
  handleEdit,
}: Props) => {
  const options = (
    <>
      <div className="p-grid">
        <>
          <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_ALTERAR">
            <div className="p-col-12">
              <Button
                btnType="ghost"
                label="Editar"
                onClick={() => handleEdit(data)}
              />
            </div>
          </Can>
          <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_CADASTRAR">
            <div className="p-col-12">
              <Button
                btnType="ghost"
                label="Duplicar"
                onClick={() => handleDuplicate(data)}
              />
            </div>
          </Can>
          <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_EXCLUIR">
            <div className="p-col-12">
              <Button
                btnType="ghost"
                label="Excluir"
                onClick={() => handleDelete(data)}
              />
            </div>
          </Can>
        </>
      </div>
    </>
  );

  return (
    <MobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Dia
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data.diaDaSemana}</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Hr. inicial
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data.horarioInicial}</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Hr. final
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data.horarioFinal}</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Duração
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data.intervalo} min</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Temporário
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {data.temp ? 'Sim' : 'Não'}
        </SimpleText>
        {data.temp && (
          <>
            <Tooltip target={`.info-temporario-${data.id}`} />
            <span
              className={`fas fa-info-circle p-ml-1 info-icon info-temporario-${data.id}`}
              data-pr-tooltip={`${data.dataPeriodoIni} até ${data.dataPeriodoFim}`}
              data-pr-position="top"
            />
          </>
        )}
      </div>
    </MobileRow>
  );
};

export default MobileRowHorarioAtendimento;
