import { useCallback } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import { TextInputSearchControlled } from 'src/components/Basics/TextInputSearch/TextInputSearchControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import PesquisaAvancada from './PesquisaAvancada';

const Filter = () => {
  const { isTablet, isLarge } = useSize();
  const useFormMethods = useForm();
  const { handleSubmit, control, watch, reset } = useFormMethods;
  const { setFilter } = useSearchList();

  const watchAllFields = watch();

  const resetFilter = useCallback(() => {
    reset();
  }, [reset]);

  const onSubmit = useCallback(
    (data: any) => {
      const { nome, cpf, numeroCartao, nomeMae, dataNascimento, codigo } = data;

      const formatField = (field: any) =>
        String(field)
          .replaceAll('.', '')
          .replaceAll('-', '')
          .replaceAll('_', '')
          .replaceAll(' ', '') || null;

      const payload: any = {
        ...(nome && { nome }),
        ...(nomeMae && { nomeMae }),
        ...(numeroCartao && { numeroCartao: formatField(numeroCartao) }),
        ...(cpf && { cpf: formatField(cpf) }),
        ...(dataNascimento && {
          dataNascimento: dayjs(new Date(dataNascimento)).format('YYYY-MM-DD'),
        }),
        ...(codigo && { codigo }),
      };

      setFilter(payload);
    },
    [setFilter],
  );

  return (
    <div className="p-d-flex p-flex-column p-flex-lg-row p-gap-1">
      <FormProvider {...useFormMethods}>
        <form
          className=" p-d-flex p-ai-end p-gap-1 p-flex-1 p-flex-wrap"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={`p-flex-grow ${
              isTablet ? '' : isLarge ? 'p-mb-1' : 'p-mb-2'
            }`}
          >
            <TextInputSearchControlled
              name="nome"
              placeholder="Pesquisar por nome do paciente"
            />
          </div>
          <div className="p-d-flex p-gap-1 p-ai-end p-mt-1 ">
            <MaskedInputControlled
              control={control}
              className=""
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              mask={MASK.CPF}
            />
            <FormInput
              className=""
              name="numeroCartao"
              label="Numero cartão"
              hideTextErrorSpace
              permitirApenasNumeros
            />
            <div className="p-d-flex p-ai-end">
              <Button type="submit" label="Pesquisar" btnType="tonal" stretch />
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="p-d-flex p-ai-end">
        <PesquisaAvancada
          defaultValues={watchAllFields}
          onSubmit={onSubmit}
          resetFilter={resetFilter}
        />
      </div>
    </div>
  );
};

export default Filter;
