import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/perfis';

export const loadPerfis = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetPerfilDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisar`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const getPerfilByIdEmpresa = (
  idEmpresa?: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<Perfil[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisar?idEmpresa=${idEmpresa}`,
    hideToast: true,
    ...options,
  });
};

export const loadPerfilById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Perfil> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendPerfil = (
  payload: PostPerfilDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostPerfilDTO> => {
  return Http.post({
    requestDescription: 'cadastro de um novo perfil',
    service: 'adm',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });
};

export const updatePerfil = (
  id: number,
  payload: PostPerfilDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostPerfilDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updatePerfilStatus = (
  id: number,
  payload: PostPerfilDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostPerfilDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/funcionalidade`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removePerfilById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

const PerfisAPI = {
  loadPerfis,
  loadPerfilById,
  sendPerfil,
  removePerfilById,
  updatePerfil,
  updatePerfilStatus,
  getPerfilByIdEmpresa,
};

export default PerfisAPI;
