import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import {
  ListaMateriaisPrescricao,
  ListaMedicamentoPrescricao,
  LoadPrescricao,
} from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import useSize from 'src/core/hooks/useSize';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';

type PanelContentProps = {
  refetch?: () => void;
  item: LoadPrescricao;
  view?: boolean;
  isExecutada: boolean;
  setItem: (v: LoadPrescricao) => void;
};

type ContentExecutadaProps = Pick<PanelContentProps, 'item' | 'view'>;

export const PanelContent = ({ item }: PanelContentProps) => {
  return <PanelContentExecutada view item={item} />;
};

const PanelContentExecutada = ({ item }: ContentExecutadaProps) => {
  const { isTablet } = useSize();

  const [materiais, setMateriais] = useState<ListaMateriaisPrescricao[]>(
    item?.prescricaoMateriais || [],
  );
  const [medicamentos, setMedicamentos] = useState<
    ListaMedicamentoPrescricao[]
  >(item?.prescricaoMedicamentos || []);

  const fetchMateriais = useCallback(() => {
    PrescricaoAPI.getPrescricaoMateriais(item.id).then(data => {
      setMateriais(data);
    });
  }, [item.id]);
  const fetchMedicamentos = useCallback(() => {
    PrescricaoAPI.getPrescricaoMedicamentos(item.id).then(data => {
      setMedicamentos(data);
    });
  }, [item.id]);

  useEffect(() => {
    Promise.all([fetchMateriais(), fetchMedicamentos()]);
  }, [fetchMateriais, fetchMedicamentos]);

  const isInativo = item.status === 'INATIVO';

  return (
    <div className={`prescricao-panel-content ${isInativo ? 'inativo' : ''}`}>
      <div className="p-d-flex p-gap-1 p-col-12 p-mb-1">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Data da ocorrência
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.PRIMARY}>
          {item.dataOcorrencia
            ? dayjs(item.dataOcorrencia).format('DD/MM/YYYY [às] HH:mm')
            : 'Nenhuma data registrada'}
        </SimpleText>
      </div>

      <SimpleText className="p-col-12" bold>
        Prescrição
      </SimpleText>

      {!!medicamentos.length &&
        medicamentos.map((medicamento, idx) => (
          <div key={idx} className="row p-d-flex p-ai-center p-col-12 p-p-0">
            <SimpleText className="p-col-4">{medicamento.descricao}</SimpleText>
            {!isTablet && (
              <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                  Posologia
                </SimpleText>
                <SimpleText>{item.posologia}</SimpleText>
              </div>
            )}
            {!isTablet && (
              <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>Via</SimpleText>
                <SimpleText>{item.via}</SimpleText>
              </div>
            )}
            <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
              <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                {medicamento.quantidade}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                className="p-text-truncate"
              >
                Data da aplicação
              </SimpleText>
              <SimpleText>
                {medicamento?.dataAplicacao
                  ? dayjs(medicamento.dataAplicacao).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </SimpleText>
            </div>
          </div>
        ))}

      <div className="p-col-12 p-d-flex p-ai-center p-p-0 p-mb-3">
        <SimpleText className="p-col-6 p-pb-0" bold>
          Materiais
        </SimpleText>
      </div>

      {!!materiais.length &&
        materiais.map((item, idx) => (
          <div key={idx} className="row p-d-flex p-ai-center p-col-12 p-p-0">
            <SimpleText className="p-col-8 p-text-climp-2">
              {item.nome}
            </SimpleText>
            <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
              <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                {item.quantidade}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                Data da aplicação
              </SimpleText>
              <SimpleText>
                {item.dataAplicacao
                  ? dayjs(item.dataAplicacao).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </SimpleText>
            </div>
          </div>
        ))}

      {item?.idEnfermeiroExecucao && (
        <>
          <SimpleText className="p-col-12 p-pb-0" bold>
            Atendido por
          </SimpleText>

          <div className="p-col-12 p-d-flex p-gap-1">
            <Avatar
              className="p-avatar-prescricao"
              label={item.nomeEnfermeiroExecucao}
            />
            <div className="row p-p-2 p-d-flex p-flex-column p-gap-1">
              <div className="p-d-flex p-flex-column">
                <div className="p-col-12 p-py-0 p-d-flex p-ai-center p-gap-1">
                  <SimpleText>{item.nomeEnfermeiroExecucao}</SimpleText>
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XXS}
                  >
                    ({item?.conselhoTissSigla} {item.numeroRegistro})
                  </SimpleText>
                </div>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                  className="p-col-12"
                >
                  {dayjs(item.dataOcorrencia).format(
                    '[Em] DD [de] MMM [de] YYYY [às] HH:mm ',
                  )}
                </SimpleText>
              </div>

              <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Observações
                </SimpleText>
                <SimpleText>{item?.observacao || '-'}</SimpleText>
              </div>
              <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Reações adversas
                </SimpleText>
                <SimpleText>{item?.reacaoAdversa || '-'}</SimpleText>
              </div>
              <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXXS}
                >
                  Justificativa para registro retroativo
                </SimpleText>
                <SimpleText>{item?.justificativaOcorrencia || '-'}</SimpleText>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
