import * as Yup from 'yup';

declare module 'yup' {
  interface ArraySchema<T> {
    requiredAllowEmpty<T>(
      isRequired?: boolean,
      message?: string,
    ): ArraySchema<any>;
  }
}

function arrayRequiredAllowEmpty<T>(
  this: Yup.ArraySchema<any>,
  isRequired = true,
  message?: string,
) {
  return this.test('field is required', message || '', function (value: any) {
    return isRequired ? Boolean(value.length) : true;
  });
}

Yup.addMethod(Yup.array, 'requiredAllowEmpty', arrayRequiredAllowEmpty);

const checkSomeRequired = (old: any, values: any): Yup.AnySchema => {
  const check = values._nodes.some((node: string) => {
    return Boolean(old[node].length);
  });

  const mapper = (oldValues: any, node: string) => {
    return {
      ...oldValues,
      [node]: Yup.array().requiredAllowEmpty(!check),
    };
  };

  return Yup.object().shape(values._nodes.reduce(mapper, {}));
};

export const formValidation = Yup.object()
  .shape({
    comunicadoDestinatarios: Yup.array().requiredAllowEmpty(),
    comunicadoEspecialidades: Yup.array().requiredAllowEmpty(),
    comunicadoConsultorios: Yup.array().requiredAllowEmpty(),
  })
  .when(checkSomeRequired);
