import { useContext } from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';

import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';

import './LoginLayout.scss';
import Support from './Support/Support';

interface LoginLayoutProps {
  content: JSX.Element | JSX.Element[];
  maxWidth: number;
  supportVisible?: boolean;
  onHideSupport?: any;
}

const LoginLayout = ({
  content,
  maxWidth,
  supportVisible,
  onHideSupport,
}: LoginLayoutProps) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Page className={`Login ${theme}`}>
      <>
        <div className={'p-grid login-content'}>
          <div
            className={
              'p-lg-6 p-col-12 p-col-align-center p-d-flex p-jc-center p-pt-3'
            }
          >
            <CustomImage src={'emed_logo_text.svg'} alt="LOGO_EMED" />
          </div>

          <div
            className={
              'p-lg-6 p-col-12 p-col-align-center p-d-flex p-jc-center'
            }
          >
            <ElevatedCard
              style={{ maxWidth }}
              className={'elevated-card p-d-flex p-ai-center'}
            >
              {content}
            </ElevatedCard>
          </div>
        </div>
        <div className={'footer p-grid'}>
          <div className={'p-col-6 p-pl-5 p-as-end'}>
            <CustomImage src={'sbis_2009.png'} alt="SBIS_2009" />
            <div>
              <SimpleText fontSize={FONT_SIZE.XXXS}>
                Versão 0.0.0 | EMEDBR Tecnologia e Saúde © 2021
              </SimpleText>
            </div>
          </div>
          <div className={'p-col-6 p-d-flex p-jc-end p-as-end p-pr-5'}>
            <CustomImage src={'logo_univision.png'} alt="LOGO_UNI" />
          </div>
        </div>
        {supportVisible && (
          <Support
            supportVisible={!!supportVisible}
            onHideSupport={onHideSupport}
          />
        )}
      </>
    </Page>
  );
};

export default LoginLayout;
