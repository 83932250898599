import { useState, useEffect, useCallback, useMemo } from 'react';

import PersonalizarProntuarioMedicoAPI from 'src/APIs/ProntuarioAPI/PersonalizarProntuarioMedicoAPI/PersonalizarProntuarioMedicoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import useDebounce from 'src/utils/useDebounce';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import CamposList from './CamposList/CamposList';

import './Campos.scss';

function Campos() {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { getCampos } = useAtendimento();

  const { isOpen, open, close } = useDisclosure({ opened: false });

  const [camposProntuario, setCamposProntuario] = useState<CampoProntuario[]>(
    [],
  );
  const [loadingAlteracao, setLoadingAlteracao] = useState<string | null>(null);
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);

  const loadCampos = useCallback(async () => {
    const response = await PersonalizarProntuarioMedicoAPI.getCampos();

    if (response?.status === 200) {
      const campos =
        response?.data?.filter?.(
          (campo: any) => campo.campo !== 'RECEITA_OCULOS',
        ) || [];
      setCamposProntuario(campos);
    }

    setLoadingInicial(false);
  }, []);

  useEffectSkipFirst(() => {
    if (isOpen) {
      setTimeout(() => {
        close();
      }, 1600);
    }
  }, [isOpen]);

  useEffect(() => {
    loadCampos();
  }, [loadCampos]);

  const onAlterarExibicao = useCallback(
    async (campo: string, tipoExibicao: string) => {
      setLoadingAlteracao(campo);

      const payload = {
        campo,
        tipoExibicao,
        idProfissionalSaude: agenda.profissionalAtivo.id,
      };

      const response =
        await PersonalizarProntuarioMedicoAPI.atualizarExibicaoCampo(payload);

      if (response?.status === 200) {
        open();

        await loadCampos();
        getCampos();
      }

      setLoadingAlteracao(null);
    },
    [agenda?.profissionalAtivo?.id, getCampos, loadCampos, open],
  );

  const getCamposProntuarioByAba = useCallback(
    (aba: string) =>
      camposProntuario.filter(camposProntuario => camposProntuario.aba === aba),
    [camposProntuario],
  );

  const abasCamposProntuario = useMemo(() => {
    const abas = [
      ...new Set(camposProntuario.map(campoProntuario => campoProntuario.aba)),
    ];

    return abas.map(aba => ({
      label: aba,
      content: (
        <CamposList
          campos={getCamposProntuarioByAba(aba)}
          onAlterarExibicao={onAlterarExibicao}
          loadingAlteracao={loadingAlteracao}
        />
      ),
    }));
  }, [
    camposProntuario,
    getCamposProntuarioByAba,
    loadingAlteracao,
    onAlterarExibicao,
  ]);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-d-flex">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          Ocultar e fixe campos do prontuário
        </SimpleText>

        {isOpen && (
          <div className="p-d-flex p-ai-center p-px-4">
            <i className="fas fa-check icon-check" />
            <SimpleText
              className="p-ml-2"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.PRIMARY}
              bold
            >
              Alterações salvas
            </SimpleText>
          </div>
        )}
      </div>
      <div className="p-col-12">
        <SimpleText className="p-mt-2" fontColor={FONT_COLOR.COLOR_60}>
          Otimize seu tempo e oculte os campos que você não utiliza durante o
          atendimento e fixe os campos que você mais utiliza para que eles sejam
          exibidos sempre que você iniciar o prontuário
        </SimpleText>
      </div>
      <div className="p-col-12">
        {loadingInicial ? (
          <GridListLoading />
        ) : (
          <VerticalTab tabItens={abasCamposProntuario} />
        )}
      </div>
    </div>
  );
}

export default Campos;
