import { useState } from 'react';

import { ProfissionalAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/ProfissionalAPI/ProfissionalAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import Can from 'src/components/Can/Can';

interface DropdownGrupoPontuacaoProps {
  profissional: any;
  pontuacao: any;
  pontuacaoOptions: any[];
}

const DropdownGrupoPontuacao = ({
  profissional,
  pontuacao,
  pontuacaoOptions,
}: DropdownGrupoPontuacaoProps) => {
  const [grupoSelecionado, setGrupoSelecionado] = useState<any>(
    pontuacao || null,
  );
  const [loading, setLoading] = useState(false);

  const { consultorios } = useAppSelector((state: RootState) => state);

  const idConsultorio = consultorios?.ativo?.id;

  const onChangeGrupo = async (e: any) => {
    setLoading(true);
    const response = await ProfissionalAPI.putGrupoPontuacaoProfissional(
      idConsultorio,
      profissional.usuario.id,
      e.value.id,
    );
    if (response.status >= 200 && response.status < 300) {
      setGrupoSelecionado(e.value);
    }
    return setLoading(false);
  };

  return (
    <Can
      checkPermission={[
        'ADM_CARTEIRA_APS_PROFISSIONAL_ALTERAR',
        'ADM_CARTEIRA_APS_PROFISSIONAL_CADASTRAR',
      ]}
    >
      {can => (
        <Dropdown
          className="dropdown-grupo"
          options={pontuacaoOptions}
          value={grupoSelecionado}
          optionLabel="nome"
          dataKey="id"
          loading={loading}
          onChange={e => onChangeGrupo(e)}
          disabled={!can}
        />
      )}
    </Can>
  );
};

export default DropdownGrupoPontuacao;
