import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/paciente-alergias';

export const loadAlergias = async (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<AlergiaDTO>> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/atendimento/${idAtendimento}`,
    hideToast: true,
    params,
    ...options,
  });
};

export const saveAlergia = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basepath}`,
    data: payload,
    return: true,
    ...options,
  });

export const updateAlergia = (
  idAlergia: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basepath}/${idAlergia}/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inactivateAlergia = async (
  idAlergia: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${idAlergia}/atendimento/${idAtendimento}/inativar`,
    data: payload,
    ...options,
  });
};

export const deleteAlergia = async (
  idAlergia: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> => {
  return Http.delete({
    service: 'pep',
    url: `${basepath}/${idAlergia}/atendimento/${idAtendimento}`,
    ...options,
  });
};

export const toggleLembreteAlergia = async (
  idAlergia: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> => {
  return Http.put({
    service: 'pep',
    url: `${basepath}/${idAlergia}/lembrete`,
    ...options,
  });
};

export const alterarSituacaoAlergia = async (
  idAlergia: number,
  idAtendimento: number,
  situacao: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basepath}/${idAlergia}/atendimento/${idAtendimento}/alterar-situacao`,
    params: { situacao },
    return: true,
    ...options,
  });

const AlergiaAPI = {
  loadAlergias,
  saveAlergia,
  updateAlergia,
  inactivateAlergia,
  deleteAlergia,
  toggleLembreteAlergia,
  alterarSituacaoAlergia,
};

export default AlergiaAPI;
