import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import { PesquisaPacienteAPI } from 'src/APIs/AgendaAPI/PesquisaPacienteAPI/PesquisaPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import FieldConvenio from '../components/FieldConvenio';
import FieldProcedimento from '../components/FieldProcedimento';
import FieldProfissionais from '../components/FieldProfissionais';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { useSelectedFaturamentos } from '../useSelectedFaturamentos';
import validationSchema from '../validationSchema';

const idTodos = -1;
const currentDate = new Date();
const pastDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);

const Filter = ({setFiltroPesquisa}: any) => {
  const [prevProfissionais, setPrevProfissionais] = useState<any[]>([]);
  const [profissionaisOptions, setProfissionaisOptions] = useState<any[]>([
    { nome: 'Todos', id: idTodos },
  ]);
  const [prevConvenios, setPrevConvenios] = useState<any[]>([]);
  const [conveniosListAPI, setConveniosListAPI] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>({});

  const { user, consultorios } = useAppSelector((state: RootState) => state);
  const { setFilter } = useSearchList();

  const { setSelectedFaturamentos } =
    useSelectedFaturamentos();


  const useFormMethods = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dataInicial: pastDate,
      dataFinal: currentDate,
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    setSelectedFaturamentos([]);
    
    if (data?.profissionais?.[0] === idTodos) {
      data.profissionais = profissionaisOptions
        .filter(item => item.id !== idTodos)
        .map(item => item.id);
    }

    if (data?.convenios?.[0] === idTodos) {
      data.convenios = conveniosListAPI
        .filter(item => item.id !== idTodos)
        .map(item => item.id);
    }

    const params = {
        nomePaciente: data?.nomePaciente || null,
        profissionais: data?.profissionais?.toString() || null,
        convenios: data?.convenios?.toString() || null,
        codigoProcedimento: data?.codigoProcedimento || null,
        dataInicial: dayjs(
          new Date(data?.dataInicial ? data?.dataInicial : pastDate),
        ).format('YYYYMMDD'),
        dataFinal: dayjs(
          new Date(
            data?.dataFinal ? data?.dataFinal : currentDate,
          ),
        ).format('YYYYMMDD'),
      };

    setFilter(params);
    setFiltroPesquisa(params);
  };

  const watchProfissional = watch('profissionais');
  const watchDataInicial = watch('dataInicial');
  const watchDataFinal = watch('dataFinal');
  const watchConvenio = watch('convenios');


  const isMedico = user?.tipoProfissionalSaude === 'MEDICO';

  useEffect(() => {
    setPrevConvenios(watchConvenio);

    if (
      !prevConvenios?.includes(-1) &&
      prevConvenios?.length > 0 &&
      watchConvenio?.includes(-1)
    ) {
      setValue('convenios', [-1]);
    }

    if (
      prevConvenios?.includes(-1) &&
      watchConvenio?.length > 1
    ) {
      const filterTodos = watchConvenio.filter(
        (id: any) => id !== -1,
      );
      setValue('convenios', filterTodos);
    }
  }, [prevConvenios, setValue, watchConvenio]);

  useEffect(() => {
    const getProfissionais = async () => {
      setLoading(true);

      const response = await PesquisaPacienteAPI.loadProfissionaisConsultorio(
        consultorios?.ativo?.id,
      );

      if (!Array.isArray(response?.data)) return;

      setProfissionaisOptions(prevData => [...prevData, ...response?.data]);

      setValue('profissionais', [idTodos]);

      setLoading(false);
    };

    if (isMedico) {
      setProfissionaisOptions([userInfo]);
      return setValue('profissionais', [userInfo?.id]);
    }

    if (consultorios?.ativo?.id) {
      getProfissionais();
    }
  }, [consultorios?.ativo?.id, isMedico, setValue, userInfo]);

  useEffect(() => {
    const getUserInfo = async () => {
      setLoading(true);
      const u = await UsuarioAPI.loadUsuarioById(user.idUsuario);

      setUserInfo(u);
      setLoading(false);
    };
    if (isMedico) {
      getUserInfo();
    }
  }, [isMedico, user.idUsuario]);

  useEffect(() => {
    setPrevProfissionais(watchProfissional);

    if (
      !prevProfissionais?.includes(idTodos) &&
      prevProfissionais?.length > 0 &&
      watchProfissional?.includes(idTodos)
    ) {
      setValue('profissionais', [idTodos]);
    }

    if (prevProfissionais?.includes(idTodos) && watchProfissional?.length > 1) {
      const filterTodos = watchProfissional?.filter(
        (id: number) => id !== idTodos,
      );
      setValue('profissionais', filterTodos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProfissional]);

  return (
    <FormProvider {...useFormMethods}>
    <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
      <div className="pesquisa p-grid p-colidTodos2">
        <div className="p-colidTodos2 p-md-6 p-lg-3">
          <FormInput
            name="nomePaciente"
            label="Nome do paciente"
            errorMsg={errors?.paciente?.message}
          />
        </div>

        <FieldProfissionais
          name="profissionais"
          label="Profissionais"
          className="p-colidTodos2 p-md-6 p-lg-3"
          options={profissionaisOptions}
          loading={loading}
          isMedico={isMedico}
        />

        <FieldConvenio
          name="convenios"
          label="Convênios"
          className="p-colidTodos2 p-md-6 p-lg-3"
          profissionais={profissionaisOptions}
          conveniosListAPI={conveniosListAPI}
          setConveniosListAPI={setConveniosListAPI}
        />

        <FieldProcedimento
          name="codigoProcedimento"
          label="Código do procedimento"
          className="p-colidTodos2 p-md-6 p-lg-3"
          conveniosListAPI={conveniosListAPI}
        />

        <div className="p-colidTodos2 p-md-6 p-lg-3">
          <CalendarInputControlled
            control={control}
            name="dataInicial"
            label="Data inicial"
            errorMsg={errors?.dataInicial?.message}
            maxDate={watchDataFinal}
            showIcon
          />
        </div>
        <div className="p-colidTodos2 p-md-6 p-lg-3">
          <CalendarInputControlled
            control={control}
            name="dataFinal"
            label="Data final"
            errorMsg={errors?.dataFinal?.message}
            maxDate={new Date()}
            minDate={watchDataInicial}
            showIcon
          />
        </div>
        <div className="p-col-2 p-d-flex p-ai-end">
          <Button
            className="p-mb-1 idTodos"
            type="submit"
            label="Pesquisar"
            icon="pi pi-search"
            btnType={BtnTypes.TONAL}
            loading={isSubmitting}
          />
        </div>
      </div>
    </form>
    </FormProvider>
  );
};

export default Filter;
