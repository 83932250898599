import React, { useCallback, useEffect, useState } from 'react';

import PesquisaProntuarioAPI from 'src/APIs/AgendaAPI/PesquisaProntuarioAPI/PesquisaProntuarioAPI';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { useAgenda } from '../AgendaContext';

import Filter from './Filter/Filter';
import Header from './Header';
import Row from './Row/Row';

import './PesquisaProntuario.scss';

const PesquisaProntuario = () => {
  const { togglePesquisaProntuario, isPesquisaProntuarioOpened } = useAgenda();
  const { windowInnerWidth } = useSize();

  const [reload, setReload] = useState(0);

  const fetchApi = useCallback(
    async ({ query, ...params }: any) => {
      try {
        if (!isPesquisaProntuarioOpened) return [];
        const response = await PesquisaProntuarioAPI.getPesquisaProntuario(
          params,
        );

        return response;
      } catch (error) {
        return [];
      }
    },
    [isPesquisaProntuarioOpened],
  );

  const renderRow = useCallback((data: any) => {
    return <Row data={data} />;
  }, []);
  const showHeader = windowInnerWidth >= 1253 ? true : false;

  return (
    <Page
      id="pesquisa-prontuario"
      className={'Agenda p-d-flex p-flex-column'}
      style={{ height: '100%' }}
    >
      <>
        <Button
          btnType="ghost"
          label="Voltar para a agenda"
          icon="fa fa-arrow-left"
          className="p-as-start p-mt-2 p-mx-4"
          onClick={() => togglePesquisaProntuario()}
        />
        <div className="p-d-flex p-flex-column p-gap-1 p-py-3 p-px-5 p-mb-2">
          <SimpleText fontSize={FONT_SIZE.MD}>
            Pesquisa de prontuário
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
            Procure o prontuário dos pacientes já atendidos no seu consultório
          </SimpleText>
        </div>

        <div className="container-pesquisa-prontuario">
          <div className="p-px-4 p-py-1 container-search-list">
            <SearchList.Root fetchApi={fetchApi}>
              <Filter />

              {showHeader ? <Header /> : <></>}

              <SearchList.InfiniteScroll
                className="p-mt-3"
                renderRow={renderRow}
                reload={reload}
              />
            </SearchList.Root>
          </div>
        </div>
      </>
    </Page>
  );
};

export default PesquisaProntuario;
