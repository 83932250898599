import { useRef } from 'react';

import { toast } from 'react-toastify';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';

interface BiodocDialogProps extends DisclosureType {
  cartao: string;
  nomePaciente: string;
  codigoServico?: string;
  tokenBiodoc: string;
}

const BiodocDialog = ({
  cartao,
  nomePaciente,
  codigoServico,
  tokenBiodoc,
  close,
}: BiodocDialogProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const biometriaUrl = process.env.REACT_APP_BIOMETRIA_URL;

  if (!tokenBiodoc || !biometriaUrl) {
    toast(<Toast />, {
      data: {
        title: 'Erro',
        message: 'Falha ao iniciar a biometria facial.',
        type: 'error',
      },
    });
    close();
  }

  return (
    <Dialog
      header="Autorização com biometria facial"
      onHide={close}
      visible={true}
      style={{ width: '90vw', height: '90vh' }}
      maximizedTabletSize
    >
      <iframe
        ref={iframeRef}
        src={
          `https://web.sandbox.biodoc.com.br/#/integration/verify?card=${cartao}&name=${nomePaciente}&token=${tokenBiodoc}&url=${biometriaUrl}` +
          (codigoServico ? `&procedure=${codigoServico}` : '')
        }
        allow="camera"
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </Dialog>
  );
};

export default BiodocDialog;
