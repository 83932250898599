import { useCallback, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Page } from '../_components/Page/Page';
import { PageHeader } from '../_components/Page/PageHeader';
import { FieldProfissionalAtendimentos } from '../ReceitasOrientacoes/components/FieldProfissionalAtendimentos';
import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import {
  SolicitacoesResultadosExamesRow,
  SolicitacoesResultadosExamesRowMobile,
} from './SolicitacoesResultadosExamesRow';

import './SolicitacoesResultadosExames.scss';

const items: any = {
  descricaoProcedimento: 'Descrição',
  codigoProcedimento: 'Código TUSS',
  nomeProfissionalSaude: 'Profissional',
  quantidade: 'Quantidade',
  resultadoInformado: 'Resultado',
  dataSolicitacao: 'Data de solicitação',
};

export const SolicitacoesResultadosExames = () => {
  const { isMobile } = useSize();

  const [refetch, setRefetch] = useState(0);
  const [params, setParams] = useState({});
  const [isPrinting, setPrinting] = useState(false);
  const [hasItems, setHasItems] = useState(false);

  const fetchAPI = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ query, sortBy = 'dataSolicitacao', ...rest }: any) => {
      setParams(rest);
      try {
        const data = await SolicitacoesProcedimentosAPI.loadSolcitacoesPortal(
          { ...rest, sortBy },
          {
            throwError: true,
          },
        );
        if (data.list.length > 0) {
          setHasItems(true);
        } else {
          setHasItems(false);
        }
        return data;
      } catch {
        return { list: [] };
      }
    },
    [],
  );

  const handleReload = () => {
    setRefetch(prev => prev + 1);
  };

  const handlePrint = () => {
    setPrinting(true);

    RelatoriosAPI.postRelatorioPortalSolicitacoesProcedimentos(params, {
      responseType: 'arraybuffer',
      throwError: true,
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => setPrinting(false));
  };

  return (
    <Page>
      <>
        <PageHeader
          title="Minhas solicitações e resultado de exames"
          subtitle="Aqui estão os resultados e solicitações de exames"
          backButton
        />

        <Page white content id="SolicitacoesResultadosExames">
          <SearchList.Root fetchApi={fetchAPI}>
            <SolicitacoesResultadosExamesHeader />

            <SearchList.BorderContainer>
              <div className="p-d-flex p-jc-end p-ai-center p-pb-4 p-gap-2">
                <Button
                  btnType="tonal"
                  label="Imprimir"
                  icon="fas fa-print"
                  onClick={handlePrint}
                  loading={isPrinting}
                  disabled={!hasItems}
                />
                <SolicitacoesResultadosExamesOrderBy />
              </div>

              <SearchList.InfiniteScroll
                reload={refetch}
                renderRows={items =>
                  items.map((item: any) =>
                    isMobile ? (
                      <SolicitacoesResultadosExamesRowMobile
                        item={item}
                        reload={handleReload}
                      />
                    ) : (
                      <SolicitacoesResultadosExamesRow
                        item={item}
                        reload={handleReload}
                      />
                    ),
                  )
                }
              />
            </SearchList.BorderContainer>
          </SearchList.Root>
        </Page>
      </>
    </Page>
  );
};

const SolicitacoesResultadosExamesHeader = () => {
  const { setFilter } = useSearchList();
  const form = useForm();

  const onSubmit = (v: any) => {
    const dataInicial = v.periodo?.[0]
      ? dayjs(v.periodo[0]).format('YYYY-MM-DD')
      : null;
    const dataFinal = v.periodo?.[1]
      ? dayjs(v.periodo[1]).format('YYYY-MM-DD')
      : null;

    const payload = {
      idProfissionalSaude: v.profissionalAtendimentos?.id,
      descricao: v.descricao,
      dataInicial,
      dataFinal,
    } as any;

    setFilter(payload);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-grid p-d-flex p-col-12 p-ai-center p-px-0 p-mt-2"
      >
        <FormInput
          name="descricao"
          icon="fas fa-search"
          placeholder="Pesquise por descrição"
          iconPos="left"
          className="p-col-6 p-md-4 input-search"
          hideTextErrorSpace
        />
        <FieldProfissionalAtendimentos
          placeholder="Selecione um profissional"
          className="p-col-6 p-md-3"
          showClear
        />
        <CalendarInputControlled
          control={form.control}
          name="periodo"
          placeholder="dd/mm/aaaa até dd/mm/aaaa"
          className="p-col-6 p-md-4"
          selectionMode="range"
          maxDate={new Date()}
          showIcon
        />
        <div className="p-col-1">
          <Button
            label="pesquisar"
            type="submit"
            btnType="tonal"
            style={{
              maxHeight: '30px',
            }}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const SolicitacoesResultadosExamesOrderBy = () => {
  const { setFilter, filter } = useSearchList();

  const [sortBy, setSortBy] = useState('dataSolicitacao');

  const itemsOrdenation: MenuItem[] = [
    { label: 'descrição', command: () => setSortBy('descricaoProcedimento') },
    { label: 'código TUSS', command: () => setSortBy('codigoProcedimento') },
    {
      label: 'profissional',
      command: () => setSortBy('nomeProfissionalSaude'),
    },
    { label: 'quantidade', command: () => setSortBy('quantidade') },
    { label: 'resultado', command: () => setSortBy('resultadoInformado') },
    {
      label: 'data de solicitação',
      command: () => setSortBy('dataSolicitacao'),
    },
  ];

  useEffectSkipFirst(() => {
    setFilter({ ...filter, sortBy });
  }, [sortBy]);

  return (
    <div className="p-d-flex p-jc-end p-ai-center">
      <SimpleText>Ordenar por:</SimpleText>
      <ButtonMenu
        label={items[sortBy]}
        btnType="green-link"
        model={itemsOrdenation}
      />
    </div>
  );
};
