import React from 'react';

import { useFormContext } from 'react-hook-form';

import { useAGA } from 'src/core/hooks/AGA/useAGA';

import { Button } from 'src/components/_UI/Button';

const FooterFormulario = () => {
  const { prevCurrentStep, currentStep, ultimoStep } = useAGA();
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Anterior"
        onClick={prevCurrentStep}
        disabled={currentStep === 0}
        stretch
        loading={isSubmitting}
      />
      {!ultimoStep ? (
        <Button
          type="submit"
          btnType="tonal"
          label="Salvar e avançar"
          loading={isSubmitting}
          stretch
        />
      ) : (
        <Button
          type="submit"
          btnType="tonal"
          label="Salvar e ver diagnostico"
          loading={isSubmitting}
          stretch
        />
      )}
    </div>
  );
};

export default FooterFormulario;
