import React, { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  impresso: any;
  onInativar: (impresso: any) => void;
  onVisualizarInativacao: (impresso: any) => void;
  isCollapsed: boolean;
  onToggle: () => void;
}

export default function PanelHeader({
  impresso,
  onInativar,
  onVisualizarInativacao,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  const menuEllipsis = useRef<TieredMenu>(null);

  const menuItems = [
    {
      label: 'Inativar',
      command: () => onInativar(impresso),
    },
  ];

  const headerTextClassName =
    impresso.status === 'INATIVO' ? 'impresso-inativo' : '';

  const dataTextClassName = `p-mt-2 ${
    impresso.status === 'INATIVO' ? 'line-through impresso-inativo' : ''
  }`;

  return (
    <div className="p-grid p-d-flex p-ai-center">
      <div className="p-col-7 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {impresso.usuario.nome}
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          Tipo documento:{' '}
          <b>{impresso.tipoImpresso.descricao || 'Não informado'}</b>
        </SimpleText>
      </div>
      <div className="p-col-3 p-d-flex p-flex-column p-ai-end">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de Ocorrência
        </SimpleText>

        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {dayjs(impresso.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-jc-end p-ai-center">
        <Button
          icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
          onClick={onToggle}
          btnType="ghost"
        />
        {impresso.status === 'ATIVO' ? (
          <>
            <Button
              btnType="gray"
              icon="fas fa-ellipsis-h"
              onClick={event => menuEllipsis?.current?.toggle(event)}
              disabled={
                impresso.idUsuarioAtualizacao !== agenda?.profissionalAtivo?.id
              }
            />

            <TieredMenu
              className="options-editar-paciente-cartoes"
              ref={menuEllipsis}
              model={menuItems}
              popup
            />
          </>
        ) : (
          <Button
            icon="fas fa-eye"
            onClick={() => onVisualizarInativacao(impresso)}
            btnType="gray"
          />
        )}
      </div>
    </div>
  );
}
