import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { useNovoAgendamento } from '../../hooks/NovoAgendamentoContext';

import './Dialog.scss';

type IProps = DisclosureType & {
  handleAgendar: () => void;
};

export const DialogPacienteNaoElegivel = ({ close, handleAgendar }: IProps) => {
  const { setIsSubmitting, resetElegibilidade } = useNovoAgendamento();

  return (
    <Dialog
      id="DialogPacienteNaoElegivel"
      visible
      onHide={close}
      header="Paciente não elegível"
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-3 p-w-100">
        <SimpleText className="p-col-12 p-px-0 p-line-height-2">
          Você deseja agendar o paciente sem a elegíbilidade TISS?
        </SimpleText>

        <div className="p-d-flex p-gap-2 p-w-100">
          <Button
            label="Não, voltar"
            btnType="ghost"
            onClick={() => [
              close(),
              setIsSubmitting(false),
              resetElegibilidade(),
            ]}
            className="p-col-4"
          />
          <Button
            label="Sim, agendar sem elegíbilidade"
            btnType="danger"
            className="p-col-8"
            onClick={() => [close(), handleAgendar()]}
          />
        </div>
      </div>
    </Dialog>
  );
};
