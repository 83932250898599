import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <div id="convenio-list-header" className="p-grid p-my-1">
      <SimpleText
        className="p-col-2 p-lg-1"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Código
      </SimpleText>
      <SimpleText
        className="p-col-4 p-lg-6 p-xl-8"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_60}
      >
        Convênio
      </SimpleText>

      <div className="p-col  p-d-flex p-jc-end">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mr-2"
        >
          Ações
        </SimpleText>
      </div>
    </div>
  );
};
