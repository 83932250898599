import { useCallback } from 'react';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  data: any;
  onDelete: (data: any) => void;
  onSwitchStatus: (data: any) => void;
  onEspecialidades: (data: any) => void;
  onConsultorios: (data: any) => void;
  especialidadesLength: number;
  consultoriosLength: number;
}

export const Row = (props: RowProps) => {
  const {
    data,
    onDelete,
    onSwitchStatus,
    onEspecialidades,
    onConsultorios,
    especialidadesLength,
    consultoriosLength,
  } = props;
  const { isTablet } = useSize();

  const getEspecialidadesLabel = useCallback(
    (especialidadesModeloLength: number) => {
      if (especialidadesModeloLength === especialidadesLength) return 'Todas';
      return `${especialidadesModeloLength} Especialidades`;
    },
    [especialidadesLength],
  );

  const getConsultoriosLabel = useCallback(
    (consultoriosModeloLength: number) => {
      if (consultoriosModeloLength === consultoriosLength) return 'Todos';
      return `${consultoriosModeloLength} Consultórios`;
    },
    [consultoriosLength],
  );

  const renderBtnEspecialidades = () => {
    const disabled =
      !data?.especialidades?.length ||
      data.tipoModelo === 'PADRAO' ||
      data?.especialidades?.length === especialidadesLength;

    return (
      <Button
        className="modal-button"
        btnType="green-link"
        label={getEspecialidadesLabel(data?.especialidades?.length || 0)}
        onClick={() => onEspecialidades(data)}
        disabled={disabled}
      />
    );
  };

  const renderBtnConsultorios = () => {
    const disabled =
      !data?.consultorios?.length ||
      data.tipoModelo === 'PADRAO' ||
      data?.consultorios?.length === consultoriosLength;

    return (
      <Button
        className="modal-button"
        btnType="green-link"
        label={getConsultoriosLabel(data?.consultorios?.length || 0)}
        onClick={() => onConsultorios(data)}
        disabled={disabled}
      />
    );
  };

  const cumonProps = {
    data,
    onSwitchStatus,
    onDelete,
    btnEspecialidades: renderBtnEspecialidades(),
    btnConsultorios: renderBtnConsultorios(),
  };

  return isTablet ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
