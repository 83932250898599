import { useCallback, useContext, useEffect, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import ConfiguracaoGuiaTissAPI from 'src/APIs/ConfigAPI/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissAPI';

import ThemeContext from 'src/core/themes/ThemeContext';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import FormServicoTuss from './FormServicoTuss/FormServicoTuss';
import { validationSchema } from './validationSchema';

import './AddConfiguracaoGuiaTiss.scss';

const AddConfiguracaoGuiaTiss = () => {
  const navigate = useNavigate();
  const { idConfigTiss } = useParams();

  const theme = useContext(ThemeContext);

  const [carregandoDados, setCarregandoDados] = useState<boolean>(false);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useFormMethods;

  const convenio = watch('convenio');

  const [configuracaoGuiaTissSelecionada, setConfiguracaoGuiaTissSelecionada] =
    useState({} as any);
  const [listaServicosTussSelecionados, setListaServicosTussSelecionados] =
    useState<any[]>([]);

  const loadConfigGuiaTiss = useCallback(async () => {
    if (idConfigTiss) {
      setCarregandoDados(true);

      const idConfigGuiaTiss = parseInt(idConfigTiss);

      const configGuiaTissEncontrada: ConfiguracaoGuiaTiss =
        await ConfiguracaoGuiaTissAPI.loadConfiguracaoGuiaTissById(
          idConfigGuiaTiss,
        );

      configGuiaTissEncontrada.servicoTussOutDtoList?.forEach((item: any) => {
        item.idServicoTuss = item.id;
      });

      setValue('descricao', configGuiaTissEncontrada.descricao);
      setValue('convenio', configGuiaTissEncontrada.convenio);
      setValue(
        'guiaTissServicoTussInDtoList',
        configGuiaTissEncontrada.servicoTussOutDtoList,
      );

      setListaServicosTussSelecionados(
        configGuiaTissEncontrada.servicoTussOutDtoList!,
      );

      setConfiguracaoGuiaTissSelecionada(configGuiaTissEncontrada);

      setCarregandoDados(false);
    }
  }, [idConfigTiss, setValue]);

  useEffect(() => {
    loadConfigGuiaTiss();
  }, [loadConfigGuiaTiss]);

  const handleServiceTuss = useCallback(
    serv => {
      setListaServicosTussSelecionados(serv);
      const servicosTussFormatados = serv.map((s: any) => ({
        idServicoTuss: s.id,
      }));

      setValue('guiaTissServicoTussInDtoList', servicosTussFormatados);
    },
    [setValue],
  );

  const saveConfiguracaoTiss = (payload: any) => {
    if (idConfigTiss) {
      return ConfiguracaoGuiaTissAPI.updateConfiguracaoGuiaTiss(
        Number(idConfigTiss),
        payload,
      );
    }

    return ConfiguracaoGuiaTissAPI.sendConfiguracaoGuiaTiss(payload);
  };

  const handleAddConfiguracaoTiss = async (data: any) => {
    const payload = {
      ...data,
      idConvenio: data.convenio.id,
    };

    const response: any = await saveConfiguracaoTiss(payload);

    if (response?.status === 200 || response?.status === 201) {
      handleGoBack();
    }
  };

  const handleGoBack = () => {
    navigate('/config/config-tiss', { replace: true });
  };

  if (carregandoDados) {
    return (
      <Page className={`container Config ${theme?.theme || ''}`} white>
        <>
          <PageHeader
            title={idConfigTiss ? 'Editar guia TISS' : 'Nova guia TISS'}
            subtitle={''}
          />

          <div className="p-p-5">
            <GridListLoading />
          </div>
        </>
      </Page>
    );
  }

  return (
    <Page className={`container Config ${theme?.theme || ''}`} white>
      <>
        <PageHeader
          title={idConfigTiss ? 'Editar guia TISS' : 'Nova guia TISS'}
          subtitle={''}
        />

        <div className="modal-body-guia-tiss p-col-12">
          <FormProvider {...useFormMethods}>
            <form
              className="p-col-12 p-md-8 p-xl-6 p-grid p-p-0"
              onSubmit={handleSubmit(handleAddConfiguracaoTiss)}
              defaultValue={configuracaoGuiaTissSelecionada}
            >
              <TextInputControlled
                control={control}
                label="Descrição"
                className="p-col-12 p-mt-3 p-mb-3"
                errorMsg={errors.descricao?.message}
                name="descricao"
              />

              <FieldConvenio
                name="convenio"
                label="Convênio"
                disabled={!!idConfigTiss}
              />

              {!!convenio ? (
                <FormServicoTuss
                  onSubmit={handleServiceTuss}
                  values={listaServicosTussSelecionados as any}
                  idConvenio={convenio.id}
                />
              ) : null}

              <div className="p-pt-2 p-pb-4">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.DANGER}
                  bold
                >
                  {errors.guiaTissServicoTussInDtoList?.message}
                </SimpleText>
              </div>

              <div className="p-col-12 p-md-4">
                <Button
                  btnType="ghost"
                  label="Cancelar"
                  className="p-col-align-center p-d-flex"
                  onClick={handleGoBack}
                  loading={isSubmitting}
                  stretch
                />
              </div>
              <div className="p-col-12 p-md-8">
                <Button
                  label="Salvar"
                  type="submit"
                  loading={isSubmitting}
                  stretch
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </>
    </Page>
  );
};

export default AddConfiguracaoGuiaTiss;
