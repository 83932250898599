import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
}

export const Row = (props: RowProps) => {
  const { data, onEdit, onDelete } = props;
  const { isMobile } = useSize();

  const renderConsultorios = () => {
    return (
      <div>
        {data?.todosConsultorios ? (
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_16}>
            Todos
          </SimpleText>
        ) : (
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_16}>
            {data?.consultorios?.map((i: any, idx: any) => [
              !!idx && ', ',
              <span key={idx}>{i.nome}</span>,
            ])}
          </SimpleText>
        )}
      </div>
    );
  };

  const cumonProps = {
    data,
    onEdit,
    onDelete,
    consultorios: renderConsultorios(),
  };

  return isMobile ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
