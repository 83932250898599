import { useState } from 'react';

import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

import { validationSchema } from './validationSchema';

import './DialogEnviarOperadoraOcorrencia.scss';

type IProps = DisclosureType & {
  customMensage?: string;
  onSuccess: () => void;
};

export const DialogEnviarOperadoraOcorrencia = ({
  close,
  state: stateProp,
  onSuccess,
}: IProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pendencias = stateProp.map((procedimento: any) => ({
    idSolicitacaoProcedimento: procedimento.idSolicitacaoProcedimento,
    descricao: procedimento.descricaoProcedimento,
    showIndicacao: procedimento.pendencia?.necessitaIndicacaoClinica,
    showJustificativa: procedimento.pendencia?.necessitaJustificativa,
    ...(procedimento.pendencia?.necessitaJustificativa && {
      justificativa: '',
    }),
    ...(procedimento.pendencia?.necessitaIndicacaoClinica && {
      indicacaoClinica: '',
    }),
    isJustificativaRequired: !!procedimento.pendencia?.necessitaJustificativa,
    isIndicacaoRequired: !!procedimento.pendencia?.necessitaIndicacaoClinica,
  }));

  const useFormMethods = useForm({
    defaultValues: {
      pendencias,
    },
    resolver: yupResolver(validationSchema),
  });

  const { control, setValue, handleSubmit } = useFormMethods;

  const { fields } = useFieldArray({
    control,
    name: 'pendencias',
  });

  const onSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);

      let { pendencias } = data;

      const ocorrenciaWithAplicarTodasJustificativas = pendencias.find(
        (pendencia: any) =>
          pendencia.flagAplicarJustificativaTodosProcedimentos,
      );
      const ocorrenciaWithAplicarTodasIndicacoes = pendencias.find(
        (pendencia: any) =>
          pendencia.flagAplicarIndicacaoClinicaTodosProcedimentos,
      );

      if (!!ocorrenciaWithAplicarTodasJustificativas) {
        pendencias = pendencias.map((pendencia: any) => ({
          ...pendencia,
          justificativa: ocorrenciaWithAplicarTodasJustificativas.justificativa,
        }));
      }

      if (!!ocorrenciaWithAplicarTodasIndicacoes) {
        pendencias = pendencias.map((pendencia: any) => ({
          ...pendencia,
          indicacaoClinica:
            ocorrenciaWithAplicarTodasIndicacoes.indicacaoClinica,
        }));
      }

      await SolicitacoesProcedimentosAPI.responderPendencias(
        Number(idAtendimento),
        pendencias,
        { throwError: true },
      );

      onSuccess();
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      id="dialog-enviar-operadora-ocorrencia"
      visible
      onHide={close}
      header="Exames e cirurgias selecionados"
      maximizedTabletSize
    >
      <>
        <SimpleText medium>
          Os procedimentos e/ou exames solicitados tem histórico recente ou
          necessitam de algum preenchimento obrigatório.
        </SimpleText>

        <Card type={CARD_TYPES.BORDER} className="p-mt-3">
          <FormProvider {...useFormMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-d-flex p-flex-column p-gap-3">
                {fields.map((procedimento: any, index) => (
                  <ProcedimentoPendencia
                    key={procedimento.id}
                    index={index}
                    idSolicitacaoProcedimento={
                      procedimento.idSolicitacaoProcedimento
                    }
                    descricao={procedimento.descricao}
                    showJustificativa={procedimento.showJustificativa}
                    showIndicacao={procedimento.showIndicacao}
                    onChangeFlagJustificativa={(checked: boolean) =>
                      fields.filter((field: any, index) => {
                        if (
                          field.idSolicitacaoProcedimento !==
                          procedimento.idSolicitacaoProcedimento
                        )
                          return setValue(
                            `pendencias.${index}.isJustificativaRequired`,
                            checked ? false : field.showJustificativa,
                          );

                        if (!checked)
                          setValue(
                            `pendencias.${index}.isJustificativaRequired`,
                            field.showJustificativa,
                          );
                      })
                    }
                    onChangeFlagIndicacao={(checked: boolean) =>
                      fields.filter((field: any, index) => {
                        if (
                          field.idSolicitacaoProcedimento !==
                          procedimento.idSolicitacaoProcedimento
                        )
                          return setValue(
                            `pendencias.${index}.isIndicacaoRequired`,
                            checked ? false : field.showIndicacao,
                          );

                        if (!checked)
                          setValue(
                            `pendencias.${index}.isIndicacaoRequired`,
                            field.showIndicacao,
                          );
                      })
                    }
                  />
                ))}

                <Button
                  type="submit"
                  label="Enviar para operadora"
                  stretch
                  loading={isSubmitting}
                />
              </div>
            </form>
          </FormProvider>
        </Card>
      </>
    </Dialog>
  );
};

const ProcedimentoPendencia = (props: any) => {
  const {
    idSolicitacaoProcedimento,
    descricao,
    showJustificativa,
    showIndicacao,
    index,
    onChangeFlagJustificativa,
    onChangeFlagIndicacao,
  } = props;
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const pendencias = watch('pendencias');

  const ocorrenciaWithflagAplicarJustificativaTodosProcedimentos =
    pendencias.find(
      (pendencia: any) => pendencia.flagAplicarJustificativaTodosProcedimentos,
    );
  const ocorrenciaWithflagAplicarIndicacaoClinicaTodosProcedimentos =
    pendencias.find(
      (pendencia: any) =>
        pendencia.flagAplicarIndicacaoClinicaTodosProcedimentos,
    );

  const aplicarJustificativaTodosProcedimentos =
    !!ocorrenciaWithflagAplicarJustificativaTodosProcedimentos;
  const isProcedimentoAplicarJustificativaTodos =
    ocorrenciaWithflagAplicarJustificativaTodosProcedimentos?.idSolicitacaoProcedimento ===
    idSolicitacaoProcedimento;

  const aplicarIndicacaoTodosProcedimentos =
    !!ocorrenciaWithflagAplicarIndicacaoClinicaTodosProcedimentos;
  const isProcedimentoAplicarIndicacaoTodos =
    ocorrenciaWithflagAplicarIndicacaoClinicaTodosProcedimentos?.idSolicitacaoProcedimento ===
    idSolicitacaoProcedimento;

  if (
    aplicarJustificativaTodosProcedimentos &&
    aplicarIndicacaoTodosProcedimentos &&
    !isProcedimentoAplicarJustificativaTodos &&
    !isProcedimentoAplicarIndicacaoTodos
  )
    return (
      <Card type={CARD_TYPES.GRAY_WITH_BORDER} className="p-grid">
        <SimpleText className="p-col-12" medium>
          {descricao}
        </SimpleText>
      </Card>
    );

  return (
    <Card type={CARD_TYPES.GRAY_WITH_BORDER} className="p-grid">
      <SimpleText className="p-col-12" medium>
        {descricao}
      </SimpleText>

      <Card className="p-col-12 p-mt-2" type={CARD_TYPES.BORDER}>
        {((showIndicacao && !aplicarIndicacaoTodosProcedimentos) ||
          (aplicarIndicacaoTodosProcedimentos &&
            isProcedimentoAplicarIndicacaoTodos)) && (
          <div className="p-col-12">
            <FormInput
              name={`pendencias.${index}.indicacaoClinica`}
              label="Indicação clínica"
              hideTextErrorSpace
            />

            <CheckboxControlled
              className="p-mt-2"
              control={control}
              name={`pendencias.${index}.flagAplicarIndicacaoClinicaTodosProcedimentos`}
              label="Aplicar a indicação clínica para todos os procedimentos"
              onChangeCustom={onChangeFlagIndicacao}
            />
          </div>
        )}

        {((showJustificativa && !aplicarJustificativaTodosProcedimentos) ||
          (aplicarJustificativaTodosProcedimentos &&
            isProcedimentoAplicarJustificativaTodos)) && (
          <div className="p-col-12">
            <TextareaInputControlled
              control={control}
              name={`pendencias.${index}.justificativa`}
              errorMsg={errors.pendencias?.[index]?.justificativa?.message}
              label="Justificativa"
            />

            <CheckboxControlled
              className="p-mt-2"
              control={control}
              name={`pendencias.${index}.flagAplicarJustificativaTodosProcedimentos`}
              label="Aplicar a justificativa para todos os procedimentos"
              onChangeCustom={onChangeFlagJustificativa}
            />
          </div>
        )}
      </Card>
    </Card>
  );
};
