import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import DocumentosAPI from 'src/APIs/ProntuarioAPI/DocumentosAPI/DocumentosAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';
import UploadFile from 'src/components/UploadFile/UploadFile';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

interface ModalNovoProps {
  visible: boolean;
  onHide: () => void;
  reloadList: () => void;
  editDocumento: Documentos | null;
}

const ModalNovo = ({
  visible,
  onHide,
  editDocumento,
  reloadList,
}: ModalNovoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [selectedFile, setSelectedFile] = useState<any>();

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    nomeDocumento: Yup.string().trim().required('informe o nome do documento'),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(
        editDocumento?.dataInclusao,
      ).max(400, 'O camp atual não deve possuir mais do que 400 caracteres.'),
    descricao: Yup.string().trim().required('O campo descrição é obrigatório.'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const handleEdit = useCallback(async () => {
    if (editDocumento) {
      setValue(
        'justificativaOcorrenciaRetroativa',
        editDocumento?.justificativaOcorrenciaRetroativa,
      );
      setValue('nomeDocumento', editDocumento.nomeDocumento);
      setValue('descricao', editDocumento.descricao);
    }
  }, [editDocumento, setValue]);

  useEffect(() => {
    handleEdit();
  }, [editDocumento, handleEdit]);

  const onSubmit = async (data: any) => {
    // setLoading(true);
    const formData = new FormData();
    formData.append('idAtendimento', idAtendimento);
    formData.append('nomeDocumento', data.nomeDocumento);
    formData.append('descricao', data.descricao);
    formData.append(
      'justificativaOcorrenciaRetroativa',
      data.justificativaOcorrenciaRetroativa,
    );

    formData.append(
      'dataOcorrencia',
      dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
    );

    let response: any;

    if (!selectedFile) {
      toast(<Toast />, {
        data: {
          title: 'Arquivo Obrigatório',
          message: 'Por favor selecione um arquivo',
          type: 'warning',
        },
      });
      return;
    }

    if (editDocumento) {
      if (typeof selectedFile === 'string') {
        formData.append('editarArquivo', 'false');
      } else {
        formData.append('editarArquivo', 'true');
        formData.append('documentoArquivo', selectedFile);
      }

      response = await DocumentosAPI.putDocumentos(editDocumento.id, formData);
    } else {
      formData.append('documentoArquivo', selectedFile);
      response = await DocumentosAPI.postDocumentos(formData);
    }

    if (response?.status === 201 || response?.status === 200) {
      setSelectedFile(null);
      reloadList();
      onHide();
    }
  };

  const accept = {
    'application/pdf': ['.pdf', '.PDF'],
    'image/tiff': ['.tiff', '.TIFF'],
    'image/jpg': ['.jpg', '.JPG'],
  };

  return (
    <Dialog
      header={editDocumento ? 'Editar documento' : 'Anexar documento'}
      type="modal"
      visible={visible}
      onHide={onHide}
      modal={false}
      maximizedTabletSize
    >
      <div className="container-adicionar">
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
          <DataOcorrencia
            control={control}
            defaultValue={
              editDocumento
                ? new Date(editDocumento.dataOcorrencia)
                : new Date()
            }
            dataInclusao={editDocumento?.dataInclusao}
          />

          <TextInputControlled
            name="nomeDocumento"
            className="p-mb-3"
            label="Nome do documento"
            maxLength={70}
            control={control}
            errorMsg={errors.nomeDocumento?.message}
          />

          <SimpleText fontSize={FONT_SIZE.XS} medium>
            Upload do documento
          </SimpleText>

          <UploadFile
            accept={accept}
            setFile={editDocumento?.nomeDocumento}
            selectedFile={files => setSelectedFile(files[0])}
          />

          <TextareaInputControlled
            name="descricao"
            label="Descrição"
            control={control}
            maxLength={100}
            errorMsg={errors.descricao?.message}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              stretch
              loading={isSubmitting}
              onClick={onHide}
            />
            <Button
              type="submit"
              label="Salvar"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ModalNovo;
