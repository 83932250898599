import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setProfissionalAtivo } from 'src/core/redux/slices/agenda/AgendaSlice';
import { RootState } from 'src/core/redux/store';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import './ProfissionalSelect.scss';

const ProfissionalSelect = () => {
  const dispatch = useAppDispatch();
  const { agenda } = useAppSelector((state: RootState) => state);

  return (
    <div className="ProfissionalSelect">
      {agenda.profissionaisDisponiveis &&
        Array.isArray(agenda.profissionaisDisponiveis) &&
        agenda.profissionaisDisponiveis.map((v: GetUsuarioDTO, i: number) => (
          <div
            key={i}
            className="item"
            onClick={() => {
              if (agenda.profissionalAtivo?.id !== v.id) {
                dispatch(setProfissionalAtivo(v));
              }
            }}
          >
            <SimpleText
              disabled={agenda.profissionalAtivo?.id === v.id ? true : false}
            >
              {v.nome}
            </SimpleText>
            {agenda.profissionaisDisponiveis.length - 1 === i ? (
              <></>
            ) : (
              <Separator />
            )}
          </div>
        ))}
    </div>
  );
};

export default ProfissionalSelect;
