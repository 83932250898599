import { memo, useEffect, useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { toast } from 'react-toastify';

import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';
import { GuiaTissAPI } from 'src/APIs/ReportAPI/GuiaTissAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import Toast from 'src/components/Basics/Toast/Toast';
import { DialogAssinarDigitalmente } from 'src/pages/Emed/Admin/AssinaturasProntuario/components/DialogAssinarDigitalmente';
import ModalEnviarGuia from 'src/pages/Emed/Prontuario/components/ModalEnviarGuia/ModalEnviarGuia';

import { LembreteButton } from './LembreteButton/LembreteButton';
interface HistoricoExamesPanelProcedimentoHeaderBtnsProps {
  idAtendimento: number;
  isInactive: boolean;
  isLembreteActive: boolean;
  hasParecer: boolean;
  hasLaudo: boolean;
  idLaudo: number;
  hasDataRepeticao: boolean;
  disabled: boolean;
  hasResultadoNumerico: boolean;
  isProcedimentoExterno: boolean;
  idProcedimento: number;
  onOpenModalLaudo: () => void;
  onOpenModalParecer: () => void;
  onAddDataRepeticao: () => void;
  onOpenInativar: () => void;
  onOpenModalInformarResultadoNumerico: () => void;
}

type EllipsisMenu = TieredMenu & {
  hide: () => void;
};

export const HistoricoExamesPanelProcedimentoHeaderBtns = (
  props: HistoricoExamesPanelProcedimentoHeaderBtnsProps,
) => {
  const [loading, setLoading] = useState(false);
  const {
    idAtendimento,
    isInactive,
    isLembreteActive: lembreteDafaultValue,
    hasParecer,
    hasLaudo,
    idLaudo,
    hasDataRepeticao,
    disabled,
    hasResultadoNumerico,
    onOpenModalLaudo,
    onOpenModalParecer,
    onAddDataRepeticao,
    onOpenInativar,
    onOpenModalInformarResultadoNumerico,
    isProcedimentoExterno,
    idProcedimento,
  } = props;

  const [isLembreteActive, setIsLembreteActive] =
    useState(lembreteDafaultValue);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    webSigner: { handleSign, isSuccess, log },
    isHsm,
    handleSignHSM,
  } = useAssinatura();

  const modalReenviarGuiaDisclosure = useDisclosure({ opened: false });

  const handlePrintGuias = async () => {
    try {
      const response = await GuiaTissAPI.getGuiaTissPdf(idAtendimento);

      if (typeof response !== 'object') {
        const data = {
          title: 'Erro',
          message: 'Falha ao baixar as guias, tente novamente',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);
    } catch (error) {}

    return;
  };

  const handleLembreteChange = async () => {
    try {
      setIsLembreteActive(prevLembreteActive => !prevLembreteActive);

      const putLembrete = isProcedimentoExterno
        ? SolicitacoesProcedimentosExternosAPI.putLembrete
        : SolicitacoesProcedimentosAPI.putLembrete;

      setIsUpdating(true);

      await putLembrete(idAtendimento, idProcedimento);
    } catch (error) {
      setIsLembreteActive(prevLembreteActive => !prevLembreteActive);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAssinaturaDocumento = async (email?: string) => {
    if (!isSuccess && !isHsm) {
      const data = {
        title: log ? 'Verifique sua assinatura digital' : 'Erro',
        message:
          log ||
          'Não foi possivel inicializar o certificado digital para assinatura das guias.',
        type: 'error',
      };
      return toast(<Toast />, { data });
    }
    try {
      setLoading(true);
      const response = await SolicitacoesProcedimentosAPI.getBase64GuiaTiss(
        idAtendimento,
      );

      const documents = {
        documents: [
          {
            content: response.base64,
            contentType: 'BASE64',
          },
        ],
      };
      let assinatura: any;

      if (isHsm) {
        assinatura = await handleSignHSM(response.base64, { skipPIN: true });
      } else {
        assinatura = await handleSign(
          documents,
          response.sequenciaAssinatura,
          'EXAMES_CIRURGIAS_GUIA',
        );
      }

      await SolicitacoesProcedimentosAPI.postAssinaturaGuiaTiss(
        idAtendimento,
        {
          base64: response.base64,
          base64Assinado: !isHsm
            ? assinatura[0].data[0].signature
            : assinatura.data.documents[0].signatures[0].value,
          email,
        },
        'email',
      );
      return modalReenviarGuiaDisclosure.close();
    } catch (error) {
      console.error('Error processing the request:', error);
    } finally {
      return setLoading(false);
    }
  };

  const menuEllipsis = useRef<EllipsisMenu>(null);

  const { isMobile } = useSize();

  const parecerText = hasParecer
    ? 'Editar parecer do exame'
    : 'Informar parecer do exame';
  const lembreteText = isLembreteActive
    ? 'Desativar lembrete'
    : 'Ativar lembrete';
  const laudoText = hasLaudo ? 'Visualizar laudo anexado' : 'Anexar laudo';
  const resultadoText = hasResultadoNumerico
    ? 'Editar resultado do exame'
    : 'Informar resultado do exame';

  const handleEllipsis = (onHandle: () => void) => {
    onHandle();
    menuEllipsis?.current?.hide();
  };

  const handleLaudoPDF = async () => {
    try {
      const response = await SolicitacoesProcedimentosAPI.getLaudoPdf(idLaudo);
      window.open(response.laudoArquivo, '_blank');
    } catch (error) {
      return;
    }
    return;
  };

  const menuEllipsisItens: MenuItem[] = [
    {
      label: lembreteText,
      command: () => handleEllipsis(handleLembreteChange),
    },
    {
      label: laudoText,
      command: () => handleEllipsis(onOpenModalLaudo),
    },
    {
      label: 'Abrir laudo',
      command: () => handleLaudoPDF(),
      disabled: !hasLaudo,
    },

    {
      label: parecerText,
      command: () => handleEllipsis(onOpenModalParecer),
    },
    {
      label: resultadoText,
      command: () => handleEllipsis(onOpenModalInformarResultadoNumerico),
    },
    {
      label: 'Adicionar data para repetição',
      command: () => handleEllipsis(onAddDataRepeticao),
      disabled: hasDataRepeticao,
    },
    {
      separator: true,
    },
    {
      label: 'Reimprimir guia',
      command: () => handleEllipsis(handlePrintGuias),
    },
    {
      label: 'Reenviar guia',
      command: () => modalReenviarGuiaDisclosure.open(),
    },
    {
      separator: true,
    },
    {
      label: 'Inativar',
      className: 'warning-color',
      command: () => handleEllipsis(onOpenInativar),
    },
  ];

  useEffectSkipFirst(() => {
    setIsLembreteActive(lembreteDafaultValue);
  }, [lembreteDafaultValue]);

  return (
    <div className="p-d-flex p-gap-1">
      {!isMobile && (
        <>
          <Button
            type="button"
            btnType="gray"
            icon="fas fa-paperclip"
            onClick={onOpenModalLaudo}
            disabled={disabled || isInactive}
          />

          <LembreteButton
            handleLembreteChange={handleLembreteChange}
            disabled={disabled || isInactive}
            isUpdating={isUpdating}
            isLembreteActive={isLembreteActive}
          />
        </>
      )}

      {isInactive ? (
        <Button
          type="button"
          btnType="gray"
          icon="fas fa-eye"
          onClick={onOpenInativar}
          disabled={disabled}
        />
      ) : (
        <Button
          icon="fas fa-ellipsis-h"
          btnType="gray"
          onClick={event => menuEllipsis?.current?.toggle(event)}
          disabled={disabled}
        />
      )}

      <TieredMenu
        className="solicitacao-exame-tiered-menu"
        ref={menuEllipsis}
        model={menuEllipsisItens}
        popup
      />

      {modalReenviarGuiaDisclosure.isOpen && (
        <ModalEnviarGuia
          handleEnviarGuiasPorEmail={handleAssinaturaDocumento}
          loading={loading}
          {...modalReenviarGuiaDisclosure}
        />
      )}
    </div>
  );
};
