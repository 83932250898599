import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

export const ValidacaoUser = () => {
  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center">
      <SpinnerLoading size="sm" />
      <SimpleText fontSize="md">
        Aguarde, estamos confirmando sua conta...
      </SimpleText>
    </div>
  );
};
