import Http, { HttpParams } from 'src/core/http/Http';

import { ConvenioAuditoriaPayload } from './@types/ConvenioAuditoria';

const basePath = '/convenios';

const loadConvenios = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetConvenioDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};
const loadConveniosAtivosSemPaginacao = (
  params?: {
    nome?: string | undefined;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetConvenioDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa/ativos/nao-paginado`,
    params,
    hideToast: true,
    ...options,
  });
};

const loadConveniosAtivos = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetConvenioDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa/ativos`,
    params,
    hideToast: true,
    ...options,
  });
};

const loadConveniosByProfissional = (
  id: any,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/profissional/${id}`,
    params,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });
};

const loadConveniosById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetConvenioDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const createConvenio = (
  payload: PostConvenio,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });

const updateConvenio = (
  payload: PostConvenio,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idConvenio}`,
    data: payload,
    throwError: true,
    ...options,
  });

const updateConvenioStatus = (
  payload: any,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/alterar-status/${idConvenio}`,
    data: payload,
    throwError: true,
    ...options,
  });

const deleteConvenio = (
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `${basePath}/${idConvenio}`,
    throwError: true,
    ...options,
  });

const getConveniosTissById = (
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/tiss/${idConvenio}`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const createConvenioTiss = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `${basePath}/tiss`,
    data: payload,
    throwError: true,
    ...options,
  });

const updateConvenioTiss = (
  payload: any,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/tiss/${idConvenio}`,
    data: payload,
    throwError: true,
    ...options,
  });

const updateConvenioLogo = (
  payload: any,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idConvenio}/logotipo`,
    data: payload,
    throwError: true,
    ...options,
  });

export const getConveniosMultiplosProfissionais = (
  idsProfissionais: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/profissionais/buscar`,
    params: idsProfissionais,
    hideToast: true,
    throwError: true,
    ...options,
  });

export const getConvenioTiposPlano = (
  idConvenio: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idConvenio}/tipos-plano`,
    hideToast: true,
    ...options,
  });

export const getConveniosByPaciente = (
  idPaciente: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/paciente/${idPaciente}`,
    hideToast: true,
    ...options,
  });

export const loadConvenioResumidoByPaciente = (
  idPaciente: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `/cartoes-paciente/convenio/resumido/paciente/${idPaciente}`,
    hideToast: true,
    ...options,
  });

export const sendPortalPacienteTiss = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.post({
    service: 'adm',
    url: `/convenios/portal-paciente/tiss`,
    data,
    ...options,
  });

export const updatePortalPacienteTiss = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `/convenios/portal-paciente/tiss/${data.idConvenio}`,
    data,
    ...options,
  });

export const updateProcedimentosSeriadosConvenio = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.put({
    service: 'adm',
    url: `/convenios/procedimentos-seriados/`,
    hideToast: true,
    data,
    ...options,
  });

const sendConvenioAuditoria = (
  data: ConvenioAuditoriaPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/convenios/auditoria`,
    data,
    ...options,
  });

const updateConvenioAuditoria = (
  id: number,
  data: ConvenioAuditoriaPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `/convenios/auditoria/${id}`,
    data,
    ...options,
  });

const loadConvenioAuditoria = (
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Omit<ConvenioAuditoriaPayload, 'idConvenio'>> =>
  Http.get({
    service: 'adm',
    url: `/convenios/${idConvenio}/auditoria`,
    hideToast: true,
    ...options,
  });

const ConvenioAPI = {
  loadConvenios,
  loadConveniosAtivos,
  loadConveniosById,
  createConvenio,
  updateConvenio,
  updateConvenioStatus,
  deleteConvenio,
  getConveniosTissById,
  createConvenioTiss,
  updateConvenioTiss,
  updateConvenioLogo,
  loadConveniosByProfissional,
  getConveniosMultiplosProfissionais,
  getConveniosByPaciente,
  getConvenioTiposPlano,
  loadConvenioResumidoByPaciente,
  sendPortalPacienteTiss,
  updatePortalPacienteTiss,
  loadConveniosAtivosSemPaginacao,
  updateProcedimentosSeriadosConvenio,
  sendConvenioAuditoria,
  updateConvenioAuditoria,
  loadConvenioAuditoria,
};

export default ConvenioAPI;
