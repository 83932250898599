import {
  Calendar as PrimeCalendar,
  CalendarProps as PrimeCalendarProps,
} from 'primereact/calendar';
import './Calendar.scss';

export type CalendarProps = PrimeCalendarProps;
const Calendar = ({ ...rest }: CalendarProps) => {
  return (
    <div className="Calendar">
      <PrimeCalendar {...rest} inline locale="pt" />
    </div>
  );
};

export default Calendar;
