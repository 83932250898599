import dayjs from 'dayjs';

export type MergeOptions = Omit<
  AvaliacaoFormularioItemHistorico,
  'descricao'
> & {
  descricao: string[];
};

export type TypeResposta = IRespostaAvaliacaoFormularioDinamicoItemResposta & {
  dataInclusao: string;
  textoResposta: string | null;
  formatoData: string | null;
};

export type AvaliacaoFormularioItemHistorico = Omit<
  IRespostaAvaliacaoFormularioDinamicoItem,
  'resposta'
> & {
  resposta: TypeResposta[];
};

export const mergeOptionsByDate = (
  perg: AvaliacaoFormularioItemHistorico,
): MergeOptions[] =>
  Object.values(
    perg.resposta.reduce((acc: any, item) => {
      const date = dayjs(item.dataInclusao).format('DD/MM/YYYY_HH:mm');
      if (!acc[date]) {
        acc[date] = { ...item, descricao: [item.descricao] };
      } else {
        acc[date].descricao.push(item.descricao);
      }
      return acc;
    }, {}),
  );

export const mergeAnswersEvolucao = (
  perg: IRespostaAvaliacaoFormularioDinamicoItem[],
): AvaliacaoFormularioItemHistorico =>
  perg.reduce((acc: any, curr) => {
    const idPergunta = curr.idPerguntaFormularioDinamico;

    if (!acc[idPergunta]) {
      acc[idPergunta] = {
        ...curr,
        resposta: [
          {
            ...curr.resposta,
            dataInclusao: curr.dataInclusao,
            textoResposta: curr.textoResposta,
            formatoData: curr.perguntaFormularioDinamico.formatoData,
          },
        ], // Puts the initial response in an array
      };
    } else {
      acc[idPergunta].resposta.push({
        ...curr.resposta,
        dataInclusao: curr.dataInclusao,
        textoResposta: curr.textoResposta,
        formatoData: curr.perguntaFormularioDinamico.formatoData,
      }); // Adds subsequent responses to the array
    }

    return acc;
  }, {});
