import { useFieldArray, useFormContext } from 'react-hook-form';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldMultiselectServicoTuss } from 'src/components/FieldMultiselectServicoTuss/FieldMultiselectServicoTuss';

import { Header } from './List/Header';
import { Row } from './List/Row';

import './Procedimentos.scss';

export const Procedimentos = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const {
    fields: servicosTuss,
    append: appendServicosTuss,
    remove: removeServicosTuss,
  } = useFieldArray({
    control,
    name: 'servicosTuss',
  });

  const convenio = watch('convenio');

  const handleAddProcedimentos = (procedimentos: any[]) => {
    const procedimentosFormatted = procedimentos.map(procedimento => ({
      codigo: procedimento.codigo,
      nome: procedimento.nome,
      idServicoTuss: procedimento.id,
      quantidade: procedimento?.quantidadePadrao || 1,
      quantidadeMaxima: procedimento?.quantidadeMaxima,
      servicoTussPrincipal: false,
    }));

    appendServicosTuss(procedimentosFormatted);
  };

  const renderProcedimentos = () => {
    if (!servicosTuss.length) {
      return (
        <div id="empty-procedimentos">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Nenhum. Adicione um procedimento utilizando a barra de pesquisa.
          </SimpleText>
        </div>
      );
    }

    return (
      <>
        <Header />
        {servicosTuss.map((servicoTuss, index) => (
          <Row
            key={index}
            servicoTuss={servicoTuss}
            index={index}
            onDelete={removeServicosTuss}
          />
        ))}
      </>
    );
  };

  const borderContainerClassName = `p-col-12 ${
    errors.servicoTussPrincipal?.message || errors.servicosTuss?.message
      ? 'error-border'
      : ''
  }`;

  return (
    <div className="p-grid">
      <div className="p-col-12 column">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} bold>
          Adicionar ao pacote
        </SimpleText>
        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Pesquise o nome do procedimento desejado para adiciona-lo ao pacote
        </SimpleText>
      </div>

      <div
        id="procedimento-border-container"
        className={borderContainerClassName}
      >
        <div className="p-d-flex p-ai-center p-mb-3 p-grid p-col-12 p-p-0">
          <div className="p-d-flex p-ai-end p-grid p-col-12 p-sm-9 p-p-0">
            <div className="p-mr-3 p-col-12 p-sm-7 p-md-6 p-p-0">
              <FieldMultiselectServicoTuss
                handleAddProcedimentos={handleAddProcedimentos}
                params={{
                  idConvenio: convenio?.id,
                }}
              />
            </div>

            <div className="p-col-12 p-sm-4 p-md-3 p-lg-2 p-p-0 p-mt-2 p-mt-sm-o">
              <Dropdown
                label="Versão"
                value="TUSS"
                name="versao"
                filter={false}
                options={[{ value: 'TUSS', label: 'TUSS' }]}
              />
            </div>
          </div>

          <SimpleText
            className="p-mt-3 p-mt-md-0 p-col-12 p-md-3 p-text-center p-text-md-right"
            fontColor={FONT_COLOR.COLOR_40}
          >
            Qtd. de Exames: {servicosTuss.length}
          </SimpleText>
        </div>

        {renderProcedimentos()}
      </div>
      {!!(
        errors.servicoTussPrincipal?.message || errors.servicosTuss?.message
      ) && (
        <SimpleText className="error p-my-3" fontSize={FONT_SIZE.XXXS}>
          {errors.servicosTuss?.message || errors.servicoTussPrincipal?.message}
        </SimpleText>
      )}
    </div>
  );
};
