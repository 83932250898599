import React from 'react';

import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

interface DesktopRowProps {
  impresso: any;
  onInativar: (impresso: any) => void;
  onVisualizarInativacao: (impresso: any) => void;
}

export default function DesktopRow({
  impresso,
  onInativar,
  onVisualizarInativacao,
}: DesktopRowProps) {
  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            impresso={impresso}
            onInativar={onInativar}
            onVisualizarInativacao={onVisualizarInativacao}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
          />
        )}
      >
        <PanelContent impresso={impresso} />
      </Panel>
    </div>
  );
}
