import { useFormContext } from 'react-hook-form';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

type PontuacaoInfo = {
  descricao: string;
  pontuacao: number;
  consultorios: GetConsultorioDTO[];
};

interface FormPontuacaoProps {
  goToNextStep: () => void;
  complexidade?: ComplexidadePacienteDTO;
  setPontuacaoInfo: (data: PontuacaoInfo) => void;
  pontuacaoInfo: any;
}

const FormPontuacao = ({
  goToNextStep,
  complexidade,
  setPontuacaoInfo,
}: FormPontuacaoProps) => {
  const useFormMethods = useFormContext();

  const { handleSubmit } = useFormMethods;

  const onAvancar = (data: any) => {
    setPontuacaoInfo(data);
    goToNextStep();
  };

  return (
    <div className="p-grid">
      <SimpleText className="p-mb-2 p-col-12" fontSize={FONT_SIZE.MD} medium>
        {complexidade?.id ? 'Editar ' : 'Cadastrar '} complexidade do paciente
      </SimpleText>

      <SimpleText className="p-col-12">
        Informe uma descrição e uma pontuação para a complexidade.
      </SimpleText>

      <FormInput
        className="p-col-12"
        name="descricao"
        label="Descrição"
        placeholder="Informe a descrição"
        maxLength={100}
        hideTextErrorSpace
      />

      <FormInput
        className="p-col-12"
        name="pontuacao"
        label="Pontuação"
        placeholder="Informe a pontuação"
        type="number"
        max={999999}
      />

      <Button
        type="button"
        label="Avançar"
        onClick={handleSubmit(onAvancar)}
        className="p-col-12"
      />
    </div>
  );
};

export default FormPontuacao;
