import { useCallback, useState } from 'react';

import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import { Button } from 'src/components/_UI/Button';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Skeleton from 'src/components/Skeleton/Skeleton';

import Row from './Row';

interface AgendaProps {
  agendamento: any;
}

const Agendamentos = ({ agendamento }: AgendaProps) => {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<number>(0);

  const fetchApi = useCallback(
    async params => {
      if (!agendamento) return [];

      try {
        setLoading(true);

        const { idMedico, idPaciente } = agendamento;

        const response = await AgendamentosAPI.getHistricoAgendamento({
          ...params,
          idMedico,
          idPaciente,
          sortDirection: 'DESC',
          sortBy: 'dataAgendamento',
        });

        return response;
      } catch (error) {
        return [];
      } finally {
        setLoading(false);
      }
    },
    [agendamento],
  );

  const renderRow = (agendamento: any): JSX.Element => {
    return (
      <Skeleton loading={loading} key={agendamento.id} height="100px">
        <Row agendamento={agendamento} />
      </Skeleton>
    );
  };

  return (
    <div className="p-px-2">
      <SearchList.Root fetchApi={fetchApi}>
        <ListHeader />
        <SearchList.InfiniteScroll
          renderRow={renderRow}
          reload={reload}
          autoHeight={false}
        />
      </SearchList.Root>
    </div>
  );
};

type Filter = 'FALTOU' | 'CANCELADO' | null;

const ListHeader = () => {
  const [selectedFilter, setSelectedFilter] = useState<Filter[]>([]);
  const { setFilter } = useSearchList();

  const handleFilter = useCallback(
    (filterStatus: Filter) => {
      let newFilter;
      if (selectedFilter.includes(filterStatus)) {
        newFilter = selectedFilter.filter(f => f !== filterStatus);
      } else {
        newFilter = [...selectedFilter, filterStatus];
      }
      setSelectedFilter(newFilter);
      const filter = newFilter.join(',');
      if (!filter) return setFilter({});
      setFilter({ status: filter });
    },
    [selectedFilter, setFilter],
  );

  return (
    <div className="p-d-flex p-mb-2">
      <Button
        btnType="gray"
        label="Falta"
        onClick={() => handleFilter('FALTOU')}
        className={`rounded ${
          selectedFilter.includes('FALTOU') ? 'active' : ''
        }`}
      />
      <Button
        btnType="gray"
        label="Cancelamento"
        onClick={() => handleFilter('CANCELADO')}
        className={`rounded ${
          selectedFilter.includes('CANCELADO') ? 'active' : ''
        }`}
      />
    </div>
  );
};

export default Agendamentos;
