import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import useDebounce from 'src/utils/useDebounce';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldMeuProfissionalProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldMeuProfissional = ({
  name = 'meuProfissional',
  label,
  ...rest
}: FieldMeuProfissionalProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [meuProfissional, setMeuProfissional] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const debounceSearch = useDebounce(querySearch, 350);

  const loadMeuProfissional = useCallback(async (queryParam: any) => {
    const response = await ProfissionalAPI.loadProfissionalSaudeByTipo({
      nome: queryParam,
      tipoProfissional: 'MEDICO',
    });

    if (!!response?.length) setMeuProfissional(response);

    setLoadingSearch(false);
  }, []);

  useEffect(() => {
    loadMeuProfissional(debounceSearch);
  }, [debounceSearch, loadMeuProfissional]);

  const meuProfissionalOptions = useMemo(() => {
    const options = meuProfissional.map(item => ({
      label: item.nome,
      value: item,
    }));

    return options;
  }, [meuProfissional]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      onFilter={e => {
        setLoadingSearch(true);
        setQuerySearch(e.filter);
      }}
      carregandoFiltro={loadingSearch}
      options={meuProfissionalOptions}
      errorMsg={errors?.[name]?.message}
      customOnChange={rest.onChange ? rest.onChange : () => null}
      labelOverflow="hidden"
      {...rest}
    />
  );
};
