import { memo, useCallback } from 'react';

import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalExcluirGrupoAtendimento.scss';
import { Button } from 'src/components/_UI/Button';

interface ModalExcluirGrupoAtendimentoProps {
  visible: boolean;
  onHide(): void;
  reload(): void;
  grupoAtendimento: GrupoAtendimento;
  listaGrupoAtendimento: GrupoAtendimento[];
  setListaGrupoAtendimento: any;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button btnType="danger" label="Sim, excluir" onClick={onAdd} stretch />
    </div>
  );
});

const ModalExcluirGrupoAtendimento = ({
  grupoAtendimento,
  visible,
  onHide,
  reload,
  listaGrupoAtendimento,
  setListaGrupoAtendimento,
}: ModalExcluirGrupoAtendimentoProps) => {
  const handleDeletarGrupoAtendimento = useCallback(async () => {
    const response: any = await GrupoAtendimentoAPI.removeGrupoAtendimento(
      grupoAtendimento.id!,
    );

    if (response.status === 204) {
      const listaGrupoAtendimentoRemovido = listaGrupoAtendimento.filter(
        g => g.id !== grupoAtendimento.id,
      );

      setListaGrupoAtendimento([...listaGrupoAtendimentoRemovido]);
      onHide();
      reload();
    }
  }, [
    grupoAtendimento.id,
    listaGrupoAtendimento,
    onHide,
    reload,
    setListaGrupoAtendimento,
  ]);

  const handleCancelGrupoAtendimento = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <Dialog
      className="footer-on modal-body-excluir-grupo"
      type="modal"
      header="Excluir grupo de atendimento"
      visible={visible}
      onHide={handleCancelGrupoAtendimento}
      footer={() => (
        <Footer
          onAdd={handleDeletarGrupoAtendimento}
          onCancel={handleCancelGrupoAtendimento}
        />
      )}
    >
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        Você não poderá mais agendar este grupo. Você realmente deseja excluir?
      </SimpleText>
    </Dialog>
  );
};

export default ModalExcluirGrupoAtendimento;
