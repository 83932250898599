import { useState, useCallback, useRef, useEffect, useMemo } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Control, useController } from 'react-hook-form';

import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';

import './DropdownComorbidade.scss';

interface DropdownComorbidadeProps {
  control: Control;
  disabled?: boolean;
}

const DropdownComorbidade = ({
  control,
  disabled = false,
}: DropdownComorbidadeProps) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const { agenda } = useAppSelector((state: RootState) => state);

  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name: 'comorbidade',
    control,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [comorbidadeSelecionada, setComorbidadeSelecionada] =
    useState<any>(null);
  const [favoritos, setFavoritos] = useState<any[]>([]);
  const [filtroSelecionado, setFiltroSelecionado] =
    useState<string>('Favoritos');

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const loadFavoritos = useCallback(async () => {
    const response = await ComorbidadeAPI.getFavoritos(
      agenda?.profissionalAtivo?.id,
    );

    if (Array.isArray(response) && !!response.length) setFavoritos(response);
  }, [agenda?.profissionalAtivo?.id]);

  useEffect(() => {
    loadFavoritos();
  }, [loadFavoritos]);

  const loadCids = useCallback(
    async (params: any) => {
      return await UtilsAPI.getCids({
        nome: searchQuery,
        codigo: searchQuery,
        ...params,
      });
    },
    [searchQuery],
  );

  const loadCiaps = useCallback(
    async (params: any) => {
      return await UtilsAPI.getCiaps({
        nome: searchQuery,
        codigo: searchQuery,
        ...params,
      });
    },
    [searchQuery],
  );

  const fetchAPI = useCallback(
    (params: any) => {
      const listagemFavoritos = favoritos.map(
        favorito => favorito?.cid || favorito?.ciap,
      );

      if (filtroSelecionado === 'Favoritos') return { list: listagemFavoritos };

      if (searchQuery?.length > 0 && filtroSelecionado === 'CID')
        return loadCids(params);

      if (searchQuery?.length > 0 && filtroSelecionado === 'CIAP')
        return loadCiaps(params);

      return { list: [] };
    },
    [favoritos, filtroSelecionado, loadCiaps, loadCids, searchQuery],
  );

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const onSelectValue = (comorbidade: any) => {
    let name = 'cid';

    if (filtroSelecionado === 'CIAP') name = 'ciap';

    if (
      filtroSelecionado === 'Favoritos' &&
      ciapsFavoritos.includes(comorbidade.nome)
    )
      name = 'ciap';

    const idName = `id${capitalizeFirstLetter(name)}`;

    onChange({
      name: name,
      value: [
        {
          id: comorbidade.id,
          [idName]: comorbidade.id,
          [name]: comorbidade,
        },
      ],
    });

    searchPanelRef.current?.hide();
  };

  const handleFavoritar = async (comorbidade: Comorbidade) => {
    const response = await ComorbidadeAPI.favoritarComorbidade(
      agenda?.profissionalAtivo?.id,
      { idCid: comorbidade.id },
    );

    if (response?.status === 200) loadFavoritos();
  };

  useEffect(() => {
    if (value?.name === 'cid') setFiltroSelecionado('CID');
    if (value?.name === 'ciap') setFiltroSelecionado('CIAP');

    setComorbidadeSelecionada(value?.value?.[0]?.[value.name] || null);
  }, [value]);

  const cidsFavoritos = useMemo(
    () =>
      favoritos
        .filter(favorito => !!favorito?.idCid)
        .map(favorito => favorito.cid.nome),
    [favoritos],
  );

  const ciapsFavoritos = useMemo(
    () =>
      favoritos
        .filter(favorito => !!favorito.idCiap)
        .map(favorito => favorito.ciap.nome),
    [favoritos],
  );

  const renderRow = (comorbidade: any) => {
    const favoritado =
      cidsFavoritos.includes(comorbidade.nome) ||
      ciapsFavoritos.includes(comorbidade.nome);

    return (
      <div
        className={`comorbidade-item p-grid p-col-12 ${
          comorbidadeSelecionada?.id === comorbidade.id
            ? 'comorbidade-selecionada'
            : ''
        }`}
      >
        <div
          className="p-col-11 p-grid"
          onClick={() => onSelectValue(comorbidade)}
        >
          <SimpleText
            className="p-col-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {comorbidade.codigo}
          </SimpleText>
          <SimpleText
            className="p-col-8"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {comorbidade.nome}
          </SimpleText>
          <SimpleText
            className="p-col-1"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {comorbidade.versao}
          </SimpleText>
        </div>
        <div
          className="p-col-1 p-d-flex p-jc-center p-ai-center"
          onClick={() => handleFavoritar(comorbidade)}
        >
          <i
            className={`fas fa-star favoritar-button ${
              favoritado ? 'favoritado' : ''
            }`}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-col-12">
      <div ref={searchInputContainerRef}>
        <TextInputSearch
          placeholder="Pesquisar CID ou CIAP"
          value={searchQuery}
          updateValue={comorbidadeSelecionada?.nome || ''}
          onChange={setSearchQuery}
          onFocus={e => searchPanelRef.current?.show(e, null)}
          disabled={disabled}
        />
      </div>

      <TextInputControlled control={control} name="comorbidade" hidden />

      {errors?.comorbidade?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors?.comorbidade?.message}
        </SimpleText>
      )}

      <OverlayPanel
        className="comorbidade-search-panel"
        ref={searchPanelRef}
        dismissable={false}
      >
        <div className="p-grid" ref={searchPanelContainerRef}>
          <SimpleText
            className="p-col-12"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
            medium
          >
            Pesquisar por
          </SimpleText>
          <div id="filtro-chips-container">
            {['Favoritos', 'CIAP', 'CID'].map(filtro => (
              <span
                key={filtro}
                className={`filtro-chip ${
                  filtro === filtroSelecionado ? 'filtro-selecionado' : ''
                }`}
                onClick={() => setFiltroSelecionado(filtro)}
              >
                {filtro}
              </span>
            ))}
          </div>
          <div id="search-results-container" className="p-col-12">
            <InfiniteScroll fetchAPI={fetchAPI} renderRow={renderRow} />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DropdownComorbidade;
