import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

interface PanelHeaderProps {
  vacina: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  vacina,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName = vacina.status === 'INATIVO' ? 'item-inativo' : '';
  const dataTextClassName = `p-mt-2 ${
    vacina.status === 'INATIVO' ? 'line-through item-inativo' : ''
  }`;

  return (
    <div className="vacina-panel-header p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-6 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Descrição
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {vacina.descricao}
        </SimpleText>
      </div>
      <div className="coluna-dados p-col-4 p-md-3 p-d-flex p-flex-column p-ai-end">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(vacina.dataOcorrencia).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>

      <div className="coluna-dados p-col-4 p-md-2 p-xl-1 retroativo-chip">
        <SimpleText fontSize={FONT_SIZE.XXXS}>
          {vacina.aplicadaEmAtraso ? 'Atraso' : 'Adequado'}
        </SimpleText>
      </div>

      <div className="coluna-dados p-col-4 p-md-2 p-xl-1 retroativo-chip">
        {!!vacina?.justificativaOcorrenciaRetroativa && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>

      <div className="coluna-dados p-col-4 p-md-2 p-d-flex p-flex-column p-ai-end">
        {!!vacina?.dataProximaDose && (
          <>
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Próxima Dose
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {dayjs(vacina.dataProximaDose)
                .locale('pt-br')
                .format('DD MMM[.] YYYY')}
            </SimpleText>
          </>
        )}
      </div>
      <div className="coluna-acoes p-col-6 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Lembrete ativo={vacina.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
