import { memo, useEffect } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useTimer } from 'src/utils/hooks/useTimer';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './DialogLoadingTiss.scss';

type IProps = DisclosureType & {
  timeout: number | undefined;
  customMensage?: string | undefined;
  onTimeout: () => void;
  setIsTimerRunning: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DialogLoadingTiss = ({
  timeout,
  customMensage,
  close,
  onTimeout,
  setIsTimerRunning,
}: IProps) => {
  useEffect(() => {
    setIsTimerRunning(true);
  }, [setIsTimerRunning]);

  const handleTimeout = () => {
    onTimeout();
    setIsTimerRunning(false);
    close();
  };

  return (
    <Dialog
      id="Dialog-loading-operadora"
      header="Conectando com a operadora"
      visible
      onHide={close}
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText fontColor="color_40">
          {customMensage ??
            'Estamos conectando com a operadora para validar as informações.'}
        </SimpleText>
        <div className="p-d-flex p-gap-2 p-ai-center">
          <SpinnerLoading size="sm" />
          <SimpleText>
            Aguarde um instante...{' '}
            {
              <Timer
                timeOutElegibilidade={timeout}
                close={() => handleTimeout()}
              />
            }
            ...
          </SimpleText>
        </div>
        <Button
          label="Cancelar"
          btnType="outline"
          onClick={() => handleTimeout()}
        />
      </div>
    </Dialog>
  );
};

const Timer = memo(({ timeOutElegibilidade, close }: any) => {
  const { start, time, isActive } = useTimer({
    initial: timeOutElegibilidade || 10,
    decrement: true,
    format: 'descriptive',
  });

  useEffectSkipFirst(() => {
    if (!isActive) close();
  }, [isActive]);

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SimpleText bold>{time}</SimpleText>;
});
