import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Button } from 'src/components/_UI/Button';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';

export const LaudoField = () => {
  const [showLaudoField, setShowLaudoField] = useState(false);

  const {
    control,
    formState: { errors },
    unregister,
  } = useFormContext();

  const handleToggleShowLaudo = () => {
    if (showLaudoField) unregister(['laudoDigitado']);

    setShowLaudoField(prevShowLaudo => !prevShowLaudo);
  };

  if (showLaudoField) {
    return (
      <>
        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="laudoDigitado"
          label="Laudo"
          errorMsg={errors.laudoDigitado?.message}
        />
        <Button
          className="danger"
          type="button"
          btnType="danger-link"
          label="Remover laudo"
          icon="fas fa-minus"
          onClick={handleToggleShowLaudo}
        />
      </>
    );
  }

  return (
    <div className="p-col-12 p-px-0">
      <Button
        type="button"
        btnType="green-link"
        className="p-col-align-start"
        label="Informar laudo"
        icon="fas fa-plus"
        onClick={handleToggleShowLaudo}
      />
    </div>
  );
};
