/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { useLocation } from 'react-router';

import dayjs from 'dayjs';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import { LoadPrescricao } from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import useTheme from 'src/core/themes/useTheme';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { DialogEncaminharExecucao } from '../components/DialogEncaminharExecucao/DialogEncaminharExecucao';
import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { DialogAssinarDigitalmente } from 'src/pages/Emed/Admin/AssinaturasProntuario/components/DialogAssinarDigitalmente';

import { PrescricaoCreate } from '../Create/PrescricaoCreate';
import { usePDF } from '../hooks/usePDF';

import './PrescricaoList.scss';

type ListHeaderProps = {
  hasPrescricoes?: boolean;
  onOpenCreate?: () => void;
  executada?: boolean;
  onOpenExecutada?: () => void;
  isOpenExecutada?: boolean;
  refetchList: () => void;
  hasPrescricoesNaoEncaminhadas?: boolean;
};

type ListRowProps = {
  executada?: boolean;
  item: LoadPrescricao;
  refetchList: () => void;
};

export const PrescricaoList = () => {
  const { dialogAssinarDigitalmente } = useAssinatura();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const dialogCreate = useDisclosure({ opened: false });

  const [prescricoes, setPrescricoes] = useState<LoadPrescricao[]>([]);
  const [prescricoesExecutadas, setPrescricoesExecutadas] = useState<
    LoadPrescricao[]
  >([]);

  const fetchAPI = useCallback(() => {
    PrescricaoAPI.loadPrescricao(idAtendimento!, {}, { throwError: true })
      .then(data => [
        setPrescricoes(
          data?.list.filter(item => item.situacaoPrescricao !== 'EXECUTADA'),
        ),
        setPrescricoesExecutadas(
          data.list.filter(item => item.situacaoPrescricao === 'EXECUTADA'),
        ),
      ])
      .catch(() => {});
  }, [idAtendimento]);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  const hasPrescricoesNaoEncaminhadas = useMemo(
    () =>
      !!prescricoes.length &&
      !!prescricoes.find(pres => !pres.idAtendimentoExecucao),
    [prescricoes],
  );

  return (
    <div className="prescricao-list">
      <ListHeader
        onOpenCreate={dialogCreate.open}
        hasPrescricoes={!!prescricoes.length}
        hasPrescricoesNaoEncaminhadas={hasPrescricoesNaoEncaminhadas}
        refetchList={fetchAPI}
      />

      <div className="p-d-flex p-flex-column p-w-100 p-gap-2">
        {!!prescricoes.length ? (
          prescricoes.map((item, idx) => (
            <ListRow refetchList={fetchAPI} key={idx} item={item} />
          ))
        ) : (
          <div className="p-d-flex p-jc-center p-ai-center p-col-12 p-my-4">
            <SimpleText>
              Nenhuma. Suas prescrições serão exibidas aqui. Adicione uma nova,
              clicando no botão adicionar.
            </SimpleText>
          </div>
        )}
      </div>

      {prescricoesExecutadas.map((item, idx) => (
        <ListExecutadas refetchList={fetchAPI} item={item} key={idx} />
      ))}

      {dialogCreate.isOpen && (
        <PrescricaoCreate refetchList={fetchAPI} {...dialogCreate} />
      )}
      {dialogAssinarDigitalmente.isOpen && (
        <DialogAssinarDigitalmente {...dialogAssinarDigitalmente} />
      )}
    </div>
  );
};

const ListHeader = ({
  onOpenCreate,
  executada,
  onOpenExecutada,
  isOpenExecutada,
  hasPrescricoes,
  refetchList,
  hasPrescricoesNaoEncaminhadas,
}: ListHeaderProps) => {
  const menu = useRef<any>(null);
  const { theme = '' } = useTheme();
  const { isTablet, isMobile } = useSize();
  const { state } = useLocation();
  const { atendimento } = useAppSelector(state => state);

  const { camposPEP } = useAtendimento();

  const [isLoading, setIsLoading] = useState(false);

  const idAtendimento = state?.idAtendimento;
  // * Implementar assinatura digital quando disponibilizado @CDU_143
  const { isPrinting, isDownloading, handleDownload, handlePrint } = usePDF(
    idAtendimento!,
  );

  const dialogExecutar = useDisclosure({ opened: false });

  const handleExecutarPrescricoes = (retornar: boolean) => {
    setIsLoading(true);

    PrescricaoAPI.sendPrescricaoExecutar(
      idAtendimento!,
      {
        prontuarioDto: {
          idAtendimento: Number(idAtendimento),
          idPaciente: Number(atendimento?.paciente?.id),
          idProfissionalSaude: Number(atendimento.medico.id),
          camposProntuario: camposPEP,
        },
        statusEncaminhamentoDto: {
          retornar,
        },
      },
      { throwError: true },
    )
      .then(() => refetchList())
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const itemsOptionsMobile = [
    {
      label: 'Adicionar medicamentos',
      icon: 'fa fa-plus',
      command: () => !!onOpenCreate && onOpenCreate(),
      disabled: !!executada,
    },
    {
      label: 'Baixar (.PDF)',
      icon: 'fa-solid  fa-file-arrow-down',
      command: () => handleDownload(),
      disabled: !hasPrescricoes,
      loading: isDownloading,
    },
    {
      label: 'Imprimir',
      icon: 'fa-solid fa-print',
      command: () => handlePrint(),
      disabled: !hasPrescricoes,
      loading: isPrinting,
    },
  ];

  return (
    <div className="prescricao-header p-col-12 p-px-0 p-mb-1">
      {!isMobile ? (
        <>
          {!executada && (
            <Button
              label={!isTablet ? 'Adicionar medicamentos' : ''}
              btnType="green-link"
              icon="fa fa-plus"
              onClick={() => !!onOpenCreate && onOpenCreate()}
              stretch
            />
          )}
          <Button
            label={!isTablet ? 'Baixar (.PDF)' : ''}
            btnType="green-link"
            icon="fa-solid  fa-file-arrow-down"
            onClick={() => handleDownload()}
            loading={isDownloading}
            disabled={!hasPrescricoes}
            stretch
          />
          <Button
            label={!isTablet ? 'Imprimir' : ''}
            btnType="green-link"
            icon="fa-solid fa-print"
            onClick={() => handlePrint()}
            loading={isPrinting}
            disabled={!hasPrescricoes}
            stretch
          />
        </>
      ) : (
        <>
          <TieredMenu
            id="opcoes-menu"
            model={itemsOptionsMobile}
            popup
            ref={menu}
          />

          <Button
            icon="fas fa-ellipsis-h"
            btnType="gray"
            onClick={event => menu?.current?.toggle(event)}
            aria-controls="opcoes-menu"
            aria-haspopup
          />
        </>
      )}

      {!executada ? (
        <Button
          label="Encaminhar para execução"
          btnType="tonal"
          icon="fas fa-chevron-down"
          iconPos="right"
          disabled={!hasPrescricoesNaoEncaminhadas}
          loading={isLoading}
          stretch
          overlay={overlay => (
            <div className="p-w-100 p-d-flex p-flex-column p-gap-2">
              <SimpleText
                onClick={() => [
                  overlay?.hide(),
                  dialogExecutar.open({
                    state: {
                      retornar: false,
                    },
                  }),
                ]}
                className="p-p-2 enc-prescricao-item"
              >
                Executar e liberar paciente
              </SimpleText>
              <SimpleText
                onClick={() => [
                  overlay?.hide(),
                  dialogExecutar.open({
                    state: {
                      retornar: true,
                    },
                  }),
                ]}
                className="p-p-2 enc-prescricao-item"
              >
                Executar e retornar paciente
              </SimpleText>
            </div>
          )}
        />
      ) : (
        <SimpleText className={`tag tag-executada ${theme}`}>
          Executada
        </SimpleText>
      )}

      {executada && (
        <Button
          btnType="ghost"
          icon={isOpenExecutada ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
          onClick={() => !!onOpenExecutada && onOpenExecutada()}
        />
      )}

      {dialogExecutar.isOpen && (
        <DialogEncaminharExecucao
          onConfirm={retornar => handleExecutarPrescricoes(retornar)}
          {...dialogExecutar}
        />
      )}
    </div>
  );
};

const ListRow = ({ item, executada, refetchList }: ListRowProps) => {
  const menu = useRef<any>(null);
  const { isMobile, isTablet } = useSize();

  const deleteDialog = useRef<OverlayPanel>(null);
  const dialogEdit = useDisclosure({ opened: false });

  const handleRemoverPrescricao = async () => {
    await PrescricaoAPI.deletePrescricao(item.id).then(() => refetchList());
    deleteDialog.current?.hide();
  };

  const itemsOptionsMobile = [
    {
      label: 'Editar',
      icon: 'fa fa-edit',
      command: () => dialogEdit.open(),
      disabled: !!executada || !!item.idAtendimentoExecucao,
    },
    {
      label: 'Remover',
      icon: 'fa fa-trash',
      command: (e: any) => deleteDialog.current?.toggle(e),
      disabled: !!executada || !!item.idAtendimentoExecucao,
    },
  ];

  return (
    <div className="prescricao-list-row">
      <div className="p-d-flex p-w-100 p-mb-1">
        <div className="p-d-flex p-flex-column p-gap-1 p-jc-center p-py-0 p-col-4 p-md-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>Posologia</SimpleText>
          <SimpleText>{item?.posologia}</SimpleText>
        </div>

        {!isMobile && (
          <div className="p-d-flex p-flex-column p-gap-1 p-jc-center p-py-0 p-col-3">
            <SimpleText fontColor={FONT_COLOR.COLOR_40}>Via</SimpleText>
            <SimpleText>{capitalizeFirstLetter(item?.via || '')}</SimpleText>
          </div>
        )}

        <div className="p-d-flex p-flex-column p-gap-1 p-jc-center p-py-0 p-col p-md-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>Data</SimpleText>
          <SimpleText>
            {item?.dataInclusao
              ? dayjs(item.dataInclusao).format('DD/MM/YYYY HH:mm')
              : '-'}
          </SimpleText>
        </div>

        {!isTablet ? (
          <div className="p-d-flex p-flex-row p-ai-center p-py-0 p-jc-end p-col-3 p-gap-1">
            <Button
              icon="fa fa-edit"
              btnType="gray"
              disabled={!!executada || !!item.idAtendimentoExecucao}
              onClick={() => dialogEdit.open()}
            />
            <Button
              icon="fa fa-trash"
              btnType="gray"
              disabled={!!executada || !!item.idAtendimentoExecucao}
              onClick={e => deleteDialog.current?.toggle(e)}
            />
          </div>
        ) : (
          <div className="p-col-3 p-d-flex p-jc-end">
            <TieredMenu
              id="opcoes-menu"
              model={itemsOptionsMobile}
              popup
              ref={menu}
            />

            <Button
              icon="fas fa-ellipsis-h"
              btnType="gray"
              onClick={event => menu?.current?.toggle(event)}
              aria-controls="opcoes-menu"
              aria-haspopup
            />
          </div>
        )}
      </div>

      <div className="p-d-flex p-flex-column p-gap-2">
        {!!item?.prescricaoMedicamentos?.length &&
          item.prescricaoMedicamentos.map((medicamento, idx) => (
            <div key={idx} className="list-row-content p-w-100 p-grid">
              <SimpleText className="p-col-8 p-md-6 p-text-climp-2" bold>
                {capitalizeFirstLetter(medicamento?.descricao || '')}
              </SimpleText>
              <div className="p-d-flex p-flex-column p-gap-1 p-col-2 p-md-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_40}>Qtde.</SimpleText>
                <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                  {medicamento?.quantidade}
                </SimpleText>
              </div>
              {executada && (
                <div className="p-col-3 p-md-2 p-px-1">
                  {medicamento?.dataAplicacao ? (
                    <span className="tag-outline">Aplicado</span>
                  ) : (
                    <span className="tag-helper">Não aplicado</span>
                  )}
                </div>
              )}
              <div className="p-d-flex p-flex-column p-gap-1 p-col-4 p-md-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                  Data de aplicação
                </SimpleText>
                <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                  {!!medicamento?.dataAplicacao
                    ? dayjs(medicamento.dataAplicacao).format(
                        'DD/MM/YYYY HH:mm',
                      )
                    : '-'}
                </SimpleText>
              </div>
            </div>
          ))}
      </div>

      {dialogEdit.isOpen && (
        <PrescricaoCreate
          refetchList={refetchList}
          item={item}
          {...dialogEdit}
        />
      )}

      <OverlayPanel
        appendTo="self"
        className="delete-dialog"
        ref={deleteDialog}
      >
        <SimpleText className="p-w-100">
          Esta exclusão será permanente. Você tem certeza que deseja excluir?
        </SimpleText>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Cancelar"
            onClick={() => deleteDialog.current?.hide()}
            stretch
          />
          <Button
            btnType="danger"
            label="Confirmar"
            onClick={() => handleRemoverPrescricao()}
            stretch
          />
        </div>
      </OverlayPanel>
    </div>
  );
};

const ListExecutadas = ({
  item,
  refetchList,
}: Pick<ListRowProps, 'item' | 'refetchList'>) => {
  const executadaDetalhes = useDisclosure({ opened: false });

  return (
    <React.Fragment>
      <ListHeader
        executada
        hasPrescricoes
        onOpenExecutada={executadaDetalhes.toggle}
        isOpenExecutada={executadaDetalhes.isOpen}
        refetchList={refetchList}
      />

      <div className="p-d-flex p-w-100 p-gap-2">
        <ListRow refetchList={refetchList} item={item} executada />
      </div>

      {executadaDetalhes.isOpen && <ExecutadaDetalhes item={item} />}
    </React.Fragment>
  );
};

const ExecutadaDetalhes = ({ item }: { item: LoadPrescricao }) => {
  return (
    <div className="p-d-flex p-flex-column p-col-12">
      <div className="p-col-12 p-d-flex p-gap-2">
        <Avatar size="normal" label={item.nomeEnfermeiroExecucao} />
        <div className="p-d-flex p-flex-column p-gap-1">
          <div className="p-d-flex p-gap-2">
            <SimpleText>{item?.nomeEnfermeiroExecucao}</SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              ({item?.conselhoTissSigla} {item?.numeroRegistro})
            </SimpleText>
          </div>

          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            {dayjs(item?.dataOcorrencia).format(
              '[Em] DD [de] MMM[.] [de] YYYY [às] HH:mm ',
            )}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Observações
        </SimpleText>
        <SimpleText>{item?.observacao || '-'}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Reações adversas
        </SimpleText>
        <SimpleText>{item?.reacaoAdversa || '-'}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Justificativa para medicamentos não aplicados:
        </SimpleText>
        <SimpleText>{item?.justificativaFinalizacao || '-'}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Justificativa para registro retroativo
        </SimpleText>
        <SimpleText>{item?.justificativaOcorrencia || '-'}</SimpleText>
      </div>
    </div>
  );
};
