import { createContext } from 'react';

export interface CreateProfissionalSaudeData {
  dadosProfissionais: any;
  checkedProfissionalExecutante: boolean, 
  dadosProfissionaisStepCompleted: boolean, 
  profExecutanteStepCompleted: boolean, 
  setDadosProfissionaisStepCompleted: (isCompleted: boolean) => void,
  setProfExecutanteStepCompleted: (isCompleted: boolean) => void,
  setCheckedProfissionalExecutante: (checked: boolean) => void,
  setStep3: (newData: any) => void;
  setStep4: (newData: any) => void;
  setDadosProfissionais: (newData: any) => void;
}

export const CreateProfissionalSaudeContext = createContext(
  {} as CreateProfissionalSaudeData
);
