import { Controller } from 'react-hook-form';

import { Checkbox } from 'src/components/_UI/Checkbox';

interface SecretaryCustomizationProps {
  control: any;
}

function SecretaryCustomization({ control }: SecretaryCustomizationProps) {
  return (
    <div className="p-col-12 p-xl-6 p-px-0">
      <div className="checkboxescustom p-col-12 p-xl-6">
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Restringir bloqueio de horários"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="restringirBloqueioHorario"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Restringir bloqueio consultórios APS"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="restringirBloqueioAps"
        />
      </div>
    </div>
  );
}

export default SecretaryCustomization;
