import { useState } from 'react';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

type ProcedimentosSolicitadosQuantidadeChangeType = {
  quantidade: number;
  isSubmitting: boolean;
  handleBlurQuantidade: (e: any) => void;
  handleChangeQuantidade: (e: any) => void;
};

interface ProcedimentosSolicitadosQuantidadeChangeProps {
  quantidadeProcedimento: number | null;
  idAtendimento: number;
  idProcedimento: number;
}

export const useProcedimentosSolicitadosQuantidadeChange = (
  props: ProcedimentosSolicitadosQuantidadeChangeProps,
): ProcedimentosSolicitadosQuantidadeChangeType => {
  const { quantidadeProcedimento, idAtendimento, idProcedimento } = props;

  const { handleUpdateProcedimentoPropValue } = useSolicitacaoExameCirurgia();

  const initialQuantidadeValue = quantidadeProcedimento || 1;

  const [quantidade, setQuantidade] = useState(initialQuantidadeValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffectSkipFirst(() => {
    if (quantidadeProcedimento) setQuantidade(initialQuantidadeValue);
  }, [quantidadeProcedimento]);

  const handleBlurQuantidade = async () => {
    if (quantidade === initialQuantidadeValue) return;

    try {
      const payload = {
        quantidade,
      };

      setIsSubmitting(true);

      await SolicitacoesProcedimentosAPI.putQuantidade(
        idAtendimento,
        idProcedimento,
        payload,
      );

      handleUpdateProcedimentoPropValue(
        idProcedimento,
        'quantidade',
        quantidade,
      );
    } catch (error) {
      setQuantidade(initialQuantidadeValue);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeQuantidade = (e: any) => setQuantidade(e.target.value);

  return {
    quantidade,
    isSubmitting,
    handleBlurQuantidade,
    handleChangeQuantidade,
  };
};
