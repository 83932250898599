import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';
import { useUserAccessData } from 'src/core/hooks/ManterUsuario/useUserAccessData';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { dateToRequest } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

import GeneralForm from './General/General';
import HealthProfessionalForm from './HealthProfessional/HealthProfessional';

const formFields = {
  nome: '',
  idSexo: '',
  dataNascimento: '',
  cpf: '',
  emailPessoal: '',
  emailProfissional: '',
  conselhoTiss: '',
  profissional: '',
  numeroRegistro: '',
  idEstadoRegistro: '',
};

interface SexoOption {
  label: string;
  value: number;
}
interface FormMasterCreateUserStep2Props {
  handleBack(): void;
  nextStep(): void;
}

const FormMasterCreateUserStep2 = ({
  handleBack,
  nextStep,
}: FormMasterCreateUserStep2Props) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state: RootState) => state);

  const { idUser, setIdUser, saveMandatoryData } = useCreateUser();
  const { accessData } = useUserAccessData();
  const { selectedProfile, isProfessionalHealth } =
    useSelectProfileCreateUser();

  const [sexoOptions, setSexoOptions] = useState<SexoOption[] | []>([]);
  const [finishFormAfterSave, setFinishFormAfterSave] = useState<boolean>(true);
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Digite um valor para nome'),
    idSexo: Yup.string().nullable().required('Selecione um valor para sexo'),
    dataNascimento: Yup.date()
      .nullable()
      .required('Digite um valor para data de nascimento'),
    cpf: Yup.string().required('Digite um valor para CPF'),
    emailPessoal: Yup.string()
      .email('Formato de email inválido')
      .required('Digite um valor para email pessoal'),
    ...(isProfessionalHealth && {
      conselhoTiss: Yup.object().required('Selecione o conselho TISS'),
      profissional: Yup.object().required('Selecione a função do profissional'),
      numeroRegistro: Yup.string().required(
        'Digite o número do registro no conselho',
      ),
      idEstadoRegistro: Yup.string().required(
        'Selecione o estado do registro no conselho',
      ),
    }),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  useEffect(() => {
    async function loadSexos() {
      const sexosResponse = await UtilsAPI.GetSexos();

      const sexos =
        sexosResponse?.map(sexo => ({
          label: capitalizeFirstLetter(sexo.descricao),
          value: sexo.id,
        })) || [];

      setSexoOptions(sexos);

      setLoadingData(false);
    }

    loadSexos();
  }, []);

  const createUser = useCallback(
    async (payload: any) => {
      const createAction = isProfessionalHealth
        ? ProfissionalAPI.postProfissional
        : UsuarioAPI.postUsuario;

      const user = await createAction(payload);

      if (user?.status === 200) {
        setIdUser(user?.data?.idProfissionalSaude || user?.data?.id);

        if (!isProfessionalHealth && finishFormAfterSave)
          return navigate('/cadastros/usuario');

        return nextStep();
      }
    },
    [isProfessionalHealth, finishFormAfterSave, setIdUser, navigate, nextStep],
  );

  const updateUser = useCallback(
    async (payload: any) => {
      const updateAction = isProfessionalHealth
        ? ProfissionalAPI.updateProfissional
        : UsuarioAPI.updateUsuario;

      const user = await updateAction('', idUser, payload);

      if (user?.status === 200) nextStep();
    },
    [isProfessionalHealth, idUser, nextStep],
  );

  const handleSaveNewUser = async (form2: any) => {
    try {
      const dataNascimento = dateToRequest(form2?.dataNascimento)?.split(
        'T',
      )[0];
      const cpf = form2?.cpf?.match(/\d/g).join('');

      const { login, perfis, customization } = accessData;

      const { nome, emailPessoal, emailProfissional, numeroRegistro } = form2;

      const idSexo = Number(form2.idSexo);
      const idEstadoRegistro = Number(form2?.idEstadoRegistro);

      const aux: any = {
        ...customization,
        nome,
        emailPessoal,
        emailProfissional,
        numeroRegistro,
        login,
        perfis,
        idEmpresa: user?.idEmpresa,
        tipo: selectedProfile?.value,
        dataNascimento,
        idSexo,
        cpf,
        ...(idEstadoRegistro && { idEstadoRegistro }),
      };

      aux.idConselhoTiss = form2?.conselhoTiss?.id;
      aux.idProfissional = form2?.profissional?.id;

      saveMandatoryData({ ...form2, idEstadoRegistro, idSexo });

      if (!idUser) return createUser(aux);

      delete aux.login;
      delete aux.tipo;
      return updateUser(aux);
    } catch (error) {
      // error
    }
  };

  function handleFinishForm() {
    setFinishFormAfterSave(true);
    handleSubmit(handleSaveNewUser);
  }

  const handleNextStep = () => {
    setFinishFormAfterSave(false);
    handleSubmit(handleSaveNewUser);
  };

  function UpdateFormButtons() {
    return (
      <>
        <div className="p-col-12 p-md-6">
          <Button
            btnType="ghost"
            label="Cancelar"
            className="p-col-align-center p-d-flex"
            type="button"
            onClick={() => navigate('/cadastros/usuario')}
            stretch
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Button
            label="Salvar edições"
            btnType="tonal"
            type="submit"
            onClick={handleNextStep}
            stretch
          />
        </div>
      </>
    );
  }

  function FinishFormButtons() {
    return (
      <>
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
          {' '}
          Deseja preencher os dados opcionais ?{' '}
        </SimpleText>
        <div className="p-col-12 p-md-6">
          <Button
            btnType="outline"
            label="sim, preencher"
            className="p-col-align-center p-d-flex"
            type="submit"
            onClick={handleNextStep}
            loading={isSubmitting}
            stretch
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Button
            label="Não, concluir cadastro"
            type="submit"
            loading={isSubmitting}
            onClick={handleFinishForm}
            stretch
          />
        </div>
      </>
    );
  }

  function NextStepButtons() {
    return (
      <>
        <div className="p-col-12 p-md-6">
          <Button
            btnType="ghost"
            label="Voltar"
            className={'p-col-align-center p-d-flex'}
            type="button"
            onClick={handleBack}
            disabled={isSubmitting}
            stretch
          />
        </div>
        <div className="p-col-12 p-md-6">
          <Button
            label="Salvar"
            type="submit"
            loading={isSubmitting}
            btnType="tonal"
            onClick={handleNextStep}
            stretch
          />
        </div>
      </>
    );
  }

  function RenderButtons() {
    if (!!idUser) return UpdateFormButtons();

    if (isProfessionalHealth) return NextStepButtons();

    return FinishFormButtons();
  }

  if (loadingData)
    return (
      <div className="formulario">
        <div className="loadingdados">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <FormProvider {...form}>
      <form
        className="p-grid p-col-12"
        onSubmit={handleSubmit(handleSaveNewUser)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        <GeneralForm
          control={control}
          errors={errors}
          sexoOptions={sexoOptions}
          setValue={setValue}
        />

        {isProfessionalHealth && (
          <HealthProfessionalForm
            control={control}
            errors={errors}
            setValue={setValue}
          />
        )}

        {RenderButtons()}
      </form>
    </FormProvider>
  );
};

export default FormMasterCreateUserStep2;
