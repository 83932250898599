import useQuestionarioController from 'src/core/hooks/Questionario/useQuestionarioController';
import useSize from 'src/core/hooks/useSize';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import PanelOptionsMenu from '../../components/PanelOptionsMenu/PanelOptionsMenu';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

import './Row.scss';

interface RowProps {
  questionario: any;
  reloadList: () => void;
  disclosures: {
    novoDisclosure: DisclosureType;
    inativarDisclosure: DisclosureType;
    visualizarJustificativaDisclosure: DisclosureType;
    deletarDisclosure: DisclosureType;
  };
}

const Row = ({ questionario, disclosures }: RowProps) => {
  const { isMobile } = useSize();

  const {
    novoDisclosure,
    // inativarDisclosure,
    visualizarJustificativaDisclosure,
    deletarDisclosure,
  } = disclosures;

  const onVisualizarQuestionario = () => {
    novoDisclosure.open({ state: { questionario, readOnly: true } });
  };

  const onEdit = () => {
    novoDisclosure.open({ state: { questionario, readOnly: false } });
  };

  const onVisualizarJustificativa = () => {
    visualizarJustificativaDisclosure.open({ state: questionario });
  };

  const onExcluir = () => {
    deletarDisclosure.open({ state: questionario });
  };

  const optionsMenu = (questionario: Questionarios) => (
    <PanelOptionsMenu
      ativo={questionario.status === 'ATIVO'}
      hideLembreteOption
      onAlterarLembrete={() => null}
      onEdit={() => onEdit()}
      onInativar={() => {}}
      onExcluir={() => onExcluir()}
      onVisualizarJustificativa={() => onVisualizarJustificativa()}
      hideEditOption={questionario?.validarOuRejeitar !== 'PENDENTE'}
      hideExcluirOption={questionario?.validarOuRejeitar !== 'PENDENTE'}
      hideInativarOption
      questionarioPendente={questionario?.validarOuRejeitar === 'PENDENTE'}
    />
  );

  const formatStatus = (status?: string) => {
    if (status === 'PENDENTE') {
      return 'Pendente validação';
    }

    if (status === 'REJEITADO') {
      return 'Rejeitado';
    }

    if (status === 'VALIDADO') {
      return 'Validado';
    }
    return '';
  };

  const headerTextClassName = `p-mt-2  ${
    questionario.status === 'INATIVO' ? 'motivo-inativo' : ''
  }`;

  const commomProps = {
    questionario,
    onVisualizarQuestionario,
    optionsMenu,
    formatStatus,
    headerTextClassName,
  };

  if (isMobile) {
    return <MobileRow {...commomProps} />;
  }

  return <DesktopRow {...commomProps} />;
};

export default Row;
