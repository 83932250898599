import { useState, useEffect, useCallback } from 'react';

import Dropzone, { Accept } from 'react-dropzone';

import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Avatar from 'src/components/Avatar/Avatar';

import './AvatarUpload.scss';

const accept: Accept = {
  'image/jpeg': ['.jpeg', '.png'],
};

interface AvatarUploadProps {
  urlFoto: any;
  className?: string;
}

export const AvatarUpload = ({ urlFoto, className }: AvatarUploadProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [foto, setFoto] = useState<any>();

  const saveImagePaciente = useCallback(
    async (foto: string) => {
      const payload = {
        idEmpresa: user?.idEmpresa,
        foto,
      };

      await PacienteAPI.updatePacienteFoto(Number(user.idPaciente), payload);
    },
    [user?.idPaciente, user?.idEmpresa],
  );

  const uploadImage = useCallback(
    async (file: File) => {
      const dataFile: FileUpload = {
        fileName: file?.name,
        contentType: file?.type,
        contentLength: file?.size,
        resourceType: 'OUTROS',
        resourceId: 1,
      };

      const responseGoogleStorage: any =
        await FileUploadAPI.sendImageComunicado(dataFile);

      if (responseGoogleStorage.status !== 200) return null;

      const res = await FileUploadAPI.updateFileUpload(
        responseGoogleStorage.data.uploadUrl,
        file,
      );

      if (res?.status === 200)
        return saveImagePaciente(responseGoogleStorage.data.filePath);
    },
    [saveImagePaciente],
  );

  useEffect(() => {
    if (foto?.path && user?.idPaciente) uploadImage(foto);
  }, [foto, user?.idPaciente, uploadImage]);

  let avatarClassName = 'avatar-upload-paciente';

  if (className) avatarClassName += ` ${className}`;

  return (
    <div className={avatarClassName}>
      <Dropzone
        accept={accept}
        onDrop={(f: any) => {
          setFoto(f[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="paciente-dropzone">
              {foto?.path ? (
                <img
                  style={{ objectFit: 'cover' }}
                  src={URL.createObjectURL(foto)}
                  alt="Foto do paciente"
                />
              ) : (
                <Avatar icon={urlFoto ? '' : 'fas fa-camera'} image={urlFoto} />
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};
