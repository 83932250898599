import React, { useCallback, useState } from 'react';

import DocumentosAssinadosAPI from 'src/APIs/ProntuarioAPI/DocumentosAssinadosAPI/DocumentosAssinadosAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Filter, Header, MobileRow } from './List';
import ModalVisualizarEmail from './ModalVisualizarEmail';

export default function RelatoriosEmailsAssinatura() {
  const [reload, setReload] = useState<number>(0);
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [searchParams, setSearchParams] = useState<any>();

  const { isMobile } = useSize();

  const modalVisualizarEmail = useDisclosure({ opened: false });

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    const response = await DocumentosAssinadosAPI.getDocumentosAssinados({
      ...params,
    });
    setSearchParams(params);

    return response;
  };

  const onVisualizarEmail = useCallback(
    (data: any) => {
      setSelectedEmail(data);
      modalVisualizarEmail.open();
    },
    [modalVisualizarEmail],
  );

  const renderRow = (data: any, isMobile: boolean): JSX.Element => {
    const commonProps = {
      email: data,
      onVisualizarEmail,
    };

    if (isMobile) return <MobileRow {...commonProps} />;

    return (
      <SearchList.CardItem>
        <DesktopRow {...commonProps} />
      </SearchList.CardItem>
    );
  };

  const handleImprimir = async (params: any) => {
    delete params.page;
    const response =
      await DocumentosAssinadosAPI.getRelatorioDocumentosAssinados(params);
    const file = new File([response], 'report.pdf', {
      type: 'application/pdf',
    });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(file);

    window.open(link.href, '_blank');
  };

  return (
    <>
      <SearchList.Root
        fetchApi={fetchApi}
        reloadWhenEmpresaChange
        empresaRequired
      >
        <div className="p-d-flex p-jc-between p-ai-center">
          <SearchList.SearchInput
            label="Pesquisar usuários"
            placeholder="Digite nome, login, CRM ou CPF"
            className={isMobile ? 'p-mb-2' : ''}
          />
          <Filter />
        </div>
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-p-1">
            <Button
              type="button"
              label="Imprimir relatório"
              icon="fas fa-print"
              btnType="pill"
              onClick={() => handleImprimir(searchParams)}
            />
            <>
              <SearchList.TotalCount className={isMobile ? 'p-mb-2' : ''} />
            </>
          </div>

          <SearchList.Header>
            <Header />
          </SearchList.Header>
          <ErrorBoundary>
            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </ErrorBoundary>
        </SearchList.BorderContainer>
      </SearchList.Root>
      {modalVisualizarEmail.isOpen && (
        <ModalVisualizarEmail {...modalVisualizarEmail} email={selectedEmail} />
      )}
    </>
  );
}
