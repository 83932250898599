import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';

import { Button } from 'src/components/_UI';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import { LogoUpload } from './LogoUpload';
import TipoPlano from './TipoPlano/TipoPlano';

import './FormAdditionalInfo.scss';

interface FormAdditionalInfoProps {
  goToNextStep: () => void;
}

export const FormAdditionalInfo = (props: FormAdditionalInfoProps) => {
  const { goToNextStep } = props;

  const navigate = useNavigate();

  const {
    basicInfoStepData,
    additionalInfoStepData,
    idConvenioEdit,
    setAdditionalInfoStepData,
  } = useManterConvenio();

  const useFormMethods = useForm({
    defaultValues: {
      exibirRelatorios: false,
      exibirSistema: false,
      ...(additionalInfoStepData || {}),
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    try {
      delete data.urlLogotipo;

      const { endereco = {}, isParticular } = basicInfoStepData || {};

      const payload = {
        ...basicInfoStepData,
        ...(!!Object.keys(endereco).length && {
          endereco: {
            ...endereco,
            idPaisEstrangeiro:
              endereco?.idPaisEstrangeiro === 0
                ? null
                : endereco?.idPaisEstrangeiro,
          },
        }),
        ...data,
        ...(isParticular && { tipoConvenio: 'PARTICULAR' }),
      };

      const saveConvenio =
        idConvenioEdit !== null
          ? ConvenioAPI.updateConvenio
          : ConvenioAPI.createConvenio;

      const response = await saveConvenio(payload, idConvenioEdit as number);

      setAdditionalInfoStepData(prevState => ({
        ...prevState,
        ...data,
      }));

      if (!idConvenioEdit) {
        return navigate(`/cadastros/convenio/${response.id}`, {
          state: {
            toStep: 2,
          },
        });
      }

      goToNextStep();
    } catch {
      //error
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form
        className="p-grid p-col-12 p-md-10 p-lg-8 p-xl-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LogoUpload />

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
          Customização
        </SimpleText>

        <div className="p-col-12">
          <div className="border-container">
            <CheckboxControlled
              className="checkbox-small p-mt-0"
              control={control}
              name="exibirRelatorios"
              label="Exibir logo do convênio nos relatórios gerados"
            />

            <CheckboxControlled
              className="checkbox-small"
              control={control}
              name="exibirSistema"
              label="Exibir logo do convênio no sistema"
            />

            {!basicInfoStepData?.isParticular && (
              <CheckboxControlled
                className="checkbox-small"
                control={control}
                name="exibirCuidadorViverBemFolhaRosto"
                label="Exibir Cuidador viver bem na folha rosto"
              />
            )}
          </div>
        </div>

        {!basicInfoStepData?.isParticular && (
          <>
            <SimpleText
              className="p-col-12"
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Carteirinha
            </SimpleText>

            <div className="p-col-12">
              <div className="border-container">
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  Aqui você informa qual será a máscara da carteirinha
                </SimpleText>

                <TextInputControlled
                  className="p-mt-2"
                  control={control}
                  name="mascaraCarterinha"
                  label="Máscara da carteirinha"
                  maxLength={30}
                />
              </div>
            </div>

            <SimpleText
              className="p-col-12"
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Tipo plano
            </SimpleText>

            <TipoPlano />
          </>
        )}

        <div className="p-col-12 p-md-3">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={() => navigate('/cadastros/convenio', { replace: true })}
            stretch
          />
        </div>

        <div className="p-col-12 p-md-9">
          <Button
            btnType="tonal"
            type="submit"
            label="Salvar"
            loading={isSubmitting}
            stretch
          />
        </div>
      </form>
    </FormProvider>
  );
};
