import React from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import './ModalVinculos.scss';

interface ModalVinculosProps extends DisclosureType {
  vinculos: any;
}

const ModalVinculos = ({ close, vinculos }: ModalVinculosProps) => {
  const { consultorios, convenios } = vinculos;
  return (
    <Dialog
      header={'Vínculos'}
      visible={true}
      onHide={close}
      style={{ width: '500px' }}
      id="modal-vinculos"
      maximizedMobileSize
    >
      <>
        <Tab
          fullHeight
          clean
          noPanel
          headerColor={false}
          className="p-w-100"
          values={[
            {
              label: 'Consultórios',
              content: <ListaConsiltorios consultorios={consultorios} />,
            },
            {
              label: 'Convênios',
              content: <ListaConvenios convenios={convenios} />,
            },
          ]}
        />
        <Button
          label="Fechar"
          btnType="outline"
          onClick={() => close()}
          className="p-px-4 p-mt-4"
          stretch
        />
      </>
    </Dialog>
  );
};

export default ModalVinculos;

const ListaConsiltorios = ({ consultorios }: any) => {
  return (
    <div className="lista-vinculos p-p-3">
      <SimpleText fontColor="color_60">Nome do consultório</SimpleText>
      {consultorios?.map((item: any) => (
        <div className="row-component p-p-2 p-my-1" key={item.id}>
          <SimpleText medium>{item.nome}</SimpleText>
        </div>
      ))}
    </div>
  );
};

const ListaConvenios = ({ convenios }: any) => {
  return (
    <div className="lista-vinculos p-p-3">
      <SimpleText fontColor="color_60">Nome do convênio</SimpleText>
      {convenios?.map((item: any) => (
        <div className="row-component p-p-2 p-my-1" key={item.id}>
          <SimpleText medium>{item.nome}</SimpleText>
        </div>
      ))}
    </div>
  );
};
