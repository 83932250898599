import Http, { HttpParams } from 'src/core/http/Http';

export const getTemplateEmail = (
  idProfissional: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.get({
    service: 'adm',
    url: `/configuracoes-template-email/profissional/${idProfissional}`,
    hideToast: true,
    errorHideToast: true,
    return: true,
    ...options,
  });
};

export const criarTemplateEmail = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'adm',
    url: '/configuracoes-template-email',
    data: payload,
    hideToast: true,
    errorHideToast: false,
    return: true,
    ...options,
  });
};

export const editarTemplateEmail = (
  payload: any,
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.put({
    service: 'adm',
    url: `/configuracoes-template-email/${id}`,
    data: payload,
    errorHideToast: false,
    return: true,
    ...options,
  });
};

const TemplateEmailAPI = {
  getTemplateEmail,
  criarTemplateEmail,
  editarTemplateEmail,
};

export default TemplateEmailAPI;
