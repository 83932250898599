import { useState } from 'react';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Support from 'src/components/LoginLayout/Support/Support';

const HelpConfigSubMenu = () => {
  const [supportVisible, setSupportVisible] = useState<boolean>(false);

  return (
    <>
      <div
        onClick={() => setSupportVisible(true)}
        className={'ItemsConfigSubMenu'}
      >
        <Button icon="fa fa-bullhorn" btnType="gray" />

        <div className={'content'}>
          <SimpleText bold>Solicitar Ajuda</SimpleText>
        </div>
      </div>

      {supportVisible && (
        <Support
          interno={true}
          supportVisible={supportVisible}
          onHideSupport={() => setSupportVisible(false)}
        />
      )}
    </>
  );
};

export default HelpConfigSubMenu;
