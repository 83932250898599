import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';

import AdminAPI from 'src/APIs/AdminAPI/AdminAPI';

import './SearchListProfessionals.scss';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';

const { ProfissionalAPI } = AdminAPI;

interface SearchListProfessionalProps {
  selected: any;
  companyProfessionalsList: any | [];
  onSelectItem(v: any): void;
  onRemoveItem(v: any): void;
}

const SearchListProfessionals = (props: SearchListProfessionalProps) => {
  const { selected, companyProfessionalsList, onSelectItem, onRemoveItem } =
    props;

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>(
    companyProfessionalsList.sort(sortProfessionalsListByName),
  );

  function sortProfessionalsListByName(a: any, b: any) {
    return a?.nome < b?.nome ? -1 : 1;
  }

  const onChangeSearch = useCallback(
    async (value: string) => {
      if (!value) return setList(companyProfessionalsList);

      setLoading(true);

      const professionalsListFilterBySearch = companyProfessionalsList.filter(
        (professional: any) =>
          professional.nome.toLowerCase().includes(value.toLowerCase()),
      );

      setList(
        professionalsListFilterBySearch?.sort(sortProfessionalsListByName),
      );
      setLoading(false);
    },
    [companyProfessionalsList],
  );

  const selectedList = useMemo(
    () =>
      selected.filter((i: any) =>
        companyProfessionalsList?.some((s: any) => s?.id === i?.id),
      ) || [],
    [companyProfessionalsList, selected],
  );

  const unselectedList = useMemo(
    () => list.filter((i: any) => !selected?.some((s: any) => s?.id === i?.id)),
    [list, selected],
  );

  return (
    <div className="consultorio-search-list-professional">
      <TextInputSearch
        autoFocus
        placeholder="Adicione usuários pesquisando"
        onChange={onChangeSearch}
      />

      <div className="search-list">
        {selectedList.map((item: any) => (
          <SearchListItemProfessionals
            selected
            key={Math.random().toString()}
            item={item}
            onClick={onRemoveItem}
          />
        ))}
        <>
          {loading ? (
            <>
              <div style={{ marginBottom: 8 }}>
                <Skeleton height="56px" borderRadius="8px" />
              </div>
            </>
          ) : (
            <>
              {unselectedList.length ? (
                unselectedList?.map((item: any) => (
                  <SearchListItemProfessionals
                    key={Math.random().toString()}
                    item={item}
                    onClick={onSelectItem}
                  />
                ))
              ) : (
                <div className="search-list-empty">
                  Nenhum profissional correspondente a pesquisa.
                </div>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

interface SearchListItemProfessionalsProps {
  item: any;
  onClick(v: Profissional): void;
  selected?: boolean;
}

const SearchListItemProfessionals = memo(
  (props: SearchListItemProfessionalsProps) => {
    const { item, onClick, selected } = props;

    const tipo =
      item?.tipo === 'PROFISSIONAL_SAUDE'
        ? item?.profissionalSaude?.conselhoTissProfissional.descricao
        : 'Secretária';

    return (
      <button
        onClick={() => onClick(item)}
        className={`
          consultorio-search-professional-item
          ${selected ? 'item-selected' : ''}
        `}
      >
        <Avatar label={item.nome} className="avatar" />

        <SimpleText
          className="item-title"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {item?.nome}
        </SimpleText>

        <div className="item-description">
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
            {tipo}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
            {item?.tipo === 'PROFISSIONAL_SAUDE'
              ? `${item?.profissionalSaude?.conselhoTiss.sigla}${item?.profissionalSaude?.numeroRegistro}`
              : ''}
          </SimpleText>
        </div>

        <span
          className={`
            item-button fas
            ${selected ? 'item-button-selected fa-minus' : ' fa-plus'}
          `}
        />
      </button>
    );
  },
);

export default memo(SearchListProfessionals);
