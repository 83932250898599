import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHIProps {
  isCollapsed: boolean;
  onToggle: () => void;
}

export default function PanelHI({ isCollapsed, onToggle }: PanelHIProps) {
  return (
    <div className="p-d-flex p-jc-between p-ai-center p-px-2">
      <div className="p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Tipo Exame
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          HI
        </SimpleText>
      </div>
      <Button
        icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
        onClick={onToggle}
        btnType="ghost"
      />
    </div>
  );
}
