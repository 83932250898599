import dayjs from 'dayjs';
import * as Yup from 'yup';

export const diffCurrentDateFromGivenDate = (date: Date) => {
  const currentDate = dayjs(new Date());

  const diferrenceBetweenDates = currentDate.diff(date, 'day');

  return diferrenceBetweenDates;
};

export const checkIsRetroativo = (
  dataOcorrencia: Date,
  dataInclusao: string | undefined,
) => {
  const dataInclusaoFormatted = dataInclusao ? new Date(dataInclusao) : null;

  const dataOcorrenciaIsToday =
    diffCurrentDateFromGivenDate(dataOcorrencia) === 0;

  if (!dataInclusaoFormatted) return !dataOcorrenciaIsToday;

  return dayjs(dataInclusaoFormatted).diff(dataOcorrencia, 'day') > 0;
};

const checkJustificativaOcorrenciaRetroativaIsRequired = (
  dataOcorrencia: any,
  dataInclusao: any,
  field: any,
) => {
  const dataOcorrenciaIsNotToday =
    diffCurrentDateFromGivenDate(dataOcorrencia) > 0;

  const isRetroativo = checkIsRetroativo(dataOcorrencia, dataInclusao);

  if (dataOcorrenciaIsNotToday && isRetroativo) {
    return field.required('Informe a justificativa');
  }

  return field;
};

export const justificativaOcorrenciaRetroativaValidation = (
  dataInclusao: any,
) =>
  Yup.string()
    .nullable()
    .when('dataOcorrencia', (dataOcorrencia, field) =>
      checkJustificativaOcorrenciaRetroativaIsRequired(
        dataOcorrencia,
        dataInclusao,
        field,
      ),
    );
