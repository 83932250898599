import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktpRowProps {
  profissional: any;
  grupoPontuacao: JSX.Element;
}

const DesktopRow = ({ profissional, grupoPontuacao }: DesktpRowProps) => {
  return (
    <CardItem>
      <div className="p-grid p-col-6 p-lg-4 p-px-0">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
          className="p-col-12 p-pb-0"
        >
          {profissional.usuario.nome}
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-col-12"
        >
          {profissional.conselhoTissProfissional?.descricao} •{' '}
          {profissional.conselhoTiss?.sigla}/{profissional.estadoRegistro.sigla}{' '}
          • {profissional.numeroRegistro}
        </SimpleText>
      </div>
      <div className="p-col-6 p-lg-4">{grupoPontuacao}</div>
    </CardItem>
  );
};

export default DesktopRow;
