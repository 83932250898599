import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Tab from 'src/components/Tab/Tab';
import { AgradecimentoDialog } from 'src/pages/Portal/Admin/Home/components/AgradecimentoDialog/AgradecimentoDialog';

import { NotificationPortalProps } from '../../useNotifications';
import { NavBarNotificacaoSistema } from '../NavBarNotificacao/NavBarNotificacaoSistema';

import { NavBarNotificacaoComunicados } from './NavBarNotificacaoComunicados';

type Props = Omit<NotificationPortalProps, 'qtdeNotificacoesUnread'>;

export const NavBarNotificacao = (items: Props) => {
  const { hashEmpresa } = useParams();

  const [empresaLogo, setEmpresaLogo] = useState('');

  const feedbackAgradecimentoDialog = useDisclosure({ opened: false });

  const sistemaNotification = {
    notifications: items.notifications,
    unreadNotifications: items.unreadNotifications,
    readNotifications: items.readNotifications,
    isLoading: items.isLoading,
  };
  const sistemaComunicados = {
    comunicados: items.comunicados,
    unreadComunicados: items.unreadComunicados,
    readComunicados: items.readComunicados,
    isLoading: items.isLoading,
  };

  useEffect(() => {
    PortalPacienteAPI.getDadosIniciaisEmpresa({
      hashEmpresa: hashEmpresa!,
    }).then(data => {
      setEmpresaLogo(data.logoUrl);
    });
  }, [hashEmpresa]);

  return (
    <div className="p-d-flex p-flex-column p-gap-1">
      <SimpleText fontSize="sm">Notificações</SimpleText>

      <Tab
        clean
        values={[
          {
            label: 'Sistema',
            content: (
              <NavBarNotificacaoSistema
                onSuccessNotification={feedbackAgradecimentoDialog.open}
                {...sistemaNotification}
              />
            ),
          },
          {
            label: 'Comunicados',
            content: (
              <NavBarNotificacaoComunicados
                empresaLogo={empresaLogo}
                {...sistemaComunicados}
              />
            ),
          },
        ]}
      />

      {feedbackAgradecimentoDialog.isOpen && (
        <AgradecimentoDialog {...feedbackAgradecimentoDialog} />
      )}
    </div>
  );
};
