import { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import OpmeAPI from 'src/APIs/ProntuarioAPI/OpmeAPI/OpmeAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import './ModalNovaOpme.scss';
interface ModalNovaVacinaProps {
  visible: boolean;
  editOpme: Opme | null;
  onHide: () => void;
  onSuccess: () => void;
}

function ModalNovaVacina({
  visible,
  editOpme,
  onHide,
  onSuccess,
}: ModalNovaVacinaProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const { user } = useAppSelector((state: RootState) => state);

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    quantidade: Yup.string().required('Informe a quantidade'),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(editOpme?.dataInclusao),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [idMaterialSelect, setIdMaterialSelect] = useState<number | null>(null);
  const [showQuantidadeEdit, setShowQuantidadeEdit] = useState<boolean>(false);

  useEffect(() => {
    reset();

    if (editOpme) {
      setSearchQuery(editOpme.material.nomeComercial);
      setValue('quantidade', editOpme.quantidade);
      setValue('observacoes', editOpme?.observacoes || '');
      setValue(
        'justificativaOcorrenciaRetroativa',
        editOpme?.justificativaOcorrenciaRetroativa || '',
      );
    } else {
      setSearchQuery('');
      setIdMaterialSelect(null);
      setShowQuantidadeEdit(false);
    }
  }, [visible, reset, editOpme, setValue]);

  useEffect(() => {
    if (editOpme) {
      setSearchQuery(editOpme.material.nomeComercial);
      setIdMaterialSelect(editOpme.idMaterial);
    }
  }, [editOpme]);

  const clearSelectedMaterial = () => {
    setValue('quantidade', '');
    setIdMaterialSelect(null);
    setShowQuantidadeEdit(false);
  };

  const loadMateriais = useCallback(
    async (params: any) => {
      return OpmeAPI.getMateriais({
        query: searchQuery,
        pageSize: 4,
        ...params,
      });
    },
    [searchQuery],
  );

  const saveAction = useCallback(
    (payload: any) => {
      if (!editOpme) return OpmeAPI.adicionarOpme(payload);

      return OpmeAPI.alterarOpme(editOpme.id, Number(idAtendimento), payload);
    },
    [editOpme, idAtendimento],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        ...data,
        idMaterial: idMaterialSelect,
        idEmpresa: user.idEmpresa,
        idAtendimento: Number(idAtendimento),
        dataOcorrencia: dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
      };

      const response = await saveAction(payload);

      if (response?.status === 200 || response?.status === 201) {
        onSuccess();
        onHide();
      }
    },
    [
      idMaterialSelect,
      user.idEmpresa,
      idAtendimento,
      saveAction,
      onSuccess,
      onHide,
    ],
  );

  const checkDisabledOnEdit = useMemo(() => {
    if (!editOpme) return false;

    return !(
      editOpme.idAtendimento === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [atendimentoStatus, editOpme, idAtendimento]);

  const quantidadeWatch = watch('quantidade');

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const renderRow = (material: any) => {
    const getMaterialClassName = () => {
      if (!idMaterialSelect) return '';

      if (idMaterialSelect === material.id) return 'material-selecionado';

      return 'material-desabilitado';
    };

    const materialClassName = getMaterialClassName();

    return (
      <div
        className={`material p-grid ${materialClassName}`}
        onClick={(e: any) => {
          if (
            e.target.className.includes('button') ||
            material.id === idMaterialSelect
          )
            return;
          setIdMaterialSelect(material.id);
          setValue('quantidade', '1');
        }}
      >
        <div className="p-col-8 p-d-flex p-flex-column">
          <SimpleText fontColor={FONT_COLOR.COLOR_16}>
            Nome Comercial
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              <i className="p-ml-3 p-mr-2 fas fa-tag" />
              {material.id}
            </SimpleText>
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {material.nomeComercial}
          </SimpleText>
        </div>
        <div className="p-col-4 p-d-flex p-jc-end p-ai-center">
          {idMaterialSelect === material.id && showQuantidadeEdit ? (
            <>
              <TextInputControlled
                className="p-mr-3"
                control={control}
                name="quantidade"
                placeholder="Quantidade"
                disabled={checkDisabledOnEdit}
                permitirApenasNumeros
              />
              <Button
                type="button"
                btnType="gray"
                icon="fas fa-check"
                onClick={() => {
                  setShowQuantidadeEdit(false);
                }}
                disabled={!quantidadeWatch}
              />
              <div className="p-mr-2" />
              <Button
                type="button"
                btnType="gray"
                icon="fas fa-times"
                onClick={clearSelectedMaterial}
              />
            </>
          ) : (
            <>
              <SimpleText className="p-mr-3" fontColor={FONT_COLOR.COLOR_16}>
                {idMaterialSelect === material.id ? quantidadeWatch : '1'}
              </SimpleText>
              <Button
                type="button"
                btnType="gray"
                icon="pi pi-pencil"
                disabled={checkDisabledOnEdit}
                onClick={() => {
                  if (idMaterialSelect !== material.id) {
                    setValue('quantidade', '1');
                  }
                  setIdMaterialSelect(material.id);
                  setShowQuantidadeEdit(true);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      id="modal-nova-opme"
      type="modal"
      header={editOpme ? 'Editar OPME' : 'Adicionar OPME'}
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
    >
      <form id="form-opme" className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <DataOcorrencia
          control={control}
          defaultValue={
            editOpme ? new Date(editOpme.dataOcorrencia) : new Date()
          }
          dataInclusao={editOpme?.dataInclusao}
          disabled={checkDisabledOnEdit}
        />

        <div className="p-col-12">
          <TextInputSearch
            placeholder="pesquisar"
            value={searchQuery}
            onChange={setSearchQuery}
            customOnChange={clearSelectedMaterial}
            disabled={checkDisabledOnEdit}
          />
        </div>

        <div className="materiais-content p-col-12">
          <InfiniteScroll fetchAPI={loadMateriais} renderRow={renderRow} />
        </div>
        {errors.quantidade?.message && (
          <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
            {errors.quantidade?.message}
          </SimpleText>
        )}

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="observacoes"
          label="Observações"
        />
      </form>
    </Dialog>
  );
}

export default ModalNovaVacina;
