import { useState, useCallback, useEffect } from 'react';

import _ from 'lodash';

//! @DEPRECATED
//! use "import { Checkbox, CheckboxProps } from 'src/components/_UI/Checkbox'" instead
import Checkbox, {
  CheckboxProps,
} from 'src/components/Basics/Checkbox/Checkbox';
import './CheckboxSearch.scss';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';

export interface CheckboxOption {
  value?: any;
  label?: string;
}

export interface CheckboxSearchProps
  extends Omit<CheckboxProps, 'disabled' | 'required'> {
  label?: string | undefined;
  checked?: boolean;
  options: CheckboxOption[] | undefined;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
}

const CheckboxSearch = ({
  value,
  onChange,
  options,
  ...props
}: CheckboxProps) => {
  const [filter, setFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  /** Used to remove selected options that not matches the filter */
  const removeUnmatchOptions = useCallback(
    (optionsFiltered: any[] | undefined) => {
      const selectedOptions = value;

      if (value?.includes) {
        const filteredSelectedOptions = selectedOptions?.filter(
          (selectedOption: any) => optionsFiltered?.includes(selectedOption),
        );

        if (_.isEqual(filteredSelectedOptions, selectedOptions) === false) {
          onChange?.(filteredSelectedOptions);
        }
      }
    },
    [value, onChange],
  );

  const getFilteredOptions = useCallback((): CheckboxOption[] | undefined => {
    const filterText = filter
      .toLocaleLowerCase('pt-br')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const optionsFiltered = options?.filter(option => {
      const optionValue = option.label
        ?.toLocaleLowerCase('pt-br')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return optionValue?.includes(filterText);
    });

    const optionsFilteredValue = optionsFiltered?.map(option => option.value);

    removeUnmatchOptions(optionsFilteredValue);

    return optionsFiltered;
  }, [options, filter, removeUnmatchOptions]);

  useEffect(() => {
    const filteredOptions: any = getFilteredOptions();

    if (value?.length < filteredOptions?.length) {
      setSelectAll(false);
    }
  }, [value, getFilteredOptions]);

  const onChangeSelectAll = useCallback(() => {
    if (selectAll === false) {
      const filteredOptions = getFilteredOptions();
      const allOptions: any = filteredOptions?.map(option => option.value);
      onChange?.(allOptions);
    } else {
      const noOptions: any = [];
      onChange?.(noOptions);
    }

    setSelectAll(selectAll === false);
  }, [selectAll, getFilteredOptions, onChange]);

  return (
    <div className={'CheckboxSearch p-mt-2 p-mb-2'}>
      <div className={'p-ml-1 p-mb-2'}>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Filtre os resultados utilizando o campo de pesquisa abaixo.
        </SimpleText>
      </div>

      <TextInput
        value={filter}
        onChange={e => {
          setFilter(e.target.value);
        }}
        className={'input-text-checkbox p-mb-3'}
        placeholder={'Pesquisar'}
        icon={'pi pi-search'}
      />

      <Checkbox
        options={[{ label: 'Selecionar todos', value: 'selecionar_todos' }]}
        value={selectAll === true ? ['selecionar_todos'] : []}
        onChange={onChangeSelectAll}
        className={'p-ml-2'}
      />

      <div className={'custom-checkbox-search'}>
        <Checkbox
          key={value}
          value={value}
          onChange={onChange}
          options={getFilteredOptions()}
          {...props}
        />
      </div>
    </div>
  );
};

export default CheckboxSearch;
