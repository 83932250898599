import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/guia-tiss';

const getGuiaTissPdf = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.get({
    service: 'report',
    url: `${basePath}/${idAtendimento}`,
    hideToast: true,
    throwError: true,
    responseType: 'arraybuffer',
    ...options,
  });

export const GuiaTissAPI = {
  getGuiaTissPdf,
};
