import { memo, useState } from 'react';

import CentroCustoAPI from 'src/APIs/AdminAPI/CentroCustoAPI/CentroCustoAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface StatusCentroCustoProps {
  loadingStatus: boolean;
  onSwitchStatus: () => void;
  ativo: boolean;
}

const StatusCentroCusto = (props: StatusCentroCustoProps) => {
  const { loadingStatus, onSwitchStatus, ativo } = props;

  if (loadingStatus)
    return (
      <div className="p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return (
    <Switch
      checked={ativo}
      showStatus
      onChange={onSwitchStatus}
      checkPermission="ADM_MANTER_CENTRO_CUSTO_ALTERAR"
    />
  );
};

export default memo(StatusCentroCusto);
