import dayjs from 'dayjs';

import { enviarImagemDoComunicado } from './../../../Admin/Comunicado/helpers';

const sexoPaciente: any = {
  MASCULINO: 3,
  FEMININO: 1,
};

export const formatarDados = async (dados: any) => {
  const {
    id,
    idEmpresa,
    nome,
    cpf,
    cns,
    sexoDescricao,
    dataNascimento,
    email,
    nomeMae,
    maeDesconhecida,
    passaporte,
    estrangeiro,
    telefoneCelular,
    telefoneRecado,
    telefoneFixo,
    religiao,
    foto,
  } = dados;

  const contatos = [];

  if (telefoneCelular)
    contatos.push({
      numero: telefoneCelular.match(/\d/g).join(''),
      tipo: 'CELULAR_PARTICULAR',
    });

  if (telefoneRecado)
    contatos.push({
      numero: telefoneRecado.match(/\d/g).join(''),
      tipo: 'RECADO',
    });

  if (telefoneFixo)
    contatos.push({
      numero: telefoneFixo.match(/\d/g).join(''),
      tipo: 'FIXO_RESIDENCIAL',
    });

  const imagem = foto?.path ? await enviarImagemDoComunicado(foto) : foto;

  const paciente = {
    id,
    idEmpresa,
    nome,
    cpf,
    cns,
    idSexo: sexoPaciente[sexoDescricao],
    dataNascimento: dayjs(dataNascimento).format('YYYY-MM-DD'),
    email,
    nomeMae,
    maeDesconhecida,
    passaporte,
    estrangeiro,
    contatos,
    religiao,
    foto: imagem,
  };

  return paciente;
};
