import { useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';
import { InferType } from 'yup';

import { validationSchemaGeral } from './utils';

import { FieldFormulariosDinamico } from '../_components/FieldFormulariosDinamico';
import { FieldPublicoAplicacao } from '../_components/FieldPublicoAplicacao';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';

type FormData = InferType<typeof validationSchemaGeral>;

export const AplicacaoFormularioCreateGeral = ({
  item,
  handleNextPage,
  handleSetItem,
}: {
  item: IAplicacaoFormularioDinamicoItem | null;
  handleNextPage(): void;
  handleSetItem(item: IAplicacaoFormularioDinamicoItem): void;
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const defaultValues = useMemo(
    () => ({
      ...item,
      publico: { codigo: item?.publico || '' },
      ...(item?.dataInicioVigencia && {
        dataInicioVigencia: new Date(item.dataInicioVigencia),
      }),
      ...(item?.dataFimVigencia && {
        dataFimVigencia: new Date(item.dataFimVigencia),
      }),
      ...(item?.formularioDinamico && {
        formularioDinamico: item.formularioDinamico,
      }),
    }),
    [item],
  );

  const form = useForm<FormData>({
    resolver: yupResolver(validationSchemaGeral),
    defaultValues,
  });

  const viewOnly = state?.to !== 'edit' && !!item;

  const onSubmit = async (v: FormData) => {
    if (viewOnly) return handleNextPage();

    const payload = {
      idFormularioDinamico: v.formularioDinamico.id!,
      nome: v.nome,
      publico: v.publico.codigo,
      descricao: v.descricao,
      dataInicioVigencia: v.dataInicioVigencia,
      dataFimVigencia: v.dataFimVigencia,
      ...(item && { formularioDinamico: undefined }),
    };

    try {
      if (item) {
        const response =
          await AplicacaoFormularioDinamicoAPI.updateAplicacaoFormulario(
            { ...item, ...payload },
            { throwError: true },
          );
        return handleSetItem(response);
      }

      const response =
        await AplicacaoFormularioDinamicoAPI.createAplicacaoFormulario(
          payload,
          { throwError: true },
        );
      return navigate(
        `/cadastros/formulario-dinamico/aplicacao/${response.id}`,
        {
          state: {
            step: 1,
            to: 'edit',
          },
        },
      );
    } catch {}
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  return (
    <FormProvider {...form}>
      <form
        className="p-col-12 p-md-8 p-lg-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <fieldset
          className="p-d-flex p-flex-wrap p-w-100 p-p-0"
          disabled={viewOnly}
        >
          <FieldFormulariosDinamico
            name="formularioDinamico"
            label="Formulário"
            sublabel="(Obrigatório)"
            placeholder="Selecione"
            className="p-px-0 p-col-12"
            disabled={viewOnly}
            ativos
            hideTextErrorSpace
          />
          <FormInput
            name="nome"
            label="Nome da aplicação"
            placeholder="Nome da aplicação"
            hideTextErrorSpace
            className="p-px-0 p-col-12"
          />

          <FieldPublicoAplicacao
            name="publico"
            label="Público da aplicação"
            sublabel="(Obrigatório)"
            placeholder="Selecione"
            className="p-px-0 p-col-12"
            disabled={viewOnly}
            hideTextErrorSpace
          />
          <TextareaInputControlled
            control={form.control}
            name="descricao"
            label="Descrição"
            className="p-px-0 p-col-12"
          />

          <SimpleText className="p-col-12 p-px-0" medium>
            Vigência da aplicação
          </SimpleText>

          <CalendarInputControlled
            control={form.control}
            name="dataInicioVigencia"
            label="Data inicial"
            minDate={new Date()}
            sublabel="(Obrigatório)"
            placeholder="Selecione"
            showIcon
            className="p-pl-0 p-col-6"
          />
          <CalendarInputControlled
            control={form.control}
            name="dataFimVigencia"
            label="Data final"
            minDate={new Date()}
            placeholder="Selecione"
            showIcon
            className="p-pr-0 p-col-6"
          />
        </fieldset>

        <div className="p-w-100 p-d-flex p-gap-2 p-my-4">
          <Button
            label="Cancelar"
            btnType="ghost"
            stretch
            onClick={() => navigate('/cadastros/formulario-dinamico/aplicacao')}
            disabled={form.formState.isSubmitting}
          />
          <Button
            label="Próximo"
            type="submit"
            stretch
            loading={form.formState.isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};
