import { useState } from 'react';

import dayjs from 'dayjs';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const NovoAgendamentoConfirmacaoForm = ({
  handleSetCompleted,
  values,
  handleGoBack,
  handleSetData,
}: {
  handleSetCompleted(): void;
  handleSetData(v: any): void;
  handleGoBack(): void;
  values: any;
}) => {
  const [loading, setLoading] = useState(false);
  const { idEmpresa, idPaciente } = useAppSelector(state => state.user);

  const onSubmit = () => {
    setLoading(true);

    const flags: string[] = values.flagsAgendamento;
    if (!!values.numCarteirinha) flags.push('PARTICULAR');

    const payload = {
      numeroCartao: values.numCarteirinha,
      idEmpresa,
      idConvenio: values.idConvenio,
      flagsAtendimento: values.flags,
      idConsultorio: values.consultorio?.id,
      idMedico: values.profissional?.id,
      idPaciente,
      dataAgendamento: values.dataAgendamento,
      status: 'AGENDADO',
    };

    PortalPacienteAPI.sendPacienteAgendamento(values.idAgendamento, payload, {
      throwError: true,
    })
      .then(d => {
        handleSetCompleted(),
          handleSetData({
            linkSalaTeleconsulta: d.linkSalaEsperaTelemedicina,
          });
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const isTeleconsulta = values.flagsAgendamento?.includes('TELEATENDIMENTO');

  const endereco = values.consultorio?.endereco;
  const enderecoFormatted = isTeleconsulta
    ? 'Teleconsulta'
    : !!endereco
    ? `${endereco?.logradouro}, ${endereco?.bairro}, ${capitalizeFirstLetter(
        endereco?.cidade?.nome,
      )} - ${endereco?.cidade?.estado?.sigla}`
    : '';

  return (
    <div className="p-col-12 p-md-8 p-lg-6 p-d-flex p-flex-column p-gap-2 p-px-3">
      <SimpleText fontColor="color_60">
        Revise se as informações do agendamento estão corretas
      </SimpleText>

      <div className="p-border-container p-d-flex p-flex-column p-gap-3">
        <SimpleText fontSize="sm" medium>
          Informações do agendamento
        </SimpleText>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Médico</SimpleText>
          <SimpleText medium>{values.profissional.nome}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Data e hora</SimpleText>
          <SimpleText className="p-text-right">
            {dayjs(values.dataAgendamento).format(
              'dddd [,] D [de] MMM [de] YYYY [, às] HH:mm',
            )}
          </SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Local</SimpleText>
          <SimpleText className="p-text-right">{enderecoFormatted}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Contato</SimpleText>
          <SimpleText>{values.consultorio?.contatos?.[0]?.numero}</SimpleText>
        </div>

        <Divider layout="horizontal" />

        <SimpleText>Informações do paciente</SimpleText>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Benificiário</SimpleText>
          <SimpleText>{values.nome}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">CPF</SimpleText>
          <SimpleText>{values.cpf}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">N. Carteirinha</SimpleText>
          <SimpleText>{values.numCarteirinha || '-'}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Nome da mãe</SimpleText>
          <SimpleText>{values.nomeMae}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">E-mail</SimpleText>
          <SimpleText>{values.email}</SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">Celular</SimpleText>
          <SimpleText>{values.celular}</SimpleText>
        </div>

        <Divider type="dashed" layout="horizontal" className="p-mb-0" />
      </div>

      <div className="p-d-flex p-gap-2 p-mt-3">
        <Button
          label="Voltar"
          btnType="ghost"
          className="p-col-3"
          disabled={loading}
          onClick={handleGoBack}
        />
        <Button
          type="submit"
          label="Salvar e avançar"
          onClick={onSubmit}
          loading={loading}
          stretch
        />
      </div>
    </div>
  );
};
