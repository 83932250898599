import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  //TODO: tipagem do profissional
  profissional: any;
  grupoPontuacao: JSX.Element;
}

const MobileRow = ({ profissional, grupoPontuacao }: MobileRowProps) => {
  const options = [
    {
      label: 'Editar',
      command: () => {},
    },
  ];

  return (
    <SearchListMobileRow options={options} hideOptions>
      <div className="p-grid">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-col-12 p-pb-0"
        >
          Profissional Cuidador
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
          className="p-col-12 p-pt-1 p-pb-0"
        >
          {profissional.usuario.nome}
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-col-12 p-pt-1"
        >
          {profissional.conselhoTissProfissional?.descricao} •{' '}
          {profissional.conselhoTiss?.sigla}/{profissional.estadoRegistro.sigla}{' '}
          • {profissional.numeroRegistro}
        </SimpleText>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-col-12"
        >
          Grupo pontuação
        </SimpleText>
        <div className="p-col-12">{grupoPontuacao}</div>
      </div>
    </SearchListMobileRow>
  );
};

export default MobileRow;
