import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  conteudo: yup.string().required('Campo obrigatório').typeError('Campo obrigatório'),
  horario: yup
    .date()
    .required('Campo obrigatório')
    .typeError('Campo deve ser data/hora'),
  periodicidade: yup
    .string()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
});