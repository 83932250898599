import { Menu } from 'primereact/menu';
import { useLocation, useParams } from 'react-router';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useQuestionarioController from 'src/core/hooks/Questionario/useQuestionarioController';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import InativarQuestionario from './InativarQuestionario/InativarQuestionario';
import ModalNovoQuestionario from './ModalNovoQuestionario/ModalNovoQuestionario';
import ModalVisualizarJustificativa from './ModalVisualizarJustificativa/ModalVisualizarJustificativa';
import Row from './Row/Row';

import './Questionarios.scss';

const Questionarios = () => {
  const { atendimentoStatus } = useAtendimento();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const {
    confirmExcluir,
    deletarDisclosure,
    inativarDisclosure,
    visualizarJustificativaDisclosure,
    novoDisclosure,
    disclosures,
    fetchAPI,
    updateList,
    setUltimoQuestionario,
    reloadList,
    menuOptions,
    options,
  } = useQuestionarioController();

  const renderRow = (questionario: Questionarios) => {
    return (
      <Row
        questionario={questionario}
        reloadList={reloadList}
        disclosures={disclosures}
      />
    );
  };

  return (
    <div className="container-questionarios">
      <SearchList.Root
        fetchApi={fetchAPI}
        lastItem={setUltimoQuestionario}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Questionários
            </SimpleText>

            <>
              <Menu model={options} popup ref={menuOptions} />

              <Button
                className="p-mr-3"
                type="button"
                btnType="tonal"
                label="Novo questionário"
                icon={'fas fa-plus'}
                onClick={event => menuOptions.current.toggle(event)}
                disabled={
                  atendimentoStatus !== 'ATENDENDO' &&
                  atendimentoStatus !== 'ATENDENDO_TRIAGEM'
                }
              />
            </>
          </div>

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {novoDisclosure.isOpen && (
        <ModalNovoQuestionario
          idAtendimento={idAtendimento}
          reloadList={reloadList}
          {...novoDisclosure}
        />
      )}

      {inativarDisclosure.isOpen && (
        <InativarQuestionario
          idAtendimento={idAtendimento}
          reloadList={reloadList}
          {...inativarDisclosure}
        />
      )}

      {visualizarJustificativaDisclosure.isOpen && (
        <ModalVisualizarJustificativa {...visualizarJustificativaDisclosure} />
      )}

      {deletarDisclosure.isOpen && (
        <ConfirmDialog
          visible={deletarDisclosure.isOpen}
          header="Excluir questionário"
          text="O questionário será excluído. Você realmente deseja excluir?"
          confirmText="Sim, excluir"
          onHide={() => {
            deletarDisclosure.close();
          }}
          onConfirm={() => confirmExcluir(deletarDisclosure.state)}
        />
      )}
    </div>
  );
};

export default Questionarios;
