import { useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Skeleton from 'src/components/Skeleton/Skeleton';

import { statusHistoricoOptions } from '../../../helpers';

import './StatusButton.scss';

type StatusMenu = TieredMenu & {
  hide: () => void;
};

interface StatusButtonProps {
  idAtendimento: number;
  idProcedimento: number;
  isProcedimentoExterno: boolean;
  disabled: boolean;
  situacaoExame?: string;
}

const getStatusLabel = (statusValue: string | undefined) => {
  if (!statusValue) return 'Status';

  const statusOption = statusHistoricoOptions.find(
    status => status.value === statusValue,
  );

  return statusOption?.label || 'Status';
};

export const StatusButton = (props: StatusButtonProps) => {
  const {
    idAtendimento,
    idProcedimento,
    isProcedimentoExterno,
    situacaoExame,
    disabled,
  } = props;

  const statusMenu = useRef<StatusMenu>(null);

  const [statusProcedimento, setStatusProcedimento] = useState(
    getStatusLabel(situacaoExame),
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const handleStatusChange = async (status: string) => {
    try {
      const putStatus = isProcedimentoExterno
        ? SolicitacoesProcedimentosExternosAPI.putStatus
        : SolicitacoesProcedimentosAPI.putStatus;

      setIsUpdating(true);

      statusMenu?.current?.hide();

      await putStatus(idAtendimento, idProcedimento, status);

      setStatusProcedimento(getStatusLabel(status));
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  };

  const statusMenuItens: MenuItem[] = statusHistoricoOptions.flatMap(
    (statusOption: any) =>
      statusOption.label !== statusProcedimento
        ? {
            label: statusOption.label,
            command: () => handleStatusChange(statusOption.value),
          }
        : [],
  );

  useEffectSkipFirst(() => {
    const status = getStatusLabel(situacaoExame);
    setStatusProcedimento(status);
  }, [situacaoExame]);

  return (
    <>
      <Skeleton
        className="p-ml-3"
        loading={isUpdating}
        width="95px"
        height="28px"
        borderRadius="8px"
      >
        <button
          className={`status-button ${disabled ? 'disabled' : ''}`}
          onClick={event => statusMenu?.current?.toggle(event)}
          disabled={disabled}
        >
          {capitalizeFirstLetter(statusProcedimento)}
          <i className="fas fa-angle-down" />
        </button>
      </Skeleton>
      <TieredMenu
        className="solicitacao-exame-tiered-menu"
        ref={statusMenu}
        model={statusMenuItens}
        popup
      />
    </>
  );
};
