import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  migracao: any;
  onReverter: (migracao: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { migracao, onReverter } = props;

  const options = [
    {
      label: 'Reverter',
      command: () => onReverter(migracao),
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Data da ocorrência
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {dayjs(new Date(migracao?.dataOcorrencia)).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Login
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {migracao?.login}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Origem e destino
        </SimpleText>
        <br />
        <SimpleText
          className="p-mt-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          Origem: {migracao?.idOrigem} - {migracao?.nomePacienteOrigem} <br />{' '}
          Destino: {migracao?.idDestino} - {migracao?.nomePacienteDestino}
        </SimpleText>
        <div className="p-mt-2">
          <SimpleText
            className={` p-py-1 p-px-2 circle-${migracao?.status}`}
            fontSize={FONT_SIZE.XXS}
            medium
          >
            {migracao.status}
          </SimpleText>
        </div>
      </div>
    </SearchListMobileRow>
  );
};
