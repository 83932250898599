import { useCallback, useState } from 'react';

import { toast } from 'react-toastify';

import UsuariosSimultaneosAPI from 'src/APIs/ConfigAPI/UsuariosSimultaneos/UsuariosSimultaneosAPI';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Toast from 'src/components/Basics/Toast/Toast';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  data: any;
}

export const Row = (props: RowProps) => {
  const { data } = props;

  const { isMobile } = useSize();

  const [editRow, setEditRow] = useState(false);
  const [edit, setEdit] = useState(0);
  const [qtdSessoes, setQtdSessoes] = useState('');

  const handleEditRow = useCallback(
    async (data: any) => {
      const qtd = parseInt(qtdSessoes);

      const payload = data;
      payload.limiteSessoes = qtd;

      const response = await UsuariosSimultaneosAPI.updateUsuariosSimultaneos(
        payload,
      );

      if (response) {
        if (response.id) {
          const toastData = {
            title: 'Config. alteradas',
            message: 'Quantidade de sessões de usuário alterada com sucesso',
            type: 'success',
          };
          toast(<Toast />, { data: toastData });

          setEditRow(false);
        } else {
        }
      }
    },
    [qtdSessoes],
  );

  const renderEdit = () => {
    return (
      <>
        {isMobile && (
          <>
            {editRow && edit === data?.id ? (
              <div className="mobile-input">
                <TextInput
                  name="qtdSessoes"
                  value={qtdSessoes}
                  onChange={(e: any) => setQtdSessoes(e.target.value)}
                />
                <div className="mobile-buttons">
                  <Button
                    btnType="gray"
                    icon="pi pi-check"
                    onClick={() => handleEditRow(data)}
                    style={{ marginRight: 10 }}
                  />
                  <Button
                    btnType="gray"
                    icon="pi pi-times"
                    onClick={() => setEditRow(false)}
                    className="p-ml-1"
                  />
                </div>
              </div>
            ) : (
              <div>
                <SimpleText fontSize={FONT_SIZE.XXS}>
                  {data?.limiteSessoes}
                </SimpleText>
              </div>
            )}
          </>
        )}

        {!isMobile && (
          <>
            <div className="p-col-2 p-md-1">
              {editRow && edit === data?.id ? (
                <div>
                  <TextInput
                    name="qtdSessoes"
                    value={qtdSessoes}
                    onChange={(e: any) => setQtdSessoes(e.target.value)}
                  />
                </div>
              ) : (
                <div>
                  <SimpleText fontSize={FONT_SIZE.XXS}>
                    {data?.limiteSessoes}
                  </SimpleText>
                </div>
              )}
            </div>
            <div className="p-col-2 p-md-1 p-d-flex p-jc-end">
              <>
                {editRow && edit === data?.id ? (
                  <>
                    <Button
                      btnType="gray"
                      icon="pi pi-check"
                      onClick={() => handleEditRow(data)}
                      style={{ marginRight: 10 }}
                    />
                    <Button
                      btnType="gray"
                      icon="pi pi-times"
                      onClick={() => {
                        setEditRow(false);
                        setQtdSessoes(data?.limiteSessoes);
                      }}
                      className="p-ml-1"
                    />
                  </>
                ) : (
                  <Button
                    btnType={'gray'}
                    icon="pi pi-pencil"
                    checkPermission="ADM_USUARIOS_SIMULTANEOS_ALTERAR"
                    onClick={() => {
                      setQtdSessoes(data?.limiteSessoes);
                      setEdit(data?.id);
                      setEditRow(true);
                    }}
                  />
                )}
              </>
            </div>
          </>
        )}
      </>
    );
  };

  return isMobile ? (
    <MobileRow data={data} onEdit={handleEditRow} edit={renderEdit()} />
  ) : (
    <DesktopRow data={data} edit={renderEdit()} />
  );
};
