import Http, { HttpParams } from 'src/core/http/Http';

export const loadParametroById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetParametroDTO> => {
  return Http.get({
    service: 'adm',
    url: `parametros/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendParametro = (
  payload: PostParametroDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostParametroDTO> => {
  return Http.post({
    service: 'adm',
    url: '/parametros',
    data: payload,
    ...options,
  });
};

export const updateParametro = (
  id: number,
  payload: PostParametroDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostParametroDTO> => {
  return Http.put({
    service: 'adm',
    url: `/parametros/${id}`,
    data: payload,
    ...options,
  });
};

export const removeParametro = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `/parametros/${id}`,
    return: true,
    ...options,
  });
};

export const loadParametros = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetParametroDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: 'parametros/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};
export const loadParametrosByCodigo = (
  params: { codigo: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetParametroDTO> => {
  return Http.get({
    service: 'adm',
    url: 'parametros/buscar-por-codigo',
    params: params,
    hideToast: true,
    ...options,
  });
};

const ParametroAPI = {
  loadParametros,
  loadParametroById,
  sendParametro,
  updateParametro,
  removeParametro,
  loadParametrosByCodigo,
};

export default ParametroAPI;
