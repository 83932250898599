import { useEffect, useState, useMemo, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';
import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';
import PersonalizarProntuarioAPI from 'src/APIs/ProntuarioAPI/PersonalizarProntuarioAPI/PersonalizarProntuarioAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import { RadioButton } from 'src/components/_UI/RadioButton';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import Page from 'src/components/Basics/Page/Page';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Separator from 'src/components/Separator/Separator';
import Tab from 'src/components/Tab/Tab';

import ListBox from './ListBox/ListBox';

import './NovoModelo.scss';

function NovoModelo() {
  const { user } = useAppSelector((state: RootState) => state);

  const navigate = useNavigate();
  const { idModelo } = useParams();

  const validationSchema = Yup.object().shape({
    nomeModelo: Yup.string().required('Informe o nome do modelo'),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [camposObrigatorios, setCamposObrigatorios] = useState<any>([]);
  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);
  const [especialidades, setEspecialidades] = useState<Especialidade[]>([]);
  const [convenios, setConvenios] = useState<Convenio[]>([]);
  const [modeloPadrao, setModeloPadrao] = useState<boolean>(true);
  const [modeloVinculado, setModeloVinculado] = useState<boolean>(false);
  const [carregandoDados, setCarregandoDados] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);

  const getCamposObrigatorios = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await PersonalizarProntuarioAPI.loadCamposObrigatorios();

      if (response && !('status' in response)) {
        setCamposObrigatorios(Object.entries(response));
        resolve(Object.entries(response));
      }

      reject(response);
    });
  };

  const getConsultorios = async () => {
    return new Promise(async (resolve, reject) => {
      const response: any = await ConsultorioAPI.loadConsultorios({
        pageSize: 1000,
      });

      if (response && !('status' in response)) {
        setConsultorios(response.list);
        resolve(response.list);
      }

      reject(response);
    });
  };

  const getEspecialidades = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await EspecialidadeAPI.loadEspecialidades({
        pageSize: 1000,
      });

      if (response && !('status' in response)) {
        setEspecialidades(response.list);
        resolve(response.list);
      }

      reject(response);
    });
  };

  const getConvenios = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await ConvenioAPI.loadConveniosAtivos({
        pageSize: 1000,
      });

      if (response && !('status' in response)) {
        setConvenios(response.list);
        resolve(response.list);
      }

      reject(response);
    });
  };

  const getDadosModelo = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      if (!idModelo) return resolve(null);

      const response =
        await PersonalizarProntuarioAPI.getPersonalizarProntuarioById(
          Number(idModelo),
        );

      if (response && response?.id) {
        const camposObrigatoriosValues = response.camposObrigatorios.map(
          campoObrigatorio => campoObrigatorio.nomeCampoObrigatorio,
        );

        setValue('nomeModelo', response.nomeModelo);
        setValue('camposObrigatorios', camposObrigatoriosValues);
        setValue('triagem', false);

        if (response.tipoModelo === 'ESPECIFICO') {
          setModeloPadrao(false);
          setModeloVinculado(true);

          setValue('especialidades', response.especialidades || []);
          setValue('consultorios', response?.consultorios || []);
          setValue('convenios', response?.convenios || []);
        }

        resolve(response);
      }

      reject(response);
    });
  }, [idModelo, setValue]);

  const getDados = useCallback(async () => {
    setCarregandoDados(true);

    await Promise.all([
      getCamposObrigatorios(),
      getConsultorios(),
      getEspecialidades(),
      getConvenios(),
      getDadosModelo(),
    ]);

    setCarregandoDados(false);
  }, [getDadosModelo]);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const checkHasVinculosErrors = useCallback(() => {
    const [especialidades = [], consultorios = [], convenios = []] = getValues([
      'especialidades',
      'consultorios',
      'convenios',
    ]);

    if (
      modeloVinculado &&
      !especialidades?.length &&
      !consultorios?.length &&
      !convenios?.length
    ) {
      const error = {
        type: 'custom',
        message:
          'Selecione pelo menos uma especialidade ou consultório ou convênio',
      };

      setError('especialidades', error);

      setError('consultorios', error);

      setError('convenios', error);

      return true;
    }

    clearErrors(['especialidades', 'consultorios', 'convenios']);

    return false;
  }, [modeloVinculado, clearErrors, getValues, setError]);

  const voltarPersonalizarProntuario = useCallback(() => {
    navigate('/config/personalizacao-prontuarios', {
      replace: true,
    });
  }, [navigate]);

  const onSubmit = useCallback(
    async (data: any) => {
      const tipoModelo = modeloPadrao ? 'PADRAO' : 'ESPECIFICO';

      const { camposObrigatorios = [] } = data;

      const especialidades = modeloPadrao ? null : data.especialidades;
      const consultorios = modeloPadrao ? null : data.consultorios;
      const convenios = modeloPadrao ? null : data.convenios;
      const triagem = data.triagem;

      const payload = {
        ...data,
        camposObrigatorios,
        tipoModelo,
        especialidades,
        consultorios,
        convenios,
        triagem,
      };

      const hasErrors = checkHasVinculosErrors();

      if (hasErrors) return;

      const saveAction = idModelo
        ? PersonalizarProntuarioAPI.putPersonalizarProntuario
        : PersonalizarProntuarioAPI.postPersonalizarProntuario;

      const response: any = await saveAction(payload, Number(idModelo));

      if (response?.status === 200 || response?.status === 201)
        voltarPersonalizarProntuario();
    },
    [
      modeloPadrao,
      idModelo,
      checkHasVinculosErrors,
      voltarPersonalizarProntuario,
    ],
  );

  useEffect(() => {
    if (!user.idEmpresa) {
      setModeloPadrao(true);
      setModeloVinculado(false);
    }
  }, [user.idEmpresa]);

  const handleToggleModeloVinculo = useCallback(() => {
    setValue('especialidades', []);
    setValue('consultorios', []);
    setValue('convenios', []);

    if (!user.idEmpresa) {
      setModeloPadrao(true);
      return setModeloVinculado(false);
    }

    setModeloPadrao(currentState => !currentState);
    setModeloVinculado(currentState => !currentState);
  }, [user.idEmpresa, setValue]);

  const handleCheckTriagem = useCallback(
    (checked: boolean) => {
      setChecked(checked);
      setValue('triagem', checked);
    },
    [setValue],
  );

  const consultoriosOptions = useMemo(
    () =>
      consultorios.map(consultorio => ({
        label: consultorio.nome,
        value: {
          id: consultorio.id,
          nome: consultorio.nome,
        },
      })),
    [consultorios],
  );

  const especialidadesOptions = useMemo(
    () =>
      especialidades.map(especialidade => ({
        label: especialidade.nome,
        value: {
          id: especialidade.id,
          nome: especialidade.nome,
        },
      })),
    [especialidades],
  );

  const conveniosOptions = useMemo(
    () =>
      convenios.map(convenio => ({
        label: convenio?.nomeFantasia || convenio.razaoSocial,
        value: {
          id: convenio.id,
          nome: convenio?.nomeFantasia || convenio.razaoSocial,
        },
      })),
    [convenios],
  );

  const camposObrigatoriosOptions = useMemo(
    () =>
      camposObrigatorios.map((campoObrigatorio: any) => ({
        label: campoObrigatorio[1],
        value: campoObrigatorio[0],
      })),
    [camposObrigatorios],
  );

  if (carregandoDados) {
    return (
      <Page>
        <>
          <PageHeader
            title="Novo modelo de prontuário"
            subtitle="Defina os campos obrigatórios"
          />

          <Page white content>
            <GridListLoading />
          </Page>
        </>
      </Page>
    );
  }

  return (
    <Page>
      <>
        <PageHeader
          title={`${idModelo ? 'Edição' : 'Novo'} modelo de prontuário`}
          subtitle="Defina os campos obrigatórios"
        />

        <Page white content>
          <>
            <form
              className="p-grid"
              onSubmit={handleSubmit(onSubmit, checkHasVinculosErrors)}
            >
              <div className="p-col-12 p-md-8 p-xl-6">
                <TextInputControlled
                  className="p-col-12 p-px-0"
                  control={control}
                  name="nomeModelo"
                  label="Nome do modelo"
                  errorMsg={errors?.nomeModelo?.message}
                />

                <Separator
                  style={{
                    height: '26px',
                  }}
                  spaceOnly
                  vertical
                />

                <div className="p-col-6 p-px-0">
                  <ComponentsLabel label="Campos obrigatórios" />
                </div>

                <ListBox
                  control={control}
                  name="camposObrigatorios"
                  options={camposObrigatoriosOptions}
                  listClassName="campos-obrigatorios-list"
                  showHeader={false}
                />

                <div className="p-col-12 p-mt-3 p-d-flex p-flex-column p-gap-2 p-px-0">
                  <ComponentsLabel label="Vínculos" />

                  <RadioButton
                    label="Modelo padrão para todos os usuários"
                    checked={modeloPadrao}
                    onChange={handleToggleModeloVinculo}
                  />
                  <RadioButton
                    label="Modelo vinculado a determinada especialidade(s), 
                    consultório(s) e/ou convênio(s)"
                    checked={modeloVinculado}
                    onChange={handleToggleModeloVinculo}
                    disabled={!user.idEmpresa && !modeloVinculado}
                  />
                </div>

                <div className="p-col-12 p-mt-3 p-px-0">
                  <ComponentsLabel label="Triagem" />
                  <Checkbox
                    name="triagem"
                    label="Modelo se aplica à triagem"
                    checked={checked}
                    onChange={(e: any) => handleCheckTriagem(e.checked)}
                    value={checked}
                  />
                </div>
              </div>

              {modeloVinculado && (
                <div className="p-col-12 p-md-8 p-xl-6 p-pt-3">
                  <ComponentsLabel label="Vínculos" />

                  <Tab
                    clean
                    className="p-pt-1"
                    values={[
                      {
                        label: 'Especialidade',
                        content: (
                          <ListBox
                            control={control}
                            name="especialidades"
                            options={especialidadesOptions}
                            listClassName="vinculos-list"
                            errorMsg={errors?.especialidades?.message}
                            setValue={setValue}
                          />
                        ),
                      },
                      {
                        label: 'Consultório',
                        content: (
                          <ListBox
                            control={control}
                            name="consultorios"
                            options={consultoriosOptions}
                            listClassName="vinculos-list"
                            errorMsg={errors?.consultorios?.message}
                            setValue={setValue}
                          />
                        ),
                      },
                      {
                        label: 'Convênios',
                        content: (
                          <ListBox
                            control={control}
                            name="convenios"
                            options={conveniosOptions}
                            listClassName="vinculos-list"
                            errorMsg={errors?.convenios?.message}
                            setValue={setValue}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              )}

              <div className="p-col-12">
                <div className="p-col-12 p-md-8 p-xl-6 p-d-flex p-gap-1">
                  <Button
                    btnType="ghost"
                    className="p-col-align-center p-d-flex"
                    label="Cancelar"
                    loading={isSubmitting}
                    onClick={voltarPersonalizarProntuario}
                    stretch
                  />
                  <Button
                    type="submit"
                    label="Salvar"
                    loading={isSubmitting}
                    stretch
                  />
                </div>
              </div>
            </form>
          </>
        </Page>
      </>
    </Page>
  );
}

export default NovoModelo;
