import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import Panel from '../../components/Panel/Panel';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import PanelContent from './Historico/PanelContent';
import PanelHeader from './Historico/PanelHeader';

import './Vacinas.scss';

const vacinasOptions = [
  { value: 'ESQUEMA_COMPLETO', label: 'Esquema completo' },
  { value: 'ESQUEMA_INCOMPLETO', label: 'Esquema Incompleto' },
  { value: 'NAO_REALIZOU', label: 'Não realizou' },
  { value: 'SEM_INFORMACAO', label: 'Sem informação' },
];

const Vacinas = () => {
  const { preNatalAtual, selectedGestacao } = usePreNatal();

  const gestacao = selectedGestacao || preNatalAtual;

  const hideHistorico =
    gestacao?.id === preNatalAtual?.id ? 'hide-historico' : '';

  const hideGestacaoAtual =
    gestacao?.id !== preNatalAtual?.id ? 'hide-gestacao-atual' : '';

  const { atendimentoStatus } = useAtendimento();

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm();

  const [ĺoading, setLoading] = useState(false);

  useEffect(() => {
    const loadVacinas = async () => {
      if (!preNatalAtual?.id) return;

      setLoading(true);

      const response = await PreNatalAPI.getVacinas(preNatalAtual?.id);

      if (response?.id) {
        reset({
          imunizacaoDuplaAdulto: response.imunizacaoDuplaAdulto,
          imunizacaoDuplaInfluenza: response.imunizacaoDuplaInfluenza,
          intercorrenciaPreNatal: response.intercorrenciaPreNatal || '',
        });
      }

      setLoading(false);
    };

    loadVacinas();
  }, [preNatalAtual?.id, reset]);

  useEffect(() => {
    const loadHistoricoVacinas = async () => {
      await PreNatalAPI.getVacinas(gestacao?.id);
    };
    if (gestacao?.id !== preNatalAtual?.id) loadHistoricoVacinas();
  }, [gestacao?.id, preNatalAtual?.id]);

  const onSubmit = async (data: any) => {
    if (!preNatalAtual?.id) return;

    await PreNatalAPI.updateVacinas(preNatalAtual?.id, data);
  };

  if (ĺoading)
    return (
      <div className="p-col-12">
        <GridListLoading />
      </div>
    );

  return (
    <>
      {gestacao?.vacina && (
        <div id="historico-vacinas-pre-natal" className={hideHistorico}>
          <SimpleText fontSize={FONT_SIZE.MD} className="p-ml-1" medium>
            Histórico de vacinas
          </SimpleText>
          <div className="p-mt-3 p-mx-2">
            <div className="p-my-1">
              <Panel
                header={(isCollapsed, onToggle) => (
                  <PanelHeader
                    isCollapsed={isCollapsed}
                    onToggle={onToggle}
                    gestacao={gestacao}
                  />
                )}
              >
                <PanelContent gestacao={gestacao} />
              </Panel>
            </div>
          </div>
        </div>
      )}

      <form
        id="vacinas-pre-natal"
        className={`p-grid p-col-12 p-px-0 ${hideGestacaoAtual}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <SimpleText
          className="p-col-12 p-pb-0"
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Imunização Dupla Adulto
        </SimpleText>
        <div className="p-col-12 p-pt-0">
          <RadioButtonGroupControlled
            control={control}
            name="imunizacaoDuplaAdulto"
            error={errors.imunizacaoDuplaAdulto}
            items={vacinasOptions}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <SimpleText
          className="p-col-12 p-pb-0"
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Influenza
        </SimpleText>
        <div className="p-col-12 p-pt-0">
          <RadioButtonGroupControlled
            control={control}
            name="imunizacaoDuplaInfluenza"
            error={errors.imunizacaoDuplaInfluenza}
            items={vacinasOptions}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="intercorrenciaPreNatal"
          label="Intercorrências do Pré-Natal"
          maxLength={500}
          maxLengthSpan
          disabled={!isAtendimentoRunning}
        />

        <div className="p-col-12 p-mt-3">
          <Button
            type="submit"
            className="p-col-12"
            btnType="tonal"
            label="Salvar"
            loading={isSubmitting}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </form>
    </>
  );
};

export default Vacinas;
