import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';

interface PanelContentProps {
  preAtendimento: PreAtendimento;
  children?: React.ReactNode;
}

function PanelContent({ preAtendimento, children }: PanelContentProps) {
  const headerTextClassName = `p-mb-2 ${
    preAtendimento.status === 'INATIVO' ? 'preAtendimento-inativa' : ''
  }`;
  const dataTextClassName =
    preAtendimento.status === 'INATIVO'
      ? 'line-through preAtendimento-inativa p-wordbreak-all'
      : 'p-wordbreak-all';

  return (
    <div className="preAtendimentos-panel-content p-grid">
      {children}
      {preAtendimento?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Inserido por <b>{preAtendimento.usuarioAtualizacao?.nome}</b> em{' '}
          {dayjs(preAtendimento.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
}

export default PanelContent;
