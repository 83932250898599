import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldAplicacaoProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldAplicacao = ({
  name = 'aplicacao',
  label,
  ...rest
}: FieldAplicacaoProps) => {
  const { control, watch } = useFormContext();
  const [aplicacao, setAplicacao] = useState<any[]>([]);

  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetQuery = useThrottle(setQuery, 500);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const load = useCallback(async (query?: string) => {
    setIsLoading(true);
    try {
      // TODO
      const response =
        await AplicacaoFormularioDinamicoAPI.loadAplicacoesFormulario(
          { nome: query || '' },
          { throwError: true },
        );

      setAplicacao(response.list);
    } catch {
      setAplicacao([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    load(query);
  }, [load, query]);

  const aplicacaoAtual = watch(name);

  const options = useMemo(() => {
    const data = aplicacao.map(item => ({
      label: item?.nome,
      value: item,
    }));

    // valida se o @item atual está listado nas options
    if (aplicacaoAtual) {
      const isAplicacaoAtualVisivel = aplicacao.find(
        perg => perg.id === aplicacaoAtual.id,
      );

      if (!isAplicacaoAtualVisivel) {
        data.unshift({
          label: aplicacaoAtual?.nome,
          value: aplicacaoAtual,
        });
      }
    }

    return data;
  }, [aplicacaoAtual, aplicacao]);

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      options={options}
      onFilter={e => handleSetQuery(e.filter)}
      loading={isLoading}
      carregandoFiltro={isLoading}
      errorMsg={error?.message}
      hideTextErrorSpace={false}
      {...rest}
    />
  );
};
