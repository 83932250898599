import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) {
    return <></>;
  }

  return (
    <div className="p-grid p-mt-1 p-mb-1">
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>
      </div>
      <div className="p-col-5 p-md-6 p-lg-7 p-xl-8">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Razão social
        </SimpleText>
      </div>
      <div className="p-col-3 p-xl-2 p-d-flex p-jc-end">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mr-1"
        >
          Ações
        </SimpleText>
      </div>
    </div>
  );
};
