import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface RowProps {
  cid: any;
  favoritado: boolean;
  onSelectValue: (cid: any) => void;
  handleFavoritar: (cid: any) => void;
}
const Row = ({ cid, favoritado, onSelectValue, handleFavoritar }: RowProps) => {
  return (
    <div className="cid-item p-grid p-w-100">
      <div className="p-col-11 p-grid p-p-0" onClick={() => onSelectValue(cid)}>
        <SimpleText
          className="p-col-12"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          {cid.codigo} - {capitalizeFirstLetter(cid.nome.toLowerCase())}
        </SimpleText>
      </div>
      <div
        className="p-col-1 p-d-flex p-jc-center p-ai-center"
        onClick={() => handleFavoritar(cid)}
      >
        <i
          className={`fas fa-star favoritar-button ${
            favoritado ? 'favoritado' : ''
          }`}
        />
      </div>
    </div>
  );
};

export default Row;
