import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';

import { ModalHistoricoCompleto } from 'src/pages/Emed/Prontuario/Atendimento/HistoricoAtendimentos/ModalHistoricoCompleto';

export const SOAPResumo = ({ ...props }: any) => {
  return (
    <HistoricoAtendimentoProvider
      historicoAssinatura
      initialAtendimento={props.selectedAtendimento}
    >
      <ModalHistoricoCompleto
        initial={'RESUMO'}
        justificativaDisclosure={() => {}}
        onInativarAtendimento={() => {}}
        reload={false}
        {...props}
      />
    </HistoricoAtendimentoProvider>
  );
};
