import Http, { HttpParams } from 'src/core/http/Http';

const loadPontuacaoFormularioDinamico = async (
  params: {
    idFormularioDinamico: number | string;
    nome?: string;
    intervaloMinimo?: number;
    intervaloMaximo?: number;
    mensagemPaciente?: string;
    pageSize?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IPontuacaoFormularioDinamicoItem[]>> =>
  Http.get({
    service: 'adm',
    url: `/pontuacao-formulario-dinamico/pesquisar`,
    params,
    hideToast: true,
    ...options,
  });
const loadPontuacaoFormularioDinamicoById = async (
  params: { id: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IPontuacaoFormularioDinamicoItem> =>
  Http.get({
    service: 'adm',
    url: `/pontuacao-formulario-dinamico/${params.id}`,
    hideToast: true,
    ...options,
  });
const createPontuacaoFormularioDinamico = async (
  payload: IPontuacaoFormularioDinamicoPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/pontuacao-formulario-dinamico`,
    data: payload,
    ...options,
  });
const updatePontuacaoFormularioDinamico = async (
  payload: IPontuacaoFormularioDinamicoItem,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `/pontuacao-formulario-dinamico/${payload.id}`,
    data: payload,
    ...options,
  });
const deletePontuacaoFormularioDinamico = async (
  params: { id: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `/pontuacao-formulario-dinamico/${params.id}`,
    ...options,
  });

export const PontuacaoFormularioDinamicoAPI = {
  loadPontuacaoFormularioDinamico,
  loadPontuacaoFormularioDinamicoById,
  createPontuacaoFormularioDinamico,
  updatePontuacaoFormularioDinamico,
  deletePontuacaoFormularioDinamico,
};
