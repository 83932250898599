import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
      <div className="p-col-4 p-lg-6">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Valor
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Opções
        </SimpleText>
      </div>
      <div className="p-col-2 p-lg-1">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Personalização
        </SimpleText>
      </div>
      <Can
        checkPermission={['ADM_PARAMETROS_ALTERAR', 'ADM_PARAMETROS_EXCLUIR']}
      >
        <div className="p-col-2 p-lg-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Ações
          </SimpleText>
        </div>
      </Can>
    </div>
  );
};
