import { useCallback, useMemo, useState, useRef } from 'react';

import { Mention } from 'primereact/mention';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface MensagensProps {
  onSubmit(v: any): void;
  handleNavigate(): void;
  textVariables: any;
  values: any;
}
const Mensagem = ({
  values,
  onSubmit,
  textVariables,
  handleNavigate,
}: MensagensProps) => {
  const mentionInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);
  const [alertMaxLength, setAlertMaxLength] = useState<boolean>(false);
  const [mensagem, setMensagem] = useState<string>(values || '');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [textDisabled, setTextDisabled] = useState<boolean>(
    values ? true : false,
  );

  const onSearch = (event: any) => {
    const query = event.query;
    let suggestions;

    if (!query.trim().length) {
      suggestions = [...textVariables];
    } else {
      suggestions = textVariables.filter((text: any) =>
        text.nome.substring(1).toLowerCase().startsWith(query.toLowerCase()),
      );
    }

    setSuggestions(suggestions);
  };

  const itemTemplate = (suggestion: any) => {
    return (
      <div className="p-d-flex p-jc-between p-ai-center p-px-3">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {suggestion.nome}
        </SimpleText>

        <div className="tamanhoVariable">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {suggestion.tamanho}
          </SimpleText>
        </div>
      </div>
    );
  };

  const textLength = useMemo(() => {
    let qtdCaracter = 0;
    let remainingText = mensagem;

    textVariables.forEach((item: any) => {
      const { tamanho, codigo } = item;

      const regex = new RegExp(`{${codigo}`, 'g');
      const ocorrencias = remainingText?.match?.(regex)?.length || 0;
      qtdCaracter += tamanho * ocorrencias;
      remainingText = remainingText?.replace?.(regex, '');
    });

    qtdCaracter += remainingText?.length || 0;

    if (qtdCaracter > 166) {
      setAlertMaxLength(true);
    }

    return qtdCaracter;
  }, [mensagem, textVariables]);

  const handleChange = useCallback((e: any) => {
    const value = (e.target as HTMLInputElement).value;
    const regexAcentos = /[\u0300-\u036f]/g;
    const textoSemAcentos = value.normalize('NFD').replace(regexAcentos, '');
    setMensagem(textoSemAcentos);
    // Correção temporária para bug do mention colocar o cursor no final do texto quando o usuário digita uma letra com acento
    const currentPosition = mentionInputRef?.current?.selectionStart;
    setTimeout(() => {
      if (mentionInputRef?.current && currentPosition) {
        mentionInputRef.current.selectionStart = currentPosition;
        mentionInputRef.current.selectionEnd = currentPosition;
      }
    });
  }, []);

  const maxLength = 166;

  return (
    <div className="p-grid p-col-12 p-lg-8 p-xl-6 p-pl-5 p-gap-2">
      <div className="novoLembrete-content">
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
          Preencha o campo mensagem com o conteúdo do sms para o lembrete de
          agendamento.
        </SimpleText>

        <div className="p-d-flex p-jc-between p-ai-center p-mt-2">
          <SimpleText
            medium
            className="p-my-3"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            Mensagem
          </SimpleText>

          {textDisabled && values && (
            <Button
              icon="fa fa-pencil"
              label="Editar"
              btnType="green-link"
              onClick={() => setTextDisabled(false)}
            />
          )}
        </div>

        <Mention
          inputRef={mentionInputRef}
          className="p-mb-2"
          trigger="{"
          value={mensagem}
          field="codigo"
          onSearch={onSearch}
          suggestions={suggestions}
          itemTemplate={itemTemplate}
          onChange={handleChange}
          rows={12}
          autoResize
          disabled={textDisabled}
        />

        <div className="p-d-flex p-jc-end p-px-2 p-mb-3 p-mt-1">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            <span className={textLength > maxLength ? 'text-danger' : ''}>
              {textLength}
            </span>
            /{maxLength}
          </SimpleText>
        </div>

        <AlertBox
          visible={alertMaxLength && textLength > maxLength}
          onHide={() => setAlertMaxLength(false)}
          type="DANGER"
          text={`Número de caracteres maior que ${maxLength}! Diminua seu texto.`}
        />

        <AlertBox
          visible={visible}
          onHide={() => setVisible(false)}
          text="Inicie e termine as variáveis com { }. Exemplo: {nomePaciente}"
        />

        {!textDisabled && values && (
          <div className="p-d-flex p-jc-end p-mt-2">
            <Button
              className="p-col-5"
              label="Cancelar"
              btnType="ghost"
              onClick={() => {
                setTextDisabled(true);
                setMensagem(values);
              }}
            />

            <Button
              className="p-col-5"
              label="Salvar alterações"
              onClick={() => {
                setTextDisabled(true);
              }}
              disabled={!mensagem.length || textLength > maxLength}
            />
          </div>
        )}
      </div>

      <Button
        className="p-my-1 p-col-3"
        label="Cancelar"
        btnType="ghost"
        onClick={handleNavigate}
      />

      <Button
        disabled={
          textLength > maxLength ||
          mensagem.length === 0 ||
          (!textDisabled && values)
        }
        className="p-my-1 p-col-4"
        label="Salvar"
        loading={loading}
        onClick={() => {
          setLoading(true);
          onSubmit(mensagem);
          setLoading(false);
        }}
      />
    </div>
  );
};

export default Mensagem;
