import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/consultorios';
export const loadConsultorios = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetConsultorioDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadConsultorioById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetConsultorioDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendConsultorio = (
  payload: PostConsultorioDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostConsultorioDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    ...options,
  });
};

export const updateConsultorio = (
  id: number,
  payload: PostConsultorioDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostConsultorioDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    ...options,
  });
};

export const removeConsultorioById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetConsultorioDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

export const loadUserConsultorios = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetConsultorioDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/usuario`,
    hideToast: true,
    ...options,
  });
};

export const putConsultorioCarteiraAps = (
  id: number,
  payload: PostConsultorioDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostConsultorioDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/carteira-aps`,
    data: payload,
    ...options,
  });
};

export const getConsultorioCarteiraApsPacientesVinculados = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<boolean> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/carteira-aps/pacientes-vinculados`,
    hideToast: true,
    ...options,
  });
};

const ConsultorioAPI = {
  loadConsultorios,
  loadConsultorioById,
  sendConsultorio,
  removeConsultorioById,
  updateConsultorio,
  loadUserConsultorios,
  putConsultorioCarteiraAps,
  getConsultorioCarteiraApsPacientesVinculados,
};

export default ConsultorioAPI;
