import { Skeleton } from 'primereact/skeleton';
import { useFormContext } from 'react-hook-form';

import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  name: string;
  label?: string;
  className?: string;
  loading: boolean;
  options: any[];
  isMedico: boolean;
}

const FieldProfissionais = (props: Props) => {
  const { label, className, loading, options, isMedico } = props;

  const { control, setValue } = useFormContext();

  if (loading) {
    return (
      <div className={className}>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {label}
        </SimpleText>
        <Skeleton className="p-mt-2" height="33px" borderRadius="8px" />
      </div>
    );
  }

  return (
    <div className={className}>
      <MultiSelectControlled
        control={control}
        name="profissionais"
        label={label}
        options={options}
        optionLabel="nome"
        optionValue="id"
        placeholder="Selecione um ou mais profissionais"
        disabled={isMedico}
        showSelectAll
        display="chip"
      />
    </div>
  );
};

export default FieldProfissionais;
