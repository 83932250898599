import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
export const Header = () => {
  return (
    <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1 header-border">
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Status
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Data
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Paciente
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Telefone de contato
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Plano/Particular
        </SimpleText>
      </div>
      <div className="p-col">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Profissional
        </SimpleText>
      </div>
      
    </div>
  );
};
