import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import { formatData } from '../helpers';


const Passaporte = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [paisesOptions, setPaisesOptions] = useState<any>([]);

  const fetchPaisesData = async () => {
    const response = await UtilsAPI.GetPaises();

    setPaisesOptions(formatData(response, 'descricao', 'id'));
  };

  useEffect(() => {
    fetchPaisesData();
  }, []);

  return (
    <>
      <DropdownControlled
        className="p-col-12"
        control={control}
        name="idPaisEmissorPassaporte"
        label="País emissor"
        placeholder="Selecione"
        options={paisesOptions}
        errorMsg={errors.idPaisEmissorPassaporte?.message}
      />
      <CalendarInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="passaporteDataValidade"
        label="Data de validade"
        placeholder="dd/mm/aaaa"
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
        errorMsg={errors.passaporteDataValidade?.message}
      />
      <CalendarInputControlled
        className="p-col-12 p-sm-6"
        control={control}
        name="passaporteDataEmissao"
        label="Data de emissão"
        placeholder="dd/mm/aaaa"
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
        maxDate={new Date()}
        errorMsg={errors.passaporteDataEmissao?.message}
      />
    </>
  );
}

export default Passaporte;