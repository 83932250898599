/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Dialog from 'src/components/Dialog/Dialog';

import './DialogOrientacoes.scss';

export const DialogOrientacoes = ({
  close,
  state,
}: DisclosureType<{ text: string }>) => {
  return (
    <Dialog
      className="dialog-orientacao-preenchimento"
      visible
      onHide={close}
      header="Orientação de preenchimento"
    >
      <div
        className="orientacao-preenchimento-content"
        dangerouslySetInnerHTML={{ __html: state?.text! }}
      />
    </Dialog>
  );
};
