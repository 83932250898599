import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  isCollapsed: boolean;
  onToggle: () => void;
  gestacao: any;
}

export default function PanelHeader({
  isCollapsed,
  onToggle,
  gestacao,
}: PanelHeaderProps) {
  const vacina = gestacao?.vacina;
  //Foram feitos alguns ajustes pois a vacina da funcionalidade nao bate com as do CDU de historico
  return (
    <div className="p-grid p-d-flex p-ai-center p-px-2">
      <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Imunização dupla adulto
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {vacina?.imunizacaoDuplaAdulto &&
            capitalizeFirstLetter(vacina?.imunizacaoDuplaAdulto).replaceAll(
              '_',
              ' ',
            )}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Imunização dupla Influenza
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {vacina?.imunizacaoDuplaInfluenza &&
            capitalizeFirstLetter(vacina?.imunizacaoDuplaInfluenza).replaceAll(
              '_',
              ' ',
            )}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Data de inclusão
          {/* Data de ocorrencia nao vem do back. */}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {vacina?.dataInclusao
            ? dayjs(new Date(vacina?.dataInclusao)).format('DD/MM/YYYY HH:mm')
            : 'Não informado'}
        </SimpleText>
        {/* Pode ser que haja uma melhoria e se adicione esta flag */}
        {/* <div className=" p-d-flex p-ai-center p-jc-center chip">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16} medium>
            Retroativo
          </SimpleText>
        </div> */}
      </div>
      <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-jc-end p-ai-center">
        {/* Pode ser que seja adicionedo em melhoria. Lembrete nao é retornado do back */}
        {/* <Lembrete ativo={true} /> */}
        <Button
          className="p-ml-2"
          icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
          onClick={onToggle}
          btnType="ghost"
        />
      </div>
    </div>
  );
}
