import Http, { HttpParams } from 'src/core/http/Http';

import { Paginator } from 'src/components/Table/Table';

const basePath = '/motivo-transferencia-aps';

export const getMotivo = (
  params?: {
    nome?: string;
    ativo?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetManterTrasferenciaApsDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: basePath,
    params,
    hideToast: true,
    ...options,
  });
};

export const getMotivoList = (
  params?: {
    nome?: string;
    ativo?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetManterTrasferenciaApsDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/lista`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getMotivoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetManterTrasferenciaApsDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const postMotivo = (
  data: PostManterTrasferenciaApsDT,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetManterTrasferenciaApsDTO> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data,
    ...options,
  });
};

export const putMotivo = (
  data: PostManterTrasferenciaApsDT,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetManterTrasferenciaApsDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${data.id}`,
    data,
    ...options,
  });
};

export const deleteMotivo = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    ...options,
  });
};

export const MotivoTransferenciaAPI = {
  getMotivo,
  getMotivoById,
  postMotivo,
  putMotivo,
  deleteMotivo,
  getMotivoList,
};
