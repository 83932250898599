import { useMemo, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';
import { ReceitasOrientacoesAPI } from 'src/APIs/ProntuarioAPI/ReceitasOrientacoesAPI/ReceitaAPI';

import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { handleOpenPDF } from 'src/utils/files';

import { Page } from '../_components/Page/Page';
import { PageHeader } from '../_components/Page/PageHeader';
import { FieldOrdenacao } from './components/FieldOrdencacao';
import { FieldProfissionalAtendimentos } from './components/FieldProfissionalAtendimentos';
import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import {
  ReceitasOrientacoesRow,
  ReceitasOrientacoesRowMobile,
} from './ReceitasOrientacoesRow';

import './ReceitasOrientacoes.scss';

const items: { [key: string]: string } = {
  item: 'Item',
  quantidade: 'Quantidade',
  posologia: 'Posologia',
  'profissional.nome': 'Profissional',
  dataOcorrencia: 'Data de ocorrência',
};

export const ReceitasOrientacoes = () => {
  const { windowInnerWidth } = useSize();

  const [isPrinting, setIsPrinting] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const isMobile = useMemo(() => windowInnerWidth <= 700, [windowInnerWidth]);

  const fetchAPI = async ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    query,
    sortBy = 'dataOcorrencia',
    ...rest
  }: any) => {
    setFilters(rest);
    try {
      const data = await ReceitasOrientacoesAPI.getReceitasOrientacoes(
        {
          ...rest,
          sortBy,
          sortDirection: 'DESC',
        },
        {
          throwError: true,
        },
      );

      return data;
    } catch {
      return { list: [] };
    }
  };

  const handlePrint = async () => {
    setIsPrinting(true);
    const res = await RelatoriosAPI.postRelatorioPortalReceitasOrientacoes({
      ...filters,
    });

    handleOpenPDF(res, 'Relatorio_atendimentos');
    setIsPrinting(false);
  };

  return (
    <Page>
      <>
        <PageHeader
          title="Minhas receitas e orientações"
          subtitle="Aqui estão reunidas as suas receitas e orientações"
          backButton
        />

        <Page id="ReceitasOrientacoes" content white>
          <SearchList.Root fetchApi={fetchAPI}>
            <ReceitasOrientacoesHeader />

            <SearchList.BorderContainer>
              <div className="p-d-flex p-jc-end p-ai-center p-pb-4 p-gap-2">
                <Button
                  btnType="tonal"
                  label="Imprimir"
                  icon="fas fa-print"
                  onClick={handlePrint}
                  loading={isPrinting}
                />
                <ReceitasOrientacoesHeaderOrderBy />
              </div>

              <SearchList.InfiniteScroll
                renderRows={items =>
                  items.map((item: any) =>
                    isMobile ? (
                      <ReceitasOrientacoesRowMobile item={item} />
                    ) : (
                      <ReceitasOrientacoesRow item={item} />
                    ),
                  )
                }
              />
            </SearchList.BorderContainer>
          </SearchList.Root>
        </Page>
      </>
    </Page>
  );
};

const ReceitasOrientacoesHeader = () => {
  const { setFilter, filter }: any = useSearchList();
  const form = useForm();

  const onSubmit = (v: any) => {
    const dataInicial = v.periodo?.[0]
      ? dayjs(v.periodo[0]).format('YYYY-MM-DD')
      : null;
    const dataFinal = v.periodo?.[1]
      ? dayjs(v.periodo[1]).format('YYYY-MM-DD')
      : null;

    setFilter({
      ...filter,
      dataInicial,
      dataFinal,
      idProfissionalSaude: v.profissionalAtendimentos?.id,
      tipo: v.tipo,
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-grid p-d-flex p-col-12 p-px-0"
      >
        <CalendarInputControlled
          control={form.control}
          name="periodo"
          placeholder="dd/mm/aaaa até dd/mm/aaaa"
          className="p-col-6 p-md-3"
          selectionMode="range"
        />

        <FieldOrdenacao
          name="tipo"
          placeholder="Selecione"
          className="p-col-6 p-md-3"
          showClear
        />
        <FieldProfissionalAtendimentos
          placeholder="Selecione"
          className="p-col-6 p-md-3"
          showClear
        />
        <div className="p-col">
          <Button
            label="pesquisar"
            type="submit"
            btnType="tonal"
            style={{
              maxHeight: '30px',
            }}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const ReceitasOrientacoesHeaderOrderBy = () => {
  const { setFilter, filter } = useSearchList();

  const [sortBy, setSortBy] = useState('dataOcorrencia');

  const itemsOrdenation: MenuItem[] = [
    { label: 'Item', command: () => setSortBy('item') },
    { label: 'Quantidade', command: () => setSortBy('quantidade') },
    {
      label: 'Posologia',
      command: () => setSortBy('posologia'),
    },
    { label: 'Profissional', command: () => setSortBy('profissional.nome') },
    { label: 'Data de ocorrência', command: () => setSortBy('dataOcorrencia') },
  ];

  useEffectSkipFirst(() => {
    setFilter({ ...filter, sortBy });
  }, [sortBy]);

  return (
    <div className="p-d-flex p-jc-end p-ai-center">
      <SimpleText>Ordenar por:</SimpleText>
      <ButtonMenu
        label={items[sortBy]!}
        btnType="green-link"
        model={itemsOrdenation}
      />
    </div>
  );
};
