import { useMemo, useState } from 'react';

import { Image } from 'primereact/image';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { itemsFieldEtapa, itemsFieldTipoConteudo } from '../utils';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import UploadFile from 'src/components/UploadFile/UploadFile';

type Props = DisclosureType & {
  handleAdd: (v: any, editingIndex?: number) => void;
  etapasInseridas: string[];
};

export const AssistenteVirtualCreateDialog = ({
  close,
  handleAdd,
  etapasInseridas,
  state = {},
}: Props) => {
  const item = state;

  const [linkConteudo, setLinkConteudo] = useState(item?.linkConteudo || null);
  const [selectedFile, setSelectedFile] = useState<File[]>(
    item?.file ? [item?.file] : [],
  );

  const form = useForm({
    defaultValues: {
      ...item,
    },
    resolver: yupResolver(
      Yup.object({
        posicao: Yup.string().required('Selecione a etapa'),
        nome: Yup.string().required('Campo obrigatório'),
        tipoConteudo: Yup.string().required('Selecione o tipo de conteúdo'),
        linkConteudo: Yup.string()
          .when('tipoConteudo', {
            is: (tipoConteudo: string) => tipoConteudo === 'VIDEO',
            then: Yup.string().required('Informe o link do conteúdo'),
          })
          .nullable(),
      }),
    ),
  });

  const tipoConteudo = form.watch('tipoConteudo');

  useEffectSkipFirst(() => {
    if (tipoConteudo === 'VIDEO') {
      setSelectedFile([]);
    }
  }, [tipoConteudo]);

  const onSubmit = (v: any) => {
    if (!item?.pathImagem && tipoConteudo === 'IMAGEM' && !selectedFile?.length)
      return;

    handleAdd(
      {
        ...v,
        posicao: Number(v.posicao),
        file: selectedFile[0],
      },
      item?.idx ?? undefined,
    );

    close();
  };

  const etapasItems = useMemo(() => {
    const etapasInseridasToString = etapasInseridas.map(etapa =>
      etapa.toString(),
    );

    const steps = itemsFieldEtapa.filter(
      step => !etapasInseridasToString.includes(step.value),
    );

    if (!!item) {
      steps.unshift({
        label: item.posicao,
        value: item.posicao,
      });
    }

    return steps;
  }, [etapasInseridas, item]);

  const isEditing = !!item && Object.keys(item)?.length;

  const hasImageError =
    !item?.pathImagem && !selectedFile.length && form.formState?.isSubmitted;

  return (
    <Dialog
      visible
      onHide={close}
      header={isEditing ? 'Editar Etapa' : 'Adicionar Etapa'}
      style={{
        width: '720px',
        minWidth: '380px',
      }}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-column p-gap-2"
        >
          <SimpleText fontSize="xs" fontColor="color_60">
            Selecione o tipo de definição que deseja inserir
          </SimpleText>
          <DropdownControlled
            control={form.control}
            name="posicao"
            label="Etapa"
            options={etapasItems}
            filter={false}
            placeholder="Selecione"
          />
          <FormInput
            name="nome"
            label="Nome da etapa"
            maxLength={50}
            hideTextErrorSpace
          />
          <TextareaInputControlled
            control={form.control}
            name="descricaoDica"
            label="Descrição da dica"
            maxLength={500}
            style={{
              resize: 'block',
            }}
          />
          <DropdownControlled
            control={form.control}
            name="tipoConteudo"
            label="Tipo de conteúdo"
            options={itemsFieldTipoConteudo}
            placeholder="Selecione"
          />
          {tipoConteudo === 'VIDEO' && (
            <FormInput
              name="linkConteudo"
              label="Link do conteúdo"
              placeholder="http://arquivos.com"
              hideTextErrorSpace
            />
          )}
          {tipoConteudo === 'IMAGEM' && (
            <>
              <SimpleText medium className="p-col-12 p-px-0 p-pb-0">
                Conteúdo
              </SimpleText>

              {linkConteudo ? (
                <div className="section-image-link p-d-flex p-jc-between p-w-100 p-gap-2 p-ai-center">
                  <div className="p-d-flex p-gap-2 p-ai-center">
                    <Image
                      width="60px"
                      height="40px"
                      src={linkConteudo}
                      imageClassName="img-preview-assistencia"
                      preview
                    />
                    <SimpleText>{item.nome}_Image</SimpleText>
                  </div>
                  <Button
                    icon="fas fa-trash"
                    btnType="gray"
                    onClick={() => {
                      delete item.pathImagem;
                      delete item.linkConteudo;

                      setLinkConteudo(null);
                    }}
                  />
                </div>
              ) : (
                <UploadFile
                  maxFiles={1}
                  selectedFile={setSelectedFile}
                  initialFile={selectedFile}
                  textAcceptedFiles="PNG, JPG ou GIF"
                  accept={{
                    'image/png': ['.png', '.PNG'],
                    'image/jpeg': ['.jpeg', '.JPEG'],
                    'image/jpg': ['.jpg', '.JPG'],
                    'image/gif': ['.gif', '.GIF'],
                  }}
                  maxSize={2000000}
                />
              )}

              {hasImageError && (
                <SimpleText className="error p-mb-2">
                  <i className="fas fa-circle-exclamation p-mr-2" />
                  Insira um conteúdo válido
                </SimpleText>
              )}
            </>
          )}
          <FormInput
            name="linkSaibaMais"
            label="Link do saiba mais"
            placeholder="http://arquivos.com"
          />
          <div className="p-d-flex p-gap-2">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
            />
            <Button
              label={isEditing ? 'Salvar' : 'Inserir'}
              type="submit"
              stretch
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
