import Http from 'src/core/http/Http';

const basePath = '/paciente-vacina';

export const getPacientesVacinasEndpoint = (params: any): Promise<Paginator<PacientesVacinas>> => (
  Http.get({
    service: 'pep',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true
  })
);

export const adicionarVacinaEndpoint = (payload: any): Promise<any> => (
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    return: true
  })
);

export const alterarPacienteVacinaEndpoint = (
  idPacienteVacina: number,
  payload: any,
): Promise<any> => (
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPacienteVacina}`,
    data: payload,
    return: true
  })
);

export const alterarPacienteVacinaLembreteEndpoint = (
  idPacienteVacina: number
): Promise<any> => (
  Http.put({
    service: 'pep',
    url: `${basePath}/lembrete/${idPacienteVacina}`,
    return: true
  })
);

export const inativarPacienteVacinaEndpoint = (
  idPacienteVacina: number,
  payload: any,
): Promise<any> => (
  Http.put({
    service: 'pep',
    url: `${basePath}/status/${idPacienteVacina}`,
    data: payload,
    return: true
  })
);

export const removerPacienteVacinaEndpoint = (
  idPacienteVacina: number
): Promise<any> => (
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idPacienteVacina}`,
    return: true
  })
);

export const getVacinaEndpoint = (): Promise<Vacinas[]> => (
  Http.get({
    service: 'pep',
    url: 'vacina/pesquisa',
    hideToast: true,
  })
);