import { useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Tab from 'src/components/Tab/Tab';

import { FormRecipientConvenios } from './FormRecipientConvenios';
import { FormRecipientPacientes } from './FormRecipientPacientes';

import './FormRecipient.scss';

interface FormRecipientProps {
  onSubmit(v: any): void;
  values: any;
}

const FormRecipient = ({ onSubmit, values }: FormRecipientProps) => {
  const useFormMethods = useForm({
    defaultValues: {
      pacientes: values?.pacientes || [],
      convenios: [],
      todosPacientes: false,
    },
  });

  const tabs = useMemo(
    () => [
      {
        label: 'Convênio',
        value: 'comunicadoConvenio',
        content: <FormRecipientConvenios />,
      },
      {
        label: 'Paciente',
        value: 'comunicadoPaciente',
        content: <FormRecipientPacientes />,
      },
    ],
    [],
  );

  const [wPacientes, wConvenios, isTodosPacientes] = useFormMethods.watch([
    'pacientes',
    'convenios',
    'todosPacientes',
  ]);

  const textAlert = `${wConvenios.length} convênio(s) ${
    !isTodosPacientes ? `e ${wPacientes.length} paciente(s) selecionados` : ''
  }`;

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={useFormMethods.handleSubmit(onSubmit)}
        className="p-col-12"
        noValidate
      >
        <div className={'FormRecipient-pacientes p-d-flex p-flex-column'}>
          <SimpleText>
            Selecione o destinatário por perfil de usuário, especialidade,
            consultório ou hospitais.
          </SimpleText>

          <Tab className={'p-mt-2 tab-recipient'} values={tabs} />
        </div>

        <AlertBox className="p-mt-2" visible text={textAlert} />

        <Button
          className="p-mt-4 p-w-100"
          label="Pré-visualizar"
          type="submit"
          disabled={
            !values.mensagem || (!wConvenios.length && !wPacientes.length)
          }
        />
      </form>
    </FormProvider>
  );
};

export default FormRecipient;
