import { useContext } from 'react';

import { Control, Controller } from 'react-hook-form';

import ThemeContext from 'src/core/themes/ThemeContext';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { RadioButton, RadioButtonProps } from '../../_UI/RadioButton';
import './RadioButtonControlled.scss';

export interface RadioButtonControlledProps extends RadioButtonProps {
  control: Control<any>;
  name: string;
  errorMsg?: string | any;
}

const RadioButtonControlled = ({
  name,
  control,
  value,
  errorMsg,
  ...rest
}: RadioButtonControlledProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={`RadioButtonControlled ${theme?.theme || ''} ${
        errorMsg ? 'error' : ''
      }`}
      key={name}
    >
      {errorMsg ? <i className={'pi pi-exclamation-circle'} /> : <></>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value: valueRadio } }) => (
          <RadioButton
            {...rest}
            value={value}
            checked={valueRadio === value}
            onChange={e => onChange(e.value)}
            className={`${errorMsg ? 'p-invalid' : ''} ${rest.className || ''}`}
          />
        )}
      />
      <div className={'p-d-flex p-jc-between p-ai-center p-mt-1'}>
        <div>
          {errorMsg && (
            <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
              {errorMsg}
            </SimpleText>
          )}
        </div>
      </div>
    </div>
  );
};

export default RadioButtonControlled;
