import { Control } from 'react-hook-form';

import dayjs from 'dayjs';

import { enumToText } from 'src/utils/questionario';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';


import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface SaudeProps {
  control: Control;
  values?: Questionarios[] | undefined;
}

const SaudeEvolucao = ({ control, values }: SaudeProps) => {
  return (
    <Template title="Saúde mental" control={control} readOnly>
      <div className="container-SaudeEvolucao">
        <CardQuestions
          title="Transtornos relacionados a saúde mental na Família ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.transtornoMental)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Ocorrência de Suicídio na Família ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.suicidio)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Sintomas de Depressão ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.depressao)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Sintomas de Ansiedade ?"
          control={control}
          readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}>
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}>
                  {enumToText(value.ansiedade)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>
      </div>
    </Template>
  );
};

export default SaudeEvolucao;
