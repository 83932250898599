import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PuericulturaAPI from 'src/APIs/ProntuarioAPI/PuericulturaAPI/PuericulturaAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import { schemaTriagem } from '../validation';

import './Triagem.scss';

const Triagem = () => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus } = useAtendimento();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schemaTriagem),
  });

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const getTriagem = useCallback(async () => {
    if (!idAtendimento) return;

    const response = await PuericulturaAPI.getTriagem(Number(idAtendimento));

    if (response.id) {
      setValue('sinalOrtolani', response.sinalOrtolani);
      setValue('testeCoracaozinho', response.testeCoracaozinho);
      setValue('testeLinguinha', response.testeLinguinha);
      setValue('testeOlhinho', response.testeOlhinho);
      setValue('testeOrelhinha', response.testeOrelhinha);
      setValue('testePezinho', response.testePezinho);
    }
  }, [idAtendimento, setValue]);

  useEffect(() => {
    getTriagem();
  }, [getTriagem]);

  const renderForm = useCallback(() => {
    const formOptions = [
      { label: 'Normal', value: 'NORMAL' },
      { label: 'Alterado', value: 'ALTERADO' },
      { label: 'Não realizado', value: 'NAO_REALIZADO' },
      { label: 'Sem informação', value: 'SEM_INFORMACAO' },
    ];

    return (
      <div className="p-mt-5">
        <div>
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Sinal de Ortolani
          </SimpleText>

          <RadioButtonGroupControlled
            name="sinalOrtolani"
            className="p-col"
            control={control}
            items={formOptions}
            error={errors.sinalOrtolani}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Teste do pezinho
          </SimpleText>

          <RadioButtonGroupControlled
            name="testePezinho"
            className="p-col"
            control={control}
            items={formOptions}
            error={errors.testePezinho}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Teste da orelinha
          </SimpleText>

          <RadioButtonGroupControlled
            name="testeOrelhinha"
            className="p-col"
            control={control}
            items={formOptions}
            error={errors.testeOrelhinha}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Teste da linguinha
          </SimpleText>

          <RadioButtonGroupControlled
            name="testeLinguinha"
            className="p-col"
            control={control}
            items={formOptions}
            error={errors.testeLinguinha}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Teste do coraçãozinho
          </SimpleText>

          <RadioButtonGroupControlled
            name="testeCoracaozinho"
            className="p-col"
            control={control}
            items={formOptions}
            error={errors.testeCoracaozinho}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Teste do olhinho
          </SimpleText>

          <RadioButtonGroupControlled
            name="testeOlhinho"
            className="p-col"
            control={control}
            items={formOptions}
            error={errors.testeOlhinho}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </div>
    );
  }, [control, errors, isAtendimentoRunning]);

  const onSubmit = async (data: any) => {
    await PuericulturaAPI.postTriagem(Number(idAtendimento), data);
  };

  return (
    <ElevatedCard noMargin className="container-triagem">
      <SimpleText
        fontSize={FONT_SIZE.MD}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Triagem neonatal
      </SimpleText>

      <form>
        {renderForm()}

        <Button
          className="p-mt-5"
          label="Salvar"
          btnType="tonal"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={!isAtendimentoRunning}
          stretch
        />
      </form>
    </ElevatedCard>
  );
};

export default Triagem;
