import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import HospitalAPI from 'src/APIs/AdminAPI/HospitalAPI/HospitalAPI';

import useSize from 'src/core/hooks/useSize';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Header, MobileRow } from './HospitalGrid';

import './CentralHospital.scss';

const CentralHospital = () => {
  const { isMobile } = useSize();

  const navigate = useNavigate();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_hospital_cadastrar',
      'adm_hospital_alterar',
      'adm_hospital_consultar',
      'adm_hospital_excluir',
    ],
  });

  const [confirmDelete, setConfirmDelete] = useState<any>(null);
  const [hospitalId, setHospitalId] = useState<any>(null);
  const [reload, setReload] = useState(0);

  function reloadList() {
    setReload(currentReload => currentReload + 1);
  }

  const onEdit = (item: any) => {
    if (item.id) {
      navigate(`/cadastros/hospital/cadastro/${item.id}`, { state: item });
    }
  };

  const handleConfirmDelete = async (item: any) => {
    if (item.id) {
      await HospitalAPI.removeHospitalById(item.id);
      reloadList();
    }

    setConfirmDelete(false);
  };

  const handleDelete = (hospital: any) => {
    setConfirmDelete(true);
    setHospitalId(hospital);
  };

  const renderRow = (data: any): JSX.Element => {
    const commomProps = {
      hospital: data,
      onEdit: onEdit,
      onDelete: handleDelete,
    };

    if (isMobile) return <MobileRow {...commomProps} />;

    return (
      <SearchList.CardItem>
        <DesktopRow {...commomProps} />
      </SearchList.CardItem>
    );
  };

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await HospitalAPI.loadHospitals({
      nome: params?.query,
      ...params,
    });
  };

  return (
    <>
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.SearchInput
          label="Pesquisar hospitais"
          placeholder="Digite um nome"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
            <Button
              type="button"
              btnType="pill"
              label="Incluir novo hospital"
              icon="fas fa-hospital"
              onClick={() => navigate('/cadastros/hospital/cadastro')}
              checkPermission="ADM_HOSPITAL_CADASTRAR"
            />

            <SearchList.TotalCount className="p-mt-3 p-mt-sm-0" />
          </div>

          {!isMobile && <Header />}

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ConfirmDialog
        visible={confirmDelete}
        header={'Excluir hospital'}
        text="O hospital será excluído da base de dados. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => {
          setConfirmDelete(null);
        }}
        onConfirm={() => {
          handleConfirmDelete(hospitalId);
        }}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default CentralHospital;
