import { useMemo, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { formatarData } from 'src/utils/date';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  data: any;
  onDelete: (data: any) => void;
  onSwitchStatus: (data: any) => void;
  btnEspecialidades: JSX.Element;
  btnConsultorios: JSX.Element;
}

export const DesktopRow = ({
  data,
  onDelete,
  onSwitchStatus,
  btnEspecialidades,
  btnConsultorios,
}: DesktopRowProps) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();

  const menuRef = useRef<OverlayPanel>(null);

  const itemsWithPermission = useMemo(() => {
    const items = [
      {
        label: 'Editar',
        command: () =>
          navigate(`/config/personalizacao-prontuarios/${data.id}`),
        checkPermission: 'PRO_MODELO_PRONTUARIO_ALTERAR',
      },
      {
        label: 'Excluir',
        command: () => onDelete(data.id),
        checkPermission: 'PRO_MODELO_PRONTUARIO_EXCLUIR',
        className: 'error',
      },
    ];

    return items.filter(
      ({ checkPermission }) =>
        !checkPermission || authorities?.includes(checkPermission),
    );
  }, [authorities, data.id, navigate, onDelete]);

  return (
    <SearchList.CardItem>
      <div className="p-col-2 p-lg-3 p-d-flex p-flex-column p-flex-lg-row p-ai-center p-gap-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data.nomeModelo}
        </SimpleText>
        {data.tipoModelo === 'PADRAO' && (
          <span className="tag-modelo-padrao">Padrão</span>
        )}
        {data.triagem && <span className="tag-modelo-triagem">Triagem</span>}
      </div>

      <div className="p-col ">{btnEspecialidades}</div>

      <div className="p-col  ">{btnConsultorios}</div>

      <div className="p-col  ">
        <div className="p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {formatarData(data?.dataCriacao)}
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.PRIMARY}
            medium
          >
            {data?.usuarioAtualizacao?.nome || ''}
          </SimpleText>
        </div>
      </div>

      <div className="p-col  ">
        <div className="p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {formatarData(data?.dataAtualizacao)}
          </SimpleText>
          <SimpleText
            className="p-mt-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.PRIMARY}
            medium
          >
            {data?.usuarioAtualizacao?.nome || ''}
          </SimpleText>
        </div>
      </div>

      <div className="p-col p-d-flex p-jc-end   ">
        <Switch
          checkPermission="PRO_MODELO_PRONTUARIO_ALTERAR"
          checked={data.status}
          onChange={() => onSwitchStatus(data)}
          showStatus
        />
      </div>

      <div className="p-col p-d-flex p-jc-end">
        <Button
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={e => menuRef.current?.toggle(e)}
          className="p-mr-1"
        />

        <TieredMenu model={itemsWithPermission} popup ref={menuRef} />
      </div>
    </SearchList.CardItem>
  );
};
