import 'dayjs/locale/pt-br';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Menu } from 'primereact/menu';
import { TieredMenu } from 'primereact/tieredmenu';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSubscription } from 'react-stomp-hooks';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import UsuarioAPI, {
  TIPO_USUARIO,
} from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import DisponibilidadeMedicaAPI from 'src/APIs/AgendaAPI/DisponibilidadeMedica/DisponibilidadeMedicaAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import {
  setProfissionaisDisponiveis,
  setProfissionalAtivo,
} from 'src/core/redux/slices/agenda/AgendaSlice';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  getAgendamentosHorarios,
  itemsSelecaoAgendamentos,
  optionsAgenda,
} from './utils';
import { formatarDataAgendaPeriodo } from 'src/utils/date';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import InvisiblePanel from 'src/components/Panel/InvisiblePanel/InvisiblePanel';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Separator from 'src/components/Separator/Separator';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import AgendaDia from 'src/pages/Emed/Agenda/MenuAgenda/AgendaDia/AgendaDia';
import CardAgendamento3Dias from 'src/pages/Emed/Agenda/MenuAgenda/AgendamentoListItem/CardAgendamento3Dias/CardAgendamento3Dias';
import CardAgendamentoSemana from 'src/pages/Emed/Agenda/MenuAgenda/AgendamentoListItem/CardAgendamentoSemana/CardAgendamentoSemana';

import { useAgenda } from '../AgendaContext';
import Sigilo from '../Sigilo/Sigilo';

import AlterarHorarioBloco from './AlterarHorarioBloco/AlterarHorarioBloco';
import DisponibilidadeMedica from './DisponibilidadeMedico/DisponibilidadeMedica';

import './MenuAgenda.scss';

enum AgendaViewTypes {
  DIA = 'dia',
  DIAS = 'dias',
  SEMANA = 'semana',
}

const PRESENCA_TISS_TOAST_MESSAGES = {
  autorizado: {
    title: 'Autorizado',
    message: 'Guia autorizada com sucesso!',
    type: 'success',
  },
  negado_operadora: {
    title: 'Guia não autorizada',
    message:
      'Guia não autorizada: Tente novamente, remova puericultura, marque como retorno, ou altere para uma consulta particular',
    type: 'error',
  },
  error: {
    title: 'Erro',
    message: 'Erro de comunicação com o TISS',
    type: 'error',
  },
};

interface DisclosureState {
  submit: (justificativa: string) => void;
}
interface MenuAgendaProps {
  horarioDisponivelSelecionado: string;
  setHorarioDisponivelSelecionado(v: string): void;
  pesquisarPacienteDisclosure: any;
}

const MenuAgenda = ({ pesquisarPacienteDisclosure }: MenuAgendaProps) => {
  const { windowInnerWidth } = useSize();
  const {
    dialogRemarcarAgendamento,
    isBarraLateralOpened,
    agendaView,
    dialogImprimirEtiqueta,
    setAgendaView,
    selectAll,
    setSelectAll,
    selectedDate,
    setSelectedDate,
    refetchAgenda,
    registros,
    loadingAgendamentosDia,
    agendaDiaItems,
    setSelectedAgendamentos,
    selectedAgendamentos,
  } = useAgenda();

  const { user, agenda, consultorios, query } = useAppSelector(
    (state: RootState) => state,
  );
  const navigate = useNavigate();
  const menuOptions = useRef<any>();
  const selectOptions = useRef<any>();
  const dispatch = useDispatch();

  const dialogJustificativaBloqueio = useDisclosure({ opened: false });
  const dialogHorarioBloco = useDisclosure({ opened: false });
  const dialogDisponibilidade = useDisclosure({ opened: false });

  const [isSelecao, setIsSelecao] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { invalidateAgenda } = query;

  const isCustomSize = useMemo(
    () => windowInnerWidth <= 960,
    [windowInnerWidth],
  );

  function actionCreator(payload: any) {
    return (dispatch: any) => {
      dispatch(setProfissionaisDisponiveis(payload.aux));
      dispatch(setProfissionalAtivo(payload.activeProfissional));
    };
  }
  //Solucao encontrada para bug que nao atualiza redux quando altera o usuario.
  const loadProfissionais = useCallback(async () => {
    try {
      if (!consultorios.ativo) {
        return;
      }
      const aux = await UsuarioAPI.loadProfissionaisByUserConsultorio(
        consultorios.ativo.id,
      );

      const activeProfissional = aux.find(
        (profissional: any) => profissional.id === agenda.profissionalAtivo?.id,
      );

      if (activeProfissional) {
        actionCreator({ aux, activeProfissional });
      } else {
        dispatch(setProfissionaisDisponiveis(aux));
      }
    } catch (e) {
      console.log(e);
    }
  }, [agenda.profissionalAtivo?.id, consultorios.ativo, dispatch]);

  const loadMedico = useCallback(async () => {
    try {
      const aux = await UsuarioAPI.loadUsuarioById(user?.idUsuario || -1);
      if (aux) {
        dispatch(setProfissionalAtivo(aux));
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, user?.idUsuario]);

  useEffect(() => {
    if (user.tipoProfissionalSaude !== 'MEDICO') {
      loadProfissionais();
    } else {
      loadMedico();
    }
  }, [dispatch, loadMedico, loadProfissionais, user.tipoProfissionalSaude]);

  useEffect(() => {
    if (invalidateAgenda) {
      refetchAgenda();
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, invalidateAgenda, refetchAgenda]);

  const handleAlterarHorariosSelected = async (
    justificativa: string,
    status?: string,
  ) => {
    const agendamentosFiltered = selectedAgendamentos.filter(
      (ag: any) => ag.status === 'LIVRE' || ag.idBloqueioAgenda,
    );

    await AgendamentosAPI.updateAgendamentosStatus({
      atendimentos: agendamentosFiltered,
      status: status ? status : 'BLOQUEADO',
      motivoBloqueado: justificativa,
      idUsuario: user.idUsuario,
      idUsuarioAlteracao: user.idUsuario,
    });

    refetchAgenda();
    setIsSelecao(false);
    setSelectAll(false);
    setSelectedAgendamentos([]);
  };

  const handleBloqueioDia = async (justificativa: string) => {
    const payload = {
      dataBloqueio: dayjs(selectedDate).format('YYYYMMDD'),
      status: 'BLOQUEADO',
      motivoBloqueado: justificativa,
      idMedico: agenda?.profissionalAtivo?.id,
      idConsultorio: consultorios.ativo?.id,
    };

    await AgendamentosAPI.saveBloqueioDia(payload);
    refetchAgenda();
  };

  const sendFlags = useCallback(
    async (payload: any) => {
      try {
        setIsLoading(true);
        await AgendamentosAPI.saveAgendamentoList(payload, {
          throwError: true,
        });

        refetchAgenda();
        setIsSelecao(false);
        setSelectAll(false);
        setSelectedAgendamentos([]);
      } catch {
      } finally {
        setIsLoading(false);
      }
    },
    [refetchAgenda, setSelectAll, setSelectedAgendamentos],
  );

  const handleFlags = useCallback(
    (flags: string[]) => {
      const payload: any = [];

      selectedAgendamentos.forEach((agendamento: any) => {
        payload.push({
          id: agendamento?.id,
          idMedico: agenda?.profissionalAtivo?.id,
          idConsultorio: consultorios.ativo?.id,
          dataAgendamento: agendamento.dataAgendamento,
          status: 'LIVRE',
          flagsAtendimentos: flags,
        });
      });

      sendFlags(payload);
    },
    [
      agenda?.profissionalAtivo?.id,
      selectedAgendamentos,
      consultorios.ativo?.id,
      sendFlags,
    ],
  );

  const handleRevogar = useCallback(
    (flags: string[]) => {
      const payload: any = [];

      selectedAgendamentos.forEach((agendamento: any) => {
        if (
          agendamento.status === 'LIVRE' &&
          agendamento?.flagsAtivas.some((flag: string) => flags.includes(flag))
        ) {
          payload.push({
            id: agendamento?.id,
            idMedico: agenda?.profissionalAtivo?.id,
            idConsultorio: consultorios.ativo?.id,
            dataAgendamento: agendamento.dataAgendamento,
            status: 'LIVRE',
            flagsAtendimentos: agendamento.flagsAtivas.filter(
              (f: string) => !flags.includes(f),
            ),
          });
        }
      });

      sendFlags(payload);
    },
    [
      agenda?.profissionalAtivo?.id,
      selectedAgendamentos,
      consultorios.ativo?.id,
      sendFlags,
    ],
  );

  useSubscription('/user/topic/notificacao', (message: any) => {
    const data = JSON.parse(message.body);

    const transacao =
      data?.dadosComplementares?.transacao === 'SADT' ||
      data?.dadosComplementares?.transacao === 'STATUS_AUTORIZACAO';

    if (
      data.tipoNotificacao === 'TISS' &&
      data.funcionalidade === 'AGENDA' &&
      transacao
    ) {
      const isVisibleOnAgendaDia = agendaDiaItems.some(
        agendamento => agendamento.id == data.dadosComplementares.idAtendimento,
      );
      const isVisibleOnRegistros = registros.some(item =>
        item.atendimentos.find(
          (atendimento: any) =>
            atendimento.id == data.dadosComplementares.idAtendimento,
        ),
      );

      if (isVisibleOnAgendaDia || isVisibleOnRegistros) {
        refetchAgenda();
      }

      const agendamento =
        agendaView === AgendaViewTypes.DIA
          ? agendaDiaItems.find(
              agendamento =>
                agendamento.id == data.dadosComplementares.idAtendimento,
            )
          : registros.find(item =>
              item.atendimentos.find(
                (atendimento: any) =>
                  atendimento.id == data.dadosComplementares.idAtendimento,
              ),
            );

      if (data.dadosComplementares.statusAtendimento === 'PRESENTE') {
        if (
          consultorios?.ativo?.identificacaoEtiqueta &&
          agendamento &&
          !dialogImprimirEtiqueta.isOpen
        ) {
          dialogImprimirEtiqueta.open({
            state: agendamento?.cartaoPlano,
          });
        }
      }

      let toastData =
        data.dadosComplementares.statusAtendimento === 'PRESENTE'
          ? PRESENCA_TISS_TOAST_MESSAGES.autorizado
          : PRESENCA_TISS_TOAST_MESSAGES.negado_operadora;
      if (data.dadosComplementares.sucesso === 'false')
        toastData = PRESENCA_TISS_TOAST_MESSAGES.error;

      if (!!data?.dadosComplementares?.statusAtendimento)
        toast(<Toast />, { data: toastData });
    }
  });

  const options = optionsAgenda({
    dialogHorarioBloco,
    setIsSelecao,
    handleBloqueioDia: () =>
      dialogJustificativaBloqueio.open({
        state: { submit: handleBloqueioDia },
      }),
  });

  const optionsSelecao = itemsSelecaoAgendamentos({
    dialogJustificativaBloqueio,
    handleAlterarHorariosSelected,
    handleFlags,
    handleRevogar,
    agendaDiaItems,
    selectedAgendamentos,
  });

  const gerarEncaixe = useCallback(async () => {
    navigate('/agenda/novo-encaixe', {
      state: {
        date: selectedDate,
        screen: 'Encaixe',
      },
    });
  }, [navigate, selectedDate]);

  const handleDayUpdate = (type: string) => {
    const data = selectedDate;
    let value;
    let numDias = 1;

    if (agendaView === AgendaViewTypes.DIA) numDias = 1;
    if (agendaView === AgendaViewTypes.DIAS) numDias = 3;
    if (agendaView === AgendaViewTypes.SEMANA) numDias = 7;

    if (type === 'increase') {
      value = selectedDate.getDate() + numDias;
    } else {
      value = selectedDate.getDate() - numDias;
    }

    data.setDate(value);

    setSelectedDate(new Date(data));
  };

  const getDisponibilidadesPendentes = useCallback(
    async (profissionalAtivoId: number, consultorioAtivoId: number) => {
      if (!(profissionalAtivoId && consultorioAtivoId)) return;

      const response =
        await DisponibilidadeMedicaAPI.getDisponibilidadeMedicaPendentes(
          profissionalAtivoId,
          consultorioAtivoId,
        );

      response.length !== 0 && dialogDisponibilidade.open();
    },
    [dialogDisponibilidade],
  );

  const [initial, final] = getAgendamentosHorarios(registros[0]?.atendimentos);

  useEffect(() => {
    getDisponibilidadesPendentes(
      agenda.profissionalAtivo?.id,
      consultorios.ativo?.id,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda.profissionalAtivo?.id, consultorios.ativo?.id]);

  return (
    <Page className={'MenuAgenda'}>
      <>
        {isSelecao ? (
          <div className="menu-agenda-header  p-flex-column p-flex-md-row p-jc-between p-ai-center">
            <Checkbox
              className="checkbox-select-all"
              name="selectAll"
              value={selectAll}
              checked={selectAll}
              label="Selecionar todos"
              onChange={() => setSelectAll((prev: boolean) => !prev)}
            />

            <div className="selection-engine p-gap-2">
              {isLoading && (
                <div className="p-d-flex p-gap-2 p-ai-center p-mr-2">
                  <SpinnerLoading size="xs" />
                  <SimpleText fontColor="primary" fontSize="xxxs">
                    aplicando alterações
                  </SimpleText>
                </div>
              )}
              <Menu
                model={optionsSelecao}
                popup
                ref={selectOptions}
                style={{ width: '30%' }}
              />
              <Button
                id="selectOptions"
                onClick={(event: any) => selectOptions.current.toggle(event)}
                btnType="tonal"
                icon={'pi pi-chevron-down'}
                iconPos={'right'}
                label={'Alterar seleção para...'}
              />
              <Button
                id="cancelSelectOptions"
                onClick={() => {
                  setSelectedAgendamentos([]);
                  setIsSelecao(false);
                  setSelectAll(false);
                  refetchAgenda();
                }}
                btnType="green-link"
                label={'Cancelar'}
              />
            </div>
          </div>
        ) : (
          <div
            className={`menu-agenda-header p-flex-column ${
              isCustomSize && isBarraLateralOpened ? '' : 'p-flex-md-row'
            } p-jc-between p-ai-center`}
          >
            <SelectButton
              id="agenda-view-select"
              className="p-order-3 p-order-md-1"
              value={agendaView}
              options={[
                { label: 'Dia', value: AgendaViewTypes.DIA },
                { label: '3 Dias', value: AgendaViewTypes.DIAS },
                { label: 'Semana', value: AgendaViewTypes.SEMANA },
              ]}
              optionDisabled={item => item.value === agendaView}
              onChange={v => {
                setAgendaView(v.value);
              }}
              disabled={loadingAgendamentosDia}
            />

            {agendaView === AgendaViewTypes.DIA && (
              <div className="day-controller p-order-2 p-my-3 p-my-md-0">
                <Button
                  icon={'pi pi-chevron-left'}
                  btnType="ghost"
                  onClick={() => handleDayUpdate('decrease')}
                />
                <SimpleText>
                  {dayjs(selectedDate)
                    .locale('pt-br')
                    .format('dddd, DD/MM/YYYY ')
                    .concat(`${initial} - ${final}`) || ''}
                </SimpleText>
                <Button
                  icon={'pi pi-chevron-right'}
                  btnType="ghost"
                  onClick={() => handleDayUpdate('increase')}
                />
              </div>
            )}

            {agendaView === AgendaViewTypes.DIAS && (
              <div className="day-controller p-order-2 p-my-3 p-my-md-0">
                <Button
                  icon={'pi pi-chevron-left'}
                  btnType="ghost"
                  onClick={() => handleDayUpdate('decrease')}
                />
                <SimpleText>
                  {formatarDataAgendaPeriodo(selectedDate || '', 2)}{' '}
                  <SimpleText fontColor={FONT_COLOR.PRIMARY} bold>
                    Próximos 3 dias
                  </SimpleText>
                </SimpleText>
                <Button
                  icon={'pi pi-chevron-right'}
                  btnType="ghost"
                  onClick={() => handleDayUpdate('increase')}
                />
              </div>
            )}

            {agendaView === AgendaViewTypes.SEMANA && (
              <div className="day-controller p-order-2 p-my-3 p-my-md-0">
                <Button
                  icon={'pi pi-chevron-left'}
                  btnType="ghost"
                  onClick={() => handleDayUpdate('decrease')}
                />
                <SimpleText>
                  {formatarDataAgendaPeriodo(selectedDate || '', 6)}{' '}
                  <SimpleText fontColor={FONT_COLOR.PRIMARY} bold>
                    Próxima semana
                  </SimpleText>
                </SimpleText>
                <Button
                  icon={'pi pi-chevron-right'}
                  btnType="ghost"
                  onClick={() => handleDayUpdate('increase')}
                />
              </div>
            )}

            <div className="menu-agenda-header-actions p-order-1 p-order-md-3">
              <Button onClick={gerarEncaixe} label={'Encaixe'} />
              <Separator spaceOnly />
              <Button
                icon={'pi pi-search'}
                btnType="gray"
                onClick={() => {
                  pesquisarPacienteDisclosure.open();
                }}
              />
              <Separator spaceOnly />
              <Button
                icon={'pi pi-refresh'}
                btnType="gray"
                onClick={() => refetchAgenda()}
              />
              <Separator spaceOnly />

              {agendaView === AgendaViewTypes.DIA && (
                <>
                  <TieredMenu
                    model={options}
                    popup
                    ref={menuOptions}
                    style={{ width: '30%' }}
                  />
                  <Button
                    id="options"
                    onClick={event => menuOptions.current.toggle(event)}
                    icon={'pi pi-ellipsis-v'}
                    btnType="ghost"
                  />
                </>
              )}
            </div>
          </div>
        )}

        {loadingAgendamentosDia ? (
          <GridListLoading />
        ) : (
          <div>
            {agendaView === AgendaViewTypes.DIA && (
              <AgendaDia
                selectedDate={selectedDate}
                checkbox={isSelecao}
                selectAll={selectAll}
                modalRemarcarAgendamentoDisclosure={dialogRemarcarAgendamento}
              />
            )}

            {agendaView === AgendaViewTypes.DIAS && (
              <div className="p-d-flex p-justify-start p-direction-row p-align-start p-col-12">
                {(registros || [])?.map((v: any, i: number) => (
                  <InvisiblePanel
                    key={i}
                    title={dayjs(registros[i]?.data)
                      .locale('pt-br')
                      .format('DD/MM/YYYY, dddd')}
                    tieredMenuItems={options}
                    tieredMenuOptions={menuOptions}
                    className="p-col-12"
                  >
                    <>
                      {(registros[i]?.atendimentos || []).map(
                        (v: any, i: number) => (
                          <CardAgendamento3Dias
                            key={i}
                            agendamento={v}
                            selectedDate={selectedDate}
                            sigilo={<Sigilo atendimento={v} />}
                          />
                        ),
                      )}
                    </>
                  </InvisiblePanel>
                ))}
              </div>
            )}

            {agendaView === AgendaViewTypes.SEMANA &&
              (registros || [])?.map((v, i) => (
                <InvisiblePanel
                  key={i}
                  title={dayjs(registros[i]?.data)
                    .locale('pt-br')
                    .format('DD/MM/YYYY, dddd')}
                  tieredMenuItems={options}
                  tieredMenuOptions={menuOptions}
                  className="p-col-12"
                >
                  <div className="p-d-flex p-justify-start p-direction-column p-align-start p-col-12 agenda-semana-container">
                    {(registros[i]?.atendimentos || []).map(
                      (v: any, i: number) => (
                        <div key={i} className="agenda-semana-item">
                          <CardAgendamentoSemana
                            key={i}
                            agendamento={v}
                            selectedDate={selectedDate}
                            sigilo={<Sigilo atendimento={v} />}
                          />
                        </div>
                      ),
                    )}
                  </div>
                </InvisiblePanel>
              ))}
          </div>
        )}

        {dialogHorarioBloco.isOpen && (
          <AlterarHorarioBloco {...dialogHorarioBloco} />
        )}

        {dialogDisponibilidade.isOpen && (
          <DisponibilidadeMedica {...dialogDisponibilidade} />
        )}

        {dialogJustificativaBloqueio.isOpen && (
          <DialogJustificativaBloqueio {...dialogJustificativaBloqueio} />
        )}
      </>
    </Page>
  );
};

const DialogJustificativaBloqueio = ({
  isOpen,
  close,
  state,
}: DisclosureType<DisclosureState>) => {
  const [justificativa, setJustificativa] = useState('');

  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      header="Bloquear horários do dia"
      className="dialog-justificativa-bloqueio"
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Você realmente deseja bloquear todos os horários livres do dia
          selecionado? Digite o motivo do bloqueio para confirmar a ação
        </SimpleText>
        <TextareaInput
          label="Motivo do bloqueio"
          value={justificativa}
          onChange={e => setJustificativa(e.target.value)}
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Cancelar"
            stretch
            onClick={() => close()}
          />
          <Button
            type="submit"
            label="Sim, bloquear"
            btnType="danger"
            stretch
            onClick={() => [state?.submit(justificativa), close()]}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default MenuAgenda;
