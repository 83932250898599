import React from 'react';

import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  isCollapsed: boolean;
  onToggle: () => void;
  gestacao: any;
}

export default function PanelHeaderHistorico({
  isCollapsed,
  onToggle,
  gestacao,
}: PanelHeaderProps) {
  // Foram feitos ajustes para ficar coerente com a funcionalidade. Mas esta um pouco diferente do cdu
  const examesFisicos = gestacao?.examesFisicos;
  return (
    <div className="p-grid p-d-flex p-ai-center p-px-2">
      <div className="p-col-12 p-sm-6 p-md-2 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          MF
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {examesFisicos?.[0]?.mf
            ? capitalizeFirstLetter(examesFisicos?.[0]?.mf)
            : ''}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-md-2 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          AU
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {examesFisicos?.[0]?.au}
        </SimpleText>
      </div>
      <div className="p-col-12 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          BCF
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {examesFisicos?.[0]?.bcf}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Data da ocorrência
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {examesFisicos?.[0]?.dataOcorrencia
            ? dayjs(new Date(examesFisicos?.[0]?.dataOcorrencia)).format(
                'DD/MM/YYYY HH:mm',
              )
            : 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-md-2 p-d-flex p-jc-end p-ai-center">
        <Button
          icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
          onClick={onToggle}
          btnType="ghost"
        />
      </div>
    </div>
  );
}
