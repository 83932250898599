import { useState } from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { capitalizeFirstLetter } from 'src/utils/utils';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import ModalTermo from '../ModalTermo/ModalTermo';

interface CardDesktopProps {
  relatorio: GetRelatorioEmails;
  modalVisualizarEmailDisclosure: any;
}

const CardDesktop = ({
  relatorio,
  modalVisualizarEmailDisclosure,
}: CardDesktopProps) => {
  const [visible, setVisible] = useState(false);
  const { isTablet } = useSize();

  const handleText = (text: string): string => {
    if (text) {
      return text;
    }

    return 'Não informado';
  };

  return (
    <>
      <div className="card-container">
        <SearchList.CardItem>
          <>
            <div className="p-col-2 p-md-1 p-text-center">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                {relatorio?.dataEnvio
                  ? dayjs(relatorio?.dataEnvio).format('DD/MM/YYYY')
                  : ''}
                <br />
                {relatorio?.dataEnvio
                  ? dayjs(relatorio?.dataEnvio).format('HH:mm:ss')
                  : ''}
              </SimpleText>
            </div>

            <div className="p-col-3">
              <div className="column">
                <SimpleText className="p-mb-2" fontColor={FONT_COLOR.COLOR_16}>
                  {handleText(relatorio?.nomePaciente)}
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  {handleText(relatorio?.cartaoBeneficiario)}
                </SimpleText>
              </div>
            </div>

            <div className="p-col-2">
              <div className="column">
                <SimpleText className="p-mb-2" fontColor={FONT_COLOR.COLOR_16}>
                  {handleText(relatorio?.nomeMedico)}
                </SimpleText>
              </div>
            </div>

            {!isTablet && (
              <div className="p-col-2">
                <div className="column">
                  <SimpleText
                    className="p-mb-2"
                    fontColor={FONT_COLOR.COLOR_16}
                  >
                    {relatorio?.formaEnvio === 'EMAIL'
                      ? relatorio?.emailDestinatario
                      : relatorio?.numeroDestinatarioSms}
                  </SimpleText>
                  <div className="p-d-flex p-ai-center">
                    <SimpleText
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_60}
                    >
                      {capitalizeFirstLetter(relatorio?.formaEnvio || '')}
                    </SimpleText>
                    <IconButton
                      btnType={IconBtnTypes.ICON_ONLY}
                      icon="fas fa-paperclip"
                      onClick={() => modalVisualizarEmailDisclosure.open()}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="p-col-3 p-md-2">
              <div className="p-d-flex p-justify-start p-align-center w-6">
                <SimpleText
                  className="p-text-center"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  {relatorio?.dataEnvio
                    ? dayjs(relatorio?.dataEnvio).format('DD/MM/YYYY')
                    : ''}
                  <br />
                  {relatorio?.dataEnvio
                    ? dayjs(relatorio?.dataEnvio).format('HH:mm:ss')
                    : ''}
                </SimpleText>
                <SimpleText
                  className="p-ml-2"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_60}
                >
                  •
                </SimpleText>

                <SimpleText className="p-ml-3" fontColor={FONT_COLOR.COLOR_16}>
                  {capitalizeFirstLetter(
                    relatorio?.telemedicinaLogTermo?.status || '',
                  )}
                </SimpleText>
              </div>
            </div>

            <div onClick={() => setVisible(true)} className="p-col-1 pointer">
              <IconButton btnType={IconBtnTypes.ICON_ONLY} icon="fa fa-file" />
            </div>

            <div id="location" className="p-col-1">
              <IconButton
                btnType={IconBtnTypes.ICON_ONLY}
                icon="fa fa-map-marker-alt"
              />

              <div className="tolltip-location">
                <SimpleText
                  bold
                  fontSize={FONT_SIZE.MD}
                  fontColor={FONT_COLOR.COLOR_16}
                >
                  Detalhes da localização:
                </SimpleText>

                <div className="item">
                  <SimpleText
                    bold
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    IP do computador:
                  </SimpleText>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {handleText(relatorio?.telemedicinaLogTermo?.ipComputador)}
                  </SimpleText>
                </div>
                <div className="item">
                  <SimpleText
                    bold
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Porta:
                  </SimpleText>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {handleText(
                      relatorio?.telemedicinaLogTermo?.portaComputador,
                    )}
                  </SimpleText>
                </div>

                <div className="item">
                  <SimpleText
                    bold
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    Localização:
                  </SimpleText>

                  <SimpleText
                    fontSize={FONT_SIZE.XS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {handleText(relatorio?.telemedicinaLogTermo?.localRegistro)}
                  </SimpleText>
                </div>
              </div>
            </div>
          </>
        </SearchList.CardItem>
      </div>

      {visible && (
        <ModalTermo
          relatorio={relatorio}
          visible={visible}
          onHide={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default CardDesktop;
