import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  return (
    <div className="p-grid p-mt-1 p-mb-1">
      <div className="p-col-7">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>
      </div>
      <div className="p-col-3 p-lg-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CNPJ
        </SimpleText>
      </div>
      <Can checkPermission={['ADM_HOSPITAL_ALTERAR', 'ADM_HOSPITAL_EXCLUIR']}>
        <div className="p-col-2 p-lg-1 p-d-flex p-jc-end">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            className="p-mr-1"
          >
            Ações
          </SimpleText>
        </div>
      </Can>
    </div>
  );
};
