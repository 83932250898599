import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DialogOrientacoes } from '../components/DialogOrientacoes/DialogOrientacoes';
import { Button } from 'src/components/_UI/Button';

interface Props {
  pergunta: any;
}

const InformativoPergunta = ({ pergunta }: Props) => {
  const dialogAjuda = useDisclosure({ opened: false });

  return (
    <>
      <Button
        icon="fas fa-circle-info"
        btnType="ghost"
        onClick={() => {
          dialogAjuda.open({
            state: {
              text: pergunta.textoAjuda,
            },
          });
        }}
        className="step-button p-d-flex p-ai-center p-jc-center p-mr-2 info-btn"
      />

      {dialogAjuda.isOpen && <DialogOrientacoes {...dialogAjuda} />}
    </>
  );
};

export default InformativoPergunta;
