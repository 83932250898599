import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  complexidade: ComplexidadePacienteDTO;
  complexidadeStatus: JSX.Element;
  consultoriosComplexidade: JSX.Element;
  dataInclusao: string;
  onEdit: (complexidade: ComplexidadePacienteDTO) => void;
  onDelete: (id: number) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const {
    complexidade,
    complexidadeStatus,
    consultoriosComplexidade,
    dataInclusao,
    onEdit,
    onDelete,
  } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(complexidade),
      checkPermission: 'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(complexidade?.id),
      checkPermission: 'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-grid">
        <div className="p-col-12">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Descrição
          </SimpleText>
          <br />
          <SimpleText
            className="p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {complexidade.descricao}
          </SimpleText>
        </div>

        <div className="p-col-12 p-sm-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Pontuação
          </SimpleText>
          <br />
          <SimpleText
            className="p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {complexidade.pontuacao}
          </SimpleText>
        </div>

        <div className="p-col-12 p-sm-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Consultórios
          </SimpleText>
          <br />
          {consultoriosComplexidade}
        </div>

        <div className="p-col-12  p-sm-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Data de inclusão
          </SimpleText>
          <br />
          <SimpleText
            className="p-col-2 p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {dataInclusao}
          </SimpleText>
        </div>

        <div className="p-col-12">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Status
          </SimpleText>
          <br />

          {complexidadeStatus}
        </div>
      </div>
    </SearchListMobileRow>
  );
};
