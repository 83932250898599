import { useState } from 'react';

import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { ResultadoExamesAPI } from 'src/APIs/ReportAPI/ResultadoExamesAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Filters } from './components/Filters';
import { HistoricoExamesPanelProcedimento } from './components/HistoricoExamesPanelProcedimento/HistoricoExamesPanelProcedimento';
import { ModalFormExameExterno } from './components/ModalFormExameExterno/ModalFormExameExterno';
import { Button } from 'src/components/_UI/Button';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import './HistoricoExamesResultados.scss';

interface HistoricoExamesResultadosProps {
  className: string;
}

type VisualizarResultadosType = {
  idsSolicitacaoExame: number[];
  idsSolicitacaoExameExterno: number[];
};

const visualizarResultadosInitial: VisualizarResultadosType = {
  idsSolicitacaoExame: [],
  idsSolicitacaoExameExterno: [],
};

export const HistoricoExamesResultados = (
  props: HistoricoExamesResultadosProps,
) => {
  const { className } = props;

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();

  const [visualizarResultados, setVisualizarResultados] = useState(
    visualizarResultadosInitial,
  );
  const [loadingVisualizarResultados, setLoadingVisualizarResultados] =
    useState(false);
  const [reload, setReload] = useState(0);

  const reloadList = () => setReload(prevReload => prevReload + 1);

  const modalFormExameExterno = useDisclosure({
    opened: false,
  });

  const fetchHistorico = ({ query, ...params }: any) => {
    return SolicitacoesProcedimentosAPI.getHistoricoSadt(
      Number(idAtendimento),
      {
        sortBy: 'dataSolicitacao',
        sortDirection: 'DESC',
        ...params,
      },
    );
  };

  const handleAddExameExterno = () =>
    modalFormExameExterno.open({ state: { onSuccess: reloadList } });

  const renderRow = (procedimento: any) => {
    const isSelected =
      visualizarResultados.idsSolicitacaoExame.includes(
        procedimento.idSolicitacaoProcedimento,
      ) ||
      visualizarResultados.idsSolicitacaoExameExterno.includes(
        procedimento.idSolicitacaoProcedimentoExterno,
      );

    return (
      <HistoricoExamesPanelProcedimento
        isSelected={isSelected}
        procedimento={procedimento}
        reloadList={reloadList}
        onSelectResultado={setVisualizarResultados}
      />
    );
  };

  const handleVisualizarResultadosSelected = async () => {
    try {
      setLoadingVisualizarResultados(true);
      const response = await ResultadoExamesAPI.postVisualizarResultados(
        visualizarResultados,
      );

      if (!response?.byteLength) {
        const data = {
          title: 'Erro',
          message: 'Falha ao visualizar os resultados, tente novamente',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      const fileURL = URL.createObjectURL(
        new Blob([response], {
          type: 'application/pdf',
        }),
      );

      window.open(fileURL);
    } catch (error) {
    } finally {
      setLoadingVisualizarResultados(false);
    }

    return;
  };
  const resultadosSelectedLength =
    visualizarResultados.idsSolicitacaoExame.length +
    visualizarResultados.idsSolicitacaoExameExterno.length;

  const renderActionBtn = () => {
    const resultadosSelectedText = `${resultadosSelectedLength} ${
      resultadosSelectedLength > 1 ? 'Selecionados' : 'Selecionado'
    }`;

    if (!!resultadosSelectedLength) {
      return (
        <div className="p-d-flex p-ai-center p-flex-column p-flex-sm-row p-my-3 p-mt-md-0">
          <SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
            {resultadosSelectedText}
          </SimpleText>

          <Button
            className="p-mx-3 p-my-2 p-my-sm-0"
            type="button"
            label="Visualizar resultados"
            icon="fas fa-print"
            onClick={handleVisualizarResultadosSelected}
            loading={loadingVisualizarResultados}
          />

          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={() => setVisualizarResultados(visualizarResultadosInitial)}
            loading={loadingVisualizarResultados}
          />
        </div>
      );
    }

    return (
      <Button
        className="p-mt-3 p-mt-sm-0"
        type="button"
        btnType="outline"
        label="Exames externos"
        icon="fas fa-plus"
        onClick={handleAddExameExterno}
        disabled={atendimentoStatus !== 'ATENDENDO'}
      />
    );
  };

  const headerClassName = `p-col-12 p-d-flex p-flex-column p-jc-between p-ai-center ${
    !!resultadosSelectedLength ? 'p-flex-md-row' : 'p-flex-sm-row'
  }`;

  return (
    <>
      {modalFormExameExterno.isOpen && (
        <ModalFormExameExterno {...modalFormExameExterno} />
      )}

      <Card
        id="historico-exames-resultados"
        type={CARD_TYPES.BORDER}
        className={`p-grid p-p-3 ${className}`}
      >
        <div className={headerClassName}>
          <SimpleText
            fontSize={FONT_SIZE.MD}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Histórico de exames e resultados
          </SimpleText>

          {renderActionBtn()}
        </div>

        <SearchList.Root fetchApi={fetchHistorico}>
          <Filters />

          <SearchList.BorderContainer>
            <SearchList.InfiniteScroll
              autoHeight
              renderRow={renderRow}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </Card>
    </>
  );
};
