import React, { useCallback, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';
import { GetProcedimentosSeriados } from 'src/models/APIs/AgendaAPI/ProcediementosSeriados';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

import { useAgenda } from '../../AgendaContext';

import './ProcedimentosSeriados.scss';

type ConfirmarPresencaItem = {
  procedimentosSeriados: GetProcedimentosSeriados[];
  idConvenio: number;
  idEmpresa: number;
  numeroCartao: string;
  tipoValidacao: string;
  tipoConvenio: string;
  idSala: number;
  idEspecialidade: number;
  validaCartao: boolean;
  status: string;
  idAtendimento: number | string;
};

type RowProps = {
  item: GetProcedimentosSeriados;
  setProcedimentosSelected: (old?: any) => void;
};

type ProcedimentosProps = DisclosureType<ConfirmarPresencaItem>;

const ProcedimentosSeriados = ({
  close,
  isOpen,
  state,
}: ProcedimentosProps) => {
  const [procedimentosSelected, setProcedimentosSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const { windowInnerWidth, isMobile } = useSize();
  const { refetchAgenda } = useAgenda();

  const procedimentos = state?.procedimentosSeriados;

  const handleSubmit = () => {
    setLoading(true);

    const payload = {
      ...state,
      procedimentosSeriados: procedimentosSelected,
    };

    AutorizacaoAPI.realizarAutorizacaoAtendimento(
      String(state?.idAtendimento),
      payload,
      { throwError: true },
    )
      .then(() => [close(), refetchAgenda()])
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const widthResize = useMemo(() => {
    return windowInnerWidth < 725;
  }, [windowInnerWidth]);

  return (
    <Dialog
      id="Procedimentos-seriados"
      maximized={widthResize}
      onHide={close}
      visible={isOpen}
      header={<SimpleText>Procedimentos Seriados</SimpleText>}
    >
      <React.Fragment>
        <div className="content">
          <div className="p-col-12 p-d-flex">
            <div className="w-30" />
            {!isMobile && (
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
                bold
                className="p-col-2"
              >
                Número da guia
              </SimpleText>
            )}
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
              bold
              className="p-col-6 p-md-4"
            >
              Serviço
            </SimpleText>
            {!isMobile && (
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
                bold
                className="p-col-1"
              >
                Qtd. Liberada
              </SimpleText>
            )}
            {!isMobile && (
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
                bold
                className="p-col-1"
              >
                Qtd. Executada
              </SimpleText>
            )}
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
              bold
              className="p-col-3 p-md-2"
            >
              Qtd. para Execução
            </SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
              bold
              className="p-col-4 p-text-center p-md-2"
            >
              Ações
            </SimpleText>
          </div>

          <div className="p-d-flex p-flex-column p-gap-2">
            {!!procedimentos?.length &&
              procedimentos.map((item, idx) => (
                <ProcedimentosSeriadosRow
                  key={idx}
                  item={item}
                  setProcedimentosSelected={setProcedimentosSelected}
                />
              ))}
          </div>
        </div>

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            stretch
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            loading={loading}
          />
          <Button
            stretch
            label="Confirmar"
            onClick={() => handleSubmit()}
            loading={loading}
            disabled={!procedimentosSelected.length}
          />
        </div>
      </React.Fragment>
    </Dialog>
  );
};

const ProcedimentosSeriadosRow = ({
  item,
  setProcedimentosSelected,
}: RowProps) => {
  const isEditing = useDisclosure({ opened: false });

  const { isMobile } = useSize();

  const validationSchema = Yup.object({
    quantidadeParaExecucao: Yup.number()
      .required('Campo obrigatório')
      .positive('Campo deve ser positivo')
      .max(
        item.quantidadeLiberada,
        'O valor não pode ser maior que a quantidade liberada',
      )
      .typeError('Campo obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...item,
      checked: false,
    },
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = useCallback(
    (values: any, event = true) => {
      if (event) {
        setProcedimentosSelected((old: any) => {
          return [...old, values];
        });

        reset(values);
      } else {
        setProcedimentosSelected((old: any) => {
          const data = old.filter(
            (value: any) => value.autorizacao !== values.autorizacao,
          );

          return [...data];
        });
      }

      isEditing.close();
    },
    [isEditing, reset, setProcedimentosSelected],
  );

  const qtdeExecucao = getValues('quantidadeParaExecucao');

  return (
    <FormProvider {...form}>
      <form className="row p-ai-center" onSubmit={handleSubmit(onSubmit)}>
        <CheckboxControlled
          control={control}
          onChangeCustom={e => [onSubmit(getValues(), e)]}
          name="checked"
          className="w-30"
        />
        {!isMobile && (
          <SimpleText className="p-col-2 p-px-3">{item.autorizacao}</SimpleText>
        )}
        <SimpleText
          className={`p-col-5 p-md-4 p-px-3 ${
            !isMobile ? 'p-text-truncate' : ''
          }`}
        >
          {item.servico} - {item.descricaoServico}
        </SimpleText>
        {!isMobile && (
          <SimpleText className="p-col-1 p-px-3">
            {item.quantidadeLiberada}
          </SimpleText>
        )}
        {!isMobile && (
          <SimpleText className="p-col-1 p-px-3">
            {item.quantidadeExecutada}
          </SimpleText>
        )}
        {isEditing.isOpen ? (
          <div className="p-col-3 p-md-2 p-px-3">
            <FormInput
              autoFocus
              className="light"
              name="quantidadeParaExecucao"
              type="number"
              maxLength={item.quantidadeLiberada.toString()?.length}
              allowEmpty={false}
              hideTextErrorSpace
            />
          </div>
        ) : (
          <SimpleText className="p-col-3 p-md-2 p-px-3">
            {qtdeExecucao}
          </SimpleText>
        )}
        {isEditing.isOpen ? (
          <div className="p-d-flex p-gap-2 p-jc-end p-w-100">
            <IconButton
              className="slide-left"
              icon="fa fa-check"
              type="submit"
              btnType={IconBtnTypes.GREEN}
              loading={isSubmitting}
            />
            <IconButton
              icon="fa fa-close"
              type="button"
              onClick={() => [
                isEditing.close(),
                form.clearErrors(),
                setValue('quantidadeParaExecucao', item.quantidadeParaExecucao),
              ]}
              loading={isSubmitting}
            />
          </div>
        ) : (
          <div className="p-d-flex p-gap-2 p-jc-end p-w-100">
            <IconButton
              icon="fa fa-edit"
              type="button"
              onClick={e => [isEditing.open(), e.preventDefault()]}
            />
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default ProcedimentosSeriados;
