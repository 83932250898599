import { useState, useEffect, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Button from 'src/components/Basics/Button/Buttons';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import GridListLoading from 'src/components/GridList/GridListLoading';

import EnderecoCamposDemograficos from '../../CamposDemograficos/Endereco';
import { saveEndereco } from '../../helpers';

import validationSchema from './validationSchema';

import './Endereco.scss';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { useManterPaciente } from '../../MaterPacienteContext';

interface EnderecoProps {
  camposDemograficosObrigatorios: boolean;
  estadosOptions: any[];
}

const Endereco = ({
  camposDemograficosObrigatorios,
  estadosOptions,
}: EnderecoProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const { handleSetFormDirty } = useManterPaciente();

  const { idPaciente } = useParams();

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema(camposDemograficosObrigatorios)),
  });

  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    setValue,
    reset,
    handleSubmit,
  } = useFormMethods;

  useEffectSkipFirst(() => {
    handleSetFormDirty(isDirty, 'DADOS_OPCIONAIS');
  }, [isDirty]);

  const [loadingData, setLoadingData] = useState<boolean>(false);

  const loadEnderecoData = useCallback(async () => {
    if (!idPaciente) return;

    setLoadingData(true);

    const response = await PacienteAPI.getPacienteEnderecoCompleto(
      Number(idPaciente),
    );

    setValue('situacaoDeRua', response?.situacaoDeRua || false);
    setValue('vulnerabilidadeSocial', response?.vulnerabilidadeSocial || false);
    setValue('cep', response?.enderecos[0]?.cep || '');
    setValue('areaMoradia', response?.areaMoradia || null);
    setValue('tipo', response?.enderecos[0]?.tipo || null);
    setValue('logradouro', response?.enderecos[0]?.logradouro || '');
    setValue('numero', response?.enderecos[0]?.numero || '');
    setValue('complementoEndereco', response?.enderecos[0]?.complemento || '');
    setValue('bairro', response?.enderecos[0]?.bairro || '');
    setValue('idEstado', response?.enderecos[0]?.cidade?.idEstado || '');
    setValue('idCidade', response?.enderecos[0]?.idCidade || '');
    setValue('observacaoEndereco', response?.observacaoEndereco || '');

    setLoadingData(false);
  }, [idPaciente, setValue]);

  useEffect(() => {
    loadEnderecoData();
  }, [loadEnderecoData]);

  useEffect(() => {
    reset({
      situacaoDeRua: false,
      vulnerabilidadeSocial: false,
      cep: '',
    });
  }, [reset]);

  const onSubmit = async (data: any) => {
    await saveEndereco(Number(idPaciente), user?.idEmpresa, data);

    reset(data, { keepValues: true });
  };

  if (loadingData)
    return (
      <div className="p-grid">
        <div className="p-col-12 p-md-8">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <FormProvider {...useFormMethods}>
      <form
        id="endereco-paciente"
        className="p-grid p-col-12 p-md-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="p-col-12">
          <CheckboxControlled
            className="checkbox-small"
            control={control}
            name="situacaoDeRua"
            label="Paciente encontra-se em situação de rua"
          />
        </div>
        <div className="p-col-12 p-mb-5">
          <CheckboxControlled
            className="checkbox-small"
            control={control}
            name="vulnerabilidadeSocial"
            label="Paciente encontra-se em vulnerabilidade social"
          />
        </div>

        <EnderecoCamposDemograficos
          showOnlyCamposDemograficosObrigatorios={false}
        />

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="observacaoEndereco"
          label="Observações"
          errorMsg={errors.observacaoEndereco?.message}
        />

        <div className="p-col-12 p-mt-5">
          <Button
            type="submit"
            label="Salvar alterações"
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default Endereco;
