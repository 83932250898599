import * as yup from 'yup';

import { isValidCPF } from 'src/utils/utils';

export const validationSchema = yup
  .object()
  .shape({
    nome: yup.string().required('Nome é obrigatório'),
    dataNascimento: yup
      .date()
      .required('Data de nascimento é obrigatório')
      .nullable(),
    sexoDescricao: yup.string(),
    nomeMae: yup
      .string()
      .notRequired()
      .when('maeDesconhecida', {
        is: (maeDesconhecida: boolean) => !maeDesconhecida,
        then: yup.string().required('Nome da mãe é obrigatório'),
        otherwise: yup.string().nullable(),
      }),
    maeDesconhecida: yup.boolean(),
    email: yup.string().nullable().email('Email inválido'),
    cpf: yup
      .string()
      .nullable()
      .when('estrangeiro', {
        is: (value: boolean) => !value,
        then: field => field.required('CPF é obrigatório'),
      })
      .test('cpf', 'O CPF é inválido', cpf => (cpf ? isValidCPF(cpf) : true)),
    cns: yup.string().nullable(),
    passaporte: yup
      .string()
      .when('estrangeiro', {
        is: (value: boolean) => value,
        then: yup.string().required('Passaporte é obrigatório'),
      })
      .nullable(),
    telefoneCelular: yup.string().nullable(),
    telefoneRecado: yup.string().nullable(),
    telefoneFixo: yup.string().nullable(),
  })
  .test(
    'oneOfRequired',
    'Ao menos um número de telefone é obrigatório',
    function (obj) {
      const { telefoneCelular, telefoneRecado, telefoneFixo } = obj;
      return Boolean(telefoneCelular || telefoneRecado || telefoneFixo);
    },
  );
