import { FormProvider, useForm } from 'react-hook-form';

import { FieldStatusFormulario } from '../_components/FieldStatusFormulario/FieldStatusFormulario';
import { FieldTipoFormulario } from './Create/_components/FieldTipoFormulario';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const FormularioDinamicoAcervoFormulariosFilter = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { setFilter, filter } = useSearchList();
  const form = useForm({
    defaultValues: {
      ...(filter.tipo && {
        tipoFormulario: {
          codigo: filter.tipo,
        },
      }),
      ...(filter.status && {
        status: {
          codigo: filter.status,
        },
      }),
    },
  });

  const onSubmit = (v: any) => {
    setFilter({
      tipo: v.tipoFormulario?.codigo,
      status: v.status?.codigo,
    });

    onSuccess?.();
  };

  return (
    <div
      className="p-p-1"
      style={{
        width: '300px',
        borderRadius: '18px',
      }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="">
          <SimpleText fontColor="color_40">
            Pesquise os formulário de forma detalhada, preenchendo um ou mais
            dos campos abaixo.
          </SimpleText>

          <div className="p-my-3 p-d-flex p-flex-column p-gap-2">
            <FieldTipoFormulario
              label="Tipos"
              name="tipoFormulario"
              className="p-px-0"
              placeholder="Selecione"
              hideTextErrorSpace
              showClear
            />
            <FieldStatusFormulario
              label="Ativo"
              name="status"
              className="p-px-0"
              placeholder="Selecione"
              hideTextErrorSpace
              showClear
            />
          </div>

          <div className="p-d-flex p-gap-2">
            <Button label="Cancelar" btnType="outline" />
            <Button type="submit" label="Pesquisar" stretch />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

