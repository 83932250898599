import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/consultorios';

export const loadSalas = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetSalaDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadSalasByConsultorioId = (
  consultorioId: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSalaDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${consultorioId}/salas`,
    hideToast: true,
    ...options,
  });
};

export const sendSala = (
  consultorioId: string,
  payload: PostSalaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostSalaDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${consultorioId}/salvar-sala`,
    data: payload,
    ...options,
  });
};

export const updateSala = (
  consultorioId: string,
  payload: PostSalaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostSalaDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${consultorioId}/salvar-sala`,
    data: payload,
    ...options,
  });
};

export const removeSalaById = (
  consultorioId: string,
  id: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSalaDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${consultorioId}/remover-sala/${id}`,
    ...options,
  });
};

const SalaAPI = {
  loadSalas,
  loadSalasByConsultorioId,
  sendSala,
  updateSala,
  removeSalaById,
};

export default SalaAPI;
