import { Control } from 'react-hook-form';

import dayjs from 'dayjs';

import { enumToText } from 'src/utils/questionario';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface ViciosProps {
  control: Control;
  values?: Questionarios[] | undefined;
}

const ViciosEvolucao = ({ control, values }: ViciosProps) => {
  return (
    <Template title="Vícios" control={control} readOnly>
      <div className="container-vicios">
        <CardQuestions title="Você costuma fumar ?" control={control} readOnly>
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  {enumToText(value.fumante)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Fumante no domicílio ?"
          control={control}
          readOnly
        >
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  {enumToText(value.fumanteDomicilio)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Você consome bebidas alcoólicas ?"
          control={control}
          readOnly
        >
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  {enumToText(value.bebidaAlcoolica)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Pratica exercícios físicos em seus horários de lazer ?"
          control={control}
          readOnly
        >
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  {enumToText(value.atividadeFisica)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>

        <CardQuestions
          title="Você está tomando alguma medicação de uso controlado ?"
          control={control}
          readOnly
        >
          <div className="p-d-flex p-ai-center p-jc-start">
            {values?.map(value => (
              <div key={value.id} className="card-itens">
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  fontSize={FONT_SIZE.XXS}
                >
                  {dayjs(value.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>

                <SimpleText
                  className="p-mt-4"
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  {enumToText(value.medicamentoControlado)}
                </SimpleText>
              </div>
            ))}
          </div>
        </CardQuestions>
      </div>
    </Template>
  );
};

export default ViciosEvolucao;
