import { useEffect, useRef } from 'react';

import { JitsiMeeting } from '@jitsi/react-sdk';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSubscription } from 'react-stomp-hooks';

import { useTelemedicina } from 'src/core/hooks/Telemedicina/useTelemedicina';

import { CardInfo } from '../components/CardInfo/CardInfo';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './SalaTeleconsulta.scss';

type SocketDataProps = {
  conteudo: string | null;
  dadosComplementares: {
    action: 'REMOVER_PACIENTE';
  };
};

export const SalaTeleconsulta = () => {
  const navigate = useNavigate();

  const { state: codEmpresa } = useLocation();
  const { atendimentoData, roomURL, setRoomURL } = useTelemedicina();

  const idAtendimento = atendimentoData?.idAtendimento;

  const api = useRef<IJitsiMeetExternalApi>();

  const statusAtendimento = atendimentoData?.statusAtendimento;

  useEffect(() => {
    if (!atendimentoData) {
      navigate(`/t/${codEmpresa}/${idAtendimento}/${idAtendimento}`);
    }
  }, [atendimentoData, codEmpresa, idAtendimento, navigate]);

  const handleExit = () => {
    setRoomURL('');

    if (statusAtendimento === 'PRESENTE' || statusAtendimento === 'ATENDENDO') {
      navigate(`/t/${codEmpresa}/${idAtendimento}/${idAtendimento}`, {
        replace: true,
        state: 'FINALIZADO',
      });
    } else {
      navigate(`/t/${codEmpresa}/${idAtendimento}/${idAtendimento}`, {
        replace: true,
      });
    }
  };

  useSubscription('/user/topic/notificacao-telemedicina', message => {
    const data: SocketDataProps = JSON.parse(message.body);

    if (data.dadosComplementares.action === 'REMOVER_PACIENTE') {
      handleExit();
    }
  });

  return (
    <div className="p-d-flex p-flex-column">
      <CardInfo
        text={`Tele consulta - ${atendimentoData?.nomeConvenio || ''}`}
      />

      <div className="teleconsulta-frame">
        <JitsiMeeting
          domain="teleatendimento.appunimed.com"
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          roomName={roomURL!}
          userInfo={{
            displayName: atendimentoData?.nomePaciente || 'Paciente',
            email: '',
          }}
          onApiReady={externalApi => (api.current = externalApi)}
          onReadyToClose={handleExit}
          spinner={SpinnerLoading}
          configOverwrite={{
            height: '100%',
            defaultLanguage: 'pt-BR',
          }}
          interfaceConfigOverwrite={{
            LANG_DETECTION: false,
            VIDEO_LAYOUT_FIT: 'nocrop',
            MOBILE_APP_PROMO: false,
          }}
        />
      </div>
    </div>
  );
};
