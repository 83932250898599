import { useCallback } from 'react';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './ChipAgendamento.scss';

export enum TYPES_CHIPS {
  ATRASADO = 'ATRASADO',
  PROXIMO = 'PROXIMO',
  INTERNET = 'INTERNET',
  PARTICULAR = 'PARTICULAR',
  CONFIRMADO = 'CONFIRMADO',
  TELEMEDICINA = 'TELECONSULTA',
  NAO_CONFIRMADO = 'NAO_CONFIRMADO',
  RESERVA_HORARIO = 'RESERVA_HORARIO',

  ONLINE = 'ONLINE',
  ENCAIXE = 'ENCAIXE',
  CENTRAL = 'CENTRAL',
  RETORNO = 'RETORNO',
  LIBERADO = 'LIBERADO',
  REMARCAR = 'REMARCAR',
  PENDENTES = 'PENDENTES',
  PROCEDIMENTO = 'PROCEDIMENTO',
  PUERICULTURA = 'PUERICULTURA',
  RECEM_NASCIDO = 'RECEM_NASCIDO',
  CENTRAL_AGENDAMENTO = 'CENTRAL_AGENDAMENTO',
  CONTINUAR = 'CONTINUAR',
  ENCAMINHAMENTO = 'ENCAMINHAMENTO',
  CUIDADO_PROGRAMADO = 'CUIDADO_PROGRAMADO',
  PRESCRICAO = 'PRESCRICAO',
  PRESCRICAO_EXECUCAO = 'PRESCRICAO_EM_ANDAMENTO',
  PRESCRICAO_FINALIZADA = 'PRESCRICAO_FINALIZADA',
  PRESCRICAO_PAUSADA = 'PRESCRICAO_PAUSADA',
}
interface ChipAgendamentoProps {
  type: string;
}

const ChipAgendamento = ({ type }: ChipAgendamentoProps) => {
  const getLabel = useCallback(() => {
    switch (type) {
      case TYPES_CHIPS.ATRASADO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Atrasado</SimpleText>;

      case TYPES_CHIPS.PROXIMO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Próximo</SimpleText>;

      case TYPES_CHIPS.INTERNET:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Internet</SimpleText>;

      case TYPES_CHIPS.PARTICULAR:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Particular</SimpleText>;

      case TYPES_CHIPS.CONFIRMADO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Confirmado</SimpleText>;

      case TYPES_CHIPS.TELEMEDICINA:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Teleconsulta</SimpleText>;

      case TYPES_CHIPS.NAO_CONFIRMADO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Não confirmado</SimpleText>
        );

      case TYPES_CHIPS.RESERVA_HORARIO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Reserva de horário</SimpleText>
        );

      case TYPES_CHIPS.ONLINE:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Agendamento online</SimpleText>
        );

      case TYPES_CHIPS.ENCAIXE:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Encaixe</SimpleText>;

      case TYPES_CHIPS.CENTRAL:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>
            Central de agendamento
          </SimpleText>
        );

      case TYPES_CHIPS.RETORNO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Retorno</SimpleText>;

      case TYPES_CHIPS.LIBERADO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>
            Liberado para atendimento
          </SimpleText>
        );

      case TYPES_CHIPS.REMARCAR:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Remarcar</SimpleText>;

      case TYPES_CHIPS.PENDENTES:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>
            Confirmações pendentes
          </SimpleText>
        );

      case TYPES_CHIPS.PROCEDIMENTO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Procedimento</SimpleText>;

      case TYPES_CHIPS.PUERICULTURA:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Puericultura</SimpleText>;

      case TYPES_CHIPS.RECEM_NASCIDO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Recém-nascido</SimpleText>;

      case TYPES_CHIPS.CENTRAL_AGENDAMENTO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Central agendamento</SimpleText>
        );

      case TYPES_CHIPS.CONTINUAR:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Continuar</SimpleText>;

      case TYPES_CHIPS.ENCAMINHAMENTO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Encaminhamento</SimpleText>
        );

      case TYPES_CHIPS.CUIDADO_PROGRAMADO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Cuidado programado</SimpleText>
        );

      case TYPES_CHIPS.CUIDADO_PROGRAMADO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Cuidado programado</SimpleText>
        );
      case TYPES_CHIPS.PRESCRICAO:
        return <SimpleText fontSize={FONT_SIZE.XXXS}>Prescrição</SimpleText>;
      case TYPES_CHIPS.PRESCRICAO_EXECUCAO:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>
            Prescrição em andamento
          </SimpleText>
        );
      case TYPES_CHIPS.PRESCRICAO_FINALIZADA:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>
            <i className="fa fa-check" />
            Prescrição finalizada
          </SimpleText>
        );
      case TYPES_CHIPS.PRESCRICAO_PAUSADA:
        return (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Prescrição pausada</SimpleText>
        );

      default:
        return '';
    }
  }, [type]);

  return <div className={`ChipAgendamento ${type}`}>{getLabel()}</div>;
};

export default ChipAgendamento;
