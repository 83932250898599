import { useState, useEffect } from 'react';

import { useLocation, useParams } from 'react-router';

import { GruposExamesAPI } from 'src/APIs/ProntuarioAPI/GruposExamesAPI/GruposExamesAPI';

type BuscaRapidaFetchGrupoDetailsType = {
  isFetching: boolean;
  grupoDetails: any;
  handlePanelExpand: () => void;
};

interface BuscaRapidaFetchGrupoDetails {
  idProcedimento: number;
}

export const useBuscaRapidaFetchGrupoDetails = (
  props: BuscaRapidaFetchGrupoDetails,
): BuscaRapidaFetchGrupoDetailsType => {
  const { idProcedimento } = props;

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [panelExpand, setPanelExpand] = useState(false);
  const [grupoDetails, setGrupoDetails] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchGrupo = async () => {
      try {
        setIsFetching(true);

        const response = await GruposExamesAPI.getGrupoExameById(
          idProcedimento,
          { params: { idAtendimento } },
        );

        setGrupoDetails(response);
      } catch (error) {
        setGrupoDetails(null);
      } finally {
        setIsFetching(false);
      }
    };

    if (panelExpand) fetchGrupo();
  }, [idAtendimento, idProcedimento, panelExpand]);

  const handlePanelExpand = () => setPanelExpand(true);

  return { grupoDetails, isFetching, handlePanelExpand };
};
