import { useCallback, useEffect, useState } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';

import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Checkbox } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Skeleton from 'src/components/Skeleton/Skeleton';

export const FormRecipientConvenios = ({}) => {
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const [convenios, setConvenios] = useState<GetConvenioDTO[]>([]);

  const { setValue, control } = useFormContext();

  const { fields, append, replace } = useFieldArray({
    name: 'convenios',
    control,
    keyName: 'idForm',
  });
  const [conveniosFiltered, setConveniosFiltered] = useState<any[]>([]);

  const fetchConvenios = useCallback(() => {
    setLoading(true);
    return ConvenioAPI.loadConveniosAtivosSemPaginacao({}, { throwError: true })
      .then(data => setConvenios(data))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  const handleSelectAll = (e: boolean) => {
    setSelectedAll(e);

    if (e) {
      convenios.map((convenio, idx) =>
        setValue(`convenios.${idx}`, {
          ...convenio,
        }),
      );
    } else {
      convenios.map(() => setValue(`convenios`, []));
    }
  };

  useEffectSkipFirst(() => {
    setConveniosFiltered(
      convenios.filter((field: any) =>
        field.nomeFantasia?.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search, fields]);

  useEffect(() => {
    fetchConvenios();
  }, [fetchConvenios]);

  const items = !!search ? conveniosFiltered : convenios;

  const handleCheckboxChange = (isChecked: boolean, conv: GetConvenioDTO) => {
    if (isChecked) {
      append(conv);
    } else {
      const dataFiltered = fields.filter((field: any) => field.id !== conv.id);
      replace(dataFiltered);
    }
  };

  return (
    <div className="p-grid p-flex-column p-gap-2">
      <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
        Filtre os resultados utilizando o campo de pesquisa abaixo.
      </SimpleText>
      <div className="p-col-12 p-px-0 p-mb-2">
        <TextInputSearch
          placeholder="Pesquisar"
          onChange={e => setSearch(e)}
          disabled={isSelectedAll}
        />
      </div>

      <Checkbox
        label="Selecionar todos"
        checked={isSelectedAll}
        onChange={e => handleSelectAll(e.checked)}
      />

      <div className="p-w-100 p-d-flex p-flex-column p-gap-2">
        {!!loading ? (
          <LoadingComponent />
        ) : (
          items.map((conv: ConvenioDTO) => (
            <div
              key={conv.id}
              data-isActive={false}
              className="content-row-convenios p-col-12 p-py-0 p-d-flex p-ai-center"
            >
              <Checkbox
                disabled={isSelectedAll}
                className="p-col-1"
                checked={!!fields.find((field: any) => field.id === conv.id)}
                onChange={e => handleCheckboxChange(e.checked, conv)}
              />

              <SimpleText className="p-col">
                {capitalizeFirstLetter(
                  !!conv?.nomeFantasia ? conv?.nomeFantasia : conv.razaoSocial,
                )}
              </SimpleText>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <>
      <Skeleton loading height="35px" />
      <Skeleton loading height="35px" />
      <Skeleton loading height="35px" />
      <Skeleton loading height="35px" />
    </>
  );
};
