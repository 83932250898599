import Dialog from 'src/components/Dialog/Dialog';
import './ModalVisualizar.scss';

interface ModalVisualizarProps {
  documento: Documentos | null;
  visible: boolean;
  onHide: () => void;
}

function ModalVisualizar({ onHide, visible, documento }: ModalVisualizarProps) {
  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header={'Você tem certeza que deseja inativar?'}
      visible={visible}
      onHide={onHide}
    >
      <img src={documento?.documentoUrl} alt="documento" />
    </Dialog>
  );
}

export default ModalVisualizar;
