import useTheme from 'src/core/themes/useTheme';

import './Card.scss';
import React from 'react';

export enum CARD_TYPES {
  GRAY = 'gray',
  GRAY_WITH_BORDER = 'gray-with-border',
  BORDER = 'border',
}
interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  type?: CARD_TYPES;
}
const Card = ({ children, type, className, ...rest }: CardProps) => {
  const { theme } = useTheme();
  return (
    <div
      className={`Card ${type || ''} ${theme || ''} ${className || ''}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
