import { Sexo } from 'src/models/APIs/UtilsAPI/SexoDTOs';
import { string } from 'yup';

import { capitalizeFirstLetter } from './utils';

export const faixaEtariaWithLabel = (item: any) => {
  if (!item) return;

  const [inicio, fim] = item.valor.split('-');
  const label = `${inicio} anos a ${fim} anos`;

  return { ...item, label };
};

export const valueFaixaEtaria = (idade = 0, list: any[]) => {
  const find = list.find((i: any) => {
    const [inicio, fim] = i.valor.split('-');
    return idade >= inicio && idade <= fim;
  });

  return find?.id;
};

export const sexoWithLabel = (sexo: Sexo) => ({
  label: capitalizeFirstLetter(sexo.descricao),
  value: sexo.id,
});

export const removeComparisonAndGetNumber = (string: string) => {
  const stringWithoutComparison = string.replace(/[<>]=?/g, '');
  const numberBeforeHyphen = stringWithoutComparison.split('-')[0];
  return numberBeforeHyphen || '';
};

export const colesterolWithLabel = (item: any) => {
  if (!item) return;

  let label;

  if (item.valor.includes('-')) {
    const [inicio, fim] = item.valor.split('-');
    label = `${inicio} entre ${fim}`;
  } else {
    label = item.valor
      .replace('<=', 'Menor ou igual a ')
      .replace('>=', 'Maior ou igual a ')
      .replace('<', 'Menor que ')
      .replace('>', 'Maior que');
  }

  return { ...item, label };
};

export const corRiscoCardiaco = (porcentagem = 0, idSexo: number) => {
  if (!porcentagem) return '';

  // idSexo: [1 = Feminino | 3 = Masculino]

  if (idSexo === 1) {
    if (porcentagem >= 32) return 'vermelho';
    if (porcentagem >= 10) return 'laranja';
    if (porcentagem >= 5) return 'amarelo';
  } else {
    if (porcentagem >= 50) return 'vermelho';
    if (porcentagem >= 20) return 'laranja';
    if (porcentagem >= 5) return 'amarelo';
  }

  return 'verde';
};
