import { useState } from 'react';

import { PontuacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/PontuacaoAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface PontuacaoStatusProps {
  idPontuacao: number;
  isAtivo: boolean;
}

export const PontuacaoStatus = (props: PontuacaoStatusProps) => {
  const { idPontuacao, isAtivo } = props;
  const [ativo, setAtivo] = useState(isAtivo);
  const [loading, setLoading] = useState(false);

  const onSwitchStatus = async () => {
    setLoading(true);
    const response = await PontuacaoCarteiraAPI.putPontuacaoStatus(idPontuacao);

    if (response?.status === 200) {
      setAtivo(response.data);
    }

    setLoading(false);
  };

  if (loading)
    return (
      <div className="p-mb-4 p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return (
    <Switch
      checked={ativo}
      showStatus
      onChange={onSwitchStatus}
      checkPermission="ADM_CARTEIRA_APS_PONTUACAO_ALTERAR"
    />
  );
};
