import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  gestacao: any;
}

export default function PanelContent({ gestacao }: PanelContentProps) {
  //Foram feitos alguns ajustes pois a vacina da funcionalidade nao bate com as do CDU de historico

  const vacina = gestacao?.vacina;
  return (
    <div className="p-grid item-historico">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Intercorrência Pré-Natal
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {vacina?.intercorrenciaPreNatal}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-4 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Data da aplicação
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {vacina?.dataInclusao
            ? dayjs(new Date(vacina?.dataInclusao)).format('DD MMM YYYY')
            : 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-8 p-d-flex p-flex-column">
        {/* <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60} className="p-mb-1">
          Motivo do atraso
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </SimpleText> */}
      </div>
      <div className="p-col-12">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          Adicionado em{' '}
          {vacina?.dataAtualizacao
            ? dayjs(new Date(vacina?.dataAtualizacao)).format(
                'DD/MM/YYYY HH:mm',
              )
            : 'Não informado'}
        </SimpleText>
      </div>
    </div>
  );
}
