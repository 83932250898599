import { AxiosInstance } from 'axios';

import HttpCore, {
  AxiosActionTypes,
  RequestParams,
} from 'src/core/http/HttpCore';
import HttpInstances from 'src/core/http/HttpInstances';

export type Service =
  | 'security'
  | 'sessao'
  | 'adm'
  | 'agenda'
  | 'pep'
  | 'report'
  | 'storage';

/**
 * @param axiosInstance - Passing a custom axios
 * isntance, will overwrite the choosen service.
 */
export interface HttpParams extends Omit<RequestParams, 'axiosInstance'> {
  service: Service;
  axiosInstance?: AxiosInstance;
}

const request = (method: AxiosActionTypes, params: HttpParams) => {
  // ATTENTION - Type cast.
  const paramsAux: RequestParams = params.axiosInstance
    ? { ...params, axiosInstance: params.axiosInstance }
    : { ...params, axiosInstance: HttpInstances[params.service] };
  return HttpCore[method](paramsAux);
};

const getAction = (params: HttpParams) => request('get', params);
const postAction = (params: HttpParams) => request('post', params);
const putAction = (params: HttpParams) => request('put', params);
const deleteAction = (params: HttpParams) => request('delete', params);

export interface HttpCoreInterface {
  get(params: HttpParams): any;
  post(params: HttpParams): any;
  put(params: HttpParams): any;
  delete(params: HttpParams): any;
}
const Http: HttpCoreInterface = {
  get: getAction,
  post: postAction,
  put: putAction,
  delete: deleteAction,
};
export default Http;
