import { useCallback, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useFormContext } from 'react-hook-form';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { FieldSelectCids } from '../FieldSelectCids/FieldSelectCids';

import ModalFavoritos from './ModalFavoritos';

type FieldCidsProps = {
  withSearch?: boolean;
  name?: string;
};

export const FieldCidsFavoritos = ({
  withSearch = true,
  name = 'cids',
}: FieldCidsProps) => {
  const { setValue, watch } = useFormContext();

  const favoritosRef = useRef<OverlayPanel>(null);
  const modalDisclosure = useDisclosure({ opened: false });

  const watchCids = watch(name);

  const handleAddCids = useCallback(
    (cids: any) => {
      if (!cids?.length) cids = [cids];

      // Valida se já existe os cids selecionados no data do form
      const cidsFiltered = cids.filter(
        (cid: any) =>
          !watchCids.find((formCid: any) => cid.codigo === formCid.codigo),
      );

      setValue('cids', [...watchCids, ...cidsFiltered]);
    },
    [setValue, watchCids],
  );

  return (
    <div className="p-grid p-col-12 p-py-0 p-ai-center">
      <div className={`p-grid p-col-12 p-md-${withSearch ? '6 p-pl-0' : '12'}`}>
        <SimpleText bold>CID</SimpleText>
        <Button
          btnType="tonal"
          label="Meus favoritos"
          iconPos="right"
          icon="pi pi-chevron-down"
          stretch
          className="p-mt-2"
          type="button"
          onClick={e => favoritosRef.current?.toggle(e)}
        />

        <OverlayPanel ref={favoritosRef}>
          <ModalFavoritos
            handleAdd={handleAddCids}
            onOpenModal={modalDisclosure.open}
            capitalizeFirstLetter={capitalizeFirstLetter}
            onHide={() => favoritosRef.current?.hide()}
            {...modalDisclosure}
          />
        </OverlayPanel>
      </div>

      {withSearch && (
        <div className="p-col-12 p-md-6 p-px-0">
          <FieldSelectCids name={name} className={name} />
        </div>
      )}
    </div>
  );
};
