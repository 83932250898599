import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  convenio: Yup.object()
    .nullable()
    .required('O campo plano de saúde é obrigatório'),
  numeroCartao: Yup.string()
    .required('O campo número da carteirinha é obrigatório')
    .max(30),
  dataValidadeCartao: Yup.date()
    .nullable()
    .required('O campo validade é obrigatório'),
});
