import { useEffect } from 'react';
import { useContext } from 'react';

import { StompSessionProvider } from 'react-stomp-hooks';
import { ToastContainer as ToastifyContainer } from 'react-toastify';

import { useAppSelector } from 'src/core/redux/hooks';
import RouterRender from 'src/core/routes/Routes';
import ThemeContext from 'src/core/themes/ThemeContext';
import ThemeProvider from 'src/core/themes/ThemeProvider';

import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

import { generateUniqueId } from 'src/utils/utils';

import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';

import './App.scss';
import 'primeflex/primeflex.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';
import '../core/themes/Theme.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App(): JSX.Element {
  const {
    user: { access_token },
    telemedicina,
  } = useAppSelector(state => state);

  const { storedValue, setValue } = useLocalStorage('identificacaoSessao');

  useEffect(() => {
    if (!storedValue) {
      const id = generateUniqueId();

      setValue(id);
    } else {
    }
  }, [setValue, storedValue]);

  const wsHeaders = {
    Authorization: `Bearer ${access_token}`,
    Xaccess: storedValue,
    // Apenas para teleconsulta
    idAtendimento: telemedicina?.idAtendimento || '',
  };

  return (
    <div className={'App'}>
      <ErrorBoundary>
        <StompSessionProvider
          url={`${process.env.REACT_APP_NOTIFICACAO_WS_URL}`}
          connectHeaders={wsHeaders}
          // debug={log => console.log('LOG ---->', log)}
          onWebSocketError={error =>
            console.log('Error ----->' + JSON.stringify(error))
          }
          onStompError={error => console.log('Error stomp ----->' + error)}
          reconnectDelay={1000}
        >
          <ThemeProvider>
            <RouterRender />
            <ToastContainer />
          </ThemeProvider>
        </StompSessionProvider>
      </ErrorBoundary>
    </div>
  );
}

const ToastContainer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <ToastifyContainer
      position="bottom-right"
      toastClassName={`Toast toast-container ${theme}`}
      progressClassName="toast-progress"
      limit={2}
      style={{ zIndex: 999999 }}
    />
  );
};

export default App;
