import React, { useCallback } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import ImpressosAPI from 'src/APIs/ProntuarioAPI/ImpressosAPI/ImpressoaAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

interface Props extends DisclosureType {
  impresso: any;
  idAtendimento: number;
  reload: any;
  setReload: any;
}

export default function InativarImpresso({
  impresso,
  isOpen,
  close,
  idAtendimento,
  reload,
  setReload,
}: Props) {
  const validationSchema = Yup.object().shape({
    justificativaInativacao: Yup.string().required(
      'Informe a justificativa da inativação',
    ),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      const response = await ImpressosAPI.inativarImpresso(
        impresso?.id,
        idAtendimento,
        data,
      );

      if (response?.status >= 200 && response?.status < 300) {
        setReload(reload + 1);
        reset();
        close();
      }
    },
    [close, idAtendimento, impresso?.id, reload, reset, setReload],
  );

  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header="Você tem certeza que deseja inativar?"
      visible={isOpen}
      onHide={() => {
        reset();
        close();
      }}
      maximizedMobileSize
    >
      <form id="inativacao-impresso">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          Você está inativando a inclusão deste item no prontuário do paciente.
          Ele continuará ser exibido como uma informação inativa. Para
          continuar, justifique.
        </SimpleText>

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="justificativaInativacao"
          label="Justificativa"
          placeholder="Informe a justificativa"
          errorMsg={errors.justificativaInativacao?.message}
        />
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            type="reset"
            label="Cancelar"
            className="p-col-align-center"
            onClick={() => {
              close();
              reset();
            }}
            stretch
          />
          <Button
            type="submit"
            label="Salvar"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
            stretch
          />
        </div>
      </form>
    </Dialog>
  );
}
