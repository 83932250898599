import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/servico-tuss';

export const loadTiposByServicoTuss = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<string[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/busca-tipos`,
    hideToast: true,
    ...options,
  });
};

export const loadClassesByServicoTuss = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<string[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/busca-classes`,
    hideToast: true,
    ...options,
  });
};

export const loadServicoTuss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadServicoTussById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetServicoTussCRUDDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendServicoTuss = (
  payload: PostServicoTussCRUDDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostServicoTussCRUDDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateServicoTuss = (
  id: number,
  payload: PostServicoTussCRUDDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostServicoTussCRUDDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateServicoTussAtivar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostServicoTussCRUDDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/ativar`,
    return: true,
    ...options,
  });
};

export const updateServicoTussInativar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostServicoTussCRUDDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/inativar`,
    return: true,
    ...options,
  });
};

export const removeServicoTussById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetServicoTussCRUDDTO> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

export const getSolicitacaoExame = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/solicitacao-exame/pesquisa`,
    hideToast: true,
    throwError: true,
    params,
    ...options,
  });

const getServicoTussFavoritos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/favoritos`,
    hideToast: true,
    throwError: true,
    params,
    ...options,
  });

const favoritarExame = (
  idServicoTuss: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `exame-favorito/${idServicoTuss}`,
    throwError: true,
    ...options,
  });

const desfavoritarExame = (
  idServicoTuss: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `exame-favorito/${idServicoTuss}`,
    throwError: true,
    ...options,
  });

const ServicoTussCRUDAPI = {
  loadServicoTuss,
  loadTiposByServicoTuss,
  loadClassesByServicoTuss,
  loadServicoTussById,
  updateServicoTuss,
  updateServicoTussAtivar,
  updateServicoTussInativar,
  removeServicoTussById,
  sendServicoTuss,
  getSolicitacaoExame,
  getServicoTussFavoritos,
  favoritarExame,
  desfavoritarExame,
};

export default ServicoTussCRUDAPI;
