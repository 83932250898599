import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-d-flex p-my-1">
          <div className="p-col-3 p-md-4 p-lg-5">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Tipo de motivo de atendimento
            </SimpleText>
          </div>
          <div className="p-col-3 p-md-4 p-lg-5">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Consultório
            </SimpleText>
          </div>
          <div className="p-col-3 p-md-2 p-lg-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Status
            </SimpleText>
          </div>
          <div className="p-col-3 p-md-2 p-lg-1 p-d-flex p-jc-end">
            <Can
              checkPermission={[
                'AGE_MOTIVO_DE_ATENDIMENTO_ALTERAR',
                'AGE_MOTIVO_DE_ATENDIMENTO_EXCLUIR',
              ]}
            >
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
                className="p-mr-1"
              >
                Ações
              </SimpleText>
            </Can>
          </div>
        </div>
      )}
    </>
  );
};
