import { useMemo, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useFieldArray, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { capitalizeFirstLetter, formatCPF } from 'src/utils/utils';

import { Button, Checkbox } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Skeleton from 'src/components/Skeleton/Skeleton';

export const FormRecipientPacientes = ({}) => {
  const [pacientes, setPacientes] = useState<any[]>([]);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const ref = useRef<OverlayPanel>(null);
  const { watch, control, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pacientes',
    keyName: 'idForm',
  });

  const convenios = watch('convenios');
  const idsConvenios = useMemo((): number[] => {
    return convenios.map((conv: any) => conv.id);
  }, [convenios]);

  const fetchPacientes = (query: string) => {
    setLoading(true);
    return PacienteAPI.loadPacientesPorConvenios(
      {
        idsConvenio: idsConvenios.toString().trim(),
        chave: query,
      },
      { throwError: true },
    )
      .then(pac => (!!pac.list ? setPacientes(pac.list) : setPacientes([])))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffectSkipFirst(() => {
    fetchPacientes(search);
  }, [search]);

  useEffectSkipFirst(() => {
    if (isSelectedAll) setValue('todosPacientes', true);
    else setValue('todosPacientes', false);
  }, [isSelectedAll]);

  const handleRemovePaciente = (idx: number) => {
    remove(idx);
  };

  const handleAddPaciente = (value: any) => {
    append(value);
    ref.current?.hide();
  };

  return (
    <div className="p-grid p-flex-column p-gap-2">
      <Checkbox
        label="Selecionar todos"
        checked={isSelectedAll}
        onChange={e => setSelectedAll(e.checked)}
      />

      <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_40}>
        Filtre os resultados utilizando o campo de pesquisa abaixo.
      </SimpleText>
      <div className="p-col-12 p-px-0 p-mb-2">
        <TextInputSearch
          placeholder="Pesquisar"
          onChange={e => setSearch(e)}
          onClick={e => ref.current?.toggle(e)}
          disabled={isSelectedAll}
        />

        <OverlayPanel ref={ref} id="PanelFormRecipient-comunicados" style={{}}>
          <div className="p-d-flex p-flex-column p-gap-2">
            {loading ? (
              <LoadingComponent />
            ) : (
              pacientes.map(value => (
                <div
                  key={value.id}
                  className="content-row p-col-12 p-py-0 p-d-flex p-ai-center"
                  onClick={() => handleAddPaciente(value)}
                >
                  <Avatar
                    label={value.nome}
                    image={value.urlFoto}
                    className="p-col-2"
                  />
                  <div className="p-col p-d-flex p-flex-column p-gap-1">
                    <SimpleText fontColor="color_60" fontSize="xxs">
                      {capitalizeFirstLetter(value.sexo || '')}
                    </SimpleText>
                    <SimpleText>{value.nome}</SimpleText>
                    <SimpleText fontColor="color_60">
                      {dayjs(new Date()).diff(value.dataNascimento, 'years')}{' '}
                      ano(s) • {formatCPF(value.cpf)}
                    </SimpleText>
                  </div>
                </div>
              ))
            )}

            {!pacientes.length && !search && (
              <SimpleText>Começe a pesquisar por pacientes.</SimpleText>
            )}
            {!loading && !pacientes.length && !!search && (
              <SimpleText>
                Não foram encontrados pacientes com essa busca.
              </SimpleText>
            )}
          </div>
        </OverlayPanel>
      </div>

      <div className="p-w-100 p-d-flex p-flex-column p-gap-2">
        {fields.map((pac: any, idx) => (
          <div
            key={idx}
            className="content-row p-col-12 p-py-0 p-d-flex p-ai-center"
          >
            <Avatar label={pac.nome} image={pac.urlFoto} className="p-col-2" />
            <div className="p-col-8 p-d-flex p-flex-column p-gap-1">
              <SimpleText fontColor="color_60" fontSize="xxs">
                {capitalizeFirstLetter(pac.sexo || '')}
              </SimpleText>
              <SimpleText>{pac.nome}</SimpleText>
              <SimpleText fontColor="color_60">
                {dayjs(new Date()).diff(pac.dataNascimento, 'years')} ano(s) •{' '}
                {formatCPF(pac.cpf)}
              </SimpleText>
            </div>

            <Button
              icon="fas fa-trash"
              btnType="gray"
              className="p-ml-auto"
              disabled={isSelectedAll}
              onClick={() => handleRemovePaciente(idx)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <>
      <Skeleton loading height="35px" />
      <Skeleton loading height="35px" />
      <Skeleton loading height="35px" />
      <Skeleton loading height="35px" />
    </>
  );
};
