import Http from 'src/core/http/Http';

const getCabecalhoProntuario = async (
  idAtendimento: number,
  options?: any,
): Promise<Prontuario> =>
  Http.get({
    service: 'pep',
    url: `cabecalhos-prontuarios/${idAtendimento}`,
    ...options,
    hideToast: true,
  });

const loadBuscaAvancada = async (
  params?: any,
  options?: any,
): Promise<Paginator<GetBuscaAvancada>> =>
  Http.get({
    service: 'pep',
    url: `prontuario/busca-avancada`,
    params,
    ...options,
    hideToast: true,
  });

const ProntuarioAPI = {
  getCabecalhoProntuario,
  loadBuscaAvancada,
};

export default ProntuarioAPI;
