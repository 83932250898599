import * as Yup from 'yup';

const msg = {
  required: 'Este campo é obrigatório',
};

export const validatioSchema = Yup.object().shape({
  portal: Yup.boolean(),
  dataOcorrencia: Yup.date().when('portal', {
    is: false,
    then: Yup.date().required(msg.required),
  }),

  // VICIOS
  fumante: Yup.string().required(msg.required),
  fumanteDomicilio: Yup.string().required(msg.required),
  atividadeFisica: Yup.string().required(msg.required),
  bebidaAlcoolica: Yup.string().required(msg.required),
  medicamentoControlado: Yup.string().required(msg.required),

  // DOR FREQUENTE
  dorCostasFrequente: Yup.string().required(msg.required),
  dorCabecaFrequente: Yup.string().required(msg.required),
  dorRelacionadaTrabalho: Yup.string().required(msg.required),
  intensidadeDores: Yup.string().required(msg.required),

  // SAUDE MENTAL
  transtornoMental: Yup.string().required(msg.required),
  suicidio: Yup.string().required(msg.required),
  depressao: Yup.string().required(msg.required),
  ansiedade: Yup.string().required(msg.required),

  // HISTORICO FAMILIAR
  mama: Yup.string().required(msg.required),
  intestino: Yup.string().required(msg.required),
  prostata: Yup.string().required(msg.required),
  outros: Yup.string().required(msg.required),
  descricaoOutros: Yup.string()
    .nullable()
    .when('outros', (outros, field) =>
      outros === 'SIM' ? field.required('Este campo é obrigatório') : field,
    ),

  // PERFIL SAUDE
  satisfacaoSaude: Yup.string().required(msg.required),
  diabetes: Yup.string().required(msg.required),
  hipertensao: Yup.string().required(msg.required),
  colesterolOuTriglicerides: Yup.string().required(msg.required),
  doencasCerebrovasculares: Yup.string().required(msg.required),
  doencasCardiovasculares: Yup.string().required(msg.required),
  doencasRespiratorias: Yup.string().required(msg.required),
  insuficienciaRenal: Yup.string().required(msg.required),
  doencasNeurologicas: Yup.string().required(msg.required),
  cirurgiaColunaProgramada: Yup.string().required(msg.required),
  usuarioDrogas: Yup.string().required(msg.required),
  calendarioVacinal: Yup.string().required(msg.required),
  tomouVacinaGripe: Yup.string().required(msg.required),
  gestante: Yup.string().required(msg.required),

  // PESQUISA COMPLEMENTAR
  realizouMamografia: Yup.string().required(msg.required),
  realizouPapanicolau: Yup.string().required(msg.required),
  realizouColonoscopia: Yup.string().required(msg.required),
  realizouSangueOcultoFezes: Yup.string().required(msg.required),

  alimentacaoNaoSeAplica: Yup.bool().when(
    [
      'equilibrada',
      'excessoGorduras',
      'excessoSal',
      'excessoCarboidratosAcucares',
    ],
    {
      is: (
        equilibrada: boolean,
        excessoGorduras: boolean,
        excessoSal: boolean,
        excessoCarboidratosAcucares: boolean,
      ) => {
        if (
          equilibrada ||
          excessoGorduras ||
          excessoSal ||
          excessoCarboidratosAcucares
        ) {
          return false;
        }

        return true;
      },
      then: schema => schema.required(msg.required),
      otherwise: schema => schema.oneOf([true], msg.required),
    },
  ),

  cirurgiasNaoSeAplica: Yup.bool().when(
    [
      'cardiaca',
      'cancer',
      'amputacaoPernaPe',
      'bariatrica',
      'outra',
      'naoRealizou',
    ],
    {
      is: (
        cardiaca: boolean,
        cancer: boolean,
        amputacaoPernaPe: boolean,
        bariatrica: boolean,
        outra: boolean,
        naoRealizou: boolean,
      ) => {
        const hasAnySurgery =
          cardiaca ||
          cancer ||
          amputacaoPernaPe ||
          bariatrica ||
          outra ||
          naoRealizou;

        if (hasAnySurgery) {
          return false;
        }

        return true;
      },
      then: schema => schema.required(msg.required),
      otherwise: schema => schema.oneOf([true], msg.required),
    },
  ),

  densitometriaNaoSeAplica: Yup.bool().when(
    ['colunaLombar', 'femurTotal', 'coloFemural', 'naoRealizouDensitometria'],
    {
      is: (
        colunaLombar: boolean,
        femurTotal: boolean,
        coloFemural: boolean,
        naoRealizouDensitometria: boolean,
      ) => {
        if (
          colunaLombar ||
          femurTotal ||
          coloFemural ||
          naoRealizouDensitometria
        ) {
          return false;
        }

        return true;
      },
      then: schema => schema.required(msg.required),
      otherwise: schema => schema.oneOf([true], msg.required),
    },
  ),
});
