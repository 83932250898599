import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';
import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

const formFields = {
  rgNumero: '',
  rgUf: '',
  rgDataEmissao: '',
  cns: '',
  rgOrgaoEmissor: '',
  complemento: '',
};
interface DocumentosProps {
  handleActiveIndex(): void;
}

const Documentos = ({ handleActiveIndex }: DocumentosProps) => {
  const { isProfessionalHealth } = useSelectProfileCreateUser();
  const { idUser } = useCreateUser();

  const [estados, setEstados] = useState<any>();
  const [usuario, setUsuario] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const form = useForm();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = form;

  const getUpdateAction = useCallback(() => {
    if (isProfessionalHealth) return ProfissionalAPI.updateProfissional;

    return UsuarioAPI.updateUsuario;
  }, [isProfessionalHealth]);

  const handleSave = useCallback(
    async (data: any) => {
      const updateAction = getUpdateAction();

      const payload: any = {
        ...data,
        idEmpresa: usuario?.idEmpresa,
      };

      const res = await updateAction('documentos', idUser, payload);

      if (res.status === 200) {
        handleActiveIndex();
      }
    },
    [getUpdateAction, handleActiveIndex, idUser, usuario?.idEmpresa],
  );

  const getDocumentosAPI = useCallback(() => {
    if (isProfessionalHealth) return ProfissionalAPI.getProfissional;

    return UsuarioAPI.getUser;
  }, [isProfessionalHealth]);

  function formatDate(date: string) {
    if (!date) return null;

    const dateFormmated = date.replace('-', '/');

    return new Date(dateFormmated);
  }

  const load = useCallback(async () => {
    const getDocumentos = getDocumentosAPI();

    const documentos = await getDocumentos(idUser, 'documentos');

    const estados = await UtilsAPI.GetEstados();

    setEstados(estados);

    setValue('rgNumero', documentos?.rgNumero);
    setValue('rgOrgaoEmissor', documentos?.rgOrgaoEmissor);
    setValue('cns', documentos?.cns);
    setValue('rgUf', documentos?.rgUf);
    setValue('rgDataEmissao', formatDate(documentos?.rgDataEmissao));
    setValue('complemento', documentos?.complemento);
    setUsuario(documentos);

    setLoading(false);
  }, [getDocumentosAPI, idUser, setValue]);

  useEffect(() => {
    load();
  }, [load]);

  const estadosOptions = useMemo(
    () =>
      estados?.map((estado: any) => ({
        label: estado?.sigla,
        value: estado?.sigla,
      })),
    [estados],
  );

  if (loading)
    return (
      <div className="formulario">
        <div className="loadingdados">
          <GridListLoading />
        </div>
      </div>
    );

  return (
    <FormProvider {...form}>
      <form
        className="p-grid"
        onSubmit={handleSubmit(handleSave)}
        defaultValue={''}
        onReset={() => reset(formFields)}
      >
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
          Registro geral (RG)
        </SimpleText>

        <FormInput
          name="rgNumero"
          type={'number'}
          label="Número"
          placeholder="Digite o número"
          className="p-col-12 p-md-6"
          hideTextErrorSpace
          maxLength={10}
        />
        <FormInput
          name="rgOrgaoEmissor"
          label="Órgão emissor"
          placeholder="Digite o órgão emissor"
          className="p-col-12 p-md-6"
          maxLength={5}
          hideTextErrorSpace
        />
        <DropdownControlled
          control={control}
          name="rgUf"
          label="UF"
          placeholder="Selecione o estado"
          className="p-col-12 p-md-6"
          options={estadosOptions}
        />
        <CalendarInputControlled
          control={control}
          name="rgDataEmissao"
          label="Data de emissão"
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-md-6"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
        />
        <FormInput
          name="complemento"
          label="Complemento"
          placeholder="Digite o complemento"
          className="p-col-12"
          maxLength={15}
        />

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
          Carteira Nacional de Saúde
        </SimpleText>

        <FormInput
          name="cns"
          label="Número de CNS"
          placeholder="Digite o CNS"
          className="p-col-12"
          type="number"
          maxLength={15}
        />

        <div className="p-col-12 p-md-6">
          <Button
            btnType="tonal"
            label={'Salvar e continuar'}
            type="submit"
            onClick={handleSubmit(handleSave)}
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default Documentos;
