import dayjs from 'dayjs';

import SimpleText, { FONT_SIZE, FONT_COLOR } from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';

interface PanelContentProps {
  medicamento: MedicamentosUso;
}

function PanelContent({ medicamento }: PanelContentProps) {
  const headerTextClassName = `p-mb-2 ${!medicamento.status ? 'medicamento-inativo' : ''}`;
  const dataTextClassName = !medicamento.status ? 'line-through medicamento-inativo' : '';

  return (
    <div className="p-grid medicamentos-panel-content">
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data início
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {dayjs(medicamento.dataInicio).format('DD/MM/YYYY')}
        </SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data fim
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {!!medicamento?.dataFim && dayjs(medicamento.dataFim).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Posologia
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {medicamento.posologia}
        </SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Via
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {medicamento.via}
        </SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Quantidade
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {medicamento.quantidade}
        </SimpleText>
      </div>
      {medicamento?.justificativaOcorrenciaRetroativa && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {medicamento.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Adicionado por <b>{medicamento.usuarioAtualizacao.nome}</b> em {dayjs(medicamento.dataCriacao).locale('pt-br').format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>

    </div>
  );
}

export default PanelContent;