import { useSelectProfileCreateUser } from 'src/core/hooks/ManterUsuario/useSelectProfileCreateUser';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';


import HealthProfessional from './HealthProfessional/HealthProfessional';
import Secretary from './Secretary/Secretary';

interface CustomizationProps {
  control: any;
}

function Customization({ control }: CustomizationProps) {
  const { selectedProfile } = useSelectProfileCreateUser();

  if (selectedProfile?.value === 'PROFISSIONAL_SAUDE')
    return (
      <>
        <SimpleText bold className="p-col-12">
          Customizações
        </SimpleText>
        <HealthProfessional control={control} />
      </>
    );

  if (selectedProfile?.value === 'SECRETARIO')
    return (
      <>
        <SimpleText bold className="p-col-12">
          Customizações
        </SimpleText>
        <Secretary control={control} />
      </>
    );

  return null;
}

export default Customization;
