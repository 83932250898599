import dayjs from 'dayjs';

import SimpleText, { FONT_SIZE, FONT_COLOR } from 'src/components/Basics/SimpleText/SimpleText';

import './PanelContent.scss';

interface PanelContentProps {
  opme: Opme;
}

function PanelContent({ opme }: PanelContentProps) {

  const headerTextClassName = `p-mb-2 ${opme.status === 'INATIVO' ? 'opme-inativa' : ''}`;
  const dataTextClassName = opme.status === 'INATIVO' ? 'line-through opme-inativa' : '';

  return (
    <div className="opme-panel-content p-grid">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Observações
        </SimpleText>
        <SimpleText
          className={dataTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {opme.observacoes}
        </SimpleText>
      </div>
      {opme.retroativo && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Justificativa para registro retroativo
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {opme.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Adicionado por <b>{opme.usuarioDto.nome}</b> em {dayjs(opme.dataInclusao).locale('pt-br').format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>

    </div>
  );
}

export default PanelContent;