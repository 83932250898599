import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

export const DialogTabChange = ({
  state: handleTabChange,
  close,
}: DisclosureType<() => void>) => {
  return (
    <Dialog
      visible
      onHide={close}
      header="Confirmação"
      style={{
        width: '528px',
      }}
    >
      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText>
          Existem dados que não foram salvos no formulário. Deseja trocar a aba
          de questionários mesmo assim?
        </SimpleText>
        <div className="p-d-flex p-gap-2">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Sim, trocar de aba"
            btnType="danger"
            onClick={() => [handleTabChange?.(), close()]}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};
