// import { Container } from './styles';

import { useCallback, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import PesoAlturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PesoAlturaAPI/PesoAlturaAPI';

import { TagPesoAltura } from '../_components/tagPesoAltura/tagPesoAltura';
import { RadioButton } from 'src/components/_UI/RadioButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from '../PanelContent/PanelContent';
import PanelHeader from '../PanelHeader/PanelHeader';

interface PesoAlturaProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const PesoAltura = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: PesoAlturaProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [unidadeMedidaPeso, setUnidadeMedidaPeso] = useState<
    'MOSTRAR_ORIGINAL' | 'KG' | 'GRAMAS'
  >('MOSTRAR_ORIGINAL');

  const [unidadeMedidaAltura, setUnidadeMedidaAltura] = useState<
    'MOSTRAR_ORIGINAL' | 'METRO' | 'CENTIMETRO'
  >('MOSTRAR_ORIGINAL');

  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      return PesoAlturaAPI.getPesoAltura({
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        todosStatus: true,
        ...params,
      });
    },
    [idAtendimento, selectedAtendimento?.idAtendimento],
  );

  const manterDuasDecimais = (value: number) => {
    return String(value)
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\.\d{2}).+/g, '$1');
  };

  const formatValueViewPeso = (value: number, unidadeMedida: any) => {
    if (unidadeMedida.codigo === 'G' && unidadeMedidaPeso === 'KG') {
      return `${manterDuasDecimais(Number(value) / 1000)} Quilogramas`;
    }

    if (unidadeMedida.codigo === 'KG' && unidadeMedidaPeso === 'GRAMAS') {
      return `${manterDuasDecimais(Number(value) * 1000)} Gramas`;
    }

    return `${value} ${unidadeMedida.descricao.concat(value > 1 ? 's' : '')}`;
  };

  const formatValueViewAltura = (value: number, unidadeMedida: any) => {
    if (unidadeMedida.codigo === 'CM' && unidadeMedidaAltura === 'METRO') {
      return `${manterDuasDecimais(Number(value) / 100)} Metros`;
    }

    if (unidadeMedida.codigo === 'M' && unidadeMedidaAltura === 'CENTIMETRO') {
      return `${manterDuasDecimais(Number(value) * 100)} Centímetros`;
    }

    return `${value} ${unidadeMedida.descricao.concat(value > 1 ? 's' : '')}`;
  };

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
  ) => {
    return (
      <div className="p-col-12 p-lg-5 p-d-flex">
        <div className="column p-jc-center">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            idade
          </SimpleText>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.idadeAnos >= 1 &&
              preAtendimento.idadeAnos + ' anos '}

            {preAtendimento.idadeMeses >= 1 &&
              preAtendimento.idadeMeses + ' meses'}

            {preAtendimento.idadeAnos === 0 &&
              preAtendimento.idadeMeses === 0 &&
              '0 meses'}
          </SimpleText>
        </div>
        <Separator layout="vertical" />

        <div className="column p-d-flex p-jc-center p-ai-center">
          <div className="column p-jc-center">
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Peso
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {formatValueViewPeso(
                preAtendimento.peso,
                preAtendimento.pesoUnidadeMedida,
              )}
            </SimpleText>
          </div>
        </div>
        <Separator layout="vertical" />

        <div className="column p-d-flex p-jc-center p-ai-center">
          <div className="column p-jc-center p-ml-2">
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Altura
            </SimpleText>
            <SimpleText
              className={dataTextClassName}
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {formatValueViewAltura(
                preAtendimento.altura,
                preAtendimento.alturaUnidadeMedida,
              )}
            </SimpleText>
          </div>
        </div>
        <Separator layout="vertical" />

        <TagPesoAltura
          imc={preAtendimento.imc}
          headerClassName={headerTextClassName}
        />

        <Separator layout="vertical" />
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    const dataTextClassName = `p-mt-2 ${
      preAtendimento.status === 'INATIVO'
        ? 'line-through preAtendimento-inativa'
        : ''
    }`;
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento}>
          <div className="container-content p-my-3">
            <div className="column p-jc-center p-ml-2">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                IMC
              </SimpleText>
              <SimpleText
                className={dataTextClassName}
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {preAtendimento.imc}
              </SimpleText>
            </div>

            <div className="column p-jc-center p-ml-2">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Superf. corporal
              </SimpleText>
              <SimpleText
                className={dataTextClassName}
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {preAtendimento.superfCorporal}
              </SimpleText>
            </div>
          </div>
        </PanelContent>
      </Panel>
    );
  };

  return (
    <div className="container-peso-altura">
      <div className="p-grid p-p0 p-mb-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          Exibir unidades de medida em:
        </SimpleText>

        <div className="row p-as-center p-jc-between p-mt-3">
          <div className="p-w-5">
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              Peso
            </SimpleText>

            <div className="p-grid p-as-center p-js-betwen p-mt-1 p-gap-2">
              <RadioButton
                className="p-col"
                name="unidadeMedida"
                label="Mostrar original"
                value="MOSTRAR_ORIGINAL"
                checked={unidadeMedidaPeso === 'MOSTRAR_ORIGINAL'}
                onChange={() => setUnidadeMedidaPeso('MOSTRAR_ORIGINAL')}
              />

              <RadioButton
                className="p-col p-mx-2"
                name="unidadeMedida"
                label="KG"
                value="KG"
                checked={unidadeMedidaPeso === 'KG'}
                onChange={() => setUnidadeMedidaPeso('KG')}
              />

              <RadioButton
                className="p-col"
                name="unidadeMedida"
                label="Gramas"
                value="GRAMAS"
                checked={unidadeMedidaPeso === 'GRAMAS'}
                onChange={() => setUnidadeMedidaPeso('GRAMAS')}
              />
            </div>
          </div>

          <div className="p-w-5">
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              Altura
            </SimpleText>

            <div className="p-grid p-js-betwen p-mt-1 p-gap-2">
              <RadioButton
                className="p-col"
                name="unidadeMedidaAltura"
                label="Mostrar original"
                value="MOSTRAR_ORIGINAL"
                checked={unidadeMedidaAltura === 'MOSTRAR_ORIGINAL'}
                onChange={() => setUnidadeMedidaAltura('MOSTRAR_ORIGINAL')}
              />

              <RadioButton
                className="p-col p-mx-2"
                name="unidadeMedidaAltura"
                label="Metros"
                value="METRO"
                checked={unidadeMedidaAltura === 'METRO'}
                onChange={() => setUnidadeMedidaAltura('METRO')}
              />

              <RadioButton
                className="p-col"
                name="unidadeMedidaAltura"
                label="Centímetros"
                value="CENTIMETRO"
                checked={unidadeMedidaAltura === 'CENTIMETRO'}
                onChange={() => setUnidadeMedidaAltura('CENTIMETRO')}
              />
            </div>
          </div>
        </div>
      </div>

      <InfiniteScroll
        fetchAPI={fetchAPI}
        renderRow={renderRow}
        updateList={updateList}
      />
    </div>
  );
};

export default PesoAltura;
