import React, { useCallback } from 'react';

import { useNavigate } from 'react-router';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';

import DesktopRow from './DesktopRow';
import DesktopRowLarge from './DesktopRowLarge';
import MobileRow from './MobileRow';

interface RowProps {
  data: any;
}

const Row = ({ data }: RowProps) => {
  const navigate = useNavigate();

  const { windowInnerWidth, isMobile } = useSize();

  const navigateProntuario = useCallback(
    (id: string) => {
      navigate(`/prontuario`, {
        state: {
          idAtendimento: id,
        },
      });
    },
    [navigate],
  );

  const prontuarioButton = (
    <Button
      btnType="gray"
      icon="fas fa-eye"
      onClick={() => {
        navigateProntuario(data.idAtendimento);
      }}
      disabled={!data.idAtendimento}
    />
  );

  const commonProps = {
    prontuarioButton,
    data,
  };

  if (isMobile) {
    return <MobileRow {...commonProps} />;
  }

  if (windowInnerWidth >= 1253) {
    return <DesktopRowLarge {...commonProps} />;
  }

  return <DesktopRow {...commonProps} />;
};

export default Row;
