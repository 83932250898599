/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';

import ThemeContext from 'src/core/themes/ThemeContext';

import { cleanObject } from 'src/utils/utils';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import FormAdditionalData from './FormsEmpresa/FormAdditionalData/FormAdditionalData';
import FormBiometria from './FormsEmpresa/FormBiometria/FormBiometria';
import FormRequiredData from './FormsEmpresa/FormRequiredData/FormRequiredData';
import FormResponsables from './FormsEmpresa/FormResponsables/FormResponsables';

import './Empresa.scss';

const Empresa = () => {
  const { theme } = useContext(ThemeContext);
  const params = useParams();

  const [stepRequiredData, setStepRequiredData] = useState<Partial<EmpresaDTO>>(
    {} as Partial<EmpresaDTO>,
  );
  const [stepResponsables, setStepResponsables] = useState<Partial<EmpresaDTO>>(
    {} as Partial<EmpresaDTO>,
  );
  const [stepBiometria, setStepBiometria] = useState<Partial<EmpresaDTO>>(
    {} as Partial<EmpresaDTO>,
  );
  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const [empresaAtual, setEmpresaAtual] = useState({} as any);
  const [dadosLogo, setDadosLogo] = useState<Partial<EmpresaDTO>>(
    {} as Partial<EmpresaDTO>,
  );
  const [loading, setLoading] = useState(false);

  const loadPage = useCallback(async () => {
    if (params.id) {
      setLoading(true);
      const id = parseInt(params.id);

      try {
        const response: any = await EmpresasAPI.loadEmpresaById(id);

        const empresaEncontrada: Partial<EmpresaDTO> = response?.data;

        const requiredData = {
          nome: empresaEncontrada.nome!,
          codigo: empresaEncontrada.codigo!,
          convenio: empresaEncontrada.convenio!,
          cnpj: empresaEncontrada.cnpj!,
          razaoSocial: empresaEncontrada.razaoSocial!,
          site: empresaEncontrada.site!,
          email: empresaEncontrada.email!,
          telefone1: empresaEncontrada?.telefone1!,
          telefone2: empresaEncontrada?.telefone2!,
          endereco: empresaEncontrada?.endereco!,
        };

        const responsables: any = {
          responsavelGeral: empresaEncontrada?.responsavelGeral,
          responsavelFinanceiro: empresaEncontrada?.responsavelFinanceiro,
          responsavelTecnico: empresaEncontrada?.responsavelTecnico,
        };

        const logo: any = {
          exibeLogoRelatorio: empresaEncontrada?.exibeLogoRelatorio,
          exibeLogoSistema: empresaEncontrada?.exibeLogoSistema,
          logoUrl: empresaEncontrada?.logoUrl,
          logo: empresaEncontrada?.logo,
        };

        setStepRequiredData({ ...requiredData });

        setStepResponsables({ ...responsables });

        setDadosLogo({ ...logo });

        empresaEncontrada.biometriaFacial &&
          setStepBiometria(empresaEncontrada.biometriaFacial);

        setEmpresaAtual({
          ...requiredData,
          ...responsables,
          ...empresaEncontrada,
          ...empresaAtual,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const handleEmpresa = useCallback(
    async (values: any) => {
      let response: any = '';

      const {
        nome,
        razaoSocial,
        cnpj,
        site,
        email,
        telefone1,
        telefone2,
        idConvenio,
        codigo,
        logradouro,
        bairro,
        complemento,
        cep,
        idCidade,
        numero,
        senhaCliente,
      } = values;

      const novaEmpresa = {
        nome,
        razaoSocial,
        cnpj,
        site,
        email,
        telefone1,
        telefone2,
        idConvenio,
        codigo,
        senhaCliente,
        endereco: {
          numero,
          complemento,
          logradouro,
          cep,
          bairro,
          idCidade,
        },
      };

      if (empresaAtual?.id) {
        const empresaAtualizado = {
          ...empresaAtual,
          ...novaEmpresa,
        };

        if (!empresaAtualizado.senhaCliente)
          delete empresaAtualizado.senhaCliente;

        const consultorioId: number = empresaAtual?.id;

        setEmpresaAtual(empresaAtualizado);

        response = await EmpresasAPI.updateEmpresa(
          consultorioId,
          empresaAtualizado,
        );

        if (response?.status === 200) {
          setEmpresaAtual(response?.data);
          if (timelineRef && timelineRef.current && timelineRef.current.next) {
            timelineRef.current?.next();
          }
        }
      } else {
        response = await EmpresasAPI.sendEmpresa(novaEmpresa as any);

        if (response?.status === 200) {
          setEmpresaAtual({
            ...novaEmpresa,
            ...response?.data,
            endereco: novaEmpresa.endereco,
          });
          if (timelineRef && timelineRef.current && timelineRef.current.next) {
            timelineRef.current?.next();
          }
        }
      }
    },

    [empresaAtual],
  );

  const handleUpdateEmpresa = useCallback(
    async (values: any) => {
      try {
        let response: any = '';

        const {
          nomeResponsavelGeral,
          cargoResponsavelGeral,
          emailResponsavelGeral,
          telefoneResponsavelGeral,
          isResponsavelFinanceiro,
          nomeResponsavelFinanceiro,
          emailResponsavelFinanceiro,
          telefoneResponsavelFinanceiro,
          nomeResponsavelTecnico,
          emailResponsavelTecnico,
          telefoneResponsavelTecnico,
          biometriaFacial,
        } = values;

        const novaEmpresa: any = {
          responsavelGeral: {
            nome: nomeResponsavelGeral,
            cargo: cargoResponsavelGeral,
            email: emailResponsavelGeral,
            telefone: telefoneResponsavelGeral,
            responsavelFinanceiro: isResponsavelFinanceiro,
          },
          responsavelFinanceiro: {
            nome: nomeResponsavelFinanceiro,
            email: emailResponsavelFinanceiro,
            telefone: telefoneResponsavelFinanceiro,
          },
          responsavelTecnico: {
            nome: nomeResponsavelTecnico,
            email: emailResponsavelTecnico,
            telefone: telefoneResponsavelTecnico,
          },
        };

        setStepResponsables(novaEmpresa);

        const empresaAtualizado = {
          ...empresaAtual,
          ...novaEmpresa,
          biometriaFacial,
        };

        const payload = cleanObject(empresaAtualizado);

        const empresaId: number = empresaAtual?.id;

        response = await EmpresasAPI.updateEmpresa(empresaId, payload);

        setEmpresaAtual(response?.data);
        if (timelineRef && timelineRef.current && timelineRef.current.next) {
          timelineRef.current?.next();
        }
      } catch (error) {
        console.error(error);
      }
    },

    [empresaAtual],
  );

  const steps = useCallback(
    (): TimelineValue[] => [
      {
        title: 'Informações básicas',
        component: (
          <FormRequiredData
            isUpdate={!!empresaAtual?.id}
            values={stepRequiredData}
            onSubmit={(values: Partial<EmpresaDTO>) => {
              setStepRequiredData(values);
              handleEmpresa(values);
            }}
          />
        ),
      },
      {
        title: 'Responsáveis',
        component: (
          <FormResponsables
            values={stepResponsables as any}
            onSubmit={(values: any) => {
              // event.preventDefault();
              // setStep2(values);
              handleUpdateEmpresa(values);
            }}
          />
        ),
        disabled: Object.keys(stepRequiredData).length === 0,
      },
      {
        title: 'Biometria',
        component: (
          <FormBiometria
            values={stepBiometria}
            onSubmit={(values: any) => {
              handleUpdateEmpresa(values);
            }}
          />
        ),
        disabled: Object.keys(stepRequiredData).length === 0,
      },
      {
        title: 'Outros',
        component: (
          <FormAdditionalData
            idEmpresa={empresaAtual.id}
            dadosLogo={dadosLogo}
          />
        ),
        disabled: Object.keys(stepRequiredData).length === 0,
      },
    ],
    [
      empresaAtual.id,
      stepRequiredData,
      stepResponsables,
      stepBiometria,
      dadosLogo,
      handleEmpresa,
      handleUpdateEmpresa,
    ],
  );

  return (
    <Page className={`Empresa ${theme} container`} white>
      <>
        <PageHeader
          title={params.id ? 'Editar cliente' : 'Novo cliente'}
          subtitle={'Preencha os campos para completar o cadastro'}
        />
        <Page content white>
          {loading ? (
            <div className="p-m-5">
              <SpinnerLoading full size="md" />
            </div>
          ) : (
            <div>
              <Timeline values={steps()} ref={timelineRef} />
            </div>
          )}
        </Page>
      </>
    </Page>
  );
};

export default Empresa;
