import { useRef, useState } from 'react';

import { Menu } from 'primereact/menu';
import { useLocation, useParams } from 'react-router';

import AparenciaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/AparenciaAPI/AparenciaAPI';
import CircunferenciaAbdominalAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaAbdominalAPI/CircunferenciaAbdominalAPI';
import CircunferenciaBracoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaBracoAPI/CircunferenciaBracoAPI';
import CircunferenciaCinturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaCinturaAPI/CircunferenciaCinturaAPI';
import CircunferenciaPanturrilhaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaPanturrilhaAPI/CircunferenciaPanturrilhaAPI';
import CircunferenciaQuadrilAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaQuadrilAPI/CircunferenciaQuadrilAPI';
import DorPacienteAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/DorPacienteAPI/DorPacienteAPI';
import GlicemiaCapilarAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/GlicemiaCapilarAPI/GlicemiaCapilarAPI';
import ObservacaoGeralAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/ObservacaoGeralAPI/ObservacaoGeralAPI';
import PerimetroCefalicoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PerimetroCefalicoAPI/PerimetroCefalicoAPI';
import PesoAlturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PesoAlturaAPI/PesoAlturaAPI';
import PressaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PressaoAPI/PressaoAPI';
import PulsoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PulsoAPI/PulsoAPI';
import RespiracaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/RespiracaoAPI/RespiracaoAPI';
import SaturacaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/SaturacaoAPI/SaturacaoAPI';
import TemperaturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/TemperaturaAPI/TemperaturaAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Graficos } from './_components/GraficoDeCrescimento/Graficos';
import { Button } from 'src/components/_UI/Button';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import Aparencia from './Aparencia/Aparencia';
import CircunferenciaAbdominal from './CircunferenciaAbdominal/CircunferenciaAbdominal';
import CircunferenciaBraco from './CircunferenciaBraco/CircunferenciaBraco';
import CircunferenciaCintura from './CircunferenciaCintura/CircunferenciaCintura';
import CircunferenciaPanturrilha from './CircunferenciaPanturrilha/CircunferenciaPanturrilha';
import CircunferenciaQuadril from './CircunferenciaQuadril/CircunferenciaQuadril';
import DorPaciente from './DorPaciente/DorPaciente';
import GlicemiaCapilar from './GlicemiaCapilar/GlicemiaCapilar';
import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import ModalPreAtendimento from './ModalPreAtendimento/ModalPreAtendimento';
import ObservacaoGeral from './ObservacaoGeral/ObservacaoGeral';
import PerimetroCefalico from './PerimetroCefalico/PerimetroCefalico';
import PesoAltura from './PesoAltura/PesoAltura';
import Pressao from './Pressao/Pressao';
import Pulso from './Pulso/Pulso';
import Respiracao from './Respiracao/Respiracao';
import Saturacao from './Saturacao/Saturacao';
import Temperatura from './Temperatura/Temperatura';

import './PreAtendimento.scss';

interface PreAtendimentoProps {
  paciente: any;
}

const tiposPreAtendimento = [
  {
    label: 'Pressão',
    value: 'PRESSAO_ARTERIAL',
    funcionalidade: 'PRE_ATENDIMENTO_PRESSAO',
  },
  {
    label: 'Peso e Altura',
    value: 'PESO_ALTURA',
    funcionalidade: 'PRE_ATENDIMENTO_PESO_ALTURA',
  },
  {
    label: 'Saturação',
    value: 'SATURACAO',
    funcionalidade: 'PRE_ATENDIMENTO_SATURACAO',
  },
  {
    label: 'Circunferência abdominal',
    value: 'CIRCUNFERENCIA_ABDOMINAL',
    funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_ABDOMINAL',
  },
  {
    label: 'Circunferência do quadril',
    value: 'CIRCUNFERENCIA_QUADRIL',
    funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_QUADRIL',
  },
  {
    label: 'Circunferência da panturrilha',
    value: 'CIRCUNFERENCIA_PANTURRILHA',
    funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_PANTURRILHA',
  },
  {
    label: 'Circunferência da cintura',
    value: 'CIRCUNFERENCIA_CINTURA',
    funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_CINTURA',
  },
  {
    label: 'Circunferência do braço',
    value: 'CIRCUNFERENCIA_BRACO',
    funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_BRACO',
  },
  {
    label: 'Escala de dor',
    value: 'DOR_PACIENTE',
    funcionalidade: 'PRE_ATENDIMENTO_DOR',
  },
  {
    label: 'Glicemia capilar',
    value: 'GLICEMIA_CAPILAR',
    funcionalidade: 'PRE_ATENDIMENTO_GLICEMIA',
  },
  {
    label: 'Temperatura',
    value: 'TEMPERATURA',
    funcionalidade: 'PRE_ATENDIMENTO_TEMPERATURA',
  },
  {
    label: 'Aparência',
    value: 'APARENCIA',
    funcionalidade: 'PRE_ATENDIMENTO_APARENCIA',
  },
  { label: 'Pulso', value: 'PULSO', funcionalidade: 'PRE_ATENDIMENTO_PULSO' },
  {
    label: 'Respiração',
    value: 'RESPIRACAO',
    funcionalidade: 'PRE_ATENDIMENTO_RESPIRACAO',
  },
  {
    label: 'Observação geral',
    value: 'OBSERVACAO_GERAL',
    funcionalidade: 'PRE_ATENDIMENTO_OBSERVACAO_GERAL',
  },
  {
    label: 'Perímetro cefálico',
    value: 'PERIMETRO_CEFALICO',
    funcionalidade: 'PRE_ATENDIMENTO_PERIMETRO_CEFALICO',
  },
];

function PreAtendimentos({ paciente }: PreAtendimentoProps) {
  const menuOptions = useRef<Menu>(null);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { atendimentoStatus, campos } = useAtendimento();

  const [preAtendimentoInativacao, setPreAtendimentoInativacao] =
    useState<PreAtendimento | null>(null);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<boolean>(false);
  const [idPreAtendimentoExcluir, setIdPreAtendimentoExcluir] = useState<
    number | null
  >(null);
  const [editPreAtendimento, setEditPreAtendimento] =
    useState<PreAtendimento | null>(null);
  const [updateList, setUpdateList] = useState<number>(0);
  const [selectedTipo, setSelectedTipo] = useState('');

  const modalNovaPressao = useDisclosure({ opened: false });

  const onEdit = (preAtendimento: PreAtendimento) => {
    setEditPreAtendimento(preAtendimento);
    modalNovaPressao.open();
  };

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
  };

  const getRemoverAPI = (selectedTipo: string) => {
    switch (selectedTipo) {
      case 'PRESSAO_ARTERIAL':
        return PressaoAPI.removerPressao;
      case 'PESO_ALTURA':
        return PesoAlturaAPI.removerPesoAltura;
      case 'SATURACAO':
        return SaturacaoAPI.removerSaturacao;
      case 'CIRCUNFERENCIA_ABDOMINAL':
        return CircunferenciaAbdominalAPI.removerCircunferenciaAbdominal;
      case 'CIRCUNFERENCIA_QUADRIL':
        return CircunferenciaQuadrilAPI.removerCircunferenciaQuadril;
      case 'CIRCUNFERENCIA_PANTURRILHA':
        return CircunferenciaPanturrilhaAPI.removerCircunferenciaPanturrilha;
      case 'CIRCUNFERENCIA_CINTURA':
        return CircunferenciaCinturaAPI.removerCircunferenciaCintura;
      case 'CIRCUNFERENCIA_BRACO':
        return CircunferenciaBracoAPI.removerCircunferenciaBraco;
      case 'DOR_PACIENTE':
        return DorPacienteAPI.removerDorPaciente;
      case 'GLICEMIA_CAPILAR':
        return GlicemiaCapilarAPI.removerGlicemiaCapilar;
      case 'TEMPERATURA':
        return TemperaturaAPI.removerTemperatura;
      case 'APARENCIA':
        return AparenciaAPI.removerAparencia;
      case 'PULSO':
        return PulsoAPI.removerPulso;
      case 'RESPIRACAO':
        return RespiracaoAPI.removerRespiracao;
      case 'OBSERVACAO_GERAL':
        return ObservacaoGeralAPI.removerObservacaoGeral;
      case 'PERIMETRO_CEFALICO':
        return PerimetroCefalicoAPI.removerPerimetroCefalico;
      // case 'CID':
      //   return CidAPI.removerCid;
      default:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }
  };

  const onExcluir = async () => {
    const removerPreAtendimento = getRemoverAPI(selectedTipo);

    const response = await removerPreAtendimento(
      idPreAtendimentoExcluir as number,
      Number(idAtendimento),
    );
    if (response?.status === 204) {
      reloadList();
      setIdPreAtendimentoExcluir(null);
    }
  };

  const onAdd = (tipo: string) => {
    modalNovaPressao.open();
    setSelectedTipo(tipo);
  };

  const options = [
    {
      label: 'Pressão',
      command: () => onAdd('PRESSAO_ARTERIAL'),
      funcionalidade: 'PRE_ATENDIMENTO_PRESSAO',
    },
    {
      label: 'Peso e Altura',
      command: () => onAdd('PESO_ALTURA'),
      funcionalidade: 'PRE_ATENDIMENTO_PESO_ALTURA',
    },
    {
      label: 'Saturação',
      command: () => onAdd('SATURACAO'),
      funcionalidade: 'PRE_ATENDIMENTO_SATURACAO',
    },
    {
      label: 'Circunferência abdominal',
      command: () => onAdd('CIRCUNFERENCIA_ABDOMINAL'),
      funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_ABDOMINAL',
    },
    {
      label: 'Circunferência do quadril',
      command: () => onAdd('CIRCUNFERENCIA_QUADRIL'),
      funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_QUADRIL',
    },
    {
      label: 'Circunferência da panturrilha',
      command: () => onAdd('CIRCUNFERENCIA_PANTURRILHA'),
      funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_PANTURRILHA',
    },
    {
      label: 'Circunferência da cintura',
      command: () => onAdd('CIRCUNFERENCIA_CINTURA'),
      funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_CINTURA',
    },
    {
      label: 'Circunferência do braço',
      command: () => onAdd('CIRCUNFERENCIA_BRACO'),
      funcionalidade: 'PRE_ATENDIMENTO_CIRCUNFERENCIA_BRACO',
    },
    {
      label: 'Escala de dor',
      command: () => onAdd('DOR_PACIENTE'),
      funcionalidade: 'PRE_ATENDIMENTO_DOR',
    },
    {
      label: 'Glicemia capilar',
      command: () => onAdd('GLICEMIA_CAPILAR'),
      funcionalidade: 'PRE_ATENDIMENTO_GLICEMIA',
    },
    {
      label: 'Temperatura',
      command: () => onAdd('TEMPERATURA'),
      funcionalidade: 'PRE_ATENDIMENTO_TEMPERATURA',
    },
    {
      label: 'Aparência',
      command: () => onAdd('APARENCIA'),
      funcionalidade: 'PRE_ATENDIMENTO_APARENCIA',
    },
    {
      label: 'Pulso',
      command: () => onAdd('PULSO'),
      funcionalidade: 'PRE_ATENDIMENTO_PULSO',
    },
    {
      label: 'Respiração',
      command: () => onAdd('RESPIRACAO'),
      funcionalidade: 'PRE_ATENDIMENTO_RESPIRACAO',
    },
    {
      label: 'Observação geral',
      command: () => onAdd('OBSERVACAO_GERAL'),
      funcionalidade: 'PRE_ATENDIMENTO_OBSERVACAO_GERAL',
    },
    {
      label: 'Perímetro cefálico',
      command: () => onAdd('PERIMETRO_CEFALICO'),
      funcionalidade: 'PRE_ATENDIMENTO_PERIMETRO_CEFALICO',
    },
  ];

  const optionsFiltered = options.filter(option => {
    const campoTab = campos?.find(
      campo => campo.funcionalidade === option.funcionalidade,
    );
    return campoTab?.exibicaoCamposModelo !== 'OCULTO';
  });

  const tiposPreAtendimentoFiltered = tiposPreAtendimento.filter(
    tipoPreAtendimento => {
      const campoTab = campos?.find(
        campo => campo.funcionalidade === tipoPreAtendimento.funcionalidade,
      );
      return campoTab?.exibicaoCamposModelo !== 'OCULTO';
    },
  );

  const onVisualizarJustificativa = (preAtendimento: PreAtendimento) => {
    setPreAtendimentoInativacao(preAtendimento);
    setVisualizarJustificativa(true);
  };

  const optionsMenu = (preAtendimento: any) => (
    <PanelOptionsMenu
      ativo={preAtendimento.status === 'ATIVO'}
      idAtendimentoRegistro={preAtendimento.idAtendimento}
      idUsuarioCriacao={preAtendimento.usuarioAtualizacao.id}
      onAlterarLembrete={() => null}
      onEdit={() => onEdit(preAtendimento)}
      onInativar={() => setPreAtendimentoInativacao(preAtendimento)}
      onExcluir={() => setIdPreAtendimentoExcluir(preAtendimento.id)}
      onVisualizarJustificativa={() =>
        onVisualizarJustificativa(preAtendimento)
      }
      hideLembreteOption
      showEditOnlyAtendimentoCorrente
    />
  );

  const renderPreAtendimento = () => {
    switch (selectedTipo) {
      case 'PRESSAO_ARTERIAL':
        return (
          <Pressao
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'PESO_ALTURA':
        return (
          <PesoAltura
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'SATURACAO':
        return (
          <Saturacao
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'CIRCUNFERENCIA_ABDOMINAL':
        return (
          <CircunferenciaAbdominal
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'CIRCUNFERENCIA_QUADRIL':
        return (
          <CircunferenciaQuadril
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'CIRCUNFERENCIA_PANTURRILHA':
        return (
          <CircunferenciaPanturrilha
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'CIRCUNFERENCIA_CINTURA':
        return (
          <CircunferenciaCintura
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'CIRCUNFERENCIA_BRACO':
        return (
          <CircunferenciaBraco
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'DOR_PACIENTE':
        return (
          <DorPaciente
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'GLICEMIA_CAPILAR':
        return (
          <GlicemiaCapilar
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'TEMPERATURA':
        return (
          <Temperatura
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'APARENCIA':
        return (
          <Aparencia
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'PULSO':
        return (
          <Pulso
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'RESPIRACAO':
        return (
          <Respiracao
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );
      case 'PERIMETRO_CEFALICO':
        return (
          <PerimetroCefalico
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      case 'OBSERVACAO_GERAL':
        return (
          <ObservacaoGeral
            panelHeaderOptionsMenu={optionsMenu}
            updateList={updateList}
            selectedTipo={selectedTipo}
          />
        );

      default:
        return null;
    }
  };

  const graficoCrescimento = useDisclosure({ opened: false });

  return (
    <div id="preAtendimentos-container">
      <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center">
        <SimpleText
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Pré Atendimento
        </SimpleText>

        <Menu
          model={optionsFiltered.sort((a, b) => a.label.localeCompare(b.label))}
          popup
          ref={menuOptions}
        />

        <Button
          type="button"
          btnType="tonal"
          label="Adicionar"
          icon="fas fa-plus"
          onClick={event => menuOptions.current?.toggle(event)}
          disabled={
            atendimentoStatus !== 'ATENDENDO' &&
            atendimentoStatus !== 'ATENDENDO_TRIAGEM'
          }
        />
      </div>

      <div className="p-d-flex p-ai-center p-mt-2 p-mb-3">
        <Dropdown
          className="p-col-12 p-sm-6 p-md-4 p-px-0 p-mr-3"
          value={selectedTipo}
          options={tiposPreAtendimentoFiltered.sort((a, b) =>
            a.label.localeCompare(b.label),
          )}
          onChange={(e: any) => setSelectedTipo(e.value)}
          filter={false}
          placeholder="Selecione"
        />

        {(selectedTipo === 'PERIMETRO_CEFALICO' ||
          selectedTipo === 'PESO_ALTURA') && (
          <Button
            onClick={() => graficoCrescimento.open()}
            icon="fas fa-chart-column"
            btnType="gray"
          />
        )}
      </div>

      {renderPreAtendimento()}

      {modalNovaPressao.isOpen && (
        <ModalPreAtendimento
          editPreAtendimento={editPreAtendimento}
          selectedTipo={selectedTipo}
          onSuccess={reloadList}
          {...modalNovaPressao}
          close={() => [modalNovaPressao.close(), setEditPreAtendimento(null)]}
          paciente={paciente}
        />
      )}

      <ModalJustificativa
        preAtendimento={preAtendimentoInativacao}
        selectedTipo={selectedTipo}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setPreAtendimentoInativacao(null);
          setVisualizarJustificativa(false);
        }}
        onSuccess={reloadList}
      />

      <ConfirmDialog
        visible={!!idPreAtendimentoExcluir}
        header="Excluir registro"
        text="O registro será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdPreAtendimentoExcluir(null)}
        onConfirm={onExcluir}
      />

      {graficoCrescimento.isOpen && (
        <Graficos initial={selectedTipo} {...graficoCrescimento} />
      )}
    </div>
  );
}

export default PreAtendimentos;
