import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldMedicoCuidadorProps = DropdownProps & {
  idConsultorio: number;
  name?: string;
  label: string;
  deleteMedicoFromOptions?: number;
};

export const FieldMedicoCuidador = ({
  idConsultorio,
  name = 'medicoCuidador',
  label,
  deleteMedicoFromOptions,
  ...rest
}: FieldMedicoCuidadorProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [medicoCuidador, setMedicoCuidador] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const loadMedicoCuidador = useCallback(async () => {
    setLoadingSearch(true);

    const response = await ProfissionalAPI.loadProfissionalSaudeByTipo({
      consultorio: idConsultorio,
      cuidador: true,
      tipoProfissional: 'MEDICO',
    });

    if (!!response?.length) setMedicoCuidador(response);

    setLoadingSearch(false);
  }, [idConsultorio]);

  useEffect(() => {
    loadMedicoCuidador();
  }, [loadMedicoCuidador]);

  const medicoCuidadorOptions = medicoCuidador.flatMap(item => {
    if (deleteMedicoFromOptions && item.id === deleteMedicoFromOptions)
      return [];

    return {
      label: item.nome,
      value: item,
    };
  });

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={medicoCuidadorOptions}
      errorMsg={errors?.[name]?.message}
      customOnChange={rest.onChange ? rest.onChange : () => null}
      labelOverflow="hidden"
      loading={loadingSearch}
      {...rest}
    />
  );
};
