import { useRef, useEffect } from 'react';

import {
  FileUpload as PrimeFileUpload,
  FileUploadProps as PrimeFileUploadProps,
} from 'primereact/fileupload';

import { Button } from '../_UI/Button';
import SimpleText, { FONT_SIZE } from '../Basics/SimpleText/SimpleText';
import './ImageInput.scss';

interface ImageInputProps extends PrimeFileUploadProps {
  onChange: any;
  value: PrimeFileUploadProps;
  errorMsg?: string;
}

const ImageInput = ({
  onChange,
  value,
  errorMsg,
  className,
  ...rest
}: ImageInputProps) => {
  const fileRef = useRef<PrimeFileUpload>(null);

  useEffect(() => {
    onChange(undefined);
    fileRef?.current?.clear();
  }, [onChange]);

  return (
    <div className={`ImageInput ${className}`}>
      <div className={`Button ${value?.name ? 'outline' : 'tonal'}  p-ml-2`}>
        <div className={'p-grid p-mt-1'}>
          <PrimeFileUpload
            ref={fileRef}
            mode="basic"
            accept="image/*"
            customUpload={true}
            chooseOptions={{
              label: 'Escolher arquivo',
              icon: `fas fa-${value?.name ? 'file' : 'plus'}`,
              className: `${
                value?.name ? 'p-button-outlined image-selected-name' : ''
              }`,
            }}
            onSelect={e => onChange(e.files[0])}
            {...rest}
          />

          {value?.name && (
            <Button
              className={'image-input-action-button'}
              btnType={'ghost'}
              label={'Excluir'}
              onClick={() => {
                onChange(undefined);
                fileRef.current?.clear();
              }}
            />
          )}
          {errorMsg && (
            <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
              {errorMsg}
            </SimpleText>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
