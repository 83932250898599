import { useState } from 'react';
import { GruposExamesAPI } from 'src/APIs/ProntuarioAPI/GruposExamesAPI/GruposExamesAPI';

type DeleteProcedimentoType = {
  isDeleting: boolean;
  handleDeleteProcedimento: (
    idProcedimento: number,
    onRemoveItemFromList: () => void,
  ) => void;
};

export const useBuscaRapidaDeleteProcedimento = (): DeleteProcedimentoType => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteProcedimento = async (
    idProcedimento: number,
    onRemoveItemFromList: () => void,
  ) => {
    try {
      setIsDeleting(true);
      await GruposExamesAPI.deleteGrupoExame(idProcedimento);
      onRemoveItemFromList();
    } catch (error) {
      // error
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    isDeleting,
    handleDeleteProcedimento,
  };
};
