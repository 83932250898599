import { useMemo } from 'react';

import { Skeleton } from 'primereact/skeleton';

import useSize from 'src/core/hooks/useSize';

function ProximosPacientesSkeleton() {
  const { windowInnerWidth } = useSize();

  const skeletonsLength = useMemo(() => (
    Math.floor((windowInnerWidth - 64) / 208)
  ), [windowInnerWidth]);

  return (
    <>
      {Array.from({ length: skeletonsLength }).map((item, index) => (
        <Skeleton
          key={index}
          width="200px"
          height="305px"
          borderRadius="8px"
        />
      ))}
    </>
  );
}

export default ProximosPacientesSkeleton;