import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  data: any;
  edit: JSX.Element;
  onEdit: (data: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { data, edit, onEdit } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(data),
      checkPermission: 'ADM_USUARIOS_SIMULTANEOS_ALTERAR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data?.nome}</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Login
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>{data?.login}</SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Qtd. de sessões
        </SimpleText>
        <br />

        {edit}
      </div>
    </SearchListMobileRow>
  );
};
