import { useCallback, useMemo, useState } from 'react';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { validationSchema } from './utils';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import { FieldEspecialidade } from 'src/components/Fields/FieldEspecialidade/FieldEspecialidade';
import { FieldServicoTuss } from 'src/components/Fields/FieldServicoTuss/FieldServicoTuss';
import { FieldTiposPedidoCuidado } from 'src/components/Fields/FieldTiposPedidoCuidado/FieldTiposPedidoCuidado';
import FormInput from 'src/components/FormInput/FormInput';

import './ModalAddAtividade.scss';

type IState = {
  item?: any;
  idx?: number;
  update?: (idx: number, v: any) => void;
};

export const ModalAddAtividade = ({
  isOpen,
  close,
  state = {},
}: DisclosureType<IState>) => {
  const { setValue, watch } = useFormContext();

  const planosValue = watch('planos');
  const convenio: ConvenioDTO = watch('convenio');
  const diasVigencia = watch('diasVigencia') || 0;

  const defaultValues = useMemo(() => {
    const items = state?.item || {};

    return {
      atividade: 'CONSULTA',
      ...items,
      execucao: items?.execucao ?? '',
    };
  }, [state]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(diasVigencia)),
  });

  const typeOfAtividade = form.watch('atividade');

  const renderFormContent: { [key: string]: JSX.Element } = {
    CONSULTA: <FormContentConsulta />,
    PEDIDO_CUIDADO: <FormContentPedido />,
    EXAMES: <FormContentExames isEditing={!!state?.item} convenio={convenio} />,
  };

  const onSubmit = useCallback(
    (values: any) => {
      if (!!state?.item && state.update) {
        state.update(state.idx!, values);
        return close();
      }

      if (values.atividade === 'CONSULTA') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            idEspecialidade: values.especialidade?.id,
            especialidade: values.especialidade,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
            resumoClinico: values.resumoClinico,
            hipoteseDiagnostica: values.hipoteseDiagnostica,
            questionamento: values.questionamento,
          },
        ]);
      }
      if (values.atividade === 'PEDIDO_CUIDADO') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            nome: values.nomeCuidado,
            idTipoCuidado: values.tipo?.id,
            tipo: values.tipo,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
          },
        ]);
      }
      if (values.atividade === 'EXAMES') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            idServicoTuss: values.servicoTuss?.id,
            servicoTuss: values.servicoTuss,
            quantidade: values.quantidade,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
          },
        ]);
      }

      return close();
    },
    [close, planosValue, setValue, state],
  );

  const isEditing = !!state?.item;

  return (
    <Dialog
      id="modal-novo-plano"
      onHide={close}
      visible={isOpen}
      header={isEditing ? 'Editar atividade' : 'Adicionar atividade'}
      modal={false}
      maximizedMobileSize
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="p-d-flex p-flex-column p-gap-3"
        >
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Selecione a atividade que deseja inserir para o convenio{' '}
            <strong>{convenio?.nomeFantasia || convenio.razaoSocial}</strong>.
          </SimpleText>

          <DropdownControlled
            control={form.control}
            name="atividade"
            label="Atividade"
            filter={false}
            options={[
              {
                label: 'Consulta',
                value: 'CONSULTA',
              },
              {
                label: 'Cuidados',
                value: 'PEDIDO_CUIDADO',
              },
              {
                label: 'Exames',
                value: 'EXAMES',
              },
            ]}
          />

          <div className="border-container">
            {renderFormContent[typeOfAtividade]}
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              type="button"
              label="Cancelar"
              btnType="ghost"
              stretch
              onClick={() => close()}
              disabled={form.formState.isSubmitting}
            />
            <Button
              type="submit"
              label="Salvar"
              stretch
              loading={form.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

const FormContentConsulta = () => {
  const form = useFormContext();

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FieldEspecialidade
        name="especialidade"
        label="Especialidade"
        placeholder="Selecione uma especialidade"
        className="p-p-0"
        hideTextErrorSpace
      />
      <FormInput
        name="recorrencia"
        label="Recorrência"
        sublabel="(dias)"
        type="number"
        min={1}
        hideTextErrorSpace
      />
      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
        maxLengthSpan
        maxLength={2500}
      />
      <TextareaInputControlled
        control={form.control}
        name="resumoClinico"
        label="Resumo clínico/Resultado de exame (s) e procedimentos realizados"
        maxLengthSpan
        maxLength={4000}
      />
      <TextareaInputControlled
        control={form.control}
        name="hipoteseDiagnostica"
        label="Hipótese diagnóstica"
        maxLengthSpan
        maxLength={4000}
      />
      <TextareaInputControlled
        control={form.control}
        name="questionamento"
        label="Questionamento"
        maxLengthSpan
        maxLength={4000}
      />
    </div>
  );
};

const FormContentPedido = () => {
  const form = useFormContext();

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FormInput
        name="nomeCuidado"
        label="Nome do cuidado"
        hideTextErrorSpace
      />
      <FieldTiposPedidoCuidado
        label="Tipo de cuidado"
        name="tipo"
        hideTextErrorSpace
        className="p-p-0"
      />
      <FormInput
        name="recorrencia"
        label="Recorrência"
        sublabel="(dias)"
        type="number"
        min={1}
        hideTextErrorSpace
      />
      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
        maxLengthSpan
        maxLength={2500}
      />
    </div>
  );
};

const FormContentExames = ({
  isEditing,
  convenio,
}: {
  isEditing: boolean;
  convenio: ConvenioDTO;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useFormContext();

  const servicoTussId = form.watch('servicoTuss')?.id;

  useEffectSkipFirst(() => {
    if (servicoTussId && !isEditing) {
      setIsLoading(true);

      ServicoTussCRUDAPI.loadServicoTussById(servicoTussId, {
        throwError: true,
      })
        .then(d =>
          form.setValue('quantidade', d.quantidadePadrao || 1, {
            shouldValidate: true,
          }),
        )
        .finally(() => setIsLoading(false));
    }
  }, [servicoTussId, isEditing]);

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FieldServicoTuss
        name="servicoTuss"
        label="Nome do exame"
        returnType="all"
        className="p-w-100"
        params={useMemo(() => ({ idConvenio: convenio?.id }), [convenio?.id])}
      />

      <FormInput
        name="quantidade"
        label="Quantidade"
        type="number"
        hideTextErrorSpace
        className="p-w-100"
        disabled={isLoading}
        icon={isLoading ? 'pi pi-spin pi-spinner' : ''}
      />
      <FormInput
        name="recorrencia"
        label="Recorrência"
        sublabel="(dias)"
        type="number"
        min={1}
        hideTextErrorSpace
      />
      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
        maxLengthSpan
        maxLength={2500}
      />
    </div>
  );
};
