import { Control, Controller } from 'react-hook-form';

import SelectButton, {
  SelectButtonProps,
} from '../../SelectButton/SelectButton';

export interface SelectButtonControlledProps extends SelectButtonProps {
  control: Control<any>;
  name: string;
}

const SelectButtonControlled = ({
  name,
  control,
  ...rest
}: SelectButtonControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }: any) => (
        <SelectButton
          {...rest}
          value={value}
          onChange={onChange}
          errorMsg={error?.message}
        />
      )}
    />
  );
};

export default SelectButtonControlled;
