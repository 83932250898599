import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
//import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';
//import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

interface AuxiliarsType {
  name: string;
  loader(): any;
}
export const LOAD_AUXILIARS_ACTION = 'auxiliars/load/all';
export const loadAuxiliar: any = createAsyncThunk(
  LOAD_AUXILIARS_ACTION,
  async (
    payload,
    { rejectWithValue, getState }: any,
  ): Promise<Partial<Auxiliars>> => {
    try {
      const preLoaded = Object.keys(getState().auxiliars);
      const auxiliars: AuxiliarsType[] = [
        //{ name: 'empresas', loader: EmpresasAPI.GetAll },
        //{ name: 'perfis', loader: PerfisAPI.GetAll },
        { name: 'generos', loader: UtilsAPI.GetGeneros },
      ];
      const toBeLoaded: AuxiliarsType[] = [];
      auxiliars.forEach(v => {
        if (!preLoaded.includes(v.name)) {
          toBeLoaded.push(v);
        }
      });
      if (toBeLoaded.length < 1) {
        return rejectWithValue({});
      }
      const callResults: PromiseSettledResult<any>[] = await Promise.allSettled(
        toBeLoaded.map(v => v.loader()),
      );
      const result: { [x: string]: any } = {};
      callResults.forEach((v: PromiseSettledResult<any>, i) => {
        if (v.status === 'fulfilled' && toBeLoaded[i] && toBeLoaded[i]?.name) {
          result[toBeLoaded[i]?.name || 'error'] = v.value;
        }
      });
      return result;
    } catch (e) {
      console.log('Error loading Usuarios auxiliars: ', e);
      return rejectWithValue({});
    }
  },
);

export interface UserType {
  label: string;
  value: string;
  img?: string;
  acronym?: string;
  name: string;
}
export interface Auxiliars {
  tiposUsuario: UserType[];
  generos: Partial<Genero>[];
}

const initialTiposUsuario: UserType[] = [
  {
    label: 'Secretariado',
    value: 'SECRETARIA',
    //img: '/perfil_secretaria.png',
    acronym: 'Se',
    name: 'secretariado',
  },
  {
    label: 'Profissional de saúde',
    value: 'PROFISSIONAL_SAUDE',
    //img: '/perfil_secretaria.png',
    acronym: 'Ps',
    name: 'medicina',
  },
  {
    label: 'Administrador',
    value: 'ADMINISTRADOR',
    //img: '/perfil_secretaria.png',
    acronym: 'Ad',
    name: 'enfermagem',
  },
  {
    label: 'Master',
    value: 'MASTER',
    //img: '/perfil_secretaria.png',
    acronym: 'Ma',
    name: 'master',
  },
];

type stateTypeAux = Partial<Auxiliars>;
type actionStypeAux = PayloadAction<Partial<Auxiliars>>;

const initialState: Partial<Auxiliars> = {
  tiposUsuario: initialTiposUsuario,
};

export const auxiliarsSlice = createSlice({
  name: 'auxiliars',
  initialState,
  reducers: {
    setAuxiliars: (state: stateTypeAux, action: actionStypeAux) => {
      return { ...state, ...action.payload };
    },
    clearAuxiliars: (state: stateTypeAux) => {
      return { tiposUsuario: initialTiposUsuario };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadAuxiliar.fulfilled,
      (state: stateTypeAux, action: actionStypeAux) => {
        return { ...state, ...action.payload };
      },
    );
  },
});

export const { setAuxiliars, clearAuxiliars } = auxiliarsSlice.actions;

export default auxiliarsSlice.reducer;
