import { DarkTheme } from 'src/core/themes/ThemeContext';
import useTheme from 'src/core/themes/useTheme';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

const ThemeConfigSubMenu = () => {
  const { theme, toogleTheme } = useTheme();
  return (
    <div className="ItemsConfigSubMenu">
      <i className="icon pi pi-moon" />
      <div className="content">
        <SimpleText bold>Modo escuro</SimpleText>
        <SimpleText>Dê um descando para seus olhos.</SimpleText>
      </div>
      <Switch
        checked={theme === DarkTheme ? true : false}
        onChange={() => {
          if (toogleTheme) toogleTheme();
        }}
      />
    </div>
  );
};

export default ThemeConfigSubMenu;
