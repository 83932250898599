import { transformarEmMoeda } from 'src/utils/currency';
import { formatarData } from 'src/utils/date';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface MobileRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onHistorico: (data: any) => void;
  onSwitchStatus: (data: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { data, onEdit, onDelete, onHistorico, onSwitchStatus } = props;

  const options = [
    {
      label: 'Historico',
      command: () => onHistorico(data),
    },
    {
      label: 'Editar',
      command: () => onEdit(data),
      checkPermission: 'ADM_SERVICOS_FATURAMENTO_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(data),
      checkPermission: 'ADM_SERVICOS_FATURAMENTO_EXCLUIR',
    },
  ];
  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Código TUSS
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {data?.servicoTuss?.codigo}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Convênio
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {data?.servicoTuss?.convenio?.nomeFantasia ||
            data?.servicoTuss?.convenio?.razaoSocial}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Profissional
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {data?.profissionalSaude?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Valor
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {transformarEmMoeda(data?.valor)}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Início vigência
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS}>
          {formatarData(data?.dataInicioVigencia)}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Status
        </SimpleText>
        <br />

        <Switch
          className="p-mt-2"
          checked={data?.status}
          onChange={onSwitchStatus}
        />
      </div>
    </SearchListMobileRow>
  );
};
