import { useContext, useRef } from 'react';

import {
  Button as PrimeButton,
  ButtonProps as PrimeButtonProps,
} from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import ThemeContext from 'src/core/themes/ThemeContext';

import Can from 'src/components/Can/Can';

import './Button.scss';

export type BtnType =
  | 'pill'
  | 'filled'
  | 'tonal'
  | 'outline'
  | 'ghost'
  | 'icon'
  | 'icon_clean'
  | 'link'
  | 'green-link'
  | 'chip'
  | 'atendido'
  | 'atendendo'
  | 'continuar'
  | 'livre'
  | 'bloqueado'
  | 'triagem'
  | 'triagem_ok'
  | 'prescricao'
  | 'prescricao_ok'
  | 'faltou'
  | 'cancelado'
  | 'negado_operadora'
  | 'danger'
  | 'warn'
  | 'neutral'
  | 'text_button';

export enum BtnTypes {
  FILLED = 'filled',
  PILL = 'pill',
  TONAL = 'tonal',
  OUT = 'outline',
  GHOST = 'ghost',
  ICON = 'icon',
  ICON_CLEAN = 'icon_clean',
  LINK = 'link',
  GREEN_LINK = 'green-link',
  CHIP = 'chip',
  ATENDIDO = 'atendido',
  ATENDENDO = 'atendendo',
  CONTINUAR = 'continuar',
  LIVRE = 'livre',
  BLOQUEADO = 'bloqueado',
  TRIAGEM = 'triagem',
  TRIAGEM_OK = 'triagem_ok',
  PRESCRICAO = 'prescricao',
  PRESCRICAO_EXECUCAO = 'prescricao_ok', 
  PRESCRICAO_FINALIZADA = 'prescricao_ok',
  FALTOU = 'faltou',
  CANCELADO = 'cancelado',
  NEGADO_OPERADORA = 'negado_operadora',
  DANGER = 'danger',
  WARN = 'warn',
  NEUTRAL = 'neutral',
  TEXT_BUTTON = 'text_button',
}
export interface ButtonProps extends Omit<PrimeButtonProps, 'label'> {
  btnType?: BtnType;
  onSubmit?(e: any): any;
  label?: string | undefined;
  overlay?: JSX.Element;
  stretch?: boolean;
  checkPermission?: string | string[];
}

const Button = ({
  btnType = 'filled',
  label = '',
  loading,
  className,
  onClick,
  overlay,
  stretch,
  checkPermission = '',
  ...rest
}: ButtonProps) => {
  const { theme } = useContext(ThemeContext);
  const overlayRef = useRef<OverlayPanel>(null);

  const rounded = btnType === BtnTypes.PILL;
  const outline = btnType === BtnTypes.OUT;
  const icon = btnType === BtnTypes.ICON;
  const icon_clean = btnType === BtnTypes.ICON_CLEAN;
  const link = btnType === BtnTypes.LINK;
  const green_link = btnType === BtnTypes.GREEN_LINK;

  return (
    <Can checkPermission={checkPermission}>
      <div className={`Button ${btnType || ''} ${stretch ? 'stretch' : ''}`}>
        <PrimeButton
          className={`
          ${className || ''}
          ${stretch ? 'stretch' : ''}
          ${theme || ''}
          ${rounded ? 'p-button-rounded p-button-raised' : ''}
          ${outline ? 'p-button-outlined' : ''}
          ${icon ? 'p-button-rounded icon-button' : ''}
          ${icon_clean ? 'p-button-rounded icon-clean' : ''}
          ${link || green_link ? 'p-button-link' : ''}
        `}
          label={label}
          loading={loading ? true : false}
          onClick={e => {
            if (overlay) {
              overlayRef.current?.toggle(e);
            }
            if (onClick && !overlay) {
              onClick(e);
            }
          }}
          {...rest}
        />
        <div className="OverlayPanel">
          <OverlayPanel ref={overlayRef}>{overlay}</OverlayPanel>
        </div>
      </div>
    </Can>
  );
};

export default Button;
