import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import SuporteAPI from 'src/APIs/AdminAPI/SuporteAPI/SuporteAPI';
import * as Yup from 'yup';

import { useUser } from 'src/core/hooks/User/User';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { isValidCPF } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import CustomRadioButton from 'src/components/Basics/CustomRadioButton/CustomRadioButton';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import Sidebar from 'src/components/Sidebar/Sidebar';

import { SupportAssistenteVirtual } from './SupportAssistenteVirtual/SupportAssistenteVirtual';
import { useChatBot } from './useChatBot';

import './Support.scss';
import { useLocation } from 'react-router';

interface SupportProps {
  supportVisible?: boolean;
  interno?: boolean;
  onHideSupport?: any;
}

enum Values {
  POSSUI_SUPORTE_EMED = 'POSSUI_SUPORTE_EMED',
  SUPORTE_TELEFONE = 'SUPORTE_TELEFONE',
  SUPORTE_EMAIL = 'SUPORTE_EMAIL',
  LINK_CHAT_SUPORTE = 'LINK_CHAT_SUPORTE',
  SUPORTE_CHAT_IMAGEM_QUALITY = 'SUPORTE_CHAT_IMAGEM_QUALITY',
  ACESSO_AO_CHAT_SUPORTE_SOMENTE_ADMIN = 'ACESSO_AO_CHAT_SUPORTE_SOMENTE_ADMIN',
  LINK_CENTRAL_AJUDA = 'LINK_CENTRAL_AJUDA',
}

const Support = ({ supportVisible, onHideSupport, interno }: SupportProps) => {
  const [linkChat, setLinkChat] = useState<any>();
  const [suporteChatImage, setSuporteChatImage] = useState<any>();
  const [formState, setFormState] = useState<string>('identityForm');
  const [suporteInfos, setSuporteInfos] = useState<any>();
  const [usuario, setUsuario] = useState<any>();
  const [selectedEmpresa, setSelectedEmpresa] = useState<any>();

  const [suporteEmed, setSuporteEmed] = useState<any>();
  const [suporteTelefone, setSuporteTelefone] = useState<any>();
  const [suporteEmail, setSuporteEmail] = useState<any>();
  const [linkCentralAjuda, setLinkCentralAjuda] = useState<any>();
  const [suporteChatAdmin, setSuporteChatAdmin] = useState<any>();
  const [clientIp, setClientIp] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const { user } = useUser();
  const { handleSelectChat } = useChatBot(
    linkChat?.valor,
    suporteChatImage?.valor,
  );

  const drawerAssistenteVirtual = useDisclosure({ opened: false });

  const validatioSchema = Yup.object().shape({
    cpf: Yup.string()
      .required('O campo CPF é obrigatório.')
      .test('cpf', 'O CPF é inválido', val => isValidCPF(val || '')),
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  const onSubmitChooseBranchForm = useCallback(
    async (cpfUser?: string, idEmpresa?: number) => {
      const cpf = !!cpfUser ? cpfUser : getValues('cpf');
      const id = !!idEmpresa ? idEmpresa : selectedEmpresa?.idEmpresa;

      if (!cpf || !id) return;

      setLoading(true);
      const response = await SuporteAPI.getSuporteEmpresa(cpf, id);
      setClientIp(response?.data.clientIp);

      if (response.status === 200) {
        response.data?.parametros.forEach((item: any) => {
          item.value = item.valor === 'S' ? true : false;

          switch (item.codigo) {
            case Values.POSSUI_SUPORTE_EMED:
              setSuporteEmed(item);
              break;

            case Values.SUPORTE_TELEFONE:
              setSuporteTelefone(item);
              break;

            case Values.SUPORTE_EMAIL:
              setSuporteEmail(item);
              break;

            case Values.LINK_CHAT_SUPORTE:
              setLinkChat(item);
              break;

            case Values.LINK_CENTRAL_AJUDA:
              setLinkCentralAjuda(item);
              break;

            case Values.SUPORTE_CHAT_IMAGEM_QUALITY:
              setSuporteChatImage(item);
              break;

            case Values.ACESSO_AO_CHAT_SUPORTE_SOMENTE_ADMIN:
              setSuporteChatAdmin(item);
              break;

            default:
              break;
          }
        });

        if (suporteInfos?.length >= 1) {
          setFormState('supportOptions');
        }
      }

      setLoading(false);
    },
    [getValues, selectedEmpresa?.idEmpresa, suporteInfos?.length],
  );

  const onSubmit = async (data: any) => {
    const response = await SuporteAPI.getSuporteCpf(data.cpf);

    if (response?.status === 200) {
      setSuporteInfos(response.data);
      setUsuario(response.data[0]);

      if (response.data.length >= 1) {
        setFormState('chooseHospital');
      } else {
        onSubmitChooseBranchForm();
        setFormState('supportOptions');
      }
    }
  };

  useEffect(() => {
    if (interno) {
      onSubmitChooseBranchForm(user?.documentos?.cpf, user?.idEmpresa);
      setFormState('supportOptions');
    }
  }, [interno, onSubmitChooseBranchForm, user]);

  const renderIdentityForm = useCallback(
    () => (
      <>
        <div className={'p-mt-3'}>
          <SimpleText fontSize={FONT_SIZE.XS}>
            Temos à disposição um time de suporte especializado, apto para
            resolver qualquer dúvida ou problema.
          </SimpleText>
        </div>

        <div className={'p-mt-3'}>
          <SimpleText fontSize={FONT_SIZE.XS}>
            Identifique-se para facilitar a sua solicitação de ajuda.
          </SimpleText>
        </div>
        <div className="p-mt-4 p-mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <MaskedInputControlled
              name="cpf"
              label="CPF"
              mask={MASK.CPF}
              unmask
              placeholder="00.000.000-00"
              control={control}
              errorMsg={errors?.cpf?.message}
            />

            <Button className="p-mt-5" label="Avançar" type="submit" />
          </form>
        </div>

        <Separator />

        <div className={'p-mt-5'}>
          <SimpleText fontSize={FONT_SIZE.XS}>
            Você já conhece nosso FAQ?
          </SimpleText>
        </div>

        <div className={'p-mt-2'}>
          <SimpleText
            bold
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.PRIMARY}
          >
            Acessar autoajuda
          </SimpleText>
        </div>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control, errors?.cpf?.message, onSubmit],
  );

  const renderSupportOptions = useCallback(
    () => (
      <div className={'SupportOptions'}>
        <div className={'p-mt-5'}>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.MD}>
            Olá;
          </SimpleText>
        </div>
        <div className={'p-mt-3 p-d-flex p-ai-center'}>
          <Avatar label={usuario?.nomeUsuario || user?.nome} size="large" />
          <div className={'p-ml-3'}>
            <div>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                className={'p-text-bold'}
                fontSize={FONT_SIZE.XS}
              >
                {usuario?.nomeUsuario || user?.nome}
              </SimpleText>
            </div>
            <div>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Número de IP: {clientIp}
              </SimpleText>
            </div>
          </div>
        </div>
        <div className={'p-mt-4'}>
          <SimpleText
            bold
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
          >
            Se você não consegue entrar
          </SimpleText>
        </div>
        <div className={'p-mt-2'}>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
            Para problemas no login ou usuário entre em contato com o
            administrador regional.
          </SimpleText>
        </div>

        <Separator />

        <div className={'p-mt-4'}>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            className={'p-text-bold'}
            fontSize={FONT_SIZE.XS}
          >
            Outros problemas
          </SimpleText>
        </div>

        {/* CENTRAL */}
        <div
          className="p-mt-3 p-jc-between p-d-flex p-ai-center others-problems-container"
          onClick={() => window.open(linkCentralAjuda?.valor)}
        >
          <div className={'p-d-flex p-ai-center'}>
            <div className={'icon-container p-d-flex p-ai-center'}>
              <i className={'fa fa-robot'} />
            </div>

            <div className={'p-ml-3'}>
              <div>
                <SimpleText
                  bold
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  Central Ajuda
                </SimpleText>
              </div>
              <div>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  Pesquise as dúvidas mais comuns do usuário e saiba mais sobre
                  a plataforma.
                </SimpleText>
              </div>
            </div>
          </div>

          <i className={'pi pi-angle-right icon-color'} />
        </div>

        {/* CHAT */}
        {suporteEmed && (
          <div
            className="p-mt-3 p-jc-between p-d-flex p-ai-center others-problems-container"
            onClick={handleSelectChat}
          >
            <div className={'p-d-flex p-ai-center'}>
              <div className={'icon-container p-d-flex p-ai-center'}>
                <i className={'fa fa-robot'} />
              </div>

              <div className={'p-ml-3'}>
                <div>
                  <SimpleText
                    bold
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}
                  >
                    Via chat
                  </SimpleText>
                </div>
                <div>
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XXS}
                  >
                    Resolva problemas simples com a ajuda do nosso chat.
                  </SimpleText>
                </div>
              </div>
            </div>

            <i className={'pi pi-angle-right icon-color'} />
          </div>
        )}

        {pathname !== '/forgot-password' && (
          <div
            className="p-mt-3 p-jc-between p-d-flex p-ai-center others-problems-container"
            onClick={() => drawerAssistenteVirtual.open()}
          >
            <div className={'p-d-flex p-ai-center'}>
              <div className={'icon-container p-d-flex p-ai-center'}>
                <i className={'fa fa-robot'} />
              </div>

              <div className={'p-ml-3'}>
                <div>
                  <SimpleText
                    bold
                    fontColor={FONT_COLOR.COLOR_16}
                    fontSize={FONT_SIZE.XS}
                  >
                    Assistente Virtual
                  </SimpleText>
                </div>
                <div>
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XXS}
                  >
                    Reveja as assistências e novidades disponíveis no sistema.
                  </SimpleText>
                </div>
              </div>
            </div>

            <i className={'pi pi-angle-right icon-color'} />
          </div>
        )}

        {/* EMAIL */}
        <div
          onClick={() => window.open(`mailto:${suporteEmail?.valor.trim()}`)}
          className="p-mt-3 p-d-flex p-ai-center p-jc-between others-problems-container"
        >
          <div className={'p-d-flex p-ai-center'}>
            <div className={'icon-container p-d-flex p-ai-center'}>
              <i className={'fa fa-envelope'} />
            </div>

            <div className={'p-ml-3'}>
              <div>
                <SimpleText
                  bold
                  fontColor={FONT_COLOR.COLOR_16}
                  fontSize={FONT_SIZE.XS}
                >
                  Via e-mail
                </SimpleText>
              </div>

              <div>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  Para casos sem urgência, opte por enviar um e-mail para
                  {' ' + suporteEmail?.valor}
                </SimpleText>
              </div>
            </div>
          </div>

          <i className={'pi pi-angle-right icon-color'} />
        </div>

        <Separator />

        <div>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
            Em casos urgentes, você pode entrar em contato por telefone:
          </SimpleText>
        </div>

        <div className="p-mt-1">
          <Button
            btnType={'green-link'}
            label={suporteTelefone?.valor}
            onClick={() => {
              window.open(`tel:${suporteTelefone?.valor.trim()}`);
            }}
          />
        </div>
      </div>
    ),
    [
      clientIp,
      drawerAssistenteVirtual,
      handleSelectChat,
      linkCentralAjuda?.valor,
      suporteEmail?.valor,
      suporteEmed,
      suporteTelefone?.valor,
      user?.nome,
      usuario?.nomeUsuario,
    ],
  );

  const renderChooseBranch = useCallback(
    () => (
      <div className={'choose-branch'}>
        <div className={'p-mt-5'}>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.SM}>
            Olá;
          </SimpleText>
        </div>
        <div className={'p-mt-3 p-d-flex p-ai-center'}>
          <Avatar label={usuario?.nomeUsuario} size="large" />
          <div className={'p-ml-3'}>
            <div>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                className={'p-text-bold'}
                fontSize={FONT_SIZE.XS}
              >
                {usuario?.nomeUsuario}
              </SimpleText>
            </div>
          </div>
        </div>
        <div className={'p-mt-4 p-mb-3'}>
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
            Identificamos que você está cadastrado em mais de uma regional.
            Selecione para qual filial você deseja o suporte.
          </SimpleText>
        </div>

        <div className="cards-container">
          {suporteInfos?.map((item: any, index: number) => (
            <CustomRadioButton
              name="empresa"
              key={index}
              checked={item?.idEmpresa === selectedEmpresa?.idEmpresa}
              onClick={() => {
                setSelectedEmpresa(item);
                setUsuario(item);
              }}
              component={
                <div className="p-p-1 p-column p-jc-start p-ai-start">
                  <div className="p-ml-1">
                    <SimpleText
                      bold
                      fontColor={FONT_COLOR.COLOR_16}
                      fontSize={FONT_SIZE.XS}
                    >
                      {item.nomeEmpresa}
                    </SimpleText>
                  </div>

                  <div className="p-mt-1 p-ml-1">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_40}
                      fontSize={FONT_SIZE.XXS}
                    >
                      {item.razaoSocial}
                    </SimpleText>
                  </div>
                </div>
              }
            />
          ))}
        </div>
        <Button
          className="p-mt-5"
          disabled={!selectedEmpresa?.idEmpresa}
          onClick={() => onSubmitChooseBranchForm()}
          label="Continuar"
          type="button"
        />
      </div>
    ),
    [
      selectedEmpresa?.idEmpresa,
      suporteInfos,
      onSubmitChooseBranchForm,
      usuario?.nomeUsuario,
    ],
  );

  const renderStateForm = useCallback(() => {
    switch (formState) {
      case 'supportOptions':
        return renderSupportOptions();
      case 'chooseHospital':
        return renderChooseBranch();
      default:
        return renderIdentityForm();
    }
  }, [formState, renderChooseBranch, renderIdentityForm, renderSupportOptions]);

  return (
    <Sidebar
      className="suport-container"
      visible={supportVisible || false}
      position={'right'}
      loading={loading}
      onHide={() => {
        setFormState('identityForm');
        onHideSupport();
      }}
    >
      <div>
        <SimpleText medium fontSize={FONT_SIZE.LG}>
          Obter suporte
        </SimpleText>

        {renderStateForm()}

        {drawerAssistenteVirtual.isOpen && (
          <SupportAssistenteVirtual
            state={{
              clientIp,
            }}
            {...drawerAssistenteVirtual}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default Support;
