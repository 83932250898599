import React from 'react';

import useSize from 'src/core/hooks/useSize';

import { CardItem } from 'src/components/SearchList/SearchListRoot';

import DesktopRow from './DesktopRow';
import DropdownGrupoPontuacao from './DropdownGrupoPontuacao';
import MobileRow from './MobileRow';

interface RowProps {
  profissional: any;
  pontuacao: any;
  pontuacaoOptions: any[];
}

const Row = ({ profissional, pontuacao, pontuacaoOptions }: RowProps) => {
  const { isMobile } = useSize();

  const grupoPontuacao = (
    <DropdownGrupoPontuacao
      profissional={profissional}
      pontuacao={pontuacao}
      pontuacaoOptions={pontuacaoOptions}
    />
  );

  const commomProps = {
    profissional,
    grupoPontuacao,
    pontuacaoOptions,
  };

  return isMobile ? (
    <MobileRow {...commomProps} />
  ) : (
    <DesktopRow {...commomProps} />
  );
};

export default Row;
