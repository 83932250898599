import React from 'react';

import { useFormContext } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const DefinicaoPedidoCuidado = () => {
  const { control } = useFormContext();

  return (
    <div className="definicao-container p-p-2">
      <TextInputControlled control={control} name="pedidoCuidado" hidden />

      <TextareaInputControlled
        control={control}
        label="Pedido de cuidado"
        name="pedidoCuidado.pedidoCuidado"
        maxLength={4000}
        maxLengthSpanDesc
      />
    </div>
  );
};

export default DefinicaoPedidoCuidado;
