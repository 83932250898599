import { FIELD, Field, FieldType } from 'src/components/Form/Form';

const retrieveFieldInitialByType = (v: FieldType) => {
  switch (v) {
    case FIELD.TEXT_INPUT:
      return '';
    case FIELD.PASS_INPUT:
      return '';
    case FIELD.MASKED_INPUT:
      return '';
    case FIELD.CHECKBOX:
      return false;
    case FIELD.RADIO:
      return null;
    case FIELD.RADIO_IMAGE:
      return null;
    case FIELD.SELECT_BUTTON:
      return null;
    default:
      return undefined;
  }
};

export const generateInitialState = (fields: Field[][]) => {
  const result: any = {};
  fields.forEach((row: Field[]) => {
    row.forEach((f: Field) => {
      if (f.type === FIELD.TEXT) {
        return;
      }
      if (!f.name) {
        return;
      }
      result[f.name] = retrieveFieldInitialByType(f.type);
    });
  });
  return result;
};
