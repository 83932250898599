import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { getRegistroObitoByIdPaciente } from 'src/APIs/ProntuarioAPI/RegistroObito/RegistroObitoAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import { FieldCidsFavoritos } from 'src/components/Fields/FieldCidsFavoritos/FieldCidsFavoritos';
import { FieldParentesco } from 'src/components/Fields/FieldGrauParentesco/FieldGrauParentesco';
import { FieldProfissao } from 'src/components/Fields/FieldProfissao/FieldProfissao';
import FormInput from 'src/components/FormInput/FormInput';

import './ModalAdicionarFamiliaresCreate.scss';

type IProps = DisclosureType & {
  handleAdd: (items: any) => void;
  handleEdit: (items: any) => void;
};

export const ModalAdicionarFamiliaresCreate = ({
  isOpen,
  close,
  handleAdd,
  handleEdit,
  state: paciente,
}: IProps) => {
  const validateSchema = Yup.object({
    nome: Yup.string().required('Campo obrigatório'),
    sexo: Yup.object()
      .required('Sexo é obrigatório')
      .typeError('Sexo é obrigatório'),
    grauParentesco: Yup.object().required('Grau de parentesco é obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      ...paciente,
      sexo: paciente?.sexo,
      grauParentesco:
        paciente?.grauParentesco ||
        (!!paciente && paciente?.idVinculoFamiliar !== null
          ? {
              id: paciente?.idVinculoFamiliar,
              descricao: paciente?.descricaoVinculoFamiliar,
            }
          : undefined),
      dataNascimento: paciente?.dataNascimento
        ? new Date(paciente.dataNascimento)
        : undefined,
      dataObito: paciente?.dataObito ? new Date(paciente.dataObito) : undefined,
      cids: paciente?.cids || [],
    },
  });

  useEffect(() => {
    if (paciente?.idPaciente && !paciente.dataObito)
      getRegistroObitoByIdPaciente(paciente.idPaciente).then(
        data =>
          !!data && [
            form.setValue('cids', [data.cid]),
            form.setValue('dataObito', new Date(data.dataObito)),
            form.setValue('causaObito', data.causaObito),
          ],
      );
  }, [form, paciente]);

  const sexoOptions = [
    { label: 'Masculino', value: { id: 3, descricao: 'MASCULINO' } },
    { label: 'Feminino', value: { id: 1, descricao: 'FEMININO' } },
  ];

  const cids = form.watch('cids');

  const handleRemoveCID = (item: any) => {
    form.setValue(
      'cids',
      cids.filter((cid: any) => item.id !== cid.id),
    );
  };

  const onSubmit = (values: any) => {
    if (paciente) {
      handleEdit(values);
    } else {
      handleAdd(values);
    }
    return close({ state: undefined });
  };

  const handleChangeDataNascimento = (e: any) => {
    if (e.value > watchDataObito) {
      form.setValue('dataObito', null);
    }
  };

  const watchDataObito = form.watch('dataObito');
  const watchDataNascimento = form.watch('dataNascimento');

  return (
    <Dialog
      className="dialog-add-familiares dialog-create-familiares"
      header="Adicionar familiares"
      maximizedMobileSize
      visible={isOpen}
      onHide={() => close({ state: undefined })}
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="dialog-content p-col-12"
        >
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {!!paciente ? 'Editar familiar' : 'Novo familiar'}
          </SimpleText>

          <div className="p-grid p-w-100">
            <FormInput
              name="nome"
              label="Nome completo"
              className="p-col-12 p-md-9"
            />

            <SelectButtonControlled
              className="p-col-12 p-md-3 p-ml-0 p-mb-0"
              control={form.control}
              name="sexo"
              label="Sexo"
              dataKey="id"
              options={sexoOptions}
            />

            <CalendarInputControlled
              name="dataNascimento"
              label="Data de nascimento"
              className="p-col-12 p-md-6"
              control={form.control}
              maxDate={new Date()}
              customOnChange={handleChangeDataNascimento}
            />
            <CalendarInputControlled
              name="dataObito"
              label="Óbito"
              className="p-col-12 p-md-6"
              control={form.control}
              maxDate={new Date()}
              minDate={watchDataNascimento}
            />

            <TextareaInputControlled
              name="causaObito"
              label="Causa Óbito"
              className="p-col-12"
              control={form.control}
              disabled={!watchDataObito}
            />

            <FieldParentesco
              name="grauParentesco"
              label="Grau de parentesco"
              className="p-col-12 p-md-6"
            />

            <FieldProfissao
              name="idOcupacao"
              label="Profissão"
              className="p-col-12 p-md-6"
              returnType="id"
            />

            <TextareaInputControlled
              name="observacoes"
              label="Observações"
              className="p-col-12"
              control={form.control}
            />

            <FieldCidsFavoritos name="cids" withSearch />

            {cids.map((item: any) => (
              <div
                key={item.id}
                className="p-w-100 cid-row p-d-flex p-ai-center p-jc-between p-gap-1"
              >
                <SimpleText>
                  {item?.codigo || '-'} {item?.nome || '-'}
                </SimpleText>
                <Button
                  icon="fa fa-trash"
                  type="button"
                  onClick={() => handleRemoveCID(item)}
                  btnType="gray"
                />
              </div>
            ))}
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              stretch
              label="Cancelar"
              btnType="ghost"
              type="button"
              onClick={() => close({ state: undefined })}
              disabled={!!form.formState.isSubmitting}
            />
            <Button
              loading={!!form.formState.isSubmitting}
              stretch
              type="submit"
              label={!!paciente ? 'Salvar alterações' : 'Adicionar'}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
