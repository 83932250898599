import { useState, useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import AparenciaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/AparenciaAPI/AparenciaAPI';
import CircunferenciaAbdominalAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaAbdominalAPI/CircunferenciaAbdominalAPI';
import CircunferenciaBracoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaBracoAPI/CircunferenciaBracoAPI';
import CircunferenciaCinturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaCinturaAPI/CircunferenciaCinturaAPI';
import CircunferenciaPanturrilhaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaPanturrilhaAPI/CircunferenciaPanturrilhaAPI';
import CircunferenciaQuadrilAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/CircunferenciaQuadrilAPI/CircunferenciaQuadrilAPI';
import DorPacienteAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/DorPacienteAPI/DorPacienteAPI';
import GlicemiaCapilarAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/GlicemiaCapilarAPI/GlicemiaCapilarAPI';
import ObservacaoGeralAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/ObservacaoGeralAPI/ObservacaoGeralAPI';
import PerimetroCefalicoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PerimetroCefalicoAPI/PerimetroCefalicoAPI';
import PesoAlturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PesoAlturaAPI/PesoAlturaAPI';
import PressaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PressaoAPI/PressaoAPI';
import PulsoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/PulsoAPI/PulsoAPI';
import RespiracaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/RespiracaoAPI/RespiracaoAPI';
import SaturacaoAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/SaturacaoAPI/SaturacaoAPI';
import TemperaturaAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/TemperaturaAPI/TemperaturaAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalJustificativa.scss';

interface ModalJustificativaProps {
  preAtendimento: PreAtendimento | null;
  selectedTipo: string;
  visualizarJustificativa: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const validationSchema = Yup.object().shape({
  justificativaInativacao: Yup.string().required(
    'Informe a justificativa da inativação',
  ),
});

function ModalJustificativa({
  preAtendimento,
  selectedTipo,
  visualizarJustificativa,
  onHide,
  onSuccess,
}: ModalJustificativaProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { agenda } = useAppSelector((state: RootState) => state);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset();

    if (preAtendimento?.justificativaInativacao) {
      setValue(
        'justificativaInativacao',
        preAtendimento.justificativaInativacao,
      );
    }
  }, [preAtendimento, reset, setValue]);

  const getInativarAPI = (selectedTipo: string) => {
    switch (selectedTipo) {
      case 'PRESSAO_ARTERIAL':
        return PressaoAPI.inativarPressao;

      case 'SATURACAO':
        return SaturacaoAPI.inativarSaturacao;

      case 'CIRCUNFERENCIA_ABDOMINAL':
        return CircunferenciaAbdominalAPI.inativarCircunferenciaAbdominal;

      case 'CIRCUNFERENCIA_QUADRIL':
        return CircunferenciaQuadrilAPI.inativarCircunferenciaQuadril;

      case 'CIRCUNFERENCIA_PANTURRILHA':
        return CircunferenciaPanturrilhaAPI.inativarCircunferenciaPanturrilha;

      case 'CIRCUNFERENCIA_CINTURA':
        return CircunferenciaCinturaAPI.inativarCircunferenciaCintura;

      case 'CIRCUNFERENCIA_BRACO':
        return CircunferenciaBracoAPI.inativarCircunferenciaBraco;

      case 'DOR_PACIENTE':
        return DorPacienteAPI.inativarDorPaciente;

      case 'GLICEMIA_CAPILAR':
        return GlicemiaCapilarAPI.inativarGlicemiaCapilar;

      case 'TEMPERATURA':
        return TemperaturaAPI.inativarTemperatura;

      case 'APARENCIA':
        return AparenciaAPI.inativarAparencia;

      case 'PULSO':
        return PulsoAPI.inativarPulso;

      case 'RESPIRACAO':
        return RespiracaoAPI.inativarRespiracao;

      case 'OBSERVACAO_GERAL':
        return ObservacaoGeralAPI.inativarObservacaoGeral;

      case 'PERIMETRO_CEFALICO':
        return PerimetroCefalicoAPI.inativarPerimetroCefalico;

      case 'PESO_ALTURA':
        return PesoAlturaAPI.inativarPesoAltura;

      default:
        return null;
    }
  };

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        ...data,
        dataOcorrencia: preAtendimento?.dataOcorrencia,
        idAtendimento,
        idProfissionalSaude: agenda.profissionalAtivo.id,
      };

      const inativarPreAtendimento = getInativarAPI(selectedTipo);

      if (!inativarPreAtendimento) return;

      const response = await inativarPreAtendimento(
        preAtendimento?.id as number,
        Number(idAtendimento),
        payload,
      );

      if (response?.status === 204) {
        onSuccess();
        onHide();
      }
    },
    [
      preAtendimento?.dataOcorrencia,
      preAtendimento?.id,
      idAtendimento,
      agenda.profissionalAtivo.id,
      selectedTipo,
      onSuccess,
      onHide,
    ],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      {visualizarJustificativa ? (
        <Button
          btnType="outline"
          label="Fechar"
          className="p-col-align-center"
          onClick={onHide}
          stretch
        />
      ) : (
        <>
          <Button
            btnType="ghost"
            label="Cancelar"
            onClick={onHide}
            loading={isSubmitting}
            stretch
          />
          <Button
            type="submit"
            label="Salvar"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
            disabled={visualizarJustificativa}
            stretch
          />{' '}
        </>
      )}
    </div>
  );

  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header={
        visualizarJustificativa
          ? 'Inativo'
          : 'Você tem certeza que deseja inativar?'
      }
      visible={!!preAtendimento}
      onHide={onHide}
      footer={renderFooter}
    >
      <form className="p-grid">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          {visualizarJustificativa ? (
            <span>
              Este item no prontuário foi inativo em{' '}
              <b>
                {dayjs(preAtendimento?.dataAtualizacao)
                  .locale('pt-br')
                  .format('DD [de] MMMM [de] YYYY [às] HH:mm')}
              </b>{' '}
              por <b>{preAtendimento?.usuarioAtualizacao.nome}</b>
            </span>
          ) : (
            'Você está inativando a inclusão deste item no prontuário do paciente. Ele continuará ser exibido como uma informação inativa. Para continuar, justifique.'
          )}
        </SimpleText>

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="justificativaInativacao"
          label="Justificativa"
          placeholder="Informe a justificativa"
          errorMsg={errors.justificativaInativacao?.message}
          readOnly={visualizarJustificativa}
        />
      </form>
    </Dialog>
  );
}

export default ModalJustificativa;
