import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  descricao: Yup.string().required('Descrição é obrigatória'),
  convenio: Yup.object().nullable().required('Convênio é obrigatório'),
  guiaTissServicoTussInDtoList: Yup.array()
    .of(
      Yup.object().shape({
        idServicoTuss: Yup.number(),
      }),
    )
    .min(1, 'Serviço Tuss é obrigatório')
    .required('Serviço Tuss é obrigatório'),
});
