import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';
import Tab from 'src/components/Tab/Tab';

import DefinicaoCamposTexto from 'src/pages/Emed/Admin/ProtocoloAtendimento/Form/Definicoes/DefinicaoCamposTexto/DefinicaoCamposTexto';
import DefinicaoImpressos from 'src/pages/Emed/Admin/ProtocoloAtendimento/Form/Definicoes/DefinicaoImpressos/DefinicaoImpressos';
import DefinicaoMotivoAtendimento from 'src/pages/Emed/Admin/ProtocoloAtendimento/Form/Definicoes/DefinicaoMotivoAtendimento/DefinicaoMotivoAtendimento';
import DefinicaoPedidoCuidado from 'src/pages/Emed/Admin/ProtocoloAtendimento/Form/Definicoes/DefinicaoPedidoCuidado/DefinicaoPedidoCuidado';
import DefinicaoSolicitacaoExames from 'src/pages/Emed/Admin/ProtocoloAtendimento/Form/Definicoes/DefinicaoSolicitacaoExame/DefinicaoSolicitacaoExames';

import './ProtocoloAtendimentoDialog.scss';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useMemed } from 'src/core/hooks/Atendimento/useMemed';

import { handleSetPrescricaoItem } from 'src/libs/memed/utils';
import { capitalizeFirstLetter } from 'src/utils/utils';

interface ProtocoloAtendimentoDialogProps extends DisclosureType {
  idAtendimento: number;
  getProntuario: () => void;
}
const ProtocoloAtendimentoDialog = ({
  isOpen,
  close,
  idAtendimento,
  getProntuario,
  ...rest
}: ProtocoloAtendimentoDialogProps) => {
  const { memedStatus, memedImpressos } = useMemed();

  const { protocolosAtendimento } = useAtendimento();
  const [protocoloSelected, setProtocoloSelected] = useState<any>(null);

  const useFormMethods = useForm({});

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useFormMethods;

  const onSubmit = async (data: any) => {
    const { definicao, vinculos, cids, ciaps, ...restProtocolo } =
      protocoloValue;
    const { consultorios, convenios, ...rest } = vinculos;
    const definicoes = data;
    if (definicoes.solicitacaoExames) {
      definicoes.solicitacaoExames = definicoes.solicitacaoExames.map(
        (s: any) => ({
          quantidade: s.quantidade as number,
          idServicoTuss: s.servicoTuss?.id || s.idServicoTuss,
        }),
      );
    }

    const payload = {
      ...restProtocolo,
      definicao: definicoes,
      ...(cids && { cids: cids.map((cid: any) => cid.id) }),
      ...(ciaps && { ciaps: ciaps.map((ciap: any) => ciap.id) }),
      ...(consultorios && {
        consultorios: consultorios.map((consultorio: any) => consultorio.id),
      }),
      ...(convenios && {
        convenios: convenios.map((convenio: any) => convenio.id),
      }),
    };

    try {
      await ProtocoloAtendimentoAPI.aplicarProtocoloAtendimento(
        idAtendimento,
        payload,
      );
      localStorage.setItem('definicoes', JSON.stringify(definicoes));
      getProntuario();
      close();
    } catch (error) {
      console.error(error);
    }
  };

  const itemTemplate = (option: any) => {
    return (
      <div className="protocolo-dialog-dropdown p-d-flex p-jc-between p-ai-center">
        <SimpleText fontColor="color_16">{option?.nome}</SimpleText>
        <div className="p-d-flex p-ai-center p-gap-3">
          <div className="p-d-flex p-gap-1 p-ai-center">
            {option?.cids?.map((cid: any) => (
              <SimpleText className="token" key={cid.id}>
                {cid.codigo}
              </SimpleText>
            ))}
          </div>
          <div className="p-d-flex p-gap-1 p-ai-center">
            {option?.ciaps?.map((ciap: any) => (
              <SimpleText className="token" key={ciap.id}>
                {ciap.codigo}
              </SimpleText>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const valueTemplate = (option: any, props: any) => {
    return (
      <div className="protocolo-dialog-dropdown p-d-flex p-jc-between p-ai-center">
        <SimpleText fontColor="color_16">{props.value?.nome}</SimpleText>
        <div className="p-d-flex p-ai-center p-gap-3">
          <div className="p-d-flex p-gap-1 p-ai-center">
            {props.value?.cids?.map((cid: any) => (
              <SimpleText className="token" key={cid.id}>
                {cid.codigo}
              </SimpleText>
            ))}
          </div>
          <div className="p-d-flex p-gap-1 p-ai-center">
            {props.value?.ciaps?.map((ciap: any) => (
              <SimpleText className="token" key={ciap.id}>
                {ciap.codigo}
              </SimpleText>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const protocoloValue = useMemo(() => {
    return protocolosAtendimento.find(
      protocolo => protocolo.id === protocoloSelected,
    );
  }, [protocoloSelected, protocolosAtendimento]);

  useEffect(() => {
    if (!protocoloValue) return;
    reset({
      ...protocoloValue.definicao,
    });
  }, [protocoloValue, reset]);

  return (
    <Dialog
      id="protocolo-dialog"
      visible={isOpen}
      header="Resumo do protocolo de atendimento"
      onHide={() => close()}
      style={{ width: '700px' }}
      maximizedMobileSize
    >
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleText fontColor="color_60">
            Selecione o protocolo que deseja aplicar ao atendimento{' '}
          </SimpleText>
          <Dropdown
            value={protocoloValue}
            options={protocolosAtendimento}
            onChange={e => setProtocoloSelected(e.value)}
            placeholder="Selecione um protocolo"
            label="Protocolo de atendimento / CID / CIAP"
            itemTemplate={itemTemplate}
            valueTemplate={protocoloValue ? valueTemplate : undefined}
            filterBy="nome"
            optionValue="id"
          />
          <Separator layout="horizontal" />
          <Tab
            clean
            headerColor={false}
            className="p-w-100"
            values={[
              {
                label: 'Campos Texto',
                content: <DefinicaoCamposTexto />,
              },
              {
                label: 'Exames e cirurgias',
                content: <DefinicaoSolicitacaoExames />,
              },
              {
                label: 'Pedido de cuidado',
                content: <DefinicaoPedidoCuidado />,
              },
              {
                label: 'Motivo de atendimento',
                content: <DefinicaoMotivoAtendimento />,
              },
              {
                label: 'Impressos',
                content: <DefinicaoImpressos />,
              },
            ]}
          />
          <div className="p-d-flex p-gap-2">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              loading={isSubmitting}
              stretch
            />
            <Button
              loading={isSubmitting}
              type="submit"
              label="Sim, aplicar o protocolo"
              stretch
              disabled={!protocoloSelected}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ProtocoloAtendimentoDialog;
