import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Button from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ProfessionalsSelected from './ProfessionalsSelected';
import SearchProfessionals from './SearchProfessionals/SearchProfessionals';

import './FormProfessionals.scss';
import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

interface FormProfessionalsProps {
  // ID passado para a tela para buscar todos os profissionais cadastrados
  onSubmit(v: GetProfissionalDTO[]): void;
  values?: GetProfissionalDTO[] | undefined;
}

const FormProfessionals = ({ onSubmit, values }: FormProfessionalsProps) => {
  const [modal, setModal] = useState(false);

  const [value, setValue] = useState<GetProfissionalDTO[]>([]);

  const load = useCallback(async () => {
    if (values && values?.length > 0) {
      const profs = await ProfissionalAPI.loadProfissionaisSaudeBySearch({
        nome: '',
      });

      const professionalsList: any[] = [];

      values.map((v: any) => {
        const findProfessional = profs?.list?.filter(
          (p: any) =>
            p.id === (v.idProfissionalSaude ? v.idProfissionalSaude : v.id),
        );

        const profEncontrado = {
          ...findProfessional[0],
          ...v,
          id: v.idProfissionalSaude
            ? v.idProfissionalSaude
            : findProfessional[0]?.id,
        };

        return professionalsList.push(profEncontrado);
      });

      setValue(professionalsList);
    }
  }, [values]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeItems = useCallback(
    items => {
      setValue(items);
      setModal(false);

      onSubmit(items);
    },
    [onSubmit],
  );

  // Ordena os valores por
  const valueOrdered = useMemo(
    () => value.sort((a, b) => (a.nome < b.nome ? -1 : 1)),
    [value],
  );

  return (
    <div className={'FormProfessionals'}>
      <SimpleText fontSize={FONT_SIZE.XS} bold>
        Equipe da clínica
      </SimpleText>

      <div className="form-container">
        <div>
          <ProfessionalsSelected
            value={valueOrdered}
            onChange={handleChangeItems}
          />

          <Button
            label={value?.length > 0 ? 'Adicionar' : 'Adicionar profissionais'}
            btnType="tonal"
            className="button-submit"
            icon="fas fa-plus"
            onClick={() => setModal(true)}
          />
        </div>
      </div>

      <SearchProfessionals
        visible={modal}
        onHide={() => setModal(false)}
        onSelect={handleChangeItems}
        initialValue={valueOrdered}
      />
    </div>
  );
};

export default memo(FormProfessionals);
