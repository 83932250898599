import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { ComplexidadePacienteAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/ComplexidadePacienteAPI.ts/ComplexidadePacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import FormConsultorios from './FormConsultorios';
import FormPontuacao from './FormPontuacao';
import { validationSchema } from './validationSchema';

import './FormComplexidade.scss';

const FormComplexidade = () => {
  const [pontuacaoInfo, setPontuacaoInfo] = useState({});
  const [editComplexidade, setEditComplexidade] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const { idComplexidade } = useParams();

  const navigate = useNavigate();

  const handleGoBack = useCallback(
    () =>
      navigate('/pacientes/carteira-aps', {
        replace: true,
        state: 'Complexidade do Paciente',
      }),
    [navigate],
  );

  const { user } = useAppSelector((state: RootState) => state);

  const timelineRef = useRef<TimelineImperativeAPI>(null);

  const goToNextStep = () => {
    timelineRef?.current?.next?.();
  };

  const goToPreviousStep = () => {
    timelineRef?.current?.previous?.();
  };

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = useFormMethods;

  useLayoutEffect(() => {
    const getComplexidadeById = async () => {
      try {
        setLoading(true);

        const response: any = await ComplexidadePacienteAPI.getComplexidadeById(
          Number(idComplexidade),
          { throwError: true },
        );

        setEditComplexidade(response);
        setPontuacaoInfo(response);

        reset({
          descricao: response.descricao,
          pontuacao: response.pontuacao,
          consultorios: response.consultoriosDto || [],
        });
      } catch (error) {
        handleGoBack();
      } finally {
        setLoading(false);
      }
    };

    if (idComplexidade) {
      getComplexidadeById();
    }
  }, [idComplexidade, handleGoBack, reset]);

  const requestToAPI = useCallback(
    (data: GetComplexidadePacienteDTO) => {
      if (idComplexidade)
        return ComplexidadePacienteAPI.putComplexidade(
          Number(idComplexidade),
          data,
        );

      return ComplexidadePacienteAPI.postComplexidade(data);
    },
    [idComplexidade],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const response = await requestToAPI(data);

      if (response.status === 200) {
        handleGoBack();
      }
    },
    [handleGoBack, requestToAPI],
  );

  const steps = useMemo(
    (): TimelineValue[] => [
      {
        title: 'Pontuação',
        component: (
          <FormPontuacao
            goToNextStep={goToNextStep}
            complexidade={editComplexidade || {}}
            setPontuacaoInfo={setPontuacaoInfo}
            pontuacaoInfo={pontuacaoInfo}
          />
        ),
      },
      {
        title: 'Consultórios',
        component: (
          <FormConsultorios
            onSubmit={onSubmit}
            goToPreviousStep={goToPreviousStep}
            values={pontuacaoInfo}
            editComplexidade={editComplexidade}
            onGoBack={handleGoBack}
          />
        ),
        disabled: Object.keys(pontuacaoInfo).length === 0,
      },
    ],
    [editComplexidade, handleGoBack, onSubmit, pontuacaoInfo],
  );

  const pageTitle = editComplexidade
    ? 'Edição de complexidade do paciente'
    : 'Novo registro de complexidade do paciente';

  const subtitle = `Preencha os campos para ${
    editComplexidade ? 'editar' : 'cadastrar'
  } a complexidade do paciente`;

  if (loading) {
    return (
      <Page id="form-complexidade-container">
        <>
          <PageHeader title={pageTitle} subtitle={subtitle} />
          <GridListLoading />
        </>
      </Page>
    );
  }

  return (
    <Page id="form-complexidade-container">
      <>
        <PageHeader title={pageTitle} subtitle={subtitle} />

        {!user?.idEmpresa ? (
          <div className="p-px-5">
            <AlertBox
              visible={true}
              text="Você precisa selecionar um Cliente para esta funcionalidade!"
            />
          </div>
        ) : (
          <Page content white>
            <FormProvider {...useFormMethods}>
              <Button
                className="btn-voltar p-mb-2"
                btnType="gray"
                id="back-button"
                label="Cancelar e voltar para complexidade"
                icon="fa fa-arrow-left"
                onClick={handleGoBack}
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Timeline values={steps} loading={false} ref={timelineRef} />
              </form>
            </FormProvider>
          </Page>
        )}
      </>
    </Page>
  );
};

export default FormComplexidade;
