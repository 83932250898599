import Avatar from 'src/components/Avatar/Avatar';

import './ResponsableListItem.scss';
import IconButton from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface ResponsableListItemProps {
  paciente: any;
  isList: boolean;
  onClick?: () => void;
  handleRemove?: () => void;
}

const ResponsableListItem = ({
  paciente,
  onClick,
  handleRemove,
  isList,
}: ResponsableListItemProps) => {
  return (
    <div className="responsableItems" onClick={onClick}>
      <div className="p-grid p-col-12 p-ai-center">
        <div className="p-d-flex p-col-6 p-ai-center">
          <Avatar
            label={paciente?.nome}
            image={paciente.urlFoto}
            className="p-col-4"
          />

          <div className="p-d-flex p-flex-column p-gap-1 p-col-8">
            <SimpleText className="p-text-truncate">
              {paciente?.nome}
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {paciente?.idade} anos
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {paciente?.cpf}
            </SimpleText>
          </div>
        </div>

        <SimpleText className="p-col-4">{paciente?.cartaoPlano}</SimpleText>

        <div className="p-col-2 p-d-flex p-jc-center p-ai-center">
          {isList && (
            <IconButton
              icon="fas fa-trash "
              onClick={handleRemove}
              checkPermission="ADM_BLOQUEIO_PACIENTES_EXCLUIR"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsableListItem;
