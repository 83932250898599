import { useRef, useEffect } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { Item } from './Item';
import { ItemContainer } from './ItemContainer';
import { useServicoTussSelect } from './ServicosTussSelectContext';

export interface CustomSelectAddProps {
  panel?: boolean;
  disabled?: boolean;
  renderProcedimento?: (
    isSelected: boolean,
    procedimento: any,
    onSelect: (procedimento: any) => void,
  ) => JSX.Element;
  onAdd: (servicosTussSelected: any[]) => void;
  lengthToSearch?: boolean;
}

export const MultiSelect = (props: CustomSelectAddProps) => {
  const {
    panel = true,
    disabled = false,
    renderProcedimento = renderProcedimentoDefault,
    onAdd,
    lengthToSearch = true,
  } = props;

  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const {
    debouncedValue,
    servicosTuss,
    servicosTussSelected,
    loading,
    searchQuery,
    handleSelectServicoTuss,
    handleChangeSearchInput,
    handleClearSearchInput,
    handleClearServicosTussSelected,
  } = useServicoTussSelect();

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const handleAddProcedimentosSelecteds = () => {
    onAdd(servicosTussSelected);
    handleClearServicosTussSelected();
    handleClearSearchInput();
    searchPanelRef.current?.hide();
  };

  const render = () => {
    if (loading)
      return (
        <div className="p-col-12 p-px-0">
          <GridListLoading />
        </div>
      );

    if (lengthToSearch && debouncedValue.length < 3) {
      return (
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Digite para pesquisar
        </SimpleText>
      );
    }

    if (!servicosTuss.length) {
      return (
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nenhum resultado encontrado
        </SimpleText>
      );
    }

    return servicosTuss.map((servicoTuss: any) => {
      const isSelected = servicosTussSelected.includes(servicoTuss);

      const handleSelect = (e: any) => {
        handleSelectServicoTuss(e.checked, servicoTuss);
      };

      return renderProcedimento(isSelected, servicoTuss, handleSelect);
    });
  };

  const ServicosTussContent = () => (
    <div className="p-grid" ref={searchPanelContainerRef}>
      <div className="solicitacoes-procedimentos-content p-col-12">
        {render()}
      </div>

      <div className="p-col-12">
        <Button
          type="button"
          btnType={BtnTypes.TONAL}
          label="Adicionar Selecionados"
          onClick={handleAddProcedimentosSelecteds}
          disabled={!servicosTussSelected.length}
        />
      </div>
    </div>
  );

  return (
    <>
      <div ref={searchInputContainerRef}>
        <div className="solicitacoes-procedimentos-search TextInputSearch p-w-100">
          <span
            className={`p-input-icon-left ${
              searchQuery ? 'p-input-icon-right' : ''
            }`}
          >
            <i className="pi pi-search" style={{ marginRight: 5 }} />
            <TextInput
              placeholder="Pesquisar"
              value={searchQuery}
              onChange={handleChangeSearchInput}
              onClick={e => searchPanelRef.current?.show(e, null)}
              disabled={disabled}
            />
            {!!searchQuery && (
              <i className="pi pi-times" onClick={handleClearSearchInput} />
            )}
          </span>
        </div>
      </div>

      {panel ? (
        <OverlayPanel
          className="solicitacoes-procedimentos-panel"
          ref={searchPanelRef}
          dismissable={false}
          appendTo="self"
        >
          <ServicosTussContent />
        </OverlayPanel>
      ) : (
        <ServicosTussContent />
      )}
    </>
  );
};

const renderProcedimentoDefault = (
  isSelected: boolean,
  procedimento: any,
  onSelect: (procedimento: any) => void,
) => {
  return (
    <ItemContainer key={procedimento.id}>
      <Item
        isSelected={isSelected}
        procedimento={procedimento}
        onSelect={onSelect}
      />
    </ItemContainer>
  );
};
