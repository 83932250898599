import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  return (
    <div className="p-grid p-m-1">
      <SimpleText
        className="p-col-2"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Nome da equipe
      </SimpleText>

      <SimpleText
        className="p-col-3"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Profissional responsável
      </SimpleText>

      <SimpleText
        className="p-col-2"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Grupo pontuação
      </SimpleText>

      <SimpleText
        className="p-col-2"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Data de inclusão
      </SimpleText>

      <SimpleText
        className="p-grid p-col-3 p-jc-center p-pl-6"
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XXS}
      >
        Ações
      </SimpleText>
    </div>
  );
};
