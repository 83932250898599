import Http, { HttpParams } from 'src/core/http/Http';

export const getTipoMotivoAtendimentoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: `tipos-motivo-atendimento/${id}`,
    hideToast: true,
    ...options,
  });
};

export const getTipoMotivoAtendimento = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetTipoMotivoAtendimentoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: 'tipos-motivo-atendimento/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadTipoMotivoAtendimento = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoMotivoAtendimentoDTO[]> => {
  return Http.get({
    service: 'agenda',
    url: `liberar-prontuario/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendTipoMotivoAtendimento = (
  payload: PostTipoMotivoAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: '/tipos-motivo-atendimento',
    data: payload,
    ...options,
  });
};

export const getBuscaMotivoAtendimento = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoMotivoAtendimentoDTO[]> => {
  return Http.get({
    service: 'agenda',
    url: '/tipos-motivo-atendimento/busca-tipo-motivo-atendimento',
    hideToast: true,
    ...options,
  });
};

export const updateTipoMotivoAtendimento = (
  id: number,
  payload: PostTipoMotivoAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'agenda',
    url: `/tipos-motivo-atendimento/${id}`,
    data: payload,
    ...options,
  });
};

export const removeTipoMotivoAtendimento = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'agenda',
    url: `/tipos-motivo-atendimento/${id}`,
    ...options,
  });
};

const TipoMotivoAtendimentoAPI = {
  getTipoMotivoAtendimento,
  loadTipoMotivoAtendimento,
  sendTipoMotivoAtendimento,
  updateTipoMotivoAtendimento,
  removeTipoMotivoAtendimento,
  getBuscaMotivoAtendimento,
  getTipoMotivoAtendimentoById,
};

export default TipoMotivoAtendimentoAPI;
