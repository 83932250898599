import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './ResponsableListItem.scss';

interface ResponsableListItemProps {
  name: string;
  id: string;
  ocupation: string;
  crm: string;
  avatar: string;
  onClick?: () => void;
}

const ResponsableListItem = ({
  name,
  id,
  ocupation,
  crm,
  avatar,
  onClick,
}: ResponsableListItemProps) => {
  return (
    <div
      key={id}
      className="responsavel-tecnico-option-container"
      onClick={onClick}
    >
      <Avatar label={avatar} className="avatar" />

      <div className={'responsableInfos'}>
        <SimpleText className={'text'} fontSize={FONT_SIZE.XS} bold>
          {name}
        </SimpleText>
        <div className="footer">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            {ocupation}
          </SimpleText>
          {crm && <span>•</span>}
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            {crm}
          </SimpleText>
        </div>
      </div>
    </div>
  );
};

export default ResponsableListItem;
