import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import avatarSemFoto from 'src/assets/imgs/avatar-sem-foto.png';

import Avatar from 'src/components/Avatar/Avatar';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import Switch from 'src/components/Switch/Switch';
import CardOption from 'src/pages/Emed/Pacientes/CentralPacientes/components/CardOption/CardOption';

interface CardPlanoSaudeProps {
  cartao: any;
  onEdit: (cartao: any) => void;
  onSwitchStatus: (status: boolean, idCartao: number) => void;
  onSetCartaoPrincipal: () => void;
}

const CardPlanoSaude = ({
  cartao,
  onEdit,
  onSwitchStatus,
  onSetCartaoPrincipal,
}: CardPlanoSaudeProps) => {
  const menuEllipsis = useRef<TieredMenu>(null);

  const menuItems = [
    {
      label: 'Editar',
      command: () => onEdit(cartao),
    },
  ];

  return (
    <CardOption
      className="p-grid p-gap-1 p-align-center card-plano-saude"
      checked={cartao.principal}
      onChange={() => onSetCartaoPrincipal()}
    >
      <div className="p-col-2 p-d-flex p-ai-center convenio-img">
        <Avatar
          image={cartao.convenio?.urlLogotipo || avatarSemFoto}
          imageAlt="convenio"
          size="large"
        />
      </div>
      <div className="p-col-12 p-sm-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {cartao.convenio?.nomeFantasia || cartao.convenio.razaoSocial}
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16}>
          {cartao.numeroCartao.replace(
            /(\d{1})(\d{3})(\d{12})(\d{1})/,
            '$1 $2 $3 $4',
          )}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          Val. {dayjs(cartao.dataValidadeCartao).format('DD/MM/YYYY')}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          Tipo plano
        </SimpleText>
        <SimpleText>{cartao.tipoPlano?.descricao}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-gap-2 p-ai-center p-jc-end">
        <Switch
          checked={cartao.status}
          onChange={() => onSwitchStatus(cartao.status, cartao.id)}
        />

        <Separator layout="vertical" />

        <IconButton
          btnType={IconBtnTypes.GREY}
          icon="fas fa-ellipsis-h"
          onClick={event => menuEllipsis?.current?.toggle(event)}
        />

        <TieredMenu
          className="options-convenio-paciente"
          ref={menuEllipsis}
          model={menuItems}
          popup
        />
      </div>
    </CardOption>
  );
};

export default CardPlanoSaude;
