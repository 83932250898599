import { useCallback, useState } from 'react';

import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';

import { handleOpenPDF } from 'src/utils/files';

import { Button } from 'src/components/_UI';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import AdvancedSearch from './AdvancedSearch/AdvancedSearch';
import CardRelatorio from './CardRelatorio/CardRelatorio';
import { Header } from './Header';

import './RelatoriosEmail.scss';
import { useAppSelector } from 'src/core/redux/hooks';

const RelatoriosEmail = () => {
  const { ativo } = useAppSelector(state => state.consultorios);

  const [reload, setReload] = useState<number>(0);
  const [advancedSearch, setAdvancedSearch] = useState<any>({});
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const renderRow = (relatorio: any) => <CardRelatorio relatorio={relatorio} />;

  const handleImprimir = useCallback(async () => {
    setLoading(true);
    const payload = {
      nomeBeneficiario: query,
      idConsultorio: ativo?.id,
      ...advancedSearch,
    };

    const response = await RelatoriosAPI.postRelatoriosEmailsPdf(payload);
    handleOpenPDF(response, 'rel_emails');

    setLoading(false);
  }, [advancedSearch, ativo?.id, query]);

  const reloadList = useCallback(() => {
    setReload(currentReload => currentReload + 1);
  }, []);

  const fetchApi = async ({ query, ...rest }: any) => {
    setQuery(query);
    setAdvancedSearch(rest);

    return await RelatoriosAPI.getRelatoriosEmails({
      nomeBeneficiario: query,
      idConsultorio: ativo?.id,
      ...rest,
    });
  };

  return (
    <div id="central-pacientes-content">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
          <SearchList.SearchInput placeholder="Digite nome do beneficiário, médico ou carteirinha" />

          <div className="p-mb-3">
            <AdvancedSearch />
          </div>
        </div>

        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center p-my-3">
            <Button
              type="button"
              onClick={handleImprimir}
              label="Imprimir relatório"
              loading={loading}
              icon="fas fa-print"
              btnType="pill"
            />

            <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
              <SearchList.TotalCount className="p-mr-4" />
            </div>
          </div>

          <SearchList.Header>
            <Header />
          </SearchList.Header>

          <SearchList.InfiniteScroll
            style={{
              overflow: 'initial',
            }}
            renderRow={renderRow}
            reload={reload}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </div>
  );
};

export default RelatoriosEmail;
