import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';

import './AlertBox.scss';

interface AlertBoxProps {
  visible: boolean;
  onHide?: () => void;
  text: string;
  className?: any;
  type?: 'DANGER' | 'WARN' | 'SUCCESS';
  children?: any;
  style?: any;
}

const AlertBox = ({
  visible,
  onHide,
  text,
  type = 'WARN',
  children,
  className,
  style,
}: AlertBoxProps) => {
  return visible ? (
    <div className={'box-alert ' + className + ` ${type}`} style={style}>
      <div>
        <i
          className={`${
            type === 'SUCCESS'
              ? 'fas fa-circle-check'
              : 'fas fa-exclamation-triangle'
          } p-mr-2`}
        />
        <span className="box-alert-text">
          {text}
          {children && children}
        </span>
      </div>
      {onHide && (
        <IconButton
          icon={'pi pi-times'}
          btnType={IconBtnTypes.ICON_ONLY}
          onClick={onHide}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default AlertBox;
