import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';
import './AdvancedSearch.scss';

const statusOptions = [
  {
    label: 'Aceito',
    value: 'ACEITO',
  },
  {
    label: 'Pendente',
    value: 'PENDENTE',
  },
  {
    label: 'Recusado',
    value: 'RECUSADO',
  },
];
const formaEnvioOptions = [
  {
    label: 'E-mail',
    value: 'EMAIL',
  },
  {
    label: 'SMS',
    value: 'SMS',
  },
  {
    label: 'Ambos',
    value: 'AMBOS',
  },
];

const AdvancedSearch = () => {
  const { user, consultorios } = useAppSelector((state: RootState) => state);

  const useFormMethods = useForm();
  const { getValues, watch, reset, control } = useFormMethods;

  const watchdataEnvioInicial = watch('dataEnvioInicial');
  const watchdataAceiteRecusaInicial = watch('dataAceiteRecusaInicial');

  const onSearch = () => {
    const data = getValues();

    const userType =
      user.tipoUsuario === 'SECRETARIO' || user.tipoUsuario === 'ENFERMEIRO';

    const params = {
      ...data,
      idConsultorio: userType ? consultorios?.ativo?.id : undefined,

      dataEnvioInicial: data.dataEnvioInicial
        ? dayjs(data.dataEnvioInicial).format('YYYYMMDD')
        : null,

      dataEnvioFinal: data.dataEnvioFinal
        ? dayjs(data.dataEnvioFinal).format('YYYYMMDD')
        : null,

      dataAceiteRecusaInicial: data.dataAceiteRecusaInicial
        ? dayjs(data.dataAceiteRecusaInicial).format('YYYYMMDD')
        : null,

      dataAceiteRecusaFinal: data.dataAceiteRecusaFinal
        ? dayjs(data.dataAceiteRecusaFinal).format('YYYYMMDD')
        : null,
    };

    return params;
  };

  const onCancel = () => {
    reset({
      dataEnvioInicial: null,
      dataEnvioFinal: null,
      dataAceiteRecusaInicial: null,
      dataAceiteRecusaFinal: null,
      cartaoBeneficiario: null,
      nomeMedico: null,
      formaEnvio: null,
      status: null,
      nomeBeneficiario: null,
    });
  };

  return (
    <SearchListFilter
      isSearch
      btnType={BtnTypes.TONAL}
      onFilter={onSearch}
      onClearFilters={onCancel}
    >
      <FormProvider {...useFormMethods}>
        <form id="advanced-search-container" className="p-grid">
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Pesquise pacientes de forma detalhada, preenchendo um ou mais dos
            campos abaixo.
          </SimpleText>

          <div className="p-col-6">
            <CalendarInputControlled
              control={control}
              name="dataEnvioInicial"
              label="Período de"
              placeholder="dd/mm/aaaa"
              maxDate={new Date()}
              mask="99/99/9999"
              showIcon
            />
          </div>
          <div className="p-col-6">
            <CalendarInputControlled
              control={control}
              name="dataEnvioFinal"
              className="p-mt-4"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              maxDate={new Date()}
              minDate={watchdataEnvioInicial || new Date()}
              mask="99/99/9999"
              showIcon
            />
          </div>

          <div className="p-col-6">
            <CalendarInputControlled
              control={control}
              name="dataAceiteRecusaInicial"
              label="Data e hora do aceite/recusa"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              maxDate={new Date()}
              mask="99/99/9999"
              showIcon
            />
          </div>
          <div className="p-col-6">
            <CalendarInputControlled
              control={control}
              name="dataAceiteRecusaFinal"
              className="p-mt-4"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              maxDate={new Date()}
              minDate={watchdataAceiteRecusaInicial || new Date()}
              mask="99/99/9999"
              showIcon
            />
          </div>

          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="nomeBeneficiario"
              placeholder="Nome do beneficiário"
            />
          </div>

          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="cartaoBeneficiario"
              placeholder="Cartão do beneficiário"
              permitirApenasNumeros
            />
          </div>

          <div className="p-col-12">
            <TextInputControlled
              name="nomeMedico"
              placeholder="Nome do médico"
              control={control}
            />
          </div>

          <div className="p-col-6">
            <DropdownControlled
              control={control}
              name="formaEnvio"
              label="Forma de envio"
              placeholder="Selecione"
              options={formaEnvioOptions}
            />
          </div>
          <div className="p-col-6">
            <DropdownControlled
              control={control}
              name="status"
              label="Status termo de consentimento"
              placeholder="Selecione"
              options={statusOptions}
            />
          </div>
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};

export default AdvancedSearch;
