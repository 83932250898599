import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import ListaEsperaAPI from 'src/APIs/AgendaAPI/ListaEsperaAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { dateToOutFormatString, stringToDate } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import ChipSelectControlled from 'src/components/ChipSelect/ChipSelectControlled';
import Dialog from 'src/components/Dialog/Dialog';
import SearchPaciente from 'src/components/SearchPaciente/SearchPaciente';

const formFields = {
  observacao: '',
  especialidade: '',
  espera: '',
  turno: '',
  dataInicio: '',
  dataFim: '',
  contato: '',
};

type Props = DisclosureType & {
  isUpdate: boolean;
  profissionalAtivo: any;
  especialidades: any[];
  loadPage: () => void;
  paciente: any;
  waitlistItem: any;
  setPaciente: (v: any) => void;
};

export const ModalAddListaEspera = ({
  close,
  isUpdate,
  profissionalAtivo,
  especialidades,
  loadPage,
  paciente,
  waitlistItem,
  setPaciente,
}: Props) => {
  const validationSchema = Yup.object().shape({
    paciente: Yup.object().required('Paciente é obrigatório'),
    especialidade: Yup.object()
      .nullable()
      .required('Especialidade é obrigatório'),
    espera: Yup.object().nullable().required('Tipo da espera é obrigatório'),
    contato: Yup.string()
      .nullable()
      .required('Pessoa que fez contato é obrigatório'),
    // dataInicio: Yup.date().nullable().required('Data inicial é obrigatória'),
    // dataFim: Yup.date().nullable().required('Data final é obrigatória'),
    // turno: Yup.array()
    //   .nullable()
    //   .min(1, 'Selecione ao menos um turno')
    //   .max(1, 'Apenas um turno pode ser selecionado')
    //   .required('Turno é obrigatório'),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (waitlistItem) {
      const dataInicio = waitlistItem.dataPrefInicial
        ? stringToDate(waitlistItem.dataPrefInicial)
        : null;

      const dataFinal = waitlistItem?.dataPrefFinal
        ? stringToDate(waitlistItem.dataPrefFinal)
        : null;

      setValue('paciente', waitlistItem?.paciente);
      setValue('observacao', waitlistItem?.observacao);
      setValue('contato', waitlistItem?.contato);
      setValue('turnos', waitlistItem?.turnos);
      setValue('espera', waitlistItem?.tipoEspera);
      setValue('especialidade', waitlistItem?.especialidade);
      setValue('dataInicio', dataInicio);
      setValue('dataFim', dataFinal);
    }
  }, [setValue, waitlistItem]);

  const handleSave = async (data: any) => {
    const dates = {
      contato: dateToOutFormatString(new Date()),
      inicial: data.dataInicio
        ? dateToOutFormatString(data.dataInicio, true, false)
        : undefined,
      final: data.dataFim
        ? dateToOutFormatString(data.dataFim, false, true)
        : undefined,
    };

    const turnos = data.turnos ?? [];

    const aux = {
      id: waitlistItem?.id,
      idPaciente: paciente.id,
      dataContato: dates.contato,
      dataPrefInicial: dates.inicial,
      dataPrefFinal: dates.final,
      observacao: data.observacao,
      contato: data.contato,
      idMedico: profissionalAtivo.id,
      idEspecialidade: data.especialidade.id,
      idTipoEspera: data.espera.id,
      turnos,
      idUsuarioCadastro: 2,
    };

    let res;

    if (isUpdate) {
      res = await ListaEsperaAPI.updateListaEspera(aux);
    } else {
      res = await ListaEsperaAPI.saveListaEspera(aux);
    }

    loadPage();

    if (res) {
      reset(formFields);
      close();
    }
  };

  const optEspecs = (especialidades || []).map((e: any) => {
    return {
      label: e.nome,
      value: {
        id: e.id,
        nome: e.nome,
      },
    };
  });

  const optEspera = [
    {
      id: 0,
      descricao: 'Primeira Consulta',
      idUsuarioCadastro: null,
    },
    {
      id: 1,
      descricao: 'Seguimento',
      idUsuarioCadastro: null,
    },
  ].map((e: any) => {
    return {
      label: e.descricao,
      value: e,
    };
  });

  return (
    <Dialog
      type="modal"
      onHide={close}
      header={
        (isUpdate ? 'Atualizar' : 'Adicionar') +
        ' na lista de espera Dr. ' +
        profissionalAtivo?.nome
      }
      visible
      style={{ borderRadius: 15, maxWidth: 800 }}
      className="dialog-add-lista-espera"
      maximizedTabletSize
    >
      <div className={'Form'}>
        <form
          className="p-fluid p-formgrid p-grid"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="grid p-col-12 p-md-12">
            <SearchPaciente
              label="Paciente"
              value={watch('paciente')}
              onSelect={(value: GetPacienteDTO | undefined) => {
                setPaciente(value);
                setValue('paciente', value);
              }}
            />
            <div className="invalid-feedback">{errors.paciente?.message}</div>
          </div>

          <div className="p-grid p-w-100">
            <DropdownControlled
              name="especialidade"
              control={control}
              label={'Tipo de especialidade'}
              options={optEspecs}
              placeholder={'Escolha o tipo de especialidade'}
              className="p-col-12 p-sm-6 p-py-1"
            />
            <DropdownControlled
              name="espera"
              control={control}
              label={'Tipo de espera'}
              options={optEspera}
              placeholder={'Escolha o tipo de espera'}
              className="p-col-12 p-sm-6 p-py-1"
            />
          </div>

          <div className="p-col-12 p-mt-1">
            <SimpleText bold>Preferência de data e turno</SimpleText>
          </div>

          <div className="p-w-100 p-grid p-my-1">
            <CalendarInputControlled
              control={control}
              name="dataInicio"
              minDate={new Date()}
              label={'De'}
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              className="p-col-12 p-sm-6 p-py-1"
            />
            <CalendarInputControlled
              control={control}
              name="dataFim"
              minDate={new Date()}
              label={'Até'}
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              className="p-col-12 p-sm-6 p-py-1"
            />
          </div>

          <ChipSelectControlled
            control={control}
            label={'Turno'}
            multipleSelect={true}
            options={[
              { label: 'Manhã', value: 'MANHA' },
              { label: 'Tarde', value: 'TARDE' },
              { label: 'Noite', value: 'NOITE' },
            ]}
            name="turnos"
            className="column p-col-12"
          />

          <SimpleText className={'p-col-12'} bold>
            Informações opcionais
          </SimpleText>

          <div className="grid p-col-12 p-my-1">
            <TextareaInputControlled
              control={control}
              name="observacao"
              rows={3}
              label={'Observações'}
              autoResize={true}
            />
          </div>

          <div className="grid p-col-12 p-field">
            <TextInputControlled
              control={control}
              name={'contato'}
              label="Pessoa que fez contato"
              className=" mb-1"
              errorMsg={errors?.contato?.message}
            />
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              type="button"
              label="Cancelar"
              btnType="ghost"
              onClick={() => close()}
              stretch
            />
            <Button
              type="submit"
              label="Salvar"
              btnType="tonal"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};
