import { useState, useCallback, useEffect } from 'react';

import ServicoTussAPI from 'src/APIs/FinanceiroAPI/ServicoTuss/ServicoTussAPI';

import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';

import useDebounce from 'src/utils/useDebounce';

type Options = {
  label: string;
  value: any;
};

export interface ServicoTussData {
  loadingSearchServicoTuss: boolean;
  servicoTussOptions: Options[];
  handleServicoTussFilter: (query: string) => void;
}

interface ServicoTussProps {
  selected?: string;
}

export const useServicoTuss = ({
  selected = '',
}: ServicoTussProps): ServicoTussData => {
  const { idConvenioEdit } = useManterConvenio();

  const [loadingSearchServicoTuss, setLoadingSearchServicoTuss] =
    useState(false);
  const [servicoTussOptions, setServicoTussOptions] = useState<Options[]>([]);
  const [searchQueryServicoTuss, setSearchQueryServicoTuss] =
    useState(selected);

  const debouncedSearchQueryServicoTuss = useDebounce(
    searchQueryServicoTuss,
    350,
  );

  const handleServicoTussFilter = (query: string) => {
    setLoadingSearchServicoTuss(true);

    setSearchQueryServicoTuss(query);
  };

  const getServicoTuss = useCallback(
    async (query: string) => {
      if (!query) return;

      setLoadingSearchServicoTuss(true);

      const response = await ServicoTussAPI.loadServicoTuss({
        nome: query,
        idConvenio: idConvenioEdit,
        page: 0,
      });

      if (!!response?.list?.length) {
        const servicoTussFormatted = response.list.map((servicoTuss: any) => ({
          label: `${servicoTuss.codigo} - ${servicoTuss.nome}`,
          value: {
            codigo: servicoTuss.codigo,
            nome: servicoTuss.nome,
            id: servicoTuss.id,
          },
        }));

        setServicoTussOptions(servicoTussFormatted);
      }

      setLoadingSearchServicoTuss(false);
    },
    [idConvenioEdit],
  );

  useEffect(() => {
    getServicoTuss(debouncedSearchQueryServicoTuss);
  }, [debouncedSearchQueryServicoTuss, getServicoTuss]);

  return {
    loadingSearchServicoTuss,
    servicoTussOptions,
    handleServicoTussFilter,
  };
};
