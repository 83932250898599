import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { JitsiMeeting } from '@jitsi/react-sdk';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import Draggable from 'react-draggable';
import { useLocation, useParams } from 'react-router';

import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import { useScrollPosition } from '../hooks/useScrollPosition';
import './IFrameTelemedicina.scss';

export const TelemedicinaIFrame = memo(() => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { tabValue, setTeleconsulta, roomName } = useAtendimento();
  const { scrollY } = useScrollPosition();

  const isMainTab = useMemo(() => {
    return tabValue === 0 ? true : false;
  }, [tabValue]);

  const { profissionalAtivo } = useAppSelector(
    (state: RootState) => state.agenda,
  );

  const [hasInvited, setHasInvited] = useState(false);
  const [api, setApi] = useState<IJitsiMeetExternalApi>();

  const nodeRef = useRef(null);

  const draggableElem = document.getElementById('tel-atendimento');

  useEffectSkipFirst(() => {
    if (isMainTab) {
      draggableElem?.style.removeProperty('transform');
    }
  }, [isMainTab]);

  const handleDispensarPaciente = useCallback(async () => {
    if (hasInvited) {
      await TelemedicinaSalaAtendimentoAPI.sendRemoverPacienteSala({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        idAtendimento: idAtendimento!,
      });
    }
  }, [hasInvited, idAtendimento]);

  const handleHabilitarSalaPaciente = useCallback(() => {
    TelemedicinaSalaAtendimentoAPI.sendSalaAtendimento({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      idAtendimento: idAtendimento!,
    });
  }, [idAtendimento]);

  useEffect(() => {
    return () => {
      handleDispensarPaciente();
    };
  }, [handleDispensarPaciente]);

  useEffectSkipFirst(() => {
    // Desabilita o btn de entrada do paciente caso o mesmo já estiver na sala.
    api?.addListener('participantJoined', () => setHasInvited(true));
    // Habilita o btn de entrada do paciente caso o mesmo estiver saído da sala.
    api?.addListener('participantLeft', () => setHasInvited(false));
    // Dispensa paciente e muda o state da Teleconsulta quando o Profissional sair da conferência.
    api?.addListener('videoConferenceLeft', () => {
      handleDispensarPaciente();
    });
  }, [api]);

  return (
    <Draggable ref={nodeRef} disabled={isMainTab}>
      <div
        className={`p-d-flex p-flex-column p-gap-2 ${!isMainTab ? 'PiP' : ''}`}
        id="tel-atendimento"
        style={scrollY}
      >
        <div className="teleconsulta-frame-atendimento">
          {!isMainTab && (
            <span className="p-d-flex p-p-2" style={{ cursor: 'grab' }}>
              <i className="fa-solid fa-arrows-up-down-left-right" />
            </span>
          )}

          <JitsiMeeting
            domain="teleatendimento.appunimed.com"
            roomName={roomName}
            userInfo={{
              email: profissionalAtivo.emailProfissional,
              displayName: profissionalAtivo.nome,
            }}
            onApiReady={externalApi => setApi(externalApi)}
            onReadyToClose={() => setTeleconsulta('FINISHED')}
            spinner={() => <SpinnerLoading full />}
            configOverwrite={{
              height: '100%',
            }}
            interfaceConfigOverwrite={{
              LANG_DETECTION: false,
              VIDEO_LAYOUT_FIT: 'nocrop',
              MOBILE_APP_PROMO: false,
            }}
          />
        </div>

        {isMainTab && (
          <Button
            stretch
            label="Habilitar entrada do paciente"
            onClick={() => handleHabilitarSalaPaciente()}
            disabled={hasInvited}
          />
        )}
      </div>
    </Draggable>
  );
});
