import { useMemo } from 'react';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';
import ModalHistorico from './ModalHistorico/ModalHistorico';

import './Row.scss';

interface RowProps {
  paciente: PacientesCarteiraDTO;
  pacienteSelected: any[];
  onDelete: (id: number) => void;
  onCheckBox: (paciente: PacientesCarteiraDTO) => void;
  onHistory: (id: number) => void;
}

export const Row = (props: RowProps) => {
  const { paciente, pacienteSelected, onDelete, onCheckBox, onHistory } = props;
  const modalHistorico = useDisclosure({ opened: false });
  const { isTablet } = useSize();

  const checked = pacienteSelected?.some(
    selected => selected.id === paciente.id,
  );

  const handleAge = (): string => {
    if (paciente.idadeAnos !== 0) {
      return (
        paciente.idadeAnos + `${paciente.idadeAnos > 1 ? ' anos' : ' ano'}`
      );
    }

    if (paciente.idadeMeses !== 0) {
      return (
        paciente.idadeMeses + `${paciente.idadeMeses > 1 ? ' mêses' : ' mês'}`
      );
    }

    return paciente.idadeDias + `${paciente.idadeDias > 1 ? ' dia' : ' dias'}`;
  };

  const isTransferencia = useMemo((): boolean => {
    if (
      paciente.ultimaMovimentacaoCarteira?.carteiraDestino?.id &&
      paciente.ultimaMovimentacaoCarteira?.carteiraOrigem?.id
    ) {
      return true;
    }

    return false;
  }, [paciente.ultimaMovimentacaoCarteira]);

  const cumonProps = {
    paciente,
    onDelete,
    onHistory,
    onCheckBox,
    checked,
    isTransferencia,
    idade: handleAge(),
  };

  return (
    <div className="novaMovimentacao-row">
      {isTablet ? (
        <MobileRow {...cumonProps} />
      ) : (
        <DesktopRow {...cumonProps} />
      )}
    </div>
  );
};
