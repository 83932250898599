import { useMemo } from 'react';

import { Checkbox, RadioButton } from 'src/components/_UI';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import Divider from 'src/components/Basics/Divider/Divider';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';

import { FormulariosContentHeader } from '../FormulariosContentHeader';

import '../Formularios.scss';

type AvaliacaoFormularioItemHistorico = Omit<
  IRespostaAvaliacaoFormularioDinamicoItem,
  'resposta'
> & {
  resposta: IRespostaAvaliacaoFormularioDinamicoItemResposta[];
};

export const DialogHistoricoContent = ({
  item,
}: {
  item: IAvaliacaoFormularioDinamicoItem;
}) => {
  const itemsMerged = useMemo((): AvaliacaoFormularioItemHistorico[] => {
    const groupeAndMergedAnswers = (item.respostas || []).reduce(
      (acc: any, item) => {
        const {
          idPerguntaFormularioDinamico,
          resposta,
          perguntaFormularioDinamico,
        } = item;

        if (!acc[idPerguntaFormularioDinamico]) {
          // Initialize the object if it doesn't exist
          acc[idPerguntaFormularioDinamico] = {
            ...item,
            resposta: [resposta], // Create an array for 'MULTIPLA_ESCOLHA'
          };
        } else if (
          perguntaFormularioDinamico.tipoResposta === 'MULTIPLA_ESCOLHA'
        ) {
          // If the response type is 'MULTIPLA_ESCOLHA', push the new response to the array
          if (!!resposta) {
            acc[idPerguntaFormularioDinamico].resposta.push(resposta);
          }
        }
        return acc;
      },
      {},
    );
    // Transform the object into an array
    return Object.values(groupeAndMergedAnswers);
  }, [item.respostas]);

  return (
    <div className="FormularioContent p-d-flex p-flex-column p-gap-2 p-w-100">
      <FormulariosContentHeader
        formulario={item.aplicacaoFormularioDinamico.formularioDinamico}
      />

      <div className="p-mt-2 p-d-flex p-flex-column p-gap-2">
        {itemsMerged.map((field, idx) => {
          const questionType: IPerguntaFormularioTiposResposta =
            field.perguntaFormularioDinamico.tipoResposta;

          if (!(field.resposta.filter(Boolean).length || field.textoResposta))
            return null;

          return (
            <div
              className="row content-row white p-p-2 p-flex-column"
              key={idx}
            >
              <div className="p-d-flex p-ai-center">
                <SimpleText className="p-px-2" fontSize="sm" medium>
                  {field.perguntaFormularioDinamico.enunciado}
                </SimpleText>
              </div>

              <Divider layout="horizontal" className="p-my-2" />

              {['TEXTO'].includes(questionType) && (
                <TextareaInput
                  name=""
                  value={field.textoResposta || ''}
                  readOnly
                />
              )}
              {['NUMERO'].includes(questionType) && (
                <InputNumber
                  value={Number(field.textoResposta) || null}
                  className="p-col-12 p-md-6 p-lg-3"
                  readOnly
                />
              )}
              {['DATA'].includes(questionType) && (
                <CalendarInput
                  value={
                    field.textoResposta
                      ? new Date(field.textoResposta)
                      : undefined
                  }
                  showIcon
                  showTime={false}
                  className="p-col-12 p-md-6 p-lg-3 pointer-events-none"
                  dateFormat={
                    field.perguntaFormularioDinamico.formatoData || 'dd/mm/yy'
                  }
                  readOnlyInput
                />
              )}
              {['ESCOLHA'].includes(questionType) && (
                <RadioButton
                  label={field.resposta?.[0]?.descricao || ''}
                  checked
                  disabled
                />
              )}
              {['MULTIPLA_ESCOLHA'].includes(questionType) &&
                !!field.resposta?.length &&
                field.resposta.map(res => (
                  <Checkbox
                    key={res?.id}
                    label={res?.descricao || ''}
                    className="p-p-2"
                    checked
                    readOnly
                  />
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
