import { useState, useEffect, useMemo, useCallback } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import RegistroObitoAPI from 'src/APIs/ProntuarioAPI/RegistroObito/RegistroObitoAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import useDebounce from 'src/utils/useDebounce';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalRegistroObito.scss';
export interface ModalRegistroObitoProps {
  dadosRegistroObito: GetRegistroObito | null;
  idPaciente: number;
  dataNascimento: Date;
  visivel: boolean;
  onSuccess: (novosDadosRegistroObito: GetRegistroObito) => void;
  onHide: () => void;
}

function ModalRegistroObito({
  dadosRegistroObito,
  idPaciente,
  dataNascimento,
  visivel,
  onSuccess,
  onHide,
}: ModalRegistroObitoProps) {
  const [pesquisaCids, setPesquisaCids] = useState<string>('');
  const [carregandoPesquisaCids, setCarregandoPesquisaCids] =
    useState<boolean>(true);
  const [cids, setCids] = useState<Cids[] | []>([]);

  const debouncedPesquisaCids = useDebounce(pesquisaCids, 350);

  const validationSchema = Yup.object().shape({
    dataObito: Yup.date().required('Selecione a data do óbito'),
    causaObito: Yup.string().required('Informe a causa do óbito'),
    registro: Yup.string().required('Informe o registro'),
    idCid: Yup.string().required('Informe o CID'),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getCids = async (query: string) => {
    const response = await UtilsAPI.GetCids({
      nome: query,
      codigo: query,
      page: 0,
    });

    if (!!response?.list?.length) setCids(response.list);

    setCarregandoPesquisaCids(false);
  };

  useEffect(() => {
    getCids(debouncedPesquisaCids || dadosRegistroObito?.cid?.nome);
  }, [dadosRegistroObito?.cid?.nome, debouncedPesquisaCids]);

  useEffect(() => {
    if (dadosRegistroObito && visivel) {
      setValue('dataObito', new Date(dadosRegistroObito.dataObito));
      setValue('causaObito', dadosRegistroObito.causaObito);
      setValue('registro', dadosRegistroObito.registro);
      setValue('idCid', dadosRegistroObito.idCid);
    }
  }, [dadosRegistroObito, visivel, setValue]);

  const cidsOptions = useMemo(
    () =>
      cids.map(cid => ({
        label: `${cid.codigo} - ${cid.nome}`,
        value: cid.id,
      })),
    [cids],
  );

  const onSubmit = async (data: any) => {
    const idCid = Number(data.idCid);

    const payload = {
      ...data,
      idCid,
      idPaciente: idPaciente,
    };

    const response = await (dadosRegistroObito?.id
      ? RegistroObitoAPI.updateRegistroObito(dadosRegistroObito.id, payload)
      : RegistroObitoAPI.postRegistroObito(payload));

    if (response && response.id) {
      onSuccess(response);
    }

    handleHide();
  };

  function handleHide() {
    reset();
    onHide();
  }

  const setCausaObitoIfEmpty = useCallback(() => {
    const [causaObito, idCid] = getValues(['causaObito', 'idCid']);

    if (causaObito) return;

    const cid = cids.find(cid => cid.id === idCid);

    if (cid) setValue('causaObito', cid.nome);
  }, [cids, getValues, setValue]);

  return (
    <Dialog
      id="modal-registro-obtio"
      type="modal"
      header="Registro de Óbito"
      visible={visivel}
      onHide={handleHide}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CalendarInputControlled
          control={control}
          name="dataObito"
          label="Data Óbito"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          minDate={dataNascimento}
          maxDate={new Date()}
          errorMsg={errors.dataObito?.message}
        />

        <TextareaInputControlled
          className="p-mt-3"
          control={control}
          name="causaObito"
          label="Causa Óbito"
          autoResize
          errorMsg={errors.causaObito?.message}
        />

        <TextInputControlled
          className="p-mt-3 p-mb-3"
          control={control}
          name="registro"
          label="Registro"
          errorMsg={errors.registro?.message}
        />

        <DropdownControlled
          control={control}
          name="idCid"
          label="CID"
          placeholder="pesquisar"
          options={cidsOptions}
          onFilter={e => {
            setCarregandoPesquisaCids(true);
            setPesquisaCids(e.filter);
          }}
          carregandoFiltro={carregandoPesquisaCids}
          customOnChange={setCausaObitoIfEmpty}
          errorMsg={errors.idCid?.message}
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            type="button"
            btnType="ghost"
            label="Cancelar"
            onClick={handleHide}
            loading={isSubmitting}
            stretch
          />
          <Button type="submit" label="Salvar" loading={isSubmitting} stretch />
        </div>
      </form>
    </Dialog>
  );
}

export default ModalRegistroObito;
