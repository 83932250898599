import { useCallback, useEffect, useState } from 'react';

import { AvaliacaoFormularioDinamicoAPI } from 'src/APIs/ProntuarioAPI/AvaliacaoFormularioDinamicoAPI/AvaliacaoFormularioDinamicoAPI';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { DialogHistoricoContent } from './DialogHistoricoContent';
import { DialogHistoricoEvolucao } from './DialogHistoricoEvolucao';

type Props = DisclosureType & {
  item: IAvaliacaoFormularioDinamicoItem;
};

export const DialogHistorico = ({
  close,
  item: historicoFormulario,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<IAvaliacaoFormularioDinamicoItem | null>(
    null,
  );

  const evolucaoContent = useDisclosure({ opened: false });

  const fetchAvaliacao = useCallback(async () => {
    try {
      setIsLoading(true);
      const response =
        await AvaliacaoFormularioDinamicoAPI.loadFormularioDinamicoById(
          { id: historicoFormulario.id },
          { throwError: true },
        );
      setItem(response);
    } catch {
      close();
    } finally {
      setIsLoading(false);
    }
  }, [close, historicoFormulario.id]);

  useEffect(() => {
    fetchAvaliacao();
  }, [fetchAvaliacao]);

  return (
    <Dialog
      visible
      onHide={close}
      header={
        <Header
          isInativo={isLoading ? false : !item?.ativo}
          handleEvolucao={() => evolucaoContent.toggle()}
          isEvolucaoVisible={evolucaoContent.isOpen}
        />
      }
      style={{
        width: '1280px',
      }}
    >
      <>
        {isLoading && <LoadingComponent />}
        {!isLoading && (
          <div
            className="row content-row p-flex-column p-p-2 p-my-2"
            style={{
              height: 'calc(100vh - 300px)',
              overflowY: 'auto',
            }}
          >
            {evolucaoContent.isOpen ? (
              <DialogHistoricoEvolucao />
            ) : (
              <DialogHistoricoContent item={item!} />
            )}
          </div>
        )}

        <Button
          label="Fechar"
          btnType="outline"
          stretch
          onClick={() => close()}
        />
      </>
    </Dialog>
  );
};

const Header = ({
  handleEvolucao,
  isEvolucaoVisible,
  isInativo,
}: {
  handleEvolucao: () => void;
  isEvolucaoVisible: boolean;
  isInativo: boolean;
}) => {
  return (
    <div className="p-w-100 p-d-flex p-ai-center p-gap-2">
      {isEvolucaoVisible && (
        <Button
          icon="fas fa-arrow-left"
          btnType="ghost"
          label="Voltar"
          onClick={handleEvolucao}
        />
      )}
      <SimpleText
        medium
        fontSize="md"
        className="p-col-6 p-p-0 p-d-flex p-ai-center p-gap-2 p-flex-column p-flex-md-row"
      >
        <SimpleText>
          Hábitos de vida - {isEvolucaoVisible ? 'Evolução' : 'Histórico'}{' '}
        </SimpleText>
        {isInativo && (
          <div>
            <span className="tag-danger-outline">Inativo</span>
          </div>
        )}
      </SimpleText>
      {!isEvolucaoVisible && !isInativo && (
        <div className="p-d-flex p-jc-end p-col-6 p-py-0 p-pr-4">
          <Button
            icon="fas fa-chart-line"
            btnType="tonal"
            label="Ver evolução"
            onClick={handleEvolucao}
          />
        </div>
      )}
    </div>
  );
};

export const LoadingComponent = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-mb-2">
      <Skeleton width="100%" height="85px" borderRadius="8px" loading />
      <Skeleton width="100%" height="85px" borderRadius="8px" loading />
      <Skeleton width="100%" height="85px" borderRadius="8px" loading />
      <Skeleton width="100%" height="85px" borderRadius="8px" loading />
      <Skeleton width="100%" height="85px" borderRadius="8px" loading />
    </div>
  );
};
