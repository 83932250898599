import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { SolicitacaoExameCirurgiaContext } from 'src/core/contexts/Atendimento/SolicitacaoExameCirurgia';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { groupBy } from 'src/utils/utils';

interface SolicitacaoExameCirurgiaProviderProps {
  children: ReactNode;
}

export function SolicitacaoExameCirurgiaProvider({
  children,
}: SolicitacaoExameCirurgiaProviderProps) {
  const { user } = useAppSelector((state: RootState) => state);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { idConvenioAtendimento, idConvenioProcedimentos } = useAtendimento();

  const [guiasProcedimentosSolicitados, setGuiasProcedimentosSolicitados] =
    useState<any[]>([]);
  const [
    loadingFetchProcedimentosSolicitados,
    setLoadingFetchProcedimentosSolicitados,
  ] = useState(false);
  const [idConvenio, setIdConvenio] = useState<number | null>(null);
  const [procedimentoNomeSearchHistorico, setProcedimentoNomeSearchHistorico] =
    useState('');
  const [exameFuturoIsEnabled, setExameFuturoIsEnabled] = useState(false);

  useEffect(() => {
    const fetchVisualizarHistoricoSadtTopo = async () => {
      const response = await ParametroAPI.loadParametros({
        codigoParametro: 'HABILITAR_EXAMES_FUTUROS',
      });

      if (response?.list?.length && response?.list[0]) {
        if (!response.list[0]?.listaCliente?.length) {
          return setExameFuturoIsEnabled(response?.list[0].valor === 'true');
        }

        const parametroPersonalizadoEmpresa =
          response.list[0]?.listaCliente.find(
            cliente => cliente.idEmpresa === user.idEmpresa,
          );

        setExameFuturoIsEnabled(
          parametroPersonalizadoEmpresa?.valor === 'true',
        );
      }
    };

    fetchVisualizarHistoricoSadtTopo();
  }, [user.idEmpresa]);

  const fetchProcedimentosSolicitados = useCallback(async () => {
    try {
      if (!idConvenioAtendimento && !idConvenioProcedimentos) return;
      setLoadingFetchProcedimentosSolicitados(true);

      const response =
        await SolicitacoesProcedimentosAPI.getSolicitacoesProcedimentos(
          Number(idAtendimento),
          (idConvenioProcedimentos ?? idConvenioAtendimento) as number,
        );

      setGuiasProcedimentosSolicitados(
        Object.entries(groupBy(response, 'numeroGuia')).map(
          ([guia, procedimentos]: any) => [Number(guia), procedimentos],
        ),
      );
    } catch (error) {
      setGuiasProcedimentosSolicitados([]);
    } finally {
      setLoadingFetchProcedimentosSolicitados(false);
    }
  }, [idAtendimento]);

  useEffect(() => {
    fetchProcedimentosSolicitados();
  }, [fetchProcedimentosSolicitados]);

  const handleDeleteProcedimentoFromGuia = (idProcedimento: any) => {
    setGuiasProcedimentosSolicitados((prevGuiasProcedimentosSolicitados: any) =>
      prevGuiasProcedimentosSolicitados
        .map(([numeroGuia, procedimentos]: any) => {
          const newProcedimentos = procedimentos.filter(
            (procedimento: any) =>
              procedimento.idSolicitacaoProcedimento !== idProcedimento,
          );

          return [numeroGuia, newProcedimentos];
        })
        .filter(([, procedimentos]: any) => !!procedimentos.length),
    );
  };

  const handleUpdateProcedimentosInGuias = (procedimentos: any[]) => {
    setGuiasProcedimentosSolicitados(
      (prevGuiasProcedimentosSolicitados: any) => {
        const guiasProcedimentosMap = new Map(
          prevGuiasProcedimentosSolicitados,
        );

        for (const procedimento of procedimentos) {
          const numeroGuia = procedimento.numeroGuia;
          const guiaAtual: any = guiasProcedimentosMap.get(numeroGuia);

          if (guiaAtual) {
            const procedimentoExistente = guiaAtual.find(
              (prevProcedimento: any) =>
                prevProcedimento.idSolicitacaoProcedimento ===
                procedimento.idSolicitacaoProcedimento,
            );

            if (procedimentoExistente) {
              // procedimento já existe na guia atual, atualizar
              guiaAtual.splice(
                guiaAtual.indexOf(procedimentoExistente),
                1,
                procedimento,
              );
            } else {
              // procedimento não existe na guia atual, adicioná-lo
              guiaAtual.push(procedimento);
            }
            guiasProcedimentosMap.set(numeroGuia, guiaAtual);
          } else {
            // nova guia para o procedimento
            guiasProcedimentosMap.set(numeroGuia, [procedimento]);
          }
        }

        // transformar o mapa de volta em um array de tuplas
        const novasGuiasProcedimentosSolicitados = Array.from(
          guiasProcedimentosMap.entries(),
        );

        // adicionar guias que não tiveram novos procedimentos
        for (const [
          numeroGuia,
          prevProcedimentos,
        ] of prevGuiasProcedimentosSolicitados) {
          if (!guiasProcedimentosMap.has(numeroGuia)) {
            novasGuiasProcedimentosSolicitados.push([
              numeroGuia,
              prevProcedimentos,
            ]);
          }
        }

        return novasGuiasProcedimentosSolicitados;
      },
    );
  };

  const handleUpdateProcedimentoPropValue = (
    idProcedimento: number,
    propName: string,
    newValue: any,
  ) => {
    setGuiasProcedimentosSolicitados((prevGuiasProcedimentosSolicitados: any) =>
      prevGuiasProcedimentosSolicitados.map(
        ([numeroGuia, prevProcedimentos]: any) => {
          const checkGuiaHasProcedimento = prevProcedimentos.some(
            (prevProcedimento: any) =>
              prevProcedimento.idSolicitacaoProcedimento === idProcedimento,
          );

          if (!checkGuiaHasProcedimento) {
            return [numeroGuia, prevProcedimentos];
          }

          const procedimentos = prevProcedimentos.map(
            (prevProcedimento: any) => {
              if (prevProcedimento.idSolicitacaoProcedimento !== idProcedimento)
                return prevProcedimento;

              prevProcedimento[propName] = newValue;

              return prevProcedimento;
            },
          );

          return [numeroGuia, procedimentos];
        },
      ),
    );
  };

  const getNextProcedimentoPendente = (idProcedimento: number) => {
    for (const [_, procedimentos] of guiasProcedimentosSolicitados) {
      const nextProcedimento = procedimentos.find((procedimento: any) => {
        const hasPendenciaJustificativa =
          procedimento.pendencia?.necessitaJustificativa &&
          !procedimento.justificativa;
        const hasPendenciaIndicacaoClinica =
          procedimento.pendencia?.necessitaIndicacaoClinica &&
          !procedimento.indicacaoClinica;

        return (
          procedimento.idSolicitacaoProcedimento !== idProcedimento &&
          (hasPendenciaJustificativa || hasPendenciaIndicacaoClinica)
        );
      });

      return nextProcedimento;
    }
  };

  return (
    <SolicitacaoExameCirurgiaContext.Provider
      value={{
        guiasProcedimentosSolicitados,
        loadingFetchProcedimentosSolicitados,
        idConvenio,
        procedimentoNomeSearchHistorico,
        exameFuturoIsEnabled,
        setProcedimentoNomeSearchHistorico,
        fetchProcedimentosSolicitados,
        handleDeleteProcedimentoFromGuia,
        handleUpdateProcedimentosInGuias,
        handleUpdateProcedimentoPropValue,
        setLoadingFetchProcedimentosSolicitados,
        setIdConvenio,
        getNextProcedimentoPendente,
      }}
    >
      {children}
    </SolicitacaoExameCirurgiaContext.Provider>
  );
}
