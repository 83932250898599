import {
  GetRespiracaoItemsProps,
  PostRespiracaoItemProps,
  PutRespiracaoItemInativarProps,
  PutRespiracaoItemProps,
} from 'src/models/APIs/ProntuarioAPI/PreAtendimento/Respiracao/RespiracaoDTO';

import Http, { HttpParams } from 'src/core/http/Http';

export const getRespiracao = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetRespiracaoItemsProps>> =>
  Http.get({
    service: 'pep',
    url: `respiracao/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesRespiracao = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'respiracao/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarRespiracao = (
  payload: PostRespiracaoItemProps,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'respiracao',
    data: payload,
    return: true,
    ...options,
  });

export const alterarRespiracao = (
  idPreAtendimento: number,
  payload: PutRespiracaoItemProps,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `respiracao/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarRespiracao = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: PutRespiracaoItemInativarProps,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `respiracao/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerRespiracao = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `respiracao/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const RespiracaoAPI = {
  getRespiracao,
  getUnidadesRespiracao,
  adicionarRespiracao,
  alterarRespiracao,
  inativarRespiracao,
  removerRespiracao,
};

export default RespiracaoAPI;
