import { useCallback, useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';

import { formatCPF } from 'src/utils/utils';

import { FieldPacienteCartoes } from './components/FieldPacienteCartoes';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

import { useNovoAgendamento } from './hooks/NovoAgendamentoContext';
import { useNavigate } from 'react-router';

export const NovoAgendamentoIdentificacaoPacienteForm = ({
  handleSetData,
  values,
}: {
  handleSetData(v: any): void;
  values: any;
}) => {
  const [loading, setLoading] = useState(false);

  const { idPaciente, idEmpresa } = useAppSelector(state => state.user);
  const { setTimeOutElegibilidade } = useNovoAgendamento();

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    numCarteirinha: Yup.string().nullable(),
    cpf: Yup.string(),
    email: Yup.string()
      .email('O e-mail não é valido')
      .required('Campo obrigatório')
      .typeError('Digite um email válido'),
    dataNascimento: Yup.date(),
    ddd: Yup.number().required('Informe o DDD').typeError('Informe o DDD'),
    celular: Yup.string()
      .required('Informe o número de celular')
      .typeError('Informe o número de celular'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...values,
      ddd: values.celular?.substring(0, 2),
      celular: values.celular?.slice(2),
    } as any,
  });

  const hasContato = useRef(false);

  useEffect(() => {
    if (values?.email) return;
    setLoading(true);

    PortalPacienteAPI.getPacienteUser({
      hideToast: true,
    })
      .then(v => {
        const numCarteirinha =
          v.cartaoPrincipal?.numeroCartao && v.cartaoPrincipal?.status
            ? v.cartaoPrincipal?.numeroCartao
            : null;

        hasContato.current = !!v.contatos?.length;
        form.reset({
          numCarteirinha,
          cpf: formatCPF(v.cpf),
          email: v.email,
          dataNascimento: new Date(v.dataNascimento),
          ddd: v.contatos?.[0]?.numero.substring(0, 2),
          celular: v.contatos?.[0]?.numero.slice(2),
          nome: v.nome,
          nomeMae: v.nomeMae,
        });
      })
      .catch(() => navigate(-1))
      .finally(() => setLoading(false));
  }, [form, values?.email]);

  const onSubmit = async (v: typeof validationSchema.__outputType) => {
    const celular = `${v.ddd} ${v.celular}`;

    try {
      let res;

      await PortalPacienteAPI.updatePacienteContato(
        idPaciente,
        {
          idEmpresa,
          contatos: [{ numero: celular, tipo: 'CELULAR_PARTICULAR' }],
          email: v.email,
        },
        {
          hideToast: true,
          errorHideToast: true,
        },
      );

      if (v.numCarteirinha) {
        res = await PortalPacienteAPI.getCartaoPaciente(
          {
            idPaciente,
            numeroCartao: v.numCarteirinha,
          },
          { throwError: true },
        );

        !!res?.convenio?.convenioTissDto?.[0]?.timeoutVerificaElegibilidade &&
          setTimeOutElegibilidade(
            res.convenio.convenioTissDto[0].timeoutVerificaElegibilidade,
          );
      }

      return handleSetData({
        ...v,
        celular,
        idConvenio: res?.convenio?.id || undefined,
        convenioUtilizaTiss:
          !!res && !!res.convenio.convenioTissDto?.[0]?.utilizaTiss,
        idCartaoPaciente: res?.id || undefined,
      });
    } catch {}
  };

  return (
    <FormProvider {...form}>
      <form className="p-col-12 p-md-6" onSubmit={form.handleSubmit(onSubmit)}>
        <fieldset
          className="p-d-flex p-flex-column p-gap-2 p-px-3"
          disabled={loading}
        >
          <SimpleText fontColor="color_60">
            Identificação do paciente
          </SimpleText>

          <FieldPacienteCartoes
            label="Número da carteirinha"
            idPaciente={idPaciente}
            showClear
          />

          <FormInput name="cpf" label="CPF" disabled hideTextErrorSpace />
          <FormInput name="email" label="E-mail" hideTextErrorSpace />
          <CalendarInputControlled
            control={form.control}
            name="dataNascimento"
            label="Data de nascimento"
            disabled
            showIcon
          />

          <SimpleText medium>Celular</SimpleText>
          <div className="p-d-flex p-gap-2">
            <FormInput
              name="ddd"
              className="p-col-3 p-md-2 p-p-0"
              type="number"
              maxLength={2}
            />
            <FormInput
              type="number"
              name="celular"
              className="p-col-9 p-md-10 p-p-0"
            />
          </div>

          <Button
            type="submit"
            label="Salvar e avançar"
            disabled={form.formState.isSubmitting}
          />
        </fieldset>
      </form>
    </FormProvider>
  );
};
