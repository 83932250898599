import { useFieldArray, useForm } from 'react-hook-form';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

export const FieldDataExamesMultiple = () => {
  const { control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dataExames',
  });

  return (
    <div className="p-d-flex p-flex-column p-mt-4 p-w-100 p-gap-2">
      <div className="p-grid p-col-12 p-d-flex p-ai-center p-p-0 p-gap-2">
        <CalendarInputControlled
          name={`dataRealizacaoExame`}
          label="Data realização"
          control={control}
          placeholder="dd/mm/aaaa"
          className="p-col-12 p-md-6 p-p-0"
        />

        <Button
          className="p-mt-4 p-col-12 p-md-6"
          style={{ width: 'auto' }}
          label="Adicionar exame futuro"
          onClick={() => append({ data: new Date().getDate(), qtde: null })}
        />
      </div>

      {fields.map((field, idx) => (
        <>
          {idx === 0 && (
            <div className="p-my-3">
              <SimpleText fontColor={FONT_COLOR.COLOR_40} bold>
                Exames futuros
              </SimpleText>
            </div>
          )}

          <div className="p-grid p-col-12 p-md-6 p-p-0 p-gap-2 p-ai-center">
            <CalendarInputControlled
              name={`dataExames.${idx}.data`}
              label="Data realização"
              control={control}
              placeholder="dd/mm/aaaa"
              hideTextErrorSpace={false}
              key={idx}
              className="p-col-12 p-md-6 p-p-0"
            />

            <FormInput
              name={`dataExames.${idx}.qtde`}
              label="Quantidade"
              type="number"
              className="p-col-9 p-md-3 p-p-0"
            />

            <Button
              icon="fa fa-trash"
              className="p-col-1"
              onClick={() => remove(idx)}
              btnType="gray"
            />
          </div>
        </>
      ))}
    </div>
  );
};
