export const tiposPeriodicidade = [
  {
    value: 'DIARIA',
    label: 'Diária',
  },
  {
    value: 'SEMANAL',
    label: 'Semanal',
  },
  {
    value: 'QUINZENAL',
    label: 'Quinzenal',
  },
  {
    value: 'MENSAL',
    label: 'Mensal',
  },
  {
    value: 'BIMESTRAL',
    label: 'Bimestral',
  },
  {
    value: 'TRIMESTRAL',
    label: 'Trimestral',
  },
  {
    value: 'QUADRIMESTRAL',
    label: 'Quadrimestral',
  },
  {
    value: 'SEMESTRAL',
    label: 'Semestral',
  },
  {
    value: 'ANUAL',
    label: 'Anual',
  },
  {
    value: 'BIMENSAL',
    label: 'Bimensal',
  },
];
