import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';
import {
  postLoginEndpoint,
  refreshtokenEmpresa,
  postRefreshTokenEndpoint,
} from 'src/APIs/SecurityAPI/AuthAPI/AuthAPI';

export const logIn = (form: FormData): Promise<User> => {
  return postLoginEndpoint(form);
};

export const refreshToken = (refreshToken: string): Promise<User> => {
  return postRefreshTokenEndpoint(refreshToken);
};

export const refreshTokenByEmpresa = (
  refreshToken: string,
  idEmpresa: string,
): Promise<User> => {
  return refreshtokenEmpresa(refreshToken, idEmpresa);
};
interface AccessToken {
  authorities: string[];
}

export const LOGIN_ACTION = 'user/login';
export const logInAction: any = createAsyncThunk(
  LOGIN_ACTION,
  async (
    form: Partial<LoginFormDTO>,
    { rejectWithValue }: { rejectWithValue(param: any): any },
  ): Promise<Partial<User>> => {
    try {
      const formAux: FormData = new FormData();
      Object.keys(form).forEach(v => {
        formAux.append(v, form[v as keyof LoginFormDTO]);
      });

      const response = await logIn(formAux);

      if ('status' in response) throw response;

      //* Portal Paciente
      if (response?.tipoUsuario === 'PACIENTE') {
        const pacienteDados = await PortalPacienteAPI.getPacienteUser({
          throwError: true,
          headers: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Authorization: `Bearer ${response.access_token}`,
          },
        })
          .then(user => user)
          .catch(() => clearUser());

        response.pacienteDados = {};
        response.idPaciente = pacienteDados.id;
      }

      const { authorities }: AccessToken = jwt_decode(response.access_token);

      response.authorities = authorities;

      return response;
    } catch (e: any) {
      if (e?.data?.errorMessage.includes('SEG-005')) {
        rejectWithValue(e);
        throw new Error('NEW_USER');
      }
      return rejectWithValue(e);
    }
  },
);

export const loginAuthLink: any = createAsyncThunk(
  LOGIN_ACTION,
  async (
    form: User,
    { rejectWithValue }: { rejectWithValue(param: any): any },
  ): Promise<Partial<User>> => {
    try {
      const response = form;

      if ('status' in response) throw response;

      //* Portal Paciente
      if (response?.tipoUsuario === 'PACIENTE') {
        const pacienteDados = await PortalPacienteAPI.getPacienteUser({
          throwError: true,
          headers: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Authorization: `Bearer ${response.access_token}`,
          },
        })
          .then(user => user)
          .catch(() => clearUser());

        response.pacienteDados = {};
        response.idPaciente = pacienteDados.id;
      }

      const { authorities }: AccessToken = jwt_decode(response.access_token);

      response.authorities = authorities;

      return response;
    } catch (e: any) {
      if (e?.data?.errorMessage.includes('SEG-005')) {
        rejectWithValue(e);
        throw new Error('NEW_USER');
      }
      return rejectWithValue(e);
    }
  },
);

export const REFRESH_TOKEN_ACTION = 'user/refresh_token';
export const refreshTokenAction: any = createAsyncThunk(
  REFRESH_TOKEN_ACTION,
  async (
    payload: { refreshTokenValue: string; idEmpresa?: number | false | null },
    { rejectWithValue }: { rejectWithValue(param: any): any },
  ): Promise<Partial<User>> => {
    try {
      let response;
      const { refreshTokenValue, idEmpresa } = payload;

      if (idEmpresa) {
        response = await refreshTokenByEmpresa(
          refreshTokenValue,
          idEmpresa.toString(),
        );
      } else {
        response = await refreshToken(payload.refreshTokenValue);
      }

      if ('status' in response) throw response;

      return response;
    } catch (e) {
      console.log('Error refreshing in: ', e);
      return rejectWithValue({});
    }
  },
);

interface UserState extends User {
  idUltimoUsuarioAutenticado: number | null;
}

type stateTypeAux = Partial<UserState>;
type actionStypeAux = PayloadAction<Partial<UserState>>;

const initialState: Partial<UserState> = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: stateTypeAux, action: actionStypeAux) => {
      const { authorities }: AccessToken = jwt_decode(
        action.payload?.access_token || '',
      );

      return { ...action.payload, authorities };
    },
    clearUser: (state: stateTypeAux) => {
      Object.assign(state, {});
      return {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        logInAction.fulfilled,
        (state: stateTypeAux, action: actionStypeAux) => {
          return { ...state, ...action.payload };
        },
      )
      .addCase(
        logInAction.rejected,
        (state: stateTypeAux, action: actionStypeAux) => {
          const { idUltimoUsuarioAutenticado = null } = state;
          return { idUltimoUsuarioAutenticado };
        },
      )
      .addCase(
        refreshTokenAction.fulfilled,
        (state: stateTypeAux, action: actionStypeAux) => {
          const payload = action.payload;

          const { authorities }: AccessToken = jwt_decode(
            action.payload?.access_token || '',
          );

          //* Dados retornando nulos e consequentemente removendo empresa selecionada.
          delete payload.idEmpresa;
          delete payload.nomeEmpresa;
          delete payload.codigoEmpresa;
          delete payload.timeZone;

          return { ...state, ...payload, authorities };
        },
      )
      .addCase(
        refreshTokenAction.rejected,
        (state: stateTypeAux, action: actionStypeAux) => {
          const { idUsuario = null } = state;
          return { idUltimoUsuarioAutenticado: idUsuario };
        },
      );
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
