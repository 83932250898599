import { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { FieldProfissionalExecutante } from '../../components/FieldProfissionalExecutante/FieldProfissionalExecutante';
import { AnexoOpme } from './components/AnexoOpme/AnexoOpme';
import { AnexoQuimioterapia } from './components/AnexoQuimioterapia/AnexoOpme';
import { ExameImagemFields } from './components/ExameImagemFields';
import { ExameLaboratorialFields } from './components/ExameLaboratorialFields';
import { ExamesFuturosFields } from './components/ExamesFuturosFields';
import { ExamesRecentes } from './components/ExamesRecentes/ExamesRecentes';
import { InternadoFields } from './components/InternadoFields';
import { LocalFields } from './components/LocalFields';
import { Ocorrencias } from './components/Ocorrencias';
import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import { escalaTempoOptions, tipoSolicitacaoOptions } from '../../helpers';

import { validationSchema } from './validationSchema';

import './ModalEditarProcedimento.scss';

type FormValues = {
  indicacaoClinica: string;
  flagAplicarIndicacaoTodosProcedimentos: boolean;
  quantidade: number;
  local: string;
  justificativa: string;
  flagAplicarJustificativaTodosProcedimentos: boolean;
  tempoDoenca?: number;
  escalatempo?: string;
  idProfissionalExecutante?: number;
  situacaoLocal?: string;
  dataSugerInternacao?: Date;
  quantidadeDiarias?: string;
  previsaoOpme?: boolean;
  idTipoInternacao?: number;
  hospital?: Hospital;
  idRegimeInternacao?: number;
  tipoSolicitacao?: string;
  observacao?: string;
  examesFuturos?: any[];
  dataRealizacao?: Date;
  questionarioSolicitacaoImg?: {
    historiaClinica: string;
    possuiSubsidiarios: boolean;
    dentroParametros: boolean;
    motivo: string;
    subsidiarios?: string;
  };
  questionarioSolicitacao?: {
    repeticao: boolean;
    dataAnterior?: Date;
    tratamentosRealizados?: string;
    outrasInformacoes?: string;
  };
};

export const ModalEditarProcedimento = (props: any) => {
  const {
    isOpen,
    close,
    state: { procedimento: initialProcedimento, onReloadList, onUpdateList },
  } = props;

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { idConvenioAtendimento, idConvenioProcedimentos } = useAtendimento();

  const [procedimento, setProcedimento] = useState(initialProcedimento);
  const [nextProcedimentoPendente, setNextProcedimentoPendente] =
    useState<any>(null);

  const { exameFuturoIsEnabled, getNextProcedimentoPendente } =
    useSolicitacaoExameCirurgia();

  useEffect(() => {
    const hasPendenciaJustificativa =
      procedimento.pendencia?.necessitaJustificativa &&
      !procedimento.justificativa;
    const hasPendenciaIndicacaoClinica =
      procedimento.pendencia?.necessitaIndicacaoClinica &&
      !procedimento.indicacaoClinica;

    if (hasPendenciaJustificativa || hasPendenciaIndicacaoClinica) {
      const nextProcedimento = getNextProcedimentoPendente(
        procedimento.idSolicitacaoProcedimento,
      );

      setNextProcedimentoPendente(nextProcedimento);
    }
  }, [getNextProcedimentoPendente, procedimento]);

  const defaultValues = useMemo(
    () => ({
      indicacaoClinica: procedimento?.indicacaoClinica || '',
      tempoDoenca: procedimento?.tempoDoenca,
      escalatempo: procedimento?.escalatempo || null,
      quantidade: procedimento?.quantidade || '',
      idProfissionalExecutante: procedimento?.idProfissionalExecutante,
      local: procedimento?.local,
      situacaoLocal: procedimento?.situacaoLocal,
      quantidadeDiarias: procedimento?.quantidadeDiarias,
      idTipoInternacao: procedimento?.idTipoInternacao,
      hospital: procedimento?.hospital,
      idRegimeInternacao: procedimento?.idRegimeInternacao,
      tipoSolicitacao: procedimento?.tipoSolicitacao || 'ELETIVA',
      observacao: procedimento?.observacao || '',
      justificativa: procedimento?.justificativa || '',
      questionarioSolicitacaoImg:
        procedimento?.questionarioSolicitacaoImg || undefined,
      questionarioSolicitacao: {
        ...procedimento?.questionarioSolicitacao,
        dataAnterior: procedimento?.questionarioSolicitacao?.dataAnterior
          ? new Date(
              procedimento?.questionarioSolicitacao?.dataAnterior.replace(
                '-',
                '/',
              ),
            )
          : undefined,
      },
      previsaoOpme: procedimento?.previsaoOpme || false,
      flagAplicarIndicacaoTodosProcedimentos: false,
      flagAplicarJustificativaTodosProcedimentos: false,
      ...(procedimento?.dataSugerInternacao && {
        dataSugerInternacao: new Date(procedimento.dataSugerInternacao),
      }),
      ...(procedimento?.dataRealizacao && {
        dataRealizacao: new Date(procedimento.dataRealizacao),
      }),
    }),
    [procedimento],
  );

  const useFormMethods = useForm<FormValues>({
    resolver: yupResolver(
      validationSchema(
        procedimento?.pendencia?.necessitaIndicacaoClinica,
        procedimento?.pendencia?.necessitaJustificativa,
        procedimento.questionarioImg,
        procedimento.questionarioLab,
      ),
    ),
    defaultValues,
  });

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useFormMethods;

  useEffectSkipFirst(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (data: FormValues) => {
    try {
      const { hospital, questionarioSolicitacao, ...rest } = data;

      const payload = {
        ...rest,
        exameFuturo: !!data?.examesFuturos?.length,
        idHospital: hospital?.id,
        idConvenio: idConvenioProcedimentos ?? idConvenioAtendimento,
        ...(questionarioSolicitacao?.repeticao !== undefined && {
          questionarioSolicitacao,
        }),
      };

      const response = await SolicitacoesProcedimentosAPI.putProcedimento(
        Number(idAtendimento),
        procedimento.idSolicitacaoProcedimento,
        payload,
      );
      if (
        data.flagAplicarIndicacaoTodosProcedimentos ||
        data.flagAplicarJustificativaTodosProcedimentos
      ) {
        onReloadList();
      } else {
        const procedimentos = response.map((procedimentoItem: any) => {
          if (
            procedimentoItem.idSolicitacaoProcedimento ===
              procedimento.idSolicitacaoProcedimento &&
            !!hospital?.id
          ) {
            procedimentoItem.hospital = hospital;
          }
          return procedimentoItem;
        });
        onUpdateList(procedimentos);
      }
      !!nextProcedimentoPendente
        ? setProcedimento(nextProcedimentoPendente)
        : close();
    } catch (error) {}
  };

  const salvarText = !!nextProcedimentoPendente
    ? 'Salvar e ir para a próxima pendência'
    : 'Salvar';

  return (
    <Dialog
      className="modal-editar-procedimento-solicitado"
      header={procedimento.descricaoProcedimento}
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
    >
      <div className="p-grid">
        <AnexoOpme
          hasAnexoOpme={procedimento.anexoOpme}
          idProcedimento={procedimento.idSolicitacaoProcedimento}
          numeroGuia={procedimento.numeroGuia}
        />
        <AnexoQuimioterapia
          hasAnexoQuimio={procedimento.anexoQuimioterapia}
          idProcedimento={procedimento.idSolicitacaoProcedimento}
          numeroGuia={procedimento.numeroGuia}
        />

        {procedimento.flagPossuiHistorico && (
          <ExamesRecentes
            codigoProcedimento={procedimento.codigoProcedimento}
          />
        )}

        <Ocorrencias
          pendencia={procedimento.pendencia}
          hasJustificativa={procedimento.justificativa}
          hasNotLaudo={!procedimento?.resultadoExame?.laudoArquivo}
          hasNotIndicacaoClinica={!procedimento.indicacaoClinica}
        />

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40} medium>
          Nova solicitação de procedimento
        </SimpleText>

        <FormProvider {...useFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)} className="p-grid p-col-12">
            <Card className="p-grid p-mt-2" type={CARD_TYPES.BORDER}>
              <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
                <FormInput
                  name="indicacaoClinica"
                  label="Indicação clínica"
                  hideTextErrorSpace
                />

                <CheckboxControlled
                  control={control}
                  name="flagAplicarIndicacaoTodosProcedimentos"
                  label="Aplicar a indicação clínica para todos os procedimentos."
                />
              </div>

              <div className="p-d-flex p-ai-end p-grid p-col-12 p-p-0">
                <FormInput
                  className="p-col-12 p-sm-6"
                  classNameInput="p-w-100"
                  name="tempoDoenca"
                  label="T. doença"
                  type="number"
                  hideTextErrorSpace
                />

                <DropdownControlled
                  className="p-col-12 p-sm-6"
                  control={control}
                  name="escalatempo"
                  options={escalaTempoOptions}
                  filter={false}
                />
              </div>

              <FormInput
                className="p-col-12 p-sm-6"
                classNameInput="p-w-100"
                name="quantidade"
                label="Quantidade"
                type="number"
                hideTextErrorSpace
              />

              {procedimento.autogerado && (
                <FieldProfissionalExecutante
                  className="p-col-12 p-sm-6"
                  name="idProfissionalExecutante"
                />
              )}

              <LocalFields
                localFieldDisabled={!procedimento.flagPermiteAlterarLocal}
              />

              <InternadoFields />

              <div className="p-col-12 p-sm-6 p-pb-0">
                <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                  Caráter solicitação
                </SimpleText>
                <RadioButtonGroupControlled
                  name="tipoSolicitacao"
                  control={control}
                  error={errors?.tipoSolicitacao}
                  items={tipoSolicitacaoOptions}
                  stretch
                />
              </div>

              <div className="p-col-12 p-py-0">
                <Divider layout="horizontal" />
              </div>

              <TextareaInputControlled
                className="p-col-12 p-md-6"
                control={control}
                name="observacao"
                label="Observações"
              />

              <div className="p-col-12 p-md-6">
                <TextareaInputControlled
                  control={control}
                  name="justificativa"
                  label="Justificativa"
                  errorMsg={errors?.justificativa?.message}
                />

                <CheckboxControlled
                  className="p-mt-2"
                  control={control}
                  name="flagAplicarJustificativaTodosProcedimentos"
                  label="Aplicar a justificativa para todos os procedimentos."
                />
              </div>
            </Card>

            <SimpleText
              className="p-col-12 p-mt-3"
              fontColor={FONT_COLOR.COLOR_40}
              medium
            >
              Exames
            </SimpleText>
            <Card className="p-grid p-mt-2" type={CARD_TYPES.BORDER}>
              <CalendarInputControlled
                className={`p-col-12 ${exameFuturoIsEnabled ? 'p-sm-6' : ''}`}
                control={control}
                name="dataRealizacao"
                label="Data da realização"
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                minDate={new Date()}
              />

              <>{exameFuturoIsEnabled && <ExamesFuturosFields />}</>
            </Card>

            <ExameImagemFields
              hasQuestionarioImg={procedimento.questionarioImg}
            />

            <ExameLaboratorialFields
              hasQuestionarioLab={procedimento.questionarioLab}
            />

            <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
              <Button
                type="button"
                btnType="ghost"
                label="Cancelar"
                onClick={close}
                loading={isSubmitting}
                stretch
              />
              <Button
                type="submit"
                label={salvarText}
                loading={isSubmitting}
                stretch
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
};
