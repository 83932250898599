import { useCallback, useState, useEffect } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { useLocation, useParams } from 'react-router';
import { useSubscription } from 'react-stomp-hooks';
import { toast } from 'react-toastify';

import { TissAPI } from 'src/APIs/ProntuarioAPI/TissAPI';

import useSize from 'src/core/hooks/useSize';
import useTheme from 'src/core/themes/useTheme';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import {
  formatBytes,
  preventDefaultAndStopPropagation,
  capitalizeFirstLetter,
} from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import { Checkbox } from 'src/components/_UI/Checkbox';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { DialogErrorTiss } from 'src/pages/Emed/Prontuario/SolicitacaoExameCirurgia/ProcedimentosSolicitadosList/components/DialogErrorTiss/DialogErrorTiss';

import { useConvenioConfigTiss } from 'src/pages/Emed/Prontuario/SolicitacaoExameCirurgia/ProcedimentosSolicitadosList/hooks/useConvenioConfigTiss';

import Toast from '../Basics/Toast/Toast';
import { DialogLoadingTiss } from '../DialogLoadingTiss/DialogLoadingTiss';
import GridListLoading from '../GridList/GridListLoading';

import { ActionsButtonsAssinatura } from './ActionsButtonsAssinatura';

import './ModalRetornoOperadora.scss';

const tagsStatusGuia: any = {
  Autorizado: 'AUTORIZADO',
  'Em análise': 'EM_ANALISE',
  Negado: 'NEGADO',
  'Aguardando justificativa técnica do solicitante': 'AGUARDANDO_JUSTIFICATIVA',
  'Aguardando documentação do prestador': 'AGUARDANDO_DOCUMENTACAO',
  'Solicitação cancelada': 'CANCELADA',
  'Autorizado parcialmente': 'PARCIALMENTE',
};

const formatMensagemGlosa = (procedimentos: any[]) => {
  const descricoesGlosa = procedimentos.flatMap(procedimento =>
    procedimento.glosas.map((glosa: any) => glosa.descricaoGlosa),
  );
  const descricoesGlosasUnicas = Array.from(new Set(descricoesGlosa));

  return capitalizeFirstLetter(descricoesGlosasUnicas.join(', '));
};

type IProps = DisclosureType & {
  guiasRetornoOperadora?: any[];
};

export const ModalRetornoOperadora = ({
  state: stateProp,
  guiasRetornoOperadora,
  isOpen,
  close,
}: IProps) => {
  //! Guia prestador sem retorno
  const { guiaPrestador, idAtendimentoSolicitacao, idConvenioSolicitacao } =
    stateProp || {};

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { isMobile } = useSize();
  const { convenioConfigTiss, loadingConvenioConfigTiss } =
    useConvenioConfigTiss();

  const dialogLoadingCancelarTiss = useDisclosure({ opened: false });
  const dialogErrorCancelarTiss = useDisclosure({ opened: false });
  const dialogErrorTiss = useDisclosure({ opened: false });

  const [loading, setLoading] = useState(false);
  const [guias, setGuias] = useState(guiasRetornoOperadora || []);
  const [guiasSelected, setGuiasSelected] = useState<any[]>([]);

  const [solicitacaoTissAtual, setSolicitacaoTissAtual] = useState('');
  const [cancelandoGuias, setCancelandoGuias] = useState(false);

  const getGuiaPrestador = useCallback(
    async (numeroGuiaPrestador: number) => {
      try {
        setLoading(true);

        const response = await TissAPI.getRetornoOperadorByGuiaPrestador(
          numeroGuiaPrestador,
          { throwError: true },
        );

        setGuias([response]);
      } catch (error) {
        close();
      } finally {
        setLoading(false);
      }
    },
    [close],
  );

  useEffect(() => {
    if (guiaPrestador) getGuiaPrestador(guiaPrestador);
  }, [getGuiaPrestador, guiaPrestador]);

  useEffectSkipFirst(() => {
    if (guiasRetornoOperadora) setGuias(guiasRetornoOperadora);
  }, [guiasRetornoOperadora]);

  const handleCancelarGuias = async () => {
    try {
      setSolicitacaoTissAtual('CANCELAMENTO_GUIA');
      setCancelandoGuias(true);

      await TissAPI.cancelarGuiasSadt(
        Number(idAtendimentoSolicitacao ?? idAtendimento),
        Number(idConvenioSolicitacao ?? convenioConfigTiss?.idConvenio),
        guiasSelected.join(','),
      );

      setGuias((prevGuias: any) =>
        prevGuias.filter((guia: any) =>
          guiasSelected.some(
            (guiaSelected: any) => guiaSelected == guia.guiaPrestador,
          ),
        ),
      );

      setGuiasSelected([]);

      if (guiasSelected.length === guias.length) close();

      //dialogLoadingCancelarTiss.open({ state: { type: 'cancelar' } });
    } catch (error) {
      //dialogErrorCancelarTiss.open({ state: { tryAgainBtnType: 'danger' } });
    } finally {
      setCancelandoGuias(false);
    }
  };

  useSubscription('/user/topic/notificacao', (message: any) => {
    const data = JSON.parse(message.body);

    if (
      data.tipoNotificacao === 'TISS' &&
      data?.dadosComplementares?.transacao === 'CANCELAMENTO_GUIA' &&
      solicitacaoTissAtual === 'CANCELAMENTO_GUIA'
    ) {
      const guiaPrestador = JSON.parse(data.dadosComplementares.guiasPrestador);
      const isSuccess = data.dadosComplementares.sucesso === 'true';
      const message = isSuccess
        ? 'foi cancelada com sucesso'
        : 'não pode ser cancelada';
      const title = isSuccess ? 'Sucesso' : 'Erro';
      const type = isSuccess ? 'success' : 'error';

      const toastData = {
        title,
        message: `A guia ${guiaPrestador[0]} ${message}`,
        type,
      };

      toast(<Toast />, { data: toastData });
      dialogLoadingCancelarTiss.close();
      if (dialogErrorTiss.isOpen) dialogErrorTiss.close();
    }
  });

  const handleSelectAll = (checked: boolean) =>
    setGuiasSelected(checked ? guias.map(guia => guia.guiaPrestador) : []);

  const allSelected = !!guias.length && guiasSelected.length === guias.length;

  if (loading) {
    return (
      <Dialog
        visible={isOpen}
        onHide={close}
        header="Retorno da operadora"
        className="dialog-retorno-operadora"
        maximizedTabletSize
      >
        <>
          <GridListLoading />

          <Button
            btnType="outline"
            label="Fechar"
            type="button"
            onClick={() => close()}
            stretch
          />
        </>
      </Dialog>
    );
  }

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header="Retorno da operadora"
      className="dialog-retorno-operadora"
      maximizedTabletSize
    >
      <div className="dialog-container">
        <div className="dialog-header p-d-flex p-jc-between">
          <div className="p-d-flex p-gap-1 p-ai-center">
            <Checkbox
              className="p-pl-2"
              checked={allSelected}
              onChange={e => handleSelectAll(e.target.checked)}
              disabled={!guias.length}
            />

            {!isMobile && (
              <SimpleText fontColor={FONT_COLOR.PRIMARY}>
                {!!allSelected && `${guiasSelected.length} selecionado(s)`}
              </SimpleText>
            )}
          </div>

          <div className="p-d-flex p-jc-end p-gap-2">
            <Button
              label="Cancelar guias"
              type="button"
              btnType="danger"
              onClick={handleCancelarGuias}
              disabled={!guiasSelected.length}
              loading={cancelandoGuias}
            />
            <ActionsButtonsAssinatura
              idAtendimento={Number(idAtendimentoSolicitacao ?? idAtendimento)}
              isDisabled={!guias.length}
            />
          </div>
        </div>

        <div className="dialog-content">
          {guias.map((guia: any, idx: number) => {
            const statusGuia = tagsStatusGuia[guia.statusGuiaOperadora];
            const accOpened = statusGuia === 'EM_ANALISE';

            return (
              <Accordion
                key={guia.guiaPrestador}
                activeIndex={accOpened ? idx : undefined}
                className={guia.checked ? 'acc-checked' : ''}
              >
                <AccordionTab
                  header={
                    <AccordionHeader
                      guia={guia}
                      statusGuia={statusGuia}
                      checked={guiasSelected.some(
                        guiaPrestadorSelect =>
                          guiaPrestadorSelect === guia.guiaPrestador,
                      )}
                      onSelect={(checked: boolean) =>
                        setGuiasSelected(prev =>
                          checked
                            ? [...prev, guia.guiaPrestador]
                            : prev.filter(
                                guiaSelected =>
                                  guiaSelected !== guia.guiaPrestador,
                              ),
                        )
                      }
                    />
                  }
                >
                  <AccordionContent
                    procedimentos={guia.procedimentos}
                    statusGuia={statusGuia}
                  />
                </AccordionTab>
              </Accordion>
            );
          })}
        </div>

        <Button
          btnType="outline"
          label="Fechar"
          type="button"
          onClick={() => close()}
          disabled={cancelandoGuias}
          stretch
        />

        {dialogLoadingCancelarTiss.isOpen && (
          <DialogLoadingTiss
            customMensage={convenioConfigTiss?.mensagemConexaoOperadora}
            onTimeout={() =>
              dialogErrorTiss.open({
                state: { tryAgainBtnType: 'danger' },
              })
            }
            timeout={convenioConfigTiss?.timeoutCancelaGuia}
            setIsTimerRunning={() => {}}
            {...dialogLoadingCancelarTiss}
          />
        )}

        {dialogErrorTiss.isOpen && (
          <DialogErrorTiss
            customMensage={convenioConfigTiss?.mensagemFalhaComunicacao}
            onRetry={() =>
              dialogLoadingCancelarTiss.open({
                state: { type: 'cancelar' },
              })
            }
            onFinish={() => {}}
            {...dialogErrorTiss}
          />
        )}
      </div>
    </Dialog>
  );
};

const AccordionHeader = ({ guia, statusGuia, checked, onSelect }: any) => {
  const { theme } = useTheme();
  const { isMobile, isTablet } = useSize();

  return (
    <div className="acc-header p-grid p-w-100 p-ai-center">
      <div className="p-w-30 p-p-0" onClick={preventDefaultAndStopPropagation}>
        <Checkbox
          checked={checked}
          onChange={e => onSelect(e.target.checked)}
        />
      </div>
      <SimpleText className="p-col-5 p-lg-3 p-sm-2 p-text-climp-2" bold>
        Guia operadora {guia.guiaOperadora}
      </SimpleText>
      <div className="p-col p-d-flex p-jc-center">
        <SimpleText className={`tag tag-${statusGuia} ${theme || ''}`}>
          {guia.statusGuiaOperadora}
        </SimpleText>
      </div>
      {!isMobile && (
        <SimpleText className="p-col-2 p-lg-3 p-md- p-text-climp-2">
          Guia prestador {guia.guiaPrestador}
        </SimpleText>
      )}
      {!isMobile && !!guia.protocoloOperadora && (
        <SimpleText
          className="p-col-2 p-text-climp-2"
          fontColor={FONT_COLOR.COLOR_60}
        >
          Protocolo {guia.protocoloOperadora}
        </SimpleText>
      )}
      <div
        onClick={preventDefaultAndStopPropagation}
        className="p-col-2 p-lg-2 p-md-3 p-d-flex p-jc-end"
      >
        {statusGuia === 'EM_ANALISE' &&
          (isTablet ? (
            <Button icon="fas fa-file-lines" btnType="tonal" disabled />
          ) : (
            <Button label="Ver guia fácil" btnType="tonal" disabled />
          ))}
      </div>
    </div>
  );
};

const AccordionContent = ({ procedimentos, statusGuia }: any) => {
  const { isMobile } = useSize();
  const [anexos, setAnexos] = useState<File[]>(['a', 'b'] as any);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const anexosEncaminhadosParaOperadora = false;

  const mensagensGlosas = formatMensagemGlosa(procedimentos);

  return (
    <>
      <div className="acc-content p-gap-2">
        {!!procedimentos.length &&
          procedimentos.map((procedimento: any, idx: number, arr: any) => (
            <div key={idx} className="row p-grid p-ai-center">
              <SimpleText className="p-col-2" fontColor={FONT_COLOR.COLOR_60}>
                {procedimento.codigoProcedimento}
              </SimpleText>
              <SimpleText className="p-col-10 p-sm-4">
                {procedimento.descricaoProcedimento}
              </SimpleText>

              <div className="p-d-flex p-flex-column p-gap-1 p-col-4 p-sm-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                  Qtd. Solicitada
                </SimpleText>
                <SimpleText>{procedimento.quantidadeSolicitada}</SimpleText>
              </div>
              <div className="p-d-flex p-flex-column p-gap-1 p-col-4 p-sm-2">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                  Qtd. Aprovada
                </SimpleText>
                <SimpleText>{procedimento.quantidadeAutorizada}</SimpleText>
              </div>

              {!!procedimento.glosas.length && (
                <SimpleText className="p-col-1 tag tag-ocorrencia p-text-truncate">
                  Ocorrência
                </SimpleText>
              )}

              {!!procedimento.glosas.length && (
                <div className="p-col p-d-flex p-jc-end">
                  <Button
                    icon="fas fa-pencil"
                    type="button"
                    btnType="gray"
                    //! Edit
                    onClick={() => {}}
                    disabled={
                      true
                      //* N pode editar se o status for diferente de NEGADO ou AUTORIZADO_PARCIALMENTE
                      /*!(
                      statusGuia === 'NEGADO' ||
                      statusGuia === 'AUTORIZADO_PARCIALMENTE'
                    )*/
                    }
                  />
                </div>
              )}

              {isMobile && idx !== arr.length - 1 && (
                <Divider layout="horizontal" className="p-col-12" />
              )}
            </div>
          ))}
      </div>

      {!!mensagensGlosas && (
        <AlertBox visible={true} text={mensagensGlosas} type="WARN" />
      )}

      {/* // TODO: CONDICIONAL PARA O ALERT DO RETORNO DA GUIA FACIL  */}
      {/*
      <AlertBox
        visible={true}
        // !Texto do retorno da guia fácil
        text="Favor anexar documentos para análise. Laudo exames cardiológicos."
        type="DANGER"
      />
      */}

      {/* TODO: Parte de anexos do guia facil */}
      {/*statusGuia !== 'AUTORIZADA' && (
        <div className="container-anexos p-w-100 p-my-2">
          {!anexosEncaminhadosParaOperadora ? (
            <>
              <SimpleText fontSize={FONT_SIZE.SM} bold>
                Anexos
              </SimpleText>

              <UploadFile
                accept={{
                  'application/pdf': ['.pdf', '.PDF'],
                  'image/png': ['.png', '.PNG'],
                  'image/jpeg': ['.jpeg', '.JPEG'],
                  'image/jpg': ['.jpg', '.JPG'],
                }}
                maxFiles={10}
                addMultiples
                textAcceptedFiles="PDF, PNG e JPG"
                selectedFile={setSelectedFiles}
                customRowFiles={(file, onRemove) => (
                  <RowContentAnexos file={file} onRemove={onRemove} />
                )}
              />
            </>
          ) : (
            anexos.map((file, idx) => (
              <div key={idx} className="p-d-flex p-flex-column p-gap-2">
                <RowContentAnexos file={file} disabled />
              </div>
            ))
          )}

          {!!selectedFiles.length && (
            <div className="p-d-flex p-my-2 p-w-100">
              <div className="p-col-3">
                <Button
                  btnType="green-link"
                  label="Cancelar"
                  type="button"
                  onClick={() => {}}
                  stretch
                />
              </div>
              <Button
                icon="fas fa-arrow-up"
                label="Enviar para operadora"
                type="button"
                // TODO: Enviar para operadora
                onClick={() => {}}
                stretch
              />
            </div>
          )}
        </div>
          )*/}
    </>
  );
};

const RowContentAnexos = ({
  file,
  onRemove,
  disabled,
}: {
  file: File;
  onRemove?: any;
  disabled?: boolean;
}) => {
  const { isMobile } = useSize();

  return (
    <div className="row p-w-100 p-px-2 p-ai-center">
      <div className="p-w-30">
        <i className="fa-solid fa-file green-icon" />
      </div>
      <SimpleText className="p-col-8" fontColor={FONT_COLOR.PRIMARY}>
        {file.name}
      </SimpleText>
      {!isMobile && (
        <SimpleText className="p-col-2 p-text-right">
          {formatBytes(file.size, 2)}
        </SimpleText>
      )}
      <div className="p-col-4 p-sm-2 p-d-flex p-jc-end">
        {!disabled && (
          <Button
            icon="fas fa-trash"
            type="button"
            btnType="gray"
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};
