import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { EmptyData } from '../../components/EmptyData';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import { PlanosRow } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/PlanosRow/PlanosRow';

import './HistoricoPlanoCuidado.scss';

export const HistoricoPlanoCuidado = () => {
  const { ativo } = useAppSelector(state => state.consultorios);
  const { paciente } = useAppSelector(state => state.atendimento);
  const { invalidateHistoricoPlanosList } = useAppSelector(
    state => state.query,
  );
  const dispatch = useDispatch();

  const { selectedAtendimento } = useHistoricoAtendimento();

  const idPaciente =
    paciente?.id || selectedAtendimento?.atendimento?.paciente?.id;

  const fetchAPI = useCallback(async () => {
    if (idPaciente) {
      return await PlanoCuidadoPacienteAPI.loadPlanoCuidadoPaciente({
        idPaciente: idPaciente,
        idConsultorio: ativo.id,
        idAtendimento: selectedAtendimento?.idAtendimento,
        historico: true,
        sortBy: 'dataInclusao',
        sortDirection: 'DESC',
      })
        .catch(() => {})
        .finally(() => {
          dispatch(resetInvalidateQuery());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ativo?.id, idPaciente, invalidateHistoricoPlanosList]);

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <SimpleText medium>Planos de cuidado</SimpleText>

      <SearchList.Root fetchApi={fetchAPI}>
        <div className="p-col-12 p-pt-0 p-d-flex p-flex-column">
          <SearchList.InfiniteScroll
            renderRows={items => (
              <div className="content-planos p-p-2">
                <div className="p-d-flex p-px-1" style={{ width: '98%' }}>
                  <SimpleText
                    className="p-col-5 p-md-7"
                    fontSize="xxs"
                    fontColor="color_60"
                  >
                    Nome do plano
                  </SimpleText>
                  <SimpleText
                    className="p-col-2"
                    fontSize="xxs"
                    fontColor="color_60"
                  >
                    Status
                  </SimpleText>
                  <SimpleText
                    className="p-col-5 p-md-3 p-text-right p-pr-6"
                    fontSize="xxs"
                    fontColor="color_60"
                  >
                    Ações
                  </SimpleText>
                </div>

                {items.map((item: any) => (
                  <PlanosRow key={item.id} type="HISTORICO" item={item} />
                ))}
              </div>
            )}
            customEmptyText={
              <div className="content-empty-row">
                <EmptyData />
              </div>
            }
          />
        </div>
      </SearchList.Root>
    </div>
  );
};
