import { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { AssistenteVirutalFilter } from './AssistenteVirtualFilter';
import {
  AssistenteVirtualRow,
  AssistenteVirtualRowMobile,
} from './AssistenteVirtualRow';

export const AssistenteVirtual = () => {
  const { windowInnerWidth } = useSize();
  const navigate = useNavigate();

  const [reload, setReload] = useState(0);

  const isResponsiveMode = useMemo(
    () => windowInnerWidth <= 800,
    [windowInnerWidth],
  );

  const fetchApi = useCallback(async params => {
    return await AssistenciaAPI.getAssistencias(
      {
        page: params.page,
        sortBy: 'dataInclusao',
        sortDirection: 'DESC',
      },
      {
        nome: params.query,
        novidade: params.mostrarNovidades,
        ajudaDeTela: params.mostrarAjudaTela,
        primeiroAcesso: params.mostrarPrimeiroAcesso,
        idFuncionalidade: params.funcionalidade?.id,
        apartirDe: params.dataInicio,
        ate: params.dataFim,
      },
    );
  }, []);

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  return (
    <SearchList.Root
      reloadWhenEmpresaChange
      empresaRequired
      fetchApi={fetchApi}
    >
      <>
        <div className="p-d-flex p-jc-md-between p-gap-2 p-align-center p-flex-wrap p-ai-center p-jc-center p-col-12 p-px-0">
          <SearchList.SearchInput
            label="Pesquise por nome de ajuda"
            placeholder="Digite uma palavra chave"
            className="p-col-12 p-md-6 p-lg-4"
          />

          <AssistenteVirutalFilter />
        </div>

        <SearchList.BorderContainer>
          <div className="p-d-flex p-gap-2 p-flex-wrap p-ai-center p-jc-between">
            <Button
              btnType="pill"
              label="Nova assistência"
              icon="fas fa-plus"
              onClick={() => navigate('/cadastros/assistente-virtual/cadastro')}
              checkPermission="ADM_ASSISTENTE_VIRTUAL_CADASTRO_CADASTRAR"
              className="p-col-12 p-md-6 p-lg-3"
            />
            <SearchList.TotalCount />
          </div>

          <SearchList.InfiniteScroll
            reload={reload}
            renderRows={items =>
              items.map((item: any, idx: number) =>
                isResponsiveMode ? (
                  <AssistenteVirtualRowMobile
                    key={idx}
                    item={item}
                    reloadList={reloadList}
                  />
                ) : (
                  <AssistenteVirtualRow
                    key={idx}
                    item={item}
                    reloadList={reloadList}
                  />
                ),
              )
            }
          />
        </SearchList.BorderContainer>
      </>
    </SearchList.Root>
  );
};
