/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import { LoadPrescricao } from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DialogInativarPrescricao } from './PanelContent/components/DialogInativarPrescricao/DialogInativarPrescricao';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Panel from './Panel/Panel';
import { PanelContent } from './PanelContent/PanelContent';
import { PanelHeader } from './PanelHeader/PanelHeader';

import './HistoricoPrescricaoMedicamentos.scss';

export const HistoricoPrescricaoMedicamentos = () => {
  const { selectedAtendimento, setItensFichaPaciente } =
    useHistoricoAtendimento();

  const idAtendimento = selectedAtendimento?.idAtendimento;

  const [update, setUpdate] = useState(0);
  const [exibirPrescricoes, setExibirPrescricoes] = useState(true);

  const fetchAPI = useCallback(
    async (params): Promise<any> => {
      const data = await PrescricaoAPI.loadPrescricao(
        idAtendimento,
        { page: params.page, sortBy: 'dataInclusao', sortDirection: 'ASC' },
        { errorHideToast: true },
      );

      setItensFichaPaciente(
        (prev: number) => Number(prev) + Number(data?.totalCount),
      );

      if (!data.list?.length) {
        setExibirPrescricoes(false);
      }

      return data;
    },
    [idAtendimento, setItensFichaPaciente],
  );

  const handleUpdate = () => {
    setUpdate(old => old + 1);
  };

  const handleInativar = (prescricao: any, justificativaInativacao: string) => {
    PrescricaoAPI.inativarPrescricao(
      prescricao.id,
      prescricao?.atendimento.id,
      {
        justificativaInativacao,
      },
      { throwError: true },
    )
      .then(() => handleUpdate())
      .catch(() => {});
  };

  if (!exibirPrescricoes) return <></>;

  return (
    <div id="prescricao-medicamentos-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        paginatedList={false}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Prescrição de Medicamento
            </SimpleText>
          </div>

          <SearchList.InfiniteScroll
            className="p-mt-5"
            reload={update}
            autoHeight={false}
            renderRow={item => (
              <Row
                key={item.id}
                item={item}
                view
                handleInativar={handleInativar}
                handleUpdate={handleUpdate}
              />
            )}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </div>
  );
};

const Row = ({ item, view, handleInativar, handleUpdate }: any) => {
  const [itemPrescricao, setItemPrescricao] = useState<LoadPrescricao>(item);
  const [isExecutada, setIsExecutada] = useState(!!item.dataOcorrencia);

  useEffect(() => {
    if (view) setIsExecutada(true);
  }, [view]);

  return (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            item={item}
            optionsMenu={
              <OptionMenu
                handleInativar={(value: string) => handleInativar(item, value)}
              />
            }
          />
        )}
      >
        <PanelContent
          isExecutada={isExecutada}
          item={itemPrescricao}
          setItem={setItemPrescricao}
          view={!!view}
          refetch={handleUpdate}
        />
      </Panel>
    </div>
  );
};

const OptionMenu = ({ handleInativar }: any) => {
  const menuEllipsis = useRef<TieredMenu>(null);

  const inativarDialog = useDisclosure({ opened: false });

  return (
    <>
      <Button
        icon="fas fa-ellipsis-h"
        btnType="gray"
        onClick={event => menuEllipsis?.current?.toggle(event)}
      />

      <TieredMenu
        className="panel-options-ficha-paciente"
        ref={menuEllipsis}
        model={[
          {
            label: 'Inativar',
            className: 'warning-color',
            command: () => inativarDialog.open(),
          },
        ]}
        popup
      />

      {inativarDialog.isOpen && (
        <DialogInativarPrescricao
          handleInativar={handleInativar}
          {...inativarDialog}
        />
      )}
    </>
  );
};
