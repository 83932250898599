import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Image } from 'primereact/image';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';
import EmedLogo from 'src/assets/imgs/emed_logo_text.svg';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { clearUser, setUser } from 'src/core/redux/slices/system/UserSlice';
import useTheme from 'src/core/themes/useTheme';

import { NavBarItemMenu } from './components/NavBarItemMenu';
import { NavBarNotificacao } from './components/NavBarNotificacao/NavBarNotificacao';
import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { useNotifications } from './useNotifications';

import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import './NavBar.scss';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

export const NavBar = ({ children }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hashEmpresa } = useParams();
  const { user } = useAppSelector(state => state);
  const { windowInnerWidth } = useSize();

  const { invalidatePaciente } = useAppSelector(state => state.query);

  const paciente = user.pacienteDados;

  const isCustomSize = useMemo(
    () => windowInnerWidth <= 960,
    [windowInnerWidth],
  );

  const fetchPacienteData = useCallback(async () => {
    try {
      const pacienteDados = await PortalPacienteAPI.getPacienteUser({
        throwError: true,
      });

      dispatch(
        setUser({
          ...user,
          pacienteDados,
        }),
      );
    } catch {}
  }, []);

  useEffect(() => {
    fetchPacienteData();
  }, [fetchPacienteData]);

  useEffectSkipFirst(() => {
    if (invalidatePaciente) {
      fetchPacienteData();
      dispatch(resetInvalidateQuery());
    }
  }, [fetchPacienteData, invalidatePaciente]);

  const tabs: MenuItem[] = [
    {
      label: 'Inicio',
      command: () => navigate(`/portal/${hashEmpresa}/inicio`),
    },
    {
      label: 'Agendamento',
      items: [
        {
          label: 'Novo agendamento',
          command: () =>
            navigate(`/portal/${hashEmpresa}/agendamento/novoAgendamento`),
        },
        {
          label: 'Meus agendamentos',
          command: () =>
            navigate(`/portal/${hashEmpresa}/agendamento/meusAgendamentos`),
        },
      ],
    },
    {
      label: 'Solicitação e exames',
      command: () =>
        navigate(`/portal/${hashEmpresa}/solicitacoesResultadosExames`),
    },
    {
      label: 'Receitas',
      command: () => navigate(`/portal/${hashEmpresa}/receitasOrientacoes`),
    },
  ];

  return (
    <>
      {!isCustomSize ? (
        <Menubar
          className="navbar-portal"
          start={<StartComponent />}
          model={tabs}
          end={<EndComponent pacienteInfo={paciente} />}
        />
      ) : (
        <Menubar
          className="navbar-portal custom-nav"
          start={
            <div className="p-d-flex p-jc-between p-ai-center p-md-mb-2">
              <StartComponent />
              <EndComponent pacienteInfo={paciente} />
            </div>
          }
          model={tabs}
        />
      )}
      {children}

      <Outlet />
    </>
  );
};

const EndComponent = ({ pacienteInfo }: any) => {
  const { theme, toogleTheme } = useTheme();

  const panelExtraConfig = useRef<OverlayPanel>(null);
  const panelNotificacao = useRef<OverlayPanel>(null);
  const responsiveButton = useRef<OverlayPanel>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hashEmpresa } = useParams();

  const { windowInnerWidth, isMobile } = useSize();

  const { qtdeNotificacoesUnread, ...notifications } = useNotifications();
  const notificationLength = !!qtdeNotificacoesUnread
    ? qtdeNotificacoesUnread.toString()
    : '';

  const isMobileCustom = useMemo(
    () => windowInnerWidth <= 625,
    [windowInnerWidth],
  );

  const handleExit = () => {
    dispatch(clearUser());
    navigate(`/portal/${hashEmpresa}/login`);
  };

  const propsImg = pacienteInfo?.urlFoto
    ? {
        image: pacienteInfo?.urlFoto,
      }
    : { icon: 'fas fa-user' };

  return (
    <div className="p-d-flex">
      <div className="profile-container p-d-flex p-gap-2 p-ai-center">
        {!isMobile && (
          <div className="p-d-flex p-flex-column p-text-right p-jc-center p-mt-2">
            <SimpleText fontColor="color_40">
              {pacienteInfo?.nome || '...'}
            </SimpleText>

            {!!pacienteInfo?.cartaoPlano &&
              !!pacienteInfo?.cartaoPrincipal?.status && (
                <ButtonMenu
                  label={`${pacienteInfo?.cartaoPlano} - ${
                    pacienteInfo?.cartaoPrincipal?.convenio?.razaoSocial || ''
                  }`}
                  btnType="green-link"
                  model={[]}
                  readOnly
                  style={{
                    padding: '0',
                  }}
                />
              )}
          </div>
        )}

        <Avatar {...propsImg} style={{ width: '2.4rem', height: '2.4rem' }} />
      </div>

      <Divider layout="vertical" />

      {isMobileCustom ? (
        <Button
          btnType="gray"
          icon="fas fa-ellipsis-h"
          style={{ marginBlock: 'auto' }}
          onClick={e => responsiveButton.current?.toggle(e)}
        />
      ) : (
        <div className="p-d-flex p-gap-2 p-ai-center">
          <Button
            btnType="gray"
            icon="fas fa-bell"
            outsideBadge={notificationLength}
            onClick={e => panelNotificacao.current?.toggle(e)}
          />
          <Button btnType="gray" icon="fas fa-message" disabled />
          <Button
            btnType="gray"
            icon="fas fa-chevron-down"
            onClick={e => panelExtraConfig.current?.toggle(e)}
          />
        </div>
      )}

      <OverlayPanel
        id="Panel-notificacao"
        className={theme}
        ref={panelNotificacao}
      >
        <NavBarNotificacao {...notifications} />
      </OverlayPanel>

      <OverlayPanel
        id="Panel-extra-config"
        className={theme}
        ref={panelExtraConfig}
      >
        <NavBarItemMenu
          onAction={() => toogleTheme!()}
          isChecked={theme === 'dark-theme'}
          icon="icon pi pi-moon"
          label="Modo escuro"
          description="Dê um descando para seus olhos"
          status
        />
        <NavBarItemMenu
          onAction={() => [panelExtraConfig.current?.hide(), handleExit()]}
          icon="fas fa-right-from-bracket"
          label="Sair do portal"
        />
      </OverlayPanel>

      {/* //* Panel Menu no modo responsivo */}
      <OverlayPanel id="Panel-extra-config" ref={responsiveButton}>
        <NavBarItemMenu
          icon="fas fa-bell"
          label="Notificações"
          badge={notificationLength}
          onAction={e => [
            panelNotificacao.current?.toggle(e),
            panelExtraConfig.current?.hide(),
          ]}
        />
        <NavBarItemMenu
          onAction={() => panelExtraConfig.current?.hide()}
          icon="fas fa-message"
          label="Mensagens"
          disabled
        />
        <NavBarItemMenu
          onAction={() => [panelExtraConfig.current?.hide(), handleExit()]}
          icon="fas fa-right-from-bracket"
          label="Sair do portal"
        />
      </OverlayPanel>
    </div>
  );
};

const StartComponent = () => {
  const { hashEmpresa } = useParams();

  const [logoImg, setLogoImg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PortalPacienteAPI.getDadosIniciaisEmpresa({
      hashEmpresa: hashEmpresa!,
    })
      .then(res => setLogoImg(res.logoUrl))
      .finally(() => setLoading(false));
  }, [hashEmpresa]);

  return (
    <div>
      {!loading && <Image src={logoImg || EmedLogo} className="img-nav" />}
    </div>
  );
};
