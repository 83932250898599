import { useCallback, useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PuericulturaAPI from 'src/APIs/ProntuarioAPI/PuericulturaAPI/PuericulturaAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';
import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import { schemaPreNatal } from '../validation';

import './PreNatal.scss';

interface PreNatalProps {
  paciente: any | null;
  idAtendimento: string | undefined;
}

const PreNatal = ({ paciente, idAtendimento }: PreNatalProps) => {
  const [cids, setCids] = useState([]);
  const [valueSearch, setValueSearch] = useState('');

  const { agenda } = useAppSelector((state: RootState) => state);
  const { atendimentoStatus } = useAtendimento();

  const refOverlay = useRef<OverlayPanel>(null);

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schemaPreNatal),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'puericulturaPreNatalCis',
  });

  const getPreNatal = useCallback(async () => {
    if (!paciente?.id) return;

    const params = {
      idPaciente: Number(paciente?.id),
      idProfissionalSaude: agenda.profissionalAtivo.id,
      idAtendimento,
    };

    const response = await PuericulturaAPI.getPreNatal(
      Number(idAtendimento),
      params,
    );

    if (response.id) {
      const sorologia = response.puericulturaPreNatalSorologia;

      setValue('puericulturaPreNatalCis', response.puericulturaPreNatalCis);
      setValue('numeroConsultas', response.numeroConsultas);
      setValue('tipoGravidez', response.tipoGravidez);
      setValue('tipoParto', response.tipoParto);
      setValue('trimestreInicioPrenatal', response.trimestreInicioPrenatal);
      setValue('imunizacaoDuplaAdulto', response.imunizacaoDuplaAdulto);
      setValue('descricao', response.descricao);

      setValue('dataCitopatologia', new Date(sorologia.dataCitopatologia));

      setValue('hepatitebStatus', sorologia.hepatitebStatus);
      setValue(
        'hepatitebPrimeiroTrimestre',
        sorologia.hepatitebPrimeiroTrimestre,
      );
      setValue(
        'hepatitebSegundoTrimestre',
        sorologia.hepatitebSegundoTrimestre,
      );
      setValue(
        'hepatitebTerceiroTrimestre',
        sorologia.hepatitebTerceiroTrimestre,
      );

      setValue('hivStatus', sorologia.hivStatus);
      setValue('hivPrimeiroTrimestre', sorologia.hivPrimeiroTrimestre);
      setValue('hivSegundoTrimestre', sorologia.hivSegundoTrimestre);
      setValue('hivTerceiroTrimestre', sorologia.hivTerceiroTrimestre);

      setValue('vdrlStatus', sorologia.vdrlStatus);
      setValue('vdrlPrimeiroTrimestre', sorologia.vdrlPrimeiroTrimestre);
      setValue('vdrlSegundoTrimestre', sorologia.vdrlSegundoTrimestre);
      setValue('vdrlTerceiroTrimestre', sorologia.vdrlTerceiroTrimestre);

      setValue('rubeolaStatus', sorologia.rubeolaStatus);
      setValue('rubeolaPrimeiroTrimestre', sorologia.rubeolaPrimeiroTrimestre);
      setValue('rubeolaSegundoTrimestre', sorologia.rubeolaSegundoTrimestre);
      setValue('rubeolaTerceiroTrimestre', sorologia.rubeolaTerceiroTrimestre);

      setValue('toxoplasmoseStatus', sorologia.toxoplasmoseStatus);
      setValue(
        'toxoplasmosePrimeiroTrimestre',
        sorologia.toxoplasmosePrimeiroTrimestre,
      );
      setValue(
        'toxoplasmoseSegundoTrimestre',
        sorologia.toxoplasmoseSegundoTrimestre,
      );
      setValue(
        'toxoplasmoseTerceiroTrimestre',
        sorologia.toxoplasmoseTerceiroTrimestre,
      );
    } else {
      setValue('hepatitebStatus', 'SEM_INFORMACAO');
      setValue('hivStatus', 'SEM_INFORMACAO');
      setValue('vdrlStatus', 'SEM_INFORMACAO');
      setValue('rubeolaStatus', 'SEM_INFORMACAO');
      setValue('toxoplasmoseStatus', 'SEM_INFORMACAO');
    }
  }, [agenda.profissionalAtivo.id, idAtendimento, paciente, setValue]);

  useEffect(() => {
    getPreNatal();
  }, [getPreNatal]);

  const renderGravidez = useCallback(() => {
    const inicioPreNatalOptions = [
      { label: '1º Trimestre', value: 'TRIMESTRE_1' },
      { label: '2º Trimestre', value: 'TRIMESTRE_2' },
      { label: '3º Trimestre', value: 'TRIMESTRE_3' },
    ];

    const gravidezOptions = [
      { label: 'Simples', value: 'SIMPLES' },
      { label: 'Múltipla', value: 'MULTIPLA' },
    ];

    return (
      <>
        <TextInputControlled
          className="inputNumeroConsultas"
          name="numeroConsultas"
          label="Número de consultas do pré-natal"
          permitirApenasNumeros
          maxLength={4}
          control={control}
        />

        <div className="p-grid p-mt-5">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Pré-natal iniciou no:
          </SimpleText>

          <RadioButtonGroupControlled
            name="trimestreInicioPrenatal"
            className="p-col"
            control={control}
            items={inicioPreNatalOptions}
            error={errors.trimestreInicioPrenatal}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-grid p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Gravidez
          </SimpleText>

          <RadioButtonGroupControlled
            name="tipoGravidez"
            className="p-col-4"
            control={control}
            items={gravidezOptions}
            error={errors.tipoGravidez}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </>
    );
  }, [control, errors, isAtendimentoRunning]);

  const handleSearch = useCallback(async (value: string) => {
    setValueSearch(value);

    if (value) {
      const params = {
        nome: value,
        codigo: value,
      };

      const data = await UtilsAPI.getCids(params);
      setCids(data.list);
    } else {
      setCids([]);
    }
  }, []);

  const renderIntercorrencias = useCallback(() => {
    const imunizacaoOptions = [
      { label: 'Esquema completo', value: 'ESQUEMA_COMPLETO' },
      { label: 'Esquema incompleto', value: 'ESQUEMA_INCOMPLETO' },
      { label: 'Não realizou', value: 'NAO_REALIZOU' },
      { label: 'Sem informação', value: 'SEM_INFORMACAO' },
    ];

    const tipoPartoOptions = [
      { label: 'Normal', value: 'NORMAL' },
      { label: 'Cesárea', value: 'CESARIA' },
      { label: 'Fórceps', value: 'FORCEPS' },
    ];

    const handleSelectCids = (cid: any) => {
      append({ idCid: cid.id, nomeCid: cid.nome, codigoCid: cid.codigo });
      refOverlay.current?.hide();
    };

    return (
      <>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          medium
        >
          Intercorrências
        </SimpleText>

        <div className="p-grid p-ai-end">
          <TextInputControlled
            className="p-col-12 p-p-0 p-mt-3 p-mr-3"
            name="descricao"
            label="Descrição"
            control={control}
          />

          <div className="p-mb-1 p-mt-2 p-grid p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
              className="p-col-12"
            >
              CID
            </SimpleText>
            <div className="p-mr-4">
              <TextInputSearch
                placeholder="Buscar por código ou nome"
                onChange={handleSearch}
                value={valueSearch}
              />
            </div>

            <Button
              type="button"
              icon="fa fa-search"
              btnType="gray"
              onClick={e => refOverlay.current?.toggle(e)}
              disabled={!isAtendimentoRunning}
            />

            <OverlayPanel ref={refOverlay} className="result-search">
              <>
                {cids.length === 0 && (
                  <li className="p-grid   p-jc-center">
                    <SimpleText
                      fontSize={FONT_SIZE.XXS}
                      fontColor={FONT_COLOR.COLOR_60}
                      medium
                    >
                      Nenhum item encontrado.
                    </SimpleText>
                  </li>
                )}

                {cids.map((cid: any) => (
                  <li key={cid.id} onClick={() => handleSelectCids(cid)}>
                    {cid.codigo} - {cid.nome}
                  </li>
                ))}
              </>
            </OverlayPanel>
          </div>
        </div>

        {fields.map((cid: any, index) => (
          <div className="cids-container" key={index}>
            <li>
              ({cid.codigoCid}) {cid.nomeCid}
            </li>

            <Button
              type="button"
              icon="fa fa-trash"
              btnType="gray"
              onClick={() => remove(index)}
            />
          </div>
        ))}

        <div className="p-grid p-mt-4">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Imunização dupla adulto
          </SimpleText>

          <RadioButtonGroupControlled
            name="imunizacaoDuplaAdulto"
            className="p-col"
            control={control}
            items={imunizacaoOptions}
            error={errors.imunizacaoDuplaAdulto}
            disabled={!isAtendimentoRunning}
          />
        </div>

        <div className="p-grid p-mt-3">
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Tipo do parto
          </SimpleText>

          <RadioButtonGroupControlled
            name="tipoParto"
            className="p-col"
            control={control}
            items={tipoPartoOptions}
            error={errors.tipoParto}
            disabled={!isAtendimentoRunning}
          />
        </div>
      </>
    );
  }, [
    append,
    cids,
    control,
    errors.imunizacaoDuplaAdulto,
    errors.tipoParto,
    fields,
    handleSearch,
    isAtendimentoRunning,
    remove,
    valueSearch,
  ]);

  const renderForm = useCallback(
    (name: string) => {
      const formOptions = [
        { label: 'Não realizada', value: 'NAO_REALIZADO' },
        { label: 'Normal', value: 'NORMAL' },
        { label: 'Alterada', value: 'ALTERADO' },
        { label: 'Sem informação', value: 'SEM_INFORMACAO' },
      ];

      return (
        <div className="content-form">
          <RadioButtonGroupControlled
            name={name + 'Status'}
            className="radio-form"
            control={control}
            items={formOptions}
            error={errors[name + 'Status']}
            disabled={!isAtendimentoRunning}
          />

          <div className="checkbox-group">
            <CheckboxControlled
              name={name + 'PrimeiroTrimestre'}
              label="1° trimestre"
              control={control}
              disabled={!isAtendimentoRunning}
            />

            <CheckboxControlled
              name={name + 'SegundoTrimestre'}
              label="2° trimestre"
              control={control}
              disabled={!isAtendimentoRunning}
            />

            <CheckboxControlled
              name={name + 'TerceiroTrimestre'}
              label="3° trimestre"
              control={control}
              disabled={!isAtendimentoRunning}
            />
          </div>
        </div>
      );
    },
    [control, errors, isAtendimentoRunning],
  );

  const renderSorologias = useCallback(() => {
    return (
      <>
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          medium
        >
          Sorologias
        </SimpleText>

        <CalendarInputControlled
          className="p-mb-5 p-mt-4 p-p-0 p-col-3"
          icon="fa fa-calendar-alt"
          showIcon
          label="Data C.O"
          name="dataCitopatologia"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          maxDate={new Date()}
          minDate={new Date(paciente?.dataNascimento)}
          control={control}
          errorMsg={errors.dataCitopatologia?.message}
        />

        <VerticalTab
          tabItens={[
            {
              label: 'HIV',

              content: renderForm('hiv'),
            },
            {
              label: 'VDRL',

              content: renderForm('vdrl'),
            },
            {
              label: 'Toxoplasmose',

              content: renderForm('toxoplasmose'),
            },
            {
              label: 'Hepatite B',

              content: renderForm('hepatiteb'),
            },
            {
              label: 'Rubeola',
              content: renderForm('rubeola'),
            },
          ]}
        />
      </>
    );
  }, [
    control,
    errors.dataCitopatologia?.message,
    paciente?.dataNascimento,
    renderForm,
  ]);

  const onSubmit = async (data: any) => {
    const {
      dataCitopatologia = dayjs(data.dataCitopatologia).format(
        'YYYY-MM-DDTHH:mmZ',
      ),
      numeroConsultas,
      trimestreInicioPrenatal,
      tipoGravidez,
      descricao,
      imunizacaoDuplaAdulto,
      tipoParto,
      puericulturaPreNatalCis,
      ...rest
    } = data;

    const payload = {
      idPaciente: paciente.id,
      idProfissionalSaude: agenda.profissionalAtivo.id,
      idAtendimento: Number(idAtendimento),

      numeroConsultas,
      trimestreInicioPrenatal,
      tipoGravidez,
      descricao,
      imunizacaoDuplaAdulto,
      tipoParto,
      puericulturaPreNatalCis,

      puericulturaPreNatalSorologia: {
        dataCitopatologia,
        ...rest,
      },
    };

    await PuericulturaAPI.postPreNatal(payload);
    getPreNatal();
  };

  return (
    <ElevatedCard noMargin className="container-preNatal">
      <SimpleText
        fontSize={FONT_SIZE.MD}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Pré-natal
      </SimpleText>

      <form>
        <fieldset disabled={!isAtendimentoRunning}>
          {renderGravidez()}

          <Divider />

          {renderIntercorrencias()}

          <Divider />

          {renderSorologias()}

          <Button
            label="Salvar"
            btnType="tonal"
            disabled={!isAtendimentoRunning}
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
            stretch
          />
        </fieldset>
      </form>
    </ElevatedCard>
  );
};

export default PreNatal;
