import { useState, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import MedicoAPI from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';
import TipoCuidadoAPI from 'src/APIs/ConfigAPI/TipoCuidado/TipoCuidadoAPI';
import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalEditPedidoCuidado.scss';

interface ModalEditPedidoCuidadoProps {
  pedidoCuidado: PedidoCuidado | null;
  onHide: () => void;
  onSuccess: () => void;
}

function ModalEditPedidoCuidado({
  pedidoCuidado,
  onHide,
  onSuccess,
}: ModalEditPedidoCuidadoProps) {
  const { consultorios } = useAppSelector((state: RootState) => state);

  const validationSchema = Yup.object().shape({
    observacaoSolicitante: Yup.string().required('Informe a descrição'),
    ...(pedidoCuidado?.programado && {
      idTipoCuidado: Yup.number().required('Selecione o tipo de cuidado'),
      idProfSaudeExec: Yup.number().required(
        'Selecione o profissional responsável',
      ),
      dataEnfermeiro: Yup.date().nullable().required('Informe a data'),
    }),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [tiposCuidados, setTiposCuidados] = useState<TipoCuidado[]>([]);
  const [profissionais, setProfissionais] = useState<Medico[]>([]);

  const loadTiposCuidados = async (idConsultorio: number) => {
    const response = await TipoCuidadoAPI.loadTipoCuidado({
      idConsultorio,
    });

    if (Array.isArray(response)) setTiposCuidados(response);
  };

  const loadMedicosByConsultorio = async (idConsultorio: number) => {
    const response: any = await MedicoAPI.loadMedicosById(idConsultorio);

    if (Array.isArray(response)) setProfissionais(response);
  };

  useEffect(() => {
    if (consultorios?.ativo?.id) {
      loadTiposCuidados(consultorios.ativo.id);
      loadMedicosByConsultorio(consultorios.ativo.id);
    }
  }, [consultorios.ativo.id]);

  useEffect(() => {
    const dataEnfermeiro = pedidoCuidado?.dataEnfermeiro
      ? new Date(pedidoCuidado.dataEnfermeiro)
      : null;

    reset({
      observacaoSolicitante: pedidoCuidado?.observacaoSolicitante || '',
      idTipoCuidado: pedidoCuidado?.idTipoCuidado || '',
      idProfSaudeExec: pedidoCuidado?.idProfSaudeExec || '',
      dataEnfermeiro,
    });
  }, [pedidoCuidado, reset, setValue]);

  const onSubmit = useCallback(
    async (data: any) => {
      const { id, idAtendimentoSolicita, dataOcorrencia, idProfSaudeSolicit } =
        pedidoCuidado || {};

      if (
        !id ||
        !idAtendimentoSolicita ||
        !dataOcorrencia ||
        !idProfSaudeSolicit
      )
        return;

      const payload = {
        ...data,
        programado: pedidoCuidado?.programado,
        dataOcorrencia,
        idAtendimentoSolicita,
        idProfSaudeSolicit,
      };

      const response = await PedidosCuidadoAPI.alterarPedidoCuidado(
        id,
        idAtendimentoSolicita,
        payload,
      );

      if (response?.status === 200) {
        onSuccess();
        onHide();
      }
    },
    [pedidoCuidado, onSuccess, onHide],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const tiposCuidadosOptions = useMemo(
    () =>
      tiposCuidados.map((tipoCuidado: TipoCuidado) => ({
        label: tipoCuidado.descricao,
        value: tipoCuidado.id,
      })),
    [tiposCuidados],
  );

  const profissionaisOptions = useMemo(
    () =>
      profissionais.map((profissional: Medico) => ({
        label: profissional.nome,
        value: profissional.id,
      })),
    [profissionais],
  );

  return (
    <Dialog
      id="modal-edit-cuidado"
      type="modal"
      header="Editar pedido cuidado"
      visible={!!pedidoCuidado}
      onHide={onHide}
      footer={renderFooter}
    >
      <form id="form-cuidado" className="p-grid">
        {pedidoCuidado?.programado && (
          <>
            <DropdownControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="idTipoCuidado"
              label="Tipo de cuidado"
              placeholder="Tipo de cuidado"
              options={tiposCuidadosOptions}
              errorMsg={errors.idTipoCuidado?.message}
            />
            <DropdownControlled
              className="p-col-12 p-sm-6"
              control={control}
              name="idProfSaudeExec"
              label="Profissional responsável"
              placeholder="Profissional responsável"
              options={profissionaisOptions}
              errorMsg={errors.idProfSaudeExec?.message}
            />
            <CalendarInputControlled
              className="p-col-12"
              control={control}
              name="dataEnfermeiro"
              label="Data"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              errorMsg={errors.dataEnfermeiro?.message}
            />
          </>
        )}

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="observacaoSolicitante"
          label="Descrição"
          placeholder="Informe a observação"
          errorMsg={errors.observacaoSolicitante?.message}
        />
      </form>
    </Dialog>
  );
}

export default ModalEditPedidoCuidado;
