import {
  ProgressBar as PrimeProgressBar,
  ProgressBarProps,
} from 'primereact/progressbar';

import SimpleText from '../Basics/SimpleText/SimpleText';

import './ProgressBar.scss';

type IProps = Omit<ProgressBarProps, 'value'> & {
  value: number;
};

export const ProgressBar = (props: IProps) => {
  const { value, showValue = true } = props;

  const className = `${props.className || ''} ${
    value >= 50 ? 'success' : 'danger'
  }`;

  return (
    <div
      style={{ pointerEvents: 'none' }}
      className={` p-w-100 p-d-flex p-flex-column p-gap-1`}
    >
      {showValue && (
        <SimpleText style={{ fontWeight: 300 }}>{value}% concluído</SimpleText>
      )}
      <PrimeProgressBar
        id="progressBar-comp"
        {...props}
        className={className}
        showValue={false}
      />
    </div>
  );
};
