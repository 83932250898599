import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import { formatEnum } from '../helpers';

import { folhaRostoValidationSchema } from './validationSchema';

const tipoPartoOptions = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'Cesárea', value: 'CESARIA' },
];

interface FolhaRostoProps {
  idPreNatal: number | null;
  idPaciente: number | null;
  valoresEnum: any;
  onSuccess: (idPreNatal: number) => void;
  onHide: () => void;
}

const FolhaRosto = ({
  idPreNatal,
  idPaciente,
  valoresEnum,
  onSuccess,
  onHide,
}: FolhaRostoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const {
    proximaGestacao,
    setProximaGestacao,
    loadPreNatal,
    tipoGestacaoOptions,
  } = usePreNatal();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(folhaRostoValidationSchema),
    defaultValues: {
      tipoGestacao: proximaGestacao,
      fetos: [{ nome: '' }],
      tipoPartoHistorico: '',
      pesoAoNascerHistorico: '',
      pesoNascimentoUnidadeMedidaHist: '',
      dataPartoHistorico: null,
      quantidadeFetos: '',
    },
  });

  const { fields: fetosFields } = useFieldArray({
    control,
    name: 'fetos',
  });

  const changeProximaGestacao = (tipoGestacao: string) => {
    const [, ultimaGestacao] = tipoGestacao.split('_');

    const [, atualProximaGestacao] = proximaGestacao.split('_');

    if (Number(ultimaGestacao) >= Number(atualProximaGestacao)) {
      const proxGestacao = `GESTACAO_0${Number(ultimaGestacao) + 1}`;

      setProximaGestacao(proxGestacao);
    }
  };

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      pesoAoNascerHistorico: String(data.pesoAoNascerHistorico).replace(
        ',',
        '.',
      ),
      idPaciente,
      registrarHistorico: true,
    };

    const saveAction = idPreNatal
      ? PreNatalAPI.updateFolhaRosto
      : PreNatalAPI.createFolhaRosto;

    const response = await saveAction(
      payload,
      Number(idAtendimento),
      idPreNatal as number,
    );

    if (response?.status === 200 || response?.status === 201) {
      loadPreNatal(idPaciente!);
      onSuccess(response.data.id);
      changeProximaGestacao(data.tipoGestacao);
    }
  };

  return (
    <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="tipoGestacao"
        label="Tipo da gestação"
        options={tipoGestacaoOptions}
        filter={false}
        errorMsg={errors.tipoGestacao?.message}
      />

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="tipoPartoHistorico"
        label="Tipo de parto"
        options={tipoPartoOptions}
        filter={false}
        errorMsg={errors.tipoPartoHistorico?.message}
      />

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="pesoAoNascerHistorico"
        label="Peso ao nascer"
        floatOnly
        maxLength={5}
        errorMsg={errors.pesoAoNascerHistorico?.message}
      />

      <div className="field-center-without-label p-col-12 p-md-6">
        <DropdownControlled
          control={control}
          name="pesoNascimentoUnidadeMedidaHist"
          options={formatEnum(valoresEnum?.unidadeMedidaEncerrarCiclo)}
          filter={false}
          errorMsg={errors.pesoNascimentoUnidadeMedidaHist?.message}
        />
      </div>

      <TextInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="quantidadeFetos"
        label="Nº Feto"
        permitirApenasNumeros
        maxLength={2}
        errorMsg={errors.quantidadeFetos?.message}
      />

      <CalendarInputControlled
        className="p-col-12 p-md-6"
        control={control}
        name="dataPartoHistorico"
        label="Data do parto"
        placeholder="dd/mm/aaaa"
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
        maxDate={new Date()}
        errorMsg={errors.dataPartoHistorico?.message}
      />

      {fetosFields.map((field, index) => (
        <TextInputControlled
          key={index}
          className="p-col-12"
          control={control}
          name={`fetos.${index}.nome`}
          label="Nome do bebê"
          maxLength={50}
          errorMsg={errors.fetos?.[index]?.nome?.message}
        />
      ))}
      <div className="p-grid p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          btnType="ghost"
          label="Cancelar"
          onClick={onHide}
          loading={isSubmitting}
          stretch
        />
        <Button type="submit" label="Salvar" loading={isSubmitting} stretch />
      </div>
    </form>
  );
};

export default FolhaRosto;
