import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './Dialog.scss';
import { useNovoAgendamento } from '../../hooks/NovoAgendamentoContext';

type IProps = DisclosureType & {
  onRetry: () => void;
  onAgendar: () => void;
};

export const DialogErroComunicacao = ({
  close,
  onAgendar,
  onRetry,
}: IProps) => {
  const { resetElegibilidade } = useNovoAgendamento();

  return (
    <Dialog
      id="DialogErroComunicacao"
      visible
      onHide={close}
      header="Erro de comunicação"
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText className="p-col-12 p-px-0 p-line-height-2">
          Não houve resposta da operadora. Agende o paciente confirmando mais
          tarde a elegibilidade ou tente novamente.
        </SimpleText>

        <div className="p-d-flex p-gap-2">
          <Button
            label="Concluir agendamento"
            btnType="outline"
            onClick={() => [close(), onAgendar()]}
            stretch
          />
          <Button
            label="Tentar novamente"
            onClick={() => [close(), onRetry(), resetElegibilidade()]}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};
