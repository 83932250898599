import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import Dialog from 'src/components/Dialog/Dialog';

import BarraLateralMenuAgenda from 'src/pages/Emed/Agenda/MenuAgenda/BarraLateralMenuAgenda/BarraLateralMenuAgenda';
import EscolhaHorarioLivre from 'src/pages/Emed/Agenda/MenuAgenda/EscolhaHorarioLivre/EscolhaHorarioLivre';
import MenuAgenda from 'src/pages/Emed/Agenda/MenuAgenda/MenuAgenda';
import UserHeader from 'src/pages/Emed/Agenda/UserHeader/UserHeader';

import { useAgenda } from '../AgendaContext';
import DetalhesAgendamento from '../MenuAgenda/DetalhesAgendamento/DetalhesAgendamento';
import EditarPaciente from '../MenuAgenda/EditarPaciente/EditarPaciente';
import GerenciarGrupo from '../MenuAgenda/GerenciarGrupo/GerenciarGrupo';
import Status from '../MenuAgenda/Status/Status';

import './AgendaCompleta.scss';

interface AgendaCompletaProps {
  pesquisarPacienteDisclosure: any;
}

const AgendaCompleta = ({
  pesquisarPacienteDisclosure,
}: AgendaCompletaProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const { isTablet } = useSize();
  const {
    toggleViewAgenda,
    dialogEditarPaciente,
    dialogDetalhesAgendamento,
    dialogGerenciarGrupos,
    isBarraLateralOpened,
    selectedDate,
    setSelectedDate,
  } = useAgenda();

  const navigate = useNavigate();
  const dialogHorariosVisibles = useDisclosure({ opened: false });

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [pacienteEspera, setPacienteEspera] = useState<any>({
    id: '',
    paciente: null,
    idListaEspera: null,
  });
  const [horarioDisponivelSelecionado, setHorarioDisponivelSelecionado] =
    useState<string>('');

  const isProfissionalSaude = user.tipoUsuario === 'PROFISSIONAL_SAUDE';

  const onSelectHorario = (props: any) => {
    navigate('/agenda/novo-agendamento', {
      state: { ...props, pacienteEspera },
    });
  };

  return (
    <Page className={'Agenda p-d-flex p-flex-row'} style={{ height: '100%' }}>
      <>
        {!activeTabIndex && (
          <BarraLateralMenuAgenda
            selectedDate={selectedDate}
            onChangeSelectedDate={setSelectedDate}
            openSelectedHorarioDisponivelModal={paciente => {
              setPacienteEspera({ ...paciente });
              dialogHorariosVisibles.open();
              setActiveTabIndex(0);
            }}
            setHorarioDisponivelSelecionado={(v: any) => {
              setHorarioDisponivelSelecionado(v);
            }}
          />
        )}
        <div
          className={`agenda-content ${
            isBarraLateralOpened && isTablet ? 'p-d-none' : ''
          }`}
        >
          {isProfissionalSaude && (
            <Button
              btnType="ghost"
              label="Sair da edição"
              icon="fa fa-arrow-left"
              className="p-as-start p-mt-2 p-mx-4"
              onClick={() => toggleViewAgenda(false)}
            />
          )}

          <UserHeader
            rightSide={
              <Button
                icon="pi pi-cog"
                btnType="tonal"
                tooltipOptions={{
                  position: 'bottom',
                  className: 'green-tooltip',
                }}
                tooltip="Conheça configurações da agenda"
                onClick={() => navigate('/agenda/config')}
              />
            }
          />

          <MenuAgenda
            horarioDisponivelSelecionado={horarioDisponivelSelecionado}
            setHorarioDisponivelSelecionado={setHorarioDisponivelSelecionado}
            pesquisarPacienteDisclosure={pesquisarPacienteDisclosure}
          />
        </div>

        {dialogHorariosVisibles.isOpen && (
          <Dialog
            visible
            position={'center'}
            closable
            dismissableMask={true}
            closeOnEscape={true}
            header={'Horários disponíveis'}
            onHide={() => dialogHorariosVisibles.close()}
            maximizedTabletSize
          >
            <EscolhaHorarioLivre
              pacienteEspera={pacienteEspera}
              onSelect={(agendamento, date) => {
                onSelectHorario({ agendamento, date });
              }}
              onHide={() => dialogHorariosVisibles.close()}
            />
          </Dialog>
        )}
        {dialogEditarPaciente.isOpen && (
          <EditarPaciente {...dialogEditarPaciente} />
        )}
        {dialogGerenciarGrupos.isOpen && (
          <GerenciarGrupo
            agendamento={dialogGerenciarGrupos.state}
            checkbox={false}
            selectedDate={selectedDate}
            {...dialogGerenciarGrupos}
          />
        )}
        {dialogDetalhesAgendamento.isOpen && (
          <DetalhesAgendamento
            agendamento={dialogDetalhesAgendamento.state}
            status={() => (
              <Status
                agendamento={dialogDetalhesAgendamento.state}
                selectedDate={selectedDate}
                onHide={dialogDetalhesAgendamento.close}
              />
            )}
            {...dialogDetalhesAgendamento}
          />
        )}
      </>
    </Page>
  );
};

export default AgendaCompleta;
