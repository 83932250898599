export const generateGeneros = (generos: Genero[]) => {
  if (!generos || !Array.isArray(generos)) {
    return [];
  }
  const aux = (generos || []).map((v: Genero) => {
    return { label: v.descricao, value: v };
  });
  return aux;
};

export const validatePasswordRules = (watchSenha: string) => {
  const hasn8Digits = watchSenha.length < 8;
  const hasnCapitalLetter = !/^(?=.*[A-Z])/.test(watchSenha);
  const hasnNumber = !/^(?=.*[0-9])/.test(watchSenha);
  const hasnSpecialCharacter = !/^(?=.*[!@#$%^&*])/.test(watchSenha);

  const validateClassName = (condition: boolean) => {
    return watchSenha ? (condition ? 'error' : 'line-through') : 'error';
  };

  return {
    hasn8Digits,
    hasnCapitalLetter,
    hasnNumber,
    hasnSpecialCharacter,
    validateClassName,
  };
};

export const cpfRegex = /^\d{11}$/;
export const passaporteRegex = /^[a-zA-z]{2}[0-9]{6}$/;
