import { useMemo } from 'react';

import { Image } from 'primereact/image';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import NotificacaoAPI from 'src/APIs/NotificacaoAPI/NotificacaoAPI';
import EmedLogo from 'src/assets/imgs/emed_logo_text.svg';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { NotificationsList } from 'src/pages/Emed/LoggedRoot/MainMenu/MainMenuNotification/MainMenuNotification';

import NavBarNotificacaoPreview from './NavBarNotificacaoPreview';

type Props = {
  comunicados: NotificationsList[];
  unreadComunicados: NotificationsList[];
  readComunicados: NotificationsList[];
  isLoading: boolean;
  empresaLogo: any;
};

export const NavBarNotificacaoComunicados = (items: Props) => {
  const comunicados = items.comunicados;
  const dispatch = useDispatch();

  const handleReadNotification = () => {
    const ids = items.unreadComunicados.map(item => item.id);
    return NotificacaoAPI.atualizarStatusNotificacoes(ids).then(() => {
      dispatch(
        setInvalidateQuery({
          invalidateNotificacoes: true,
        }),
      );
    });
  };

  return !items.isLoading && comunicados.length ? (
    <div
      style={{
        maxHeight: '460px',
        overflowY: 'scroll',
      }}
    >
      {!!items.unreadComunicados?.length && (
        <>
          <div className="p-d-flex p-jc-between p-ai-center p-my-1">
            <SimpleText medium>Novos</SimpleText>
            <Button
              label="Marcar todos como lido"
              onClick={handleReadNotification}
              btnType="ghost"
            />
          </div>

          <div className="p-d-flex p-flex-column p-gap-2">
            {items.unreadComunicados.map((item: any, idx: any) => (
              <NavBarNotificacaoItem
                logo={items.empresaLogo}
                item={item}
                key={idx}
              />
            ))}
          </div>
        </>
      )}

      {!!items.readComunicados?.length && (
        <>
          <div className="p-d-flex p-jc-between p-ai-center p-mb-2 p-mt-3">
            <SimpleText medium>Visualizados</SimpleText>
          </div>

          <div className="p-d-flex p-flex-column p-gap-2">
            {items.readComunicados.map((item: any, idx: any) => (
              <NavBarNotificacaoItem
                key={idx}
                item={item}
                logo={items.empresaLogo}
                isVisualizado
              />
            ))}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="p-w-100 p-text-center">
      {!!items.isLoading ? (
        <LoadingComponent />
      ) : (
        <SimpleText fontColor="color_40">
          Suas notificações apareceram aqui
        </SimpleText>
      )}
    </div>
  );
};

const NavBarNotificacaoItem = ({
  isVisualizado,
  item,
  logo,
}: {
  isVisualizado?: boolean;
  item: NotificationsList;
  logo: string;
}) => {
  const complemento = item.conteudo;
  const dialogDisclosure = useDisclosure({ opened: false });

  const diffTempo = useMemo(() => {
    const diffInMinutes = dayjs(new Date()).diff(
      new Date(item.dataCriacao),
      'minute',
    );
    const diffInHours = dayjs(new Date()).diff(
      new Date(item.dataCriacao),
      'hour',
    );
    const diffInDays = dayjs(new Date()).diff(
      new Date(item.dataCriacao),
      'days',
    );

    return diffInMinutes < 60
      ? `Há ${diffInMinutes} min.`
      : diffInHours <= 24
      ? `Há ${diffInHours} ${diffInHours === 1 ? 'hora' : 'horas'}`
      : diffInDays <= 6
      ? `Há ${diffInDays} ${diffInHours === 1 ? 'dia' : 'dias'}`
      : '';
  }, [item.dataCriacao]);

  return (
    <div className="row-component p-d-flex p-ai-center">
      <Image
        src={!!logo ? logo : EmedLogo}
        className="p-col-3 p-p-0"
        width="100%"
      />

      <div className="p-col p-p-0">
        <div className="p-d-flex p-gap-1 p-ai-center p-p-2">
          {!isVisualizado && (
            <span className="tag-cid p-p-">
              <SimpleText fontSize="xxxs">Novo</SimpleText>
            </span>
          )}
          <SimpleText fontSize="xxxs" fontColor="color_60">
            • {diffTempo} •
          </SimpleText>
          <SimpleText fontSize="xxxs" fontColor="color_60">
            {dayjs(item.dataCriacao).format('DD [de] MMM [de] YYYY')}
          </SimpleText>
        </div>
        <div className="p-px-2 p-pb-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_40">{complemento}</SimpleText>
        </div>
      </div>

      <Button
        className="p-col-1 p-p-0"
        btnType="ghost"
        icon="fas fa-chevron-right"
        onClick={() => dialogDisclosure.open()}
      />

      <NavBarNotificacaoPreview
        notificacao={item}
        logo={logo}
        visible={dialogDisclosure.isOpen}
        onClose={dialogDisclosure.close}
      />
    </div>
  );
};

const LoadingComponent = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-1 ">
      <Skeleton loading width="100%" className="p-mt-2" />
      <Skeleton loading width="100%" height="60px" className="p-mt-1" />
      <Skeleton loading width="100%" height="60px" />
      <Skeleton loading width="100%" className="p-mt-2" />
      <Skeleton loading width="100%" height="60px" className="p-mt-1" />
      <Skeleton loading width="100%" height="60px" />
    </div>
  );
};
