import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalInativar.scss';

export const ModalInativar = (props: any) => {
  const {
    isOpen,
    close,
    state: {
      justificativaInativacao,
      nomeUsuario,
      dataInativacao,
      idAtendimento,
      idProcedimento,
      onSuccess,
    },
  } = props;

  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      justificativaInativacao,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await SolicitacoesProcedimentosAPI.putInativar(
        idAtendimento,
        idProcedimento,
        data,
      );

      onSuccess(data.justificativaInativacao);

      close();
    } catch (error) {}
  };

  const renderFooter = () => {
    if (!!justificativaInativacao) {
      return (
        <Button
          type="button"
          btnType="ghost"
          label="Cancelar"
          className="p-col-align-center"
          onClick={close}
          loading={isSubmitting}
          stretch
        />
      );
    }

    return (
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          type="button"
          btnType="ghost"
          label="Cancelar"
          onClick={close}
          loading={isSubmitting}
          stretch
        />
        <Button
          type="button"
          label="Salvar"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={!isDirty}
          stretch
        />
      </div>
    );
  };

  const renderTextInfo = () => {
    if (!!justificativaInativacao) {
      const dataInativacaoFormatted = dataInativacao
        ? dayjs(dataInativacao).format('DD [de] MMMM [de] YYYY')
        : '';

      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Este item no prontuário foi inativo em{' '}
          <b>{dataInativacaoFormatted}</b> por <b>{nomeUsuario}</b>
        </SimpleText>
      );
    }

    return (
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        Você está inativando este item no histórico de exames e resultados. Ele
        continuará ser exibido como uma informação inativa. Para continuar,
        justifique.
      </SimpleText>
    );
  };

  const modalTitle = !!justificativaInativacao
    ? 'Inativo'
    : 'Você tem certeza que deseja inativar?';

  return (
    <Dialog
      id="modal-inativar"
      header={modalTitle}
      visible={isOpen}
      onHide={close}
      type="modal"
      modal={false}
      footer={renderFooter}
      maximizedMobileSize
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-mb-3">{renderTextInfo()}</div>

        <TextareaInputControlled
          control={control}
          name="justificativaInativacao"
          label="Justificativa"
          disabled={!!justificativaInativacao}
        />
      </form>
    </Dialog>
  );
};
