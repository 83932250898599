import { useState, useMemo, useEffect } from 'react';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import TextInputSearch from '../Basics/TextInputSearch/TextInputSearch';

import './VerticalTab.scss';

export interface TabItem {
  label: string;
  content?: JSX.Element;
  headerClass?: string;
  disabled?: boolean;
  modal?: (active: boolean, onClose: () => void) => JSX.Element;
}

interface VerticalTabProps {
  tabItens: TabItem[];
  filter?: boolean;
  filterLabel?: string;
  updateTabIndex?: number | null;
  onChange?: (tabItem: TabItem) => void;
  className?: string;
}

function VerticalTab({
  tabItens,
  filter,
  filterLabel,
  updateTabIndex,
  onChange,
  className = '',
}: VerticalTabProps) {
  const [filterTab, setFilterTab] = useState('');
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    if (typeof updateTabIndex === 'number') setActiveIndex(updateTabIndex);
  }, [updateTabIndex]);

  const filteredTab = useMemo(
    () =>
      tabItens.filter(tabItem =>
        tabItem.label.toLowerCase().includes(filterTab.toLowerCase()),
      ),
    [filterTab, tabItens],
  );

  return (
    <div className={`vertical-tab-container p-grid ${className}`}>
      <ul id="vertical-tab-nav-content" className="p-col-12 p-md-3">
        {filter && (
          <>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {filterLabel}
            </SimpleText>
            <div className="p-mt-2 p-mb-3">
              <TextInputSearch onChange={setFilterTab} placeholder="Filtrar" />
            </div>
          </>
        )}
        {filteredTab.map((tabItem: TabItem, index) => {
          let className = 'vertical-tab-nav';

          if (index === activeIndex) className += ' selected';
          if (tabItem?.disabled) className += ' disabled';

          className += ' ' + (tabItem.headerClass || '');

          return (
            <li
              key={index}
              className={className}
              onClick={() => {
                if (tabItem?.disabled) return;

                setActiveIndex(index);
                if (onChange) onChange(tabItem);
              }}
            >
              {tabItem.label}
            </li>
          );
        })}
      </ul>

      <div id="vertical-tab-content" className="p-col">
        {filteredTab.map((tabItem: TabItem, index) => {
          let className = 'vertical-tab';

          if (index !== activeIndex) className += ' hidden';

          return (
            <div key={index} className={className}>
              {tabItem?.modal?.(index === activeIndex, () =>
                setActiveIndex(0),
              ) || tabItem.content}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default VerticalTab;
