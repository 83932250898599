import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface CardMobileProps {
  paciente: any;
  cardAction: JSX.Element;
}

const CardMobile = ({ paciente, cardAction }: CardMobileProps) => {
  const renderConveniosNomes = () =>
    paciente?.cartoes?.map((cartao: any, index: number, cartoes: any) => {
      let convenioNome = index === 0 ? ' • ' : '';

      convenioNome += capitalizeFirstLetter(
        cartao?.convenio?.nomeFantasia || cartao?.convenio.razaoSocial,
      );

      if (index + 1 !== cartoes.length) convenioNome += ' • ';

      return convenioNome;
    });

  return (
    <div className="div-container">
      <div className="options-position">{cardAction}</div>
      <div className="p-col-12 column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome/Sexo/Planos de saúde
        </SimpleText>
        <div className="p-d-flex p-mt-2">
          <Avatar
            className="avatar"
            label={paciente?.dadosGeraisObrigatorios.nome}
            image={paciente?.urlFoto || ''}
          />
          <div className="column">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
              medium
            >
              {paciente.dadosGeraisObrigatorios.nome}
            </SimpleText>

            <SimpleText
              className="p-mt-2"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              {capitalizeFirstLetter(
                paciente.dadosGeraisObrigatorios.sexo.descricao,
              )}{' '}
              {renderConveniosNomes()}
            </SimpleText>
          </div>
        </div>
      </div>

      <div className="p-col-12 column p-mt-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CPF/Registro
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {paciente.dadosGeraisObrigatorios.cpf?.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            '$1.$2.$3-$4',
          )}
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Registro no. {paciente.id}
        </SimpleText>
      </div>

      <div className="p-col-12 column p-mt-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Data de nascimento
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {dayjs(paciente.dadosGeraisObrigatorios.dataNascimento).format(
            'DD/MM/YYYY',
          )}
        </SimpleText>
      </div>

      <div className="p-col-12 column p-mt-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome da mãe
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {paciente.dadosGeraisObrigatorios.nomeMae}
        </SimpleText>
      </div>

      <div className="p-col-12 column p-mt-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Paciente ativo
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
        >
          {paciente.status ? 'SIM' : 'NÃO'}
        </SimpleText>
      </div>
    </div>
  );
};

export default CardMobile;
