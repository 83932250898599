import { useState } from 'react';

import { AuditoriaAPI } from 'src/APIs/AdminAPI/AuditoriaAPI/AuditoriaAPI';

import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import MobileRow from './MobileRow/MobileRow';
import SearchForm from './SearchForm';

import '../RelatoriosTrilhaAuditoria.scss';

const SearchListTrilhaAuditoria = () => {
  const [reload, setReload] = useState(0);
  const [habilitarExportacao, setHabilitarExportacao] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchApi = async ({ query, ...params }: any) => {
    if (!params?.dataInicial || !params?.dataFinal) {
      return [];
    }

    const response = await AuditoriaAPI.getPesquisaAuditoria(params);

    setHabilitarExportacao(!!response?.list?.length);

    return response;
  };

  const renderRow = (item: any) => {
    return <MobileRow item={item} />;
  };

  return (
    <div id="trilha-auditoria-container">
      <SearchList.Root
        fetchApi={fetchApi}
        reloadWhenEmpresaChange
        reloadWhenConsultorioChange
      >
        <SimpleText fontColor="color_40" className="p-col-12 p-pb-3 p-px-1">
          Pesquise no registro ações, eventos ou atividades que um usuário ou
          sistema realizou em sua sessão.
        </SimpleText>

        <SearchList.BorderContainer>
          <SearchForm
            habilitarExportacao={habilitarExportacao}
            setReload={setReload}
          />
          <Divider layout="horizontal" className="p-mb-1" />
          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </div>
  );
};

export default SearchListTrilhaAuditoria;
