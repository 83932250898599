import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { formatCPF } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import IconButton, {
  IconBtnSizes,
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { Header } from './Header';

interface Props {
  selectedOrigem: any;
  selectedDestino: any;
}

export default function ConfirmarMigracao({
  selectedOrigem,
  selectedDestino,
}: Props) {
  const { isMobile } = useSize();

  return (
    <div className="p-grid p-mb-2">
      <div className="p-col-12 p-d-flex p-flex-column">
        <SimpleText
          className="p-mb-2"
          fontColor={FONT_COLOR.COLOR_40}
          fontSize={FONT_SIZE.XS}
          medium
        >
          Confirmação de migração
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
          Você está migrando o prontuário de um paciente de origem, para um
          outro paciente de destino. Confirme os dados e para continuar, clique
          em migrar.
        </SimpleText>
      </div>

      <div className="migracoes-selecionadas p-col-12 p-d-flex p-flex-column">
        {selectedOrigem ? (
          !isMobile ? (
            <PacienteRow item={selectedOrigem} />
          ) : (
            <PacienteRowMobile item={selectedOrigem} />
          )
        ) : (
          <div className="p-d-flex p-jc-center p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.SM}
              fontColor={FONT_COLOR.COLOR_40}
              medium
              className="p-mt-3 p-mb-3 p-text-center"
            >
              Não há origem selecionada
            </SimpleText>
          </div>
        )}
      </div>

      <div className="p-col-12 p-d-flex p-jc-evenly p-ai-center">
        <IconButton
          btnType={IconBtnTypes.ICON_ONLY}
          icon="fas fa-angles-down"
          btnSize={IconBtnSizes.MEDIUM}
        />
        <IconButton
          btnType={IconBtnTypes.ICON_ONLY}
          icon="fas fa-angles-down"
          btnSize={IconBtnSizes.MEDIUM}
        />
        <IconButton
          btnType={IconBtnTypes.ICON_ONLY}
          icon="fas fa-angles-down"
          btnSize={IconBtnSizes.MEDIUM}
        />
      </div>

      <div className="migracoes-selecionadas p-col-12 p-d-flex p-flex-column">
        {selectedDestino ? (
          !isMobile ? (
            <PacienteRow item={selectedDestino} />
          ) : (
            <PacienteRowMobile item={selectedDestino} />
          )
        ) : (
          <div className="p-d-flex p-jc-center p-ai-center">
            <SimpleText
              fontSize={FONT_SIZE.SM}
              fontColor={FONT_COLOR.COLOR_40}
              medium
              className="p-mt-3 p-mb-3 p-text-center"
            >
              Não há destino selecionado
            </SimpleText>
          </div>
        )}
      </div>
    </div>
  );
}

const PacienteRow = ({ item }: any) => {
  const { isTablet } = useSize();

  const getCpfOrPassaporte = (item: any) => {
    if (item?.cpf) return formatCPF(item?.cpf);
    if (item?.passaporte) return item?.passaporte;
    return 'Não informado';
  };

  return (
    <>
      <Header />
      <div className={'p-grid p-col-12 p-p-0 p-ai-center origem'}>
        <div className="p-col-3 p-d-flex p-ai-center">
          <Avatar
            className="avatar p-mr-2"
            label={item?.paciente?.nome}
            image={item?.paciente?.fotoUrl || ''}
          />

          <div className="p-d-flex p-flex-column p-ml-1 p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {item?.paciente?.nome}
            </SimpleText>
            <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
              {item?.paciente?.id} -{' '}
              {item?.paciente?.dataNascimento
                ? dayjs(item?.paciente?.dataNascimento).format('DD/MM/YYYY')
                : 'Não informado'}
            </SimpleText>
          </div>
        </div>
        <div className="p-col-1 p-md-2 p-d-flex p-ai-center p-jc-between">
          {!isTablet && (
            <SimpleText
              className="p-mr-1"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {item?.nomeMae}
            </SimpleText>
          )}
          <IconButton
            className="atendimentos-info"
            icon="fas fa-clipboard-question"
            btnType={IconBtnTypes.ICON_ONLY}
            overlay={
              <div className="p-grid detalhe-atendimento-migracao">
                <div className="p-col-12 p-d-flec p-jc-center">
                  <SimpleText
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                  >
                    Informações de atendimento:
                  </SimpleText>
                </div>
                <div className="p-col-12 p-grid p-py-1">
                  <div className="p-col-2 p-py-1">
                    <SimpleText
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_60}
                      medium
                    >
                      Número
                    </SimpleText>
                  </div>
                  <div className="p-col-5 p-py-1">
                    <SimpleText
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_60}
                      medium
                    >
                      Data ocorrência
                    </SimpleText>
                  </div>
                  <div className="p-col-5 p-py-1">
                    <SimpleText
                      fontSize={FONT_SIZE.XXXS}
                      fontColor={FONT_COLOR.COLOR_60}
                      medium
                    >
                      Nome médico
                    </SimpleText>
                  </div>
                </div>
                {item?.atendimentos?.map((atendimento: any, index: any) => (
                  <div
                    key={index}
                    className="p-col-12 p-grid detalhe-atendimento p-mb-1 p-py-1"
                  >
                    <div className="p-col-2 p-py-1">
                      <SimpleText
                        fontSize={FONT_SIZE.XXXS}
                        fontColor={FONT_COLOR.COLOR_16}
                      >
                        {atendimento?.id}
                      </SimpleText>
                    </div>
                    <div className="p-col-5 p-py-1">
                      <SimpleText
                        fontSize={FONT_SIZE.XXXS}
                        fontColor={FONT_COLOR.COLOR_16}
                      >
                        {atendimento?.dataOcorrencia
                          ? dayjs(new Date(atendimento?.dataOcorrencia)).format(
                              'DD/MM/YYYY HH:mm',
                            )
                          : 'Não informado'}
                      </SimpleText>
                    </div>
                    <div className="p-col-5 p-py-1">
                      <SimpleText
                        fontSize={FONT_SIZE.XXXS}
                        fontColor={FONT_COLOR.COLOR_16}
                      >
                        {atendimento?.nomeMedico}
                      </SimpleText>
                    </div>
                  </div>
                ))}
              </div>
            }
          />
        </div>
        <div
          title={item?.cartaoConvenio || 'Não informado'}
          className="p-col-3 p-md-2 p-text-truncate"
        >
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {item?.cartaoConvenio || 'Não informado'}
          </SimpleText>
        </div>
        <div
          title={item?.cns || 'Não informado'}
          className="p-col-2 p-text-truncate"
        >
          <SimpleText
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {item?.cns || 'Não informado'}
          </SimpleText>
        </div>
        <div className="p-col-3">
          <div className="column">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {getCpfOrPassaporte(item)}
            </SimpleText>
          </div>
        </div>
      </div>
    </>
  );
};

const PacienteRowMobile = ({ item }: any) => {
  const getCpfOrPassaporte = (item: any) => {
    if (item?.cpf) return formatCPF(item?.cpf);
    if (item?.passaporte) return item?.passaporte;
    return 'Não informado';
  };

  return (
    <>
      <div className="p-col-12 origem">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {item?.paciente?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome da mãe
        </SimpleText>
        <br />
        <SimpleText
          className="p-mr-1"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {item?.nomeMae}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Cartão
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {item?.cartaoConvenio || 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          CNS
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {item?.cns || 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          CPF/Passaporte
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {getCpfOrPassaporte(item)}
        </SimpleText>
        <br />
      </div>
    </>
  );
};
