import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';

import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
} from 'src/components/Timeline/Timeline';

import { AssistenteVirtualCreateFormConteudo } from './AssistenteVirtualCreateFormConteudo';
import { AssistenteVirtualCreateFormGeral } from './AssistenteVirtualCreateFormGeral';

import './AssistenteVirtualCreate.scss';

export const AssistenteVirtualCreate = () => {
  const { id } = useParams();

  const [data, setData] = useState({});

  const timelineRef = useRef<TimelineImperativeAPI>(null);

  useEffect(() => {
    if (id) {
      AssistenciaAPI.getAssistenciasById(id!, {
        throwError: true,
      })
        .then(data => {
          setData(data);
        })
        .catch(() => {});
    }
  }, [id]);

  const handleSubmitFormGeral = (value: any) => {
    setData({ ...data, ...value });
    timelineRef.current?.next?.();
  };

  const handleGoBack = () => {
    timelineRef.current?.previous?.();
  };

  return (
    <Page>
      <>
        <PageHeader
          title="Nova assistência do sistema"
          subtitle="Edite os campos de texto e vínculos dos protocolos"
        />

        <Page content white>
          <Timeline
            ref={timelineRef}
            values={[
              {
                title: 'Geral',
                component: (
                  <AssistenteVirtualCreateFormGeral
                    data={data}
                    handleSubmit={handleSubmitFormGeral}
                  />
                ),
              },
              {
                title: 'Conteúdo',
                component: (
                  <AssistenteVirtualCreateFormConteudo
                    data={data}
                    handleGoBack={handleGoBack}
                  />
                ),
                disabled: !Object.keys(data)?.length,
              },
            ]}
          />
        </Page>
      </>
    </Page>
  );
};
