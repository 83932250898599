import { useState, useEffect, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';
import VerticalTab, { TabItem } from 'src/components/VerticalTab/VerticalTab';

import Doencas from './Doencas';
import GlicemiaTtgo from './GlicemiaTtgo';
import HiHb from './HiHb';
import LeucocitosPlaquetas from './LeucocitosPlaquetas';
import Pff from './Pff';
import TshT4 from './TshT4';
import Urocult from './Urocult';
import validationSchema from './validationSchema';

const Reagentes = () => {
  const { preNatalAtual } = usePreNatal();
  const { atendimentoStatus } = useAtendimento();

  const [tabNameActive, setTabNameActive] = useState('HI e HB');
  const [loading, setLoading] = useState(true);

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema(tabNameActive)),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useFormMethods;

  const loadReagentes = useCallback(async () => {
    if (!preNatalAtual?.id) return;

    setLoading(true);

    const response = await PreNatalAPI.getReagentes(preNatalAtual?.id);

    if (response?.status === 200) {
      for (const key in response.data) {
        if (response.data[key] === null) {
          delete response.data[key];
        }
      }

      reset(response.data);
    }

    setLoading(false);
  }, [preNatalAtual?.id, reset]);

  useEffect(() => {
    loadReagentes();
  }, [loadReagentes]);

  const onChangeTab = (tabItem: TabItem) => {
    setTabNameActive(tabItem.label);
  };

  const onSubmit = async (data: any) => {
    if (!preNatalAtual?.id) return;

    await PreNatalAPI.updateReagente(preNatalAtual?.id, data);
  };

  const renderReagente = (children: JSX.Element) => (
    <FormProvider {...useFormMethods}>
      <form
        className="reagente-container p-grid p-col-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        {children}

        {errors.atLeastOneOf?.message && (
          <SimpleText className="p-col-12 error" fontSize={FONT_SIZE.XXXS}>
            {errors.atLeastOneOf?.message}
          </SimpleText>
        )}

        <div className="p-col-12 p-mt-3">
          <Button
            type="submit"
            className="p-col-12"
            btnType="tonal"
            label="Salvar"
            loading={isSubmitting}
            disabled={atendimentoStatus !== 'ATENDENDO'}
          />
        </div>
      </form>
    </FormProvider>
  );

  if (loading)
    return (
      <div className="p-col-12">
        <GridListLoading />
      </div>
    );

  return (
    <VerticalTab
      filter
      filterLabel="Reagentes"
      onChange={onChangeTab}
      tabItens={[
        {
          label: 'HI e HB',
          content: renderReagente(<HiHb />),
        },
        {
          label: 'Leucócitos e plaquetas',
          content: renderReagente(<LeucocitosPlaquetas />),
        },
        {
          label: 'Glicemia e TTGO',
          content: renderReagente(<GlicemiaTtgo />),
        },
        {
          label: 'TSH e T4',
          content: renderReagente(<TshT4 />),
        },
        {
          label: 'Urocult',
          content: renderReagente(<Urocult />),
        },
        {
          label: 'PFF',
          content: renderReagente(<Pff />),
        },
        {
          label: 'Doenças',
          content: renderReagente(<Doencas />),
        },
      ]}
    />
  );
};

export default Reagentes;
