import { verificarParametos } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import SwitchStatus from './componets/SwitchStatus';

interface DesktopRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  calculoPacientes: (data: any) => void;
}

const DesktopRow = (props: DesktopRowProps) => {
  const { data, onEdit, onDelete, calculoPacientes } = props;

  return (
    <SearchList.CardItem>
      <div className="p-col-2">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.apelido}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {data?.periodo}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {calculoPacientes(data?.grupoAtendimentoPacientes)}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.nomeTipoGrupo}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <div>
          <SimpleText
            className={`p-col-2 personalizacao ${
              data?.quantidadeAgendamentos > 0 ? 'personal' : ''
            }`}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {verificarParametos(data?.quantidadeAgendamentos)}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-1">
        <SwitchStatus data={data} />
      </div>

      <div className="p-col-1 p-d-flex p-px-0 p-gap-2">
        <Button
          checkPermission="AGE_GRUPO_DE_ATENDIMENTO_ALTERAR"
          btnType="gray"
          icon="fas fa-edit"
          onClick={() => onEdit(data)}
        />

        <Button
          checkPermission="AGE_GRUPO_DE_ATENDIMENTO_EXCLUIR"
          btnType="gray"
          icon="fas fa-trash"
          onClick={() => onDelete(data)}
        />
      </div>
    </SearchList.CardItem>
  );
};

export default DesktopRow;
