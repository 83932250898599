import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
} from 'src/components/Timeline/Timeline';

import { AplicacaoFormularioCreateGeral } from './AplicacaoFormularioCreateGeral';
import { AplicacaoFormularioCreateVinculo } from './AplicacaoFormularioCreateVinculos';

export const AplicacaoFormularioCreate = () => {
  const [item, setItem] = useState<IAplicacaoFormularioDinamicoItem | null>(
    null,
  );

  const { query } = useAppSelector(state => state);
  const { id } = useParams();
  const { state } = useLocation();

  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSetItem = (value: IAplicacaoFormularioDinamicoItem) => {
    setItem(value);
    timelineRef.current?.goToStep?.(1);
  };

  const { invalidateAplicacoes } = query;

  const fetchAplicacaoItem = useCallback(
    async (id: string) => {
      try {
        const response =
          await AplicacaoFormularioDinamicoAPI.loadAplicacaoFormularioById({
            id: Number(id),
          });
        setItem(response);
      } catch {
        navigate('/cadastros/formulario-dinamico/aplicacao');
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (id) fetchAplicacaoItem(id);
  }, [fetchAplicacaoItem, id]);

  useEffect(() => {
    if (invalidateAplicacoes && id) {
      fetchAplicacaoItem(id);
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, fetchAplicacaoItem, id, invalidateAplicacoes]);

  useEffect(() => {
    if (state?.step === 1) {
      timelineRef.current?.goToStep?.(1);
    }
  }, [state]);

  const title = !item
    ? 'Nova aplicação formulário'
    : state?.to === 'edit'
    ? 'Edição da aplicação de formulários'
    : 'Visualização da aplicação de formulários';

  return (
    <Page>
      <>
        <PageHeader
          title={title}
          subtitle="Crie e edite os formulários"
          hasBreadcrumbs
        />

        <Page content white>
          <Timeline
            ref={timelineRef}
            values={[
              {
                title: 'Geral',
                component: (
                  <AplicacaoFormularioCreateGeral
                    item={item}
                    handleSetItem={handleSetItem}
                    handleNextPage={() => timelineRef.current?.next?.()}
                  />
                ),
              },
              {
                title: 'Vínculos',
                component: (
                  <AplicacaoFormularioCreateVinculo
                    item={item!}
                    handleGoBack={() => timelineRef.current?.previous?.()}
                  />
                ),
                disabled: !item,
              },
            ]}
          />
        </Page>
      </>
    </Page>
  );
};
