import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

interface SolicitacaoItemProps {
  solicitacao: any;
  index: number;
  updateSolicitacao: (solicitacao: any, index: any) => void;
  handleRemove: (idx: number) => void;
}
const SolicitacaoItem = ({
  solicitacao,
  index,
  updateSolicitacao,
  handleRemove,
}: SolicitacaoItemProps) => {
  return (
    <div className="p-d-flex p-jc-between p-p-2 p-px-4 p-my-1">
      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Código - Descrição
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {solicitacao.servicoTuss?.codigo || solicitacao.idServicoTuss} -{' '}
          {capitalizeFirstLetter(solicitacao.servicoTuss?.nome) ||
            solicitacao.nome}
        </SimpleText>
      </div>

      <div className="p-d-flex p-gap-2">
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Quantidade
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            {solicitacao.quantidade}
          </SimpleText>
        </div>
        <Button
          btnType="gray"
          icon="pi pi-pencil"
          onClick={() => updateSolicitacao(solicitacao, index)}
          type="button"
        />
        <Button
          btnType="gray"
          icon="pi pi-trash"
          onClick={() => handleRemove(index)}
          type="button"
        />
      </div>
    </div>
  );
};

export default SolicitacaoItem;
