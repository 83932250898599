import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import {
  GetContrarreferenciaItem,
  GetEncaminhamentoItem,
  GetEncaminhamentos,
} from 'src/models/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  capitalizeFirstLetter,
  preventDefaultAndStopPropagation,
} from 'src/utils/utils';

import { EmptyData } from '../../components/EmptyData';
import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tooltip from 'src/components/Tooltip/Tooltip';

import {
  DialogInativacaoEncaminhamento,
  DialogVisualizarInativacaoEncaminhamento,
} from './DialogInativacaoEncaminhamento';

import './Encaminhamentos.scss';

export const HistoricoEncaminhamentos = () => {
  const [encaminhamentos, setEncaminhamentos] = useState<GetEncaminhamentos[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  const { selectedAtendimento } = useHistoricoAtendimento();

  const fetchApi = useCallback(() => {
    setLoading(true);
    AtendimentoEncaminhamentoAPI.loadEncaminhamentos(
      selectedAtendimento.idAtendimento,
      { somenteAtendimento: true },
      {
        throwError: true,
      },
    )
      .then(data => !!data?.length && setEncaminhamentos(data))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [selectedAtendimento.idAtendimento]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  const itemGrouped = useMemo(() => {
    // Reduce para agrupar os itens em um array de arrays
    const data = encaminhamentos.reduce((groupedItems: any[], currentItem) => {
      if (currentItem.tipo === 'Contrarreferencia') {
        // Procura o encaminhamento correspondente
        const encaminhamento = encaminhamentos.find(
          itemEnc => itemEnc.id === currentItem.idEncaminhamento,
        );

        if (encaminhamento) {
          groupedItems.push([encaminhamento, currentItem]);
        } else {
          groupedItems.push([currentItem]);
        }
      } else {
        const contra = encaminhamentos.find(
          itemContra => itemContra.idEncaminhamento === currentItem.id,
        );

        if (!contra) {
          groupedItems.push([currentItem]);
        }
      }

      return groupedItems;
    }, []);

    return data;
  }, [encaminhamentos]);

  return (
    <div className="historico-encaminhamentos p-d-flex p-flex-column p-gap-3">
      <SimpleText className="p-w-100" bold>
        Encaminhamento
      </SimpleText>

      <div className="content-historico-enc">
        {loading ? (
          <SpinnerLoading full />
        ) : (
          <>
            {!!itemGrouped.length ? (
              <div className="row-container">
                {itemGrouped.map((item, idx) => (
                  <div key={idx} className="row-content">
                    {!!item?.length &&
                      item.map((item: any, idx: number) => (
                        <Accordion key={idx}>
                          <AccordionTab
                            header={
                              <AccordionHeader refetch={fetchApi} item={item} />
                            }
                          >
                            <AccordionContent item={item} />
                          </AccordionTab>
                        </Accordion>
                      ))}
                  </div>
                ))}
              </div>
            ) : (
              <div className="content-empty-row">
                <EmptyData />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const AccordionHeader = ({
  item,
  refetch,
}: {
  item: GetEncaminhamentos;
  refetch: () => void;
}) => {
  const { isHistoricoAssinatura } = useHistoricoAtendimento();
  const { isTablet } = useSize();
  const menuEllipsis = useRef<TieredMenu>(null);
  const [contra, setContra] = useState<GetContrarreferenciaItem>();

  const dialogInativacao = useDisclosure({ opened: false });
  const dialogShowInativacao = useDisclosure({ opened: false });

  const isEncaminhamento = item?.tipo === 'Encaminhamento';
  const isInativo = item.status === 'INATIVO';

  useEffect(() => {
    if (!isEncaminhamento)
      AtendimentoEncaminhamentoAPI.loadContrarreferenciaById(
        String(item.id),
      ).then(d => setContra(d));
  }, [isEncaminhamento, item.id]);

  if (isEncaminhamento) {
    return (
      <div className={`row p-d-flex ${isInativo ? 'inativo' : ''}`}>
        <div className="p-col-2 p-sm-2">
          <SimpleText className="tag tag-e">Encaminhamento</SimpleText>
        </div>

        {!isTablet && (
          <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Tipo de encaminhamento
            </SimpleText>
            <SimpleText>{item.descricaoTipoEncaminhamento}</SimpleText>
          </div>
        )}

        <div className="p-d-flex p-flex-column p-gap-1 p-col-3 p-md-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Especialidade
          </SimpleText>
          <SimpleText>{item.nomeEspecialidade}</SimpleText>
        </div>

        {!isTablet && (
          <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Médico
            </SimpleText>
            <SimpleText>{item.nomeMedico}</SimpleText>
          </div>
        )}

        <div className="p-d-flex p-flex-column p-gap-1 p-col-5 p-md-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Encaminhado em:
          </SimpleText>
          <SimpleText>
            {dayjs(item.dataEncaminhamento).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
        </div>

        <div className="p-col p-d-flex p-jc-start p-jc-md-end">
          {isInativo ? (
            <Button
              icon="fas fa-eye"
              btnType="gray"
              onClick={e => [
                dialogShowInativacao.open({
                  state: { justificativa: item.justificativaInativacao },
                }),
                preventDefaultAndStopPropagation(e),
              ]}
            />
          ) : (
            <>
              {!isHistoricoAssinatura && (
                <Button
                  icon="fas fa-ellipsis-h"
                  btnType="gray"
                  onClick={e => [
                    menuEllipsis.current?.toggle(e),
                    preventDefaultAndStopPropagation(e),
                  ]}
                />
              )}
            </>
          )}

          <div onClick={preventDefaultAndStopPropagation}>
            {!isHistoricoAssinatura && (
              <TieredMenu
                className="panel-options-paciente"
                ref={menuEllipsis}
                model={[
                  {
                    label: 'Inativar',
                    className: 'error',
                    command: () => [
                      dialogInativacao.open({ state: item }),
                      menuEllipsis.current?.setState({ visible: false }),
                    ],
                  },
                ]}
                popup
              />
            )}
          </div>
        </div>

        {dialogInativacao.isOpen && (
          <DialogInativacaoEncaminhamento
            refetch={refetch}
            {...dialogInativacao}
          />
        )}

        {dialogShowInativacao.isOpen && (
          <DialogVisualizarInativacaoEncaminhamento {...dialogShowInativacao} />
        )}
      </div>
    );
  }

  return (
    <div className={`row p-d-flex ${isInativo ? 'inativo' : ''}`}>
      <div className="p-col-2 p-sm-2">
        <SimpleText className={'tag tag-c'}>Contrarreferencia</SimpleText>
      </div>

      {!isTablet && (
        <div className="p-d-flex p-flex-column p-gap-1 p-col-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Respondido
          </SimpleText>
          <SimpleText>{contra?.usuarioAtualizacao?.nome}</SimpleText>
        </div>
      )}

      <div className="p-d-flex p-flex-column p-gap-1 p-col-3 p-md-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Especialidade
        </SimpleText>
        <SimpleText>{item.nomeEspecialidade}</SimpleText>
      </div>

      <div className="p-d-flex p-flex-column p-gap-1 p-col-5 p-md-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Respondido em:
        </SimpleText>
        <SimpleText>
          {dayjs(contra?.dataAtualizacao).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>

      <div className="p-col p-d-flex p-jc-start p-jc-md-end">
        {isInativo ? (
          <Button
            icon="fas fa-eye"
            btnType="gray"
            onClick={e => [
              dialogShowInativacao.open({
                state: { justificativa: item.justificativaInativacao },
              }),
              preventDefaultAndStopPropagation(e),
            ]}
          />
        ) : (
          <>
            {!isHistoricoAssinatura && (
              <Button
                icon="fas fa-ellipsis-h"
                btnType="gray"
                onClick={e => [
                  menuEllipsis.current?.toggle(e),
                  preventDefaultAndStopPropagation(e),
                ]}
              />
            )}
          </>
        )}

        <div onClick={preventDefaultAndStopPropagation}>
          {!isHistoricoAssinatura && (
            <TieredMenu
              className="panel-options-paciente"
              ref={menuEllipsis}
              model={[
                {
                  label: 'Inativar',
                  className: 'error',
                  command: () => [
                    dialogInativacao.open({ state: item }),
                    menuEllipsis.current?.setState({ visible: false }),
                  ],
                },
              ]}
              popup
            />
          )}
        </div>
      </div>

      {dialogInativacao.isOpen && (
        <DialogInativacaoEncaminhamento
          refetch={refetch}
          {...dialogInativacao}
        />
      )}

      {dialogShowInativacao.isOpen && (
        <DialogVisualizarInativacaoEncaminhamento {...dialogShowInativacao} />
      )}
    </div>
  );
};

const AccordionContent = ({ item }: { item: GetEncaminhamentos }) => {
  const [encaminhamento, setEncaminhamento] =
    useState<GetEncaminhamentoItem | null>(null);
  const [contra, setContra] = useState<GetContrarreferenciaItem>();
  const [isLoading, setIsLoading] = useState(false);

  const isContrarreferencia = item?.tipo !== 'Encaminhamento';
  const isInativo = item.status === 'INATIVO';

  useEffect(() => {
    setIsLoading(true);

    if (item.id && !isContrarreferencia) {
      AtendimentoEncaminhamentoAPI.loadEncaminhamentoById(item.id.toString(), {
        throwError: true,
      })
        .then(data => setEncaminhamento(data))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    } else if (item.id && isContrarreferencia) {
      AtendimentoEncaminhamentoAPI.loadContrarreferenciaById(String(item.id))
        .then(d => [setContra(d), setEncaminhamento(d.encaminhamento)])
        .finally(() => setIsLoading(false));
    }
  }, [isContrarreferencia, item.id]);

  return (
    <div
      className={`card-encaminhamento-row p-gap-3 ${
        isInativo ? 'inativo' : ''
      }`}
    >
      {isLoading ? (
        <SpinnerLoading full />
      ) : (
        <>
          <div className="p-d-flex p-flex-column">
            <div className="p-col-12 p-grid p-ai-center p-p-0 p-mb-3">
              {isContrarreferencia && (
                <div className="p-w-100 p-grid p-d-flex p-mb-4 p-ai-center">
                  <div className="p-col-6 p-md-3 p-px-0 p-d-flex p-flex-column p-gap-1">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      fontSize={FONT_SIZE.XXS}
                    >
                      Periodicidade prevista para reavaliação
                    </SimpleText>
                    <SimpleText bold>
                      {capitalizeFirstLetter(contra?.chavePeriodicidade || '')}
                    </SimpleText>
                  </div>
                  <div className="p-col-2 p-px-0 p-d-flex p-flex-column p-gap-1">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      fontSize={FONT_SIZE.XXS}
                    >
                      Alta
                    </SimpleText>
                    <SimpleText bold>
                      {!!contra?.alta ? 'Sim' : 'Não'}
                    </SimpleText>
                  </div>
                  <div className="p-col-3 p-px-0 p-d-flex p-flex-column p-gap-1">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      fontSize={FONT_SIZE.XXS}
                    >
                      Fora da área de ação
                    </SimpleText>
                    <SimpleText bold>
                      {!!contra?.encaminhamentoExterno ? 'Sim' : 'Não'}
                    </SimpleText>
                  </div>
                  <div className="p-md-2 p-col-6 p-px-0 p-d-flex p-flex-column p-gap-1">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      fontSize={FONT_SIZE.XXS}
                    >
                      Primeira consulta
                    </SimpleText>
                    <div className="p-d-flex p-gap-1">
                      <i className="fa fa-calendar icon-calendar" />
                      <SimpleText>
                        {dayjs(contra?.dataPrimeiraConsulta).format(
                          'DD/MM/YYYY',
                        )}
                      </SimpleText>
                    </div>
                  </div>
                  <div className="p-md-2 p-col-6 p-px-0 p-d-flex p-flex-column p-gap-1">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      fontSize={FONT_SIZE.XXS}
                    >
                      Ultima consulta
                    </SimpleText>
                    <div className="p-d-flex p-gap-1">
                      <i className="fa fa-calendar icon-calendar" />
                      <SimpleText>
                        {dayjs(contra?.dataUltimaConsulta).format('DD/MM/YYYY')}
                      </SimpleText>
                    </div>
                  </div>
                </div>
              )}

              {!isContrarreferencia && (
                <>
                  <SimpleText className="p-mr-2" bold>
                    CID:{' '}
                  </SimpleText>

                  {encaminhamento?.cids.map(item => (
                    <>
                      <Tooltip target={`#${item.codigo}`} />
                      <SimpleText
                        className="tag-cids"
                        id={item.codigo}
                        key={item.codigo}
                        data-pr-tooltip={item.nome}
                        data-pr-position="top"
                      >
                        {item.codigo}
                      </SimpleText>
                    </>
                  ))}
                </>
              )}
            </div>

            <div className="p-d-flex p-flex-column p-my-2">
              <SimpleText bold>
                Resumo clínico/Resultado de exame(s) e procedimentos realizados
              </SimpleText>
              <SimpleText className={`row-content`}>
                {isContrarreferencia
                  ? contra?.resumoClinicoContrarreferencia
                  : encaminhamento?.resumoClinico || ''}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-my-2">
              <SimpleText bold>Hipótese diagnóstica</SimpleText>
              <SimpleText className={`row-content`}>
                {isContrarreferencia
                  ? contra?.hipoteseDiagnosticaContrarreferencia
                  : encaminhamento?.hipoteseDiagnostica || ''}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-my-2">
              <SimpleText bold>Questionamento</SimpleText>
              <SimpleText className={`row-content`}>
                {isContrarreferencia
                  ? contra?.questionamentoContrarreferencia
                  : encaminhamento?.questionamento || ''}
              </SimpleText>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
