export const proximosPacientesMesmoProfissional = [
  'ATENDENDO',
  'CONTINUAR',
  'TRIAGEM_FINALIZADA',
];

export const allowedStatusPaciente = (isEnfermeiro: boolean) => [
  'PRESENTE',
  isEnfermeiro && 'TRIAGEM',
  isEnfermeiro && 'ATENDENDO_TRIAGEM',
  isEnfermeiro && 'PRESCRICAO',
  isEnfermeiro && 'PRESCRICAO_EXECUCAO',
  isEnfermeiro && 'PRESCRICAO_PAUSADA',
];

export const ordenarProximosPacientes = (
  a: AtendimentoAgendamento,
  b: AtendimentoAgendamento,
  c: any,
) => {
  const { dataPresenca: dataPresencaA, dataAgendamento: dataAgendamentoA } = a;
  const { dataPresenca: dataPresencaB, dataAgendamento: dataAgendamentoB } = b;

  const itemA = c === 'dataAgendamento' ? dataAgendamentoA : dataPresencaA;
  const itemB = c === 'dataAgendamento' ? dataAgendamentoB : dataPresencaB;

  if (itemA < itemB) return -1;
  if (itemA > itemB) return 1;
  return 0;
};
