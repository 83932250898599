import { useCallback, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import MedicamentosUsoAPI from 'src/APIs/ProntuarioAPI/MedicamentosUsoAPI/MedicamentosUsoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalJustificativaInativacao from './ModalJustificativaInativacao/ModalJustificativaInativacao';
import ModalNovoMedicamento from './ModalNovoMedicamento/ModalNovoMedicamento';
import ModalVisualizarJustificativa from './ModalVisualizarJustificativa/ModalVisualizarJustificativa';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './MedicamentoUso.scss';

interface MedicamentoUsoProps {
  idPaciente: number | null;
}

function MedicamentosUso({ idPaciente }: MedicamentoUsoProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getInfoLembretes, atendimentoStatus } = useAtendimento();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [modalNovoMedicamentoVisible, setModalNovoMedicamentoVisible] =
    useState<boolean>(false);
  const [idMedicamentoInativacao, setIdMedicamentoInativacao] = useState<
    number | null
  >(null);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<MedicamentosUso | null>(null);
  const [editMedicamento, setEditMedicamento] =
    useState<MedicamentosUso | null>(null);
  const [updateList, setUpdateList] = useState<number>(0);

  const fetchAPI = useCallback(
    ({ query, ...rest }: any): Promise<any> => {
      return MedicamentosUsoAPI.getMedicamentosUso({
        idAtendimento,
        nomeMedicamento: query,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        ...rest,
      });
    },
    [idAtendimento],
  );

  const onEdit = (medicamento: MedicamentosUso) => {
    setEditMedicamento(medicamento);
    setModalNovoMedicamentoVisible(true);
  };

  const onAlterarLembrete = async (idMedicamento: number) => {
    const response = await MedicamentosUsoAPI.alterarMedicamentoLembrete(
      idMedicamento,
    );

    if (response?.status === 204) reloadList();
  };

  const onExcluir = async (idMedicamento: number) => {
    const response = await MedicamentosUsoAPI.removerMedicamento(idMedicamento);

    if (response?.status === 204) reloadList();
  };

  const onAlterarEmUso = async (idMedicamento: number) => {
    const response = await MedicamentosUsoAPI.alterarMedicamentoEmUso(
      idMedicamento,
    );

    if (response?.status === 204) reloadList();
  };

  const optionsMenu = (medicamento: MedicamentosUso) => (
    <PanelOptionsMenu
      ativo={medicamento.status !== 'INATIVO'}
      lembreteAtivo={medicamento.lembrete}
      idAtendimentoRegistro={medicamento.idAtendimento}
      idUsuarioCriacao={medicamento.idProfissionalSaude}
      onAlterarLembrete={() => onAlterarLembrete(medicamento.id)}
      onEdit={() => onEdit(medicamento)}
      onInativar={() => setIdMedicamentoInativacao(medicamento.id)}
      onExcluir={() => onExcluir(medicamento.id)}
      onVisualizarJustificativa={() => setVisualizarJustificativa(medicamento)}
    />
  );

  const renderRow = (medicamento: MedicamentosUso) => (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            medicamento={medicamento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            onAlterarEmUso={() => onAlterarEmUso(medicamento.id)}
            optionsMenu={optionsMenu(medicamento)}
          />
        )}
      >
        <PanelContent medicamento={medicamento} />
      </Panel>
    </div>
  );

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));
  };

  const onHideModalNovoMedicamento = () => {
    setEditMedicamento(null);
    setModalNovoMedicamentoVisible(false);
  };

  const toggleShowSearchInput = () => setShowSearchInput(prev => !prev);

  return (
    <div id="medicamento-uso-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Medicamentos em uso
            </SimpleText>

            <div className="p-d-flex p-ai-center">
              <Button
                className="p-mr-4"
                type="button"
                btnType="tonal"
                label="Adicionar medicamento em uso"
                icon="fas fa-plus"
                onClick={() => setModalNovoMedicamentoVisible(true)}
                disabled={
                  atendimentoStatus !== 'ATENDENDO' &&
                  atendimentoStatus !== 'ATENDENDO_TRIAGEM'
                }
              />

              <Button
                icon="fas fa-search"
                btnType="gray"
                onClick={toggleShowSearchInput}
              />
            </div>
          </div>

          {showSearchInput && (
            <div className="p-mb-5 p-mt-3">
              <SearchList.SearchInput
                className="p-col-12"
                placeholder="Pesquisar"
              />
            </div>
          )}

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalNovoMedicamento
        visible={modalNovoMedicamentoVisible}
        onHide={onHideModalNovoMedicamento}
        editMedicamento={editMedicamento}
        onSuccess={reloadList}
      />

      <ModalJustificativaInativacao
        idMedicamento={idMedicamentoInativacao}
        onHide={() => setIdMedicamentoInativacao(null)}
        onSuccess={reloadList}
      />

      <ModalVisualizarJustificativa
        medicamento={visualizarJustificativa}
        onHide={() => setVisualizarJustificativa(null)}
      />
    </div>
  );
}

export default MedicamentosUso;
