import { Control, Controller } from 'react-hook-form';

import Calendar, { CalendarProps } from '../../Calendar/Calendar';

export interface CalendarControlledProps extends Omit<CalendarProps, 'value'> {
  control: Control;
  name: string;
}

const CalendarControlled = ({
  name,
  control,
  onChange: onChangeField,
  ...rest
}: CalendarControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }: any) => (
        <Calendar
          {...rest}
          value={value}
          onBlur={onBlur}
          onChange={e => [onChange(e), onChangeField?.(e)]}
        />
      )}
    />
  );
};

export default CalendarControlled;
