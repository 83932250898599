/* eslint-disable @typescript-eslint/ban-types */
import React, {
  CSSProperties,
  forwardRef,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { CheckboxChangeParams } from 'primereact/checkbox';
import { MultiSelectDisplayType } from 'primereact/multiselect';
import { SelectButtonChangeParams } from 'primereact/selectbutton';

import {
  FormikConfig,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
  useFormik,
} from 'formik';
import lodash from 'lodash';

import { Checkbox } from 'src/components/_UI/Checkbox';
import { RadioButton } from 'src/components/_UI/RadioButton';
import Button, {
  BtnTypes,
  ButtonProps,
} from 'src/components/Basics/Button/Buttons';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import CheckboxList from 'src/components/Basics/Checkbox/Checkbox';
import CheckboxSearch from 'src/components/Basics/CheckboxSearch/CheckboxSearch';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import ImageRadioButton from 'src/components/Basics/ImageRadioButton/ImageRadioButton';
import MaskedInput from 'src/components/Basics/MaskedInput/MaskedInput';
import MultiSelect from 'src/components/Basics/MultiSelect/MultiSelect';
import PasswordInput, {
  PasswordInputProps,
} from 'src/components/Basics/PasswordInput/PasswordInput';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import TextInputMinute from 'src/components/Basics/TextInputMinute/TextInputMinute';
import VerificationCodeInput from 'src/components/Basics/VerificationCodeInput/VerificationCodeInput';
import ChipSelect from 'src/components/ChipSelect/ChipSelect';
import { generateInitialState } from 'src/components/Form/formUtils';
import ImageInput from 'src/components/ImageInput/ImageInput';
import SearchPaciente from 'src/components/SearchPaciente/SearchPaciente';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Separator, { SeparatorProps } from 'src/components/Separator/Separator';
import Switch from 'src/components/Switch/Switch';
import './Form.scss';

export enum FIELD {
  TEXT = 'Text',
  TEXT_INPUT = 'TextInput',
  TEXTAREA_INPUT = 'TextareaInput',
  MASKED_INPUT = 'MaskedInput',
  NUMBER_INPUT = 'TextInput',
  DATETIME_INPUT = 'DateTimeInput',
  DATE_INPUT = 'DateInput',
  DATE_RANGE_INPUT = 'DateRangeInput',
  ENABLE_DROPDOWN = 'EnableDropdown',
  HOUR_INPUT = 'HourInput',
  PASS_INPUT = 'PassInput',
  CHECKBOX = 'Checkbox',
  CHECKBOX_LIST = 'CheckboxList',
  CHECKBOX_SEARCH = 'CheckboxSearch',
  RADIO = 'RadioButton',
  RADIO_IMAGE = 'ImageRadioButton',
  SELECT_BUTTON = 'SelectButton',
  MULTI_SELECT = 'MultiSelect',
  BUTTON = 'Button',
  VERIFICATION_CODE = 'VerificationCode',
  CUSTOM_CONTENT = 'CustomContent',
  IMAGE_INPUT = 'ImageInput',
  DROPDOWN = 'Dropdown',
  SEPARATOR = 'Separator',
  CHIP_SELECT = 'ChipSelect',
  MINUTE_INPUT = 'NumberMinuteInput',
}
export type FieldType =
  | 'Text'
  | 'TextInput'
  | 'TextareaInput'
  | 'NumberInput'
  | 'MaskedInput'
  | 'PassInput'
  | 'Checkbox'
  | 'CheckboxList'
  | 'CheckboxSearch'
  | 'RadioButton'
  | 'ImageRadioButton'
  | 'SelectButton'
  | 'MultiSelect'
  | 'ImageInput'
  | 'Button'
  | 'VerificationCode'
  | 'CustomContent'
  | 'Dropdown'
  | 'EnableDropdown'
  | 'Separator'
  | 'DateInput'
  | 'DateTimeInput'
  | 'DateRangeInput'
  | 'HourInput'
  | 'ChipSelect'
  | 'NumberMinuteInput';

export enum DEPENDENCY_OPERATOR {
  EQUAL = 'equal',
  DIFFERENT = 'dif',
}
export type Field = {
  name: string;
  type: FieldType;
  label?: string;
  placeholder?: string;
  initial?: any;
  mask?: any;
  required?: boolean;
  disabled?: boolean;
  visible?: boolean;
  checked?: boolean;
  errorMsg?: string;
  options?: any[];
  slotChar?: string;
  keyfilter?: string;
  info?: string;
  maxLength?: number;
  autoResize?: boolean;
  display?: MultiSelectDisplayType;
  btnType?: BtnTypes;
  icon?: string;
  className?: string;
  classNameContainer?: string;
  value?: any;
  dependenceField?: string;
  dependenceValue?: any;
  dependenceOperator?: DEPENDENCY_OPERATOR;
  length?: number;
  customContent?: any;
  customOnChange?: Function;
  rows?: number;
  onClick?: any;
  style?: CSSProperties;
  selectionMode?: any;
  fontSize?: any;
  fontWeight?: any;
  margin?: any;
  id?: any;
  textInputType?: HTMLInputTypeAttribute;
  separatorProps?: SeparatorProps;
  passwordInputProps?: PasswordInputProps;
  minDate?: any;
};
interface FormProps
  extends Omit<FormikConfig<FormikValues>, 'initialValues' | 'onSubmit'> {
  fields: Field[][];
  title?: string;
  btns?: ButtonProps[];
  initialValues?: any | undefined;
  onSubmit?: any | undefined;
  buttonSubmitValidateDirty?: boolean;
  onChangeValues?: (values: any) => void;
}
const Form = (
  {
    fields,
    title,
    btns,
    onSubmit,
    initialStatus,
    buttonSubmitValidateDirty = true,
    onChangeValues,
    ...props
  }: FormProps,
  ref: any,
) => {
  const formik = useFormik({
    initialValues: initialStatus || generateInitialState(fields),
    onSubmit: onSubmit,
    ...props,
  });

  const [enableDropdown, setEnableDropdown] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    submit: async () => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        await formik.submitForm();
      } else {
        formik.setTouched(
          setNestedObjectValues<FormikTouched<FormikValues>>(errors, true),
        );
      }
    },
    getValues: () => {
      return formik.values;
    },
    setValue: (field_name: string, value: any) => {
      formik.setFieldValue(field_name, value);
    },
    clearValue: (fieldName: string) => {
      const field = formik.values?.[fieldName];
      if (field) {
        formik.setFieldValue(fieldName, undefined);
      }
    },
    complete: () => {
      formik.setSubmitting(false);
    },
  }));

  useEffect(() => {
    onChangeValues?.(formik.values);
  }, [formik.values]);

  const renderField = useCallback(
    (f: Field, grid: string) => {
      const commomProps = {
        name: f.name,
        key: f.name,
        required: f.required || false,
      };

      const error: any =
        formik.errors[f.name] && formik.touched[f.name]
          ? formik.errors[f.name]
          : '';
      const { dependenceField } = f;
      if (dependenceField) {
        const dependenceCurrentValue = formik.values?.[dependenceField || ''];
        const operator = f.dependenceOperator;
        let result;
        switch (operator) {
          case 'equal':
            result = lodash.eq(dependenceCurrentValue, f.dependenceValue);
            break;
          case 'dif':
            result = !lodash.eq(dependenceCurrentValue, f.dependenceValue);
            break;
          default:
            result = lodash.eq(dependenceCurrentValue, f.dependenceValue);
        }
        if (!result) {
          return <></>;
        }
      }

      switch (f.type) {
        case FIELD.TEXT:
          return (
            <SimpleText
              {...commomProps}
              style={(f.style, { fontWeight: f.fontWeight, margin: f.margin })}
              fontSize={f.fontSize}
              className={`${f.classNameContainer ?? grid}`}
            >
              {f.label}
            </SimpleText>
          );
        case FIELD.TEXT_INPUT:
          return (
            <TextInput
              {...commomProps}
              disabled={f.disabled}
              placeholder={f.placeholder}
              className={f.classNameContainer ?? `${grid}`}
              label={f.label}
              icon={f.icon}
              maxLength={f.maxLength}
              value={formik.values?.[f.name] || f.initial}
              errorMsg={error}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={f.style}
              type={f.textInputType}
            />
          );
        case FIELD.TEXTAREA_INPUT:
          return (
            <TextareaInput
              {...commomProps}
              rows={f.rows}
              placeholder={f.placeholder}
              className={`${grid}`}
              label={f.label}
              autoResize={f.autoResize || false}
              maxLength={f.maxLength}
              value={formik.values?.[f.name] || f.initial}
              errorMsg={error}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur}
              style={f.style}
            />
          );
        case FIELD.PASS_INPUT:
          return (
            <PasswordInput
              {...commomProps}
              {...f.passwordInputProps}
              placeholder={f.placeholder}
              className={`${grid}`}
              label={f.label}
              value={formik.values?.[f.name]}
              errorMsg={error}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
          );
        case FIELD.CHECKBOX:
          return (
            <Checkbox
              {...commomProps}
              className={f.classNameContainer ?? `${grid} ${f.className}`}
              label={f.label || ''}
              checked={formik.values?.[f.name] || f.value}
              value={formik.values?.[f.name] || f.value}
              onChange={(value: CheckboxChangeParams) => {
                formik.setFieldValue(f.name, value.target.checked, true);
                f.customOnChange?.(value.target);
              }}
            />
          );
        case FIELD.CHECKBOX_LIST:
          return (
            <CheckboxList
              {...f}
              className={`${grid} ${f.className}`}
              classNameContainer={f.classNameContainer}
              label={f.label}
              checked={formik.values?.[f.name] || false}
              value={formik.values?.[f.name] || false}
              onChange={(value: CheckboxChangeParams) => {
                formik.setFieldValue(f.name, value, true);
                f.customOnChange?.(value.target);
              }}
            />
          );
        case FIELD.CHECKBOX_SEARCH:
          return (
            <CheckboxSearch
              {...commomProps}
              className={`${grid} ${f.className}`}
              label={f.label}
              options={f.options}
              value={formik.values?.[f.name]}
              onChange={(value: any) => {
                formik.setFieldValue(f.name, value);
                f.customOnChange?.(value);
              }}
              //onBlur={formik.handleBlur}
            />
          );
        case FIELD.DROPDOWN:
          return (
            <Dropdown
              {...commomProps}
              className={f.className ?? `${grid}`}
              label={f.label}
              value={formik.values?.[f.name] ?? f.initial ?? f.value}
              options={f.options || []}
              errorMsg={error}
              onChange={(value: SelectButtonChangeParams) => {
                formik.setFieldValue(f.name, value.value, true);
                f.customOnChange?.(value.value);
              }}
              placeholder={f?.placeholder || ''}
              onHide={() => formik.setFieldTouched(f.name)}
            />
          );
        case FIELD.ENABLE_DROPDOWN:
          return (
            <div className="enable-dropdown">
              <div className="header-dropdown">
                <SimpleText {...commomProps} className={'bold'}>
                  {f.label}
                </SimpleText>
                <Switch
                  checked={enableDropdown}
                  onChange={e => setEnableDropdown(e.value)}
                />
              </div>
              {enableDropdown ? (
                f.name === 'paciente' ? (
                  <SearchPaciente
                    label=""
                    onSelect={value => {
                      formik.setFieldValue(f.name, value, true);
                    }}
                    value={formik.values?.[f.name]}
                  />
                ) : (
                  <Dropdown
                    {...commomProps}
                    className={`${grid}`}
                    // label={f.label}
                    value={formik.values?.[f.name]}
                    options={f.options || []}
                    errorMsg={error}
                    onChange={(value: SelectButtonChangeParams) => {
                      formik.setFieldValue(f.name, value.value, true);
                      f.customOnChange?.(value.value);
                    }}
                    onHide={() => formik.setFieldTouched(f.name)}
                  />
                )
              ) : null}
            </div>
          );
        case FIELD.SELECT_BUTTON:
          return (
            <SelectButton
              {...commomProps}
              className={`${grid} p-mt-2`}
              label={f.label}
              value={formik.values?.[f.name] || f.initial}
              options={f.options || []}
              onChange={(value: SelectButtonChangeParams) => {
                formik.setFieldValue(f.name, value.value, true);
                f.customOnChange?.(value.value);
              }}
              // onBlur={formik.handleBlur}
            />
          );
        case FIELD.RADIO:
          return f?.options?.map(
            ({ value, label, classNameContainer }, idx) => (
              <div
                key={idx}
                style={f.style}
                className={
                  f.classNameContainer ?? classNameContainer ?? `${grid} p-mb-1`
                }
              >
                <RadioButton
                  {...commomProps}
                  label={label}
                  name={label}
                  value={f.value || value}
                  checked={
                    value === f.value || formik.values?.[f.name] === value
                  }
                  onChange={async () => {
                    formik.setFieldValue(f.name, value, true);
                    f.customOnChange?.(value);
                  }}
                />
              </div>
            ),
          );
        case FIELD.RADIO_IMAGE:
          return (
            <ImageRadioButton
              className={`${grid}`}
              {...commomProps}
              customClassName={f.className}
              options={f.options || []}
              value={formik.values?.[f.name] || f.initial}
              onPress={(value: any) => {
                formik.setFieldValue(f.name, value, true);
                f.customOnChange?.(value);
              }}
            />
          );
        case FIELD.MASKED_INPUT:
          return (
            <MaskedInput
              className={`${grid} ${f.className}`}
              label={f.label}
              mask={f.mask}
              {...commomProps}
              value={formik.values?.[f.name] || f.initial}
              errorMsg={error}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
          );
        case FIELD.DATETIME_INPUT:
          return (
            <CalendarInput
              hideOnDateTimeSelect
              className={`${grid}`}
              {...commomProps}
              label={f.label}
              value={formik.values?.[f.name] || f.initial}
              id={f.id}
              name={f.name}
              showTime
              hourFormat="24"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              errorMsg={error}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur(f.name)}
            />
          );
        case FIELD.DATE_INPUT:
          return (
            <CalendarInput
              hideOnDateTimeSelect
              className={`${grid} ${f.classNameContainer}`}
              {...commomProps}
              label={f.label}
              value={formik.values?.[f.name] || f.initial}
              dateFormat="dd/mm/yy"
              minDate={f.minDate}
              mask="99/99/9999"
              errorMsg={error}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur(f.name)}
            />
          );
        case FIELD.DATE_RANGE_INPUT:
          return (
            <CalendarInput
              hideOnDateTimeSelect
              showIcon
              dateFormat="dd/mm/yy"
              selectionMode={f.selectionMode}
              className={`${grid}`}
              {...commomProps}
              label={f.label}
              value={formik.values?.[f.name]}
              mask="99/99/9999"
              errorMsg={error}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur(f.name)}
            />
          );
        case FIELD.HOUR_INPUT:
          return (
            <CalendarInput
              hideOnDateTimeSelect
              className={`${grid} ${f.classNameContainer}`}
              {...commomProps}
              label={f.label}
              value={formik.values?.[f.name]}
              showIcon
              showTime
              mask="99:99"
              timeOnly
              icon="fas fa-clock"
              errorMsg={error}
              onChange={e => {
                formik.setFieldValue(f.name, e.value, true);
                f.customOnChange?.(e.target.value);
              }}
              onBlur={formik.handleBlur(f.name)}
            />
          );
        case FIELD.MINUTE_INPUT:
          return (
            <TextInputMinute
              className={f.classNameContainer ?? `${grid}`}
              {...commomProps}
              // label={f.label}
              value={formik.values?.[f.name]}
              errorMsg={error}
              onChange={e => {
                formik.setFieldTouched(f.name, true, true);
                formik.setFieldValue(f.name, e.value, true);
                f.customOnChange?.(e.value);
              }}
              onValueChange={e => {
                formik.setFieldTouched(f.name, true, true);
                formik.setFieldValue(f.name, e.value, true);
                f.customOnChange?.(e.value);
              }}
            />
          );
        case FIELD.MULTI_SELECT:
          return (
            <MultiSelect
              className={`${grid}`}
              display={f.display || 'chip'}
              options={f.options || []}
              value={formik.values?.[f.name]}
              label={f.label}
              {...commomProps}
              errorMsg={error}
              onBlur={formik.handleBlur}
              onChange={e => {
                formik.handleChange(e);
                f.customOnChange?.(e.target.value);
              }}
            />
          );
        case FIELD.IMAGE_INPUT:
          return (
            <ImageInput
              className={`${grid} p-ml-0`}
              value={formik.values?.[f.name]}
              {...commomProps}
              errorMsg={error}
              onChange={(value: any) => {
                formik.setFieldValue(f.name, value, true);
                f.customOnChange?.(value);
              }}
            />
          );
        case FIELD.BUTTON:
          return (
            <div
              className={`${
                f.classNameContainer ? f.classNameContainer : `p-grid ${grid}`
              }`}
            >
              <Button
                {...commomProps}
                type={'button'}
                btnType={f.btnType || BtnTypes.GHOST}
                icon={f.icon}
                label={f.label}
                className={f.className}
                style={f.style}
                onClick={
                  f.onClick
                    ? f.onClick
                    : () => {
                        formik.setFieldValue(f.name, f.value);
                        f.customOnChange?.(f.value);
                      }
                }
              />
            </div>
          );
        case FIELD.VERIFICATION_CODE:
          return (
            <>
              <VerificationCodeInput
                {...commomProps}
                className={`${grid}`}
                length={f.length}
                label={f.label}
                name={f.name}
                errorMsg={error}
                setFieldTouched={formik.setFieldTouched}
                setFieldValue={formik.setFieldValue}
              />
            </>
          );
        case FIELD.SEPARATOR:
          return (
            <div className={`${grid} p-mb-0`}>
              <Separator {...f.separatorProps} />
            </div>
          );
        case FIELD.CHIP_SELECT:
          return (
            <ChipSelect
              {...commomProps}
              className={`${grid} ${f.className}`}
              label={f.label}
              options={f.options}
              value={formik.values?.[f.name]}
              errorMsg={error}
              onChange={(value: any[]) => {
                formik.setFieldTouched(f.name, true, true);
                formik.setFieldValue(f.name, value, true);
                f.customOnChange?.(value);
              }}
            />
          );
        case FIELD.CUSTOM_CONTENT:
          return <>{f.customContent}</>;
        default:
          return <></>;
      }
    },
    [enableDropdown, formik],
  );

  const renderRow = useCallback(
    (row: Field[]) => {
      const columnSize = 12 / row.length;
      return (
        <>
          {(row || []).map((f: Field) => {
            let grid = `p-field p-col-12 p-md-${columnSize}`;

            if (f.classNameContainer) {
              grid = `p-field ${f.classNameContainer}`;
            }

            if (f.visible || f.visible === undefined) {
              return renderField(f, grid);
            } else {
              return null;
            }
          })}
        </>
      );
    },
    [renderField],
  );

  return (
    <div className={'Form'}>
      {(title || title === '') && (
        <SimpleText fontSize={FONT_SIZE.XS}>{title}</SimpleText>
      )}

      <form
        className="p-fluid p-formgrid p-grid"
        onSubmit={formik.handleSubmit}
      >
        {fields.map((row: Field[], index: number) => (
          <React.Fragment key={index}>{renderRow(row)}</React.Fragment>
        ))}
        {(btns || [])?.length > 0 && (
          <div className={'btns p-col-12 p-grid'}>
            {(btns || []).map(
              ({ onSubmit, type, ...props }: ButtonProps, index: number) => {
                return (
                  <div
                    key={index.toString()}
                    className={
                      props.className ? props.className : 'btn p-col-12'
                    }
                  >
                    <Button
                      {...props}
                      type={type}
                      name={props.name}
                      disabled={
                        type === 'submit' &&
                        (!formik.isValid ||
                          (buttonSubmitValidateDirty === true && !formik.dirty))
                      }
                      loading={formik.isSubmitting}
                    />
                  </div>
                );
              },
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default forwardRef(Form);
