import { memo } from 'react';

import { Image } from 'primereact/image';

import EmedLogo from 'src/assets/imgs/emed_logo_text.svg';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { getNotificationTimeDistance } from './helpers';
import { NotificationsList } from './MainMenuNotification';

interface PreviewNotificacaoProps {
  notificacao: NotificationsList | null;
  visible: boolean;
  onClose: () => void;
}

const PreviewNotificacao: React.FC<PreviewNotificacaoProps> = ({
  notificacao,
  visible,
  onClose,
}: PreviewNotificacaoProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const openLink = () => {
    window.open(notificacao?.dadosComplementares?.linkExterno, '_blank');
  };

  return (
    <div>
      <Dialog
        header={'Visualizar comunicados'}
        visible={visible}
        className={'dialog-preview-notificacao'}
        style={{ width: '90vw', maxWidth: 640 }}
        onHide={onClose}
      >
        <div className={'notification-dialog'}>
          {notificacao?.dadosComplementares?.imagem && (
            <div className={'notification-dialog__image'}>
              <img
                alt={'Imagem da notificação'}
                src={notificacao.dadosComplementares.imagem}
                onClick={() =>
                  notificacao?.dadosComplementares?.flagLinkImagem
                    ? openLink()
                    : () => {}
                }
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  cursor: notificacao?.dadosComplementares?.flagLinkImagem
                    ? 'pointer'
                    : 'default',
                }}
              />
            </div>
          )}
          <div className="notification-dialog__content">
            <div className={'avatar p-d-flex p-jc-center p-ai-center'}>
              <Image
                alt={'Logo comunicado'}
                src={notificacao?.icone || user.logoUrl || EmedLogo}
                width="100%"
              />
            </div>

            <div className={'notification-dialog__item'}>
              <div className={'notification-dialog__title'}>
                <SimpleText
                  className={`system-notification-time-ago-text ${FONT_COLOR.COLOR_60}`}
                  fontSize={FONT_SIZE.XXXXS}
                >
                  {getNotificationTimeDistance(notificacao?.dataCriacao ?? '')}
                </SimpleText>
                <SimpleText fontSize={FONT_SIZE.XS}>
                  {notificacao?.titulo}
                </SimpleText>
              </div>
              <div>
                <SimpleText
                  className={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  {notificacao?.conteudo}
                </SimpleText>
              </div>

              {notificacao?.dadosComplementares?.textoLink &&
                !notificacao?.dadosComplementares?.flagLinkImagem && (
                  <div onClick={() => openLink()}>
                    <SimpleText
                      className={'dialog-text-link'}
                      fontSize={FONT_SIZE.XXS}
                    >
                      {notificacao.dadosComplementares.textoLink}
                    </SimpleText>
                  </div>
                )}
            </div>
          </div>
          <div>
            <Button
              stretch
              label={'Fechar'}
              onClick={onClose}
              btnType="ghost"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default memo(PreviewNotificacao);
