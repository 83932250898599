import { useCallback, useEffect, useState } from 'react';

export const useScrollPosition = () => {
  const [scrollY, setY] = useState({
    top: '240px',
  });

  const handleScrollPosition = useCallback(() => {
    // Pegando o valor "top" do iframe
    const scroll = Object.values(scrollY)?.[0]?.replace(/[^0-9]/g, '');
    // Valor base "top" do iframe
    const scrollBaseNumber = 240;
    // Definindo o valor "top" do iframe baseado no pageYOffset
    const positionScrolled = scrollBaseNumber - window.pageYOffset;

    if (positionScrolled >= 130) {
      setY({ top: `${positionScrolled}px` });
    } else {
      Number(scroll) > 130 ? setY({ top: '130px' }) : null;
    }
  }, [scrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollPosition, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, [handleScrollPosition]);

  return { scrollY };
};
