import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  funcionalidade: Funcionalidade;
  onConfig: (funcionalidade: Funcionalidade) => void;
  onSwitchStatus: (funcionalidade: Funcionalidade) => void;
}

export const Row = (props: RowProps) => {
  const { funcionalidade, onConfig, onSwitchStatus } = props;

  const { isMobile } = useSize();

  const handleConfig = () => {
    onConfig(funcionalidade);
  };

  const handleSwitchStatus = () => {
    onSwitchStatus(funcionalidade);
  };

  const commonProps = {
    funcionalidade,
    onConfig: handleConfig,
    onSwitchStatus: handleSwitchStatus,
  };

  return isMobile ? (
    <MobileRow {...commonProps} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};
