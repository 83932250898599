import { useEffect } from 'react';

import { Control, useFormContext } from 'react-hook-form';

import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface PesquisaComplementarProps {
  readOnly?: boolean | undefined;
  values?: Questionarios | undefined;
}

enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  SEM_DOR = 'SEM_DOR',
  LIGEIRA = 'DOR_LIGEIRA',
  MODERADA = 'DOR_MODERADA',
  INTENSA = 'DOR_INTENSA',
  MAXIMA = 'DOR_MASSA',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

const PesquisaComplementar = ({
  values,
  readOnly,
}: PesquisaComplementarProps) => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();

  const PesquisaNaoSeAplica = watch('PesquisaNaoSeAplica');

  useEffect(() => {
    const fieldsToUpdate = [
      'realizouMamografia',
      'realizouPapanicolau',
      'realizouColonoscopia',
      'realizouSangueOcultoFezes',
    ];

    if (typeof PesquisaNaoSeAplica === 'boolean') {
      const valueNaoSeAplica = PesquisaNaoSeAplica
        ? Values.NAO_SE_APLICA
        : undefined;

      fieldsToUpdate.forEach(field => {
        setValue(field, valueNaoSeAplica, {
          shouldValidate: PesquisaNaoSeAplica,
        });
      });
    }
  }, [setValue, PesquisaNaoSeAplica]);

  return (
    <Template
      title="Pesquisa complementar"
      name="PesquisaNaoSeAplica"
      readOnly={readOnly}
      resetField={resetField}
      control={control}
    >
      <div className="container-pesquisa">
        <CardQuestions
          title="Se mulher acima de 40 anos, realizou exame de mamografia atualmente?"
          name="realizouMamografia"
          control={control}
          disabled={readOnly || PesquisaNaoSeAplica}
          defaultValue={values?.realizouMamografia}
          error={errors?.realizouMamografia?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            unstyled
            name="realizouMamografia"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || PesquisaNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Se mulher acima de 40 anos, realizou exame de papanicolau no último ano?"
          name="realizouPapanicolau"
          control={control}
          disabled={readOnly || PesquisaNaoSeAplica}
          defaultValue={values?.realizouPapanicolau}
          error={errors?.realizouPapanicolau?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="realizouPapanicolau"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || PesquisaNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Se acima de 50 anos, realizou colonoscopia no último ano?"
          name="realizouColonoscopia"
          control={control}
          disabled={readOnly || PesquisaNaoSeAplica}
          defaultValue={values?.realizouColonoscopia}
          error={errors?.realizouColonoscopia?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="realizouColonoscopia"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || PesquisaNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Se acima de 50 anos, realizou exame de sangue oculto nas fezes no último ano?"
          name="realizouSangueOcultoFezes"
          control={control}
          disabled={readOnly || PesquisaNaoSeAplica}
          defaultValue={values?.realizouSangueOcultoFezes}
          error={errors?.realizouSangueOcultoFezes?.message}
        >
          <RadioButtonGroupControlled
            unstyled
            control={control}
            name="realizouSangueOcultoFezes"
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || PesquisaNaoSeAplica}
          />
        </CardQuestions>
      </div>
    </Template>
  );
};

export default PesquisaComplementar;
