import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  lembrete: any;
  onEdit: (lembrete: GetLembreteAgendamentoDTO) => void;
  onDelete: (id: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { lembrete, onEdit, onDelete } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(lembrete),
      checkPermission: 'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(lembrete.id),
      checkPermission: 'ADM_CONFIGURACAO_LEMBRETE_CONSULTA_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          ID
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {lembrete.id}
        </SimpleText>
      </div>

      <div className="column p-mt-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Mensagem
        </SimpleText>

        <SimpleText
          className="p-mt-2 ellipsis"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {lembrete.mensagem}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
