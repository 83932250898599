import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  servicoTuss: any;
  fabricanteDropdown: JSX.Element;
  inputQuantidade: JSX.Element;
  onDelete: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { servicoTuss, fabricanteDropdown, inputQuantidade, onDelete } = props;

  return (
    <div className="pacote-servico-tuss-item p-grid p-align-center">
      <SimpleText
        className="p-col-2 p-lg-1"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XXS}
        medium
      >
        {servicoTuss.codigo}
      </SimpleText>

      <SimpleText
        className="p-col-4 p-lg-5"
        fontColor={FONT_COLOR.COLOR_40}
        fontSize={FONT_SIZE.XXS}
      >
        {servicoTuss.nome}
      </SimpleText>

      <div className="p-col-3 p-lg-4">{fabricanteDropdown}</div>

      <div className="p-col p-d-flex p-jc-between p-ai-center">
        {inputQuantidade}
        <Button
          type="button"
          className="p-col-1"
          btnType="gray"
          icon="fas fa-trash"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};
