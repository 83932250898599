import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  consultorio: any;
  onEdit: (consultorio: any) => void;
  onDelete: (consultorio: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { consultorio, onEdit, onDelete } = props;
  const menu = useRef<any>(null);

  const itemsOptions = [
    {
      label: 'Editar',
      command: () => onEdit(consultorio),
      checkPermission: 'ADM_CONSULTORIO_ALTERAR',
    },
    {
      label: 'Excluir',
      command: () => onDelete(consultorio),
      checkPermission: 'ADM_CONSULTORIO_EXCLUIR',
      className: 'error',
    },
  ];

  return (
    <>
      <div className="p-col-4">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {consultorio?.nome}
        </SimpleText>
      </div>
      <div className="p-col-3 p-lg-4">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {consultorio?.cnes}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-3">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
            {consultorio?.contributors}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2 p-lg-1 p-d-flex p-jc-end">
        <Button
          className="p-mr-1"
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={event => menu?.current?.toggle(event)}
        />

        <TieredMenu
          className="tiered-menu"
          model={itemsOptions}
          popup
          ref={menu}
        />
      </div>
    </>
  );
};
