import dayjs from 'dayjs';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useBuscaRapidaDeleteProcedimento } from '../hooks/useBuscaRapidaDeleteProcedimento';
import { useBuscaRapidaDisfavorProcedimento } from '../hooks/useBuscaRapidaDisfavorProcedimento';

enum TipoProcedimento {
  GRUPO = 'G',
  FAVORITO = 'F',
}

export const BuscaRapidaProcedimentoPanelHeader = (props: any) => {
  const {
    options,
    isSelected,
    procedimento,
    onEdit,
    onRemoveItemFromList,
    onSelect,
  } = props;

  const { isDeleting, handleDeleteProcedimento } =
    useBuscaRapidaDeleteProcedimento();
  const { isDisfavoring, handleDisfavorProcedimento } =
    useBuscaRapidaDisfavorProcedimento();

  const modalConfirmDisclosure = useDisclosure({ opened: false });

  const isProcedimentoGrupo = procedimento.tipo === TipoProcedimento.GRUPO;
  const isProcedimentoFavorito =
    procedimento.tipo === TipoProcedimento.FAVORITO;

  const formatFraseAlert = ({
    dataUltimaRealizacao,
    dataUltimaSolicitacao,
  }: any) => {
    if (dataUltimaRealizacao) {
      const dataRealizacao = dayjs().diff(dataUltimaRealizacao, 'day');
      const diaPreffix = dataRealizacao > 1 ? 'dias' : 'dia';
      const diaText =
        dataRealizacao === 0 ? 'hoje' : `há ${dataRealizacao} ${diaPreffix}`;

      return `Última realização ${diaText}`;
    }

    const dataSolicitacao = dayjs().diff(dataUltimaSolicitacao, 'day');
    const diaPreffix = dataSolicitacao > 1 ? 'dias' : 'dia';
    const diaText =
      dataSolicitacao === 0 ? 'hoje' : `há ${dataSolicitacao} ${diaPreffix}`;

    return `Última solicitação ${diaText}`;
  };

  const renderActionBtns = () => {
    if (isProcedimentoGrupo) {
      return (
        <div className="p-d-flex p-ai-center">
          <Button
            type="button"
            btnType="gray"
            icon="pi pi-pencil"
            onClick={onEdit}
          />

          <Button
            type="button"
            btnType="gray"
            icon="pi pi-trash"
            onClick={() => modalConfirmDisclosure.open()}
            loading={isDeleting}
          />
        </div>
      );
    }

    if (isProcedimentoFavorito) {
      return (
        <Skeleton
          loading={isDisfavoring}
          height="0.8rem"
          width="0.8rem"
          borderRadius="50%"
        >
          <i
            className="favorite pi pi-star-fill"
            onClick={() =>
              handleDisfavorProcedimento(
                procedimento.idExameFavorito,
                onRemoveItemFromList,
              )
            }
          />
        </Skeleton>
      );
    }

    return null;
  };

  const toggleIcon = options.collapsed ? 'pi pi-angle-down' : 'pi pi-angle-up';

  return (
    <>
      <div className="busca-rapida-item-header">
        <div className="p-d-flex p-ai-center">
          <Checkbox checked={isSelected} onChange={onSelect} />

          <SimpleText
            className="solicitacao-exame-codigo"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {procedimento?.codigo}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
            {procedimento.nome}
          </SimpleText>

          {isProcedimentoGrupo && (
            <i className={toggleIcon} onClick={options.onTogglerClick} />
          )}
        </div>

        <div className="p-d-flex p-ai-center">
          {(procedimento?.dataUltimaSolicitacao ||
            procedimento?.dataUltimaRealizacao) && (
            <div className="p-d-flex p-ai-center p-mr-2">
              <i className="fas fa-exclamation-triangle p-mr-2" />
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {formatFraseAlert(procedimento)}
              </SimpleText>
            </div>
          )}

          {renderActionBtns()}
        </div>
      </div>
      <ConfirmDialog
        visible={modalConfirmDisclosure.isOpen}
        header={'Excluir procedimento'}
        text="O grupo de procedimentos será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => {
          modalConfirmDisclosure.close();
        }}
        onConfirm={() => {
          handleDeleteProcedimento(procedimento.id, onRemoveItemFromList);
        }}
      />
    </>
  );
};
