import { useState, useEffect } from 'react';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setProfissionaisDisponiveis } from 'src/core/redux/slices/agenda/AgendaSlice';
import {
  setConsultorioAtivo,
  setConsultorios,
} from 'src/core/redux/slices/user/ConsultoriosSlice';
import { RootState } from 'src/core/redux/store';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';

import './SelectConsultorio.scss';

const SelectConsultorio = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  const [consultorioQuery, setConsultorioQuery] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const handleSelecionarConsultario = async (v: any) => {
    if (consultorios.ativo?.id !== v.id) {
      dispatch(setConsultorioAtivo(v));
    }

    // Deixar com dois clicks
    document.getElementById('Main-menu')?.click();
    document.getElementById('Main-menu')?.click();

    const aux = await UsuarioAPI.loadProfissionaisByUserConsultorio(v.id);

    dispatch(setProfissionaisDisponiveis(aux));
  };

  useEffect(() => {
    const fetchConsultorios = async () => {
      setLoading(true);

      const response: any = await ConsultorioAPI.loadUserConsultorios();

      if (Array.isArray(response)) dispatch(setConsultorios(response));

      setLoading(false);
    };

    fetchConsultorios();
  }, [dispatch]);

  const clinicFiltered = consultorioQuery
    ? consultorios?.disponiveis.filter((consultorio: any) =>
        consultorio.nome.toLowerCase().includes(consultorioQuery.toLowerCase()),
      )
    : consultorios?.disponiveis;

  const render = () => {
    if (loading)
      return (
        <div className="clientes-panel">
          <GridListLoading />
        </div>
      );

    return (
      <div className="p-d-flex p-flex-column p-gap-1">
        {clinicFiltered &&
        Array.isArray(clinicFiltered) &&
        clinicFiltered.length > 0 ? (
          clinicFiltered.map((v: any) => (
            <div
              key={v.id}
              className="item-select"
              onClick={() => handleSelecionarConsultario(v)}
            >
              <SimpleText style={{ cursor: 'pointer' }}>{v.nome}</SimpleText>
            </div>
          ))
        ) : (
          <SimpleText>Nenhum consultório disponível</SimpleText>
        )}
      </div>
    );
  };

  return (
    <div className="p-d-flex p-flex-column overlay-consultorio">
      <TextInput
        icon="pi pi-search"
        onChange={e => setConsultorioQuery(e.target.value)}
        value={consultorioQuery}
        placeholder="Pesquisar por nome"
      />

      {render()}
    </div>
  );
};

export default SelectConsultorio;
