/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import { useNavigate } from 'react-router';

import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Button from 'src/components/Basics/Button/Buttons';
import Page from 'src/components/Basics/Page/Page';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalEditValoresServico from './ModalEditValoresServico/ModalEditValoresServico';
import ModalExcluirValoresServico from './ModalExcluirValoresServico/ModalExcluirValoresServico';
import ModalHistoricoValoresServico from './ModalHistoricoValoresServico/ModalHistoricoValoresServico';

import './ValoresServicoContent.scss';
import { Row } from './ValoresServicosGrid/Row';

const ValoresServicoContent = () => {
  const { user } = useAppSelector((state: RootState) => state);

  const navigate = useNavigate();

  const [modalHistoricoValorServico, setModalHistoricoValorServico] =
    useState(false);
  const [modalExcluirValoresServico, setModalExcluirValoresServico] =
    useState(false);
  const [modalEditValorServico, setModalEditValorServico] = useState(false);
  const [valorServico, setValorServico] = useState({
    id: null,
    consultoriosIds: [],
    descricao: '',
    status: false,
  });
  const [reload, setReload] = useState(0);

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const fetchApi = async (params: any): Promise<any> => {
    const data = await ServicoTussValorAPI.loadServicoTussValor({
      idEmpresa: user.idEmpresa,
      nomeServico: params.query,
      page: params.page,
      somenteVigentes: true,
    });

    return data;
  };

  const handleModalEditValoresServico = (item: any) => {
    setValorServico(item);
    setModalEditValorServico(true);
  };

  const handleModalEditValorServicoClose = () => {
    setModalEditValorServico(false);
  };

  const handleModalEditValorServicoSuccess = () => {
    setModalEditValorServico(false);
  };

  const handleModalExcluirValoresServicoClose = () => {
    setModalExcluirValoresServico(false);
  };

  const handleExcluirValoresServico = (item: any) => {
    setModalExcluirValoresServico(true);
    setValorServico(item || { id: '' });
  };

  const handleModalHistoricoValoresServico = (item: any) => {
    setValorServico(item || { id: '' });
    setModalHistoricoValorServico(true);
  };

  const handleModalHistoricoValoresServicoClose = () => {
    setModalHistoricoValorServico(false);
  };

  const renderRow = (data: any): JSX.Element => (
    <Row
      data={data}
      onHistorico={handleModalHistoricoValoresServico}
      onDelete={handleExcluirValoresServico}
      onEdit={handleModalEditValoresServico}
      reload={reloadList}
    />
  );

  return (
    <Page white content>
      <>
        <SearchList.Root
          fetchApi={fetchApi}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.BorderContainer>
            <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
              <SearchList.SearchInput placeholder="Digite a descrição do serviço" />

              <Button
                className="p-mb-3"
                label="Cadastrar valores de serviço"
                icon="fa fa-plus"
                onClick={() => navigate('/faturamento/novo-valores-servico')}
                checkPermission="ADM_SERVICOS_FATURAMENTO_CADASTRAR"
              />
            </div>

            <SearchList.Header>
              <Header />
            </SearchList.Header>

            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>

        <ModalEditValoresServico
          valorServico={valorServico}
          setValorServico={setValorServico}
          visible={modalEditValorServico}
          onHide={handleModalEditValorServicoClose}
          onSuccess={() => [
            handleModalEditValorServicoSuccess(),
            setTimeout(() => reloadList(), 500),
          ]}
        />

        {modalHistoricoValorServico && (
          <ModalHistoricoValoresServico
            valorServico={valorServico}
            visible={modalHistoricoValorServico}
            onHide={handleModalHistoricoValoresServicoClose}
          />
        )}

        {modalExcluirValoresServico && (
          <ModalExcluirValoresServico
            valorServico={valorServico}
            visible={modalExcluirValoresServico}
            onHide={handleModalExcluirValoresServicoClose}
            reload={reloadList}
          />
        )}
      </>
    </Page>
  );
};

export default ValoresServicoContent;
