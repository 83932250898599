import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  equipe: any;
  equipeStatus: JSX.Element;
  dataInclusao: string;
  onEdit: (equipe: any) => void;
  onDelete: (id: number) => void;
  profissionalResponsavel: any;
}

export const MobileRow = (props: MobileRowProps) => {
  const {
    equipe,
    equipeStatus,
    dataInclusao,
    onEdit,
    onDelete,
    profissionalResponsavel,
  } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(equipe),
      checkPermission: 'ADM_CARTEIRA_APS_EQUIPE_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(equipe?.id),
      checkPermission: 'ADM_CARTEIRA_APS_EQUIPE_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-grid">
        <div className="p-col-12">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome da equipe
          </SimpleText>
          <br />
          <SimpleText
            className="p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {equipe.nome}
          </SimpleText>
        </div>

        <div className="p-col-12 p-sm-4 p-px-0">
          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Profissional responsável
          </SimpleText>
          <br />
          <SimpleText
            className="p-col-12 p-wordbreak-all p-pb-0"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {profissionalResponsavel?.profissionalSaude.usuario.nome}
          </SimpleText>
          <br />
          <SimpleText
            className="p-col-12 p-wordbreak-all p-pt-0"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            {
              profissionalResponsavel?.profissionalSaude
                .conselhoTissProfissional?.descricao
            }{' '}
            • {profissionalResponsavel?.profissionalSaude.conselhoTiss?.sigla}{' '}
            {profissionalResponsavel?.profissionalSaude.conselhoTiss?.codigo}
          </SimpleText>
        </div>

        <div className="p-col-12 p-sm-4 p-px-0">
          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Grupo pontuação
          </SimpleText>
          <br />
          <SimpleText
            className="p-col-2 p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {equipe.pontuacao?.nome}
          </SimpleText>
        </div>

        <div className="p-col-12 p-sm-4 p-px-0">
          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Data de inclusão
          </SimpleText>
          <br />
          <SimpleText
            className="p-col-2 p-wordbreak-all"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {dataInclusao}
          </SimpleText>
        </div>

        <div className="p-col-12">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Status
          </SimpleText>
          <br />

          {equipeStatus}
        </div>
      </div>
    </SearchListMobileRow>
  );
};
