import { useState } from 'react';

import dayjs from 'dayjs';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ModalNovoQuestionario from 'src/pages/Emed/Prontuario/FichaPaciente/Questionarios/ModalNovoQuestionario/ModalNovoQuestionario';

interface PanelHeaderProps {
  questionario: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({ questionario }: PanelHeaderProps) => {
  const { selectedAtendimento } = useHistoricoAtendimento();

  const novoDisclosure = useDisclosure({ opened: false });

  const headerTextClassName =
    questionario?.status === 'INATIVO'
      ? 'p-mt-2 item-inativo cursor-pointer'
      : 'p-mt-2 cursor-pointer';

  return (
    <>
      <div
        className={
          questionario?.status === 'INATIVO'
            ? 'p-grid p-col-12 p-align-center continer-panel inativo'
            : 'p-grid p-col-12 p-align-center continer-panel'
        }
      >
        <div
          className="p-col-12 p-md-6 p-d-flex cursor-pointer"
          onClick={() =>
            novoDisclosure.open({
              state: { questionario: questionario, readOnly: true },
            })
          }
        >
          <i className="fas fa-file-medical-alt cursor-pointer" />

          <div className="p-d-flex p-flex-column p-ml-2">
            <SimpleText
              medium
              className="cursor-pointer"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.PRIMARY}
            >
              Avaliação enfermagem
            </SimpleText>

            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              Preenchido por {questionario?.usuario.nome}
            </SimpleText>
          </div>
        </div>

        <div className="p-col-6 p-md-3 p-d-flex p-flex-column">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Data de ocorrência
          </SimpleText>

          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
          >
            {dayjs(questionario?.dataOcorrencia).format('DD/MM/YYYY  HH:mm')}
          </SimpleText>
        </div>

        <div className="p-col-6 p-md-3 retroativo-chip">
          {questionario?.retroativo && (
            <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
          )}
        </div>
      </div>

      {novoDisclosure.isOpen && (
        <ModalNovoQuestionario
          idAtendimento={selectedAtendimento.idAtendimento}
          reloadList={() => {}}
          {...novoDisclosure}
        />
      )}
    </>
  );
};
