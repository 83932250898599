import { useState } from 'react';

import { useNavigate } from 'react-router';

import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import useSize from 'src/core/hooks/useSize';

import { Header } from './components/Header';
import AdvancedSearch from './components/SearchList/AdvancedSearch/AdvancedSearch';
import FilterSearch from './components/SearchList/FilterSearch/FilterSearch';
import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import CardPaciente from './CardPaciente/CardPaciente';

import './CentralPacientes.scss';

const CentralPacientes = () => {
  const navigate = useNavigate();

  const [reload, setReload] = useState<number>(0);
  const [idPacienteExcluir, setIdPacienteExcluir] = useState<number | null>(
    null,
  );

  const { isTablet } = useSize();

  const onConfirmExcluir = async () => {
    const response = await PacienteAPI.removePaciente(
      idPacienteExcluir as number,
    );

    if (response?.status === 204) {
      setReload(current => current + 1);
      setIdPacienteExcluir(null);
    }
  };

  const renderRow = (paciente: any) => (
    <CardPaciente
      paciente={paciente}
      onExcluir={setIdPacienteExcluir}
      refetchList={setReload}
    />
  );

  const fetchApi = async ({ query, ...rest }: any) => {
    return await PacienteAPI.pacientesPesquisaCompleta({
      chave: query,
      ...rest,
    });
  };

  return (
    <Page white id="central-pacientes">
      <>
        <PageHeader
          title="Pacientes"
          subtitle="Tenha uma visão detalhada sobre os pacientes."
        />

        <div className="p-px-4">
          <SearchList.Root
            fetchApi={fetchApi}
            empresaRequired
            reloadWhenEmpresaChange
            onlyQueryLength={3}
          >
            <div className="p-d-flex p-flex-column p-flex-md-row p-jc-start p-jc-md-between p-ai-center">
              <SearchList.SearchInput
                placeholder="Digite nome, nome da mãe, CPF ou a carteirinha"
                className="p-col-12 p-md-6 p-mb-2 p-px-0"
              />

              <AdvancedSearch />
            </div>

            <SearchList.BorderContainer>
              <div className="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-mb-3">
                <Button
                  type="button"
                  label="Incluir novo paciente"
                  onClick={() => {
                    navigate('/pacientes/novo');
                  }}
                  btnType="pill"
                  icon="fas fa-plus"
                  checkPermission="ADM_PACIENTE_CADASTRAR"
                  className="p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2"
                />

                <div className="p-d-flex p-jc-end p-ai-center p-gap-2 p-mt-1 p-w-100">
                  <SearchList.TotalCount medium />

                  <FilterSearch />
                </div>
              </div>

              <Header />

              <SearchList.InfiniteScroll
                renderRow={renderRow}
                reload={reload}
                customEmptyText="Utilize os filtros ou faça uma busca para encontrar os pacientes."
              />
            </SearchList.BorderContainer>
          </SearchList.Root>
        </div>

        <ConfirmDialog
          visible={!!idPacienteExcluir}
          header="Excluir paciente"
          text="O paciente será excluído da base de dados. Você realmente deseja excluir?"
          confirmText="Sim, excluir"
          onHide={() => setIdPacienteExcluir(null)}
          onConfirm={onConfirmExcluir}
        />
      </>
    </Page>
  );
};

export default CentralPacientes;
