import { useState, useEffect, useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

import './FormEndereco.scss';
import { useAgenda } from '../../../AgendaContext';

const FormEndereco = ({ values, handleCancel, idPaciente }: any) => {
  const { setEndereco } = useAgenda();
  const [paisSelecionado, setPaisSelecionado] = useState('');
  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState<any>([]);
  const [cidades, setCidades] = useState<any>([]);

  const schena = Yup.object().shape({
    idPais: Yup.string().required('País obrigatório'),
    idCidade: Yup.string().required('Cidade obrigatória'),
    cep: Yup.string().when('idPais', {
      is: (idPais: string) => idPais === '0',
      then: Yup.string().required('CEP obrigatório'),
    }),
    logradouro: Yup.string().required('Logradouro obrigatório'),
    bairro: Yup.string().required('Bairro obrigatório'),
    numero: Yup.string().required('Número obrigatório'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schena),
  });

  useEffect(() => {
    if (values) {
      const {
        cep,
        estado,
        cidade,
        idCidade,
        logradouro,
        complemento,
        numero,
        bairro,
      } = values;

      setValue(
        'idPais',
        values.idPaisEstrangeiro ? values.idPaisEstrangeiro : 0,
      );
      setValue('cep', values.endereco?.cep || cep);
      setValue('estado', values.endereco?.cidade?.estado?.sigla || estado);
      setValue('cidade', values.endereco?.cidade?.nome || cidade);
      setValue('idCidade', values.endereco?.cidade?.id || idCidade);
      setValue('logradouro', values.endereco?.logradouro || logradouro);
      setValue('complemento', values.endereco?.complemento || complemento);
      setValue('numero', values.endereco?.numero || numero);
      setValue('bairro', values.endereco?.bairro || bairro);
    }
  }, [setValue, values]);

  useEffect(() => {
    async function loadCep() {
      if (values.cep && values.cep.length === 9) {
        const resp = await UtilsAPI.GetAddressByCep(values.cep);
        if (resp !== undefined) {
          const { logradouro, bairro, nomeCidade, uf, idCidade, ddd } = resp;
          setValue('logradouro', logradouro);
          setValue('bairro', bairro);
          setValue('estado', uf);
          setValue('cidade', nomeCidade);
          setValue('idCidade', idCidade);
          setValue('ddd', ddd);
        }
      }
    }

    loadCep();
  }, [setValue, values.cep]);

  useEffect(() => {
    UtilsAPI.GetPaises().then(setPaises);
    UtilsAPI.GetEstados().then(setEstados);
  }, []);

  useEffect(() => {
    reset(values);
    if (values.idEstado) {
      UtilsAPI.GetCidades(values.idEstado).then(setCidades);
    }
  }, [reset, values]);

  const paisesOptions = useMemo(
    () =>
      paises?.map((pais: any) => ({
        label: pais?.descricao,
        value: pais?.id,
      })),
    [paises],
  );

  const estadosOptions = useMemo(
    () =>
      estados?.map((estado: any) => ({
        label: estado?.nome,
        value: estado?.id,
      })),
    [estados],
  );

  const cidadesOptions = useMemo(
    () =>
      cidades?.map((cidade: any) => ({
        label: cidade?.nome,
        value: cidade?.id,
      })),
    [cidades],
  );

  const salvarEndereco = async (data: any) => {
    const {
      idPais,
      bairro,
      cep,
      cidade,
      complemento,
      idCidade,
      numero,
      logradouro,
    } = data;

    const endereco = {
      idPais,
      bairro,
      cep,
      idCidade,
      descCidade: cidade,
      complemento,
      numero,
      indicadorEstrangeiro: +idPais !== 0,
      logradouro,
    };

    await PacienteAPI.editarEnderecoPaciente(idPaciente, endereco).then(
      (res: any) => {
        setEndereco(res.logradouro);
      },
    );
  };

  const handlePaisSelect = useCallback(async paisId => {
    setPaisSelecionado(paisId);
  }, []);

  const handleEstadoSelect = useCallback(async estadoId => {
    return new Promise(async resolve => {
      const cidades: any = await UtilsAPI.GetCidades(estadoId);

      if (Array.isArray(cidades)) setCidades(cidades);

      resolve(true);
    });
  }, []);

  const searchAddressByZipCode = async () => {
    const cep = getValues('cep');
    if (cep && cep.length === 9) {
      const resp = await UtilsAPI.GetAddressByCep(cep);

      if (resp !== undefined) {
        const {
          logradouro,
          bairro,
          nomeCidade,
          uf,
          idCidade,
          idEstado,
          complemento,
          ddd,
        } = resp;

        if (idEstado) {
          await handleEstadoSelect(idEstado);
        }

        setValue('logradouro', logradouro);
        setValue('bairro', bairro);
        setValue('complemento', complemento);
        setValue('estado', uf);
        setValue('cidade', nomeCidade);
        setValue('idEstado', idEstado);
        setValue('idCidade', idCidade);
        setValue('ddd', ddd);
        setValue('cep', cep);
      }
    }
  };

  const BrazilLocationForm = useCallback(
    () => (
      <div className="row">
        <DropdownControlled
          control={control}
          label="Estado"
          className="grid p-col-6 p-md-6 p-pl-0"
          customOnChange={handleEstadoSelect}
          options={estadosOptions}
          errorMsg={errors.idEstado?.message}
          name="idEstado"
        />

        <DropdownControlled
          control={control}
          label="Cidade"
          className="grid p-col-6 p-md-6"
          options={cidadesOptions}
          errorMsg={errors.idCidade?.message}
          name="idCidade"
        />
      </div>
    ),
    [cidadesOptions, control, errors, estadosOptions, handleEstadoSelect],
  );

  const ForeignLocationForm = useCallback(
    () => (
      <TextInputControlled
        control={control}
        label="Cidade"
        placeholder="Digite a cidade"
        className="grid p-col-12 p-md-12 p-pl-0"
        errorMsg={errors.descCidade?.message}
        name="descCidade"
      />
    ),
    [control, errors.descCidade?.message],
  );

  const LocationForm = useCallback(() => {
    if (!paisSelecionado) {
      return <BrazilLocationForm />;
    }

    return <ForeignLocationForm />;
  }, [BrazilLocationForm, ForeignLocationForm, paisSelecionado]);

  return (
    <div className={'FormEndereco'}>
      <form
        onSubmit={handleSubmit(data => {
          salvarEndereco(data);
        })}
      >
        <div className={'p-p-0 p-col-12 p-mt-2 p-mb-3'}>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
            medium
          >
            Endereço
          </SimpleText>
        </div>

        <DropdownControlled
          errorMsg={errors.idPais?.message}
          name="idPais"
          control={control}
          label="País"
          className="p-mb-3"
          options={paisesOptions}
          customOnChange={handlePaisSelect}
        />

        <SimpleText
          className="p-pb-2"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
        >
          Digite o CEP para preencher automaticamente os campos
        </SimpleText>

        <div className="p-col-12 p-grid p-ai-center p-p-0">
          <div className="p-col-8 p-pl-0">
            <MaskedInputControlled
              errorMsg={errors.cep?.message}
              name="cep"
              control={control}
              label="CEP"
              className="p-mb-2"
              mask={MASK.CEP}
            />
          </div>
          <div className="p-col-4 p-pb-0 p-mt-2 p-pr-0">
            <Button
              onClick={() => searchAddressByZipCode()}
              label="Pesquisar"
              btnType={BtnTypes.TONAL}
              style={{ height: 38.74 }}
              className="p-col-12"
              type="button"
              disabled={+paisSelecionado !== 0}
            />
          </div>
        </div>

        <div className="p-grid p-col-12 p-p-0 p-mb-3">
          <div className="p-col-12 p-p-0">{LocationForm()}</div>
        </div>

        <TextInputControlled
          errorMsg={errors.logradouro?.message}
          name="logradouro"
          control={control}
          label="Logradouro"
          className="p-mb-3"
        />

        <TextInputControlled
          className="p-mb-3"
          errorMsg={errors.bairro?.message}
          name="bairro"
          control={control}
          label="Bairro"
        />

        <div className="p-grid p-col-12 p-p-0 p-mb-4">
          <div className="p-col-6 p-p-0">
            <TextInputControlled
              errorMsg={errors.complemento?.message}
              name="complemento"
              control={control}
              label="Complemento"
              className="p-mr-2 p-mb-0"
            />
          </div>
          <div className="p-col-6 p-p-0">
            <TextInputControlled
              errorMsg={errors.numero?.message}
              name="numero"
              control={control}
              label="Número"
            />
          </div>
        </div>

        <div className="p-grid p-col-12 p-p-0 p-mb-1 p-jc-between">
          <div className={'p-col-4 p-p-0'}>
            <Button
              btnType={BtnTypes.GHOST}
              label="Cancelar"
              type="button"
              style={{ width: '100%' }}
              onClick={handleCancel}
            />
          </div>
          <div className={'p-col-8 p-p-0'}>
            <Button
              btnType={BtnTypes.FILLED}
              label="Salvar"
              type="submit"
              loading={isSubmitting}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormEndereco;
