import { useCallback, useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { formatFraseAlert } from './utils';

import { Button, Checkbox } from 'src/components/_UI';
import { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { FavoriteButton } from './FavoriteButton';

import './FieldMultiselectServicoTuss.scss';

type Props = {
  disabled?: boolean;
  handleAddProcedimentos: (items: any[]) => void;
  canFavorite?: boolean;
  params: {
    idConvenio: number | null | undefined;
    idAtendimento?: number;
    opme?: boolean;
  };
};

export const FieldMultiselectServicoTuss = ({
  disabled,
  handleAddProcedimentos,
  params: { idConvenio, idAtendimento, opme = false },
  canFavorite,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [servicosTussSelected, setServicosTussSelected] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { invalidateServicosTuss } = useAppSelector(state => state.query);

  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const handleClearSearchInput = () => setSearchQuery('');
  

  const fetchServicosTuss = useCallback(
    (query: string) => {
      setIsLoading(true);
      ServicoTussCRUDAPI.getSolicitacaoExame(
        {
          codigoNomeApelido: query,
          idConvenio,
          idAtendimento,
          opme,
        },
        { throwError: true },
      )
        .then(d => {
          const itemsSorted = d.sort((a: any, b: any) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          });

          return setItems(itemsSorted);
        })
        .catch(() => {})
        .finally(() => [
          setIsLoading(false),
          !!invalidateServicosTuss && dispatch(resetInvalidateQuery()),
        ]);
    },
    [dispatch, idAtendimento, idConvenio, invalidateServicosTuss, opme],
  );

  useEffectSkipFirst(() => {
    if (!searchQuery.length) setItems([]);
    if (searchQuery.length >= 3) fetchServicosTuss(searchQuery);
  }, [fetchServicosTuss, searchQuery]);

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const handleSelect = (isChecking: boolean, item: any) => {
    if (isChecking) return setServicosTussSelected(old => [...old, item]);

    setServicosTussSelected(old => old.filter(serv => serv.id !== item.id));
  };

  return (
    <>
      <div ref={searchInputContainerRef}>
        <div className="solicitacoes-procedimentos-search TextInputSearch p-w-100">
          <span
            className={`p-input-icon-left ${
              searchQuery ? 'p-input-icon-right' : ''
            }`}
          >
            <i className="pi pi-search" style={{ marginRight: 5 }} />
            <TextInput
              placeholder="Pesquisar"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onClick={e => searchPanelRef.current?.show(e, null)}
              onFocus={e => searchPanelRef.current?.show(e, null)}
              disabled={disabled}
            />
            {!!searchQuery && (
              <i className="pi pi-times" onClick={handleClearSearchInput} />
            )}
          </span>
        </div>
      </div>

      <OverlayPanel
        className="solicitacoes-procedimentos-panel"
        ref={searchPanelRef}
        dismissable={false}
        appendTo="self"
        onHide={() => searchQuery.length && fetchServicosTuss(searchQuery)}
      >
        <div className="p-grid" ref={searchPanelContainerRef}>
          <div className="solicitacoes-procedimentos-content p-col-12">
            {isLoading ? (
              <LoadingComp />
            ) : items?.length ? (
              items.map(item => (
                <Item
                  key={item.id}
                  procedimento={item}
                  onSelect={(e: boolean) => handleSelect(e, item)}
                  isSelected={servicosTussSelected.includes(item)}
                  canFavorite={canFavorite}
                />
              ))
            ) : !searchQuery.length || searchQuery.length < 3 ? (
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Digite para pesquisar
              </SimpleText>
            ) : (
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Nenhum resultado encontrado
              </SimpleText>
            )}
          </div>

          <div className="p-col-12">
            <Button
              type="button"
              btnType={BtnTypes.TONAL}
              label="Adicionar Selecionados"
              onClick={() => [
                handleAddProcedimentos(servicosTussSelected),
                searchPanelRef?.current?.hide(),
                setServicosTussSelected([]),
              ]}
              disabled={!servicosTussSelected.length}
              stretch
            />
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

export const Item = (props: any) => {
  const { isSelected, procedimento, onSelect, canFavorite } = props;

  return (
    <div className="solicitacao-procedimento-item">
      <div className="solicitacao-procedimento-item-data p-d-flex p-ai-center p-w-100">
        <Checkbox checked={isSelected} onChange={e => onSelect(e.checked)} />

        <SimpleText
          className="solicitacao-exame-codigo"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {procedimento?.codigo}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {procedimento.nome}
        </SimpleText>

        <div className="p-ml-auto">
          {(procedimento.dataUltimaSolicitacao ||
            procedimento.dataUltimaRealizacao) && (
            <SimpleText
              className="p-mr-3"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              <i className="fas fa-exclamation-triangle p-mr-2" />
              {formatFraseAlert(procedimento)}
            </SimpleText>
          )}

          {canFavorite && (
            <FavoriteButton
              isSelected={procedimento.favorito}
              idProcedimento={procedimento.id}
              idExameFavorito={procedimento.idExameFavorito}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingComp = () => {
  return (
    <div className="p-d-flex p-flex-column p-gap-1">
      <Skeleton loading borderRadius="6px" height="40px" />
      <Skeleton loading borderRadius="6px" height="40px" />
      <Skeleton loading borderRadius="6px" height="40px" />
    </div>
  );
};
