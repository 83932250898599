import Page from 'src/components/Basics/Page/Page';

import './GrupoTuss.scss';
import PageHeader from 'src/components/PageHeader/PageHeader';

import GrupoTussGlobal from './GrupoTussGlobal/GrupoTussGlobal';

const GrupoTuss = () => {
  return (
    <Page className={'GrupoTuss'}>
      <>
        <PageHeader
          title={'Grupos TUSS'}
          subtitle={
            'Gerencie os grupos TUSS que serão utilizados nos serviços TUSS e TISS'
          }
        />
        <GrupoTussGlobal />
      </>
    </Page>
  );
};

export default GrupoTuss;
