import Http, { HttpParams } from 'src/core/http/Http';

export const getProvedorBiometria = (
    idAtendimento: string,
    options?: Omit<HttpParams, 'url' | 'service'>,
) => {
    return Http.get({
        service: 'pep',
        url: `/biometria-facial/provedor/${idAtendimento}`,
        return: true,
        hideToast: true,
        throwError: true,
        ...options,
    });
}

export const getBiometriaUnivision = (
    idAtendimento: string,
    options?: Omit<HttpParams, 'url' | 'service'>,
) => {
    return Http.get({
        service: 'pep',
        url: `/biometria-facial/${idAtendimento}`,
        hideToast: true,
        throwError: true,
        ...options,
    });
}


export const BiometriaAPI = {
    getProvedorBiometria,
    getBiometriaUnivision,
}