import { useForm, FormProvider } from 'react-hook-form';

import dayjs from 'dayjs';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import {
  Filter as SearchListFilter,
  SearchInput,
} from 'src/components/SearchList/SearchListRoot';

const tipoDocumentoOptions = [
  { label: 'Encaminhamento', value: 'ENCAMINHAMENTO' },
  { label: 'Contrarreferência', value: 'CONTRARREFERENCIA' },
  { label: 'Guia SADT', value: 'GUIA_SADT' },
  { label: 'Guia Resumida de Exames', value: 'GUIA_RESUMIDA_EXAMES' },
  { label: 'Exportação de Prontuários', value: 'EXPORTACAO_PRONTUARIOS' },
];

const statusOptions = [
  { label: 'Enviado', value: 'ENVIADO' },
  { label: 'Erro', value: 'ERRO' },
  { label: 'Reenviado', value: 'REENVIADO' },
  { label: 'Erro reenvio', value: 'ERRO_REENVIO' },
];

export const Filter = () => {
  const useFormMethods = useForm();

  const { getValues, reset, control, watch } = useFormMethods;

  const watchDataFim = watch('dataFim');
  const watchDataInicio = watch('dataInicio');

  const handleFilter = () => {
    const {
      tipoDocumento = [],
      status = [],
      dataInicio,
      dataFim,
      nomeBeneficiario,
      cartaoBeneficiario,
      nomeProfissionalSaude,
    } = getValues();

    return {
      tipoDocumento: tipoDocumento.toString(),
      status: status.toString(),
      dataInicio: dataInicio
        ? dayjs(new Date(dataInicio)).format('YYYYMMDD')
        : '',
      dataFim: dataFim ? dayjs(new Date(dataFim)).format('YYYYMMDD') : '',
      nomeBeneficiario,
      cartaoBeneficiario,
      nomeProfissionalSaude,
    };
  };

  const handleClearFilters = () => {
    reset();
  };

  return (
    <SearchListFilter
      onFilter={handleFilter}
      onClearFilters={handleClearFilters}
    >
      <FormProvider {...useFormMethods}>
        <form className="p-grid">
          <SimpleText medium className="p-col-12 p-pb-0">
            Período
          </SimpleText>
          <div className="p-col-6 ">
            <CalendarInputControlled
              control={control}
              name="dataInicio"
              maxDate={watchDataFim || new Date()}
              placeholder="dd/mm/aaaa"
              showIcon
            />
          </div>
          <div className="p-col-6 ">
            <CalendarInputControlled
              control={control}
              name="dataFim"
              minDate={watchDataInicio}
              maxDate={new Date()}
              placeholder="dd/mm/aaaa"
              showIcon
            />
          </div>
          <div className="p-col-12 ">
            <TextInputControlled
              control={control}
              name="nomeBeneficiario"
              icon="fa fa-search"
              placeholder="Nome do beneficiario"
            />
          </div>
          <div className="p-col-12 ">
            <TextInputControlled
              control={control}
              name="cartaoBeneficiario"
              icon="fa fa-search"
              placeholder="Cartão do beneficiario"
              permitirApenasNumeros
            />
          </div>
          <div className="p-col-12 ">
            <TextInputControlled
              control={control}
              name="nomeProfissionalSaude"
              icon="fa fa-search"
              placeholder="Profissional"
            />
          </div>
          <div className="p-col-6">
            <MultiSelectControlled
              control={control}
              name="tipoDocumento"
              options={tipoDocumentoOptions}
              placeholder="Selecione"
              label="Tipo de documento"
            />
          </div>
          <div className="p-col-6">
            <MultiSelectControlled
              control={control}
              name="status"
              options={statusOptions}
              placeholder="Selecione"
              label="Status"
            />
          </div>
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};
