import { createContext, useContext, useState } from 'react';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

const FaturamentoContext = createContext({} as any);

export function SelectedFaturamentosProvider({children}: any) {
  const [selectedFaturamentos, setSelectedFaturamentos] = useState([]);
  const [reload, setReload] = useState(0);

  return (
    <FaturamentoContext.Provider
      value={{
        selectedFaturamentos,
        setSelectedFaturamentos,
        reload,
        setReload,
      }}
    >
      {children}
    </FaturamentoContext.Provider>
  );
}

export function useSelectedFaturamentos() {
  const context = useContext(FaturamentoContext);

  return context;
}

