import Http, { HttpParams } from 'src/core/http/Http';

import { Paginator } from 'src/components/Table/Table';

const basePath = '/equipe-carteira-aps/consultorio';

export const getCabecalhoInfo = (
  idConsultorio: number,
  idCuidador: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'pep',
    url: `/carteira-aps/consultorio/${idConsultorio}/carteira/${idCuidador}`,
    hideToast: true,
    ...options,
  });
};

export const getSearchPacientes = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'pep',
    url: '/carteira-aps/pacientes-sem-vinculo',
    params,
    hideToast: true,
    ...options,
  });
};

export const getHistoricoTransferencia = (
  idConsultorio: number,
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<PacientesCarteiraDTO>> => {
  return Http.get({
    service: 'pep',
    url: `carteira-aps/consultorio/${idConsultorio}/historico-movimentacoes/${idPaciente}`,
    hideToast: true,
    ...options,
  });
};

export const getPacientesCarteira = (
  idConsultorio: number,
  idCuidador: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<PacientesCarteiraDTO>> => {
  return Http.get({
    service: 'pep',
    url: `carteira-aps/consultorio/${idConsultorio}/carteira/${idCuidador}/pacientes`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getPacientes = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<PacientesCarteiraDTO>> => {
  return Http.get({
    service: 'pep',
    url: `carteira-aps/consultorio/${params.idConsultorio}/carteira/pacientes`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getMotivos = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'adm',
    url: '/motivo-transferencia-aps',
    params,
    hideToast: true,
    ...options,
  });
};

export const getEquipeCarteira = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `/carteira-aps/consultorio/${params.idConsultorio}/carteira/todas`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getEquipeCarteiraById = (
  id: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${idConsultorio}/equipe/${id}`,
    hideToast: true,
    ...options,
  });
};

export const postEquipeCarteira = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/${data.idConsultorio}/equipe`,
    data,
    return: true,
    ...options,
  });
};

export const postTrasferenciaPaciente = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `/carteira-aps/consultorio/${data.idConsultorio}/transferir-pacientes`,
    data,
    return: true,
    ...options,
  });
};

export const postTransferenciaCarteira = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `/carteira-aps/consultorio/${data.idConsultorio}/transferir-carteira`,
    data,
    return: true,
    ...options,
  });
};

export const putEquipeCarteira = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${data.idConsultorio}/equipe/${data.id}`,
    data,
    return: true,
    ...options,
  });
};

export const putIncluirPaciente = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `/carteira-aps/consultorio/${data.idConsultorio}/carteira/${data.idCuidador}/incluir`,
    data: { ids: data.ids },
    hideToast: true,
    return: true,
    ...options,
  });
};

export const putExcluirPaciente = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `/carteira-aps/consultorio/${data.idConsultorio}/carteira/${data.idCuidador}/excluir`,
    data,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const putEquipeCarteiraStatus = (
  id: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${idConsultorio}/equipe/${id}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const deleteEquipeCarteira = (
  id: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${idConsultorio}/equipe/${id}`,
    ...options,
  });
};

export const MovimentacaoCarteiraAPI = {
  getCabecalhoInfo,
  postTrasferenciaPaciente,
  postTransferenciaCarteira,
  getHistoricoTransferencia,
  putIncluirPaciente,
  getSearchPacientes,
  getPacientes,
  getMotivos,
  getPacientesCarteira,
  putExcluirPaciente,
  getEquipeCarteira,
  getEquipeCarteiraById,
  postEquipeCarteira,
  putEquipeCarteira,
  deleteEquipeCarteira,
};
