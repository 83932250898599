import { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { MotivoTransferenciaAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/MotivoTransferenciaAPI';

import useDebounce from 'src/utils/useDebounce';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type Props = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldMotivoTransferencia = ({
  name = 'motivoTransferencia',
  label,
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const load = async () => {
    const response = await MotivoTransferenciaAPI.getMotivoList();

    if (!!response?.length) setItems(response);

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      loading={loading}
      options={items}
      errorMsg={errors?.[name]?.message}
      customOnChange={rest.onChange ? rest.onChange : () => null}
      labelOverflow="hidden"
      optionLabel="nome"
      {...rest}
    />
  );
};
