import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import TipoContaPagarAPI from 'src/APIs/AdminAPI/TipoContaPagarAPI/TipoContaPagarAPI';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import GridListLoading from 'src/components/GridList/GridListLoading';
import PageHeader from 'src/components/PageHeader/PageHeader';

import FormTipoContaPagar from './FormTipoContaPagar';

const ManterTipoContaPagar = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Page className={''}>
      <>
        <PageHeader
          title={
            params.id
              ? 'Editar tipo de conta a pagar'
              : 'Novo tipo de conta a pagar'
          }
          subtitle={'Preencha os campo para completar o cadastro'}
        />
        <Page content white className="p-pt-2">
          <>
            <Button
              className="btn-voltar p-mb-2 p-ml-2"
              btnType="ghost"
              id="back-button"
              label="Voltar"
              icon="fa fa-arrow-left"
              onClick={() => navigate('/faturamento/tipo-conta-pagar')}
            />
            <FormTipoContaPagar />
          </>
        </Page>
      </>
    </Page>
  );
};

export default ManterTipoContaPagar;
