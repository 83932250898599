import React, { useMemo } from 'react';

import {
  ControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form';

import {
  TextInputProps,
  InputNumberProps,
  InputNumber,
  InputText,
} from 'src/components/Basics/Input';

import SimpleText, { FONT_COLOR } from '../Basics/SimpleText/SimpleText';
import Skeleton from '../Skeleton/Skeleton';

type FormInputComponentProps = TextInputProps &
  InputNumberProps & {
    ref: any;
    value?: any;
  };

type FormInputProps = Pick<ControllerProps, 'name' | 'defaultValue'> &
  Omit<FormInputComponentProps, 'ref'> & {
    loading?: boolean;
  };

const FormInputComponent = React.forwardRef<any, FormInputComponentProps>(
  ({ type = 'text', ...props }, ref) => {
    const types: { [key: string]: React.ReactElement } = useMemo(
      () => ({
        text: <InputText {...props} ref={ref} />,
        number: <InputNumber {...props} ref={ref} />,
      }),
      [props, ref],
    );

    const Component = useMemo(
      () => types[type] || <InputText {...props} ref={ref} />,
      [props, ref, type, types],
    );

    return Component;
  },
);

const FormInput = ({
  name,
  loading,
  defaultValue,
  ...rest
}: FormInputProps) => {
  const { onChange, onBlur } = rest;
  const { control } = useFormContext();
  const {
    fieldState: { error },
    field,
  } = useController({ control, name, defaultValue });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
  };

  if (loading) {
    return (
      <div>
        {rest.label && (
          <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
            {rest.label}
          </SimpleText>
        )}
        <Skeleton
          className="p-mt-2"
          height="33px"
          borderRadius="8px"
          loading={true}
        />
      </div>
    );
  }

  return (
    <FormInputComponent
      {...rest}
      ref={field.ref}
      value={field.value}
      onBlur={e => [field.onBlur(), handleOnBlur(e)]}
      onChange={e => [field.onChange(e), handleOnChange(e)]}
      errorMsg={error?.message}
    />
  );
};

export default FormInput;
