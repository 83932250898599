import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {};

type TelemedicinaProps = {
  idAtendimento: string | number;
};

export const telemedicinaSlice = createSlice({
  name: 'atendimento',
  initialState,
  reducers: {
    setTelemedicina: (
      state: TelemedicinaProps,
      action: PayloadAction<TelemedicinaProps>,
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setTelemedicina } = telemedicinaSlice.actions;

export default telemedicinaSlice.reducer;
