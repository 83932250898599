/**
 * @deprecated The component should not be used
 */

import { useCallback } from 'react';

import {
  Checkbox as PrimeCheckbox,
  CheckboxProps as PrimeCheckboxProps,
} from 'primereact/checkbox';

import './Checkbox.scss';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export interface CheckboxOption {
  value?: any;
  label?: string;
  renderCustomComponent?: (
    checkbox: JSX.Element,
    onChange?: () => void,
  ) => JSX.Element;
}

export interface CheckboxProps
  extends Omit<PrimeCheckboxProps, 'disabled' | 'required'> {
  label?: string | undefined;
  checked?: boolean;
  options?: CheckboxOption[] | undefined;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  classNameContainer?: string | undefined;
}

const Checkbox = ({
  name,
  label,
  value,
  options,
  onChange,
  checked,
  disabled,
  required,
  className,
  classNameContainer,
  ...props
}: CheckboxProps) => {
  const option = useCallback(
    o => {
      let checkboxValue = value && value.includes ? value : [];
      const isSelected = checkboxValue.includes(o.value);
      const isDisabled = !!disabled || !!o?.disabled;

      const checkboxChange = () => {
        if (isDisabled) return;
        if (isSelected) {
          checkboxValue = checkboxValue.filter(
            (val: string) => val !== o.value,
          );
        } else {
          checkboxValue.push(o.value);
        }

        if (onChange) {
          onChange(checkboxValue);
        }
      };

      const checkboxContainerClass = ['Checkbox p-mb-0 p-d-flex p-ai-center'];
      if (isSelected) checkboxContainerClass.push('checkbox-active');
      if (className) checkboxContainerClass.push(className);
      if (isDisabled)
        checkboxContainerClass.push('checkbox-container-disabled');

      if (o.renderCustomComponent) {
        return o.renderCustomComponent(
          <div
            key={o.name + Math.random().toString()}
            onClick={checkboxChange}
            className={checkboxContainerClass.join(' ')}
          >
            <PrimeCheckbox
              key={o.name + Math.random().toString()}
              inputId={o.value}
              {...props}
              disabled={isDisabled}
              value={o.value}
              checked={isSelected}
            />
          </div>,
          checkboxChange,
        );
      }

      return (
        <div
          key={o.name + Math.random().toString()}
          onClick={checkboxChange}
          className={checkboxContainerClass.join(' ')}
        >
          <PrimeCheckbox
            key={o.name + Math.random().toString()}
            inputId={o.value}
            {...props}
            disabled={isDisabled}
            value={o.value}
            checked={isSelected}
          />
          <SimpleText onClick={checkboxChange} className="p-checkbox-label">
            {o.label}
          </SimpleText>
        </div>
      );
    },

    [onChange, className, value, disabled, props],
  );

  return (
    <div
      key={name + Math.random().toString()}
      className={'checkbox-container p-ml-1'}
      {...props}
    >
      {label || label === '' ? (
        <div className={'p-ml-1 p-mb-2'}>
          <label className={'p-text-bold'} htmlFor={name}>
            <SimpleText>{label}</SimpleText>
          </label>
        </div>
      ) : (
        <></>
      )}
      <div className={classNameContainer}>
        {(options || []).map(v => option(v))}
      </div>
    </div>
  );
};

export default Checkbox;
