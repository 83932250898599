import dayjs from 'dayjs';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalVisualizarJustificativa.scss';

function ModalVisualizarJustificativa({
  state,
  isOpen,
  close,
}: DisclosureType) {
  const questionario = state;

  const renderFooter = () => (
    <div className="p-grid">
      <div className="p-col-12">
        <Button
          btnType="outline"
          label="Fechar"
          onClick={() => close()}
          stretch
        />
      </div>
    </div>
  );

  return (
    <Dialog
      id="modal-visualizar-justificativa"
      type="modal"
      header="Inativo"
      visible={isOpen}
      onHide={close}
      footer={renderFooter}
    >
      <div className="p-grid">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          Este item no prontuário foi inativo em{' '}
          <b>
            {dayjs(questionario?.dataAtualizacao)
              .locale('pt-br')
              .format('DD [de] MMMM [de] YYYY [às] HH:mm')}
          </b>{' '}
          por <b>{questionario?.usuario?.nome}</b>
        </SimpleText>

        <TextareaInput
          className="p-col-12"
          label="Justificativa"
          value={questionario?.justificativaInativacao || ''}
          readOnly
        />
      </div>
    </Dialog>
  );
}

export default ModalVisualizarJustificativa;
