import Http, { HttpParams } from 'src/core/http/Http';

export const getDorPaciente = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `dor-paciente/atendimento/${params.idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const getUnidadesDorPaciente = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'dor-paciente/valores-enum',
    hideToast: true,
    ...options,
  });

export const adicionarDorPaciente = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: 'dor-paciente',
    data: payload,
    return: true,
    ...options,
  });

export const alterarDorPaciente = (
  idPreAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `dor-paciente/${idPreAtendimento}/atendimento/${payload.idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarDorPaciente = (
  idPreAtendimento: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `dor-paciente/${idPreAtendimento}/atendimento/${idAtendimento}/inativar/`,
    data: payload,
    return: true,
    ...options,
  });

export const removerDorPaciente = (
  idPreAtendimento: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `dor-paciente/${idPreAtendimento}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const DorPacienteAPI = {
  getDorPaciente,
  getUnidadesDorPaciente,
  adicionarDorPaciente,
  alterarDorPaciente,
  inativarDorPaciente,
  removerDorPaciente,
};

export default DorPacienteAPI;
