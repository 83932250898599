import './MainMenuNotification.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useContext } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useSubscription } from 'react-stomp-hooks';

import dayjs from 'dayjs';
import NotificacaoAPI from 'src/APIs/NotificacaoAPI/NotificacaoAPI';
import EmedLogo from 'src/assets/imgs/emed_logo_text.svg';

import ThemeContext from 'src/core/themes/ThemeContext';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Badge from 'src/components/Badge/Badge';
import IconButton from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { ModalRetornoOperadora } from 'src/components/ModalRetornoOperadora/ModalRetornoOperadora';
import Tab from 'src/components/Tab/Tab';

import {
  getNotificationTimeDistance,
  marcarNotificacaoComoNaoLida,
  marcarTodosComoLido,
} from './helpers';
import PreviewNotificacao from './PreviewNotificacao';

export interface NotificationsList {
  id: number;
  titulo: string;
  conteudo: string;
  dataCriacao: Date;
  referencia: number;
  status: string;
  icone?: any;
  funcionalidade: string;
  tipoNotificacao?: any;
  dadosComplementares?: any;
  lida: boolean;
}

interface NotificationsResponse {
  page: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
  count: number;
  list: NotificationsList[];
}

const MainMenuNotification = () => {
  const overlayRef = useRef<OverlayPanel>(null);
  const { theme } = useContext(ThemeContext);
  const [showPreviewNotificacao, setShowPreviewNotificacao] = useState(false);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [notificacoesComunicado, setNotificacoesComunicado] = useState<
    NotificationsList[]
  >([]);
  const [notificacoesSistema, setNotificacoesSistema] = useState<
    NotificationsList[]
  >([]);
  const [guiasRetornoOperadora, setGuiasRetornoOperadora] = useState<any[]>([]);

  const dialogRetornoOperadora = useDisclosure({ opened: false });

  const [notificacaoSelecionada, setNotificacaoSelecionada] =
    useState<NotificationsList | null>(null);

  const carregarListaDeNotificacoesComunicado = useCallback(async () => {
    NotificacaoAPI.buscarNotificacoes('comunicado', {
      pageSize: 100,
    }).then((response: NotificationsResponse) => {
      if (response?.list) {
        setNotificacoesComunicado(response?.list);
      }
    });
  }, []);

  const carregarListaDeNotificacoesSistema = useCallback(async () => {
    NotificacaoAPI.buscarNotificacoes('sistema', {
      pageSize: 100,
    }).then((response: NotificationsResponse) => {
      if (response?.list) {
        setNotificacoesSistema(response?.list);
      }
    });
  }, []);

  useEffect(() => {
    carregarListaDeNotificacoesComunicado();
    carregarListaDeNotificacoesSistema();
  }, [
    atualizarLista,
    carregarListaDeNotificacoesComunicado,
    carregarListaDeNotificacoesSistema,
  ]);

  useSubscription('/topic/messages', (message: any) => {
    const newNotification: NotificationsList = JSON.parse(message.body).message;

    const setNewNotification =
      newNotification.tipoNotificacao === 'COMUNICADO'
        ? setNotificacoesComunicado
        : setNotificacoesSistema;

    setNewNotification(currentNotifications => [
      newNotification,
      ...currentNotifications,
    ]);
  });

  useSubscription('/user/topic/notificacao', (message: any) => {
    const newNotification: NotificationsList = JSON.parse(message.body);

    if (!!newNotification.titulo) {
      const setNewNotification =
        newNotification.tipoNotificacao === 'COMUNICADO'
          ? setNotificacoesComunicado
          : setNotificacoesSistema;

      setNewNotification(currentNotifications => [
        newNotification,
        ...currentNotifications,
      ]);
    }
  });

  const getNumberOfUnreadByNotificationsType = (
    notifications: NotificationsList[],
  ): number =>
    notifications?.reduce(
      (total, notif) => (notif.lida ? total : total + 1),
      0,
    );

  const getNumberOfUnreadNotifications = useCallback((): number => {
    let unreadNotifications = 0;

    unreadNotifications += getNumberOfUnreadByNotificationsType(
      notificacoesComunicado,
    );

    unreadNotifications +=
      getNumberOfUnreadByNotificationsType(notificacoesSistema);

    return unreadNotifications;
  }, [notificacoesComunicado, notificacoesSistema]);

  const readNotifications = useCallback(async (id: number) => {
    if (!id) return;

    return await NotificacaoAPI.atualizarStatusNotificacao(id);
  }, []);

  const renderNotificationElement = useCallback(
    (notification: NotificationsList): JSX.Element => {
      const onClick = () => {
        if (
          notification?.dadosComplementares?.transacao === 'SADT' &&
          notification?.dadosComplementares?.guiaPrestador
        ) {
          dialogRetornoOperadora.open({
            state: {
              guiaPrestador: notification?.dadosComplementares?.guiaPrestador,
              idAtendimentoSolicitacao:
                notification?.dadosComplementares?.idAtendimento,
              idConvenioSolicitacao:
                notification?.dadosComplementares?.idConvenio,
            },
          });
        } else {
          setNotificacaoSelecionada(notification);
          setShowPreviewNotificacao(true);
        }
        readNotifications(notification.id);
      };

      const isComunicado = notification.funcionalidade === 'COMUNICADO';

      return (
        <div
          key={Math.random().toString()}
          onClick={isComunicado ? onClick : () => null}
          className={`notification-container ${isComunicado ? 'cursor' : ''}`}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <div className="notificatio-infos">
              {isComunicado && (
                <div className={'notificatio-logo'}>
                  <img alt={'Logo'} src={notification.icone || EmedLogo} />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <div className="notification-status">
                  <div className="notification-status-time">
                    {!notification.lida && (
                      <SimpleText className={'notification-unread'}>
                        Novo
                      </SimpleText>
                    )}

                    {!notification.lida && <div>•</div>}
                    <div className="notification-date">
                      {getNotificationTimeDistance(
                        notification.dataCriacao,
                      ) && (
                        <>
                          <SimpleText
                            className={'notification-time-ago-text'}
                            fontSize={FONT_SIZE.XXXS}
                          >
                            {getNotificationTimeDistance(
                              notification.dataCriacao,
                            )}
                          </SimpleText>
                          <div>•</div>
                        </>
                      )}

                      <SimpleText
                        className={'notification-time-ago-text'}
                        fontSize={FONT_SIZE.XXXS}
                      >
                        {dayjs(notification.dataCriacao)
                          .locale('pt-BR')
                          .format('DD MMM. YYYY')}
                      </SimpleText>
                    </div>
                  </div>
                  {notification.funcionalidade !== 'COMUNICADO' && (
                    <div className={'notification-option'}>
                      <IconButton
                        style={{ borderRadius: '50%', background: 'none' }}
                        icon="pi pi-ellipsis-v"
                        overlayClassName="notification-options-overlay"
                        overlay={
                          <div
                            className="marcar-como-nao-lida"
                            onClick={
                              notification?.id
                                ? async () => {
                                    await marcarNotificacaoComoNaoLida(
                                      notification.id,
                                    );
                                    await carregarListaDeNotificacoesSistema();
                                    setTimeout(() => {
                                      document
                                        .getElementById('notification-icon')
                                        ?.click();
                                    }, 1000);
                                  }
                                : () => {}
                            }
                          >
                            <SimpleText>Marcar como não lido</SimpleText>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
                <SimpleText
                  className={`cursor ${FONT_COLOR.COLOR_40}`}
                  fontSize={FONT_SIZE.XXS}
                >
                  {notification.titulo}
                </SimpleText>
              </div>
            </div>
            <div>
              {isComunicado && (
                <i className={'angle-right-color pi pi-angle-right'} />
              )}
            </div>
          </div>

          {notification.funcionalidade !== 'COMUNICADO' && (
            <div className="notification-icon-open cursor" onClick={onClick}>
              <SimpleText>Abrir</SimpleText>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM488 0H352c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.88L370.8 96L169.4 297.4c-12.5 12.5-12.5 32.75 0 45.25C175.6 348.9 183.8 352 192 352s16.38-3.125 22.62-9.375L416 141.3l41.38 41.38c9.156 9.141 22.88 11.84 34.88 6.938C504.2 184.6 512 172.9 512 160V24C512 10.74 501.3 0 488 0z" />
                </svg>
              </div>
            </div>
          )}
        </div>
      );
    },
    [
      carregarListaDeNotificacoesSistema,
      dialogRetornoOperadora,
      readNotifications,
    ],
  );

  const filterNotificationsByReadStatus = useCallback(
    (notificacoes: NotificationsList[], status: boolean) =>
      notificacoes?.filter((notif: any) => {
        return notif.lida === status;
      }),
    [],
  );

  const renderNotifications = useCallback(
    (notificacoes: NotificationsList[]): JSX.Element => {
      const notificacoesNovas = filterNotificationsByReadStatus(
        notificacoes,
        false,
      );

      const notificacoesLidas = filterNotificationsByReadStatus(
        notificacoes,
        true,
      );

      return (
        <div
          className={'AnnouncementsNotifications'}
          key={Math.random().toString()}
        >
          {notificacoesNovas?.length > 0 && (
            <>
              <div className="p-d-flex p-ai-center p-jc-between p-mb-2">
                <div>
                  <SimpleText fontSize={FONT_SIZE.XS}>Novos</SimpleText>
                </div>
                <div className={'main-menu-button cursor'}>
                  <SimpleText
                    onClick={async () => {
                      await marcarTodosComoLido(notificacoesNovas);
                      setAtualizarLista(prev => !prev);
                    }}
                    style={{
                      color: '#54A275',
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    Marcar todos como lido
                  </SimpleText>
                </div>
              </div>
              {notificacoesNovas.map(renderNotificationElement)}
            </>
          )}
          {notificacoesLidas?.length > 0 && (
            <>
              <div className={'p-mt-2 p-mb-2'}>
                <SimpleText fontSize={FONT_SIZE.XS}>Visualizados</SimpleText>
              </div>
              {notificacoesLidas.map(renderNotificationElement)}
            </>
          )}
        </div>
      );
    },
    [filterNotificationsByReadStatus, renderNotificationElement],
  );

  return (
    <div
      key={Math.random().toString()}
      className={'MainMenuNotification p-pr-2'}
    >
      {dialogRetornoOperadora.isOpen && (
        <ModalRetornoOperadora {...dialogRetornoOperadora} />
      )}

      <Button
        btnType={getNumberOfUnreadNotifications() > 0 ? 'tonal' : 'gray'}
        onClick={e => {
          setShowPreviewNotificacao(false);
          overlayRef.current?.toggle(e);
        }}
        id={'notification-icon'}
        icon={'pi pi-bell'}
      />

      {getNumberOfUnreadNotifications() > 0 && (
        <Badge
          className={'badge-position'}
          severity="warning"
          value={getNumberOfUnreadNotifications()}
        />
      )}

      <OverlayPanel
        className={`overlay-main-menu-notification p-px-2 p-py-2 ${theme}`}
        ref={overlayRef}
      >
        <div className="p-d-flex p-ai-center p-jc-between">
          <div>
            <SimpleText fontSize={FONT_SIZE.MD}>Notificações</SimpleText>
          </div>
          <div>
            <IconButton icon={'pi pi-ellipsis-v'} />
          </div>
        </div>

        <Tab
          clean
          values={[
            {
              label: 'Sistema',
              content: renderNotifications(notificacoesSistema),
            },
            {
              label: 'Comunicados',
              content: renderNotifications(notificacoesComunicado),
            },
          ]}
        />
      </OverlayPanel>
      <PreviewNotificacao
        notificacao={notificacaoSelecionada}
        visible={showPreviewNotificacao}
        onClose={() => {
          setShowPreviewNotificacao(false);
          setAtualizarLista(prev => !prev);
        }}
      />
    </div>
  );
};

export default MainMenuNotification;
