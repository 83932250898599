import { useEffect, useState } from 'react';

import {
  ListBox as PrimeListBox,
  ListBoxProps as PrimeListBoxProps,
} from 'primereact/listbox';
import { Control, Controller } from 'react-hook-form';

import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';

import './ListBox.scss';

interface ListBoxValue {
  id: number;
  nome: string;
}

interface ListBoxProps extends PrimeListBoxProps {
  control: Control;
  name: string;
  options: any;
  errorMsg?: string;
  optionsClassName?: string;
  customTextEmpyt?: string;
}

function ListBox({
  control,
  name,
  options,
  errorMsg,
  optionsClassName = 'listSelected-options',
  customTextEmpyt = 'Nenhum resultado encontrado',
  disabled = false,
  ...rest
}: ListBoxProps) {
  const [filter, setFilter] = useState<string>('');
  const [optionsState, setOptionsState] = useState<any>(options);

  useEffect(() => {
    const uniqueOptions = Array.from(
      new Set(options?.map((a: any) => a?.value?.id)),
    ).map(id => {
      return options?.find((a: any) => a?.value?.id === id);
    });

    const filterOptions = uniqueOptions.filter((option: any) =>
      option?.label?.toLowerCase().includes(filter.toLowerCase()),
    );

    setOptionsState(filterOptions);
  }, [filter, options]);

  function itemTemplate(option: any, selecionados: ListBoxValue[]) {
    const selecionado = option?.value?.id
      ? selecionados?.some(
          selecionado => selecionado?.id === option?.value?.id,
        ) || false
      : selecionados?.includes(option?.value) || false;

    if (!options?.length) {
      return (
        <div className="empty">
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
            Nenhum médico adicionado.
          </SimpleText>
        </div>
      );
    }

    return (
      <div
        className={`modelo-listbox-options p-d-flex ${optionsClassName} ${
          selecionado && !disabled && 'selecionado'
        }`}
      >
        <Checkbox
          className="display-none"
          disabled={!disabled}
          checked={selecionado}
        />
        <span>{option?.label}</span>
      </div>
    );
  }

  return (
    <>
      <div className="TextInputSearch p-mb-3">
        <span
          className={`p-input-icon-left ${filter ? 'p-input-icon-right' : ''}`}
        >
          <i className="pi pi-search" style={{ marginRight: 5 }} />
          <TextInput
            placeholder="Pesquisar..."
            disabled={disabled}
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />

          {!!filter && (
            <i className="pi pi-times" onClick={() => setFilter('')} />
          )}
        </span>
      </div>

      {!optionsState.length && (
        <div className="campos-obrigatorios-list">
          <div className="content-empyt">
            <SimpleText fontColor={FONT_COLOR.COLOR_40}>
              {customTextEmpyt}
            </SimpleText>
          </div>
        </div>
      )}

      {!!optionsState.length && (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }: any) => {
            return (
              <PrimeListBox
                disabled={disabled}
                options={optionsState}
                value={value}
                onChange={onChange}
                itemTemplate={option => itemTemplate(option, value)}
                multiple
                {...rest}
              />
            );
          }}
        />
      )}

      {errorMsg && (
        <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
          {errorMsg}
        </SimpleText>
      )}
    </>
  );
}

export default ListBox;
