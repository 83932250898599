import * as Yup from 'yup';

import { statusHistoricoOptions } from '../../../helpers';

const statusValues = statusHistoricoOptions.map(
  (statusOption: any) => statusOption.value,
);

export const validationSchema = (hasLaudoArquivo: boolean) =>
  Yup.object().shape({
    dataRealizacao: Yup.date().nullable(),
    laudoDigitado: Yup.string().nullable(),
    severidadeAlteracao: Yup.string().nullable(),
    resutladoNumerico: Yup.number().nullable(),
    dataRepeticao: Yup.date().nullable(),
    statusExame: Yup.string()
      .oneOf(statusValues, 'O campo status é obrigatório')
      .nullable()
      .when([], {
        is: () => hasLaudoArquivo,
        then: field => field.required('O campo status é obrigatório'),
      }),
    dataResultado: Yup.date()
      .nullable()
      .when([], {
        is: () => hasLaudoArquivo,
        then: field =>
          field.required('O campo data do resultado é obrigatório'),
      }),
    observacao: Yup.string().nullable(),
  });
