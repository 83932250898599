import React from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { bgColors } from '../DiagnosticoFuncionalGlobal';
import { generateRandomString } from 'src/utils/utils';
import Tooltip from 'src/components/Tooltip/Tooltip';
import dayjs from 'dayjs';

interface RiscoSocioAmbientalProps {
  diagnostico: any;
}

const RiscoSocioAmbiental = ({ diagnostico }: RiscoSocioAmbientalProps) => {
  const escalaGijon = Array.isArray(
    diagnostico?.riscoSocioAmbiental?.escalaGijon,
  )
    ? diagnostico?.riscoSocioAmbiental?.escalaGijon
    : [diagnostico?.riscoSocioAmbiental?.escalaGijon];
  const quedas = Array.isArray(diagnostico?.riscoSocioAmbiental?.quedas)
    ? diagnostico?.riscoSocioAmbiental?.quedas
    : [diagnostico?.riscoSocioAmbiental?.quedas];

  const corEscalaGijon = escalaGijon[0]?.cor;
  const corQuedas = quedas[0]?.cor;

  return (
    <div className="p-d-flex border-dashed-x p-ai-center border-dashed-bottom">
      <div className="w-210px p-d-flex p-jc-center">
        <SimpleText fontSize="xxs" className="p-p-1">
          RISCO SÓCIO AMBIENTAL
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column">
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              ESCALA DE GIJÓN
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corEscalaGijon === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corEscalaGijon === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              (11 a 15) – (1)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corEscalaGijon === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`(16 ou >) – (2)`}
            </SimpleText>
          </div>

          {escalaGijon.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              RISCO AMBIENTAL – QUEDAS
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center p-p-1 ${
              corQuedas === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x p-p-1 ${
              corQuedas === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              (7 a 14) – (1)
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corQuedas === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              (15 a 24) – (2)
            </SimpleText>
          </div>

          {quedas.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default RiscoSocioAmbiental;
