import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PlanoCuidadoAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoAPI/PlanoCuidadoAPI';
import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import { ProfissionalSaude } from 'src/models/APIs/AdminAPI/ProfissionalSaude/ProfissionalSaudeDTO';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { formatarPlanos } from '../utils';
import { handleResetForm } from './utils';

import {
  PlanoCuidadoConsultaItem,
  PlanoCuidadoExamesItem,
  PlanoCuidadoPedidoCuidadoItem,
} from './components';
import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldCiapMultiselect } from 'src/components/Fields/FieldCiapMultiselect/FieldCiapMultiselect';
import { FieldCidsMultiselect } from 'src/components/Fields/FieldCidsMultiselect/FieldCidsMultiselect';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { SectionDataProps } from '../PlanoDeCuidadoCreate';

import { ModalAddNovoPlano } from './ModalAddNovoPlano/ModalAddNovoPlano';

import './PlanoDeCuidadoFormConfigurar.scss';

type IProps = {
  close: () => void;
  sectionData: SectionDataProps;
  isEditing: boolean;
};

export const PlanoDeCuidadoFormConfigurar = ({
  close,
  sectionData,
  isEditing,
}: IProps) => {
  const { atendimento } = useAppSelector(state => state);
  const modalAdd = useDisclosure({ opened: false });
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const dispatch = useDispatch();
  const { atendimentoStatus } = useAtendimento();

  const [loading, setLoading] = useState(true);

  const defaultValues = useMemo(() => {
    return {
      ...sectionData,
      planos: [],
    } as any;
  }, [sectionData]);

  const validateSchema = Yup.object({
    planos: Yup.array(
      Yup.object({
        atividade: Yup.string(),
        consultas: Yup.array().when('atividade', {
          is: (atividade: string) => atividade === 'CONSULTA',
          then: field =>
            field.min(1, 'É necessário cadastrar as data programadas'),
        }),
        pedidosCuidado: Yup.array().when('atividade', {
          is: (atividade: string) => atividade === 'PEDIDO_CUIDADO',
          then: field =>
            field.min(1, 'É necessário cadastrar as data programadas'),
        }),
        exames: Yup.array().when('atividade', {
          is: (atividade: string) => atividade === 'EXAMES',
          then: field =>
            field.min(1, 'É necessário cadastrar as data programadas'),
        }),
      }),
    )
      .required('Campo obrigatório')
      .min(1, 'É necessário pelo menos 1 plano cadastrado.'),
  });

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validateSchema),
  });

  const getPlanoById = useCallback(async () => {
    try {
      setLoading(true);

      const Api = !isEditing
        ? PlanoCuidadoAPI.loadPlanoCuidadoById
        : PlanoCuidadoPacienteAPI.loadPlanoCuidadoPacienteById;

      const data = await Api(
        isEditing ? sectionData.id! : sectionData.planoCuidado?.id,
        {
          ...(isEditing && { idAtendimento: sectionData.idAtendimentoOrigem! }),
        },
        { throwError: true },
      );

      if (!data) return;

      handleResetForm(form.reset, { ...defaultValues, ...data });
    } catch {
      close();
    } finally {
      setLoading(false);
    }
  }, [
    close,
    defaultValues,
    form.reset,
    isEditing,
    sectionData?.id,
    sectionData?.idAtendimentoOrigem,
    sectionData?.planoCuidado?.id,
  ]);

  useEffect(() => {
    if (sectionData.planoCuidado?.id) getPlanoById();
  }, [getPlanoById, sectionData]);

  const {
    fields: planos,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: 'planos',
    keyName: 'idForm',
  });

  const handleDelete = useCallback(
    (idx: number) => {
      remove(idx);
    },
    [remove],
  );

  const planoComponent = useCallback(
    (item: any, idx: number) => {
      const idAtendimentoOrigem = item.idAtendimentoOrigem ?? idAtendimento;
      const isNotAtendimentoCorrente = !(
        idAtendimento == idAtendimentoOrigem &&
        atendimentoStatus === 'ATENDENDO'
      );

      const props = {
        key: idx,
        idx,
        item,
        isNotAtendimentoCorrente,
        handleUpdate: update,
        onEdit: () => modalAdd.open({ state: { item, idx, update } }),
        handleDelete: () => handleDelete(idx),
      };

      if (item.atividade === 'CONSULTA')
        return <PlanoCuidadoConsultaItem {...props} />;
      if (item.atividade === 'PEDIDO_CUIDADO')
        return <PlanoCuidadoPedidoCuidadoItem {...props} />;
      if (item.atividade === 'EXAMES')
        return <PlanoCuidadoExamesItem {...props} />;

      return null;
    },
    [atendimentoStatus, handleDelete, idAtendimento, modalAdd, update],
  );

  const onSubmit = (values: any) => {
    const type = isEditing ? 'PUT' : 'POST';
    const Api = isEditing
      ? PlanoCuidadoPacienteAPI.updatePlanoCuidadoPaciente
      : PlanoCuidadoPacienteAPI.createPlanoCuidadoPaciente;
    const idsProfissionaisResponsaveis = values.profissionais.map(
      (value: ProfissionalSaude) => value.id,
    );

    const payload = {
      id: isEditing ? values.id : undefined,
      idPlanoCuidado: values.planoCuidado.id,
      idAtendimentoOrigem: atendimento.id,
      lembreteFimVigencia: !!values.lembreteFimVigencia,
      idsProfissionaisResponsaveis,
      atividadesConsultas: formatarPlanos(values.planos, 'CONSULTA', type),
      atividadesCuidado: formatarPlanos(values.planos, 'PEDIDO_CUIDADO', type),
      atividadesExame: formatarPlanos(values.planos, 'EXAMES', type),
    };

    return Api(payload, {
      throwError: true,
    })
      .then(() => [
        dispatch(setInvalidateQuery({ invalidatePlanosList: true })),
        close(),
      ])
      .catch(() => {});
  };

  const planosError = !!form.formState.errors?.planos?.length;

  if (loading) return <GridListLoading />;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="config-add-plano-cuidado p-grid p-ai-center"
      >
        <FormInput
          label="Nome do plano"
          name="nome"
          className="p-col-12"
          hideTextErrorSpace
          disabled
        />
        <FieldCidsMultiselect
          label="CID"
          name="cids"
          className="p-col-12"
          disabled
        />
        <FieldCiapMultiselect
          label="CIAP"
          name="ciaps"
          className="p-col-12"
          disabled
        />

        <div className="p-col-4">
          <FormInput
            name="diasVigencia"
            label="Período de vigência"
            sublabel="(dias)"
            placeholder="Período em dias"
            type="number"
            hideTextErrorSpace
            disabled
          />
        </div>

        <CheckboxControlled
          control={form.control}
          name="lembreteFimVigencia"
          label="Lembrete fim da vigência"
          className="p-col-6 p-mt-4"
        />

        <div className="p-col-12 p-d-flex p-ai-center p-jc-between p-mt-3">
          <SimpleText medium>Atividades do plano</SimpleText>
          <Button
            icon="fas fa-plus"
            type="button"
            label="Adicionar atividade"
            onClick={() => modalAdd.open()}
            disabled={form.formState.isSubmitting}
          />
        </div>

        <div className="content">
          {!!planos.length ? (
            planos.map((item, idx) => planoComponent(item, idx))
          ) : (
            <div
              style={{ height: '100px' }}
              className="p-d-flex p-ai-center p-jc-center"
            >
              <SimpleText>Sem planos cadastrados</SimpleText>
            </div>
          )}
        </div>

        {planosError && (
          <AlertBox
            visible
            text="É necessário cadastrar as datas programadas para as atividades
          adicionadas."
            type="DANGER"
          />
        )}

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-3">
          <Button
            btnType="ghost"
            label="Cancelar"
            type="button"
            onClick={() => close()}
            stretch
            disabled={form.formState.isSubmitting}
          />
          <Button
            label="Salvar"
            type="submit"
            stretch
            disabled={!planos.length}
            loading={form.formState.isSubmitting}
          />
        </div>

        {modalAdd.isOpen && <ModalAddNovoPlano {...modalAdd} />}
      </form>
    </FormProvider>
  );
};
