import { useState } from 'react';

import {
  UseFormSetValue,
  UseFormGetValues,
  FieldValues,
} from 'react-hook-form';
import { toast } from 'react-toastify';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import Toast from 'src/components/Basics/Toast/Toast';

export interface AddressData {
  loadingCepSearch: boolean;
  handleSearchAddressByCep: () => void;
}

interface AddressProps {
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  handleStateSelect: (idState: number) => void;
}

export const useCep = ({
  setValue,
  getValues,
  handleStateSelect,
}: AddressProps): AddressData => {
  const [loadingCepSearch, setLoadingCepSearch] = useState(false);

  const handleSearchAddressByCep = async () => {
    const cep = getValues('endereco.cep');

    if (cep && cep.length === 9) {
      setLoadingCepSearch(true);

      try {
        const response = await UtilsAPI.GetAddressByCep(cep);

        if ('status' in response) throw Error('Load CEP data error');

        if (response !== undefined) {
          const { logradouro, bairro, idEstado, idCidade, complemento } =
            response;

          setValue('endereco.idPaisEstrangeiro', 0, { shouldValidate: true });
          setValue('endereco.logradouro', logradouro, { shouldValidate: true });
          setValue('endereco.bairro', bairro, { shouldValidate: true });
          setValue('endereco.complemento', complemento, {
            shouldValidate: true,
          });
          setValue('endereco.estado', idEstado, { shouldValidate: true });

          await handleStateSelect(idEstado);

          setValue('endereco.idCidade', idCidade, { shouldValidate: true });
        }
      } catch (error) {
        setValue('cep', '');
        const data = { message: 'CEP não encontrado.', type: 'warning' };
        toast(<Toast />, { data });
      } finally {
        setLoadingCepSearch(false);
      }
    }
  };

  return {
    loadingCepSearch,
    handleSearchAddressByCep,
  };
};
