/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';

import GrupoTussAPI from 'src/APIs/AdminAPI/GrupoTussAPI/GrupoTussAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './GrupoGrid/Row';
import { Header } from './Header';
import ModalAddGrupoTuss from './ModalAddGrupoTuss/ModalAddGrupoTuss';
import ModalExcluirGrupoTuss from './ModalExcluirGrupoTuss/ModalExcluirGrupoTuss';

import './GrupoTussGlobal.scss';

const GrupoTussGlobal = () => {
  const [modalExcluirGrupoTuss, setModalExcluirGrupoTuss] = useState(false);
  const [grupoTuss, setGrupoTuss] = useState<any>(null);
  const [reload, setReload] = useState<number>(0);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'adm_grupo_tuss_cadastrar',
  });

  const modalGrupoTuss = useDisclosure({
    opened: false,
    onClose: () => [setGrupoTuss(null)],
  });

  const handleModalEditGrupoTuss = useCallback((item: any) => {
    setGrupoTuss(item);
    modalGrupoTuss.open();
  }, []);

  const handleEditStatusGrupoTuss = useCallback(
    async (data: any) => {
      let response: any;

      if (data?.status) {
        response = await GrupoTussAPI.updateGrupoTussInativar(data?.id);
      } else {
        response = await GrupoTussAPI.updateGrupoTussAtivar(data?.id);
      }

      if (response?.status === 200) {
        reloadList();
      }
    },
    [reload],
  );

  const handleModalExcluirGrupoTussClose = useCallback(() => {
    setModalExcluirGrupoTuss(false);
  }, []);

  const handleExcluirParametro = useCallback((item: any) => {
    setGrupoTuss(item);
    setModalExcluirGrupoTuss(true);
  }, []);

  const renderRow = (data: any): JSX.Element => (
    <Row
      data={data}
      onSwitchStatus={handleEditStatusGrupoTuss}
      onDelete={handleExcluirParametro}
      onEdit={handleModalEditGrupoTuss}
    />
  );

  const fetchApi = async ({ query, ...rest }: any) => {
    return await GrupoTussAPI.loadGrupoTuss({
      nome: query,
      ...rest,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  return (
    <div id="GrupoTussGlobal" className="p-px-4">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
            <SearchList.SearchInput placeholder="Pesquisar grupos TUSS" />

            <Button
              btnType="pill"
              className="p-mb-2"
              label="Novo grupo TUSS"
              icon="fas fa-plus"
              onClick={() => modalGrupoTuss.open()}
              checkPermission="ADM_GRUPO_TUSS_CADASTRAR"
            />
          </div>

          <SearchList.Header>
            <Header />
          </SearchList.Header>

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {modalGrupoTuss.isOpen && (
        <ModalAddGrupoTuss
          grupoTussSelecionado={grupoTuss}
          visible={modalGrupoTuss.isOpen}
          onHide={() => modalGrupoTuss.close()}
          setReload={reloadList}
        />
      )}

      <ModalExcluirGrupoTuss
        grupoTuss={grupoTuss}
        visible={modalExcluirGrupoTuss}
        onHide={handleModalExcluirGrupoTussClose}
        onSuccess={reloadList}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default GrupoTussGlobal;
