import { memo } from 'react';

import { Skeleton } from 'primereact/skeleton';
import './GridList.scss';

const GridListLoading = memo(() => {
  return (
    <div className="GridList">
      <Skeleton height="40px" borderRadius="8px" />
      <Skeleton height="40px" borderRadius="8px" />
      <Skeleton height="40px" borderRadius="8px" />
      <Skeleton height="40px" borderRadius="8px" />
    </div>
  );
});

export default memo(GridListLoading);
