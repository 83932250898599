import { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import dayjs from 'dayjs';
import { PesquisaPacienteAPI } from 'src/APIs/AgendaAPI/PesquisaPacienteAPI/PesquisaPacienteAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import {
  Filter as SearchListFilter,
  SearchInput,
} from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

export const Filter = () => {
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const useFormMethods = useForm();

  const { getValues, reset, control, watch, setValue } = useFormMethods;

  useEffect(() => {
    setValue('todosStatus', true);
  }, [setValue]);

  const handleFilter = () => {
    const { dataNascimento = null, status = [] } = getValues();

    return {
      dataNascimento: dataNascimento
        ? dayjs(dataNascimento).format('YYYYMMDD')
        : null,
      status: status?.toString() || null,
      query: null,
    };
  };

  const handleClearFilters = () => {
    reset();
  };

  useEffect(() => {
    const getStatus = async () => {
      const response = await PesquisaPacienteAPI.getSatatusPermitidos();
      const options = Object.keys(response.data).map(key => ({
        label: capitalizeFirstLetter(key).replaceAll('_', ' '),
        value: key,
      }));

      setStatusOptions(options);
    };
    getStatus();
  }, []);

  const watchTodosStatus = watch('todosStatus');
  const watchFiltroStatus = watch('filtroStatus');
  const watchStatus = watch('status');

  useEffect(() => {
    const arrayTodosStatus = statusOptions.map(option => option.value);
    if (watchTodosStatus === true) {
      setValue('status', arrayTodosStatus);
    }
  }, [setValue, statusOptions, watchTodosStatus]);

  useEffect(() => {
    if (watchStatus?.length !== statusOptions?.length) {
      setValue('todosStatus', false);
    }
  }, [setValue, statusOptions, watchStatus]);

  const filteredStatusOptions = statusOptions.filter(option =>
    option.label.toLowerCase().includes(watchFiltroStatus?.toLowerCase() || ''),
  );

  return (
    <SearchListFilter
      onFilter={handleFilter}
      onClearFilters={handleClearFilters}
      btnType={BtnTypes.TONAL}
      type="button"
    >
      <FormProvider {...useFormMethods}>
        <form>
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XXS}
            className="p-col-12"
          >
            Pesquise pacientes de forma detalhada,{'\n'}preenchendo um ou mais
            dos campos abaixo.
          </SimpleText>
          <div className="p-col-12 p-mb-1">
            <CalendarInputControlled
              control={control}
              name="dataNascimento"
              label="Data de nascimento"
              placeholder="Data de nascimento"
              showIcon
            />
          </div>
          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="filtroStatus"
              label="Status da agenda"
              placeholder="Filtre a situação desejada"
            />
          </div>
          <div className="p-col-12 p-mb-3">
            <div className="p-my-1 p-d-flex p-jc-between">
              <CheckboxControlled
                control={control}
                name="todosStatus"
                label="Todos"
                className="p-ml-1"
              />
              <Button
                type="button"
                label="Limpar"
                btnType={BtnTypes.GREEN_LINK}
                onClick={() => setValue('status', [])}
              />
            </div>
            <Separator layout="horizontal" />
            <div className="opcoes-status">
              <MultipleCheckboxControlled
                name="status"
                options={
                  watchFiltroStatus ? filteredStatusOptions : statusOptions
                }
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};
