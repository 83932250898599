import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { AplicacaoFormularioDinamicoAPI } from 'src/APIs/AdminAPI/AplicacaoFormularioDinamicoAPI/AplicacaoFormularioDinamicoAPI';
import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import ConvenioAPI from 'src/APIs/AdminAPI/ConvenioAPI/ConvenioAPI';
import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import { Button, Checkbox } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Tab from 'src/components/Tab/Tab';

type ContentProps = {
  type: 'ESPECIALIDADE' | 'CONSULTORIO' | 'CONVENIO';
  selectedItems: any[];
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>;
  handleSeletedAll: (e: boolean) => void;
  isSeletedAll: boolean;
  readOnly: boolean;
};

const idVinculoKey: { [key: string]: string } = {
  ESPECIALIDADE: 'idEspecialidade',
  CONSULTORIO: 'idConsultorio',
  CONVENIO: 'idConvenio',
};

export const AplicacaoFormularioCreateVinculo = ({
  handleGoBack,
  item,
}: {
  handleGoBack(): void;
  item: IAplicacaoFormularioDinamicoItem;
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const viewOnly = state?.to !== 'edit' && !!item;

  const itemsSelectedAll: { [key: string]: boolean } = {
    ESPECIALIDADE: !!item?.selecionarTodosEspecialidade,
    CONSULTORIO: !!item?.selecionarTodosConsultorio,
    CONVENIO: !!item?.selecionarTodosConvenio,
  };

  const [selectedAll, setSeletedAll] = useState<string[]>([
    ...Object.keys(itemsSelectedAll).filter(key => itemsSelectedAll[key]),
  ]);
  const [especialidades, setEspecialidades] = useState<
    { idEspecialidade: number; id?: number }[]
  >(item?.especialidades || []);
  const [consultorios, setConsultorios] = useState<
    { idConsultorio: number; id?: number }[]
  >(item?.consultorios || []);
  const [convenios, setConvenios] = useState<
    { idConvenio: number; id?: number }[]
  >(item?.convenios || []);

  const [idxTab, setIdxTab] = useState(0);

  const isValid =
    (especialidades.length || selectedAll.includes('ESPECIALIDADE')) &&
    (consultorios.length || selectedAll.includes('CONSULTORIO')) &&
    (convenios.length || selectedAll.includes('CONVENIO'));

  const handleSelectAll = (event: boolean, type: string) => {
    if (event) {
      setSeletedAll(prev => [...prev, type]);
    } else {
      setSeletedAll(prev => prev.filter(item => item !== type));
    }
  };

  const handleSubmit = async () => {
    if (viewOnly) return navigate('/cadastros/formulario-dinamico/aplicacao');

    const especialidadesItem = especialidades.map(espec => ({
      idEspecialidade: espec.idEspecialidade,
      ...(espec.id && { id: espec.id }),
    }));
    const consultoriosItem = consultorios.map(espec => ({
      idConsultorio: espec.idConsultorio,
      ...(espec.id && { id: espec.id }),
    }));
    const conveniosItem = convenios.map(espec => ({
      idConvenio: espec.idConvenio,
      ...(espec.id && { id: espec.id }),
    }));

    const payload = {
      ...item,
      especialidades: especialidadesItem,
      consultorios: consultoriosItem,
      convenios: conveniosItem,
      formularioDinamico: undefined,
    };

    try {
      await AplicacaoFormularioDinamicoAPI.updateAplicacaoFormulario(payload, {
        throwError: true,
      });

      handleGoBack();
      navigate('/cadastros/formulario-dinamico/aplicacao');
    } catch {
      return;
    }
  };

  return (
    <div className="p-d-flex p-flex-column p-col-12 p-md-8 p-lg-6 p-gap-3">
      <SimpleText medium>Vínculos</SimpleText>

      <Tab
        clean
        initialTabIndex={idxTab}
        setActiveIndex={idxTab}
        onChange={setIdxTab}
        values={[
          {
            label: 'Especialidade',
            content: (
              <Content
                type="ESPECIALIDADE"
                selectedItems={especialidades}
                setSelectedItems={setEspecialidades}
                isSeletedAll={selectedAll.includes('ESPECIALIDADE')}
                readOnly={viewOnly}
                handleSeletedAll={(e: boolean) =>
                  handleSelectAll(e, 'ESPECIALIDADE')
                }
              />
            ),
          },
          {
            label: 'Consultório',
            content: (
              <Content
                type="CONSULTORIO"
                selectedItems={consultorios}
                setSelectedItems={setConsultorios}
                isSeletedAll={selectedAll.includes('CONSULTORIO')}
                readOnly={viewOnly}
                handleSeletedAll={(e: boolean) =>
                  handleSelectAll(e, 'CONSULTORIO')
                }
              />
            ),
          },
          {
            label: 'Convênio',
            content: (
              <Content
                type="CONVENIO"
                selectedItems={convenios}
                setSelectedItems={setConvenios}
                isSeletedAll={selectedAll.includes('CONVENIO')}
                readOnly={viewOnly}
                handleSeletedAll={(e: boolean) =>
                  handleSelectAll(e, 'CONVENIO')
                }
              />
            ),
          },
        ]}
      />

      <div
        className="p-w-100 p-d-flex p-gap-2 p-mb-2 p-mt-3"
        style={{ position: 'relative' }}
      >
        <Button
          label="Voltar"
          onClick={() => handleGoBack()}
          btnType="ghost"
          stretch
        />
        {[0, 1].includes(idxTab) && (
          <Button
            label="Próximo"
            btnType="tonal"
            onClick={() => setIdxTab(idxTab + 1)}
            stretch
          />
        )}
        {idxTab === 2 && (
          <>
            {!isValid && (
              <AlertBox
                visible
                style={{
                  position: 'absolute',
                  bottom: '38px',
                }}
                text="Selecione pelo menos uma especialidade, consultório e convênio."
              />
            )}
            <Button
              label="Salvar"
              onClick={() => handleSubmit()}
              stretch
              disabled={!isValid}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Content = ({
  type,
  selectedItems,
  setSelectedItems,
  handleSeletedAll,
  isSeletedAll,
  readOnly,
}: ContentProps) => {
  const api: { [key: string]: any } = {
    ESPECIALIDADE: EspecialidadeAPI.loadEspecialidades,
    CONSULTORIO: ConsultorioAPI.loadConsultorios,
    CONVENIO: ConvenioAPI.loadConveniosAtivos,
  };

  const fetchApi = async (params: any) => {
    return api[type]({
      nome: params.query,
      ...params,
    });
  };

  const handleChange = (checked: boolean, item: GetEspecialidadeDTO) => {
    if (checked) {
      setSelectedItems(prev => [
        ...prev,
        {
          [idVinculoKey[type]!]: item.id,
        },
      ]);
    } else {
      setSelectedItems(prev =>
        prev.filter(selected => selected[idVinculoKey[type]!] !== item.id),
      );
    }
  };

  return (
    <SearchList.Root fetchApi={fetchApi}>
      <SearchList.SearchInput
        className="p-col-12 p-px-0"
        placeholder="Pesquise por nome"
        disabled={readOnly}
      />

      <div className="p-col-12 p-px-0 p-py-1 p-d-flex p-jc-between">
        <Checkbox
          checked={isSeletedAll}
          onChange={e => [handleSeletedAll(e.checked), setSelectedItems([])]}
          className="p-p-2"
          label="Selecionar todos"
          disabled={readOnly}
        />

        <Button
          btnType="ghost"
          label="Limpar seleções"
          onClick={() => setSelectedItems([])}
          disabled={selectedItems.length === 0 || readOnly}
        />
      </div>

      <SearchList.BorderContainer className="p-p-1">
        <SearchList.InfiniteScroll
          autoHeight={false}
          height={300}
          renderRow={(item: any) => (
            <div
              className="row content-row p-mb-2"
              data-checked={selectedItems.some(
                selected => selected[idVinculoKey[type]!] === item.id,
              )}
            >
              <Checkbox
                label={type === 'CONVENIO' ? item.razaoSocial : item.nome}
                className="p-col-12"
                onChange={e => handleChange(e.checked, item)}
                checked={selectedItems.some(
                  selected => selected[idVinculoKey[type]!] === item.id,
                )}
                disabled={isSeletedAll || readOnly}
              />
            </div>
          )}
        />
      </SearchList.BorderContainer>
    </SearchList.Root>
  );
};
