import * as Yup from 'yup';

import { escalaTempoOptions } from '../../../helpers';

const unidadeTempoValues = escalaTempoOptions.map(
  (localOption: any) => localOption.value,
);

export const validationSchema = (hasLaudoArquivo: boolean) =>
  Yup.object().shape({
    solicitante: Yup.string()
      .nullable()
      .required('O campo solicitante é obrigatório.'),
    conselhoSolicitante: Yup.string()
      .nullable()
      .required('O campo número do conselho é obrigatório.'),
    cidIndicacaoClinica: Yup.string().nullable(),
    tempoDoenca: Yup.number().nullable(),
    unidadeTempoDoenca: Yup.string()
      .oneOf(unidadeTempoValues, 'O campo unidade tempo é obrigatório')
      .nullable()
      .when('tempoDoenca', {
        is: (tempoDoenca: number) => typeof tempoDoenca === 'number',
        then: field => field.required('O campo unidade tempo é obrigatório'),
      }),
    laudoDigitado: Yup.string().nullable(),
    exames: Yup.array().of(
      Yup.object().shape({
        idServicoTuss: Yup.number()
          .nullable()
          .required('O campo código ou descrição é obrigatório'),
        quantidade: Yup.number()
          .nullable()
          .required('O campo quantidade é obrigatório'),
        nivelAlteracao: Yup.string().nullable(),
        resultadoDigitado: Yup.number().nullable(),
        dataResultado: Yup.date()
          .nullable()
          .when([], {
            is: () => hasLaudoArquivo,
            then: field =>
              field.required('O campo data do resultado é obrigatório'),
          }),
      }),
    ),
  });
