import Http, { HttpParams } from 'src/core/http/Http';

export const getDisponibilidadeMedica = (
  idMedico: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: `/disponibilidade-medica?idProfissionalSaude=${idMedico}&idConsultorio=${idConsultorio}`,
    hideToast: true,
    ...options,
  });
};

export const getDisponibilidadeMedicaPendentes = (
  idMedico: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: `/disponibilidade-medica/pendentes?idProfissionalSaude=${idMedico}&idConsultorio=${idConsultorio}`,
    hideToast: true,
    ...options,
  });
};

export const postDisponibilidadeMedica = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'agenda',
    url: '/disponibilidade-medica/salvar-lista',
    data: payload,
    ...options,
  });
};

const DisponibilidadeMedicaAPI = {
  getDisponibilidadeMedica,
  getDisponibilidadeMedicaPendentes,
  postDisponibilidadeMedica,
};

export default DisponibilidadeMedicaAPI;
