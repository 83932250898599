import { Route } from 'react-router-dom';

import { AuthEnforcer, Redirect, ValidateAuth } from './utils';

import { NavBar } from 'src/pages/Portal/Admin/_components/Nav/NavBar';

import { MeusAgendamentos } from 'src/pages/Portal/Admin/Agendamentos/MeusAgendamentos/MeusAgendamentos';
import { NovoAgendamento } from 'src/pages/Portal/Admin/Agendamentos/NovoAgendamento/NovoAgendamento';
import { Home } from 'src/pages/Portal/Admin/Home/Home';
import { MeusDados } from 'src/pages/Portal/Admin/MeusDados/MeusDados';
import PerfilSaude from 'src/pages/Portal/Admin/PerfilSaude/PerfilSaude';
import { ReceitasOrientacoes } from 'src/pages/Portal/Admin/ReceitasOrientacoes/ReceitasOrientacoes';
import { SolicitacoesResultadosExames } from 'src/pages/Portal/Admin/SolicitacoesResultadosExames/SolicitacoesResultadosExames';
import { AuthLink } from 'src/pages/Portal/Auth/AuthLink/AuthLink';
import { Login } from 'src/pages/Portal/Auth/Login';
import { RedefinicaoSenha } from 'src/pages/Portal/Auth/RedefinicaoSenha';
import { Registro } from 'src/pages/Portal/Auth/Registro';
import { ValidacaoUser } from 'src/pages/Portal/Auth/ValidacaoUser';

export default function PortalRoutes() {
  return (
    <Route>
      {/* //* Authenticated Routes */}
      <Route path="/portal/:hashEmpresa" element={<AuthEnforcer />}>
        <Route element={<NavBar />}>
          {/* //? Criar um routerMap */}
          <Route path="/portal/:hashEmpresa/inicio" element={<Home />} />
          <Route
            path="/portal/:hashEmpresa/agendamento/meusAgendamentos"
            element={<MeusAgendamentos />}
          />
          <Route
            path="/portal/:hashEmpresa/agendamento/novoAgendamento"
            element={<NovoAgendamento />}
          />
          <Route
            path="/portal/:hashEmpresa/meusDados"
            element={<MeusDados />}
          />
          <Route
            path="/portal/:hashEmpresa/perfilSaude"
            element={<PerfilSaude />}
          />
          <Route
            path="/portal/:hashEmpresa/receitasOrientacoes"
            element={<ReceitasOrientacoes />}
          />
          <Route
            path="/portal/:hashEmpresa/solicitacoesResultadosExames"
            element={<SolicitacoesResultadosExames />}
          />
          <Route path="/portal/:hashEmpresa/perfilSaude" element={<></>} />
        </Route>
      </Route>

      {/* //* Auth Routes */}
      <Route path="/portal/:hashEmpresa/" element={<ValidateAuth />}>
        <Route
          path="/portal/:hashEmpresa/:hashLogin/auth-link"
          element={<AuthLink />}
        />
        <Route path="/portal/:hashEmpresa/login" element={<Login />} />
        <Route
          path="/portal/:hashEmpresa/login/:hashEmailConfirmation"
          element={<Login />}
        />
        <Route path="/portal/:hashEmpresa/registro" element={<Registro />} />
        <Route
          path="/portal/:hashEmpresa/redefinicao"
          element={<RedefinicaoSenha />}
        />
        <Route
          path="/portal/:hashEmpresa/confirmacao/:user/:hash"
          element={<ValidacaoUser />}
        />
      </Route>

      <Route path="/portal/:hashEmpresa/*" element={<Redirect />} />
      <Route path="/portal/:hashEmpresa" element={<Redirect />} />
    </Route>
  );
}
