import React, { useState } from 'react';

import dayjs from 'dayjs';
import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import useSize from 'src/core/hooks/useSize';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './Row.scss';

interface Props {
  dataDocumento: any;
  setReload: (value: any) => void;
  setSelectedAtendimento: (value: any) => void;
  dialogHistoricoSOAP: DisclosureType;
}

const Row = ({
  dataDocumento,
  setReload,
  setSelectedAtendimento,
  dialogHistoricoSOAP,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingSOAP, setLoadingSOAP] = useState(false);

  const { isTablet } = useSize();
  const {
    webSigner: { handleSign },
    modeloAssinatura,
    handleSignHSM,
  } = useAssinatura();

  const onSignProntuario = async (idAtendimento: any) => {
    setLoading(true);
    try {
      const response = await AtendimentoAPI.getProntuarioAssinar(idAtendimento);
      modeloAssinatura === 'HSM'
        ? await handleSignHSM(response?.data?.prontuarioBase64, {
            idAtendimento: idAtendimento,
          })
        : await handleSign(
            {
              documents: [
                {
                  content: response?.data?.prontuarioBase64,
                  contentType: 'BASE64',
                },
              ],
            },
            {
              sequenciaAssinatura: response?.data?.sequenciaAssinatura,
              tipoDocumento: 'EXAMES_CIRURGIAS_GUIA',
            },
            [idAtendimento],
          );
      setReload((prev: number) => prev + 1);
    } catch (err: any) {
      console.log(err);
    }

    setLoading(false);
  };

  const onOpenSOAP = async (idAtendimento: any) => {
    if (!idAtendimento) return;
    setLoadingSOAP(true);
    try {
      const response = await HistoricoAtendimentosAPI.getAtendimentoById(
        idAtendimento,
        { throwError: true },
      );
      setSelectedAtendimento(response);
      dialogHistoricoSOAP.open();
    } catch (err: any) {
      setSelectedAtendimento(null);
    } finally {
      return setLoadingSOAP(false);
    }
  };

  return (
    <div className="row-item-assinatura p-mb-2 p-ai-center">
      <SimpleText className="p-col-4 p-md-2">
        {dataDocumento.dataOcorrencia
          ? dayjs(new Date(dataDocumento?.dataOcorrencia)).format(
              'DD/MM/YYYY HH:mm',
            )
          : dayjs(new Date(dataDocumento?.dataAgendamento)).format(
              'DD/MM/YYYY HH:mm',
            )}
      </SimpleText>
      <SimpleText className="p-col-4 p-md-5">
        {dataDocumento?.paciente?.nome}
      </SimpleText>
      {!isTablet && (
        <SimpleText className="p-col-3 p-md-2">
          {dataDocumento?.paciente?.dataNascimento
            ? dayjs(new Date(dataDocumento?.paciente?.dataNascimento)).format(
                'DD/MM/YYYY',
              )
            : 'Não informado'}
        </SimpleText>
      )}

      <div className="p-col p-d-flex p-gap-1 p-jc-end">
        <Button
          icon="fas fa-file-signature"
          onClick={() => onSignProntuario(dataDocumento.id)}
          loading={loading}
          btnType="gray"
          stretch
        />
        <Button
          icon="fas fa-receipt"
          onClick={() => onOpenSOAP(dataDocumento.id)}
          loading={loadingSOAP}
          btnType="gray"
          stretch
        />
      </div>
    </div>
  );
};

export default Row;
