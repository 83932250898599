import { memo, useCallback, useEffect, useState } from 'react';

import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Tab from 'src/components/Tab/Tab';

import CartoesPaciente from './CartoesPaciente/CartoesPaciente';
import FormDadosPessoais from './FormDadosPessoasis/FormDadosPessoais';
import FormEndereco from './FormEndereco/FormEndereco';

const EditarPaciente = ({ state, close }: DisclosureType) => {
  const [paciente, setPaciente] = useState<any>(state);
  const [loading, setLoading] = useState(false);
  const IdPaciente = state.id;

  const [endereco, setEndereco] = useState(paciente?.enderecos?.[0]);

  const [foto, setFoto] = useState(paciente?.foto);

  const handleCancel = () => {
    close();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await PacienteAPI.loadPacienteById(IdPaciente, { throwError: true })
        .then(response => {
          setPaciente(response);
        })
        .catch(e => {
          console.log(e);
          setPaciente({});
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [IdPaciente, paciente.id]);

  useEffect(() => {
    const aux = paciente?.enderecos?.[0];
    setEndereco({
      ...aux,
      idCidade: aux?.cidade?.id,
      idEstado: aux?.cidade?.estado?.id,
      idPais: aux?.cidade?.estado?.idPais,
    });
  }, [paciente?.enderecos]);

  const renderFormDadosPessoais = () => {
    return (
      <>
        <FormDadosPessoais
          paciente={paciente}
          handleCancel={handleCancel}
          foto={foto}
          setFoto={setFoto}
        />
      </>
    );
  };

  return (
    <div>
      <Dialog
        visible
        style={{ width: '750px' }}
        header="Editar Paciente"
        onHide={() => close()}
        className="editar-paciente-dialog"
        maximizedTabletSize
      >
        <Skeleton loading={loading}>
          <Tab
            clean
            values={[
              {
                label: 'Informações',
                content: renderFormDadosPessoais(),
              },
              {
                label: 'Endereço',
                content: (
                  <FormEndereco
                    values={endereco}
                    setEndereco={setEndereco}
                    handleCancel={handleCancel}
                    idPaciente={paciente?.id}
                  />
                ),
              },
              {
                label: 'Pagamento e plano de saúde',
                content: (
                  <CartoesPaciente
                    paciente={paciente}
                    onCancel={handleCancel}
                    idPaciente={paciente?.id}
                  />
                ),
              },
            ]}
          />
        </Skeleton>
      </Dialog>
    </div>
  );
};

export default memo(EditarPaciente);
