import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { ComplexidadePacienteAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/ComplexidadePacienteAPI.ts/ComplexidadePacienteAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type Props = DropdownProps & {
  idConsultorio: number;
  name?: string;
  label: string;
};

export const FieldComplexidadePaciente = ({
  idConsultorio,
  name = 'complexidadePaciente',
  label,
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [items, setItems] = useState<ComplexidadePacienteDTO[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const load = useCallback(async () => {
    setLoadingSearch(true);

    const response = await ComplexidadePacienteAPI.getComplexidadeLista({
      idConsultorio,
    });

    if (Array.isArray(response)) setItems(response);

    setLoadingSearch(false);
  }, [idConsultorio]);

  useEffect(() => {
    load();
  }, [load]);

  const options = useMemo(() => {
    const options = items.map(item => ({
      label: item.descricao,
      value: item,
    }));

    return options;
  }, [items]);

  return (
    <DropdownControlled
      className="p-col-12"
      dataKey="id"
      control={control}
      name={name}
      label={label}
      options={options}
      errorMsg={errors?.[name]?.message}
      customOnChange={rest.onChange ? rest.onChange : () => null}
      labelOverflow="hidden"
      loading={loadingSearch}
      {...rest}
    />
  );
};
