import React, { useCallback, useEffect, useState } from 'react';

import { CalendarChangeParams } from 'primereact/calendar';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { fullNumbersStringDateToFormatedString } from 'src/utils/date';
import { dateToRequest } from 'src/utils/utils';

import Button from 'src/components/Basics/Button/Buttons';
import IconButton from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Calendar from 'src/components/Calendar/Calendar';
import './EscolhaHorarioLivre.scss';

const WEEK_DAYS = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

interface EscolhaHorarioLivreProps {
  dataInicial?: Date;
  editAgendamento?: AtendimentoAgendamento;
  onSelect?(date: AtendimentoAgendamento, horario: string): void;
  onHide(): void;
  pacienteEspera?: any;
}
const EscolhaHorarioLivre = ({
  dataInicial,
  onSelect,
  onHide,
  editAgendamento,
  pacienteEspera,
}: EscolhaHorarioLivreProps) => {
  const navigate = useNavigate();

  const { agenda, consultorios } = useAppSelector(state => state);
  const [minimized, setMinimized] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(dataInicial || new Date());
  const [availables, setAvailables] = useState<AgendamentosDia[]>([]);

  const loadAvailableHours = useCallback(async () => {
    try {
      if (agenda?.profissionalAtivo && consultorios?.ativo) {
        const dateAux = new Date(date);
        dateAux.setDate(dateAux.getDate() + 4);
        const aux = await AgendamentosAPI.loadHorariosLivres(
          agenda.profissionalAtivo.id,
          consultorios.ativo.id,
          dateToRequest(date)?.split('T')[0]?.replaceAll('-', '') || '',
          dateToRequest(dateAux)?.split('T')[0]?.replaceAll('-', '') || '',
        );
        if (aux) {
          setAvailables(aux);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [agenda, consultorios, date]);

  useEffect(() => {
    loadAvailableHours();
  }, [loadAvailableHours]);

  const renderTimestamp = useCallback(
    (agendamento: AtendimentoAgendamento, _date: string) => {
      const hour = dayjs(agendamento?.dataAgendamento).format('HH:mm');
      return (
        <div
          className="timestamp"
          onClick={() => {
            if (onSelect) {
              onSelect(agendamento, _date);
            }
          }}
        >
          <SimpleText fontColor={FONT_COLOR.COLOR_DARK_GREEN} bold>
            {hour}
          </SimpleText>
        </div>
      );
    },
    [onSelect],
  );

  const gerarEncaixe = useCallback(async () => {
    navigate('/agenda/novo-encaixe', {
      state: {
        screen: 'Encaixe',
        date: new Date(date).setTime(new Date().getTime()),
        editAgendamento,
        pacienteEspera,
      },
    });
    onHide();
  }, [date, editAgendamento, navigate, onHide, pacienteEspera]);

  const renderHeader = useCallback(() => {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow: Date = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return (
      <div className="header">
        {(availables || []).map((d: AgendamentosDia, i: number) => {
          let label = '';
          const formattedDate = fullNumbersStringDateToFormatedString(
            d.data,
          ).split('/');
          let aux;
          if (
            formattedDate &&
            Array.isArray(formattedDate) &&
            formattedDate.length > 1
          ) {
            aux = new Date(
              parseInt(formattedDate[2] || '0') || 0,
              parseInt(formattedDate[1] || '0') - 1 || 0,
              parseInt(formattedDate[0] || '0') || 0,
              0,
              0,
              0,
              0,
            );
          }

          if (!aux) {
            label = '';
          } else {
            //console.log('today: ', today.getTime());
            //console.log('tomorrow: ', tomorrow.getTime());
            //console.log('aux: ', aux.getTime());
            if (today.getTime() === aux.getTime()) {
              label = 'Hoje';
            } else if (tomorrow.getTime() === aux.getTime()) {
              label = 'Amanhã';
            } else {
              label = WEEK_DAYS[aux.getDay()] || '';
            }
          }

          const data = formattedDate[0] + '/' + formattedDate[1];

          return (
            <div key={`${Math.random()}:${i}`} className="timestamp-header">
              <div>{label}</div>
              <div>{data}</div>
            </div>
          );
        })}
      </div>
    );
  }, [availables]);

  const renderAvailableTimestamps = useCallback(
    values => {
      return (
        <div className="row">
          {(values || []).map((agendamentos: any, j: number) => {
            let aux = agendamentos?.atendimentos ?? [];
            if (minimized) {
              aux = aux.slice(0, 5);
            }
            return (
              <div key={j} className="column">
                {aux.map((timestamp: any, i: number) => {
                  return (
                    <div key={i}>
                      {renderTimestamp(timestamp, agendamentos.data)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    },
    [minimized, renderTimestamp],
  );

  const renderTimestampsTable = useCallback(() => {
    return (
      <>
        {renderHeader()}
        {renderAvailableTimestamps(availables)}
      </>
    );
  }, [availables, renderAvailableTimestamps, renderHeader]);

  return (
    <div className="EscolhaHorarioLivre">
      <SimpleText>
        Clique em um horário disponível para agendar o paciente.
      </SimpleText>
      <div className="title">
        <SimpleText fontSize={FONT_SIZE.MD}>
          {MONTHS[date.getMonth() || -1] || ''}
        </SimpleText>
        <IconButton
          icon={'pi pi-calendar'}
          overlay={
            <Calendar
              value={date}
              monthNavigator
              yearNavigator
              yearRange="2020:2050"
              hideOnDateTimeSelect
              onChange={(e: CalendarChangeParams) => {
                console.log(e);
                if (Array.isArray(e.value)) {
                  return;
                }
                setDate(e.value || new Date());
              }}
            />
          }
        />
      </div>

      <div className="dates">{renderTimestampsTable()}</div>
      <Button
        label={minimized ? 'Mais horários' : 'Menos'}
        btnType="outline"
        icon={minimized ? 'pi pi-calendar-down' : 'pi pi-angle-up'}
        onClick={() => {
          setMinimized(prev => !prev);
        }}
      />
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          label={'Cancelar'}
          btnType="ghost"
          onClick={() => {
            onHide();
          }}
          stretch
        />

        <Button
          label={'Gerar encaixe'}
          btnType="tonal"
          onClick={gerarEncaixe}
          stretch
        />
      </div>
    </div>
  );
};

export default EscolhaHorarioLivre;
