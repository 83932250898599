export const mergeMultipleObjects = (objects: any[]) => {
  const merged: any = {
    atividadesVidaDiaria: {
      autoCuidadoKATZ: [],
      avdsInstrumentais: [],
      avdsAvancadas: [],
    },
    cognicaoHumor: {
      MEEM: [],
      desenhoRelogio: [],
      fluenciaVerbal: [],
      humor: [],
    },
    mobilidade: {
      TUG: [],
      velocidadeMarcha: [],
      dinamometro: [],
      alcanceFuncional: [],
      preensaoPinca: [],
      continenciaUrinaria: [],
      cotinenciaFecal: [],
    },
    comunicacao: {
      visao: [],
      audicao: [],
      falaVoz: [],
    },
    avaliacaoGeral: {
      idade: [],
      manIdoso: [],
      fenotipoFried: [],
      comorbidades: [],
    },
    riscoSocioAmbiental: {
      escalaGijon: [],
      quedas: [],
    },
  };

  // Itera sobre cada objeto no array
  objects.forEach(obj => {
    // Itera sobre cada categoria
    for (const category in merged) {
      for (const subCategory in merged[category]) {
        // Se a propriedade existe no objeto, adiciona os dados
        if (obj[category] && obj[category][subCategory]) {
          const dataArray = Array.isArray(obj[category][subCategory])
            ? obj[category][subCategory]
            : [obj[category][subCategory]];

          merged[category][subCategory].push(...dataArray);
        } else {
          if (!merged[category]) {
            merged[category] = {};
          }
          if (!merged[category][subCategory]) {
            merged[category][subCategory] = null;
          }
        }
      }
    }
  });

  return merged;
};

export const sumPontuacoesByIndex = (diagnostico: any) => {
  const categorias: any = Object.values(diagnostico);
  const firstCategoria = categorias[0];
  const firstKey: any = Object.keys(firstCategoria)[0];
  const numItems = firstCategoria[firstKey]?.length || 0;
  const somas = Array(numItems).fill(0);

  categorias.forEach((categoria: any) => {
    Object.values(categoria).forEach((items: any) => {
      if (Array.isArray(items)) {
        items.forEach((item: { pontuacao: number }, index: number) => {
          somas[index] += item?.pontuacao;
        });
      }
    });
  });

  return somas;
};
