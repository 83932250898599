import { ReactNode, useState, useMemo } from 'react';

import { CreateProfissionalSaudeContext } from '../../contexts/ManterUsuario/CreateProfissionalSaude';

interface CreateProfissionalSaudeProviderProps {
  children: ReactNode;
}

export function CreateProfissionalSaudeProvider({ children }: CreateProfissionalSaudeProviderProps) {
  const [checkedProfissionalExecutante, setCheckedProfissionalExecutante] = useState<boolean>(false);
  const [dadosProfissionais, setDadosProfissionais] = useState<any>({});
  const [dadosProfissionaisStepCompleted, setDadosProfissionaisStepCompleted] = useState<boolean>(false);
  const [profExecutanteStepCompleted, setProfExecutanteStepCompleted] = useState<boolean>(false);

  function setStep3(newData: any) {

    const [listaConfigTiss = [], listaEspecialidade = []] = newData;

    setDadosProfissionais((oldState: any) => ({
      ...oldState,
      listaConfigTiss,
      listaEspecialidade,
    }))
  }

  function setStep4(profissionalExecutante: any) {
    setDadosProfissionais((oldState: any) => ({
      ...oldState,
      profissionalExecutante,
    }));

    setProfExecutanteStepCompleted(true);
  }

  return (
    <CreateProfissionalSaudeContext.Provider
      value={{
        checkedProfissionalExecutante, 
        dadosProfissionais,
        dadosProfissionaisStepCompleted, 
        profExecutanteStepCompleted, 
        setDadosProfissionaisStepCompleted,
        setProfExecutanteStepCompleted,
        setCheckedProfissionalExecutante,
        setStep3,
        setStep4,
        setDadosProfissionais,
      }}
    >
      {children}
    </CreateProfissionalSaudeContext.Provider>
  );
}
