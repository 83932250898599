/* eslint-disable react/prop-types */
import { useState } from 'react';

import UsuariosSimultaneosAPI from 'src/APIs/ConfigAPI/UsuariosSimultaneos/UsuariosSimultaneosAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import { Row } from './UsuariosSimultaneosGrid/Row';

import './UsuariosSimultaneos.scss';

const UsuariosSimultaneos = () => {
  const { idEmpresa } = useAppSelector(state => state.user);
  const [reload, setReload] = useState(0);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_usuarios_simultaneos_cadastrar',
      'adm_usuarios_simultaneos_alterar',
      'adm_usuarios_simultaneos_consultar',
      'adm_usuarios_simultaneos_excluir',
    ],
  });

  const fetchApi = async (params: any): Promise<any> => {
    return await UsuariosSimultaneosAPI.loadUsuariosSimultaneos({
      nomeLogin: params.query,
      idEmpresa,
      ...params,
    });
  };

  const renderRow = (data: any) => <Row data={data} />;

  return (
    <>
      <div className="UsuariosSimultaneos">
        <SearchList.Root
          fetchApi={fetchApi}
          empresaRequired
          reloadWhenEmpresaChange
        >
          <SearchList.BorderContainer>
            <SearchList.SearchInput placeholder="Pesquise por usuários" />

            <Header />

            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </>
  );
};

export default UsuariosSimultaneos;
