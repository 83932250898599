import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = 'preatendimento-observacoes';

export const getObservacoesGerais = (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/atendimento/${idAtendimento}`,
    params,
    hideToast: true,
    ...options,
  });

export const adicionarObservacaoGeral = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: baseUrl,
    data: payload,
    return: true,
    ...options,
  });

export const alterarObservacaoGeral = (
  idObservacaoGeral: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idObservacaoGeral}/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const inativarObservacaoGeral = (
  idObservacaoGeral: number,
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idObservacaoGeral}/atendimento/${idAtendimento}/inativar`,
    data: payload,
    return: true,
    ...options,
  });

export const removerObservacaoGeral = (
  idObservacaoGeral: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${baseUrl}/${idObservacaoGeral}/atendimento/${idAtendimento}`,
    return: true,
    ...options,
  });

const ObservacaoGeralAPI = {
  getObservacoesGerais,
  adicionarObservacaoGeral,
  alterarObservacaoGeral,
  inativarObservacaoGeral,
  removerObservacaoGeral,
};

export default ObservacaoGeralAPI;
