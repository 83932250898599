import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

import { ModalCertificadoSenha } from './ModalCertificadoSenha/ModaCertificadoSenha';

const SignConfigSubMenu = () => {
  const { hsmValidDate } = useAssinatura();
  const modalSign = useDisclosure({ opened: false });

  return (
    <div className="ItemsConfigSubMenu">
      <i className="icon fas fa-file-signature" />
      <div className="content">
        <SimpleText bold>Salvar senha certificado</SimpleText>
      </div>
      <Switch checked={hsmValidDate} onChange={() => modalSign.open()} />
      {modalSign.isOpen && <ModalCertificadoSenha {...modalSign} />}
    </div>
  );
};

export default SignConfigSubMenu;
