import React from 'react';

import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import {
  chipTypeDiagnosticos,
  chipTypeNivelDiagnosticos,
  chipTypeStatusCid,
  diagnosticos,
  nivelDiagnosticos,
  statusCid,
} from '../../../HistoricoAtendimentos';

interface PanelContentProps {
  cidCiap: any;
}

export const PanelContent = ({ cidCiap }: PanelContentProps) => {
  const headerTextClassName = `p-mb-2 ${
    cidCiap.ativo === true ? '' : 'line-through row-inativa'
  }`;
  const dataTextClassName =
    cidCiap.ativo === true ? '' : 'line-through row-inativa';

  return (
    <div className="panel-content p-p-2">
      <div className="p-d-flex p-gap-1 fixed-corner-right">
        {cidCiap.estadoDiagnosticoCid ? (
          <div className={chipTypeDiagnosticos[cidCiap.estadoDiagnosticoCid]}>
            <SimpleText fontSize={FONT_SIZE.XXXS} medium>
              {diagnosticos[cidCiap.estadoDiagnosticoCid]}
            </SimpleText>
          </div>
        ) : null}
        {cidCiap.nivelDiagnosticoCid ? (
          <div
            className={chipTypeNivelDiagnosticos[cidCiap.nivelDiagnosticoCid]}
          >
            <SimpleText fontSize={FONT_SIZE.XXXS} medium>
              {nivelDiagnosticos[cidCiap.nivelDiagnosticoCid]}
            </SimpleText>
          </div>
        ) : null}

        <div className={chipTypeStatusCid[cidCiap.ativo]}>
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            {statusCid[cidCiap.ativo]}
          </SimpleText>
        </div>
      </div>
      {cidCiap.retroativo && (
        <div className="p-col-12 p-d-flex p-flex-column">
          <div className="p-d-flex p-jc-between">
            <SimpleText
              className={headerTextClassName}
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Justificativa para registro retroativo
            </SimpleText>
          </div>
          <SimpleText
            className={dataTextClassName}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {cidCiap.justificativaOcorrenciaRetroativa}
          </SimpleText>
        </div>
      )}
      <div className="p-col-12 p-d-flex">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          {cidCiap.ativo ? 'Adicionado' : 'Inativado'} por{' '}
          <b>{cidCiap.usuarioAtualizacao?.nome}</b> em{' '}
          {dayjs(cidCiap.dataInclusao)
            .locale('pt-br')
            .format('DD MMM [de] YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
};
