import { ReactNode, useCallback, useLayoutEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';

import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import {
  TelemedicinaContext,
  TelemedicinaAtendimentoProps,
} from 'src/core/contexts/Telemedicina/Telemedicina';
import { setUser } from 'src/core/redux/slices/system/UserSlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { NavBar } from 'src/pages/Emed/Telemedicina/components/NavBar/NavBar';

interface IProps {
  children?: ReactNode;
}

export function TelemedicinaProvider({ children }: IProps) {
  const { codEmpresa } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [atendimentoData, setAtendimentoData] =
    useState<null | TelemedicinaAtendimentoProps>(null);
  const [roomURL, setRoomURL] = useState<string>('');

  useLayoutEffect(() => {
    TelemedicinaSalaAtendimentoAPI.authorizationTelemedicina().then(d =>
      dispatch(setUser(d)),
    );
  }, [dispatch]);

  useEffectSkipFirst(() => {
    if (!!roomURL && !!atendimentoData)
      navigate(
        `/t/sala/${roomURL}/atendimento/${atendimentoData.idAtendimento}`,
        { state: codEmpresa },
      );
  }, [roomURL]);

  const handleSetAtendimento = useCallback(data => {
    setAtendimentoData(data);
  }, []);

  return (
    <>
      <NavBar />
      <TelemedicinaContext.Provider
        value={{ atendimentoData, handleSetAtendimento, setRoomURL, roomURL }}
      >
        {children}

        <Outlet />
      </TelemedicinaContext.Provider>
    </>
  );
}
