import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import { HistoricoAtendimentos } from '../Atendimento/HistoricoAtendimentos/HistoricoAtendimentos';

import DadosNascimento from './DadosNacimento/DadosNascimento';
import Dnpm from './Dnpm/Dnpm';
import Observacoes from './Observacoes/Observacoes';
import PreNatal from './PreNatal/PreNatal';
import Triagem from './Triagem/Triagem';

interface PuericulturaProps {
  paciente: any | null;
  idAtendimento: any;
  usuario: any;
  idPaciente: number | null;
}

function Puericultura({
  paciente,
  idAtendimento,
  usuario,
  idPaciente,
}: PuericulturaProps) {
  const { campos } = useAtendimento();
  const tabs = [
    {
      label: 'Pré-Natal',
      content: <PreNatal paciente={paciente} idAtendimento={idAtendimento} />,
      funcionalidade: 'PUERICULTURA_PRE_NATAL',
    },
    {
      label: 'Dados do nascimento',
      content: <DadosNascimento paciente={paciente} />,
      funcionalidade: 'PUERICULTURA_DADOS_DO_NASCIMENTO',
    },
    {
      label: 'Triagem',
      content: <Triagem />,
      funcionalidade: 'PUERICULTURA_TRIAGEM',
    },
    {
      label: 'DNPM',
      modal: (visible: any, onClose: any) => (
        <Dnpm
          visible={visible}
          paciente={paciente}
          idAtendimento={Number(idAtendimento)}
          onClose={onClose}
        />
      ),
      funcionalidade: 'PUERICULTURA_DNPM',
    },
    {
      label: 'Observações',
      content: <Observacoes />,
      funcionalidade: 'PUERICULTURA_OBSERVACOES',
    },
  ];

  const tabsFiltered = tabs.filter(tab => {
    const campoTab = campos?.find(
      campo => campo.funcionalidade === tab.funcionalidade,
    );
    return campoTab?.exibicaoCamposModelo !== 'OCULTO';
  });

  return (
    <>
      <VerticalTab tabItens={tabsFiltered} />
      <HistoricoAtendimentoProvider>
        <HistoricoAtendimentos usuario={usuario} idPaciente={idPaciente} />
      </HistoricoAtendimentoProvider>
    </>
  );
}

export default Puericultura;
