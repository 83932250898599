import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { FeedbackAgradecimento } from '../Feedback/FeedbackAgradecimento';

import './AgradecimentoDialog.scss';

export const AgradecimentoDialog = ({ isOpen, close }: DisclosureType) => {
  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      modal={false}
      className="dialog-agradecimento-portal"
      position="bottom-right"
      header={
        <SimpleText fontSize="sm" fontColor="color_40" medium>
          Obrigado por compartilhar a sua opinião.
        </SimpleText>
      }
    >
      <FeedbackAgradecimento close={close} />
    </Dialog>
  );
};
