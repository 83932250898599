import { ReceitaMemedProps } from 'src/models/APIs/ProntuarioAPI/Receita/ReceitaDTOs';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/receitas-orientacoes';

const getReceitasOrientacoes = (
  params: {
    dataInicial?: string | undefined;
    dataFinal?: string | undefined;
    tipo?: string;
    idProfissionalSaude?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/pesquisar`,
    ...options,
    params: params,
    hideToast: true,
  });
};

const getReceitasOrientacoesTipos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/tipo`,
    ...options,
    hideToast: true,
  });
};

const getReceitasOrientacoesProfissionais = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/profissional`,
    ...options,
    hideToast: true,
  });
};

export const ReceitasOrientacoesAPI = {
  getReceitasOrientacoes,
  getReceitasOrientacoesTipos,
  getReceitasOrientacoesProfissionais,
};
