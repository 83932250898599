import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <div className="p-col-1">
            <SimpleText fontSize={FONT_SIZE.XXS}>Código</SimpleText>
          </div>
          <div className="p-col-8">
            <SimpleText fontSize={FONT_SIZE.XXS}>Descrição</SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText fontSize={FONT_SIZE.XXS}>Status</SimpleText>
          </div>

          <Can
            checkPermission={[
              'ADM_CONFIGURACAO_TISS_ALTERAR',
              'ADM_CONFIGURACAO_TISS_EXCLUIR',
            ]}
          >
            <div className="p-col-1">
              <SimpleText fontSize={FONT_SIZE.XXS}>Ações</SimpleText>
            </div>
          </Can>
        </div>
      )}
    </>
  );
};
