import { memo, useCallback } from 'react';

import ConfiguracaoGuiaTissAPI from 'src/APIs/ConfigAPI/ConfiguracaoGuiaTiss/ConfiguracaoGuiaTissAPI';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalExcluirConfiguracaoGuiaTissProps {
  visible: boolean;
  onHide(): void;
  onSuccess(): void;
  configuracaoGuiaTiss: ConfiguracaoGuiaTiss;
  configuracaoGuiaTissList: ConfiguracaoGuiaTiss[];
  setConfiguracaoGuiaTiss: any;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className={'p-col-align-center p-d-flex'}
        onClick={onCancel}
        stretch
      />
      <Button
        btnType="danger"
        className={'danger'}
        label="Sim, excluir"
        onClick={onAdd}
        stretch
      />
    </div>
  );
});

const ModalExcluirConfiguracaoGuiaTiss = ({
  configuracaoGuiaTiss,
  visible,
  onHide,
  configuracaoGuiaTissList,
  setConfiguracaoGuiaTiss,
  onSuccess,
}: ModalExcluirConfiguracaoGuiaTissProps) => {
  const handleDeletarConfiguracaoTiss = useCallback(async () => {
    const response: any =
      await ConfiguracaoGuiaTissAPI.removeConfiguracaoGuiaTiss(
        configuracaoGuiaTiss.id!,
      );

    if (response?.status === 204) {
      const configuracaoGuiaTissRemovida = configuracaoGuiaTissList.filter(
        c => c.id !== configuracaoGuiaTiss.id,
      );
      setConfiguracaoGuiaTiss([...configuracaoGuiaTissRemovida]);
      onHide();
      onSuccess();
    }
  }, [
    configuracaoGuiaTiss,
    configuracaoGuiaTissList,
    onHide,
    onSuccess,
    setConfiguracaoGuiaTiss,
    onSuccess,
  ]);

  const handleCancelConfiguracaoGuiaTiss = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Excluir guia TISS"
      visible={visible}
      onHide={handleCancelConfiguracaoGuiaTiss}
      footer={() => (
        <Footer
          onAdd={handleDeletarConfiguracaoTiss}
          onCancel={handleCancelConfiguracaoGuiaTiss}
        />
      )}
    >
      <SimpleText fontColor={FONT_COLOR.COLOR_40} className="p-pl-2">
        Você realmente deseja excluir esta guia TISS?
      </SimpleText>
    </Dialog>
  );
};

export default ModalExcluirConfiguracaoGuiaTiss;
