import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  especialidade: any;
  onSwitchStatus: (especialidade: any) => void;
  onEdit: (especialidade: any) => void;
  onDelete: (especialidade: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { especialidade, onSwitchStatus, onEdit, onDelete } = props;

  const options = [
    {
      label: !!especialidade?.status ? 'Inativar' : 'Ativar',
      command: () => onSwitchStatus(especialidade),
      checkPermission: 'ADM_ESPECIALIDADE_ALTERAR',
    },
    {
      label: 'Editar',
      command: () => onEdit(especialidade),
      checkPermission: 'ADM_ESPECIALIDADE_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(especialidade),
      checkPermission: 'ADM_ESPECIALIDADE_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {especialidade?.nome}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
