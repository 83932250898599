import React, { useEffect } from 'react';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

const BiometriaOK = () => {
  useEffect(() => {
    window.parent.postMessage({ type: 'biometria', status: 'ok' }, '*');
  }, []);
  return (
    <div
      className="p-d-flex p-ai-center p-jc-center"
      style={{ width: '100%', height: '100%' }}
    >
      <SpinnerLoading size="lg" full />
    </div>
  );
};

export default BiometriaOK;
