import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistStore } from 'redux-persist';
import App from 'src/App/App';

import { UserProvider } from './core/containers/Users/Users';
import { store } from 'src/core/redux/store';

import reportWebVitals from './reportWebVitals';

import './index.scss';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </UserProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
