import Http, { HttpParams } from 'src/core/http/Http';

export const loadSatisfacaos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSatisfacaoDTO[]> => {
  return Http.get({
    service: 'adm',
    url: '/satisfacao/busca-satisfacoes',
    ...options,
  });
};

export const loadSatisfacaoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSatisfacaoDTO> => {
  return Http.get({
    service: 'adm',
    url: `/satisfacao/busca-satisfacao/${id}`,
    ...options,
  });
};

export const sendSatisfacao = (
  payload: PostSatisfacaoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostSatisfacaoDTO> => {
  return Http.post({
    service: 'adm',
    url: '/satisfacao/salva-satisfacao',
    data: payload,
    ...options,
  });
};

export const updateSatisfacao = (
  id: number,
  payload: PostSatisfacaoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostSatisfacaoDTO> => {
  return Http.put({
    service: 'adm',
    url: `/satisfacao/salva-satisfacao/${id}`,
    data: payload,
    ...options,
  });
};

export const removeSatisfacaoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetSatisfacaoDTO> => {
  return Http.delete({
    service: 'adm',
    url: `/satisfacao/remove-satisfacao/${id}`,
    ...options,
  });
};

const SatisfacaoAPI = {
  loadSatisfacaos,
  loadSatisfacaoById,
  sendSatisfacao,
  removeSatisfacaoById,
  updateSatisfacao,
};

export default SatisfacaoAPI;
