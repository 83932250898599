import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import EmpresaProvedorSmsAPI from 'src/APIs/EmpresaProvedorSmsAPI/EmpresaProvedorSmsAPI';
import * as Yup from 'yup';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

interface ServicoMensagemPageProps {
  values: EmpresaProvedorSms;
  idEmpresa: string | undefined;
}

const ServicoMensagem = ({ values, idEmpresa }: ServicoMensagemPageProps) => {
  const navigate = useNavigate();

  const formValidation = useCallback((): any => {
    return Yup.object().shape({
      provedor: Yup.string().required('O campo Provedor é obrigatório.'),
      urlApi: Yup.string().required('O campo Link é obrigatório.'),
      usuarioApi: Yup.string().required('O campo usuário é obrigatório'),
      senhaApi: Yup.string().required('O campo Password é obrigatório.'),
      callbackStatus: Yup.boolean(),
      callbackConfirmacao: Yup.string().when('callbackStatus', {
        is: (e: boolean) => !!e,
        then: Yup.string().required('Campo é obrigatório'),
      }),
      callbackCancelamento: Yup.string().when('callbackStatus', {
        is: (e: boolean) => !!e,
        then: Yup.string().required('Campo é obrigatório'),
      }),
    });
  }, []);

  const useFormMethods = useForm<any>({
    resolver: yupResolver(formValidation()),
    defaultValues: {
      provedor: values?.provedor || '',
      urlApi: values?.urlApi || '',
      usuarioApi: values?.usuarioApi || '',
      senhaApi: values?.senhaApi || '',
      callbackStatus: values?.callbackStatus || false,
      callbackConfirmacao: values?.callbackConfirmacao || '',
      callbackCancelamento: values?.callbackCancelamento || '',
    } as any,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useFormMethods;

  const provedor = watch('provedor');

  const handleCancel = useCallback(() => {
    reset();
    navigate(-1);
  }, [navigate, reset]);

  const handleEmpresa = useCallback(
    async (formData: any) => {
      let response: any;

      const data = {
        ...formData,
        idEmpresa: Number(idEmpresa || '') || undefined,
      };

      if (values && Object.keys(values)?.length > 0) {
        response = await EmpresaProvedorSmsAPI.updateEmpresaProvedorSms(data);
      } else {
        response = await EmpresaProvedorSmsAPI.sendEmpresaProvedorSms(data);
      }

      if (response?.status === 201 || response?.status === 200) {
        handleCancel();
      }
    },
    [handleCancel, idEmpresa, values],
  );

  const callbackToggle = watch('callbackStatus');

  useEffectSkipFirst(() => {
    setValue('urlApi', '');
    setValue('usuarioApi', '');
    setValue('senhaApi', '');
  }, [provedor]);

  return (
    <div className="ServicoMensagem p-grid p-col-12 p-px-0">
      <SimpleText
        className="p-col-12"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
      >
        Serviço de mensagem
      </SimpleText>
      <SimpleText
        className="p-col-12"
        fontColor={FONT_COLOR.COLOR_40}
        fontSize={FONT_SIZE.XS}
      >
        Configure o sistema de envio de mensagens SMS do cliente
      </SimpleText>
      <FormProvider {...useFormMethods}>
        <form
          className="p-grid p-col-12 p-px-0 p-pb-4"
          onSubmit={handleSubmit(handleEmpresa)}
        >
          <FieldServicos />
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.urlApi?.message}
            name="urlApi"
            label="Link da API"
            placeholder="http://"
            maxLength={200}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12 p-sm-6"
            errorMsg={errors.usuarioApi?.message}
            name="usuarioApi"
            label="Usuário"
            maxLength={64}
          />
          <PasswordInputControlled
            className="p-col-12 p-sm-6"
            errorMsg={errors.senhaApi?.message}
            name="senhaApi"
            control={control}
            label="Senha"
            toggleMask
            maxLength={200}
          />

          <SimpleText className="p-col-12" medium>
            Callback
          </SimpleText>
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Ative para permitir a configuração de confirmação e
            callbackCancelamento
          </SimpleText>

          <SwitchControlled
            control={control}
            name="callbackStatus"
            label={callbackToggle ? 'Ativo' : 'Desativado'}
            className="p-col-12"
          />

          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Os campos abaixo são para configuração do retorno definido pelo
            fornecedor
          </SimpleText>

          <FormInput
            hideTextErrorSpace
            name="callbackConfirmacao"
            label="Confirmação"
            errorMsg={errors.callbackConfirmacao?.message}
            className="p-col-6"
            maxLength={10}
          />
          <FormInput
            hideTextErrorSpace
            name="callbackCancelamento"
            label="Cancelamento"
            errorMsg={errors.callbackCancelamento?.message}
            className="p-col-6"
            maxLength={10}
          />

          <div className="p-col-12 p-sm-4">
            <Button
              className="p-col-align-center p-d-flex"
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={handleCancel}
              disabled={isSubmitting}
              stretch
            />
          </div>
          <div className="p-col-12 p-sm-8">
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const FieldServicos = () => {
  const { id } = useParams();
  const { control } = useFormContext();

  const [options, setOptions] = useState<any[]>([]);

  const getServicos = useCallback(async () => {
    if (!id) return;

    try {
      const res = await EmpresaProvedorSmsAPI.loadEmpresaProvedores(id, {
        throwError: true,
      });

      setOptions(
        res.map((serv: any) => ({
          label: serv.descricao,
          value: serv.codigo,
        })),
      );
    } catch {}
  }, [id]);

  useEffect(() => {
    getServicos();
  }, [getServicos]);

  return (
    <DropdownControlled
      control={control}
      name="provedor"
      label="Provedor do serviço"
      options={options}
      className="p-col-12"
      filter={false}
    />
  );
};

export default ServicoMensagem;
