import { useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Skeleton from 'src/components/Skeleton/Skeleton';

import { severidadeOptions } from '../../../helpers';

import './SeveridadeButton.scss';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

type SeveridadeMenu = TieredMenu & {
  hide: () => void;
};

interface SeveridadeButtonProps {
  idAtendimento: number;
  idProcedimento: number;
  isProcedimentoExterno: boolean;
  disabled: boolean;
  severidadeAlteracao?: string;
}

const getSeveridadeLabel = (severidadeValue: string | undefined) => {
  if (!severidadeValue) return 'Severidade';

  const statusOption = severidadeOptions.find(
    status => status.value === severidadeValue,
  );

  return statusOption?.label || 'Severidade';
};

export const SeveridadeButton = (props: SeveridadeButtonProps) => {
  const {
    idAtendimento,
    idProcedimento,
    isProcedimentoExterno,
    severidadeAlteracao,
    disabled,
  } = props;

  const severidadeMenu = useRef<SeveridadeMenu>(null);

  const [severidade, setSeveridade] = useState(
    getSeveridadeLabel(severidadeAlteracao),
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSeveridadeChange = async (severidadeAlteracao: string) => {
    try {
      const putSeveridade = isProcedimentoExterno
        ? SolicitacoesProcedimentosExternosAPI.putSeveridade
        : SolicitacoesProcedimentosAPI.putSeveridade;

      setIsUpdating(true);

      severidadeMenu?.current?.hide();

      const payload = {
        severidadeAlteracao,
      };

      await putSeveridade(idAtendimento, idProcedimento, payload);

      setSeveridade(getSeveridadeLabel(severidadeAlteracao));
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  };

  const severidadeMenuItens: MenuItem[] = severidadeOptions.flatMap(
    (severidadeOption: any) =>
      severidadeOption.label !== severidade
        ? {
            label: severidadeOption.label,
            command: () => handleSeveridadeChange(severidadeOption.value),
          }
        : [],
  );

  const className = `severidade-button ${severidade} ${
    disabled ? 'disabled' : ''
  }`;

  useEffectSkipFirst(() => {
    const severidade = getSeveridadeLabel(severidadeAlteracao);
    setSeveridade(severidade);
  }, [severidadeAlteracao]);

  return (
    <>
      <Skeleton
        className="p-ml-3"
        loading={isUpdating}
        width="95px"
        height="25px"
        borderRadius="8px"
      >
        <button
          className={className}
          onClick={event => severidadeMenu?.current?.toggle(event)}
          disabled={disabled}
        >
          {capitalizeFirstLetter(severidade)}
          <i className="fas fa-caret-down" />
        </button>
      </Skeleton>
      <TieredMenu
        className="solicitacao-exame-tiered-menu"
        ref={severidadeMenu}
        model={severidadeMenuItens}
        popup
      />
    </>
  );
};
