import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

type Props = {
  assistenteVirtualDialog: DisclosureType;
  assistenciasVirtuaisPendentes: any[];
  handleClose(id: number, isChecked?: boolean): void;
};

export const useAssistenciaVirtual = (): Props => {
  const { tipoUsuario, primeiroAcesso } = useAppSelector(state => state.user);
  const [assistenciasVirtuaisPendentes, setAssistenciasVirtuais] = useState<
    any[]
  >([]);

  const assistenteVirtualDialog = useDisclosure({ opened: false });

  const setAssistencias = useCallback(
    data => {
      const assistencias = [];

      const filteredAssistencias = data.filter(
        (item: any) => item.tipoUsuario === tipoUsuario || !item.tipoUsuario,
      );

      if (!filteredAssistencias.length) return;

      const sortedAssistencias = filteredAssistencias.sort(
        (a: any, b: any) =>
          dayjs(a.dataInclusao).unix() - dayjs(b.dataInclusao).unix(),
      );

      const assistenciaNovidade = sortedAssistencias.find(
        (item: any) => item.novidade,
      );
      const assistenciaPrimeiroAcesso = sortedAssistencias.find(
        (item: any) => item.primeiroAcesso,
      );

      if (assistenciaPrimeiroAcesso && primeiroAcesso)
        assistencias.push(assistenciaPrimeiroAcesso);
      if (assistenciaNovidade) assistencias.push(assistenciaNovidade);

      setAssistenciasVirtuais(assistencias);
    },
    [primeiroAcesso, tipoUsuario],
  );

  useEffect(() => {
    AssistenciaAPI.getAssistenciasNaoVisualizadas({ throwError: true })
      .then(setAssistencias)
      .catch(() => {});
  }, [setAssistencias]);

  useEffect(() => {
    if (!!assistenciasVirtuaisPendentes.length) {
      assistenteVirtualDialog.open({
        state: assistenciasVirtuaisPendentes[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistenciasVirtuaisPendentes, assistenciasVirtuaisPendentes.length]);

  const handleClose = useCallback((id: number, isChecked = false) => {
    setAssistenciasVirtuais(old => old.filter(data => data.id !== id));

    if (isChecked) {
      AssistenciaAPI.sendOcultarAssistencia(id);
    }
  }, []);

  return {
    assistenteVirtualDialog,
    assistenciasVirtuaisPendentes,
    handleClose,
  };
};
