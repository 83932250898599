import React from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Checkbox } from 'src/components/_UI/Checkbox';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

const PanelHeader = ({
  item,
  isCollapsed,
  onToggle,
  selectedFaturamentos,
  setSelectedFaturamentos,
  valorReceber,
}: any) => {
  const { isMobile, isTablet } = useSize();

  const desktopSize = !isMobile && !isTablet;

  return (
    <div className="p-grid p-d-flex p-ai-center">
      <div className="p-col-6 p-md-2 p-d-flex">
        {!desktopSize ? (
          <>
            <Checkbox
              checked={selectedFaturamentos?.includes(item)}
              onChange={e => {
                if (e.checked) {
                  setSelectedFaturamentos([...selectedFaturamentos, item]);
                } else {
                  setSelectedFaturamentos(
                    selectedFaturamentos?.filter((i: any) => i !== item),
                  );
                }
              }}
            />
            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Data de atendimento:
              </SimpleText>
              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {item?.dataAtendimento
                  ? dayjs(new Date(item.dataAtendimento)).format('DD/MM/YYYY')
                  : 'Não informado'}
              </SimpleText>
            </div>
          </>
        ) : (
          <Checkbox
            checked={selectedFaturamentos?.includes(item)}
            onChange={e => {
              if (e.checked) {
                setSelectedFaturamentos([...selectedFaturamentos, item]);
              } else {
                setSelectedFaturamentos(
                  selectedFaturamentos?.filter((i: any) => i !== item),
                );
              }
            }}
            label={
              item?.dataAtendimento
                ? dayjs(new Date(item.dataAtendimento)).format('DD/MM/YYYY')
                : 'Não informado'
            }
          />
        )}
      </div>
      {!desktopSize && (
        <div className="p-col-6 p-md-1 p-d-flex p-ai-center p-justify-end p-py-0">
          <IconButton
            icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            btnType={IconBtnTypes.GHOST}
            onClick={onToggle}
          />
        </div>
      )}
      <div className="p-col-6 p-md-3 p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Paciente:
          </SimpleText>
        )}
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {item?.paciente}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-3 p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Médico:
          </SimpleText>
        )}
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {item?.medico}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-2 p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Convênio:
          </SimpleText>
        )}
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          {item?.convenio && capitalizeFirstLetter(item?.convenio)}
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-1 p-py-1 p-d-flex p-flex-column">
        {!desktopSize && (
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Valor à receber:
          </SimpleText>
        )}
        <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_16}>
          R$ {valorReceber}
        </SimpleText>
      </div>
      {desktopSize && (
        <div className="p-col-6 p-md-1 p-d-flex p-ai-center p-justify-end p-py-0">
          <IconButton
            icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            btnType={IconBtnTypes.GHOST}
            onClick={onToggle}
          />
        </div>
      )}
    </div>
  );
};

export default PanelHeader;
