import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

interface DadosPacienteProps {
  paciente: any;
}

const DadosPaciente = ({ paciente }: DadosPacienteProps) => {
  const navigate = useNavigate();

  const getEnderecoResidencial = (enderecos: any) => {
    if (!enderecos) return [];

    const enderecoResidencial = enderecos?.find(
      (endereco: any) => endereco.tipo === 'RESIDENCIAL',
    );

    if (!enderecoResidencial) return [];

    const { logradouro, numero, complemento, bairro, cidade } =
      enderecoResidencial;

    return [
      `${logradouro}, ${numero} ${complemento ? `, ${complemento}` : ''}`,
      `${bairro}, ${capitalizeFirstLetter(
        cidade.nome,
      )} - ${capitalizeFirstLetter(cidade.estado.nome)}`,
    ];
  };

  const renderCardPaciente = (paciente: any) => {
    const endereco = getEnderecoResidencial(paciente?.enderecos?.enderecos);

    return (
      <div id="paciente-data" className="card-visualizacao-paciente">
        <div>
          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Sexo
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {capitalizeFirstLetter(
                paciente.dadosGeraisObrigatorios.sexo.descricao,
              )}
            </SimpleText>
          </div>
          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Nasceu em
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {dayjs(paciente.dataNascimento)
                .locale('pt-br')
                .format('DD [de] MMM. [de] YYYY')}
            </SimpleText>
          </div>
          <div className="p-d-flex">
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Mora em
            </SimpleText>
            <div>
              <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
                {endereco?.[0]}
                <br />
                {endereco?.[1]}
              </SimpleText>
            </div>
          </div>
          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              CPF
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {paciente.dadosGeraisObrigatorios.cpf?.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                '$1.$2.$3-$4',
              )}
            </SimpleText>
          </div>
          <div>
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Mãe
            </SimpleText>
            <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
              {paciente.dadosGeraisObrigatorios.nomeMae}
            </SimpleText>
          </div>
        </div>
        <Separator />

        <Button
          type="button"
          btnType={BtnTypes.TEXT_BUTTON}
          label="Editar cadastro"
          onClick={() => navigate(`/pacientes/${paciente.id}`)}
        />

        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_79}>
          Última edição em:{' '}
          {dayjs(paciente.dataAtualizacao)
            .locale('pt-br')
            .format('DD [de] MMM. [de] YYYY')}
        </SimpleText>
      </div>
    );
  };

  return (
    <div className="p-col-12 p-lg-6">
      <SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
        Dados do paciente
      </SimpleText>

      {paciente ? (
        renderCardPaciente(paciente)
      ) : (
        <Skeleton width="100%" height="30vh" borderRadius="10px" />
      )}
    </div>
  );
};

export default DadosPaciente;
