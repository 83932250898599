import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import * as Yup from 'yup';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { FieldTiposEncaminhamento } from './components/FieldTipoEncaminhamentos';
import { Button } from 'src/components/_UI/Button';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import { FieldCidsFavoritos } from 'src/components/Fields/FieldCidsFavoritos/FieldCidsFavoritos';
import { FieldEspecialidade } from 'src/components/Fields/FieldEspecialidade/FieldEspecialidade';
import { FieldMedicoEspecialidade } from 'src/components/Fields/FieldMedicoEspecialidade/FieldMedicoEspecialidade';

import './ModalNovoEncaminhamento.scss';

type GerarTissDialogState = {
  handleClose: (gerarGuia: boolean) => void;
};

type ModalNovoEncaminhamentoProps = DisclosureType<any> & {
  reload: () => void;
};

export const ModalNovoEncaminhamento = ({
  ...props
}: ModalNovoEncaminhamentoProps) => {
  return (
    <Dialog
      onHide={() => props.close({ state: null })}
      visible={props.isOpen}
      className="novo-encaminhamento"
      header="Novo encaminhamento"
    >
      <ErrorBoundary>
        <ModalNovoEncaminhamentoContent {...props} />
      </ErrorBoundary>
    </Dialog>
  );
};

const ModalNovoEncaminhamentoContent = ({
  close,
  reload,
  state: stateProp,
}: ModalNovoEncaminhamentoProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const [encaminhamentoEdit, setEncaminhamentoEdit] = useState<any>(null);

  const gerarTissDialog = useDisclosure({ opened: false });

  const encaminhamento = stateProp?.encaminhamento;

  useEffect(() => {
    if (encaminhamento?.id) {
      AtendimentoEncaminhamentoAPI.loadEncaminhamentoById(
        encaminhamento?.id,
      ).then(setEncaminhamentoEdit);
    }
  }, [encaminhamento]);

  const validationSchema = Yup.object({
    tipoEncaminhamento: Yup.object().required('Campo obrigatório').nullable(),
    especialidade: Yup.object().required('Campo obrigatório').nullable(),
    medico: Yup.object().nullable(),
    resumoClinico: Yup.string().required('Campo obrigatório'),
    hipoteseDiagnostica: Yup.string().required('Campo obrigatório'),
    questionamento: Yup.string().required('Campo obrigatório'),
  });

  const defaultValues = useMemo(() => {
    return {
      tipoEncaminhamento: { id: encaminhamentoEdit?.idTipoEncaminhmento },
      cids: encaminhamentoEdit?.cids || [],
      especialidade: encaminhamentoEdit?.idEspecialidade
        ? {
            id: encaminhamentoEdit?.idEspecialidade,
            nome: encaminhamentoEdit?.nomeEspecialidade,
          }
        : null,
      medico: { id: encaminhamentoEdit?.idMedico },
      encaminhamentoExterno: encaminhamentoEdit?.encaminhamentoExterno,
      resumoClinico: encaminhamentoEdit?.resumoClinico,
      hipoteseDiagnostica: encaminhamentoEdit?.hipoteseDiagnostica,
      questionamento: encaminhamentoEdit?.questionamento,
    } as any;
  }, [encaminhamentoEdit]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffectSkipFirst(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const watchCIDS = form.watch('cids');
  const watchEspecialidade = form.watch('especialidade');
  const watchEncaminhamentoExterno = form.watch('encaminhamentoExterno');

  const onSubmit = useCallback(
    async (values: any) => {
      const cids = values.cids.map((cid: any) => cid.id);
      const payload = {
        idAtendimento: Number(idAtendimento),
        idTipoEncaminhamento: values.tipoEncaminhamento?.id,
        idMedico: values?.medico?.id,
        idEspecialidade: values?.especialidade?.id,
        idsCids: cids,
        encaminhamentoExterno: values.encaminhamentoExterno,
        resumoClinico: values.resumoClinico,
        hipoteseDiagnostica: values.hipoteseDiagnostica,
        questionamento: values.questionamento,
      };

      if (!!encaminhamentoEdit) {
        await AtendimentoEncaminhamentoAPI.updateEncaminhamento(
          {
            ...payload,
            idEncaminhamento: encaminhamentoEdit.id,
          },
          {
            throwError: true,
          },
        )
          .then(() => [close({ state: null }), reload()])
          .catch(() => {});
      } else {
        await AtendimentoEncaminhamentoAPI.createEncaminhamento(payload, {
          throwError: true,
        })
          .then(() => [close({ state: null }), reload()])
          .catch(() => {});
      }
    },
    [close, encaminhamentoEdit, idAtendimento, reload],
  );

  // TODO: Quando Guia Tiss estiver concluido
  // const handleClose = (gerarGuia: boolean) => {
  //   if (gerarGuia) {
  //     return close({ state: { gerarGuia: true } });
  //   }

  //   return close();
  // };

  const handleRemoveCID = (item: any) => {
    form.setValue(
      'cids',
      watchCIDS.filter((cid: any) => item.id !== cid.id),
    );
  };

  const hasEspecialidade = useMemo(
    () => !!watchEspecialidade?.id,
    [watchEspecialidade?.id],
  );

  useEffectSkipFirst(() => {
    if (!encaminhamento) return form.setValue('medico', { id: null });
  }, [watchEspecialidade?.id, encaminhamento]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="p-grid p-col-12">
        <div className="p-col-12 p-p-0">
          <FieldTiposEncaminhamento
            name="tipoEncaminhamento"
            label="Tipo de encaminhamento"
            className="p-col-12 p-md-6 p-py-0"
          />
        </div>

        <FieldEspecialidade
          name="especialidade"
          label="Especialidade"
          className="p-col-12 p-md-6 "
        />

        <FieldMedicoEspecialidade
          name="medico"
          label="Médico"
          className="p-col-12 p-md-6 p-text-truncate"
          params={{ idEspecialidade: watchEspecialidade?.id }}
          disabled={!hasEspecialidade || watchEncaminhamentoExterno}
        />

        <FieldCidsFavoritos />

        {watchCIDS.map((item: any) => (
          <div
            key={item.id}
            className="p-col-12 cid-row p-d-flex p-ai-center p-jc-between"
          >
            <SimpleText>
              {item?.codigo || '-'} {item?.nome || '-'}
            </SimpleText>
            <Button
              icon="fa fa-trash"
              onClick={() => handleRemoveCID(item)}
              btnType="gray"
            />
          </div>
        ))}

        <CheckboxControlled
          control={form.control}
          onChangeCustom={e => e && form.setValue('medico', { id: null })}
          name="encaminhamentoExterno"
          label="Fora da area de ação"
          className="p-px-2 p-py-4"
        />

        <TextareaInputControlled
          control={form.control}
          name="resumoClinico"
          label="Resumo clínico/Resultado de exame (s) e procedimentos realizados"
          maxLength={4000}
          maxLengthSpan
          className="p-col-12"
        />
        <TextareaInputControlled
          control={form.control}
          name="hipoteseDiagnostica"
          label="Hipótese diagnóstica"
          maxLength={4000}
          maxLengthSpan
          className="p-col-12"
        />
        <TextareaInputControlled
          control={form.control}
          name="questionamento"
          label="Questionamento"
          maxLength={4000}
          maxLengthSpan
          className="p-col-12"
        />

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            stretch
            btnType="ghost"
            onClick={() => [close({ state: null })]}
            loading={form.formState.isSubmitting}
          />
          <Button
            type="submit"
            label="Salvar"
            stretch
            loading={form.formState.isSubmitting}
          />
        </div>
      </form>

      {gerarTissDialog.isOpen && <DialogGerarTiss {...gerarTissDialog} />}
    </FormProvider>
  );
};

const DialogGerarTiss = ({
  isOpen,
  close,
  state,
}: DisclosureType<GerarTissDialogState>) => {
  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      className="dialog-gerar-tiss"
      header="Guia TISS para encaminhamento"
      closable={false}
    >
      <div className="p-col-12 p-d-flex p-flex-column p-gap-4 p-p-0">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Deseja adicionar uma guia TISS ao encaminhamento clínico?
        </SimpleText>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Não"
            stretch
            btnType="ghost"
            onClick={() => [close(), state?.handleClose(false)]}
          />
          <Button
            label="Sim"
            stretch
            onClick={() => [close(), state?.handleClose(true)]}
          />
        </div>
      </div>
    </Dialog>
  );
};
