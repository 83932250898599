import { useCallback, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { FieldGrupoTUSS } from '../../components/FieldGrupoTUSS/FieldGrupoTUSS';
import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';

import ModalSairServicoTuss from '../../ModalSairServicoTuss/ModalSairServicoTuss';

import './FormServicoTussStep1.scss';

const formFields = {
  tipo: '',
  codigo: '',
  nome: '',
  codigoTabelaTiss: '',
  classe: '',
};

interface FormServicoTussStep1Props {
  onSubmit(v: Partial<UsuarioDTO>): void;
  values: Partial<ServicoTussCRUD>;
}

const FormServicoTussStep1 = ({
  onSubmit,
  values,
}: FormServicoTussStep1Props) => {
  const navigate = useNavigate();
  const {
    user: { idEmpresa },
  } = useAppSelector((state: RootState) => state);

  const modalSairSemSalvar = useDisclosure({ opened: false });

  const validationSchema = Yup.object().shape({
    convenio: Yup.object().required('Selecione um convênio'),
    // guiaTiss: Yup.string().required('Selecione uma Guia TISS'),
    versao: Yup.string().required('Selecione uma versão'),
    grupoTuss: Yup.object().required('Selecione uma Grupo TUSS'),
    nome: Yup.string().required('Digite um nome para o Serviço TUSS'),
    codigo: Yup.string().required('Digite um código para o Serviço TUSS'),
    codigoTabelaTiss: Yup.string()
      .required('Digite um código da Tabela Tiss para o Serviço TUSS')
      .max(2, 'Tabela Tiss deve ter no máximo 2 caracteres'),
    classe: Yup.string()
      .required('Digite um classe para o Serviço TUSS')
      .max(2, 'Classe deve ter no máximo 2 caracteres'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    control,
    handleSubmit,
    setValue,
    reset,
  } = form;

  const handleModalSairSemSalvar = useCallback(() => {
    reset(formFields);
    navigate(-1);
  }, [navigate, reset]);

  const handleCancel = useCallback(() => {
    if (isDirty) {
      modalSairSemSalvar.open();
    } else {
      handleModalSairSemSalvar();
    }
  }, [handleModalSairSemSalvar, isDirty, modalSairSemSalvar]);

  const handleGrupoTuss = useCallback(
    async (data: any) => {
      const { convenio, grupoTuss, guiaTiss, ...rest } = data;

      onSubmit({
        ...rest,
        idConvenio: convenio.id,
        idGrupoTuss: grupoTuss.id,
        idGuiaTiss: guiaTiss?.id,
      });
    },
    [onSubmit],
  );

  useEffect(() => {
    if (values !== undefined || Object.keys(values).length > 0) {
      setValue('convenio', values?.convenio ? values.convenio : undefined);
      setValue('guiaTiss', values?.guiaTiss ? values.guiaTiss : undefined);
      setValue('versao', 'TUSS');
      setValue('grupoTuss', values?.grupoTuss ? values.grupoTuss : undefined);
      setValue('codigo', values.codigo);
      setValue('nome', values.nome);
      setValue('codigoTabelaTiss', values.codigoTabelaTiss);
      setValue('classe', values.classe);
    }
  }, [setValue, values]);

  const convenio = form.watch('convenio');

  return (
    <FormProvider {...form}>
      <form
        className="step1-servicotuss"
        onSubmit={handleSubmit(handleGrupoTuss)}
        onReset={() => reset(formFields)}
      >
        <FieldConvenio label="Convênio" />

        {!!values.id && (
          <TextInputControlled
            control={control}
            name="guiaTiss.descricao"
            label="Guia TISS"
            className="grid p-col-12 p-md-12"
            disabled
          />
        )}

        <DropdownControlled
          control={control}
          label="Versão"
          className="grid p-col-12 p-md-12"
          errorMsg={errors.versao?.message}
          name="versao"
          filter={false}
          options={[{ value: 'TUSS', label: 'TUSS' }]}
        />

        <FieldGrupoTUSS
          name="grupoTuss"
          label="Grupo TUSS"
          params={{ idConvenio: convenio?.id }}
          disabled={!convenio?.id}
        />

        <div className={'row'}>
          <TextInputControlled
            control={control}
            label="Código"
            className="grid p-col-3 p-md-3"
            errorMsg={errors.codigo?.message}
            name="codigo"
          />

          <TextInputControlled
            control={control}
            label="Nome TUSS"
            className="grid p-col-9 p-md-9"
            errorMsg={errors.nome?.message}
            name="nome"
          />
        </div>

        <TextInputControlled
          control={control}
          label="Tabela TISS"
          className="grid p-col-12 p-md-12"
          errorMsg={errors.codigoTabelaTiss?.message}
          name="codigoTabelaTiss"
          maxLength={2}
        />

        <TextInputControlled
          control={control}
          label="Classe"
          className="grid p-col-12 p-md-12"
          errorMsg={errors.classe?.message}
          name="classe"
          maxLength={2}
        />

        <div className={'p-d-flex p-grid p-col-12 p-px-0'}>
          <div className={'p-col-12 p-sm-3'}>
            <Button
              btnType="ghost"
              stretch
              label="Voltar"
              className={'p-col-align-center p-d-flex'}
              onClick={handleCancel}
              loading={isSubmitting}
            />
          </div>
          <div className={'p-col-12 p-sm-9'}>
            <Button
              stretch
              label="Salvar"
              type="submit"
              disabled={!idEmpresa}
              onClick={handleSubmit(handleGrupoTuss)}
              loading={isSubmitting}
              btnType="tonal"
            />
          </div>
        </div>

        {modalSairSemSalvar.isOpen && (
          <ModalSairServicoTuss
            goBack={handleModalSairSemSalvar}
            onHide={modalSairSemSalvar.close}
            visible={modalSairSemSalvar.isOpen}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default FormServicoTussStep1;
