import { useEffect, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext } from 'react-hook-form';

import GrupoTussAPI from 'src/APIs/AdminAPI/GrupoTussAPI/GrupoTussAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldGrupoTUSSProps = DropdownProps & {
  label: string;
  params?: any;
};

export const FieldGrupoTUSS = ({
  name = 'grupoTuss',
  label,
  params = {},
  ...rest
}: FieldGrupoTUSSProps) => {
  const [grupoTUSSOptions, setGrupoTUSSOptions] = useState<any>([]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    GrupoTussAPI.loadGrupoTuss({ ...params }).then(data =>
      setGrupoTUSSOptions(
        data.list.map((item: any) => ({
          label: item.nome,
          value: item,
        })),
      ),
    );
  }, [params]);

  return (
    <DropdownControlled
      control={control}
      label={label}
      className="grid p-col-12 p-md-12"
      errorMsg={errors?.[name]?.message}
      options={grupoTUSSOptions}
      dataKey="id"
      name={name}
      {...rest}
    />
  );
};
