import { useEffect, useState } from 'react';

import { Image } from 'primereact/image';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import RecuperarSenhaAPI from 'src/APIs/SecurityAPI/RecuperarSenhaAPI/RecuperarSenhaAPI';
import { SessaoAPI } from 'src/APIs/SecurityAPI/SessaoAPI/SessaoAPI';
import ValidarCodigoAPI from 'src/APIs/SecurityAPI/ValidarCodigoAPI/ValidarCodigoAPI';
import EmedLogo from 'src/assets/imgs/emed_logo_text.svg';
import { PASS_TYPES } from 'src/models/APIs/SecurityAPI/AuthAPI/PasswordModels';
import * as Yup from 'yup';

import { logInAction } from 'src/core/redux/slices/system/UserSlice';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { useEmpresa } from '../utils';

import { Button } from 'src/components/_UI';
import Card from 'src/components/Basics/Card/Card';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ModalSessoes from 'src/pages/Emed/Login/Login/ModalSessoes/ModalSessoes';

import './Login.scss';

export const Login = () => {
  const [isRecuperacaoSenha, toggleRecuperacaoSenha] = useState(false);

  const { state } = useLocation();
  const { hashEmailConfirmation, hashEmpresa } = useParams();
  const { empresaData, isLoading } = useEmpresa();
  const navigate = useNavigate();

  useEffect(() => {
    if (hashEmailConfirmation) {
      ValidarCodigoAPI.validarEmailPortal(hashEmailConfirmation);
      navigate(`/portal/${hashEmpresa}/login`, { replace: true });
    }
  }, [hashEmailConfirmation, hashEmpresa, navigate]);

  const title = isRecuperacaoSenha ? 'Recuperação de Conta' : 'Bem-vindo';
  const subtitle = isRecuperacaoSenha
    ? 'Solicite a troca de sua senha informando seu e-mail'
    : 'Entre para agendar consultas rapidamente';

  const handleToggle = () => {
    toggleRecuperacaoSenha(old => !old);
  };

  useEffect(() => {
    if (state === 'recovery_account') toggleRecuperacaoSenha(true);
  }, [state]);

  if (isLoading) return <SpinnerLoading full />;

  return (
    <div id="Login-portal-container" className="p-flex-column p-flex-lg-row">
      <div className="p-md-12 p-lg-6 p-xl-7 p-d-flex p-jc-center p-ai-center p-p-4">
        <Image
          downloadable={false}
          src={empresaData?.logoUrl || EmedLogo}
          alt="logo"
          className="img-logo-login"
        />
      </div>

      <div className="p-md-12 p-lg-6 p-xl-5 p-d-flex p-jc-center p-jc-lg-start p-ai-center h-auto">
        <Card className="card-login p-d-flex p-flex-column p-gap-2 ">
          <SimpleText fontSize="xl">{title}</SimpleText>
          <SimpleText fontColor="color_40">{subtitle}</SimpleText>

          {isRecuperacaoSenha ? (
            <FormRecuperacao
              empresaId={empresaData?.codigo}
              toggleRecuperacaoSenha={handleToggle}
            />
          ) : (
            <FormLogin
              empresaId={empresaData?.codigo}
              toggleRecuperacaoSenha={handleToggle}
            />
          )}
        </Card>
      </div>

      <span className="box-bottom" />
    </div>
  );
};

const FormLogin = ({ toggleRecuperacaoSenha, empresaId }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalSessoes = useDisclosure({ opened: false });

  const { hashEmpresa } = useParams();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        usuario: Yup.string().required('Informe os dados no campo'),
        senha: Yup.string().required('Informe a senha'),
      }),
    ),
  });

  const handleSubmit = async (values: any) => {
    const username = `${empresaId}/${values.usuario}`;

    const sessoesParams = {
      usuario: username,
      senha: values.senha,
      viaPortalPaciente: true,
    };

    try {
      const response = await SessaoAPI.checkSessoes(sessoesParams);

      const signIn = async () => {
        const data = await dispatch(
          logInAction({
            username,
            password: values.senha,
            grant_type: PASS_TYPES.PASSWORD,
            tpLogin: 'portal',
          }),
        );

        if (data?.error?.message === 'NEW_USER') {
          return navigate(`/portal/${hashEmpresa}/redefinicao`, {
            state: {
              username,
              senha: values.senha,
              isNewUser: true,
            },
          });
        }
      };

      if (!response?.flagNecessarioInativarSessao) {
        return await signIn();
      }

      return modalSessoes.open({
        state: {
          ...response,
          handleLogin: signIn,
        },
      });
    } catch {}
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="p-mt-4 p-d-flex p-flex-column"
      >
        <FormInput
          name="usuario"
          label="Usuário"
          placeholder="Insira seu e-mail, CPF, nº do cartão ou passaporte"
          sublabel={(<LabelTooltip />) as any}
        />
        <PasswordInputControlled
          control={form.control}
          name="senha"
          label="Senha"
          placeholder="Insira sua senha"
        />

        <div className="p-mt-1">
          <Button
            label="Esqueci minha senha"
            btnType="green-link"
            onClick={toggleRecuperacaoSenha}
            className="p-p-0"
            disabled={form.formState.isSubmitting || !empresaId}
          />
        </div>

        <div className="p-mt-6 p-d-flex p-flex-column p-gap-2">
          <Button
            label="Entrar"
            type="submit"
            loading={form.formState.isSubmitting}
            disabled={!empresaId}
            stretch
          />
          <Button
            label="Cadastrar-se"
            btnType="outline"
            stretch
            onClick={() => navigate(`/portal/${hashEmpresa}/registro`)}
            disabled={form.formState.isSubmitting || !empresaId}
          />
        </div>

        {modalSessoes.isOpen && <ModalSessoes {...modalSessoes} />}
      </form>
    </FormProvider>
  );
};

const FormRecuperacao = ({ toggleRecuperacaoSenha, empresaId }: any) => {
  const navigate = useNavigate();
  const { hashEmpresa } = useParams();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string()
          .email('Informe um e-mail válido')
          .required('Informe os dados no campo'),
      }),
    ),
  });

  const handleSubmit = async (data: any) => {
    try {
      await RecuperarSenhaAPI.sendRecuperarSenha(
        {
          login: data.email,
          codigoEmpresa: empresaId,
        },
        {
          throwError: true,
        },
      );

      return navigate(`/portal/${hashEmpresa}/redefinicao`, {
        state: {
          email: data.email,
          empresaId,
        },
      });
    } catch {
      return navigate(`/portal/${hashEmpresa}/redefinicao`, {
        state: {
          email: data.email,
          empresaId,
        },
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="p-mt-4 p-d-flex p-flex-column p-gap-4"
      >
        <FormInput
          name="email"
          label="E-mail"
          placeholder="Insira seu e-mail"
        />

        <div className="p-mt-6 p-d-flex p-gap-4">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={toggleRecuperacaoSenha}
            disabled={form.formState.isSubmitting}
            stretch
          />
          <Button label="Enviar código" type="submit" stretch />
        </div>
      </form>
    </FormProvider>
  );
};

const LabelTooltip = () => {
  return (
    <>
      <i className="fas fa-info-circle" id="tooltip-login" />
      <Tooltip style={{ minWidth: '380px' }} target={`#tooltip-login`}>
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_100" medium>
            Para efetuar o login, entre com uma das condições
          </SimpleText>
          <ul className="p-ml-4">
            <li>
              <SimpleText fontColor="primary">
                E-mail, deve conter o e-mail cadastrado:
              </SimpleText>
            </li>
            <SimpleText className="p-ml-2" fontSize="xxs" fontColor="color_60">
              Exemplo: usuario@email.com
            </SimpleText>
            <li>
              <SimpleText fontColor="primary">
                CPF, deve ser formado por 12 números sem pontos
              </SimpleText>
            </li>
            <SimpleText className="p-ml-2" fontSize="xxs" fontColor="color_60">
              Exemplo: 000.000.000-00
            </SimpleText>
            <li>
              <SimpleText fontColor="primary">
                Nº do cartão, deve ser formado por 16 números
              </SimpleText>
            </li>
            <SimpleText className="p-ml-2" fontSize="xxs" fontColor="color_60">
              Exemplo: 0 00 000000000000 0
            </SimpleText>
            <li>
              <SimpleText fontColor="primary">
                Passaporte, deve ser formado por 2 caracteres de letra e 6
                números
              </SimpleText>
            </li>
            <SimpleText className="p-ml-2" fontSize="xxs" fontColor="color_60">
              Exemplo: AA000000
            </SimpleText>
          </ul>
          <br />
        </div>
      </Tooltip>
    </>
  );
};
