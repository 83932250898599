import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import CardDesktop from './CardDesktop';
import CardMobile from './CardMobile';
import './CardRelatorio.scss';
import ModalVisualizarEmail from './ModalVisualizarEmail';

interface CardRelatorioProps {
  relatorio: GetRelatorioEmails;
}

const CardRelatorio = ({ relatorio }: CardRelatorioProps) => {
  const { isMobile } = useSize();
  const modalVisualizarEmailDisclosure = useDisclosure({ opened: false });

  return (
    <>
      {isMobile ? (
        <CardMobile
          relatorio={relatorio}
          modalVisualizarEmailDisclosure={modalVisualizarEmailDisclosure}
        />
      ) : (
        <CardDesktop
          relatorio={relatorio}
          modalVisualizarEmailDisclosure={modalVisualizarEmailDisclosure}
        />
      )}
      {modalVisualizarEmailDisclosure.isOpen && (
        <ModalVisualizarEmail
          {...modalVisualizarEmailDisclosure}
          email={relatorio?.conteudoEmail}
        />
      )}
    </>
  );
};

export default CardRelatorio;
