import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/tipos-estabelecimento';

export const loadTipoEstabelecimentosBySearch = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetTipoEstabelecimentoDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadTipoEstabelecimentos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetTipoEstabelecimentoDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    hideToast: true,
    ...options,
  });
};

const TipoEstabelecimentoAPI = {
  loadTipoEstabelecimentosBySearch,
  loadTipoEstabelecimentos,
};

export default TipoEstabelecimentoAPI;
