import { useCallback, useEffect, useState } from 'react';

import HistoricoAtendimentosAPI from 'src/APIs/ProntuarioAPI/HistoricoAtendimentosAPI/HistoricoAtndimentosAPI';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { PanelContent } from './PanelContent';
import { PanelHeader } from './PanelHeader';

export default function HistoricoVacinas() {
  const [quantidadeVacinas, setQuantidadeVacinas] = useState<number>(1);
  const [infoVacina, setInfoVacina] = useState<any>();
  const [exibirLista, setExibirLista] = useState<boolean>(true);

  useEffect(() => {
    if (quantidadeVacinas === 0) {
      setExibirLista(false);
    }
  }, [quantidadeVacinas]);

  const { selectedAtendimento, reload, setItensFichaPaciente } =
    useHistoricoAtendimento();

  const fetchVacinas = useCallback(
    async (params: any) => {
      const response = await HistoricoAtendimentosAPI.getVacinasAtendimento({
        idPaciente: selectedAtendimento?.atendimento?.paciente?.id,
        sortBy: 'dataAtualizacao',
        sortDirection: 'DESC',
        somenteAtendimento: true,
        idAtendimento: selectedAtendimento?.idAtendimento,
        ...params,
      });
      setQuantidadeVacinas(response?.totalCount);
      setItensFichaPaciente(
        (prev: any) => Number(prev) + Number(response?.totalCount),
      );

      return response;
    },
    [
      selectedAtendimento?.atendimento?.paciente?.id,
      selectedAtendimento?.idAtendimento,
      setItensFichaPaciente,
    ],
  );

  const getInfoVacina = useCallback(async () => {
    const response = await HistoricoAtendimentosAPI.getVacinaGeral({
      idPaciente: selectedAtendimento?.atendimento?.paciente?.id,
    });

    if (response.status === 200) setInfoVacina(response.data);
  }, [selectedAtendimento?.atendimento?.paciente?.id]);

  useEffect(() => {
    getInfoVacina();
  }, [getInfoVacina]);

  const renderRowVacina = (vacina: any) => {
    return (
      <div className="p-my-1">
        <Panel
          header={(isCollapsed, onToggle) => (
            <PanelHeader
              vacina={vacina}
              isCollapsed={isCollapsed}
              onToggle={onToggle}
            />
          )}
        >
          <PanelContent vacina={vacina} />
        </Panel>
      </div>
    );
  };

  const getStatusVacinal = () => {
    if (infoVacina?.statusVacinal === null) return '';

    return infoVacina?.statusVacinal ? 'Adequado' : 'Atrasado';
  };

  return (
    <div>
      {exibirLista && (
        <SearchList.Root fetchApi={fetchVacinas}>
          <SearchList.Header>
            <SimpleText
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Vacinas
            </SimpleText>
          </SearchList.Header>

          <SearchList.BorderContainer>
            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Calendário vacinal
              </SimpleText>

              <SimpleText
                className="p-mt-1 p-mb-2"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {getStatusVacinal()}
              </SimpleText>
            </div>

            <div className="p-d-flex p-flex-column">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Observações
              </SimpleText>

              <SimpleText
                className="p-my-1"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
              >
                {infoVacina?.observacaoVacinal}
              </SimpleText>
            </div>

            <SearchList.InfiniteScroll
              renderRow={renderRowVacina}
              reload={reload}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      )}
    </div>
  );
}
