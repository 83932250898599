import { Control, Controller } from 'react-hook-form';

import MaskedInput, { MaskerInputProps } from '../MaskedInput/MaskedInput';

export interface MaskedInputControlledProps extends MaskerInputProps {
  control: Control<any>;
  name: string;
}

const MaskedInputControlled = ({
  name,
  control,
  ...rest
}: MaskedInputControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }: any) => (
        <MaskedInput
          {...rest}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    />
  );
};

export default MaskedInputControlled;
