import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import HistoricoAGAAPI from 'src/APIs/ProntuarioAPI/HistoricoAGAAPI/HistoricoAGAAPI';

import { useAGA } from 'src/core/hooks/AGA/useAGA';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalInativarHistorico.scss';

interface ModalInativarHistoricoProps {
  isOpen: boolean;
  close: () => void;
  historico: any;
}

export const ModalInativarHistorico = ({
  isOpen,
  close,
  historico,
}: ModalInativarHistoricoProps) => {
  const { setReloadHistorico } = useAGA();
  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      justificativaInativacao: historico?.justificativaInativacao || '',
    },
  });

  const onSubmit = async (data: any) => {
    const { justificativaInativacao } = data;
    try {
      await HistoricoAGAAPI.inativarHistoricoById(
        historico,
        justificativaInativacao,
      );
      setReloadHistorico((prev: number) => prev + 1);
      close();
    } catch (error) {
      throw new Error('Erro ao inativar histórico');
    }
  };

  const renderFooter = () => {
    if (!!historico.justificativaInativacao) {
      return (
        <Button
          type="button"
          btnType="ghost"
          label="Cancelar"
          className="p-col-align-center"
          onClick={close}
          loading={isSubmitting}
          stretch
        />
      );
    }

    return (
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          type="button"
          btnType="ghost"
          label="Cancelar"
          onClick={close}
          loading={isSubmitting}
          stretch
        />
        <Button
          type="button"
          label="Salvar"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={!isDirty}
          stretch
        />
      </div>
    );
  };

  const renderTextInfo = () => {
    if (!!historico.dataInativacao) {
      const dataInativacaoFormatted = historico.dataInativacao
        ? dayjs(historico.dataInativacao).format('DD [de] MMMM [de] YYYY')
        : '';

      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Este item no prontuário foi inativo em{' '}
          <b>{dataInativacaoFormatted}</b> por <b>Fulano de tal</b>
        </SimpleText>
      );
    }

    return (
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        Você está inativando este item no histórico de exames e resultados. Ele
        continuará ser exibido como uma informação inativa. Para continuar,
        justifique.
      </SimpleText>
    );
  };

  const modalTitle = !!historico.justificativaInativacao
    ? 'Inativo'
    : 'Você tem certeza que deseja inativar?';

  return (
    <Dialog
      id="modal-inativar"
      header={modalTitle}
      visible={isOpen}
      onHide={close}
      type="modal"
      modal={false}
      footer={renderFooter}
      maximizedMobileSize
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-mb-3">{renderTextInfo()}</div>

        <TextareaInputControlled
          control={control}
          name="justificativaInativacao"
          label="Justificativa"
          disabled={!!historico.justificativaInativacao}
        />
      </form>
    </Dialog>
  );
};
