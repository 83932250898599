import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isMobile, isLarge, isTablet } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isTablet === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1 p-pr-3">
          <div className="p-col-3 p-lg-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Convênio
            </SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Guia TISS
            </SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Grupo TUSS
            </SimpleText>
          </div>
          <div className="p-col-1">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Código
            </SimpleText>
          </div>
          <div className="p-col-2">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Nome
            </SimpleText>
          </div>
          <div className="p-col-1">
            <SimpleText
              style={{ marginLeft: -5 }}
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Classe
            </SimpleText>
          </div>
          {!isLarge && (
            <div className="p-col-1">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Status
              </SimpleText>
            </div>
          )}
          <div className="p-col-1">
            <SimpleText
              className="p-pl-3"
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Ações
            </SimpleText>
          </div>
        </div>
      )}
    </>
  );
};
