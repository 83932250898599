import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { CartoesPacienteAPI } from 'src/APIs/AdminAPI/CartoesPacienteAPI/CartoesPacienteAPI';

import { DropdownProps } from 'src/components/Basics/Dropdown/Dropdown';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldProfissionalProps = DropdownProps & {
  name?: string;
  label: string;
  idPaciente: number;
  customOnChange?: (value: any) => void;
};

export const FieldPacienteCartoes = ({
  name = 'numCarteirinha',
  label,
  idPaciente,
  ...rest
}: FieldProfissionalProps) => {
  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [cartoes, setCartoes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const loadCartoes = useCallback(async () => {
    try {
      setLoading(true);

      const res = await CartoesPacienteAPI.loadCartoesPaciente(idPaciente, {
        somenteAtivos: true,
      });

      setCartoes(res);
    } catch {
    } finally {
      setLoading(false);
    }
  }, [idPaciente]);

  useEffect(() => {
    loadCartoes();
  }, [loadCartoes]);

  const options = useMemo(() => {
    const res = cartoes.map(value => ({
      label: value.numeroCartao,
      value: value.numeroCartao,
    }));

    return res;
  }, [cartoes]);

  return (
    <DropdownControlled
      control={control}
      name={name}
      dataKey="id"
      label={label}
      filter={false}
      options={options}
      errorMsg={error?.message}
      loading={loading}
      {...rest}
    />
  );
};
