import { memo, useCallback, useMemo } from 'react';

import Chip from 'src/components/Basics/Chip/Chip';
import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';

import SimpleText, { FONT_SIZE } from '../Basics/SimpleText/SimpleText';
import './ChipSelect.scss';

type ChipValue = string | number;

interface ChipSelectItem {
  label: string;
  value: ChipValue;
  className?: string;
  hide?: boolean;
}

export interface ChipSelectProps {
  name?: string | undefined;
  label?: string | undefined;
  className?: string | undefined;
  required?: boolean;
  options?: ChipSelectItem[] | undefined;
  onChange?(v: ChipValue[] | undefined): void;
  value?: ChipValue[];
  errorMsg?: string | any;
  multipleSelect?: boolean;
}

const ChipSelect = (props: ChipSelectProps) => {
  const {
    label,
    name,
    className,
    required,
    onChange,
    options = [],
    value = [],
    errorMsg,
    multipleSelect = true,
  } = props;

  const hasLabel = useMemo(
    () => label || label === '' || required,
    [label, required],
  );

  const handleChange = useCallback(
    item => (v: boolean) => {
      if (!multipleSelect && onChange) return onChange(item.value);

      const newValue = [...(value || [])];
      const index = newValue.findIndex(i => i === item.value);

      if (v) {
        if (index >= 0) {
          newValue[index] = item.value;
        } else {
          newValue.push(item.value);
        }
      } else if (index >= 0) {
        newValue.splice(index, 1);
      }

      if (onChange) {
        onChange(newValue.length === 0 ? undefined : newValue);
      }

      return newValue;
    },
    [multipleSelect, onChange, value],
  );

  const isChecked = useCallback(v => value?.includes(v), [value]);

  return (
    <div className={`ChipSelect ${className || ''}`} key={name}>
      {hasLabel && <ComponentsLabel label={label} required />}

      <div className={'chip-select-items'}>
        {options.map(item => (
          <Chip
            key={`item-${item.value}`}
            label={item.label}
            className={item.className}
            checked={isChecked(item.value)}
            onChange={handleChange(item)}
            hide={item.hide}
          />
        ))}
      </div>

      <div className={'p-d-flex p-jc-between p-ai-center p-mt-1'}>
        {errorMsg && (
          <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
            {errorMsg}
          </SimpleText>
        )}
      </div>
    </div>
  );
};

export default memo(ChipSelect);
