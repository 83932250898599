import { useRef } from 'react';

import {
  CheckboxProps as PrimeCheckboxProps,
  Checkbox as PrimeCheckbox,
} from 'primereact/checkbox';

import { generateUniqueId } from 'src/utils/utils';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './style.scss';

export type CheckboxProps = PrimeCheckboxProps & {
  label?: string;
};

export const Checkbox = ({ label, className, ...props }: CheckboxProps) => {
  const checkboxRef = useRef<any>(null);

  const inputName = props?.name ? props.name : generateUniqueId();

  return (
    <div className={`Checkbox-ui ${className || ''}`}>
      <PrimeCheckbox inputId={inputName} ref={checkboxRef} {...props} />
      {!!label && (
        <SimpleText
          fontSize="xxs"
          htmlFor={inputName}
          aria-disabled={!!props.disabled}
        >
          {label}
        </SimpleText>
      )}
    </div>
  );
};
