import { useEffect, useState, useMemo, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { GruposExamesAPI } from 'src/APIs/ProntuarioAPI/GruposExamesAPI/GruposExamesAPI';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import { FieldProfissionalExecutante } from '../FieldProfissionalExecutante/FieldProfissionalExecutante';

import { Procedimentos } from './Procedimentos/Procedimentos';
import { validationSchema } from './validationSchema';

import './ModalFormGrupo.scss';

type FormValues = {
  nome: string;
  grupoExameServicoTussSet: any[];
  idProfissionalExecutante?: number;
};

export const ModalFormGrupo = (props: any) => {
  const { isOpen, close, state, onSuccess } = props;

  const { idConvenio } = useSolicitacaoExameCirurgia();

  const [grupo, setGrupo] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(() => {
    const grupoExameServicoTussSet =
      grupo?.grupoExameServicoTussSet.map(({ servicoTuss }: any) => ({
        codigo: servicoTuss.codigo,
        nome: servicoTuss.nome,
        idServicoTuss: servicoTuss.id,
      })) || [];

    return {
      nome: grupo?.nome || '',
      idProfissionalExecutante: grupo?.idProfissionalExecutante,
      grupoExameServicoTussSet,
    };
  }, [grupo]);

  const useFormMethods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useFormMethods;

  useEffectSkipFirst(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleClose = useCallback(() => {
    close({ state: null });
  }, [close]);

  useEffect(() => {
    const fetchGrupo = async () => {
      if (!state) return;

      try {
        setLoading(true);

        const response = await GruposExamesAPI.getGrupoExameById(state);

        setGrupo(response);
      } catch (error) {
        handleClose();
      } finally {
        setLoading(false);
      }
    };

    fetchGrupo();
  }, [handleClose, state]);

  const onSubmit = async (data: FormValues) => {
    try {
      const payload = {
        ...data,
        idConvenio,
      };

      const saveAction = state
        ? GruposExamesAPI.putGrupoExame
        : GruposExamesAPI.postGrupoExame;

      await saveAction(payload, state);

      onSuccess();

      handleClose();
    } catch (error) {}
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        className="p-col-align-center"
        onClick={handleClose}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const render = () => {
    if (loading) return <GridListLoading />;

    return (
      <FormProvider {...useFormMethods}>
        <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            className="p-col-12 p-sm-6"
            name="nome"
            label="Nome do grupo"
            hideTextErrorSpace
          />

          <FieldProfissionalExecutante
            className="p-col-12 p-sm-6"
            name="idProfissionalExecutante"
          />

          <Procedimentos />
        </form>
      </FormProvider>
    );
  };

  const dialogTitle = state ? 'Editar grupo' : 'Novo grupo';

  return (
    <Dialog
      className="modal-criar-grupo-solicitacoes-prodecimento"
      header={dialogTitle}
      visible={isOpen}
      onHide={handleClose}
      maximizedTabletSize
      footer={renderFooter}
    >
      {render()}
    </Dialog>
  );
};
