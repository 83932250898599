import Http, { HttpParams } from 'src/core/http/Http';

export const loadServicoTuss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetServicoTussDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: 'servico-tuss/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

const ServicoTussAPI = {
  loadServicoTuss,
};

export default ServicoTussAPI;
