import { useCallback } from 'react';

import ProntuarioAPI from 'src/APIs/ProntuarioAPI/ProntuarioAPI';

import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import PageHeader from 'src/components/PageHeader/PageHeader';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import { BuscaAvancadaHeader } from './BuscaAvancadaHeader';
import { BuscaAvancadaRow } from './BuscaAvancadaRow';
import { BuscaAvancadaSearchHeader } from './BuscaAvancadaSearchHeader';

import './BuscaAvancada.scss';

const BuscaAvancada = () => {
  const fetchApi = useCallback(async (params: any) => {
    if (params?.palavraChave || params?.paciente) {
      const data = await ProntuarioAPI.loadBuscaAvancada({
        ...params,
        campoEm: params?.campoEm || 'TODOS',
        queTenha: params?.queTenha || 'TODOS',
        ordenacao: params?.ordenacao || 'DATA_DE_ATENDIMENTO',
      });

      return data?.list ? data : { list: [] };
    }

    return { list: [] };
  }, []);

  const renderRow = (items: GetBuscaAvancada[]) => {
    return (
      <>
        <div className="p-w-100">
          <OptionsQueryParamsHeader />
        </div>

        <div style={{ minWidth: '1000px' }}>
          <BuscaAvancadaHeader />

          <div className="p-d-flex p-flex-column p-gap-2">
            {items.map((item, idx) => (
              <BuscaAvancadaRow key={idx} item={item} />
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <Page id="busca-avancada-geral">
      <>
        <PageHeader
          title="Busca avançada geral"
          subtitle="Pesquise e encontre onde foi inserido determinado texto. "
        />

        <Page className="page-content" content white>
          <SearchList.Root fetchApi={fetchApi}>
            <BuscaAvancadaSearchHeader />

            <div className="p-mt-3 p-w-100">
              <SearchList.BorderContainer>
                <SearchList.InfiniteScroll
                  renderRows={renderRow}
                  emptyResultLayout={EmptyCustomLayout}
                  customEmptyText="Os resultados das buscas irão aparecer aqui."
                />
              </SearchList.BorderContainer>
            </div>
          </SearchList.Root>
        </Page>
      </>
    </Page>
  );
};

const OptionsQueryParamsHeader = () => {
  const { setFilter: setQueryParam, filter, totalCount } = useSearchList();

  console.log(totalCount);

  const handleSet = (value: string) => {
    setQueryParam(old => ({ ...old, ordenacao: value }));
  };

  const typeLabel: { [key: string]: string } = {
    DATA_DE_ATENDIMENTO: 'Data de atendimento',
    CPF_REGISTRO: 'CPF',
    PACIENTE: 'Paciente',
    CID: 'CID',
    ANAMNESE: 'Anamnese',
    EXAME_FISICO: 'Exame Físico',
    PATOLOGIA_DETECTADA: 'Patologia Detectada',
    PROGNOSTICO: 'Prognóstico',
    DECISAO_TERAPEUTICA: 'Decisão Terapêutica',
    DESCRICAO_TUSS: 'Descrição TUSS',
  };

  const total = Intl.NumberFormat('pt-BR').format(totalCount);

  return (
    <div className="p-d-flex p-gap-1 p-ai-center p-jc-end">
      <SimpleText medium className="p-px-2">
        Resultado da pesquisa: {total}
      </SimpleText>
      <SimpleText fontSize={FONT_SIZE.XXS}>Ordenar por: </SimpleText>
      <SplitButton
        btnType={SPLIT_BTN_TYPE.GHOST}
        label={typeLabel[filter.ordenacao] || 'Data de atendimento'}
        model={[
          {
            label: typeLabel['DATA_DE_ATENDIMENTO']!,
            command: () => handleSet('DATA_DE_ATENDIMENTO'),
          },
          {
            label: typeLabel['CPF_REGISTRO']!,
            command: () => handleSet('CPF_REGISTRO'),
          },
          {
            label: typeLabel['PACIENTE']!,
            command: () => handleSet('PACIENTE'),
          },
          {
            label: typeLabel['CID']!,
            command: () => handleSet('CID'),
          },
          {
            label: typeLabel['ANAMNESE']!,
            command: () => handleSet('ANAMNESE'),
          },
          {
            label: typeLabel['EXAME_FISICO']!,
            command: () => handleSet('EXAME_FISICO'),
          },
          {
            label: typeLabel['PATOLOGIA_DETECTADA']!,
            command: () => handleSet('PATOLOGIA_DETECTADA'),
          },
          {
            label: typeLabel['PROGNOSTICO']!,
            command: () => handleSet('PROGNOSTICO'),
          },
          {
            label: typeLabel['DECISAO_TERAPEUTICA']!,
            command: () => handleSet('DECISAO_TERAPEUTICA'),
          },
          {
            label: typeLabel['DESCRICAO_TUSS']!,
            command: () => handleSet('DESCRICAO_TUSS'),
          },
        ]}
      />
    </div>
  );
};

const EmptyCustomLayout = () => {
  return (
    <div className="card-empty">
      <SimpleText fontSize={FONT_SIZE.SM} medium>
        Não há resultados...
      </SimpleText>
      <SimpleText>Faça uma busca utilizando os filtros</SimpleText>
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        os resultados apareceram aqui.
      </SimpleText>
    </div>
  );
};

export default BuscaAvancada;
