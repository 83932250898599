import { useCallback, useEffect, useState } from 'react';

import { UseFormSetValue } from 'react-hook-form';

import dayjs from 'dayjs';
import TemplateEmailAPI from 'src/APIs/AdminAPI/TemplateEmailAPI/TemplateEmailAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { FormValues } from '../EmailAniversario';

interface TemplateEmailData {
  getTemplateEmail: () => void;
  template: any;
}

interface TemplateEmailProps {
  setValue: UseFormSetValue<FormValues>;
}

export const useTemplateEmail = ({
  setValue,
}: TemplateEmailProps): TemplateEmailData => {
  const [template, setTemplate] = useState<any>(null);

  const { agenda } = useAppSelector((state: RootState) => state);

  const getTemplateEmail = useCallback(async () => {
    if (!agenda?.profissionalAtivo?.id) {
      return;
    }
    const templateEmail = await TemplateEmailAPI.getTemplateEmail(
      agenda?.profissionalAtivo?.id,
    );
    if (templateEmail.status >= 200 && templateEmail.status < 300) {
      setTemplate(templateEmail?.data);

      const conteudo =
        templateEmail?.data.conteudo ||
        '{{nome}} - Nome do paciente; {{nome_social}} - Apelido do paciente; {{data_nasc}} - Data de nascimento do paciente; {{idade}} - Idade do paciente; {{nome_profissional}} - Nome do profissional selecionado';

      setValue('conteudo', conteudo);

      setValue(
        'horario',
        templateEmail?.data?.horario
          ? dayjs(templateEmail?.data?.horario).toDate()
          : dayjs(new Date()).toDate(),
      );
      templateEmail?.data?.emailDiario && setValue('periodicidade', 'diario');
      templateEmail?.data?.emailMensal && setValue('periodicidade', 'mensal');
    }
  }, [agenda?.profissionalAtivo?.id, setValue]);

  useEffect(() => {
    getTemplateEmail();
  }, [agenda?.profissionalAtivo?.id, getTemplateEmail, setValue]);

  return { getTemplateEmail, template };
};
