import React from 'react';

import { utcToShow } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  item: any;
  isCollapsed: boolean;
  onToggle: (e: any) => void;
}

const PanelHeader = ({ item, isCollapsed, onToggle }: Props) => {
  return (
    <div className="p-grid p-justify-between p-align-center bg-96">
      <div className="p-md-6 p-lg-2 p-xl-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Data
        </SimpleText>
        <SimpleText>
          {item?.dataAtualizacao
            ? utcToShow(new Date(item?.dataAtualizacao))
            : '-'}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-3 p-md-2 p-lg-1 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Operaçao
        </SimpleText>
        <SimpleText>{item?.tipoOperacao}</SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-lg-3 p-xl-2 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Tabela
        </SimpleText>
        <SimpleText>{item?.tabela}</SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-lg-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Usuário
        </SimpleText>
        <SimpleText>
          {item?.usuario?.id} {' - '}
          <b>{item?.usuario?.nome}</b>
        </SimpleText>
      </div>
      <div className="p-col-6 p-md-4 p-lg-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Modulo
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>{item?.modulo}</SimpleText>
      </div>
      <div className="p-col-6 p-sm-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Ação
        </SimpleText>
        <SimpleText>
          {item?.acao || (
            <SimpleText fontColor="color_60">Valor não encontrado</SimpleText>
          )}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Num.id
        </SimpleText>
        <SimpleText>{item?.id}</SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Chave primária
        </SimpleText>
        <SimpleText>{item?.chavePrimaria}</SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_60}
          className="p-mb-1"
        >
          Num.ass.
        </SimpleText>
        <SimpleText>{item?.numeroAssinatura || '-'}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-jc-center p-pt-0">
        <Button
          icon={isCollapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
          label={isCollapsed ? 'Ver alterações' : 'Ocultar alterações'}
          onClick={onToggle}
          btnType={IconBtnTypes.GHOST}
          disabled={
            item?.tabela === 'PRONTUARIO' || item?.tipoOperacao === 'Action'
          }
        />
      </div>
    </div>
  );
};

export default PanelHeader;
