import { Control, Controller } from 'react-hook-form';

import PasswordInput, {
  PasswordInputProps,
} from '../PasswordInput/PasswordInput';

export interface PasswordInputControlledProps extends PasswordInputProps {
  control: Control<any, any>;
  name: string;
  hideErrorMsg?: boolean;
}

const PasswordInputControlled = ({
  name,
  control,
  hideErrorMsg,
  ...rest
}: PasswordInputControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <PasswordInput
          {...rest}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          errorMsg={hideErrorMsg ? '' : error?.message || ''}
        />
      )}
    />
  );
};

export default PasswordInputControlled;
