import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isTablet } = useSize();

  if (isTablet) return null;

  return (
    <>
      {isTablet === false && (
        <div className="p-grid p-my-1 ">
          <div className="p-col-2 p-lg-3">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Modelo
            </SimpleText>
          </div>
          <div className="p-col">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
              className="p-px-1"
            >
              Especialidade
            </SimpleText>
          </div>
          <div className="p-col">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
              className="p-px-1"
            >
              Consultórios
            </SimpleText>
          </div>
          <div className="p-col">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Criado em / Autor
            </SimpleText>
          </div>
          <div className="p-col">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Editado em / Autor
            </SimpleText>
          </div>
          <div className="p-col p-text-right">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Situações
            </SimpleText>
          </div>
          <Can
            checkPermission={[
              'PRO_MODELO_PRONTUARIO_ALTERAR',
              'PRO_MODELO_PRONTUARIO_EXCLUIR',
            ]}
          >
            <div className="p-col p-text-right">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                className="p-mr-2"
              >
                Ações
              </SimpleText>
            </div>
          </Can>
        </div>
      )}
    </>
  );
};
