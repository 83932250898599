import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';

type IProps = DisclosureType & {
  handleInativar: (v: string) => void;
};

export const DialogInativarPrescricao = ({
  close,
  isOpen,
  handleInativar,
}: IProps) => {
  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        justificativa: Yup.string().required('Justificativa é obrigatória'),
      }),
    ),
  });

  const onSubmit = (values: any) => {
    handleInativar(values.justificativa);
    close();
  };

  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      header="Inativar prescrição?"
      style={{ width: '480px' }}
    >
      <FormProvider {...form}>
        <form
          onClick={form.handleSubmit(onSubmit)}
          className="p-d-flex p-flex-column p-gap-3"
        >
          <SimpleText className="p-w-100">
            Você está prestes a inativar uma prescrição. Se deseja continuar, é
            necessário informar a justificativa da inativação.
          </SimpleText>
          <TextareaInputControlled
            name="justificativa"
            control={form.control}
            label="Justificativa da inativação"
          />
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              onClick={() => close()}
              stretch
            />
            <Button label="Confirmar" type="submit" btnType="danger" stretch />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
