/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import { useNavigate } from 'react-router';

import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';

import useSize from 'src/core/hooks/useSize';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header, DesktopRow, MobileRow } from './EspecialidadeGrid';
import ModalExcluirEspecialidade from './ModalExcluirEspecialidade/ModalExcluirEspecialidade';

import './CentralEspecialidade.scss';

const CentralEspecialidade = () => {
  const { isMobile } = useSize();

  const navigate = useNavigate();

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_especialidade_cadastrar',
      'adm_especialidade_alterar',
      'adm_especialidade_consultar',
      'adm_especialidade_excluir',
    ],
  });

  const [modalExcluirEspecialidade, setModalExcluirEspecialidade] =
    useState(false);
  const [especialidade, setEspecialidade] = useState({} as EspecialidadeDTO);
  const [reload, setReload] = useState(0);

  const fetchApi = async (params: any): Promise<Paginator<any>> => {
    return await EspecialidadeAPI.loadEspecialidades({
      nome: params?.query,
      ...params,
    });
  };

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleEdit = (item: any) => {
    navigate(`/cadastros/especialidade/cadastro/${item.id}`, { state: item });
  };

  const handleModalExcluirEspecialidadeClose = () => {
    setModalExcluirEspecialidade(false);
    reloadList();
  };

  const handleDelete = (item: any) => {
    setModalExcluirEspecialidade(true);
    setEspecialidade(item);
  };

  const handleSwitchStatus = async (especialidade: any) => {
    const response: any = await EspecialidadeAPI.updateEspecialidadeStatus(
      especialidade?.id,
      {
        status: !especialidade.status,
      },
    );

    if (response.status === 200) {
      await EspecialidadeAPI.loadEspecialidadeById(especialidade?.id);

      reloadList();
    }
  };

  const renderRow = (especialidade: any) => {
    const commomProps = {
      especialidade,
      onSwitchStatus: handleSwitchStatus,
      onEdit: handleEdit,
      onDelete: handleDelete,
    };

    if (isMobile) return <MobileRow {...commomProps} />;

    return <DesktopRow {...commomProps} />;
  };

  return (
    <>
      <SearchList.Root fetchApi={fetchApi}>
        <SearchList.SearchInput
          label="Pesquisar especialidades"
          placeholder="Digite um nome"
        />
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center">
            <Button
              type="button"
              btnType="pill"
              label="Incluir nova especialidade"
              icon="fas fa-briefcase"
              onClick={() => navigate('/cadastros/especialidade/cadastro')}
              checkPermission="ADM_ESPECIALIDADE_CADASTRAR"
            />

            <SearchList.TotalCount className="p-mt-3 p-mt-sm-0" />
          </div>

          <Header />

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalExcluirEspecialidade
        especialidade={especialidade}
        visible={modalExcluirEspecialidade}
        onHide={handleModalExcluirEspecialidadeClose}
      />

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </>
  );
};

export default CentralEspecialidade;
