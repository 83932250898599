import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { SolicitacoesProcedimentosExternosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosExternosAPI/SolicitacoesProcedimentosExternosAPI';

import { Button } from 'src/components/_UI/Button';
import CalendarControlled from 'src/components/Basics/CalendarControlled/CalendarControlled';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalAddDataRepeticao.scss';

export const ModalAddDataRepeticao = (props: any) => {
  const {
    isOpen,
    close,
    state: { idAtendimento, idProcedimento, isProcedimentoExterno, onSuccess },
  } = props;

  const {
    control,
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const putDataRealizacao = isProcedimentoExterno
        ? SolicitacoesProcedimentosExternosAPI.putDataRepeticao
        : SolicitacoesProcedimentosAPI.putDataRepeticao;

      const { dataRepeticao } = data;

      const dataRepeticaoFormatted = dayjs(dataRepeticao).format('YYYYMMDD');

      await putDataRealizacao(
        idAtendimento,
        idProcedimento,
        dataRepeticaoFormatted,
      );

      onSuccess(dataRepeticao);

      close();
    } catch (error) {}
  };

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        type="button"
        btnType="ghost"
        label="Cancelar"
        onClick={close}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="button"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={!isDirty}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-add-data-repeticao"
      header="Adicionar data para repetição"
      visible={isOpen}
      onHide={close}
      type="modal"
      modal={false}
      footer={renderFooter}
      maximizedMobileSize
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CalendarControlled
          control={control}
          name="dataRepeticao"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          minDate={new Date()}
        />
      </form>
    </Dialog>
  );
};
