import { ReactNode, useState, useCallback } from 'react';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { PreNatalContext } from '../../contexts/Atendimento/PreNatal';

const tipoGestacaoOptions = [
  { label: 'Gestação 01', value: 'GESTACAO_01' },
  { label: 'Gestação 02', value: 'GESTACAO_02' },
  { label: 'Gestação 03', value: 'GESTACAO_03' },
  { label: 'Gestação 04', value: 'GESTACAO_04' },
  { label: 'Gestação 05', value: 'GESTACAO_05' },
  { label: 'Gestação 06', value: 'GESTACAO_06' },
  { label: 'Gestação 07', value: 'GESTACAO_07' },
  { label: 'Gestação 08', value: 'GESTACAO_08' },
  { label: 'Gestação 09', value: 'GESTACAO_09' },
  { label: 'Gestação 10', value: 'GESTACAO_10' },
  { label: 'Gestação 11', value: 'GESTACAO_11' },
  { label: 'Gestação 12', value: 'GESTACAO_12' },
  { label: 'Gestação 13', value: 'GESTACAO_13' },
  { label: 'Gestação 14', value: 'GESTACAO_14' },
  { label: 'Gestação 15', value: 'GESTACAO_15' },
  { label: 'Gestação 16', value: 'GESTACAO_16' },
  { label: 'Gestação 17', value: 'GESTACAO_17' },
  { label: 'Gestação 18', value: 'GESTACAO_18' },
  { label: 'Gestação 19', value: 'GESTACAO_19' },
  { label: 'Gestação 20', value: 'GESTACAO_20' },
];

interface PreNatalProviderProps {
  children: ReactNode;
}

const getProximaGestacao = (preNatais: any[]) => {
  const preNatalOrdenado = preNatais.sort((a: any, b: any) => {
    if (a.id < b.id) return 1;

    if (a.id > b.id) return -1;

    return 0;
  });

  if (!preNatalOrdenado?.[0]?.tipoGestacao) return 'GESTACAO_01';

  const [, ultimaGestacao] = preNatalOrdenado[0].tipoGestacao.split('_');

  const proxGestacao =
    Number(ultimaGestacao) >= 9
      ? `GESTACAO_${Number(ultimaGestacao) + 1}`
      : `GESTACAO_0${Number(ultimaGestacao) + 1}`;

  return proxGestacao;
};

export function PreNatalProvider({ children }: PreNatalProviderProps) {
  const { agenda } = useAppSelector((state: RootState) => state);

  const [preNatalAtual, setPreNatalAtual] = useState<any>(null);
  const [proximaGestacao, setProximaGestacao] = useState<string>('GESTACAO_01');
  const [loadingPreNatal, setLoadingPreNatal] = useState(true);
  const [utilizarDppUsAcompanhamento, setUtilizarDppUsAcompanhamento] =
    useState(false);
  const [gestacoes, setGestacoes] = useState<any[]>([]);
  const [selectedGestacao, setSelectedGestacao] = useState<any>(null);

  const loadPreNatal = useCallback(
    async (idPaciente: number) => {
      if (!idPaciente) return;

      setLoadingPreNatal(true);

      const response = await PreNatalAPI.getPreNatal(idPaciente);

      if (response?.[0]?.id) {
        const preNataisByProfissionalSaude = response.filter(
          (preNatal: any) =>
            preNatal.idProfissionalSaude === agenda?.profissionalAtivo?.id,
        );

        const preNatalAtivo = preNataisByProfissionalSaude.find(
          (preNatal: any) => preNatal.statusPreNatal === 'ATIVO',
        );

        setProximaGestacao(getProximaGestacao(preNataisByProfissionalSaude));

        setPreNatalAtual(preNatalAtivo || null);
      }
      setGestacoes(response);
      setLoadingPreNatal(false);
    },
    [agenda?.profissionalAtivo?.id],
  );

  const clearPreNatal = () => {
    setPreNatalAtual(null);
  };

  return (
    <PreNatalContext.Provider
      value={{
        preNatalAtual,
        proximaGestacao,
        gestacoes,
        selectedGestacao,
        setSelectedGestacao,
        loadingPreNatal,
        utilizarDppUsAcompanhamento,
        loadPreNatal,
        clearPreNatal,
        setUtilizarDppUsAcompanhamento,
        setProximaGestacao,
        tipoGestacaoOptions,
      }}
    >
      {children}
    </PreNatalContext.Provider>
  );
}
