import { useMemo } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { AssistenciaAPI } from 'src/APIs/AdminAPI/AssistenciaAPI/AssistenciaAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { submitImage } from '../utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { AssistenteVirtualCreateDialog } from './AssistenteVirtualCreateDialogEtapas';

export const AssistenteVirtualCreateFormConteudo = ({
  data = {},
  handleGoBack,
}: {
  data: any;
  handleGoBack(): void;
}) => {
  const { id } = useParams();
  const { idEmpresa } = useAppSelector(state => state.user);

  const navigate = useNavigate();
  const dialogCreate = useDisclosure({ opened: false });

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        etapas: Yup.array()
          .min(1, 'Adicione ao menos uma etapa')
          .required('As etapas são obrigatórias'),
      }),
    ),
    defaultValues: {
      etapas: (data?.etapas?.length ? data.etapas : []) as any[],
    },
  });

  const { fields, remove, update, replace } = useFieldArray({
    name: 'etapas',
    control: form.control,
    keyName: 'idForm',
  });

  const etapasInseridas = useMemo(() => {
    return fields.map(item => item.posicao);
  }, [fields]);

  const handleAdd = (v: any, editingIndex?: number) => {
    if (editingIndex !== undefined) {
      return update(editingIndex, v);
    }

    const updatedFields = [...fields, v].sort((a, b) => a.posicao - b.posicao);
    return replace(updatedFields);
  };

  const handleSubmit = async (valuesFormConteudo: any) => {
    try {
      // Use Promise.all to process each field asynchronously
      const fieldsEtapas = await Promise.all(
        fields.map(async etapa => {
          const { tipoConteudo, file, ...rest } = etapa;

          if (tipoConteudo === 'IMAGEM' && file) {
            const { data } = await submitImage(file, idEmpresa);
            const filePath = data?.filePath;

            return {
              ...rest,
              tipoConteudo,
              linkConteudo: filePath,
              pathImagem: filePath
            };
          }
          return etapa;
        }),
      );

      const assistenciaData = {
        ...data,
        ...valuesFormConteudo,
        etapas: fieldsEtapas,
      };

      if (!id) {
        await AssistenciaAPI.sendAssistencia(assistenciaData, {
          throwError: true,
        });
      } else {
        await AssistenciaAPI.updateAssistencia(id, assistenciaData, {
          throwError: true,
        });
      }

      return navigate('/cadastros/assistente-virtual');
    } catch (error) {}
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        id="etapa-form"
        className="p-col-12 p-md-8 p-lg-7 p-xl-6 p-d-flex p-flex-column p-gap-2"
      >
        <div className="p-d-flex p-jc-between p-ai-center p-gap-2">
          <SimpleText fontSize="sm" medium>
            Definições de conteúdo das dicas
          </SimpleText>
          <Button
            label="Adicionar etapa"
            icon="fas fa-plus"
            btnType="tonal"
            onClick={() => dialogCreate.open()}
          />
        </div>

        <div className="p-border-container">
          {!fields.length ? (
            <div className="empty-etapa">
              <SimpleText fontColor="color_40" className="p-mb-2" medium>
                Nenhuma etapa adicionada
              </SimpleText>
              <SimpleText fontColor="primary" medium>
                Adicione uma ou mais etapas
              </SimpleText>
            </div>
          ) : (
            fields.map((item, idx) => (
              <EtapasRow
                key={item.posicao}
                item={item}
                handleRemove={() => remove(idx)}
                handleEdit={() =>
                  dialogCreate.open({
                    state: { ...item, idx },
                  })
                }
              />
            ))
          )}
        </div>

        <div className="p-d-flex p-gap-1 p-my-2">
          <Button
            label="Voltar"
            btnType="ghost"
            onClick={handleGoBack}
            stretch
            disabled={form.formState.isSubmitting}
          />
          <Button
            label="Salvar"
            type="submit"
            disabled={!fields.length}
            loading={form.formState.isSubmitting}
            stretch
          />
        </div>
      </form>

      {dialogCreate.isOpen && (
        <AssistenteVirtualCreateDialog
          etapasInseridas={etapasInseridas}
          handleAdd={handleAdd}
          {...dialogCreate}
        />
      )}
    </FormProvider>
  );
};

const EtapasRow = ({ item, handleRemove, handleEdit }: any) => {
  return (
    <div className="row-component p-d-flex p-col-12 p-mb-2">
      <div className="p-col-2 p-d-flex p-flex-column p-gap-1 p-ai-center">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Posição
        </SimpleText>
        <span className="step-position" data-count={item.posicao} />
      </div>
      <div className="p-col-6 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Etapa
        </SimpleText>
        <SimpleText medium>{item.nome}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-gap-1 p-jc-end">
        <Button icon="fas fa-edit" btnType="ghost" onClick={handleEdit} />
        <Button icon="fas fa-trash" btnType="ghost" onClick={handleRemove} />
      </div>
    </div>
  );
};
