import dayjs from 'dayjs';

export const PRESENCA_TISS_TOAST_MESSAGES = {
  autorizado: {
    title: 'Autorizado',
    message: 'Guia autorizada com sucesso!',
    type: 'success',
  },
  negado_operadora: {
    title: 'Guia não autorizada',
    message:
      'Guia não autorizada: Tente novamente, remova puericultura, marque como retorno, ou altere para uma consulta particular',
    type: 'error',
  },
  error: {
    title: 'Erro',
    message: 'Erro de comunicação com o TISS',
    type: 'error',
  },
};

export const GetAgendaProfissionalTipo = ['PROXIMOS_PACIENTES', 'AGENDA_DIA'];

export const getPacienteDateInfo = (date: string | undefined) => {
  if (!date) return;

  const dateNow = dayjs(new Date()).startOf('day');
  const dateToCompare = dayjs(date).startOf('day');

  const diffDays = dateNow.diff(dateToCompare, 'day');

  return {
    dayPreffix: diffDays === 1 ? 'dia' : 'dias',
    daysDifferenceToNow: diffDays,
    dateFormatted: dayjs(date).format('DD/MM/YYYY'),
  };
};