import { AxiosResponse } from 'axios';
import {
  GetProcedimentosSeriados,
  LoadProcedimentosSeriadosParams,
} from 'src/models/APIs/AgendaAPI/ProcediementosSeriados';

import Http, { HttpParams } from 'src/core/http/Http';

const loadProcedimentosSeriados = (
  { idAtendimento, idConvenio, numeroCartao }: LoadProcedimentosSeriadosParams,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<AxiosResponse<GetProcedimentosSeriados[]>> => {
  return Http.get({
    service: 'agenda',
    url: `agendas/procedimentos-seriados/${idAtendimento}/convenio/${idConvenio}/beneficiario/${numeroCartao}`,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const ProcedimentosSeriadosAPI = {
  loadProcedimentosSeriados,
};
