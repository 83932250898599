import { memo, useCallback, useState, useEffect } from 'react';

import { Skeleton } from 'primereact/skeleton';

import TipoGrupoAtendimentoAPI from 'src/APIs/AgendaAPI/TipoGrupoAtendimento/TipoGrupoAtendimentoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Can from 'src/components/Can/Can';
import Dialog from 'src/components/Dialog/Dialog';

import TipoGrupoItem from './TipoGrupoItem/TipoGrupoItem';

import './ModalGerenciarTipoGrupo.scss';
interface ModalGerenciarTipoGrupoProps {
  visible: boolean;
  onHide(): void;
}

interface FooterProps {
  onCancel(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel } = props;

  return (
    <div className={'p-grid'}>
      <div className={'p-col-12'}>
        <Button btnType="ghost" label="Fechar" onClick={onCancel} stretch />
      </div>
    </div>
  );
});

const ModalGerenciarTipoGrupo = ({
  visible,
  onHide,
}: ModalGerenciarTipoGrupoProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [nameTipoGrupo, setNameTipoGrupo] = useState('');
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [tipoGrupoSelecionado, setTipoGrupoSelecionado] = useState({} as any);
  const [isEditing, setIsEditing] = useState(false);

  const loadTiposGrupoAtendimento = useCallback(async () => {
    try {
      setLoading(true);
      const tipoGrupoEncontrado =
        await TipoGrupoAtendimentoAPI.loadTipoGrupoAtendimentos({ nome: '' });

      setList(tipoGrupoEncontrado?.list as any);
      setLoading(false);
    } catch (error) {
      console.log(error, 'loadTiposGrupoAtendimento Error');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadTiposGrupoAtendimento();
  }, [loadTiposGrupoAtendimento]);

  const handleSalvarTipoGrupo = useCallback(async () => {
    const nameExists = nameTipoGrupo.trim();

    if (nameExists !== '' && !isEditing) {
      try {
        const tipoGrupo = {
          nome: nameTipoGrupo,
          status: true,
          idUsuario: user.idUsuario,
          idEmpresa: user.idEmpresa,
        };
        const tipoGrupoCadastrado =
          await TipoGrupoAtendimentoAPI.sendTipoGrupoAtendimento(
            tipoGrupo as any,
          );
        setNameTipoGrupo('');
        setList([...list, tipoGrupoCadastrado]);
      } catch (error) {}
    }

    if (nameExists !== '' && isEditing) {
      console.log('entrou');
      const tipoGrupoAtualizado = {
        ...tipoGrupoSelecionado,
        nome: nameTipoGrupo,
      };

      try {
        await TipoGrupoAtendimentoAPI.updateTipoGrupoAtendimento(
          tipoGrupoSelecionado.id!,
          tipoGrupoAtualizado,
          { throwError: true },
        );

        await TipoGrupoAtendimentoAPI.loadTipoGrupoAtendimentos({
          id: tipoGrupoSelecionado.id!,
        });

        const tipoGrupoExistente = list.findIndex(
          tipoGrupo => tipoGrupo.id === tipoGrupoAtualizado.id,
        );

        list.splice(tipoGrupoExistente, 1, tipoGrupoAtualizado);
        setNameTipoGrupo('');
        setIsEditing(false);
      } catch (error) {
        setNameTipoGrupo('');
        setIsEditing(false);
      }
    }
  }, [nameTipoGrupo, isEditing, user, list, tipoGrupoSelecionado]);

  const handleEditTipoGrupo = useCallback((tipoGrp: any) => {
    setTipoGrupoSelecionado(tipoGrp);
    setNameTipoGrupo(tipoGrp.nome);
    setIsEditing(true);
  }, []);

  const handleRemoveTipoGrupo = useCallback(
    (tipoGrupo: any) => {
      try {
        TipoGrupoAtendimentoAPI.removeTipoGrupoAtendimento(tipoGrupo.id!).then(
          retornoDelete => {
            if (!retornoDelete) {
              const tipoGruposExists = list.filter(
                (r: TipoGrupoAtendimentoDTO) => r.id !== tipoGrupo.id,
              );
              setList(tipoGruposExists);
            }
          },
        );
      } catch (error) {}
    },
    [list],
  );

  const handleCancelGerenciarTipoGrupo = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Gerenciar tipos de grupo"
      visible={visible}
      onHide={handleCancelGerenciarTipoGrupo}
      footer={() => <Footer onCancel={handleCancelGerenciarTipoGrupo} />}
      maximizedTabletSize
    >
      <div className="modal-body-gerenciar-tipo-grupo">
        <SimpleText className="title" fontColor={FONT_COLOR.COLOR_40}>
          Adicione ou gerencie os tipos de grupo disponíveis no seu consultório
        </SimpleText>

        <Can
          checkPermission={[
            'AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_CADASTRAR',
            'AGE_TIPO_DE_GRUPO_DE_ATENDIMENTO_ALTERAR',
          ]}
        >
          <>
            <TextInput
              placeholder="Digite um nome"
              label="Nome do tipo do grupo"
              value={nameTipoGrupo}
              onChange={t => setNameTipoGrupo(t.target.value)}
              className="p-mt-4"
            />

            <Button
              className={'submit'}
              label="Salvar"
              onClick={handleSalvarTipoGrupo}
              stretch
            />
          </>
        </Can>

        <SimpleText
          className={'p-mt-5 p-mb-2'}
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          bold
        >
          Lista de tipos de grupo
        </SimpleText>
        <div className="tipo-grupo-container">
          {loading ? (
            <>
              <div style={{ marginBottom: 8 }}>
                <Skeleton height="56px" borderRadius="8px" />
              </div>
            </>
          ) : list && list.length > 0 ? (
            list.map((tipoGrupo: TipoGrupoAtendimentoDTO, index: number) => (
              <TipoGrupoItem
                key={index}
                index={index}
                text={tipoGrupo.nome}
                checked={tipoGrupo.status}
                setChecked={() => setTipoGrupoSelecionado(tipoGrupo)}
                onEdit={() => handleEditTipoGrupo(tipoGrupo)}
                onDelete={() => handleRemoveTipoGrupo(tipoGrupo)}
                tipoGrupoSelecionado={tipoGrupo}
                tipoGrupoList={list}
              />
            ))
          ) : (
            <SimpleText className={'text'} fontSize={FONT_SIZE.XS}>
              Cadastre um tipo de atendimento
            </SimpleText>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ModalGerenciarTipoGrupo;
