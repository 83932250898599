import { useMemo, useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';

interface DesktopRowProps {
  perfil: Perfil;
  onEdit: () => void;
  onDelete: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const { perfil, onEdit, onDelete } = props;

  const menuRef = useRef<any>(null);

  const itemsWithPermission = useMemo(() => {
    const items = [
      {
        label: 'Editar',
        command: () => onEdit(),
        checkPermission: 'ADM_PERFIL_ALTERAR',
      },
      {
        label: 'Excluir',
        command: () => onDelete(),
        checkPermission: 'ADM_PERFIL_EXCLUIR',
        className: 'error',
      },
    ];

    return items.filter(
      ({ checkPermission }) =>
        !checkPermission || authorities?.includes(checkPermission),
    );
  }, [authorities, onDelete, onEdit]);

  return (
    <CardItem>
      <SimpleText
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XS}
        medium
        className="p-col-4 p-text-truncate p-wordbreak-normal"
      >
        {perfil.descricao}
      </SimpleText>
      <SimpleText
        fontColor={FONT_COLOR.COLOR_60}
        fontSize={FONT_SIZE.XS}
        className="p-col-6 p-text-truncate p-wordbreak-normal"
      >
        {perfil.descricaoCompleta}
      </SimpleText>
      <div className="p-col-2 p-d-flex p-jc-end p-ai-center p-gap-2">
        <Button
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={e => menuRef.current?.toggle(e)}
        />

        <TieredMenu model={itemsWithPermission} ref={menuRef} popup />
      </div>
    </CardItem>
  );
};
