import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import useSize from 'src/core/hooks/useSize';

import DesktopRow from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  data: any;
  setGrupoAtendimento: (grupoAtendimento: any) => void;
  setModalExcluirGrupoAtendimento: (
    modalExcluirGrupoAtendimento: boolean,
  ) => void;
  reloadList: () => void;
}

export const Row = (props: RowProps) => {
  const { data, setGrupoAtendimento, setModalExcluirGrupoAtendimento } = props;

  const handleExcluirGrupoAtendimento = useCallback(
    (item: any) => {
      setModalExcluirGrupoAtendimento(true);
      setGrupoAtendimento(item);
    },
    [setGrupoAtendimento, setModalExcluirGrupoAtendimento],
  );

  const navigate = useNavigate();
  const { isMobile } = useSize();

  const onEdit = useCallback(
    (data: any) => {
      if (data.id) {
        navigate(`/pacientes/novo-grupo-atendimento/${data.id}`, {
          state: data,
        });
      }
    },
    [navigate],
  );

  function calculoPacientes(pac: any) {
    if (pac?.length > 1) {
      return `${pac?.length} pacientes`;
    } else if (pac?.length === 1) {
      return '1 paciente';
    } else {
      return '0 paciente';
    }
  }

  return isMobile ? (
    <MobileRow
      data={data}
      onEdit={onEdit}
      onDelete={handleExcluirGrupoAtendimento}
      calculoPacientes={calculoPacientes}
    />
  ) : (
    <DesktopRow
      data={data}
      onEdit={onEdit}
      onDelete={handleExcluirGrupoAtendimento}
      calculoPacientes={calculoPacientes}
    />
  );
};
