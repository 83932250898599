import { useLocation } from 'react-router';

import QuestionariosAPI from 'src/APIs/ProntuarioAPI/Questionarios/QuestionariosAPI';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import PanelOptionsMenu from '../../../components/PanelOptionsMenu/PanelOptionsMenu';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

import './Row.scss';

interface RowProps {
  questionario: any;
  reloadList: () => void;
  disclosures: {
    novoDisclosure: DisclosureType;
    inativarDisclosure: DisclosureType;
    visualizarJustificativaDisclosure: DisclosureType;
    deletarDisclosure: DisclosureType;
  };
}

const Row = ({ questionario, disclosures, reloadList }: RowProps) => {
  const { state } = useLocation();
  const idAtendimentoCurrent = state?.idAtendimento;
  const { isMobile } = useSize();

  const {
    validarOuRejeitar,
    id,
    idAtendimento,
    status,
    idUsuarioAtualizacao,
    idPaciente,
  } = questionario;

  const {
    novoDisclosure,
    inativarDisclosure,
    visualizarJustificativaDisclosure,
    deletarDisclosure,
  } = disclosures;

  const canValidar =
    validarOuRejeitar === 'PENDENTE' || validarOuRejeitar === null;

  const onVisualizarQuestionario = () => {
    novoDisclosure.open({ state: { questionario, readOnly: true } });
  };

  const onEdit = () => {
    novoDisclosure.open({ state: { questionario } });
  };

  const onInativar = () => {
    inativarDisclosure.open({ state: questionario });
  };

  const onVisualizarJustificativa = () => {
    visualizarJustificativaDisclosure.open({ state: questionario });
  };

  const onExcluir = () => {
    deletarDisclosure.open({ state: questionario });
  };

  const onValidarOuRejeitar = async (validarOuRejeitar: boolean) => {
    const response = await QuestionariosAPI.putValidarOuRejeitarQuestionario(
      idAtendimentoCurrent,
      id,
      validarOuRejeitar,
    );
    if (response.status === 200) {
      reloadList();
    }
  };

  const optionsMenu = () => (
    <PanelOptionsMenu
      ativo={status === 'ATIVO'}
      hideLembreteOption
      idAtendimentoRegistro={idAtendimento}
      idUsuarioCriacao={idUsuarioAtualizacao}
      onAlterarLembrete={() => null}
      onEdit={() => onEdit()}
      onInativar={() => onInativar()}
      onExcluir={() => onExcluir()}
      onVisualizarJustificativa={() => onVisualizarJustificativa()}
      onValidar={() => onValidarOuRejeitar(true)}
      onRejeitar={() => onValidarOuRejeitar(false)}
      showEditOnlyAtendimentoCorrente
      showValidarRejeitar={canValidar}
    />
  );

  const formatStatus = (status?: string) => {
    if (status === 'REJEITADO') {
      return 'Rejeitado';
    }

    if (status === 'VALIDADO') {
      return 'Validado';
    }
    return 'Pendente validação';
  };

  const headerTextClassName = `p-mt-2 cursor-pointer ${
    questionario.status === 'INATIVO' ? 'motivo-inativo' : ''
  }`;

  const commomProps = {
    questionario,
    onVisualizarQuestionario,
    optionsMenu,
    formatStatus,
    headerTextClassName,
  };

  if (isMobile) {
    return <MobileRow {...commomProps} />;
  }

  return <DesktopRow {...commomProps} />;
};

export default Row;
