import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  hospital: any;
  onEdit: (hospital: any) => void;
  onDelete: (hospital: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { hospital, onEdit, onDelete } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(hospital),
      checkPermission: 'ADM_HOSPITAL_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(hospital),
      checkPermission: 'ADM_HOSPITAL_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>
        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {hospital?.nome}
        </SimpleText>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CNPJ
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {hospital?.cnpj}
        </SimpleText>
      </div>
    </SearchListMobileRow>
  );
};
