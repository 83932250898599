import Dropzone, { Accept } from 'react-dropzone';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './UploadLaudo.scss';

const accept: Accept = {
  'image/jpeg': ['.jpeg', '.png', '.pdf'],
};

interface UploadLaudoProps {
  loadedFile: any;
  handleLoadFile: (files: File[]) => void;
  handleDeleteFile: () => void;
}

export const UploadLaudo = (props: UploadLaudoProps) => {
  const { loadedFile, handleLoadFile, handleDeleteFile } = props;

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleDeleteFile();
  };

  const renderDropzoneContent = () => {
    if (!!loadedFile) {
      return (
        <div
          key={loadedFile.id}
          className="p-w-100 p-d-flex p-jc-between p-ai-center"
        >
          <div className="p-d-flex p-ai-center">
            {loadedFile.isImage ? (
              <img src={loadedFile.preview} width={100} />
            ) : (
              <i className="fas fa-file-pdf" />
            )}
            <SimpleText
              className="p-ml-3"
              fontColor={FONT_COLOR.PRIMARY}
              medium
            >
              {loadedFile.name}
            </SimpleText>
          </div>

          <div className="p-d-flex p-ai-center">
            <SimpleText className="p-mr-3" fontColor={FONT_COLOR.COLOR_40}>
              {loadedFile.readableSize}
              {loadedFile.isImage &&
                ` - ${loadedFile.width}x${loadedFile.height}px`}
            </SimpleText>
            <Button
              type="button"
              btnType="gray"
              icon="fas fa-trash"
              onClick={handleDelete}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <i className="fas fa-file" />

        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_40}>
          Você pode arrastar seus arquivos aqui.
        </SimpleText>

        <SimpleText
          className="p-mt-1"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Formato aceito: PDF, PNG e JPG
        </SimpleText>
      </>
    );
  };

  return (
    <div className="p-col-12">
      <div id="laudo-upload-container" className="p-col-12 p-grid">
        <Dropzone
          accept={accept}
          onDrop={handleLoadFile}
          maxFiles={1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="p-col-12">
              <input {...getInputProps()} />

              <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                {renderDropzoneContent()}
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};
