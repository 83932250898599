import { useFormContext } from 'react-hook-form';

import { FieldUnidade } from '../../components/FieldUnidade';
import { FieldViaAdm } from '../../components/FieldViaAdm';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';

interface DesktopRowProps {
  medicamento: any;
  onDelete: () => void;
  idx: number;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { control } = useFormContext();
  const { medicamento, onDelete, idx } = props;

  return (
    <div className="p-grid pacote-servico-tuss-item p-grid p-align-center">
      <div className="p-col-4 p-sm-2 p-md-1 p-lg-1 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Código
        </SimpleText>
        <SimpleText fontColor="color_60" fontSize="xxs" medium>
          {medicamento.codigoProcedimento}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-5 p-lg-8 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Descrição
        </SimpleText>
        <SimpleText fontSize="xxs" medium>
          {medicamento.descricao}
        </SimpleText>
      </div>

      <div className="p-col-4 p-sm-2 p-lg-1 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Dose
        </SimpleText>
        <FormInput
          className="p-mr-2"
          name={`medicamentosQuimioterapia.${idx}.dose`}
          type="number"
          min={1}
          // max={medicamento?.quantidadeMaxima}
          allowEmpty={false}
          hideTextErrorSpace
        />
      </div>
      <div className="p-col-4 p-sm-2 p-lg-1 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Frequencia
        </SimpleText>
        <FormInput
          className="p-mr-2"
          name={`medicamentosQuimioterapia.${idx}.frequencia`}
          type="number"
          min={1}
          // max={medicamento?.quantidadeMaxima}
          allowEmpty={false}
          hideTextErrorSpace
        />
      </div>

      <div className="p-col-4 p-sm-2 p-lg-1 p-d-flex p-jc-end">
        <Button icon="fas fa-trash" onClick={onDelete} btnType="gray" />
      </div>

      <div className="p-col-6 p-sm-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Unidade de medida
        </SimpleText>
        <FieldUnidade
          name={`medicamentosQuimioterapia.${idx}.unidadeMedida`}
          dataKey="codigo"
        />
      </div>
      <div className="p-col-6 p-sm-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Via administração
        </SimpleText>
        <FieldViaAdm
          name={`medicamentosQuimioterapia.${idx}.viaAdm`}
          dataKey="codigo"
        />
      </div>
      <div className="p-col-6 p-sm-4 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data prevista
        </SimpleText>
        <CalendarInputControlled
          control={control}
          showIcon
          placeholder="dd/mm/aaaa"
          name={`medicamentosQuimioterapia.${idx}.dataPrevistaAdministracao`}
        />
      </div>
    </div>
  );
};
