import { useContext, useRef } from 'react';

import {
  Button as PrimeButton,
  ButtonProps as PrimeButtonProps,
} from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import ThemeContext from 'src/core/themes/ThemeContext';

import Can from 'src/components/Can/Can';

import './IconButton.scss';

export enum IconBtnTypes {
  GREY = 'grey',
  WHITE = 'white',
  ICON_ONLY = 'icon-only',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  GHOST = 'ghost',
  FAB_GREY = 'fab-grey',
  WARN = 'warn',
  DANGER = 'danger',
}
export enum IconBtnSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export interface IconButtonProps extends Omit<PrimeButtonProps, 'label'> {
  onSubmit?(e: any): any;
  btnType?: IconBtnTypes;
  btnSize?: IconBtnSizes;
  overlay?: JSX.Element;
  squared?: boolean;
  overlayClassName?: string;
  checkPermission?: string | string[];
}

const IconButton = ({
  loading,
  btnType,
  btnSize,
  overlay,
  squared,
  onClick,
  overlayClassName,
  className,
  checkPermission = '',
  ...rest
}: IconButtonProps) => {
  const { theme } = useContext(ThemeContext);
  const overlayRef = useRef<OverlayPanel>(null);

  return (
    <Can checkPermission={checkPermission}>
      <div
        className={`IconButton ${btnType ? btnType : IconBtnTypes.GREY} ${
          btnSize ? btnSize : ''
        }`}
      >
        <PrimeButton
          className={`
        ${squared ? '' : 'p-button-rounded p-button-outlined'} 
        ${theme || ''}
        ${className || ''}
        `}
          loading={loading ? true : false}
          onClick={e => {
            if (overlay) {
              overlayRef.current?.toggle(e);
            }
            if (onClick && !overlay) {
              onClick(e);
            }
          }}
          {...rest}
        />
        <div className="OverlayPanel">
          <OverlayPanel className={overlayClassName ?? ''} ref={overlayRef}>
            {overlay}
          </OverlayPanel>
        </div>
      </div>
    </Can>
  );
};

export default IconButton;
