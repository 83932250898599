import { PanelHeaderTemplateOptions } from 'primereact/panel';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface BuscaRapidaPanelHeaderProps {
  options: PanelHeaderTemplateOptions;
  onCreateGrupo: () => void;
}

export const BuscaRapidaPanelHeader = (props: BuscaRapidaPanelHeaderProps) => {
  const { options, onCreateGrupo } = props;

  const { atendimentoStatus } = useAtendimento();

  const toggleIcon = options.collapsed
    ? 'pi pi-chevron-down'
    : 'pi pi-chevron-up';
  const className = `p-d-flex p-ai-center p-jc-between ${
    options.collapsed ? 'busca-closed' : 'busca-open'
  }`;

  return (
    <div id="busca-rapida-header" className={className}>
      <SimpleText
        fontSize={FONT_SIZE.MD}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        Busca rápida
      </SimpleText>

      <div className="p-d-flex p-ai-center">
        <Button
          type="button"
          btnType="outline"
          label="Criar grupo"
          icon="pi pi-plus"
          onClick={onCreateGrupo}
          disabled={atendimentoStatus !== 'ATENDENDO'}
        />
        <Button
          className="p-ml-3"
          type="button"
          icon={toggleIcon}
          btnType="ghost"
          onClick={options.onTogglerClick}
        />
      </div>
    </div>
  );
};
