import { useState } from 'react';

import ClienteAPI from 'src/APIs/AdminAPI/ClienteAPI/ClieteAPI';

import useSize from 'src/core/hooks/useSize';

import AlertBox from 'src/components/AlertBox/AlertBox';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';
import SwitchStatus from './SwitchStatus';

interface RowProps {
  cardapio: Cardapio;
  onConfig: (cardapio: Cardapio) => void;
}

export const Row = (props: RowProps) => {
  const { cardapio: item, onConfig } = props;
  const [statusItem, setStatusItem] = useState(item.status);
  const [cardapio, setCardapio] = useState(item);

  const handleSwitchStatus = async () => {
    if (!statusItem) {
      try {
        const response = await ClienteAPI.ativarClienteStatus(
          Number(cardapio.id),
          Number(cardapio.funcionalidade.id),
          { throwError: true },
        );
        if (!cardapio.id) {
          setCardapio((old: any) => ({ ...old, id: response.id }));
        }

        return setStatusItem(response.status);
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const response = await ClienteAPI.inativarClienteStatus(
        Number(cardapio.id),
        { throwError: true },
      );
      setStatusItem(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  const { isMobile } = useSize();

  const handleConfig = () => {
    onConfig(cardapio);
  };

  const alertDivergencia = (
    <AlertBox
      text="Funcionalidade desabilitada pelo usuário Master."
      visible={!cardapio.funcionalidade.status}
    />
  );

  const switchStatus = (
    <SwitchStatus
      cardapio={cardapio}
      isAtivo={statusItem}
      setAtivo={setStatusItem}
    />
  );

  const commonProps = {
    cardapio,
    onConfig: handleConfig,
    onSwitchStatus: handleSwitchStatus,
    alertDivergencia,
  };

  return isMobile ? (
    <MobileRow {...commonProps} statusItem={statusItem} />
  ) : (
    <DesktopRow {...commonProps} switchStatus={switchStatus} />
  );
};
