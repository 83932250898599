import Http, { HttpParams } from 'src/core/http/Http';

export const getHistoricoExportacao = async (
  params: any,
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `exportacao-prontuario/historico/${idPaciente}`,
    params,
    return: true,
    hideToast: true,
    ...options,
  });
};

export const alterarEntregue = async (
  idPaciente: number,
  idExportacao: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `exportacao-prontuario/historico/${idPaciente}/registrar-entrega/${idExportacao}`,
    data: params,
    return: true,
    ...options,
  });
};

export const HistoricoExportacaoAPI = {
  alterarEntregue,
  getHistoricoExportacao,
};
