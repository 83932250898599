import React, { useEffect, useRef, useState } from 'react';

import { Splitter, SplitterPanel } from 'primereact/splitter';

import _ from 'lodash';
import DnpmAPI from 'src/APIs/ProntuarioAPI/DnpmAPI/DnpmAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const Colunas = [
  'Questionário',
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  '1 ano',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  '2 anos',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  '3 anos',
];

const emitter = (() => {
  // Keep track of the currently highlighted row and column
  let currentRow = -1;
  let currentCol = -1;
  // 2d array indexed by row and column
  const subs: any[] = [];
  return {
    // Subscribe function that will be called once for each cell, in a
    // useEffect
    subscribe(r: any, c: any, cb: any) {
      subs[r] = subs[r] ?? [];
      // Note that this does not handle multiple subscriptions for the
      // same cell
      subs[r][c] = cb;
      // This will be invoked by useEffect's cleanup
      return () => delete subs[r][c];
    },
    // Called by each cell when it is hovered
    highlight(newRow: any, newCol: any) {
      subs.forEach((row, r) => {
        row.forEach((cb: any, c: any) => {
          const wasHighlighted = r === currentRow || c === currentCol;
          const isHighlighted = r === newRow || c === newCol;
          // Only notify if the highlighting for this row has changed.
          // We could optimize this loop to only run for the changed
          // rows, but you're unlikely to see noticable gains.
          if (wasHighlighted !== isHighlighted) {
            cb(isHighlighted);
          }
        });
      });

      // Update the currently highlighted cell, otherwise you'll never
      // unhighlight the old ones.
      currentRow = newRow;
      currentCol = newCol;
    },
  };
})();

interface TableProps {
  setRespostasQuestionario: any;
  questionario: any;
}

function Table({ setRespostasQuestionario, questionario }: TableProps) {
  const [perguntas, setPerguntas] = useState<any[]>([]);
  console.log('🚀 ~ Table ~ perguntas:', perguntas);
  const [perguntasQuestionario, setPerguntasQuestionario] = useState<any[]>([]);

  useEffect(() => {
    const getPerguntas = async () => {
      const response = await DnpmAPI.getPerguntas();
      setPerguntas(response.data);
    };
    getPerguntas();
  }, []);

  useEffect(() => {
    if (questionario) {
      setPerguntasQuestionario(questionario);
    }
  }, [questionario]);

  const handleClickColuna = (pergunta: any, coluna: any) => {
    if (perguntasQuestionario[pergunta] !== coluna) {
      setPerguntasQuestionario((prevState: any) => ({
        ...prevState,
        [pergunta]: coluna,
      }));
      setRespostasQuestionario((prevState: any) => ({
        ...prevState,
        [pergunta]: coluna,
      }));
    } else {
      setPerguntasQuestionario((prevState: any) => ({
        ...prevState,
        [pergunta]: '',
      }));
      setRespostasQuestionario((prevState: any) => ({
        ...prevState,
        [pergunta]: '',
      }));
    }
  };

  return (
    <table>
      <thead className="p-d-flex p-w-100 p-flex-wrap p-pb-2">
        <tr
          className="p-d-flex p-jc-center content-header p-col-6 p-p-0"
          style={{ width: '30%' }}
        >
          <th rowSpan={2} className="p-text-center">
            <SimpleText medium>Questionário</SimpleText>
          </th>
        </tr>

        <tr
          className="content-header p-d-flex p-jc-center p-col-6 p-p-0"
          style={{ width: '70%' }}
        >
          <th rowSpan={2} className="p-text-center">
            <SimpleText medium>Idade em meses</SimpleText>
          </th>
        </tr>
      </thead>

      <tbody className="p-d-flex p-w-100">
        <Splitter className="p-w-100">
          <SplitterPanel
            size={45}
            minSize={10}
            style={{
              maxWidth: 'fit-content',
            }}
          >
            <tr className="content-header-description">
              <th>&nbsp;</th>
            </tr>
            {perguntas.map((pergunta, i) => (
              <tr className="content-description" key={i}>
                <td className="p-p-0">{pergunta.descricao}&nbsp;</td>
              </tr>
            ))}
          </SplitterPanel>
          <SplitterPanel size={55}>
            <tr className="content-header-idades">
              {Colunas.map((coluna, index) => {
                if (coluna === 'Questionário') {
                  return null;
                } else if (coluna === '1 ano') {
                  return (
                    <th
                      key={index}
                      className="p-d-flex p-flex-column p-text-center"
                    >
                      <SimpleText medium>1</SimpleText>
                      <SimpleText medium fontSize="xxs">
                        ano
                      </SimpleText>
                    </th>
                  );
                } else if (coluna === '2 anos') {
                  return (
                    <th
                      key={index}
                      className="p-d-flex p-flex-column p-text-center"
                    >
                      <SimpleText medium>2</SimpleText>
                      <SimpleText medium fontSize="xxs">
                        anos
                      </SimpleText>
                    </th>
                  );
                } else if (coluna === '3 anos') {
                  return (
                    <th
                      key={index}
                      className="p-d-flex p-flex-column p-text-center"
                    >
                      <SimpleText medium>3</SimpleText>
                      <SimpleText medium fontSize="xxs">
                        anos
                      </SimpleText>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className="p-text-center">
                      <SimpleText>{coluna}</SimpleText>
                    </th>
                  );
                }
              })}
            </tr>

            {perguntas.map((pergunta, i) => (
              <TableRow
                key={i}
                row={i}
                pergunta={pergunta}
                colunas={38}
                handleClickColuna={handleClickColuna}
                perguntasQuestionario={perguntasQuestionario}
              />
            ))}
          </SplitterPanel>
        </Splitter>
      </tbody>
    </table>
  );
}
interface TableRowProps {
  row: number;
  pergunta: any;
  colunas: any;
  handleClickColuna: any;
  perguntasQuestionario: any;
}

const TableRow = React.memo(
  ({
    row,
    pergunta,
    colunas,
    handleClickColuna,
    perguntasQuestionario,
  }: TableRowProps) => {
    return (
      <tr>
        {Array(colunas)
          .fill(0)
          .map((_, i) => (
            <TableCell
              key={i}
              row={row}
              column={i}
              pergunta={pergunta}
              handleClickColuna={handleClickColuna}
              perguntasQuestionario={perguntasQuestionario}
            />
          ))}
      </tr>
    );
  },
);
interface TableCellProps {
  row: number;
  column: number;
  pergunta: any;
  handleClickColuna: any;
  perguntasQuestionario: any;
}

function TableCell({
  row,
  column,
  pergunta,
  handleClickColuna,
  perguntasQuestionario,
}: TableCellProps) {
  const { atendimentoStatus } = useAtendimento();
  const ref = useRef<any>();

  const adequado =
    column - 1 >= pergunta.enquadramentoInicial &&
    column - 1 <= pergunta.enquadramentoFinal;

  useEffect(() => {
    emitter.subscribe(row, column, (isHighlighted: boolean) => {
      if (ref.current) {
        // Directly update the class on the DOM node
        ref.current.classList.toggle('highlight-cell', isHighlighted);
      }
    });
  }, [column, row]);

  const checkSelected = () => {
    const marcado = Object.entries(perguntasQuestionario).find(
      ([key, value]) => {
        return key === _.camelCase(pergunta.codigo) && value === column - 1;
      },
    );

    if (!marcado) {
      return <i style={{ width: '12px', height: '12px' }}></i>;
    }

    return <i className="fa-solid fa-circle" />;
  };

  return column === 0 ? (
    <></>
  ) : (
    <td
      onMouseEnter={() => emitter.highlight(row, column)}
      id={_.camelCase(pergunta.codigo)}
      ref={ref}
      style={adequado ? { background: '#E0E4EC', borderRadius: '3px' } : {}}
      className={adequado ? 'adequado coluna' : 'atrasado coluna'}
      onClick={() =>
        atendimentoStatus === 'ATENDENDO'
          ? handleClickColuna(_.camelCase(pergunta.codigo), column - 1)
          : {}
      }
    >
      <div className="p-d-flex p-jc-center p-ai-center">{checkSelected()}</div>
    </td>
  );
}

export default Table;
