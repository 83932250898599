import { useCallback, useEffect, useState } from 'react';

import AdminAPI from 'src/APIs/AdminAPI/AdminAPI';

import Button from 'src/components/Basics/Button/Buttons';
import Page from 'src/components/Basics/Page/Page';
import './Feedback.scss';

const Feedback = () => {
  const [Feedbacks, setFeedbacks] = useState<Satisfacao[]>();
  const [FeedbackById, setFeedbackById] = useState<Satisfacao>();

  const loadPage = useCallback(async () => {
    const aux = await AdminAPI.SatisfacaoAPI.loadSatisfacaos();
    if (aux) {
      setFeedbacks(aux);
    }
    const auxById = await AdminAPI.SatisfacaoAPI.loadSatisfacaoById(1);
    if (auxById) {
      setFeedbackById(aux);
    }
  }, []);

  const saveFeedback = useCallback(() => {
    const aux: PostSatisfacaoDTO = {
      nivelSatisfacao: 'INSATISFEITO',
      justificativa: 'Luzinal atende muito mau',
      nomeMae: 'Mãe do Luzi',
      contato: {
        numero: '(11) 3423-3223',
        tipo: 'CELULAR_PARTICULAR',
      },
      usuario: {
        id: '2',
      },
    };
    AdminAPI.SatisfacaoAPI.sendSatisfacao(aux);
  }, []);

  const updateFeedback = useCallback(() => {
    const aux: PostSatisfacaoDTO = {
      nivelSatisfacao: 'INSATISFEITO 2',
      justificativa: 'Luzinal atende muito mau',
      nomeMae: 'Mãe do Luzi',
      contato: {
        numero: '(11) 3423-3223',
        tipo: 'CELULAR_PARTICULAR',
      },
      usuario: {
        id: '2',
      },
    };
    AdminAPI.SatisfacaoAPI.updateSatisfacao(2, aux);
  }, []);

  const removeFeedback = useCallback(() => {
    AdminAPI.SatisfacaoAPI.removeSatisfacaoById(2);
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  useEffect(() => {
    console.log(Feedbacks, FeedbackById);
  }, [Feedbacks, FeedbackById]);

  return (
    <Page>
      <>
        <span>Feedback</span>
        <Button
          label={'salvar'}
          onClick={() => {
            saveFeedback();
          }}
        />
        <Button
          label={'Atualizar'}
          onClick={() => {
            updateFeedback();
          }}
        />
        <Button
          label={'Remover'}
          onClick={() => {
            removeFeedback();
          }}
        />
      </>
    </Page>
  );
};

export default Feedback;
