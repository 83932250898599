export const formularioAuthorities = [
  // Pergunta_formulario_dinamico
  'ADM_MANTER_PERGUNTA_FORMULARIO_DINAMICO_CONSULTAR',
  // Formulario_dinamico
  'ADM_MANTER_FORMULARIO_DINAMICO_CONSULTAR',
  // Aplicacao_formulario_dinamico
  'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_CONSULTAR',

  'ADM_GESTAO_MONITORAMENTO_FORMULARIO_DINAMICO',
];
