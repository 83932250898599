import {
  RadioButton,
  RadioButtonProps,
} from 'primereact/radiobutton';

import './CardOption.scss';

export interface CardOptionProps extends RadioButtonProps {
  children: JSX.Element|JSX.Element[];
  className?: string;
}

const CardOption = ({ 
  children, 
  checked = false, 
  className,
  ...props 
}: CardOptionProps) => {
  return (
    <div className={`card-option ${checked ? 'selected' : ''} ${className ? className : ''}`}>
      <RadioButton checked={checked} {...props} /> 
      {children}
    </div>
  );
}

export default CardOption;