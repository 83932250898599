import { ReactNode, useState } from 'react';

import {
  HistoricoAtendimentoContext,
  INITIAL_STATE,
} from 'src/core/contexts/Atendimento/HistoricoAtendimento';

interface HistoricoAtendimentoProviderProps {
  initialAtendimento?: any;
  historicoAssinatura?: boolean;
  children: ReactNode;
}

export const HistoricoAtendimentoProvider = ({
  initialAtendimento = null,
  historicoAssinatura = false,
  children,
}: HistoricoAtendimentoProviderProps) => {
  const [quantidadeRegistros, setQuantidadeRegistros] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [ocultarRegistros, setOcultarRegistros] = useState(false);
  const [todosAtendimentos, setTodosAtendimentos] = useState(true);
  const [atualAtendimento, setAtualAtendimento] = useState(false);
  const [filtroHistorico, setFiltroHistorico] = useState<string | null>(
    'ANAMNESE',
  );
  const [selectedAtendimento, setSelectedAtendimento] =
    useState<any>(initialAtendimento);
  const [selectedCidCiap, setSelectedCidCiap] = useState<any>(null);
  const [reload, setReload] = useState(false);
  const [itensFichaPaciente, setItensFichaPaciente] = useState<any>(0);
  const [quantidadePreAtendimento, setQuantidadePreAtendimento] = useState(0);
  const [updateList, setUpdateList] = useState<number>(0);

  const isHistoricoAssinatura = historicoAssinatura;

  const optionsHistorico = [
    { label: 'Anamnese', value: 'ANAMNESE' },
    { label: 'Receitas', value: 'RECEITAS' },
    { label: 'Exames e Procedimentos', value: 'EXAMES_PROCEDIMENTOS' },
  ];

  return (
    <HistoricoAtendimentoContext.Provider
      value={{
        state: INITIAL_STATE,
        quantidadeRegistros,
        totalRegistros,
        setTotalRegistros,
        ocultarRegistros,
        setQuantidadeRegistros,
        setOcultarRegistros,
        todosAtendimentos,
        setTodosAtendimentos,
        atualAtendimento,
        setAtualAtendimento,
        optionsHistorico,
        filtroHistorico,
        setFiltroHistorico,
        selectedAtendimento,
        setSelectedAtendimento,
        selectedCidCiap,
        setSelectedCidCiap,
        reload,
        setReload,
        itensFichaPaciente,
        setItensFichaPaciente,
        quantidadePreAtendimento,
        setQuantidadePreAtendimento,
        updateList,
        setUpdateList,
        isHistoricoAssinatura,
      }}
    >
      {children}
    </HistoricoAtendimentoContext.Provider>
  );
};
