import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface MobileRowProps {
  convenio: Convenio;
  onViewConvenio: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onSwitchStatus: () => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { convenio, onViewConvenio, onEdit, onDelete, onSwitchStatus } = props;

  const options = [
    {
      label: 'Visualizar',
      command: onViewConvenio,
    },
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_CONVENIO_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: onDelete,
      checkPermission: 'ADM_CONVENIO_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="column ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {convenio.id}
        </SimpleText>
      </div>

      <div className="column p-mt-4">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Convênio
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {convenio.razaoSocial}
        </SimpleText>
      </div>

      <div className="p-mt-4">
        <Can checkPermission="ADM_CONVENIO_ALTERAR">
          {can => (
            <Switch
              checked={convenio.ativo}
              onChange={onSwitchStatus}
              disabled={!can}
            />
          )}
        </Can>
      </div>
    </SearchListMobileRow>
  );
};
