import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import AuthAPI from 'src/APIs/SecurityAPI/AuthAPI/AuthAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setUser } from 'src/core/redux/slices/system/UserSlice';
import { RootState } from 'src/core/redux/store';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import './SelectEmpresa.scss';

const SelectEmpresa = () => {
  const { user } = useAppSelector((state: RootState) => state);

  const dispatch = useAppDispatch();

  const handleSelectEmpresa = async (v: any) => {
    const res = await AuthAPI.refreshtokenEmpresa(user?.refresh_token, v?.id);
    dispatch(
      setUser({
        ...res,
        idEmpresa: v.id,
        nomeEmpresa: v.razaoSocial,
        logoUrl: v.logoUrl,
      }),
    );

    document.getElementById('root')?.click();
  };

  const fetchEmpresas = async (params: any) => {
    const response = await UsuarioAPI.loadSensitiveUtils(user, {
      nome: params?.query,
      ...params,
    });
    return response?.empresas;
  };

  const renderRow = (data: any): JSX.Element => {
    return (
      <div
        className="item-select p-my-1"
        onClick={() => handleSelectEmpresa(data)}
      >
        <SimpleText>{data.razaoSocial}</SimpleText>
      </div>
    );
  };

  return (
    <div className="overlay-empresa">
      <SearchList.Root fetchApi={fetchEmpresas}>
        <SearchList.SearchInput
          placeholder="Pesquisar por nome"
          className="p-w-100 p-mb-1"
        />

        <SearchList.InfiniteScroll
          customEmptyText="Nenhuma empresa disponível"
          renderRow={renderRow}
          autoHeight={false}
        />
      </SearchList.Root>
    </div>
  );
};

export default SelectEmpresa;
