import { useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import { InputNumber } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import DropdownServicosTUSS from './DropdownServicoTUSS';
import SolicitacaoItem from './SolicitacaoItem';

const DefinicaoSolicitacaoExames = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [servicoSelected, setServicoSelected] = useState<any>(null);
  const [quantidade, setQuantidade] = useState<string>();
  const [updateIndex, setUpdateIndex] = useState<number>();

  const { control, watch, setValue, getValues } = useFormContext();

  const {
    fields: solicitacaoExamesFields,
    append: appendSolicitacaoExames,
    remove: removeSolicitacaoExames,
    update: updateSolicitacaoExames,
  } = useFieldArray({
    control,
    name: 'solicitacaoExames',
  });

  const checkServicosDuplicados = () => {
    if (!servicoSelected || !quantidade) return false;

    if (isUpdating) {
      return solicitacaoExamesFields.some(
        (solicitacao: any) =>
          solicitacao.servicoTuss.id === servicoSelected.id &&
          solicitacao.quantidade === quantidade,
      );
    }

    return solicitacaoExamesFields.some(
      (solicitacao: any) => solicitacao.servicoTuss.id === servicoSelected.id,
    );
  };

  const handleAddSolicitacaoExame = () => {
    if (!servicoSelected || !quantidade) {
      return;
    }

    const isDuplicated = checkServicosDuplicados();
    if (isDuplicated) {
      return toast(<Toast />, {
        data: {
          message: 'Este serviço já foi adicionado.',
          type: 'warning',
        },
      });
    }

    appendSolicitacaoExames({
      servicoTuss: servicoSelected,
      quantidade,
    });
    setQuantidade('');
    return setServicoSelected(null);
  };

  const handleRemove = (index: number) => {
    removeSolicitacaoExames(index);
  };

  const handleUpdate = (solicitacao: any, index: number) => {
    setIsUpdating(true);
    setServicoSelected(solicitacao.servicoTuss);
    setQuantidade(solicitacao.quantidade);
    setUpdateIndex(index);
  };

  const handleUpdateSolicitacao = () => {
    if (
      !servicoSelected ||
      !quantidade ||
      updateIndex === undefined ||
      updateIndex === null
    ) {
      return;
    }

    const isDuplicated = checkServicosDuplicados();
    if (isDuplicated) {
      return toast(<Toast />, {
        data: {
          message: 'Este serviço já foi adicionado.',
          type: 'warning',
        },
      });
    }

    updateSolicitacaoExames(updateIndex, {
      servicoTuss: servicoSelected,
      quantidade,
    });
    setQuantidade('');
    setServicoSelected(null);
    return setIsUpdating(false);
  };

  return (
    <div className="definicao-container p-p-2">
      <DropdownServicosTUSS
        control={control}
        servicoSelected={servicoSelected}
        setServicoSelected={setServicoSelected}
      />
      <TextInput
        label="Quantidade"
        className="p-col-12 p-md-6"
        value={quantidade || ''}
        onChange={e => setQuantidade(e.target.value)}
        permitirApenasNumeros
      />
      <Button
        label={
          isUpdating ? 'Editar o serviço TUSS' : 'Adicionar o serviço TUSS'
        }
        onClick={() =>
          isUpdating ? handleUpdateSolicitacao() : handleAddSolicitacaoExame()
        }
        icon="pi pi-plus"
        iconPos="left"
        disabled={!servicoSelected || !quantidade}
        stretch
      />
      {isUpdating && (
        <Button
          className="p-my-1"
          label={`Cancelar edição`}
          onClick={() => {}}
          icon="pi pi-times"
          iconPos="left"
          btnType="light-danger"
          // disabled={!descricaoMotivo}
          stretch
        />
      )}

      <div className="p-col-12">
        <div
          className="border-container"
          style={{ overflowY: 'auto', maxHeight: '200px' }}
        >
          <div className="lista-respostas">
            {!solicitacaoExamesFields.length && (
              <Card className="p-mb-2 p-text-center" type={CARD_TYPES.GRAY}>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  Não há solicitação de exame cadastrado.
                </SimpleText>
              </Card>
            )}

            {solicitacaoExamesFields.map((solicitacao: any, index) => {
              const idSolcitacao =
                solicitacao.servicoTuss?.id || solicitacao.idServicoTuss;
              console.log(solicitacao);
              return (
                <SolicitacaoItem
                  key={idSolcitacao}
                  index={index}
                  handleRemove={handleRemove}
                  solicitacao={solicitacao}
                  updateSolicitacao={handleUpdate}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefinicaoSolicitacaoExames;
