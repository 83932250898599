import { useNavigate } from 'react-router';

import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  lembrete: GetLembreteAgendamentoDTO;
  tabIndex: number;
  onDelete: (id: number) => void;
}

export const Row = (props: RowProps) => {
  const { lembrete, onDelete, tabIndex } = props;

  const navigate = useNavigate();
  const { isMobile } = useSize();

  const onEdit = (lembrete: GetLembreteAgendamentoDTO) => {
    if (lembrete.id) {
      navigate(`/config/general/lembrete/${lembrete.id}`, {
        state: tabIndex,
      });
    }
  };

  const cumonProps = {
    lembrete,
    onEdit,
    onDelete,
  };

  return isMobile ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
