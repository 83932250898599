import { useFormContext, useFieldArray } from 'react-hook-form';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldMultiselectServicoTuss } from 'src/components/FieldMultiselectServicoTuss/FieldMultiselectServicoTuss';

import './Procedimentos.scss';

export const Procedimentos = () => {
  const { idConvenio } = useSolicitacaoExameCirurgia();

  const { isMobile } = useSize();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    fields: procedimentos,
    append: appendProcedimentos,
    remove: removeProcedimentos,
  } = useFieldArray({
    control,
    name: 'grupoExameServicoTussSet',
  });

  const handleAddProcedimentos = (procedimentos: any[]) => {
    const procedimentosFormatted = procedimentos.map(procedimento => ({
      codigo: procedimento.codigo,
      nome: procedimento.nome,
      idServicoTuss: procedimento.id,
    }));

    appendProcedimentos(procedimentosFormatted);
  };

  const render = () => {
    if (!procedimentos.length) {
      return (
        <div id="empty-procedimentos">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Nenhum procedimento selecionado. <br />
            Você pode usar a busca para selecionar os procedimentos e adicionar
            no seu novo grupo.
          </SimpleText>
        </div>
      );
    }

    return procedimentos.map((procedimento: any, index) => (
      <div
        key={procedimento.idServicoTuss}
        className="procedimento-item p-ai-center p-jc-between p-flex-column p-flex-sm-row"
      >
        <div className="p-d-flex p-ai-center p-flex-column p-flex-sm-row p-text-center p-text-sm-left">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {procedimento.codigo}
          </SimpleText>

          <SimpleText
            className="p-my-2 p-my-sm-0"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {procedimento.nome}
          </SimpleText>
        </div>

        <div className="p-d-flex">
          {!isMobile && <Divider layout="vertical" />}

          <Button
            className="p-col-1"
            btnType="gray"
            icon="fas fa-trash"
            onClick={() => removeProcedimentos(index)}
          />
        </div>
      </div>
    ));
  };

  const borderContainerClassName = `p-col-12 ${
    errors.grupoExameServicoTussSet?.message ? 'error-border' : ''
  }`;

  return (
    <>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
        Procedimentos do novo grupo
      </SimpleText>

      <div
        id="procedimentos-grupo-container"
        className={borderContainerClassName}
      >
        <div className="p-grid p-col-12 p-align-end p-p-0 p-mb-2">
          <div className="p-col">
            <FieldMultiselectServicoTuss
              handleAddProcedimentos={handleAddProcedimentos}
              params={{
                idConvenio,
              }}
            />
          </div>

          <div className="p-col-fixed">
            <Dropdown
              label="Versão"
              value="TUSS"
              name="versao"
              filter={false}
              options={[{ value: 'TUSS', label: 'TUSS' }]}
            />
          </div>
        </div>

        {render()}
      </div>

      {!!errors.grupoExameServicoTussSet?.message && (
        <SimpleText className="error p-my-3" fontSize={FONT_SIZE.XXXS}>
          {errors.grupoExameServicoTussSet?.message}
        </SimpleText>
      )}
    </>
  );
};
