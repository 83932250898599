import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/servico-tuss';

export const loadServicoTussComplementaresById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetServicoTussComplementaresOutDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/dadosComplementares`,
    hideToast: true,
    ...options,
  });
};

export const updateServicoComplementaresTuss = (
  id: number,
  payload: PutServicoTussComplementaresInDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetServicoTussComplementaresOutDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/dadosComplementares`,
    data: payload,
    return: true,
    ...options,
  });
};

const ServicoTussComplementaresAPI = {
  loadServicoTussComplementaresById,
  updateServicoComplementaresTuss,
};

export default ServicoTussComplementaresAPI;
