import { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ProfissionalAPI/ProfissionalAPI';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';

import { Checkbox } from 'src/components/_UI/Checkbox';
import AlertBox from 'src/components/AlertBox/AlertBox';

interface HealthProfessionalCustomizationProps {
  control: any;
}

function HealthProfessionalCustomization({
  control,
}: HealthProfessionalCustomizationProps) {
  const { idUser } = useCreateUser();

  const [hasPacientesVinculados, setHasPacientesVinculados] = useState(false);

  useEffect(() => {
    const getPacientesVinculados = async () => {
      if (!idUser) return;

      const response = await ProfissionalAPI.getPacientesVinculadosCarteiraAps(
        idUser,
      );

      if (response.status === 200) {
        setHasPacientesVinculados(response.data);
      }
    };

    getPacientesVinculados();
  }, [idUser]);

  return (
    <div className="p-col-12 p-xl-6 p-px-0">
      <div className="checkboxescustom">
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Ocultar campo CID"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="ocultarCid"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Ocultar campo CIAP"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="ocultarCiap"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Obrigatório informar motivo de atendimento"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="habilitaObrigarMotivoAtendimento"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Profissional não cooperado"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="profissionalNaoCooperado"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Transação TISS como Pessoa Juridica"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="transacaoTissPessoaJuridica"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Confirmação do cartão"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="confirmacaoCartao"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Autorizar sem cartão"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="autorizarSemCartao"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <div className="grid p-col-12">
              <Checkbox
                label="Cuidador"
                value={value}
                checked={value}
                onChange={onChange}
              />

              <AlertBox
                visible={hasPacientesVinculados}
                text="Este campo não pode ser desmarcado, pois o profissional possui pacientes vinculados!"
                onHide={() => setHasPacientesVinculados(false)}
              />
            </div>
          )}
          name="cuidador"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Cuidador Viver Bem"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="cuidadorViverBem"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Profissional cuidador agendamento online"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="profissionalCuidadorAgendamentoOnline"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Não realiza transação TISS"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="naoRealizaTransacaoTiss"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Habilitar notificação Avaliação Contrarreferência"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="habilitarNotificacaoAvaliacaoContrarreferencia"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Envio de SMS"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="habilitaEnvioSms"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Habilitar alerta upload exame agendamento online"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="habilitarAlertaUploadExameAgendamentoOnline"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Restringir bloqueio consultórios APS"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="restringirBloqueioAps"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Restringir bloqueio de horários"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="restringirBloqueioHorario"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }: any) => (
            <Checkbox
              label="Atendimento padrão retorno"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="atendimentoRetornoPadrao"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }: any) => (
            <Checkbox
              label="Prontuário restrito"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="prontuarioRestrito"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }: any) => (
            <Checkbox
              label="Atende obstetrícia"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="atendeObstetricia"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }: any) => (
            <Checkbox
              label="Exibir folha rosto no prontuário"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="exibirFolhaRostoProntuario"
        />

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }: any) => (
            <Checkbox
              label="Executa prescrição"
              className="grid p-col-12 p-md-12"
              value={value}
              checked={value}
              onChange={onChange}
            />
          )}
          name="executaPrescricao"
        />
      </div>
    </div>
  );
}

export default HealthProfessionalCustomization;
