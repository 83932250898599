import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  motivo: any;
}

const PanelContent = ({ motivo }: PanelContentProps) => {
  return (
    <div className="p-d-flex p-flex-column p-gap-2 row-component p-p-2">
      <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
        Descrição
      </SimpleText>
      <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
        {motivo.descricao}
      </SimpleText>
    </div>
  );
};

export default PanelContent;
