import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import { AuditoriaAPI } from 'src/APIs/AdminAPI/AuditoriaAPI/AuditoriaAPI';

import { stringToEnum } from 'src/utils/utils';

import { BtnTypes } from 'src/components/Basics/Button/Buttons';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldSearchUsuario } from 'src/components/Fields/FieldSearchUsuario/FieldSearchUsuario';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';
interface FilterProps {
  habilitarFilter: boolean;
  watchDataFinal: Date;
  watchDataInicial: Date;
}

export const Filter = ({
  habilitarFilter,
  watchDataFinal,
  watchDataInicial,
}: FilterProps) => {
  const [tabelaOptions, setTabelaOptions] = useState<any[]>([]);
  const [tiposUsuariosOptions, setTiposUsuariosOptions] = useState<any[]>([]);
  const [optionsTipoOperacao, setOptionsTipoOperacao] = useState<any[]>([]);

  const { getValues, reset, control, watch, setValue } = useFormContext();
  const { setFilter } = useSearchList();

  const handleFilter = () => {
    const filter = getValues();
    return {
      ...(filter?.tipoOperacao?.codigo && {
        tipoOperacao: filter.tipoOperacao.codigo,
      }),
      ...(filter?.tabela?.codigo && { tabela: filter.tabela.codigo }),
      ...(filter.numeroAssinatura && {
        numeroAssinatura: filter.numeroAssinatura,
      }),
      ...(filter?.usuario && { usuario: filter.usuario?.id }),
      ...(filter?.tipoUsuario?.descricao && {
        tipoUsuario: stringToEnum(filter.tipoUsuario.descricao),
      }),
      ...(filter?.idPaciente && { idPaciente: filter.idPaciente }),
      ...(filter?.chavePrimaria && { chavePrimaria: filter?.chavePrimaria }),
      dataInicial: watchDataInicial
        ? dayjs(new Date(watchDataInicial)).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        : null,
      dataFinal: watchDataFinal
        ? dayjs(new Date(watchDataFinal)).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        : null,
    };
  };

  const handleClearFilters = () => {
    const data = {
      tipoOperacao: '',
      tabela: '',
      numeroAssinatura: '',
      chavePrimaria: '',
      idPaciente: '',
      tipoUsuario: '',
      usuario: '',
      dataInicial: new Date(watchDataInicial) as any,
      dataFinal: new Date(watchDataFinal) as any,
    };

    reset(data);
    setFilter(data);
    return {
      dataInicial: new Date(watchDataInicial) as any,
      dataFinal: new Date(watchDataFinal) as any,
    };
  };

  const [tipoUsuario] = watch(['tipoUsuario']);

  useEffect(() => {
    const getTabelas = async () => {
      const response = await AuditoriaAPI.getTabelas();
      if (response) {
        setTabelaOptions(response);
      }
    };
    const getTiposOperacoes = async () => {
      const response = await AuditoriaAPI.getTiposOperacoes();
      setOptionsTipoOperacao(response);
    };
    getTiposOperacoes();
    getTabelas();
  }, []);

  useEffect(() => {
    const getTiposUsuario = async () => {
      const response = await AuditoriaAPI.getTiposUsuario();
      if (response) {
        setTiposUsuariosOptions(response);
      }
    };
    getTiposUsuario();
  }, []);

  return (
    <SearchListFilter
      onFilter={handleFilter}
      onClearFilters={handleClearFilters}
      btnType={BtnTypes.TONAL}
      type="button"
      disabled={!habilitarFilter}
    >
      <div className="p-grid filter">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_40}
          fontSize={FONT_SIZE.XXS}
          className="p-col-12"
        >
          Selecione o tipo de informação que você procura.
        </SimpleText>

        <div className="p-col-6">
          <DropdownControlled
            control={control}
            name="tipoOperacao"
            label="Tipo de operação"
            placeholder="Selecione..."
            optionLabel="descricao"
            dataKey="codigo"
            options={optionsTipoOperacao}
            hideTextErrorSpace
          />
        </div>

        <div className="p-col-6">
          <DropdownControlled
            control={control}
            name="tabela"
            label="Tabela"
            placeholder="Selecione..."
            optionLabel="descricao"
            dataKey="codigo"
            options={tabelaOptions}
            hideTextErrorSpace
          />
        </div>

        <div className="p-col-6">
          <FormInput
            name="numeroAssinatura"
            label="Número da assinatura"
            placeholder="Digite o número..."
            permitirApenasNumeros
            hideTextErrorSpace
          />
        </div>

        <div className="p-col-6">
          <FormInput
            name="chavePrimaria"
            label="Chave primária"
            placeholder="Digite a chave..."
            hideTextErrorSpace
          />
        </div>

        <div className="p-col-12">
          <FormInput
            name="idPaciente"
            label="ID do paciente"
            placeholder="Digite o ID..."
            permitirApenasNumeros
            hideTextErrorSpace
          />
        </div>

        <SimpleText
          className="p-col-12"
          fontColor={FONT_COLOR.COLOR_40}
          fontSize={FONT_SIZE.XXS}
        >
          Filtre por tipo de usuário e/ou usuário
        </SimpleText>

        <div className="p-col-12">
          <DropdownControlled
            control={control}
            name="tipoUsuario"
            label="Tipo de usuário"
            placeholder="Selecione..."
            optionLabel="descricao"
            dataKey="codigo"
            options={tiposUsuariosOptions}
            customOnChange={() => setValue('usuario', '')}
          />
        </div>

        <FieldSearchUsuario tipoUsuario={tipoUsuario} />
      </div>
    </SearchListFilter>
  );
};
