import { useState } from 'react';

import { PortalPacienteAPI } from 'src/APIs/AdminAPI/PortalPacienteAPI/PortalPacienteAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './Feedback.scss';
import { FeedbackAgradecimento } from './FeedbackAgradecimento';

export const Feedback = ({ isOpen, close, state }: DisclosureType) => {
  const [selectedEmoji, setSelectedEmoji] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isVoted, setIsVoted] = useState(false);

  const handleSendFeecback = () => {
    if (selectedEmoji) {
      setIsLoading(true);

      PortalPacienteAPI.sendAvaliacaoAtendimento({
        idAtendimento: state?.idAtendimento,
        nota: selectedEmoji,
      })
        .then(() => setIsVoted(true))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      closable={false}
      modal={false}
      draggable={false}
      className="dialog-feedback-portal"
      position="bottom-right"
      showHeader={isVoted}
      header={
        isVoted ? (
          <SimpleText fontSize="sm" fontColor="color_40" medium>
            Obrigado por compartilhar a sua opinião.
          </SimpleText>
        ) : null
      }
    >
      {isVoted ? (
        <FeedbackAgradecimento close={close} />
      ) : (
        <div className="p-d-flex p-flex-column p-gap-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.SM}>
            Como foi seu último atendimento?
          </SimpleText>
          <SimpleText
            className="p-line-height-2"
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
          >
            {state?.conteudo}
          </SimpleText>

          <div className="emoji-area p-d-flex p-gap-2">
            <div
              className={`emoji-container ${
                selectedEmoji === 1 ? 'selected' : ''
              }`}
              onClick={() => setSelectedEmoji(1)}
            >
              😣
            </div>
            <div
              className={`emoji-container ${
                selectedEmoji === 2 ? 'selected' : ''
              }`}
              onClick={() => setSelectedEmoji(2)}
            >
              ☹️
            </div>
            <div
              className={`emoji-container ${
                selectedEmoji === 3 ? 'selected' : ''
              }`}
              onClick={() => setSelectedEmoji(3)}
            >
              😐
            </div>
            <div
              className={`emoji-container ${
                selectedEmoji === 4 ? 'selected' : ''
              }`}
              onClick={() => setSelectedEmoji(4)}
            >
              😊
            </div>
            <div
              className={`emoji-container ${
                selectedEmoji === 5 ? 'selected' : ''
              }`}
              onClick={() => setSelectedEmoji(5)}
            >
              🥰
            </div>
          </div>

          <Divider layout="horizontal" />

          <div className="p-col-12 p-d-flex p-flex-row p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Fechar"
              type="button"
              btnType="green-link"
              onClick={() => close()}
              stretch
              disabled={isLoading}
            />
            <Button
              label="Enviar"
              type="button"
              onClick={() => handleSendFeecback()}
              disabled={!selectedEmoji}
              loading={isLoading}
              stretch
            />
          </div>
        </div>
      )}
    </Dialog>
  );
};
