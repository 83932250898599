import React from 'react';

import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';

const DefinicaoImpressos = () => {
  const { control, watch } = useFormContext();

  const watchImpressos = watch('impressos');

  return (
    <div className="definicao-container p-p-2">
      <TextInputControlled control={control} name="impressos" hidden />
      <TextareaInputControlled
        control={control}
        label="Atestado"
        name="impressos.atestado"
        placeholder="Excreva os sintomas do paciente"
        maxLength={4000}
        maxLengthSpanDesc
      />
      <TextareaInputControlled
        control={control}
        label="Orientacao"
        name="impressos.orientacao"
        maxLength={4000}
        maxLengthSpanDesc
      />
      <TextareaInputControlled
        control={control}
        label="Receita"
        name="impressos.receita"
        maxLength={4000}
        maxLengthSpanDesc
      />
    </div>
  );
};

export default DefinicaoImpressos;
