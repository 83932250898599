import { DetailedHTMLProps, Fragment, useContext } from 'react';

import ThemeContext from 'src/core/themes/ThemeContext';

import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';
import './Page.scss';

export interface PageProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  content?: true;
  white?: boolean;
  scroll?: boolean;
  children?: JSX.Element;
}

const Page = ({
  children,
  className,
  content,
  white,
  scroll,
  ...rest
}: PageProps) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={`Page ${theme.theme} ${className || ''} ${
        content ? 'content' : ''
      } ${white ? 'white-mode' : ''}`}
      {...rest}
    >
      {scroll ? <ScrollPanel>{children || <></>}</ScrollPanel> : children}
    </div>
  );
};

export default Page;
