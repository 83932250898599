import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AssinaturaHsmAPI } from 'src/APIs/AdminAPI/AssinaturaHsm/AssinaturaHsmAPI';
import * as Yup from 'yup';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { encodeStringToBase64 } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

export const ModalCertificadoSenha = ({ isOpen, close }: DisclosureType) => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const { getPin } = useAssinatura();

  const validationSchema = Yup.object().shape({
    hsmSenha: Yup.string().required('A senha PIN é obrigatória'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      hsmSenha: '',
      hsmHorasVigencia: 0,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async (values: any) => {
    const senhaBase64 = encodeStringToBase64(values?.hsmSenha);
    values.hsmSenha = senhaBase64;
    if (values.hsmHorasVigencia === 0) {
      values.hsmHorasVigencia = null;
    }
    const response = await AssinaturaHsmAPI.putPinHSM(
      agenda?.profissionalAtivo?.id,
      values,
    );
    if (response?.status >= 200 && response?.status < 300) {
      getPin();
      close();
    }
  };

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      header="Assinar digitalmente"
      style={{ maxWidth: '520px' }}
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-mb-4">
            <SimpleText fontColor={FONT_COLOR.COLOR_60}>
              Informe a senha PIN e clique em salvar. Ela ficará disponível para
              próximas assinaturas de documentos e prontuários.{' '}
            </SimpleText>
          </div>

          <FormInput
            name="hsmSenha"
            label="Senha PIN"
            placeholder="Informe a senha PIN da assinatura de documentos"
            errorMsg={errors.hsmSenha?.message}
            type="password"
          />

          <DropdownControlled
            control={control}
            name="hsmHorasVigencia"
            label="Validade da senha"
            options={[
              {
                label: 'Nenhum',
                value: 0,
              },
              {
                label: '2 Horas',
                value: 2,
              },
              {
                label: '4 Horas',
                value: 4,
              },
              {
                label: '8 Horas',
                value: 8,
              },
              {
                label: '12 Horas',
                value: 12,
              },
            ]}
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={() => close()}
              stretch
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
