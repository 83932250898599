import { Menubar } from 'primereact/menubar';
import CustomImage from 'src/components/Basics/CustomImage/CustomImage';

import './NavBar.scss';

export const NavBar = () => {
  return (
    <Menubar
      className={'navBar-telemedicina'}
      start={
        <CustomImage
          src={'emed_logo_text.svg'}
          width={'145px'}
          alt="LOGO_EMED"
        />
      }
    />
  );
};
