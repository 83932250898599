import { memo, useCallback, useEffect, useState } from 'react';

import './ListProfessionals.scss';
import { Skeleton } from 'primereact/skeleton';

import { BloqueioPacienteAPI } from 'src/APIs/AdminAPI/BloqueioPacienteAPI/BloqueioPacienteAPI';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Can from 'src/components/Can/Can';

import UserHeader from 'src/pages/Emed/Agenda/UserHeader/UserHeader';

import ModalBloqueio from './ModalBloqueio/ModalBloqueio';
import ResponsableListItem from './ResponsableListItem/ResponsableListItem';

const ListProfessionals = () => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<Usuario[]>();
  const [listaPacientesBloqueados, setListaPacientesBloqueados] = useState<
    Usuario[]
  >([]);
  const [paciente, setPaciente] = useState<any>({});
  const [modalBloqueio, setModalBloqueio] = useState(false);

  const loadPacientesBloqueados = useCallback(async () => {
    if (!agenda.profissionalAtivo?.id) return;
    const response: any = await BloqueioPacienteAPI.loadMedicoById(
      agenda.profissionalAtivo?.id,
    );

    // Ordena os dados por nome
    setListaPacientesBloqueados(
      response?.list?.sort((a: any, b: any) => (a.nome < b.nome ? -1 : 1)),
    );
  }, [agenda]);

  useEffect(() => {
    loadPacientesBloqueados();
  }, [loadPacientesBloqueados, agenda]);

  const onChangeSearch = useCallback(async (value: string) => {
    setList([]);
    setSearch(value);

    if (value) {
      setLoading(true);
      const response = await PacienteAPI.loadPacienteByNome({ nome: value });

      // Ordena os dados por nome
      setList(
        response?.list?.sort((a: any, b: any) => (a.nome < b.nome ? -1 : 1)),
      );
      setLoading(false);
    }
  }, []);

  const handlePaciente = useCallback((pac: any) => {
    setSearch('');
    setModalBloqueio(true);
    setPaciente(pac);
  }, []);

  const handleDesbloquearPaciente = useCallback((pac: Usuario) => {
    setModalBloqueio(true);
    setPaciente(pac);
  }, []);

  const handleBloqueioModalClose = useCallback(() => {
    setModalBloqueio(false);
  }, []);

  return (
    <div className="p-grid p-flex-column p-gap-2">
      <SimpleText className="p-col-12" fontSize={FONT_SIZE.MD} bold>
        Bloqueio de pacientes
      </SimpleText>
      <SimpleText className="p-col-12">
        Adicione o paciente numa lista de bloqueio e o impeça de agendar
        consultas com determinado médico
      </SimpleText>

      <SimpleText className="p-col-12" bold>
        Selecione o médico
      </SimpleText>

      <div className="user p-col-12">
        <UserHeader />
      </div>

      <Can
        checkPermission={[
          'ADM_BLOQUEIO_PACIENTES_CADASTRAR',
          'ADM_BLOQUEIO_PACIENTES_ALTERAR',
        ]}
      >
        <>
          <SimpleText className="p-col-12" bold>
            Pesquise e selecione o paciente para adicionar na lista de bloqueio
          </SimpleText>
          <div className="p-col-12">
            <TextInputSearch
              autoFocus
              placeholder="Digite o nome, CPF ou a carteirinha"
              onChange={onChangeSearch}
              value={search}
            />
          </div>
        </>
      </Can>

      {loading ? (
        <>
          <div className="p-col-12">
            <Skeleton height="56px" borderRadius="8px" />
          </div>
        </>
      ) : (
        <>
          {search && list && list?.length > 0 && (
            <div className="p-col-12">
              {list?.map((i: any, idx: number) => {
                return (
                  <ResponsableListItem
                    key={idx}
                    paciente={i}
                    onClick={() => handlePaciente(i)}
                    isList={false}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
      <SimpleText className="p-col-12" bold>
        Lista de bloqueio
      </SimpleText>

      <div className="p-col-12 p-py-0">
        {listaPacientesBloqueados && listaPacientesBloqueados?.length > 0 ? (
          <>
            <div className="p-d-flex p-col-12">
              <SimpleText className="p-col-6">Paciente</SimpleText>
              <SimpleText className="p-col-4">Carteirinha</SimpleText>
              <SimpleText className="p-col-2">Ações</SimpleText>
            </div>
            {listaPacientesBloqueados?.map((i: any, idx: number) => {
              return (
                <ResponsableListItem
                  key={idx}
                  paciente={i?.paciente}
                  handleRemove={() => handleDesbloquearPaciente(i)}
                  isList
                />
              );
            })}
          </>
        ) : (
          <SimpleText>
            Não há pacientes bloqueados. Pesquise um paciente e adicione-o na
            lista de bloqueio.
          </SimpleText>
        )}
      </div>

      <ModalBloqueio
        paciente={paciente}
        visible={modalBloqueio}
        onHide={handleBloqueioModalClose}
        listaPacientesBloqueados={listaPacientesBloqueados}
        setListaPacientesBloqueados={setListaPacientesBloqueados}
      />
    </div>
  );
};

export default memo(ListProfessionals);
