import { useEffect, useState } from 'react';

import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import VerticalTab from 'src/components/VerticalTab/VerticalTab';

import { HistoricoAtendimentos } from '../Atendimento/HistoricoAtendimentos/HistoricoAtendimentos';

import EncerrarCiclo from './EncerrarCiclo/EncerrarCiclo';
import ExamesFisicos from './ExamesFisicos/ExamesFisicos';
import FolhaRosto from './FolhaRosto/FolhaRosto';
import PreNatalCard from './PreNatalCard';
import RegistrarHistorico from './RegistrarHistorico/RegistrarHistorico';
import Sorologia from './Sorologia/Sorologia';
import Ultrassom from './Ultrassom/Ultrassom';
import Vacinas from './Vacinas/Vacinas';

import './PreNatal.scss';

interface PreNatalProps {
  idPaciente: number | null;
  usuario: any;
}

const PreNatal = ({ idPaciente, usuario }: PreNatalProps) => {
  const { preNatalAtual, clearPreNatal, selectedGestacao, loadPreNatal } =
    usePreNatal();
  const { campos } = useAtendimento();

  const [valoresEnum, setValoresEnum] = useState<any>();
  const [updateTabIndex, setUpdateTabIndex] = useState<number | null>(null);

  const gestacao = selectedGestacao || preNatalAtual;

  useEffect(() => {
    const loadValoresEnum = async () => {
      const response = await PreNatalAPI.getValoresEnum();

      if (!('status' in response)) {
        setValoresEnum(response);
      }
    };

    loadValoresEnum();
  }, []);

  const onEncerrarCiclo = () => {
    loadPreNatal(idPaciente!);
    clearPreNatal();
    setUpdateTabIndex(0);
  };

  const renderEncerrarCiclo = (visible: boolean, onHide: () => void) => (
    <EncerrarCiclo
      valoresEnum={valoresEnum}
      visible={visible}
      onHide={onHide}
      onEncerrarCiclo={onEncerrarCiclo}
    />
  );

  const renderRegistrarHistorico = (visible: boolean, onHide: () => void) => (
    <RegistrarHistorico
      idPaciente={idPaciente}
      valoresEnum={valoresEnum}
      visible={visible}
      onHide={onHide}
    />
  );

  const tabs = [
    {
      label: 'Folha de rosto',
      content: (
        <PreNatalCard
          registrarHistorico
          renderEncerrarCiclo={renderEncerrarCiclo}
          renderRegistrarHistorico={renderRegistrarHistorico}
        >
          <FolhaRosto idPaciente={idPaciente} />
        </PreNatalCard>
      ),
      funcionalidade: 'PRE_NATAL_GESTANTE_FOLHA_DE_ROSTO',
    },
    {
      label: 'Ultrassom',
      content: (
        <PreNatalCard renderEncerrarCiclo={renderEncerrarCiclo}>
          <Ultrassom valoresEnum={valoresEnum} idPaciente={idPaciente} />
        </PreNatalCard>
      ),
      disabled: !gestacao?.id,
      funcionalidade: 'PRE_NATAL_GESTANTE_ULTRASSOM',
    },
    {
      label: 'Sorologia',
      content: (
        <PreNatalCard renderEncerrarCiclo={renderEncerrarCiclo}>
          <Sorologia />
        </PreNatalCard>
      ),
      disabled: !gestacao?.id,
      funcionalidade: 'PRE_NATAL_GESTANTE_SOROLOGIA',
    },
    {
      label: 'Vacinas',
      content: (
        <PreNatalCard renderEncerrarCiclo={renderEncerrarCiclo}>
          <Vacinas />
        </PreNatalCard>
      ),
      disabled: !gestacao?.id,
      funcionalidade: 'PRE_NATAL_GESTANTE_VACINAS',
    },
    {
      label: 'Exames físicos',
      content: (
        <PreNatalCard renderEncerrarCiclo={renderEncerrarCiclo}>
          <ExamesFisicos valoresEnum={valoresEnum} />
        </PreNatalCard>
      ),
      disabled: !gestacao?.id,
      funcionalidade: 'PRE_NATAL_GESTANTE_EXAMES_FISICOS',
    },
  ];

  const tabsFiltered = tabs.filter(tab => {
    const campoTab = campos?.find(
      campo => campo.funcionalidade === tab.funcionalidade,
    );
    return campoTab?.exibicaoCamposModelo !== 'OCULTO';
  });

  return (
    <div id="pre-natal">
      <VerticalTab updateTabIndex={updateTabIndex} tabItens={tabsFiltered} />
      <HistoricoAtendimentoProvider>
        <HistoricoAtendimentos usuario={usuario} idPaciente={idPaciente} />
      </HistoricoAtendimentoProvider>
    </div>
  );
};

export default PreNatal;
