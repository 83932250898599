import { useState } from 'react';

import dayjs from 'dayjs';

import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import CalendarInput from 'src/components/Basics/CalendarInput/CalendarInput';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import ChipSelect from 'src/components/ChipSelect/ChipSelect';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import SelectButton from 'src/components/SelectButton/SelectButton';

import {
  tagsHistoricoOptions,
  visualizacaoHistoricoOptions,
  ordenacaoHistoricoOptions,
} from '../../helpers';

export const Filters = () => {
  const { isMobile } = useSize();

  const {
    procedimentoNomeSearchHistorico,
    setProcedimentoNomeSearchHistorico,
  } = useSolicitacaoExameCirurgia();

  const { page, totalCount, listItems, setFilter } = useSearchList();

  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<any>();
  const [visualizacaoHistorico, setVisualizacaoHistorico] =
    useState('MEUS_ATENDIMENTOS');
  const [ordenacao, setOrdenacao] = useState('dataSolicitacao');
  const [tags, setTags] = useState([]);

  useEffectSkipFirst(() => {
    if (procedimentoNomeSearchHistorico) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        descricao: procedimentoNomeSearchHistorico,
      }));
    }
  }, [procedimentoNomeSearchHistorico]);

  const handleChangeVisualizacaoHistorico = (e: any) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      todosAtendimentos: e.value === 'TODOS',
    }));

    setVisualizacaoHistorico(e.value);
  };

  const filterColumn = isMobile ? 'p-col-12' : 'p-col';
  const searchButtonClassName = isMobile ? 'p-col-12 p-jc-between' : 'p-p-2';

  const handleSearch = () => {
    let [dataInicial, dataFinal] = data || [];

    dataInicial = dataInicial
      ? dayjs(dataInicial).format('YYYYMMDD')
      : undefined;
    dataFinal = dataFinal ? dayjs(dataFinal).format('YYYYMMDD') : undefined;

    setFilter((prevFilter: any) => ({
      ...prevFilter,
      descricao: searchQuery,
      dataInicial,
      dataFinal,
    }));
  };

  const handleCleanFilters = () => {
    setFilter({});
    setSearchQuery('');
    setData(null);
    setProcedimentoNomeSearchHistorico('');
  };

  const handleChangeTagsFilter = (tags: any) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      tags: tags?.join() || '',
    }));
    setTags(tags || []);
  };

  const handleDataChange = (e: any) => setData(e.value);
  const handleChangeSort = (e: any) => {
    setFilter((prevFilter: any) => ({
      ...prevFilter,
      sortBy: e.value,
      sortDirection: 'DESC',
    }));
    setOrdenacao(e.value);
  };

  return (
    <div className="p-grid ">
      <div className={filterColumn}>
        <TextInputSearch
          placeholder="Pesquisar por descrição"
          value={searchQuery}
          onChange={setSearchQuery}
          updateValue={procedimentoNomeSearchHistorico}
        />
      </div>

      <CalendarInput
        className={filterColumn}
        selectionMode="range"
        placeholder="dd/mm/aaaa até dd/mm/aaaa"
        value={data}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
        maxDate={new Date()}
        onChange={handleDataChange}
        hideOnDateTimeSelect
      />

      <div className={`${searchButtonClassName} p-d-flex p-gap-2 p-ai-center`}>
        <Button
          type="button"
          btnType="tonal"
          label="pesquisar"
          onClick={handleSearch}
        />
        <Button
          btnType="green-link"
          type="button"
          label="Limpar"
          onClick={handleCleanFilters}
        />
      </div>

      <ChipSelect
        className="p-col-12 p-d-flex p-flex-column p-flex-sm-row"
        options={tagsHistoricoOptions}
        value={tags}
        onChange={handleChangeTagsFilter}
      />

      <div className="p-col-12 p-d-flex p-flex-1 p-jc-between p-flex-column p-flex-md-row">
        <div className="p-d-flex p-ai-center p-flex-column p-flex-sm-row p-jc-between p-jc-md-start">
          <SelectButton
            className="p-ml-0"
            options={visualizacaoHistoricoOptions}
            value={visualizacaoHistorico}
            onChange={handleChangeVisualizacaoHistorico}
          />

          <div className="p-ml-0 p-ml-sm-2 p-my-3 p-my-sm-0">
            <SimpleText className="p-mr-2" fontColor={FONT_COLOR.COLOR_60}>
              Exibindo {listItems.length} registros de {totalCount}
            </SimpleText>

            <i className="fas fa-lock-open" />
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-jc-center p-jc-sm-end">
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>Ordenar por:</SimpleText>

          <Dropdown
            id="historico-ordenacao"
            value={ordenacao}
            onChange={handleChangeSort}
            filter={false}
            options={ordenacaoHistoricoOptions}
          />
        </div>
      </div>
    </div>
  );
};
