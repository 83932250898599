import { useState } from 'react';

import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface SwitchStatusProps {
  data: any;
}

const SwitchStatus = ({ data }: SwitchStatusProps) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(data?.status || false);

  useEffectSkipFirst(() => {
    if (data?.status) {
      setStatus(data.status);
    }
  }, [data?.status]);

  async function handleIsActive(item: any) {
    setLoading(true);
    try {
      const grupoEditado = {
        ...item,
        status: !status,
      };

      const grupoId = item.id.toString();

      await GrupoAtendimentoAPI.updateGrupoAtendimento(grupoId, grupoEditado);
      const response = await GrupoAtendimentoAPI.loadGrupoAtendimentoById(
        grupoId,
      );
      setStatus(response.status);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <SpinnerLoading size="sm" />;
  }

  return (
    <Switch
      checkPermission="AGE_GRUPO_DE_ATENDIMENTO_ALTERAR"
      checked={status}
      onChange={() => handleIsActive(data)}
    />
  );
};

export default SwitchStatus;
