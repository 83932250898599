import { useEffect, useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import PreNatalAPI from 'src/APIs/ProntuarioAPI/PreNatalAPI/PreNatalAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { formatEnum } from '../helpers';

import PanelContentHistorico from './Historico/PanelContentHistorico';
import PanelHeaderHistorico from './Historico/PanelHeaderHistorico';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';
import validationSchema from './validationSchema';

const mfOptions = [
  { label: 'Positivo', value: 'POSITIVO' },
  { label: 'Negativo', value: 'NEGATIVO' },
];

interface ExamesFisicosProps {
  valoresEnum: any;
}

const ExamesFisicos = ({ valoresEnum }: ExamesFisicosProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { atendimentoStatus } = useAtendimento();
  const { preNatalAtual, selectedGestacao } = usePreNatal();

  const gestacao = selectedGestacao || preNatalAtual;

  const isAtendimentoRunning =
    atendimentoStatus === 'ATENDENDO' ||
    atendimentoStatus === 'ATENDENDO_TRIAGEM';

  const hideHistorico =
    gestacao?.id === preNatalAtual?.id ? 'hide-historico' : '';

  const hideGestacaoAtual =
    gestacao?.id !== preNatalAtual?.id ? 'hide-gestacao-atual' : '';

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [examesFisicoHistorico, setExamesFisicoHistorico] = useState<any[]>([]);
  const [idExameFisicoEditar, setIdExameFisicoEditar] = useState<number | null>(
    null,
  );
  const [idExameFisicoExcluir, setIdExameFisicoExcluir] = useState<
    number | null
  >(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reset({
      bcfUnidadeMedida: 'Batimentos por minuto',
    });
  }, [reset]);

  const loadHistoricoExamesFisicos = useCallback(async () => {
    if (!preNatalAtual?.id) return;

    setLoading(true);

    const response = await PreNatalAPI.getHistoricoExamesFisico(
      preNatalAtual?.id,
    );

    if (Array.isArray(response)) setExamesFisicoHistorico(response);

    setLoading(false);
  }, [preNatalAtual?.id]);

  useEffect(() => {
    loadHistoricoExamesFisicos();
  }, [loadHistoricoExamesFisicos]);

  const onSubmit = async (data: any) => {
    if (!preNatalAtual?.id) return;

    const payload = {
      ...data,
      au: String(data.au).replace(',', '.'),
      bcfUnidadeMedida: 'BPM',
      idAtendimento,
    };

    const saveAction = idExameFisicoEditar
      ? PreNatalAPI.updateExameFisico
      : PreNatalAPI.createExameFisico;
    const idSaveAction = idExameFisicoEditar
      ? idExameFisicoEditar
      : preNatalAtual?.id;

    const response = await saveAction(idSaveAction, payload);

    if (response?.status === 201 || response?.status === 200) {
      loadHistoricoExamesFisicos();
      reset({
        bcfUnidadeMedida: 'Batimentos por minuto',
      });
      setIdExameFisicoEditar(null);
    }
  };

  const onEdit = (exameFisico: any) => {
    setIdExameFisicoEditar(exameFisico.id);

    reset({
      mf: exameFisico.mf,
      dataOcorrencia: new Date(exameFisico.dataOcorrencia),
      au: exameFisico.au,
      auUnidadeMedida: exameFisico.auUnidadeMedida.codigo,
      bcf: exameFisico.bcf,
      bcfUnidadeMedida: 'Batimentos por minuto',
      edema: exameFisico.edema,
    });
  };

  const onExcluir = async () => {
    if (!idExameFisicoExcluir) return;

    const response = await PreNatalAPI.deleteExameFisico(idExameFisicoExcluir);

    if (response.status === 204) {
      setIdExameFisicoEditar(null);
      loadHistoricoExamesFisicos();
      reset({
        bcfUnidadeMedida: 'Batimentos por minuto',
      });
    }

    setIdExameFisicoExcluir(null);
  };

  return (
    <>
      {gestacao?.examesFisicos && (
        <div id="historico-vacinas-pre-natal" className={hideHistorico}>
          <SimpleText fontSize={FONT_SIZE.MD} className="p-ml-1" medium>
            Histórico do exame físico
          </SimpleText>
          <div className="p-mt-3 p-mx-2">
            <div className="p-my-1">
              <Panel
                header={(isCollapsed, onToggle) => (
                  <PanelHeaderHistorico
                    isCollapsed={isCollapsed}
                    onToggle={onToggle}
                    gestacao={gestacao}
                  />
                )}
              >
                <PanelContentHistorico gestacao={gestacao} />
              </Panel>
            </div>
          </div>
        </div>
      )}

      <div id="exames-fisicos-pre-natal" className={hideGestacaoAtual}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset
            className="p-grid p-col-12 p-px-0"
            disabled={!isAtendimentoRunning}
          >
            <DropdownControlled
              className="p-col-12 p-md-6"
              control={control}
              name="mf"
              label="MF"
              options={mfOptions}
              placeholder="Selecione"
              filter={false}
              disabled={!isAtendimentoRunning}
            />

            <CalendarInputControlled
              className="p-col-12 p-md-6"
              control={control}
              name="dataOcorrencia"
              label="Data da ocorrência"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              minDate={new Date(preNatalAtual?.dataInclusao)}
              maxDate={new Date()}
            />

            <div className="p-grid p-col-12 p-p-0">
              <SimpleText
                className="p-col-12 p-pb-0"
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                AU
              </SimpleText>

              <TextInputControlled
                className="p-col-12 p-md-6"
                control={control}
                name="au"
                floatOnly
                maxLength={5}
              />

              <DropdownControlled
                className="p-col-12 p-md-6"
                control={control}
                name="auUnidadeMedida"
                options={formatEnum(valoresEnum?.unidadeMedidaExameFisico)}
                filter={false}
                disabled={!isAtendimentoRunning}
              />
            </div>

            <div className="p-grid p-col-12 p-p-0">
              <SimpleText
                className="p-col-12 p-pb-0"
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                BCF
              </SimpleText>

              <TextInputControlled
                className="p-col-12 p-md-6 p-pt-0"
                control={control}
                name="bcf"
                permitirApenasNumeros
                maxLength={3}
              />

              <TextInputControlled
                className="p-col-12 p-md-6 p-pt-0"
                control={control}
                name="bcfUnidadeMedida"
                disabled
              />
            </div>

            <TextInputControlled
              className="p-col-12"
              control={control}
              name="edema"
              label="Edema"
              maxLength={50}
            />

            <div className="p-col-12 p-mt-3">
              <Button
                type="submit"
                className="p-col-12"
                btnType="tonal"
                label={idExameFisicoEditar ? 'Salvar' : 'Adicionar'}
                icon={!idExameFisicoEditar ? 'fas fa-plus' : ''}
                loading={isSubmitting}
                disabled={!isAtendimentoRunning}
              />
            </div>
          </fieldset>
        </form>

        <SimpleText
          className="p-col-12"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Histórico do exame físico
        </SimpleText>

        <div id="historico-pre-natal-container" className="p-col-12">
          {loading && <GridListLoading />}

          {!examesFisicoHistorico.length && !loading && (
            <SimpleText fontColor={FONT_COLOR.COLOR_60}>
              Não há histórico do exame físico
            </SimpleText>
          )}

          {examesFisicoHistorico.map((exameFisico: any) => (
            <Panel
              key={exameFisico.id}
              header={(isCollapsed, onToggle) => (
                <PanelHeader
                  exameFisico={exameFisico}
                  isCollapsed={isCollapsed}
                  onToggle={onToggle}
                  onEdit={() => onEdit(exameFisico)}
                  onDelete={() => setIdExameFisicoExcluir(exameFisico.id)}
                  disabledOptionsMenu={
                    !(
                      Number(idAtendimento) === exameFisico.idAtendimento &&
                      atendimentoStatus === 'ATENDENDO'
                    )
                  }
                />
              )}
            >
              <PanelContent exameFisico={exameFisico} />
            </Panel>
          ))}
        </div>

        <ConfirmDialog
          visible={!!idExameFisicoExcluir}
          header="Excluir Exame Físico"
          text="O exame físico será excluído. Você realmente deseja excluir?"
          confirmText="Sim, excluir"
          onHide={() => setIdExameFisicoExcluir(null)}
          onConfirm={onExcluir}
        />
      </div>
    </>
  );
};

export default ExamesFisicos;
