import PageComp, { PageProps } from 'src/components/Basics/Page/Page';

type Props = PageProps & {
  children: any;
};

export const Page = ({ children, content, ...props }: Props) => {
  return (
    <PageComp
      style={{
        paddingInline: content ? '5%' : '0 !important',
      }}
      {...props}
    >
      {children}
    </PageComp>
  );
};
