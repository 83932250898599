import { ReactElement } from 'react';

import { useDispatch } from 'react-redux';
import { Route, useNavigate } from 'react-router-dom';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from 'src/core/redux/hooks';
import { clearUser } from 'src/core/redux/slices/system/UserSlice';
import { RootState } from 'src/core/redux/store';

import { RouteItem } from './RouterMap';

export enum ROUTES {
  LOGIN = '/login',
  LOGIN_FACIAL = '/biometric-auth',
  LOGIN_FORGOT_PASSWORD = '/forgot-password',
  LOGIN_RECOVER_PASSWORD = '/recover-password',
  HOME = '/',
}

export const AuthEnforcer = () => {
  const { user } = useAppSelector((state: RootState) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (user?.tipoUsuario === 'PACIENTE') {
    dispatch(clearUser());
    navigate(ROUTES.LOGIN);
  }

  if (!user?.idUsuario) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} />;
  }
  return <Outlet />;
};

export const generateRoutes = (
  routes: RouteItem[],
  authorities: any,
): ReactElement[] => {
  const rotasFiltradasByPermission = routes.filter(
    ({ checkPermission }: RouteItem) => {
      if (Array.isArray(checkPermission)) {
        return checkPermission.some(permission =>
          authorities?.includes(permission),
        );
      }

      if (checkPermission && !authorities?.includes(checkPermission)) {
        return false;
      }

      return true;
    },
  );

  return rotasFiltradasByPermission.map(
    ({ route, element, items }: RouteItem) => {
      if (items) {
        return (
          <Route path={route} element={element} key={route}>
            {generateRoutes(items, authorities)}
          </Route>
        );
      }

      return <Route path={route} element={element} key={route} />;
    },
  );
};
