import { Page } from '../_components/Page/Page';
import { PageHeader } from '../_components/Page/PageHeader';

import List from './List/List';

const PerfilSaude = () => {
  return (
    <Page>
      <>
        <PageHeader
          title="Perfil Saúde"
          subtitle="Aqui estão resunidos os seus últimos questionarios de avaliação de saúde."
          backButton
        ></PageHeader>

        <Page content white className="p-py-4">
          <List />
        </Page>
      </>
    </Page>
  );
};

export default PerfilSaude;
