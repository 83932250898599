import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import { loadProfissionaisSaudeBySearch } from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import { loadMedicosById } from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type Props = DropdownProps & {
  name?: string;
  label: string;
};

const defaultOption = [
  {
    label: null,
    value: null,
    disabled: true,
  },
];

export const FieldSearchProfissionalSaude = ({
  name = 'profissionalSaude',
  label,
  ...rest
}: Props) => {
  const { control } = useFormContext();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const [medicos, setMedicos] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  const [querySearch, setQuerySearch] = useState('');

  const consultorio = consultorios?.ativo?.id;

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const loadPage = useCallback(async () => {
    if (querySearch.length < 3) return setMedicos([]);
    setLoading(true);

    if (consultorio) {
      const responseMedicos = await loadMedicosById(consultorio);

      if (!!responseMedicos?.length) setMedicos(responseMedicos);
    } else {
      const responseMedicos = await loadProfissionaisSaudeBySearch({
        nome: querySearch,
      });

      if (!!responseMedicos?.list?.length) setMedicos(responseMedicos.list);
    }

    setLoading(false);
  }, [consultorio, querySearch]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const optionsMedico = useMemo(() => {
    const data = medicos.map(medico => ({
      label: medico?.nome,
      value: medico,
    }));

    return data;
  }, [medicos]);

  const options = optionsMedico.length ? optionsMedico : defaultOption;
  const emptyFilterMessage =
    querySearch.length >= 3
      ? 'Nenhuma opção encontrada'
      : 'Digite para pesquisar';

  return (
    <DropdownControlled
      className="p-col-12"
      control={control}
      name={name}
      dataKey="id"
      label={label}
      options={options}
      loading={isLoading}
      errorMsg={error?.message}
      placeholder="Pesquisar..."
      carregandoFiltro={isLoading}
      filterPlaceholder="Digite para pesquisar"
      onFilter={e => [setQuerySearch(e.filter)]}
      emptyFilterMessage={emptyFilterMessage}
      showClear
      {...rest}
    />
  );
};
