import { useCallback } from 'react';

import ServicoTussValorAPI from 'src/APIs/FinanceiroAPI/ServicoTussValor/ServicoTussValorAPI';

import useSize from 'src/core/hooks/useSize';

import { DesktopRow } from './DesktopRow';
import { MobileRow } from './MobileRow';

interface RowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onHistorico: (data: any) => void;
  reload: () => void;
}

export const Row = (props: RowProps) => {
  const { data, onEdit, onDelete, onHistorico, reload } = props;
  const { isMobile } = useSize();

  const handleIsActive = useCallback(async () => {
    const item = {
      ...data,
      status: !data.status,
    };

    await ServicoTussValorAPI.updateStatusServicoTussValor(data.id, item);

    reload();
  }, [data, reload]);

  const cumonProps = {
    data,
    onEdit,
    onDelete,
    onHistorico,
    onSwitchStatus: handleIsActive,
  };

  return isMobile ? (
    <MobileRow {...cumonProps} />
  ) : (
    <DesktopRow {...cumonProps} />
  );
};
