import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import ServicoTussAPI from 'src/APIs/FinanceiroAPI/ServicoTuss/ServicoTussAPI';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import SearchServicoTuss from './SearchServicoTuss/SearchServicoTuss';
import ServicoTussSelected from './ServicoTussSelected';

import './FormServicoTuss.scss';

interface FormServicoTussProps {
  onSubmit(v: any[]): void;
  values?: any[] | undefined;
}

const FormServicoTuss = ({ onSubmit, values }: FormServicoTussProps) => {
  const [modal, setModal] = useState(false);

  const [value, setValue] = useState<any[]>([]);

  const load = useCallback(async () => {
    if (values && values?.length > 0) {
      const tuss = await ServicoTussAPI.loadServicoTuss({ nome: '' });

      const servicosTussList: any[] = [];

      values.map((v: any) => {
        const findServicoTuss = tuss?.list?.filter(
          (p: any) => p.id === (v.idServicoTuss ? v.idServicoTuss : v.id),
        );

        const servicoEncontrado = {
          ...findServicoTuss[0],
          ...v,
          id: v.idServicoTuss ? v.idServicoTuss : findServicoTuss[0]?.id,
        };

        return servicosTussList.push(servicoEncontrado);
      });

      setValue(servicosTussList);
    }
  }, [values]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeItems = useCallback(
    items => {
      setValue(items);
      setModal(false);

      onSubmit(items);
    },
    [onSubmit],
  );

  // Ordena os valores por
  const valueOrdered = useMemo(
    () => value.sort((a, b) => (a.nome < b.nome ? -1 : 1)),
    [value],
  );

  return (
    <div className={'FormServicoTuss-Especialidade '}>
      <SimpleText fontSize={FONT_SIZE.XS} bold>
        Serviço TUSS
      </SimpleText>

      <div className="form-container-tuss">
        <div>
          <ServicoTussSelected
            value={valueOrdered}
            onChange={handleChangeItems}
          />

          <Button
            label={value?.length > 0 ? 'Adicionar' : 'Adicionar serviço TUSS'}
            btnType="tonal"
            icon="fas fa-plus"
            onClick={() => setModal(true)}
            type="button"
            stretch
          />
        </div>
      </div>

      <SearchServicoTuss
        visible={modal}
        onHide={() => setModal(false)}
        onSelect={handleChangeItems}
        initialValue={valueOrdered}
      />
    </div>
  );
};

export default memo(FormServicoTuss);
