import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

import { formatData } from '../helpers';


const RacaCor = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [racaCorOptions, setRacaoCorOptions] = useState<any>([]);

  const fetchRacaCorData = async () => {
    const response = await UtilsAPI.GetRacaCor();

    setRacaoCorOptions(formatData(response, 'descricao', 'id'));
  };

  useEffect(() => {
    fetchRacaCorData();
  }, []);

  return (
    <DropdownControlled
      className="p-col-12 p-sm-6"
      control={control}
      name="idRacaCor"
      label="Raça/cor"
      options={racaCorOptions}
      errorMsg={errors.idRacaCor?.message}
    />
  );
}

export default RacaCor;