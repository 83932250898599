import { useState, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import './ModalAtender.scss';

interface ModalAtenderProps {
  pedidoCuidado: PedidoCuidado | null;
  isEdit: boolean;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

function ModalAtender({
  pedidoCuidado,
  isEdit,
  visible,
  onHide,
  onSuccess,
}: ModalAtenderProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const validationSchema = Yup.object().shape({
    dataOcorrenciaExecucao: Yup.date().required('Informe a data da ocorrencia'),
    ...(pedidoCuidado?.tipoCuidado?.obrigaPreenchimentoDescricao && {
      observacaoExecutante: Yup.string().required('Descrição obrigatória'),
    }),
    ...(pedidoCuidado?.programado && {
      idTipoCuidadoResposta: Yup.number().required('Selecione uma resposta'),
    }),

    justificativaOcorrenciaRetroativaExecucao:
      justificativaOcorrenciaRetroativaValidation(pedidoCuidado?.dataExecucao),
  });
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const {
      justificativaOcorrenciaRetroativaExecucao = '',
      observacaoExecutante = '',
    } = pedidoCuidado || {};

    reset();

    if (isEdit) {
      setValue('observacaoExecutante', observacaoExecutante);
      if (justificativaOcorrenciaRetroativaExecucao) {
        setValue(
          'observacaoExecutante',
          justificativaOcorrenciaRetroativaExecucao,
        );
      }
      setValue('idTipoCuidadoResposta', pedidoCuidado?.tipoCuidadoResposta?.id);
    }
  }, [isEdit, pedidoCuidado, reset, setValue]);

  const saveAction = useCallback(() => {
    if (isEdit) return PedidosCuidadoAPI.alterarAtendimentoPedidoCuidado;

    return PedidosCuidadoAPI.atenderPedidoCuidado;
  }, [isEdit]);

  const onSubmit = useCallback(
    async data => {
      const payload = {
        ...data,
        idAtendimento,
        dataOcorrenciaExecucao: dayjs(data.dataOcorrenciaExecucao).format(
          'YYYY-MM-DDTHH:mmZ',
        ),
      };

      const response = await saveAction()(
        pedidoCuidado?.id as number,
        pedidoCuidado?.idAtendimentoSolicita as number,
        payload,
      );

      if (response?.status === 200 || response?.status === 201) {
        onSuccess();
        onHide();
      }
    },
    [
      idAtendimento,
      saveAction,
      pedidoCuidado?.id,
      pedidoCuidado?.idAtendimentoSolicita,
      onSuccess,
      onHide,
    ],
  );

  const respostas = pedidoCuidado?.tipoCuidado?.respostas?.filter(
    (resposta: any) => resposta.status,
  );
  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header={'Execução do Cuidado'}
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
    >
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <DataOcorrencia
          control={control}
          nameDataOcorrencia="dataOcorrenciaExecucao"
          nameJustificativaRetroativa="justificativaOcorrenciaRetroativaExecucao"
          defaultValue={
            pedidoCuidado?.dataOcorrenciaExecucao
              ? new Date(pedidoCuidado.dataOcorrenciaExecucao)
              : new Date()
          }
          dataInclusao={pedidoCuidado?.dataExecucao}
        />

        <DropdownControlled
          className="p-col-12"
          control={control}
          name="idTipoCuidadoResposta"
          label="Resposta"
          options={respostas || []}
          optionLabel="descricao"
          optionValue="id"
          errorMsg={errors.idTipoCuidadoResposta?.message}
        />

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="observacaoExecutante"
          placeholder="Descrição do Cuidado"
          label="Descrição"
          errorMsg={errors.observacaoExecutante?.message}
          maxLength={255}
          maxLengthSpan
        />
      </form>
    </Dialog>
  );
}

export default ModalAtender;
