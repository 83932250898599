import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/guia-tiss';

export const loadConfiguracaoGuiaTissById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ConfiguracaoGuiaTiss> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendConfiguracaoGuiaTiss = (
  payload: PostConfiguracaoGuiaTissDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostConfiguracaoGuiaTissDTO> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateConfiguracaoGuiaTiss = (
  id: number,
  payload: PostConfiguracaoGuiaTissDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostConfiguracaoGuiaTissDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateStatusConfiguracaoGuiaTiss = (
  id: number,
  payload: Partial<PostConfiguracaoGuiaTissDTO>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Partial<PostConfiguracaoGuiaTissDTO>> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/status/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeConfiguracaoGuiaTiss = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    return: true,
    ...options,
  });
};

export const loadConfiguracaoGuiaTiss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetConfiguracaoGuiaTissDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadServicosTussComConfiguracaoGuiaTiss = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: 'guia-tiss-servico-tuss/grupotuss-incluindo-ja-utilizados',
    params: params,
    return: true,
    hideToast: true,
    ...options,
  });
};

const ConfiguracaoGuiaTissAPI = {
  loadConfiguracaoGuiaTiss,
  loadConfiguracaoGuiaTissById,
  sendConfiguracaoGuiaTiss,
  updateConfiguracaoGuiaTiss,
  removeConfiguracaoGuiaTiss,
  updateStatusConfiguracaoGuiaTiss,
  loadServicosTussComConfiguracaoGuiaTiss,
};

export default ConfiguracaoGuiaTissAPI;
