import { verificarParametos } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

import SwitchStatus from './componets/SwitchStatus';

interface MobileRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  calculoPacientes: (data: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { data, onEdit, onDelete, calculoPacientes } = props;

  const options = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'AGE_GRUPO_DE_ATENDIMENTO_ALTERA',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: onDelete,
      checkPermission: 'AGE_GRUPO_DE_ATENDIMENTO_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Apelido do grupo
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {data?.apelido}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          M/A inicial dos atend.
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.periodo}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Total de pacientes
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {calculoPacientes(data?.grupoAtendimentoPacientes)}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Tipo de grupo
        </SimpleText>
        <br />
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data?.nomeTipoGrupo}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Qtd. de agendamentos
        </SimpleText>
        <br />
        <SimpleText
          className={`p-col-2 personalizacao ${
            data?.quantidadeAgendamentos > 0 ? 'personal' : ''
          }`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {verificarParametos(data?.quantidadeAgendamentos)}
        </SimpleText>
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Situação
        </SimpleText>
        <br />

        <SwitchStatus data={data} />
      </div>
    </SearchListMobileRow>
  );
};
