import { useCallback } from 'react';

import { useLocation, useParams } from 'react-router';

import ObservacaoGeralAPI from 'src/APIs/ProntuarioAPI/PreAtendimentoAPI/ObservacaoGeralAPI/ObservacaoGeralAPI';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import PanelContent from '../PanelContent/PanelContent';
import PanelHeader from '../PanelHeader/PanelHeader';

interface GlicemiaCapilarProps {
  panelHeaderOptionsMenu: (preAtendimento: PreAtendimento) => JSX.Element;
  updateList: number;
  selectedTipo: string;
  selectedAtendimento?: any;
}

const GlicemiaCapilar = ({
  panelHeaderOptionsMenu,
  updateList,
  selectedTipo,
  selectedAtendimento,
}: GlicemiaCapilarProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const fetchAPI = useCallback(
    async (params: any): Promise<any> => {
      return ObservacaoGeralAPI.getObservacoesGerais(Number(idAtendimento), {
        idAtendimento: selectedAtendimento?.idAtendimento || idAtendimento,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
        somenteAtendimento: selectedAtendimento?.idAtendimento ? true : false,
        ...params,
      });
    },
    [idAtendimento, selectedAtendimento?.idAtendimento],
  );

  const leftContent = (
    preAtendimento: PreAtendimento,
    headerTextClassName: string,
    dataTextClassName: string,
    isCollapsed: boolean,
  ) => {
    return (
      <div className="p-col-6 p-md-4 p-d-flex observacao-texto">
        <div className="observacao-column column p-jc-center">
          <SimpleText
            className={headerTextClassName}
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Observação
          </SimpleText>
          <SimpleText
            className={`${dataTextClassName} ${
              isCollapsed ? 'word-break' : 'p-text-nowrap p-text-truncate'
            }`}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {preAtendimento.observacao}
          </SimpleText>
        </div>
        <Separator layout="vertical" />
      </div>
    );
  };

  const renderRow = (preAtendimento: PreAtendimento) => {
    return (
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            preAtendimento={preAtendimento}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            leftContent={leftContent}
            selectedTipo={selectedTipo}
            optionsMenu={panelHeaderOptionsMenu(preAtendimento)}
            hideOptionsMenu={selectedAtendimento ? true : false}
          />
        )}
      >
        <PanelContent preAtendimento={preAtendimento} />
      </Panel>
    );
  };

  return (
    <InfiniteScroll
      fetchAPI={fetchAPI}
      renderRow={renderRow}
      updateList={updateList}
    />
  );
};

export default GlicemiaCapilar;
