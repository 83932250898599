import { useRef } from 'react';

import { Menu } from 'primereact/menu';

import { Button } from 'src/components/_UI/Button';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

const contatos: any = {
  CELULAR_PARTICULAR: 'Celular',
  RECADO: 'Telefone para recados',
  FIXO_RESIDENCIAL: 'Telefone residencial',
  CELULAR_COMERCIAL: 'Telefone comercial',
};

export function PacienteAccordion(props: any) {
  const { item, optionsItems, openSelectedHorarioDisponivelModal } = props;
  const optionsRef = useRef<any>();

  function formatPhoneNumber(number?: string) {
    if (!number) return 'Não informado';
    const cleanedNumber = number.replace(/\D/g, '');
    if (cleanedNumber.length === 11) {
      return cleanedNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }
    if (cleanedNumber.length === 10) {
      return cleanedNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return cleanedNumber;
  }

  return (
    <>
      <div className="accordion-content">
        <div className="accordion-info">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXXS}
            medium
          >
            Informações de contato
          </SimpleText>
          {item?.paciente?.contatos?.map((contato: any) => (
            <div key={contato.id} className="information">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                {contatos?.[contato.tipo]}
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {formatPhoneNumber(contato.numero)}
              </SimpleText>
            </div>
          ))}
          {item?.paciente?.email && (
            <div className="information">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                E-mail
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {item.paciente.email}
              </SimpleText>
            </div>
          )}
          <Separator className="p-my-2" />
          <div className="information">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Pessoa que fez o contato
            </SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {item.contato}
            </SimpleText>
          </div>
          <div className="information">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Observações
            </SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {item.observacao}
            </SimpleText>
          </div>

          <div className="information">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Especialidade
            </SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {item.especialidade.nome}
            </SimpleText>
          </div>
          <div className="double-information">
            <div className="information">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Preferência de data
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {item?.dataPrefInicial
                  ?.split(' ')[0]
                  ?.substring(0, 5)
                  ?.replace('-', '/')}{' '}
                à{' '}
                {item?.dataPrefFinal
                  ?.split(' ')[0]
                  ?.substring(0, 5)
                  ?.replace('-', '/')}
              </SimpleText>
            </div>
            <div className="information">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Turno
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {!!item?.turnos?.length
                  ? item.turnos.join(', ')
                  : 'Não informado'}
              </SimpleText>
            </div>
          </div>
          <div className="information">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXXS}
            >
              Tipo de espera
            </SimpleText>
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XXS}
            >
              {item.tipoEspera.descricao}
            </SimpleText>
          </div>
        </div>

        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXXS}>
          Adicionado por {item.medico.nome} em{' '}
          {item.dataContato.replaceAll('-', '/')}
        </SimpleText>
        <div className="p-flex p-justify-between accordion-actions">
          <Button
            onClick={() =>
              openSelectedHorarioDisponivelModal({
                id: item.id,
                paciente: item.paciente,
                idListaEspera: item.id,
              })
            }
            label="Agendar"
          />

          <Menu model={optionsItems(item)} popup ref={optionsRef} />

          <IconButton
            icon="fas fa-ellipsis-h"
            btnType={IconBtnTypes.GREY}
            onClick={event => optionsRef?.current?.toggle?.(event)}
          />
        </div>
      </div>
    </>
  );
}
