import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import Badge from 'src/components/Badge/Badge';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import GridListLoading from '../GridList/GridListLoading';
import Separator from '../Separator/Separator';
import Skeleton from '../Skeleton/Skeleton';

import './Timeline.scss';

export interface TimelineValue {
  component: JSX.Element;
  title?: string;
  description?: string;
  disabled?: boolean;
}
export interface TimelineImperativeAPI {
  next?(): void;
  previous?(): void;
  goToStep?(step: number): void;
}

interface TimelineProps {
  values?: TimelineValue[];
  loading?: boolean;
  inline?: boolean;
  onChange?: (tabIndex: number) => void;
}

const Timeline: ForwardRefRenderFunction<
  TimelineImperativeAPI,
  TimelineProps
> = (
  { values = [], loading = false, inline = false, onChange }: TimelineProps,
  ref,
) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [rendered, setRendered] = useState([0]);

  useImperativeHandle(ref, () => ({
    next: () => {
      setActiveIndex(prev => {
        return values.length > prev ? prev + 1 : prev;
      });
    },
    previous: () => {
      setActiveIndex(prev => {
        return prev > 0 ? prev - 1 : prev;
      });
    },
    goToStep: step => {
      setActiveIndex(step);
    },
  }));

  useEffectSkipFirst(() => {
    onChange && onChange(activeIndex);
    if (!rendered.includes(activeIndex))
      setRendered(old => [...old, activeIndex]);
  }, [activeIndex]);

  const renderOption = (v: TimelineValue, index: number) => {
    return (
      <Skeleton
        loading={loading}
        height="40px"
        borderRadius="8px"
        className="p-mb-4"
      >
        <div
          key={index}
          className={`option ${index === activeIndex ? 'active' : ''} ${
            v.disabled ? 'disabled' : ''
          }`}
          onClick={() => {
            if (!v.disabled) {
              setActiveIndex(index);
            }
          }}
        >
          {values.length - 1 !== index ? <div className={'dash'} /> : <></>}
          <Badge className={'badge'} value={index + 1} />
          <div className={'option-desc'}>
            <SimpleText className={'title'}>{v.title || ''}</SimpleText>
            <SimpleText>{v.description || ''}</SimpleText>
          </div>
        </div>
      </Skeleton>
    );
  };

  const renderComponent = (activeIndex: number, values: TimelineValue[]) => {
    if (loading) {
      return <GridListLoading />;
    }

    return values.map((item, idx) =>
      rendered.includes(idx) ? (
        <div
          key={idx}
          className="p-w-100"
          style={{ display: activeIndex === idx ? 'block' : 'none' }}
        >
          {item.component}
        </div>
      ) : (
        <></>
      ),
    );
  };

  return (
    <div className={`Timeline ${!!inline ? 'inline' : ''}`}>
      <div className={'timeline'}>
        {(values || []).map((v: TimelineValue, index: number) =>
          renderOption(v, index),
        )}
      </div>
      {!inline && <Separator layout="vertical" />}
      <div className={'component'}>{renderComponent(activeIndex, values)}</div>
    </div>
  );
};

export default forwardRef(Timeline);
