import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { useFormulario } from './useFormulariosContext';

import './Formularios.scss';

export const FormulariosContentHeader = ({
  formulario,
  isInativo = false,
}: {
  formulario: IFormularioDinamicoItem;
  isInativo?: boolean;
}) => {
  const { prontuario } = useFormulario();
  const { isOpen, toggle } = useDisclosure({ opened: false });

  if (formulario === null) {
    return <></>;
  }

  return (
    <div
      className="form-header p-d-flex p-flex-column p-p-3 p-gap-2"
      data-inactive={isInativo}
    >
      <SimpleText medium fontSize="md">
        {formulario.nome}
      </SimpleText>
      <div className="p-p-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText medium>Nome:</SimpleText>
        <SimpleText>
          {prontuario?.atendimento?.paciente?.nome || '-'}
        </SimpleText>
      </div>

      <div className="row content-row p-jc-between p-ai-center p-flex-wrap p-p-2">
        <SimpleText className="p-col-8 p-wordbreak-normal" medium>
          Descrição do formulário
        </SimpleText>
        <Button
          className="p-col-4"
          icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="gray"
          onClick={() => toggle()}
        />

        {isOpen && (
          <div className="p-col-12 row content-row white p-mt-1">
            <SimpleText>{formulario.descricao}</SimpleText>
          </div>
        )}
      </div>

      {isInativo && (
        <AlertBox
          text="Este formulário não está mais disponível para preenchimento porque está inativo."
          visible
          className="p-w-100"
        />
      )}
    </div>
  );
};
