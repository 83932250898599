import { useState, useCallback } from 'react';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import ModalPasswordReset from './ModalAddValoresServico/ModalPasswordReset';

const PasswordResetSubMenu = () => {
  const modalDisclosure = useDisclosure({ opened: false });

  return (
    <>
      <div
        className={'ItemsConfigSubMenu'}
        onClick={() => modalDisclosure.open()}
      >
        <Button icon={'pi pi-key'} btnType="gray" />
        <div className={'content'}>
          <SimpleText bold>Redefinir senha</SimpleText>
        </div>
      </div>
      {modalDisclosure.isOpen && <ModalPasswordReset {...modalDisclosure} />}
    </>
  );
};

export default PasswordResetSubMenu;
