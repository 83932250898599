import dayjs from 'dayjs';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

export type AtendimentoGrupoDialogProblemasAlergiasType = {
  type: 'PROBLEMAS' | 'ALERGIAS';
  items: any[];
};

type Props = DisclosureType<AtendimentoGrupoDialogProblemasAlergiasType>;

export const AtendimentoGrupoDialogProblemasAlergias = ({
  close,
  state,
}: Props) => {
  const count = state?.items?.length;
  const dialogType = state?.type;

  const title =
    dialogType === 'PROBLEMAS'
      ? `Lista de problemas ativos (${count})`
      : `Lista de alergias e intolerâncias ativas (${count})`;
  const header =
    dialogType === 'PROBLEMAS'
      ? 'Código - Descrição da CID'
      : 'Tipo - Descrição';

  return (
    <Dialog
      visible
      className="DialogGrupoListaProblemasAlergias"
      onHide={close}
      header={title}
      modal={false}
    >
      <>
        <div className="border-container p-d-flex p-flex-column p-gap-1 p-mb-2">
          <SimpleText fontColor="color_60" className="p-col-12">
            {header}
          </SimpleText>

          <div className="p-d-flex p-flex-column p-gap-1 p-my-1">
            {state?.items?.map((item, idx) => {
              return <ItemDetalhes key={idx} item={item} type={dialogType!} />;
            })}
          </div>
        </div>

        <Button
          btnType="outline"
          label="Fechar"
          stretch
          onClick={() => close()}
        />
      </>
    </Dialog>
  );
};

const ItemDetalhes = ({
  item,
  type,
}: {
  item: any;
  type: 'ALERGIAS' | 'PROBLEMAS';
}) => {
  if (!item) return null;

  return (
    <div
      key={item.id}
      className="content-item p-d-flex p-flex-row p-ai-center p-gap-1 p-w-100"
    >
      <SimpleText lines={2} className="p-col-7 p-p-0 p-word-break-normal">
        {type === 'ALERGIAS'
          ? item.principioAtivo || item.descricao
          : `${item?.cid?.codigo} - ${item?.cid.nome}`}
      </SimpleText>
      <SimpleText className="p-col p-text-right" fontColor="color_40">
        {type === 'ALERGIAS'
          ? `Adicionado em ${dayjs(item.dataInclusao).format('DD/MM/YYYY')}`
          : ''}
      </SimpleText>
    </div>
  );
};