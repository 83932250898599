import { useMemo } from 'react';

import { PanelHeaderTemplateOptions } from 'primereact/panel';

import dayjs from 'dayjs';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Panel from 'src/components/Panel/Panel';

import { useBuscaRapidaFetchGrupoDetails } from '../hooks/useBuscaRapidaFetchGrupoDetails';

import { BuscaRapidaProcedimentoPanelHeader } from './BuscaRapidaProcedimentoPanelHeader';

interface BuscaRapidaProcedimentoPanelProps {
  isSelected: boolean;
  procedimento: any;
  onEdit: (idProcedimento: number) => void;
  onRemoveItemFromList: (idProcedimento: number) => void;
  onSelect: React.Dispatch<React.SetStateAction<any[]>>;
}

export const BuscaRapidaProcedimentoPanel = (
  props: BuscaRapidaProcedimentoPanelProps,
) => {
  const { isSelected, procedimento, onEdit, onRemoveItemFromList, onSelect } =
    props;

  const { isFetching, grupoDetails, handlePanelExpand } =
    useBuscaRapidaFetchGrupoDetails({ idProcedimento: procedimento.id });

  const handleSelect = (e: any) => {
    if (e.checked)
      return onSelect((prevItensSelected: any) => [
        ...prevItensSelected,
        procedimento,
      ]);

    onSelect((prevItensSelected: any) =>
      prevItensSelected.filter(
        (prevItem: any) => prevItem.id !== procedimento.id,
      ),
    );
  };
  const handleEdit = () => onEdit(procedimento.id);
  const handleRemoveItemFromList = () => onRemoveItemFromList(procedimento.id);

  const formatFraseAlert = ({
    dataUltimaRealizacao,
    dataUltimaSolicitacao,
  }: any) => {
    if (dataUltimaRealizacao) {
      const dataRealizacao = dayjs().diff(dataUltimaRealizacao, 'day');
      const diaPreffix = dataRealizacao > 1 ? 'dias' : 'dia';
      const diaText =
        dataRealizacao === 0 ? 'hoje' : `há ${dataRealizacao} ${diaPreffix}`;

      return `Última realização ${diaText}`;
    }

    const dataSolicitacao = dayjs().diff(dataUltimaSolicitacao, 'day');
    const diaPreffix = dataSolicitacao > 1 ? 'dias' : 'dia';
    const diaText =
      dataSolicitacao === 0 ? 'hoje' : `há ${dataSolicitacao} ${diaPreffix}`;

    return `Última solicitação ${diaText}`;
  };

  const grupoItens = useMemo(
    () =>
      grupoDetails?.grupoExameServicoTussSet.map(({ servicoTuss }: any) => (
        <div
          key={servicoTuss.id}
          className="busca-rapida-procedimentos p-d-flex p-flex-column p-flex-sm-row p-jc-between p-ai-center"
        >
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
            {servicoTuss.nome}
          </SimpleText>

          {(servicoTuss.dataUltimaSolicitacao ||
            servicoTuss.dataUltimaRealizacao) && (
            <div className="p-order-0 p-order-sm-1 p-text-center p-text-sm-right p-mb-2 p-mb-sm-0">
              <i className="fas fa-exclamation-triangle p-mr-2" />
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XXS}
              >
                {formatFraseAlert(servicoTuss)}
              </SimpleText>
            </div>
          )}
        </div>
      )),
    [grupoDetails?.grupoExameServicoTussSet],
  );

  const renderBuscaRapidaProcedimentoPanelHeader = (
    options: PanelHeaderTemplateOptions,
  ) => (
    <BuscaRapidaProcedimentoPanelHeader
      options={options}
      isSelected={isSelected}
      procedimento={procedimento}
      onEdit={handleEdit}
      onRemoveItemFromList={handleRemoveItemFromList}
      onSelect={handleSelect}
    />
  );

  const renderPanelExpand = () => {
    if (isFetching) return <GridListLoading />;

    return grupoItens;
  };

  return (
    <Panel
      className="busca-rapida-item"
      headerTemplate={renderBuscaRapidaProcedimentoPanelHeader}
      toggleable
      collapsed
      onExpand={handlePanelExpand}
    >
      {renderPanelExpand()}
    </Panel>
  );
};
