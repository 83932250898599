import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ManterPacienteProviderProps {
  children: React.ReactNode;
}

interface ManterPacienteContextProps {
  handleSetFormDirty: (state: boolean, type: string) => void;
  isFormsDirty: boolean;
  uploadingFoto: boolean;
  setUploadingFoto: (state: boolean) => void;
}

const ManterPacienteContext = createContext({} as ManterPacienteContextProps);

export function ManterPacienteProvider({
  children,
}: ManterPacienteProviderProps) {
  const [isFormDadosObrigatoriosDirty, setIsFormDadosObrigatoriosDirty] =
    useState(false);
  const [isFormDadosOpcionaisDirty, setIsFormDadosOpcionaisDirty] =
    useState(false);
  const [isFormContatoDirty, setIsFormisFormContatoDirty] = useState(false);
  const [uploadingFoto, setUploadingFoto] = useState(false);

  const handleSetFormDirty = useCallback((data: boolean, form: string) => {
    if (form === 'DADOS_OBRIGATORIOS') setIsFormDadosObrigatoriosDirty(data);
    if (form === 'DADOS_OPCIONAIS') setIsFormDadosOpcionaisDirty(data);
    if (form === 'CONTATOS') setIsFormisFormContatoDirty(data);
  }, []);

  const isFormsDirty = useMemo(() => {
    return !![
      isFormDadosObrigatoriosDirty,
      isFormDadosOpcionaisDirty,
      isFormContatoDirty,
    ].find(el => el === true);
  }, [
    isFormDadosObrigatoriosDirty,
    isFormDadosOpcionaisDirty,
    isFormContatoDirty,
  ]);

  return (
    <ManterPacienteContext.Provider
      value={{
        handleSetFormDirty,
        isFormsDirty,
        uploadingFoto,
        setUploadingFoto,
      }}
    >
      {children}
    </ManterPacienteContext.Provider>
  );
}

export const useManterPaciente = () => useContext(ManterPacienteContext);
