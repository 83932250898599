import useSize from 'src/core/hooks/useSize';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const MonitoramentoTissHeader = () => {
  const { isTablet } = useSize();

  return (
    <div className="content-header p-d-flex p-col-12 p-py-0">
      <SimpleText fontColor="color_60" className="p-col-2">
        Serviço / Guia
      </SimpleText>
      <SimpleText fontColor="color_60" className="p-col-2">
        Profissional
      </SimpleText>
      <SimpleText fontColor="color_60" className="p-col-4 p-md-2">
        Paciente / Cartão
      </SimpleText>
      <SimpleText fontColor="color_60" className="p-col-4 p-md-2">
        Resultado
      </SimpleText>
      {!isTablet && (
        <SimpleText fontColor="color_60" className="p-col-1">
          Resposta
        </SimpleText>
      )}
      {!isTablet && (
        <SimpleText fontColor="color_60" className="p-col">
          Inicio/Fim
        </SimpleText>
      )}

      {!isTablet && (
        <SimpleText fontColor="color_60" className="p-col p-text-center">
          XML
        </SimpleText>
      )}
    </div>
  );
};
