import { useState } from 'react';

import { HistoricoExportacaoAPI } from 'src/APIs/ProntuarioAPI/HistoricoExportacaoAPI/HistoricoExportacaoAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { DesktopRow, Header, MobileRow } from './Grid';

import './ModalHistoricoExportacaoProntuario.scss';

interface ModalHistoricoExportacaoProntuarioProps extends DisclosureType {
  paciente: any;
}

export default function ModalHistoricoExportacaoProntuario({
  close,
  isOpen,
  paciente,
}: ModalHistoricoExportacaoProntuarioProps) {
  const [reload, setReload] = useState(0);
  const [googleUrl, setGoogleUrl] = useState('');

  const onAlterarEntregue = async (idExportacao: number, params: any) => {
    const response = await HistoricoExportacaoAPI.alterarEntregue(
      paciente.id,
      idExportacao,
      params,
    );
    if (response?.status === 200 || response?.status === 204) {
      setReload(reload + 1);
    }
  };

  const handleDownloadArquivo = async (exportacao: any) => {
    const fileURL =
      exportacao?.recibo || exportacao?.assinatura || exportacao?.arquivo;

    const link = document.createElement('a');

    link.href = fileURL;
    window.open(link.href, '_blank');
  };

  const renderRow = (exportacao: any, isMobile: boolean) => {
    if (isMobile)
      return (
        <MobileRow
          exportacao={exportacao}
          onAlterarEntregue={onAlterarEntregue}
          setGoogleUrl={setGoogleUrl}
          handleDownloadArquivo={handleDownloadArquivo}
        />
      );
    return (
      <DesktopRow
        exportacao={exportacao}
        onAlterarEntregue={onAlterarEntregue}
        setGoogleUrl={setGoogleUrl}
        handleDownloadArquivo={handleDownloadArquivo}
      />
    );
  };

  const fetchApi = async (params: any) => {
    const response = await HistoricoExportacaoAPI.getHistoricoExportacao(
      {
        ...params,
        sortBy: 'dataAtualizacao',
        sortDirection: 'DESC',
      },
      Number(paciente.id),
    );

    if (response?.status >= 200 && response?.status < 300) {
      return response?.data;
    }
    return;
  };

  return (
    <Dialog
      header="Histórico exportação prontuário"
      onHide={close}
      visible={isOpen}
      maximizedMobileSize
      maximizedTabletSize
      className="historico-exportacao"
    >
      <>
        <SearchList.Root fetchApi={fetchApi}>
          <div className="p-col-12">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_0}
              fontSize={FONT_SIZE.XS}
              medium
            >
              Paciente: {paciente?.nome}
            </SimpleText>
          </div>
          <div className="p-col-12">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_0}
              fontSize={FONT_SIZE.XS}
              medium
            >
              Lista de exportações
            </SimpleText>
          </div>
          <div className="p-col-12">
            <SearchList.Header>
              <Header />
            </SearchList.Header>
            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </div>
        </SearchList.Root>
        <div className="p-col-12">
          <Button
            label="Fechar"
            onClick={() => close()}
            btnType="outline"
            stretch
          />
        </div>
      </>
    </Dialog>
  );
}
