import { useCallback, useState } from 'react';

import Dropzone, { Accept } from 'react-dropzone';
import { toast } from 'react-toastify';

import axios from 'axios';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';

import './OverlayAnexar.scss';

interface ReciboProps {
  url: string | undefined;
  name: string;
  size: number;
  type: string;
}

interface OverlayAnexarProps {
  exportacao: any;
  index: number;
  setGoogleUrl: any;
  onAlterarEntregue: any;
}

export const OverlayAnexar = ({
  exportacao,
  setGoogleUrl,
  onAlterarEntregue,
}: OverlayAnexarProps) => {
  const [fileBruto, setFileBruto] = useState<any>({});
  const [dadosLocalRecibo, setDadosLocalRecibo] = useState<ReciboProps>({
    url: '',
    name: '',
    size: 0,
    type: '',
  });
  const [temRecibo, setTemRecibo] = useState(false);

  const [visible, setVisible] = useState(false);

  const accept: Accept = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
  };

  const handleUploadLocal = useCallback(async (file: any) => {
    const localUrl = URL.createObjectURL(file[0]);
    setFileBruto(file);

    setDadosLocalRecibo({
      url: localUrl,
      name: file[0]?.name,
      size: file[0]?.size,
      type: file[0]?.type,
    });
  }, []);

  const handleRemoveUrl = useCallback(() => {
    setDadosLocalRecibo({ url: '', name: '', size: 0, type: '' });
    setFileBruto({});
    setGoogleUrl('');
    setTemRecibo(false);
  }, [setGoogleUrl]);

  const toastData = useCallback((type: 'success' | 'error') => {
    return {
      title: type === 'success' ? 'Sucesso' : 'Atenção',
      message:
        type === 'success'
          ? 'Upload de imagem para o sistema concluída com sucesso'
          : 'Ocorreu um erro ao realizar o upload de imagem para o sistema',
      type,
    };
  }, []);

  const handleUploadToSystem = useCallback(async () => {
    const dataFile: FileUpload = {
      fileName: dadosLocalRecibo?.name,
      contentType: dadosLocalRecibo?.type,
      contentLength: dadosLocalRecibo?.size,
      resourceType: 'OUTROS',
      resourceId: exportacao.id,
    };
    const responseGoogleStorage: any = await FileUploadAPI.sendFileUpload(
      dataFile,
      {
        hideToast: true,
      },
    );

    if (responseGoogleStorage?.status === 200) {
      const urlGoogle = responseGoogleStorage?.data?.uploadUrl;

      const payload = {
        recebido: true,
        recibo: responseGoogleStorage?.data?.filePath,
      };

      await onAlterarEntregue(exportacao?.id, payload);

      const data = fileBruto[0];

      await axios.put(urlGoogle, data, {
        headers: {
          'Content-Type': dadosLocalRecibo?.type,
        },
      });

      return;
    }
    return toast(<Toast />, { data: toastData('error') });
  }, [
    dadosLocalRecibo?.name,
    dadosLocalRecibo?.size,
    dadosLocalRecibo?.type,
    exportacao.id,
    fileBruto,
    onAlterarEntregue,
    toastData,
  ]);

  const onRejected = () =>
    toast(<Toast />, {
      data: {
        title: 'Erro',
        message: 'Formato não suportado. Apenas arquivo PDF pode ser anexado',
        type: 'error',
      },
    });

  return (
    <div className="recibo-container">
      <div className="recibo-c-header">
        <Dropzone
          onDrop={acceptedFiles =>
            acceptedFiles?.length && handleUploadLocal(acceptedFiles)
          }
          accept={accept}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <Button
                btnType="tonal"
                icon="fa fa-plus"
                label="Adicionar"
                disabled={!!dadosLocalRecibo?.url}
                {...getRootProps()}
              />
              <input {...getInputProps()} />
            </>
          )}
        </Dropzone>
      </div>
      {dadosLocalRecibo?.url !== '' ? (
        <div className="recibo-c-body-preview p-my-4 p-px-2">
          <div className="recibo-c-body-item">
            <div className="recibo-c-body-item-infos p-d-flex p-flex-column p-gap-2 ">
              <SimpleText
                fontColor={FONT_COLOR.PRIMARY}
                fontSize={FONT_SIZE.XS}
                medium
              >
                {dadosLocalRecibo?.name}
              </SimpleText>

              <div className="recibo-c-body-item-infos-right">
                {dadosLocalRecibo?.size > 0 && (
                  <SimpleText
                    className="p-mr-1"
                    fontColor={FONT_COLOR.COLOR_40}
                    fontSize={FONT_SIZE.XS}
                  >
                    {dadosLocalRecibo?.size} KB
                  </SimpleText>
                )}
              </div>

              <Button
                btnType="gray"
                icon="fa fa-trash"
                onClick={handleRemoveUrl}
              />
            </div>
          </div>
        </div>
      ) : (
        <Dropzone
          onDrop={acceptedFiles =>
            acceptedFiles?.length && handleUploadLocal(acceptedFiles)
          }
          accept={accept}
          maxFiles={1}
          onDropRejected={() => onRejected()}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className="recibo-c-body p-ai-center p-d-flex p-flex-column p-p-4 p-gap-2"
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <SimpleText
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}
              >
                Você pode arrastar seus arquivos aqui.
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXXS}
              >
                Formato aceito: PDF.
              </SimpleText>
            </div>
          )}
        </Dropzone>
      )}
      <div className="recibo-c-footer">
        <div className="recibo-c-footer-btns">
          <div className="p-col-12">
            <Button
              icon="fa fa-arrow-up"
              label="Enviar para o sistema"
              disabled={dadosLocalRecibo?.url === '' || temRecibo}
              type="button"
              onClick={handleUploadToSystem}
              stretch
            />
          </div>
        </div>
        {visible && (
          <AlertBox
            visible={visible}
            onHide={() => setVisible(false)}
            text="Pressione “enviar” para iniciar o upload"
          />
        )}
      </div>
    </div>
  );
};
