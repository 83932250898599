import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

interface RowProps {
  removeServicosVinculados: (idServico: string) => void;
  data: any;
}

const Row = ({ data, removeServicosVinculados }: RowProps) => {
  return (
    <SearchList.CardItem>
      <div className="p-grid p-ai-start p-jc-between p-w-100">
        <div className="p-d-flex p-flex-column p-jc-start p-gap-1 p-col-10 p-md-4">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Código - Nome do serviço
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {data.nomeCodigoServicoTuss}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-jc-start p-gap-1 p-col-6 p-sm-5 p-md-3 p-sm-order-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Profissional de saúde
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {data.nomeProfissionalSaude}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-jc-start p-gap-1 p-col-6 p-sm-5 p-md-3 p-sm-order-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Convênio
          </SimpleText>
          <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XS}>
            {capitalizeFirstLetter(data.nomeConvenio)}
          </SimpleText>
        </div>
        <div className="p-d-flex p-ai-center p-jc-end p-col p-md-order-3">
          <Button
            btnType="gray"
            icon="fas fa-trash"
            onClick={() => {
              removeServicosVinculados(data.id);
            }}
          />
        </div>
      </div>
    </SearchList.CardItem>
  );
};

export default Row;
