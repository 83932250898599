import { BrowserRouter, Routes as RoutesDom } from 'react-router-dom';

import EmedRoutes from './Emed/Emed.routes';
import PortalRoutes from './Portal/Portal.routes';
import TelemedicinaRoutes from './Telemedicina/Telemedicina.routes';

const Routes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <RoutesDom>
        {EmedRoutes()}
        {TelemedicinaRoutes()}
        {PortalRoutes()}
      </RoutesDom>
    </BrowserRouter>
  );
};

export default Routes;
