import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/servico-tuss';

export const loadServicoTussRestricoesById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetServicoTussRestricoesOutDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/restricoes`,
    hideToast: true,
    ...options,
  });
};

export const updateServicoTussRestricoes = (
  id: number,
  payload: PutServicoTussRestricoesInDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PutServicoTussRestricoesInDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/restricoes`,
    data: payload,
    return: true,
    ...options,
  });
};

const ServicoTussRestricoesAPI = {
  loadServicoTussRestricoesById,
  updateServicoTussRestricoes,
};

export default ServicoTussRestricoesAPI;
