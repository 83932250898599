import Http, { HttpParams } from 'src/core/http/Http';

import { Sexo } from './../../models/APIs/UtilsAPI/SexoDTOs.d';

export const retrieveSexos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Sexo[]> => {
  return Http.get({
    service: 'adm',
    url: '/utils/busca-sexo',
    hideToast: true,
    ...options,
  });
};

export const retrieveGeneros = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any[]> => {
  return Http.get({
    service: 'adm',
    url: '/utils/busca-genero',
    hideToast: true,
    ...options,
  });
};

export const retriveRacaCor = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/utils/busca-raca-cor',
    hideToast: true,
    ...options,
  });
};

export const retriveGrauEscolar = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/utils/busca-grau-escolar',
    hideToast: true,
    ...options,
  });
};

export const getCepAddress = (
  cep: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/utils/busca-cep?cep=${cep}`,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });
};

export const getCidades = (
  idEstado: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/utils/busca-cidades?idEstado=${idEstado}`,
    hideToast: true,
    ...options,
  });
};

export const retriveEstados = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/utils/busca-estados',
    hideToast: true,
    ...options,
  });
};

export const retrievePaises = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/utils/busca-paises',
    hideToast: true,
    ...options,
  });
};

export const retrieveCids = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<Cids[]>> => {
  return Http.get({
    service: 'pep',
    url: '/utils/busca-combinada-cids',
    params,
    hideToast: true,
    ...options,
  });
};

export const getVinculoFamiliar = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: '/utils/busca-vinculo-familiar',
    hideToast: true,
    ...options,
  });

export const getSituacaoFamiliar = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: '/utils/busca-situacao-familiar',
    hideToast: true,
    ...options,
  });

const UtilsAPI = {
  GetSexos: retrieveSexos,
  GetGeneros: retrieveGeneros,
  GetAddressByCep: getCepAddress,
  GetCidades: getCidades,
  GetEstados: retriveEstados,
  GetPaises: retrievePaises,
  GetGrauEscolar: retriveGrauEscolar,
  GetRacaCor: retriveRacaCor,
  GetCids: retrieveCids,
  getVinculoFamiliar,
  getSituacaoFamiliar,
};

export default UtilsAPI;
