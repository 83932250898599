import React from 'react';

import dayjs from 'dayjs';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';

interface Props extends DisclosureType {
  impresso: any;
}

export default function VisualizarInativacaoImpresso({
  impresso,
  isOpen,
  close,
}: Props) {
  return (
    <Dialog
      id="modal-justificativa-inativacao"
      type="modal"
      header="Você tem certeza que deseja inativar?"
      visible={isOpen}
      onHide={() => {
        close();
      }}
      maximizedMobileSize
    >
      <form id="inativacao-impresso">
        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
          Este item no prontuário foi inativado em{' '}
          <b>
            {dayjs(new Date(impresso.dataAtualizacao)).format(
              'DD [de] MMM [de] YYYY',
            ) || 'data não informada'}
          </b>{' '}
          por {impresso.usuario.nome}
        </SimpleText>

        <TextareaInput
          className="p-col-12"
          name="justificativaInativacao"
          label="Justificativa"
          value={impresso.justificativaInativacao || ''}
          disabled
        />
        <div className="p-grid">
          <div className="p-col-12">
            <Button
              btnType="ghost"
              type="reset"
              label="Fechar"
              className="p-col-align-center"
              onClick={() => {
                close();
              }}
              stretch
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
