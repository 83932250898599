import React, { useEffect } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalVisualizarEmailProps extends DisclosureType {
  email: any;
}

export default function ModalVisualizarEmail({
  isOpen,
  close,
  email,
}: ModalVisualizarEmailProps) {
  useEffect(() => {
    if (email?.conteudoEmail.startsWith('<html')) {
      const getDiv = setTimeout(() => {
        const divTermo: any = document.querySelector('#email-html');

        divTermo.innerHTML = email?.conteudoEmail;
      }, 50);

      return () => {
        clearTimeout(getDiv);
      };
    }
    return () => {};
  }, [email]);

  useEffect(() => {
    if (email?.conteudoEmail.startsWith('<?xml')) {
      const htmlIndex = email?.conteudoEmail.indexOf('<html');
      const newEmailContent = email?.conteudoEmail.substring(htmlIndex);
      const getDiv = setTimeout(() => {
        const divTermo: any = document.querySelector('#email-html');

        divTermo.innerHTML = newEmailContent;
      }, 50);

      return () => {
        clearTimeout(getDiv);
      };
    }
    return () => {};
  }, [email]);

  return (
    <Dialog
      id="visualizar-email"
      type="modal"
      header="Visualizar conteúdo do e-mail"
      visible={isOpen}
      onHide={close}
      maximizedMobileSize
      maximizedTabletSize
    >
      <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
        <div id="email-html"></div>
        {!email?.conteudoEmail?.startsWith('<html') &&
          !email?.conteudoEmail?.startsWith('<?xml') && (
            <p>{email?.conteudoEmail}</p>
          )}
        <div className="p-d-flex p-mt-2 p-w-100 p-gap-1">
          <Button
            label="Fechar"
            onClick={() => close()}
            btnType="ghost"
            stretch
          />
          {email?.urlDocumentoAnexo && (
            <Button
              label="Baixar anexo"
              onClick={() => window.open(email?.urlDocumentoAnexo, '_blank')}
              btnType="tonal"
              stretch
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}
