import { createContext } from 'react';

export interface AccessData {
  login: string;
  perfis: [];
  idEmpresa?: number;
  customization?: any;
}

export interface UserAccessDataContextData {
  accessData: AccessData;
  accessStepCompleted: boolean;
  saveAccessData: (data: AccessData) => void;
  setAccessStepCompleted: (isCompleted: boolean) => void;
}

export const UserAccessDataContext = createContext(
  {} as UserAccessDataContextData,
);
