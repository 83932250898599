import Http, { HttpParams } from 'src/core/http/Http';

const getProntuariosNaoAssinados = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'agenda',
    url: '/agendas/atendimentos-nao-assinados',
    params,
    data: params,
    hideToast: true,
    return: true,
    ...options,
  });
};

const putAssinarDocumento = async (
  idAtendimento: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'agenda',
    url: `/agendas/assinar-atendimento/${idAtendimento}`,
    data,
    hideToast: true,
    return: true,
    ...options,
  });
};

export const AssinaturaAPI = {
  getProntuariosNaoAssinados,
  putAssinarDocumento,
};
