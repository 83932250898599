import React, { useState } from 'react';

import ClienteAPI from 'src/APIs/AdminAPI/ClienteAPI/ClieteAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface SwitchStatusProps {
  cardapio: any;
  isAtivo: boolean;
  setAtivo: any;
}

const SwitchStatus = ({
  cardapio: item,
  isAtivo,
  setAtivo,
}: SwitchStatusProps) => {
  const [loading, setLoading] = useState(false);
  const [cardapio, setCardapio] = useState<any>(item);

  const onSwitchStatus = async () => {
    setLoading(true);
    if (!isAtivo) {
      try {
        const response = await ClienteAPI.ativarClienteStatus(
          Number(cardapio.id),
          Number(cardapio.funcionalidade.id),
          { throwError: true },
        );

        if (!cardapio.id) {
          setCardapio((old: any) => ({ ...old, id: response.id }));
        }

        setAtivo(response.status);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await ClienteAPI.inativarClienteStatus(
          Number(cardapio.id),
          { throwError: true },
        );
        setAtivo(response.status);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading)
    return (
      <div className="p-mb-4 p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return <Switch checked={isAtivo} showStatus onChange={onSwitchStatus} />;
};

export default SwitchStatus;
