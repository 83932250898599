import { Checkbox } from '../_UI/Checkbox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from '../Basics/SimpleText/SimpleText';

export const Item = (props: any) => {
  const { isSelected, procedimento, onSelect, children } = props;

  return (
    <div className="solicitacao-procedimento-item-data p-d-flex p-ai-center">
      <Checkbox checked={isSelected} onChange={onSelect} />

      <SimpleText
        className="solicitacao-exame-codigo"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_40}
      >
        {procedimento?.codigo}
      </SimpleText>
      <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
        {procedimento.nome}
      </SimpleText>

      {children}
    </div>
  );
};
