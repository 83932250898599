import React, { useState } from 'react';

import { FaturamentoAPI } from 'src/APIs/FinanceiroAPI/FaturamentoAPI/FaturamentoAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { useSelectedFaturamentos } from './useSelectedFaturamentos';

interface ModalAlterarStatusProps extends DisclosureType {
  isOpen: boolean;
  close: () => void;
}

const ModalAlterarStatus = ({ isOpen, close }: ModalAlterarStatusProps) => {
  const [status, setStatus] = useState('PENDENTE');

  const { selectedFaturamentos, setReload } = useSelectedFaturamentos();

  const statusOptions = [
    { label: 'PAGO', value: 'PAGO' },
    { label: 'GLOSA', value: 'GLOSA' },
    { label: 'PENDENTE', value: 'PENDENTE' },
    { label: 'FATURADO', value: 'FATURADO' },
  ];

  const handleChangeStatusGeral = async (novoStatus: string) => {
    const idsFaturamentos = selectedFaturamentos.map(
      (faturamento: any) => faturamento.idAtendimento,
    );
    await FaturamentoAPI.putStatusAtendimento({
      idsAtendimento: idsFaturamentos.join(','),
      status: novoStatus,
    });
    setReload((prev: any) => prev + 1);
    return close();
  };

  return (
    <Dialog
      type="modal"
      onHide={() => close()}
      visible={isOpen}
      header="Alterar status geral"
      maximizedMobileSize
      className="modal-alterar-status"
    >
      <div className="p-grid">
        <div className="p-col-12">
          <SimpleText>Alterar o status de todos os serviços para:</SimpleText>
        </div>
        <div className="p-col-12 p-py-0">
          <Dropdown
            label="Status"
            options={statusOptions}
            value={status}
            onChange={e => setStatus(e.value)}
          />
        </div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Salvar"
            onClick={() => {
              handleChangeStatusGeral(status);
            }}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ModalAlterarStatus;
