import { useState } from 'react';

import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';

import useSize from 'src/core/hooks/useSize';

import AlertBox from 'src/components/AlertBox/AlertBox';

import DesktopRow from './DesktopRow';
import RowMobile from './MobileRow';
import SwitchStatus from './SwitchStatus';

interface RowProps {
  data: any;
  handleConfigFuncionalidade: any;
}

const Row = ({ data, handleConfigFuncionalidade }: RowProps) => {
  const [statusItem, setStatusItem] = useState(data.status);

  const { isMobile } = useSize();

  const disabledByAdmin = !data.statusCardapio;
  const disabledByMaster = !data.funcionalidade.status;
  const alertBoxMessage = disabledByMaster
    ? 'Funcionalidade desabilitada pelo usuário Master.'
    : 'Funcionalidade desabilitada pelo usuário Administrador.';

  const alertDivergencia = (
    <AlertBox
      text={alertBoxMessage}
      visible={disabledByMaster || disabledByAdmin}
    />
  );

  const switchStatus = (
    <SwitchStatus
      idFuncionalidade={data.funcionalidade.id}
      idPerfil={data.idPerfil}
      status={statusItem}
      onChange={setStatusItem}
    />
  );

  const commonProps = {
    data,
    alertDivergencia,
    handleConfigFuncionalidade,
    switchStatus,
  };

  return isMobile ? (
    <RowMobile {...commonProps} statusItem={statusItem} />
  ) : (
    <DesktopRow {...commonProps} />
  );
};

export default Row;
