import { useCallback, useState } from 'react';

import { useLocation, useParams } from 'react-router';

import OpmeAPI from 'src/APIs/ProntuarioAPI/OpmeAPI/OpmeAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';
import PanelOptionsMenu from 'src/pages/Emed/Prontuario/components/PanelOptionsMenu/PanelOptionsMenu';

import ModalJustificativa from './ModalJustificativa/ModalJustificativa';
import ModalNovaOpme from './ModalNovaOpme/ModalNovaOpme';
import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

import './Opme.scss';

interface OpmeProps {
  idPaciente: number | null;
}

function Opme({ idPaciente }: OpmeProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { getInfoLembretes, atendimentoStatus } = useAtendimento();

  const [modalNovaOpmeVisible, setModalNovaOpmeVisible] =
    useState<boolean>(false);
  const [editOpme, setEditOpme] = useState<Opme | null>(null);
  const [idOpmeInativacao, setIdOpmeInativacao] = useState<number | null>(null);
  const [idOpmeExcluir, setIdOpmeExcluir] = useState<number | null>(null);
  const [visualizarJustificativa, setVisualizarJustificativa] =
    useState<Opme | null>(null);
  const [updateList, setUpdateList] = useState<number>(0);

  const fetchAPI = useCallback(
    (params: any): Promise<any> => {
      return OpmeAPI.getOpmes(idAtendimento, {
        ...params,
        sortBy: 'dataOcorrencia',
        sortDirection: 'DESC',
      });
    },
    [idAtendimento],
  );

  const onAlterarLembrete = async (idOpme: number) => {
    const response = await OpmeAPI.alterarOpmeLembrete(idOpme);

    if (response?.status === 204) reloadList();
  };

  const onEdit = (opme: Opme) => {
    setEditOpme(opme);
    setModalNovaOpmeVisible(true);
  };

  const optionsMenu = (opme: Opme) => (
    <PanelOptionsMenu
      ativo={opme.status === 'ATIVO'}
      lembreteAtivo={opme.lembrete}
      idAtendimentoRegistro={opme.idAtendimento}
      idUsuarioCriacao={opme.idUsuarioAlteracao}
      onAlterarLembrete={() => onAlterarLembrete(opme.id)}
      onEdit={() => onEdit(opme)}
      onInativar={() => setIdOpmeInativacao(opme.id)}
      onExcluir={() => setIdOpmeExcluir(opme.id)}
      onVisualizarJustificativa={() => setVisualizarJustificativa(opme)}
      showEditOnlyAtendimentoCorrente
    />
  );

  const renderRow = (opme: Opme) => (
    <div className="p-mt-2">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            opme={opme}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            optionsMenu={optionsMenu(opme)}
          />
        )}
      >
        <PanelContent opme={opme} />
      </Panel>
    </div>
  );

  const reloadList = () => {
    setUpdateList(currentUpdate => currentUpdate + 1);
    getInfoLembretes(Number(idPaciente));
  };

  const onExcluir = async () => {
    const response = await OpmeAPI.removerOpme(
      idOpmeExcluir as number,
      Number(idAtendimento),
    );

    if (response?.status === 204) {
      reloadList();
      setIdOpmeExcluir(null);
    }
  };

  const onHideModalOpme = () => {
    setEditOpme(null);
    setModalNovaOpmeVisible(false);
  };

  return (
    <div id="opme-container">
      <SearchList.Root
        fetchApi={fetchAPI}
        empresaRequired
        consultorioRequired
        reloadWhenConsultorioChange
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center p-mb-3">
            <SimpleText
              fontSize={FONT_SIZE.MD}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              OPME
            </SimpleText>

            <Button
              className="p-mr-4"
              type="button"
              btnType="tonal"
              label="Registrar"
              icon="fas fa-plus"
              onClick={() => setModalNovaOpmeVisible(true)}
              disabled={
                atendimentoStatus !== 'ATENDENDO' &&
                atendimentoStatus !== 'ATENDENDO_TRIAGEM'
              }
            />
          </div>

          <SearchList.InfiniteScroll
            customEmptyText="Nenhum item encontrado para esta busca."
            className="p-mt-5"
            renderRow={renderRow}
            reload={updateList}
            autoHeight={false}
          />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ModalNovaOpme
        visible={modalNovaOpmeVisible}
        editOpme={editOpme}
        onHide={onHideModalOpme}
        onSuccess={reloadList}
      />

      <ModalJustificativa
        idOpme={idOpmeInativacao}
        visualizarJustificativa={visualizarJustificativa}
        onHide={() => {
          setIdOpmeInativacao(null);
          setVisualizarJustificativa(null);
        }}
        onSuccess={reloadList}
      />

      <ConfirmDialog
        visible={!!idOpmeExcluir}
        header="Excluir OPME"
        text="A OPME será excluída. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdOpmeExcluir(null)}
        onConfirm={onExcluir}
      />
    </div>
  );
}

export default Opme;
