import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './GrupoAtendimentoGrid/Row';
import { Header } from './Header';
import ModalExcluirGrupoAtendimento from './ModalExcluirGrupoAtendimento/ModalExcluirGrupoAtendimento';
import ModalFiltroGrupoAtendimento from './ModalFiltroGrupoAtendimento/ModalFiltroGrupoAtendimento';

import './GrupoAtendimento.scss';

const GrupoAtendimento = () => {
  const navigate = useNavigate();

  const [modalExcluirGrupoAtendimento, setModalExcluirGrupoAtendimento] =
    useState(false);
  const [grupoAtendimento, setGrupoAtendimento] = useState({} as any);
  const [modalFiltroGrupoAtendimento, setModalFiltroGrupoAtendimento] =
    useState(false);

  const [listagem, setListagem] = useState<any[]>([]);
  const [reload, setReload] = useState(0);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'age_grupo_de_atendimento_cadastrar',
      'age_grupo_de_atendimento_alterar',
      'age_grupo_de_atendimento_consultar',
      'age_grupo_de_atendimento_excluir',
    ],
  });

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleModalExcluirGrupoAtendimentoClose = useCallback(() => {
    setModalExcluirGrupoAtendimento(false);
  }, []);

  const handleModalFiltroGrupoAtendimentoClose = useCallback(() => {
    setModalFiltroGrupoAtendimento(false);
  }, []);

  const renderRow = (data: any) => (
    <Row
      data={data}
      reloadList={reloadList}
      setGrupoAtendimento={setGrupoAtendimento}
      setModalExcluirGrupoAtendimento={setModalExcluirGrupoAtendimento}
    />
  );

  const fetchApi = ({ query, ...rest }: any) => {
    return GrupoAtendimentoAPI.loadGrupoAtendimentos({
      apelido: query,
      ...rest,
    });
  };

  return (
    <Page className={'GrupoAtendimento'} white>
      <>
        <PageHeader
          title={'Grupos de atendimento'}
          subtitle={
            'Cadastre e gerencie grupos de pacientes para um atendimento médico'
          }
        />
        <div className="GrupoAtendimento-body">
          <SearchList.Root
            fetchApi={fetchApi}
            empresaRequired
            reloadWhenEmpresaChange
          >
            <SearchList.BorderContainer>
              <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center">
                <SearchList.SearchInput placeholder="Pesquisar grupo" />

                <div className=" p-d-flex p-mb-3">
                  <Button
                    btnType="pill"
                    type="button"
                    className="p-mr-3"
                    label="Novo grupo de atendimento"
                    icon="fas fa-plus"
                    checkPermission="AGE_GRUPO_DE_ATENDIMENTO_CADASTRAR"
                    onClick={() =>
                      navigate('/pacientes/novo-grupo-atendimento')
                    }
                  />

                  <Button
                    label="Filtros"
                    onClick={() => setModalFiltroGrupoAtendimento(true)}
                    btnType={BtnTypes.TONAL}
                  />
                </div>
              </div>

              <Header />

              <SearchList.InfiniteScroll
                renderRow={renderRow}
                reload={reload}
              />
            </SearchList.BorderContainer>

            <ModalFiltroGrupoAtendimento
              visible={modalFiltroGrupoAtendimento}
              onHide={handleModalFiltroGrupoAtendimentoClose}
            />
          </SearchList.Root>
        </div>

        <ModalExcluirGrupoAtendimento
          grupoAtendimento={grupoAtendimento}
          visible={modalExcluirGrupoAtendimento}
          onHide={handleModalExcluirGrupoAtendimentoClose}
          listaGrupoAtendimento={listagem}
          setListaGrupoAtendimento={setListagem}
          reload={reloadList}
        />

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </>
    </Page>
  );
};

export default GrupoAtendimento;
