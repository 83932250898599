import Http, { HttpParams } from 'src/core/http/Http';

const loadMonitoramentoTissPesquisaAvancada = (
  params: loadMonitoramentoTissPesquisaAvancadaParams,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> => {
  return Http.get({
    service: 'adm',
    url: `/monitoramento-tiss/pesquisa-avancada`,
    params,
    hideToast: true,
    ...options,
  });
};

export const MonitoramentoTissAPI = {
  loadMonitoramentoTissPesquisaAvancada,
};
