import { memo, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { cleanObject } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import CamposRow from './CamposRow/CamposRow';
import { ProtocoloAtendimentoCreateDialog } from './ProtocoloAtendimentoCreateDialog';

const ProtocoloAtendimentoCreateFormCampos = ({
  data = {},
  handleGoBack,
}: {
  data: any;
  handleGoBack(): void;
}) => {
  const [definicao, setDefinicao] = useState<any>(
    cleanObject(data.definicao) || {},
  );
  const { id } = useParams();
  const vinculosData = data;
  const isEdit = !!id;

  const navigate = useNavigate();
  const dialogCreate = useDisclosure({ opened: false });

  const form = useForm({});

  useEffect(() => {
    form.reset({ definicao: data?.definicao?.length ? data.definicao : [] });
  }, [data.definicao, form]);

  const handleAdd = (v: any) => {
    const { tipo, ...rest } = v;
    const newDefinicao = {
      ...definicao,
      ...rest,
    };
    setDefinicao(newDefinicao);
  };

  const handleRemove = (v: any) => {
    const newDefinicao = { ...definicao };
    delete newDefinicao[v.tipo];
    setDefinicao(newDefinicao);
  };

  const handleEdit = (tipo?: string) => {
    dialogCreate.open({
      state: { definicao, tipo },
    });
  };

  const handleUpdateDefinicao = (v: any) => {
    const { tipo, ...rest } = v;
    const newDefinicao = {
      ...definicao,
      ...rest,
    };
    setDefinicao(newDefinicao);
    dialogCreate.close();
  };

  const handleSubmit = async () => {
    const {
      cids,
      ciaps,
      vinculos,
      nome,
      consultorios,
      convenios,
      ...restVinculos
    } = vinculosData;

    const cidsIds = cids?.map((cid: any) => cid.id);
    const ciapsIds = ciaps?.map((ciap: any) => ciap.id);

    const definicoes = definicao;

    if (definicoes.solicitacaoExames) {
      definicoes.solicitacaoExames = definicao.solicitacaoExames.map(
        (s: any) => ({
          quantidade: s.quantidade as number,
          idServicoTuss: s.servicoTuss?.id || s.idServicoTuss,
          nome: s.servicoTuss?.nome || s.nome,
        }),
      );
    }

    const payload = {
      nome,
      cids: cidsIds,
      ciaps: ciapsIds,
      definicao: definicoes,
      ...(vinculos && {
        consultorios: vinculos.consultorios.map((c: any) => c.id),
      }),
      ...(vinculos && { convenios: vinculos.convenios.map((c: any) => c.id) }),
      ...(consultorios && { consultorios }),
      ...(convenios && { convenios }),
      ...(restVinculos.id && { id: restVinculos.id }),
    };

    try {
      if (isEdit) {
        await ProtocoloAtendimentoAPI.updateProtocoloAtendimento(payload);
        navigate('/cadastros/protocolo-atendimento');
        return;
      }
      await ProtocoloAtendimentoAPI.postProtocoloAtendimento(payload);
      navigate('/cadastros/protocolo-atendimento');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        id="definicao-form"
        className="p-col-12 p-md-8  p-d-flex p-flex-column p-gap-2"
      >
        <div className="p-d-flex p-jc-between p-ai-center p-gap-2">
          <SimpleText fontSize="sm" medium>
            Definições do protocolo
          </SimpleText>
          <Button
            label="Adicionar definição"
            icon="fas fa-plus"
            btnType="tonal"
            onClick={() => handleEdit()}
          />
        </div>

        <div className="p-border-container">
          {Object.keys(definicao).length === 0 ? (
            <div className="empty-definicao">
              <SimpleText fontColor="color_40" className="p-mb-2" medium>
                Nenhuma definicao adicionada
              </SimpleText>
              <SimpleText fontColor="primary" medium>
                Adicione uma ou mais definicao
              </SimpleText>
            </div>
          ) : (
            Object.keys(definicao).map(key => (
              <CamposRow
                key={key}
                item={{ tipo: key, ...definicao[key] }}
                handleRemove={() => handleRemove({ tipo: key })}
                handleEdit={() => handleEdit(key)}
              />
            ))
          )}
        </div>

        <div className="p-d-flex p-gap-1 p-my-2">
          <Button
            label="Voltar"
            btnType="ghost"
            onClick={handleGoBack}
            stretch
            disabled={form.formState.isSubmitting}
          />
          <Button
            label="Salvar"
            type="submit"
            // disabled={!Object.keys(definicao).length}
            loading={form.formState.isSubmitting}
            stretch
          />
        </div>
      </form>

      {dialogCreate.isOpen && (
        <ProtocoloAtendimentoCreateDialog
          handleUpdateDefinicao={handleUpdateDefinicao}
          handleAdd={handleAdd}
          {...dialogCreate}
        />
      )}
    </FormProvider>
  );
};

export default memo(ProtocoloAtendimentoCreateFormCampos);
