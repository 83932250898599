/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';

import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import { ProntuarioMedicamento } from 'src/models/APIs/ProntuarioAPI/MedicamentosAPI/MedicamentosAPI';
import {
  ListaMedicamentoPrescricao,
  LoadPrescricao,
} from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { FieldMedicamentosFavoritos } from '../components/FieldMedicamentosFavoritos/FieldMedicamentosFavoritos';
import { FieldMedicamentosHistorico } from '../components/FieldMedicamentosHistorico/FieldMedicamentosHistorico';
import { FieldSearchMedicamentos } from '../components/FieldSearchMedicamentos/FieldSearchMedicamentos';
import { FieldVia } from '../components/FieldVia/FieldVia';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import FormInput from 'src/components/FormInput/FormInput';

import './PrescricaoCreate.scss';

type IPrescricaoCreateProps = DisclosureType & {
  item?: LoadPrescricao;
  refetchList: () => void;
};

type IPrescricaoRowProps = {
  item: ListaMedicamentoPrescricao;
  idx: number;
  handleRemove: () => void;
};

export const PrescricaoCreate = ({ ...props }: IPrescricaoCreateProps) => {
  return (
    <Dialog
      className="prescricao-create-dialog"
      visible={props.isOpen}
      onHide={props.close}
      header="Prescrição"
      maximizedMobileSize
    >
      <ErrorBoundary>
        <PrescricaoCreateComp {...props} />
      </ErrorBoundary>
    </Dialog>
  );
};

const PrescricaoCreateComp = ({
  close,
  item: prescricao,
  refetchList,
}: IPrescricaoCreateProps) => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    posologia: Yup.string().required('Campo obrigatório'),
    via: Yup.string().required('Campo obrigatório'),
    prescricaoMedicamentos: Yup.array().of(
      Yup.object({
        quantidade: Yup.number()
          .positive('O valor deve ser positivo')
          .typeError('O valor deve ser numérico'),
      }),
    ),
  });

  const formList = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      descricao: undefined,
      posologia: prescricao?.posologia,
      via: prescricao?.via,
      prescricaoMedicamentos: prescricao?.prescricaoMedicamentos || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'prescricaoMedicamentos',
    control: formList.control,
  });

  const handleAdd = (items: ProntuarioMedicamento[], aditionalInfo?: any) => {
    const data = items.map(item => ({
      idMedicamento: item.id,
      descricao: item.nome,
      laboratorio: item.detentorRegistroAnvisa,
      quantidade: item?.quantidade || 1,
    }));

    append(data);

    if (aditionalInfo) {
      formList.setValue('via', aditionalInfo?.via);
      formList.setValue('posologia', aditionalInfo?.posologia);
    }
  };

  const handleRemove = (idx: number) => {
    remove(idx);
  };

  const handleFavoritar = useCallback(
    async (values: any) => {
      const payload = {
        ...values,
        idAtendimento: Number(idAtendimento),
        ...(values.descricao && { descricao: values.descricao }),
      };

      return PrescricaoAPI.createPrescricaoFavoritos(
        profissionalAtivo.id,
        payload,
      ).then(() =>
        //Invalida query de prescrições favoritas
        dispatch(setInvalidateQuery({ invalidatePrescricaoFavotiros: true })),
      );
    },
    [dispatch, idAtendimento, profissionalAtivo.id],
  );

  const onSubmit = useCallback(
    async (values: any, type = 'submit') => {
      if (type === 'favorite') {
        return handleFavoritar(values);
      }

      const isEditing = !!prescricao;
      const payload = isEditing
        ? { ...prescricao, ...values }
        : { idAtendimento: Number(idAtendimento), ...values };

      try {
        const action = isEditing
          ? PrescricaoAPI.updatePrescricao(idAtendimento!, payload, {
              throwError: true,
            })
          : PrescricaoAPI.createPrescricao(idAtendimento!, payload, {
              throwError: true,
            });
        await action;

        return [close(), refetchList()];
      } catch {
        return;
      }
    },
    [close, handleFavoritar, idAtendimento, prescricao, refetchList],
  );

  return (
    <div id="prescricao-create" className="prescricao-create-container">
      <div className="p-grid p-w-100 p-ai-center">
        <div className="p-col-12 p-sm-7">
          <FieldSearchMedicamentos onAdd={handleAdd} />
        </div>
        <div className="p-col-6 p-sm-2">
          <FieldMedicamentosFavoritos onAdd={handleAdd} />
        </div>
        <div className="p-col-6 p-sm-3">
          <FieldMedicamentosHistorico onAdd={handleAdd} />
        </div>
      </div>

      <FormProvider {...formList}>
        <form onSubmit={formList.handleSubmit(onSubmit)}>
          <div className="prescricao-content">
            {!!fields.length ? (
              fields.map((item, idx) => (
                <PrescricaoContentRow
                  item={item}
                  key={idx}
                  idx={idx}
                  handleRemove={() => handleRemove(idx)}
                />
              ))
            ) : (
              <div className="p-d-flex p-flex-1 p-ai-center p-jc-center">
                <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                  Nenhum. Adicione medicamentos utilizando a barra de pesquisa.
                </SimpleText>
              </div>
            )}
          </div>

          <div className="prescricao-footer p-col-12 p-grid p-ai-center">
            {formList.getValues().prescricaoMedicamentos?.length >= 2 && (
              <FormInput
                name="descricao"
                label="Título"
                className="p-col-12"
                maxLength={40}
                hideTextErrorSpace
              />
            )}

            <FieldVia name="via" label="Via" className="p-col-12 p-sm-3" />
            <FormInput
              name="posologia"
              label="Posologia"
              maxLength={40}
              className="p-col-12 p-sm-6"
            />

            <div className="p-col-12 p-sm-3">
              <Button
                label="Adicionar aos Favoritos"
                type="button"
                btnType="tonal"
                onClick={formList.handleSubmit(v => onSubmit(v, 'favorite'))}
                disabled={!formList.getValues().prescricaoMedicamentos.length}
                loading={formList.formState.isSubmitting}
                stretch
              />
            </div>
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              type="button"
              onClick={() => close()}
              btnType="ghost"
              disabled={formList.formState.isSubmitting}
              stretch
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              disabled={!formList.getValues().prescricaoMedicamentos.length}
              loading={formList.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const PrescricaoContentRow = ({
  item,
  idx,
  handleRemove,
}: IPrescricaoRowProps) => {
  const { isTablet } = useSize();
  const isEditing = useDisclosure({ opened: false });

  const form = useFormContext();

  const fieldQtdeError = form.getFieldState(
    `prescricaoMedicamentos.${idx}.quantidade`,
  ).error?.message;

  const watchQtde = form.watch(`prescricaoMedicamentos.${idx}.quantidade`);

  return (
    <div className="row p-ai-center">
      <SimpleText className="p-col-6 p-md-4 p-text-climp-2">
        {item?.descricao}
      </SimpleText>
      {!isTablet && (
        <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>Laboratorio</SimpleText>
          <SimpleText>{item?.laboratorio || '-'}</SimpleText>
        </div>
      )}

      {isEditing.isOpen ? (
        <div className="p-col-3 p-md-2 p-d-flex p-flex-column p-ai-center p-gap-1">
          <FormInput
            autoFocus
            className="light"
            name={`prescricaoMedicamentos.${idx}.quantidade`}
            type="number"
            hideTextErrorSpace
            max={99}
            classNameInput="p-w-100"
          />
        </div>
      ) : (
        <div className="p-col-2 p-md-2 p-d-flex p-jc-center">
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText
              fontColor={
                !!fieldQtdeError ? FONT_COLOR.DANGER : FONT_COLOR.COLOR_60
              }
            >
              Qtde.
            </SimpleText>
            <SimpleText
              fontColor={
                !!fieldQtdeError ? FONT_COLOR.DANGER : FONT_COLOR.PRIMARY
              }
            >
              {watchQtde || 0}
            </SimpleText>
          </div>
        </div>
      )}

      {isEditing.isOpen ? (
        <div className="p-d-flex p-col p-jc-end p-p-0 p-gap-1">
          <Button
            className="slide-left"
            icon="fa fa-check"
            type="button"
            btnType="gray"
            onClick={() => isEditing.close()}
          />
          <Button
            icon="fa fa-close"
            type="button"
            btnType="gray"
            onClick={() => [
              isEditing.close(),
              form.setValue(
                `prescricaoMedicamentos.${idx}.quantidade`,
                item?.quantidade || 0,
              ),
            ]}
          />
        </div>
      ) : (
        <div className="p-d-flex p-col p-jc-end p-p-0 p-gap-1">
          <Button
            icon="fa fa-edit"
            type="button"
            btnType="gray"
            onClick={e => [isEditing.open(), e.preventDefault()]}
          />
          <Button
            icon="fa fa-trash"
            type="button"
            onClick={() => handleRemove()}
            btnType="gray"
          />
        </div>
      )}
    </div>
  );
};
