import { useMemo } from 'react';

import useSize from 'src/core/hooks/useSize';

import { utcToShow } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

import './Compromissos.scss';
interface Props {
  data: any;
  tipos_compormissos: any;
  handleEdit: (data: any) => void;
  handleDuplicate: (data: any) => void;
  handleDelete: (data: any) => void;
  isLastRow: boolean;
}

const DesktopRow = ({
  data,
  tipos_compormissos,
  handleDelete,
  handleDuplicate,
  handleEdit,
  isLastRow,
}: Props) => {
  const { windowInnerWidth } = useSize();

  const isLg = useMemo(() => windowInnerWidth >= 1176, [windowInnerWidth]);

  return (
    <div className="p-d-flex p-flex-column p-gap-1">
      <div className="grid-list-item listitem">
        <div className="p-col-3">
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {tipos_compormissos[data.tipoCompromisso]}
          </SimpleText>
        </div>
        <div className="p-col-3">
          <SimpleText fontSize={FONT_SIZE.XXS}>{data.descricao}</SimpleText>
        </div>
        <div className="p-col-2">
          <i className="fas fa-calendar p-mr-2 data-icon"></i>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {utcToShow(new Date(data.dataInicio))}
          </SimpleText>
        </div>
        <div className={`p-col-${isLg ? '3' : '2'} p-px-1 `}>
          <i className="fas fa-calendar p-mr-2 data-icon"></i>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {utcToShow(new Date(data.dataFim))}
          </SimpleText>
        </div>
        <div className={`p-col-${isLg ? '1' : '2'} p-px-0 p-d-flex`}>
          <>
            <Can checkPermission="AGE_COMPROMISSO_ALTERAR">
              <Button
                btnType="ghost"
                icon="pi pi-pencil"
                onClick={() => handleEdit(data)}
              />
            </Can>
            <Can checkPermission="AGE_COMPROMISSO_CADASTRAR">
              <Button
                btnType="ghost"
                icon="fas fa-clone"
                onClick={() => handleDuplicate(data)}
              />
            </Can>
            <Can checkPermission="AGE_COMPROMISSO_EXCLUIR">
              <Button
                btnType="ghost"
                icon="fas fa-trash"
                onClick={() => handleDelete(data)}
              />
            </Can>
          </>
        </div>
      </div>

      {!isLastRow && <Divider layout="horizontal" className="p-p-0 p-m-0" />}
    </div>
  );
};

export default DesktopRow;
