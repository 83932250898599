import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import DisponibilidadeMedicaAPI from 'src/APIs/AgendaAPI/DisponibilidadeMedica/DisponibilidadeMedicaAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import IconButton, {
  IconBtnSizes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import GridListLoading from 'src/components/GridList/GridListLoading';

import './DisponibilidadeMedica.scss';

const DisponibilidadeMedica = ({ close }: DisclosureType) => {
  const { user, consultorios, agenda } = useAppSelector(
    (state: RootState) => state,
  );
  const { control, handleSubmit, setValue } = useForm();

  const menu = useRef<TieredMenu>(null);

  const [profissionalSelecionado, setProfissionalSelecionado] = useState(
    agenda?.profissionalAtivo,
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [especialidades, setEspecialidades] = useState<DisponibilidadeMedica[]>(
    [],
  );

  const getDisponibilidade = useCallback(
    async (value?: any) => {
      if (!value && !profissionalSelecionado) return;

      setLoading(true);

      if (value) {
        setProfissionalSelecionado(value);
      }

      const response = await DisponibilidadeMedicaAPI.getDisponibilidadeMedica(
        value ? value?.id : profissionalSelecionado.id,
        consultorios.ativo?.id,
      );

      if (response?.status === 400) {
        setProfissionalSelecionado(null);
      }

      if (Array.isArray(response) && !!response.length) {
        setEspecialidades(response);

        response?.map((item: DisponibilidadeMedica) => {
          return (
            item?.dataDisponibilidade &&
            setValue(
              String(item?.especialidade?.id),
              new Date(item?.dataDisponibilidade),
            )
          );
        });
      }

      setLoading(false);
    },
    [profissionalSelecionado, consultorios.ativo?.id, setValue],
  );

  useEffect(() => {
    getDisponibilidade();
  }, [getDisponibilidade]);

  const handleFormSubmit = useCallback(
    async data => {
      setSubmitting(true);
      const listDiponibilidadeMedica = Object.entries(data).map(
        ([idEspecialidade, dataDisponibilidade]: any) => ({
          idEspecialidade,
          dataDisponibilidade:
            dataDisponibilidade &&
            dayjs(dataDisponibilidade).format('YYYY-MM-DDTHH:mmZ'),
        }),
      );

      const payload = {
        listDiponibilidadeMedica,
        idProfissionalSaude: agenda.profissionalAtivo?.id,
        idConsultorio: consultorios.ativo?.id,
      };

      const response = await DisponibilidadeMedicaAPI.postDisponibilidadeMedica(
        payload,
      );

      !response.status && close();

      setSubmitting(false);
    },
    [agenda.profissionalAtivo?.id, consultorios.ativo?.id, close],
  );

  const renderFooter = useCallback(() => {
    return (
      <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
        <Button
          className="p-col-align-center"
          label="Fechar"
          btnType="ghost"
          onClick={() => close()}
          stretch
        />
        <Button
          type="submit"
          label="Salvar"
          onClick={handleSubmit(handleFormSubmit)}
          loading={submitting}
          stretch
        />
      </div>
    );
  }, [handleFormSubmit, handleSubmit, close, submitting]);

  const menuItems = useMemo(() => {
    return (agenda?.profissionaisDisponiveis || []).map((prof: any) => ({
      label: prof.nome,
      command: (e: any) => [getDisponibilidade(prof), menu.current?.toggle(e)],
      disabled: prof?.id === profissionalSelecionado?.id,
    }));
  }, [
    agenda?.profissionaisDisponiveis,
    getDisponibilidade,
    profissionalSelecionado,
  ]);

  return (
    <Dialog
      header="Disponibilidade médica"
      modal={false}
      visible
      onHide={close}
      className="modal-disponibilidade"
      type="modal"
      footer={renderFooter}
      maximizedMobileSize
    >
      <div className="disponibilidade-container">
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XS}>
          Liste a disponibilidade do médico abaixo nas seguintes especialidades
        </SimpleText>
        <div className="p-d-flex p-mt-5 p-mb-3">
          <CustomImage
            width="54px"
            className="p-mr-1"
            src={'perfil_secretaria.png'}
          />

          <div className="p-grid column p-mt-1">
            <SimpleText fontSize={FONT_SIZE.XS} bold>
              {profissionalSelecionado?.nome || '-'}
            </SimpleText>

            <SimpleText
              className="p-mt-2"
              fontColor={FONT_COLOR.COLOR_40}
              fontSize={FONT_SIZE.XXS}
            >
              {profissionalSelecionado?.profissionalSaude?.conselhoTiss
                ?.sigla || ''}
              /
              {profissionalSelecionado?.profissionalSaude?.numeroRegistro || ''}
            </SimpleText>
          </div>

          {user.tipoProfissionalSaude !== 'MEDICO' && (
            <>
              <IconButton
                icon="pi pi-chevron-down"
                disabled={menuItems.length < 1 || !profissionalSelecionado}
                btnSize={IconBtnSizes.SMALL}
                onClick={event => menu?.current?.toggle(event)}
              />

              <TieredMenu
                className="panel-options-paciente"
                ref={menu}
                model={menuItems}
                popup
              />
            </>
          )}
        </div>

        <SimpleText
          fontColor={FONT_COLOR.COLOR_40}
          fontSize={FONT_SIZE.XS}
          bold
        >
          Especialidades
        </SimpleText>

        <form className="p-mt-3">
          {loading && <GridListLoading />}

          {!loading && !especialidades.length && (
            <SimpleText fontColor={FONT_COLOR.COLOR_60}>
              Nenhuma especialidade cadastrada para esse profissional de saúde.
            </SimpleText>
          )}

          {!loading &&
            especialidades?.map(especialidades => (
              <CalendarInputControlled
                key={especialidades.id}
                className="p-mt-3"
                name={String(especialidades?.especialidade?.id)}
                label={especialidades?.especialidade?.nome}
                placeholder="dd/mm/aaaa"
                minDate={new Date()}
                control={control}
              />
            ))}
        </form>
      </div>
    </Dialog>
  );
};

export default DisponibilidadeMedica;
