import { useEffect } from 'react';

import { toast } from 'react-toastify';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';

import Divider from 'src/components/Basics/Divider/Divider';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';

const wsdlLinks = [
  'urlCancelaGuia',
  'urlLoteGuias',
  'urlSolicitacaoDemonstrativoRetorno',
  'urlSolicitacaoProcedimento',
  'urlSolicitacaoStatusAutorizacao',
  'urlSolicitacaoStatusProtocoloRecurso',
  'urlSolicitacaoStatusProtocolo',
  'urlVerificaElegibilidade',
  'urlRecursoGlosa',
];

interface TissProps {
  convenioTiss: any;
}

export const Tiss = (props: TissProps) => {
  const { convenioTiss } = props;

  const { onCopy, hasCopied } = useCopyClipboard();

  useEffect(() => {
    if (hasCopied) {
      toast(<Toast />, {
        data: {
          title: 'Sucesso!',
          message: 'Link copiado com sucesso!',
          type: 'success',
        },
      });
    }
  }, [hasCopied]);

  if (!convenioTiss?.id) return null;

  const renderLinkWsdl = (linkName: string) => {
    if (!convenioTiss?.[linkName]) return null;

    return (
      <div key={linkName} className="link-wsdl-item">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          {convenioTiss[linkName]}
        </SimpleText>

        <Divider layout="vertical" />

        <IconButton
          icon="fas fa-clone"
          btnType={IconBtnTypes.GREY}
          onClick={() => onCopy(convenioTiss[linkName])}
        />
      </div>
    );
  };

  return (
    <div className="p-mt-5">
      <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
        Versão TISS: {convenioTiss?.versaoTiss}
      </SimpleText>

      {wsdlLinks.map(wsdlLink => renderLinkWsdl(wsdlLink))}
    </div>
  );
};
