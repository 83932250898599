import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './ModalListaAlergias.scss';

interface ModalListaAlergiasProps {
  modalLista: AlergiaDTO[];
  onHide: () => void;
}

function ModalListaAlergias({ modalLista, onHide }: ModalListaAlergiasProps) {
  return (
    <Dialog
      type="modal"
      header={`Lista de alergias e intolerâncias ativas (${modalLista.length})`}
      visible={!!modalLista.length}
      onHide={onHide}
      maximizedTabletSize
    >
      <>
        <div id="prontuario-header-lista-container">
          <div className="p-mb-2">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Tipo - Descrição
            </SimpleText>
          </div>

          <div className="p-grid">
            {modalLista.map((alergia, idx) => (
              <div
                key={alergia.id}
                className="card-modal-lista p-grid p-col-12"
              >
                <div className="p-col-12 p-sm-8">
                  <Tooltip target={`.alergia-${idx}`} />
                  <SimpleText
                    className={`alergia-${idx}`}
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_16}
                    medium
                    data-pr-tooltip={alergia?.descricao}
                    data-pr-position="top"
                  >
                    {capitalizeFirstLetter(
                      alergia.tipoAlergia || alergia.tipoIntolerancia,
                    )}{' '}
                    - {alergia.descricao}
                  </SimpleText>
                </div>
                <SimpleText
                  className="p-col-12 p-sm-4 p-wordbreak-normal"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  Adicionado em{' '}
                  {dayjs(alergia.dataInclusao).format('DD/MM/YYYY [às] HH:mm')}
                </SimpleText>
              </div>
            ))}
          </div>
        </div>

        <Button
          className="p-mt-4"
          btnType="outline"
          label="Fechar"
          onClick={onHide}
          stretch
        />
      </>
    </Dialog>
  );
}

export default ModalListaAlergias;
