import { useMemo, useState } from 'react';

import { useNavigate } from 'react-router';
import { useSubscription } from 'react-stomp-hooks';

import dayjs from 'dayjs';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useSessionStorage } from 'src/utils/hooks/useLocalStorage';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

import ChipAgendamento from 'src/pages/Emed/Agenda/MenuAgenda/ChipAgendamento/ChipAgendamento';

import GerenciarGrupo from '../../../MenuAgenda/GerenciarGrupo/GerenciarGrupo';

import AtendimentoEstado from './AtendimentoEstado/AtendimentoEstado';

import './CardProximoPaciente.scss';

interface CardProximoPacienteProps {
  atendimento: AtendimentoAgendamento;
  children: JSX.Element;
}

function CardProximoPaciente({
  atendimento,
  children,
}: CardProximoPacienteProps) {
  const { setValue } = useSessionStorage('folhaRostoProntuario');

  const [isOnline, setIsOnline] = useState(false);

  const gerenciarGrupoPacientes = useDisclosure({ opened: false });

  const isAtendimentoGrupo = atendimento.idGrupoAtendimento;

  const horarioAgendamento = useMemo(
    () => atendimento.dataAgendamento?.split('T')[1].slice(0, 5),
    [atendimento.dataAgendamento],
  );

  const tempoChegadaDescricao = useMemo(() => {
    if (!atendimento.dataPresenca) return '-';

    const date = dayjs(new Date());
    const tempo = date.diff(atendimento.dataPresenca, 'minute') || '0';

    return `Presente há ${tempo} min`;
  }, [atendimento.dataPresenca]);

  const horaChegada = useMemo(
    () =>
      atendimento.dataPresenca
        ? dayjs(atendimento.dataPresenca).format('HH:mm')
        : '--:--',
    [atendimento.dataPresenca],
  );

  const navigate = useNavigate();

  const handleAtender = () => {
    setValue({
      exibirFolhaRosto:
        !!atendimento?.convenio?.exibirCuidadorViverBemFolhaRosto,
    });
    navigate(`/prontuario`, {
      state: {
        idAtendimento: atendimento.id,
      },
    });
  };

  const isAtendimentoAtual =
    dayjs(atendimento.dataAgendamento).format('DD/MM/YYYY') ===
    dayjs().format('DD/MM/YYYY');

  useSubscription('/user/topic/notificacao', (message: any) => {
    const data = JSON.parse(message.body);
    const idAtendimento = data?.dadosComplementares?.idAtendimento;

    if (
      idAtendimento &&
      Number(idAtendimento) === atendimento.id &&
      isAtendimentoAtual
    ) {
      setIsOnline(true);
    }
  });

  return (
    <div className="card-proximo-paciente p-d-flex p-flex-column p-jc-between">
      <SimpleText fontSize={FONT_SIZE.MD} fontColor={FONT_COLOR.COLOR_16}>
        {horarioAgendamento}
      </SimpleText>

      <AtendimentoEstado estado={atendimento.status} />

      <div id="proximo-paciente-flags-container" className="p-d-flex">
        {atendimento?.flagsAtivas.map((flag, idx) => (
          <ChipAgendamento key={idx} type={flag} />
        ))}
        {isOnline && atendimento?.flagsAtivas?.includes('TELECONSULTA') && (
          <div className="paciente-online-chip">
            <SimpleText>Paciente Online</SimpleText>
          </div>
        )}
      </div>

      <div className="p-d-flex p-flex-column p-gap-2">
        <SimpleText
          lines={1}
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.PRIMARY}
          medium
        >
          {atendimento?.paciente?.nome || atendimento?.apelidoGrupo}
        </SimpleText>

        {isAtendimentoGrupo && atendimento?.quantidadePaciente && (
          <SimpleText
            lines={1}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {atendimento.quantidadePaciente} pacientes
          </SimpleText>
        )}

        {atendimento.descricaoEspecialidade && (
          <SimpleText
            lines={1}
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_40}
          >
            {atendimento.descricaoEspecialidade}
          </SimpleText>
        )}
      </div>

      <div className="p-d-flex p-ai-center p-jc-between">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_79}>
          <i className="fas fa-clock" /> {horaChegada}
        </SimpleText>

        {/* //* Do not render SIGILO when ATENDIMENTO is in group */}
        {!isAtendimentoGrupo && children}
      </div>

      {isAtendimentoGrupo ? (
        <Can checkPermission="PRO_VISUALIZAR_PRONTUARIO">
          {can => (
            <Button
              btnType={BtnTypes.FILLED}
              label="Gerenciar grupo"
              onClick={() => gerenciarGrupoPacientes.open()}
              disabled={!can}
            />
          )}
        </Can>
      ) : (
        <Can checkPermission="PRO_VISUALIZAR_PRONTUARIO">
          {can => (
            <Button
              btnType={BtnTypes.FILLED}
              label="Ver prontuário"
              onClick={() => handleAtender()}
              disabled={!can}
            />
          )}
        </Can>
      )}

      <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
        {tempoChegadaDescricao}
      </SimpleText>

      {gerenciarGrupoPacientes.isOpen && (
        <GerenciarGrupo
          agendamento={atendimento}
          checkbox={false}
          selectedDate={new Date()}
          {...gerenciarGrupoPacientes}
        />
      )}
    </div>
  );
}

export default CardProximoPaciente;
