import { Button } from '../_UI';
import SimpleText from '../Basics/SimpleText/SimpleText';

import './Tab.scss';

export const TabHeaderTemplate = ({
  badge,
  label,
  onClick,
  onClickIcon,
  isActive,
}: {
  badge?: string | false;
  label: string;
  onClick: () => void;
  onClickIcon?: (() => void) | undefined;
  isActive: boolean;
}) => {
  if (!!badge) {
    return (
      <Button
        btnType="ghost"
        className="btn-ghost-default p-m-1"
        label={label}
        onClick={onClick}
        {...(!!badge && { badge })}
      />
    );
  }

  return (
    <div
      className="Tab-custom-header-container"
      style={{
        position: 'relative',
      }}
    >
      <div
        data-isActive={isActive}
        className="Tab-custom-header p-d-flex p-ai-center p-gap-2 p-py-2"
        onClick={() => onClick()}
      >
        <SimpleText fontSize="xxs" fontColor="color_40" medium>
          {label}
        </SimpleText>
        {onClickIcon && (
          <i className="fas fa-circle-info" onClick={onClickIcon} />
        )}
      </div>

      <span
        className="Tab-custom-header-border-active"
        data-hasInfo={!!onClickIcon}
        data-isActive={isActive}
      />
    </div>
  );
};
