import dayjs from 'dayjs';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Lembrete from 'src/pages/Emed/Prontuario/components/Lembrete/Lembrete';

interface PanelHeaderProps {
  motivoAtendimento: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export const PanelHeader = ({
  motivoAtendimento,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) => {
  const headerTextClassName =
    motivoAtendimento.status === 'INATIVO' ? 'p-mt-2 item-inativo' : 'p-mt-2';

  return (
    <div className="p-grid p-col-12 p-align-center">
      <div className="coluna-nome p-col-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Motivo
        </SimpleText>
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {motivoAtendimento.tipoMotivoAtendimento.descricao}
        </SimpleText>
      </div>

      <div className="coluna-dados p-col-3 p-md-3 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data de ocorrência
        </SimpleText>
        <SimpleText
          className={headerTextClassName}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {dayjs(motivoAtendimento.dataOcorrencia).format('DD/MM/YYYY  HH:mm')}
        </SimpleText>
      </div>

      <div className="coluna-dados p-col-3 p-md-3 retroativo-chip">
        {motivoAtendimento.retroativo && (
          <SimpleText fontSize={FONT_SIZE.XXXS}>Retroativo</SimpleText>
        )}
      </div>

      <div className="coluna-acoes p-col-3 p-md-3 p-d-flex p-jc-end p-ai-center">
        <Lembrete ativo={motivoAtendimento.lembrete} />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
};
