import React, { useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';

import './ModalResumoGuia.scss';

export const ModalResumoGuia = ({ ...props }: DisclosureType) => {
  return (
    <Dialog
      onHide={props.close}
      visible={props.isOpen}
      className="modal-resumo-guia"
      header="Resumo guia"
    >
      <ErrorBoundary>
        <ModalResumoGuiaContent {...props} />
      </ErrorBoundary>
    </Dialog>
  );
};

const ModalResumoGuiaContent = ({ close }: DisclosureType) => {
  const [guias, setGuias] = useState(['a']);

  return (
    <div className="resumo-guia-content">
      <Accordion>
        {guias.map((item, idx) => (
          <AccordionTab key={idx} header={<ModalResumoGuiaAccordionHeader />}>
            <ModalResumoGuiaAccordionContent />
          </AccordionTab>
        ))}
      </Accordion>

      <Button label="Fechar" stretch btnType="ghost" onClick={() => close()} />
    </div>
  );
};

const ModalResumoGuiaAccordionHeader = () => {
  const tag = 'AUTORIZADO';

  const tagType: { [key: string]: string } = {
    AUTORIZADO: 'autorizado',
    EM_ANALISE: 'emAnalise',
    PARCIALMENTE: 'parcialmente',
    NEGADO: 'negado',
  };

  return (
    <div className="p-grid p-col-12 p-ai-center p-gap-4 p-py-0">
      <SimpleText className="p-flex-1">Guia 00000000000</SimpleText>
      <SimpleText className={`tag tag-${tagType[tag]}`}>Autorizado</SimpleText>
    </div>
  );
};

const ModalResumoGuiaAccordionContent = () => {
  const { isMobile } = useSize();

  const [items, setItems] = useState(['a', 'b']);

  const alertBox = useDisclosure({ opened: false });

  return (
    <>
      <div className="resumo-guia-accordion-content p-grid p-col-12 p-ai-center">
        {items.map((item, idx) => (
          <React.Fragment key={idx}>
            {!isMobile && (
              <SimpleText className="p-col-2" fontColor={FONT_COLOR.COLOR_40}>
                41009995
              </SimpleText>
            )}
            <SimpleText className="p-col-6 p-text-truncate">
              Angiotomografia arterial de Tórax (Pacote)
            </SimpleText>

            <div className="p-col-3 p-md-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                Qtd. Solicitada
              </SimpleText>
              <SimpleText bold> 01</SimpleText>
            </div>
            <div className="p-col-3 p-md-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_40}>
                Qtd. Aprovada
              </SimpleText>
              <SimpleText bold> 01</SimpleText>
            </div>
          </React.Fragment>
        ))}
      </div>

      <AlertBox
        text="Retorno na glossa"
        type="DANGER"
        visible={alertBox.isOpen}
        onHide={() => alertBox.close()}
      />
    </>
  );
};
