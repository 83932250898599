import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

function checkAuth(authorities: string[], checkPermission: string | string[]): boolean {
  if (Array.isArray(checkPermission)) {
    return checkPermission.some(permission => authorities?.includes(permission));
  }
  
  if (checkPermission && !authorities?.includes(checkPermission)) {
    return false;
  }

  return true;
}

interface CanProps {
  checkPermission: string | string[];
  children: JSX.Element | ((checkAuth: boolean) => JSX.Element);
}

function Can({ children, checkPermission }: CanProps) {
  const { user: { authorities } } = useAppSelector((state: RootState) => state);

  if (typeof children === 'function') {
    return children(checkAuth(authorities, checkPermission));
  }

  return checkAuth(authorities, checkPermission) ? children : <></>;
}

export default Can;