import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './HistoricoEdicoes.scss';

const HistoricoEdicoes = () => {
  return (
    <div id="historico-edicoes-paciente" className="p-col-12 p-lg-6">
      {/*<SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
        Histórico de edições
      </SimpleText>

      <div className="card-visualizacao-paciente p-grid">
        <SimpleText
          className="p-col-3"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Data
        </SimpleText>
        <SimpleText
          className="p-col"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Tipo de operação
        </SimpleText>
        <SimpleText
          className="p-col"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Campo
        </SimpleText>
        <SimpleText
          className="p-col"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Valor anterior
        </SimpleText>
        <SimpleText
          className="p-col"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_60}
        >
          Valor novo
        </SimpleText>

        <InfiniteScroll
          fetchAPI={fetchAPI}
          renderRow={renderRow}
        />
  </div>*/}
    </div>
  );
};

export default HistoricoEdicoes;
