import { useCallback, useEffect, useState } from 'react';

import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { useSelectedFaturamentos } from '../useSelectedFaturamentos';

import PanelContent from './PanelContent';
import PanelHeader from './PanelHeader';

interface ListRowProps {
  item: any;
}

const ListRow = ({ item }: ListRowProps) => {
  const { selectedFaturamentos, setSelectedFaturamentos } =
    useSelectedFaturamentos();
  const [valorReceber, setValorReceber] = useState(item.valorReceber);

  const calculoValorGlosaTotal = (obj: any): number => {
    let sum = 0;

    if (obj.valorGlosa !== null) {
      sum += obj.valorGlosa;
    }

    obj.servicosAutogerados?.map((servico: any) => {
      if (servico.valorGlosa !== null) {
        sum += servico.valorGlosa;
      }
    });

    return sum;
  };

  const calculoValorTotal = (obj: any): number => {
    let sum = 0;

    if (obj.valor !== null) {
      sum += obj.valor;
    }

    obj.servicosAutogerados?.map((servico: any) => {
      if (servico.valor !== null) {
        sum += servico.valor;
      }
    });

    return sum;
  };

  const calculoValorTotalReceber = useCallback(() => {
    const totalGlosa = calculoValorGlosaTotal(item);
    const totalValor = calculoValorTotal(item);

    setValorReceber(totalValor - totalGlosa);
  }, [item]);

  useEffect(() => {
    calculoValorTotalReceber();
  }, [calculoValorTotalReceber]);

  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            item={item}
            selectedFaturamentos={selectedFaturamentos}
            setSelectedFaturamentos={setSelectedFaturamentos}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            valorReceber={valorReceber}
          />
        )}
      >
        <PanelContent
          item={item}
          calculoValorTotalReceber={calculoValorTotalReceber}
        />
      </Panel>
    </div>
  );
};

export default ListRow;
