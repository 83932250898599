import Http, { HttpParams } from 'src/core/http/Http';

type Options = Omit<HttpParams, 'service' | 'url'>;

const loadAvaliacoesFormularioDinamico = async (
  params: {
    idEspecialidade: number;
    idConsultorio: number;
    idConvenio: number;
  },
  options?: Options,
): Promise<IAplicacaoFormularioDinamicoItem[]> => {
  return Http.get({
    service: 'pep',
    url: `/avaliacao-formulario-dinamico/aplicacoes-formulario/especialidade/${params.idEspecialidade}/consultorio/${params.idConsultorio}/convenio/${params.idConvenio}`,
    hideToast: true,
    ...options,
  });
};
const loadHistoricoFormularioDinamico = async (
  params: {
    idAplicacaoFormularioDinamico: number;
    idProfissionalSaude?: number;
    idPaciente: number;
    sortDirection?: 'ASC' | 'DESC';
    sortBy?: string;
    page?: number;
    pageSize?: number;
  },
  options?: Options,
): Promise<Paginator<IAvaliacaoFormularioDinamicoItem[]>> => {
  return Http.get({
    service: 'pep',
    url: `/avaliacao-formulario-dinamico/historico`,
    params,
    hideToast: true,
    ...options,
  });
};
const loadFormularioDinamicoById = async (
  params: {
    id: number;
  },
  options?: Options,
): Promise<IAvaliacaoFormularioDinamicoItem> => {
  return Http.get({
    service: 'pep',
    url: `/avaliacao-formulario-dinamico/${params.id}`,
    params,
    hideToast: true,
    ...options,
  });
};
const createAvaliacoesFormularioDinamico = async (
  payload: IPayloadAvaliacaoFormularioDinamico,
  options?: Options,
): Promise<IAplicacaoFormularioDinamicoItem[]> => {
  return Http.post({
    service: 'pep',
    url: `/avaliacao-formulario-dinamico`,
    data: payload,
    ...options,
  });
};
const updateAvaliacoesFormularioDinamico = async (
  payload: IPayloadAvaliacaoFormularioDinamico & { id: number },
  options?: Options,
): Promise<IAplicacaoFormularioDinamicoItem[]> => {
  return Http.put({
    service: 'pep',
    url: `/avaliacao-formulario-dinamico/${payload.id!}`,
    data: payload,
    ...options,
  });
};
const inativarAvaliacaoFormularioDinamico = async (
  id: number,
  justificativa: string,
  options?: Options,
): Promise<IAplicacaoFormularioDinamicoItem[]> => {
  return Http.put({
    service: 'pep',
    url: `/avaliacao-formulario-dinamico/${id}/inativar`,
    data: {
      justificativa,
    },
    ...options,
  });
};

export const AvaliacaoFormularioDinamicoAPI = {
  loadAvaliacoesFormularioDinamico,
  loadHistoricoFormularioDinamico,
  createAvaliacoesFormularioDinamico,
  loadFormularioDinamicoById,
  updateAvaliacoesFormularioDinamico,
  inativarAvaliacaoFormularioDinamico,
};
