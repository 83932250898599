import { useState } from 'react';

import { ComplexidadePacienteAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/ComplexidadePacienteAPI.ts/ComplexidadePacienteAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface ComplexidadeStatusProps {
  idComplexidade: number;
  isAtivo: boolean;
}

export const ComplexidadeStatus = (props: ComplexidadeStatusProps) => {
  const { idComplexidade, isAtivo } = props;
  const [ativo, setAtivo] = useState(isAtivo);
  const [loading, setLoading] = useState(false);

  const onSwitchStatus = async () => {
    setLoading(true);
    const response = await ComplexidadePacienteAPI.putStatusComplexidade(
      idComplexidade,
    );
    console.log(response);
    if (response?.status === 200) {
      setAtivo(response.data);
    }

    setLoading(false);
  };

  if (loading)
    return (
      <div className="p-mb-4 p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return (
    <Switch
      checked={ativo}
      showStatus
      onChange={onSwitchStatus}
      checkPermission="ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_ALTERAR"
    />
  );
};
