import { useParams } from 'react-router';

import ListaPlanoSaude from '../../components/ListaPlanoSaude/ListaPlanoSaude';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PlanoSaude.scss';

const PlanoSaude = () => {
  const { idPaciente } = useParams();

  return (
    <div
      id="plano-saude-manter-paciente"
      className="p-grid p-col-12 p-md-11 p-lg-10 p-xl-8"
    >
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40} medium>
        Lista de planos de saúde
      </SimpleText>

      <ListaPlanoSaude idPaciente={Number(idPaciente)} />
    </div>
  );
};

export default PlanoSaude;
