import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface MobileRowProps {
  motivo: GetManterTrasferenciaApsDTO;
  onEdit: (motivo: GetManterTrasferenciaApsDTO) => void;
  onDelete: (id: number) => void;
  onSwitchStatus: (motivo: GetManterTrasferenciaApsDTO) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { motivo, onEdit, onDelete, onSwitchStatus } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(motivo),
      checkPermission: 'ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_ALTERAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(motivo.id),
      checkPermission: 'ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_EXCLUIR',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Motivo
        </SimpleText>
        <br />
        <SimpleText
          className="p-wordbreak-all"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {motivo.nome}
        </SimpleText>
      </div>

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Status
        </SimpleText>
        <br />

        <Switch
          showStatus
          checked={motivo?.status}
          onChange={() => onSwitchStatus(motivo)}
          checkPermission="ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_ALTERAR"
        />
      </div>
    </SearchListMobileRow>
  );
};
