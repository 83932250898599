import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import MedicoAPI from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';
import RelatoriosAPI from 'src/APIs/AdminAPI/RelatoriosAPI/RelatoriosAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { handleDownloadXLS, handleOpenPDF } from 'src/utils/files';
import { dateToRequest } from 'src/utils/utils';

import { Checkbox } from 'src/components/_UI/Checkbox';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { InputText } from 'src/components/Basics/Input';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { FieldSelectConsultorios } from 'src/components/Fields/FieldSelectConsultorios/FieldSelectConsultorios';
import { FieldSelectMedicos } from 'src/components/Fields/FieldSelectMedicos/FieldSelectMedicos';
import Separator from 'src/components/Separator/Separator';
import './RelatoriosAgenda.scss';

import './RelatoriosAgenda.scss';

const RelatoriosAgenda = () => {
  const {
    user,
    consultorios: { ativo, disponiveis },
    agenda,
  } = useAppSelector((state: RootState) => state);

  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [medicosConsultorio, setMedicosConsultorio] = useState<Medico[]>([]);

  const formValidation = Yup.object().shape({
    periodo: Yup.array()
      .test(
        'oneOfRequired',
        'É necessário adicionar o periodo de tempo, com data inicial e final',
        function (item: any) {
          let response = false;
          if (item) {
            response = item[1] !== null;
          }

          return response;
        },
      )
      .typeError(
        'É necessário adicionar o periodo de tempo, com data inicial e final',
      ),
  });

  useEffect(() => {
    if (ativo?.id)
      MedicoAPI.loadMedicosById(ativo.id).then(setMedicosConsultorio);
  }, [ativo?.id]);

  const checkboxFields = useMemo(() => {
    const data = [
      { name: 'LIVRE', label: 'Livre' },
      { name: 'PRESENTE', label: 'Presente' },
      { name: 'AGENDADO', label: 'Agendado' },
      { name: 'ATENDIDO', label: 'Atendido' },
      { name: 'ATENDENDO', label: 'Atendendo' },
      { name: 'CONTINUAR', label: 'Continuar' },
      { name: 'BLOQUEADO', label: 'Bloqueado' },
      { name: 'TRIAGEM', label: 'Triagem' },
      { name: 'TRIAGEM_FINALIZADA', label: 'Triagem Finalizada' },
      { name: 'PRESCRICAO', label: 'Prescrição' },
      { name: 'PRESCRICAO_FINALIZADA', label: 'Prescrição Finalizada' },
      { name: 'FALTOU', label: 'Faltou' },
      { name: 'CANCELADO', label: 'Cancelado' },
      { name: 'NEGADO_OPERADORA', label: 'Negado Operadora' },
    ];

    return !!search.length
      ? data.filter(item => item.name.includes(search.toUpperCase()))
      : data;
  }, [search]);

  const form = useForm({
    resolver: yupResolver(formValidation),
  });

  const handleRequestReport = useCallback(async () => {
    const {
      periodo,
      medicos = [],
      consultorios: consultoriosSelected,
      type: typeExport,
      ...values
    } = form.getValues();

    const idsConsultorios = (
      consultoriosSelected?.length > 0 ? consultoriosSelected : disponiveis
    ).map((con: any) => con.id);

    const medicosSelecionados = medicos?.map((medico: any) => medico.id);

    const status = Object.keys(values).filter(key => !!values[key]);

    if (!status.length) {
      return setErrorStatus(true);
    } else setErrorStatus(false);

    const aux: any = {
      dataInicio: dateToRequest(periodo[0]),
      dataFim: dateToRequest(periodo[1]),
      idsConsultorios,
      listStatus: status,
      ...(user.tipoUsuario === 'SECRETARIO' || user.tipoUsuario === 'SECRETARIA'
        ? {
            idsMedicos:
              medicosSelecionados.length > 0
                ? medicosSelecionados
                : medicosConsultorio.map(medico => medico.id),
          }
        : { idsMedicos: [agenda?.profissionalAtivo?.id] }),
    };

    await RelatoriosAPI.requestReportAgenda(aux, typeExport).then(function (
      response,
    ) {
      if (typeExport === 'pdf' && response) {
        handleOpenPDF(response, 'rel_agenda');
      } else {
        handleDownloadXLS(response, 'rel_agenda');
      }
    });
  }, [
    agenda?.profissionalAtivo?.id,
    disponiveis,
    form,
    medicosConsultorio,
    user.tipoUsuario,
  ]);

  const handleSetAllChecked = useCallback(
    (e: boolean) => {
      if (e) {
        setChecked(e);
        checkboxFields.forEach(box => form.setValue(box.name, true));
      } else {
        setChecked(e);
        checkboxFields.forEach(box => form.setValue(box.name, false));
      }
    },
    [checkboxFields, form],
  );

  if (!ativo?.id) {
    return (
      <div className="p-py-2 p-px-4">
        <AlertBox
          visible={true}
          text="Você precisa selecionar um Consultório para esta funcionalidade!"
        />
      </div>
    );
  }

  return (
    <>
      <div id="relatorio-agenda p-col-12">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleRequestReport)}
            className="p-grid p-col-12 p-xl-5 p-md-8"
          >
            <CalendarInputControlled
              name="periodo"
              control={form.control}
              hideOnDateTimeSelect
              showIcon
              label="Período"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              selectionMode="range"
              className="p-col-12 p-px-0"
            />
            <FieldSelectConsultorios
              name="consultorios"
              label="Consultório"
              className="p-col-12 p-px-0"
            />

            {user.tipoUsuario === 'SECRETARIO' && (
              <FieldSelectMedicos
                name="medicos"
                label="Médicos"
                className="p-col-12 p-px-0"
              />
            )}

            <SimpleText className="p-col-12" bold>
              Situação
            </SimpleText>

            <div className="form-relatorio-situacao p-col-12 p-grid">
              <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
                Filtre os resultados utilizando o campo de pesquisa abaixo.
              </SimpleText>

              <InputText
                placeholder="Pesquisar"
                icon="fa fa-search"
                className="p-col-12"
                onChange={e => setSearch(e.target.value)}
                value={search}
                hideTextErrorSpace
              />

              <Checkbox
                onChange={e => handleSetAllChecked(e.checked)}
                label="Selecionar todos"
                className="p-col-12"
                checked={checked}
              />

              <Separator className="p-col-12 p-my-1" />

              <div className="p-col-12 p-grid checkboxes-content">
                {checkboxFields.map(item => (
                  <CheckboxControlled
                    key={item.name}
                    control={form.control}
                    name={item.name}
                    label={item.label}
                    className="p-col-6"
                  />
                ))}
              </div>
              {errorStatus && (
                <SimpleText className="p-col-12 error">
                  Ao menos uma situação deve ser selecionada!
                </SimpleText>
              )}
            </div>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
              className="p-col-12 p-mt-4"
            >
              Para concluir, gere o relatório optando pelo formato.
            </SimpleText>

            <div className="p-col-12 p-d-flex p-ai-center p-p-0 p-gap-1 p-mb-4">
              <Button
                btnType={BtnTypes.TONAL}
                label="Abrir em PDF"
                className="p-col-12 p-md-6"
                stretch
                type="submit"
                onClick={() => form.setValue('type', 'pdf')}
                loading={form.formState.isSubmitting}
              />
              <Button
                btnType={BtnTypes.TONAL}
                label="Baixar XLS (Excel)"
                className="p-col-6"
                stretch
                type="submit"
                onClick={() => form.setValue('type', 'excel')}
                loading={form.formState.isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default RelatoriosAgenda;
