import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import { ListaMateriaisPrescricao } from 'src/models/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';

import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

type IProps = {
  mat: ListaMateriaisPrescricao;
  idx: number;
};

export const MateriaisRow = ({ mat, idx }: IProps) => {
  const form = useFormContext();

  useEffect(() => {
    form.setValue(`prescricaoMateriais.${idx}.aplicado`, !!mat.dataAplicacao);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mat.dataAplicacao]);

  return (
    <div key={idx} className="row p-d-flex p-ai-center p-col-12 p-p-0">
      <CheckboxControlled
        name={`prescricaoMateriais.${idx}.aplicado`}
        className="p-col"
        control={form.control}
      />
      <SimpleText className="p-col-7 p-text-climp-2">{mat.nome}</SimpleText>
      <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>Qtde.</SimpleText>
        <SimpleText fontColor={FONT_COLOR.PRIMARY}>{mat.quantidade}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-1 p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Data da aplicação
        </SimpleText>
        <SimpleText>
          {mat.dataAplicacao
            ? dayjs(mat.dataAplicacao).format('DD/MM/YYYY HH:mm')
            : '-'}
        </SimpleText>
      </div>
    </div>
  );
};
