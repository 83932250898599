import { useCallback, useEffect, useMemo, useState } from 'react';

import { GestaoMonitoramentoFormularioAPI } from 'src/APIs/AdminAPI/GestaoMonitoramentoFormularioAPI/GestaoMonitoramentoFormularioAPI';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { formatCPF } from 'src/utils/utils';

import { Button, Checkbox } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { useGestaoMonitoramento } from './hooks/GestaoMonitoramentoContext';

export const PacientesSearch = () => {
  const [situacao, setSituacao] = useState('ATIVO');
  const [nome, setNome] = useState('');

  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  const [count, setCount] = useState({ count: 0, totalCount: 0 });

  const {
    filters,
    selectedAllPacientes,
    setSelectedAllPacientes,
    handleClearPacientes,
  } = useGestaoMonitoramento();

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);

      const data =
        await GestaoMonitoramentoFormularioAPI.loadPacientesFormularioAplicacao(
          {
            idsAplicacao: filters.idsAplicacao!,
            nome,
            ...(situacao !== 'TODOS' && {
              excluido: situacao === 'INATIVO',
            }),
            orderBy: 'nome',
          },
          { throwError: true },
        );

      setItems(data.list);
      setCount({ count: data.count, totalCount: data.totalCount });
    } catch {
    } finally {
      setLoading(false);
    }
  }, [filters.idsAplicacao, nome, situacao]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  const handleInput = useThrottle(setNome, 150);

  return (
    <div
      id="plano-search"
      className="p-col-12 p-md-6 p-pl-0 p-d-flex p-flex-column"
    >
      <SimpleText className="p-col-12" medium fontSize={FONT_SIZE.SM}>
        Pacientes atrelados a aplicação dos formulários
      </SimpleText>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Liste os paciente que fazem parte de uma aplicação de formulário.
      </SimpleText>

      <div className="p-col-12 p-mb-2">
        <TextInputSearch
          onChange={handleInput}
          value={nome}
          placeholder="Pesquise pelo nome do paciente"
        />
      </div>

      <div className="p-w-100 p-py-2 p-d-flex p-gap-2 p-ai-center p-gap-2">
        <SelectButton
          onChange={e => setSituacao(e.value)}
          value={situacao}
          options={[
            {
              label: 'Ativo',
              value: 'ATIVO',
            },
            {
              label: 'Inativo',
              value: 'INATIVO',
            },
            {
              label: 'Todos',
              value: 'TODOS',
            },
          ]}
        />
        <SimpleText
          fontColor={FONT_COLOR.COLOR_60}
          className="p-text-end p-wordbreak-normal"
        >
          Exibindo {count.count} registros de {count.totalCount}
        </SimpleText>
      </div>

      <div className="p-w-100 p-px-2 p-pb-1 p-d-flex p-jc-between">
        <Checkbox
          label="Selecionar todos"
          checked={selectedAllPacientes}
          onChange={e => setSelectedAllPacientes(e.checked)}
        />
        <Button
          btnType="ghost"
          label="Limpar"
          onClick={() => [
            handleClearPacientes(),
            setSelectedAllPacientes(false),
          ]}
        />
      </div>

      <div className="plano-border-container p-px-2">
        <div className="p-d-flex p-col-12">
          <div
            style={{
              width: '40px',
            }}
          />
          <SimpleText
            className="p-col-5"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Nome / Documento
          </SimpleText>
          <SimpleText
            className="p-col-5"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Número Cartão
          </SimpleText>
        </div>

        <div className="content-items p-d-flex p-flex-column p-gap-2 p-pb-2">
          {isLoading ? (
            <LoadingComponent />
          ) : !!items.length ? (
            items.map(item => <PacientesRow key={item.id} item={item} />)
          ) : (
            <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-py-2">
              <SimpleText>
                Nenhum paciente para o plano foi encontrado.
              </SimpleText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PacientesRow = ({ item }: { item: any }) => {
  const { setSelectedPacientes, selectedPacientes, selectedAllPacientes } =
    useGestaoMonitoramento();

  const handleCheck = (e: boolean) => {
    setSelectedPacientes(e, item.id);
  };

  const isChecked = useMemo(
    () => !!selectedPacientes.find(i => i === item.id),
    [selectedPacientes, item],
  );

  return (
    <div
      onClick={() => {}}
      className={`item p-d-flex p-col-12 ${isChecked ? 'selected' : ''}`}
    >
      <Checkbox
        checked={isChecked}
        onChange={e => handleCheck(e.checked)}
        style={{
          width: '40px',
        }}
        disabled={selectedAllPacientes}
      />
      <div className="p-col-5 p-d-flex p-flex-column p-gap-2 p-jc-center">
        <SimpleText>{item?.nome}</SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          {formatCPF(item?.cpf || '')}
        </SimpleText>
      </div>
      <div className="p-col-5 p-d-flex p-flex-column p-gap-2">
        <SimpleText>{item.cartaoPrincipal?.numeroCartao || '-'}</SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          {item.cartaoPrincipal?.convenio?.nome}
        </SimpleText>
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  const props = {
    loading: true,
    height: '40px',
    borderRadius: '8px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};
