import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/pacientes';

export const pacientesPesquisaCompleta = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa-completa`,
    params,
    hideToast: true,
    ...options,
  });

export const visualizarPaciente = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/visualizar`,
    hideToast: true,
    ...options,
  });

export const getPacienteDadosObrigatorios = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/dadosObrigatorios`,
    hideToast: true,
    ...options,
  });

export const createPaciente = (
  data: any,
  hideToast: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: basePath,
    data,
    hideToast,
    ...options,
  });

export const pacientesSemelhantes = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'adm',
    url: `${basePath}/cadastro-rapido/semelhantes`,
    data: payload,
    hideToast: true,
    ...options,
  });
};

export const updatePaciente = (
  data: any,
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}`,
    data,
    return: true,
    ...options,
  });

export const getPacienteFoto = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/foto`,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });

export const updatePacienteFoto = (
  idPaciente: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/foto`,
    data,
    ...options,
  });

export const getPacienteContato = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/contato`,
    hideToast: true,
    ...options,
  });

export const updatePacienteContato = (
  idPaciente: number,
  data: any,
  hideToast: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/contato`,
    data,
    hideToast,
    ...options,
  });

export const getPacienteDadosPessoais = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/dadosPessoaisDocumentos`,
    hideToast: true,
    ...options,
  });

export const updatePacienteDadosPessoais = (
  idPaciente: number,
  data: any,
  hideToast: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/dadosPessoaisDocumentos`,
    data,
    hideToast,
    ...options,
  });

export const getPacienteEnderecoCompleto = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/endereco-completo`,
    hideToast: true,
    ...options,
  });

export const updatePacienteEnderecoCompleto = (
  idPaciente: number,
  data: any,
  hideToast: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/endereco-completo`,
    data,
    hideToast,
    ...options,
  });

export const getPacienteDadosProfissionais = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `${basePath}/${idPaciente}/dadosProfissionais`,
    hideToast: true,
    ...options,
  });

export const updatePacienteDadosProfissionais = (
  idPaciente: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/dadosProfissionais`,
    data,
    ...options,
  });

export const removePaciente = (
  idPaciente: number,
  hideToast?: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'adm',
    url: `${basePath}/${idPaciente}`,
    return: true,
    hideToast: hideToast || false,
    ...options,
  });

export const ativarPaciente = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/ativar`,
    return: true,
    ...options,
  });

export const inativarPaciente = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'adm',
    url: `${basePath}/${idPaciente}/inativar`,
    return: true,
    ...options,
  });

export const loadPacientesPorConvenios = (
  params: {
    chave?: string;
    idsConvenio: any;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any>> =>
  Http.get({
    service: 'adm',
    params,
    url: `${basePath}/por-convenio`,
    hideToast: true,
    ...options,
  });

const PacienteAPI = {
  pacientesPesquisaCompleta,
  visualizarPaciente,
  getPacienteDadosObrigatorios,
  createPaciente,
  pacientesSemelhantes,
  updatePaciente,
  getPacienteFoto,
  updatePacienteFoto,
  getPacienteContato,
  updatePacienteContato,
  getPacienteDadosPessoais,
  updatePacienteDadosPessoais,
  getPacienteEnderecoCompleto,
  updatePacienteEnderecoCompleto,
  getPacienteDadosProfissionais,
  updatePacienteDadosProfissionais,
  removePaciente,
  ativarPaciente,
  inativarPaciente,
  loadPacientesPorConvenios,
};

export default PacienteAPI;
