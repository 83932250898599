import { useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { toast } from 'react-toastify';

import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';

export const DetalhesTeleconsulta = ({ agendamento }: any) => {
  const [isLoading, setLoading] = useState(false);
  const { onCopy, hasCopied } = useCopyClipboard();

  const ref = useRef<OverlayPanel>(null);

  const handleResendLink = () => {
    setLoading(true);
    TelemedicinaSalaAtendimentoAPI.sendPacienteLinkTelemedicina({
      idAtendimento: agendamento.id,
    })
      .then(() => ref.current?.hide())
      .finally(() => setLoading(false));
  };

  useEffectSkipFirst(() => {
    if (hasCopied)
      toast(<Toast />, {
        data: {
          message: 'Link copiado com sucesso!',
          type: 'success',
        },
      });
  }, [hasCopied]);

  return (
    <div className="row-component p-col-12 p-d-flex p-ai-center">
      <SimpleText
        className="p-col-1 p-d-flex p-jc-center"
        fontColor="primary"
        fontSize="lg"
      >
        <i className="fas fa-arrow-up-right-from-square" />
      </SimpleText>
      <div className="p-col-8 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="primary" fontSize="sm" medium>
          Sala para atendimento
        </SimpleText>
        <SimpleText fontColor="color_60">
          {agendamento.linkSalaEsperaTelemedicina ||
            agendamento.linkSalaTelemedicina}
        </SimpleText>
      </div>
      <div className="p-col-3 p-jc-end p-d-flex p-gap-1">
        <Button
          icon="fas fa-copy"
          btnType="gray"
          onClick={() =>
            onCopy(
              agendamento.linkSalaEsperaTelemedicina ||
                agendamento.linkSalaTelemedicina,
            )
          }
          disabled={
            !agendamento.linkSalaEsperaTelemedicina &&
            !agendamento.linkSalaTelemedicina
          }
        />
        <Button
          icon="fas fa-share"
          btnType="gray"
          onClick={e => ref.current?.toggle(e)}
        />
      </div>

      <OverlayPanel
        ref={ref}
        style={{
          width: '340px',
        }}
      >
        <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize="md">Reenviar link</SimpleText>
          <SimpleText>
            Você deseja enviar novamente o link da sala de atendimento para o
            e-mail pessoal do paciente?
          </SimpleText>
          <Button
            className="p-mt-2"
            label="Reenviar"
            onClick={handleResendLink}
            loading={isLoading}
          />
        </div>
      </OverlayPanel>
    </div>
  );
};
