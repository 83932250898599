import { useCallback, useEffect, useMemo, useState } from 'react';

import '../Formularios.scss';
import dayjs from 'dayjs';
import { AvaliacaoFormularioDinamicoAPI } from 'src/APIs/ProntuarioAPI/AvaliacaoFormularioDinamicoAPI/AvaliacaoFormularioDinamicoAPI';

import {
  AvaliacaoFormularioItemHistorico,
  mergeAnswersEvolucao,
  mergeOptionsByDate,
} from './utils';

import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { FormulariosContentHeader } from '../FormulariosContentHeader';
import { useFormulario } from '../useFormulariosContext';

import { LoadingComponent } from './DialogHistorico';

export const DialogHistoricoEvolucao = () => {
  const { selectedAplicacaoFormulario, prontuario } = useFormulario();

  const [isLoading, setIsLoading] = useState(true);
  const [historico, setHistorico] = useState<
    IAvaliacaoFormularioDinamicoItem[]
  >([]);

  const fetchHistorico = useCallback(async () => {
    if (!prontuario || !selectedAplicacaoFormulario) return;

    try {
      const response =
        await AvaliacaoFormularioDinamicoAPI.loadHistoricoFormularioDinamico(
          {
            idAplicacaoFormularioDinamico: selectedAplicacaoFormulario.id,
            idPaciente: prontuario.atendimento.paciente.id,
            sortDirection: 'DESC',
            sortBy: 'dataInclusao',
            pageSize: 20,
          },
          { throwError: true },
        );
      setHistorico(response.list);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [prontuario, selectedAplicacaoFormulario]);

  const perguntasMapped = useMemo((): AvaliacaoFormularioItemHistorico[] => {
    if (!historico) return [];

    const perguntasFormatted = historico.flatMap(perg => {
      return perg.respostas || [];
    });
    const data: any = mergeAnswersEvolucao(perguntasFormatted);

    return Object.values(data);
  }, [historico]);

  useEffect(() => {
    fetchHistorico();
  }, [fetchHistorico]);

  return (
    <div className="FormularioContent p-d-flex p-flex-column p-gap-2 p-w-100">
      {selectedAplicacaoFormulario?.formularioDinamico && (
        <FormulariosContentHeader
          formulario={selectedAplicacaoFormulario.formularioDinamico}
        />
      )}

      {isLoading && <LoadingComponent />}

      {!isLoading &&
        !!perguntasMapped.length &&
        perguntasMapped.map((perg, idx) => {
          return (
            <div
              key={idx}
              className="row content-row white p-p-2 p-flex-column"
            >
              <SimpleText className="p-p-2" fontSize="sm" medium>
                {perg.perguntaFormularioDinamico.enunciado}
              </SimpleText>
              <Divider layout="horizontal" className="p-my-2" />

              <HistoryAnswerSection perg={perg} />
            </div>
          );
        })}
    </div>
  );
};

const HistoryAnswerSection = ({
  perg,
}: {
  perg: AvaliacaoFormularioItemHistorico;
}) => {
  const type = perg.perguntaFormularioDinamico.tipoResposta;
  const options = type === 'MULTIPLA_ESCOLHA' ? mergeOptionsByDate(perg) : [];

  return (
    <>
      {['TEXTO'].includes(type) && (
        <div
          className="p-col-12 p-d-flex p-flex-column p-gap-2"
          style={{
            overflowX: 'auto',
          }}
        >
          {perg.resposta.map((item, index, arr) => {
            return (
              <div
                key={item.id}
                className="p-col-12 p-d-flex p-flex-column p-gap-2"
              >
                <SimpleText fontSize="xxxs" fontColor="color_60">
                  {dayjs(item.dataInclusao).format('DD/MM/YYYY')}
                </SimpleText>
                <SimpleText>{item.descricao || item.textoResposta}</SimpleText>

                {arr.length - 1 !== index && (
                  <Divider layout="horizontal" className="p-p-0 p-m-0" />
                )}
              </div>
            );
          })}
        </div>
      )}
      {['DATA'].includes(type) && (
        <div
          className="p-col-12 p-d-flex p-gap-2"
          style={{
            overflowX: 'auto',
          }}
        >
          {perg.resposta.map((item, index) => (
            <div key={index} className="p-col-2 p-d-flex p-flex-column p-gap-2">
              <SimpleText fontSize="xxxs" fontColor="color_60">
                {dayjs(item.dataInclusao).format('DD/MM/YYYY')}
              </SimpleText>
              <SimpleText>
                {item.textoResposta
                  ? dayjs(item.textoResposta).format(
                      item.formatoData?.toUpperCase() || 'DD/MM/YY',
                    )
                  : ''}
              </SimpleText>
            </div>
          ))}
        </div>
      )}
      {['NUMERO', 'ESCOLHA'].includes(type) && (
        <div
          className="p-col-12 p-d-flex p-gap-2"
          style={{
            overflowX: 'auto',
          }}
        >
          {perg.resposta.map((item, index) => (
            <div key={index} className="p-col-2 p-d-flex p-flex-column p-gap-2">
              <SimpleText fontSize="xxxs" fontColor="color_60">
                {dayjs(item.dataInclusao).format('DD/MM/YYYY')}
              </SimpleText>
              <SimpleText>{item.descricao || item.textoResposta}</SimpleText>
            </div>
          ))}
        </div>
      )}
      {['MULTIPLA_ESCOLHA'].includes(type) && (
        <div
          className="p-col-12 p-d-flex p-gap-2"
          style={{
            overflowX: 'auto',
          }}
        >
          {options.map((item, index) => (
            <div key={index} className="p-col-2 p-d-flex p-flex-column p-gap-2">
              <SimpleText fontSize="xxxs" fontColor="color_60">
                {dayjs(item.dataInclusao).format('DD/MM/YYYY')}
              </SimpleText>
              <div className="p-d-flex p-flex-column p-gap-2">
                {item.descricao.map((desc, idx) => (
                  <SimpleText key={idx}>{desc}</SimpleText>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
