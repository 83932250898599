export function validateMainPosition(context: any, { result, fallback }: any) {
  let value = context?.tick?.label;

  if (!value) return fallback;

  if (typeof value === 'object') value = value.join(' ');

  return value.includes('ano') || value.includes('anos') ? result : fallback;
}

export const pacienteSexoCor: { [key: string]: string } = {
  MASCULINO: '#5C9AF7',
  FEMININO: '#FF38A9',
};

export const initializeChartFromIdade = (idadeEmAnos: number) => {
  if (idadeEmAnos <= 2) return 0;
  return 1;
};
export const initializeChartPesoFromIdade = (idadeEmAnos: number) => {
  if (idadeEmAnos < 2) return 0;
  if (idadeEmAnos < 5) return 1;
  else return 2;
};

export function validateDaysToMonth(value: number, idadeFinal?: number) {
  // Não considera anos bissextos
  // switch (value) {
  //   case 59:
  //     return '2';
  //   case 120:
  //     return '4';
  //   case 181:
  //     return '6';
  //   case 243:
  //     return '8';
  //   case 304:
  //     return '10';
  //   case 365:
  //     return ['1', 'ano'];
  //   case 424:
  //     return '2';
  //   case 485:
  //     return '4';
  //   case 546:
  //     return '6';
  //   case 608:
  //     return '8';
  //   case 669:
  //     return '10';
  //   case 730:
  //     return ['2', 'anos'];
  //   case 820:
  //     return '3';
  //   case 910:
  //     return '6';
  //   case 1000:
  //     return '9';
  //   case 1090:
  //     return ['3', 'anos'];
  //   case 1180:
  //     return '3';
  //   case 1270:
  //     return '6';
  //   case 1360:
  //     return '9';
  //   case 1450:
  //     return ['4', 'anos'];
  //   case 1540:
  //     return '3';
  //   case 1630:
  //     return '6';
  //   case 1720:
  //     return '9';
  //   case 1810:
  //     return ['5', 'anos'];
  //   default:
  //     return null;
  // }

  if (idadeFinal === 24) {
    switch (value) {
      case 59:
        return '2';
      case 120:
        return '4';
      case 181:
        return '6';
      case 243:
        return '8';
      case 304:
        return '10';
      case 365:
        return ['1', 'ano'];
      case 424:
        return '2';
      case 485:
        return '4';
      case 546:
        return '6';
      case 608:
        return '8';
      case 669:
        return '10';
      case 730:
        return ['2', 'anos'];

      default:
        return null;
    }
  }

  if (idadeFinal === 60) {
    switch (value) {
      case 730:
        return ['2', 'anos'];
      case 820:
        return '3';
      case 910:
        return '6';
      case 1000:
        return '9';
      case 1090:
        return ['3', 'anos'];
      case 1180:
        return '3';
      case 1270:
        return '6';
      case 1360:
        return '9';
      case 1450:
        return ['4', 'anos'];
      case 1540:
        return '3';
      case 1630:
        return '6';
      case 1720:
        return '9';
      case 1810:
        return ['5', 'anos'];
      default:
        return null;
    }
  }

  if (idadeFinal === 120) {
    switch (value) {
      case 61:
        return ['5', 'anos'];
      case 63:
        return '3';
      case 66:
        return '6';
      case 69:
        return '9';

      case 72:
        return ['6', 'anos'];

      case 75:
        return '3';
      case 78:
        return '6';
      case 81:
        return '9';

      case 84:
        return ['7', 'anos'];
      case 87:
        return '3';
      case 90:
        return '6';
      case 93:
        return '9';

      case 96:
        return ['8', 'anos'];
      case 99:
        return '3';
      case 102:
        return '6';
      case 105:
        return '9';

      case 108:
        return ['9', 'anos'];
      case 111:
        return '3';
      case 114:
        return '6';
      case 117:
        return '9';

      case 120:
        return ['10', 'anos'];
      default:
        return null;
    }
  }

  if (idadeFinal === 228) {
    switch (value) {
      case 61:
        return ['5'];

      case 72:
        return ['6'];

      case 84:
        return ['7'];

      case 96:
        return ['8'];

      case 108:
        return ['9'];

      case 120:
        return ['10'];

      case 132:
        return ['11'];

      case 144:
        return ['12'];

      case 156:
        return ['13'];

      case 168:
        return ['14'];

      case 180:
        return ['15'];

      case 192:
        return ['16'];

      case 204:
        return ['17'];

      case 216:
        return ['18'];

      case 228:
        return ['19'];

      default:
        return null;
    }
  }

  // Considerando valores de retorno
  // for (let el = 24; el > 0; el--) {
  //   const idadeEmDias = dayjs(new Date(2022, el, 30)).diff(
  //     new Date(2022, 1, 0),
  //     'day',
  //   );
  //   console.log(el, idadeEmDias);
  // }

  switch (value) {
    case 1:
      return '0';
    case 58:
      return '2';
    case 119:
      return '4';
    case 180:
      return '6';
    case 242:
      return '8';
    case 303:
      return '10';
    case 364:
      return ['1', 'ano'];
    case 423:
      return '2';
    case 484:
      return '4';
    case 545:
      return '6';
    case 607:
      return '8';
    case 668:
      return '10';
    case 729:
      return ['2', 'anos'];

    default:
      return null;
  }
}
