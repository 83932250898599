import useSize from 'src/core/hooks/useSize';

import { transformarEmMoeda } from 'src/utils/currency';
import { formatarData } from 'src/utils/date';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onHistorico: (data: any) => void;
  onSwitchStatus: (data: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { data, onEdit, onDelete, onHistorico, onSwitchStatus } = props;

  const { isTablet } = useSize();

  return (
    <CardItem>
      <div className="p-col-3">
        <SimpleText
          className="p-d-block p-mb-1"
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XS}
        >
          {data?.servicoTuss?.codigo}
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XXS}>
          {data?.servicoTuss?.nome}
        </SimpleText>
      </div>
      <div className="p-col-2">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {data?.servicoTuss?.convenio?.nomeFantasia ||
              data?.servicoTuss?.convenio?.razaoSocial}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {data?.profissionalSaude?.nome}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-1">
        <div>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {transformarEmMoeda(data?.valor)}
          </SimpleText>
        </div>
      </div>

      <>
        {!isTablet && (
          <div className="p-col-1">
            <SimpleText fontSize={FONT_SIZE.XXS}>
              {formatarData(data?.dataInicioVigencia)}
            </SimpleText>
          </div>
        )}

        {!isTablet && (
          <div className="p-col-1">
            <div>
              <Switch
                checked={data?.status}
                onChange={onSwitchStatus}
                checkPermission="ADM_SERVICOS_FATURAMENTO_ALTERAR"
              />
            </div>
          </div>
        )}
      </>

      <div className="p-col-2 p-d-flex p-jc-start p-px-0">
        <IconButton
          icon="pi pi-history"
          btnType={IconBtnTypes.ICON_ONLY}
          onClick={() => onHistorico(data)}
        />
        <IconButton
          btnType={IconBtnTypes.ICON_ONLY}
          icon="pi pi-pencil"
          onClick={() => onEdit(data)}
          checkPermission="ADM_SERVICOS_FATURAMENTO_ALTERAR"
        />
        <IconButton
          icon="pi pi-trash"
          btnType={IconBtnTypes.ICON_ONLY}
          onClick={() => onDelete(data)}
          checkPermission="ADM_SERVICOS_FATURAMENTO_EXCLUIR"
        />
      </div>
    </CardItem>
  );
};
