import { memo, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { PlanoDeCuidadoCreate } from 'src/pages/Emed/Prontuario/PlanoDeCuidado/Create/PlanoDeCuidadoCreate';

import { PlanosPacientesRowContent } from './PlanosPacientesRowContent';
import { PlanosPacientesRowHeader } from './PlanosPacientesRowHeader';

type IProps = {
  item: any;
  type: 'PRONTUARIO' | 'HISTORICO' | 'GESTAO';
};

export const PlanosRow = memo(({ item, ...props }: IProps) => {
  const [percentual, setPercentual] = useState(item.percentualConclusao ?? 0);

  const edicaoDialog = useDisclosure({ opened: false });

  const isInativo = item.status === 'INATIVO';
  const className = isInativo ? 'inativo' : '';

  return (
    <>
      <Accordion id="acc-plano-list" className={className}>
        <AccordionTab
          header={
            <PlanosPacientesRowHeader
              item={item}
              isInativo={isInativo}
              edicaoDialog={edicaoDialog}
              percentual={percentual}
              {...props}
            />
          }
        >
          <PlanosPacientesRowContent
            setPercentual={setPercentual}
            item={item}
            {...props}
          />
        </AccordionTab>
      </Accordion>

      {edicaoDialog.isOpen && <PlanoDeCuidadoCreate {...edicaoDialog} />}
    </>
  );
});
