import React from 'react';
import { useCallback, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import { MedicamentosAPI } from 'src/APIs/ProntuarioAPI/MedicamentosAPI/MedicamentosAPI';
import { ProntuarioMedicamento } from 'src/models/APIs/ProntuarioAPI/MedicamentosAPI/MedicamentosAPI';

import useSize from 'src/core/hooks/useSize';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useThrottle } from 'src/utils/hooks/useThrottle';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './FieldSearchMedicamentos.scss';

type FieldSearchMedicamentosProps = {
  onAdd: (items: any[]) => void;
};

type IMedicamentosRow = {
  setItems: (old?: any) => void;
  item: ProntuarioMedicamento;
};

export const FieldSearchMedicamentos = ({
  onAdd,
}: FieldSearchMedicamentosProps) => {
  const { isTablet } = useSize();
  const searchPanelRef = useRef<OverlayPanel>(null);
  const inputRef = useRef<any>();

  const [inputValue, setInputValue] = useState<string>('');
  const [items, setItems] = useState<ProntuarioMedicamento[]>([]);
  const [itemsSelected, setItemsSelected] = useState<ProntuarioMedicamento[]>(
    [],
  );

  const [loadingQuery, setLoadingQuery] = useState(false);

  const inputSearch = useThrottle(setInputValue, 500);

  const fetchAPI = async (queryParam: string) => {
    setLoadingQuery(true);

    MedicamentosAPI.loadMedicamentos(
      { descricao: queryParam },
      { throwError: true },
    )
      .then(data => [setItems(data.list)])
      .catch(() => {})
      .finally(() => setLoadingQuery(false));
  };

  useEffectSkipFirst(() => {
    fetchAPI(inputValue);
  }, [inputValue]);

  const handleAdd = () => {
    setInputValue('');
    inputRef.current?.clear();

    onAdd(itemsSelected);

    setItemsSelected([]);
  };

  useEffectSkipFirst(() => {
    if (inputValue && !searchPanelRef.current?.state.visible) {
      searchPanelRef.current?.setState({ visible: true });
    }
  }, [inputValue]);

  return (
    <div className="p-w-100 p-grid">
      <TextInputSearch
        ref={inputRef}
        aria-controls="overlay_panel"
        placeholder="Pesquisar por descrição, princípio ativo ou marca"
        onChange={e => inputSearch(e)}
        value={inputValue}
        onClick={e => searchPanelRef.current?.show(e, e.target)}
      />

      <OverlayPanel
        id="overlay_panel"
        appendTo="self"
        className="panel-search-medicamentos"
        ref={searchPanelRef}
      >
        <div className="p-w-100">
          {!!items.length && !loadingQuery ? (
            <React.Fragment>
              <div className="p-d-flex p-col-12 p-p-0 p-ai-center">
                <div style={{ minWidth: '30px' }} />
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  bold
                  className="p-col-2 p-md-1"
                >
                  Código
                </SimpleText>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_60}
                  bold
                  className="p-col-8 p-md-6"
                >
                  Medicamentos
                </SimpleText>
                {!isTablet && (
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_60}
                    bold
                    className="p-col-2"
                  >
                    Laboratório
                  </SimpleText>
                )}
                {!isTablet && (
                  <SimpleText
                    fontColor={FONT_COLOR.COLOR_60}
                    bold
                    className="p-col-2"
                  >
                    Princípio ativo
                  </SimpleText>
                )}
              </div>

              <div
                style={{ maxHeight: '300px', overflowY: 'scroll' }}
                className="fav-content"
              >
                {items.map((item: any, idx: number) => (
                  <MedicamentosRow
                    item={item}
                    setItems={setItemsSelected}
                    key={idx}
                  />
                ))}
              </div>

              {!!itemsSelected.length && (
                <div className="p-py-2">
                  <Button
                    label="Adicionar"
                    stretch
                    onClick={() => [
                      handleAdd(),
                      searchPanelRef.current?.hide(),
                    ]}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="p-d-flex p-jc-center p-my-2">
              {loadingQuery ? (
                <SpinnerLoading full size="sm" />
              ) : (
                <SimpleText>
                  {!!inputValue
                    ? 'Nenhum medicamento encontrado'
                    : 'Começe a digitar para buscar por medicamentos'}
                </SimpleText>
              )}
            </div>
          )}
        </div>
      </OverlayPanel>
    </div>
  );
};

const MedicamentosRow = ({ setItems, item }: IMedicamentosRow) => {
  const { isTablet } = useSize();
  const [checked, setChecked] = useState(false);

  const onSubmit = useCallback(
    (event = true) => {
      if (event) {
        setItems((old: any) => {
          return [...old, item];
        });
      } else {
        setItems((old: any) => {
          const data = old.filter((value: any) => value.id !== item.id);

          return [...data];
        });
      }
    },
    [setItems, item],
  );

  return (
    <div className="row">
      <div className="p-d-flex p-col-12 p-ai-center p-py-0">
        <div className="w-30" onClick={e => e.stopPropagation()}>
          <Checkbox
            checked={checked}
            onChange={e => [onSubmit(e.checked), setChecked(e.checked)]}
          />
        </div>
        <SimpleText
          fontColor={FONT_COLOR.COLOR_40}
          className="p-col-2 p-md-1 p-p-0 p-text-right"
        >
          {item.codigo}
        </SimpleText>
        <SimpleText className="p-col-8 p-md-6 p-text-truncate">
          {item.nome}
        </SimpleText>

        {!isTablet && (
          <SimpleText className="p-col-2 p-text-truncate">
            {item.detentorRegistroAnvisa}
          </SimpleText>
        )}
        {!isTablet && (
          <SimpleText className="p-col-2 p-text-truncate">
            {item.principioAtivo}
          </SimpleText>
        )}
      </div>
    </div>
  );
};
