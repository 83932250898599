import { PausarAtendimentoPayload } from 'src/models/APIs/AgendaAPI/AtendimentoModels';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/prontuario';

export const iniciarAtendimento = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/iniciar/atendimento/${idAtendimento}`,
    return: true,
    hideToast: true,
    ...options,
  });

export const finalizarAtendimento = (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/finalizar/atendimento/${idAtendimento}`,
    data: payload,
    return: true,
    ...options,
  });

export const finalizarTriagem = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/finalizar/triagem/${idAtendimento}`,
    return: true,
    ...options,
  });

export const pausarAtendimento = (
  payload: PausarAtendimentoPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/pausar/atendimento/${payload.idAtendimento}`,
    data: payload,
    ...options,
    hideToast: true,
    return: true,
    ...options,
  });

export const blurOcorrenciaRetroativa = (
  data: any,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'agenda',
    url: `agendas/atendimento-ocorrencia/${idAtendimento}`,
    data,
    return: true,
    hideToast: true,
    ...options,
  });

export const getProntuarioAssinar = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `assinatura-eletronica/atendimento/${idAtendimento}`,
    return: true,
    hideToast: true,
    ...options,
  });

export const getPinHSM = (
  idProfissional: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'adm',
    url: `profissional-saude/${idProfissional}/hsm`,
    return: true,
    hideToast: true,
    ...options,
  });

export const getPendenciasAtendimento = (
  idAtendimento: number | string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/finalizar-atendimento/pendencias/${idAtendimento}`,
    hideToast: true,
    ...options,
  });

const AtendimentoAPI = {
  iniciarAtendimento,
  finalizarAtendimento,
  finalizarTriagem,
  pausarAtendimento,
  blurOcorrenciaRetroativa,
  getProntuarioAssinar,
  getPinHSM,
  getPendenciasAtendimento,
};

export default AtendimentoAPI;
