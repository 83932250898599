import dayjs from 'dayjs';
require('dayjs/locale/pt-br');

dayjs().format();

export const weekDays = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo',
];

export enum DIA_SEMANA {
  Segunda = 'SEGUNDA',
  Terca = 'TERCA',
  Quarta = 'QUARTA',
  Quinta = 'QUINTA',
  Sexta = 'SEXTA',
  Sabado = 'SABADO',
  Domingo = 'DOMINGO',
}

/**
 * Converte formato 'HH:mm' para tipo Date (javascript)
 */
export const formatHourMinuteToDate = (time: string | Date) => {
  if (time instanceof Date) return time;

  const timeSplit = time.split(':');
  if (timeSplit.length !== 2) return;

  const CURRENT = new Date();
  CURRENT.setHours(Number(timeSplit[0]), Number(timeSplit[1]));

  return CURRENT;
};

export const fullNumbersStringDateToFormatedString = (v: string) => {
  const day: string = v.substring(6, 8);
  const month: string = v.substring(4, 6);
  const year: string = v.substring(0, 4);
  return day + '/' + month + '/' + year;
};

export const dateAndHourToDate = (date: Date, hour: string) => {
  if (!date || !hour) {
    return;
  }
  const aux = new Date(date);
  const horario = (hour || '').split(':');

  if (horario && Array.isArray(horario)) {
    if (horario[0]) {
      aux.setHours(parseInt(horario[0]));
    }
    if (horario[1]) {
      aux.setMinutes(parseInt(horario[1]));
    }
    if (horario[2]) {
      aux.setSeconds(parseInt(horario[2]));
    }
  }
  return aux;
};

export function calcularIdade(dataNascimento: string) {
  if (!dataNascimento) {
    return 'Não tem idade';
  }
  const dataPartes = dataNascimento.split('-');

  let dia_aniversario = parseInt(dataPartes[2]!);
  let mes_aniversario = parseInt(dataPartes[1]!);
  let ano_aniversario = parseInt(dataPartes[0]!);

  const d = new Date();
  const ano_atual = d.getFullYear();
  const mes_atual = d.getMonth() + 1;
  const dia_atual = d.getDate();

  ano_aniversario = +ano_aniversario;
  mes_aniversario = +mes_aniversario;
  dia_aniversario = +dia_aniversario;

  let quantos_anos = ano_atual - ano_aniversario;

  if (
    (mes_atual < mes_aniversario || mes_atual) === mes_aniversario &&
    dia_atual < dia_aniversario
  ) {
    quantos_anos--;
  }

  const idade = quantos_anos < 0 ? 0 : quantos_anos;

  return `${idade} anos`;
}

export const formatarData = (dataRecebida: string) => {
  if (!dataRecebida) return 'Sem data';
  const dataCheiaEmPartes = dataRecebida.split('T');
  const dataEmPartes = dataCheiaEmPartes[0]!.split('-');

  const dia = dataEmPartes[2];
  const mes = dataEmPartes[1];
  const ano = dataEmPartes[0];

  return `${dia}/${mes}/${ano}`;
};

export const formatarDataAgendaPeriodo = (
  dataRecebida: Date,
  quantidadeDiasPeriodo: number,
) => {
  const primeiroDia = dayjs(dataRecebida).format('DD');

  const dataProximoPeriodo = dayjs(dataRecebida).add(
    quantidadeDiasPeriodo,
    'day',
  );

  const segundoDiaFormatado = dayjs(dataProximoPeriodo)
    .locale('pt-br')
    .format('DD');

  const mes = dayjs(dataProximoPeriodo).locale('pt-br').format('MMM');

  return `${primeiroDia} - ${segundoDiaFormatado} de ${mes} ${dayjs(
    dataRecebida,
  )
    .locale('pt-br')
    .format('hh:mm Z')}.`;
};

export function formatarDataHora(data: any, hora: any) {
  const dataHoraCompleta = dayjs(data)
    .set('hour', dayjs(hora).get('hour'))
    .set('minute', dayjs(hora).get('minute'))
    .toISOString();

  return dataHoraCompleta;
}
