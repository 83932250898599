import { useCallback, useEffect, useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';

import './ModalModelo.scss';

interface ModalModeloProps {
  onOpenModal: () => void;
  modelos: any[];
  passData: any;
  valorCampoProntuario: string;
  setValorCampoProntuario: any;
}

const ModalModelo = ({ onOpenModal, modelos, passData }: ModalModeloProps) => {
  const [modelosFiltrados, setModelosFiltrados] = useState<any[]>([]);

  useEffect(() => {
    setModelosFiltrados(modelos);
  }, [modelos]);

  const search = useCallback(
    (e: any) => {
      const filteredModelos = modelos.filter(modelo => {
        return modelo.nome?.toLowerCase().includes(e.toLowerCase());
      });
      setModelosFiltrados(filteredModelos);
    },
    [modelos],
  );

  return (
    <div id="modal-modelo" className="p-d-flex p-flex-column modelos p-p-2">
      <div className="p-d-flex p-jc-between p-ai-center">
        <SimpleText
          className="p-ml-2"
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Modelos
        </SimpleText>
        <Button
          icon="pi pi-save"
          className="p-button-rounded p-button-outlined p-mr-2 save-btn"
          onClick={onOpenModal}
          btnType="tonal"
        />
      </div>

      <div className="p-my-2">
        <TextInputSearch placeholder="Pesquisar modelo" onChange={search} />
      </div>

      <div>
        <SimpleText
          className="p-ml-2 p-mt-4"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Inicialização
        </SimpleText>
        {modelosFiltrados.map((modelo: any, idx) => {
          if (modelo.padrao === true) {
            return (
              <div key={idx} className="p-d-flex p-ai-center p-my-1">
                <Button
                  key={modelo.id}
                  className="p-button-rounded p-button-outlined add-btn p-mr-2"
                  icon="pi pi-plus"
                  onClick={() => passData(modelo)}
                  btnType="gray"
                />
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {modelo.nome}
                </SimpleText>
              </div>
            );
          }

          return <></>;
        })}
      </div>

      <div>
        <SimpleText
          className="p-ml-2 p-mt-4"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Outros
        </SimpleText>
        {modelosFiltrados.map((modelo: any, idx) => {
          if (modelo.padrao === false) {
            return (
              <div key={idx} className="p-d-flex p-ai-center p-my-1">
                <Button
                  key={modelo.id}
                  className="p-button-rounded p-button-outlined add-btn p-mr-2"
                  icon="pi pi-plus"
                  onClick={() => passData(modelo)}
                  btnType="gray"
                />
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {modelo.nome}
                </SimpleText>
              </div>
            );
          }
          return <></>;
        })}
      </div>
    </div>
  );
};

export default ModalModelo;
