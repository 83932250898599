import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/grupos-exames';

const getPacotes = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: basePath,
    hideToast: true,
    throwError: true,
    params,
    ...options,
  });

const getGrupoExameById = async (
  idGrupoExame: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/${idGrupoExame}`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const postGrupoExame = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });

const putGrupoExame = async (
  payload: any,
  idPacote: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idPacote}`,
    data: payload,
    throwError: true,
    ...options,
  });

const deleteGrupoExame = async (
  idGrupoExame: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idGrupoExame}`,
    throwError: true,
    ...options,
  });

export const GruposExamesAPI = {
  getPacotes,
  getGrupoExameById,
  postGrupoExame,
  putGrupoExame,
  deleteGrupoExame,
};
