import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { FieldConvenioByPaciente } from '../components/FieldConvenioPaciente/FieldConvenioPaciente';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';

import BarraLateralProntuario from '../Atendimento/IniciarAtendimento/BarraLateralProntuario/BarraLateralProntuario';
import { Telemedicina } from '../Atendimento/Telemedicina/Telemedicina';

import { PlanoDeCuidadoCreate } from './Create/PlanoDeCuidadoCreate';
import {
  PlanoDeCuidadoProvider,
  usePlanoDeCuidado,
} from './hooks/PlanosDeCuidadoContext';
import { PlanoDeCuidadoContent } from './PlanoDeCuidadoContent';
import './PlanoDeCuidado.scss';

export const PlanoDeCuidado = ({ idPaciente }: any) => {
  const { showBarraLateral, isTeleatendimento } = useAtendimento();

  return (
    <div id="Plano-cuidado-prontuario" className="p-flex-column p-flex-md-row">
      <PlanoDeCuidadoProvider>
        <PlanoDeCuidadoComp />
      </PlanoDeCuidadoProvider>
      <div
        id="barra-lateral-prontuario"
        className={showBarraLateral || isTeleatendimento ? 'p-sm-3' : 'p-sm-1'}
      >
        {isTeleatendimento && <Telemedicina />}
        <BarraLateralProntuario idPaciente={idPaciente} />
      </div>
    </div>
  );
};

const PlanoDeCuidadoComp = () => {
  const { atendimento } = useAppSelector((state: RootState) => state);
  const createPlanoDeCuidado = useDisclosure({ opened: false });
  const { showBarraLateral, atendimentoStatus, paciente } = useAtendimento();

  const { idConvenio, setIdConvenio } = usePlanoDeCuidado();

  const isAtendendo = atendimentoStatus === 'ATENDENDO';
  const isRetornoAndConvenioNotSelected = atendimento.retorno && !idConvenio;

  return (
    <ElevatedCard
      title="Plano de cuidado"
      className={`plano-cuidado-pront-content ${
        showBarraLateral ? 'p-col-12 p-md-9' : 'p-col-12 p-sm-11'
      }`}
    >
      <div className="p-col-12 p-px-0 p-d-flex p-jc-between p-ai-center">
        <SimpleText className="p-col p-px-0" fontSize="sm" medium>
          Plano de cuidado
        </SimpleText>

        <Button
          label="Adicionar"
          icon="fas fa-plus"
          btnType="tonal"
          className="p-col-4 p-md-3 p-lg-2"
          disabled={!isAtendendo || isRetornoAndConvenioNotSelected}
          onClick={() => createPlanoDeCuidado.open()}
        />
      </div>

      {(atendimento.particular || atendimento.retorno) && (
        <FieldConvenioByPaciente
          className="p-col-12 p-md-6 p-lg-4 p-px-0"
          label="Convênio"
          idConvenio={idConvenio}
          idPaciente={paciente?.id}
          onChange={e => setIdConvenio(e.value)}
        />
      )}

      <div className="p-d-flex p-flex-column p-gap-2 p-col-12 p-px-0">
        <SimpleText medium>Plano de cuidado e atividades</SimpleText>
        <SimpleText fontColor="color_40">
          Pesquise pelo planos de cuidado e suas ativadades
        </SimpleText>
      </div>

      <PlanoDeCuidadoContent />

      {createPlanoDeCuidado.isOpen && (
        <PlanoDeCuidadoCreate {...createPlanoDeCuidado} />
      )}
    </ElevatedCard>
  );
};
