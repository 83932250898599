import ComunicadosAPI from 'src/APIs/AdminAPI/ComunicadosAPI/ComunicadosAPI';

export const converterValoresParaOForm = (valores: ComunicadoDTO) => {
  return {
    comunicadoDestinatarios:
      valores.comunicadoDestinatarios?.map(
        destinatario => destinatario.tipoUsuario,
      ) || [],
    comunicadoEspecialidades:
      valores.comunicadoEspecialidades?.map(
        especialidade => especialidade.idEspecialidade,
      ) || [],
    comunicadoConsultorios:
      valores.comunicadoConsultorios?.map(
        consultorios => consultorios.idConsultorio,
      ) || [],
  };
};

export const quantidadeDeUsuarios = async (valores: any) => {
  return ComunicadosAPI.loadQuantidadeDeDestinatarios(valores);
};

export const destinatariosText = (qty: number, destinatarios: any) => {
  let text = `${qty} ${qty === 1 ? 'usuário' : 'usuários'}`;

  if (destinatarios.comunicadoEspecialidades?.length > 0) {
    text += ` em ${destinatarios.comunicadoEspecialidades?.length} ${
      destinatarios.comunicadoEspecialidades?.length === 1
        ? 'especialidade'
        : 'especialidades'
    }`;
  }
  if (destinatarios.comunicadoConsultorios?.length > 0) {
    text += ` em ${destinatarios.comunicadoConsultorios?.length} ${
      destinatarios.comunicadoConsultorios?.length === 1
        ? 'consultório'
        : 'consultórios'
    }`;
  }

  return text + ' selecionados';
};
