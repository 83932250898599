import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelLeucocitosPlaquetasContentProps {
  idGestacao: number;
  historicoReagentes: any;
}

export default function PanelLeucocitosPlaquetasContent({
  idGestacao,
  historicoReagentes,
}: PanelLeucocitosPlaquetasContentProps) {
  return (
    <>
      {historicoReagentes?.leucocitosPrimeiroTrimestre ||
      historicoReagentes?.leucocitosSegundoTrimestre ||
      historicoReagentes?.leucocitosTerceiroTrimestre ||
      historicoReagentes?.plaquetasPrimeiroTrimestre ||
      historicoReagentes?.plaquetasSegundoTrimestre ||
      historicoReagentes?.plaquetasTerceiroTrimestre ? (
        <div className="p-grid">
          {historicoReagentes?.leucocitosPrimeiroTrimestre && (
            <div className="p-grid p-col-12 item-historico p-p-1 p-mb-1">
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Exame
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  LeucocitosPlaquetas
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Trimestre
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  1º Trimestre
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Resultado
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {historicoReagentes?.leucocitosPrimeiroTrimestre}
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Data da inclusão
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {dayjs(new Date(historicoReagentes?.dataInclusao)).format(
                    'DD/MM/YYYY',
                  )}
                </SimpleText>
              </div>
            </div>
          )}
          {historicoReagentes?.leucocitosTerceiroTrimestre && (
            <div className="p-grid p-col-12 item-historico p-p-1 p-mb-1">
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Exame
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  LeucocitosPlaquetas
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Trimestre
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  3º Trimestre
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Resultado
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {historicoReagentes?.leucocitosTerceiroTrimestre}
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Data da inclusão
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {dayjs(new Date(historicoReagentes?.dataInclusao)).format(
                    'DD/MM/YYYY',
                  )}
                </SimpleText>
              </div>
            </div>
          )}
          {historicoReagentes?.plaquetasPrimeiroTrimestre && (
            <div className="p-grid p-col-12 item-historico p-p-1 p-mb-1">
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Exame
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  LeucocitosPlaquetas
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Trimestre
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  1º Trimestre
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Resultado
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {historicoReagentes?.plaquetasPrimeiroTrimestre}
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Data da inclusão
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {dayjs(new Date(historicoReagentes?.dataInclusao)).format(
                    'DD/MM/YYYY',
                  )}
                </SimpleText>
              </div>
            </div>
          )}
          {historicoReagentes?.plaquetasPrimeiroTrimestre && (
            <div className="p-grid p-col-12 item-historico p-p-1 p-mb-1">
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Exame
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  LeucocitosPlaquetas
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Trimestre
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  3º Trimestre
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Resultado
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {historicoReagentes?.plaquetasPrimeiroTrimestre}
                </SimpleText>
              </div>
              <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XXXS}
                  fontColor={FONT_COLOR.COLOR_60}
                  className="p-mb-1"
                >
                  Data da inclusão
                </SimpleText>
                <SimpleText
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  {dayjs(new Date(historicoReagentes?.dataInclusao)).format(
                    'DD/MM/YYYY',
                  )}
                </SimpleText>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="p-d-flex p-ai-center p-jc-center p-p-2">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XXS}
            medium
          >
            Nenhum resultado encontrado
          </SimpleText>
        </div>
      )}
    </>
  );
}
