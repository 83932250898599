import { useState } from 'react';

import { PanelHeaderTemplateOptions } from 'primereact/panel';

import dayjs from 'dayjs';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { ModalAddDataRepeticao } from '../../../components/ModalAddDataRepeticao/ModalAddDataRepeticao';
import { ModalInativar } from '../../../components/ModalInativar/ModalInativar';
import { ModalParecer } from '../../../components/ModalParecer/ModalParecer';
import { ModalUploadLaudo } from '../../../components/ModalUploadLaudo/ModalUploadLaudo';
import { ModalVisualizarLaudo } from '../../../components/ModalVisualizarLaudo/ModalVisualizarLaudo';
import { SelectablePanel } from '../../../components/SelectablePanel/SelectablePanel';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import { HistoricoExamesPanelProcedimentoHeader } from '../HistoricoExamesPanelProcedimentoHeader';
import { HistoricoExamesPanelProcedimentoHeaderBtns } from '../HistoricoExamesPanelProcedimentoHeaderBtns';
import { ModalIncluirResultado } from '../ModalIncluirResultado/ModalIncluirResultado';
import { ModalInformarResultado } from '../ModalInformarResultado';

import './HistoricoExamesPanelProcedimento.scss';

export const HistoricoExamesPanelProcedimento = (props: any) => {
  const { isSelected, procedimento, reloadList, onSelectResultado } = props;

  const { user } = useAppSelector((state: RootState) => state);

  const { atendimentoStatus } = useAtendimento();

  const disabledWhenAtendimentoStatusIsNotAtendendo =
    atendimentoStatus !== 'ATENDENDO';
  const disabledWhenProfissionalSaudeIsNotOwner =
    procedimento.idUsuarioAtualizacao !== user.idUsuario;

  const isProcedimentoExterno = !!procedimento.idSolicitacaoProcedimentoExterno;
  const idProcedimento =
    procedimento.idSolicitacaoProcedimento ??
    procedimento.idSolicitacaoProcedimentoExterno;

  const [dataRepeticao, setDataRepeticao] = useState<string | null>(
    procedimento.dataRepeticao,
  );
  const [parecer, setParecer] = useState(
    procedimento?.parecerMedicoResultadoExame || '',
  );
  const [justificativaInativacao, setJustificativaInativacao] = useState(
    procedimento?.justificativaInativacao || '',
  );
  const [laudoArquivo, setLaudoArquivo] = useState<any>(
    procedimento?.resultadoExame?.laudoArquivo || null,
  );

  const modalUploadLaudo = useDisclosure({ opened: false });
  const modalVisualizarLaudo = useDisclosure({ opened: false });
  const modalDataRepeticao = useDisclosure({ opened: false });
  const modalParecer = useDisclosure({ opened: false });
  const modalInativar = useDisclosure({ opened: false });
  const modalIncluirResultado = useDisclosure({ opened: false });
  const modalInformarResultadoNumerico = useDisclosure({ opened: false });

  const handleOpenModalInformarResultadoNumerico = () =>
    modalInformarResultadoNumerico.open({
      state: {
        resutladoNumerico: procedimento?.resultadoExame?.resultadoDigitado,
        idAtendimento: procedimento.idAtendimento,
        idProcedimento,
        isProcedimentoExterno,
        onSuccess: reloadList,
      },
    });

  const handleAddDataRepeticao = () =>
    modalDataRepeticao.open({
      state: {
        idAtendimento: procedimento.idAtendimento,
        idProcedimento,
        isProcedimentoExterno,
        onSuccess: setDataRepeticao,
      },
    });

  const handleOpenModalParecer = () => {
    modalParecer.open({
      state: {
        parecer,
        idAtendimento: procedimento.idAtendimento,
        idProcedimento,
        isProcedimentoExterno,
        onSuccess: setParecer,
      },
    });
  };

  const handleOpenInativar = () => {
    modalInativar.open({
      state: {
        justificativaInativacao,
        nomeUsuario: procedimento.nomeUsuarioAtualizacao,
        dataInativacao: procedimento.dataAtualizacao,
        idAtendimento: procedimento.idAtendimento,
        idProcedimento,
        onSuccess: setJustificativaInativacao,
      },
    });
  };

  const handleOpenIncluirResultado = () =>
    modalIncluirResultado.open({
      state: { procedimento, laudoArquivo, onSuccess: reloadList },
    });

  const handleOpenModalLaudo = () => {
    if (laudoArquivo) {
      return modalVisualizarLaudo.open({ state: { laudoArquivo } });
    }

    const handleSuccessUploadLaudo = (laudoArquivo: any) => {
      setLaudoArquivo(laudoArquivo);
      reloadList();
    };

    return modalUploadLaudo.open({
      state: {
        idAtendimento: procedimento.idAtendimento,
        idProcedimento,
        isProcedimentoExterno,
        onSuccess: handleSuccessUploadLaudo,
      },
    });
  };

  const dataRepeticaoFormatted = dataRepeticao
    ? dayjs(dataRepeticao).format('DD/MM/YYYY')
    : 'Data repetição não adicionada';

  const headerTemplate = (options: PanelHeaderTemplateOptions) => (
    <HistoricoExamesPanelProcedimentoHeader
      options={options}
      isSelected={isSelected}
      isProcedimentoExterno={isProcedimentoExterno}
      idProcedimento={idProcedimento}
      procedimento={procedimento}
      dataRepeticaoFormatted={dataRepeticaoFormatted}
      onOpenIncluirResultado={handleOpenIncluirResultado}
      hasLaudo={!!laudoArquivo}
      onSelectResultado={onSelectResultado}
      disabled={
        disabledWhenAtendimentoStatusIsNotAtendendo ||
        disabledWhenProfissionalSaudeIsNotOwner
      }
      isInactive={justificativaInativacao}
    >
      <HistoricoExamesPanelProcedimentoHeaderBtns
        idAtendimento={procedimento.idAtendimento}
        idProcedimento={idProcedimento}
        isInactive={justificativaInativacao}
        isLembreteActive={!!procedimento.lembrete}
        isProcedimentoExterno={isProcedimentoExterno}
        hasParecer={!!parecer}
        hasLaudo={!!laudoArquivo}
        idLaudo={procedimento.resultadoExame?.id}
        hasDataRepeticao={!!dataRepeticao}
        hasResultadoNumerico={!!procedimento?.resultadoExame?.resultadoDigitado}
        onOpenModalInformarResultadoNumerico={
          handleOpenModalInformarResultadoNumerico
        }
        onOpenModalLaudo={handleOpenModalLaudo}
        onOpenModalParecer={handleOpenModalParecer}
        onAddDataRepeticao={handleAddDataRepeticao}
        onOpenInativar={handleOpenInativar}
        disabled={
          disabledWhenAtendimentoStatusIsNotAtendendo ||
          disabledWhenProfissionalSaudeIsNotOwner
        }
      />
    </HistoricoExamesPanelProcedimentoHeader>
  );

  return (
    <>
      {modalDataRepeticao.isOpen && (
        <ModalAddDataRepeticao {...modalDataRepeticao} />
      )}
      {modalParecer.isOpen && <ModalParecer {...modalParecer} />}
      {modalInativar.isOpen && <ModalInativar {...modalInativar} />}
      {modalIncluirResultado.isOpen && (
        <ModalIncluirResultado {...modalIncluirResultado} />
      )}
      {modalUploadLaudo.isOpen && <ModalUploadLaudo {...modalUploadLaudo} />}
      {modalVisualizarLaudo.isOpen && (
        <ModalVisualizarLaudo {...modalVisualizarLaudo} />
      )}
      {modalInformarResultadoNumerico.isOpen && (
        <ModalInformarResultado {...modalInformarResultadoNumerico} />
      )}

      <SelectablePanel
        className="historico-procedimento-panel"
        isSelected={isSelected}
        headerTemplate={headerTemplate}
      >
        <div className="historico-panel-content p-grid p-col-12">
          <div className="p-col-3 p-d-flex p-flex-column">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Qtd. Solicitada
            </SimpleText>

            <SimpleText
              className="p-mt-1"
              fontColor={FONT_COLOR.COLOR_40}
              medium
            >
              {procedimento.quantidade}
            </SimpleText>
          </div>

          <div className="p-col-3 p-d-flex p-flex-column">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Qtd. Aprovada
            </SimpleText>

            <SimpleText
              className="p-mt-1"
              fontColor={FONT_COLOR.COLOR_40}
              medium
            ></SimpleText>
          </div>

          <div className="p-col-3 p-d-flex p-flex-column">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data de Solictação
            </SimpleText>

            <SimpleText
              className="p-mt-1"
              fontColor={FONT_COLOR.COLOR_40}
              medium
            >
              {procedimento.dataSolicitacao
                ? dayjs(new Date(procedimento.dataSolicitacao)).format(
                    'DD/MM/YYYY HH:mm',
                  )
                : ''}
            </SimpleText>
          </div>

          <SimpleText className="p-col-12">
            Adicionado {isProcedimentoExterno && 'externamente'} por{' '}
            <span>{procedimento.nomeMedicoInclusao}</span>{' '}
            {(!!procedimento.dataSolicitacao || procedimento.dataInclusao) &&
              dayjs(
                procedimento.dataSolicitacao ?? procedimento.dataInclusao,
              ).format('[em] DD [de] MMM [de] YYYY')}
            <i className="fas fa-lock-open p-ml-1" />
          </SimpleText>
        </div>
      </SelectablePanel>
    </>
  );
};
