export const modulos = {
  AGE: 'Agenda',
  PRO: 'Prontuário',
  ADM: 'Administrativo',
  FIN: 'Financeiro',
  HOM: 'Home Care',
  AGA: 'Aga',
  MON: 'Monitoramento',
  REL: 'Relatório',
  SUP: 'Suporte',
  AGO: 'Agenda online',
  TRI: 'Triagem',
  PRE: 'Prescrição',
};

export const tiposUsuario = [
  {
    label: 'Secretariado',
    value: 'SECRETARIO',
  },
  {
    label: 'Profissional de saúde',
    value: 'PROFISSIONAL_SAUDE',
  },
  {
    label: 'Administrador',
    value: 'ADMINISTRADOR',
  },
  {
    label: 'Master',
    value: 'MASTER',
  },
];

export const groupByModule = (perfilFuncionalidades: any) => {
  const group = perfilFuncionalidades.reduce(
    (accumulator: any, currentValue: any) => {
      const modulo =
        modulos[currentValue.funcionalidade.modulo as keyof typeof modulos];

      (accumulator[modulo] = accumulator[modulo] || []).push(currentValue);
      return accumulator;
    },
    {},
  );

  return Object.entries(group).map(([type, data]) => ({ type, data }));
};
