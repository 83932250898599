import React, { useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import { Checkbox, CheckboxProps } from 'src/components/_UI/Checkbox';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import '../RemarcarAgendamento.scss';

interface ConfirmarRemarcacaoProps {
  visible: boolean;
  registrarHistoricoFalta: boolean;
  setRegistrarHistoricoFalta: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onHide: () => void;
}

function ConfirmarRemarcacao({
  visible,
  registrarHistoricoFalta,
  setRegistrarHistoricoFalta,
  onConfirm,
  onHide,
}: ConfirmarRemarcacaoProps) {
  const [avisoVisivel, setAvisoVisivel] = useState<boolean>(true);

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className="p-col-align-center"
        onClick={onHide}
        stretch
      />
      <Button label="Sim, liberar" onClick={onConfirm} stretch />
    </div>
  );

  return (
    <Dialog
      className="remarcar-modal"
      visible={visible}
      header="Tornar horário livre"
      onHide={onHide}
      footer={renderFooter}
    >
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Você confirma esta ação?
        </SimpleText>

        <Checkbox
          className="p-mt-4"
          label="Registrar a ocorrência no histórico do paciente"
          checked={registrarHistoricoFalta}
          onChange={() =>
            setRegistrarHistoricoFalta((isChecked: boolean) => !isChecked)
          }
        />

        <AlertBox
          text="Mantenha a ocorrência no histórico do paciente. Atenção, esta ação não pode ser revertida."
          visible={avisoVisivel}
          onHide={() => setAvisoVisivel(false)}
        />
      </div>
    </Dialog>
  );
}

export default ConfirmarRemarcacao;
