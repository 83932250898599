import { Control, Controller } from 'react-hook-form';

import CalendarInput, {
  CalendarInputProps,
} from '../CalendarInput/CalendarInput';

export interface CalendarInputControlledProps
  extends Omit<CalendarInputProps, 'value'> {
  control: Control<any>;
  name: string;
  customOnChange?: (e: any) => void;
}

const CalendarInputControlled = ({
  name,
  control,
  customOnChange,
  ...rest
}: CalendarInputControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }: any) => (
        <CalendarInput
          {...rest}
          value={value}
          onBlur={onBlur}
          onChange={e => {
            onChange(e);
            if (customOnChange) customOnChange(e);
          }}
          errorMsg={
            error?.message || error?.[0]?.message || error?.[1]?.message
          }
        />
      )}
    />
  );
};

export default CalendarInputControlled;
