import {
  Badge as PrimeBadge,
  BadgeProps as PrimeBadgeProps,
} from 'primereact/badge';

type BadgeProps = PrimeBadgeProps;

const Badge = ({ ...props }: BadgeProps) => {
  return <PrimeBadge {...props} />;
};

export default Badge;
