import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { formatCnpj } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { CardItem } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  empresa: any;
  onSwitchStatus: (empresa: any) => void;
  onView: (empresa: any) => void;
  onEdit: (empresa: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { empresa, onSwitchStatus, onView, onEdit } = props;
  const menu = useRef<any>(null);

  const itemsOptions = [
    {
      label: 'Editar',
      command: () => onEdit(empresa),
      checkPermission: 'ADM_EMPRESA_ALTERAR',
    },
    {
      label: 'Visualizar',
      command: () => onView(empresa),
    },
  ];
  return (
    <CardItem>
      <div className="p-col-2">
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {empresa?.codigo}
        </SimpleText>
      </div>
      <div className="p-col-5 p-md-6 p-lg-7 p-xl-8">
        <SimpleText
          className="p-d-block p-mb-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {empresa?.razaoSocial}
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {formatCnpj(empresa?.cnpj)}
        </SimpleText>
      </div>

      <div className="p-col-5 p-md-4 p-lg-3 p-xl-2 p-d-flex p-ai-center p-jc-end p-gap-3">
        <Switch
          className="p-as-center"
          checked={empresa?.status}
          onChange={() => onSwitchStatus(empresa)}
          checkPermission="ADM_EMPRESA_ALTERAR"
          showStatus
        />

        <div className="p-d-flex p-jc-end">
          <Button
            className="p-mr-1"
            btnType="gray"
            icon="fas fa-ellipsis-h"
            onClick={event => menu?.current?.toggle(event)}
          />

          <TieredMenu
            className="tiered-menu"
            model={itemsOptions}
            popup
            ref={menu}
          />
        </div>
      </div>
    </CardItem>
  );
};
