import { useMemo } from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { capitalizeFirstLetter } from 'src/utils/utils';

import Divider from 'src/components/Basics/Divider/Divider';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './HorarioAtendimento.scss';

interface Props {
  data: any;
  handleEdit: (data: any) => void;
  handleDuplicate: (data: any) => void;
  handleDelete: (data: any) => void;
  isLastRow: boolean;
}

const DesktopRow = ({
  data,
  handleDelete,
  handleDuplicate,
  handleEdit,
  isLastRow,
}: Props) => {
  const { windowInnerWidth } = useSize();

  const horarioTemporario = !!(data.dataPeriodoIni && data.dataPeriodoFim);

  const isLg = useMemo(() => windowInnerWidth >= 1176, [windowInnerWidth]);

  return (
    <div className="p-d-flex p-flex-column p-gap-1">
      <div className="grid-list-item listitem">
        <div className="p-col-2 p-px-1">
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {capitalizeFirstLetter(data.diaDaSemana)}
          </SimpleText>
        </div>
        <div className="p-col-2 p-px-1 p-d-flex p-ai-center p-gap-1">
          <SimpleText fontColor="color_60">
            <i className="fas fa-clock" />
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {data.horarioInicial}
          </SimpleText>
        </div>
        <div className="p-col-2 p-px-1 p-d-flex p-ai-center p-gap-1">
          <SimpleText fontColor="color_60">
            <i className="fas fa-clock" />
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XXS}>{data.horarioFinal}</SimpleText>
        </div>
        <div className="p-col-2 p-px-1 p-d-flex p-ai-center p-gap-1">
          <SimpleText fontSize={FONT_SIZE.XXS}>{data.intervalo} min</SimpleText>
        </div>
        <div className={`p-col-${isLg ? '3' : '2'} p-px-1 `}>
          <SimpleText fontSize={FONT_SIZE.XXS}>
            {horarioTemporario ? 'Sim' : 'Não'}
          </SimpleText>
          {horarioTemporario && (
            <>
              <Tooltip target={`.info-temporario-${data.id}`} />
              <span
                className={`fas fa-info-circle p-ml-1 info-icon info-temporario-${data.id} p-ml-2`}
                data-pr-tooltip={`${dayjs(data.dataPeriodoIni).format(
                  'DD/MM/YYYY',
                )} até ${dayjs(data.dataPeriodoFim).format('DD/MM/YYYY')}`}
                data-pr-position="top"
              />
            </>
          )}
        </div>
        <div className={`p-col-${isLg ? '1' : '2'} p-px-0 p-d-flex`}>
          <>
            <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_ALTERAR">
              <IconButton
                btnType={IconBtnTypes.ICON_ONLY}
                icon="pi pi-pencil"
                squared
                onClick={() => handleEdit(data)}
              />
            </Can>
            <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_CADASTRAR">
              <IconButton
                btnType={IconBtnTypes.ICON_ONLY}
                icon="fas fa-clone"
                squared
                onClick={() => handleDuplicate(data)}
              />
            </Can>
            <Can checkPermission="AGE_HORARIO_DE_ATENDIMENTO_EXCLUIR">
              <IconButton
                btnType={IconBtnTypes.ICON_ONLY}
                icon="fas fa-trash"
                squared
                onClick={() => handleDelete(data)}
              />
            </Can>
          </>
        </div>
      </div>

      {!isLastRow && <Divider layout="horizontal" className="p-p-0 p-m-0" />}
    </div>
  );
};

export default DesktopRow;
