import React from 'react';

import './IndiceVulnerabilidadeBar.scss';
import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  value: number;
}

const IndiceVulnerabilidadeBar = ({ value }: Props) => {
  const { isMobile } = useSize();

  const getSegmentColor = (index: number) => {
    if (index >= 1 && index <= 6) return 'robusto';
    if (index >= 7 && index <= 14) return 'pre-fragil';
    if (index >= 15 && index <= 24) return 'fragil-baixa';
    if (index >= 25 && index <= 34) return 'fragil-alta';
    if (index >= 35 && index <= 40) return 'fragil-final';
    return '';
  };

  const segments = Array.from({ length: 40 }, (_, index) => {
    const segmentColor = getSegmentColor(index + 1);
    const isFilled = index + 1 <= value;
    return (
      <div
        key={index}
        className={`segment ${isFilled ? segmentColor : ''} index${index} ${
          isMobile && 'mobile'
        }`}
      />
    );
  });

  return (
    <div className={`progress-bar-container p-my-2 ${isMobile && 'mobile'}`}>
      {segments}
      <div className={`borders p-d-flex ${isMobile && 'mobile p-flex-column'}`}>
        <div
          className={`border-robusto p-d-flex p-ai-center p-jc-center ${
            isMobile && 'mobile'
          }`}
        >
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            Idoso Robusto (0 - 6)
          </SimpleText>
        </div>
        <div
          className={`border-pre-fragil p-d-flex p-ai-center p-jc-center ${
            isMobile && 'mobile'
          }`}
        >
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            Idoso Pré-Frágil (7 - 14)
          </SimpleText>
        </div>
        <div
          className={`border-fragil-baixa p-d-flex p-ai-center p-jc-center ${
            isMobile && 'mobile'
          }`}
        >
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            Idoso frágil
            <br />
            Baixa complexidade (15 - 24)
          </SimpleText>
        </div>
        <div
          className={`border-fragil-alta p-d-flex p-ai-center p-jc-center ${
            isMobile && 'mobile'
          }`}
        >
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            Idoso frágil
            <br />
            Alta complexidade (25 - 34)
          </SimpleText>
        </div>
        <div
          className={`border-fragil-final p-d-flex p-ai-center p-jc-center ${
            isMobile && 'mobile'
          }`}
        >
          <SimpleText fontSize={FONT_SIZE.XXXS} medium>
            Idoso frágil
            <br />
            Fase final de vida (35 - 40)
          </SimpleText>
        </div>
      </div>
    </div>
  );
};

export default IndiceVulnerabilidadeBar;
