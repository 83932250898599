import { FormProvider, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { FieldServicoTiss } from '../components/FieldServicosTiss';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const PendenciasTissFilter = () => {
  const { setFilter } = useSearchList();

  const form = useForm({
    defaultValues: {
      dataInicial: new Date(dayjs().subtract(7, 'day') as any),
      dataFinal: new Date(),
      tentativas: 0,
    },
  });

  const onSubmit = (v: any) => {
    setFilter(v);
  };

  const handleClear = () => {
    setFilter({ page: 0 });
    form.reset();
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-col-12 p-p-0 p-grid p-ai-center"
      >
        <CalendarInputControlled
          control={form.control}
          name="dataInicial"
          label="Data inicial"
          className="p-col-6 p-md-2"
          hideTextErrorSpace
          showIcon
        />
        <CalendarInputControlled
          control={form.control}
          name="dataFinal"
          label="Data final"
          className="p-col-6 p-md-2"
          hideTextErrorSpace
          showIcon
        />

        <FieldServicoTiss
          name="servico"
          label="Serviços"
          className="p-col-6 p-sm-3 p-lg-4"
        />

        <FormInput
          name="tentativas"
          label="Tentativas"
          type="number"
          className="p-col-6 p-sm-3 p-md-2"
          hideTextErrorSpace
        />

        <div className="p-col p-d-flex p-gap-1 p-mt-3">
          <Button
            label="Limpar"
            btnType="outline"
            stretch
            onClick={handleClear}
          />
          <Button label="Pesquisar" type="submit" stretch />
        </div>
      </form>
    </FormProvider>
  );
};
