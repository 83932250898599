import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import IconButton, {
  IconBtnSizes,
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Panel, { PanelProps } from 'src/components/Panel/Panel';
import './BasicPanel.scss';

export interface BasicPanelRef {
  collapse(): void;
  show(): void;
}
interface BasicPanelProps
  extends Omit<PanelProps, 'toggleable' | 'collapsed' | 'toggleable'> {
  title?: string;
  subTitle?: string;
  children: JSX.Element;
  disabled?: boolean;

  template?: JSX.Element;
}
const BasicPanel = (
  { title, subTitle, disabled, template, ...rest }: BasicPanelProps,
  ref: Ref<BasicPanelRef> | undefined,
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    collapse: () => {
      setIsOpen(false);
    },
    show: () => {
      setIsOpen(true);
    },
  }));

  return (
    <Panel
      {...rest}
      toggleable
      collapsed={!isOpen}
      onToggle={e => {
        setIsOpen(e.value);
      }}
      headerTemplate={
        <div className="BasicPanel">
          {template ? (
            template
          ) : (
            <div className="titles">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}>
                {title || ''}
              </SimpleText>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_40}
                fontSize={FONT_SIZE.XXS}>
                {subTitle || ''}
              </SimpleText>
            </div>
          )}

          <IconButton
            icon={isOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
            btnSize={IconBtnSizes.SMALL}
            btnType={IconBtnTypes.ICON_ONLY}
            disabled={!!disabled}
            onClick={() => {
              setIsOpen((prev: boolean) => !prev);
            }}
          />
        </div>
      }
    />
  );
};

export default forwardRef(BasicPanel);
