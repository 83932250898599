import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI/Button';
import ModalEnviarGuia from 'src/pages/Emed/Prontuario/components/ModalEnviarGuia/ModalEnviarGuia';

import './ButtonEnviarEmail.scss';

type IProps = {
  handleEnviarGuiasPorEmail: (email: string) => void;
  disabled: boolean;
  modalEmailDisclosure?: any;
  loading?: boolean;
  loadingAssinatura?: boolean;
};

export const ButtonEnviarEmail = ({
  handleEnviarGuiasPorEmail,
  modalEmailDisclosure,
  disabled,
  loading,
  loadingAssinatura,
}: IProps) => {
  const { isMobile } = useSize();

  return (
    <div>
      {isMobile ? (
        <Button
          btnType="gray"
          icon="fas fa-envelope"
          type="button"
          style={{ borderRadius: '8px' }}
          onClick={() => modalEmailDisclosure?.open()}
          disabled={disabled}
        />
      ) : (
        <Button
          label="Enviar por e-mail"
          icon="fas fa-envelope"
          btnType="tonal"
          type="button"
          onClick={() => modalEmailDisclosure?.open()}
          disabled={disabled}
        />
      )}

      {modalEmailDisclosure?.isOpen && (
        <ModalEnviarGuia
          handleEnviarGuiasPorEmail={handleEnviarGuiasPorEmail}
          loaging={loading}
          loadingAssinatura={loadingAssinatura}
          {...modalEmailDisclosure}
        />
      )}
    </div>
  );
};
