import 'dayjs/locale/pt-br';

import { memo, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import SigiloAPI from 'src/APIs/AgendaAPI/Sigilo/SigiloAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Box from 'src/components/Basics/Box/Box';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalRevogarSigilo.scss';

dayjs().format();

interface ModalRevogarSigiloProps {
  visible: boolean;
  onSuccess(): void;
  onCancel(): void;
  paciente: any;
  setPaciente?: (p: any) => void;
}

interface SigiloProps {
  dataLiberacao: string;
  dataValidade: string;
  id: number;
  nivel: 'SADT' | 'SOAP' | 'BLOQUEADO';
  tipo: 'PACIENTE_DOC_CONSENTIMENTO' | 'PRONTUARIO_LIBERACAO' | 'PARAMETRO';
  usuario: string;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
  sigilo: SigiloProps;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd, sigilo } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType={'ghost'} label="Cancelar" onClick={onCancel} stretch />
      <Button
        label="Revogar"
        onClick={onAdd}
        disabled={sigilo?.tipo !== 'PRONTUARIO_LIBERACAO'}
        stretch
      />
    </div>
  );
});

const ModalRevogarSigilo = ({
  paciente,
  visible,
  onCancel,
  onSuccess,
}: ModalRevogarSigiloProps) => {
  const [alertVisible, setAlertVisible] = useState(true);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'pro_revogar_sigilo',
  });

  const handleNivelSigilo = useCallback((nvl: string) => {
    if (nvl === 'BLOQUEADO') {
      return 'Nível 0 - Bloqueado';
    }
    if (nvl === 'SADT') {
      return 'Nível 1 - Liberar apenas resultado de exames';
    }
    return 'Nível 2 - Liberado para o consultório';
  }, []);

  const handleRevogarSigilo = useCallback(async () => {
    try {
      const response = await SigiloAPI.updateRevokeSigilo(
        paciente?.sigilo?.id,
        paciente?.sigilo?.tipo,
      );

      if (response?.status === 202) {
        onSuccess();
      }
    } catch (error) {
      console.log({ error });
    }
  }, [paciente, onSuccess]);

  const handleCancelSigilo = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header="Revogar liberação do sigilo"
      visible={visible}
      onHide={handleCancelSigilo}
      footer={() => (
        <Footer
          onAdd={handleRevogarSigilo}
          onCancel={handleCancelSigilo}
          sigilo={paciente?.sigilo}
        />
      )}
      maximizedTabletSize
    >
      <div className="modal-body-revogar-sigilo">
        <SimpleText className="p-mb-4" fontColor={FONT_COLOR.COLOR_40}>
          Você tem certeza que deseja revogar a liberação do sigilo do paciente{' '}
          {paciente?.paciente?.nome}?
        </SimpleText>

        <Box label="Detalhes da liberação">
          <div className="infos">
            <div className="line">
              <span className="left">Nível do sigilo</span>
              <span className="right">
                {handleNivelSigilo(paciente?.sigilo?.nivel)}
              </span>
            </div>
            <div className="line">
              <span className="left">Data da modificação</span>
              <span className="right">
                {paciente?.sigilo?.dataLiberacao
                  ? dayjs(paciente?.sigilo?.dataLiberacao)
                      .locale('pt-br')
                      .format('DD, MMM, YYYY')
                  : 'Sem data'}
              </span>
            </div>
            {paciente?.sigilo?.usuario && (
              <div className="line">
                <span className="left">Modificado por</span>

                <span className="right">{paciente?.sigilo?.usuario}</span>
              </div>
            )}
            <div className="line">
              <span className="left">Valido até</span>
              <span className="right">
                {paciente?.sigilo?.dataValidade
                  ? dayjs(paciente?.sigilo?.dataValidade)
                      .locale('pt-br')
                      .format('DD, MMM, YYYY')
                  : 'Sem data'}
              </span>
            </div>
          </div>
        </Box>

        {alertVisible && (
          <div className="box-alert">
            <i className="fas fa-exclamation-triangle p-mr-2" />
            <span className="box-alert-text">
              Ao revogar a liberação de sigilo os médicos não poderão mais ter
              acesso ao prontuário completo do paciente
            </span>
            <IconButton
              icon="pi pi-times"
              btnType={IconBtnTypes.ICON_ONLY}
              onClick={() => setAlertVisible(false)}
            />
          </div>
        )}

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </Dialog>
  );
};

export default ModalRevogarSigilo;
