import { useState, useCallback, useRef, useEffect } from 'react';

import InfiniteScrollComponent from 'react-infinite-scroll-component';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import GridListLoading from 'src/components/GridList/GridListLoading';

interface List {
  list: any[];
}

interface InfiniteScrollProps {
  fetchAPI(params?: any): Promise<Paginator<any>> | Paginator<any> | List;
  renderRow: (data: any) => JSX.Element;
  filtro?: (item: any) => boolean;
  updateList?: number;
}

const InfiniteScroll = ({
  fetchAPI,
  renderRow,
  updateList,
}: InfiniteScrollProps) => {
  const page = useRef(0);

  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    if (!fetchAPI) return [];
    // TODO: Alterar para chamada da API

    try {
      const aux = await fetchAPI({
        page: page.current,
      });

      if (!('page' in aux)) setHasMore(false);

      return aux.list;
    } catch (e) {
      console.log('Error fetching data from SearchList: ', e);
    }

    // TODO: Validar quantidade de resultado
    // para desabilitar scroll infinito
    const objectConstructor = {}.constructor;

    return Array.from({ length: 50 }).filter(
      (v: any) => v && v.constructor === objectConstructor,
    );
  }, [fetchAPI]);

  const load = useCallback(async () => {
    page.current = 0;
    setItems([]);

    setLoading(true);

    const newItems = await fetch();

    setItems(newItems ?? []);

    if (newItems?.length === 0) {
      setHasMore(false);
    }

    setLoading(false);
  }, [fetch]);

  const fetchMoreData = useCallback(async () => {
    page.current += 1;

    const newItems = await fetch();

    if (!!newItems?.length) {
      setItems((prev: any) => {
        return [...prev.concat(newItems)];
      });
    }
  }, [fetch]);

  useEffect(() => {
    load();
  }, [load, updateList]);

  if (loading) return <GridListLoading />;

  return (
    <InfiniteScrollComponent
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={!hasMore ? <GridListLoading /> : <></>}
      height={400}
    >
      {items &&
        Array.isArray(items) &&
        (items || []).map((data, index) => {
          return <div key={index}>{renderRow(data)}</div>;
        })}
      {!items?.length && !loading && (
        <div
          style={{ height: '100%' }}
          className="p-d-flex p-flex-1 p-ai-center p-jc-center sem-resultado"
        >
          <SimpleText className="label">
            Nenhum item encontrado para esta busca.
          </SimpleText>
        </div>
      )}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
