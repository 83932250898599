import { useEffect, useMemo, useState } from 'react';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';

import { capitalizeFirstLetter } from 'src/utils/utils';

import GestacaoCard from '../components/GestacaoCard/GestacaoCard';
import { Button } from 'src/components/_UI/Button';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';

interface PreNatalCardProps {
  children: JSX.Element;
  registrarHistorico?: boolean;
  renderEncerrarCiclo: (visible: boolean, onHide: () => void) => JSX.Element;
  renderRegistrarHistorico?: (
    visible: boolean,
    onHide: () => void,
  ) => JSX.Element;
}

const PreNatalCard = ({
  children,
  registrarHistorico = false,
  renderEncerrarCiclo,
  renderRegistrarHistorico,
}: PreNatalCardProps) => {
  const { preNatalAtual, gestacoes, selectedGestacao, setSelectedGestacao } =
    usePreNatal();
  const { atendimentoStatus } = useAtendimento();

  const [encerrarCicloVisible, setEncerrarCicloVisible] = useState(false);
  const [registrarHistoricoVisible, setRegistrarHistoricoVisible] =
    useState(false);
  const [clearGestacao, setClearGestacao] = useState(true);

  useEffect(() => {
    if (gestacoes.length >= 2) {
      setClearGestacao(false);
    }
  }, [gestacoes]);

  const gestacaoOptions = useMemo(() => {
    return gestacoes
      .sort((g, next) => {
        if (g.tipoGestacao > next.tipoGestacao) return 1;
        if (g.tipoGestacao < next.tipoGestacao) return -1;
        return 0;
      })
      .map(gestacao => ({
        value: gestacao,
        label: capitalizeFirstLetter(gestacao.tipoGestacao).replaceAll(
          '_',
          ' ',
        ),
      }));
  }, [gestacoes]);

  return (
    <>
      {renderEncerrarCiclo(encerrarCicloVisible, () =>
        setEncerrarCicloVisible(false),
      )}

      {renderRegistrarHistorico?.(registrarHistoricoVisible, () =>
        setRegistrarHistoricoVisible(false),
      )}

      <ElevatedCard className="card-pre-natal p-grid" noMargin type="white">
        <div className="p-col-12 p-d-flex p-jc-between p-ai-center">
          <SimpleText
            fontSize={FONT_SIZE.MD}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Pré-natal
          </SimpleText>

          <div className="p-d-flex">
            {gestacoes.length >= 1 && (
              <Dropdown
                options={gestacaoOptions}
                value={selectedGestacao || preNatalAtual}
                onChange={e => setSelectedGestacao(e.value)}
                className="p-mr-3"
                showClear={true}
              />
            )}

            {registrarHistorico && (
              <Button
                type="button"
                btnType="tonal"
                label="Registrar histórico"
                icon="fas fa-plus"
                disabled={atendimentoStatus !== 'ATENDENDO'}
                onClick={() => setRegistrarHistoricoVisible(true)}
              />
            )}
            <Button
              className="p-ml-3"
              type="button"
              btnType="tonal"
              label="Encerrar ciclo"
              onClick={() => setEncerrarCicloVisible(true)}
              disabled={!preNatalAtual?.id || atendimentoStatus !== 'ATENDENDO'}
            />
          </div>
        </div>

        <GestacaoCard />

        {children}
      </ElevatedCard>
    </>
  );
};

export default PreNatalCard;
