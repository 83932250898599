import { useRef, useEffect } from 'react';

export function useEffectSkipFirst(fnc: () => any, inputs: Array<any> = []) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    if (fnc && typeof fnc === 'function') {
      fnc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
