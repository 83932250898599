import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = 'aga/atendimento-formularios';

const getDiagnosticoFuncional = (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDiagnosticoFuncional> => {
  return Http.get({
    service: 'pep',
    url: `${baseUrl}/atendimento/${idAtendimento}/diagnostico-funcional-global`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const getHistoricoDiagnosticoFuncional = (
  idPaciente: number,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IHistoricoDiagnosticoFuncional> => {
  return Http.get({
    service: 'pep',
    url: `${baseUrl}/paciente/${idPaciente}/historico-diagnostico-funcional-global/${idAtendimento}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const DiagnosticoFuncionalAPI = {
  getDiagnosticoFuncional,
  getHistoricoDiagnosticoFuncional,
};
