import Http, { HttpParams } from 'src/core/http/Http';

export const sendBloqueioPaciente = (
  payload: PostBloqueioPacienteDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostBloqueioPacienteDTO> => {
  return Http.post({
    service: 'adm',
    url: '/bloqueio-paciente',
    data: payload,
    hideToast: true,
    ...options,
  });
};

export const removeBloqueioPacienteById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<BloqueioPacienteDTO> => {
  return Http.delete({
    service: 'adm',
    url: `/bloqueio-paciente/${id}`,
    hideToast: true,
    ...options,
  });
};

export const loadMedicoById = (
  medicoId: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetPacienteDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `bloqueio-paciente/pesquisar?medicoId=${medicoId}`,
    hideToast: true,
    ...options,
  });
};

export const BloqueioPacienteAPI = {
  sendBloqueioPaciente,
  removeBloqueioPacienteById,
  loadMedicoById,
};
