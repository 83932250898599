import React from 'react';

import useSize from 'src/core/hooks/useSize';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import Vulnerabilidade from './Vulnerabilidade';

import './EscalaFragilidade.scss';

interface EscalaFragilidadeProps {
  indice: number;
}

const EscalaFragilidade = ({ indice }: EscalaFragilidadeProps) => {
  const { isMobile } = useSize();
  const fragilidade = indice < 7 ? 'Baixa' : indice < 15 ? 'Média' : 'Alta';

  const borderFragilidade =
    indice < 7
      ? 'border-robusto'
      : indice < 15
      ? 'border-pre-fragil'
      : indice < 25
      ? 'border-fragil-baixa'
      : indice < 35
      ? 'border-fragil-alta'
      : 'border-fragil-final';

  return (
    <div className="escala-fragibilidade-diagnostico bg-neutral-color p-grid p-p-1 p-mt-3 p-col-12">
      <SimpleText fontSize="sm" className="p-col-12">
        Escala de fragilidade
      </SimpleText>
      <div className="p-col-12 p-grid border-dashed br-8px">
        <div
          className={`p-col-12 p-md-6 p-d-flex p-flex-column p-ai-center p-jc-center p-gap-2 ${borderFragilidade}`}
        >
          <SimpleText fontSize="sm" fontColor="color_16">
            Índice de vulnerabilidade
          </SimpleText>
          <SimpleText fontSize="md" fontColor="color_16">
            {fragilidade}
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            vulnerabilidade
          </SimpleText>
          <SimpleText fontSize="md">{indice}</SimpleText>
          <Vulnerabilidade value={indice} />
        </div>
        <div className="p-grid p-col-12 p-md-6">
          <div className="p-col-6 p-d-flex p-flex-column p-gap-3">
            <div className="p-d-flex p-flex-column p-ai-center p-jc-between p-gap-1">
              <SimpleText fontSize="md">Baixa</SimpleText>
              <SimpleText fontSize="xs">vulnerabilidade</SimpleText>
              <div
                className={`border-robusto p-d-flex p-ai-center p-jc-center p-p-1 ${
                  isMobile ? 'mobile' : ''
                }`}
              >
                <SimpleText fontSize="xxxs" medium className="p-text-center">
                  <div className="p-d-flex p-jc-center  w-160px">
                    Idoso Robusto
                    <br />
                    Sem complexidade (0 - 6)
                  </div>
                </SimpleText>
              </div>
            </div>
            <div className="p-d-flex p-flex-column p-ai-center p-jc-between p-gap-1">
              <SimpleText fontSize="md">Moderada</SimpleText>
              <SimpleText fontSize="xs">vulnerabilidade</SimpleText>
              <div
                className={`border-pre-fragil p-d-flex p-ai-center p-jc-center p-p-1 ${
                  isMobile ? 'mobile' : ''
                }`}
              >
                <SimpleText fontSize="xxxs" medium className="p-text-center">
                  <div className="p-d-flex p-jc-center  w-160px">
                    Idoso Pré-Frágil
                    <br />
                    Sem complexidade (7 - 14)
                  </div>
                </SimpleText>
              </div>
            </div>
          </div>
          <div className="p-col-6">
            <div className="p-d-flex p-flex-column p-ai-center p-jc-between p-gap-1">
              <SimpleText fontSize="md">Alta</SimpleText>
              <SimpleText fontSize="xs">vulnerabilidade</SimpleText>
              <div
                className={`border-fragil-baixa p-d-flex p-ai-center p-jc-center p-p-1 ${
                  isMobile ? 'mobile' : ''
                }`}
              >
                <SimpleText fontSize="xxxs" medium className="p-text-center">
                  <div className="p-d-flex p-jc-center  w-160px">
                    Idoso frágil
                    <br />
                    Baixa complexidade (15 - 24)
                  </div>
                </SimpleText>
              </div>
              <div
                className={`border-fragil-alta p-d-flex p-ai-center p-jc-center p-p-1 ${
                  isMobile ? 'mobile' : ''
                }`}
              >
                <SimpleText fontSize="xxxs" medium className="p-text-center">
                  <div className="p-d-flex p-jc-center  w-160px">
                    Idoso frágil
                    <br />
                    Alta complexidade (25 - 34)
                  </div>
                </SimpleText>
              </div>
              <div
                className={`border-fragil-final p-d-flex p-ai-center p-jc-center p-p-1 ${
                  isMobile ? 'mobile' : ''
                }`}
              >
                <SimpleText fontSize="xxxs" medium className="p-text-center">
                  <div className="p-d-flex p-jc-center  w-160px">
                    Idoso frágil
                    <br />
                    Fase final de vida (35 - 40)
                  </div>
                </SimpleText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EscalaFragilidade;
