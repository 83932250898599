import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

interface MobileRowProps {
  migracao: any;
  onDeleteCpf: () => void;
  onSelectDestino: (migracao: any) => void;
  isSelected: boolean;
}

export const MobileRow = (props: MobileRowProps) => {
  const { migracao, onSelectDestino, isSelected, onDeleteCpf } = props;

  const options = [
    {
      label: 'Reverter',
      command: () => onSelectDestino(migracao),
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div
        className={isSelected ? 'p-col-12 destino selected' : 'p-col-12 origem'}
        onClick={onSelectDestino}
      >
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.paciente?.nome}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome da mãe
        </SimpleText>
        <br />
        <SimpleText
          className="p-mr-1"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.nomeMae}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Cartão
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.cartaoConvenio || 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          CNS
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.cns || 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          CPF/Passaporte
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.cpf || 'Não informado'}
        </SimpleText>
        <br />
        <SimpleText
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          {migracao?.passaporte || 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <Button
          label="Apagar CPF"
          btnType={BtnTypes.TONAL}
          icon="fas fa-eraser"
          iconPos="left"
          onClick={onDeleteCpf}
        />
      </div>
    </SearchListMobileRow>
  );
};
