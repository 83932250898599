import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/auditoria';

export const getPesquisaAuditoria = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basepath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getAuditoriaById = async (
  idAuditoria: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basepath}/id/${idAuditoria}`,
    hideToast: true,
    ...options,
  });
};

export const getTabelas = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basepath}/tabelas`,
    hideToast: true,
    ...options,
  });
};

export const getTiposUsuario = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basepath}/tipos-usuario`,
    hideToast: true,
    ...options,
  });
};

export const getTiposOperacoes = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basepath}/tipo-operacao`,
    hideToast: true,
    ...options,
  });
};

export const exportCsv = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basepath}/csv`,
    hideToast: true,
    responseType: 'blob',
    ...options,
  });
};

export const AuditoriaAPI = {
  getPesquisaAuditoria,
  getAuditoriaById,
  getTabelas,
  getTiposUsuario,
  getTiposOperacoes,
  exportCsv,
};
