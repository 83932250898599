import { memo, useCallback, useMemo } from 'react';

import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './ProfessionalSelected.scss';

interface ProfessionalsSelectedProps {
  value: GetProfissionalDTO[] | undefined;
  onChange(i: GetProfissionalDTO[], keepStep?: boolean): void;
}

interface SelectedList {
  profissionalSaude: GetProfissionalDTO[];
  secretariado: GetProfissionalDTO[];
}

const ProfessionalsSelected = (props: ProfessionalsSelectedProps) => {
  const { value = [], onChange } = props;

  const list: SelectedList = useMemo(() => {
    return value.reduce(
      (value: SelectedList, item) => {
        if (item?.tipo === 'PROFISSIONAL_SAUDE')
          value.profissionalSaude.push(item);
        if (item?.tipo === 'SECRETARIO') value.secretariado.push(item);

        return value;
      },
      { profissionalSaude: [], secretariado: [] },
    );
  }, [value]);

  const handleRemove = useCallback(
    (item: GetProfissionalDTO) => {
      const items = value.filter(i => i.id !== item.id);
      onChange(items, true);
    },
    [onChange, value],
  );

  return (
    <div className="ProfessionalSelected" style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 16 }}>
        <TitleText title="Profissional de saúde" />

        <div>
          {list.profissionalSaude.length > 0 ? (
            <>
              {list.profissionalSaude?.map(item => (
                <ProfessionalSelectedItem
                  key={`${item.tipo}-${item.id}`}
                  item={item}
                  onClick={handleRemove}
                />
              ))}
            </>
          ) : (
            <EmptyText />
          )}
        </div>
      </div>

      <div>
        <TitleText title="Secretariado" />

        <div>
          {list.secretariado.length > 0 ? (
            <>
              {list.secretariado?.map(item => (
                <ProfessionalSelectedItem
                  key={`${item.tipo}-${item.id}`}
                  item={item}
                  onClick={handleRemove}
                />
              ))}
            </>
          ) : (
            <EmptyText />
          )}
        </div>
      </div>
    </div>
  );
};

const TitleText = memo(({ title }: TitleTextProps) => {
  return (
    <SimpleText bold fontSize={FONT_SIZE.XXS} className="list-title">
      {title}
    </SimpleText>
  );
});

const EmptyText = memo(() => {
  return (
    <SimpleText
      fontSize={FONT_SIZE.XXXS}
      fontColor={FONT_COLOR.COLOR_60}
      className="search-list-empty"
    >
      Não há cadastros.
    </SimpleText>
  );
});

const ProfessionalSelectedItem = memo(
  (props: ProfessionalSelectedItemProps) => {
    const { item, onClick } = props;

    const tipo =
      item.tipo === 'PROFISSIONAL_SAUDE'
        ? item?.profissionalSaude?.conselhoTissProfissional.descricao
        : 'Secretária';

    return (
      <div className="ProfessionalSelectedItem p-flex-column p-flex-sm-row p-gap-3">
        <Avatar label={item.nome} className="avatar" />

        <SimpleText
          className="p-d-flex p-flex-1 p-text-center"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {item.nome}
        </SimpleText>

        <div className="p-d-flex p-flex-column p-ai-center p-ai-sm-end">
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
            {tipo || ''}
          </SimpleText>
          <SimpleText fontSize={FONT_SIZE.XS} fontColor={FONT_COLOR.COLOR_60}>
            {item.tipo === 'PROFISSIONAL_SAUDE'
              ? `${item?.profissionalSaude?.conselhoTiss.sigla || ''}${
                  item?.profissionalSaude?.numeroRegistro || ''
                }`
              : ''}
          </SimpleText>
        </div>
        <Button
          btnType="gray"
          icon="fas fa-trash"
          onClick={() => onClick(item)}
        />
      </div>
    );
  },
);

interface TitleTextProps {
  title: string;
}

interface ProfessionalSelectedItemProps {
  item: GetProfissionalDTO;
  onClick(v: GetProfissionalDTO): void;
}

export default memo(ProfessionalsSelected);
