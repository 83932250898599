import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  ultrassom: any;
  isCollapsed: boolean;
  onToggle: () => void;
}

export default function PanelHeader({
  ultrassom,
  isCollapsed,
  onToggle,
}: PanelHeaderProps) {
  return (
    <div className="p-grid">
      <div className="p-col-6 p-sm-2 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          IG Semanas
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom.idadeGestacionalSemanas}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-2 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          IG Dias
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom.idadeGestacionalDias}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-3 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Apresentação
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {ultrassom?.apresentacao
            ? capitalizeFirstLetter(ultrassom?.apresentacao)
            : 'Não informado'}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Data US
        </SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {dayjs(new Date(ultrassom?.dataUs)).format('DD/MM/YYYY')}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-jc-end">
        <Button
          icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
        />
      </div>
    </div>
  );
}
