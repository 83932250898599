import { ChangeEvent, useContext } from 'react';

import {
  Password as PrimePassword,
  PasswordProps as PrimePasswordProps,
} from 'primereact/password';

import ThemeContext from 'src/core/themes/ThemeContext';

import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './PasswordInput.scss';

export interface PasswordInputProps extends PrimePasswordProps {
  label?: string | undefined;
  errorMsg?: string;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  value?: string;
  sublabel?: string;
}

const PasswordInput = ({
  label,
  name,
  errorMsg,
  className,
  autoComplete,
  onChange,
  value = '',
  placeholder = '',
  sublabel,
  required,
  ...props
}: PasswordInputProps) => {
  const theme = useContext(ThemeContext);

  const renderLabel = () => {
    if (label || label === '' || required) {
      return (
        <ComponentsLabel
          name={name}
          label={label}
          sublabel={sublabel}
          required
        />
      );
    }
    return <></>;
  };

  return (
    <div
      className={`PasswordInput ${theme?.theme || ''} ${className || ''}`}
      key={name}
    >
      {renderLabel()}
      <PrimePassword
        {...props}
        id={name || 'pass'}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${errorMsg ? 'p-invalid' : ''}`}
        feedback={false}
        autoComplete={
          !autoComplete || (autoComplete && autoComplete === 'off')
            ? 'new-password'
            : autoComplete
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e);
          }
        }}
      />
      {errorMsg ? (
        <SimpleText className={'error p-mt-1'} fontSize={FONT_SIZE.XXXS}>
          {errorMsg}
        </SimpleText>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PasswordInput;
