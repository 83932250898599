import { PanelHeaderTemplateOptions } from 'primereact/panel';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import PanelComponent from 'src/components/Panel/Panel';

import './VariantPanel.scss';

type Props = {
  title: string;
  children: React.ReactElement;
  subtitle?: string;
  isOpened?: boolean;
  hasError?: boolean;
};

export const VariantPanel = (props: Props) => {
  const { title, children, subtitle, isOpened, hasError } = props;

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-right'
      : 'pi pi-chevron-down';

    const className = `p-d-flex p-ai-center p-p-2 ${
      options.collapsed ? 'variant-panel-collapsed' : 'variant-panel-expanded'
    }`;

    return (
      <div className={className}>
        <IconButton
          className="p-mr-3"
          type="button"
          icon={toggleIcon}
          btnType={IconBtnTypes.GREY}
          onClick={options.onTogglerClick}
        />

        <SimpleText fontColor={FONT_COLOR.COLOR_40} medium>
          {title}
        </SimpleText>

        {!!subtitle && <small className="subtitle-panel">{subtitle}</small>}
      </div>
    );
  };

  return (
    <PanelComponent
      className={`variant-panel ${hasError ? 'panel-error' : ''}`}
      headerTemplate={headerTemplate}
      toggleable
      collapsed={!isOpened}
    >
      {children}
    </PanelComponent>
  );
};
