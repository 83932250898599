/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import './FornecedorTussGlobal.scss';

import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Row } from './FornecedorGrid/Row';
import { Header } from './Header';
import ModalAddFornecedorTuss from './ModalAddFornecedorTuss/ModalAddFornecedorTuss';
import ModalExcluirFornecedorTuss from './ModalExcluirFornecedorTuss/ModalExcluirFornecedorTuss';

const FornecedorTussGlobal = () => {
  const [modalAddFornecedorTuss, setModalAddFornecedorTuss] = useState(false);
  const [fornecedorTuss, setFornecedorTuss] = useState<any>({});
  const [reload, setReload] = useState<number>(0);

  const [items, setItems] = useState<any[]>([]);

  const [modalExcluirFornecedorTuss, setModalExcluirFornecedorTuss] =
    useState(false);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_fornecedor_tuss_cadastrar',
      'adm_fornecedor_tuss_alterar',
      'adm_fornecedor_tuss_consultar',
      'adm_fornecedor_tuss_excluir',
    ],
  });

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleModalAddFornecedorTuss = useCallback(() => {
    setModalAddFornecedorTuss(true);
  }, []);

  const handleModalAddFornecedorTussClose = useCallback(() => {
    setModalAddFornecedorTuss(false);
    setFornecedorTuss({});
  }, []);

  const handleModalEditFornecedorTuss = useCallback((item: any) => {
    setFornecedorTuss(item);
    setModalAddFornecedorTuss(true);
  }, []);

  const fetchApi = async ({ query, ...rest }: any) => {
    return await FornecedorTussAPI.loadFornecedorTuss({
      nome: query,
      ...rest,
    });
  };

  const handleModalExcluirFornecedorTussClose = useCallback(() => {
    setModalExcluirFornecedorTuss(false);
    setFornecedorTuss({});
  }, []);

  const handleExcluirFornecedorTuss = useCallback((item: any) => {
    setFornecedorTuss(item);
    setModalExcluirFornecedorTuss(true);
  }, []);

  const handleIsActive = useCallback(
    async (item: any) => {
      const fornecedorEditado = {
        ...item,
        status: !item.status,
      };

      const response: any = await FornecedorTussAPI.updateFornecedorTussStatus(
        item?.id,
        fornecedorEditado,
      );

      if (response?.status === 200) {
        setItems([...items]);
        reloadList();
      }
    },
    [items],
  );

  const renderRow = (data: any): JSX.Element => (
    <Row
      data={data}
      onDelete={handleExcluirFornecedorTuss}
      onEdit={handleModalEditFornecedorTuss}
      onSwitchStatus={handleIsActive}
    />
  );

  return (
    <div id="FornecedorTussGlobal" className="p-px-4">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <div className="p-d-flex p-jc-between p-ai-center p-flex-wrap">
            <SearchList.SearchInput placeholder="Pesquisar por nome fornecedor" />

            <Button
              btnType="pill"
              className="p-mb-2"
              label="Novo Fornecedor"
              icon="fas fa-plus"
              onClick={handleModalAddFornecedorTuss}
              checkPermission="ADM_FORNECEDOR_TUSS_CADASTRAR"
            />
          </div>

          <SearchList.Header>
            <Header />
          </SearchList.Header>

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {modalAddFornecedorTuss && (
        <ModalAddFornecedorTuss
          fornecedorTussSelecionado={fornecedorTuss}
          visible={modalAddFornecedorTuss}
          reload={reloadList}
          onHide={handleModalAddFornecedorTussClose}
        />
      )}

      {modalExcluirFornecedorTuss && (
        <ModalExcluirFornecedorTuss
          fornecedorTuss={fornecedorTuss}
          reload={reloadList}
          visible={modalExcluirFornecedorTuss}
          onHide={handleModalExcluirFornecedorTussClose}
          fornecedorTussList={items}
          setFornecedorTussList={setItems}
        />
      )}

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default FornecedorTussGlobal;
