import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import ComplexidadePaciente from './ComplexidadePaciente/ComplexidadePaciente';
import Equipe from './Equipe/Equipe';
import MotivoTrasferencia from './MotivoTransferencia/MotivoTransferencia';
import Movimentacao from './Movimentacao/Movimentacao';
import PontuacaoCarteira from './PontuacaoCarteira/PontuacaoCarteira';
import Profissional from './Profissional/Profissional';

import './CarteiraAPS.scss';
const CarteiraAPS = () => {
  const { state: labelTabIndex } = useLocation();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const tabs = [
    {
      label: 'Pontuação carteira',
      content: <PontuacaoCarteira />,
      checkPermission: 'ADM_CARTEIRA_APS_PONTUACAO_CONSULTAR',
    },
    {
      label: 'Motivo da transferência',
      content: <MotivoTrasferencia />,
      checkPermission: 'ADM_CARTEIRA_APS_MOTIVO_TRANSFERENCIA_CONSULTAR',
    },
    {
      label: 'Complexidade do Paciente',
      content: <ComplexidadePaciente />,
      checkPermission: 'ADM_CARTEIRA_APS_COMPLEXIDADE_PACIENTE_CONSULTAR',
    },
    {
      label: 'Profissional',
      content: <Profissional />,
      hide:
        consultorios?.ativo?.tipoMonitoramentoCarteiraAps !== 'PROFISSIONAL',
      checkPermission: 'ADM_CARTEIRA_APS_PROFISSIONAL_CONSULTAR',
    },
    {
      label: 'Equipe',
      content: <Equipe />,
      hide: consultorios?.ativo?.tipoMonitoramentoCarteiraAps !== 'EQUIPE',
      checkPermission: 'ADM_CARTEIRA_APS_EQUIPE_CONSULTAR',
    },
    {
      label: 'Movimentação carteira',
      content: <Movimentacao />,
      checkPermission: 'ADM_CARTEIRA_APS_MOVIMETACAO_CONSULTAR',
    },
  ];

  const initialTabIndex = tabs.findIndex(tab => tab.label === labelTabIndex);
  return (
    <Page white className="carteira-APS">
      <>
        <PageHeader
          title="Configuração carteira APS"
          subtitle="Configure as faixas de pontuação."
        />
        <Tab
          initialTabIndex={initialTabIndex !== -1 ? initialTabIndex : 0}
          underHeader
          clean
          values={tabs}
        />
      </>
    </Page>
  );
};

export default CarteiraAPS;
