import { useEffect, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useCreateUser } from 'src/core/hooks/ManterUsuario/useCreateUser';

import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import FormInput from 'src/components/FormInput/FormInput';

interface SexoOption {
  label: string;
  value: number;
}
interface GenerallProps {
  control: any;
  errors: any;
  sexoOptions: SexoOption[] | [];
  setValue: (fieldName: any, value: any) => void;
}

function General({ sexoOptions, setValue }: GenerallProps) {
  const { mandatoryData } = useCreateUser();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const setFormValues = useCallback(() => {
    setValue('nome', mandatoryData?.nome);
    setValue('idSexo', mandatoryData?.dadosPessoais?.idSexo);
    setValue('dataNascimento', mandatoryData?.dadosPessoais?.dataNascimento);
    setValue('cpf', mandatoryData?.documentos?.cpf);
    setValue('emailPessoal', mandatoryData?.emailPessoal);
    setValue('emailProfissional', mandatoryData?.emailProfissional);
  }, [mandatoryData, setValue]);

  useEffect(() => {
    setFormValues();
  }, [setFormValues]);

  return (
    <>
      <FormInput
        name="nome"
        className="p-col-12"
        label="Nome completo"
        placeholder="Nome do usuário"
        hideTextErrorSpace
        maxLength={50}
      />
      <SelectButtonControlled
        control={control}
        name="idSexo"
        className="p-col-12 p-px-0"
        label="Sexo"
        options={sexoOptions}
      />
      <CalendarInputControlled
        control={control}
        name="dataNascimento"
        className="p-col-12 p-md-6"
        hideOnDateTimeSelect
        placeholder="dd/mm/aaaa"
        label={'Data de nascimento'}
        maxDate={new Date()}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
      <MaskedInputControlled
        control={control}
        name="cpf"
        placeholder="000.000.000-00"
        className="p-col-12 p-md-6"
        label={'CPF'}
        mask={MASK.CPF}
        errorMsg={errors.cpf?.message}
      />
      <FormInput
        name="emailPessoal"
        label="E-mail pessoal do usuário"
        className="p-col-12"
        placeholder="exemplo@teste.com.br"
        hideTextErrorSpace
        maxLength={255}
      />
      <FormInput
        name="emailProfissional"
        label="E-mail profissional do usuário"
        placeholder="exemplo@teste.com.br"
        className="p-col-12"
        maxLength={255}
      />
    </>
  );
}

export default General;
