import { ProntuarioMedicamento } from 'src/models/APIs/ProntuarioAPI/MedicamentosAPI/MedicamentosAPI';

import Http, { HttpParams } from 'src/core/http/Http';

const basepath = '/medicamentos';

const loadMedicamentos = async (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<ProntuarioMedicamento[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}`,
    hideToast: true,
    params: payload,
    ...options,
  });
};

export const MedicamentosAPI = {
  loadMedicamentos,
};
