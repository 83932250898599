import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import AGAAPI from 'src/APIs/ProntuarioAPI/AGAAPI/AGAAPI';

import { AGAContext } from 'src/core/contexts/AGA/AGA';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

interface AGAProviderProps {
  children: ReactNode;
}

interface GroupedData {
  [key: string]: {
    formularioPai: number;
    lista: any[];
  };
}

export function AGAProvider({ children }: AGAProviderProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [listaFormularios, setListaFormularios] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [groupedForms, setGroupedForms] = useState<GroupedData>({} as any);
  const [tabAtiva, setTabAtiva] = useState<number>(0);
  const [maxTabs, setMaxTabs] = useState<number>(0);
  const [respondeuTodasPerguntas, setRespondeuTodasPerguntas] =
    useState<boolean>(false);
  const [avancarSemResponder, setAvancarSemResponder] =
    useState<boolean>(false);
  const [reloadHistorico, setReloadHistorico] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean | null>(null);
  const [formularioEdit, setFormularioEdit] = useState<any>(null);
  const [evolucaoHistorico, setEvolucaoHistorico] = useState<any>(null);

  const confirmDialogDisclosure = useDisclosure({ opened: false });
  const evolucaoDialogDisclosure = useDisclosure({ opened: false });
  const historicoDialogDisclosure = useDisclosure({ opened: false });
  const diagnosticoFuncionalDisclosure = useDisclosure({ opened: false });

  const cancelPreenchimentoFormulario = useCallback(() => {
    setIsEdit(null);
    setFormularioEdit(null);
  }, []);

  useEffect(() => {
    setReloadHistorico((prev: number) => prev + 1);
  }, [isEdit]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await AGAAPI.getListaFormularios();

        setListaFormularios(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const newGroupedData: {
      [key: string]: { formularioPai: number; lista: any[] };
    } = {};

    const formsComPerguntas = listaFormularios.filter(
      (form: any) => form.perguntas.length > 0,
    );

    formsComPerguntas.forEach(item => {
      const idAgaFormularioPai = item.idAgaFormularioPai;

      if (!newGroupedData[idAgaFormularioPai]) {
        newGroupedData[idAgaFormularioPai] = {
          formularioPai: idAgaFormularioPai,
          lista: [],
        };
      }

      newGroupedData[idAgaFormularioPai]?.lista.push(item);
    });

    setGroupedForms(newGroupedData);
  }, [listaFormularios]);

  const scrollToTopAGA = useCallback(() => {
    const tabAGA = document.querySelector('#TabAGA');
    const tabPanel = tabAGA?.querySelector('.p-tabview-panel');

    if (tabPanel) {
      tabPanel.scrollTop = 0;
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const prevCurrentStep = useCallback(() => {
    if (currentStep === 0 && tabAtiva === 0) return;
    if (tabAtiva <= maxTabs && tabAtiva > 0) {
      setTabAtiva(prev => prev - 1);
      setIsEdit(false);
      setFormularioEdit(null);
      scrollToTopAGA();
      return;
    }
    setCurrentStep(prev => prev - 1);
    scrollToTopAGA();
  }, [currentStep, maxTabs, scrollToTopAGA, tabAtiva]);

  const ultimoStep = currentStep === 6 && tabAtiva === maxTabs;

  const nextCurrentStep = useCallback(() => {
    if (ultimoStep) return;
    if (currentStep === 6 && tabAtiva >= maxTabs) {
      return setReloadHistorico((prev: number) => prev + 1);
    }
    if (tabAtiva < maxTabs) {
      setTabAtiva(prev => prev + 1);
      setIsEdit(false);
      setFormularioEdit(null);
      scrollToTopAGA();
      return;
    }
    setCurrentStep(prev => prev + 1);
    setTabAtiva(0);
    setIsEdit(false);
    setFormularioEdit(null);
    setReloadHistorico((prev: number) => prev + 1);
    scrollToTopAGA();
  }, [currentStep, maxTabs, scrollToTopAGA, tabAtiva, ultimoStep]);

  const idFormularioPai = useMemo(() => {
    switch (currentStep) {
      case 0:
        return 20;
      case 1:
        return 3;
      case 2:
        return 7;
      case 3:
        return 2;
      case 4:
        return 12;
      case 5:
        return 14;
      case 6:
        return 17;
      default:
        return 0;
    }
  }, [currentStep]);

  const questionariosList = groupedForms[idFormularioPai]?.lista;

  const activeFormulario = useMemo(() => {
    if (questionariosList) {
      return questionariosList[tabAtiva];
    }
    return null;
  }, [questionariosList, tabAtiva]);

  return (
    <AGAContext.Provider
      value={{
        state: {},
        currentStep,
        setCurrentStep,
        prevCurrentStep,
        nextCurrentStep,
        groupedForms,
        loading,
        tabAtiva,
        setTabAtiva,
        maxTabs,
        setMaxTabs,
        ultimoStep,
        confirmDialogDisclosure,
        respondeuTodasPerguntas,
        setRespondeuTodasPerguntas,
        avancarSemResponder,
        setAvancarSemResponder,
        idFormularioPai,
        questionariosList,
        activeFormulario,
        reloadHistorico,
        setReloadHistorico,
        historicoDialogDisclosure,
        evolucaoDialogDisclosure,
        isEdit,
        setIsEdit,
        cancelPreenchimentoFormulario,
        formularioEdit,
        setFormularioEdit,
        evolucaoHistorico,
        setEvolucaoHistorico,
        scrollToTopAGA,
        listaFormularios,
        diagnosticoFuncionalDisclosure,
      }}
    >
      {children}
    </AGAContext.Provider>
  );
}
