import { useState, useEffect, useCallback } from 'react';

import { useParams, useNavigate } from 'react-router';

import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import Tab from 'src/components/Tab/Tab';

import Header from './Header/Header';
import Visualizacao from './Visualizacao/Visualizacao';

import './VisualizarPaciente.scss';

const VisualizarPaciente = () => {
  const { idPaciente } = useParams();

  const navigate = useNavigate();

  const [paciente, setPaciente] = useState<any>();
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const loadPacienteData = useCallback(async () => {
    if (!idPaciente) return;

    const response = await PacienteAPI.visualizarPaciente(Number(idPaciente));

    if (!response?.id) navigate('/pacientes', { replace: true });

    setPaciente(response);

    setLoadingData(false);
  }, [idPaciente, navigate]);

  useEffect(() => {
    loadPacienteData();
  }, [loadPacienteData]);

  return (
    <div id="visualizar-paciente-container">
      <Header paciente={paciente} loadingData={loadingData} />
      <Tab
        underHeader
        values={[
          {
            label: 'Visualização',
            content: <Visualizacao paciente={paciente} loadingData={loadingData} />
          }
        ]}
      />
    </div>
  );
}

export default VisualizarPaciente;