import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  servicoTuss: any;
  radioButtonPrincipal: JSX.Element;
  inputQuantidade: JSX.Element;
  onDelete: () => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { servicoTuss, radioButtonPrincipal, inputQuantidade, onDelete } =
    props;

  return (
    <div className="pacote-servico-tuss-item p-grid">
      <div className="p-col-2 p-lg-1">{radioButtonPrincipal}</div>

      <SimpleText
        className="p-col-3 p-lg-2"
        fontColor={FONT_COLOR.COLOR_16}
        fontSize={FONT_SIZE.XXS}
        medium
      >
        {servicoTuss.codigo}
      </SimpleText>
      <div className="servico-tuss-descricao-container p-col-4 p-lg-7">
        <SimpleText fontColor={FONT_COLOR.COLOR_40} fontSize={FONT_SIZE.XXS}>
          {servicoTuss.nome}
        </SimpleText>
      </div>

      <div className="p-col-2 p-lg-1">{inputQuantidade}</div>

      <Button
        className="p-col-1"
        btnType="gray"
        icon="fas fa-trash"
        onClick={onDelete}
      />
    </div>
  );
};
