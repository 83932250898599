import { Divider, DividerProps } from 'primereact/divider';
import './Separator.scss';

export interface SeparatorProps extends DividerProps {
  spaceOnly?: boolean;
  vertical?: boolean;
}

const Separator = ({
  className = '',
  spaceOnly,
  vertical,
  ...props
}: SeparatorProps) => {
  if (spaceOnly) {
    return (
      <div
        className={`Separator space-only ${
          vertical ? 'vertical' : 'horizontal'
        } ${className}`}
        {...props}
      />
    );
  }
  return <Divider className={`Separator ${className}`} {...props} />;
};

export default Separator;
