import { BlipChat } from 'blip-chat-widget';

export const useChatBot = (sourceUrl: string, icon?: string) => {
  const handleZendesk = () => {
    const zendesk = document.querySelector('#ze-snippet');
    if (!!zendesk) {
      window.zE.activate({ hideOnClose: true });
      return;
    }

    const head: any = document.querySelector('head');
    const scriptEl = document.createElement('script');

    scriptEl.setAttribute('id', 'ze-snippet');
    scriptEl.setAttribute('src', sourceUrl);
    head.appendChild(scriptEl);

    setTimeout(() => {
      window.zE('webWidget', 'setLocale', 'pt-BR');
      window.zE('webWidget', 'hide');
    }, 500);

    setTimeout(() => {
      window.zE.activate({ hideOnClose: true });
    }, 1000);
  };

  const handleBlipChat = () => {
    const key = sourceUrl.substring(sourceUrl?.indexOf('appKey=') + 7);

    window.blipClient = new BlipChat()
      .withAppKey(key)
      .withButton({
        color: '#00a550',
        icon: icon,
      })
      .withCustomCommonUrl('https://qualitysoftware.chat.blip.ai/')
      .withEventHandler(BlipChat.LEAVE_EVENT, () =>
        window.blipClient.destroy(),
      );

    window.blipClient.build();
    window.blipClient.toogleChat();
  };

  const handleSelectChat = () => {
    if (!!sourceUrl) {
      if (sourceUrl?.indexOf('zdassets') !== -1) {
        handleZendesk();
      } else {
        handleBlipChat();
      }
    }
  };

  return { handleSelectChat };
};
