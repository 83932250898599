import Http, { HttpParams } from 'src/core/http/Http';

export const getProfissionais = (
  idConsultorio: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/profissional-saude/consultorio/${idConsultorio}/cuidador`,
    params,
    hideToast: true,
    ...options,
  });
};

export const putGrupoPontuacaoProfissional = (
  idConsultorio: number,
  idProfissional: number,
  idGrupoPontuacao: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/profissional-saude/consultorio/${idConsultorio}/cuidador/${idProfissional}/pontuacao/${idGrupoPontuacao}`,
    return: true,
    ...options,
  });
};

export const ProfissionalAPI = {
  getProfissionais,
  putGrupoPontuacaoProfissional,
};
