import { memo, useCallback, useMemo, useRef, useState } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button, Checkbox } from '../_UI';
import Divider from '../Basics/Divider/Divider';
import SimpleText from '../Basics/SimpleText/SimpleText';
import Dialog from '../Dialog/Dialog';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from '../Timeline/Timeline';

import { AssistenteVirtualDialogContent } from './AssistenteVirtualDialogContent';
import { Etapas } from './types';

import './AssistenteVirtualDialog.scss';

type Props = DisclosureType & {
  canHide?: boolean;
  onClose?: (id: number, isChecked?: boolean) => void;
};

export const AssistenteVirtualDialog = memo(
  ({ close, state: item, canHide = false, onClose }: Props) => {
    const [isChecked, setChecked] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const timelineRef = useRef<TimelineImperativeAPI>(null);

    const tabOptions: TimelineValue[] = (item?.etapas || []).map(
      (etapa: Etapas) => ({
        title: etapa.nome,
        component: <AssistenteVirtualDialogContent item={etapa} />,
      }),
    );

    const hasMore = useMemo(() => {
      return tabOptions.length > tabIndex + 1;
    }, [tabIndex, tabOptions.length]);

    const headerTitle = item.primeiroAcesso
      ? 'Primeiro Acesso'
      : item.novidade
      ? 'Novidade'
      : item.nome;

    const handleClose = useCallback(async () => {
      onClose && onClose(item.id, isChecked);
      close();
    }, [close, isChecked, item.id, onClose]);

    return (
      <Dialog
        id="Dialog-assistente-virtual"
        visible
        onHide={handleClose}
        header={<DialogHeader title={headerTitle} />}
      >
        <div className="p-d-flex p-flex-column p-gap-3">
          {item.primeiroAcesso && (
            <>
              <SimpleText fontSize="md">
                Olá, seja bem vindo ao assistente virtual!
              </SimpleText>
              <SimpleText fontSize="sm">
                Como esse é seu primeiro acesso, acompanhe os tutoriais abaixo.
                Seguindo o passo a passo, poderá aprender como acessar as
                principais funcionalidades do sistema.
              </SimpleText>
            </>
          )}
          {item.novidade && (
            <>
              <SimpleText fontSize="md">
                Olá, tem novidade no sistema!
              </SimpleText>
              <SimpleText fontSize="sm">
                Você já conhece a nova funcionalidade de{' '}
                <b>{item.funcionalidade?.descricao || item.nome}</b>
              </SimpleText>
            </>
          )}

          <SimpleText fontColor="color_40">{item.descricao}</SimpleText>

          <Timeline
            inline
            ref={timelineRef}
            onChange={setTabIndex}
            values={tabOptions}
          />

          {canHide && (
            <Checkbox
              label="Não visualizar essa dica na próxima abertura do sistema"
              checked={isChecked}
              onChange={e => setChecked(e.checked)}
            />
          )}

          <Divider layout="horizontal" className="p-w-100 p-m-0" />

          <div className="p-w-100 p-d-flex p-gap-1">
            <Button
              btnType="ghost"
              label="Fechar"
              onClick={handleClose}
              stretch
            />
            <Button
              btnType="tonal"
              label="Próximo passo"
              onClick={() => timelineRef.current?.next?.()}
              stretch
              disabled={!hasMore}
            />
          </div>
        </div>
      </Dialog>
    );
  },
);

const DialogHeader = ({ title }: { title: string }) => {
  return (
    <div className="p-d-flex p-col-12 p-px-0">
      <span className="tag tag-sm tag-outline tag-tonal p-mr-2">
        Assistente virtual
      </span>
      <SimpleText medium fontSize="sm">
        {title}
      </SimpleText>
    </div>
  );
};
