import { useCallback } from 'react';

import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton from 'src/components/SplitButton/SplitButton';

import ChipAgendamento from 'src/pages/Emed/Agenda/MenuAgenda/ChipAgendamento/ChipAgendamento';

import { useAgenda } from '../../../AgendaContext';
import ModalCardPaciente from '../../ModalCardPaciente/ModalCardPaciente';
import Status from '../../Status/Status';

import './CardAgendamento3Dias.scss';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { generateUniqueId } from 'src/utils/utils';

interface CardAgendamento3DiasProps {
  agendamento: AtendimentoAgendamento;
  selectedDate: Date;
  sigilo: JSX.Element;
  isOnline?: boolean;
  renderActions?: any;
}
const CardAgendamento3Dias = ({
  agendamento,
  selectedDate,
  isOnline,
  renderActions,
}: CardAgendamento3DiasProps) => {
  const { dialogEditarPaciente, dialogDetalhesAgendamento } = useAgenda();

  const uid = generateUniqueId();

  const isAgendamentoGrupo = useCallback((): boolean => {
    if (agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo) {
      return true;
    }

    return false;
  }, [agendamento.idAtendimentoEmGrupo, agendamento.idGrupoAtendimento]);

  const renderChips = useCallback(() => {
    return (
      <div className="chips">
        {agendamento?.flagsAtivas?.map(item => (
          <ChipAgendamento key={item} type={item} />
        ))}
        {isOnline && agendamento?.flagsAtivas?.includes('TELECONSULTA') && (
          <div className="paciente-online-chip">
            <SimpleText>Paciente Online</SimpleText>
          </div>
        )}
      </div>
    );
  }, [agendamento?.flagsAtivas, isOnline]);

  const renderChipsInfo = useCallback(() => {
    return (
      <>
        <div className="upper-middle">{renderChips()}</div>

        {isAgendamentoGrupo() && (
          <div className="bottom-middle p-mt-1">
            <SimpleText
              bold
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.PRIMARY}
            >
              {agendamento.apelidoGrupo}
            </SimpleText>

            <SimpleText
              className="p-ml-2"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              • {agendamento.quantidadePaciente} pacientes
            </SimpleText>
          </div>
        )}

        {agendamento?.paciente?.nome ? (
          <div className="bottom-middle">
            <SplitButton
              label={agendamento?.paciente?.nome || ''}
              className="modal-card-paciente"
              model={[
                {
                  template: (
                    <ModalCardPaciente
                      paciente={agendamento?.paciente}
                      setExibirModal={() =>
                        dialogEditarPaciente.open({
                          state: agendamento?.paciente,
                        })
                      }
                    />
                  ),
                },
              ]}
              menuClassName="menu-card-paciente"
            />

            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_40}
              style={{ marginLeft: 5 }}
            >
              {agendamento?.convenio?.nomeFantasia ||
                agendamento?.convenio?.razaoSocial}
            </SimpleText>
          </div>
        ) : (
          <div className="bottom-middle">
            <SimpleText fontColor={FONT_COLOR.PRIMARY} medium>
              {agendamento?.agendamentoRapidoNome}
            </SimpleText>
          </div>
        )}
      </>
    );
  }, [agendamento, dialogEditarPaciente, isAgendamentoGrupo, renderChips]);

  return (
    <div
      className={`CardAgendamento3Dias animationFadeRight p-d-flex p-flex-column p-jc-between ${
        agendamento.status === 'BLOQUEADO' ? 'card-bloqueado' : ''
      }`}
    >
      <div className="p-d-flex p-jc-around p-ai-center p-w-100 p-mb-2">
        <div className="left">
          <div className="top">
            <SimpleText fontSize={FONT_SIZE.SM} fontColor={FONT_COLOR.COLOR_40}>
              {agendamento?.dataAgendamento
                ? dayjs(agendamento?.dataAgendamento).format('HH:mm')
                : ''}
            </SimpleText>

            <div className="p-d-flex p-gap-2">
              <Status agendamento={agendamento} selectedDate={selectedDate} />

              {renderActions}
            </div>
          </div>

          <div className="middle">
            {agendamento?.status !== 'BLOQUEADO' ? renderChipsInfo() : <></>}
          </div>
        </div>

        {agendamento.status !== 'LIVRE' &&
          agendamento.status !== 'BLOQUEADO' && (
            <div
              className="right"
              onClick={() =>
                dialogDetalhesAgendamento.open({ state: agendamento })
              }
            >
              <i className="fas fa-chevron-right" />
            </div>
          )}
      </div>

      {!!agendamento?.observacao?.trim() && (
        <div className="p-d-flex observacao p-p-1">
          <SimpleText
            className="p-mr-2"
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_79}
          >
            Observação:
          </SimpleText>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            className={`observacao-agendamento-${uid} cursor-pointer`}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Tooltip
              target={`.observacao-agendamento-${uid}`}
              className="p-w-100"
              autoHide={false}
              position="bottom"
            >
              {agendamento.observacao}
            </Tooltip>
            {agendamento.observacao}
          </SimpleText>
        </div>
      )}
    </div>
  );
};

export default CardAgendamento3Dias;
