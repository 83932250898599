import dayjs from 'dayjs';

import { useHistoricoAtendimento } from 'src/core/hooks/Atendimento/useHistoricoAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import ModalRevogarSigilo from 'src/pages/Emed/Agenda/Sigilo/ModalRevogarSigilo/ModalRevogarSigilo';
import ModalSigilo from 'src/pages/Emed/Agenda/Sigilo/ModalSigilo/ModalSigilo';

interface Props {
  atendimento: any;
}

export const CabecalhoHistoricoAtendimento = ({ atendimento }: Props) => {
  const { agenda } = useAppSelector((state: RootState) => state);

  const paciente = atendimento.atendimento?.paciente;

  const { setUpdateList } = useHistoricoAtendimento();

  const modalSigilo = useDisclosure({ opened: false });
  const modalRevogarSigilo = useDisclosure({ opened: false });

  const nivelSigilo = atendimento.atendimento.nivelSigilo;
  const pacienteSigilo = { paciente, sigilo: atendimento.atendimento?.sigilo };

  const handleTypeIconSigilo = (nvlSigilo: any) => {
    if (nvlSigilo === 0) {
      return 'fa-solid fa-lock';
    }
    return 'fa-solid fa-lock-open';
  };

  const handleColorIconSigilo = (nvlSigilo: any) => {
    if (nvlSigilo === 0) {
      return ' red';
    }
    if (nvlSigilo === 1) {
      return ' yellow';
    }
    return ' green';
  };

  return (
    <div className="p-d-flex p-ai-center">
      <Avatar label={atendimento?.atendimento.medico.nome} />
      <div className="p-d-flex p-flex-column p-ml-2">
        <div className="p-d-flex p-flex-column p-flex-sm-row p-ai-start p-ai-sm-center p-jc-left">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {atendimento?.atendimento.medico.nome}
          </SimpleText>
          <SimpleText
            className="p-ml-2 p-js-start"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            (CRM {atendimento?.atendimento.medico.numeroConselho}
            {' • '}
            {atendimento?.atendimento.medico.estadoConselho}
            {atendimento?.atendimento.especialidade
              ? ' • ' + atendimento?.atendimento.especialidade.nome
              : ''}
            )
          </SimpleText>
        </div>
        <div className="p-d-flex p-mt-1 p-ai-center">
          {atendimento?.atendimento?.complemento && (
            <>
              <div className="complemento-chip">
                <SimpleText fontSize={FONT_SIZE.XXXS} medium>
                  Complemento
                </SimpleText>
              </div>
              <SimpleText fontSize={FONT_SIZE.XXXS} className="p-px-2">
                {' • '}
              </SimpleText>
            </>
          )}
          <SimpleText
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
            className="p-py-1"
          >
            ({'Em '}
            {atendimento?.atendimento?.dataAgendamento
              ? `${dayjs(atendimento?.atendimento.dataAgendamento).format(
                  'DD/MM/YYYY',
                )} às ${dayjs(atendimento?.atendimento.dataAgendamento).format(
                  'HH:mm',
                )}`
              : 'hora não informada'}{' '}
            -{' '}
            {atendimento?.atendimento.status === 'TRIAGEM_FINALIZADA'
              ? dayjs(atendimento?.atendimento.horaAtendimentoTriagem).format(
                  'HH:mm',
                )
              : atendimento?.atendimento.horaSaida
              ? dayjs(atendimento?.atendimento.horaSaida).format('HH:mm')
              : 'Não finalizado'}
            ) {atendimento?.status === 'INATIVO' && '- Inativado'}
            {atendimento?.atendimento.status === 'TRIAGEM_FINALIZADA' &&
              '- Triagem'}
          </SimpleText>
          {atendimento?.atendimento?.justificativaOcorrencia && (
            <div className="p-d-flex p-ai-center">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
                className="p-px-2"
              >
                {' • '}
              </SimpleText>
              <SimpleText className="tag tag-retroativo" fontSize="xxxs">
                Retroativo
              </SimpleText>
            </div>
          )}
          {agenda?.profissionalAtivo?.id !==
            atendimento?.atendimento.medico.id && (
            <>
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
                className="p-px-2"
              >
                {' • '}
              </SimpleText>
              <i
                onClick={() =>
                  nivelSigilo === 0
                    ? modalSigilo.open()
                    : modalRevogarSigilo.open()
                }
                className={
                  handleTypeIconSigilo(nivelSigilo) +
                  handleColorIconSigilo(nivelSigilo)
                }
                style={{ cursor: 'pointer' }}
              ></i>
            </>
          )}
        </div>
      </div>
      {modalSigilo.isOpen && (
        <ModalSigilo
          onSuccess={() => [setUpdateList(old => old + 1), modalSigilo.close()]}
          paciente={paciente}
          visible={modalSigilo.isOpen}
          onCancel={() => [modalSigilo.close()]}
        />
      )}
      {modalRevogarSigilo.isOpen && (
        <ModalRevogarSigilo
          onSuccess={() => [setUpdateList(old => old + 1), modalSigilo.close()]}
          paciente={pacienteSigilo}
          visible={modalRevogarSigilo.isOpen}
          onCancel={() => [modalRevogarSigilo.close()]}
        />
      )}
    </div>
  );
};
