import { useCallback, useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';

import { GestaoMonitoramentoFormularioAPI } from 'src/APIs/AdminAPI/GestaoMonitoramentoFormularioAPI/GestaoMonitoramentoFormularioAPI';
import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import * as SearchList from './components/SearchList/SearchListRoot';
import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import PageHeader from 'src/components/PageHeader/PageHeader';

import { FormularioDinamicoProvider } from '../AplicacaoFormulario/useFormularioDinamicoAplicacaoFormulario';

import { FormularioDinamicoGestaoMonitoramentoFilter } from './FormularioDinamicoGestaoMonitoramentoFilter';
import { FormularioDinamicoGestaoMonitoramentoRow } from './FormularioDinamicoGestaoMonitoramentoRow';
import { FormulariosSearch } from './FormulariosSearch';
import {
  GestaoMonitoramentoProvider,
  useGestaoMonitoramento,
} from './hooks/GestaoMonitoramentoContext';
import { PacientesSearch } from './PacientesSearch';

import './GestaoMonitoramento.scss';

const items: { [key: string]: string } = {
  dataInclusao: 'Data de monitoramento',
};

export const FormularioDinamicoGestaoMonitoramento = () => (
  <Page>
    <>
      <PageHeader
        title="Gestão e monitoramento"
        subtitle="Filtre e monitore os formulários que foram aplicados."
      />
      <Page className="page-content" content white>
        <GestaoMonitoramentoProvider>
          <FormularioDinamicoProvider>
            <FormularioDinamicoGestaoMonitoramentoComp />
          </FormularioDinamicoProvider>
        </GestaoMonitoramentoProvider>
      </Page>
    </>
  </Page>
);

const FormularioDinamicoGestaoMonitoramentoComp = () => {
  const { filters, selectedAllForms, selectedAllPacientes } =
    useGestaoMonitoramento();

  const { atendimentoStatus } = useAtendimento();

  const autoHeight = atendimentoStatus?.includes('ATENDENDO');

  const filtersRef = useRef<OverlayPanel>(null);

  const [sortBy, setSortBy] = useState('dataInclusao');
  const [nome, setNome] = useState('');

  const itemsOrdenation: MenuItem[] = [
    {
      label: 'data de monitoramento',
      command: () => setSortBy('dataInclusao'),
    },
  ];

  const fetch = useCallback(
    async (params: any) => {
      try {
        if (
          !filters.idsAplicacao.length &&
          !filters.idsPacientes.length &&
          !nome &&
          !selectedAllForms &&
          !selectedAllPacientes
        )
          return { list: [] };

        const data = await GestaoMonitoramentoFormularioAPI.loadAvaliacoes({
          nomeFormulario: nome,
          sortBy,
          ...filters,
          ...params,
        });
        return data;
      } catch {
      } finally {
      }
      return;
    },
    [filters, nome, selectedAllForms, selectedAllPacientes, sortBy],
  );

  const handleNome = useThrottle((v: string) => setNome(v), 750);

  return (
    <div id="gestao-monitoramento" className="p-grid p-w-100">
      <FormulariosSearch />
      <PacientesSearch />

      <SearchList.Root fetchApi={fetch} reloadWhenEmpresaChange empresaRequired>
        <div className="p-grid p-d-flex p-flex-column p-w-100">
          <SimpleText className="p-col-12" fontSize={FONT_SIZE.SM} medium>
            Dados do monitoramento
          </SimpleText>
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
            Filtre e monitore a pontuação obtida em cada formulário
          </SimpleText>

          <div className="p-grid p-col-12 p-px-0 p-ai-end p-jc-center p-gap-2">
            <div className="p-d-flex p-col-12 p-md-10">
              <TextInputSearch
                placeholder="Pesquise pelo nome"
                onChange={v => handleNome(v)}
              />
            </div>

            <Button
              label="Pesquisa avançada"
              icon="fas fa-chevron-down"
              type="button"
              btnType="tonal"
              iconPos="right"
              className="p-col"
              onClick={e => filtersRef.current?.toggle(e)}
            />
          </div>

          <div className="p-d-flex p-jc-end p-ai-center p-gap-2 p-my-2">
            <SimpleText>Ordenar por:</SimpleText>
            <ButtonMenu
              label={items[sortBy]!}
              btnType="green-link"
              model={itemsOrdenation}
            />
          </div>
        </div>

        <div className="p-mb-4 p-w-100">
          <div className="p-col-12 p-pt-0 p-d-flex p-flex-column p-gap-2">
            <SearchList.InfiniteScroll
              autoHeight={autoHeight}
              customEmptyText={
                'Utilize os filtros ou faça uma busca para encontrar os dados de monitoramento.'
              }
              renderRow={item => (
                <FormularioDinamicoGestaoMonitoramentoRow item={item} />
              )}
            />
          </div>
        </div>

        <FormularioDinamicoGestaoMonitoramentoFilter ref={filtersRef} />
      </SearchList.Root>
    </div>
  );
};
