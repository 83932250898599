import Http, { HttpParams } from 'src/core/http/Http';

export const loadHorarioAtendimento = (
  idMedico: number,
  idConsultorio: number,
  page: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetHorarioAtendimentoDTO[]>> => {
  return Http.get({
    service: 'agenda',
    url: `/configuracoes-agenda/busca/${idMedico}/consultorio/${idConsultorio}`,
    params: { page },
    hideToast: true,
    ...options,
  });
};

export const createHorarioAtendimento = (
  payload: PostHorarioAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostHorarioAtendimentoDTO> => {
  return Http.post({
    service: 'agenda',
    url: '/configuracoes-agenda',
    data: payload,
    successMessage: 'Horários configurados com sucesso.',
    ...options,
  });
};

export const updateHorarioAtendimento = (
  payload: PostHorarioAtendimentoDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostHorarioAtendimentoDTO> => {
  return Http.put({
    service: 'agenda',
    url: '/configuracoes-agenda',
    data: payload,
    successMessage: 'Configuração alterada com sucesso.',
    ...options,
  });
};

export const deleteHorarioAtendimento = (
  id: number,
  idMedico: number,
  idConsultorio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetHorarioAtendimentoDTO> => {
  return Http.delete({
    service: 'agenda',
    url: `configuracoes-agenda/${id}/medico/${idMedico}/consultorio/${idConsultorio}`,
    successMessage: 'Configuração removida com sucesso.',
    ...options,
  });
};

const HorarioAtendimentoAPI = {
  loadHorarioAtendimento,
  createHorarioAtendimento,
  updateHorarioAtendimento,
  deleteHorarioAtendimento,
};

export default HorarioAtendimentoAPI;
