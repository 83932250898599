import React from 'react';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

const Header = () => {
  return (
    <div className="p-grid p-flex-1 p-p-1">
      <div className="p-col-6 p-sm-4 p-md-2 ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Consultorio
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Código
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-4 ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Descrição
        </SimpleText>
      </div>
    </div>
  );
};

export default Header;
