import { Button } from '../_UI/Button';
import SimpleText from '../Basics/SimpleText/SimpleText';

import Dialog, { DialogProps } from './Dialog';
import './Dialog.scss';

interface InfoDialogProps extends DialogProps {
  closeText?: string;
  text?: string;
  closeBtnType?: any;
}

const InfoDialog = ({
  children,

  header,
  closeText = 'Fechar',
  closeBtnType = 'outline',
  visible,
  onHide,
  text,
  ...rest
}: InfoDialogProps) => {
  return (
    <Dialog
      visible={visible || false}
      position={'center'}
      closable
      dismissableMask={true}
      closeOnEscape={true}
      header={header}
      onHide={onHide}
      className="footer-on"
      style={{ maxWidth: '600px' }}
    >
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText>{text}</SimpleText>
        {children}
        <div className="p-d-flex p-gap-2 p-col-12 p-px-0">
          <div className={'p-col-12 p-px-0'}>
            <Button
              label={closeText}
              onClick={() => onHide()}
              btnType={closeBtnType}
              stretch
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InfoDialog;
