import { useManterConvenio } from 'src/core/hooks/ManterConvenio/useManterConvenio';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tab from 'src/components/Tab/Tab';

import { ConfigTissAuditoria } from './ConfigTissAuditoria';
import { FormTiss } from './FormTiss';
import { FormWsdlTiss } from './FormWsdlTiss';
import PortalPaciente from './PortalPaciente';
import ProcedimentosSeriadosConvenio from './ProcedimentosSeriadosConvenio';
import './ConfigTiss.scss';

export const ConfigTiss = () => {
  const { basicInfoStepData } = useManterConvenio();

  const { user } = useAppSelector((state: RootState) => state);

  const render = () => {
    if (basicInfoStepData?.isParticular) {
      return (
        <>
          <SimpleText
            className="p-col-12 p-px-3"
            fontColor={FONT_COLOR.COLOR_16}
          >
            TISS
          </SimpleText>
          <FormTiss />
        </>
      );
    }

    return (
      <Tab
        clean
        values={[
          {
            label: 'TISS',
            content: <FormTiss />,
          },
          {
            label: 'WSDL TISS',
            content: <FormWsdlTiss />,
          },
          {
            label: 'Portal do paciente',
            content: <PortalPaciente />,
          },
          {
            label: 'Auditoria',
            content: <ConfigTissAuditoria />,
          },
          {
            label: 'Procedimentos seriados',
            content: <ProcedimentosSeriadosConvenio />,
          },
        ]}
      />
    );
  };

  return (
    <div className="p-grid p-col-12">
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
        Configuração TISS - {user.idEmpresa ? user.nomeEmpresa : 'Cliente'}
      </SimpleText>
      <SimpleText className="p-col-12 p-mb-5" fontColor={FONT_COLOR.COLOR_40}>
        Configure as integrações TISS do convênio
      </SimpleText>

      {render()}
    </div>
  );
};
