import React, { memo, useCallback, useEffect } from 'react';

import { useFormContext, useFieldArray } from 'react-hook-form';

import dayjs from 'dayjs';
import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import Separator from 'src/components/Separator/Separator';

interface AgendamentoPedidoCuidadoProps {
  agendamento: any;
  index: number;
  onRemove: (index: number) => void;
  isEditing: boolean;
}

const AgendamentoPedidoCuidado = memo(
  ({
    agendamento,
    index,
    onRemove,
    isEditing,
  }: AgendamentoPedidoCuidadoProps) => {
    const { consultorios } = useAppSelector((state: RootState) => state);

    const {
      control,
      watch,
      getValues,
      setValue,
      formState: { errors },
    } = useFormContext();

    const watchProfissionalSaude = watch('profissionalSaude');

    const [horarioProgramadoOptions, setHorarioProgramadoOptions] =
      React.useState<any[]>([]);
    const [alertVisible, setAlertVisible] = React.useState<boolean>(false);

    const dataProgramada = watch(`agendamentos.${index}.dataProgramada`);

    const getHorarios = useCallback(
      async (dia: any) => {
        const response = await PedidosCuidadoAPI.getHorariosDisponiveis(
          Number(getValues('profissionalSaude')),
          Number(consultorios.ativo?.id),
          dayjs(new Date(dia)).format('YYYYMMDD'),
          {
            somenteHorariosLivres: true,
          },
        );

        if (response?.status === 200) {
          const horarioProgramadoFormatado =
            response?.data[0]?.atendimentos.map((item: any) => ({
              label: dayjs(item.dataAgendamento).format('HH:mm'),
              value: dayjs(item.dataAgendamento).toDate(),
            }));
          setHorarioProgramadoOptions(horarioProgramadoFormatado);
        } else {
          setHorarioProgramadoOptions([]);
        }
      },
      [consultorios.ativo?.id, getValues],
    );

    useEffect(() => {
      if (dataProgramada) {
        getHorarios(dataProgramada);
      }
    }, [dataProgramada, getHorarios, watchProfissionalSaude]);

    useEffect(() => {
      setValue('encaixe', agendamento?.encaixe);
    }, [agendamento?.encaixe, setValue]);

    const watchDia = watch(`agendamentos.${index}.dataProgramada`);
    const watchEncaixe = watch(`agendamentos.${index}.encaixe`);

    // useEffectSkipFirst(() => {
    //   if (!watchEncaixe) {
    //     setValue(`agendamentos.${index}.horarioProgramado`, null);
    //   }
    // }, [watchDia, watchEncaixe]);

    const isDisabled =
      isEditing && agendamento.status && agendamento.status !== 'AGENDADO';

    return (
      <>
        <div className="p-d-flex p-ai-center p-jc-between p-my-1">
          <div className="p-d-flex p-ai-center p-grid p-col-9">
            <CalendarInputControlled
              control={control}
              name={`agendamentos.${index}.dataProgramada`}
              label="Dia"
              showIcon
              className="p-col-6"
              disabled={isDisabled}
              errorMsg={errors?.agendamentos?.[index]?.dataProgramada?.message}
            />
            {watchEncaixe === false && (
              <DropdownControlled
                control={control}
                name={`agendamentos.${index}.horarioProgramado`}
                label="Hora"
                options={horarioProgramadoOptions}
                placeholder="Selecione um horário"
                className="p-col-6"
                disabled={isDisabled || watchDia ? false : true}
                errorMsg={
                  errors?.agendamentos?.[index]?.horarioProgramado?.message
                }
              />
            )}
            {watchEncaixe === true && (
              <CalendarInputControlled
                control={control}
                name={`agendamentos.${index}.horarioProgramado`}
                label="Hora"
                icon={'fas fa-clock'}
                showIcon
                timeOnly
                className="p-col-6"
                disabled={isDisabled || watchDia ? false : true}
                errorMsg={
                  errors?.agendamentos?.[index]?.horarioProgramado?.message
                }
              />
            )}
          </div>
          <div className="p-d-flex p-ai-end p-col-3 p-mt-4">
            <CheckboxControlled
              control={control}
              label="Encaixe"
              name={`agendamentos.${index}.encaixe`}
              className="p-as-end p-mb-2"
            />
            <Separator spaceOnly />
            <Button
              icon="fas fa-trash"
              btnType="gray"
              type="button"
              onClick={() => {
                onRemove(index);
              }}
            />
          </div>
        </div>
        {!horarioProgramadoOptions.length && (
          <div>
            <AlertBox
              visible={alertVisible}
              onHide={() => setAlertVisible(false)}
              text="Não tem horários disponíveis para esse dia! Você pode marcar como encaixe e informar um horário."
            />
          </div>
        )}
      </>
    );
  },
);

export default AgendamentoPedidoCuidado;
