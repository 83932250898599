import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import SalaAPI from 'src/APIs/AdminAPI/ConsultorioAPI/SalaAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { BtnTypes } from 'src/components/Basics/Button/Buttons';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import { Filter as SearchListFilter } from 'src/components/SearchList/SearchListRoot';

import './AdvancedSearch.scss';

const sexoOptions = [
  {
    label: 'Masculino',
    value: 3,
  },
  {
    label: 'Feminino',
    value: 1,
  },
];

const AdvancedSearch = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);
  const useFormMethods = useForm();
  const { getValues, reset, control } = useFormMethods;

  const [salasOptions, setSalasOptions] = useState<any[]>([]);
  const { isTablet } = useSize();

  useEffect(() => {
    async function fetchSalas() {
      if (!consultorios?.ativo?.id) return;

      reset({
        sala: undefined,
      });

      const response = await SalaAPI.loadSalasByConsultorioId(
        consultorios.ativo.id,
      );

      if (Array.isArray(response) && response.length) {
        setSalasOptions(
          response.map(sala => ({
            label: sala.nome,
            value: sala.id,
          })),
        );
      }
    }

    fetchSalas();
  }, [consultorios?.ativo?.id, reset]);

  const onFilter = () => {
    const values = getValues();
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(
        ([key, value]) => value !== null && value !== undefined && value !== '',
      ),
    );
    return filteredValues;
  };

  const onCancel = () => {
    reset({
      nome: '',
      cpf: '',
      nomeMae: '',
      idsSexo: '',
      idadeInicial: '',
      idadeFinal: '',
      sala: '',
    });
  };

  return (
    <SearchListFilter
      isSearch
      btnType={BtnTypes.TONAL}
      onFilter={onFilter}
      onClearFilters={onCancel}
      btnClassName={isTablet ? 'p-col-12 p-mb-3' : ''}
    >
      <FormProvider {...useFormMethods}>
        <form className="p-grid">
          <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
            Pesquise pacientes de forma detalhada, preenchendo um ou mais dos
            campos abaixo.
          </SimpleText>
          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="nome"
              placeholder="Nome"
            />
          </div>
          <div className="p-col-12">
            <TextInputControlled
              control={control}
              name="cpf"
              placeholder="CPF"
              permitirApenasNumeros
            />
          </div>
          <div className="p-col-12">
            <TextInputControlled
              name="nomeMae"
              control={control}
              placeholder="Nome da mãe"
            />
          </div>
          <div className="p-col-6">
            <DropdownControlled
              control={control}
              name="sala"
              label="Sala"
              placeholder="Selecione"
              options={salasOptions}
            />
          </div>
          <div className="p-col-6">
            <DropdownControlled
              control={control}
              name="idsSexo"
              label="Sexo"
              placeholder="Selecione"
              options={sexoOptions}
            />
          </div>
          <div className="p-col-6">
            <TextInputControlled
              control={control}
              name="idadeInicial"
              label="Idade inicial"
              placeholder="Idade inicial"
              permitirApenasNumeros
            />
          </div>
          <div className="p-col-6">
            <TextInputControlled
              control={control}
              name="idadeFinal"
              label="Idade final"
              placeholder="Idade final"
              permitirApenasNumeros
            />
          </div>
        </form>
      </FormProvider>
    </SearchListFilter>
  );
};

export default AdvancedSearch;
