import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

export const Header = () => {
  const { isTablet } = useSize();

  return (
    <div className="p-grid">
      <div className="p-col-4 p-md-3">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Nome/Id/Data de nasc.
        </SimpleText>
      </div>
      {!isTablet && (
        <div className="p-col-2">
          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            Nome da mãe
          </SimpleText>
        </div>
      )}
      <div className="p-col-3 p-md-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Cartão convênio
        </SimpleText>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CNS
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-ai-center ">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          CPF/Passaporte
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-ai-center p-jc-center" />
    </div>
  );
};
