import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/personalizar-prontuario';

export const loadCamposObrigatorios = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<CamposObrigatorios> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/camposObrigatorios`,
    hideToast: true,
    ...options,
  });
};

export const getPersonalizarProntuario = (
  params?: any,
  options?: any,
): Promise<Paginator<ModeloProntuario[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/pesquisa`,
    params,
    hideToast: true,
    ...options,
  });
};

export const getPersonalizarProntuarioById = (
  idModeloProntuario: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ModeloProntuario> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/${idModeloProntuario}`,
    hideToast: true,
    ...options,
  });
};

export const postPersonalizarProntuario = (
  payload: PostPersonalizarProntuario,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ModeloProntuario> => {
  return Http.post({
    service: 'pep',
    url: basePath,
    data: payload,
    return: true,
    ...options,
  });
};

export const putPersonalizarProntuario = (
  payload: PostPersonalizarProntuario,
  idModeloProntuario: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ModeloProntuario> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${idModeloProntuario}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const putAlterarStatus = (
  action: string,
  idModeloProntuario: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ModeloProntuario> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${idModeloProntuario}/${action}`,
    ...options,
  });
};

export const deletePersonalizarProntuario = (
  idModeloProntuario: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> => {
  return Http.delete({
    service: 'pep',
    url: `${basePath}/${idModeloProntuario}`,
    ...options,
  });
};

const PersonalizarProntuarioAPI = {
  loadCamposObrigatorios,
  getPersonalizarProntuario,
  getPersonalizarProntuarioById,
  postPersonalizarProntuario,
  putPersonalizarProntuario,
  putAlterarStatus,
  deletePersonalizarProntuario,
};

export default PersonalizarProntuarioAPI;
