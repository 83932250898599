import { formatarDataHora } from 'src/utils/date';

export function formatarPlanos(
  planos: any[],
  atividade: string,
  type?: 'POST' | 'PUT',
) {
  const planosFiltrados = planos?.filter(
    plano => plano.atividade === atividade,
  );

  if (!planosFiltrados?.length) return [];

  const mapper =
    atividade === 'CONSULTA'
      ? 'consultas'
      : atividade === 'EXAMES'
      ? 'exames'
      : 'pedidosCuidado';

  const planosFormatados = planosFiltrados.map(plano => ({
    ...plano,
    ...(mapper === 'pedidosCuidado' && {
      idProfissionalSaudeExecutante: plano.profissionalSaude?.id,
      idTipoCuidado: plano.tipo?.id || plano.idTipoCuidado,
      profissionalSaude: undefined,
      tipo: undefined,
    }),
    ...(mapper === 'consultas' && {
      idEspecialidade: plano.especialidade?.id || plano.idEspecialidade,
      especialidade: undefined,
    }),
    ...(mapper === 'exames' && {
      servicoTuss: undefined,
    }),
    id: type === 'PUT' ? plano?.id : undefined,
    [mapper]:
      plano[mapper]?.map((data: any) => ({
        id: data.id,
        dataPrevistaRealizacao: formatarDataHora(data.data, data.hora),
        ...(mapper === 'pedidosCuidado' && { encaixe: !!data.encaixe }),
      })) || [],
  }));

  return planosFormatados;
}
