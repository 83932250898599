import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = 'pre-natal-gestante';

export const getPreNatal = (
  idPaciente: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/${idPaciente}/gestacao`,
    hideToast: true,
    ...options,
  });

export const getValoresEnum = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/valores-enum`,
    hideToast: true,
    ...options,
  });

export const getFolhaRosto = (
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/gestacao/${idPreNatal}`,
    hideToast: true,
    ...options,
  });

export const createFolhaRosto = (
  payload: any,
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${baseUrl}/${idAtendimento}/gestacao`,
    data: payload,
    return: true,
    ...options,
  });

export const updateFolhaRosto = (
  payload: any,
  idAtendimento: number,
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idAtendimento}/gestacao/${idPreNatal}`,
    data: payload,
    return: true,
    ...options,
  });

export const listUltrassom = (
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/ultrassom`,
    hideToast: true,
    ...options,
  });

export const getUltrassom = (
  idUltrassom: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/ultrassom/${idUltrassom}`,
    hideToast: true,
    ...options,
  });

export const createUltrassom = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/ultrassom`,
    data: payload,
    ...options,
  });

export const updateUltrassom = (
  idUltrassom: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/ultrassom/${idUltrassom}`,
    data: payload,
    ...options,
  });

export const deleteUltrassom = (
  idUltrassom: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${baseUrl}/ultrassom/${idUltrassom}`,
    return: true,
    ...options,
  });

export const inativarUltrassom = (
  idUltrassom: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/ultrassom/${idUltrassom}/inativar`,
    data: payload,
    return: true,
    ...options,
  });

export const getVacinas = (
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/vacinas`,
    hideToast: true,
    ...options,
  });

export const updateVacinas = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/vacinas`,
    data: payload,
    ...options,
  });

export const getHistoricoExamesFisico = (
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/exames-fisicos`,
    hideToast: true,
    ...options,
  });

export const createExameFisico = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/exames-fisicos`,
    data: payload,
    return: true,
    ...options,
  });

export const updateExameFisico = (
  idExameFisico: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/exames-fisicos/${idExameFisico}`,
    data: payload,
    return: true,
    ...options,
  });

export const deleteExameFisico = (
  idExameFisico: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${baseUrl}/exames-fisicos/${idExameFisico}`,
    return: true,
    ...options,
  });

export const getHistoricoCoombs = (
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/coombs`,
    hideToast: true,
    ...options,
  });

export const createCoombs = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/coombs`,
    data: payload,
    return: true,
    ...options,
  });

export const deleteCoombs = (
  idCoomb: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${baseUrl}/coombs/${idCoomb}`,
    return: true,
    ...options,
  });

export const getReagentes = (
  idPreNatal: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/reagente`,
    hideToast: true,
    return: true,
    ...options,
  });

export const updateReagente = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/reagente`,
    data: payload,
    ...options,
  });

export const encerrarCiclo = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/encerrar-ciclo`,
    data: payload,
    return: true,
    ...options,
  });

export const updateSorologiaHistorico = (
  idPreNatal: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${baseUrl}/${idPreNatal}/sorologia`,
    data: payload,
    ...options,
  });

export const getPacientesPreNatal = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${baseUrl}/pacientes`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });

  export const getProfissionaisSaudePreNatal = (
    params?: any,
    options?: Omit<HttpParams, 'service' | 'url'>,
  ): Promise<any> =>
    Http.get({
      service: 'pep',
      url: `${baseUrl}/profissionais`,
      params,
      hideToast: true,
      throwError: true,
      ...options,
    });

const PreNatalAPI = {
  getPreNatal,
  getValoresEnum,
  getFolhaRosto,
  createFolhaRosto,
  updateFolhaRosto,
  listUltrassom,
  getUltrassom,
  createUltrassom,
  updateUltrassom,
  deleteUltrassom,
  inativarUltrassom,
  getVacinas,
  updateVacinas,
  getHistoricoExamesFisico,
  createExameFisico,
  updateExameFisico,
  deleteExameFisico,
  getHistoricoCoombs,
  createCoombs,
  deleteCoombs,
  getReagentes,
  updateReagente,
  encerrarCiclo,
  updateSorologiaHistorico,
  getPacientesPreNatal,
  getProfissionaisSaudePreNatal,
};

export default PreNatalAPI;
