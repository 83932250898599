import dayjs from 'dayjs';

import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const MeusAgendamentosHistoricoRow = ({
  item,
}: any): React.ReactElement => {
  const endereco = item.consultorio?.endereco;
  const contato = item.consultorio?.contatoDto?.[0]?.numero || '-';
  const enderecoFormatado = !!endereco
    ? `${endereco?.logradouro} - ${endereco?.bairro}, ${endereco?.nomeCidade} - ${endereco?.siglaEstado}, ${endereco?.cep}`
    : '';

  return (
    <div className="historico-row p-col-12 p-mb-1">
      <div className="p-d-flex p-col-12 p-p-0">
        <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Data e hora
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {dayjs(item.dataAgendamento).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Profissional
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {item.medico?.nome}
          </SimpleText>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Contato
          </SimpleText>
          <SimpleText className="p-text-truncate">{contato}</SimpleText>
        </div>
      </div>

      <Divider layout="horizontal" className="p-col-12 p-p-0 p-m-0" />

      <div className="p-col-12 p-d-flex p-gap-1 p-ai-center">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Endereço:
        </SimpleText>
        <SimpleText>{enderecoFormatado}</SimpleText>
      </div>
    </div>
  );
};

export const MeusAgendamentosHistoricoRowMobile = ({ item }: any) => {
  const endereco = item.consultorio?.endereco;
  const contato = item.consultorio?.contato?.numero || '-';
  const enderecoFormatado = `${endereco.logradouro} - ${endereco.bairro}, ${endereco.nomeCidade} - ${endereco.siglaEstado}, ${endereco.cep}`;

  return (
    <div className="historico-row p-grid p-col-12 p-mb-1">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data e hora
        </SimpleText>
        <SimpleText>
          {dayjs(item.dataAgendamento).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Profissional
        </SimpleText>

        <SimpleText>{item.medico?.nome}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Contato
        </SimpleText>
        <SimpleText>{contato}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-ai-center p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Endereço
        </SimpleText>
        <SimpleText className="p-line-height-2">{enderecoFormatado}</SimpleText>
      </div>
    </div>
  );
};
