import { lazy, Suspense } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Dialog from 'src/components/Dialog/Dialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './ModalPreAtendimento.scss';

interface ModalPreAtendimentoProps extends DisclosureType {
  editPreAtendimento: PreAtendimento | null;
  selectedTipo: string;
  onSuccess: () => void;
  paciente: any;
}

function ModalPreAtendimento({
  isOpen,
  close,
  editPreAtendimento,
  selectedTipo,
  onSuccess,
  paciente,
}: ModalPreAtendimentoProps) {
  const commonProps = {
    editPreAtendimento: editPreAtendimento,
    onHide: () => close(),
    onSuccess: onSuccess,
    paciente: paciente,
  };

  const formComponent: { [key: string]: any } = {
    PRESSAO_ARTERIAL: lazy(() => import('./ModalNovaPressao')),
    SATURACAO: lazy(() => import('./ModalNovaSaturacao')),
    CIRCUNFERENCIA_ABDOMINAL: lazy(
      () => import('./ModalNovaCircunferenciaAbdominal'),
    ),
    CIRCUNFERENCIA_QUADRIL: lazy(
      () => import('./ModalNovaCircunferenciaQuadril'),
    ),
    CIRCUNFERENCIA_PANTURRILHA: lazy(
      () => import('./ModalNovaCircunferenciaPanturrilha'),
    ),
    CIRCUNFERENCIA_CINTURA: lazy(
      () => import('./ModalNovaCircunferenciaCintura'),
    ),
    CIRCUNFERENCIA_BRACO: lazy(() => import('./ModalNovaCircunferenciaBraco')),
    DOR_PACIENTE: lazy(() => import('./ModalNovaDorPaciente')),
    GLICEMIA_CAPILAR: lazy(() => import('./ModalNovaGlicemiaCapilar')),
    TEMPERATURA: lazy(() => import('./ModalNovaTemperatura')),
    APARENCIA: lazy(() => import('./ModalNovaAparencia')),
    PULSO: lazy(() => import('./ModalNovaPulso')),
    RESPIRACAO: lazy(() => import('./ModalNovaRespiracao')),
    OBSERVACAO_GERAL: lazy(() => import('./ModalObservacaoGeral')),
    PERIMETRO_CEFALICO: lazy(() => import('./ModalNovaPerimetroCefalico')),
    PESO_ALTURA: lazy(() => import('./ModalPesoAltura')),
    // CID: lazy(() => import('./ModalNovaCID')),
  };

  const header: { [key: string]: string } = {
    PRESSAO_ARTERIAL: 'Pressão Arterial',
    SATURACAO: 'Saturação',
    CIRCUNFERENCIA_ABDOMINAL: 'Circunferência Abdominal',
    CIRCUNFERENCIA_QUADRIL: 'Circunferência do quadril',
    CIRCUNFERENCIA_PANTURRILHA: 'Circunferência da panturrilha',
    CIRCUNFERENCIA_CINTURA: 'Circunferência da cintura',
    CIRCUNFERENCIA_BRACO: 'Circunferência do braço',
    DOR_PACIENTE: 'Escala de dor',
    GLICEMIA_CAPILAR: 'Glicemia Capilar',
    TEMPERATURA: 'Temperatura',
    APARENCIA: 'Aparência',
    PULSO: 'Pulso',
    RESPIRACAO: 'Respiração',
    OBSERVACAO_GERAL: 'Observação',
    PERIMETRO_CEFALICO: 'Perimetro cefálico',
    PESO_ALTURA: 'Peso e Altura',
  };

  const FormContent = formComponent[selectedTipo];

  return (
    <Dialog
      id="modal-nova-preAtendimento"
      type="modal"
      header={header[selectedTipo]}
      visible={isOpen}
      onHide={() => close()}
      maximizedTabletSize
    >
      <Suspense fallback={<SpinnerLoading full />}>
        <FormContent {...commonProps} />
      </Suspense>
    </Dialog>
  );
}

export default ModalPreAtendimento;
