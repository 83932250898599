import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type IContextProps = {
  filters: null | {
    nome?: string;
    ativo?: boolean;
    emAtraso?: boolean;
    concluido?: boolean;
    idProfissionalResponsavel?: number[];
  };
  setFilters: (v: any) => void;
  selectedPlano: number | null;
  selectedPaciente: number | null;
  setSelectedPlano: (v: number | null) => void;
  setSelectedPaciente: (v: number | null) => void;
  reloadList: () => void;
  reload: number;
  quantidadeFiltros: number;
  limparFiltros: () => void;
};

const PlanosPacientesContext = createContext({} as IContextProps);

export const PlanosPacientesProvider = (props: {
  children: React.ReactChild;
}) => {
  const [filters, setFilters] = useState({});
  const [reload, setReload] = useState(0);
  const [selectedPlano, setSelectedPlano] = useState<number | null>(null);
  const [selectedPaciente, setSelectedPaciente] = useState<number | null>(null);
  const [quantidadeFiltros, setQuantidadeFiltros] = useState(0);

  const reloadList = () => {
    setReload(prev => prev + 1);
    const quantidadeFiltros =
      (selectedPlano ? 1 : 0) + (selectedPaciente ? 1 : 0);
    setQuantidadeFiltros(quantidadeFiltros);
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      reloadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const limparFiltros = () => {
    setSelectedPlano(null);
    setSelectedPaciente(null);
    setQuantidadeFiltros(0);
    setReload(prev => prev + 1);
  };

  return (
    <PlanosPacientesContext.Provider
      value={{
        filters,
        setFilters,
        selectedPlano,
        selectedPaciente,
        setSelectedPlano,
        setSelectedPaciente,
        reloadList,
        reload,
        quantidadeFiltros,
        limparFiltros,
      }}
    >
      {props.children}
    </PlanosPacientesContext.Provider>
  );
};

export const usePlanosPacientes = () => useContext(PlanosPacientesContext);
