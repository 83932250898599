import { useLocation } from 'react-router';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import Funcionalidades from 'src/pages/Emed/Config/Cardapio/Funcionalidades/Funcionalidades';

import Clientes from './Clientes/Clientes';
import Perfis from './Perfis/Perfis';

import './Cardapio.scss';

const Cardapio = () => {
  const tab = useLocation().state;
  console.log('🚀 ~ Cardapio ~ tab:', tab);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_cardapio_cadastrar',
      'adm_cardapio_alterar',
      'adm_cardapio_consultar',
      'adm_cardapio_excluir',
    ],
  });

  return (
    <Page className={'Cardapio'}>
      <>
        <PageHeader
          title={'Cardápio'}
          subtitle={'Gerencie as funcionalidades do sistema eMed'}
          clean
        />
        <Tab
          initialTabIndex={tab || ''}
          clean
          underHeader
          values={[
            {
              label: 'Lista de Funcionalidades',
              content: <Funcionalidades />,
              checkPermission: 'ADM_FUNCIONALIDADE_CONSULTAR',
            },
            {
              label: 'Configurações de cliente',
              content: <Clientes />,
              checkPermission: 'ADM_CARDAPIO_CONSULTAR',
            },
            {
              label: 'Perfís de usuário',
              content: tabIndex => <Perfis tabIndex={tabIndex} />,
              checkPermission: 'ADM_PERFIL_CONSULTAR',
            },
          ]}
        />

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </>
    </Page>
  );
};

export default Cardapio;
