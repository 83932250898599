import { memo, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalSairSemSalvarGrupoAtendimento.scss';

interface ModalSairSemSalvarGrupoAtendimentoProps {
  visible: boolean;
  onHide(): void;
}

interface FooterProps {
  onCancel(): void;
  onAdd(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onAdd } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Não, cancelar"
        onClick={onCancel}
        stretch
      />
      <Button btnType="danger" label="Sim, sair" onClick={onAdd} stretch />
    </div>
  );
});

const ModalSairSemSalvarGrupoAtendimento = ({
  visible,
  onHide,
}: ModalSairSemSalvarGrupoAtendimentoProps) => {
  const navigate = useNavigate();

  const handleSairSemSalvarGrupoAtendimento = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleCancelSairSemSalvarGrupoAtendimento = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <Dialog
      className="footer-on modal-body-excluir-grupo-sair"
      type="modal"
      header="Sair sem salvar"
      visible={visible}
      onHide={handleCancelSairSemSalvarGrupoAtendimento}
      footer={() => (
        <Footer
          onAdd={handleSairSemSalvarGrupoAtendimento}
          onCancel={handleCancelSairSemSalvarGrupoAtendimento}
        />
      )}
    >
      <SimpleText fontColor={FONT_COLOR.COLOR_40}>
        Você tem certeza que deseja sair e descartar todas as edições feitas no
        cadastro?
      </SimpleText>
    </Dialog>
  );
};

export default ModalSairSemSalvarGrupoAtendimento;
