import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { FieldTelaApresentacao } from './components/FieldTelaApresentacao';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

export const AssistenteVirutalFilter = () => {
  const ref = useRef<OverlayPanel>(null);
  const { setFilter } = useSearchList();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        dataInicio: Yup.date().nullable(),
        dataFim: Yup.date().when('dataInicio', {
          is: (dataInicio: Date) => !!dataInicio,
          then: Yup.date()
            .min(
              Yup.ref('dataInicio'),
              'A data final deve ser maior que a data inicial',
            )
            .required('Informe a data final')
            .nullable(),
          otherwise: Yup.date().nullable(),
        }),
      }),
    ),
  });

  const onSubmit = (v: any) => {
    setFilter({
      ...v,
      mostrarNovidades: v.mostrarNovidades || undefined,
      mostrarAjudaTela: v.mostrarAjudaTela || undefined,
      mostrarPrimeiroAcesso: v.mostrarPrimeiroAcesso || undefined,
    });
    ref.current?.hide();
  };

  return (
    <>
      <Button
        label="Filtros avançados"
        icon="fas fa-chevron-down"
        iconPos="right"
        btnType="tonal"
        onClick={e => ref.current?.toggle(e)}
        style={{
          maxHeight: '35px',
        }}
      />

      <OverlayPanel
        ref={ref}
        style={{
          width: '390px',
        }}
      >
        <FormProvider {...form}>
          <SimpleText fontColor="color_60">
            Pesquise os protocolos de atendimento de forma detalhada,
            preenchendo um ou mais dos campos abaixo.
          </SimpleText>

          <form onSubmit={form.handleSubmit(onSubmit)} className="p-grid">
            <FieldTelaApresentacao
              name="funcionalidade"
              placeholder="Selecione"
              label="Tela de apresentação"
              className="p-col-12 p-px-0"
            />

            <SimpleText fontColor="color_60" className="p-col-12 p-p-0" medium>
              Período de criação
            </SimpleText>

            <CalendarInputControlled
              control={form.control}
              name="dataInicio"
              label="Data de ínicio"
              className="p-col-6 p-pl-0"
              placeholder="dd/mm/aaaa"
              showIcon
            />
            <CalendarInputControlled
              control={form.control}
              name="dataFim"
              label="Data final"
              className="p-col-6 p-pr-0"
              placeholder="dd/mm/aaaa"
              showIcon
            />

            <CheckboxControlled
              control={form.control}
              name="mostrarNovidades"
              label="Mostrar novidades"
              className="p-col-4 p-pl-0"
            />
            <CheckboxControlled
              control={form.control}
              name="mostrarAjudaTela"
              label="Mostrar ajuda de tela"
              className="p-col-4 p-px-0"
            />
            <CheckboxControlled
              control={form.control}
              name="mostrarPrimeiroAcesso"
              label="Mostrar primeiro acesso"
              className="p-col-4 p-px-0"
            />

            <div className="p-col-12 p-px-0 p-gap-2 p-d-flex">
              <Button
                label="Cancelar"
                btnType="outline"
                className="p-col-4"
                onClick={() => ref.current?.hide()}
              />
              <Button label="Pesquisar" className="p-col " type="submit" />
            </div>
          </form>
        </FormProvider>
      </OverlayPanel>
    </>
  );
};
