import Http, { HttpParams } from 'src/core/http/Http';

export const loadServicoTussValorById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetServicoTussValorDTO> => {
  return Http.get({
    service: 'adm',
    url: `servico-tuss-valor/${id}`,
    hideToast: true,
    ...options,
  });
};

export const sendServicoTussValor = (
  payload: PostServicoTussValorDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: '/servico-tuss-valor',
    data: payload,
    return: true,
    ...options,
  });
};

export const updateServicoTussValor = (
  id: number,
  idProfissional: number,
  payload: PutServicoTussValorDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/servico-tuss-valor/${id}/profissionalSaude/${idProfissional}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateStatusServicoTussValor = (
  id: number,
  payload: PostServicoTussValorDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostServicoTussValorDTO> => {
  return Http.put({
    service: 'adm',
    url: `/servico-tuss-valor/status/${id}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const removeServicoTussValor = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `/servico-tuss-valor/${id}`,
    return: true,
    ...options,
  });
};

export const loadServicoTussValor = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetServicoTussValorDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: 'servico-tuss-valor/pesquisa',
    params: params,
    hideToast: true,
    ...options,
  });
};

const ServicoTussValorAPI = {
  loadServicoTussValor,
  loadServicoTussValorById,
  sendServicoTussValor,
  updateServicoTussValor,
  removeServicoTussValor,
  updateStatusServicoTussValor,
};

export default ServicoTussValorAPI;
