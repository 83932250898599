import DadosPaciente from './DadosPaciente/DadosPaciente';
import HistoricoEdicoes from './HistoricoEdicoes/HistoricoEdicoes';
import ListaCartoes from './ListaCartoes/ListaCartoes';

import './Visualizacao.scss';

interface VisualizacaoProps {
  paciente: GetPacienteDTO | undefined;
  loadingData: boolean;
}


const Visualizacao = ({ paciente, loadingData }: VisualizacaoProps) => {
  return (
    <div id="visualizacao-paciente-container" className="p-grid">
      <DadosPaciente paciente={paciente} />
      <HistoricoEdicoes />
      <ListaCartoes />
    </div>
  );
}

export default Visualizacao;