import { memo, useState } from 'react';

import { useLocation, useParams } from 'react-router';
import { useSubscription } from 'react-stomp-hooks';
import { toast } from 'react-toastify';

import { SolicitacoesProcedimentosAPI } from 'src/APIs/ProntuarioAPI/SolicitacoesProcedimentosAPI/SolicitacoesProcedimentosAPI';
import { TissAPI } from 'src/APIs/ProntuarioAPI/TissAPI';
import { GuiaResumidaAPI } from 'src/APIs/ReportAPI/GuiaResumidaAPI';
import { GuiaTissAPI } from 'src/APIs/ReportAPI/GuiaTissAPI';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useSolicitacaoExameCirurgia } from 'src/core/hooks/Atendimento/useSolicitacaoExameCirurgia';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { handleDownloadPDF } from 'src/utils/files';

import { ButtonEnviarEmail } from '../../components/ButtonEnviarEmail/ButtonEnviarEmail';
import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import { DialogLoadingTiss } from 'src/components/DialogLoadingTiss/DialogLoadingTiss';
import { ModalRetornoOperadora } from 'src/components/ModalRetornoOperadora/ModalRetornoOperadora';
import Skeleton from 'src/components/Skeleton/Skeleton';
import { DialogAssinarDigitalmente } from 'src/pages/Emed/Admin/AssinaturasProntuario/components/DialogAssinarDigitalmente';

import { useConvenioConfigTiss } from '../hooks/useConvenioConfigTiss';
import { useSelectedProcedimentosSolicitados } from '../hooks/useSelectedProcedimentosSolicitados';

import { DialogEnviarOperadoraOcorrencia } from './DialogEnviarOperadoraOcorrencia/DialogEnviarOperadoraOcorrencia';
import { DialogErrorTiss } from './DialogErrorTiss/DialogErrorTiss';
import { ProcedimentosSolicitadosGuiasList } from './ProcedimentosSolicitadosGuiasList';

import './ProcedimentosSolicitadosList.scss';

export const ProcedimentosSolicitadosList = () => {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { atendimentoStatus } = useAtendimento();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const {
    idConvenio,
    guiasProcedimentosSolicitados,
    fetchProcedimentosSolicitados,
  } = useSolicitacaoExameCirurgia();

  const [isSubmittingOperadora, setIsSubmittingOperadora] = useState(false);
  const [cancelandoGuias, setCancelandoGuias] = useState(false);
  const [guiasRetornoOperadora, setGuiasRetornoOperadora] = useState<any[]>([]);
  const [isTimerRunning, setIsTimeRunning] = useState(false);

  const modalEmailDisclosure = useDisclosure({ opened: false });
  const dialogErrorTiss = useDisclosure({ opened: false });
  const dialogErrorCancelarTiss = useDisclosure({ opened: false });
  const dialogLoadingTiss = useDisclosure({ opened: false });
  const dialogLoadingCancelarTiss = useDisclosure({ opened: false });
  const dialogEnviarOperadoraOcorrencia = useDisclosure({ opened: false });
  const dialogRetornoOperadora = useDisclosure({
    opened: false,
    onClose: () => [setIsTimeRunning(false), setGuiasRetornoOperadora([])],
  });
  const [solicitacaoTissAtual, setSolicitacaoTissAtual] = useState('');

  const disabledWhenAtendimentoStatusIsNotAtendendo =
    atendimentoStatus !== 'ATENDENDO';

  const {
    allGuiaSelected,
    guiasSelected,
    handleSelectAllGuias,
    handleSelectGuia,
    checkGuiaIsSelected,
    getGuiasSelectedLabel,
  } = useSelectedProcedimentosSolicitados({
    guiasProcedimentosSolicitados,
  });

  const { convenioConfigTiss, loadingConvenioConfigTiss } =
    useConvenioConfigTiss();

  const handleCancelarGuias = async () => {
    try {
      setSolicitacaoTissAtual('CANCELAMENTO_GUIA');
      setCancelandoGuias(true);

      await TissAPI.cancelarGuiasSadt(
        Number(idAtendimento),
        Number(idConvenio),
        guiasSelected.join(','),
      );

      fetchProcedimentosSolicitados();

      //dialogLoadingCancelarTiss.open({ state: { type: 'cancelar' } });
      handleSelectAllGuias(false);
    } catch (error) {
      //dialogErrorCancelarTiss.open({ state: { tryAgainBtnType: 'danger' } });
    } finally {
      setCancelandoGuias(false);
    }
  };

  const handleSubmitOperadora = async (checkPendencia = true) => {
    try {
      setSolicitacaoTissAtual('SADT');
      setIsSubmittingOperadora(true);

      if (checkPendencia) {
        const procedimentosComPendencia = guiasProcedimentosSolicitados
          .flatMap(([_, procedimentos]) => procedimentos)
          .flatMap(procedimento =>
            (procedimento.pendencia?.necessitaIndicacaoClinica &&
              !procedimento.indicacaoClinica) ||
            (procedimento.pendencia?.necessitaJustificativa &&
              !procedimento.justificativa)
              ? procedimento
              : [],
          );

        if (procedimentosComPendencia.length)
          return dialogEnviarOperadoraOcorrencia.open({
            state: procedimentosComPendencia,
          });
      }

      await SolicitacoesProcedimentosAPI.enviarOperadora(
        Number(idAtendimento),
        Number(idConvenio),
        { throwError: true },
      );

      dialogEnviarOperadoraOcorrencia.close();
      dialogLoadingTiss.open();
    } catch (error) {
      dialogEnviarOperadoraOcorrencia.close();
    } finally {
      setIsSubmittingOperadora(false);
    }
  };

  useSubscription('/user/topic/notificacao', (message: any) => {
    const { dadosComplementares, ...data } = JSON.parse(message.body);

    const isTipoTransacaoAceita =
      dadosComplementares?.transacao === 'SADT' ||
      dadosComplementares?.transacao === 'INTERNACAO';

    if (
      data.tipoNotificacao === 'TISS' &&
      isTipoTransacaoAceita &&
      isTimerRunning
    ) {
      if (dadosComplementares.sucesso === 'false') {
        dialogLoadingTiss.close();
        dialogErrorTiss.open();

        return toast(<Toast />, {
          data: {
            title: 'Erro',
            message: 'Erro de comunicação com o TISS',
            type: 'error',
          },
        });
      }

      dadosComplementares.procedimentos = JSON.parse(
        dadosComplementares.procedimentos,
      );
      setGuiasRetornoOperadora(prev => [...prev, dadosComplementares]);

      dialogLoadingTiss.close();
      dialogErrorTiss.close();

      dialogRetornoOperadora.open();
    }

    if (
      data.tipoNotificacao === 'TISS' &&
      data?.dadosComplementares?.transacao === 'CANCELAMENTO_GUIA' &&
      solicitacaoTissAtual === 'CANCELAMENTO_GUIA' &&
      !dialogRetornoOperadora.isOpen
    ) {
      const guiaPrestador = JSON.parse(data.dadosComplementares.guiasPrestador);
      const isSuccess = data.dadosComplementares.sucesso === 'true';
      const message = isSuccess
        ? 'foi cancelada com sucesso'
        : 'não pode ser cancelada';
      const title = isSuccess ? 'Sucesso' : 'Erro';
      const type = isSuccess ? 'success' : 'error';

      const toastData = {
        title,
        message: `A guia ${guiaPrestador[0]} ${message}`,
        type,
      };

      toast(<Toast />, { data: toastData });
      dialogLoadingCancelarTiss.close();
      setIsTimeRunning(false);
    }

    return;
  });

  const renderTissBtns = () => {
    if (loadingConvenioConfigTiss) {
      return (
        <>
          <div>
            <Skeleton
              loading={true}
              width="112px"
              height="32px"
              borderRadius="8px"
            />
          </div>
          <div>
            <Skeleton
              loading={true}
              width="124px"
              height="32px"
              borderRadius="8px"
            />
          </div>
        </>
      );
    }

    if (!convenioConfigTiss?.utilizaTiss) return <></>;

    return (
      <>
        <Button
          label="Cancelar guias"
          type="button"
          btnType="danger"
          onClick={handleCancelarGuias}
          disabled={
            disabledWhenAtendimentoStatusIsNotAtendendo ||
            !guiasSelected.length ||
            isSubmittingOperadora
          }
          loading={cancelandoGuias}
        />

        <Button
          label="Enviar operadora"
          type="button"
          onClick={() => handleSubmitOperadora()}
          loading={isSubmittingOperadora}
          disabled={
            disabledWhenAtendimentoStatusIsNotAtendendo ||
            !guiasProcedimentosSolicitados.length ||
            cancelandoGuias ||
            agenda?.profissionalAtivo?.profissionalSaude
              ?.naoRealizaTransacaoTiss
          }
        />
      </>
    );
  };

  return (
    <div id="procedimentos-selecionados-container">
      {dialogLoadingTiss.isOpen && (
        <DialogLoadingTiss
          customMensage={convenioConfigTiss?.mensagemConexaoOperadora}
          onTimeout={() => dialogErrorTiss.open()}
          timeout={convenioConfigTiss?.timeoutSolicitacaoProcedimento}
          setIsTimerRunning={setIsTimeRunning}
          {...dialogLoadingTiss}
        />
      )}

      {dialogLoadingCancelarTiss.isOpen && (
        <DialogLoadingTiss
          customMensage={convenioConfigTiss?.mensagemConexaoOperadora}
          onTimeout={() =>
            dialogErrorTiss.open({
              state: { tryAgainBtnType: 'danger' },
            })
          }
          timeout={convenioConfigTiss?.timeoutCancelaGuia}
          setIsTimerRunning={setIsTimeRunning}
          {...dialogLoadingCancelarTiss}
        />
      )}

      {dialogErrorTiss.isOpen && (
        <DialogErrorTiss
          customMensage={convenioConfigTiss?.mensagemFalhaComunicacao}
          onRetry={() => [dialogLoadingTiss.open(), handleSubmitOperadora()]}
          onFinish={() => setIsTimeRunning(false)}
          {...dialogErrorTiss}
        />
      )}

      {dialogErrorCancelarTiss.isOpen && (
        <DialogErrorTiss
          customMensage={convenioConfigTiss?.mensagemFalhaComunicacao}
          onRetry={() =>
            dialogLoadingTiss.open({
              state: { type: 'cancelar' },
            })
          }
          onFinish={() => setIsTimeRunning(false)}
          {...dialogErrorCancelarTiss}
        />
      )}

      {dialogRetornoOperadora.isOpen && (
        <ModalRetornoOperadora
          guiasRetornoOperadora={guiasRetornoOperadora}
          {...dialogRetornoOperadora}
        />
      )}

      {dialogEnviarOperadoraOcorrencia.isOpen && (
        <DialogEnviarOperadoraOcorrencia
          onSuccess={() => [
            handleSubmitOperadora(false),
            fetchProcedimentosSolicitados(),
          ]}
          {...dialogEnviarOperadoraOcorrencia}
        />
      )}

      <div className="p-mb-3">
        <SimpleText
          fontSize={FONT_SIZE.MD}
          fontColor={FONT_COLOR.COLOR_16}
          medium
        >
          Lista de procedimentos selecionados
        </SimpleText>
      </div>
      <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
        <Checkbox
          checked={allGuiaSelected}
          label={getGuiasSelectedLabel()}
          onChange={e => handleSelectAllGuias(e.target.checked)}
        />

        <div className="p-d-flex p-jc-end p-gap-2 ">
          {renderTissBtns()}

          <ActionsBtnsAssinatura
            disabledWhenAtendimentoStatusIsNotAtendendo={
              disabledWhenAtendimentoStatusIsNotAtendendo
            }
            modalEmailDisclosure={modalEmailDisclosure}
          />
        </div>
      </div>

      <ProcedimentosSolicitadosGuiasList
        handleSelectGuia={handleSelectGuia}
        checkGuiaIsSelected={checkGuiaIsSelected}
      />
    </div>
  );
};

const ActionsBtnsAssinatura = memo(
  ({
    disabledWhenAtendimentoStatusIsNotAtendendo,
    modalEmailDisclosure,
  }: any) => {
    const { guiasProcedimentosSolicitados } = useSolicitacaoExameCirurgia();

    const [loading, setLoading] = useState(false);

    const { state } = useLocation();

    const idAtendimento = state?.idAtendimento;

    const {
      webSigner: { handleSign, isSuccess, log },
      isHsm,
      handleSignHSM,
      dialogAssinarDigitalmente,
    } = useAssinatura();

    const handleEnviarGuiasPorEmail = (email: string) => {
      handleAssinaturaGuiaTiss('email', email);
    };

    const handleAssinaturaGuiaTiss = async (funcao: string, email?: string) => {
      if (!isSuccess && !isHsm) {
        const data = {
          title: log ? 'Verifique sua assinatura digital' : 'Erro',
          message:
            log ||
            'Falha ao baixar as guias, verifique sua assinatura digital e tente novamente.',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      try {
        setLoading(true);

        const response = await SolicitacoesProcedimentosAPI.getBase64GuiaTiss(
          Number(idAtendimento),
        );

        const documents = {
          documents: [
            {
              content: response.base64,
              contentType: 'BASE64',
            },
          ],
        };

        const assinatura: any = isHsm
          ? await handleSignHSM(response.base64, {
              skipPIN: funcao === 'email',
            })
          : await handleSign(documents, {
              sequenciaAssinatura: response.sequenciaAssinatura,
              tipoDocumento: 'EXAMES_CIRURGIAS_GUIA',
            });

        if (funcao === 'PDF') {
          const pdfGuiaTiss: any =
            await SolicitacoesProcedimentosAPI.postAssinaturaGuiaTiss(
              Number(idAtendimento),
              {
                base64: response.base64,
                base64Assinado: !isHsm
                  ? assinatura[0].data[0].signature
                  : assinatura.data.documents[0].signatures[0].value,
              },
            );

          handleDownloadPDF(pdfGuiaTiss, 'Guia TISS.pdf');
        }

        if (funcao === 'email') {
          await SolicitacoesProcedimentosAPI.postAssinaturaGuiaTiss(
            Number(idAtendimento),
            {
              base64: response.base64,
              base64Assinado: !isHsm
                ? assinatura[0].data[0].signature
                : assinatura.data.documents[0].signatures[0].value,
              email,
            },
            'email',
          );
          modalEmailDisclosure.close();
          return setLoading(false);
        }

        return setLoading(false);
      } catch {
        return setLoading(false);
      }
    };

    const handleAssinaturaGuiaResumida = async () => {
      if (!isSuccess && !isHsm) {
        const data = {
          title: log ? 'Verifique sua assinatura digital' : 'Erro',
          message:
            log ||
            'Falha ao baixar as guias, verifique sua assinatura digital e tente novamente.',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }

      try {
        setLoading(true);

        const response = await GuiaResumidaAPI.getBase64GuiaResumida(
          Number(idAtendimento),
        );

        const documents = {
          documents: [
            {
              content: response.base64,
              contentType: 'BASE64',
            },
          ],
        };

        const assinatura: any = isHsm
          ? await handleSignHSM(response.base64)
          : await handleSign(documents, {
              sequenciaAssinatura: response.sequenciaAssinatura,
              tipoDocumento: 'EXAMES_CIRURGIAS_GUIA_RESUMIDA',
            });

        const pdfGuiaResumida: any =
          await GuiaResumidaAPI.pdfAssinaturaGuiaResumida(
            Number(idAtendimento),
            {
              base64: response.base64,
              base64Assinado: !isHsm
                ? assinatura[0].data[0].signature
                : assinatura.data.documents[0].signatures[0].value,
            },
          );

        handleDownloadPDF(pdfGuiaResumida, 'Guia Resumida.pdf');
      } catch {
        // error
      } finally {
        return setLoading(false);
      }
    };

    const handlePrintGuias = async () => {
      try {
        const response = await GuiaTissAPI.getGuiaTissPdf(
          Number(idAtendimento),
        );

        if (typeof response !== 'object') {
          const data = {
            title: 'Erro',
            message: 'Falha ao baixar as guias.',
            type: 'error',
          };
          return toast(<Toast />, { data });
        }

        const fileURL = URL.createObjectURL(
          new Blob([response], {
            type: 'application/pdf',
          }),
        );

        window.open(fileURL);
      } catch (error) {}

      return;
    };

    const handlePrintGuiasResumida = async () => {
      try {
        const response = await GuiaResumidaAPI.printGuiaResumidaPdf(
          Number(idAtendimento),
        );

        if (typeof response !== 'object') {
          const data = {
            title: 'Erro',
            message: 'Falha ao baixar as guias.',
            type: 'error',
          };
          return toast(<Toast />, { data });
        }

        const fileURL = URL.createObjectURL(
          new Blob([response], {
            type: 'application/pdf',
          }),
        );

        window.open(fileURL);
      } catch (error) {}

      return;
    };

    return (
      <>
        <ButtonEnviarEmail
          handleEnviarGuiasPorEmail={handleEnviarGuiasPorEmail}
          modalEmailDisclosure={modalEmailDisclosure}
          loadingAssinatura={loading}
          disabled={
            disabledWhenAtendimentoStatusIsNotAtendendo ||
            !guiasProcedimentosSolicitados.length
          }
          loading={loading}
        />

        <ButtonMenu
          icon="fas fa-file-download"
          label=".PDF"
          btnType="tonal"
          model={[
            {
              label: 'Baixar (.PDF)',
              disabled: true,
              className: 'model-label',
            },
            {
              label: 'Guia TISS',
              command: () => {
                handleAssinaturaGuiaTiss('PDF');
              },
            },
            {
              label: 'Guia Resumida',
              command: handleAssinaturaGuiaResumida,
            },
          ]}
          disabled={
            disabledWhenAtendimentoStatusIsNotAtendendo ||
            !guiasProcedimentosSolicitados.length
          }
          hideLabelWhenIsTablet
          loading={loading}
        />

        <ButtonMenu
          icon="fas fa-print"
          btnType="tonal"
          model={[
            {
              label: 'Imprimir',
              disabled: true,
              className: 'model-label',
            },
            {
              label: 'Guia TISS',
              command: handlePrintGuias,
            },
            {
              label: 'Guia Resumida',
              command: handlePrintGuiasResumida,
            },
          ]}
          disabled={
            disabledWhenAtendimentoStatusIsNotAtendendo ||
            !guiasProcedimentosSolicitados.length
          }
        />
        {dialogAssinarDigitalmente.isOpen && (
          <DialogAssinarDigitalmente {...dialogAssinarDigitalmente} />
        )}
      </>
    );
  },
);
