import { useState } from 'react';

import { useNavigate } from 'react-router';

import { PacoteAPI } from 'src/APIs/AdminAPI/PacoteAPI/PacoteAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { ConvenioFilter } from './ConvenioFilter';
import { Header } from './Header';
import { Row } from './Row';

export const List = () => {
  const navigate = useNavigate();

  const confirmDelete = useDisclosure({ opened: false });

  const [reload, setReload] = useState(0);

  const fetchPacotes = ({ query, ...params }: any) =>
    PacoteAPI.getPacotes({
      ...params,
      codigoNomePacote: query,
    });

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleDelete = (idPacote: number) => {
    confirmDelete.open({ state: idPacote });
  };

  const handleConfirmDelete = async () => {
    try {
      await PacoteAPI.deletePacote(confirmDelete.state);

      reloadList();
      confirmDelete.close();
    } catch (error) {}
  };

  const handleSwitchStatus = async (idPacote: number) => {
    try {
      await PacoteAPI.switchStatusPacote(idPacote);

      reloadList();
    } catch (error) {}
  };

  const renderRow = (item: any) => {
    return (
      <Row
        pacote={item}
        onSwitchStatus={handleSwitchStatus}
        onDelete={handleDelete}
      />
    );
  };

  const handleAddNewPacote = () => {
    navigate('/config/config-tuss/pacote/novo');
  };

  return (
    <>
      <SearchList.Root fetchApi={fetchPacotes} empresaRequired>
        <div className="p-grid p-d-flex p-jc-between p-ai-end">
          <ConvenioFilter />

          <div className="p-col-12 p-md-6 p-lg-4 p-pr-0 p-pl-0 p-pl-md-2 p-mb-1 p-grid">
            <Button
              className="p-col-12 p-px-0"
              btnType="pill"
              label="Novo pacote"
              icon="fas fa-plus"
              onClick={handleAddNewPacote}
              checkPermission="ADM_PACOTES_CADASTRAR"
            />
          </div>
        </div>

        <SearchList.BorderContainer>
          <SearchList.SearchInput placeholder="Pesquisar um pacote" />

          <Header />
          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      <ConfirmDialog
        visible={confirmDelete.isOpen}
        header="Excluir pacote"
        text="O pacote será excluído da base de dados. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => confirmDelete.close()}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};
