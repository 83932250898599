import { useCallback, useEffect, useRef, useState } from 'react';

import { MovimentacaoCarteiraAPI } from 'src/APIs/AdminAPI/CarteiraApsAPI/Movimentacao/MovimentacaoAPI';

import useDebounce from 'src/utils/useDebounce';
import { formatCPF, capitalizeFirstLetter } from 'src/utils/utils';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Avatar from 'src/components/Avatar/Avatar';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import GridListLoading from 'src/components/GridList/GridListLoading';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';

import './SearchPacientes.scss';

interface SearchPacientesProps {
  label?: string;
  setPacienteSelected(profissional: any): void;
  pacienteSelected: any;
}

const SearchProfissionais = (props: SearchPacientesProps) => {
  const { label, pacienteSelected, setPacienteSelected } = props;

  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [pacientesSearch, setPacientesSearch] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const debouncedSearch = useDebounce(searchQuery, 350);

  const getProfissionaisConsultorio = useCallback(async () => {
    try {
      setLoading(true);
      const response = await MovimentacaoCarteiraAPI.getSearchPacientes(
        {
          nome: debouncedSearch,
        },
        { throwError: true },
      );

      if ('status' in response) return;

      setPacientesSearch(response.list);
    } catch (e) {
      setPacientesSearch([]);
    } finally {
      setLoading(false);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    getProfissionaisConsultorio();
  }, [getProfissionaisConsultorio]);

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnPanel) {
        setShowOverlay(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, []);

  const pacientesOptions = pacientesSearch.filter(
    (paciente: any) =>
      !pacienteSelected.some((selected: any) => selected.id === paciente.id),
  );

  const renderOverlay = useCallback(() => {
    if (loading) return <GridListLoading />;

    if (!pacientesOptions.length)
      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Nenhum paciente encontrado
        </SimpleText>
      );

    return (
      <div className="scroll-place">
        <ScrollPanel stretch={true} activityIndicator={false}>
          <>
            <AlertBox
              text="A lista pesquisada não trás pacientes já vinculados a outra equipe ou profissional"
              className="p-mb-2"
              visible
            />

            {pacientesOptions?.map((paciente: any, index: number) => (
              <div
                key={index}
                className="p-my-1"
                id="search-profissionais-card"
                onClick={() => {
                  setPacienteSelected((prev: any) => [...prev, paciente]);

                  setShowOverlay(false);
                }}
              >
                <Card type={CARD_TYPES.GRAY}>
                  <div className="p-d-flex p-p-1">
                    <div className="p-d-flex">
                      <div className="p-mr-1">
                        <Avatar
                          className="avatar"
                          label={paciente?.nome}
                          image={paciente?.fotoUrl || ''}
                        />
                      </div>
                      <div className="p-d-flex p-flex-column p-jc-center p-ml-2">
                        <SimpleText
                          fontSize={FONT_SIZE.XS}
                          fontColor={FONT_COLOR.COLOR_16}
                          className="p-mb-2"
                          medium
                        >
                          {paciente?.nome}
                        </SimpleText>
                        <SimpleText
                          fontSize={FONT_SIZE.XXS}
                          fontColor={FONT_COLOR.COLOR_60}
                        >
                          {formatCPF(paciente.cpf)} •{' '}
                          {capitalizeFirstLetter(paciente.sexoDescricao)}{' '}
                          {paciente.convenio || ''}
                        </SimpleText>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </>
        </ScrollPanel>
      </div>
    );
  }, [loading, searchQuery, pacientesOptions, setPacienteSelected]);

  return (
    <div ref={searchPanelContainerRef} className="search-input">
      <TextInput
        label={label || ''}
        rounded
        icon="pi pi-search"
        placeholder="Pesquisar"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        onFocus={() => setShowOverlay(true)}
      />

      {showOverlay && (
        <div className="search-input-overlay">
          <ElevatedCard type={'white'} stretch noMargin>
            <div className="search-input-overlay-content">
              {renderOverlay()}
            </div>
          </ElevatedCard>
        </div>
      )}
    </div>
  );
};

export default SearchProfissionais;
