import { useState } from 'react';

import { toast } from 'react-toastify';

import Toast from 'src/components/Basics/Toast/Toast';

export const useCopyClipboard = (value = '') => {
  const [hasCopied, setHasCopied] = useState(false);

  const copyToClipboard = (value: string) => {
    if (document.execCommand) {
      // Browsers antigos
      const textArea = document.createElement('textarea');
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setHasCopied(true);
    } else if (navigator.clipboard) {
      // Browser que suporta clipboard API
      navigator.clipboard.writeText(value).then(() => {
        setHasCopied(true);
      });
    }
  };

  const onCopy = (newValue: string) => {
    if (!newValue) {
      return toast(<Toast />, {
        data: {
          message: 'Nenhum valor para copiar.',
          type: 'warning',
        },
      });
    }
    copyToClipboard(newValue.trim() || value.trim());
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 1500);
    return;
  };

  return { value, onCopy, hasCopied };
};
