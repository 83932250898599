import dayjs from 'dayjs';

import { capitalizeFirstLetter, formatCPF } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

export const BuscaAvancadaRow = ({ item }: { item: GetBuscaAvancada }) => {
  const status = item.status === 'ATIVO' ? 'ativo' : 'inativo';

  return (
    <div className="content-row p-col-12 p-d-flex p-ai-center">
      <div className="p-col-2 p-d-flex p-flex-column p-ai-center p-gap-2">
        <SimpleText>
          {item?.dataAgendamento
            ? dayjs(item.dataAgendamento).format('DD/MM/YYYY HH:mm')
            : '-'}
        </SimpleText>
        <span className={`tag tag-${status}`}>
          <SimpleText>{capitalizeFirstLetter(status)}</SimpleText>
        </span>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText>{item.paciente}</SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          {item.cpf ? formatCPF(item.cpf) : '-'}
        </SimpleText>
      </div>
      <div className="p-col-1 p-d-flex p-jc-center p-ai-center p-gap-1">
        <Tooltip target="#cid" content={item.nomeCid} />
        {item.codigoCid ? (
          <span
            id="cid"
            data-pr-tooltip={item.nomeCid || '-'}
            data-pr-position="top"
            className="tag tag-CID"
          >
            <SimpleText>{item.codigoCid}</SimpleText>
          </span>
        ) : (
          '-'
        )}
      </div>

      <div className="p-col">
        {item.anamnese && <Tooltip target="#col-anamnese" />}
        <SimpleText
          className="p-text-climp-4"
          id="col-anamnese"
          data-pr-tooltip={item.anamnese || '-'}
          data-pr-position="top"
        >
          {item.anamnese || '-'}
        </SimpleText>
      </div>
      <div className="p-col">
        {item.exameFisico && <Tooltip target="#col-exame" />}
        <SimpleText
          className="p-text-climp-4"
          id="col-exame"
          data-pr-tooltip={item.exameFisico || '-'}
          data-pr-position="top"
        >
          {item.exameFisico || '-'}
        </SimpleText>
      </div>
      <div className="p-col">
        {item.patologiaDetectada && <Tooltip target="#col-patologia" />}
        <SimpleText
          className="p-text-climp-4"
          id="col-patologia"
          data-pr-tooltip={item.patologiaDetectada || '-'}
          data-pr-position="top"
        >
          {item.patologiaDetectada || '-'}
        </SimpleText>
      </div>
      <div className="p-col">
        {item.prognostico && <Tooltip target="#col-prognostico" />}
        <SimpleText
          className="p-text-climp-4"
          id="col-prognostico"
          data-pr-tooltip={item.prognostico || '-'}
          data-pr-position="top"
        >
          {item.prognostico || '-'}
        </SimpleText>
      </div>
      <div className="p-col">
        {item.decisaoTerapeutica && <Tooltip target="#col-decisao" />}
        <SimpleText
          className="p-text-climp-4"
          id="col-decisao"
          data-pr-tooltip={item.decisaoTerapeutica || '-'}
          data-pr-position="top"
        >
          {item.decisaoTerapeutica || '-'}
        </SimpleText>
      </div>
      <div className="p-col">
        {item.descricaoTuss && <Tooltip target="#col-tuss" />}
        <SimpleText
          className="p-text-climp-4"
          id="col-tuss"
          data-pr-tooltip={item.descricaoTuss || '-'}
          data-pr-position="top"
        >
          {item.descricaoTuss || '-'}
        </SimpleText>
      </div>
    </div>
  );
};
