import React from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { bgColors } from '../DiagnosticoFuncionalGlobal';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { generateRandomString } from 'src/utils/utils';
import dayjs from 'dayjs';

interface CognicaoHumorProps {
  diagnostico: any;
}

const CognicaoHumor = ({ diagnostico }: CognicaoHumorProps) => {
  const MEEM = Array.isArray(diagnostico?.cognicaoHumor?.MEEM)
    ? diagnostico?.cognicaoHumor?.MEEM
    : [diagnostico?.cognicaoHumor?.MEEM];
  const desenhoRelogio = Array.isArray(
    diagnostico?.cognicaoHumor?.desenhoRelogio,
  )
    ? diagnostico?.cognicaoHumor?.desenhoRelogio
    : [diagnostico?.cognicaoHumor?.desenhoRelogio];
  const fluenciaVerbal = Array.isArray(
    diagnostico?.cognicaoHumor?.fluenciaVerbal,
  )
    ? diagnostico?.cognicaoHumor?.fluenciaVerbal
    : [diagnostico?.cognicaoHumor?.fluenciaVerbal];
  const humor = Array.isArray(diagnostico?.cognicaoHumor?.humor)
    ? diagnostico?.cognicaoHumor?.humor
    : [diagnostico?.cognicaoHumor?.humor];

  const corMEEM = MEEM[0]?.cor;
  const corDesenhoRelogio = desenhoRelogio[0]?.cor;
  const corFluenciaVerbal = fluenciaVerbal[0]?.cor;
  const corHumor = humor[0]?.cor;

  return (
    <div className="p-d-flex border-dashed-x p-ai-center border-dashed-bottom">
      <div className="w-210px p-d-flex p-jc-center">
        <SimpleText fontSize="xxs" className="p-p-1">
          COGNIÇÃO E HUMOR
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column">
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              MEEM
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center  p-p-1 ${
              corMEEM === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x  p-p-1 ${
              corMEEM === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Comprometimento Cognitivo Possível (19 - 25) - 2
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corMEEM === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`Comprometimento Cognitivo Provável (= ou < 18) - 4`}
            </SimpleText>
          </div>

          {MEEM.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1 p-text-center">
              TESTE DO DESENHO DO RELÓGIO
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center  p-p-1 ${
              corDesenhoRelogio === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x  p-p-1 ${
              corDesenhoRelogio === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Alterações nos ponteiros (6 a 8) - 1
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corDesenhoRelogio === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              Números incorretos (1 a 5) - 2
            </SimpleText>
          </div>

          {desenhoRelogio.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              FLUÊNCIA VERBAL{' '}
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center  p-p-1 ${
              corFluenciaVerbal === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x  p-p-1 ${
              corFluenciaVerbal === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              (9 a 13 ) - 1
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corFluenciaVerbal === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`(= ou < que 8) - 2`}
            </SimpleText>
          </div>

          {fluenciaVerbal.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
        <div className="p-d-flex p-flex-1">
          <div className="w-210px p-d-flex p-jc-center border-dashed-x">
            <SimpleText fontSize="xxs" className="p-p-1">
              HUMOR (EDG15){' '}
            </SimpleText>
          </div>
          <div
            className={`w-47px p-d-flex p-jc-center p-ai-center  p-p-1 ${
              corHumor === 0 ? bgColors[0] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-70 p-text-center">
              0
            </SimpleText>
          </div>
          <div
            className={`w-331px p-d-flex p-jc-center  border-dashed-x  p-p-1 ${
              corHumor === 1 ? bgColors[1] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              TD Suspeito (5 a 9) - 2
            </SimpleText>
          </div>
          <div
            className={`w-330px p-d-flex p-jc-center p-p-1 ${
              corHumor === 2 ? bgColors[2] : ''
            }`}
          >
            <SimpleText fontSize="xxs" className="p-p-1 p-w-90 p-text-center">
              {`TD Provável (10 ou >) - 4`}
            </SimpleText>
          </div>

          {humor.map(
            (
              item: {
                pontuacao: number;
                cor: number;
                dataResposta: string;
                nomeProfissionalSaude: string;
              },
              index: number,
            ) => {
              const tooltipId = generateRandomString();
              return (
                <div
                  key={index}
                  className={`w-47px p-d-flex p-jc-center p-ai-center ${
                    index % 2 === 0 ? 'border-dashed-x' : ''
                  }`}
                >
                  {item?.dataResposta && (
                    <Tooltip
                      id="tooltip-diagnostico-funcional-global"
                      target={`.${tooltipId}`}
                      position="top"
                    >
                      <SimpleText
                        fontSize="xxs"
                        data-pr-tooltip={dayjs(
                          new Date(item?.dataResposta),
                        ).format('DD/MM/YYYY [às] HH:mm')}
                        data-pr-position="top"
                        className="tooltip-text"
                      >
                        {dayjs(new Date(item?.dataResposta)).format(
                          'DD/MM/YYYY [às] HH:mm',
                        )}{' '}
                        por <strong>{item?.nomeProfissionalSaude || ''}</strong>
                      </SimpleText>
                    </Tooltip>
                  )}
                  <SimpleText
                    fontSize="xxs"
                    className={`p-p-1 ${tooltipId} p-flex-1 p-text-center`}
                  >
                    {item?.pontuacao}
                  </SimpleText>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default CognicaoHumor;
