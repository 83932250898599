export const transformarEmMoeda = (valorRecebido: number) => {
  if (!valorRecebido) return '';

  const valorTransformado = valorRecebido.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return valorTransformado;
};
