import { memo, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from 'src/components/_UI';
import { FieldCiapMultiselect } from 'src/components/Fields/FieldCiapMultiselect/FieldCiapMultiselect';
import { FieldCidsMultiselect } from 'src/components/Fields/FieldCidsMultiselect/FieldCidsMultiselect';
import FormInput from 'src/components/FormInput/FormInput';
import Tab from 'src/components/Tab/Tab';

import VinculosConsultorios from './Vinculos/VinculosConsultorios';
import VinculosConvenios from './Vinculos/VinculosConvenios';

const ProtocoloAtendimentoCreateFormVinculos = ({
  data,
  handleSubmit,
}: {
  data: any;
  handleSubmit(v: any): void;
}) => {
  const navigate = useNavigate();

  const formMethods = useForm({
    resolver: yupResolver(
      Yup.object({
        nome: Yup.string()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
      }),
    ),
  });

  const { reset, watch } = formMethods;

  useEffect(() => {
    const defaultConsultorios =
      data?.vinculos?.consultorios?.map((consultorio: any) => consultorio.id) ||
      [];

    const defaultConvenios =
      data?.vinculos?.convenios?.map((convenio: any) => convenio.id) || [];
    reset({
      nome: data?.nome || '',
      consultorios: defaultConsultorios || [],
      convenios: defaultConvenios || [],
      ...data,
    });
  }, [data, reset]);

  const watchNome = watch('nome');

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        className="p-col-12 p-md-8 p-lg-7 p-xl-6 p-d-flex p-flex-column"
      >
        <FormInput
          className="p-px-2"
          name="nome"
          label="Nome do protocolo"
          maxLength={100}
        />
        <FieldCidsMultiselect name="cids" label="CID" />
        <FieldCiapMultiselect name="ciaps" label="CIAP" />

        <Tab
          clean
          headerColor={false}
          className="p-w-100"
          values={[
            {
              label: 'Consultórios',
              content: <VinculosConsultorios />,
            },
            {
              label: 'Convênios',
              content: <VinculosConvenios />,
            },
          ]}
        />

        <div className="p-d-flex p-gap-1 p-my-2">
          <Button
            label="Voltar"
            btnType="ghost"
            onClick={() => navigate(-1)}
            stretch
          />
          <Button
            label="Próximo"
            btnType="tonal"
            type="submit"
            disabled={!watchNome}
            stretch
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default memo(ProtocoloAtendimentoCreateFormVinculos);
