import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';

import CardQuestions from '../../Components/CardQuestions/CardQuestions';
import Template from '../../Components/Template/Template';

interface HistoricoFamiliarProps {
  readOnly?: boolean | undefined;
  values?: Questionarios | undefined;
}

enum Values {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_SE_APLICA = 'NAO_SE_APLICA',
}

const HistoricoFamiliar = ({ values, readOnly }: HistoricoFamiliarProps) => {
  const {
    setValue,
    watch,
    control,
    formState: { errors },
    resetField,
  } = useFormContext();

  const HistoricoNaoSeAplica = watch('HistoricoNaoSeAplica');
  const outros = watch('outros');
  const outrosNaoSeAplica = watch('outrosNaoSeAplica');

  useEffect(() => {
    if (typeof HistoricoNaoSeAplica === 'boolean') {
      const valueNaoSeAplica = HistoricoNaoSeAplica
        ? Values.NAO_SE_APLICA
        : undefined;

      const fields = [
        'mama',
        'intestino',
        'prostata',
        'outros',
        'descricaoOutros',
      ];

      fields.forEach(field => {
        setValue(field, valueNaoSeAplica, {
          shouldValidate: HistoricoNaoSeAplica,
        });
      });
    }
  }, [setValue, HistoricoNaoSeAplica]);

  useEffect(() => {
    if (outrosNaoSeAplica) {
      setValue('outros', Values.NAO_SE_APLICA, { shouldValidate: true });
      setValue('descricaoOutros', undefined, { shouldValidate: true });
    }
  }, [outrosNaoSeAplica, setValue]);

  useEffect(() => {
    if (outros === 'NAO') {
      setValue('descricaoOutros', undefined, { shouldValidate: true });
      setValue('descricaoNaoSeAplica', undefined, { shouldValidate: true });
    }
  }, [outros, setValue]);

  return (
    <Template
      title="Histórico familiar câncer"
      name="HistoricoNaoSeAplica"
      readOnly={readOnly}
      resetField={resetField}
      control={control}
    >
      <div className="container-historico">
        <CardQuestions
          title="Mama:"
          name="mama"
          control={control}
          disabled={readOnly || HistoricoNaoSeAplica}
          defaultValue={values?.mama}
          error={errors?.mama?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="mama"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || HistoricoNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Intestino:"
          name="intestino"
          control={control}
          disabled={readOnly || HistoricoNaoSeAplica}
          defaultValue={values?.intestino}
          error={errors?.intestino?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="intestino"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || HistoricoNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Próstata:"
          name="prostata"
          control={control}
          disabled={readOnly || HistoricoNaoSeAplica}
          defaultValue={values?.prostata}
          error={errors?.prostata?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="prostata"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || HistoricoNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Outros:"
          name="outros"
          control={control}
          disabled={readOnly || HistoricoNaoSeAplica}
          defaultValue={values?.outros}
          error={errors?.outros?.message}
        >
          <RadioButtonGroupControlled
            control={control}
            name="outros"
            unstyled
            dir="row"
            items={[
              {
                label: 'Sim',
                value: Values.SIM,
              },
              {
                label: 'Não',
                value: Values.NAO,
              },
            ]}
            disabled={readOnly || HistoricoNaoSeAplica}
          />
        </CardQuestions>

        <CardQuestions
          title="Descrição:"
          name="descricaoOutros"
          control={control}
          defaultValue={values?.descricaoOutros}
          disabled={
            outros === 'NAO' ||
            outrosNaoSeAplica ||
            readOnly ||
            HistoricoNaoSeAplica
          }
          error={errors?.descricaoOutros?.message}
        >
          <TextareaInputControlled
            control={control}
            name="descricaoOutros"
            disabled={
              outros === 'NAO' ||
              outrosNaoSeAplica ||
              readOnly ||
              HistoricoNaoSeAplica
            }
          />
        </CardQuestions>
      </div>
    </Template>
  );
};

export default HistoricoFamiliar;
