import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import { RadioButtonGroupControlled } from 'src/components/RadioButtonGroupControlled/RadioButtonGroupControlled';
import Skeleton from 'src/components/Skeleton/Skeleton';

const tipoMonitoramentoCarteiraAps = [
  { label: 'Profissional', value: 'PROFISSIONAL' },
  { label: 'Equipe', value: 'EQUIPE' },
];

const defaultValues = {
  tipoMonitoramentoCarteiraAps: 'PROFISSIONAL',
  limitePacientes: '',
  limitePontos: '',
};
interface FormCarteiraApsProps {
  idConsultorio: number;
  carteiraAps: any;
  goBackStep: () => void;
}

const FormCarteiraAps = ({
  idConsultorio,
  carteiraAps,
  goBackStep,
}: FormCarteiraApsProps) => {
  const navigation = useNavigate();

  const [showAlertBox, setShowAlertBox] = useState(false);
  const [hasPacientesVinculados, setHasPacientesVinculados] = useState(false);
  const [loading, setLoading] = useState(true);

  const useFormMethods = useForm({
    defaultValues: carteiraAps ?? defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormMethods;

  useEffect(() => {
    const getHasPacientesVinculados = async () => {
      try {
        setLoading(true);

        const response =
          await ConsultorioAPI.getConsultorioCarteiraApsPacientesVinculados(
            idConsultorio,
            { throwError: true },
          );

        setHasPacientesVinculados(response);
        setShowAlertBox(response);
      } catch (error) {
        goBackStep();
      } finally {
        setLoading(false);
      }
    };

    getHasPacientesVinculados();
  }, [goBackStep, idConsultorio]);

  const onSubmit = async (data: any) => {
    const response = await ConsultorioAPI.putConsultorioCarteiraAps(
      idConsultorio,
      data,
    );

    if (!('status' in response))
      navigation('/cadastros/consultorio', { replace: true });
  };

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-grid p-col-12 p-md-9 p-lg-7 p-xl-5"
      >
        <div className="p-col-12">
          <SimpleText fontSize={FONT_SIZE.XS} bold>
            Monitorar carteira por:
          </SimpleText>

          <Skeleton loading={loading} height="34px" borderRadius="8px">
            <RadioButtonGroupControlled
              control={control}
              name="tipoMonitoramentoCarteiraAps"
              items={tipoMonitoramentoCarteiraAps}
              stretch
              disabled={hasPacientesVinculados}
            />
          </Skeleton>

          <AlertBox
            visible={showAlertBox}
            onHide={() => setShowAlertBox(false)}
            text="Para alterar a forma de monitorar a carteira, remova todos os vínculos dos pacientes com a Equipe/Profissional."
          />
        </div>

        <Divider layout="horizontal" className="p-col-12" />

        <div className="p-grid p-col-12">
          <FormInput
            name="limitePacientes"
            label="Limite de pacientes"
            type="number"
            className="p-col-6"
            max={9999}
          />

          <FormInput
            name="limitePontos"
            label="Limite de pontos"
            type="number"
            className="p-col-6"
            max={999999}
          />
        </div>

        <div className="p-col-12 p-mt-3">
          <Button
            label="Salvar"
            className="p-w-100 p-mt-5"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default FormCarteiraAps;
