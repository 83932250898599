import { memo, useMemo } from 'react';

import { ToastContentProps } from 'react-toastify';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './Toast.scss';

const Toast = (props: ToastProps) => {
  const { data } = props;

  const icon = useMemo(() => {
    if (data?.type === 'error' || data?.type === 'warning')
      return 'fa-exclamation-triangle';

    return 'fa-check-circle';
  }, [data]);

  const title = useMemo(() => {
    if (data?.type === 'error') return 'Erro';
    if (data?.type === 'warning') return 'Atenção';

    return 'Sucesso';
  }, [data]);

  return (
    <div className="toast-header-container">
      <span className={`toast-icon-container ${data?.type}`}>
        <i className={`fas ${icon}`}></i>
      </span>
      <div className="toast-header-content">
        <div className="toast-header-title">
          <SimpleText fontSize={FONT_SIZE.MD}>
            {data?.title || title}
          </SimpleText>
        </div>

        <SimpleText
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          className="toast-content-text">
          {data?.message || 'Ocorreu um erro'}
        </SimpleText>
      </div>
    </div>
  );
};

interface Data {
  message?: string;
  type?: 'error' | 'warning' | 'success';
  title?: string;
}

type ToastProps = Partial<ToastContentProps<Data>>;

export default memo(Toast);
