import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

interface DesktopRowProps {
  hospital: any;
  onEdit: (hospital: any) => void;
  onDelete: (hospital: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { hospital, onEdit, onDelete } = props;
  const menu = useRef<any>(null);

  const itemsOptions = [
    {
      label: 'Editar',
      command: () => onEdit(hospital),
      checkPermission: 'ADM_HOSPITAL_ALTERAR',
    },
    {
      label: 'Excluir',
      command: () => onDelete(hospital),
      checkPermission: 'ADM_HOSPITAL_EXCLUIR',
      className: 'error',
    },
  ];

  return (
    <>
      <SimpleText
        className="p-col-7"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
        medium
      >
        {hospital?.nome}
      </SimpleText>

      <SimpleText
        className="p-col-3 p-lg-4"
        fontSize={FONT_SIZE.XXS}
        fontColor={FONT_COLOR.COLOR_16}
      >
        {hospital?.cnpj}
      </SimpleText>

      <div className="p-col-2 p-lg-1 p-d-flex p-jc-end p-gap-2">
        <Button
          className="p-mr-1"
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={event => menu?.current?.toggle(event)}
        />

        <TieredMenu
          className="tiered-menu"
          model={itemsOptions}
          popup
          ref={menu}
        />
      </div>
    </>
  );
};
