import * as Yup from 'yup';

const msg = {
  required: 'Este campo é obrigatório',
};

const listServicoTussValidationSchema = Yup.object().shape({
  nome: Yup.string().required(msg.required),
  valor: Yup.number().nullable().required(msg.required),
});

export const validationSchema = Yup.object().shape({
  nome: Yup.number().required(msg.required),
  listServicoTuss: Yup.array().of(listServicoTussValidationSchema),
});
