import { useMemo } from 'react';

import dayjs from 'dayjs';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter, formatCPF } from 'src/utils/utils';

import { DialogVinculos } from '../AplicacaoFormulario/_components/DialogVinculos/DialogVinculos';
import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { useFormularioDinamicoAplicacaoFormulario } from '../AplicacaoFormulario/useFormularioDinamicoAplicacaoFormulario';

const sexoOptions: { [key: number]: string } = {
  3: 'Masculino',
  1: 'Feminino',
};

export const FormularioDinamicoGestaoMonitoramentoRow = ({
  item,
}: {
  item: IGestaoMonitoramentoItems;
}) => {
  const { isOpen, toggle } = useDisclosure({ opened: false });
  const { consultorios, convenios, especialidades } =
    useFormularioDinamicoAplicacaoFormulario();

  const dialogVinculos = useDisclosure({ opened: false });

  const vinculosLength = useMemo(() => {
    const vinculosConsultorios = item.aplicacaoFormularioDinamico.consultorios
      ?.length
      ? item.aplicacaoFormularioDinamico.consultorios
      : consultorios;
    const vinculosEspecialidades = item.aplicacaoFormularioDinamico
      .especialidades?.length
      ? item.aplicacaoFormularioDinamico.especialidades
      : especialidades;
    const vinculosConvenios = item.aplicacaoFormularioDinamico.convenios?.length
      ? item.aplicacaoFormularioDinamico.convenios
      : convenios;

    const length =
      vinculosConsultorios.length +
      vinculosEspecialidades.length +
      vinculosConvenios.length;

    return length > 99 ? '+99' : length.toString();
  }, [item, consultorios, especialidades, convenios]);

  return (
    <div
      className={
        'row content-row p-col-12 p-mb-2 p-ai-top p-flex-wrap gap-md-2'
      }
    >
      <div
        className="p-d-flex p-flex-column p-gap-2 p-text-center"
        style={{
          width: '60px',
        }}
      >
        <SimpleText fontColor="color_60" fontSize="xxs">
          Pontuação
        </SimpleText>
        <SimpleText fontColor="primary" fontSize="lg" medium>
          {item.totalPontuacao || (
            <SimpleText fontColor="color_60" fontSize="xxs">
              Ausente
            </SimpleText>
          )}
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-3 p-col-8 p-md-3 p-py-0">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Formulário
        </SimpleText>
        <SimpleText medium>
          {item.aplicacaoFormularioDinamico.formularioDinamico.nome}
        </SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-3 p-col-4 p-md-2 p-py-0">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Aplicação
        </SimpleText>
        <SimpleText medium>{item.aplicacaoFormularioDinamico.nome}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-3 p-col-4 p-md-3 p-py-0">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Profissional de saúde monitor
        </SimpleText>
        <SimpleText medium>{item.profissionalSaude.usuario.nome}</SimpleText>
      </div>
      <div className="p-d-flex p-flex-column p-gap-3 p-col-4 p-md-2 p-py-0">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data do ultimo monitoramento
        </SimpleText>
        <SimpleText>
          {dayjs(
            item.dataAtualizacao ? item.dataAtualizacao : item.dataInclusao,
          ).format('DD/MM/YYYY HH:mm')}
        </SimpleText>
      </div>
      <div className="p-d-flex p-gap-2 p-col p-py-0 p-jc-end p-ai-center">
        <Divider layout="vertical" className="p-m-0 p-p-0" />
        <Button
          icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="gray"
          onClick={() => toggle()}
        />
      </div>

      {isOpen && (
        <div className="p-col-12 row content-row white p-p-1 p-mt-1 p-flex-wrap">
          <div className="p-d-flex p-col-6 p-sm-4 p-md-3 p-p-1 row content-row">
            <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
              <SimpleText fontColor="color_60" fontSize="xxs">
                Local de aplicação
              </SimpleText>
              <SimpleText medium>
                {capitalizeFirstLetter(
                  item.aplicacaoFormularioDinamico.publico,
                )}
              </SimpleText>
            </div>
            <div
              className="p-col-6 p-d-flex p-flex-column p-gap-2 cursor-pointer"
              onClick={() => dialogVinculos.open()}
            >
              <SimpleText
                fontColor="color_60"
                fontSize="xxs"
                className="pointer-events-none"
              >
                Vínculos
              </SimpleText>
              <SimpleText fontColor="primary" className="pointer-events-none">
                {vinculosLength} vinculos
              </SimpleText>
            </div>
          </div>

          <div className="p-col-6 p-sm-4 p-md-3 p-xl-2 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Nome do paciente
            </SimpleText>
            <SimpleText medium>{item.paciente?.nome}</SimpleText>
          </div>
          <div className="p-col-5 p-sm-4 p-md-3 p-xl-2 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Carteirinha
            </SimpleText>
            <SimpleText medium>{item.paciente?.cartaoPrincipal}</SimpleText>
          </div>
          <div className="p-col-5 p-sm-3 p-md-2 p-xl-1 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              CPF
            </SimpleText>
            <SimpleText medium>
              {item.paciente?.cpf && formatCPF(item.paciente.cpf)}
            </SimpleText>
          </div>
          <div className="p-col-2 p-xl-1 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Cidade
            </SimpleText>
            <SimpleText medium>
              {item.paciente?.enderecoResidencial?.descCidade || '-'}
            </SimpleText>
          </div>
          <div className="p-col-5 p-sm-3 p-md-2 p-xl-1 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Data de nasc.
            </SimpleText>
            <SimpleText medium>
              {item.paciente?.dataNascimento &&
                dayjs(item.paciente.dataNascimento).format('DD/MM/YYYY')}
            </SimpleText>
          </div>
          <div className="p-col-2 p-sm-1 p-xl-1 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Idade
            </SimpleText>
            <SimpleText medium>
              {item.paciente?.dataNascimento &&
                dayjs().diff(item.paciente.dataNascimento, 'year')}
            </SimpleText>
          </div>
          <div className="p-col-5 p-sm-3 p-xl-1 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Sexo biológico
            </SimpleText>
            <SimpleText medium>
              {item.paciente?.idSexo && sexoOptions[item.paciente.idSexo]}
            </SimpleText>
          </div>

          <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Mensagem para o paciente
            </SimpleText>
            <SimpleText medium>
              {item.pontuacao?.mensagemPaciente || '-'}
            </SimpleText>
          </div>
        </div>
      )}

      {dialogVinculos.isOpen && (
        <DialogVinculos
          {...dialogVinculos}
          state={item.aplicacaoFormularioDinamico}
        />
      )}
    </div>
  );
};
