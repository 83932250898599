import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/solicitacoes-procedimentos';

const getBuscaRapida = async (
  idAtendimento: number,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/busca-rapida`,
    hideToast: true,
    throwError: true,
    params,
    ...options,
  });

const postProcedimento = async (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/adicionar-procedimento`,
    data: payload,
    throwError: true,
    hideToast: true,
    ...options,
  });

const putProcedimento = async (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}`,
    data: payload,
    throwError: true,
    ...options,
  });

const getSolicitacoesProcedimentos = async (
  idAtendimento: number,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/convenio/${idConvenio}/listar-procedimento`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const getTipoInternacao = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/tipo-internacao`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const getRegimeInternacao = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/regime-internacao`,
    hideToast: true,
    throwError: true,
    ...options,
  });

const deleteProcedimento = async (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/remover-procedimento/${idSolicitacaoProcedimento}`,
    throwError: true,
    ...options,
  });

const putProcedimentoLaudo = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/laudo`,
    data: payload,
    throwError: true,
    ...options,
  });

const putQuantidade = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/quantidade`,
    data: payload,
    throwError: true,
    ...options,
  });

const putDataRealizacao = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/data-realizacao`,
    data: payload,
    throwError: true,
    ...options,
  });

const getHistoricoSadt = (
  idAtendimento: number,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/historico-resultado-exame`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });

const putStatus = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  status: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/alterar-status/${status}`,
    throwError: true,
    ...options,
  });

const putSeveridade = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idSolicitacaoProcedimento}/${idAtendimento}/criticidade`,
    data: payload,
    throwError: true,
    hideToast: true,
    ...options,
  });

const putLembrete = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/lembrete/${idSolicitacaoProcedimento}`,
    throwError: true,
    ...options,
  });

const putDataRepeticao = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  dataRepeticao: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/data-repeticao/${dataRepeticao}`,
    throwError: true,
    ...options,
  });

const putParecer = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/parecer`,
    data: payload,
    throwError: true,
    ...options,
  });

const putInativar = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idSolicitacaoProcedimento}/${idAtendimento}/historico-sadt/inativar`,
    data: payload,
    throwError: true,
    ...options,
  });

const putResultado = (
  idAtendimento: number,
  idSolicitacaoProcedimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/procedimento/${idSolicitacaoProcedimento}/resultado`,
    data: payload,
    throwError: true,
    ...options,
  });

const getBase64GuiaTiss = (idAtendimento: number): Promise<any> =>
  Http.get({
    service: 'report',
    url: `guia-tiss/${idAtendimento}/dados`,
    hideToast: true,
    throwError: true,
  });

const postAssinaturaGuiaTiss = (
  idAtendimento: number,
  documentos: any,
  funcao?: string,
): Promise<any> =>
  Http.post({
    service: 'report',
    url: `guia-tiss/${idAtendimento}${funcao ? `/${funcao}` : ''}`,
    data: documentos,
    throwError: true,
    return: true,
    responseType: 'arraybuffer',
  });

const putConvenioProcedimentos = (
  idAtendimento: number,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
) =>
  Http.put({
    service: 'agenda',
    url: `agendas/atendimento/${idAtendimento}/convenio-procedimentos/${idConvenio}`,
    hideToast: true,
    ...options,
  });

const deleteAllProcedimentos = async (
  idAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/remover-todos-procedimentos`,
    ...options,
  });

const enviarOperadora = async (
  idAtendimento: number,
  idConvenio: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'pep',
    url: `/tiss/enviar-operadora/convenio/${idConvenio}/atendimento/${idAtendimento}`,
    hideToast: true,
    ...options,
  });

const responderPendencias = async (
  idAtendimento: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/${idAtendimento}/responder-pendenicas`,
    data: payload,
    hideToast: true,
    ...options,
  });

const loadSolcitacoesPortal = async (
  params?: {
    descricao?: string;
    idProfissionalSaude?: number;
    dataInicial?: string;
    dataFinal?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/paciente`,
    params,
    hideToast: true,
    ...options,
  });

const getLaudoPdf = async (
  idLaudo: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `resultado-exame/laudo-importado/${idLaudo}`,
    hideToast: true,
    throwError: true,
    ...options,
  });

export const SolicitacoesProcedimentosAPI = {
  getBuscaRapida,
  postProcedimento,
  putProcedimento,
  getSolicitacoesProcedimentos,
  getTipoInternacao,
  getRegimeInternacao,
  deleteProcedimento,
  putProcedimentoLaudo,
  putQuantidade,
  putDataRealizacao,
  getHistoricoSadt,
  getBase64GuiaTiss,
  postAssinaturaGuiaTiss,
  putStatus,
  putSeveridade,
  putLembrete,
  putDataRepeticao,
  putParecer,
  putInativar,
  putResultado,
  putConvenioProcedimentos,
  deleteAllProcedimentos,
  enviarOperadora,
  responderPendencias,
  loadSolcitacoesPortal,
  getLaudoPdf,
};
